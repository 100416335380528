import { Component } from "@angular/core";
import { ExportBase } from "../export-base";
import { ExportType } from "@enums/ExportType";
import { ExportDataService } from "@app/export/services/export-data.service";

@Component({
    selector: "export-datamanagement",
    templateUrl: "./export-datamanagement.component.html",
    styleUrls: ["./export-datamanagement.component.scss"],
})
export class ExportDatamanagementComponent extends ExportBase {
    includeChecked = false;
    constructor(exportDataService: ExportDataService) {
        super(exportDataService);
    }

    downloadDatamanagement(type: ExportType) {
        const extra = `includedeletedandclosedmois=${this.includeChecked}`;
        this.exportDataService.downloadExport(type, extra);
    }
}
