import { Component, OnInit } from "@angular/core";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";
import { StandingDataType } from "@enums/StandingDataType";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";
import { UserCurrentInfoModel } from "./shared/user-current-info-model";
import { UserService } from "./shared/user.service";

@Component({
    selector: "user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
    tab: TabModel;
    user: UserCurrentInfoModel;
    passwordChangeModel = {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
    };

    ipConfigType;

    constructor(
        private _ds: UserService,
        private _authDS: UserAuthenticationService,
        private _toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this._ds.getUserInfo().subscribe((x) => {
            this.user = x;

            if (this.user.manageIPAllowed) {
                this.ipConfigType = StandingDataType.IpWhitelistingPersonal;
            }

            this.tab.showLoader = false;
        });
    }

    logout() {
        this._authDS.logout().subscribe((x) => {
            window.location.reload();
        });
    }

    changePassword() {
        this._ds.changePassword(this.passwordChangeModel).subscribe((x) => {
            if (!x) {
                this.passwordChangeModel.oldPassword = "";
                this.passwordChangeModel.newPassword = "";
                this.passwordChangeModel.newPasswordConfirm = "";
                this._toastr.success("Password has been updated.", "Success");
            } else {
                this._toastr.error(x, "Update failed");
            }
        });
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem) {
            return;
        }

        switch (menuItem.menuItemId) {
            case "roles":
                window.dispatchEvent(new Event("resize"));
                break;
        }
    }
}
