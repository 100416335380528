import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { PeriodService } from "./shared/period.service";
import { ModuleType } from "@enums/ModuleType";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "app-periods",
    templateUrl: "./periods.component.html",
    styleUrls: ["./periods.component.scss"],
})
export class PeriodsComponent implements OnInit {
    tab: TabModel;

    selectedModule = ModuleType.ORM;
    moduleTypes = ModuleType;

    periods: any[];

    configLoaded = false;
    config: GenericListConfig;

    isRiskAdminU: boolean;
    isBimAdminU: boolean;

    constructor(
        private _ds: PeriodService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this._ds.getPermissions().subscribe((perm) => {
            this.isBimAdminU = perm.isBimAdminU;
            this.isRiskAdminU = perm.isRiskAdminU;

            if (!this.isRiskAdminU) {
                // make sure you see the BIM data, when you heve no Risk rights.
                this.selectedModule = ModuleType.BIM;
            }
            this.loadConfig();
        });
    }

    tabChanged(newTab: TabMenuItem) {
        this.selectedModule = +newTab.menuItemId;
        this.loadConfig();
    }

    loadConfig() {
        this.configLoaded = false;

        this.config = <GenericListConfig>{
            name: `Periods (${ModuleType[this.selectedModule]})`,
            dataMethod: () => {
                return this._ds.getByModule(this.selectedModule);
            },

            allowAdd: true,
            allowEdit: true,

            uiidAdd: "btn-add-period",

            fields: [
                {
                    fieldName: "ID",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                    hideInEdit: true,
                },
                {
                    fieldName: "PeriodName",
                    prettyName: "Period name",
                    fieldType: GenericListFieldType.Text,
                    required: true,
                    uiid: "period-name-input",
                },
                {
                    fieldName: "Active",
                    prettyName: "Current period",
                    fieldType: GenericListFieldType.CheckBox,
                    hideInEdit: true,
                },
                {
                    fieldName: "StartDate",
                    prettyName: "Period start",
                    fieldType: GenericListFieldType.Date,
                    uiid: "period-start-input",
                },
                {
                    fieldName: "EndDate",
                    prettyName: "Period end",
                    fieldType: GenericListFieldType.Date,
                    uiid: "period-end-input",
                },
                {
                    fieldName: "PeriodClosed",
                    prettyName: "Period close date",
                    fieldType: GenericListFieldType.Date,
                    isReadonly: true,
                    hideInEdit: true,
                },
                {
                    fieldName: "AuthUserName",
                    prettyName: "Started by",
                    fieldType: GenericListFieldType.Text,
                    hideInEdit: true,
                },
            ],

            add: async (row) => {
                const moduleName = ModuleType[this.selectedModule];

                // Creates a random 4 digit number within the 0000 - 9999 range
                const confirmationNumber = (Math.floor(Math.random() * 10000) + 10000)
                    .toString()
                    .substring(1);

                const enteredNumber = (
                    await this._promptService
                        .prompt({
                            maxHeight: "375px",
                            maxWidth: "440px",
                            data: {
                                title: "Create new period",
                                message: `You are about to create a new '${moduleName}' period. \nEnter the following code into the confirmation field below as confirmation: \n \n ${confirmationNumber}`,
                                fields: [
                                    {
                                        prettyName: "Confirmation of number",
                                        fieldName: "confirmationnumber",
                                        fieldType: GenericListFieldType.Text,
                                        uiid: "period-confirmation-number-input",
                                    },
                                ],
                                centerText: true,
                            },
                        })
                        .toPromise()
                ).confirmationnumber;

                if (confirmationNumber === enteredNumber) {
                    await this._ds.store(this.selectedModule, row).toPromise();
                    this._toastr.success(
                        "New period has been created and has been set to current active period!",
                        "Create successful"
                    );
                    return true;
                } else if (enteredNumber && enteredNumber.length > 0) {
                    this._toastr.warning(
                        "Confirmation code does not match.",
                        "Invalid confirmation code"
                    );
                }

                return false;
            },
            edit: async (row) => {
                await this._ds.store(this.selectedModule, row).toPromise();
                this._toastr.success("Period has been updated!", "Update successful");
                return true;
            },
        };

        this.configLoaded = true;
    }
}
