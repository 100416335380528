<cerrix-collapsing-box boxTitle="Merge Fields" [isCollapsed]="true" *ngIf="mergeFieldTables">
    <ng-template #content>
        <ng-container *ngFor="let mergeFieldTable of mergeFieldTables">
            <div class="title">
                <span>{{ mergeFieldTable.title }}</span>
                <i
                    *ngIf="mergeFieldTable.description"
                    class="far fa-info-circle ms-1"
                    [tooltip]="mergeFieldTable.description"
                ></i>
            </div>
            <div class="data-grid">
                <ng-container *ngFor="let mergeField of mergeFieldTable.fields">
                    <div class="header">
                        <span>{{ mergeField.name }}</span>
                        <i
                            *ngIf="mergeField.description"
                            class="far fa-info-circle ms-1"
                            [tooltip]="mergeField.description"
                        ></i>
                    </div>
                    <div class="value">
                        <span>{{ mergeField.fieldName }}</span>
                        <i
                            class="copy-button far fa-clipboard-list ms-1"
                            tooltip="Copy to clipboard"
                            (click)="copyField(mergeField.fieldName)"
                        ></i>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-template>
</cerrix-collapsing-box>
