import { Component, Input, ViewChild } from "@angular/core";
import { RiskModel } from "@app/risk/models/RiskModel";
import { RiskDataService } from "@app/risk/services/RiskDataService";

import { RiskPeriodScoreChartComponent } from "./risk-period-score-chart/risk-period-score-chart.component";
import { RiskScoreChartModel } from "@app/risk/models/RiskScoreChartModel";

@Component({
    selector: "risk-result-overview",
    templateUrl: "./risk-result-overview.component.html",
    styleUrls: ["./risk-result-overview.component.scss"],
})
export class RiskResultOverviewComponent {
    @ViewChild("likelihoodScoreChart", { static: true })
    likelihoodScoreChart: RiskPeriodScoreChartComponent;
    @ViewChild("impactScoreChart", { static: true })
    impactScoreChart: RiskPeriodScoreChartComponent;

    loadedCharts = false;
    numberOfKris: number;

    @Input() risk: RiskModel;

    constructor(private _riskDS: RiskDataService) {}

    load(refresh: boolean) {
        if (!refresh && this.loadedCharts) {
            return;
        }

        this.loadedCharts = false;
        this._riskDS.getPeriodScoreData(this.risk.guid).subscribe((chartModel) => {
            this.loadLikelihoodChart(chartModel);
            this.loadImpactChart(chartModel);

            this.loadedCharts = true;
        });
        this.numberOfKris = this.risk.kriGuids.length;
    }

    private loadLikelihoodChart(chartModel: RiskScoreChartModel) {
        chartModel = JSON.parse(JSON.stringify(chartModel)) as RiskScoreChartModel;

        // Only show likelihood serie
        chartModel.series = chartModel.series.filter(
            (x) =>
                x.index === chartModel.likelihoodIndex ||
                x.index === chartModel.grossLikelihoodIndex
        );
        chartModel.chartTitle = "Likelihood over time";

        this.likelihoodScoreChart.loadChart(chartModel);
    }

    private loadImpactChart(chartModel: RiskScoreChartModel) {
        chartModel = JSON.parse(JSON.stringify(chartModel)) as RiskScoreChartModel;

        // Only show all impact series (including custom impacts)
        chartModel.series = chartModel.series.filter(
            (x) =>
                x.index === chartModel.impactIndex ||
                x.index === chartModel.grossImpactIndex ||
                x.index >= chartModel.customImpactStartIndex
        );
        chartModel.chartTitle = "Impact over time";

        this.impactScoreChart.loadChart(chartModel);
    }
}
