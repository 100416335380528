import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { guid } from "@methods/uniqueMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";
import { WopiConfigurationService } from "./services/wopi-configuration.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-wopi",
    templateUrl: "./wopi.component.html",
})
export class WopiComponent implements OnInit, AfterViewInit {
    tab: TabModel;

    documentGuid: string; //documentGuid needs to be imported from the current url
    action: string; // Action needs to be imported from the current url (edit or embedview)

    accessToken: string;
    accessTokenTtl: number;

    /** Prevent favicon from being changed in browser tab */
    @Input()
    public preventFavIcon = false;

    @ViewChild("wopiForm")
    wopiForm: ElementRef;
    url: string;

    constructor(
        private renderer: Renderer2,
        private host: ElementRef,
        private wopiConfigurationService: WopiConfigurationService,
        private toastrService: ToastrService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.tab) {
            this.documentGuid = this.tab.config.documentGuid;
            this.action = this.tab.config.action;
        } else {
            this.documentGuid = this.route.snapshot.url[1].path;
            this.action = this.route.snapshot.url[2].path;
        }
    }

    async ngAfterViewInit(): Promise<void> {
        await this.setActionUrl();

        if (!this.url) {
            this.toastrService.error(`Failed opening WOPI.`);
            return;
        }

        if (this.tab) {
            this.appendIframeInTab();
        } else {
            this.appendIframeInWindow();
        }
    }

    private async setActionUrl(): Promise<boolean> {
        const wopiConfiguration = await this.wopiConfigurationService.getWopiInfo(
            this.documentGuid,
            this.action
        );

        const actionUrl = wopiConfiguration.actionUrl;

        if (!actionUrl) {
            this.toastrService.warning(`Document is not supported.`);
            return false;
        }

        this.accessToken = wopiConfiguration.accessToken;
        this.accessTokenTtl = wopiConfiguration.accessTokenTtl;

        this.url = actionUrl;
        this.setFavIcon(wopiConfiguration.favIconUrl);

        return true;
    }

    private appendIframeInWindow(): void {
        this.wopiForm.nativeElement.action = new URL(this.url).href;

        const iframeName = `office-iframe-${guid()}`;
        let iframe = this.createIframe(iframeName);

        const frameholder = document.getElementById("frameholder");
        frameholder.appendChild(iframe);

        this.wopiForm.nativeElement.target = iframeName;

        setTimeout(() => {
            this.wopiForm.nativeElement.submit();
        }, 1);
    }

    private appendIframeInTab(): void {
        this.wopiForm.nativeElement.action = new URL(this.url).href;

        const iframeName = `office-iframe-${guid()}`;
        let iframe = this.createIframe(iframeName);

        this.renderer.appendChild(this.host.nativeElement, iframe);

        this.wopiForm.nativeElement.target = iframeName;

        setTimeout(() => {
            this.wopiForm.nativeElement.submit();
        }, 1);
    }

    private createIframe(iframeName: string): HTMLIFrameElement {
        // Requires to be appended dynamicly otherwise form target doesn't work
        // and opens the editor in a seperate browser tab
        const iframe = document.createElement("iframe");
        iframe.className = "office_frame";
        iframe.name = iframeName;
        iframe.id = iframeName;
        iframe.allowFullscreen = true;
        iframe.style.display = "block";
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        iframe.setAttribute(
            "sandbox",
            "allow-downloads allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
        );

        return iframe;
    }

    private setFavIcon(favIconUrl: string): void {
        if (this.preventFavIcon) {
            return;
        }

        const faviconElement = document.getElementById("favicon");
        faviconElement.setAttribute("href", favIconUrl);
    }
}
