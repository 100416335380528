<label
    for="form-title"
    *ngIf="
        field.fieldType != fieldType.CheckBox &&
        field.fieldType != fieldType.Documents &&
        field.fieldType != fieldType.OrganizationRiskMatrixEditor &&
        !hideLabel
    "
>
    {{ field.formattedName }}
</label>

<!-- This is filler for checkboxes, so they align with other fieldtypes. -->
<label
    for="form-title"
    class="d-block"
    *ngIf="!hideFillers && !field.hideFillers && field.fieldType == fieldType.CheckBox"
    >&nbsp;</label
>

<div class="loading" *ngIf="additionalData && additionalData[field.fieldName + 'Loading']">
    <i class="fas fa-circle-notch fa-spin"></i>
</div>
<ng-container *ngIf="additionalData && !additionalData[field.fieldName + 'Loading']">
    <cerrix-checkbox
        [label]="field.formattedName"
        [(value)]="row[field.fieldName]"
        [disabled]="field.isReadonly || !editEnabled"
        *ngIf="field.fieldType == fieldType.CheckBox"
    ></cerrix-checkbox>

    <i
        class="fas fa-info-circle ms-2"
        *ngIf="field.description && field.fieldType != fieldType.Label"
        [matTooltip]="field.description"
        position="right"
    ></i>

    <label
        class="form-text normalWeight"
        *ngIf="field.description && field.fieldType == fieldType.Label"
    >
        {{ field.description }}
    </label>

    <cerrix-textfield
        [dataUiid]="field.uiid"
        [identifier]="field.fieldName"
        [(ngModel)]="row[field.fieldName]"
        *ngIf="
            field.fieldType == fieldType.Text ||
            field.fieldType == fieldType.Email ||
            field.fieldType == fieldType.Phone
        "
        [required]="field.required"
        [placeholder]="field.placeholder"
        [disabled]="field.isReadonly || !editEnabled"
        [multiline]="field.fieldType == fieldType.TextArea"
    >
    </cerrix-textfield>

    <cerrix-textarea
        [dataUiid]="field.uiid"
        [name]="field.fieldName"
        [(ngModel)]="row[field.fieldName]"
        *ngIf="field.fieldType == fieldType.TextArea"
        [required]="field.required"
        [placeholder]="field.placeholder"
        [disabled]="field.isReadonly || !editEnabled"
        [rows]="5"
    >
    </cerrix-textarea>

    <input
        type="number"
        [attr.data-uiid]="field.uiid"
        class="form-control form-control-sm"
        [name]="field.fieldName"
        [(ngModel)]="row[field.fieldName]"
        *ngIf="field.fieldType == fieldType.Number"
        [required]="field.required"
        [placeholder]="field.placeholder"
        [disabled]="field.isReadonly || !editEnabled"
        [min]="field.minimumValue"
        [max]="field.maximumValue"
        ng-pattern="/^[0-9]*$/"
    />

    <input
        type="text"
        [attr.data-uiid]="field.uiid"
        class="form-control form-control-sm"
        [name]="field.fieldName"
        [(ngModel)]="row[field.fieldName]"
        *ngIf="field.fieldType == fieldType.Decimal"
        [required]="field.required"
        [placeholder]="field.placeholder"
        [disabled]="field.isReadonly || !editEnabled"
        ng-pattern="/^[-]?[0-9]+([.][0-9]{1,4})?$/"
    />

    <date-input
        [showTimePicker]="field.fieldType == fieldType.DateTime"
        [(value)]="row[field.fieldName]"
        [attr.data-uiid]="field.uiid"
        *ngIf="field.fieldType == fieldType.Date || field.fieldType == fieldType.DateTime"
        [minDate]="field.minimumValue"
        [maxDate]="field.maximumValue"
        [disabled]="field.isReadonly || !editEnabled"
        [required]="field.required"
    >
    </date-input>

    <ng-select
        [name]="field.fieldName"
        [attr.data-uiid]="field.uiid"
        [multiple]="field.fieldType == fieldType.MultiSelect"
        [items]="additionalData[field.fieldName]"
        [(ngModel)]="row[field.fieldName]"
        (change)="updateSelect(field.fieldName, $event)"
        bindLabel="Name"
        bindValue="ID"
        [closeOnSelect]="field.fieldType == fieldType.SingleSelect"
        [disabled]="field.isReadonly || !editEnabled"
        [required]="field.required"
        [placeholder]="field.placeholder"
        [clearable]="!field.required"
        [appendTo]="field.appendTo"
        *ngIf="
            field.fieldType == fieldType.SingleSelect || field.fieldType == fieldType.MultiSelect
        "
    >
    </ng-select>

    <cerrix-select-tree
        [attr.data-uiid]="field.uiid"
        [multiselect]="field.fieldType == fieldType.MultiTree"
        [tree]="additionalData[field.fieldName]"
        [value]="row[field.fieldName]"
        [required]="field.required"
        [readonly]="field.isReadonly || !editEnabled"
        [placeholder]="field.placeholder"
        (onChangeIds)="updateTreeSelection(field.fieldName, $event)"
        (onChangeSingleId)="updateTreeSelection(field.fieldName, $event)"
        *ngIf="field.fieldType == fieldType.SingleTree || field.fieldType == fieldType.MultiTree"
    >
    </cerrix-select-tree>

    <ng-select
        [name]="field.fieldName"
        [attr.data-uiid]="field.uiid"
        [items]="additionalData[field.fieldName]"
        [disabled]="field.isReadonly || !editEnabled"
        [required]="field.required"
        [placeholder]="field.placeholder"
        [(ngModel)]="row[field.fieldName]"
        (change)="updateSelect(field.fieldName, $event)"
        bindLabel="Name"
        bindValue="ID"
        [appendTo]="field.appendTo"
        *ngIf="field.fieldType == fieldType.ColoredSelect"
    >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <div
                [style.background-color]="item.Color"
                style="min-width: 5px; min-height: 16px; float: left; margin-right: 10px"
            ></div>
            {{ item.Name }}
        </ng-template>
    </ng-select>

    <document-manager-old
        [Documents]="row[field.fieldName]"
        [attr.data-uiid]="field.uiid"
        [Label]="field.formattedName"
        [noMargin]="true"
        [AllowAdd]="!field.isReadonly || editEnabled"
        [AllowEdit]="!field.isReadonly || editEnabled"
        [AllowDelete]="!field.isReadonly || editEnabled"
        *ngIf="field.fieldType == fieldType.Documents"
    ></document-manager-old>

    <ng-select
        [name]="field.fieldName"
        [attr.data-uiid]="field.uiid"
        [items]="additionalData[field.fieldName]"
        [disabled]="field.isReadonly || !editEnabled"
        [multiple]="true"
        [(ngModel)]="row[field.fieldName]"
        [required]="field.required"
        [placeholder]="field.placeholder"
        (change)="updateSelect(field.fieldName, $event)"
        [loading]="additionalData[field.fieldName + 'Loading']"
        *ngIf="field.fieldType == fieldType.StringArray"
        [addTag]="true"
        [closeOnSelect]="false"
        [appendTo]="field.appendTo"
    >
    </ng-select>

    <cerrix-color-select
        *ngIf="field.fieldType == fieldType.ColorPicker"
        [dataUiid]="field.uiid"
        [disabled]="field.isReadonly || !editEnabled"
        [required]="field.required"
        [(value)]="row[field.fieldName]"
    ></cerrix-color-select>

    <cerrix-icon-picker
        *ngIf="field.fieldType == fieldType.IconPicker"
        [attr.data-uiid]="field.uiid"
        [disabled]="field.isReadonly || !editEnabled"
        [(value)]="row[field.fieldName]"
    ></cerrix-icon-picker>

    <sub-list-manager
        *ngIf="field.fieldType == fieldType.GenericList"
        [attr.data-uiid]="field.uiid"
        [disabled]="field.isReadonly || !editEnabled"
        [(value)]="row[field.fieldName]"
        [listConfig]="field.additionalConfig"
    ></sub-list-manager>

    <pre class="prebox" *ngIf="field.fieldType == fieldType.PreBox">{{ row[field.fieldName] }}</pre>
    <div
        class="renderbox"
        *ngIf="field.fieldType == fieldType.RenderBox"
        [innerHTML]="row[field.fieldName]"
    ></div>

    <!-- Custom Field types -->
    <organization-riskmatrix-editor
        *ngIf="field.fieldType == fieldType.OrganizationRiskMatrixEditor"
        [attr.data-uiid]="field.uiid"
        [disabled]="field.isReadonly || !editEnabled"
        [(value)]="row[field.fieldName]"
        [row]="row"
    ></organization-riskmatrix-editor>

    <risk-appetite-selection
        *ngIf="field.fieldType == fieldType.MultiSelectRiskMatrix"
        [attr.data-uiid]="field.uiid"
        (valueChange)="updateSelect(field.fieldName, $event)"
        [disabled]="field.isReadonly || !editEnabled"
        [(value)]="row[field.fieldName]"
    >
    </risk-appetite-selection>

    <framework-assessment-scores
        *ngIf="field.fieldType == fieldType.FrameworkAssessmentScores"
        [attr.data-uiid]="field.uiid"
        [disabled]="field.isReadonly || !editEnabled"
        [(value)]="row[field.fieldName]"
        [row]="row"
    ></framework-assessment-scores>

    <custom-field-editor
        *ngIf="field.fieldType == fieldType.CustomFields"
        [attr.data-uiid]="field.uiid"
        [disabled]="field.isReadonly || !editEnabled"
        [(value)]="row"
        [field]="field"
    ></custom-field-editor>

    <cerrix-editor-popup
        *ngIf="field.fieldType == fieldType.WYSIWYG"
        [(value)]="row[field.fieldName]"
    >
    </cerrix-editor-popup>
</ng-container>
