import { Component, OnInit } from "@angular/core";
import { AboutService } from "./services/about.service";
import { AboutModel } from "./models/AboutModel";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    public tab: TabModel;

    public about: AboutModel;

    public currentDate = new Date();
    public halloweenLogo = false;

    public constructor(private aboutService: AboutService) {}

    public async ngOnInit() {
        this.tab.showLoader = true;

        try {
            this.about = await firstValueFrom(this.aboutService.getAbout());
        } finally {
            this.tab.showLoader = false;
        }

        if (this.currentDate.getDate() === 31 && this.currentDate.getMonth() === 9) {
            this.halloweenLogo = true;
        }
    }
}
