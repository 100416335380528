<div class="d-flex flex-column">
    <div class="d-flex flex-row">
        <div
            *ngFor="let step of steps; let i = index"
            class="d-flex flex-row align-items-center step"
            [ngClass]="getStepStyle(step)"
        >
            <div class="step-icon-wrapper" [attr.data-uiid]="'new-eff-workflow-step-' + step.Name">
                <eff-popover
                    *ngIf="step.Id != effectivenessPageType.Completed"
                    [popoverContentTemplate]="popoverContent"
                    [popoverButtonTemplate]="popoverButton"
                    [panelClass]="'eff-workflow-step-popover'"
                >
                    <ng-template #popoverContent>
                        <eff-workflow-popover [step]="step"></eff-workflow-popover>
                    </ng-template>

                    <ng-template #popoverButton>
                        <mat-icon>lens</mat-icon>
                    </ng-template>
                </eff-popover>
                <mat-icon *ngIf="step.Id == effectivenessPageType.Completed">lens</mat-icon>
                <div class="step-title" data-uiid="new-eff-workflow-step-title">
                    {{ step.Name }}
                </div>
            </div>
            <div class="step-separator"></div>
        </div>
    </div>
</div>
