<ng-container *ngIf="videoSettings">
    <div class="row">
        <div class="mb-3">
            <label for="video-{{ field.ID }}" class="form-label">Video URL</label>
            <input
                type="text"
                class="form-control"
                id="video-{{ field.ID }}"
                name="video-{{ field.ID }}"
                placeholder="youtube.com/watch?v=dQw4w9WgXcQ"
                [(ngModel)]="videoSettings.videoUrl"
                (change)="saveModel()"
            />
        </div>
    </div>

    <ng-container *ngIf="videoSettings.videoUrl">
        <hr />
        <div class="row">
            <div class="col">
                <div class="example-video-container">
                    <label>Preview</label>
                    <div class="example-video-player">
                        <cerrix-video-player [url]="videoSettings.videoUrl"></cerrix-video-player>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
