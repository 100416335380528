import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Observable } from "rxjs";
import { GenericListButton } from "./GenericList/GenericManagerConfig";

export class CerrixGridEditorConfig<T = any> {
    /** Data that is edited. Editing is reference based. */
    data: any[];

    columns: CerrixGridEditorColumnConfig[];

    idProperty: string;
    childProperty?: string;

    /** Action buttons per row. */
    rowButtons?: GenericListButton[];
    rowButtonsFirst?: boolean;

    maxRowHeight?: number;

    allowReordering?: boolean;
    allowResizing?: boolean;

    onRowChanged?(changedRow: T): void;
}

export class CerrixGridColumnConfig {
    fieldType: GenericListFieldType;

    fieldName: string;
    prettyName?: string;

    columnWidth?: number;

    hidden?: boolean;

    pinLeft?: boolean;
    pinRight?: boolean;
}

export class CerrixGridEditorColumnConfig extends CerrixGridColumnConfig {
    isReadonly?: boolean;

    /** Data that is for columns that need it, such as the selectable options in the Select fieldtypes. */
    fieldData?: any;

    /** Data that is used for the support rows of editColumns */
    supportData?: any;

    getDataMethod?(): Observable<any>;
}
