<div *ngIf="!readonly">
    <div class="radio-options" *ngIf="field.FieldType === types.Radio">
        <div
            class="form-check"
            *ngFor="let option of optionSettings.options; let optionIndex = index"
        >
            <input
                type="radio"
                class="form-check-input"
                id="radio-{{ field.ID }}-{{ optionIndex }}"
                name="radio-{{ field.ID }}"
                (click)="updateRadio(option)"
                [checked]="this.singleSelected === option"
            />
            <label class="form-check-label" for="radio-{{ field.ID }}-{{ optionIndex }}">{{
                option
            }}</label>
        </div>
    </div>

    <div class="checkbox-options" *ngIf="field.FieldType === types.Checkbox">
        <div
            class="form-check"
            *ngFor="let option of optionSettings.options; let optionIndex = index"
        >
            <input
                type="checkbox"
                class="form-check-input"
                id="checkbox-{{ field.ID }}-{{ optionIndex }}"
                (click)="updateCheckbox(option)"
                [checked]="this.multiSelected.indexOf(option) >= 0"
            />
            <label class="form-check-label" for="checkbox-{{ field.ID }}-{{ optionIndex }}">{{
                option
            }}</label>
        </div>
    </div>

    <div class="dropdown-options" *ngIf="field.FieldType === types.Dropdown">
        <ng-select
            id="dropdown-{{ field.ID }}"
            name="subjectSelect"
            [ngModel]="singleSelected || multiSelected" (ngModelChange)="singleSelected || (multiSelected = $event)"
            [items]="optionSettings.options"
            (change)="updateDropdown($event)"
            [multiple]="optionSettings.multiSelect"
            [closeOnSelect]="
                !optionSettings.multiSelect ||
                (optionSettings.multiSelect &&
                    (optionSettings.maximumSelection > 0
                        ? optionSettings.maximumSelection
                        : optionSettings.options.length) <=
                        multiSelected.length + 1)
            "
            [maxSelectedItems]="
                optionSettings.maximumSelection > 0
                    ? optionSettings.maximumSelection
                    : optionSettings.options.length
            "
        >
        </ng-select>
    </div>
</div>

<div *ngIf="readonly">
    {{ answer ? answer : "No selection." }}
</div>
