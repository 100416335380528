import { NgModule, ModuleWithProviders } from "@angular/core";

import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { TpaConnectionDataService } from "./services/tpa-connection.data.service";
import { ExternalConnectionOverviewComponent } from "./external-connection-overview.component";
import { TpaConnectionOverviewComponent } from "./third-party-assurance/overview/tpa-overview.component";
import { TpaRequestOverviewComponent } from "./third-party-assurance/request/tpa-request-overview.component";
import { TpaRequestDetailComponent } from "./third-party-assurance/detail/tpa-request-detail.component";
import { TpaConnectionWizardComponent } from "./third-party-assurance/connection-wizard/connection-wizard.component";
import { TpaControlLinkableComponent } from "./third-party-assurance/connection-wizard/tpa-control-linkable/tpa-control-linkable.component";

@NgModule({
    declarations: [
        ExternalConnectionOverviewComponent,
        TpaConnectionOverviewComponent,
        TpaRequestOverviewComponent,
        TpaRequestDetailComponent,
        TpaConnectionWizardComponent,
        TpaControlLinkableComponent,
    ],
    imports: [CommonModule, SharedModule],
})
export class ExternalConnectionModule {
    static forRoot(): ModuleWithProviders<ExternalConnectionModule> {
        return {
            ngModule: ExternalConnectionModule,
            providers: [TpaConnectionDataService],
        };
    }
}
