<div class="page-area cerrix-column">
    <div class="title-bar">
        <div class="title">Workflow History</div>
    </div>
    <div class="button-bar" *ngIf="ready">
        <button class="cerrix-button primary" (click)="toggleView()">
            <span>View as {{ showAsDiagram ? "table" : "diagram" }}</span
            ><i class="fal fa-{{ showAsDiagram ? 'table' : 'project-diagram' }}"></i>
        </button>
        <button class="cerrix-button secondary" (click)="exportDiagram()" *ngIf="showAsDiagram">
            <span>Export diagram</span><i class="fal fa-file-download"></i>
        </button>
        <ng-container *ngIf="!showAsDiagram">
            <button
                class="cerrix-button secondary"
                (click)="showTableReversed = !showTableReversed"
            >
                <span>Reverse order</span><i class="fal fa-sort-alt"></i>
            </button>
            <button class="cerrix-button secondary" (click)="exportTable(tableContainer)">
                <span>Export table</span><i class="fal fa-file-download"></i>
            </button>
        </ng-container>
    </div>
</div>

<!-- Because we need a reference to diagramContainer we only hide this element. -->
<div class="cerrix-workflow-history-graph" [hidden]="!showAsDiagram">
    <div class="centering-flex-blox">
        <div #diagramContainer></div>
    </div>
</div>

<div class="cerrix-workflow-history-table" #tableContainer *ngIf="!showAsDiagram">
    <div class="cerrix-row header">
        <div>Status</div>
        <div>Action</div>
        <div>On</div>
        <div>By</div>
        <ng-container [ngTemplateOutlet]="extraTableHeaders"></ng-container>
        <div>Comment</div>
    </div>

    <div class="cerrix-column">
        <div class="cerrix-row" *ngFor="let row of workflowHistory | reverse : showTableReversed">
            <div [title]="row.statusDescription">{{ row.statusDescription }}</div>
            <div [title]="row.actionDescription">{{ row.actionDescription }}</div>
            <div [title]="row.actionOn | dateTimeFormat">
                {{ row.actionOn | dateTimeFormat }}
            </div>
            <div [title]="row.actionBy">{{ row.actionBy }}</div>
            <ng-container
                [ngTemplateOutlet]="extraTableValues"
                [ngTemplateOutletContext]="{ row: row }"
            ></ng-container>
            <div [title]="row.comment">{{ row.comment }}</div>
        </div>
    </div>
</div>
