import {
    Directive,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    NgZone,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { DocumentMethods } from "@methods/DocumentMethods";

@Directive({
    selector: "[fileDrop]",
})
export class FileDropDirective implements AfterViewInit {
    @Input() allowedExtensions: string;
    @Input() allowDrop: boolean;
    @Input() fullScreenDropArea: boolean = true;
    @Output() fileDrop = new EventEmitter<File[]>();

    fileDropArea: HTMLElement;

    constructor(public el: ElementRef, private ngZone: NgZone, private _toastr: ToastrService) {}

    ngAfterViewInit() {
        if (this.fileDrop.observers.length === 0) {
            // No observers, prevent the browser window from opening the files on drop
            this.overrideDefaults();
        } else if (this.allowDrop) {
            this.initDropArea();
        }
    }

    initDropArea() {
        let showDrag = false,
            timeout;

        this.fileDropArea = this.el.nativeElement;
        this.fileDropArea.classList.add("file-drop-area");

        this.ngZone.runOutsideAngular(() => {
            this.fileDropArea.addEventListener("dragenter", (e) => {
                e.dataTransfer.dropEffect = "copy";
                this.onDragEnter();
                showDrag = true;
            });

            this.fileDropArea.addEventListener("dragover", (e) => {
                e.dataTransfer.dropEffect = "copy";
                showDrag = true;
            });

            this.fileDropArea.addEventListener("dragleave", (e) => {
                showDrag = false;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    if (!showDrag) {
                        this.onDragLeave();
                    }
                }, 50);
            });
        });

        this.fileDropArea.addEventListener(
            "drop",
            (e) => {
                this.ngZone.run(() => {
                    e.dataTransfer.dropEffect = "copy";
                    this.onDrop(e);
                });
            },
            false
        );

        this.createDropZone();
    }

    onDragEnter() {
        this.fileDropArea.classList.add("highlight");
    }

    onDragLeave() {
        this.fileDropArea.classList.remove("highlight");
    }

    onDrop(e: DragEvent) {
        this.preventDefault(e);

        const dt = e.dataTransfer;
        const files = dt.files as FileList;

        this.onDragLeave();

        if (this.fileDrop && files.length > 0) {
            dt.dropEffect = "copy";
            const allowedFiles = DocumentMethods.filterAllowedFiles(
                this._toastr,
                files,
                this.allowedExtensions
            );

            if (allowedFiles.length > 0) {
                this.fileDrop.emit(allowedFiles);
            }
        }
    }

    createDropZone(): any {
        const dropAreaElement = document.createElement("div");
        dropAreaElement.classList.add("drop-area-indicator");

        const dropMessage = document.createElement("h4");
        if (this.fullScreenDropArea) {
            dropAreaElement.classList.add("fullscreen");
            dropMessage.classList.add("fullscreen");
            dropMessage.innerHTML = "Incoming!";
        }

        dropAreaElement.appendChild(dropMessage);
        this.fileDropArea.appendChild(dropAreaElement);
    }

    // Prevent the browser from opening files when a file is dropped
    // run outside of angular, since every pixel a file is dragged fires changedetection
    // https://stackoverflow.com/a/41051625
    overrideDefaults() {
        this.ngZone.runOutsideAngular(() => {
            ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
                window.addEventListener(
                    eventName,
                    (e) => {
                        this.preventDefault(e);
                    },
                    false
                );
            });
        });
    }

    preventDefault(ev) {
        ev.preventDefault();
        ev.stopPropagation();
    }
}
