<div class="modal-header">
    <h5 class="modal-title">{{ title ? title : "Comment for this recommendation" }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
    <ul class="nav nav-tabs">
        <li class="nav-item active">
            <a class="nav-link active" href="#commentDetail" data-toggle="tab">Comment</a>
        </li>
    </ul>

    <div class="tab-content">
        <div class="tab-pane active" id="commentDetail">
            <form novalidate [formGroup]="commentForm">
                <div class="form-group row">
                    <label for="commentInput" class="col-3 col-form-label">Comment</label>
                    <div class="col-9">
                        <cerrix-textarea
                            errorLabel="Comment"
                            identifier="commentInput"
                            [rows]="10"
                            formControlName="comment"
                            placeholder="Required"
                        ></cerrix-textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        (click)="saveClick()"
        [disabled]="!commentForm.valid"
    >
        Save
    </button>
    <button type="button" class="btn btn-secondary" (click)="closeClick()">Close</button>
</div>
