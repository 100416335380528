import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "bim-get-moi-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class BimGetMoiDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get MoI Data API",
            httpMethod: "GET",
            path: "/moidata/moi",

            parameters: [
                {
                    name: "includeDeleted",
                    in: "query",
                    paramType: "boolean",
                    required: false,
                    example: "true | false",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        "moIs, finding reports and assessments": [
                            {
                                id: "integer",
                                is_Overdue: "string",
                                moi_Source: "string",
                                moi_Name: "string",
                                subject: "string",
                                status: "string",
                                date_Status_Changed: "datetime",
                                priority: "string",
                                feasibility: "string",
                                progress: "integer",
                                organization_Responsible: "string",
                                organization_Responsible_Path: "string",
                                responsible: "string",
                                "delegate / Operator": "string",
                                "moI Auditor/Assessor": "string",
                                finding_Description: "string",
                                start_Date: "datetime",
                                date_Created: "datetime",
                                due_Date: "datetime",
                                initial_Due_Date: "datetime",
                                implementation_Score: "string",
                                recommendation: "string",
                                management_Response: "string",
                                last_Comment_Auditor: "string",
                                comment_Date_Auditor: "datetime",
                                comment_Name_Auditor: "string",
                                last_Comment_Responsible_Or_Delegate: "string",
                                comment_Date_Responsible_Or_Delegate: "datetime",
                                comment_Name_Responsible_Or_Delegate: "string",
                                report_No: "string",
                                report_Type: "string",
                                report_Name: "string",
                                report_Date: "datetime",
                                report_Due_Date: "datetime",
                                overall_Score: "string",
                                conclusion: "string",
                                assessment_Type: "string",
                                assessment_Date: "datetime",
                                assessment_Score: "string",
                                comments: "string",
                                business_Dimension_Paths: "string",
                                business_Dimensions: "string",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
