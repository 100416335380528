import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import {
    GenericStandingDataConfig,
    StandingDataOverviewType,
} from "@app/shared/models/GenericList/GenericStandingDataConfig";
import { ControlsUserType } from "@app/standingdata/shared/enums/ControlsUserType";
import { CerrixRight } from "@enums/authorization/CerrixRight";
import { TargetModule } from "@enums/document/TargetModule";
import { StandingDataType } from "@enums/StandingDataType";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { ModuleType } from "../enums/ModuleType";
import { AppConstants } from "./../../app/app.constants";
import { StandingdataDataService } from "./../../app/standingdata/shared/standingdata.service";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { toPromise } from "@methods/CommonMethods";

@Injectable()
export class StandingDataMapping {
    // Do not set maxlength unless it differentiates from the standard text- / textarea length

    //#region General

    // Organization
    1: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        categories: [
            {
                categoryName: "Details",
                editorWidth: 6,
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        fieldName: "Comments",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Business Dimension",
                        fieldName: "BusinessDimensions",
                        fieldType: GenericListFieldType.MultiTree,
                        editorWidth: 6,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) => {
                            return ds.getMaintenanceListByType(StandingDataType.BusinessDimensions);
                        },
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.BusinessDimensions),
                    },
                    {
                        prettyName: "External Identifier",
                        fieldName: "ExternalID",
                        description:
                            "Identifier provided through user provisioning (SCIM) to identify organization.",
                        fieldType: GenericListFieldType.Text,
                        editorWidth: 6,
                    },
                ],
            },
            {
                categoryName: "Risk Matrix",
                editorWidth: 6,
                fields: [
                    {
                        prettyName: "",
                        fieldName: "Matrix",
                        fieldType: GenericListFieldType.OrganizationRiskMatrixEditor,
                        excludeInExport: true,
                    },
                ],
            },
        ],
        onAdd: (parent: any) => {
            const parentMatrix = parent ? parent["Matrix"] : null;
            return {
                UseParentOrDefaultMatrix: true,
                Matrix: parentMatrix,
                ParentMatrix: parentMatrix,
            };
        },
    };

    // BusinessDimensions
    5: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        getAdditionalDeleteMessage: (row: any) =>
            row.HasProcessDiagrams
                ? "Please note: Possible linked processes are also deleted when deleting this business dimension"
                : null,
        categories: [
            {
                categoryName: "Details",
                editorWidth: 6,
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },

                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        fieldName: "Comments",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Organizations",
                        fieldName: "Organizations",
                        description: Configuration.NoOrganizationsSelectedDescription,
                        fieldType: GenericListFieldType.MultiTree,
                        defaultValue: [],
                        isReadonly: true,
                        editorWidth: 12,
                        getDataMethodByRow: (row, ds) => {
                            return ds.getMaintenanceListByType(StandingDataType.Organization);
                        },
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.Organization),
                    },
                    {
                        prettyName: "Framework Dimensions",
                        fieldName: "FrameworkDimensions",
                        fieldType: GenericListFieldType.MultiTree,
                        defaultValue: [],
                        editorWidth: 12,
                        getDataMethodByRow: (row, ds) => {
                            return ds.getMaintenanceListByType(
                                StandingDataType.FrameworkDimensions
                            );
                        },
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.FrameworkDimensions),
                    },
                ],
            },
        ],
    };

    // DocumentTypes
    10: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        prettyName: "Module",
                        fieldName: "TargetId",
                        defaultValue: +TargetModule.All,
                        fieldType: GenericListFieldType.SingleSelect,
                        required: true,
                        getDataMethodByRow: (row, ds) => ds.getDocumentTypeTargets(),
                    },
                ],
            },
        ],
    };

    // HyperlinkTypes
    11: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        prettyName: "Module",
                        fieldName: "TargetId",
                        defaultValue: +TargetModule.All,
                        fieldType: GenericListFieldType.SingleSelect,
                        required: true,
                        getDataMethodByRow: (row, ds) => ds.getDocumentTypeTargets(),
                    },
                ],
            },
        ],
    };

    // FrameworkDimensions
    15: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        categories: [
            {
                categoryName: "Details",
                editorWidth: 6,
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },

                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        fieldName: "Comments",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Business Dimension",
                        fieldName: "BusinessDimensions",
                        fieldType: GenericListFieldType.MultiTree,
                        defaultValue: [],
                        isReadonly: true,
                        editorWidth: 12,
                        getDataMethodByRow: (row, ds) => {
                            return ds.getMaintenanceListByType(StandingDataType.BusinessDimensions);
                        },
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.BusinessDimensions),
                    },
                ],
            },
            // https://dev.azure.com/cerrix/Cerrix/_workitems/edit/8499
            // {
            //     categoryName: "Assessment Scores",
            //     editorWidth: 6,
            //     fields: [
            //         {
            //             fieldName: "",
            //             fieldType: GenericListFieldType.FrameworkAssessmentScores,
            //             excludeInExport: true,
            //         },
            //     ],
            // },
        ],
        onAdd: (parent: any) => {
            const parentScores = parent ? parent["Scores"] : null;
            return {
                UseParentOrDefaultScores: true,
                Scores: parentScores,
                ParentScores: parentScores,
            };
        },
    };

    // IpWhitelistingGlobal
    50: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        prettyName: "IP Address",
                        fieldName: "IP",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                    },
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        prettyName: "Expiration Date",
                        fieldName: "ExpirationDate",
                        fieldType: GenericListFieldType.DateTime,
                    },
                ],
            },
        ],
    };

    // IpWhitelistingPersonal
    55: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        prettyName: "IP Address",
                        fieldName: "IP",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                    },
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        prettyName: "Expiration Date",
                        fieldName: "ExpirationDate",
                        fieldType: GenericListFieldType.DateTime,
                    },
                ],
            },
        ],
    };

    //#endregion

    //#region Risk

    // RiskArea
    100: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // RiskCatalogue
    105: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Catalogue details",
                fields: [
                    {
                        showInline: true,
                        editorWidth: 6,
                        fields: [
                            {
                                prettyName: "Catalogue item name",
                                fieldName: "Name",
                                fieldType: GenericListFieldType.Text,
                                required: true,
                                unique: true,
                                overviewSortOrder: 1,
                            },
                            {
                                prettyName: "Applicable organizations",
                                fieldName: "applicableOrganizationIds",
                                description: Configuration.NoOrganizationsSelectedDescription,
                                fieldType: GenericListFieldType.MultiTree,
                                getDataMethodByRow: (row, ds) =>
                                    ds.getOrganizationsIncludingSelected(
                                        ModuleType.ORM,
                                        row["applicableOrganizationIds"]
                                    ),
                                getExportDataMethod: (ds) =>
                                    ds.getExportByType(StandingDataType.Organization),
                                hideInOverview: true,
                                required: false,
                            },
                        ],
                    },
                    {
                        prettyName: "Definition",
                        fieldName: "definitionRiskCatalogue",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                        overviewSortOrder: 2,
                    },
                ],
            },
            {
                categoryName: "Risk properties",
                fields: [
                    {
                        showInline: true,
                        fields: [
                            {
                                prettyName: "Risk name",
                                fieldName: "riskname",
                                fieldType: GenericListFieldType.Text,
                                overviewSortOrder: 3,
                                editorWidth: 6,
                            },
                            {
                                prettyName: "Default / Mandatory",
                                fieldName: "overrideRiskName",
                                fieldType: GenericListFieldType.SingleSelect,

                                getDataMethodByRow: (row, ds) => ds.getRiskOverrideTypes(),
                                editorWidth: 6,
                                defaultValue: 1,
                            },
                        ],
                    },
                    {
                        showInline: true,
                        fields: [
                            {
                                prettyName: "Risk description",
                                fieldName: "Description",
                                fieldType: GenericListFieldType.TextArea,
                                overviewSortOrder: 4,
                                editorWidth: 6,
                            },
                            {
                                prettyName: "Default / Mandatory",
                                fieldName: "overrideRiskDescription",
                                fieldType: GenericListFieldType.SingleSelect,

                                getDataMethodByRow: (row, ds) => ds.getRiskOverrideTypes(),
                                editorWidth: 6,
                                defaultValue: 1,
                            },
                        ],
                    },
                    {
                        showInline: true,
                        fields: [
                            {
                                prettyName: "Risk area",
                                fieldName: "riskAreaId",
                                fieldType: GenericListFieldType.SingleSelect,
                                getDataMethodByRow: (row, ds) =>
                                    ds.getAllByType(StandingDataType.RiskArea, row["riskAreaId"]),
                                hideInOverview: true,
                                required: true,
                                editorWidth: 6,
                                defaultValue: null,
                            },
                            {
                                prettyName: "Default / Mandatory",
                                fieldName: "overrideRiskArea",
                                fieldType: GenericListFieldType.SingleSelect,

                                getDataMethodByRow: (row, ds) => ds.getRiskOverrideTypes(),
                                editorWidth: 6,
                                defaultValue: 1,
                            },
                        ],
                    },
                    {
                        showInline: true,
                        fields: [
                            {
                                prettyName: "Event category",
                                fieldName: "eventCategoryId",
                                fieldType: GenericListFieldType.SingleTree,
                                getDataMethodByRow: (row, ds) => ds.getEventCategory(),
                                hideInOverview: true,
                                required: true,
                                editorWidth: 6,

                                getExportDataMethod: (ds) =>
                                    ds.getExportByType(StandingDataType.LerEventCategory),
                            },
                            {
                                prettyName: "Default / Mandatory",
                                fieldName: "overrideEventCategory",
                                fieldType: GenericListFieldType.SingleSelect,

                                getDataMethodByRow: (row, ds) => ds.getRiskOverrideTypes(),
                                editorWidth: 6,
                                defaultValue: 1,
                            },
                        ],
                    },
                    {
                        showInline: true,
                        fields: [
                            {
                                prettyName: "Available subtypes",
                                fieldName: "subTypeIds",
                                fieldType: GenericListFieldType.MultiSelect,
                                getDataMethodByRow: (row, ds) =>
                                    ds.getAllByType(
                                        StandingDataType.RiskSubtype,
                                        row["subTypeIds"]
                                    ),
                                hideInOverview: true,
                                editorWidth: 6,
                            },
                            {
                                showInline: true,
                                editorWidth: 6,
                                fields: [
                                    {
                                        prettyName: "Default / Mandatory",
                                        fieldName: "overrideSubTypes",
                                        fieldType: GenericListFieldType.SingleSelect,
                                        getDataMethodByRow: (row, ds) => ds.getRiskOverrideTypes(),
                                        defaultValue: 1,
                                    },
                                    {
                                        prettyName: "None",
                                        fieldName: "noneLabel",
                                        fieldType: GenericListFieldType.Label,
                                        hideInOverview: true,
                                        excludeInExport: true,
                                        description:
                                            "When no selection of default or mandatory is given, this field will not be changed when changing the risk catalogue.",
                                    },
                                    {
                                        prettyName: "Default",
                                        fieldName: "defaultLabel",
                                        fieldType: GenericListFieldType.Label,
                                        hideInOverview: true,
                                        excludeInExport: true,
                                        description:
                                            "When this checkbox is picked, this field will change automatically when the risk catalogue is changed within a risk.",
                                    },
                                    {
                                        prettyName: "Mandatory",
                                        fieldName: "mandatoryLabel",
                                        fieldType: GenericListFieldType.Label,
                                        hideInOverview: true,
                                        excludeInExport: true,
                                        description:
                                            "When this checkbox is picked, this field can not be changed by risk admins and writers within risk detail.",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                categoryName: "Risk appetite",
                fields: [
                    {
                        prettyName: "Risk appetite for catalogue item",
                        fieldName: "riskAppetiteSelection",
                        description:
                            "Please select in the risk heatmap on the left outer border of likelihood and impact scores that are within risk appetite. The border must consist of connected (horizontal, vertical or diagonal) boxes with the first and last boxes being on outer edges of the heatmap. This means that everything that is not selected on the right side of the border will be outside the risk appetite.",
                        fieldType: GenericListFieldType.MultiSelectRiskMatrix,
                        hideInOverview: true,
                        editorWidth: 6,
                        excludeInExport: true,
                    },
                ],
            },
        ],
    };

    // RiskAssessment
    110: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                        required: true,
                    },
                    {
                        prettyName: "Value",
                        fieldName: "Weight",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                    },
                ],
            },
        ],
    };

    // RiskSubtype
    115: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // RiskCustomFields
    120: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
        ],
    };

    // RiskTreatment
    125: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // RiskLikelihood
    130: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        disableAdd: true,
        disableDelete: true,
        disableSort: true,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Text",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                    },
                    {
                        prettyName: "Min %",
                        fieldName: "MinValue",
                        fieldType: GenericListFieldType.Number,
                        required: false,
                        editorWidth: 4,
                    },
                    {
                        prettyName: "Max %",
                        fieldName: "MaxValue",
                        fieldType: GenericListFieldType.Number,
                        required: false,
                        editorWidth: 4,
                    },
                    {
                        prettyName: "Default Value %",
                        fieldName: "DefaultValue",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                        editorWidth: 4,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        required: true,
                    },
                ],
            },
        ],
        getBeforeSavePromptMessage: async (
            row: any,
            originalRow: any,
            settingsService: SettingsDataService
        ) => {
            const enabled = await toPromise(
                settingsService.getSetting(ApplicationSettings.EnableBudgetBasedRisk)
            );

            if (!enabled.BoolValue) {
                return "";
            }

            return "Updating any likelihood standing data will force all risk's financial impacts to be recalculated. \n \n Are you sure you want to continue?";
        },
    };

    // RiskImpact
    135: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        disableAdd: true,
        disableDelete: true,
        disableSort: true,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Text",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                    },
                    {
                        prettyName: "Min",
                        fieldName: "MinValue",
                        fieldType: GenericListFieldType.Number,
                        required: false,
                        editorWidth: 4,
                    },
                    {
                        prettyName: "Max",
                        fieldName: "MaxValue",
                        fieldType: GenericListFieldType.Number,
                        required: false,
                        editorWidth: 4,
                    },
                    {
                        prettyName: "Default Value",
                        fieldName: "DefaultValue",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                        editorWidth: 4,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        required: true,
                    },
                ],
            },
        ],
    };

    // RiskImpactScales
    140: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        categoryName: "",
                        showInline: true,
                        editorWidth: 6,
                        fields: [
                            {
                                fieldName: "Name",
                                fieldType: GenericListFieldType.Text,
                                required: true,
                                unique: true,
                            },
                        ],
                    },
                    {
                        prettyName: "Items",
                        fieldName: "scaleItems",
                        fieldType: GenericListFieldType.GenericList,
                        additionalConfig: <GenericListConfig>{
                            name: "",
                            isSortable: false,
                            allowAdd: false,
                            allowDelete: false,
                            allowEdit: true,
                            fields: [
                                {
                                    fieldName: "Name",
                                    fieldType: GenericListFieldType.Text,
                                    unique: true,
                                },
                                {
                                    prettyName: "Value",
                                    fieldName: "Weight",
                                    fieldType: GenericListFieldType.Number,
                                    required: true,
                                    minimumValue: 1,
                                },
                            ],
                        },
                        defaultValue: [
                            <IdNameCombination>{ ID: 1, Name: "1" },
                            <IdNameCombination>{ ID: 2, Name: "2" },
                            <IdNameCombination>{ ID: 3, Name: "3" },
                            <IdNameCombination>{ ID: 4, Name: "4" },
                            <IdNameCombination>{ ID: 5, Name: "5" },
                        ],
                    },
                ],
            },
        ],
    };

    // RiskFinancialImpact
    145: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        disableAdd: true,
        disableDelete: true,
        disableSort: true,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Text",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                    },
                    {
                        prettyName: "Min %",
                        fieldName: "MinValue",
                        fieldType: GenericListFieldType.Decimal,
                        required: false,
                        editorWidth: 4,
                    },
                    {
                        prettyName: "Max %",
                        fieldName: "MaxValue",
                        fieldType: GenericListFieldType.Decimal,
                        required: false,
                        editorWidth: 4,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        required: true,
                    },
                ],
            },
        ],
        getBeforeSavePromptMessage: async (
            row: any,
            originalRow: any,
            settingsService: SettingsDataService
        ) => {
            return "Updating any financial impact standing data will force all risk's financial impacts to be recalculated. \n \n Are you sure you want to continue?";
        },
    };

    //#endregion

    //#region Events

    // LerReasonsNoNotification
    300: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerActiveInWhichSector
    305: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerNatureOfBreach
    310: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerPersonsInvolved
    315: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerConsequencesToCiaOfData
    320: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerNatureOfIncident
    325: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerConsequenceOfBreach
    330: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerPersonalDataTypes
    335: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerTypesOfBreach
    340: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerClassificationIncident
    345: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                        required: true,
                    },
                    {
                        prettyName: "Value",
                        fieldName: "Weight",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                    },
                ],
            },
        ],
    };

    // LerBusinessLine
    350: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Abbreviation",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                    },
                ],
            },
        ],
    };

    // LerFailureType
    355: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.Text,
                    },
                ],
            },
        ],
    };

    // LerEffect
    360: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerIncidentType
    365: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerReputationImpact
    370: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.Text,
                    },
                ],
            },
        ],
    };

    // LerEventCategory
    375: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },
                ],
            },
        ],
    };

    // LerSpecialCategoriesOfPersonalData
    380: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // LerCustomFields
    385: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
        ],
    };

    // LerDataBreachCustomFields
    386: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
        ],
    };

    //#endregion

    //#region Control

    // ControlSource
    400: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // ControlType
    405: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // ControlAspectIc
    410: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlFunction
    415: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlExecution
    420: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlLinkedStandards
    425: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlQualityAspects
    430: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlCatalogue
    435: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Catalogue details",
                fields: [
                    {
                        prettyName: "Catalogue item name",
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                        editorWidth: 6,
                        overviewSortOrder: 1,
                    },
                    {
                        prettyName: "Description",
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                        overviewSortOrder: 2,
                    },
                ],
            },
            {
                categoryName: "Control properties",
                fields: [
                    {
                        showInline: true,
                        editorWidth: 6,
                        fields: [
                            {
                                prettyName: "Type",
                                fieldName: "Type",
                                fieldType: GenericListFieldType.SingleSelect,
                                getDataMethodByRow: (row, ds) =>
                                    ds.getAllByType(StandingDataType.ControlType, row["Type"]),
                                hideInOverview: true,
                                required: false,
                                editorWidth: 8,
                            },
                            {
                                prettyName: "Allow override",
                                fieldName: "TypeOverwritable",
                                fieldType: GenericListFieldType.CheckBox,
                                defaultValue: false,
                                hideInOverview: true,
                                editorWidth: 4,
                            },
                            {
                                prettyName: "Source",
                                fieldName: "Source",
                                fieldType: GenericListFieldType.SingleSelect,
                                getDataMethodByRow: (row, ds) =>
                                    ds.getAllByType(StandingDataType.ControlSource, row["Source"]),
                                hideInOverview: true,
                                required: false,
                                editorWidth: 8,
                            },
                        ],
                    },
                ],
            },
            {
                categoryName: "Structures",
                fields: [
                    {
                        prettyName: "Framework Dimensions",
                        fieldName: "FrameworkDimensionIds",
                        fieldType: GenericListFieldType.MultiTree,
                        getDataMethodByRow: (row, ds) =>
                            ds.getVisibleFrameworkDimensions(ModuleType.Controls),
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.FrameworkDimensions),
                        hideInOverview: true,
                        required: false,
                        editorWidth: 8,
                    },
                ],
            },
        ],
    };

    // ControlFrequency
    440: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                    {
                        prettyName: "Suggested number of test samples",
                        fieldName: "suggestedNumberOfTestSamples",
                        fieldType: GenericListFieldType.Number,
                        minimumValue: 0,
                        required: true,
                    },
                    {
                        prettyName: "Minimum number of test samples",
                        fieldName: "minimumNumberOfTestSamples",
                        fieldType: GenericListFieldType.Number,
                        minimumValue: 0,
                        required: true,
                    },
                ],
            },
        ],
    };

    // ControlDiCatalogue
    445: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        prettyName: "Tester(s)",
                        fieldName: "testerIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getDiUsers(ControlsUserType.Tester, row["testerIdList"]),
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Evidence Uploader(s)",
                        fieldName: "evidenceUploaderIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getDiUsers(ControlsUserType.Uploader, row["evidenceUploaderIdList"]),
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Quality Aspect",
                        fieldName: "qualityAspectIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.ControlQualityAspects,
                                row["qualityAspectIdList"]
                            ),
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Linked Standard",
                        fieldName: "linkedStandardId",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.ControlLinkedStandards,
                                row["linkedStandardId"]
                            ),
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Test Frequency",
                        fieldName: "testFrequencyId",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) => ds.getControlTestFrequencies(),
                        editorWidth: 6,
                    },
                    {
                        prettyName: "# Test Sample",
                        fieldName: "numberOfTestSamples",
                        fieldType: GenericListFieldType.Number,
                        minimumValue: 0,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Test Activity",
                        fieldName: "testActivity",
                        fieldType: GenericListFieldType.TextArea,
                    },
                    {
                        prettyName: "Test Norm",
                        fieldName: "testNorm",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlAdvancedEffectivenessCatalogue
    450: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Catalogue information",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        prettyName: "Applicable Organizations",
                        fieldName: "applicableOrganizationIdList",
                        description: Configuration.NoOrganizationsSelectedDescription,
                        fieldType: GenericListFieldType.MultiTree,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getOrganizationsIncludingSelected(
                                ModuleType.Controls,
                                row["applicableOrganizationIdList"]
                            ),
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.Organization),
                    },
                ],
            },
            {
                categoryName: "Default involved persons",
                fields: [
                    {
                        prettyName: "Evidence uploaders(s)",
                        fieldName: "evidenceUploaderIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getEffusers(
                                ControlsUserType.Uploader,
                                row["evidenceUploaderIdList"]
                            ),
                    },
                    {
                        prettyName: "Tester(s)",
                        fieldName: "testerIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getEffusers(ControlsUserType.Tester, row["testerIdList"]),
                    },
                    {
                        prettyName: "Reviewer(s)",
                        fieldName: "reviewerIdList",
                        hideInEditBySettingsService: async (settingsService) => {
                            const setting = (
                                await settingsService
                                    .getSetting(
                                        ApplicationSettings.EffectivenessTestingReviewerRequired
                                    )
                                    .toPromise()
                            ).BoolValue;
                            return !setting;
                        },
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getEffusers(ControlsUserType.Reviewer, row["reviewerIdList"]),
                    },
                ],
            },
            {
                categoryName: "Default properties",
                fields: [
                    {
                        prettyName: "Linked Standard",
                        fieldName: "linkedStandardId",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.ControlLinkedStandards,
                                row["linkedStandardId"]
                            ),
                    },
                    {
                        prettyName: "Quality Aspect(s)",
                        fieldName: "qualityAspectIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.ControlQualityAspects,
                                row["qualityAspectIdList"]
                            ),
                    },
                    {
                        prettyName: "Sample Type",
                        fieldName: "sampleType",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) => ds.getSampleTypes(),
                    },
                ],
            },
            {
                categoryName: "Method of testing",
                fields: [
                    {
                        prettyName: "Test norm",
                        fieldName: "testNorm",
                        fieldType: GenericListFieldType.TextArea,
                    },
                    {
                        prettyName: "Test steps",
                        fieldName: "testSteps",
                        fieldType: GenericListFieldType.GenericList,
                        additionalConfig: <GenericListConfig>{
                            name: "",
                            idProp: "ID",
                            isSortable: true,
                            sortProp: "Sort_Order",
                            fields: [
                                {
                                    prettyName: "Reference",
                                    fieldName: "reference",
                                    fieldType: GenericListFieldType.Text,
                                    required: true,
                                },
                                {
                                    prettyName: "Description",
                                    fieldName: "description",
                                    fieldType: GenericListFieldType.TextArea,

                                    required: true,
                                },
                                {
                                    prettyName: "Required documents",
                                    fieldName: "requiredDocuments",
                                    fieldType: GenericListFieldType.StringArray,
                                    defaultValue: [],
                                },
                                {
                                    prettyName: "Test method",
                                    fieldName: "testMethodId",
                                    fieldType: GenericListFieldType.SingleSelect,
                                    required: true,
                                    getDataMethod: () => {
                                        const ds =
                                            AppConstants.AppInjector.get(StandingdataDataService);
                                        return ds.getMaintenanceListByType(
                                            StandingDataType.ControlTestMethod
                                        );
                                    },
                                },
                            ],
                        },
                        defaultValue: [],
                    },
                ],
            },
        ],
    };

    // ControlSimpleEffectivenessCatalogue
    451: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Catalogue information",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        prettyName: "Applicable Organizations",
                        fieldName: "applicableOrganizationIdList",
                        description: Configuration.NoOrganizationsSelectedDescription,
                        fieldType: GenericListFieldType.MultiTree,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getOrganizationsIncludingSelected(
                                ModuleType.Controls,
                                row["applicableOrganizationIdList"]
                            ),
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.Organization),
                    },
                ],
            },
            {
                categoryName: "Default involved persons",
                fields: [
                    {
                        prettyName: "Evidence uploaders(s)",
                        fieldName: "evidenceUploaderIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getEffusers(
                                ControlsUserType.Uploader,
                                row["evidenceUploaderIdList"]
                            ),
                    },
                    {
                        prettyName: "Tester(s)",
                        fieldName: "testerIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getEffusers(ControlsUserType.Tester, row["testerIdList"]),
                    },
                ],
            },
            {
                categoryName: "Default properties",
                fields: [
                    {
                        prettyName: "Linked Standard",
                        fieldName: "linkedStandardId",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.ControlLinkedStandards,
                                row["linkedStandardId"]
                            ),
                    },
                    {
                        prettyName: "Quality Aspect(s)",
                        fieldName: "qualityAspectIdList",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.ControlQualityAspects,
                                row["qualityAspectIdList"]
                            ),
                    },
                    {
                        prettyName: "Test frequency",
                        fieldName: "testFrequency",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) => ds.getControlTestFrequencies(),
                    },
                ],
            },
            {
                categoryName: "Method of testing",
                fields: [
                    {
                        prettyName: "Test norm",
                        fieldName: "testNorm",
                        fieldType: GenericListFieldType.TextArea,
                    },
                    {
                        prettyName: "Test activity",
                        fieldName: "testActivity",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlScore
    455: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        sortMessageSubtitle:
            "The conclusion score for each scored control will be recalculated, please wait...",
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        prettyName: "Active",
                        fieldName: "IsActive",
                        fieldType: GenericListFieldType.CheckBox,
                        defaultValue: false,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                        required: true,
                    },
                ],
            },
        ],
    };

    // ControlTestMethod
    460: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // ControlCustomFields
    465: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
        ],
    };

    //#endregion

    //#region BIM

    // BimValidationType
    500: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // BimAssessmentType
    505: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        categoryName: "",
                        showInline: true,
                        editorWidth: 6,
                        fields: [
                            {
                                fieldName: "Name",
                                fieldType: GenericListFieldType.Text,
                                required: true,
                                unique: true,
                            },
                        ],
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Scores",
                        fieldName: "scores",
                        fieldType: GenericListFieldType.GenericList,
                        defaultValue: [],
                        additionalConfig: <GenericListConfig>{
                            name: "",
                            isSortable: true,
                            sortProp: "Sort_Order",
                            fields: [
                                {
                                    fieldName: "Name",
                                    fieldType: GenericListFieldType.Text,
                                    required: true,
                                },
                                {
                                    fieldName: "Description",
                                    fieldType: GenericListFieldType.Text,
                                },
                                {
                                    fieldName: "Color",
                                    fieldType: GenericListFieldType.ColorPicker,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    };

    // BimSubject
    510: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // BimFeasibility
    515: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // BimPriority
    520: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // BimImplementationScore
    525: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                        required: true,
                    },
                    {
                        prettyName: "Value",
                        fieldName: "Weight",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                    },
                ],
            },
        ],
    };

    // BimReportType
    530: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        categoryName: "",
                        showInline: true,
                        editorWidth: 6,
                        fields: [
                            {
                                fieldName: "Name",
                                fieldType: GenericListFieldType.Text,
                                required: true,
                                unique: true,
                            },
                            {
                                prettyName: "Organizations",
                                fieldName: "organizations",
                                description: Configuration.NoOrganizationsSelectedDescription,
                                fieldType: GenericListFieldType.MultiTree,
                                defaultValue: [],
                                getDataMethodByRow: (row, ds) =>
                                    ds.getOrganizationsIncludingSelected(
                                        ModuleType.BIM,
                                        row["organizations"]
                                    ),
                                getExportDataMethod: (ds) =>
                                    ds.getExportByType(StandingDataType.Organization),
                                hideInOverview: true,
                                required: false,
                            },
                        ],
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Scores",
                        fieldName: "scores",
                        fieldType: GenericListFieldType.GenericList,
                        defaultValue: [],
                        additionalConfig: <GenericListConfig>{
                            name: "",
                            isSortable: true,
                            sortProp: "Sort_Order",
                            fields: [
                                {
                                    fieldName: "Name",
                                    fieldType: GenericListFieldType.Text,
                                    required: true,
                                    unique: true,
                                },
                                {
                                    fieldName: "Description",
                                    fieldType: GenericListFieldType.Text,
                                },
                                {
                                    fieldName: "Color",
                                    fieldType: GenericListFieldType.ColorPicker,
                                    required: true,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    };

    //#endregion

    //#region Forms

    // FormGroup
    700: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Group Information",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
            {
                categoryName: "User Selection",
                fields: [
                    {
                        fieldName: "UserOrganizations",
                        prettyName: "User Organizations",
                        description:
                            "Users from these organizations will be included. The group will change if a user is added or removed from an organization.",
                        conditionallyRequired: true,

                        fieldType: GenericListFieldType.MultiTree,
                        defaultValue: [],
                        hideInOverview: true,
                        getDataMethodByRow: (row, ds) =>
                            ds.getOrganizationsIncludingSelected(
                                ModuleType.FRM,
                                row["UserOrganizations"]
                            ),
                        getExportDataMethod: (ds) =>
                            ds.getExportByType(StandingDataType.Organization),
                    },
                    {
                        fieldName: "Users",
                        fieldType: GenericListFieldType.MultiSelect,
                        defaultValue: [],
                        conditionallyRequired: true,
                        hideInOverview: true,
                        getDataMethodByRow: (row, ds) => ds.getUsers(CerrixRight.Default),
                    },
                ],
            },
        ],
    };

    // FormCategory
    705: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    //#endregion

    //#region DM

    // DmBasis
    1200: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // DmSourceDocuments
    1205: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // DmTransferSafeguard
    1210: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // DmRemovalPolicy
    1215: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // DmSensitivityOfProcessing
    1220: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                ],
            },
        ],
    };

    // DmDataSubjects
    1230: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },
                    {
                        prettyName: "Description",
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Comments",
                        fieldName: "Comments",
                        fieldType: GenericListFieldType.TextArea,
                        required: false,
                        editorWidth: 6,
                    },
                ],
            },
        ],
    };

    // DmSystems
    1235: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        prettyName: "Owner",
                        fieldName: "owner",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) => ds.getThirdParties(row["owner"]),
                        required: true,
                    },
                    {
                        prettyName: "Confidentiality",
                        fieldName: "confidentiality",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(
                                StandingDataType.DmConfidentiality,
                                row["confidentiality"]
                            ),
                        required: true,
                    },
                    {
                        prettyName: "Integrity",
                        fieldName: "integrity",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(StandingDataType.DmIntegrity, row["integrity"]),
                        required: true,
                    },
                    {
                        prettyName: "Availability",
                        fieldName: "availability",
                        fieldType: GenericListFieldType.SingleSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(StandingDataType.DmAvailability, row["availability"]),
                        required: true,
                    },
                ],
            },
        ],
    };

    // DmDataStructures
    1240: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },
                    {
                        prettyName: "Description",
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Comments",
                        fieldName: "Comments",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Special Category",
                        fieldName: "SpecialCategory",
                        fieldType: GenericListFieldType.CheckBox,
                        defaultValue: false,
                    },
                    {
                        prettyName: "Sensitive",
                        fieldName: "Sensitive",
                        fieldType: GenericListFieldType.CheckBox,
                        defaultValue: false,
                    },
                ],
            },
        ],
    };

    // DmConfidentiality
    1245: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Abbreviation",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        maxLength: 1,
                    },
                ],
            },
        ],
    };

    // DmIntegrity
    1250: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Abbreviation",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        maxLength: 1,
                    },
                ],
            },
        ],
    };

    // DmAvailability
    1255: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Abbreviation",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        maxLength: 1,
                    },
                ],
            },
        ],
    };

    // ThirdParty
    // TpThirdPartyScore
    1500: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                        required: true,
                    },
                    {
                        prettyName: "Value",
                        fieldName: "Weight",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                    },
                ],
            },
        ],
    };

    // TpService
    1505: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.Tree,
        parentProp: "ParentID",
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        required: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Use parent / default icon",
                        fieldType: GenericListFieldType.CheckBox,
                        required: false,
                        defaultValue: true,
                        editorWidth: 12,
                    },
                ],
            },
        ],
    };

    // TpContractStatus
    1510: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // TpRelationship
    1515: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // TpCriticality
    1520: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                        required: true,
                    },
                    {
                        prettyName: "Value",
                        fieldName: "Weight",
                        fieldType: GenericListFieldType.Number,
                        required: true,
                        minimumValue: 1,
                    },
                ],
            },
        ],
    };

    // TpStatusOfInvolvement
    1525: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                ],
            },
        ],
    };

    // ThirdPartyCustomFields
    1530: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
        ],
    };

    // BusinessAssessmentScore
    1600: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // Framework Review Type
    1700: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                    },
                ],
            },
        ],
    };

    // FrameworkAssessmentScore
    1705: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // AuditImpact
    1800: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // AuditOverallRiskScore
    1805: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // AuditAssessmentScore
    1810: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // AuditControlEnvironment
    1815: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        categoryName: "",
                        showInline: true,
                        editorWidth: 6,
                        fields: [
                            {
                                fieldName: "Name",
                                fieldType: GenericListFieldType.Text,
                                required: true,
                                unique: true,
                            },
                        ],
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 6,
                    },
                    {
                        prettyName: "Scores",
                        fieldName: "Scores",
                        fieldType: GenericListFieldType.GenericList,
                        defaultValue: [],
                        additionalConfig: <GenericListConfig>{
                            name: "",
                            isSortable: true,
                            sortProp: "Sort_Order",
                            fields: [
                                {
                                    fieldName: "Name",
                                    fieldType: GenericListFieldType.Text,
                                    required: true,
                                    unique: true,
                                },
                                {
                                    prettyName: "Weight",
                                    fieldName: "Weight",
                                    fieldType: GenericListFieldType.Number,
                                    required: true,
                                    minimumValue: 1,
                                },
                                {
                                    fieldName: "Color",
                                    fieldType: GenericListFieldType.ColorPicker,
                                    required: true,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    };

    // AuditType
    1900: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        required: true,
                    },
                    {
                        prettyName: "Fieldwork Step Observation Scores",
                        fieldName: "ObservationScores",
                        fieldType: GenericListFieldType.GenericList,
                        defaultValue: [
                            {
                                ID: -1,
                                Name: "Bad",
                                Weight: 10,
                                Color: "#d65035",
                                Sort_Order: 10,
                            },
                            {
                                ID: -2,
                                Name: "Medium",
                                Weight: 5,
                                Color: "#ffd10b",
                                Sort_Order: 20,
                            },
                            {
                                ID: -3,
                                Name: "Good",
                                Weight: 1,
                                Color: "#92b926",
                                Sort_Order: 30,
                            },
                        ],
                        additionalConfig: <GenericListConfig>{
                            name: "",
                            isSortable: true,
                            sortProp: "Sort_Order",
                            fields: [
                                {
                                    fieldName: "Name",
                                    fieldType: GenericListFieldType.Text,
                                    required: true,
                                    unique: true,
                                },
                                {
                                    prettyName: "Value",
                                    fieldName: "Weight",
                                    fieldType: GenericListFieldType.Number,
                                    required: true,
                                    minimumValue: 1,
                                },
                                {
                                    fieldName: "Color",
                                    fieldType: GenericListFieldType.ColorPicker,
                                    required: true,
                                },
                            ],
                            onSortChange: (data: any[]) => {
                                let i = 1;
                                data.forEach((d) => {
                                    d.Sort_Order = i * 10;
                                    i++;
                                });
                            },
                        },
                    },
                ],
            },
        ],
    };

    // AuditScore
    1905: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // AuditConfidentiality
    1910: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // AuditCriticality
    1915: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // Audit Workflow
    1925: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        disableAdd: true,
        disableDelete: true,
        disableSort: true,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        isReadonly: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 12,
                    },
                ],
            },
        ],
    };

    // AuditRiskAssessmentFields
    1930: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
            {
                categoryName: "Audit settings",
                fields: [
                    {
                        fieldName: "auditTypeIds",
                        formattedName: "Audit Type(s)",
                        fieldType: GenericListFieldType.MultiSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(StandingDataType.AuditType, row["auditTypeIds"]),
                    },
                ],
            },
        ],
    };

    // AuditContextCustomFields
    1935: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        customEditor: true,
        categories: [
            {
                categoryName: "Details",
                showInline: true,
                fields: [
                    {
                        fieldName: "",
                        fieldType: GenericListFieldType.CustomFields,
                    },
                ],
            },
            {
                categoryName: "Audit settings",
                fields: [
                    {
                        fieldName: "auditTypeIds",
                        formattedName: "Audit Type(s)",
                        fieldType: GenericListFieldType.MultiSelect,
                        getDataMethodByRow: (row, ds) =>
                            ds.getAllByType(StandingDataType.AuditType, row["auditTypeIds"]),
                    },
                ],
            },
        ],
    };

    // Audit Severity
    2000: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    // Audit Finding Follow-up
    2010: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                        editorWidth: 12,
                    },
                ],
            },
        ],
    };

    // TaskScore
    2100: GenericStandingDataConfig = {
        overviewType: StandingDataOverviewType.List,
        categories: [
            {
                categoryName: "Details",
                fields: [
                    {
                        fieldName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        unique: true,
                    },
                    {
                        fieldName: "Color",
                        fieldType: GenericListFieldType.ColorPicker,
                    },
                ],
            },
        ],
    };

    //#endregion
}
