import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { TargetModule } from "@enums/document/TargetModule";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { DocumentTemplatesModuleModel } from "./models/document-templates-module-model";
import { MergeFieldTableModel } from "./models/merge-field-table-model";
import { DocumentGenerationParams } from "@app/shared/document-manager/document-manager-generate/DocumentGenerationParams";
import { DocumentModel } from "@models/documents/documentmodel";
import { KeyValuePair } from "@models/generic/KeyValuePair";

@Injectable({
    providedIn: "root",
})
export class DocumentTemplatesDataService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "document-templates");
    }

    public getDocumentTemplate(module: TargetModule): Observable<DocumentTemplatesModuleModel> {
        return this.get<DocumentTemplatesModuleModel>(`/${module}`);
    }

    public updateDocumentTemplate(
        module: TargetModule,
        model: DocumentTemplatesModuleModel
    ): Observable<never> {
        return this.putWithDocuments(model, model.documents, `/${module}`);
    }

    public getMergeFields(module: TargetModule): Observable<MergeFieldTableModel[]> {
        return this.get<MergeFieldTableModel[]>(`/${module}/merge-fields`);
    }

    public generateDocument(parameters: DocumentGenerationParams): Observable<DocumentModel> {
        return this.post<DocumentModel>(parameters, `/${parameters.module}/generate`);
    }

    public getOutputTypes(): Observable<KeyValuePair[]> {
        return this.get<KeyValuePair[]>(`/output-types`);
    }
}
