import { guid } from "../../../methods/uniqueMethods";

export class TabMenuItem {
    identifier: string = guid();
    menuItemId: string;
    name: string;
    obj?: any;
    clickable? = true;
    hidden? = false;

    // Icon to append if needed.
    iconClass?: string;

    children?: TabMenuItem[] = [];

    constructor(init?: Partial<TabMenuItem>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}
