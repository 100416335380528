import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { ThirdPartyContractModel } from "@models/thirdparty/ThirdPartyContractModel";
import { Observable } from "rxjs";
import { DocumentMethods } from "@methods/DocumentMethods";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { ThirdPartyPermissions } from "@models/thirdparty/ThirdPartyPermissions";
import { isGuid } from "@methods/uniqueMethods";

@Injectable()
export class ThirdPartyContractDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "thirdpartycontract");
    }

    public getContractData(guid: string) {
        const url = `/contractdata/${guid}`;
        return this.get<ThirdPartyContractModel>(url);
    }

    public getContracts(thirdPartyGuid: string): Observable<any[]> {
        const url = `/contracts/${thirdPartyGuid}`;
        return this.get<any[]>(url);
    }

    public deleteContract(guid: string): Observable<string[]> {
        const url = `/${guid}/deletecontract`;
        return this.delete(url);
    }

    public getPermissions(guid: string): Observable<ThirdPartyPermissions> {
        const url = isGuid(guid) ? `/${guid}/permissions` : "/null/permissions";
        return this.get<ThirdPartyPermissions>(url);
    }

    public storeContract(model: ThirdPartyContractModel) {
        const url = `/storeContract`;
        const formData = DocumentMethods.convertToFormDataWithFiles("contract", model);
        return this.post<string>(formData, url);
    }

    public getHistory(guid: string): Observable<HistoryModel[]> {
        const url = `/${guid}/history`;
        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(guid: string, mutationId: number): Observable<HistoryChange[]> {
        const url = `/${guid}/history/${mutationId}`;
        return this.get<HistoryChange[]>(url);
    }
}
