import { Component, ElementRef, ViewChild } from "@angular/core";
import { EventDataService } from "@app/event";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { SeriesBarOptions } from "highcharts";
import { BaseHighchartWidget } from "../BaseHighchartWidget";

@Component({
    selector: "event-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class EventWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private eventDataService: EventDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.eventDataService.getWidgetInfo(filters).toPromise();

        this.config.noData = !data || data.totalCount === 0;
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <SeriesBarOptions>{
                pointWidth: 25,
                name: "Events",
                data: [
                    {
                        name: "Total incidents",
                        y: data.totalCount,
                        color: "#024d60",
                    },
                    {
                        name: "Incidents resulted in loss",
                        y: data.lossCount,
                        color: "#d65035",
                    },
                    {
                        name: "Incidents resulted in profit",
                        y: data.profitCount,
                        color: "#097F1E",
                    },
                    {
                        name: "Open incidents",
                        y: data.openCount,
                        color: "#ff9900",
                    },
                ],
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);

        config.legend = {
            enabled: false,
        };

        config.tooltip = {
            headerFormat: "<b>{point.x}:</b> {point.y}",
            pointFormat: "",
        };

        config.xAxis = {
            labels: {
                format: "{value:.0f}",
            },
            categories: [
                "Total incidents",
                "Incidents resulted in loss",
                "Incidents resulted in profit",
                "Open incidents",
            ],
        };

        config.yAxis = {
            min: 0,
            allowDecimals: false,
            title: {
                text: "Number of incidents",
            },
        };

        return config;
    }

    protected async openWorkspace(ev) {
        const searchKeywords: SearchKeyword[] = this.getSearchKeywords(ev.point.category);

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.EventOverview, preset);
    }

    private getSearchKeywords(category: string) {
        const searchKeywords: SearchKeyword[] = [];
        searchKeywords.push(
            this.getSearchKeyword("Status", "Ready For Approval", ComparisonType.Contains)
        );
        searchKeywords.push(
            this.getSearchKeyword("Status", "Awaiting Improvements", ComparisonType.Contains)
        );
        if (category !== "Open incidents") {
            searchKeywords.push(
                this.getSearchKeyword("Status", "Approved and Closed", ComparisonType.Contains)
            );
        }

        if (category === "Incidents resulted in loss") {
            searchKeywords.push(
                this.getSearchKeyword("Net costs", "0", ComparisonType["After/Greater than"])
            );
        } else if (category === "Incidents resulted in profit") {
            searchKeywords.push(
                this.getSearchKeyword("Net costs", "0", ComparisonType["Before/Less than"])
            );
        }

        return searchKeywords;
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.EventOverview, preset);
    }
}
