import { IProcessRiskConnection } from "./../../Interfaces/IProcessRiskConnection";
import { IRiskControlConnection } from "./../../Interfaces/IRiskControlConnection";
import { ProcessCellConnectionModel } from "./../../models/ProcessCellConnectionModel";
import { PrintControlModel } from "./../../../../common/models/processeditor/PrintControlModel";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListConfigMultiLevel } from "@app/shared/models/GenericList/GenericListMultiLevel";
import { POPOUT_MODAL_DATA, POPOUT_WINDOW_REF } from "@app/shared/services/popup/popup.tokens";
import { nameof } from "@methods/CommonMethods";
import { FindNode } from "@methods/TreeMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { PrintData } from "../../models/PrintData";
import { PrintRiskModel } from "@models/processeditor/PrintRiskModel";
import { PrintOverviewModel } from "@models/processeditor/PrintOverviewModel";
import {
    createCellRaciConfig,
    createLinkedControlConfig,
    createLinkedRiskConfig,
    createMoiRenderer,
    createUnlinkedEventConfig,
    createUnlinkedMOIConfig,
} from "./PrintConfig";
import { ProcessEditorPrintType } from "@app/process-editor/enums/ProcessEditorPrintType";
import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { ProcessStepModel } from "@app/process-editor/models/ProcessStepModel";
import { RendererConfig } from "@models/workspace/RendererConfig";
@Component({
    selector: "app-print",
    templateUrl: "./print.component.html",
    styleUrls: ["./print.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PrintComponent implements OnInit {
    private businessDimensions;

    public printOverviewModel: PrintOverviewModel;
    public printData: PrintData;
    public isGraphRisksAndControls: boolean;
    public tab: TabModel = { loadingMessage: "Loading print data" } as any;

    public unlinkedRiskConfig: GenericListConfig;
    public linkedRiskConfig: GenericListConfigMultiLevel;
    public linkedControlConfig: GenericListConfig;
    public unlinkedControlConfig: GenericListConfig;
    public moiRenderer: RendererConfig[];
    public unlinkedMOIConfig: GenericListConfig;
    public unlinkedEventConfig: GenericListConfig;
    public cellRaciConfig: GenericListConfig;

    public cellViewModels: CellViewModel[];

    constructor(
        @Inject(POPOUT_MODAL_DATA) public data: PrintData,
        @Inject(POPOUT_WINDOW_REF) private _window: Window,
        private _ds: ProcessEditorDataService
    ) {
        this.printData = data;
        this.isGraphRisksAndControls =
            this.printData?.printSetting === ProcessEditorPrintType.GraphRisksAndControls;
    }

    async ngOnInit(): Promise<void> {
        const bds = await this._ds.getBusinessDimensions(this.data.meta.id).toPromise();
        this.businessDimensions = bds;

        this.linkedRiskConfig = createLinkedRiskConfig(
            this.printData.printSetting === ProcessEditorPrintType.GraphAndData
        );
        this.linkedControlConfig = createLinkedControlConfig();
        this.moiRenderer = createMoiRenderer();
        this.unlinkedMOIConfig = createUnlinkedMOIConfig();
        this.unlinkedEventConfig = createUnlinkedEventConfig();
        this.cellRaciConfig = createCellRaciConfig();

        if (this.printData.printSetting !== ProcessEditorPrintType.GraphOnly) {
            const data = await this._ds
                .getPrintOverviewModel(
                    this.data.meta.id,
                    {
                        processControlConnections:
                            this.data.processCellConnectionModel.processControlConnections,
                        processRiskConnections:
                            this.data.processCellConnectionModel.processRiskConnections,
                        riskControlConnections:
                            this.data.processCellConnectionModel.riskControlConnections,
                    },
                    this.printData.printSetting
                )
                .toPromise();

            this.printOverviewModel = data;
            this.getUnlinkedControls();
            this.getUnlinkedRisks();
            if (this.printData.printSetting === ProcessEditorPrintType.GraphAndData) {
                this.getUnlinkedEvents();
                this.getUnlinkedMOIs();
            }
        }

        this.createCellViewModels();

        if (!this.data.isPrintPreview) {
            // This puts the print command at the end of the queue
            // which gives angular the chance to render its components first
            setTimeout(() => {
                this._window.print();
            }, 0);
        }
    }

    public get stringify() {
        return JSON.stringify;
    }

    public createCellViewModels() {
        const printSetting = this.printData.printSetting;

        // Skip Risk & Control cells when printing the graph
        const skippedCells =
            printSetting == ProcessEditorPrintType.GraphOnly
                ? [ItemEnum.Risk, ItemEnum.Control]
                : [];

        const filteredSteps = this.data.processSteps.filter(
            (c) =>
                c.includeinprint &&
                c.includeinprint != "false" &&
                (printSetting == ProcessEditorPrintType.GraphAndData ||
                    skippedCells.indexOf(ItemEnum.fromShape(c.shape as any)) == -1)
        );

        const sortedSteps = filteredSteps.sort((a, b) => {
            if (a.ordernr && b.ordernr) return a.ordernr - b.ordernr;
            if (!a.ordernr || (a.ordernr < 1 && b.ordernr)) return -1;
            if (a.ordernr && (!b.ordernr || b.ordernr < 1)) return 1;
            return a.y - b.y || a.x - b.x;
        });

        const mappedSteps = sortedSteps.map((step) => <CellViewModel>{ processStep: step });

        mappedSteps.forEach((cell) => {
            cell.businessDimensionName = this.getBusinessDimensionName(cell.processStep);
            if (printSetting !== ProcessEditorPrintType.GraphOnly) {
                cell.linkedRiskConfig = this.getLinkedRiskConfigForCell(cell.processStep);
                cell.linkedControlConfig = this.getLinkedControlConfigForCell(cell.processStep);
            }
            cell.raciConfig = this.getRaciConfigForCell(cell.processStep);
        });

        this.cellViewModels = mappedSteps;
    }

    private getBusinessDimensionName(cell: ProcessStepModel): string {
        const linkedId = cell.linkedId;
        if (!linkedId || !this.businessDimensions) {
            return "";
        }

        return FindNode(this.businessDimensions, linkedId)?.Name ?? "";
    }

    private getLinkedControlConfigForCell(cell: ProcessStepModel): GenericListConfig[] {
        let controls: PrintControlModel[] = [];

        const controlProcesses =
            this.data.processCellConnectionModel.processControlConnections.filter(
                (cr: any) => cr.process == cell.cellID
            );
        controlProcesses.map((controlProcess) => {
            // Add controls linked to a process step
            controls = controls.concat(
                this.printOverviewModel.linkedControlsMap[controlProcess.control]
            );
        });

        if (this.printData.printSetting === ProcessEditorPrintType.GraphRisksAndControls) {
            // Add controls linked to a risk
            controls = controls.concat(this.getRiskControlConfigForCell(cell));
            controls = controls.sortBy("identifier", true);
        }

        let copy: GenericListConfig = { ...this.linkedControlConfig };
        copy.data = controls;
        return [copy];
    }

    private getLinkedRiskConfigForCell(cell: ProcessStepModel): GenericListConfigMultiLevel[] {
        const riskProcesses = this.data.processCellConnectionModel.processRiskConnections.filter(
            (cr) => cr.process == cell.cellID
        );

        return riskProcesses.map((riskProcess) => {
            const copy: GenericListConfigMultiLevel = { ...this.linkedRiskConfig };

            const risks = this.printOverviewModel.linkedRisksMap[riskProcess.risk];
            copy.data = risks;

            copy.innerListProp = nameof<PrintRiskModel>("linkedControls");
            copy.innerConfig = { ...this.linkedControlConfig };

            return copy;
        });
    }

    private getRaciConfigForCell(cell: ProcessStepModel): GenericListConfig {
        const copy = { ...this.cellRaciConfig };
        copy.data = this.data.cellraci.filter((cr) => cr.cellId == cell.cellID);

        return copy;
    }

    private getUnlinkedRisks() {
        let data: PrintRiskModel[];

        if (this.printData.printSetting === ProcessEditorPrintType.GraphRisksAndControls) {
            // All risks in the diagram not linked to a process step are shown for the GraphRisksAndControls printtype
            const processRisks = this.data.processCellConnectionModel.processRiskConnections;
            const risksWithoutProcessStep = this.data.risks.filter(
                (risk) => !processRisks.some((pr) => pr.risk === risk.cellId)
            );
            data = this.printOverviewModel.unlinkedRisks.filter((unlinkedRisk) =>
                risksWithoutProcessStep.some(
                    (dataRisk) => unlinkedRisk.identifier === dataRisk.identifier
                )
            );
        } else {
            // All unlinked risks for this business dimension are shown
            data = this.printOverviewModel.unlinkedRisks.filter(
                (unlinkedRisk) =>
                    !this.data.risks.some(
                        (dataRisk) => unlinkedRisk.identifier === dataRisk.identifier
                    )
            );
        }

        this.unlinkedRiskConfig = { ...this.linkedRiskConfig };
        this.unlinkedRiskConfig.name = "Risks";
        this.unlinkedRiskConfig.data = data;
    }

    private getUnlinkedControls() {
        let data: PrintControlModel[];
        if (this.printData.printSetting === ProcessEditorPrintType.GraphRisksAndControls) {
            // All controls in the diagram not linked to a process step are shown for the GraphRisksAndControls printtype
            const processControls = this.data.processCellConnectionModel.processControlConnections;
            const riskControls = this.data.processCellConnectionModel.riskControlConnections;
            const controlsWithoutProcessStep = this.data.controls.filter(
                (c) =>
                    !processControls.some((pc) => pc.control === c.cellId) &&
                    !riskControls.some((rc) => (rc.control = c.cellId))
            );
            data = this.printOverviewModel.unlinkedControls.filter((unlinkedControl) =>
                controlsWithoutProcessStep.some(
                    (dataControl) => unlinkedControl.identifier === dataControl.identifier
                )
            );
        } else {
            // All unlinked risks for this business dimension are shown
            data = this.printOverviewModel.unlinkedControls.filter(
                (unlinkedControl) =>
                    !this.data.controls.some(
                        (dataControl) => unlinkedControl.identifier === dataControl.identifier
                    )
            );
        }

        this.unlinkedControlConfig = { ...this.linkedControlConfig };
        this.unlinkedControlConfig.name = "Controls";
        this.unlinkedControlConfig.data = data;
    }

    private getUnlinkedEvents() {
        this.unlinkedEventConfig.data = this.printOverviewModel.unlinkedEvents;
    }

    private getUnlinkedMOIs() {
        this.unlinkedMOIConfig.data = this.printOverviewModel.unlinkedMois;
    }

    public trackByName(_index: number, config: GenericListConfig) {
        return config.name;
    }

    private getRiskControlConfigForCell(cell: ProcessStepModel): PrintControlModel[] {
        const riskProcesses = this.data.processCellConnectionModel.processRiskConnections.filter(
            (cr: IProcessRiskConnection) => cr.process == cell.cellID
        );
        const controlRisks = this.data.processCellConnectionModel.riskControlConnections.filter(
            (rc: IRiskControlConnection) => riskProcesses.some((rp) => rp.risk == rc.risk)
        );

        let controls: PrintControlModel[] = [];
        controlRisks.map((controlRisk) => {
            const copy: GenericListConfig = { ...this.linkedControlConfig };

            controls = controls.concat(this.printOverviewModel.riskControlsMap[controlRisk.risk]);
        });
        return controls;
    }
}

export class CellViewModel {
    processStep: ProcessStepModel;
    businessDimensionName: string;
    linkedControlConfig: GenericListConfig[];
    linkedRiskConfig: GenericListConfig[];
    raciConfig: GenericListConfig;
    riskControlConfig: GenericListConfig[];
}
