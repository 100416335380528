<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export Users</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <hr />

        <button class="btn btn-default me-2 mb-2" (click)="download(ExportType.UsersImport)">
            Export for users import
        </button>
    </div>
</div>
