<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="request-screen-system" menuSystem [cerrixTab]="tab" *ngIf="tpaRequestDetailModel">
    <div
        class="request-screens"
        [attr.name]="'Assurance request'"
        menuicon="fas fa-calculator"
        menusystemsubmenu
    >
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <div class="detail-page-card">
                <div class="detail-page-card-title"></div>
            </div>
            <div
                class="detail-page-summary"
                *ngIf="tpaRequestDetailModel && tpaRequestDetailModel.ID > 0"
            >
                <div class="row">
                    <div class="col-6">
                        <div class="property">
                            <b>Request ID:</b> {{ tpaRequestDetailModel.requestID }}
                        </div>
                        <div class="property">
                            <b>Connection name:</b> &nbsp;<span
                                data-uiid="request-detail-name-label"
                                >{{ tpaRequestDetailModel.connectionName }}</span
                            >
                        </div>
                        <div class="property">
                            <b>Requested test type:</b>
                            {{ tpaRequestDetailModel.requestedTestplanType }}
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="property">
                            <b>Start date :</b> {{ tpaRequestDetailModel.startDate | dateFormat }}
                        </div>
                        <div class="property">
                            <b>End date:</b> {{ tpaRequestDetailModel.endDate | dateFormat }}
                        </div>
                    </div>
                </div>
            </div>
            <generic-list-manager *ngIf="validConfig" [config]="validConfig"></generic-list-manager>
            <generic-list-manager
                *ngIf="invalidConfig"
                [config]="invalidConfig"
            ></generic-list-manager>
        </div>
    </div>
</div>
