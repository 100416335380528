<ng-container *ngIf="config && !config.showLoader">
    <div class="container" [class.clickable]="!invalidConfig" (click)="openWorkspace()">
        <div class="error-message" *ngIf="invalidConfig">Not or incorrectly configured</div>

        <ng-container *ngIf="!invalidConfig">
            <div class="header" *ngIf="customTitle">{{ customTitle }}</div>
            <ng-container *ngIf="!customTitle">
                <div class="header"># of {{ itemName }}</div>
                <div class="subtitle" *ngIf="presetName">({{ presetName }})</div>
            </ng-container>
            <div class="count">{{ count }}</div>
        </ng-container>
    </div>
</ng-container>
