import { Component, OnInit } from "@angular/core";
import { HistoryModel, HistoryType } from "@models/history/HistoryModel";
import { LocalizationService } from "@app/shared";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { FormHistory } from "@app/forms/models/other/FormHistory";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { HistoryMethods } from "@methods/HistoryMethods";

@Component({
    selector: "form-history-overview",
    templateUrl: "./form-history-overview.component.html",
    styleUrls: ["./form-history-overview.component.scss"]
})
export class FormHistoryOverviewComponent implements OnInit {
    tab: TabModel;

    Id: number;
    historyModels: HistoryModel[];
    displayedColumn: string[] = ["Column", "OldValue", "NewValue"];
    HistoryType = HistoryType;
    HistoryDetailModel: any = {};

    isLoading: boolean;

    constructor(
        private localizationService: LocalizationService,
        private _formDS: FormsDataService
    ) {}

    ngOnInit() {
        this.Id = parseInt(this.tab.id);
        this.loadHistory();
    }

    loadHistory() {
        if (this.Id > 0) {
            this.isLoading = true;
            this.historyModels = null;
            this._formDS.getHistory(this.Id).subscribe(history => {
                this.historyModels = history;
                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
        }
    }

    loadChanges(historyModel: HistoryModel) {
        if (historyModel.CombinedChanges) {
            return;
        }

        this.HistoryDetailModel = null;
        this._formDS.getHistoryChanges(this.Id, historyModel.ID).subscribe(changes => {
            const historyDetailModel = this.processHistory(changes);
            this.HistoryDetailModel = historyDetailModel;
        });
    }

    processHistory(history: FormHistory) {
        // create local object to display history
        const pageChanges: any = [];
        history.PageChanges.forEach(page => {
            const workflowDefChanges: any = [];
            page.WorkflowPageDefinitionChanges.forEach(wfd => {
                const wfdHistoryModel: any = {};
                wfdHistoryModel.changes = HistoryMethods.processChanges(
                    wfd.Changes,
                    this.localizationService
                );

                workflowDefChanges.push(wfdHistoryModel);
            });

            const fieldChanges: any = [];
            page.FieldChanges.forEach(field => {
                const fieldDocumentChanges: any = [];
                if (field.FieldDocumentChanges) {
                    field.FieldDocumentChanges.forEach(fieldDoc => {
                        const fieldDocumentHistoryModel: any = {};
                        fieldDocumentHistoryModel.name = field.Name;
                        fieldDocumentHistoryModel.changes = HistoryMethods.processChanges(
                            fieldDoc.Changes,
                            this.localizationService
                        );

                        fieldDocumentChanges.push(fieldDocumentHistoryModel);
                    });
                }

                const fieldHistoryModel: any = {};

                fieldHistoryModel.name = field.Name;
                fieldHistoryModel.changes = HistoryMethods.processChanges(
                    field.Changes,
                    this.localizationService
                );
                fieldHistoryModel.documentChanges = fieldDocumentChanges;

                fieldChanges.push(fieldHistoryModel);
            });

            const pageHistoryModel: any = {};
            pageHistoryModel.name = page.Name;
            pageHistoryModel.changes = HistoryMethods.processChanges(
                page.Changes,
                this.localizationService
            );
            pageHistoryModel.fieldChanges = fieldChanges;
            pageHistoryModel.workflowChanges = workflowDefChanges;

            pageChanges.push(pageHistoryModel);
        });

        const workflowDefChanges: any = [];
        history.WorkflowDefinitionChanges.forEach(wfd => {
            const wfdHistoryModel: any = {};
            wfdHistoryModel.changes = HistoryMethods.processChanges(
                wfd.Changes,
                this.localizationService
            );

            workflowDefChanges.push(wfdHistoryModel);
        });

        const historyDetailModel: any = {};
        historyDetailModel.changes = HistoryMethods.processChanges(
            history.Changes,
            this.localizationService
        );
        historyDetailModel.pageChanges = pageChanges;
        historyDetailModel.workflowDefChanges = workflowDefChanges;

        return historyDetailModel;
    }
}
