<div *ngIf="isLoading" class="fancy-loader"></div>
<risk-linkable
    *ngIf="!isLoading"
    typename="risks"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    (linkedChange)="onLinkedItemsChanged($event)"
    [showAdvancedFilters]="true"
    [activeFilters]="activeFilters"
>
</risk-linkable>

<div *ngIf="!isLoading && controlModel && (controlModel.IsShared || (linkedFromOtherOrganization != null && linkedFromOtherOrganization.any()))">
    <risk-linkable
        typename="risks from other organizations"
        displayValueProp="name"
        [linked]="linkedFromOtherOrganization"
        [unlinked]="[]"
        [orderByValueProperty]="true"
        [disabled]="true"
    >
    </risk-linkable>
</div>
