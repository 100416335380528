import {
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IncidentRisksService } from "@app/incident/services/incident-risks.service";
import { IncidentLinkModel } from "@app/incident/models/incident-link.model";
import {
    CerrixButtonComponent,
    CerrixDialogService,
    CerrixIconButtonComponent,
} from "@cerrix/components";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { RiskModel } from "@app/risk/models/RiskModel";
import { CerrixLinksEditomponent } from "./incident-links-edit/cerrix-links-edit.component";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { ToastrService } from "ngx-toastr";
import { IncidentControlsService } from "@app/incident/services/incident-controls.service";

@Component({
    selector: "incident-links-tab",
    templateUrl: "./incident-links-tab.component.html",
    styleUrls: ["./incident-links-tab.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        CerrixIconButtonComponent,
        CerrixButtonComponent,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
    ],
    providers: [],
})
export class IncidentLinksTabComponent implements OnChanges {
    @Input() canEditIncident!: boolean;
    @Input() public incidentId: string;
    @Input() public riskIds: string[] = [];
    @Input() public controlIds: string[] = [];
    @Output() riskIdsChange = new EventEmitter<string[]>();
    @Output() controlIdsChange = new EventEmitter<string[]>();

    protected risks: IncidentLinkModel[] = [];
    protected controls: IncidentLinkModel[] = [];
    protected mois: LinkedMoiModel[] = [];

    private readonly destroyRef = inject(DestroyRef);
    private readonly incidentRisksService = inject(IncidentRisksService);
    private readonly incidentControlsService = inject(IncidentControlsService);
    private readonly moiService = inject(MoiDataService);
    private readonly dialog = inject(MatDialog);
    private readonly tabservice = inject(TabService);
    private readonly pages = inject(Pages);
    private readonly dialogService = inject(CerrixDialogService);
    private readonly toastrService = inject(ToastrService);

    ngOnChanges(): void {
        if (!!this.riskIds && this.riskIds.length > 0) {
            this.incidentRisksService
                .getByIds(this.riskIds)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((risks) => {
                    this.risks = risks;
                });
        }

        if (!!this.controlIds && this.controlIds.length > 0) {
            this.incidentControlsService
                .getByIds(this.controlIds)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((controls) => {
                    this.controls = controls;
                });
        }

        this.moiService
            .getLinkedMois(this.incidentId, MoiTypes.Incident_Moi)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((mois) => {
                this.mois = mois;
            });
    }

    protected unlinkRisk(riskId: string) {
        this.riskIds = this.riskIds.filter((r) => r !== riskId);
        this.risks = this.risks.filter((r) => r.id !== riskId);
        this.riskIdsChange.emit(this.riskIds);
    }

    protected unlinkControl(controlId: string) {
        this.controlIds = this.controlIds.filter((c) => c !== controlId);
        this.controls = this.controls.filter((c) => c.id !== controlId);
        this.controlIdsChange.emit(this.controlIds);
    }

    protected openEditLinksPopup(type: "risks" | "controls") {
        let dialogInput;

        if (type == "risks") {
            dialogInput = {
                label: "Risk",
                selectedLinkIds: this.riskIds,
                linksService: this.incidentRisksService,
                incidentId: this.incidentId,
            };
        } else if (type == "controls") {
            dialogInput = {
                label: "Control",
                selectedLinkIds: this.controlIds,
                linksService: this.incidentControlsService,
                incidentId: this.incidentId,
            };
        }

        let dialogRef = this.dialog.open(CerrixLinksEditomponent, {
            width: "940px",
            height: "634px",
            data: dialogInput,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.length) {
                if (type == "risks") {
                    this.risks = result;
                    this.riskIds = result.map((r) => r.id);
                    this.riskIdsChange.emit(this.riskIds);
                } else if (type == "controls") {
                    this.controls = result;
                    this.controlIds = result.map((c) => c.id);
                    this.controlIdsChange.emit(this.controlIds);
                }
            }
        });
    }

    protected openRisk(risk: RiskModel) {
        this.tabservice.generateTab(
            this.pages.RiskDetail,
            risk.id,
            `(R) ${+risk.identifier} - ${risk.name}`
        );
    }

    protected openControl(control: IncidentLinkModel) {
        this.tabservice.generateTab(
            this.pages.ControlDetail,
            control.id,
            `(C) ${control.identifier} - ${control.name}`
        );
    }

    protected createMoi() {
        this.tabservice.generateTab(this.pages.MoiDetail, null, null, {
            ParentGuid: this.incidentId,
            MoiType: MoiTypes.Incident_Moi,
        });
    }

    protected openMoi(moi: LinkedMoiModel) {
        this.tabservice.generateTab(
            this.pages.MoiDetail,
            moi.guid,
            `(M) ${+moi.identifier} - ${moi.name}`
        );
    }

    protected deleteMoi(moi: LinkedMoiModel) {
        this.dialogService.openWarningConfirmDialog({
            data: {
                promptUiid: "delete-incident-moi-dialog-uiid",
                title: "Delete moi",
                message: "Are you sure you want to delete this moi?",
                positiveButton: {
                    text: "Delete moi",
                    buttonClass: "warning",
                    onAction: () => {
                        this.moiService.deleteMoi(moi.guid).subscribe(() => {
                            this.ngOnChanges();
                            this.toastrService.success("Moi deleted");
                        });
                        return true;
                    },
                },
                negativeButton: {
                    text: "Cancel",
                    buttonClass: "cancel",
                    onAction: () => {
                        return true;
                    },
                },
            },
        });
    }
}
