import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

declare var $: any;

@Component({
    selector: "ai-suggestions-popup",
    templateUrl: "./ai-suggestions-popup.component.html",
    styleUrls: ["./ai-suggestions-popup.component.scss"],
})
export class AiSuggestionsPopupComponent implements OnInit {
    suggestions: string[];
    currentSuggestion: string;
    currentSuggestionIndex: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AiSuggestionsPopupComponent>
    ) {}

    ngOnInit(): void {
        this.suggestions = this.data.suggestions;
        this.currentSuggestionIndex = 1;
        this.currentSuggestion = this.suggestions[0];
    }

    selectSuggestion(index) {
        this.currentSuggestionIndex = index;
        this.currentSuggestion = this.suggestions[this.currentSuggestionIndex - 1];
    }

    onApplyChanges() {
        this.data.onConfirm(this.currentSuggestion);
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
