<div class="main-page-area">
    <div class="main-page-content">
        <eff-step-header
            [controlGuid]="effectivenessModel.controlGuid"
            title="Control testing"
            (onSaveChanges)="save(true)"
            (onShowInstructions)="toggleInstructions()"
            [ngClass]="{ 'is-step-rejected': pageState.isRejected }"
            [showNonOccurrenceWarningArea]="isNonOccurrenceTestPlan"
        >
        </eff-step-header>

        <author-message-box
            *ngIf="pageState.isRejected"
            [author]="pageState.rejectedBy"
            [authorLabel]="pageState.rejectedByRole"
            [message]="pageState.rejectionMessage"
        >
        </author-message-box>

        <eff-checkbox
            class="expand-collapse-all"
            label="Open all"
            (modelChange)="expandCollapseAll($event, tableConfig?.DisableRowFunc)"
        ></eff-checkbox>

        <div class="main-grid d-flex flex-column">
            <div *ngFor="let viewSample of viewSamples">
                <div class="grid-header justify-content-between">
                    <div class="d-flex flex-row">
                        <div class="bold-text">Sample {{ viewSample.sample.name }}</div>
                        <eff-inline-text-editor
                            label="Add sample remark"
                            [disabled]="!stepPermissions.isEditSampleRemarkAllowed"
                            [(model)]="viewSample.sample.remarks"
                            (complete)="saveRemarksChange(viewSample.sample)"
                        ></eff-inline-text-editor>
                    </div>
                </div>
                <eff-table
                    [dataSource]="viewSample.gridDataSource"
                    [columns]="displayedColumns"
                    [tableConfig]="tableConfig"
                    [expandedRowTemplate]="expandedTemplate"
                >
                    <ng-template #expandedTemplate let-item="item">
                        <div class="grid-expanded-row">
                            <div class="table-row">
                                <div class="table-cell documents">
                                    <div class="d-flex flex-column gap-3">
                                        <div
                                            class="d-flex flex-column gap-2"
                                            *ngIf="item.noEvidence"
                                        >
                                            <eff-checkbox
                                                [disabled]="true"
                                                [checked]="true"
                                                label="No evidence"
                                            ></eff-checkbox>
                                            <div>
                                                {{ item.noEvidenceReason }}
                                            </div>
                                        </div>
                                        <documents-list
                                            class="documets-list-small-droparea"
                                            [class.hidden]="
                                                item.isSample &&
                                                isSampleDisabled(item) &&
                                                item.documents.length === 0
                                            "
                                            [documents]="item.documents"
                                            [canAdd]="stepPermissions.evidenceSaveAllowed"
                                            [canEdit]="stepPermissions.evidenceSaveAllowed"
                                            [canDelete]="stepPermissions.evidenceSaveAllowed"
                                        ></documents-list>
                                    </div>
                                </div>
                                <div class="table-cell tester-comment">
                                    <div class="d-flex flex-column gap-3 tester-comment-inner">
                                        <div *ngIf="pageState.isRejected">
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    testerScoreTemplate;
                                                    context: { item: item }
                                                "
                                            ></ng-container>
                                        </div>
                                        <ng-container
                                            *ngTemplateOutlet="
                                                testerCommentTemplate;
                                                context: { item: item }
                                            "
                                        ></ng-container>
                                    </div>
                                </div>
                                <div class="table-cell tester-score">
                                    <div class="tester-score-inner">
                                        <div *ngIf="pageState.isRejected" class="reviewer-comment">
                                            {{ item.commentReviewer }}
                                        </div>

                                        <div *ngIf="!pageState.isRejected">
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    testerScoreTemplate;
                                                    context: { item: item }
                                                "
                                            ></ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template column-name="name" let-item="item">
                        <div class="d-flex flex-row gap-3 align-items-center">
                            <div
                                [ngClass]="{ 'sample-name-cell': item.isSample }"
                                class="regular-text"
                            >
                                {{ item.isSample ? "Sample" : "Step" }} {{ item.name }}
                            </div>

                            <div
                                class="d-flex flex-row align-items-center gap-1"
                                *ngIf="visibleDocuments(item.documents).length > 0"
                            >
                                <eff-icon class="remark-icon" icon="attachment_outlined"></eff-icon>
                                <div>({{ visibleDocuments(item.documents).length }})</div>
                            </div>
                            <div *ngIf="item.noEvidence && item.noEvidenceReason">
                                <eff-icon class="remark-icon" icon="comment_outlined"></eff-icon>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template column-name="testerComment" let-item="item">
                        <div *ngIf="!item.IsExpanded && !pageState.isRejected">
                            <div class="add-comment-label" *ngIf="!item.commentTester">
                                add a comment
                            </div>
                            <eff-icon
                                class="remark-icon"
                                *ngIf="item.commentTester"
                                icon="comment_outlined"
                            ></eff-icon>
                        </div>
                        <div
                            *ngIf="!item.IsExpanded && pageState.isRejected"
                            class="score-comment d-flex flex-row"
                        >
                            <ng-container
                                *ngTemplateOutlet="testerScoreView; context: { item: item }"
                            ></ng-container>
                            <div *ngIf="!item.IsExpanded && item.commentTester">
                                <eff-icon class="remark-icon" icon="comment_outlined"></eff-icon>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template column-name="score" let-item="item">
                        <div *ngIf="!item.IsExpanded">
                            <ng-container
                                *ngTemplateOutlet="testerScoreView; context: { item: item }"
                            ></ng-container>
                        </div>
                    </ng-template>

                    <ng-template column-name="commentReviewer" let-item="item">
                        <div *ngIf="!item.IsExpanded && item.commentReviewer">
                            <eff-icon class="remark-icon" icon="comment_outlined"></eff-icon>
                        </div>
                    </ng-template>
                </eff-table>
            </div>
        </div>

        <ng-template #testerScoreTemplate let-item="item">
            <div>
                <div *ngIf="stepPermissions.evidenceSaveAllowed">
                    <eff-colored-selector
                        *ngIf="item.isSample"
                        [disabled]="isSampleDisabled(item)"
                        [options]="sampleScores"
                        [model]="item.sampleScore"
                        [triggerOpen]="item.triggerOpenScore"
                        (modelChange)="onScoreChanged($event, item)"
                    >
                    </eff-colored-selector>

                    <eff-colored-selector
                        *ngIf="!item.isSample"
                        [options]="stepScores"
                        [(model)]="item.stepScore"
                        [triggerOpen]="item.triggerOpenScore"
                        (modelChange)="onScoreChanged($event, item)"
                    >
                    </eff-colored-selector>
                </div>
                <div *ngIf="!stepPermissions.evidenceSaveAllowed">
                    <ng-container
                        *ngTemplateOutlet="testerScoreView; context: { item: item }"
                    ></ng-container>
                </div>
            </div>
        </ng-template>

        <ng-template #testerScoreView let-item="item">
            <div
                class="d-flex flex-row align-items-center status-cell"
                [class.disabled]="isSampleDisabled(item)"
            >
                <div class="status-bullet" [ngClass]="getScoreColor(item)"></div>
                <div *ngIf="!stepPermissions.evidenceSaveAllowed">
                    {{ item.scoreName }}
                </div>
                <div *ngIf="stepPermissions.evidenceSaveAllowed">
                    <div *ngIf="item.isSample">
                        {{ item.sampleScore == 0 ? "Add a score" : item.scoreName }}
                    </div>
                    <div *ngIf="!item.isSample">
                        {{ item.stepScore == 0 ? "Add a score" : item.scoreName }}
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #testerCommentTemplate let-item="item">
            <div>
                <eff-textarea
                    [disabled]="item.isSample && isSampleDisabled(item)"
                    class="grid-textarea-style"
                    [(model)]="item.commentTester"
                    label="add a comment"
                    *ngIf="stepPermissions.evidenceSaveAllowed"
                    (modelChange)="onChangesDebounce()"
                ></eff-textarea>
                <div *ngIf="!stepPermissions.evidenceSaveAllowed">{{ item.commentTester }}</div>
            </div>
        </ng-template>

        <div class="bottom-buttons-wrapper">
            <ng-container *ngIf="isNonOccurrenceTestPlan">
                <eff-button
                    *ngIf="stepPermissions.isEditAllowed"
                    text="Reject non occurrence"
                    size="large-size"
                    class="bottom-button missing-evidence-btn"
                    (click)="onRejectNonOccurrenceClick()"
                ></eff-button>
                <eff-button
                    *ngIf="stepPermissions.isEditAllowed"
                    text="Accept non occurrence"
                    size="large-size"
                    class="bottom-button final-conclusion-btn"
                    (click)="onAcceptNonOccurrenceClick()"
                ></eff-button>
            </ng-container>

            <ng-container *ngIf="!isNonOccurrenceTestPlan && stepPermissions.evidenceSaveAllowed">
                <eff-button
                    text="Reject: missing evidence"
                    size="large-size"
                    class="bottom-button missing-evidence-btn"
                    (click)="onMissingEvidenceClick()"
                ></eff-button>
                <eff-button
                    text="Final conclusion"
                    size="large-size"
                    class="bottom-button final-conclusion-btn"
                    (click)="onFinalConclusionClick()"
                ></eff-button>
            </ng-container>
        </div>
    </div>

    <div class="side-page-area">
        <eff-test-instructions-info-box
            [panelOpenState]="true"
            [samples]="samples"
            [effectivenessModel]="effectivenessModel"
        >
        </eff-test-instructions-info-box>
        <eff-evidence-instructions-info-box
            [samples]="samples"
            [instructions]="effectivenessModel.evidenceUploadInstructions"
        >
        </eff-evidence-instructions-info-box>
        <eff-source-document-info-box
            [effectivenessModel]="effectivenessModel"
            [hideSampling]="false"
            [stepPermissions]="stepPermissions"
        ></eff-source-document-info-box>
        <eff-control-info-box [controlGuid]="effectivenessModel.controlGuid"></eff-control-info-box>
    </div>
</div>

<cerrix-slide-over #cerrixSlideOver>
    <ng-template #title>
        <div class="paragraph">
            <div class="header">How to test</div>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="paragraph">
            <div class="header">
                1. Evaluate and score test steps (applicable for test plans with test steps)
            </div>
            <div class="content">
                <ol>
                    <li>
                        Download evidence:
                        <ol type="i">
                            <li>Navigate to the evidence document.</li>
                            <li>Click the “Download” button to retrieve the file.</li>
                        </ol>
                    </li>
                    <li>
                        Evaluate and score test steps:
                        <ol type="i">
                            <li>Analyse the evidence according to test step description.</li>
                            <li>Select an appropriate score based on your evaluation.</li>
                            <li>
                                It is recommended to give a comment on the basis of the assigned
                                score, ensuring to detail what specific elements were tested.
                            </li>
                            <li>
                                Be concise and clear in explaining the reasoning behind the score.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Save your evaluation and score:
                        <ol type="i">
                            <li>Click “Save changes” to save your score and comments.</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div class="paragraph">
            <div class="header">2. Evaluate and score samples</div>
            <div class="content">
                <ol>
                    <li>
                        Download evidence:
                        <ol type="i">
                            <li>Navigate to the evidence document.</li>
                            <li>Click the “Download” button to retrieve the file.</li>
                        </ol>
                    </li>
                    <li>
                        Evaluate and score sample:
                        <ol type="i">
                            <li>Analyse the evidence according to the test instruction.</li>
                            <li>Select an appropriate score based on your evaluation.</li>
                            <li>
                                Give a comment on the basis of the assigned score, ensuring to
                                detail what specific elements were tested.
                            </li>
                            <li>
                                Be concise and clear in explaining the reasoning behind the score.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Save your evaluation and score:
                        <ol type="i">
                            <li>Click “Save changes” to save your score and comments.</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div class="paragraph">
            <div class="content">
                Once test steps and samples have received scores and comments and have been saved,
                two buttons will become available. Please choose one of these two options
            </div>
        </div>
        <div class="paragraph">
            <div class="header">3. Missing evidence</div>
            <div class="content">
                <ol>
                    <li>
                        Identify missing evidence:
                        <ol type="i">
                            <li>Identify if any evidence is missing.</li>
                        </ol>
                    </li>
                    <li>
                        Notify evidence uploader:
                        <ol type="i">
                            <li>Click “Missing Evidence” to alert the evidence uploader.</li>
                            <li>
                                A pop-up will emerge, requiring you to specify the missing evidence.
                            </li>
                            <li>
                                Click “Confirm” to send the notification. The uploader will be
                                prompted to provide the missing evidence.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div class="paragraph">
            <div class="header">4. Final conclusion score</div>
            <div class="content">
                <ol>
                    <li>
                        Give a final conclusion score:
                        <ol type="i">
                            <li>Click the green “Give Final Conclusion Score” button.</li>
                            <li>
                                Select a Score according to the test norm and provide a comment.
                            </li>
                            <li>
                                Articulate the rationale behind the given score, offering a holistic
                                view of the entire test.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Complete Test Stage:
                        <ol type="i">
                            <li>
                                Click “Confirm” to complete the test or go to the next stage if a
                                reviewer is provided.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    </ng-template>
</cerrix-slide-over>
