import { ControlExportData } from "./models/ControlExportData";
import { nameof } from "@methods/jeffs-toolkit";
import { AdvancedEffectivenessExportData } from "./models/AdvancedEffectivenessExportData";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import {
    ParentChildButtonConfig,
    ParentChildFilterConfig,
    ParentChildSelectorConfig,
} from "@app/shared/models/ParentChildSelector/ParentChildSelectorConfig";

export class ControlCopyExportSharedMethods {
    public static createSelectorConfig(
        controls: ControlExportData[],
        buttonConfig: ParentChildButtonConfig[]
    ): ParentChildSelectorConfig {
        const filters: ParentChildFilterConfig[] = [
            this.getDateFilter("Start date", (e) => e.startDate),
            this.getDateFilter("End date", (e) => e.endDate),
            this.getMapChildFilter(controls, "Test status", (e) => e.statusDescription),
            this.getMapChildFilter(controls, "Overdue", (e) => e.isOverdue),
            this.getManyChildFilter(
                controls,
                "Source document uploaders",
                (e) => e.sourceDocumentUploaders
            ),
            this.getManyChildFilter(controls, "Evidence uploaders", (e) => e.evidenceUploaders),
            this.getManyChildFilter(controls, "Testers", (e) => e.testers),
            this.getManyChildFilter(controls, "Reviewers", (e) => e.reviewers),
        ];

        return {
            items: controls,
            hideToolbar: false,

            parentIdentifierProperty: nameof<ControlExportData>((c) => c.guid),
            childIdentifierProperty: nameof<AdvancedEffectivenessExportData>((c) => c.guid),

            headerProp: nameof<ControlExportData>((c) => c.header),

            childPrettyName: "all plans",
            childProperty: nameof<ControlExportData>((c) => c.effectivenessList),
            childNameProperty: nameof<AdvancedEffectivenessExportData>((eff) => eff.name),
            childToggleProperty: nameof<AdvancedEffectivenessExportData>((eff) => eff.isChecked),

            overviewProperties: [
                {
                    prettyName: "Identifier",
                    propertyName: nameof<ControlExportData>((c) => c.identifier),
                },
                {
                    prettyName: "Name",
                    propertyName: nameof<ControlExportData>((c) => c.name),
                },
                {
                    prettyName: "Organization",
                    propertyName: nameof<ControlExportData>((c) => c.organization),
                },
                {
                    prettyName: "Risks",
                    propertyName: nameof<ControlExportData>((c) => c.risks),
                },
            ],

            filters: filters,

            buttons: buttonConfig,
            extraToggles: [],

            filterMethod: (allItems: ControlExportData[]) => {
                ControlCopyExportSharedMethods.filterMethod(allItems, filters);
            },
        };
    }

    public static getDateFilter(
        prettyName: string,
        callbackFn: (obj: AdvancedEffectivenessExportData) => string
    ) {
        return {
            prettyName: prettyName,
            propertyName: nameof<AdvancedEffectivenessExportData>(callbackFn),
            filterType: GenericListFieldType.Date,
            childFilter: true,
        };
    }

    public static getMapChildFilter(
        controls: ControlExportData[],
        prettyName: string,
        callbackFn: (obj: AdvancedEffectivenessExportData) => string
    ): ParentChildFilterConfig {
        return {
            prettyName: prettyName,
            propertyName: nameof<AdvancedEffectivenessExportData>(callbackFn),
            filterType: GenericListFieldType.MultiSelect,
            childFilter: true,

            data: controls
                .selectMany<ControlExportData, string>((c) => c.effectivenessList.map(callbackFn))
                .distinct()
                .sort(),
        };
    }

    public static getManyChildFilter(
        controls: ControlExportData[],
        prettyName: string,
        callbackFn: (obj: AdvancedEffectivenessExportData) => string[]
    ): ParentChildFilterConfig {
        return {
            prettyName: prettyName,
            propertyName: nameof<AdvancedEffectivenessExportData>(callbackFn),
            filterType: GenericListFieldType.MultiSelect,
            childFilter: true,

            data: controls
                .selectMany<ControlExportData, string>((c) =>
                    c.effectivenessList.selectMany<AdvancedEffectivenessExportData, string>(
                        callbackFn
                    )
                )
                .distinct()
                .sort(),
        };
    }

    public static filterMethod(
        allControls: ControlExportData[],
        filters: ParentChildFilterConfig[]
    ): void {
        let filtered = false;
        allControls.forEach((control) => {
            control.hide = false;
            control.effectivenessList.forEach((tp) => {
                tp.hide = false;
            });
        });

        const startDateFilter = filters.find(
            (f) =>
                f.propertyName === nameof<AdvancedEffectivenessExportData>((eff) => eff.startDate)
        );
        const endDateFilter = filters.find(
            (f) => f.propertyName === nameof<AdvancedEffectivenessExportData>((eff) => eff.endDate)
        );
        if (startDateFilter.value && endDateFilter.value) {
            filtered = true;
            allControls.forEach((control) => {
                control.effectivenessList.forEach((tp) => {
                    tp.hide =
                        tp.startDate > endDateFilter.value || tp.endDate < startDateFilter.value;
                    tp.selected = tp.selected && !tp.hide;
                });
            });
        }

        filters.forEach((filter) => {
            if (
                filter.value &&
                (!Array.isArray(filter.value) || (filter.value as []).length > 0) &&
                filter.filterType === GenericListFieldType.MultiSelect
            ) {
                filtered = true;
                const fValues = filter.value as [];

                allControls.forEach((control) => {
                    if (filter.childFilter) {
                        control.effectivenessList.forEach((tp) => {
                            tp.hide = tp.hide || !this.multiCheck(fValues, tp[filter.propertyName]);
                            tp.selected = tp.selected && !tp.hide;
                        });
                    } else {
                        control.hide =
                            control.hide || !this.multiCheck(fValues, control[filter.propertyName]);
                    }
                });
            }
        });

        if (filtered) {
            allControls.forEach((control) => {
                control.hide = !(control.effectivenessList as []).some(
                    (tp: any) => tp.hide !== true
                );
            });
        }
    }

    public static multiCheck(fValues: any[], value: any): boolean {
        if (Array.isArray(value)) {
            return fValues.some((filterValue: any) => (value as any[]).indexOf(filterValue) >= 0);
        } else {
            return fValues.some((filterValue: any) => filterValue === value);
        }
    }
}
