import { FormPage } from "../detail";

export class WorkflowPageDefinition {
    ID: number;
    PageID: number;
    WorkflowDefinitionID: number;
    Groups: number[];
    LinkedToStarter: boolean;
    LinkedToPrevious: boolean;

    Page: FormPage;
}