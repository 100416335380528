import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable, firstValueFrom } from "rxjs";
import { WopiProofKeyInfo } from "../models/wopi-proof-key-info-model";
import { WopiDebugInfoModel } from "../models/wopi-debug-info-model";
import { WopiLockInfo } from "../models/wopi-lock-info-model";
import { WopiCurrentLockInfo } from "../models/wopi-current-lock-info-model";
import { WopiDiscoveryModel } from "../models/discovery/wopi-discovery-model";
import { wopiFileValidationModel } from "../models/wopiFileValidationModel";
import { WopiTransactionInfo } from "../models/wopi-transaction-info-model";

@Injectable({
    providedIn: "root",
})
export class WopiDebugDataService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "wopidebug");
    }

    public async enabled(): Promise<boolean> {
        let altUri = "/enabled";
        return await firstValueFrom(this.get(altUri));
    }

    public getProofKeyInfo(
        guid: string,
        start: Date,
        end: Date
    ): Observable<WopiDebugInfoModel<WopiProofKeyInfo>[]> {
        let altUri = "/logs/proofkey";
        if (!guid && !start && !end) {
            return this.get(altUri);
        }

        altUri += "?";
        let params: string[] = new Array();
        if (guid) {
            params.push(`guid=${guid}`);
        }
        if (start) {
            params.push(`start=${start}`);
        }
        if (end) {
            params.push(`end=${end}`);
        }

        altUri += params.join("&");
        return this.get(altUri);
    }

    public async deleteProofkeyLogs(): Promise<void> {
        let altUri = "/logs/proofkey";
        return await firstValueFrom(this.delete(altUri));
    }

    public getLockInfo(
        guid: string,
        start: Date,
        end: Date
    ): Observable<WopiDebugInfoModel<WopiLockInfo>[]> {
        let altUri = "/logs/lock";
        if (!guid && !start && !end) {
            return this.get(altUri);
        }

        altUri += "?";
        let params: string[] = new Array();
        if (guid) {
            params.push(`guid=${guid}`);
        }
        if (start) {
            params.push(`start=${start}`);
        }
        if (end) {
            params.push(`end=${end}`);
        }

        altUri += params.join("&");
        return this.get(altUri);
    }

    public async deleteLockLogs(): Promise<void> {
        let altUri = "/logs/lock";
        return await firstValueFrom(this.delete(altUri));
    }

    public getTransactionInfo(
        guid: string,
        start: Date,
        end: Date
    ): Observable<WopiDebugInfoModel<WopiTransactionInfo>[]> {
        let altUri = "/logs/transaction";
        if (!guid && !start && !end) {
            return this.get(altUri);
        }

        altUri += "?";
        let params: string[] = new Array();
        if (guid) {
            params.push(`guid=${guid}`);
        }
        if (start) {
            params.push(`start=${start}`);
        }
        if (end) {
            params.push(`end=${end}`);
        }

        altUri += params.join("&");
        return this.get(altUri);
    }

    public async deleteTransactionLogs(): Promise<void> {
        let altUri = "/logs/transaction";
        return await firstValueFrom(this.delete(altUri));
    }

    public getCurrentLockInfo(): Observable<WopiCurrentLockInfo[]> {
        let altUri = "/current/lock";
        return this.get(altUri);
    }

    public async getDiscoveryInfo(): Promise<WopiDiscoveryModel> {
        let altUri = "/discovery";
        return await firstValueFrom(this.get(altUri));
    }

    public async clearDiscoveryCache(): Promise<void> {
        let altUri = "/discovery/clear";
        return await firstValueFrom(this.post(null, altUri));
    }

    public async validateDocument(guid: string): Promise<wopiFileValidationModel> {
        const url = `/validateDocument/${guid}`;
        return firstValueFrom(this.get<wopiFileValidationModel>(url));
    }
}
