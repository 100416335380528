import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { FormLinkableModule } from "@app/forms/enums/FormLinkableModule";
import { Form } from "@app/forms/models";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { LinkableIconConfig } from "@app/shared/models/Linkable/LinkableIconConfig";
import { FormsLinkModel } from "@app/forms/models/other/FormsLinkModel";
import { nameof } from "@methods/jeffs-toolkit";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";

@Component({
    selector: "form-linkable",
    templateUrl: "./form-linkable.component.html",
    styleUrls: ["../overview/form-overview/form-overview.component.scss"],
})
export class FormLinkableComponent extends ItemLinkableBase {
    @Input() parentGuid: string;
    @Input() linkedModule: FormLinkableModule;
    @Input() displayValueProp: string;
    @Input() linked: FormsLinkModel[];
    @Input() unlinked: FormsLinkModel[];
    @Input() linkedForms: Form[];
    @Input() disabled: boolean = false;
    @Input() hideActions: boolean = false;
    @Input() hideFilter: boolean = false;
    @Input() allowAddNew: boolean = false;

    @ViewChild("linkTemplate") linkTemplate: TemplateRef<any>;

    showOverview: boolean = true;
    cleanSpacers: boolean = true;

    rowIconConfig: LinkableIconConfig[] = [
        {
            icon: "fas fa-circle green",
            actionColumn: nameof<FormsLinkModel>((c) => c.isActive),
            actionValue: true,
        },
        {
            icon: "fas fa-circle red",
            actionColumn: nameof<FormsLinkModel>((c) => c.isActive),
            actionValue: false,
        },
    ];

    constructor(
        protected _toastrService: ToastrService,
        protected _promptService: CerrixPromptService,
        private _tabService: TabService,
        private _pages: Pages
    ) {
        super(_toastrService, _promptService);
    }

    openLinkPopup() {
        this._promptService
            .prompt({
                maxWidth: 1200,
                maxHeight: 800,
                data: {
                    customTemplate: this.linkTemplate,
                    cancelButton: {
                        show: false,
                    },
                    confirmButton: {
                        uiid: "button-confirm-popup",
                    },
                    closeOnEsc: false,
                    confirmOnEnter: false,
                },
            })
            .onConfirm()
            .subscribe(() => {
                this.linkedChange.emit(this.linked);
            });
    }

    protected openForm(formLink: FormsLinkModel): void {
        if (!formLink.hasAccess) {
            return;
        }

        this._tabService.generateTab(this._pages.FormResults, formLink.id, "Results");
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return of(false);
    }

    openTab(item: any, hasAccess: boolean): void {}
}
