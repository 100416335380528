<div class="comments-container">
    <div class="comments-header">
        <span class="title">{{ title }}</span>
        <button mat-icon-button (click)="hideChat.emit()">
            <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
        </button>
    </div>

    <div #scrollContainer class="comments-body">
        <chat-comment
            *ngFor="let comment of comments; let i = index"
            [comment]="comment"
            [userColor]="userColors.get(comment.userId)"
            [triggerAfterRendered]="i === comments.length - 1"
            (afterRendered)="afterLastCommentDisplayed()"
        ></chat-comment>
    </div>

    <div *ngIf="canAdd" class="comments-footer">
        <div class="add-comment-container">
            <cerrix-textarea
                [placeholder]="placeholder"
                [formControl]="commentTextForm"
                [textareaAutosize]="true"
                [autosizeMinRows]="1"
                [autosizeMaxRows]="8"
            ></cerrix-textarea>
        </div>

        <button
            [disabled]="!commentTextForm.value || loading"
            mat-flat-button
            class="add-comment-button"
            (click)="onAddComment()"
        >
            <mat-icon fontSet="material-icons-outlined">send</mat-icon>
        </button>
    </div>
</div>
