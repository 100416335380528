import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DocumentModel } from "@models/documents/documentmodel";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentDataService } from "@services/http/document/DocumentDataService";

@Component({
    selector: "document-history",
    templateUrl: "./document-history.component.html",
    styleUrls: ["./document-history.component.scss"],
})
export class DocumentHistoryComponent implements OnChanges {
    @Input() guid: string;
    documentHistory: DocumentModel[];

    constructor(private _docDS: DocumentDataService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes["guid"];
        if (isGuid(change.currentValue) && change.currentValue !== change.previousValue) {
            this.loadHistory();
        }
    }

    loadHistory() {
        this._docDS.GetDocumentHistory(this.guid).then((history) => {
            this.documentHistory = history;
        });
    }
}
