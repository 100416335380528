import { Component, ElementRef, ViewChild } from "@angular/core";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { RiskWorkspaceModel } from "@app/risk/models/RiskWorkspaceModel";
import { nameof } from "@methods/CommonMethods";

@Component({
    selector: "risks-per-catalogue-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class RisksPerCatalogueWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private ds: RiskDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            nameof<RiskWorkspaceModel>("organizationID").toString();

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            nameof<RiskWorkspaceModel>("businessDimensionIDs").toString();

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.FrameworkDimension] =
            nameof<RiskWorkspaceModel>("frameworkDimensionIDs").toString();
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.ds.getRisksPerCatalogueWidgetInfo(filters).toPromise();

        this.config.noData = !data || data.length === 0 || !data.some((d) => d.y > 0);
        if (this.config.noData) {
            return null;
        }

        const config = this.getBaseChartConfig(null, [
            <any>{
                name: "Risk Catalogues",
                data,
            },
        ]);

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        config.xAxis = {
            type: "category",
        };

        config.yAxis = {
            min: 0,
            allowDecimals: false,
            title: {
                text: "Number of risks",
            },
        };

        return config;
    }

    protected async openWorkspace(ev) {
        const searchKeywords: SearchKeyword[] = await this.getSearchKeywords(ev.point.name);
        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.RiskOverview, preset);
    }

    private async getSearchKeywords(catalogue: string) {
        const searchKeywords: SearchKeyword[] = [
            this.getSearchKeyword(
                nameof<RiskWorkspaceModel>("riskCatalogue"),
                catalogue,
                ComparisonType.Contains
            ),
        ];

        return searchKeywords;
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.RiskOverview, preset);
    }
}
