import { DocumentModel } from "@models/documents/documentmodel";
import { TaskSeriesRecurrenceModel } from "./task-series-recurrence-model";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { IHasDocuments } from "@methods/DocumentMethods";
import { TaskType } from "../enums/TaskType";

export class TaskSeriesModel implements IHasDocuments {
    id: number;
    guid: string;
    identifier: string;
    name: string;
    description: string;
    creator: string;
    lastModifier: string;
    taskScoreOption: number;
    linkedTypes: TaskType[] = [];
    linkedIds: number[] = [];
    notificationPeriodIds: number[] = [];
    responsibleIds: number[] = [];
    reviewerIds: number[] = [];
    recurrence: TaskSeriesRecurrenceModel = new TaskSeriesRecurrenceModel();
    Documents: DocumentModel[];
    documentIdList: number[];
    Hyperlinks: HyperlinkModel[];
}
