import { Component, Input, OnInit } from "@angular/core";
import { EvidenceSampleEditModel } from "../../../shared/models";
import { AdvEffDataService } from "../../../shared/services";

@Component({
    selector: "eff-evidence-instructions-info-box",
    templateUrl: "./eff-evidence-instructions-info-box.component.html",
    styleUrls: ["./eff-evidence-instructions-info-box.component.scss"],
})
export class EffEvidenceInstructionsInfoBoxComponent implements OnInit {
    @Input() effectivenessGuid: string;
    @Input() samples: EvidenceSampleEditModel[];
    @Input() isCollapsed = false;
    @Input() instructions: string = "";
    @Input() panelOpenState: boolean = false;

    constructor(private _ds: AdvEffDataService) {}

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        if (!this.samples) {
            if (!this.isCollapsed && this.effectivenessGuid) {
                this._ds.getEvidenceSamples(this.effectivenessGuid).subscribe((data) => {
                    this.samples = data;
                });
            }
        }
    }
}
