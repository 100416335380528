import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { WopiDebugDataService } from "../../services/wopi-debug-data.service";
import { WopiLockInfo } from "../../models/wopi-lock-info-model";
import { WopiCurrentLockInfo } from "../../models/wopi-current-lock-info-model";
import { map, Observable } from "rxjs";

@Component({
    selector: "lock-info",
    templateUrl: "./lock-info.component.html",
    styleUrls: ["./lock-info.component.scss"],
})
export class LockInfoComponent implements OnInit {
    currentLockConfig: GenericListConfig;
    lockLogConfig: GenericListConfig;
    isEnabled = false;

    documentGuid: string;
    startDate: Date;
    endDate: Date;

    constructor(
        private _ds: WopiDebugDataService,
        private _prompt: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    ngOnInit() {
        this.createCurrentLockListConfig();
        this.createLockLogListConfig();
    }

    private getCurrentData(): Observable<WopiCurrentLockInfo[]> {
        let observable = this._ds.getCurrentLockInfo();
        var modeled = observable.pipe(
            map((data) => {
                let idCounter = 1;
                data.forEach((i) => {
                    try {
                        i.id = idCounter++;
                    } catch {}
                });
                return data;
            })
        );

        return modeled;
    }

    private getLogData(): Observable<WopiLockInfo[]> {
        var observable = this._ds.getLockInfo(null, null, null);
        var modeled = observable.pipe(
            map((data) => {
                let idCounter = 1;
                data.forEach((i) => {
                    try {
                        i.data = JSON.parse(i.jsonData);
                        i.data.id = idCounter++;
                        i.data.logDate = i.logDate;
                    } catch {}
                });
                return data.map((d) => d.data);
            })
        );

        return modeled;
    }

    private createCurrentLockListConfig() {
        this.currentLockConfig = <GenericListConfig>{
            name: "Current Locks",
            idProp: "id",
            dataMethod: () => this.getCurrentData(),
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xxl",
            hideEmptyResult: false,
            fields: [
                {
                    fieldName: "DocumentGuid",
                    prettyName: "Document Guid",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },

                {
                    fieldName: "LockId",
                    prettyName: "Lock Id ",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },

                {
                    fieldName: "ExpirationDate",
                    prettyName: "Expiration Date",
                    required: false,
                    fieldType: GenericListFieldType.DateTime,
                },
                {
                    fieldName: "VersionCreated",
                    prettyName: "Version Created",
                    required: false,
                    fieldType: GenericListFieldType.CheckBox,
                },
            ],
        };
    }

    private createLockLogListConfig() {
        this.lockLogConfig = <GenericListConfig>{
            name: "Lock Logs",
            idProp: "id",
            dataMethod: () => this.getLogData(),
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xxl",
            hideEmptyResult: false,
            fields: [
                {
                    fieldName: "logDate",
                    prettyName: "Date",
                    required: true,
                    fieldType: GenericListFieldType.DateTime,
                    hideInOverview: false,
                },
                {
                    fieldName: "DocumentGuid",
                    prettyName: "Document Guid",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },

                {
                    fieldName: "LockId",
                    prettyName: "Lock Id ",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },

                {
                    fieldName: "ExistingLockId",
                    prettyName: "Existing Lock Id",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Action",
                    prettyName: "Action",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Result",
                    prettyName: "Result",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },
            ],
        };
    }

    private async deleteLogs() {
        await this._ds.deleteLockLogs();
        this._toastr.success("Lock logs deleted");
    }
}
