<form novalidate [formGroup]="commentForm">
    <h2 mat-dialog-title>
        {{ title ? title : "Comment for this recommendation" }}
    </h2>
    <mat-dialog-content style="min-width: 750px">
        <div class="fancy-loader" *ngIf="loading"></div>
        <mat-tab-group
            *ngIf="!loading"
            (selectedTabChange)="tabChange($event)"
            mat-align-tabs="center"
            animationDuration="0ms"
        >
            <mat-tab label="Comment">
                <div class="container">
                    <div class="input-container pt-3">
                        <div *ngIf="showProgress">
                            <mat-label>Progress</mat-label>
                            <mat-slider min="0" max="100" step="5" thumbLabel showTickMarks
                                ><input matSliderThumb formControlName="progress" />
                            </mat-slider>
                        </div>

                        <mat-form-field *ngIf="showFeasibility">
                            <mat-label>Feasibility</mat-label>
                            <mat-select formControlName="feasibilityId">
                                <mat-option>None</mat-option>
                                <mat-option
                                    *ngFor="let feasibility of feasibilities"
                                    [value]="feasibility.id"
                                >
                                    {{ feasibility.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-label>Comment</mat-label>
                        <cerrix-textarea
                            data-uiid="moi-comments-comment-textarea"
                            errorLabel="Comment"
                            formControlName="comment"
                            [rows]="maxCommentRows"
                            [required]="commentRequired"
                        ></cerrix-textarea>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="History" *ngIf="comment.id && comment.id > 0">
                <div class="container">
                    <div class="fancy-loader" *ngIf="!histories"></div>
                    <table mat-table [dataSource]="histories" *ngIf="histories" class="col-md-12">
                        <ng-container matColumnDef="modifiedAt">
                            <th mat-header-cell *matHeaderCellDef>Edited on</th>
                            <td mat-cell *matCellDef="let history">
                                {{ history.modifiedAt | dateFormat }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let history">{{ history.action }}</td>
                        </ng-container>

                        <ng-container matColumnDef="modifiedBy">
                            <th mat-header-cell *matHeaderCellDef>Edited by</th>
                            <td mat-cell *matCellDef="let history">
                                {{ history.modifiedBy }} <br />
                                <i>{{ history.modifiedByRole }}</i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef>Comment</th>
                            <td mat-cell *matCellDef="let history">{{ history.comment }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let history; columns: columnsToDisplay"></tr>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button
            mat-button
            data-uiid="moi-comments-save-button"
            [disabled]="!commentForm.valid"
            (click)="saveClick()"
        >
            Save
        </button>
        <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</form>
