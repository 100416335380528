import { firstValueFrom } from "rxjs";
import { KriDataService } from "@app/kri/services/kri-data.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { Component, Input, OnInit } from "@angular/core";
import { KriDataGraphModel } from "../../models/kri-data-graph-model";

@Component({
    selector: "kri-data-graph-overview",
    templateUrl: "./kri-data-graph-overview.component.html",
    styleUrls: ["./kri-data-graph-overview.component.scss"],
})
export class KriDataGraphOverviewComponent implements OnInit {
    @Input() kriGuidsFromHtml: string[];

    tab: TabModel;
    kriGuids: string[];

    data: KriDataGraphModel[];

    constructor(private _ds: KriDataService) {}

    async ngOnInit() {
        if (this.kriGuidsFromHtml && this.kriGuidsFromHtml.length > 0) {
            this.kriGuids = this.kriGuidsFromHtml;
        } else if (this.tab && this.tab.config && this.tab.config.guids) {
            this.kriGuids = this.tab.config.guids;
        }

        if (this.kriGuids) {
            this.data = await firstValueFrom(this._ds.getMultipleDataGraphInfo(this.kriGuids));
        }

        if (this.tab) {
            this.tab.showLoader = false;
        }
    }
}
