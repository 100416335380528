import { Component, Input } from "@angular/core";
import { StepStateEnum, WorkflowStepConfig } from "./models/workflow-step-config";
import { EffectivenessPageType } from "../../../shared/enums";

@Component({
    selector: "eff-workflow-bar",
    templateUrl: "./eff-workflow-bar.component.html",
    styleUrls: ["./eff-workflow-bar.component.scss"],
})
export class EffWorkflowBarComponent {
    @Input() steps: WorkflowStepConfig[] = [];

    effectivenessPageType = EffectivenessPageType;

    getStepStyle(step: WorkflowStepConfig) {
        switch (step.State) {
            case StepStateEnum.Active:
                return "active-step";
            case StepStateEnum.Completed:
                return "completed-step";
            case StepStateEnum.Disabled:
                return "disabled-step";
        }
    }
}
