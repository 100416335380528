import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Configuration } from "@app/app.constants";
import { NotificationModel } from "@app/mailmessages/models/notificationModel";
import { NotificationService } from "@app/mailmessages/service/notification.service";
import { CerrixEditorComponent } from "@app/shared/components/cerrix-editor/cerrix-editor.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { ModuleType } from "@enums/ModuleType";
import { getFormControl } from "@methods/CommonMethods";
import { isEmpty } from "@methods/isEmpty";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
    selector: "uc-notification-detail",
    templateUrl: "./notification-detail.component.html",
    styleUrls: ["./notification-detail.component.scss"],
})
export class NotificationDetailComponent implements OnInit {
    @ViewChild("editor") editorComponent: CerrixEditorComponent;

    tab: TabModel;
    id: number;

    pageReady = false;
    module: ModuleType;
    isTaskModule: boolean = false;
    enableTypeField: boolean = false;

    notification: NotificationModel;
    keywords: string[];
    orgs: CerrixTreeItem[];
    mailEnabled: boolean;

    periods: IdNameCombination[] = [];
    types: IdNameCombination[] = [];
    subTypes: IdNameCombination[] = [];
    recipients: any[] = [];

    form: FormGroup;
    isEmpty = false;
    organizationRequest: Observable<CerrixTreeItem[]>;
    noOrganizationsSelectedDescription = Configuration.NoOrganizationsSelectedDescription;

    subjectHasFocus: boolean;

    constructor(
        private _mmService: NotificationService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {
        this.id = this._route.snapshot.params.id;
    }

    ngOnInit() {
        const config = this.tab.config;
        if (!config) {
            this.toastr.error("Error opening details.");
            this.tab.close(false);
            return;
        }
        this.module = config.module;
        this.isTaskModule = this.module === ModuleType.Tasks;
        this.enableTypeField = config.module === ModuleType.BIM || config.module === ModuleType.IM;

        this._mmService.getNotification(this.id, this.module).subscribe((notification) => {
            if (this.id === 0) {
                notification.period = null;
                notification.subType = null;
                if (this.isTaskModule) {
                    // pbi=12764: only here default changes .....
                    notification.active = true;
                    notification.subject = 'Notification for task " [TaskName] "';
                }
            }
            this.keywords = notification.keywords;
            this.notification = notification;
            this.mailEnabled = this.notification.active;

            this._mmService.getNotificationStandingData(this.module).subscribe((data) => {
                this.periods = data.periods;
                this.types = data.types;
                this.subTypes = data.subTypes;
                this.reloadRecipients(notification.subType);
                this.initFormGroup(notification);
                this.pageReady = true;
            });
        });
        this.organizationRequest = this._mmService.getOrganizations();
    }

    initFormGroup(notification: NotificationModel) {
        this.form = new FormGroup({
            name: new FormControl(notification.name, [Validators.required]),
            active: new FormControl(notification.active),
            recipients: new FormControl(notification.recipients),
            subject: new FormControl(notification.subject, [Validators.required]),
            organizations: new FormControl(notification.organizations),
        });

        if (this.enableTypeField)
            this.form.addControl("types", new FormControl(notification.types, Validators.required));

        if (!this.isTaskModule) {
            this.form.addControl(
                "period",
                new FormControl(notification.period, Validators.required)
            );
            this.form.addControl(
                "subType",
                new FormControl(notification.subType, Validators.required)
            );
        }

        this.form.valueChanges.subscribe(() =>
            FormValidationHelper.mapToModel(this.form, this.notification)
        );
    }

    onChange(message: string) {
        this.notification.message = message;
    }

    addKeyword(keyword: string) {
        if (this.subjectHasFocus) {
            const ctrl = getFormControl(this.form, "subject");
            const value = ctrl.value;
            ctrl.setValue(value + " [" + keyword + "]");
        } else {
            this.editorComponent.insertText(" [" + keyword + "]");
        }
    }

    reloadRecipients(item: IdNameCombination | number) {
        if (!item) {
            return;
        }

        let id = 0;
        if (typeof item === "number") {
            id = item;
        } else {
            id = item.ID;
        }

        this._mmService
            .getRecipients(this.module, id, this.notification.recipients)
            .subscribe((users) => {
                this.recipients = users;
            });
    }

    async save() {
        const emptyFroala = `<p><span class="fr-marker" data-id="0" data-type="false" style="display: none; line-height: 0;">​</span><span class="fr-marker" data-id="0" data-type="true" style="display: none; line-height: 0;">​</span><br></p>`;

        this.isEmpty = false;
        if (!this.notification.organizations) {
            this.notification.organizations = [];
        }

        const messageEmpty =
            isEmpty(this.notification.message) || this.notification.message === emptyFroala;

        if (this.form.valid && !messageEmpty) {
            this.notification.id = this.id;

            const savingPrompt = this._promptService.loader("Saving changes, please wait...");
            await this._mmService
                .saveNotification(this.notification)
                .pipe(finalize(() => savingPrompt.close()))
                .toPromise()
                .then((model: NotificationModel) => {
                    this.id = model.id;
                    this._tabService.refresh(this._pages.MailMessages);

                    this.toastr.success("", "Update completed");
                })
                .catch((_) => {
                    this.toastr.error("", "Update failed");
                });
        } else {
            FormValidationHelper.markAllAsTouched(this.form);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.form);
            let validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);

            if (messageEmpty) {
                this.isEmpty = true;
                validationMessage += "<br> - Message is required";
            }

            this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }

    deleteClick() {
        this._mmService.deleteNotification(this.id).subscribe((_) => {
            this._tabService.refresh(this._pages.MailMessages);
            this.toastr.success("Notification deleted");
            this.tab.close(false);
        });
    }

    validate() {
        let isValid = true;
        const errors: string[] = [];
        if (isEmpty(this.notification.name)) {
            errors.push("'Name' is required");
            isValid = false;
        }

        if (isEmpty(this.notification.subject)) {
            errors.push("'Subject' is required");
            isValid = false;
        }

        if (errors.any()) {
            this.toastr.warning(errors.join("<br />"), "Validations", { enableHtml: true });
        }

        return isValid;
    }

    setFocusToSubject() {
        this.subjectHasFocus = true;
    }

    deFocusSubject() {
        this.subjectHasFocus = false;
    }
}
