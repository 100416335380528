import { Component, ViewChild } from "@angular/core";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { SentNotificationOverviewComponent } from "../sent/overview/sentnotification-overview.component";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "app-mail-overview",
    templateUrl: "./notification-maintenance.component.html",
    styleUrls: ["./notification-maintenance.component.scss"],
})
export class MailMessageComponent {
    @ViewChild("sentmails") sentmails: SentNotificationOverviewComponent;
    tab: TabModel;
    header = "Mail";
    moduleTypes = ModuleType;

    constructor(public permService: PermissionsService) {}
}
