import { IHasDocuments } from "@methods/DocumentMethods";
import { DocumentModel } from "./../../../../common/models/documents/documentmodel";
import { AuditUniverseAssessmentModel } from "./audit-universe-assessment-model";
import { AuditUniverseControlEnvScoreModel } from "./audit-universe-control-env-score-model";
import { AuditUniverseOverallRiskScoreResultModel as AuditUniverseOverallRiskScoreResultModel } from "./audit-universe-overall-risk-score-result-model";

export class AuditUniverseModel implements IHasDocuments {
    guid: string;
    id: number;
    identifier: string;

    name: string;
    description: string;
    auditor: string;

    impact: number;
    impactComment: string;

    createdBy: string;
    dateCreated: Date;
    dateUpdated: Date;

    organizations: number[];
    businessDimensions: number[];
    frameworkDimensions: number[];

    plannedYears: string;

    controlEnvironments: AuditUniverseControlEnvScoreModel[];
    overallRiskScoreResults: AuditUniverseOverallRiskScoreResultModel[];
    assessments: AuditUniverseAssessmentModel[];

    auditIds: number[];
    formIds: number[];
    Documents: DocumentModel[];

    constructor() {
        this.organizations = [];
        this.businessDimensions = [];
        this.frameworkDimensions = [];
        this.controlEnvironments = [];
        this.overallRiskScoreResults = [];
        this.assessments = [];
        this.auditIds = [];
        this.formIds = [];
        this.Documents = [];
    }
}
