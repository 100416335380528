import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RiskScoringStandingDataModel } from "@app/risk/models/RiskScoringStandingDataModel";
import { charToUpperCase, getFormControl, getFormValue, nameof } from "@methods/CommonMethods";

@Component({
    selector: "risk-quantitative-scoring",
    templateUrl: "./risk-quantitative-scoring.component.html",
    styleUrls: ["./risk-quantitative-scoring.component.scss"],
})
export class RiskQuantitativeScoringComponent implements OnInit, OnChanges {
    @Input() parentForm: FormGroup;

    @Input() impactRequest: Promise<RiskScoringStandingDataModel[]>;
    @Input() likelihoodRequest: Promise<RiskScoringStandingDataModel[]>;

    @Input() grossImpactActive: number;
    @Input() grossLikelihoodActive: number;

    @Input() netImpactActive: number;
    @Input() netLikelihoodActive: number;

    impactStandingDataModels: RiskScoringStandingDataModel[];
    likelihoodStandingDataModels: RiskScoringStandingDataModel[];

    activeGrossImpactModel: RiskScoringStandingDataModel;
    activeGrossLikelihoodModel: RiskScoringStandingDataModel;
    activeNetImpactModel: RiskScoringStandingDataModel;
    activeNetLikelihoodModel: RiskScoringStandingDataModel;

    grossAle: string;
    grossAleTimeout: any; //NodeJS.Timeout;
    grossAleCalculating = false;
    grossImpactValid = true;
    grossLikelihoodValid = true;

    netAle: string;
    netAleTimeout: any; //NodeJS.Timeout;
    netAleCalculating = false;
    netImpactValid = true;
    netLikelihoodValid = true;

    async ngOnInit() {
        if (this.impactRequest) {
            this.impactStandingDataModels = await this.impactRequest;
        }

        if (this.likelihoodRequest) {
            this.likelihoodStandingDataModels = await this.likelihoodRequest;
        }

        this.setActiveGrossModels();
        this.setActiveNetModels();

        this.calculateGross();
        this.calculateNet();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.impactStandingDataModels || !this.likelihoodStandingDataModels) {
            return;
        }

        const hasChanged = (change: SimpleChange): boolean => {
            return change && change.previousValue != change.currentValue;
        };

        const grossImpactChange =
            changes[nameof<RiskQuantitativeScoringComponent>("grossImpactActive")];
        const grossLikelihoodChange =
            changes[nameof<RiskQuantitativeScoringComponent>("grossLikelihoodActive")];
        if (hasChanged(grossImpactChange) || hasChanged(grossLikelihoodChange)) {
            this.setActiveGrossModels();
            this.calculateGross();
        }

        const netImpactChange =
            changes[nameof<RiskQuantitativeScoringComponent>("netImpactActive")];
        const netLikelihoodChange =
            changes[nameof<RiskQuantitativeScoringComponent>("netLikelihoodActive")];
        if (hasChanged(netImpactChange) || hasChanged(netLikelihoodChange)) {
            this.setActiveNetModels();
            this.calculateNet();
        }
    }

    private setActiveGrossModels() {
        if (this.grossImpactActive >= 0 && this.grossLikelihoodActive >= 0) {
            this.activeGrossImpactModel = this.impactStandingDataModels[this.grossImpactActive];
            this.activeGrossLikelihoodModel =
                this.likelihoodStandingDataModels[this.grossLikelihoodActive];

            this.setPrettyStandingDataText(this.activeGrossImpactModel);
            this.setPrettyStandingDataText(this.activeGrossLikelihoodModel, " %");
        } else {
            this.activeGrossImpactModel = null;
            this.activeGrossLikelihoodModel = null;
        }
    }

    private setActiveNetModels() {
        if (this.netImpactActive >= 0 && this.netLikelihoodActive >= 0) {
            this.activeNetImpactModel = this.impactStandingDataModels[this.netImpactActive];
            this.activeNetLikelihoodModel =
                this.likelihoodStandingDataModels[this.netLikelihoodActive];

            this.setPrettyStandingDataText(this.activeNetImpactModel);
            this.setPrettyStandingDataText(this.activeNetLikelihoodModel, " %");
        } else {
            this.activeNetImpactModel = null;
            this.activeNetLikelihoodModel = null;
        }
    }

    private setPrettyStandingDataText(model: RiskScoringStandingDataModel, valueAppend?: string) {
        if (!valueAppend) {
            valueAppend = "";
        }

        let text: string;
        let minValue = (model.MinValue > 0 ? model.MinValue : 1) + valueAppend;
        let maxValue = model.MaxValue > 0 ? model.MaxValue + valueAppend : null;

        if (maxValue) {
            text = `Value must be between ${minValue} and ${maxValue}`;
        } else {
            text = `Value cannot be lower than ${minValue}`;
        }

        model._prettyRangeText = text;
        model._prettyPlaceholderText = `Inherited value: ${model.DefaultValue}`;
    }

    calculateGross() {
        this.planCalculation("gross");
    }

    calculateNet() {
        this.planCalculation("net");
    }

    private planCalculation(netOrGross: "gross" | "net") {
        const prefix = (value: string) => netOrGross + value;

        if (this[prefix("AleTimeout")]) {
            clearTimeout(this[prefix("AleTimeout")]);
            this[prefix("AleTimeout")] = null;
        } else {
            this[prefix("Ale")] = "";
            this[prefix("AleCalculating")] = true;
        }

        this[prefix("AleTimeout")] = setTimeout(() => {
            this.calculateMethod(netOrGross);
        }, 200);
    }

    private calculateMethod(netOrGross: "gross" | "net") {
        const prefix = (value: string) => netOrGross + value;

        let impactValue = getFormValue(this.parentForm, prefix("QuantitativeImpact"));
        let likelihoodValue = getFormValue(this.parentForm, prefix("QuantitativeLikelihood"));

        const impactModel = this.impactStandingDataModels[this[prefix("ImpactActive")]];
        const likelihoodModel = this.likelihoodStandingDataModels[this[prefix("LikelihoodActive")]];

        if (!impactValue && impactModel) {
            impactValue = impactModel.DefaultValue;
        }

        if (!likelihoodValue && likelihoodModel) {
            likelihoodValue = likelihoodModel.DefaultValue;
        }

        this.getAndSetFieldValidity(
            prefix("ImpactValid"),
            impactValue,
            impactModel,
            prefix("QuantitativeImpact"),
            charToUpperCase(netOrGross) + " Single loss Expectancy is out of allowed range."
        );

        this.getAndSetFieldValidity(
            prefix("LikelihoodValid"),
            likelihoodValue,
            likelihoodModel,
            prefix("QuantitativeLikelihood"),
            charToUpperCase(netOrGross) + " Likelihood % is out of allowed range."
        );

        if (this[prefix("ImpactValid")] && this[prefix("LikelihoodValid")]) {
            if (!impactValue) impactValue = 0;
            if (!likelihoodValue) likelihoodValue = 0;

            let aleValue = impactValue * (likelihoodValue / 100);
            aleValue = Math.round(aleValue * 100) / 100;

            this[prefix("Ale")] = "" + aleValue;
        } else {
            this[prefix("Ale")] = "Values are not valid";
        }

        this[prefix("AleCalculating")] = false;
        this[prefix("AleTimeout")] = null;
    }

    private getAndSetFieldValidity(
        validFieldName: string,
        value: number,
        model: RiskScoringStandingDataModel,
        controlName: string,
        invalidMessage: string
    ) {
        this[validFieldName] = this.validateValueWithinRange(value, model);

        const control = getFormControl(this.parentForm, controlName);
        if (!this[validFieldName]) {
            control.setErrors({
                outOfRange: invalidMessage,
            });
        } else {
            control.updateValueAndValidity();
        }
    }

    private validateValueWithinRange(value: number, model: RiskScoringStandingDataModel) {
        if (!model) return true;

        const minValue = model.MinValue > 0 ? model.MinValue : 1;
        const maxValue = model.MaxValue;

        const tooLow = value < minValue;
        const tooHigh = maxValue > 0 && value > maxValue;

        return !tooLow && !tooHigh;
    }
}
