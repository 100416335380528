import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Configuration } from "@app/app.constants";
import { MailMessageModel } from "@app/mailmessages/models/mailMessageModel";
import { MailMessageService } from "@app/mailmessages/service/mailmessage.service";
import { CerrixEditorComponent } from "@app/shared/components/cerrix-editor/cerrix-editor.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { Pages } from "@constants/pages/Pages";
import { isEmpty } from "@methods/isEmpty";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

@Component({
    selector: "uc-mailmessages-detail",
    templateUrl: "./mailmessages-detail.component.html",
    styleUrls: ["./mailmessages-detail.component.scss"],
})
export class MailMessageDetailComponent implements OnInit {
    @ViewChild("editor") editorComponent: CerrixEditorComponent;
    tab: TabModel;
    tabID: string;
    id: number;

    pageReady = false;

    mailMessage: MailMessageModel;
    keywords: string[];
    orgs: CerrixTreeItem[];
    // mailEnabled: boolean;

    form: FormGroup;
    isEmpty = false;

    organizationRequest: Observable<CerrixTreeItem[]>;
    noOrganizationsSelectedDescription = Configuration.NoOrganizationsSelectedDescription;

    constructor(
        private _mmService: MailMessageService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages
    ) {
        this.id = this._route.snapshot.params.id;
    }
    ngOnInit() {
        this._mmService.getMailMessage(this.id).subscribe((mail) => {
            this.keywords = mail.Keywords;
            this.mailMessage = mail;

            this.initFormGroup(this.mailMessage);
            this.pageReady = true;
        });

        this.organizationRequest = this._mmService.getOrganizations();
    }

    initFormGroup(mail: MailMessageModel) {
        this.form = new FormGroup({
            subject: new FormControl(mail.Subject, [Validators.required]),
            OrganizationIdList: new FormControl(mail.OrganizationIdList),
        });

        this.form.valueChanges.subscribe(() =>
            FormValidationHelper.mapToModel(this.form, this.mailMessage)
        );
    }

    onChange(value: string) {
        this.mailMessage.Message = value;
    }

    addKeyword(keyword: string) {
        this.editorComponent.insertText(" [" + keyword + "]");
    }

    save() {
        const emptyFroala = `<p><span class="fr-marker" data-id="0" data-type="false" style="display: none; line-height: 0;">​</span><span class="fr-marker" data-id="0" data-type="true" style="display: none; line-height: 0;">​</span><br></p>`;

        this.isEmpty = false;

        if (!this.mailMessage.OrganizationIdList) {
            this.mailMessage.OrganizationIdList = [];
        }

        this.mailMessage.OrganizationsCSV = this.mailMessage.OrganizationIdList.join(";");
        const messageEmpty =
            isEmpty(this.mailMessage.Message) || this.mailMessage.Message === emptyFroala;

        if (this.form.valid && !messageEmpty) {
            this._mmService.saveMailMessage(this.mailMessage).subscribe((_) => {
                this.toastr.success("", "Update completed");
                this._tabService.refresh(this._pages.MailMessages);
            });
        } else {
            FormValidationHelper.markAllAsTouched(this.form);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.form);
            let validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);

            if (messageEmpty) {
                this.isEmpty = true;
                validationMessage += "<br> - Message is required";
            }

            this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }
}
