import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "../../app.constants";
import { KeyValue } from "@models/generic/KeyValuePair";
import { RoleMaintenanceModel, RoleRightPatchModel } from "../shared/RoleRightMaintenanceModel";
import { firstValueFrom } from "rxjs";

@Injectable()
export class DevManagementDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "devmanagement");
    }

    getImportTypes() {
        const url = "/imports";
        return this.get<KeyValue<number, string>[]>(url);
    }

    import(type: number, file: File) {
        const formData = new FormData();
        formData.append("file", file);

        const url = "/imports/" + type;
        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");

        return this.post<KeyValue<string, boolean>[]>(formData, url);
    }

    getExportTypes() {
        const url = "/exports";
        return this.get<KeyValue<number, string>[]>(url);
    }

    export(type: number) {
        const url = `/exports/${type}`;
        this.download(url);
    }

    getAvailableExecutables() {
        const url = `/executables`;
        return this.get<KeyValue<number, string>[]>(url);
    }

    executeExecutable(executionID: number, asTestrun: boolean) {
        const url = `/executables/${executionID}?asTestRun=${asTestrun === true}`;
        return this.get<string>(url);
    }

    //#region Role Right patching

    getRoleRightDefinitions() {
        const url = `/roles`;
        return this.get<RoleMaintenanceModel[]>(url);
    }

    getRoleRightPatches() {
        const url = `/roles/patches`;
        return this.get<RoleRightPatchModel[]>(url);
    }

    setRoleRightDefinition(roleID: number, rightID: number) {
        const url = `/roles/patches/${roleID}/${rightID}`;
        return this.post(null, url);
    }

    deleteRoleRightDefinition(roleID?: number, rightID?: number) {
        if (!roleID) {
            roleID = null;
        }
        if (!rightID) {
            rightID = null;
        }

        const url = `/roles/patches/${roleID}/${rightID}`;
        return this.delete(url);
    }

    reloadSiteConfigurations() {
        const url = "/actions/reload-site-config";
        return this.get<any>(url);
    }

    reloadUsers() {
        const url = "/actions/reload-users";
        return this.get<any>(url);
    }

    reloadDbSettings() {
        const url = "/actions/reload-db-settings";
        return this.get<any>(url);
    }

    triggerNotifications() {
        const url = "/actions/trigger-notifications";
        return this.get<any>(url);
    }

    testScheduler() {
        const url = "/actions/test-scheduler";
        return this.get<any>(url);
    }

    //#endregion

    async getApplicationKeys(): Promise<any> {
        const url = "/appkeys";
        return await firstValueFrom(this.get<any>(url));
    }

    async generateApplicationKey(keyType: string, reason: string): Promise<any> {
        const url = `/appkeys/generate`;
        let keyModel = {
            KeyType: keyType,
            Reason: reason,
        };
        return await firstValueFrom(this.post<any>(keyModel, url));
    }
}
