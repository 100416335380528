import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormField, FormFieldConfig } from '@app/forms/models/detail';
import { parseTyped } from '@methods/CommonMethods';

@Component({
    selector: "form-entry-date",
    templateUrl: "./form-entry-date.component.html",
    styleUrls: ["../../form-entry-field.component.scss"]
})
export class FormEntryDateComponent implements OnInit {
    @Input() field: FormField;
    @Input() readonly: boolean;
    @Input() answer: string;
    @Output() answerChange = new EventEmitter<string>();

    dateSettings: FormFieldConfig;

    ngOnInit() {
        this.dateSettings = parseTyped<FormFieldConfig>(this.field.CustomSettings, new FormFieldConfig());
    }

    fieldChange() {
        this.answerChange.emit(this.answer);
    }
}