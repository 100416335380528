import { MatIconModule } from "@angular/material/icon";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CerrixFileDropDirective } from "../directives/cerrix-file-drop.directive";

@Component({
    selector: "cerrix-drop-area",
    templateUrl: "./cerrix-drop-area.component.html",
    styleUrls: ["./cerrix-drop-area.component.scss"],
    standalone: true,
    imports: [CommonModule, MatIconModule, CerrixFileDropDirective],
    providers: [],
})
export class CerrixDropAreaComponent {
    @Input() allowedFileExtensions: string;
    @Output() onFileSelection: EventEmitter<{ fileList: FileList; isBrowse: boolean }> =
        new EventEmitter();

    @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

    protected openFileSelect() {
        this.fileInput.nativeElement.click();
    }

    protected filesSelection() {
        this.onFileSelection.emit({ fileList: this.fileInput.nativeElement.files, isBrowse: true });
        this.fileInput.nativeElement.value = "";
    }

    protected fileDropHandler(fileList: FileList) {
        this.onFileSelection.emit({ fileList, isBrowse: false });
    }
}
