import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { WopiDebugDataService } from "../../services/wopi-debug-data.service";
import { WopiTransactionInfo } from "../../models/wopi-transaction-info-model";
import { map, Observable } from "rxjs";

@Component({
    selector: "transaction-info",
    templateUrl: "./transaction-info.component.html",
    styleUrls: ["./transaction-info.component.scss"],
})
export class TransactionInfoComponent implements OnInit {
    config: GenericListConfig;
    isEnabled = false;

    documentGuid: string;
    startDate: Date;
    endDate: Date;

    constructor(private _ds: WopiDebugDataService, private _toastr: ToastrService) {}

    ngOnInit() {
        this.createListConfig();
    }

    private getData(): Observable<WopiTransactionInfo[]> {
        var observable = this._ds.getTransactionInfo(null, null, null);
        var modeled = observable.pipe(
            map((data) => {
                let idCounter = 1;
                data.forEach((i) => {
                    try {
                        i.data = JSON.parse(i.jsonData);
                        i.data.id = idCounter++;
                        i.data.logDate = i.logDate;
                        i.data.documentGuid = i.documentGuid;
                        i.data.requestUrl = i.requestUrl;
                        i.data.token = i.token;
                    } catch {}
                });
                return data.map((d) => d.data);
            })
        );

        return modeled;
    }

    private createListConfig() {
        this.config = <GenericListConfig>{
            name: "Transaction Logs",
            idProp: "id",
            dataMethod: () => this.getData(),
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xxl",
            hideEmptyResult: false,
            fields: [
                {
                    fieldName: "logDate",
                    prettyName: "Date",
                    required: true,
                    fieldType: GenericListFieldType.DateTime,
                    hideInOverview: false,
                },
                {
                    fieldName: "documentGuid",
                    prettyName: "Guid",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: false,
                },
                {
                    fieldName: "Action",
                    prettyName: "Action",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: false,
                },
                {
                    fieldName: "requestUrl",
                    prettyName: "Url",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: false,
                },
                {
                    fieldName: "ResponseStatusCode",
                    prettyName: "Response",
                    required: true,
                    fieldType: GenericListFieldType.Number,
                    hideInOverview: false,
                },

                {
                    fieldName: "token",
                    prettyName: "Token",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: true,
                },
                {
                    fieldName: "RequestHeaders",
                    prettyName: "Request Headers",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "ResponseHeaders",
                    prettyName: "Response Headers",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "ResponseData",
                    prettyName: "Response Data",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
            ],
        };
    }

    private async deleteLogs() {
        await this._ds.deleteTransactionLogs();
        this._toastr.success("Transaction logs deleted");
    }
}
