import { Component, ContentChild, TemplateRef, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "cerrix-slide-over",
    templateUrl: "./cerrix-slide-over.component.html",
    styleUrls: ["./cerrix-slide-over.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CerrixSlideOverComponent {
    @ContentChild("title") titleTemplate: TemplateRef<any>;
    @ContentChild("content") contentTemplate: TemplateRef<any>;

    showSlideOver: boolean = false;

    // Animation duration in seconds
    animationDuration: number = 0.3;
    slideOutAnimation: boolean = false;

    show() {
        this.slideOutAnimation = false;
        this.showSlideOver = true;
    }

    close() {
        this.slideOutAnimation = true;
        setTimeout(() => {
            this.showSlideOver = false;
        }, this.animationDuration * 1000 + 50);
    }
}
