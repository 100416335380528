export enum StandingDataType {
    // General
    Organization = 1,
    BusinessDimensions = 5,
    DocumentTypes = 10,
    HyperlinkTypes = 11,
    FrameworkDimensions = 15,

    IpWhitelistingGlobal = 50,
    IpWhitelistingPersonal = 55,

    // Risk
    RiskArea = 100,
    RiskCatalogue = 105,
    RiskAssessment = 110,
    RiskSubtype = 115,
    RiskCustomField = 120,

    RiskTreatment = 125,
    RiskLikelihood = 130,
    RiskImpact = 135,
    RiskImpactScales = 140,

    // Events
    LerReasonsNoNotification = 300,
    LerActiveInWhichSector = 305,
    LerNatureOfBreach = 310,
    LerPersonsInvolved = 315,
    LerConsequencesToCiaOfData = 320,
    LerNatureOfIncident = 324,
    LerConsequenceOfBreach = 330,
    LerPersonalDataTypes = 335,
    LerTypesOfBreach = 340,
    LerClassificationIncident = 345,
    LerBusinessLine = 350,
    LerFailureType = 355,
    LerEffect = 360,
    LerIncidentType = 365,
    LerReputationImpact = 370,
    LerEventCategory = 375,
    LerSpecialCategoriesOfPersonalData = 380,
    LerCustomFields = 385,
    LerDataBreachCustomFields = 386,

    // Control
    ControlSource = 400,
    ControlType = 405,
    ControlAspectIc = 410,
    ControlFunction = 415,
    ControlExecutionMethod = 420,
    ControlLinkedStandards = 425,
    ControlQualityAspects = 430,
    ControlCatalogue = 435,
    ControlFrequency = 440,
    ControlDiCatalogue = 445,
    ControlAdvancedEffectivenessCatalogue = 450,
    ControlSimpleEffectivenessCatalogue = 451,
    ControlScore = 455,
    ControlTestMethod = 460,
    ControlCustomFields = 465,

    // BIM
    BimValidationType = 500,
    BimAssessmentType = 505,
    BimSubject = 510,
    BimFeasibility = 515,
    BimPriority = 520,
    BimImplementationScore = 525,
    BimReportType = 530,

    // Forms
    FormGroup = 700,
    FormCategory = 705,

    // DM
    DmBasis = 1200,
    DmSourceDocuments = 1205,
    DmTransferSafeguard = 1210,
    DmRemovalPolicy = 1215,
    DmSensitivityOfProcessing = 1220,
    DmEntities = 1225,
    DmDataSubjects = 1230,
    DmSystems = 1235,
    DmDataStructures = 1240,
    DmConfidentiality = 1245,
    DmIntegrity = 1250,
    DmAvailability = 1255,

    // TP
    TpThirdPartyScore = 1500,
    TpService = 1505,
    TpContractStatus = 1510,
    TpRelationship = 1515,
    TpCriticality = 1520,

    TpStatusOfInvolvement = 1525,
    ThirdPartyCustomFields = 1530,
    BusinessAssessmentScore = 1600,
    ReviewType = 1700,
    FrameworkAssessmentScore = 1705,

    //#region  Auditing

    // Audit Universe
    AuditImpact = 1800,
    AuditOverallRiskScore = 1805,
    AuditAssessmentScore = 1810,
    AuditControlEnvironment = 1815,

    // Audit
    AuditType = 1900,
    AuditScore = 1905,
    AuditConfidentiality = 1910,
    AuditCriticality = 1915,

    AuditWorkflow = 1925,
    AuditRiskAssessmentFields = 1930,
    AuditContextCustomFields = 1935,

    // Findings
    AuditSeverity = 2000,
    AuditFindingFollowUp = 2010,

    //Tasks
    TaskScore = 2100,
    //#endregion Auditing
}
