import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { Injectable } from "@angular/core";
import { RendererType } from "@enums/RendererType";
import { BusinessNavigatorDataService } from "../business-navigator/services/business-navigator-data.service";
import { NavigatorConfigType } from "../enums/NavigatorConfigType";
import { FormatType } from "@enums/FormatType";
import { nameof } from "@methods/jeffs-toolkit";
import { BusinessNavigatorReviewModel } from "../business-navigator/models/business-navigator-review-model";

@Injectable({
    providedIn: "root",
})
export class NavGenericListFactory {
    constructor(
        public tabService: TabService,
        private _pages: Pages,
        private _businessDs: BusinessNavigatorDataService
    ) {}
    createConfig(
        moduleType: NavigatorConfigType,
        additionalParams: any | any[] = null
    ): GenericListConfig {
        let config;
        switch (moduleType) {
            case NavigatorConfigType.Risk:
                config = this.initConfig(NavigatorConfigType.Risk, moduleType);
                this.buildRiskConfigFields(config);
                break;
            case NavigatorConfigType.Control:
                config = this.initConfig(NavigatorConfigType.Control, moduleType);
                this.buildControlConfigFields(config);
                break;
            case NavigatorConfigType.LinkedControl:
                config = this.initConfig(NavigatorConfigType.LinkedControl, moduleType);
                this.buildLinkedControlConfigFields(config);
                break;
            case NavigatorConfigType.Event:
                config = this.initConfig(NavigatorConfigType.Event, moduleType);
                this.buildEventConfigFields(config);
                break;
            case NavigatorConfigType.Moi:
                config = this.initConfig(NavigatorConfigType.Moi, moduleType);
                this.setMoiConfigFields(config);
                break;
            case NavigatorConfigType.Kri:
                config = this.initConfig(NavigatorConfigType.Kri, moduleType);
                this.setKriConfigFields(config);
                break;
            case NavigatorConfigType.DataProcessing:
                config = this.initConfig(NavigatorConfigType.DataProcessing, moduleType);
                this.setDataProcessingConfigFields(config);
                break;

            case NavigatorConfigType.BusinessReview:
                config = this.initConfig(
                    NavigatorConfigType.BusinessReview,
                    moduleType,
                    "id",
                    "id",
                    false
                );
                this.setBusinessReviewConfigFields(config);
                break;

            case NavigatorConfigType.ThirdParty:
                config = this.initConfig(NavigatorConfigType.ThirdParty, moduleType);
                this.setThirdPartyConfigFields(config);
                break;
        }
        return config;
    }

    private initConfig(
        typeName: string,
        moduleType: NavigatorConfigType,
        id = "Guid",
        identifier = "Identifier",
        canOpenDetails = true
    ): GenericListConfig {
        const config = <GenericListConfig>{
            name: typeName,
            limitViewTo: 5,
            idProp: id,

            overviewRowActions: [],
            rendererConfig: [],
            fields: [],

            overviewSortBy: identifier,
            overviewSortAsc: true,
            hideRefreshButton: true,
            editOverride: (row) => {},
            dataMethod: () => {},
        };

        if (canOpenDetails) {
            config.overviewRowActions.push({
                icon: "fas fa-external-link-alt",
                tooltip: "Open details",
                clickEvent: (row: any) => {
                    this.openDetailsTab(row, moduleType);
                },
            });
        }

        return config;
    }

    private buildRiskConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Risk identifier")
            .addTextField("Risk name", "Name")
            .addTextField("Organization")
            .addTextField("Owner")
            .addTextField("GrossScoreColor", "GrossScoreColor", true)
            .addTextField("Gross score")
            .addTextField("Net score")
            .addTextField("NetScoreColor", "NetScoreColor", true)
            .addTextField("Overall risk assessment")
            .addTextField("RiskAssessmentColor", "RiskAssessmentColor", true)
            .addTextField("Risk appetite")
            .addTextField("RiskAppetiteAssessmentColor", "RiskAppetiteAssessmentColor", true)
            .addTextField("Connected controls", "Connected controls", true)
            .addScoreRenderer("Gross score", "GrossScoreColor")
            .addScoreRenderer("Net score", "NetScoreColor")
            .addScoreRenderer("Overall risk assessment", "RiskAssessmentColor")
            .addScoreRenderer("Risk appetite", "RiskAppetiteAssessmentColor")
            .build();
    }

    private buildControlConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Control identifier")
            .addTextField("Name")
            .addTextField("Is key control")
            .addTextField("Organization")
            .addTextField("Owner")
            .addTextField("Effectiveness score")
            .addTextField("Effectiveness Score Color", "Effectiveness Score Color", true)
            .addTextField("Latest Period Score")
            .addTextField("Latest Period Score Color", "Latest Period Score Color", true)
            .addScoreRenderer("Effectiveness score", "Effectiveness Score Color", true)
            .addScoreRenderer("Latest Period Score", "Latest Period Score Color", true)
            .build();
    }

    private buildLinkedControlConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Control identifier")
            .addTextField("Name")
            .addTextField("Organization")
            .addTextField("Owner")
            .addTextField("EffectivenessScore", "Effectiveness score")
            .addTextField("EffectivenessScoreColor", "Effectiveness Score Color", true)
            .addScoreRenderer("EffectivenessScore", "EffectivenessScoreColor", true)
            .build();
    }

    private buildEventConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Event identifier")
            .addTextField("Event name", "Name")
            .addTextField("Databreach")
            .addTextField("Organization")
            .addTextField("Net costs")
            .addTextField("Status")
            .addDateField("Date detected")
            .build();
    }

    private setKriConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "KRI identifier")
            .addTextField("Name")
            .addTextField("OrganizationNames", "Organization")
            .addTextField("Frequency", "KRI Frequency")
            .addTextField("LastDataPoint", "Last datapoint")
            .addTextField("LastDataPointColor", "LastDataPointColor", true)
            .addTextField("AggregateScore", "Aggregate Score")
            .addTextField("AggregateScoreColor", "AggregateScoreColor", true)
            .addScoreRenderer("LastDataPoint", "LastDataPointColor", true)
            .addScoreRenderer("AggregateScore", "AggregateScoreColor", true)
            .build();
    }

    private setMoiConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Moi identifier")
            .addTextField("Name")
            .addTextField("Organization Responsible", "Organization responsible")
            .addTextField("Status")
            .addTextField("Progress")
            .addTextField("Moi Type")
            .addDateField("Due Date", "Due Date")
            .addTextField("Is Overdue", null, true, true)
            .addProgressRenderer("Progress")
            .addDueDateRenderer("Due Date", "Is Overdue", "True", true)
            .build();
    }

    private setDataProcessingConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Data Processing identifier")
            .addTextField("Name")
            .addTextField("Organization")
            .build();
    }

    private setBusinessReviewConfigFields(config: GenericListConfig) {
        const scoreData = this._businessDs.getScores();

        config = new ConfigBuilder(config)
            .addDateField(
                nameof<BusinessNavigatorReviewModel>((x) => x.dateReviewed),
                "Date reviewed",
                true
            )
            .addColorSelect(
                nameof<BusinessNavigatorReviewModel>((x) => x.scoreId),
                scoreData,
                "Score"
            )
            .addTextField(
                nameof<BusinessNavigatorReviewModel>((x) => x.reviewer),
                "Reviewer",
                false,
                true
            )
            .addTextArea(
                nameof<BusinessNavigatorReviewModel>((x) => x.comment),
                "Comment"
            )
            .addTextField(
                nameof<BusinessNavigatorReviewModel>((x) => x.organization),
                "Organization",
                false,
                true
            )
            .addScoreRenderer(
                nameof<BusinessNavigatorReviewModel>((x) => x.scoreName),
                nameof<BusinessNavigatorReviewModel>((x) => x.scoreColor)
            )
            .build();

        config.allowAdd = true;
        config.allowEdit = true;
        config.editOverride = null;
        config.overviewSortAsc = false;
        config.overviewSortBy = "id";
    }

    private setThirdPartyConfigFields(config: GenericListConfig) {
        config = new ConfigBuilder(config)
            .addTextField("Identifier", "Third Party identifier")
            .addTextField("Name")
            .addTextField("Organizations in use")
            .addTextField("Responsible")
            .addTextField("Contracted value")
            .addTextField("Reviewer")
            .addDateField("Date tested", "Date reviewed", true)
            .addTextField("Review score")
            .addTextField("ReviewScoreColor", "ReviewScoreColor", true)
            .addScoreRenderer("Review score", "ReviewScoreColor", true)
            .build();
    }

    private openDetailsTab(row: any, moduleType: NavigatorConfigType) {
        let tab;
        switch (moduleType) {
            case NavigatorConfigType.Risk:
                tab = this.tabService.getTab(this._pages.RiskDetail, row.Guid);
                break;
            case NavigatorConfigType.Event:
                tab = this.tabService.getTab(this._pages.EventDetail, row.Guid);
                break;
            case NavigatorConfigType.LinkedControl:
            case NavigatorConfigType.Control:
                tab = this.tabService.getTab(this._pages.ControlDetail, row.Guid);
                break;
            case NavigatorConfigType.Moi:
                tab = this.tabService.getTab(this._pages.MoiDetail, row.Guid);
                break;
            case NavigatorConfigType.Kri:
                tab = this.tabService.getTab(this._pages.KriDetail, row.Guid);
                break;
            case NavigatorConfigType.DataProcessing:
                tab = this.tabService.getTab(this._pages.DataManagement, row.Guid);
                break;
            case NavigatorConfigType.ThirdParty:
                tab = this.tabService.getTab(
                    this._pages.ThirdpartyDetail,
                    row.Guid,
                    `(TP) - ${row.Name}`
                );
                break;
        }
        this.tabService.addTab(tab);
    }
}

class ConfigBuilder {
    config: GenericListConfig = {};
    constructor(config: GenericListConfig) {
        this.config = config;
    }

    addTextField(
        fieldName: string,
        prettyName?: string,
        hideInOverview = false,
        hideInEdit = false
    ) {
        this.config.fields.push({
            fieldName,
            prettyName,
            fieldType: GenericListFieldType.Text,
            hideInEdit,
            hideInOverview,
        });
        return this;
    }

    addTextArea(fieldName: string, prettyName?: string, required = true, editorWidth: number = 12) {
        this.config.fields.push({
            fieldName,
            prettyName,
            fieldType: GenericListFieldType.TextArea,
            required,
            editorWidth: editorWidth,
        });
        return this;
    }

    addDateField(fieldName: string, prettyName?: string, hideInEdit = false) {
        this.config.fields.push({
            fieldName,
            prettyName,
            fieldType: GenericListFieldType.Date,
            hideInEdit,
        });
        return this;
    }

    addDateTimeField(fieldName: string, prettyName?: string, hideInEdit = false) {
        this.config.fields.push({
            fieldName,
            prettyName,
            fieldType: GenericListFieldType.DateTime,
            hideInEdit,
        });
        return this;
    }

    addSingleSelectField(
        fieldName: string,
        data: any,
        prettyName?: string,
        required = true,
        editorWidth: number = 12
    ) {
        this.config.fields.push({
            fieldName,
            prettyName,
            fieldType: GenericListFieldType.SingleSelect,
            getDataMethod: () => data,
            required,
            editorWidth: editorWidth,
        });
        return this;
    }

    addColorSelect(
        fieldName: string,
        data: any,
        prettyName?: string,
        required = true,
        editorWidth: number = 12
    ) {
        this.config.fields.push({
            fieldName,
            prettyName,
            fieldType: GenericListFieldType.ColoredSelect,
            getDataMethod: () => data,
            required,
            editorWidth: editorWidth,
        });
        return this;
    }

    addScoreRenderer(textColumn: string, actionColumn?: string, hideActionColumn?: boolean) {
        this.config.rendererConfig.push({
            textColumn,
            actionColumn,
            hideActionColumn,
            type: RendererType.Score,
        });
        return this;
    }

    addProgressRenderer(textColumn: string, actionColumn?: string, hideActionColumn?: boolean) {
        this.config.rendererConfig.push({
            textColumn,
            actionColumn,
            hideActionColumn,
            type: RendererType.Progress,
        });
        return this;
    }

    addDueDateRenderer(
        textColumn: string,
        actionColumn?: string,
        actionValue?: string,
        hideActionColumn?: boolean
    ) {
        this.config.rendererConfig.push({
            textColumn,
            actionColumn,
            actionValue,
            hideActionColumn,
            type: RendererType.DueDate,
            formatType: FormatType.DateFormat,
        });
        return this;
    }

    build() {
        return this.config;
    }
}
