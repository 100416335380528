<div class="audit-finding-linked-audit-info">
    <div class="detail-page-card" *ngIf="linkInfo">
        <div class="detail-page-card-title">Linked Audit</div>
        <div class="detail-page-card-body padded form-group-styling row">
            <div class="form-group col-6">
                <label class="form-label">Audit</label>
                <div class="input-group">
                    <input
                        type="text"
                        value="{{ +linkInfo.identifier }} - {{ linkInfo.auditName }}"
                        class="form-control form-control-sm"
                        readonly
                    />
                    <label class="input-group-text action-button" (click)="openAudit()">
                        <i class="fas fa-external-link-alt"></i>
                    </label>
                </div>
            </div>

            <div class="col-6">
                <generic-list-manager
                    *ngIf="tableConfig"
                    [config]="tableConfig"
                ></generic-list-manager>
            </div>
        </div>
    </div>
</div>
