import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TaskScoreOptions } from "@app/tasks/enums/TaskScoreOptions";
import { TaskType } from "@app/tasks/enums/TaskType";
import { TaskWorkflowStatus } from "@app/tasks/enums/TaskWorkflowStatus";
import { BaseTaskModel } from "@app/tasks/models/BaseTaskModel";
import { TaskPermissionModel } from "@app/tasks/models/TaskPermissionsModel";
import { TaskStandingDataModel } from "@app/tasks/models/TaskStandingDataModel";
import { TaskDataService } from "@app/tasks/services/TaskDataService";
import { TargetModule } from "@enums/document/TargetModule";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "task-detail-main",
    templateUrl: "./task-detail-main.component.html",
    styleUrls: ["./task-detail-main.component.scss"],
})
export class TaskDetailMainComponent implements OnInit {
    @Input() cerrixTab: TabModel;
    @Input() model: BaseTaskModel;
    @Input() formGroup: FormGroup;
    @Input() permissions: TaskPermissionModel;
    @Input() standingData: TaskStandingDataModel;

    @Input() showLinkedControls: boolean = false;
    @Input() isReviewEnabled = false;

    @Output() onLinkedTypeChange = new EventEmitter<IdNameCombination[]>();
    TaskWorkflowStatus = TaskWorkflowStatus;
    TaskScoreOptions = TaskScoreOptions;

    assessmentTypesLoading = true;
    dateToday = new Date();

    //docs
    documents: DocumentModel[];
    targetModule = TargetModule.Control;

    get showWorkflowComment(): boolean {
        if (!this.model || !this.model.workflowComment) {
            return false;
        }

        if (this.isReviewEnabled) {
            return true;
        }

        if (this.model.workflowState != TaskWorkflowStatus.Done) {
            return true;
        }

        return false;
    }

    constructor(private _ds: TaskDataService) {}

    ngOnInit() {
        this.loadDocuments();
    }

    //#region Events

    linkedTypesChanged(selection: IdNameCombination[]) {
        this.onLinkedTypeChange.emit(selection);
        let showLinkedControls = false;

        // Only loop once
        selection.forEach((element) => {
            if (element.ID == TaskType.ControlExecution) {
                showLinkedControls = this.setBoolValue(showLinkedControls, true);
            }
        });

        this.showLinkedControls = showLinkedControls;
    }

    // Make sure to only set to false if not set to true by another type
    setBoolValue(currentValue: boolean, newValue: boolean): boolean {
        if (currentValue == true && newValue == false) {
            return currentValue;
        }

        return newValue;
    }

    //#endregion Events

    loadDocuments(): void {
        if (this.model.guid && isGuid(this.model.guid)) {
            if (!this.documents) {
                this._ds.getDocuments(this.model.guid.toString()).subscribe((documents) => {
                    this.documents = documents;
                    this.model.Documents = documents;
                });
            } else {
                this.documents = [];
            }
        }
    }
}
