<div *ngIf="model">
    <div class="main-area">
        <div class="main-content">
            <div class="cerrix-column">
                <div class="title-bar">
                    <div class="title">Audit Templates</div>
                </div>

                <small>Upload templates to be used in a audit to generate a document.</small>

                <div class="button-bar">
                    <button class="cerrix-button secondary" (click)="save()">
                        <span>Save changes</span>
                        <i class="far fa-save"></i>
                    </button>
                </div>
            </div>

            <document-manager
                [(Documents)]="model.documents"
                [DocumentTypeTargets]="documentTargetModule"
                (DocumentsChange)="onDocumentsChange($event)"
                ForcedExtensions=".docx"
            >
            </document-manager>
        </div>

        <div class="side-area">
            <document-templates-merge-fields
                [targetModule]="targetModule"
            ></document-templates-merge-fields>
            <document-templates-mail-merge-info></document-templates-mail-merge-info>
        </div>
    </div>
</div>
