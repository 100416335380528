import { Input, Output, EventEmitter, Directive } from "@angular/core";
import { EventModel } from "@models/event/EventModel";
import { EventDataService } from "@app/event/services/EventDataService";
import { EventRiskControlModel } from "@app/event/shared/event-risk-control-model";
import { ModalOptions, BsModalService } from "ngx-bootstrap/modal";
import { EventRiskControlDialogComponent } from "../event-risk-control-dialog/event-risk-control-dialog.component";
import { FormGroup } from "@angular/forms";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { Observable } from "rxjs";
import { getFormValue } from "@methods/CommonMethods";

@Directive()
export abstract class BaseEventRiskControl {
    @Input() parentForm: FormGroup;
    @Input() disabled: boolean;
    @Input() eventModel: EventModel;
    @Output() linkChanged = new EventEmitter<never>();

    linked: EventRiskControlModel[];
    unlinked: EventRiskControlModel[];

    isLoading = true;
    previousOrganizationId: number;

    failureTypes: IdNameCombination[];

    hasAccessChecks = {};
    accessCheckInProgress = false;

    abstract getLinkable(guid: string, organizationId: number): Observable<EventRiskControlModel[]>;
    abstract linkedItemsChanged(linked: EventRiskControlModel[]);
    abstract getLinked(): number[];

    constructor(protected _eventDS: EventDataService, private _modalService: BsModalService) {}

    private loadData(linkedIds: number[], items: EventRiskControlModel[]) {
        const linked = [],
            unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }

    load() {
        const currentOrganizationId = getFormValue<EventModel>(this.parentForm, "OrganizationId");
        if (
            (this.unlinked || this.linked) &&
            this.previousOrganizationId === +currentOrganizationId
        ) {
            return;
        }

        this.previousOrganizationId = currentOrganizationId;
        this.getLinkable(this.eventModel.Guid, currentOrganizationId).subscribe((data) => {
            const linkedIds = this.getLinked();
            this.loadData(linkedIds, data);
            this.isLoading = false;
        });
    }

    editLinked(item: EventRiskControlModel) {
        if (!this.failureTypes) {
            this._eventDS.getFailureTypes().subscribe((failureTypes) => {
                this.failureTypes = failureTypes;

                this.openEditDialog(item);
            });
        } else {
            this.openEditDialog(item);
        }
    }

    openEditDialog(control: EventRiskControlModel) {
        const config = <ModalOptions<EventRiskControlDialogComponent>>{
            backdrop: "static",
            class: "comments-dialog",

            initialState: <EventRiskControlDialogComponent>{
                riskControlModel: control,
                eventGuid: this.eventModel.Guid,
                failureTypes: this.failureTypes,
            },
        };

        this._modalService
            .show(EventRiskControlDialogComponent, config)
            .content.afterClosed.subscribe((changedItem) => {
                const controlIndex = this.linked.findIndex((x) => x.id === changedItem.id);
                this.linked[controlIndex] = changedItem;
                if (changedItem) {
                    this.updateDisplayItems(this.linked[controlIndex]);
                }
                this.linkedItemsChanged(this.linked);
            });
    }

    openBatchEdit() {
        if (!this.failureTypes) {
            this._eventDS.getFailureTypes().subscribe((failureTypes) => {
                this.failureTypes = failureTypes;

                this.openBatchEditDialog();
            });
        } else {
            this.openBatchEditDialog();
        }
    }

    openBatchEditDialog() {
        const config = <ModalOptions<EventRiskControlDialogComponent>>{
            backdrop: "static",
            class: "comments-dialog",

            initialState: <EventRiskControlDialogComponent>{
                eventGuid: this.eventModel.Guid,
                failureTypes: this.failureTypes,
                items: this.linked,
                isBatchEdit: true,
            },
        };

        this._modalService
            .show(EventRiskControlDialogComponent, config)
            .content.afterClosed.subscribe((changedItem) => {
                if (changedItem) {
                    const selectedControlIds: number[] = changedItem.batchControls;
                    selectedControlIds.forEach((cId) => {
                        const controlIndex = this.linked.findIndex((x) => x.id === cId);
                        const item = this.linked[controlIndex];
                        this.updateBatchDisplayItems(item, changedItem);
                        this.linked[controlIndex] = item;
                    });
                    this.linkedItemsChanged(this.linked);
                }
            });
    }

    updateBatchDisplayItems(itemToUpdate: EventRiskControlModel, newItem: EventRiskControlModel) {
        const comment = itemToUpdate.displayItems.find((x) => x.Header === "Comment");
        if (comment) {
            comment.Value = newItem.comment;
            itemToUpdate.comment = newItem.comment;
        }

        const failuretype = itemToUpdate.displayItems.find((x) => x.Header === "Failuretype");
        if (failuretype) {
            failuretype.Value = newItem.failureType;
            itemToUpdate.failureTypeId = newItem.failureTypeId;
        }
    }

    updateDisplayItems(item: EventRiskControlModel) {
        const comment = item.displayItems.find((x) => x.Header === "Comment");
        if (comment) {
            comment.Value = item.comment;
        }

        const failuretype = item.displayItems.find((x) => x.Header === "Failuretype");
        if (failuretype) {
            failuretype.Value = item.failureType;
        }
    }
}
