import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { IDashboardWidgetComponent } from "../shared/interfaces/IDashboardWidgetComponent";
import { DashboardWidgetModel } from "../shared/models/DashboardWidgetModel";

export abstract class BaseWidget implements IDashboardWidgetComponent {
    config: DashboardWidgetModel;
    globalFilters: any;
    filterKeys = WidgetFilterKey;

    get hasFilters(): boolean {
        return this.hasCustomConfig && this.config.customConfig.filterValues ? true : false;
    }

    get hasCustomConfig(): boolean {
        return this.config && this.config.customConfig ? true : false;
    }

    load() {
        this.bindApi();
        this.config.showLoader = false;
    }

    protected onFilterChange() {
        this.config.noData = false;
        this.config.noDataExplanation = "";

        this.load();
    }

    protected onResize() {
        this.load();
    }

    protected onGlobalOpenClick() {
        return;
    }

    protected bindApi() {
        if (!this.config.api) {
            this.config.api = {};
        }

        if (!this.config.api.onResizeCallback) {
            this.config.api.onResizeCallback = this.onResize.bind(this);
        }

        if (!this.config.api.onFilterChanged) {
            this.config.api.onFilterChanged = this.onFilterChange.bind(this);
        }

        if (!this.config.api.onGlobalFilterChange) {
            this.config.api.onGlobalFilterChange = this.onFilterChange.bind(this);
        }

        if (!this.config.api.onGlobalOpenClick) {
            this.config.api.onGlobalOpenClick = this.onGlobalOpenClick.bind(this);
        }
    }

    protected getFilters() {
        var filters = {};

        this.config.configurations.forEach((f) => {
            const key = f.fieldName;
            filters[key] = this.getFilter(key, f.defaultValue);
        });

        return filters;
    }

    protected getFilter(filterKey: string, defaultValue?: any) {
        let returnValue = defaultValue ? defaultValue : null;

        if (!this.hasCustomConfig) {
            return returnValue;
        }

        if (this.config.customConfig.globalFilterEnabled && this.globalFilters) {
            const globalValue = this.globalFilters[filterKey];
            if (globalValue != undefined && globalValue != null) {
                return globalValue;
            }
        }

        const configuredValue = this.config.customConfig.filterValues
            ? this.config.customConfig.filterValues[filterKey]
            : undefined;

        if (configuredValue != undefined && configuredValue != null) {
            return configuredValue;
        }

        return returnValue;
    }
}
