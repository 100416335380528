export enum MoiCommentType {
    Comment = 1,
    Reject = 2,
    Accept = 3,
    Confirm = 4,
    Decline = 5,
    Reopen = 8,
    DueDateChange = 9,
    ReadyForAcceptance = 10,
    Close = 11,
    Resubmit = 12,
}
