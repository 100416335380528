import { Component, Input, ViewChild } from "@angular/core";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { ControlTestScoreChartConfig } from "./ControlTestScoreChartConfig";
import { ControlChartDataService } from "./control-chart.data.service";
import { SimpleTestingType } from "../../testing/simple/enums/SimpleTestingType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ControlAdvEffChartComponent } from "./control-adv-eff-chart/control-adv-eff-chart.component";
import { ControlTestscoreChartComponent } from "./control-testscore-chart/control-testscore-chart.component";

@Component({
    selector: "control-test-result",
    templateUrl: "./control-test-result.component.html",
    styleUrls: ["./control-test-result.component.scss"],
})
export class ControlTestResultComponent {
    @Input() cerrixTab: TabModel;
    @Input() guid: string;
    @Input() permissions: ControlPermissionModel;

    @ViewChild("diChart") diChart: ControlTestscoreChartComponent;
    @ViewChild("effSimple") effSimple: ControlTestscoreChartComponent;
    @ViewChild("effAdv") effAdv: ControlAdvEffChartComponent;

    isLoaded = false;

    constructor(private ds: ControlChartDataService) {}

    async load(): Promise<void> {
        if (!this.guid || this.guid.length === 0) {
            return;
        }
        const createDiPromise = this.createDiChart();
        const createEffPromise = this.createEffChart();

        if (this.isLoaded) {
            return;
        }
        this.isLoaded = true;

        await createDiPromise;
        await createEffPromise;

        window.addEventListener("resize", () => {
            if (this.diChart && this.diChart.loadedChart) {
                this.diChart.loadedChart.reflow();
            }
            if (this.effSimple && this.effSimple.loadedChart) {
                this.effSimple.loadedChart.reflow();
            }
            if (this.effAdv && this.effAdv.chart && this.effAdv.chart.loadedChart) {
                this.effAdv.chart.loadedChart.reflow();
            }
        });
    }

    async createDiChart(): Promise<void> {
        if (!this.permissions.CanOpenDi) {
            return;
        }

        const data = await this.ds
            .getSimpleChart(SimpleTestingType.DesignImplementation, this.guid)
            .toPromise();
        const config = <ControlTestScoreChartConfig>{
            chartTitle: "",
            xTitle: "Test result date",
            xCategories: data.xAxis,
            yTitle: "Score",
            yCategories: data.yAxis.map((x) => x.Name),
            data: data.data,
        };

        this.diChart.createChart(config);
    }

    async createEffChart(): Promise<void> {
        if (this.permissions.CanOpenSimpleEff) {
            const data = await this.ds
                .getSimpleChart(SimpleTestingType.Effectiveness, this.guid)
                .toPromise();
            const config = <ControlTestScoreChartConfig>{
                chartTitle: "",
                xTitle: "Test result date",
                xCategories: data.xAxis,
                yTitle: "Score",
                yCategories: data.yAxis.map((x) => x.Name),
                data: data.data,
            };

            this.effSimple.createChart(config);
        } else if (this.permissions.CanOpenAdvEff) {
            this.effAdv.setup(this.guid);
        }
    }
}
