<div>
    <div *ngIf="!workflowHistory">
        <div class="fancy-loader"></div>
    </div>
    <table class="table fixed-header table-sm" *ngIf="workflowHistory">
        <thead>
            <tr>
                <th style="width: 200px;">Status</th>
                <th style="width: 130px;" class="d-none d-lg-table-cell">Action</th>
                <th style="width: 140px;" class="d-none d-lg-table-cell">On</th>
                <th style="width: 200px;" class="d-none d-sm-table-cell">By</th>
                <th style="width: 180px;">Score</th>
                <th class="d-none d-xl-table-cell">Comment</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let history of workflowHistory">
                <td [title]="history.statusDescription">{{ history.statusDescription }}</td>
                <td [title]="history.actionDescription" class="d-none d-lg-table-cell">
                    {{ history.actionDescription }}
                </td>
                <td [title]="history.actionOn | dateTimeFormat" class="d-none d-lg-table-cell">
                    {{ history.actionOn | dateTimeFormat }}
                </td>
                <td [title]="history.actionBy" class="d-none d-sm-table-cell">{{ history.actionBy }}</td>
                <td [title]="history.score">
                    <div
                        class="cell-score-color"
                        [ngStyle]="{
                            'background-color': history.scoreColor ? history.scoreColor : ''
                        }"
                    ></div>
                    {{ history.score }}
                </td>
                <td [title]="history.comment" class="d-none d-xl-table-cell">{{ history.comment }}</td>
            </tr>
        </tbody>
    </table>
</div>
