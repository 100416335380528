<form
    class="detail-page-card-body padded cerrix-control-sm form-group-styling"
    [formGroup]="parentForm"
    novalidate
>
    <div class="detail-page-card">
        <div class="detail-page-card-title">Period Details</div>
        <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Period name"
                                dataUiid="auditperiodsdetailmain-name"
                                formControlName="name"
                                identifier="periodNameInput"
                                [required]="true"
                            ></cerrix-textfield>
                        </div>
                        <div class="row">
                            <div class="form-group col-6">
                                <label for="StartDate">Date closed</label>
                                <date-input
                                    data-uiid="auditperiodsdetailmain-dateclosed"
                                    [disabled]="true"
                                    [value]="model.dateClosed"
                                ></date-input>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Right block -->
                <div class="col-12 col-xl-6 right-block">
                    <div class="row">
                        <div class="form-group col-6">
                            <label for="StartDate">Start date</label>
                            <date-input
                                data-uiid="auditperiodsdetailmain-startdate"
                                formControlName="startDate"
                            ></date-input>
                        </div>
                        <div class="form-group col-6">
                            <label for="EndDate">End date</label>
                            <date-input
                                data-uiid="auditperiodsdetailmain-enddate"
                                formControlName="endDate"
                            ></date-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <generic-list-manager
            [config]="auditUniverseConfig"
            *ngIf="auditUniverseConfig"
        ></generic-list-manager>
    </div>
</form>
