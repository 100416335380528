import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { CustomFieldBase } from '../custom-field-base';
import { GenericListConfig } from '@app/shared/models/GenericList/GenericList';

@Component({
    selector: 'sub-list-manager',
    templateUrl: './sub-list-manager.component.html',
    styleUrls: ['./sub-list-manager.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubListManagerComponent extends CustomFieldBase implements OnInit {
    @Input() listConfig: GenericListConfig;

    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.listConfig) {
            return;
        }

        this.listConfig.allowAdd = this.listConfig.allowAdd == null ? !this.disabled : this.listConfig.allowAdd;
        this.listConfig.allowEdit = this.listConfig.allowEdit == null ? !this.disabled : this.listConfig.allowEdit;
        this.listConfig.allowDelete = this.listConfig.allowDelete == null ? !this.disabled : this.listConfig.allowDelete;

        this.listConfig.data = this.value;
        this.listConfig.dataChanged = (newData) => {
            this.value = newData;
            this.valueChange.emit(this.value);
        };
    }
}
