import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { Observable } from "rxjs";

export interface FilterOutput {
    organization: number | null | undefined;
    businessDimension: number;
}

@Component({
    selector: "process-editor-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements AfterViewInit {
    @Input() isViewer: boolean;
    @Output() onApplyFilter = new EventEmitter<FilterOutput>();

    selectedOrganizationId: number | null | undefined;
    selectedBusinessDimensionId: number;

    orgRequest: Observable<CerrixTreeItem[]>;
    bdRequest: Observable<CerrixTreeItem[]>;

    parentForm: FormGroup;

    constructor(
        private _standingDataService: StandingdataDataService,
        private _processDataService: ProcessEditorDataService
    ) {}

    ngAfterViewInit() {
        this.setOrgTree();
        this.setBdTree();
    }

    reloadBusinessData() {
        this.setBdTree();
    }

    setFilterSelection(input: FilterOutput) {
        this.selectedOrganizationId = input.organization;
        this.selectedBusinessDimensionId = input.businessDimension;
        this.reloadBusinessData();
        this.applyFilter();
    }

    applyFilter() {
        this.onApplyFilter.emit({
            organization: this.selectedOrganizationId,
            businessDimension: this.selectedBusinessDimensionId,
        });
    }

    private setOrgTree() {
        const orgCall = this.isViewer
            ? this._processDataService.getViewerOrganizations()
            : this._processDataService.getEditorOrganizations();

        this.orgRequest = orgCall;
    }

    private setBdTree() {
        let bdCall;
        if (!this.selectedOrganizationId) {
            if (this.isViewer) {
                bdCall = this._processDataService.getViewerBusinessDimensions();
            } else {
                bdCall = this._processDataService.getEditorBusinessDimensions();
            }
        } else {
            bdCall = this._standingDataService.getBusinessDimensionsByOrganization(
                this.selectedOrganizationId,
                this.selectedBusinessDimensionId
            );
        }

        this.bdRequest = bdCall;
    }
}
