<div
    #contextmenu
    id="contextmenu"
    [class]="theme"
    [ngStyle]="{ visibility: visible ? 'visible' : 'hidden', top: posY + 'px', left: posX + 'px' }"
>
    <ul id="menulist">
        <ng-template ngFor let-menuItem [ngForOf]="menu">
            <ng-container *ngIf="!menuItem.visible || menuItem.visible()">
                <!-- MENUITEM -->
                <li
                    [class]="
                        !menuItem.enabled || menuItem.enabled() ? 'actionitem' : 'disabled-item'
                    "
                    *ngIf="!menuItem.divider && !menuItem.subMenu"
                    (click)="
                        (!menuItem.enabled || menuItem.enabled()) &&
                            menuItem.click &&
                            menuItem.click()
                    "
                    (mouseover)="mouseOverItem()"
                >
                    <i
                        *ngIf="menuItem.icon"
                        class="actionicon {{ menuItem.icon }}"
                        aria-hidden="true"
                    ></i>
                    <span class="actiontext">{{ menuItem.text }}</span>
                    <span *ngIf="menuItem.shortcut" class="actionshortcut">{{
                        menuItem.shortcut.getString()
                    }}</span>
                </li>
                <!-- SUBMENU -->
                <li
                    [class]="
                        !menuItem.enabled || menuItem.enabled() ? 'actionitem' : 'disabled-item'
                    "
                    *ngIf="menuItem.subMenu"
                    (click)="stopPropagation($event)"
                    (mouseover)="
                        (!menuItem.enabled || menuItem.enabled()) &&
                            mouseoverSubMenu($event, menuItem)
                    "
                >
                    <span class="actiontext">{{ menuItem.text }}</span>
                    <i class="submenuicon fa fa-caret-right" aria-hidden="true"></i>
                </li>
                <hr *ngIf="menuItem.divider" />
            </ng-container>
        </ng-template>
    </ul>
</div>

<ng-template #submenu></ng-template>
