import { Component, Input, OnInit } from "@angular/core";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { MatrixModel } from "@models/generic/MatrixModel";

@Component({
    selector: "business-risks-widget",
    templateUrl: "./business-risks-widget.component.html",
    styleUrls: ["./business-risks-widget.component.scss"],
})
export class BusinessRisksWidgetComponent implements OnInit {
    @Input() businessDimensionId: number;
    @Input() orgId: number;

    netMatrix: MatrixModel;
    grossMatrix: MatrixModel;

    constructor(private _riskDS: RiskDataService) {}

    async ngOnInit() {
        this.netMatrix = await this._riskDS
            .getRiskMatrixChart(this.getMatrixChartConfig("Net"))
            .toPromise();

        this.grossMatrix = await this._riskDS
            .getRiskMatrixChart(this.getMatrixChartConfig("Gross"))
            .toPromise();
    }

    private getMatrixChartConfig(netOrGross: "Net" | "Gross") {
        return {
            Organization: this.orgId,
            OrganizationIncludeChildren: true,

            BusinessDimension: this.businessDimensionId,
            BusinessDimensionIncludeChildren: true,

            FrameworkDimension: null,
            FrameworkDimensionIncludeChildren: false,

            NetGross: netOrGross == "Net" ? 1 : 2,
            ImpactScale: null,
            RiskCatalogue: null,
        };
    }
}
