import { Component, OnInit } from "@angular/core";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { KriDataService } from "../services/kri-data.service";
import { ToastrService } from "ngx-toastr";
import { download } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { finalize } from "rxjs/operators";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import * as excel from "exceljs/dist/exceljs.min.js";
import moment from "moment";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "kri-data-upload",
    templateUrl: "./kri-data-upload.component.html",
    styleUrls: ["./kri-data-upload.component.scss"],
})
export class KriDataUploadComponent implements OnInit {
    tab: TabModel;

    showConfig = false;
    loading = false;

    validations: string;
    listConfig: GenericListConfig;

    constructor(
        private ds: KriDataService,
        private toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit(): void {
        this.listConfig = <GenericListConfig>{
            name: "Data",
            hideHeader: true,
            idProp: "id",

            data: [],

            allowAdd: true,
            allowEdit: true,
            allowDelete: true,

            hideAddButton: true,
            hideEditButton: true,
            hideDeleteButton: true,

            fields: [
                {
                    fieldName: "kriID",
                    prettyName: "KRI",
                    fieldType: GenericListFieldType.Number,
                },
                {
                    fieldName: "value",
                    prettyName: "Value",
                    fieldType: GenericListFieldType.Number,
                },
                {
                    fieldName: "date",
                    prettyName: "Date",
                    fieldType: GenericListFieldType.DateTime,
                },
                {
                    fieldName: "organization",
                    prettyName: "Organization",
                    fieldType: GenericListFieldType.Text,
                },
            ],

            overviewRowActions: [
                {
                    icon: "fas fa-trash",
                    tooltip: "",
                    clickEvent: (row) => {
                        const index = (this.listConfig.data as any[]).findIndex(
                            (x) => x.id == row.id
                        );
                        this.listConfig.data.splice(index, 1);
                        this.listConfig.reload();
                    },
                },
            ],
        };
    }

    uploadData() {
        const uploadPrompt = this._promptService.loader("Uploading data, please wait...");

        this.ds
            .uploadKriData(this.listConfig.data)
            .pipe(finalize(() => uploadPrompt.close()))
            .subscribe((validations) => {
                if (validations) {
                    this.validations = validations;
                    this.toastr.error(
                        "Data could not be upload. Please correct the validations.",
                        "Failed!"
                    );
                } else {
                    this.toastr.success("Data is uploaded.", "Succes!");
                    this.tab.refresh();
                }
            });
    }

    downloadTemplate() {
        download("/api/kris/datatemplate");
    }

    clearAll() {
        this.loading = false;
        this.showConfig = false;

        this.listConfig.data = [];
    }

    readFile(fileInput) {
        this.clearAll();
        if (!fileInput || !fileInput.target || !fileInput.target.files) {
            return;
        }

        this.loading = true;

        const file = fileInput.target.files[0];
        const wb = new excel.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then((workbook) => {
                this.convertSheetToArray(workbook.getWorksheet(1));
            });
        };
    }

    convertSheetToArray(sheet) {
        const items = [];
        let id = -1;
        sheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
                items.push({
                    id: id--,
                    kriID: row.values[1],
                    value: +("" + row.values[2]).replace(",", "."),
                    date: moment(row.values[3], "YYYYMMDDHHmmss").toDate(),
                    organization: row.values[4],
                });
            }
        });

        if (items.length === 0) {
            this.clearAll();
            this.toastr.error(
                "Template is invalid or did not contain any items!",
                "Invalid template"
            );
            return;
        }

        this.listConfig.data = items;
        this.loading = false;
        this.showConfig = true;
    }
}
