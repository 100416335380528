import { HttpResponse } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { TargetModule } from "@enums/document/TargetModule";
import { MoiStatusType } from "@enums/moi/MoiStatusType";
import { MoiTypes } from "@enums/moi/MoiTypes";
import {
    closeDirtyPageCheck,
    getFormControl,
    isDirty,
    parseTyped,
    toPromise,
} from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { MoiConfig } from "@models/moi/MoiConfig";
import { MoiDueDateUpdateModel } from "@models/moi/MoiDueDateUpdateModel";
import { MoiModel } from "@models/moi/MoiModel";
import { MoiPermissionModel } from "@models/moi/MoiPermissionModel";
import { MoiRequestModel } from "@models/moi/MoiRequestModel";
import { MoiWorkflowUpdateModel } from "@models/moi/MoiWorkflowUpdateModel";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/internal/operators/finalize";
import { MoiDataService } from "../services/moi-data.service";
import { MoiCommentEditorConfig } from "../shared/moi-comment-editor-config";
import { MoiCommentModel } from "../shared/moi-comment-model";
import { MoiCommentType } from "../shared/moi-comment-type.enum";
import { MoiControlsComponent } from "./moi-controls/moi-controls.component";
import { MoiCommentsComponent } from "./moi-detail-main/moi-comments/moi-comments.component";
import { MoiDetailMainComponent } from "./moi-detail-main/moi-detail-main.component";
import { MoiFindingsComponent } from "./moi-findings/moi-findings.component";
import { MoiRisksComponent } from "./moi-risks/moi-risks.component";
import { MoiSourceSelectorComponent } from "./moi-source-selector/moi-source-selector.component";
import { IncidentService } from "@app/incident/services/incident.service";
import { IncidentMoiOverviewModel } from "@app/incident/models/incident-moi-overview-model";

@Component({
    selector: "moi-detail",
    templateUrl: "./moi-detail.component.html",
    styleUrls: ["./moi-detail.component.scss"],
})
export class MoiDetailComponent implements OnInit {
    @ViewChild("comments") comments: MoiCommentsComponent;
    @ViewChild("moiRisksComponent") moiRisksComponent: MoiRisksComponent;
    @ViewChild("moiControlsComponent")
    moiControlsComponent: MoiControlsComponent;
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent;
    @ViewChild(MoiDetailMainComponent)
    mainDetailComponent: MoiDetailMainComponent;
    @ViewChild("moiFindingsComponent")
    moiFindingsComponent: MoiFindingsComponent;

    tab: TabModel;
    tabID: string;
    id: string;

    linkedRiskData: string[];
    unlinkedRiskData: string[];

    formDirty = false;
    backupModel: MoiModel;

    moiModel: MoiModel;
    MoiTypes: MoiTypes;
    moiPermissions: MoiPermissionModel;
    sd: any;
    parentSummary: any;

    parentForm: FormGroup;

    moiTypeDescription = "IM Moi";
    showLinkedRisksAndControls = false;
    showLinkedFindings = false;

    hideSaveButton = false;
    typeSelectionInitiated = false;

    moiConfig: MoiConfig;

    moiStatusDescription: "";
    workflowDisabled = false;

    targetModule = TargetModule.ImprovementManagement;

    // Cache the duedate to handle due date change
    originalDueDate: Date;

    hyperlinksMenuItemId = "menuItemHyperlinks";
    startDateFormControl: FormControl<Date>;
    dueDateFormControl: FormControl<Date>;

    constructor(
        private route: ActivatedRoute,
        public _moiDS: MoiDataService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private _promptService: CerrixPromptService,
        private _pages: Pages,
        private _incidentDS: IncidentService
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;

        this.setupInit();
    }

    setupInit() {
        const isExisting = this.id && isGuid(this.id.toString());
        let request: Observable<MoiRequestModel>;
        let moiConfig: MoiConfig;
        if (isExisting) {
            request = this._moiDS.getMoi(this.id);
        } else {
            moiConfig = this.tab.config
                ? this.tab.config.MoiType
                    ? this.tab.config
                    : parseTyped<MoiConfig>(this.tab.config, null)
                : null;

            if (moiConfig) {
                request = this._moiDS.getNewMoi(moiConfig.MoiType, moiConfig.ParentId);
            } else {
                // Check here it is a new unspecified moi.
                if (!this.typeSelectionInitiated) {
                    this.typeSelectionInitiated = true;
                    Promise.resolve().then(() => {
                        this.initTypeSelection();
                    });
                    return;
                } else {
                    this.toastr.error("Could not create a new MoI");
                    this.tab.close();
                }
            }
        }

        this.processRequest(request, moiConfig, isExisting);
    }

    initTypeSelection() {
        return this.dialog
            .open(MoiSourceSelectorComponent, {
                minHeight: "200px",
                minWidth: "200px",
                disableClose: true,
            })
            .afterClosed()
            .subscribe((result) => {
                if (!result) {
                    this.tab.close(false);
                    return;
                }

                const newMoiConfig = result as MoiConfig;
                if (this.tab.config) {
                    const currentConfig = <MoiConfig>this.tab.config;
                    newMoiConfig.OrganizationId = currentConfig.OrganizationId;
                    newMoiConfig.BusinessId = currentConfig.BusinessId;
                }

                this.tab.config = newMoiConfig;
                this.setupInit();
            });
    }

    processRequest(
        request: Observable<MoiRequestModel>,
        moiConfig: MoiConfig,
        isExisting: boolean
    ) {
        request.subscribe((response) => {
            this.moiPermissions = response.permissions;
            this.sd = response.standingdata;
            if (response.moi.Type != MoiTypes.Incident_Moi) {
                this.parentSummary = response.parent;
            }
            this.initMoiDetails(response.moi, moiConfig, isExisting);
        });
    }

    async initMoiDetails(moiModel: MoiModel, moiConfig: MoiConfig, isExisting: boolean) {
        this.moiTypeDescription = MoiTypes[MoiTypes[moiModel.Type].toString() + "Description"];

        if (isExisting) {
            this.tab.name = `(M) ${+moiModel.Identifier} - ${moiModel.Name}`;
        } else {
            this.tab.name = "Create a new " + this.moiTypeDescription;
            moiModel.ParentGuid = moiConfig.ParentGuid;
            moiModel.AssessmentGuid = moiConfig.AssessmentGuid;

            this.initNewMoiModel(moiModel);
        }

        this.showLinkedRisksAndControls = moiModel.Type === MoiTypes.IM_Moi && isExisting;
        this.showLinkedFindings = this.showLinkedRisksAndControls && moiModel.HasLinkedAudit;

        this.moiConfig = {
            ParentId: moiModel.ParentId,
            MoiType: moiModel.Type,
        };

        if (!moiModel.Documents) {
            moiModel.Documents = [];
        }

        this.originalDueDate = moiModel.DueDate;

        if (moiModel.Type == MoiTypes.Incident_Moi) {
            const summary = await this._incidentDS
                .getIncidentSummaryForMoi(moiModel.ParentGuid)
                .toPromise();
            this.parentSummary = summary;
            if (summary.date_Detected) {
                this.parentSummary.date_Detected = new Date(summary.date_Detected);
            } else {
                this.parentSummary.date_Detected = null;
            }
            if (summary.date_Occurred) {
                this.parentSummary.date_Occurred = new Date(summary.date_Occurred);
            } else {
                this.parentSummary.date_Occurred = null;
            }
            this.parentSummary.canOpenParent = summary.canOpenIncident;
            moiModel.BusinessDimensions = summary.business_Dimensions;
            moiModel.FrameworkDimensions = summary.framework_Dimensions;
        }

        // Make backup of initial version to check if something has changed.
        this.backupModel = JSON.parse(JSON.stringify(moiModel));

        this.moiModel = moiModel;
        this.initFormValidation(moiModel, this.moiPermissions);

        this.setStatusDescription();

        this.tab.beforeClose = (checkOnly: boolean) => {
            const unsavedChanges = this.formDirty;
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !unsavedChanges);
        };
        this.tab.showLoader = false;
    }

    initNewMoiModel(moiModel: MoiModel) {
        const conf = <MoiConfig>this.tab.config;
        if (conf) {
            const orgID = conf.OrganizationId;
            if (orgID) {
                moiModel.OrganizationResponsible = +orgID;
            }

            if (conf.MoiType == MoiTypes.IM_Moi) {
                const bdID = conf.BusinessId;
                if (bdID) {
                    moiModel.BusinessDimensions = [+bdID];
                }
                moiModel.FindingDescription = conf.FindingDescription;
                moiModel.Recommendation = conf.Recommendation;
                moiModel.ManagementResponse = conf.ManagementResponse;
                const findingId = conf.LinkedFinding;
                if (findingId) {
                    moiModel.LinkedFindings = [+findingId];
                }
            }
        }
    }

    initFormValidation(moiModel: MoiModel, permissions: MoiPermissionModel) {
        this.startDateFormControl = new FormControl({
            value: moiModel.StartDate,
            disabled: !permissions.canChangeOtherFields,
        });
        this.dueDateFormControl = new FormControl(
            { value: moiModel.DueDate, disabled: !permissions.canChangeDueDate },
            Validators.required
        );
        this.parentForm = new FormGroup(
            {
                Name: new FormControl(
                    { value: moiModel.Name, disabled: !permissions.canChangeOtherFields },
                    [Validators.required]
                ),
                Subject: new FormControl(
                    { value: moiModel.Subject, disabled: !permissions.canChangeOtherFields },
                    Validators.required
                ),
                Priority: new FormControl(
                    { value: moiModel.Priority, disabled: !permissions.canChangeOtherFields },
                    Validators.required
                ),
                ImplementationScore: new FormControl({
                    value: moiModel.ImplementationScore,
                    disabled: !permissions.canChangeOtherFields,
                }),
                Requester: new FormControl({ value: moiModel.Requester, disabled: true }),
                DateCreated: new FormControl({ value: moiModel.DateCreated, disabled: true }),
                InitialDueDate: new FormControl({ value: moiModel.InitialDueDate, disabled: true }),
                StartDate: this.startDateFormControl,
                DueDate: this.dueDateFormControl,
                FindingDescription: new FormControl({
                    value: moiModel.FindingDescription,
                    disabled: !permissions.canChangeOtherFields,
                }),
                Recommendation: new FormControl(
                    { value: moiModel.Recommendation, disabled: !permissions.canChangeOtherFields },
                    Validators.required
                ),

                OrganizationReviewer: new FormControl(
                    {
                        value: moiModel.OrganizationReviewer,
                        disabled: !permissions.canAppointReviewer,
                    },
                    Validators.required
                ),
                Reviewers: new FormControl(
                    { value: moiModel.Reviewers, disabled: !permissions.canAppointReviewer },
                    Validators.required
                ),
                OrganizationResponsible: new FormControl(
                    {
                        value: moiModel.OrganizationResponsible,
                        disabled: !permissions.canAppointResponsible,
                    },
                    Validators.required
                ),
                Responsibles: new FormControl(
                    { value: moiModel.Responsibles, disabled: !permissions.canAppointResponsible },
                    Validators.required
                ),
                Delegates: new FormControl({
                    value: moiModel.Delegates,
                    disabled: !permissions.canAppointDelegate,
                }),
                BusinessDimensions: new FormControl({
                    value: moiModel.BusinessDimensions,
                    disabled: !permissions.canChangeBusinessDimensions,
                }),
                FrameworkDimensions: new FormControl({
                    value: moiModel.FrameworkDimensions,
                    disabled: !permissions.canChangeFrameworkDimensions,
                }),
                ManagementResponse: new FormControl({
                    value: moiModel.ManagementResponse,
                    disabled: !permissions.canChangeManagementResponse,
                }),
                Url: new FormControl({
                    value: moiModel.Url,
                    disabled: !permissions.canChangeOtherFields,
                }),
            },
            {
                validators: FormValidationHelper.validateEndStartDate<MoiModel>(
                    "StartDate",
                    "DueDate",
                    "Due date may not be before start date",
                    false
                ),
            }
        );

        this.parentForm.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(this.parentForm, this.moiModel);
            this.checkDirty();
        });

        // This will set the OrganizationResponsible to disabled or enabled correctly.
        this.linkedRiskControlsChanged();

        if (permissions.canOnlyReadMoi) {
            this.parentForm.disable();
        } else {
            getFormControl<MoiModel>(this.parentForm, "DueDate").valueChanges.subscribe((dueDate) =>
                this.dueDateValueChanged(dueDate)
            );
        }
    }

    checkDirty() {
        this.formDirty = isDirty(this.backupModel, this.moiModel);
    }

    async save(skipReload?: boolean) {
        const validForm = this.parentForm.valid;
        const disabledButCanUpload = // When all fields are disabled, the form is invalid. But I still want to allow uploading of documents
            this.parentForm.disabled && !this.moiPermissions.canOnlyReadMoi;

        if (!validForm && !disabledButCanUpload) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
            if (validationErrors && Object.keys(validationErrors).length > 0) {
                const validationMessage =
                    FormValidationHelper.getGeneralErrorMessage(validationErrors);
                this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

                return false;
            }
            return false;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.moiModel);

        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        let storeSuccess = false;
        await this._moiDS
            .storeMoi(this.moiModel)
            .toPromise()
            .then((value: HttpResponse<any>) => {
                savingPrompt.close();
                this.toastr.success("", "Update completed");
                this.tab.id = value.body.toString();
                storeSuccess = true;
                if (!skipReload) {
                    if (
                        this.tab.parent &&
                        this.tab.parent.lookupname === this._pages.AuditFindingDetail
                    ) {
                        this.tab.parent.refresh();
                    }
                    this.tab.refresh();
                }
            })
            .catch((error) => {
                savingPrompt.close();
                storeSuccess = false;
            });

        return storeSuccess;
    }

    linkedRiskControlsChanged() {
        const control = getFormControl<MoiModel>(this.parentForm, "OrganizationResponsible");

        const hasRights = this.moiPermissions.canAppointResponsible;
        if (!hasRights) {
            control.disable();
            return;
        }

        const hasLinkedControls =
            this.moiModel.LinkedControls && this.moiModel.LinkedControls.length > 0;
        const hasLinkedRisks = this.moiModel.LinkedRisks && this.moiModel.LinkedRisks.length > 0;

        if (hasLinkedControls || hasLinkedRisks) {
            control.disable();
        } else {
            control.enable();
        }

        this.checkDirty();
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.name) {
                case "History": {
                    this.toggleSaveButton(true);
                    this.historyOverviewComponent.loadHistory();
                    break;
                }
                case "Risks": {
                    this.toggleSaveButton(false);
                    this.moiRisksComponent.load();
                    break;
                }
                case "Controls": {
                    this.toggleSaveButton(false);
                    this.moiControlsComponent.load();
                    break;
                }
                case "Audit findings": {
                    this.toggleSaveButton(false);
                    this.moiFindingsComponent.load();
                    break;
                }
                case "Hyperlinks":
                    this.loadHyperlinks();
                    break;
                default: {
                    this.toggleSaveButton(false);
                }
            }
        }
    }

    loadHyperlinks(): void {
        if (!this.moiModel.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this._moiDS.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.moiModel.Hyperlinks = hyperlinks;
            });
        }
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
    }

    toggleSaveButton(hide: boolean) {
        if (this.hideSaveButton !== hide) {
            this.hideSaveButton = hide;
        }
    }

    // Date Changed
    dueDateValueChanged(newDate: Date) {
        this.startDateFormControl.updateValueAndValidity();
        this.startDateFormControl.markAsTouched();
        if (!this.shouldShowDueDatePopup(newDate)) {
            return;
        }

        const commentEditorConfig = new MoiCommentEditorConfig();
        commentEditorConfig.hideFeasibility = true;
        commentEditorConfig.hideProgress = true;
        commentEditorConfig.title = "Comment for due date change";

        const dueDateControl = getFormControl<MoiModel>(this.parentForm, "DueDate");

        this.comments.newCommentDialog(MoiCommentType.DueDateChange, commentEditorConfig).then(
            (comment) => {
                if (comment && comment.comment) {
                    const dueDateUpdateModel = new MoiDueDateUpdateModel();
                    dueDateUpdateModel.comment = comment;
                    dueDateUpdateModel.newDate = newDate;

                    const prompt = this._promptService.loader("Updating due date, please wait...");

                    this._moiDS
                        .updateDueDate(this.moiModel.Guid, dueDateUpdateModel)
                        .pipe(finalize(() => prompt.close()))
                        .subscribe((x) => {
                            this.originalDueDate = newDate;
                            dueDateControl.setValue(newDate);
                            this.comments.loadComments();
                            this.toastr.success("Due date successfully updated");
                        });
                } else {
                    dueDateControl.setValue(this.originalDueDate);
                    this.toastr.error(
                        "A change due date comment is required when updating the due date.",
                        "Due date update failed"
                    );
                }
            },
            (error) => {
                dueDateControl.setValue(this.originalDueDate);
                this.toastr.error(error || error.Message, "Due date update failed");
            }
        );
    }

    private shouldShowDueDatePopup(newDate: Date): boolean {
        if (this.moiModel.Id == 0) {
            // Don't show popup on new item
            return false;
        }
        if (!newDate || newDate === this.originalDueDate) {
            // No popup if we didn't change to a new due date
            return false;
        }
        if (!this.isValidDate(newDate)) {
            // No need to show popup if Due date is invalid
            this.toastr.warning("Can't update Due date since new Due date is invalid");
            return false;
        }
        if (
            !this.startDateFormControl ||
            (!this.startDateFormControl.disabled &&
                (!this.startDateFormControl.valid ||
                    !this.isValidDate(this.startDateFormControl.value)))
        ) {
            // No need to show popup if Start date is invalid
            this.toastr.warning("Can't update Due date since Start date is invalid");
            return false;
        }
        if (newDate < this.startDateFormControl.value) {
            // Don't show popup if newDate is before Start date
            this.toastr.warning("Can't update Due date since it can't be before Start date");
            return false;
        }

        return true;
    }

    private isValidDate(date: Date): boolean {
        if (!date) {
            return true;
        }

        if (Object.prototype.toString.call(date) !== "[object Date]") {
            return false;
        }
        if (isNaN(date.getDate())) {
            return false;
        }
        return true;
    }

    // Workflow
    setStatusDescription() {
        if (this.moiModel.StatusId && this.moiModel.StatusId > 0) {
            this.moiStatusDescription =
                MoiStatusType[MoiStatusType[this.moiModel.StatusId].toString() + "Description"];
        } else {
            this.moiStatusDescription = "";
        }
    }

    async nextStatus(approved: boolean) {
        let reloadSkipped = false;
        if (this.formDirty) {
            reloadSkipped = true;
            const saved = await this.save(reloadSkipped);
            if (!saved) {
                return;
            }
        }

        let moiCommentType: MoiCommentType;
        let moiCommentTitle: string;
        let commentOptional: boolean = false;
        let showFeasibility: boolean = false;
        let showProgress: boolean = false;
        let checkProgress: boolean = false;
        let checkProgressSubMessage: string;
        switch (this.moiModel.StatusId as MoiStatusType) {
            case MoiStatusType.Unconfirmed:
                {
                    if (approved) {
                        moiCommentType = MoiCommentType.Confirm;
                        moiCommentTitle = "Confirm comment";
                    } else {
                        moiCommentType = MoiCommentType.Decline;
                        moiCommentTitle = "Decline comment";
                    }
                }
                break;
            case MoiStatusType.Declined:
                {
                    moiCommentType = MoiCommentType.Resubmit;
                    moiCommentTitle = "Resubmit comment";
                }
                break;
            case MoiStatusType.InProgress:
                {
                    moiCommentType = MoiCommentType.ReadyForAcceptance;
                    moiCommentTitle = "Ready for acceptance comment";
                    showFeasibility = true;
                    showProgress = true;
                    commentOptional = true;
                    checkProgress = true;
                    checkProgressSubMessage =
                        "are you sure you want to update the status to ready for acceptance?";
                }
                break;
            case MoiStatusType.ReadyForAcceptance:
                {
                    if (approved) {
                        moiCommentType = MoiCommentType.Accept;
                        moiCommentTitle = "Accept comment";
                    } else {
                        moiCommentType = MoiCommentType.Reject;
                        moiCommentTitle = "Reject comment";
                    }
                }
                break;
            case MoiStatusType.Closed:
                {
                    moiCommentType = MoiCommentType.Reopen;
                    moiCommentTitle = "Comment on reopening MoI";
                    showFeasibility = true;
                    showProgress = true;
                }
                break;
        }

        if (moiCommentType) {
            const comment = await this.showCommentDialog({
                type: moiCommentType,
                title: moiCommentTitle,
                showProgress: showProgress,
                showFeasibility: showFeasibility,
                checkProgress: checkProgress,
                checkProgressSubMessage: checkProgressSubMessage,
                commentOptional: commentOptional,
            });

            if (comment) {
                if (comment.comment || commentOptional) {
                    this.updateWorkflow(comment, approved);
                    return;
                } else {
                    this.toastr.error(
                        "A workflow comment is required when updating this status.",
                        "Workflow update failed"
                    );
                }
            }

            if (reloadSkipped) {
                this.tab.refresh();
            }
        } else {
            const commentmodel = new MoiCommentModel();
            commentmodel.comment = "N/A";
            this.updateWorkflow(commentmodel, approved);
        }
    }

    private async showCommentDialog(config: {
        type: MoiCommentType;
        title: string;
        showProgress: boolean;
        showFeasibility: boolean;
        commentOptional?: boolean;
        checkProgress?: boolean;
        checkProgressSubMessage?: string;
    }): Promise<MoiCommentModel> {
        const commentEditorConfig = new MoiCommentEditorConfig();
        commentEditorConfig.hideFeasibility = !config.showFeasibility;
        commentEditorConfig.hideProgress = !config.showProgress;
        commentEditorConfig.title = config.title;
        commentEditorConfig.commentRequired = !config.commentOptional;
        commentEditorConfig.checkProgress = config.checkProgress;
        commentEditorConfig.checkProgressSubMessage = config.checkProgressSubMessage;

        return this.comments.newCommentDialog(config.type, commentEditorConfig);
    }

    updateWorkflow(comment: MoiCommentModel, approved: boolean) {
        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        const workflowUpdateModel = new MoiWorkflowUpdateModel();
        workflowUpdateModel.comment = comment;
        workflowUpdateModel.approved = approved;

        this._moiDS
            .updateWorkflow(this.moiModel.Guid, workflowUpdateModel)
            .pipe(finalize(() => savingPrompt.close()))
            .subscribe((status) => {
                this.processWorkflowChanges(+status);
            });
    }

    async closeMoi() {
        let reloadSkipped = false;
        if (this.formDirty) {
            reloadSkipped = true;
            const saved = await this.save(reloadSkipped);
            if (!saved) {
                return;
            }
        }
        const comment = await this.showCommentDialog({
            type: MoiCommentType.Close,
            title: "Add comment on closing MoI",
            showProgress: true,
            showFeasibility: true,
            commentOptional: true,
            checkProgress: true,
            checkProgressSubMessage: "are you sure you want to close this MoI?",
        });

        if (comment) {
            const savingPrompt = this._promptService.loader("Closing MoI, please wait...");

            this._moiDS
                .closeMoi(this.moiModel.Guid, comment)
                .pipe(finalize(() => savingPrompt.close()))
                .subscribe((status) => {
                    this.processWorkflowChanges(+status);
                });
        }
    }

    processWorkflowChanges(statusId: number) {
        if (this.moiModel.StatusId !== statusId) {
            this.moiModel.StatusId = statusId;
            this.workflowDisabled = true;
            this.setStatusDescription();

            this.toastr.success("", "Update completed");

            this.tab.refresh();
        }
    }
}
