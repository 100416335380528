import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HeartbeatConfigurationModel } from "@app/shared/models/heartbeat-configuration-model";
import { CerrixRole } from "@enums/authorization/CerrixRole";
import { Observable, firstValueFrom } from "rxjs";
import { ApiDataService } from "../../../../services/http/ApiDataService";
import { Configuration } from "../../../app.constants";
import { UserCurrentInfoModel } from "./user-current-info-model";

@Injectable()
export class UserService extends ApiDataService {
    cachedUserName: Promise<string>;
    lastUserName: string;

    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "users/current");
    }

    public hasAccess(roles: CerrixRole[]): Observable<boolean> {
        const url = `/current/hasaccess?roles=${roles.join()}`;

        return this.get<boolean>(url);
    }

    public getUserGuid(): Observable<string> {
        const url = "/guid";
        return this.getString(url);
    }

    public getCurrentUserId(): Observable<number> {
        const url = "/currentid";
        return this.get<number>(url);
    }

    public getUserName(refresh?): Promise<string> {
        const url = "/username";

        if (refresh) {
            this.cachedUserName = null;
        }

        if (!this.cachedUserName) {
            this.cachedUserName = firstValueFrom(this.getString(url));
            this.cachedUserName.then((x) => (this.lastUserName = x));
        }

        return this.cachedUserName;
    }

    /** Returns the current user information with base data mapped. (for more info about what is considered base data, see 'UserCurrentInfoModel) */
    public getUserInfoBase() {
        const url = "/baseinfo";

        return this.get<UserCurrentInfoModel>(url);
    }

    /** Returns the current user information with all data mapped. (this call will take more time then 'getUserInfoBase') */
    public getUserInfo() {
        const url = "/info";

        return this.get<UserCurrentInfoModel>(url);
    }

    public changePassword(model: any) {
        const url = "/changepassword";
        return this.post<string>(model, url);
    }

    public getHeartbeat() {
        const url = "/heartbeat";
        return this.get<HeartbeatConfigurationModel>(url);
    }

    public postHeartbeat() {
        const url = "/heartbeat";
        return this.post(null, url);
    }
}
