import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

@Injectable({
    providedIn: "root",
})
export class MonitoringService {
    private isInitialized = false;
    private appInsights: ApplicationInsights;

    public initialize(appInsightsInstrumentationKey: string) {
        if (appInsightsInstrumentationKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appInsightsInstrumentationKey,
                    enableAutoRouteTracking: true,
                },
            });

            this.appInsights.loadAppInsights();

            this.isInitialized = true;
        }
    }

    public logPageView(name?: string, url?: string) {
        if (this.isInitialized) {
            this.appInsights.trackPageView({
                name,
                uri: url,
            });
        }
    }

    public logEvent(name: string, properties?: { [key: string]: any }) {
        if (this.isInitialized) {
            this.appInsights.trackEvent({ name }, properties);
        }
    }

    public logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (this.isInitialized) {
            this.appInsights.trackMetric({ name, average }, properties);
        }
    }

    public logException(exception: Error, severityLevel?: number) {
        if (this.isInitialized) {
            this.appInsights.trackException({ exception, severityLevel });
        }
    }

    public logTrace(message: string, properties?: { [key: string]: any }) {
        if (this.isInitialized) {
            this.appInsights.trackTrace({ message }, properties);
        }
    }
}
