<ul id="process-menu">
    <ng-container *ngFor="let item of menu">
        <li #listElement>
            {{ item.text }}
        </li>
        <cerrix-contextmenu
            [menu]="item.menu"
            [elementClickToggle]="listElement"
            theme="processeditor"
        ></cerrix-contextmenu>
    </ng-container>
</ul>
