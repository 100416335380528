import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { guid } from "@methods/uniqueMethods";

@Component({
    selector: "cerrix-url-input",
    templateUrl: "./cerrix-url-input.component.html",
    styleUrls: ["./cerrix-url-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CerrixUrlInputComponent,
            multi: true,
        },
    ],
})
export class CerrixUrlInputComponent implements ControlValueAccessor {
    _value = "";
    _onChangeFormValidation = Function.prototype;
    _onTouchedFormValidation = Function.prototype;

    @Input() disabled = false;
    @Input() required = false;
    @Input("dataUiid") dataUiid: string = guid();

    @Output() valueChange = new EventEmitter<string>();

    @Input()
    set value(url: string) {
        if (this._value === url) {
            return;
        }

        this._value = url;
        this.valueChange.emit(url);
        this._onChangeFormValidation(url);
    }
    get value(): string {
        return this._value;
    }

    openLink() {
        if (this.value && this.value.length > 0) {
            const hasProtocol = this.checkUrl();
            if (hasProtocol) {
                window.open(this.value);
            } else {
                window.open("//" + this.value);
            }
        }
    }

    checkUrl(): boolean {
        const regex = new RegExp("(f|ht)tp");
        if (!regex.test(this.value)) {
            return false;
        }
        return true;
    }

    writeValue(obj: string): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this._onChangeFormValidation = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouchedFormValidation = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
