import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { ICerrixPrompt } from "@app/shared/cerrix-prompt/models/ICerrixPrompt";
import { GenericManagerHelper } from "@app/shared/helpers/generic-manager-helper";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import {
    GenericStandingDataConfig,
    StandingDataField,
} from "@app/shared/models/GenericList/GenericStandingDataConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingDataHelper } from "@app/standingdata/shared/standingdata.helper";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { isDirty, tryParseJson } from "@methods/CommonMethods";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import { StandingDataCategoryButton } from "./../../../shared/models/GenericList/GenericStandingDataConfig";
import { Configuration } from "@app/app.constants";
import { IncidentRootCauseCategoryService } from "@app/incident/services/incident-root-cause-category.service";
import { ApplicationSettings } from "@services/http/settings/application-settings";

@Component({
    selector: "standing-data-editor",
    templateUrl: "./standing-data-editor.component.html",
    styleUrls: ["./standing-data-editor.component.scss"],
})
export class StandingDataEditorComponent implements OnInit {
    @Input() config: GenericStandingDataConfig;
    @Input() type: StandingDataType;
    @Input() name: string;

    @Output() onChange = new EventEmitter<number>();
    @Output() onDetailOpen = new EventEmitter<any>();

    fieldType = GenericListFieldType;
    loading = false;

    data: any[];
    additionalData = {};
    row: any;
    originalRow: any;

    flatFieldList: StandingDataField[] = [];

    constructor(
        private _standingdataDS: StandingdataDataService,
        private _settingsService: SettingsDataService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _elementRef: ElementRef,
        private _configuration: Configuration,
        private _rootcauseService: IncidentRootCauseCategoryService
    ) {}

    ngOnInit() {
        StandingDataHelper.doForField(this.config.categories, (field: StandingDataField) => {
            this.flatFieldList.push(field);
            if (field.hideInEditBySettingsService) {
                field.hideInEditBySettingsService(this._settingsService).then((disabled) => {
                    field.hideInEdit = disabled;
                });
            }

            field.uiid = "standingdata-input-" + field.fieldName;
        });
    }

    add(row) {
        this.loading = true;

        setTimeout(() => {
            this.open(row);
        }, 0);
    }

    async edit(id: number) {
        if (!id) {
            throw Error("ID not valid. Use 'add' method for new entries!");
        }

        const result = await this.dirtyCheck();

        if (!result) {
            return false;
        }

        this.loading = true;
        const row = await this._standingdataDS.getByType(this.type, id).toPromise();

        this.open(row);
        return true;
    }

    async dirtyCheck() {
        if (isDirty(this.originalRow, this.row)) {
            return await this._promptService
                .confirm(
                    "Unsaved changes",
                    "There are unsaved changes, are you sure you want to discard your changes?"
                )
                .toPromise();
        }
        return of(true).toPromise();
    }

    private open(row) {
        this.row = row;

        this.mapConfigToRow();
        this.loadAdditionalData();

        // We do this after all other mapping and loading, so we are sure we have a snapshot of values that are not edited by user.
        this.originalRow = JSON.parse(JSON.stringify(row));
        this.onDetailOpen.emit(row);

        if (this.loading) {
            this.loading = false;
        }
    }

    async save() {
        GenericManagerHelper.markAllFieldsAsTouched(this._elementRef);

        const validations = GenericManagerHelper.validateFields(
            "ID",
            this.data,
            this.row,
            this.flatFieldList,
            this.config,
            true
        );

        if (validations.length === 0) {
            if (this.config.getBeforeSavePromptMessage) {
                const beforeSavePromptMessage = await this.config.getBeforeSavePromptMessage(
                    this.row,
                    this.originalRow,
                    this._settingsService
                );

                if (beforeSavePromptMessage?.length > 0) {
                    const result = await this._promptService
                        .confirmCustom({
                            maxWidth: "450px",
                            maxHeight: "300px",
                            data: {
                                message: beforeSavePromptMessage,
                                title: "Before save " + this.name.toLowerCase(),
                            },
                        })
                        .toPromise();

                    if (!result) {
                        return;
                    }
                }
            }

            this.loading = true;

            this.row.Name = this.row.Name.trim();

            try {
                const result = (await this._standingdataDS
                    .storeByType(this.type, this.row)
                    .toPromise()) as any;
                this.open(result);
                this.onChange.emit(result.ID);
            } catch {
                this.loading = false;
            }
        } else {
            const validationMsg = validations.map((validation) => "- " + validation).join("\n");
            this._toastr.warning(validationMsg, "Save failed.");
        }
    }

    async delete() {
        if (+this.row.ID > 0) {
            try {
                const promptConfig: Partial<MatDialogConfig<ICerrixPrompt>> = {
                    data: {
                        title: "Confirm delete",
                        message: "Are you sure you wish to delete the current item?",
                    },
                };

                if (this.type === StandingDataType.LerEventCategory) {
                    const useIncidents = (
                        await this._settingsService
                            .getSetting(ApplicationSettings.UseIncidents)
                            .toPromise()
                    ).BoolValue;
                    if (useIncidents) {
                        const isInUse = await this._rootcauseService.getEventCategoryUsage(
                            this.row.ID
                        );
                        if (isInUse) {
                            this._toastr.error(
                                "Incidents",
                                "This item is still in use in the following module(s)"
                            );
                            return;
                        }
                    }
                }

                if (this.config.getAdditionalDeleteMessage) {
                    const additionalDeleteMessage = this.config.getAdditionalDeleteMessage(
                        this.row
                    );
                    if (additionalDeleteMessage) {
                        promptConfig.maxHeight = "275px";
                        promptConfig.maxWidth = "475px";
                        promptConfig.data.message = `${promptConfig.data.message}

                        ${additionalDeleteMessage}`;
                    }
                }

                this._promptService
                    .confirmCustom(promptConfig)
                    .onConfirm()
                    .subscribe(() => {
                        this.loading = true;
                        this._standingdataDS
                            .deleteByType(this.type, this.row.ID)
                            .pipe(finalize(() => (this.loading = false)))
                            .subscribe(() => {
                                this.row = null;
                                this.originalRow = null;
                                this.onChange.emit(null);
                                this.loading = false;
                            });
                    });
            } catch {
                this.loading = false;
            }
        }
    }

    mapConfigToRow() {
        StandingDataHelper.doForField(this.config.categories, (field: StandingDataField) => {
            if (this.row[field.fieldName] == undefined || this.row[field.fieldName] == null) {
                if (field.defaultValue !== undefined) {
                    this.row[field.fieldName] = field.defaultValue;
                } else {
                    this.row[field.fieldName] = "";
                }
            }
        });
    }

    loadAdditionalData() {
        this.additionalData = {};
        StandingDataHelper.doForField(this.config.categories, (field: StandingDataField) => {
            if (
                field.getDataMethodByRow ||
                (field.getDataMethodName && this._standingdataDS[field.getDataMethodName])
            ) {
                this.additionalData[field.fieldName] = null;
                this.additionalData[field.fieldName + "Loading"] = true;
                const dataCall: Observable<any> = field.getDataMethodByRow
                    ? field.getDataMethodByRow(this.row, this._standingdataDS)
                    : this._standingdataDS[field.getDataMethodName](field.getDataParams);

                dataCall.subscribe((d) => {
                    const data = tryParseJson(d);
                    if (data) {
                        this.additionalData[field.fieldName] = data;
                    } else {
                        this.additionalData[field.fieldName] = d;
                    }

                    this.additionalData[field.fieldName + "Loading"] = false;
                });
            }
        });
    }

    async executeCategoryButton(button: StandingDataCategoryButton) {
        if (button.requiresReload && !(await this.dirtyCheck())) {
            return;
        }

        this.loading = true;
        const succeeded = await button.action(this.row);
        if (!succeeded) {
            this.loading = false;
            return;
        }

        if (button.requiresReload) {
            // Force dirty check to be false.
            this.originalRow = JSON.parse(JSON.stringify(this.row));
            this.edit(this.row.ID);
        } else {
            this.loading = false;
        }
    }

    genericFieldValueChanged(data: any) {
        if (this.config.customEditor && data) {
            if (!data.ID) {
                data.ID = this.row.ID;
            }

            this.row = data;
        }
    }
}
