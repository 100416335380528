import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
declare var $: any;

@Component({
    selector: "eff-inline-text-editor",
    templateUrl: "./eff-inline-text-editor.component.html",
    styleUrls: ["./eff-inline-text-editor.component.scss"],
})
export class EffInlineTextEditorComponent {
    @Input() model: string;
    @Input() label: string;
    @Input() disabled: boolean;
    @ViewChild("inputText") input: ElementRef;
    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    @Output() complete: EventEmitter<any> = new EventEmitter();

    isEditMode: boolean = false;

    onModelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["model"]) {
            setTimeout(this.resizeInput.bind(this), 0);
        }
    }

    ngAfterViewInit() {
        const input = this.input.nativeElement;
        input.addEventListener("input", this.resizeInput.bind(this));
    }

    ngOnInit() {
        $(document).click(
            function () {
                if (this.isEditMode && !this.disabled) {
                    this.complete.emit(this.model);
                    this.isEditMode = false;
                }
            }.bind(this)
        );
    }

    onEdit() {
        this.isEditMode = true;
        setTimeout(
            function () {
                this.input.nativeElement.focus();
            }.bind(this),
            100
        );
    }

    resizeInput() {
        this.input.nativeElement.style.width = "10px";
        this.input.nativeElement.style.width = this.input.nativeElement.scrollWidth + "px";
    }
}
