import { Component, OnInit, Input } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ToastrService } from "ngx-toastr";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { Configuration } from "@app/app.constants";
import { GenericListManagerComponent } from "@app/shared/generic-managers/generic-list-manager/generic-list-manager.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Observable } from "rxjs";
import { inputs } from "@syncfusion/ej2-angular-treegrid/src/treegrid/treegrid.component";

@Component({
    selector: "expandable-list",
    templateUrl: "./expandable-list.component.html",
    styleUrls: ["./expandable-list.component.scss"],
})
export class ExpandableListComponent extends GenericListManagerComponent implements OnInit {
    @Input() config: GenericListConfig;
    @Input() innerConfig: GenericListConfig;
    @Input() innerConfigDataMethod: (guid: string) => Observable<any>;
    @Input() colSpan: number;

    activeRow: any;
    scopedTableData: any[];
    scopedTableExpanded = false;
    scopedToggleAllowed = false;

    constructor(
        standingdataDS: StandingdataDataService,
        toastr: ToastrService,
        configuration: Configuration,
        bsModalService: BsModalService,
        promptService: CerrixPromptService
    ) {
        super(standingdataDS, toastr, configuration, bsModalService, promptService);
    }
    ngOnInit() {
        this.config.reload = async () => {
            this.tableData = null;
            this.baseConfig = this.config;
            await this.loadPage();
        };

        this.baseConfig = this.config;
        this.editor = this.editorCmp;

        this.loadPage();
    }

    triggerClick(row: any) {
        this.activeRow = row;
    }

    toggleScopedTableData() {
        this.scopedTableExpanded = !this.scopedTableExpanded;
        this.setScopedTable();
    }

    setScopedTable() {
        this.scopedToggleAllowed =
            this.config.limitViewTo > 0 &&
            !this.config.isSortable &&
            this.tableData.length > this.config.limitViewTo;

        if (this.scopedToggleAllowed && !this.scopedTableExpanded) {
            this.scopedTableData = this.searchedTableData.slice(0, this.config.limitViewTo);
        } else {
            this.scopedTableData = this.searchedTableData;
        }

        this.config._visibleRowCount = this.searchedTableData.length;
    }

    getLinkedIds(row) {
        this.scopedTableData.filter((a) => a !== row).forEach((x) => (x.showEditor = false));
        this.innerConfig.dataMethod = () => this.innerConfigDataMethod(row.Guid ?? row.guid);

        if (this.innerConfig.reload) {
            this.innerConfig.reload();
        }
    }
}
