import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "cerrix-editor-popup",
    templateUrl: "./cerrix-editor-popup.component.html",
    styleUrls: ["./cerrix-editor-popup.component.scss"],
})
export class CerrixEditorPopupComponent {
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();
    @ViewChild("editorTemplate") editorTemplate: any;

    loadedValue;

    constructor(private promptService: CerrixPromptService) {}

    openPopup() {
        this.loadedValue = this.value;
        this.promptService.prompt({
            maxHeight: "800px",
            maxWidth: "1200px",
            data: {
                title: "Edit Text",
                customTemplate: this.editorTemplate,
                cancelButton: {
                    text: "Close",
                },
                confirmButton: {
                    show: false,
                },
            },
        });
    }
}
