import { mxClient } from "../mxClient";
import { mxgraph as mxType } from "mxgraph-factory";
import { ProcessEditorHelper } from "../helpers/ProcessEditorHelper";
import { nameof } from "@methods/jeffs-toolkit";
import { ProcessCellAttribute } from "@models/processeditor/DiagramData";

export class CerrixCellCodec extends mxClient.mxObjectCodec {
    constructor() {
        super(
            new mxClient.mxCell(),
            ["children", "edges", "overlays", "mxTransient"],
            ["parent", "source", "target"]
        );
    }

    afterDecode(dec: any, node: Element, obj: mxType.mxCell) {
        if (obj.isVertex()) {
            const extra = ProcessEditorHelper.setExtraAttributes(obj.value, <ProcessCellAttribute>{
                shapeName: obj.getShape(),
                label: this.getAttribute(obj.value, (x) => x.label),
                comment: this.getAttribute(obj.value, (x) => x.comment),
                order_nr: this.getAttribute(obj.value, (x) => x.order_nr),
                include_in_print: this.getAttribute(obj.value, (x) => x.include_in_print),
                isEdge: false,
            });

            obj.value = extra;
        }

        return obj;
    }

    private getAttribute(extra: any, nameFunction: (obj: ProcessCellAttribute) => any): string {
        const attributeName = nameof<ProcessCellAttribute>(nameFunction);
        return extra.getAttribute(attributeName) || "";
    }

    isCellCodec() {
        return true;
    }

    /**
     * Overidden to disable conversion of value to number.
     */
    isNumericAttribute(dec, attr, obj) {
        return attr.nodeName !== "value" && super.isNumericAttribute(dec, attr, obj);
    }

    /**
     * Function: isExcluded
     *
     * Excludes user objects that are XML nodes.
     */

    isExcluded(obj, attr, value, isWrite) {
        return (
            super.isExcluded(obj, attr, value, isWrite) ||
            (isWrite &&
                attr === "value" &&
                value.nodeType === mxClient.mxConstants.NODETYPE_ELEMENT)
        );
    }

    /**
     * Function: afterEncode
     *
     * Encodes an <mxCell> and wraps the XML up inside the
     * XML of the user object (inversion).
     */
    afterEncode(enc, obj, node) {
        if (obj.value != null && obj.value.nodeType === mxClient.mxConstants.NODETYPE_ELEMENT) {
            // Wraps the graphical annotation up in the user object (inversion)
            // by putting the result of the default encoding into a clone of the
            // user object (node type 1) and returning this cloned user object.
            const tmp = node;
            node = mxClient.mxUtils.importNode(enc.document, obj.value, true);
            node.appendChild(tmp);

            // Moves the id attribute to the outermost XML node, namely the
            // node which denotes the object boundaries in the file.
            const id = tmp.getAttribute("id");
            node.setAttribute("id", id);
            tmp.removeAttribute("id");
        }

        return node;
    }

    /**
     * Function: beforeDecode
     *
     * Decodes an <mxCell> and uses the enclosing XML node as
     * the user object for the cell (inversion).
     */
    beforeDecode(dec, node, obj) {
        let inner = node.cloneNode(true);
        const classname = this.getName();

        if (node.nodeName != classname) {
            // Passes the inner graphical annotation node to the
            // object codec for further processing of the cell.
            const tmp = node.getElementsByTagName(classname)[0];

            if (tmp != null && tmp.parentNode === node) {
                mxClient.mxUtils.removeWhitespace(tmp, true);
                mxClient.mxUtils.removeWhitespace(tmp, false);
                tmp.parentNode.removeChild(tmp);
                inner = tmp;
            } else {
                inner = null;
            }

            // Creates the user object out of the XML node
            obj.value = node.cloneNode(true);
            const id = obj.value.getAttribute("id");

            if (id != null) {
                obj.setId(id);
                obj.value.removeAttribute("id");
            }
        } else {
            // Uses ID from XML file as ID for cell in model
            obj.setId(node.getAttribute("id"));
        }

        // Preprocesses and removes all Id-references in order to use the
        // correct encoder (this) for the known references to cells (all).
        if (inner != null) {
            for (let i = 0; i < (this as any).idrefs.length; i++) {
                const attr = (this as any).idrefs[i];
                const ref = inner.getAttribute(attr);

                if (ref != null) {
                    inner.removeAttribute(attr);
                    let object = dec.objects[ref] || dec.lookup(ref);

                    if (object == null) {
                        // Needs to decode forward reference
                        const element = dec.getElementById(ref);

                        if (element != null) {
                            const decoder =
                                mxClient.mxCodecRegistry.codecs[element.nodeName] || this;
                            object = decoder.decode(dec, element);
                        }
                    }

                    obj[attr] = object;
                }
            }
        }

        return inner;
    }
}
