<div class="form-group">
    <label
        class="field-title"
        for="field-{{ field.ID }}"
        *ngIf="field.ShowName"
        [title]="field.Required ? 'This field is required.' : ''"
        [ngClass]="{
            'text-success': field.Valid === true && !field.Validation,
            'text-danger': field.Valid === false || field.Validation
        }"
    >
        <i class="fas fa-check-circle" *ngIf="field.Valid === true && !field.Validation"></i>
        <i class="fas fa-exclamation-circle" *ngIf="field.Valid === false || field.Validation"></i>
        {{ field.Name }}
        <span *ngIf="field.Required"> *</span>
    </label>
    <small class="form-text text-danger" *ngIf="field.Validation">{{ field.Validation }}</small>
    <small class="form-text text-muted" [hidden]="!field.AdditionalLabel || readonly">{{
        field.AdditionalLabel
    }}</small>
</div>

<ng-template #customComponent></ng-template>

<div class="form-group field-comment" *ngIf="isReview">
    <cerrix-textarea
        [(ngModel)]="comment"
        (valueChange)="emitChange()"
        placeholder="Field review comment"
    ></cerrix-textarea>
</div>

<div class="form-group field-comment" *ngIf="!isReview && comment">
    <small class="text-muted">Comment from reviewer:</small><br />
    <pre class="readonly-comment">{{ comment }}</pre>
</div>
