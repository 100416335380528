import { Component, Input, OnInit } from "@angular/core";
import { EffectivenessModel, EvidenceSampleEditModel } from "../../../shared/models";

@Component({
    selector: "eff-test-instructions-info-box",
    templateUrl: "./eff-test-instructions-info-box.component.html",
    styleUrls: ["./eff-test-instructions-info-box.component.scss"],
})
export class EffTestInstructionsInfoBoxComponent implements OnInit {
    @Input() effectivenessGuid: string;
    @Input() samples: EvidenceSampleEditModel[];
    @Input() effectivenessModel: EffectivenessModel;
    @Input() showEvidenceUploadInstructions: boolean = false;
    @Input() panelOpenState: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
