<mat-accordion class="history px-1" *ngIf="documentHistory">
    <mat-expansion-panel #matExpensionPanel *ngFor="let historyEntry of documentHistory">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="ms-2 text-nowrap">Version: {{ historyEntry.Version }}</span>
                <i class="p-2 text-truncate">{{ historyEntry.Name }}</i>
            </mat-panel-title>
            <mat-panel-description>
                <a
                    class="fas fa-download"
                    href="api/documents/{{ historyEntry.Guid }}/data"
                    HrefTarget="_blank"
                ></a>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <table class="table">
            <tbody>
                <tr>
                    <th>User</th>
                    <td>{{ historyEntry.Username }}</td>
                </tr>
                <tr>
                    <th>Date</th>
                    <td>{{ historyEntry.Date | dateTimeFormat }}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{{ historyEntry.DocumentType }}</td>
                </tr>
                <tr>
                    <th>Description</th>
                    <td tooltip="{{ historyEntry.Description }}">{{ historyEntry.Description }}</td>
                </tr>
                <tr>
                    <th>File size</th>
                    <td>{{ historyEntry.DataSize | fileSize }}</td>
                </tr>
            </tbody>
        </table>
    </mat-expansion-panel>
</mat-accordion>
