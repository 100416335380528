import { Component, OnInit } from "@angular/core";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { AuditWorkspaceModel } from "@app/audit/shared/data-models/audit-workspace-model";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { FilterType } from "@enums/FilterType";
import { ModuleSubType } from "@enums/ModuleSubType";
import { ModuleType } from "@enums/ModuleType";
import { nameof } from "@methods/jeffs-toolkit";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "audit-overview",
    templateUrl: "./audit-overview.component.html",
    styleUrls: ["./audit-overview.component.scss"],
})
export class AuditOverviewComponent implements OnInit {
    moduleType = ModuleType.AUDIT;
    moduleSubType = ModuleSubType.Audits;

    appendToModuleType = ModuleSubType[ModuleSubType.Audits];
    tabID: string;
    tab: TabModel;

    data: Promise<AuditWorkspaceModel[]>;

    headerLookup: { [propName: string]: string } = AuditOverviewComponent.getHeaderLookup();

    private static getHeaderLookup(): { [propName: string]: string } {
        let headerLookup: any = {};
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditName)] = "Audit Name";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditScore)] = "Audit Score";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.criticality)] = "Criticality";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditConclusion)] = "Audit Conclusion";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditStatus)] = "Audit Status";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.numberOfFindings)] = "# of Findings";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditType)] = "Audit Type";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditPlan)] = "Audit Plan";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditObjective)] = "Audit Objective";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.confidentiality)] = "Confidentiality";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.organizations)] = "Organization(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.businessDimensions)] =
            "Business Dimension(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.frameworkDimensions)] =
            "Framework Dimension(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.rationale)] = "Rationale";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.sponsors)] = "Sponsor(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditees)] = "Auditee(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.auditors)] = "Auditor(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.reviewers)] = "Reviewer(s)";
        headerLookup[nameof<AuditWorkspaceModel>((x) => x.chiefAuditExecutives)] =
            "Chief Audit Executive(s)";

        return headerLookup;
    }

    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: nameof<AuditWorkspaceModel>((x) => x.organizationIds),
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: nameof<AuditWorkspaceModel>((x) => x.businessDimensionIds),
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: nameof<AuditWorkspaceModel>((x) => x.frameworkDimensionIds),
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];
    rendererConfig: RendererConfig[] = [
        RendererConfig.createScoreConfig(
            nameof<AuditWorkspaceModel>((x) => x.criticality),
            nameof<AuditWorkspaceModel>((x) => x.criticalityColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<AuditWorkspaceModel>((x) => x.auditScore),
            nameof<AuditWorkspaceModel>((x) => x.auditScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<AuditWorkspaceModel>((x) => x.confidentiality),
            nameof<AuditWorkspaceModel>((x) => x.confidentialityColor)
        ),
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];

    dateProps: string[] = [];

    numberProps: string[] = [nameof<AuditWorkspaceModel>((x) => x.numberOfFindings)];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _pages: Pages,
        private _tabs: TabService,
        private _permissions: PermissionsService,
        private _promptService: CerrixPromptService,
        private _ds: AuditDataService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.audit;
        this.data = this._ds.getList().then(this.mapHeaders);
    }

    openDetail(audit: AuditWorkspaceModel) {
        this._tabs.generateTab(
            this._pages.Audit,
            audit.guid,
            `(A) ${+audit.identifier} - ${audit.auditName}`
        );
    }

    add() {
        this._tabs.generateTab(this._pages.Audit, null, "(A) New Audit");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
