<app-linkable
    typename="data processing"
    [displayValueProp]="displayValueProp"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    (onOpenClicked)="openItem($event)"
    [hideActions]="hideActions"
    [hideFilter]="hideFilter"
    [allowAddNew]="allowAddNew"
>
</app-linkable>
