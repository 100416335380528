import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TpaConnectionDataService } from "@app/external-connections/services/tpa-connection.data.service";
import { CerrixWizardComponent } from "@app/shared/cerrix-wizard/cerrix-wizard.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { LinkableIconConfig } from "@app/shared/models/Linkable/LinkableIconConfig";
import {
    CerrixWizardConfig,
    CerrixWizardStepConfig,
} from "@app/shared/models/WizardModels/CerrixWizardConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { RendererType } from "@enums/RendererType";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { isGuid } from "@methods/uniqueMethods";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { ToastrService } from "ngx-toastr";
import { ConnectionWizardModel } from "../models/ConnectionWizardModel";
import { TpaControlLinkModel } from "../models/TpaControlLinkModel";
import { TpaControlLinkableComponent } from "./tpa-control-linkable/tpa-control-linkable.component";

@Component({
    selector: "app-tpa-connection-wizard",
    templateUrl: "./connection-wizard.component.html",
    styleUrls: ["./connection-wizard.component.scss"],
})
export class TpaConnectionWizardComponent implements OnInit {
    @ViewChild("wizard") wizard: CerrixWizardComponent;
    @ViewChild("linkable") linkable: TpaControlLinkableComponent;

    @Input() connectionGuid: string;
    @Output() reload: EventEmitter<void> = new EventEmitter();

    initialLoadCompleted: boolean = false;
    summary: boolean = true;

    nameGroup: FormGroup;
    apiKeyGroup: FormGroup;
    controlGroup: FormGroup;
    testInformationGroup: FormGroup;
    wizardConfig: CerrixWizardConfig;

    connectionWizardModel: ConnectionWizardModel;
    apiSoftIpActive: boolean;

    isSummary: boolean = false;

    minDate: Date;
    maxDate: Date;

    // region controls tab
    disabled: boolean = false;
    displayProperty: string = "name";
    rowIconConfig: LinkableIconConfig[] = [
        {
            icon: "fas fa-key",
            actionColumn: nameof<ControlLinkModel>(
                (c: ControlLinkModel): boolean => c.isKeyControl
            ),
            actionValue: true,
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            type: RendererType.Score,
            textColumn: "Effectiveness score",
            actionColumn: "Effectiveness score color",
            hideActionColumn: true,
        },
    ];

    // end region controls tab

    constructor(
        private _promptService: CerrixPromptService,
        private _tpaDatasService: TpaConnectionDataService,
        private _toastr: ToastrService
    ) {
        this.minDate = new Date();
        this.minDate.setHours(0, 0, 0, 0);
        this.maxDate = new Date();
        this.maxDate.setUTCFullYear(this.maxDate.getUTCFullYear() + 1);
    }
    async ngOnInit(): Promise<void> {
        this.connectionWizardModel = new ConnectionWizardModel();

        const wizardSetup = await toPromise(this._tpaDatasService.getWizardSetup());
        this.apiSoftIpActive = wizardSetup.apiSoftIpActive;

        this.initFormGroups();
        this.setupWizard();
    }

    initFormGroups(): void {
        this.nameGroup = new FormGroup({
            name: new FormControl(this.connectionWizardModel.name, Validators.required),
            description: new FormControl(this.connectionWizardModel.description),
            employee: new FormControl(this.connectionWizardModel.employee),
        });

        this.apiKeyGroup = new FormGroup({
            expirationDate: new FormControl(this.connectionWizardModel.expirationDate, [
                Validators.required,
                FormValidationHelper.validateExpirationDate(this.minDate, this.maxDate),
            ]),
            ipRestriction: new FormControl(
                this.connectionWizardModel.ipRestriction,
                this.apiSoftIpActive ? Validators.required : null
            ),
        });

        this.controlGroup = new FormGroup({
            linkedControls: new FormControl(this.connectionWizardModel.linkedControls),
        });

        this.testInformationGroup = new FormGroup(
            {
                testplanType: new FormControl(
                    this.connectionWizardModel.testplanType,
                    Validators.required
                ),
                startDate: new FormControl(
                    this.connectionWizardModel.startDate,
                    Validators.required
                ),
                endDate: new FormControl(this.connectionWizardModel.endDate, Validators.required),
            },
            {
                validators: [
                    FormValidationHelper.validateStartEndDate<ConnectionWizardModel>(
                        nameof<ConnectionWizardModel>(
                            (c: ConnectionWizardModel): Date => c.startDate
                        ),
                        nameof<ConnectionWizardModel>(
                            (c: ConnectionWizardModel): Date => c.endDate
                        ),
                        "End Date may not be before Start Date",
                        false
                    ),
                ],
            }
        );
    }

    setupWizard(): void {
        this.wizardConfig = <CerrixWizardConfig>{
            showInModal: true,
            enableSummary: true,
            enableValidation: true,
            activePageIndex: 0,
            steps: ["Basic information", "API Key details", "Controls", "Test information"].map(
                (s: string): CerrixWizardStepConfig =>
                    <CerrixWizardStepConfig>{
                        name: s,
                        valid: null,
                    }
            ),

            useDedicatedClose: true,
            allowPageChange: true,
            buttons: [],

            showModalOnComplete: true,
            completeModalTitle: "Third party assurance connection created.",
            completeModalSubtitle: "Click close to close this dialog and return to the overview.",
            reload: async (): Promise<boolean> => {
                return true;
            },
            complete: async (): Promise<boolean> => {
                FormValidationHelper.mapToModel(this.nameGroup, this.connectionWizardModel);
                FormValidationHelper.mapToModel(this.apiKeyGroup, this.connectionWizardModel);
                this.connectionWizardModel.linkedControls = this.linkable.linked.map(
                    (c: TpaControlLinkModel): number => c.id
                );
                FormValidationHelper.mapToModel(
                    this.testInformationGroup,
                    this.connectionWizardModel
                );

                if (this.connectionGuid) {
                    this.wizardConfig.completeModalTitle =
                        "Third party assurance connection has been updated";
                } else {
                    this.wizardConfig.completeModalTitle =
                        "Third party assurance connection has been created";
                }

                const saveResult = await this._tpaDatasService
                    .storeTpaConnection(this.connectionGuid, this.connectionWizardModel)
                    .toPromise();

                if (saveResult && isGuid(saveResult.guid)) {
                    this.reload.emit();
                    this.resetPage();
                    if (!this.connectionGuid) {
                        this.wizardConfig.completeModalSubtitle = `Please save the following information. This can only be provided once.

                    Identifier: ${saveResult.identifier}
                    Password: ${saveResult.password}
                    
                    Click close to close this dialog and return to the overview.`;
                    } else {
                        this.wizardConfig.completeModalSubtitle =
                            "Click close to close this dialog and return to the overview.";
                    }
                    return true;
                } else {
                    this._toastr.error("An error occurred during saving.", "Validation error");
                }

                return false;
            },

            changeToPage: (fromPage: number, toPage: number): boolean => {
                const maxIndex = this.wizardConfig.steps.length;

                if (fromPage === 0) {
                    FormValidationHelper.markAllAsTouched(this.nameGroup);
                    this.setPageValidity(fromPage, this.nameGroup);
                } else if (fromPage === 1) {
                    FormValidationHelper.markAllAsTouched(this.apiKeyGroup);
                    this.setPageValidity(fromPage, this.apiKeyGroup);
                } else if (fromPage === 2) {
                    FormValidationHelper.markAllAsTouched(this.controlGroup);
                    this.setPageValidityControls(fromPage, this.controlGroup);
                } else if (fromPage === 3) {
                    FormValidationHelper.markAllAsTouched(this.testInformationGroup);
                    this.setPageValidity(fromPage, this.testInformationGroup);
                }

                // If user is going to summary.
                this.isSummary = toPage === maxIndex;
                if (this.isSummary) {
                    this.linkable.showLinkPanel = false;
                    this.linkable.disabled = true;
                    this.setWizardCompletable();
                } else {
                    this.linkable.showLinkPanel = true;
                    this.linkable.disabled = false;
                }

                this.setDisabledControls();
                return true;
            },
        };
    }

    setDisabledControls(): void {
        const stopEmit = { emitEvent: false };
        if (this.isSummary) {
            this.disableAllControls(stopEmit);
            return;
        }
        this.nameGroup.enable(stopEmit);
        this.apiKeyGroup.enable(stopEmit);
        this.controlGroup.enable(stopEmit);
        this.testInformationGroup.enable(stopEmit);
    }

    disableAllControls(stopEmit: any): void {
        this.nameGroup.disable(stopEmit);
        this.apiKeyGroup.disable(stopEmit);
        this.testInformationGroup.disable(stopEmit);
        this.controlGroup.disable(stopEmit);
    }

    setPageValidity(pageIndex: number, formGroup?: FormGroup, overrideValidity?: boolean): void {
        let valid = overrideValidity;
        if (!overrideValidity && formGroup) {
            if (!FormValidationHelper.allFormControlsTouched(formGroup)) {
                valid = null;
            } else {
                valid = formGroup.valid || formGroup.disabled;
            }
        }

        // this will check if the form / wizard can be completed, after changing a value.
        if (this.isSummary) {
            this.setWizardCompletableValue();
        }

        this.wizardConfig.steps[pageIndex].valid = valid;
    }

    setPageValidityControls(
        pageIndex: number,
        formGroup?: FormGroup,
        overrideValidity?: boolean
    ): void {
        let valid = overrideValidity;

        this.connectionWizardModel.linkedControls = this.linkable.linked.map(
            (c: TpaControlLinkModel): number => c.id
        );

        if (!overrideValidity && formGroup) {
            if (!FormValidationHelper.allFormControlsTouched(formGroup)) {
                valid = null;
            } else if (
                this.connectionWizardModel.linkedControls &&
                this.connectionWizardModel.linkedControls.length > 0
            ) {
                valid = true;
            } else {
                valid = false;
            }

            // reset filters, so you can see all items, and not only the filtered ones, because otherwise you can have more results in the connection as shown in summary
            this.linkable.resetFilters();
        }

        // this will check if the form / wizard can be completed, after changing a value.
        if (this.isSummary) {
            this.setWizardCompletableValue();
        }

        this.wizardConfig.steps[pageIndex].valid = valid;
    }

    async load(openAfterLoad: boolean): Promise<void> {
        if (this.initialLoadCompleted) {
            this.resetPage();
        }

        let loadingPrompt;
        if (openAfterLoad) {
            loadingPrompt = this._promptService.loader("Loading wizard, please wait...");
        }

        try {
            if (this.connectionGuid) {
                this.connectionWizardModel = await this._tpaDatasService
                    .getTpaConnectionWizardModel(this.connectionGuid)
                    .toPromise();
            } else {
                this.connectionWizardModel = new ConnectionWizardModel();
            }

            await this.applyModel(true);

            this.initialLoadCompleted = true;
            await this.linkable.reload(this.connectionWizardModel.linkedControls);

            if (openAfterLoad) {
                this.setDisabledControls();
                loadingPrompt.close();
                loadingPrompt = null;

                this.wizard.openWizard();
            }
        } finally {
            if (loadingPrompt) {
                loadingPrompt.close();
            }
        }
    }

    resetPage(): void {
        this.untouchPage();

        // This part will reset all variables that have a role in the wizard.
        this.connectionWizardModel = null;
        this.isSummary = false;
    }

    // This will 'untouch' the wizard, so it goes back to page 1 and all fields are untouched.
    untouchPage(): void {
        FormValidationHelper.markAllAsUntouched(this.nameGroup);
        FormValidationHelper.markAllAsUntouched(this.apiKeyGroup);
        FormValidationHelper.markAllAsUntouched(this.controlGroup);
        FormValidationHelper.markAllAsUntouched(this.testInformationGroup);

        this.wizardConfig.completable = false;
        this.wizardConfig.allowPageChange = true;
        this.wizardConfig.activePageIndex = 0;
        this.wizardConfig.steps.forEach((s: CerrixWizardStepConfig): void => {
            s.valid = null;
            s.hide = false;
        });
    }

    applyModel(reset: boolean): void {
        FormValidationHelper.updateFormGroup(this.connectionWizardModel, this.nameGroup);
        FormValidationHelper.updateFormGroup(this.connectionWizardModel, this.apiKeyGroup);
        FormValidationHelper.updateFormGroup(this.connectionWizardModel, this.controlGroup);
        FormValidationHelper.updateFormGroup(this.connectionWizardModel, this.testInformationGroup);

        if (reset) {
            this.untouchPage();
        }
    }

    setWizardCompletable(): void {
        // Make sure all fields are touched so valid check will work correctly.
        FormValidationHelper.markAllAsTouched(this.nameGroup);
        FormValidationHelper.markAllAsTouched(this.apiKeyGroup);
        FormValidationHelper.markAllAsTouched(this.controlGroup);
        FormValidationHelper.markAllAsTouched(this.testInformationGroup);

        this.setPageValidity(0, this.nameGroup);
        this.setPageValidity(1, this.apiKeyGroup);
        this.setPageValidityControls(2, this.controlGroup);
        this.setPageValidity(3, this.testInformationGroup);

        this.setWizardCompletableValue();
    }

    setWizardCompletableValue(): void {
        this.wizardConfig.completable =
            (this.nameGroup.valid || this.nameGroup.disabled) &&
            (this.apiKeyGroup.valid || this.apiKeyGroup.disabled) &&
            this.controlGroup.valid &&
            this.linkable &&
            this.linkable.linked.any() &&
            (this.testInformationGroup.valid || this.testInformationGroup.disabled);
    }
}
