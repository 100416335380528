<div class="override-toggle">
    <cerrix-checkbox
        label="Use parent / default risk matrix."
        [(value)]="row.UseParentOrDefaultMatrix"
    ></cerrix-checkbox>
</div>
<hr />
<div class="parent-matrix-viewer" *ngIf="row.UseParentOrDefaultMatrix && row.ParentMatrix">
    <label>
        Preview of the current parent / default matrix that will be applied to this organization.
    </label>
    <cerrix-riskmatrix-summary
        [matrix]="row.ParentMatrix"
        [cellSize]="cellSize"
        [cellSpacing]="cellSpacing"
    ></cerrix-riskmatrix-summary>
</div>
<div class="parent-matrix-viewer" *ngIf="row.UseParentOrDefaultMatrix && !row.ParentMatrix">
    <label>
        Risk matrix of configured parent (or default fallback matrix) will be used. No preview
        available currently.
    </label>
</div>
<div class="risk-matrix-editor" *ngIf="value && !row.UseParentOrDefaultMatrix">
    <div class="left-area" *ngIf="summaryListConfig">
        <generic-list-manager [config]="summaryListConfig"></generic-list-manager>
    </div>

    <div class="right-area">
        <cerrix-riskmatrix-summary
            [matrix]="value"
            [cellSize]="cellSize"
            [cellSpacing]="cellSpacing"
            (cellClick)="matrixCellClick($event.Likelihood, $event.Impact)"
        ></cerrix-riskmatrix-summary>
    </div>
</div>
