<div class="detail-page-card">
    <div class="detail-page-card-title" *ngIf="!config.hideHeader">{{ config.name }}</div>
    <div class="detail-page-card-actions">
        <div
            *ngIf="
                (config.allowAdd && !config.hideAddButton) ||
                (config.allowDelete && !config.hideDeleteButton)
            "
        >
            <button
                [attr.data-uiid]="config.uiidAdd"
                class="btn btn-raised"
                (click)="addRow()"
                *ngIf="config.allowAdd && !config.hideAddButton"
            >
                <i class="fas fa-plus-circle"></i>
                <span>Add</span>
            </button>
            <button
                [attr.data-uiid]="config.uiidDelete"
                class="btn btn-raised red"
                (click)="deleteRow(this.activeRow)"
                *ngIf="config.allowDelete && !config.hideDeleteButton"
                [disabled]="!activeRow"
            >
                <i class="fas fa-trash"></i>
                <span>Delete</span>
            </button>
        </div>
        <div *ngIf="config.showToolbarSearch">
            <input
                type="text"
                class="form-control"
                placeholder="Quick search / filter"
                (debounce-input-event)="config.onSearch($event)"
                inputTooltip
            />
        </div>
        <div *ngIf="config.allowLink && !config.hideLinkButton">
            <button
                class="btn btn-raised blue"
                (click)="openLinkModal()"
                *ngIf="!config.hideLinkButton"
            >
                <i class="fas fa-link"></i>
                <span>Link</span>
            </button>
        </div>
        <div *ngIf="config.dataMethod && !config.hideRefreshButton">
            <button
                class="btn btn-raised green"
                (click)="loadPage()"
                *ngIf="config.dataMethod && !config.hideRefreshButton"
            >
                <i class="fas fa-sync"></i>
                <span>Reload</span>
            </button>
        </div>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm" *ngIf="!ready">
        <div class="fancy-loader submodule"></div>
    </div>

    <div
        class="detail-page-card-body padded"
        *ngIf="ready && (!tableData || tableData.length == 0)"
    >
        <small *ngIf="config.emptyMessage">{{ config.emptyMessage }}</small>
        <small *ngIf="!config.emptyMessage">No {{ config.name | lowercase }} available.</small>
        <small *ngIf="!config.emptyMessage && config.allowAdd"
            >&nbsp;<b>Click add</b> to create a new one.</small
        >
    </div>
    <div
        class="detail-page-card-body overflow-auto"
        *ngIf="scopedTableData && scopedTableData.length > 0"
    >
        <table class="table-cerrix rounded striped bordered-columns reset table-hoverable">
            <thead>
                <tr>
                    <th class="small-header"></th>
                    <ng-template ngFor let-field [ngForOf]="config.fields">
                        <th class="text-center" *ngIf="!field.hideInOverview">
                            {{ field.prettyName ? field.prettyName : field.fieldName }}
                        </th>
                    </ng-template>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-outerData [ngForOf]="scopedTableData">
                    <tr
                        class="row-hover"
                        [ngClass]="{
                            highlighted: activeRow && activeRow[idProp] == outerData[idProp]
                        }"
                        (click)="triggerClick(outerData)"
                    >
                        <td
                            class="text-center small-header"
                            (click)="
                                getLinkedIds(outerData);
                                outerData.showEditor = !outerData.showEditor
                            "
                        >
                            <div>
                                <span>
                                    <i
                                        class="far"
                                        [class.fa-minus-square]="outerData.showEditor"
                                        [class.fa-plus-square]="!outerData.showEditor"
                                    ></i>
                                </span>
                            </div>
                        </td>
                        <ng-template ngFor let-field [ngForOf]="config.fields">
                            <td class="text-truncate text-center" *ngIf="!field.hideInOverview">
                                <workspace-cell
                                    [configs]="config.rendererConfig"
                                    [row]="outerData"
                                    [header]="field.fieldName"
                                >
                                </workspace-cell>
                            </td>
                        </ng-template>
                        <td class="text-center">
                            <span *ngFor="let action of config.overviewRowActions">
                                <i
                                    class="{{ action.icon }}"
                                    matTooltip="{{ action.tooltip }}"
                                    (click)="action.clickEvent(outerData)"
                                >
                                </i>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="outerData.showEditor" class="inner-table">
                        <td [colSpan]="colSpan">
                            <generic-list-manager [config]="innerConfig" *ngIf="innerConfig">
                            </generic-list-manager>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
        <div
            class="custom-pagination"
            (click)="toggleScopedTableData()"
            *ngIf="scopedToggleAllowed"
        >
            View
            {{
                scopedTableExpanded
                    ? "last " + this.config.limitViewTo
                    : "all " + this.config._visibleRowCount
            }}
        </div>
    </div>
</div>
