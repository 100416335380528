import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { OrganizationBudgetModel } from "./models/OrganizationBudgetModel";

@Injectable()
export class OrganizationBudgetDataService extends ApiDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "organization/budgets");
    }

    getOrgBudgets(): Observable<OrganizationBudgetModel[]> {
        return this.get<OrganizationBudgetModel[]>();
    }

    storeOrgBudgets(model: OrganizationBudgetModel[]): Observable<HttpEvent<any>> {
        return this.post(model);
    }
}
