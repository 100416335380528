import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StandingdataDataService } from "./shared/standingdata.service";
import { StandingDataGenericComponent } from "./generic/standingdata-generic.component";
import { StandingDataOverviewComponent } from "./standingdata-overview/standingdata-overview.component";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { StandingDataListViewComponent } from "./generic/standing-data-list-view/standing-data-list-view.component";
import { StandingDataTreeViewComponent } from "./generic/standing-data-tree-view/standing-data-tree-view.component";
import { StandingDataEditorComponent } from "./generic/standing-data-editor/standing-data-editor.component";

@NgModule({
    declarations: [
        StandingDataGenericComponent,
        StandingDataOverviewComponent,
        StandingDataListViewComponent,
        StandingDataTreeViewComponent,
        StandingDataEditorComponent,
    ],
    exports: [StandingDataGenericComponent],
    imports: [CommonModule, FormsModule, SharedModule],
})
export class StandingdataModule {
    static forRoot(): ModuleWithProviders<StandingdataModule> {
        return {
            ngModule: StandingdataModule,
            providers: [StandingdataDataService],
        };
    }
}
