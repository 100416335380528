import { Component, ElementRef, ViewChild } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { ThirdPartyDataService } from "@pages/thirdparty/services/ThirdPartyDataService";
import { TabService } from "@services/tabs/TabService";
import { SeriesColumnOptions } from "highcharts";
import { BaseHighchartWidget } from "../BaseHighchartWidget";
import { toPromise } from "@methods/CommonMethods";

@Component({
    selector: "thirdparty-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class ThirdPartyWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private thirdpartyDS: ThirdPartyDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[this.filterKeys.GlobalFilter.Organization] =
            "OrganizationResponsibleID";

        this.globalFilterWorkspaceColumns[this.filterKeys.GlobalFilter.BusinessDimension] =
            "BusinessDimensionIds";
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await toPromise(this.thirdpartyDS.getWidgetInfo(filters));
        
        this.config.noData = !data || !data.some(tpScore => tpScore.y > 0)
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <any>{
                colorByPoint: true,
                data,
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);
        config.legend = { enabled: false };
        config.xAxis = {
            type: "category",
        };

        config.yAxis = {
            allowDecimals: false,
            title: {
                text: "Number of third parties",
            },
        };

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        return config;
    }

    protected async openWorkspace(ev: Highcharts.PointClickEventObject) {
        const type = ev.point.options.name.toString();

        const searchKeywords: SearchKeyword[] = [this.getSearchKeyword("Review score", type)];

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.Thirdparty, preset);
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.Thirdparty, preset);
    }
}
