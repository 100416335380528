<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar" *ngIf="settings">
    <button class="btn btn-cerrix" (click)="save()" [disabled]="!formDirty">
        <i class="fas fa-save"></i>
        <span class="d-none d-lg-inline">Save settings</span>
    </button>

    <span class="date-edited" *ngIf="settings.DateModified">
        Last save: {{settings.DateModified | dateTimeFormat}}
    </span>
</div>

<div class="detail-page-card" *ngIf="settings">
    <div class="detail-page-card-title">Personal calendar settings</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="form-group">
            <label for="frequencySelect">Show items in my schedule for next:</label>
            <ng-select id="frequencySelect" name="frequencySelect" [items]="settings.AllFrequencies"
                [(ngModel)]="settings.SelectedFrequency" bindLabel="Value" bindValue="Key" (change)="changed()">
            </ng-select>
        </div>

        <div class="row" *ngIf="settings.SelectedFrequency == 4">
            <div class="col-6">
                <div class="form-group">
                    <label>From</label>
                    <date-input [(value)]="settings.CustomDateFrom" (valueChange)="changed()"></date-input>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Till</label>
                    <date-input [(value)]="settings.CustomDateTill" (valueChange)="changed()">

                    </date-input>
                </div>
            </div>
        </div>

        <cerrix-checkbox [(value)]="settings.ShowOverdueItems" (valueChange)="changed()"
            label="Show items that are overdue" fontsize="0.9em">
        </cerrix-checkbox>

        <div>
            <small class="text-truncated">
                Overdue items, no matter the indicated date values above, will be shown with a exclamation icon
                (<i class="fas fa-exclamation-triangle"></i>)
            </small>
        </div>
    </div>
</div>