import { Component, Input, ViewChild } from "@angular/core";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { LinkedMoiOverviewComponent } from "@app/moi/moi-linked-overview/moi-linked-overview.component";
import { LinkableComponent } from "@app/shared/linkable/linkable.component";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { ControlModel } from "@app/controls/models/ControlModel";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { ControlDetailComponent } from "../control-detail.component";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { LinkModel } from "@models/generic/LinkModel";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "control-mois",
    templateUrl: "./control-mois.component.html",
    styleUrls: ["./control-mois.component.scss"],
})
export class ControlMoiComponent {
    @Input() controlModel: ControlModel;
    @Input() moiType: MoiTypes;
    @Input() parentGuid: string;
    @Input() permissions: ControlPermissionModel;

    @ViewChild("linkedControlMoisComponent")
    linkedControlMoisComponent: LinkedMoiOverviewComponent;
    @ViewChild("linkedImMoi") linkedImMoi: LinkableComponent;

    isLoading = true;
    // Link IM MOIS
    displayValueProp = "name";
    linked: LinkModel[] = [];
    unlinked: LinkModel[] = [];
    disabled: false;
    hideActions: true;
    hideFilter: false;
    allowAddNew = false;

    hasAccessChecks = {};
    accessCheckInProgress = false;
    cdc: ControlDetailComponent;

    constructor(
        private _controlDS: ControlDataService,
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        private _moiDS: MoiDataService,
        private _promptService: CerrixPromptService
    ) {}

    load(refresh: boolean, cdc: ControlDetailComponent): void {
        this.linkedControlMoisComponent.load(refresh);
        this.cdc = cdc;

        if (!this.controlModel.Organization) {
            this._toastr.warning("Select organization first!");
            this.isLoading = false;
            return;
        }

        this._controlDS
            .getLinkableMois(this.controlModel.Guid, this.controlModel.Organization)
            .subscribe((data) => {
                this.loadData(data, this.controlModel.MoiIdList);
                this.isLoading = false;
            });
    }

    onLinkedItemsChanged(linked: LinkModel[]): void {
        const newMoisList = linked.map((x) => x.id);
        this.controlModel.MoiIdList = newMoisList.sort((a, b) => a - b);

        const isChanged = this.controlModel.MoiIdList.isDifferent(
            this.cdc.detailMain.backupModel.MoiIdList
        );
        TabComponentHelper.toggleTabDirty(this.cdc.tab, "menuItemMoi", isChanged);
    }

    private loadData(mois: LinkModel[], linkedMois: number[]): void {
        const linked: LinkModel[] = [];
        const unlinked: LinkModel[] = [];

        if (mois) {
            mois.forEach((m) => {
                if (linkedMois && linkedMois.find((x) => x === m.id)) {
                    linked.push(m);
                } else {
                    unlinked.pushIfNotExists(m);
                }
            });
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }

    openItem(item: IHasGuid): void {
        const hasAccess = this.hasAccessChecks[item.guid];
        if (hasAccess == null && !this.accessCheckInProgress) {
            this.accessCheckInProgress = true;

            const loadingPrompt = this._promptService.loader("Checking rights, please wait...");

            this.hasAccessToItem(item.guid)
                .pipe(finalize(() => loadingPrompt.close()))
                .subscribe((x) => {
                    this.hasAccessChecks[item.guid] = x;

                    this.openTab(item, x);
                    this.accessCheckInProgress = false;
                });
        } else {
            this.openTab(item, hasAccess);
        }
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._moiDS.hasAccessToMoi(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean): void {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.MoiDetail,
                item.guid,
                `(M) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this Moi.");
        }
    }
}
