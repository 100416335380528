<div class="fancy-loader submodule my-4" *ngIf="isLoading"></div>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="overviewConfig">
        <div class="detail-page-action-bar flex-action-bar">
            <div></div>
            <div class="right-area">
                <button (click)="clearDiscoveryCache()" class="btn btn-dark">
                    <i class="fas fa-plus me-2"></i>
                    Clear Cache
                </button>
            </div>
        </div>
        <div></div>
        <div>
            <generic-grouped-list-manager [config]="overviewConfig"></generic-grouped-list-manager>
        </div>
    </ng-container>
</ng-container>
