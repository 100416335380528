import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentUserModel } from "../models/incident-user.model";
import { UserModel } from "@cerrix/components";

@Injectable({
    providedIn: "root",
})
export class IncidentUsersService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/users`;

    public getAll(): Observable<IncidentUserModel[]> {
        return this.httpClient.get<IncidentUserModel[]>(this.baseUrl);
    }

    public getUsersForStaffing(): Observable<Map<string, UserModel>> {
        return this.httpClient.get<Map<string, UserModel>>(`${this.baseUrl}/staffing`);
    }
}
