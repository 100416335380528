<div class="detail-page-card" [formGroup]="parentForm">
    <div class="detail-page-card-title">Quantitative scoring</div>
    <div class="detail-page-card-body padded cerrix-control-sm">
        <!-- Gross Quantitative scoring -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="grossQuantitativeImpact">Gross Single loss Expectancy</label>
                    <input
                        class="form-control form-control-sm"
                        id="grossQuantitativeImpact"
                        name="grossQuantitativeImpact"
                        type="number"
                        formControlName="grossQuantitativeImpact"
                        [placeholder]="
                            activeGrossImpactModel
                                ? activeGrossImpactModel._prettyPlaceholderText
                                : ''
                        "
                        inputTooltip
                        (keyup)="calculateGross()"
                    />
                    <div
                        class="form-text"
                        [class.red]="!grossImpactValid"
                        *ngIf="activeGrossImpactModel"
                    >
                        {{ activeGrossImpactModel._prettyRangeText }}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="grossQuantitativeLikelihood">Gross Likelihood %</label>
                    <div class="input-group input-group-sm">
                        <input
                            class="form-control form-control-sm"
                            id="grossQuantitativeLikelihood"
                            name="grossQuantitativeLikelihood"
                            type="number"
                            formControlName="grossQuantitativeLikelihood"
                            [placeholder]="
                                activeGrossLikelihoodModel
                                    ? activeGrossLikelihoodModel._prettyPlaceholderText
                                    : ''
                            "
                            inputTooltip
                            (keyup)="calculateGross()"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                    <div
                        class="form-text"
                        [class.red]="!grossLikelihoodValid"
                        *ngIf="activeGrossLikelihoodModel"
                    >
                        {{ activeGrossLikelihoodModel._prettyRangeText }}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label>
                        Gross Annualized loss Expectancy
                        <i
                            class="fas fa-question-circle"
                            title="Gross Single Loss Expectancy * Gross Likelihood %"
                        ></i>
                    </label>
                    <div class="ale-value-field">
                        <span *ngIf="grossAleCalculating">
                            <i class="fas fa-circle-notch fa-spin"></i>
                            Calculating...
                        </span>
                        <span *ngIf="grossAle">{{ grossAle }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Net Quantitative scoring -->
        <div class="row mt-2">
            <div class="col">
                <div class="form-group">
                    <label for="netQuantitativeImpact">Net Single loss Expectancy</label>
                    <input
                        class="form-control form-control-sm"
                        id="netQuantitativeImpact"
                        name="netQuantitativeImpact"
                        type="number"
                        formControlName="netQuantitativeImpact"
                        [placeholder]="
                            activeNetImpactModel ? activeNetImpactModel._prettyPlaceholderText : ''
                        "
                        inputTooltip
                        (keyup)="calculateNet()"
                    />
                    <div
                        class="form-text"
                        [class.red]="!netImpactValid"
                        *ngIf="activeNetImpactModel"
                    >
                        {{ activeNetImpactModel._prettyRangeText }}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="netQuantitativeLikelihood">Net Likelihood %</label>
                    <div class="input-group input-group-sm">
                        <input
                            class="form-control form-control-sm"
                            id="netQuantitativeLikelihood"
                            name="netQuantitativeLikelihood"
                            type="number"
                            formControlName="netQuantitativeLikelihood"
                            [placeholder]="
                                activeNetLikelihoodModel
                                    ? activeNetLikelihoodModel._prettyPlaceholderText
                                    : ''
                            "
                            inputTooltip
                            (keyup)="calculateNet()"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                    <div
                        class="form-text"
                        [class.red]="!netLikelihoodValid"
                        *ngIf="activeNetLikelihoodModel"
                    >
                        {{ activeNetLikelihoodModel._prettyRangeText }}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label>
                        Net Annualized loss Expectancy
                        <i
                            class="fas fa-question-circle"
                            title="Net Single Loss Expectancy * Net Likelihood %"
                        ></i>
                    </label>
                    <div class="ale-value-field">
                        <span *ngIf="netAleCalculating">
                            <i class="fas fa-circle-notch fa-spin"></i>
                            Calculating...
                        </span>
                        <span *ngIf="netAle">{{ netAle }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
