import { Injectable } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { toPromise } from "@methods/CommonMethods";
import { DocumentMethods } from "@methods/DocumentMethods";
import { DocumentConfig } from "@models/documents/DocumentConfig";
import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { ToastrService } from "ngx-toastr";
import {
    AddDocumentsParameters,
    OpenDocumentPopupReason,
} from "../document-manager/models/add-documents-parameters.model";
import { AddDocumentsPopupComponent } from "../document-manager/add-documents-popup/add-documents-popup.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
    providedIn: "root",
})
export class DocumentManagerService {
    documentConfig: DocumentConfig;
    documentTypes: IdNameCombination[];

    constructor(
        private _documentDS: DocumentDataService,
        private _standingDataDS: StandingdataDataService,
        private _toastr: ToastrService,
        private _dialog: MatDialog
    ) {}

    async loadData() {
        let docConfigPromise = null;
        let docTypesConfigPromise = null;

        if (!this.documentConfig) {
            docConfigPromise = toPromise(this._documentDS.GetDocumentConfig());
        }

        if (!this.documentTypes) {
            docTypesConfigPromise = toPromise(this._standingDataDS.getDocumentTypes(null));
        }

        if (docConfigPromise) {
            this.documentConfig = await docConfigPromise;
        }

        if (docTypesConfigPromise) {
            this.documentTypes = await docTypesConfigPromise;
        }
    }

    addEditFiles(newDocuments: DocumentModel[], originalDocuments: DocumentModel[]) {
        if (!newDocuments) {
            return;
        }

        const allNewFiles = [...newDocuments, ...originalDocuments]
            .filter((doc) => doc.IsNew && doc.File)
            .map((doc) => <File>doc.File);

        if (
            !DocumentMethods.validateUploadLimit(
                this.documentConfig.maxUploadSizeInMb,
                this._toastr,
                allNewFiles
            )
        ) {
            return;
        }

        for (let docIndex = 0; docIndex < newDocuments.length; docIndex++) {
            const doc = newDocuments[docIndex];

            this.setFileImg(doc);
            if (doc.IsNew) {
                const updated = doc.Guid
                    ? originalDocuments.filter((x) => x.IsNew && x.Guid === doc.Guid)
                    : [];
                if (updated.length > 0) {
                    this.updateFile(updated[0], doc, originalDocuments);
                } else {
                    originalDocuments.unshift(doc);
                }
            } else {
                doc.Changed = true;

                const updated = originalDocuments.filter((x) => x.ID === doc.ID && !x.Deleted);
                if (updated.length > 0) {
                    this.updateFile(updated[0], doc, originalDocuments);
                }
            }
        }
    }

    deleteDocument(guid: string, allDocuments: DocumentModel[]) {
        const doc = allDocuments.find((d) => d.Guid == guid);
        if (!doc) {
            return;
        }

        if (!doc.CanDelete) {
            return;
        }

        if (doc.IsNew) {
            const newIndex = allDocuments.indexOf(doc);
            allDocuments.splice(newIndex, 1);
        } else {
            doc.Deleted = !doc.Deleted;
        }
    }

    updateFile(oldFile: DocumentModel, newFile: DocumentModel, originalDocuments: DocumentModel[]) {
        const replaceIndex = originalDocuments.indexOf(oldFile);
        originalDocuments.splice(replaceIndex, 1, newFile);
    }

    setFileImg(doc: DocumentModel) {
        const fileNameSplit = doc && doc.Name ? doc.Name.toLowerCase().split(".") : [];
        const fileExt = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : "";

        if (fileExt.indexOf("pdf") >= 0) {
            doc.FileIcon = "file-pdf";
        } else if (
            fileExt.indexOf("jpg") >= 0 ||
            fileExt.indexOf("png") >= 0 ||
            fileExt.indexOf("jpeg") >= 0
        ) {
            doc.FileIcon = "file-image";
        } else if (fileExt.indexOf("doc") >= 0) {
            doc.FileIcon = "file-word";
        } else if (fileExt.indexOf("txt") >= 0 || fileExt.indexOf("msg") >= 0) {
            doc.FileIcon = "file-alt";
        } else if (
            fileExt.indexOf("xls") >= 0 ||
            fileExt.indexOf("xlsx") >= 0 ||
            fileExt.indexOf("xml") >= 0
        ) {
            doc.FileIcon = "file-excel";
        } else if (fileExt.indexOf("zip") >= 0) {
            doc.FileIcon = "file-archive";
        } else {
            doc.FileIcon = "file";
        }
    }

    public downloadDocument(doc: DocumentModel) {
        var linkToFile = document.createElement("a");

        if (!doc.IsNew) {
            linkToFile.target = "_blank";
            linkToFile.href = `api/documents/manager/${doc.Guid}/data`;
        } else {
            linkToFile.download = doc.Name;
            linkToFile.href = URL.createObjectURL(doc.File);
        }

        linkToFile.click();
        linkToFile.remove();
    }

    public openEditDocumentDialog(
        documents: DocumentModel[],
        allDocuments: DocumentModel[],
        openReason: OpenDocumentPopupReason,
        canAdd: boolean,
        canEdit: boolean,
        canDelete: boolean,
        documentsChangeCallback?: (documents: DocumentModel[]) => void
    ) {
        if (documents.length == 0) {
            return;
        }

        if (openReason == OpenDocumentPopupReason.EditDocument && !canEdit) {
            openReason = OpenDocumentPopupReason.ViewDocument;
        }

        const parameters = <AddDocumentsParameters>{
            documentTypes: this.documentTypes,
            canAddDocument: canAdd,
            canEditDocument: canEdit,
            canDeleteDocument: canDelete,
            openReason: openReason,
        };

        if (documents.length == 1) {
            const clone = <DocumentModel>new Object(documents[0]);
            parameters.documents = [clone];
        } else {
            parameters.documents = documents;
        }

        this._dialog.open(AddDocumentsPopupComponent, {
            data: {
                parameters: parameters,
                onSave: ((documents: DocumentModel[], isDeleted: boolean) => {
                    if (isDeleted) {
                        if (documents.length > 0) {
                            this.deleteDocument(documents[0].Guid, allDocuments);
                        }
                    } else {
                        this.addEditFiles(documents, allDocuments);
                    }

                    if (documentsChangeCallback) {
                        documentsChangeCallback(allDocuments);
                    }
                }).bind(this),
            },
            panelClass: "eff-popup-wrapper",
            width: "700px",
        });
    }
}
