<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="text-center" *ngIf="about">
    <img
        *ngIf="halloweenLogo == false"
        class="about-logo"
        src="/assets/img/logo.svg"
        alt="Cerrix-Logo"
    />
    <img
        *ngIf="halloweenLogo == true"
        class="about-logo"
        title="Happy halloween"
        src="/assets/img/halloween.gif"
        alt="Cerrix-Logo"
    />
    <div>
        <b>{{ about.product }}</b>
    </div>
    <table>
        <tbody>
            <tr>
                <td colspan="2"><hr /></td>
            </tr>
            <tr>
                <td>Version number</td>
                <td>{{ about.version }}</td>
            </tr>
            <tr>
                <td>Version date</td>
                <td>{{ about.datePublished }} <small>(W. Europe Standard Time)</small></td>
            </tr>
            <tr>
                <td colspan="2"><hr /></td>
            </tr>
        </tbody>
    </table>
</div>
