<div class="modal-header">
    <h5 class="modal-title">Adding Systems</h5>
</div>
<div class="modal-body">
    <div class="content">
        <div class="form-group cerrix-control-sm">
            <div *ngIf="systemOptions.length === 0">
                No systems available
            </div>
            <ng-select
                *ngIf="systemOptions.length > 0"
                [items]="systemOptions"
                bindLabel="Name"
                bindValue="ID"
                [isOpen]="true"
                [multiple]="true"
                (change)="changed($event)"
            >
                <ng-template ng-option-tmp let-system="item" let-search="searchTerm">
                    <div class="card" (click)="system.selected = !system.selected">
                        <div class="card-body" [class.selected]="system.selected">
                            <div *ngIf="system.selected">{{ system.Name }}</div>
                            <div *ngIf="!system.selected">
                                <p class="card-title">{{ system.Name }}</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        *ngIf="showManageAllData"
        type="button"
        class="btn btn-primary"
        (click)="manageDataAllSystems()"
    >
        Manage data for all systems
    </button>
    <button type="button" class="btn btn-primary" (click)="okClick()">Ok</button>
    <button type="button" class="btn btn-secondary" (click)="closeClick()">Close</button>
</div>
