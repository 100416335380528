import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import {
    ParentChildSelectorConfig,
    ParentChildButtonConfig,
} from "@app/shared/models/ParentChildSelector/ParentChildSelectorConfig";
import { ControlCopyEffectivenessDataService } from "./control-copy-effectiveness.data.service";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ControlExportData } from "@app/controls/models/ControlExportData";
import { AdvancedEffectivenessExportData } from "@app/controls/models/AdvancedEffectivenessExportData";
import { nameof } from "@methods/jeffs-toolkit";
import { ControlCopyExportSharedMethods } from "@app/controls/control-copy-export-shared-methods";

@Component({
    selector: "control-copy-effectiveness",
    templateUrl: "./control-copy-effectiveness.component.html",
    styleUrls: ["./control-copy-effectiveness.component.scss"],
})
export class ControlCopyEffectivenessComponent implements OnInit {
    tab: TabModel;
    config: string[];

    selectorConfig: ParentChildSelectorConfig;

    constructor(
        private _ds: ControlCopyEffectivenessDataService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit(): void {
        const buttonConfig: ParentChildButtonConfig[] = [
            {
                prettyName: "Copy testplan",
                icon: "fas fa-file-archive",
                disabled: false,
                click: this.handleClick.bind(this),
            },
        ];

        this._ds.getWithGuids(this.config).subscribe((controls) => {
            controls.forEach((control) => {
                control.header = `${control.identifier} - ${control.name}`;
                control.effectivenessList = control.effectivenessList.sortBy("enddate", true);
                control.effectivenessList.forEach((effectiveness) => {
                    if (effectiveness.testPeriodName) {
                        effectiveness.renderedName = `${effectiveness.testPeriodName} - ${effectiveness.name}`;
                    } else {
                        effectiveness.renderedName = effectiveness.name;
                    }
                });
            });

            this.selectorConfig = ControlCopyExportSharedMethods.createSelectorConfig(
                controls,
                buttonConfig
            );

            this.selectorConfig.filters.push({
                prettyName: "Test period filter",
                propertyName: nameof<AdvancedEffectivenessExportData>((eff) => eff.testPeriodName),
                filterType: GenericListFieldType.MultiSelect,
                childFilter: true,

                data: controls
                    .selectMany<ControlExportData, string>((c) =>
                        c.effectivenessList.map((e) => e.testPeriodName)
                    )
                    .distinct()
                    .sort(),
            });

            this.tab.showLoader = false;
        });
    }

    private async handleClick(allItems: ControlExportData[], btn): Promise<void> {
        {
            const items = this.getSelectedExportData(allItems);
            if (items.length <= 0) {
                this._promptService.alert({
                    data: {
                        title: "Selection required!",
                        message: "Select at least one testplan before copying",
                    },
                });
                return;
            }

            const newGroupName = (
                await this._promptService
                    .prompt({
                        maxHeight: "250px",
                        maxWidth: "440px",
                        data: {
                            title: "Please enter new group name",
                            fields: [
                                {
                                    prettyName: "Group Name",
                                    fieldName: "groupname",
                                    fieldType: GenericListFieldType.Text,
                                },
                            ],
                        },
                    })
                    .toPromise()
            ).groupname;

            if (newGroupName == null) return;

            const count = items
                .map((control) => {
                    const selectedEffectiveness = control.effectivenessList.filter(
                        (effectiveness) => {
                            return effectiveness.selected === true;
                        }
                    );
                    return selectedEffectiveness.length;
                })
                .reduce(function (total, num) {
                    return total + num;
                });

            const confirmText = `Are you sure you want to copy ${count} Test Plan${
                count > 1 ? "s" : ""
            }?`;

            if (await this._promptService.confirm("Continue copy?", confirmText).toPromise()) {
                this.tab.loadingMessage = "Copying Test Plan(s)";
                this.tab.showLoader = true;
                this._ds.copyData(items, newGroupName).then((x) => {
                    this._toastr.success(
                        "",
                        `Test Plan${count > 1 ? "s have" : " has"} been copied`
                    );

                    this.tab.loadingMessage = null;
                    this.tab.refresh();
                });
            }
        }
    }

    private getSelectedExportData(data: ControlExportData[]): ControlExportData[] {
        const selectedItems: ControlExportData[] = [];

        const copyData: ControlExportData[] = JSON.parse(JSON.stringify(data));
        copyData.forEach((control) => {
            control.effectivenessList = control.effectivenessList.filter((eff) => eff.selected);
            if (control.effectivenessList.length > 0) {
                selectedItems.push(control);
            }
        });

        return selectedItems;
    }
}
