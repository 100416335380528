<span class="browse-button" (click)="openFileSelect()">{{ text }}</span>

<input
    type="file"
    id="fileUpload"
    [accept]="allowedFileExtensions"
    [multiple]="true"
    [hidden]="true"
    (change)="updateFiles()"
    #fileInput
/>
