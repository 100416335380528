import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditFieldworkStepDataService } from "@app/audit/services/audit-fieldworkstep-data.service";
import { AuditFieldworkStepDetailModel } from "@app/audit/shared/data-models/audit-fieldwork-step-detail-model";
import { AuditFieldworkPermissionModel } from "@app/audit/shared/permission-models/audit-fieldwork-permission-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { StandingDataItemWithColor } from "@models/standingdata/StandingDataItemWithColor";
import { Observable } from "rxjs";

@Component({
    selector: "audit-fieldwork-step-detail-main",
    templateUrl: "./audit-fieldwork-step-detail-main.component.html",
    styleUrls: ["./audit-fieldwork-step-detail-main.component.scss"],
})
export class AuditFieldworkStepDetailMainComponent {
    @Input() auditGuid: string;
    @Input() permissions: AuditFieldworkPermissionModel;
    @Input() stepModel: AuditFieldworkStepDetailModel;

    formGroup: FormGroup;
    scores: Observable<StandingDataItemWithColor[]>;

    constructor(private _ds: AuditFieldworkStepDataService) {}

    async ngOnInit() {
        this.scores = this._ds.getAuditObservationScores(
            this.auditGuid,
            this.stepModel.observationScore
        );
        this.createFormGroup();
    }

    private createFormGroup() {
        this.formGroup = new FormGroup<any>({
            description: new FormControl({
                value: this.stepModel.description,
                disabled: true,
            }),
            observation: new FormControl(this.stepModel.observation),
            observationScore: new FormControl(this.stepModel.observationScore),
            observationDate: new FormControl(this.stepModel.observationDate),
        });

        if (!this.permissions.canEditObservation) {
            this.formGroup.disable();
        } else {
            this.formGroup.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.formGroup, this.stepModel);
            });
        }
    }
}
