import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditUniverseDataService } from "@app/audit-universe/services/audit-universe-data.service";
import { AuditUniverseLinkAuditModel } from "@app/audit-universe/shared/data-models/audit-universe-link-audit-model";
import { AuditUniverseModel } from "@app/audit-universe/shared/data-models/audit-universe-model";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { RendererType } from "@enums/RendererType";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, Observable, of } from "rxjs";

@Component({
    selector: "audit-universe-linked-audits",
    templateUrl: "./audit-universe-linked-audits.component.html",
})
export class AuditUniverseLinkedAuditsComponent extends ItemLinkableBase {
    @Input() parentForm: FormGroup;
    @Input() auditUniverseModel: AuditUniverseModel;
    @Input() disabled: boolean;
    @Output() linkChanged: EventEmitter<never> = new EventEmitter<never>();

    protected isLoading: boolean = true;
    private auditsLoaded: boolean = false;
    public linked: AuditUniverseLinkAuditModel[];
    public unlinked: AuditUniverseLinkAuditModel[];

    public rendererConfig: RendererConfig[] = [
        {
            type: RendererType.Score,
            textColumn: "Status",
            actionColumn: "StatusColor",
            hideActionColumn: true,
        },
    ];

    constructor(
        private dataService: AuditUniverseDataService,
        private auditDataService: AuditDataService,
        private tabService: TabService,
        private pages: Pages,
        toastr: ToastrService,
        promptService: CerrixPromptService
    ) {
        super(toastr, promptService);
    }

    public hasAccessToItem(guid: string): Observable<boolean> {
        return this.auditDataService.hasAccess(guid);
    }

    public openTab(item: any, hasAccess: boolean): void {
        if (hasAccess) {
            this.tabService.generateTab(this.pages.AuditDetail, item.guid, `(A) ${item.name}`);
        } else {
            this._toastr.warning("Not enough rights to open this audit.");
        }
    }

    public load(): void {
        if (this.auditsLoaded) {
            return;
        }
        this.auditsLoaded = true;

        this.reload();
    }

    protected reload(): void {
        this.isLoading = true;

        firstValueFrom(this.dataService.getLinkableAudits(this.auditUniverseModel.guid)).then(
            (audits: AuditUniverseLinkAuditModel[]): void => {
                this.loadLinkableAudits(audits);

                this.isLoading = false;
            }
        );
    }

    protected linkedItemsChanged(linked: AuditUniverseLinkAuditModel[]): void {
        this.auditUniverseModel.auditIds = linked.map(
            (a: AuditUniverseLinkAuditModel): number => a.id
        );

        this.linkChanged.emit();
    }

    private loadLinkableAudits(audits: AuditUniverseLinkAuditModel[]): void {
        const linkedItems = [];
        const unlinkedItems = [];
        if (this.auditUniverseModel.auditIds && this.auditUniverseModel.auditIds.length > 0) {
            for (const audit of audits) {
                if (this.auditUniverseModel.auditIds.includes(audit.id)) {
                    linkedItems.push(audit);
                } else {
                    unlinkedItems.push(audit);
                }
            }
        } else {
            unlinkedItems.addRange(audits);
        }

        this.linked = linkedItems;
        this.unlinked = unlinkedItems;
    }
}
