<nav class="cerrix-navbar">
    <div class="holidayseason" *ngIf="isHolidaySeason">
        <navbar-snow></navbar-snow>
    </div>
    <div class="logo">
        <cerrix-logo [width]="200" [height]="35"></cerrix-logo>
    </div>

    <div class="filler"></div>

    <navbar-calendar></navbar-calendar>
    <navbar-help></navbar-help>
    <navbar-user></navbar-user>
</nav>
