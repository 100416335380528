import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CerrixEditorComponent } from "@app/shared/components/cerrix-editor/cerrix-editor.component";
import { ModuleType } from "@enums/ModuleType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "uc-periodic-notification-detail",
    templateUrl: "./periodic-notification-detail.component.html",
    styleUrls: ["./periodic-notification-detail.component.scss"],
})
export class PeriodicNotificationDetailComponent implements OnInit {
    @ViewChild("editor") editorComponent: CerrixEditorComponent;
    module: ModuleType;
    moduleTypes = ModuleType;

    tab: TabModel;
    id: number;

    constructor(private _route: ActivatedRoute, private toastr: ToastrService) {
        this.id = this._route.snapshot.params.id;
    }

    async ngOnInit() {
        const config = this.tab.config;
        if (!config) {
            this.toastr.error("Error opening details.");
            this.tab.close(false);
            return;
        }

        this.module = config.module;
    }
}
