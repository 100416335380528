import { Injectable, inject } from "@angular/core";
import { ApplicationPermissionsModel } from "../../common/models/permissions/ApplicationPermissionsModel";
import { PermissionsDataService } from "../http/permissions/PermissionsDataService";
import { PermissionModel } from "@models/permissions/permission.model";
import { MicroservicePermissionService } from "./permissions.service";
import { combineLatest } from "rxjs";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";

@Injectable()
export class PermissionsService {
    private microservicePermissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );

    private settingsService: SettingsDataService = inject(SettingsDataService);

    permissionsLoaded = false;
    permissions = new ApplicationPermissionsModel();

    awaitedMethods = [];

    constructor(private permDS: PermissionsDataService) {}

    initializePermissions() {
        this.permDS.getPermissions().subscribe((x) => {
            this.settingsService.getSetting(ApplicationSettings.UseIncidents).subscribe((ip) => {
                if (ip.BoolValue) {
                    this.microservicePermissionService.setPermissions().subscribe(() => {
                        this.permissionsLoaded = true;
                    });
                } else {
                    this.permissionsLoaded = true;
                }
            });

            this.permissions = x;
            this.awaitedMethods.forEach((method) => method());
        });
    }

    hasStringRight(rightString: string) {
        let permModel = this.permissions;
        let hasAccess = false;

        const checks = rightString.split(".");
        checks.forEach((check, index) => {
            if (index === checks.length - 1) {
                hasAccess = permModel[check];
            } else {
                permModel = permModel[check];
            }
        });

        return hasAccess;
    }
}
