import { Component } from "@angular/core";
import { DevManagementDataService } from "@app/admin/services/devmanagement.service";
import { KeyValue } from "@models/generic/KeyValuePair";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "dev-code-executions",
    templateUrl: "./dev-code-executions.component.html",
    styleUrls: ["./dev-code-executions.component.scss"],
})
export class DevCodeExecutionComponent {
    executables: KeyValue<number, string>[];

    selectedExecutable: number;
    testrunSelected = true;
    executionMessage: string;

    constructor(
        private _ds: DevManagementDataService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {
        _ds.getAvailableExecutables().subscribe((x) => {
            this.executables = x;
            this.selectedExecutable = this.executables[0].Key;
        });
    }

    async execute() {
        const executableName = this.executables.find((x) => x.Key === this.selectedExecutable)
            .Value;
        const executionConfirmMessage = `Are you sure you want to __${
            this.testrunSelected ? "TESTRUN" : "EXECUTE"
        }__ the executable?`;

        this._promptService
            .confirm(executableName, executionConfirmMessage)
            .onConfirm()
            .subscribe(() => {
                this._ds
                    .executeExecutable(this.selectedExecutable, this.testrunSelected)
                    .subscribe((res) => {
                        this.executionMessage = res;
                        if (!this.executionMessage || this.executionMessage.trim().length === 0) {
                            this._toastr.success("", "Code execution succeeded.");
                        }
                    });
            });
    }
}
