import { Component, OnInit, ViewChild } from "@angular/core";
import { GetIconByModuleString } from "@methods/Icons";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { TabModel } from "../../../common/models/generic/TabModels/TabModel";
import { StandingdataDataService } from "../shared/standingdata.service";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { StandingDataGenericComponent } from "../generic/standingdata-generic.component";

@Component({
    selector: "app-standingdata-overview",
    templateUrl: "./standingdata-overview.component.html",
    styleUrls: ["./standingdata-overview.component.scss"],
})
export class StandingDataOverviewComponent implements OnInit {
    @ViewChild("customMenu", { static: true }) customMenu: any;
    @ViewChild("genericSD", { static: false }) genericSD: StandingDataGenericComponent;

    tab: TabModel;
    sortedModules = [];

    allowedPages: CerrixTreeItem[];
    selectedPage: CerrixTreeItem;

    sdConfig;

    constructor(private _sdService: StandingdataDataService) {}

    ngOnInit() {
        this.tab.menu = new TabMenu();
        this.tab.menu.customMenu = this.customMenu;
        if (!this.tab.showLoader) {
            this.tab.showLoader = true;
        }

        this._sdService.getStandingDataOverview().subscribe((data) => {
            data.forEach((node) => {
                const moduleIcon = GetIconByModuleString(node.Name);
                if (moduleIcon) {
                    node.Icon = JSON.stringify({ selector: moduleIcon });
                }
            });

            this.allowedPages = data;
            this.loadFromID(this.allowedPages);
            this.tab.showLoader = false;
        });
    }

    loadFromID(nodes: CerrixTreeItem[]) {
        let found = false;
        if (this.tab.id) {
            let i = 0;

            while (!found && i < nodes.length) {
                const node = nodes[i];
                if ("" + node.ID === "" + this.tab.id) {
                    this.nodeClicked(node);
                    found = true;
                } else if (node.Children.length > 0) {
                    found = this.loadFromID(node.Children);
                    node.isExpanded = found;
                }
                i++;
            }
        }

        return found;
    }

    async nodeClicked(node: CerrixTreeItem) {
        if (node.Children.length === 0) {
            if (!this.selectedPage || this.selectedPage.ID !== node.ID) {
                if (this.genericSD && !(await this.genericSD.editCmp.dirtyCheck())) return;

                this.selectedPage = node;
                this.tab.id = "" + node.ID;

                this.sdConfig = null;
                this.loadConfig(this.selectedPage.ID);
                this.tab.name = "SD | " + node.Name;
            }
        } else {
            node.isExpanded = !node.isExpanded;
        }
    }

    loadConfig(pageID) {
        setTimeout(() => {
            this.sdConfig = {
                type: pageID,
            };
        }, 1000);
    }
}
