import { Component } from "@angular/core";
import { DevManagementDataService } from "@app/admin/services/devmanagement.service";
import {
    RoleMaintenanceModel,
    RightMaintenanceModel,
} from "@app/admin/shared/RoleRightMaintenanceModel";
import { ToastrService } from "ngx-toastr";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DevRoleRightPatchesComponent } from "./dev-role-right-patches/dev-role-right-patches.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "dev-cerrixrole",
    templateUrl: "./dev-cerrixrole.component.html",
    styleUrls: ["./dev-cerrixrole.component.scss"],
})
export class DevCerrixroleComponent {
    roles: RoleMaintenanceModel[];
    selectedRole: RoleMaintenanceModel;
    selectedRight: RightMaintenanceModel;

    bsModalRef: BsModalRef;

    constructor(
        private _ds: DevManagementDataService,
        private _toastr: ToastrService,
        private _modalService: BsModalService,
        private _promptService: CerrixPromptService
    ) {
        this.loadRoles();
    }

    loadRoles() {
        this._ds.getRoleRightDefinitions().subscribe((x) => {
            this.roles = x;
            this.selectedRole = this.roles[0];
            this.setRight();
        });
    }

    setRight() {
        this.selectedRight = this.selectedRole.rights[0];
    }

    changeLink() {
        const call =
            this.selectedRight.currentValue === this.selectedRight.defaultValue
                ? this._ds.setRoleRightDefinition(
                      this.selectedRole.roleID,
                      this.selectedRight.rightID
                  )
                : this._ds.deleteRoleRightDefinition(
                      this.selectedRole.roleID,
                      this.selectedRight.rightID
                  );

        call.subscribe(() => {
            this.selectedRight.currentValue = !this.selectedRight.currentValue;
        }, this.throwUpdateError);
    }

    async deleteLink(onlySelectedRole: boolean) {
        let roleID = null;
        if (onlySelectedRole) {
            roleID = this.selectedRole.roleID;
            if (!roleID || roleID <= 0) {
                this._toastr.error(
                    "Current selected role is not valid.",
                    "Could not delete links!"
                );
                return;
            }
        }

        const patchText = onlySelectedRole
            ? `remove all '${this.selectedRole.roleName}' patches`
            : "remove all patches";

        if (
            await this._promptService
                .confirm("Confirm", `Are you sure you want to ${patchText}? There is no way back!`)
                .toPromise()
        ) {
            if (
                await this._promptService
                    .confirm(
                        "Confirm",
                        "Are you 100% sure you want to do this. There is no history, so there is no restore available!"
                    )
                    .toPromise()
            ) {
                await this._ds.deleteRoleRightDefinition(roleID).toPromise();
                this.loadRoles();
                this._promptService.alert({
                    data: {
                        title: "Done",
                        message: "Role - Right definition has been deleted.",
                    },
                });
            }
        }
    }

    openPatchesModal() {
        this.bsModalRef = this._modalService.show(DevRoleRightPatchesComponent, {
            class: "modal-xl",
        });
    }

    throwUpdateError() {
        this._toastr.error("Something went wrong while updating link.", "Update failed!");
    }

    customSearchRoleFn(term: string, item: RoleMaintenanceModel) {
        term = term.toLowerCase();
        return (
            item.roleName.toLowerCase().indexOf(term) > -1 ||
            item.roleDescription.toLowerCase().indexOf(term) > -1
        );
    }

    customSearchRightFn(term: string, item: RightMaintenanceModel) {
        term = term.toLowerCase();
        return (
            item.rightName.toLowerCase().indexOf(term) > -1 ||
            item.rightDescription.toLowerCase().indexOf(term) > -1
        );
    }
}
