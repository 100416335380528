import { Component, OnInit, Input } from "@angular/core";
import { CombinedHistoryChange, HistoryType } from "@models/history/HistoryModel";

@Component({
    selector: "history-detail",
    templateUrl: "./history-detail.component.html",
    styleUrls: ["./history-detail.component.scss"],
})
export class HistoryDetailComponent implements OnInit {
    @Input() changes: CombinedHistoryChange[];

    displayedColumn: string[] = ["Column", "OldValue", "NewValue"];
    dataSource: CombinedHistoryChange[];
    HistoryType = HistoryType;

    constructor() {}

    ngOnInit() {
        this.dataSource = this.changes;
    }
}
