import { EffectivenessStatusType } from "../enums/effectiveness-status-type.enum";
import { SampleGenerationType } from "../enums/sample-generation-type.enum";
import { EffectivenessWizardTestStepModel } from "./effectiveness-wizard-test-step-model";

export class EffectivenessWizardModel {
    name: string;
    testPeriod: number;

    status: EffectivenessStatusType;

    startDate: Date;
    endDate: Date;

    sampleGenerationMethod: number;
    reasonManualSampleGeneration: string;

    sourceDocumentUploaders: number[];
    evidenceUploaders: number[];
    testers: number[];
    reviewers: number[];

    sourceDocumentDueDate: Date;
    evidenceDueDate: Date;
    testerDueDate: Date;
    reviewerDueDate: Date;

    linkedStandard: number;
    qualityAspects: number[];
    testNorm: string;
    testInstructions: string;

    sourceDocumentUploadInstructions: string;
    evidenceUploadInstructions: string;

    storeAsCatalogue: boolean;
    storeAsCatalogueName: string;

    testSteps: EffectivenessWizardTestStepModel[];
    reviewEnabled: boolean;
    canChangeSampleMethod: boolean;

    constructor() {
        this.sourceDocumentUploaders = [];
        this.evidenceUploaders = [];
        this.testers = [];
        this.reviewers = [];
        this.qualityAspects = [];
        this.testSteps = [];
        this.reviewEnabled = true;
        this.canChangeSampleMethod = true;
        this.sampleGenerationMethod = SampleGenerationType.automatic;
    }
}
