import { Component, Input } from "@angular/core";
import { AuditWorkflowDataService } from "@app/audit/services/audit-workflow-data.service";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditWorkflowHistoryModel } from "@app/audit/shared/data-models/audit-workflow-history-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";

@Component({
    selector: "audit-detail-workflow-history",
    templateUrl: "./audit-detail-workflow-history.component.html",
})
export class AuditDetailWorkflowHistoryComponent {
    @Input() auditModel: AuditModel;

    public config: GenericListConfig;

    constructor(private _ds: AuditWorkflowDataService) {}

    async load() {
        if (this.config) {
            return;
        }

        const workflowHistory = await toPromise(this._ds.getWorkflowHistory(this.auditModel.guid));
        this.setupHistoryListConfig(workflowHistory);
    }

    private setupHistoryListConfig(workflowHistory: AuditWorkflowHistoryModel[]) {
        this.config = <GenericListConfig>{
            idProp: nameof<AuditWorkflowHistoryModel>((x) => x.id),
            name: "Workflow History",
            data: workflowHistory,

            allowAdd: false,
            allowEdit: false,
            allowDelete: false,

            fields: [
                {
                    fieldName: nameof<AuditWorkflowHistoryModel>((x) => x.statusDescription),
                    prettyName: "Status",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<AuditWorkflowHistoryModel>((x) => x.actionDescription),
                    prettyName: "Action",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<AuditWorkflowHistoryModel>((x) => x.actionDate),
                    prettyName: "On",
                    fieldType: GenericListFieldType.DateTime,
                },
                {
                    fieldName: nameof<AuditWorkflowHistoryModel>((x) => x.actionBy),
                    prettyName: "By",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<AuditWorkflowHistoryModel>((x) => x.comment),
                    prettyName: "Comment",
                    fieldType: GenericListFieldType.TextArea,
                },
            ],

            uiid: "list-workflow-history",
        };
    }
}
