import { DocumentPermissionModel } from "@models/permissions/DocumentPermissionModel";

export class ThirdPartyPermissions {
    canView: boolean;
    canEdit: boolean;
    canReview: boolean;
    canDelete: boolean;
    canLinkControls: boolean;
    canLinkRisks: boolean;
    canLinkForms: boolean;
    canAddMoi: boolean;
    readOnly: boolean;
    documentPermissions: DocumentPermissionModel;
}
