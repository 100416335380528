<ng-container>
    <ng-container *ngIf="currentLockConfig && lockLogConfig">
        <div class="detail-page-action-bar flex-action-bar">
            <div></div>
            <div class="right-area">
                <button (click)="deleteLogs()" class="btn btn-dark">
                    <i class="fas fa-plus me-2"></i>
                    Delete All
                </button>
            </div>
        </div>
        <generic-list-manager [config]="currentLockConfig"></generic-list-manager>
        <generic-list-manager [config]="lockLogConfig"></generic-list-manager>
    </ng-container>
</ng-container>
