<mat-tab-group class="small-tabs tab-body-full-height process-details" id="tab-group" #matTabGroup>
    <mat-tab *ngIf="selectedCellType === CellTypesEnum.Process">
        <ng-template mat-tab-label>
            <mat-icon
                class="tab-icon"
                fontSet="fas"
                fontIcon="fa-info-circle"
                inline="true"
            ></mat-icon>
        </ng-template>
        <div id="properties-sidebar" *ngIf="processMetadata">
            <div
                *ngFor="let field of processMetadataFields"
                class="form-group col-{{ field.editorWidth ? field.editorWidth : '12' }}"
            >
                <generic-field-editor
                    [field]="field"
                    [editEnabled]="!field.isReadonly"
                    [row]="processMetadata"
                    [additionalData]="additionalData"
                >
                </generic-field-editor>
            </div>
        </div>
    </mat-tab>
    <mat-tab *ngIf="selectedCellType === CellTypesEnum.Process">
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon" fontSet="fas" fontIcon="fa-file" inline="true"></mat-icon>
        </ng-template>
        <div id="documents-sidebar" *ngIf="graph && graph.diagramData">
            <document-manager-old
                [Documents]="graph.diagramData.Documents"
                [disabled]="readOnly"
                [AllowAdd]="!readOnly && graph.diagramData.canManageDocuments"
                [AllowEdit]="!readOnly && graph.diagramData.canManageDocuments"
                [AllowDelete]="!readOnly && graph.diagramData.canManageDocuments"
                [DocumentTypeTargets]="documentTypeTargets"
                [compactMode]="true"
            ></document-manager-old>
        </div>
    </mat-tab>
    <mat-tab *ngIf="selectedCellType === CellTypesEnum.Module">
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon" [ngClass]="_selectedModuleIcon"> </mat-icon>
        </ng-template>

        <ng-template
            (attached)="portalAttached($event)"
            [cdkPortalOutlet]="_selectedPropertyPortal"
        ></ng-template>

        <div class="form-group col-12">
            <generic-field-editor
                [field]="processCommentField"
                [editEnabled]="!readOnly"
                [row]="processMetadata"
            >
            </generic-field-editor>
        </div>
    </mat-tab>
    <mat-tab *ngIf="selectedCellType === CellTypesEnum.Shape">
        <ng-template mat-tab-label>
            <mat-icon
                class="tab-icon"
                fontSet="fas"
                fontIcon="fa-info-circle"
                inline="true"
            ></mat-icon>
        </ng-template>

        <div class="detail-page-card shape-details">
            <div class="detail-page-card-title">Shape details</div>
            <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
                <div class="sub-list-manager" *ngIf="cellRaciConfig">
                    <generic-list-manager [config]="cellRaciConfig"></generic-list-manager>
                </div>

                <div class="form-group col-12 margin-top">
                    <generic-field-editor
                        [field]="shapeCommentField"
                        [editEnabled]="!readOnly"
                        [row]="shapeCommentData"
                    >
                    </generic-field-editor>

                    <label class="margin-top"><b>Linked Process</b></label>
                    <cerrix-select-tree
                        *ngIf="businessDimensions"
                        [tree]="businessDimensions"
                        [disabled]="readOnly"
                        [multiselect]="false"
                        [readonly]="readOnly"
                        [(value)]="selectedLinkedProcess"
                        (onChangeSingleId)="linkedProcessSelected($event)"
                    >
                    </cerrix-select-tree>
                </div>
            </div>
        </div>

        <div class="detail-page-card shape-details" *ngIf="showCellPrintSettings">
            <div class="detail-page-card-title">Print options</div>
            <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
                <generic-field-editor
                    class="block checkbox-no-label"
                    [field]="includeInPrintField"
                    [editEnabled]="!readOnly"
                    [row]="includeInPrintData"
                >
                </generic-field-editor>

                <generic-field-editor
                    class="margin-top block"
                    [field]="printSequenceField"
                    [editEnabled]="!readOnly"
                    [row]="printSequenceData"
                >
                </generic-field-editor>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
