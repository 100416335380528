<mat-form-field
    [class.disabled]="this.disabled"
    >
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <textarea
        class="regular-text"
        matInput
        [formControl]="formController"
        (keyup)="onModelChanged()"
    ></textarea>
</mat-form-field>
