import { Component, Input, EventEmitter, Output } from "@angular/core";
import { DashboardModel } from "../shared/models/DashboardModel";
import { DashboardService } from "../services/dashboard.service";
import { IdNameDescriptionModel } from "@models/generic/IdNameDescriptionModel";
import { DashboardPermissionModel } from "@models/permissions/DashboardPermissionModel";
import { PermissionsService } from "@services/permissions/PermissionsService";

@Component({
    selector: "dashboard-configuration",
    templateUrl: "./dashboard-configuration.component.html",
    styleUrls: ["./dashboard-configuration.component.scss"],
})
export class DashboardConfigurationComponent {
    @Input() config: DashboardModel;
    @Output() changed = new EventEmitter<never>();

    roleGroups: IdNameDescriptionModel[];
    permissions: DashboardPermissionModel;

    constructor(ds: DashboardService, permService: PermissionsService) {
        this.permissions = permService.permissions.Dashboard;
        if (this.permissions.RoleGroupLinker) {
            ds.getRoleGroups().subscribe((rg) => (this.roleGroups = rg));
        }
    }

    emitChange() {
        this.changed.emit();
    }

    defaultChanged(newValue: boolean) {
        if (newValue) {
            this.config.favorite = true;
        }
    }
}
