<div class="row" *ngIf="controlModel">
    <div *ngIf="isLoading">
        <div class="fancy-loader"></div>
    </div>
    <div class="col-12" *ngIf="!isLoading">
        <event-linkable
            #linkableEvents
            typename="events"
            displayValueProp="name"
            [linked]="linked"
            [unlinked]="unlinked"
            [orderByValueProperty]="true"
            [disabled]="true"
        >
        </event-linkable>
    </div>
</div>
