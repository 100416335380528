import {
    Component,
    ContentChild,
    ElementRef,
    Input,
    TemplateRef,
    ViewChild,
    OnInit,
} from "@angular/core";
import { InviteUser } from "@models/invite/InviteUser";
import { CerrixEditorComponent } from "../components/cerrix-editor/cerrix-editor.component";
import { InviteConfigModel } from "@models/invite/InviteModel";
import { ToastrService, ActiveToast } from "ngx-toastr";
import { CerrixInviteType } from "@enums/invite/CerrixInviteType";
import { InviteMessage } from "@models/invite/InviteMessage";
import { ContextMenuItem, MenuItemBuilder } from "../contextmenu/menu-item";

@Component({
    selector: "cerrix-invite",
    templateUrl: "./cerrix-invite.component.html",
    styleUrls: ["./cerrix-invite.component.scss"],
})
export class CerrixInviteComponent implements OnInit {
    @Input() inviteConfig: InviteConfigModel;

    @ViewChild("subjectInput") subjectInput: HTMLInputElement;
    @ViewChild("cerrixEditor") cerrixEditor: CerrixEditorComponent;
    @ContentChild("userTemplate", { static: true }) userTemplate: TemplateRef<ElementRef>;

    quickFilter: string;
    organizationSelection: number[] = [];

    // Toastr object to always keep track of 1 toastr for presetmanagement.
    presetToastr: ActiveToast<any>;
    newPresetName = "";
    selectedPreset: any;

    public contextMenu: ContextMenuItem[];

    constructor(private toastr: ToastrService, private _menuItemBuilder: MenuItemBuilder) {}

    ngOnInit() {
        if (this.inviteConfig.templates.length > 0) {
            this.selectedPreset = this.inviteConfig.templates[0].ID;
            this.loadPreset();
        }

        this.buildContextMenu();
    }

    userClicked(user: InviteUser) {
        if (user.disabled) {
            return;
        }

        const userIndex = this.inviteConfig.userSelection.indexOf(user.ID);
        if (userIndex >= 0) {
            this.inviteConfig.userSelection.splice(userIndex, 1);
        } else {
            this.inviteConfig.userSelection.push(user.ID);
        }

        this.inviteConfig.userSelection = this.inviteConfig.userSelection.slice(0);
    }

    sendInvite() {
        if (this.inviteConfig.onSend) {
            const users = this.inviteConfig.users.filter(
                (u) => this.inviteConfig.userSelection.indexOf(u.ID) >= 0
            );
            this.inviteConfig.onSend(this.inviteConfig.subject, this.inviteConfig.message, users);
        }
    }

    filterChanged() {
        this.inviteConfig.users.forEach((user) => {
            let hidden = false;

            if (
                this.organizationSelection.length > 0 &&
                this.organizationSelection.indexOf(user.organizationID) < 0
            ) {
                hidden = true;
            } else if (this.quickFilter && this.quickFilter.length > 0) {
                const filters = this.quickFilter.split(" ");
                if (
                    !filters.some(
                        (f) =>
                            f.length > 0 &&
                            this.inviteConfig.quickFilterProperties.some(
                                (qs) => ("" + user[qs]).toLowerCase().indexOf(f) >= 0
                            )
                    )
                ) {
                    hidden = true;
                }
            }

            user["_hidden"] = hidden;
        });
    }

    toggleFilteredItems() {
        const itemsToToggle = this.inviteConfig.users.filter(
            (x) => x["_hidden"] !== true && x.disabled !== true
        );
        if (itemsToToggle.length > 0) {
            const isToggled = this.inviteConfig.userSelection.indexOf(itemsToToggle[0].ID) >= 0;
            itemsToToggle.forEach((x) => {
                if (isToggled) {
                    const pushedIndex = this.inviteConfig.userSelection.indexOf(x.ID);
                    if (pushedIndex >= 0) {
                        this.inviteConfig.userSelection.splice(pushedIndex, 1);
                    }
                } else {
                    if (this.inviteConfig.userSelection.indexOf(x.ID) < 0) {
                        this.inviteConfig.userSelection.push(x.ID);
                    }
                }
            });
        }

        // This makes all objects update that are binded to this property.
        this.inviteConfig.userSelection = this.inviteConfig.userSelection.slice(0);
    }

    customSearchFn(term: string, item: InviteUser) {
        term = term.toLowerCase();
        return (
            item.displayName.toLowerCase().indexOf(term) > -1 ||
            item.mail?.toLowerCase().indexOf(term) > -1
        );
    }

    insertSubjectTag(tag: string) {
        let pos = this.subjectInput.selectionStart;
        const tagToInsert = `[${tag}]`;
        if (this.inviteConfig.subject && this.inviteConfig.subject.length >= pos) {
            this.inviteConfig.subject = [
                this.inviteConfig.subject.slice(0, pos),
                tagToInsert,
                this.inviteConfig.subject.slice(pos),
            ].join("");
        } else {
            pos = 0;
            this.inviteConfig.subject += tagToInsert;
        }

        if (this.subjectInput.selectionStart) {
            this.subjectInput.setSelectionRange(pos + tagToInsert.length, pos + tagToInsert.length);
        }

        this.subjectInput.focus();
    }

    async saveNewPreset() {
        if (!this.inviteConfig.onSaveTemplate) {
            return;
        }

        this.closePresetToastr();

        if (!this.newPresetName || this.newPresetName.trim().length === 0) {
            this.presetToastr = this.toastr.warning("Preset name is required!", "New Preset");
            return;
        }

        if (!this.inviteConfig.subject || this.inviteConfig.subject.trim().length === 0) {
            this.presetToastr = this.toastr.warning("Subject is required!", "New Preset");
            return;
        }

        if (!this.inviteConfig.message || this.inviteConfig.message.trim().length === 0) {
            this.presetToastr = this.toastr.warning("Message is required!", "New Preset");
            return;
        }

        const newID = this.inviteConfig.templates.getLowerIDByProp("ID", 1);
        const template = <InviteMessage>{
            ID: newID < 0 ? newID : -1,
            templateType: CerrixInviteType.Default,
            templateName: this.newPresetName,
            subject: this.inviteConfig.subject,
            message: this.inviteConfig.message,
        };

        const stored = await this.inviteConfig.onSaveTemplate(template);
        if (stored) {
            this.inviteConfig.templates.push(template);
            this.newPresetName = "";
            this.selectedPreset = template.ID;
            this.presetToastr = this.toastr.success("Template has been saved", "New Preset");
        }
    }

    async deletePreset() {
        const preset = this.inviteConfig.templates.find((t) => t.ID === this.selectedPreset);
        if (preset) {
            const deleted = await this.inviteConfig.onDeleteTemplate(preset.ID);
            if (deleted) {
                const presetIndex = this.inviteConfig.templates.indexOf(preset);
                this.inviteConfig.templates.splice(presetIndex, 1);
                this.selectedPreset = null;
                this.inviteConfig.templates = this.inviteConfig.templates.slice(0);
            }
        }
    }

    loadPreset() {
        const presetToLoad = this.inviteConfig.templates.find((t) => t.ID === this.selectedPreset);
        if (presetToLoad) {
            this.inviteConfig.subject = presetToLoad.subject;
            this.inviteConfig.message = presetToLoad.message;
        }
    }

    closePresetToastr() {
        if (this.presetToastr) {
            this.presetToastr.portal.destroy();
            this.presetToastr = null;
        }
    }

    buildContextMenu() {
        if (!this.inviteConfig.tags) {
            return;
        }

        this.inviteConfig.tags.forEach((tag) => {
            this._menuItemBuilder.appendItem(tag, () => this.insertSubjectTag(tag));
        });

        const tagMenu = this._menuItemBuilder.build();
        this.contextMenu = this._menuItemBuilder
            .appendItem("Clear", () => {
                this.inviteConfig.subject = "";
                this.subjectInput.focus();
            })
            .appendSubMenu("Insert tag", tagMenu, null, "fal fa-file-import")
            .build();
    }
}
