<eff-expansion-panel
    [contentTemplate]="content"
    title="Source Documents"
    class="eff-details-panel"
    [panelOpenState]="panelOpenState"
    data-uiid="new-eff-upload-source-document-source-upload-instructions-info-box"
>
    <ng-template #content>
        <div class="rows-container">
            <div
                class="regular-text multiple-whitespaces"
                [innerHTML]="effectivenessModel.sourceDocumentUploadInstructions"
            ></div>
            <div class="source-documents-list" *ngIf="stepPermissions?.canViewSourceDocuments">
                <documents-list
                    *ngIf="sourceDocumentModel && sourceDocumentModel.documents?.length > 0"
                    [canAdd]="false"
                    [canEdit]="false"
                    [canDelete]="false"
                    [documents]="sourceDocumentModel.documents"
                ></documents-list>
                <div
                    *ngIf="!sourceDocumentModel || sourceDocumentModel.documents?.length === 0"
                    class="header no-documents"
                >
                    No source documents uploaded.
                </div>
            </div>

            <div class="rows-container" *ngIf="!hideSampling">
                <div class="bold-text header">Sampling</div>
                <div class="row-container" *ngIf="effectivenessModel.samplePopulation">
                    <div class="bold-text row-label">Population:</div>
                    <div class="regular-text">{{ effectivenessModel.samplePopulation }}</div>
                </div>

                <div class="row-container" *ngIf="effectivenessModel.numberOfTestSamples">
                    <div class="bold-text row-label">Sample size:</div>
                    <div class="regular-text">{{ effectivenessModel.numberOfTestSamples }}</div>
                </div>

                <div class="row-container" *ngIf="effectivenessModel.sampleTypeDescription">
                    <div class="bold-text row-label">Sample type:</div>
                    <div class="regular-text">{{ effectivenessModel.sampleTypeDescription }}</div>
                </div>

                <div class="row-container" *ngIf="isSpreadSheet()">
                    <div class="bold-text row-label">Column ID:</div>
                    <div class="regular-text">{{ columnId }}</div>
                </div>

                <div class="row-container" *ngIf="isSpreadSheet()">
                    <div class="bold-text row-label">Rows to skip:</div>
                    <div class="regular-text">{{ rowsToSkip }}</div>
                </div>

                <div
                    class="row-container"
                    *ngIf="
                        effectivenessModel.sampleGenerationType == sampleGenerationType.automatic &&
                        effectivenessModel.seedNumber
                    "
                >
                    <div class="bold-text row-label">Seed number:</div>
                    <div class="regular-text">{{ effectivenessModel.seedNumber }}</div>
                </div>

                <div
                    class="bold-text row-label"
                    *ngIf="effectivenessModel.sampleGenerationType == sampleGenerationType.manual"
                >
                    Reason manual sampling:
                </div>
                <div
                    class="regular-text"
                    *ngIf="effectivenessModel.sampleGenerationType == sampleGenerationType.manual"
                >
                    {{ effectivenessModel.sampleGenerationReason }}
                </div>
            </div>
        </div>
    </ng-template>
</eff-expansion-panel>
