import { PresetApiModel } from "./PresetApiModel";
import { SearchKeyword } from "./SearchKeyword";

export class Preset {
    searchKeywords: SearchKeyword[] = [];
    columns: string[] = [];
    paginationAmount: number;
    sortColumn: string;
    sortOrder: string;
}

export class PresetConfig {
    id: number;
    guid: string;
    name: string;
    description?: string;
    icon: string;
    module: string;
    preset: Preset;
    isDefault?: Boolean;
    isPublic?: boolean;
    isSystemDefault?: Boolean;
    isPredefined: Boolean;
    isFavorite: boolean;
    isRolegroup: boolean;

    roleGroups: [] = [];

    isOwner: boolean;
    createdBy: string;
    updatedOn: Date;

    api?: PresetApiModel;
}
