import { KriDataGraphModel } from "./../shared/models/kri-data-graph-model";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "../../app.constants";
import { Observable, of } from "rxjs";
import { KriModel } from "@models/kri";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { KeyValue } from "@models/generic/KeyValuePair";
import { KriPermissions } from "../shared/models/kri-permissions";
import { KriWidgetDataModel } from "../shared/models/kri-widget-data-model";
import { DocumentModel } from "@models/documents/documentmodel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { addParameterToUrl } from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";

@Injectable()
export class KriDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "kris");
    }

    public getKris(): Observable<any[]> {
        return this.get<any[]>();
    }

    public getPermissions(kriGuid: string): Observable<KriPermissions> {
        const url = `/${kriGuid}/permissions`;

        return this.get<KriPermissions>(url);
    }

    public getKri(kriGuid: string): Observable<KriModel> {
        const url = `/${kriGuid}`;

        return this.get<KriModel>(url);
    }

    public uploadKriData(kriData: any[]) {
        const url = "/uploaddata";
        return this.post<string>(kriData, url);
    }

    public storeKri(kri: KriModel): Observable<HttpEvent<Object>> {
        return this.postWithDocuments(kri, kri.documents);
    }

    public deleteKri(kriGuid: string): Observable<void> {
        const url = `/${kriGuid}`;

        return this.delete(url);
    }

    public getOrganizations(organizationIds: number[]): Observable<CerrixTreeItem[]> {
        let url = "/organizations";
        url = addParameterToUrl(url, "selectedIds", organizationIds);

        return this.get<CerrixTreeItem[]>(url);
    }

    public getBusinessDimensions(
        organizationIds: number[],
        bussinessDimensionIds: number[]
    ): Observable<CerrixTreeItem[]> {
        let url = "/businessDimensions";
        url = addParameterToUrl(url, "organizationIds", organizationIds);
        url = addParameterToUrl(url, "selectedIds", bussinessDimensionIds);

        return this.get<CerrixTreeItem[]>(url);
    }

    public getRiskCatalogues(
        organizationIds: number[],
        riskCatalogueIds: number[]
    ): Observable<KeyValue<number, string>[]> {
        let url = "/riskCatalogues";

        url = addParameterToUrl(url, "organizationIds", organizationIds);
        url = addParameterToUrl(url, "selectedIds", riskCatalogueIds);

        return this.get<KeyValue<number, string>[]>(url);
    }

    public getFrequencies(): Observable<KeyValue<number, string>[]> {
        const url = "/frequencies";

        return this.get<KeyValue<number, string>[]>(url);
    }

    public getAggregations(): Observable<KeyValue<number, string>[]> {
        const url = "/aggregations";

        return this.get<KeyValue<number, string>[]>(url);
    }

    public getGraphTypes(): Observable<KeyValue<number, string>[]> {
        const url = "/graphTypes";

        return this.get<KeyValue<number, string>[]>(url);
    }

    public getMeasurements(): Observable<KeyValue<string, string>[]> {
        const url = "/measurements";

        return this.get<KeyValue<string, string>[]>(url);
    }

    public getHistory(kriGuid: string | number): Observable<HistoryModel[]> {
        const url = `/${kriGuid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(
        kriGuid: string | number,
        historyDate: string | number
    ): Observable<HistoryChange[]> {
        const url = `/${kriGuid}/history/${historyDate}`;

        return this.get<HistoryChange[]>(url);
    }

    public getWidgetInfo(filters: any) {
        const url = `/widgetinfo`;
        return this.post<KriWidgetDataModel[]>(filters, url);
    }

    public getDataGraphInfo(kriGuid: string) {
        const url = `/${kriGuid}/datagraphinfo`;
        return this.get<KriDataGraphModel>(url);
    }

    public getMultipleDataGraphInfo(kriGuid: string[]) {
        const url = `/datagraphinfo?guids=` + kriGuid.join(",");
        return this.get<KriDataGraphModel[]>(url);
    }

    public getNameFilterModels() {
        const url = `/namefiltermodels`;
        return this.get<any[]>(url);
    }

    public getDocuments(guid: string): Observable<DocumentModel[]> {
        const url = `/${guid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(guid: string): Observable<HyperlinkModel[]> {
        const url = `/${guid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public getLinkedRisks(guid: string): Observable<RiskLinkModel[]> {
        const url = `/${guid}/risks`;
        return this.get<RiskLinkModel[]>(url);
    }

    public hasAccess(guid: string) {
        if (isGuid(guid)) {
            const url = `/${guid}/hasaccess`;
            return this.get<boolean>(url);
        }

        return of(false);
    }
}
