// Please keep this enum in line with ModuleSubType.cs
export enum ModuleSubType {
    ControlTesting = 1,
    ControlTestingDescription = "Control Testing",

    DataBreach = 2,
    DataBreachDescription = "Data Breach",

    FindingReports = 3,
    FindingReportsDescription = "Finding Reports",

    Assessment = 4,
    AssessmentDescription = "Assessment",

    MoiDetails = 5,
    MoiDetailsDescription = "Moi Details",

    RiskScores = 6,
    RiskScoresDescription = "Risk Scores",

    AdvEffTesting = 7,
    AdvEffTestingDescription = "Controls advanced effectiveness testing",

    BusinessNavigator = 100,
    BusinessNavigatorDescription = "Business",

    FrameworkNavigator = 110,
    FrameworkNavigatorDescription = "Framework",

    ThirdpartyAssuranceRequest = 120,
    ThirdpartyAssuranceRequestDescription = "Third party assurance request",

    AuditUniverse = 200,
    AuditUniverseDescription = "Audit Universe Objects",

    AuditPeriods = 205,
    AuditPeriodsDescription = "Audit Period",

    Audits = 210,
    AuditsDescription = "Audits",

    AuditFindings = 215,
    AuditFindingsDescription = "Audit Findings",

    TaskSeries = 300,
    TaskSeriesDescription = "Task Series",

    ControlExecution = 305,
    ControlExecutionDescription = "Control Execution",

    OrganizationMaintenance = 400,
    OrganizationMaintenanceDescription = "Organization Maintenance",

    BusinessDimensionMaintenance = 405,
    BusinessDimensionMaintenanceDescription = "Business Dimension Maintenance",

    FrameworkDimensionMaintenance = 410,
    FrameworkDimensionMaintenanceDescription = "Framework Dimension Maintenance",
}
