import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import {
    EffectivenessConclusionsModel,
    EffectivenessModel,
    EffectivenessOverviewModel,
    EffectivenessSourceDocumentsModel,
    EvidenceSampleEditModel,
    EvidenceSampleRemarkModel,
    EvidenceTestStepEditModel,
    LinkedRiskModel,
    SourceDocumentUploadModel,
} from "../models";
import { SelectOptionColorModel } from "@models/generic";

@Injectable()
export class AdvEffDataService extends ApiDataService {
    constructor(protected http: HttpClient, private configuration: Configuration) {
        super(http, configuration, "controls/testing/advanced/effectiveness");
    }

    getEffectivenessGuidById(effectivenessId: number): Observable<string> {
        const url = `/guid/${effectivenessId}`;

        return this.get<string>(url);
    }

    getEffectivenessWorkspace(): Observable<EffectivenessOverviewModel[]> {
        return this.get<EffectivenessOverviewModel[]>();
    }

    getEffectiveness(guid: string): Observable<EffectivenessModel> {
        const url = `/${guid}`;

        return this.get<EffectivenessModel>(url);
    }

    getSourceDocuments(guid: string): Observable<EffectivenessSourceDocumentsModel> {
        const url = `/${guid}/sourcedocuments`;

        return this.get<EffectivenessSourceDocumentsModel>(url);
    }

    getSourceDocumentPopulation(guid: string, documentGuid: string): Observable<number> {
        const url = `/${guid}/sourcedocuments/${documentGuid}/population`;

        return this.get<number>(url);
    }

    getPopulation(
        guid: string,
        documentGuid: string,
        idColumn: number,
        rowsToSkip: number
    ): Observable<number> {
        const url = `/${guid}/sourcedocuments/${documentGuid}/population/${idColumn}/${rowsToSkip}`;

        return this.get<number>(url);
    }

    getEvidenceSamples(guid: string): Observable<EvidenceSampleEditModel[]> {
        const url = `/${guid}/samples`;

        return this.get<EvidenceSampleEditModel[]>(url).pipe(
            map((samples) => {
                const items = [];
                for (const sample of samples) {
                    items.push(new EvidenceSampleEditModel().deserialize(sample));
                }

                return items;
            })
        );
    }

    getConclusions(guid: string): Observable<EffectivenessConclusionsModel> {
        const url = `/${guid}/conclusions`;

        return this.get<EffectivenessConclusionsModel>(url);
    }

    getLinkedRisks(guid: string): Observable<LinkedRiskModel[]> {
        const url = `/${guid}/linkedrisks`;

        return this.get<LinkedRiskModel[]>(url);
    }

    postSourceDocuments(
        guid: string,
        sourceDocumentsModel: SourceDocumentUploadModel
    ): Observable<void> {
        const url = `/${guid}/sourcedocuments`;

        return this.postWithDocuments<void>(
            sourceDocumentsModel,
            sourceDocumentsModel.documents,
            url
        );
    }

    postEvidenceSample(
        guid: string,
        sampleGuid: string,
        model: EvidenceSampleEditModel
    ): Observable<EvidenceSampleEditModel> {
        const url = `/${guid}/samples/${sampleGuid}`;
        return this.postWithDocuments<EvidenceSampleEditModel>(model, model.documents, url);
    }

    postEvidenceTestStep(
        guid: string,
        sampleGuid: string,
        testStepGuid: string,
        model: EvidenceTestStepEditModel
    ): Observable<EvidenceTestStepEditModel> {
        const url = `/${guid}/samples/${sampleGuid}/teststeps/${testStepGuid}`;

        return this.postWithDocuments<EvidenceTestStepEditModel>(model, model.documents, url);
    }

    postEvidenceSampleRemark(
        guid: string,
        sampleGuid: string,
        model: EvidenceSampleRemarkModel
    ): Observable<void> {
        const url = `/${guid}/samples/${sampleGuid}/remark`;

        return this.post<void>(model, url);
    }

    public getSampleScores(): Observable<SelectOptionColorModel<number>[]> {
        return this.get<SelectOptionColorModel<number>[]>("/sample-scores");
    }

    public getTestStepScores(): Observable<SelectOptionColorModel<number>[]> {
        return this.get<SelectOptionColorModel<number>[]>("/test-step-scores");
    }
}
