import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditUniversePeriodDataService } from "@app/audit-universe-period/services/audit-universe-period-data.service";
import { AuditUniversePeriodModel } from "@app/audit-universe-period/shared/data-models/audit-universe-period-model";
import { AuditUniversePeriodPermissionModel } from "@app/audit-universe-period/shared/page-models/audit-universe-period-permission-model";
import { AuditUniverseLinkableModel } from "@app/audit-universe/shared/data-models/audit-universe-linkable-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { RendererType } from "@enums/RendererType";
import { nameof } from "@methods/CommonMethods";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "audit-universe-period-detail-main",
    templateUrl: "./audit-universe-period-detail-main.component.html",
    styleUrls: ["./audit-universe-period-detail-main.component.scss"],
})
export class AuditUniversePeriodDetailMainComponent implements OnInit {
    @Input() permissions: AuditUniversePeriodPermissionModel;
    @Input() parentForm: FormGroup;
    @Input() model: AuditUniversePeriodModel;

    protected auditUniverseConfig: GenericListConfig;

    constructor(
        private auditPeriodDataService: AuditUniversePeriodDataService,
        private pages: Pages,
        private tabService: TabService
    ) {}

    ngOnInit(): void {
        if (this.model.dateClosed) {
            this.parentForm.disable();
        }

        if (this.permissions.canViewLinkedAuditUniverse) {
            this.auditPeriodDataService
                .getLinkableUniverseObjects()
                .subscribe((data: AuditUniverseLinkableModel[]): void => {
                    this.createAuditUniverseConfig(data);
                });
        }
    }

    private createAuditUniverseConfig(linkableUniverseObjects: AuditUniverseLinkableModel[]): void {
        const linked = [];
        const unlinked = [];
        for (const linkable of linkableUniverseObjects) {
            if (this.model.universeObjectIds.includes(linkable.id)) {
                linked.push(linkable);
            } else {
                unlinked.push(linkable);
            }
        }

        this.auditUniverseConfig = <GenericListConfig>{
            name: "Linked Universe Objects",
            idProp: nameof<AuditUniverseLinkableModel>("id"),

            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            allowLink: this.permissions.canLinkAuditUniverse,

            isSortableByColumn: true,
            limitViewTo: 5,
            data: linked,
            linkData: <any>{
                idValueProp: nameof<AuditUniverseLinkableModel>("id"),
                displayValueProp: nameof<AuditUniverseLinkableModel>("name"),
                unlinked,
                linkablePaginationItemCount: 15,
            },
            fields: [
                {
                    fieldType: GenericListFieldType.Text,
                    prettyName: "Name",
                    fieldName: nameof<AuditUniverseLinkableModel>("name"),
                },
                {
                    fieldType: GenericListFieldType.Text,
                    prettyName: "Evaluated Risk Score",
                    fieldName: nameof<AuditUniverseLinkableModel>("evaluatedRiskScore"),
                },
                {
                    fieldType: GenericListFieldType.Text,
                    prettyName: "Assessment Score",
                    fieldName: nameof<AuditUniverseLinkableModel>("assessmentScore"),
                },
            ],
            rendererConfig: [
                {
                    type: RendererType.Score,
                    textColumn: nameof<AuditUniverseLinkableModel>("evaluatedRiskScore"),
                    actionColumn: nameof<AuditUniverseLinkableModel>("evaluatedRiskScoreColor"),
                    hideActionColumn: true,
                },
                {
                    type: RendererType.Score,
                    textColumn: nameof<AuditUniverseLinkableModel>("assessmentScore"),
                    actionColumn: nameof<AuditUniverseLinkableModel>("assessmentScoreColor"),
                    hideActionColumn: true,
                },
                {
                    type: RendererType.Score,
                    textColumn: "Evaluated Risk Score",
                    actionColumn: nameof<AuditUniverseLinkableModel>("evaluatedRiskScoreColor"),
                    hideActionColumn: true,
                },
                {
                    type: RendererType.Score,
                    textColumn: "Assessment Score",
                    actionColumn: nameof<AuditUniverseLinkableModel>("assessmentScoreColor"),
                    hideActionColumn: true,
                },
            ],
            overviewRowActionsName: "Open",
            overviewRowActions: [
                {
                    icon: "fas fa-external-link",
                    tooltip: "Open",
                    clickEvent: (item: any): void => {
                        const guid = linkableUniverseObjects.find(
                            (x: AuditUniverseLinkableModel): boolean => x.id === item.id
                        ).guid;

                        this.tabService.generateTab(this.pages.AuditUniverseDetail, guid);
                    },
                },
            ],
            onLinkedDataChange: (data: AuditUniverseLinkableModel[]): void => {
                this.model.universeObjectIds.splice(
                    0,
                    Infinity,
                    ...data.map((x: AuditUniverseLinkableModel): any => x.id)
                );
            },
        };
    }
}
