import { CerrixInviteType } from "../../enums/invite/CerrixInviteType";
import { InviteMessage } from "./InviteMessage";
import { InviteUser } from "./InviteUser";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";

export class InviteModel {
    targetName: string;

    message: string;
    subject: string;

    inviteType: CerrixInviteType;

    templates: InviteMessage[];
    users: InviteUser[];
    organizations: CerrixTreeItem[];

    keywords: string[];
}

export class FormInviteModel extends InviteModel { }

export class InviteConfigModel {
    inviteTargetMessage: string;

    subject: string;
    message: string;

    tags: string[];
    templates: InviteMessage[];

    users: InviteUser[];
    userSelection: number[];

    organizations: CerrixTreeItem[];
    quickFilterProperties: string[];

    onSaveTemplate: (template: InviteMessage) => Promise<boolean>;
    onDeleteTemplate: (id: number) => Promise<boolean>;

    onSend: (subject: string, message: string, users: InviteUser[]) => void;
}