import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Component({
    selector: "eff-button",
    templateUrl: "./eff-button.component.html",
    styleUrls: ["./eff-button.component.scss"],
})
export class EffButtonComponent {
    @Input() icon: string;
    @Input() size: string = "medium-size";
    @Input() text: string;
    @Input() iconPosition: string = "left";
    @Input() iconSize: string;
    @Input() disabled: boolean = false;
    @Output() onClick: EventEmitter<any> = new EventEmitter();

    displayIcon(position) {
        return this.icon && this.iconPosition == position;
    }

    isIconLeft() {
        return this.iconPosition == "left";
    }

    get iconDimension() {
        return this.iconSize ? this.iconSize : this.size;
    }

    @HostListener("click", ["$event"])
    public hostClick(event: any): void {
        if (!this.disabled) {
            this.onClick.emit(event);
        }
    }
}
