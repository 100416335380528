<cerrix-loader [cerrixTab]="parentTab"></cerrix-loader>

<div class="detail-page-action-bar flex-action-bar">
    <div>
        <button data-uiid="button-save" class="btn btn-cerrix" (click)="saveSettings()">
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save</span>
        </button>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Notification Settings</div>
    <div class="detail-page-card-body padded">
        <cerrix-textfield
            name="slackToken"
            fieldLabel="Slack Token"
            dataUiid="notification-settings-slack-token"
            [(ngModel)]="slackToken"
        >
        </cerrix-textfield>
        <label class="help-text">
            App OAuth token (starting with xoxb-) with the following permissions:<br />
            - chat:write<br />- im:write<br />- users:read<br />- users:read.email<br />
            See step 1, 2, and the first part of step 3 on
            <a href="https://api.slack.com/quickstart#creating" target="_blank">
                the Slack API quickstart guide
            </a>
            for more information.
        </label>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Slack Options</div>
    <div class="detail-page-card-body padded">
        <div>
            <button data-uiid="button-sync" class="btn btn-success" (click)="syncAllUsers()">
                <i class="fas fa-sync"></i>
                <span class="d-none d-lg-inline">Link Slack Account for all users</span>
            </button>
        </div>
    </div>
</div>
