<div class="d-flex flex-column" *ngIf="filteredDocuments().length > 0">
    <div class="document-container d-flex flex-row gap-3" *ngFor="let doc of filteredDocuments()">
        <div
            (click)="onEditDeleteDocument(doc)"
            class="regular-text-small document-name text-ellipsis"
        >
            {{ doc.Name }}
        </div>
        <div class="document-actions d-flex flex-row align-items-center gap-2">
            <div *ngIf="doc.Description">
                <eff-icon class="action-icon" icon="comment_outlined"></eff-icon>
            </div>
            <eff-icon
                *ngIf="!doc.IsNew && doc.Name && doc.Name.endsWith('.pdf')"
                (click)="openDocumentViewer(doc)"
                icon="find_in_page_outlined"
                class="action-icon"
            ></eff-icon>
            <eff-icon
                class="action-icon"
                icon="cloud_download_outlined"
                (click)="downloadDocument(doc)"
            ></eff-icon>
        </div>
    </div>
</div>
<eff-drop-files-area
    *ngIf="canAdd"
    [allowedFileExtensions]="allowedFileExtensions"
    [allowDrop]="canAdd"
    (onDroppedFiles)="onFilesDropped($event)"
>
</eff-drop-files-area>
