import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { guid } from "@methods/uniqueMethods";
import { isObservable, Observable } from "rxjs";

@Component({
    selector: "cerrix-select",
    templateUrl: "./cerrix-select.component.html",
    styleUrls: ["./cerrix-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CerrixSelectComponent,
            multi: true,
        },
    ],
})
export class CerrixSelectComponent implements OnInit, ControlValueAccessor {
    valid = true;
    _value: number | number[];

    _onChangeFormValidation = Function.prototype;
    _onTouchedFormValidation = Function.prototype;

    @Input() id: string;
    @Input() prettyName: string;
    @Input() disabled = false;

    @Input() multiple = false;
    @Input() closeOnSelect = true;
    @Input() addTag = false;
    @Input() placeholder = "";
    @Input() clearable = true;
    @Input() searchable = true;

    @Input() getDataMethod: Observable<any> | Promise<any> | any;

    @Input() bindValue: string = "ID";
    @Input() bindLabel: string = "Name";
    @Input() bindColor: string = "Color";
    @Input() ngModelOptions = {};

    @Input() dataUiid: string = guid();

    @Output() valueChange = new EventEmitter<number | number[]>();

    @Input()
    set value(value: number | number[]) {
        if (this._value === value) {
            return;
        }

        this._value = value;
        this.valueChange.emit(value);
        this._onChangeFormValidation(value);
    }
    get value(): number | number[] {
        return this._value;
    }

    data: any[];
    showNoData: boolean;
    showRefreshButton = true;

    constructor() {}

    ngOnInit(): void {
        this.reloadData();
    }

    reloadData() {
        this.data = null;
        this.showNoData = false;

        const handler = (data: any[]) => {
            this.data = data;
            this.showNoData = this.prettyName && this.data.length === 0;

            let intersects;
            if (Array.isArray(this.value)) {
                intersects = !data.some((x) =>
                    (this.value as []).some((value) => x[this.bindValue] == value)
                );
            } else {
                intersects = !data.some((x) => x[this.bindValue] == this.value);
            }

            if (intersects) {
                if (this.multiple) {
                    this.value = [];
                } else {
                    this.value = null;
                }
            }
        };

        if (isObservable(this.getDataMethod)) {
            (<Observable<any>>this.getDataMethod).subscribe(handler);
        } else if (
            typeof this.getDataMethod === "object" &&
            typeof this.getDataMethod.then === "function"
        ) {
            (<Promise<any>>this.getDataMethod).then(handler);
        } else {
            handler(JSON.parse(JSON.stringify(this.getDataMethod)));
        }
    }

    // Form Validation
    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this._onChangeFormValidation = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouchedFormValidation = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
