import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { guid } from "@methods/uniqueMethods";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Directive({
    selector: "[menuSystem]",
})
export class MenuSystemDirective implements AfterViewInit {
    @Input() cerrixTab: TabModel;
    @Input() collapsed = false;
    @Input() doNotShowTucked = false;
    @Input() loadLastOnReload = true;
    @Output() change = new EventEmitter<TabMenuItem>();
    @Output() onInitComplete = new EventEmitter<TabMenuItem>();

    topHtml: HTMLElement;
    activeMenu: string;

    readonly subMenuSelector = "menusystemsubmenu";
    readonly menuItemSelector = "menusystemitem";
    constructor(public el: ElementRef) {}

    ngAfterViewInit() {
        this.topHtml = this.el.nativeElement as HTMLElement;
        this.topHtml.classList.add(this.cerrixTab.identifier + "-menu-system");

        const menu = new TabMenu();
        menu.menuItemClicked = (menuItem) => {
            this.activateMenuItem(menuItem);
        };

        menu.doNotShowTucked = this.doNotShowTucked;
        menu.menuItems = this.getMenuItems(this.topHtml);
        menu.activeMenuItem = this.activeMenu;

        setTimeout(() => {
            this.cerrixTab.menu = menu;
            this.onInitComplete.emit();
            if (this.loadLastOnReload) {
                this.loadLastActiveTab();
            }
        }, 0);
    }

    getMenuItems(top: HTMLElement): TabMenuItem[] {
        const children = top.children;

        const menuItems: TabMenuItem[] = [];
        for (let i = 0; i < children.length; i++) {
            const child = children[i] as HTMLElement;
            const childType = this.getChildType(child);
            if (childType === MenuType.None) {
                continue;
            }
            if (childType === MenuType.Multiple) {
                throw Error("Child item contains multiple selectors!");
            }

            menuItems.push(this.createMenuItem(child, childType === MenuType.SubMenu));
        }

        return menuItems;
    }

    createMenuItem(element: HTMLElement, isSubMenu: boolean): TabMenuItem {
        const item = new TabMenuItem();

        const elemName = element.getAttribute("name");
        item.name = elemName ? elemName : "Unnamed item";

        const elemIcon = element.getAttribute("menuicon");
        item.iconClass = elemIcon;

        const menuID = this.cerrixTab.identifier + "-" + guid();
        item.identifier = menuID;

        item.menuItemId = element.getAttribute("menuItemId");
        item.menuItemId = item.menuItemId ? item.menuItemId : item.name;

        element.id = menuID;

        if (isSubMenu) {
            item.clickable = false;
            item.children = this.getMenuItems(element);
        } else {
            item.clickable = true;

            if (!this.activeMenu) {
                this.activeMenu = menuID;
            } else {
                element.classList.add("d-none");
            }
        }

        return item;
    }

    getChildType(element: HTMLElement): MenuType {
        let menuType = MenuType.None;

        const isSubmenu = element.hasAttribute(this.subMenuSelector);
        const isItem = element.hasAttribute(this.menuItemSelector);

        if (isSubmenu && isItem) {
            menuType = MenuType.Multiple;
            return menuType;
        }

        if (isSubmenu) {
            menuType = MenuType.SubMenu;
        }
        if (isItem) {
            menuType = MenuType.MenuItem;
        }
        return menuType;
    }

    activateMenuItem(menuItem: TabMenuItem) {
        if (this.activeMenu) {
            const oldPage = document.getElementById(this.activeMenu);
            oldPage.classList.add("d-none");
        }

        this.activeMenu = menuItem.identifier;
        const newPage = document.getElementById(this.activeMenu);
        newPage.classList.remove("d-none");

        this.cerrixTab.menu.activeMenuItem = this.activeMenu;
        if (this.change) {
            this.change.emit(menuItem);
            if (this.loadLastOnReload) {
                this.storeActiveTab(menuItem);
            }
        }
    }

    private loadLastActiveTab() {
        const menuToOpen = this.cerrixTab.config ? decodeURI(this.cerrixTab.config.activemenu) : "";
        if (menuToOpen) {
            const menu = this.cerrixTab.menu.getTabMenuItemByMenuItemId(menuToOpen, true);
            if (menu) {
                this.cerrixTab.menu.menuItemClicked(menu);
            }

            return;
        }
    }

    private storeActiveTab(ev: TabMenuItem) {
        if (!this.cerrixTab.config) {
            this.cerrixTab.config = {};
        }

        this.cerrixTab.config.activemenu = encodeURI(ev.menuItemId);
    }
}

enum MenuType {
    None,
    Multiple,
    SubMenu,
    MenuItem,
}
