import { Component, Input } from "@angular/core";
import { LinkModel } from "@models/generic/LinkModel";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { RiskModel } from "@app/risk/models/RiskModel";

@Component({
    selector: "risk-thirdparty",
    templateUrl: "./risk-thirdparty.component.html"
})
export class RiskThirdpartyComponent {
    @Input() risk: RiskModel;

    linked: LinkModel[] = [];
    unlinked: LinkModel[] = [];
    isLoading = true;

    constructor(private _riskDS: RiskDataService) {}

    load() {
        this.isLoading = true;
        this._riskDS.getLinkedThirdParties(this.risk.guid).subscribe(data => {
            this.linked = data;

            this.isLoading = false;
        });
    }
}
