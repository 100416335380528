<div class="fancy-loader" *ngIf="isLoading && eventModel && eventModel.OrganizationId"></div>
<control-linkable
    *ngIf="!isLoading && eventModel && eventModel.OrganizationId"
    #linkableControls
    typename="controls"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    (linkedChange)="linkedItemsChanged($event)"
    (onLinkedClick)="editLinked($event)"
    (onBatchClicked)="openBatchEdit()"
>
</control-linkable>
