import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { WorkflowStepPermissionModel } from "../../../shared/models/workflow-step-permission-model";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { EvidenceSampleEditModel } from "../../../shared/models/evidence-sample-edit.model";
import { AdvEffDataService } from "../../../shared/services/adv-eff-data.service";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { DocumentModel } from "@models/documents/documentmodel";
import { nameof } from "@methods/jeffs-toolkit";
import { map } from "rxjs/operators";
import { Observable, defer } from "rxjs";
import { TargetModule } from "@enums/document/TargetModule";
import { ValidationModel } from "../../../shared/models/validation-model";
import { getFormControl, getFormValue } from "@methods/CommonMethods";
import { SelectOptionColorModel } from "@models/generic";

@Component({
    selector: "adv-eff-evidence-sample-editor",
    templateUrl: "./adv-eff-evidence-sample-editor.component.html",
    styleUrls: ["./adv-eff-evidence-sample-editor.component.scss"],
})
export class EvidenceSampleEditorComponent implements OnInit {
    evidenceDocumentPermissions: DocumentManagerPermissionModel;
    documentTypeTargets = TargetModule.Control;

    lastReason = "";

    @Input() useReviewer = false;
    @Input() workflowStepPermission: WorkflowStepPermissionModel;
    @Input() evidenceItem: EvidenceSampleEditModel;
    @Input() showAllFields: boolean;
    @Input() scores: SelectOptionColorModel<number>[];

    @ViewChild("container", { static: true }) container: ElementRef;

    get finishedLoading(): boolean {
        return !!this.workflowStepPermission && !!this.evidenceItem;
    }
    get noEvidence(): boolean {
        return (
            this.workflowStepPermission.evidenceUploadEnabled &&
            getFormValue<EvidenceSampleEditModel>(this.mainFormGroup, this.esNoEvidenceProp)
        );
    }
    get documentsDisabled(): boolean {
        return (
            !this.workflowStepPermission.canViewSampleDocuments ||
            getFormControl<EvidenceSampleEditModel>(this.mainFormGroup, this.esDocumentsProp)
                .disabled
        );
    }

    mainFormGroup: FormGroup;

    esNoEvidenceProp: keyof EvidenceSampleEditModel;
    esDocumentsProp: keyof EvidenceSampleEditModel;
    esScoreProp: keyof EvidenceSampleEditModel;
    esCommentTesterProp: keyof EvidenceSampleEditModel;
    esCommentReviewerProp: keyof EvidenceSampleEditModel;
    esNoEvidenceReasonProp: keyof EvidenceSampleEditModel;

    constructor(private advancedTestingService: AdvEffDataService) {
        this.initializeModelProps();
    }

    ngOnInit() {
        this.evidenceDocumentPermissions = new DocumentManagerPermissionModel();
        this.evidenceDocumentPermissions.canAddDocument = false;

        this.initFormValidation();
    }

    enableTesterAssessment(assessmentAllowed: boolean) {
        const scoreControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esScoreProp
        );
        const commentTesterControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esCommentTesterProp
        );
        if (assessmentAllowed && this.workflowStepPermission.testerAssessmentEnabled) {
            scoreControl.enable();
            commentTesterControl.enable();
        } else {
            scoreControl.disable();
            commentTesterControl.disable();
        }
    }

    saveChanges(): Observable<EvidenceSampleEditModel> {
        FormValidationHelper.mapToModel(this.mainFormGroup, this.evidenceItem);

        return defer(async () => {
            this.evidenceItem.status = this.workflowStepPermission.status;

            return await this.advancedTestingService
                .postEvidenceSample(
                    this.evidenceItem.effectivenessGuid,
                    this.evidenceItem.guid,
                    this.evidenceItem
                )
                .pipe(
                    map((result) => {
                        this.evidenceItem.updateValues(result);
                        this.initFormValidation();

                        return result;
                    })
                )
                .toPromise();
        });
    }

    geetName(): string {
        return this.evidenceItem.name;
    }

    isValid(): ValidationModel {
        let isValid = this.mainFormGroup.valid;
        let messages: string[] = [];

        if (!isValid) {
            var errors = FormValidationHelper.getFormControlErrors(this.mainFormGroup);
            if (errors[nameof<EvidenceSampleEditModel>((x) => x.noEvidenceReason)])
                messages.push(`Missing 'Reason no evidence' for sample ${this.evidenceItem.name}`);
            if (errors[nameof<EvidenceSampleEditModel>((x) => x.score)])
                messages.push(`Score is required for sample ${this.evidenceItem.name}`);
            if (errors[nameof<EvidenceSampleEditModel>((x) => x.commentTester)])
                messages.push(`Comment is required for sample ${this.evidenceItem.name}`);
            if (errors[nameof<EvidenceSampleEditModel>((x) => x.commentReviewer)])
                messages.push(`Comment is required for sample ${this.evidenceItem.name}`);
        }

        return { valid: isValid, errors: messages };
    }

    isDirty(): boolean {
        return this.mainFormGroup.dirty;
    }

    markTouched() {
        FormValidationHelper.markAllAsTouched(this.mainFormGroup);
    }

    onDocumentsChange(documents: DocumentModel[]) {
        const documentsControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esDocumentsProp
        );
        documentsControl.setValue(documents);
        documentsControl.markAsDirty();
        documentsControl.markAsTouched();
        documentsControl.updateValueAndValidity();
    }

    private initFormValidation() {
        if (!this.mainFormGroup) {
            this.mainFormGroup = new FormGroup({
                noEvidence: new FormControl({
                    value: this.evidenceItem.noEvidence,
                    disabled: true,
                }),
                noEvidenceReason: new FormControl({
                    value: this.evidenceItem.noEvidenceReason,
                    disabled: true,
                }),
                documents: new FormControl({ value: this.evidenceItem.documents, disabled: true }),
                score: new FormControl({ value: this.evidenceItem.score, disabled: true }),
                commentTester: new FormControl({
                    value: this.evidenceItem.commentTester,
                    disabled: true,
                }),
                commentReviewer: new FormControl({
                    value: this.evidenceItem.commentReviewer,
                    disabled: true,
                }),
            });

            const noEvidenceComponent = getFormControl<EvidenceSampleEditModel>(
                this.mainFormGroup,
                this.esNoEvidenceProp
            );

            noEvidenceComponent.valueChanges.subscribe((noEvidence) => {
                if (this.workflowStepPermission.evidenceUploadEnabled) {
                    this.updateReason(noEvidence);
                    this.setEvidenceValidation(noEvidence);
                }
            });
        } else {
            FormValidationHelper.updateFormGroup(this.evidenceItem, this.mainFormGroup);
        }

        // Disable on evidence doesn't work by default most likely because it's not directly used in html
        // So set to disabled manually
        const documentsControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esDocumentsProp
        );
        documentsControl.disable();

        if (this.workflowStepPermission.evidenceUploadEnabled) {
            const noEvidenceComponent = getFormControl<EvidenceSampleEditModel>(
                this.mainFormGroup,
                this.esNoEvidenceProp
            );
            noEvidenceComponent.enable({ emitEvent: false });

            this.setEvidenceValidation(this.evidenceItem.noEvidence);

            this.evidenceDocumentPermissions.canAddDocument = true;
        } else if (this.workflowStepPermission.testerAssessmentEnabled) {
            const scoreControl = getFormControl<EvidenceSampleEditModel>(
                this.mainFormGroup,
                this.esScoreProp
            );
            scoreControl.setValidators([Validators.required, Validators.min(1), Validators.max(3)]);

            const commentTesterControl = getFormControl<EvidenceSampleEditModel>(
                this.mainFormGroup,
                this.esCommentTesterProp
            );
            commentTesterControl.setValidators([Validators.required]);

            this.evidenceDocumentPermissions.canAddDocument = true;
            documentsControl.enable();
        } else if (this.workflowStepPermission.reviewerAssessmentEnabled) {
            const commentReviewerControl = getFormControl<EvidenceSampleEditModel>(
                this.mainFormGroup,
                this.esCommentReviewerProp
            );
            commentReviewerControl.enable();

            this.evidenceDocumentPermissions.canAddDocument = true;
            documentsControl.enable();
        }
    }

    updateReason(noEvidence: boolean) {
        const noEvidenceReasonControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esNoEvidenceReasonProp
        );
        if (!noEvidence) {
            this.lastReason = noEvidenceReasonControl.value;
            noEvidenceReasonControl.setValue("");
        } else {
            noEvidenceReasonControl.setValue(this.lastReason);
        }
    }

    private setEvidenceValidation(noEvidence: boolean) {
        const noEvidenceReasonControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esNoEvidenceReasonProp
        );
        const documentsControl = getFormControl<EvidenceSampleEditModel>(
            this.mainFormGroup,
            this.esDocumentsProp
        );
        if (noEvidence) {
            noEvidenceReasonControl.setValidators([Validators.required]);
            noEvidenceReasonControl.enable();

            documentsControl.disable();
            documentsControl.clearValidators();
        } else {
            noEvidenceReasonControl.disable();
            noEvidenceReasonControl.clearValidators();

            documentsControl.enable();
        }

        noEvidenceReasonControl.updateValueAndValidity();
        documentsControl.updateValueAndValidity();
    }

    private initializeModelProps() {
        this.esNoEvidenceProp = nameof<EvidenceSampleEditModel>((x) => x.noEvidence);
        this.esDocumentsProp = nameof<EvidenceSampleEditModel>((x) => x.documents);
        this.esScoreProp = nameof<EvidenceSampleEditModel>((x) => x.score);
        this.esCommentTesterProp = nameof<EvidenceSampleEditModel>((x) => x.commentTester);
        this.esCommentReviewerProp = nameof<EvidenceSampleEditModel>((x) => x.commentReviewer);
        this.esNoEvidenceReasonProp = nameof<EvidenceSampleEditModel>((x) => x.noEvidenceReason);
    }
}
