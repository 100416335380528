export enum ItemEnum {
    Unknown = 0,
    Risk = "Risk",
    Control = "Control",
    Process = "Process",
    PredefinedProcess = "Predefined Process",
}

export namespace ItemEnum {
    export enum ShapeEnum {
        risk = "risk",
        riskcontrol = "riskcontrol",
        process = "process",
        predefinedprocess = "predefinedprocess",
    }

    export function fromShape(shape: keyof typeof ShapeEnum): ItemEnum {
        switch (shape) {
            case ShapeEnum.risk:
                return ItemEnum.Risk;
            case ShapeEnum.riskcontrol:
                return ItemEnum.Control;
            case ShapeEnum.process:
                return ItemEnum.Process;
            case ShapeEnum.predefinedprocess:
                return ItemEnum.PredefinedProcess;

            default:
                return ItemEnum.Unknown;
        }
    }

    export function getNamePlural(itemEnum: ItemEnum) {
        return itemEnum.toString() + "s";
    }
}
