import { Component, OnInit } from "@angular/core";
import { DashboardWidgetMapping } from "../shared/DashboardWidgetMapping";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { DashboardWidgetModel } from "../shared/models/DashboardWidgetModel";

@Component({
    selector: "widget-market",
    templateUrl: "./widget-market.component.html",
    styleUrls: ["./widget-market.component.scss"],
})
export class WidgetMarketComponent implements OnInit {
    marketWidgets: DashboardWidgetModel[];
    filteredMarketWidgets: DashboardWidgetModel[];

    draggingMarketItem: DashboardWidgetModel;

    constructor(private _permService: PermissionsService) {}

    ngOnInit() {
        this.initMarketItems();
        this.filter(null);
    }

    dragStartHandler(ev: DragEvent, draggingItem: DashboardWidgetModel) {
        this.draggingMarketItem = draggingItem;

        ev.dataTransfer.setData("text/plain", JSON.stringify(draggingItem.customConfig));
        ev.dataTransfer.dropEffect = "copy";
    }

    filter(filterString: string) {
        if (!filterString || filterString.trim() === "") {
            this.filteredMarketWidgets = this.marketWidgets;
            return;
        }

        filterString = filterString.trim().toLowerCase();

        this.filteredMarketWidgets = this.marketWidgets.filter(
            (mw) =>
                mw.title.toLowerCase().indexOf(filterString) >= 0 ||
                (mw.description && mw.description.toLowerCase().indexOf(filterString) >= 0)
        );
    }

    private initMarketItems() {
        const allWidgets = DashboardWidgetMapping.all();
        const sortedWidgets = this.SortWidgets(allWidgets);

        this.marketWidgets = sortedWidgets.filter((widget) => {
            if (!widget.right) {
                return true;
            }

            return this._permService.hasStringRight(widget.right);
        });
    }

    private SortWidgets(widgets: DashboardWidgetModel[]) {
        var sortedWidgets = widgets.sort((n1, n2) => {
            if (n1.title.toLocaleLowerCase() > n2.title.toLocaleLowerCase()) {
                return 1;
            }

            if (n1.title.toLocaleLowerCase() < n2.title.toLocaleLowerCase()) {
                return -1;
            }

            return 0;
        });
        return sortedWidgets;
    }
}
