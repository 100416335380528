import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "eff-popup-header",
    templateUrl: "./eff-popup-header.component.html",
    styleUrls: ["./eff-popup-header.component.scss"],
})
export class EffPopupHeaderComponent {
    @Input() title: string;
    @Input() dialogRef: MatDialogRef<any>;
    @Input() icon: string;
    @Output() onClose: EventEmitter<void> = new EventEmitter();
    constructor() {}

    closeDialog() {
        this.onClose.emit();
        this.dialogRef.close();
    }

    get showIcon(): boolean {
        return this.icon !== undefined && this.icon !== null && this.icon !== "";
    }
}
