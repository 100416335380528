div.process-widget-image {
    height: 100%;
    width: 100%;
    user-select: none;

    > svg {
        height: 100%;
        width: 100%;
    }
}

div.widget-container {
    width: 100%;
    height: 100%;
    padding: 10px;

    div.process-widget-image {
        cursor: pointer;
    }
}
