<div class="calendar-view" [style.max-height]="maxHeight">
    <div class="calendar-items">
        <div class="calendar-loading" *ngIf="!calendar">
            <div class="fancy-loader submodule"></div>
        </div>

        <ng-container *ngIf="calendar">
            <ng-container *ngIf="calendar.days.length == 0">
                <div class="calendar-empty">
                    <div class="done-visual">
                        <div class="line"><hr /></div>

                        <div class="check">
                            <i class="fal fa-check-circle"></i>
                        </div>

                        <div class="line"><hr /></div>
                    </div>

                    <p>You're All Caught Up!</p>
                </div>
            </ng-container>

            <ng-container *ngIf="calendar.days.length > 0">
                <div class="detail-page-card" *ngFor="let day of calendar.days">
                    <div class="detail-page-card-title">{{ day.name }}</div>
                    <div
                        class="detail-page-card-body cerrix-control-sm list-group list-group-flush"
                    >
                        <div
                            class="list-group-item list-group-item-action"
                            *ngFor="let item of day.items"
                            (click)="openItem(item)"
                        >
                            <div class="title">
                                <span class="name" [title]="item.name">
                                    {{ item.name }}
                                </span>

                                <small class="time-indication" [class.red]="day.isOverdue">
                                    {{ item.timeframe }}
                                </small>
                            </div>

                            <p class="description text-truncate" [title]="item.description">
                                {{ item.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="calendar-actions" *ngIf="showActions">
        <div class="calendar-action" (click)="reload(true)" [disabled]="!calendar">
            <i class="fas fa-sync" [class.fa-spin]="!calendar"></i>
        </div>

        <div class="calendar-action" (click)="openTab(pages.Calendar)">
            <i class="fas fa-calendar"></i>
        </div>

        <div class="calendar-action" (click)="openTab(pages.CalendarSettings)">
            <i class="fas fa-cog"></i>
        </div>
    </div>
</div>
