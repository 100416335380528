import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentDocumentTypeModel } from "../models/incident-document-type.model";

@Injectable({
    providedIn: "root",
})
export class IncidentDocumentTypeService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/document-types`;

    public getAll(): Observable<IncidentDocumentTypeModel[]> {
        return this.httpClient.get<IncidentDocumentTypeModel[]>(this.baseUrl);
    }
}
