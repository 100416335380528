import { Component, OnInit } from '@angular/core';
import { TabService } from '@services/tabs/TabService';
import { Pages } from '@constants/pages/Pages';
import { PermissionsService } from '@services/permissions/PermissionsService';

@Component({
    selector: 'app-export-overview',
    templateUrl: './export-overview.component.html',
    styleUrls: ['./export-overview.component.scss']
})
export class ExportOverviewComponent implements OnInit {
    downloadlink: string;

    constructor(public tabService: TabService, public pages: Pages, public permService: PermissionsService) { }

    ngOnInit() {

    }

}
