import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ProcessedDocumentsService } from "@app/document-intelligence/services/processed-documents-service";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ProcessedDocumentDetailModel } from "@app/document-intelligence/models/ProcessedDocumentDetailModel";
import { ProcessedDocumentSuggestionModel } from "@app/document-intelligence/models/ProcessedDocumentSuggestionModel";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { RiskConfigModel } from "@app/risk/models/risk-config-model";
import { ControlConfigModel } from "@app/controls/models/ControlConfigModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ProcessedDocumentFeedbackModel } from "@app/document-intelligence/models/ProcessedDocumentFeedbackModel";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "processed-document-detail",
    templateUrl: "./processed-document-detail.component.html",
    styleUrls: ["./processed-document-detail.component.scss"],
})
export class ProcessedDocumentDetailComponent implements OnInit {
    tab: TabModel;
    id: string;

    detailModel: ProcessedDocumentDetailModel;

    constructor(
        private _ds: ProcessedDocumentsService,
        private route: ActivatedRoute,
        private _tabService: TabService,
        private _pages: Pages,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;

        const detail = await firstValueFrom(this._ds.getDetail(+this.id));

        this.tab.name = `(PD) ${detail.id} - ${detail.title}`;

        this.detailModel = detail;
    }

    protected acceptRisk(suggestedRisk: ProcessedDocumentSuggestionModel) {
        this.openRiskTab(suggestedRisk);
    }

    protected async acceptRiskWithEdits(suggestedRisk: ProcessedDocumentSuggestionModel) {
        const result = await this.promptFeedback(
            suggestedRisk,
            "Accept risk with edits",
            "Provide the updated description for the risk. This will be used to improve future suggestions.",
            "Accept with edits"
        );

        if (result) {
            this.openRiskTab(suggestedRisk, result);
        }
    }

    protected async declineRisk(suggestedRisk: ProcessedDocumentSuggestionModel) {
        const showNameAndDescription = false;

        const result = await this.promptFeedback(
            suggestedRisk,
            "Decline risk",
            "Provide the reason for declining the risk. This will be used to improve future suggestions.",
            "Decline",
            showNameAndDescription
        );

        if (result) {
            await firstValueFrom(this._ds.declineSuggestedRisk(result));

            suggestedRisk.isDoneProcessing = true;
        }
    }

    private openRiskTab(
        suggestedRisk: ProcessedDocumentSuggestionModel,
        feedback?: ProcessedDocumentFeedbackModel
    ) {
        const config = <RiskConfigModel>{
            name: suggestedRisk.name,
            description: suggestedRisk.description,
            processedDocumentRiskId: suggestedRisk.id,
        };

        if (feedback && feedback.reasonUpdate.length > 0) {
            config.name = feedback.updatedName;
            config.description = feedback.updatedDescription;
            config.reasonUpdate = feedback.reasonUpdate;
        }

        this._tabService.generateTab(this._pages.RiskDetail, 0, "(R) New Risk", config);
    }

    protected acceptControl(suggestedControl: ProcessedDocumentSuggestionModel) {
        this.openControlTab(suggestedControl);
    }
    protected async acceptControlWithEdits(suggestedControl: ProcessedDocumentSuggestionModel) {
        const result = await this.promptFeedback(
            suggestedControl,
            "Accept control with edits",
            "Provide the updated name and/or description for the control. This will be used to improve future suggestions.",
            "Accept with edits"
        );

        if (result) {
            this.openControlTab(suggestedControl, result);
        }
    }

    protected async declineControl(suggestedControl: ProcessedDocumentSuggestionModel) {
        const showNameAndDescription = false;

        const result = await this.promptFeedback(
            suggestedControl,
            "Decline control",
            "Provide the reason for declining the control. This will be used to improve future suggestions.",
            "Decline",
            showNameAndDescription
        );

        if (result) {
            await firstValueFrom(this._ds.declineSuggestedControl(result));

            suggestedControl.isDoneProcessing = true;
        }
    }

    protected async delete() {
        await firstValueFrom(this._ds.deleteProcessedDocument(this.detailModel.id));

        this._toastr.success("Processed document deleted");
        this._tabService.refresh(this._pages.ProcessedDocumentOverview);
        this.tab.close(false);
    }

    private openControlTab(
        suggestedControl: ProcessedDocumentSuggestionModel,
        feedback?: ProcessedDocumentFeedbackModel
    ) {
        const config = <ControlConfigModel>{
            name: suggestedControl.name,
            description: suggestedControl.description,
            processedDocumentControlId: suggestedControl.id,
        };

        if (feedback && feedback.reasonUpdate.length > 0) {
            config.name = feedback.updatedName;
            config.description = feedback.updatedDescription;
            config.reasonUpdate = feedback.reasonUpdate;
        }

        this._tabService.generateTab(this._pages.ControlDetail, 0, "(C) New Control", config);
    }

    private promptFeedback(
        suggestedItem: ProcessedDocumentSuggestionModel,
        title: string,
        message: string,
        confirmButton: string,
        showNameAndDescription: boolean = true
    ): Promise<ProcessedDocumentFeedbackModel> {
        return this._promptService
            .confirmCustom({
                maxWidth: "800px",
                maxHeight: showNameAndDescription ? "680px" : "480px",
                data: {
                    title: title,
                    message: message,
                    fields: [
                        {
                            prettyName: "Updated name",
                            fieldName: "feedbackName",
                            fieldType: GenericListFieldType.Text,
                            defaultValue: suggestedItem.description,
                            hideInEdit: !showNameAndDescription,
                        },
                        {
                            prettyName: "Updated description",
                            fieldName: "feedbackDescription",
                            fieldType: GenericListFieldType.TextArea,
                            defaultValue: suggestedItem.description,
                            hideInEdit: !showNameAndDescription,
                        },
                        {
                            prettyName: "Reason",
                            fieldName: "feedbackReason",
                            fieldType: GenericListFieldType.TextArea,
                            required: true,
                        },
                    ],
                    confirmButton: {
                        text: confirmButton,
                    },
                },
            })
            .toPromise()
            .then((result) => {
                if (result) {
                    return <ProcessedDocumentFeedbackModel>{
                        id: suggestedItem.id,
                        updatedName: result.feedbackName,
                        updatedDescription: result.feedbackDescription,
                        reasonUpdate: result.feedbackReason,
                    };
                }
            });
    }
}
