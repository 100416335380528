import { Component } from "@angular/core";
import { CalendarDataService } from "@app/calendar/services/calendar-data.service";

@Component({
    selector: "navbar-calendar",
    templateUrl: "./navbar-calendar.component.html",
    styleUrls: ["../navbar-plugin-base.scss", "./navbar-calendar.component.scss"],
})
export class NavbarCalendarComponent {
    get todoCount() {
        return this._calendarDS.calendarModel ? this._calendarDS.calendarModel.todoCount : 0;
    }

    constructor(private _calendarDS: CalendarDataService) {}
}
