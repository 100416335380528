<ng-template #template>
    <div class="modal-header">
        <h5 class="modal-title pull-left">
            {{ editEnabled ? "Edit" : "View" }} {{ config.name | lowercase }}
        </h5>
    </div>
    <div class="modal-body cerrix-control-sm container-fluid" *ngIf="row">
        <div class="row">
            <div
                class="form-group col-{{ field.editorWidth ? field.editorWidth : '12' }}"
                *ngFor="let field of FieldsToEdit"
            >
                <generic-field-editor
                    [row]="row"
                    [field]="field"
                    [editEnabled]="editEnabled"
                    [additionalData]="additionalData"
                    (valueChanged)="fieldValueChanged($event)"
                ></generic-field-editor>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="config.editWindowButtons">
            <button
                *ngFor="let btn of config.editWindowButtons"
                [class]="btn.class ? btn.class : 'btn btn-secondary'"
                [attr.data-uiid]="btn.uiid"
                (click)="customButtonClicked(btn)"
            >
                <i [class]="btn.icon" *ngIf="btn.icon"></i>
                <span class="ms-2">{{ btn.text }}</span>
            </button>
            <div class="custom-button-seperator"></div>
        </ng-container>
        <button
            data-uiid="genericdialog-applybutton"
            type="button"
            class="btn btn-primary"
            (click)="apply()"
            *ngIf="editEnabled"
        >
            Apply changes
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    </div>
</ng-template>
