import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { SelectOption } from "./models/select-option.model";
import { FormControl } from "@angular/forms";

@Component({
    selector: "eff-select",
    templateUrl: "./eff-select.component.html",
    styleUrls: ["./eff-select.component.scss"],
})
export class EffSelectComponent {
    @Input() options: Array<SelectOption> = [];
    @Input() label: string;
    @Input() model: any;
    @Input() formController: FormControl = new FormControl();
    @Input() panelClass: string;
    @Input() isTemplate: boolean = false;
    @Input() optionTemplate: TemplateRef<any>;
    @Input() selectedOptionTemplate: TemplateRef<any>;
    @Input() disabled: boolean = false;

    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    selectedOption: SelectOption;
    @ViewChild("selector") selector: any;

    ngOnInit() {
        this.selectedOption = this.options.find((o) => o.Id == this.formController.value);
        this.formController.valueChanges.subscribe((value) => {
            this.model = value;
            this.selectedOption = this.options.find((o) => o.Id == this.formController.value);
            this.onModelChanged();
        });

        if (this.disabled) {
            this.disableEnableFormControl();
        }
    }

    onModelChanged() {
        this.modelChange.emit(this.formController.value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["model"]) {
            this.formController.setValue(this.model);
        }

        if (changes["disabled"]) {
            this.disableEnableFormControl();
        }
    }

    disableEnableFormControl() {
        if (this.formController) {
            if (this.disabled) {
                this.formController.disable();
            } else {
                this.formController.enable();
            }
        }
    }

    open() {
        this.selector?.open();
    }

    close() {
        this.selector?.close();
    }
}
