import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { JwtExternalAuthenticationService } from "@app/authentication/shared/jwt-external-authentication.service";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private jwtExternalAuthService: JwtExternalAuthenticationService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // For now token is only used at login to initialize form authentication
        if (request.url.endsWith("/login/external")) {
            const authenticationToken =
                this.jwtExternalAuthService.getExternalAuthenticationToken();
            if (authenticationToken) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${authenticationToken}`,
                    },
                });
            }
        }

        return next.handle(request);
    }
}
