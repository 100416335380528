.form-status-overview {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;

    .detail-page-action-bar {
        input.form-control {
            max-width: 400px;
        }
    }

    .categories {
        flex: 1;
        padding: 20px;
        overflow: auto;

        .category-overview {
            & + .category-overview {
                border-top: 1px solid #eaeaea;
            }

            .category-title {
                font-size: 1.5em;
                font-weight: bold;
            }

            .status-overview {
                td {
                    width: 20%;
                }

                &.workflow-status-overview {
                    td {
                        width: calc(100% / 6);
                    }
                }

                .open-color {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    margin: 1px 0;
                }

                .headers {
                    flex-direction: row;
                    margin-left: 10px;

                    .detail-page-card-actions {
                        display: flex;
                        justify-content: space-between;
                    }

                    .left-items {
                        display: flex;
                        justify-content: flex-start;
                    }

                    .right-items {
                        display: flex;
                        justify-content: flex-end;
                        margin-left: auto;
                    }
                }

                .detail-page-card:not(.headers) {
                    margin-top: 0;

                    .detail-page-card-actions {
                        display: none;
                    }
                }
            }
        }
    }
}
