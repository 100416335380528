<eff-popup-header
    [icon]="icon"
    [title]="data.title"
    [dialogRef]="dialogRef"
    (onClose)="data.onActionPerformed(2)"
></eff-popup-header>
<div class="dialog-body">
    <div class="regular-text-big">
        {{ data.message }}
    </div>
</div>

<div class="dialog-footer">
    <eff-button
        *ngIf="data.cancelButton"
        size="medium-size"
        class="cancel-button"
        [text]="data.cancelButton"
        (click)="data.onActionPerformed(2)"
    ></eff-button>
    <eff-button
        [class]="data.confirmType"
        [text]="data.confirmButton"
        (click)="data.onActionPerformed(1)"
    ></eff-button>
</div>
