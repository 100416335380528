<form [formGroup]="parentForm">
    <div *ngIf="!customFieldControlInfos || customFieldControlInfos.length === 0">
        {{ emptyMessage }}
    </div>
    <div formArrayName="customFields" *ngIf="customFieldControlInfos">
        <div class="row">
            <div
                [formGroupName]="customFieldIndex"
                *ngFor="let customField of customFieldControlInfos; let customFieldIndex = index"
                class="form-group col-{{ columns }}  {{
                    !singleColumn ? (customFieldIndex % 2 == 0 ? 'left-block' : 'right-block') : ''
                }}"
            >
                <label
                    for="customFieldInput-{{ customField.id }}"
                    *ngIf="
                        customField.data.fieldType != CustomFieldType.Textfield &&
                        customField.data.fieldType != CustomFieldType.Textarea
                    "
                    >{{ customField.data.Name }}</label
                >

                <cerrix-textfield
                    *ngIf="customField.data.fieldType == CustomFieldType.Textfield"
                    [fieldLabel]="customField.data.Name"
                    identifier="customFieldInput-{{ customField.id }}"
                    formControlName="value"
                    [placeholder]="customField.data.required ? 'Required' : ''"
                ></cerrix-textfield>

                <cerrix-textarea
                    *ngIf="customField.data.fieldType == CustomFieldType.Textarea"
                    [fieldLabel]="customField.data.Name"
                    identifier="customFieldInput-{{ customField.id }}"
                    [rows]="9"
                    formControlName="value"
                    [placeholder]="customField.data.required ? 'Required' : ''"
                ></cerrix-textarea>

                <date-input
                    *ngIf="customField.data.fieldType == CustomFieldType.DateField"
                    name="customFieldInput-{{ customField.id }}"
                    formControlName="dateValue"
                    [required]="customField.data.required"
                    inputTooltip
                ></date-input>

                <input
                    *ngIf="customField.data.fieldType == CustomFieldType.NumberField"
                    class="form-control form-control-sm"
                    formControlName="value"
                    type="number"
                    name="customFieldInput-{{ customField.id }}"
                    [required]="customField.data.required"
                    [placeholder]="customField.data.required ? 'Required' : ''"
                    inputTooltip
                />

                <ng-select
                    *ngIf="
                        customField.data.fieldType == CustomFieldType.SingleSelect ||
                        customField.data.fieldType == CustomFieldType.MultiSelect
                    "
                    [formControlName]="
                        customField.data.fieldType == CustomFieldType.SingleSelect
                            ? 'optionId'
                            : 'optionIds'
                    "
                    name="customFieldInput-{{ customField.id }}"
                    [placeholder]="customField.data.required ? 'Required' : ''"
                    [items]="customField.data.answerOptions"
                    [multiple]="customField.data.fieldType == CustomFieldType.MultiSelect"
                    [closeOnSelect]="customField.data.fieldType == CustomFieldType.SingleSelect"
                    bindLabel="Name"
                    bindValue="ID"
                    inputTooltip
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        <div [style.background-color]="item.Color" class="color-indicator"></div>
                        {{ item.Name }}
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
</form>
