import { Observable, Subject, Subscription, forkJoin } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocomplete, MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { AsyncPipe, CommonModule } from "@angular/common";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormGroupDirective,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabGroup, MatTabsModule } from "@angular/material/tabs";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRippleModule, MAT_DATE_FORMATS } from "@angular/material/core";
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
    CdkDrag,
    CdkDropList,
} from "@angular/cdk/drag-drop";
import { MatDatepickerModule } from "@angular/material/datepicker";
import moment from "moment";

import { IncidentService } from "../../incident/services/incident.service";
import { ComparisonOperator } from "../models/comparison-operator.enum";
import { FieldType } from "../models/field-type.enum";
import { PresetModel } from "../models/preset.model";
import { PresetRuleEditModel } from "../models/preset-rule-edit.model";
import { PresetColumnModel } from "../models/preset-column.model";
import { PresetDialogInput } from "../models/preset-dialog-input.model";
import { PresetDialogResult } from "../models/preset-dialog-result.model";
import { PresetService } from "@app/preset/services/preset.service";
import { comparisonOperatorsTranslation } from "locale/translations-mappings/comparison-operators-translation";
import { PresetBaseModel } from "@app/preset/models/preset-base.model";
import { LocalizationService } from "../../shared/localization/localization.service";
import { DateTimeDisplayPipe } from "../../shared/pipes/date-time-display.pipe";
import { incidentColumnsTranslation } from "../../../locale/translations-mappings/incident-columns-translation";
import { PresetConfigColumnModel } from "../models/preset-config-column.model";
import { SelectOptionModel } from "../models/select-option.model";
import { PresetRuleDescriptionModel } from "../models/preset-rule-description.model";
import { CerrixTreeSelectComponent, TreeItemNode } from "@cerrix/components";

@Component({
    selector: "preset-config-dialog",
    templateUrl: "./preset-config-dialog.component.html",
    styleUrls: ["./preset-config-dialog.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatChipsModule,
        AsyncPipe,
        MatDialogModule,
        MatGridListModule,
        MatCheckboxModule,
        MatTabsModule,
        CdkDropList,
        CdkDrag,
        MatRippleModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        DateTimeDisplayPipe,
        CerrixTreeSelectComponent,
    ],
    providers: [IncidentService, PresetService],
})
export class PresetConfigDialogComponent implements OnInit {
    protected readonly ComparisonOperator: typeof ComparisonOperator = ComparisonOperator;
    protected readonly FieldType: typeof FieldType = FieldType;
    protected readonly incidentColumnsTranslation: Map<string, string> = incidentColumnsTranslation;
    protected readonly comparisonOperatorsTranslation: Map<ComparisonOperator, string> =
        comparisonOperatorsTranslation;

    @Input() data: PresetDialogInput | null = inject(MAT_DIALOG_DATA);
    @Output() public favoriteAddEvent: EventEmitter<PresetBaseModel> =
        new EventEmitter<PresetBaseModel>();
    @Output() public favoriteRemoveEvent: EventEmitter<PresetBaseModel> =
        new EventEmitter<PresetBaseModel>();

    protected dateFormats = inject(MAT_DATE_FORMATS);

    private destroyRef = inject(DestroyRef);
    private presetService = inject(PresetService);

    private localizationService = inject(LocalizationService);
    private dialogRef: MatDialogRef<PresetConfigDialogComponent, PresetDialogResult> = inject(
        MatDialogRef<PresetConfigDialogComponent, PresetDialogInput>
    );

    @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
    @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;

    private preset: PresetModel | null = null;
    protected dataLoaded: boolean = false;

    protected treeColumnNames: string[] = [];
    protected filteredColumns$ = new Subject<PresetConfigColumnModel[]>();
    private columns: PresetConfigColumnModel[] = [];
    private previousColumn: PresetConfigColumnModel | null = null;
    private comparisonOperators: { value: ComparisonOperator; name: string }[] = [];
    protected filteredComparisonOperators$ = new Subject<
        { value: ComparisonOperator; name: string }[]
    >();

    private getOptionsSubscription: Subscription = null;
    protected loadingRuleSearchOptions: boolean = false;
    protected ruleSearchOptions: SelectOptionModel[] | TreeItemNode[] = [];

    protected presetRules: PresetRuleEditModel[] = [];

    protected presetRuleForm = new FormGroup({
        id: new FormControl<string | undefined>(undefined),
        comparisonOperator: new FormControl<
            { value: ComparisonOperator; name: string } | undefined
        >(undefined, Validators.required),
        column: new FormControl<PresetConfigColumnModel | undefined>(
            undefined,
            (control: AbstractControl): ValidationErrors | null => {
                return control.value && typeof control.value === "object"
                    ? null
                    : { required: true };
            }
        ),
        searchValue: new FormControl(undefined, Validators.required),
    });

    protected visibleColumns: PresetConfigColumnModel[] = [];
    protected hiddenColumns: PresetConfigColumnModel[] = [];

    protected presetDetailsForm = new FormGroup({
        name: new FormControl<string | undefined>(undefined, Validators.required),
        icon: new FormControl<string | undefined>(undefined),
        description: new FormControl<string | undefined>(undefined),
        isFavorite: new FormControl<boolean>(false, Validators.required),
    });

    protected hasColumnsChanges: boolean = false;
    private initialFavoriteValue: boolean = false;
    protected saveDisabled: boolean = false;

    ngOnInit() {
        this.treeColumnNames = this.presetService.getTreeColumnNames();
        this.dateFormats.parse.dateInput = this.localizationService.localizationInfo.dateFormat;
        this.dateFormats.display.dateInput = this.localizationService.localizationInfo.dateFormat;

        this.setPreset();
        this.loadData();
    }

    private setPreset(): void {
        if (this.data.preset) {
            this.preset = { ...this.data.preset };
            if (!this.preset.predefined) {
                this.presetDetailsForm.patchValue({
                    name: this.preset.name,
                    icon: this.preset.icon,
                    description: this.preset.description,
                    isFavorite: this.preset.favorite,
                });
            }
        } else {
            this.preset = {
                name: "",
                favorite: false,
                systemDefault: false,
                predefined: false,
                presetRules: [],
                presetColumns: [],
            };
        }

        this.initialFavoriteValue = this.preset.favorite;
    }

    private loadData(): void {
        forkJoin([
            this.presetService.getAllColumns().pipe(takeUntilDestroyed(this.destroyRef)),
            this.getPresetRulesDescription().pipe(takeUntilDestroyed(this.destroyRef)),
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([headers, presetRules]) => {
                this.columns = headers;
                this.setPresetRules(presetRules);
                this.setColumnsFilter();
                this.setComparisonOperators();
                this.setColumnsDisplay();

                this.dataLoaded = true;
            });
    }

    private getPresetRulesDescription(): Observable<PresetRuleDescriptionModel[]> {
        if (this.preset.presetRules?.length) {
            return this.presetService.getPresetRulesDescription(this.preset.presetRules);
        } else {
            return new Observable((obs) => {
                obs.next([]);
                obs.complete();
            });
        }
    }

    private setComparisonOperators(): void {
        this.comparisonOperators = Object.values(ComparisonOperator)
            .filter((v) => !isNaN(Number(v)))
            .map((v) => {
                return {
                    name: this.comparisonOperatorsTranslation.get(v as ComparisonOperator),
                    value: v as ComparisonOperator,
                };
            });

        this.filteredComparisonOperators$.next(this.comparisonOperators);
    }

    private setPresetRules(presetRules: PresetRuleDescriptionModel[]): void {
        if (presetRules?.length) {
            this.presetRules = presetRules.map((pr) => {
                const colName = pr.customFieldId ?? pr.columnName;
                const column = this.columns.find((c) => c.name === colName);

                return {
                    ...pr,
                    columnName: column.translation ?? column.name,
                    fieldType: column.fieldType,
                };
            });
        }
    }

    private setColumnsFilter(): void {
        this.filteredColumns$.next(this.filterColumns(""));

        this.presetRuleForm.controls.column.valueChanges.subscribe((value) => {
            if (!value || typeof value === "string") {
                this.presetRuleForm.patchValue(
                    {
                        searchValue: undefined,
                    },
                    { emitEvent: true }
                );
            }

            this.filteredColumns$.next(this.filterColumns(value || ""));
        });
    }

    private setColumnsDisplay(): void {
        if (this.preset?.presetColumns?.length) {
            const vcUnordered: { order: number; column: PresetConfigColumnModel }[] = [];
            this.columns.forEach((c) => {
                var presetColumn = this.preset.presetColumns.find(
                    (pc) =>
                        (pc.customFieldId && pc.customFieldId === c.name) ||
                        (!pc.customFieldId && pc.columnName === c.name)
                );

                if (presetColumn) {
                    vcUnordered.push({ order: presetColumn.order, column: c });
                } else {
                    this.hiddenColumns.push(c);
                }
            });

            this.visibleColumns = vcUnordered
                .sort((a, b) => a.order - b.order)
                .map((c) => c.column);
        } else {
            this.visibleColumns = [...this.columns];
        }

        this.hasColumnsChanges =
            JSON.stringify(this.columns) !== JSON.stringify(this.visibleColumns);
    }

    private filterColumns(value: string | PresetConfigColumnModel): PresetConfigColumnModel[] {
        const filterValue =
            typeof value === "string"
                ? value.toLowerCase()
                : value.translation
                ? value.translation.toLowerCase()
                : this.incidentColumnsTranslation.get(value.name)?.toLowerCase();

        return this.columns.filter(
            (c) =>
                (c.translation && c.translation.toLowerCase().includes(filterValue)) ||
                (!c.translation &&
                    this.incidentColumnsTranslation
                        .get(c.name)
                        ?.toLowerCase()
                        ?.includes(filterValue))
        );
    }

    private getOperatorsForFieldType(fieldType: FieldType): ComparisonOperator[] {
        switch (fieldType) {
            case FieldType.TextBox:
            case FieldType.TextField:
                return [
                    ComparisonOperator.Equal,
                    ComparisonOperator.NotEqual,
                    ComparisonOperator.StartsWith,
                    ComparisonOperator.EndsWith,
                    ComparisonOperator.Contains,
                    ComparisonOperator.NotContains,
                ];

            case FieldType.Date:
            case FieldType.DateTime:
                return [
                    ComparisonOperator.Equal,
                    ComparisonOperator.NotEqual,
                    ComparisonOperator.LessThan,
                    ComparisonOperator.LessThanOrEqual,
                    ComparisonOperator.GreaterThan,
                    ComparisonOperator.GreaterThanOrEqual,
                ];
            case FieldType.SingleSelect:
            case FieldType.MultiSelect:
                return [
                    ComparisonOperator.Equal,
                    ComparisonOperator.Contains,
                    ComparisonOperator.NotContains,
                ];
            case FieldType.Numeric:
                return [
                    ComparisonOperator.Equal,
                    ComparisonOperator.NotEqual,
                    ComparisonOperator.LessThan,
                    ComparisonOperator.LessThanOrEqual,
                    ComparisonOperator.GreaterThan,
                    ComparisonOperator.GreaterThanOrEqual,
                ];

            case FieldType.Checkbox:
                return [ComparisonOperator.Equal, ComparisonOperator.NotEqual];
            default:
                return [];
        }
    }

    protected displayColumnText(column: PresetConfigColumnModel): string {
        if (column) {
            return column.translation
                ? column.translation
                : this.incidentColumnsTranslation.get(column.name);
        }
        return "";
    }

    private fillPresetValues(): void {
        this.preset.name = this.presetDetailsForm.value.name;
        this.preset.description = this.presetDetailsForm.value.description;
        this.preset.icon = this.presetDetailsForm.value.icon;
        this.preset.favorite = this.presetDetailsForm.value.isFavorite;

        this.preset.presetRules = this.presetRules.map((pr) => {
            const { fieldType, displayValue, ...others } = pr;
            return {
                ...others,
                columnName: pr.customFieldId ? undefined : pr.columnName,
            };
        });

        this.preset.presetColumns = this.visibleColumns.map((c, index) => {
            return {
                customFieldId: c.translation ? c.name : undefined,
                columnName: c.translation ? undefined : c.name,
                order: index,
            };
        });
    }

    private clearRuleSearchOptions(): void {
        this.loadingRuleSearchOptions = false;

        if (this.getOptionsSubscription && !this.getOptionsSubscription.closed) {
            this.getOptionsSubscription.unsubscribe();
        }

        if (this.ruleSearchOptions.length) {
            this.ruleSearchOptions = [];
        }
    }

    protected onSelectedColumn(column: PresetConfigColumnModel, isEdit: boolean): void {
        if (column) {
            const fieldTypeOperators = this.getOperatorsForFieldType(column.fieldType);
            const newComparisonOperators = this.comparisonOperators.filter((x) =>
                fieldTypeOperators.includes(x.value)
            );
            this.filteredComparisonOperators$.next(newComparisonOperators);

            if (
                column.fieldType === FieldType.SingleSelect ||
                column.fieldType === FieldType.MultiSelect
            ) {
                if (this.previousColumn?.name !== column.name) {
                    this.clearRuleSearchOptions();

                    this.getOptionsSubscription = this.presetService
                        .getColumnOptions(column.name, !!column.translation)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe((options) => {
                            this.loadingRuleSearchOptions = false;
                            this.ruleSearchOptions = options;
                        });
                }
            } else if (
                this.previousColumn?.fieldType === FieldType.SingleSelect ||
                this.previousColumn?.fieldType === FieldType.MultiSelect
            ) {
                this.clearRuleSearchOptions();
            }

            if (!isEdit) {
                if (
                    this.presetRuleForm.value.comparisonOperator &&
                    !newComparisonOperators.some(
                        (co) => co.value === this.presetRuleForm.value.comparisonOperator.value
                    )
                ) {
                    this.presetRuleForm.patchValue(
                        {
                            comparisonOperator: undefined,
                        },
                        { emitEvent: true }
                    );
                }
            }

            this.previousColumn = column;
        }
    }

    protected onAddFilter(formDirective: FormGroupDirective): void {
        if (this.presetRuleForm.valid) {
            let searchValue = this.presetRuleForm.value.searchValue?.toString();
            if (
                this.presetRuleForm.value.column.fieldType === FieldType.DateTime ||
                this.presetRuleForm.value.column.fieldType === FieldType.Date
            ) {
                searchValue = moment(this.presetRuleForm.value.searchValue).toISOString();
            }

            let displayValue = undefined;

            if (
                this.presetRuleForm.value.column.fieldType === FieldType.MultiSelect ||
                this.presetRuleForm.value.column.fieldType === FieldType.SingleSelect
            ) {
                displayValue = this.ruleSearchOptions
                    .filter((o) => this.presetRuleForm.value.searchValue.includes(o.id))
                    .map((o) => o.name)
                    .join(", ");
            }

            this.presetRules.push({
                id: this.presetRuleForm.value.id,
                customFieldId: this.presetRuleForm.value.column.translation
                    ? this.presetRuleForm.value.column.name
                    : undefined,
                columnName:
                    this.presetRuleForm.value.column.translation ??
                    this.presetRuleForm.value.column.name,
                fieldType: this.presetRuleForm.value.column.fieldType,
                comparisonOperator: this.presetRuleForm.value.comparisonOperator.value,
                value: searchValue,
                displayValue: displayValue,
            });

            formDirective.resetForm();
            this.presetRuleForm.reset();

            this.autocomplete.options.find((o) => o.selected)?.deselect();
        }
    }

    protected onRemoveFilter(filter: PresetRuleEditModel): void {
        var index = this.presetRules.indexOf(filter);
        if (index >= 0) {
            this.presetRules.splice(index, 1);
        }
    }

    protected onEditFilter(filter: PresetRuleEditModel): void {
        this.onRemoveFilter(filter);

        const columnName = filter.customFieldId ?? filter.columnName;
        var column = this.columns.find((c) => c.name === columnName);
        this.presetRuleForm.patchValue(
            {
                id: filter.id,
                column: column,
                comparisonOperator: this.comparisonOperators.find(
                    (co) => co.value === filter.comparisonOperator
                ),
                searchValue:
                    column.fieldType == FieldType.MultiSelect ||
                    column.fieldType == FieldType.SingleSelect
                        ? filter.value.split(",")
                        : filter.value,
            },
            { emitEvent: true }
        );

        if (column) {
            this.autocomplete.options.find((o) => o.value.name === column.name)?.select();
            this.onSelectedColumn(column, true);
        }
    }

    protected onApplyPreset(): void {
        this.fillPresetValues();

        this.dialogRef.close({ preset: this.preset });
    }

    protected onDropColumn(event: CdkDragDrop<PresetColumnModel[]>, dropOnVisible: boolean): void {
        if (dropOnVisible || (!dropOnVisible && this.visibleColumns.length > 1)) {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex
                );
            }

            this.hasColumnsChanges =
                JSON.stringify(this.columns) !== JSON.stringify(this.visibleColumns);
        }
    }

    protected onColumToggle(pc: PresetConfigColumnModel, transferToVisible: boolean): void {
        if (!transferToVisible && this.visibleColumns.length > 1) {
            transferArrayItem(
                this.visibleColumns,
                this.hiddenColumns,
                this.visibleColumns.indexOf(pc),
                this.hiddenColumns.length
            );
        } else if (transferToVisible) {
            transferArrayItem(
                this.hiddenColumns,
                this.visibleColumns,
                this.hiddenColumns.indexOf(pc),
                this.visibleColumns.length
            );
        }

        this.hasColumnsChanges =
            JSON.stringify(this.columns) !== JSON.stringify(this.visibleColumns);
    }

    protected onSavePreset(): void {
        this.validateAllFormFields(this.presetDetailsForm);

        if (this.presetDetailsForm.valid) {
            this.saveDisabled = true;
            this.fillPresetValues();

            if (this.preset.id && !this.preset.predefined) {
                this.presetService
                    .editPreset(this.preset)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe(() => {
                        if (this.initialFavoriteValue && !this.preset.favorite) {
                            this.favoriteRemoveEvent.emit(this.preset as PresetBaseModel);
                        } else if (!this.initialFavoriteValue && this.preset.favorite) {
                            this.favoriteAddEvent.emit(this.preset as PresetBaseModel);
                        }

                        this.dialogRef.close({
                            preset: this.preset,
                            saved: true,
                        });
                    });
            } else {
                var preset = { ...this.preset, id: null, predefined: false };
                this.presetService
                    .addPreset(preset)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((data) => {
                        let presetWithId = { ...preset, id: data };
                        if (presetWithId.favorite) {
                            this.favoriteAddEvent.emit(presetWithId as PresetBaseModel);
                        }

                        this.dialogRef.close({
                            preset: presetWithId,
                            saved: true,
                        });
                    });
            }
        } else {
            this.matTabGroup.selectedIndex = 2;
        }
    }

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            formGroup.get(field)?.markAsTouched({ onlySelf: true });
        });
    }
}
