import { Component, OnInit, Input } from "@angular/core";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { MatDialog } from "@angular/material/dialog";
import { MoiCommentsDialogComponent } from "../moi-comments-dialog/moi-comments-dialog.component";
import { MoiCommentEditorParameters } from "@app/moi/shared/moi-comment-editor-parameters";
import { MoiCommentModel } from "@app/moi/shared/moi-comment-model";
import { MoiCommentType } from "@app/moi/shared/moi-comment-type.enum";
import { MoiCommentEditorConfig } from "@app/moi/shared/moi-comment-editor-config";
import { MoiCommentPermissionModel } from "@models/moi/MoiCommentPermissionModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { toPromise } from "@methods/CommonMethods";

@Component({
    selector: "moi-comments",
    templateUrl: "./moi-comments.component.html",
    styleUrls: ["./moi-comments.component.scss"],
})
export class MoiCommentsComponent implements OnInit {
    @Input() moiGuid: string;
    @Input() permissions: MoiCommentPermissionModel;

    comments: MoiCommentModel[];
    scopedComments: MoiCommentModel[];

    selectedComment: MoiCommentModel;
    showAll = false;

    isLoading = true;

    constructor(
        private moiDataService: MoiDataService,
        private dialog: MatDialog,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit(): void {
        if (!this.moiGuid) {
            return;
        }

        this.loadComments();
    }

    async getProgress(): Promise<number> {
        if (this.comments) {
            const progressComments = this.comments.filter((x) => !x.isDeleted && x.progress);
            return progressComments.length === 0 ? 0 : progressComments[0].progress;
        }

        return toPromise(this.moiDataService.getProgress(this.moiGuid));
    }

    loadComments(): void {
        this.moiDataService.getComments(this.moiGuid).subscribe((value) => {
            this.comments = value;

            this.showAllClick(false);
            this.isLoading = false;
        });
    }

    newCommentDialog(
        commentType: MoiCommentType,
        config: MoiCommentEditorConfig = null
    ): Promise<MoiCommentModel> {
        const comment = new MoiCommentModel();
        comment.commentType = commentType;

        return this.openNewDialog(comment, config);
    }

    newComment(commentType: MoiCommentType) {
        const comment = new MoiCommentModel();
        comment.commentType = commentType;
        comment.canEdit = this.permissions.canAddComment;
        this.editComment(comment);
    }

    editComment(comment: MoiCommentModel) {
        this.openNewDialog(comment).then((comment) => {
            if (comment && comment.canEdit) {
                this.moiDataService.storeComment(this.moiGuid, comment).subscribe((commentGuid) => {
                    this.loadComments();
                });
            }
        });
    }

    async openNewDialog(
        comment: MoiCommentModel,
        config: MoiCommentEditorConfig = null
    ): Promise<MoiCommentModel> {
        const parameters = new MoiCommentEditorParameters();
        parameters.moiGuid = this.moiGuid;

        const clone = <MoiCommentModel>new Object(comment);
        parameters.comment = clone;

        if (!config) {
            config = new MoiCommentEditorConfig();
        }

        let canSetProgress = false;
        switch (comment.commentType) {
            case MoiCommentType.Close:
                canSetProgress = this.permissions.canSetProgressOnClose;
                break;
            case MoiCommentType.Reopen:
                canSetProgress = this.permissions.canSetProgressOnReopen;
                break;
            default:
                canSetProgress = this.permissions.canSetProgress;
                break;
        }

        config.hideProgress = config.hideProgress || !canSetProgress;
        if (!config.hideProgress && !parameters.comment.progress) {
            const progress = await this.getProgress();
            parameters.comment.progress = progress;
        }

        parameters.config = config;

        return toPromise(
            this.dialog
                .open(MoiCommentsDialogComponent, {
                    data: parameters,
                })
                .afterClosed()
        );
    }

    deleteComment(comment: MoiCommentModel) {
        this._promptService
            .confirm("Delete comment", "Are you sure you want to delete this comment?")
            .onConfirm()
            .subscribe(() => {
                this.moiDataService.deleteComment(this.moiGuid, comment.guid).subscribe((_) => {
                    this.loadComments();
                });
            });
    }

    addClick() {
        this.newComment(MoiCommentType.Comment);
    }

    editClick(comment: MoiCommentModel) {
        this.editComment(comment);
    }

    deleteClick(comment: MoiCommentModel) {
        this.deleteComment(comment);
        this.selectedComment = null;
    }

    showAllClick(showAll: boolean) {
        if (showAll) {
            this.scopedComments = this.comments;
        } else {
            this.scopedComments = this.comments.slice(0, 5);
        }

        this.showAll = showAll;
    }
}
