import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { Pages } from "@constants/pages/Pages";
import { TargetModule } from "@enums/document/TargetModule";
import { toPromise } from "@methods/CommonMethods";
import { DocumentMethods } from "@methods/DocumentMethods";
import { DocumentDetailsParameters } from "@models/documents/DocumentDetailsParameters";
import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { TabService } from "@services/tabs/TabService";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Observable, firstValueFrom } from "rxjs";
import {
    SortService,
    FilterService,
    ReorderService,
    ResizeService,
    ColumnMenuService,
    ContextMenuItemModel,
    GridComponent,
    SelectionSettingsModel,
    RecordDoubleClickEventArgs,
    RecordClickEventArgs,
} from "@syncfusion/ej2-angular-grids";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { BaseFileModel, FileType } from "@models/documents/BaseFileModel";
import { DocumentFolderModel } from "@models/documents/DocumentFolderModel";
import { CerrixGridColumnConfig } from "@app/shared/models/cerrix-grid-editor-config";
import { PopupService } from "@app/shared/services/popup/popup.service";
import { DocumentManagerDetailsComponent } from "../document-manager-details/document-manager-details.component";
import { DocumentManagerGenerateComponent } from "../document-manager-generate/document-manager-generate.component";
import { DocumentManagerFolderDetailsComponent } from "./document-manager-folder-details/document-manager-folder-details.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { MenuEventArgs } from "@syncfusion/ej2-angular-navigations";

@Component({
    selector: "document-manager-folder",
    templateUrl: "./document-manager-folder.component.html",
    styleUrls: ["./document-manager-folder.component.scss"],
    providers: [SortService, FilterService, ReorderService, ResizeService, ColumnMenuService],
})
export class DocumentManagerFolderComponent implements OnInit, OnChanges {
    @Input() cerrixTab: TabModel;

    // Actual DocumentBinding
    @Input() Documents: DocumentModel[] = [];
    @Input() Folders: DocumentFolderModel[] = [];
    @Input() ParentGuid: string;
    @Input() permissions: DocumentManagerPermissionModel;
    @Output() DocumentsChange = new EventEmitter<DocumentModel[]>();
    @Output() FoldersChange = new EventEmitter<DocumentFolderModel[]>();

    // Custom component Configuration options
    @Input() Label = "Documents";
    @Input() MaxHeight: string;
    @Input() headerHeight: string;
    @Input() hideLabel = false;
    @Input() hideAddFolderButton = false;
    @Input() hideAddButton = false;
    @Input() hideGenerateButton = true;
    @Input() noMargin = false;
    @Input() disabled = false;
    @Input() compactMode = false;

    // This option hides the entire grid, but still allows the user to add documents using direct references to the component.
    @Input() showUi = true;

    @Input() ShowType = true;
    @Input() AllowAdd = true;
    @Input() AllowEdit = true;
    @Input() AllowDelete = true;
    @Input() ForcedExtensions: string;
    @Input() DocumentTypeTargets: TargetModule | TargetModule[];

    @Input() NoDocumentMessage: string;
    @Input() ExtraFieldColumnConfig: CerrixGridColumnConfig[];

    highlightedGuid: string;
    username: string;
    documentTypes: IdNameCombination[];

    serverAllowedExtensions: string;
    allowedFileExtensions: string;
    maxUploadSizeInMb: number;

    targetedDocument: DocumentModel;

    slideOutTitle: string;
    slideOutContent: TabModel;

    folderData: BaseFileModel[] = [];
    folderPath: Breadcrumb[] = [];
    currentFolder: DocumentFolderModel = null;

    doubleClickTimer: any;
    preventSingleClick: boolean = false;

    FileType = FileType;

    _sortColumnOptions = { columns: [{ field: "Date", direction: "Descending" }] };
    _filterOptions = { type: "Excel" };

    private defaultFolderIcon =
        '{"iconName":"folder","selector":"fas fa-folder","animate":false,"border":false,"color":"#000000"}';

    @ViewChild("ejGrid") ejGridObj: any;
    fileMenuColumnName = "fileMenu";
    selectionColumnName = "selectionColumn";

    public selectionOptions?: SelectionSettingsModel = {
        mode: "Row",
        type: "Multiple",
        checkboxOnly: true,
    };

    private cutRowsId = "cutRows";
    private pasteRowsId = "pasteRows";
    private deleteRowsId = "deleteRows";
    private cutRows: BaseFileModel[] = [];

    contextMenuItems?: ContextMenuItemModel[] = [
        { text: "Cut", target: ".e-content", id: this.cutRowsId },
        { text: "Paste", target: ".e-content", id: this.pasteRowsId },
        { text: "Delete", target: ".e-content", id: this.deleteRowsId },
    ];

    constructor(
        private _userDS: UserService,
        private _documentDS: DocumentDataService,
        private _tabService: TabService,
        private _pages: Pages,
        private _modalService: BsModalService,
        private _standingDataDS: StandingdataDataService,
        private _toastr: ToastrService,
        private _popupService: PopupService,
        private _promptService: CerrixPromptService
    ) {
        this.getSD();
    }

    async ngOnInit() {
        if (
            this.cerrixTab &&
            this.cerrixTab.config &&
            this.cerrixTab.config.selectedDocumentFolderId > 0
        ) {
            let currentFolder = this.Folders.filter(
                (f) => f.ID == this.cerrixTab.config.selectedDocumentFolderId
            ).first();
            this.openFolder(currentFolder);
        }
    }

    async getSD() {
        this.username = await this._userDS.getUserName();
        this.documentTypes = await toPromise(
            this._standingDataDS.getDocumentTypes(this.DocumentTypeTargets)
        );
        const documentConfig = await toPromise(this._documentDS.GetDocumentConfig());

        this.serverAllowedExtensions = documentConfig.allowedFileExtensions;

        this.maxUploadSizeInMb = documentConfig.maxUploadSizeInMb;

        if (this.ForcedExtensions) {
            const forcedList = this.ForcedExtensions.split(",");
            const serverList = this.serverAllowedExtensions.split(",");
            const allowed = forcedList.filter((f) => serverList.indexOf(f) >= 0);

            this.allowedFileExtensions = allowed.join(",");
        } else {
            this.allowedFileExtensions = this.serverAllowedExtensions;
        }
    }

    ngOnChanges() {
        if (!this.permissions) {
            this.permissions = new DocumentManagerPermissionModel();
            this.permissions.canAddDocument = this.AllowAdd;
            this.permissions.canAddFolder = this.AllowAdd;

            this.Documents.forEach((x) => {
                x.CanDelete = this.AllowDelete;
                x.CanEdit = this.AllowEdit;
            });
        }

        if (this.Folders === undefined || this.Folders === null) {
            this.Folders = [];
        }

        this.reloadGrid();
    }

    emitChange() {
        this.DocumentsChange.emit(this.Documents);
        this.FoldersChange.emit(this.Folders);
    }

    openAddFolder(folder?: DocumentFolderModel) {
        if (!folder) {
            folder = new DocumentFolderModel();
            folder.IsNew = true;
            folder.FileIcon = this.defaultFolderIcon;

            if (this.currentFolder) {
                folder.ParentID = this.currentFolder.ID;
                folder.FileIcon = this.currentFolder.FileIcon;
            }
        }

        this.openNewFolderDialog(folder).subscribe((newFolder) => {
            this.applyFolder(newFolder);
        });
    }

    openFolder(folder?: DocumentFolderModel) {
        if (folder.IsCut) {
            return;
        }

        this.currentFolder = folder;
        this.folderPath.push({ Guid: folder.Guid, Name: folder.Name });

        if (
            this.cerrixTab &&
            this.cerrixTab.config &&
            this.currentFolder &&
            this.currentFolder.ID > 0 // Make sure to only keep a reference to existing folders
        ) {
            this.cerrixTab.config.selectedDocumentFolderId = this.currentFolder.ID;
        }

        this.reloadGrid();
    }

    openEdit($event: RecordDoubleClickEventArgs) {
        if (!this.shouldHandleClickEvent($event)) {
            return;
        }

        const folderOrFile = <BaseFileModel>$event.rowData;
        if (folderOrFile.IsCut) {
            return;
        }

        clearTimeout(this.doubleClickTimer);
        this.preventSingleClick = true;

        switch (folderOrFile.FileType) {
            case FileType.Folder:
                this.openAddFolder(folderOrFile as DocumentFolderModel);
                break;
            case FileType.Document:
                this.openEditFile(folderOrFile as DocumentModel);
                break;
            default:
                break;
        }
    }

    openEditFile(doc?: DocumentModel) {
        this.targetedDocument = null;
        if (doc !== undefined && doc !== null) {
            this.targetedDocument = JSON.parse(JSON.stringify(doc)) as DocumentModel;
            this.targetedDocument.File = doc.File;
        }

        this.openNewDialog(this.targetedDocument).subscribe((documents) => {
            this.applyEdit(documents);
        });
    }

    singleClickAction($event: RecordClickEventArgs) {
        if (!this.shouldHandleClickEvent($event)) {
            return;
        }

        const folderOrFile = <BaseFileModel>$event.rowData;

        this.doubleClickTimer = setTimeout(
            function () {
                if (!this.preventSingleClick) {
                    switch (folderOrFile.FileType) {
                        case FileType.Folder:
                            this.openFolder(folderOrFile);
                            break;
                        default:
                            break;
                    }
                }
                this.preventSingleClick = false;
            }.bind(this),
            200
        );
    }

    protected async openGenerate(): Promise<void> {
        const document = await this.openGenerateDialog();
        if (document) {
            this.setFileImg(document);
            this.Documents.unshift(document);
            this.emitChange();

            this._toastr.success("Document generated successfully");
        }
    }

    openEditorInWindow(doc: DocumentModel) {
        const url = "/wopi/" + doc.Guid + "/edit?singleviewinstance=true";
        this._popupService.forceOpenInNewWindow(url);
    }

    openEmbedView(doc: DocumentModel) {
        const extension = doc.Name.split(".").pop();
        this.slideOutContent = this._tabService.getTab(this._pages.Wopi, doc.Guid, doc.Name, {
            documentGuid: doc.Guid,
            documentFileExtension: extension,
            action: "embedview",
        });
        this.slideOutTitle = doc.Name;
    }

    applyFolder(folder: DocumentFolderModel) {
        if (!folder) {
            return;
        }

        if (folder.IsNew && !folder.ID) {
            folder.ID = this.getNewFolderId();

            this.Folders.push(folder);
        } else this.emitChange();

        this.reloadGrid();
    }

    getNewFolderId(): number {
        // If there are no folders, return -1
        if (!this.Folders || this.Folders.length === 0) {
            return -1;
        }

        let smallestId = 0;

        for (const folder of this.Folders) {
            if (folder.ID < smallestId) {
                smallestId = folder.ID;
            }
        }

        // Ensure it is a new unique negative ID
        return smallestId - 1;
    }

    applyEdit(documents: DocumentModel[]) {
        if (!documents) {
            return;
        }

        let allNewFiles = null;
        if (this.currentFolder) {
            // Set correct folder id
            documents.forEach((doc: DocumentModel) => {
                doc.FolderID = this.currentFolder.ID;
            });
        }

        allNewFiles = [...documents, ...this.Documents]
            .filter((doc) => doc.IsNew && doc.File)
            .map((doc) => <File>doc.File);

        if (
            !DocumentMethods.validateUploadLimit(this.maxUploadSizeInMb, this._toastr, allNewFiles)
        ) {
            return;
        }

        for (let docIndex = 0; docIndex < documents.length; docIndex++) {
            const doc = documents[docIndex];

            this.setFileImg(doc);
            if (doc.IsNew) {
                if (this.currentFolder) {
                    const updated = doc.Guid
                        ? this.Documents.filter((x) => x.IsNew && x.Guid === doc.Guid)
                        : [];
                    if (updated.length > 0) {
                        this.updateFile(updated[0], doc);
                    } else {
                        this.Documents.unshift(doc);
                    }
                } else {
                    const updated = doc.Guid
                        ? this.Documents.filter((x) => x.IsNew && x.Guid === doc.Guid)
                        : [];
                    if (updated.length > 0) {
                        this.updateFile(updated[0], doc);
                    } else {
                        this.Documents.unshift(doc);
                    }
                }
            } else {
                doc.Changed = true;

                let updated = null;
                if (this.currentFolder) {
                    updated = this.Documents.filter((x) => x.ID === doc.ID && !x.Deleted);
                } else {
                    updated = this.Documents.filter((x) => x.ID === doc.ID && !x.Deleted);
                }

                if (updated.length > 0) {
                    this.updateFile(updated[0], doc);
                }
            }
        }

        this.emitChange();

        this.reloadGrid();
    }

    updateFolder(oldFolder: DocumentFolderModel, newFolder: DocumentFolderModel) {
        if (this.currentFolder) {
            const replaceIndex = this.Folders.indexOf(oldFolder);
            this.Folders.splice(replaceIndex, 1, newFolder);
        } else {
            const replaceIndex = this.Folders.indexOf(oldFolder);
            this.Folders.splice(replaceIndex, 1, newFolder);
        }
    }

    updateFile(oldFile: DocumentModel, newFile: DocumentModel) {
        if (this.currentFolder) {
            const replaceIndex = this.Documents.indexOf(oldFile);
            this.Documents.splice(replaceIndex, 1, newFile);
        } else {
            const replaceIndex = this.Documents.indexOf(oldFile);
            this.Documents.splice(replaceIndex, 1, newFile);
        }
    }

    async toggleDelete(data: BaseFileModel) {
        await this.handleDelete([data]);
    }

    private async handleDelete(fileModels: BaseFileModel[]) {
        // If multiple items are selected, we should either put all of them to deleted or restore all.
        // If there's any undeleted item in here, delete all of them. If they are all deleted already, restore them all
        const deleteItems = fileModels.some((fm) => !fm.Deleted);

        if (deleteItems && fileModels.some((fm) => fm.FileType == FileType.Folder)) {
            const result = await this.confirmDeleteFolder();
            if (!result) {
                return;
            }
        }

        fileModels.forEach((data) => {
            const guid = data.Guid;
            if (data.FileType == FileType.Document) {
                const doc = this.Documents.find((d) => d.Guid == guid);
                if (!doc || !doc.CanDelete) {
                    return;
                }

                if (doc.IsNew) {
                    const newIndex = this.Documents.indexOf(doc);
                    this.Documents.splice(newIndex, 1);
                } else {
                    doc.Deleted = deleteItems;
                }
            } else {
                const folder = this.Folders.find((d) => d.Guid == guid);
                if (!folder || !folder.CanDelete) {
                    return;
                }

                if (folder.IsNew) {
                    const newIndex = this.Folders.indexOf(folder);
                    this.Folders.splice(newIndex, 1);
                } else {
                    const isDeleted = deleteItems;

                    this.markAllFilesAndSubfoldersDeleted(folder, isDeleted);
                }
            }

            this.highlightedGuid = null;
            this.emitChange();

            this.reloadGrid();
        });
    }

    private markAllFilesAndSubfoldersDeleted(folder: DocumentFolderModel, isDeleted: boolean) {
        folder.Deleted = isDeleted;

        const documentsForFolder = this.getDocumentsForFolder(folder.ID);
        documentsForFolder.forEach((doc) => {
            doc.Deleted = isDeleted;
        });

        const foldersForFolder = this.getFoldersForFolder(folder.ID);
        foldersForFolder.forEach((fld) => {
            fld.Deleted = isDeleted;

            this.markAllFilesAndSubfoldersDeleted(fld, isDeleted);
        });
    }

    setFileImg(doc: DocumentModel) {
        const fileNameSplit = doc && doc.Name ? doc.Name.toLowerCase().split(".") : [];
        const fileExt =
            fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1].trim() : "";

        switch (fileExt) {
            // Word
            case "doc":
            case "docm":
            case "docx":
            case "dot":
            case "dotm":
            case "dotx":
            case "odt": {
                doc.msAppIcon = "ms-word";
                doc.msFileIcon = `ms-${fileExt}`;
                break;
            }

            // Powerpoint
            case "odp":
            case "pot":
            case "potm":
            case "potx":
            case "pps":
            case "ppsm":
            case "ppsx":
            case "ppt":
            case "pptm":
            case "pptx": {
                doc.msAppIcon = "ms-powerpoint";
                doc.msFileIcon = `ms-${fileExt}`;
                break;
            }

            // Excel
            case "csv":
            case "ods":
            case "xls":
            case "xlsm":
            case "xlsx": {
                doc.msAppIcon = "ms-excel";
                doc.msFileIcon = `ms-${fileExt}`;
                break;
            }

            // Others
            case "pdf": {
                doc.FileIcon = "file-pdf";
                break;
            }
            case "jpg":
            case "png":
            case "jpeg": {
                doc.FileIcon = "file-image";
                break;
            }
            case "txt":
            case "msg": {
                doc.FileIcon = "file-alt";
                break;
            }
            case "xml": {
                doc.FileIcon = "file-excel";
                break;
            }
            case "zip": {
                doc.FileIcon = "file-archive";
                break;
            }
            default: {
                doc.FileIcon = "file";
                break;
            }
        }
    }

    fileDropHandler(files: File[]) {
        if (this.AllowAdd) {
            const droppedDocuments = DocumentMethods.convertFileListToDocumentModel(
                files,
                this.username
            );

            this.applyEdit(droppedDocuments);
        }
    }

    openDocumentViewer(document: DocumentModel) {
        const url = "/api/documents/" + document.Guid + "/pdf";
        this._popupService.forceOpenInNewWindow(url);
    }

    private openNewFolderDialog(folder: DocumentFolderModel): Observable<DocumentFolderModel> {
        const clone = <DocumentFolderModel>new Object(folder);

        const config = <ModalOptions<DocumentManagerFolderDetailsComponent>>{
            backdrop: "static",
            class: "documents-dialog",

            initialState: <DocumentManagerFolderDetailsComponent>{
                Folder: clone,
                ExistingFolders: this.Folders,
            },
        };

        return this._modalService.show<DocumentManagerFolderDetailsComponent>(
            DocumentManagerFolderDetailsComponent,
            config
        ).content.afterClosed;
    }

    private openNewDialog(doc: DocumentModel): Observable<DocumentModel[]> {
        const parameters = <DocumentDetailsParameters>{
            permissions: this.permissions,
            allowedFileExtensions: this.allowedFileExtensions,
            documentTypes: this.documentTypes,
            showType: this.ShowType,
            userName: this.username,
            disabled: this.disabled,

            maxUploadSizeInMb: this.maxUploadSizeInMb,
            filesToUpload: this.Documents.filter((x) => x.IsNew).map((x) => x.File),
        };

        const clone = <DocumentModel>new Object(doc);
        parameters.document = clone;

        const config = <ModalOptions<DocumentManagerDetailsComponent>>{
            backdrop: "static",
            class: "documents-dialog",

            initialState: <DocumentManagerDetailsComponent>{
                parameters,
            },
        };

        return this._modalService.show<DocumentManagerDetailsComponent>(
            DocumentManagerDetailsComponent,
            config
        ).content.afterClosed;
    }

    private async openGenerateDialog(): Promise<DocumentModel> {
        if (
            !this.ParentGuid ||
            !this.DocumentTypeTargets ||
            Array.isArray(this.DocumentTypeTargets)
        ) {
            return;
        }
        const config = <ModalOptions<DocumentManagerGenerateComponent>>{
            initialState: <DocumentManagerGenerateComponent>{
                parameters: {
                    module: this.DocumentTypeTargets,
                    parentGuid: this.ParentGuid,
                },
            },
        };
        const modal = this._modalService.show<DocumentManagerGenerateComponent>(
            DocumentManagerGenerateComponent,
            config
        );
        return (await firstValueFrom(modal.content.afterClosed)) as Promise<DocumentModel>;
    }

    //#region Grid Events

    reloadGrid() {
        this.Documents.forEach((x) => {
            this.setFileImg(x);
            x.FileType = FileType.Document;
        });
        this.Folders.forEach((x) => {
            x.FileType = FileType.Folder;
        });

        const folderIdForFilter = this.currentFolder ? this.currentFolder.ID : null;

        const documentsForFolder = this.getDocumentsForFolder(folderIdForFilter);
        const foldersForFolder = this.getFoldersForFolder(folderIdForFilter);

        this.folderData = [...foldersForFolder, ...documentsForFolder];

        if (this.ejGridObj) {
            // Need a small timeout here because sometimes the folderData won't change in time for ejsGrid
            setTimeout(() => this.ejGridObj.refresh(), 100);
        }
    }

    private getDocumentsForFolder(folderId?: number): DocumentModel[] {
        if (!this.Documents) {
            return [];
        }

        const documentsForFolder = this.Documents.filter((d) => d.FolderID == folderId);

        return documentsForFolder;
    }

    private getFoldersForFolder(folderId?: number): DocumentFolderModel[] {
        if (!this.Folders) {
            return [];
        }

        const foldersForFolder = this.Folders.filter((d) => d.ParentID == folderId);

        return foldersForFolder;
    }

    private async confirmDeleteFolder() {
        return await firstValueFrom(
            this._promptService
                .confirmCustom({
                    maxHeight: "300px",
                    maxWidth: "350px",
                    data: {
                        title: "Delete folder(s)",
                        message:
                            "This will delete the selected folder(s) including content, are you sure?",
                    },
                })
                .getResult()
        );
    }

    navigateBreadcrumb(crumb?: Breadcrumb): void {
        if (crumb) {
            if (this.folderPath.includes(crumb)) {
                this.folderPath.splice(this.folderPath.indexOf(crumb) + 1);
                const targetedFolder = this.Folders.filter((folder) => folder.Guid == crumb.Guid);
                if (targetedFolder.length > 0) {
                    this.currentFolder = targetedFolder[0];
                }

                this.reloadGrid();
            }
        } else {
            this.folderPath = [];
            this.currentFolder = null;

            if (this.cerrixTab && this.cerrixTab.config) {
                this.cerrixTab.config.selectedDocumentFolderId = null;
            }

            this.reloadGrid();
        }
    }

    async contextMenuClick(args: MenuEventArgs): Promise<void> {
        const itemId = args.item.id;
        switch (itemId) {
            case this.cutRowsId:
                {
                    this.cutSelectedRows();
                }
                break;
            case this.pasteRowsId:
                {
                    this.pasteSelectedRows(args);
                }
                break;
            case this.deleteRowsId:
                {
                    await this.deleteSelectedRows();
                }
                break;
        }
    }

    private cutSelectedRows() {
        this.cutRows = [];

        const selectedRowIndexes = (this.ejGridObj as GridComponent).getSelectedRowIndexes();

        selectedRowIndexes.forEach((rowIndex) => {
            const selectedRow = this.folderData[rowIndex];
            selectedRow.IsCut = true;

            this.cutRows.push(selectedRow);
        });

        this.reloadGrid();
    }

    private pasteSelectedRows(args: any) {
        if (this.cutRows.length === 0) {
            return;
        }

        let targetFolder: DocumentFolderModel;
        if (args && args.rowInfo && args.rowInfo.rowData) {
            const fileOrFolder = <BaseFileModel>args.rowInfo.rowData;
            const isPasteOnFolder = fileOrFolder.FileType == FileType.Folder;
            if (isPasteOnFolder) {
                targetFolder = fileOrFolder as DocumentFolderModel;
            }
        }

        if (targetFolder == null) {
            targetFolder = this.currentFolder;
        }

        if (!this.validatePaste(targetFolder)) {
            return;
        }

        const parentId = targetFolder ? targetFolder.ID : null;
        this.cutRows.forEach((element: BaseFileModel) => {
            element.IsCut = false;
            element.Changed = true;

            if (element.FileType == FileType.Document) {
                (element as DocumentModel).FolderID = parentId;
            } else if (element.FileType == FileType.Folder) {
                (element as DocumentFolderModel).ParentID = parentId;
            }
        });

        this.cutRows = [];

        this.reloadGrid();
    }

    private async deleteSelectedRows() {
        const selectedRowIndexes = (this.ejGridObj as GridComponent).getSelectedRowIndexes();
        const selectedRows = selectedRowIndexes.map((rowIndex) => this.folderData[rowIndex]);
        await this.handleDelete(selectedRows);
    }

    private validatePaste(folder: DocumentFolderModel): boolean {
        const parentId = folder ? folder.ID : null;
        const cutFolders = this.cutRows.filter((row) => row.FileType === FileType.Folder);
        const cutFiles = this.cutRows.filter((row) => row.FileType === FileType.Document);

        const notMoved =
            cutFolders.some(
                (f) =>
                    (<DocumentFolderModel>f).ParentID == parentId ||
                    (<DocumentFolderModel>f).ID == parentId
            ) || cutFiles.some((f) => (<DocumentModel>f).FolderID == parentId);

        if (notMoved) {
            // Undo the selection
            this.cutRows.forEach((element: BaseFileModel) => {
                element.IsCut = false;
            });

            this.cutRows = [];
            this.reloadGrid();

            return false;
        }

        // Create a Set of trimmed and lowercased folder names in the current folder
        const currentFolderNamesSet = new Set(
            this.Folders.filter((folder) => folder.ParentID === parentId).map((folder) =>
                folder.Name.trim().toLowerCase()
            )
        );

        // Check if any cut folder name exists in the current folder names Set
        const isValid = !cutFolders
            .map((folder) => folder.Name.trim().toLowerCase())
            .some((name) => currentFolderNamesSet.has(name));

        if (!isValid) {
            this._toastr.warning(
                "The current folder already contains a folder with the same name."
            );

            return false;
        }

        return true;
    }

    private shouldHandleClickEvent($event: any): boolean {
        const skippedColumnNames = [this.fileMenuColumnName, this.selectionColumnName];
        if ($event && $event.column && skippedColumnNames.indexOf($event.column.uid) > -1) {
            // Skip event handling
            return false;
        }

        return true;
    }

    //#endregion Grid Events
}

class Breadcrumb {
    Guid: string;
    Name: string;
}
