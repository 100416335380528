import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "@app/app.constants";
import { ControlModel } from "../../models/ControlModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { ControlValidationModel } from "./ControlValidationModel";
import { ControlStandingData } from "./ControlStandingData";
import { Observable } from "rxjs";
import { ControlCatalogueModel } from "./ControlCatalogueModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ControlStandingDataOrganizationBound } from "./ControlStandingDataOrganizationBound";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";

@Injectable()
export class ControlDetailMainDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    public getStandingData(guid?: string): Observable<ControlStandingData> {
        const url = guid && guid.length > 0 ? `/${guid}/standingdata` : "/standingdata";
        return this.get<ControlStandingData>(url);
    }

    public getOrganizationStandingData(
        id: number
    ): Observable<ControlStandingDataOrganizationBound> {
        const url = `/standingdata/organization/${id}`;
        return this.get<ControlStandingDataOrganizationBound>(url);
    }

    public getFrameworkDimensionStandingData(model: ControlModel): Observable<CerrixTreeItem[]> {
        const url = `/standingdata/frameworkdimensions`;
        return this.post<CerrixTreeItem[]>(model, url);
    }

    public getControlCatalogue(ccId: number): Observable<ControlCatalogueModel> {
        const url = `/controlcatalogue/${ccId}`;
        return this.get<ControlCatalogueModel>(url);
    }

    public validateOrganizationChange(model: ControlModel): Observable<ControlValidationModel> {
        const url = `/validation/organizationchange`;
        return this.post<ControlValidationModel>(model, url);
    }

    public validateBusinessDimensionChange(
        model: ControlModel
    ): Observable<ControlValidationModel> {
        const url = `/validation/businessdimensionchange`;
        return this.post<ControlValidationModel>(model, url);
    }

    public getCustomFields(): Observable<CustomFieldStandingDataModel[]> {
        const url = `/customfields`;
        return this.get<CustomFieldStandingDataModel[]>(url);
    }

    public generateDescription(control: ControlModel): Observable<string[]> {
        const url = `/generate/description`;
        return this.post<string[]>(control, url);
    }
}
