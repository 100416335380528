import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "kri-post-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class KriPostDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Upload KRI/KPI Data",
            httpMethod: "POST",
            path: "/kri/data/store/{identifier}",

            parameters: [
                {
                    name: "Identifier",
                    description: "Identifier of the Kri the data should be attached to.",
                    in: "path",
                    required: true,
                    example: "00000001",
                },
                {
                    name: "value",
                    in: "query",
                    paramType: "number",
                    required: true,
                    example: 6.2,
                },
                {
                    name: "organization",
                    in: "query",
                    paramType: "string",
                    required: true,
                    example: "CERRIX/Organization_A",
                },
                {
                    name: "dateTime",
                    in: "query",
                    paramType: "date",
                    required: false,
                    example: "21-07-2021",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                },
            ],
        };
    }
}
