<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    [module]="'audit'"
    [moduleType]="moduleType"
    [moduleSubType]="moduleSubType"
    [appendToModuleType]="appendToModuleType"
    [idProp]="'guid'"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [buttonConfig]="buttonConfig"
    [NumberProperties]="numberProps"
    (onDblClick)="openDetail($event)"
    (onAddClick)="add()"
>
</cerrix-workspace>
