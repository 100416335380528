import { Component, Input } from "@angular/core";
import { LinkModel } from "@models/generic/LinkModel";
import { RiskDataService } from '@app/risk/services/RiskDataService';
import { RiskModel } from '@app/risk/models/RiskModel';

@Component({
    selector: 'risk-datamanagement',
    templateUrl: './risk-datamanagement.component.html',
    styleUrls: ['./risk-datamanagement.component.scss']
})
export class RiskDatamanagementComponent {

    @Input() risk: RiskModel;

    linked: LinkModel[];
    isLoading = true;

    constructor(private _riskDS: RiskDataService) { }

    load(refresh: boolean) {
        if (this.linked && !refresh) {
            return;
        }

        this.isLoading = true;
        this._riskDS.getLinkedDataProcessings(this.risk.guid).subscribe(data => {
            this.linked = data;

            this.isLoading = false;
        });
    }
}
