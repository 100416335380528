import { Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[excelColumnNameInput]",
})
export class ExcelColumnNameInputDirective {
    constructor(el: ElementRef) {
        const nativeElement = el.nativeElement as HTMLInputElement;

        nativeElement.maxLength = 3;
        nativeElement.placeholder = "A - XFD";
        nativeElement.title = "Column name between 'A' and 'XFD'.";
        nativeElement.classList.add("text-uppercase");
    }
}
