import { Component, Input } from "@angular/core";

@Component({
    selector: "author-message-box",
    templateUrl: "./author-message-box.component.html",
    styleUrls: ["./author-message-box.component.scss"],
})
export class AuthorMessageBoxComponent {
    @Input() author: string;
    @Input() authorLabel: string;
    @Input() message: string;

    initials: string;

    ngOnInit() {
        this.initials = this.getInitials(this.author);
    }

    getInitials(name) {
        if (!name) {
            return;
        }
        var names = name.split(", ");
        if (names.length == 0) {
            return;
        }

        var initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
}
