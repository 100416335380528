<div class="detail-page-card">
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <form *ngIf="systemModel">
            <div class="row">
                <div class="col-6">
                    <div *ngIf="systemModel.hidden" class="name">
                        No system
                    </div>
                    <div *ngIf="!systemModel.hidden">
                        <button
                            *ngIf="!disabled"
                            class="btn btn-raised red pull-right"
                            (click)="deleteSystem()"
                        >
                            <i class="fas fa-minus-circle"></i>
                        </button>
                        <div class="name">
                            {{ systemModel.name }}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="btn-toolbar">
                        <button
                            *ngIf="!disabled"
                            class="btn btn-raised pull-right"
                            (click)="manageData()"
                        >
                            <i class="fas fa-plus-circle"></i>
                            <span>Manage data</span>
                        </button>
                    </div>
                </div>
            </div>
            <hr />
            <div class="fancy-loader submodule" *ngIf="!systemModel.dataSubjectsLoaded"></div>
            <datasubjects-overview
                *ngIf="systemModel.dataSubjectsLoaded"
                [(dataSubjects)]="systemModel.dataSubjects"
                [disabled]="disabled"
            >
            </datasubjects-overview>
        </form>
    </div>
</div>
