import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DocumentTemplatesDataService } from "@app/document-templates/document-templates-data.service";
import { DocumentTemplatesModuleModel } from "@app/document-templates/models/document-templates-module-model";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { FormValidationMessageHelper } from "@app/shared/helpers/form-validation-message-helper";
import { FormValidators } from "@app/shared/helpers/form-validators";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { DocumentManagerGenerateParameters } from "./DocumentManagerGenerateParameters";
import { DocumentGenerationParams } from "./DocumentGenerationParams";
import { firstValueFrom, forkJoin } from "rxjs";
import { KeyValuePair } from "@models/generic/KeyValuePair";

/**
 * Template for document generation modal in document manager.
 */
@Component({
    selector: "app-document-manager-generate",
    templateUrl: "./document-manager-generate.component.html",
    styleUrls: ["./document-manager-generate.component.scss"],
})
export class DocumentManagerGenerateComponent extends BaseModal implements OnInit {
    // Modal input parameters
    public parameters: DocumentManagerGenerateParameters;

    // Parameters for generation call
    private generationParameters: DocumentGenerationParams;

    // Data for selects
    protected documentTemplatesModel: DocumentTemplatesModuleModel;
    protected supportedFileTypes: KeyValuePair[];

    protected get finishedLoading(): boolean {
        return !!this.documentTemplatesModel && !!this.formGroup;
    }

    protected formGroup: FormGroup;

    private previousValidationToastId?: number;

    constructor(
        bsModalRef: BsModalRef,
        private templateService: DocumentTemplatesDataService,
        private promptService: CerrixPromptService,
        private toastService: ToastrService
    ) {
        super(bsModalRef);
    }

    async ngOnInit(): Promise<void> {
        const module = this.parameters.module;

        const result = await firstValueFrom(
            forkJoin({
                documentTemplates: this.templateService.getDocumentTemplate(module),
                outputTypes: this.templateService.getOutputTypes(),
            })
        );
        this.documentTemplatesModel = result.documentTemplates;
        this.supportedFileTypes = result.outputTypes;

        this.generationParameters = {
            module: this.parameters.module,
            sourceGuid: this.parameters.parentGuid,
            documentType: this.supportedFileTypes[0].key,
        };

        this.createFormGroup();
    }

    protected async generate(): Promise<void> {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();

            const validationMessage = new FormValidationMessageHelper<
                Partial<DocumentGenerationParams>
            >({
                templateGuid: "Template",
                documentName: "Document name",
                documentType: "Document type",
            })
                .processFormGroup(this.formGroup)
                .getErrorMessage();

            const validationToast = this.toastService.warning(validationMessage, "Validation", {
                enableHtml: true,
            });

            if (this.previousValidationToastId) {
                this.toastService.remove(this.previousValidationToastId);
            }
            this.previousValidationToastId = validationToast.toastId;

            return;
        }

        this.generationParameters = Object.assign(this.generationParameters, this.formGroup.value);

        const savingPrompt = this.promptService.loader("Generating document, please wait...");

        try {
            const document = await firstValueFrom(
                this.templateService.generateDocument(this.generationParameters)
            );

            this.onConfirm(document);
        } finally {
            savingPrompt.close();
        }
    }

    protected close(): void {
        super.onCancel();
    }

    private createFormGroup(): void {
        this.formGroup = new FormGroup({
            templateGuid: new FormControl(
                this.generationParameters.templateGuid,
                Validators.required
            ),
            documentName: new FormControl(this.generationParameters.documentName, [
                Validators.required,
                FormValidators.validFileName,
            ]),
            documentType: new FormControl(
                this.generationParameters.documentType,
                Validators.required
            ),
        });
    }
}
