// Content styling
.cerrix-slide-over {
    .slide-over {
        font-size: 16px;

        .paragraph {
            .header {
                font-size: 18px;
                font-weight: bold;
            }

            .content {
                margin-top: 20px;

                .subheader {
                    font-weight: bold;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }

            &:not(:only-child) {
                padding-bottom: 20px;
            }

            &:not(:first-child) {
                padding-top: 20px;
                border-top: 1px solid #d7dce0;
            }
        }

        .title-area {
            background-color: var(--accent-color);
            color: white;
            padding: 45px;
            padding-right: 60px;
        }

        .content-area {
            padding: 45px;
            padding-right: 60px;

            display: flex;
            flex-flow: column;
        }
    }
}

// Component styling
.cerrix-slide-over {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease-in-out;

    .slide-over {
        min-width: 500px;
        width: 25vw;
        height: 100%;
        background: #fff;

        overflow-y: auto;
        overflow-x: hidden;

        animation-name: slide-in;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;

        .close-button {
            position: absolute;
            top: 20px;
            right: 30px;

            cursor: pointer;
            font-size: 30px;
            color: white;
        }

        &.slide-out {
            animation-name: slide-out;
        }
    }

    @keyframes slide-in {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slide-out {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100%);
        }
    }
}
