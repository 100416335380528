import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { Observable } from "rxjs";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { EventDataService } from "..";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "event-linkable",
    templateUrl: "./event-linkable.component.html",
    styleUrls: ["./event-linkable.component.scss"],
})
export class EventLinkableComponent extends ItemLinkableBase {
    constructor(
        protected _toastr: ToastrService,
        protected _promptService: CerrixPromptService,
        private _eventDS: EventDataService,
        private _pages: Pages,
        private _tabService: TabService
    ) {
        super(_toastr, _promptService);
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._eventDS.hasAccessToEvent(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.EventDetail,
                item.guid,
                `(E) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this Event.");
        }
    }
}
