<div class="detail-page-card" [formGroup]="mainFormGroup">
    <div class="detail-page-card-title">Details</div>
    <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
        <div class="row">
            <div class="col-12 col-xl-6 left-block">
                <div class="form-group">
                    <cerrix-textfield
                        fieldLabel="Name"
                        dataUiid="kridetailmain-name"
                        identifier="nameInput"
                        formControlName="name"
                        placeholder="Required"
                    ></cerrix-textfield>
                </div>

                <hr />

                <div class="form-group">
                    <label for="orgTree">Organizations</label>
                    <cerrix-select-tree
                        data-uiid="kridetailmain-organizations"
                        name="Organizations"
                        [tree]="organizations"
                        formControlName="organizationIds"
                        placeholder="Required"
                        (onChangeIds)="organizationsChanged()"
                        [multiselect]="true"
                    >
                    </cerrix-select-tree>
                </div>
                <div class="form-group">
                    <label for="bdTree"
                        >Business dimensions
                        <i
                            *ngIf="!organizationIds || organizationIds.length === 0"
                            class="far fa-question-circle ms-2"
                            [matTooltip]="'Please select an organization first.'"
                        >
                        </i>
                    </label>
                    <cerrix-select-tree
                        data-uiid="kridetailmain-businessdimensions"
                        [tree]="businessDimensions"
                        formControlName="businessDimensionIds"
                        [readonly]="!organizationIds || organizationIds.length === 0"
                        [multiselect]="true"
                    >
                    </cerrix-select-tree>
                </div>
                <div class="form-group">
                    <label for="riskCatSelect"
                        >Risk catalogues
                        <i
                            *ngIf="!organizationIds || organizationIds.length === 0"
                            class="far fa-question-circle ms-2"
                            [matTooltip]="'Please select an organization first.'"
                        >
                        </i>
                    </label>
                    <ng-select
                        data-uiid="kridetailmain-riskcatalogue"
                        name="riskCatSelect"
                        [items]="riskCatalogues | async"
                        [loading]="riskCataloguesLoading"
                        formControlName="riskCatalogueIds"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        bindValue="Key"
                        bindLabel="Value"
                        placeholder="Select Risk Catalogues"
                    >
                    </ng-select>
                </div>

                <hr />

                <div class="form-group">
                    <cerrix-textfield
                        fieldLabel="Data owner"
                        dataUiid="kridetailmain-dataowner"
                        identifier="dataOwnerInput"
                        formControlName="dataOwner"
                    ></cerrix-textfield>
                </div>
                <div class="form-group">
                    <cerrix-textfield
                        fieldLabel="Source"
                        dataUiid="kridetailmain-source"
                        identifier="sourceInput"
                        formControlName="source"
                    ></cerrix-textfield>
                </div>

                <hr />

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="freqSelect">Frequency</label>
                            <ng-select
                                data-uiid="kridetailmain-frequency"
                                id="freqSelect"
                                name="freqSelect"
                                [items]="frequencies | async"
                                [loading]="frequenciesLoading"
                                formControlName="frequency"
                                bindValue="Key"
                                bindLabel="Value"
                                placeholder="Required"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="aggregateSelect">Aggregate</label>
                            <ng-select
                                data-uiid="kridetailmain-aggregate"
                                id="aggregateSelect"
                                name="aggregateSelect"
                                [items]="aggregations | async"
                                [loading]="aggregationsLoading"
                                formControlName="aggregate"
                                bindValue="Key"
                                bindLabel="Value"
                                placeholder="Required"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="measurementSelect">Measurement</label>
                            <ng-select
                                data-uiid="kridetailmain-measurement"
                                id="measurementSelect"
                                name="measurementSelect"
                                [items]="measurements | async"
                                [loading]="measurementsLoading"
                                formControlName="measurement"
                                bindValue="Key"
                                bindLabel="Value"
                                placeholder="Required"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="graphTypeSelect">Graph type</label>
                            <ng-select
                                data-uiid="kridetailmain-graphtype"
                                id="graphTypeSelect"
                                name="graphTypeSelect"
                                [items]="graphTypes | async"
                                [loading]="graphTypesLoading"
                                formControlName="graphType"
                                bindValue="Key"
                                bindLabel="Value"
                                placeholder="Required"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="greenThresholdInput">Green threshold</label>
                            <input
                                data-uiid="kridetailmain-greenthreshold"
                                type="number"
                                class="form-control form-control-sm"
                                id="greenThresholdInput"
                                name="greenThresholdInput"
                                formControlName="greenThreshold"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="redThresholdInput">Red threshold</label>
                            <input
                                data-uiid="kridetailmain-redthreshold"
                                type="number"
                                class="form-control form-control-sm"
                                id="redThresholdInput"
                                name="sredThresholdInputourceInput"
                                formControlName="redThreshold"
                            />
                        </div>
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col">
                        <cerrix-checkbox
                            data-uiid="kridetailmain-iskpi"
                            formControlName="isKpi"
                            label="Is KPI"
                            fontsize="0.9em"
                        >
                        </cerrix-checkbox>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-6 right-block">
                <div class="form-group">
                    <cerrix-textarea
                        fieldLabel="Description"
                        dataUiid="kridetailmain-description"
                        identifier="descriptionInput"
                        [rows]="9"
                        formControlName="description"
                    ></cerrix-textarea>
                </div>
                <hr />
                <div class="form-group">
                    <cerrix-textarea
                        fieldLabel="Calculation"
                        dataUiid="kridetailmain-calculation"
                        identifier="calculationInput"
                        [rows]="9"
                        formControlName="calculation"
                    ></cerrix-textarea>
                </div>
                <hr />
                <div class="form-group">
                    <cerrix-textarea
                        fieldLabel="Comments"
                        dataUiid="kridetailmain-comments"
                        identifier="commentInput"
                        [rows]="9"
                        formControlName="comments"
                    ></cerrix-textarea>
                </div>
            </div>
        </div>
    </div>
</div>
