import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DocumentModel } from "@models/documents/documentmodel";

@Component({
    selector: "eff-document-details",
    templateUrl: "./eff-document-details.component.html",
    styleUrls: ["./eff-document-details.component.scss"],
})
export class EffDocumentDetailsComponent {
    @Input() document: DocumentModel;
}
