import { Component, ElementRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KeyCode } from "@enums/KeyCode.enum";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { GenericManagerHelper } from "../helpers/generic-manager-helper";
import { GenericListFieldType } from "../models/GenericList/GenericListField";
import { CerrixPromptService } from "../services/cerrix-prompt.service";
import { ICerrixPrompt } from "./models/ICerrixPrompt";

@Component({
    selector: "cerrix-prompt",
    templateUrl: "./cerrix-prompt.component.html",
    styleUrls: ["./cerrix-prompt.component.scss"],
})
export class CerrixPromptComponent {
    public readonly ngSelectHolderCls = CerrixPromptService.ngSelectHolderCls;
    private readonly _promptResult = new Subject<boolean | any>();
    private readonly _onConfirmResult = new Subject();
    private readonly _promptUiid = "cerrix-prompt";

    constructor(
        public dialogRef: MatDialogRef<CerrixPromptComponent>,
        public toastr: ToastrService,
        private _elementRef: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data: ICerrixPrompt
    ) {
        this.setupFields();
        this.bindKeyListers();
    }

    getResult() {
        return this._promptResult.asObservable();
    }

    toPromise() {
        return this.getResult().toPromise();
    }

    onConfirm() {
        return this._onConfirmResult.asObservable();
    }

    cancelClick() {
        this._promptResult.next(false);
        this._promptResult.complete();
        this.close();
    }

    confirmClick() {
        if (!this.isValid()) {
            return;
        }

        if (!this.ShouldPopupClose())
        {
            return;
        }

        const resultToEmit =
            this.data.fields && this.data.fields.any() ? this.data.fieldResult : true;

        this._promptResult.next(resultToEmit);
        this._promptResult.complete();

        if (resultToEmit === true) {
            this._onConfirmResult.next(null);
            this._onConfirmResult.complete();
        }

        this.close();
    }

    close() {
        this.dialogRef.close();
    }

    private setupFields() {
        if (this.data.fields && this.data.fields.any()) {
            this.data.fields.forEach((f) => {
                f.isReadonly = f.isReadonly === true;
                f.formattedName = f.prettyName ? f.prettyName : f.fieldName;
                f.formattedName += f.required ? " *" : "";

                if (
                    f.fieldType == GenericListFieldType.SingleSelect ||
                    f.fieldType == GenericListFieldType.MultiSelect ||
                    f.fieldType == GenericListFieldType.ColoredSelect ||
                    f.fieldType == GenericListFieldType.StringArray
                ) {
                    f.appendTo = CerrixPromptService.ngSelectHolderSelector;
                }
            });

            if (!this.data.fieldResult) {
                this.data.fieldResult = {};
            }
        }

        if (!this.data.promptUiid) {
            this.data.promptUiid = this._promptUiid;
        }
    }

    private bindKeyListers() {
        const bindKeyListeners = this.data.closeOnEsc || this.data.confirmOnEnter;

        if (bindKeyListeners) {
            this.dialogRef.keydownEvents().subscribe((event) => {
                if (this.data.closeOnEsc && event.keyCode === KeyCode.Esc) {
                    this.cancelClick();
                } else if (this.data.confirmOnEnter && event.keyCode === KeyCode.Enter) {
                    this.confirmClick();
                }
            });
        }
    }

    private ShouldPopupClose(){
        if(this.data.confirmButton.action && typeof this.data.confirmButton.action() === 'boolean'){
            return this.data.confirmButton.action();
        }
        else{
            return true;
        }
        
    }

    private isValid(): boolean {
        if (this.data.fields && this.data.fieldResult) {
            GenericManagerHelper.markAllFieldsAsTouched(this._elementRef);

            const validations = GenericManagerHelper.validateGenericFields(
                this.data.fieldResult,
                this.data.fields,
                true
            );

            if (validations.length != 0) {
                const validationMsg = validations.map((validation) => "- " + validation).join("\n");
                this.toastr.warning(validationMsg, "Save failed.");

                return false;
            }
        }

        return true;
    }
}
