import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormInviteType } from "@app/forms/enums/FormInviteType";
import { Form } from "@app/forms/models";
import { FormResultStatus } from "@app/forms/models/result/FormResultStatus";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { FormPermissionModel } from "@models/permissions/FormPermissionModel";
import { TabService } from "@services/tabs/TabService";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "form-status",
    templateUrl: "./form-status.component.html",
    styleUrls: ["./form-status.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FormStatusComponent implements OnInit {
    @Input() categories: IdNameCombination[];
    @Input() permissions: FormPermissionModel;

    formCategories: { category: string; forms: Form[]; hidden: boolean }[];
    filter = "";

    constructor(
        private _formDS: FormsDataService,
        private pages: Pages,
        private tabService: TabService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this.loadOverview();
    }

    loadOverview() {
        this.formCategories = null;

        this._formDS
            .getStatusOverview()
            .toPromise()
            .then((frms) => {
                this.setupConfigs(frms);
                this.setupFormCategories(frms);
            });
    }

    setupConfigs(forms: Form[]) {
        forms.forEach((form) => {
            form._genericConfig = <GenericListConfig>{
                idProp: "ID",
                data: form.ResultStatus.map((rs) => Object.assign(rs, { title: form.Title })),
                limitViewTo: 5,

                name: form.Title,
                hideHeader: true,
                isSortable: true,

                emptyMessage: "No invitees yet.",
                maxHeight: "300px",

                overviewRowActions: [
                    {
                        icon: "fas fa-trash",
                        tooltip: "Delete invite",
                        showProperty: "allowDelete",
                        clickEvent: (row) => {
                            this.deleteInvite(row);
                        },
                    },
                ],

                fields: [
                    {
                        fieldName: "title",
                        prettyName: "Title",
                        fieldType: GenericListFieldType.Text,
                        hideInOverview: true,
                        includeInSearch: true,
                    },
                    {
                        fieldName: "starterUser",
                        prettyName: "Invited user",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        fieldName: "dateInvited",
                        prettyName: "Date invited",
                        fieldType: GenericListFieldType.DateTime,
                    },
                    {
                        fieldName: "dateInvitedDayCount",
                        prettyName: "Invited # days ago",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        fieldName: "status",
                        prettyName: "Status",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        fieldName: "currentUser",
                        prettyName: "Current user",
                        fieldType: GenericListFieldType.Text,
                    },
                    {
                        fieldName: "allowDelete",
                        fieldType: GenericListFieldType.CheckBox,
                        hideInEdit: true,
                        hideInOverview: true,
                    },
                ],

                editOverride: (row) => {
                    this.openEntry(form, row);
                },
            };

            form._hasOpenInvites =
                form.ResultStatus &&
                form.ResultStatus.filter((x) => x.inviteCompleted !== true).length > 0;
        });
    }

    setupFormCategories(forms: Form[]) {
        this.formCategories = forms
            .map((f) => f.Category)
            .distinct()
            .map((c) => {
                const catForms = forms.filter((f) => f.Category === c);
                const catName = catForms[0].CategoryName;

                return {
                    category: catName,
                    forms: catForms,
                    hidden: false,
                };
            });
    }

    openEntry(form: Form, row: FormResultStatus) {
        if (row.allowOpening) {
            const tab = this.tabService.getTab(
                this.pages.FormEntry,
                row.resultID,
                `(FRM) Entry - ${form.Title}`,
                { formid: form.ID }
            );
            this.tabService.addTab(tab);
        }
    }

    openRequiredInvite(form: Form) {
        this.tabService.generateTab(this.pages.FormInvite, form.ID, "Invite - " + form.Title, {
            targetedinvitationtype: FormInviteType.RequiredInvitation,
        });
    }

    openRemindInvite(form: Form) {
        this.tabService.generateTab(this.pages.FormInvite, form.ID, "Invite - " + form.Title, {
            targetedinvitationtype: FormInviteType.ReminderInvitation,
        });
    }

    openOverview(form: Form) {
        this.tabService.generateTab(this.pages.FormOverview, form.ID, "Results - " + form.Title);
    }

    deleteInvite(row) {
        this._promptService
            .confirm(
                "Delete invite",
                `Are you sure you want to delete the invite to: '${row.starterUser}'?`
            )
            .onConfirm()
            .subscribe(() => {
                this._formDS
                    .deleteInvite(row.ID)
                    .toPromise()
                    .then(() => {
                        this.loadOverview();
                    });
            });
    }

    filterTrigger() {
        this.formCategories.forEach((cat) => {
            cat.hidden = true;
            cat.forms.forEach((form) => {
                form._genericConfig.onSearch(this.filter);
                if (form._genericConfig._visibleRowCount > 0 || !this.filter) {
                    cat.hidden = false;
                }
            });
        });
    }
}
