import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "reverse",
    pure: false,
})
export class ReversePipe implements PipeTransform {
    transform(value, returnReversed: boolean = true) {
        if (!value) return;
        if (!returnReversed) return value;

        return value.reverse();
    }
}
