import { Component, Input, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { EffectivenessModel, WorkflowStepPermissionModel } from "../../shared/models";
import { EffectivenessPageType, EffectivenessStatusType } from "../../shared/enums";
import { AdvEffDataService, AdvEffWorkflowDataService } from "../../shared/services";
import { EffWorkFlowService, EffWorkFlowState } from "../common/services/eff-workflow.service";
import { WorkflowStepConfig } from "../common/eff-workflow-bar/models/workflow-step-config";

@Component({
    selector: "eff-detail-main",
    templateUrl: "./eff-detail-main.component.html",
    styleUrls: ["./eff-detail-main.component.scss"],
})
export class EffDetailMainComponent implements OnInit {
    @Input() cerrixTab: TabModel;
    @Input() effectivenessGuid: string;

    effectivenessModel: EffectivenessModel;
    stepPermissions: WorkflowStepPermissionModel;
    workFlowSteps: EffWorkFlowState[];

    currentPage: EffectivenessPageType = EffectivenessPageType.NotStarted;
    currentPageState: EffWorkFlowState;
    pageTypes = EffectivenessPageType;

    constructor(
        private _ds: AdvEffDataService,
        private _workflowDs: AdvEffWorkflowDataService,
        private _effWorkFlowService: EffWorkFlowService
    ) {}

    async ngOnInit() {
        this._ds.getEffectiveness(this.effectivenessGuid).subscribe(async (eff) => {
            this.effectivenessModel = eff;
            this.workFlowSteps = await this._effWorkFlowService.getWorkFlowSteps(eff);
            this.currentPage = this.determinePageType();
            this.currentPageState = this.workFlowSteps.find((s) => s.stepId == this.currentPage);
            this.cerrixTab.name = eff.groupName + " | " + eff.name;
        });

        this._workflowDs
            .getWorkflowStepPermission(this.effectivenessGuid)
            .subscribe((permissions) => {
                this.stepPermissions = permissions;
            });
    }

    private determinePageType() {
        switch (this.effectivenessModel.status) {
            case EffectivenessStatusType.waitingForSourceDocuments:
                return EffectivenessPageType.UploadSourceDocuments;
            case EffectivenessStatusType.waitingForSamples:
                return EffectivenessPageType.UploadSourceDocuments;
            case EffectivenessStatusType.requestEvidence:
                return EffectivenessPageType.UploadEvidence;
            case EffectivenessStatusType.readyForAssessment:
            case EffectivenessStatusType.assessmentInProgress:
            case EffectivenessStatusType.waitingForConclusion:
            case EffectivenessStatusType.nonOccurrence:
                return EffectivenessPageType.Test;
            case EffectivenessStatusType.waitingForReviewer:
            case EffectivenessStatusType.reviewNonOccurrence:
                return EffectivenessPageType.Review;
            case EffectivenessStatusType.completed:
            case EffectivenessStatusType.nonOccurrenceCompleted:
                return EffectivenessPageType.Completed;
            case EffectivenessStatusType.undefined:
                return EffectivenessPageType.NotStarted;
        }
    }
}
