import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import {
    SourceDocumentUploadModel,
    WorkflowConclusionModel,
    WorkflowGenerateSamplesModel,
    WorkflowHistoryModel,
    WorkflowStepPermissionModel,
} from "../models";
import { EffectivenessUsers } from "../models/effectiveness-users";

@Injectable()
export class AdvEffWorkflowDataService extends ApiDataService {
    constructor(protected http: HttpClient, private configuration: Configuration) {
        super(http, configuration, "controls/testing/advanced/effectiveness");
    }

    getWorkflowStepPermission(guid: string): Observable<WorkflowStepPermissionModel> {
        const url = `/${guid}/workflow/steppermission`;

        return this.get<WorkflowStepPermissionModel>(url).pipe(
            map((pm) => new WorkflowStepPermissionModel().deserialize(pm))
        );
    }

    getWorkflowHistory(guid: string): Observable<WorkflowHistoryModel[]> {
        const url = `/${guid}/workflow/history`;

        return this.get<WorkflowHistoryModel[]>(url);
    }

    getUserFromEffectiveness(guid: string): Observable<EffectivenessUsers> {
        const url = `/${guid}/workflow/effecivenessusers`;

        return this.get<EffectivenessUsers>(url);
    }

    postWorkflowNonOccurrenceReopen(guid: string): Observable<void> {
        const url = `/${guid}/workflow/nonoccurrencereopen`;

        return this.post<void>(null, url);
    }

    postWorkflowNonOccurrenceAcceptReviewer(
        guid: string,
        model: WorkflowConclusionModel
    ): Observable<void> {
        const url = `/${guid}/workflow/nonoccurrenceacceptreviewer`;

        return this.post<void>(model, url);
    }

    postWorkflowNonOccurrenceModifyReviewer(
        guid: string,
        model: WorkflowConclusionModel
    ): Observable<void> {
        const url = `/${guid}/workflow/nonoccurrencemodifyreviewer`;

        return this.post<void>(model, url);
    }

    postWorkflowNonOccurrenceRejectReviewer(
        guid: string,
        model: WorkflowConclusionModel
    ): Observable<void> {
        const url = `/${guid}/workflow/nonoccurrencerejectreviewer`;

        return this.post<void>(model, url);
    }

    postWorkflowNonOccurrenceAcceptTester(
        guid: string,
        model: WorkflowConclusionModel
    ): Observable<void> {
        const url = `/${guid}/workflow/nonoccurrenceaccepttester`;

        return this.post<void>(model, url);
    }

    postWorkflowNonOccurrenceRejectTester(
        guid: string,
        model: WorkflowConclusionModel
    ): Observable<void> {
        const url = `/${guid}/workflow/nonoccurrencerejecttester`;

        return this.post<void>(model, url);
    }

    postWorkflowControlDidNotOccur(guid: string): Observable<void> {
        const url = `/${guid}/workflow/controldidnotoccur`;

        return this.post<void>({}, url);
    }

    postWorkflowUploadSourceDocuments(
        guid: string,
        workflowData: SourceDocumentUploadModel
    ): Observable<void> {
        const url = `/${guid}/workflow/uploadsourcedocuments`;

        return this.postWithDocuments<void>(workflowData, workflowData.documents, url);
    }

    postWorkflowGenerateSamples(
        guid: string,
        workflowData: WorkflowGenerateSamplesModel
    ): Observable<void> {
        const url = `/${guid}/workflow/generatesamples`;

        return this.post<void>(workflowData, url);
    }

    postWorkflowCompleteEvidenceUpload(guid: string): Observable<void> {
        const url = `/${guid}/workflow/completeevidenceupload`;

        return this.post<void>(null, url);
    }

    postWorkflowAcceptTester(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/workflow/accepttester`;

        return this.post<void>(model, url);
    }

    postWorkflowRejectTester(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/workflow/rejecttester`;

        return this.post<void>(model, url);
    }

    postWorkflowAcceptReviewer(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/workflow/acceptreviewer`;

        return this.post<void>(model, url);
    }

    postWorkflowModifyReviewer(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/workflow/modifyreviewer`;

        return this.post<void>(model, url);
    }

    postWorkflowRejectReviewer(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/workflow/rejectreviewer`;

        return this.post<void>(model, url);
    }

    postWorkflowReopen(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/workflow/reopen`;

        return this.post<void>(model, url);
    }

    postTestPeriodScore(guid: string, model: WorkflowConclusionModel): Observable<void> {
        const url = `/${guid}/testperiod/score`;

        return this.post<void>(model, url);
    }
}
