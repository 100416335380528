import { Component } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "app-external-connection-overview",
    templateUrl: "./external-connection-overview.component.html",
    styleUrls: ["./external-connection-overview.component.scss"],
})
export class ExternalConnectionOverviewComponent {
    tabID: string;
    tab: TabModel;

    constructor() {}
}
