import { mxClient } from "./mxClient";

declare module "mxgraph-factory" {
    namespace mxgraph {
        interface mxCell {
            // getShape doesn't exist on mxCell but we tell typescript to merge the decleration
            getShape(): string;
        }
    }
}

/**
 * This function is also in "MxCell.cs"
 */
mxClient.mxCell.prototype.getShape = function (): string {
    let shape: string;
    const style = this.style as string;
    if (style) {
        const shapeList = [
            "swimlane",
            "rectangle",
            "ellipse",
            "triangle",
            "rhombus",
            "line",
            "hexagon",
            "cylinder",
            "actor",
            "image",
        ];

        const declarations = style.split(";");
        for (const rawDeclaration of declarations) {
            const [property, value] = rawDeclaration
                .split("=")
                .map((d: string): string => d.trim());

            if (value) {
                if (property === "shape") {
                    shape = value;
                }
            } else if (shapeList.includes(property)) {
                shape = property;
            }
        }
    }

    return shape ?? "rectangle";
};
