import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppConstants } from "@app/app.constants";
import { AuditFieldworkStepDataService } from "@app/audit/services/audit-fieldworkstep-data.service";
import { AuditFieldworkFrameworkModel } from "@app/audit/shared/data-models/audit-fieldwork-framework-model";
import { AuditFieldworkStandingDataModel } from "@app/audit/shared/data-models/audit-fieldwork-standing-data-model";
import { AuditFieldworkStepModel } from "@app/audit/shared/data-models/audit-fieldwork-step-model";
import { AuditDetailPermissionModel } from "@app/audit/shared/permission-models/audit-detail-permission-model";
import { CerrixSelectTreeComponent } from "@app/shared/cerrix-select-tree/cerrix-select-tree.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixTreeViewerConfig } from "@app/shared/models/cerrix-tree-viewer-config";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { nameof } from "@methods/jeffs-toolkit";
import { FindNode } from "@methods/TreeMethods";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "audit-detail-fieldwork-plan",
    templateUrl: "./audit-detail-fieldwork-plan.component.html",
    styleUrls: ["./audit-detail-fieldwork-plan.component.scss"],
})
export class AuditDetailFieldworkPlanComponent implements OnInit {
    @Input() auditGuid: string;
    @Input() permissions: AuditDetailPermissionModel;
    @Input() steps: AuditFieldworkStepModel[];

    standingData: AuditFieldworkStandingDataModel;
    auditors: IdNameCombination[];
    reviewers: IdNameCombination[];

    selectedFrameworkIDs: number[];

    frameworkSteps: any[];
    customSteps: any[];

    selectedStep: AuditFieldworkStepModel;
    selectedStepExecutionDetails?: any = null;
    selectedStepIsFrameworkStep = false;
    selectedFormGroup: FormGroup;

    treeViewerConfig: CerrixTreeViewerConfig;

    pageReady = false;
    useReviewWorkflowFormControl: FormControl<boolean>;
    auditorsFormControl: FormControl<number[]>;
    reviewersFormControl: FormControl<number[]>;

    constructor(
        private _ds: AuditFieldworkStepDataService,
        private _promptService: CerrixPromptService,
        private _toastService: ToastrService
    ) {}

    async ngOnInit() {
        if (
            !this.permissions.fieldworkPermissions ||
            !this.permissions.fieldworkPermissions.canView
        ) {
            this._toastService.warning(
                "Not enough rights to view fieldwork steps.",
                "Not enough rights!"
            );
            return;
        }

        this.standingData = await firstValueFrom(this._ds.getPlanningStandingData(this.auditGuid));
        this.setSelectedFrameworkIDs();

        this.sortSteps();
        this.createTreeConfig();

        this.pageReady = true;
    }

    onOpen(selected: AuditFieldworkStepModel) {
        this.useReviewWorkflowFormControl = new FormControl(selected.useReviewWorkflow);
        this.auditorsFormControl = new FormControl(
            selected.auditors,
            selected.useReviewWorkflow ? Validators.required : null
        );
        this.reviewersFormControl = new FormControl(
            selected.reviewers,
            selected.useReviewWorkflow ? Validators.required : null
        );
        const newFormGroup = new FormGroup<any>({
            name: new FormControl(selected.name, Validators.required),
            icon: new FormControl(selected.icon, Validators.required),
            description: new FormControl(selected.description),
            auditors: this.auditorsFormControl,
            reviewers: this.reviewersFormControl,
            useReviewWorkflow: this.useReviewWorkflowFormControl,
        });

        this.auditors = this.standingData.auditors.filter(
            (data) => !data.isArchived || selected.auditors.some((sel) => sel == data.ID)
        );

        this.reviewers = this.standingData.reviewers.filter(
            (data) => !data.isArchived || selected.reviewers.some((sel) => sel == data.ID)
        );

        this.selectedStep = null;
        this.selectedStepExecutionDetails = null;
        this.selectedFormGroup = null;

        if (selected.id > 0) {
            if (!this.permissions.fieldworkPermissions.canEdit) {
                newFormGroup.disable();
            }
        } else {
            if (!this.permissions.fieldworkPermissions.canCreate) {
                this._toastService.warning(
                    "Not enough rights to create a new fieldwork step.",
                    "Not enough rights!"
                );
                return;
            }
        }

        // Add a small delay so frontend has time to process null sets, before initializing new values.
        setTimeout(() => {
            this.selectedStep = selected;
            this.setSelectedStepExecutionDetails();
            this.selectedStepIsFrameworkStep = selected.frameworkId > 0 ? true : false;
            this.selectedFormGroup = newFormGroup;
            this.selectedFormGroup.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.selectedFormGroup, selected);
                this.treeViewerConfig.reloadData();
            });
        }, 100);
    }

    setSelectedStepExecutionDetails() {
        if (this.selectedStep && this.selectedStep.executionDetails) {
            this.selectedStepExecutionDetails = JSON.parse(this.selectedStep.executionDetails);
        } else {
            this.selectedStepExecutionDetails = null;
        }
    }

    async removeStep(id) {
        await this.removeSteps([id], true);
    }

    async removeSteps(ids: any[], reloadTree?: boolean) {
        if (!this.permissions.fieldworkPermissions.canDelete) {
            this._toastService.warning(
                "Not enough rights to remove existing fieldwork steps.",
                "Not enough rights!"
            );
            return;
        }

        const anyChildren = this.steps.find((x) => ids.indexOf(x.parentId) >= 0) ? true : false;
        var continueDelete = anyChildren
            ? await this._promptService
                  .confirm(
                      `Deleting item${ids.length > 1 ? "s" : ""} with children.`,
                      "All child items will be removed as well. Are you sure you want to continue?"
                  )
                  .toPromise()
            : true;

        if (!continueDelete) {
            return;
        }

        ids.forEach((id) => {
            const step = this.steps.find((x) => x.id == id);

            if (step) {
                const childSteps = this.steps.filter((x) => x.parentId == x.id);
                if (childSteps) {
                    this.removeSteps(
                        childSteps.map((x) => x.id),
                        false
                    );
                }

                this.steps.splice(this.steps.indexOf(step), 1);
            }
        });

        if (this.selectedStep && ids.indexOf(this.selectedStep.id) >= 0) {
            this.selectedStep = null;
            this.selectedFormGroup = null;
            this.selectedStepExecutionDetails = null;
        }

        if (reloadTree) {
            this.treeViewerConfig.reloadData();
        }
    }

    async applyFrameworkChanges(selectedFrameworks: number[], treeComp: CerrixSelectTreeComponent) {
        const differences = this.steps
            .filter((x) => x.frameworkId)
            .findDifferences(
                selectedFrameworks,
                (l: AuditFieldworkStepModel, r: number) => l.frameworkId == r
            );

        if (this.permissions.fieldworkPermissions.canCreate) {
            differences.added.forEach((frameworkId: number) => {
                const framework = FindNode(
                    this.standingData.frameworks,
                    frameworkId
                ) as AuditFieldworkFrameworkModel;
                const newStep = this.getNewStepModel();
                newStep.parentId = framework.ParentID;
                newStep.frameworkId = frameworkId;
                newStep.frameworkName = framework.Name;

                newStep.name = framework.Name;
                newStep.description = framework.Description;
                newStep.icon = framework.Icon;

                this.steps.push(newStep);
            });

            // Correct parentIds
            differences.added.forEach((frameworkId: number) => {
                const node = this.steps.find((x) => x.frameworkId == frameworkId);
                if (node.parentId) {
                    const parentNode = this.steps.find((x) => x.frameworkId == node.parentId);
                    if (parentNode) {
                        node.parentId = parentNode.id;
                    } else {
                        node.parentId = null;
                    }
                }
            });
        } else if (differences.added.length > 0) {
            this._toastService.warning(
                "Not enough rights to create new fieldwork steps.",
                "Not enough rights!"
            );
        }

        if (differences.deleted.length > 0) {
            if (
                !this.permissions.fieldworkPermissions.canDelete &&
                differences.deleted.some((s) => s.id > 0)
            ) {
                this._toastService.warning(
                    "Not enough rights to remove existing fieldwork steps.",
                    "Not enough rights!"
                );
            } else {
                await this.removeSteps(differences.deleted.map((x) => x.id));
            }
        }

        this.sortSteps();
        this.createTreeConfig();

        this.setSelectedFrameworkIDs();
        treeComp.updateValue(this.selectedFrameworkIDs);
    }

    addCustomStep() {
        if (!this.permissions.fieldworkPermissions.canCreate) return;

        const newStep = this.getNewStepModel();
        this.steps.push(newStep);

        this.sortSteps();
        this.createTreeConfig();
        this.treeViewerConfig.selectNode(newStep.id);
    }

    private getNewStepModel() {
        return <AuditFieldworkStepModel>{
            id: -1 * (this.steps.length + 1),
            sortOrder: this.steps.length * 10,

            name: "New step",
            description: "",
            icon: AppConstants.DefaultIcon,

            auditors: [],
            reviewers: [],
        };
    }

    private setSelectedFrameworkIDs() {
        this.selectedFrameworkIDs = this.steps
            .filter((x) => x.frameworkId > 0)
            .map((x) => x.frameworkId);
    }

    private createTreeConfig() {
        if (this.treeViewerConfig) {
            this.treeViewerConfig.reloadData();
            return;
        }

        this.treeViewerConfig = {
            data: this.steps,
            allowDragDrop: this.permissions.fieldworkPermissions.canEdit,

            idProperty: nameof<AuditFieldworkStepModel>((x) => x.id),
            textProperty: nameof<AuditFieldworkStepModel>((x) => x.name),
            iconProperty: nameof<AuditFieldworkStepModel>((x) => x.icon),

            treeProperty: {
                parentProperty: nameof<AuditFieldworkStepModel>((x) => x.parentId),
            },

            onNodeSelect: (selectedNodeId: any) => {
                const step = this.steps.find((x) => x.id == +selectedNodeId);
                this.onOpen(step);
            },

            onNodeMove: (updatedNodeIdsInOrder: any[], parentId: any) => {
                updatedNodeIdsInOrder.forEach((nodeId, index) => {
                    const step = this.steps.find((x) => x.id == +nodeId);

                    step.parentId = parentId ? +parentId : null;
                    step.sortOrder = index * 10;
                });
            },
        };
    }

    private sortSteps() {
        this.steps = this.steps.sortBy(
            nameof<AuditFieldworkStepModel>((x) => x.sortOrder),
            true
        );
    }

    useReviewWorkflowChanged(e) {
        if (e.checked) {
            this.auditorsFormControl.setValidators(Validators.required);
            this.reviewersFormControl.setValidators(Validators.required);
        } else {
            this.auditorsFormControl.clearValidators();
            this.reviewersFormControl.clearValidators();
        }
        this.auditorsFormControl.markAsTouched();
        this.reviewersFormControl.markAsTouched();
        this.auditorsFormControl.updateValueAndValidity();
        this.reviewersFormControl.updateValueAndValidity();
    }
}
