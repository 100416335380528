import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";

export class DataBreachModel {
    ID: number;
    InternalBreachIdentifier: string;
    ExternalBreachIdentifier: string;
    OrganizationId: number;
    AuthoritiesNotified = false;
    AuthoritiesNotifiedDate: Date;
    AuthoritiesNotifiedTime: Date;
    InternationalAuthoritiesNotified = false;
    InternationalAuthoritiesName: string;
    NatureOfIncidentId: number;
    BreachDescription: string;
    BreachCause: string;
    BreachOccurenceDateTime: Date;
    BreachEndDateTime: Date;
    OutsourcedDataProcessing = false;
    SubdataProcessor = false;
    DataProcessorName: string;
    NumberOfPersonsInvolvedMinimum: number;
    NumberOfPersonsInvolvedMaximum: number;
    PersonsInvolvedNotificationDate: Date;
    ExplanationNoNotificationPersonsInvolved: string;
    NotificationPersonsInvolved: string;
    PersonsInvolvedDescription: string;
    PersonsInvolvedFromOtherEuCountries = false;
    FollowUpDescription: string;
    Comments: string;
    PersonsInvolvedNotified = false;
    SecondaryNotificationNotified = false;
    SecondaryNotificationDate: Date;
    SecondaryNotificationDescription: string;
    DataProtectionOfficerId: number;
    RegistrationNr: string;
    OrganizationName: string;
    Address: string;
    ZipCode: string;
    City: string;
    ActiveInWhichSectorId: number;
    PreventativeMeasures: string;
    PersonsNotified: number;
    MeansOfCommunication: string;
    ReasonNoNotificationId: number;
    CrossborderProcessing = false;
    EUCountry: string;
    NumberOfDataRegistersInvolved: number;

    // MultiSelects
    BreachTypes: number[];
    PersonalDataTypes: number[];
    SensitivePersonalDataTypes: number[];
    DataBreachConsequences: number[];
    ResponsibleList: number[];
    BreachAssessorList: number[];
    BreachCategoryList: number[];
    PersonsInvolvedList: number[];
    ConsequencesToCiaOfDataList: number[];

    // Special case
    BreachDetectionDateTime: Date;
    BreachDetectionTime: string;

    // CustomFields
    CustomFields: CustomFieldDetailModel[] = [];
}
