import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "risk-get-risk-control-and-moi-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class RiskGetRiskControlAndMoiDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Template API Specification",
            httpMethod: "GET",
            path: "/riskdata/riskcontrolmois",

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        risks: [
                            {
                                "risk Identifier": "string",
                                "risk Name": "string",
                                "organization Path": "string",
                                organization: "string",
                                "risk Catalogue": "string",
                                "risk Subtype": "string",
                                "risk Definition": "string",
                                "risk Description": "string",
                                "business Dimension Paths": "string",
                                "business Dimensions": "string",
                                cause: "string",
                                effect: "string",
                                "likelihood Gross": "integer",
                                "likelihood Net": "integer",
                                "impact Gross": "integer",
                                "impact Net": "integer",
                                "gross Score": "integer",
                                "net Score": "integer",
                                "gross Single loss Expectancy": "integer",
                                "gross Quantitative Likelihood": "integer",
                                "gross Annualized loss Expectancy": "integer",
                                "net Single loss Expectancy": "integer",
                                "net Quantitative Likelihood": "integer",
                                "net Annualized loss Expectancy": "integer",
                                "risk Area": "string",
                                "event Category": "string",
                                owner: "string",
                                "risk Treatment": "string",
                                "risk Appetite": "string",
                                "risk Appetite Comment": "string",
                                "risk Comments": "string",
                                confidential: "boolean",
                                applicable: "boolean",
                                approved: "boolean",
                                reviewed: "boolean",
                                "key Risk": "boolean",
                                "date Created": "datetime",
                                "date Modified": "datetime",
                                "last Modifier": "string",
                                "risk Assessment": "string",
                                "risk Assessment Date": "datetime",
                            },
                        ],
                        controls: [
                            {
                                "risk Identifier": "string",
                                "risk Name": "string",
                                "control Identifier": "string",
                                "control Name": "string",
                                "control Description": "string",
                                "organization Path": "string",
                                organization: "string",
                                "business Dimensions": "string",
                                "framework Dimensions": "string",
                                "control Catalogue": "string",
                                "control Definition": "string",
                                "control Type": "string",
                                source: "string",
                                "is Key Control": "boolean",
                                "requires Monitoring": "boolean",
                                "aspect IC": "string",
                                "control Frequency": "string",
                                "control Execution Method": "string",
                                "in Place": "string",
                                owner: "string",
                                "start Date": "datetime",
                                "end Date": "datetime",
                                "control Objective": "string",
                                "initial Costs": "integer",
                                "annual Costs": "integer",
                                comments: "string",
                                "design Score": "string",
                                "design Score_COLOR": "color",
                                "implementation Score": "string",
                                "implementation Score_COLOR": "color",
                                "effectiveness Score": "string",
                                "effectiveness Score_COLOR": "color",
                                "latest Period Score": "string",
                                "latest Period Score_COLOR": "color",
                                "final Conclusion Score": "string",
                                "final Conclusion Score_COLOR": "color",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
