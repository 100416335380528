<div class="cerrix-select">
    <div *ngIf="!data">
        <i class="fas fa-circle-notch fa-spin me-2" data-uiid="data-loader"></i>
    </div>
    <div *ngIf="data">
        <label class="no-data" *ngIf="showNoData"
            >No {{ prettyName }} available
            <i
                *ngIf="showRefreshButton"
                title="Click to reload data"
                class="fas fa-sync me-2"
                (click)="reloadData()"
            ></i>
        </label>
        <ng-select
            *ngIf="data.length > 0"
            [id]="id"
            [items]="data"
            [bindLabel]="bindLabel"
            [bindValue]="bindValue"
            [closeOnSelect]="closeOnSelect"
            [placeholder]="placeholder"
            [multiple]="multiple"
            [(ngModel)]="value"
            [ngModelOptions]="ngModelOptions"
            [disabled]="disabled"
            [addTag]="addTag"
            [searchable]="searchable"
            [clearable]="clearable"
            [attr.data-uiid]="dataUiid"
        >
            <ng-template
                *ngIf="data.length > 0 && data[0][bindColor]"
                ng-label-tmp
                ng-option-tmp
                let-item="item"
            >
                <div
                    [style.background-color]="item[bindColor]"
                    class="cerrix-select-color-element"
                    style="min-width: 5px; min-height: 16px; float: left; margin-right: 10px"
                ></div>
                {{ item[bindLabel] }}
            </ng-template>
        </ng-select>
    </div>
</div>
