import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { AvailableManualModel } from "../models/available-manual-model";

@Injectable()
export class ManualDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "manuals");
    }

    public getManuals() {
        return this.get<AvailableManualModel[]>();
    }
}
