import { HttpClient } from "@angular/common/http";
import { Injectable, Type, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentClassificationModel } from "../models/incident-classification.model";
import { BaseStandingDataService } from "@app/standing-data/interfaces/base-standing-data.service";
import { IncidentClassificationEditModel } from "../models/incident-classification-edit.model";
import { BaseStandingDataEditComponent } from "@app/standing-data/interfaces/base-standing-data-edit.component";
import { ClassificationEditComponent } from "@app/standing-data/classification-edit/classification-edit.component";

@Injectable({
    providedIn: "root",
})
export class IncidentClassificationService implements BaseStandingDataService {
    private readonly httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/classifications`;

    public getAll(): Observable<IncidentClassificationModel[]> {
        return this.httpClient.get<IncidentClassificationModel[]>(this.baseUrl);
    }

    public getClassification(classificationId: string): Observable<IncidentClassificationModel> {
        return this.httpClient.get<IncidentClassificationModel>(
            `${this.baseUrl}/${classificationId}`
        );
    }

    public addClassification(classification: IncidentClassificationEditModel): Observable<string> {
        return this.httpClient.post<string>(this.baseUrl, classification);
    }

    public editClassification(classification: IncidentClassificationEditModel): Observable<void> {
        return this.httpClient.put<void>(`${this.baseUrl}/${classification.id}`, classification);
    }

    public deleteClassification(classificationId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/${classificationId}`);
    }

    public changeOrder(classificationId: string, order: number): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/${classificationId}/order`, order);
    }

    public getEditComponent(): Type<BaseStandingDataEditComponent> {
        return ClassificationEditComponent;
    }
}
