import { Component, Input } from "@angular/core";
import { RadioOption } from "@app/controls/testing/advanced/detail-new/common/eff-radio-group/models/radio-option.model";
import { SampleType } from "@app/controls/testing/advanced/shared/enums";
import {
    EffectivenessModel,
    EffectivenessSourceDocumentsModel,
    WorkflowGenerateSamplesModel,
} from "@app/controls/testing/advanced/shared/models";
import { ExcelHelper } from "@app/shared/helpers/excel-helper";
import { toPromise } from "@methods/CommonMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { SampleTypeFormGroup } from "./models/sample-type-form-group.model";
import _ from "underscore";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { AdvEffDataService } from "@app/controls/testing/advanced/shared/services";

@Component({
    selector: "sample-type-step",
    templateUrl: "./sample-type-step.component.html",
    styleUrls: ["./sample-type-step.component.scss"],
})
export class SampleTypeStepComponent {
    @Input() sampleTypes: any[] = [];
    @Input() samplingInformation: WorkflowGenerateSamplesModel;
    @Input() sourceDocumentModel: EffectivenessSourceDocumentsModel;
    @Input() effectivenessModel: EffectivenessModel;
    @Input() sampleFormGroup: SampleTypeFormGroup;
    @Input() isAutomatic: boolean;

    sampleTypeOptions: RadioOption[] = [];
    exceldocument: DocumentModel;
    constructor(private _ds: AdvEffDataService) {}

    ngOnInit() {
        let visibleSocuments = this.sourceDocumentModel.documents.filter((doc) => !doc.Deleted);
        let excelSourceDocuments =
            visibleSocuments.length == 1
                ? visibleSocuments.filter((x) => x.Name.toLowerCase().endsWith(".xlsx"))
                : visibleSocuments.filter(
                      (x) => x.IsSelected && x.Name.toLowerCase().endsWith(".xlsx")
                  );

        if (excelSourceDocuments.length > 0) {
            this.exceldocument = excelSourceDocuments[0];
            this.sampleFormGroup.documentIdFormControl.setValue(this.exceldocument.Guid);
        } else {
            this.sampleTypes = this.sampleTypes.filter((s) => s.ID != 3); //spreadsheet
        }

        this.sampleTypeOptions = this.sampleTypes.map((type) => {
            return { id: type.ID, name: type.Name };
        });

        this.sampleFormGroup.init(this.samplingInformation, this.isAutomatic);
        this.sampleFormGroup.idColumnFormControl.valueChanges
            .pipe(debounceTime(2000), distinctUntilChanged())
            .subscribe(() => {
                this.getMaximumPopulation(this);
            });
        this.sampleFormGroup.rowsToSkipFormControl.valueChanges
            .pipe(debounceTime(2000), distinctUntilChanged())
            .subscribe(() => {
                this.getMaximumPopulation(this);
            });
    }

    sampleTypeChanged() {
        this.sampleFormGroup.init(this.samplingInformation, this.isAutomatic);
        if (this.isSpreadsheet()) {
            this.sampleFormGroup.populationSizeFormControl.setValue("");
        }
    }

    hidePopulationSize() {
        return this.samplingInformation.sampleType == SampleType.date;
    }

    isSpreadsheet() {
        return this.samplingInformation.sampleType == SampleType.spreadsheet;
    }

    hasValue(value) {
        return value != null && value != undefined && value != "";
    }

    async getMaximumPopulation(that) {
        if (
            !that.hasValue(that.sampleFormGroup.idColumnFormControl.value) ||
            !that.hasValue(that.sampleFormGroup.rowsToSkipFormControl.value)
        ) {
            return;
        }

        var idColumn = ExcelHelper.convertExcelColumnToNumber(
            that.sampleFormGroup.idColumnFormControl.value
        );

        let maximumPopulation = await toPromise(
            that._ds.getPopulation(
                that.effectivenessModel.guid,
                that.sampleFormGroup.documentIdFormControl.value,
                idColumn,
                that.sampleFormGroup.rowsToSkipFormControl.value
            )
        );

        that.sampleFormGroup.populationSizeFormControl.setValue(maximumPopulation);
    }
}
