<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export Datamanagement</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <hr />

        <mat-checkbox color="primary" name="includeMois" [(ngModel)]="includeChecked"
            >Include closed MoIs from previous BIM periods</mat-checkbox
        >

        <hr />

        <button
            class="btn btn-default me-2 mb-2"
            (click)="downloadDatamanagement(ExportType.DataManagement)"
        >
            Datamanagement
        </button>

        <button
            class="btn btn-default me-2 mb-2"
            (click)="downloadDatamanagement(ExportType.DataManagementAndMoi)"
        >
            Datamanagement and MoIs
        </button>
    </div>
</div>
