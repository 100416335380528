export function GetBaseHighchartConfig(chartType: string) {
    return <Highcharts.Options>{
        chart: {
            type: chartType,
        },
        title: {
            text: "",
        },
        legend: {
            enabled: true,
            reversed: false,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                cursor: "pointer",
                point: {
                    events: {},
                },
            },
            pie: {
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                },
                showInLegend: true,
            },
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF"],
                },
            },
        },
    };
}
