import { ComparisonType } from "../../enums/workspace/ComparisonType";
import moment from "moment";

export class SearchKeyword {
    searchValue: string;
    col: string;
    specific: boolean;
    compareType: ComparisonType;
    remoteSearch?: Boolean = false;
}

export class SearchKeywordHelper {
    search: SearchKeyword;

    constructor(keyword: SearchKeyword) {
        this.search = keyword;
    }

    isSearched?(data: any, isNumber?: boolean, isDate?: boolean): boolean {
        if (this.search.specific) {
            return true; // wrong method to check specific filter.
        }

        if (this.search.col === "") {
            const headers = Object.getOwnPropertyNames(data);
            for (let c = 0; c < headers.length; c++) {
                const colValue = ("" + data[headers[c]]).toLowerCase().trim();
                if (colValue.indexOf(this.search.searchValue.toLowerCase()) >= 0) {
                    return true;
                }
            }

            return false;
        } else {
            const compareType = +this.search.compareType as ComparisonType;

            let found = false;
            let colValue: any = ("" + data[this.search.col]).toLowerCase().trim();
            let checkValue: any = this.replaceSearchTag(this.search.searchValue.toLowerCase());

            if (isNumber) {
                colValue = parseInt(colValue, 10);
                checkValue = parseInt(checkValue, 10);
            }

            if (isDate) {
                // Strip time of date object
                colValue = new Date(new Date(colValue).toDateString()).getTime();
                checkValue = new Date(this.convertStringDateFormat(checkValue)).getTime();

                if (
                    compareType !== ComparisonType["Is not equal to"] &&
                    (!colValue || isNaN(+colValue) || !checkValue || isNaN(+checkValue))
                ) {
                    return found;
                }
            }

            // Check by type
            switch (compareType) {
                case ComparisonType["Contains"]: {
                    found = colValue.indexOf(checkValue) >= 0;
                    break;
                }
                case ComparisonType["Does not contain"]: {
                    found = colValue.indexOf(checkValue) < 0;
                    break;
                }
                case ComparisonType["Is equal to"]: {
                    found = checkValue === colValue;
                    break;
                }
                case ComparisonType["Is not equal to"]: {
                    found = checkValue !== colValue;
                    break;
                }
                case ComparisonType["Begins with"]: {
                    found = ("" + colValue).startsWith("" + checkValue);
                    break;
                }
                case ComparisonType["Ends with"]: {
                    found = ("" + colValue).endsWith("" + checkValue);
                    break;
                }
                case ComparisonType["After/Greater than"]: {
                    found = colValue > checkValue;
                    break;
                }
                case ComparisonType["Before/Less than"]: {
                    found = colValue < checkValue;
                    break;
                }
                case ComparisonType["Equal or after/greater than"]: {
                    found = colValue >= checkValue;
                    break;
                }
                case ComparisonType["Equal or before/less than"]: {
                    found = colValue <= checkValue;
                    break;
                }
            }

            return found;
        }
    }

    isSearchedSpecific?(columnIds: number[], filterIds: number[]): boolean {
        if (!columnIds || columnIds.length === 0) {
            return false;
        }

        return columnIds.some((cid) => filterIds.indexOf(cid) >= 0);
    }

    replaceSearchTag?(tag: string) {
        if (tag === "{{today}}") {
            tag = moment().format("DD-MM-YYYY");
        }
        return tag;
    }

    /** This method will return the string in the following format: yyyy/mm/dd */
    convertStringDateFormat(value: string) {
        // format is: dd-mm-yyyy
        if (RegExp(/^[0-9]{1,2}[-][0-9]{1,2}[-][0-9]{4}/).test(value)) {
            return value.split("-").reverse().join("/");
        }

        // format is: yyyy-mm-dd
        if (RegExp(/^[0-9]{4}[-][0-9]{1,2}[-][0-9]{1,2}/).test(value)) {
            return value.split("-").join("/");
        }

        // format is: dd/mm/yyyy
        if (RegExp(/^[0-9]{1,2}[\/][0-9]{1,2}[\/][0-9]{4}/).test(value)) {
            return value.split("/").reverse().join("/");
        }

        // format is: yyyy/mm/dd
        if (RegExp(/^[0-9]{4}[\/][0-9]{1,2}[\/][0-9]{1,2}/).test(value)) {
            return value;
        }
    }
}
