import { Pipe, PipeTransform } from "@angular/core";
import { LocalizationService } from "../localization";

@Pipe({
    name: "dateTimeFormat",
    pure: true,
})
export class DateTimeFormatPipe implements PipeTransform {
    constructor(private localizationService: LocalizationService) {}

    transform(value: any, includeSeconds: boolean = false): string | null {
        if (value == null || value === "" || value !== value) {
            return null;
        }

        if (typeof value === "string") {
            value = new Date(value);
        }

        const localizationInfo = this.localizationService.localizationInfo;
        const format = includeSeconds
            ? localizationInfo.dateTimeFormat
            : localizationInfo.dateShortTimeFormat;

        return this.localizationService.formatDate(value, format);
    }
}
