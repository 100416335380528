<cerrix-loader [cerrixTab]="tab" *ngIf="tab"></cerrix-loader>

<ng-container *ngIf="data && ((tab && !tab.showLoader) || kriGuids)">
    <div class="detail-page-card" *ngFor="let kriData of data">
        <div class="detail-page-card-body">
            <kri-data-graph
                [kriGuid]="kriData.guid"
                [showOpen]="true"
                [preLoadedData]="kriData"
            ></kri-data-graph>
        </div>
    </div>
</ng-container>
