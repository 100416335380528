<div class="fancy-loader" *ngIf="isLoading"></div>
<app-linkable
    *ngIf="!isLoading"
    typename="audits"
    displayValueProp="name"
    idValueProp="id"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    [hideActions]="true"
    [hideFilter]="true"
    [allowAddNew]="false"
    [rendererConfig]="rendererConfig"
    (onOpenClicked)="openItem($event)"
    (onReloadClicked)="reload()"
    (linkedChange)="linkedItemsChanged($event)"
>
</app-linkable>
