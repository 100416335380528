import { Component, Output, EventEmitter, OnInit, Input } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { TestPeriodDataService } from "./adv-eff-testperiod-data.service";
import { TestPeriodModel } from "../shared/test-period.model";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TestSamplesModel } from "../../shared/models/testsamples-model";
import { nameof } from "@methods/jeffs-toolkit";
import { getAssessmentScores } from "../../shared/adv-eff-shared-methods";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { ToastrService } from "ngx-toastr";
import { toPromise } from "@methods/CommonMethods";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";

@Component({
    selector: "adv-eff-testperiod-manager",
    templateUrl: "./adv-eff-testperiod-manager.component.html",
    styleUrls: ["./adv-eff-testperiod-manager.component.scss"],
})
export class TestperiodManagerComponent implements OnInit {
    @Input() controlGuid: string;
    @Input() modalRef: BsModalRef;
    @Input() controlPermissions: ControlPermissionModel;

    @Output() reload = new EventEmitter();

    config: GenericListConfig;
    samples: TestSamplesModel;

    constructor(
        private ds: TestPeriodDataService,
        private _promptService: CerrixPromptService,
        private controlDataService: ControlDataService,
        private _toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.ds.getNumberOfTestSamples(this.controlGuid).subscribe((ts) => {
            this.samples = ts;
            this.createConfig();
        });
    }

    createConfig() {
        this.config = <GenericListConfig>{
            name: "Test periods",
            hideHeader: true,
            isSortable: true,

            allowAdd: this.controlPermissions.CanManageEffectivenesses,
            allowEdit: this.controlPermissions.CanManageEffectivenesses,
            allowDelete: this.controlPermissions.CanManageEffectivenesses,

            fields: [
                {
                    fieldName: nameof<TestPeriodModel>((period) => period.Name),
                    prettyName: "Period Name",
                    fieldType: GenericListFieldType.Text,
                    required: true,
                    editorWidth: 12,
                },
                {
                    fieldName: nameof<TestPeriodModel>((period) => period.minimalSamplesCount),
                    prettyName: "Minimal # samples",
                    fieldType: GenericListFieldType.Number,
                    defaultValue: this.samples.suggestedSamples,
                    minimumValue: this.samples.minimumSamples,
                    editorWidth: "12",
                },
                {
                    fieldName: nameof<TestPeriodModel>((period) => period.effectivenessPeriodCount),
                    prettyName: "# Effectiveness periods",
                    fieldType: GenericListFieldType.Number,
                    hideInEdit: true,
                },
                {
                    fieldName: nameof<TestPeriodModel>((period) => period.startDate),
                    prettyName: "Start date",
                    fieldType: GenericListFieldType.Date,
                    required: true,
                    editorWidth: "6",
                },
                {
                    fieldName: nameof<TestPeriodModel>((period) => period.endDate),
                    prettyName: "End date",
                    fieldType: GenericListFieldType.Date,
                    required: true,
                    editorWidth: "6",
                },
                {
                    fieldName: nameof<TestPeriodModel>((period) => period.periodScore),
                    prettyName: "Score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    required: false,
                    hideInEdit: true,
                    getDataMethod: () => getAssessmentScores(this.controlDataService),
                },
            ],

            preEditCheck: (row: TestPeriodModel) => {
                return row.ID !== 0;
            },
            preDeleteCheck: (row: TestPeriodModel) => {
                return row.ID !== 0;
            },

            add: async (row: TestPeriodModel) => {
                await this.ds.store(this.controlGuid, row).toPromise();
                this.reload.emit();
                return true;
            },
            edit: async (row: TestPeriodModel, uneditedRow: TestPeriodModel) => {
                await this.ds.store(this.controlGuid, row).toPromise();
                this.reload.emit();
                return true;
            },

            delete: async (row: TestPeriodModel) => {
                await this.ds.deletePeriod(this.controlGuid, row.ID).toPromise();
                this.reload.emit();
                return true;
            },

            dataMethod: () => this.ds.getTestPeriodData(this.controlGuid),

            extraButtons: [
                {
                    text: "Score test period",
                    show: this.controlPermissions.CanScoreEffectivenessesTestPeriod,
                    class: "btn btn-warning",
                    action: async (activeRow) => {
                        if (!activeRow || !activeRow.ID) {
                            this._toastrService.warning(
                                "First select a period to score a test period"
                            );
                            return false;
                        }

                        if (await this.openTestPeriodScoreDialog(activeRow.ID)) {
                            this.config.reload();
                            return true;
                        }
                        return false;
                    },
                },
            ],
        };
    }
    async openTestPeriodScoreDialog(testPeriodID: number) {
        const fields = [];
        fields.push({
            prettyName: "Conclusion",
            fieldName: "scoreId",
            fieldType: GenericListFieldType.ColoredSelect,
            placeholder: "Required",
            required: true,
            selfInit: true,
            getDataMethod: () => getAssessmentScores(this.controlDataService),
        });

        let result = await toPromise(
            this._promptService
                .prompt({
                    hasBackdrop: false,
                    data: {
                        confirmOnEnter: false,
                        closeOnEsc: false,
                        title: "Add score",
                        fields: fields,
                        confirmButton: {
                            text: "OK",
                        },
                    },
                })
                .getResult()
        );
        if (result) {
            const prompt = this.getSavingPrompt();
            await toPromise(this.ds.postTestPeriodScore(this.controlGuid, testPeriodID, result));
            prompt.close();
            this.reload.emit();

            return true;
        }
    }

    private getSavingPrompt(): CerrixPromptComponent {
        return this._promptService.loader("Saving changes, please wait...");
    }
}
