<div
    class="detail-page-card"
    [class.m-0]="noMargin"
    [class.compact-document-manager-old]="compactMode"
    fileDrop
    (fileDrop)="fileDropHandler($event)"
    [fullScreenDropArea]="fullScreenDropArea"
    [allowedExtensions]="allowedFileExtensions"
    [allowDrop]="permissions.canAddDocument"
    *ngIf="allowedFileExtensions"
>
    <div class="document-title-row">
        <div class="detail-page-card-title" *ngIf="!hideLabel && Label">{{ Label }}</div>
        <i
            *ngIf="!hideLabel && Label && Tooltip"
            class="far fa-info-circle mx-2"
            [matTooltip]="Tooltip"
        >
        </i>
    </div>

    <div class="detail-page-card-actions" *ngIf="!hideAddButton">
        <button
            data-uiid="documentmanager-adddocument"
            class="btn btn-raised"
            (click)="openEdit()"
            [disabled]="disabled || !permissions.canAddDocument"
        >
            <i class="fas fa-plus-circle"></i>
            <span>Add document</span>
        </button>
    </div>
    <div
        class="detail-page-card-body flush-expansion-panels"
        [style.max-height]="MaxHeight ? MaxHeight : ''"
        [class.scrollable]="scrollEnabled"
    >
        <span *ngIf="!ShowSummary">
            <mat-accordion>
                <mat-expansion-panel
                    *ngFor="let doc of Documents | sortby : 'Date' : 'desc'"
                    [ngClass]="{ deleted: doc.Deleted }"
                >
                    <mat-expansion-panel-header
                        [collapsedHeight]="headerHeight"
                        [expandedHeight]="headerHeight"
                    >
                        <mat-panel-title
                            [class.col-md-8]="!compactMode"
                            [class.col-md-12]="compactMode"
                            [matTooltip]="doc.Name"
                        >
                            <i
                                class="fas fa-{{ doc.FileIcon }}"
                                *ngIf="!doc.IsNew && !doc.Edited && !doc.Deleted"
                            ></i>
                            <i class="fas fa-plus" *ngIf="doc.IsNew"></i>
                            <i class="fas fa-pencil" *ngIf="doc.Edited"></i>
                            <i class="fas fa-times" *ngIf="doc.Deleted"></i>
                            <span class="document-name text-truncate ms-2"
                                >{{ doc.Name }} <i *ngIf="doc.Changed">(edited)</i></span
                            >
                        </mat-panel-title>
                        <mat-panel-description>
                            <i
                                *ngIf="!doc.Deleted && AllowEdit"
                                class="fas fa-pencil-alt"
                                (click)="openEdit(doc); $event.stopPropagation()"
                            ></i>
                            <a
                                *ngIf="!doc.IsNew"
                                class="fas fa-download"
                                [href]="'api/documents/manager/' + doc.Guid + '/data'"
                                target="_blank"
                            ></a>
                            <i
                                *ngIf="!disabled && doc.CanDelete"
                                class="fas fa-{{ doc.Deleted ? 'undo' : 'trash' }}"
                                (click)="toggleDelete(doc); $event.stopPropagation()"
                            ></i>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <table class="table">
                        <tbody>
                            <tr>
                                <th>User</th>
                                <td>{{ doc.Username }}</td>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <td>{{ doc.Date | dateTimeFormat }}</td>
                            </tr>
                            <tr>
                                <th># of versions</th>
                                <td>{{ doc.Version }}</td>
                            </tr>
                            <tr *ngIf="ShowType">
                                <th>Type</th>
                                <td>{{ doc.DocumentType }}</td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td title="{{ doc.Description }}">{{ doc.Description }}</td>
                            </tr>
                            <tr *ngIf="!doc.IsNew && doc.Name.endsWith('.pdf')">
                                <th>PDF Viewer</th>
                                <td
                                    (click)="openDocumentViewer(doc)"
                                    style="color: blue; cursor: pointer"
                                >
                                    Click Here to open the PDF Viewer
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </span>
        <div class="drop-zone p-2" *ngIf="!disabled && (!Documents || Documents.length === 0)">
            <span *ngIf="permissions.canAddDocument">{{ NoDocumentMessage }}</span>
            <span *ngIf="!permissions.canAddDocument">No documents uploaded.</span>
        </div>
        <div class="documents-col" *ngIf="ShowSummary">
            <span *ngIf="Documents?.length > 0">
                {{ Documents.length }} document{{ Documents.length > 1 ? "s" : "" }}
            </span>
        </div>
    </div>
</div>
