import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { ManualComponent } from "./manual.component";
import { ApiPowerBIUsageComponent } from "./pages/manual-pages/apis/api-powerbi-usage/api-powerbi-usage.component";
import { BimGetMoiDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/bim/bim-get-moi-data-api-manual";
import { BimGetMoiSummaryDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/bim/bim-get-moi-summary-data-api-manual";
import { ControlGenerateSamplesApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-generate-samples-api-manual.component";
import { ControlGetSamplesApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-get-samples-api-manual.component";
import { ControlReadyForAssessmentApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-ready-for-assessment-api-manual.component";
import { ControlTestInfoApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-test-info-api-manual.component";
import { ControlSamplesUploadEvidenceApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-samples-upload-evidence-api-manual.component";
import { ControlTestStepsUploadEvidenceApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-teststeps-upload-evidence-api-manual.component";
import { ControlUploadSourceDocumentApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-upload-source-document-api-manual.component";
import { EventGetDataBreachAndMoiDataManualComponent } from "./pages/manual-pages/apis/api-spec/types/events/event-get-data-breach-and-moi-data-manual.component";
import { EventGetEventAndMoiDataManualComponent } from "./pages/manual-pages/apis/api-spec/types/events/event-get-event-and-moi-data-manual.component";
import { EventGetEventDataManualComponent } from "./pages/manual-pages/apis/api-spec/types/events/event-get-event-data-manual.component";
import { TpaGetEvidenceExportApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/external-connections/third-party-assurance/tpa-getevidencedocument-api-manual";
import { TpaGetResultsApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/external-connections/third-party-assurance/tpa-getresults-api-manual";
import { TpaRequestExportApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/external-connections/third-party-assurance/tpa-requestexport-api-manual";
import { FrGetFindingReportAssDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/findingreport/fr-finding-report-ass-data-api-manual.component";
import { KriGetDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/kri/kri-get-data-api-manual";
import { KriPostDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/kri/kri-post-data-api-manual";
import { RiskGetAssessmentPerOrgDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/risk/risk-get-assessment-per-org-api-manual.component";
import { RiskGetRiskAndControlDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/risk/risk-get-risk-and-control-data-api-manual.component";
import { RiskGetRiskControlAndMoiDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/risk/risk-get-risk-control-and-moi-data-api-manual.component";
import { RiskGetRiskDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/risk/risk-get-risk-data-api-manual.component";
import { ApiUsageManualComponent } from "./pages/manual-pages/apis/api-usage-manual/api-usage-manual.component";
import { PdfManualComponent } from "./pages/manual-pages/pdf-manual/pdf-manual.component";
import { ApiManualSectionComponent } from "./pages/manual-sections/ApiManualSection.component";
import { ManualDataService } from "./services/manual-data.service";
import { ControlSamplesNoEvidenceApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-samples-no-evidence-api-manual.component";
import { ControlSamplesScoreApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-samples-score-api-manual.component";
import { ControlTestStepsScoreApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/control/control-teststeps-score-api-manual.component";
import { FrmFormsResultsApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/frm/frm-forms-results-api-manual";
import { TaskGetTaskDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/task/task-get-task-data-api-manual.component";
import { DirectDataApiManualComponent } from "./pages/manual-pages/apis/api-spec/types/directdata/directdata-api-manual.component";

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [
        ManualComponent,

        ApiUsageManualComponent,
        ApiPowerBIUsageComponent,

        RiskGetRiskDataApiManualComponent,
        RiskGetRiskAndControlDataApiManualComponent,
        RiskGetRiskControlAndMoiDataApiManualComponent,
        RiskGetAssessmentPerOrgDataApiManualComponent,

        ControlTestInfoApiManualComponent,
        ControlUploadSourceDocumentApiManualComponent,
        ControlGenerateSamplesApiManualComponent,
        ControlGetSamplesApiManualComponent,
        ControlSamplesNoEvidenceApiManualComponent,
        ControlSamplesUploadEvidenceApiManualComponent,
        ControlTestStepsUploadEvidenceApiManualComponent,
        ControlReadyForAssessmentApiManualComponent,
        ControlReadyForAssessmentApiManualComponent,
        ControlSamplesScoreApiManualComponent,
        ControlTestStepsScoreApiManualComponent,

        EventGetEventDataManualComponent,
        EventGetEventAndMoiDataManualComponent,
        EventGetDataBreachAndMoiDataManualComponent,

        FrGetFindingReportAssDataApiManualComponent,

        BimGetMoiDataApiManualComponent,
        BimGetMoiSummaryDataApiManualComponent,

        KriGetDataApiManualComponent,
        KriPostDataApiManualComponent,

        FrmFormsResultsApiManualComponent,

        TpaRequestExportApiManualComponent,
        TpaGetResultsApiManualComponent,
        TpaGetEvidenceExportApiManualComponent,

        TaskGetTaskDataApiManualComponent,

        ApiManualSectionComponent,
        PdfManualComponent,

        DirectDataApiManualComponent,
    ],
})
export class ManualModule {
    static forRoot(): ModuleWithProviders<ManualModule> {
        return {
            ngModule: ManualModule,
            providers: [ManualDataService],
        };
    }
}
