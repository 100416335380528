import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app/app.constants";
import { Observable, Subject, of } from "rxjs";
import { SettingsItem } from "common/models/settings/SettingsItem";
import { ApiDataService } from "./ApiDataService";
import { map } from "rxjs/operators";
import { ApplicationSettings } from "./settings/application-settings";

@Injectable()
export class SettingsDataService extends ApiDataService {
    private savedSubject = new Subject<number>();
    private cachedSettings = {};

    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "settings");
    }

    public getSettings(): Observable<SettingsItem[]> {
        return this.get<SettingsItem[]>().pipe(
            map((settings) => {
                for (const setting of settings) {
                    this.cachedSettings[setting.ID] = setting;
                }

                return settings;
            })
        );
    }

    public getSetting(settingId: ApplicationSettings): Observable<SettingsItem> {
        if (this.cachedSettings[settingId]) {
            return of(this.cachedSettings[settingId]);
        } else {
            const url = `/${settingId}`;
            return this.get<SettingsItem>(url).pipe(
                map((setting) => {
                    this.cachedSettings[setting.ID] = setting;
                    return setting;
                })
            );
        }
    }

    public updateSetting(model: SettingsItem): Observable<never> {
        const updateModel = {
            boolValue: model.BoolValue,
            textValue: model.SerializedOption ? undefined : model.TextValue,
            numberValue: model.NumberValue,
            serializedOption: model.SerializedOption ? model.SerializedOption : undefined,
        };

        const url = `/${model.ID}`;
        const observable = this.put(updateModel, url);

        observable.pipe(
            map((model: SettingsItem) => {
                this.cachedSettings[model.ID] = model;
                this.savedSubject.next(model.ID);

                return model;
            })
        );

        return observable;
    }

    public getSavedChangeObservable(): Observable<number> {
        return this.savedSubject.asObservable();
    }
}
