import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { CerrixRight } from "@enums/authorization/CerrixRight";
import { ModuleType } from "@enums/ModuleType";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { NgxMasonryComponent } from "ngx-masonry";
import { tap } from "rxjs/operators";
import { RightDescriptionComponent } from "../right-description/right-description.component";
import { UserDetailModel } from "../shared/user-detail-model";
import { UserModuleRoleModel, UserModuleRoles } from "../shared/user-module-roles-model";

@Component({
    selector: "role-assign",
    templateUrl: "./role-assign.component.html",
    styleUrls: ["./role-assign.component.scss"],
})
export class RoleAssignComponent implements OnInit {
    constructor(private _standingDataDs: StandingdataDataService) {}

    @Input() allowChange = false;
    @Input() userModel: UserDetailModel;
    @Input() moduleRoles: UserModuleRoles[];

    @ViewChild("rightDescription", { static: true }) rightDescription: RightDescriptionComponent;
    @ViewChild(NgxMasonryComponent, { static: false }) masonry: NgxMasonryComponent;
    @ViewChild(NgxMasonryComponent, { read: ElementRef, static: false })
    masonryElementRef: ElementRef;

    filterText = "";

    slideOverRole: UserModuleRoleModel;

    moduleTypes = ModuleType;
    imSupervisors: Promise<IdNameCombination[]>;
    imSupervisorsLoading = false;

    ngOnInit() {
        if (
            this.moduleRoles.findIndex((mr) => mr.module == ModuleType.IM) >= 0 &&
            this.userModel &&
            (this.allowChange || this.userModel.supervisorId)
        ) {
            this.imSupervisorsLoading = true;
            this.imSupervisors = this._standingDataDs
                .getUsers(CerrixRight.ImSupervisorAssignable)
                .pipe(tap(() => (this.imSupervisorsLoading = false)))
                .toPromise();
        }
    }

    roleClicked(role: UserModuleRoleModel) {
        if (!role.disabled && this.allowChange) {
            role.checked = !role.checked;
        }
    }

    openRightOverview(role: UserModuleRoleModel) {
        this.rightDescription.open(role.roleName, role.rights);
    }

    filter() {
        const filterKeywords =
            this.filterText && this.filterText.length > 0 && this.filterText[0] === "="
                ? [this.filterText.slice(1).trim()]
                : this.filterText
                      .toLowerCase()
                      .split(" ")
                      .map((fk) => fk.trim())
                      .filter((fk) => fk.length > 0);

        this.moduleRoles.forEach((module) => {
            module.hidden = true;
            module.roles.forEach((role) => {
                const roleNameLowered = role.roleName.toLowerCase();
                const roleDescrLowered = role.roleDescription.toLowerCase();
                if (
                    filterKeywords.length === 0 ||
                    filterKeywords.some(
                        (fk) =>
                            roleNameLowered.indexOf(fk) >= 0 || roleDescrLowered.indexOf(fk) >= 0
                    )
                ) {
                    role.hidden = false;
                    module.hidden = false;
                } else {
                    role.hidden = true;
                }
            });
        });
    }

    imSupervisorChanged(supervisor: IdNameCombination) {
        if (supervisor) {
            this.userModel.supervisorId = supervisor.ID;
        } else {
            this.userModel.supervisorId = null;
        }
    }
}
