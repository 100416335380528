import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { toPromise } from "@methods/CommonMethods";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { TaskSeriesModel } from "@app/tasks/models/task-series-model";
import { TaskSeriesDataService } from "@app/tasks/services/task-series-data.service";
import {
    AgendaService,
    DayService,
    MonthAgendaService,
    MonthService,
    TimelineMonthService,
    TimelineViewsService,
    WeekService,
    WorkWeekService,
} from "@syncfusion/ej2-angular-schedule";
import { CerrixRecurrenceComponent } from "@app/shared/components/cerrix-recurrence/cerrix-recurrence.component";
import { RecurrenceViewModel } from "@app/shared/components/cerrix-recurrence/recurrence-view-model";
import { RecurrenceFrequency } from "@app/shared/components/cerrix-recurrence/cerrix-recurrence-frequency.enum";
import { NotificationService } from "@app/mailmessages/service/notification.service";
import { ModuleType } from "@enums/ModuleType";
import { TaskType } from "@app/tasks/enums/TaskType";
import { StandingDataHelper } from "@app/standingdata/shared/standingdata.helper";

@Component({
    selector: "task-series-detail-main",
    templateUrl: "./task-series-detail-main.component.html",
    styleUrls: ["./task-series-detail-main.component.scss"],
    providers: [
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        MonthAgendaService,
        TimelineViewsService,
        TimelineMonthService,
    ],
})
export class TaskSeriesDetailMainComponent implements OnInit {
    @Input() cerrixTab: TabModel;
    @Input() model: TaskSeriesModel;
    @Input() permissions: ControlPermissionModel;
    @ViewChild("recurrenceComponent")
    private recurrenceComponent: CerrixRecurrenceComponent;

    constructor(
        private _ds: TaskSeriesDataService,
        private _notificationService: NotificationService
    ) {}

    backupModel: TaskSeriesModel;

    formGroup: FormGroup;
    validResponsibles: IdNameCombination[];
    protected validReviewers: IdNameCombination[];
    validNotificationPeriods: IdNameCombination[];
    linkedTypes: IdNameCombination[];
    recurrenceViewModel: RecurrenceViewModel;

    taskScoreOptions = [
        {
            ID: 1,
            Name: "Required",
        },
        {
            ID: 2,
            Name: "Not required",
        },
        {
            ID: 3,
            Name: "No (not visible)",
        },
    ];

    async ngOnInit(): Promise<void> {
        this.backupModel = JSON.parse(JSON.stringify(this.model));
        this.recurrenceViewModel = this.ConvertTaskSeriesRecurrenceToViewModel();
        this.validResponsibles = await toPromise(
            this._ds.getResponsibles(this.model.responsibleIds)
        );
        this.validReviewers = await toPromise(this._ds.getReviewers(this.model.reviewerIds));
        this.validNotificationPeriods = (
            await toPromise(this._notificationService.getNotificationStandingData(ModuleType.Tasks))
        ).periods;
        this.linkedTypes = [{ ID: TaskType.ControlExecution, Name: "Control execution" }];

        await this.initFormGroup();

        this.cerrixTab.showLoader = false;
    }

    async initFormGroup() {
        const responsibleIdsFormControl = new FormControl(
            this.model.responsibleIds,
            Validators.required
        );
        const reviewerIdsFormControl = new FormControl(this.model.reviewerIds);
        this.formGroup = new FormGroup({
            name: new FormControl(this.model.name, Validators.required),
            description: new FormControl(this.model.description),
            responsibleIds: responsibleIdsFormControl,
            reviewerIds: reviewerIdsFormControl,
            linkedTypes: new FormControl(this.model.linkedTypes, Validators.required),
            notificationPeriodIds: new FormControl(this.model.notificationPeriodIds),
            taskScoreOption: new FormControl(this.model.taskScoreOption, Validators.required),
        });
        this.formGroup.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(this.formGroup, this.model);
        });

        // User can't be responsible and reviewer at the same time
        responsibleIdsFormControl.valueChanges.subscribe((v) => {
            this.validReviewers = StandingDataHelper.getOptionsDisabledByIds(
                this.validReviewers,
                v
            );
        });
        reviewerIdsFormControl.valueChanges.subscribe((v) => {
            this.validResponsibles = StandingDataHelper.getOptionsDisabledByIds(
                this.validResponsibles,
                v
            );
        });

        this.validResponsibles = StandingDataHelper.getOptionsDisabledByIds(
            this.validResponsibles,
            this.model.reviewerIds
        );
        this.validReviewers = StandingDataHelper.getOptionsDisabledByIds(
            this.validReviewers,
            this.model.responsibleIds
        );
    }

    validateRecurrence() {
        return this.recurrenceComponent.validate();
    }

    private ConvertTaskSeriesRecurrenceToViewModel(): RecurrenceViewModel {
        const modelRecurrence = this.model.recurrence;
        let viewModel: RecurrenceViewModel = Object.assign(
            {},
            new RecurrenceViewModel(),
            modelRecurrence
        );
        if (
            modelRecurrence.frequency &&
            (modelRecurrence.frequency === RecurrenceFrequency.Monthly ||
                modelRecurrence.frequency === RecurrenceFrequency.Yearly)
        ) {
            if (!modelRecurrence.byMonthDay || modelRecurrence.byMonthDay == 0) {
                viewModel.otherByDay = modelRecurrence.byDay;
                viewModel.otherByDayType = modelRecurrence.bySetPos;
                viewModel.radioSelection = "Other";
            }
        } else {
            viewModel.radioSelection = "Day";
        }
        return viewModel;
    }
}
