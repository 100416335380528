import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { MoiModel } from "@models/moi/MoiModel";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { nameof } from "@methods/jeffs-toolkit";
import { ComparisonType } from "@enums/workspace/ComparisonType";

@Component({
    selector: "moi-risks",
    templateUrl: "./moi-risks.component.html",
    styleUrls: ["./moi-risks.component.scss"],
})
export class MoiRisksComponent implements OnInit {
    @Input() model: MoiModel;
    @Input() disabled = false;

    activeFilters: SearchKeyword[] = [];

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    lastOrganizationId = 0;
    linked: RiskLinkModel[];
    unlinked: RiskLinkModel[];

    constructor(private moiDs: MoiDataService) {}

    ngOnInit(): void {
        const defaultOrganizationFilter = <SearchKeyword>{
            col: nameof<RiskLinkModel>((x) => x.organizationId),
            searchValue: this.model.OrganizationResponsible + "",
            specific: true,
            compareType: ComparisonType["Is equal to"],
        };

        this.activeFilters.push(defaultOrganizationFilter);
    }

    load() {
        if (
            !this.model ||
            !this.model.OrganizationResponsible ||
            this.lastOrganizationId === this.model.OrganizationResponsible
        ) {
            return;
        }

        this.isLoading = true;
        this.lastOrganizationId = this.model.OrganizationResponsible;

        this.moiDs.getLinkableRisks(this.model.Guid).subscribe((value) => {
            this.loadData(this.model.LinkedRisks, value.sortBy("name", true));
            this.isLoading = false;
        });
    }

    linkedChanged(linked: RiskLinkModel[]) {
        this.model.LinkedRisks = linked.map((x) => x.id);
        this.changed.emit(this.model.LinkedControls);
    }

    private loadData(linkedIds: number[], items: RiskLinkModel[]) {
        const linked = [],
            unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
