<div class="row" *ngIf="loaded">
    <div class="form-group col">
        <ng-select
            id="sourcedata-{{ field.ID }}"
            name="sourcedata-{{ field.ID }}"
            [multiple]="isMultiselect"
            [items]="field.SourceData"
            [(ngModel)]="selectedOptions"
            bindLabel="Name"
            bindValue="ID"
            [disabled]="readonly"
            (change)="emitChange()"
            (clear)="emitChange()"
            *ngIf="!isTreeSource"
            [closeOnSelect]="!isMultiselect"
        >
        </ng-select>
        <cerrix-select-tree
            [multiselect]="isMultiselect"
            [tree]="field.SourceData"
            [(value)]="selectedOptions"
            [readonly]="readonly"
            (onChange)="emitChange()"
            (onChangeSingle)="emitChange()"
            *ngIf="isTreeSource"
        >
        </cerrix-select-tree>
    </div>
</div>
