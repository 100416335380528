export enum ApplicationSettings {
    EffectivenessTestingReviewerRequired = 1,
    UseSimpleEffectiveness = 4,
    ShowCauseField = 6,
    ShowEffectField = 7,
    ShowQuantitativeScoringDetails = 8,
    UseRaciTable = 9,
    CustomerLogo = 12,
    AccentColor = 13,
    GenerateSamplesType = 41,
    ShowDashboardType = 51,
    UseControlAnalysis = 61,
    ShowOnlyEssentialInformation = 221,
    FeatureToggleFindingReportDetails = 733,
    FeatureToggleNewEffectivenessTestingScreens = 740,
    EnableTargetRisk = 760,
    EnableBudgetBasedRisk = 770,
    EnableAiAssistance = 989,
    UseIncidents = 11000,
    IncidentsUrl = 11001,
}
