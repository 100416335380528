<div class="space-evenly">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Gross risk matrix</div>
        <div class="detail-page-card-body padded">
            <div class="fancy-loader submodule" *ngIf="!grossMatrix"></div>
            <cerrix-riskmatrix
                [matrix]="grossMatrix"
                [highlightSelection]="false"
                *ngIf="grossMatrix"
            ></cerrix-riskmatrix>
        </div>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Net risk matrix</div>
        <div class="detail-page-card-body padded">
            <div class="fancy-loader submodule" *ngIf="!netMatrix"></div>
            <cerrix-riskmatrix
                [matrix]="netMatrix"
                [highlightSelection]="false"
                *ngIf="netMatrix"
            ></cerrix-riskmatrix>
        </div>
    </div>
</div>
