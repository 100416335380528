import { Component, Input, OnInit } from "@angular/core";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { NavigatorHyperlinkModel } from "@app/navigators/models/navigator-hyperlinks-model";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TargetModule } from "@enums/document/TargetModule";
import { toPromise } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "business-hyperlinks",
    templateUrl: "./business-hyperlinks.component.html",
})
export class BusinessHyperlinksComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissions: BusinessNavigatorPermissionModel;
    @Input() cerrixTab: TabModel;

    canEdit = true;
    isDirty = false;
    hyperlinkModel: NavigatorHyperlinkModel = {parentId: null, hyperlinks: [] };
    targetModule = TargetModule.BusinessDimension;

    hyperlinks: HyperlinkModel[] = [];

    public hyperlinksLoaded = false;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    ngOnInit() {}

    async loadHyperlinks() {
        if (this.requestModel.businessID < 1) {
            return;
        }

        this.hyperlinks = await toPromise(this._businessDs.getHyperlinks(this.requestModel));
        this.hyperlinksLoaded = true;
    }

    saveHyperlinks() {
        const prompt = this._promptService.loader("Saving hyperlinks!");

        this.hyperlinkModel.parentId = this.requestModel.businessID;
        this.hyperlinkModel.hyperlinks = this.hyperlinks;

        return toPromise(this._businessDs.storeHyperlinks(this.hyperlinkModel))
            .then((_) => {
                this._toastr.success("Save successful", "Success");
            })
            .finally(() => {
                prompt.close();
                this.cerrixTab.refresh()
            });
    }

    checkDirty(items): void {
        this.hyperlinks = items;
        TabComponentHelper.toggleTabDirty(this.cerrixTab, "menuItemHyperlinks", true);
        this.isDirty = true;
    }
}
