declare var $: any;
import FroalaEditor from "froala-editor";

export function RegisterPdfButton() {
    // Define an icon.
    FroalaEditor.DefineIcon("pdfIcon", { NAME: "file-pdf", template: "font_awesome_5" });

    // Define a button.
    FroalaEditor.RegisterCommand("insertPdf", {
        title: "Insert PDF",
        icon: "pdfIcon",
        focus: true,
        showOnMobile: true,

        // Called when the button is hit.
        callback: function () {
            // The current context is the editor instance.
            const id = $(this.el).parents(".fr-box")[0].id;
            window.postMessage(id + "|PDFCLICKED", "*");
        },
    });
}
