<div class="form-entry-fileupload">
    <document-manager-old
        [(Documents)]="answer"
        [ShowType]="true"
        [AllowAdd]="!readonly"
        [AllowEdit]="!readonly"
        [AllowDelete]="!readonly"
        [scrollEnabled]="true"
        (DocumentsChange)="changeTrigger()"
        Label=""
        MaxHeight="500px"
    >
    </document-manager-old>
</div>
