<div class="cerrix-collapsing-box" [class.is-collapsed]="isCollapsed">
    <div class="cerrix-collapsing-box-title-bar" *ngIf="titleTemplate">
        <ng-container
            [ngTemplateOutlet]="titleTemplate"
            [ngTemplateOutletContext]="{ toggleCollapse: toggleCollapse, isCollapsed: isCollapsed }"
        ></ng-container>
    </div>

    <div class="cerrix-collapsing-box-title-bar" (click)="toggleCollapse()" *ngIf="!titleTemplate">
        <ng-container>
            <div class="cerrix-collapsing-box-title">{{ boxTitle }}</div>
            <div class="cerrix-collapsing-box-collapse-button">
                <i class="fas fa-chevron-{{ isCollapsed ? 'down' : 'up' }}"></i>
            </div>
        </ng-container>
    </div>

    <div class="cerrix-collapsing-box-content" *ngIf="!isCollapsed">
        <ng-container
            [ngTemplateOutlet]="contentTemplate"
            [ngTemplateOutletContext]="{ toggleCollapse: toggleCollapse, isCollapsed: isCollapsed }"
        ></ng-container>
    </div>
</div>
