import { ControlDetailOverviewDataService } from "@app/controls/detail-overview/control-detail-overview.data.service";
import { Component, Input, OnInit } from "@angular/core";
import { ControlDetailOverviewModel } from "@app/controls/detail-overview/ControlDetailOverviewModel";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { Observable } from "rxjs";

@Component({
    selector: "eff-control-info-box",
    templateUrl: "./eff-control-info-box.component.html",
    styleUrls: ["./eff-control-info-box.component.scss"],
})
export class EffControlInfoBoxComponent implements OnInit {
    @Input() controlGuid: string;
    @Input() panelOpenState: boolean = false;

    controlInfo$: Observable<ControlDetailOverviewModel> = undefined;

    constructor(
        private _pages: Pages,
        private _tabService: TabService,
        private _controlService: ControlDetailOverviewDataService
    ) {}

    async ngOnInit() {
        await this.loadControlInfo();
    }

    async loadControlInfo() {
        if (this.controlInfo$) return;

        this.controlInfo$ = await this._controlService.getControlOverview(this.controlGuid);
    }

    openControl() {
        this._tabService.generateTab(this._pages.ControlDetail, this.controlGuid);
    }

    openEffOverview() {
        this._tabService.generateTab(
            this._pages.ControlsTestingAdvEffControlOverview,
            this.controlGuid
        );
    }
}
