import { Component, OnInit, Input } from "@angular/core";
import { ProcessEditorComponent } from "../../process-editor.component";
import { ColorEvent } from "ngx-color";
import { Actions, ActionEnum } from "../../actions";
import { MenuItemBuilder, ContextMenuItem } from "@app/shared/contextmenu/menu-item";
import { GraphEditor } from "../../grapheditor";
import { ProcessEditorPrintType } from "@app/process-editor/enums/ProcessEditorPrintType";

interface DropdownData {
    label: string;
    value?: number | string;
}

@Component({
    selector: "process-editor-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
    @Input() actions: Actions;
    @Input() fullscreenContainer: HTMLDivElement;
    @Input() processEditor: ProcessEditorComponent;
    @Input() readOnly: boolean;

    isContainerFullscreen = false;
    private fillColorDebouncer: number;
    private strokeColorDebouncer: number;
    private textColorDebouncer: number;

    fontSizeData: DropdownData[] = [
        { label: "6" },
        { label: "8" },
        { label: "9" },
        { label: "10" },
        { label: "12" },
        { label: "14" },
        { label: "18" },
        { label: "24" },
        { label: "30" },
        { label: "36" },
        { label: "48" },
        { label: "60" },
    ];

    fontFamilyData: DropdownData[] = [
        { label: "Arial", value: "Arial" },
        { label: "Calibri", value: "Calibri" },
        { label: "Cambria", value: "Cambria" },
        { label: "Courier New", value: "Courier New" },
        { label: "Garamond", value: "Garamond" },
        { label: "Helvetica", value: "Helvetica" },
        { label: "Times New Roman", value: "Times New Roman" },
        { label: "Verdana", value: "Verdana" },
    ];

    selectedFontSize: DropdownData;
    selectedFontFamily: DropdownData;
    isEdgeSelected: boolean;

    alignMenuData = this._menuItemBuilder
        .appendItem(
            "Left",
            () => {
                this.align(this.processEditor.mxDeps.mxConstants.ALIGN_LEFT);
            },
            null,
            "fas fa-align-left"
        )
        .appendItem(
            "Center",
            () => {
                this.align(this.processEditor.mxDeps.mxConstants.ALIGN_CENTER);
            },
            null,
            "fas fa-align-center"
        )
        .appendItem(
            "Right",
            () => {
                this.align(this.processEditor.mxDeps.mxConstants.ALIGN_RIGHT);
            },
            null,
            "fas fa-align-right"
        )
        .appendDivider()
        .appendItem(
            "Top",
            () => {
                this.verticalAlign(this.processEditor.mxDeps.mxConstants.ALIGN_TOP);
            },
            null,
            "fas fa-arrow-to-top"
        )
        .appendItem(
            "Middle",
            () => {
                this.verticalAlign(this.processEditor.mxDeps.mxConstants.ALIGN_MIDDLE);
            },
            null,
            "fas fa-arrows-v"
        )
        .appendItem(
            "Bottom",
            () => {
                this.verticalAlign(this.processEditor.mxDeps.mxConstants.ALIGN_BOTTOM);
            },
            null,
            "fas fa-arrow-to-bottom"
        )
        .build();

    connectionMenuData = this._menuItemBuilder
        .appendItem(
            "Line",
            () => {
                this.changeEdgeShape();
            },
            null,
            "far fa-long-arrow-alt-right"
        )
        .appendItem(
            "Link",
            () => {
                this.changeEdgeShape("link");
            },
            null,
            "fal fa-grip-lines"
        )
        .appendItem(
            "Arrow",
            () => {
                this.changeEdgeShape("flexArrow");
            },
            null,
            "fal fa-arrow-alt-right"
        )
        .appendItem(
            "Simple Arrow",
            () => {
                this.changeEdgeShape("arrow");
            },
            null,
            "fas fa-align-justify"
        )
        .build();

    waypointsMenuData = this._menuItemBuilder
        .appendItem(
            "Straight",
            () => {
                this.changeEdgeStyle(this.processEditor.mxDeps.mxConstants.EDGESTYLE_SIDETOSIDE);
            },
            null,
            "fal fa-horizontal-rule"
        )
        .appendItem(
            "Orthogonal",
            () => {
                this.changeEdgeStyle(this.processEditor.mxDeps.mxConstants.EDGESTYLE_ORTHOGONAL);
            },
            null,
            "fal fa-horizontal-rule"
        )
        .appendItem(
            "Simple",
            () => {
                this.changeEdgeStyle(this.processEditor.mxDeps.mxConstants.EDGESTYLE_ELBOW);
            },
            null,
            "fal fa-horizontal-rule"
        )
        .appendItem(
            "Simple (Vertical)",
            () => {
                this.changeEdgeStyle(this.processEditor.mxDeps.mxConstants.EDGESTYLE_ELBOW, true);
            },
            null,
            "fal fa-horizontal-rule"
        )
        .appendItem(
            "Curved",
            () => {
                this.changeEdgeStyle(
                    this.processEditor.mxDeps.mxConstants.EDGESTYLE_ORTHOGONAL,
                    true,
                    true
                );
            },
            null,
            "fal fa-wave-sine"
        )
        .appendItem(
            "Relational",
            () => {
                this.changeEdgeStyle(
                    this.processEditor.mxDeps.mxConstants.EDGESTYLE_ENTITY_RELATION,
                    true
                );
            },
            null,
            "fal fa-wave-sine"
        )
        .build();

    printMenuData = this.buildPrintButtons(false);

    previewMenuData = this.buildPrintButtons(true);

    private buildPrintButtons(preview: boolean): ContextMenuItem[] {
        return this._menuItemBuilder
            .appendItem(
                "Graph",
                () => this.processEditor.print(preview, ProcessEditorPrintType.GraphOnly),
                null,
                "fas fa-print-search"
            )
            .appendItem(
                "Graph, Risks, Controls, Events and MoIs",
                () => this.processEditor.print(preview, ProcessEditorPrintType.GraphAndData),
                null,
                "fas fa-print-search"
            )
            .appendItem(
                "Graph with linked Risks and Controls",
                () =>
                    this.processEditor.print(preview, ProcessEditorPrintType.GraphRisksAndControls),
                null,
                "fas fa-print-search"
            )
            .build();
    }

    get actionEnum() {
        return ActionEnum;
    }

    constructor(private _menuItemBuilder: MenuItemBuilder) {}

    ngOnInit() {
        document.addEventListener("fullscreenchange", () => {
            if (document.fullscreenElement) {
                this.isContainerFullscreen = true;
            } else {
                this.isContainerFullscreen = false;
            }
        });
        const graph = this.processEditor.graph;
        graph.getSelectionModel().addListener(graph.dependencies.mxEvent.CHANGE, () => {
            this.selectionChanged(graph);
        });
    }

    changeTextColor(event: ColorEvent) {
        if (this.textColorDebouncer) {
            clearTimeout(this.textColorDebouncer);
            this.textColorDebouncer = null;
        }
        this.textColorDebouncer = window.setTimeout(() => {
            const mx = this.processEditor.mxDeps;
            this.changeColor(mx.mxConstants.STYLE_FONTCOLOR, event.color.hex);
        }, 50);
    }

    changeStrokeColor(event: ColorEvent) {
        if (this.strokeColorDebouncer) {
            clearTimeout(this.strokeColorDebouncer);
            this.strokeColorDebouncer = null;
        }
        this.strokeColorDebouncer = window.setTimeout(() => {
            const mx = this.processEditor.mxDeps;
            this.changeColor(mx.mxConstants.STYLE_STROKECOLOR, event.color.hex);
        }, 50);
    }

    changeFillColor(event: ColorEvent) {
        if (this.fillColorDebouncer) {
            clearTimeout(this.fillColorDebouncer);
            this.fillColorDebouncer = null;
        }
        this.fillColorDebouncer = window.setTimeout(() => {
            const mx = this.processEditor.mxDeps;
            this.changeColor(mx.mxConstants.STYLE_FILLCOLOR, event.color.hex);
            this.setOpacity(event.color.rgb.a * 100);
        }, 50);
    }

    private changeColor(colorConstant: string, hexColor: string) {
        const selectedCells = this.processEditor.graph.getSelectionCells();
        if (selectedCells) {
            const mx = this.processEditor.mxDeps;
            mx.mxUtils.setCellStyles(
                this.processEditor.graph.getModel(),
                selectedCells,
                colorConstant,
                hexColor
            );
            this.processEditor.graph.refresh();
        }
    }

    setOpacity(opacity: number) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        this.processEditor.graph.setCellStyles(mxConstants.STYLE_OPACITY, opacity);
    }

    changeEdgeShape(constant?: string) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        const edges = this.processEditor.graph.getSelectionCells().filter((c) => c.isEdge());
        if (!edges.empty()) {
            this.processEditor.graph.setCellStyles(mxConstants.STYLE_SHAPE, constant, edges);
        }
    }

    changeEdgeStyle(constant?: string, isVertical?: boolean, isCurved?: boolean) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        const edges = this.processEditor.graph.getSelectionCells().filter((c) => c.isEdge());
        if (!edges.empty()) {
            this.processEditor.graph.setCellStyles(mxConstants.STYLE_EDGE, constant, edges);
            this.processEditor.graph.setCellStyles(
                mxConstants.STYLE_ELBOW,
                isVertical ? mxConstants.ELBOW_VERTICAL : mxConstants.ELBOW_HORIZONTAL,
                edges
            );
            this.processEditor.graph.setCellStyles(
                mxConstants.STYLE_CURVED,
                isCurved ? "1" : null,
                edges
            );
        }
    }

    changeFontSize(data: DropdownData) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        this.processEditor.graph.setCellStyles(mxConstants.STYLE_FONTSIZE, data.label);
    }

    changeFontFamily(data: DropdownData) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        this.processEditor.graph.setCellStyles(mxConstants.STYLE_FONTFAMILY, data.value);
    }

    align(constant: string) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        this.processEditor.graph.setCellStyles(mxConstants.STYLE_ALIGN, constant);
    }

    verticalAlign(constant: string) {
        const mxConstants = this.processEditor.mxDeps.mxConstants;
        this.processEditor.graph.setCellStyles(mxConstants.STYLE_VERTICAL_ALIGN, constant);
    }

    toggleFullScreen() {
        if (this.fullscreenContainer) {
            if (!this.isContainerFullscreen) {
                this.fullscreenContainer.requestFullscreen().then(() => {
                    this.isContainerFullscreen = true;
                });
            } else {
                document.exitFullscreen().then(() => {
                    this.isContainerFullscreen = false;
                });
            }
        }
    }

    selectionChanged(graph: GraphEditor) {
        const cell = graph.getSelectionCell();

        if (cell) {
            const style = graph.getCellStyle(cell);
            const mxConstants = graph.dependencies.mxConstants;
            this.selectedFontFamily =
                this.fontFamilyData.find(
                    (dd) => dd.value === style[mxConstants.STYLE_FONTFAMILY]
                ) || this.fontFamilyData[0];
            this.selectedFontSize =
                this.fontSizeData.find((dd) => dd.value === style[mxConstants.STYLE_FONTSIZE]) ||
                this.fontSizeData[4];
            this.isEdgeSelected = cell.isEdge();
        } else {
            this.selectedFontSize = null;
            this.selectedFontFamily = null;
            this.isEdgeSelected = false;
        }
    }
}
