import { Component, Input, OnInit } from "@angular/core";
import { WorkflowHistoryModel } from "../../shared/models";
import { AdvEffWorkflowDataService } from "../../shared/services";

@Component({
    selector: "eff-detail-workflow-history",
    templateUrl: "./eff-detail-workflow-history.component.html",
    styleUrls: ["./eff-detail-workflow-history.component.scss"],
})
export class EffDetailWorkflowHistoryComponent implements OnInit {
    @Input() effectivenessGuid: string;

    workflowHistoryData: WorkflowHistoryModel[];

    constructor(private _ds: AdvEffWorkflowDataService) {}

    ngOnInit(): void {
        this._ds.getWorkflowHistory(this.effectivenessGuid).subscribe((data) => {
            this.workflowHistoryData = data.reverse();
        });
    }
}
