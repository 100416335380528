import { Component, OnInit, Input } from "@angular/core";
import { UserStandingdataModel } from "@app/user/user-maintenance/shared/user-standingdata-model";
import { UserMaintenancePermissionModel } from "@models/permissions/UserMaintenancePermissionModel";
import { UserDetailModel } from "@app/user/user-maintenance/shared/user-detail-model";

@Component({
    selector: "user-info",
    templateUrl: "./user-info.component.html",
    styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnInit {
    @Input() user: UserDetailModel;
    @Input() sd: UserStandingdataModel;
    @Input() permissions: UserMaintenancePermissionModel;

    constructor() {}

    ngOnInit() {}
}
