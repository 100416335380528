import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sortby",
})
export class SortbyPipe implements PipeTransform {
    transform(array: any, sortBy: string, order?: string): any[] {
        const sortOrder = order && order == "desc" ? false : true; // setting default ascending order

        return array.sortBy(sortBy, sortOrder);
    }
}
