import { RendererType } from "../../enums/RendererType";
import { FormatType } from "../../enums/FormatType";

export class RendererConfig {
    textColumn: string;
    hideColumn?: boolean;
    actionColumn?: string;
    hideActionColumn?: boolean;
    actionValue?: any;
    type: RendererType;
    columnWidth?: number;
    formatType?: FormatType;
    extraValue?: string;

    /** Used to make percentages easier to use:
     * 1 (default) takes over the number right away (eg. 50 -> 50%).
     * 0.01 makes it behave like a normal percentage (eg. 0.5 -> 50%).
     */
    percentMultiplier?: number;

    constructor() {
        this.hideColumn = false;
        this.actionColumn = "";
        this.actionValue = "";
        this.hideActionColumn = false;
    }

    public static createDateConfig(textColumn: string): RendererConfig {
        return {
            textColumn: textColumn,
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        };
    }

    public static createShortTimeConfig(textColumn: string): RendererConfig {
        return {
            textColumn: textColumn,
            type: RendererType.Default,
            formatType: FormatType.DateShortTimeFormat,
        };
    }

    public static createScoreConfig(textColumn: string, actionColumn: string): RendererConfig {
        return {
            textColumn: textColumn,
            type: RendererType.Score,
            actionColumn: actionColumn,
            hideActionColumn: true,
        };
    }

    public static createOverdueConfig(textColumn: string, actionColumn?: string): RendererConfig {
        let rendererConfig: RendererConfig = {
            textColumn: textColumn,
            type: RendererType.DueDate,
            formatType: FormatType.DateFormat,
        };

        if (actionColumn) {
            rendererConfig.actionColumn = actionColumn;
            rendererConfig.hideActionColumn = true;
            rendererConfig.actionValue = "True";
        }

        return rendererConfig;
    }
}
