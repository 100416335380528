<div class="detail-page-card">
    <div class="detail-page-card-title" *ngIf="!config.hideHeader">{{ config.name }}</div>
    <div class="detail-page-card-body padded cerrix-control-sm" *ngIf="!ready">
        <div class="fancy-loader submodule"></div>
    </div>
    <div
        class="detail-page-card-body padded"
        *ngIf="ready && (!tableData || tableData.length == 0)"
    >
        <small *ngIf="config.emptyMessage">{{ config.emptyMessage }}</small>
        <small *ngIf="!config.emptyMessage">No {{ config.name | lowercase }} available.</small>
    </div>
    <div
        class="detail-page-card-body overflow-auto"
        *ngIf="scopedTableData && scopedTableData.length > 0"
    >
        <table class="table-cerrix rounded striped bordered-columns reset table-hoverable">
            <thead>
                <tr #theadRow>
                    <th class="small-header"></th>
                    <ng-template ngFor let-field [ngForOf]="config.fields">
                        <th class="text-center" *ngIf="!field.hideInOverview">
                            {{ field.prettyName ? field.prettyName : field.fieldName }}
                        </th>
                    </ng-template>
                    <th *ngIf="config.overviewRowActions"></th>
                </tr>
            </thead>
            <tbody>
                <ng-template
                    ngFor
                    let-outerData
                    [ngForOf]="scopedTableData"
                    let-even="even"
                    let-odd="odd"
                >
                    <tr
                        class="row-hover"
                        [ngClass]="{
                            highlighted: activeRow && activeRow[idProp] == outerData[idProp],
                            'even-row': even,
                            'odd-row': odd
                        }"
                        (click)="triggerClick(outerData)"
                    >
                        <td
                            class="text-center small-header"
                            (click)="
                                getLinkedIds(outerData);
                                outerData.showEditor = !outerData.showEditor
                            "
                        >
                            <div *ngIf="config.innerConfig">
                                <span>
                                    <i
                                        class="far"
                                        [class.fa-minus-square]="outerData.showEditor"
                                        [class.fa-plus-square]="!outerData.showEditor"
                                    ></i>
                                </span>
                            </div>
                        </td>
                        <ng-template ngFor let-field [ngForOf]="config.fields">
                            <td class="text-truncate text-center" *ngIf="!field.hideInOverview">
                                <workspace-cell
                                    [configs]="config.rendererConfig"
                                    [row]="outerData"
                                    [header]="field.fieldName"
                                >
                                </workspace-cell>
                            </td>
                        </ng-template>
                        <td
                            class="text-center"
                            *ngIf="config.overviewRowActions as overviewRowActions"
                        >
                            <span *ngFor="let action of overviewRowActions">
                                <i
                                    class="{{ action.icon }}"
                                    matTooltip="{{ action.tooltip }}"
                                    (click)="action.clickEvent(outerData)"
                                >
                                </i>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="outerData.showEditor && config.innerConfig" class="inner-table">
                        <td [colSpan]="theadRow.childElementCount">
                            <cerrix-table-multi-level
                                *ngIf="setupInnerconfig(outerData) as innerConfig"
                                [config]="innerConfig"
                            >
                            </cerrix-table-multi-level>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
        <div
            class="custom-pagination"
            (click)="toggleScopedTableData()"
            *ngIf="scopedToggleAllowed"
        >
            View
            {{
                scopedTableExpanded
                    ? "last " + this.config.limitViewTo
                    : "all " + this.config._visibleRowCount
            }}
        </div>
    </div>
</div>
