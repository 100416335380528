import { OnInit, Component, Input } from "@angular/core";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { NotificationService } from "@app/mailmessages/service/notification.service";
import { NotificationModel } from "@app/mailmessages/models/notificationModel";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "uc-notification-overview",
    templateUrl: "./notification-overview.component.html",
    styleUrls: ["./notification-overview.component.scss"],
})
export class NotificationOverviewComponent implements OnInit {
    @Input() module: ModuleType;

    config: GenericListConfig;

    constructor(
        private _tabService: TabService,
        private _mmService: NotificationService,
        public pages: Pages
    ) {}

    ngOnInit() {
        this._mmService.getNotifications(this.module).subscribe((data) => {
            this.setupConfig(data);
        });
    }

    setupConfig(data: NotificationModel[]) {
        this.config = <GenericListConfig>{
            name: "Notifications",
            data: data,
            idProp: "id",

            allowAdd: true,
            allowEdit: true,
            allowDelete: false,

            fields: [
                {
                    fieldName: "name",
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: "subject",
                    prettyName: "Subject",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "active",
                    prettyName: "Send Active",
                    fieldType: GenericListFieldType.CheckBox,
                },
            ],
            addOverride: () => {
                const config = {
                    module: this.module,
                };
                this._tabService.generateTab(
                    this.pages.NotificationDetail,
                    0,
                    "New notification",
                    config
                );
            },
            editOverride: (row: NotificationModel) => {
                const config = {
                    module: this.module,
                };
                this._tabService.generateTab(
                    this.pages.NotificationDetail,
                    row.id,
                    row.name,
                    config
                );
            },
        };
    }

    openNotification(item: NotificationModel) {
        const config = {
            module: this.module,
        };
        this._tabService.generateTab(this.pages.NotificationDetail, item.id, item.name, config);
    }

    add() {
        const config = {
            module: this.module,
        };
        this._tabService.generateTab(this.pages.NotificationDetail, 0, "New notification", config);
    }
}
