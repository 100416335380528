<div class="modal-header">
    <h4 class="modal-title pull-left">Audit sampler</h4>
</div>
<div class="modal-body">
    <form [formGroup]="formGroup" *ngIf="formGroup">
        <div class="row">
            <div class="col-6 left-block">
                <div class="form-group">
                    <label>Sample type</label>
                    <ng-select
                        bindValue="ID"
                        bindLabel="Name"
                        [items]="sampleTypes"
                        [clearable]="false"
                        [(ngModel)]="sampleModel.sampleType"
                        [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                </div>
                <div class="form-group" [hidden]="sampleModel.sampleType != sampleTypeEnum.number">
                    <label>Test population size</label>
                    <input
                        type="number"
                        min="1"
                        class="form-control form-control-sm"
                        formControlName="testPopulationSize"
                    />
                </div>
                <div class="row" [hidden]="sampleModel.sampleType != sampleTypeEnum.date">
                    <div class="form-group col">
                        <label>Start date</label>
                        <date-input formControlName="startDate"></date-input>
                    </div>
                    <div class="form-group col">
                        <label>End date</label>
                        <date-input formControlName="endDate"></date-input>
                    </div>
                </div>
            </div>
            <div class="col-6 right-block">
                <div class="form-group">
                    <label>Seed number</label>
                    <input
                        type="number"
                        class="form-control form-control-sm"
                        formControlName="seedNumber"
                    />
                </div>
                <div class="form-group">
                    <label>Number of test samples</label>
                    <input
                        type="number"
                        class="form-control form-control-sm"
                        formControlName="numberOfSamples"
                    />
                </div>
            </div>
        </div>

        <div class="form-group-separator" *ngIf="allValidations.length > 0"></div>
        <div class="red" *ngIf="allValidations.length > 0">
            <p *ngFor="let validation of allValidations">
                {{ validation }}
            </p>
        </div>

        <div class="form-group-separator" *ngIf="generatedSamples"></div>
        <div class="form-group" *ngIf="generatedSamples">
            <label>Result</label>
            <textarea
                class="form-control form-control-sm"
                readonly
                [(ngModel)]="generatedSamples"
                [ngModelOptions]="{ standalone: true }"
                inputTooltip
            ></textarea>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-cerrix" (click)="generate()" [disabled]="isGenerating">
        Generate
        <i class="fas fa-circle-notch fa-spin ms-2" *ngIf="isGenerating"></i>
    </button>
    <button class="btn btn-dark" (click)="modalRef.hide()">Close</button>
</div>
