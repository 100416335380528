<div class="detail-page-card" *ngIf="user">
    <div class="detail-page-card-title">Role groups</div>
    <div class="detail-page-card-actions">
        <ng-select
            data-uiid="usermanagement-roles-rolegroups"
            id="roleGroupsSelect"
            name="roleGroupsSelect"
            [items]="sd.roleGroups"
            [(ngModel)]="user.roleGroups"
            bindLabel="name"
            bindValue="ID"
            [disabled]="!permissions.canAssignGroups"
            [multiple]="true"
            [clearable]="true"
            [loading]="!sd.roleGroups"
            [closeOnSelect]="false"
            (change)="roleGroupChanged()"
        >
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <div>
                    <b>{{ item.name }}</b>
                    <small class="text-muted" *ngIf="item.description">
                        - {{ item.description }}</small
                    >
                </div>
                <ng-container *ngFor="let moduleRole of item.moduleRoles">
                    <ng-container *ngFor="let role of moduleRole.roles">
                        <span
                            class="badge bg-warning py-1 me-1"
                            matTooltip="Click to see a detailed view."
                            (click)="openRightOverview(role); $event.stopPropagation()"
                            *ngIf="role.checked"
                        >
                            {{ role.roleName }} ({{ role.rights.length }} right{{
                                role.rights.length === 1 ? "" : "s"
                            }})
                        </span>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ng-select>
    </div>
</div>

<role-assign
    data-uiid="usermanagement-roles-roleassign"
    [moduleRoles]="user.moduleRoles"
    [(userModel)]="user"
    [allowChange]="permissions.canAssignRoles"
    #roleAssign
></role-assign>
