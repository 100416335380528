<mat-card>
    <mat-card-header>
        <mat-card-title>All available presets</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
        <mat-grid-list rowHeight="2:1" gutterSize="1rem" cols="4">
            <mat-grid-tile *ngFor="let preset of presets">
                <mat-card style="width: 100%">
                    <mat-card-header>
                        <mat-card-title>
                            <mat-icon *ngIf="preset.icon">{{ preset.icon }}</mat-icon>

                            {{ preset.name }}

                            <mat-chip disabled>rolegroup</mat-chip>
                        </mat-card-title>

                        <mat-card-subtitle>
                            <span *ngIf="preset.predefined; else userPreset">System preset</span>
                            <ng-template #userPreset>
                                <span>Preset created by {{ preset.createdBy }} </span>
                            </ng-template>

                            <span *ngIf="preset.modifiedOn && !preset.predefined"
                                >Last updated on {{ preset.modifiedOn | dateTimeDisplay }}</span
                            >
                        </mat-card-subtitle>
                    </mat-card-header>

                    <mat-card-content>
                        <p>{{ preset.description }}</p>
                    </mat-card-content>

                    <mat-card-actions>
                        <button
                            mat-icon-button
                            matTooltip="Apply"
                            showDelay="300"
                            [disabled]="preset.id === activePresetId"
                            (click)="applyPreset(preset.id)"
                        >
                            <mat-icon>play_circle_outline</mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            matTooltip="Set as default"
                            showDelay="300"
                            (click)="updateUserDefault(preset, !preset.userDefault)"
                            [color]="preset.userDefault ? 'primary' : ''"
                        >
                            <mat-icon>home_outline</mat-icon>
                        </button>

                        <button
                            *ngIf="!preset.predefined"
                            mat-icon-button
                            matTooltip="Public"
                            showDelay="300"
                            (click)="updatePublic(preset.id, !preset.public, preset.systemDefault)"
                            [color]="preset.public ? 'primary' : ''"
                        >
                            <mat-icon>public</mat-icon>
                        </button>

                        <button
                            mat-icon-button
                            matTooltip="Favourite preset"
                            showDelay="300"
                            (click)="updateFavourite(preset, !preset.favourite)"
                            [color]="preset.favourite ? 'primary' : ''"
                        >
                            <mat-icon>{{ preset.favourite ? "star" : "star_outline" }}</mat-icon>
                        </button>

                        <button
                            *ngIf="!preset.predefined && !preset.systemDefault"
                            mat-icon-button
                            matTooltip="Delete preset"
                            showDelay="300"
                            (click)="deletePreset(preset.id)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>

                        <button
                            *ngIf="canSetSystemDefault()"
                            mat-icon-button
                            matTooltip="System default"
                            showDelay="300"
                            (click)="updateSystemDefault(preset.id, !preset.systemDefault)"
                            [color]="preset.systemDefault ? 'primary' : ''"
                        >
                            <mat-icon>home_work_outline</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>

    <mat-card-footer> </mat-card-footer>
</mat-card>
