<ng-container *ngIf="!config.showLoader">
    <ng-container *ngIf="invalidConfig">
        <div class="centered-text">
            <span>No (or an invalid) report is selected.</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!invalidConfig && waitInput">
        <div class="centered-text input-actions">
            <div class="buttons">
                <button class="btn btn-primary" (click)="waitInput = false">
                    <i class="fas fa-file-download"></i>
                    <span>Load report</span>
                </button>

                <button class="btn btn-dark" (click)="openReport()">
                    <i class="fas fa-external-link-alt"></i>
                    <span>Open report</span>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!waitInput && reportID">
        <report-powerbi [id]="reportID"></report-powerbi>
    </ng-container>
</ng-container>
