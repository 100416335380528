import { SharedModule } from "@app/shared/shared.module";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserNotificationSettingsComponent } from "./user-notification-settings.component";

@NgModule({
    imports: [SharedModule, CommonModule],
    declarations: [UserNotificationSettingsComponent],
    exports: [UserNotificationSettingsComponent],
})
export class UserNotificationSettingsModule {
    static forRoot(): ModuleWithProviders<UserNotificationSettingsModule> {
        return {
            ngModule: UserNotificationSettingsModule,
            providers: [],
        };
    }
}
