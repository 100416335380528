import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SimpleTestingType } from "../../enums/SimpleTestingType";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { SimpleTestingStandingData } from "../../models/SimpleTestingStandingData";
import { SimpleTestingDataService } from "../../services/simple-testing.service";
import { SimpleTestingModel } from "../../models/SimpleTestingModel";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { Observable, of } from "rxjs";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { isObjectDirty } from "@methods/CommonMethods";
import { GenericListManagerComponent } from "@app/shared/generic-managers/generic-list-manager/generic-list-manager.component";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { SimpleTestingCatalogueUpdateModel } from "../../models/SimpleTestingCatalogueUpdateModel";
import { SimpleTestingPermissions } from "../../models/SimpleTestingPermissions";
import { nameof, setFormControlValue } from "@methods/jeffs-toolkit";
import { SimpleTestingScore } from "@app/controls/testing/simple/models/SimpleTestingScore";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";

@Component({
    selector: "simple-testing-main",
    templateUrl: "./simple-testing-main.component.html",
    styleUrls: ["./simple-testing-main.component.scss"],
})
export class SimpleTestingMainComponent implements OnInit {
    @Input() permissions: SimpleTestingPermissions;
    @Input() model: SimpleTestingModel;
    @Input() type: SimpleTestingType;
    @Input() typeDesc: string;
    @Input() testScoreConfig: GenericListConfig;

    formDirty = false;
    backupModel: SimpleTestingModel;

    standingData: SimpleTestingStandingData;
    simpleTestingGroup: FormGroup;

    @ViewChild("testScores") testScores: GenericListManagerComponent;

    constructor(private ds: SimpleTestingDataService) {}

    ngOnInit(): void {
        this.backupModel = JSON.parse(JSON.stringify(this.model));
        this.ds.getStandingData(this.type, this.model.ControlGuid).subscribe((x) => {
            this.standingData = x;
            this.setTestScoreFieldConfig();
            this.createGroup();
        });
    }

    createGroup(): void {
        this.simpleTestingGroup = new FormGroup(this.getFormControls(), {
            validators: [],
        });

        if (!this.permissions.CanEdit) {
            this.simpleTestingGroup.disable();
        }

        this.simpleTestingGroup.updateValueAndValidity();
        this.simpleTestingGroup.markAllAsTouched();
    }

    private getFormControls(): { [p: string]: AbstractControl } {
        let result: { [p: string]: AbstractControl } = {};
        result[nameof<SimpleTestingModel>((t) => t.TestActivity)] = new FormControl(
            this.model.TestActivity
        );
        result[nameof<SimpleTestingModel>((t) => t.TestNorm)] = new FormControl(
            this.model.TestNorm
        );
        result[nameof<SimpleTestingModel>((t) => t.TestSamples)] = new FormControl(
            this.model.TestSamples,
            Validators.min(0)
        );
        result[nameof<SimpleTestingModel>((t) => t.Testers)] = new FormControl(
            this.model.Testers,
            Validators.required
        );
        result[nameof<SimpleTestingModel>((t) => t.InitialTestDate)] = new FormControl(
            this.model.InitialTestDate,
            Validators.required
        );
        result[nameof<SimpleTestingModel>((t) => t.EvidenceUploaders)] = new FormControl(
            this.model.EvidenceUploaders,
            Validators.required
        );
        result[nameof<SimpleTestingModel>((t) => t.UploaderDueDate)] = new FormControl(
            this.model.UploaderDueDate,
            Validators.min(0)
        );
        result[nameof<SimpleTestingModel>((t) => t.LinkedStandard)] = new FormControl(
            this.model.LinkedStandard
        );
        result[nameof<SimpleTestingModel>((t) => t.QualityAspect)] = new FormControl(
            this.model.QualityAspect
        );
        result[nameof<SimpleTestingModel>((t) => t.TestFrequency)] = new FormControl(
            this.model.TestFrequency
        );

        const catalogueValidator =
            this.type === SimpleTestingType.DesignImplementation ? [Validators.required] : [];

        result[nameof<SimpleTestingModel>((t) => t.Catalogue)] = new FormControl(
            this.model.Catalogue,
            catalogueValidator
        );

        return result;
    }

    catalogueChanged(selected: IdNameCombination): void {
        if (!selected || !selected.ID) {
            return;
        }
        this.ds
            .getCatalogueValues(this.type, this.model.ControlGuid, selected.ID)
            .subscribe((x) => {
                this.loadCatalogueChangeModel(x);
            });
    }

    loadCatalogueChangeModel(model: SimpleTestingCatalogueUpdateModel): void {
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.TestActivity,
            model.TestActivity
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.TestNorm,
            model.TestNorm
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.TestSamples,
            model.TestSamples
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.Testers,
            model.Testers
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.EvidenceUploaders,
            model.EvidenceUploaders
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.TestFrequency,
            model.TestFrequency
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.QualityAspect,
            model.QualityAspect
        );
        setFormControlValue<SimpleTestingModel>(
            this.simpleTestingGroup,
            (t) => t.LinkedStandard,
            model.LinkedStandard
        );

        this.standingData.Testers = model.UpdatedTestersList;
        this.standingData.EvidenceUploaders = model.UpdatedUploadersList;
        this.standingData.TestFrequencies = model.UpdatedTestFrequenciesList;
        this.standingData.QualityAspects = model.UpdatedQualityAspectsList;
        this.standingData.LinkedStandards = model.UpdatedLinkedStandardsList;
    }

    checkDirty(): void {
        FormValidationHelper.mapToModel(this.simpleTestingGroup, this.model);
        this.formDirty = isObjectDirty<SimpleTestingModel>(
            this.backupModel,
            this.model,
            nameof<SimpleTestingModel>((t) => t.UploaderEvidenceLoaded)
        );
    }

    setTestScoreFieldConfig(): void {
        // Don't think this check is needed, but was in the original code
        if (
            this.type !== SimpleTestingType.Effectiveness &&
            this.type !== SimpleTestingType.DesignImplementation
        )
            return;

        const fields: GenericListField[] = [];

        fields.push({
            fieldName: nameof<SimpleTestingScore>((t) => t.TestResultDate),
            prettyName: "Test result date",
            fieldType: GenericListFieldType.Date,
            maximumValue: new Date(),
            required: true,
            overviewSortOrder: 1,
        });

        if (this.type === SimpleTestingType.DesignImplementation) {
            fields.push(this.createScoreField("Design score", 6, 2, (x) => x.Score1));
            fields.push(this.createScoreField("Implementation score", 6, 3, (x) => x.Score2));
        } else if (this.type === SimpleTestingType.Effectiveness) {
            fields.push(this.createScoreField("Effectiveness score", 12, 3, (x) => x.Score1));
        }

        fields.push({
            fieldName: nameof<SimpleTestingScore>((t) => t.Comment),
            fieldType: GenericListFieldType.TextArea,
            editorWidth: 12,
            overviewSortOrder: 5,
        });

        if (this.permissions.CanViewTestResultEvidence) {
            fields.push({
                fieldName: nameof<SimpleTestingScore>((t) => t.Documents),
                fieldType: GenericListFieldType.Documents,
                overviewSortOrder: 6,
            });
        }

        fields.push({
            fieldName: nameof<SimpleTestingScore>((t) => t.Version),
            fieldType: GenericListFieldType.Text,
            hideInEdit: true,
            overviewSortOrder: 7,
            defaultValue: this.model.Version,
        });

        fields.push({
            fieldName: nameof<SimpleTestingScore>((t) => t.User),
            fieldType: GenericListFieldType.Text,
            hideInEdit: true,
            defaultValue: "-",
            overviewSortOrder: 4,
        });

        this.testScoreConfig.fields = fields;
    }

    private createScoreField(
        name: string,
        editorWidth: number,
        overviewSortOrder: number,
        scoreFunction: (obj: SimpleTestingScore) => any
    ): GenericListField {
        let field: GenericListField = {
            fieldName: nameof<SimpleTestingScore>(scoreFunction),
            prettyName: name,
            fieldType: GenericListFieldType.ColoredSelect,
            required: true,
            editorWidth: editorWidth,
            overviewSortOrder: overviewSortOrder,
            getDataMethod: () => {
                // For overview: Load all active & inactive scores
                return of(this.standingData.Scores);
            },
            getDataMethodByRow: (row: SimpleTestingScore) => {
                // For edit: Load all active & selected scores
                let score = scoreFunction(row);
                return this.getScoresForRow(score);
            },
        };
        return field;
    }

    private getScoresForRow(scoreId: number): Observable<IdNameColorModel[]> {
        let scores: IdNameColorModel[] = [];
        this.standingData.Scores.forEach((score) => {
            if (score.disabled != true || scoreId === score.ID) {
                scores.push(score);
            }
        });
        return of(scores);
    }
}
