import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import {Observable, of} from "rxjs";
import {isGuid} from "@methods/uniqueMethods";
import {ModuleType} from "@enums/ModuleType";
import {ControlDetailOverviewModel} from "./ControlDetailOverviewModel";

@Injectable()
export class ControlDetailOverviewDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    public getControlOverview(guid: string): Observable<ControlDetailOverviewModel> {
        if (!isGuid(guid)) {
            return of(new ControlDetailOverviewModel());
        }

        const url = `/${guid}/overview`;
        return this.get<ControlDetailOverviewModel>(url);
    }

    public getControlOverviewWithModule(
        guid: string,
        parentModule: ModuleType,
        parentGuid: string
    ): Observable<ControlDetailOverviewModel> {
        if (!isGuid(guid)) {
            return of(new ControlDetailOverviewModel());
        }

        const url = `/${guid}/overview/${parentModule}/${parentGuid}`;
        return this.get<ControlDetailOverviewModel>(url);
    }
}
