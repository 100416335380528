<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export Tasks</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <hr />

        <button
            class="btn btn-default me-2 mb-2"
            (click)="exportTasks()"
            *ngIf="permService.permissions.HasTaskAccess"
        >
            Tasks
        </button>
    </div>
</div>
