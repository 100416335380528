import { Component, EventEmitter, OnInit } from "@angular/core";
import { Form } from "@app/forms/models/detail/Form";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { FormMainStandingData } from "@app/forms/models/other/FormMainStandingData";
import { ToastrService } from "ngx-toastr";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { FormLinkableModule } from "@app/forms/enums/FormLinkableModule";

@Component({
    selector: "form-main-settings",
    templateUrl: "./form-main-settings.component.html",
    styleUrls: ["./form-main-settings.component.scss"],
})
export class FormMainSettingsComponent implements OnInit {
    saveChanges: EventEmitter<Form> = new EventEmitter<Form>();

    FormLinkableModule = FormLinkableModule;

    form: Form;
    sd: FormMainStandingData;

    linkableModules: IdNameCombination[];
    selectedLinkedModule: IdNameCombination;
    moduleDisabled: boolean = false;

    linked: any[] = [];
    unlinked: any[] = [];
    displayValueProp: string = "name";
    typename: string = "";

    constructor(private _formDS: FormsDataService, private _toastr: ToastrService) {}

    async ngOnInit() {
        this.linkableModules = this.getLinkableModules();
        this.selectedLinkedModule = this.linkableModules
            .filter((x) => x.ID == this.form.LinkedModule)
            .first();

        await this.getLinkedItems();

        // if there are linked items, do not allow the module to be changed.
        if (this.linked.any()) {
            this.moduleDisabled = true;
        }

        this._formDS.getMainStandingData(this.form.ID).subscribe((d) => {
            this.sd = d;

            if (!this.form.ID || this.form.ID <= 0) {
                if (!this.form.Category) {
                    if (d.categories) {
                        this.form.Category = d.categories[0].ID;
                    } else {
                        this._toastr.warning(
                            "Please create a category and reopen this screen to select a category!",
                            "No categories found"
                        );
                    }
                }

                if (!this.form.Responsibles || this.form.Responsibles.length === 0) {
                    this.form.Responsibles = d.defaultResponsibleSelection;
                }

                if (!this.form.Designers || this.form.Designers.length === 0) {
                    this.form.Designers = d.defaultDesignerSelection;
                }
            }
        });
    }

    getLinkableModules(): IdNameCombination[] {
        const linkableModules: IdNameCombination[] = [];
        Object.keys(FormLinkableModule)
            .filter((f) => !isNaN(Number(f)))
            .forEach((k) => {
                const moduleString = FormLinkableModule[k];
                const moduleDescr = FormLinkableModule[moduleString + "Description"];
                linkableModules.push(new IdNameCombination(+k, moduleDescr));
            });
        return linkableModules;
    }

    moduleChanged(item: IdNameCombination) {
        this.selectedLinkedModule = item;
        this.getLinkedItems();
    }

    async getLinkedItems() {
        if (this.selectedLinkedModule) {
            this.linked = await this._formDS.getLinkedForms(
                this.form.ID,
                this.selectedLinkedModule.ID
            );

            switch (this.selectedLinkedModule.ID) {
                case +FormLinkableModule.Audit:
                    this.typename = "audits";
                    break;
                case +FormLinkableModule.AuditUniverse:
                    this.typename = "audit universes";
                    break;
                case +FormLinkableModule.Controls:
                    this.typename = "controls";
                    break;
                case +FormLinkableModule.ThirdParty:
                    this.typename = "third parties";
                    break;
            }
        }
    }

    onSaveChanges() {
        this.saveChanges.emit(this.form);
    }
}
