import { IHasDocuments } from "@methods/DocumentMethods";
import { DocumentModel } from "@models/documents/documentmodel";

export class ThirdPartyContractModel implements IHasDocuments {
    ID: number;
    guid: string;
    ContractNumber: string;
    ContractedValue: number;
    Status: string;
    TermsOfNotice: number;
    SignatureDate: Date;
    StartDate: Date;
    EndDate: Date;
    AnnualValue: number;
    Framework: boolean;
    ContractOwner: number;
    currencyCode: string;
    DateModified: Date;

    ThirdPartyGuid: string;

    Documents: DocumentModel[];
    Services: number[] = [];
}
