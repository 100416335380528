<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    class="details"
    (change)="handleMenuItemClick($event)"
    *ngIf="!tab.showLoader"
    data-uiid="audit-universe-detail-page"
>
    <div class="detail-page-action-bar">
        <button
            class="btn btn-cerrix"
            data-uiid="save-audit-universe"
            (click)="save()"
            *ngIf="!readonly"
        >
            <i class="fas fa-save"></i>
            <span data-uiid="save" class="d-none d-lg-inline">Save object</span>
        </button>

        <span class="sub-message" *ngIf="historicalView">
            Viewing historical readonly data. Audit object from
            {{ model.dateUpdated | dateFormat }}
        </span>

        <span class="date-edited" *ngIf="!historicalView && model.id > 0">
            Last save: {{ model.dateUpdated | dateTimeFormat }}
        </span>

        <div class="right-area" *ngIf="permissions.canChangeDate">
            <date-input
                [(value)]="dateFilter"
                [maxDate]="dateFilterMax"
                (valueChange)="dateChanged()"
            ></date-input>
        </div>
    </div>

    <div class="screens" name="Universe Object" menuicon="fad fa-planet-ringed" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <audit-universe-detail-main
                [permissions]="permissions"
                [standingData]="standingData"
                [model]="model"
                [formGroup]="formGroup"
                (dateChanged)="dateChangeHandler($event)"
            ></audit-universe-detail-main>
        </div>
        <div
            name="Related Data"
            [attr.menuItemId]="menuItemData"
            menuicon="fad fa-planet-ringed"
            menusystemitem
            *ngIf="permissions.canViewRelatedData"
        >
            <audit-universe-data
                [organizations]="model.organizations"
                [businessDimensions]="model.businessDimensions"
                [frameworkDimensions]="model.frameworkDimensions"
                [dateFilter]="dateFilter"
                [manualMode]="true"
                #moduleDataComp
            ></audit-universe-data>
        </div>
        <div name="Links" menuicon="fas fa-link" menusystemsubmenu *ngIf="model.id > 0">
            <div
                name="Audits"
                [attr.menuItemId]="menuItemAudits"
                menuicon="fal fa-clipboard-list-check"
                menusystemitem
                *ngIf="permissions.canViewLinkedAudits"
            >
                <audit-universe-linked-audits
                    #auditsComponent
                    [auditUniverseModel]="model"
                    [parentForm]="formGroup"
                    [disabled]="!permissions.canLinkAudits"
                >
                </audit-universe-linked-audits>
            </div>
            <div
                [attr.menuItemId]="menuItemForms"
                name="Forms"
                menuicon="fas fa-file-alt"
                menusystemitem
                *ngIf="permissions.canViewLinkedForms"
            >
                <audit-universe-linked-forms
                    #formsComponent
                    [model]="model"
                    [disabled]="!permissions.canLinkForms"
                ></audit-universe-linked-forms>
            </div>
            <div
                name="Documents"
                [attr.menuItemId]="menuItemDocuments"
                menuicon="far fa-file"
                menusystemitem
                *ngIf="permissions.canViewDocuments"
            >
                <div *ngIf="!documents">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="documents">
                    <document-manager
                        [(Documents)]="model.Documents"
                        (DocumentsChange)="checkDocumentsDirty()"
                        [permissions]="permissions.documentPermissions"
                        [DocumentTypeTargets]="targetModule"
                    >
                    </document-manager>
                </div>
            </div>
        </div>
        <div
            name="History"
            [attr.menuItemId]="menuItemHistory"
            menuicon="fas fa-history"
            menusystemitem
            *ngIf="permissions.canViewHistory"
        >
            <app-history-overview
                *ngIf="id"
                [historyDataService]="_ds"
                [Id]="id"
                #historyOverviewComponent
            >
            </app-history-overview>
        </div>
        <div name="Options" menuicon="fas fa-check-double" menusystemitem *ngIf="model.id > 0">
            <detail-options
                moduleName="Universe Object"
                [objectName]="model.name"
                [allowDelete]="permissions.canDelete"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
