import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";

export enum OpenDocumentPopupReason {
    ViewDocument = 0,
    AddDocument = 1,
    EditDocument = 2,
}

export class AddDocumentsParameters {
    documents: DocumentModel[];
    canAddDocument: boolean;
    canEditDocument: boolean;
    canDeleteDocument: boolean;
    documentTypes: IdNameCombination[];
    openReason: OpenDocumentPopupReason;
}
