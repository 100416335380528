import { FormControl, FormGroup } from "@angular/forms";
import { SampleType } from "@app/controls/testing/advanced/shared/enums";
import { WorkflowGenerateSamplesModel } from "@app/controls/testing/advanced/shared/models";

export class ManualSample {
    name: string;
    formControl: FormControl;
}

export class ManualSamplesGroup {
    samples: ManualSample[] = [];
    formGroup: FormGroup = new FormGroup([]);

    fillFormGroup() {
        this.formGroup = new FormGroup(this.samples.map((s) => s.formControl));
    }

    setSamples(samples: ManualSample[]) {
        this.samples = samples;
        this.fillFormGroup();
    }

    updateSample(samplingInformation: WorkflowGenerateSamplesModel) {
        let sampleNames = this.samples.map((s) => s.formControl.value);
        if (samplingInformation.sampleType == SampleType.number) {
            samplingInformation.numberSamples = sampleNames.map((x) => +x);
        } else if (samplingInformation.sampleType == SampleType.date) {
            samplingInformation.dateSamples = sampleNames.map((x) => {
                let date = new Date(x);
                return new Date(
                    Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        date.getHours(),
                        0,
                        0,
                        0
                    )
                );
            });
        } else {
            samplingInformation.textSamples = sampleNames;
        }
    }

    isAllFilled() {
        for (var i = 0; i < this.samples.length; i++) {
            if (
                this.samples[i].formControl.value == null ||
                this.samples[i].formControl.value == undefined ||
                this.samples[i].formControl.value == ""
            ) {
                return false;
            }
        }
        return true;
    }
}
