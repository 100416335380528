export enum IncidentPermissionType {
    ViewWorkspace = 10,
    SetSystemDefaultPreset = 20,
    EditIncident = 30,
    IncidentTypeStandingData = 40,
    DeleteIncident = 50,
    ClassificationStandingData = 60,
    SelectableAsReporter = 100,
    SelectableAsAssessor = 110,
    SelectableAsResponsible = 120,
    SelectableAsInformed = 130,
}
