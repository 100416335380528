import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";

export abstract class BaseModal {
    public afterClosed: Subject<any>;

    constructor(public _bsModalRef: BsModalRef) {
        this.afterClosed = new Subject();
    }

    public onClose(): Subject<any> {
        return this.afterClosed;
    }

    protected onConfirm(obj: any): void {
        this.afterClosed.next(obj);
        this._bsModalRef.hide();
    }

    protected onCancel(): void {
        this.afterClosed.next(false);
        this._bsModalRef.hide();
    }
}
