import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Configuration } from "@app/app.constants";
import { PeriodicNotificationMoiModel } from "@app/mailmessages/models/periodicNotificationMoiModel";
import { PeriodInfo } from "@app/mailmessages/models/periodicNotificationMoiStandingDataModel";
import { PeriodicNotificationMoiService } from "@app/mailmessages/service/periodic-notification-moi.service";
import { CerrixEditorComponent } from "@app/shared/components/cerrix-editor/cerrix-editor.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { ModuleType } from "@enums/ModuleType";
import { getFormControl, toPromise } from "@methods/CommonMethods";
import { isEmpty } from "@methods/isEmpty";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
    selector: "uc-periodic-notification-detail-moi",
    templateUrl: "./periodic-notification-detail-moi.component.html",
    styleUrls: ["./periodic-notification-detail-moi.component.scss"],
})
export class PeriodicNotificationDetailMoiComponent implements OnInit {
    @ViewChild("editor") editorComponent: CerrixEditorComponent;
    @Input() module: ModuleType;
    @Input() id: number;
    @Input() cerrixTab: TabModel;

    pageReady = false;

    notification: PeriodicNotificationMoiModel;
    keywords: string[];
    orgs: CerrixTreeItem[];
    mailEnabled: boolean;

    dayNumbers: IdNameCombination[] = [];
    periods: PeriodInfo[] = [];
    moiTypes: IdNameCombination[] = [];
    userTypes: IdNameCombination[] = [];
    priorities: IdNameCombination[] = [];

    form: FormGroup;
    isEmpty = false;
    organizationRequest: Observable<CerrixTreeItem[]>;
    noOrganizationsSelectedDescription = Configuration.NoOrganizationsSelectedDescription;

    subjectHasFocus: boolean;

    constructor(
        private _pnService: PeriodicNotificationMoiService,
        private toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        var notification = await toPromise(this._pnService.getNotification(this.id));
        if (this.id === 0) {
            notification.Period = null;
            notification.UserTypeIds = [];
            notification.DayNumber = null;
        }

        this.notification = notification;
        this.mailEnabled = this.notification.Active;

        var data = await toPromise(this._pnService.getNotificationStandingData());
        this.periods = data.Periods;
        this.moiTypes = data.MoiTypeOptions;
        this.userTypes = data.UserTypeOptions;
        this.priorities = data.PriorityOptions;
        this.keywords = data.Keywords;

        if (this.notification.Period) {
            this.setDayNumbers(this.periods.find((x) => x.ID === this.notification.Period));
        }

        this.initFormGroup(notification);
        this.pageReady = true;

        this.organizationRequest = this._pnService.getOrganizations();
    }

    setDayNumbers(period: PeriodInfo): void {
        this.dayNumbers = [];

        if (!period) {
            return;
        }

        for (let i = 1; i <= period.MaxDayNumber; i++) {
            this.dayNumbers.push({ ID: i, Name: i.toString() });
        }
    }

    initFormGroup(notification: PeriodicNotificationMoiModel) {
        this.form = new FormGroup({
            Name: new FormControl(notification.Name, [Validators.required]),
            Active: new FormControl(notification.Active),
            Subject: new FormControl(notification.Subject, [Validators.required]),
            Organizations: new FormControl(notification.Organizations),
        });

        this.form.addControl("MoiTypeIds", new FormControl(notification.MoiTypeIds));

        this.form.addControl("Period", new FormControl(notification.Period, Validators.required));
        this.form.addControl(
            "DayNumber",
            new FormControl(notification.DayNumber, Validators.required)
        );

        this.form.addControl("UserTypeIds", new FormControl(notification.UserTypeIds));
        this.form.addControl("PriorityIds", new FormControl(notification.PriorityIds));

        this.form.valueChanges.subscribe(() =>
            FormValidationHelper.mapToModel(this.form, this.notification)
        );
    }

    onChange(message: string) {
        this.notification.Message = message;
    }

    addKeyword(keyword: string) {
        if (this.subjectHasFocus) {
            const ctrl = getFormControl(this.form, "subject");
            const value = ctrl.value;
            ctrl.setValue(value + " [" + keyword + "]");
        } else {
            this.editorComponent.insertText(" [" + keyword + "]");
        }
    }

    async save() {
        this.isEmpty = false;
        if (!this.notification.Organizations) {
            this.notification.Organizations = [];
        }

        if (!this.validate()) {
            return;
        }

        this.notification.Id = this.id;

        const savingPrompt = this._promptService.loader("Saving changes, please wait...");
        var savePromise = toPromise(
            this._pnService
                .saveNotification(this.notification)
                .pipe(finalize(() => savingPrompt.close()))
        );

        await savePromise
            .then((storedId: number) => {
                this.id = storedId;
                this._tabService.refresh(this._pages.MailMessages);

                this.toastr.success("", "Update completed");
            })
            .catch((_) => {
                this.toastr.error("", "Update failed");
            });
    }

    deleteClick() {
        this._pnService.deleteNotification(this.id).subscribe((_) => {
            this._tabService.refresh(this._pages.MailMessages);
            this.toastr.success("Notification deleted");
            this.cerrixTab.close(false);
        });
    }

    validate(): boolean {
        const errors: string[] = [];
        if (isEmpty(this.notification.Name)) {
            errors.push("'Name' is required");
        }

        if (!this.dayNumbers.find((x) => x.ID === this.notification.DayNumber)) {
            errors.push("Invalid day selected");
        }

        if (isEmpty(this.notification.Subject)) {
            errors.push("'Subject' is required");
        }

        const emptyFroala = `<p><span class="fr-marker" data-id="0" data-type="false" style="display: none; line-height: 0;">​</span><span class="fr-marker" data-id="0" data-type="true" style="display: none; line-height: 0;">​</span><br></p>`;
        const messageEmpty =
            isEmpty(this.notification.Message) || this.notification.Message === emptyFroala;

        if (messageEmpty) {
            this.isEmpty = true;
            errors.push("'Message' is required");
        }

        if (!this.form.valid) {
            FormValidationHelper.markAllAsTouched(this.form);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.form);
            let validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            errors.push(validationMessage);
        }

        if (errors.any()) {
            this.toastr.warning(errors.join("<br />"), "Validations", { enableHtml: true });
        }

        return errors.length === 0;
    }

    setFocusToSubject() {
        this.subjectHasFocus = true;
    }

    deFocusSubject() {
        this.subjectHasFocus = false;
    }
}
