import { Component, Input, OnInit } from "@angular/core";
import { EventConfigModel } from "@app/event/shared/event-config-model";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { NavigatorConfigType } from "@app/navigators/enums/NavigatorConfigType";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "business-events",
    templateUrl: "./business-events.component.html",
})
export class BusinessEventsComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissionsModel: BusinessNavigatorPermissionModel;
    config: GenericListConfig;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _navFactory: NavGenericListFactory,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        this.config = this._navFactory.createConfig(NavigatorConfigType.Event);

        this.config.dataMethod = () => this._businessDs.getEvents(this.requestModel);
        this.config.hideRefreshButton = false;

        this.config.allowAdd = this.permissionsModel.canAddEvent;
        this.config.addOverride = () => {
            const config = <EventConfigModel>{
                organizationId: this.requestModel.organizationID,
                businessId: this.requestModel.businessID,
            };
            this._tabService.generateTab(this._pages.EventDetail, 0, "(E) New Event", config);
        };
    }
}
