import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "../../app.constants";
import { AuditUniverseRiskDataModel } from "../shared/data-models/audit-universe-risk-data-model";
import { AuditUniverseModuleDataRequestModel } from "../shared/request-models/audit-universe-module-data-request-model";

@Injectable()
export class AuditUniverseModuleDataService extends ApiDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audituniverse/moduledata");
    }

    //#region Module Data

    getRiskData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<AuditUniverseRiskDataModel>(model, "/risk");
    }

    getControlData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<any[]>(model, "/control");
    }

    getEventData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<any[]>(model, "/event");
    }

    geMoiData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<any[]>(model, "/moi");
    }

    getKriData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<any[]>(model, "/kri");
    }

    getDataManagementData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<any[]>(model, "/datamanagement");
    }

    getThirdPartyData(model: AuditUniverseModuleDataRequestModel) {
        return this.post<any[]>(model, "/thirdparty");
    }

    //#endregion Module Data
}
