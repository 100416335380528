import { StandingdataDataService } from "../shared/standingdata.service";
import { Output, Input, EventEmitter, OnInit, Directive } from "@angular/core";
import {
    GenericStandingDataConfig,
    StandingDataField,
    StandingDataOverviewType,
} from "@app/shared/models/GenericList/GenericStandingDataConfig";
import { StandingDataType } from "@enums/StandingDataType";
import { CellRendererPipe } from "@app/shared/pipes/cell-renderer.pipe";
import { Export } from "@methods/Export";
import {
    GenericListFieldType,
    GenericListField,
} from "@app/shared/models/GenericList/GenericListField";
import { tryParseJson } from "@methods/CommonMethods";
import { Observable } from "rxjs";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { StandingDataHelper } from "../shared/standingdata.helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Directive()
export class StandingDataView implements OnInit {
    @Input() config: GenericStandingDataConfig;
    @Input() standingDataType: StandingDataType;
    @Input() name: string;
    @Input() selected: number;
    @Input() changeCallback: (id: number) => Promise<boolean>;

    @Output() onAdd = new EventEmitter<any>();
    @Output() onDataLoaded = new EventEmitter<any[]>();

    editMode = false;
    currentRow: any;

    // Export
    exporter: Export;
    isGeneratingExport = false;

    constructor(
        public dataService: StandingdataDataService,
        public cellRenderer: CellRendererPipe,
        public _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this.exporter = new Export(this.cellRenderer, this._promptService);

        this.loadData();
    }

    loadData(selected?: number) {
        throw Error("Not implemented");
    }

    detailOpened(row) {
        this.currentRow = row;
    }

    async itemClick(item: any) {
        if (!this.editMode && item.ID !== this.selected) {
            if (await this.changeCallback(item.ID)) {
                this.selected = item.ID;
            }
        }
    }

    addClick(row?: any) {
        if (!row) {
            row = {};
        }

        this.selected = null;
        this.onAdd.emit(row);
    }

    exportClick() {
        this.prepareExport();
    }

    private prepareExport() {
        this.isGeneratingExport = true;

        const headers = [];
        const prettyHeaders = {};
        const additionalData = {};
        const genericListFields = [];
        let dataCallsLength = 0;
        let completedDataCalls = 0;

        StandingDataHelper.doForField(this.config.categories, (field: StandingDataField) => {
            // Ignore iconpickers by default
            if (field.excludeInExport || field.fieldType == GenericListFieldType.IconPicker) {
                return;
            }

            const prettyName = field.prettyName ? field.prettyName : field.fieldName;

            // Process complex fields
            if (field.fieldType === GenericListFieldType.GenericList) {
                const listConfig = field.additionalConfig as GenericListConfig;
                const listFields = listConfig.fields;

                // Generate the headers for nested genericlists
                listFields.forEach((listField) => {
                    const listFieldName = `${field.fieldName}.${listField.fieldName}`;
                    headers.push(listFieldName);

                    const listPrettyName = listField.prettyName
                        ? listField.prettyName
                        : listField.fieldName;
                    prettyHeaders[listFieldName] = prettyName + " - " + listPrettyName;
                });

                genericListFields.push({ fieldName: field.fieldName, listFields });
            } else {
                headers.push(field.fieldName);
                prettyHeaders[field.fieldName] = prettyName;
            }

            // Fetch additional data
            if (
                field.getDataMethodByRow ||
                (field.getDataMethodName && this.dataService[field.getDataMethodName])
            ) {
                dataCallsLength++;

                additionalData[field.fieldName] = null;
                const dataCall: Observable<any> = field.getExportDataMethod
                    ? field.getExportDataMethod(this.dataService)
                    : field.getDataMethodByRow
                    ? field.getDataMethodByRow({}, this.dataService)
                    : this.dataService[field.getDataMethodName](field.getDataParams);

                dataCall.subscribe((d) => {
                    completedDataCalls++;

                    const data = tryParseJson(d);
                    if (data) {
                        additionalData[field.fieldName] = data;
                    } else {
                        additionalData[field.fieldName] = d;
                    }

                    // If all calls are completed, move to the next function
                    if (dataCallsLength === completedDataCalls) {
                        this.processExportData(
                            headers,
                            prettyHeaders,
                            additionalData,
                            genericListFields
                        );
                    }
                });
            }
        });

        // If no additional data needs to be fetched, move to the next function
        if (dataCallsLength === 0) {
            this.processExportData(headers, prettyHeaders, additionalData, genericListFields);
        }
    }

    async processExportData(
        headers: string[],
        prettyHeaders: object,
        additionalData: object,
        genericListFields: any[]
    ) {
        // First fetch the export data
        const exportData = await this.dataService
            .getExportByType(this.standingDataType)
            .toPromise();

        // Process additional data, create csv strings of numbers & number arrays
        exportData.forEach((dataRow: any) => {
            Object.keys(additionalData).forEach((key) => {
                if (dataRow[key]) {
                    const rowValues = Array.isArray(dataRow[key])
                        ? (dataRow[key] as number[])
                        : [dataRow[key]];

                    let mappedColumn: string[];
                    const additionalMapping = additionalData[key];

                    // Addtional mapping is either an array or an object
                    if (Array.isArray(additionalMapping)) {
                        mappedColumn = additionalMapping
                            .filter((am) => rowValues.indexOf(am.ID) > -1)
                            .map((x) => x.Name);
                    } else {
                        mappedColumn = rowValues.map((x) => additionalMapping[x]);
                    }

                    dataRow[key] = mappedColumn.length > 0 ? mappedColumn.join("; ") : "";
                }
            });
        });

        // Process nested generic lists
        if (genericListFields.length > 0) {
            for (let index = 0; index < exportData.length; index++) {
                const dataRow = exportData[index];

                // This will add 1 or more rows to the existing exportData, so change the index based on number of added rows
                index = this.processNestedList(index, exportData, dataRow, genericListFields);
            }
        }

        const data = exportData.slice();
        await this.exporter
            .exportData(`SD-${this.name}`, data, [], headers, prettyHeaders)
            .then(() => {
                this.isGeneratingExport = false;
            })
            .catch((reason) => {
                this._promptService.alert({
                    data: {
                        title: "Export failed",
                        message: "Something went wrong while generating the export.",
                    },
                });
                this.isGeneratingExport = false;
            });
    }

    private processNestedList(
        index: number,
        dataRows: any[],
        dataRow: any,
        genericListFields: any[]
    ): number {
        // Loop through each generic list
        genericListFields.forEach((genericList) => {
            const listData = dataRow[genericList.fieldName];

            if (listData && listData.length > 0) {
                const rowCopies = [];

                // Loop through each row of the generic list
                listData.forEach((listRow, rowIndex) => {
                    // Flatten the list into an object with the specified fields from the StandingDataMapping
                    const mappedListData = {};
                    genericList.listFields.forEach((genericListField: GenericListField) => {
                        mappedListData[genericList.fieldName + "." + genericListField.fieldName] =
                            listRow[genericListField.fieldName];
                    });

                    // Do not create copy of first row
                    if (rowIndex === 0) {
                        const extendedDataRow = { ...dataRow, ...mappedListData };
                        delete extendedDataRow[genericList.fieldName];

                        // Replace the data row with the extended row
                        dataRows[index] = extendedDataRow;
                    } else {
                        let dataRowCopy = JSON.parse(JSON.stringify(dataRow));
                        delete dataRowCopy[genericList.fieldName];

                        dataRowCopy = { ...dataRowCopy, ...mappedListData };
                        rowCopies.push(dataRowCopy);
                    }
                });

                // Add the rowcopies of the flattened generic list data to the datarow array
                dataRows.splice(index + 1, 0, ...rowCopies);

                // Move the index based on the added length
                return index + rowCopies.length;
            }
        });

        return index;
    }
}
