import { ProcessingPurposeModel } from "./ProcessingPurposeModel";
import { SourceDeliveryModel } from "./SourceDeliveryModel";
import { DataRecipientModel } from "./DataRecipientModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { IHasDocuments } from "@methods/DocumentMethods";
import { MdsModel } from "./MdsModel";

export class ExpandedProcessingPurposeModel implements IHasDocuments {
    processingPurpose: ProcessingPurposeModel;
    dataStructures: MdsModel;
    sourceDocuments: SourceDeliveryModel[];
    dataRecipients: DataRecipientModel[];

    Documents: DocumentModel[];
}
