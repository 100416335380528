import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app.constants";
import { Observable } from "rxjs";
import { ApiDataService } from "@services/http/ApiDataService";
import { ModuleType } from "@enums/ModuleType";
import { PeriodicNotificationModel } from "../models/periodicNotificationModel";

@Injectable()
export class PeriodicNotificationService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "periodicnotifications");
    }

    getNotifications(module: ModuleType): Observable<PeriodicNotificationModel[]> {
        const url = "?module=" + module;
        return this.get<PeriodicNotificationModel[]>(url);
    }

    getOrganizations() {
        const url = "/organizations";
        return this.get<any[]>(url);
    }
}
