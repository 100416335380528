import { Component, OnInit, ViewChild } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { SimpleTestingConfig } from "../models/SimpleTestingConfig";
import { SimpleTestingType } from "../enums/SimpleTestingType";
import { SimpleTestingDataService } from "../services/simple-testing.service";
import { SimpleTestingModel } from "../models/SimpleTestingModel";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { SimpleTestingMainComponent } from "./simple-testing-main/simple-testing-main.component";
import { SimpleTestingWorkflowButtonModel } from "../models/SimpleTestingWorkflowButtonModel";
import { ToastrService } from "ngx-toastr";
import { DocumentManagerComponent } from "@app/shared/document-manager/document-manager.component";
import { SimpleTestingPermissions } from "../models/SimpleTestingPermissions";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { SimpleTestingScore } from "../models/SimpleTestingScore";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SimpleTestingTestPlanComponent } from "./simple-testing-testplan/simple-testing-test-plan.component";
import { LocalizationService } from "@app/shared";
import { finalize } from "rxjs/operators";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { TargetModule } from "@enums/document/TargetModule";
import { isGuid } from "@methods/uniqueMethods";
import { setEmptyStringsToNull, toPromise } from "@methods/CommonMethods";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";

@Component({
    selector: "simple-testing-detail",
    templateUrl: "./simple-testing-detail.component.html",
    styleUrls: ["./simple-testing-detail.component.scss"],
})
export class SimpleTestingDetailComponent implements OnInit {
    tab: TabModel;
    id: string;
    config: SimpleTestingConfig;

    type: SimpleTestingType;
    typeIcon: string;
    typeDesc: string;

    permissions: SimpleTestingPermissions;
    model: SimpleTestingModel;
    testScoreConfig: GenericListConfig;

    overlayPrompt: CerrixPromptComponent;
    testPlanByScoreVersion: number;
    documentTypeTargets: TargetModule[];

    @ViewChild("mainComponent") mainComponent: SimpleTestingMainComponent;
    @ViewChild("evidenceComponent") evidenceComponent: DocumentManagerComponent;
    @ViewChild("historyOverviewComponent")
    historyOverview: HistoryOverviewComponent;

    @ViewChild(ModalDirective) testPlanByVersionModal: ModalDirective;
    @ViewChild("testplanByVersion")
    testPlanByVersion: SimpleTestingTestPlanComponent;

    targetModule = TargetModule.Control;
    hyperlinksMenuItemId = "menuItemHyperlinks";

    constructor(
        public ds: SimpleTestingDataService,
        private toastr: ToastrService,
        private localization: LocalizationService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit(): void {
        if (!this.config || !this.config.type) {
            this.type = SimpleTestingType.DesignImplementation;
        } else if (typeof this.config.type === "number") {
            this.type = this.config.type;
        } else {
            this.type = Number.parseInt(this.config.type, 0);
        }

        const isDocImp = this.type === SimpleTestingType.DesignImplementation;
        this.typeIcon = isDocImp ? "fas fa-book" : "fas fa-compress-arrows-alt";
        this.typeDesc = isDocImp ? "D&I testing" : "Effectiveness testing";

        this.ds.getPermissions(this.type, this.id).subscribe(
            (permissions) => {
                this.permissions = permissions;
                if (!this.permissions.CanView) {
                    this.toastr.error(`Not enough rights to open ${this.typeDesc}.`, "No access!");
                    this.tab.close(false);
                    return;
                }

                this.ds.getSimpleTesting(this.type, this.id).subscribe((model) => {
                    if (model == null) {
                        model = new SimpleTestingModel();
                    }

                    if (model.Version === 0) {
                        model.ControlGuid = this.id;
                    }

                    this.model = model;
                    this.initTestScoreConfig();

                    this.tab.name = `(C - ${isDocImp ? "DI" : "EFF"}) ${
                        this.model.ControlIdentifier
                    } - ${this.model.ControlName}`;

                    this.tab.showLoader = false;
                });
            },
            // OnError
            (_) => this.tab.close(false)
        );
    }

    handleMenuItemClick(menuItem: TabMenuItem): void {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "evidenceTab":
                    if (!this.model.UploaderEvidenceLoaded) {
                        this.ds.getUploaderEvidence(this.type, this.id).subscribe((x) => {
                            this.model.Documents = x;
                            this.model.UploaderEvidenceLoaded = true;
                        });
                    }
                    break;
                case this.hyperlinksMenuItemId:
                    this.loadHyperlinks();
                    break;
                case "historyTab":
                    this.historyOverview.loadHistory();
                    break;
            }
        }
    }

    loadHyperlinks(): void {
        if (!this.model.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this.ds.getHyperlinks(this.type, this.id.toString()).subscribe((hyperlinks) => {
                this.model.Hyperlinks = hyperlinks;
            });
        }
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
    }

    async uploadEvidence(skipReload?: boolean): Promise<boolean> {
        if (!skipReload) {
            this.overlayPrompt = this._promptService.loader("Uploading evidence, please wait...");
        }
        await this.ds.storeEvidence(this.type, this.model.ControlGuid, this.model).toPromise();

        if (!skipReload) {
            this.overlayPrompt.close();
            this.toastr.success("Changes have been saved!", "Success");
            this.tab.showLoader = true;
            this.tab.refresh();
        }

        return true;
    }

    async save(skipReload?: boolean): Promise<boolean> {
        this.mainComponent.checkDirty();
        const errors = FormValidationHelper.getFormControlErrors(
            this.mainComponent.simpleTestingGroup
        );

        const validated = Object.getOwnPropertyNames(errors).length === 0;
        if (!validated) {
            FormValidationHelper.markAllAsTouched(this.mainComponent.simpleTestingGroup);
            this.toastr.warning(
                "Some fields do not match the requirements, please correct these fields first!",
                "Validation failed!"
            );
            return false;
        }

        if (!skipReload) {
            this.overlayPrompt = this._promptService.loader("Saving changes, please wait...");
        }

        await toPromise(
            this.ds
                .storeSimpleTesting(this.type, this.model.ControlGuid, this.model)
                .pipe(finalize(() => this.overlayPrompt.close()))
        );

        if (!skipReload) {
            this.overlayPrompt.close();
            this.toastr.success("Changes have been saved!", "Success");
            this.tab.showLoader = true;
            this.tab.refresh();
        }

        return true;
    }

    async doAction(button: SimpleTestingWorkflowButtonModel): Promise<void> {
        if (button.NewTestScoreButton) {
            this.mainComponent.testScores.addRow();
            return;
        }

        
            const skipReload = true;
            if (!this.permissions.CanEdit && this.permissions.CanUploadEvidence) {
                const saved = await this.uploadEvidence(skipReload);
                if (!saved) {
                    return;
                }
            } else {
                const saved = await this.save(skipReload);
                if (!saved) {
                    return;
                }
            }
        
        // Perform workflow action now!!
        this.ds
            .doWorkflow(this.type, this.model.ControlGuid, button.TargetStatusCode)
            .subscribe((x) => {
                // Finally refresh tab to get latest version of testplan.
                this.toastr.success(
                    "Status changes been saved!",
                    "Success"
                );

                this.tab.showLoader = true;
                this.tab.refresh();
            });
    }

    initTestScoreConfig(): void {
        this.testScoreConfig = <GenericListConfig>{
            name: "Test scores",
            isSortable: false,
            limitViewTo: 3,

            overviewSortBy: "ID",
            overviewSortAsc: false,

            allowAdd: this.permissions.CanCreateScore,
            allowEdit: this.permissions.CanEditScore,
            allowDelete: false,

            hideAddButton: true,

            overviewRowActionsName: "Open version",
            overviewRowActions: [
                {
                    icon: "fas fa-history",
                    tooltip: "View test plan version",
                    clickEvent: async (row: SimpleTestingScore) => {
                        this.testPlanByScoreVersion = row.Version;
                        await this.testPlanByVersion.loadOldVersion(row.Version);
                        this.testPlanByVersionModal.show();
                    },
                },
            ],

            dataMethod: () => this.ds.getTestScores(this.type, this.model.ControlGuid),
            add: this.addClick.bind(this),
            edit: this.editClick.bind(this),
        };
    }

    async shouldInitialDueDateBeChanged(row: SimpleTestingScore): Promise<boolean> {
        let answer = false;
        if (row && row.TestResultDate < this.model.InitialTestDate) {
            answer = await this._promptService
                .confirm(
                    "",
                    `The test result date is before the initial test date.\n\nDo you want to change the initial test date to ${this.localization.formatDate(
                        row.TestResultDate
                    )}?`
                )
                .toPromise();
        }

        row.UpdateInitialTestDate = answer;
        return answer;
    }

    private async addClick(row: SimpleTestingScore) {
        var saved = false
        this.mainComponent.checkDirty();
        const answer = await this.shouldInitialDueDateBeChanged(row);
        if(this.mainComponent.formDirty && this.permissions.CanEdit) {
            saved = await this.save(true);
        } else{
            saved = true;
        }

        if (saved || answer) {
            this.tab.showLoader = true;
            await this.ds.storeTestScore(this.type, this.model.ControlGuid, row).toPromise();

            // Refetch all data, because workflow status has changed at this point.
            this.tab.refresh();
            return true;
        }
    }

    private async editClick(row: SimpleTestingScore, uneditedRow: SimpleTestingScore) {
        try {
            const answer = await this.shouldInitialDueDateBeChanged(row);

            this.overlayPrompt = this._promptService.loader("Saving testscore, please wait...");

            await this.ds
                .storeTestScore(this.type, this.model.ControlGuid, row)
                .pipe(
                    finalize(() => {
                        this.overlayPrompt.close();
                    })
                )
                .toPromise();

            this.toastr.success("", "Test score has been updated!");

            if (answer) {
                this.tab.showLoader = true;
                this.tab.refresh();
            }

            return true;
        } catch {
            return false;
        }
    }
}
