import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";
import { DevPowerBIService } from "@app/admin/services/devpowerbi.service";
import { DevPowerBIMaintenanceModel, DevPowerBIModel } from "@app/admin/shared/dev-powerbi-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { finalize, firstValueFrom, of } from "rxjs";

@Component({
    selector: "dev-powerbi",
    templateUrl: "./dev-powerbi.component.html",
    styleUrls: ["./dev-powerbi.component.scss"],
})
export class DevPowerBIComponent {
    config: GenericListConfig;
    isEnabled = false;
    isLoading = true;

    constructor(
        private _ds: DevPowerBIService,
        private _prompt: CerrixPromptService,
        private _toastr: ToastrService
    ) {
        _ds.isEnabled().then((e) => {
            this.isEnabled = e;
            if (this.isEnabled) {
                this.init();
            } else {
                this.isLoading = false;
            }
        });
    }

    init() {
        this._ds.getReports().then((data) => {
            this.createListConfig(data);
            this.isLoading = false;
        });
    }

    createTemplateDatasets() {
        const loaderPrompt = this._prompt.loader("Creating templates");
        firstValueFrom(
            this._ds.createTemplates().pipe(
                finalize(() => {
                    loaderPrompt.close();
                })
            )
        ).then(() => {
            this._toastr.success("", "Templates created");
        });
    }

    private createListConfig(data: DevPowerBIMaintenanceModel) {
        this.config = <GenericListConfig>{
            name: "Registered Power BI Reports",
            data: data.reports,
            idProp: "id",

            allowAdd: true,
            allowEdit: true,
            allowDelete: true,

            fields: [
                {
                    fieldName: "nameInPowerBI",
                    prettyName: "Name in Power BI",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "name",
                    prettyName: "Name",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "description",
                    prettyName: "Description",
                    required: true,
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    fieldName: "module",
                    prettyName: "Module",
                    required: true,
                    fieldType: GenericListFieldType.SingleSelect,
                    getDataMethod: () => {
                        return of(
                            Object.keys(data.modules).map(
                                (x) =>
                                    <IdNameCombination>{
                                        ID: +x,
                                        Name: data.modules[x],
                                    }
                            )
                        );
                    },
                },
                {
                    fieldName: "dataset",
                    prettyName: "Dataset",
                    required: false,
                    fieldType: GenericListFieldType.SingleSelect,
                    getDataMethod: () => {
                        return of(
                            Object.keys(data.datasets).map(
                                (x) =>
                                    <IdNameCombination>{
                                        ID: +x,
                                        Name: data.datasets[x],
                                    }
                            )
                        );
                    },
                },
                {
                    fieldName: "requiredRoleGroup",
                    prettyName: "Required role group for report",
                    required: false,
                    fieldType: GenericListFieldType.SingleSelect,
                    getDataMethod: () => {
                        return of(
                            Object.keys(data.roleGroups).map(
                                (x) =>
                                    <IdNameCombination>{
                                        ID: +x,
                                        Name: data.roleGroups[x],
                                    }
                            )
                        );
                    },
                },
            ],

            add: async (row: DevPowerBIModel) => {
                const id = await this._ds.updateReport(row);
                row.id = id;
                this._toastr.success("Report is registered!", "Success");
                return true;
            },

            edit: async (row: DevPowerBIModel) => {
                await this._ds.updateReport(row);
                this._toastr.success("Report is updated!", "Success");
                return true;
            },

            delete: async (row: DevPowerBIModel) => {
                const confirmed = await this._prompt
                    .confirm(
                        `Delete '${row.name}'?`,
                        "Are you sure you want to delete this report?"
                    )
                    .toPromise();

                if (!confirmed) return false;

                await this._ds.deleteReport(row.id);
                this._toastr.success("Report is deleted!", "Success");
                return true;
            },
        };
    }
}
