<form [formGroup]="formGroup">
    <div class="audit-workflow-graph">
        <cerrix-workflow [config]="workflowConfig" *ngIf="workflowConfig"></cerrix-workflow>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Details</div>
        <div class="detail-page-card-body padded form-group-styling">
            <div class="row">
                <div class="col left-block">
                    <div class="row">
                        <div class="col">
                            <cerrix-textfield
                                fieldLabel="Audit name"
                                identifier="auditNameInput"
                                placeholder="Required"
                                formControlName="auditName"
                                dataUiid="audit-name-input"
                            ></cerrix-textfield>
                        </div>
                        <div class="col">
                            <cerrix-textfield
                                fieldLabel="Audit plan"
                                identifier="auditPlanInput"
                                formControlName="auditPlan"
                                dataUiid="audit-plan-input"
                            ></cerrix-textfield>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="nameInput">Audit type</label>
                                <cerrix-select
                                    id="auditTypeSelect"
                                    prettyName="audit type"
                                    bindValue="ID"
                                    bindLabel="Name"
                                    formControlName="auditType"
                                    [getDataMethod]="standingData.types"
                                    dataUiid="audit-type-select"
                                    [required]="true"
                                    placeholder="Required"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="nameInput">Criticality</label>
                                <cerrix-select
                                    id="criticality"
                                    prettyName="criticality"
                                    bindValue="ID"
                                    bindLabel="Name"
                                    formControlName="criticality"
                                    [getDataMethod]="standingData.criticalities"
                                    dataUiid="audit-criticality-select"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="nameInput">Audit score</label>
                                <cerrix-select
                                    id="auditScore"
                                    prettyName="audit type"
                                    bindValue="ID"
                                    bindLabel="Name"
                                    formControlName="auditScore"
                                    [getDataMethod]="standingData.scores"
                                    dataUiid="audit-score-select"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="nameInput">Confidentiality</label>
                                <cerrix-select
                                    id="confidentiality"
                                    prettyName="confidentiality"
                                    bindValue="ID"
                                    bindLabel="Name"
                                    formControlName="confidentiality"
                                    [getDataMethod]="standingData.confidentialities"
                                    dataUiid="audit-confidentiality-select"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col right-block">
                    <div class="form-group">
                        <label for="nameInput">Sponsor(s)</label>
                        <cerrix-select
                            id="sponsorsSelect"
                            prettyName="Sponsor(s)"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="sponsors"
                            [getDataMethod]="standingData.sponsorUsers"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            dataUiid="audit-sponsors-select"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <label for="nameInput">Auditee(s)</label>
                        <cerrix-select
                            id="auditeesSelect"
                            prettyName="Auditee(s)"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="auditees"
                            [getDataMethod]="standingData.auditeeUsers"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            dataUiid="audit-auditees-select"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <label for="nameInput">Auditor(s)</label>
                        <cerrix-select
                            id="auditorsSelect"
                            prettyName="Auditor(s)"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="auditors"
                            [getDataMethod]="standingData.auditorUsers"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            dataUiid="audit-auditors-select"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <label for="nameInput">Reviewer(s)</label>
                        <cerrix-select
                            id="reviewersSelect"
                            prettyName="Reviewer(s)"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="reviewers"
                            [getDataMethod]="standingData.reviewerUsers"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            dataUiid="audit-reviewers-select"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <label for="nameInput">Chief Audit Executive(s)</label>
                        <cerrix-select
                            id="chiefAuditExecutivesSelect"
                            prettyName="Chief Audit Executives(s)"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="chiefAuditExecutives"
                            [getDataMethod]="standingData.chiefAuditExecutiveUsers"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            dataUiid="audit-chief-audit-executive-select"
                        >
                        </cerrix-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col left-block">
                    <cerrix-textarea
                        fieldLabel="Audit Objective"
                        identifier="auditObjectiveInput"
                        formControlName="auditObjective"
                        dataUiid="audit-objective-input"
                    ></cerrix-textarea>
                </div>

                <div class="col right-block">
                    <cerrix-textarea
                        fieldLabel="Rationale"
                        identifier="auditRationaleInput"
                        formControlName="auditRationale"
                        dataUiid="audit-rationale-input"
                    ></cerrix-textarea>
                </div>
            </div>

            <cerrix-textarea
                fieldLabel="Audit Conclusion"
                identifier="auditConclusionInput"
                formControlName="auditConclusion"
                dataUiid="audit-conclusion-input"
            ></cerrix-textarea>
        </div>
    </div>
</form>
