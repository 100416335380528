import {
    Component,
    ContentChildren,
    Directive,
    Input,
    QueryList,
    TemplateRef,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { EffTableColumnConfig } from "./models/eff-table-column-config.model";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { EffTableConfig, EffTableSelectionEnum } from "./models/eff-table-config.model";
@Directive({
    selector: "[column-name]",
})
export class EffTableColumnDirective {
    constructor(public readonly template: TemplateRef<any>) {}
    @Input("column-name") columnName!: string;
}

@Component({
    selector: "eff-table",
    templateUrl: "./eff-table.component.html",
    styleUrls: ["./eff-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed,void", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
})
export class EffTableComponent {
    @Input() tableConfig: EffTableConfig = new EffTableConfig();
    @Input() columns: Array<EffTableColumnConfig> = [];
    @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    @Input() expandedRowTemplate: TemplateRef<any>;
    @ContentChildren(EffTableColumnDirective) templates: QueryList<EffTableColumnDirective>;

    displayedColumns() {
        var displayedRows = this.columns.map((c) => c.PropertyName);

        if (this.tableConfig.ExpandableRow) {
            //displayedRows = displayedRows.concat(["expand"]);
        }

        if (this.isSelectionActive()) {
            displayedRows = ["select"].concat(displayedRows);
        }

        return displayedRows;
    }

    getTemplate(columnName: string) {
        if (!this.templates) {
            return null;
        }

        let template = this.templates.toArray().find((t) => {
            return t.columnName == columnName;
        })?.template;

        if (template == undefined) {
            return null;
        }
        return template;
    }

    expandCollapseRow($event: any, item: any) {
        if (!item.isSample && this.isRowDisabled(item)) {
            return;
        }
        $event.stopPropagation();
        item.IsExpanded = !item.IsExpanded;

        if (this.tableConfig?.OnRowClickFunc) {
            this.tableConfig?.OnRowClickFunc(item);
        }
    }

    isRowDisabled(item: any) {
        return (
            this.tableConfig &&
            this.tableConfig.DisableRowFunc &&
            this.tableConfig.DisableRowFunc(item, this.dataSource.data)
        );
    }

    getRowClass(item: any) {
        if (this.tableConfig && this.tableConfig.RowClassFunc) {
            return this.tableConfig.RowClassFunc(item);
        }
        return "";
    }

    isRowExpanded(item: any) {
        return item.IsExpanded;
    }

    onSelectUnselect(item: any) {
        if (
            this.isSelectionActive() &&
            this.tableConfig.Selection == EffTableSelectionEnum.Single
        ) {
            this.dataSource.data.forEach((dataItem) => {
                if (dataItem != item) {
                    dataItem.IsSelected = false;
                }
            });
            item.IsSelected = !item.IsSelected;
        }
    }

    isSelectionActive() {
        return (
            this.tableConfig.Selection != undefined &&
            this.tableConfig.Selection != null &&
            this.tableConfig.Selection != EffTableSelectionEnum.None
        );
    }
}
