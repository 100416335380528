import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { DataBreachModel } from "@models/event/DataBreachModel";
import { Observable } from "rxjs";
import { BreachPermissionModel } from "@models/event/BreachPermissionModel";
import { BreachHeavyStandingDataCollection } from "@models/event/BreachHeavyStandingDataCollection";
import { BreachLightStandingDataCollection } from "@models/event/BreachLightStandingDataCollection";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";

@Injectable()
export class DataBreachDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "databreach");
    }

    public getDataBreach(guid: string): Observable<DataBreachModel> {
        const url = `/${guid}/databreach`;
        return this.get<DataBreachModel>(url);
    }

    public getLightSd(guid: string): Observable<BreachLightStandingDataCollection> {
        const url = `/${guid}/light`;
        return this.get<BreachLightStandingDataCollection>(url);
    }

    public getHeavySd(guid: string): Observable<BreachHeavyStandingDataCollection> {
        const url = `/${guid}/heavy`;
        return this.get<BreachHeavyStandingDataCollection>(url);
    }

    public getLightSdForNewBreach(): Observable<BreachLightStandingDataCollection> {
        const url = "/light";
        return this.get<BreachLightStandingDataCollection>(url);
    }

    public getHeavySdForNewBreach(): Observable<BreachHeavyStandingDataCollection> {
        const url = "/heavy";
        return this.get<BreachHeavyStandingDataCollection>(url);
    }

    public getPermissions(lossEventGuid: string): Observable<BreachPermissionModel> {
        const url = `/${lossEventGuid}/permissions`;
        return this.get<BreachPermissionModel>(url);
    }

    public getPermissionsForNewEvent(): Observable<BreachPermissionModel> {
        const url = `/permissions`;
        return this.get<BreachPermissionModel>(url);
    }

    public getHistory(eventGuid: string): Observable<HistoryModel[]> {
        const url = `/${eventGuid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(eventGuid: string, historyDate: string): Observable<HistoryChange[]> {
        const url = `/${eventGuid}/history/${historyDate}`;

        return this.get<HistoryChange[]>(url);
    }

    getCustomFields(): Observable<CustomFieldStandingDataModel[]> {
        const url = `/customfields`;
        return this.get<CustomFieldStandingDataModel[]>(url);
    }
}
