<div menuSystem [cerrixTab]="tab">
    <div [attr.name]="'Dev Management'" menuicon="fas fa-code" menusystemsubmenu>
        <div name="Import" menuicon="fas fa-file-import" menusystemitem>
            <dev-import></dev-import>
        </div>
        <div name="Export" menuicon="fas fa-file-export" menusystemitem>
            <dev-export></dev-export>
        </div>
        <div name="Custom roles" menuicon="fas fa-user-tag" menusystemitem>
            <dev-cerrixrole></dev-cerrixrole>
        </div>
        <div name="Power BI" menuicon="fas fa-chart-area" menusystemitem>
            <dev-powerbi></dev-powerbi>
        </div>
        <div name="Executables" menuicon="fas fa-terminal" menusystemitem>
            <dev-code-executions></dev-code-executions>
        </div>
        <div name="Actions" menuicon="far fa-angle-double-right" menusystemitem>
            <dev-actions></dev-actions>
        </div>
        <div name="Application Keys" menuicon="far fa-key" menusystemitem>
            <dev-keys></dev-keys>
        </div>
    </div>
</div>
