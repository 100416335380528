import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { LocalizationService } from "@app/shared/localization";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TimepickerComponent } from "ngx-bootstrap/timepicker";
import { isNumberKey } from "@methods/CommonMethods";

@Component({
    selector: "date-input",
    templateUrl: "./date-input.component.html",
    styleUrls: ["./date-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateInputComponent,
            multi: true,
        },
    ],
})
export class DateInputComponent implements OnInit, ControlValueAccessor {
    @ViewChild("timepicker") timepicker: TimepickerComponent;

    placeholder: string;

    valid = true;
    isInitialized = false;
    private _value: Date;
    private _bsValue: Date;

    public _timeValue: Date;

    _onChangeFormValidation = Function.prototype;
    _onTouchedFormValidation = Function.prototype;

    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() disabled = false;
    @Input() required = false;
    @Input() showTimePicker = false;

    @Output() valueChange = new EventEmitter<Date>();

    /**
     * Input for a UTC Date
     */
    @Input()
    set value(value: Date) {
        if (this._value === value) {
            return;
        }

        // showTimePicker might not have been set yet
        if (this.isInitialized) {
            const dateValue = this.fixInput(value);
            this.setWithUtcDate(dateValue);
        } else {
            this._value = value;
            this.bsValue = value;
            if (this.showTimePicker) {
                this.timeValue = value;
            }
        }
    }
    get value(): Date {
        return this._value;
    }

    /**
     * Internal user Date
     */
    set bsValue(value: Date) {
        if (this.required) {
            this.valid = value !== null;
        }

        if (this._bsValue === value) {
            return;
        }

        this._bsValue = value;
        this.setCombinedDateTime();
    }
    get bsValue(): Date {
        return this._bsValue;
    }

    set timeValue(value: Date) {
        this._timeValue = value;
        this.setCombinedDateTime();
    }

    get timeValue(): Date {
        return this._timeValue;
    }

    constructor(private localizationService: LocalizationService) {}

    ngOnInit(): void {
        const dateFormat = this.localizationService.localizationInfo.dateFormat;

        this.placeholder = dateFormat.toLowerCase();

        if (this.required) {
            this.placeholder += " (Req.)";
        }

        this.isInitialized = true;
    }

    setCombinedDateTime() {
        this.valueChanged();

        const datetime = this._bsValue;

        if (this.showTimePicker) {
            if (this.timeValue) {
                datetime.setHours(this.timeValue.getHours());
                datetime.setMinutes(this.timeValue.getMinutes());
                this._value = datetime;
            } else {
                // reset
                this._value = null;
            }
        } else {
            this._value = datetime
                ? new Date(
                      Date.UTC(datetime.getFullYear(), datetime.getMonth(), datetime.getDate())
                  )
                : null;
        }

        this._onChangeFormValidation(this._value);
        this.valueChange.emit(this._value);
    }

    // Validate input date
    valueChanged() {
        let validDate = false;
        if (this._bsValue && (this.timeValue || !this.showTimePicker)) {
            validDate = true;
        }

        // Mark control as invalid if date is invalid
        if (!validDate) {
            if (this.showTimePicker && this.timepicker) {
                this.timepicker.invalidHours = false;
                this.timepicker.invalidMinutes = false;
            }
        }
    }

    // Only allow numbers, dashes (45) or control keys (<32) in the data input field
    dateKeyDown(event) {
        const dashCode = 45;
        const charCode = event.charCode;
        if (charCode > 31 && charCode !== dashCode && !isNumberKey(event)) {
            event.preventDefault();
        }

        this._onTouchedFormValidation();
    }

    /**
     * Set values with a UTC date
     */
    private setWithUtcDate(value: Date) {
        this._value = value;
        this._bsValue = value;
        this._timeValue = value;
    }

    /**
     * Dates are turned into string with JSON.stringify but not restored with JSON.parse
     */
    private fixInput(value: Date | string): Date {
        if (value) {
            // Make sure value is a Date and create clone to not edit original value
            // because you could be editing a default value from generic field editor then
            value = new Date(value);

            if (this.showTimePicker) {
                value.setSeconds(0, 0);
            } else {
                value.setUTCHours(0, 0, 0, 0);
            }
        }

        return <Date>value;
    }

    /**
     * Form validation input
     */
    writeValue(obj: any): void {
        this.value = obj;
    }

    /**
     * Register form validation change event
     */
    registerOnChange(fn: any): void {
        this._onChangeFormValidation = fn;
    }

    /**
     * Register form validation touched event
     */
    registerOnTouched(fn: any): void {
        this._onTouchedFormValidation = fn;
    }

    /**
     * Handle form validation disable
     */
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    callTouchedFormValidation() {
        if (this._onTouchedFormValidation) {
            this._onTouchedFormValidation();
        }
    }
}
