import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { ExcelHelper } from "@app/shared/helpers/excel-helper";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { FormValidationMessageHelper } from "@app/shared/helpers/form-validation-message-helper";
import { FormValidators } from "@app/shared/helpers/form-validators";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { closeDirtyPageCheck, toPromise } from "@methods/CommonMethods";
import { getFormControl, getFormValue, nameof } from "@methods/jeffs-toolkit";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { SettingsItem } from "@models/settings/SettingsItem";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { TabService } from "@services/tabs/TabService";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EvidenceComponent } from "./evidence/adv-eff-evidence.component";
import {
    EffectivenessConclusionsModel,
    EffectivenessModel,
    EffectivenessSourceDocumentsModel,
    EvidenceSampleEditModel,
    LinkedRiskModel,
    SourceDocumentUploadModel,
    WorkflowConclusionModel,
    WorkflowGenerateSamplesModel,
    WorkflowHistoryModel,
    WorkflowStepPermissionModel,
} from "../shared/models";
import { getAssessmentScores } from "../shared/adv-eff-shared-methods";
import { EffectivenessStatusType, SampleType, SampleGenerationType } from "../shared/enums";
import { AdvEffDataService, AdvEffWorkflowDataService } from "../shared/services";

@Component({
    selector: "adv-eff-detail",
    templateUrl: "./adv-eff-detail.component.html",
    styleUrls: ["./adv-eff-detail.component.scss"],
})
export class AdvEffDetailComponent implements OnInit {
    // int.MaxValue
    maxSamplePopulationSize = 2147483647;

    tab: TabModel;
    tabID: string;
    id: string;

    @ViewChild(EvidenceComponent)
    evidenceComponent: EvidenceComponent;

    @ViewChild("generateSamplesDialog", { static: true })
    generateSamplesDialog: ElementRef;

    get finishedLoading(): boolean {
        return !!this.details && !!this.workflowStepPermission;
    }

    hideActionBar = false;
    dialogRef: BsModalRef;

    workflowStepPermission = new WorkflowStepPermissionModel();
    nonOccurrenceCompleteAllowed = false;
    reviewNonOccurrenceAllowed = false;
    testNonOccurrenceAllowed = false;
    sourceDocumentUploadAllowed = false;
    sampleGenerationAllowed = false;
    completeUploadingEvidenceAllowed = false;
    completeAssessmentAllowed = false;
    reviewAllowed = false;
    completeAllowed = false;
    requestEvidenceUpload = false;

    showAllFields: boolean;
    showFields: boolean;
    defaultToggleSetting: boolean;

    useReviewerSetting: boolean;
    useReviewer: boolean;

    details: EffectivenessModel;
    sourceDocuments: DocumentModel[];
    sourceDocumentsFormGroup: FormGroup;
    evidenceSamples: EvidenceSampleEditModel[];
    conclusions: EffectivenessConclusionsModel;
    linkedRisks: LinkedRiskModel[];
    workflowHistory: WorkflowHistoryModel[];
    generateSamplesModel: WorkflowGenerateSamplesModel;
    generateSamplesFormGroup: FormGroup;

    hasError: boolean = false;
    warningShown: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private advancedTestingService: AdvEffDataService,
        private workflowDataService: AdvEffWorkflowDataService,
        private settingsService: SettingsDataService,
        private tabService: TabService,
        private pages: Pages,
        private toastr: ToastrService,
        private changeDetector: ChangeDetectorRef,
        private _promptService: CerrixPromptService,
        private formBuilder: FormBuilder,
        private controlDataService: ControlDataService
    ) {}

    async ngOnInit(): Promise<void> {
        const oldScreenOrigin =
            !isGuid(this.tab.id) &&
            this.route.snapshot.url.findIndex(
                (x) =>
                    x.path === "effectivenesssourcedocument" || x.path === "effectivenessevidence"
            ) >= 0;
        if (oldScreenOrigin) {
            const snapShotUrl = this.route.snapshot.url;
            const effectivenessId = +snapShotUrl[snapShotUrl.length - 1].path;

            this.tab.id = await toPromise(
                this.advancedTestingService.getEffectivenessGuidById(effectivenessId)
            );
        }

        this.settingsService
            .getSetting(ApplicationSettings.ShowOnlyEssentialInformation)
            .subscribe((setting) => {
                this.showAllFields = !setting.BoolValue;
            });

        this.loadData(this.tab.id);
        this.tab.beforeClose = (checkOnly) => {
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !this.isDirty());
        };
    }

    loadData(guid: string): void {
        this.loadWorkflowPermissions(guid).then(
            () => {
                this.advancedTestingService.getEffectiveness(guid).subscribe((details) => {
                    this.useReviewer = details.reviewEnabled;

                    this.details = details;

                    this.tab.showLoader = false;

                    this.loadSourceDocumentsData(guid);

                    this.advancedTestingService.getEvidenceSamples(guid).subscribe((items) => {
                        this.evidenceSamples = items;
                    });

                    this.advancedTestingService.getConclusions(guid).subscribe((conclusions) => {
                        this.conclusions = conclusions;
                    });
                });
            },
            () => {
                this.tab.close(false);
            }
        );
    }

    private loadSourceDocumentsData(guid: string): void {
        if (!this.workflowStepPermission.canViewSourceDocuments) {
            return;
        }

        this.sourceDocuments = null;
        this.sourceDocumentsFormGroup = null;

        this.advancedTestingService.getSourceDocuments(guid).subscribe((sourceDocumentsModel) => {
            this.createSourceDocumentsFormGroup(sourceDocumentsModel);

            this.sourceDocuments = sourceDocumentsModel.documents;
        });
    }

    private createSourceDocumentsFormGroup(
        sourceDocumentsModel: EffectivenessSourceDocumentsModel
    ): void {
        const disabled = !this.sourceDocumentUploadAllowed && !this.sampleGenerationAllowed;
        const columnName = ExcelHelper.convertNumberToExcelColumn(sourceDocumentsModel.idColumn);

        this.sourceDocumentsFormGroup = this.formBuilder.group({
            idColumn: new FormControl({ value: columnName, disabled }, [
                FormValidators.excelColumnName,
            ]),
            rowsToSkip: new FormControl({ value: sourceDocumentsModel.rowsToSkip, disabled }, [
                Validators.min(0),
                // Max Excel rows is 1,048,576
                Validators.max(1048575),
                Validators.pattern(/^[0-9]+$/),
            ]),
        });
    }

    loadWorkflowPermissions(guid: string): Promise<WorkflowStepPermissionModel> {
        return this.workflowDataService
            .getWorkflowStepPermission(guid)
            .pipe(
                map((stepPermission) => {
                    this.workflowStepPermission = stepPermission;
                    this.processPermission();

                    return stepPermission;
                })
            )
            .toPromise();
    }

    reset(guid: string): void {
        this.workflowStepPermission = null;
        this.details = null;
        this.evidenceSamples = null;
        this.conclusions = null;
        this.linkedRisks = null;
        this.workflowHistory = null;

        this.loadData(guid);
    }

    processPermission(): void {
        this.nonOccurrenceCompleteAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.nonOccurrenceCompleted
        );
        this.reviewNonOccurrenceAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.reviewNonOccurrence
        );
        this.testNonOccurrenceAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.nonOccurrence
        );
        this.sourceDocumentUploadAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.waitingForSourceDocuments
        );
        this.sampleGenerationAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.waitingForSamples
        );
        this.completeUploadingEvidenceAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.requestEvidence
        );
        this.requestEvidenceUpload = this.completeUploadingEvidenceAllowed;
        this.completeAssessmentAllowed = this.isCompleteAllowed(
            EffectivenessStatusType.waitingForConclusion
        );
        this.reviewAllowed = this.isCompleteAllowed(EffectivenessStatusType.waitingForReviewer);
        this.completeAllowed = this.isCompleteAllowed(EffectivenessStatusType.completed);

        this.showFields =
            !(
                this.sourceDocumentUploadAllowed ||
                this.sampleGenerationAllowed ||
                this.requestEvidenceUpload
            ) || this.showAllFields;
    }

    private isCompleteAllowed(status: EffectivenessStatusType): boolean {
        if (this.workflowStepPermission.isCompleteAllowed)
            return this.workflowStepPermission.status === status;
        return false;
    }

    async save(): Promise<void> {
        this.hasError = false;

        const prompt = this.getSavingPrompt();

        try {
            if (this.sourceDocumentUploadAllowed || this.sampleGenerationAllowed) {
                await this.handleSourceSave(prompt);
            } else if (this.workflowStepPermission.evidenceSaveAllowed) {
                await this.handleEvidenceSave(prompt);
            }
        } catch {
            this.hasError = true;
        } finally {
            this.loadWorkflowPermissions(this.details.guid);
            prompt.close();
        }
    }

    private async handleSourceSave(prompt: CerrixPromptComponent): Promise<void> {
        if (
            this.sourceDocuments.every((sd) => !sd.IsNew && !sd.Changed && !sd.Deleted) &&
            this.sourceDocumentsFormGroup.pristine
        ) {
            return;
        }

        if (!this.validSourceDocumentsInput()) {
            return;
        }

        prompt.data.title = "Saving source documents, please wait...";

        const sourceDocumentsModel = this.getSourceDocumentsModel();
        await this.advancedTestingService
            .postSourceDocuments(this.details.guid, sourceDocumentsModel)
            .toPromise();

        this.loadSourceDocumentsData(this.details.guid);
    }

    private async handleEvidenceSave(prompt: CerrixPromptComponent): Promise<void> {
        const validation = this.evidenceComponent.validateEvidence();
        if (!validation.valid) {
            validation.errors.forEach((e) => this.toastr.warning(e));
            this.evidenceComponent.scrollToFirstInvalidComponent();
            return;
        }

        const saveObservables = this.evidenceComponent.saveChanges();
        if (saveObservables.length === 0) {
            return;
        }

        let progress = 0;
        for (const saveObservable of saveObservables) {
            prompt.data.title = `Saving changes ${++progress} of ${
                saveObservables.length
            }, please wait...`;

            const result = await saveObservable.toPromise();

            // Need to update workflow permissions when status has changed (between tester score saves)
            if (
                result.status !== this.workflowStepPermission.status &&
                progress < saveObservables.length
            ) {
                await this.loadWorkflowPermissions(this.details.guid);
                this.changeDetector.detectChanges();
            }
        }
    }

    openReopenNonOccurrenceDialog(): void {
        this.confirmDiscardChanges(() => {
            this._promptService
                .confirm("Reopen Test Plan?", "Are you sure you want to reopen the Test Plan?")
                .onConfirm()
                .subscribe(() => {
                    const prompt = this.getSavingPrompt();

                    this.workflowDataService
                        .postWorkflowNonOccurrenceReopen(this.details.guid)
                        .subscribe(
                            () => {
                                this.reset(this.details.guid);
                                prompt.close();
                            },
                            (error) => {
                                prompt.close();
                            }
                        );
                });
        });
    }

    openReviewerNonOccurrenceAcceptDialog(): void {
        this.confirmDiscardChanges(() => {
            const fields: GenericListField[] = [];
            fields.push({
                prettyName: "Comment",
                fieldName: nameof<WorkflowConclusionModel>((x) => x.comment),
                required: true,
                fieldType: GenericListFieldType.TextArea,
            });

            return this._promptService
                .prompt({
                    height: "350px",
                    data: {
                        title: "Add comment",
                        fields,
                        confirmButton: {
                            text: "Save",
                        },
                    },
                })
                .getResult()
                .subscribe((result: WorkflowConclusionModel) => {
                    const prompt = this.getSavingPrompt();
                    this.workflowDataService
                        .postWorkflowNonOccurrenceAcceptReviewer(this.details.guid, result)
                        .subscribe(
                            () => {
                                prompt.close();
                                this.reset(this.details.guid);
                            },
                            (error) => {
                                prompt.close();
                            }
                        );
                });
        });
    }

    openReviewerNonOccurrenceModifyConclusionDialog(): void {
        const dialogConfig = this.createDialogConfig("Add comment", true, true);
        this.PromptDialog<WorkflowConclusionModel>(
            dialogConfig,
            this.workflowDataService.postWorkflowNonOccurrenceModifyReviewer.bind(
                this.workflowDataService
            ),
            { titleText: "Non occurrence conclusion as reviewer", saveButtonText: "Modify" }
        );
    }

    openReviewerNonOccurrenceRejectDialog(): void {
        const dialogConfig = this.createDialogConfig("Add comment", false, true);
        this.PromptDialog<WorkflowConclusionModel>(
            dialogConfig,
            this.workflowDataService.postWorkflowNonOccurrenceRejectReviewer.bind(
                this.workflowDataService
            ),
            { titleText: "Reason of rejection", saveButtonText: "Reject" }
        );
    }

    openTesterNonOccurrenceConclusionDialog(): void {
        const dialogConfig = this.createDialogConfig("Add comment", true, true);
        this.PromptDialog<WorkflowConclusionModel>(
            dialogConfig,
            this.workflowDataService.postWorkflowNonOccurrenceAcceptTester.bind(
                this.workflowDataService
            ),
            { titleText: "Non occurrence conclusion as tester", saveButtonText: "Save" }
        );
    }

    openTesterNonOccurrenceRejectDialog(): void {
        const dialogConfig = this.createDialogConfig("Add comment", false, true);
        this.PromptDialog<WorkflowConclusionModel>(
            dialogConfig,
            this.workflowDataService.postWorkflowNonOccurrenceRejectTester.bind(
                this.workflowDataService
            ),
            { titleText: "Reason of rejection", saveButtonText: "Reject" }
        );
    }

    public async finishUploadingSourceDocuments(): Promise<void> {
        if (!this.validSourceDocumentsInput()) return;

        const sourceDocumentsModel = this.getSourceDocumentsModel();
        if (!sourceDocumentsModel.documents.some((doc) => !doc.Deleted)) {
            const confirm = await this._promptService
                .confirm("Confirm", "Complete this step without uploading source document?")
                .toPromise();
            if (!confirm) return;
        }

        const prompt = this.getSavingPrompt();
        prompt.data.title = "Finishing upload source documents, please wait...";

        try {
            await this.workflowDataService
                .postWorkflowUploadSourceDocuments(this.details.guid, sourceDocumentsModel)
                .toPromise();
            this.reset(this.details.guid);
        } finally {
            prompt.close();
        }
    }

    async controlDidNotOccur(): Promise<void> {
        await this.save();
        if (!this.hasError) {
            this._promptService
                .confirm("Update status", "Continue control didn't occur?")
                .onConfirm()
                .subscribe(() => {
                    const prompt = this.getSavingPrompt();

                    this.workflowDataService
                        .postWorkflowControlDidNotOccur(this.details.guid)
                        .subscribe(
                            () => {
                                this.reset(this.details.guid);
                                prompt.close();
                            },
                            (error) => {
                                prompt.close();
                            }
                        );
                });
        }
    }

    async openGenerateSampleDialog(): Promise<void> {
        const prompt = this.getSavingPrompt();
        try {
            await this.handleSourceSave(prompt);
        } catch {
            return;
        } finally {
            prompt.close();
        }

        this.openGenerateSamplesDialog(
            this.workflowDataService.postWorkflowGenerateSamples.bind(this.workflowDataService),
            {
                effectivenessGuid: this.details.guid,
                sampleGenerationType: this.details.sampleGenerationType,
                startDate: this.details.startDate,
                endDate: this.details.endDate,
            },
            { class: "modal-lg" }
        );
    }

    async completeUploadingEvidence(): Promise<void> {
        await this.save();
        if (!this.hasError) {
            this._promptService
                .confirm("Update status", "Complete uploading evidence?")
                .onConfirm()
                .subscribe(() => {
                    const prompt = this.getSavingPrompt();

                    this.workflowDataService
                        .postWorkflowCompleteEvidenceUpload(this.details.guid)
                        .subscribe(
                            () => {
                                this.reset(this.details.guid);
                                prompt.close();
                            },
                            (error) => {
                                prompt.close();
                            }
                        );
                });
        }
    }

    async openFinalConclusionDialog(): Promise<void> {
        await this.save();

        if (!this.hasError) {
            const dialogConfig = this.createDialogConfig("Add comment", true, true);
            this.PromptDialog<WorkflowConclusionModel>(
                dialogConfig,
                this.workflowDataService.postWorkflowAcceptTester.bind(this.workflowDataService),
                { titleText: "Final conclusion as tester", saveButtonText: "Save" }
            );
        }
    }

    async openMissingEvidenceDialog(): Promise<void> {
        await this.save();

        if (!this.hasError) {
            const dialogConfig = this.createDialogConfig("Add comment", false, true);
            this.PromptDialog<WorkflowConclusionModel>(
                dialogConfig,
                this.workflowDataService.postWorkflowRejectTester.bind(this.workflowDataService),
                { titleText: "Reason of rejection", saveButtonText: "Reject" }
            );
        }
    }

    async openReviewerAcceptDialog() {
        await this.save();

        if (!this.hasError) {
            const dialogConfig = this.createDialogConfig("Add comment", false, true);
            this.PromptDialog<WorkflowConclusionModel>(
                dialogConfig,
                this.workflowDataService.postWorkflowAcceptReviewer.bind(this.workflowDataService),
                { titleText: "Reason of acceptance", saveButtonText: "Accept" }
            );
        }
    }

    async openReviewerModifyConclusionDialog() {
        await this.save();

        if (!this.hasError) {
            const dialogConfig = this.createDialogConfig("Add comment", true, true);
            this.PromptDialog<WorkflowConclusionModel>(
                dialogConfig,
                this.workflowDataService.postWorkflowModifyReviewer.bind(this.workflowDataService),
                { titleText: "Final conclusion as reviewer", saveButtonText: "Modify" }
            );
        }
    }

    async openReviewerRejectDialog() {
        await this.save();

        if (!this.hasError) {
            const dialogConfig = this.createDialogConfig("Add comment", false, true);
            this.PromptDialog<WorkflowConclusionModel>(
                dialogConfig,
                this.workflowDataService.postWorkflowRejectReviewer.bind(this.workflowDataService),
                { titleText: "Reason of rejection", saveButtonText: "Reject" }
            );
        }
    }

    openTestPeriodScoreDialog() {
        const dialogConfig = this.createDialogConfig("Add score", true, false);
        this.PromptDialog<WorkflowConclusionModel>(
            dialogConfig,
            this.workflowDataService.postTestPeriodScore.bind(this.workflowDataService),
            { effectivenessModel: this.details }
        );
    }

    openReopenDialog() {
        const dialogConfig = this.createDialogConfig("Add comment", false, true);
        this.PromptDialog<WorkflowConclusionModel>(
            dialogConfig,
            this.workflowDataService.postWorkflowReopen.bind(this.workflowDataService),
            { titleText: "Reopen test plan?", saveButtonText: "Reopen" }
        );
    }

    openControl() {
        const trimmedIdentifier = this.details.controlIdentifier.replace(/^0+/g, "");
        this.tabService.generateTab(
            this.pages.ControlDetail,
            this.details.controlGuid,
            `(C) ${trimmedIdentifier} - ${this.details.controlName}`
        );
    }

    openRisk(risk: LinkedRiskModel) {
        const trimmedIdentifier = risk.identifier.replace(/^0+/g, "");
        this.tabService.generateTab(
            this.pages.RiskDetail,
            risk.guid,
            `(R) ${trimmedIdentifier} - ${risk.name}`
        );
    }

    isDirty(): boolean {
        if (this.sourceDocumentUploadAllowed || this.sampleGenerationAllowed) return false;

        return this.evidenceComponent && this.evidenceComponent.isDirty();
    }

    confirmDiscardChanges(callback: () => void) {
        if (this.isDirty()) {
            this._promptService
                .confirm(
                    "Unsaved changes",
                    "There are unsaved changes, are you sure you want to discard your changes?"
                )
                .onConfirm()
                .subscribe(() => {
                    callback();
                });
        } else {
            callback();
        }
    }

    protected async onSampleEditRemark(sample: EvidenceSampleEditModel): Promise<any> {
        let prompt: CerrixPromptComponent;
        try {
            prompt = this._promptService.prompt({
                hasBackdrop: false,
                data: {
                    title: "Update remarks",
                    fields: [
                        {
                            fieldType: GenericListFieldType.TextArea,
                            fieldName: "remarks",
                            prettyName: "Remarks",
                            defaultValue: sample.remarks,
                        },
                    ],
                    confirmButton: {
                        text: "Update",
                    },
                },
            });

            const result = await firstValueFrom(prompt.getResult());
            if (result) {
                await firstValueFrom(
                    this.advancedTestingService.postEvidenceSampleRemark(
                        this.details.guid,
                        sample.guid,
                        {
                            remark: result.remarks,
                        }
                    )
                );

                sample.remarks = result.remarks;
            }
        } finally {
            prompt?.close();
        }
    }

    onMenuItemClick(menuItem) {
        if (!menuItem || !menuItem.name) {
            return;
        }

        this.hideActionBar = menuItem.name !== "Details";

        switch (menuItem.name) {
            case "Risks": {
                if (!this.linkedRisks) {
                    this.advancedTestingService
                        .getLinkedRisks(this.details.guid)
                        .subscribe((linkedRisks) => {
                            this.linkedRisks = linkedRisks;
                        });
                }
                break;
            }
            case "Workflow History": {
                if (!this.workflowHistory) {
                    this.workflowDataService
                        .getWorkflowHistory(this.details.guid)
                        .subscribe((history) => {
                            this.workflowHistory = history;
                        });
                }
                break;
            }
        }
    }

    private validSourceDocumentsInput(): boolean {
        if (this.sourceDocumentsFormGroup.invalid) {
            FormValidationHelper.markAllAsTouched(this.sourceDocumentsFormGroup);

            const validationMessage = new FormValidationMessageHelper<SourceDocumentUploadModel>({
                idColumn: "ID column",
                rowsToSkip: "Rows to skip",
                documents: "Documents",
            })
                .processFormGroup(this.sourceDocumentsFormGroup)
                .getErrorMessage();

            this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

            return false;
        }

        return true;
    }

    private getSourceDocumentsModel(): SourceDocumentUploadModel {
        const sourceDocumentsModel = new SourceDocumentUploadModel();
        sourceDocumentsModel.documents = this.sourceDocuments;
        const columnName = getFormValue<SourceDocumentUploadModel>(
            this.sourceDocumentsFormGroup,
            (sdu) => sdu.idColumn
        );
        sourceDocumentsModel.idColumn = ExcelHelper.convertExcelColumnToNumber(columnName);
        sourceDocumentsModel.rowsToSkip = getFormValue<SourceDocumentUploadModel>(
            this.sourceDocumentsFormGroup,
            (sdu) => sdu.rowsToSkip
        );

        return sourceDocumentsModel;
    }

    private createDialogConfig(title: string, conclusionVisible: boolean, commentVisible: boolean) {
        const fields = [];
        if (conclusionVisible) {
            fields.push({
                prettyName: "Conclusion",
                fieldName: "scoreId",
                fieldType: GenericListFieldType.ColoredSelect,
                placeholder: "Required",
                required: true,
                selfInit: true,
                getDataMethod: () => getAssessmentScores(this.controlDataService),
            });
        }
        if (commentVisible) {
            fields.push({
                prettyName: "Comment",
                fieldName: "comment",
                placeholder: "Required",
                required: true,
                fieldType: GenericListFieldType.TextArea,
            });
        }
        const dialogConfig = { title, fields };
        return dialogConfig;
    }

    private PromptDialog<T>(
        config: any,
        postFunction: (guid: string, model: T) => Observable<any>,
        initialState: object = null,
        modalOptions: ModalOptions = null
    ) {
        if (!modalOptions) {
            modalOptions = {};
        }
        modalOptions.initialState = initialState;

        this._promptService
            .prompt({
                hasBackdrop: false,
                data: {
                    confirmOnEnter: false,
                    closeOnEsc: false,
                    title: config.title,
                    fields: config.fields,
                    confirmButton: {
                        text: "OK",
                    },
                },
            })
            .getResult()
            .subscribe((result) => {
                if (result) {
                    const prompt = this.getSavingPrompt();
                    this.advancedTestingService;
                    postFunction(this.details.guid, result).subscribe(
                        () => {
                            prompt.close();
                            this.reset(this.details.guid);
                        },
                        (error) => {
                            prompt.close();
                        }
                    );
                }
            });
    }

    private async openGenerateSamplesDialog(
        postFunction: (guid: string, model: WorkflowGenerateSamplesModel) => Observable<any>,
        initialState: object = null,
        modalOptions: ModalOptions = null
    ) {
        const setting = await this.settingsService
            .getSetting(ApplicationSettings.GenerateSamplesType)
            .toPromise();

        const sampleTypeOptions = this.checkSampleType(setting.TextValue);

        this.generateSamplesModel = <WorkflowGenerateSamplesModel>{
            populationSize: this.maxSamplePopulationSize,
            sampleTypeOptions,
            sampleGenerationType: this.details.sampleGenerationType,
        };

        this.generateSamplesFormGroup = this.getGenerateSamplesFormGroup(sampleTypeOptions);

        this._promptService
            .prompt({
                hasBackdrop: false,
                data: {
                    title: "Generate samples",
                    customTemplate: this.generateSamplesDialog,
                    confirmButton: {
                        text: "Finish generating samples",
                        action: () => {
                            return this.validateSampleDialog(this.generateSamplesModel);
                        },
                    },
                },
            })
            .getResult()
            .subscribe((result) => {
                if (result) {
                    this.getFormGroupData();
                    const prompt = this.getSavingPrompt();
                    postFunction(this.details.guid, this.generateSamplesModel).subscribe(() => {
                        prompt.close();
                        this.reset(this.details.guid);
                    });
                }
            });
    }

    private getFormGroupData() {
        const gsPopulationSize = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.populationSize
        );

        const gsDocumentGuid = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.documentGuid
        );

        const gsNumberOfSamples = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.numberSamples
        );

        const gsDateSamples = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.dateSamples
        );

        const gsTextSamples = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.textSamples
        );

        const gsSampleType = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.sampleType
        );

        const gsNumberOfTestSamples = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (c) => c.numberOfTestSamples
        );

        if (this.validateSampleDialog(this.generateSamplesModel)) {
            this.generateSamplesModel.sampleType = gsSampleType.value;
            this.generateSamplesModel.numberOfTestSamples = gsNumberOfTestSamples.value;
            if (this.generateSamplesModel.sampleType == SampleType.number) {
                this.generateSamplesModel.populationSize = gsPopulationSize.value;
            } else if (this.generateSamplesModel.sampleType == SampleType.spreadsheet) {
                this.generateSamplesModel.documentGuid = gsDocumentGuid.value;
                this.generateSamplesModel.populationSize = gsPopulationSize.value;
            }
            if (this.generateSamplesModel.sampleGenerationType == SampleGenerationType.manual) {
                if (this.generateSamplesModel.sampleType == SampleType.number) {
                    this.generateSamplesModel.numberSamples = gsNumberOfSamples.value;
                } else if (this.generateSamplesModel.sampleType == SampleType.date) {
                    this.generateSamplesModel.dateSamples = gsDateSamples.value;
                } else if (this.generateSamplesModel.sampleType == SampleType.text) {
                    this.generateSamplesModel.textSamples = gsTextSamples.value;
                }
            }
        }
    }

    private getGenerateSamplesFormGroup(sampleTypeOptions: any[]): FormGroup {
        const formGroup = this.formBuilder.group({
            sampleType: new FormControl(sampleTypeOptions[0].id, Validators.required),
            documentGuid: new FormControl(null),
            numberOfTestSamples: new FormControl(1, [
                Validators.required,
                Validators.min(1),
                Validators.max(100),
                Validators.pattern(/^[0-9]+$/),
            ]),
            populationSize: new FormControl(1, [
                Validators.required,
                Validators.min(1),
                Validators.max(this.maxSamplePopulationSize),
                Validators.pattern(/^[0-9]+$/),
            ]),
            numberSamples: this.formBuilder.array([]),
            dateSamples: this.formBuilder.array([]),
            textSamples: this.formBuilder.array([]),
        });

        getFormControl<WorkflowGenerateSamplesModel>(
            formGroup,
            (wgsm) => wgsm.sampleType
        ).valueChanges.subscribe((sampleType) => {
            this.generateSamplesModel.sampleType = sampleType;
            sampleType = parseInt(sampleType, 10);
            this.setPopulationSizeAndSourceDocumentValidation(sampleType);
        });
        getFormControl<WorkflowGenerateSamplesModel>(
            formGroup,
            (wgsm) => wgsm.numberOfTestSamples
        ).valueChanges.subscribe(() => {
            const sampleType = getFormValue<WorkflowGenerateSamplesModel>(
                formGroup,
                (x) => x.sampleType
            );
            this.setPopulationSizeAndSourceDocumentValidation(sampleType);
        });
        getFormControl<WorkflowGenerateSamplesModel>(
            formGroup,
            (wgsm) => wgsm.populationSize
        ).valueChanges.subscribe(() => {
            const numberSamplesFormControl = getFormControl<WorkflowGenerateSamplesModel>(
                formGroup,
                (wgsm) => wgsm.numberSamples
            );
            const numberSampleControls = (numberSamplesFormControl as FormArray).controls;
            for (const numberSampleControl of numberSampleControls) {
                numberSampleControl.setValidators(this.getNumberSampleValidators());
                numberSampleControl.updateValueAndValidity();
            }
        });

        return formGroup;
    }

    toggleFields(e) {
        this.showAllFields = e.checked;
        this.showFields =
            !(
                this.sourceDocumentUploadAllowed ||
                this.sampleGenerationAllowed ||
                this.requestEvidenceUpload
            ) || this.showAllFields;
    }

    /**
     * Set population size and trigger control to update manual input
     */
    private setPopulationSizeAndSourceDocumentValidation(sampleType: SampleType) {
        const populationSizeControl = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (wgsm) => wgsm.populationSize
        );
        // Set Population Control. Only Number uses this
        if (sampleType == SampleType.number) {
            populationSizeControl.enable();
            populationSizeControl.setValidators([
                Validators.required,
                FormValidators.min<WorkflowGenerateSamplesModel>(
                    nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.numberOfTestSamples)
                ),
                Validators.max(this.generateSamplesModel.maxPopulationSize),
                Validators.pattern(/^[0-9]+$/),
            ]);
        } else {
            populationSizeControl.disable();
            populationSizeControl.setValidators(null);
        }

        const documentGuidControl = getFormControl<WorkflowGenerateSamplesModel>(
            this.generateSamplesFormGroup,
            (wgsm) => wgsm.documentGuid
        );
        // Set Document Guid Control. Only Spreadsheet uses this
        if (sampleType == SampleType.spreadsheet) {
            documentGuidControl.enable();
            documentGuidControl.setValidators([Validators.required]);
        } else {
            documentGuidControl.disable();
            documentGuidControl.setValidators(null);
        }

        populationSizeControl.updateValueAndValidity();
    }

    private getNumberSampleValidators(): ValidatorFn[] {
        return [Validators.required, Validators.pattern(/^[0-9]+$/)];
    }

    private validateSampleDialog(model: WorkflowGenerateSamplesModel): boolean {
        if (this.generateSamplesFormGroup.invalid) {
            FormValidationHelper.markAllAsTouched(this.generateSamplesFormGroup);

            const validationMessage = new FormValidationMessageHelper<WorkflowGenerateSamplesModel>(
                {
                    sampleType: "Sample type",
                    documentGuid: "Source document",
                    numberOfTestSamples: "Number of test samples",
                    populationSize: "Population size",
                    numberSamples: "Samples",
                    dateSamples: "Samples",
                    textSamples: "Samples",
                }
            )
                .processFormGroup(this.generateSamplesFormGroup)
                .getErrorMessage();

            if (!this.warningShown) {
                this.warningShown = true;
                this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
            } else {
                this.warningShown = false;
            }

            return false;
        }
        return true;
    }

    private checkSampleType(setting: string): any[] {
        let sampleTypeOptions = [];
        const showSpreadsheetGeneration =
            this.details.sampleGenerationType == SampleGenerationType.automatic &&
            this.sourceDocuments &&
            this.sourceDocuments.length > 0;

        const options = setting.split(";");
        options.forEach((option) => {
            if (option.startsWith("[") && option.endsWith("]")) {
                switch (option.toLowerCase()) {
                    case "[all]":
                        sampleTypeOptions = [
                            { id: SampleType.number, name: "Number" },
                            { id: SampleType.date, name: "Date" },
                        ];
                        if (this.details.sampleGenerationType == SampleGenerationType.manual) {
                            sampleTypeOptions.push({
                                id: SampleType.text,
                                name: "Text",
                            });
                        }

                        if (showSpreadsheetGeneration) {
                            sampleTypeOptions.push({
                                id: SampleType.spreadsheet,
                                name: "Spreadsheet",
                            });
                        }

                        break;
                    case "[number]":
                        sampleTypeOptions = [{ id: SampleType.number, name: "Number" }];
                        break;
                    case "[date]":
                        sampleTypeOptions = [{ id: SampleType.date, name: "Date" }];
                        break;
                    case "[text]":
                        sampleTypeOptions = [{ id: SampleType.text, name: "Text" }];
                        break;
                    case "[spreadsheet]":
                        if (showSpreadsheetGeneration) {
                            sampleTypeOptions = [
                                { id: SampleType.spreadsheet, name: "Spreadsheet" },
                            ];
                        } else {
                            sampleTypeOptions = [{ id: SampleType.number, name: "Number" }];
                        }
                        break;
                }
            }
        });

        return sampleTypeOptions;
    }

    private getSavingPrompt(): CerrixPromptComponent {
        return this._promptService.loader("Saving changes, please wait...");
    }
}
