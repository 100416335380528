import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { download, nameof } from "@methods/CommonMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { DocumentSharedModel } from "@app/audit/shared/data-models/document-shared-model";
import { DocumentsModel } from "@app/shared/models/DocumentsModel";

declare var $: any;

@Component({
    selector: "shared-documents",
    templateUrl: "./shared-documents.component.html",
    styleUrls: ["./shared-documents.component.scss"],
})
export class SharedDocumentsComponent implements OnInit {
    moduleType = ModuleType.CD;
    tabID: string;
    tab: TabModel;
    data: Promise<DocumentSharedModel[]>;
    headerLookup: { [propName: string]: string } = this.getHeaderLookup();
    permissions: WorkspaceModulePermissionModel;
    filterConfigs: FilterConfig[] = [];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: nameof<DocumentSharedModel>("updatedOn"),
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: nameof<DocumentSharedModel>("fileSize"),
            type: RendererType.Default,
            formatType: FormatType.FileSizeFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [
        {
            text: "Download",
            icon: "fas fa-download",
            rowRequired: true,
            clickEvent: (guid: string) => {
                // Should retrieve url from one place (document service)
                download(`api/documents/${guid}/data`);
            },
        },
        {
            text: "Show History",
            icon: "fas fa-history",
            rowRequired: true,
            clickEvent: (guid: string) => {
                this.documentHistoryGuid = guid;
                $(this.historyModal.nativeElement).modal("show");
            },
        },
    ];

    numberProps: string[] = [
        nameof<DocumentSharedModel>("version"),
        nameof<DocumentSharedModel>("fileSize"),
    ];
    dateProps: string[] = [nameof<DocumentSharedModel>("updatedOn")];

    @ViewChild("addModal", { static: true }) addModal: ElementRef;
    NewDocuments: DocumentModel[] = [];

    @ViewChild("historyModal", { static: true }) historyModal: ElementRef;
    documentHistoryGuid: string;

    protected disabled = false;

    constructor(
        private permissionsService: PermissionsService,
        private toastr: ToastrService,
        private dataService: DocumentDataService,
        protected promptService: CerrixPromptService
    ) {
        this.permissions = {
            canAdd: permissionsService.permissions.Documents.SharedUploader,
            canOpen: true,

            isModuleAdmin: false,
            canPublish: false,
            canSetSystemDefault: false,
        };
    }

    ngOnInit(): void {
        const hasAccess = this.permissionsService.permissions.Documents.SharedViewer;
        if (!hasAccess) {
            this.toastr.error("No Access!");
            this.tab.close(false);
        }

        this.data = firstValueFrom(this.dataService.GetSharedDocuments()).then(this.mapHeaders);
    }

    protected getFile(row: DocumentSharedModel) {
        this.promptService
            .confirm("Download", `Do you want to download '${row.name}'?`)
            .onConfirm()
            .subscribe(() => {
                // Should retrieve url from one place (document service)
                download(`api/documents/${row.guid}/data`);
            });
    }

    protected openDocumentManager() {
        $(this.addModal.nativeElement).modal("show");
    }

    protected async addDocuments() {
        this.disabled = true;
        const model = new DocumentsModel();
        model.documents = this.NewDocuments;

        try {
            await this.dataService.StoreSharedDocuments(model);
        } catch (e) {
            this.disabled = false;
            throw e;
        }

        this.tab.refresh();
        this.NewDocuments = [];
        $(this.addModal.nativeElement).modal("hide");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    private getHeaderLookup(): { [propName: string]: string } {
        const headerLookup = {};
        headerLookup[nameof<DocumentSharedModel>("guid")] = "Guid";
        headerLookup[nameof<DocumentSharedModel>("name")] = "Name";
        headerLookup[nameof<DocumentSharedModel>("description")] = "Description";
        headerLookup[nameof<DocumentSharedModel>("updatedBy")] = "Last editor";
        headerLookup[nameof<DocumentSharedModel>("updatedOn")] = "Date last edit";
        headerLookup[nameof<DocumentSharedModel>("sourceTypeName")] = "Source";
        headerLookup[nameof<DocumentSharedModel>("typeName")] = "Type";
        headerLookup[nameof<DocumentSharedModel>("version")] = "Versions";
        headerLookup[nameof<DocumentSharedModel>("fileSize")] = "Filesize";

        return headerLookup;
    }
}
