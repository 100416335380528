import { Component, Input, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { ControlModel } from "@app/controls/models/ControlModel";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { ControlDetailComponent } from "../control-detail.component";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { SharedControlLinkModel } from "@app/controls/models/SharedControlLinkModel";
import { nameof } from "@methods/jeffs-toolkit";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { ComparisonType } from "@enums/workspace/ComparisonType";

@Component({
    selector: "control-risks",
    templateUrl: "./control-risks.component.html",
    styleUrls: ["./control-risks.component.scss"],
})
export class ControlRiskComponent implements OnInit {
    @Input() controlModel: ControlModel;
    activeFilters: SearchKeyword[] = [];

    disabled = true;
    previousOrganizationId: number;
    linked: RiskLinkModel[];
    unlinked: RiskLinkModel[];
    linkedFromOtherOrganization: RiskLinkModel[];
    isLoading = true;
    cdc: ControlDetailComponent;

    constructor(private _controlDS: ControlDataService, _modalService: BsModalService) {}

    ngOnInit(): void {
        const defaultOrganizationFilter = <SearchKeyword>{
            col: nameof<RiskLinkModel>((x) => x.organizationId),
            searchValue: this.controlModel.Organization + "",
            specific: true,
            compareType: ComparisonType["Is equal to"],
        };

        this.activeFilters.push(defaultOrganizationFilter);
    }

    onLinkedItemsChanged(linked: RiskLinkModel[]): void {
        const newRisksList = linked.map((x) => x.id);
        if (this.linkedFromOtherOrganization != null && this.linkedFromOtherOrganization.any()) {
            newRisksList.addRange(this.linkedFromOtherOrganization.map((x) => x.id));
        }
        this.controlModel.RiskIdList = newRisksList.sort((a, b) => a - b);
        const isChanged = this.controlModel.RiskIdList.isDifferent(
            this.cdc.detailMain.backupModel.RiskIdList
        );
        TabComponentHelper.toggleTabDirty(this.cdc.tab, "menuItemRisks", isChanged);
    }

    load(
        controlModel: ControlModel,
        permissions: ControlPermissionModel,
        controlDetail: ControlDetailComponent
    ): void {
        this.cdc = controlDetail;
        this.disabled = !permissions.CanLinkRisks;

        if (!controlModel.Organization) {
            return;
        }
        this.controlModel = controlModel;
        const currentOrganizationId = controlModel.Organization;
        if (
            (this.unlinked || this.linked) &&
            this.previousOrganizationId === currentOrganizationId
        ) {
            return;
        }

        this.previousOrganizationId = currentOrganizationId;
        this._controlDS
            .getLinkableRisks(this.controlModel.Guid, currentOrganizationId)
            .subscribe((data) => {
                this.loadData(data);
                this.isLoading = false;
            });
    }

    private loadData(items: SharedControlLinkModel<RiskLinkModel>): void {
        this.linked = items.linkedFromOwnOrganization;
        this.unlinked = items.linkable;
        if (
            this.controlModel.IsShared ||
            (items.linkedFromOtherOrganization != null && items.linkedFromOtherOrganization.any())
        ) {
            this.linkedFromOtherOrganization = items.linkedFromOtherOrganization;
        }
    }
}
