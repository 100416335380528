<div class="card" *ngIf="mainFormGroup">
    <div class="card-body" [formGroup]="mainFormGroup">
        <h5 class="card-title">Export KRI/KPI</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <div class="form-group">
            <label>Start date</label>
            <date-input
                #startDateComponent
                formControlName="startDate"
                [maxDate]="endDate"
            ></date-input>
        </div>

        <div class="form-group">
            <label>End date</label>
            <date-input
                #endDateComponent
                formControlName="endDate"
                [minDate]="startDate"
            ></date-input>
        </div>
        <hr />
        <button class="btn btn-default me-2 mb-2" (click)="exportKriDataPoints()">
            KRI/KPI Data Points Export
        </button>
    </div>
</div>
