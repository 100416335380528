import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePair } from '../../models/generic/KeyValuePair';

@Pipe({name: 'enum2kvp'})
export class EnumKeyValuePair implements PipeTransform {
  transform(value): KeyValuePair[] {
    const keys: KeyValuePair[] = [];
    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push(new KeyValuePair(parseInt(enumMember, 10), value[enumMember]));
      }
    }

    return keys;
  }
}
