<div class="document-manager-main-container">
    <ng-container>
        <eff-table
            *ngIf="dataSource.data.length > 0"
            [dataSource]="dataSource"
            [columns]="displayedColumns"
            [tableConfig]="tableConfig"
            [expandedRowTemplate]="expandedTemplate"
        >
            <ng-template column-name="Name" let-item="item">
                <div class="d-flex flex-row gap-3 document-name-column">
                    <div
                        class="document-name text-ellipsis h3-title"
                        data-uiid="new-eff-document-manager-item-name"
                    >
                        {{ item.Name }}
                    </div>
                    <eff-popover
                        [popoverContentTemplate]="popoverContent"
                        [popoverButtonTemplate]="popoverButton"
                        [data]="item"
                    >
                        <ng-template #popoverContent>
                            <eff-document-details [document]="item"></eff-document-details>
                        </ng-template>

                        <ng-template #popoverButton>
                            <div class="d-flex flex-row">
                                <eff-icon
                                    icon="info_outlined"
                                    class="action-icon"
                                    [attr.data-uiid]="
                                        'new-eff-document-manager-item-info-icon-' + item.Name
                                    "
                                ></eff-icon>
                            </div>
                        </ng-template>
                    </eff-popover>
                </div>
            </ng-template>

            <ng-template column-name="Actions" let-item="item">
                <div
                    class="d-flex flex-row gap-1 align-items-center action-buttons justify-content-end"
                >
                    <eff-icon
                        *ngIf="!item.IsNew && item.Name && item.Name.endsWith('.pdf')"
                        (click)="openDocumentViewer(item)"
                        icon="find_in_page_outlined"
                        class="action-icon"
                        [attr.data-uiid]="'new-eff-document-manager-item-view-icon-' + item.Name"
                    ></eff-icon>
                    <eff-icon
                        (click)="onEditDocument(item)"
                        icon="edit_square_outlined"
                        class="action-icon"
                        [attr.data-uiid]="'new-eff-document-manager-item-edit-icon-' + item.Name"
                    ></eff-icon>
                    <eff-icon
                        class="action-icon"
                        icon="cloud_download_outlined"
                        (click)="downloadDocument(item)"
                        [attr.data-uiid]="
                            'new-eff-document-manager-item-download-icon-' + item.Name
                        "
                    ></eff-icon>
                    <eff-button-with-confirmation
                        label="Delete?"
                        icon="delete_outlined"
                        *ngIf="item.CanDelete && canDelete"
                        (onConfirmation)="deleteDocument(item)"
                        [attr.data-uiid]="'new-eff-document-manager-item-delete-icon-' + item.Name"
                    >
                    </eff-button-with-confirmation>
                </div>
            </ng-template>
        </eff-table>

        <eff-drop-files-area
            *ngIf="canAdd"
            [allowedFileExtensions]="allowedFileExtensions"
            [allowDrop]="canAdd"
            (onDroppedFiles)="onFilesDropped($event)"
        >
        </eff-drop-files-area>
    </ng-container>
</div>
