<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState">
        <mat-expansion-panel-header>
            <mat-panel-title> {{ title }} </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
            <ng-container *ngTemplateOutlet="contentTemplate; context: { data: data }">
            </ng-container>
        </div>
    </mat-expansion-panel>
</mat-accordion>
