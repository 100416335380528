import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { toPromise } from "@methods/CommonMethods";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { Configuration } from "../../app.constants";
import { CalendarModel } from "../models/CalendarModel";
import { CalendarSettingsModel } from "../models/CalendarSettingsModel";

@Injectable()
export class CalendarDataService extends ApiDataService {
    calendarModel: CalendarModel;
    onReload = new EventEmitter<CalendarModel>();

    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "calendar");
        this.delayedCalenderLoad();
    }

    loadCalendar() {
        this.calendarModel = null;
        toPromise(this.get<CalendarModel>()).then((model) => {
            this.calendarModel = model;
            this.onReload.emit(model);
        });
    }

    getSettings(): Observable<CalendarSettingsModel> {
        const url = this.actionUrl + "/settings";
        return this.http.get<CalendarSettingsModel>(url);
    }

    storeSettings(settings: CalendarSettingsModel) {
        const url = this.actionUrl + "/settings";
        return this.http.post(url, settings);
    }

    /** We implemented this method, so calendar only starts loading after 5s. This makes sure the calendar is only retrieved after all other priority calls have been queued. */
    private delayedCalenderLoad() {
        setTimeout(() => {
            if (!this.calendarModel) {
                this.loadCalendar();
            }
        }, 5000);
    }
}
