import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "print-process-flow",
    templateUrl: "./print-process-flow.component.html",
    styleUrls: ["./print-process-flow.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class PrintProcessFlowComponent {
    @Input() public printGraph: string;
}
