<div class="main-page-area">
    <div class="main-page-content">
        <eff-step-header
            [ngClass]="{ 'is-step-rejected': pageState.isRejected }"
            [controlGuid]="effectivenessModel.controlGuid"
            [hideSaveButton]="!stepPermissions.isEditAllowed"
            title="Review"
            (onShowInstructions)="toggleInstructions()"
            (onSaveChanges)="saveChanges(true)"
            [showNonOccurrenceWarningArea]="isNonOccurrenceTestPlan"
        >
        </eff-step-header>

        <author-message-box
            *ngIf="pageState.isRejected"
            [author]="pageState.rejectedBy"
            [authorLabel]="pageState.rejectedByRole"
            [message]="pageState.rejectionMessage"
        >
        </author-message-box>

        <eff-checkbox
            class="expand-collapse-all"
            label="Open all"
            (modelChange)="expandCollapseAll($event)"
        ></eff-checkbox>

        <div class="d-flex flex-column grid-conclusion-wrapper">
            <div class="main-grid d-flex flex-column">
                <div *ngFor="let viewSample of viewSamples">
                    <div class="grid-header">
                        <div class="bold-text">Sample {{ viewSample.sample.name }}</div>
                        <eff-inline-text-editor
                            label="Add sample remark"
                            [(model)]="viewSample.sample.remarks"
                            [disabled]="!stepPermissions.isEditSampleRemarkAllowed"
                            (complete)="saveRemarksChange(viewSample.sample)"
                        ></eff-inline-text-editor>
                    </div>
                    <eff-table
                        [dataSource]="viewSample.gridDataSource"
                        [columns]="displayedColumns"
                        [tableConfig]="tableConfig"
                        [expandedRowTemplate]="expandedTemplate"
                    >
                        <ng-template #expandedTemplate let-item="item">
                            <div class="grid-expanded-row">
                                <div class="table-row">
                                    <div class="table-cell documents">
                                        <div class="d-flex flex-column gap-3">
                                            <div
                                                class="d-flex flex-column gap-2"
                                                *ngIf="item.noEvidence"
                                            >
                                                <eff-checkbox
                                                    [disabled]="true"
                                                    [checked]="true"
                                                    label="No evidence"
                                                ></eff-checkbox>
                                                <div>
                                                    {{ item.noEvidenceReason }}
                                                </div>
                                            </div>
                                            <documents-list
                                                class="documets-list-small-droparea"
                                                [documents]="item.documents"
                                                [canAdd]="stepPermissions.evidenceSaveAllowed"
                                                [canEdit]="stepPermissions.evidenceSaveAllowed"
                                                [canDelete]="stepPermissions.evidenceSaveAllowed"
                                            ></documents-list>
                                        </div>
                                    </div>
                                    <div class="table-cell tester-comment">
                                        <div class="comment">
                                            {{ item.commentTester }}
                                        </div>
                                    </div>
                                    <div class="table-cell reviewer-comment">
                                        <div class="tester-comment-inner">
                                            <eff-textarea
                                                class="grid-textarea-style"
                                                [(model)]="item.commentReviewer"
                                                *ngIf="stepPermissions.evidenceSaveAllowed"
                                                label="add a comment"
                                            ></eff-textarea>
                                            <div *ngIf="!stepPermissions.evidenceSaveAllowed">
                                                {{ item.commentReviewer }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template column-name="name" let-item="item">
                            <div class="d-flex flex-row gap-3 align-items-center">
                                <div
                                    [ngClass]="{ 'sample-name-cell': item.isSample }"
                                    class="regular-text"
                                >
                                    {{ item.isSample ? "Sample" : "Step" }} {{ item.name }}
                                </div>

                                <div
                                    class="d-flex flex-row align-items-center gap-1"
                                    *ngIf="visibleDocuments(item.documents).length > 0"
                                >
                                    <eff-icon
                                        class="remark-icon"
                                        icon="attachment_outlined"
                                    ></eff-icon>
                                    <div>({{ visibleDocuments(item.documents).length }})</div>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template column-name="scoreTester" let-item="item">
                            <div class="d-flex flex-row align-items-center status-cell">
                                <div class="status-bullet" [ngClass]="getScoreColor(item)"></div>
                                <div>{{ item.scoreName }}</div>
                            </div>
                        </ng-template>

                        <ng-template column-name="testerComment" let-item="item">
                            <div *ngIf="!item.IsExpanded && item.commentTester">
                                <eff-icon class="remark-icon" icon="comment_outlined"></eff-icon>
                            </div>
                        </ng-template>

                        <ng-template column-name="reviewerComment" let-item="item">
                            <div *ngIf="!item.IsExpanded">
                                <div class="add-comment-label" *ngIf="!item.commentReviewer">
                                    add a comment
                                </div>
                                <eff-icon
                                    class="remark-icon"
                                    *ngIf="item.commentReviewer"
                                    icon="comment_outlined"
                                ></eff-icon>
                            </div>
                        </ng-template>
                    </eff-table>
                </div>
            </div>
            <eff-detail-final-conclusion
                [effectivenessModel]="effectivenessModel"
            ></eff-detail-final-conclusion>
        </div>

        <div class="bottom-buttons-wrapper" *ngIf="stepPermissions.isEditAllowed">
            <eff-button
                text="Reject"
                size="large-size"
                class="bottom-button reject-btn"
                (click)="reject()"
            ></eff-button>

            <eff-button
                text="Modify"
                size="large-size"
                class="bottom-button final-score-modify-btn"
                (click)="modify()"
            ></eff-button>

            <eff-button
                text="Accept"
                size="large-size"
                class="bottom-button final-score-accept-btn"
                (click)="accept()"
            ></eff-button>
        </div>
    </div>

    <div class="side-page-area">
        <eff-test-instructions-info-box
            [panelOpenState]="true"
            [samples]="samples"
            [effectivenessModel]="effectivenessModel"
        >
        </eff-test-instructions-info-box>
        <eff-evidence-instructions-info-box
            [samples]="samples"
            [instructions]="effectivenessModel.evidenceUploadInstructions"
        >
        </eff-evidence-instructions-info-box>
        <eff-source-document-info-box
            [effectivenessModel]="effectivenessModel"
            [hideSampling]="hideSampling"
            [stepPermissions]="stepPermissions"
        ></eff-source-document-info-box>
        <eff-control-info-box [controlGuid]="effectivenessModel.controlGuid"></eff-control-info-box>
    </div>
</div>

<cerrix-slide-over #cerrixSlideOver>
    <ng-template #title>
        <div class="paragraph">
            <div class="header">How to review</div>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="paragraph">
            <div class="header">1. Give reviewer comment on teststeps, samples and scores</div>
            <div class="content">
                <ol>
                    <li>Review the evidence provided for each test step and/or sample.</li>
                    <li>
                        Review the given comment & score by the tester for each test step and/or
                        sample.
                    </li>
                    <li>Give review comment per test step and/or sample (optional).</li>
                    <li>Click "Save changes".</li>
                </ol>
            </div>
        </div>
        <div class="paragraph">
            <div class="header">2. Give review score</div>
            <div class="content">
                <ul>
                    <li>
                        <b>Reject</b>: By clicking “Reject” you are sending the test plan back to
                        the tester. You must provide a comment for sending the test plan back to the
                        tester.
                    </li>
                    <li>
                        <b>Modify</b>: By clicking “Modify” you are able to modify the score given
                        by the tester. You must provide a comment why you are modifying the score of
                        the tester.
                    </li>
                    <li>
                        <b>Accept</b>: By clicking “Accept” you are confirming the score given by
                        the tester. You must provide a comment why you agree with the score of the
                        tester.
                    </li>
                </ul>
            </div>
        </div>
    </ng-template>
</cerrix-slide-over>
