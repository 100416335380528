<div class="cerrix-slide-out" [class.slided]="showSlideOver">
    <div
        class="slide-out-container"
        [class.slide-out]="slideOutAnimation"
        style="animation-duration: 0.3s"
        [style.width]="containerWidth"
        (click)="$event.stopPropagation()"
        #slideOutContainer
    >
        <div class="slide-out-resizer" (mousedown)="initDrag($event)">&boxV;</div>
        <div class="content">
            <div class="slide-out-header">
                <div class="close-button" (click)="close()">
                    <i class="fas fa-times-circle"></i>
                </div>
                <div class="slide-out-title">{{ title }}</div>
            </div>
            <div class="iframe-overlay" [class.hidden]="!containerDragging" #iframeOverlay></div>
        </div>
    </div>
</div>
