import { Component, Input, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { OverrideRiskFieldTypes } from "@app/risk/enums/OverrideRiskFieldTypes";
import { RiskAppetiteType } from "@app/risk/enums/RiskAppetiteType";
import { RiskCatalogueChangesItem } from "@app/risk/enums/RiskCatalogueChangesItem";
import { RiskStandingDataType } from "@app/risk/enums/RiskStandingDataType";
import { RiskCatalogueModel } from "@app/risk/models/RiskCatalogueModel";
import { RiskModel } from "@app/risk/models/RiskModel";
import { RiskPermissionModel } from "@app/risk/models/RiskPermissionModel";
import { RiskScoringStandingDataModel } from "@app/risk/models/RiskScoringStandingDataModel";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { CerrixSelectComponent } from "@app/shared/cerrix-select/cerrix-select.component";
import { CerrixRiskMatrixComponent } from "@app/shared/components/cerrix-riskmatrix/cerrix-riskmatrix.component";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleType } from "@enums/ModuleType";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { StandingDataType } from "@enums/StandingDataType";
import {
    getFormControl,
    getFormValue,
    setFormControlValue,
    toPromise,
} from "@methods/CommonMethods";
import { FindNode } from "@methods/TreeMethods";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { MatrixCell } from "@models/generic/MatrixModel";
import { MatrixSelectionModel } from "@models/generic/MatrixSelectionModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { forkJoin, of } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import { CustomFieldType } from "@enums/customfields/CustomFieldType";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { RiskValidationModel } from "@app/risk/models/RiskValidationModel";
import { RiskFinancialImpactValues } from "@app/risk/models/RiskFinancialImpactValues";

@Component({
    selector: "risk-detail-main",
    templateUrl: "./risk-detail-main.component.html",
    styleUrls: ["./risk-detail-main.component.scss"],
})
export class RiskDetailMainComponent implements OnInit {
    @ViewChild("riskCatalogueCmp") riskCatalogueCmp: CerrixSelectComponent;
    @ViewChild("ownersCmp") ownersCmp: CerrixSelectComponent;
    @ViewChildren("riskmatrix") riskMatrices: CerrixRiskMatrixComponent[];

    @Input() risk: RiskModel;
    @Input() permissions: RiskPermissionModel;
    @Input() parentForm: FormGroup;

    @Input() impactScaleData: any;
    @Input() customFieldData: any;

    @Input() enableTargetRisk: boolean;
    enableBudgetBasedRisk: boolean;

    id: number;
    riskMoiType: MoiTypes.RISK_Moi;

    selectedRiskCatalogue: RiskCatalogueModel;
    selectedOrganizationId: number;
    previousOrganizationId: number;
    previousBusinessDimensions: number[];

    riskInitialized = false;

    // Standing data requests
    organizationRequest: Promise<CerrixTreeItem[]>;
    riskCatalogueRequest: Promise<RiskCatalogueModel[]>;
    subTypeRequest: Promise<IdNameCombination[]>;
    riskAreaRequest: Promise<IdNameCombination[]>;
    eventCategoryRequest: Promise<IdNameCombination[]>;
    likelihoodRequest: Promise<RiskScoringStandingDataModel[]>;
    impactRequest: Promise<RiskScoringStandingDataModel[]>;
    riskTreatmentRequest: Promise<IdNameCombination[]>;
    overallAssessmentRequest: Promise<IdNameColorModel[]>;
    riskOwnersRequest: Promise<IdNameCombination[]>;
    businessDimensionsRequest: Promise<IdNameCombination[]>;
    frameworkDimensionsRequest: Promise<IdNameCombination[]>;

    // Risk scoring
    grossRiskMatrix: MatrixCell;
    netRiskMatrix: MatrixCell;
    targetRiskMatrix: MatrixCell;

    // Risk appetite
    RiskAppetiteType = RiskAppetiteType;
    riskAppetiteInLine: RiskAppetiteType = RiskAppetiteType.NotSet;
    riskAppetiteInfo: string;

    // Custom fields
    CustomFieldType = CustomFieldType;

    eventCategoryLoaded = false;
    // subtypes
    subTypesLoaded = false;
    availableSubTypes: IdNameCombination[];
    filteredSubTypes: IdNameCombination[] = [];

    // Leading scale consistency properties
    alignCustomGrossIndex: number = null;
    alignCustomNetIndex: number = null;
    alignCustomTargetIndex: number = null;
    alignCustomLeadingValue: boolean = true;

    lookupRiskArea: IdNameCombination[];
    lookupEventCategory: IdNameCombination[];
    riskCatalogueChangedPrompt: CerrixPromptComponent;

    showCustomImpactScales: boolean;
    showQuantitativeScoring: Boolean;

    constructor(
        private route: ActivatedRoute,
        private _riskDS: RiskDataService,
        private _standingDataDS: StandingdataDataService,
        private _promptService: CerrixPromptService,
        private _appSettingsDS: SettingsDataService
    ) {
        this.id = this.id > 0 ? this.id : this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        this.enableTargetRisk = this.permissions.enableTargetRisk;
        this.enableBudgetBasedRisk = this.permissions.enableBudgetBasedRisk;

        this.showCustomImpactScales =
            this.enableBudgetBasedRisk ||
            this.parentForm.controls.impactScales["controls"].length > 0;

        this._appSettingsDS
            .getSetting(ApplicationSettings.ShowQuantitativeScoringDetails)
            .subscribe((setting) => {
                this.showQuantitativeScoring = setting.BoolValue;
            });

        this.selectedOrganizationId = this.risk.organizationId;
        this.previousOrganizationId = this.risk.organizationId;
        this.previousBusinessDimensions = this.risk.businessDimensionIds;

        this.riskOwnersRequest = toPromise(
            this._riskDS.getStandingDataByType(RiskStandingDataType.RiskOwners, this.risk)
        );

        if (this.risk.organizationId > 0) {
            // Risk specific standing data requests
            this.riskCatalogueRequest = toPromise(
                this._riskDS.getStandingDataByType(RiskStandingDataType.RiskCatalogues, this.risk)
            );

            this.businessDimensionsRequest = toPromise(
                this._riskDS.getStandingDataByType(
                    RiskStandingDataType.BusinessDimensions,
                    this.risk
                )
            );
            this.frameworkDimensionsRequest = toPromise(
                this._riskDS.getStandingDataByType(
                    RiskStandingDataType.FrameworkDimensions,
                    this.risk
                )
            );
        } else {
            this.riskCatalogueRequest = toPromise(of([]));
            this.businessDimensionsRequest = toPromise(of([]));
            this.frameworkDimensionsRequest = toPromise(of([]));
        }

        this.organizationRequest = toPromise(
            this._standingDataDS.getOrganizationsIncludingSelected(
                ModuleType.ORM,
                this.risk.organizationId,
                this.permissions.canEdit
            )
        );

        this.riskAreaRequest = toPromise(
            this._standingDataDS.getAllByType(StandingDataType.RiskArea, this.risk.riskAreaId)
        );
        this.overallAssessmentRequest = toPromise(
            this._standingDataDS.getAllByType(
                StandingDataType.RiskAssessment,
                this.risk.riskAssessmentId
            )
        );
        this.likelihoodRequest = toPromise(
            this._standingDataDS.getAllByType(
                StandingDataType.RiskLikelihood,
                this.risk.likelihoodId
            )
        );
        this.impactRequest = toPromise(
            this._standingDataDS.getAllByType(StandingDataType.RiskImpact)
        );
        this.riskTreatmentRequest = toPromise(
            this._standingDataDS.getAllByType(
                StandingDataType.RiskTreatment,
                this.risk.riskTreatmentId
            )
        );
        this.eventCategoryRequest = toPromise(
            this._standingDataDS.getEventCategory().pipe(
                finalize(() => {
                    this.eventCategoryLoaded = true;
                })
            )
        );
        this.subTypeRequest = toPromise(
            this._standingDataDS
                .getAllByType(StandingDataType.RiskSubtype, ...this.risk.subtypeIds)
                .pipe(
                    finalize(() => {
                        this.subTypesLoaded = true;
                    })
                )
        );

        // Since the catalogues and subtypes rely on each other wait for them to complete
        forkJoin({
            riskCatalogues: this.riskCatalogueRequest,
            subTypes: this.subTypeRequest,
        }).subscribe((x) => {
            this.selectedRiskCatalogue = x.riskCatalogues.find(
                (c) => c.ID === this.risk.riskCatalogueId
            );
            this.availableSubTypes = x.subTypes;

            this.setRiskCatalogueValues();
        });

        this.setupValueListeners();
        this.setupRiskMatrix();

        this._standingDataDS
            .getAllByType(StandingDataType.RiskArea, this.risk.riskAreaId)
            .subscribe((x) => {
                this.lookupRiskArea = x;
            });
        this._standingDataDS.getEventCategory().subscribe((x) => {
            this.lookupEventCategory = x;
        });

        this.riskInitialized = true;
    }

    setupValueListeners(): void {
        if (!this.permissions.readonly) {
            // Setup organization changed listener
            getFormControl<RiskModel>(this.parentForm, "organizationId").valueChanges.subscribe(
                (organizationId?: number) => {
                    this.organizationChanged(organizationId);
                    this.setFinancialImpactGrossOrganization(organizationId);
                    this.setFinancialImpactNetOrganization(organizationId);
                    this.setFinancialImpactTargetOrganization(organizationId);
                }
            );

            if (this.permissions.enableBudgetBasedRisk) {
                getFormControl<RiskModel>(this.parentForm, "financialImpactGross")
                    .valueChanges.pipe(debounceTime(400)) // Waits a bit after the user stops typing
                    .subscribe((impact: number) => {
                        this.setFinancialImpactGrossImpact(impact);
                    });
                getFormControl<RiskModel>(this.parentForm, "financialImpactNet")
                    .valueChanges.pipe(debounceTime(400))
                    .subscribe((impact: number) => {
                        this.setFinancialImpactNetImpact(impact);
                    });

                if (this.enableTargetRisk) {
                    getFormControl<RiskModel>(this.parentForm, "financialImpactTarget")
                        .valueChanges.pipe(debounceTime(400))
                        .subscribe((impact: number) => {
                            this.setFinancialImpactTargetImpact(impact);
                        });
                }
            }
        }
    }

    setupRiskMatrix(): void {
        this.grossRiskMatrix = {
            Impact: this.risk.impactId - 1,
            Likelihood: this.risk.likelihoodId - 1,
            Color: "",
        };

        this.netRiskMatrix = {
            Impact: this.risk.netImpactId - 1,
            Likelihood: this.risk.netLikelihoodId - 1,
            Color: "",
        };

        this.targetRiskMatrix = {
            Impact: this.risk.targetImpactId - 1,
            Likelihood: this.risk.targetLikelihoodId - 1,
            Color: "",
        };

        // Setup listeners
        getFormControl<RiskModel>(this.parentForm, "likelihoodId").valueChanges.subscribe(
            (likelihoodId: number) => {
                this.grossRiskMatrix.Likelihood = likelihoodId - 1;
                this.setRiskAppetite();
                this.setFinancialImpactGrossLikelihood(likelihoodId);
            }
        );

        getFormControl<RiskModel>(this.parentForm, "impactId").valueChanges.subscribe(
            (impactId: number) => {
                this.grossRiskMatrix.Impact = impactId - 1;
                this.setRiskAppetite();
            }
        );

        getFormControl<RiskModel>(this.parentForm, "netLikelihoodId").valueChanges.subscribe(
            (netLikelihoodId: number) => {
                this.netRiskMatrix.Likelihood = netLikelihoodId - 1;
                this.setRiskAppetite();
                this.setFinancialImpactNetLikelihood(netLikelihoodId);
            }
        );

        getFormControl<RiskModel>(this.parentForm, "netImpactId").valueChanges.subscribe(
            (netImpactId: number) => {
                this.netRiskMatrix.Impact = netImpactId - 1;
                this.setRiskAppetite();
            }
        );

        if (this.enableTargetRisk) {
            getFormControl<RiskModel>(this.parentForm, "targetLikelihoodId").valueChanges.subscribe(
                (targetLikelihoodId: number) => {
                    this.targetRiskMatrix.Likelihood = targetLikelihoodId - 1;
                    this.setRiskAppetite();
                    this.setFinancialImpactTargetLikelihood(targetLikelihoodId);
                }
            );

            getFormControl<RiskModel>(this.parentForm, "targetImpactId").valueChanges.subscribe(
                (targetImpactId: number) => {
                    this.targetRiskMatrix.Impact = targetImpactId - 1;
                    this.setRiskAppetite();
                }
            );
        }
    }

    grossRiskMatrixChanged(changes: MatrixCell): void {
        this.setRiskMatrixValues(changes, "impactId", "likelihoodId");
    }

    netRiskMatrixChanged(changes: MatrixCell): void {
        this.setRiskMatrixValues(changes, "netImpactId", "netLikelihoodId");
    }

    targetRiskMatrixChanged(changes: MatrixCell): void {
        this.setRiskMatrixValues(changes, "targetImpactId", "targetLikelihoodId");
    }

    private setRiskMatrixValues(
        changes: MatrixCell,
        impactProp: keyof RiskModel,
        likelihoodProp: keyof RiskModel
    ): void {
        setFormControlValue<RiskModel>(this.parentForm, impactProp, changes.Impact + 1);
        setFormControlValue<RiskModel>(this.parentForm, likelihoodProp, changes.Likelihood + 1);

        this.setRiskAppetite();
    }

    private setRiskAppetite(): void {
        if (!this.risk.riskCatalogue) {
            this.setRiskAppetiteOutline(null);
            return;
        }

        const catalogueRiskAppetite = this.risk.riskCatalogue.riskAppetiteSelection;
        if (!catalogueRiskAppetite || catalogueRiskAppetite.length === 0) {
            this.riskAppetiteInLine = RiskAppetiteType.NotSet;
            this.riskAppetiteInfo = "No risk appetite defined.";

            this.setRiskAppetiteOutline(null);
            return;
        }

        let selectedLikelihood = getFormValue<RiskModel>(this.parentForm, "netLikelihoodId");
        let selectedImpact = getFormValue<RiskModel>(this.parentForm, "netImpactId");

        if (!selectedLikelihood || !selectedImpact) {
            selectedLikelihood = getFormValue<RiskModel>(this.parentForm, "likelihoodId");
            selectedImpact = getFormValue<RiskModel>(this.parentForm, "impactId");
        }

        // Risk appetite selection is zero based
        const zeroBasedCorrection = 1;
        const inLine =
            catalogueRiskAppetite.findIndex(
                (x) =>
                    x.Likelihood === selectedLikelihood - zeroBasedCorrection &&
                    x.Impact === selectedImpact - zeroBasedCorrection
            ) > -1;
        if (inLine) {
            this.riskAppetiteInLine = RiskAppetiteType.InLine;
            this.riskAppetiteInfo = "Net risk in line with risk appetite for this category";
        } else {
            this.riskAppetiteInLine = RiskAppetiteType.NotInLine;
            this.riskAppetiteInfo = "Net risk not in line with Risk appetite for this category";
        }

        this.setRiskAppetiteOutline(catalogueRiskAppetite);
    }

    private organizationChanged(organizationId): void {
        if (organizationId == this.previousOrganizationId) {
            return;
        }

        this.selectedOrganizationId = organizationId;
        if (organizationId > 0) {
            getFormControl<RiskModel>(this.parentForm, "riskCatalogueId").enable();
            getFormControl<RiskModel>(this.parentForm, "businessDimensionIds").enable();
            getFormControl<RiskModel>(this.parentForm, "frameworkDimensionIds").enable();

            // Check if the organization changes should be validated
            if (
                this.risk.businessDimensionIds.length === 0 &&
                this.risk.connectedControlIds.length === 0
            ) {
                this.reloadOrganizationDependencies(organizationId);

                return;
            }

            this._riskDS
                .validateOrganizationChange(this.risk)
                .subscribe(async (validations: RiskValidationModel) => {
                    if (!validations || validations.messages.length === 0) {
                        this.reloadOrganizationDependencies(organizationId);
                        return;
                    }

                    if (validations.invalidControls.length > 0) {
                        let message =
                            "There are linked controls(s) blocking the organization change. Please remove the following controls(s) before changing the organization:\n\n";

                        validations.invalidControlNames.forEach(
                            (m) => (message = message + m + "\n")
                        );

                        this.createConfirm("Cannot change organization!", message);

                        setFormControlValue<RiskModel>(
                            this.parentForm,
                            "organizationId",
                            this.previousOrganizationId
                        );

                        return;
                    }

                    let message =
                        "Due to links with business dimension(s) and/or framework dimension(s), this organization cannot be changed. Do you want to remove the following item(s) before changing the organization? :\n\n";
                    validations.messages.forEach((m) => (message = message + m + "\n"));
                    const confirmed = await toPromise(
                        this.createConfirm("Cannot change organization!", message).getResult()
                    );

                    if (confirmed) {
                        this.setCorrectedValues(validations);

                        this.reloadOrganizationDependencies(organizationId);
                    } else {
                        setFormControlValue<RiskModel>(
                            this.parentForm,
                            "organizationId",
                            this.previousOrganizationId
                        );
                    }
                });
        } else {
            getFormControl<RiskModel>(this.parentForm, "riskCatalogueId").disable();
            getFormControl<RiskModel>(this.parentForm, "businessDimensionIds").disable();
            getFormControl<RiskModel>(this.parentForm, "frameworkDimensionIds").disable();
        }
    }

    private setCorrectedValues(validations: RiskValidationModel): void {
        const correctedControls = this.risk.connectedControlIds.filter(
            (x) => validations.invalidControls.indexOf(x) === -1
        );

        const correctedBusinessDimensions = this.risk.businessDimensionIds.filter(
            (x) => validations.invalidBusinessDimensions.indexOf(x) === -1
        );

        const correctedFrameworkDimenensions = this.risk.frameworkDimensionIds.filter(
            (x) => validations.invalidFrameworkDimensions.indexOf(x) === -1
        );

        this.risk.connectedControlIds = correctedControls;
        this.risk.businessDimensionIds = correctedBusinessDimensions;
        this.risk.frameworkDimensionIds = correctedFrameworkDimenensions;

        setFormControlValue<RiskModel>(
            this.parentForm,
            "businessDimensionIds",
            correctedBusinessDimensions
        );

        setFormControlValue<RiskModel>(
            this.parentForm,
            "frameworkDimensionIds",
            correctedFrameworkDimenensions
        );
    }

    public async setFinancialImpactGrossOrganization(organizationId: number) {
        return await this.setFinancialImpactGross(
            organizationId,
            this.risk.financialImpactGross,
            this.risk.likelihoodId
        );
    }

    public async setFinancialImpactGrossImpact(financialImpactGross: number) {
        return await this.setFinancialImpactGross(
            this.risk.organizationId,
            financialImpactGross,
            this.risk.likelihoodId
        );
    }

    public async setFinancialImpactGrossLikelihood(likelihoodId: number) {
        return await this.setFinancialImpactGross(
            this.risk.organizationId,
            this.risk.financialImpactGross,
            likelihoodId
        );
    }

    private async setFinancialImpactGross(
        organizationId: number,
        financialImpactGross: number,
        likelihoodId: number
    ) {
        if (!this.enableBudgetBasedRisk || !organizationId) {
            return;
        }

        const impactValues = await this.getFinancialImpact(
            organizationId,
            likelihoodId,
            financialImpactGross
        );
        const financialImpactScale = this.risk.impactScales.find((x) => x.ID == -1);
        financialImpactScale.grossImpactId = impactValues.FinancialImpactScore;

        this.parentForm.controls.impactScales["controls"].find((control) => {
            if (control.controls.ID.value === -1) {
                control.controls.grossImpactId.setValue(impactValues.FinancialImpactScore);
            }
        });

        this.risk.potentialLossGrossMin = impactValues.PotentialLossMin;
        setFormControlValue<RiskModel>(
            this.parentForm,
            "potentialLossGrossMin",
            this.risk.potentialLossGrossMin
        );

        this.risk.potentialLossGrossMax = impactValues.PotentialLossMax;
        setFormControlValue<RiskModel>(
            this.parentForm,
            "potentialLossGrossMax",
            this.risk.potentialLossGrossMax
        );

        this.checkCustomScaleValuesAreAlignable();
    }

    public async setFinancialImpactNetOrganization(organizationId: number) {
        return await this.setFinancialImpactNet(
            organizationId,
            this.risk.financialImpactNet,
            this.risk.netLikelihoodId
        );
    }

    public async setFinancialImpactNetImpact(financialImpactNet: number) {
        return await this.setFinancialImpactNet(
            this.risk.organizationId,
            financialImpactNet,
            this.risk.netLikelihoodId
        );
    }

    public async setFinancialImpactNetLikelihood(netLikelihoodId: number) {
        return await this.setFinancialImpactNet(
            this.risk.organizationId,
            this.risk.financialImpactNet,
            netLikelihoodId
        );
    }

    public async setFinancialImpactNet(
        organizationId: number,
        financialImpactNet: number,
        netLikelihoodId: number
    ) {
        if (!this.enableBudgetBasedRisk || !organizationId) {
            return;
        }

        const impactValues = await this.getFinancialImpact(
            organizationId,
            netLikelihoodId,
            financialImpactNet
        );

        const financialImpactScale = this.risk.impactScales.find((x) => x.ID == -1);
        financialImpactScale.netImpactId = impactValues.FinancialImpactScore;

        this.parentForm.controls.impactScales["controls"].find((control) => {
            if (control.controls.ID.value === -1) {
                control.controls.netImpactId.setValue(impactValues.FinancialImpactScore);
            }
        });

        this.risk.potentialLossNetMin = impactValues.PotentialLossMin;
        setFormControlValue<RiskModel>(
            this.parentForm,
            "potentialLossNetMin",
            this.risk.potentialLossNetMin
        );

        this.risk.potentialLossNetMax = impactValues.PotentialLossMax;
        setFormControlValue<RiskModel>(
            this.parentForm,
            "potentialLossNetMax",
            this.risk.potentialLossNetMax
        );

        this.checkCustomScaleValuesAreAlignable();
    }

    public async setFinancialImpactTargetOrganization(organizationId: number) {
        return await this.setFinancialImpactTarget(
            organizationId,
            this.risk.financialImpactTarget,
            this.risk.targetLikelihoodId
        );
    }

    public async setFinancialImpactTargetImpact(financialImpactTarget: number) {
        return await this.setFinancialImpactTarget(
            this.risk.organizationId,
            financialImpactTarget,
            this.risk.targetLikelihoodId
        );
    }

    public async setFinancialImpactTargetLikelihood(targetLikelihoodId: number) {
        return await this.setFinancialImpactTarget(
            this.risk.organizationId,
            this.risk.financialImpactTarget,
            targetLikelihoodId
        );
    }

    public async setFinancialImpactTarget(
        organizationId: number,
        financialImpactTarget: number,
        targetLikelihoodId: number
    ) {
        if (!this.enableBudgetBasedRisk || !organizationId) {
            return;
        }

        const impactValues = await this.getFinancialImpact(
            organizationId,
            targetLikelihoodId,
            financialImpactTarget
        );
        const financialImpactScale = this.risk.impactScales.find((x) => x.ID == -1);
        financialImpactScale.targetImpactId = impactValues.FinancialImpactScore;

        this.parentForm.controls.impactScales["controls"].find((control) => {
            if (control.controls.ID.value === -1) {
                control.controls.targetImpactId.setValue(impactValues.FinancialImpactScore);
            }
        });

        this.risk.potentialLossTargetMin = impactValues.PotentialLossMin;
        setFormControlValue<RiskModel>(
            this.parentForm,
            "potentialLossTargetMin",
            this.risk.potentialLossTargetMin
        );

        this.risk.potentialLossTargetMax = impactValues.PotentialLossMax;
        setFormControlValue<RiskModel>(
            this.parentForm,
            "potentialLossTargetMax",
            this.risk.potentialLossTargetMax
        );

        this.checkCustomScaleValuesAreAlignable();
    }

    private async getFinancialImpact(
        organizationId: number,
        likelihoodId: number,
        financialImpact: number
    ): Promise<RiskFinancialImpactValues> {
        let result = await toPromise(
            this._riskDS.calculateFinancialImpact(organizationId, likelihoodId, financialImpact)
        );

        return result;
    }

    private checkRiskCatalogueOrganizationChange(organizationId: number): void {
        const isApplicableRiskCatalogue =
            !this.selectedRiskCatalogue ||
            !this.selectedRiskCatalogue.applicableOrganizationIds ||
            this.selectedRiskCatalogue.applicableOrganizationIds.empty() ||
            this.selectedRiskCatalogue.applicableOrganizationIds.indexOf(organizationId) >= 0;

        if (!isApplicableRiskCatalogue) {
            this.resetRiskCatalogueValues();
        }
    }

    private reloadOrganizationDependencies(organizationId: number): void {
        this.risk.organizationId = organizationId;
        this.previousOrganizationId = organizationId;

        // Reset and reload risk catalogue combobox
        this.checkRiskCatalogueOrganizationChange(organizationId);
        this.riskCatalogueCmp.getDataMethod = this._riskDS.getStandingDataByType(
            RiskStandingDataType.RiskCatalogues,
            this.risk
        );
        this.riskCatalogueCmp.reloadData();

        // Reload business dimensions
        this.businessDimensionsRequest = toPromise(
            this._riskDS.getStandingDataByType(RiskStandingDataType.BusinessDimensions, this.risk)
        );

        // Reload framework dimensions
        this.frameworkDimensionsRequest = toPromise(
            this._riskDS.getStandingDataByType(RiskStandingDataType.FrameworkDimensions, this.risk)
        );

        // Reload owners
        this.ownersCmp.getDataMethod = this._riskDS.getStandingDataByType(
            RiskStandingDataType.RiskOwners,
            this.risk
        );
        this.ownersCmp.reloadData();
    }

    riskCatalogueChanged(catalogueId?: number, manually = false): void {
        if (this.riskCatalogueCmp && this.riskCatalogueCmp.data) {
            if (catalogueId) {
                if (
                    !this.selectedRiskCatalogue ||
                    this.selectedRiskCatalogue.ID == null ||
                    this.selectedRiskCatalogue.ID === 0
                ) {
                    this.selectedRiskCatalogue = (
                        this.riskCatalogueCmp.data as RiskCatalogueModel[]
                    ).find((x) => x.ID === catalogueId);
                    this.setRiskCatalogueValues(manually, true);
                    return;
                } else if (this.selectedRiskCatalogue.ID === catalogueId) {
                    return;
                }
                const riskCatalogueChanges = this.getRiskCatalogueChanges(catalogueId);

                // If none of the fields are being overridden then the popup doesn't need to be shown
                if (
                    riskCatalogueChanges.every(
                        (x) => x.OverrideType === OverrideRiskFieldTypes.None
                    )
                ) {
                    this.selectedRiskCatalogue = (
                        this.riskCatalogueCmp.data as RiskCatalogueModel[]
                    ).find((x) => x.ID === catalogueId);
                    this.setRiskCatalogueValues(manually, true);

                    return;
                }

                const defaultChanges = riskCatalogueChanges.filter(
                    (c) => c.OverrideType == OverrideRiskFieldTypes.Default
                );
                const mandatoryChanges = riskCatalogueChanges.filter(
                    (c) => c.OverrideType == OverrideRiskFieldTypes.Mandatory
                );

                const additionalConfig = <GenericListConfig>{
                    allowAdd: false,
                    allowDelete: false,
                    allowEdit: false,
                    idProp: "ID",
                    editOverride: () => {
                        return;
                    },
                    name: "",
                    fields: [
                        {
                            fieldName: "Field",
                            fieldType: GenericListFieldType.Text,
                        },
                        {
                            fieldName: "Current",
                            fieldType: GenericListFieldType.Text,
                        },
                        {
                            fieldName: "New",
                            fieldType: GenericListFieldType.Text,
                        },
                    ],
                };

                this.riskCatalogueChangedPrompt = this._promptService.confirmCustom({
                    maxHeight: "600px",
                    maxWidth: "900px",
                    data: {
                        title: "Changing risk catalogue",
                        message:
                            "The following items will be changed by selecting this risk catalogue. Do you want these fields to change?\n\nConfirm: All fields will be changed.\nNo: Only mandatory fields are changed.\nCancel: Undo risk catalogue change.",
                        fields: [
                            {
                                prettyName: "Default Changes",
                                fieldName: "changesDefault",
                                fieldType: GenericListFieldType.GenericList,
                                defaultValue: defaultChanges,
                                additionalConfig: additionalConfig,
                            },
                            {
                                prettyName: "Mandatory Changes",
                                fieldName: "changesMandatory",
                                fieldType: GenericListFieldType.GenericList,
                                defaultValue: mandatoryChanges,
                                additionalConfig: additionalConfig,
                            },
                        ],
                        confirmButton: { show: false },
                        cancelButton: { show: false },
                        closeOnEsc: false,
                        confirmOnEnter: false,
                        extraButtons: [
                            {
                                text: "Cancel",
                                show: true,
                                class: "btn btn-secondary",
                                icon: "far fa-times",
                                action: () => {
                                    this.riskCatalogueChangedPrompt.close();
                                    this.riskCatalogueCmp.value = this.selectedRiskCatalogue.ID;
                                },
                            },
                            {
                                text: "No",
                                show: true,
                                class: "btn btn-danger",
                                icon: "far fa-times",
                                action: () => {
                                    this.selectedRiskCatalogue = (
                                        this.riskCatalogueCmp.data as RiskCatalogueModel[]
                                    ).find((x) => x.ID === catalogueId);
                                    this.setRiskCatalogueValues(manually, false);
                                    this.riskCatalogueChangedPrompt.close();
                                },
                            },
                            {
                                text: "Confirm",
                                show: true,
                                class: "btn btn-cerrix",
                                icon: "far fa-check",
                                action: () => {
                                    this.selectedRiskCatalogue = (
                                        this.riskCatalogueCmp.data as RiskCatalogueModel[]
                                    ).find((x) => x.ID === catalogueId);
                                    this.setRiskCatalogueValues(manually, true);

                                    this.riskCatalogueChangedPrompt.close();
                                },
                            },
                        ],
                    },
                });
            } else {
                this.selectedRiskCatalogue = new RiskCatalogueModel();
                this.setRiskCatalogueValues(manually, false);
            }
        }
    }

    private getRiskCatalogueChanges(newCatalogueId: number): RiskCatalogueChangesItem[] {
        const changes: RiskCatalogueChangesItem[] = [];
        const newCatalogue = (this.riskCatalogueCmp.data as RiskCatalogueModel[]).find(
            (x) => x.ID === newCatalogueId
        );
        let currentValue = getFormValue<RiskModel>(this.parentForm, "name");
        if (
            currentValue !== newCatalogue.riskname &&
            newCatalogue.overrideRiskName !== OverrideRiskFieldTypes.None
        ) {
            changes.push(<RiskCatalogueChangesItem>{
                ID: 1,
                Field: "Risk name",
                Current: currentValue,
                New: newCatalogue.riskname,
                OverrideType: newCatalogue.overrideRiskName,
            });
        }

        currentValue = getFormValue<RiskModel>(this.parentForm, "description");
        if (
            currentValue !== newCatalogue.Description &&
            newCatalogue.overrideRiskDescription !== OverrideRiskFieldTypes.None
        ) {
            changes.push(<RiskCatalogueChangesItem>{
                ID: 2,
                Field: "Description ",
                Current: currentValue,
                New: newCatalogue.Description,
                OverrideType: newCatalogue.overrideRiskDescription,
            });
        }

        currentValue = getFormValue<RiskModel>(this.parentForm, "riskAreaId");
        if (
            currentValue !== newCatalogue.riskAreaId &&
            newCatalogue.overrideRiskArea !== OverrideRiskFieldTypes.None
        ) {
            const currentRiskArea =
                this.lookupRiskArea.find((ra) => ra.ID === currentValue)?.Name ?? "";
            const newRiskArea = this.lookupRiskArea.find(
                (ra) => ra.ID === newCatalogue.riskAreaId
            ).Name;
            changes.push(<RiskCatalogueChangesItem>{
                ID: 3,
                Field: "Risk area ",
                Current: currentRiskArea,
                New: newRiskArea,
                OverrideType: newCatalogue.overrideRiskArea,
            });
        }

        currentValue = getFormValue<RiskModel>(this.parentForm, "eventCategoryId");
        if (
            currentValue !== newCatalogue.eventCategoryId &&
            newCatalogue.overrideEventCategory !== OverrideRiskFieldTypes.None
        ) {
            const currentEventCategory = this.getSubcategory(currentValue);
            const newEventCategory = this.getSubcategory(newCatalogue.eventCategoryId);
            changes.push(<RiskCatalogueChangesItem>{
                ID: 4,
                Field: "Event category ",
                Current: currentEventCategory,
                New: newEventCategory,
                OverrideType: newCatalogue.overrideEventCategory,
            });
        }

        currentValue = getFormValue<RiskModel>(this.parentForm, "subtypeIds");
        if (
            newCatalogue.overrideSubTypes !== OverrideRiskFieldTypes.None &&
            this.isSubTypeChanged(newCatalogue, currentValue)
        ) {
            changes.push(<RiskCatalogueChangesItem>{
                ID: 5,
                Field: "Subtype ",
                Current: currentValue ? this.getSubtypeAsString(currentValue) : "",
                New:
                    newCatalogue.subTypeIds && newCatalogue.subTypeIds.any()
                        ? this.getSubtypeAsString(newCatalogue.subTypeIds)
                        : "",
                OverrideType: newCatalogue.overrideSubTypes,
            });
        }

        return changes;
    }

    private getSubtypeAsString(values: number[]): string {
        let subtypes: string = "";

        for (var i = 0; i < values.length; i++) {
            const items = this.availableSubTypes.filter((x) => values.includes(x.ID));

            subtypes = items.map((x) => x.Name).join("; ");
        }

        return subtypes;
    }

    private getSubcategory(id: number): string {
        const item = FindNode(this.lookupEventCategory as CerrixTreeItem[], id);
        if (item) {
            return item.Name;
        }
    }

    private isSubTypeChanged(newCatalogue: RiskCatalogueModel, currentValues?: number[]): boolean {
        if (!currentValues && newCatalogue.subTypeIds && newCatalogue.subTypeIds.any()) return true;
        if (currentValues && newCatalogue.subTypeIds && !newCatalogue.subTypeIds.any()) return true;
        if (
            newCatalogue.subTypeIds &&
            currentValues &&
            newCatalogue.subTypeIds.length === currentValues.length
        ) {
            var isChanged = false;
            for (var i = 0; i < currentValues.length; i++) {
                const current = currentValues[i];
                if (!newCatalogue.subTypeIds.find((x) => x == current)) {
                    isChanged = true;
                }
            }
            return isChanged;
        } else {
            return true;
        }
    }

    private setRiskCatalogueValues(manually: boolean = false, allFields: boolean = true): void {
        if (!this.selectedRiskCatalogue || this.selectedRiskCatalogue.ID < 0) {
            return;
        }

        getFormControl(this.parentForm, "definitionRiskCatalogue").setValue(
            this.selectedRiskCatalogue.definitionRiskCatalogue
        );

        // riskappetite
        this.risk.riskCatalogue = this.selectedRiskCatalogue;
        this.setRiskAppetite();

        this.filterSubTypes();
        if (manually) {
            // Only when changing the RiskCatalogue manually from list
            this.checkOverridableFields(allFields);
        } else {
            this.setOverrideFields();
        }
    }

    private setOverrideFields(): void {
        let ctrl = getFormControl<RiskModel>(this.parentForm, "name");
        this.selectedRiskCatalogue.overrideRiskName === OverrideRiskFieldTypes.Mandatory
            ? ctrl.disable()
            : this.setControlState(ctrl);

        ctrl = getFormControl<RiskModel>(this.parentForm, "description");
        this.selectedRiskCatalogue.overrideRiskDescription === OverrideRiskFieldTypes.Mandatory
            ? ctrl.disable()
            : this.setControlState(ctrl);

        ctrl = getFormControl<RiskModel>(this.parentForm, "riskAreaId");
        this.selectedRiskCatalogue.overrideRiskArea === OverrideRiskFieldTypes.Mandatory
            ? ctrl.disable()
            : this.setControlState(ctrl);

        ctrl = getFormControl<RiskModel>(this.parentForm, "eventCategoryId");
        this.selectedRiskCatalogue.overrideEventCategory === OverrideRiskFieldTypes.Mandatory
            ? ctrl.disable()
            : this.setControlState(ctrl);

        ctrl = getFormControl<RiskModel>(this.parentForm, "subtypeIds");
        this.selectedRiskCatalogue.overrideSubTypes === OverrideRiskFieldTypes.Mandatory
            ? ctrl.disable()
            : this.setControlState(ctrl);
    }

    private checkOverridableFields(allFields: boolean): void {
        this.checkOverrideRiskName(allFields);
        this.checkOverrideRiskDescription(allFields);
        this.checkOverrideRiskArea(allFields);
        this.checkOverrideEventCategory(allFields);
        this.checkOverrideSubTypes(allFields);
    }

    private checkOverrideRiskName(allFields: boolean): void {
        const ctrl = getFormControl<RiskModel>(this.parentForm, "name");
        if (this.selectedRiskCatalogue.overrideRiskName === OverrideRiskFieldTypes.None) {
            this.setControlState(ctrl);
        } else {
            if (
                this.selectedRiskCatalogue.overrideRiskName === OverrideRiskFieldTypes.Mandatory ||
                allFields
            ) {
                ctrl.enable(); // otherwise value not updated.
                ctrl.setValue(this.selectedRiskCatalogue.riskname);
            }

            this.selectedRiskCatalogue.overrideRiskName === OverrideRiskFieldTypes.Mandatory
                ? ctrl.disable()
                : this.setControlState(ctrl);
        }
    }

    private checkOverrideRiskDescription(allFields: boolean): void {
        const ctrl = getFormControl<RiskModel>(this.parentForm, "description");
        if (this.selectedRiskCatalogue.overrideRiskDescription === OverrideRiskFieldTypes.None) {
            this.setControlState(ctrl);
        } else {
            if (
                this.selectedRiskCatalogue.overrideRiskDescription ===
                    OverrideRiskFieldTypes.Mandatory ||
                allFields
            ) {
                ctrl.enable(); // otherwise value not updated.
                ctrl.setValue(this.selectedRiskCatalogue.Description);
            }

            this.selectedRiskCatalogue.overrideRiskDescription === OverrideRiskFieldTypes.Mandatory
                ? ctrl.disable()
                : this.setControlState(ctrl);
        }
    }

    private checkOverrideRiskArea(allFields: boolean): void {
        const ctrl = getFormControl<RiskModel>(this.parentForm, "riskAreaId");
        if (this.selectedRiskCatalogue.overrideRiskArea === OverrideRiskFieldTypes.None) {
            this.setControlState(ctrl);
        } else {
            if (
                this.selectedRiskCatalogue.overrideRiskArea === OverrideRiskFieldTypes.Mandatory ||
                allFields
            ) {
                ctrl.enable(); // otherwise value not updated.
                if (this.selectedRiskCatalogue.riskAreaId) {
                    ctrl.setValue(this.selectedRiskCatalogue.riskAreaId);
                } else {
                    ctrl.setValue(null);
                }
            }

            this.selectedRiskCatalogue.overrideRiskArea === OverrideRiskFieldTypes.Mandatory
                ? ctrl.disable()
                : this.setControlState(ctrl);
        }
    }

    private checkOverrideEventCategory(allFields: boolean): void {
        const ctrl = getFormControl<RiskModel>(this.parentForm, "eventCategoryId");
        if (this.selectedRiskCatalogue.overrideEventCategory === OverrideRiskFieldTypes.None) {
            this.setControlState(ctrl);
        } else {
            if (
                this.selectedRiskCatalogue.overrideEventCategory ===
                    OverrideRiskFieldTypes.Mandatory ||
                allFields
            ) {
                ctrl.enable(); // otherwise value not updated.
                const newEventCategoryValue = this.selectedRiskCatalogue.eventCategoryId
                    ? this.selectedRiskCatalogue.eventCategoryId
                    : null;

                setFormControlValue<RiskModel>(
                    this.parentForm,
                    "eventCategoryId",
                    newEventCategoryValue
                );
            }

            this.selectedRiskCatalogue.overrideEventCategory === OverrideRiskFieldTypes.Mandatory
                ? ctrl.disable()
                : this.setControlState(ctrl);
        }
    }

    private checkOverrideSubTypes(allFields: boolean): void {
        const ctrl = getFormControl<RiskModel>(this.parentForm, "subtypeIds");
        const currentValues = ctrl.value;
        if (this.selectedRiskCatalogue.overrideSubTypes === OverrideRiskFieldTypes.None) {
            if (this.selectedRiskCatalogueSubTypeIds().any()) {
                const items = this.selectedRiskCatalogueSubTypeIds().filter((x) =>
                    currentValues.includes(x)
                );

                ctrl.enable(); // otherwise value not updated.
                if (items.length > 0) {
                    ctrl.setValue(items);
                } else {
                    ctrl.setValue(null);
                    this.risk.subtypeIds = null;
                }
            }
            this.setControlState(ctrl);
        } else {
            if (
                this.selectedRiskCatalogue.overrideSubTypes === OverrideRiskFieldTypes.Mandatory ||
                allFields
            ) {
                ctrl.enable(); // otherwise value not updated.
                if (this.selectedRiskCatalogueSubTypeIds().length > 0) {
                    ctrl.setValue(this.selectedRiskCatalogue.subTypeIds);
                } else {
                    ctrl.setValue(null);
                    this.risk.subtypeIds = null;
                }
            } else {
                // check values and only set valid items.
                const items = this.selectedRiskCatalogueSubTypeIds().filter((x) =>
                    currentValues.includes(x)
                );

                ctrl.enable(); // otherwise value not updated.
                if (items.length > 0) {
                    ctrl.setValue(items);
                } else {
                    ctrl.setValue(null);
                    this.risk.subtypeIds = null;
                }
            }

            this.selectedRiskCatalogue.overrideSubTypes === OverrideRiskFieldTypes.Mandatory
                ? ctrl.disable()
                : this.setControlState(ctrl);
        }
    }

    setControlState(ctrl: AbstractControl): void {
        !this.permissions.readonly ? ctrl.enable() : ctrl.disable();
    }

    private resetRiskCatalogueValues(): void {
        getFormControl<RiskModel>(this.parentForm, "riskCatalogueId").setValue(null);
        getFormControl<RiskModel>(this.parentForm, "subtypeIds").setValue(null);
        getFormControl<RiskModel>(this.parentForm, "riskAreaId").setValue(null);
        getFormControl(this.parentForm, "definitionRiskCatalogue").setValue(null);
        getFormControl<RiskModel>(this.parentForm, "eventCategoryId").setValue(null);
    }

    private filterSubTypes(): void {
        if (this.selectedRiskCatalogue && this.selectedRiskCatalogue.subTypeIds) {
            this.filteredSubTypes = this.availableSubTypes.filter((x) =>
                this.selectedRiskCatalogue.subTypeIds.includes(x.ID)
            );
        } else {
            this.filteredSubTypes = [];
        }
    }

    private setRiskAppetiteOutline(catalogueRiskAppetite: MatrixSelectionModel[]): void {
        this.riskMatrices.forEach((matrix) => {
            matrix.setSuggestedSelectionOutline(catalogueRiskAppetite);
        });
    }

    //#region Framework dimensions

    async reloadFrameworkDimensions(bds: number[]): Promise<void> {
        if (!bds || !bds.isDifferent(this.previousBusinessDimensions)) {
            return;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.risk);

        if (this.risk.frameworkDimensionIds.length === 0) {
            this.reloadBusinessDimensionDependencies(bds);
            return;
        }

        const msg = await toPromise(this._riskDS.validateBusinessDimensionChange(this.risk));
        if (!msg || msg.messages.length == 0) {
            this.reloadBusinessDimensionDependencies(bds);
            return;
        }

        // only invalid FD
        let message =
            "These items can't be deleted, due to links with framework dimension(s). Do you want to remove the following item(s) before changing the business dimenesion(s)? :\n\n";
        msg.messages.forEach((m) => (message = message + m + "\n"));

        const confirmed = await toPromise(
            this.createConfirm("Cannot change business dimensions!", message).getResult()
        );
        if (confirmed) {
            this.updateFrameworkDimension(msg.invalidFrameworkDimensions);
            this.reloadBusinessDimensionDependencies(bds);
        } else {
            setFormControlValue<RiskModel>(
                this.parentForm,
                "businessDimensionIds",
                this.previousBusinessDimensions
            );
        }
    }

    updateFrameworkDimension(invalidFd: number[]): void {
        const previousFd = this.risk.frameworkDimensionIds;
        for (const ifd of invalidFd) {
            const index = previousFd.indexOf(ifd);
            if (index !== -1) {
                previousFd.splice(index, 1);
            }
        }

        this.risk.frameworkDimensionIds = previousFd;
    }

    reloadBusinessDimensionDependencies(bds: number[]): void {
        this.previousBusinessDimensions = bds;

        const selectedFrameworkDimensions = getFormValue<RiskModel>(
            this.parentForm,
            "frameworkDimensionIds"
        );

        this.frameworkDimensionsRequest = toPromise(
            this._riskDS.getStandingDataByType(RiskStandingDataType.FrameworkDimensions, this.risk)
        );

        setFormControlValue<RiskModel>(
            this.parentForm,
            "frameworkDimensionIds",
            selectedFrameworkDimensions
        );
    }

    //#endregion Framework dimensions

    //#region Risk Scoring leading scale consistency

    async checkCustomScaleValuesAreAlignable(): Promise<void> {
        this.alignCustomGrossIndex = null;
        this.alignCustomNetIndex = null;
        this.alignCustomTargetIndex = null;
        this.alignCustomLeadingValue = true;

        if (!this.parentForm) {
            return;
        }

        const leadingScaleID = this.risk.leadingImpactScaleId;
        const customScaleFormGroups = this.parentForm.controls["impactScales"][
            "controls"
        ] as FormGroup[];

        let customGrossIndex: number, customNetIndex: number, customTargetIndex: number;
        if (leadingScaleID) {
            // Get the current selected IDs of leading impact scales.
            const leadingFormGroup = customScaleFormGroups.find(
                (fg) => fg.controls["ID"].value == leadingScaleID
            );

            // Get the list of values of current impact scales, so we can determine the current selected index.
            const leadingScales = this.impactScaleData[leadingScaleID];
            const leadingItems = leadingScales.items as IdNameCombination[];

            // Get leading impact scale indexes
            const leadingGrossID = leadingFormGroup.controls["grossImpactId"].value;
            customGrossIndex = leadingItems.findIndex((i) => i.ID === leadingGrossID);

            const leadingNetID = leadingFormGroup.controls["netImpactId"].value;
            customNetIndex = leadingItems.findIndex((i) => i.ID === leadingNetID);

            if (this.enableTargetRisk) {
                const leadingTargetID = leadingFormGroup.controls["targetImpactId"].value;
                customTargetIndex = leadingItems.findIndex((i) => i.ID === leadingTargetID);
            }
        } else {
            // Set this to false, because we are comparing highest right now.
            this.alignCustomLeadingValue = false;

            // Map all customScales to a object so we can go through them more easily.
            const selectedValues = customScaleFormGroups.map((fg) => {
                let targetImpactId = -1;
                if (this.enableTargetRisk) {
                    targetImpactId = fg.controls["targetImpactId"].value;
                }

                return {
                    id: fg.controls["ID"].value,
                    gross: fg.controls["grossImpactId"].value,
                    net: fg.controls["netImpactId"].value,
                    target: targetImpactId,
                };
            });

            // Set these to -1 so we can compare if we have any actual values that have a higher index.
            customGrossIndex = -1;
            customNetIndex = -1;
            customTargetIndex = -1;

            // Find the highest gross and net custom impact scale. (index-wise)
            selectedValues.forEach((value) => {
                const scales = this.impactScaleData[value.id].items as IdNameCombination[];
                if (value.gross) {
                    const grossIndex = scales.findIndex((i) => i.ID === value.gross);
                    if (grossIndex > customGrossIndex) {
                        customGrossIndex = grossIndex;
                    }
                }

                if (value.net) {
                    const netIndex = scales.findIndex((i) => i.ID === value.net);
                    if (netIndex > customNetIndex) {
                        customNetIndex = netIndex;
                    }
                }

                if (value.target) {
                    const targetIndex = scales.findIndex((i) => i.ID === value.target);
                    if (targetIndex > customTargetIndex) {
                        customTargetIndex = targetIndex;
                    }
                }
            });
        }

        // Get default impact items and the currently selected gross and net IDs.
        const impactItems = await this.impactRequest;

        const currentGross = this.parentForm.get("impactId").value;
        const currentGrossIndex = impactItems.findIndex((i) => i.ID === currentGross);

        this.alignCustomGrossIndex =
            customGrossIndex >= 0 &&
            ((this.alignCustomLeadingValue && customGrossIndex != currentGrossIndex) ||
                customGrossIndex > currentGrossIndex)
                ? customGrossIndex
                : null;

        const currentNet = this.parentForm.get("netImpactId").value;
        const currentNetIndex = impactItems.findIndex((i) => i.ID === currentNet);

        this.alignCustomNetIndex =
            customNetIndex >= 0 &&
            ((this.alignCustomLeadingValue && customNetIndex != currentNetIndex) ||
                customNetIndex > currentNetIndex)
                ? customNetIndex
                : null;

        if (this.enableTargetRisk) {
            const currentTarget = this.parentForm.get("targetImpactId").value;
            const currentTargetIndex = impactItems.findIndex((i) => i.ID === currentTarget);

            this.alignCustomTargetIndex =
                customTargetIndex >= 0 &&
                ((this.alignCustomLeadingValue && customTargetIndex != currentTargetIndex) ||
                    customTargetIndex > currentTargetIndex)
                    ? customTargetIndex
                    : null;
        }
    }

    async setGrossImpactToCustomScale(): Promise<void> {
        if (this.alignCustomGrossIndex == null) {
            return;
        }

        const impactItems = await this.impactRequest;
        const idToSet = impactItems[this.alignCustomGrossIndex].ID;
        this.parentForm.get("impactId").setValue(idToSet);
        this.alignCustomGrossIndex = null;
    }

    async setNetImpactToCustomScale(): Promise<void> {
        if (this.alignCustomNetIndex == null) {
            return;
        }

        const impactItems = await this.impactRequest;
        const idToSet = impactItems[this.alignCustomNetIndex].ID;
        this.parentForm.get("netImpactId").setValue(idToSet);
        this.alignCustomNetIndex = null;
    }

    async setTargetImpactToCustomScale(): Promise<void> {
        if (this.alignCustomTargetIndex == null) {
            return;
        }

        const impactItems = await this.impactRequest;
        const idToSet = impactItems[this.alignCustomTargetIndex].ID;
        this.parentForm.get("targetImpactId").setValue(idToSet);
        this.alignCustomTargetIndex = null;
    }

    //#endregion Risk Scoring leading scale consistency

    private createConfirm(title: string, body: string): CerrixPromptComponent {
        return this._promptService.confirmCustom({
            maxWidth: "450px",
            maxHeight: "350px",
            data: {
                title: title,
                message: body,
            },
        });
    }

    private selectedRiskCatalogueSubTypeIds(): number[] {
        return this.selectedRiskCatalogue.subTypeIds ?? [];
    }

    fetchDescriptions = (): Promise<string[]> => {
        FormValidationHelper.mapToModel(this.parentForm, this.risk);

        return toPromise(this._riskDS.generateDescription(this.risk));
    };

    onDescriptionGenerated(description: string): void {
        const formControl = getFormControl<RiskModel>(this.parentForm, "description");
        formControl.setValue(description);
    }
}
