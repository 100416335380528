import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DocumentMethods } from "@methods/DocumentMethods";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { LinkModel } from "@models/generic/LinkModel";
import { MatrixModel } from "@models/generic/MatrixModel";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable, of } from "rxjs";
import { ApiDataService } from "../../../services/http/ApiDataService";
import { Configuration } from "../../app.constants";
import { RiskStandingDataType } from "../enums/RiskStandingDataType";
import { RiskModel, RiskWrapper } from "../models/RiskModel";
import { RiskPermissionModel } from "../models/RiskPermissionModel";
import { RiskScoreChartModel } from "../models/RiskScoreChartModel";
import { RiskValidationModel } from "../models/RiskValidationModel";
import { IdNameCombination } from "./../../../common/models/generic/IdNameCombination";
import { KriLinkModel } from "@models/kri/KriLinkModel";
import { RiskWorkspaceModel } from "../models/RiskWorkspaceModel";
import { RiskFinancialImpactValues } from "../models/RiskFinancialImpactValues";

@Injectable()
export class RiskDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "risk");
    }

    public getPermissions(guid?: string): Observable<RiskPermissionModel> {
        const url = guid && guid.length > 0 ? `/${guid}/permissions` : "/null/permissions";
        return this.get<RiskPermissionModel>(url);
    }

    public getRisk(guid: string): Observable<RiskWrapper> {
        const url = this.actionUrl + `/${guid}`;
        return this.http.get<any>(url);
    }

    public getBaseGuidFromPeriodGuid(guid: string) {
        const url = `/${guid}/getbaseguidfromperiodguid`;

        return this.get<string>(url);
    }

    public getList() {
        return this.get<RiskWorkspaceModel[]>();
    }

    public getByPeriodId(periodId: string) {
        const url = `/getlist/${periodId}`;
        return this.get<RiskWorkspaceModel[]>(url);
    }

    public hasAccess() {
        const url = "/hasaccess";
        return this.get<boolean>(url);
    }

    public hasAccessToRisk(guid: string) {
        if (isGuid(guid)) {
            const url = `/hasaccess/${guid}`;
            return this.get<boolean>(url);
        }

        return of(false);
    }

    public storeRisk(risk: RiskModel): Observable<HttpEvent<any>> {
        const formData = DocumentMethods.convertToFormDataWithFiles("risk", risk);
        return this.request(formData);
    }

    public getStandingDataByType(standingdataType: RiskStandingDataType, riskModel: RiskModel) {
        let url = riskModel.id > 0 ? `/${riskModel.guid}/` : "/null/";

        const hasOrganizationId = riskModel.organizationId > 0;
        switch (standingdataType) {
            case RiskStandingDataType.RiskCatalogues:
                if (!hasOrganizationId && !riskModel.riskCatalogueId) {
                    throw new Error(
                        "organizationId and risk catalogue is required to fetch the risk catalogue information."
                    );
                }

                url += `riskcatalogue/${riskModel.organizationId}/${riskModel.riskCatalogueId}`;
                break;
            case RiskStandingDataType.RiskOwners:
                // This method does not need the guid
                url = `/riskowners/${hasOrganizationId ? riskModel.organizationId : null}`;
                break;
            case RiskStandingDataType.BusinessDimensions:
                // This requires organization
                if (!hasOrganizationId) {
                    throw new Error("organizationId is required to fetch business dimensions.");
                }

                url += `businessdimensions/${riskModel.organizationId}`;
                break;
            case RiskStandingDataType.FrameworkDimensions:
                // This requires organization
                if (!hasOrganizationId) {
                    throw new Error("organizationId is required to fetch framework dimensions");
                }

                url = `/frameworkdimensions`;

                return this.post<CerrixTreeItem[]>(riskModel, url);
        }

        return this.get<any[]>(url);
    }

    public validateOrganizationChange(model: RiskModel): Observable<RiskValidationModel> {
        const url = `/validation/organizationchange`;

        return this.post<RiskValidationModel>(model, url);
    }

    public validateBusinessDimensionChange(model: RiskModel): Observable<RiskValidationModel> {
        const url = `/validation/businessdimensionchange`;

        return this.post<RiskValidationModel>(model, url);
    }

    public getLinkableMois(riskGuid: string): Observable<LinkModel[]> {
        const url = `/${riskGuid}/mois`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkedDataProcessings(riskGuid: string): Observable<LinkModel[]> {
        const url = `/${riskGuid}/dataprocessing`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkedThirdParties(riskGuid: string): Observable<LinkModel[]> {
        const url = `/${riskGuid}/thirdparty`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkedEvents(riskGuid: string): Observable<LinkModel[]> {
        const url = `/${riskGuid}/events`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkableKris(riskGuid: string, organizationId: number): Observable<KriLinkModel[]> {
        const url = `/${riskGuid}/kri/${organizationId}`;
        return this.get<KriLinkModel[]>(url);
    }

    public deleteRisk(riskGuid: string) {
        const url = `/${riskGuid}`;
        return this.delete(url);
    }

    public getHistory(riskGuid: string): Observable<HistoryModel[]> {
        const url = `/${riskGuid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(riskGuid: string, historyDate: string): Observable<HistoryChange[]> {
        const url = `/${riskGuid}/history/${historyDate}`;

        return this.get<HistoryChange[]>(url);
    }

    public getPeriodScoreData(riskGuid: string): Observable<RiskScoreChartModel> {
        const url = `/${riskGuid}/periodscore`;

        return this.get<RiskScoreChartModel>(url);
    }

    public getDocuments(riskGuid: string): Observable<DocumentModel[]> {
        const url = `/${riskGuid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(riskGuid: string): Observable<HyperlinkModel[]> {
        const url = `/${riskGuid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public getRiskScoringTypes() {
        const url = `/widget/scoringtypes`;
        return this.get<IdNameCombination[]>(url);
    }

    public getAvailableScales(id?: number) {
        let url = `/widget/availablescales`;
        if (id) {
            url += `?id=${id}`;
        }
        return this.get<IdNameCombination[]>(url);
    }

    public getRiskMatrixChart(filters: any) {
        const url = `/widget/matrixdata`;
        return this.post<MatrixModel>(filters, url);
    }

    public getRisksPerCatalogueWidgetInfo(filters: any) {
        const url = `/widget/riskspercataloguedata`;
        return this.post<{ name: string; y: number; color: string }[]>(filters, url);
    }

    public getLinkedControls(guid: string): Observable<number[]> {
        const url = `/${guid}/linkedcontrols`;
        return this.get<number[]>(url);
    }

    public generateDescription(risk: RiskModel): Observable<string[]> {
        const url = `/generate/description`;
        return this.post<string[]>(risk, url);
    }

    public calculateFinancialImpact(
        organizationId: number,
        likelihoodId: number,
        financialImpact: number
    ): Observable<RiskFinancialImpactValues> {
        const url = `/financialImpact`;
        const body = {
            organizationId: organizationId,
            likelihoodId: likelihoodId,
            financialImpact: financialImpact,
        };
        return this.post<RiskFinancialImpactValues>(body, url);
    }
}
