<div class="detail-page-card">
    <div class="detail-page-card-title">Options for {{ objectName }}</div>
    <div class="detail-page-card-actions">
        <button
            class="btn btn-raised me-2"
            *ngFor="let btn of buttonConfig"
            (click)="buttonClick(btn)"
            [disabled]="btn.isDisabled"
        >
            <i class="{{ btn.icon }}" *ngIf="btn.icon"></i>
            <span>{{ btn.text }}</span>
        </button>
        <br *ngIf="buttonConfig && buttonConfig.length > 0" />
        <button
            class="btn btn-raised red"
            (click)="deleteClick()"
            [disabled]="!allowDelete"
            data-uiid="detailoptions-deletebutton"
        >
            <i class="fas fa-trash"></i>
            <span>Delete {{ moduleName }}</span>
        </button>
    </div>
</div>
