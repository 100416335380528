import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RiskModel } from "@app/risk/models/RiskModel";
import { setFormControlValue } from "@methods/CommonMethods";

@Component({
    selector: "risk-impact-scales",
    templateUrl: "./risk-impact-scales.component.html",
    styleUrls: ["./risk-impact-scales.component.scss"],
})
export class RiskImpactScalesComponent implements OnInit {
    @Input() risk: RiskModel;
    @Input() parentForm: FormGroup;
    @Input() impactScaleData: any;
    @Input() enableTargetRisk: boolean;
    @Input() enableBudgetBasedRisk: boolean;

    @Output() onValueChange = new EventEmitter<never>();

    isLoading = true;

    constructor() {}

    ngOnInit() {
        setTimeout(() => {
            this.isLoading = false;
            this.emitChange();
        }, 250);
    }

    leadingImpactScaleClick(impactScaleId: number) {
        if (this.risk.leadingImpactScaleId == impactScaleId) {
            setFormControlValue<RiskModel>(this.parentForm, "leadingImpactScaleId", null);
        } else {
            setFormControlValue<RiskModel>(this.parentForm, "leadingImpactScaleId", impactScaleId);
        }

        this.emitChange();
    }

    emitChange() {
        this.onValueChange.emit();
    }
}
