import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import * as Highcharts from "highcharts/highcharts-gantt";

@Component({
    selector: "audit-planning",
    templateUrl: "./audit-planning.component.html",
    styleUrls: ["./audit-planning.component.scss"],
})
export class AuditPlanningComponent implements OnInit {
    tab: TabModel;
    @ViewChild("chartContainer", { static: true }) chartContainer: ElementRef;

    constructor(private _ds: AuditDataService) {}

    async ngOnInit() {
        await this.createChart();
        this.tab.showLoader = false;
    }

    private async createChart() {
        Highcharts.ganttChart(this.chartContainer.nativeElement as HTMLElement, {
            title: {
                text: "Audit Planning",
            },

            credits: {
                enabled: false,
            },

            yAxis: {
                uniqueNames: false,
            },

            navigator: {
                enabled: true,
                series: {
                    type: "gantt",
                    pointWidth: 0.5,
                    pointPadding: 0.25,
                },
                yAxis: {
                    min: 0,
                    max: 3,
                    reversed: true,
                    categories: [],
                },
            },
            scrollbar: {
                enabled: true,
            },

            rangeSelector: {
                enabled: true,
                selected: 5,
            },

            series: [
                {
                    name: "Planning",
                    type: "gantt",
                    data: await this._ds.getPlanningData(),
                },
            ],
        });
    }
}
