import { Injectable, inject } from "@angular/core";
import { BaseTreeService } from "@cerrix/components";
import { LocalStorageService } from "@services/http/LocalStorageService";

@Injectable({
    providedIn: "root",
})
export class TreeSettingsService extends BaseTreeService {
    private cascadeChildrenStorageKey = "CERRIX_SELECT_TREE_CASCADE_CHILDREN";

    private localStorage: LocalStorageService = inject(LocalStorageService);

    getCascadeChildren(): boolean {
        return this.localStorage.getItem<boolean>(this.cascadeChildrenStorageKey);
    }
    setCascadeChildren(cascadeChildren: boolean): void {
        this.localStorage.setItem(this.cascadeChildrenStorageKey, cascadeChildren);
    }
}
