import { NgModule, ModuleWithProviders } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import { EventDataService } from "./services/EventDataService";
import { EventDetailComponent } from "./event-detail/event-detail.component";
import { EventDetailMainComponent } from "./event-detail/event-detail-main/event-detail-main.component";
import { EventOverviewComponent } from "./event-overview/event-overview.component";
import { EventCommentsComponent } from "./event-comments/event-comments.component";
import { EventCommentsDialogComponent } from "./event-comments/event-comments-dialog/event-comments-dialog.component";
import { BreachDetailComponent } from "./breach-detail/breach-detail.component";
import { DataBreachDataService } from "./services/DataBreachDataService";
import { EventRiskComponent } from "./event-detail/event-risk/event-risk.component";
import { EventControlComponent } from "./event-detail/event-control/event-control.component";
import { EventRiskControlDialogComponent } from "./event-detail/event-risk-control-dialog/event-risk-control-dialog.component";

@NgModule({
    declarations: [
        EventDetailComponent,
        EventDetailMainComponent,
        EventOverviewComponent,
        EventCommentsComponent,
        EventCommentsDialogComponent,
        EventRiskComponent,
        EventControlComponent,
        EventRiskControlDialogComponent,
        BreachDetailComponent,
    ],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class EventModule {
    static forRoot(): ModuleWithProviders<EventModule> {
        return {
            ngModule: EventModule,
            providers: [EventDataService, DataBreachDataService],
        };
    }
}
