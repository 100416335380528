import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { LocalizationService } from "@app/shared";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { isDirty, tryParseJson } from "@methods/CommonMethods";
import { Observable } from "rxjs";

@Component({
    selector: "generic-field-editor",
    templateUrl: "./generic-field-editor.component.html",
    styleUrls: ["./generic-field-editor.component.scss"],
})
export class GenericFieldEditorComponent implements OnInit, DoCheck {
    @Input() row: any;
    @Input() field: GenericListField;
    @Input() editEnabled: boolean;
    @Input() hideFillers: boolean;
    @Input() additionalData = {};
    @Input() selfInit = false;
    @Input() customEditor = false;
    @Input() hideLabel = false;
    @Output() valueChanged = new EventEmitter();

    fieldType = GenericListFieldType;

    private _rowCopy: any = null;

    constructor(
        private localizationService: LocalizationService,
        private _standingdataDS: StandingdataDataService,
        private _configuration: Configuration
    ) {}

    ngOnInit() {
        if (this.customEditor) {
            this.row = this.row ? this.row : {};
            return;
        }

        this.field.formattedName = this.field.prettyName
            ? this.field.prettyName
            : this.field.fieldName;
        this.field.formattedName += this.field.required ? " *" : "";

        this.field.placeholder = this.field.placeholder || (this.field.required ? "Required" : "");

        if (this.row[this.field.fieldName] == undefined || this.row[this.field.fieldName] == null) {
            if (this.field.defaultValue !== undefined) {
                this.row[this.field.fieldName] = this.field.defaultValue;
            } else if (
                this.field.fieldType === GenericListFieldType.MultiSelect ||
                this.field.fieldType === GenericListFieldType.MultiTree ||
                this.field.fieldType === GenericListFieldType.Documents
            ) {
                this.row[this.field.fieldName] = [];
            } else if (this.field.fieldType === GenericListFieldType.Date) {
                this.row[this.field.fieldName] = this.localizationService.getUserDateAsUtc();
            } else if (this.field.fieldType === GenericListFieldType.DateTime) {
                this.row[this.field.fieldName] = new Date();
            } else if (
                this.field.fieldType === GenericListFieldType.ColoredSelect ||
                this.field.fieldType === GenericListFieldType.SingleSelect
            ) {
                this.row[this.field.fieldName] = null;
            } else {
                this.row[this.field.fieldName] = "";
            }
        }

        // Map max field lengths based on input type only if no maxLength is set
        if (!this.field.maxLength) {
            if (this.field.fieldType == GenericListFieldType.Text) {
                this.field.maxLength = this._configuration.MaxTextFieldLength;
            } else if (this.field.fieldType == GenericListFieldType.TextArea) {
                this.field.maxLength = this._configuration.MaxTextAreaLength;
            }
        }

        if (this.selfInit || this.field.selfInit) {
            this.setMetadata();
        }
    }

    // We use docheck instead of ngOnChanges because we expect changes within properties of row, which does not always cause a changeevent
    ngDoCheck() {
        if (!this.field) {
            return;
        }

        if (this._rowCopy) {
            if (this.field.checkReloadDataByRowChanges && isDirty(this._rowCopy, this.row)) {
                if (this.field.checkReloadDataByRowChanges(this._rowCopy, this.row)) {
                    this.setMetadata();
                }

                this.valueChanged.emit();
            } else if (this.customEditor && isDirty(this.row, this._rowCopy)) {
                this.valueChanged.emit(this.row);
            } else if (isDirty(this.row[this.field.fieldName], this._rowCopy[this.field.fieldName]))
                this.valueChanged.emit();
        }

        this._rowCopy = JSON.parse(JSON.stringify(this.row));
    }

    updateSelect(fieldName, data) {
        if (Array.isArray(data)) {
            if (data.some((d) => d.ID)) {
                this.row[fieldName] = data.map((d) => d.ID);
            } else {
                this.row[fieldName] = data;
            }
        } else if (data) {
            if (data.ID) {
                this.row[fieldName] = data.ID;
            } else {
                this.row[fieldName] = data;
            }
        }
    }

    updateTreeSelection(fieldname, selected: number | number[]) {
        if (Array.isArray(selected)) {
            this.row[fieldname] = selected;
        } else {
            this.row[fieldname] = [selected];
        }

        if (
            this.field.fieldType === GenericListFieldType.SingleTree &&
            Array.isArray(this.row[fieldname])
        ) {
            this.row[fieldname] = this.row[fieldname][0];
        }
    }

    setMetadata() {
        if (!this.additionalData) {
            this.additionalData = {};
        }

        if (this.field.getDataMethod) {
            this.additionalData[this.field.fieldName + "Loading"] = true;
            this.field.getDataMethod().subscribe((_res) => {
                this.additionalData[this.field.fieldName] = _res;
                this.additionalData[this.field.fieldName + "Loading"] = false;
            });
        } else if (
            this.field.getDataMethodByRow ||
            (this.field.getDataMethodName && this._standingdataDS[this.field.getDataMethodName])
        ) {
            this.additionalData[this.field.fieldName] = null;
            this.additionalData[this.field.fieldName + "Loading"] = true;
            const dataCall: Observable<any> = this.field.getDataMethodByRow
                ? this.field.getDataMethodByRow(this.row, this._standingdataDS)
                : this._standingdataDS[this.field.getDataMethodName](this.field.getDataParams);

            dataCall.subscribe((d) => {
                const data = tryParseJson(d);
                if (data) {
                    this.additionalData[this.field.fieldName] = data;
                } else {
                    this.additionalData[this.field.fieldName] = d;
                }
                this.additionalData[this.field.fieldName + "Loading"] = false;
            });
        }
    }
}
