import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../../api-spec";

@Component({
    selector: "tpa-get-evidence-export-api-manual",
    templateUrl: "../../../api-spec.html",
    styleUrls: ["../../../api-spec.scss"],
})
export class TpaGetEvidenceExportApiManualComponent extends ApiSpecComponent {
    initSpecs(): ApiSpecificationModel {
        return <ApiSpecificationModel>{
            name: "Get evidence document",
            description: "Retrieve the evidence documents.",
            httpMethod: "GET",
            path: "thirdpartyassurance/getevidencedocument/{requestId}",

            parameters: [
                {
                    name: "requestId",
                    description: "id of the request",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
                {
                    name: "controlidentifier",
                    description: "id of the requested control",
                    in: "query",
                    paramType: "integer",
                    required: true,
                    example: "1",
                },
                {
                    name: "documentid",
                    description: "id of the document",
                    in: "query",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: "binary data",
                },
            ],
        };
    }
}
