import { Component, Input } from "@angular/core";
import { ControlModel } from "@app/controls/models/ControlModel";
import { FormLinkableModule } from "@app/forms/enums/FormLinkableModule";
import { FormsLinkModel } from "@app/forms/models/other/FormsLinkModel";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { LinkModel } from "@models/generic/LinkModel";

@Component({
    selector: "control-forms",
    templateUrl: "./control-forms.component.html",
})
export class ControlFormsComponent {
    @Input() controlModel: ControlModel;
    @Input() disabled: boolean;

    linkedModule: FormLinkableModule = FormLinkableModule.Controls;
    isLoading: boolean = true;
    linkableForms: FormsLinkModel[] = [];
    unlinked: FormsLinkModel[] = [];
    linked: FormsLinkModel[] = [];

    constructor(private formsDs: FormsDataService) {}

    public async load(): Promise<void> {
        this.linkableForms = await this.formsDs.getLinkableForms(FormLinkableModule.Controls);
        this.initLists();
        this.isLoading = false;
    }

    initLists(): void {
        const linkedForms = this.controlModel.FormsIdList;

        this.linked = [];
        this.unlinked = [];

        this.linkableForms.forEach((x) => {
            if (linkedForms.includes(x.id)) {
                this.linked.push(x);
            } else {
                this.unlinked.push(x);
            }
        });
    }

    linkedItemsChanged(linked: LinkModel[]): void {
        const newFormsList = linked.map((x) => x.id);
        this.controlModel.FormsIdList = newFormsList;

        this.initLists();
    }
}
