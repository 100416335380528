<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [headerLookup]="headerLookup"
    [data]="data"
    [module]="'MailMessages'"
    [moduleType]="moduleType"
    [appendToModuleType]="'sentmails'"
    [idProp]="'ID'"
    [permissions]="permissions"
    (onDblClick)="openMailMessage($event)"
    [DateProperties]="dateProps"
    [rendererConfig]="rendererConfig"
    [filterConfig]="filterConfigs"
    [useLightWorkspace]="true"
>
</cerrix-workspace>
