<div class="d-flex flex-column main-section">
    <div class="message-section" *ngIf="finalConclusion?.message">
        {{ finalConclusion?.message }}
    </div>
    <div class="d-flex flex-row score-author-section" *ngIf="!finalConclusion.hideScore">
        <div class="d-flex flex-column author-section" *ngIf="!isEditMode">
            <div class="label">{{ finalConclusion.authorLabel }}</div>
            <div class="d-flex flex-row">
                <div class="circle">
                    <p class="circle-inner">{{ initials }}</p>
                </div>
                <div class="author-name">{{ finalConclusion.author }}</div>
            </div>
        </div>
        <div class="d-flex flex-column score-section">
            <div class="label">Final score</div>
            <eff-colored-selector
                *ngIf="isEditMode"
                [options]="finalConclusion.availableScores"
                [(model)]="finalConclusion.score"
            />
            <div
                *ngIf="!isEditMode"
                [style.background-color]="finalConclusion.scoreColor"
                class="final-score-view"
            >
                {{ finalConclusion.scoreName }}
            </div>
        </div>
    </div>
    <div class="comment-section">
        <eff-textarea
            *ngIf="isEditMode"
            class="grid-textarea-style"
            [(model)]="finalConclusion.conclusion"
            label="add a comment"
        ></eff-textarea>
        <div *ngIf="!isEditMode" class="comment-readonly">{{ finalConclusion.conclusion }}</div>
    </div>
</div>
