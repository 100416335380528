<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="cerrix-detail-page" menuSystem [cerrixTab]="tab" (change)="onMenuItemClick($event)">
    <div
        [attr.name]="'Effectiveness Testing'"
        class="control-testing-detail-page-submenu"
        menuicon="far fa-glasses-alt"
        menusystemsubmenu
    >
        <div
            name="Details"
            class="control-testing-detail-page-new-wrapper"
            menuicon="fas fa-info-circle"
            menusystemitem
        >
            <eff-detail-main
                class="control-testing-detail-page-new"
                [cerrixTab]="tab"
                [effectivenessGuid]="effectivenessGuid"
            ></eff-detail-main>
        </div>

        <div [attr.name]="'Links'" menuicon="fas fa-link" menusystemsubmenu>
            <div name="Risks" menuicon="fas fa-exclamation-triangle" menusystemitem>
                <div class="detail-page-card">
                    <div class="detail-page-card-title">Linked Risks</div>
                    <div class="detail-page-card-body padded cerrix-compact">
                        <adv-eff-linked-risks
                            [linkedRisks]="linkedRisks$ | async"
                            (riskClicked)="openRisk($event)"
                        >
                        </adv-eff-linked-risks>
                    </div>
                </div>
            </div>
        </div>

        <div name="Workflow history" menuicon="fas fa-history" menusystemitem>
            <eff-detail-workflow-history
                [effectivenessGuid]="effectivenessGuid"
            ></eff-detail-workflow-history>
        </div>
    </div>
</div>
