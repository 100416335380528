import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "../../app.constants";
import { AuditModuleDataRequestModel } from "../shared/request-models/audit-module-data-request-model";

@Injectable()
export class AuditModuleDataService extends ApiDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audit/moduledata");
    }

    //#region Module Data

    getRiskData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/risk");
    }

    getControlData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/control");
    }

    getEventData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/event");
    }

    geMoiData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/moi");
    }

    getKriData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/kri");
    }

    getDataManagementData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/datamanagement");
    }

    getThirdPartyData(model: AuditModuleDataRequestModel) {
        return this.post<any[]>(model, "/thirdparty");
    }

    //#endregion Module Data
}
