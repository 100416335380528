import { Component, Input, OnInit } from "@angular/core";
import { FormFieldTypeDescription, FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { CustomSortableOptions } from "@methods/PageMethods";
import { FormField, FormFieldConfig } from "@app/forms/models/detail/FormField";
import { KeyValue } from "@models/generic/KeyValuePair";
import { parseTyped } from "@methods/CommonMethods";
import { SortableOptions } from "sortablejs";

@Component({
    selector: "form-detail-options",
    templateUrl: "./form-detail-options.component.html",
    styleUrls: ["../../form-detail-field.component.scss", "./form-detail-options.component.scss"],
})
export class FormDetailOptionsComponent implements OnInit {
    @Input() field: FormField;
    optionSettings = new FormFieldConfig();

    types = FormFieldType;
    typeDescriptions = FormFieldTypeDescription;

    options: KeyValue<number, string>[];
    sortingOptions: SortableOptions;

    multiselectLocked = false;
    optionsLocked = false;

    constructor() {
        this.sortingOptions = CustomSortableOptions({
            handle: ".fa-sort",
        });
    }

    ngOnInit() {
        this.optionSettings = parseTyped<FormFieldConfig>(
            this.field.CustomSettings,
            new FormFieldConfig()
        );
        if (!this.optionSettings.options || this.optionSettings.options.length === 0) {
            this.optionSettings.options = [""];
        }

        if (this.optionSettings.fieldType !== this.field.FieldType) {
            this.optionSettings.fieldType = this.field.FieldType;
        }

        this.options = this.optionSettings.options.map(
            (x, i) => <KeyValue<number, string>>{ Key: i, Value: x }
        );
    }

    addOption() {
        this.options.push({ Key: this.options.length, Value: "" });
        this.updateAnswer();
    }

    removeOption(index: number) {
        this.options.splice(index, 1);
        this.updateAnswer();
    }

    updateAnswer() {
        if (
            this.field.FieldType === FormFieldType.Checkbox ||
            (this.field.FieldType === FormFieldType.Dropdown && this.optionSettings.multiSelect)
        ) {
            this.field.Required = this.optionSettings.minimumSelection > 0;
        }

        this.optionSettings.options = this.options.map((x) => x.Value);
        this.field.CustomSettings = JSON.stringify(this.optionSettings);
    }

    updateConfig(isMapbackConfig: boolean, config?: FormFieldConfig) {
        this.multiselectLocked = false;
        this.optionsLocked = false;

        if (!this.optionSettings) {
            this.optionSettings = new FormFieldConfig();
        }

        if (isMapbackConfig && config) {
            this.multiselectLocked = true;
            this.optionSettings.minimumSelection = config.minimumSelection;
            this.optionSettings.maximumSelection = config.maximumSelection;
            this.optionSettings.multiSelect = true;

            this.optionSettings.options = config.options;
            this.options = this.optionSettings.options.map(
                (x, i) => <KeyValue<number, string>>{ Key: i, Value: x }
            );
            this.optionsLocked = true;
        }

        this.updateAnswer();
    }
}
