import { Component, OnInit } from "@angular/core";
import { ModuleType } from "@enums/ModuleType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { FilterType } from "@enums/FilterType";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { FormatType } from "@enums/FormatType";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { MoiDataService } from "../services/moi-data.service";
import { ToastrService, ActiveToast } from "ngx-toastr";
import { isGuid } from "@methods/uniqueMethods";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { toPromise } from "@methods/CommonMethods";

@Component({
    selector: "app-moi-overview",
    templateUrl: "./moi-overview.component.html",
    styleUrls: ["./moi-overview.component.scss"],
})
export class MoiOverviewComponent implements OnInit {
    moduleType = ModuleType.BIM;
    tabID: string;
    tab: TabModel;
    data: Promise<any[]>;
    headerLookup: any = {};
    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations Responsible",
            column: "OrganizationID",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Organizations Reviewer",
            column: "OrganizationReviewerID",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: "BusinessDimensionIDs",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: "FrameworkDimensionIDs",
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Period",
            column: "Period",
            type: FilterType.Period,
            param: "",
            remoteFilter: true,
            module: ModuleType.BIM,
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Due Date",
            actionColumn: "Is Overdue",
            actionValue: "Yes",
            type: RendererType.DueDate,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "Progress",
            type: RendererType.Progress,
        },
        {
            textColumn: "Implementation Score",
            actionColumn: "Implementation_Score_Color",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "Overall Score",
            actionColumn: "Overall_Score_Color",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "Assessment Score",
            actionColumn: "Assessment_Score_Color",
            hideActionColumn: true,
            type: RendererType.Score,
        },
    ];

    numberProps: string[] = ["Progress"];
    dateProps: string[] = [
        "Due Date",
        "Start Date",
        "Date Created",
        "Initial Due Date",
        "Comment Date",
        "Report Date",
        "Report Due Date",
        "Assessment Date",
        "Date Status Changed",
    ];

    permissions: WorkspaceModulePermissionModel;

    currentlyLoadedPeriod = null;
    periodFilterToast: ActiveToast<any> = null;

    constructor(
        private tabService: TabService,
        private moiDataService: MoiDataService,
        private toastr: ToastrService,
        private pages: Pages,
        private _permissions: PermissionsService
    ) {}

    ngOnInit() {
        this.setDateFilters();
        this.setPermissions();
        this.loadData(this.currentlyLoadedPeriod);
    }

    private setDateFilters() {
        this.dateProps.forEach((x) => {
            this.rendererConfig.push(<RendererConfig>{
                textColumn: x,
                type: RendererType.Default,
                formatType: FormatType.DateFormat,
            });
        });
    }

    async openMoi(moi: any) {
        if (this.currentlyLoadedPeriod > 0) {
            // Check whether the user has access to the moi, store the result in the moi object to avoid another call
            if (moi.hasAccess == null) {
                moi.hasAccess = await toPromise(this.moiDataService.hasAccessToMoi(moi.Guid));
            }

            if (!moi.hasAccess) {
                this.createPeriodToast(
                    "Unable to open MoI.",
                    "This MoI is deleted in the current period."
                );

                return;
            }

            this.createPeriodToast(
                "Period filter applied.",
                "Opening current state of the MoI. \n\n Current MoI field values may not align with those displayed in the workspace.",
                true
            );

            this.openTab(moi.Guid, `(M) Measure of improvement`);

            return;
        }

        this.openTab(moi.Guid, `(M) Measure of improvement`);
    }

    createPeriodToast(title: string, msg: string, persistent?: boolean) {
        if (this.periodFilterToast) {
            this.periodFilterToast.toastRef.close();
        }

        this.periodFilterToast = this.toastr.warning(msg, title, { disableTimeOut: persistent });
    }

    addMoi() {
        this.openTab(null, `(M) New Measure of Improvement`);
    }

    private openTab(guid: string, name: string) {
        this.tabService.generateTab(this.pages.MoiDetail, guid, name);
    }

    getData(keyWords: SearchKeyword[]) {
        this.currentlyLoadedPeriod = null;
        for (const keyWord of keyWords) {
            const columnName = keyWord.col.toLowerCase();
            switch (columnName) {
                case "period": {
                    const periodID = parseInt(keyWord.searchValue, 0);
                    if (periodID > 0) {
                        this.currentlyLoadedPeriod = periodID;
                    }
                    break;
                }
            }
        }

        this.loadData(this.currentlyLoadedPeriod);
    }

    private setPermissions(): void {
        this.permissions = this._permissions.permissions.Workspace.bim;
    }

    private loadData(periodId: number = null): void {
        this.data = this.moiDataService.getMois(periodId).toPromise().then(this.mapHeaders);
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
