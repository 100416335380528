[
    {
        "name": "afghanistan",
        "selector": "flag-icon flag-icon-af",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "kabul"
        ]
    },
    {
        "name": "aland islands",
        "selector": "flag-icon flag-icon-ax",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "mariehamn"
        ]
    },
    {
        "name": "albania",
        "selector": "flag-icon flag-icon-al",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "tirana"
        ]
    },
    {
        "name": "algeria",
        "selector": "flag-icon flag-icon-dz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "algiers"
        ]
    },
    {
        "name": "american samoa",
        "selector": "flag-icon flag-icon-as",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "pago pago"
        ]
    },
    {
        "name": "andorra",
        "selector": "flag-icon flag-icon-ad",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "andorra la vella"
        ]
    },
    {
        "name": "angola",
        "selector": "flag-icon flag-icon-ao",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "luanda"
        ]
    },
    {
        "name": "anguilla",
        "selector": "flag-icon flag-icon-ai",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "the valley"
        ]
    },
    {
        "name": "antigua and barbuda",
        "selector": "flag-icon flag-icon-ag",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "st. john's"
        ]
    },
    {
        "name": "argentina",
        "selector": "flag-icon flag-icon-ar",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "buenos aires"
        ]
    },
    {
        "name": "armenia",
        "selector": "flag-icon flag-icon-am",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "yerevan"
        ]
    },
    {
        "name": "aruba",
        "selector": "flag-icon flag-icon-aw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "oranjestad"
        ]
    },
    {
        "name": "australia",
        "selector": "flag-icon flag-icon-au",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "canberra"
        ]
    },
    {
        "name": "austria",
        "selector": "flag-icon flag-icon-at",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "vienna"
        ]
    },
    {
        "name": "azerbaijan",
        "selector": "flag-icon flag-icon-az",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "baku"
        ]
    },
    {
        "name": "bahamas",
        "selector": "flag-icon flag-icon-bs",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "nassau"
        ]
    },
    {
        "name": "bahrain",
        "selector": "flag-icon flag-icon-bh",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "manama"
        ]
    },
    {
        "name": "bangladesh",
        "selector": "flag-icon flag-icon-bd",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "dhaka"
        ]
    },
    {
        "name": "barbados",
        "selector": "flag-icon flag-icon-bb",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "bridgetown"
        ]
    },
    {
        "name": "belarus",
        "selector": "flag-icon flag-icon-by",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "minsk"
        ]
    },
    {
        "name": "belgium",
        "selector": "flag-icon flag-icon-be",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "brussels"
        ]
    },
    {
        "name": "belize",
        "selector": "flag-icon flag-icon-bz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "belmopan"
        ]
    },
    {
        "name": "benin",
        "selector": "flag-icon flag-icon-bj",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "porto-novo"
        ]
    },
    {
        "name": "bermuda",
        "selector": "flag-icon flag-icon-bm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "hamilton"
        ]
    },
    {
        "name": "bhutan",
        "selector": "flag-icon flag-icon-bt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "thimphu"
        ]
    },
    {
        "name": "bolivia (plurinational state of)",
        "selector": "flag-icon flag-icon-bo",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "sucre"
        ]
    },
    {
        "name": "bonaire, sint eustatius and saba",
        "selector": "flag-icon flag-icon-bq",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "kralendijk"
        ]
    },
    {
        "name": "bosnia and herzegovina",
        "selector": "flag-icon flag-icon-ba",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "sarajevo"
        ]
    },
    {
        "name": "botswana",
        "selector": "flag-icon flag-icon-bw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "gaborone"
        ]
    },
    {
        "name": "brazil",
        "selector": "flag-icon flag-icon-br",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "brasília"
        ]
    },
    {
        "name": "british indian ocean territory",
        "selector": "flag-icon flag-icon-io",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "diego garcia"
        ]
    },
    {
        "name": "brunei darussalam",
        "selector": "flag-icon flag-icon-bn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "bandar seri begawan"
        ]
    },
    {
        "name": "bulgaria",
        "selector": "flag-icon flag-icon-bg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "sofia"
        ]
    },
    {
        "name": "burkina faso",
        "selector": "flag-icon flag-icon-bf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "ouagadougou"
        ]
    },
    {
        "name": "burundi",
        "selector": "flag-icon flag-icon-bi",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "bujumbura"
        ]
    },
    {
        "name": "cabo verde",
        "selector": "flag-icon flag-icon-cv",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "praia"
        ]
    },
    {
        "name": "cambodia",
        "selector": "flag-icon flag-icon-kh",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "phnom penh"
        ]
    },
    {
        "name": "cameroon",
        "selector": "flag-icon flag-icon-cm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "yaoundé"
        ]
    },
    {
        "name": "canada",
        "selector": "flag-icon flag-icon-ca",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "ottawa"
        ]
    },
    {
        "name": "cayman islands",
        "selector": "flag-icon flag-icon-ky",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "george town"
        ]
    },
    {
        "name": "central african republic",
        "selector": "flag-icon flag-icon-cf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "bangui"
        ]
    },
    {
        "name": "chad",
        "selector": "flag-icon flag-icon-td",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "n'djamena"
        ]
    },
    {
        "name": "chile",
        "selector": "flag-icon flag-icon-cl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "santiago"
        ]
    },
    {
        "name": "china",
        "selector": "flag-icon flag-icon-cn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "beijing"
        ]
    },
    {
        "name": "christmas island",
        "selector": "flag-icon flag-icon-cx",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "flying fish cove"
        ]
    },
    {
        "name": "cocos (keeling) islands",
        "selector": "flag-icon flag-icon-cc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "west island"
        ]
    },
    {
        "name": "colombia",
        "selector": "flag-icon flag-icon-co",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "bogotá"
        ]
    },
    {
        "name": "comoros",
        "selector": "flag-icon flag-icon-km",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "moroni"
        ]
    },
    {
        "name": "cook islands",
        "selector": "flag-icon flag-icon-ck",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "avarua"
        ]
    },
    {
        "name": "costa rica",
        "selector": "flag-icon flag-icon-cr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "san josé"
        ]
    },
    {
        "name": "croatia",
        "selector": "flag-icon flag-icon-hr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "zagreb"
        ]
    },
    {
        "name": "cuba",
        "selector": "flag-icon flag-icon-cu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "havana"
        ]
    },
    {
        "name": "curaçao",
        "selector": "flag-icon flag-icon-cw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "willemstad"
        ]
    },
    {
        "name": "cyprus",
        "selector": "flag-icon flag-icon-cy",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "nicosia"
        ]
    },
    {
        "name": "czech republic",
        "selector": "flag-icon flag-icon-cz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "prague"
        ]
    },
    {
        "name": "côte d'ivoire",
        "selector": "flag-icon flag-icon-ci",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "yamoussoukro"
        ]
    },
    {
        "name": "democratic republic of the congo",
        "selector": "flag-icon flag-icon-cd",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "kinshasa"
        ]
    },
    {
        "name": "denmark",
        "selector": "flag-icon flag-icon-dk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "copenhagen"
        ]
    },
    {
        "name": "djibouti",
        "selector": "flag-icon flag-icon-dj",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "djibouti"
        ]
    },
    {
        "name": "dominica",
        "selector": "flag-icon flag-icon-dm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "roseau"
        ]
    },
    {
        "name": "dominican republic",
        "selector": "flag-icon flag-icon-do",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "santo domingo"
        ]
    },
    {
        "name": "ecuador",
        "selector": "flag-icon flag-icon-ec",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "quito"
        ]
    },
    {
        "name": "egypt",
        "selector": "flag-icon flag-icon-eg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "cairo"
        ]
    },
    {
        "name": "el salvador",
        "selector": "flag-icon flag-icon-sv",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "san salvador"
        ]
    },
    {
        "name": "equatorial guinea",
        "selector": "flag-icon flag-icon-gq",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "malabo"
        ]
    },
    {
        "name": "eritrea",
        "selector": "flag-icon flag-icon-er",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "asmara"
        ]
    },
    {
        "name": "estonia",
        "selector": "flag-icon flag-icon-ee",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "tallinn"
        ]
    },
    {
        "name": "ethiopia",
        "selector": "flag-icon flag-icon-et",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "addis ababa"
        ]
    },
    {
        "name": "falkland islands",
        "selector": "flag-icon flag-icon-fk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "stanley"
        ]
    },
    {
        "name": "faroe islands",
        "selector": "flag-icon flag-icon-fo",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "tórshavn"
        ]
    },
    {
        "name": "federated states of micronesia",
        "selector": "flag-icon flag-icon-fm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "palikir"
        ]
    },
    {
        "name": "fiji",
        "selector": "flag-icon flag-icon-fj",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "suva"
        ]
    },
    {
        "name": "finland",
        "selector": "flag-icon flag-icon-fi",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "helsinki"
        ]
    },
    {
        "name": "former yugoslav republic of macedonia",
        "selector": "flag-icon flag-icon-mk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "skopje"
        ]
    },
    {
        "name": "france",
        "selector": "flag-icon flag-icon-fr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "paris"
        ]
    },
    {
        "name": "french guiana",
        "selector": "flag-icon flag-icon-gf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "cayenne"
        ]
    },
    {
        "name": "french polynesia",
        "selector": "flag-icon flag-icon-pf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "papeete"
        ]
    },
    {
        "name": "french southern territories",
        "selector": "flag-icon flag-icon-tf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "saint-pierre, réunion"
        ]
    },
    {
        "name": "gabon",
        "selector": "flag-icon flag-icon-ga",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "libreville"
        ]
    },
    {
        "name": "gambia",
        "selector": "flag-icon flag-icon-gm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "banjul"
        ]
    },
    {
        "name": "georgia",
        "selector": "flag-icon flag-icon-ge",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "tbilisi"
        ]
    },
    {
        "name": "germany",
        "selector": "flag-icon flag-icon-de",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "berlin"
        ]
    },
    {
        "name": "ghana",
        "selector": "flag-icon flag-icon-gh",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "accra"
        ]
    },
    {
        "name": "gibraltar",
        "selector": "flag-icon flag-icon-gi",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "gibraltar"
        ]
    },
    {
        "name": "greece",
        "selector": "flag-icon flag-icon-gr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "athens"
        ]
    },
    {
        "name": "greenland",
        "selector": "flag-icon flag-icon-gl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "nuuk"
        ]
    },
    {
        "name": "grenada",
        "selector": "flag-icon flag-icon-gd",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "st. george's"
        ]
    },
    {
        "name": "guadeloupe",
        "selector": "flag-icon flag-icon-gp",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "basse-terre"
        ]
    },
    {
        "name": "guam",
        "selector": "flag-icon flag-icon-gu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "hagåtña"
        ]
    },
    {
        "name": "guatemala",
        "selector": "flag-icon flag-icon-gt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "guatemala city"
        ]
    },
    {
        "name": "guernsey",
        "selector": "flag-icon flag-icon-gg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "saint peter port"
        ]
    },
    {
        "name": "guinea",
        "selector": "flag-icon flag-icon-gn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "conakry"
        ]
    },
    {
        "name": "guinea-bissau",
        "selector": "flag-icon flag-icon-gw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "bissau"
        ]
    },
    {
        "name": "guyana",
        "selector": "flag-icon flag-icon-gy",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "georgetown"
        ]
    },
    {
        "name": "haiti",
        "selector": "flag-icon flag-icon-ht",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "port-au-prince"
        ]
    },
    {
        "name": "holy see",
        "selector": "flag-icon flag-icon-va",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "vatican city"
        ]
    },
    {
        "name": "honduras",
        "selector": "flag-icon flag-icon-hn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "tegucigalpa"
        ]
    },
    {
        "name": "hong kong",
        "selector": "flag-icon flag-icon-hk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "hong kong"
        ]
    },
    {
        "name": "hungary",
        "selector": "flag-icon flag-icon-hu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "budapest"
        ]
    },
    {
        "name": "iceland",
        "selector": "flag-icon flag-icon-is",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "reykjavik"
        ]
    },
    {
        "name": "india",
        "selector": "flag-icon flag-icon-in",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "new delhi"
        ]
    },
    {
        "name": "indonesia",
        "selector": "flag-icon flag-icon-id",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "jakarta"
        ]
    },
    {
        "name": "iran (islamic republic of)",
        "selector": "flag-icon flag-icon-ir",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "tehran"
        ]
    },
    {
        "name": "iraq",
        "selector": "flag-icon flag-icon-iq",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "baghdad"
        ]
    },
    {
        "name": "ireland",
        "selector": "flag-icon flag-icon-ie",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "dublin"
        ]
    },
    {
        "name": "isle of man",
        "selector": "flag-icon flag-icon-im",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "douglas"
        ]
    },
    {
        "name": "israel",
        "selector": "flag-icon flag-icon-il",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "jerusalem"
        ]
    },
    {
        "name": "italy",
        "selector": "flag-icon flag-icon-it",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "rome"
        ]
    },
    {
        "name": "jamaica",
        "selector": "flag-icon flag-icon-jm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "kingston"
        ]
    },
    {
        "name": "japan",
        "selector": "flag-icon flag-icon-jp",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "tokyo"
        ]
    },
    {
        "name": "jersey",
        "selector": "flag-icon flag-icon-je",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "saint helier"
        ]
    },
    {
        "name": "jordan",
        "selector": "flag-icon flag-icon-jo",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "amman"
        ]
    },
    {
        "name": "kazakhstan",
        "selector": "flag-icon flag-icon-kz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "astana"
        ]
    },
    {
        "name": "kenya",
        "selector": "flag-icon flag-icon-ke",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "nairobi"
        ]
    },
    {
        "name": "kiribati",
        "selector": "flag-icon flag-icon-ki",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "south tarawa"
        ]
    },
    {
        "name": "kosovo",
        "selector": "flag-icon flag-icon-xk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "pristina"
        ]
    },
    {
        "name": "kuwait",
        "selector": "flag-icon flag-icon-kw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "kuwait city"
        ]
    },
    {
        "name": "kyrgyzstan",
        "selector": "flag-icon flag-icon-kg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "bishkek"
        ]
    },
    {
        "name": "laos",
        "selector": "flag-icon flag-icon-la",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "vientiane"
        ]
    },
    {
        "name": "latvia",
        "selector": "flag-icon flag-icon-lv",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "riga"
        ]
    },
    {
        "name": "lebanon",
        "selector": "flag-icon flag-icon-lb",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "beirut"
        ]
    },
    {
        "name": "lesotho",
        "selector": "flag-icon flag-icon-ls",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "maseru"
        ]
    },
    {
        "name": "liberia",
        "selector": "flag-icon flag-icon-lr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "monrovia"
        ]
    },
    {
        "name": "libya",
        "selector": "flag-icon flag-icon-ly",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "tripoli"
        ]
    },
    {
        "name": "liechtenstein",
        "selector": "flag-icon flag-icon-li",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "vaduz"
        ]
    },
    {
        "name": "lithuania",
        "selector": "flag-icon flag-icon-lt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "vilnius"
        ]
    },
    {
        "name": "luxembourg",
        "selector": "flag-icon flag-icon-lu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "luxembourg city"
        ]
    },
    {
        "name": "macau",
        "selector": "flag-icon flag-icon-mo",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "macau"
        ]
    },
    {
        "name": "madagascar",
        "selector": "flag-icon flag-icon-mg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "antananarivo"
        ]
    },
    {
        "name": "malawi",
        "selector": "flag-icon flag-icon-mw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "lilongwe"
        ]
    },
    {
        "name": "malaysia",
        "selector": "flag-icon flag-icon-my",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "kuala lumpur"
        ]
    },
    {
        "name": "maldives",
        "selector": "flag-icon flag-icon-mv",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "malé"
        ]
    },
    {
        "name": "mali",
        "selector": "flag-icon flag-icon-ml",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "bamako"
        ]
    },
    {
        "name": "malta",
        "selector": "flag-icon flag-icon-mt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "valletta"
        ]
    },
    {
        "name": "marshall islands",
        "selector": "flag-icon flag-icon-mh",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "majuro"
        ]
    },
    {
        "name": "martinique",
        "selector": "flag-icon flag-icon-mq",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "fort-de-france"
        ]
    },
    {
        "name": "mauritania",
        "selector": "flag-icon flag-icon-mr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "nouakchott"
        ]
    },
    {
        "name": "mauritius",
        "selector": "flag-icon flag-icon-mu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "port louis"
        ]
    },
    {
        "name": "mayotte",
        "selector": "flag-icon flag-icon-yt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "mamoudzou"
        ]
    },
    {
        "name": "mexico",
        "selector": "flag-icon flag-icon-mx",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "mexico city"
        ]
    },
    {
        "name": "moldova",
        "selector": "flag-icon flag-icon-md",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "chișinău"
        ]
    },
    {
        "name": "monaco",
        "selector": "flag-icon flag-icon-mc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "monaco"
        ]
    },
    {
        "name": "mongolia",
        "selector": "flag-icon flag-icon-mn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "ulaanbaatar"
        ]
    },
    {
        "name": "montenegro",
        "selector": "flag-icon flag-icon-me",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "podgorica"
        ]
    },
    {
        "name": "montserrat",
        "selector": "flag-icon flag-icon-ms",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "little bay, brades, plymouth"
        ]
    },
    {
        "name": "morocco",
        "selector": "flag-icon flag-icon-ma",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "rabat"
        ]
    },
    {
        "name": "mozambique",
        "selector": "flag-icon flag-icon-mz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "maputo"
        ]
    },
    {
        "name": "myanmar",
        "selector": "flag-icon flag-icon-mm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "naypyidaw"
        ]
    },
    {
        "name": "namibia",
        "selector": "flag-icon flag-icon-na",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "windhoek"
        ]
    },
    {
        "name": "nauru",
        "selector": "flag-icon flag-icon-nr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "yaren district"
        ]
    },
    {
        "name": "nepal",
        "selector": "flag-icon flag-icon-np",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "kathmandu"
        ]
    },
    {
        "name": "netherlands",
        "selector": "flag-icon flag-icon-nl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "amsterdam"
        ]
    },
    {
        "name": "new caledonia",
        "selector": "flag-icon flag-icon-nc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "nouméa"
        ]
    },
    {
        "name": "new zealand",
        "selector": "flag-icon flag-icon-nz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "wellington"
        ]
    },
    {
        "name": "nicaragua",
        "selector": "flag-icon flag-icon-ni",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "managua"
        ]
    },
    {
        "name": "niger",
        "selector": "flag-icon flag-icon-ne",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "niamey"
        ]
    },
    {
        "name": "nigeria",
        "selector": "flag-icon flag-icon-ng",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "abuja"
        ]
    },
    {
        "name": "niue",
        "selector": "flag-icon flag-icon-nu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "alofi"
        ]
    },
    {
        "name": "norfolk island",
        "selector": "flag-icon flag-icon-nf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "kingston"
        ]
    },
    {
        "name": "north korea",
        "selector": "flag-icon flag-icon-kp",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "pyongyang"
        ]
    },
    {
        "name": "northern mariana islands",
        "selector": "flag-icon flag-icon-mp",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "capitol hill"
        ]
    },
    {
        "name": "norway",
        "selector": "flag-icon flag-icon-no",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "oslo"
        ]
    },
    {
        "name": "oman",
        "selector": "flag-icon flag-icon-om",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "muscat"
        ]
    },
    {
        "name": "pakistan",
        "selector": "flag-icon flag-icon-pk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "islamabad"
        ]
    },
    {
        "name": "palau",
        "selector": "flag-icon flag-icon-pw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "ngerulmud"
        ]
    },
    {
        "name": "panama",
        "selector": "flag-icon flag-icon-pa",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "panama city"
        ]
    },
    {
        "name": "papua new guinea",
        "selector": "flag-icon flag-icon-pg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "port moresby"
        ]
    },
    {
        "name": "paraguay",
        "selector": "flag-icon flag-icon-py",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "asunción"
        ]
    },
    {
        "name": "peru",
        "selector": "flag-icon flag-icon-pe",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "lima"
        ]
    },
    {
        "name": "philippines",
        "selector": "flag-icon flag-icon-ph",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "manila"
        ]
    },
    {
        "name": "pitcairn",
        "selector": "flag-icon flag-icon-pn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "adamstown"
        ]
    },
    {
        "name": "poland",
        "selector": "flag-icon flag-icon-pl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "warsaw"
        ]
    },
    {
        "name": "portugal",
        "selector": "flag-icon flag-icon-pt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "lisbon"
        ]
    },
    {
        "name": "puerto rico",
        "selector": "flag-icon flag-icon-pr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "san juan"
        ]
    },
    {
        "name": "qatar",
        "selector": "flag-icon flag-icon-qa",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "doha"
        ]
    },
    {
        "name": "republic of the congo",
        "selector": "flag-icon flag-icon-cg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "brazzaville"
        ]
    },
    {
        "name": "romania",
        "selector": "flag-icon flag-icon-ro",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "bucharest"
        ]
    },
    {
        "name": "russia",
        "selector": "flag-icon flag-icon-ru",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "moscow"
        ]
    },
    {
        "name": "rwanda",
        "selector": "flag-icon flag-icon-rw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "kigali"
        ]
    },
    {
        "name": "réunion",
        "selector": "flag-icon flag-icon-re",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "saint-denis"
        ]
    },
    {
        "name": "saint barthélemy",
        "selector": "flag-icon flag-icon-bl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "gustavia"
        ]
    },
    {
        "name": "saint helena, ascension and tristan da cunha",
        "selector": "flag-icon flag-icon-sh",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "jamestown"
        ]
    },
    {
        "name": "saint kitts and nevis",
        "selector": "flag-icon flag-icon-kn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "basseterre"
        ]
    },
    {
        "name": "saint lucia",
        "selector": "flag-icon flag-icon-lc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "castries"
        ]
    },
    {
        "name": "saint martin",
        "selector": "flag-icon flag-icon-mf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "marigot"
        ]
    },
    {
        "name": "saint pierre and miquelon",
        "selector": "flag-icon flag-icon-pm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "saint-pierre"
        ]
    },
    {
        "name": "saint vincent and the grenadines",
        "selector": "flag-icon flag-icon-vc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "kingstown"
        ]
    },
    {
        "name": "samoa",
        "selector": "flag-icon flag-icon-ws",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "apia"
        ]
    },
    {
        "name": "san marino",
        "selector": "flag-icon flag-icon-sm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "san marino"
        ]
    },
    {
        "name": "sao tome and principe",
        "selector": "flag-icon flag-icon-st",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "são tomé"
        ]
    },
    {
        "name": "saudi arabia",
        "selector": "flag-icon flag-icon-sa",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "riyadh"
        ]
    },
    {
        "name": "senegal",
        "selector": "flag-icon flag-icon-sn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "dakar"
        ]
    },
    {
        "name": "serbia",
        "selector": "flag-icon flag-icon-rs",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "belgrade"
        ]
    },
    {
        "name": "seychelles",
        "selector": "flag-icon flag-icon-sc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "victoria"
        ]
    },
    {
        "name": "sierra leone",
        "selector": "flag-icon flag-icon-sl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "freetown"
        ]
    },
    {
        "name": "singapore",
        "selector": "flag-icon flag-icon-sg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "singapore"
        ]
    },
    {
        "name": "sint maarten",
        "selector": "flag-icon flag-icon-sx",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "philipsburg"
        ]
    },
    {
        "name": "slovakia",
        "selector": "flag-icon flag-icon-sk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "bratislava"
        ]
    },
    {
        "name": "slovenia",
        "selector": "flag-icon flag-icon-si",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "ljubljana"
        ]
    },
    {
        "name": "solomon islands",
        "selector": "flag-icon flag-icon-sb",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "honiara"
        ]
    },
    {
        "name": "somalia",
        "selector": "flag-icon flag-icon-so",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "mogadishu"
        ]
    },
    {
        "name": "south africa",
        "selector": "flag-icon flag-icon-za",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "pretoria"
        ]
    },
    {
        "name": "south georgia and the south sandwich islands",
        "selector": "flag-icon flag-icon-gs",
        "categories": [
            "Flags"
        ],
        "tags": [
            "antarctica",
            "king edward point"
        ]
    },
    {
        "name": "south korea",
        "selector": "flag-icon flag-icon-kr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "seoul"
        ]
    },
    {
        "name": "south sudan",
        "selector": "flag-icon flag-icon-ss",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "juba"
        ]
    },
    {
        "name": "spain",
        "selector": "flag-icon flag-icon-es",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "madrid"
        ]
    },
    {
        "name": "sri lanka",
        "selector": "flag-icon flag-icon-lk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "sri jayawardenepura kotte, colombo"
        ]
    },
    {
        "name": "state of palestine",
        "selector": "flag-icon flag-icon-ps",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "ramallah"
        ]
    },
    {
        "name": "sudan",
        "selector": "flag-icon flag-icon-sd",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "khartoum"
        ]
    },
    {
        "name": "suriname",
        "selector": "flag-icon flag-icon-sr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "paramaribo"
        ]
    },
    {
        "name": "svalbard and jan mayen",
        "selector": "flag-icon flag-icon-sj",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "longyearbyen"
        ]
    },
    {
        "name": "swaziland",
        "selector": "flag-icon flag-icon-sz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "lobamba, mbabane"
        ]
    },
    {
        "name": "sweden",
        "selector": "flag-icon flag-icon-se",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "stockholm"
        ]
    },
    {
        "name": "switzerland",
        "selector": "flag-icon flag-icon-ch",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "bern"
        ]
    },
    {
        "name": "syrian arab republic",
        "selector": "flag-icon flag-icon-sy",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "damascus"
        ]
    },
    {
        "name": "taiwan",
        "selector": "flag-icon flag-icon-tw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "taipei"
        ]
    },
    {
        "name": "tajikistan",
        "selector": "flag-icon flag-icon-tj",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "dushanbe"
        ]
    },
    {
        "name": "tanzania",
        "selector": "flag-icon flag-icon-tz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "dodoma"
        ]
    },
    {
        "name": "thailand",
        "selector": "flag-icon flag-icon-th",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "bangkok"
        ]
    },
    {
        "name": "timor-leste",
        "selector": "flag-icon flag-icon-tl",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "dili"
        ]
    },
    {
        "name": "togo",
        "selector": "flag-icon flag-icon-tg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "lomé"
        ]
    },
    {
        "name": "tokelau",
        "selector": "flag-icon flag-icon-tk",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "nukunonu, atafu,tokelau"
        ]
    },
    {
        "name": "tonga",
        "selector": "flag-icon flag-icon-to",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "nukuʻalofa"
        ]
    },
    {
        "name": "trinidad and tobago",
        "selector": "flag-icon flag-icon-tt",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "port of spain"
        ]
    },
    {
        "name": "tunisia",
        "selector": "flag-icon flag-icon-tn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "tunis"
        ]
    },
    {
        "name": "turkey",
        "selector": "flag-icon flag-icon-tr",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "ankara"
        ]
    },
    {
        "name": "turkmenistan",
        "selector": "flag-icon flag-icon-tm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "ashgabat"
        ]
    },
    {
        "name": "turks and caicos islands",
        "selector": "flag-icon flag-icon-tc",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "cockburn town"
        ]
    },
    {
        "name": "tuvalu",
        "selector": "flag-icon flag-icon-tv",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "funafuti"
        ]
    },
    {
        "name": "uganda",
        "selector": "flag-icon flag-icon-ug",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "kampala"
        ]
    },
    {
        "name": "ukraine",
        "selector": "flag-icon flag-icon-ua",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "kiev"
        ]
    },
    {
        "name": "united arab emirates",
        "selector": "flag-icon flag-icon-ae",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "abu dhabi"
        ]
    },
    {
        "name": "united kingdom",
        "selector": "flag-icon flag-icon-gb",
        "categories": [
            "Flags"
        ],
        "tags": [
            "europe",
            "london"
        ]
    },
    {
        "name": "united states minor outlying islands",
        "selector": "flag-icon flag-icon-um",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "washington, d.c."
        ]
    },
    {
        "name": "united states of america",
        "selector": "flag-icon flag-icon-us",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "washington, d.c."
        ]
    },
    {
        "name": "uruguay",
        "selector": "flag-icon flag-icon-uy",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "montevideo"
        ]
    },
    {
        "name": "uzbekistan",
        "selector": "flag-icon flag-icon-uz",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "tashkent"
        ]
    },
    {
        "name": "vanuatu",
        "selector": "flag-icon flag-icon-vu",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "port vila"
        ]
    },
    {
        "name": "venezuela (bolivarian republic of)",
        "selector": "flag-icon flag-icon-ve",
        "categories": [
            "Flags"
        ],
        "tags": [
            "south america",
            "caracas"
        ]
    },
    {
        "name": "vietnam",
        "selector": "flag-icon flag-icon-vn",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "hanoi"
        ]
    },
    {
        "name": "virgin islands (british)",
        "selector": "flag-icon flag-icon-vg",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "road town"
        ]
    },
    {
        "name": "virgin islands (u.s.)",
        "selector": "flag-icon flag-icon-vi",
        "categories": [
            "Flags"
        ],
        "tags": [
            "north america",
            "charlotte amalie"
        ]
    },
    {
        "name": "wallis and futuna",
        "selector": "flag-icon flag-icon-wf",
        "categories": [
            "Flags"
        ],
        "tags": [
            "oceania",
            "mata-utu"
        ]
    },
    {
        "name": "western sahara",
        "selector": "flag-icon flag-icon-eh",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "laayoune"
        ]
    },
    {
        "name": "yemen",
        "selector": "flag-icon flag-icon-ye",
        "categories": [
            "Flags"
        ],
        "tags": [
            "asia",
            "sana'a"
        ]
    },
    {
        "name": "zambia",
        "selector": "flag-icon flag-icon-zm",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "lusaka"
        ]
    }, {
        "name": "zimbabwe",
        "selector": "flag-icon flag-icon-zw",
        "categories": [
            "Flags"
        ],
        "tags": [
            "africa",
            "harare"
        ]
    }
]