import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EventDataService } from "@app/event/services/EventDataService";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { EventRiskControlModel } from "@app/event/shared/event-risk-control-model";

@Component({
    selector: "event-risk-control-dialog",
    templateUrl: "./event-risk-control-dialog.component.html",
    styleUrls: ["./event-risk-control-dialog.component.scss"],
})
export class EventRiskControlDialogComponent extends BaseModal implements OnInit {
    eventGuid: string;
    riskControlModel: EventRiskControlModel;
    failureTypes: IdNameCombination[];
    title: string;

    items: EventRiskControlModel[];
    isBatchEdit = false;
    loading = true;

    commentForm: FormGroup;

    constructor(_bsModalRef: BsModalRef, private _eventDS: EventDataService) {
        super(_bsModalRef);
    }

    ngOnInit(): void {
        if (!this.riskControlModel) {
            this.riskControlModel = new EventRiskControlModel();
        }
        const comment = this.riskControlModel ? this.riskControlModel : new EventRiskControlModel();

        this.title = "Editing " + comment.name ? comment.name : "batch";

        this.commentForm = new FormGroup({
            failureTypeId: new FormControl(comment.failureTypeId),
            comment: new FormControl(comment.comment),
        });
        if (this.isBatchEdit) {
            this.commentForm.addControl(
                "batchControls",
                new FormControl(comment.batchControls, [Validators.required])
            );
        }
        if (comment.failureTypeId) {
            this.riskControlModel.failureType = this.failureTypes.find(
                (x) => x.ID === comment.failureTypeId
            ).Name;
        }
        this.loading = false;
    }

    saveClick() {
        FormValidationHelper.mapToModel(this.commentForm, this.riskControlModel);
        this._bsModalRef.hide();

        super.onConfirm(this.riskControlModel);
    }

    closeClick() {
        super.onCancel();
    }

    failureTypeChanged(item: IdNameCombination) {
        if (item) {
            this.riskControlModel.failureType = item.Name;
        } else {
            this.riskControlModel.failureType = "";
        }
    }
}
