import { ProcessEditorComponent } from "./process-editor.component";
import { ActionShortcut } from "@app/shared/contextmenu/menu-item";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";
import { ProcessEditorPrintType } from "./enums/ProcessEditorPrintType";

export enum ActionEnum {
    SELECT_ALL,
    UNSELECT_ALL,
    UNDO,
    REDO,
    SELECT_VERTICES,
    SELECT_EDGES,
    EDIT,
    TOGGLE_LOCK,
    TOGGLE_GRID,
    RESET_VIEW,
    GROUP_CELLS,
    UNGROUP_CELLS,
    TO_FRONT,
    TO_BACK,
    REMOVE_CELLS,
    COPY,
    PASTE,
    CUT,
    PRINT,
    IMPORT,
    SAVE,
    ZOOM_IN,
    ZOOM_OUT,
    TOGGLE_BOLD,
    TOGGLE_ITALIC,
    TOGGLE_UNDERLINE,
    TOGGLE_SEQUENCE_NR,
}
export class Actions {
    private actionMap = new Map<
        ActionEnum,
        {
            handler: () => void;
            shortcut?: ActionShortcut;
            permission?: keyof ApplicationPermissionsModel;
        }
    >();

    constructor(
        processEditor: ProcessEditorComponent,
        private _permService: PermissionsService,
        private _toastr: ToastrService
    ) {
        const graph = processEditor.graph;
        const mxConstants = graph.dependencies.mxConstants;

        // Functions in handler needs to be wrapped otherwise "this" gets overridden
        const entries: Array<{
            actionEnum: ActionEnum;
            handler: () => void;
            shortcut?: ActionShortcut;
            permission?: keyof ApplicationPermissionsModel;
        }> = [
            {
                actionEnum: ActionEnum.SELECT_ALL,
                handler: () => graph.selectAll(),
                shortcut: ActionShortcut.Create("A", true),
            },
            {
                actionEnum: ActionEnum.UNSELECT_ALL,
                handler: () => graph.selectionModel.clear(),
                shortcut: ActionShortcut.Create("A", true, true),
            },
            {
                actionEnum: ActionEnum.UNDO,
                handler: () => graph.undo(),
                shortcut: ActionShortcut.Create("Z", true),
            },
            {
                actionEnum: ActionEnum.REDO,
                handler: () => graph.redo(),
                shortcut: ActionShortcut.Create("Y", true),
            },
            {
                actionEnum: ActionEnum.SELECT_VERTICES,
                handler: () => graph.selectVertices(null),
                shortcut: ActionShortcut.Create("I", true, true),
            },
            {
                actionEnum: ActionEnum.SELECT_EDGES,
                handler: () => graph.selectEdges(null),
                shortcut: ActionShortcut.Create("E", true, true),
            },
            {
                actionEnum: ActionEnum.EDIT,
                handler: () => graph.startEditing(),
                shortcut: ActionShortcut.Create("F2"),
            },
            {
                actionEnum: ActionEnum.TOGGLE_LOCK,
                handler: () => graph.toggleLockCells(),
                shortcut: ActionShortcut.Create("L", true),
            },
            {
                actionEnum: ActionEnum.TOGGLE_GRID,
                handler: () => graph.toggleGrid(),
                shortcut: ActionShortcut.Create("G", true),
            },
            {
                actionEnum: ActionEnum.RESET_VIEW,
                handler: () => {
                    graph.zoomTo(0.5, null);
                    graph.view.setTranslate(1, 1);
                    graph.center(true, false, 0.25);
                },
                shortcut: ActionShortcut.Create("H", true),
            },
            {
                actionEnum: ActionEnum.GROUP_CELLS,
                handler: () => graph.groupCells(null),
                shortcut: ActionShortcut.Create("U", true),
            },
            {
                actionEnum: ActionEnum.UNGROUP_CELLS,
                handler: () => graph.ungroupCells(null),
                shortcut: ActionShortcut.Create("U", true, true),
            },
            {
                actionEnum: ActionEnum.TO_FRONT,
                handler: () => graph.orderCells(false, null),
                shortcut: ActionShortcut.Create("F", true, true),
            },
            {
                actionEnum: ActionEnum.TO_BACK,
                handler: () => graph.orderCells(true, null),
                shortcut: ActionShortcut.Create("B", true, true),
            },
            {
                actionEnum: ActionEnum.REMOVE_CELLS,
                handler: () => graph.removeCells(),
                shortcut: ActionShortcut.Create("Delete"),
            },
            {
                actionEnum: ActionEnum.COPY,
                handler: () => graph.copy(),
                shortcut: ActionShortcut.Create("C", true),
            },
            {
                actionEnum: ActionEnum.PASTE,
                handler: () => graph.paste(),
                shortcut: ActionShortcut.Create("V", true),
            },
            {
                actionEnum: ActionEnum.CUT,
                handler: () => graph.cut(),
                shortcut: ActionShortcut.Create("X", true),
            },
            {
                actionEnum: ActionEnum.PRINT,
                handler: () => processEditor.print(true, ProcessEditorPrintType.GraphAndData),
                shortcut: ActionShortcut.Create("P", true),
            },
            {
                actionEnum: ActionEnum.IMPORT,
                handler: () => processEditor.importLocalFile(),
                shortcut: ActionShortcut.Create("I", true),
                permission: "HasProcessEditorVisioImportAccess",
            },
            {
                actionEnum: ActionEnum.SAVE,
                handler: () => processEditor.saveDiagram(),
                shortcut: ActionShortcut.Create("S", true),
            },
            {
                actionEnum: ActionEnum.ZOOM_IN,
                handler: () => graph.zoomIn(),
                shortcut: ActionShortcut.Create("Mousewheel", true),
            },
            {
                actionEnum: ActionEnum.ZOOM_OUT,
                handler: () => graph.zoomOut(),
                shortcut: ActionShortcut.Create("Mousewheel", true),
            },
            {
                actionEnum: ActionEnum.TOGGLE_BOLD,
                handler: () => {
                    graph.toggleCellStyleFlags(mxConstants.STYLE_FONTSTYLE, mxConstants.FONT_BOLD);
                },
            },
            {
                actionEnum: ActionEnum.TOGGLE_ITALIC,
                handler: () => {
                    graph.toggleCellStyleFlags(
                        mxConstants.STYLE_FONTSTYLE,
                        mxConstants.FONT_ITALIC
                    );
                },
            },
            {
                actionEnum: ActionEnum.TOGGLE_UNDERLINE,
                handler: () => {
                    graph.toggleCellStyleFlags(
                        mxConstants.STYLE_FONTSTYLE,
                        mxConstants.FONT_UNDERLINE
                    );
                },
            },
            {
                actionEnum: ActionEnum.TOGGLE_SEQUENCE_NR,
                handler: () => {
                    graph.showOrderNr = !graph.showOrderNr;
                    graph.refresh();
                },
            },
        ];

        entries.forEach((x) =>
            this.actionMap.set(x.actionEnum, {
                handler: x.handler,
                shortcut: x.shortcut,
                permission: x.permission,
            })
        );
    }

    public get(actionEnum: ActionEnum): () => void {
        if (this.hasPermission(actionEnum)) {
            return this.actionMap.get(actionEnum).handler;
        } else {
            return () => {
                this._toastr.error("Action can't be performed", "Insufficient rights");
            };
        }
    }

    public getShortcut(actionEnum: ActionEnum): ActionShortcut | null {
        return this.actionMap.get(actionEnum).shortcut;
    }

    public hasPermission(actionEnum: ActionEnum): boolean {
        const permissionToCheck = this.actionMap.get(actionEnum).permission;

        if (permissionToCheck == null) {
            return true;
        }

        return this._permService.permissions[permissionToCheck] as boolean;
    }

    public execute(actionEnum: ActionEnum): void {
        if (this.hasPermission(actionEnum)) {
            this.actionMap.get(actionEnum).handler();
        } else {
            this._toastr.error("Action can't be performed", "Insufficient rights");
        }
    }
}
