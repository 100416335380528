import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeValue } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string, type?: string): SafeValue {
        if (!type) {
            type = 'resourceUrl';
        }

        switch (type) {
            case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}