import { DevPowerBIService } from "./services/devpowerbi.service";
import { DevPowerBIComponent } from "./pages/devmanagement/components/dev-powerbi/dev-powerbi.component";
import { NgModule, ModuleWithProviders } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import { DevManagementDataService } from "./services/devmanagement.service";

import { DevManagementComponent } from "./pages/devmanagement/devmanagement.component";
import { DevImportComponent } from "./pages/devmanagement/components/dev-import/dev-import.component";
import { DevExportComponent } from "./pages/devmanagement/components/dev-export/dev-export.component";
import { DevCodeExecutionComponent } from "./pages/devmanagement/components/dev-code-executions/dev-code-executions.component";
import { DevCerrixroleComponent } from "./pages/devmanagement/components/dev-cerrixrole/dev-cerrixrole.component";
import { DevRoleRightPatchesComponent } from "./pages/devmanagement/components/dev-cerrixrole/dev-role-right-patches/dev-role-right-patches.component";
import { DevActionsComponent } from "./pages/devmanagement/components/dev-actions/dev-actions.component";
import { DevKeysComponent } from "./pages/devmanagement/components/dev-keys/dev-keys.component";

@NgModule({
    declarations: [
        DevManagementComponent,
        DevImportComponent,
        DevExportComponent,
        DevCodeExecutionComponent,
        DevCerrixroleComponent,
        DevRoleRightPatchesComponent,
        DevActionsComponent,
        DevPowerBIComponent,
        DevKeysComponent,
    ],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class AdminModule {
    static forRoot(): ModuleWithProviders<AdminModule> {
        return {
            ngModule: AdminModule,
            providers: [DevManagementDataService, DevPowerBIService],
        };
    }
}
