import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ThirdPartyContractModel } from "@models/thirdparty/ThirdPartyContractModel";
import { ThirdPartyDataService } from "../services/ThirdPartyDataService";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ThirdPartyPermissions } from "@models/thirdparty/ThirdPartyPermissions";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { TargetModule } from "@enums/document/TargetModule";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { ThirdPartyTermsOfNoticeModel } from "@models/thirdparty/ThirdPartyTermsOfNoticeModel";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { Observable } from "rxjs";
import { ThirdPartyContractDataService } from "../services/ThirdPartyContractDataService";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { toPromise } from "@methods/CommonMethods";

@Component({
    selector: "thirdparty-contracts",
    templateUrl: "./thirdparty-contracts.component.html",
    styleUrls: ["./thirdparty-contracts.component.scss"],
})
// TODO Make ReviewFrequencyModel more generic
// TODO Pass users, services and frequencies from details to this screen instead of making calls again
export class ThirdPartyContractsComponent implements OnInit {
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent;

    parentForm: FormGroup;
    tab: TabModel;
    header = "Contracts";
    services: CerrixTreeItem[];
    termOfNotice: ThirdPartyTermsOfNoticeModel[];
    contractOwnerRequest: Observable<IdNameCombination[]>;
    contract: ThirdPartyContractModel;
    thirdPartyGuid: string;
    permissions: ThirdPartyPermissions;
    status: any[];

    sd: any;

    isLoading = true;
    documentTypeTargets = TargetModule.ThirdParty;

    constructor(
        private _thirdPartyDS: ThirdPartyDataService,
        private _standingDataDS: StandingdataDataService,
        public _contractDS: ThirdPartyContractDataService,
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        this.sd = await toPromise(this._thirdPartyDS.getStandingData());

        if (!this.sd) {
            this._toastr.error("Something went wrong!");
            this.tab.close();
        }

        if (this.tab.config) {
            this.thirdPartyGuid = this.tab.config.thirdPartyGuid;
        }

        this.permissions = await toPromise(this._contractDS.getPermissions(this.tab.id));

        if (!this.tab.id) {
            var contract = new ThirdPartyContractModel();
            contract.ThirdPartyGuid = this.thirdPartyGuid;
            this.initContract(contract);
        } else {
            this._contractDS
                .getContractData(this.tab.id)
                .subscribe((contract: ThirdPartyContractModel) => {
                    this.initContract(contract);
                });
        }
    }

    async save() {
        if (this.permissions.canEdit && !this.parentForm.valid) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
            return;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.contract);

        const savingPrompt = this._promptService.loader("Saving changes, please wait...");
        const call = toPromise(
            this._contractDS.storeContract(this.contract).pipe(finalize(() => savingPrompt.close()))
        ).then((v: string) => (this.tab.id = v));

        await call;

        this._toastr.success("Update completed");
        this.tab.refresh();
    }

    getServices(items: CerrixTreeItem[]) {
        this.services = items;
    }

    getTermOfNotice(items: ThirdPartyTermsOfNoticeModel[]) {
        this.termOfNotice = items;
    }

    getStatus(items: IdNameCombination[]) {
        this.status = items;
    }

    delete() {
        this._contractDS.deleteContract(this.contract.guid).subscribe(
            (validations) => {
                if (validations && validations.length > 0) {
                    this._toastr.warning(validations.toString());
                } else {
                    this._toastr.success("Contract deleted");
                    this._tabService.refresh(this._pages.ThirdpartyContractDetail);
                    this.tab.close(false);
                }
            },
            (error) => {
                this._toastr.error("", error || error.Message);
            }
        );
    }

    initContract(contract: ThirdPartyContractModel) {
        this.contract = contract;

        this.isLoading = false;
        this.tab.showLoader = false;

        this.contractOwnerRequest = this._thirdPartyDS.getUsers(contract.ContractOwner);

        this._thirdPartyDS.getServices().subscribe((value) => this.getServices(value));
        this._standingDataDS
            .getAllByType(StandingDataType.TpContractStatus)
            .subscribe((value) => this.getStatus(value));
        this._thirdPartyDS.getTermOfNotice().subscribe((value) => {
            this.getTermOfNotice(value);
        });

        this.parentForm = new FormGroup({
            ContractNumber: new FormControl(contract.ContractNumber, [Validators.required]),
            ContractedValue: new FormControl(contract.ContractedValue, Validators.min(0)),
            Status: new FormControl(contract.Status),
            ContractOwner: new FormControl(contract.ContractOwner),
            TermsOfNotice: new FormControl(contract.TermsOfNotice, [Validators.required]),
            SignatureDate: new FormControl(contract.SignatureDate),
            StartDate: new FormControl(contract.StartDate),
            EndDate: new FormControl(contract.EndDate),
            AnnualValue: new FormControl(contract.AnnualValue, Validators.min(0)),
            Framework: new FormControl(contract.Framework),
            Services: new FormControl(contract.Services),
            currencyCode: new FormControl(contract.currencyCode),
        });

        if (!this.permissions.canEdit) {
            this.parentForm.disable();
        }
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "history": {
                    this.historyOverviewComponent.loadHistory();
                    break;
                }
            }
        }
    }
}
