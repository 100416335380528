<div class="color-picker-shell" (click)="picker.click()">
    <div class="color-select">
        <input
            class="form-control form-control-sm color-input"
            [(ngModel)]="value"
            [disabled]="disabled"
            [attr.data-uiid]="dataUiid"
            type="color"
            #picker
        />
        <input
            class="form-control form-control-sm color-select"
            [(ngModel)]="value"
            readonly
            type="text"
            *ngIf="!pickeronly"
            [required]="required"
            [placeholder]="required ? 'Required' : ''"
            inputTooltip
        />
    </div>
</div>
