import { Component, Input } from '@angular/core';
import { TabService } from '@services/tabs/TabService';
import { Pages } from '@constants/pages/Pages';

@Component({
  selector: 'moi-thirdparty-summary',
  templateUrl: './moi-thirdparty-summary.component.html',
  styleUrls: ['./moi-thirdparty-summary.component.scss']
})

export class MoiThirdPartySummaryComponent {
  @Input() summaryModel: any;
  @Input() thirdpartyGUID: any;

  constructor(private tabService: TabService, private pages: Pages) {
  }

  openThirdParty() {
    if (!this.thirdpartyGUID || !this.summaryModel.CanOpenParent) {
      return;
    }

    this.tabService.generateTab(this.pages.ThirdpartyDetail, this.thirdpartyGUID);
  }
}
