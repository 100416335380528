import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { firstValueFrom } from "rxjs";
import { WopiConfiguration } from "../models/wopi-configuration";

@Injectable({
    providedIn: "root",
})
export class WopiConfigurationService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "wopiconfiguration");
    }

    public async getWopiInfo(documentGuid: string, action: string): Promise<WopiConfiguration> {
        return await firstValueFrom(this.get(`?documentGuid=${documentGuid}&action=${action}`));
    }
}
