<div class="d-flex flex-column" *ngIf="conclusions">
    <div class="header">Conclusion</div>
    <div class="content d-flex flex-row">
        <eff-final-conclusion-box
            class="tester-conclusion"
            *ngIf="testerFinalConclusion"
            [finalConclusion]="testerFinalConclusion"
            [isEditMode]="false"
        ></eff-final-conclusion-box>

        <div class="reviewer-conclusion">
            <eff-final-conclusion-box
                *ngIf="reviewerFinalConclusion && effectivenessModel.reviewEnabled"
                [finalConclusion]="reviewerFinalConclusion"
                [isEditMode]="false"
            ></eff-final-conclusion-box>
            <div></div>
        </div>
    </div>
</div>
