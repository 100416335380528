import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { MoiWidgetModel } from "@app/moi/shared/moiWidgetModel";
import { MoiStatusType } from "@enums/moi/MoiStatusType";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { DocumentMethods } from "@methods/DocumentMethods";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { SelectOptionModel } from "@models/generic";
import { LinkModel } from "@models/generic/LinkModel";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { MoiDueDateUpdateModel } from "@models/moi/MoiDueDateUpdateModel";
import { MoiModel } from "@models/moi/MoiModel";
import { MoiWorkflowUpdateModel } from "@models/moi/MoiWorkflowUpdateModel";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable, of } from "rxjs";
import { AssessmentSummaryModel } from "../shared/assessment-summary-model";
import { MoiCommentHistoryModel } from "../shared/moi-comment-history-model";
import { MoiCommentModel } from "../shared/moi-comment-model";
import { MoiCreatorPermissions } from "../shared/moi-creator-permissions.enum";
import { MoiFeasibilityModel } from "../shared/moi-feasibility-model";
import { MoiRequestModel } from "./../../../common/models/moi/MoiRequestModel";

@Injectable()
export class MoiDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "mois");
    }

    public hasAccess() {
        const url = "/hasaccess";
        return this.get<boolean>(url);
    }

    public hasAccessToMoi(moiGuid: string): Observable<boolean> {
        const url = `/${moiGuid}/hasaccess`;

        return this.get<boolean>(url);
    }

    public getMois(periodId: number = null): Observable<any[]> {
        const url = periodId ? `?periodId=${periodId}` : null;

        return this.get<any[]>(url);
    }

    public getMoi(guid: string): Observable<MoiRequestModel> {
        const url = `/${guid}`;
        return this.get<MoiRequestModel>(url);
    }

    public getNewMoi(moiType: MoiTypes, parentID: number): Observable<MoiRequestModel> {
        const url = `/new/${moiType}/${parentID}`;
        return this.get<MoiRequestModel>(url);
    }

    public getCreatorPermissions(): Observable<MoiCreatorPermissions> {
        const url = `/permissions/create`;
        return this.get(url);
    }

    public getLinkableSources(moiType: MoiTypes) {
        const url = `/source/${moiType}`;
        return this.get<any>(url);
    }

    public getSourceIdByGuid(moiType: MoiTypes, guid: string): Observable<number> {
        const url = `/${moiType}/getsourceidbyguid/${guid}`;
        return this.get<number>(url);
    }

    public getDocuments(id: string | number): Promise<DocumentModel[]> {
        const url = `/${id}/documents`;
        return this.get<DocumentModel[]>(url).toPromise();
    }

    public getHyperlinks(id: string | number): Observable<HyperlinkModel[]> {
        const url = `/${id}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public storeMoi(moi: MoiModel): Observable<HttpEvent<Object>> {
        const formData = DocumentMethods.convertToFormDataWithFiles("moi", moi);
        const url = this.actionUrl;
        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");

        const uploadReq = new HttpRequest("POST", url, formData, {
            headers,
            reportProgress: true,
        });

        return this.http.request(uploadReq);
    }

    public getHistory(moiGuid: string): Observable<HistoryModel[]> {
        const url = `/${moiGuid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(moiGuid: string, mutationId: number) {
        const url = `/${moiGuid}/history/${mutationId}`;

        return this.get<HistoryChange[]>(url);
    }

    public getComments(moiGuid: string): Observable<MoiCommentModel[]> {
        const url = `/${moiGuid}/comments`;
        return this.get<MoiCommentModel[]>(url);
    }

    public storeComment(moiGuid: string, comment: MoiCommentModel): Observable<HttpEvent<number>> {
        const url = `/${moiGuid}/comments`;
        return this.post(comment, url);
    }

    public deleteComment(moiGuid: string, commentGuid: string): Observable<never> {
        const url = `/${moiGuid}/comments/${commentGuid}`;
        return this.delete(url);
    }

    public getCommentHistory(
        moiGuid: string,
        commentGuid: string
    ): Observable<MoiCommentHistoryModel[]> {
        const url = `/${moiGuid}/comments/${commentGuid}/history`;
        return this.get(url);
    }

    public getFeasibilities(): Observable<MoiFeasibilityModel[]> {
        const url = `/feasibilities`;
        return this.get<MoiFeasibilityModel[]>(url);
    }

    public getProgress(moiGuid: string): Observable<number> {
        const url = `/${moiGuid}/progress`;
        return this.get<number>(url);
    }

    public getAssessmentSummary(assessmentGuid: string): Observable<AssessmentSummaryModel> {
        const url = `/assessmentsummary/${assessmentGuid}`;
        return this.get<AssessmentSummaryModel>(url);
    }

    public getAssessmentsByFindingReport(
        findingReportId: number
    ): Observable<AssessmentSummaryModel[]> {
        const url = `/assessmentsbyfindingreport/${findingReportId}`;
        return this.get<AssessmentSummaryModel[]>(url);
    }

    public getExportableMoiTypes() {
        const url = "/exportablemoitypes";
        return this.get<SelectOptionModel<number>[]>(url);
    }

    public getExportableReportTypes() {
        const url = "/exportablereporttypes";
        return this.get<SelectOptionModel<number>[]>(url);
    }

    public getExportableFindingReports() {
        const url = "/exportablefindingreports";
        return this.get<SelectOptionModel<number>[]>(url);
    }

    public updateWorkflow(
        moiGuid: string,
        workflowUpdateModel: MoiWorkflowUpdateModel
    ): Observable<HttpEvent<MoiStatusType>> {
        const url = `/${moiGuid}/workflow`;
        return this.post(workflowUpdateModel, url);
    }

    public closeMoi(
        moiGuid: string,
        comment: MoiCommentModel
    ): Observable<HttpEvent<MoiStatusType>> {
        const url = `/${moiGuid}/closemoi`;

        return this.post(comment, url);
    }

    public updateDueDate(
        moiGuid: string,
        moiDueDateUpdateModel: MoiDueDateUpdateModel
    ): Observable<HttpEvent<Date>> {
        const url = `/${moiGuid}/duedate`;

        return this.post(moiDueDateUpdateModel, url);
    }

    public getLinkedMois(parentGuid: string, moiType: MoiTypes): Observable<LinkedMoiModel[]> {
        const url = `/${moiType}/linkedmois/${parentGuid}`;

        return this.get<LinkedMoiModel[]>(url);
    }

    public deleteMoi(moiGuid: string): Observable<HttpEvent<boolean>> {
        const url = `/${moiGuid}/delete`;

        return this.post(moiGuid, url);
    }

    public getLinkableRisks(moiGuid: string): Observable<RiskLinkModel[]> {
        const url = `/${isGuid(moiGuid) ? `${moiGuid}` : "null"}/risks`;
        return this.get<RiskLinkModel[]>(url);
    }

    public getLinkableFindings(moiGuid: string): Observable<LinkModel[]> {
        if (isGuid(moiGuid)) {
            const url = "/" + moiGuid + "/findings";

            return this.get<LinkModel[]>(url);
        }
        return of([]);
    }

    public getWidgetInfo(filters: any) {
        const url = `/widgetinfo`;
        return this.post<MoiWidgetModel>(filters, url);
    }
}
