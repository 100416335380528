<table
    mat-table
    [dataSource]="dataSource"
    [ngClass]="{
        'expandable-table': tableConfig?.ExpandableRow == true,
        'hide-table-header': tableConfig?.HideHeader,
        'separate-rows': tableConfig?.SeparateRows
    }"
    [multiTemplateDataRows]="tableConfig?.ExpandableRow == true"
>
    <ng-container matColumnDef="{{ column.PropertyName }}" *ngFor="let column of columns">
        <th mat-header-cell *matHeaderCellDef width="{{ column.Width }}">
            {{ column.DisplayedName }}
        </th>

        <div *ngIf="!column.IsTemplate">
            <td mat-cell *matCellDef="let element" width="{{ column.Width }}">
                {{ element[column.PropertyName] }}
            </td>
        </div>
        <div *ngIf="column.IsTemplate">
            <td mat-cell *matCellDef="let element" width="{{ column.Width }}">
                <ng-container
                    *ngTemplateOutlet="getTemplate(column.PropertyName); context: { item: element }"
                >
                </ng-container>
            </td>
        </div>
    </ng-container>

    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef width="1%">&nbsp;</th>
        <td mat-cell *matCellDef="let element" width="1%">
            <div (click)="expandCollapseRow($event, element)">
                <mat-icon *ngIf="!isRowExpanded(element)">keyboard_arrow_down</mat-icon>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox
                class="table-checkbox"
                (click)="$event.stopPropagation()"
                (change)="onSelectUnselect(row)"
                [checked]="row.IsSelected"
                [disableRipple]="true"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns().length">
            <div
                class="expanded-element-detail"
                [@detailExpand]="isRowExpanded(element) ? 'expanded' : 'collapsed'"
            >
                <ng-container *ngIf="isRowExpanded(element)">
                    <ng-container
                        *ngTemplateOutlet="expandedRowTemplate; context: { item: element }"
                    >
                    </ng-container>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="rowsSeparator">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns().length"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns()" data-uiid="eff-table-row-header"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns()"
        class="table-row"
        [class.expandable-row]="tableConfig?.ExpandableRow"
        (click)="expandCollapseRow($event, row)"
        [ngClass]="getRowClass(row)"
        [class.expanded-row]="isRowExpanded(row)"
        [class.disabled-row]="
            tableConfig &&
            tableConfig.DisableRowFunc &&
            tableConfig.DisableRowFunc(row, dataSource.data)
        "
        data-uiid="eff-table-row"
    ></tr>
    <div *ngIf="tableConfig?.ExpandableRow == true">
        <tr
            mat-row
            [ngClass]="getRowClass(row)"
            *matRowDef="let row; columns: ['expandedDetail']"
            [class.detail-row-expanded]="isRowExpanded(row)"
            class="expanded-detail-row"
            data-uiid="eff-table-row-expanded-detail"
        ></tr>
    </div>
    <div *ngIf="tableConfig?.SeparateRows">
        <tr mat-row *matRowDef="let row; columns: ['rowsSeparator']" class="rows-separator"></tr>
    </div>
</table>
