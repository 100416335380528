import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { CustomSortableOptions } from "@methods/PageMethods";
import { DisplayItemModel } from "@models/generic/DisplayItemModel";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { ToastrService } from "ngx-toastr";
import { SortableOptions } from "sortablejs";
import { LinkableIconConfig } from "../models/Linkable/LinkableIconConfig";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { WorkspaceAdvancedConfigComponent } from "../workspace/workspace-advanced-config/workspace-advanced-config.component";
import { ModuleType } from "@enums/ModuleType";
import { Preset } from "@models/workspace/Preset";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { PresetFilterHelper } from "../helpers/preset-filter-helper";
import { FilterType } from "@enums/FilterType";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { toPromise } from "@methods/CommonMethods";
import { ComparisonType } from "@enums/workspace/ComparisonType";

export type LinkData = Pick<LinkableComponent, "typename" | "unlinked" | "displayValueProp">;

@Component({
    selector: "app-linkable",
    templateUrl: "./linkable.component.html",
    styleUrls: ["./linkable.component.scss"],
})
export class LinkableComponent implements OnInit, OnChanges {
    @Output() linkedChange = new EventEmitter<any[]>();
    @Output() onLinkedClick: EventEmitter<any> = new EventEmitter();
    @Output() onBatchClicked: EventEmitter<any> = new EventEmitter();
    @Output() onOpenClicked: EventEmitter<any> = new EventEmitter();
    @Output() onAddClicked: EventEmitter<any> = new EventEmitter();
    @Output() onReloadClicked: EventEmitter<any> = new EventEmitter();

    @Input() rowIconConfig: LinkableIconConfig[];
    @Input() rendererConfig: RendererConfig[] = [];

    @Input() moduleType: ModuleType;
    @Input() linked: any[] = [];
    @Input() unlinked: any[] = [];
    @Input() displayValueProp: string;
    @Input() idValueProp: string;
    @Input() hideActions = false;
    @Input() hidePanelToggle = false;
    @Input() hideFilter = false;
    @Input() orderByValueProperty = false;
    @Input() disabled = false;
    @Input() allowAddNew = false;
    @Input() typename: string; // plural because we cant to link riskS / controlS / moiS/ etc.
    @Input() cleanSpacers: boolean = false;

    // Filters
    @Input() showAdvancedFilters: boolean = false;
    @Input() filterConfig: FilterConfig[] = [];
    @Input() activeFilters: SearchKeyword[] = [];
    @Input() NumberProperties: string[] = [];
    @Input() DateProperties: string[] = [];
    headerLookup = {};
    activeHeaders: string[] = [];

    @ViewChild("page") pageElementRef: ElementRef;
    @ViewChild("advancedConfigComponent")
    advancedConfigComponent: WorkspaceAdvancedConfigComponent;

    sortingOptions: SortableOptions;

    showLinkPanel = true;
    currentExpandedRow = null;

    filterText: string;
    filteredUnlinked: any[];

    showBatchButton = false;
    showReloadButton = false;
    openItemEnabled = false;
    editItemEnabled = false;
    addItemEnabled = false;

    linkablePaginationPage = 0;
    linkablePaginationPageCount = 0;
    @Input() linkablePaginationItemCount = 25;
    linkablePaginationText = "";
    linkablePage: any[];

    // Checks
    linkedOnInit: any[];
    unlinkedOnInit: any[];
    lengthOnInit = 0;

    useIcons = false;

    get singularTypename(): string {
        if (this.typename.charAt(this.typename.length - 1) === "s")
            return this.typename.slice(0, -1);
    }

    correctTypename() {
        const keyWord = "Linked ";
        if (this.typename && this.typename.startsWith(keyWord)) {
            this.typename = this.typename.slice(keyWord.length - 1, this.typename.length);
        }
    }

    constructor(
        private _toastr: ToastrService,
        private _standingDataService: StandingdataDataService
    ) {}

    ngOnInit() {
        this.setupInit();

        this.editItemEnabled = this.onLinkedClick.observers.length !== 0;
        this.showBatchButton = this.onBatchClicked.observers.length !== 0 && !this.disabled;
        this.showReloadButton = this.onReloadClicked.observers.length !== 0;
        this.openItemEnabled = this.onOpenClicked.observers.length !== 0;
        this.addItemEnabled = this.allowAddNew && this.onAddClicked.observers.length !== 0;

        this.sortingOptions = CustomSortableOptions({
            sort: false,
            group: "header-group-" + this.typename,
            draggable: ".list-group-item-action",
            disabled: this.disabled,
            onRemove: (e) => {
                this.quickFilter();
                this.linkedChange.emit(this.linked);
            },
        });

        this.quickFilter();
        this.setupActiveFilters();

        this.showLinkPanel = !this.disabled;
    }

    // Will be called after the linkable items have been refreshed
    ngOnChanges(changes: SimpleChanges): void {
        if (Object.keys(changes).length === 2 && changes["linked"] && changes["unlinked"]) {
            this.setupInit();
        }
    }

    setupInit() {
        this.correctTypename();
        // Filter linked items out of the unlinked list
        this.unlinked = this.unlinked.filter((x) => this.linked.indexOf(x) === -1);
        this.unlinkedOnInit = this.unlinked;
        this.linkedOnInit = this.linked;
        this.lengthOnInit = this.unlinked.length + this.linked.length;

        if (this.rowIconConfig && this.rowIconConfig.length > 0) {
            this.useIcons = true;

            this.setIcons(this.linked);
            this.setIcons(this.unlinked);
        }

        if (this.unlinked.length > 0 && this.unlinked[0].displayItems) {
            this.parseDisplayItems(this.unlinked);
        }

        if (this.linked.length > 0 && this.linked[0].displayItems) {
            this.parseDisplayItems(this.linked);
        }
    }

    itemDoubleClick(item: any, linked: boolean, force: boolean) {
        if (!force && !linked && this.editItemEnabled) {
            this.onLinkedClick.emit(item);
        }
    }

    toggleItem(item: any, linked: boolean, force: boolean = false) {
        if (this.disabled) {
            return;
        }

        if (!force && !linked && this.editItemEnabled) {
            return;
        }

        if (linked) {
            const index = this.unlinked.indexOf(item);
            this.unlinked.splice(index, 1);

            if (this.orderByValueProperty) {
                this.linked.pushOrdered(item, this.displayValueProp);
            } else {
                this.linked.push(item);
            }
        } else {
            const index = this.linked.indexOf(item);
            this.linked.splice(index, 1);

            if (this.orderByValueProperty) {
                this.unlinked.pushOrdered(item, this.displayValueProp);
            } else {
                this.unlinked.push(item);
            }
        }

        this.quickFilter();

        if (this.areListsCorrect()) {
            this.linkedChange.emit(this.linked);
        }
    }

    quickFilter() {
        if (this.showAdvancedFilters) {
            this.applyActiveFilters();
            return;
        }

        const filterTags =
            this.filterText && this.filterText.length > 0
                ? this.filterText.toLowerCase().split(" ")
                : [];

        if (filterTags.length === 0) {
            this.filteredUnlinked = this.unlinked.filter((_) => true);
        } else {
            this.filteredUnlinked = this.unlinked.filter((item) =>
                filterTags.some((ft) => item[this.displayValueProp].toLowerCase().indexOf(ft) >= 0)
            );
        }

        this.calculatePagination();
    }

    calculatePagination() {
        this.linkablePaginationPageCount = Math.ceil(
            this.filteredUnlinked.length / this.linkablePaginationItemCount
        );

        this.setPagination(0);
    }

    setPagination(page: number) {
        if (page < 0 || page > this.linkablePaginationPageCount) {
            return;
        }

        if (page === this.linkablePaginationPageCount) {
            this.linkablePage = this.filteredUnlinked;
            return;
        }

        this.linkablePaginationPage = page;

        const linkableItemStart = this.linkablePaginationPage * this.linkablePaginationItemCount;
        const linkableItemEnd = linkableItemStart + this.linkablePaginationItemCount;
        this.linkablePage = this.filteredUnlinked.slice(linkableItemStart, linkableItemEnd);

        const start = this.linkablePaginationPage * this.linkablePaginationItemCount + 1;
        const end =
            this.linkablePaginationPage + 1 === this.linkablePaginationPageCount
                ? this.filteredUnlinked.length
                : this.linkablePaginationPage * this.linkablePaginationItemCount +
                  this.linkablePaginationItemCount;

        this.linkablePaginationText = `Showing ${start} - ${end} of ${this.filteredUnlinked.length} ${this.typename}`;
    }

    batchClicked() {
        if (this.onBatchClicked) {
            this.onBatchClicked.emit();
        }
    }

    addClicked() {
        if (this.onAddClicked) {
            this.onAddClicked.emit();
        }
    }

    reloadClicked() {
        if (this.showReloadButton && this.onReloadClicked) {
            this.onReloadClicked.emit();
        }
    }

    openItem(item: any) {
        this.onOpenClicked.emit(item);
    }

    expandItem(item) {
        item._isExpanded = !item._isExpanded;

        // Uncomment this item to only allow 1 expanded row.
        // if (this.currentExpandedRow != item) {
        //     if (this.currentExpandedRow) {
        //         this.currentExpandedRow._isExpanded = false;
        //     }

        //     this.currentExpandedRow = item;
        // }
    }

    private areListsCorrect(): boolean {
        let listsValid = true;

        const checkProp = this.idValueProp ? this.idValueProp : this.displayValueProp;
        // Check if lists contain doubles
        if (this.linked.findDuplicatesByProp(checkProp).any()) {
            this.linked = this.linked.distinct();

            this._toastr.warning(
                "Linked list contained a duplicate item, it has been removed.",
                ""
            );

            listsValid = false;
        }

        if (this.unlinked.findDuplicatesByProp(checkProp).any()) {
            this.unlinked = this.unlinked.distinct();

            this._toastr.warning(
                "Unlinked list contained a duplicate item, it has been removed.",
                ""
            );

            listsValid = false;
        }

        if (this.unlinked.length + this.linked.length !== this.lengthOnInit) {
            this._toastr.warning("Items were missing, both lists are restored.", "Changed failed");
            this.unlinked = this.unlinkedOnInit;
            this.linked = this.linkedOnInit;

            listsValid = false;
        }

        return listsValid;
    }

    setIcons(items) {
        if (items) {
            this.rowIconConfig.forEach((config, iconIndex) => {
                items.forEach((item) => {
                    if (!item.rowIcons) {
                        item.rowIcons = [];
                    }

                    let iconToPush = "";
                    if (item[config.actionColumn] === config.actionValue) {
                        iconToPush = config.icon;
                    } else {
                        // Add empty icon to reserve space
                        iconToPush = "fa-fw";
                    }

                    if (item.rowIcons[iconIndex] != iconToPush) {
                        item.rowIcons[iconIndex] = iconToPush;
                    }
                });
            });

            if (this.cleanSpacers === true) {
                items.forEach((item) => {
                    for (let i = item.rowIcons.length - 1; i >= 0; i--) {
                        const icon = item.rowIcons[i];
                        if (icon.indexOf("fa-fw") !== -1) {
                            item.rowIcons.splice(i, 1);
                        }
                    }
                });
            }
        }
    }

    parseDisplayItems(items) {
        // Create array of hidden columns, which should be filtered out of the displayItems array
        const renderConfig = this.rendererConfig || [];
        const hiddenRendererColumns = [];
        for (const rc of renderConfig) {
            if (rc.hideColumn) {
                hiddenRendererColumns.push(rc.textColumn);
            }
            if (rc.hideActionColumn && rc.actionColumn) {
                hiddenRendererColumns.push(rc.actionColumn);
            }
        }

        items.forEach((item, itemIndex: number) => {
            // Create flat object of the whole displayItems array, required for the workspace cell renderer
            const parsedItems = {};
            item.linkableDisplayItems = [];

            for (let i = item.displayItems.length - 1; i >= 0; i--) {
                const displayItem: DisplayItemModel = item.displayItems[i];
                parsedItems[displayItem.Header] = displayItem.Value;

                // Only display the non-hidden display items
                if (!hiddenRendererColumns.includes(displayItem.Header)) {
                    item.linkableDisplayItems.push(displayItem);

                    // Extra mapping is required when advanced filters are used
                    if (this.showAdvancedFilters) {
                        // Required for filtering via SearchKeyWords, make sure to not overwrite existing values
                        if (!item[displayItem.Header]) {
                            item[displayItem.Header] = displayItem.Value;
                        }

                        if (itemIndex === 0 && !this.activeHeaders.includes(displayItem.Header)) {
                            // Extract headers on first item only
                            this.headerLookup[displayItem.Header] = displayItem.Header;
                            this.activeHeaders.push(displayItem.Header);
                        }
                    }
                }
            }

            item.displayItemRow = parsedItems;
        });
    }

    showFilterConfig() {
        if (!this.showAdvancedFilters) {
            return;
        }

        this.advancedConfigComponent.showConfig();
    }

    clearFilters() {
        this.filterText = "";
        this.activeFilters = [];

        this.filteredUnlinked = this.unlinked;

        this.calculatePagination();
    }

    async setupActiveFilters() {
        if (this.showAdvancedFilters && this.activeFilters && this.activeFilters.any()) {
            await Promise.all(
                this.activeFilters.map(async (activeFilter) => {
                    const filter = this.filterConfig.find((x) => x.column === activeFilter.col);
                    if (!filter) {
                        return;
                    }

                    const selected = activeFilter.searchValue.split(";").map(Number);
                    if (!selected || !selected.length) {
                        return;
                    }

                    const idsDown = await this.getIdsDownByType(filter.type, selected[0]);

                    activeFilter.searchValue = idsDown.join(";");
                })
            );
        }

        this.applyActiveFilters();
    }

    async getIdsDownByType(type: FilterType, id: number): Promise<number[]> {
        switch (type) {
            case FilterType.Organization:
                return toPromise(
                    this._standingDataService.getIdsDownByType(StandingDataType.Organization, id)
                );
            case FilterType.BusinessDimensions:
                return toPromise(
                    this._standingDataService.getIdsDownByType(
                        StandingDataType.BusinessDimensions,
                        id
                    )
                );
            case FilterType.FrameworkDimensions:
                return toPromise(
                    this._standingDataService.getIdsDownByType(
                        StandingDataType.FrameworkDimensions,
                        id
                    )
                );
            default:
                throw new Error("Filter type not yet configured");
        }
    }

    applyActiveFilters() {
        if (!this.showAdvancedFilters) {
            return;
        }

        const activeFilters = [...this.activeFilters];
        if (this.filterText && this.filterText.length > 0) {
            activeFilters.push({
                searchValue: this.filterText,
                col: "",
                specific: false,
                compareType: ComparisonType.Contains,
            });
        }

        this.filteredUnlinked = PresetFilterHelper.filterData(
            activeFilters,
            this.unlinked,
            this.NumberProperties,
            this.DateProperties
        );

        this.calculatePagination();
    }

    applyFilters(filters: Preset) {
        this.activeFilters = filters.searchKeywords;

        this.applyActiveFilters();
    }
}
