import { Component, OnInit, ViewChild } from "@angular/core";
import { DeletedItemsService } from "./shared/deleted-items.service";
import { ModuleType } from "@enums/ModuleType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { GetIconByModuleString } from "@methods/Icons";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { ModuleSubType } from "@enums/ModuleSubType";
import { DeletedItemMenuModel } from "./shared/models/deleted-item-menu-model";
import { nameof } from "@methods/jeffs-toolkit";
import { DeletedItemModel } from "./shared/models/deleted-item-model";
import { AdvEffectivenessDeletedItemModel } from "./shared/models/adv-effectiveness-deleted-item-model";
import { ToastrService } from "ngx-toastr";
import { ArchivedStructureModel } from "./shared/models/archived-structure-model";

@Component({
    selector: "deleted-items",
    templateUrl: "./deleted-items.component.html",
    styleUrls: ["./deleted-items.component.scss"],
})
export class DeletedItemsComponent implements OnInit {
    @ViewChild("customMenu", { static: true }) customMenu: any;
    tab: TabModel;

    selectedMenuItem: DeletedItemMenuModel;
    menuItems: DeletedItemMenuModel[] = [];

    configLoaded = false;
    config: GenericListConfig;

    constructor(
        private _ds: DeletedItemsService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    ngOnInit() {
        this.tab.showLoader = true;
        this._ds.getList().subscribe((menuItems) => {
            if (menuItems && menuItems.length > 0) {
                this.setIcons(menuItems);

                this.menuItems = menuItems;
                this.selectedMenuItem = this.menuItems[0];

                this.loadConfig();
                this.tab.showLoader = false;
                this.tab.menu = new TabMenu();
                this.tab.menu.customMenu = this.customMenu;
            } else {
                this.tab.close(false);
            }
        });
    }

    setIcons(menuItems: DeletedItemMenuModel[]) {
        menuItems.forEach((menuItem) => {
            const moduleDescr = menuItem.subType
                ? ModuleSubType[ModuleSubType[menuItem.subType] + "Description"]
                : ModuleType[ModuleType[menuItem.module] + "Description"];

            menuItem.icon = GetIconByModuleString(moduleDescr);

            if (menuItem.children && menuItem.children.length > 0) {
                this.setIcons(menuItem.children);
            }
        });
    }

    nodeClicked(item: DeletedItemMenuModel): void {
        if (item.children.length === 0) {
            this.selectedMenuItem = item;
            this.config = null;
            this.loadConfig();
        } else {
            item.isExpanded = !item.isExpanded;
        }
    }

    loadConfig() {
        this.configLoaded = false;

        const that = this;
        setTimeout(() => {
            this.config = <GenericListConfig>{
                idProp: nameof<DeletedItemModel>((x) => x.id),
                skipReloadConfirm: true,
                uiid: "list-deleted-items",
                name: "Deleted Item",
                dataMethod: () => {
                    const module = this.selectedMenuItem.module;
                    const subModule = this.selectedMenuItem.subType;

                    return this._ds.getByModule(module, subModule);
                },
                isSortableByColumn: true,
                fields: this.getConfigFields(),

                editWindowButtons: [
                    {
                        text: "Restore",
                        class: "btn btn-warning",
                        icon: "far fa-trash-restore-alt",
                        uiid: "button-restore",
                        clickEvent: async (row: DeletedItemModel) => {
                            if (
                                await this._promptService
                                    .confirm(
                                        "Restore item",
                                        that.getConfirmWarning(
                                            row,
                                            that.selectedMenuItem.module,
                                            that.selectedMenuItem.subType
                                        )
                                    )
                                    .toPromise()
                            ) {
                                this.configLoaded = false;
                                const module = this.selectedMenuItem.module;
                                const subModule = this.selectedMenuItem.subType;

                                this._ds
                                    .restoreByModule(module, row.id, subModule)
                                    .subscribe((x) => {
                                        this._toastr.success("Restore has been completed.");
                                    })
                                    .add(() => {
                                        this.configLoaded = true;
                                    });

                                return true;
                            }

                            return false;
                        },
                    },
                ],
            };

            this.configLoaded = true;
        }, 100);
    }

    private getConfigFields() {
        switch (this.selectedMenuItem.module) {
            case ModuleType.COMMON:
                {
                    switch (this.selectedMenuItem.subType) {
                        case ModuleSubType.OrganizationMaintenance:
                        case ModuleSubType.BusinessDimensionMaintenance:
                        case ModuleSubType.FrameworkDimensionMaintenance: {
                            return this.getArchivedStructureConfigFields();
                        }
                    }
                }
                break;
            case ModuleType.Controls: {
                switch (this.selectedMenuItem.subType) {
                    case ModuleSubType.AdvEffTesting: {
                        return this.getAdvTestingConfigFields();
                    }
                }
            }
        }

        return this.getDefaultConfigFields();
    }

    private getArchivedStructureConfigFields() {
        return [
            {
                fieldName: nameof<ArchivedStructureModel>((x) => x.id),
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
                hideInEdit: true,
                hideInOverview: true,
            },
            {
                fieldName: nameof<ArchivedStructureModel>((x) => x.name),
                prettyName: "Name",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<ArchivedStructureModel>((x) => x.fullPath),
                prettyName: "Full path",
                fieldType: GenericListFieldType.TextArea,
                isReadonly: true,
            },
            {
                fieldName: nameof<ArchivedStructureModel>((x) => x.dateArchived),
                prettyName: "Date archived",
                fieldType: GenericListFieldType.DateTime,
                isReadonly: true,
            },
            {
                fieldName: nameof<DeletedItemModel>((x) => x.username),
                prettyName: "Archived by",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
        ];
    }

    private getDefaultConfigFields(): GenericListField[] {
        const hideOrganization =
            this.selectedMenuItem.module === ModuleType.FRM ||
            this.selectedMenuItem.module == ModuleType.AUDIT;

        return [
            {
                fieldName: nameof<DeletedItemModel>((x) => x.id),
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
                hideInEdit: true,
                hideInOverview: true,
            },
            {
                fieldName: nameof<DeletedItemModel>((x) => x.identifier),
                prettyName: "Identifier",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<DeletedItemModel>((x) => x.name),
                prettyName: "Name",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<DeletedItemModel>((x) => x.dateTimeDeleted),
                prettyName: "Date deleted",
                fieldType: GenericListFieldType.DateTime,
                isReadonly: true,
            },
            {
                fieldName: nameof<DeletedItemModel>((x) => x.username),
                prettyName: "Deleted by",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<DeletedItemModel>((x) => x.organization),
                prettyName: "Organization",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
                hideInEdit: hideOrganization,
                hideInOverview: hideOrganization,
            },
        ];
    }

    private getAdvTestingConfigFields(): GenericListField[] {
        return [
            {
                fieldName: nameof<DeletedItemModel>((x) => x.id),
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
                hideInEdit: true,
                hideInOverview: true,
            },
            {
                fieldName: nameof<AdvEffectivenessDeletedItemModel>((x) => x.name),
                prettyName: "Testplan name",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<AdvEffectivenessDeletedItemModel>((x) => x.periodName),
                prettyName: "Period name",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<AdvEffectivenessDeletedItemModel>((x) => x.control),
                prettyName: "Control",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: nameof<AdvEffectivenessDeletedItemModel>((x) => x.dateTimeDeleted),
                prettyName: "Date deleted",
                fieldType: GenericListFieldType.DateTime,
                isReadonly: true,
            },
            {
                fieldName: nameof<AdvEffectivenessDeletedItemModel>((x) => x.username),
                prettyName: "Deleted by",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
        ];
    }

    private getConfirmWarning(row: DeletedItemModel, module: ModuleType, subType: ModuleSubType) {
        const baseMsg = `Are you sure you want to restore '${row.name}'?`;
        switch (module) {
            case ModuleType.COMMON:
                {
                    let itemName = "";
                    switch (subType) {
                        case ModuleSubType.OrganizationMaintenance:
                            itemName = "organizations";
                            break;
                        case ModuleSubType.BusinessDimensionMaintenance:
                            itemName = "business dimensions";
                            break;
                        case ModuleSubType.FrameworkDimensionMaintenance:
                            itemName = "framework dimensions";
                            break;
                    }

                    return baseMsg + `\n This will also restore all archived child ${itemName}.`;
                }
                break;
        }

        return baseMsg;
    }
}
