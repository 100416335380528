import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ThirdPartyDataService } from "@pages/thirdparty/services/ThirdPartyDataService";
import { ThirdPartyReviewModel } from "@models/thirdparty/ThirdpartyReviewModel";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ThirdPartyReviewScoreModel } from "@models/thirdparty/ThirdpartyReviewScoreModel";
import { of } from "rxjs";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";

@Component({
    selector: "thirdparty-reviews",
    templateUrl: "thirdparty-review.component.html",
    styleUrls: ["thirdparty-review.component.scss"],
})
export class ThirdPartyReviewComponent implements OnInit {
    @Input() thirdpartyModel: ThirdPartyModel;
    @Input() canReview: boolean;

    reviews: ThirdPartyReviewModel[];
    isLoading = true;

    scores: ThirdPartyReviewScoreModel[];
    reviewConfig: GenericListConfig;

    constructor(
        private dataservice: ThirdPartyDataService,
        private _sdDataService: StandingdataDataService,
        private _userService: UserService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        if (!this.thirdpartyModel && !this.thirdpartyModel.Guid) {
            return;
        }
        this.initReviewConfig();
    }

    initReviewConfig() {
        this.reviewConfig = <GenericListConfig>{
            idProp: "id",
            uiidAdd: "thirdpartyreview-addbutton",
            uiidDelete: "thirdpartyreview-deletebutton",
            name: "Review",
            isSortable: false,

            allowAdd: this.canReview,
            allowDelete: this.canReview,
            allowEdit: this.canReview,

            data: this.thirdpartyModel.Reviews,
            overviewSortBy: "createdAt",
            overviewSortAsc: false,

            fields: [
                {
                    fieldName: "scoreId",
                    prettyName: "Score",
                    uiid: "thirdpartyreview-score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    required: true,
                    editorWidth: "12",
                    getDataMethod: () => {
                        return this._sdDataService.getAllByType(StandingDataType.TpThirdPartyScore);
                    },
                },
                {
                    fieldName: "comment",
                    prettyName: "Comment",
                    uiid: "thirdpartyreview-comment",
                    required: true,
                    fieldType: GenericListFieldType.TextArea,
                    editorWidth: "12",
                },
                {
                    fieldName: "createdBy",
                    prettyName: "Reviewer",
                    fieldType: GenericListFieldType.Text,
                    hideInEdit: true,
                },
                {
                    fieldName: "createdAt",
                    prettyName: "Date",
                    fieldType: GenericListFieldType.Date,
                    hideInEdit: true,
                },
            ],
            add: async (row: ThirdPartyReviewModel) => {
                this._userService.getUserName().then((username) => (row.createdBy = username));
                // This is set at server side
                row.createdAt = new Date();
                return true;
            },
            delete: async (row: ThirdPartyReviewModel) => {
                return await this._promptService
                    .confirm("Delete review", "Are you sure you want to delete this review?")
                    .toPromise();
            },

            dataChanged: (data) => {},
        };
    }

    editReview() {
        return of(this.thirdpartyModel.Reviews);
    }

    async saveReview(data: ThirdPartyReviewModel) {
        const model = new ThirdPartyReviewModel();

        model.comment = data.comment;
        model.scoreId = data.scoreId;
        model.score = data.score;

        if (data.id > 0) {
            model.id = data.id;
        } else {
            model.id = 0;
        }

        this.thirdpartyModel.Reviews.push(model);
    }
}
