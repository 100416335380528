import { Component, ViewChild, ElementRef } from "@angular/core";
import * as Highcharts from "highcharts";
import { ControlTestScoreChartConfig } from "../ControlTestScoreChartConfig";
import { Chart } from "highcharts";

@Component({
    selector: "control-testscore-chart",
    templateUrl: "./control-testscore-chart.component.html",
    styleUrls: ["./control-testscore-chart.component.scss"],
})
export class ControlTestscoreChartComponent {
    @ViewChild("chart", { static: true }) chartElem: ElementRef;
    loadedChart: Chart;
    showNoData = false;

    createChart(config: ControlTestScoreChartConfig): void {
        this.showNoData = config.data.length === 0;
        if (this.showNoData) return;

        this.loadedChart = Highcharts.chart(this.chartElem.nativeElement, {
            chart: {
                type: "column",
            },
            title: {
                text: config.chartTitle,
            },
            xAxis: {
                categories: config.xCategories,
                title: {
                    text: config.xTitle,
                },
            },
            tooltip: {
                formatter: function () {
                    return config.yCategories[this.y];
                },
            },
            yAxis: {
                labels: {
                    formatter: function () {
                        return config.yCategories[this.value];
                    },
                },
                tickInterval: 1,
                min: 0,
                max: config.yCategories.length - 1,
                title: {
                    text: config.yTitle,
                },
            },
            credits: {
                enabled: false,
            },
            series: config.data,
        });
    }
}
