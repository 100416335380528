import { Component } from "@angular/core";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { Observable } from "rxjs";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "datamanagement-linkable",
    templateUrl: "./datamanagement-linkable.component.html",
    styleUrls: ["./datamanagement-linkable.component.scss"],
})
export class DatamanagementLinkableComponent extends ItemLinkableBase {
    hasAccessChecks = {};
    accessCheckInProgress = false;

    constructor(
        protected _toastr: ToastrService,
        protected _promptService: CerrixPromptService,
        private _dmDataService: DatamanagementDataService,
        private _pages: Pages,
        private _tabService: TabService
    ) {
        super(_toastr, _promptService);
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._dmDataService.hasAccess(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.DataManagement,
                item.guid,
                `(P) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this data processing.");
        }
    }
}
