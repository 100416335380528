<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="form-screen-system" menuSystem [cerrixTab]="tab" *ngIf="categories && permissions">
    <div
        class="form-screens"
        [attr.name]="'Forms'"
        menuicon="fas fa-file-alt"
        menuItemId="home"
        menusystemsubmenu
    >
        <div name="Form overview" menuicon="fas fa-home" menusystemitem>
            <form-overview
                [cerrixTab]="tab"
                [categories]="categories"
                [permissions]="permissions"
            ></form-overview>
        </div>
        <div
            name="Invited status overview"
            menuicon="fas fa-stream"
            menuItemId="invites"
            menusystemitem
        >
            <form-status [categories]="categories" [permissions]="permissions"></form-status>
        </div>
        <div
            name="Form Drafts"
            menuicon="fal fa-drafting-compass"
            menuItemId="drafts"
            menusystemitem
            *ngIf="permissions.IsEditor || permissions.IsReleaser"
        >
            <unreleased-form-overview></unreleased-form-overview>
        </div>
    </div>
</div>
