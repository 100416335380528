<div class="fancy-loader" *ngIf="!pageReady"></div>

<div class="detail-page-action-bar flex-action-bar">
    <div>
        <button
            data-uiid="organization-budgets-save-button"
            class="btn btn-cerrix"
            (click)="save()"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save</span>
        </button>
    </div>
</div>

<div class="detail-page-card" *ngIf="pageReady && gridConfig">
    <div class="detail-page-card-title">Organizational Budget</div>
    <div class="detail-page-card-actions">
        <button
            class="btn btn-raised"
            (click)="loadGrid()"
            data-uiid="organization-budgets-reload-button"
        >
            <i class="fas fa-sync"></i>
            <span class="ms-2">Reload</span>
        </button>
    </div>
    <div class="detail-page-card-body overflow-auto">
        <cerrix-grid-editor
            [config]="gridConfig"
            data-uiid="organization-budgets-grid-editor"
        ></cerrix-grid-editor>
    </div>
</div>
