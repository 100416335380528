import { Component, ViewEncapsulation } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { CerrixTextInputBase } from "../cerrix-textinput-base";
import { NgControl } from "@angular/forms";

@Component({
    selector: "cerrix-textfield",
    templateUrl: "./cerrix-textfield.component.html",
    styleUrls: ["../cerrix-textinput.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CerrixTextfieldComponent extends CerrixTextInputBase {
    public constructor(control: NgControl, _configuration: Configuration) {
        super(control, _configuration);
        this.control.valueAccessor = this;
    }
}
