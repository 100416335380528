import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, DatePipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ControlLinkableComponent } from "@app/controls/linkable/control-linkable.component";
import { DatamanagementLinkableComponent } from "@app/datamanagement/pages/datamanagement-linkable/datamanagement-linkable.component";
import { EventLinkableComponent } from "@app/event/event-linkable/event-linkable.component";
import { FormLinkableComponent } from "@app/forms/pages/form-linkable/form-linkable.component";
import { LinkedMoiOverviewComponent } from "@app/moi/moi-linked-overview/moi-linked-overview.component";
import { RiskLinkableComponent } from "@app/risk/pages/risk-linkable/risk-linkable.component";
import { ThirdPartyLinkableComponent } from "@app/thirdparty/thirdparty-linkable.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { GridsterModule } from "angular-gridster2";
import "froala-editor/js/plugins.pkgd.min.js";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxMasonryModule } from "ngx-masonry";
import { SortablejsModule } from "../shared/sortablejs/sortablejs.module";
import { ToastrModule } from "ngx-toastr";
import {
    ArrayFormatPipe,
    DateFieldComponent,
    DateFormatPipe,
    DateInputComponent,
    DateTimeFormatPipe,
} from ".";
import { CheckboxComponent } from "./cerrix-checkbox/cerrix-checkbox.component";
import { CerrixColorSelectComponent } from "./cerrix-color-select/cerrix-color-select.component";
import { ComboBoxComponent } from "./cerrix-combo-box/cerrix-combo-box.component";
import { CerrixGridEditorComponent } from "./cerrix-grid-editor/cerrix-grid-editor.component";
import { CerrixInviteComponent } from "./cerrix-invite/cerrix-invite.component";
import { CerrixLogoComponent } from "./cerrix-logo/cerrix-logo.component";
import { CerrixParentChildSelectorComponent } from "./cerrix-parent-child-selector/cerrix-pcs.component";
import { CerrixPromptComponent } from "./cerrix-prompt/cerrix-prompt.component";
import { CerrixSelectTreeComponent } from "./cerrix-select-tree/cerrix-select-tree.component";
import { CerrixSelectComponent } from "./cerrix-select/cerrix-select.component";
import { CerrixSlideOverComponent } from "./cerrix-slide-over/cerrix-slide-over.component";
import { CerrixTableMultiLevelComponent } from "./cerrix-table-multi-level/cerrix-table-multi-level.component";
import { CerrixTableComponent } from "./cerrix-table/cerrix-table.component";
import { CerrixTreeViewerComponent } from "./cerrix-tree-viewer/cerrix-tree-viewer.component";
import { CerrixVideoPlayerComponent } from "./cerrix-video-player/cerrix-video-player.component";
import { CerrixWizardComponent } from "./cerrix-wizard/cerrix-wizard.component";
import { CerrixWorkflowComponent } from "./cerrix-workflow/cerrix-workflow.component";
import { CerrixCustomFieldsEditorComponent } from "./components/cerrix-custom-fields-editor/cerrix-custom-fields-editor.component";
import { CerrixEditorComponent } from "./components/cerrix-editor/cerrix-editor.component";
import { CerrixIconPickerComponent } from "./components/cerrix-icon-picker/cerrix-icon-picker.component";
import { CerrixIconViewerComponent } from "./components/cerrix-icon-viewer/cerrix-icon-viewer.component";
import { CerrixImagePickerComponent } from "./components/cerrix-image-picker/cerrix-image-picker.component";
import { CerrixLoaderComponent } from "./components/cerrix-loader/cerrix-loader.component";
import { CerrixRiskmatrixSummaryComponent } from "./components/cerrix-riskmatrix-summary/cerrix-riskmatrix-summary.component";
import { CerrixRiskMatrixComponent } from "./components/cerrix-riskmatrix/cerrix-riskmatrix.component";
import { CerrixTextAreaComponent } from "./components/cerrix-textinput/cerrix-textarea/cerrix-textarea.component";
import { CerrixTextfieldComponent } from "./components/cerrix-textinput/cerrix-textfield/cerrix-textfield.component";
import { CerrixUrlInputComponent } from "./components/cerrix-url-input/cerrix-url-input.component";
import { DetailOptionsComponent } from "./components/detail-options/detail-options.component";
import { ExpandableListComponent } from "./components/expandable-list/expandable-list.component";
import { ContextmenuComponent } from "./contextmenu/contextmenu.component";
import { MessageDialogComponent } from "./dialogs/message-dialog/message-dialog.component";
import { DebounceInputEventDirective } from "./directives/debounce-input-event.directive";
import { ExcelColumnNameInputDirective } from "./directives/excel-column-name-input.directive";
import { FileDropDirective } from "./directives/file-drop.directive";
import { InputTooltipDirective } from "./directives/input-tooltip.directive";
import { MenuSystemDirective } from "./directives/menu-system.directive";
import { UserHeartbeatDirective } from "./directives/user-heartbeat.directive";
import { DocumentHistoryComponent } from "./document-manager/document-history/document-history.component";
import { DocumentManagerDetailsComponent } from "./document-manager/document-manager-details/document-manager-details.component";
import { DocumentManagerComponent } from "./document-manager/document-manager.component";
import { CerrixEditorPopupComponent } from "./generic-managers/generic-field-editor/custom-field-types/cerrix-editor-popup/cerrix-editor-popup.component";
import { CustomFieldEditor } from "./generic-managers/generic-field-editor/custom-field-types/custom-field-editor/custom-field-editor.component";
import { FrameworkAssessmentScores } from "./generic-managers/generic-field-editor/custom-field-types/framework-assessment-scores/framework-assessment-scores.component";
import { OrganizationRiskmatrixEditorComponent } from "./generic-managers/generic-field-editor/custom-field-types/organization-riskmatrix-editor/organization-riskmatrix-editor.component";
import { RiskAppetiteSelectionComponent } from "./generic-managers/generic-field-editor/custom-field-types/risk-appetite-selection/risk-appetite-selection.component";
import { SubListManagerComponent } from "./generic-managers/generic-field-editor/custom-field-types/sub-list-manager/sub-list-manager.component";
import { GenericFieldEditorComponent } from "./generic-managers/generic-field-editor/generic-field-editor.component";
import { GenericFormEditorComponent } from "./generic-managers/generic-form-editor/generic-form-editor.component";
import { GenericGroupedListManagerComponent } from "./generic-managers/generic-grouped-list-manager/generic-grouped-list-manager.component";
import { GenericListManagerComponent } from "./generic-managers/generic-list-manager/generic-list-manager.component";
import { HistoryDetailComponent } from "./history-overview/history-detail/history-detail.component";
import { HistoryOverviewComponent } from "./history-overview/history-overview.component";
import { HyperlinkManagerComponent } from "./hyperlink-manager/hyperlink-manager.component";
import { LinkableComponent } from "./linkable/linkable.component";
import { CellRendererPipe } from "./pipes/cell-renderer.pipe";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { FilterPipe } from "./pipes/filter.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { SortbyPipe } from "./pipes/sortby.pipe";
import { CerrixInviteDataService } from "./services/cerrix-invite.service";
import { CerrixPromptService } from "./services/cerrix-prompt.service";
import { CerrixThemingService } from "./services/cerrix-theming.service";
import { PopupService } from "./services/popup/popup.service";
import { SharedDataService } from "./services/shared-data.service";
import { WorkspaceAdvancedConfigComponent } from "./workspace/workspace-advanced-config/workspace-advanced-config.component";
import { WorkspaceCellRendererComponent } from "./workspace/workspace-cell-renderer/workspace-cell-renderer.component";
import { WorkspacePresetManagementComponent } from "./workspace/workspace-preset-management/workspace-preset-management.component";
import { WorkspaceComponent } from "./workspace/workspace.component";

import { GridModule } from "@syncfusion/ej2-angular-grids";
import { DropDownListAllModule, ListBoxModule } from "@syncfusion/ej2-angular-dropdowns";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import { TreeGridModule } from "@syncfusion/ej2-angular-treegrid";
import { DiagramModule } from "@syncfusion/ej2-angular-diagrams";
import { CerrixEditorViewerComponent } from "./components/cerrix-editor-viewer/cerrix-editor-viewer.component";
import { TextAreaAutoGrowDirective } from "./directives/textarea-auto-grow";
import { FindingsLinkableComponent } from "@app/audit-finding/linkable/findings-linkable.component";
import { DocumentManagerOldComponent } from "./document-manager-old/document-manager-old.component";
import { CerrixRecurrenceComponent } from "./components/cerrix-recurrence/cerrix-recurrence.component";
import { CerrixAnimatedIllustrationComponent } from "./cerrix-animated-illustration/cerrix-animated-illustration.component";

import { LottieComponent, provideLottieOptions } from "ngx-lottie";
import { CerrixCollapsingBoxComponent } from "./cerrix-collapsing-box/cerrix-collapsing-box.component";
import { CerrixDocumentInfoBoxViewComponent } from "./cerrix-document-info-box-view/cerrix-document-info-box-view.component";
import { CerrixBannerCardComponent } from "./cerrix-banner-card/cerrix-banner-card.component";
import { CerrixWorkflowHistoryGraphComponent } from "./cerrix-workflow-history-graph/cerrix-workflow-history-graph.component";
import { ReversePipe } from "./pipes/reverse.pipe";
import { CerrixTextareaWithAutohideComponent } from "./components/cerrix-textarea-with-autohide/cerrix-textarea-with-autohide.component";
import { CerrixTextfieldWithAutohideComponent } from "./components/cerrix-textfield-with-autohide/cerrix-textfield-with-autohide.component";
import { WorkspacePresetService } from "./services/workspace/workspace-preset.service";
import { WorkspaceCountService } from "./services/workspace/workspace-count.service";
import { KriLinkableComponent } from "@app/kri/kri-linkable/kri-linkable.component";
import { DocumentManagerGenerateComponent } from "./document-manager/document-manager-generate/document-manager-generate.component";
import { KriDataGraphOverviewComponent } from "@app/kri/shared/pages/kri-data-graph-overview/kri-data-graph-overview.component";
import { KriDataGraphComponent } from "@app/kri/shared/pages/kri-data-graph/kri-data-graph.component";
import { CerrixSlideOverWopiComponent } from "./cerrix-slide-over-wopi/cerrix-slide-over-wopi.component";
import { BaseDatepickerService, BaseTreeService } from "@cerrix/components";
import { TreeSettingsService } from "./services/tree-settings.service";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";
import { DatepickerSettingsService } from "./services/datepicker-settings.service";

import { DocumentManagerFolderDetailsComponent } from "./document-manager/document-manager-folder/document-manager-folder-details/document-manager-folder-details.component";
import { DocumentManagerFolderComponent } from "./document-manager/document-manager-folder/document-manager-folder.component";
import { AiSuggestionsPopupComponent } from "./ai-suggestions-popup/ai-suggestions-popup.component";
import { AiDescriptionGeneratorComponent } from "./ai-description-generator/ai-description-generator.component";
import { IncidentService } from "@app/incident/services/incident.service";
export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ "lottie-web");
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,

        // ej2 imports
        DropDownListAllModule,
        GridModule,
        TreeViewModule,
        TreeGridModule,
        ListBoxModule,
        DiagramModule,

        // Move external stuff to seperate module
        BrowserAnimationsModule,

        ToastrModule,
        AccordionModule,
        BsDropdownModule,
        PopoverModule,
        ModalModule,
        TabsModule.forRoot(),
        TooltipModule,
        SortablejsModule,
        BsDatepickerModule,
        TimepickerModule.forRoot(),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        GridsterModule,
        GridModule,

        NgxMasonryModule,
        NgSelectModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatStepperModule,
        MatTableModule,
        MatToolbarModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatRadioModule,
        MatRippleModule,
        MatSliderModule,
        MatSortModule,
        MatTabsModule,
        MatTooltipModule,
        MatTreeModule,
        MatFormFieldModule,

        LottieComponent,
    ],
    declarations: [
        DateFormatPipe,
        DateTimeFormatPipe,
        FilterPipe,
        FileSizePipe,
        ArrayFormatPipe,
        SafePipe,
        SortbyPipe,
        ReversePipe,

        MenuSystemDirective,
        FileDropDirective,
        DebounceInputEventDirective,
        ExcelColumnNameInputDirective,
        UserHeartbeatDirective,
        InputTooltipDirective,
        TextAreaAutoGrowDirective,

        DateInputComponent,
        DateFieldComponent,
        CerrixLoaderComponent,

        WorkspaceComponent,
        WorkspaceCellRendererComponent,
        WorkspaceAdvancedConfigComponent,
        WorkspacePresetManagementComponent,
        CerrixSelectTreeComponent,
        ComboBoxComponent,
        DocumentManagerComponent,
        DocumentManagerOldComponent,
        HyperlinkManagerComponent,
        GenericListManagerComponent,
        GenericGroupedListManagerComponent,
        GenericFormEditorComponent,
        GenericFieldEditorComponent,
        OrganizationRiskmatrixEditorComponent,
        RiskAppetiteSelectionComponent,
        SubListManagerComponent,
        FrameworkAssessmentScores,
        CustomFieldEditor,

        HistoryOverviewComponent,
        HistoryDetailComponent,
        DocumentManagerDetailsComponent,
        DocumentHistoryComponent,
        LinkableComponent,
        MessageDialogComponent,
        CerrixPromptComponent,
        CheckboxComponent,
        CerrixTableComponent,
        CerrixTableMultiLevelComponent,
        DetailOptionsComponent,
        LinkedMoiOverviewComponent,
        RiskLinkableComponent,
        KriLinkableComponent,
        ControlLinkableComponent,
        FindingsLinkableComponent,
        EventLinkableComponent,
        FormLinkableComponent,
        DatamanagementLinkableComponent,
        ThirdPartyLinkableComponent,
        CerrixSelectComponent,
        CerrixParentChildSelectorComponent,
        CerrixWizardComponent,
        CerrixInviteComponent,
        CerrixSlideOverComponent,
        CerrixColorSelectComponent,
        CerrixIconViewerComponent,
        CerrixUrlInputComponent,
        CerrixGridEditorComponent,
        CerrixWorkflowComponent,
        CerrixTreeViewerComponent,

        CerrixVideoPlayerComponent,
        CerrixLogoComponent,
        CerrixEditorComponent,
        CerrixEditorViewerComponent,
        CerrixIconPickerComponent,
        CerrixImagePickerComponent,
        CerrixRiskMatrixComponent,
        CerrixRiskmatrixSummaryComponent,
        CerrixEditorPopupComponent,
        CellRendererPipe,
        ContextmenuComponent,

        ExpandableListComponent,
        CerrixCustomFieldsEditorComponent,
        CerrixTextfieldComponent,
        CerrixTextAreaComponent,
        CerrixRecurrenceComponent,
        CerrixAnimatedIllustrationComponent,
        CerrixCollapsingBoxComponent,
        CerrixDocumentInfoBoxViewComponent,
        CerrixBannerCardComponent,
        CerrixWorkflowHistoryGraphComponent,
        CerrixTextareaWithAutohideComponent,
        CerrixTextfieldWithAutohideComponent,
        DocumentManagerGenerateComponent,
        KriDataGraphOverviewComponent,
        KriDataGraphComponent,
        CerrixSlideOverWopiComponent,
        DocumentManagerFolderComponent,
        DocumentManagerFolderDetailsComponent,

        AiSuggestionsPopupComponent,
        AiDescriptionGeneratorComponent,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        // ContextMenuModule,

        // Move external stuff to seperate module
        BrowserAnimationsModule,

        ToastrModule,
        AccordionModule,
        BsDropdownModule,
        PopoverModule,
        ModalModule,
        TabsModule,
        TooltipModule,
        SortablejsModule,
        BsDatepickerModule,
        TimepickerModule,
        GridsterModule,

        NgxMasonryModule,
        NgSelectModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MatFormFieldModule,

        DateFormatPipe,
        DateTimeFormatPipe,
        FilterPipe,
        FileSizePipe,
        ArrayFormatPipe,
        SafePipe,
        SortbyPipe,
        ReversePipe,

        MenuSystemDirective,
        FileDropDirective,
        DebounceInputEventDirective,
        ExcelColumnNameInputDirective,
        UserHeartbeatDirective,
        InputTooltipDirective,
        TextAreaAutoGrowDirective,

        DateInputComponent,
        DateFieldComponent,
        CerrixLoaderComponent,

        WorkspaceComponent,
        WorkspaceCellRendererComponent,
        WorkspaceAdvancedConfigComponent,
        WorkspacePresetManagementComponent,
        CerrixSelectTreeComponent,
        ComboBoxComponent,
        DocumentManagerComponent,
        DocumentManagerFolderComponent,
        DocumentManagerOldComponent,
        HyperlinkManagerComponent,
        GenericListManagerComponent,
        GenericGroupedListManagerComponent,
        GenericFormEditorComponent,
        GenericFieldEditorComponent,
        OrganizationRiskmatrixEditorComponent,
        RiskAppetiteSelectionComponent,
        SubListManagerComponent,
        FrameworkAssessmentScores,
        CustomFieldEditor,

        HistoryOverviewComponent,
        HistoryDetailComponent,
        DocumentManagerDetailsComponent,
        DocumentHistoryComponent,
        LinkableComponent,
        CheckboxComponent,
        LinkedMoiOverviewComponent,
        CerrixTableComponent,
        CerrixTableMultiLevelComponent,
        DetailOptionsComponent,
        RiskLinkableComponent,
        KriLinkableComponent,
        ControlLinkableComponent,
        FindingsLinkableComponent,
        EventLinkableComponent,
        FormLinkableComponent,
        DatamanagementLinkableComponent,
        ThirdPartyLinkableComponent,
        CerrixSelectComponent,
        CerrixParentChildSelectorComponent,
        CerrixWizardComponent,
        CerrixInviteComponent,
        CerrixSlideOverComponent,
        CerrixColorSelectComponent,
        CerrixIconViewerComponent,
        CerrixUrlInputComponent,
        CerrixGridEditorComponent,
        CerrixWorkflowComponent,
        CerrixTreeViewerComponent,

        CerrixVideoPlayerComponent,
        CerrixLogoComponent,
        CerrixEditorComponent,
        CerrixEditorViewerComponent,
        CerrixIconPickerComponent,
        CerrixImagePickerComponent,
        CerrixRiskMatrixComponent,
        CerrixRiskmatrixSummaryComponent,
        ContextmenuComponent,

        ExpandableListComponent,
        CerrixCustomFieldsEditorComponent,
        CerrixTextfieldComponent,
        CerrixTextAreaComponent,
        CerrixRecurrenceComponent,
        CerrixAnimatedIllustrationComponent,
        CerrixCollapsingBoxComponent,
        CerrixDocumentInfoBoxViewComponent,
        CerrixBannerCardComponent,
        CerrixWorkflowHistoryGraphComponent,
        CerrixTextareaWithAutohideComponent,
        CerrixTextfieldWithAutohideComponent,
        KriDataGraphComponent,
        KriDataGraphOverviewComponent,

        AiSuggestionsPopupComponent,
        AiDescriptionGeneratorComponent,

        LottieComponent,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateInputComponent,
            multi: true,
        },
        { provide: BaseTreeService, useClass: TreeSettingsService },
        { provide: BaseDatepickerService, useClass: DatepickerSettingsService },
        provideMomentDateAdapter(),
        provideLottieOptions({
            player: playerFactory,
        }),
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                CellRendererPipe,
                DateFormatPipe,
                DateTimeFormatPipe,
                DatePipe,
                FilterPipe,
                FileSizePipe,
                ArrayFormatPipe,
                SafePipe,
                SortbyPipe,
                ReversePipe,
                SharedDataService,
                PopupService,
                CerrixInviteDataService,
                CerrixPromptService,
                CerrixThemingService,
                WorkspacePresetService,
                WorkspaceCountService,
                IncidentService,
            ],
        };
    }
}
