import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "event-get-data-breach-and-moi-data-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class EventGetDataBreachAndMoiDataManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Data Breach and MoI Data API",
            httpMethod: "GET",
            path: "/eventdata/databreachmois",

            parameters: [
                {
                    name: "includeDeleted",
                    in: "query",
                    paramType: "boolean",
                    required: false,
                    example: "true | false",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        "data breach": [
                            {
                                "event Identifier": "string",
                                "event name": "string",
                                status: "string",
                                "internal breach identifier": "string",
                                "external breach identifier": "string",
                                "data breach organization": "string",
                                "date breach detected": "datetime",
                                "date breach occurred": "datetime",
                                "date end of breach": "datetime",
                                "authorities notified": "string",
                                "authorities notified date": "datetime",
                                "data breach responsible(s)": "string",
                                "data breach assessor(s)": "string",
                                "type(s) of breach": "string",
                                "nature of breach": "string",
                                "nature of incident": "string",
                                "preventative Measures": "string",
                                "description of breach": "string",
                                "cause of breach": "string",
                                "personal data": "string",
                                "special categories of personal data": "string",
                                "name data processor": "string",
                                "outsourced data processing": "string",
                                "sub data processor": "string",
                                "minimum number of persons involved": "integer",
                                "maximum number of persons involved": "integer",
                                "description persons involved": "string",
                                "number of data registers involved": "integer",
                                "persons in other EU countries involved": "string",
                                "person(s) Involved": "string",
                                "consequences of personal data breach": "string",
                                "consequences to CIA of Data": "string",
                                "follow up description": "string",
                                "data breach comments": "string",
                                "persons involved notified": "string",
                                "explanation for no notification": "string",
                                "means Of Communication": "string",
                                "explanation notification persons involved": "string",
                                "nr Of Persons Notified": 0,
                                "reason no notification": "string",
                                "date persons involved notified": "datetime",
                                "secondary notification": "string",
                                "secondary notification description": "string",
                                "data protection officer": "string",
                                "international authorities notified": "string",
                                "name of international authorities": "string",
                                "which EU Country": "string",
                                "crossborder Processing": "string",
                                "chamber Of Commerce Registration Nr": "string",
                                "name of the Organization": "string",
                                address: "string",
                                zipCode: "string",
                                city: "string",
                                "active In Which Sector": "string",
                            },
                        ],
                        moIs: [
                            {
                                "event Identifier": "string",
                                "event name": "string",
                                "moI Identifier": "string",
                                "moI Name": "string",
                                subject: "string",
                                status: "string",
                                priority: "string",
                                feasibility: "string",
                                progress: "integer",
                                "organization Responsible": "string",
                                responsible: "string",
                                delegate: "string",
                                "organization Auditor": "string",
                                auditor: "string",
                                "finding Description": "string",
                                "start Date": "datetime",
                                "date Created": "datetime",
                                "due Date": "datetime",
                                "initial Due Date": "datetime",
                                "implementation Score": "string",
                                recommendation: "string",
                                "management Response": "string",
                                "last Comment": "string",
                                "comment Date": "datetime",
                                "business Dimension Paths": "string",
                                "business Dimensions": "string",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
