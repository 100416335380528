import { CustomFieldType } from "@enums/customfields/CustomFieldType";
import { RendererType } from "@enums/RendererType";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { RendererConfig } from "@models/workspace/RendererConfig";

export class CustomFieldHelper {
    static expandCustomFields(
        rows: Object[],
        fieldName: string,
        standingData: IdNameCombination[],
        headerLookup: { [propName: string]: string },
        rendererConfig: RendererConfig[],
        nameFormatter?: (name: string) => string
    ) {
        if (!rows || rows.length === 0) {
            return rows;
        }

        // Add custom headers
        for (const customField of standingData) {
            const formattedName = nameFormatter
                ? nameFormatter(customField.Name)
                : customField.Name;

            headerLookup[fieldName + "_" + customField.ID] = formattedName;

            const customFieldModel = customField as CustomFieldStandingDataModel;
            if (customFieldModel && customFieldModel.fieldType == CustomFieldType.SingleSelect) {
                rendererConfig.push({
                    textColumn: fieldName + "_" + customField.ID,
                    actionColumn: fieldName + "_" + customField.ID + "_Color",
                    hideActionColumn: true,
                    type: RendererType.Score,
                });
            }
        }

        // Add custom fields to rows
        for (const row of rows) {
            const fieldValues: { id: number; value: string }[] = row[fieldName];
            for (const customField of standingData) {
                const fieldValue = fieldValues
                    ? fieldValues.find((f) => f.id == customField.ID)
                    : null;

                row[fieldName + "_" + customField.ID] =
                    fieldValue && fieldValue.value ? fieldValue.value : "";

                const customFieldModel = customField as CustomFieldStandingDataModel;
                if (
                    customFieldModel &&
                    customFieldModel.fieldType == CustomFieldType.SingleSelect
                ) {
                    if (fieldValue && fieldValue.value) {
                        const answer = customFieldModel.answerOptions.find(
                            (c) => c.Name === fieldValue.value
                        );
                        row[fieldName + "_" + customField.ID + "_Color"] = answer
                            ? answer.Color
                            : "";
                    } else {
                        row[fieldName + "_" + customField.ID + "_Color"] = "";
                    }
                }
            }

            delete row[fieldName];
        }
    }
}
