<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar" *ngIf="risk">
    <button
        class="btn btn-cerrix"
        (click)="save()"
        *ngIf="!permissions.readonly"
        data-uiid="button-save-risk"
    >
        <i class="fas fa-save"></i>
        <span class="d-none d-lg-inline">Save risk</span>
    </button>

    <span class="date-edited" *ngIf="risk.id > 0">
        Last save: {{ risk.lastSaveDate | dateTimeFormat }}
    </span>
</div>

<div
    class="datamanagement-screen-system"
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    data-uiid="risk-detail-page"
    *ngIf="riskLoaded"
>
    <div
        class="risk-screens"
        [attr.name]="'Risk'"
        menuicon="fas fa-exclamation-triangle"
        menusystemsubmenu
    >
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem menItemId="riskDetails">
            <risk-detail-main
                [risk]="risk"
                [parentForm]="parentForm"
                [permissions]="permissions"
                [impactScaleData]="impactScaleData"
                [customFieldData]="customFieldData"
                #riskDetail
            >
            </risk-detail-main>
        </div>
        <div
            *ngIf="risk.id > 0"
            name="Risk result overview"
            menuItemId="resultOverview"
            menuicon="fas fa-stream"
            menusystemitem
        >
            <risk-result-overview [risk]="risk" #riskResultOverviewComponent>
            </risk-result-overview>
        </div>
        <div
            *ngIf="risk.id > 0"
            class="risk-screens"
            [attr.name]="'Links'"
            menuicon="fas fa-link"
            menusystemsubmenu
        >
            <div
                name="Controls"
                [attr.menuItemId]="controlsMenuItemId"
                menuicon="fas fa-shield-alt"
                menusystemitem
            >
                <risk-controls
                    *ngIf="riskLoaded"
                    [model]="risk"
                    [disabled]="!permissions.canLinkControls"
                    [permissions]="permissions"
                    #riskControlsComponent
                >
                </risk-controls>
            </div>

            <div
                name="Events"
                [attr.menuItemId]="eventsMenuItemId"
                menuicon="fas fa-bolt"
                menusystemitem
            >
                <risk-events [model]="risk" #riskEventsComponent> </risk-events>
            </div>

            <div
                *ngIf="permissions.canLinkBim || permissions.canViewMoi"
                name="Measures of Improvement"
                menuicon="fas fa-tasks"
                [attr.menuItemId]="moisMenuItemId"
                menusystemitem
            >
                <risk-mois
                    [risk]="risk"
                    [permissions]="permissions"
                    [showAdvancedFilters]="true"
                    [activeFilters]="activeFilters"
                    #moisComponent
                >
                </risk-mois>
            </div>

            <div
                name="Key Risk Indicators"
                menuicon="fa-solid fa-gauge-high"
                menuItemId="kriMenuItemId"
                menusystemitem
            >
                <risk-kri [model]="risk" [permissions]="permissions" #kriComponent> </risk-kri>
            </div>

            <div
                name="Data processing"
                menuItemId="dataProcessing"
                menuicon="fas fa-database"
                menusystemitem
            >
                <risk-datamanagement [risk]="risk" #datamanagementComponent> </risk-datamanagement>
            </div>
            <div
                menuItemId="menuItemTp"
                name="Third party"
                menuicon="fas fa-handshake"
                *ngIf="permissions.canViewTP"
                menusystemitem
            >
                <risk-thirdparty #thirdpartyComponent [risk]="risk" [disabled]="true">
                </risk-thirdparty>
            </div>
            <div
                name="Documents"
                [attr.menuItemId]="documentsMenuItemId"
                menuicon="far fa-file"
                menusystemitem
                *ngIf="risk.id > 0"
            >
                <div *ngIf="!risk.Documents">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="risk.Documents">
                    <document-manager-old
                        [(Documents)]="risk.Documents"
                        [permissions]="permissions.documentPermissions"
                        (DocumentsChange)="documentsChanged($event)"
                        [DocumentTypeTargets]="targetModule"
                    ></document-manager-old>
                </div>
            </div>
            <div
                name="Hyperlinks"
                [attr.menuItemID]="hyperlinksMenuItemId"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="risk.id > 0"
            >
                <div *ngIf="!risk.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="risk.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="risk.Hyperlinks"
                        (HyperlinksChanged)="hyperlinksChanged($event)"
                        [TargetModule]="targetModule"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>

        <div
            name="History"
            menuicon="fas fa-history"
            menusystemitem
            menuItemId="history"
            *ngIf="risk.id > 0"
        >
            <app-history-overview
                #historyOverviewComponent
                [historyDataService]="riskDS"
                [Id]="risk.guid"
                [useOldHistory]="true"
            >
            </app-history-overview>
        </div>

        <div
            *ngIf="risk.id > 0"
            name="Options"
            menuItemId="options"
            menuicon="fas fa-check-double"
            menusystemitem
        >
            <detail-options
                moduleName="risk"
                [objectName]="risk ? risk.name : 'risk'"
                [allowDelete]="permissions.canDelete"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
