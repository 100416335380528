<div class="main-page-area">
    <div class="main-page-content">
        <eff-step-header
            [ngClass]="{ 'is-step-rejected': pageState.isRejected }"
            [controlGuid]="effectivenessModel.controlGuid"
            [hideSaveButton]="!stepPermissions.isEditAllowed"
            title="Upload evidence"
            (onShowInstructions)="toggleInstructions()"
            (onSaveChanges)="saveChanges(true)"
        >
        </eff-step-header>
        <author-message-box
            *ngIf="pageState.isRejected"
            [author]="pageState.rejectedBy"
            [authorLabel]="pageState.rejectedByRole"
            [message]="pageState.rejectionMessage"
        >
        </author-message-box>
        <eff-checkbox
            data-uiid="eff-test-upload-evidence-expand-collapse-all"
            class="expand-collapse-all"
            label="Open all"
            (modelChange)="expandCollapseAll($event)"
        ></eff-checkbox>
        <div class="main-grid d-flex flex-column">
            <div *ngFor="let viewSample of viewSamples">
                <div class="grid-header">
                    <div class="bold-text" data-uiid="eff-test-upload-evidence-grid-header">
                        Sample {{ viewSample.sample.name }}
                    </div>
                    <eff-inline-text-editor
                        label="Add sample remark"
                        [(model)]="viewSample.sample.remarks"
                        [disabled]="!stepPermissions.isEditSampleRemarkAllowed"
                        (complete)="saveRemarksChange(viewSample.sample)"
                    ></eff-inline-text-editor>
                </div>
                <eff-table
                    [dataSource]="viewSample.gridDataSource"
                    [columns]="displayedColumns"
                    [tableConfig]="tableConfig"
                    [expandedRowTemplate]="
                        pageState.isRejected ? rejectedExpandedTemplate : expandedTemplate
                    "
                >
                    <ng-template #rejectedExpandedTemplate let-item="item">
                        <div class="grid-expanded-row rejected-layout">
                            <div
                                class="table-row"
                                data-uiid="eff-test-upload-evidence-rejected-row"
                            >
                                <div class="table-cell documents">
                                    <documents-list
                                        *ngIf="!item.noEvidence || hasNoEvidenceAndDocuments(item)"
                                        class="documets-list-small-droparea"
                                        [documents]="item.documents"
                                        [canAdd]="stepPermissions.evidenceSaveAllowed"
                                        [canEdit]="stepPermissions.evidenceSaveAllowed"
                                        [canDelete]="stepPermissions.evidenceSaveAllowed"
                                        (documentsChange)="onDocumentsChanged()"
                                        data-uiid="eff-test-upload-evidence-rejected-documents-list"
                                    ></documents-list>
                                    <div
                                        class="documents-and-no-evidence-spacing"
                                        *ngIf="hasNoEvidenceAndDocuments(item)"
                                    ></div>
                                    <div
                                        *ngIf="
                                            visibleDocuments(item.documents).length == 0 ||
                                            hasNoEvidenceAndDocuments(item)
                                        "
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                noEvidenceTemplate;
                                                context: { item: item }
                                            "
                                        ></ng-container>
                                    </div>
                                </div>
                                <div class="table-cell tester-comment">
                                    <div class="comment">{{ item.commentTester }}</div>
                                </div>
                                <div class="table-cell tester-score">
                                    <div *ngIf="item.IsExpanded">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                testerScoreView;
                                                context: { item: item }
                                            "
                                        ></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #expandedTemplate let-item="item">
                        <div class="d-flex flex-column grid-expanded-row normal-layout">
                            <documents-list
                                *ngIf="!item.noEvidence"
                                [ngClass]="{
                                    'has-evidence': visibleDocuments(item.documents).length > 0
                                }"
                                [documents]="item.documents"
                                [canAdd]="stepPermissions.evidenceSaveAllowed"
                                [canEdit]="stepPermissions.evidenceSaveAllowed"
                                [canDelete]="stepPermissions.evidenceSaveAllowed"
                                (documentsChange)="onDocumentsChanged()"
                                data-uiid="eff-test-upload-evidence-documents-list"
                            ></documents-list>
                            <div *ngIf="visibleDocuments(item.documents).length == 0">
                                <ng-container
                                    *ngTemplateOutlet="noEvidenceTemplate; context: { item: item }"
                                ></ng-container>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template column-name="name" let-item="item">
                        <div class="d-flex flex-row gap-3 align-items-center">
                            <div
                                [ngClass]="{ 'sample-name-cell': item.isSample }"
                                class="regular-text"
                                data-uiid="eff-test-upload-evidence-name"
                            >
                                {{ item.isSample ? "Sample" : "Step" }} {{ item.name }}
                            </div>

                            <div
                                class="d-flex flex-row align-items-center gap-1"
                                *ngIf="visibleDocuments(item.documents).length > 0"
                            >
                                <eff-icon class="remark-icon" icon="attachment_outlined"></eff-icon>
                                <div>({{ visibleDocuments(item.documents).length }})</div>
                            </div>
                            <div *ngIf="item.noEvidence && item.noEvidenceReason">
                                <eff-icon class="remark-icon" icon="comment_outlined"></eff-icon>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template column-name="evidence" let-item="item">
                        <div
                            class="d-flex flex-row align-items-center status-cell"
                            data-uiid="eff-test-upload-evidence-status"
                        >
                            <div
                                class="status-bullet"
                                data-uiid="eff-test-upload-evidence-status-bullet"
                                [ngClass]="
                                    getEvidenceStatusClass(item, viewSample.gridDataSource.data)
                                "
                            ></div>
                            <div
                                class="regular-text"
                                data-uiid="eff-test-upload-evidence-status-text"
                            >
                                {{ getEvidenceStatusText(item, viewSample.gridDataSource.data) }}
                            </div>
                        </div>
                    </ng-template>

                    <ng-template column-name="testerComment" let-item="item">
                        <div *ngIf="!item.IsExpanded && item.commentTester">
                            <eff-icon class="remark-icon" icon="comment_outlined"></eff-icon>
                        </div>
                    </ng-template>

                    <ng-template column-name="score" let-item="item">
                        <div *ngIf="!item.IsExpanded">
                            <ng-container
                                *ngTemplateOutlet="testerScoreView; context: { item: item }"
                            ></ng-container>
                        </div>
                    </ng-template>
                </eff-table>
            </div>
        </div>

        <ng-template #testerScoreView let-item="item">
            <div class="d-flex flex-row align-items-center status-cell">
                <div class="status-bullet" [ngClass]="getScoreColor(item)"></div>
                <div>
                    {{ item.scoreName }}
                </div>
            </div>
        </ng-template>

        <div class="bottom-buttons-wrapper">
            <eff-button
                *ngIf="stepPermissions.evidenceSaveAllowed"
                data-uiid="eff-test-upload-evidence-save-changes"
                text="Finish uploading evidence"
                size="large-size"
                class="bottom-button upload-evidence-btn"
                (click)="completeEvidenceUpload()"
            ></eff-button>
        </div>
    </div>

    <div class="side-page-area">
        <eff-evidence-instructions-info-box
            [panelOpenState]="true"
            [samples]="samples"
            [instructions]="effectivenessModel.evidenceUploadInstructions"
        >
        </eff-evidence-instructions-info-box>
        <eff-test-instructions-info-box
            [panelOpenState]="false"
            [samples]="samples"
            [effectivenessModel]="effectivenessModel"
        >
        </eff-test-instructions-info-box>
        <eff-source-document-info-box
            [effectivenessModel]="effectivenessModel"
            [hideSampling]="false"
            [stepPermissions]="stepPermissions"
        ></eff-source-document-info-box>
        <eff-control-info-box [controlGuid]="effectivenessModel.controlGuid"></eff-control-info-box>
    </div>
</div>

<ng-template #noEvidenceTemplate let-item="item">
    <div class="d-flex flex-column no-evidence-section" *ngIf="item.isSample">
        <eff-checkbox
            [(model)]="item.noEvidence"
            [disabled]="!stepPermissions.evidenceSaveAllowed"
            label="No evidence"
            (modelChange)="onChanges()"
            data-uiid="eff-testing-no-evidence-checkbox"
        ></eff-checkbox>
        <div class="d-flex flex-column" *ngIf="item.noEvidence">
            <div class="label-text bold-text">No evidence reason</div>
            <eff-textarea
                class="no-evidence-textarea"
                [(model)]="item.noEvidenceReason"
                *ngIf="stepPermissions.evidenceSaveAllowed"
                (modelChange)="onChangesDebounce()"
            ></eff-textarea>
            <div *ngIf="!stepPermissions.evidenceSaveAllowed">
                {{ item.noEvidenceReason }}
            </div>
        </div>
    </div>
</ng-template>

<cerrix-slide-over #cerrixSlideOver>
    <ng-template #title>
        <div class="paragraph">
            <div class="header">How to upload evidence</div>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="paragraph">
            <div class="header">Uploading Evidence</div>
            <div class="content">
                Follow these steps to effectively upload evidence or indicate the unavailability of
                evidence for your test steps / samples.
            </div>
        </div>
        <div class="paragraph">
            <div class="header">1. Add evidence</div>
            <div class="content">
                <ol>
                    <li>
                        Select the Sample
                        <ol type="i">
                            <li>
                                Begin by choosing the specific sample for which you are adding
                                evidence.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Upload the document:
                        <ol type="i">
                            <li>Click on the "Add document" button, or</li>
                            <li>
                                Drag the document(s) into the box labeled “Drop documents here.”
                            </li>
                        </ol>
                    </li>
                    <li>
                        Provide additional information (optional):
                        <ol type="i">
                            <li>
                                Add the document type and a brief description in the corresponding
                                boxes to provide context or additional details regarding the
                                uploaded evidence.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Save changes:
                        <ol type="i">
                            <li>
                                Click on the "Save changes" button to ensure the evidence and any
                                entered information is saved.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div class="paragraph">
            <div class="header">2. In case of no available evidence</div>
            <div class="content">
                <ol>
                    <li>
                        Indicate no evidence:
                        <ol type="i">
                            <li>
                                Select the applicable sample and click on the “No Evidence for This
                                Sample” button if there is no available evidence for the selected
                                sample.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Reason no evidence:
                        <ol type="i">
                            <li>
                                A pop-up will appear prompting you to describe the reason for the
                                lack of evidence. Fill in the explanation and click “Confirm.”
                            </li>
                        </ol>
                    </li>
                    <li>
                        Save changes:
                        <ol type="i">
                            <li>Click on the “Save changes” button to save your input.</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div class="paragraph">
            <div class="header">3. Complete the evidence upload process</div>
            <div class="content">
                <ol>
                    <li>
                        Check sample status:
                        <ol type="i">
                            <li>
                                Ensure that every sample either has evidence uploaded or is marked
                                as having “No evidence”. If this not the case, you cannot finish the
                                evidence upload stage.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Finish the process:
                        <ol type="i">
                            <li>
                                The green “Finish evidence upload” button will become available once
                                all items are filled.
                            </li>
                            <li>
                                Click on this button to conclude the evidence upload stage and
                                proceed to the subsequent test stage.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    </ng-template>
</cerrix-slide-over>
