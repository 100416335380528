import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { MailMessageComponent } from "./pages/maintenance/notification-maintenance.component";
import { SentNotificationOverviewComponent } from "./pages/sent/overview/sentnotification-overview.component";
import { NotificationService } from "./service/notification.service";
import { FormsModule } from "@angular/forms";
import { MailMessageDetailComponent } from "./pages/mailmessages/detail/mailmessages-detail.component";
import { MailMessagesOverviewComponent } from "./pages/mailmessages/overview/mailmessages-overview.component";
import { NotificationOverviewComponent } from "./pages/notifications/overview/notification-overview.component";
import { NotificationDetailComponent } from "./pages/notifications/detail/notification-detail.component";
import { MailMessageService } from "./service/mailmessage.service";
import { SentMailService } from "./service/sentmail.service";
import { PeriodicNotificationOverviewComponent } from "./pages/periodic/overview/periodic-notification-overview.component";
import { PeriodicNotificationDetailComponent } from "./pages/periodic/detail/periodic-notification-detail.component";
import { PeriodicNotificationMoiService } from "./service/periodic-notification-moi.service";
import { PeriodicNotificationDetailMoiComponent } from "./pages/periodic/detail/pages/moi/periodic-notification-detail-moi.component";
import { PeriodicNotificationService } from "./service/periodic-notification.service";
import { NotificationSettingsComponent } from "./pages/settings/notification-settings.component";
import { NotificationSettingsService } from "./service/notification-settings.service";

@NgModule({
    declarations: [
        MailMessageComponent,
        SentNotificationOverviewComponent,
        MailMessageDetailComponent,
        MailMessagesOverviewComponent,
        NotificationOverviewComponent,
        NotificationDetailComponent,
        PeriodicNotificationOverviewComponent,
        PeriodicNotificationDetailComponent,
        PeriodicNotificationDetailMoiComponent,
        NotificationSettingsComponent,
    ],
    imports: [CommonModule, FormsModule, SharedModule],
})
export class MailMessagesModule {
    static forRoot(): ModuleWithProviders<MailMessagesModule> {
        return {
            ngModule: MailMessagesModule,
            providers: [
                NotificationService,
                PeriodicNotificationService,
                PeriodicNotificationMoiService,
                MailMessageService,
                SentMailService,
                NotificationSettingsService,
            ],
        };
    }
}
