import { Component, Input } from '@angular/core';
import { TabService } from '@services/tabs/TabService';
import { Pages } from '@constants/pages/Pages';

@Component({
  selector: 'moi-risk-summary',
  templateUrl: './moi-risk-summary.component.html',
  styleUrls: ['./moi-risk-summary.component.scss']
})
export class MoiRiskSummaryComponent {
  @Input() summaryModel: any;
  @Input() riskGuid: any;

  constructor(private tabservice: TabService, private pages: Pages) { }

  openRisk() {
      if (!this.riskGuid || !this.summaryModel.CanOpenParent) {
          return;
      }

      this.tabservice.generateTab(this.pages.RiskDetail, this.riskGuid);
  }
}
