import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DocumentMethods } from "@methods/DocumentMethods";
declare var $: any;

@Component({
    selector: "browse-files-button",
    templateUrl: "./browse-files-button.component.html",
    styleUrls: ["./browse-files-button.component.scss"],
})
export class EffBrowseFilesButtonComponent {
    @Input() text: string = "browse";
    @Input() allowedFileExtensions: string;
    @Output() onFilesDropped: EventEmitter<File[]> = new EventEmitter();

    @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

    openFileSelect() {
        $(this.fileInput.nativeElement).trigger("click");
    }

    updateFiles() {
        this.onFilesDropped.emit(this.fileInput.nativeElement.files);
    }
}
