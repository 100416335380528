<div class="fancy-loader" *ngIf="!formCategories"></div>

<div class="form-status-overview" *ngIf="formCategories">
    <div class="detail-page-action-bar">
        <input
            type="text"
            class="form-control form-control-sm"
            name="filterInput"
            [(ngModel)]="filter"
            (debounce-input-event)="filterTrigger()"
            placeholder="Search / filter invites"
            inputTooltip
        />
    </div>

    <div class="categories">
        <ng-container *ngFor="let category of formCategories">
            <div class="category-overview" *ngIf="!category.hidden">
                <div class="category-title">
                    {{ category.category }}
                </div>
                <div
                    class="status-overview"
                    *ngFor="let form of category.forms; let i = index"
                    [class.workflow-status-overview]="form.Workflow"
                >
                    <div [hidden]="filter && form._genericConfig._visibleRowCount === 0">
                        <div class="detail-page-card headers mb-0 row">
                            <div
                                class="detail-page-card-title col-{{ form.CanInvite ? '12' : '6' }}"
                            >
                                <div
                                    class="open-color me-2"
                                    [style.background-color]="
                                        form.IsOpenForEntries
                                            ? form.FormOutOfDateRange
                                                ? 'orange'
                                                : 'green'
                                            : 'red'
                                    "
                                    [matTooltip]="
                                        form.IsOpenForEntries
                                            ? form.FormOutOfDateRange
                                                ? 'Form outside daterange'
                                                : 'Form is open for entries.'
                                            : 'Form is closed for entries.'
                                    "
                                ></div>
                                {{ form.Title }}
                                <i
                                    class="fas fa-code-branch ms-2"
                                    *ngIf="form.Workflow"
                                    matTooltip="This is a worklfow form."
                                ></i>
                                <small class="text-muted ms-2" *ngIf="form.EndDate"
                                    >(End date: {{ form.EndDate | dateFormat }})</small
                                >
                            </div>
                            <div
                                class="detail-page-card-actions col-{{
                                    form.CanInvite ? '12' : '6'
                                }}"
                            >
                                <div class="left-items" *ngIf="form.CanInvite">
                                    <button
                                        class="btn btn-raised green"
                                        (click)="openRequiredInvite(form)"
                                    >
                                        <i class="far fa-envelope"></i>
                                        <span>Invite users</span>
                                    </button>
                                    <button
                                        class="btn btn-raised dark"
                                        (click)="openRemindInvite(form)"
                                        *ngIf="form._hasOpenInvites"
                                    >
                                        <i class="far fa-bell"></i>
                                        <span>Remind invitees</span>
                                    </button>
                                </div>

                                <div class="right-items">
                                    <button class="btn btn-raised" (click)="openOverview(form)">
                                        <span>All results</span>
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <generic-list-manager [config]="form._genericConfig"></generic-list-manager>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
