import { Component } from "@angular/core";
import { ManualPageType } from "@app/manual/models/manual-page-type";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "api-powerbi-usage",
    templateUrl: "./api-powerbi-usage.component.html",
    styleUrls: ["./api-powerbi-usage.component.scss"],
})
export class ApiPowerBIUsageComponent {
    apiUrl: string;

    constructor(private _tabService: TabService, private _pages: Pages) {
        this.apiUrl = window.location.origin + "/api";
    }

    openProfile() {
        this._tabService.generateTab(this._pages.Profile, null, null, null, "api-keys");
    }

    openReleaseNotes() {
        this._tabService.generateTab(this._pages.ReleaseNotes, null, null);
    }

    openApiUsageManual() {
        this._tabService.openManual(ManualPageType.ApiUsage);
    }

    openGetRiskDataManual() {
        this._tabService.openManual(ManualPageType.RiskDataApi);
    }
}
