<ng-container *ngIf="effectivenessModel && stepPermissions">
    <eff-workflow-status
        [effectivenessModel]="effectivenessModel"
        [workFlowSteps]="workFlowSteps"
    ></eff-workflow-status>

    <eff-detail-breadcrumb-info
        [effectivenessModel]="effectivenessModel"
    ></eff-detail-breadcrumb-info>

    <ng-container [ngSwitch]="currentPage">
        <ng-container *ngSwitchCase="pageTypes.UploadSourceDocuments">
            <eff-testing-upload-source-document
                class="eff-step-page"
                *ngIf="stepPermissions.canViewSourceDocuments"
                [cerrixTab]="cerrixTab"
                [effectivenessModel]="effectivenessModel"
                [stepPermissions]="stepPermissions"
                [pageState]="currentPageState"
            ></eff-testing-upload-source-document>
        </ng-container>
        <ng-container *ngSwitchCase="pageTypes.UploadEvidence">
            <eff-testing-upload-evidence
                class="eff-step-page"
                [cerrixTab]="cerrixTab"
                [effectivenessModel]="effectivenessModel"
                [stepPermissions]="stepPermissions"
                [pageState]="currentPageState"
            ></eff-testing-upload-evidence>
        </ng-container>
        <ng-container *ngSwitchCase="pageTypes.Test">
            <eff-testing-test
                class="eff-step-page"
                [cerrixTab]="cerrixTab"
                [effectivenessModel]="effectivenessModel"
                [stepPermissions]="stepPermissions"
                [pageState]="currentPageState"
            ></eff-testing-test>
        </ng-container>
        <ng-container *ngSwitchCase="pageTypes.Review">
            <eff-testing-review
                class="eff-step-page"
                [cerrixTab]="cerrixTab"
                [effectivenessModel]="effectivenessModel"
                [stepPermissions]="stepPermissions"
                [pageState]="currentPageState"
            ></eff-testing-review>
        </ng-container>
        <ng-container *ngSwitchCase="pageTypes.Completed">
            <eff-testing-completed
                class="eff-step-page"
                [cerrixTab]="cerrixTab"
                [effectivenessModel]="effectivenessModel"
                [stepPermissions]="stepPermissions"
            ></eff-testing-completed>
        </ng-container>
    </ng-container>
</ng-container>
