<div class="d-flex flex-column" data-uiid="new-eff-step-header">
    <div class="top-header-section d-flex flex-row justify-content-between">
        <span class="h1-title" data-uiid="new-eff-step-header-title">{{ title }}</span>
        <eff-button
            class="align-self-end link-button"
            icon="info_outlined"
            size="medium-size"
            text="Instructions"
            (onClick)="clickInstructions()"
            data-uiid="new-eff-step-header-instructions-button"
        ></eff-button>
    </div>
    <div class="bottom-header-section d-flex flex-row align-items-center">
        <eff-save-button
            [text]="saveButtonLabel"
            *ngIf="!hideSaveButton"
            (click)="onSave()"
            data-uiid="new-eff-step-header-save-button"
        >
        </eff-save-button>
        <div
            class="d-flex flex-column control-description-container"
            *ngIf="controlInfo$ | async as controlInfo"
            data-uiid="new-eff-step-header-control-information"
        >
            <span class="control-label bold-text">Control name</span>
            <span class="regular-text" data-uiid="new-eff-step-header-control-name">{{
                controlInfo.ControlName
            }}</span>
        </div>
    </div>
    <div
        class="warning-area"
        data-uiid="new-eff-step-header-warning-area"
        *ngIf="showNonOccurrenceWarningArea"
    >
        <h2 class="h2-title">Control didn't occur</h2>
        <ul>
            <li>The Control didn't occur in this test period.</li>
        </ul>
    </div>
</div>
