<eff-popup-header
    [title]="title"
    [dialogRef]="dialogRef"
    data-uiid="new-eff-add-documents-popup-header"
></eff-popup-header>
<div class="dialog-body" data-uiid="new-eff-add-documents-popup-dialog">
    <div *ngIf="documents.length > 0" class="d-flex flex-column">
        <div class="documents-container d-flex flex-column">
            <div
                *ngFor="let document of documents"
                class="document-container d-flex flex-row justify-content-between align-items-center"
                data-uiid="new-eff-add-documents-popup-document-list"
            >
                <div class="d-flex flex-row gap-2 align-items-center document">
                    <eff-icon icon="description_outlined"></eff-icon>
                    <div>{{ document.Name }}</div>
                </div>
                <eff-button-with-confirmation
                    label="delete"
                    icon="delete_outlined"
                    *ngIf="showDeleteButton() && document.CanDelete"
                    (onConfirmation)="onDelete(document)"
                >
                </eff-button-with-confirmation>
            </div>
        </div>
        <div class="controllers d-flex flex-column">
            <div class="d-flex flex-column controller-container" *ngIf="documentTypes">
                <div class="controller-label">Document Type</div>
                <eff-select
                    class="document-type-controller"
                    [options]="documentTypes"
                    [formController]="docTypeFormControl"
                    data-uiid="new-eff-add-documents-popup-document-type-select"
                ></eff-select>
            </div>
            <div class="d-flex flex-column controller-container">
                <div class="controller-label">Description</div>
                <eff-textarea
                    [formController]="docDescriptionFormControl"
                    data-uiid="new-eff-add-documents-popup-description-text-area"
                ></eff-textarea>
            </div>
        </div>
    </div>
</div>

<div class="dialog-footer">
    <eff-button
        class="green"
        [text]="addDocumentBtnLabel"
        *ngIf="showSaveButton"
        (click)="onApplyChanges()"
        data-uiid="new-eff-add-documents-popup-apply-button"
    ></eff-button>
</div>
