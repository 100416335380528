import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    ChangeDetectorRef,
    Input,
} from "@angular/core";
import { CustomFieldBase } from "../custom-field-base";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { GenericListManagerComponent } from "@app/shared/generic-managers/generic-list-manager/generic-list-manager.component";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { MatrixModel } from "@models/generic/MatrixModel";

@Component({
    selector: "organization-riskmatrix-editor",
    templateUrl: "./organization-riskmatrix-editor.component.html",
    styleUrls: ["./organization-riskmatrix-editor.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class OrganizationRiskmatrixEditorComponent extends CustomFieldBase implements OnInit {
    @Input() row: any;
    @ViewChild(GenericListManagerComponent) summaryManager: GenericListManagerComponent;

    cellSize = 60;
    cellSpacing = 2;

    summaryListConfig: GenericListConfig;

    constructor(private cd: ChangeDetectorRef, private toastr: ToastrService) {
        super();
    }

    ngOnInit() {
        if (!this.value) {
            this.value = <MatrixModel>{
                likelihoodSize: 5,
                impactSize: 5,
                colors: [],
                matrix: {},
            };
        }

        this.fixConfig();

        this.summaryListConfig = {
            name: "Summary Colors",

            allowAdd: true,
            allowEdit: true,
            allowDelete: true,

            hideRefreshButton: true,

            isSortable: true,

            fields: [
                {
                    fieldName: "Color",
                    fieldType: GenericListFieldType.ColorPicker,
                    unique: true,
                    required: true,
                    defaultValue: "#000000",
                },
            ],

            dataMethod: () => {
                return of(this.value.colors.map((color, i) => ({ ID: color, Color: color })));
            },

            onSortChange: (sortedData) => {
                this.value.colors = sortedData.map((x) => x.Color);
                this.emitChange();
            },

            add: async (row) => {
                this.value.colors.push(row.Color);
                this.emitChange();
                return true;
            },

            edit: async (row, uneditedRow) => {
                const index = this.value.colors.indexOf(uneditedRow.Color);
                this.value.colors[index] = row.Color;
                this.emitChange();

                setTimeout(() => {
                    this.summaryManager.activeRow = this.summaryManager.tableData.find(
                        (x) => x.Color === row.Color
                    );
                }, 0);

                return true;
            },

            preDeleteCheck: (row) => {
                const allowed = this.value.colors.length > 1;
                if (!allowed) {
                    this.toastr.warning(
                        "There must be at least one color available!",
                        "Cannot remove color."
                    );
                }
                return allowed;
            },

            delete: async (row) => {
                const index = this.value.colors.indexOf(row.Color);
                this.value.colors.splice(index, 1);
                this.emitChange();

                return true;
            },
        };
    }

    fixConfig() {
        if (this.value.colors.length === 0) {
            this.value.colors.push("#008f05");
        }

        if (!this.value.matrix) {
            this.value.matrix = {};
        }

        for (let yIndex = 0; yIndex < this.value.likelihoodSize; yIndex++) {
            if (!this.value.matrix[yIndex]) {
                this.value.matrix[yIndex] = [];
            }

            for (let xIndex = 0; xIndex < this.value.impactSize; xIndex++) {
                const colorPointer = this.value.matrix[yIndex][xIndex];
                if (!+colorPointer) {
                    this.value.matrix[yIndex][xIndex] = 0;
                }
            }
        }
    }

    matrixCellClick(y: number, x: number) {
        if (!this.summaryManager || !this.summaryManager.activeRow) {
            return;
        }
        this.value.matrix[y][x] = this.value.colors.indexOf(this.summaryManager.activeRow.Color);
        this.emitChange();
    }

    emitChange() {
        this.valueChange.emit(this.value);
        this.cd.detectChanges();
    }
}
