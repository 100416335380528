import { DocumentModel } from "@models/documents/documentmodel";
import { KriDataModel } from "./KriDataModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { IHasDocuments } from "@methods/DocumentMethods";

export class KriModel implements IHasDocuments {
    guid: string;
    id: number;
    baseID: number;
    modifiedById: number;
    modifiedBy: string;

    modifiedAt: Date;
    deletedAt: Date;

    organizationIds: number[] = [];
    businessDimensionIds: number[] = [];
    riskCatalogueIds: number[] = [];

    identifier: string;
    name: string;
    description: string;
    calculation: string;

    dataOwner: string;
    source: string;
    frequency: number;
    aggregate: number;
    measurement: string;
    greenThreshold: number;
    redThreshold: number;
    isKpi = false;
    graphType: number;

    comments: string;

    aggregateScore: string;
    aggregateScoreColor: string;

    documents: DocumentModel[] = [];
    Hyperlinks: HyperlinkModel[];
    datas: KriDataModel[] = [];
    riskIds: number[] = [];

    get Documents(): DocumentModel[] {
        return this.documents;
    }
}
