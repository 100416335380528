import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WopiDebugDataService } from "./services/wopi-debug-data.service";

@Component({
    selector: "wopi-debug",
    templateUrl: "./wopi-debug.component.html",
    styleUrls: ["./wopi-debug.component.scss"],
})
export class WopiDebugComponent implements OnInit {
    tab: TabModel;
    enabled: boolean;

    constructor(private _ds: WopiDebugDataService) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        this.enabled = await this._ds.enabled();
    }
}
