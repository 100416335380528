import { HttpResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { OverrideRiskFieldTypes } from "@app/risk/enums/OverrideRiskFieldTypes";
import { RiskCatalogueModel } from "@app/risk/models/RiskCatalogueModel";
import { RiskModel, RiskWrapper } from "@app/risk/models/RiskModel";
import { RiskPermissionModel } from "@app/risk/models/RiskPermissionModel";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { TargetModule } from "@enums/document/TargetModule";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { closeDirtyPageCheck, isObjectDirty, nameof, toPromise } from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { RiskControlsComponent } from "./risk-controls/risk-controls.component";
import { RiskDatamanagementComponent } from "./risk-datamanagement/risk-datamanagement.component";
import { RiskDetailMainComponent } from "./risk-detail-main/risk-detail-main.component";
import { RiskEventsComponent } from "./risk-events/risk-events.component";
import { RiskMoiComponent } from "./risk-mois/risk-mois.component";
import { RiskResultOverviewComponent } from "./risk-result-overview/risk-result-overview.component";
import { RiskConfigModel } from "@app/risk/models/risk-config-model";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { RiskThirdpartyComponent } from "./risk-thirdparty/risk-thirdparty.component";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";
import { RiskKriComponent } from "./risk-kri/risk-kri.component";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { ImpactScaleModel } from "@app/risk/models/ImpactScaleModel";

@Component({
    selector: "app-risk-detail",
    templateUrl: "./risk-detail.component.html",
    styleUrls: ["./risk-detail.component.scss"],
})
export class RiskDetailComponent implements OnInit {
    @ViewChild("riskDetail") riskDetail: RiskDetailMainComponent;
    @ViewChild("riskControlsComponent")
    riskControlsComponent: RiskControlsComponent;
    @ViewChild("riskEventsComponent") riskEventsComponent: RiskEventsComponent;
    @ViewChild("moisComponent") moisComponent: RiskMoiComponent;
    @ViewChild("kriComponent") kriComponent: RiskKriComponent;
    @ViewChild("datamanagementComponent")
    datamanagementComponent: RiskDatamanagementComponent;
    @ViewChild("thirdpartyComponent")
    thirdpartyComponent: RiskThirdpartyComponent;
    @ViewChild("historyOverviewComponent")
    historyOverviewComponent: HistoryOverviewComponent;
    @ViewChild("riskResultOverviewComponent")
    riskResultOverviewComponent: RiskResultOverviewComponent;

    tab: TabModel;
    id: string;
    risk: RiskModel;
    riskGuid: string;
    parentForm: FormGroup;
    riskMoiType = MoiTypes.RISK_Moi;

    permissions: RiskPermissionModel;

    impactScaleData: any = {};
    customFieldData: CustomFieldInfoModel = {};

    // dirty checks
    formDirty = false;
    documentsDirty = false;
    hyperlinksDirty = false;
    riskLoaded = false;
    tabIconBackup: string;
    backupModel: RiskModel;

    // Menu item ids
    moisMenuItemId = "mois";
    controlsMenuItemId = "controls";
    documentsMenuItemId = "documents";
    eventsMenuItemId = "events";
    hyperlinksMenuItemId = "hyperlinks";

    targetModule = TargetModule.Risk;

    activeFilters: SearchKeyword[] = [];

    constructor(
        public riskDS: RiskDataService,
        private route: ActivatedRoute,
        private _tabService: TabService,
        private _toastr: ToastrService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit(): Promise<void> {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;
        await this.setupInit();

        const defaultOrganizationFilter = <SearchKeyword>{
            col: "organizationId",
            searchValue: this.risk.organizationId + "",
            specific: true,
            compareType: ComparisonType["Is equal to"],
        };

        this.activeFilters.push(defaultOrganizationFilter);
    }

    async setupInit(): Promise<void> {
        const isExisting = this.id && isGuid(this.id);
        this.riskGuid = isExisting ? this.id : null;

        try {
            var permissions = await toPromise(this.riskDS.getPermissions(this.riskGuid));
            this.permissions = permissions;

            var risk = await toPromise(this.riskDS.getRisk(this.riskGuid));
            this.initRisk(risk);
        } catch (error) {
            this.tab.close(false);
        }

        this.tab.beforeClose = (checkOnly: boolean) => {
            const unsavedChanges = this.formDirty || this.documentsDirty || this.hyperlinksDirty;
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !unsavedChanges);
        };
    }

    initRisk(riskWrapper: RiskWrapper): void {
        const riskModel = riskWrapper.risk;
        const isExisting = riskModel.id > 0;

        this.tab.name = isExisting
            ? `(R) ${+riskModel.identifier} - ${riskModel.name}`
            : (this.tab.name = `(R) New risk`);

        this.tab.showLoader = false;

        riskModel.riskCatalogue = riskModel.riskCatalogue
            ? riskModel.riskCatalogue
            : new RiskCatalogueModel();

        if (!isExisting) {
            if (riskModel.organizationId == 0) {
                riskModel.organizationId = null;
            }
            this.setNewRiskValues(riskModel);
        }

        this.initFormgroup(riskWrapper);

        this.backupModel = JSON.parse(JSON.stringify(riskModel));
        this.risk = riskModel;
        this.riskLoaded = true;
    }

    setNewRiskValues(riskModel: RiskModel): void {
        const conf = <RiskConfigModel>this.tab.config;
        if (conf) {
            const orgID = conf.organizationId;
            if (orgID) {
                riskModel.organizationId = orgID;
            }

            const bdID = conf.businessId;
            if (bdID) {
                riskModel.businessDimensionIds = [bdID];
            }

            const name = conf.name;
            if (name) {
                riskModel.name = name;
            }

            const desc = conf.description;
            if (desc) {
                riskModel.description = desc;
            }

            const riskId = conf.processedDocumentRiskId;
            if (riskId) {
                riskModel.processedDocumentRiskId = riskId;

                const reasonUpdate = conf.reasonUpdate;
                if (reasonUpdate) {
                    riskModel.reasonUpdate = reasonUpdate;
                }
            }
        }
    }

    async initFormgroup(riskWrapper: RiskWrapper): Promise<void> {
        const riskModel = riskWrapper.risk;

        const sdImpactScales = riskWrapper.impactScales;
        const impactScaleFormArray = new FormArray([]);
        for (const impactScale of sdImpactScales) {
            const userImpactScale = riskModel.impactScales.find((x) => x.ID === impactScale.ID);

            const grossImpactValue = userImpactScale
                ? userImpactScale.grossImpactId
                : impactScale.grossImpactId;

            const netImpactValue = userImpactScale
                ? userImpactScale.netImpactId
                : impactScale.netImpactId;

            const impactScaleFormGroup = new FormGroup<any>({
                ID: new FormControl(impactScale.ID),
                grossImpactId: new FormControl(grossImpactValue),
                netImpactId: new FormControl(netImpactValue),
                leadingImpactScale: new FormControl(riskModel.leadingImpactScaleId),
                Name: new FormControl(impactScale.Name),
            });

            if (this.permissions.enableTargetRisk) {
                const targetImpactValue = userImpactScale
                    ? userImpactScale.targetImpactId
                    : impactScale.targetImpactId;

                impactScaleFormGroup.addControl(
                    nameof<ImpactScaleModel>("targetImpactId"),
                    new FormControl(targetImpactValue)
                );
            }

            // Store extra information from the impactScale in a custom object which is easier accessible in the front-end
            this.impactScaleData[impactScale.ID] = {
                name: impactScale.Name,
                items: impactScale.scaleItems,
            };

            if (!this.permissions.canScore) {
                impactScaleFormGroup.disable();
            }

            impactScaleFormArray.push(impactScaleFormGroup);
        }

        const customFieldsFormArray = new FormArray([]);
        const sdCustomFields = riskWrapper.customFields;

        for (const customField of sdCustomFields.filter((x) => x.visible)) {
            const currentField = riskModel.customFields.find(
                (x) => x.customFieldId === customField.customFieldId
            );
            const customFieldFormGroup = new FormGroup<any>({
                ID: new FormControl(currentField ? currentField.ID : 0),
                Name: new FormControl(customField.Name),
                customFieldId: new FormControl(customField.customFieldId),
                required: new FormControl(customField.required),
            });

            const valueProperty = getCustomFieldValueProperty(customField);
            customFieldFormGroup.addControl(
                valueProperty,
                new FormControl(
                    currentField ? currentField[valueProperty] : null,
                    customField.required ? [Validators.required] : []
                )
            );

            // Store extra information from the custom field in a custom object which is easier accessible in the front-end

            this.customFieldData[customField.customFieldId] = {
                Name: customField.Name,
                fieldType: customField.fieldType,
                required: customField.required,
                answerOptions: customField.answerOptions,
            };

            customFieldsFormArray.push(customFieldFormGroup);
        }

        this.parentForm = new FormGroup({
            // Risk detail
            name: new FormControl(riskModel.name, [Validators.required]),
            description: new FormControl(riskModel.description),
            organizationId: new FormControl(riskModel.organizationId, Validators.required),
            businessDimensionIds: new FormControl(riskModel.businessDimensionIds),
            frameworkDimensionIds: new FormControl(riskModel.frameworkDimensionIds),
            owner: new FormControl(riskModel.owner, Validators.required),
            approved: new FormControl({
                value: riskModel.approved,
                disabled: !this.permissions.canApprove,
            }),
            reviewed: new FormControl({
                value: riskModel.reviewed,
                disabled: !this.permissions.canReview,
            }),

            riskCatalogueId: new FormControl(riskModel.riskCatalogue.ID, Validators.required),
            definitionRiskCatalogue: new FormControl({
                value: riskModel.riskCatalogue.definitionRiskCatalogue,
                disabled: true,
            }),
            subtypeIds: new FormControl(riskModel.subtypeIds),
            riskAreaId: new FormControl(riskModel.riskAreaId),
            eventCategoryId: new FormControl(riskModel.eventCategoryId),
            confidential: new FormControl({
                value: riskModel.confidential,
                disabled: !this.permissions.canSetConfidential,
            }),
            isKeyRisk: new FormControl({
                value: riskModel.isKeyRisk,
                disabled: !this.permissions.canSetKeyRisk,
            }),
            applicable: new FormControl({
                value: riskModel.applicable,
                disabled: !this.permissions.canSetApplicable,
            }),

            // Risk scoring
            likelihoodId: new FormControl(
                { value: riskModel.likelihoodId, disabled: !this.permissions.canScore },
                Validators.required
            ),
            impactId: new FormControl(
                { value: riskModel.impactId, disabled: !this.permissions.canScore },
                Validators.required
            ),

            netLikelihoodId: new FormControl({
                value: riskModel.netLikelihoodId,
                disabled: !this.permissions.canScore,
            }),
            netImpactId: new FormControl({
                value: riskModel.netImpactId,
                disabled: !this.permissions.canScore,
            }),

            leadingImpactScaleId: new FormControl(riskModel.leadingImpactScaleId),
            impactScales: impactScaleFormArray,

            financialImpactGross: new FormControl(riskModel.financialImpactGross),
            financialImpactNet: new FormControl(riskModel.financialImpactNet),
            financialImpactTarget: new FormControl(riskModel.financialImpactTarget),

            potentialLossGrossMin: new FormControl(riskModel.potentialLossGrossMin),
            potentialLossGrossMax: new FormControl(riskModel.potentialLossGrossMax),
            potentialLossNetMin: new FormControl(riskModel.potentialLossNetMin),
            potentialLossNetMax: new FormControl(riskModel.potentialLossNetMax),
            potentialLossTargetMin: new FormControl(riskModel.potentialLossTargetMin),
            potentialLossTargetMax: new FormControl(riskModel.potentialLossTargetMax),

            // Quantitative scoring
            grossQuantitativeImpact: new FormControl({
                value: riskModel.grossQuantitativeImpact,
                disabled: !this.permissions.canScore,
            }),
            netQuantitativeImpact: new FormControl({
                value: riskModel.netQuantitativeImpact,
                disabled: !this.permissions.canScore,
            }),
            grossQuantitativeLikelihood: new FormControl({
                value: riskModel.grossQuantitativeLikelihood,
                disabled: !this.permissions.canScore,
            }),
            netQuantitativeLikelihood: new FormControl({
                value: riskModel.netQuantitativeLikelihood,
                disabled: !this.permissions.canScore,
            }),

            // Risk assessment
            appetiteComment: new FormControl({
                value: riskModel.appetiteComment,
                disabled: !this.permissions.canScore,
            }),
            riskTreatmentId: new FormControl(riskModel.riskTreatmentId),
            riskAssessmentId: new FormControl({
                value: riskModel.riskAssessmentId,
                disabled: !this.permissions.canScore,
            }),
            riskAssessmentComment: new FormControl({
                value: riskModel.riskAssessmentComment,
                disabled: !this.permissions.canScore,
            }),
            riskAssessmentDate: new FormControl({
                value: riskModel.riskAssessmentDate,
                disabled: !this.permissions.canScore,
            }),

            // Comments and custom fields
            comments: new FormControl(riskModel.comments),
            customFields: customFieldsFormArray,
        });

        if (this.permissions.showCauseField) {
            this.parentForm.addControl(
                nameof<RiskModel>("cause"),
                new FormControl(riskModel.cause)
            );
        }

        if (this.permissions.showEffectField) {
            this.parentForm.addControl(
                nameof<RiskModel>("effect"),
                new FormControl(riskModel.effect)
            );
        }

        if (this.permissions.enableTargetRisk) {
            this.parentForm.addControl(
                nameof<RiskModel>("targetLikelihoodId"),
                new FormControl(riskModel.targetLikelihoodId)
            );

            this.parentForm.addControl(
                nameof<RiskModel>("targetImpactId"),
                new FormControl(riskModel.targetImpactId)
            );
        }

        if (this.permissions.readonly) {
            this.parentForm.disable();
        } else {
            this.parentForm.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.parentForm, this.risk);
                this.checkDirty();
            });
        }

        FormValidationHelper.mapToModel(this.parentForm, riskModel);
    }

    checkDirty(): void {
        if (this.riskLoaded) {
            const propsToIgnore = [
                nameof<RiskModel>("Documents"),
                nameof<RiskModel>("startValidity"),
                nameof<RiskModel>("dateCreated"),
                nameof<RiskModel>("riskCatalogue"),
                nameof<RiskModel>("lastSaveDate"),
            ];

            // Ignore properties if they are forced by the risk catalogue
            if (this.riskDetail && this.riskDetail.selectedRiskCatalogue) {
                if (
                    this.riskDetail.selectedRiskCatalogue.overrideRiskName ===
                    OverrideRiskFieldTypes.Mandatory
                )
                    propsToIgnore.push(nameof<RiskModel>("name"));

                if (
                    this.riskDetail.selectedRiskCatalogue.overrideRiskDescription ===
                    OverrideRiskFieldTypes.Mandatory
                )
                    propsToIgnore.push(nameof<RiskModel>("description"));

                if (
                    this.riskDetail.selectedRiskCatalogue.overrideRiskArea ===
                    OverrideRiskFieldTypes.Mandatory
                )
                    propsToIgnore.push(nameof<RiskModel>("riskAreaId"));

                if (
                    this.riskDetail.selectedRiskCatalogue.overrideEventCategory ===
                    OverrideRiskFieldTypes.Mandatory
                )
                    propsToIgnore.push(nameof<RiskModel>("eventCategoryId"));

                if (
                    this.riskDetail.selectedRiskCatalogue.overrideSubTypes ===
                    OverrideRiskFieldTypes.Mandatory
                )
                    propsToIgnore.push(nameof<RiskModel>("subtypeIds"));
            }

            this.formDirty = isObjectDirty<RiskModel>(
                this.backupModel,
                this.risk,
                ...propsToIgnore
            );

            this.toggleTabDirty(this.formDirty);
        }
    }

    toggleTabDirty(dirty: boolean): void {
        if (!this.tab.menu) {
            return;
        }

        const tab = this.tab.menu.menuItems[0].children.find((x) => x.name === "Details");
        if (!tab) {
            return;
        }

        if (!this.tabIconBackup) {
            this.tabIconBackup = tab.iconClass;
        }

        tab.iconClass = this.tabIconBackup + (dirty ? " orange" : "");
    }

    async save(): Promise<void> {
        if (this.parentForm.valid) {
            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            await this.riskDS
                .storeRisk(this.risk)
                .toPromise()
                .then((value: HttpResponse<any>) => {
                    savingPrompt.close();
                    this._toastr.success("", "Update completed");
                    this.tab.id = value.body.toString();

                    this._tabService.listeners.triggerListener(
                        TabEventListenerType.OnSave,
                        this.tab,
                        this.tab.id
                    );

                    if (this.risk.processedDocumentRiskId > 0) {
                        this._tabService.refresh(this._pages.ProcessedDocumentDetail);
                    }

                    this.tab.refresh();
                })
                .catch((error) => {
                    savingPrompt.close();
                });
        } else {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }

    documentsChanged(documents: DocumentModel[]): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.documentsMenuItemId, true);
        this.documentsDirty = true;
    }

    hyperlinksChanged(hyperlinks: HyperlinkModel[]): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.hyperlinksDirty = true;
    }

    handleMenuItemClick(menuItem: TabMenuItem): void {
        if (!menuItem) {
            return;
        }

        switch (menuItem.menuItemId) {
            case "riskDetails": {
                break;
            }
            case "resultOverview": {
                this.riskResultOverviewComponent.load(false);
                break;
            }
            case this.controlsMenuItemId: {
                this.riskControlsComponent.load(this);
                break;
            }
            case this.eventsMenuItemId: {
                this.riskEventsComponent.load();
                break;
            }
            case this.moisMenuItemId: {
                this.moisComponent.load(false, this);
                break;
            }
            case "kriMenuItemId": {
                this.kriComponent.load(this, false);
                break;
            }
            case "dataProcessing": {
                this.datamanagementComponent.load(false);
                break;
            }
            case "menuItemTp": {
                this.thirdpartyComponent.load();
                break;
            }
            case this.documentsMenuItemId: {
                this.loadDocuments();
                break;
            }
            case this.hyperlinksMenuItemId: {
                this.loadHyperlinks();
                break;
            }
            case "history": {
                this.historyOverviewComponent.loadHistory();
                break;
            }
            case "options": {
                break;
            }
        }
    }

    delete(): void {
        this.riskDS.deleteRisk(this.risk.guid).subscribe((_) => {
            this._toastr.success(`Risk '${this.risk.name}' deleted`);

            this._tabService.refresh(this._pages.Risks);
            this.tab.close(false);
        });
    }

    private loadDocuments(): void {
        if (this.risk.Documents) return;

        if (this.id && isGuid(this.id.toString())) {
            this.riskDS.getDocuments(this.id.toString()).subscribe((documents) => {
                this.risk.Documents = documents;
            });
        } else {
            this.risk.Documents = [];
        }
    }

    private loadHyperlinks(): void {
        if (this.risk.Hyperlinks) return;

        if (this.id && isGuid(this.id.toString())) {
            this.riskDS.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.risk.Hyperlinks = hyperlinks;
            });
        } else {
            this.risk.Documents = [];
        }
    }
}
