<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    class="user-detail"
    data-uiid="user-detail"
    (change)="handleMenuItemClick($event)"
    *ngIf="!tab.showLoader && user"
>
    <div class="detail-page-action-bar" *ngIf="!hideSaveBar">
        <button
            class="btn btn-cerrix"
            (click)="save()"
            [disabled]="!canSave"
            data-uiid="save-user-btn"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save user</span>
        </button>

        <span class="date-edited" *ngIf="user.lastLogin"
            >Last login: {{ user.lastLogin | dateTimeFormat }}</span
        >
        <span class="date-edited" *ngIf="!isNewUserRequest && !user.lastLogin"
            >User did not login yet.</span
        >

        <div class="workflow-area">
            <span class="workflow-description" *ngIf="user.failedLogins > 0">
                <span *ngIf="user.dateLocked"
                    >Locked since <b>{{ user.dateLocked | dateTimeFormat }}</b> and
                    <b>{{ user.failedLogins }}</b> failed logins total.</span
                >
                <span *ngIf="!user.dateLocked"
                    >User currently has <b>{{ user.failedLogins }}</b> failed login{{
                        user.failedLogins > 1 ? "s" : ""
                    }}.</span
                >
            </span>
            <button
                class="btn btn-outline-secondary"
                *ngIf="user.dateLocked"
                (click)="unlockUser()"
                [disabled]="!permissions.canLockUnlock"
            >
                <i class="fas fa-lock-open"></i>
                <span class="d-none d-lg-inline ms-2">Unlock user</span>
            </button>
        </div>
    </div>

    <div
        class="user-screens full-height"
        [attr.name]="'User management'"
        menuicon="fas fa-user-cog"
        menusystemsubmenu
    >
        <div name="Profile" menuicon="fas fa-user" menusystemitem>
            <user-info [user]="user" [sd]="sd" [permissions]="permissions"></user-info>
        </div>
        <div
            data-uiid="userdetail-notification-settings"
            name="Notification Settings"
            menuItemId="notification-settings"
            menuicon="fas fa-bell"
            menusystemitem
            *ngIf="user"
        >
            <user-notification-settings
                [userModel]="user"
                [parentTab]="tab"
                [showSave]="false"
            ></user-notification-settings>
        </div>
        <div
            data-uiid="userdetail-roles"
            name="Roles"
            menuItemId="roles"
            menuicon="fas fa-user-tag"
            *ngIf="permissions.canAssignRoles"
            menusystemitem
        >
            <user-roles [user]="user" [sd]="sd" [permissions]="permissions"></user-roles>
        </div>
        <div
            name="Authentication"
            menuicon="fas fa-user-shield"
            *ngIf="permissions.canEditLogins"
            menusystemitem
        >
            <user-logins [user]="user" [sd]="sd" [permissions]="permissions"></user-logins>
        </div>
        <div
            class="full-height"
            name="API Keys"
            menuItemId="apikeytab"
            menuicon="fas fa-key"
            *ngIf="permissions.canViewApiKeys && !isNewUserRequest"
            menusystemitem
        >
            <api-key-overview [userGuid]="user.guid"></api-key-overview>
        </div>
        <div
            class="full-height"
            name="Audit"
            menuItemId="audittab"
            menuicon="fas fa-user-friends"
            *ngIf="permissions.canViewAudit && !isNewUserRequest"
            menusystemitem
        >
            <user-audit [user]="user" #audit></user-audit>
        </div>
        <div
            name="History"
            menuItemId="historytab"
            menuicon="fas fa-user-clock"
            *ngIf="permissions.canViewHistory && !isNewUserRequest"
            menusystemitem
        >
            <user-history [user]="user" #history></user-history>
        </div>
        <div
            name="Options"
            menuicon="fas fa-check-double"
            *ngIf="permissions.canDelete && !isNewUserRequest"
            menusystemitem
        >
            <detail-options
                moduleName="user"
                [objectName]="'user'"
                [allowDelete]="true"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
