export enum FormLinkableModule {
    Controls = 1,
    ControlsDescription = "Controls",
    AuditUniverse = 2,
    AuditUniverseDescription = "Audit Universe",
    Audit = 3,
    AuditDescription = "Audit",

    ThirdParty = 4,
    ThirdPartyDescription = "Third party",
}
