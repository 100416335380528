import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "../../../app/app.constants";
import { DocumentModel } from "@models/documents/documentmodel";
import { FileType } from "@enums/FileType";
import { ApiDataService } from "../ApiDataService";
import { DocumentsModel } from "@app/shared/models/DocumentsModel";
import { DocumentConfig } from "@models/documents/DocumentConfig";
import { Observable, firstValueFrom } from "rxjs";
import { DocumentBulkDeletedModel } from "@app/audit/shared/data-models/document-bulk-deleted-model";
import { DocumentAdminModel } from "@app/audit/shared/data-models/document-admin-model";
import { DocumentSharedModel } from "@app/audit/shared/data-models/document-shared-model";

@Injectable()
export class DocumentDataService extends ApiDataService {
    cachedDocumentConfig: Observable<DocumentConfig>;

    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "documents");
    }

    public getDocumentUrl(guid: string): string {
        return `${this.actionUrl}/${guid}/data`;
    }

    public GetImagesUrl(): string {
        return `${this.actionUrl}/images`;
    }

    public GetSharedFroalaUrl(): string {
        return `${this.actionUrl}/shared/froala`;
    }

    public GetAdminDocuments(): Observable<DocumentAdminModel[]> {
        const url = "/admin";

        return this.get<DocumentAdminModel[]>(url);
    }

    public GetBulkDeletedDocuments(): Observable<DocumentBulkDeletedModel[]> {
        return this.get<DocumentBulkDeletedModel[]>("/bulk-deleted");
    }

    public GetSharedDocuments(type?: FileType): Observable<DocumentSharedModel[]> {
        if (!type) {
            type = FileType.Undefined;
        }
        const url = `/shared?fileType=${type}`;

        return this.get<DocumentSharedModel[]>(url);
    }

    public GetImages(): Promise<any[]> {
        const url = `/images`;

        return this.get<any[]>(url).toPromise();
    }

    public GetDocumentConfig(refresh?: boolean): Observable<DocumentConfig> {
        if (refresh) {
            this.cachedDocumentConfig = null;
        }
        if (!this.cachedDocumentConfig) {
            const url = "/config";
            this.cachedDocumentConfig = this.get<DocumentConfig>(url);
        }

        return this.cachedDocumentConfig;
    }

    public GetDocumentHistory(guid?: string): Promise<DocumentModel[]> {
        const url = `/${guid}/history`;

        return this.get<DocumentModel[]>(url).toPromise();
    }

    public StoreSharedDocuments(model: DocumentsModel): Promise<{}> {
        const url = "/shared";

        return this.postWithDocuments(model, model.documents, url).toPromise();
    }

    public bulkDeleteDocuments(documentGuids: string[]): Promise<void> {
        return firstValueFrom(this.post({ documentGuids: documentGuids }, "/bulk-delete"));
    }
}
