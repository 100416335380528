import { OnInit, Output, EventEmitter, Input, ViewChild, Directive } from "@angular/core";
import { LinkableComponent } from "@app/shared/linkable/linkable.component";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { CerrixPromptService } from "../services/cerrix-prompt.service";
import { RendererConfig } from "@models/workspace/RendererConfig";

@Directive()
export abstract class ItemLinkableBase implements OnInit {
    @ViewChild(LinkableComponent, { static: true }) linkableComponent: LinkableComponent;

    @Output() linkedChange = new EventEmitter<any[]>();
    @Output() onLinkedClick: EventEmitter<any> = new EventEmitter();
    @Output() onBatchClicked: EventEmitter<any> = new EventEmitter();
    @Output() onOpenClicked: EventEmitter<any> = new EventEmitter();
    @Output() onAddClicked: EventEmitter<any> = new EventEmitter();
    @Output() onReloadClicked: EventEmitter<any> = new EventEmitter();

    @Input() linked: IHasGuid[];
    @Input() unlinked: IHasGuid[];
    @Input() displayValueProp: string;
    @Input() hideActions = false;
    @Input() hideFilter = false;
    @Input() orderByValueProperty = false;
    @Input() disabled = false;
    @Input() allowAddNew = false;
    @Input() rendererConfig: RendererConfig[];

    hasAccessChecks = {};
    accessCheckInProgress = false;

    abstract hasAccessToItem(guid: string): Observable<boolean>;
    abstract openTab(item: any, hasAccess: boolean);

    constructor(protected _toastr: ToastrService, protected _promptService: CerrixPromptService) {}

    ngOnInit() {
        if (this.linkedChange.observers.length > 0) {
            this.linkableComponent.linkedChange.subscribe((x) => {
                this.linkedChange.emit(x);
            });
        }

        if (this.onBatchClicked.observers.length > 0) {
            this.linkableComponent.onBatchClicked.subscribe((x) => {
                this.onBatchClicked.emit(x);
            });
        }

        if (this.onLinkedClick.observers.length > 0) {
            this.linkableComponent.onLinkedClick.subscribe((x) => {
                this.onLinkedClick.emit(x);
            });
        }

        if (this.onAddClicked.observers.length > 0) {
            this.linkableComponent.onAddClicked.subscribe((x) => {
                this.onAddClicked.emit(x);
            });
        }

        if (this.onReloadClicked.observers.length > 0) {
            this.linkableComponent.onReloadClicked.subscribe((x) => {
                this.onReloadClicked.emit(x);
            });
        }
    }

    openItem(item: IHasGuid) {
        const hasAccess = this.hasAccessChecks[item.guid];
        if (hasAccess == null && !this.accessCheckInProgress) {
            this.accessCheckInProgress = true;

            const prompt = this._promptService.loader("Checking rights, please wait...");

            this.hasAccessToItem(item.guid)
                .pipe(finalize(() => prompt.close()))
                .subscribe(
                    (x) => {
                        this.hasAccessChecks[item.guid] = x;

                        this.openTab(item, x);
                        this.accessCheckInProgress = false;
                    },
                    (error) => {
                        this.accessCheckInProgress = false;
                    }
                );
        } else {
            this.openTab(item, hasAccess);
        }
    }

    reloadClicked() {
        if (this.onReloadClicked.observers.length > 0) {
            this.onReloadClicked.emit();
        }
    }
}
