import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { getFormControl } from "@methods/CommonMethods";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { DataStructuresStandingData } from "@app/datamanagement/models/DataStructuresStandingData";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { EntityModel } from "@app/datamanagement/models/EntityModel";
import { SystemsDialogComponent } from "../../dialogs/systems-dialog/systems-dialog.component";
import { EntityRoles } from "@app/datamanagement/enums/EntityRoles";
import { SystemModel } from "@app/datamanagement/models/SystemModel";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import _ from "underscore";

@Component({
    selector: "entity-detail",
    templateUrl: "./entity-detail.component.html",
    styleUrls: ["./entity-detail.component.scss", "../../../../sass/datamanagement.scss"],
})
export class EntityDetailComponent implements OnInit {
    @Input() entityModel: EntityModel;
    @Input() standingData: DataStructuresStandingData;
    @Input() disabled: boolean;
    @Output() entityDeleted = new EventEmitter<EntityModel>();

    entityForm: FormGroup;

    entityRoleSelected = false;
    showSubprocessor = false;

    systemsRequiredSelected = false;
    showAddSystems = false;

    entityRoles: any[];
    systemsRequired: any[];
    systemOptions: IdNameCombination[];
    selectedSystems: IdNameCombination[];

    constructor(
        private _modalService: BsModalService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        // Get the entity info from the standing data
        const entityInfo = this.standingData.thirdparties.find((x) => x.ID === this.entityModel.id);

        const isNew = this.entityModel.isNew;

        const hasDataControllerFlag =
            EntityRoles.DataController === (entityInfo.EntityRoles & EntityRoles.DataController);
        const hasDataProcessorFlag =
            EntityRoles.DataProcessor === (entityInfo.EntityRoles & EntityRoles.DataProcessor);

        if (isNew) {
            if (hasDataControllerFlag && !hasDataProcessorFlag) {
                this.entityModel.role = EntityRoles.DataController;
            } else {
                this.entityModel.role = EntityRoles.DataProcessor;
            }
        }
        this.initDropDowns(hasDataControllerFlag, hasDataProcessorFlag);
        this.initFormValidation();
        this.loadSystems();

        this.changeRole(this.entityModel.role);

        if (!isNew) {
            this.changeSystemsRequired(this.entityModel.systemsRequired, null);
        }
    }

    initDropDowns(hasControllerFlag: boolean, hasProcessorFlag: boolean) {
        const entityRoles = [];
        if (hasControllerFlag) {
            entityRoles.push({
                id: EntityRoles.DataController,
                name: "Data controller",
            });
        }

        if (hasProcessorFlag) {
            entityRoles.push({
                id: EntityRoles.DataProcessor,
                name: "Processor",
            });
        }

        this.entityRoles = entityRoles;

        this.systemsRequired = [
            {
                id: true,
                name: "Yes",
            },
            {
                id: false,
                name: "No",
            },
        ];
    }

    initFormValidation() {
        this.entityForm = new FormGroup({
            role: new FormControl(this.entityModel.role),
            subProcessor: new FormControl(this.entityModel.subProcessor),
            systemsRequired: new FormControl(this.entityModel.systemsRequired),
        });

        if (this.disabled) {
            this.entityForm.disable();
        } else {
            // Only bind the change listener when the entity has more than one role
            const roleControl = getFormControl<EntityModel>(this.entityForm, "role");
            if (this.entityRoles.length > 1) {
                roleControl.valueChanges.subscribe((roleValue: number) => {
                    this.changeRole(roleValue);
                });
            } else {
                roleControl.disable();
            }

            const systemsRequiredControl = getFormControl<EntityModel>(
                this.entityForm,
                "systemsRequired"
            );
            systemsRequiredControl.valueChanges.subscribe((required: boolean) => {
                this.changeSystemsRequired(required, systemsRequiredControl);
            });
        }
    }

    changeRole(role: number) {
        if (!role) {
            return;
        }

        this.entityRoleSelected = true;
        this.showSubprocessor = role === EntityRoles.DataProcessor;
    }

    async changeSystemsRequired(systemsRequired: boolean, systemsRequiredControl: AbstractControl) {
        this.systemsRequiredSelected = true;

        if (systemsRequiredControl) {
            const hasSystems = this.entityModel.systems.length > 0;
            // If systems are connected and systems are not required, ask user for confirmation
            if (
                !systemsRequired &&
                hasSystems &&
                !(await this._promptService
                    .confirm(
                        "Remove systems",
                        "All connected systems will be removed, are you sure?"
                    )
                    .toPromise())
            ) {
                // Set value back to true
                systemsRequiredControl.setValue(true, { emitEvent: false });

                return;
            }

            // Check the hidden system
            if (
                systemsRequired &&
                hasSystems &&
                this.entityModel.systems[0].dataSubjects.length > 0 &&
                !(await this._promptService
                    .confirm(
                        "Remove data subjects",
                        "All connected data subjects will be removed, are you sure?"
                    )
                    .toPromise())
            ) {
                // Set value back to false
                systemsRequiredControl.setValue(false, { emitEvent: false });

                return;
            }

            // Add hidden systems to the entity
            if (!systemsRequired) {
                const hiddenSystem = <SystemModel>{
                    id: 0,
                    hidden: true,
                    dataSubjects: [],
                    dataSubjectIds: [],
                    dataSubjectsLoaded: true,
                };

                this.entityModel.systems = [hiddenSystem];
            } else {
                // Clear all systems from the entityModel
                this.entityModel.systems = [];
            }
        }

        this.showAddSystems = systemsRequired === true;
    }

    loadSystems() {
        if (!this.entityModel || !this.entityModel.id) {
            return;
        }

        // Get available systems for the entity from the standing data
        const sdEntity = this.standingData.thirdparties.filter((x) => x.ID === this.entityModel.id);
        if (sdEntity.length > 0) {
            this.systemOptions = sdEntity[0].Systems;
        } else {
            this.systemOptions = [];
        }
    }

    showAddSystemsDialog() {
        // Only show systems which have not been chosen for this entity
        const currentSystemIds = this.entityModel.systems.map((x) => x.id);

        const availableSystems = this.systemOptions.filter(
            (x) => currentSystemIds.indexOf(x.ID) === -1
        );
        const systemsClone = JSON.parse(JSON.stringify(availableSystems)) as IdNameCombination[];

        const config = <ModalOptions<SystemsDialogComponent>>{
            backdrop: "static",
            class: "datamanagement-dialog modal-md modal-lg",

            initialState: <SystemsDialogComponent>{
                entityModel: this.entityModel,
                systemOptions: systemsClone,
                standingData: this.standingData,
            },
        };

        this._modalService
            .show(SystemsDialogComponent, config)
            .content.afterClosed.subscribe((dialogResult: SystemModel[] | boolean) => {
                if (dialogResult) {
                    const addedSystems = dialogResult as SystemModel[];
                    this.entityModel.systems.addRange(addedSystems);
                }
            });
    }

    deleteSystem(systemModel: SystemModel) {
        const index = this.entityModel.systems.indexOf(systemModel);
        if (index >= 0) {
            this.entityModel.systems.splice(index, 1);
        }
    }

    // endregion

    deleteEntity() {
        this._promptService
            .confirm("Delete entity", "Are you sure you want to delete this entity?")
            .onConfirm()
            .subscribe(() => {
                this.entityDeleted.emit(this.entityModel);
            });
    }

    mapToModel() {
        FormValidationHelper.mapToModel(this.entityForm, this.entityModel);
    }
}
