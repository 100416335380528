<ng-template #basicInfo>
    <form [formGroup]="nameDateGroup" *ngIf="nameDateGroup">
        <div class="row header-row">
            <div class="col">
                <label>Basic information</label>
            </div>
        </div>
        <div class="row centered-row" *ngIf="!standingData">
            <div class="fancy-loader submodule"></div>
        </div>
        <div class="row centered-row" *ngIf="standingData">
            <div class="col-12 pb-5 text-start" [hidden]="isSummary">
                <div class="form-group">
                    <label>Select origin of testplan</label>
                    <ng-select
                        data-uiid="wizard-testplan-origin-select"
                        [(ngModel)]="effectivenessOrigin"
                        [ngModelOptions]="{ standalone: true }"
                        bindValue="ID"
                        bindLabel="Name"
                        [clearable]="false"
                        (change)="originChanged()"
                        [items]="[
                            { ID: 1, Name: 'New Test Plan' },
                            { ID: 2, Name: 'Copy from existing Test Plan' },
                            { ID: 3, Name: 'Copy from catalogue' }
                        ]"
                    >
                    </ng-select>
                </div>
                <div class="form-group" *ngIf="effectivenessOrigin == originTypes.existing">
                    <label>Select existing testplan to copy</label>
                    <ng-select
                        datau-iid="wizard-testplan-to-copy-select"
                        [(ngModel)]="originExistingID"
                        [ngModelOptions]="{ standalone: true }"
                        bindValue="ID"
                        bindLabel="Name"
                        [items]="existingTestPlans | async"
                        [loading]="!existingTestPlans && !originLoading"
                        [clearable]="false"
                        (change)="loadExistingEffectiveness()"
                    >
                    </ng-select>
                </div>
                <div class="form-group" *ngIf="effectivenessOrigin == originTypes.catalogue">
                    <label>Select catalogue to copy</label>
                    <ng-select
                        data-uiid="wizard-catalogue-to-copy-select"
                        [(ngModel)]="originCatalogueID"
                        [ngModelOptions]="{ standalone: true }"
                        bindValue="ID"
                        bindLabel="Name"
                        [items]="catalogues | async"
                        [loading]="!catalogues && !originLoading"
                        [clearable]="false"
                        (change)="loadCatalogue()"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col left-block">
                <div class="form-group">
                    <label>Name test plan *</label>
                    <cerrix-textfield
                        dataUiid="wizard-testplan-name-textfield"
                        errorLabel="Name test plan"
                        formControlName="name"
                    ></cerrix-textfield>
                </div>
                <div class="form-group">
                    <label>Test period</label>
                    <ng-select
                        data-uiid="wizard-test-period-select"
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="testPeriod"
                        [items]="standingData.TestPeriodData"
                        [loading]="!standingData.TestPeriodData"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col right-block">
                <div class="form-group">
                    <label>Start date *</label>
                    <date-input
                        data-uiid="wizard-start-date-dateinput"
                        formControlName="startDate"
                    ></date-input>
                    <div
                        class="red"
                        data-uiid="wizard-start-date-error-div"
                        *ngIf="nameDateGroup.controls.startDate.errors"
                    >
                        <p *ngIf="nameDateGroup.controls.startDate.errors.periodDates">
                            {{ nameDateGroup.controls.startDate.errors.periodDates }}
                        </p>
                    </div>
                </div>
                <div class="form-group">
                    <label>End date *</label>
                    <date-input
                        data-uiid="wizard-end-date-dateinput"
                        formControlName="endDate"
                    ></date-input>
                    <div
                        class="red"
                        data-uiid="wizard-end-date-error-div"
                        *ngIf="nameDateGroup.controls.endDate.errors"
                    >
                        <p *ngIf="nameDateGroup.controls.endDate.errors.startDateAfterEndDate">
                            {{ nameDateGroup.controls.endDate.errors.startDateAfterEndDate }}
                        </p>
                        <p *ngIf="nameDateGroup.controls.endDate.errors.periodDates">
                            {{ nameDateGroup.controls.endDate.errors.periodDates }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #sampleGeneration>
    <form [formGroup]="sampleGenerationGroup" *ngIf="sampleGenerationGroup">
        <div class="row header-row">
            <div class="col">
                <label>Sample generation</label>
            </div>
        </div>
        <div class="row centered-row" *ngIf="!standingData">
            <div class="fancy-loader submodule"></div>
        </div>
        <div class="row centered-row" *ngIf="standingData">
            <div class="col">
                <div class="form-group">
                    <label
                        >Sample generation method
                        <i
                            class="fas fa-info-circle ms-2"
                            matTooltip="Sampling generation can be done in several ways:

Automatic:
During the control test, the number of controls executed (the population size) and the number of controls to be tested (test samples) are entered by the source uploader. The system will randomly choose which controls to test.

Manual:
In this case not the system but the source uploader will choose which controls to test.

One Sample:
No source upload or sampling is required because the control is only executed once each period. There is only one sample possible. The usual upload source document and generate samples are skipped. The workflow will start at Upload evidence."
                        ></i
                    ></label>
                    <ng-select
                        data-uiid="wizard-sample-generation-method-select"
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="sampleGenerationMethod"
                        [items]="standingData.SampleGenMethData"
                        [loading]="!standingData.SampleGenMethData"
                        (change)="setDisabledControls()"
                        [clearable]="false"
                    >
                    </ng-select>
                </div>
                <div class="form-group">
                    <label>Reason manual sample generation</label>
                    <cerrix-textarea
                        dataUiid="wizard-reason-manual-sample-generation-textarea"
                        errorLabel="Reason manual sample generation"
                        formControlName="reasonManualSampleGeneration"
                    ></cerrix-textarea>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #personsInvolvedDueDates>
    <form [formGroup]="personsInvolvedGroup" *ngIf="personsInvolvedGroup">
        <div class="row header-row">
            <div class="col left-block">
                <label>Persons involved</label>
            </div>
            <div class="col right-block">
                <label>Due dates</label>
            </div>
        </div>
        <div class="row centered-row" *ngIf="!standingData">
            <div class="fancy-loader submodule"></div>
        </div>
        <div *ngIf="standingData">
            <div class="row centered-row">
                <div class="col left-block">
                    <div class="form-group" *ngIf="!showSourceDocumentUploader">
                        <label>No source document uploader required</label>
                    </div>
                    <div class="form-group" *ngIf="showSourceDocumentUploader">
                        <label>Source document uploader *</label>
                        <i
                            class="fas fa-info-circle ms-2"
                            title="Select an evidence uploader, tester or reviewer to be able to select a source document uploader."
                        ></i>
                        <ng-select
                            data-uiid="wizard-source-document-uploader-select"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="sourceDocumentUploaders"
                            [items]="standingData.SourceDocumentUploaderData"
                            [loading]="!standingData.SourceDocumentUploaderData"
                            [multiple]="true"
                            [closeOnSelect]="false"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col right-block">
                    <div class="form-group" *ngIf="showSourceDocumentUploader">
                        <label>Source document due date *</label>
                        <date-input
                            data-uiid="wizard-source-document-uploader-due-date-dateinput"
                            formControlName="sourceDocumentDueDate"
                        ></date-input>
                        <div
                            data-uiid="wizard-source-document-uploader-due-date-error-div"
                            class="red"
                            *ngIf="personsInvolvedGroup.controls.sourceDocumentDueDate.errors"
                        >
                            <p
                                *ngIf="
                                    personsInvolvedGroup.controls.sourceDocumentDueDate.errors
                                        .DueDate
                                "
                            >
                                {{
                                    personsInvolvedGroup.controls.sourceDocumentDueDate.errors
                                        .DueDate
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row centered-row">
                <div class="col left-block">
                    <div class="form-group">
                        <label>Evidence uploader(s) *</label>
                        <ng-select
                            data-uiid="wizard-evidence-uploader-select"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="evidenceUploaders"
                            [items]="standingData.EvidenceUploaderData"
                            [loading]="!standingData.EvidenceUploaderData"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="setSourceDocumentUploaderUsers()"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col right-block">
                    <div class="form-group">
                        <label>Evidence uploader due date *</label>
                        <date-input
                            data-uiid="wizard-evidence-uploader-due-date-dateinput"
                            formControlName="evidenceDueDate"
                        ></date-input>
                        <div
                            class="red"
                            data-uiid="wizard-evidence-uploader-due-date-error-div"
                            *ngIf="personsInvolvedGroup.controls.evidenceDueDate.errors"
                        >
                            <p *ngIf="personsInvolvedGroup.controls.evidenceDueDate.errors.DueDate">
                                {{ personsInvolvedGroup.controls.evidenceDueDate.errors.DueDate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row centered-row">
                <div class="col left-block">
                    <div class="form-group">
                        <label>Tester(s) *</label>
                        <ng-select
                            data-uiid="wizard-tester-select"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="testers"
                            [items]="standingData.TesterData"
                            [loading]="!standingData.TesterData"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="setSourceDocumentUploaderUsers()"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col right-block">
                    <div class="form-group">
                        <label>Tester due date *</label>
                        <date-input
                            data-uiid="wizard-tester-due-date-dateinput"
                            formControlName="testerDueDate"
                        ></date-input>
                        <div
                            class="red"
                            data-uiid="wizard-tester-due-date-error-div"
                            *ngIf="personsInvolvedGroup.controls.testerDueDate.errors"
                        >
                            <p *ngIf="personsInvolvedGroup.controls.testerDueDate.errors.DueDate">
                                {{ personsInvolvedGroup.controls.testerDueDate.errors.DueDate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row centered-row" *ngIf="!reviewerRequiredApplicationSetting">
                <div class="form-group">
                    <label
                        >Review step
                        <i
                            class="fas fa-info-circle ms-2"
                            matTooltip="When the review is switched off, the control test workflow will end after the control test step."
                        ></i
                    ></label>
                    <mat-slide-toggle
                        data-uiid="wizard-review-step-toggle"
                        name="reviewEnabled"
                        formControlName="reviewEnabled"
                        size="small"
                        color="primary"
                        class="ms-5"
                    >
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row centered-row">
                <div class="col left-block">
                    <div class="form-group" *ngIf="showReviewers">
                        <label>Reviewer(s) *</label>
                        <ng-select
                            data-uiid="wizard-reviewers-select"
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="reviewers"
                            [items]="standingData.ReviewerData"
                            [loading]="!standingData.ReviewerData"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            (change)="setSourceDocumentUploaderUsers()"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col right-block">
                    <div class="form-group" *ngIf="showReviewers">
                        <label>Reviewer due date *</label>
                        <date-input
                            data-uiid="wizard-reviewers-due-date-dateinput"
                            formControlName="reviewerDueDate"
                        ></date-input>
                        <div
                            class="red"
                            data-uiid="wizard-reviewers-due-date-error-div"
                            *ngIf="personsInvolvedGroup.controls.reviewerDueDate.errors"
                        >
                            <p *ngIf="personsInvolvedGroup.controls.reviewerDueDate.errors.DueDate">
                                {{ personsInvolvedGroup.controls.reviewerDueDate.errors.DueDate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #testInformation>
    <form [formGroup]="basicTestInfoGroup" *ngIf="basicTestInfoGroup">
        <div class="row header-row">
            <div class="col">
                <label>Test information</label>
            </div>
        </div>
        <div class="row centered-row" *ngIf="!standingData">
            <div class="fancy-loader submodule"></div>
        </div>
        <div class="row centered-row" *ngIf="standingData">
            <div class="col left-block">
                <div class="form-group" *ngIf="showNewScreens">
                    <label>Source document upload instructions</label>
                    <i
                        class="fas fa-info-circle ms-2"
                        title="These instructions are only shown in the new screens"
                    ></i>
                    <cerrix-textarea
                        dataUiid="wizard-source-document-upload-instructions-textarea"
                        errorLabel="Source document upload instructions"
                        formControlName="sourceDocumentUploadInstructions"
                    ></cerrix-textarea>
                </div>
                <div class="form-group" *ngIf="showNewScreens">
                    <label>Evidence upload instructions</label>
                    <i
                        class="fas fa-info-circle ms-2"
                        title="These instructions are only shown in the new screens"
                    ></i>
                    <cerrix-textarea
                        dataUiid="wizard-evidence-upload-instructions-textarea"
                        errorLabel="Evidence upload instructions"
                        formControlName="evidenceUploadInstructions"
                    ></cerrix-textarea>
                </div>
                <div class="form-group">
                    <label>Test instructions</label>
                    <cerrix-textarea
                        dataUiid="wizard-test-instructions-textarea"
                        errorLabel="Test instructions"
                        formControlName="testInstructions"
                    ></cerrix-textarea>
                </div>
            </div>
            <div class="col right-block">
                <div class="form-group">
                    <label>Linked standard *</label>
                    <ng-select
                        data-uiid="wizard-linked-standard-select"
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="linkedStandard"
                        [items]="standingData.LinkedStandardData"
                        [loading]="!standingData.LinkedStandardData"
                    >
                    </ng-select>
                </div>
                <div class="form-group">
                    <label>Quality aspects *</label>
                    <ng-select
                        data-uiid="wizard-quality-aspects-select"
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="qualityAspects"
                        [items]="standingData.QualityAspectData"
                        [loading]="!standingData.QualityAspectData"
                        [multiple]="true"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
                <div class="form-group">
                    <label>Test norm</label>
                    <cerrix-textarea
                        dataUiid="wizard-test-norm-textarea"
                        errorLabel="Test norm"
                        formControlName="testNorm"
                    ></cerrix-textarea>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #testSteps>
    <div class="row header-row">
        <div class="col">
            <label>Test steps</label>
        </div>
    </div>

    <generic-list-manager
        *ngIf="testStepConfig"
        [config]="testStepConfig"
        #testStepManager
    ></generic-list-manager>
</ng-template>

<ng-template #summary>
    <div class="row header-row" *ngIf="model && !effectivenessGuid">
        <div class="col">
            <label>Summary</label>
        </div>
    </div>
    <div class="row centered-row" *ngIf="model && !effectivenessGuid">
        <div class="col">
            <cerrix-checkbox
                dataUiid="wizard-save-as-catalogue-checkbox"
                label="Save Test Plan as catalogue?"
                [(ngModel)]="model.storeAsCatalogue"
            ></cerrix-checkbox>
            <div class="form-group" *ngIf="model.storeAsCatalogue">
                <label>Catalogue name *</label>
                <cerrix-textfield
                    dataUiid="wizard-save-as-catalogue-catalogue-name-textfield"
                    errorLabel="Catalogue name"
                    [(ngModel)]="model.storeAsCatalogueName"
                ></cerrix-textfield>
            </div>
        </div>
    </div>
</ng-template>

<cerrix-wizard
    [config]="wizardConfig"
    *ngIf="wizardConfig"
    [templates]="[basicInfo, sampleGeneration, personsInvolvedDueDates, testInformation, testSteps]"
    [summaryTemplate]="summary"
    #wizard
>
</cerrix-wizard>
