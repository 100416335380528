<div class="control-screen-system" menuSystem [cerrixTab]="tab" *ngIf="id && isLoaded">
    <div
        class="control-screens"
        name="Control execution"
        menuicon="fas fa-shield-alt"
        menusystemsubmenu
    >
        <div
            menuItemId="menuRecentUpcoming"
            name="Recent and upcoming"
            menuicon="fas fa-info-circle"
            menusystemitem
        >
            <generic-list-manager
                *ngIf="recentUpcomingConfig"
                [config]="recentUpcomingConfig"
            ></generic-list-manager>

            <control-detail-overview
                *ngIf="id && recentUpcomingConfig"
                [ControlGuid]="id"
                Label="Linked control"
                [HideOpenControlButton]="false"
                [HideControlDetails]="false"
                [HideDesignScore]="true"
                [HideEffectivenessScore]="true"
            ></control-detail-overview>
        </div>
        <div
            menuItemId="menuTaskSeries"
            name="Task Series"
            menuicon="fas fa-info-circle"
            menusystemitem
            *ngIf="
                permissions.canCreateTaskseries ||
                permissions.canEditTaskseries ||
                permissions.canDeleteTaskseries
            "
        >
            <generic-list-manager [config]="taskSeriesConfig"></generic-list-manager>
        </div>
        <div
            menuItemId="menuAllControlExecutions"
            name="All control executions"
            menuicon="fas fa-info-circle"
            menusystemitem
        >
            <generic-list-manager
                *ngIf="allControlExecutionsConfig"
                [config]="allControlExecutionsConfig"
            ></generic-list-manager>

            <control-detail-overview
                *ngIf="id && allControlExecutionsConfig"
                [ControlGuid]="id"
                Label="Linked control"
                [HideOpenControlButton]="false"
                [HideControlDetails]="false"
                [HideDesignScore]="true"
                [HideEffectivenessScore]="true"
            ></control-detail-overview>
        </div>
    </div>
</div>
