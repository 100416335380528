<div class="fancy-loader" *ngIf="!pageReady"></div>

<div class="detail-page-action-bar" *ngIf="pageReady">
    <button class="btn btn-cerrix" (click)="save()">
        <i class="fas fa-save" aria-hidden="true"></i>
        <span class="d-none d-lg-inline ms-2">Save</span>
    </button>
</div>

<div class="detail-page-card" [formGroup]="form" *ngIf="pageReady">
    <div class="detail-page-card-title">Edit mailmessage</div>
    <div class="detail-page-card-body padded form-group-styling invite-compose-area">
        <div class="form-group">
            <cerrix-checkbox
                fontsize="0.9em"
                label="Send mail"
                [(value)]="mailMessage.Enabled"
            ></cerrix-checkbox>
        </div>
        <div class="form-group">
            <label>Description</label>
            <div>
                <label id="description">{{ mailMessage.Description }}</label>
            </div>
        </div>
        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Subject"
                id="subject"
                formControlName="subject"
            ></cerrix-textfield>
        </div>
        <div class="form-group">
            <label>Keywords</label>
            <div>
                <button
                    class="keyword-button"
                    *ngFor="let keyword of keywords"
                    (click)="addKeyword(keyword)"
                >
                    {{ keyword }}
                </button>
            </div>
        </div>
        <div class="form-group">
            <label>Message</label>
            <cerrix-editor
                [class.cerrixEditor]="isEmpty"
                [value]="mailMessage.Message"
                (valueChange)="onChange($event)"
                #editor
            ></cerrix-editor>
        </div>
        <div class="form-group">
            <label class="col-form-label">Selected organizations</label>
            <i
                class="fas fa-info-circle ms-2"
                [matTooltip]="noOrganizationsSelectedDescription"
                position="right"
            ></i>
            <cerrix-select-tree
                [getDataMethod]="organizationRequest"
                formControlName="OrganizationIdList"
                [multiselect]="true"
            >
            </cerrix-select-tree>
        </div>
    </div>
</div>
