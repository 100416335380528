import { ApplicationSettings } from "@services/http/settings/application-settings";
import { Component, OnInit } from "@angular/core";
import { SettingsDataService } from "../../services/http/SettingsDataService";
import { SettingsItem } from "../../common/models/settings/SettingsItem";
import { ToastrService } from "ngx-toastr";
import { TabModel } from "../../common/models/generic/TabModels/TabModel";
import { GetIconByModuleString } from "@methods/Icons";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { SettingsStandingDataDropDownModel } from "@models/settings/SettingsStandingDataDropDownModel";
import { SettingsStandingDataDropDownItemModel } from "@models/settings/SettingsStandingDataDropDownItemModel";
import { AppSettingValueType } from "@models/settings/app-setting-value-type.enum";
import { setAppAccentColor } from "@methods/CommonMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";

@Component({
    selector: "app-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
    tab: TabModel;

    valueType = AppSettingValueType;
    groups: {
        module: string;
        icon: string;
        allowFilter: boolean;
        searchFilter: string;
        settings: SettingsItem[];
    }[];

    standingDataDropDownOptions: Record<string, IdNameCombination[]> = {};

    allMenuName = "All";
    activeTabName = this.allMenuName;

    constructor(
        private settingsService: SettingsDataService,
        private toastr: ToastrService,
        private standingDataService: StandingdataDataService
    ) {}

    ngOnInit() {
        if (!this.tab.showLoader) {
            this.tab.showLoader = true;
        }

        this.settingsService.getSettings().subscribe((settings) => {
            this.setup(settings);
            this.tab.showLoader = false;
        });
    }

    setup(settings: SettingsItem[]) {
        this.setSpecificTypes(settings);
        settings = settings.sort(this.sortTogglesToBottom);

        this.groups = [
            {
                module: this.allMenuName,
                icon: "fad fa-sliders-h",
                allowFilter: true,
                searchFilter: "",
                settings: settings,
            },
        ];

        var modules = settings.map((s) => s.ModuleName).distinct();
        modules.forEach((m) => {
            this.groups.push({
                module: m,
                icon: GetIconByModuleString(m),
                allowFilter: false,
                searchFilter: "",
                settings: settings.filter((s) => s.ModuleName == m),
            });
        });
    }

    filter(searchFilter: string, settings: SettingsItem[]) {
        const keywords = searchFilter.toLowerCase().split(" ");
        settings.forEach((setting) => {
            const settingName = setting.Name.toLowerCase();
            setting._hide = !keywords.some((kw) => settingName.indexOf(kw) >= 0);
        });
    }

    menuChange(tab: TabMenuItem) {
        // Somehow a event bubbles up, which causes tab to be a mousevent. So make sure we have a TabMenuItem here.
        if (tab instanceof TabMenuItem) {
            this.activeTabName = tab.name;
        }
    }

    UpdateDropdownAndSave(c: SettingsItem) {
        const options = c.DropdownOptions.map(function (i) {
            if (i === c.SelectedDropdownOption) {
                return "[" + i + "]";
            }
            return i;
        });

        c.TextValue = options.join(";");
        this.SaveChange(c);
    }

    UpdateSdDropdownAndSave(c: SettingsItem) {
        const updateModel = JSON.parse(c.SerializedOption) as SettingsStandingDataDropDownModel;
        updateModel.selectedOption = c.SelectedDropdownOption;
        if (c.selectedStandingDataDropdownId) {
            updateModel.options.map((x) => {
                x.selectedStandingDataId =
                    x.option === c.SelectedDropdownOption ? c.selectedStandingDataDropdownId : null;

                return x;
            });
        }

        c.SerializedOption = JSON.stringify(updateModel);

        // Only saved when this option has no extra options / an extra option has been selected
        if (
            !this.standingDataDropDownOptions[c.Name + "-" + c.SelectedDropdownOption] ||
            c.selectedStandingDataDropdownId > 0
        ) {
            this.SaveChange(c);
        }
    }

    UpdateSwitchAndSave(e, c: SettingsItem) {
        c.BoolValue = e.checked;
        this.SaveChange(c);
    }

    async Base64ImageChanged(ev, setting: SettingsItem) {
        try {
            const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            setting.SerializedOption = <string>await toBase64(ev.target.files[0]);
        } catch (error) {
            this.toastr.error(
                "Something went wrong while processing the image.",
                "Image not processed"
            );
        }
    }

    SaveColorSetting(colorItem: SettingsItem) {
        if (colorItem.ID === ApplicationSettings.AccentColor) {
            setAppAccentColor(colorItem.SerializedOption);
        }

        this.SaveChange(colorItem);
    }

    SaveChange(settingsItem: SettingsItem) {
        this.settingsService.updateSetting(settingsItem).subscribe(
            () => {
                this.toastr.success(`'${settingsItem.Name}' has been updated`, "Update succeeded");
            },
            (error) => {
                this.toastr.error(error.error.ExceptionMessage, "Update failed");
            }
        );
    }

    private setSpecificTypes(settings: SettingsItem[]) {
        settings.forEach((c: SettingsItem) => {
            // Json types have to be specified a bit more specific.
            if (c.ValueType == this.valueType.Json) {
                if (this.isStandingDataDropdownCheck(c)) {
                    this.setStandingDataDropDown(c);
                    c.isSdDropDown = true;
                }
            } else if (c.ValueType == this.valueType.Dropdown) {
                this.setDropdownOptions(c);
            }
        });
    }

    private isStandingDataDropdownCheck(c: SettingsItem) {
        return c.SerializedOption && c.SerializedOption.indexOf("standingDataType");
    }

    private setDropdownOptions(c: SettingsItem) {
        const options = c.TextValue.split(";");
        c.DropdownOptions = options.map(function (i) {
            if (i[0] === "[" && i[i.length - 1] === "]") {
                c.SelectedDropdownOption = i.substring(1, i.length - 1);
                return c.SelectedDropdownOption;
            }
            return i;
        });
    }

    private setStandingDataDropDown(c: SettingsItem) {
        c.DropdownOptions = [];

        const standingDataOptionsModel = JSON.parse(
            c.SerializedOption
        ) as SettingsStandingDataDropDownModel;
        c.SelectedDropdownOption = standingDataOptionsModel.selectedOption;

        standingDataOptionsModel.options.forEach((item: SettingsStandingDataDropDownItemModel) => {
            c.DropdownOptions.push(item.option);
            if (!c.selectedStandingDataDropdownId && item.selectedStandingDataId > 0) {
                c.selectedStandingDataDropdownId = item.selectedStandingDataId;
            }

            if (item.standingDataType > -1) {
                this.standingDataService
                    .getAllByType(item.standingDataType, +item.selectedStandingDataId)
                    .subscribe(
                        (items: any) => {
                            this.standingDataDropDownOptions[c.Name + "-" + item.option] = items;
                        },
                        () => {
                            this.standingDataDropDownOptions[c.Name + "-" + item.option] = [];
                        }
                    );
            }
        });
    }

    private sortTogglesToBottom(left: SettingsItem, right: SettingsItem) {
        // If ValueTypes are the same, we will straight go down to comparing based on ID's
        if (left.ValueType != right.ValueType) {
            const toggle = AppSettingValueType.Bit;
            // If left is a toggle we push left down, since we already know right cannot be a toggle anymore (left valuetype != right valuetype)
            if (left.ValueType == toggle) {
                return 1;
            }

            // If right is a toggle we push left up.
            if (right.ValueType == toggle) {
                return -1;
            }
        }

        // If valuetypes were the same, or were not but also neither was a toggle, we just compare based on ID's.
        return left.ID < right.ID ? -1 : 1;
    }
}
