.audit-finding-linked-audit-info {
    generic-list-manager {
        .detail-page-card {
            margin: 0;

            .detail-page-card-title {
                font-size: 0.9em;
            }

            .detail-page-card-actions {
                display: none;
            }
        }
    }
}
