import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { Configuration } from "../../../app/app.constants";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { DataStructuresStandingData } from "@app/datamanagement/models/DataStructuresStandingData";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { ApiDataService } from "@services/http/ApiDataService";
import { DatamanagementPermissionModel } from "../models/DatamanagementPermissionModel";
import { ProcessingPurposeModel } from "../models/ProcessingPurposeModel";
import { ProcessingPurposeStandingData } from "../models/ProcessingPurposeStandingData";
import { DataRecipientModel } from "../models/DataRecipientModel";
import { SourceDeliveryModel } from "../models/SourceDeliveryModel";
import { DataStructureModel } from "../models/DataStructureModel";
import { ExpandedProcessingPurposeModel } from "../models/ExpandedProcessingPurposeModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { ConnectedSystemModel } from "@app/datamanagement/models/ConnectedSystemModel";
import { ConnectedDataStructureModel } from "@app/datamanagement/models/ConnectedDataStructureModel";
import { ConnectedDataSubjectModel } from "@app/datamanagement/models/ConnectedDataSubjectModel";
import { isGuid } from "@methods/uniqueMethods";
import { StandingDataType } from "@enums/StandingDataType";
import { EntityRoles } from "../enums/EntityRoles";
import { StructureValidationModel } from "@models/generic/StructureValidationModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { addParameterToUrl } from "@methods/CommonMethods";

@Injectable()
export class DatamanagementDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "datamanagement");
    }

    public getPermissions(ppGuid?: string): Observable<DatamanagementPermissionModel> {
        const url = ppGuid && ppGuid.length > 0 ? `/${ppGuid}/permissions` : "/null/permissions";
        return this.get<DatamanagementPermissionModel>(url);
    }

    public getProcessingPurpose(ppGuid: string): Observable<ProcessingPurposeModel> {
        const url = `/${ppGuid}`;
        return this.get<ProcessingPurposeModel>(url);
    }

    public getStandingDataByType(standingDataType: StandingDataType, selected: number[]) {
        let url = `/standingdatabytype/${standingDataType}`;
        url = addParameterToUrl(url, "selectedIds", selected);

        return this.get<any[]>(url);
    }

    public getDataSubjectsSDAsList(selected: number[]) {
        let url = `/dataSubjectsList`;
        url = addParameterToUrl(url, "selectedIds", selected);

        return this.get<any[]>(url);
    }

    public getEntitiesByRole(entityRole: EntityRoles, selected: number[]) {
        if (!selected) {
            selected = [];
        }

        let url = `/entitiesbytype/${entityRole}`;
        url = addParameterToUrl(url, "selectedIds", selected);

        return this.get<any[]>(url);
    }

    public getAll() {
        const url = this.actionUrl;
        return this.http.get<any[]>(url);
    }

    public getDataRecipients(ppGuid: string): Observable<DataRecipientModel[]> {
        if (ppGuid && ppGuid.length > 0) {
            const url = `/${ppGuid}/datarecipients`;
            return this.get<DataRecipientModel[]>(url);
        }

        return of([]);
    }

    public getSourceDelivery(ppGuid: string): Observable<SourceDeliveryModel[]> {
        if (ppGuid && ppGuid.length > 0) {
            const url = `/${ppGuid}/sourcedelivery`;
            return this.get<SourceDeliveryModel[]>(url);
        }

        return of([]);
    }

    public getSystems(processingPurposeID: number): Observable<ConnectedSystemModel[]> {
        const url = `/${processingPurposeID}/systems`;
        return this.get<any[]>(url);
    }

    public getDataSubjects(connectedSystemID: number): Observable<ConnectedDataSubjectModel[]> {
        const url = `/dataSubjects/${connectedSystemID}`;
        return this.get<any[]>(url);
    }

    public getDataStructures(
        connectedDataSubjectID: number
    ): Observable<ConnectedDataStructureModel[]> {
        const url = `/dataStructures/${connectedDataSubjectID}`;
        return this.get<any[]>(url);
    }

    public deleteProcessingPurpose(processingPurposeGuid: string): Observable<string[]> {
        const url = `/${processingPurposeGuid}`;
        return this.delete(url);
    }

    getDatastructureStandingData(selectedIds: number[]): Observable<DataStructuresStandingData> {
        let url = `/datastructurestandingdata`;
        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get<DataStructuresStandingData>(url);
    }

    public store<T>(itemToUpdate: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + "/store", itemToUpdate);
    }

    public getHistory(ppGuid: string, extraParams: string) {
        switch (extraParams) {
            case "detail":
                return this.getDetailsHistory(ppGuid);
            case "mds":
                return this.getMdsHistory(ppGuid);
            default:
                throw new Error("Tried to get history with unknown type: " + extraParams);
        }
    }

    public getHistoryChanges(ppGuid: string, mutationId: number, extraParams: string) {
        switch (extraParams) {
            case "detail":
                return this.getDetailsHistoryChanges(ppGuid, mutationId);
            case "mds":
                return this.getMdsHistoryChanges(ppGuid, mutationId);
            default:
                throw new Error("Tried to get history changes with unknown type: " + extraParams);
        }
    }

    public getDetailsHistory(ppGuid: string): Observable<HistoryModel[]> {
        const url = `/${ppGuid}/detailsHistory`;

        return this.get<HistoryModel[]>(url);
    }

    public getDetailsHistoryChanges(
        ppGuid: string,
        mutationId: number
    ): Observable<HistoryChange[]> {
        const url = `/${ppGuid}/detailsHistory/${mutationId}`;

        return this.get<HistoryChange[]>(url);
    }

    public getMdsHistory(ppGuid: string): Observable<HistoryModel[]> {
        const url = `/${ppGuid}/mdsHistory`;

        return this.get<HistoryModel[]>(url);
    }

    public getMdsHistoryChanges(ppGuid: string, mutationId: number): Observable<HistoryChange[]> {
        const url = `/${ppGuid}/mdsHistory/${mutationId}`;

        return this.get<HistoryChange[]>(url);
    }

    public validateOrganizationChange(
        model: ProcessingPurposeModel
    ): Observable<StructureValidationModel> {
        const url = `/validateorganizationchange`;

        return this.post<StructureValidationModel>(model, url);
    }

    public validateBusinessDimensionChange(
        model: ProcessingPurposeModel
    ): Observable<StructureValidationModel> {
        const url = `/validatebusinessdimensionchange`;

        return this.post<StructureValidationModel>(model, url);
    }

    public getOrganizationDependencies(model: ProcessingPurposeModel) {
        const url = `/organizationdependencies`;

        return this.post<ProcessingPurposeStandingData>(model, url);
    }

    public getBusinessDimensionDependencies(model: ProcessingPurposeModel) {
        const url = `/businessdimensiondependencies`;

        return this.post<ProcessingPurposeStandingData>(model, url);
    }

    public storeProcessingPurpose(
        model: ExpandedProcessingPurposeModel
    ): Observable<HttpEvent<Object>> {
        return this.postWithDocuments(model, model.Documents);
    }

    getDataStructureModel(guid: string): Observable<DataStructureModel> {
        const url = `/datastructure/${guid}`;
        return this.get<DataStructureModel>(url);
    }

    public getDocuments(ppGuid: string): Observable<DocumentModel[]> {
        const url = `/${ppGuid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(id: string | number): Observable<HyperlinkModel[]> {
        const url = `/${id}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public hasAccess(ppGuid: string) {
        if (isGuid(ppGuid)) {
            const url = `/hasaccess/${ppGuid}`;
            return this.get<boolean>(url);
        }

        return of(false);
    }
}
