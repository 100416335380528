import { Component, Input, OnInit } from "@angular/core";
import { isObservable, Observable } from "rxjs";
import { CerrixWorkflowConfig, CerrixWorkflowStep } from "../models/cerrix-workflow-config";

@Component({
    selector: "cerrix-workflow",
    templateUrl: "./cerrix-workflow.component.html",
    styleUrls: ["./cerrix-workflow.component.scss"],
})
export class CerrixWorkflowComponent implements OnInit {
    @Input() config: CerrixWorkflowConfig;
    steps: CerrixWorkflowStep[];

    ngOnInit() {
        this.init();
    }

    init() {
        const handler = (steps: CerrixWorkflowStep[]) => {
            this.steps = steps;
        };

        if (isObservable(this.config.dataMethod)) {
            (<Observable<CerrixWorkflowStep[]>>this.config.dataMethod).subscribe(handler);
        } else if (
            typeof this.config.dataMethod === "object" &&
            typeof this.config.dataMethod["then"] === "function"
        ) {
            (<Promise<CerrixWorkflowStep[]>>this.config.dataMethod).then(handler);
        } else {
            handler(this.config.dataMethod as CerrixWorkflowStep[]);
        }
    }

    onStepClick(step: CerrixWorkflowStep) {
        if (!this.config.onStepClick || step.disableClick) {
            return;
        }

        this.config.onStepClick(step);
    }
}
