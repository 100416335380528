<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-card" *ngIf="!tab.showLoader">
    <div class="detail-page-card-title">Personal calendar</div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="reloadData()">
            <i class="fas fa-sync"></i>
            <span>Refresh data</span>
        </button>
    </div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="row">
            <div class="col-auto">
                <div class="custom-calendar">
                    <bs-datepicker-inline
                        [(bsValue)]="selectedDate"
                        [bsConfig]="bsConfig"
                        [dateCustomClasses]="dateCustomClasses"
                        (bsValueChange)="setSelected()"
                    >
                    </bs-datepicker-inline>
                </div>
            </div>
            <div class="col" *ngIf="selectedDay">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">{{ selectedDay.name }}</div>
                    <div
                        class="detail-page-card-body cerrix-control-sm list-group list-group-flush"
                    >
                        <div
                            class="list-group-item list-group-item-action"
                            *ngFor="let item of selectedDay.items"
                            (click)="open(item)"
                        >
                            <div class="title">
                                <span class="name" [title]="item.name">
                                    {{ item.name }}
                                </span>

                                <small class="time-indication" [class.red]="item.overdue">
                                    {{ item.timeframe }}
                                </small>
                            </div>

                            <p class="description text-truncate" [title]="item.description">
                                {{ item.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
