export enum FormStructureType {
    Organization = 100,
    BusinessDimensions = 200,
    FrameworkDimensions = 300,
    Users = 500,

    EventCategory = 400,
    IncidentType = 600,
    Classification = 700,
    Effects = 800,
    ReputationImpact = 900,
    BusinessLine = 950,

    PersonalData = 1000,
    ConsequencesPersonalDataBreach = 1100,
    ConsequencesCia = 1200,
    NatureOfIncident = 1300,
    ReasonNoNofication = 1400,
    ActiveSector = 1500,
    BreachType = 1600,
    BreachCategory = 1700,
    PersonalDataSpecialCategories = 1800,
    PersonsInvolved = 1900,

    RiskCatalogue = 2000,

    Countries = 10000,
    Currency = 10100
}