import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { validateVideoSource } from "@methods/ValidationMethods";
import { RegexConstants } from "./../../../common/constants/RegexConstants";

enum VideoSourceTypes {
    Youtube = 1,
    Vimeo = 2,
}

@Component({
    selector: "cerrix-video-player",
    templateUrl: "./cerrix-video-player.component.html",
    styleUrls: ["./cerrix-video-player.component.scss"],
})
export class CerrixVideoPlayerComponent implements OnChanges {
    @Input() url: string;

    showVideo = false;
    showValidationMessage = false;
    loadTimeout: any;

    videoSourceTypes = VideoSourceTypes;
    embedSourceType: VideoSourceTypes;
    embedKey: string;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        const urlChange = changes["url"];
        if (urlChange && urlChange.previousValue != urlChange.currentValue) {
            this.validateAndLoadVideo(urlChange.currentValue);
        }
    }

    private validateAndLoadVideo(url: string) {
        this.showVideo = false;
        this.showValidationMessage = false;
        this.embedSourceType = null;
        this.embedKey = null;

        if (this.loadTimeout) {
            clearTimeout(this.loadTimeout);
            this.loadTimeout = null;
        }

        if (url && validateVideoSource(url)) {
            this.loadTimeout = setTimeout(() => {
                this.loadEmbedSettings(url);
                this.showVideo = true;
            }, 1000);
        } else {
            this.showValidationMessage = true;
        }
    }

    private loadEmbedSettings(url: string) {
        if (url.indexOf("youtube.com") >= 0 || url.indexOf("youtu.be") >= 0) {
            this.embedSourceType = VideoSourceTypes.Youtube;
            this.embedKey = this.getYoutubeVideoKey(url);
        } else if (url.indexOf("vimeo.com") >= 0) {
            this.embedSourceType = VideoSourceTypes.Vimeo;
            this.embedKey = this.getVimeoVideoKey(url);
        }
    }

    // https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
    private getYoutubeVideoKey(url: string): string {
        var match = RegExp(RegexConstants.youtubeRegex).exec(url);
        if (match && match[RegexConstants.youtubeRegexIdCaptureGroup].length == 11) {
            return match[RegexConstants.youtubeRegexIdCaptureGroup];
        } else {
            return "";
        }
    }

    // https://stackoverflow.com/questions/13286785/get-video-id-from-vimeo-url
    private getVimeoVideoKey(url: string): string {
        var match = RegExp(RegexConstants.vimeoRegex).exec(url);
        if (match && match[RegexConstants.vimeoRegexIdCaptureGroup].length > 0) {
            return match[RegexConstants.vimeoRegexIdCaptureGroup];
        } else {
            return "";
        }
    }
}
