import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditWorkflowDataService } from "@app/audit/services/audit-workflow-data.service";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditDetailStandingDataModel } from "@app/audit/shared/page-models/audit-detail-standing-data-model";
import { CerrixWorkflowConfig } from "@app/shared/models/cerrix-workflow-config";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "audit-detail-main",
    templateUrl: "./audit-detail-main.component.html",
    styleUrls: ["./audit-detail-main.component.scss"],
})
export class AuditDetailMainComponent implements OnInit {
    @Input() auditModel: AuditModel;
    @Input() formGroup: FormGroup;
    @Input() readonly: boolean;
    @Input() standingData: AuditDetailStandingDataModel;

    workflowConfig: CerrixWorkflowConfig;

    constructor(
        private _workflowDs: AuditWorkflowDataService,
        private _prompt: CerrixPromptService
    ) {}

    async ngOnInit() {
        this.createWorkflowConfig();
        if (this.readonly) {
            this.formGroup.disable();
        }
    }

    //#region public methods

    //#endregion public methods

    //#region private methods

    private createWorkflowConfig() {
        this.workflowConfig = null;
        if (!this.auditModel || !this.auditModel.guid) {
            return;
        }

        this.workflowConfig = <CerrixWorkflowConfig>{
            highlightBackground: true,
            dataMethod: this._workflowDs.getWorkflowGraph(this.auditModel.guid),

            // OA: During reuse of the cerrix-workflow component and fixing a bug this started showing up in the audit-detail-main component.
            // This was never implemented correctly and was hidden because of a bug so I commented it out, until we can figure out what to do with it.

            // onStepClick: (step: AuditWorkflowStepModel) => {
            //     this._prompt.prompt({
            //         maxWidth: "1000px",
            //         maxHeight: "700px",
            //         data: {
            //             title: step.stepName,
            //             fieldResult: step,
            //             fields: [
            //                 {
            //                     prettyName: "Planned finished",
            //                     fieldName: "plannedFinished",
            //                     fieldType: GenericListFieldType.Date,
            //                     editorWidth: 6,
            //                     isReadonly: this.readonly,
            //                 },
            //                 {
            //                     prettyName: "Actual finished",
            //                     fieldName: "actualFinished",
            //                     fieldType: GenericListFieldType.Date,
            //                     editorWidth: 6,
            //                     isReadonly: true,
            //                 },
            //                 {
            //                     prettyName: "Planned hours",
            //                     fieldName: "plannedHours",
            //                     fieldType: GenericListFieldType.Number,
            //                     editorWidth: 6,
            //                     isReadonly: this.readonly,
            //                 },
            //                 {
            //                     prettyName: "Completed hours",
            //                     fieldName: "completedHours",
            //                     fieldType: GenericListFieldType.Number,
            //                     editorWidth: 6,
            //                     isReadonly: this.readonly,
            //                 },
            //                 {
            //                     prettyName: "Documents",
            //                     fieldName: "documents",
            //                     fieldType: GenericListFieldType.Documents,
            //                     editorWidth: 12,
            //                     isReadonly: true,
            //                 },
            //                 {
            //                     prettyName: "Comments",
            //                     fieldName: "comments",
            //                     fieldType: GenericListFieldType.TextArea,
            //                     editorWidth: 12,
            //                     isReadonly: this.readonly,
            //                 },
            //             ],
            //             confirmButton: {
            //                 show: !this.readonly,
            //             },
            //         },
            //     });
            // },
        };
    }

    //#endregion private methods
}
