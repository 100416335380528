import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { ControlExportData } from "@app/controls/models/ControlExportData";
import { Observable } from "rxjs";

@Injectable()
export class ControlCopyEffectivenessDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls/testing/advanced/effectiveness/testplancopies");
    }

    public getWithGuids(guids: string[]): Observable<ControlExportData[]> {
        const formData = new FormData();
        formData.append("guids", JSON.stringify(guids));

        return this.post<ControlExportData[]>(formData);
    }

    public copyData(data: any[], newGroupName: string): Promise<void> {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        const url = "/copy?newGroupname=" + newGroupName;
        return this.post<void>(formData, url).toPromise();
    }
}
