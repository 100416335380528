@if (dataLoaded && detailsForm) {
<div [formGroup]="detailsForm" class="tabcontent-container">
    <div class="row-item">
        <cerrix-field-wrapper [required]="true" label="Reporting organization" type="secondary">
            <cerrix-tree-select
                placeholder="Select organizations"
                [multiple]="false"
                formControlName="organizationId"
                [treeList]="reportedOrganizations"
            ></cerrix-tree-select>
        </cerrix-field-wrapper>
        <cerrix-field-wrapper label="Incident type" type="secondary">
            <cerrix-autocomplete
                placeholder="Search..."
                formControlName="incidentTypeIds"
                [options]="incidentTypes"
                (ngModelChange)="onIncidentTypeChange.emit($event)"
            ></cerrix-autocomplete>
        </cerrix-field-wrapper>
    </div>
    <div class="row-item">
        <cerrix-field-wrapper label="Caused by" type="secondary">
            <cerrix-tree-select
                placeholder="Select organizations"
                [multiple]="true"
                formControlName="causedByOrganizationIds"
                [treeList]="causedByOrganizations"
            >
                <organization-icon
                    left-icon
                    [matTooltip]="'Internal organization'"
                    [matTooltipClass]="'cerrix-tooltip'"
                ></organization-icon>
            </cerrix-tree-select>

            <cerrix-autocomplete
                placeholder="Search..."
                formControlName="causedByThirdPartyIds"
                [options]="causedByThirdparties"
            >
                <thirdparty-icon
                    left-icon
                    [matTooltip]="'Third party'"
                    [matTooltipClass]="'cerrix-tooltip'"
                ></thirdparty-icon>
            </cerrix-autocomplete>
        </cerrix-field-wrapper>
        <cerrix-field-wrapper label="Affected" type="secondary">
            <cerrix-tree-select
                placeholder="Select organizations"
                [multiple]="true"
                formControlName="affectedOrganizationIds"
                [treeList]="affectedOrganizations"
            >
                <organization-icon
                    left-icon
                    [matTooltip]="'Internal organization'"
                    [matTooltipClass]="'cerrix-tooltip'"
                ></organization-icon>
            </cerrix-tree-select>
            <cerrix-autocomplete
                placeholder="Search..."
                formControlName="affectedThirdPartyIds"
                [options]="affectedThirdparties"
            >
                <button (click)="tooltip.show()">Show tooltip</button>
                <thirdparty-icon
                    left-icon
                    #tooltip="matTooltip"
                    [matTooltip]="'Third party'"
                    [matTooltipClass]="'cerrix-tooltip'"
                ></thirdparty-icon>
            </cerrix-autocomplete>
        </cerrix-field-wrapper>
    </div>
    <div class="row-item">
        <cerrix-field-wrapper label="Rootcause category" type="secondary">
            <cerrix-tree-select
                placeholder="Select rootcause category"
                [multiple]="true"
                formControlName="rootCauseCategoryIds"
                [treeList]="rootCauseCategories"
            ></cerrix-tree-select>
        </cerrix-field-wrapper>
        <cerrix-field-wrapper label="Classification" type="secondary">
            <cerrix-select
                placeholder="Select classification"
                formControlName="classificationId"
                [options]="classifications"
            ></cerrix-select>
        </cerrix-field-wrapper>
    </div>
    <div class="row-item">
        <cerrix-field-wrapper label="Rootcause" type="secondary">
            <cerrix-textarea formControlName="rootCause"></cerrix-textarea>
        </cerrix-field-wrapper>
    </div>
    <div class="row-item">
        <cerrix-field-wrapper label="Impact description" type="secondary">
            <cerrix-textarea formControlName="impact"></cerrix-textarea>
        </cerrix-field-wrapper>
    </div>
    <div class="row-item">
        <cerrix-field-wrapper label="Business dimensions" type="secondary">
            <cerrix-tree-select
                placeholder="Select business dimensions"
                [multiple]="true"
                formControlName="businessDimensionIds"
                [treeList]="businessDimensions"
            ></cerrix-tree-select>
        </cerrix-field-wrapper>
        <cerrix-field-wrapper label="Framework dimensions" type="secondary">
            <cerrix-tree-select
                placeholder="Select framework dimensions"
                [multiple]="true"
                formControlName="frameworkDimensionIds"
                [treeList]="frameworkDimensions"
            ></cerrix-tree-select>
        </cerrix-field-wrapper>
    </div>
    <div class="row-item">
        <cerrix-field-wrapper label="Internal identifier" type="secondary">
            <cerrix-input formControlName="internalIdentifier"></cerrix-input>
        </cerrix-field-wrapper>
        <div></div>
    </div>
</div>
} @else {
<div class="fancy-loader submodule"></div>
}
