<div class="detail-page-summary">
    <div class="row">
        <div class="col">
            <div class="property"><b>Audit id:</b> {{ stepModel.auditId }}</div>
            <div class="property"><b>Audit name:</b> {{ stepModel.auditName }}</div>
            <div class="property"><b>Fieldwork step:</b> {{ stepModel.name }}</div>
        </div>
        <div class="col">
            <div class="property"><b>Auditor(s):</b> {{ stepModel.auditorNames }}</div>
            <div class="property"><b>Reviewer(s):</b> {{ stepModel.reviewerNames }}</div>
        </div>
    </div>
</div>

<audit-fieldwork-step-comments
    *ngIf="stepModel.useReviewWorkflow"
    [auditGuid]="stepModel.auditGuid"
    [auditFieldworkStepGuid]="stepModel.guid"
    #comments
>
</audit-fieldwork-step-comments>

<div class="detail-page-card">
    <div class="detail-page-card-title">Details</div>
    <div class="detail-page-card-body padded form-group-styling row" [formGroup]="formGroup">
        <div class="col">
            <cerrix-textarea
                fieldLabel="Description"
                formControlName="description"
            ></cerrix-textarea>
        </div>
    </div>
</div>

<audit-fieldwork-step-execution-working-method [stepModel]="stepModel">
</audit-fieldwork-step-execution-working-method>

<div class="detail-page-card">
    <div class="detail-page-card-title">Observation</div>
    <div class="detail-page-card-body padded form-group-styling">
        <form [formGroup]="formGroup">
            <cerrix-textarea
                fieldLabel="Observation"
                formControlName="observation"
            ></cerrix-textarea>

            <div class="row">
                <div class="col left-block">
                    <div class="form-group col cerrix-textfield">
                        <label>Observation Score</label>
                        <cerrix-select
                            prettyName="Observation score"
                            [getDataMethod]="scores"
                            formControlName="observationScore"
                        ></cerrix-select>
                    </div>
                </div>
                <div class="col right-block">
                    <div class="form-group">
                        <label for="dateOccurredInput">Observation Date</label>
                        <date-input formControlName="observationDate"></date-input>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
