import { Component, OnInit, Input } from "@angular/core";
import { FindingReportDetailsModel } from "@app/findingreport/shared/models";
import { FindingReportDataService } from "@app/findingreport/services";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";

@Component({
    selector: "linked-finding-report",
    templateUrl: "./finding-report-linked-summary.component.html",
    styleUrls: ["./finding-report-linked-summary.component.scss"]
})
export class FindingReportLinkedSummaryComponent implements OnInit {
    @Input() findingReportGuid: string;
    @Input() showOpenButton: false;

    detailsModel: FindingReportDetailsModel;

    constructor(
        private findingReportService: FindingReportDataService,
        private tabService: TabService,
        private pages: Pages
    ) {}

    ngOnInit() {
        if (this.findingReportGuid) {
            this.findingReportService.getFindingReport(this.findingReportGuid).subscribe(findingReport => {
                this.detailsModel = findingReport;
            });
        }
    }

    openFindingReport() {
        this.tabService.generateTab(
            this.pages.ImDetail,
            this.detailsModel.guid,
            `(F) ${+this.detailsModel.identifier} - ${this.detailsModel.name}`
        );
    }
}
