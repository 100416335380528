import { Injectable } from "@angular/core";

export class ActionShortcut {
    private constructor(public code: string, public ctrl: boolean, public shift: boolean) {}

    static Create(code: string, ctrl?: boolean, shift?: boolean) {
        return new ActionShortcut(code, ctrl, shift);
    }

    getString() {
        const modifiers = [];

        if (this.ctrl) {
            modifiers.push("ctrl");
        }

        if (this.shift) {
            modifiers.push("shift");
        }

        return `${modifiers.any() ? modifiers.join("+") + "+" : ""}${this.code}`;
    }

    toKeyString() {
        const modifiers = [];

        if (this.ctrl) {
            modifiers.push("control");
        }

        if (this.shift) {
            modifiers.push("shift");
        }

        return `${modifiers.any() ? modifiers.join(".") + "." : ""}${this.code}`.toLowerCase();
    }
}

export interface ContextMenuItem {
    text?: string;
    icon?: string;
    divider?: boolean;
    subMenu?: ContextMenuItem[];
    enabled?: () => boolean;
    visible?: () => boolean;
    click?: () => void;
    shortcut?: ActionShortcut;
}

@Injectable({ providedIn: "root" })
export class MenuItemBuilder {
    private menuItems: ContextMenuItem[] = [];

    constructor() {}

    public appendItem(
        text: string,
        click: () => void,
        shortcut?: ActionShortcut,
        icon?: string,
        enabled?: () => boolean
    ): MenuItemBuilder {
        this.menuItems.push({ text, click, shortcut, icon, enabled });
        return this;
    }

    public appendContextMenuItem(contextMenuItem: ContextMenuItem): MenuItemBuilder {
        this.menuItems.push(contextMenuItem);
        return this;
    }

    public appendSubMenu(
        text: string,
        subMenu: ContextMenuItem[],
        enabled?: () => boolean,
        icon?: string
    ): MenuItemBuilder {
        this.menuItems.push({ text, icon, subMenu, enabled });
        return this;
    }

    public appendDivider(): MenuItemBuilder {
        this.menuItems.push({ divider: true });
        return this;
    }

    public build() {
        const menuItems = this.menuItems;
        this.menuItems = [];
        return menuItems;
    }
}
