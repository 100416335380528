import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { KriDataService } from "@app/kri/services/kri-data.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { getFormControl, getFormValue } from "@methods/CommonMethods";
import { KeyValue } from "@models/generic/KeyValuePair";
import { KriModel } from "@models/kri";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { tap } from "rxjs/operators";
import _ from "underscore";

@Component({
    selector: "kri-detail-main",
    templateUrl: "./kri-detail-main.component.html",
    styleUrls: ["./kri-detail-main.component.scss"],
})
export class KriDetailMainComponent implements OnInit {
    @Input() organizations: CerrixTreeItem[];
    @Input() businessDimensions: CerrixTreeItem[];
    @Input() mainFormGroup: FormGroup;

    loadingData = false;

    riskCataloguesLoading = true;
    frequenciesLoading = true;
    aggregationsLoading = true;
    graphTypesLoading = true;
    measurementsLoading = true;

    riskCatalogues: Promise<KeyValue<number, string>[]>;
    frequencies: Promise<KeyValue<number, string>[]>;
    aggregations: Promise<KeyValue<number, string>[]>;
    graphTypes: Promise<KeyValue<number, string>[]>;
    measurements: Promise<KeyValue<string, string>[]>;

    private originalOrganizationIds: number[] = [];

    get organizationIds(): number[] {
        return getFormValue<KriModel>(this.mainFormGroup, "organizationIds");
    }
    get businessDimensionIds(): number[] {
        return getFormValue<KriModel>(this.mainFormGroup, "businessDimensionIds");
    }
    get riskCatalogueIds(): number[] {
        return getFormValue<KriModel>(this.mainFormGroup, "riskCatalogueIds");
    }

    constructor(private _kriDS: KriDataService, private _promptService: CerrixPromptService) {}

    ngOnInit() {
        const organizationIds = this.organizationIds;
        const riskCatalogueIds = this.riskCatalogueIds;
        this.originalOrganizationIds = organizationIds;

        this.riskCatalogues = this._kriDS
            .getRiskCatalogues(organizationIds, riskCatalogueIds)
            .pipe(tap(() => (this.riskCataloguesLoading = false)))
            .toPromise();

        this.frequencies = this._kriDS
            .getFrequencies()
            .pipe(tap(() => (this.frequenciesLoading = false)))
            .toPromise();

        this.aggregations = this._kriDS
            .getAggregations()
            .pipe(tap(() => (this.aggregationsLoading = false)))
            .toPromise();

        this.graphTypes = this._kriDS
            .getGraphTypes()
            .pipe(tap(() => (this.graphTypesLoading = false)))
            .toPromise();

        this.measurements = this._kriDS
            .getMeasurements()
            .pipe(tap(() => (this.measurementsLoading = false)))
            .toPromise();
    }

    async organizationsChanged() {
        if (_.isEqual(_.sortBy(this.organizationIds), _.sortBy(this.originalOrganizationIds))) {
            return;
        }

        if (this.businessDimensionIds.length > 0 || this.riskCatalogueIds.length > 0) {
            const confirmed = await this._promptService
                .confirm(
                    "Organization change",
                    "Selected Business Dimensions and Risk Catalogues will be reset. Change organizations anyway?"
                )
                .toPromise();

            if (!confirmed) {
                getFormControl<KriModel>(this.mainFormGroup, "organizationIds").setValue(
                    this.originalOrganizationIds
                );
                return;
            }
        }

        this.originalOrganizationIds = this.organizationIds;
        getFormControl<KriModel>(this.mainFormGroup, "businessDimensionIds").setValue([]);
        const riskCatalogueIdsControl = getFormControl<KriModel>(
            this.mainFormGroup,
            "riskCatalogueIds"
        );
        riskCatalogueIdsControl.setValue([]);

        if (!this.organizationIds || this.organizationIds.length === 0) {
            this.businessDimensions = [];
            this.riskCatalogues = null;
            riskCatalogueIdsControl.disable();
            return;
        }

        riskCatalogueIdsControl.enable();
        this._kriDS
            .getBusinessDimensions(this.organizationIds, this.businessDimensionIds)
            .subscribe((businessDimensions) => {
                this.businessDimensions = businessDimensions;
            });

        this.riskCataloguesLoading = true;
        this.riskCatalogues = this._kriDS
            .getRiskCatalogues(this.organizationIds, this.riskCatalogueIds)
            .pipe(tap(() => (this.riskCataloguesLoading = false)))
            .toPromise();
    }
}
