import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "eff-input",
    templateUrl: "./eff-input.component.html",
    styleUrls: ["./eff-input.component.scss"],
})
export class EffInputComponent {
    @Input() model;
    @Input() type: string;
    @Input() label: string;
    @Input() formController: FormControl;
    @Input() disabled: boolean = false;
    @Output() modelChange: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.disableEnableFormControl();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["disabled"]) {
            this.disableEnableFormControl();
        }
    }

    disableEnableFormControl() {
        if (this.formController) {
            if (this.disabled) {
                this.formController.disable();
            } else {
                this.formController.enable();
            }
        }
    }

    onModelChanged() {
        this.modelChange.emit(this.model);
    }

    hasErrors() {
        if (this.formController) {
            return this.formController.errors != null || this.formController.errors != undefined;
        }
        return false;
    }
}
