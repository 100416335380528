<div class="link-page row" *ngIf="linked && unlinked" #page>
    <div class="linked-items col-6" [class.col-12]="!showLinkPanel">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Linked {{ typename }}</div>
            <div class="form-group" *ngIf="showLinkPanel">
                <div>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Filter"
                            (debounce-input-event)="filterText(true)"
                            [(ngModel)]="filterTextLinked"
                            *ngIf="!hideFilter"
                            inputTooltip
                        />
                        <div class="input-group-append">
                            <button
                                type="button"
                                class="btn btn-cerrix"
                                (click)="toggleSelection(false)"
                            >
                                Deselect all
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <cerrix-select-tree
                        [tree]="frameworkDimensionTree"
                        id="tpaWizardLinkedFD"
                        [multiselect]="true"
                        [maxLines]="2"
                        (onChangeIds)="filterFrameworkDimension($event, true)"
                    >
                    </cerrix-select-tree>
                </div>
            </div>
            <div class="detail-page-card-body" *ngIf="linked && linked.length > 0">
                <ul
                    class="list-group list-group-flush"
                    [sortablejs]="linked"
                    [sortablejsOptions]="sortingOptions"
                >
                    <li
                        class="list-group-item list-group-item-action py-2"
                        *ngFor="let item of linkedPage"
                    >
                        <div class="d-flex w-100 justify-content-between">
                            <h6
                                class="text-truncate me-2 mb-0"
                                [matTooltip]="item[displayValueProp]"
                            >
                                <i
                                    *ngIf="item.displayItems && item.displayItems.length > 0"
                                    (click)="expandItem(item); $event.stopPropagation()"
                                    class="far fa-{{
                                        item._isExpanded ? 'minus' : 'plus'
                                    }}-square group-expander"
                                ></i>
                                <i *ngIf="useIcons" class="{{ item.rowIcon }} ms-2 rowIcon"> </i>
                                {{ item[displayValueProp] }}
                            </h6>
                            <h6 class="group-item-actions mb-0">
                                <i
                                    *ngIf="!disabled && showLinkPanel"
                                    (click)="toggleItem(item, false, true)"
                                    class="fas fa-arrow-right ms-2"
                                ></i>
                            </h6>
                        </div>
                        <div
                            class="item-expand"
                            *ngIf="
                                item._isExpanded &&
                                item.displayItems &&
                                item.displayItems.length > 0
                            "
                        >
                            <table class="cerrix-table bordered-columns">
                                <tbody>
                                    <tr *ngFor="let prop of item.displayItems">
                                        <td>
                                            <b>{{ prop.Header }}</b>
                                        </td>
                                        <td class="text-truncate">
                                            <workspace-cell
                                                [configs]="rendererConfig"
                                                [row]="item.displayItemRow"
                                                [header]="prop.Header"
                                            >
                                            </workspace-cell>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="detail-page-footer">
                <div class="list-empty-text" *ngIf="!linkedPage || linkedPage.length == 0">
                    <span *ngIf="!linked || linked.length == 0">
                        There are no linked {{ typename }}
                    </span>
                    <span *ngIf="linked && linked.length > 0">
                        No {{ typename }} match the filter
                    </span>
                </div>
                <div
                    class="pagination justify-content-center"
                    *ngIf="linked && linked.length > 0 && linkedPaginationPageCount > 1"
                >
                    <ul class="pagination">
                        <li class="page-item" [class.disabled]="linkedPaginationPage == 0">
                            <span
                                class="page-link"
                                (click)="setPaginationPage(linkedPaginationPage - 1, true)"
                            >
                                Previous
                            </span>
                        </li>

                        <li class="page-item disabled">
                            <span class="page-link">{{ linkedPaginationText }}</span>
                        </li>

                        <li
                            class="page-item"
                            [class.disabled]="linkedPaginationPage >= linkedPaginationPageCount - 1"
                        >
                            <span
                                class="page-link"
                                (click)="setPaginationPage(linkedPaginationPage + 1, true)"
                            >
                                Next
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="linkable-items col-6" [hidden]="!showLinkPanel">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Linkable {{ typename }}</div>
            <div class="form-group">
                <div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button
                                type="button"
                                class="btn btn-cerrix"
                                (click)="toggleSelection(true)"
                            >
                                Select all
                            </button>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Filter"
                            (debounce-input-event)="filterText(false)"
                            [(ngModel)]="filterTextUnlinked"
                            *ngIf="!hideFilter"
                            inputTooltip
                        />
                    </div>
                </div>

                <div>
                    <cerrix-select-tree
                        [tree]="frameworkDimensionTree"
                        id="tpaWizardUnlinkedFD"
                        [multiselect]="true"
                        [maxLines]="2"
                        (onChangeIds)="filterFrameworkDimension($event, false)"
                        [value]="selectedFdIdUnlinked"
                    >
                    </cerrix-select-tree>
                </div>
            </div>
            <div class="detail-page-card-body">
                <ul
                    class="list-group list-group-flush"
                    [sortablejs]="unlinked"
                    [sortablejsOptions]="sortingOptions"
                >
                    <li
                        class="list-group-item list-group-item-action py-2"
                        *ngFor="let item of linkablePage"
                    >
                        <div class="d-flex w-100 justify-content-between">
                            <h6
                                class="text-truncate me-2 mb-0"
                                [matTooltip]="item[displayValueProp]"
                            >
                                <i
                                    class="fas fa-arrow-left me-2"
                                    (click)="toggleItem(item, true, true)"
                                ></i>

                                <i
                                    *ngIf="item.displayItems && item.displayItems.length > 0"
                                    (click)="expandItem(item); $event.stopPropagation()"
                                    class="far fa-{{
                                        item._isExpanded ? 'minus' : 'plus'
                                    }}-square group-expander"
                                ></i>
                                <i *ngIf="useIcons" class="{{ item.rowIcon }} ms-2 rowIcon"> </i>

                                {{ item[displayValueProp] }}
                            </h6>
                        </div>
                        <div
                            class="item-expand"
                            *ngIf="
                                item._isExpanded &&
                                item.displayItems &&
                                item.displayItems.length > 0
                            "
                        >
                            <table class="cerrix-table bordered-columns">
                                <tbody>
                                    <tr *ngFor="let prop of item.displayItems">
                                        <td>
                                            <b>{{ prop.Header }}</b>
                                        </td>
                                        <td class="text-truncate">
                                            <workspace-cell
                                                [configs]="rendererConfig"
                                                [row]="item.displayItemRow"
                                                [header]="prop.Header"
                                            >
                                            </workspace-cell>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="detail-page-footer">
                <div class="list-empty-text" *ngIf="!linkablePage || linkablePage.length == 0">
                    <span *ngIf="!unlinked || unlinked.length == 0">
                        There are no {{ typename }} to link
                    </span>
                    <span *ngIf="unlinked && unlinked.length > 0">
                        No {{ typename }} match the filter
                    </span>
                </div>
                <div
                    class="pagination justify-content-center"
                    *ngIf="unlinked && unlinked.length > 0 && linkablePaginationPageCount > 1"
                >
                    <ul class="pagination">
                        <li class="page-item" [class.disabled]="linkablePaginationPage == 0">
                            <span
                                class="page-link"
                                (click)="setPaginationPage(linkablePaginationPage - 1, false)"
                            >
                                Previous
                            </span>
                        </li>

                        <li class="page-item disabled">
                            <span class="page-link">{{ linkablePaginationText }}</span>
                        </li>

                        <li
                            class="page-item"
                            [class.disabled]="
                                linkablePaginationPage >= linkablePaginationPageCount - 1
                            "
                        >
                            <span
                                class="page-link"
                                (click)="setPaginationPage(linkablePaginationPage + 1, false)"
                            >
                                Next
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
