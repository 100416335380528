import { Component, ViewEncapsulation } from "@angular/core";
import { UserAuthenticationService } from "../shared/user-authentication.service";
import { Subject } from "rxjs";

@Component({
    selector: "login-modal",
    templateUrl: "./login-modal.component.html",
    styleUrls: ["./login-modal.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LoginModalComponent {
    loginObservable$: Subject<never>;
    showModal = false;

    constructor(private authService: UserAuthenticationService) {
        this.authService.handleSessionExpiredEvent.subscribe((subj) => {
            this.loginObservable$ = subj;
            this.showModal = true;
        });
    }

    loginCompleted() {
        this.showModal = false;
        this.loginObservable$.next(<never>null);
        this.loginObservable$.complete();

        this.loginObservable$ = null;
    }
}
