<div class="form-group col cerrix-textfield">
    <label [for]="fieldName" *ngIf="fieldLabel && fieldLabel.length > 0" [class]="labelCss"
        >{{ fieldLabel }}
    </label>
    <input
        [type]="type"
        [id]="fieldId"
        [name]="fieldName"
        [(ngModel)]="value"
        [disabled]="disabled"
        [readonly]="readonly"
        [required]="required"
        [attr.data-uiid]="dataUiid"
        [placeholder]="placeholder"
        [class]="cssClass"
        [class.ng-valid]="valid"
        [class.ng-invalid]="!valid"
        [class.ng-pending]="control && control.pending"
        [class.ng-pristine]="control && control.pristine"
        [class.ng-dirty]="control && control.dirty"
        [class.ng-untouched]="control && control.untouched"
        [class.ng-touched]="control && control.touched"
        [style]="style"
        [attr.maxlength]="limitInput ? maxLength : null"
        (blur)="onBlur()"
        (keyup)="onKeyup()"
        inputTooltip
        #inputField
    />
</div>
