import { Component, Input, OnInit } from "@angular/core";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { FindingReportSummaryModel } from "@app/moi/shared/finding-report-summary-model";
import { MoiModel } from "@models/moi/MoiModel";
import { AssessmentSummaryModel } from "@app/moi/shared/assessment-summary-model";
import { MoiDataService } from "@app/moi/services/moi-data.service";

@Component({
    selector: "moi-finding-report-summary",
    templateUrl: "./moi-finding-report-summary.component.html",
    styleUrls: ["./moi-finding-report-summary.component.scss"],
})
export class MoiFindingReportSummaryComponent implements OnInit {
    @Input() summaryModel: FindingReportSummaryModel;
    @Input() showOpenButton: false;
    @Input() moi: MoiModel;
    @Input() canLink: boolean;

    assessments: AssessmentSummaryModel[];
    assessmentModel: AssessmentSummaryModel;
    assessmentIsLoading: boolean;

    constructor(
        private tabservice: TabService,
        private pages: Pages,
        private _moiDS: MoiDataService
    ) {}

    ngOnInit() {
        this.load();
    }

    load() {
        this.assessments = null;
        this.assessmentModel = null;
        this.assessmentIsLoading = true;

        if (this.moi.AssessmentGuid) {
            this._moiDS
                .getAssessmentSummary(this.moi.AssessmentGuid)
                .subscribe((summary: AssessmentSummaryModel) => {
                    this.assessmentModel = summary;
                    this.assessmentIsLoading = false;
                });
        } else {
            this._moiDS
                .getAssessmentsByFindingReport(this.moi.ParentId)
                .subscribe((assessments) => {
                    this.assessments = assessments;
                    this.assessmentIsLoading = false;
                });
        }
    }

    change(event) {
        this.assessmentModel = event;
    }

    linkAssessment() {
        if (this.assessmentModel) {
            this.moi.AssessmentGuid = this.assessmentModel.guid;
        }
    }

    openAssessment() {
        if (!this.assessmentModel.id) {
            return;
        }

        this.tabservice.generateTab(this.pages.AssessmentDetail, this.assessmentModel.guid, null, {
            parentGuid: this.assessmentModel.findingReportGuid,
        });
    }

    openFindingReport() {
        if (!this.summaryModel.id) {
            return;
        }

        this.tabservice.generateTab(this.pages.ImDetail, this.summaryModel.guid);
    }
}
