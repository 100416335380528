import { Component, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { JobSchedulerDataService } from "@app/job-scheduler/services/job-scheduler-data.service";
import { JobInfoModel } from "@app/job-scheduler/models/job-info-model";
import { getJobStatusColors } from "@app/job-scheduler/enums/job-status-enum";
import { getJobTypeDescription } from "@app/job-scheduler/enums/job-types-enum";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { from, of } from "rxjs";
import { GenericListButton } from "@app/shared/models/GenericList/GenericManagerConfig";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "job-scheduler-list",
    templateUrl: "./job-scheduler-list.component.html",
    styleUrls: ["./job-scheduler-list.component.scss"],
})
export class JobSchedulerListComponent implements OnInit {
    config: GenericListConfig;
    isEnabled = false;
    isLoading = true;

    documentGuid: string;
    startDate: Date;
    endDate: Date;

    statusColors: IdNameColorModel[];

    constructor(private _ds: JobSchedulerDataService, private _toastr: ToastrService) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        this.statusColors = getJobStatusColors();
        this.reload();
    }

    private async reload() {
        this.createListConfig();
        this.isLoading = false;
    }

    private async getData(): Promise<JobInfoModel[]> {
        var data = await this._ds.getList();
        data.forEach((i) => {
            try {
                i.jobTypeText = getJobTypeDescription(i.jobType);
            } catch {}
        });

        return data;
    }

    private createListConfig() {
        this.config = <GenericListConfig>{
            name: "Scheduled Jobs",
            idProp: "id",
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xl",
            hideEmptyResult: false,
            dataMethod: () => from(this.getData()),
            fields: [
                {
                    fieldName: "id",
                    prettyName: "ID",
                    fieldType: GenericListFieldType.Number,
                    hideInOverview: false,
                },
                {
                    fieldName: "creator",
                    prettyName: "Creator",
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: false,
                },
                {
                    fieldName: "jobTypeText",
                    prettyName: "Job Type",
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: false,
                },
                {
                    fieldName: "dateScheduled",
                    prettyName: "Scheduled Date",
                    fieldType: GenericListFieldType.DateTime,
                    hideInOverview: false,
                },
                {
                    fieldName: "status",
                    prettyName: "Status",
                    fieldType: GenericListFieldType.ColoredSelect,
                    hideInOverview: false,
                    getDataMethod: () => {
                        return of(this.statusColors);
                    },
                },

                {
                    fieldName: "dateCreated",
                    prettyName: "Creation Date",
                    fieldType: GenericListFieldType.DateTime,
                    hideInOverview: true,
                },
                {
                    fieldName: "dateUpdated",
                    prettyName: "Last Update",
                    fieldType: GenericListFieldType.DateTime,
                    hideInOverview: true,
                },

                {
                    fieldName: "readableInfo",
                    prettyName: "Readable Info",
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: true,
                },

                {
                    fieldName: "technicalInfo",
                    prettyName: "Technical Info",
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "params",
                    prettyName: "Job Parameters",
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "allowRetry",
                    fieldType: GenericListFieldType.CheckBox,
                    hideInOverview: true,
                    hideInEdit: true,
                },
            ],
            overviewRowActionsName: "Actions",
            overviewRowActions: [
                <GenericListButton>{
                    showProperty: "allowRetry",
                    tooltip: "Retry",
                    icon: "fas fa-rotate-right",
                    dataUiid: "retry-job-button",
                    clickEvent: async (row: JobInfoModel) => {
                        await this.retryJob(row);
                    },
                },
            ],
        };
    }

    private async retryJob(row: JobInfoModel) {
        this.isLoading = true;

        try {
            await this._ds.retryJob(row.id);

            this._toastr.success(
                `Retry attempt for job #${row.id} was successful. Please monitor the job status for updates.`
            );

            await this.reload();
        } finally {
            this.isLoading = false;
        }
    }
}
