import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InviteModel } from "../../../common/models/invite/InviteModel";
import { Configuration } from "../../../app/app.constants";
import { ApiDataService } from "../ApiDataService";

@Injectable()
export class InviteDataService extends ApiDataService {
    cachedData: Promise<InviteModel>;

    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "invites");
    }

    getItems(refresh?: boolean, id?: number, typeId?: number): Promise<InviteModel> {
        if (refresh) {
            this.cachedData = null;
        }
        if (!this.cachedData) {
            const url = `/${typeId}?objectID=${id}`;
            this.cachedData = this.get<InviteModel>(url).toPromise();
        }
        return this.cachedData;
    }

    sendInvite(invites: InviteModel): Promise<Object> {
        const response = this.post(invites).toPromise();

        return response;
    }
}
