import { Options } from "sortablejs";
declare var $: any;

export function ImagePreview(file, imgTagID): void {
    const reader = new FileReader();
    reader.onload = () => {
        const output = document.getElementById(imgTagID) as HTMLImageElement;
        output.src = reader.result as string;
    };
    reader.readAsDataURL(file);
}

export function CustomSortableOptions(options?: Options) {
    if (!options) {
        options = {};
    }

    // Class for custom styling applied to dragged element.
    if (!options.ghostClass) {
        options.ghostClass = "sortingGhost";
    }

    // Start drag image at mouse pointer instead of current position of the dragged element.
    // This is done to prevent ubstruction of view with large elements.
    if (!options.setData) {
        options.setData = function (dataTransfer, draggedElement) {
            dataTransfer.setDragImage(draggedElement, 0, 0);
        };
    }

    return options;
}

/** Modifies the '.detail-page-card' DOM elements under the provided selector to make the
 * '.detail-page-card-body' DOM element collapse/expand when clicked on the '.detail-page-card-title' DOM element.
 *
 * Both the title and body have to be present for this to take effect.
 * @param {string} preselector - Required. If this selector is not correctly limited to the targeted selection it will cause other unmodified page cards on other tabs/pages to be modified aswell.
 */
export function makePageCardsCollapsable(preselector: string) {
    // preselector is required!
    if (!preselector || !preselector.trim()) {
        return;
    }

    // Attribute-Key to keep track of original page-card title.
    const originalTitleAttr = "original-title";
    $(preselector + " .detail-page-card").each((i, card) => {
        const title = $(card).find(".detail-page-card-title");
        const body = $(card).find(".detail-page-card-body");
        if (title !== null && body !== null) {
            // If the same page-card is being modified again --> Dont
            const isModifiedAlready =
                title.attr(originalTitleAttr) && title.attr(originalTitleAttr).trim()
                    ? true
                    : false;

            if (!isModifiedAlready) {
                // Get the original title and store it as a attribute on the title DOM element with the Attribute Key.
                const origTitle = title.html();
                title.attr(originalTitleAttr, origTitle);

                // Add the icon to the title to reflect the current collapse/expand status. Also set cursor to be a 'pointer'.
                title.html(getCollapseExpandIcon(false) + title.attr(originalTitleAttr));
                title.css("cursor", "pointer");

                // Bind event on title to collapse/expand (slideToggle) the body and change the icon of the title to reflect that.
                title.on("click", () => {
                    const wasExpanded = body.css("display") !== "none";

                    body.slideToggle();
                    title.html(getCollapseExpandIcon(wasExpanded) + title.attr(originalTitleAttr));
                });
            }
        }
    });
}

function getCollapseExpandIcon(getExpand: boolean) {
    if (getExpand) {
        return '<i class="fas fa-angle-down me-2"></i>';
    }
    return '<i class="fas fa-angle-up me-2"></i>';
}
