import { Injectable, Inject } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { Observable } from "rxjs";

export interface Options {
    // A target for global event notifications.
    element: any;
    // A combination of key attributes values (https://www.w3.org/TR/uievents-key/#key-attribute-value) delimited by "."
    keys: string;
}

export interface Shortcut extends Options {
    handler: (e: Event) => void;
}

@Injectable({ providedIn: "root" })
export class Hotkeys {
    defaults: Partial<Options> = {
        element: this.container,
    };

    // Keeps track which shortcuts have been added.
    private collisionList: Options[] = [];

    constructor(
        private eventManager: EventManager,
        @Inject(DOCUMENT) private container: Document
    ) {}

    addShortcut(options: Partial<Options>): Observable<Event> {
        const opt = { ...this.defaults, ...options } as Options;
        const event = `keydown.${opt.keys}`;

        // If a shortcut with the same keys has been added on the same element, it will throw an error!
        if (this.collisionList.some((x) => x.element === opt.element && x.keys === opt.keys)) {
            throw new Error(
                "Shortcut has already been defined on element:\nkey: " +
                    opt.keys +
                    "\nelement: " +
                    opt.element.nodeName
            );
        } else {
            this.collisionList.push(opt);
        }

        return new Observable((ob) => {
            const handler = (e) => {
                ob.next(e);
            };

            const dispose = this.eventManager.addEventListener(opt.element, event, handler);

            return () => {
                dispose();
                const optionIndex = this.collisionList.indexOf(opt);
                if (optionIndex > -1) {
                    this.collisionList.splice(optionIndex, 1);
                }
            };
        });
    }
}
