<h3 class="mb-2">Using the API in Power BI</h3>
<span>
    For this manual on how to use CERRIX APIs within Power BI it is expected that the user has
    general knowledge of Power BI and how to create Reports and manage data within Power BI. For
    more information about Power BI (Desktop), please consult the following Microsoft
    documentations:
</span>
<span>
    <ul>
        <li>
            <a target="_blank" href="https://docs.microsoft.com/en-us/power-bi/fundamentals/">
                Get started with Power BI
            </a>
        </li>
        <li>
            <a
                target="_blank"
                href="https://docs.microsoft.com/en-us/power-bi/fundamentals/desktop-what-is-desktop"
            >
                Power BI Desktop
            </a>
        </li>
        <li>
            <a target="_blank" href="https://docs.microsoft.com/en-us/power-bi/connect-data/">
                Connect to data in Power BI
            </a>
            (for the CERRIX API the 'Web' data source is used)
        </li>
    </ul>
</span>

<h5>Registering an API in CERRIX</h5>
<p>
    <span>
        To make use of APIs, the first step is to create an API in CERRIX. This can be done through
        the "Api Keys" sub menu on your profile page, which can be found in the top right corner of
        the site under the profile button or by clicking <a (click)="openProfile()">here</a>.
    </span>

    <span>
        Create a new API Key (the page and or button can be inaccessible because of insufficient
        rights) by clicking on the
        <button class="btn btn-raised mx-2"><i class="fas fa-plus me-2"></i>Add</button>
        button.
    </span>

    <span>
        On the right section you can enter <b>a name for the API key</b> (so you can track for which
        system you used the key), <b>an expiration date</b> (which can be at most a year from date
        of creation, so do not forget to extend this when the date is due) and the
        <b>APIs that should be accessible</b> with this key.
    </span>

    <span
        >Once you selected which APIs you want to use, click on save and wait for the popup to open
        (see popup below). The popup contains a generated <b>Identifier</b> and <b>Password</b>.
        <b>Copy and save these values now, because you will not be able to see them again!</b> If
        you lose these values you will have to recreate the key and reconfigure the system with the
        new authentication values.
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/api-creation.png" />
    </span>
</p>

<h5>Configuring CERRIX as a data source in Power BI</h5>
<p>
    <span>
        Now that we have CERRIX configured, we can move to Power BI. As example we will use the
        <a (click)="openGetRiskDataManual()">'Get Risk Data'</a> API, but the steps defined can be
        applied to the other APIs as well.
    </span>

    <span>
        The first step is to open Power BI Desktop and create a new report. In the tool ribbon open
        the 'Get data' dropdown and select 'Web' (see image below).
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/powerbi/powerbi-web-datasource.png" />
    </span>

    <span>
        The popup that shows up will ask for the URL. This URL consists of two parts, the base and
        the api path. The base path can be found in the
        <a (click)="openApiUsageManual()">'API Usage'</a> manual and the api path can be found in
        the manual of your targeted API. For this example we are using
        <a (click)="openGetRiskDataManual()">'Get Risk Data'</a>.
    </span>

    <span>
        If you combine the two (seperated by a single '/' forward slash!) you should get an URL
        which should look like this:
    </span>

    <span class="centered">
        <b>{{ apiUrl }}/riskdata/risk</b>
    </span>

    <span>
        After entering the URL into the URL field and clicking on 'OK', another popup will open for
        the authentication settings. On the left side select 'Basic' and here the User name field
        must be entered with the Identifier and the Password with the Password value, which you
        acquired in the previous step (Registering an API in CERRIX).
    </span>
    <span>
        The 'Select which level to apply these settings to' option can help with managing only one
        set of credentials or multiple, depending on your configuration within CERRIX. For more
        information about this option see the official
        <a
            target="_blank"
            href="https://docs.microsoft.com/en-us/power-query/connectorauthentication#set-the-level-of-the-authentication-method"
            >Microsoft Documentation</a
        >.
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/powerbi/powerbi-web-config.png" />
    </span>

    <span>
        The image above shows how the final configuration should look like, where after we can click
        on Connect. This will start requesting data from CERRIX and open the Transform Data tool. If
        it does not open the tool, it can be opened through the tool ribbon manually.
    </span>
</p>

<h5>Transforming data</h5>
<p>
    <span>
        Before we can use the data to create reports, we need to transform it so Power BI knows how
        to interpret the data. The amount of steps will different for each CERRIX API, but generally
        it consists of two main steps. Step one will be to setup the data source so we can create
        links to it and seperate the tables. Step two will be to create a seperate table for each
        part of the API. This last step will be done multiple times, depending on the API, for this
        example (Get Risk Data API) it will be performed for the Risks and the Controls.
    </span>

    <span>
        Before we can configure the tables, we should setup the data source so we can easily extract
        the correct data per table. In case Power BI automatically applied transformations, make
        sure these transformations are undone. To do this open the Query Settings and under Applied
        Steps make sure only the source step exists. All steps after that can be deleted. The
        settings Power BI performs are close to the final settings we want to achieve, but to make
        it easier to follow along, we will start at the minimum.
    </span>

    <span>
        To create columns for each table we will convert the Source data into a table. For this we
        highlight the source data (risk in this example) and in the 'Record Tools - Convert' ribbon
        we use the 'Into Table' tool. This will convert the data into a table with multiple rows and
        two columns (Name and Value). We want to reverse this, so we have a column per table and a
        single row with the 'List' value per column.
    </span>

    <span>
        To reverse the columns and the rows we use the 'Transform' ribbon. In here we use the
        'Transpose' tool, which will reverse the rows and columns. After this we will also click on
        'Use First Row as Headers' to shift the rows up and make the column names equal to the table
        names. The image below shows the final state of our source data and the Applied steps to it.
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/powerbi/powerbi-source-transformation.png" />
    </span>

    <span>
        Before we extract our tables we will configure the source query so it will not show up as a
        table while creating the report and make it clear it is the source query. To do this, we
        will right click on the single query we have in the left 'Queries' menu (see image below).
        In this context menu we will rename it, so it is more clear this is the source of your data,
        so for example 'Source'. We will also uncheck the 'Enable load' option in the context menu.
        This will make sure the Source Query does not show up in the table list while creating the
        report.
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/powerbi/powerbi-source-query-config.png" />
    </span>

    <span>
        Now that we have the Data source configured we can move to extracting the data and creating
        tables. To this we will start by using the same context menu on the Source query (see image
        above). In here we will click on 'Reference', which will create a new entry in the Queries
        menu. This step and onwards will be performed for each table we want to create, so for the
        'Get Risk Data' API we will do this for Risks and Controls.
    </span>

    <span>
        After we got a reference query for a table, we will right click on the item in the Query
        Menu and make sure this Query does have the 'Enable load' option checked. Otherwise the
        table will not show up while creating the report. It is also recommended to rename this
        Query to the name of the table. Before moving to the next steps make sure you have the
        correct query highlighted!
    </span>

    <span>
        Each column in the table we have is a separate table, so to make the query only contain the
        data of one table we will remove columns which are not needed (for the Risks table, we
        remove the controls column and for the Controls table we will remove the risks column). To
        remove a column, right click on the column header and click "Remove" in the context menu.
    </span>

    <span>
        At this point we should have a table with one column and one row. To retrieve the data of
        the table we can click on the icon with the arrows going left and right in the column header
        cell (see image below). This will open a dropdown where we will use the 'Expand to New Rows'
        option. This will expand the single row we have now (which is a List) to multiple rows.
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/powerbi/powerbi-table-extract.png" />
    </span>

    <span>
        Next up is to get the table columns. For this we will click on the same icon we clicked on
        in the previous step (see image above). This will open another dropdown with all the columns
        that are available (see image below). If the 'List may be incomplete' warning appears at the
        bottom of the popup, click on 'Load more' to make sure all the available columns are loaded.
        Also disable the 'Use original column name as prefix' option so we do not get a prefix on
        all the columns. Depending on your needs you either select all or specific columns you want
        to get into your table. After your selection click on 'OK' and this will expand all the
        columns in the main table and show all data we initially retrieved in the first step.
    </span>

    <span>
        <b
            >This last step may have to be redone in case the API data changes and new columns get
            added or edited!</b
        >
    </span>

    <span class="centered">
        <img src="/static/images/manuals/api/powerbi/powerbi-column-extract.png" />
    </span>

    <span>
        After we have created all the tables we want we can click on the 'Close & Apply' button in
        the Home ribbon. This will transform your data and close the Transform Data tool. The main
        Power BI Desktop window will now show the tables in the fields menu on the right side of
        your window.
    </span>
</p>

<h5>Final Steps</h5>
<p>
    <strong>Sharing reports / CERRIX API</strong>
    <span>
        While using the API, CERRIX is not in control of the requested data. So sharing the report
        with a colleague or another person, means giving them access to all the data accessible
        through the API under your account. So please share responsibly! You can always delete the
        API key on the
        <a (click)="openProfile()">profile</a> page within the application. This will prevent future
        requests with the credentials, but does not prevent access to already retrieved data.
    </span>

    <strong>Renaming columns</strong>
    <span>
        While configuring the tables in the Transform Data tool, you have the option to rename the
        columns we extract in the final steps. Make sure to keep track of renamed columns. This can
        help dealing with errors in case the API data is changed. Renames to columns in the API data
        will be noted in the <a (click)="openReleaseNotes()">Release Notes</a>, so make sure to
        consult the latest <a (click)="openReleaseNotes()">Release Notes</a> after updates. For more
        information on renaming columns, consult the official
        <a target="_blank" href="https://docs.microsoft.com/en-us/power-query/rename-column"
            >Microsoft Documentation</a
        >.
    </span>

    <strong>Configuring Relationships</strong>
    <span>
        Power BI is smart and most likely will configure relationships automatically. If this is not
        the case, or you want custom relationships configured, we recommend looking at the official
        <a
            target="_blank"
            href="https://docs.microsoft.com/en-us/power-bi/transform-model/desktop-create-and-manage-relationships"
            >Microsoft Documentation</a
        >
        for more detailed information.
    </span>
</p>
