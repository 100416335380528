<div class="btn-group">
    <button
        class="navbar-button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
    >
        <i class="fad fa-calendar-alt"></i>
        <span class="badge rounded-pill bg-danger" *ngIf="todoCount > 0">{{ todoCount }}</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end">
        <div class="calendar-menu">
            <calendarmenu maxHeight="500px"></calendarmenu>
        </div>
    </div>
</div>
