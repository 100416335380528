import { Component, Input, ViewEncapsulation, Output, EventEmitter } from "@angular/core";
import { MatrixModel, MatrixCell } from "@models/generic/MatrixModel";

@Component({
    selector: "cerrix-riskmatrix-summary",
    templateUrl: "./cerrix-riskmatrix-summary.component.html",
    styleUrls: ["./cerrix-riskmatrix-summary.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CerrixRiskmatrixSummaryComponent {
    @Input() matrix: MatrixModel;
    @Input() cellSize = 60;
    @Input() cellSpacing = 2;

    @Output() cellClick = new EventEmitter<MatrixCell>();
    @Output() summaryClick = new EventEmitter<number>();

    handleSummaryClick(index) {
        this.summaryClick.emit(index);
    }

    handleCellClick(cell: MatrixCell) {
        this.cellClick.emit(cell);
    }
}
