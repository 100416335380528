import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../../api-spec";

@Component({
    selector: "tpa-get-results-api-manual",
    templateUrl: "../../../api-spec.html",
    styleUrls: ["../../../api-spec.scss"],
})
export class TpaGetResultsApiManualComponent extends ApiSpecComponent {
    initSpecs(): ApiSpecificationModel {
        return <ApiSpecificationModel>{
            name: "Get Results",
            description: "Retrieve the requested controls based on a requestid",
            httpMethod: "GET",
            path: "/thirdpartyassurance/getresults/{requestId}",

            parameters: [
                {
                    name: "requestId",
                    description: "id of the request",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
            ],
            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        Status: "string",
                        Controls: [
                            {
                                ControlIdentifier: "string",
                                ControlName: "string",
                                ControlDescription: "string",
                                ControlFrequency: "string",
                                KeyControl: "boolean",
                                RequiresMonitoring: "boolean",
                                ControlExecution: "string",
                                AspectIC: "string",
                                MitigatedRisks: "string[]",
                                RelatedOrganization: "string",
                                RelatedBusinessDimensionPaths: "string[]",
                                RelatedBusinessDimensions: "string[]",
                                RelatedFrameworkDimensions: "string[]",
                                TestPeriods: [
                                    {
                                        TestPeriodName: "string",
                                        TestPeriodScore: "string",
                                        TestPeriodStartDate: "date",
                                        TestPeriodEndDate: "date",
                                        TestPlans: [
                                            {
                                                TestPlanName: "string",
                                                TestPlanStatus: "string",
                                                TestPlanStartDate: "date",
                                                TestPlanEndDate: "date",
                                                SampleMethod: "string",
                                                ReasonForManualGeneration: "string",
                                                SampleType: "string",
                                                TestSample: "integer",
                                                TestPopulation: "integer",
                                                SourceDocumentUploader: "string",
                                                DueDateSourceDocumentUploader: "date",
                                                EvidenceUploaders: "string[]",
                                                DueDateEvidenceUploader: "date",
                                                Testers: "string[]",
                                                DueDateTester: "date",
                                                Reviewers: "string[]",
                                                DueDateReviewer: "date",
                                                LinkedStandard: "string",
                                                QualityAspects: "string",
                                                TestNorm: "string",
                                                TestedBy: "string",
                                                OverallScoreTester: "string",
                                                CommentTester: "string",
                                                DateTesterTested: "date",
                                                ReviewedBy: "string",
                                                OverallScoreReviewer: "string",
                                                CommentReviewer: "string",
                                                DateReviewerTested: "date",
                                                TestSamples: [
                                                    {
                                                        SampleName: "string",
                                                        SampleScore: "string",
                                                        SampleTestedBy: "string",
                                                        SampleCommentTester: "string",
                                                        SampleReviewedBy: "string",
                                                        SampleCommentReviewer: "string",
                                                        TestSteps: [
                                                            {
                                                                TestStepName: "string",
                                                                TestStepScore: "string",
                                                                TestStepTestedBy: "string",
                                                                TestStepCommentTester: "string",
                                                                TestStepReviewedBy: "string",
                                                                TestStepCommentReviewer: "string",
                                                                StepDocuments: [
                                                                    {
                                                                        DocumentID: "uuid",
                                                                        DocumentDescription:
                                                                            "string",
                                                                        FileName: "string",
                                                                        FileHash: "string",
                                                                        UploadedBy: "string",
                                                                        LastModified: "datetime",
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                        SampleDocuments: [
                                                            {
                                                                DocumentID: "uuid",
                                                                DocumentDescription: "string",
                                                                FileName: "string",
                                                                FileHash: "string",
                                                                UploadedBy: "string",
                                                                LastModified: "datetime",
                                                            },
                                                        ],
                                                    },
                                                ],
                                                SourceDocuments: [
                                                    {
                                                        DocumentID: "uuid",
                                                        DocumentDescription: "string",
                                                        FileName: "string",
                                                        FileHash: "string",
                                                        UploadedBy: "string",
                                                        LastModified: "datetime",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
        };
    }
}
