<div class="modal-header">
    <h5 class="modal-title">Adding data subjects & data structures</h5>
</div>
<div class="modal-body">
    <div class="content">
        <form [formGroup]="formGroup" class="form-group">
            <div class="form-group">
                <cerrix-select-tree
                    placeholder="Select at least one data subject"
                    [tree]="standingData.dataSubjects"
                    [multiselect]="true"
                    (onChangeIds)="dataSubjectChanged($event)"
                    [value]="currentDataSubjectsIds"
                >
                </cerrix-select-tree>
            </div>
            <div *ngIf="dataSubjectModels.length > 0">
                <table class="cerrix-table bordered rounded">
                    <thead>
                        <tr>
                            <th width="50px"></th>
                            <th>Data subjects</th>
                            <th>Data structures</th>
                            <th width="80px">Archive policy</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let dataSubject of dataSubjectModels; let i = index"
                            (click)="dataSubject.selected = !dataSubject.selected"
                        >
                            <td class="table-cell-btn">
                                <button
                                    class="btn btn-raised red"
                                    (click)="deleteDataSubject(dataSubject)"
                                >
                                    <i class="fas fa-minus-circle"></i>
                                </button>
                            </td>
                            <td>{{ dataSubject.name }}</td>
                            <td>
                                <cerrix-select-tree
                                    placeholder="Required"
                                    [tree]="standingData.dataStructures"
                                    [multiselect]="true"
                                    [formControlName]="'' + dataSubject.id"
                                    [identifier]="i + '_dataStructureSelect_' + dataSubject.id"
                                    (onChangeIds)="setDataStructures($event, dataSubject)"
                                >
                                </cerrix-select-tree>
                            </td>
                            <td class="table-cell-btn">
                                <button
                                    *ngIf="dataSubject.dataStructures.length > 0"
                                    class="btn btn-raised"
                                    title="Manage archive policy"
                                    (click)="manageArchivePolicy(dataSubject)"
                                >
                                    <i class="fas fa-archive"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="!disabled" type="button" class="btn btn-primary" (click)="okClick()">Ok</button>
    <button type="button" class="btn btn-secondary" (click)="closeClick()">Close</button>
</div>
