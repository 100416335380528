import { DocumentModel } from "@models/documents/documentmodel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { DataRecipientModel } from "./DataRecipientModel";
import { SourceDeliveryModel } from "./SourceDeliveryModel";

export class ProcessingPurposeModel {
    ID: number;
    guid: string;
    lastSaveDate: Date;

    identifier: string;
    name: string;
    description: string;
    purpose: string;
    securityMeasures: string;
    basisId: number;
    explanationBasis: string;
    organizationId: number;
    businessDimensionIds: number[];
    frameworkDimensionIds: number[];
    removalIds: number[];
    sensitivityIds: number[];
    generalArchivePolicy: string;
    profiling: boolean;
    dataAnalytics: boolean;

    dataSubjectIds: number[];
    dataSubject: string;
    dataProcessorIds: number[];
    dataControllerIds: number[];
    dataRecipientThirdPartyIds: number[];
    thirdPartyIds: number[];

    dpiaRequired: boolean;
    dpiaDate: Date;
    dpiaReference: string;
    dpiaConclusion: string;

    riskIds: number[];
    controlIds: number[];

    // Only used on the frond end
    dataRecipients: DataRecipientModel[];
    sourceDeliveries: SourceDeliveryModel[];
    documents: DocumentModel[];
    Hyperlinks: HyperlinkModel[];

    constructor() {
        this.businessDimensionIds = [];
        this.frameworkDimensionIds = [];
        this.removalIds = [];
        this.sensitivityIds = [];
        this.dataSubjectIds = [];
        this.dataProcessorIds = [];
        this.dataControllerIds = [];
        this.thirdPartyIds = [];

        this.riskIds = [];
        this.controlIds = [];

        this.dataRecipients = [];
        this.sourceDeliveries = [];

        this.profiling = false;
        this.dataAnalytics = false;
        this.dpiaRequired = false;
    }
}
