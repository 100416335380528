export enum FormatType {
    None, // <-- must be here for undefined check (0)
    DateFormat,
    DateShortTimeFormat,
    DateTimeFormat,
    DecimalFormat,
    PercentFormat,
    CurrencyFormat,
    FileSizeFormat,
}
