import {
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    Validators,
    FormGroup,
} from "@angular/forms";
import { getFormValue } from "@methods/CommonMethods";
import { ExcelHelper } from "@app/shared/helpers/excel-helper";
import moment from "moment";

/*
 * Return example for custom validation errors (FormControl):
 * {
 *   uniqueValidationName: {
 *     someOptionalValueKey: 123,
 *     getErrorMessage: (fieldName, prettyFieldNames) => {
 *       return errorMessage
 *     }
 *   }
 * }
 *
 * Cross field validation (FormGroup):
 * {
 *   uniqueValidationName: {
 *     someOptionalValueKey: 123,
 *     getErrorMessage: prettyFieldNames => {
 *       return errorMessage
 *     }
 *   }
 * }
 */

function isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
}

/**
 * Custom validators for CERRIX
 */
export class FormValidators {
    /**
     * Min value validator dependend on other control
     */
    static min<T>(dependentControlKey: keyof T): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control === control.root) {
                // Not yet attached to FormGroup
                return null;
            }

            const minValue = getFormValue<T>(control.root as FormGroup, dependentControlKey);

            const result = Validators.min(minValue)(control);
            if (result) {
                result.min.getErrorMessage = (fieldName, prettyFieldNames) => {
                    const dependendFieldName = this.getPrettyFieldName(
                        prettyFieldNames,
                        dependentControlKey
                    );

                    return `"${fieldName}" must be greater than or equal to ${dependendFieldName}`;
                };
            }

            return result;
        };
    }

    /**
     * Max value validator dependend on other control (root must be FormGroup)
     */
    static max<T>(dependentControlKey: keyof T): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control === control.root) {
                // Not yet attached to FormGroup
                return null;
            }

            const maxValue = getFormValue<T>(control.root as FormGroup, dependentControlKey);

            const result = Validators.max(maxValue)(control);
            if (result) {
                result.max.getErrorMessage = (fieldName, prettyFieldNames) => {
                    const dependendFieldName = this.getPrettyFieldName(
                        prettyFieldNames,
                        dependentControlKey
                    );

                    return `"${fieldName}" must be less than or equal to ${dependendFieldName}`;
                };
            }

            return result;
        };
    }

    /**
     * Cross field validator checking if start date is before or equal to end date
     */
    static startEndDate<T>(
        dependentStartDateControlKey: keyof T,
        dependentEndDateControlKey: keyof T,
        alsoCompareTime = false
    ): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            let startDate = getFormValue<T>(formGroup, dependentStartDateControlKey) as Date;
            let endDate = getFormValue<T>(formGroup, dependentEndDateControlKey) as Date;

            if (isEmptyInputValue(startDate) || isEmptyInputValue(endDate)) {
                return null;
            }

            if (!alsoCompareTime) {
                startDate = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate()
                );
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
            }

            if (startDate <= endDate) {
                return;
            }

            return {
                startEndDate: {
                    startDate,
                    endDate,
                    getErrorMessage: (prettyFieldNames) => {
                        const startDateFieldName = this.getPrettyFieldName(
                            prettyFieldNames,
                            dependentStartDateControlKey
                        );
                        const endDateFieldName = this.getPrettyFieldName(
                            prettyFieldNames,
                            dependentEndDateControlKey
                        );

                        return `"${startDateFieldName}" must be before or equal to ${endDateFieldName}`;
                    },
                },
            };
        };
    }

    /**
     * Validator that requires the date to not be in the future
     */
    static notFutureDate(control: AbstractControl): ValidationErrors | null {
        const futureDate = moment(new Date()).add(1, "days").startOf("day");
        return !isEmptyInputValue(control.value) && moment(control.value) >= futureDate
            ? {
                  futureDate: {
                      current: control.value,
                      getErrorMessage: (fieldName) => {
                          return `"${fieldName}" may not be in the future`;
                      },
                  },
              }
            : null;
    }

    /**
     * Validator that requires the column name to be valid
     */
    static excelColumnName(control: AbstractControl): ValidationErrors | null {
        const columnName = control.value;
        if (isEmptyInputValue(columnName)) {
            return null;
        }

        const columnNumber = ExcelHelper.convertExcelColumnToNumber(columnName);
        if (!columnNumber || columnNumber < 1 || columnNumber > 16384) {
            return {
                excelColumn: {
                    current: control.value,
                    getErrorMessage: (fieldName) => {
                        return `"${fieldName}" must be between A and XFD`;
                    },
                },
            };
        }

        return null;
    }

    /**
     * Cross field validator checking if either all values are empty or all are filled
     */
    static allEmptyOrAllFilled<T>(...dependentControlKeys: Array<keyof T>): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            const values = dependentControlKeys.map((ck) => getFormValue<T>(formGroup, ck));

            let hasNoValue = false;
            let hasValue = false;
            for (const v of values) {
                if (isEmptyInputValue(v)) {
                    hasNoValue = true;
                } else {
                    hasValue = true;
                }
            }

            return hasNoValue !== hasValue
                ? null
                : {
                      allEmptyOrAllFilled: {
                          controls: dependentControlKeys,
                          values,
                          getErrorMessage: (prettyFieldNames) => {
                              const prettyNames = dependentControlKeys.map(
                                  (ck) => `"${this.getPrettyFieldName(prettyFieldNames, ck)}"`
                              );

                              return `Following must all by empty or all have value: ${prettyNames.join(
                                  ", "
                              )}`;
                          },
                      },
                  };
        };
    }

    public static validFileName(control: AbstractControl): ValidationErrors | null {
        const columnName = control.value;
        if (isEmptyInputValue(columnName)) {
            return null;
        }
        // Not allowed characters: \ / : * ? " < > |
        const validFileNameRegex = /^[^\\\/:*?"<>|]+$/;
        if (!validFileNameRegex.test(columnName)) {
            return {
                validFileNameWithoutExt: {
                    current: control.value,
                    getErrorMessage: (fieldName: string) => {
                        return `"${fieldName}" contains invalid file name characters`;
                    },
                },
            };
        }
    }

    private static getPrettyFieldName(
        prettyFieldNames: string[],
        dependentControlKey: string | number | symbol
    ): string {
        return dependentControlKey in prettyFieldNames
            ? `"${prettyFieldNames[dependentControlKey]}"`
            : <string>dependentControlKey;
    }
}
