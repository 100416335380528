<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export Risks</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <hr />

        <button class="btn btn-default me-2 mb-2" (click)="download(ExportType.Risks)">
            Risks
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="download(ExportType.RisksControls)"
            *ngIf="permService.permissions.HasRiskControlExportAccess"
        >
            Risks and controls
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="download(ExportType.RiskControlMois)"
            *ngIf="permService.permissions.HasRiskControlExportAccess"
        >
            Risks, controls and mois
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="exportRiskAssessmentPerOrganization()"
            *ngIf="permService.permissions.HasRiskControlExportAccess"
        >
            Risk Assessment per Organization (CRSA)
        </button>
    </div>
</div>
