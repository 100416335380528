export enum ComparisonOperator {
    Equal = 1,
    NotEqual = 2,
    LessThan = 3,
    LessThanOrEqual = 4,
    GreaterThan = 5,
    GreaterThanOrEqual = 6,
    StartsWith = 7,
    EndsWith = 8,
    Contains = 9,
    NotContains = 10,
}
