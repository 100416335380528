import { CommonModule } from "@angular/common";
import {
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChatCommentModel } from "../models/chat-comment-model";
import { ChatCommentComponent } from "../chat-comment/chat-comment.component";
import { BaseChatService } from "../services/base-chat.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CerrixTextareaComponent, CerrixValidators } from "@cerrix/components";
import { Observable } from "rxjs";

@Component({
    selector: "cerrix-chat",
    templateUrl: "./cerrix-chat.component.html",
    styleUrls: ["./cerrix-chat.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        ChatCommentComponent,
        CerrixTextareaComponent,
    ],
    providers: [],
})
export class CerrixChatComponent {
    protected _comments: ChatCommentModel[] = [];

    public get comments(): ChatCommentModel[] {
        return this._comments;
    }
    @Input() public set comments(newComments: ChatCommentModel[]) {
        if (this._comments !== newComments) {
            newComments.forEach((comment) => {
                this.setUserColor(comment.userId);
            });
            this._comments = newComments;
        }
    }

    @Input() public canAdd!: boolean;
    @Input() public entityId!: string;
    @Input() public title: string;
    @Input() public placeholder: string = "Write a comment";
    @Output() hideChat = new EventEmitter<void>();

    private destroyRef = inject(DestroyRef);
    private chatService = inject(BaseChatService);
    private maxCommentLength = 32767;

    protected loading: boolean = false;

    protected userColors = new Map<string, string>();
    private availableColors: string[] = [
        "blue-icon",
        "green-icon",
        "red-icon",
        "purple-icon",
        "orange-icon",
    ];

    protected commentTextForm = new FormControl<string>(
        null,
        CerrixValidators.maxLength(this.maxCommentLength, "Comment exceeds max length")
    );

    private setLoading(loading: boolean): void {
        if (loading) {
            this.loading = true;
            this.commentTextForm.disable();
        } else {
            this.loading = false;
            this.commentTextForm.enable();
        }
    }

    private setUserColor(userId: string): void {
        if (!this.userColors.has(userId)) {
            this.userColors.set(
                userId,
                this.availableColors[this.userColors.size % this.availableColors.length]
            );
        }
    }

    public hasUnsavedChanges(): boolean {
        return !!this.commentTextForm.value;
    }

    public saveComment(): Observable<boolean> {
        return new Observable((observer) => {
            if (this.commentTextForm.valid) {
                this.setLoading(true);

                this.chatService
                    .addComment(this.entityId, this.commentTextForm.value)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                        next: (comment) => {
                            this.setUserColor(comment.userId);
                            this.comments.push(comment);
                            this.commentTextForm.setValue(null);

                            this.setLoading(false);

                            observer.next(true);
                            observer.complete();
                        },
                        error: () => {
                            this.setLoading(false);

                            observer.next(false);
                            observer.complete();
                        },
                    });
            } else {
                observer.next(false);
                observer.complete();
            }
        });
    }

    protected onAddComment() {
        this.saveComment()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((_) => {});
    }

    @ViewChild("scrollContainer") private scrollContainer: ElementRef;

    protected afterLastCommentDisplayed(): void {
        this.scrollContainer.nativeElement.scrollTop =
            this.scrollContainer.nativeElement.scrollHeight;
    }
}
