<div class="matrix-with-summary">
    <div class="summary-matrix">
        <div
            class="summary-cell"
            [class.clickable]="summaryClick.observers.length > 0"
            *ngFor="let color of matrix.colors; let i = index"
            (click)="handleSummaryClick(i)"
            [ngStyle]="{
                'background-color': color,
                width:
                    ((cellSize + 2 * cellSpacing) * matrix.impactSize - 2 * cellSpacing) /
                        matrix.colors.length +
                    'px'
            }"
        >
            <div class="cell-value" *ngIf="matrix.summaryContent && matrix.summaryContent[color]">
                <span>{{ matrix.summaryContent[color] }}</span>
            </div>
        </div>
    </div>

    <div class="matrix">
        <cerrix-riskmatrix
            [matrix]="matrix"
            [cellSize]="cellSize"
            [cellSpacing]="cellSpacing"
            [highlightSelection]="false"
            (selectedChange)="handleCellClick($event)"
        ></cerrix-riskmatrix>
    </div>
</div>
