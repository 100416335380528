<cerrix-textfield
    id="field-{{ field.ID }}"
    *ngIf="!isArea"
    [(ngModel)]="answer"
    (keyup)="fieldChange()"
    [disabled]="readonly"
    [readonly]="readonly"
></cerrix-textfield>
<cerrix-textarea
    id="field-{{ field.ID }}"
    *ngIf="isArea"
    [(ngModel)]="answer"
    (keyup)="fieldChange()"
    [disabled]="readonly"
    [readonly]="readonly"
    inputTooltip
></cerrix-textarea>
