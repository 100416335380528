<form [formGroup]="formGroup">
    <div class="detail-page-summary" *ngIf="model.id > 0">
        <div class="row">
            <div class="col">
                <div class="property"><b>Identifier:</b> {{ model.identifier }}</div>
                <div class="property"><b>Auditor:</b> {{ model.createdBy }}</div>
            </div>
            <div class="col">
                <div class="property">
                    <b>Date Created:</b> {{ model.dateCreated | dateTimeFormat }}
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <generic-list-manager
                [config]="overallRiskScoringListConfig"
                *ngIf="overallRiskScoringListConfig"
            ></generic-list-manager>
        </div>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Details</div>
        <div class="detail-page-card-body padded form-group-styling">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <cerrix-textfield
                        fieldLabel="Object name"
                        id="nameInput"
                        name="nameInput"
                        placeholder="Required"
                        formControlName="name"
                        dataUiid="audit-universe-name-input"
                    >
                    </cerrix-textfield>
                    <cerrix-textarea
                        fieldLabel="Description"
                        id="descriptionInput"
                        name="descriptionInput"
                        formControlName="description"
                        dataUiid="audit-universe-description-input"
                    >
                    </cerrix-textarea>

                    <div class="form-group">
                        <label for="impactSelect">Impact</label>
                        <cerrix-select
                            id="impactSelect"
                            prettyName="impact"
                            bindLabel="Name"
                            bindValue="ID"
                            [getDataMethod]="standingData.impacts"
                            formControlName="impact"
                            dataUiid="audit-universe-impact-input"
                        >
                        </cerrix-select>
                    </div>
                    <cerrix-textarea
                        fieldLabel="Impact Comment"
                        id="impactComment"
                        name="impactComment"
                        formControlName="impactComment"
                        dataUiid="audit-universe-impactcomment-input"
                    >
                    </cerrix-textarea>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <div class="form-group">
                        <label for="organizationSelect">Organization</label>
                        <cerrix-select-tree
                            [tree]="standingData.organizations"
                            [multiselect]="true"
                            [maxLines]="4"
                            formControlName="organizations"
                            dataUiid="audit-universe-organization-input"
                        >
                        </cerrix-select-tree>
                    </div>
                    <div class="form-group">
                        <label for="bdSelect">Business Dimensions</label>
                        <cerrix-select-tree
                            [tree]="standingData.businessDimensions"
                            [multiselect]="true"
                            [maxLines]="4"
                            formControlName="businessDimensions"
                            dataUiid="audit-universe-businessdimension-input"
                        >
                        </cerrix-select-tree>
                    </div>
                    <div class="form-group">
                        <label for="bdSelect">Framework Dimensions</label>
                        <cerrix-select-tree
                            [tree]="standingData.frameworkDimensions"
                            [multiselect]="true"
                            [maxLines]="4"
                            formControlName="frameworkDimensions"
                            dataUiid="audit-universe-frameworkdimension-input"
                        >
                        </cerrix-select-tree>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <generic-list-manager
                [config]="assessmentListConfig"
                *ngIf="assessmentListConfig"
            ></generic-list-manager>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <generic-list-manager [config]="controlEnvironmentListConfig"></generic-list-manager>
            <div class="row fw-bold">
                <div class="col-1"></div>
                <div class="col-3">Total Score</div>
                <div class="col-2 ms-2 text-center">
                    <span> {{ controlEnvironmentTotalScore }}</span>
                </div>
            </div>
        </div>
    </div>
</form>
