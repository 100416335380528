<div class="detail-page-action-bar">
    <button
        class="btn btn-cerrix me-2"
        [disabled]="!listConfig.data || listConfig.data.length === 0"
        (click)="uploadData()"
    >
        <i class="fas fa-cloud-upload-alt"></i>
        <span class="d-none d-lg-inline">Upload data</span>
    </button>

    <span class="sub-message" *ngIf="listConfig.data.length == 0">Upload file to start</span>
    <span class="sub-message" *ngIf="listConfig.data.length > 0"
        >{{ listConfig.data.length }} data found to upload.</span
    >

    <div class="workflow-area">
        <button class="btn btn-outline-secondary" (click)="downloadTemplate()">
            <i class="fas fa-cloud-download-alt" aria-hidden="true"></i>
            <span class="d-none d-xl-inline ms-2">Download template</span>
        </button>
    </div>
</div>

<div class="form-group validations" *ngIf="validations">
    <label class="text-danger">Upload validations</label>
    <pre class="form-control">{{ validations }}</pre>
</div>

<div class="detail-page-card detail-page-card-data-upload-format">
    <div class="detail-page-card-title">Found data for upload</div>
    <div class="detail-page-card-actions">
        <input
            type="file"
            multiple="false"
            (change)="readFile($event)"
            #fileupload
            style="display: none"
        />
        <button class="btn btn-raised" (click)="fileupload.click()">
            <i class="fas fa-file-excel" aria-hidden="true"></i>
            <span class="d-none d-xl-inline ms-2">Select file</span>
        </button>
    </div>

    <div class="detail-page-card-body padded" *ngIf="!showConfig && !loading">
        <small>Upload an Excel file with data to start.</small>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm" *ngIf="!showConfig && loading">
        <div class="fancy-loader submodule"></div>
    </div>
</div>

<generic-list-manager [config]="listConfig" *ngIf="showConfig"></generic-list-manager>
