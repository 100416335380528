import { HttpClient } from "@angular/common/http";
import { Injectable, Type, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentTypeModel } from "../models/incident-type.model";
import { IncidentTypeEditModel } from "../models/incident-type-edit.model";
import { BaseStandingDataService } from "../../standing-data/interfaces/base-standing-data.service";
import { IncidentTypeDisplayModel } from "../models/incident-type-display.model";
import { BaseStandingDataEditComponent } from "@app/standing-data/interfaces/base-standing-data-edit.component";
import { IncidentTypeEditComponent } from "@app/standing-data/incident-type-edit/incident-type-edit.component";

@Injectable({
    providedIn: "root",
})
export class IncidentTypesService implements BaseStandingDataService {
    private readonly httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/incident-types`;

    public getAll(): Observable<IncidentTypeModel[]> {
        return this.httpClient.get<IncidentTypeModel[]>(this.baseUrl);
    }

    public getIncidentType(incidentTypeId: string): Observable<IncidentTypeEditModel> {
        return this.httpClient.get<IncidentTypeEditModel>(`${this.baseUrl}/${incidentTypeId}`);
    }

    public addIncidentType(incidentType: IncidentTypeEditModel): Observable<string> {
        return this.httpClient.post<string>(this.baseUrl, incidentType);
    }

    public editIncidentType(incidentType: IncidentTypeEditModel): Observable<void> {
        return this.httpClient.put<void>(`${this.baseUrl}/${incidentType.id}`, incidentType);
    }

    public deleteIncidentType(incidentTypeId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/${incidentTypeId}`);
    }

    public changeOrder(incidentTypeId: string, order: number): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/${incidentTypeId}/order`, order);
    }

    public getSectionsAndCustomFields(
        incidentTypeIds: string[]
    ): Observable<IncidentTypeDisplayModel[]> {
        return this.httpClient.get<IncidentTypeDisplayModel[]>(
            `${this.baseUrl}/custom-fields?ids=${JSON.stringify(incidentTypeIds)}`
        );
    }

    public getEditComponent(): Type<BaseStandingDataEditComponent> {
        return IncidentTypeEditComponent;
    }
}
