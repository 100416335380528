import { Component, OnInit } from "@angular/core";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { FilterComponent } from "@enums/workspace/FilterComponent";
import { toPromise } from "@methods/CommonMethods";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { FormatType } from "common/enums/FormatType";
import { RendererType } from "common/enums/RendererType";
import { Pages } from "../../../../common/constants/pages/Pages";
import { FilterType } from "../../../../common/enums/FilterType";
import { ModuleType } from "../../../../common/enums/ModuleType";
import { TabModel } from "../../../../common/models/generic/TabModels/TabModel";
import { FilterConfig } from "../../../../common/models/workspace/FilterConfig";
import { RendererConfig } from "../../../../common/models/workspace/RendererConfig";
import { TabService } from "../../../../services/tabs/TabService";

@Component({
    selector: "app-datamanagement-overview",
    templateUrl: "./datamanagement-overview.component.html",
    styleUrls: ["./datamanagement-overview.component.scss"],
})
export class DataManagementOverviewComponent implements OnInit {
    moduleType = ModuleType.DM;
    tabID: string;
    tab: TabModel;
    data: Promise<any[]>;
    headerLookup: any = {
        riskCount: "# of risks",
        controlCount: "# of controls",
        moiCount: "# of mois",
        documentCount: "# of documents",
    };
    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: "OrganizationID",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: "BusinessDimensionIDs",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: "FrameworkDimensionIDs",
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Data subjects",
            column: "DataSubjectIds",
            type: FilterType.DataSubjects,
            component: FilterComponent.MultiSelectTree,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Datastructures",
            column: "DataStructureIds",
            type: FilterType.DataStructures,
            component: FilterComponent.MultiSelectTree,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Most recent DPIA",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
    ];

    numberProps: string[] = ["riskCount", "controlCount", "moiCount", "documentCount"];
    dateProps: string[] = ["Most recent DPIA"];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _datamanagementDS: DatamanagementDataService,
        private _tabs: TabService,
        public pages: Pages,
        private _permissions: PermissionsService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.dataManagement;
        this.data = this._datamanagementDS.getAll().toPromise().then(this.mapHeaders);
    }

    openDetail(dm: any) {
        this._tabs.generateTab(
            this.pages.DataManagement,
            dm.Guid,
            `(P) ${+dm.Identifier} - ${dm.Name}`
        );
    }

    add() {
        this._tabs.generateTab(this.pages.DataManagement, null, "Add new Data Processing");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
