import { Component, ViewChild, Input } from "@angular/core";
import { LinkableComponent } from "@app/shared/linkable/linkable.component";
import { FilterableIdNameCombination } from "@models/generic/FilterableIdNameCombination";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { RiskLinkModel } from '@models/risk/RiskLinkModel';
import { ThirdPartyDataService } from '../services/ThirdPartyDataService';

@Component({
    selector: 'thirdparty-risks',
    templateUrl: './thirdparty-risks.component.html',
    styleUrls: ['./thirdparty-risks.component.scss']
})
export class ThirdPartyRisksComponent {
    @Input() model: ThirdPartyModel;
    @Input() disabled;

    linked: RiskLinkModel[];
    unlinked: RiskLinkModel[];
    isLoading = true;

    constructor(private _DS: ThirdPartyDataService) { }

    load() {
        if (!this.model) {
            return;
        }

        this._DS.getRisks(this.model.OrganizationResponsibleId).subscribe(
            value => {
                this.loadData(this.model.LinkedRisks, value.sortBy("name", true));
                this.isLoading = false;
            }
        );
    }

    linkedChanged(newList: RiskLinkModel[]) {
        this.model.LinkedRisks = newList.map(x => x.id);
    }

    private loadData(linkedIds: number[], items: RiskLinkModel[]) {
        const linked = [], unlinked = [];

        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }
        this.linked = linked;
        this.unlinked = unlinked;
    }
}