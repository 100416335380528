<form [formGroup]="parentForm" *ngIf="!isLoading">
    <div formArrayName="impactScales">
        <!-- Header Row -->
        <div class="row align-items-center header-row">
            <div
                [class.col]="enableTargetRisk"
                [class.col-xl-2]="!enableTargetRisk"
                class="form-group"
            >
                <label>Leading impact scale</label>
            </div>
            <div
                class="col form-group"
                [class.col-xl-4]="!enableTargetRisk"
                [class.left-block]="!enableTargetRisk"
            >
                <label>Gross</label>
            </div>
            <div
                class="col form-group"
                [class.col-xl-6]="!enableTargetRisk"
                [class.right-block]="!enableTargetRisk"
            >
                <label>Net</label>
            </div>
            <div class="col form-group" *ngIf="enableTargetRisk">
                <label>Target</label>
            </div>
        </div>

        <!-- Dynamic Rows for Inputs -->
        <div
            class="row"
            *ngFor="let impactScale of parentForm.controls.impactScales['controls']; let i = index"
            [formGroupName]="i"
        >
            <div [class.col]="enableTargetRisk" [class.col-xl-2]="!enableTargetRisk">
                <div class="row align-items-center">
                    <div class="col form-group bold">
                        <input
                            type="radio"
                            [attr.id]="'leadingScaleBtn_' + impactScale.controls.ID.value"
                            (click)="leadingImpactScaleClick(impactScale.controls.ID.value)"
                            [checked]="risk.leadingImpactScaleId === impactScale.controls.ID.value"
                        />
                        {{ impactScaleData[impactScale.controls.ID.value].name }}
                    </div>
                </div>
            </div>

            <div
                class="col form-group"
                [class.col-xl-4]="!enableTargetRisk"
                [class.left-block]="!enableTargetRisk"
            >
                <ng-select
                    [attr.id]="'grossImpactSelect_' + impactScale.controls.ID.value"
                    name="grossImpactSelect"
                    [items]="impactScaleData[impactScale.controls.ID.value].items"
                    formControlName="grossImpactId"
                    bindLabel="Name"
                    bindValue="ID"
                    [closeOnSelect]="true"
                    (change)="emitChange()"
                    [readonly]="impactScale.controls.ID.value < 0"
                >
                </ng-select>
            </div>

            <div class="col" [class.right-block]="!enableTargetRisk">
                <div class="form-group">
                    <ng-select
                        [attr.id]="'netImpactSelect_' + impactScale.controls.ID.value"
                        name="netImpactSelect"
                        [items]="impactScaleData[impactScale.controls.ID.value].items"
                        formControlName="netImpactId"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="true"
                        (change)="emitChange()"
                        [readonly]="impactScale.controls.ID.value < 0"
                    >
                    </ng-select>
                </div>
            </div>

            <div class="col" *ngIf="enableTargetRisk">
                <div class="form-group">
                    <ng-select
                        [attr.id]="'targetImpactSelect_' + impactScale.controls.ID.value"
                        name="targetImpactSelect"
                        [items]="impactScaleData[impactScale.controls.ID.value].items"
                        formControlName="targetImpactId"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="true"
                        (change)="emitChange()"
                        [readonly]="impactScale.controls.ID.value < 0"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</form>
