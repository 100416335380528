.form-entry {
    .form-pages {
        margin-top: 10px;
    }

    .workflow-area {
        margin-left: 20px;
    }

    .readonly-comment {
        padding: 5px;
        border-radius: 0.25rem;
        white-space: pre-line;
        background-color: #fff2ac;
        background-image: linear-gradient(to right, #ffe359 0%, #fff2ac 100%);
    }

    .form-group + .form-group {
        margin-top: 25px;
    }

    .form-group.col {
        margin-bottom: 0;
    }

    .global-comment {
        margin: 20px;

        label {
            font-weight: bold;
        }
    }

    .readonly-comment {
        padding: 5px;
        border-radius: 0.25rem;
        white-space: pre-line;
        background-color: #fff2ac;
        background-image: linear-gradient(to right, #ffe359 0%, #fff2ac 100%);
    }

    .form-bottom-controls {
        display: flex;
        justify-content: center;

        .btn + .btn {
            margin-left: 10px;
        }
    }
}
