<div class="detail-page-action-bar">
    <button class="btn btn-cerrix" (click)="export()" [disabled]="!(selectedExportType > 0)">
        <i class="fas fa-save"></i>
        <span class="d-none d-lg-inline">Download export</span>
    </button>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Export configuration</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="fancy-loader submodule" *ngIf="!exportTypes"></div>
        <div *ngIf="exportTypes">
            <div class="form-group">
                <label for="typeSelect">Export type</label>
                <ng-select
                    id="typeSelect"
                    name="typeSelect"
                    [items]="exportTypes"
                    [(ngModel)]="selectedExportType"
                    bindLabel="Value"
                    bindValue="Key"
                    [closeOnSelect]="true"
                    [multiple]="false"
                    [clearable]="false"
                >
                </ng-select>
            </div>
        </div>
    </div>
</div>
