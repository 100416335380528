<div class="form-overview" data-uiid="form-overview">
    <div class="detail-page-action-bar">
        <button
            class="btn btn-cerrix me-2"
            (click)="tabService.generateTab(pages.FormDetail, 0, 'New Form')"
            *ngIf="permissions.IsCreator"
        >
            <i class="fas fa-plus-circle"></i>
            <span class="d-none d-lg-inline">Add new form</span>
        </button>

        <input
            data-uiid="input-formfilter"
            type="text"
            class="form-control quick-search ms-2"
            placeholder="Search / filter forms"
            (debounce-input-event)="this.filterForms()"
            [(ngModel)]="filter"
            inputTooltip
        />

        <div
            class="workflow-area"
            *ngIf="permissions.IsCategoryAdministrator || permissions.IsGroupAdministrator"
        >
            <div class="btn-group extra-actions">
                <button
                    class="btn btn-outline-secondary"
                    (click)="openCategoriesSD()"
                    title="Categories"
                    *ngIf="permissions.IsCategoryAdministrator"
                >
                    <i class="fas fa-th-large" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">Categories</span>
                </button>
                <button
                    class="btn btn-outline-secondary"
                    (click)="openGroupSD()"
                    title="Groups"
                    *ngIf="permissions.IsGroupAdministrator"
                >
                    <i class="fas fa-users" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">Groups</span>
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!scopedCategories">
        <div class="fancy-loader"></div>
    </ng-container>

    <ng-container *ngIf="scopedCategories">
        <div class="no-forms-info" *ngIf="scopedCategories.length == 0">
            <ng-container *ngIf="!filter">There are no forms available.</ng-container>
            <ng-container *ngIf="filter"
                >There are no forms found matching search criteria.</ng-container
            >
        </div>

        <div class="all-forms overview-content" *ngIf="scopedCategories.length > 0">
            <div class="detail-page-card" *ngFor="let group of scopedCategories">
                <div class="detail-page-card-title">
                    {{ group.Category }}
                </div>
                <div class="detail-page-card-body padded">
                    <ul class="form-cards">
                        <li
                            class="card clickable"
                            *ngFor="let form of group.Forms"
                            data-uiid="form-preview"
                        >
                            <div
                                class="card-img-top main"
                                (click)="openForm(form)"
                                [style.backgroundImage]="
                                    'url(' +
                                    (form.ImageGuid
                                        ? '/thumbnails/FormsOverview/' + form.ImageGuid + '.png'
                                        : '/static/images/form_logo_fallback.jpg') +
                                    ')'
                                "
                                data-uiid="btn-open-form"
                            ></div>
                            <div class="card-body" (click)="openForm(form)">
                                <h5 class="card-title" data-uiid="label-form-title">
                                    {{ form.Title }}
                                    <i
                                        *ngIf="form.CanEdit && form.HasDraft"
                                        class="fas fa-file-signature ms-2"
                                        matTooltip="This form has draft(s) available."
                                    ></i>
                                </h5>
                                <p class="card-text" title="{{ form.Description }}">
                                    {{ form.Description }}
                                </p>
                            </div>
                            <div
                                class="card-footer text-muted text-start"
                                *ngIf="
                                    form.CanEdit ||
                                    form.CanToggleStatus ||
                                    form.CanDelete ||
                                    form.CanInvite
                                "
                            >
                                <button
                                    type="button"
                                    class="btn btn-cerrix"
                                    data-uiid="button-edit-form"
                                    *ngIf="form.CanEdit"
                                    (click)="editForm(form)"
                                >
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                    <span class="d-none d-xl-inline">Edit</span>
                                </button>
                                <button
                                    type="button"
                                    data-uiid="button-togglestatus"
                                    *ngIf="form.CanToggleStatus"
                                    (click)="toggleIsOpenForEntries(form)"
                                    [matTooltip]="
                                        form.IsOpenForEntries
                                            ? form.FormOutOfDateRange
                                                ? 'Form is open but is outside daterange'
                                                : 'Form is open'
                                            : 'Form is closed'
                                    "
                                    class="btn btn-{{
                                        form.IsOpenForEntries
                                            ? form.FormOutOfDateRange
                                                ? 'warning'
                                                : 'success'
                                            : 'danger'
                                    }} ms-1"
                                >
                                    <i
                                        class="fas fa-toggle-{{
                                            form.IsOpenForEntries ? 'on' : 'off'
                                        }}"
                                        *ngIf="!form.IsOpenForEntries || !form.FormOutOfDateRange"
                                        aria-hidden="true"
                                    ></i>
                                    <i
                                        class="fas fa-calendar-times"
                                        *ngIf="form.IsOpenForEntries && form.FormOutOfDateRange"
                                        aria-hidden="true"
                                    ></i>
                                    <span class="d-none d-xl-inline ms-2">
                                        {{
                                            form.IsOpenForEntries
                                                ? "Click to close"
                                                : "Click to open"
                                        }}
                                    </span>
                                </button>
                                <div
                                    class="btn-group dropup float-end"
                                    *ngIf="form.CanDelete || form.CanInvite"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-outline-secondary dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-uiid="button-form-options"
                                    >
                                        <i class="fas fa-ellipsis-v fa-lg" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <!-- Dropdown menu links -->
                                        <a
                                            class="dropdown-item"
                                            data-uiid="button-delete-form"
                                            (click)="deleteForm(form)"
                                            *ngIf="form.CanDelete"
                                            >Delete</a
                                        >
                                        <a
                                            class="dropdown-item"
                                            data-uiid="button-invite-form"
                                            (click)="inviteForm(form)"
                                            *ngIf="form.CanInvite"
                                            >Invite</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>
</div>
