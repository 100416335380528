import { Component, Input, OnInit } from "@angular/core";
import { LinkedAssessmentModel } from "@app/assessment/shared/models";
import { RendererType } from "@enums/RendererType";
import { FormatType } from "@enums/FormatType";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { AssessmentDataService } from "@app/assessment/services/assessment-data.service";
import { FindingReportDataService } from "@app/findingreport/services";
import { finalize, map } from "rxjs/operators";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "assessment-linked-overview",
    templateUrl: "./assessment-linked-overview.component.html",
    styleUrls: ["./assessment-linked-overview.component.scss"],
})
export class AssessmentLinkedOverviewComponent implements OnInit {
    @Input() parentGuid: string;

    @Input() canAdd = false;
    @Input() canDelete = false;

    genericListConfig: GenericListConfig;

    hasAccessChecks = {};
    accessCheckInProgress = false;

    constructor(
        private assessmentDataService: AssessmentDataService,
        private findingReportDataService: FindingReportDataService,
        private tabService: TabService,
        private pages: Pages,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.loadConfig();
    }

    private loadConfig() {
        this.genericListConfig = {
            idProp: "guid",
            uiidAdd: "assessments-addbutton",
            uiidDelete: "assessments-deletebutton",
            name: "Assessments",
            isSortable: false,
            emptyMessage: "No assessments",
            allowAdd: this.canAdd,
            allowDelete: this.canDelete,
            allowEdit: this.canAdd,
            dataMethod: this.getAssessments.bind(this),
            fields: [
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "identifier",
                    prettyName: "Identifier",
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "assessmentTypeName",
                    prettyName: "Assessment type",
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "overallScore",
                    prettyName: "Overall score",
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "overallScoreColor",
                    prettyName: "Overall score color",
                    hideInOverview: true,
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "assessorName",
                    prettyName: "Assessor",
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "assessorOrganizationName",
                    prettyName: "Organization assessor",
                },
                {
                    fieldType: GenericListFieldType.Number,
                    fieldName: "numberOfLinkedMois",
                    prettyName: "# linked MoIs",
                },
                {
                    fieldType: GenericListFieldType.Date,
                    fieldName: "assessmentDate",
                    prettyName: "Assessment date",
                },
            ],
            rendererConfig: [
                {
                    textColumn: "assessmentDate",
                    type: RendererType.Default,
                    formatType: FormatType.DateFormat,
                },
                {
                    textColumn: "overallScore",
                    actionColumn: "overallScoreColor",
                    hideActionColumn: true,
                    type: RendererType.Score,
                },
            ],
            addOverride: () => {
                this.tabService.generateTab(this.pages.AssessmentDetail, null, "Assessment", {
                    parentGuid: this.parentGuid,
                });
            },
            editOverride: async (model: LinkedAssessmentModel) => {
                this.openAssessment(model);
            },
            delete: async (model) => {
                if (
                    await this._promptService
                        .confirm(
                            "Delete assessment",
                            `Delete selected '${model.assessmentTypeName}'?`
                        )
                        .toPromise()
                ) {
                    return this.assessmentDataService
                        .deleteAssessment(model.guid)
                        .pipe(map(() => true))
                        .toPromise();
                }

                return false;
            },
        };
    }

    private getAssessments() {
        return this.findingReportDataService.getLinkedAssessments(this.parentGuid);
    }

    openAssessment(item: LinkedAssessmentModel): void {
        const hasAccess = this.hasAccessChecks[item.guid];
        if (hasAccess == null && !this.accessCheckInProgress) {
            this.accessCheckInProgress = true;

            const loadingPrompt = this._promptService.loader("Checking rights, please wait...");

            this.assessmentDataService
                .hasAccessToAssessment(this.parentGuid, item.guid)
                .pipe(finalize(() => loadingPrompt.close()))
                .subscribe((x) => {
                    this.hasAccessChecks[item.guid] = x;

                    this.openTab(item, x);
                    this.accessCheckInProgress = false;
                });
        } else {
            this.openTab(item, hasAccess);
        }
    }

    private openTab(model: LinkedAssessmentModel, hasAccess: boolean): void {
        if (hasAccess === true) {
            this.tabService.generateTab(
                this.pages.AssessmentDetail,
                model.guid,
                `(A) ${+model.identifier} - ${model.assessmentTypeName}`,
                { parentGuid: this.parentGuid }
            );
        } else {
            this._toastr.warning("Not enough rights to open this Assessment.");
        }
    }
}
