import { GenericListField } from "./GenericListField";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { Observable } from "rxjs";
import { LinkData } from "@app/shared/linkable/linkable.component";

export class GenericManagerConfig {
    name?: string;

    idProp?: string;
    uiid?: string;
    uiidAdd?: string;
    uiidDelete?: string;

    emptyMessage?: string;
    hideEmptyResult?: boolean;

    allowAdd?: boolean;
    allowEdit?: boolean;
    allowDelete?: boolean;
    allowLink?: boolean;

    addButtonTooltip?: string;

    hideAddButton?: boolean;
    hideDeleteButton?: boolean;
    hideRefreshButton?: boolean;
    hideLinkButton?: boolean;

    hideHeader?: boolean;
    overviewSortBy?: string;
    overviewSortAsc?: boolean;
    overviewRowActions?: GenericListButton[];
    overviewRowActionsName?: string;

    editWindowButtons?: GenericEditWindowButton[];

    rendererConfig?: RendererConfig[];
    fields?: GenericListField[];
    data?: any;

    linkData?: LinkData;
    onLinkedDataChange?: (data: any[]) => void;

    dataMethod?(): Observable<any>;
    dataChanged?(data: any): void;
    rowValueChanged?(currentRow: any, updatedValues: any): void;

    /** This will be set after initial create. This can be called to recreate the manager without a reference to it! */
    reload?(): Promise<void>;

    addOverride?();
    add?(row: any): Promise<boolean>;
    preAddCheck?(): boolean;

    editOverride?(row: any);
    edit?(row: any, uneditedRow: any): Promise<boolean>;
    preEditCheck?(row: any): boolean;

    deleteOverride?(row: any);
    delete?(row: any): Promise<boolean>;
    preDeleteCheck?(row: any): boolean;

    linkOverride?();
    link?(row: any): Promise<boolean>;
    preLinkCheck?(): boolean;
}

export class GenericListButton {
    icon: string;
    tooltip: string;
    showProperty?: string;
    dataUiid?: string;
    clickEvent?(row: any): void;
}

export class GenericEditWindowButton {
    text: string;
    icon: string;
    class: string;
    uiid?: string;
    clickEvent?(row: any): boolean | Promise<boolean>;
}
