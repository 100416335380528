import { DocumentModel } from "@models/documents/documentmodel";
import { IHasDocuments } from "@methods/DocumentMethods";

export interface IModule {
    guid: string;
    name: string;
    identifier: string;
    cellId: string;

    readonly label: string;
}

export class Meta {
    processName: string;
    processTextPath: string;

    processDescription: string;
    comment: string;
    author: string;
    id: number;
    version: string;
    authorizedBy: string;
    date: string;
    reviewer: string;
    scoreReviewer: number;
}

export class RiskItem implements IModule {
    cellRiskID: number; // PK
    cellId: string;
    riskID: number;
    guid: string;
    identifier: string;
    name: string;
    description: string;
    isHidden: string;
    riskConfidential: boolean;

    get label() {
        return this.identifier + " - " + this.name;
    }

    constructor(data: RiskItem) {
        Object.assign(this, data);
    }
}

class CellItem {
    cellId: string;
    cellLabel: string;
    comment: string;
    shape: string;
}

export class ProcessCellAttribute {
    shapeName: string;
    isEdge: boolean;

    label: string;
    comment?: string;
    order_nr?: string;
    linked_id?: string;
    include_in_print: string;
}

export class RaciItem {
    cellId: string;
    functionId: number;
    id: number;
    functionName: string;

    isResponsible: boolean;
    isAccountable: boolean;
    isConsulted: boolean;
    isInformed: boolean;
}

export class ControlItem implements IModule {
    cellRiskID: number; // PK
    cellId: string;
    controlID: number;
    guid: string;
    identifier: string;
    name: string;
    description: string;

    get label() {
        return this.identifier + " - " + this.name;
    }

    constructor(data: ControlItem) {
        Object.assign(this, data);
    }
}

export class DiagramData implements IHasDocuments {
    id: string;

    /// <summary>
    ///     The title of the graph
    /// </summary>
    caption: string;
    xmlGraph: string;
    previewImage: string;

    metadata: Meta;

    risks: RiskItem[];
    controls: ControlItem[];
    cells: CellItem[];
    racis: RaciItem[];
    Documents: DocumentModel[];
    canManageDocuments: boolean;

    constructor(data: DiagramData) {
        if (!data) {
            return;
        }

        Object.assign(this, data);

        this.risks = data.risks.map((r) => new RiskItem(r));
        this.controls = data.controls.map((c) => new ControlItem(c));
    }
}
