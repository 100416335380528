import { Component, OnInit } from "@angular/core";
import { RiskWorkspaceModel } from "@app/risk/models/RiskWorkspaceModel";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { CustomFieldHelper } from "@app/shared/helpers/customField-helper";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { FilterType } from "@enums/FilterType";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { StandingDataType } from "@enums/StandingDataType";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { FilterComponent } from "@enums/workspace/FilterComponent";
import { nameof, toPromise } from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { ActiveToast, ToastrService } from "ngx-toastr";

@Component({
    selector: "app-risk-overview",
    templateUrl: "./risk-overview.component.html",
    styleUrls: ["./risk-overview.component.scss"],
})
export class RiskOverviewComponent implements OnInit {
    moduleType = ModuleType.ORM;
    tabID: string;
    tab: TabModel;
    data: Promise<any[]>;
    headerLookup: { [propName: string]: string } = RiskOverviewComponent.getHeaderLookup();

    private static getHeaderLookup(): { [propName: string]: string } {
        let headerLookup: any = {};
        headerLookup[nameof<RiskWorkspaceModel>("identifier")] = "Identifier";
        headerLookup[nameof<RiskWorkspaceModel>("riskName")] = "Risk name";
        headerLookup[nameof<RiskWorkspaceModel>("grossScore")] = "Gross score";
        headerLookup[nameof<RiskWorkspaceModel>("netScore")] = "Net score";
        headerLookup[nameof<RiskWorkspaceModel>("riskAppetite")] = "Risk appetite";
        headerLookup[nameof<RiskWorkspaceModel>("overallRiskAssessment")] = 
            "Overall risk assessment";
        headerLookup[nameof<RiskWorkspaceModel>("assessmentDate")] = "Assessment date";
        headerLookup[nameof<RiskWorkspaceModel>("organization")] = "Organization";
        headerLookup[nameof<RiskWorkspaceModel>("riskCatalogue")] = "Risk catalogue";
        headerLookup[nameof<RiskWorkspaceModel>("subtypes")] = "Subtypes";
        headerLookup[nameof<RiskWorkspaceModel>("businessDimensions")] = "Business dimensions";
        headerLookup[nameof<RiskWorkspaceModel>("frameworkDimensions")] = "Framework dimensions";
        headerLookup[nameof<RiskWorkspaceModel>("riskArea")] = "Risk area";
        headerLookup[nameof<RiskWorkspaceModel>("eventCategory")] = "Event category";
        headerLookup[nameof<RiskWorkspaceModel>("cause")] = "Cause";
        headerLookup[nameof<RiskWorkspaceModel>("effect")] = "Effect";
        headerLookup[nameof<RiskWorkspaceModel>("grossImpact")] = "Gross impact";
        headerLookup[nameof<RiskWorkspaceModel>("grossLikelihood")] = "Gross likelihood";
        headerLookup[nameof<RiskWorkspaceModel>("netImpact")] = "Net impact";
        headerLookup[nameof<RiskWorkspaceModel>("netLikelihood")] = "Net likelihood";
        headerLookup[nameof<RiskWorkspaceModel>("quantitativeScoringPlaceholder")] = "-";
        headerLookup[nameof<RiskWorkspaceModel>("confidential")] = "Confidential";
        headerLookup[nameof<RiskWorkspaceModel>("applicable")] = "Applicable";
        headerLookup[nameof<RiskWorkspaceModel>("reviewed")] = "Reviewed";
        headerLookup[nameof<RiskWorkspaceModel>("reviewedDate")] = "Reviewed date";
        headerLookup[nameof<RiskWorkspaceModel>("reviewedBy")] = "Reviewed by";
        headerLookup[nameof<RiskWorkspaceModel>("approved")] = "Approved";
        headerLookup[nameof<RiskWorkspaceModel>("approvedDate")] = "Approved date";
        headerLookup[nameof<RiskWorkspaceModel>("approvedBy")] = "Approved by";
        headerLookup[nameof<RiskWorkspaceModel>("keyRisk")] = "Key risk";
        headerLookup[nameof<RiskWorkspaceModel>("owner")] = "Owner";
        headerLookup[nameof<RiskWorkspaceModel>("description")] = "Description";
        headerLookup[nameof<RiskWorkspaceModel>("treatment")] = "Treatment";
        headerLookup[nameof<RiskWorkspaceModel>("commentOnOverallRiskAssessment")] = "Comment on overall risk assessment";
        headerLookup[nameof<RiskWorkspaceModel>("comments")] = "Comments";
        headerLookup[nameof<RiskWorkspaceModel>("dateModified")] = "Date modified";
        headerLookup[nameof<RiskWorkspaceModel>("dateCreated")] = "Date created";
        headerLookup[nameof<RiskWorkspaceModel>("connectedControls")] = "Connected controls";
        headerLookup[nameof<RiskWorkspaceModel>("connectedMois")] = "Connected MoIs";
        headerLookup[nameof<RiskWorkspaceModel>("connectedEvents")] = "Connected events";
        headerLookup[nameof<RiskWorkspaceModel>("numberOfDocuments")] = "# of documents";
        headerLookup[nameof<RiskWorkspaceModel>("organizationPath")] = "Organization path";
        headerLookup[nameof<RiskWorkspaceModel>("targetRiskPlaceholder")] = "-";
        headerLookup[nameof<RiskWorkspaceModel>("leadingImpactScale")] = "Leading Impact Scale";
        headerLookup[nameof<RiskWorkspaceModel>("impactScalesPlaceholder")] = "-";
        headerLookup[nameof<RiskWorkspaceModel>("customFieldsPlaceholder")] = "-";

        return headerLookup;
    }

    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: nameof<RiskWorkspaceModel>("organizationID"),
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: nameof<RiskWorkspaceModel>("businessDimensionIDs"),
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: nameof<RiskWorkspaceModel>("frameworkDimensionIDs"),
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Risk Catalogues",
            column: nameof<RiskWorkspaceModel>("riskCatalogueID"),
            type: FilterType.RiskCatalogue,
            hideActionColumn: true,
            param: "",
            component: FilterComponent.ComboBox,
        },
        {
            label: "Period",
            column: "Period",
            type: FilterType.Period,
            param: "",
            module: ModuleType.ORM,
            remoteFilter: true,
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: nameof<RiskWorkspaceModel>("grossImpact"),
            actionColumn: nameof<RiskWorkspaceModel>("grossImpactColor"),
            hideActionColumn: true,
            type: RendererType.Score,
            columnWidth: 200,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("grossLikelihood"),
            actionColumn: nameof<RiskWorkspaceModel>("grossLikelihoodColor"),
            hideActionColumn: true,
            type: RendererType.Score,
            columnWidth: 200,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("grossScore"),
            actionColumn: nameof<RiskWorkspaceModel>("grossScoreColor"),
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("netImpact"),
            actionColumn: nameof<RiskWorkspaceModel>("netImpactColor"),
            hideActionColumn: true,
            type: RendererType.Score,
            columnWidth: 200,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("netLikelihood"),
            actionColumn: nameof<RiskWorkspaceModel>("netLikelihoodColor"),
            hideActionColumn: true,
            type: RendererType.Score,
            columnWidth: 200,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("netScore"),
            actionColumn: nameof<RiskWorkspaceModel>("netScoreColor"),
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("overallRiskAssessment"),
            actionColumn: nameof<RiskWorkspaceModel>("overallRiskAssessmentColor"),
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("riskAppetite"),
            actionColumn: nameof<RiskWorkspaceModel>("riskAppetiteColor"),
            hideActionColumn: true,
            type: RendererType.Score,
        },

        {
            textColumn: nameof<RiskWorkspaceModel>("reviewedDate"),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("approvedDate"),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("dateModified"),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("dateCreated"),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: nameof<RiskWorkspaceModel>("assessmentDate"),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
    ];

    numberProps: string[] = [
        nameof<RiskWorkspaceModel>("grossScore"),
        nameof<RiskWorkspaceModel>("netScore"),
        nameof<RiskWorkspaceModel>("numberOfDocuments"),
    ];

    dateProps: string[] = [
        nameof<RiskWorkspaceModel>("approvedDate"),
        nameof<RiskWorkspaceModel>("dateCreated"),
        nameof<RiskWorkspaceModel>("dateModified"),
        nameof<RiskWorkspaceModel>("reviewedDate"),
        nameof<RiskWorkspaceModel>("assessmentDate"),
    ];

    permissions: WorkspaceModulePermissionModel;

    currentlyLoadedPeriod = null;
    periodFilterToast: ActiveToast<any> = null;

    initialLoadCompleted = false;

    constructor(
        private _riskDS: RiskDataService,
        private standingDataService: StandingdataDataService,
        private _tabs: TabService,
        public pages: Pages,
        private _permissions: PermissionsService,
        private toastr: ToastrService,
        private appSettingsDs: SettingsDataService
    ) {}

    ngOnInit(): void {
        this.permissions = this._permissions.permissions.Workspace.risk;
        this.data = this.getFormattedData();

        this._tabs.listeners.addGlobalListener(
            TabEventListenerType.OnSave,
            this.pages.RiskDetail,
            () => {
                this.tab.refresh();
            },
            this.tab
        );
    }

    async getFormattedData(periodID?: number): Promise<RiskWorkspaceModel[]> {
        const data = periodID
            ? await toPromise(this._riskDS.getByPeriodId(this.currentlyLoadedPeriod))
            : await toPromise(this._riskDS.getList());

        await this.configureCauseAndEffect();
        await this.configureQuantitativeScoring();

        const useTargetRisk = await toPromise(
            this.appSettingsDs.getSetting(ApplicationSettings.EnableTargetRisk)
        );

        await this.configureTargetRisk(useTargetRisk.BoolValue);

        // #6896 Make sure custom impact scales are the first dynamic fields that are added, because of order of fields.
        await this.configureImpactScales(useTargetRisk.BoolValue, data);
        await this.expandStandingDataCustomFields(data);

        this.initialLoadCompleted = true;
        return this.mapHeaders(data);
    }

    async configureCauseAndEffect(): Promise<void> {
        const showCauseField = await toPromise(
            this.appSettingsDs.getSetting(ApplicationSettings.ShowCauseField)
        );

        if (!showCauseField.BoolValue) {
            this.replaceHeaderLookupPlaceholder(nameof<RiskWorkspaceModel>("cause"), null);
        }

        const ShowEffectField = await toPromise(
            this.appSettingsDs.getSetting(ApplicationSettings.ShowEffectField)
        );

        if (!ShowEffectField.BoolValue) {
            this.replaceHeaderLookupPlaceholder(nameof<RiskWorkspaceModel>("effect"), null);
        }
    }

    async configureQuantitativeScoring(): Promise<void> {
        const useQuantitativeScoring = await toPromise(
            this.appSettingsDs.getSetting(ApplicationSettings.ShowQuantitativeScoringDetails)
        );

        const placeholderProp = nameof<RiskWorkspaceModel>("quantitativeScoringPlaceholder");
        if (!useQuantitativeScoring.BoolValue) {
            this.replaceHeaderLookupPlaceholder(placeholderProp, null);

            return;
        }

        const extraHeaders = {
            [nameof<RiskWorkspaceModel>("grossSingleLossExpectancy")]:
                "Gross single loss Expectancy",
            [nameof<RiskWorkspaceModel>("grossQuantitativeLikelihood")]: "Gross likelihood %",
            [nameof<RiskWorkspaceModel>("grossAnnualizedLossExpectancy")]:
                "Gross Annualized loss Expectancy",
            [nameof<RiskWorkspaceModel>("netSingleLossExpectancy")]: "Net single loss Expectancy",
            [nameof<RiskWorkspaceModel>("netQuantitativeLikelihood")]: "Net likelihood %",
            [nameof<RiskWorkspaceModel>("netAnnualizedLossExpectancy")]:
                "Net Annualized loss Expectancy",
        };

        this.replaceHeaderLookupPlaceholder(placeholderProp, extraHeaders);

        this.rendererConfig.push(
            {
                textColumn: nameof<RiskWorkspaceModel>("grossSingleLossExpectancy"),
                type: RendererType.Default,
                formatType: FormatType.DecimalFormat,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("grossAnnualizedLossExpectancy"),
                type: RendererType.Default,
                formatType: FormatType.DecimalFormat,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("netSingleLossExpectancy"),
                type: RendererType.Default,
                formatType: FormatType.DecimalFormat,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("netAnnualizedLossExpectancy"),
                type: RendererType.Default,
                formatType: FormatType.DecimalFormat,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("grossQuantitativeLikelihood"),
                type: RendererType.Default,
                formatType: FormatType.PercentFormat,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("netQuantitativeLikelihood"),
                type: RendererType.Default,
                formatType: FormatType.PercentFormat,
            }
        );

        this.numberProps.addRange([
            nameof<RiskWorkspaceModel>("grossSingleLossExpectancy"),
            nameof<RiskWorkspaceModel>("grossQuantitativeLikelihood"),
            nameof<RiskWorkspaceModel>("grossAnnualizedLossExpectancy"),
            nameof<RiskWorkspaceModel>("netSingleLossExpectancy"),
            nameof<RiskWorkspaceModel>("netQuantitativeLikelihood"),
            nameof<RiskWorkspaceModel>("netAnnualizedLossExpectancy"),
        ]);
    }

    async configureTargetRisk(useTargetRisk: boolean): Promise<void> {
        const placeholderProp = nameof<RiskWorkspaceModel>("targetRiskPlaceholder");
        if (!useTargetRisk) {
            this.replaceHeaderLookupPlaceholder(placeholderProp, null);

            return;
        }

        const extraHeaders = {
            [nameof<RiskWorkspaceModel>("targetImpact")]: "Target impact",
            [nameof<RiskWorkspaceModel>("targetLikelihood")]: "Target likelihood",
            [nameof<RiskWorkspaceModel>("targetScore")]: "Target score",
        };

        this.replaceHeaderLookupPlaceholder(placeholderProp, extraHeaders);

        this.rendererConfig.push(
            {
                textColumn: nameof<RiskWorkspaceModel>("targetImpact"),
                actionColumn: nameof<RiskWorkspaceModel>("targetImpactColor"),
                hideActionColumn: true,
                type: RendererType.Score,
                columnWidth: 200,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("targetLikelihood"),
                actionColumn: nameof<RiskWorkspaceModel>("targetLikelihoodColor"),
                hideActionColumn: true,
                type: RendererType.Score,
                columnWidth: 200,
            },
            {
                textColumn: nameof<RiskWorkspaceModel>("targetScore"),
                actionColumn: nameof<RiskWorkspaceModel>("targetScoreColor"),
                hideActionColumn: true,
                type: RendererType.Score,
            }
        );

        this.numberProps.push(nameof<RiskWorkspaceModel>("targetScore"));
    }

    getData(keyWords: SearchKeyword[]): void {
        // For now there is always 1 serverfilter, which is the periodId
        this.currentlyLoadedPeriod = null;
        if (keyWords && keyWords.length > 0 && +keyWords[0].searchValue > 0) {
            this.currentlyLoadedPeriod = +keyWords[0].searchValue;
        }

        this.data = this.getFormattedData(this.currentlyLoadedPeriod);
    }

    async openDetail(risk: RiskWorkspaceModel): Promise<void> {
        if (this.currentlyLoadedPeriod > 0) {
            const response = await toPromise(this._riskDS.getBaseGuidFromPeriodGuid(risk.guid));
            if (isGuid(response)) {
                if (response !== risk.guid) {
                    const msg = `Opening historical items is currently not available. Click here to open '${risk["Risk name"]}' in the current state.`;
                    this.createPeriodToast("Risk not available", msg, () => {
                        this.openTab(response, `(R) Risk`);
                    });

                    return;
                }
            } else {
                const msg = `Opening historical items is currently not available.`;
                this.createPeriodToast("Risk not available", msg, null);

                return;
            }
        }

        this.openTab(risk.guid, `(R) Risk`);
    }

    createPeriodToast(title: string, msg: string, onTab?: () => any): void {
        if (this.periodFilterToast) {
            this.periodFilterToast.toastRef.close();
        }

        this.periodFilterToast = this.toastr.info(msg, title);
        if (onTab) {
            this.periodFilterToast.onTap.subscribe(() => {
                onTab();
            });
        }
    }

    add(): void {
        this.openTab(null, `(R) New Risk`);
    }

    private openTab(guid: string, name: string): void {
        this._tabs.generateTab(this.pages.RiskDetail, guid, name);
    }

    private async getStandingData(
        standingDataType: StandingDataType
    ): Promise<IdNameCombination[]> {
        // Retrieve standing data fields
        const sd: IdNameCombination[] = await toPromise(
            this.standingDataService.getMaintenanceListByType(standingDataType)
        );

        return sd;
    }

    private async expandStandingDataCustomFields(data: RiskWorkspaceModel[]): Promise<void> {
        const expandableFields = (
            await toPromise(this.standingDataService.getAllByType(StandingDataType.RiskCustomField))
        ).filter((x) => x.visible);

        CustomFieldHelper.expandCustomFields(
            data,
            nameof<RiskWorkspaceModel>("customFields"),
            expandableFields,
            this.headerLookup,
            this.rendererConfig
        );
    }

    private mapHeaders = (rows: RiskWorkspaceModel[]): RiskWorkspaceModel[] => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    private async configureImpactScales(
        useTargetRisk: boolean,
        rows: RiskWorkspaceModel[]
    ): Promise<void> {
        const impactScales = await this.getStandingData(StandingDataType.RiskImpactScales);

        if (!this.initialLoadCompleted) {
            for (const impactScale of impactScales) {
                this.rendererConfig.push({
                    textColumn: "grossImpactScales_" + impactScale.ID,
                    actionColumn: "grossImpactScaleColors_" + impactScale.ID,
                    hideActionColumn: true,
                    type: RendererType.Score,
                    columnWidth: 200,
                });

                this.rendererConfig.push({
                    textColumn: "netImpactScales_" + impactScale.ID,
                    actionColumn: "netImpactScaleColors_" + impactScale.ID,
                    hideActionColumn: true,
                    type: RendererType.Score,
                    columnWidth: 200,
                });

                if (useTargetRisk) {
                    this.rendererConfig.push({
                        textColumn: "targetImpactScales_" + impactScale.ID,
                        actionColumn: "targetImpactScaleColors_" + impactScale.ID,
                        hideActionColumn: true,
                        type: RendererType.Score,
                        columnWidth: 200,
                    });
                }
            }
        }

        CustomFieldHelper.expandCustomFields(
            rows,
            nameof<RiskWorkspaceModel>("grossImpactScales"),
            impactScales,
            this.headerLookup,
            this.rendererConfig,
            (name) => name + " - Gross"
        );

        CustomFieldHelper.expandCustomFields(
            rows,
            nameof<RiskWorkspaceModel>("netImpactScales"),
            impactScales,
            this.headerLookup,
            this.rendererConfig,
            (name) => name + " - Net"
        );

        if (useTargetRisk) {
            CustomFieldHelper.expandCustomFields(
                rows,
                nameof<RiskWorkspaceModel>("targetImpactScales"),
                impactScales,
                this.headerLookup,
                this.rendererConfig,
                (name) => name + " - Target"
            );
        }

        CustomFieldHelper.expandCustomFields(
            rows,
            nameof<RiskWorkspaceModel>("grossImpactScaleColors"),
            impactScales,
            this.headerLookup,
            this.rendererConfig,
            (name) => name + " - Gross_Color"
        );

        CustomFieldHelper.expandCustomFields(
            rows,
            nameof<RiskWorkspaceModel>("netImpactScaleColors"),
            impactScales,
            this.headerLookup,
            this.rendererConfig,
            (name) => name + " - Net_Color"
        );

        if (useTargetRisk) {
            CustomFieldHelper.expandCustomFields(
                rows,
                nameof<RiskWorkspaceModel>("targetImpactScaleColors"),
                impactScales,
                this.headerLookup,
                this.rendererConfig,
                (name) => name + " - Target_Color"
            );
        }
    }

    private replaceHeaderLookupPlaceholder(
        placeholder: string,
        extraHeaders: { [propName: string]: string }
    ): void {
        if (extraHeaders && Object.keys(extraHeaders).length > 0) {
            // Reconstruct the object, adding the new entries in place of the placeholder
            let expandedObj: { [propName: string]: string } = {};

            // Iterate over the original object keys in order
            for (const key in this.headerLookup) {
                if (key === placeholder) {
                    // Add the new entries instead of "placeholder"
                    Object.assign(expandedObj, extraHeaders);
                } else {
                    // Copy the existing key-value pairs
                    expandedObj[key] = this.headerLookup[key];
                }
            }

            this.headerLookup = expandedObj;
        } else {
            if (placeholder in this.headerLookup) {
                delete this.headerLookup[placeholder]; // Remove the placeholder
            }
        }
    }
}
