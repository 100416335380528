<cerrix-collapsing-box boxTitle="Word" [isCollapsed]="true">
    <ng-template #content>
        <div class="title">Shortcuts</div>
        <div class="data-grid mb-3">
            <div class="header">
                <kbd><kbd>CTRL</kbd> + <kbd>F9</kbd></kbd>
            </div>
            <div class="value">Add field</div>

            <div class="header">
                <kbd><kbd>ALT</kbd> + <kbd>F9</kbd></kbd>
            </div>
            <div class="value">Toggle fields visibility</div>
        </div>

        <div class="title">Example</div>
        <div class="alert alert-warning" role="alert">
            Fields must be created manually and can't be copied from this example!
        </div>
        <div class="alert alert-info" role="alert">
            Content between table start and end will be repeated for each record. This can also be
            used in a table by including it at the start of the first column and end of last column.
        </div>
        <div class="bg-light border ps-2">
            <pre><samp>
Audit
Name: <b>{{"{MERGEFIELD Name}"}}</b>
Criticality: <b>{{"{MERGEFIELD Criticality}"}}{{"{MERGEFIELD CriticalityColor}"}}</b>

Findings
<b>{{"{MERGEFIELD TableStart:Findings}"}}</b>
Name: <b>{{"{MERGEFIELD Findings:Name}"}}</b>

Finding MoIs
<b>{{"{MERGEFIELD TableStart:FindingMois}"}}</b>
Name: <b>{{"{MERGEFIELD FindingMois:Name}"}}</b>
Date: <b>{{"{MERGEFIELD FindingMois:DueDate \\@ \"yyyy-MM-dd\"}"}}</b>
<b>{{"{MERGEFIELD TableEnd:FindingMois}"}}</b>

<b>{{"{MERGEFIELD TableEnd:Findings}"}}</b></samp></pre>
        </div>
    </ng-template>
</cerrix-collapsing-box>
