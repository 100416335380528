export enum DashboardGraphType {
    bar = 100,
    pie = 200,
    column = 300,
}

// The string values has to be the graph type of the chart (has to be exact)
export const DashboardGraphTypeDescription: { [type in DashboardGraphType]: string } = {
    100: "Bar chart",
    200: "Pie chart",
    300: "Column chart",
};

export const DashboardGraphTypeHighchartMapping: { [type in DashboardGraphType]: string } = {
    100: "bar",
    200: "pie",
    300: "column",
};
