export class WorkspaceButtonConfig {
    text: string;

    customTooltip?: string;
    icon?: string;
    isDisabled?: boolean;

    /**
     * Property to define if the config should be clickable if no item is selected.
     * Also makes sure the clickEvent gets an itemID when clicked.
     * @type Boolean
     */
    rowRequired?: boolean;

    /**
     * Should the full range (all items visible) be passed as a param.
     */
    getVisibleRange?: boolean;

    /**
     * Action that needs to be run when config is clicked in bottom bar.
     * @param selection Ids of item(s) that are selected/visible depending on rowRequired and getVisibleRange properties.
     */
    clickEvent?(selection?: number | string | number[] | string[]): void;
}
