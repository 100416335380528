<div class="btn-group">
    <button class="navbar-button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fal fa-question-circle"></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li class="dropdown-item" (click)="openManuals()">
            <span class="icon">
                <i class="fas fa-books"></i>
            </span>
            <span class="text">Manuals</span>
        </li>
        <li class="dropdown-item" (click)="openReleaseNotes()">
            <span class="icon">
                <i class="far fa-file-alt"></i>
            </span>
            <span class="text">Release Notes</span>
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li class="dropdown-item" (click)="openAbout()">
            <span class="icon">
                <i class="far fa-info-circle"></i>
            </span>
            <span class="text">About CERRIX</span>
        </li>
    </ul>
</div>
