import { Component, OnInit } from "@angular/core";
import { UserMaintenanceService } from "../services/user-maintenance.service";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ModuleType } from "@enums/ModuleType";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { FilterType } from "@enums/FilterType";
import { RendererType } from "@enums/RendererType";
import { WorkspaceContextMenuItem } from "@models/workspace/WorkspaceContextMenuItem";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { FormatType } from "@enums/FormatType";
import { ToastrService } from "ngx-toastr";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "app-user-overview",
    templateUrl: "./user-overview.component.html",
    styleUrls: ["./user-overview.component.scss"],
})
export class UserOverviewComponent implements OnInit {
    tabID: string;
    tab: TabModel;

    moduleType = ModuleType.Admin;
    data: Promise<any[]>;
    headerLookup: any = {
        Firstname: "First name",
        Lastname: "Last name",
        LastLoginDate: "Last login date",
        LoginDisabledText: "Basic authentication",
        LoginUsername: "Basic username",
        AdDisabledText: "AD authentication",
        AdUsername: "AD username",
        OrganizationPath: "Organization path",
        RoleGroups: "Role groups",
    };

    filterConfigs: FilterConfig[] = [
        {
            label: "Organization",
            column: "OrganizationID",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "LastLoginDate",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: "preventEdit",
            type: RendererType.Default,
            hideColumn: true,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];

    contextMenuItems: WorkspaceContextMenuItem[] = [
        {
            text: "Open user detail",
            icon: "fas fa-user-edit",
            enabled: (row) => {
                return this.permissions.canOpen;
            },
            clickEvent: (row) => {
                this.openDetail(row);
            },
        },
    ];

    DateProperties = ["LastLoginDate"];

    permissions = new WorkspaceModulePermissionModel();

    idProperty = "Guid";

    constructor(
        private _userDS: UserMaintenanceService,
        private _tabs: TabService,
        private _permissions: PermissionsService,
        public pages: Pages,
        private toastService: ToastrService
    ) {
        const permissions = _permissions.permissions.UserMaintenance;
        this.permissions.canAdd = permissions.canCreate;
        this.permissions.canOpen =
            permissions.canEditDetails ||
            permissions.canEditLogins ||
            permissions.canAssignGroups ||
            permissions.canAssignRoles;

        if (permissions.canManageRoleGroup) {
            this.buttonConfig.push({
                text: "Role groups",
                icon: "fas fa-tags",
                clickEvent: () => {
                    this._tabs.generateTab(this.pages.UserRoleGroup);
                },
            });
        }
    }

    ngOnInit() {
        if (!this._permissions.permissions.UserMaintenance.hasAccess) {
            this.tab.close(false);
            return;
        }

        this.data = this._userDS.getList().toPromise().then(this.mapHeaders);
    }

    openDetail(user) {
        if (user.preventEdit) {
            this.toastService.info("User can't be edited.");
            return;
        }

        this._tabs.generateTab(this.pages.UserDetail, user[this.idProperty], `User`);
    }

    add() {
        this._tabs.generateTab(this.pages.UserDetail, null, "User | New user");
    }
    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
