<div class="widget-frame" #widgetFrame>
    <div class="title" *ngIf="!config.customConfig.hideHeader">
        <div
            class="name"
            [matTooltip]="
                config.customConfig.customTitle ? config.customConfig.customTitle : config.title
            "
        >
            <cerrix-icon-viewer
                [icon]="
                    config.customConfig.customIcon ? config.customConfig.customIcon : config.icon
                "
                appendClass="me-2"
            ></cerrix-icon-viewer>
            <span>{{
                config.customConfig.customTitle ? config.customConfig.customTitle : config.title
            }}</span>
        </div>
        <div class="filler"></div>
        <div class="actions" *ngIf="config.hasGlobalOpen">
            <i class="fas fa-external-link" (click)="config.api.onGlobalOpenClick()"></i>
        </div>
    </div>
    <div
        class="widget-content"
        [class.hide-overflow]="config.showLoader || config.noData || unauthorized"
    >
        <div
            class="fancy-loader submodule"
            [class.sm]="
                config.customConfig.gridsterConfig.rows < 2 ||
                config.customConfig.gridsterConfig.cols < 2
            "
            [class.md]="
                config.customConfig.gridsterConfig.rows < 3 ||
                config.customConfig.gridsterConfig.cols < 3
            "
            *ngIf="config.showLoader"
        ></div>
        <div class="nodata-message" *ngIf="config.noData">
            <span *ngIf="!config.noDataExplanation">No data found for this selection.</span>
            <span *ngIf="config.noDataExplanation">{{ config.noDataExplanation }}</span>
        </div>
        <div class="unauthorized-message" *ngIf="unauthorized">
            <i class="far fa-chart-bar" *ngIf="config.customConfig.gridsterConfig.rows > 1"></i>
            <span>No data due to improper rights.</span>
        </div>
        <ng-template #widgetContainer></ng-template>
    </div>
</div>
