import { HttpResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditUniversePeriodDataService } from "@app/audit-universe-period/services/audit-universe-period-data.service";
import { AuditUniversePeriodPermissionModel } from "@app/audit-universe-period/shared/page-models/audit-universe-period-permission-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { toPromise } from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";
import { AuditUniversePeriodModel } from "./../../shared/data-models/audit-universe-period-model";

@Component({
    selector: "audit-universe-period-detail",
    templateUrl: "./audit-universe-period-detail.component.html",
    styleUrls: ["./audit-universe-period-detail.component.scss"],
})
export class AuditUniversePeriodDetailComponent implements OnInit {
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent;

    tab: TabModel;
    id: string;

    permissions: AuditUniversePeriodPermissionModel;
    model: AuditUniversePeriodModel;

    parentForm: FormGroup;

    constructor(
        public _ds: AuditUniversePeriodDataService,
        private _toastService: ToastrService,
        private _promptService: CerrixPromptService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    async ngOnInit() {
        const isExistingRequest = this.id && isGuid(this.id);

        this.permissions = await toPromise(
            this._ds.getPermissions(isExistingRequest ? this.id : null)
        );

        if (!isExistingRequest && !this.permissions.canCreate) {
            this._toastService.error("No Access!");
            this.tab.close(false);
            return;
        }

        this.model = isExistingRequest
            ? await toPromise(this._ds.getObject(this.id))
            : new AuditUniversePeriodModel();

        this.initForms(this.model);
        this.tab.showLoader = false;
    }

    private initForms(auditperiod: AuditUniversePeriodModel) {
        this.parentForm = new FormGroup({
            name: new FormControl(auditperiod.name, [Validators.required]),
            startDate: new FormControl(auditperiod.startDate),
            endDate: new FormControl(auditperiod.endDate),
        });

        if (!this.permissions.canEditDetails) {
            this.parentForm.disable();
        } else {
            this.parentForm.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.parentForm, this.model);
            });
        }
    }

    async save() {
        if (!this.permissions.canEdit) {
            return;
        }

        if (!this.parentForm.valid) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastService.warning(validationMessage, "Save failed.", { enableHtml: true });
            return;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.model);

        const savingPrompt = this._promptService.loader("Saving changes, please wait...");
        const call = this._ds
            .store(this.model)
            .pipe(finalize(() => savingPrompt.close()))
            .toPromise()
            .then((value: HttpResponse<any>) => {
                this.tab.id = value.toString();
            });

        await call;

        this._tabService.refresh(this._pages.AuditUniversePeriodDetail);

        this._toastService.success("Update completed");
        this.tab.refresh();
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "history": {
                    this.historyOverviewComponent.loadHistory();
                    break;
                }
            }
        }
    }

    delete(): void {
        this._ds.deletePeriod(this.model.guid).subscribe(
            (value) => {
                if (value && value.length > 0) {
                    this._toastService.warning(value.toString());
                } else {
                    this._toastService.success("Audit period deleted");
                    if (
                        this.tab.parent &&
                        this.tab.parent.lookupname === this._pages.AuditUniversePeriod
                    ) {
                        this.tab.parent.refresh();
                    }
                    this.tab.close(false);
                }
            },
            (error) => {
                this._toastService.error("", error || error.Message);
            }
        );
    }

    closePeriod() {
        this.model.dateClosed = new Date();
        this.save();
    }
}
