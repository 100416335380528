import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { Configuration } from "../../app.constants";
import { AuditWorkflowButtonConfig } from "../shared/data-models/audit-workflow-button-config";
import { AuditWorkflowHistoryModel } from "../shared/data-models/audit-workflow-history-model";
import { AuditWorkflowStepModel } from "../shared/data-models/audit-workflow-step-model";
import { AuditWorkflowUpdateModel } from "../shared/data-models/audit-workflow-update-model";

@Injectable()
export class AuditWorkflowDataService extends ApiDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audit");
    }

    public getWorkflowButtonConfig(auditGuid: string) {
        const url = this.getWorkflowUrl(auditGuid, "buttonconfig");
        return this.get<AuditWorkflowButtonConfig>(url);
    }

    public getWorkflowGraph(auditGuid: string) {
        const url = this.getWorkflowUrl(auditGuid, "graph");
        return this.get<AuditWorkflowStepModel[]>(url);
    }

    public getWorkflowHistory(auditGuid: string): Observable<AuditWorkflowHistoryModel[]> {
        const url = this.getWorkflowUrl(auditGuid, "history");
        return this.get<AuditWorkflowHistoryModel[]>(url);
    }

    public updateWorkflow(auditGuid: string, workflowModel: AuditWorkflowUpdateModel) {
        const url = this.getWorkflowUrl(auditGuid, `update`);
        return this.post(workflowModel, url);
    }

    private getWorkflowUrl(auditGuid: string, url: string): string {
        let generatedUrl = "/" + auditGuid + "/workflow";

        if (url.startsWith("/")) generatedUrl += url;
        else generatedUrl += "/" + url;

        return generatedUrl;
    }
}
