<div class="detail-page-card" *ngIf="formGroup && assessmentDetailModel && permissionsModel">
    <div class="detail-page-card-title">Assessment configuration</div>
    <div class="detail-page-card-actions">
        <button
            data-uiid="business-detail-save"
            class="btn btn-raised"
            (click)="saveAssessmentConfiguration()"
            [disabled]="isSavingAssessment"
            *ngIf="permissionsModel.canEdit"
        >
            <i class="fas fa-save" *ngIf="!isSavingAssessment"></i>
            <i class="fas fa-circle-notch fa-spin ms-2" *ngIf="isSavingAssessment"></i>
            <span class="d-none d-lg-inline">Save</span>
        </button>
    </div>
    <div class="detail-page-card-body padded form-group-styling" [formGroup]="formGroup">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="reviewerSelect">Reviewer(s)</label>
                    <ng-select
                        data-uiid="business-detail-reviewer"
                        id="reviewerSelect"
                        name="reviewerSelect"
                        [items]="assessmentDetailModel.availableReviewers"
                        formControlName="reviewers"
                        bindLabel="Name"
                        bindValue="ID"
                        placeholder="Required"
                        [multiple]="true"
                    >
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="frequencyselect">Review frequency</label>
                    <ng-select
                        data-uiid="business-detail-reviewfrequency"
                        id="frequencyselect"
                        name="frequencyselect"
                        [items]="assessmentDetailModel.availableFrequencies"
                        formControlName="frequency"
                        bindLabel="Name"
                        bindValue="ID"
                        placeholder="Required"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="initialReviewDate">Initial date</label>
                    <date-input
                        data-uiid="business-detail-initialdate"
                        formControlName="initialReviewDate"
                        [minDate]="minDate"
                    ></date-input>
                </div>
                <div class="form-group">
                    <label for="nextReviewDate">Next review date</label>
                    <date-input formControlName="nextReviewDate"></date-input>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sub-list-manager" *ngIf="reviewsConfig">
    <generic-list-manager [config]="reviewsConfig"></generic-list-manager>
</div>
