import { Component, OnInit } from "@angular/core";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { nameof } from "@methods/CommonMethods";
import { DocumentBulkDeletedModel } from "@app/audit/shared/data-models/document-bulk-deleted-model";

@Component({
    selector: "bulk-deleted-documents",
    templateUrl: "./bulk-deleted-documents.component.html",
    styleUrls: ["./bulk-deleted-documents.component.scss"],
})
export class BulkDeletedDocumentsComponent implements OnInit {
    moduleType = ModuleType.CD;
    tabID: string;
    tab: TabModel;
    data: Promise<DocumentBulkDeletedModel[]>;
    headerLookup: { [propName: string]: string } = this.getHeaderLookup();
    permissions: WorkspaceModulePermissionModel;
    filterConfigs: FilterConfig[] = [];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: nameof<DocumentBulkDeletedModel>("uploadedOn"),
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: nameof<DocumentBulkDeletedModel>("deletedOn"),
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];

    numberProps: string[] = [];
    dateProps: string[] = [
        nameof<DocumentBulkDeletedModel>("uploadedOn"),
        nameof<DocumentBulkDeletedModel>("deletedOn"),
    ];

    constructor(
        private permissionsService: PermissionsService,
        private toastr: ToastrService,
        private dataService: DocumentDataService
    ) {
        this.permissions = {
            canAdd: false,
            canOpen: false,

            isModuleAdmin: false,
            canPublish: false,
            canSetSystemDefault: false,
        };
    }

    ngOnInit(): void {
        const hasAccess = this.permissionsService.permissions.Documents.Admin;
        if (!hasAccess) {
            this.toastr.error("No Access!");
            this.tab.close(false);
        }

        this.data = firstValueFrom(this.dataService.GetBulkDeletedDocuments()).then(
            this.mapHeaders
        );
    }

    mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    private getHeaderLookup(): { [propName: string]: string } {
        const headerLookup = {};
        headerLookup[nameof<DocumentBulkDeletedModel>("guid")] = "Guid";
        headerLookup[nameof<DocumentBulkDeletedModel>("name")] = "Name";
        headerLookup[nameof<DocumentBulkDeletedModel>("uploadedBy")] = "Uploaded by";
        headerLookup[nameof<DocumentBulkDeletedModel>("uploadedOn")] = "Uploaded on";
        headerLookup[nameof<DocumentBulkDeletedModel>("sourceTypeName")] = "Source";
        headerLookup[nameof<DocumentBulkDeletedModel>("typeName")] = "Type";
        headerLookup[nameof<DocumentBulkDeletedModel>("deletedBy")] = "Deleted by";
        headerLookup[nameof<DocumentBulkDeletedModel>("deletedOn")] = "Deleted on";

        return headerLookup;
    }
}
