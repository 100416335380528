import { HttpEvent, HttpHeaders, HttpInterceptor } from "@angular/common/http";
import { inject, Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { JwtAuthService } from "../auth/jwt-auth.service";
import { switchMap } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AccessTokenInterceptorService implements HttpInterceptor {
    constructor(
        @Inject(LOCALE_ID) protected language: string,
        private jwtAuthService: JwtAuthService
    ) {}

    intercept(req, next): Observable<HttpEvent<any>> {
        const incidentsUrl = localStorage.getItem("incidentUrl");
        if (incidentsUrl !== undefined && incidentsUrl != "" && req.url?.startsWith(incidentsUrl)) {
            return this.jwtAuthService.getAccessToken().pipe<any>(
                switchMap((accessToken) => {
                    const tokenizedReq = req.clone({
                        headers: new HttpHeaders({
                            Authorization: `Bearer ${accessToken}`,
                            Language: this.language,
                        }),
                    });
                    return next.handle(tokenizedReq);
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
