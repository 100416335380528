import { EventEmitter, Injectable } from "@angular/core";
import { Pages } from "@constants/pages/Pages";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { TabEventListenerManager } from "@models/generic/TabModels/TabEventListenerManager";
import { guid, isGuid } from "../../common/methods/uniqueMethods";
import { TabModel } from "../../common/models/generic/TabModels/TabModel";
import { ManualPageTypeModel } from "./../../app/manual/models/ManualPageTypeModel";

@Injectable()
export class TabService {
    addTabEvent: EventEmitter<TabModel> = new EventEmitter<TabModel>();
    addTabFromUrlEvent: EventEmitter<TabModel> = new EventEmitter<TabModel>();
    closeTabByGuid: EventEmitter<string> = new EventEmitter<string>();
    storeTabChangesEvent: EventEmitter<any> = new EventEmitter();
    renameTabEvent: EventEmitter<string> = new EventEmitter<string>();
    refreshTabEvent: EventEmitter<string> = new EventEmitter<string>();

    public sideMenuTucked = false;
    public activeTab: TabModel;
    public listeners: TabEventListenerManager;

    constructor(private _pages: Pages) {
        window.addEventListener("message", this.frameTabMessage.bind(this), false);
        this.listeners = new TabEventListenerManager();
    }

    addTab(model: TabModel) {
        this.addTabEvent.emit(model);
    }

    addUrlTab(tab: TabModel) {
        this.addTabFromUrlEvent.emit(tab);
    }

    storeTabChanges() {
        this.storeTabChangesEvent.emit();
    }

    getTab(lookupname: string, id?, tabName?: string, config?: any): TabModel {
        return {
            identifier: guid(),
            name: tabName && tabName.length > 0 ? tabName : lookupname,
            lookupname: lookupname,
            id: id,
            config: config,
        };
    }

    generateTab(lookupname: string, id?, tabName?: string, config?: any, activeMenu?: string) {
        if (activeMenu) {
            if (!config) {
                config = {};
            }

            config.activemenu = activeMenu;
        }

        const tab: TabModel = this.getTab(lookupname, id, tabName, config);
        this.addTabEvent.emit(tab);
    }

    frameTabMessage: any = (event: any) => {
        if (typeof event.data !== typeof "" || event.data.indexOf("|") === 0) {
            return;
        }

        const msg = (event.data as string).split("|");
        if (msg[0] === "NEWTABEVENT") {
            const tab: TabModel = JSON.parse(msg[1]);
            tab.identifier = guid();
            this.addTabEvent.emit(tab);
        } else if (msg[0] === "CLOSETABEVENT" && isGuid(msg[1])) {
            const params = msg.length === 3 ? msg[1] + "|" + msg[2] : msg[1];
            this.closeTabByGuid.emit(params);
        } else if (msg[0] === "STARTSCREENLOADEDEVENT") {
            window.location.reload();
        } else if (msg[0] === "RENAMETABEVENT" && isGuid(msg[1])) {
            const param = msg[1] + "|" + msg[2];
            this.renameTabEvent.emit(param);
        }
    };

    refresh(page: string) {
        this.refreshTabEvent.emit(page);
    }

    openManual(pageType: ManualPageTypeModel) {
        const tab: TabModel = this.getTab(this._pages.Manuals);
        tab._fullPath = pageType.path;

        this.addTab(tab);
    }

    handleWorkspaceDuplicate(tab: TabModel) {
        if (!tab.config || !tab.config.presetOverride) {
            return;
        }

        this.listeners.triggerGlobalListener(
            TabEventListenerType.LoadPreset,
            tab.lookupname,
            tab.config.presetOverride
        );
    }

    handleConfigChangedEvent(tab: TabModel) {
        this.listeners.triggerGlobalListener(
            TabEventListenerType.ReloadConfig,
            tab.lookupname,
            tab
        );
    }
}
