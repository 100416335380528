import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DocumentTemplatesDataService } from "@app/document-templates/document-templates-data.service";
import { DocumentTemplatesModuleModel } from "@app/document-templates/models/document-templates-module-model";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TargetModule } from "@enums/document/TargetModule";
import { DocumentModel } from "@models/documents/documentmodel";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "document-templates-module",
    templateUrl: "./document-templates-module.component.html",
    styleUrls: ["./document-templates-module.component.scss"],
})
export class DocumentTemplatesModuleComponent {
    @Input() targetModule: TargetModule;
    @Output() saved = new EventEmitter();

    protected model: DocumentTemplatesModuleModel;
    protected documentTargetModule = TargetModule.DocumentTemplates;

    private isDocumentsDirty = false;

    constructor(
        private service: DocumentTemplatesDataService,
        private toastService: ToastrService,
        private promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        await this.loadModel();
    }

    protected async save() {
        if (!this.isDocumentsDirty) {
            this.toastService.info("No changes to save");
            return;
        }

        const savingPrompt = this.promptService.loader("Saving changes, please wait...");

        try {
            await firstValueFrom(
                this.service.updateDocumentTemplate(this.model.module, this.model)
            );

            // Tab refresh is required to reset state of document manager component.
            this.saved.emit();

            this.toastService.success("Save was successful");
        } finally {
            savingPrompt.close();
        }
    }

    private async loadModel() {
        this.model = await firstValueFrom(this.service.getDocumentTemplate(this.targetModule));

        this.isDocumentsDirty = false;
    }

    protected onDocumentsChange(documents: DocumentModel[]) {
        this.isDocumentsDirty = documents.some((d) => d.IsNew || d.Changed || d.Deleted);
    }
}
