<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    class="details"
    *ngIf="!tab.showLoader"
    (change)="onMenuItemClick($event)"
>
    <div class="detail-page-action-bar">
        <button class="btn btn-cerrix" (click)="save()" *ngIf="permissions.canEdit">
            <i class="fas fa-save"></i>
            <span data-uiid="save" class="d-none d-lg-inline">Save step</span>
        </button>

        <div class="workflow-area" *ngIf="stepModel.useReviewWorkflow">
            <span class="workflow-description">
                Workflow Status |
                <b>{{
                    stepModel.useReviewWorkflow && stepModel.workflow.statusDescription
                        ? stepModel.workflow.statusDescription
                        : "New"
                }}</b>
                <i
                    class="far fa-question-circle mx-2"
                    [matTooltip]="
                        stepModel.workflow && stepModel.workflow.statusDescription
                            ? stepModel.workflow.statusDescription
                            : ''
                    "
                ></i>
            </span>
            <button
                type="button"
                (click)="newCommentDialog($event, true)"
                class="btn btn-success"
                id="positive"
                *ngIf="nextStatus"
            >
                {{ nextStatus }}
            </button>
            <button
                (click)="newCommentDialog($event, false)"
                class="btn btn-danger"
                id="negative"
                *ngIf="backStatus"
            >
                {{ backStatus }}
            </button>
        </div>
    </div>

    <div
        name="Fieldwork step"
        menuItemId="fieldwork-step"
        menuicon="fas fa-step-forward"
        menusystemsubmenu
    >
        <div
            name="Details"
            menuItemId="fieldwork-step-details"
            menuicon="fas fa-info-circle"
            menusystemitem
        >
            <audit-fieldwork-step-detail-main
                [auditGuid]="auditGuid"
                [permissions]="permissions"
                [stepModel]="stepModel"
            ></audit-fieldwork-step-detail-main>

            <audit-fieldwork-step-finding-linking
                [auditGuid]="auditGuid"
                [stepGuid]="stepModel.guid"
                [permissions]="permissions"
                [linkedFindings]="stepModel.findings"
                *ngIf="permissions.canViewLinkedFindings"
            ></audit-fieldwork-step-finding-linking>
        </div>
        <div
            name="Links"
            menuItemId="fieldwork-step-links"
            menuicon="fas fa-link"
            menusystemsubmenu
        >
            <div
                name="Risks"
                menuItemId="fieldwork-step-risks"
                menuicon="fad fa-exclamation-triangle"
                menusystemitem
                *ngIf="permissions.canViewLinkedRisks"
            >
                <audit-fieldwork-step-risk-linking
                    [auditGuid]="auditGuid"
                    [stepGuid]="stepModel.guid"
                    [permissions]="permissions"
                    [linkedRisks]="stepModel.risks"
                ></audit-fieldwork-step-risk-linking>
            </div>
            <div
                name="Controls"
                menuItemId="fieldwork-step-controls"
                menuicon="fad fa-shield-alt"
                menusystemitem
                *ngIf="permissions.canViewLinkedControls"
            >
                <audit-fieldwork-step-control-linking
                    [auditGuid]="auditGuid"
                    [stepGuid]="stepModel.guid"
                    [permissions]="permissions"
                    [linkedControls]="stepModel.controls"
                ></audit-fieldwork-step-control-linking>
            </div>
            <div
                name="Documents"
                [attr.menuItemId]="documentTabId"
                menuicon="far fa-file"
                menusystemitem
                *ngIf="permissions.canViewLinkedDocuments"
            >
                <audit-fieldwork-step-document-linking
                    [auditGuid]="auditGuid"
                    [permissions]="permissions"
                    [linkedDocuments]="stepModel.documents"
                ></audit-fieldwork-step-document-linking>
            </div>
            <div
                menuItemId="history"
                name="History"
                menusystemitem
                menuicon="fas fa-history"
                *ngIf="stepModel.id > 0"
            >
                <app-history-overview
                    [historyDataService]="_ds"
                    [Id]="stepModel.guid"
                    [ExtraParam]="auditGuid"
                    #historyOverviewComponent
                >
                </app-history-overview>
            </div>
        </div>
    </div>
</div>
