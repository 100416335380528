import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "eff-checkbox",
    templateUrl: "./eff-checkbox.component.html",
    styleUrls: ["./eff-checkbox.component.scss"],
})
export class EffCheckboxComponent {
    @Input() model: boolean;
    @Input() formController: FormControl = new FormControl();
    @Input() label: boolean;
    @Input() disabled: boolean = false;
    @Input() checked: boolean = false;
    @Output() modelChange: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        if (this.model != null && this.model != undefined) {
            this.formController.setValue(this.model);
        }

        if (this.disabled) {
            this.disableEnableFormControl();
        }

        if (this.checked) {
            this.checkedFormControl();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["model"]) {
            this.formController.setValue(this.model);
        }

        if (changes["disabled"]) {
            this.disableEnableFormControl();
        }

        if (changes["value"]) {
            this.checkedFormControl();
        }
    }

    onClick(value: boolean) {
        this.modelChange.emit(value);
    }

    disableEnableFormControl() {
        if (this.formController) {
            if (this.disabled) {
                this.formController.disable();
            } else {
                this.formController.enable();
            }
        }
    }

    checkedFormControl() {
        if (this.formController) {
            if (this.checked) {
                this.formController.setValue(true);
            } else {
                this.formController.setValue(false);
            }
        }
    }
}
