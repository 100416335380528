<ng-template #imageContainer>
    <div
        *ngIf="!config.showLoader"
        [innerHTML]="previewImage | safe: 'html'"
        (click)="maximize()"
        class="process-widget-image"
    ></div>
</ng-template>

<div class="widget-container">
    <ng-container [ngTemplateOutlet]="imageContainer"></ng-container>
</div>
