import { Component, Input } from '@angular/core';
import { TabService } from '@services/tabs/TabService';
import { Pages } from '@constants/pages/Pages';

@Component({
  selector: 'moi-event-summary',
  templateUrl: './moi-event-summary.component.html',
  styleUrls: ['./moi-event-summary.component.scss']
})
export class MoiEventSummaryComponent {
  @Input() summaryModel: any;
  @Input() eventID: any;

  constructor(private tabservice: TabService, private pages: Pages) { }

  openEvent() {
      if (!this.eventID || !this.summaryModel.CanOpenParent) {
          return;
      }

      this.tabservice.generateTab(this.pages.EventDetail, this.eventID);
  }
}
