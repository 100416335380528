<div class="comment-item">
    <div *ngIf="comment.commentTypeDescription" class="comment-type">
        <div class="description">
            {{ comment.commentTypeDescription }}
        </div>
        <div class="date">
            {{ comment.createdOn | dateTimeDisplay : true }}
        </div>
    </div>

    <div *ngIf="comment.value" class="comment-content">
        <div [class]="['user-icon', userColor]">{{ comment.initials }}</div>

        <div class="comment-data">
            <div [class]="['comment', comment.commentClass ? comment.commentClass : '']">
                <div class="username">{{ comment.userName }}</div>
                <div class="text">
                    {{ showMoreLessButton && showMore ? clippedText : comment.value }}

                    <div *ngIf="showMoreLessButton">
                        <span class="link-btn" (click)="onToggleMore()">
                            <ng-container *ngIf="showMore; else showLess">Read more</ng-container>
                            <ng-template #showLess>Read less</ng-template>
                        </span>
                    </div>
                </div>
            </div>

            <div class="date">{{ comment.createdOn | dateTimeDisplay : true }}</div>
        </div>
    </div>
</div>
