import { Configuration } from "@app/app.constants";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { IsMobile, tryParseJson } from "@methods/CommonMethods";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { GenericManagerHelper } from "../helpers/generic-manager-helper";
import { GenericFormEditor } from "../interfaces/generic-form-editor";
import { GenericManagerConfig } from "../models/GenericList/GenericManagerConfig";
import { CerrixPromptService } from "../services/cerrix-prompt.service";

export class GenericFormManagerBase {
    editor: GenericFormEditor;
    baseConfig: GenericManagerConfig;

    data: any[];
    additionalData = {};

    idProp = "ID";
    sortProp = "Sort_Order";

    constructor(
        private _standingdataDS: StandingdataDataService,
        private _toastr: ToastrService,
        private _config: Configuration,
        protected _promptService: CerrixPromptService
    ) {}

    loadForm() {
        this.additionalData = {};
    }

    checkAdditionalData() {
        const calls: { field: string; call: Promise<any> }[] = [];
        this.baseConfig.fields.forEach((field) => {
            let call: Observable<any> = null;
            if (field.getDataMethod) {
                call = field.getDataMethod();
            } else if (field.getDataMethodName) {
                call = this._standingdataDS[field.getDataMethodName](
                    field.getDataParams
                ) as Observable<any>;
            } else if (field.getDataMethodByRow) {
                call = field.getDataMethodByRow(null);
            }

            if (call) {
                calls.push({ field: field.fieldName, call: call.toPromise() });
            }
        });

        let callsCompleted = 0;
        calls.forEach((c) => {
            c.call.then((d) => {
                const data = tryParseJson(d);
                if (data) {
                    this.additionalData[c.field] = data;
                } else {
                    this.additionalData[c.field] = d;
                }

                callsCompleted++;
                if (callsCompleted === calls.length) {
                    this.setTable();
                }
            });
        });

        if (calls.length === 0) {
            this.setTable();
        }
    }

    // virtual methods that can be defined in derived class.
    setTable() {}
    setCustomProps(row) {}

    addRow() {
        if (this.baseConfig.addOverride) {
            this.baseConfig.addOverride();
            return;
        }

        if (!this.baseConfig.allowAdd) {
            return;
        }

        const addCheckAllowed = !this.baseConfig.preAddCheck || this.baseConfig.preAddCheck();
        if (!addCheckAllowed) {
            return;
        }

        const newModel = {};
        newModel[this.idProp] = this.data.getLowerIDByProp(this.idProp, 1);
        if (newModel[this.idProp] >= 0) {
            newModel[this.idProp] = -1;
        }

        this.baseConfig.fields.forEach((field) => {
            // value calculate only works with a list format. Otherwise the default value will be set in the generic-field-editor component.
            if (field.defaultValueCalculate) {
                newModel[field.fieldName] = field.defaultValueCalculate(this.data);
            }
        });

        this.setCustomProps(newModel);

        this.editRow(newModel);
    }

    singleClick(row: any) {
        if (IsMobile()) {
            this.editRow(row);
        }
    }

    editRow(row: any) {
        if (!row) {
            return;
        }

        let dataRow = this.data.find((x) => x[this.idProp] === row[this.idProp]);
        if (!dataRow) {
            dataRow = row;
        }

        if (this.baseConfig.editOverride) {
            this.baseConfig.editOverride(dataRow);
            return;
        }

        const editCheckAllowed = !this.baseConfig.preEditCheck || this.baseConfig.preEditCheck(row);
        if (!editCheckAllowed) {
            return;
        }

        const editedRow = JSON.parse(JSON.stringify(dataRow));
        this.baseConfig.fields.forEach(
            (f) =>
                (editedRow[f.fieldName] = GenericManagerHelper.parseField(
                    editedRow[f.fieldName],
                    f
                ))
        );

        this.editor.editEnabled =
            row[this.idProp] > 0 ? this.baseConfig.allowEdit : this.baseConfig.allowAdd;
        this.editor.edit(editedRow);
    }

    // Add and edit will end up in this method.
    async applyRowChange(editedRow: any) {
        const validations = GenericManagerHelper.validateFields(
            this.idProp,
            this.data,
            editedRow,
            this.baseConfig.fields,
            null,
            true
        );
        if (validations.length === 0) {
            const oldRow = this.data.find((r) => r[this.idProp] === editedRow[this.idProp]);
            if (oldRow) {
                // This should be blocked if it is not allowed. Because we will allow opening the modal to view details.
                if (!this.baseConfig.allowEdit) {
                    return;
                }

                const oldRowBackup = JSON.parse(JSON.stringify(oldRow));
                this.baseConfig.fields.forEach(
                    (f) => (oldRow[f.fieldName] = editedRow[f.fieldName])
                );

                const editCompleted =
                    !this.baseConfig.edit || (await this.baseConfig.edit(oldRow, oldRowBackup));
                if (!editCompleted) {
                    this.baseConfig.fields.forEach(
                        (f) => (oldRow[f.fieldName] = oldRowBackup[f.fieldName])
                    );
                    this.editRow(editedRow);
                    return;
                }
            } else {
                const addCompleted = !this.baseConfig.add || (await this.baseConfig.add(editedRow));
                if (!addCompleted) {
                    this.editRow(editedRow);
                    return;
                }

                this.data.push(editedRow);
            }

            return true;
        } else {
            const message = validations.distinct().join("<br/>");
            this._toastr.warning(message, "Save failed", {
                enableHtml: true,
                timeOut: this._config.ToastrErrorTimeout,
            });

            // Open model with row
            this.editRow(editedRow);
            return false;
        }
    }

    async deleteRow(activeRow) {
        const dataRow = this.data.find((d) => d[this.idProp] === activeRow[this.idProp]);

        if (this.baseConfig.deleteOverride) {
            this.baseConfig.deleteOverride(dataRow);
            return false;
        }

        if (!this.baseConfig.allowDelete) {
            return false;
        }

        const deleteCheckAllowed =
            !this.baseConfig.preDeleteCheck || this.baseConfig.preDeleteCheck(dataRow);
        if (!deleteCheckAllowed) {
            return false;
        }

        const deleteCompleted = !this.baseConfig.delete || (await this.baseConfig.delete(dataRow));
        if (!deleteCompleted) {
            return false;
        }

        const dataI = this.data.indexOf(dataRow);
        this.data.splice(dataI, 1);
        this.emitDataChanged(this.data);
        return true;
    }

    emitDataChanged(data) {
        if (this.baseConfig && this.baseConfig.dataChanged) {
            this.baseConfig.dataChanged(data);
        }
    }
}
