import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ExportType } from "@enums/ExportType";
import { ApiDataService } from "@services/http/ApiDataService";

@Injectable({
    providedIn: "root",
})
export class ExportDataService extends ApiDataService {
    public constructor(httpClient: HttpClient, configuration: Configuration) {
        super(httpClient, configuration, "exports");
    }

    public downloadExport(exportType: ExportType, additionalData?: string) {
        if (!exportType) {
            throw new Error("ExportType must be specified to download export");
        }

        let url = `/${exportType}`;
        if (additionalData) {
            url += `?${additionalData}`;
        }

        this.download(url);
    }
}
