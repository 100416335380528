export enum TabEventListenerType {
    OnLoad,
    OnClose,
    OnFocus,
    OnBlur,
    OnSave,

    LoadPreset,
    ReloadConfig,
}
