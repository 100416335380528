<div *ngIf="!finishedLoading">
    <div class="fancy-loader"></div>
</div>
<div *ngIf="finishedLoading">
    <div class="detail-page-summary" *ngIf="detailsModel.guid">
        <div class="row">
            <div class="col-lg-6 left-block">
                <div class="property"><b>ID:</b> {{ detailsModel.identifier }}</div>
                <div class="property text-truncate">
                    <b>Name:</b>&nbsp;<span data-uiid="finding-report-name-label">{{
                        detailsModel.name
                    }}</span>
                </div>
                <div class="property"><b>Responsible:</b> {{ detailsModel.responsibleName }}</div>
            </div>
            <div class="col-lg-6 right-block">
                <div class="property">
                    <b>Due date:</b> {{ detailsModel.reportDueDate | dateFormat }}
                </div>
                <div class="property">
                    <b>Date created:</b> {{ detailsModel.createdAt | dateFormat }}
                </div>
                <div class="property">
                    <b>Overall score:</b>
                    <div
                        class="summary-score-color"
                        [style.background-color]="
                            detailsModel.overallScoreColor ? detailsModel.overallScoreColor : ''
                        "
                    ></div>
                    {{ detailsModel.overallScoreName }}
                </div>
            </div>
        </div>
    </div>
    <div class="detail-page-card mt-3" [formGroup]="formGroup">
        <div class="detail-page-card-title">Details</div>
        <div class="detail-page-card-body padded form-group-styling">
            <div class="row">
                <div class="col-xl-6 left-block">
                    <div class="row" *ngIf="detailsModel.auditGuid">
                        <div class="form-group">
                            <label class="form-label">Audit</label>
                            <div class="input-group">
                                <input
                                    type="text"
                                    value="{{ detailsModel.auditDisplayName }}"
                                    class="form-control form-control-sm"
                                    readonly
                                />
                                <label
                                    *ngIf="canOpenAudit"
                                    class="input-group-text action-button"
                                    (click)="openAudit()"
                                >
                                    <i class="fas fa-external-link-alt"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Report name"
                                    dataUiid="finding-report-name"
                                    formControlName="name"
                                    placeholder="Required"
                                ></cerrix-textfield>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Report type</label>
                                <ng-select
                                    data-uiid="finding-report-type"
                                    [items]="reportTypesPromise | async"
                                    formControlName="reportTypeId"
                                    bindValue="value"
                                    placeholder="Required"
                                    [loading]="reportTypesLoading"
                                    (change)="onReportTypeChange($event)"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-separator"></div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Report no."
                                    dataUiid="finding-report-no"
                                    formControlName="reportNumber"
                                ></cerrix-textfield>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Sub name"
                                    dataUiid="finding-report-subname"
                                    formControlName="subName"
                                ></cerrix-textfield>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Report URL</label>
                                <cerrix-url-input
                                    dataUiid="finding-report-url"
                                    formControlName="reportUrl"
                                ></cerrix-url-input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Overall score</label>
                                <ng-select
                                    data-uiid="finding-report-overallscore"
                                    [items]="overallScoresPromise | async"
                                    formControlName="overallScoreId"
                                    bindValue="value"
                                    [loading]="reportTypesLoading"
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <span
                                            class="select-template-color"
                                            [style.background-color]="item.color"
                                        ></span>
                                        {{ item.label }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Validation type</label>
                                <ng-select
                                    data-uiid="finding-report-validationtype"
                                    [items]="validationTypesPromise | async"
                                    formControlName="validationTypeId"
                                    bindValue="value"
                                    [loading]="validationTypesLoading"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Report date</label>
                                <date-input
                                    data-uiid="finding-report-date"
                                    formControlName="reportDate"
                                ></date-input>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Report due date</label>
                                <date-input
                                    data-uiid="finding-report-duedate"
                                    formControlName="reportDueDate"
                                ></date-input>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-separator"></div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Organization responsible</label>
                                <cerrix-select-tree
                                    data-uiid="finding-report-responsibleorganization"
                                    [tree]="responsibleOrganizations"
                                    formControlName="responsibleOrganizationId"
                                    placeholder="Required"
                                    [multiselect]="false"
                                >
                                </cerrix-select-tree>
                            </div>
                            <div class="form-group">
                                <label>Responsible</label>
                                <ng-select
                                    data-uiid="finding-report-responsible"
                                    [items]="responsiblesPromise | async"
                                    formControlName="responsibleId"
                                    bindValue="value"
                                    placeholder="Required"
                                    [loading]="responsiblesLoading"
                                >
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <label>Organization reviewer</label>
                                <cerrix-select-tree
                                    data-uiid="finding-report-reviewerorganization"
                                    [tree]="reviewerOrganizations"
                                    formControlName="reviewerOrganizationId"
                                    [multiselect]="false"
                                >
                                </cerrix-select-tree>
                            </div>
                            <div class="form-group">
                                <label>Reviewer</label>
                                <ng-select
                                    [items]="reviewersPromise | async"
                                    data-uiid="finding-report-reviewer"
                                    formControlName="reviewerId"
                                    bindValue="value"
                                    placeholder="Required"
                                    [loading]="reviewersLoading"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 right-block">
                    <div class="form-group-separator d-xl-none"></div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textarea
                                fieldLabel="Scope & Objective"
                                dataUiid="finding-report-scopeobjective"
                                formControlName="scopeAndObjective"
                            ></cerrix-textarea>
                        </div>
                    </div>
                    <div class="form-group-separator"></div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textarea
                                fieldLabel="Conclusion"
                                dataUiid="finding-report-conclusion"
                                formControlName="conclusion"
                            ></cerrix-textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <linked-moi-overview
        [moiType]="moiType"
        [parentGuid]="detailsModel.guid"
        [canAdd]="permissionsModel.canAddMoi"
        [canDelete]="permissionsModel.canDeleteMoi"
        [useLazyLoading]="false"
        *ngIf="detailsModel.guid"
    >
    </linked-moi-overview>
</div>
