import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { nameof } from "@methods/jeffs-toolkit";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import {
    DragAndDropEventArgs,
    FieldsSettingsModel,
    NodeSelectEventArgs,
    TreeViewComponent,
} from "@syncfusion/ej2-angular-navigations";
import { CerrixTreeViewerConfig } from "../models/cerrix-tree-viewer-config";

@Component({
    selector: "cerrix-tree-viewer",
    templateUrl: "./cerrix-tree-viewer.component.html",
    styleUrls: ["./cerrix-tree-viewer.component.scss"],
    providers: [],
})
export class CerrixTreeViewerComponent implements OnInit {
    @Input() config: CerrixTreeViewerConfig;

    ejTreeFieldsConfig: FieldsSettingsModel;

    @ViewChild("treeViewComp") treeViewComp: TreeViewComponent;

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.validateConfig();
        this.ejTreeFieldsConfig = this.getEjConfig();

        this.config.reloadData = () => {
            this.treeViewComp.fields.dataSource = <any>this.getDataSource();
        };

        this.config.selectNode = (id: any) => {
            this.treeViewComp.selectedNodes = ["" + id];
            this.treeViewComp.refresh();

            if (this.config.onNodeSelect) {
                this.config.onNodeSelect(id);
            }
        };
    }

    onSelect(args: NodeSelectEventArgs) {
        if (!this.config.onNodeSelect || args.action != "select") {
            return;
        }

        this.config.onNodeSelect(args.nodeData.id);
    }

    onDrop(args: DragAndDropEventArgs) {
        if (!this.config.onNodeMove) {
            return;
        }

        // Small timeout to give the component time to update the data values.
        setTimeout(() => {
            const draggedNodeId = args.draggedNodeData.id as string;
            const nodeInfo = this.treeViewComp.getNode(draggedNodeId);
            const parentId = nodeInfo.parentID as string;

            // We select the children elements based on the parentid or on the root.
            let childNodes = parentId
                ? this.treeViewComp.element
                      .querySelector(`[data-uid='${parentId}']`)
                      .querySelector("ul")
                      .querySelectorAll(":scope > li")
                : this.treeViewComp.element.querySelector("ul").querySelectorAll(":scope > li");

            // use getNode method to convert the html elements to the data nodes that contain information we need.
            const orderedNodes: any[] = [];
            childNodes.forEach((x) => {
                orderedNodes.push(this.treeViewComp.getNode(x).id);
            });

            this.config.onNodeMove(orderedNodes, parentId);
        }, 100);
    }

    private validateConfig() {
        if (!this.config) {
            return false;
        }

        return true;
    }

    private getEjConfig() {
        const config: FieldsSettingsModel = {
            dataSource: <any>this.getDataSource(),

            id: nameof<CerrixTreeItem>((x) => x.ID),
            text: nameof<CerrixTreeItem>((x) => x.Name),
            child: nameof<CerrixTreeItem>((x) => x.Children),
        };

        return config;
    }

    private getDataSource() {
        let datasource: CerrixTreeItem[];

        if (this.config.treeProperty["childProperty"]) {
            // basic tree
        } else {
            // parent tree
            datasource = <any[]>(
                this.createTreeFromParentIDs(
                    this.config.data,
                    this.config.treeProperty["parentProperty"]
                )
            );
        }

        return datasource;
    }

    private createTreeFromParentIDs(data: any[], parentIdSelector: string) {
        const treeData = data
            .filter((x) => !x[parentIdSelector])
            .map((x) => this.convertNode(x, data, parentIdSelector));

        return treeData;
    }

    private convertNode(node: any, allNodes: any[], parentIdSelector: string): CerrixTreeItem {
        const childNodes = allNodes.filter(
            (x) => x[parentIdSelector] == node[this.config.idProperty]
        );
        const convertedChildNodes = childNodes.map((x) =>
            this.convertNode(x, allNodes, parentIdSelector)
        );

        const treeNode = this.getTreeNode(node, node[parentIdSelector]);
        if (convertedChildNodes && convertedChildNodes.length > 0) {
            treeNode.Children = convertedChildNodes;
        }

        return treeNode;
    }

    private getTreeNode(node: any, parentId?: any) {
        const treeNode: CerrixTreeItem = {
            ID: node[this.config.idProperty],
            Name: node[this.config.textProperty],
            Icon: node[this.config.iconProperty],

            ParentID: parentId ? parentId : null,
            ID_Path: null,
            Children: null,
        };

        // If we already have an instance open, we try to copy that node state as it is now.
        if (this.treeViewComp) {
            const existingNode = this.treeViewComp.getNode("" + treeNode.ID);
            if (existingNode && existingNode.id) {
                treeNode["expanded"] = existingNode.expanded;
                treeNode["selected"] = existingNode.selected;
                treeNode["isChecked"] = existingNode.isChecked;
            }
        }

        return treeNode;
    }
}
