import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { ModuleType } from "@enums/ModuleType";
import { ModuleSubType } from "@enums/ModuleSubType";
import { DeletedItemMenuModel } from "./models/deleted-item-menu-model";

@Injectable()
export class DeletedItemsService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "deleteditems");
    }

    getList() {
        return this.get<DeletedItemMenuModel[]>();
    }

    getByModule(module: ModuleType, subModule: ModuleSubType): any {
        const url = `/${module}/${subModule}`;
        return this.get<any>(url);
    }

    restoreByModule(module: ModuleType, id: number, subModule: ModuleSubType) {
        const url = `/${module}/${id}/${subModule}`;
        return this.post(null, url);
    }
}
