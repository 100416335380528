import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { ControlExecutionDataService } from "../services/control-execution-data.service";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { nameof, toPromise } from "@methods/CommonMethods";
import { TaskSeriesOverviewModel } from "../control-execution-detail/models/task-series-overview-model";
import { ControlExecutionOverviewModel } from "../control-execution-detail/models/control-execution-overview-model";
import { RendererType } from "@enums/RendererType";
import { FormatType } from "@enums/FormatType";
import { isGuid } from "@methods/uniqueMethods";
import { lastValueFrom } from "rxjs";
import { ControlExecutionPermissionModel } from "../control-execution-detail/models/control-execution-permission-model";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";

@Component({
    selector: "control-execution-overview",
    templateUrl: "./control-execution-overview.component.html",
})
export class ControlExecutionOverviewComponent implements OnInit {
    protected id: string;
    protected tab: TabModel;

    protected recentUpcomingConfig: GenericListConfig;
    protected taskSeriesConfig: GenericListConfig;
    protected allControlExecutionsConfig: GenericListConfig;
    permissions: ControlExecutionPermissionModel;
    isLoaded: Boolean = false;

    constructor(
        private route: ActivatedRoute,
        private _tabService: TabService,
        private _pages: Pages,
        private _ds: ControlExecutionDataService,
        private _appSettingDS: SettingsDataService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;

        if (!isGuid(this.id)) {
            this.tab.close(false);
            return;
        }

        this.permissions = await lastValueFrom(this._ds.getPermissions());

        this.createRecentAndUpcomingConfig();
        this.createTaskSeriesConfig();
        this.createAllControlExecutionConfig();

        this.tab.showLoader = false;
        this.isLoaded = true;
    }

    private createRecentAndUpcomingConfig(): void {
        this.recentUpcomingConfig = <GenericListConfig>{
            name: "Recent and upcoming",
            emptyMessage: `No control executions available. Click 'Task series' in menu to create a series of planned control executions.`,
            idProp: nameof<ControlExecutionOverviewModel>("guid"),
            uiid: "control-execution-recent-upcoming-overview",
            isSortable: false,

            allowAdd: false,
            allowEdit: true,
            allowDelete: false,

            fields: [
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("actionDate"),
                    prettyName: "Action date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("statusDescription"),
                    prettyName: "Status",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("name"),
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("responsibleNames"),
                    prettyName: "Responsibles",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("reviewerNames"),
                    prettyName: "Reviewers",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "scoreName",
                    prettyName: "Score",
                    fieldType: GenericListFieldType.ColoredSelect,
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "scoreName_COLOR",
                    prettyName: "Score color",
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("comments"),
                    prettyName: "Comments",
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("completedAt"),
                    prettyName: "Date completed",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("completedBy"),
                    prettyName: "Completed by",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("documentCount"),
                    prettyName: "Document #",
                    fieldType: GenericListFieldType.Number,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<ControlExecutionOverviewModel>("actionDate"),
                    actionColumn: nameof<ControlExecutionOverviewModel>("actionDateExpired"),
                    actionValue: true,
                    type: RendererType.DueDate,
                    formatType: FormatType.DateFormat,
                },
                {
                    textColumn: "scoreName",
                    actionColumn: "scoreName_COLOR",
                    hideActionColumn: true,
                    type: RendererType.Score,
                },
            ],

            dataMethod: () => {
                return this._ds.getRecentAndUpcoming(this.id);
            },
            editOverride: (row) => {
                this._tabService.generateTab(
                    this._pages.TaskDetail, // ControlExecutionDetail,
                    row.guid,
                    `Execution ${+row.identifier} - ${row.name}`
                );
            },
        };
    }

    private createTaskSeriesConfig() {
        if (this.taskSeriesConfig) {
            return;
        }

        this.taskSeriesConfig = <GenericListConfig>{
            name: "Task Series",
            idProp: nameof<TaskSeriesOverviewModel>("guid"),
            uiid: "control-execution-task-series-overview",
            uiidAdd: "control-execution-task-series-overview-addbutton",
            isSortable: false,

            allowAdd: this.permissions.canCreateTaskseries,
            allowEdit: this.permissions.canEditTaskseries,
            allowDelete: false, // Only make task series deletable in the detail view

            hideDeleteButton: true,
            skipReloadConfirm: true,

            fields: [
                {
                    fieldName: nameof<TaskSeriesOverviewModel>("name"),
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<TaskSeriesOverviewModel>("startdate"),
                    prettyName: "Start date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<TaskSeriesOverviewModel>("enddate"),
                    prettyName: "End date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<TaskSeriesOverviewModel>("responsibles"),
                    prettyName: "Responsible(s)",
                    fieldType: GenericListFieldType.Text,
                },
            ],

            dataMethod: () => {
                return this._ds.getTaskSeries(this.id);
            },
            addOverride: () => {
                this._tabService.generateTab(
                    this._pages.TaskSeriesDetail,
                    null,
                    "New Control Execution",
                    { controlGuid: this.id }
                );
            },
            editOverride: (row) => {
                this._tabService.generateTab(
                    this._pages.TaskSeriesDetail,
                    row.guid,
                    `Execution ${+row.identifier} - ${row.name}`
                );
            },
        };
    }

    private createAllControlExecutionConfig(): void {
        if (this.allControlExecutionsConfig) {
            return;
        }

        this.allControlExecutionsConfig = <GenericListConfig>{
            name: "Control executions",
            emptyMessage: `No control executions available. Click 'Task series' in menu to create a series of planned control executions. Click ‘Add’ to immediately execute an On event control.`,
            idProp: nameof<ControlExecutionOverviewModel>("guid"),
            uiid: "control-execution-overview",
            uiidAdd: "control-execution-overview-addbutton",
            isSortable: false,

            allowAdd: true,
            allowEdit: true,
            allowDelete: false,

            addButtonTooltip: "Add On Event control execution",

            fields: [
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("actionDate"),
                    prettyName: "Action date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("statusDescription"),
                    prettyName: "Status",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("name"),
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("responsibleNames"),
                    prettyName: "Responsibles",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("reviewerNames"),
                    prettyName: "Reviewers",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "scoreName",
                    prettyName: "Score",
                    fieldType: GenericListFieldType.ColoredSelect,
                },
                {
                    fieldType: GenericListFieldType.Text,
                    fieldName: "scoreName_COLOR",
                    prettyName: "Score color",
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("comments"),
                    prettyName: "Comments",
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("completedAt"),
                    prettyName: "Date completed",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("completedBy"),
                    prettyName: "Completed by",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ControlExecutionOverviewModel>("documentCount"),
                    prettyName: "Document #",
                    fieldType: GenericListFieldType.Number,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<ControlExecutionOverviewModel>("actionDate"),
                    actionColumn: nameof<ControlExecutionOverviewModel>("actionDateExpired"),
                    actionValue: true,
                    type: RendererType.DueDate,
                    formatType: FormatType.DateFormat,
                },
                {
                    textColumn: "scoreName",
                    actionColumn: "scoreName_COLOR",
                    hideActionColumn: true,
                    type: RendererType.Score,
                },
            ],

            dataMethod: () => {
                return this._ds.getControlExecutions(this.id);
            },
            addOverride: () => {
                this._tabService.generateTab(
                    this._pages.TaskDetail,
                    null,
                    "New Control Execution",
                    { controlGuid: this.id }
                );
            },
            editOverride: (row) => {
                this._tabService.generateTab(
                    this._pages.TaskDetail, // ControlExecutionDetail,
                    row.guid,
                    `Execution ${+row.identifier} - ${row.name}`
                );
            },
        };
    }
}
