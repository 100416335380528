import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { TabModel } from "./TabModel";
import { guid } from "@methods/uniqueMethods";

export class TabEventListenerManager {
    private listeners: TabListenerModel[];

    constructor() {
     
        this.listeners = [];
    }

    /** The bindToTab will allow for auto cleanup of listeners when the tab that created the listener gets closed.
     *  If you do not provide this variable you are responsible for created listeners! */
    addTabListener(
        type: TabEventListenerType,
        tabIdentifier: string,
        callback: (arg?: any) => void,
        bindToTab?: TabModel
    ) {
        const newListener = <TabListenerModel>{
            listenerID: guid(),
            type: type,
            isGlobal: false,
            targetTabID: tabIdentifier,
            callback: callback,
            linkedToTabIdentifier: bindToTab && bindToTab.identifier,
        };

        this.listeners.push(newListener);
        return newListener.listenerID;
    }

    /** The bindToTab will allow for auto cleanup of listeners when the tab that created the listener gets closed.
     *  If you do not provide this variable you are responsible for created listeners! */
    addGlobalListener(
        type: TabEventListenerType,
        lookupName: string,
        callback: (arg?: any) => void,
        bindToTab?: TabModel
    ) {
        const newListener = <TabListenerModel>{
            listenerID: guid(),
            type: type,
            isGlobal: true,
            targetTabType: lookupName,
            callback: callback,
            linkedToTabIdentifier: bindToTab && bindToTab.identifier,
        };

        this.listeners.push(newListener);
        return newListener.listenerID;
    }

    removeListeners(tab: TabModel) {
        const foundListeners = this.listeners.filter(
            (l) => l.linkedToTabIdentifier === tab.identifier
        );

        if (foundListeners.length > 0) {
            foundListeners.forEach((fl) => {
                const index = this.listeners.indexOf(fl);
                this.listeners.splice(index, 1);
            });
        }
    }

    removeTabListenerByType(tab: TabModel, type: TabEventListenerType) {
        const foundListeners = this.listeners.filter(
            (l) => l.linkedToTabIdentifier === tab.identifier && l.type == type
        );

        if (foundListeners.length > 0) {
            foundListeners.forEach((fl) => {
                const index = this.listeners.indexOf(fl);
                this.listeners.splice(index, 1);
            });
        }
    }

    removeListener(listenerID: string) {
        const foundListenerIndex = this.listeners.findIndex((l) => l.listenerID === listenerID);
        if (foundListenerIndex >= 0) {
            this.listeners.splice(foundListenerIndex, 1);
        }
    }

    triggerListener(type: TabEventListenerType, tab: TabModel, arg?: any) {
        const foundListeners = this.listeners.filter(
            (l) =>
                l.type === type &&
                ((l.isGlobal && l.targetTabType === tab.lookupname) ||
                    (!l.isGlobal && l.targetTabID === tab.identifier))
        );

        foundListeners.forEach((listener) => {
            listener.callback(arg);
        });
    }

    triggerGlobalListener(type: TabEventListenerType, lookupname: string, arg?: any) {
        const foundListeners = this.listeners.filter(
            (l) => l.type === type && l.targetTabType === lookupname
        );

        foundListeners.forEach((listener) => {
            listener.callback(arg);
        });
    }
}

class TabListenerModel {
    listenerID: string;
    type: TabEventListenerType;

    isGlobal: boolean;

    targetTabType?: string;
    targetTabID?: string;

    linkedToTabIdentifier?: string;
    callback: (arg?: any) => void;
}
