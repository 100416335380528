import { DirectDataApiModuleSection } from "./directdata-api-model";

export class DirectDataApiMapping {
    static getMapping(): { [key: string]: DirectDataApiModuleSection } {
        return {
            General: {
                user: {
                    name: "User",
                    description: "Get user details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        FirstName: "string",
                        LastName: "string",
                        DisplayName: "string",
                        EmailAddress: "string",
                        Remarks: "string",
                        OrganizationGuid: "uuid",
                    },
                },
                organization: {
                    name: "Organization",
                    description: "Get organization standing data",
                    hasDateFilter: true,
                    hasSplitPathsOption: true,
                    response: {
                        Guid: "uuid",
                        Name: "string",
                        Description: "string",
                        Comments: "string",
                        ParentGuid: "uuid",
                        SortOrder: "integer",
                        Icon: "string",
                        Path: "string",
                    },
                },
                businessdimension: {
                    name: "Business Dimensions",
                    description: "Get business dimensions standing data",
                    hasDateFilter: true,
                    hasSplitPathsOption: true,
                    response: {
                        Guid: "uuid",
                        Name: "string",
                        Description: "string",
                        Comments: "string",
                        ParentGuid: "uuid",
                        SortOrder: "integer",
                        Icon: "string",
                        Path: "string",
                    },
                },
                frameworkdimension: {
                    name: "Framework Dimensions",
                    description: "Get framework dimensions standing data",
                    hasDateFilter: true,
                    hasSplitPathsOption: true,
                    response: {
                        Guid: "uuid",
                        Name: "string",
                        Description: "string",
                        Comments: "string",
                        ParentGuid: "uuid",
                        SortOrder: "integer",
                        Icon: "string",
                        Path: "string",
                    },
                },
            },
            // Risk
            Risk: {
                risk: {
                    name: "Risk",
                    description: "Get risk details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        Description: "string",
                        Comments: "string",
                        Cause: "string",
                        Effect: "string",
                        OrganizationGuid: "uuid",
                        RiskCatalogueId: "integer",
                        GrossLikelihoodId: "integer",
                        GrossImpactId: "integer",
                        NetLikelihoodId: "integer",
                        NetImpactId: "integer",
                        RiskAreaId: "integer",
                        EventCategoryId: "integer",
                        Approved: "boolean",
                        ApproverUserGuid: "uuid",
                        ApprovedDate: "datetime",
                        Reviewed: "boolean",
                        ReviewerUserGuid: "uuid",
                        ReviewedDate: "datetime",
                        Owner: "string",
                        RiskTreatmentId: "integer",
                        RiskAssessmentId: "integer",
                        KeyRisk: "boolean",
                        Confidential: "boolean",
                        AppetiteComment: "string",
                        RiskAssessmentComment: "string",
                        LeadingImpactScaleId: "integer",
                        RiskAssessmentDate: "datetime",
                        GrossQuantitativeImpact: "integer",
                        GrossQuantitativeLikelihood: "integer",
                        NetQuantitativeImpact: "integer",
                        NetQuantitativeLikelihood: "integer",
                        BusinessDimensions: ["uuid"],
                        FrameworkDimensions: ["uuid"],
                        RiskSubtypes: ["integer"],
                        CustomImpactScaleValues: [
                            {
                                ImpactScaleId: "integer",
                                GrossImpactId: "integer",
                                NetImpactId: "integer",
                            },
                        ],
                        LinkedControls: ["uuid"],
                        LinkedRiskMois: ["uuid"],
                        LinkedBimMois: ["uuid"],
                        LinkedThirdParties: ["uuid"],
                    },
                },
                riskappetite: {
                    name: "Risk Appetite",
                    description: "Get risk appetite details",
                    hasDateFilter: true,
                    response: {
                        RiskGuid: "uuid",
                        AppetiteInLine: "string",
                    },
                },

                riskarea: {
                    name: "Risk Area",
                    description: "Get risk area standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
                riskcatalogue: {
                    name: "Risk Catalogue",
                    description: "Get risk catalogue standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        Definition: "string",
                        RiskName: "string",
                        RiskAreaId: "integer",
                        EventCategoryId: "integer",
                        Weight: "integer",
                        RiskAppetite: "string",
                        RiskSubtypes: ["integer"],
                    },
                },
                riskeventcategory: {
                    name: "Risk Event Category",
                    description: "Get risk event category standing data",
                    hasSplitPathsOption: true,
                    response: {
                        Id: "integer",
                        Name: "string",
                        ParentId: "integer",
                        SortOrder: "integer",
                        Icon: "string",
                    },
                },
                riskimpact: {
                    name: "Risk Impact",
                    description: "Get risk impact standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        Text: "string",
                        DefaultValue: "integer",
                        MinValue: "integer",
                        MaxValue: "integer",
                    },
                },
                riskimpactscale: {
                    name: "Risk Impact Scale",
                    description: "Get risk impact scale standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        ImpactScaleOptions: [
                            {
                                Id: "integer",
                                Name: "string",
                                SortOrder: "integer",
                                Weight: "integer",
                            },
                        ],
                    },
                },
                risklikelihood: {
                    name: "Risk Likelihood",
                    description: "Get risk likelihood standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        Text: "string",
                        DefaultValue: "integer",
                        MinValue: "integer",
                        MaxValue: "integer",
                    },
                },
                risksubtype: {
                    name: "Risk Subtype",
                    description: "Get risk subtype standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },
                risktreatment: {
                    name: "Risk Treatment",
                    description: "Get risk treatment standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },
            },
            Control: {
                control: {
                    name: "Control",
                    description: "Get control details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        Description: "string",
                        Comments: "string",
                        Owner: "string",
                        Approved: "boolean",
                        Reviewed: "boolean",
                        InPlace: "boolean",
                        IsKeyControl: "boolean",
                        RequiresMonitoring: "boolean",
                        RequiresExecution: "boolean",
                        IsShared: "boolean",
                        InitialCosts: "integer",
                        AnnualCosts: "integer",
                        ControlObjective: "string",
                        ConclusionSummary: "string",
                        DateCreated: "datetime",
                        StartDate: "datetime",
                        EndDate: "datetime",
                        ControlFrequencyId: "integer",
                        AspectInternalControlId: "integer",
                        ControlExecutionMethodId: "integer",
                        ControlTypeId: "integer",
                        SourceId: "string",
                        ControlCatalogueId: "integer",
                        OrganizationGuid: "uuid",
                        BusinessDimensions: ["uuid"],
                        FrameworkDimensions: ["uuid"],
                        ApplicableOrganizations: ["uuid"],
                        LinkedRisks: ["uuid"],
                        LinkedControlMois: ["uuid"],
                        LinkedBimMois: ["uuid"],
                        LinkedThirdParties: ["uuid"],
                    },
                },
                controlexecution: {
                    name: "Control Execution",
                    description: "Get control execution standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },

                // Control standing data
                controlaspectinternalcontrol: {
                    name: "Control Aspect Internal Control",
                    description: "Get control aspect internal control standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },
                controlcatalogue: {
                    name: "Control Catalogue",
                    description: "Get control catalogue standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        SourceId: "string",
                        ControlTypeId: "integer",
                        AllowTypeCodeOverride: "boolean",
                        FrameworkDimensionGuids: ["uuid"],
                    },
                },
                controlexecutionmethod: {
                    name: "Control Execution Method",
                    description: "Get control execution method standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },
                controlfunction: {
                    name: "Control Function",
                    description: "Get control function standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },
                controlfrequency: {
                    name: "Control Frequency",
                    description: "Get control frequency standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        MinSampleCount: "integer",
                        SuggestedSampleCount: "integer",
                    },
                },
                controlsource: {
                    name: "Control Source",
                    description: "Get control source standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
                controltype: {
                    name: "Control Type",
                    description: "Get control type standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },

                // Control testing
                designimplementation: {
                    name: "Design Implementation",
                    description: "Get design implementation details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        ControlGuid: "uuid",
                        CatalogueId: "integer",
                        FunctionId: "integer",
                        LinkedStandardsId: "integer",
                        ControlTestFrequency: "string",
                        NumberOfTestSamples: "integer",
                        Activity: "string",
                        Norm: "string",
                        Status: "string",
                        InitialDueDate: "datetime",
                        UploaderDueDate: "datetime",
                        QualityAspects: ["integer"],
                        EvidenceUploaders: ["uuid"],
                        Testers: ["uuid"],
                    },
                },
                simpleeffectiveness: {
                    name: "Simple Effectiveness",
                    description: "Get simple effectiveness details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        ControlGuid: "uuid",
                        CatalogueId: "integer",
                        FunctionId: "integer",
                        LinkedStandardsId: "integer",
                        ControlTestFrequency: "string",
                        NumberOfTestSamples: "integer",
                        Activity: "string",
                        Norm: "string",
                        Status: "string",
                        InitialDueDate: "datetime",
                        UploaderDueDate: "datetime",
                        QualityAspects: ["integer"],
                        EvidenceUploaders: ["uuid"],
                        Testers: ["uuid"],
                    },
                },
                controlscoreoverview: {
                    name: "Control Score Overview",
                    description: "Get control score overview details",
                    hasDateFilter: true,
                    response: {
                        ControlGuid: "uuid",
                        DesignScoreId: "integer",
                        ImplementationScoreId: "integer",
                        DesignImplementationDateTested: "datetime",
                        DesignImplementationNextTestDate: "datetime",
                        EffectivenessScoreId: "integer",
                        EffectivenessDateTested: "datetime",
                        EffectivenessNextTestDate: "datetime",
                        ConclusionScoreId: "integer",
                    },
                },

                advancedeffectiveness: {
                    name: "Advanced Effectiveness",
                    description: "Get advanced effectiveness details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        ControlGuid: "uuid",
                        Name: "string",
                        CatalogueId: "integer",
                        PeriodGuid: "uuid",
                        LinkedStandardsId: "integer",
                        TestPopulation: "integer",
                        NumberOfTestSamples: "integer",
                        Norm: "string",
                        SampleType: "string",
                        SampleGenerationType: "string",
                        SampleGenerationReason: "string",
                        SampleGeneratorSeed: "integer",
                        AcceptReason: "string",
                        RejectionReason: "string",
                        ReopenReason: "string",
                        ReviewEnabled: "boolean",
                        Instructions: "string",
                        SourceDocumentUploadInstructions: "string",
                        EvidenceUploadInstructions: "string",
                        Status: "string",
                        WorkflowAction: "string",
                        StartDate: "datetime",
                        EndDate: "datetime",
                        SourceUploaderDueDate: "datetime",
                        EvidenceUploaderDueDate: "datetime",
                        TesterDueDate: "datetime",
                        ReviewerDueDate: "datetime",
                        QualityAspects: ["integer"],
                        SourceDocumentUploaders: ["uuid"],
                        EvidenceUploaders: ["uuid"],
                        Testers: ["uuid"],
                        Reviewers: ["uuid"],
                    },
                },
                advancedeffectivenessperiod: {
                    name: "Advanced Effectiveness Period",
                    description: "Get advanced effectiveness period details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        ControlGuid: "uuid",
                        Name: "string",
                        MinimalNumberOfSamples: "integer",
                        StartDate: "datetime",
                        EndDate: "datetime",
                        ScoreId: "integer",
                    },
                },
                advancedeffectivenesssample: {
                    name: "Advanced Effectiveness Sample",
                    description: "Get advanced effectiveness sample details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        EffectivenessGuid: "uuid",
                        Name: "string",
                        SortOrder: "integer",
                        Score: "integer",
                        NoEvidence: "boolean",
                        ReasonNoEvidence: "string",
                        CommentTester: "string",
                        CommentReviewer: "string",
                        Remarks: "string",
                    },
                },
                advancedeffectivenesstestresult: {
                    name: "Advanced Effectiveness Test Result",
                    description: "Get advanced effectiveness test result details",
                    hasDateFilter: true,
                    response: {
                        EffectivenessGuid: "uuid",
                        TesterUserGuid: "uuid",
                        TesterScoreId: "integer",
                        TesterComment: "string",
                        TesterDate: "datetime",

                        ReviewerUserGuid: "uuid",
                        ReviewerScoreId: "integer",
                        ReviewerComment: "string",
                        ReviewerDate: "datetime",
                    },
                },
                advancedeffectivenessteststepdefinition: {
                    name: "Advanced Effectiveness Test Step Definition",
                    description: "Get advanced effectiveness test step definition details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        EffectivenessGuid: "uuid",
                        TestMethodId: "integer",
                        Reference: "string",
                        Description: "string",
                        RequiredDocuments: "string",
                        SortOrder: "integer",
                    },
                },
                advancedeffectivenessteststepinput: {
                    name: "Advanced Effectiveness Test Step",
                    description: "Get advanced effectiveness test step details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        SampleGuid: "uuid",
                        TestStepDefinitionGuid: "uuid",
                        Score: "string",
                        CommentTester: "string",
                        CommentReviewer: "string",
                    },
                },

                // Control testing standing data
                advancedeffectivenesscatalogue: {
                    name: "Advanced Effectiveness Catalogue",
                    description: "Get advanced effectiveness catalogue standing data",
                    hasDateFilter: true,
                    response: {
                        Id: "integer",
                        Name: "string",
                        LinkedStandardsId: "integer",
                        Norm: "string",
                        SampleType: "string",
                        QualityAspects: ["integer"],
                        EvidenceUploaders: ["uuid"],
                        Testers: ["uuid"],
                        Reviewers: ["uuid"],
                        ApplicableOrganizations: ["uuid"],
                    },
                },
                advancedeffectivenesscatalogueteststep: {
                    name: "Advanced Effectiveness Catalogue Test Step",
                    description: "Get advanced effectiveness catalogue test step standing data",
                    hasDateFilter: true,
                    response: {
                        Id: "integer",
                        CatalogueId: "integer",
                        TestMethodId: "integer",
                        Reference: "string",
                        Description: "string",
                        RequiredDocuments: "string",
                        SortOrder: "integer",
                    },
                },
                controllinkedstandard: {
                    name: "Control Linked Standard",
                    description: "Get control linked standard standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        Description: "string",
                        SortOrder: "integer",
                    },
                },
                controlqualityaspect: {
                    name: "Control Quality Aspect",
                    description: "Get control quality aspect standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        Description: "string",
                        SortOrder: "integer",
                    },
                },
                controltestingscore: {
                    name: "Control Testing Score",
                    description: "Get control testing assessment score standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
                controltestmethod: {
                    name: "Control Test Method",
                    description: "Get control test method standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        Description: "string",
                        SortOrder: "integer",
                    },
                },
                designimplementationcatalogue: {
                    name: "Design Implementation Catalogue",
                    description: "Get design implementation catalogue standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        LinkedStandardId: "integer",
                        ControlTestFrequency: "string",
                        NumberOfSamples: "integer",
                        Activity: "string",
                        Norm: "string",
                        QualityAspects: ["integer"],
                        EvidenceUploaders: ["uuid"],
                        Testers: ["uuid"],
                    },
                },
            },
            "Business Improvement Management": {
                bimmoi: {
                    name: "BIM Moi",
                    description: "Get BIM Moi details",
                    hasDateFilter: true,
                    response: {
                        FindingReportGuid: "uuid",
                        AssessmentGuid: "uuid",
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        SubjectId: "integer",
                        PriorityId: "integer",
                        ImplementationScoreId: "integer",
                        StartDate: "datetime",
                        DueDate: "datetime",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        Delegates: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        OrganizationReviewerGuid: "uuid",
                        Reviewers: ["uuid"],
                        FindingDescription: "string",
                        Recommendation: "string",
                        ManagementResponse: "string",
                        MoiUrl: "string",
                    },
                },
                controlmoi: {
                    name: "Control Moi",
                    description: "Get control moi details",
                    hasDateFilter: true,
                    response: {
                        ControlGuid: "uuid",
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        SubjectId: "integer",
                        PriorityId: "integer",
                        ImplementationScoreId: "integer",
                        StartDate: "datetime",
                        DueDate: "datetime",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        Delegates: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        OrganizationReviewerGuid: "uuid",
                        Reviewers: ["uuid"],
                        FindingDescription: "string",
                        Recommendation: "string",
                        ManagementResponse: "string",
                        MoiUrl: "string",
                    },
                },
                riskmoi: {
                    name: "Risk Moi",
                    description: "Get risk moi details",
                    hasDateFilter: true,
                    response: {
                        RiskGuid: "uuid",
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        SubjectId: "integer",
                        PriorityId: "integer",
                        ImplementationScoreId: "integer",
                        StartDate: "datetime",
                        DueDate: "datetime",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        Delegates: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        OrganizationReviewerGuid: "uuid",
                        Reviewers: ["uuid"],
                        FindingDescription: "string",
                        Recommendation: "string",
                        ManagementResponse: "string",
                        MoiUrl: "string",
                    },
                },
                eventmoi: {
                    name: "Event Moi",
                    description: "Get event moi details",
                    hasDateFilter: true,
                    response: {
                        EventGuid: "uuid",
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        SubjectId: "integer",
                        PriorityId: "integer",
                        ImplementationScoreId: "integer",
                        StartDate: "datetime",
                        DueDate: "datetime",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        Delegates: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        OrganizationReviewerGuid: "uuid",
                        Reviewers: ["uuid"],
                        FindingDescription: "string",
                        Recommendation: "string",
                        ManagementResponse: "string",
                        MoiUrl: "string",
                    },
                },
                thirdpartymoi: {
                    name: "Third Party Moi",
                    description: "Get third party moi details",
                    hasDateFilter: true,
                    response: {
                        ThirdPartyGuid: "uuid",
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        SubjectId: "integer",
                        PriorityId: "integer",
                        ImplementationScoreId: "integer",
                        StartDate: "datetime",
                        DueDate: "datetime",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        Delegates: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        OrganizationReviewerGuid: "uuid",
                        Reviewers: ["uuid"],
                        FindingDescription: "string",
                        Recommendation: "string",
                        ManagementResponse: "string",
                        MoiUrl: "string",
                    },
                },
                datamanagementmoi: {
                    name: "Data Management Moi",
                    description: "Get data management moi details",
                    hasDateFilter: true,
                    response: {
                        ProcessingPurposeGuid: "uuid",
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        SubjectId: "integer",
                        PriorityId: "integer",
                        ImplementationScoreId: "integer",
                        StartDate: "datetime",
                        DueDate: "datetime",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        Delegates: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        OrganizationReviewerGuid: "uuid",
                        Reviewers: ["uuid"],
                        FindingDescription: "string",
                        Recommendation: "string",
                        ManagementResponse: "string",
                        MoiUrl: "string",
                    },
                },

                moicomment: {
                    name: "Moi Comment",
                    description: "Get moi comment details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        MoiGuid: "uuid",
                        UserGuid: "uuid",
                        Text: "string",
                        DateCreated: "datetime",
                        Progress: "integer",
                        Type: "string",
                        FeasibilityId: "integer",
                        UserType: "string",
                    },
                },

                // Moi standing data
                moifeasibility: {
                    name: "Moi Feasibility",
                    description: "Get moi feasibility standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
                moiimplementationscore: {
                    name: "Moi Implementation Score",
                    description: "Get moi implementation score standing data",
                    response: {
                        Weight: "integer",
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
                moipriority: {
                    name: "Moi Priority",
                    description: "Get moi priority standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
                moisubject: {
                    name: "Moi Subject",
                    description: "Get moi subject standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },

                // Finding report
                findingreport: {
                    name: "Finding Report",
                    description: "Get finding report details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        ReportTypeId: "integer",
                        ReportNumber: "string",
                        SubName: "string",
                        OverallScoreId: "integer",
                        ValidationTypeId: "integer",
                        OrganizationResponsibleGuid: "uuid",
                        ResponsibleGuid: "uuid",
                        OrganizationReviewerGuid: "uuid",
                        ReviewerGuid: "uuid",
                        ScopeAndObjective: "string",
                        Conclusion: "string",
                        ReportDate: "datetime",
                        ReportDueDate: "datetime",
                    },
                },

                // Finding report standing data
                findingreportreporttype: {
                    name: "Finding Report Report Type",
                    description: "Get finding report report type standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                        Organizations: ["uuid"],
                    },
                },
                findingreportreporttypescore: {
                    name: "Finding Report Report Type Score",
                    description: "Get finding report report type score standing data",
                    response: {
                        Id: "integer",
                        ReportTypeId: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
                findingreportvalidationtype: {
                    name: "Finding Report Validation Type",
                    description: "Get finding report validation type standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },

                // Assessment
                assessment: {
                    name: "Assessment",
                    description: "Get assessment details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        AssessmentTypeId: "integer",
                        AssessmentDate: "datetime",
                        AssessmentUrl: "string",
                        OverallScoreId: "integer",
                        ReviewerGuid: "uuid",
                        OrganizationReviewerGuid: "uuid",
                        CommentsReviewer: "string",
                        FindingReportGuid: "uuid",
                        LinkedBimMois: ["uuid"],
                    },
                },

                // Assessment standing data
                assessmenttype: {
                    name: "Assessment Type",
                    description: "Get assessment type standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Description: "string",
                    },
                },
                assessmenttypescore: {
                    name: "Assessment Type Score",
                    description: "Get assessment type score standing data",
                    response: {
                        Id: "integer",
                        AssessmentTypeId: "integer",
                        Name: "string",
                        Description: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
            },
            "Third Party": {
                thirdparty: {
                    name: "Third Party",
                    description: "Get third party details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        Identifier: "string",
                        Name: "string",
                        Description: "string",
                        Comment: "string",
                        Address: "string",
                        City: "string",
                        CountryCode: "string",
                        Email: "string",
                        Phone: "string",
                        Website: "string",
                        LeiCode: "string",
                        DunsNumber: "string",
                        AuthorizedSignatory: "string",
                        ChamberOfCommerce: "string",
                        VAT: "string",
                        ThirdPartyResponsible: "string",
                        StatusOfInvolvementId: "string",
                        Services: ["integer"],
                        StartDateServicing: "datetime",
                        EndDateServicing: "datetime",
                        RelationshipId: "string",
                        CriticalityId: "string",
                        IsEuMember: "boolean",
                        IsDataController: "boolean",
                        IsDataProcessor: "boolean",
                        IsDataRecipient: "boolean",
                        IsThirdParty: "boolean",
                        OrganizationResponsibleGuid: "uuid",
                        Responsibles: ["uuid"],
                        OrganizationBeneficialOwnerGuid: "uuid",
                        BeneficialOwner: "string",
                        OrganizationsInUse: ["uuid"],
                        BusinessDimensions: ["uuid"],
                        LinkedRisks: ["uuid"],
                        LinkedControls: ["uuid"],
                        LinkedThirdPartyMois: ["uuid"],
                    },
                },

                thirdpartycontact: {
                    name: "Third Party Contact",
                    description: "Get third party contact details",
                    hasDateFilter: true,
                    response: {
                        ThirdPartyGuid: "uuid",
                        Name: "string",
                        Role: "string",
                        Phone: "string",
                        Email: "string",
                    },
                },
                thirdpartyreview: {
                    name: "Third Party Review",
                    description: "Get third party review details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        ReviewerGuid: "uuid",
                        ReviewFrequency: "string",
                        InitialReviewDate: "datetime",
                        NextReviewDate: "datetime",
                        Reviews: [
                            {
                                ScoreId: "integer",
                                Comment: "string",
                                Reviewer: "string",
                                Date: "datetime",
                            },
                        ],
                    },
                },
                thirdpartycontract: {
                    name: "Third Party Contract",
                    description: "Get third party contract details",
                    hasDateFilter: true,
                    response: {
                        ThirdPartyGuid: "uuid",
                        ContractNumber: "string",
                        Status: "string",
                        ContractOwnerGuid: "uuid",
                        CurrencyCode: "string",
                        ContractedValue: "integer",
                        AnnualValue: "integer",
                        SignatureDate: "datetime",
                        StartDate: "datetime",
                        EndDate: "datetime",
                        TermOfNotice: "string",
                        IsFrameworkContract: "boolean",
                        Services: ["integer"],
                    },
                },

                // Third party standing data
                thirdpartycontractstatus: {
                    name: "Third Party Contract Status",
                    description: "Get third party contract status standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
                thirdpartycriticality: {
                    name: "Third Party Criticality",
                    description: "Get third party criticality standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
                thirdpartyrelationship: {
                    name: "Third Party Relationship",
                    description: "Get third party relationship standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
                thirdpartyscore: {
                    name: "Third Party Score",
                    description: "Get third party score standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
                thirdpartyservices: {
                    name: "Third Party Services",
                    description: "Get third party services standing data",
                    hasSplitPathsOption: true,
                    response: {
                        Id: "integer",
                        Name: "string",
                        ParentId: "integer",
                        SortOrder: "integer",
                        Icon: "string",
                    },
                },
                thirdpartystatusofinvolvement: {
                    name: "Third Party Status of Involvement",
                    description: "Get third party status of involvement standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                    },
                },
            },
            Task: {
                taskseries: {
                    name: "Task Series",
                    description: "Get task series details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        Name: "string",
                        TaskType: ["string"],
                        Responsibles: ["uuid"],
                        Reviewers: ["uuid"],
                        Notifications: ["string"],
                        TaskScoreOption: "string",
                        Description: "string",
                        StartDate: "datetime",
                        EndDate: "datetime",
                    },
                },
                tasks: {
                    name: "Task",
                    description: "Get task details",
                    hasDateFilter: true,
                    response: {
                        Guid: "uuid",
                        Name: "string",
                        TaskType: ["string"],
                        Responsibles: ["uuid"],
                        Reviewers: ["uuid"],
                        Notifications: ["string"],
                        TaskScoreOption: "string",
                        Description: "string",
                        Comments: "string",
                        ActionDate: "datetime",
                        LinkedControls: ["uuid"],
                    },
                },

                // Task standing data
                taskscore: {
                    name: "Task Score",
                    description: "Get task score standing data",
                    response: {
                        Id: "integer",
                        Name: "string",
                        SortOrder: "integer",
                        Color: "string",
                    },
                },
            },
        };
    }
}
