<linked-moi-overview
    [moiType]="moiType"
    [parentGuid]="risk.guid"
    [canAdd]="permissions.canAddMoi"
    [canDelete]="permissions.canDeleteMoi"
    *ngIf="permissions.canViewMoi"
    #linkedRiskMoisComponent
>
</linked-moi-overview>

<hr />

<app-linkable
    *ngIf="!isLoading"
    typename="measures of improvement of Finding Reports"
    [displayValueProp]="displayValueProp"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="!permissions.canLinkBim"
    (onOpenClicked)="openItem($event)"
    [hideActions]="hideActions"
    [hideFilter]="hideFilter"
    [allowAddNew]="allowAddNew"
    [showAdvancedFilters]="showAdvancedFilters"
    [activeFilters]="activeFilters"
    [filterConfig]="filterConfig"
    (linkedChange)="linkedItemsChanged($event)"
    [moduleType]="moiModuleType"
    #linkedImMoi
>
</app-linkable>
