import { Component, DestroyRef, inject, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatTabsModule } from "@angular/material/tabs";

import {
    CerrixAvatarGroupComponent,
    CerrixTitleComponent,
    CerrixTextareaComponent,
    CerrixFieldWrapperComponent,
    CerrixDatepickerComponent,
    CerrixButtonComponent,
    CerrixValidators,
    getFormErrorMessages,
    UserModel,
    UserListInterface,
    CerrixDialogService,
    TreeItemNode,
    SelectOptionModel,
    CurrencyInterface,
    CerrixDropMenuComponent,
    CerrixIconComponent,
} from "@cerrix/components";

import { IncidentService } from "../services/incident.service";
import { TabModel } from "../../../common/models/generic/TabModels/TabModel";
import { IncidentDetailsModel } from "../models/incident-details.model";
import { IncidentDetailsSettingsMenuModel } from "../models/incident-details-settings-menu.model";
import { DateTimeDisplayPipe } from "./../../shared/pipes/date-time-display.pipe";
import { IncidentDetailsTabcontentComponent } from "./incident-details-tabcontent/incident-details-tabcontent.component";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormRecord,
    FormsModule,
    ReactiveFormsModule,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { IncidentEditModel } from "../models/incident-edit.model";
import { IncidentTypesService } from "../services/incident-types.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { forkJoin, lastValueFrom, map, Observable } from "rxjs";
import { IncidentCustomFieldsTabcontentComponent } from "./incident-custom-fields-tab/incident-custom-fields-tab.component";
import { IncidentCustomFieldEditModel } from "../models/incident-custom-field-edit.model";
import { IncidentTypeDisplayModel } from "../models/incident-type-display.model";
import { CustomFieldDisplayModel } from "../models/custom-field-display.model";
import { OrderdListItemModel } from "../models/orderd-list-item.model";
import { IncidentTypeTabModel } from "./incident-custom-fields-tab/models/incident-type-tab.model";
import { FieldType } from "@app/preset/models/field-type.enum";
import moment from "moment";
import { IncidentChatService } from "../services/incident-chat.service";
import { IncidentDocumentsTabComponent } from "./incident-documents-tab/incident-documents-tab.component";
import { IncidentDocumentEditModel } from "../models/incident-document-edit.model";
import { guid } from "@methods/uniqueMethods";
import { DocumentModel } from "../../shared/cerrix-documents/models/document.model";
import { BaseDocumentsService } from "../../shared/cerrix-documents/services/base-documents.service";
import { IncidentDocumentsService } from "../services/incident-documents.service";
import { IncidentDocumentModel } from "../models/incident-document.model";
import { ChatToggleButtonComponent } from "@app/shared/components/chat/chat-toggle-button/chat-toggle-button.component";
import { CerrixChatComponent } from "@app/shared/components/chat/cerrix-chat/cerrix-chat.component";
import { BaseChatService } from "@app/shared/components/chat/services/base-chat.service";
import { IncidentUsersService } from "../services/incident-users.service";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { IncidentWorkflowChangeComponent } from "./incident-workflow-change/incident-workflow-change.component";
import { IncidentStatusEditModel } from "../models/incident-status-edit.model";
import { IncidentStatus } from "../models/incident-status.enum";
import { IncidentWorkflowChangeInputModel } from "./incident-workflow-change/models/incident-workflow-change-input.model";
import { CerrixMessageDialogComponent } from "@app/shared/cerrix-message-dialog/cerrix-message-dialog.component";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { IncidentPermissionType } from "@enums/permissions/incident-permission-type.enum";
import { PermissionLevel } from "@enums/permissions/permission-level.enum";
import { IncidentOrganizationService } from "../services/incident-organization.service";
import { IncidentThirdPartyService } from "../services/incident-thirdparty.service";
import { IncidentRootCauseCategoryService } from "../services/incident-root-cause-category.service";
import { IncidentClassificationService } from "../services/incident-classification.service";
import { IncidentBusinessDimensionService } from "../services/incident-business-dimension.service";
import { IncidentFrameworkDimensionService } from "../services/incident-framework-dimension.service";
import { FormFieldConfig } from "@app/forms/models";
import { FormStructureType } from "@app/forms/enums/FormStructureType";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { LocalizationService } from "@app/shared";
import { IncidentCommentType } from "../models/incident-comment-type.enum";
import { ChatCommentModel } from "@app/shared/components/chat/models/chat-comment-model";
import { CerrixWorkflowBarComponent } from "@app/shared/cerrix-workflow-bar/cerrix-workflow-bar.component";
import { WorkflowStepItemModel } from "@app/shared/cerrix-workflow-bar/models/workflow-step-item";
import { WorkflowStepStatus } from "@app/shared/cerrix-workflow-bar/models/workflow-step-status.enum";
import { IncidentLinksTabComponent } from "./incident-links-tab/incident-links-tab.component";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { MoiTypes } from "@enums/moi/MoiTypes";

@Component({
    selector: "incident-details",
    templateUrl: "./incident-details.component.html",
    styleUrls: ["./incident-details.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatTabsModule,
        CerrixAvatarGroupComponent,
        MatDialogModule,
        CerrixTitleComponent,
        CerrixTextareaComponent,
        CerrixFieldWrapperComponent,
        CerrixDatepickerComponent,
        CerrixButtonComponent,
        DateTimeDisplayPipe,
        IncidentDetailsTabcontentComponent,
        IncidentCustomFieldsTabcontentComponent,
        IncidentDocumentsTabComponent,
        ChatToggleButtonComponent,
        CerrixChatComponent,
        CerrixDropMenuComponent,
        CerrixIconComponent,
        IncidentLinksTabComponent,
        CerrixWorkflowBarComponent,
    ],
    providers: [
        IncidentService,
        ToastrService,
        { provide: BaseChatService, useClass: IncidentChatService },
        { provide: BaseDocumentsService, useClass: IncidentDocumentsService },
    ],
})
export class IncidentDetailsComponent implements OnInit {
    private readonly maxTextareaLength = 32767;
    private readonly maxTextfieldLength = 300;

    private readonly destroyRef = inject(DestroyRef);
    private readonly toastrService = inject(ToastrService);
    private readonly cerrixDialogService = inject(CerrixDialogService);
    private readonly incidentService = inject(IncidentService);
    private readonly incidentTypesService = inject(IncidentTypesService);
    private readonly documentsService = inject(BaseDocumentsService);
    private readonly usersService = inject(IncidentUsersService);
    private readonly userService = inject(UserService);
    private readonly dialogService = inject(CerrixDialogService);
    private chatService = inject(BaseChatService);
    private localizationService = inject(LocalizationService);
    private readonly permissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );
    private readonly organizationService = inject(IncidentOrganizationService);
    private readonly thirdpartyService = inject(IncidentThirdPartyService);
    private readonly rootCauseCategoryService = inject(IncidentRootCauseCategoryService);
    private readonly classificationService = inject(IncidentClassificationService);
    private readonly businessDimensionService = inject(IncidentBusinessDimensionService);
    private readonly frameworkDimensionService = inject(IncidentFrameworkDimensionService);
    private readonly formsDataService = inject(FormsDataService);
    private readonly moiService = inject(MoiDataService);

    private readonly tabService = inject(TabService);
    private readonly _pages = inject(Pages);

    protected reportedOrganizations: TreeItemNode[] | undefined = undefined;
    protected causedByOrganizations: TreeItemNode[] | undefined = undefined;
    protected affectedOrganizations: TreeItemNode[] | undefined = undefined;
    protected rootCauseCategories: TreeItemNode[] | undefined = undefined;
    protected classifications: SelectOptionModel[] | undefined = undefined;
    protected businessDimensions: TreeItemNode[] | undefined = undefined;
    protected frameworkDimensions: TreeItemNode[] | undefined = undefined;
    protected causedByThirdparties: SelectOptionModel[] | undefined = undefined;
    protected affectedThirdparties: SelectOptionModel[] | undefined = undefined;
    protected currencies: CurrencyInterface[] | undefined = undefined;

    protected incidentTypes: OrderdListItemModel[] = [];
    protected selectedIncidentTypes: IncidentTypeTabModel[] = [];

    public tab: TabModel;
    public id: string;

    protected readonly IncidentStatus: typeof IncidentStatus = IncidentStatus;

    protected dataLoaded: boolean = false;
    protected saveDisabled: boolean = true;
    protected incidentUpdated: boolean = false;

    protected canEditIncident: boolean = false;
    protected canEditStaffing: boolean = false;
    protected canReopenIncident: boolean = false;
    protected settingsPermissions: IncidentDetailsSettingsMenuModel = {};
    protected showSettingsMenu: boolean = false;
    protected selectedPermissionIndex: number = 0;
    protected incident: IncidentDetailsModel = undefined;
    protected documents: DocumentModel[] = [];

    //used for documents, users, risks and controls changes
    protected incidentChanged: boolean = false;

    protected headerExpanded: boolean = false;

    protected futureDateLimit: Date;
    protected minDueDate?: Date;
    protected minDateDetected?: Date;

    protected incidentForm: FormGroup;
    protected incidentReference: string;

    @ViewChild("chatComponent") private chatComponent: CerrixChatComponent;
    protected chatText: string = `Chat`;
    protected displayComments: boolean = false;

    users: UserModel[] = [];
    assignedUsers: UserListInterface[] = [
        {
            id: "a0b0851f-1ccc-44cb-81c5-0bc82d249641",
            name: "Reporter",
            description: "Reported the incident",
            users: [],
            selectableUsers: [],
            userLimit: 1,
            disabled: true,
            required: true,
        },
        {
            id: "0804a9b2-ae09-4fcd-aea1-0bdf2aa52093",
            name: "Assessor",
            description: "Evaluates the incident",
            users: [],
            selectableUsers: [],
            userLimit: -1,
            required: true,
        },
        {
            id: "e3ffca00-4902-447d-8044-0bbda7d3d6b2",
            name: "Responsible",
            description: "Makes improvements",
            users: [],
            selectableUsers: [],
            userLimit: -1,
            required: true,
        },
        {
            id: "bf0a9f17-e7b0-44d2-b5e2-93daddade95f",
            name: "Informed",
            description: "Informed about the incident",
            users: [],
            selectableUsers: [],
            userLimit: -1,
        },
    ];

    userMappings = [
        { index: 0, property: "reportedById", isArray: false },
        { index: 1, property: "assessorsIds", isArray: true },
        { index: 2, property: "responsiblesIds", isArray: true },
        { index: 3, property: "informedIds", isArray: true },
    ];

    private dialog = inject(MatDialog);
    private reportedById: string;

    protected comments: ChatCommentModel[] = [];
    private getComments(): Observable<ChatCommentModel[]> {
        if (this.id) {
            return this.chatService.getComments(this.id);
        } else {
            return new Observable((observer) => {
                observer.next([]);
                observer.complete();
            });
        }
    }

    protected workflowSteps: WorkflowStepItemModel[] = [];

    private getStepAssignedUsers(mappingIndex: number): { key: string; values: string[] }[] {
        if (
            this.assignedUsers[mappingIndex].users?.length &&
            this.assignedUsers[mappingIndex].selectableUsers
        ) {
            const users = this.assignedUsers[mappingIndex].selectableUsers
                .filter((su) => this.assignedUsers[mappingIndex].users.includes(su.id))
                .map((su) => su.name);
            if (users.length) {
                return [{ key: "Assignee(s)", values: users }];
            }
        }

        return [];
    }

    private getCompletedStepDetails(
        commentTypes: string[],
        action: string,
        dateTimePipe: DateTimeDisplayPipe
    ): { key: string; values: string[] }[] {
        let lastCommentForTypes: ChatCommentModel | null = null;

        this.comments.forEach((c) => {
            if (
                commentTypes.includes(c.commentType) &&
                (lastCommentForTypes === null || c.createdOn > lastCommentForTypes.createdOn)
            ) {
                lastCommentForTypes = c;
            }
        });

        return lastCommentForTypes
            ? [
                  {
                      key: action,
                      values: [lastCommentForTypes.userName],
                  },
                  {
                      key: "Date completed",
                      values: [dateTimePipe.transform(lastCommentForTypes.createdOn)],
                  },
              ]
            : [];
    }

    private getWorkflowStepStatus(incidentStepStatus: IncidentStatus): WorkflowStepStatus {
        if (this.incident?.incidentStatus?.id) {
            if (incidentStepStatus.valueOf() < this.incident.incidentStatus.id.valueOf()) {
                return WorkflowStepStatus.Completed;
            } else if (this.incident.incidentStatus.id.valueOf() === incidentStepStatus.valueOf()) {
                return this.incident.incidentStatus.id === IncidentStatus.Closed ||
                    this.incident.incidentStatus.id === IncidentStatus.Rejected
                    ? WorkflowStepStatus.Completed
                    : WorkflowStepStatus.Active;
            }
        }

        return WorkflowStepStatus.Disabled;
    }

    private mapWorkflowSteps() {
        this.workflowSteps = [];
        const dateTimePipe = new DateTimeDisplayPipe(this.localizationService);

        //Reporting Step
        const reportingStep: WorkflowStepItemModel = {
            id: "Reporting",
            name: "Report incident",
            infoTitle: "New incident",
            status: this.incident?.id ? WorkflowStepStatus.Completed : WorkflowStepStatus.Active,
        };
        if (this.incident?.id) {
            reportingStep.stepDetails = this.getCompletedStepDetails(
                [IncidentCommentType[IncidentCommentType.Created]],
                "Reported by",
                dateTimePipe
            );
        }
        this.workflowSteps.push(reportingStep);

        //Assessing Step
        const assessingStep: WorkflowStepItemModel = {
            id: "Assessing",
            name: "Assessing",
            infoTitle: "Evaluate incident",
            status: this.getWorkflowStepStatus(IncidentStatus.AwaitingAcceptance),
        };
        if (this.incident?.id) {
            assessingStep.stepDetails =
                assessingStep.status === WorkflowStepStatus.Completed
                    ? this.getCompletedStepDetails(
                          this.incident.incidentStatus?.id === IncidentStatus.Rejected
                              ? [IncidentCommentType[IncidentCommentType.Rejected]]
                              : [IncidentCommentType[IncidentCommentType.Accepted]],
                          "Completed by",
                          dateTimePipe
                      )
                    : this.getStepAssignedUsers(1);
        }
        this.workflowSteps.push(assessingStep);

        if (this.incident?.incidentStatus?.id === IncidentStatus.Rejected) {
            //Rejected Step
            const rejectedStep: WorkflowStepItemModel = {
                id: "Rejected",
                name: "Rejected",
                infoTitle: "Rejected incident",
                status: WorkflowStepStatus.Completed,
            };
            if (this.incident?.id) {
                rejectedStep.stepDetails = this.getCompletedStepDetails(
                    [IncidentCommentType[IncidentCommentType.Rejected]],
                    "Completed by",
                    dateTimePipe
                );
            }
            this.workflowSteps.push(rejectedStep);
        } else {
            //Improving Step
            const improvingStep: WorkflowStepItemModel = {
                id: "Improving",
                name: "Improving",
                infoTitle: "Analyse incident",
                status: this.getWorkflowStepStatus(IncidentStatus.AwaitingImprovements),
            };
            if (this.incident?.id) {
                improvingStep.stepDetails =
                    improvingStep.status === WorkflowStepStatus.Completed
                        ? this.getCompletedStepDetails(
                              [IncidentCommentType[IncidentCommentType.ReadyForApproval]],
                              "Completed by",
                              dateTimePipe
                          )
                        : this.getStepAssignedUsers(2);
            }
            this.workflowSteps.push(improvingStep);

            //Reviewing Step
            const reviewingStep: WorkflowStepItemModel = {
                id: "Review",
                name: "Review",
                infoTitle: "Review incident",
                status: this.getWorkflowStepStatus(IncidentStatus.ReadyForApproval),
            };
            if (this.incident?.id) {
                reviewingStep.stepDetails =
                    reviewingStep.status === WorkflowStepStatus.Completed
                        ? this.getCompletedStepDetails(
                              [IncidentCommentType[IncidentCommentType.Approved]],
                              "Completed by",
                              dateTimePipe
                          )
                        : this.getStepAssignedUsers(1);
            }
            this.workflowSteps.push(reviewingStep);

            //Completed Step
            const completedStep: WorkflowStepItemModel = {
                id: "Completed",
                name: "Completed",
                infoTitle: "Completed incident",
                status: this.getWorkflowStepStatus(IncidentStatus.Closed),
            };
            if (this.incident?.id) {
                completedStep.stepDetails =
                    completedStep.status === WorkflowStepStatus.Completed
                        ? this.getCompletedStepDetails(
                              [IncidentCommentType[IncidentCommentType.Approved]],
                              "Completed by",
                              dateTimePipe
                          )
                        : this.incident.dueDate
                        ? [
                              {
                                  key: "Due date",
                                  values: [dateTimePipe.transform(this.incident.dueDate)],
                              },
                          ]
                        : null;
            }
            this.workflowSteps.push(completedStep);
        }
    }

    private saveOnTabClose: boolean = false;

    private unsavedChangesCheck(tabActionType: "close" | "refresh", checkOnly?: boolean): boolean {
        if (!this.saveOnTabClose) {
            const unsavedChanges = this.incidentForm?.dirty || this.incidentChanged;
            const unsavedChatChanges = this.chatComponent?.hasUnsavedChanges();

            if (unsavedChanges !== true && unsavedChatChanges !== true) {
                return true;
            }

            if (!checkOnly) {
                this.dialogService.openDialog({
                    data: {
                        title: "Unsaved changes",
                        message:
                            "You have unsaved changes. Do you want to save your changes first?",
                        icon: "warning",
                        iconClass: "material-symbols-outlined warning",
                        positiveButton: {
                            text: "Save & close",
                            buttonClass: "affirmative",
                            onAction: () => {
                                const saveActionList: Observable<boolean>[] = [];

                                if (unsavedChanges) {
                                    if (this.validateIncidentForm()) {
                                        saveActionList.push(this.saveIncident(null, true));
                                    } else {
                                        saveActionList.push(
                                            new Observable((observer) => {
                                                observer.next(false);
                                                observer.complete();
                                            })
                                        );
                                    }
                                }
                                if (unsavedChatChanges) {
                                    saveActionList.push(this.chatComponent.saveComment());
                                }

                                this.saveOnTabClose = true;

                                forkJoin(saveActionList)
                                    .pipe(takeUntilDestroyed(this.destroyRef))
                                    .subscribe({
                                        next: (results) => {
                                            this.saveOnTabClose = false;

                                            if (!results.some((r) => !r)) {
                                                this.tabAction(tabActionType);
                                            }
                                        },
                                        error: () => {
                                            this.saveOnTabClose = false;
                                        },
                                    });

                                return true;
                            },
                        },
                        negativeButton: {
                            text: "Continue without saving",
                            buttonClass: "cancel",
                            onAction: () => {
                                this.tabAction(tabActionType);
                                return true;
                            },
                        },
                    },
                });
            }
        }

        return false;
    }

    private tabAction(tabActionType: "close" | "refresh") {
        if (tabActionType === "close") {
            this.tab.beforeClose = () => true;
            this.tab.close();
        } else {
            this.tab.beforeRefresh = () => true;
            this.tab.refresh();
        }
    }

    ngOnInit() {
        this.tab.beforeClose = (checkOnly: boolean) => {
            return this.unsavedChangesCheck("close", checkOnly);
        };
        this.tab.beforeRefresh = (checkOnly: boolean) => {
            return this.unsavedChangesCheck("refresh", checkOnly);
        };

        this.futureDateLimit = new Date();

        forkJoin([
            this.getEditIncidentRights().pipe(takeUntilDestroyed(this.destroyRef)),
            this.getIncident().pipe(takeUntilDestroyed(this.destroyRef)),
            this.incidentTypesService.getAll().pipe(takeUntilDestroyed(this.destroyRef)),
            this.userService.getUserGuid().pipe(takeUntilDestroyed(this.destroyRef)),
            this.usersService.getUsersForStaffing().pipe(takeUntilDestroyed(this.destroyRef)),
            this.getComments().pipe(takeUntilDestroyed(this.destroyRef)),
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([canEditIncident, incident, incidentTypes, userGuid, users, comments]) => {
                this.comments = comments;

                this.canEditIncident = canEditIncident;
                userGuid = userGuid.replaceAll('"', "");

                this.incidentTypes = incidentTypes.map((it) => {
                    return { id: it.id.toString(), name: it.translation, order: it.order };
                });

                for (const permission in users) {
                    const currentList = this.assignedUsers.filter((x) => x.id === permission);
                    if (currentList !== undefined && currentList.length > 0) {
                        for (const user of users[permission]) {
                            currentList.first().selectableUsers.push(user);
                        }
                    }
                }

                if (incident?.id) {
                    this.tab.name = `(I) ${incident.identifier} - ${incident.name}`;
                    this.incident = incident;
                    this.documents = this.getMappedIncidentDocuments(incident.incidentDocuments);
                    this.displayComments = true;
                    this.canEditStaffing = this.getEditStaffingPermission(incident, userGuid);
                    this.getSettingsPermissions();
                    if (this.canEditStaffing) {
                        this.selectedPermissionIndex = 1;
                    }

                    if (
                        this.incident.incidentStatus.id === IncidentStatus.Closed ||
                        this.incident.incidentStatus.id === IncidentStatus.Rejected
                    ) {
                        this.incidentService
                            .canReopenIncident(this.incident.id)
                            .subscribe((canReopen) => {
                                this.canReopenIncident = canReopen;
                            });
                    } else {
                        this.canReopenIncident = false;
                    }

                    this.userMappings.forEach((mapping) => {
                        if (mapping.isArray) {
                            this.assignedUsers[mapping.index].users = incident[
                                mapping.property
                            ].filter((id) =>
                                this.assignedUsers[mapping.index].selectableUsers.some(
                                    (user) => user.id === id
                                )
                            );
                        } else if (
                            this.assignedUsers[mapping.index].selectableUsers.some(
                                (user) => user.id === incident[mapping.property]
                            )
                        ) {
                            this.assignedUsers[mapping.index].users.push(
                                incident[mapping.property]
                            );
                        }
                    });

                    for (const list in this.assignedUsers) {
                        this.assignedUsers[list].disabled = false;
                    }

                    if (this.incident?.incidentTypeIds.length) {
                        this.selectedIncidentTypes = this.incidentTypes.filter((it) =>
                            this.incident.incidentTypeIds.some((id) => id === it.id)
                        );
                    }

                    this.incidentReference = `(${this.incident.identifier}) ${this.incident.name}`;
                } else {
                    // Only reporter can add incidents, otherwise stop and close tab.
                    const isReporter = this.permissionService.hasAnyPermission(
                        MicroserviceModule.Incidents,
                        [IncidentPermissionType.SelectableAsReporter]
                    );
                    if (!isReporter) {
                        this.toastrService.error("Not enough rights!");
                        this.tab.close(false);
                        return;
                    }
                    this.headerExpanded = true;
                    this.reportedById = userGuid;
                    this.assignedUsers[0].users.push(this.reportedById);
                    this.canEditStaffing = true;
                    this.incidentReference = "New incident";
                }

                this.getOrganizations(incident?.id);
                this.getRootCauseCategories(incident?.id);
                this.getClassifications();
                this.getBusinessDimensions(incident?.id);
                this.getFrameworkDimensions(incident?.id);
                this.getThirdParties(incident?.id);
                this.getCurrencies();

                this.createIncidentForm();
                this.loadCustomFieldsForIncidentTypes(true, this.incident?.incidentTypeIds);

                this.mapWorkflowSteps();

                this.tab.showLoader = false;
                this.dataLoaded = true;
                this.saveDisabled = false;
            });
    }

    private getOrganizations(incidentId: string): void {
        if (!incidentId) {
            this.organizationService
                .getAll()
                .pipe(map(this.mapTranslatableTreeOptions))
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((organizations) => {
                    this.reportedOrganizations = organizations;
                    this.causedByOrganizations = organizations;
                    this.affectedOrganizations = organizations;
                });
        } else {
            forkJoin([
                this.organizationService
                    .getReportedOrganizations(incidentId)
                    .pipe(map(this.mapTranslatableTreeOptions))
                    .pipe(takeUntilDestroyed(this.destroyRef)),
                this.organizationService
                    .getCausedbyOrganizations(incidentId)
                    .pipe(map(this.mapTranslatableTreeOptions))
                    .pipe(takeUntilDestroyed(this.destroyRef)),
                this.organizationService
                    .getAffectedOrganizations(incidentId)
                    .pipe(map(this.mapTranslatableTreeOptions))
                    .pipe(takeUntilDestroyed(this.destroyRef)),
            ])
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(
                    ([reportedOrganizations, causedbyOrganizations, affectedOrganizations]) => {
                        this.reportedOrganizations = reportedOrganizations;
                        this.causedByOrganizations = causedbyOrganizations;
                        this.affectedOrganizations = affectedOrganizations;
                    }
                );
        }
    }

    private getRootCauseCategories(incidentId: string): void {
        this.rootCauseCategoryService
            .getAllByIncident(incidentId)
            .pipe(map(this.mapTranslatableTreeOptions))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((rootCauseCategories) => {
                this.rootCauseCategories = rootCauseCategories;
            });
    }

    private getClassifications(): void {
        this.classificationService
            .getAll()
            .pipe(
                map((options) =>
                    options.map((o) => {
                        return { id: o.id.toString(), name: o.translation, color: o.color };
                    })
                )
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((classifications) => {
                this.classifications = classifications;
            });
    }

    private getBusinessDimensions(incidentId: string): void {
        this.businessDimensionService
            .getAllByIncident(incidentId)
            .pipe(map(this.mapTranslatableTreeOptions))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((businessDimensions) => {
                this.businessDimensions = businessDimensions;
            });
    }

    private getFrameworkDimensions(incidentId: string): void {
        this.frameworkDimensionService
            .getAllByIncident(incidentId)
            .pipe(map(this.mapTranslatableTreeOptions))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((frameworkDimensions) => {
                this.frameworkDimensions = frameworkDimensions;
            });
    }

    private getThirdParties(incidentId: string): void {
        if (!incidentId) {
            this.thirdpartyService
                .getAll()
                .pipe(
                    map((options) =>
                        options.map((o) => {
                            return { id: o.id.toString(), name: o.name, color: null };
                        })
                    )
                )
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((thirdparties) => {
                    this.causedByThirdparties = thirdparties;
                    this.affectedThirdparties = thirdparties;
                });
        } else {
            forkJoin([
                this.thirdpartyService
                    .getCausedbyThirdParties(incidentId)
                    .pipe(
                        map((options) =>
                            options.map((o) => {
                                return { id: o.id.toString(), name: o.name, color: null };
                            })
                        )
                    )
                    .pipe(takeUntilDestroyed(this.destroyRef)),
                this.thirdpartyService
                    .getAffectedThirdParties(incidentId)
                    .pipe(
                        map((options) =>
                            options.map((o) => {
                                return { id: o.id.toString(), name: o.name, color: null };
                            })
                        )
                    )
                    .pipe(takeUntilDestroyed(this.destroyRef)),
            ]).subscribe(([causedbyThirdparties, affectedThirdparties]) => {
                this.causedByThirdparties = causedbyThirdparties;
                this.affectedThirdparties = affectedThirdparties;
            });
        }
    }

    private getFilteredOrganizationsById(
        organizations: TreeItemNode[],
        organizationIds: string[]
    ): TreeItemNode[] {
        return organizations?.filter((o) => organizationIds.includes(o.id));
    }

    private getFilteredThirdPartiesById(
        thirdparties: SelectOptionModel[],
        thirdpartyIds: string[]
    ): SelectOptionModel[] {
        return thirdparties?.filter((t) => thirdpartyIds.includes(t.id));
    }

    private getCurrencies() {
        const callModel = <FormFieldConfig>{
            isAdminRequest: false,
            structureType: FormStructureType.Currency,
        };
        this.formsDataService.getStructureData(null, callModel).then((currencies) => {
            this.currencies = currencies.map((c) => {
                return { id: c["ID"], name: c["Name"], sign: c["Sign"] };
            });
        });
    }

    protected getCurrentlySelectedOrganizations(): TreeItemNode[] {
        if (this.causedByOrganizations === undefined || this.affectedOrganizations === undefined) {
            return undefined;
        }

        let selectedOrgs = [
            ...this.getFilteredOrganizationsById(
                this.causedByOrganizations,
                this.incidentForm.value["details"]["causedByOrganizationIds"] ??
                    this.incident?.causedByOrganizationIds ??
                    []
            ),
            ...this.getFilteredOrganizationsById(
                this.affectedOrganizations,
                this.incidentForm.value["details"]["affectedOrganizationIds"] ??
                    this.incident?.affectedOrganizationIds ??
                    []
            ),
        ].reduce((acc: TreeItemNode[], org: TreeItemNode) => {
            if (!acc.find((item: TreeItemNode) => item.id === org.id)) {
                acc.push(org);
            }
            return acc;
        }, []);

        return selectedOrgs;
    }

    protected getCurrentlySelectedThirdParties(): SelectOptionModel[] {
        if (this.causedByThirdparties === undefined || this.affectedThirdparties === undefined) {
            return undefined;
        }

        let selectedTp = [
            ...this.getFilteredThirdPartiesById(
                this.causedByThirdparties,
                this.incidentForm.value["details"]["causedByThirdPartyIds"] ??
                    this.incident?.causedByThirdPartyIds ??
                    []
            ),
            ...this.getFilteredThirdPartiesById(
                this.affectedThirdparties,
                this.incidentForm.value["details"]["affectedThirdPartyIds"] ??
                    this.incident?.affectedThirdPartyIds ??
                    []
            ),
        ].reduce((acc: SelectOptionModel[], tp: SelectOptionModel) => {
            if (!acc.find((item: SelectOptionModel) => item.id === tp.id)) {
                acc.push(tp);
            }
            return acc;
        }, []);

        return selectedTp;
    }

    private readonly mapTranslatableTreeOptions = (
        options: (TreeItemNode & {
            translation: string;
        })[]
    ): TreeItemNode[] =>
        options.map((n) => {
            return {
                id: n.id,
                name: n.translation ?? n.name,
                parentId: n.parentId,
                icon: n.icon,
                order: n.order,
            };
        });

    protected toggleHeaderSection(): void {
        this.headerExpanded = !this.headerExpanded;
    }

    protected onChange(controlName: "occurredOn" | "detectedOn"): void {
        if (controlName === "occurredOn") {
            const headerDetails = <FormGroup>this.incidentForm.controls["headerDetails"];

            this.minDateDetected = headerDetails.get("occurredOn").value;

            headerDetails.setControl(
                "detectedOn",
                new FormControl<Date>(headerDetails.get("detectedOn").value, [
                    CerrixValidators.required("Date detected is required"),
                    CerrixValidators.maxDate(
                        this.futureDateLimit,
                        "Date detected can not be in the future"
                    ),
                    CerrixValidators.minDate(
                        this.minDateDetected,
                        "Date detected can not be before date occurred",
                        "beforeDateOccurred"
                    ),
                ])
            );
            const detectedOn = headerDetails.get("detectedOn");
            detectedOn.updateValueAndValidity({ onlySelf: true });
            detectedOn.markAsTouched();
        }

        if (controlName === "occurredOn" || controlName === "detectedOn") {
            const headerDetails = <FormGroup>this.incidentForm.controls["headerDetails"];

            this.minDueDate = this.getMaxFromDates(
                headerDetails.get("occurredOn").value,
                headerDetails.get("detectedOn").value
            );

            headerDetails.setControl(
                "dueDate",
                new FormControl<Date>(headerDetails.get("dueDate").value, [
                    CerrixValidators.minDate(
                        headerDetails.get("occurredOn").value,
                        "Due date can not be before date occurred",
                        "beforeDateOccurred"
                    ),
                    CerrixValidators.minDate(
                        headerDetails.get("detectedOn").value,
                        "Due date can not be before date detected",
                        "beforeDateDetected"
                    ),
                ])
            );

            const dueDate = headerDetails.get("dueDate");
            dueDate.updateValueAndValidity({ onlySelf: true });
            dueDate.markAsTouched();
        }
    }

    protected onRiskIdsChange(riskIds: string[]) {
        this.incident.riskIds = riskIds;
        this.incidentChanged = true;
    }

    protected onControlIdsChange(controlIds: string[]) {
        this.incident.controlIds = controlIds;
        this.incidentChanged = true;
    }

    protected onAssignedUsersChange(assignedUsers: UserListInterface[]) {
        this.assignedUsers = assignedUsers;
        this.incidentChanged = true;
    }

    protected onDocumentsChange(documents: DocumentModel[]) {
        this.documents = documents;
        this.incidentChanged = true;
    }

    private async setUpdatedIncident(
        updatedIncident: IncidentDetailsModel,
        statusChanged: boolean
    ) {
        this.incidentChanged = false;
        this.incident = updatedIncident;
        this.incidentReference = `(${this.incident.identifier}) ${this.incident.name}`;
        this.documents = this.getMappedIncidentDocuments(this.incident.incidentDocuments);

        if (statusChanged) {
            await this.updateUserPermissions(this.incident);
        }

        this.incidentUpdated = true;

        this.createIncidentForm();
        this.addCustomFieldsInForm(true, this.selectedIncidentTypes);

        this.getComments()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((comments) => {
                this.comments = comments;
                this.mapWorkflowSteps();
            });

        if (
            this.incident.incidentStatus.id === IncidentStatus.Closed ||
            this.incident.incidentStatus.id === IncidentStatus.Rejected
        ) {
            this.incidentService.canReopenIncident(this.incident.id).subscribe((canReopen) => {
                this.canReopenIncident = canReopen;
            });
        } else {
            this.canReopenIncident = false;
        }
    }

    protected onSave(): void {
        if (this.validateIncidentForm()) {
            this.saveIncident().subscribe((_) => {});
        }
    }

    protected onReopen(): void {
        const dialogRef = this.dialog.open<
            IncidentWorkflowChangeComponent,
            IncidentWorkflowChangeInputModel,
            { comment: string }
        >(IncidentWorkflowChangeComponent, {
            data: {
                title: "Reopen",
                infoText: "Please give a comment why incident is reopened.",
                btnText: "Reopen",
                btnColor: "success",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.comment) {
                this.saveDisabled = true;
                this.incidentService.reopenIncident(this.incident.id, result.comment).subscribe({
                    next: async (updatedIncident) => {
                        const oldIncidentStatus = this.incident.incidentStatus.id;

                        await this.setUpdatedIncident(updatedIncident, true);

                        this.displayStatuschangedMessage(
                            oldIncidentStatus,
                            this.incident.incidentStatus.id
                        );

                        this.toastrService.success("Update completed");
                        this.saveDisabled = false;
                    },
                    error: () => {
                        this.saveDisabled = false;
                    },
                });
            }
        });
    }

    private validateIncidentForm(): boolean {
        this.incidentForm.markAllAsTouched();

        const validationErrors = [
            ...this.getInvalidDateErrors(),
            ...this.getInvalidStaffingErrors(),
            ...getFormErrorMessages(this.incidentForm),
        ];

        if (
            !this.incidentForm.value["headerDetails"]?.description?.trim() ||
            !this.incidentForm.value["headerDetails"]?.detectedOn
        ) {
            if (this.headerExpanded == false) {
                this.toggleHeaderSection();
            }
        }

        if (validationErrors.length) {
            this.toastrService.warning(
                validationErrors.map((e) => "- " + e).join("\n"),
                "Save failed"
            );
            return false;
        }

        return this.incidentForm.valid;
    }

    protected saveIncident(
        changedStatus?: IncidentStatusEditModel,
        stopAfterSave?: boolean
    ): Observable<boolean> {
        return new Observable((observer) => {
            const documents = this.getUpdatedIncidentDocuments();

            let incident: IncidentEditModel = {
                ...this.incident,
                ...this.incidentForm.value["headerDetails"],
                ...this.incidentForm.value["details"],
                incidentCustomFields: this.getCustomFieldsFormValues(),
                incidentDocuments: documents.incidentDocuments,
            };
            incident.reportedById = this.assignedUsers[0].users.first();
            incident.assessorsIds = this.assignedUsers[1].users;
            incident.responsiblesIds = this.assignedUsers[2].users;
            incident.informedIds = this.assignedUsers[3].users;

            if (incident.id) {
                if (changedStatus) {
                    incident.incidentStatus = changedStatus.incidentStatus;
                    incident.statusChangeComment = changedStatus.statusChangeComment;
                } else {
                    incident.incidentStatus = this.incident.incidentStatus.id;
                }

                this.saveDisabled = true;
                this.incidentService.editIncident(incident, documents.files).subscribe({
                    next: async (updatedIncident) => {
                        if (stopAfterSave) {
                            observer.next(true);
                            observer.complete();
                        }

                        const oldIncidentStatus = this.incident.incidentStatus.id;

                        await this.setUpdatedIncident(updatedIncident, !!changedStatus);

                        if (changedStatus) {
                            this.displayStatuschangedMessage(
                                oldIncidentStatus,
                                this.incident.incidentStatus.id
                            );
                        }

                        this.toastrService.success("Update completed");
                        this.saveDisabled = false;

                        observer.next(true);
                        observer.complete();
                    },
                    error: () => {
                        this.saveDisabled = false;
                        observer.next(false);
                        observer.complete();
                    },
                });
            } else {
                this.saveDisabled = true;
                this.incidentService.addIncident(incident).subscribe({
                    next: (incidentId) => {
                        if (stopAfterSave) {
                            observer.next(true);
                            observer.complete();
                        }

                        this.tab.id = incidentId;
                        this.id = incidentId;
                        this.incidentService.getIncident(incidentId).subscribe({
                            next: async (updatedIncident) => {
                                await this.setUpdatedIncident(updatedIncident, true);

                                this.showConfirmPopup(
                                    "Incident reported",
                                    "You have succesfully reported your incident. You can close this popup"
                                );

                                this.toastrService.success("Update completed");

                                this.tab.name = `(I) ${this.incident.identifier} - ${this.incident.name}`;
                                this.saveDisabled = false;

                                observer.next(true);
                                observer.complete();
                            },
                            error: () => {
                                this.saveDisabled = false;

                                observer.next(false);
                                observer.complete();
                            },
                        });
                    },
                    error: () => {
                        this.saveDisabled = false;

                        observer.next(false);
                        observer.complete();
                    },
                });
            }
        });
    }

    protected onIncidentStatusChange(currentStatus: IncidentStatus, newStatus: IncidentStatus) {
        if (this.validateIncidentForm()) {
            let dialogInput: IncidentWorkflowChangeInputModel | null = null;

            switch (currentStatus) {
                case IncidentStatus.AwaitingAcceptance:
                    switch (newStatus) {
                        case IncidentStatus.AwaitingImprovements:
                            dialogInput = {
                                title: "Accept reported incident",
                                btnText: "Accept report",
                                btnColor: "success",
                            };
                            break;
                        case IncidentStatus.Rejected:
                            dialogInput = {
                                title: "Reject reported incident",
                                infoText:
                                    "Please give comment as to why this incident report is rejected and closed.",
                                btnText: "Send feedback",
                                btnColor: "primary",
                            };
                            break;
                    }
                    break;
                case IncidentStatus.AwaitingImprovements:
                    dialogInput = {
                        title: "Ready for review",
                        btnText: "Ready for review",
                        btnColor: "success",
                    };
                    break;
                case IncidentStatus.ReadyForApproval:
                    switch (newStatus) {
                        case IncidentStatus.Closed:
                            dialogInput = {
                                title: "Approve & close",
                                infoText:
                                    "When approving this workflow, this incident report will be closed.",
                                btnText: "Approve & close",
                                btnColor: "success",
                            };
                            break;
                        case IncidentStatus.AwaitingImprovements:
                            dialogInput = {
                                title: "Reject",
                                infoText:
                                    "When rejecting this workflow, your feedback is send to the responsibles.",
                                btnText: "Send feedback",
                                btnColor: "primary",
                            };
                            break;
                    }
                    break;
            }

            if (dialogInput) {
                const dialogRef = this.dialog.open<
                    IncidentWorkflowChangeComponent,
                    IncidentWorkflowChangeInputModel,
                    { comment: string }
                >(IncidentWorkflowChangeComponent, {
                    data: dialogInput,
                });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result?.comment) {
                        this.saveIncident({
                            incidentStatus: newStatus,
                            statusChangeComment: result.comment,
                        }).subscribe((_) => {});
                    }
                });
            }
        }
    }

    private displayStatuschangedMessage(
        oldIncidentStatus: IncidentStatus,
        incidentStatus: IncidentStatus
    ): void {
        let title = "";
        let message = "";

        switch (oldIncidentStatus) {
            case IncidentStatus.AwaitingAcceptance:
                switch (incidentStatus) {
                    case IncidentStatus.AwaitingImprovements:
                        title = "Incident report accepted";
                        message =
                            "The incident report is accepted and ready for making improvements.";
                        break;
                    case IncidentStatus.Rejected:
                        title = "Incident report rejected";
                        message = "This incident report is rejected and closed.";
                        break;
                }
                break;
            case IncidentStatus.AwaitingImprovements:
                title = "Ready for review";
                message = "This incident report is now ready to be reviewed";
                break;
            case IncidentStatus.ReadyForApproval:
                switch (incidentStatus) {
                    case IncidentStatus.Closed:
                        title = "Incident approved & closed";
                        message = "This incident report is succesfully approved & closed.";
                        break;
                    case IncidentStatus.AwaitingImprovements:
                        title = "Incident report rejected";
                        message =
                            "This incident report is rejected and returned to the responsible(s) for further improvement.";
                        break;
                }
                break;
            case IncidentStatus.Closed:
                if (incidentStatus === IncidentStatus.AwaitingImprovements) {
                    title = "Incident report reopened";
                    message =
                        'The closed incident report is successfully reopened and is now in status "Awaiting improvements"';
                }
                break;
            case IncidentStatus.Rejected:
                if (incidentStatus === IncidentStatus.AwaitingAcceptance) {
                    title = "Incident report reopened";
                    message =
                        'The rejected incident report is successfully reopened and is now in status "Awaiting acceptance"';
                }
                break;
        }

        if (title !== "" && message !== "") {
            this.dialog.open(CerrixMessageDialogComponent, {
                data: {
                    title: title,
                    message: message,
                },
            });
        }
    }

    protected onIncidentTypeChange(itIds: string[]): void {
        const incidentCFform = <FormRecord<FormRecord>>(
            this.incidentForm.get("incidentCustomFields")
        );

        const removedIncidentTypes = this.selectedIncidentTypes.filter(
            (sit) => !itIds.includes(sit.id)
        );
        if (removedIncidentTypes.length) {
            removedIncidentTypes.forEach((removedIT) => {
                this.selectedIncidentTypes = this.selectedIncidentTypes.filter(
                    (sit) => sit !== removedIT
                );
                incidentCFform.removeControl(removedIT.id);
            });
        }

        const addedIncidentTypeIds = itIds.filter(
            (id) => !this.selectedIncidentTypes.some((sit) => sit.id === id)
        );
        if (addedIncidentTypeIds.length) {
            const addedIncidentTypes = this.incidentTypes.filter((it) =>
                addedIncidentTypeIds.includes(it.id)
            );
            this.selectedIncidentTypes = [
                ...this.selectedIncidentTypes,
                ...addedIncidentTypes,
            ].sort((a, b) => a.order - b.order);

            this.loadCustomFieldsForIncidentTypes(false, addedIncidentTypeIds);
        }
    }

    protected formHasErrors(form: AbstractControl): boolean {
        return form && form.invalid && (form.dirty || form.touched);
    }

    private getEditIncidentRights(): Observable<boolean> {
        if (this.id) {
            return this.incidentService.getEditIncidentRights(this.id);
        } else {
            return new Observable((observer) => {
                observer.next(true);
                observer.complete();
            });
        }
    }

    private getIncident(): Observable<IncidentDetailsModel | undefined> {
        if (this.id) {
            return this.incidentService.getIncident(this.id);
        } else {
            return new Observable((observer) => {
                observer.next(undefined);
                observer.complete();
            });
        }
    }

    private getInvalidDateErrors(): string[] {
        const invalidDateErrors: string[] = [];

        if (this.incidentForm.get("headerDetails").get("dueDate").hasError("matDatepickerParse")) {
            invalidDateErrors.push("Due date is invalid");
        }

        if (
            this.incidentForm.get("headerDetails").get("detectedOn").hasError("matDatepickerParse")
        ) {
            invalidDateErrors.push("Date detected is invalid");
        }

        if (
            this.incidentForm.get("headerDetails").get("occurredOn").hasError("matDatepickerParse")
        ) {
            invalidDateErrors.push("Date occurred is invalid");
        }

        return invalidDateErrors;
    }

    private getInvalidStaffingErrors(): string[] {
        const invalidStaffingErrors: string[] = [];

        this.assignedUsers.forEach((userList) => {
            if (userList.required && !userList.users.length) {
                invalidStaffingErrors.push(`There is no user as ${userList.name} assigned yet`);
            }
        });

        return invalidStaffingErrors;
    }

    public async deleteIncident(): Promise<void> {
        if (this.incident?.id) {
            const linkedMois = await this.moiService
                .getLinkedMois(this.incident.id, MoiTypes.Incident_Moi)
                .toPromise();

            if (linkedMois.length > 0) {
                const moiIds = linkedMois.map((moi) => moi.identifier);
                this.toastrService.warning(
                    `the following MoI(s) are still attached to this incident: ${moiIds.join(
                        ", "
                    )} \nPlease delete these MoI(s) first`,
                    "Deletion of this incident is not possible because:",
                    { enableHtml: true }
                );
                return;
            }

            this.dialogService.openWarningConfirmDialog({
                data: {
                    promptUiid: "delete-incident-dialog-uiid",
                    title: "Delete incident",
                    message: "Are you sure you want to delete this incident?",
                    positiveButton: {
                        text: "Delete incident",
                        buttonClass: "warning",
                        onAction: () => {
                            const refreshIncidents = () => {
                                this.tabService.refresh(this._pages.Incidents);
                            };
                            this.incidentService.deleteIncident(this.incident.id).subscribe({
                                next: () => {
                                    this.tab.close(false);
                                    refreshIncidents();
                                    this.toastrService.success("Incident deleted");
                                },
                            });
                            return true;
                        },
                    },
                    negativeButton: {
                        text: "Cancel",
                        buttonClass: "cancel",
                        onAction: () => {
                            return true;
                        },
                    },
                },
            });
        }
    }

    private createIncidentForm(): void {
        this.minDueDate = this.getMaxFromDates(
            this.incident?.occurredOn ? new Date(this.incident.occurredOn) : undefined,
            this.incident?.detectedOn ? new Date(this.incident.detectedOn) : undefined
        );

        this.incidentForm = new FormGroup({
            headerDetails: new FormGroup({
                name: new FormControl<string>(this.incident?.name, [
                    CerrixValidators.required("Incident name is required"),
                    CerrixValidators.maxLength(
                        this.maxTextfieldLength,
                        "Incident name exceeds max length"
                    ),
                ]),
                dueDate: new FormControl<Date>(
                    this.incident?.dueDate ? new Date(this.incident.dueDate) : undefined,
                    [
                        CerrixValidators.minDate(
                            this.incident?.occurredOn
                                ? new Date(this.incident.occurredOn)
                                : undefined,
                            "Due date can not be before date occurred",
                            "beforeDateOccurred"
                        ),
                        CerrixValidators.minDate(
                            this.incident?.detectedOn
                                ? new Date(this.incident.detectedOn)
                                : undefined,
                            "Due date can not be before date detected",
                            "beforeDateDetected"
                        ),
                    ]
                ),
                detectedOn: new FormControl<Date>(
                    this.incident?.detectedOn ? new Date(this.incident.detectedOn) : undefined,
                    [
                        CerrixValidators.required("Date detected is required"),
                        CerrixValidators.maxDate(
                            this.futureDateLimit,
                            "Date detected can not be in the future"
                        ),
                        CerrixValidators.minDate(
                            this.incident?.occurredOn
                                ? new Date(this.incident.occurredOn)
                                : undefined,
                            "Date detected can not be before date occurred",
                            "beforeDateOccurred"
                        ),
                    ]
                ),
                occurredOn: new FormControl<Date>(
                    this.incident?.occurredOn ? new Date(this.incident.occurredOn) : undefined,
                    CerrixValidators.maxDate(
                        this.futureDateLimit,
                        "Date occurred can not be in the future"
                    )
                ),
                description: new FormControl<string>(this.incident?.description, [
                    CerrixValidators.maxLength(
                        this.maxTextareaLength,
                        "Description exceeds max length"
                    ),
                    CerrixValidators.required("Description is required"),
                ]),
            }),
            details: new FormGroup({
                organizationId: new FormControl<string>(
                    this.incident?.organizationId,
                    CerrixValidators.required("Reporting organization is required")
                ),
                incidentTypeIds: new FormControl<string[]>(this.incident?.incidentTypeIds),
                causedByOrganizationIds: new FormControl<string[]>(
                    this.incident?.causedByOrganizationIds
                ),
                affectedOrganizationIds: new FormControl<string[]>(
                    this.incident?.affectedOrganizationIds
                ),
                causedByThirdPartyIds: new FormControl<string[]>(
                    this.incident?.causedByThirdPartyIds
                ),
                affectedThirdPartyIds: new FormControl<string[]>(
                    this.incident?.affectedThirdPartyIds
                ),
                rootCauseCategoryIds: new FormControl<string[]>(
                    this.incident?.rootCauseCategoryIds
                ),
                classificationId: new FormControl<string>(this.incident?.classificationId),
                rootCause: new FormControl<string>(
                    this.incident?.rootCause,
                    CerrixValidators.maxLength(
                        this.maxTextareaLength,
                        "Rootcause exceeds max length"
                    )
                ),
                impact: new FormControl<string>(
                    this.incident?.impact,
                    CerrixValidators.maxLength(
                        this.maxTextareaLength,
                        "Impact description exceeds max length"
                    )
                ),
                businessDimensionIds: new FormControl<string[]>(
                    this.incident?.businessDimensionIds
                ),
                frameworkDimensionIds: new FormControl<string[]>(
                    this.incident?.frameworkDimensionIds
                ),
                internalIdentifier: new FormControl<string>(this.incident?.internalIdentifier),
            }),
            incidentCustomFields: new FormRecord({}),
        });

        if (!this.canEditIncident) {
            this.incidentForm.disable();
        }
    }

    private getMaxFromDates(firstDate?: Date, secondDate?: Date): Date | null {
        if (firstDate && secondDate) {
            return firstDate > secondDate ? firstDate : secondDate;
        }

        return firstDate ? firstDate : secondDate;
    }

    protected cfRequestsInProgress: number = 0;

    private loadCustomFieldsForIncidentTypes(
        useCurrentIncidentValue: boolean,
        incidentTypeIds?: string[]
    ): void {
        if (incidentTypeIds?.length) {
            this.cfRequestsInProgress++;

            this.incidentTypesService.getSectionsAndCustomFields(incidentTypeIds).subscribe({
                next: (incidentTypes) => {
                    const remainingIncidentTypes: IncidentTypeDisplayModel[] = [];

                    incidentTypes.forEach((it) => {
                        const selectedIncidentType = this.selectedIncidentTypes.find(
                            (sit) => sit.id === it.id
                        );
                        if (selectedIncidentType) {
                            remainingIncidentTypes.push(it);
                            selectedIncidentType.customFields = it.customFields;
                            selectedIncidentType.incidentTypeSections = it.incidentTypeSections;
                        }
                    });

                    this.addCustomFieldsInForm(useCurrentIncidentValue, remainingIncidentTypes);

                    this.cfRequestsInProgress--;
                },
                error: () => {
                    this.cfRequestsInProgress--;
                },
            });
        }
    }

    private addCustomFieldsInForm(
        useCurrentIncidentValue: boolean,
        incidentTypes: IncidentTypeDisplayModel[]
    ): void {
        if (incidentTypes.length) {
            const incidentCFform = <FormRecord<FormRecord>>(
                this.incidentForm.get("incidentCustomFields")
            );
            incidentTypes.forEach((it) => {
                const customFieldsForm = new FormRecord({});

                it.customFields?.forEach((cf) => {
                    this.addCustomFieldInForm(useCurrentIncidentValue, customFieldsForm, cf);
                });

                it.incidentTypeSections?.forEach((section) => {
                    section.customFields?.forEach((cf) => {
                        this.addCustomFieldInForm(useCurrentIncidentValue, customFieldsForm, cf);
                    });
                });

                incidentCFform.addControl(it.id, customFieldsForm);
            });

            if (!this.canEditIncident) {
                incidentCFform.disable();
            }
        }
    }

    private addCustomFieldInForm(
        useCurrentIncidentValue: boolean,
        customFieldsForm: FormRecord,
        customField: CustomFieldDisplayModel
    ): void {
        let customFieldValue: string | string[] | number | moment.Moment = null;
        const stringValue = useCurrentIncidentValue
            ? this.incident?.customFields?.find((icf) => icf.id === customField.id)?.value
            : null;

        if (stringValue) {
            switch (customField.customFieldType) {
                case FieldType.MultiSelect:
                    customFieldValue = stringValue.split(",");
                    break;
                case FieldType.Numeric:
                    customFieldValue = parseInt(stringValue);
                    break;
                case FieldType.Date:
                case FieldType.DateTime:
                    customFieldValue = moment(stringValue);
                    break;
                default:
                    customFieldValue = stringValue;
                    break;
            }
        }

        customFieldsForm.addControl(
            customField.id,
            new FormControl(
                customFieldValue,
                customField.isRequired
                    ? CerrixValidators.required(`${customField.translation}  is required`)
                    : undefined
            )
        );
    }

    private getCustomFieldsFormValues(): IncidentCustomFieldEditModel[] {
        const result: IncidentCustomFieldEditModel[] = [];

        const incidentCFform = <FormRecord<FormRecord>>(
            this.incidentForm.get("incidentCustomFields")
        );

        Object.keys(incidentCFform.value).forEach((itKey) => {
            Object.keys(incidentCFform.controls[itKey].value).forEach((cfKey) => {
                const value = (<AbstractControl>incidentCFform.controls[itKey].controls[cfKey])
                    .value;

                result.push({
                    customFieldId: cfKey,
                    value: value
                        ? value instanceof Date || value instanceof moment
                            ? (value as moment.Moment).toISOString()
                            : value.toString()
                        : null,
                });
            });
        });

        return result;
    }

    private getUpdatedIncidentDocuments(): {
        incidentDocuments: IncidentDocumentEditModel[];
        files: { [id: string]: File };
    } {
        const incidentDocuments: IncidentDocumentEditModel[] = [];
        const files: { [id: string]: File } = {};

        this.documents.forEach((document) => {
            const documentId = document.id ?? guid();

            incidentDocuments.push({
                id: documentId,
                name: document.name,
                documentTypeId: document.documentTypeId,
                description: document.description,
                isNew: !document.id,
            });

            if (document.file) {
                files[documentId] = document.file;
            }
        });

        return { incidentDocuments, files };
    }

    private getMappedIncidentDocuments(
        incidentDocuments: IncidentDocumentModel[]
    ): DocumentModel[] {
        return incidentDocuments
            ? incidentDocuments.map((d) => {
                  return {
                      ...d,
                      fileIcon: this.documentsService.getDocumentIcon(d.name),
                  };
              })
            : [];
    }
    protected onToggleComments() {
        this.displayComments = !this.displayComments;
    }
    private showConfirmPopup(title: string, message: string): void {
        this.dialogService.openSuccessDialog({
            data: {
                title: title,
                message: message,
            },
        });
    }
    private getEditStaffingPermission(incident: IncidentDetailsModel, userGuid: string): boolean {
        if (
            incident.incidentStatus.id === IncidentStatus.Rejected ||
            incident.incidentStatus.id === IncidentStatus.Closed
        ) {
            return false;
        }

        if (incident.assessorsIds.includes(userGuid)) {
            switch (incident.incidentStatus.id) {
                case IncidentStatus.AwaitingAcceptance:
                case IncidentStatus.AwaitingImprovements:
                case IncidentStatus.ReadyForApproval:
                    return true;
                default:
                    break;
            }
        }

        const permissionLevel = this.permissionService.getPermissionLevel(
            MicroserviceModule.Incidents,
            IncidentPermissionType.EditIncident
        );
        switch (permissionLevel) {
            case PermissionLevel.Unrestricted:
            case PermissionLevel.Restricted:
                return this.canEditIncident;
            default:
                return false;
        }
    }

    private async getSettingsPermissions(): Promise<void> {
        if (this.id) {
            this.settingsPermissions.deletePermission = await lastValueFrom(
                this.incidentService.getDeleteIncidentPermission(this.id)
            );
        }

        this.showSettingsMenu = Object.keys(this.settingsPermissions).some(
            (p) => this.settingsPermissions[p]
        );
    }

    private async updateUserPermissions(incident: IncidentDetailsModel): Promise<void> {
        this.canEditIncident = await lastValueFrom(this.getEditIncidentRights());
        const userGuid = await lastValueFrom(this.userService.getUserGuid());
        this.canEditStaffing = this.getEditStaffingPermission(incident, userGuid);
        this.getSettingsPermissions();
    }
}
