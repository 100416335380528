import { RecurrenceFrequency } from "./cerrix-recurrence-frequency.enum";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators, FormsModule } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { getFormControl } from "@methods/CommonMethods";
import { KeyValue } from "@models/generic/KeyValuePair";
import { RecurrenceViewModel } from "./recurrence-view-model";
import { SelectOptionModel } from "@models/generic";

const stopEmit = { emitEvent: false };

@Component({
    selector: "cerrix-recurrence",
    templateUrl: "./cerrix-recurrence.component.html",
    styleUrls: ["./cerrix-recurrence.component.scss"],
})
export class CerrixRecurrenceComponent implements OnInit {
    @Input() parentForm: FormGroup;
    @Input() model: RecurrenceViewModel;
    @Input() disableAllExcept: string[];

    constructor() {}

    formGroup: FormGroup;
    frequencies: IdNameCombination[];
    frequencycount: number;

    recurrenceFrequencys = RecurrenceFrequency;

    whenSelection: IdNameCombination[] = [
        { ID: 1, Name: "First" },
        { ID: 2, Name: "Second" },
        { ID: 3, Name: "Third" },
        { ID: 4, Name: "Fourth" },
        { ID: -1, Name: "Last" },
    ];

    weekDays: SelectOptionModel<string>[] = [
        { value: "SU", label: "S" },
        { value: "MO", label: "M" },
        { value: "TU", label: "T" },
        { value: "WE", label: "W" },
        { value: "TH", label: "T" },
        { value: "FR", label: "F" },
        { value: "SA", label: "S" },
    ];

    daySelection: KeyValue<string, string>[];
    months: IdNameCombination[];
    disabledControls: string[] = [];
    byDayDisabled: boolean;

    ngOnInit() {
        this.frequencies = this.getFrequencies();
        this.daySelection = this.getDays();
        this.months = this.getMonths();
        if (!this.model.byDay) {
            this.model.byDay = "";
        }
        this.initFormGroup();
    }

    initFormGroup() {
        this.formGroup = new FormGroup({
            startdate: new FormControl(this.model.startdate, Validators.required),
            enddate: new FormControl(this.model.enddate),
            frequency: new FormControl(this.model.frequency, Validators.required),
            interval: new FormControl(this.model.interval),
            byMonthDay: new FormControl(this.model.byMonthDay),
            byMonth: new FormControl(this.model.byMonth),
            otherByDayType: new FormControl(this.model.otherByDayType),
            otherByDay: new FormControl(this.model.otherByDay),
        });
        this.formGroup.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(this.formGroup, this.model);
        });
        if (this.disableAllExcept?.length > 0) {
            let controls = Object.keys(this.formGroup.controls).filter(
                (e) => !this.disableAllExcept.find((disable) => disable === e)
            );
            this.disableControls(controls);
        }
        this.byDayDisabled = this.model.id && this.model.id > 0; // disabled in edit mode
    }

    validate() {
        FormValidationHelper.markAllAsTouched(this.formGroup);
        if (this.model.frequency == this.recurrenceFrequencys.Weekly) {
            this.setWeeklyValidation();
        }
        if (this.model.enddate < this.model.startdate) {
            return "<br> - The end date cannot be before the start date.";
        }

        if (!this.formGroup.valid) {
            const validationErrors = FormValidationHelper.getFormControlErrors(this.formGroup);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            return validationMessage;
        }
        // check weekly, because these are no form controls.
        if (this.model.frequency == this.recurrenceFrequencys.Weekly) {
            if (!this.model.byDay || this.model.byDay.length === 0) {
                return "<br> - Please fill in all required fields.";
            }
        }
        return null;
    }

    frequencyChanged(change: IdNameCombination) {
        switch (change.ID) {
            case RecurrenceFrequency.Weekly:
                this.model.interval = 1;
                this.frequencycount = 52;
                break;
            case RecurrenceFrequency.Monthly:
                this.model.interval = 1;
                this.frequencycount = 12;
                this.model.radioSelection = "Day";
                break;
            case RecurrenceFrequency.Yearly:
                this.model.interval = 1;
                this.frequencycount = 10;
                this.model.radioSelection = "Day";
                break;
        }

        const control = getFormControl<RecurrenceViewModel>(this.formGroup, "interval");
        control.setValue(1);

        this.setConditionalValidations(change.ID);
    }

    setConditionalValidations(frequency: RecurrenceFrequency) {
        const radioDaySelection = this.model.radioSelection == "Day";
        switch (frequency) {
            case RecurrenceFrequency.Weekly:
                this.setControlValidation("enddate", true);
                this.setControlValidation("byMonth", false);
                this.setControlValidation("byMonthDay", false);
                this.setControlValidation("otherByDayType", false);
                this.setControlValidation("otherByDay", false);
                break;
            case RecurrenceFrequency.Monthly:
                this.setControlValidation("enddate", true);
                this.setControlValidation("byMonth", false);
                this.setControlValidation("byMonthDay", radioDaySelection);
                this.setControlValidation("otherByDayType", !radioDaySelection);
                this.setControlValidation("otherByDay", !radioDaySelection);
                break;
            case RecurrenceFrequency.Yearly:
                this.setControlValidation("enddate", true);
                this.setControlValidation("byMonth", true);
                this.setControlValidation("byMonthDay", radioDaySelection);
                this.setControlValidation("otherByDayType", !radioDaySelection);
                this.setControlValidation("otherByDay", !radioDaySelection);
                break;
        }
    }

    setControlValidation(name: keyof RecurrenceViewModel, required: boolean) {
        const ctrl = getFormControl(this.formGroup, name);
        if (required) {
            // add required validation
            ctrl.addValidators(Validators.required);
        } else {
            // remove required validation
            ctrl.clearValidators();
        }
        ctrl.updateValueAndValidity();
        ctrl.markAsUntouched();
    }

    getItems(nrOfItems: number): IdNameCombination[] {
        const items: IdNameCombination[] = [];
        for (let i = 1; i <= nrOfItems; i++) {
            items.push(new IdNameCombination(i, i.toString()));
        }

        return items;
    }

    getFrequencies() {
        return [
            { ID: RecurrenceFrequency.Weekly, Name: "Weekly" },
            { ID: RecurrenceFrequency.Monthly, Name: "Monthly" },
            { ID: RecurrenceFrequency.Yearly, Name: "Yearly" },
        ];
    }

    getDays(): KeyValue<string, string>[] {
        return [
            { Key: "su", Value: "Sunday" },
            { Key: "mo", Value: "Monday" },
            { Key: "tu", Value: "Tuesday" },
            { Key: "we", Value: "Wednesday" },
            { Key: "th", Value: "Thursday" },
            { Key: "fr", Value: "Friday" },
            { Key: "sa", Value: "Saturday" },
        ];
    }

    getMonths(): IdNameCombination[] {
        return [
            { ID: 1, Name: "January" },
            { ID: 2, Name: "February" },
            { ID: 3, Name: "March" },
            { ID: 4, Name: "April" },
            { ID: 5, Name: "May" },
            { ID: 6, Name: "June" },
            { ID: 7, Name: "July" },
            { ID: 8, Name: "August" },
            { ID: 9, Name: "September" },
            { ID: 10, Name: "October" },
            { ID: 11, Name: "November" },
            { ID: 12, Name: "December" },
        ];
    }

    onRadioChanged(event) {
        this.model.radioSelection = event.target.value;
        this.setRadioButton(event.target.value);
    }

    setRadioButton(value: string) {
        if (value == "Day") {
            this.setControlValidation("byMonthDay", true);
            this.setControlValidation("otherByDayType", false);
            this.setControlValidation("otherByDay", false);
        } else {
            this.setControlValidation("byMonthDay", false);
            this.setControlValidation("otherByDayType", true);
            this.setControlValidation("otherByDay", true);
        }
    }

    toggleWeekDay(event, weekdaySelection: SelectOptionModel<string>) {
        if (!this.byDayDisabled) {
            if (!this.model.byDay.includes(weekdaySelection.value)) {
                if (this.model.byDay.length > 0) {
                    this.model.byDay += ",";
                }
                this.model.byDay += weekdaySelection.value;
            } else {
                this.model.byDay = this.model.byDay
                    .replace(weekdaySelection.value, "")
                    .replace(",,", ",");
                if (this.model.byDay.endsWith(",")) {
                    this.model.byDay = this.model.byDay.slice(0, -1);
                }
                if (this.model.byDay.startsWith(",")) {
                    this.model.byDay = this.model.byDay.slice(1);
                }
            }
            this.setWeeklyValidation();
        }
    }

    setWeeklyValidation() {
        const div = document.getElementById("weeklyDiv");
        if (this.model.byDay == "") {
            div.classList.add("invalid");
        } else {
            div.classList.remove("invalid");
        }
    }

    disableControls(controlNames: string[]) {
        controlNames.forEach((name) => getFormControl(this.formGroup, name).disable(stopEmit));
    }
}
