<div class="row">
    <div class="col-12 col-xl-6" *ngIf="permissions.CanOpenDi">
        <div class="detail-page-card">
            <div class="detail-page-card-title">D&I Scoring</div>
            <div class="detail-page-card-body padded cerrix-control-sm">
                <div style="width: 100%; height: 47px;" *ngIf="permissions.CanOpenAdvEff"></div>
                <control-testscore-chart #diChart></control-testscore-chart>
            </div>
        </div>
    </div>
    <div class="col-12 col-xl-6" *ngIf="permissions.CanOpenSimpleEff || permissions.CanOpenAdvEff">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Effectiveness Scoring</div>
            <div class="detail-page-card-body padded cerrix-control-sm">
                <control-testscore-chart *ngIf="permissions.CanOpenSimpleEff" #effSimple></control-testscore-chart>
                <control-adv-eff-chart *ngIf="permissions.CanOpenAdvEff" #effAdv></control-adv-eff-chart>
            </div>
        </div>
    </div>
</div>
