<div class="detail-page-summary" *ngIf="backupModel && backupModel.DateCreated">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>ID:</b> {{ backupModel.Identifier }}</div>
            <div class="property text-truncate"><b>Name:</b> {{ backupModel.Name }}</div>
            <div class="property">
                <b>Registration date:</b> {{ backupModel.DateCreated | dateFormat }}
            </div>
        </div>
        <div class="col-6">
            <div class="property"><b>Last modifier:</b> {{ backupModel.LastModifier }}</div>
            <div class="property"><b>Control owner:</b> {{ backupModel.Owner }}</div>
        </div>
    </div>
</div>

<!-- Global loader for whole Detail tab -->
<div class="detail-page-card" *ngIf="!controlFormGroup || !standingData">
    <div class="detail-page-card-title">Control detail</div>
    <div class="detail-page-card-body padded cerrix-control-sm">
        <div class="fancy-loader submodule"></div>
    </div>
</div>

<form
    [formGroup]="controlFormGroup"
    *ngIf="controlFormGroup && standingData"
    data-uiid="control-detail-form"
>
    <control-detail-overview
        data-uiid="control-detail-testscores"
        [ControlGuid]="controlModel.Guid"
        Label="Test scores"
        [HideOpenControlButton]="true"
        [HideControlDetails]="true"
        [HideDesignScore]="false"
        [HideEffectivenessScore]="false"
    >
    </control-detail-overview>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Control detail</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="form-group">
                        <label for="controlCatalogueSelect">Control catalogue</label>
                        <ng-select
                            data-uiid="control-detail-controlcatalogue"
                            id="controlCatalogueSelect"
                            name="controlCatalogueSelect"
                            [items]="standingData.ControlCatalogues"
                            formControlName="ControlCatalogue"
                            bindLabel="Name"
                            bindValue="ID"
                            (change)="onControlCatalogueChange($event)"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <cerrix-textfield
                            fieldLabel="Control name"
                            dataUiid="control-detail-controlname"
                            identifier="controlNameInput"
                            formControlName="Name"
                            placeholder="Required"
                        ></cerrix-textfield>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <ai-description-generator
                                fieldLabel="Description"
                                dataUiid="control-detail-description"
                                identifier="controlDescriptionInput"
                                [rows]="4"
                                formControlName="Description"
                                [fetchDescriptionsDatamethod]="fetchDescriptions"
                                (onDescriptionGenerated)="onDescriptionGenerated($event)"
                            ></ai-description-generator>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <div class="form-group">
                        <label>Organization</label>
                        <cerrix-select-tree
                            data-uiid="control-detail-organization"
                            [tree]="standingData.Organizations"
                            formControlName="Organization"
                            placeholder="Required"
                            [multiselect]="false"
                            (onChangeSingleId)="reloadBusinessData()"
                        >
                        </cerrix-select-tree>
                    </div>
                    <div class="form-group">
                        <label
                            >Business dimensions
                            <i
                                *ngIf="!selectedOrganizationId"
                                class="far fa-question-circle ms-2"
                                [matTooltip]="'Please select an organization first.'"
                            ></i>
                        </label>
                        <cerrix-select-tree
                            data-uiid="control-detail-businessdimensions"
                            [tree]="standingData.BusinessDimensions"
                            formControlName="BusinessDimensions"
                            [multiselect]="true"
                            [readonly]="!selectedOrganizationId"
                            (onChangeIds)="reloadFrameworkDimensions($event)"
                        >
                        </cerrix-select-tree>
                    </div>
                    <div class="form-group">
                        <label
                            >Framework dimensions
                            <i
                                *ngIf="!selectedOrganizationId"
                                class="far fa-question-circle ms-2"
                                [matTooltip]="'Please select an organization first.'"
                            ></i>
                        </label>
                        <cerrix-select-tree
                            data-uiid="control-detail-frameworkdimensions"
                            [tree]="standingData.FrameworkDimensions"
                            formControlName="FrameworkDimensions"
                            [multiselect]="true"
                            [readonly]="!selectedOrganizationId"
                        >
                        </cerrix-select-tree>
                    </div>
                    <div class="form-group">
                        <label> Applicable Organizations </label>
                        <cerrix-select-tree
                            data-uiid="control-detail-applicable-organizations"
                            [tree]="standingData.AllApplicableOrganizations"
                            formControlName="ApplicableOrganizations"
                            [multiselect]="true"
                            [readonly]="true"
                        >
                        </cerrix-select-tree>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <hr />
                    <div class="row">
                        <div class="form-group-inline col-4">
                            <i class="fas fa-key mx-2"></i>
                            <cerrix-checkbox
                                data-uiid="control-detail-keycontrol"
                                formControlName="KeyControl"
                                label="Key control"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                        </div>
                        <div class="form-group-inline col-4">
                            <i class="fas fa-search mx-2"></i>
                            <cerrix-checkbox
                                data-uiid="control-detail-requiresmonitoring"
                                formControlName="RequiresMonitoring"
                                label="Monitoring"
                                tooltip="Requires monitoring"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                        </div>
                        <div class="form-group-inline col-4">
                            <i class="fas fa-search mx-2"></i>
                            <cerrix-checkbox
                                data-uiid="control-detail-requiresexecution"
                                formControlName="RequiresExecution"
                                label="Execution"
                                tooltip="Requires execution"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                            <i
                                class="far fa-info-circle mx-2"
                                matTooltip="Use Cerrix to manage control execution."
                            >
                            </i>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <hr />
                    <div class="row">
                        <div class="form-group-inline col-6">
                            <i class="fas fa-share-alt mx-2"> </i>
                            <cerrix-checkbox
                                data-uiid="control-detail-isshared"
                                formControlName="IsShared"
                                label="Shared Control"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                            <i
                                class="far fa-info-circle mx-2"
                                matTooltip="When a control is shared, it can be linked to risks, events, third parties and dataprocessings from other organizations. This means that the control can be shown in the workspace of control users outside its organization."
                            >
                            </i>
                        </div>
                        <div class="form-group-inline col-6">
                            <i class="fas fa-map-pin mx-2"></i>
                            <cerrix-checkbox
                                data-uiid="control-detail-inplace"
                                formControlName="InPlace"
                                label="In place"
                                fontsize="0.9em"
                                (valueChange)="checkboxChanged($event)"
                            >
                            </cerrix-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <hr style="margin-top: 0.25em" />
                    <div class="form-group">
                        <label for="controlOwnerSelect">Control owner</label>
                        <ng-select
                            data-uiid="control-detail-controlowner"
                            id="controlOwnerSelect"
                            name="controlOwnerSelect"
                            [items]="standingData.ControlOwners"
                            [addTag]="true"
                            formControlName="Owner"
                            bindLabel="Name"
                            bindValue="Name"
                            placeholder="{{ inPlaceChecked ? 'Required' : '' }}"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label for="controlTypeSelect">Type</label>
                        <ng-select
                            data-uiid="control-detail-type"
                            id="controlTypeSelect"
                            name="controlTypeSelect"
                            [items]="standingData.Types"
                            formControlName="Type"
                            bindLabel="Name"
                            bindValue="ID"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label for="controlSourceSelect">Source</label>
                        <ng-select
                            data-uiid="control-detail-source"
                            id="controlSourceSelect"
                            name="controlSourceSelect"
                            [items]="standingData.Sources"
                            formControlName="Source"
                            bindLabel="Name"
                            bindValue="ID"
                        >
                        </ng-select>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-lg-6">
                            <label>Start date</label>
                            <date-input
                                data-uiid="control-detail-startdate"
                                formControlName="StartDate"
                            ></date-input>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label>End date</label>
                            <date-input
                                data-uiid="control-detail-enddate"
                                formControlName="EndDate"
                            ></date-input>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <hr style="margin-top: 0.25em" />
                    <div class="form-group">
                        <label for="controlFrequencySelect">Control frequency</label>
                        <ng-select
                            data-uiid="control-detail-controlfrequency"
                            id="controlFrequencySelect"
                            name="controlFrequencySelect"
                            [items]="standingData.Frequencies"
                            formControlName="Frequency"
                            bindLabel="Name"
                            bindValue="ID"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label for="controlAspectIcSelect">Aspect IC</label>
                        <ng-select
                            data-uiid="control-detail-aspectic"
                            id="controlAspectIcSelect"
                            name="controlAspectIcSelect"
                            [items]="standingData.AspectICs"
                            formControlName="AspectIc"
                            bindLabel="Name"
                            bindValue="ID"
                        >
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label for="controlExecutionSelect">Control execution method</label>
                        <ng-select
                            data-uiid="control-detail-controlexecutionmethod"
                            id="controlExecutionMethodSelect"
                            name="controlExecutionMethodSelect"
                            [items]="standingData.ControlExecutionMethods"
                            formControlName="ControlExecutionMethod"
                            bindLabel="Name"
                            bindValue="ID"
                        >
                        </ng-select>
                    </div>

                    <div class="form-group">
                        <cerrix-textarea
                            fieldLabel="Control objective"
                            dataUiid="control-detail-controlobjective"
                            identifier="controlObjectiveInput"
                            [rows]="4"
                            formControlName="ControlObjective"
                        ></cerrix-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="useControlAnalysis">
        <div class="detail-page-card-title">Control analysis</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="form-group">
                        <cerrix-textarea
                            fieldLabel="Conclusion summary"
                            dataUiid="control-detail-conclusionsummary"
                            identifier="conclusionSummaryInput"
                            formControlName="ConclusionSummary"
                        ></cerrix-textarea>
                    </div>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <div class="form-group">
                        <cerrix-textarea
                            fieldLabel="Comments"
                            dataUiid="control-detail-comments"
                            identifier="controlCommentsInput"
                            formControlName="Comments"
                        ></cerrix-textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="form-group col-12 col-lg-6">
                            <label for="initialCostsNumber">Initial costs</label>
                            <input
                                data-uiid="control-detail-initialcosts"
                                type="number"
                                class="form-control form-control-sm"
                                id="initialCostsNumber"
                                name="initialCostsNumber"
                                formControlName="InitialCosts"
                            />
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label for="annualCostsNumber">Annual costs</label>
                            <input
                                data-uiid="control-detail-annualcosts"
                                type="number"
                                class="form-control form-control-sm"
                                id="annualCostsNumber"
                                name="annualCostsNumber"
                                formControlName="AnnualCosts"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <generic-list-manager
        data-uiid="control-detail-raci"
        *ngIf="raciConfig"
        [config]="raciConfig"
    ></generic-list-manager>

    <!-- Custom fields -->
    <div class="detail-page-card" *ngIf="controlCustomFieldsFormGroup">
        <div class="detail-page-card-title">Custom fields</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <cerrix-custom-fields-editor
                [parentForm]="controlCustomFieldsFormGroup"
                [customFieldData]="customFieldData"
                [singleColumn]="false"
            ></cerrix-custom-fields-editor>
        </div>
    </div>
</form>
