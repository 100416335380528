export class ConnectionWizardModel {
    guid: string;
    // basic info
    name: string;
    description: string;
    employee: string;

    // API Key details
    expirationDate: Date;
    ipRestriction: string;

    // Linked controls
    linkedControls: number[] = [];

    // Test information
    testplanType: number;
    startDate: Date;
    endDate: Date;
}
