<div *ngIf="eventModel && eventModel.OrganizationId && isLoading" class="fancy-loader"></div>
<risk-linkable
    *ngIf="eventModel && eventModel.OrganizationId && !isLoading"
    #linkableRisks
    typename="risks"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    (linkedChange)="linkedItemsChanged($event)"
    (onLinkedClick)="editLinked($event)"
    (onBatchClicked)="openBatchEdit()"
>
</risk-linkable>
