import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterableIdNameCombination } from '@models/generic/FilterableIdNameCombination';
import { StandingdataDataService } from '@app/standingdata/shared/standingdata.service';
import { DatamanagementPermissionModel } from '@app/datamanagement/models/DatamanagementPermissionModel';
import { ProcessingPurposeModel } from '@app/datamanagement/models/ProcessingPurposeModel';
import { RiskLinkModel } from '@models/risk/RiskLinkModel';

@Component({
    selector: 'datamanagement-risks',
    templateUrl: './datamanagement-risks.component.html',
    styleUrls: ['./datamanagement-risks.component.scss']
})
export class DatamanagementRisksComponent {
    @Input() model: ProcessingPurposeModel;
    @Input() disabled = false;
    @Input() permissions: DatamanagementPermissionModel;

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    lastOrganizationId = 0;
    linked: FilterableIdNameCombination[];
    unlinked: FilterableIdNameCombination[];

    constructor(private standingdataDataService: StandingdataDataService) { }

    load() {
        if (!this.model || !this.model.organizationId || this.lastOrganizationId === this.model.organizationId) {
            return;
        }

        this.isLoading = true;
        this.lastOrganizationId = this.model.organizationId;

        this.standingdataDataService.getRisks(this.model.organizationId).subscribe(
            value => {
                this.loadData(this.model.riskIds, value.sortBy("name", true));
                this.isLoading = false;
            }
        );
    }

    linkedChanged(linked: RiskLinkModel[]) {
        this.model.riskIds = linked.map(x => x.id);
        this.changed.emit(this.model.riskIds);
    }

    private loadData(linkedIds: number[], items: RiskLinkModel[]) {
        const linked = [], unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
