import { Component, Input, OnInit } from "@angular/core";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { CellRendererPipe } from "@app/shared/pipes/cell-renderer.pipe";

@Component({
    selector: "workspace-cell",
    templateUrl: "./workspace-cell-renderer.component.html",
    styleUrls: ["./workspace-cell-renderer.component.scss"],
})
export class WorkspaceCellRendererComponent implements OnInit {
    @Input() configs: RendererConfig[];
    @Input() row: any;
    @Input() header: string;
    @Input() width: number;
    @Input() stretched: Boolean;
    @Input() renderer: RendererConfig;

    rendererTypes = RendererType;
    showTooltip = true;

    constructor(private cellRenderer: CellRendererPipe) {}

    ngOnInit(): void {
        if (!this.renderer) {
            const foundRenderers = (this.configs || []).filter((r) => r.textColumn === this.header);
            if (foundRenderers.length > 0) {
                this.renderer = foundRenderers[0];
                this.setShowTooltip(this.renderer);
            }
        } else {
            this.header = this.renderer.textColumn;
        }
    }

    defaultRender(value: any, renderer: RendererConfig) {
        return this.cellRenderer.transform(value, renderer);
    }

    overDueRender(value: any, rendererConfig: RendererConfig, overDueElement: HTMLDivElement) {
        let isOverDue = false;
        if (rendererConfig.actionColumn) {
            isOverDue = this.row[rendererConfig.actionColumn] === rendererConfig.actionValue;
        } else if (value && value instanceof Date) {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            isOverDue = value < currentDate;
        }

        if (isOverDue) {
            overDueElement.classList.add("overdue-style");
        }

        return this.cellRenderer.transform(value, rendererConfig);
    }

    setShowTooltip(renderer: RendererConfig) {
        this.showTooltip = renderer.type !== RendererType.Icon;
    }
}
