<app-linkable
    [typename]="typename"
    [displayValueProp]="displayValueProp"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    (onOpenClicked)="openItem($event)"
    [hideActions]="hideActions"
    [hideFilter]="hideFilter"
    [allowAddNew]="allowAddNew"
    [rendererConfig]="rendererConfig"
    [showAdvancedFilters]="showAdvancedFilters"
    [activeFilters]="activeFilters"
    [filterConfig]="filterConfig"
    [NumberProperties]="numberProperties"
    [moduleType]="moduleType"
>
</app-linkable>
