import { Component } from '@angular/core';
import { DevManagementDataService } from '@app/admin/services/devmanagement.service';
import { RoleRightPatchModel } from '@app/admin/shared/RoleRightMaintenanceModel';
import { GenericListConfig } from '@app/shared/models/GenericList/GenericList';
import { GenericListFieldType } from '@app/shared/models/GenericList/GenericListField';

@Component({
    selector: 'dev-role-right-patches',
    templateUrl: './dev-role-right-patches.component.html',
    styleUrls: ['./dev-role-right-patches.component.scss']
})
export class DevRoleRightPatchesComponent {
    config: GenericListConfig;

    constructor(private _ds: DevManagementDataService) {
        this.loadPatches();
    }

    loadPatches() {
        this._ds.getRoleRightPatches().subscribe(x => this.setupConfig(x));
    }

    setupConfig(patches: RoleRightPatchModel[]) {
        this.config = <GenericListConfig>{
            name: "All active role-right patches",
            data: patches,
            idProp: 'guid',

            allowAdd: false,
            allowEdit: false,
            allowDelete: true,

            fields: [{
                fieldName: "roleName",
                prettyName: "Role",
                fieldType: GenericListFieldType.Text,
            }, {
                fieldName: "rightName",
                prettyName: "Right",
                fieldType: GenericListFieldType.Text,
            }, {
                fieldName: "defaultValue",
                prettyName: "Linked by default",
                fieldType: GenericListFieldType.CheckBox,
            }, {
                fieldName: "currentValue",
                prettyName: "Currently linked",
                fieldType: GenericListFieldType.CheckBox,
            }],

            deleteOverride: (row: RoleRightPatchModel) => {
                this._ds.deleteRoleRightDefinition(row.roleID, row.rightID).subscribe(() => {
                    this._ds.getRoleRightPatches().subscribe(x => {
                        this.config.data = x;
                        this.config.reload();
                    });
                });
            }
        };
    }
}
