import { Component, OnInit, Input } from "@angular/core";
import { DataSubjectModel } from "@app/datamanagement/models/DataSubjectModel";
import { BsModalService } from "ngx-bootstrap/modal";
import { DataStructuresHelper } from "@app/datamanagement/shared/helpers/datastructures-helper";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { ToastrService } from "ngx-toastr";
import { DataStructureModel } from "@app/datamanagement/models/DataStructureModel";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "datasubjects-overview",
    templateUrl: "./datasubjects-overview.component.html",
    styleUrls: ["./datasubjects-overview.component.scss", "../../../../sass/datamanagement.scss"],
})
export class DataSubjectsOverviewComponent implements OnInit {
    @Input() dataSubjects: DataSubjectModel[];
    @Input() disabled: boolean;

    constructor(
        private _modalService: BsModalService,
        private _datamanagementDS: DatamanagementDataService,
        private _toastr: ToastrService
    ) {}

    ngOnInit() {
        this.dataSubjects = this.dataSubjects ? this.dataSubjects : [];

        this.loadDataStructures();
    }

    loadDataStructures() {
        this.dataSubjects.forEach((dataSubject) => {
            if (dataSubject.connectedId) {
                this._datamanagementDS
                    .getDataStructures(dataSubject.connectedId)
                    .subscribe((dataStructures) => {
                        dataSubject.dataStructures = dataStructures.map(
                            (x) =>
                                <DataStructureModel>{
                                    connectedId: x.id,

                                    dataSubjectId: dataSubject.id,
                                    id: x.dataStructureID,
                                    name: x.name,
                                    archivePolicy: x.archivePolicy,
                                }
                        );

                        dataSubject.dataStructuresLoaded = true;
                    });
            }
        });
    }

    viewDataStructures(dataSubject: DataSubjectModel) {
        DataStructuresHelper.viewArchivePolicyDialog(
            dataSubject,
            this._modalService,
            this.disabled
        ).subscribe((dialogResult: DataStructureModel[] | boolean) => {
            if (dialogResult) {
                dataSubject.dataStructures = dialogResult as DataStructureModel[];
            }
        });
    }
}
