export class SampleScoreColors {
    public static NoScore = "#B7BABF";
    public static Sufficient = "#66C664";
    public static Insufficient = "#e34935";
    public static Inapplicable = "#B7BABF";
}

export class StepScoreColorEnum {
    public static NoScore = "#B7BABF";
    public static Correct = "#66C664";
    public static Incorrect = "#e34935";
    public static Inapplicable = "#B7BABF";
    public static MissingEvidence = "#F39A14";
}
