<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar flex-action-bar" *ngIf="controlModel">
    <div>
        <button
            data-uiid="button-savecontrol"
            class="btn btn-cerrix"
            (click)="save()"
            *ngIf="permissions.CanEdit"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save control</span>
        </button>

        <span class="date-edited">
            Last save: {{ controlModel.DateModified | dateTimeFormat }}
        </span>
    </div>

    <div class="workflow-area" *ngIf="controlGuid">
        <button
            (click)="openSimpleTesting(simpleTestingTypes.DesignImplementation)"
            class="btn btn-dark"
            data-uiid="btn-open-design-implementation"
            *ngIf="permissions.CanOpenDi && requiresMonitoringOnInit"
        >
            <i class="fas fa-external-link-alt"></i>
            Design & Implementation testing
        </button>
        <button
            (click)="openControlExecution()"
            class="btn btn-dark"
            data-uiid="btn-open-control-execution"
            *ngIf="
                permissions.CanOpenControlExecution &&
                requiresExecutionOnInit
            "
        >
            <i class="fas fa-external-link-alt"></i>
            Control execution
        </button>
        <button
            (click)="openSimpleTesting(simpleTestingTypes.Effectiveness)"
            data-uiid="btn-open-simple-effectiveness"
            class="btn btn-dark"
            *ngIf="permissions.CanOpenSimpleEff && requiresMonitoringOnInit"
        >
            <i class="fas fa-external-link-alt"></i>
            Effectiveness testing
        </button>
        <button
            (click)="openAdvOverview()"
            data-uiid="btn-open-advanced-effectiveness"
            class="btn btn-dark"
            *ngIf="permissions.CanOpenAdvEff && requiresMonitoringOnInit"
        >
            <i class="fas fa-external-link-alt"></i>
            Effectiveness testing
        </button>
    </div>
</div>

<div
    class="control-screen-system"
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    *ngIf="controlModel"
>
    <div
        class="control-screens"
        [attr.name]="'Control'"
        menuicon="fas fa-shield-alt"
        menusystemsubmenu
    >
        <div menuItemId="menuDetails" name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <control-detail-main
                [controlModel]="controlModel"
                [cerrixTab]="tab"
                [permissions]="permissions"
                #detailMain
            >
            </control-detail-main>
        </div>

        <div
            name="Test result overview"
            [attr.menuItemId]="'menuTestResultOverview'"
            menuicon="fas fa-vials"
            menusystemitem
            *ngIf="controlGuid"
        >
            <control-test-result
                [guid]="controlGuid"
                [cerrixTab]="tab"
                [permissions]="permissions"
                #testResult
            >
            </control-test-result>
        </div>

        <div
            class="control-screens"
            [attr.menuItemId]="'menuLinks'"
            [attr.name]="'Links'"
            menuicon="fas fa-link"
            menusystemsubmenu
            *ngIf="controlGuid"
        >
            <div
                menuItemId="menuItemRisks"
                name="Risks"
                menuicon="fas fa-exclamation-triangle"
                menusystemitem
                *ngIf="permissions.CanViewRisks"
            >
                <control-risks [controlModel]="controlModel" #controlRisks> </control-risks>
            </div>
            <div
                menuItemId="menuItemEvents"
                name="Events"
                menuicon="fas fa-bolt"
                menusystemitem
                *ngIf="permissions.CanViewEvents"
            >
                <control-events #controlEvents [controlModel]="controlModel" [disabled]="true">
                </control-events>
            </div>
            <div
                menuItemId="menuItemMoi"
                name="Measures of Improvements"
                menuicon="fas fa-tasks"
                *ngIf="permissions.CanViewBim || permissions.CanViewMoi"
                menusystemitem
            >
                <control-mois
                    [permissions]="permissions"
                    [moiType]="cmMoiType"
                    [parentGuid]="controlGuid"
                    [controlModel]="controlModel"
                    #controlMoisComponent
                >
                </control-mois>
            </div>
            <div
                menuItemId="menuItemDm"
                name="Data processing"
                menuicon="fas fa-database"
                menusystemitem
                *ngIf="permissions.CanViewDm"
            >
                <control-datamanagement
                    #controlDmComponent
                    [controlModel]="controlModel"
                    [disabled]="!permissions.CanLinkDm"
                ></control-datamanagement>
            </div>
            <div
                menuItemId="menuItemTp"
                name="Third party"
                menuicon="fas fa-handshake"
                *ngIf="permissions.CanViewTp"
                menusystemitem
            >
                <control-thirdparty
                    #controlTpComponent
                    [controlModel]="controlModel"
                    [disabled]="true"
                >
                </control-thirdparty>
            </div>
            <div menuItemId="menuItemForms" name="Forms" menuicon="fas fa-file-alt" menusystemitem>
                <control-forms
                    #formsComponent
                    [controlModel]="controlModel"
                    [disabled]="!permissions.CanLinkForms"
                ></control-forms>
            </div>
            <div
                *ngIf="controlModel"
                menuItemId="menuItemDocuments"
                name="Documents"
                menuicon="far fa-file"
                menusystemitem
            >
                <div *ngIf="!documents">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="documents">
                    <document-manager-old
                        [(Documents)]="controlModel.Documents"
                        (DocumentsChange)="checkDocumentsDirty()"
                        [permissions]="permissions.DocumentPermissionModel"
                        [DocumentTypeTargets]="targetModule"
                    >
                    </document-manager-old>
                </div>
            </div>
            <div
                name="Hyperlinks"
                [attr.menuItemID]="hyperlinksMenuItemId"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="controlModel"
            >
                <div *ngIf="!controlModel.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="controlModel.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="controlModel.Hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>

        <div
            menuItemId="menuItemHistory"
            name="History"
            menuicon="fas fa-history"
            menusystemitem
            *ngIf="controlGuid"
        >
            <app-history-overview [historyDataService]="_controlDS" [Id]="controlGuid">
            </app-history-overview>
        </div>

        <div *ngIf="controlGuid" name="Options" menuicon="fas fa-check-double" menusystemitem>
            <detail-options
                moduleName="control"
                [objectName]="controlModel ? controlModel.Name : 'control'"
                [allowDelete]="permissions.CanDelete"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
