import { FroalaEvents } from "./FroalaEvents";

/** More options at https://www.froala.com/wysiwyg-editor/docs/options */
export class FroalaOptions {
    videoUpload: boolean;
    fileUpload: boolean;

    imageUpload: boolean;
    imageUploadURL: string;
    imageUploadMethod: string;
    imageUploadParam: string;
    imageUploadParams: {};

    imageManagerLoadURL: string;
    imageInsertButtons: string[];

    linkList: any[];
    attribution: boolean;

    charCounterCount: boolean;
    iconsTemplate: string;
    useClasses: boolean;
    spellcheck: boolean;
    theme: string;
    toolbarButtons: any;

    videoInsertButtons: string[];
    videoAllowedProviders: string[];

    events: FroalaEvents;
    key: string;
    quickInsertEnabled: boolean;
}
