import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { coerceBooleanProperty } from "@app/shared/helpers/coercion-helper";
import { TargetModule } from "@enums/document/TargetModule";
import { DocumentModel } from "@models/documents/documentmodel";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "adv-eff-source-documents",
    templateUrl: "./adv-eff-source-documents.component.html",
    styleUrls: ["./adv-eff-source-documents.component.scss"],
})
export class SourceDocumentsComponent {
    sourceDocumentPermissions = new DocumentManagerPermissionModel();
    documentTypeTargets = TargetModule.Control;

    @Input()
    public sourceDocuments: DocumentModel[];

    @Input()
    public sourceDocumentsFormGroup: FormGroup;

    @Input()
    get disabled() {
        return this._disabled;
    }
    set disabled(value: any) {
        const newValue = coerceBooleanProperty(value);

        if (newValue !== this.disabled) {
            this._disabled = newValue;
            this.sourceDocumentPermissions.canAddDocument = !newValue;
            this._changeDetectorRef.markForCheck();
        }
    }

    private _disabled = true;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
        this.sourceDocumentPermissions.canAddDocument = !this.disabled;
    }
}
