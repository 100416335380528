import { Component, OnInit, Input } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { MoiCommentPermissionModel } from "@models/moi/MoiCommentPermissionModel";
import { EventCommentModel } from "../shared/event-comment-model";
import { EventDataService } from "../services/EventDataService";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { EventCommentsDialogComponent } from "./event-comments-dialog/event-comments-dialog.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "event-comments",
    templateUrl: "./event-comments.component.html",
    styleUrls: ["./event-comments.component.scss"],
})
export class EventCommentsComponent implements OnInit {
    @Input() eventGuid: string;
    @Input() permissions: MoiCommentPermissionModel = new MoiCommentPermissionModel();

    isLoading = true;
    comments: EventCommentModel[];
    scopedComments: EventCommentModel[];

    showAll = false;

    dialogRef: BsModalRef;

    constructor(
        private _eventDS: EventDataService,
        private modalService: BsModalService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit(): void {
        if (!this.eventGuid) {
            this.isLoading = false;
            return;
        }

        this.loadComments();
    }

    loadComments(): void {
        this._eventDS.getComments(this.eventGuid).subscribe((value) => {
            this.comments = value;
            this.comments = this.comments.sort(
                (val1, val2) => val2.createdAt.getTime() - val1.createdAt.getTime()
            );

            this.showAllClick(false);
            this.isLoading = false;
        });
    }

    newCommentDialog(): Observable<EventCommentModel> {
        const comment = new EventCommentModel();

        return this.openNewDialog(comment);
    }

    newComment() {
        const comment = new EventCommentModel();
        comment.canEdit = this.permissions.canAddComment;

        this.editComment(comment);
    }

    editComment(comment: EventCommentModel) {
        this.openNewDialog(comment).subscribe((editedComment) => {
            if (comment && comment.canEdit) {
                this._eventDS
                    .storeComment(this.eventGuid, editedComment)
                    .subscribe((commentGuid) => {
                        this.loadComments();
                    });
            }
        });
    }

    private openNewDialog(comment: EventCommentModel): Subject<EventCommentModel> {
        const isExisting = comment && comment.id > 0;

        const config = {
            backdrop: "static",
            class: "comments-dialog",
            initialState: <EventCommentsDialogComponent>{
                title: isExisting ? "Edit comment" : "Add comment",
                eventGuid: this.eventGuid,
                comment: comment,
            },
        };

        this.dialogRef = this.modalService.show(EventCommentsDialogComponent, <any>config);

        return this.dialogRef.content.afterClosed;
    }

    deleteComment(comment: EventCommentModel) {
        this._promptService
            .confirm("Delete comment", "Are you sure you want to delete the comment?")
            .onConfirm()
            .subscribe(() => {
                this._eventDS.deleteComment(this.eventGuid, comment.id).subscribe((_) => {
                    this.loadComments();
                });
            });
    }

    showAllClick(showAll: boolean) {
        if (showAll) {
            this.scopedComments = this.comments;
        } else {
            this.scopedComments = this.comments.slice(0, 5);
        }

        this.showAll = showAll;
    }

    addClick() {
        this.newComment();
    }

    editClick(comment: EventCommentModel) {
        this.editComment(comment);
    }
}
