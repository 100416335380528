import { Component, Input } from '@angular/core';
import { TabService } from '@services/tabs/TabService';
import { Pages } from '@constants/pages/Pages';

@Component({
  selector: 'moi-processing-purpose-summary',
  templateUrl: './moi-processing-purpose-summary.component.html',
  styleUrls: ['./moi-processing-purpose-summary.component.scss']
})
export class MoiProcessingPurposeSummaryComponent {
  @Input() summaryModel: any;
  @Input() processingPurposeID: any;

  constructor(private tabservice: TabService, private pages: Pages) { }

  openProcessingPurpose() {
      if (!this.processingPurposeID || !this.summaryModel.CanOpenParent) {
          return;
      }

      this.tabservice.generateTab(this.pages.DataManagement, this.processingPurposeID);
  }
}
