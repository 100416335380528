.document-link-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    document-manager {
        .detail-page-card {
            margin: 0;
        }

        .detail-page-card-title,
        .detail-page-card-actions {
            display: none;
        }
    }
}
