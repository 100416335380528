export class AuditFindingModel {
    guid: string;
    auditGuid: string;
    id: number;
    identifier: string;
    findingReportGuid: string;

    createdBy: string;
    createdOn: string;

    modifiedBy: string;
    modifiedOn: string;

    findingName: string;
    findingDescription: string;
    recommendation: string;
    severityId?: number;
    comment: string;
    managementResponse: string;
    managementResponseWorkflowState: number;

    followUpIds: number[] = [];
    linkedMois: number[] = [];

    auditToLinkAfterCreate?: string;
    auditStepToLinkAfterCreate?: string;

    rootCause: string;
    findingAuditees: number[] = [];
}
