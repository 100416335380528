<ng-template #allPresetsView>
    <div class="fancy-loader" *ngIf="!presets"></div>
    <div class="presets" *ngIf="presets">
        <input
            type="text"
            placeholder="Search / filter preset"
            class="form-control mb-2"
            [(ngModel)]="scopeFilter"
            (debounce-input-event)="filterPresets()"
            inputTooltip
        />
        <ngx-masonry [options]="{ percentPosition: true, gutter: 10 }">
            <div ngxMasonryItem class="masonry-item" *ngFor="let preset of scopedPresets">
                <div
                    class="card"
                    [class.border-primary]="currentPreset && preset.id == currentPreset.id"
                >
                    <div class="card-body">
                        <h5 class="card-title">
                            <cerrix-icon-viewer [icon]="preset.icon"></cerrix-icon-viewer>
                            {{ preset.name }}
                        </h5>
                        <p class="card-text text-muted" [matTooltip]="preset.description">
                            {{ preset.description }}
                        </p>
                        <footer class="blockquote-footer text-muted">
                            {{ preset.createdBy }} ({{ preset.updatedOn | dateTimeFormat }})
                        </footer>
                        <div class="card-actions d-inline-flex w-100 mt-2">
                            <button
                                class="button"
                                (click)="setPreset(preset)"
                                matTooltip="Open Preset"
                                [disabled]="currentPreset && preset.id == currentPreset.id"
                            >
                                <i class="fad fa-external-link fa-fw"></i>
                            </button>
                            <button
                                class="button"
                                (click)="OpenInfoDialog(preset)"
                                matTooltip="Preset Information"
                            >
                                <i class="fas fa-info"></i>
                            </button>
                            <button
                                class="button"
                                (click)="preset.api.setDefault()"
                                matTooltip="Default Preset"
                            >
                                <i
                                    class="{{
                                        preset.isDefault ? 'fas primary-color' : 'far'
                                    }} fa-house fa-fw"
                                ></i>
                            </button>
                            <button
                                class="button"
                                [disabled]="preset.isDefault"
                                (click)="preset.api.toggleFavorite()"
                                matTooltip="Favourite Preset"
                            >
                                <i
                                    class="{{
                                        preset.isFavorite ? 'fas orange' : 'far'
                                    }} fa-star fa-fw"
                                ></i>
                            </button>
                            <button
                                class="button"
                                (click)="preset.api.copyUrlToClipboard()"
                                *ngIf="usePresetHyperlink"
                                matTooltip="Copy Link"
                            >
                                <i class="far fa-copy fa-fw"></i>
                            </button>
                            <button
                                class="button delete-button"
                                (click)="preset.api.delete()"
                                *ngIf="preset.isOwner && !preset.isSystemDefault"
                                matTooltip="Delete Preset"
                            >
                                <i class="fas fa-trash fa-fw"></i>
                            </button>
                            <button
                                class="button ms-auto"
                                (click)="preset.api.setSystemDefault()"
                                matTooltip="Toggle system default"
                                [disabled]="!preset.isPublic && !preset.isPredefined"
                                *ngIf="permissions.canSetSystemDefault"
                            >
                                <i
                                    class="{{
                                        preset.isSystemDefault ? 'fas primary-color' : 'far'
                                    }} fa-house-lock fa-fw"
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ngx-masonry>
    </div>
</ng-template>
