<ng-template #incidentsMenu>
    <div style="display: flex; flex-direction: column; height: 100%">
        <div style="flex: 1">
            <mat-tree
                [dataSource]="leftMenuDataSource"
                [treeControl]="treeControl"
                style="background: transparent"
            >
                <!-- leaf node -->
                <mat-tree-node
                    *matTreeNodeDef="let node"
                    matTreeNodePadding
                    matTreeNodePaddingIndent="15px"
                    style="color: white"
                >
                    <button
                        mat-icon-button
                        disabled
                        *ngIf="!node.children && node.level === 0"
                    ></button>

                    <button
                        mat-button
                        [disabled]="
                            node.preset && !!node.preset && node.preset.id === this.activePresetId
                        "
                        [color]="
                            node.preset && !!node.preset && node.preset.id === this.activePresetId
                                ? 'gray'
                                : 'white'
                        "
                        (click)="leftMenuAction(node)"
                    >
                        <mat-icon *ngIf="node.preset" style="margin-right: 0.2rem">
                            {{ node.preset.icon }}
                        </mat-icon>
                        {{ node.name }}
                    </button>
                </mat-tree-node>

                <!-- expandable nodes -->
                <mat-tree-node
                    *matTreeNodeDef="let node; when: hasChild"
                    matTreeNodePadding
                    matTreeNodePaddingIndent="15px"
                    style="color: white"
                    isExpanded
                >
                    <button
                        mat-icon-button
                        matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name"
                    >
                        <mat-icon>
                            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                        </mat-icon>
                    </button>
                    <mat-icon *ngIf="node.icon" style="margin-right: 0.2rem">
                        {{ node.icon }}
                    </mat-icon>
                    {{ node.name }}
                </mat-tree-node>
            </mat-tree>
        </div>

        <incident-standing-data-menu
            *ngIf="hasStandingDataAccess()"
            [standingDataType]="selectedStandingData?.standingDataType"
            (standingDataTypeChange)="setStandingData($event)"
        ></incident-standing-data-menu>
    </div>
</ng-template>

<div *ngIf="!selectedStandingData" class="incident-overview-container">
    <div class="actions-top-bar" *ngIf="isReporter">
        <div class="left-panel">
            <cerrix-button color="primary" size="medium" (click)="addIncident()"
                ><mat-icon fontSet="material-symbols-outlined">add</mat-icon
                ><ng-container>Add new incident</ng-container></cerrix-button
            >
        </div>
    </div>

    <div class="filter-top-bar">
        <div class="left-panel">
            <mat-form-field class="search-filter-container">
                <mat-label>Search / filter incidents</mat-label>
                <input
                    matInput
                    type="text"
                    [(ngModel)]="searchValue"
                    (input)="onSearch(false)"
                    (keydown.enter)="onSearch(true)"
                />
                <button *ngIf="searchValue" matSuffix mat-icon-button (click)="onClearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <button
                [disabled]="!preset && activePresetId"
                (click)="onPresetConfig()"
                mat-stroked-button
            >
                <ng-container *ngIf="preset || activePresetId; else addPreset"
                    >Edit preset</ng-container
                >
                <ng-template #addPreset>Add preset</ng-template>
                <mat-icon iconPositionEnd *ngIf="preset && hasPresetChanges" color="warn"
                    >save</mat-icon
                >
            </button>
        </div>
        <div class="right-panel">
            <mat-divider class="divider" [vertical]="true"></mat-divider>
            <cerrix-icon-button
                tooltipContent="Export"
                [disabled]="!incidents?.length || loadingIncidents"
                (click)="exportIncidents()"
            >
                <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
            </cerrix-icon-button>
        </div>
    </div>

    <div class="table-container">
        <div class="loading-spinner" *ngIf="loadingIncidents || loadingHeaders">
            <mat-spinner></mat-spinner>
        </div>

        <div class="table-content">
            <table
                mat-table
                [dataSource]="incidents"
                *ngIf="headers.size"
                matSort
                [matSortActive]="sortActive"
                [matSortDirection]="sortDirection"
                (matSortChange)="onSortChange($event)"
            >
                <ng-container matColumnDef="Classification">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("Classification") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.classification"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.classification }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Organization">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("Organization") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.organization"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.organization }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ReportedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("ReportedBy") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.reportedBy"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.reportedBy }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="CompletedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("CompletedBy") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.completedBy"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.completedBy }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("IncidentStatus") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="incidentStatus[element.incidentStatus]"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.incidentStatusTranslation }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("Name") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.name"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Identifier" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("Identifier") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.identifier"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.identifier }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("Description") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.description"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.description }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="OccurredOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("OccurredOn") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.occurredOn | dateTimeDisplay"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.occurredOn | dateTimeDisplay }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="DetectedOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("DetectedOn") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.detectedOn | dateTimeDisplay"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.detectedOn | dateTimeDisplay }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ClosedOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("ClosedOn") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.closedOn | dateTimeDisplay"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.closedOn | dateTimeDisplay }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="DueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("DueDate") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.dueDate | dateTimeDisplay"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.dueDate | dateTimeDisplay }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="RootCause">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("RootCause") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.rootCause"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.rootCause }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="InternalIdentifier">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("InternalIdentifier") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.internalIdentifier"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.internalIdentifier }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Impact">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                        {{ incidentColumnsTranslation.get("Impact") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.impact"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.impact }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentTypes">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentTypes") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.incidentTypes"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.incidentTypes }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentBusinessDimensions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentBusinessDimensions") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.businessDimensions"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.businessDimensions }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentFrameworkDimensions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentFrameworkDimensions") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.frameworkDimensions"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.frameworkDimensions }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentRootCauseCategories">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentRootCauseCategories") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.rootCauseCategories"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.rootCauseCategories }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentCausedByOrganizations">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentCausedByOrganizations") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.causedByOrganizations"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.causedByOrganizations }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentAffectedOrganizations">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentAffectedOrganizations") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.affectedOrganizations"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.affectedOrganizations }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentCausedByThirdParties">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentCausedByThirdParties") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.causedByThirdParties"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.causedByThirdParties }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentAffectedThirdParties">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentAffectedThirdParties") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.affectedThirdParties"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.affectedThirdParties }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentResponsibles">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentResponsibles") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.responsibles"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.responsibles }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentAssessors">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentAssessors") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.assessors"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.assessors }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="IncidentInformed">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ incidentColumnsTranslation.get("IncidentInformed") }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="element.informed"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ element.informed }}
                    </td>
                </ng-container>

                <ng-container
                    *ngFor="let header of customFieldHeaders"
                    [matColumnDef]="header.name"
                >
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [disabled]="header.fieldType === FieldType.MultiSelect"
                        disableClear
                    >
                        {{ header.translation }}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [matTooltip]="incidentsCustomFields.get(element.id + header.name)"
                        [matTooltipShowDelay]="tooltipDelay"
                    >
                        {{ incidentsCustomFields.get(element.id + header.name) }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    (click)="openIncident(row)"
                ></tr>

                <tr *matNoDataRow>
                    <ng-container *ngIf="!loadingIncidents">
                        <td class="no-data-cell" [attr.colspan]="displayedColumns?.length">
                            No data matching the filter
                        </td>
                    </ng-container>
                </tr>
            </table>
        </div>

        <mat-paginator
            *ngIf="headers.size"
            [length]="totalIncidents"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50, 100]"
            showFirstLastButtons
            (page)="onPageEvent($event)"
        ></mat-paginator>
    </div>
</div>

<ng-container
    #standingDataContainer
    *ngIf="selectedStandingData"
    [ngComponentOutlet]="selectedStandingData.component"
    [ngComponentOutletInputs]="{
        title: selectedStandingData.title
    }"
    [ngComponentOutletInjector]="selectedStandingData.injector"
></ng-container>
