import { Component, OnInit, Input, ViewChildren } from "@angular/core";
import { CerrixTreeItem } from "../../../../common/models/tree/CerrixTreeItem";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { ProcessingPurposeModel } from "@app/datamanagement/models/ProcessingPurposeModel";
import { DatamanagementPermissionModel } from "@app/datamanagement/models/DatamanagementPermissionModel";
import { ModalOptions, BsModalService } from "ngx-bootstrap/modal";
import { DataStructuresStandingData } from "@app/datamanagement/models/DataStructuresStandingData";
import { FormGroup } from "@angular/forms";
import { MdsModel } from "@app/datamanagement/models/MdsModel";
import { EntityModel } from "@app/datamanagement/models/EntityModel";
import { EntityDialogComponent } from "./dialogs/entity-dialog/entity-dialog.component";
import { ConnectedSystemModel } from "@app/datamanagement/models/ConnectedSystemModel";
import { EntityRoles } from "@app/datamanagement/enums/EntityRoles";
import { SystemModel } from "@app/datamanagement/models/SystemModel";
import { EntityDetailComponent } from "./entities/entity-detail.component/entity-detail.component";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "processingpurpose-datastructure",
    templateUrl: "./datastructures.component.html",
    styleUrls: ["./datastructures.component.scss", "../../sass/datamanagement.scss"],
})
export class ProcessingPurposeDataStructuresComponent implements OnInit {
    @ViewChildren("entityDetail") entityDetails: EntityDetailComponent[];

    disabled: Boolean;
    datastructuresStandingData: CerrixTreeItem[];

    standingData: DataStructuresStandingData;

    @Input() processingPurpose: ProcessingPurposeModel;
    @Input() permissions: DatamanagementPermissionModel;

    mainFormGroup: FormGroup;

    dataStructuresModel: MdsModel;
    connectedSystems: ConnectedSystemModel[];

    constructor(
        private datamanagementService: DatamanagementDataService,
        private modalService: BsModalService
    ) {}

    async ngOnInit() {
        this.disabled = this.permissions.readonly;

        // Fetch on background
        this.datamanagementService
            .getDatastructureStandingData(this.processingPurpose.dataProcessorIds)
            .subscribe((standingData) => {
                // Set required property for datastructure archive policy
                standingData.generalArchivePolicy = this.processingPurpose.generalArchivePolicy;
                this.standingData = standingData;
            });
    }

    async loadDataStructures(generalArchivePolicy: string) {
        if (!this.processingPurpose || !this.processingPurpose.ID) {
            return;
        }

        // Set the general archive policy in the standing data
        if (this.standingData) {
            this.standingData.generalArchivePolicy = generalArchivePolicy;
        }

        if (this.dataStructuresModel) {
            return;
        }

        const result = new MdsModel();
        result.processingPurposeId = this.processingPurpose.ID;

        const connectedSystems = await this.datamanagementService
            .getSystems(result.processingPurposeId)
            .toPromise();

        // Process fetched systems
        connectedSystems.forEach((connectedSystem) => {
            let processor: EntityModel;
            if (result.entities.some((x) => x.id === connectedSystem.processorId)) {
                processor = result.entities.find((x) => x.id === connectedSystem.processorId);
            } else {
                processor = new EntityModel();
                result.entities.push(processor);

                processor.id = connectedSystem.processorId;
                processor.name = connectedSystem.processorName;
                processor.systemsRequired = !connectedSystem.hidden;

                // Do entity flags
                if (
                    EntityRoles.DataController ===
                    (connectedSystem.processorFlags & EntityRoles.DataController)
                ) {
                    processor.role = EntityRoles.DataController;
                } else {
                    processor.role = EntityRoles.DataProcessor;
                }
                processor.subProcessor =
                    EntityRoles.SubProcessor ===
                    (connectedSystem.processorFlags & EntityRoles.SubProcessor);
            }

            const systemModel = new SystemModel();
            systemModel.id = connectedSystem.systemId;
            systemModel.connectedId = connectedSystem.id;
            systemModel.name = connectedSystem.name;
            systemModel.hidden = connectedSystem.hidden;

            processor.systems.push(systemModel);
        });

        this.dataStructuresModel = result;
    }

    getDataStructureModel(): MdsModel {
        if (!this.dataStructuresModel) {
            return null;
        }

        // Map each entity to their model
        this.entityDetails.forEach((entityDetail) => {
            entityDetail.mapToModel();
        });

        // Return a copy, to prevent pre-save processing to alter the current state
        const dataStructuresClone = JSON.parse(
            JSON.stringify(this.dataStructuresModel)
        ) as MdsModel;

        return dataStructuresClone;
    }

    addEntity() {
        // Only show entities which have not been chosen
        const currentEntities = this.dataStructuresModel.entities.map((x) => x.id);

        this.standingData.thirdparties.forEach((x) => (x.ID = Number.parseInt(x.Identifier, 10)));

        const availableEntities = this.standingData.thirdparties.filter(
            (x) => currentEntities.indexOf(x.ID) === -1
        );

        const data: EntityModel[] = [];
        availableEntities.forEach((x) => {
            const em = new EntityModel();
            em.id = x.ID;
            em.name = x.Name;
            data.push(em);
        });

        const config = <ModalOptions<EntityDialogComponent>>{
            backdrop: "static",
            class: "datamanagement-dialog modal-md modal-lg",

            initialState: <EntityDialogComponent>{
                entities: data,
            },
        };

        this.modalService
            .show(EntityDialogComponent, config)
            .content.afterClosed.subscribe((dialogResult: EntityModel[] | boolean) => {
                if (dialogResult) {
                    const selectedEntities = dialogResult as EntityModel[];
                    selectedEntities.forEach((x) => {
                        const addedEntity = <EntityModel>{
                            processingPurposeId: this.processingPurpose.ID,
                            id: x.id,
                            name: x.name,
                            systems: [],

                            isNew: true,
                        };

                        this.dataStructuresModel.entities.push(addedEntity);
                    });
                }
            });
    }

    deleteEntity(entityModel: EntityModel) {
        const index = this.dataStructuresModel.entities.indexOf(entityModel);
        if (index >= 0) {
            this.dataStructuresModel.entities.splice(index, 1);
        }
    }
}
