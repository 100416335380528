import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewChildren,
    EventEmitter,
    Output,
    OnDestroy,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { CerrixWizardComponent } from "@app/shared/cerrix-wizard/cerrix-wizard.component";
import { GenericListManagerComponent } from "@app/shared/generic-managers/generic-list-manager/generic-list-manager.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import {
    CerrixWizardConfig,
    CerrixWizardStepConfig,
} from "@app/shared/models/WizardModels/CerrixWizardConfig";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { Subject, of } from "rxjs";
import { OverviewDataService } from "../shared/adv-eff-overview-data.service";
import { EffectivenessWizardModel } from "../../shared/models/effectiveness-wizard-model";
import { EffectivenessWizardTestStepModel } from "../../shared/models/effectiveness-wizard-test-step-model";
import { EffectivenessWizardStandingData } from "./effectiveness-wizard-standingdata.model";
import { LocalizationService } from "@app/shared/localization";
import { isGuid } from "@methods/uniqueMethods";
import { SampleGenerationType } from "../../shared/enums/sample-generation-type.enum";
import { EffectivenessOriginType } from "./effectiveness-origin-type.enum";
import { ToastrService } from "ngx-toastr";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { map, takeUntil } from "rxjs/operators";
import { EffectivenessStatusType } from "../../shared/enums/effectiveness-status-type.enum";
import { nameof } from "@methods/jeffs-toolkit";
import { toDate } from "../../shared/adv-eff-shared-methods";
import { getFormControl, getFormValue, toPromise } from "@methods/CommonMethods";

const stopEmit = { emitEvent: false };

@Component({
    selector: "adv-eff-wizard",
    templateUrl: "./adv-eff-wizard.component.html",
    styleUrls: ["./adv-eff-wizard.component.scss"],
})
export class WizardComponent implements OnInit, OnDestroy {
    @ViewChild("wizard") wizard: CerrixWizardComponent;
    @ViewChildren("testStepManager") testStepManagers: GenericListManagerComponent[];

    @Input() controlGuid: string;
    @Input() effectivenessGuid: string;
    @Output() reload = new EventEmitter();

    tab: TabModel;
    id: any;

    reviewerRequiredApplicationSetting = true;
    showReviewers = true;
    showNewScreens = false;

    initialLoadCompleted = false;

    copyEffectivenessBackupGuid = null;

    editAllowed = false;

    model: EffectivenessWizardModel;
    standingData: EffectivenessWizardStandingData;

    nameDateGroup: FormGroup;
    sampleGenerationGroup: FormGroup;
    personsInvolvedGroup: FormGroup;
    basicTestInfoGroup: FormGroup;
    testStepsTouched = false;

    originTypes = EffectivenessOriginType;
    effectivenessOrigin = EffectivenessOriginType.new;

    originExistingID: string;
    existingTestPlans: Promise<IdNameCombination[]>;
    originCatalogueID: string;
    catalogues: Promise<IdNameCombination[]>;
    originLoading = false;

    testStepConfig: GenericListConfig;
    wizardConfig: CerrixWizardConfig;

    isSummary = false;
    showSourceDocumentUploader = true;

    namePageIndex = 0;
    sampleGenerationPageIndex = 1;
    personsInvolvedPageIndex = 2;
    basicTestInfoPageIndex = 3;
    testStepPagexIndex = 4;

    sourceDocumentUploadersProp: keyof EffectivenessWizardModel;
    sourceDocumentDueDateProp: keyof EffectivenessWizardModel;

    evidenceUploadersProp: keyof EffectivenessWizardModel;
    evidenceDueDateProp: keyof EffectivenessWizardModel;

    testersProp: keyof EffectivenessWizardModel;
    testerDueDateProp: keyof EffectivenessWizardModel;

    reviewEnabledProp: keyof EffectivenessWizardModel;
    reviewersProp: keyof EffectivenessWizardModel;
    reviewerDueDateProp: keyof EffectivenessWizardModel;

    startDateProp: keyof EffectivenessWizardModel;
    endDateProp: keyof EffectivenessWizardModel;
    testPeriodProp: keyof EffectivenessWizardModel;

    sampleGenerationMethodProp: keyof EffectivenessWizardModel;
    reasonManualSampleGenerationProp: keyof EffectivenessWizardModel;

    linkedStandardProp: keyof EffectivenessWizardModel;
    qualityAspectsProp: keyof EffectivenessWizardModel;

    // Define destroy subject
    readonly destroy$ = new Subject<boolean>();

    constructor(
        private _overviewDS: OverviewDataService,
        private _sdDS: StandingdataDataService,
        private _appSettingDS: SettingsDataService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService,
        private _locService: LocalizationService
    ) {
        this.initializeModelProps();
    }

    async ngOnInit() {
        this.reviewerRequiredApplicationSetting = (
            await toPromise(
                this._appSettingDS.getSetting(
                    ApplicationSettings.EffectivenessTestingReviewerRequired
                )
            )
        ).BoolValue;

        this.showNewScreens = (
            await toPromise(
                this._appSettingDS.getSetting(
                    ApplicationSettings.FeatureToggleNewEffectivenessTestingScreens
                )
            )
        ).BoolValue;

        this.showReviewers = true;

        this.model = new EffectivenessWizardModel();

        this.standingData = new EffectivenessWizardStandingData();
        this.initFormGroups();
        this.initConfigs();

        await this.load(false);
    }

    private initFormGroups() {
        this.nameDateGroup = new FormGroup(
            {
                name: new FormControl(this.model.name, [Validators.required]),
                testPeriod: new FormControl(this.model.testPeriod),
                startDate: new FormControl(this.model.startDate),
                endDate: new FormControl(this.model.endDate),
            },
            {
                validators: [(group: FormGroup) => this.getDateValidator(group)],
            }
        );

        this.sampleGenerationGroup = new FormGroup({
            sampleGenerationMethod: new FormControl(
                {
                    value: this.model.sampleGenerationMethod,
                    disabled: !this.model.canChangeSampleMethod,
                },
                [Validators.required]
            ),
            reasonManualSampleGeneration: new FormControl(this.model.reasonManualSampleGeneration, [
                Validators.required,
            ]),
        });

        this.personsInvolvedGroup = new FormGroup(
            {
                sourceDocumentUploaders: new FormControl(this.model.sourceDocumentUploaders, [
                    Validators.required,
                ]),
                evidenceUploaders: new FormControl(this.model.evidenceUploaders, [
                    Validators.required,
                ]),
                testers: new FormControl(this.model.testers, [Validators.required]),
                reviewers: new FormControl(this.model.reviewers),
                sourceDocumentDueDate: new FormControl(this.model.sourceDocumentDueDate),
                evidenceDueDate: new FormControl(this.model.evidenceDueDate),
                testerDueDate: new FormControl(this.model.testerDueDate),
                reviewerDueDate: new FormControl(this.model.reviewerDueDate),

                reviewEnabled: new FormControl(this.model.reviewEnabled),
            },
            {
                validators: [(group: FormGroup) => this.getPersonValidator(group)],
            }
        );

        if (this.showReviewers) {
            const reviewerControl = getFormControl<EffectivenessWizardModel>(
                this.personsInvolvedGroup,
                this.reviewersProp
            );
            reviewerControl.setValidators(Validators.required);
            reviewerControl.updateValueAndValidity();

            getFormControl<EffectivenessWizardModel>(
                this.personsInvolvedGroup,
                this.reviewEnabledProp
            )
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((reviewEnabledValue: boolean) => {
                    if (reviewEnabledValue == this.showReviewers) {
                        return;
                    }

                    this.showReviewers = reviewEnabledValue;

                    if (!reviewEnabledValue) {
                        this.setSourceDocumentUploaderUsers();
                    }

                    this.setDisabledControls();
                });
        }

        this.basicTestInfoGroup = new FormGroup({
            linkedStandard: new FormControl(this.model.linkedStandard, [Validators.required]),
            qualityAspects: new FormControl(this.model.qualityAspects, [Validators.required]),
            testNorm: new FormControl(this.model.testNorm),
            testInstructions: new FormControl(this.model.testInstructions),
        });

        if (this.showNewScreens) {
            const evidenceUploadInstructions = new FormControl(
                this.model.evidenceUploadInstructions
            );
            this.basicTestInfoGroup.addControl(
                "evidenceUploadInstructions",
                evidenceUploadInstructions
            );

            const sourceDocumentUploadInstructions = new FormControl(
                this.model.sourceDocumentUploadInstructions
            );
            this.basicTestInfoGroup.addControl(
                "sourceDocumentUploadInstructions",
                sourceDocumentUploadInstructions
            );
        }

        this.nameDateGroup.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.setPageValidity(this.namePageIndex, this.nameDateGroup));
        this.sampleGenerationGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((x) => {
            this.setPageValidity(this.sampleGenerationPageIndex, this.sampleGenerationGroup);
            this.setDisabledControls(); // Sample generation method has custom enabled/disabled controls so run method.
        });

        this.personsInvolvedGroup.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() =>
                this.setPageValidity(this.personsInvolvedPageIndex, this.personsInvolvedGroup)
            );
        this.basicTestInfoGroup.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() =>
                this.setPageValidity(this.basicTestInfoPageIndex, this.basicTestInfoGroup)
            );
    }

    private getDateValidator(group: FormGroup) {
        const startDateControl = getFormControl<EffectivenessWizardModel>(
            group,
            this.startDateProp
        );
        const startDate = toDate(startDateControl.value);
        const startErrors = {};
        let loadStartError = false;
        if (!startDate || isNaN(startDate) || startDate <= 0) {
            startErrors["required"] = true;
            loadStartError = true;
        }

        const endDateControl = getFormControl<EffectivenessWizardModel>(group, this.endDateProp);
        const endDate = toDate(endDateControl.value);
        const endErrors = {};
        let loadEndError = false;
        if (!endDate || isNaN(endDate) || endDate <= 0) {
            endErrors["required"] = true;
            loadEndError = true;
        }
        if (endDate > 0 && startDate > endDate) {
            endErrors["startDateAfterEndDate"] = `Start date cannot be after end date`;
            loadEndError = true;
        }

        const testPeriodControl = getFormControl<EffectivenessWizardModel>(
            group,
            this.testPeriodProp
        );
        const testPeriodValue = testPeriodControl.value;
        const testRow =
            this.standingData && this.standingData.TestPeriodData && testPeriodValue
                ? this.standingData.TestPeriodData.find((x) => x.ID === testPeriodValue)
                : null;
        if (testRow) {
            const periodStart = toDate(testRow.startDate);
            const periodEnd = toDate(testRow.endDate);
            const formattedStart = this._locService.formatDate(testRow.startDate);
            const formattedEnd = this._locService.formatDate(testRow.endDate);
            if (startDate > 0 && (startDate < periodStart || startDate > periodEnd)) {
                startErrors[
                    "periodDates"
                ] = `Start date does not fall within test period (${formattedStart} - ${formattedEnd})`;
                loadStartError = true;
            }

            if (endDate > 0 && (endDate < periodStart || endDate > periodEnd)) {
                endErrors[
                    "periodDates"
                ] = `End date does not fall within test period (${formattedStart} - ${formattedEnd})`;
                loadEndError = true;
            }
        }

        startDateControl.setErrors(loadStartError ? startErrors : null);
        endDateControl.setErrors(loadEndError ? endErrors : null);

        return {};
    }

    private getPersonValidator(personsGroup: FormGroup) {
        const endDateControl = getFormControl<EffectivenessWizardModel>(
            this.nameDateGroup,
            this.endDateProp
        );
        const endDate = toDate(endDateControl.value);

        const sourceDueDateControl = getFormControl<EffectivenessWizardModel>(
            personsGroup,
            this.sourceDocumentDueDateProp
        );

        const formattedEnd = this._locService.formatDate(endDateControl.value);
        const sourceDueDate = this.validateSourceDate(
            sourceDueDateControl,
            endDate,
            formattedEnd,
            "Source document"
        );

        const evidenceDueDateControl = getFormControl<EffectivenessWizardModel>(
            personsGroup,
            this.evidenceDueDateProp
        );

        const evidenceDueDate = this.validateEvidenceDate(
            evidenceDueDateControl,
            endDate,
            sourceDueDate,
            formattedEnd,
            "Evidence uploader"
        );

        const testerDueDateControl = getFormControl<EffectivenessWizardModel>(
            personsGroup,
            this.testerDueDateProp
        );
        const testerDueDate = this.validateDate(
            testerDueDateControl,
            evidenceDueDate,
            "Tester",
            "evidence uploader"
        );

        if (this.showReviewers) {
            const reviewerDueDateControl = getFormControl<EffectivenessWizardModel>(
                personsGroup,
                this.reviewerDueDateProp
            );
            this.validateDate(reviewerDueDateControl, testerDueDate, "Reviewer", "tester");
        }

        return {};
    }

    private validateSourceDate(
        control: AbstractControl,
        compareDate: number,
        dateString: any,
        fieldName: string
    ): number {
        const dueDate = toDate(control.value);
        const errors = {};
        let loadError = false;
        if (!dueDate || isNaN(dueDate) || dueDate <= 0) {
            errors["required"] = true;
            loadError = true;
        }

        if (dueDate > 0 && compareDate > dueDate) {
            errors["DueDate"] = `${fieldName} due date cannot be before end date (${dateString}) `;
            loadError = true;
        }

        control.setErrors(loadError ? errors : null);

        return dueDate;
    }

    private validateEvidenceDate(
        control: AbstractControl,
        testplanEndDate: number,
        compareSourceDueDate: number,
        dateString: any,
        fieldName: string
    ): number {
        const evidenceDueDate = toDate(control.value);
        const errors = {};
        let loadError = false;
        if (!evidenceDueDate || isNaN(evidenceDueDate) || evidenceDueDate <= 0) {
            errors["required"] = true;
            loadError = true;
        } else {
            if (evidenceDueDate > 0 && testplanEndDate > evidenceDueDate) {
                errors[
                    "DueDate"
                ] = `${fieldName} due date cannot be before end date (${dateString}) `;
                loadError = true;
            }

            if (compareSourceDueDate > 0 && compareSourceDueDate > evidenceDueDate) {
                errors[
                    "DueDate"
                ] = `${fieldName} due date cannot be before source document due date`;
                loadError = true;
            }
        }

        control.setErrors(loadError ? errors : null);

        return evidenceDueDate;
    }

    private validateDate(
        control: AbstractControl,
        compareDate: number,
        prefix1: string,
        prefix2: string
    ): number {
        const dueDate = toDate(control.value);
        const errors = {};
        let loadError = false;
        if (!dueDate || isNaN(dueDate) || dueDate <= 0) {
            errors["required"] = true;
            loadError = true;
        }
        if (dueDate > 0 && compareDate > dueDate) {
            errors["DueDate"] = prefix1 + " due date cannot be before " + prefix2 + " due date";
            loadError = true;
        }

        control.setErrors(loadError ? errors : null);

        return dueDate;
    }

    private initConfigs() {
        this.testStepConfig = <GenericListConfig>{
            name: "Test steps",
            hideHeader: true,
            isSortable: true,

            allowAdd: true,
            allowEdit: true,
            allowDelete: true,

            uiid: "wizard-test-step-list",

            fields: [
                {
                    fieldName: nameof<EffectivenessWizardTestStepModel>((x) => x.reference),
                    prettyName: "Reference",
                    fieldType: GenericListFieldType.Text,
                    required: true,
                    overviewSortOrder: 0,
                    editorWidth: 6,
                    uiid: "wizard-test-step-reference-textfield",
                },
                {
                    fieldName: nameof<EffectivenessWizardTestStepModel>((x) => x.testMethod),
                    prettyName: "Test method",
                    fieldType: GenericListFieldType.SingleSelect,
                    required: true,
                    editorWidth: "6",
                    overviewSortOrder: 2,
                    uiid: "wizard-test-step-test-method-select",
                    getDataMethod: () =>
                        this._sdDS.getMaintenanceListByType(StandingDataType.ControlTestMethod),
                },
                {
                    fieldName: nameof<EffectivenessWizardTestStepModel>((x) => x.description),
                    prettyName: "Description",
                    fieldType: GenericListFieldType.TextArea,
                    required: true,
                    overviewSortOrder: 1,
                    editorWidth: "12",
                    uiid: "wizard-test-step-description-textarea",
                },
                {
                    fieldName: nameof<EffectivenessWizardTestStepModel>((x) => x.requiredDocuments),
                    prettyName: "Required Documents",
                    description: "Add required documents by typing in the box.",
                    fieldType: GenericListFieldType.StringArray,
                    overviewSortOrder: 3,
                    editorWidth: "12",
                    uiid: "wizard-test-step-required-documents-select",
                    getDataMethodByRow: (row: EffectivenessWizardTestStepModel) => {
                        if (!row) {
                            row = new EffectivenessWizardTestStepModel();
                        }
                        if (!row.requiredDocuments) {
                            row.requiredDocuments = [];
                        }

                        return of(row.requiredDocuments);
                    },
                },
            ],

            data: this.model.testSteps,
            dataChanged: (data) => {
                this.model.testSteps = data;
                this.testStepConfig.data = data;
            },
        };

        this.wizardConfig = <CerrixWizardConfig>{
            showInModal: true,
            enableSummary: true,
            enableValidation: true,
            activePageIndex: 0,
            steps: [
                "Basic information",
                "Sample generation",
                "Persons involved | Due dates",
                "Test information",
                "Test steps",
            ].map(
                (s) =>
                    <CerrixWizardStepConfig>{
                        name: s,
                        valid: null,
                    }
            ),

            useDedicatedClose: true,
            allowPageChange: true,
            buttons: [],

            showModalOnComplete: true,
            completeModalTitle: "Test Plan has been created",
            completeModalSubtitle:
                "Click close to close this dialog and return to the overview. Or click on 'Create copy' to copy current values into a new Test Plan wizard.",
            completeModalButtons: [
                {
                    text: "Create copy",
                    icon: "fas fa-copy",
                    click: async () => {
                        this.wizard.closeCompleteModal();
                        this.isSummary = false;
                        this.effectivenessGuid = null;
                        await this.load(true);

                        this.effectivenessOrigin = EffectivenessOriginType.existing;
                        this.originExistingID = this.copyEffectivenessBackupGuid;
                        this.originChanged();
                        await this.loadExistingEffectiveness();
                    },
                },
            ],

            reload: async () => {
                return true;
            },
            complete: async () => {
                if (this.model.storeAsCatalogue && !this.model.storeAsCatalogueName) {
                    this._toastr.warning("Catalogue name is required!");
                    return false;
                }

                FormValidationHelper.mapToModel(this.nameDateGroup, this.model);
                FormValidationHelper.mapToModel(this.sampleGenerationGroup, this.model);
                FormValidationHelper.mapToModel(this.personsInvolvedGroup, this.model);
                FormValidationHelper.mapToModel(this.basicTestInfoGroup, this.model);

                if (this.effectivenessGuid) {
                    this.wizardConfig.completeModalTitle = "Test Plan has been updated";
                } else {
                    this.wizardConfig.completeModalTitle = "Test Plan has been created";
                }

                const postResult = await toPromise(
                    this._overviewDS.storeEffectiveness(
                        this.controlGuid,
                        this.effectivenessGuid,
                        this.model
                    )
                );

                if (postResult && isGuid(postResult)) {
                    this.reload.emit();

                    this.copyEffectivenessBackupGuid = postResult;
                    this.resetPage();

                    return true;
                } else {
                    this._toastr.error(postResult, "Validation error");
                }

                return false;
            },

            changeToPage: (fromPage, toPage) => {
                const maxIndex = this.wizardConfig.steps.length;

                if (fromPage === this.namePageIndex) {
                    FormValidationHelper.markAllAsTouched(this.nameDateGroup);
                    this.setPageValidity(fromPage, this.nameDateGroup);
                } else if (fromPage === this.sampleGenerationPageIndex) {
                    FormValidationHelper.markAllAsTouched(this.sampleGenerationGroup);
                    this.setPageValidity(fromPage, this.sampleGenerationGroup);
                } else if (fromPage === this.personsInvolvedPageIndex) {
                    FormValidationHelper.markAllAsTouched(this.personsInvolvedGroup);
                    this.setPageValidity(fromPage, this.personsInvolvedGroup);
                } else if (fromPage === this.basicTestInfoPageIndex) {
                    FormValidationHelper.markAllAsTouched(this.basicTestInfoGroup);
                    this.setPageValidity(fromPage, this.basicTestInfoGroup);
                } else if (fromPage === this.testStepPagexIndex) {
                    this.testStepsTouched = true;
                    this.setPageValidity(fromPage, null, true);
                }

                // If user is going to summary.
                this.isSummary = toPage === maxIndex;
                if (this.isSummary) {
                    this.setWizardCompletable();
                }

                this.setDisabledControls();
                return true;
            },
        };
    }

    setPageValidity(pageIndex: number, formGroup?: FormGroup, overrideValidity?: boolean) {
        let valid = overrideValidity;
        if (!overrideValidity && formGroup) {
            if (!FormValidationHelper.allFormControlsTouched(formGroup)) {
                valid = null;
            } else {
                valid = formGroup.valid;
            }
        }

        // this will check if the form / wizard can be completed, after changing a value.
        if (this.isSummary) {
            this.setWizardCompletableValue();
        }

        this.wizardConfig.steps[pageIndex].valid = valid;
    }

    setWizardCompletable() {
        if (!this.editAllowed) {
            this.wizardConfig.completable = false;
            return;
        }

        // Make sure all fields are touched so valid check will work correctly.
        FormValidationHelper.markAllAsTouched(this.nameDateGroup);
        FormValidationHelper.markAllAsTouched(this.sampleGenerationGroup);
        FormValidationHelper.markAllAsTouched(this.personsInvolvedGroup);
        FormValidationHelper.markAllAsTouched(this.basicTestInfoGroup);
        this.testStepsTouched = true;

        this.setPageValidity(this.namePageIndex, this.nameDateGroup);
        this.setPageValidity(this.sampleGenerationPageIndex, this.sampleGenerationGroup);
        this.setPageValidity(this.personsInvolvedPageIndex, this.personsInvolvedGroup);
        this.setPageValidity(this.basicTestInfoPageIndex, this.basicTestInfoGroup);
        this.setPageValidity(this.testStepPagexIndex, null, true);

        this.setWizardCompletableValue();
    }

    setWizardCompletableValue() {
        this.wizardConfig.completable =
            (this.nameDateGroup.valid || this.nameDateGroup.disabled) &&
            (this.sampleGenerationGroup.valid || this.sampleGenerationGroup.disabled) &&
            this.personsInvolvedGroup.valid &&
            (this.basicTestInfoGroup.valid || this.basicTestInfoGroup.disabled);
    }

    setDisabledControls() {
        this.disableAllControls(stopEmit);
        this.applyPersonEditVisibilityRules();

        if (!this.editAllowed || (this.isSummary && !this.effectivenessGuid)) {
            return;
        }
        // PersonsInvolve
        this.enablePersonsEdit();

        if (
            !this.model.status ||
            this.model.status === EffectivenessStatusType.waitingForSourceDocuments ||
            this.model.status === EffectivenessStatusType.waitingForSamples
        ) {
            // NameDate
            this.nameDateGroup.enable(stopEmit);

            // BasicTestInfo
            this.basicTestInfoGroup.enable(stopEmit);
            this.testStepConfig.allowAdd =
                this.testStepConfig.allowEdit =
                this.testStepConfig.allowDelete =
                this.testStepConfig.isSortable =
                    true;

            // SampleGeneration
            const sampleControl = this.getSampleControl();
            const reasonControl = getFormControl<EffectivenessWizardModel>(
                this.sampleGenerationGroup,
                this.reasonManualSampleGenerationProp
            );

            if (this.model.canChangeSampleMethod) {
                sampleControl.enable(stopEmit);
            }

            if (sampleControl.value === SampleGenerationType.manual) {
                reasonControl.enable(stopEmit);
            } else {
                reasonControl.setValue("", stopEmit);
                reasonControl.disable(stopEmit);
            }
        }
    }
    private applyPersonEditVisibilityRules() {
        if (this.getSampleGenerationMethod() === SampleGenerationType.oneSample) {
            this.showSourceDocumentUploader = false;
        } else {
            this.showSourceDocumentUploader = true;
        }
    }

    private getSampleControl() {
        return getFormControl<EffectivenessWizardModel>(
            this.sampleGenerationGroup,
            this.sampleGenerationMethodProp
        );
    }

    private getSampleGenerationMethod() {
        return <SampleGenerationType>(
            getFormValue<EffectivenessWizardModel>(
                this.sampleGenerationGroup,
                this.sampleGenerationMethodProp
            )
        );
    }

    private disableAllControls(stopEmit) {
        this.nameDateGroup.disable(stopEmit);
        this.sampleGenerationGroup.disable(stopEmit);
        this.personsInvolvedGroup.disable(stopEmit);
        this.basicTestInfoGroup.disable(stopEmit);
        this.testStepConfig.allowAdd = false;
        this.testStepConfig.allowEdit = false;
        this.testStepConfig.allowDelete = false;
        this.testStepConfig.isSortable = false;
    }

    private enablePersonsEdit() {
        if (!this.model.status) {
            this.enableSourceUploaderControls();
            this.enableEvidenceControls();
            this.enableTestersControls();
            this.enableReviewControls();
        } else {
            switch (this.model.status) {
                case EffectivenessStatusType.waitingForSourceDocuments:
                case EffectivenessStatusType.waitingForSamples:
                    this.enableSourceUploaderControls();
                    this.enableEvidenceControls();
                    this.enableTestersControls();
                    this.enableReviewControls();
                    break;
                case EffectivenessStatusType.requestEvidence:
                    this.enableEvidenceControls([this.evidenceDueDateProp]);
                    this.enableTestersControls();
                    this.enableReviewControls();
                    break;
                case EffectivenessStatusType.nonOccurrence:
                case EffectivenessStatusType.readyForAssessment:
                case EffectivenessStatusType.assessmentInProgress:
                case EffectivenessStatusType.waitingForConclusion:
                    this.enableTestersControls([this.testerDueDateProp]);
                    this.enableReviewControls();
                    break;
                case EffectivenessStatusType.reviewNonOccurrence:
                case EffectivenessStatusType.waitingForReviewer:
                    this.enableReviewControls([this.reviewEnabledProp, this.reviewerDueDateProp]);
                    break;
                case EffectivenessStatusType.completed:
                case EffectivenessStatusType.nonOccurrenceCompleted:
                    throw "Completed test plan should not be editable";
                default:
                    throw "Workflow status not implemented";
            }
        }
    }

    setWizardToSummaryOnly() {
        this.isSummary = true;

        this.wizardConfig.allowPageChange = false;
        this.wizardConfig.activePageIndex = this.wizardConfig.steps.length;
        this.wizardConfig.steps.forEach((s) => (s.hide = true));

        // Because the wizard will not trigger page clicks we will touch all formgroups to fix validations.
        FormValidationHelper.markAllAsTouched(this.nameDateGroup);
        FormValidationHelper.markAllAsTouched(this.sampleGenerationGroup);
        FormValidationHelper.markAllAsTouched(this.personsInvolvedGroup);
        FormValidationHelper.markAllAsTouched(this.basicTestInfoGroup);
        this.testStepsTouched = true;

        this.setWizardCompletable();
    }

    resetPage() {
        this.untouchPage();

        // This part will reset all variables that have a role in the wizard.
        this.editAllowed = false;
        this.model = null;
        this.standingData = null;

        this.effectivenessOrigin = EffectivenessOriginType.new;
        this.originExistingID = null;
        this.existingTestPlans = null;
        this.originCatalogueID = null;
        this.catalogues = null;

        this.originLoading = false;
        this.isSummary = false;

        this.showReviewers = true;
        this.showSourceDocumentUploader = true;
    }

    // This will 'untouch' the wizard, so it goes back to page 1 and all fields are untouched.
    untouchPage(): void {
        FormValidationHelper.markAllAsUntouched(this.nameDateGroup);
        FormValidationHelper.markAllAsUntouched(this.sampleGenerationGroup);
        FormValidationHelper.markAllAsUntouched(this.personsInvolvedGroup);
        FormValidationHelper.markAllAsUntouched(this.basicTestInfoGroup);
        this.testStepsTouched = false;
        this.testStepConfig.reload();

        this.wizardConfig.completable = false;
        this.wizardConfig.allowPageChange = true;
        this.wizardConfig.activePageIndex = 0;
        this.wizardConfig.steps.forEach((s) => {
            s.valid = null;
            s.hide = false;
        });
    }

    //#endregion

    //#region Load data methods.

    async load(openAfterLoad: boolean): Promise<void> {
        let loadingPrompt;
        if (openAfterLoad) {
            loadingPrompt = this._promptService.loader("Loading wizard, please wait...");
        }

        try {
            if (this.initialLoadCompleted) {
                this.resetPage();
            }

            // Set effectivenessGuid to null if it has a value like undefined. (datacalls will not like undefined value)
            if (!this.effectivenessGuid) {
                this.effectivenessGuid = null;
            }

            this.editAllowed = await toPromise(
                this._overviewDS.canEditEffectiveness(this.controlGuid, this.effectivenessGuid)
            );

            // Load model that contains the info that needs to be shown. Based on effectivenessGuid get existing or create a new one.
            if (this.effectivenessGuid) {
                this.model = await toPromise(
                    this._overviewDS.getEffectiveness(this.effectivenessGuid)
                );
            } else {
                this.model = new EffectivenessWizardModel();
            }

            await this.applyModel(true);

            this.setDisabledControls();

            // If the testplan is existing we will only show the last page (summary)
            if (!!this.effectivenessGuid) {
                this.setWizardToSummaryOnly();
            }

            this.initialLoadCompleted = true;
            if (openAfterLoad) {
                loadingPrompt.close();
                loadingPrompt = null;

                this.wizard.openWizard();
            }
        } finally {
            if (loadingPrompt) {
                loadingPrompt.close();
            }
        }
    }

    async applyModel(reset: boolean): Promise<void> {
        if (this.reviewerRequiredApplicationSetting) {
            this.model.reviewEnabled = true;
        }
        FormValidationHelper.updateFormGroup(this.model, this.nameDateGroup);
        FormValidationHelper.updateFormGroup(this.model, this.sampleGenerationGroup);
        FormValidationHelper.updateFormGroup(this.model, this.personsInvolvedGroup);
        FormValidationHelper.updateFormGroup(this.model, this.basicTestInfoGroup);
        this.testStepConfig.data = this.model.testSteps;

        this.showReviewers = this.isReviewEnabled();
        await this.loadStandingData();

        if (reset) {
            this.untouchPage();
        } else {
            this.testStepConfig.reload();
        }
    }

    async loadStandingData(): Promise<void> {
        this.standingData = new EffectivenessWizardStandingData();

        this.standingData.ProductiveUserIds = this.effectivenessGuid
            ? await toPromise(
                  this._overviewDS.getProductiveUsers(this.controlGuid, this.effectivenessGuid)
              )
            : [];
        const disableUsersFn = (users: IdNameCombination[], selectedUsers: number[]) => {
            users.forEach(
                (u) =>
                    (u.disabled =
                        selectedUsers.includes(u.ID) &&
                        this.standingData.ProductiveUserIds.includes(u.ID))
            );

            return users;
        };

        this.standingData.TestPeriodData = await toPromise(
            this._overviewDS.getTestPeriodData(this.controlGuid)
        );
        this.standingData.SampleGenMethData = await toPromise(
            this._overviewDS.getSampleGenMethData()
        );
        this.standingData.EvidenceUploaderData = await toPromise(
            this._overviewDS
                .getEvidenceUploaderData(this.effectivenessGuid)
                .pipe(map((users) => disableUsersFn(users, this.model.evidenceUploaders)))
        );
        this.standingData.TesterData = await toPromise(
            this._overviewDS
                .getTesterData(this.effectivenessGuid)
                .pipe(map((users) => disableUsersFn(users, this.model.testers)))
        );
        this.standingData.ReviewerData = await toPromise(
            this._overviewDS
                .getReviewerData(this.effectivenessGuid)
                .pipe(map((users) => disableUsersFn(users, this.model.reviewers)))
        );
        this.standingData.LinkedStandardData = await toPromise(
            this._overviewDS.getLinkedStandardData(this.effectivenessGuid)
        );
        this.standingData.QualityAspectData = await toPromise(
            this._overviewDS.getQualityAspectData(this.effectivenessGuid)
        );

        this.updateControlData(
            this.nameDateGroup,
            this.testPeriodProp,
            this.standingData.TestPeriodData
        );
        this.updateControlData(
            this.sampleGenerationGroup,
            this.sampleGenerationMethodProp,
            this.standingData.SampleGenMethData
        );

        this.updateControlDataArray(
            this.personsInvolvedGroup,
            this.evidenceUploadersProp,
            this.standingData.EvidenceUploaderData
        );
        this.updateControlDataArray(
            this.personsInvolvedGroup,
            this.testersProp,
            this.standingData.TesterData
        );
        this.updateControlDataArray(
            this.personsInvolvedGroup,
            this.reviewersProp,
            this.standingData.ReviewerData
        );
        this.setSourceDocumentUploaderUsers();

        this.updateControlData(
            this.basicTestInfoGroup,
            this.linkedStandardProp,
            this.standingData.LinkedStandardData
        );
        this.updateControlDataArray(
            this.basicTestInfoGroup,
            this.qualityAspectsProp,
            this.standingData.QualityAspectData
        );
    }

    updateControlData<T>(
        group: FormGroup,
        nameProp: keyof EffectivenessWizardModel,
        standingData: IdNameCombination[]
    ): void {
        const control = getFormControl(group, nameProp);

        const selectedID = control.value as number;
        if (selectedID) {
            const isAvailable = standingData.some((su) => su.ID === selectedID);
            if (!isAvailable) {
                control.setValue(null);
            }
        }
    }

    updateControlDataArray<T>(
        group: FormGroup,
        nameProp: keyof EffectivenessWizardModel,
        standingData: IdNameCombination[]
    ): void {
        const control = getFormControl(group, nameProp);

        const selectedIDs = control.value as number[];
        if (selectedIDs) {
            const updatedIDs = selectedIDs.filter((id) => standingData.some((su) => su.ID === id));
            control.setValue(updatedIDs);
        }
    }

    setSourceDocumentUploaderUsers(): void {
        if (!this.standingData) {
            return;
        }

        const uploaders = this.getIds(
            this.evidenceUploadersProp,
            this.standingData.EvidenceUploaderData
        );
        const testers = this.getIds(this.testersProp, this.standingData.TesterData);

        let allUsers = uploaders.concat(testers);
        if (this.showReviewers) {
            const reviewers = this.getIds(this.reviewersProp, this.standingData.ReviewerData);
            allUsers.addRange(reviewers);
        }

        // Make one distinct list of users.
        allUsers = allUsers.filter((user, i) => allUsers.findIndex((u) => u.ID === user.ID) === i);

        // Clone and set disabled
        allUsers = allUsers.map((u) => {
            const inc = new IdNameCombination(u.ID, u.Name);
            inc.disabled =
                this.model.sourceDocumentUploaders.includes(u.ID) &&
                this.standingData.ProductiveUserIds.includes(u.ID);

            return inc;
        });

        this.standingData.SourceDocumentUploaderData = allUsers;
        this.updateControlDataArray<EffectivenessWizardModel>(
            this.personsInvolvedGroup,
            this.sourceDocumentUploadersProp,
            this.standingData.SourceDocumentUploaderData
        );
        this.setDisabledControls();
    }

    private getIds(
        nameProp: keyof EffectivenessWizardModel,
        standingData: IdNameCombination[]
    ): IdNameCombination[] {
        const control = getFormControl<EffectivenessWizardModel>(
            this.personsInvolvedGroup,
            nameProp
        );
        const ids = control.value as number[];

        if (ids && standingData) return standingData.filter((x) => ids.indexOf(x.ID) >= 0);
        return [];
    }

    originChanged(): void {
        if (
            this.effectivenessOrigin === EffectivenessOriginType.existing &&
            !this.existingTestPlans
        ) {
            this.existingTestPlans = toPromise(
                this._overviewDS.getEffectivenessNames(this.controlGuid)
            );
        } else if (
            this.effectivenessOrigin === EffectivenessOriginType.catalogue &&
            !this.catalogues
        ) {
            this.catalogues = toPromise(this._overviewDS.getCatalogues(this.controlGuid));
        }
    }

    loadExistingEffectiveness(): Promise<EffectivenessWizardModel> {
        this.originLoading = true;
        const promise = toPromise(this._overviewDS.getEffectivenessAsCopy(this.originExistingID));
        promise.then(async (tp) => {
            this.model = tp;
            this.originLoading = false;
            await this.applyModel(false);
            this.setWizardCompletable();
        });
        return promise;
    }

    loadCatalogue(): void {
        this.originLoading = true;
        this._overviewDS
            .getCatalogue(this.originCatalogueID)
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (tp) => {
                this.model = tp;
                this.originLoading = false;
                await this.applyModel(true);
            });
    }

    private isReviewEnabled(): boolean {
        if (this.reviewerRequiredApplicationSetting) {
            return true;
        }
        const reviewEnabled = <boolean>(
            getFormValue<EffectivenessWizardModel>(
                this.personsInvolvedGroup,
                this.reviewEnabledProp
            )
        );

        return reviewEnabled;
    }

    private initializeModelProps(): void {
        this.sourceDocumentUploadersProp = nameof<EffectivenessWizardModel>(
            (x) => x.sourceDocumentUploaders
        );
        this.sourceDocumentDueDateProp = nameof<EffectivenessWizardModel>(
            (x) => x.sourceDocumentDueDate
        );

        this.evidenceUploadersProp = nameof<EffectivenessWizardModel>((x) => x.evidenceUploaders);
        this.evidenceDueDateProp = nameof<EffectivenessWizardModel>((x) => x.evidenceDueDate);

        this.testersProp = nameof<EffectivenessWizardModel>((x) => x.testers);
        this.testerDueDateProp = nameof<EffectivenessWizardModel>((x) => x.testerDueDate);

        this.reviewEnabledProp = nameof<EffectivenessWizardModel>((x) => x.reviewEnabled);
        this.reviewersProp = nameof<EffectivenessWizardModel>((x) => x.reviewers);
        this.reviewerDueDateProp = nameof<EffectivenessWizardModel>((x) => x.reviewerDueDate);

        this.startDateProp = nameof<EffectivenessWizardModel>((x) => x.startDate);
        this.endDateProp = nameof<EffectivenessWizardModel>((x) => x.endDate);
        this.testPeriodProp = nameof<EffectivenessWizardModel>((x) => x.testPeriod);

        this.sampleGenerationMethodProp = nameof<EffectivenessWizardModel>(
            (x) => x.sampleGenerationMethod
        );
        this.reasonManualSampleGenerationProp = nameof<EffectivenessWizardModel>(
            (x) => x.reasonManualSampleGeneration
        );

        this.linkedStandardProp = nameof<EffectivenessWizardModel>((x) => x.linkedStandard);
        this.qualityAspectsProp = nameof<EffectivenessWizardModel>((x) => x.qualityAspects);
    }

    private enableSourceUploaderControls(
        propsToKeepDisabled: (keyof EffectivenessWizardModel)[] = []
    ) {
        this.enableControlsOfFormGroup(this.personsInvolvedGroup, [
            this.sourceDocumentUploadersProp,
            this.sourceDocumentDueDateProp,
        ]);

        let keepDisabled = [...propsToKeepDisabled];

        if (
            !this.standingData ||
            !this.standingData.SourceDocumentUploaderData ||
            this.standingData.SourceDocumentUploaderData.length === 0
        ) {
            keepDisabled.pushIfNotExists(this.sourceDocumentUploadersProp);
        }

        if (this.getSampleGenerationMethod() === SampleGenerationType.oneSample) {
            keepDisabled.pushIfNotExists(this.sourceDocumentUploadersProp);
            keepDisabled.pushIfNotExists(this.sourceDocumentDueDateProp);

            getFormControl<EffectivenessWizardModel>(
                this.personsInvolvedGroup,
                this.sourceDocumentUploadersProp
            ).setValue([], stopEmit);

            getFormControl<EffectivenessWizardModel>(
                this.personsInvolvedGroup,
                this.sourceDocumentDueDateProp
            ).setValue(null, stopEmit);
        }

        this.disableControlsOfFormGroup(this.personsInvolvedGroup, keepDisabled);
    }

    private enableEvidenceControls(propsToKeepDisabled: (keyof EffectivenessWizardModel)[] = []) {
        this.enableControlsOfFormGroup(this.personsInvolvedGroup, [
            this.evidenceUploadersProp,
            this.evidenceDueDateProp,
        ]);
        this.disableControlsOfFormGroup(this.personsInvolvedGroup, propsToKeepDisabled);
    }

    private enableTestersControls(propsToKeepDisabled: (keyof EffectivenessWizardModel)[] = []) {
        this.enableControlsOfFormGroup(this.personsInvolvedGroup, [
            this.testersProp,
            this.testerDueDateProp,
        ]);
        this.disableControlsOfFormGroup(this.personsInvolvedGroup, propsToKeepDisabled);
    }

    private enableReviewControls(propsToKeepDisabled: (keyof EffectivenessWizardModel)[] = []) {
        const reviewEnabled = this.isReviewEnabled();
        if (reviewEnabled) {
            this.enableControlsOfFormGroup(this.personsInvolvedGroup, [
                this.reviewEnabledProp,
                this.reviewersProp,
                this.reviewerDueDateProp,
            ]);
        } else {
            this.showReviewers = false;
            this.enableControlsOfFormGroup(this.personsInvolvedGroup, [this.reviewEnabledProp]);
        }
        this.disableControlsOfFormGroup(this.personsInvolvedGroup, propsToKeepDisabled);
    }

    private disableControlsOfFormGroup(
        formGroup: FormGroup<any>,
        propsToDisable: (keyof EffectivenessWizardModel)[]
    ) {
        propsToDisable.forEach((property) =>
            getFormControl<EffectivenessWizardModel>(formGroup, property).disable(stopEmit)
        );
    }

    private enableControlsOfFormGroup(
        formGroup: FormGroup<any>,
        propsToEnable: (keyof EffectivenessWizardModel)[]
    ) {
        propsToEnable.forEach((property) =>
            getFormControl<EffectivenessWizardModel>(formGroup, property).enable(stopEmit)
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
