import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProcessEditorPrintType } from "@app/process-editor/enums/ProcessEditorPrintType";
import { ControlItem, DiagramData, RiskItem } from "@models/processeditor/DiagramData";
import { PrintOverviewModel } from "@models/processeditor/PrintOverviewModel";
import { ProcessCellConnections } from "@models/processeditor/ProcessCellConnections";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { Configuration } from "../../../app/app.constants";
import { ApiDataService } from "../ApiDataService";

@Injectable()
export class ProcessEditorDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "processeditor");
    }

    getProcessRisks(cellId: number, orgId: number | null | undefined, businessDimensionID: number) {
        let url = `/${businessDimensionID}/risks?cellId=${cellId}`;
        if (orgId) url += `&orgId=${orgId}`;

        return this.get<RiskItem[]>(url).pipe(
            map((riskItems) => riskItems.map<RiskItem>((item) => new RiskItem(item)))
        );
    }

    getProcessControls(
        cellId: number,
        orgId: number | null | undefined,
        businessDimensionID: number
    ) {
        let url = `/${businessDimensionID}/controls?cellId=${cellId}`;
        if (orgId) url += `&orgId=${orgId}`;

        return this.get<ControlItem[]>(url).pipe(
            map((controlItems) => controlItems.map<any>((item) => new ControlItem(item as any)))
        );
    }

    getEditorOrganizations() {
        const url = "/editororganizations";
        return this.get<CerrixTreeItem[]>(url);
    }

    getViewerOrganizations() {
        const url = "/viewerorganizations";
        return this.get<CerrixTreeItem[]>(url);
    }

    public getViewerBusinessDimensions() {
        const url = this.actionUrl + `/viewerbusinessdimensions`;
        return this.http.get<CerrixTreeItem[]>(url);
    }

    public getEditorBusinessDimensions() {
        const url = this.actionUrl + `/editorbusinessdimensions`;
        return this.http.get<CerrixTreeItem[]>(url);
    }

    getBusinessDimensions(businessDimensionID: number): Observable<CerrixTreeItem[]> {
        const url = `/businessDimensions/${businessDimensionID}`;

        return this.get<CerrixTreeItem[]>(url);
    }

    getPrintOverviewModel(
        businessDimensionID: number,
        connections: ProcessCellConnections,
        printType: ProcessEditorPrintType
    ): Observable<PrintOverviewModel> {
        const url = `/printoverviewmodel/${businessDimensionID}/${printType}`;

        return this.post<PrintOverviewModel>(connections, url);
    }

    getPreview(businessDimensionID: number, organizationId: number = null): Observable<string> {
        let url = `/preview/${businessDimensionID}?organizationId=`;
        if (organizationId) {
            url += organizationId;
        } else {
            url += "null";
        }

        return this.getString(url);
    }

    getDiagram(businessDimensionID: number, organizationId: number = null) {
        let url = `/${businessDimensionID}?organizationId=`;
        if (organizationId) {
            url += organizationId;
        } else {
            url += "null";
        }

        return this.get<DiagramData>(url).pipe(map((dd) => new DiagramData(dd)));
    }

    saveDiagram(diagram: Partial<DiagramData>): Observable<string> {
        return this.postWithDocuments<string>(diagram, diagram.Documents);
    }

    public hasAccessToBusinessDimension(businessDimensionId: number): Observable<boolean> {
        const url = `/${businessDimensionId}/hasaccess`;

        return this.get<boolean>(url);
    }
}
