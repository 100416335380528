<div class="d-flex flex-row button-container align-items-center">
    <eff-icon
        [icon]="icon"
        class="action-icon"
        *ngIf="!showConfirmation"
        (click)="$event.stopPropagation(); onClick()"
    ></eff-icon>
    <div #confirmationButton>
        <eff-button
            *ngIf="showConfirmation"
            [text]="label"
            [icon]="icon"
            iconPosition="right"
            (onClick)="onConfirmationClick()"
        ></eff-button>
    </div>
</div>
