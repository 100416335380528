import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { TaskDataService } from "./services/TaskDataService";
import { TaskControlsComponent } from "./task-detail/task-controls/task-controls.component";
import { TaskDetailMainComponent } from "./task-detail/task-detail-main/task-detail-main.component";
import { TaskDetailComponent } from "./task-detail/task-detail.component";
import { TaskSeriesControlsComponent } from "./pages/detail/task-series-controls/task-series-controls.component";
import { TaskSeriesDetailComponent } from "./pages/detail/task-series-detail.component";
import { TaskSeriesDetailMainComponent } from "./pages/detail/details-main/task-series-detail-main.component";
import { TaskSeriesDataService } from "./services/task-series-data.service";
import { ScheduleModule } from "@syncfusion/ej2-angular-schedule";
import { TaskOverviewComponent } from "./pages/task-overview/task-overview.component";

@NgModule({
    declarations: [
        TaskOverviewComponent,
        TaskDetailMainComponent,
        TaskDetailComponent,
        TaskControlsComponent,
        TaskSeriesDetailComponent,
        TaskSeriesDetailMainComponent,
        TaskSeriesControlsComponent,
    ],
    exports: [],
    imports: [FormsModule, CommonModule, SharedModule, ScheduleModule],
})
export class TaskModule {
    static forRoot(): ModuleWithProviders<TaskModule> {
        return {
            ngModule: TaskModule,
            providers: [TaskDataService, TaskSeriesDataService],
        };
    }
}
