import { Component, Input, OnInit } from "@angular/core";
import { SampleType } from "../../../shared/enums/sample-type.enum";
import { SampleGenerationType } from "../../../shared/enums/sample-generation-type.enum";
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { WorkflowGenerateSamplesModel } from "../../../shared/models/workflow-generate-samples-model";
import { getFormControl, nameof, setFormControlValue } from "@methods/jeffs-toolkit";
import { FormValidators } from "@app/shared/helpers/form-validators";
import { LocalizationService } from "@app/shared";
import { DocumentModel } from "@models/documents/documentmodel";
import { AdvEffDataService } from "../../../shared/services/adv-eff-data.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { Configuration } from "@app/app.constants";

@Component({
    selector: "adv-eff-generate-samples-dialog",
    templateUrl: "./adv-eff-generate-samples-dialog.component.html",
    styleUrls: ["./adv-eff-generate-samples-dialog.component.scss"],
})
export class GenerateSamplesDialogComponent implements OnInit {
    @Input() mainFormGroup: FormGroup;
    @Input() effectivenessGuid: string;
    @Input() model: WorkflowGenerateSamplesModel;
    @Input() startDate: Date;
    @Input() endDate: Date;

    get finishedLoading(): boolean {
        return !!this.mainFormGroup && !!this.sourceDocuments;
    }

    get sampleType(): SampleType {
        return this.getControlValue((wgsm) => wgsm.sampleType, 0);
    }
    get documentGuid(): string {
        return this.getControlValue<string>((wgsm) => wgsm.documentGuid, null);
    }
    get numberOfTestSamples(): number {
        return this.getControlValue((wgsm) => wgsm.numberOfTestSamples, 1);
    }
    get populationSize(): number {
        return this.getControlValue((wgsm) => wgsm.populationSize, 1);
    }
    get numberSamples(): number[] {
        return this.getControlValue((wgsm) => wgsm.numberSamples, [1]);
    }
    get dateSamples(): Date[] {
        return this.getControlValue((wgsm) => wgsm.dateSamples, [new Date()]);
    }

    private getControlValue<T>(
        nameFunction: (obj: WorkflowGenerateSamplesModel) => any,
        defaultValue: T
    ): T {
        const ctrl = getFormControl<WorkflowGenerateSamplesModel>(this.mainFormGroup, nameFunction);
        if (ctrl) {
            return ctrl.value;
        }
        return defaultValue;
    }

    disabled = false;
    maxTextFieldLength = this._configuration.MaxTextFieldLength;

    // For markup
    SampleGenerationType = SampleGenerationType;
    SampleType = SampleType;
    sampleTypeOptions = [];
    sourceDocuments: DocumentModel[];

    constructor(
        private promptService: CerrixPromptService,
        private localizationService: LocalizationService,
        private advTestingService: AdvEffDataService,
        private _configuration: Configuration
    ) {}

    async ngOnInit() {
        this.sourceDocuments = await this.getExcelSourceDocuments();

        this.sampleTypeOptions = this.model.sampleTypeOptions;

        if (this.model.sampleGenerationType === SampleGenerationType.manual) {
            this.sampleCountChanged();
        }
    }

    sampleCountChanged(): void {
        let activeArray: string;
        let inactiveArray: string[] = [];
        if (this.sampleType == SampleType.number) {
            activeArray = nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.numberSamples);
            inactiveArray.push(nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.dateSamples));
            inactiveArray.push(nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.textSamples));
        } else if (this.sampleType == SampleType.date) {
            activeArray = nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.dateSamples);
            inactiveArray.push(nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.numberSamples));
            inactiveArray.push(nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.textSamples));
        } else if (this.sampleType == SampleType.text) {
            activeArray = nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.textSamples);
            inactiveArray.push(nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.numberSamples));
            inactiveArray.push(nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.dateSamples));
        }

        inactiveArray.forEach((array) => {
            const toClearArray = this.mainFormGroup.get(array) as FormArray;
            while (toClearArray.length > 0) {
                toClearArray.removeAt(toClearArray.length - 1);
            }
        });

        const numberOfTestSamples = this.numberOfTestSamples;
        if (numberOfTestSamples < 1 || numberOfTestSamples > 100) {
            return;
        }

        const sampleArray = this.mainFormGroup.get(activeArray) as FormArray;
        if (sampleArray.length > numberOfTestSamples) {
            while (sampleArray.length > numberOfTestSamples) {
                sampleArray.removeAt(sampleArray.length - 1);
            }
        } else if (sampleArray.length < numberOfTestSamples) {
            for (let i = sampleArray.length; i < numberOfTestSamples; i++) {
                const formControl = this.checkSampleTypeForValidation();

                // Workaround for Firefox not putting focus on spinner input
                formControl.markAsTouched();
                sampleArray.push(formControl);
            }
        }

        this.mainFormGroup.markAsTouched();
    }

    private checkSampleTypeForValidation() {
        if (this.sampleType == SampleType.number) {
            return new FormControl(1, this.getNumberSampleValidators());
        }
        if (this.sampleType == SampleType.date) {
            return new FormControl(this.startDate, [
                Validators.required,
                this.validateDate.bind(this),
            ]);
        }
        if (this.sampleType == SampleType.text) {
            return new FormControl("default", [
                Validators.required,
                FormValidationHelper.validateMaxLength(
                    "Sample",
                    this._configuration.MaxTextFieldLength
                ),
            ]);
        }
    }

    async sourceDocumentChanged(): Promise<void> {
        this.model.documentGuid = this.documentGuid;

        let population: number = null;
        if (this.documentGuid) {
            const prompt = this.promptService.loader("Updating population, please wait...");

            try {
                population = await this.advTestingService
                    .getSourceDocumentPopulation(this.effectivenessGuid, this.documentGuid)
                    .toPromise();
            } finally {
                prompt.close();
            }
        }

        setFormControlValue<WorkflowGenerateSamplesModel>(
            this.mainFormGroup,
            (wgsm) => wgsm.populationSize,
            population
        );
    }

    disable(): void {
        this.disabled = true;
        this.mainFormGroup.disable();
    }

    enable(): void {
        this.disabled = false;
        this.mainFormGroup.enable();
        if (this.sampleType != SampleType.number) {
            getFormControl<WorkflowGenerateSamplesModel>(
                this.mainFormGroup,
                (wgsm) => wgsm.populationSize
            ).disable();
        }
    }

    /**
     * Set population size and trigger control to update manual input
     */
    private setPopulationSizeAndSourceDocumentValidation(sampleType: SampleType) {
        const populationSizeControl = getFormControl<WorkflowGenerateSamplesModel>(
            this.mainFormGroup,
            (wgsm) => wgsm.populationSize
        );
        // Set Population Control. Only Number uses this
        if (sampleType == SampleType.number) {
            populationSizeControl.enable();
            populationSizeControl.setValidators([
                Validators.required,
                FormValidators.min<WorkflowGenerateSamplesModel>(
                    nameof<WorkflowGenerateSamplesModel>((wgsm) => wgsm.numberOfTestSamples)
                ),
                Validators.max(this.model.maxPopulationSize),
                Validators.pattern(/^[0-9]+$/),
            ]);
        } else {
            populationSizeControl.disable();
            populationSizeControl.setValidators(null);
        }

        const documentGuidControl = getFormControl<WorkflowGenerateSamplesModel>(
            this.mainFormGroup,
            (wgsm) => wgsm.documentGuid
        );
        // Set Document Guid Control. Only Spreadsheet uses this
        if (sampleType == SampleType.spreadsheet) {
            documentGuidControl.enable();
            documentGuidControl.setValidators([Validators.required]);
        } else {
            documentGuidControl.disable();
            documentGuidControl.setValidators(null);
        }

        populationSizeControl.updateValueAndValidity();
    }

    private getNumberSampleValidators(): ValidatorFn[] {
        return [Validators.required, Validators.pattern(/^[0-9]+$/)];
    }

    private validateDate(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (value >= this.startDate && value <= this.endDate) {
            return null;
        }

        return {
            invalidDateRange: true,
            minDate: this.localizationService.formatDate(this.startDate),
            maxDate: this.localizationService.formatDate(this.endDate),
        };
    }

    private async getExcelSourceDocuments() {
        const model = await this.advTestingService
            .getSourceDocuments(this.effectivenessGuid)
            .toPromise();

        return model.documents.filter((x) => x.Name.toLowerCase().endsWith(".xlsx"));
    }
}
