import { Injectable } from "@angular/core";
import { AboutComponent } from "@app/about/about.component";
import { DevManagementComponent } from "@app/admin/pages/devmanagement/devmanagement.component";
import { AssessmentDetailsComponent } from "@app/assessment/pages";
import { AuditUniversePeriodDetailComponent } from "@app/audit-universe-period/pages/audit-universe-period-detail/audit-universe-period-detail.component";
import { AuditUniversePeriodOverviewComponent } from "@app/audit-universe-period/pages/audit-universe-period-overview/audit-universe-period-overview.component";
import { AuditUniverseDetailComponent } from "@app/audit-universe/pages/audit-universe-detail/audit-universe-detail.component";
import { AuditUniverseOverviewComponent } from "@app/audit-universe/pages/audit-universe-overview/audit-universe-overview.component";
import { AuditUniversePlanningComponent } from "@app/audit-universe/pages/audit-universe-planning/audit-universe-planning.component";
import { AuditDetailComponent } from "@app/audit/pages/audit-detail/audit-detail.component";
import { AuditOverviewComponent } from "@app/audit/pages/audit-overview/audit-overview.component";
import { AuditPlanningComponent } from "@app/audit/pages/audit-planning/audit-planning.component";
import { CalendarComponent } from "@app/calendar/pages/calendar/calendar.component";
import { CalendarSettingsComponent } from "@app/calendar/pages/settings/calendarsettings.component";
import { ControlCopyEffectivenessComponent } from "@app/controls/copy-effectiveness/control-copy-effectiveness.component";
import { ControlDetailComponent } from "@app/controls/detail/control-detail.component";
import { ControlExportEffectivenessComponent } from "@app/controls/export-effectiveness/control-export-effectiveness.component";
import { ControlOverviewComponent } from "@app/controls/overview/control-overview.component";
import { SharedControlOverview } from "@app/controls/overview/shared-controls/shared-control-overview";
import { AdvEffControlOverviewComponent } from "@app/controls/testing/advanced/control-overview/adv-eff-control-overview.component";
import { AdvEffDetailComponent } from "@app/controls/testing/advanced/detail/adv-eff-detail.component";
import { AdvEffOverviewComponent } from "@app/controls/testing/advanced/overview/adv-eff-overview.component";
import { SimpleTestingDetailComponent } from "@app/controls/testing/simple/detail/simple-testing-detail.component";
import { SimpleTestingType } from "@app/controls/testing/simple/enums/SimpleTestingType";
import { SimpleTestingConfig } from "@app/controls/testing/simple/models/SimpleTestingConfig";
import { DashboardComponent } from "@app/dashboard/dashboard.component";
import { ProcessingPurposeDataStructuresComponent } from "@app/datamanagement/pages/datastructures/datastructures.component";
import { DataManagementDetailComponent } from "@app/datamanagement/pages/detail/datamanagement-detail.component";
import { DataManagementOverviewComponent } from "@app/datamanagement/pages/overview/datamanagement-overview.component";
import { DeletedItemsComponent } from "@app/deleted-items/deleted-items.component";
import { AdminDocumentsComponent } from "@app/documents/admin/admin-documents.component";
import { SharedDocumentsComponent } from "@app/documents/shared/shared-documents.component";
import { BulkDeletedDocumentsComponent } from "@app/documents/bulk-deleted/bulk-deleted-documents.component";
import { EventDetailComponent } from "@app/event";
import { EventOverviewComponent } from "@app/event/event-overview/event-overview.component";
import { ExportOverviewComponent } from "@app/export/export-overview.component";
import { ExternalConnectionOverviewComponent } from "@app/external-connections/external-connection-overview.component";
import { TpaRequestDetailComponent } from "@app/external-connections/third-party-assurance/detail/tpa-request-detail.component";
import { TpaRequestOverviewComponent } from "@app/external-connections/third-party-assurance/request/tpa-request-overview.component";
import {
    FindingReportDetailsComponent,
    FindingReportOverviewComponent,
} from "@app/findingreport/pages";
import { FormDetailComponent } from "@app/forms/pages/detail/form-detail.component";
import { FormEntryComponent } from "@app/forms/pages/entry/form-entry.component";
import { FormHistoryOverviewComponent } from "@app/forms/pages/history/form-history-overview.component";
import { FormInviteComponent } from "@app/forms/pages/invite/form-invite.component";
import { FormHomeComponent } from "@app/forms/pages/overview/form-home.component";
import { FormResultsComponent } from "@app/forms/pages/overview/results-overview/form-results.component";
import { FormStatusComponent } from "@app/forms/pages/overview/status-overview/form-status.component";
import { KriDataGraphOverviewComponent } from "@app/kri/shared/pages/kri-data-graph-overview/kri-data-graph-overview.component";
import { KriDataUploadComponent } from "@app/kri/kri-data-upload/kri-data-upload.component";
import { KriDetailComponent } from "@app/kri/kri-detail/kri-detail.component";
import { KriOverviewComponent } from "@app/kri/kri-overview/kri-overview.component";
import { MailMessageDetailComponent } from "@app/mailmessages/pages/mailmessages/detail/mailmessages-detail.component";
import { MailMessageComponent } from "@app/mailmessages/pages/maintenance/notification-maintenance.component";
import { NotificationDetailComponent } from "@app/mailmessages/pages/notifications/detail/notification-detail.component";
import { ManualComponent } from "@app/manual/manual.component";
import { MoiDetailComponent } from "@app/moi/moi-detail/moi-detail.component";
import { MoiOverviewComponent } from "@app/moi/moi-overview/moi-overview.component";
import { BusinessNavigatorDetailComponent } from "@app/navigators/business-navigator/business-navigator-detail/business-navigator-detail.component";
import { BusinessNavigatorOverviewComponent } from "@app/navigators/business-navigator/business-navigator-overview/business-navigator-overview.component";
import { PeriodsComponent } from "@app/periods/periods.component";
import { ProcessOverviewComponent } from "@app/process-editor/components/process-overview/process-overview.component";
import { ProcessEditorComponent } from "@app/process-editor/process-editor.component";
import { ReleaseNotesComponent } from "@app/release-notes/pages/release-notes/release-notes.component";
import { ReportOverviewComponent } from "@app/report/report-overview/report-overview.component";
import { RiskDetailComponent } from "@app/risk/pages/detail/risk-detail/risk-detail.component";
import { RiskOverviewComponent } from "@app/risk/pages/risk-overview/risk-overview.component";
import { SettingsComponent } from "@app/settings/settings.component";
import { StandingDataOverviewComponent } from "@app/standingdata/standingdata-overview/standingdata-overview.component";
import { UserDetailComponent } from "@app/user/user-maintenance/user-detail/user-detail.component";
import { UserOverviewComponent } from "@app/user/user-maintenance/user-overview/user-overview.component";
import { UserRoleGroupComponent } from "@app/user/user-maintenance/user-role-group/user-role-group.component";
import { UserProfileComponent } from "@app/user/user-profile/user-profile.component";
import { PageMapping } from "@models/generic/TabModels/PageMapping";
import { InviteComponent } from "@pages/invite/invite.component";
import { ThirdPartyContractsComponent } from "@pages/thirdparty/thirdparty-contracts/thirdparty-contracts.component";
import { ThirdPartyDetailComponent } from "@pages/thirdparty/thirdparty-detail/thirdparty-detail.component";
import { ThirdPartyOverviewComponent } from "@pages/thirdparty/thirdparty-overview/thirdparty-overview.component";
import { AuditFindingOverviewComponent } from "../../../app/audit-finding/pages/audit-finding-overview/audit-finding-overview.component";
import { AuditFindingDetailComponent } from "./../../../app/audit-finding/pages/audit-finding-detail/audit-finding-detail.component";
import { AuditFieldworkStepDetailComponent } from "./../../../app/audit/pages/audit-fieldwork-step-detail/audit-fieldwork-step-detail.component";
import { ReportPowerbiComponent } from "./../../../app/report/report-powerbi/report-powerbi.component";
import { ControlExecutionOverviewComponent } from "@app/controls/control-execution/control-execution-overview/control-execution-overview.component";
import { TaskDetailComponent } from "@app/tasks/task-detail/task-detail.component";
import { TaskSeriesDetailComponent } from "@app/tasks/pages/detail/task-series-detail.component";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { EffDetailComponent } from "@app/controls/testing/advanced/detail-new/eff-detail.component";
import { WopiComponent } from "@app/wopi/wopi.component";
import { WopiDebugComponent } from "@app/wopi/wopidebug/wopi-debug.component";
import { DocumentTemplatesDetailsComponent } from "@app/document-templates/details/document-templates-details.component";
import { JobSchedulerStatusComponent } from "@app/job-scheduler/job-scheduler-status/job-scheduler-status.component";
import { TaskOverviewComponent } from "@app/tasks/pages/task-overview/task-overview.component";
import { IncidentOverviewComponent } from "@app/incident/incident-overview/incident-overview.component";
import { IncidentDetailsComponent } from "@app/incident/incident-details/incident-details.component";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { PeriodicNotificationDetailComponent } from "@app/mailmessages/pages/periodic/detail/periodic-notification-detail.component";
import { ProcessedDocumentOverviewComponent } from "@app/document-intelligence/pages/processed-document-overview/processed-document-overview.component";
import { ProcessedDocumentDetailComponent } from "@app/document-intelligence/pages/processed-document-detail/processed-document-detail.component";
import { OrganizationBudgetComponent } from "@app/org-budget/organization-budget.component";

@Injectable()
export class PageLookup {
    "Dashboard": PageMapping = {
        Component: DashboardComponent,
        NoDuplicate: true,
        PermissionProperty: "",
    };

    "Risks": PageMapping = {
        Component: RiskOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasRiskAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Risk Detail": PageMapping = {
        Component: RiskDetailComponent,
        PermissionProperty: "HasRiskAccess",
        ShowLoader: true,
    };
    "Controls": PageMapping = {
        Component: ControlOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasControlAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Control Detail": PageMapping = {
        Component: ControlDetailComponent,
        PermissionProperty: "HasControlAccess",
        ShowLoader: true,
    };
    "Advanced Testing Test Plans": PageMapping = {
        Component: AdvEffOverviewComponent,
        PermissionProperty: "HasAdvancedTestingAccess",
        NoDuplicate: true,
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Advanced Testing Test Plan": PageMapping = {
        Component: AdvEffDetailComponent,
        PermissionProperty: "HasAdvancedTestingAccess",
        ShowLoader: true,

        // If the feature toggle is enabled, we want to use the new component, otherwise we want to use the old component.
        FeatureToggleSetting: ApplicationSettings.FeatureToggleNewEffectivenessTestingScreens,
        FeatureToggleCustomComponentSetter: (setting: boolean) => {
            if (setting) {
                return EffDetailComponent;
            }

            return null;
        },
    };
    "Advanced Testing Control Overview": PageMapping = {
        Component: AdvEffControlOverviewComponent,
        PermissionProperty: "HasAdvancedTestingAccess",
        CheckDuplicateOnGuid: true,
    };

    "Control Simple Eff Detail": PageMapping = {
        Component: SimpleTestingDetailComponent,
        PermissionProperty: "HasControlAccess",
        CheckDuplicateOnGuid: true,
        ComponentConfig: <SimpleTestingConfig>{ type: SimpleTestingType.Effectiveness },
        ShowLoader: true,
    };
    "Control Simple DI Detail": PageMapping = {
        Component: SimpleTestingDetailComponent,
        PermissionProperty: "HasControlAccess",
        CheckDuplicateOnGuid: true,
        ComponentConfig: <SimpleTestingConfig>{
            type: SimpleTestingType.DesignImplementation,
        },
        ShowLoader: true,
    };

    "Export Effectiveness": PageMapping = {
        Component: ControlExportEffectivenessComponent,
        PermissionProperty: "HasEffectivenessExportAccess",
        ShowLoader: true,
    };

    "Copy Effectiveness": PageMapping = {
        Component: ControlCopyEffectivenessComponent,
        PermissionProperty: "HasEffectivenessCopyAccess",
        ShowLoader: true,
    };

    "Shared Control": PageMapping = {
        Component: SharedControlOverview,
        PermissionProperty: "HasSharedControlAccess",
        ShowLoader: true,
        NoDuplicate: true,
    };

    "Control Execution Overview": PageMapping = {
        Component: ControlExecutionOverviewComponent,
        PermissionProperty: "HasControlAccess",
        ShowLoader: true,
    };

    "Task Series Detail": PageMapping = {
        Component: TaskSeriesDetailComponent,
        PermissionProperty: "",
        ShowLoader: true,
    };

    "Measures of Improvement": PageMapping = {
        Component: MoiOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasMoiAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Moi Detail": PageMapping = {
        Component: MoiDetailComponent,
        PermissionProperty: "HasMoiAccess",
        ShowLoader: true,
    };

    "Key Risk Indicators": PageMapping = {
        Component: KriOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasKriAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Kri Detail": PageMapping = {
        Component: KriDetailComponent,
        PermissionProperty: "HasKriAccess",
        ShowLoader: true,
    };
    "Kri Upload Data": PageMapping = {
        Component: KriDataUploadComponent,
        NoDuplicate: true,
        PermissionProperty: "HasKriDataUploadAccess",
    };
    "Kri Graphs": PageMapping = {
        Component: KriDataGraphOverviewComponent,
        NoDuplicate: false,
        ShowLoader: true,
        PermissionProperty: "HasKriAccess",
    };

    "Events": PageMapping = {
        Component: EventOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasEventAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };

    "Event Detail": PageMapping = {
        Component: EventDetailComponent,
        PermissionProperty: "HasEventAccess",
        ShowLoader: true,
    };

    "Incidents": PageMapping = {
        Component: IncidentOverviewComponent,
        microserviceModule: MicroserviceModule.Incidents,
        PermissionProperty: "HasIncidentAccess",
        ShowLoader: true,
        NoDuplicate: true,
    };

    "Incident Details": PageMapping = {
        Component: IncidentDetailsComponent,
        PermissionProperty: "HasIncidentAccess",
        ShowLoader: true,
    };

    "Data Management": PageMapping = {
        Component: DataManagementOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasDataManagementAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Data Management Detail": PageMapping = {
        Component: DataManagementDetailComponent,
        PermissionProperty: "HasDataManagementAccess",
    };
    "Datastucture Management": PageMapping = {
        Component: ProcessingPurposeDataStructuresComponent,
        PermissionProperty: "HasDataManagementAccess",
    };

    "Reports": PageMapping = {
        Component: ReportOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasReportingAccess",
    };
    "Report Detail": PageMapping = {
        Component: ReportPowerbiComponent,
        PermissionProperty: "HasReportingAccess",
        ShowLoader: false,
    };

    "Exports": PageMapping = {
        Component: ExportOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasExportAccess",
    };

    "Profile": PageMapping = {
        Component: UserProfileComponent,
        NoDuplicate: true,
        PermissionProperty: "",
        ShowLoader: true,
    };

    "About": PageMapping = { Component: AboutComponent, NoDuplicate: true, PermissionProperty: "" };
    "Invite": PageMapping = { Component: InviteComponent, PermissionProperty: "HasInviteAccess" };

    "Calendar": PageMapping = { Component: CalendarComponent, NoDuplicate: true, ShowLoader: true };
    "Calendar Settings": PageMapping = {
        Component: CalendarSettingsComponent,
        NoDuplicate: true,
        ShowLoader: true,
    };

    // Admin
    "Settings": PageMapping = {
        Component: SettingsComponent,
        NoDuplicate: true,
        PermissionProperty: "IsApplicationAdmin",
        ShowLoader: true,
    };
    "Standing Data": PageMapping = {
        Component: StandingDataOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasStandingDataAccess",
        ShowLoader: true,
    };
    "Mail messages": PageMapping = {
        Component: MailMessageComponent,
        NoDuplicate: true,
        PermissionProperty: "MailMessages.HasMailAccess",
    };

    "Mail messages detail": PageMapping = {
        Component: MailMessageDetailComponent,
        PermissionProperty: "MailMessages.HasMailAccess",
    };

    "Notification detail": PageMapping = {
        Component: NotificationDetailComponent,
        PermissionProperty: "MailMessages.HasMailAccess",
    };

    "Periodic detail": PageMapping = {
        Component: PeriodicNotificationDetailComponent,
        PermissionProperty: "MailMessages.HasMailAccess",
    };

    "Periods": PageMapping = {
        Component: PeriodsComponent,
        NoDuplicate: true,
        PermissionProperty: "HasPeriodAccess",
    };

    "Deleted items": PageMapping = {
        Component: DeletedItemsComponent,
        ShowLoader: true,
        NoDuplicate: true,
        PermissionProperty: "HasDeletedItemsAccess",
    };

    "User management": PageMapping = {
        Component: UserOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "UserMaintenance.hasAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "User detail": PageMapping = {
        Component: UserDetailComponent,
        PermissionProperty: "UserMaintenance.hasAccess",
        ShowLoader: true,
    };

    "User role group": PageMapping = {
        Component: UserRoleGroupComponent,
        PermissionProperty: "UserMaintenance.canManageRoleGroup",
        ShowLoader: true,
    };

    "Scheduler Status": PageMapping = {
        Component: JobSchedulerStatusComponent,
        NoDuplicate: true,
    };

    "Finding Reports": PageMapping = {
        Component: FindingReportOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasFindingReportAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Findings Report Detail": PageMapping = {
        Component: FindingReportDetailsComponent,
        NoDuplicate: false,
        PermissionProperty: "HasFindingReportAccess",
    };

    "Assessment Detail": PageMapping = {
        Component: AssessmentDetailsComponent,
        NoDuplicate: false,
        PermissionProperty: "HasFindingReportAccess",
    };

    // Navigator
    "Navigator - Process Overview": PageMapping = {
        Component: ProcessOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasProcessViewerAccess",
        ListenToConfigChangedEvent: true,
    };
    "Navigator - Process Editor": PageMapping = {
        Component: ProcessEditorComponent,
        NoDuplicate: true,
        PermissionProperty: "HasProcessEditorAccess",
        ShowWarningOnClose: true,
        ListenToConfigChangedEvent: true,
    };

    "Navigator - Business Dimensions": PageMapping = {
        Component: BusinessNavigatorOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasBDManagementAccess",
        ShowLoader: true,
        ListenToConfigChangedEvent: true,
        IsWorkspace: true,
    };

    "Business Assessment": PageMapping = {
        Component: BusinessNavigatorDetailComponent,
        PermissionProperty: "HasBDManagementAccess",
        NoDuplicate: true,
        CheckDuplicateOnGuid: true,
        ShowLoader: true,
    };

    // TODO clean up
    "Navigator - Framework Dimension": PageMapping = {
        Url: "Pages/Navigator/NavigatorFrameworkDimensions.aspx",
        NoDuplicate: true,
        PermissionProperty: "HasFDManagementAccess",
    };

    // Forms (These checks are dependent on user and form, so just a general hasAccess check will suffice.)
    "Forms": PageMapping = {
        Component: FormHomeComponent,
        NoDuplicate: true,
        PermissionProperty: "Forms.HasAccess",
        ShowLoader: true,
    };
    "Form Results": PageMapping = {
        Component: FormResultsComponent,
        PermissionProperty: "Forms.HasAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Form Status": PageMapping = {
        Component: FormStatusComponent,
        PermissionProperty: "Forms.HasAccess",
    };
    "Form Detail": PageMapping = {
        Component: FormDetailComponent,
        NoDuplicate: true,
        CheckDuplicateOnGuid: true,
        PermissionProperty: "Forms.HasAccess",
    };
    "Form Entry": PageMapping = {
        Component: FormEntryComponent,
        PermissionProperty: "Forms.HasAccess",
    };
    "Form History": PageMapping = {
        Component: FormHistoryOverviewComponent,
        PermissionProperty: "Forms.HasAccess",
    };
    "Form Invite": PageMapping = {
        Component: FormInviteComponent,
        PermissionProperty: "Forms.IsInviter",
    };

    // Documents
    "Admin Documents": PageMapping = {
        Component: AdminDocumentsComponent,
        PermissionProperty: "Documents.Admin",
        ShowLoader: true,
        NoDuplicate: true,
    };
    "Document Templates": PageMapping = {
        Component: DocumentTemplatesDetailsComponent,
        PermissionProperty: "DocumentTemplates.HasAccess",
        NoDuplicate: true,
    };
    "Shared Documents": PageMapping = {
        Component: SharedDocumentsComponent,
        PermissionProperty: "Documents.SharedViewer",
        ShowLoader: true,
        NoDuplicate: true,
    };
    "Bulk Deleted Documents": PageMapping = {
        Component: BulkDeletedDocumentsComponent,
        PermissionProperty: "Documents.Admin",
        ShowLoader: true,
        NoDuplicate: true,
    };

    //#region Auditing

    "Audit Universe Workspace": PageMapping = {
        Component: AuditUniverseOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasUniverseAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };

    "Audit Universe Detail": PageMapping = {
        Component: AuditUniverseDetailComponent,
        PermissionProperty: "auditing.hasUniverseAccess",
        ShowLoader: true,
    };

    "Audit Universe Period": PageMapping = {
        Component: AuditUniversePeriodOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasPeriodAccess",
        ShowLoader: true,
    };

    "Audit Universe Period Detail": PageMapping = {
        Component: AuditUniversePeriodDetailComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasPeriodAccess",
        ShowLoader: true,
    };

    "Audit Universe Period Planning Widget": PageMapping = {
        Component: AuditUniversePlanningComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasPeriodAccess",
        ShowLoader: true,
    };

    "Audit Workspace": PageMapping = {
        Component: AuditOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasAuditAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };

    "Audit Detail": PageMapping = {
        Component: AuditDetailComponent,
        PermissionProperty: "auditing.hasAuditAccess",
        ShowLoader: true,
    };

    "Audit Fieldwork Step": PageMapping = {
        Component: AuditFieldworkStepDetailComponent,
        PermissionProperty: "auditing.hasAuditAccess",
        ShowLoader: true,
        NoDuplicate: true,
        CheckDuplicateOnGuid: true,
        ListenToConfigChangedEvent: true,
    };

    "Audit Planning": PageMapping = {
        Component: AuditPlanningComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasAuditAccess",
        ShowLoader: true,
    };

    "Audit Finding": PageMapping = {
        Component: AuditFindingOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "auditing.hasFindingAccess",
        ShowLoader: true,
    };

    "Audit Finding Detail": PageMapping = {
        Component: AuditFindingDetailComponent,
        PermissionProperty: "auditing.hasFindingAccess",
        ShowLoader: true,
    };

    //#endregion Auditing

    // Admin
    "Dev Management": PageMapping = {
        Component: DevManagementComponent,
        PermissionProperty: "IsDeveloperAdmin",
        NoDuplicate: true,
    };

    // Third Party
    "Third Party": PageMapping = {
        Component: ThirdPartyOverviewComponent,
        PermissionProperty: "HasThirdPartyAccess",
        NoDuplicate: true,
        ShowLoader: true,
        IsWorkspace: true,
    };
    "Third Party Detail": PageMapping = {
        Component: ThirdPartyDetailComponent,
        ShowLoader: true,
        PermissionProperty: "HasThirdPartyAccess",
    };
    "Third Party Contract Detail": PageMapping = {
        Component: ThirdPartyContractsComponent,
        ShowLoader: true,
    };

    "Manuals": PageMapping = {
        Component: ManualComponent,
        NoDuplicate: true,
        ShowLoader: false,
        ListenToConfigChangedEvent: true,
    };

    "Release Notes": PageMapping = {
        Component: ReleaseNotesComponent,
        NoDuplicate: true,
        ShowLoader: false,
        ListenToConfigChangedEvent: true,
    };

    // External Connections
    "External connections": PageMapping = {
        Component: ExternalConnectionOverviewComponent,
        NoDuplicate: true,
        ShowLoader: false,
    };

    "Third Party Assurance requests": PageMapping = {
        Component: TpaRequestOverviewComponent,
        ShowLoader: true,
        NoDuplicate: true,
    };

    "Wopi": PageMapping = {
        Component: WopiComponent,
    };

    "Wopi Debug": PageMapping = {
        Component: WopiDebugComponent,
        PermissionProperty: "IsDeveloperAdmin",
        NoDuplicate: true,
    };

    "Assurance requests": PageMapping = {
        Component: TpaRequestDetailComponent,
        ShowLoader: true,
    };

    "Tasks": PageMapping = {
        Component: TaskOverviewComponent,
        NoDuplicate: true,
        PermissionProperty: "HasTaskAccess",
        ShowLoader: true,
        IsWorkspace: true,
    };

    "Task Detail": PageMapping = {
        Component: TaskDetailComponent,
        ShowLoader: true,
        PermissionProperty: "HasTaskAccess",
    };

    "Processed Document Overview": PageMapping = {
        Component: ProcessedDocumentOverviewComponent,
        NoDuplicate: true,
    };

    "Processed Document Detail": PageMapping = {
        Component: ProcessedDocumentDetailComponent,
        PermissionProperty: "IsDeveloperAdmin",
    };

    "Organizational Budget": PageMapping = {
        Component: OrganizationBudgetComponent,
        PermissionProperty: "HasOrganizationBudgetAccess",
        NoDuplicate: true,
    };
}
