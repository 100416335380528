import { Component, Input, OnInit } from "@angular/core";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { parseTyped } from "@methods/CommonMethods";

@Component({
    selector: "form-entry-video",
    templateUrl: "./form-entry-video.component.html",
    styleUrls: ["./form-entry-video.component.scss"],
})
export class FormEntryVideoComponent implements OnInit {
    @Input() field: FormField;

    url: string;

    ngOnInit() {
        this.field.Required = false;
        const config = parseTyped<FormFieldConfig>(
            this.field.CustomSettings,
            new FormFieldConfig()
        );

        if (config && config.videoUrl) {
            this.url = config.videoUrl;
        }
    }
}
