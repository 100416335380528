import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { InviteConfigModel, InviteModel, FormInviteModel } from "@models/invite/InviteModel";
import { FormInviteUser } from "@models/invite/InviteUser";
import { CerrixInviteDataService } from "@app/shared/services/cerrix-invite.service";
import { CerrixInviteType } from "@enums/invite/CerrixInviteType";
import { InviteMessage } from "@models/invite/InviteMessage";
import { FormInviteType } from "@app/forms/enums/FormInviteType";
import { InviteFormSendModel } from "@models/invite/InviteSendModel";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { finalize } from "rxjs/operators";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "form-invite",
    templateUrl: "./form-invite.component.html",
    styleUrls: ["./form-invite.component.scss"],
})
export class FormInviteComponent implements OnInit {
    tab: TabModel;
    id: number;

    inviteType = FormInviteType;

    targetedInvitationType: FormInviteType;
    inviteModelPromise: Promise<InviteModel>;
    inviteModel: FormInviteModel;
    inviteConfig: InviteConfigModel;

    constructor(
        private _dataService: CerrixInviteDataService,
        private _toastrService: ToastrService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        if (isNaN(this.id)) {
            this.tab.close(false);
            this._toastrService.error("Form ID is required!", "Form invitation");
            return;
        }

        let targetInvitation: FormInviteType;
        if (this.tab && this.tab.config) {
            targetInvitation = +this.tab.config.targetedinvitationtype;
            this.targetedInvitationType = targetInvitation;
        }

        this.inviteModelPromise = this._dataService
            .getInviteModel(CerrixInviteType.CerrixForm, this.id, null)
            .toPromise();

        this.inviteModelPromise.then((x) => {
            this.inviteModel = x as FormInviteModel;
            this.tab.name = "Invite - " + this.inviteModel.targetName;

            if (targetInvitation > 0) {
                this.loadType(targetInvitation);
            }
        });
    }

    async loadType(inviteType: FormInviteType) {
        this.targetedInvitationType = inviteType;
        this.initConfig();
    }

    initConfig() {
        const config = new InviteConfigModel();

        config.tags = this.inviteModel.keywords;
        config.quickFilterProperties = [
            "displayName",
            "mail",
            "organizationName",
            "invitationStatus",
            "assignedObjectName",
        ];
        config.subject = this.inviteModel.subject;
        config.message = this.inviteModel.message;

        config.users = this.inviteModel.users;
        config.userSelection = [];

        config.templates = this.inviteModel.templates;
        config.organizations = this.inviteModel.organizations;

        config.onSaveTemplate = (template: InviteMessage) => {
            template.templateType = CerrixInviteType.CerrixForm;
            return this._dataService.storeTemplate(template).toPromise();
        };

        config.onDeleteTemplate = async (id: number) => {
            await this._dataService.deleteTemplate(CerrixInviteType.CerrixForm, id).toPromise();
            return true;
        };

        config.onSend = (subject: string, message: string, users: FormInviteUser[]) => {
            if (this.targetedInvitationType !== FormInviteType.ReminderInvitation) {
                if (users.length === 0) {
                    this._toastrService.warning(
                        "At least one user must be selected!",
                        "User selection error"
                    );
                    return;
                }
            }

            this._promptService
                .confirm(
                    "Send invites",
                    "This message will be sent to all results that are linked to an required invite and are not completed yet." +
                        " The invitation will be sent to the 'Current user' within the form result."
                )
                .onConfirm()
                .subscribe(() => {
                    if (
                        !subject ||
                        subject.trim().length === 0 ||
                        !message ||
                        message.trim().length === 0
                    ) {
                        this._toastrService.warning(
                            "Please make sure subject and message field are filled in!",
                            "Invitation error"
                        );
                        return;
                    }

                    const prompt = this._promptService.loader("Sending invites, please wait...");

                    const model = <InviteFormSendModel>{
                        subject,
                        message,
                        users: users.map((x) => x.ID),
                        inviteType: CerrixInviteType.CerrixForm,

                        formInviteType: this.targetedInvitationType,
                        formID: this.id,
                    };

                    this._dataService
                        .sendInvite(model.inviteType, model)
                        .pipe(finalize(() => prompt.close()))
                        .toPromise()
                        .then(() => {
                            if (this.targetedInvitationType === FormInviteType.ReminderInvitation) {
                                this._toastrService.success(
                                    `Reminder is sent to the current user(s) assigned to non completed required invites!`,
                                    "Reminder sent successfully!"
                                );
                            } else {
                                this._toastrService.success(
                                    `Invited ${users.length} user(s)`,
                                    "Invite sent successfully!"
                                );
                            }

                            if (
                                this.tab.parent &&
                                this.tab.parent.lookupname === this._pages.Forms
                            ) {
                                this.tab.parent.refresh();
                                this.tab.parent.activate();
                                this.tab.close(false);
                            } else {
                                this.tab.config = {
                                    targetedinvitationtype: this.targetedInvitationType,
                                };
                                this.tab.refresh();
                            }
                        });
                });
        };

        let inviteTargetPrefix = "";
        if (this.targetedInvitationType === FormInviteType.RequiredInvitation) {
            inviteTargetPrefix = "Required invitation for";
            config.users.forEach(
                (u: FormInviteUser) =>
                    (u.disabled = u.isInvited === true && u.inviteCompleted !== true)
            );
        } else if (this.targetedInvitationType === FormInviteType.ReminderInvitation) {
            inviteTargetPrefix = "Reminder mail for";
            config.users = [];
        } else if (this.targetedInvitationType === FormInviteType.ShareInvitation) {
            inviteTargetPrefix = "Sharing mail for";
        }

        config.inviteTargetMessage = `${inviteTargetPrefix} '${this.inviteModel.targetName}'`;

        this.inviteConfig = config;
    }
}
