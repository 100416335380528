<!-- Global loader for whole Detail tab -->
<div class="detail-page-card" *ngIf="!simpleTestingGroup">
    <div class="detail-page-card-title">Test detail</div>
    <div class="detail-page-card-body padded cerrix-control-sm">
        <div class="fancy-loader submodule"></div>
    </div>
</div>

<div *ngIf="simpleTestingGroup">
    <generic-list-manager
        *ngIf="testScoreConfig && permissions.CanViewScore"
        [config]="testScoreConfig"
        #testScores
    ></generic-list-manager>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Test detail</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <simple-testing-testplan
                [type]="type"
                [standingData]="standingData"
                [simpleTestingGroup]="simpleTestingGroup"
                (catalogueChanged)="catalogueChanged($event)"
            >
            </simple-testing-testplan>
        </div>
    </div>
</div>

<control-detail-overview
    *ngIf="model"
    [ControlGuid]="model.ControlGuid"
    Label="Linked control"
    [HideOpenControlButton]="false"
    [HideControlDetails]="false"
    [HideDesignScore]="false"
    [HideEffectivenessScore]="false"
></control-detail-overview>
