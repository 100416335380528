<div class="detail-page-card">
    <div class="detail-page-card-title">Measures of Improvements</div>

    <div *ngIf="!linkedMois" class="p-3">
        <div class="fancy-loader submodule"></div>
    </div>
    <div [class.d-none]="!linkedMois">
        <div class="detail-page-card-actions flexme">
            <div>
                <button
                    class="btn btn-raised"
                    data-uiid="moi-linkable-add-button"
                    (click)="addMoi()"
                    *ngIf="canAdd"
                >
                    <i class="fas fa-plus-circle"></i>
                    <span>Add MoI</span>
                </button>
                <button
                    class="btn btn-raised red"
                    data-uiid="moi-linkable-delete-button"
                    (click)="deleteMoi()"
                    [disabled]="!activeRow"
                    *ngIf="canDelete"
                >
                    <i class="fas fa-times-circle"></i>
                    <span>Delete MoI</span>
                </button>
            </div>
            <div>
                <button
                    class="btn btn-raised float-end"
                    data-uiid="moi-linkable-reload-button"
                    (click)="load(true)"
                >
                    <i class="fas fa-sync"></i>
                    <span>Reload</span>
                </button>
            </div>
        </div>

        <div class="detail-page-card-body">
            <cerrix-table
                [idProp]="'guid'"
                [config]="overviewConfig"
                [rendererConfig]="rendererConfig"
                [activeRow]="activeRow"
                [Centered]="true"
                [NoPadding]="true"
                [NoBorder]="true"
                data-uiid="moi-linkable-table"
                (activeRowChange)="activeRow = $event"
                (rowDblClick)="openMoi($event)"
                #cerrixTableComponent
            >
            </cerrix-table>
        </div>
    </div>
</div>
