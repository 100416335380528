import { Component } from "@angular/core";
import { QuickLinkItemContent } from "@app/dashboard/shared/enums/QuickLinkItemContent.enum";
import { DashboardQuickLinkModel } from "@app/dashboard/shared/models/DashboardQuickLinkModel";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { BaseWidget } from "../BaseWidget";

@Component({
    selector: "form-overview-widget",
    templateUrl: "../link-widget/link-widget.component.html",
    styleUrls: ["../link-widget/link-widget.component.scss"],
})
export class FormOverviewWidgetComponent extends BaseWidget {
    quickLinks: DashboardQuickLinkModel[];
    contentType = QuickLinkItemContent;

    constructor(
        private _ds: FormsDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {
        super();
    }

    async load() {
        const selectedForms = this.getFilter(this.filterKeys.Forms.FormIDs);
        this.config.noData =
            !selectedForms || !Array.isArray(selectedForms) || selectedForms.length === 0;

        if (!this.config.noData) {
            this.config.showLoader = true;
            const forms = await this._ds.getFormWidgetOverview(selectedForms).toPromise();
            this.quickLinks = forms.map((f) => {
                return <DashboardQuickLinkModel>{
                    title: f.Title,
                    page: "" + f.ID,
                    imageUrl: f.ImageGuid
                        ? "/thumbnails/FormsOverview/" + f.ImageGuid + ".png"
                        : "/static/images/SimpleScreen_Editor_Image.jpg",
                    description: f.Description,
                };
            });
        }

        super.load();
    }

    openPage(formid: number) {
        if (isNaN(formid)) {
            return;
        }

        this._tabService.generateTab(this._pages.FormResults, formid, "Results", {
            forceStartEntry: this.getFilter(this.filterKeys.Forms.LinkToNewFormEntry),
        });
    }
}
