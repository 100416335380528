import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { RiskModel } from "@app/risk/models/RiskModel";
import { RiskPermissionModel } from "@app/risk/models/RiskPermissionModel";
import { RiskDetailComponent } from "../risk-detail.component";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { ModuleType } from "@enums/ModuleType";
import { RiskDataService } from "@app/risk/services/RiskDataService";

@Component({
    selector: "risk-controls",
    templateUrl: "./risk-controls.component.html",
    styleUrls: ["./risk-controls.component.scss"],
})
export class RiskControlsComponent {
    @Input() model: RiskModel;
    @Input() disabled = false;
    @Input() permissions: RiskPermissionModel;

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    lastOrganizationId = 0;
    linked: ControlLinkModel[];
    unlinked: ControlLinkModel[];
    riskDetailComponent: RiskDetailComponent;
    moduleType = ModuleType;

    constructor(
        private _riskDataService: RiskDataService,
        private _controlDataService: ControlDataService
    ) {}

    load(rdc: RiskDetailComponent, forceReload = false): void {
        this.riskDetailComponent = rdc;
        if (
            !forceReload &&
            (!this.model ||
                !this.model.organizationId ||
                this.lastOrganizationId === this.model.organizationId)
        ) {
            return;
        } else {
            this._riskDataService.getLinkedControls(this.model.guid).subscribe((value) => {
                this.model.connectedControlIds = value;
            });
        }

        this.isLoading = true;
        this.lastOrganizationId = this.model.organizationId;

        this._controlDataService
            .getLinkableControls(ModuleType.ORM, this.model.id, this.model.organizationId)
            .subscribe((value) => {
                this.loadData(this.model.connectedControlIds, value.sortBy("name", true));
                this.isLoading = false;
            });
    }

    reloadClicked(): void {
        this.load(this.riskDetailComponent, true);
    }

    loadByOrganizationId(organization: number): void {
        this.isLoading = true;
        this.lastOrganizationId = organization;
        this._controlDataService
            .getLinkableControls(ModuleType.ORM, this.model.id, organization)
            .subscribe((value) => {
                this.loadData(this.model.connectedControlIds, value.sortBy("name", true));
                this.isLoading = false;
            });
    }

    linkedChanged(linked: ControlLinkModel[]): void {
        const newRisksList = linked.map((x) => x.id);
        this.model.connectedControlIds = newRisksList.sort((a, b) => a - b);

        const isChanged = this.model.connectedControlIds.isDifferent(
            this.riskDetailComponent.backupModel.connectedControlIds
        );
        TabComponentHelper.toggleTabDirty(
            this.riskDetailComponent.tab,
            this.riskDetailComponent.controlsMenuItemId,
            isChanged
        );
    }

    private loadData(linkedIds: number[], items: ControlLinkModel[]): void {
        const linked = [];
        const unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
