import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "./../shared/shared.module";
import { ReportOverviewComponent } from "./report-overview/report-overview.component";
import { ReportPowerbiComponent } from "./report-powerbi/report-powerbi.component";
import { ReportDataService } from "./services/ReportDataService";

@NgModule({
    imports: [CommonModule, SharedModule.forRoot()],
    declarations: [ReportOverviewComponent, ReportPowerbiComponent],
    exports: [ReportPowerbiComponent],
})
export class ReportModule {
    static forRoot(): ModuleWithProviders<ReportModule> {
        return {
            ngModule: ReportModule,
            providers: [ReportDataService],
        };
    }
}
