<div class="layout" [class.edit-layout]="editMode">
    <div class="left-menu">
        <div class="sorting-loading" *ngIf="editMode && sortLoading">
            <div class="fancy-loader"></div>
            <div class="sort-message">
                <div class="title">{{ sortMessage }}</div>
                <div class="sub-title" *ngIf="config.sortMessageSubtitle">
                    {{ config.sortMessageSubtitle }}
                </div>
            </div>
        </div>
        <div class="detail-page-card">
            <div class="detail-page-card-title">{{ name }}</div>
            <div class="detail-page-card-actions">
                <button class="btn btn-raised" (click)="toggleEditMode()">
                    <i class="fas fa-sort me-2"></i>{{ editMode ? "Complete" : "Change" }} order
                </button>
                <div class="spacer"></div>
                <button
                    type="button"
                    class="btn btn-raised export-button"
                    (click)="exportClick()"
                    [disabled]="isGeneratingExport"
                    title="Export"
                >
                    <i class="fas fa-circle-notch fa-spin" *ngIf="isGeneratingExport"></i>
                    <i class="fas fa-file-excel" aria-hidden="true" *ngIf="!isGeneratingExport"></i>
                </button>
                <ng-container *ngIf="!editMode">
                    <button class="btn btn-raised green" (click)="addNew()">
                        <i class="fas fa-plus me-2"></i>Root
                    </button>
                    <button
                        class="btn btn-raised green"
                        (click)="addNew(selected)"
                        [disabled]="!selected"
                    >
                        <i class="fas fa-plus me-2"></i>Child
                    </button>
                </ng-container>
            </div>
            <div class="detail-page-card-actions search-actions" *ngIf="!editMode">
                <input
                    type="text"
                    placeholder="Search"
                    class="form-control form-control-sm"
                    [(ngModel)]="searchFilter"
                    inputTooltip
                />

                <button class="btn btn-outline-secondary btn-sm search-button" (click)="search()">
                    <i class="fas fa-search"></i>
                </button>
                <button
                    class="btn btn-outline-secondary btn-sm search-reset-button"
                    (click)="resetSearchFilter()"
                >
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="detail-page-card-body" *ngIf="dataLoading">
                <div class="fancy-loader submodule"></div>
            </div>
            <div class="detail-page-card-body cerrix-control-sm" *ngIf="!dataLoading">
                <div class="nodes">
                    <ng-container *ngFor="let node of data">
                        <ng-container
                            *ngTemplateOutlet="
                                nodeTemplate;
                                context: { node: node, parent: null, depth: 0 }
                            "
                        >
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #nodeTemplate let-node="node" let-parent="parent" let-depth="depth">
    <div
        class="node"
        [class.dropzone]="editMode && node.highlight"
        [class.dropabove]="editMode && dropAbove"
        [class.dropbelow]="editMode && dropBelow"
        [class.moving]="editMode && node.show === false"
        [class.just-moved]="editMode && node.newlocation"
        [class.selected]="!editMode && node.ID === selected"
        [ngStyle]="{ 'z-index': depth }"
        [class.filterhighlight]="!editMode && node.highlight"
        [class.filterhide]="!editMode && node.show === false"
        [draggable]="editMode && !sortLoading"
        (dragstart)="dragStart(node, parent, $event)"
        (dragend)="dragStop(node, $event)"
        (dragover)="dragOver($event)"
    >
        <div
            class="node-detail"
            (drop)="dragDrop(node, parent, $event)"
            (dragenter)="dragEnter(node)"
            [title]="node.Name"
        >
            <div class="node-child-toggler">
                <i
                    class="fas fa-angle-{{ node.isExpanded ? 'down' : 'right' }}"
                    (click)="node.isExpanded = !node.isExpanded"
                    *ngIf="node.Children.length > 0"
                ></i>
            </div>
            <div class="node-icon" (click)="itemClick(node)">
                <cerrix-icon-viewer [icon]="node.Icon" appendClass="fa-fw"></cerrix-icon-viewer>
            </div>
            <div class="node-name" (click)="itemClick(node)">{{ node.Name }}</div>
        </div>
        <div class="children" *ngIf="node.isExpanded && node.Children.length > 0">
            <ng-container *ngFor="let child of node.Children">
                <ng-container
                    *ngTemplateOutlet="
                        nodeTemplate;
                        context: { node: child, parent: node, depth: depth + 1 }
                    "
                >
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
