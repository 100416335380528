import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AuditFieldworkStepDataService } from "@app/audit/services/audit-fieldworkstep-data.service";
import { AuditFieldworkStepCommentModel } from "@app/audit/shared/data-models/audit-fieldwork-step-comment-model";
import { AuditFieldworkStepDetailModel } from "@app/audit/shared/data-models/audit-fieldwork-step-detail-model";
import { AuditFieldworkStepWorkflowUpdateModel } from "@app/audit/shared/data-models/audit-fieldwork-step-workflow-update-model";
import { AuditFieldworkWorkflowStepCommentType } from "@app/audit/shared/data-models/AuditFieldworkWorkflowStepCommentType";
import { AuditFieldworkStepDetailConfigModel } from "@app/audit/shared/page-models/audit-fieldwork-step-detail-config-model";
import { AuditFieldworkPermissionModel } from "@app/audit/shared/permission-models/audit-fieldwork-permission-model";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { toPromise } from "@methods/CommonMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, takeUntil } from "rxjs";
import { nameof } from "@methods/jeffs-toolkit";
import { AuditFieldworkStepWorkflowModel } from "@app/audit/shared/data-models/AuditFieldworkStepWorkflowModel";

@Component({
    selector: "audit-fieldwork-step-detail",
    templateUrl: "./audit-fieldwork-step-detail.component.html",
    styleUrls: ["./audit-fieldwork-step-detail.component.scss"],
})
export class AuditFieldworkStepDetailComponent implements OnInit, OnDestroy {
    @Output() onSave = new EventEmitter<never>();

    public static readonly documentTabId = "fieldwork-step-documents";
    nextStatus: string;
    backStatus: string;
    nextStatusRequiredComment: boolean;
    backStatusRequiredComment: boolean;
    readonly destroy$ = new Subject<boolean>();
    get documentTabId() {
        // We cant use the static variable in html so we create a get variable here to pass through the value.
        return AuditFieldworkStepDetailComponent.documentTabId;
    }

    tab: TabModel;
    permissions: AuditFieldworkPermissionModel;
    stepModel: AuditFieldworkStepDetailModel;

    auditGuid: string;
    stepGuid: string;

    @ViewChild(HistoryOverviewComponent)
    private historyOverviewComponent: HistoryOverviewComponent;

    constructor(
        private _ds: AuditFieldworkStepDataService,
        private _promptService: CerrixPromptService,
        private _toastrService: ToastrService,
        private _tabService: TabService
    ) {}

    async ngOnInit(): Promise<void> {
        this.setRequestConfig();

        if (!this.auditGuid || !this.stepGuid) {
            return;
        }

        this.permissions = await toPromise(this._ds.getPermissions(this.auditGuid, this.stepGuid));
        if (!this.permissions || !this.permissions.canView) {
            this._toastrService.warning(
                "Not enough rights to view fieldwork step.",
                "Not enough rights"
            );
            this.tab.close(false);
        }

        this._ds
            .getFieldworkDetails(this.auditGuid, this.stepGuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (model: AuditFieldworkStepDetailModel): void => {
                    this.stepModel = model;
                    this.bindConfigChangeListener();
                    this.tab.showLoader = false;
                    this.handleWorkflow(this.stepModel.workflow);
                },
                error: (): void => {
                    this.tab.close(false);
                },
            });
    }

    public onMenuItemClick(menuItem: TabMenuItem): void {
        if (!menuItem || !menuItem.menuItemId) {
            return;
        }

        switch (menuItem.menuItemId) {
            case "history": {
                this.historyOverviewComponent.loadHistory();
                break;
            }
        }
    }

    async save(): Promise<void> {
        if (!this.permissions.canEdit) {
            return;
        }

        const loader = this._promptService.loader("Saving Fieldwork Step...");
        await this._ds.storeDetails(this.auditGuid, this.stepModel).finally(() => {
            loader.close();
        });
        this.onSave.emit();

        this._toastrService.success("", "Update completed");
    }

    private setRequestConfig(): boolean {
        const castedConfig = this.tab.config
            ? (this.tab.config as AuditFieldworkStepDetailConfigModel)
            : null;

        if (!castedConfig || !castedConfig.auditguid || !castedConfig.stepguid) {
            this._toastrService.error("Request data is missing.", "Could not open fieldwork step.");
            return false;
        }

        this.auditGuid = castedConfig.auditguid;
        this.stepGuid = castedConfig.stepguid;
    }

    private bindConfigChangeListener(): void {
        this._tabService.listeners.removeTabListenerByType(
            this.tab,
            TabEventListenerType.ReloadConfig
        );

        this._tabService.listeners.addGlobalListener(
            TabEventListenerType.ReloadConfig,
            this.tab.lookupname,
            async (newTab: TabModel) => {
                if (this.tab.config.activemenu != newTab.config.activemenu) {
                    const menuItem = this.tab.menu.getTabMenuItemByMenuItemId(
                        newTab.config.activemenu
                    );
                    this.tab.menu.menuItemClicked(menuItem);
                }
            },
            this.tab
        );
    }

    async newCommentDialog(event: any, approve: boolean) {
        event.target.disabled = true;
        try {
            let reloadSkipped = false;

            const openDialogMethod = () => {
                this.openCommentDialog(approve, reloadSkipped);
            };

            await this.save();
            openDialogMethod();
        } finally {
            event.target.disabled = false;
        }
    }

    async openCommentDialog(approve: boolean, reloadAfterdone: boolean) {
        this.openNewDialog(approve)
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (newComment: AuditFieldworkStepCommentModel) => {
                if (newComment) {
                    this.tab.showLoader = true;
                    const workflow = new AuditFieldworkStepWorkflowUpdateModel();
                    workflow.approved = approve;
                    workflow.comment = newComment.comment;
                    workflow.currentStatus = this.stepModel.workflow.status;
                    (await this._ds.updateStatus(this.stepModel.guid, workflow, this.auditGuid))
                        .pipe(takeUntil(this.destroy$))
                        .subscribe({
                            next: (value) => {
                                this._toastrService.success(value.Message);
                                this.tab.refresh();
                            },
                            error: (error) => {
                                this._toastrService.error("", error || error.Message);
                                this.tab.showLoader = false;
                            },
                        });
                } else if (reloadAfterdone) {
                    this.tab.refresh();
                }
            });
    }

    private openNewDialog(approve: boolean): Observable<AuditFieldworkStepCommentModel> {
        const fields: GenericListField[] = [];

        fields.push({
            prettyName: "Comment",
            fieldName: nameof<AuditFieldworkStepCommentModel>((x) => x.comment),
            required: approve ? this.nextStatusRequiredComment : this.backStatusRequiredComment,
            fieldType: GenericListFieldType.TextArea,
        });
        return <Observable<AuditFieldworkStepCommentModel>>this._promptService
            .prompt({
                height: "350px",
                data: {
                    title: "Add comment",
                    fields: fields,
                    closeOnEsc: false,
                    confirmOnEnter: false,
                    confirmButton: {
                        text: "OK",
                    },
                },
            })
            .getResult();
    }

    handleWorkflow(workflow: AuditFieldworkStepWorkflowModel) {
        switch (workflow.status) {
            case AuditFieldworkWorkflowStepCommentType.New: {
                if (this.permissions.canPutInReview) {
                    this.nextStatus = workflow.positiveValue;
                    this.nextStatusRequiredComment = false;
                }
                break;
            }
            case AuditFieldworkWorkflowStepCommentType.InReview: {
                if (this.permissions.canApprove) {
                    this.nextStatus = workflow.positiveValue;
                    this.nextStatusRequiredComment = false;
                }
                if (this.permissions.canReject) {
                    this.backStatus = workflow.negativeValue;
                    this.backStatusRequiredComment = true;
                }
                break;
            }
            case AuditFieldworkWorkflowStepCommentType.Approved:
                if (this.permissions.canReopen) {
                    this.backStatus = workflow.negativeValue;
                    this.backStatusRequiredComment = true;
                }
                break;
            case AuditFieldworkWorkflowStepCommentType.Rejected: {
                if (this.permissions.canPutInReview) {
                    this.nextStatus = workflow.positiveValue;
                    this.nextStatusRequiredComment = true;
                }
                break;
            }
            case AuditFieldworkWorkflowStepCommentType.Reopened: {
                if (this.permissions.canPutInReview) {
                    this.nextStatus = workflow.positiveValue;
                    this.nextStatusRequiredComment = true;
                }
                break;
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
