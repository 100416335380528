import { Type } from "@angular/core";
import { Observable } from "rxjs";
import { StandingDataItemModel } from "../models/standing-data-item.model";
import { BaseStandingDataEditComponent } from "./base-standing-data-edit.component";

export abstract class BaseStandingDataService {
    abstract getAll(): Observable<StandingDataItemModel[]>;
    abstract changeOrder(id: string, order: number): Observable<void>;
    abstract getEditComponent(): Type<BaseStandingDataEditComponent>;
}
