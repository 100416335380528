<div class="fancy-loader" *ngIf="controlModel && isLoading"></div>
<thirdparty-linkable
    *ngIf="controlModel"
    #linkableThirdParty
    typename="third party"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="true"
>
</thirdparty-linkable>
