import { Injectable } from "@angular/core";
import { IncidentPermissionType } from "@enums/permissions/incident-permission-type.enum";
import { BaseMicroservicePermissionService } from "./base-microservice-permission.service";
import { MicroserviceModule } from "@enums/microservice-module.enum";

@Injectable({
    providedIn: "root",
})
export class IncidentsPermissionService extends BaseMicroservicePermissionService<IncidentPermissionType> {
    public microserviceModule: MicroserviceModule = MicroserviceModule.Incidents;

    constructor() {
        super(localStorage.getItem("incidentUrl"));
    }
}
