import { MatIconModule } from "@angular/material/icon";
import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CerrixButtonComponent, CerrixIconButtonComponent } from "@cerrix/components";

@Component({
    selector: "cerrix-confirmation-button",
    templateUrl: "./cerrix-confirmation-button.component.html",
    styleUrls: ["./cerrix-confirmation-button.component.scss"],
    standalone: true,
    imports: [CommonModule, MatIconModule, CerrixIconButtonComponent, CerrixButtonComponent],
    providers: [],
})
export class CerrixConfirmationButtonComponent {
    @Input() public icon: string = "delete";
    @Input() public btnLabel: string;

    @Output() onConfirmationClick = new EventEmitter<void>();

    protected showConfirmation: boolean = false;

    @HostListener("document:click")
    private clickOutside(event: any) {
        this.showConfirmation = false;
    }

    protected onIconClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.showConfirmation = true;
    }

    protected onButtonClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.onConfirmationClick.emit();
        this.showConfirmation = false;
    }
}
