import { Component, Input, Output, ViewEncapsulation } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { CerrixTextInputBase } from "../cerrix-textinput-base";
import { NgControl } from "@angular/forms";

@Component({
    selector: "cerrix-textarea",
    templateUrl: "./cerrix-textarea.component.html",
    styleUrls: ["./cerrix-textarea.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CerrixTextAreaComponent extends CerrixTextInputBase {
    @Input() rows = 3;
    @Input() autoGrowEnabled = false;

    public constructor(control: NgControl, _configuration: Configuration) {
        super(control, _configuration);
        this.control.valueAccessor = this;

        this.multiline = true;
    }
}
