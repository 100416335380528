import { Component, OnInit } from "@angular/core";
import { FilterConfig } from "../../../common/models/workspace/FilterConfig";
import { FilterType } from "../../../common/enums/FilterType";
import { TabService } from "../../../services/tabs/TabService";
import { Pages } from "../../../common/constants/pages/Pages";
import { SearchKeyword } from "../../../common/models/workspace/SearchKeyword";
import { ModuleType } from "../../../common/enums/ModuleType";
import { RendererConfig } from "../../../common/models/workspace/RendererConfig";
import { TabModel } from "../../../common/models/generic/TabModels/TabModel";
import { FormatType } from "common/enums/FormatType";
import { RendererType } from "common/enums/RendererType";
import { EventDataService } from "../services/EventDataService";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { isGuid } from "@methods/uniqueMethods";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { CustomFieldHelper } from "@app/shared/helpers/customField-helper";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { toPromise } from "@methods/CommonMethods";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";

@Component({
    selector: "app-event-overview",
    templateUrl: "./event-overview.component.html",
    styleUrls: ["./event-overview.component.scss"],
})
export class EventOverviewComponent implements OnInit {
    moduleType = ModuleType.LER;
    tabID: string;
    tab: TabModel;
    data: Promise<any[]>;
    headerLookup: any = {};
    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: "OrganizationID",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: "BusinessDimensionIDs",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Period",
            column: "Period",
            type: FilterType.Period,
            param: "",
            remoteFilter: true,
            module: ModuleType.ORM,
        },
    ];

    rendererConfig: RendererConfig[] = [];

    numberProps: string[] = [
        "Nr Of Persons Notified",
        "Number of Data Registers Involved",
        "Minimum number of persons involved",
        "Maximum number of persons involved",
        "Gross costs",
        "Recovery",
        "Net costs",
    ];
    dateProps: string[] = [
        "Date occurred",
        "Date detected",
        "Date registered",
        "Date closed",
        "Date breach occurred",
        "Date end of breach",
        "Date persons involved notified",
    ];
    dateTimeProps: string[] = ["Date breach detected", "Authorities notified date"];
    allDateProps: string[] = [...this.dateProps, ...this.dateTimeProps];

    permissions: WorkspaceModulePermissionModel;

    currentlyLoadedPeriod = null;
    periodFilterToast: ActiveToast<any> = null;

    eventCustomFields: IdNameCombination[] = [];
    databreachCustomFields: IdNameCombination[] = [];

    constructor(
        private _eventDS: EventDataService,
        private standingDataService: StandingdataDataService,
        private _tabs: TabService,
        public pages: Pages,
        private _permissions: PermissionsService,
        private toastr: ToastrService,
        private _settingsService: SettingsDataService
    ) {}

    ngOnInit() {
        this.setRendererConfig();

        this.permissions = this._permissions.permissions.Workspace.event;

        this.data = toPromise(this._eventDS.getEvents()).then(
            async (rows) => await this.mapHeaders(rows)
        );

        this._settingsService.getSetting(ApplicationSettings.UseIncidents).subscribe((setting) => {
            this.permissions.canAdd = this.permissions.canAdd && !setting.BoolValue;
        });
    }

    private async getCustomFields() {
        this.eventCustomFields = (
            await toPromise(this.standingDataService.getAllByType(StandingDataType.LerCustomFields))
        ).filter((x) => x.visible);

        this.databreachCustomFields = (
            await toPromise(
                this.standingDataService.getAllByType(StandingDataType.LerDataBreachCustomFields)
            )
        ).filter((x) => x.visible);
    }

    setRendererConfig() {
        this.dateProps.forEach((dateProp) => {
            const config = <RendererConfig>{
                textColumn: dateProp,
                type: RendererType.Default,
                formatType: FormatType.DateFormat,
            };

            this.rendererConfig.push(config);
        });

        this.dateTimeProps.forEach((dateTimeProp) => {
            const config = <RendererConfig>{
                textColumn: dateTimeProp,
                type: RendererType.Default,
                formatType: FormatType.DateShortTimeFormat,
            };

            this.rendererConfig.push(config);
        });

        this.numberProps.forEach((numberProp) => {
            const config = <RendererConfig>{
                textColumn: numberProp,
                type: RendererType.Default,
                formatType: FormatType.DecimalFormat,
            };

            this.rendererConfig.push(config);
        });
    }

    async openDetail(event: any) {
        if (this.currentlyLoadedPeriod > 0) {
            const response = await this._eventDS.getBaseGuidFromPeriodGuid(event.Guid).toPromise();
            if (isGuid(response)) {
                if (response !== event.Guid) {
                    const msg = `Opening historical items is currently not available. Click here to open '${event["Event name"]}' in the current state.`;
                    this.createPeriodToast("Event not available", msg, () => {
                        this.openTab(response, `(E) Event`);
                    });

                    return;
                }
            } else {
                const msg = `Opening historical items is currently not available.`;
                this.createPeriodToast("Event not available", msg, null);

                return;
            }
        }

        this.openTab(event.Guid, `(E) Event`);
    }

    createPeriodToast(title: string, msg: string, onTab?: () => any) {
        if (this.periodFilterToast) {
            this.periodFilterToast.toastRef.close();
        }

        this.periodFilterToast = this.toastr.info(msg, title);
        if (onTab) {
            this.periodFilterToast.onTap.subscribe(() => {
                onTab();
            });
        }
    }

    add() {
        this.openTab(null, `(E) New Event`);
    }

    private openTab(guid: string, name: string) {
        this._tabs.generateTab(this.pages.EventDetail, guid, name);
    }

    getData(keyWords: SearchKeyword[]) {
        // For now there is always 1 serverfilter, which is the periodId
        this.currentlyLoadedPeriod = null;
        if (keyWords && keyWords.length > 0 && +keyWords[0].searchValue > 0) {
            this.currentlyLoadedPeriod = +keyWords[0].searchValue;
        }

        this.data = this._eventDS
            .getEvents(this.currentlyLoadedPeriod)
            .toPromise()
            .then(this.mapHeaders);
    }

    private mapHeaders = async (rows) => {
        await this.getCustomFields();
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    if (propName === "customFields") {
                        this.addEventsCustomFields(propName, rows);
                    } else if (propName === "databreachCustomFields") {
                        this.addDatabreachCustomFields(propName, rows);
                    } else {
                        this.headerLookup[propName] = propName;
                    }
                }
            });
        }
        // Change the order ot the items, to the headerLookup, so that the Events Custom fields are in the correct place.
        const items = this.reorderColumns(rows);

        return items;
    };

    private reorderColumns(rows) {
        const items = [];

        for (let i = 0; i < rows.length; i++) {
            const dataChange = {};
            const dataOrg = rows[i];
            Object.entries(this.headerLookup).forEach((x) => {
                dataChange[x[0]] = dataOrg[x[0]];
                const key = x[0] + "_Color";
                if (dataOrg[key]) {
                    dataChange[key] = dataOrg[key];
                }
            });
            items[i] = dataChange;
        }

        return items;
    }

    private addEventsCustomFields(fieldName: string, data: Object[]) {
        CustomFieldHelper.expandCustomFields(
            data,
            fieldName,
            this.eventCustomFields,
            this.headerLookup,
            this.rendererConfig
        );
    }

    private addDatabreachCustomFields(fieldName: string, data: Object[]) {
        CustomFieldHelper.expandCustomFields(
            data,
            fieldName,
            this.databreachCustomFields,
            this.headerLookup,
            this.rendererConfig
        );
    }
}
