export enum ModuleType {
    COMMON = 0,
    COMMONDescription = "General",

    ORM = 1,
    ORMDescription = "Risks",

    KRI = 2,
    KRIDescription = "Key Risk Indicators",

    LER = 3,
    LERDescription = "Events",
    LERIncidentDescription = "Incidents",

    Controls = 4,
    ControlsDescription = "Controls",

    BIM = 5,
    BIMDescription = "Measures of Improvement",

    CR = 6,
    CRDescription = "Cerrix Reporting",

    FRM = 7,
    FRMDescription = "Forms",

    CD = 8,
    CDDescription = "Cerrix Documents",

    CI = 9,
    CIDescription = "Cerrix Invite",

    DSB = 11,
    DSBDescription = "Dashboard",

    DM = 12,
    DMDescription = "Data management",

    FR = 13,
    FRDescription = "Finding Reports",

    NAV = 14,
    NAVDescription = "Process management",

    IM = 15,
    IMDescription = "Improvement management",

    TP = 18,
    TPDescription = "Third Party",

    EC = 19,
    ECDescription = "Third party assurance requests",

    AUDIT = 20,
    AUDITDescription = "Audit",

    Tasks = 21,
    TasksDescription = "Tasks",

    // Leave this one for the end of list.
    Admin = 100,
    AdminDescription = "Administration",
}
