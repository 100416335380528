<div id="shapes-sidebar">
    <mat-accordion [multi]="true" [displayMode]="'flat'" *ngIf="graph">
        <mat-expansion-panel
            class="panel"
            [expanded]="isFirst"
            *ngFor="let kv of shapes | keyvalue: keepOrder; first as isFirst; trackBy: trackBy"
        >
            <mat-expansion-panel-header class="header" [collapsedHeight]="'35px'" [expandedHeight]="'35px'">
                <mat-panel-title
                    matTooltip="Click to expand. Drag and drop shapes into the diagram. Shift+click to change selection. Alt+click to insert and connect."
                >
                    {{ kv.key }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content">
                <ng-container *ngFor="let shape of kv.value">
                    <ng-template [ngIf]="shape.useImage" [ngIfElse]="svgTemplate">
                        <img
                            src="{{ shape.image }}"
                            class="shape"
                            #element
                            (Create)="makeDraggable(shape, element)"
                            (click)="onShapeClick($event, shape)"
                            [matTooltip]="shape.name"
                        />
                    </ng-template>
                    <ng-template #svgTemplate>
                        <div
                            class="shape"
                            *ngIf="shape.svg"
                            [innerHTML]="shape.safeSvg"
                            #element
                            (Create)="makeDraggable(shape, element)"
                            (click)="onShapeClick($event, shape)"
                            [matTooltip]="shape.name"
                        ></div>
                    </ng-template>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
