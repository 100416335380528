import { MatrixSelectionModel } from "./MatrixSelectionModel";

export class MatrixModel {
    colors: string[];
    matrix: {};
    impactSize: number;
    likelihoodSize: number;

    summaryContent?: { [key: string]: number };
    matrixContent?: string[][];

    appetiteRange?: MatrixSelectionModel[];

    likelihoodInfo?: MatrixInfoModel[];
    impactInfo?: MatrixInfoModel[];
    customImpactName?: string;
}

export class MatrixCell extends MatrixSelectionModel {
    Color: string;
}

export class MatrixInfoModel {
    ID: number;
    Name: string;
    Description?: string;
}
