<div class="modal-header">
    <h5 class="modal-title">Workflow Definitions</h5>
</div>
<div class="container-fluid">
    <div class="workflow-actions">
        <button class="btn btn-cerrix me-2" (click)="newDefinition()">
            <i class="fas fa-plus-circle"></i>
            <span class="d-none d-lg-inline">Add definition</span>
        </button>
        <select class="custom-select" [(ngModel)]="focusedDefinition">
            <option *ngFor="let def of definitions; let defIndex = index" [value]="defIndex">
                {{ def.Name }}
            </option>
        </select>
    </div>
    <hr />
    <div
        *ngFor="let def of definitions; let defIndex = index"
        [hidden]="defIndex != focusedDefinition"
    >
        <i
            class="fas fa-trash workflow-delete"
            (click)="deleteDefinition()"
            *ngIf="definitions.length > 1"
        ></i>
        <div class="form-group">
            <label for="form-groups">Definition name</label>
            <cerrix-textfield [(ngModel)]="def.Name" cssClass="form-control"> </cerrix-textfield>
        </div>
        <div class="form-group">
            <label for="form-groups">Definition data owner groups</label>
            <ng-select
                [(ngModel)]="def.DefinitionDataOwners"
                id="form-groups"
                [items]="groups"
                bindValue="ID"
                bindLabel="Name"
                [closeOnSelect]="false"
                [multiple]="true"
            >
            </ng-select>
            <small class="text-muted"
                >Users in selected groups get view access to all completed pages/forms created under
                this workflow definition.</small
            >
        </div>
        <mat-accordion multi="true">
            <mat-expansion-panel
                *ngFor="let pageDef of def.PageDefinitions; let pageIndex = index"
                [class.section-start]="pageIndex > 0 && !pageDef.LinkedToPrevious"
            >
                <mat-expansion-panel-header [class.bg-highlight]="form.Pages[pageIndex].Type === 2">
                    <mat-panel-title>
                        Page {{ pageIndex + 1 }} - {{ pageDef.Page.Title }}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ pageDef.Page.Type === pageType.Entry ? "Entry page" : "Review page" }}
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div
                    *ngIf="
                        pageIndex > 0 &&
                        pageDef.Page.Type === 1 &&
                        form.Pages[pageIndex - 1].Type === 1 &&
                        !pageDef.LinkedToStarter &&
                        (!pageDef.Groups || pageDef.Groups.length === 0)
                    "
                >
                    <mat-checkbox color="primary" [(ngModel)]="pageDef.LinkedToPrevious">
                        Link to previous page
                    </mat-checkbox>
                    <br />
                    <small
                        >Linking to previous will allow the previous user to also answer the fields
                        on this page.</small
                    >
                    <br />
                </div>

                <div
                    *ngIf="
                        pageIndex !== 0 &&
                        pageDef.Page.Type === 1 &&
                        !pageDef.LinkedToPrevious &&
                        (!pageDef.Groups || pageDef.Groups.length === 0)
                    "
                >
                    <mat-checkbox color="primary" [(ngModel)]="pageDef.LinkedToStarter">
                        Same as entry starter
                    </mat-checkbox>
                    <br />
                    <small
                        >This will skip the user selection screen and link the page to the user that
                        started the form.</small
                    >
                    <br />
                </div>

                <div
                    class="form-group"
                    *ngIf="!pageDef.LinkedToStarter && !pageDef.LinkedToPrevious"
                >
                    <label for="form-groups">Groups</label>
                    <ng-select
                        [(ngModel)]="pageDef.Groups"
                        id="form-groups"
                        [items]="groups"
                        [multiple]="true"
                        bindValue="ID"
                        bindLabel="Name"
                        [closeOnSelect]="false"
                        (change)="setGroup(pageDef, $event)"
                    >
                    </ng-select>
                    <div
                        class="alert alert-danger small-alert system-required"
                        role="alert"
                        *ngIf="groups && groups.length == 0"
                    >
                        No User Groups found. Please create User Groups first.
                    </div>
                    <small
                        >Selecting a group will mark the start of a user 'section'. In cases of
                        consecutive entry pages the next page will have the option to link it to
                        this page.</small
                    >
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSaveChanges()">Apply changes</button>
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
</div>
