import { Component } from "@angular/core";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { BaseWidget } from "./../BaseWidget";
import { WorkspaceCountService } from "@app/shared/services/workspace/workspace-count.service";
import { firstValueFrom } from "rxjs";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "module-detail-widget",
    templateUrl: "./module-detail-widget.component.html",
    styleUrls: ["./module-detail-widget.component.scss"],
})
export class ModuleDetailWidgetComponent extends BaseWidget {
    invalidConfig = false;

    count: number;

    itemName: string;
    presetName: string;
    pageName: string;

    customTitle?: string;

    constructor(
        private _ds: WorkspaceCountService,
        private _tabService: TabService,
        private _pages: Pages
    ) {
        super();
    }

    _loadTimeout: any;
    load() {
        this.config.showLoader = true;

        if (this._loadTimeout) {
            clearTimeout(this._loadTimeout);
        }

        // Wait for 500ms before loading the widget (to prevent multiple calls to the API)
        this._loadTimeout = setTimeout(() => {
            this._loadTimeout = null;
            this.initDetails().then(() => {
                super.load();
            });
        }, 500);
    }

    openWorkspace() {
        this.onGlobalOpenClick();
    }

    protected onGlobalOpenClick() {
        if (this.invalidConfig) {
            return;
        }

        const presetId = this.getFilter(WidgetFilterKey.ModuleDetail.WorkspacePreset);
        this._tabService.generateTab(this._pages[this.pageName], null, "", {
            presetID: presetId,
        });
    }

    private async initDetails() {
        const configuredModule = this.getFilter(WidgetFilterKey.ModuleDetail.Module);
        const configuredPreset = this.getFilter(WidgetFilterKey.ModuleDetail.WorkspacePreset);

        this.customTitle = this.getFilter(WidgetFilterKey.ModuleDetail.CustomTitle);

        if (!configuredModule) {
            this.invalidConfig = true;
            return;
        }

        const moduleConfig = (await firstValueFrom(this._ds.getAvailableModules())).find(
            (x) => x.presetCode == configuredModule
        );
        this.itemName = moduleConfig.itemName;
        this.presetName = moduleConfig.presets.find((x) => x.ID == configuredPreset)?.Name;
        this.pageName = moduleConfig.pageName;

        const result = await firstValueFrom(this._ds.getCount(configuredModule, configuredPreset));

        this.count = result.count;
        this.invalidConfig = false;
    }
}
