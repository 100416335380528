import { SharedModule } from "@app/shared/shared.module";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { AuthenticationComponent } from "./authentication.component";
import { LoginComponent } from "./login/login.component";
import { UserAuthenticationService } from "./shared/user-authentication.service";
import { LoginModalComponent } from "./login-modal/login-modal.component";

@NgModule({
    imports: [FormsModule, CommonModule, SharedModule],
    declarations: [AuthenticationComponent, LoginComponent, LoginModalComponent],
    exports: [LoginComponent, LoginModalComponent],
})
export class AuthenticationModule {
    static forRoot(): ModuleWithProviders<AuthenticationModule> {
        return {
            ngModule: AuthenticationModule,
            providers: [UserAuthenticationService],
        };
    }
}
