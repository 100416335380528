import { Pipe, PipeTransform } from '@angular/core';
import { MoiCommentType } from '..';

@Pipe({
  name: 'moiCommentType'
})
export class MoiCommentTypePipe implements PipeTransform {

  transform(commentType: MoiCommentType): string {
    if (!commentType) {
      return "Unspecified";
    }

    return MoiCommentType[commentType].toString().replace(/([A-Z])/g, ' $1');
  }

}
