<h1 mat-dialog-title>{{ isLoaded ? "Select" : "Loading" }} Source of MoI</h1>
<div mat-dialog-content style="min-height: 200px">
    <div class="fancy-loader" *ngIf="!isLoaded"></div>
    <div *ngIf="isLoaded">
        <ng-select
            data-uiid="moi-sourceselector-select"
            [items]="moiSources"
            bindLabel="Value"
            bindValue="Key"
            [(ngModel)]="selectedSource"
            placeholder="Select moi type"
            (change)="setSourceData()"
            [clearable]="false"
        >
        </ng-select>
        <input
            class="form-control text-center my-2"
            [(ngModel)]="textFilter"
            (debounce-input-event)="this.filterData()"
            placeholder="Search / filter"
            [disabled]="!allData || allData.length == 0"
            inputTooltip
        />
        <div
            *ngIf="!allData || allData.length === 0 || !filteredData || filteredData.length == 0"
            class="text-center mt-2"
        >
            No data found matching source and filter(s).
        </div>
        <table
            data-uiid="moi-sourceselector-table"
            class="cerrix-table moi-source-table bordered hoverable striped rounded"
            *ngIf="allData && allData.length > 0 && filteredData && filteredData.length > 0"
        >
            <thead>
                <tr>
                    <th
                        *ngFor="let column of columnInfo"
                        (click)="setSort(column.renderer.textColumn)"
                        [style.width]="column.renderer.columnWidth + 'px'"
                    >
                        {{ column.header }}
                        <i
                            *ngIf="column.renderer.textColumn == sortColumn"
                            class="fas fa-sort-amount-{{ sortAsc ? 'down' : 'up' }}"
                        ></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let row of currentData"
                    [class.highlighted]="selectedGuid == row[sourceGuidColumn]"
                    (click)="selectedGuid = row[sourceGuidColumn]"
                >
                    <td class="text-truncate" *ngFor="let column of columnInfo">
                        <workspace-cell
                            [renderer]="column.renderer"
                            [row]="row"
                            [width]="column.renderer.columnWidth"
                        ></workspace-cell>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav
            class="mt-2"
            aria-label="source-table-navigation"
            *ngIf="filteredData && filteredData.length > 0"
        >
            <ul class="pagination justify-content-center">
                <li
                    class="page-item"
                    [class.disabled]="currentPage == 0"
                    (click)="setPagination(currentPage - 1)"
                >
                    <span class="page-link" aria-label="Previous">Previous</span>
                </li>
                <li class="page-item disabled">
                    <span class="page-link">Page {{ currentPage + 1 }} of {{ pageCount }}</span>
                </li>
                <li
                    class="page-item"
                    [class.disabled]="currentPage == pageCount - 1"
                    (click)="setPagination(currentPage + 1)"
                >
                    <span class="page-link" aria-label="Next">Next</span>
                </li>
            </ul>
        </nav>
    </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end" *ngIf="isLoaded">
    <button mat-raised-button (click)="close(true)">
        <i class="fas fa-times"></i>
        <span class="d-none d-lg-inline ms-2">Close</span>
    </button>
    <button
        data-uiid="moi-sourceselector-linksource"
        mat-raised-button
        color="primary"
        (click)="close()"
        [disabled]="!selectedGuid"
    >
        <i class="fas fa-link"></i>
        <span class="d-none d-lg-inline ms-2">Link source</span>
    </button>
</div>
