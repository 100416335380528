<div
    class="cerrix-select-tree form-control"
    [class.ng-valid]="control && control.valid"
    [class.ng-invalid]="(control && !control.disabled && !control.valid) || this.customInvalid"
    [class.ng-pending]="control && control.pending"
    [class.ng-pristine]="control && control.pristine"
    [class.ng-dirty]="control && control.dirty"
    [class.ng-untouched]="control && control.untouched"
    [class.ng-touched]="(control && control.touched) || this.customTouched"
    [class.singleselect]="!multiselect"
    [class.editable]="!readonly"
    [attr.disabled]="readonly"
    (click)="openSingleSelectionIfApplicable()"
>
    <!-- This hidden input type is so we can trigger ng-invalid on non formcontrol binding -->
    <input type="text" class="hidden-blur-input" (blur)="onCustomInputBlur()" />

    <div class="multi-select-actions" *ngIf="multiselect">
        <button class="btn" title="Expand all" (click)="expandAll(selectionTree)">
            <i class="fal fa-plus-square fa-fw expand-btn"></i>
        </button>

        <button class="btn" title="Collapse all" (click)="collapseAll(selectionTree)">
            <i class="fal fa-minus-square fa-fw collapse-btn"></i>
        </button>

        <div class="filler"></div>

        <button class="btn edit-tree-btn" *ngIf="!readonly && !loading" (click)="openSelection()">
            <span>
                <i class="fas fa-edit"></i>
                <span class="d-none d-md-inline ms-2">Edit Selection</span>
            </span>
        </button>
    </div>

    <div
        class="tree-selection"
        [class.single-node]="!multiselect"
        [ngStyle]="{ 'max-height': maxLines * 35 + 'px' }"
    >
        <ng-container *ngIf="!selectionTree || selectionTree.length == 0">
            <span class="no-selection placeholder" *ngIf="!loading">{{
                placeholder ? placeholder : "No selection made"
            }}</span>
            <span class="no-selection" *ngIf="loading">
                <i class="fas fa-circle-notch fa-spin"></i>
                <span class="ms-2">Loading...</span>
            </span>
        </ng-container>

        <div class="nodes current-selection" *ngIf="selectionTree && selectionTree.length > 0">
            <ng-container *ngFor="let node of selectionTree">
                <ng-container
                    *ngTemplateOutlet="
                        nodeTemplate;
                        context: {
                            node: node,
                            selection: null,
                            parent: null,
                            depth: 0,
                            fullpath: !multiselect
                        }
                    "
                ></ng-container>
            </ng-container>
        </div>
    </div>

    <div class="single-select-actions" *ngIf="!multiselect && !readonly && !loading">
        <button class="btn edit-tree-btn" title="Change selection">
            <span>
                <i class="fas fa-edit"></i>
            </span>
        </button>
    </div>
</div>

<ng-template #treeStructure>
    <div class="action-bar">
        <button id="tree-expand-all" class="btn" title="Expand all" (click)="expandAll(popupTree)">
            <i class="fal fa-plus-square fa-fw expand-btn"></i>
        </button>

        <button
            id="tree-expand-selected"
            class="btn"
            title="Expand to selected"
            (click)="expandSelected(popupTree)"
        >
            <i class="fal fa-check-square fa-fw expand-btn"></i>
        </button>

        <button
            id="tree-collapse-all"
            class="btn"
            title="Collapse all"
            (click)="collapseAll(popupTree)"
        >
            <i class="fal fa-minus-square fa-fw collapse-btn"></i>
        </button>

        <ng-container>
            <div class="vertical-seperator"></div>

            <button
                *ngIf="multiselect"
                id="tree-select-all"
                class="btn green"
                title="Select all"
                (click)="selectAll(popupTree)"
            >
                <i class="fal fa-check-double fa-fw select-all-btn"></i>
            </button>

            <button
                id="tree-deselect-all"
                class="btn red"
                title="Deselect all"
                (click)="deselectAll(popupTree)"
            >
                <i class="fal fa-times fa-fw deselect-all-btn"></i>
            </button>

            <div class="vertical-seperator"></div>

            <button
                *ngIf="multiselect"
                id="tree-cascade-toggle"
                class="btn cascade-toggle"
                title="Apply toggle action to all underlying items if selected."
                (click)="toggleCascadeChildren()"
            >
                <span>
                    <input class="form-check-input" type="checkbox" [checked]="cascadeChildren" />
                    <span class="ms-2">Include children</span>
                </span>
            </button>
        </ng-container>

        <div class="filler"></div>

        <div class="filter-action">
            <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Search / Filter"
                [(ngModel)]="searchFilter"
                (debounce-input-event)="filterPopupTree(popupTree)"
            />

            <button
                class="btn red ms-2"
                title="Clear filter"
                [disabled]="!searchFilter"
                (click)="resetFilterPopupTree(popupTree)"
            >
                <i class="fal fa-times fa-fw"></i>
            </button>
        </div>
    </div>

    <div class="nodes" [attr.data-multi-select]="multiselect ? 'true' : 'false'">
        <ng-container *ngFor="let node of popupTree">
            <ng-container
                *ngTemplateOutlet="
                    nodeTemplate;
                    context: { node: node, selection: popupSelection, parent: null, depth: 0 }
                "
            ></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template
    #nodeTemplate
    let-node="node"
    let-selection="selection"
    let-parent="parent"
    let-depth="depth"
    let-fullpath="fullpath"
>
    <div
        class="node"
        [attr.data-name]="node.Name"
        [class.filterhighlight]="node.highlight"
        [class.filterhide]="node.show === false"
        [class.root-node]="depth == 0"
    >
        <div class="node-detail" [title]="node.ID_Path ? node.ID_Path : node.Name">
            <div class="node-child-toggler" (click)="toggleExpand(node)">
                <i
                    class="fas fa-angle-{{ node.isExpanded ? 'down' : 'right' }}"
                    *ngIf="node.Children && node.Children.length > 0"
                ></i>
            </div>

            <span class="node-name" [class.selectable]="!readonly" (click)="toggleNode(node)">
                <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="selection.indexOf(node.ID) >= 0"
                    [class.indeterminate]="node.ChildSelected"
                    *ngIf="selection && multiselect"
                />
                <input
                    class="form-check-input"
                    type="radio"
                    [checked]="selection.indexOf(node.ID) >= 0"
                    *ngIf="selection && !multiselect"
                />

                <span class="form-check-label ms-2">
                    <cerrix-icon-viewer
                        [icon]="node.Icon"
                        appendClass="fa-fw me-1"
                    ></cerrix-icon-viewer>
                    <span class="node-name-text">{{ fullpath ? node.ID_Path : node.Name }}</span>
                </span>
            </span>
        </div>

        <div class="children" *ngIf="node.isExpanded && node.Children && node.Children.length > 0">
            <ng-container *ngFor="let child of node.Children">
                <ng-container
                    *ngTemplateOutlet="
                        nodeTemplate;
                        context: {
                            node: child,
                            selection: selection,
                            parent: node,
                            depth: depth + 1
                        }
                    "
                >
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
