import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "../../app.constants";
import { MailMessageModel } from "../models/mailMessageModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { ModuleType } from "@enums/ModuleType";

@Injectable()
export class MailMessageService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "mailmessages");
    }

    getData(module: ModuleType) {
        const url = `?module=${module}`;
        return this.get<MailMessageModel[]>(url);
    }

    getMailMessage(id: number) {
        const url = "/" + id;
        return this.get<MailMessageModel>(url);
    }

    getOrganizations() {
        const url = "/organizations";
        return this.get<any[]>(url);
    }

    saveMailMessage(mailMessage: MailMessageModel) {
        return this.post(mailMessage);
    }
}
