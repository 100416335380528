<h3 class="mb-2">API Usage</h3>

<h5>Base URI</h5>
<p class="no-indent">
    <span>
        <span class="d-inline">{{ apiUrl }}</span>
    </span>
</p>

<h5>Authentication</h5>
<p>
    <strong> Basic Authentication </strong>
    <span>
        CERRIX currently only supports basic authentication. Basic authentication can be used by
        encoding the "Identifier:Password" combination as Base64 and prefixing it with "Basic ".
    </span>
</p>
<p>
    <strong> Example </strong>
    <span>
        <pre class="example-block">
Identifier = "abc"
Password = "123"
Base64 = "YWJjOjEyMw==" <-- (Base64("abc:123"))
================================================
Authentication = "Basic YWJjOjEyMw=="</pre
        >
    </span>
</p>

<h5>HTTP Methods</h5>
<p>
    <strong> GET </strong>
    <span> Used to retrieve data. </span>
</p>
<p>
    <strong> POST </strong>
    <span> Used to store and update data. </span>
</p>
<p>
    <strong> Delete </strong>
    <span> Used to delete data. </span>
</p>
