.matrix-with-summary {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;

    .cell-value {
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 100%;
    }

    .summary-matrix {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 30px;
        margin-bottom: 20px;
        margin-left: 50px;

        .summary-cell {
            height: 30px;

            &.clickable {
                cursor: pointer;
            }
        }
    }

    .matrix {
        display: flex;
        justify-content: center;
    }
}
