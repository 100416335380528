import { Component } from "@angular/core";
import { ExportDataService } from "@app/export/services/export-data.service";
import { MoiExportConfigModel } from "@app/export/shared/models/moi-export-config-model";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleType } from "@enums/ModuleType";
import { nameof } from "@methods/jeffs-toolkit";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { map } from "rxjs/operators";
import { ExportBase } from "../export-base";
import { ExportType } from "./../../../../common/enums/ExportType";
import { MoiTypes } from "./../../../../common/enums/moi/MoiTypes";
import { IdNameCombination } from "./../../../../common/models/generic/IdNameCombination";

@Component({
    selector: "export-bim",
    templateUrl: "./export-bim.component.html",
    styleUrls: ["./export-bim.component.scss"],
})
export class ExportBimComponent extends ExportBase {
    public isBimAdmin;

    constructor(
        exportDataService: ExportDataService,
        private _ds: MoiDataService,
        public permService: PermissionsService,
        private cerrixPrompt: CerrixPromptService,
        private sdDataService: StandingdataDataService
    ) {
        super(exportDataService);
        this.isBimAdmin = permService.permissions.IsBimAdmin;
    }

    moiExport() {
        var fields = this.getFields([
            this.fieldName((x) => x.moiTypes),
            this.fieldName((x) => x.reportTypes),
            this.fieldName((x) => x.includeDeletedandClosedmois),
        ]);

        this.showExportPopup("MoIs Export", null, fields, ExportType.Mois);
    }

    moiSummaryExport() {
        var fields = this.getFields([
            this.fieldName((x) => x.moiTypes),
            this.fieldName((x) => x.reportTypes),
            this.fieldName((x) => x.includeDeletedandClosedmois),
        ]);

        this.showExportPopup("MoI Summary Export", null, fields, ExportType.MoiSummary);
    }

    moiCommentsExport() {
        var fields = this.getFields([
            this.fieldName((x) => x.moiTypes),
            this.fieldName((x) => x.reportTypes),
            this.fieldName((x) => x.includeDeletedandClosedmois),
        ]);

        this.showExportPopup("MoI and Comments Export", null, fields, ExportType.MoiComments);
    }

    findingReportAssessmentExport() {
        var fields = this.getFields([this.fieldName((x) => x.reportTypes)]);

        this.showExportPopup(
            "Finding reports & assessments",
            null,
            fields,
            ExportType.FindingReportsAndAssessments
        );
    }

    findingReportInformationExport() {
        var fields = this.getFields([
            this.fieldName((x) => x.organizations),
            this.fieldName((x) => x.reportTypes),
            this.fieldName((x) => x.findingReports),
        ]);

        this.showExportPopup(
            "Finding report information Export",
            null,
            fields,
            ExportType.FindingReportInformation
        );
    }

    showExportPopup(
        title: string,
        message: string,
        fields: GenericListField[],
        exportype: ExportType
    ): void {
        this.cerrixPrompt
            .prompt({
                height: "500px",
                data: {
                    title: title,
                    message: message,
                    fields: fields,

                    confirmButton: {
                        text: "Download",
                        icon: "fad fa-cloud-download",
                    },
                },
            })
            .toPromise()
            .then((result: MoiExportConfigModel) => {
                if (!result) {
                    return;
                }

                const extraString = this.toRequestQuery(result);
                this.download(exportype, extraString);
            });
    }

    private fieldName(nameFunction: (obj: MoiExportConfigModel) => any) {
        return nameof<MoiExportConfigModel>(nameFunction);
    }

    private getFields(fieldNames: string[]): GenericListField[] {
        const requested = (field: string) => {
            return fieldNames.indexOf(field) >= 0;
        };

        var fields: GenericListField[] = [];

        if (requested(this.fieldName((x) => x.organizations))) {
            fields.push({
                prettyName: "Organizations",
                fieldName: this.fieldName((x) => x.organizations),
                fieldType: GenericListFieldType.MultiTree,
                required: true,
                selfInit: true,
                getDataMethod: () => {
                    return this.sdDataService.getOrganizations(ModuleType.BIM);
                },
            });
        }

        if (requested(this.fieldName((x) => x.moiTypes))) {
            fields.push({
                prettyName: "MoI Types",
                fieldName: this.fieldName((x) => x.moiTypes),
                fieldType: GenericListFieldType.MultiSelect,
                placeholder: "No filter",
                selfInit: true,
                getDataMethod: () => {
                    return this._ds
                        .getExportableMoiTypes()
                        .pipe(map((x) => x.map((i) => new IdNameCombination(i.value, i.label))));
                },
            });
        }

        if (requested(this.fieldName((x) => x.reportTypes))) {
            fields.push({
                prettyName: "Report Types",
                fieldName: this.fieldName((x) => x.reportTypes),
                fieldType: GenericListFieldType.MultiSelect,
                placeholder: "No filter",
                selfInit: true,
                getDataMethod: () => {
                    return this._ds
                        .getExportableReportTypes()
                        .pipe(map((x) => x.map((i) => new IdNameCombination(i.value, i.label))));
                },
            });
        }

        if (requested(this.fieldName((x) => x.findingReports))) {
            fields.push({
                prettyName: "Finding Reports",
                fieldName: this.fieldName((x) => x.findingReports),
                fieldType: GenericListFieldType.MultiSelect,
                placeholder: "No filter",
                selfInit: true,
                getDataMethod: () => {
                    return this._ds
                        .getExportableFindingReports()
                        .pipe(map((x) => x.map((i) => new IdNameCombination(i.value, i.label))));
                },
            });
        }

        if (requested(this.fieldName((x) => x.includeDeletedandClosedmois))) {
            fields.push({
                prettyName: "Include closed MoIs from previous BIM Periods",
                fieldName: this.fieldName((x) => x.includeDeletedandClosedmois),
                fieldType: GenericListFieldType.CheckBox,
                hideFillers: true,
                selfInit: true,
            });
        }

        return fields;
    }

    private toRequestQuery(model: MoiExportConfigModel): string {
        const stringSections: string[] = [];
        if (model.includeDeletedandClosedmois) {
            stringSections.push("includedeletedandclosedmois=true");
        }

        if (model.organizations && model.organizations.length > 0) {
            stringSections.push("organizations=" + model.organizations.join(";"));
        }

        if (model.moiTypes && model.moiTypes.length > 0) {
            stringSections.push("moiTypes=" + model.moiTypes.join(";"));
        }

        if (model.reportTypes && model.reportTypes.length > 0) {
            stringSections.push("reportTypes=" + model.reportTypes.join(";"));
        }

        if (model.findingReports && model.findingReports.length > 0) {
            stringSections.push("findingReports=" + model.findingReports.join(";"));
        }

        const extraString = stringSections.length > 0 ? stringSections.join("&") : "";
        return extraString;
    }
}
