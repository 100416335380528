<div class="fancy-loader" *ngIf="!loaded"></div>
<form [formGroup]="formGroup" *ngIf="loaded">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Scope</div>
        <div class="detail-page-card-body form-group-styling scope-detail">
            <div class="col-12 col-xl-6 left-block">
                <div class="form-group">
                    <label for="organizationSelect">Organizations</label>
                    <cerrix-select-tree
                        #orgTree
                        [tree]="standingData.organizations"
                        [multiselect]="true"
                        [maxLines]="4"
                        formControlName="organizationIds"
                        data-uiid="audit-organization-select"
                    >
                    </cerrix-select-tree>
                </div>

                <div class="form-group">
                    <label for="bdSelect">Business Dimensions</label>
                    <cerrix-select-tree
                        #businessTree
                        [tree]="standingData.businessDimensions"
                        [multiselect]="true"
                        [maxLines]="4"
                        formControlName="businessDimensionIds"
                        dataUiid="audit-businessdimension-select"
                    >
                    </cerrix-select-tree>
                </div>
                <div class="form-group">
                    <label for="fdSelect">Framework Dimensions</label>
                    <cerrix-select-tree
                        #businessTree
                        [tree]="standingData.frameworkDimensions"
                        [multiselect]="true"
                        [maxLines]="4"
                        formControlName="frameworkDimensionIds"
                        dataUiid="audit-frameworkdimension-select"
                    >
                    </cerrix-select-tree>
                </div>
            </div>
            <div class="col-12 col-xl-6 right-block">
                <generic-list-manager
                    [config]="auditUniverseConfig"
                    *ngIf="auditUniverseConfig"
                ></generic-list-manager>
            </div>
        </div>
    </div>
    <div class="detail-page-card" *ngIf="auditModel.id > 0">
        <div class="detail-page-card-title">Custom fields</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <cerrix-custom-fields-editor
                [parentForm]="formGroup"
                [customFieldData]="customFieldData"
                [singleColumn]="false"
                emptyMessage="No context fields are defined for this Audit Type."
            >
            </cerrix-custom-fields-editor>
        </div>
    </div>
</form>
