import { Component, Input, OnInit } from "@angular/core";
import { TaskSeriesModel } from "@app/tasks/models/task-series-model";
import { TaskSeriesDataService } from "@app/tasks/services/task-series-data.service";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";

@Component({
    selector: "task-series-controls",
    templateUrl: "./task-series-controls.component.html",
})
export class TaskSeriesControlsComponent {
    @Input() model: TaskSeriesModel;
    @Input() disabled = false;

    linked: ControlLinkModel[];
    unlinked: ControlLinkModel[];
    isLoading = true;
    constructor(private _ds: TaskSeriesDataService) {}

    load() {
        if (!this.model) {
            return;
        }

        this._ds.getLinkableControls(this.model.guid).subscribe((value) => {
            this.loadData(this.model.linkedIds, value.sortBy("name", true));
            this.isLoading = false;
        });
    }

    linkedChanged(newList: ControlLinkModel[]) {
        this.model.linkedIds = newList.map((x) => x.id);
    }

    private loadData(linkedIds: number[], items: ControlLinkModel[]) {
        const linked = [],
            unlinked = [];

        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }
        this.linked = linked;
        this.unlinked = unlinked;
    }
}
