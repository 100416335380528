<div class="link-page row" *ngIf="linked && unlinked" #page>
    <div
        class="linked-items col-6"
        [class.col-12]="!showLinkPanel"
        data-uiid="section-linked-items"
    >
        <div
            class="detail-page-card"
            [class.no-links]="!allowAddNew && !showBatchButton && !showLinkPanel && disabled"
        >
            <div class="detail-page-card-title">Linked {{ typename }}</div>
            <div class="detail-page-card-actions">
                <button class="btn btn-raised green" (click)="addClicked()" *ngIf="allowAddNew">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add new {{ singularTypename }}</span>
                </button>
                <button class="btn btn-raised" (click)="batchClicked()" *ngIf="showBatchButton">
                    <i class="fas fa-pencil-alt"></i>
                    <span>Edit linked {{ typename }}</span>
                </button>
                <button
                    class="btn btn-raised"
                    (click)="showLinkPanel = !showLinkPanel"
                    *ngIf="!disabled && !hidePanelToggle"
                >
                    <i class="{{ showLinkPanel ? 'fas fa-check-square' : 'far fa-square' }}"></i>
                    <span>{{ showLinkPanel ? "Hide" : "Show" }} linkable {{ typename }}</span>
                </button>
                <button class="btn btn-raised" (click)="reloadClicked()" *ngIf="showReloadButton">
                    <i class="far fa-sync-alt"></i>
                    <span>Reload</span>
                </button>
            </div>
            <div class="detail-page-card-body">
                <ul
                    class="list-group list-group-flush"
                    [sortablejs]="linked"
                    [sortablejsOptions]="sortingOptions"
                >
                    <li
                        class="list-group-item list-group-item-action py-2"
                        *ngFor="let item of linked"
                    >
                        <div class="d-flex w-100 justify-content-between">
                            <h6
                                class="text-truncate me-2 mb-0"
                                [matTooltip]="item[displayValueProp]"
                            >
                                <i
                                    *ngIf="
                                        item.linkableDisplayItems &&
                                        item.linkableDisplayItems.length > 0
                                    "
                                    (click)="expandItem(item); $event.stopPropagation()"
                                    class="far fa-{{
                                        item._isExpanded ? 'minus' : 'plus'
                                    }}-square group-expander"
                                ></i>
                                <span *ngIf="useIcons">
                                    <i
                                        *ngFor="let rowIcon of item.rowIcons"
                                        class="{{ rowIcon }} ms-2 rowIcon"
                                    >
                                    </i>
                                </span>
                                <span data-uiid="text-item">
                                    {{ item[displayValueProp] }}
                                </span>
                            </h6>
                            <h6 class="group-item-actions mb-0">
                                <i
                                    *ngIf="openItemEnabled && editItemEnabled"
                                    class="fas fa-pencil-alt"
                                    (click)="
                                        itemDoubleClick(item, false, false);
                                        $event.stopPropagation()
                                    "
                                ></i>

                                <i
                                    *ngIf="openItemEnabled"
                                    class="fas fa-external-link-alt open-item-link ms-2"
                                    matTooltip="Open in new tab"
                                    (click)="openItem(item); $event.stopPropagation()"
                                    data-uiid="button-open-item"
                                ></i>

                                <i
                                    *ngIf="!disabled && showLinkPanel"
                                    (click)="toggleItem(item, false, true)"
                                    class="fas fa-arrow-right ms-2"
                                    data-uiid="button-unlink-item"
                                ></i>
                            </h6>
                        </div>
                        <div
                            class="item-expand"
                            *ngIf="
                                item._isExpanded &&
                                item.linkableDisplayItems &&
                                item.linkableDisplayItems.length > 0
                            "
                        >
                            <table class="cerrix-table bordered-columns">
                                <tbody>
                                    <tr *ngFor="let prop of item.linkableDisplayItems">
                                        <td>
                                            <b>{{ prop.Header }}</b>
                                        </td>
                                        <td class="text-truncate">
                                            <workspace-cell
                                                [configs]="rendererConfig"
                                                [row]="item.displayItemRow"
                                                [header]="prop.Header"
                                            >
                                            </workspace-cell>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="detail-page-footer text-center">
                <div class="list-empty-text p-1" *ngIf="!linked || linked.length == 0">
                    There are no linked {{ typename }}
                </div>
                <span *ngIf="linked && linked.length > 0"
                    >{{ linked.length }} linked {{ typename }}</span
                >
            </div>
        </div>
    </div>

    <div class="linkable-items col-6" [hidden]="!showLinkPanel" data-uiid="section-linkable-items">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Linkable {{ typename }}</div>
            <div class="detail-page-card-actions">
                <input
                    type="text"
                    class="form-control form-control-sm me-2"
                    placeholder="Filter"
                    (debounce-input-event)="quickFilter()"
                    [(ngModel)]="filterText"
                    *ngIf="!hideFilter"
                    inputTooltip
                />
                <div class="spacer"></div>
                <button
                    class="btn btn-outline-secondary form-control-sm me-2"
                    (click)="showFilterConfig()"
                    matTooltip="Show advanced filters"
                    *ngIf="showAdvancedFilters"
                >
                    <i class="fas fa-filters" aria-hidden="true"></i>
                </button>
                <button
                    class="btn btn-outline-secondary form-control-sm"
                    matTooltip="Clear filters"
                    (click)="clearFilters()"
                    *ngIf="showAdvancedFilters"
                >
                    <i class="fas fa-filter-circle-xmark" aria-hidden="true"></i>
                </button>
            </div>
            <div class="detail-page-card-body">
                <ul
                    class="list-group list-group-flush"
                    [sortablejs]="unlinked"
                    [sortablejsOptions]="sortingOptions"
                >
                    <li
                        class="list-group-item list-group-item-action py-2"
                        *ngFor="let item of linkablePage"
                    >
                        <div class="d-flex w-100 justify-content-between">
                            <h6
                                class="text-truncate me-2 mb-0"
                                [matTooltip]="item[displayValueProp]"
                            >
                                <i
                                    class="fas fa-arrow-left me-2"
                                    (click)="toggleItem(item, true, true)"
                                    data-uiid="button-link-item"
                                ></i>

                                <i
                                    *ngIf="
                                        item.linkableDisplayItems &&
                                        item.linkableDisplayItems.length > 0
                                    "
                                    (click)="expandItem(item); $event.stopPropagation()"
                                    class="far fa-{{
                                        item._isExpanded ? 'minus' : 'plus'
                                    }}-square group-expander"
                                ></i>

                                <span *ngIf="useIcons">
                                    <i
                                        *ngFor="let rowIcon of item.rowIcons"
                                        class="{{ rowIcon }} ms-2 rowIcon"
                                    >
                                    </i>
                                </span>
                                <span data-uiid="text-item">
                                    {{ item[displayValueProp] }}
                                </span>
                            </h6>
                            <h6 class="group-item-actions mb-0" *ngIf="openItemEnabled">
                                <i
                                    class="fas fa-external-link-alt open-item-link"
                                    matTooltip="Open in new tab"
                                    (click)="openItem(item); $event.stopPropagation()"
                                    data-uiid="button-open-item"
                                ></i>
                            </h6>
                        </div>
                        <div
                            class="item-expand"
                            *ngIf="
                                item._isExpanded &&
                                item.linkableDisplayItems &&
                                item.linkableDisplayItems.length > 0
                            "
                        >
                            <table class="cerrix-table bordered-columns">
                                <tbody>
                                    <tr *ngFor="let prop of item.linkableDisplayItems">
                                        <td>
                                            <b>{{ prop.Header }}</b>
                                        </td>
                                        <td class="text-truncate">
                                            <workspace-cell
                                                [configs]="rendererConfig"
                                                [row]="item.displayItemRow"
                                                [header]="prop.Header"
                                            >
                                            </workspace-cell>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="detail-page-footer">
                <div class="list-empty-text" *ngIf="!linkablePage || linkablePage.length == 0">
                    <span *ngIf="!unlinked || unlinked.length == 0">
                        There are no {{ typename }} to link
                    </span>
                    <span *ngIf="unlinked && unlinked.length > 0">
                        No {{ typename }} match the filter
                    </span>
                </div>
                <div
                    class="pagination justify-content-center"
                    *ngIf="unlinked && unlinked.length > 0 && linkablePaginationPageCount > 1"
                >
                    <ul class="pagination">
                        <li class="page-item" [class.disabled]="linkablePaginationPage == 0">
                            <span
                                class="page-link"
                                (click)="setPagination(linkablePaginationPage - 1)"
                            >
                                Previous
                            </span>
                        </li>

                        <li class="page-item disabled">
                            <span class="page-link">{{ linkablePaginationText }}</span>
                        </li>

                        <li
                            class="page-item"
                            [class.disabled]="
                                linkablePaginationPage >= linkablePaginationPageCount - 1
                            "
                        >
                            <span
                                class="page-link"
                                (click)="setPagination(linkablePaginationPage + 1)"
                            >
                                Next
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showAdvancedFilters">
        <workspace-advanced-workspace-config
            [tabID]="tabID"
            [moduleType]="moduleType"
            [filterConfig]="filterConfig"
            [activeFilters]="activeFilters"
            [NumberProperties]="NumberProperties"
            [DateProperties]="DateProperties"
            [allHeaders]="activeHeaders"
            [headerLookup]="headerLookup"
            [activeHeaders]="activeHeaders"
            (ApplyClick)="applyFilters($event)"
            [showTableConfiguration]="false"
            #advancedConfigComponent
        >
        </workspace-advanced-workspace-config>
    </div>
</div>
