import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { copyText } from "@methods/CommonMethods";
import { guid } from "@methods/uniqueMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { TabMenu } from "./../../common/models/generic/TabModels/TabMenu";
import { TabMenuItem } from "./../../common/models/generic/TabModels/TabMenuItem";
import { ManualSectionType } from "./models/manual-section-type.enum";
import { PdfManualComponent } from "./pages/manual-pages/pdf-manual/pdf-manual.component";
import { ApiManualSectionComponent } from "./pages/manual-sections/ApiManualSection.component";

@Component({
    selector: "cerrix-manual",
    templateUrl: "./manual.component.html",
    styleUrls: ["./manual.component.scss"],
})
export class ManualComponent implements OnInit {
    @ViewChild("manualPage", { read: ViewContainerRef, static: true }) manualPageContent;
    tab: TabModel;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _toastr: ToastrService,
        private _tabService: TabService
    ) {}

    ngOnInit() {
        this.bindChangeEvent();
        this.bindCopyUrl();
        this.initTabMenu();

        this.loadFromConfig();
    }

    //#region Area to add new pages to

    private getCompByManualPageType(type: ManualSectionType): any {
        switch (type) {
            case ManualSectionType.PdfManual:
                return PdfManualComponent;
            case ManualSectionType.ApiManual:
                return ApiManualSectionComponent;
            default:
                return null;
        }
    }

    private getMenuItems() {
        return [
            new TabMenuItem({
                identifier: this.newMenuID(),
                name: "Manuals",
                iconClass: "fas fa-books",
                clickable: false,
                children: [
                    new TabMenuItem({
                        identifier: this.newMenuID(),
                        name: "PDF Manuals",
                        iconClass: "fad fa-file-pdf",
                        menuItemId: ManualSectionType.PdfManual,
                    }),
                    new TabMenuItem({
                        identifier: this.newMenuID(),
                        name: "API Manual",
                        iconClass: "fas fa-key",
                        menuItemId: ManualSectionType.ApiManual,
                    }),
                ],
            }),
        ];
    }

    //#endregion Area to add new pages to

    //#region Functional Code that should not need changes

    private initTabMenu() {
        const menu: TabMenu = new TabMenu();
        menu.menuItems = this.getMenuItems();
        menu.menuItemClicked = (menuItem) => {
            this.activateItem(menuItem);
        };

        this.tab.menu = menu;
    }

    private activateItem(item: TabMenuItem) {
        let pageCmp = this.getCompByManualPageType(<ManualSectionType>item.menuItemId);
        if (pageCmp == null) {
            throw new Error(`Tab menu item '${item.menuItemId}' has no component attached to it.`);
        }

        if (this.manualPageContent) {
            this.manualPageContent.clear();
        }

        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(pageCmp);
        const componentRef = this.manualPageContent.createComponent(componentFactory);
        if (componentRef.instance) {
            componentRef.instance.cerrixTab = this.tab;
        }

        this.tab.name = "Manual - " + item.name;
        this.tab.config.activeMenu = item.menuItemId;
        this.tab.menu.activeMenuItem = item.identifier;
    }

    private loadFromConfig() {
        if (!this.tab.config) {
            this.tab.config = {};
        }

        if (this.tab._fullPath) {
            const fullPath = this.tab._fullPath;
            let routeSections = fullPath.split("/");
            if (routeSections[0] == "manuals") {
                routeSections.shift();
            }

            if (routeSections.length > 0) {
                this.tab.config.activeMenu = routeSections[0];
                if (routeSections.length > 1) {
                    this.tab.config.manualPage = routeSections.slice(1).join("/");
                }
            }
        }

        let initialPage = this.findInitialPage(this.tab.menu.menuItems, this.tab.config.activeMenu);
        initialPage = initialPage
            ? initialPage
            : this.findInitialPage(this.tab.menu.menuItems, null);

        if (initialPage) {
            this.activateItem(initialPage);
        }

        this.tab.showLoader = false;
    }

    private findInitialPage(pages: TabMenuItem[], target: string): TabMenuItem {
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];

            if (target) {
                if (page.menuItemId == target) {
                    return page;
                }
            } else if (page.menuItemId) {
                return page;
            }

            if (page.children) {
                const foundInChildren = this.findInitialPage(page.children, target);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }

        return null;
    }

    private bindChangeEvent() {
        this._tabService.listeners.removeTabListenerByType(
            this.tab,
            TabEventListenerType.ReloadConfig
        );

        this._tabService.listeners.addGlobalListener(
            TabEventListenerType.ReloadConfig,
            this.tab.lookupname,
            async (newTab: TabModel) => {
                this.tab.showLoader = true;
                this.tab._fullPath = newTab._fullPath;

                this.loadFromConfig();
            },
            this.tab
        );
    }

    private bindCopyUrl() {
        const that = this;
        this.tab.copyUrlToClipboard = function () {
            let url = window.location.origin;

            url = url += "/manuals";

            if (that.tab.config.activeMenu) {
                url = url += "/" + that.tab.config.activeMenu;

                if (
                    that.tab.config.manualPage &&
                    that.tab.config.activeMenu != ManualSectionType.PdfManual
                ) {
                    url = url += "/" + that.tab.config.manualPage;
                }
            }

            copyText(url.toLowerCase());
            that._toastr.success("Url copied to clipboard", "Copy URL");
        };
    }

    private newMenuID() {
        return this.tab.identifier + "-" + guid();
    }

    //#endregion Functional Code that should not need changes
}
