import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormArray, AbstractControl } from "@angular/forms";
import { CustomFieldType } from "@enums/customfields/CustomFieldType";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";

@Component({
    selector: "cerrix-custom-fields-editor",
    templateUrl: "./cerrix-custom-fields-editor.component.html",
    styleUrls: ["./cerrix-custom-fields-editor.component.scss"],
})
export class CerrixCustomFieldsEditorComponent implements OnInit {
    @Input() parentForm: FormGroup;
    @Input() customFieldData: CustomFieldInfoModel;
    @Input() singleColumn: boolean = true;
    @Input() emptyMessage: string = "No custom fields defined.";

    // CustomFields fields
    CustomFieldType = CustomFieldType;
    columns: number = 12;

    customFieldControlInfos: CustomFieldControlInfo[] = [];

    public ngOnInit() {
        const allControls = this.getCustomFieldControls();

        this.columns = this.singleColumn ? 12 : 6;

        allControls.forEach((control) => {
            const id = this.getCustomFieldIdValue(control);
            const data = this.getCustomFieldData(id);
            const newControl = <CustomFieldControlInfo>{
                id: id,
                data: data,
                control: control,
            };
            this.customFieldControlInfos.push(newControl);
        });
    }

    // Page helper functions
    getCustomFieldControls(): AbstractControl[] {
        return (this.parentForm.get("customFields") as FormArray).controls;
    }

    getCustomFieldIdValue(customField: AbstractControl): number {
        return customField.get("customFieldId").value;
    }

    getCustomFieldData(customFieldId: number): CustomFieldInfoModel {
        return this.customFieldData[customFieldId];
    }
}

export class CustomFieldControlInfo {
    id: number;
    data: CustomFieldInfoModel;
    control: AbstractControl;
}
