<div class="detail-page-action-bar flex-action-bar space-between">
    <div class="load-area">
        <button
            type="button"
            (click)="applyFilter()"
            class="btn btn-cerrix"
            [disabled]="!selectedBusinessDimensionId"
        >
            <i class="fas fa-link"></i>
            <span class="d-none d-lg-inline">Load Process</span>
            <i
                *ngIf="!selectedBusinessDimensionId"
                class="far fa-question-circle ms-2"
                [matTooltip]="'Please select a configuration first.'"
            ></i>
        </button>
    </div>

    <div class="filtering-area">
        <div class="form-group smallfont">
            <label><b>Organization</b></label>
            <cerrix-select-tree
                placeholder="No selection made"
                [getDataMethod]="orgRequest"
                [(value)]="selectedOrganizationId"
                [multiselect]="false"
                (onChangeSingleId)="reloadBusinessData()"
            >
            </cerrix-select-tree>
        </div>

        <div class="form-group smallfont">
            <label for="businessDimenionTree">
                <b>Business Dimension</b>
            </label>
            <cerrix-select-tree
                placeholder="Required"
                [getDataMethod]="bdRequest"
                [(value)]="selectedBusinessDimensionId"
                [multiselect]="false"
            >
            </cerrix-select-tree>
        </div>
    </div>
</div>
