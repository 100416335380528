<div *ngIf="incidentType && customFieldsForm" class="custom-fields-tab">
    @if (incidentType.customFields) {
    <div class="custom-fields-container">
        <ng-container
            *ngFor="let customField of incidentType.customFields"
            [ngTemplateOutlet]="customFieldTemplate"
            [ngTemplateOutletContext]="{
                customField: customField,
                cfForm: customFieldsForm
            }"
        ></ng-container>
    </div>

    } @if (incidentType.incidentTypeSections) {
    <ng-container *ngFor="let its of incidentType.incidentTypeSections">
        <div class="custom-fields-container">
            <ng-container
                *ngFor="let customField of its.customFields"
                [ngTemplateOutlet]="customFieldTemplate"
                [ngTemplateOutletContext]="{
                    customField: customField,
                    cfForm: customFieldsForm
                }"
            ></ng-container>
        </div>
    </ng-container>
    }
</div>

<ng-template #customFieldTemplate let-customField="customField" let-cfForm="cfForm">
    <div
        [formGroup]="cfForm"
        class="custom-field-item"
        [class.full-width]="fullWidthCustomTypes.includes(customField.customFieldType)"
    >
        <cerrix-field-wrapper
            [label]="customField.translation"
            type="secondary"
            [required]="customField.isRequired"
            [info]="customField.description"
        >
            <ng-container [ngSwitch]="customField.customFieldType">
                <ng-container *ngSwitchCase="FieldType.TextField">
                    <cerrix-input
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                    ></cerrix-input>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.TextBox">
                    <cerrix-textarea
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                    ></cerrix-textarea>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.SingleSelect">
                    <cerrix-select
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                        [options]="
                            customFieldOptions.has(customField.id)
                                ? customFieldOptions.get(customField.id)
                                : []
                        "
                        [useVisibility]="true"
                    ></cerrix-select>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.MultiSelect">
                    <cerrix-autocomplete
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                        [options]="
                            customFieldOptions.has(customField.id)
                                ? customFieldOptions.get(customField.id)
                                : []
                        "
                        [useVisibility]="true"
                    ></cerrix-autocomplete>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.Checkbox">
                    <cerrix-checkbox
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                    ></cerrix-checkbox>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.Numeric">
                    <cerrix-input-numeric
                        [placeholder]="customField.placeholder ?? ''"
                        [formControlName]="customField.id"
                    ></cerrix-input-numeric>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.Date">
                    <cerrix-datepicker
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                    ></cerrix-datepicker>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.DateTime">
                    <cerrix-datetime-picker
                        [placeholder]="customField.placeholder"
                        [formControlName]="customField.id"
                    ></cerrix-datetime-picker>
                </ng-container>

                <ng-container *ngSwitchCase="FieldType.FinancialImpact">
                    <cerrix-financial-impact
                        [formControlName]="customField.id"
                        [internalOrganizations]="currentSelectedOrganizations"
                        [thirdParties]="currentSelectedThirdParties"
                        [currencyList]="currencies"
                    ></cerrix-financial-impact>
                </ng-container>
            </ng-container>
        </cerrix-field-wrapper>
    </div>
</ng-template>
