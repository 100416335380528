import { Component, OnInit, Input } from "@angular/core";
import { predefinedShapes, Shape } from "./shapes";
import { DomSanitizer } from "@angular/platform-browser";
import { initCerrixShapes } from "./cerrix-shapes";
import { initMxGraphShapes } from "./mxgraph-shapes";
import { mxgraph } from "mxgraph-factory";
import { GraphEditor } from "../../grapheditor";
import { ProcessEditorHelper } from "@app/process-editor/helpers/ProcessEditorHelper";
import { ProcessCellAttribute } from "@models/processeditor/DiagramData";

@Component({
    selector: "process-editor-shape-sidebar",
    templateUrl: "./shape-sidebar.component.html",
    styleUrls: ["./shape-sidebar.component.scss"],
})
export class ShapeSidebarComponent implements OnInit {
    shapes;
    parser = new DOMParser();
    @Input() graph: GraphEditor;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.shapes = { ...predefinedShapes };
        const keys = Object.keys(this.shapes);
        keys.forEach((key) => {
            this.shapes[key].forEach((shape) => {
                if (typeof shape.svg === "string") {
                    shape.safeSvg = this.sanitizer.bypassSecurityTrustHtml(shape.svg);
                }
            });
        });

        initMxGraphShapes(this.graph, this.graph.dependencies);
        initCerrixShapes(this.graph.dependencies);
        this.loadStencils();
    }

    loadStencils() {
        this.loadStencil("/assets/mxgraph/shapes/xml/bpmn.xml");
        this.loadStencil("/assets/mxgraph/shapes/xml/cerrix.xml");
    }

    loadStencil(filepath: string) {
        const mxUtils = this.graph.dependencies.mxUtils;
        const mxStencilRegistry = this.graph.dependencies.mxStencilRegistry;
        const mxStencil = this.graph.dependencies.mxStencil;
        const shapes = mxUtils.load(filepath).getDocumentElement() as Element;

        if (!shapes) {
            return;
        }

        Array.from(shapes.children).forEach((shape) => {
            const stencilName =
                shapes.getAttribute("name") +
                "." +
                shape.getAttribute("name").toLowerCase().replace(" ", "_");
            mxStencilRegistry.addStencil(stencilName, new mxStencil(shape));
        });
    }

    keepOrder(a, b) {
        return a;
    }

    trackBy(index, item) {
        return index;
    }

    makeDraggable(shape: Shape, element: HTMLElement) {
        this.enableDragDrop(shape, element, this.graph);
    }

    enableDragDrop(shape: Shape, element: HTMLElement, graph: GraphEditor) {
        const { mxUtils, mxDragSource } = graph.dependencies;

        const value = this.createNewElement(shape);
        const cells = [this.createCell(shape, value)];

        // Drag source is configured to use dragElt for preview and as drag icon
        // if scalePreview (last) argument is true. Dx and dy are null to force
        // the use of the defaults. Note that dx and dy are only used for the
        // drag icon but not for the preview.
        const ds = mxUtils.makeDraggable(
            element,
            this.getFindGraphUnderCursorFunc([graph]),
            this.getOnDropFunc(cells),
            this.createDragPreview(shape),
            null,
            null,
            graph.autoScroll,
            true
        );

        // Redirects feature to global switch. Note that this feature should only be used
        // if the the x and y arguments are used in funct to insert the cell.
        ds.isGuidesEnabled = function () {
            return graph.graphHandler.guidesEnabled;
        };

        // Restores original drag icon while outside of graph
        ds.createDragElement = mxDragSource.prototype.createDragElement;
    }

    onShapeClick(event: MouseEvent, shape: Shape) {
        const { mxCell, mxGeometry } = this.graph.dependencies;
        const graph = this.graph;

        graph.container.focus();

        if (event.shiftKey && !graph.isSelectionEmpty()) {
            const value = shape.name;
            this.updateShapes(
                new mxCell(
                    value != null ? value : "",
                    new mxGeometry(0, 0, shape.width, shape.height),
                    shape.style
                ),
                graph.getSelectionCells()
            );
            graph.scrollCellToVisible(graph.getSelectionCell());
        }
    }

    private createCell(shape: Shape, value: Element) {
        const { mxCell, mxGeometry, mxPoint } = this.graph.dependencies;
        const cell = new mxCell(
            value != null ? value : "",
            new mxGeometry(0, 0, shape.width, shape.height),
            shape.style
        );

        if (shape.isEdge) {
            cell.geometry.setTerminalPoint(new mxPoint(0, shape.height), true);
            cell.geometry.setTerminalPoint(new mxPoint(shape.width, 0), false);
            cell.edge = true;
        } else {
            cell.vertex = true;
        }

        return cell;
    }

    private createNewElement(shape: Shape): Element {
        const extra = ProcessEditorHelper.createExtraAttributes(<ProcessCellAttribute>{
            shapeName: shape.name,
            label: shape.value,
            isEdge: shape.isEdge,
        });

        return extra;
    }

    private createDragPreview(shape: Shape) {
        let dragPreview;

        if (shape.useImage) {
            dragPreview = document.createElement("img");
            dragPreview.src = shape.image;
        } else {
            dragPreview = document.createElement("div");
            dragPreview.innerHTML = shape.svg;
        }

        dragPreview.style.width = shape.width + "px";
        dragPreview.style.height = shape.height + "px";

        return dragPreview;
    }

    private getOnDropFunc(cells: mxgraph.mxCell[]) {
        return function (graph, evt, target) {
            const validDropTarget =
                target != null ? graph.isValidDropTarget(target, cells, evt) : false;
            let select = null;

            if (
                target != null &&
                !validDropTarget &&
                graph.getModel().getChildCount(target) === 0 &&
                graph.getModel().isVertex(target) === cells[0].vertex
            ) {
                // otherwise it replaces the item..
                select = target;
            } else {
                if (target != null && !validDropTarget) {
                    target = null;
                }

                const pt = graph.getPointForEvent(evt);
                select = graph.moveCells(cells, pt.x, pt.y, true, target);
            }

            graph.setSelectionCells(select);
        };
    }

    private getFindGraphUnderCursorFunc(graphs: GraphEditor[]) {
        const { mxUtils, mxEvent } = this.graph.dependencies;
        return function (evt) {
            const x = mxEvent.getClientX(evt);
            const y = mxEvent.getClientY(evt);
            const elt = document.elementFromPoint(x, y);

            for (let i = 0; i < graphs.length; i++) {
                if (mxUtils.isAncestorNode(graphs[i].container, elt) && graphs[i].isEnabled()) {
                    return graphs[i];
                }
            }

            return null;
        };
    }

    private updateShapes(source: mxgraph.mxCell, targets: mxgraph.mxCell[]) {
        const graph = this.graph;

        const sourceCellStyle = graph.getCellStyle(source);
        const result = [];

        graph.model.beginUpdate();
        try {
            const cellStyle = graph.getModel().getStyle(source);

            // Lists the styles to carry over from the existing shape
            const styles = [
                "shadow",
                "dashed",
                "dashPattern",
                "fontFamily",
                "fontSize",
                "fontColor",
                "align",
                "startFill",
                "startSize",
                "endFill",
                "endSize",
                "strokeColor",
                "strokeWidth",
                "fillColor",
                "gradientColor",
                "html",
                "part",
                "noEdgeStyle",
                "edgeStyle",
                "elbow",
                "childLayout",
                "recursiveResize",
                "container",
                "collapsible",
                "connectable",
            ];

            for (let i = 0; i < targets.length; i++) {
                const targetCell = targets[i];

                if (
                    graph.getModel().isVertex(targetCell) === graph.getModel().isVertex(source) ||
                    graph.getModel().isEdge(targetCell) === graph.getModel().isEdge(source)
                ) {
                    const state = graph.view.getState(targetCell);
                    const style = state != null ? state.style : graph.getCellStyle(targets[i]);
                    graph.getModel().setStyle(targetCell, cellStyle);

                    this.removeChildrenFromCompositeCells(targetCell);

                    if (style != null) {
                        this.replaceUMLLifeLineParticipantStyle(
                            graph,
                            sourceCellStyle,
                            targetCell,
                            style
                        );
                        this.copyStylesFromTarget(graph, styles, targetCell, style);
                    }

                    result.push(targetCell);
                }
            }
        } finally {
            graph.model.endUpdate();
        }

        return result;
    }

    // Replaces the participant style in the lifeline shape with the target shape
    private replaceUMLLifeLineParticipantStyle(
        graph: GraphEditor,
        sourceCellStyle: any,
        targetCell: mxgraph.mxCell,
        targetStyle: any
    ) {
        const { mxConstants } = graph.dependencies;
        if (
            targetStyle[mxConstants.STYLE_SHAPE] === "umlLifeline" &&
            sourceCellStyle[mxConstants.STYLE_SHAPE] !== "umlLifeline"
        ) {
            graph.setCellStyles(mxConstants.STYLE_SHAPE, "umlLifeline", [targetCell]);
            graph.setCellStyles("participant", sourceCellStyle[mxConstants.STYLE_SHAPE], [
                targetCell,
            ]);
        }
    }

    private copyStylesFromTarget(
        graph: GraphEditor,
        styles: string[],
        targetCell: mxgraph.mxCell,
        targetStyle: any
    ) {
        for (let j = 0; j < styles.length; j++) {
            const value = targetStyle[styles[j]];

            if (value != null) {
                graph.setCellStyles(styles[j], value, [targetCell]);
            }
        }
    }

    private removeChildrenFromCompositeCells(targetCell: mxgraph.mxCell) {
        const graph = this.graph;
        const mxUtils = this.graph.dependencies.mxUtils;
        const state = graph.view.getState(targetCell);

        if (state != null && mxUtils.getValue(state.style, "composite", "0") === "1") {
            const childCount = graph.model.getChildCount(targetCell);

            for (let j = childCount; j >= 0; j--) {
                graph.model.remove(graph.model.getChildAt(targetCell, j));
            }
        }
    }
}
