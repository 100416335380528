import { Component, OnInit } from "@angular/core";

@Component({
    selector: "navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    isHolidaySeason: Boolean;

    constructor() {}

    ngOnInit(): void {
        this.checkHolidaySeasonDate();
    }

    checkHolidaySeasonDate() {
        const today = new Date();
        // Only in december active
        if (today.getMonth() == 11) {
            this.isHolidaySeason = true;
        }
    }
}
