import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { TargetModule } from "@enums/document/TargetModule";
import { toPromise } from "@methods/CommonMethods";
import { DocumentMethods } from "@methods/DocumentMethods";
import { DocumentDetailsParameters } from "@models/documents/DocumentDetailsParameters";
import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { DocumentManagerDetailsComponent } from "../document-manager/document-manager-details/document-manager-details.component";
import { PopupService } from "../services/popup/popup.service";

@Component({
    selector: "document-manager-old",
    templateUrl: "./document-manager-old.component.html",
    styleUrls: ["./document-manager-old.component.scss"],
})
export class DocumentManagerOldComponent implements OnChanges {
    // Actual DocumentBinding
    @Input() Documents: DocumentModel[] = [];
    @Input() permissions: DocumentManagerPermissionModel;
    @Output() DocumentsChange = new EventEmitter<DocumentModel[]>();

    // Custom component Configuration options
    _disabled = false;
    get disabled(): boolean {
        if (!this.AllowAdd && !this.AllowDelete && !this.AllowEdit) {
            return true;
        }
        return this._disabled;
    }
    @Input() set disabled(value: boolean) {
        this._disabled = value;
    }
    @Input() Label = "Documents";
    @Input() MaxHeight: string;
    @Input() headerHeight: string;
    @Input() hideLabel = false;
    @Input() hideAddButton = false;
    @Input() noMargin = false;

    @Input() compactMode = false;

    @Input() ShowType = true;
    @Input() ShowDescription = true;
    @Input() SingleFileUpload = false;
    @Input() AllowAdd = true;
    @Input() AllowEdit = true;
    @Input() AllowDelete = true;
    @Input() ForcedExtensions: string;
    @Input() DocumentTypeTargets: TargetModule | TargetModule[];
    @Input() NoDocumentMessage: string = "Drop documents here or use the 'Add document' button.";
    @Input() ShowSummary = false;
    @Input() fullScreenDropArea = true;
    @Input() scrollEnabled = false;
    @Input() Tooltip: string;

    highlightedGuid: string;
    username: string;
    documentTypes: IdNameCombination[];

    serverAllowedExtensions: string;
    allowedFileExtensions: string;
    maxUploadSizeInMb: number;

    targetedDocument: DocumentModel;

    constructor(
        private _userDS: UserService,
        private _documentDS: DocumentDataService,
        private _modalService: BsModalService,
        private _standingDataDS: StandingdataDataService,
        private _toastr: ToastrService,
        private _popupService: PopupService
    ) {
        this.getSD();
    }

    async getSD() {
        this.username = await this._userDS.getUserName();
        this.documentTypes = await toPromise(
            this._standingDataDS.getDocumentTypes(this.DocumentTypeTargets)
        );
        const documentConfig = await toPromise(this._documentDS.GetDocumentConfig());

        this.serverAllowedExtensions = documentConfig.allowedFileExtensions;
        this.maxUploadSizeInMb = documentConfig.maxUploadSizeInMb;

        if (this.ForcedExtensions) {
            const forcedList = this.ForcedExtensions.split(",");
            const serverList = this.serverAllowedExtensions.split(",");
            const allowed = forcedList.filter((f) => serverList.indexOf(f) >= 0);

            this.allowedFileExtensions = allowed.join(",");
        } else {
            this.allowedFileExtensions = this.serverAllowedExtensions;
        }
    }

    ngOnChanges() {
        if (!this.permissions) {
            this.permissions = new DocumentManagerPermissionModel();
            this.permissions.canAddDocument = this.AllowAdd;

            this.Documents.forEach((x) => {
                x.CanDelete = this.AllowDelete;
                x.CanEdit = this.AllowEdit;
            });
        }

        this.reloadGrid();
    }

    openEdit(doc?: DocumentModel) {
        this.targetedDocument = doc ? (JSON.parse(JSON.stringify(doc)) as DocumentModel) : null;
        if (this.targetedDocument) {
            this.targetedDocument.File = doc.File;
        }

        this.openNewDialog(this.targetedDocument).subscribe((documents) => {
            this.applyEdit(documents);
        });
    }

    applyEdit(documents: DocumentModel[]) {
        if (!documents) {
            return;
        }

        const allNewFiles = [...documents, ...this.Documents]
            .filter((doc) => doc.IsNew && doc.File)
            .map((doc) => <File>doc.File);

        if (
            !DocumentMethods.validateUploadLimit(this.maxUploadSizeInMb, this._toastr, allNewFiles)
        ) {
            return;
        }

        for (let docIndex = 0; docIndex < documents.length; docIndex++) {
            const doc = documents[docIndex];

            this.setFileImg(doc);
            if (doc.IsNew) {
                const updated = doc.Guid
                    ? this.Documents.filter((x) => x.IsNew && x.Guid === doc.Guid)
                    : [];
                if (updated.length > 0) {
                    this.updateFile(updated[0], doc);
                } else {
                    this.Documents.unshift(doc);
                }
            } else {
                doc.Changed = true;

                const updated = this.Documents.filter((x) => x.ID === doc.ID && !x.Deleted);
                if (updated.length > 0) {
                    this.updateFile(updated[0], doc);
                }
            }
        }

        this.DocumentsChange.emit(this.Documents);
    }

    updateFile(oldFile: DocumentModel, newFile: DocumentModel) {
        const replaceIndex = this.Documents.indexOf(oldFile);
        this.Documents.splice(replaceIndex, 1, newFile);
    }

    toggleDelete(doc: DocumentModel) {
        if (!doc.CanDelete) {
            return;
        }

        if (doc.IsNew) {
            const newIndex = this.Documents.indexOf(doc);
            this.Documents.splice(newIndex, 1);
        } else {
            doc.Deleted = !doc.Deleted;
        }

        this.highlightedGuid = null;
        this.DocumentsChange.emit(this.Documents);
    }

    setFileImg(doc: DocumentModel) {
        const fileNameSplit = doc && doc.Name ? doc.Name.toLowerCase().split(".") : [];
        const fileExt = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : "";

        if (fileExt.indexOf("pdf") >= 0) {
            doc.FileIcon = "file-pdf";
        } else if (
            fileExt.indexOf("jpg") >= 0 ||
            fileExt.indexOf("png") >= 0 ||
            fileExt.indexOf("jpeg") >= 0
        ) {
            doc.FileIcon = "file-image";
        } else if (fileExt.indexOf("doc") >= 0) {
            doc.FileIcon = "file-word";
        } else if (fileExt.indexOf("txt") >= 0 || fileExt.indexOf("msg") >= 0) {
            doc.FileIcon = "file-alt";
        } else if (
            fileExt.indexOf("xls") >= 0 ||
            fileExt.indexOf("xlsx") >= 0 ||
            fileExt.indexOf("xml") >= 0
        ) {
            doc.FileIcon = "file-excel";
        } else if (fileExt.indexOf("zip") >= 0) {
            doc.FileIcon = "file-archive";
        } else {
            doc.FileIcon = "file";
        }
    }

    fileDropHandler(files: File[]) {
        if (this.AllowAdd) {
            if (this.SingleFileUpload && (files.length > 1 || this.Documents.length > 0)) {
                this._toastr.warning("Only one document can be uploaded at a time.");
                return;
            }

            const droppedDocuments = DocumentMethods.convertFileListToDocumentModel(
                files,
                this.username
            );

            this.applyEdit(droppedDocuments);
        }
    }

    openDocumentViewer(document: DocumentModel) {
        const url = "/api/documents/" + document.Guid + "/pdf";
        this._popupService.forceOpenInNewWindow(url);
    }

    private openNewDialog(doc: DocumentModel): Observable<DocumentModel[]> {
        const parameters = <DocumentDetailsParameters>{
            permissions: this.permissions,
            allowedFileExtensions: this.allowedFileExtensions,
            documentTypes: this.documentTypes,
            showType: this.ShowType,
            showDescription: this.ShowDescription,
            singleFileUpload: this.SingleFileUpload,
            userName: this.username,
            disabled: this.disabled,

            maxUploadSizeInMb: this.maxUploadSizeInMb,
            filesToUpload: this.Documents.filter((x) => x.IsNew).map((x) => x.File),
        };

        const clone = <DocumentModel>new Object(doc);
        parameters.document = clone;

        const config = <ModalOptions<DocumentManagerDetailsComponent>>{
            backdrop: false,
            class: "documents-dialog",

            initialState: <DocumentManagerDetailsComponent>{
                parameters,
            },
        };

        return this._modalService.show<DocumentManagerDetailsComponent>(
            DocumentManagerDetailsComponent,
            config
        ).content.afterClosed;
    }

    //#region Grid Events

    reloadGrid() {
        this.Documents.forEach((x) => this.setFileImg(x));
    }

    //#endregion Grid Events
}
