<div *ngIf="dataSubjects.length > 0">
    <div class="row datasubjects-header">
        <div class="col-6">Data subject:</div>
        <div class="col-6">Connected data structures:</div>
    </div>
    <div *ngFor="let dataSubject of dataSubjects" class="row datasubjects-body">
        <div class="col-6 name">{{dataSubject.name}}</div>
        <div class="col-6">
            <button [disabled]="dataSubject.dataStructures.length === 0" class="btn btn-raised btn-block" [class.green]="dataSubject.dataStructures.length > 0"
                (click)="viewDataStructures(dataSubject)">
                <span *ngIf="dataSubject.dataStructures.length > 0">View ({{dataSubject.dataStructures.length}}) data structures</span>
                <span *ngIf="dataSubject.dataStructures.length === 0" title="Use manage data to select data structures">No data structures</span>
            </button>
        </div>
    </div>
</div>