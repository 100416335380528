import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DocumentModel } from "@models/documents/documentmodel";
import { SelectOption } from "../../eff-select/models/select-option.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
    AddDocumentsParameters,
    OpenDocumentPopupReason,
} from "../models/add-documents-parameters.model";

declare var $: any;

@Component({
    selector: "add-documents-popup",
    templateUrl: "./add-documents-popup.component.html",
    styleUrls: ["./add-documents-popup.component.scss"],
})
export class AddDocumentsPopupComponent implements OnInit {
    parameters: AddDocumentsParameters;

    documentTypes: SelectOption[] = [];
    documentForm: FormGroup;

    documents: Array<DocumentModel> = [];
    docTypeFormControl: FormControl;
    docDescriptionFormControl: FormControl;
    addDocumentBtnLabel: string;
    title: string;
    isOneDocument: boolean;
    showSaveButton: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddDocumentsPopupComponent>
    ) {}

    ngOnInit(): void {
        this.parameters = this.data.parameters;
        this.documents = this.parameters.documents;

        if (
            this.parameters.openReason == OpenDocumentPopupReason.EditDocument &&
            !this.parameters.canEditDocument
        ) {
            this.parameters.openReason = OpenDocumentPopupReason.ViewDocument;
        }

        this.isOneDocument = this.parameters.openReason != OpenDocumentPopupReason.AddDocument;

        if (this.parameters.documentTypes) {
            this.documentTypes = this.parameters.documentTypes.map((item) => {
                return { Id: item.ID, Label: item.Name };
            });
        }

        this.docTypeFormControl = new FormControl();
        this.docDescriptionFormControl = new FormControl();
        this.documentForm = new FormGroup({
            DocumentType: this.docTypeFormControl,
            Description: this.docDescriptionFormControl,
        });

        switch (this.parameters.openReason) {
            case OpenDocumentPopupReason.EditDocument:
                this.title = "Edit document";
                this.addDocumentBtnLabel = "Apply changes";
                this.showSaveButton = true;
                if (!this.parameters.canEditDocument) {
                    this.disableControllers();
                    this.showSaveButton = false;
                }
                break;
            case OpenDocumentPopupReason.AddDocument:
                this.title = "Add document(s)";
                this.addDocumentBtnLabel = "Add document(s)";
                this.showSaveButton = true;
                break;
            case OpenDocumentPopupReason.ViewDocument:
                this.title = "View document";
                this.disableControllers();
                this.showSaveButton = false;
                break;
        }

        if (this.isOneDocument) {
            this.docTypeFormControl.setValue(this.documents[0].DocumentTypeID);
            this.docDescriptionFormControl.setValue(this.documents[0].Description);
        }
    }

    setDocumentDetails(doc: DocumentModel) {
        const description = this.docDescriptionFormControl.value;
        const documentType = this.docTypeFormControl.value;

        doc.Description = description;

        if (!isNaN(documentType) && documentType > 0) {
            doc.DocumentTypeID = documentType;
            doc.DocumentType = this.documentTypes.find((x) => x.Id === documentType).Label;
        }
    }

    onApplyChanges() {
        this.documents.forEach((doc) => {
            this.setDocumentDetails(doc);
        });

        let isDelete = false;
        this.data.onSave(this.documents, isDelete);
        this.dialogRef.close();
    }

    onDelete(document: DocumentModel) {
        if (this.parameters.openReason == OpenDocumentPopupReason.AddDocument) {
            this.documents = this.documents.filter((item) => item != document);
        } else {
            let isDelete = true;
            this.data.onSave(this.documents, isDelete);
            this.dialogRef.close();
        }
    }

    disableControllers() {
        this.docTypeFormControl.disable();
        this.docDescriptionFormControl.disable();
    }

    showDeleteButton() {
        return (
            (this.parameters.openReason == OpenDocumentPopupReason.AddDocument &&
                this.documents.length > 1) ||
            (this.parameters.openReason == OpenDocumentPopupReason.EditDocument &&
                this.documents.length == 1 &&
                this.parameters.canDeleteDocument)
        );
    }
}
