import { Component, Input, ViewEncapsulation } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditRiskAssessmentConfigurationModel } from "@app/audit/shared/data-models/audit-risk-assessment-configuration-model";
import { AuditRiskAssessmentModel } from "@app/audit/shared/data-models/audit-risk-assessment-model";
import { AuditDetailPermissionModel } from "@app/audit/shared/permission-models/audit-detail-permission-model";
import { ControlLinkableModel } from "@app/controls/models/control-linkable-model";
import { RiskLinkableModel } from "@app/risk/models/risk-linkable-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { RendererType } from "@enums/RendererType";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "audit-detail-risk-assessment",
    templateUrl: "./audit-detail-risk-assessment.component.html",
    styleUrls: ["./audit-detail-risk-assessment.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AuditDetailRiskAssessmentComponent {
    @Input() auditModel: AuditModel;
    @Input() permissions: AuditDetailPermissionModel;

    riskAssessmentConfig: AuditRiskAssessmentConfigurationModel;

    risksConfig: GenericListConfig;
    controlsConfig: GenericListConfig;

    customFieldData: { [key: number]: CustomFieldInfoModel } = {};
    riskAssessmentFormGroup: FormGroup;

    private _initExecuted = false;
    loaded = false;

    constructor(
        private _ds: AuditDataService,
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    init() {
        if (this._initExecuted) {
            return;
        }

        this._initExecuted = true;
        this._ds.getAuditRiskAssessmentData(this.auditModel.guid).subscribe({
            next: (d) => {
                this.riskAssessmentConfig = d;
                this.initAuditModel();
                this.createRiskAssessmentFormGroup();

                this.createRiskConfig();
                this.createControlConfig();

                this.loaded = true;
            },
            error: () => {
                this._initExecuted = false;
            },
        });
    }

    public onSave(): void {
        if (this.riskAssessmentFormGroup) {
            FormValidationHelper.markAllAsTouched(this.riskAssessmentFormGroup);
        }
    }

    private initAuditModel() {
        const riskAssessment = new AuditRiskAssessmentModel();

        riskAssessment.auditGuid = this.riskAssessmentConfig.auditGuid;
        riskAssessment.auditType = this.riskAssessmentConfig.auditType;
        riskAssessment.riskAssessment = this.riskAssessmentConfig.riskAssessment;

        riskAssessment.linkedRisks = this.riskAssessmentConfig.linkedRisks;
        riskAssessment.linkedControls = this.riskAssessmentConfig.linkedControls;

        this.auditModel.riskAssessment = riskAssessment;
    }

    private createRiskAssessmentFormGroup() {
        const customFieldsFormArray = new FormArray([]);

        for (const field of this.riskAssessmentConfig.riskAssessmentQuestions) {
            const answerField = this.auditModel.riskAssessment.riskAssessment.find(
                (x) => x.customFieldId === field.ID
            );

            const customFieldFormGroup = new FormGroup<any>({
                ID: new FormControl(answerField ? answerField.ID : 0),
                Name: new FormControl(field.Name),
                customFieldId: new FormControl(field.ID),
                required: new FormControl(field.required),
            });

            const valueProperty = getCustomFieldValueProperty(field);
            customFieldFormGroup.addControl(
                valueProperty,
                new FormControl(
                    answerField ? answerField[valueProperty] : null,
                    field.required ? [Validators.required] : []
                )
            );

            // Store extra information from the custom field in a custom object which is easier accessible in the front-end
            this.customFieldData[field.ID] = {
                Name: field.Name,
                fieldType: field.fieldType,
                required: field.required,
                answerOptions: field.answerOptions,
            };

            customFieldsFormArray.push(customFieldFormGroup);
        }

        this.riskAssessmentFormGroup = new FormGroup({
            customFields: customFieldsFormArray,
        });

        if (!this.permissions.canEditRiskAssessment) {
            this.riskAssessmentFormGroup.disable();
        } else {
            this.riskAssessmentFormGroup.valueChanges.subscribe(() => {
                this.auditModel.riskAssessment.riskAssessment =
                    this.riskAssessmentFormGroup.value["customFields"];
            });
        }
    }

    private createRiskConfig() {
        if (!this.permissions.canViewLinkedRisk) {
            return;
        }

        const linked = [];
        const unlinked = [];

        this.riskAssessmentConfig.linkableRisks.forEach((d) => {
            if (this.riskAssessmentConfig.linkedRisks.indexOf(d.id) >= 0) {
                linked.push(d);
            } else {
                unlinked.push(d);
            }
        });

        this.risksConfig = <GenericListConfig>{
            name: "Linked Risks",
            idProp: nameof<RiskLinkableModel>((x) => x.id),

            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            allowLink: this.permissions.canLinkRisk,

            isSortableByColumn: true,
            limitViewTo: 5,

            linkData: <any>{
                idValueProp: nameof<RiskLinkableModel>((x) => x.id),
                displayValueProp: nameof<RiskLinkableModel>((x) => x.name),
                unlinked,
                linkablePaginationItemCount: 15,
            },

            data: linked,
            fields: [
                {
                    prettyName: "Name",
                    fieldName: nameof<RiskLinkableModel>((x) => x.name),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Net score",
                    fieldName: nameof<RiskLinkableModel>((x) => x.netScore),
                    fieldType: GenericListFieldType.Number,
                },
                {
                    prettyName: "Risk Catalogue",
                    fieldName: nameof<RiskLinkableModel>((x) => x.riskCatalogue),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<RiskLinkableModel>((x) => x.netScore),
                    actionColumn: nameof<RiskLinkableModel>((x) => x.netScoreColor),
                    hideActionColumn: true,
                    type: RendererType.Score,
                },
            ],

            overviewRowActionsName: "Open",
            overviewRowActions: [
                {
                    icon: "fas fa-external-link",
                    tooltip: "Open",
                    clickEvent: (item: RiskLinkableModel) => {
                        const guid = this.riskAssessmentConfig.linkableRisks.find(
                            (x) => x.id === item.id
                        ).guid;

                        if (!guid) {
                            this._toastr.warning(
                                "Could not open the requested item.",
                                "Item not available"
                            );
                        } else {
                            this._tabService.generateTab(this._pages.RiskDetail, guid);
                        }
                    },
                },
            ],

            onLinkedDataChange: (data: any[]) => {
                this.auditModel.riskAssessment.linkedRisks.splice(
                    0,
                    this.auditModel.riskAssessment.linkedRisks.length
                );
                this.auditModel.riskAssessment.linkedRisks.push(...data.map((d) => d.id));
            },
        };
    }

    private createControlConfig() {
        if (!this.permissions.canViewLinkedControl) {
            return;
        }

        const linked = [];
        const unlinked = [];

        this.riskAssessmentConfig.linkableControls.forEach((d) => {
            if (this.riskAssessmentConfig.linkedControls.indexOf(d.id) >= 0) {
                linked.push(d);
            } else {
                unlinked.push(d);
            }
        });

        this.controlsConfig = <GenericListConfig>{
            name: "Linked Controls",
            idProp: nameof<ControlLinkableModel>((x) => x.id),

            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            allowLink: this.permissions.canLinkControl,

            isSortableByColumn: true,
            limitViewTo: 5,

            linkData: <any>{
                idValueProp: nameof<ControlLinkableModel>((x) => x.id),
                displayValueProp: nameof<ControlLinkableModel>((x) => x.name),
                unlinked,
                linkablePaginationItemCount: 15,
            },

            data: linked,
            fields: [
                {
                    prettyName: "Name",
                    fieldName: nameof<ControlLinkableModel>((x) => x.name),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Effectiveness score",
                    fieldName: nameof<ControlLinkableModel>((x) => x.effectivenessScore),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Control frequency",
                    fieldName: nameof<ControlLinkableModel>((x) => x.frequency),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<ControlLinkableModel>((x) => x.effectivenessScore),
                    actionColumn: nameof<ControlLinkableModel>((x) => x.effectivenessScoreColor),
                    hideActionColumn: true,
                    type: RendererType.Score,
                },
            ],

            overviewRowActionsName: "Open",
            overviewRowActions: [
                {
                    icon: "fas fa-external-link",
                    tooltip: "Open",
                    clickEvent: (item: ControlLinkableModel) => {
                        const guid = this.riskAssessmentConfig.linkableControls.find(
                            (x) => x.id === item.id
                        ).guid;

                        if (!guid) {
                            this._toastr.warning(
                                "Could not open the requested item.",
                                "Item not available"
                            );
                        } else {
                            this._tabService.generateTab(this._pages.ControlDetail, guid);
                        }
                    },
                },
            ],

            onLinkedDataChange: (data: any[]) => {
                this.auditModel.riskAssessment.linkedControls.splice(
                    0,
                    this.auditModel.riskAssessment.linkedControls.length
                );
                this.auditModel.riskAssessment.linkedControls.push(...data.map((d) => d.id));
            },
        };
    }
}
