import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditFindingCreateRequestModel } from "@app/audit-finding/shared/page-models/audit-finding-create-request-model";
import { AuditFindingPermissionModel } from "@app/audit-finding/shared/page-models/audit-finding-permission-model";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { RendererType } from "@enums/RendererType";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { isGuid } from "@methods/uniqueMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { MoiConfig } from "@models/moi/MoiConfig";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { map, of } from "rxjs";
import { TabModel } from "./../../../../common/models/generic/TabModels/TabModel";
import { AuditFindingDataService } from "./../../services/audit-finding-data.service";
import { AuditFindingModel } from "./../../shared/data-models/audit-finding-model";

@Component({
    selector: "audit-finding-detail",
    templateUrl: "./audit-finding-detail.component.html",
    styleUrls: ["./audit-finding-detail.component.scss"],
})
export class AuditFindingDetailComponent implements OnInit {
    @ViewChild(HistoryOverviewComponent) historyDetailOverviewComponent;
    tab: TabModel;
    id: string;

    permissions: AuditFindingPermissionModel;
    model: AuditFindingModel;
    formGroup: FormGroup;

    workflowStatusText: string = undefined;
    acceptButtonText: string = undefined;
    rejectButtonText: string = undefined;

    moiType: MoiTypes = MoiTypes.IM_Moi;
    tableConfig: GenericListConfig;
    linkedMois: number[] = [];

    constructor(
        protected _ds: AuditFindingDataService,
        private _moiDS: MoiDataService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _pages: Pages,
        private _tabService: TabService
    ) {}

    async ngOnInit() {
        const isExistingRequest = this.id && isGuid(this.id);

        this.permissions = await this.getPermissions(isExistingRequest);
        if (!this.permissions.canView || (!isExistingRequest && !this.permissions.canCreate)) {
            this._toastr.error("No Access!");
            this.tab.close(false);
            return;
        }

        const initCall = isExistingRequest ? this.getExisting() : this.initNewFinding();

        initCall.subscribe({
            next: (model: AuditFindingModel): void => {
                this.model = model;
                this.initWorkflowButtons();
                this.initFormGroup();
                this.linkedMois = model.linkedMois;
                this.createMoiTableConfig();

                this.tab.showLoader = false;
            },
            error: (): void => {
                this.tab.close(false);
            },
        });
    }

    private getExisting() {
        return this._ds.getObject(this.id);
    }

    initNewFinding() {
        if (!this.tab.config) {
            this._toastr.error("Request data is missing.", "Could not create a new finding.");
            this.tab.close(false);
            return;
        }

        const createRequest = this.tab.config as AuditFindingCreateRequestModel;
        const model = new AuditFindingModel();
        model.auditGuid = createRequest.auditguid;
        model.auditToLinkAfterCreate = createRequest.auditguid;
        model.auditStepToLinkAfterCreate = createRequest.stepguid;

        return of(model);
    }

    initFormGroup() {
        this.formGroup = new FormGroup({
            findingName: new FormControl(this.model.findingName, Validators.required),
            findingAuditees: new FormControl(this.model.findingAuditees),
            findingDescription: new FormControl(this.model.findingDescription, Validators.required),
            recommendation: new FormControl(this.model.recommendation),
            severityId: new FormControl(this.model.severityId),
            comment: new FormControl(this.model.comment),
            managementResponse: new FormControl(this.model.managementResponse),
            followUpIds: new FormControl(this.model.followUpIds),
            rootCause: new FormControl(this.model.rootCause),
        });

        if (!this.permissions.canEdit) {
            FormValidationHelper.disableFormGroupExcept<AuditFindingModel>(
                this.formGroup,
                "managementResponse"
            );
        }

        if (this.permissions.canEdit || this.permissions.canEditManagementResponse) {
            this.formGroup.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.formGroup, this.model);
            });

            FormValidationHelper.mapToModel(this.formGroup, this.model);
        }
    }

    initWorkflowButtons() {
        switch (this.model.managementResponseWorkflowState) {
            case 0: {
                this.workflowStatusText = "New";
                if (this.permissions.isAuditor) {
                    this.acceptButtonText = "Wait for management response";
                }
                break;
            }
            case 20: {
                this.workflowStatusText = "Awaiting auditee response";
                if (this.permissions.isAuditee) {
                    this.acceptButtonText = "Share management response";
                }
                break;
            }
            case 40: {
                this.workflowStatusText = "Awaiting auditor reaction";
                if (this.permissions.isAuditor) {
                    this.acceptButtonText = "Accept management response";
                    this.rejectButtonText = "Request for update";
                }
                break;
            }
            case 80: {
                this.workflowStatusText = "Awaiting solution";
                if (this.permissions.isAuditor) {
                    this.acceptButtonText = "Close";
                }
                break;
            }
            case 100: {
                this.workflowStatusText = "Closed";
                break;
            }
            default: {
            }
        }
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "history": {
                    this.historyDetailOverviewComponent.loadHistory();
                    break;
                }
            }
        }
    }

    async acceptButtonClick(): Promise<any> {
        switch (this.model.managementResponseWorkflowState) {
            case 0: {
                this.model.managementResponseWorkflowState = 20;
                break;
            }
            case 20: {
                this.model.managementResponseWorkflowState = 40;
                break;
            }
            case 40: {
                this.model.managementResponseWorkflowState = 80;
                break;
            }
            case 80: {
                this.model.managementResponseWorkflowState = 100;
                break;
            }
            default: {
            }
        }

        await this.save();
    }

    async rejectButtonClick(): Promise<any> {
        switch (this.model.managementResponseWorkflowState) {
            case 40: {
                this.model.managementResponseWorkflowState = 20;
                break;
            }
            default: {
            }
        }

        await this.save();
    }

    async save(): Promise<any> {
        if (!this.permissions.canEdit && !this.permissions.canEditManagementResponse) {
            return;
        }

        if (this.formGroup.valid) {
            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            await this._ds
                .storeAuditFinding(this.model)
                .toPromise()
                .then((findingGuid: string) => {
                    savingPrompt.close();
                    this._toastr.success("", "Update completed");
                    this.tab.id = findingGuid;
                    if (
                        this.tab.parent &&
                        this.tab.parent.lookupname === this._pages.AuditFinding
                    ) {
                        this.tab.parent.refresh();
                    }
                    this.tab.refresh();
                })
                .catch((error) => {
                    savingPrompt.close();
                });
        } else {
            FormValidationHelper.markAllAsTouched(this.formGroup);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.formGroup);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }

    public delete() {
        if (!this.permissions.canDelete) {
            return;
        }

        this._ds.deleteAuditFinding(this.model.guid).subscribe((validations) => {
            if (validations && validations.length > 0) {
                this._toastr.warning(validations.toString());
            } else {
                this._toastr.success("Audit finding deleted");
                this._tabService.refresh(this._pages.AuditFinding);
                this.tab.close(false);
            }
        });
    }

    private async getPermissions(isExistingRequest: boolean): Promise<AuditFindingPermissionModel> {
        if (isExistingRequest) {
            return await toPromise(this._ds.getPermissions(this.id));
        } else {
            const createRequest = this.tab.config as AuditFindingCreateRequestModel;
            return await toPromise(this._ds.getPermissionsByAudit(createRequest.auditguid));
        }
    }

    private createMoiTableConfig() {
        this.tableConfig = <GenericListConfig>{
            name: "Measures of improvements",
            idProp: "id",

            allowAdd: this.permissions.canCreate,
            allowEdit: this.permissions.canEdit,
            allowLink: this.permissions.canLink,
            linkData: <any>{
                displayValueProp: nameof<LinkedMoiModel>((x) => x.name),
                linkablePaginationItemCount: 15,
                hidePanelToggle: true,
            },
            fields: [
                {
                    prettyName: "Identifier",
                    fieldName: nameof<LinkedMoiModel>((x) => x.identifier),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Name",
                    fieldName: nameof<LinkedMoiModel>((x) => x.name),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Organization responsible",
                    fieldName: nameof<LinkedMoiModel>((x) => x.organizationResponsible),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Responsible",
                    fieldName: nameof<LinkedMoiModel>((x) => x.responsible),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Status",
                    fieldName: nameof<LinkedMoiModel>((x) => x.status),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Due date",
                    fieldName: nameof<LinkedMoiModel>((x) => x.dueDate),
                    fieldType: GenericListFieldType.Date,
                },
                {
                    prettyName: "Progress",
                    fieldName: nameof<LinkedMoiModel>((x) => x.progress),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<LinkedMoiModel>((x) => x.dueDate),
                    actionColumn: nameof<LinkedMoiModel>((x) => x.isOverdue),
                    actionValue: "Yes",
                    type: RendererType.DueDate,
                    formatType: FormatType.DateFormat,
                },
                {
                    textColumn: nameof<LinkedMoiModel>((x) => x.progress),
                    type: RendererType.Progress,
                },
            ],

            dataMethod: () => {
                return this._ds.getLinkableMois(this.model.findingReportGuid).pipe(
                    map((data) => {
                        let linked: LinkedMoiModel[] = [];
                        let unlinked: LinkedMoiModel[] = [];

                        if (this.linkedMois.length === 0) {
                            unlinked = data;
                        } else {
                            data.forEach((row) => {
                                if (this.linkedMois.indexOf(row.id) >= 0) {
                                    linked.push(row);
                                } else {
                                    unlinked.push(row);
                                }
                            });
                        }

                        this.tableConfig.linkData.unlinked = unlinked;
                        return linked;
                    })
                );
            },
            addOverride: () => {
                this.openMoi(null);
            },
            editOverride: (row) => {
                this.openMoi(row);
            },

            onLinkedDataChange: (data: LinkedMoiModel[]) => {
                this.linkedMois.splice(0, this.linkedMois.length);
                this.linkedMois.push(...data.map((d) => d.id));
                this.model.linkedMois = this.linkedMois;
            },
        };
    }

    async openMoi(row: LinkedMoiModel) {
        if (row) {
            this._tabService.generateTab(this._pages.MoiDetail, row.guid);
        } else {
            const sourceID = await this._moiDS
                .getSourceIdByGuid(this.moiType, this.model.findingReportGuid)
                .toPromise();
            if (!sourceID || sourceID <= 0) {
                this._toastr.error("Source not found!");
                return;
            }

            const config = <MoiConfig>{
                MoiType: this.moiType,
                ParentId: sourceID,
                ParentGuid: this.model.findingReportGuid,
                FindingDescription: this.model.findingDescription,
                Recommendation: this.model.recommendation,
                ManagementResponse: this.model.managementResponse,
                LinkedFinding: this.model.id,
            };
            this._tabService.generateTab(this._pages.MoiDetail, null, "(M) Create new Moi", config);
        }
    }
}
