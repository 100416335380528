import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { DatamanagementDataService } from "./services/datamanagement-data.service";
import { DataManagementOverviewComponent } from "./pages/overview/datamanagement-overview.component";
import { DataManagementDetailComponent } from "./pages/detail/datamanagement-detail.component";
import { ProcessingPurposeDataStructuresComponent } from "./pages/datastructures/datastructures.component";
import { DataManagementDetailMainComponent } from "./pages/detail/datamanagement-detail-main/datamanagement-detail-main.component";
import { DatamanagementRisksComponent } from "./pages/detail/datamanagement-risks/datamanagement-risks.component";
import { DatamanagementControlsComponent } from "./pages/detail/datamanagement-controls/datamanagement-controls.component";
import { EntityDetailComponent } from "./pages/datastructures/entities/entity-detail.component/entity-detail.component";
import { SystemDetailComponent } from "./pages/datastructures/systems/system-detail/system-detail.component";

import { EntityDialogComponent } from "./pages/datastructures/dialogs/entity-dialog/entity-dialog.component";
import { SystemsDialogComponent } from "./pages/datastructures/dialogs/systems-dialog/systems-dialog.component";
import { DatasubjectDialogComponent } from "./pages/datastructures/dialogs/datasubject-dialog/datasubject-dialog.component";
import { ArchivePolicyDialogComponent } from "./pages/datastructures/dialogs/archivepolicy-dialog/archivepolicy-dialog.component";
import { DataSubjectsOverviewComponent } from "./pages/datastructures/datasubjects/datasubjects-overview/datasubjects-overview.component";

@NgModule({
    declarations: [
        DataManagementOverviewComponent,
        DataManagementDetailComponent,
        DataManagementDetailMainComponent,
        ProcessingPurposeDataStructuresComponent,
        DatamanagementControlsComponent,
        DatamanagementRisksComponent,
        EntityDialogComponent,
        EntityDetailComponent,
        SystemsDialogComponent,
        SystemDetailComponent,
        DatasubjectDialogComponent,
        ArchivePolicyDialogComponent,
        DataSubjectsOverviewComponent,
    ],

    imports: [FormsModule, CommonModule, SharedModule],
})
export class DatamanagementModule {
    static forRoot(): ModuleWithProviders<DatamanagementModule> {
        return {
            ngModule: DatamanagementModule,
            providers: [DatamanagementDataService],
        };
    }
}
