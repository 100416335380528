export class AuditUniversePeriodModel {
    guid: string;
    id: number;
    name: string;
    startDate?: Date;
    endDate?: Date;
    dateClosed?: Date;

    universeObjectIds: number[] = [];
}
