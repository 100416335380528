import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";

@Injectable({
    providedIn: "root",
})
export class AdvEffHistoryDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    getHistory(controlGuid: string): Observable<HistoryModel[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/history`;

        return this.get<HistoryModel[]>(url);
    }
    getHistoryChanges(
        controlGuid: string,
        historyId: string | number
    ): Observable<HistoryChange[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/history/${historyId}`;

        return this.get<HistoryChange[]>(url);
    }
}
