import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentStatusModel } from "../models/incident-status.model";

@Injectable({
    providedIn: "root",
})
export class IncidentStatusService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/incident-status`;

    public getAll(): Observable<IncidentStatusModel[]> {
        return this.httpClient.get<IncidentStatusModel[]>(this.baseUrl);
    }
}
