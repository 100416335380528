import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-samples-score-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlSamplesScoreApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Score Sample API",
            description: "Used to score a specific Sample.",

            httpMethod: "POST",
            path: "/controls/testing/advanced/effectiveness/automation/samples/{sampleGuid}/score",

            parameters: [
                {
                    name: "sampleGuid",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
                {
                    name: "sampleScore",
                    description: "Score for the Sample.",
                    in: "query",
                    paramType: "string",
                    required: true,
                    example: "No Score | Sufficient | Insufficient | Inapplicable",
                },
                {
                    name: "sampleComment",
                    description: "Comment for the Sample.",
                    in: "query",
                    paramType: "string",
                    required: true,
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: "Request has been successfully processed",
                },
            ],
        };
    }
}
