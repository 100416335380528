import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import { EffDetailComponent } from "./eff-detail.component";
import { EffTestingUploadSourceDocumentComponent } from "./status-components/eff-testing-upload-source-document/eff-testing-upload-source-document.component";
import { EffTestingUploadEvidenceComponent } from "./status-components/eff-testing-upload-evidence/eff-testing-upload-evidence.component";
import { EffTestingTestComponent } from "./status-components/eff-testing-test/eff-testing-test.component";
import { EffTestingReviewComponent } from "./status-components/eff-testing-review/eff-testing-review.component";
import { EffTestingCompletedComponent } from "./status-components/eff-testing-completed/eff-testing-completed.component";
import { EffDetailMainComponent } from "./eff-detail-main/eff-detail-main.component";
import { EffWorkflowStatusComponent } from "./eff-workflow-status/eff-workflow-status.component";
import { EffDetailBreadcrumbInfoComponent } from "./eff-detail-breadcrumb-info/eff-detail-breadcrumb-info.component";
import { EffDetailWorkflowHistoryComponent } from "./eff-detail-workflow-history/eff-detail-workflow-history.component";
import { ControlModule } from "@app/controls/control.module";
import { EffDetailFinalConclusionComponent } from "./eff-detail-final-conclusion/eff-detail-final-conclusion.component";
import { LinkedRisksComponent } from "./linked-risks/eff-linked-risks.component";
import { DocumentManagerNewComponent } from "./common/document-manager/document-manager.component";
import { EffTableComponent, EffTableColumnDirective } from "./common/eff-table/eff-table.component";
import { EffDocumentDetailsComponent } from "./common/document-views/eff-document-details/eff-document-details.component";
import { EffButtonComponent } from "./common/eff-button/eff-button.component";
import { EffIconComponent } from "./common/eff-icon/eff-icon.component";
import { AddDocumentsPopupComponent } from "./common/document-manager/add-documents-popup/add-documents-popup.component";
import { EffSelectComponent } from "./common/eff-select/eff-select.component";
import { EffTextareaComponent } from "./common/eff-textarea/eff-textarea.component";
import { EffDropFilesAreaComponent } from "./common/eff-drop-files-area/eff-drop-files-area.component";
import { EffStepHeaderComponent } from "./common/eff-step-header/eff-step-header.component";
import { EffPopoverComponent } from "./common/eff-popover/eff-popover.component";
import { EffSaveButtonComponent } from "./common/eff-save-button/eff-save-button.component";
import { EffBrowseFilesButtonComponent } from "./common/browse-files-button/browse-files-button.component";
import { DocumentsListComponent } from "./status-components/components/documents-list/documents-list.component";
import { EffColoredSelectorComponent } from "./common/eff-colored-selector/eff-colored-selector.component";
import { EffInlineTextEditorComponent } from "./common/eff-inline-text-editor/eff-inline-text-editor.component";
import { EffWorkflowBarComponent } from "./common/eff-workflow-bar/eff-workflow-bar.component";
import { EffCheckboxComponent } from "./common/eff-checkbox/eff-checkbox.component";
import { EffFinalConclusionBoxComponent } from "./status-components/components/eff-final-conclusion-box/eff-final-conclusion-box.component";
import { EffFinalConclusionPopupComponent } from "./status-components/components/popups/final-conclusion-popup/eff-final-conclusion-popup.component";
import { EffConfirmationPopupComponent } from "./common/popups/eff-confirmation-popup/eff-confirmation-popup.component";
import { EffPopupHeaderComponent } from "./common/popups/eff-popup-header/eff-popup-header.component";
import { GenerateSamplesPopupComponent } from "./status-components/popups/generate-samples-popup/generate-samples-popup.component";
import { EffRadioGroupComponent } from "./common/eff-radio-group/eff-radio-group.component";
import { SampleTypeStepComponent } from "./status-components/popups/generate-samples-popup/steps/sample-type-step/sample-type-step.component";
import { EffInputComponent } from "./common/eff-input/eff-input.component";
import { ManualSamplesStepComponent } from "./status-components/popups/generate-samples-popup/steps/manual-samples-step/manual-samples-step.component";
import { EffDatePickerComponent } from "./common/eff-date-picker/eff-date-picker.component";
import { EffButtonWithConfirmationComponent } from "./common/eff-button-with-confirmation/eff-button-with-confirmation.component";
import { EffExpansionPanelComponent } from "./common/eff-expansion-panel/eff-expansion-panel.component";
import { EffControlInfoBoxComponent } from "./info-boxes/eff-control-info-box/eff-control-info-box.component";
import { EffSourceDocumentInfoBoxComponent } from "./info-boxes/eff-source-document-info-box/eff-source-document-info-box.component";
import { EffEvidenceInstructionsInfoBoxComponent } from "./info-boxes/eff-evidence-instructions-info-box/eff-evidence-instructions-info-box.component";
import { EffTestInstructionsInfoBoxComponent } from "./info-boxes/eff-test-instructions-info-box/eff-test-instructions-info-box.component";
import { EffReadMoreComponent } from "./common/eff-read-more/eff-read-more.component";
import { AuthorMessageBoxComponent } from "./status-components/components/author-message-box/author-message-box.component";
import { EffWorkflowPopoverComponent } from "./common/eff-workflow-bar/eff-workflow-popover/eff-workflow-popover.component";

@NgModule({
    declarations: [
        EffDetailComponent,
        EffTestingUploadSourceDocumentComponent,
        EffTestingUploadEvidenceComponent,
        EffTestingTestComponent,
        EffTestingReviewComponent,
        EffTestingCompletedComponent,
        EffDetailMainComponent,
        EffWorkflowStatusComponent,
        EffDetailBreadcrumbInfoComponent,
        EffDetailWorkflowHistoryComponent,
        EffDetailFinalConclusionComponent,
        LinkedRisksComponent,
        DocumentManagerNewComponent,
        EffTableComponent,
        EffTableColumnDirective,
        EffDocumentDetailsComponent,
        EffButtonComponent,
        EffIconComponent,
        AddDocumentsPopupComponent,
        EffSelectComponent,
        EffTextareaComponent,
        EffDropFilesAreaComponent,
        EffStepHeaderComponent,
        EffPopoverComponent,
        EffSaveButtonComponent,
        EffBrowseFilesButtonComponent,
        DocumentsListComponent,
        EffColoredSelectorComponent,
        EffInlineTextEditorComponent,
        EffWorkflowBarComponent,
        EffCheckboxComponent,
        EffFinalConclusionBoxComponent,
        EffFinalConclusionPopupComponent,
        EffConfirmationPopupComponent,
        EffStepHeaderComponent,
        EffPopupHeaderComponent,
        GenerateSamplesPopupComponent,
        EffRadioGroupComponent,
        SampleTypeStepComponent,
        ManualSamplesStepComponent,
        EffInputComponent,
        EffDatePickerComponent,
        EffButtonWithConfirmationComponent,
        EffExpansionPanelComponent,
        EffControlInfoBoxComponent,
        EffSourceDocumentInfoBoxComponent,
        EffEvidenceInstructionsInfoBoxComponent,
        EffTestInstructionsInfoBoxComponent,
        EffReadMoreComponent,
        AuthorMessageBoxComponent,
        EffWorkflowPopoverComponent,
    ],
    imports: [CommonModule, SharedModule, ControlModule],
})
export class EffDetailNewModule {
    static forRoot(): ModuleWithProviders<EffDetailNewModule> {
        return {
            ngModule: EffDetailNewModule,
            providers: [],
        };
    }
}
