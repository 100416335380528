<button class="btn btn-cerrix" (click)="openPopup()">
    <i class="fas fa-pencil"></i>
    <span>Edit Text</span>
</button>

<ng-template #editorTemplate>
    <div class="cerrix-editor-in-popup">
        <cerrix-editor
            [value]="loadedValue"
            (valueChange)="valueChange.emit($event)"
        ></cerrix-editor>
    </div>
</ng-template>
