import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { ContextMenuItem } from "././menu-item";

interface Listener {
    target: Element | Document | Window;
    event: keyof HTMLElementEventMap;
    callback: (e: Event & MouseEvent) => any;
    isPassive?: boolean;
}

@Component({
    selector: "cerrix-contextmenu",
    templateUrl: "./contextmenu.component.html",
    styleUrls: ["./contextmenu.component.scss"],
})
export class ContextmenuComponent implements OnInit, OnDestroy {
    @ViewChild("contextmenu", { static: true }) public contextMenu: ElementRef;
    @ViewChild("submenu", { read: ViewContainerRef, static: true })
    subMenuContainer: ViewContainerRef;

    @Input() menu: ContextMenuItem[] = [];
    @Input() visible = false;
    @Input() container: HTMLDivElement;
    @Input() elementClickToggle: HTMLDivElement;
    @Input() openOnDrag = true;
    @Input() theme: "cerrixstyle" | "processeditor" = "cerrixstyle";

    private subMenuMap: WeakMap<ContextMenuItem, ComponentRef<ContextmenuComponent>> =
        new WeakMap();
    private lastCollapsedComponent: ContextmenuComponent;

    // Used in HTML to position menu
    posX = 0;
    posY = 0;

    private lastRightClickPos: { x?: number; y?: number } = { x: null, y: null };
    private isDragged = false;
    private readonly dragThreshold = 5;

    public isSubMenu = false;

    private listeners: Listener[] = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private _cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // Recursively create submenus
        this.createSubMenus();
        this.setupListeners();
    }

    addEventListener(l: Listener) {
        l.target.addEventListener(l.event, l.callback, l.isPassive);
        this.listeners.push(l);
        return l;
    }

    setMenuPosition(pos: { top: number; left: number }) {
        const { width, height } = this.getDimensions();
        this.posX = pos.left + width > window.innerWidth ? window.innerWidth - width : pos.left;
        this.posY = pos.top + height > window.innerHeight ? window.innerHeight - height : pos.top;
    }

    getDimensions() {
        return {
            width: this.contextMenu.nativeElement.offsetWidth,
            height: this.contextMenu.nativeElement.offsetHeight,
        };
    }

    showMenu() {
        // Only updates component once when it appears
        this._cdRef.markForCheck();
        this.visible = true;
    }

    hideMenu() {
        // It is not necessary to update the component when it is invisible
        if (this.visible) {
            this._cdRef.markForCheck();
        }

        this.visible = false;
        if (this.lastCollapsedComponent) {
            this.lastCollapsedComponent.visible = false;
            this.lastCollapsedComponent._cdRef.markForCheck();
        }
    }

    toggleMenu() {
        this.visible = !this.visible;
    }

    // Handler in HTML
    stopPropagation(e: Event) {
        e.stopPropagation();
        return false;
    }

    // Mouseover handler in HTML
    mouseOverItem() {
        if (this.lastCollapsedComponent) {
            this.lastCollapsedComponent.hideMenu();
        }
    }

    // Mouseover handler in HTML
    mouseoverSubMenu(e: MouseEvent, item: ContextMenuItem) {
        this.mouseOverItem();

        const compRef = this.subMenuMap.get(item);
        const instance = compRef.instance;
        instance.showMenu();
        this.lastCollapsedComponent = instance;
        const currentTarget = e.currentTarget as HTMLElement;
        instance.setMenuPosition({
            left: currentTarget.offsetWidth + this.contextMenu.nativeElement.offsetLeft,
            top: this.calculateOffsetTop(currentTarget) + currentTarget.offsetHeight / 2,
        });
    }

    private setupListeners() {
        // Open the menu on left click when it is attached to an element
        if (this.elementClickToggle) {
            this.addEventListener({
                target: this.elementClickToggle,
                event: "click",
                callback: (e) => this.openMenuAtElement(),
            });
        }

        // Close menu when a left click occurs
        this.addEventListener({
            target: window,
            event: "click",
            callback: () => this.hideMenu(),
            isPassive: true,
        });

        // Prevent opening browsers contextmenu when right click on cerrix-contextmenu
        this.addEventListener({
            target: this.contextMenu.nativeElement,
            event: "contextmenu",
            callback: (e) => {
                if (!this.visible) {
                    e.preventDefault();
                    return false;
                }
            },
        });

        /*
         If the contextmenu is NOT a submenu it should open on right click
         If the contextmenu IS a submenu it should close on right click
        */

        if (!this.isSubMenu && this.container) {
            // Keep track of the mousemovement to prevent it from opening when it is dragged too far
            if (!this.openOnDrag) {
                this.addEventListener({
                    target: document,
                    event: "pointerdown",
                    callback: (e) => this.trackLeftMouseDownPosition(e),
                });

                this.addEventListener({
                    target: document,
                    event: "pointerup",
                    callback: (e) => this.setDraggedIfExceedsThreshold(e),
                });
            }

            this.addEventListener({
                target: this.container,
                event: "contextmenu",
                callback: (e: MouseEvent) => this.openMenuAtMousePosition(e),
            });
        } else {
            this.addEventListener({
                target: window,
                event: "contextmenu",
                callback: () => this.hideMenu(),
            });
        }
    }

    private createSubMenus() {
        const factory = this.componentFactoryResolver.resolveComponentFactory(ContextmenuComponent);

        this.menu.forEach((item) => {
            const itemMenu = item.subMenu;
            if (itemMenu) {
                const subMenu = factory.create(this.subMenuContainer.injector);
                const subMenuInstance = subMenu.instance;
                subMenuInstance.menu = itemMenu;
                subMenuInstance.isSubMenu = true;
                subMenu.changeDetectorRef.detectChanges();
                this.subMenuContainer.insert(subMenu.hostView);
                this.subMenuMap.set(item, subMenu);
            }
        });
    }

    public openMenuAtMousePosition(e: MouseEvent) {
        e.preventDefault();

        if (this.isDragged) {
            return;
        }

        const origin = {
            left: e.pageX,
            top: e.pageY,
        };

        this.showMenu();
        this.setMenuPosition(origin);

        return false;
    }

    private openMenuAtElement() {
        const top =
            this.calculateOffsetTop(this.elementClickToggle) + this.elementClickToggle.clientHeight;
        const left = this.calculateOffsetLeft(this.elementClickToggle);
        this.setMenuPosition({ top, left });
        this.showMenu();
    }

    private setDraggedIfExceedsThreshold(e: MouseEvent) {
        if (e.which === 3 || e.button === 2) {
            this.isDragged =
                Math.abs(this.lastRightClickPos.x - e.clientX) > this.dragThreshold ||
                Math.abs(this.lastRightClickPos.y - e.clientY) > this.dragThreshold;
        }
    }

    private trackLeftMouseDownPosition(e: MouseEvent) {
        if (e.which === 3 || e.button === 2) {
            this.lastRightClickPos.x = e.clientX;
            this.lastRightClickPos.y = e.clientY;
        }
    }

    private calculateOffsetTop(offsetParent, accum = 0): number {
        if (offsetParent) {
            accum += offsetParent.offsetTop - (offsetParent.scrollTop || 0);
            return this.calculateOffsetTop(offsetParent.offsetParent, accum);
        } else {
            return accum;
        }
    }

    private calculateOffsetLeft(offsetParent, accum = 0): number {
        if (offsetParent) {
            accum += offsetParent.offsetLeft;
            return this.calculateOffsetLeft(offsetParent.offsetParent, accum);
        } else {
            return accum;
        }
    }

    ngOnDestroy(): void {
        this.listeners.forEach((l) => {
            l.target.removeEventListener(l.event, l.callback, l.isPassive);
        });
    }
}
