<div class="flex-height">
    <process-editor-filter
        class="filter"
        (onApplyFilter)="loadDiagram($event)"
        [isViewer]="readOnly"
        #filter
        *ngIf="showFilter"
    ></process-editor-filter>
    <div id="process-editor" #processEditor [hidden]="!isFilterApplied">
        <process-editor-menubar
            class="menubar"
            *ngIf="!readOnly"
            [actions]="actions"
        ></process-editor-menubar>
        <process-editor-toolbar
            class="toolbar"
            [readOnly]="readOnly"
            [actions]="actions"
            [fullscreenContainer]="processEditor"
            [processEditor]="this"
        ></process-editor-toolbar>

        <div class="editor">
            <as-split unit="percent" #split="asSplit" (dragEnd)="onDragEnd()">
                <as-split-area
                    [size]="splitConfig.shapeArea.size"
                    [visible]="splitConfig.shapeArea.visible"
                    #shapeArea="asSplitArea"
                >
                    <div id="shapes-sidebar" class="shapes-sidebar">
                        <process-editor-shape-sidebar #shapesSidebar [graph]="graph">
                        </process-editor-shape-sidebar>
                    </div>
                </as-split-area>
                <as-split-area
                    [size]="splitConfig.graphArea.size"
                    #graphArea="asSplitArea"
                    class="graph-split-area"
                >
                    <div class="graph">
                        <div
                            #graphContainer
                            id="graphContainer"
                            tabindex="-1"
                            class="graph-container"
                            (wheel)="zoomGraph($event)"
                            (contextmenu)="panContextMenuIntercept($event)"
                        ></div>
                        <canvas #gridCanvas></canvas>
                        <div *ngIf="isLoading" class="fancy-loader"></div>
                    </div>
                </as-split-area>

                <as-split-area
                    [size]="splitConfig.propertiesArea.size"
                    #propertiesArea="asSplitArea"
                >
                    <process-editor-properties-sidebar
                        id="properties"
                        [readOnly]="readOnly"
                        [graph]="graph"
                        [processMetadata]="graph.diagramData && graph.diagramData.metadata"
                        [currentOrganizationId]="currentOrganizationId"
                        [currentBusinessDimensionId]="currentBusinessDimensionId"
                        [cerrixTab]="tab"
                        *ngIf="propertiesLoaded"
                    >
                    </process-editor-properties-sidebar>
                </as-split-area>
            </as-split>
        </div>

        <process-editor-footer
            (onProcessSelected)="loadDiagram($event)"
            [path]="path"
            class="footer"
            #footer
        >
        </process-editor-footer>

        <cerrix-contextmenu
            *ngIf="!readOnly"
            [menu]="contextMenu"
            [container]="graphContainer"
            [openOnDrag]="false"
            theme="processeditor"
        ></cerrix-contextmenu>
    </div>
</div>
