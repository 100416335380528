<ng-container *ngIf="showVideo">
    <div class="cerrix-video-player">
        <ng-container [ngSwitch]="embedSourceType">
            <!-- Youtube -->
            <ng-container *ngSwitchCase="videoSourceTypes.Youtube">
                <iframe
                    [src]="'https://www.youtube-nocookie.com/embed/' + embedKey | safe"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
            </ng-container>

            <!-- Vimeo -->
            <ng-container *ngSwitchCase="videoSourceTypes.Vimeo">
                <iframe
                    [src]="'https://player.vimeo.com/video/' + embedKey | safe"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="url && showValidationMessage">
    <div class="cerrix-video-validation">
        <span
            >Provided url did not match any supported video services
            <i class="fas fa-question-circle" title="Supported video sources: Youtube, Vimeo"></i
        ></span>
    </div>
</ng-container>
