import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app.constants";
import { NotificationModel } from "../models/notificationModel";
import { Observable } from "rxjs";
import { NotificationStandingDataModel } from "../models/notificationStandingDataModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { ModuleType } from "@enums/ModuleType";

@Injectable()
export class NotificationService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "notifications");
    }

    getNotifications(module: ModuleType): Observable<NotificationModel[]> {
        const url = "?module=" + module;
        return this.get<NotificationModel[]>(url);
    }

    getNotification(id: number, module: ModuleType): Observable<NotificationModel> {
        const url = `/${id}/${module}`;
        return this.get<NotificationModel>(url);
    }

    getNotificationStandingData(module: ModuleType): Observable<NotificationStandingDataModel> {
        const url = "/standingdata?module=" + module;
        return this.get<NotificationStandingDataModel>(url);
    }

    getRecipients(module: ModuleType, subType: number, recipients: number[]): Observable<any[]> {
        const url = `/users/${module}/${subType}`;
        const formData = new FormData();
        formData.append("selectedusers", JSON.stringify(recipients));
        return this.post<any[]>(formData, url);
    }

    getOrganizations() {
        const url = "/organizations";
        return this.get<any[]>(url);
    }

    saveNotification(notification: NotificationModel): Observable<NotificationModel> {
        return this.post(notification);
    }

    deleteNotification(id: number): Observable<boolean> {
        const url = `/${id}`;
        return this.delete(url);
    }
}
