import { Component, Input } from "@angular/core";
import { DocumentTemplatesDataService } from "@app/document-templates/document-templates-data.service";
import { MergeFieldTableModel } from "@app/document-templates/models/merge-field-table-model";
import { TargetModule } from "@enums/document/TargetModule";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "document-templates-merge-fields",
    templateUrl: "./document-templates-merge-fields.component.html",
    styleUrls: ["./document-templates-merge-fields.component.scss"],
})
export class DocumentTemplatesMergeFieldsComponent {
    @Input() targetModule: TargetModule;

    protected mergeFieldTables: MergeFieldTableModel[];

    constructor(private dataService: DocumentTemplatesDataService) {}

    async ngOnInit() {
        this.mergeFieldTables = await firstValueFrom(
            this.dataService.getMergeFields(this.targetModule)
        );
    }

    protected copyField(mergeFieldName: string): void {
        navigator.clipboard.writeText(`MERGEFIELD ${mergeFieldName}`);
    }
}
