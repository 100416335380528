<ng-container *ngIf="ready">
    <ng-container *ngFor="let conf of listConfigs">
        <ng-container *ngIf="conf.fields">
            <generic-list-manager [config]="conf"></generic-list-manager>
        </ng-container>

        <ng-container *ngIf="!conf.fields">
            <div class="space-evenly">
                <div class="detail-page-card" *ngIf="conf.grossMatrixModel">
                    <div class="detail-page-card-title">Gross risk matrix</div>
                    <div class="detail-page-card-body padded flex-center">
                        <cerrix-riskmatrix
                            [matrix]="conf.grossMatrixModel"
                            [highlightSelection]="false"
                            [cellSize]="50"
                        ></cerrix-riskmatrix>
                    </div>
                </div>

                <div class="detail-page-card" *ngIf="conf.netMatrixModel">
                    <div class="detail-page-card-title">Net risk matrix</div>
                    <div class="detail-page-card-body padded flex-center">
                        <cerrix-riskmatrix
                            [matrix]="conf.netMatrixModel"
                            [highlightSelection]="false"
                            [cellSize]="50"
                        ></cerrix-riskmatrix>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
