import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from "@angular/forms";
import { SampleType } from "@app/controls/testing/advanced/shared/enums";
import {
    EffectivenessSourceDocumentsModel,
    WorkflowGenerateSamplesModel,
} from "@app/controls/testing/advanced/shared/models";
import { ExcelHelper } from "@app/shared/helpers/excel-helper";

export class SampleTypeFormGroup {
    idColumnFormControl: FormControl = new FormControl();
    populationSizeFormControl: FormControl;
    rowsToSkipFormControl: FormControl = new FormControl();
    numberOfTestSamplesFormControl: FormControl = new FormControl();
    documentIdFormControl: FormControl = new FormControl();

    formGroup: FormGroup = new FormGroup([]);
    submitPerformed: boolean = false;

    constructor() {
        this.populationSizeFormControl = new FormControl();
    }

    init(samplingInformation: WorkflowGenerateSamplesModel, isAutomatic: boolean) {
        this.submitPerformed = false;
        this.clearValidators();

        switch (samplingInformation.sampleType) {
            case SampleType.number:
            case SampleType.text:
                this.numberOfTestSamplesFormControl.setValidators([
                    this.testSamplesGraterThanPopulationSize(),
                    this.maxValue(100, "Test samples"),
                    this.minValue(1, "Test samples"),
                ]);
                this.populationSizeFormControl.setValidators([this.minValue(1, "Population size")]);

                this.formGroup = new FormGroup([
                    this.populationSizeFormControl,
                    this.numberOfTestSamplesFormControl,
                ]);
                break;
            case SampleType.date:
                this.numberOfTestSamplesFormControl.setValidators([
                    this.maxValue(100, "Test samples"),
                    this.minValue(1, "Test samples"),
                ]);
                this.formGroup = new FormGroup([this.numberOfTestSamplesFormControl]);
                break;
            case SampleType.spreadsheet:
                this.numberOfTestSamplesFormControl.setValidators([
                    this.testSamplesGraterThanPopulationSize(),
                    this.maxValue(100, "Test samples"),
                    this.minValue(1, "Test samples"),
                ]);
                this.populationSizeFormControl.setValidators([this.minValue(1, "Population size")]);
                this.formGroup = new FormGroup([
                    this.populationSizeFormControl,
                    this.numberOfTestSamplesFormControl,
                    this.idColumnFormControl,
                    this.rowsToSkipFormControl,
                ]);
                break;
        }
    }

    updateSample(
        samplingInformation: WorkflowGenerateSamplesModel,
        sourceDocumentModel: EffectivenessSourceDocumentsModel
    ) {
        if (samplingInformation.sampleType == SampleType.spreadsheet) {
            var idColumn = ExcelHelper.convertExcelColumnToNumber(this.idColumnFormControl.value);
            samplingInformation.idColumn = idColumn;
            samplingInformation.rowsToSkip = +this.rowsToSkipFormControl.value;
        }

        samplingInformation.populationSize = +this.populationSizeFormControl.value;
        samplingInformation.numberOfTestSamples = +this.numberOfTestSamplesFormControl.value;

        let selectedDocument = sourceDocumentModel.documents.find(
            (x) => x.Guid === this.documentIdFormControl.value
        );

        if (selectedDocument) {
            samplingInformation.documentGuid = selectedDocument.Guid;
            samplingInformation.documentID = selectedDocument.ID;
        }
    }

    checkAndDisplayValidity() {
        this.submitPerformed = true;
        this.formGroup.updateValueAndValidity();
        this.getFormControls().forEach(function (formControl) {
            formControl.updateValueAndValidity();
        });

        var that = this;
        setTimeout(() => {
            that.submitPerformed = false;
        }, 100);
        return this.formGroup.valid;
    }

    isAllFilled() {
        let formControls = this.getFormControls();
        for (var i = 0; i < formControls.length; i++) {
            if (
                formControls[i].value == null ||
                formControls[i].value == undefined ||
                formControls[i].value == ""
            ) {
                return false;
            }
        }
        return true;
    }

    clearValidators() {
        this.getFormControls().forEach(function (formControl) {
            formControl.clearValidators();
            formControl.updateValueAndValidity();
        });
    }

    getFormControls(): AbstractControl[] {
        return this.formGroup.controls as any as AbstractControl[];
    }

    //form validators
    testSamplesGraterThanPopulationSize(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.submitPerformed) {
                return null;
            }
            let populationSize: number = +this.populationSizeFormControl.value;
            let controllerValue = +control.value;
            if (populationSize > 0 && controllerValue > populationSize) {
                return {
                    error: "Too many samples for population size",
                };
            }

            return null;
        };
    }

    maxValue(maxValue: number, name: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.submitPerformed) {
                return null;
            }

            if (control.value > maxValue) {
                return {
                    error: `${name} must be smaller than ${maxValue}`,
                };
            }

            return null;
        };
    }

    minValue(minValue: number, name: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.submitPerformed) {
                return null;
            }

            if (control.value < minValue) {
                return {
                    error: `${name} must be greater than ${minValue}`,
                };
            }

            return null;
        };
    }
}
