<ng-container *ngIf="config && !config.showLoader">
    <div class="row">
        <div
            *ngFor="let quickLink of quickLinks"
            class="quick-link col"
            [style.font-size]="
                config.customConfig.filterValues[filterKeys.ModuleOverview.FontSize] + 'px'
            "
            [class.vertically-aligned]="
                config.customConfig.filterValues[filterKeys.ModuleOverview.LinkContent] ==
                contentType.IconTitle
            "
            [class.icons-only]="
                config.customConfig.filterValues[filterKeys.ModuleOverview.LinkContent] ==
                contentType.Icon
            "
            (click)="openPage(quickLink.page)"
            [attr.data-uiid]="quickLink._datauiid"
        >
            <div class="quick-link-image">
                <i [class]="quickLink.icon + ' fa-fw'" *ngIf="quickLink.icon"></i>
                <div
                    *ngIf="quickLink.imageUrl"
                    class="img"
                    [style.background-image]="'url(' + quickLink.imageUrl + ')'"
                    [style.width]="
                        config.customConfig.filterValues[filterKeys.ModuleOverview.FontSize] * 5 +
                        'px'
                    "
                    [style.height]="
                        config.customConfig.filterValues[filterKeys.ModuleOverview.FontSize] *
                            (5 / 2) +
                        'px'
                    "
                ></div>
            </div>
            <div class="quick-link-info">
                <div class="quick-link-title">
                    {{ quickLink.title }}
                </div>
                <div class="quick-link-description">
                    {{ quickLink.description }}
                </div>
            </div>
        </div>
    </div>
</ng-container>
