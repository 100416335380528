export class ManualPageTypeModel {
    /** ID of the page */
    page: string;

    /** The section the page resides in, e.g. 'releasenotes' */
    section: string;

    /** The full path that is used to navigate to the correct manual section and page. */
    path: string;

    constructor(init?: Partial<ManualPageTypeModel>) {
        if (init) {
            Object.assign(this, init);
        }

        // This makes the object return path by default.
        this.valueOf = function () {
            return this.path;
        };
    }
}
