<div class="breadcrumb-area" data-uiid="new-eff-breadcrumbs">
    <span class="section bold">
        <span title="Control Identifier" data-uiid="new-eff-breadcrumbs-control-identifier">{{
            effectivenessModel.controlIdentifier
        }}</span>
        <span title="Control Name" data-uiid="new-eff-breadcrumbs-control-name">{{
            effectivenessModel.controlName
        }}</span>
    </span>

    <span class="separator">|</span>

    <span class="section">
        <span title="Test plan name" data-uiid="new-eff-breadcrumbs-testplan-name">{{
            effectivenessModel.name
        }}</span>
        <span title="Start Date" data-uiid="new-eff-breadcrumbs-testplan-start-date"
            >({{ effectivenessModel.startDate | dateFormat }}</span
        >
        <span class="mx-0">-</span>
        <span title="End Date" data-uiid="new-eff-breadcrumbs-testplan-end-date"
            >{{ effectivenessModel.endDate | dateFormat }})</span
        >
    </span>

    <span class="separator">|</span>

    <span class="section">
        <span title="Period Name" data-uiid="new-eff-breadcrumbs-testplan-period-name">{{
            effectivenessModel.groupName
        }}</span>
    </span>
</div>
