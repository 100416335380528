<div class="detail-page-summary" *ngIf="detailModel">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>Organization:</b> {{ detailModel.organizationName }}</div>
            <div class="property"><b>Business Dimension:</b> {{ detailModel.businessName }}</div>
        </div>
        <div class="col-6">
            <div class="property">
                <b>Registration Date:</b> {{ detailModel.registrationDate | dateFormat }}
            </div>
            <div class="property"><b>Registered By:</b> {{ detailModel.registeredBy }}</div>
        </div>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Business Details</div>
    <div class="detail-page-card-body padded form-group-styling">
        <div class="fancy-loader" *ngIf="!detailModel"></div>
        <div class="row" *ngIf="detailModel">
            <div class="col-6">
                <div class="form-group">
                    <label>Description</label>
                    <textarea
                        class="form-control form-control-sm"
                        [value]="detailModel.businessDescription"
                        readonly
                    ></textarea>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Comment</label>
                    <textarea
                        class="form-control form-control-sm"
                        [value]="detailModel.businessComment"
                        readonly
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
