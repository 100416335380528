import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "eff-confirmation-popup",
    templateUrl: "./eff-confirmation-popup.component.html",
    styleUrls: ["./eff-confirmation-popup.component.scss"],
})
export class EffConfirmationPopupComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EffConfirmationPopupComponent>
    ) {}

    get icon(): string {
        return this.data.confirmType == "warning-button" ? "warning" : "";
    }
}
