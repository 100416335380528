import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

@Component({
    selector: "cerrix-collapsing-box",
    templateUrl: "./cerrix-collapsing-box.component.html",
    styleUrls: ["./cerrix-collapsing-box.component.scss"],
})
export class CerrixCollapsingBoxComponent {
    @Input() boxTitle: string;
    @Input() isCollapsed = false;
    @Output() isCollapsedChange = new EventEmitter<boolean>();

    @ContentChild("title") titleTemplate: TemplateRef<any>;
    @ContentChild("content") contentTemplate: TemplateRef<any>;

    // We have to define the method like this to avoid scoping issues.
    toggleCollapse = () => {
        this.isCollapsed = !this.isCollapsed;
        this.isCollapsedChange.emit(this.isCollapsed);
    };

    setCollapsed = (collapsed: boolean) => {
        this.isCollapsed = collapsed;
        this.isCollapsedChange.emit(this.isCollapsed);
    };
}
