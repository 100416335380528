<div class="main-page-area">
    <div class="main-page-content">
        <eff-step-header
            id="testing-completed"
            [controlGuid]="effectivenessModel.controlGuid"
            title="Completed"
            saveButtonLabel="Reopen"
            [hideSaveButton]="hideReopenButton()"
            (onSaveChanges)="reopenTestPlan()"
            [showNonOccurrenceWarningArea]="isNonOccurrence"
        >
        </eff-step-header>

        <div class="d-flex flex-column grid-conclusion-wrapper">
            <eff-detail-final-conclusion
                [effectivenessModel]="effectivenessModel"
            ></eff-detail-final-conclusion>
            <div class="d-flex flex-column">
                <eff-checkbox
                    class="expand-collapse-all"
                    label="Open all"
                    (modelChange)="expandCollapseAll($event)"
                ></eff-checkbox>
                <div class="main-grid d-flex flex-column">
                    <div *ngFor="let viewSample of viewSamples">
                        <div class="grid-header">
                            <div class="bold-text">Sample {{ viewSample.sample.name }}</div>
                            <eff-inline-text-editor
                                label="Add sample remark"
                                [(model)]="viewSample.sample.remarks"
                                [disabled]="!stepPermissions.isEditSampleRemarkAllowed"
                                (complete)="saveRemarksChange(viewSample.sample)"
                            ></eff-inline-text-editor>
                        </div>
                        <eff-table
                            [dataSource]="viewSample.gridDataSource"
                            [columns]="displayedColumns"
                            [tableConfig]="tableConfig"
                            [expandedRowTemplate]="expandedTemplate"
                        >
                            <ng-template #expandedTemplate let-item="item">
                                <div class="grid-expanded-row">
                                    <div class="table-row">
                                        <div class="table-cell documents">
                                            <div class="d-flex flex-column gap-3">
                                                <div
                                                    class="d-flex flex-column gap-2"
                                                    *ngIf="item.noEvidence"
                                                >
                                                    <eff-checkbox
                                                        [disabled]="true"
                                                        [checked]="true"
                                                        label="No evidence"
                                                    ></eff-checkbox>
                                                    <div>
                                                        {{ item.noEvidenceReason }}
                                                    </div>
                                                </div>
                                                <documents-list
                                                    class="documets-list-small-droparea"
                                                    [documents]="item.documents"
                                                    [canAdd]="false"
                                                    [canEdit]="false"
                                                    [canDelete]="false"
                                                ></documents-list>
                                            </div>
                                        </div>
                                        <div class="table-cell tester-comment">
                                            <div class="comment">
                                                {{ item.commentTester }}
                                            </div>
                                        </div>
                                        <div class="table-cell reviewer-comment">
                                            <div class="comment">
                                                {{ item.commentReviewer }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-template column-name="name" let-item="item">
                                <div class="d-flex flex-row gap-3 align-items-center">
                                    <div
                                        [ngClass]="{ 'sample-name-cell': item.isSample }"
                                        class="regular-text"
                                    >
                                        {{ item.isSample ? "Sample" : "Step" }} {{ item.name }}
                                    </div>

                                    <div
                                        class="d-flex flex-row align-items-center gap-1"
                                        *ngIf="item.documents.length > 0"
                                    >
                                        <eff-icon
                                            class="remark-icon"
                                            icon="attachment_outlined"
                                        ></eff-icon>
                                        <div>({{ item.documents.length }})</div>
                                    </div>
                                </div>
                            </ng-template>

                            <ng-template column-name="scoreTester" let-item="item">
                                <div class="d-flex flex-row align-items-center status-cell">
                                    <div
                                        class="status-bullet"
                                        [ngClass]="getScoreColor(item)"
                                    ></div>
                                    <div>{{ item.scoreName }}</div>
                                </div>
                            </ng-template>

                            <ng-template column-name="testerComment" let-item="item">
                                <div *ngIf="!item.IsExpanded && item.commentTester">
                                    <eff-icon
                                        class="remark-icon"
                                        icon="comment_outlined"
                                    ></eff-icon>
                                </div>
                            </ng-template>

                            <ng-template column-name="reviewerComment" let-item="item">
                                <div *ngIf="!item.IsExpanded && item.commentReviewer">
                                    <eff-icon
                                        class="remark-icon"
                                        icon="comment_outlined"
                                    ></eff-icon>
                                </div>
                            </ng-template>
                        </eff-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="side-page-area">
        <eff-test-instructions-info-box
            [samples]="samples"
            [effectivenessModel]="effectivenessModel"
            [showEvidenceUploadInstructions]="true"
            *ngIf="samples && effectivenessModel"
        >
        </eff-test-instructions-info-box>
        <eff-evidence-instructions-info-box
            [samples]="samples"
            [instructions]="effectivenessModel.evidenceUploadInstructions"
        ></eff-evidence-instructions-info-box>
        <eff-source-document-info-box
            [effectivenessModel]="effectivenessModel"
            [hideSampling]="false"
            [stepPermissions]="stepPermissions"
        ></eff-source-document-info-box>
        <eff-control-info-box [controlGuid]="effectivenessModel.controlGuid"></eff-control-info-box>
    </div>
</div>
