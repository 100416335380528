import { IdNameColorModel } from "@models/generic/IdNameColorModel";

export enum JobStatus {
    Unknown = 0,
    Scheduled = 1,
    Running = 2,
    Paused = 3,
    WaitingForUser = 4,
    Finished = 5,
    Failed = 6,
    Cancelled = 7,
}

export function getJobStatusColors(): IdNameColorModel[] {
    let result: IdNameColorModel[] = [
        new IdNameColorModel(+JobStatus.Unknown, "Unkown", "#666666"),
        new IdNameColorModel(+JobStatus.Scheduled, "Scheduled", "#0000FF"),
        new IdNameColorModel(+JobStatus.Running, "Running", "#00FFFF"),
        new IdNameColorModel(+JobStatus.Paused, "Paused", "#FFFF00"),
        new IdNameColorModel(+JobStatus.WaitingForUser, "WaitingForUser", "#FFFF00"),
        new IdNameColorModel(+JobStatus.Finished, "Finished", "#008000"),
        new IdNameColorModel(+JobStatus.Failed, "Failed", "#800000"),
        new IdNameColorModel(+JobStatus.Cancelled, "Cancelled", "#FF9900"),
    ];
    return result;
}
