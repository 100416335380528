import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import {
    CerrixButtonComponent,
    CerrixFieldWrapperComponent,
    CerrixTextareaComponent,
    CerrixTitleComponent,
    CerrixValidators,
} from "@cerrix/components";
import { IncidentWorkflowChangeInputModel } from "./models/incident-workflow-change-input.model";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "incident-workflow-change",
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        CerrixTitleComponent,
        CerrixButtonComponent,
        CerrixFieldWrapperComponent,
        CerrixTextareaComponent,
    ],
    templateUrl: "./incident-workflow-change.component.html",
    styleUrl: "./incident-workflow-change.component.scss",
})
export class IncidentWorkflowChangeComponent {
    @Input() public dialogData: IncidentWorkflowChangeInputModel = inject(MAT_DIALOG_DATA);

    private maxTextLength = 32767;
    private dialogRef: MatDialogRef<IncidentWorkflowChangeInputModel, { comment: string }> =
        inject(MatDialogRef);

    protected commentForm = new FormControl<string>(null, [
        CerrixValidators.required("Comment is required"),
        CerrixValidators.maxLength(this.maxTextLength, "Comment exceeds max length"),
    ]);

    protected onClick() {
        if (this.commentForm.valid) {
            this.dialogRef.close({ comment: this.commentForm.value });
        } else if (!this.commentForm.touched) {
            this.commentForm.markAllAsTouched({ emitEvent: true });
        }
    }
}
