import { Component } from "@angular/core";
import { DashboardConstants } from "@app/dashboard/shared/DashboardConstants";
import { DashboardLegendType } from "@app/dashboard/shared/enums/DashboardLegendType.enum";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { MatrixModel } from "@models/generic/MatrixModel";
import { MatrixSelectionModel } from "@models/generic/MatrixSelectionModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { BaseChartWidget } from "../BaseChartWidget";
import { RiskDataService } from "./../../../risk/services/RiskDataService";
import { nameof, toPromise } from "@methods/CommonMethods";
import { RiskWorkspaceModel } from "@app/risk/models/RiskWorkspaceModel";

@Component({
    selector: "risk-matrix-widget",
    templateUrl: "./risk-matrix-widget.component.html",
    styleUrls: ["./risk-matrix-widget.component.scss"],
})
export class RiskMatrixWidgetComponent extends BaseChartWidget {
    matrix: MatrixModel;
    cellSize = 60;
    enableSummaryClick = true;

    get showSummary(): boolean {
        return this.getFilter(WidgetFilterKey.RiskMatrix.ShowSummary, true);
    }

    get showNetRisks(): boolean {
        return this.getFilter(WidgetFilterKey.RiskMatrix.NetOrGross, 1) == 1;
    }

    get showLegend(): boolean {
        return +this.config.customConfig.legendType != DashboardLegendType.off;
    }

    constructor(
        private _riskDS: RiskDataService,
        private _pages: Pages,
        private _toastr: ToastrService,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            nameof<RiskWorkspaceModel>("organizationID").toString();

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            nameof<RiskWorkspaceModel>("businessDimensionIDs").toString();

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.FrameworkDimension] =
            nameof<RiskWorkspaceModel>("frameworkDimensionIDs").toString();
    }

    async load() {
        this.config.showLoader = true;
        await this.loadMatrix();
        super.load();
    }

    onResize() {
        const cellMax =
            this.matrix.impactSize >= this.matrix.likelihoodSize
                ? this.matrix.impactSize
                : this.matrix.likelihoodSize;

        const minAxis =
            this.config.customConfig.gridsterConfig.cols >
            this.config.customConfig.gridsterConfig.rows
                ? this.config.customConfig.gridsterConfig.rows
                : this.config.customConfig.gridsterConfig.cols;

        const widgetWidth = minAxis * DashboardConstants.cellSizing;

        this.cellSize =
            (widgetWidth - 100 - (this.showLegend ? 100 : 0) - (this.showSummary ? 50 : 0)) /
            cellMax;
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBaseRiskMatrixPreset();
        this.openPreset(this._pages.RiskOverview, preset);
    }

    async drillDownFromCell(model: MatrixSelectionModel) {
        const preset = await this.getBaseRiskMatrixPreset();
        preset.preset.searchKeywords.push({
            col: this.getLikelihoodColumnToSearch(),
            searchValue: this.matrix.likelihoodInfo[model.Likelihood].Name,
            compareType: ComparisonType.Contains,
            specific: false,
            remoteSearch: false,
        });

        preset.preset.searchKeywords.push({
            col: await this.getImpactColumnToSearch(),
            searchValue: this.matrix.impactInfo[model.Impact].Name,
            compareType: ComparisonType.Contains,
            specific: false,
            remoteSearch: false,
        });

        this.openPreset(this._pages.RiskOverview, preset);
    }

    async drillDownFromSummary(clickedIndex: number) {
        if (!this.enableSummaryClick) {
            return;
        }

        const preset = await this.getBaseRiskMatrixPreset();

        preset.preset.searchKeywords.push({
            col: this.showNetRisks
                ? nameof<RiskWorkspaceModel>("netScoreColor")
                : nameof<RiskWorkspaceModel>("grossScoreColor"),
            searchValue: this.matrix.colors[clickedIndex],
            compareType: ComparisonType["Is equal to"],
            specific: false,
            remoteSearch: false,
        });

        this.openPreset(this._pages.RiskOverview, preset);
    }

    private async getBaseRiskMatrixPreset() {
        const preset = await super.getBasePreset();

        const riskCatalogue = this.getFilter(WidgetFilterKey.RiskMatrix.RiskCatalogue);
        if (riskCatalogue > 0) {
            preset.preset.searchKeywords.push({
                col: nameof<RiskWorkspaceModel>("riskCatalogueID"),
                searchValue: riskCatalogue,
                compareType: ComparisonType.Contains,
                specific: true,
                remoteSearch: false,
            });
        }

        return preset;
    }

    private async loadMatrix() {
        const keys = WidgetFilterKey.GlobalFilter;
        const filters = this.getFilters();
        if (!filters[keys.Organization]) {
            filters[keys.Organization] = -1;
        }

        const matrix = await toPromise(this._riskDS.getRiskMatrixChart(filters));
        this.config.noData =
            !matrix || !Object.values(matrix.summaryContent).some((scores) => scores > 0);
        if (this.config.noData) {
            return null;
        }

        this.matrix = matrix;

        if (filters[this.filterKeys.RiskMatrix.ImpactScale] > 0 && !this.matrix.customImpactName) {
            this._toastr.warning(
                "Configured Impact Scale not found, fallback to default applied.",
                "Configuration warning"
            );
            this.enableSummaryClick = true;
        } else {
            this.enableSummaryClick = filters[this.filterKeys.RiskMatrix.ImpactScale] == 0;
        }

        this.onResize();
    }

    private async getImpactColumnToSearch() {
        const defaultColumn = this.showNetRisks
            ? nameof<RiskWorkspaceModel>("netImpact")
            : nameof<RiskWorkspaceModel>("grossImpact");

        const customScale = this.getFilter(this.filterKeys.RiskMatrix.ImpactScale);
        if (!customScale) {
            return defaultColumn;
        }

        return (this.showNetRisks ? "netImpactScales_" : "grossImpactScales_") + customScale;
    }

    private getLikelihoodColumnToSearch() {
        return this.showNetRisks
            ? nameof<RiskWorkspaceModel>("netLikelihood")
            : nameof<RiskWorkspaceModel>("grossLikelihood");
    }
}
