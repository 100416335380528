<div class="modal-header">
    <h4 class="modal-title pull-left">Manage test periods</h4>
</div>
<div class="modal-body">
    <generic-list-manager *ngIf="config" [config]="config" skipReloadConfirm="true">
    </generic-list-manager>
</div>
<div class="modal-footer">
    <button class="btn btn-dark" (click)="modalRef.hide()">Close</button>
</div>
