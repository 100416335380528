import { Component } from "@angular/core";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";
import { CerrixThemingService, ThemePreference } from "@app/shared/services/cerrix-theming.service";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { UserCurrentInfoModel } from "@app/user/user-profile/shared/user-current-info-model";

@Component({
    selector: "navbar-user",
    templateUrl: "./navbar-user.component.html",
    styleUrls: ["../navbar-plugin-base.scss", "./navbar-user.component.scss"],
})
export class NavbarUserComponent {
    userInfo: UserCurrentInfoModel;
    hideNameInToolbar = false;
    themes = ThemePreference;

    constructor(
        userDS: UserService,
        private _authenticationDs: UserAuthenticationService,
        public _tabservice: TabService,
        public _pages: Pages,
        public _themeService: CerrixThemingService
    ) {
        userDS.getUserInfoBase().subscribe((ui) => {
            this.userInfo = ui;
            setTimeout(() => {
                this.hideNameInToolbar = true;
            }, 5000);
        });
    }

    openProfile() {
        this._tabservice.generateTab(this._pages.Profile);
    }

    signOut() {
        this._authenticationDs.logout().subscribe(() => {
            window.location.href = window.location.origin;
        });
    }
}
