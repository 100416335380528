export enum EffectivenessStatusType {
    reviewNonOccurrence = -4,
    nonOccurrence = -3,
    waitingForSourceDocuments = -2,
    waitingForSamples = -1,
    undefined = 0,
    requestEvidence = 1,
    readyForAssessment = 2,
    assessmentInProgress = 3,
    waitingForConclusion = 4,
    waitingForReviewer = 5,
    completed = 6,
    nonOccurrenceCompleted = 7
}
