import { Component } from "@angular/core";
import { AbstractEffTestingStatusComponent } from "../abstract-eff-testing-status-component";
import { EffTableConfig } from "../../common/eff-table/models/eff-table-config.model";
import { EffTableColumnConfig } from "../../common/eff-table/models/eff-table-column-config.model";
import { EvidenceSampleTestStep } from "../models/evidence-sample-step.model";
import { SampleView } from "../models/sample-view.model";
import _ from "underscore";

@Component({
    selector: "eff-testing-upload-evidence",
    templateUrl: "./eff-testing-upload-evidence.component.html",
    styleUrls: ["./eff-testing-upload-evidence.component.scss"],
})
export class EffTestingUploadEvidenceComponent extends AbstractEffTestingStatusComponent {
    protected loadSamples: boolean = true;
    evidenceUploadValid: boolean = false;

    tableConfig: EffTableConfig = {
        ExpandableRow: true,
        SeparateRows: true,
        RowClassFunc(item: EvidenceSampleTestStep) {
            if (item.isSample) {
                return "sample-row";
            }
            return "";
        },
    };

    displayedColumns: Array<EffTableColumnConfig> = [
        { PropertyName: "name", DisplayedName: "Name", Width: "70%", IsTemplate: true },
        { PropertyName: "evidence", DisplayedName: "Evidence", IsTemplate: true, Width: "30%" },
    ];

    onChangesDebounce = _.debounce(this.onChanges, 400, false);

    override onLoad() {
        if (this.pageState.isRejected) {
            this.displayedColumns = [
                { PropertyName: "name", DisplayedName: "Name", IsTemplate: true, Width: "40%" },
                {
                    PropertyName: "testerComment",
                    DisplayedName: "Comment tester",
                    IsTemplate: true,
                    Width: "30%",
                },
                { PropertyName: "score", DisplayedName: "Score", IsTemplate: true, Width: "30%" },
            ];
        }

        this.validateEvidence();
    }

    onDocumentsChanged(documents: any) {
        this.validateEvidence();
    }

    onChanges() {
        this.validateEvidence();
    }

    validateEvidence(showWarning: boolean = false) {
        let valid = true;
        this.viewSamples.forEach((sampleView: SampleView) => {
            if (!this.sampleOrAnyTestStepHasEvidence(sampleView, showWarning)) {
                valid = false;
            }
        });
        this.evidenceUploadValid = valid;
    }

    public sampleOrAnyTestStepHasEvidence(
        sampleView: SampleView,
        showWarning: boolean = false
    ): boolean {
        let sampleItem = sampleView.gridDataSource.data.find((s) => s.isSample);
        let valid = true;

        if (sampleItem.noEvidence) {
            if (
                sampleItem.noEvidenceReason == "" ||
                sampleItem.noEvidenceReason == null ||
                sampleItem.noEvidenceReason == undefined
            ) {
                if (showWarning) {
                    this._toastr.warning(
                        `Please enter no evidence reason on sample '${sampleItem.name}"`
                    );
                }
                valid = false;
            }
        } else {
            if (
                this.visibleDocuments(sampleView.sample.documents).length == 0 &&
                !sampleView.sample.testSteps.some(
                    (ts) => this.visibleDocuments(ts.documents).length > 0
                )
            ) {
                if (showWarning) {
                    this._toastr.warning(
                        `Please upload evidence on sample '${sampleItem.name}"`,
                        "",
                        { timeOut: 12500 }
                    );
                }
                valid = false;
            }
        }

        return valid;
    }

    hasEvidence(item: EvidenceSampleTestStep) {
        return this.visibleDocuments(item.documents)?.length > 0;
    }

    getEvidenceStatusClass(item: EvidenceSampleTestStep, allItems: EvidenceSampleTestStep[]) {
        if (item.isSample && item.noEvidence) {
            return "status-no-evidence";
        }

        if (this.visibleDocuments(item.documents).length > 0) {
            return "status-has-evidence";
        }

        if (!item.isSample) {
            return "status-evidence-optional";
        } else {
            if (
                allItems.some((gridItem) => this.visibleDocuments(gridItem.documents)?.length > 0)
            ) {
                return "status-evidence-optional";
            }
        }

        return "status-needs-evidence";
    }

    getEvidenceStatusText(item: EvidenceSampleTestStep, allItems: EvidenceSampleTestStep[]) {
        if (item.isSample && item.noEvidence) {
            return "No evidence";
        }

        if (this.visibleDocuments(item.documents)?.length > 0) {
            return "Done";
        }

        if (!item.isSample) {
            return "Evidence optional";
        } else {
            if (
                allItems.some((gridItem) => this.visibleDocuments(gridItem.documents)?.length > 0)
            ) {
                return "Evidence optional";
            }
        }

        return "Evidence required";
    }

    async saveChanges(doRefresh: boolean = false) {
        await this.saveSampleChanges();
        if (doRefresh) {
            this.cerrixTab.refresh();
        }
    }

    async completeEvidenceUpload() {
        this.validateEvidence(true);
        if (!this.evidenceUploadValid) {
            return;
        }

        await this.saveChanges();
        this._workflowDs
            .postWorkflowCompleteEvidenceUpload(this.effectivenessModel.guid)
            .subscribe(() => {
                this.cerrixTab.refresh();
            });
    }

    hasNoEvidenceAndDocuments(item: EvidenceSampleTestStep) {
        return this.visibleDocuments(item.documents).length > 0 && item.noEvidence;
    }
}
