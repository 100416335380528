import { PageLookup } from "@constants/pages/page-lookup";
import {
    Input,
    OnInit,
    Component,
    ComponentFactoryResolver,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { PageMapping } from "@models/generic/TabModels/PageMapping";

@Component({
    // tslint:disable-next-line:directive-selector
    selector: "tab-pane",
    templateUrl: "./tab-pane.component.html",
    styleUrls: ["./tab-pane.component.scss"],
})
export class TabPaneComponent implements OnInit {
    @Input() cerrixTab: TabModel;

    @ViewChild("customComponent", { read: ViewContainerRef, static: true }) customComponent;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _pageLookup: PageLookup
    ) {}

    ngOnInit() {
        if (this.cerrixTab.refresh == null) {
            this.cerrixTab.refresh = () => {
                if (this.cerrixTab.beforeRefresh != null && !this.cerrixTab.beforeRefresh()) {
                    return;
                }
                this.hardRefresh(this.cerrixTab);
            };
        }

        this.hardRefresh(this.cerrixTab);
    }

    hardRefresh(tab: TabModel) {
        if (this.customComponent) {
            this.customComponent.clear();
        }

        this.setDefaults(tab);

        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(tab.comp);
        const componentRef = this.customComponent.createComponent(componentFactory);
        if (componentRef.instance) {
            this.cerrixTab.menu = null;

            componentRef.instance.id = tab.id;
            componentRef.instance.tab = tab;
            componentRef.instance.tabID = tab.identifier;
            componentRef.instance.config = tab.config;
        }
    }

    setDefaults(tab: TabModel) {
        // Reset showloader flag if it is enabled by default for the tab.
        const lookup = this._pageLookup[tab.lookupname] as PageMapping;
        if (lookup.ShowLoader) {
            tab.showLoader = true;
        }
    }
}
