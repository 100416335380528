import { Component, Input } from "@angular/core";
import { AuditUniverseModel } from "@app/audit-universe/shared/data-models/audit-universe-model";
import { FormLinkableModule } from "@app/forms/enums/FormLinkableModule";
import { FormsLinkModel } from "@app/forms/models/other/FormsLinkModel";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { LinkModel } from "@models/generic/LinkModel";

@Component({
    selector: "audit-universe-linked-forms",
    templateUrl: "./audit-universe-linked-forms.component.html",
    styleUrls: ["./audit-universe-linked-forms.component.scss"],
})
export class AuditUniverseLinkedFormsComponent {
    @Input() model: AuditUniverseModel;
    @Input() disabled: boolean;

    protected linkedModule: FormLinkableModule = FormLinkableModule.AuditUniverse;
    protected linkableForms: FormsLinkModel[] = [];
    protected unlinked: FormsLinkModel[] = [];
    protected linked: FormsLinkModel[] = [];
    protected isLoading: boolean = true;

    constructor(private formsDataService: FormsDataService) {}

    public load(): void {
        this.formsDataService
            .getLinkableForms(FormLinkableModule.AuditUniverse)
            .then((linkedForms: FormsLinkModel[]): void => {
                this.linkableForms = linkedForms;

                this.loadForms();

                this.isLoading = false;
            });
    }

    public linkedItemsChanged(linked: LinkModel[]): void {
        this.model.formIds = linked.map((link: LinkModel): number => link.id);

        this.loadForms();
    }

    private loadForms(): void {
        const linkedItems = [];
        const unlinkedItems = [];
        if (this.model.formIds && this.model.formIds.length > 0) {
            for (const form of this.linkableForms) {
                if (this.model.formIds.includes(form.id)) {
                    linkedItems.push(form);
                } else {
                    unlinkedItems.push(form);
                }
            }
        } else {
            unlinkedItems.addRange(this.linkableForms);
        }

        this.linked = linkedItems;
        this.unlinked = unlinkedItems;
    }
}
