import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ProcessedDocumentsService } from "@app/document-intelligence/services/processed-documents-service";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { firstValueFrom } from "rxjs";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ProcessedDocumentUploadModel } from "@app/document-intelligence/models/ProcessedDocumentUploadModel";
import { ProcessedDocumentOverviewModel } from "@app/document-intelligence/models/ProcessedDocumentOverviewModel";
import { nameof } from "@methods/CommonMethods";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { ToastrService } from "ngx-toastr";
import { DocumentModel } from "@models/documents/documentmodel";

@Component({
    selector: "processed-document-overview",
    templateUrl: "./processed-document-overview.component.html",
    styleUrls: ["./processed-document-overview.component.scss"],
})
export class ProcessedDocumentOverviewComponent implements OnInit {
    @ViewChild("uploadDocument", { static: true }) uploadDocumentTemplate: ElementRef;

    tab: TabModel;
    config: GenericListConfig;

    private documentToUpload: DocumentModel;

    constructor(
        private _ds: ProcessedDocumentsService,
        private _promptService: CerrixPromptService,
        private _tabService: TabService,
        private _pages: Pages,
        private _toastr: ToastrService
    ) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        this.createListConfig();
    }

    onDocumentsChange(documents: DocumentModel[]) {
        if (documents && documents.length > 1) {
            this._toastr.warning("Only one document can be uploaded at a time.");

            this.documentToUpload = null;

            return;
        }

        this.documentToUpload = documents[0];
    }

    protected async addDocumentToProcess(): Promise<any> {
        let prompt: CerrixPromptComponent;
        try {
            prompt = this._promptService.prompt({
                hasBackdrop: false,
                data: {
                    title: "Upload document",
                    customTemplate: this.uploadDocumentTemplate,
                    confirmButton: {
                        text: "Upload",
                    },
                },
            });

            const result = await firstValueFrom(prompt.getResult());
            if (result) {
                const uploadModel = <ProcessedDocumentUploadModel>{
                    Documents: [this.documentToUpload],
                };

                await firstValueFrom(this._ds.postDocument(uploadModel));

                this._toastr.success(
                    "Document uploaded successfully. A new scheduled job has been created to process the document."
                );
            } else {
                this.documentToUpload = null;
            }
        } finally {
            prompt?.close();
            this.documentToUpload = null;
        }
    }

    private createListConfig() {
        this.config = <GenericListConfig>{
            name: "Processed Documents",
            idProp: nameof<ProcessedDocumentOverviewModel>("id"),
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xxl",
            hideEmptyResult: false,
            fields: [
                {
                    fieldName: nameof<ProcessedDocumentOverviewModel>("title"),
                    prettyName: "Title",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ProcessedDocumentOverviewModel>("chunks"),
                    prettyName: "Processed / Total chunks",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ProcessedDocumentOverviewModel>("risks"),
                    prettyName: "Processed / Total risks",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<ProcessedDocumentOverviewModel>("controls"),
                    prettyName: "Processed / Total controls",
                    fieldType: GenericListFieldType.Text,
                },
            ],
            dataMethod: () => this._ds.getOverview(),

            editOverride: (row) => {
                this.openDetail(row);
            },
        };
    }

    private openDetail(row: ProcessedDocumentOverviewModel) {
        if (row) {
            this._tabService.generateTab(this._pages.ProcessedDocumentDetail, row.id);
        }
    }
}
