import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-get-samples-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlGetSamplesApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Samples API",
            httpMethod: "GET",
            path: "/controls/testing/advanced/effectiveness/{effectivenessGuid}/automation/samples",

            parameters: [
                {
                    name: "effectivenessGuid",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: [
                        {
                            testSampleId: "uuid",
                            testSampleName: "string",
                            testSteps: [
                                {
                                    testStepId: "uuid",
                                    testStepName: "string",
                                    requiredDocuments: "string",
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    }
}
