import { Component, OnInit } from "@angular/core";
import { Pages } from "@constants/pages/Pages";
import { FilterType } from "@enums/FilterType";
import { FormatType } from "@enums/FormatType";
import { ModuleSubType } from "@enums/ModuleSubType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { nameof } from "@methods/jeffs-toolkit";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { BusinessNavigatorOverviewModel } from "../models/business-navigator-overview-model";
import { BusinessNavigatorRequestModel } from "../models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "../services/business-navigator-data.service";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "business-navigator-overview",
    templateUrl: "./business-navigator-overview.component.html",
})
export class BusinessNavigatorOverviewComponent implements OnInit {
    tabID: string;
    tab: TabModel;

    moduleType = ModuleType.NAV;
    module = "Business Assessment";
    appendToModuleType = ModuleSubType[ModuleSubType.BusinessNavigator];

    idProp = this.getProperty((x) => x.assessmentID);

    data: Promise<any[]>;
    headerLookup: any = {};
    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: this.getProperty((x) => x.organizationID),
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: this.getProperty((x) => x.businessID),
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: this.getProperty((x) => x.score),
            actionColumn: this.getProperty((x) => x.scoreColor),
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: this.getProperty((x) => x.initialReviewDate),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: this.getProperty((x) => x.nextReviewDate),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: this.getProperty((x) => x.processDiagramDate),
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
    ];

    numberProps: string[] = [this.getProperty((x) => x.numberOfReviews)];
    dateProps: string[] = [
        this.getProperty((x) => x.initialReviewDate),
        this.getProperty((x) => x.nextReviewDate),
        this.getProperty((x) => x.processDiagramDate),
    ];

    permissions: WorkspaceModulePermissionModel = new WorkspaceModulePermissionModel();

    initialLoadCompleted = false;

    constructor(
        private dataService: BusinessNavigatorDataService,
        private tabService: TabService,
        private pages: Pages,
        private _permissions: PermissionsService
    ) {}

    ngOnInit() {
        if (this._permissions.permissions.HasBDManagementAccess) {
            this.permissions.canAdd = true;
            this.permissions.canOpen = true;
            this.permissions.canPublish = true;
        } else {
            this.tab.close(false);
            return;
        }

        this.mapHeaders();
        this.setData();

        this.tabService.listeners.addGlobalListener(
            TabEventListenerType.OnSave,
            this.pages.BusinessAssessment,
            () => {
                this.tab.refresh();
            },
            this.tab
        );
    }

    private mapHeaders() {
        this.headerLookup[this.getProperty((x) => x.organization)] = "Organization";
        this.headerLookup[this.getProperty((x) => x.business)] = "Business dimension";
        this.headerLookup[this.getProperty((x) => x.reviewers)] = "Reviewers";
        this.headerLookup[this.getProperty((x) => x.frequency)] = "Frequency";
        this.headerLookup[this.getProperty((x) => x.initialReviewDate)] = "Initial review date";
        this.headerLookup[this.getProperty((x) => x.nextReviewDate)] = "Next review date";
        this.headerLookup[this.getProperty((x) => x.score)] = "Score";
        this.headerLookup[this.getProperty((x) => x.numberOfReviews)] = "# of reviews";
        this.headerLookup[this.getProperty((x) => x.processDiagramAuthor)] = "Process author";
        this.headerLookup[this.getProperty((x) => x.processDiagramAuthorizedBy)] =
            "Process authorized by";
        this.headerLookup[this.getProperty((x) => x.processDiagramDate)] = "Process date created";
        this.headerLookup[this.getProperty((x) => x.processDiagramVersion)] = "Process version";
    }

    private setData() {
        this.data = this.dataService.getOverview();
    }

    openDetail(review: BusinessNavigatorOverviewModel) {
        this.openTab(review);
    }

    add() {
        this.openTab();
    }

    private openTab(review?: BusinessNavigatorOverviewModel) {
        const tabGuid = review ? `${review.organizationID}-${review.businessID}` : null;
        const requestModel = review
            ? <BusinessNavigatorRequestModel>{
                  organizationID: review.organizationID,
                  businessID: review.businessID,
                  hasAssessment: true,
              }
            : null;

        this.tabService.generateTab(
            this.pages.BusinessAssessment,
            tabGuid,
            this.module,
            requestModel
        );
    }

    private getProperty(predicate: (obj: BusinessNavigatorOverviewModel) => any) {
        const key = nameof<BusinessNavigatorOverviewModel>(predicate);
        return key;
    }
}
