import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ControlModule } from "@app/controls/control.module";

import { SharedModule } from "@app/shared/shared.module";
import { MoiOverviewComponent, MoiDetailComponent } from ".";
import { MoiDetailMainComponent } from "./moi-detail/moi-detail-main/moi-detail-main.component";
import { MoiEventSummaryComponent } from "./moi-detail/moi-detail-main/moi-event-summary/moi-event-summary.component";
import { MoiFindingReportSummaryComponent } from "./moi-detail/moi-detail-main/moi-finding-report-summary/moi-finding-report-summary.component";
import { MoiRiskSummaryComponent } from "./moi-detail/moi-detail-main/moi-risk-summary/moi-risk-summary.component";
import { MoiProcessingPurposeSummaryComponent } from "./moi-detail/moi-detail-main/moi-processing-purpose-summary/moi-processing-purpose-summary.component";
import { MoiDataService } from "./services/moi-data.service";
import { MoiRisksComponent } from "./moi-detail/moi-risks/moi-risks.component";
import { MoiControlsComponent } from "./moi-detail/moi-controls/moi-controls.component";
import { MoiCommentsComponent } from "./moi-detail/moi-detail-main/moi-comments/moi-comments.component";
import { MoiCommentTypePipe } from "./pipes/moi-comment-type.pipe";
import { MoiCommentsDialogComponent } from "./moi-detail/moi-detail-main/moi-comments-dialog/moi-comments-dialog.component";
import { MoiSourceSelectorComponent } from "./moi-detail/moi-source-selector/moi-source-selector.component";
import { MoiThirdPartySummaryComponent } from "./moi-detail/moi-detail-main/moi-thirdparty-summary/moi-thirdparty-summary.component";
import { MoiFindingsComponent } from "./moi-detail/moi-findings/moi-findings.component";
import { MoiIncidentSummaryComponent } from "./moi-detail/moi-detail-main/moi-incident-summary/moi-incident-summary.component";

@NgModule({
    declarations: [
        MoiOverviewComponent,
        MoiDetailComponent,
        MoiDetailMainComponent,
        MoiEventSummaryComponent,
        MoiFindingReportSummaryComponent,
        MoiRiskSummaryComponent,
        MoiRisksComponent,
        MoiControlsComponent,
        MoiFindingsComponent,
        MoiCommentsComponent,
        MoiThirdPartySummaryComponent,
        MoiIncidentSummaryComponent,
        MoiCommentTypePipe,
        MoiCommentsDialogComponent,
        MoiSourceSelectorComponent,
        MoiProcessingPurposeSummaryComponent,
    ],
    imports: [CommonModule, FormsModule, ControlModule, SharedModule],
})
export class MoiModule {
    static forRoot(): ModuleWithProviders<MoiModule> {
        return {
            ngModule: MoiModule,
            providers: [MoiDataService],
        };
    }
}
