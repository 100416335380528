import { MatIconModule } from "@angular/material/icon";
import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CerrixDropAreaComponent } from "../cerrix-drop-area/cerrix-drop-area.component";
import { CerrixIconButtonComponent, SelectOptionModel } from "@cerrix/components";
import { MatDialog } from "@angular/material/dialog";
import { CerrixEditDocumentComponent } from "../cerrix-edit-document/cerrix-edit-document.component";

import { DocumentModel } from "../models/document.model";
import { EditDocumentPopup } from "../models/edit-document-popup.model";
import { BaseDocumentsService } from "../services/base-documents.service";
import { CerrixConfirmationButtonComponent } from "../cerrix-confirmation-button/cerrix-confirmation-button.component";

@Component({
    selector: "cerrix-documents-list",
    templateUrl: "./cerrix-documents-list.component.html",
    styleUrls: ["./cerrix-documents-list.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        CerrixIconButtonComponent,
        CerrixDropAreaComponent,
        CerrixConfirmationButtonComponent,
    ],
    providers: [],
})
export class CerrixDocumentsListComponent implements OnInit {
    @Input() public documents: DocumentModel[];
    @Output() documentsChange = new EventEmitter<DocumentModel[]>();
    @Output() fileDownload = new EventEmitter<DocumentModel>();
    @Output() openDocumentViewer = new EventEmitter<DocumentModel>();

    @Input() canAdd!: boolean;
    @Input() canEdit!: boolean;
    @Input() canDelete!: boolean;

    private dialog = inject(MatDialog);

    private documentsService = inject(BaseDocumentsService);

    protected documentTypes: SelectOptionModel[] = [];
    protected documentConfig: {
        maxUploadSizeInMb: number;
        allowedFileExtensions: string;
    };

    ngOnInit(): void {
        this.documentsService.getDocumentTypes().subscribe((documentTypes) => {
            this.documentTypes = documentTypes;
        });
        this.documentsService.getDocumentConfig().subscribe((documentConfig) => {
            this.documentConfig = documentConfig;
        });
    }

    protected onRemoveFile(document: DocumentModel) {
        this.documents = this.documents.filter((d) => d !== document);
        this.documentsChange.emit(this.documents);
    }

    protected onFileDownload(document: DocumentModel) {
        this.fileDownload.emit(document);
    }

    protected onOpenPdfDocument(document: DocumentModel) {
        this.openDocumentViewer.emit(document);
    }

    protected onFileSelection(selection: { fileList: FileList; isBrowse: boolean }) {
        if (selection.fileList?.length) {
            const files = this.documentsService.filterAllowedFiles(
                selection.fileList,
                this.documentConfig.allowedFileExtensions
            );

            if (
                files.length &&
                this.documentsService.validateUploadLimit(
                    files,
                    this.documentConfig.maxUploadSizeInMb
                )
            ) {
                const newDocuments: DocumentModel[] = [];

                files.forEach((file) => {
                    newDocuments.push({
                        name: file.name,
                        file: file,
                        fileIcon: this.documentsService.getDocumentIcon(file.name),
                    });
                });

                if (selection.isBrowse) {
                    this.openEditDocumentsPopup(newDocuments, "Add");
                } else {
                    this.documentsChange.emit([...this.documents, ...newDocuments]);
                }
            }
        }
    }

    protected onEditDocument(document: DocumentModel) {
        this.openEditDocumentsPopup([document], !this.canEdit && !this.canAdd ? "View" : "Edit");
    }

    private openEditDocumentsPopup(
        documents: DocumentModel[],
        openReason: "View" | "Add" | "Edit"
    ) {
        const dialogInput: EditDocumentPopup = {
            documents,
            documentTypes: this.documentTypes,
            openReason: openReason,
            canAddDocument: this.canAdd,
            canEditDocument: this.canEdit,
        };

        let dialogRef = this.dialog.open(CerrixEditDocumentComponent, {
            width: "826px",
            data: dialogInput,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.length) {
                if (openReason === "Add") {
                    this.documents = [...this.documents, ...result];
                    this.documentsChange.emit(this.documents);
                } else if (openReason === "Edit") {
                    this.documentsChange.emit(this.documents);
                }
            }
        });
    }
}
