<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<cerrix-invite [inviteConfig]="inviteConfig" *ngIf="inviteConfig">
    <ng-template #userTemplate let-user>
        <div class="card" [class.bg-primary]="inviteConfig.userSelection.indexOf(user.ID) >= 0">
            <div class="card-body">
                <h6 class="card-title">{{ user.displayName }}</h6>
                <p class="card-text">{{ user.mail }}</p>
                <p class="card-text">{{ user.organizationName }}</p>
                <p
                    class="card-text text-truncate"
                    [title]="user.assignedObjectName"
                    *ngIf="user.assignedObjectName"
                >
                    {{ user.assignedObjectName }}
                </p>
                <p class="card-text">
                    <small class="text-muted">{{ user.invitationStatus }}</small>
                </p>
            </div>
        </div>
    </ng-template>
</cerrix-invite>

<div class="invite-type-selection" *ngIf="!targetedInvitationType && inviteModel">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Required form invitation</h5>
            <p class="card-subtitle text-muted mb-3">
                Invitation that will require selected users to fill out the form.
            </p>
            <p class="card-subtitle text-muted mb-4">
                Users will be shown in the 'Invite status overview' to enable easy tracking of
                result progress.
            </p>
            <button
                class="btn btn-outline-primary"
                (click)="loadType(inviteType.RequiredInvitation)"
            >
                Select
            </button>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Share form</h5>
            <p class="card-subtitle text-muted mb-4">
                Standalone invite to share availability of the form.
            </p>
            <button class="btn btn-outline-primary" (click)="loadType(inviteType.ShareInvitation)">
                Select
            </button>
        </div>
    </div>
</div>
