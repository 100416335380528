<div
    *ngIf="!saveSuccess"
    class="d-flex flex-row button-container align-items-center justify-content-center normal-state"
    ngClass="{ save-success: saveSuccess}"
>
    <span class="button-label">{{ text }}</span>
</div>

<div
    *ngIf="saveSuccess"
    class="d-flex flex-row button-container align-items-center justify-content-between saved-state"
>
    <span class="button-label">{{ saveSuccessText }}</span>
    <eff-icon icon="done"></eff-icon>
</div>
