import { Component, OnInit, Input } from "@angular/core";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { TargetModule } from "@enums/document/TargetModule";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ToastrService } from "ngx-toastr";
import { NavigatorDocumentModel } from "@app/navigators/models/navigator-document-model";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";

@Component({
    selector: "business-assessment-documents",
    templateUrl: "./business-assessment-documents.component.html",
    styleUrls: ["./business-assessment-documents.component.scss"],
})
export class BusinessAssessmentDocumentsComponent {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissions: BusinessNavigatorPermissionModel;
    @Input() cerrixTab: TabModel;

    documentTypeTargets = TargetModule.BusinessDimension;
    canEdit = true;
    isDocumentDirty = false;
    docModel: NavigatorDocumentModel = { documents: [] };

    isLoadingDocuments = false;
    public documentsLoaded = false;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    async loadDocuments(force = false) {
        if (this.requestModel.businessID < 1 || (this.documentsLoaded && !force)) {
            return;
        }

        this.isLoadingDocuments = true;
        this._businessDs.getAssessmentDocuments(this.requestModel).subscribe((docs) => {
            docs.parentId = this.requestModel.businessAssessmentID;
            this.docModel = docs;
            this.isLoadingDocuments = false;
            this.documentsLoaded = true;
        });
    }

    async saveDocuments() {
        const prompt = this._promptService.loader("Uploading documents!");
        return this._businessDs
            .storeAssessmentDocuments(this.docModel)
            .toPromise()
            .then((_) => {
                this._toastr.success("Documents have been uploaded!", "Success");
            })
            .finally(() => {
                prompt.close();
            });
    }

    checkDirty(): void {
        TabComponentHelper.toggleTabDirty(this.cerrixTab, "menuItemDocuments", true);
        this.isDocumentDirty = true;
    }
}
