import { AppConstants } from "@app/app.constants";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { Preset, PresetConfig } from "@models/workspace/Preset";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseWidget } from "./BaseWidget";
import { WorkspacePresetService } from "@app/shared/services/workspace/workspace-preset.service";

export abstract class BaseChartWidget extends BaseWidget {
    protected globalFilterWorkspaceColumns: { [key: string]: string };

    constructor(protected _tabService: TabService, protected _sdService: StandingdataDataService) {
        super();

        this.setDefaultFilterColumnMappings();
    }

    protected async getBasePreset(search?: SearchKeyword[]): Promise<PresetConfig> {
        let preset: PresetConfig = null;

        const workspacePreset = this.getFilter(WidgetFilterKey.GlobalFilter.WorkspacePreset, null);
        if (workspacePreset) {
            if (!this.config.workspacePresetModuleKey)
                throw Error(
                    "The use of Workspace Preset also requires the use of 'workspacePresetModuleKey' property in DashboardWidgetMapping config."
                );

            const workspaceDs = AppConstants.AppInjector.get(WorkspacePresetService);
            preset = await workspaceDs
                .getPreset(this.config.workspacePresetModuleKey, workspacePreset)
                .toPromise();
        } else {
            preset = new PresetConfig();
            preset.id = -1;
            preset.preset = new Preset();
            preset.preset.columns = ["*"];
            preset.preset.searchKeywords = await this.getGlobalSearchFilters();
        }

        if (search) {
            preset.preset.searchKeywords.addRange(search);
        }

        return preset;
    }

    protected getSearchKeyword(col: string, searchValue: string, comparisonType?: ComparisonType) {
        const searchkeyword = new SearchKeyword();
        searchkeyword.col = col;
        searchkeyword.searchValue = searchValue;
        searchkeyword.compareType = comparisonType ? comparisonType : ComparisonType["Is equal to"];
        searchkeyword.remoteSearch = false;

        return searchkeyword;
    }

    protected openPreset(page: string, preset: PresetConfig) {
        this._tabService.generateTab(page, null, "", {
            presetOverride: JSON.stringify(preset),
        });
    }

    private async getGlobalSearchFilters() {
        const keys = WidgetFilterKey.GlobalFilter;

        let filters = [
            this.getPeriodFilter(),
            await this.getSelectedTreeIDs(keys.Organization, keys.OrganizationIncludeChildren),
            await this.getSelectedTreeIDs(
                keys.BusinessDimension,
                keys.BusinessDimensionIncludeChildren
            ),
            await this.getSelectedTreeIDs(
                keys.FrameworkDimension,
                keys.FrameworkDimensionIncludeChildren
            ),
        ];

        filters = filters.filter((x) => x);
        return filters;
    }

    private getPeriodFilter() {
        const filter = this.getFilter(WidgetFilterKey.GlobalFilter.Period, null);
        if (!filter) {
            return null;
        }

        const searchFilter = this.getSearchKeyword("Period", "");
        searchFilter.specific = true;
        searchFilter.remoteSearch = true;
        searchFilter.searchValue = filter;

        return searchFilter;
    }

    private async getSelectedTreeIDs(treeKey: string, includeChildrenKey: string) {
        const searchFilter = this.getSearchKeyword(this.globalFilterWorkspaceColumns[treeKey], "");
        searchFilter.specific = true;

        const filter = this.config.configurations.find((f) => f.fieldName == treeKey);
        if (!filter) {
            return null;
        }

        const selectedFilterValue = this.getFilter(treeKey);
        if (!selectedFilterValue || selectedFilterValue <= 0) {
            return null;
        }

        const includeChildren = this.config.configurations.some(
            (f) => f.fieldName == includeChildrenKey
        );
        if (!includeChildren || !this.getFilter(includeChildrenKey, false)) {
            searchFilter.searchValue = "" + selectedFilterValue;
        } else {
            let filterValues =
                this.config.customConfig && this.config.customConfig.filterValues
                    ? { ...this.config.customConfig.filterValues }
                    : {};
            if (this.globalFilters) {
                Object.assign(filterValues, this.globalFilters);
            }
            const tree: CerrixTreeItem[] = await filter
                .getDataMethodByRow(filterValues, this._sdService)
                .toPromise();

            const targetNode = this.findInTree(tree, selectedFilterValue);
            if (!targetNode) {
                return null;
            }

            const ids = this.getNodeIDsFlattened(targetNode);
            searchFilter.searchValue = ids.join(";");
        }

        return searchFilter;
    }

    private findInTree(tree: CerrixTreeItem[], idToFind: number) {
        if (!tree) {
            return null;
        }

        for (let item of tree) {
            if (item.ID == idToFind) {
                return item;
            }
            const foundInChildren = this.findInTree(item.Children, idToFind);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
        return null;
    }

    private getNodeIDsFlattened(item: CerrixTreeItem) {
        if (!item) {
            return [];
        }

        var ids: number[] = [item.ID];
        var itemsToPush = JSON.parse(JSON.stringify(item.Children)) as CerrixTreeItem[];
        while (itemsToPush.length > 0) {
            const child = itemsToPush.pop();
            ids.push(child.ID);
            if (child.Children && child.Children.length > 0) {
                itemsToPush.addRange(JSON.parse(JSON.stringify(child.Children)));
            }
        }

        return ids;
    }

    private setDefaultFilterColumnMappings() {
        const keys = WidgetFilterKey.GlobalFilter;

        this.globalFilterWorkspaceColumns = {};
        this.globalFilterWorkspaceColumns[keys.Organization] = "OrganizationID";
        this.globalFilterWorkspaceColumns[keys.BusinessDimension] = "BusinessDimensionIDs";
        this.globalFilterWorkspaceColumns[keys.FrameworkDimension] = "FrameworkDimensionIDs";
    }
}
