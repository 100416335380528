<div class="eff-select-wrapper" [ngClass]="{ 'is-disabled': this.formController.disabled }">
    <mat-form-field>
        <mat-label *ngIf="label">{{ label }}</mat-label>
        <mat-select
            #selector
            [formControl]="formController"
            hideSingleSelectionIndicator
            [panelClass]="panelClass"
        >
            <mat-select-trigger *ngIf="isTemplate">
                <ng-container
                    *ngTemplateOutlet="
                        selectedOptionTemplate;
                        context: { selectedOption: selectedOption }
                    "
                >
                </ng-container>
            </mat-select-trigger>
            <mat-option
                *ngFor="let option of options"
                [value]="option.Id"
                [ngClass]="{ hide: option.Hide }"
            >
                <div
                    *ngIf="!isTemplate"
                    [attr.data-uiid]="'new-eff-eff-select-option-' + option.Label"
                >
                    {{ option.Label }}
                </div>
                <ng-container *ngIf="isTemplate">
                    <ng-container *ngTemplateOutlet="optionTemplate; context: { option: option }">
                    </ng-container>
                </ng-container>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
