import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { UserDetailModel } from "@app/user/user-maintenance/shared/user-detail-model";
import { ModuleType } from "@enums/ModuleType";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { copyText, nameof } from "@methods/CommonMethods";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { Preset, PresetConfig } from "@models/workspace/Preset";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { WorkspacePresetService } from "@app/shared/services/workspace/workspace-preset.service";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";

declare var $: any;

@Component({
    selector: "workspace-preset-management",
    templateUrl: "./workspace-preset-management.component.html",
    styleUrls: ["./workspace-preset-management.component.scss"],
})
export class WorkspacePresetManagementComponent implements AfterViewInit {
    @ViewChild("allPresetsView", { static: true }) allPresetsView: ElementRef;

    @Input() cerrixTab: TabModel;
    @Input() moduleType: ModuleType;
    @Input() appendToModuleType: string;

    @Input() activeSearchKeywords: SearchKeyword[];
    @Input() allHeaders: string[];
    @Input() activeColumns: string[];
    @Input() activePaginationAmount: number;
    @Input() activeSortCol: string;
    @Input() sortAsc: boolean;

    @Input() permissions: WorkspaceModulePermissionModel;
    @Input() usePresetHyperlink: boolean = true;

    @Output() presetsReady = new EventEmitter<boolean>();
    @Output() selectedPreset = new EventEmitter<PresetConfig>();

    initialPresetsFetched = false;

    presets: PresetConfig[];
    scopedPresets: PresetConfig[];
    scopeFilter: string;
    customPresets: PresetConfig[];

    actualDefaultPresetID: number;
    managePresetID = 0;

    currentPreset: PresetConfig;

    createMenuItem: TabMenuItem = new TabMenuItem();
    browseMenu: TabMenuItem = new TabMenuItem();
    predefinedMenu: TabMenuItem = new TabMenuItem();
    favoriteMenu: TabMenuItem = new TabMenuItem();
    rolegroupMenu: TabMenuItem = new TabMenuItem();

    openPrompt: CerrixPromptComponent;
    user: UserDetailModel;

    disabledPresetChangeConfirmations = false;

    constructor(
        private _presetDS: WorkspacePresetService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _tabService: TabService,
        private _prompt: CerrixPromptService,
        private _pages: Pages
    ) {}

    ngAfterViewInit() {
        this.setTabPresetChangeListener();
        this.setupPresets();
    }

    private setTabPresetChangeListener() {
        this._tabService.listeners.removeTabListenerByType(
            this.cerrixTab,
            TabEventListenerType.LoadPreset
        );

        this._tabService.listeners.addGlobalListener(
            TabEventListenerType.LoadPreset,
            this.cerrixTab.lookupname,
            async (preset) => {
                if (preset) {
                    this.cerrixTab.activate();
                    if (!this.disabledPresetChangeConfirmations) {
                        const result = await this._promptService
                            .confirmCustom({
                                maxHeight: "320px",
                                data: {
                                    title: "Load new preset?",
                                    message:
                                        "Any unsaved changes may be lost. Are you sure you want to load the new preset?",
                                    fields: [
                                        {
                                            prettyName: "Do not ask again.",
                                            description:
                                                "If you check this, future load request will be performed immediately for this specific tab.",
                                            fieldName: "dontAskAgain",
                                            fieldType: GenericListFieldType.CheckBox,
                                        },
                                    ],
                                },
                            })
                            .toPromise();

                        if (!result) {
                            return;
                        } else if (result["dontAskAgain"]) {
                            this.disabledPresetChangeConfirmations = true;
                        }
                    }

                    this.setPreset(JSON.parse(preset));
                }
            },
            this.cerrixTab
        );
    }

    moduleCode(): string {
        let module = ModuleType[this.moduleType];
        if (this.appendToModuleType) {
            module += "-" + this.appendToModuleType;
        }

        return module;
    }

    fixIncorrectHeaderCasing(dbPresets: PresetConfig[]) {
        const presets: PresetConfig[] = [];
        dbPresets.forEach((preset) => {
            preset.preset.searchKeywords.forEach((y) => {
                this.allHeaders.forEach((header) => {
                    if (y.col.toLowerCase() === header.toLowerCase()) {
                        y.col = header;
                    }
                });
            });
            presets.push(preset);
        });

        return presets;
    }

    async setupPresets() {
        // bug 8351: Mailmessage menu not shown, because presets overrides the menu.
        if (!this.moduleType) {
            return;
        }

        let presets = await this._presetDS.getPresets(this.moduleCode()).toPromise();
        presets = this.fixIncorrectHeaderCasing(presets);
        presets.forEach((preset) => {
            this.bindApi(preset);
        });

        this.presets = presets;
        this.customPresets = this.presets.filter((x) => !x.isPredefined);

        this.filterPresets();

        if (!this.initialPresetsFetched) {
            this.initialPresetsFetched = true;
            this.presetsReady.emit(true);

            if (this.cerrixTab.config.presetOverride) {
                var presetConfig = this.fixIncorrectHeaderCasing([
                    JSON.parse(this.cerrixTab.config.presetOverride),
                ]).first();
                this.setPreset(presetConfig);
            } else if (this.cerrixTab.config.presetID) {
                const presetToLoad = this.presets.find(
                    (x) => x.id == this.cerrixTab.config.presetID
                );
                if (presetToLoad) {
                    this.setPreset(presetToLoad);
                } else {
                    this.activateDefaultPreset();
                }
            } else {
                this.activateDefaultPreset();
            }
        }

        this.createMenu();
    }

    filterPresets() {
        if (!this.scopeFilter) {
            this.scopedPresets = this.presets;
            return;
        }

        const keywords = this.scopeFilter.toLowerCase().split(" ");
        const compare = (preset: PresetConfig, text: string) => {
            if (
                preset.name.toLowerCase().indexOf(text) >= 0 ||
                preset.description?.toLowerCase().indexOf(text) >= 0
            ) {
                return true;
            }

            return false;
        };

        this.scopedPresets = [];
        this.presets.forEach((preset) => {
            if (keywords.some((kw) => compare(preset, kw))) {
                this.scopedPresets.push(preset);
            }
        });
    }

    bindApi(preset: PresetConfig) {
        if (!preset.api) {
            preset.api = {};
        }

        preset.api.save = this.savePreset.bind(this, preset);
        preset.api.delete = this.delete.bind(this, preset);
        preset.api.toggleFavorite = this.toggleFavorite.bind(this, preset);
        preset.api.setDefault = this.updateDefaultPreset.bind(this, preset);
        preset.api.setSystemDefault = this.updateSystemDefaultPreset.bind(this, preset);
        preset.api.copyUrlToClipboard = this.copyUrlToClipboard.bind(this, preset);
    }

    copyUrlToClipboard(presetConfig: PresetConfig) {
        if (!presetConfig || !presetConfig.id || !this.cerrixTab.lookupname) {
            this._toastr.error("Could not copy to clipboard", "Error");
            return;
        }

        const lookupKey = this._pages.getReversed(this.cerrixTab.lookupname);
        if (!lookupKey) {
            this._toastr.error("Could not copy to clipboard", "Error");
            return;
        }

        let url = `${window.location.origin}/${lookupKey}/${presetConfig.id}`;
        copyText(url.toLowerCase());
        this._toastr.success("Url copied to clipboard", "Copy URL");
    }

    savePreset(presetConfig: PresetConfig) {
        if (!presetConfig) {
            return;
        }

        presetConfig.module = this.moduleCode();
        const preset = new Preset();
        preset.columns = this.activeColumns;
        preset.paginationAmount = this.activePaginationAmount;
        preset.searchKeywords = this.activeSearchKeywords.filter((x) => x.remoteSearch !== true);
        preset.sortColumn = this.activeSortCol;
        preset.sortOrder = this.sortAsc ? "asc" : "desc";

        presetConfig.preset = preset;
        this._presetDS.store(presetConfig).subscribe(async (storedID: number) => {
            await this.setupPresets();

            const presetToLoad = this.presets.find((x) => x.id == storedID);
            if (presetToLoad) {
                this.setPreset(presetToLoad);
            }

            this._toastr.success(`Preset with name '${presetConfig.name}'`, "Saved!");
        });
    }

    async delete(preset: PresetConfig) {
        const shouldDelete = await this._prompt
            .confirm(
                "Delete Preset",
                preset.isPublic
                    ? "This is a public preset. When deleting this preset, this preset will also not be available for other users.\nDo you want to delete this preset?"
                    : "Do you want to delete this preset?"
            )
            .toPromise();

        if (!shouldDelete) {
            return false;
        }

        await this._presetDS.remove(preset.id).toPromise();
        await this.setupPresets();
    }

    async toggleFavorite(preset: PresetConfig) {
        if (preset.isFavorite) {
            await this._presetDS.unfavorite(preset.id).toPromise();
            preset.isFavorite = false;
            preset.isDefault = false;
        } else {
            await this._presetDS.favorite(preset.id).toPromise();
            preset.isFavorite = true;
        }

        await this.setupPresets();
    }

    async togglePublic(preset: PresetConfig) {
        if (preset.isPublic) {
            await this._presetDS.unpublic(preset.id, this.moduleCode()).toPromise();
            preset.isPublic = false;
            preset.isDefault = false;
        } else {
            await this._presetDS.public(preset.id, this.moduleCode()).toPromise();
            preset.isPublic = true;
        }

        await this.setupPresets();
    }

    async deleteSelectedPreset() {
        await this._presetDS.remove(this.managePresetID).toPromise();
        await this.setupPresets();
        this.managePresetID = 0;
    }

    updateDefaultPreset(preset: PresetConfig) {
        if (preset.id === 0) {
            return;
        }

        // If it was predefined but now it is custom, we should update predefined presets menu aswell.
        const isOrWasPredefined =
            this.presets.filter((x) => x.id === +preset.id || x.id === +this.actualDefaultPresetID)
                .length > 0;
        const isCustom = this.presets.filter((x) => x.id === +preset.id).length > 0;

        // we just want to know if it is an id that exists.
        if (isOrWasPredefined || isCustom) {
            this._presetDS.setDefault(preset.id, this.moduleCode()).subscribe((done) => {
                this.setupPresets();
            });
        }
    }

    updateSystemDefaultPreset(preset: PresetConfig) {
        if (preset.id === 0 || !this.permissions.canSetSystemDefault) {
            return;
        }

        this._presetDS.setSystemDefault(preset.id, this.moduleCode()).subscribe((done) => {
            this.setupPresets();
        });
    }

    activateDefaultPreset() {
        // Set preset to load to the preset configured as default.
        let presetToLoad = this.presets.filter((x) => x.isDefault === true)[0];

        // If there is no default, we should load the first system default preset.
        if (!presetToLoad) {
            presetToLoad = this.presets.filter((x) => x.isSystemDefault === true)[0];
        }

        // If there is no system default, we should load the first predefined preset.
        if (!presetToLoad) {
            presetToLoad = this.presets.filter((x) => x.isPredefined === true)[0];
        }

        // If we have a preset to load, we should load it.
        this.actualDefaultPresetID = null;
        if (presetToLoad) {
            this.actualDefaultPresetID = presetToLoad.id;
            this.setPreset(presetToLoad);
        }
    }

    setPreset(presetConfig: PresetConfig) {
        this.openPrompt?.close();

        this.currentPreset = presetConfig;
        if (this.cerrixTab && this.cerrixTab.menu) {
            this.cerrixTab.menu.activeMenuItem = this.getPresetTabIdentifier(presetConfig.id);
        }

        this.selectedPreset.emit(presetConfig);
    }

    showEditPreset(preset: PresetConfig) {
        if (this.openPrompt) {
            this.openPrompt.close();
            this.openPrompt = null;
        }

        this.openPrompt = this._prompt.prompt({
            maxWidth: "700px",
            maxHeight: "550px",
            data: {
                title: "Preset settings",
                fields: [
                    {
                        fieldName: nameof<PresetConfig>("name"),
                        prettyName: "Name",
                        fieldType: GenericListFieldType.Text,
                        required: true,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("icon"),
                        prettyName: "Icon",
                        fieldType: GenericListFieldType.IconPicker,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("description"),
                        prettyName: "Description",
                        fieldType: GenericListFieldType.TextArea,
                    },
                    {
                        fieldName: nameof<PresetConfig>("roleGroups"),
                        prettyName: "Role groups",
                        fieldType: GenericListFieldType.MultiSelect,
                        description:
                            "Adding a role-group will make the preset available for all users within that role-group",
                        editorWidth: 12,
                        selfInit: true,
                        getDataMethod: () => {
                            return this._presetDS.getRoleGroups();
                        },
                    },
                    {
                        fieldName: nameof<PresetConfig>("isPublic"),
                        prettyName: "Is public",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: !this.permissions.canPublish || preset.isSystemDefault == true,
                        description:
                            "When making a preset public, the preset will be available for all other users.\n\n Note: If a public preset is configured as system default, it cannot be made private again until the system default is changed.",
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isFavorite"),
                        prettyName: "Is Favorite",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: preset.isDefault == true,
                        editorWidth: 6,
                    },
                ],
                fieldResult: preset,
                confirmOnEnter: false,
                confirmButton: {
                    show: true,
                },
                cancelButton: {
                    text: "Close",
                },
            },
        });

        this.openPrompt.getResult().subscribe((preset: PresetConfig) => {
            this.savePreset(preset);
        });
    }

    updatePreset() {
        this.showEditPreset(this.currentPreset);
    }

    showBrowsePresets() {
        if (this.openPrompt) {
            this.openPrompt.close();
            this.openPrompt = null;
        }

        this.presets = null;
        this.setupPresets();

        this.openPrompt = this._prompt.prompt({
            maxWidth: "1200px",
            maxHeight: "800px",
            data: {
                title: "All available presets",
                customTemplate: this.allPresetsView,
                confirmButton: {
                    show: false,
                },
                cancelButton: {
                    text: "Close",
                },
            },
        });
    }

    createMenu() {
        this.cerrixTab.menu?.menuItems.clear();

        if (!this.cerrixTab.menu) {
            this.cerrixTab.menu = new TabMenu();

            this.cerrixTab.menu.menuItemClicked = (tabMenuItem) => {
                const preset = this.presets.find((x) => x.id === tabMenuItem.obj);
                // Should only be one.
                if (preset) {
                    this.setPreset(preset);
                } else {
                    switch (tabMenuItem.identifier) {
                        case this.createMenuItem.identifier:
                            const preset = new PresetConfig();
                            preset.isFavorite = true;

                            this.showEditPreset(preset);
                            break;
                        case this.browseMenu.identifier:
                            this.showBrowsePresets();
                            break;
                    }
                }
            };

            this.createMenuItem = new TabMenuItem();
            this.createMenuItem.name = "Create new Preset";
            this.createMenuItem.obj = "createMenuId";
            this.createMenuItem.iconClass = "fas fa-plus";
            this.createMenuItem.clickable = true;

            this.browseMenu = new TabMenuItem();
            this.browseMenu.name = "Browse Presets";
            this.browseMenu.obj = "browseMenuId";
            this.browseMenu.iconClass = "fas fa-folder-open";
            this.browseMenu.clickable = true;

            this.predefinedMenu = new TabMenuItem();
            this.predefinedMenu.name = "Predefined presets";
            this.predefinedMenu.iconClass = "far fa-dot-circle";
            this.predefinedMenu.clickable = false;

            this.favoriteMenu = new TabMenuItem();
            this.favoriteMenu.name = "Favorites";
            this.favoriteMenu.iconClass = "fas fa-star";
            this.favoriteMenu.clickable = false;

            this.rolegroupMenu = new TabMenuItem();
            this.rolegroupMenu.name = "Role group";
            this.rolegroupMenu.iconClass = "fas fa-tags";
            this.rolegroupMenu.clickable = false;
        }

        this.cerrixTab.menu.menuItems.push(this.createMenuItem, this.browseMenu);

        this.cerrixTab.menu.menuItems.push(
            this.presets.some((x) => x.isFavorite) ? this.favoriteMenu : this.predefinedMenu
        );

        if (this.presets.some((x) => x.isRolegroup)) {
            this.cerrixTab.menu.menuItems.push(this.rolegroupMenu);
        }

        this.setPredefinedMenuItems();
        this.setFavoriteMenuItems();
        this.setRolegroupMenuItems();
    }

    setPredefinedMenuItems() {
        this.predefinedMenu.children = [];
        const predefined = this.presets.filter((x) => x.isPredefined);
        if (predefined.length > 0) {
            predefined.forEach((p) => {
                const pre = new TabMenuItem();
                pre.identifier = this.getPresetTabIdentifier(p.id);
                pre.name = p.name;
                pre.obj = p.id;
                pre.iconClass = p.icon;

                if (this.currentPreset && p.id === this.currentPreset.id) {
                    this.cerrixTab.menu.activeMenuItem = pre.identifier;
                }

                this.predefinedMenu.children.push(pre);
            });
            this.predefinedMenu.hidden = false;
        } else {
            this.predefinedMenu.hidden = true;
        }
    }

    setFavoriteMenuItems() {
        this.favoriteMenu.children = [];
        const customs = this.presets.filter((x) => x.isFavorite);
        if (customs.length > 0) {
            customs.forEach((c) => {
                const custom = new TabMenuItem();
                custom.identifier = this.getPresetTabIdentifier(c.id);
                custom.name = c.name;
                custom.obj = c.id;
                custom.iconClass = c.icon;

                if (this.currentPreset && c.id === this.currentPreset.id) {
                    this.cerrixTab.menu.activeMenuItem = custom.identifier;
                }
                this.favoriteMenu.children.push(custom);
            });
        }
    }

    OpenInfoDialog(preset: PresetConfig) {
        this._prompt.prompt({
            maxWidth: "600px",
            maxHeight: "525px",
            data: {
                title: preset.name,
                fields: [
                    {
                        fieldName: nameof<PresetConfig>("createdBy"),
                        prettyName: "Created by",
                        fieldType: GenericListFieldType.Text,
                        isReadonly: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: nameof<PresetConfig>("updatedOn"),
                        prettyName: "Last updated on",
                        fieldType: GenericListFieldType.DateTime,
                        isReadonly: true,
                        editorWidth: 12,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isPredefined"),
                        prettyName: "Is a predefined preset",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: true,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isSystemDefault"),
                        prettyName: "Is set as system default",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: true,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isPublic"),
                        prettyName: "Is public",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: true,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isRolegroup"),
                        prettyName: "Is a rolegroup preset",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: true,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isDefault"),
                        prettyName: "Is set as my default",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: true,
                        editorWidth: 6,
                    },
                    {
                        fieldName: nameof<PresetConfig>("isFavorite"),
                        prettyName: "Is favorited",
                        fieldType: GenericListFieldType.CheckBox,
                        isReadonly: true,
                        editorWidth: 6,
                    },
                ],
                fieldResult: preset,
                confirmOnEnter: false,
                confirmButton: {
                    show: false,
                },
                cancelButton: {
                    text: "Close",
                },
            },
        });
    }

    setRolegroupMenuItems() {
        this.rolegroupMenu.children = [];
        const customs = this.presets.filter((x) => x.isRolegroup);
        if (customs.length > 0) {
            customs.forEach((c) => {
                const custom = new TabMenuItem();
                custom.identifier = this.getPresetTabIdentifier(c.id);
                custom.name = c.name;
                custom.obj = c.id;
                custom.iconClass = c.icon;

                if (this.currentPreset && c.id === this.currentPreset.id) {
                    this.cerrixTab.menu.activeMenuItem = custom.identifier;
                }
                this.rolegroupMenu.children.push(custom);
            });
            this.rolegroupMenu.hidden = false;
        } else {
            this.rolegroupMenu.hidden = true;
        }
    }

    private getPresetTabIdentifier(presetID: number) {
        return "workspacepreset-" + this.moduleCode() + "-" + presetID;
    }
}
