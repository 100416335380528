import { Component, OnInit } from "@angular/core";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleType } from "@enums/ModuleType";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "shared-control-overview",
    templateUrl: "./shared-control-overview.html",
})

export class SharedControlOverview implements OnInit {
    currentOrganization: number;
    controlsLoading: boolean = true;
    CanShareControls: boolean;
    OrganizationList: CerrixTreeItem[];
    tab: TabModel;
    showLinkable: boolean = true;

    backupUnlinked: ControlLinkModel[];
    linked: ControlLinkModel[];
    unlinked: ControlLinkModel[];

    constructor(
        private _controlDS: ControlDataService,
        private _standingDataDS: StandingdataDataService,
        private _toastr: ToastrService,
        private _permissions: PermissionsService
     ) {}

    ngOnInit(): void {
        this.CanShareControls = this._permissions.permissions.HasSharedControlAccess;
        this.loadLinkedControls();

        this._standingDataDS.getOrganizations(ModuleType.Controls, false).subscribe( (x) => {
            this.OrganizationList = x;
        });
    }

    save() {
        //selected org and linked
        if(!this.currentOrganization) {
            this._toastr.warning("Please select an Organization first");
        }
        else {
            this._controlDS.storeSharedControlsPerOrganization(this.currentOrganization, this.linked.map(x => x.id))
                .subscribe( () => {
                    this._toastr.success("Save successful");
                    this.loadLinkedControls();
            });
        }
    }

    OrganizationChanged(newOrganization: number) {
        if(this.currentOrganization === newOrganization)
            return;

        this.currentOrganization = newOrganization;
        this.showLinkable = false;
        this.setLinkedControls();
         setTimeout(() => {this.showLinkable = true}, 100)
    }

    loadLinkedControls () {
        this._controlDS.getAllSharedControls().subscribe( (x) => {
            this.backupUnlinked = x;
            this.setLinkedControls();
            this.tab.showLoader = false;
            this.controlsLoading = false;
        });
     }

    setLinkedControls() {
        if(this.currentOrganization <= 0) {
            this.unlinked = this.backupUnlinked;
            this.linked = [];
            return;
        }

        //Filter out Controls that have the currently selected organization as organization. No need to display those.
        var tempBackupUnlinked = this.backupUnlinked.filter(x => x.organizationId != this.currentOrganization);

        //Linked are the Controls that are linked in the database duhhhhh
        var linked = tempBackupUnlinked.filter(x => x.applicableOrganizationList.includes(this.currentOrganization));

        var unlinked = tempBackupUnlinked.filter(x => linked.map(y => y.id).includes(x.id) == false)

        this.linked = linked;
        this.unlinked = unlinked;
    }
}