import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { ModuleType } from "@enums/ModuleType";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PeriodService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "periods");
    }

    getByModule(module: ModuleType) {
        const url = `/${module}`;
        return this.get<ModuleType[]>(url);
    }

    store(module: ModuleType, model) {
        const url = `/${module}`;
        return this.post(model, url);
    }

    getPermissions() {
        const url = `/permissions`;
        return this.get<any>(url);
    }
}
