<ng-container *ngIf="readOnly; else writableToolbar">
    <ul id="toolbar">
        <li class="toolbar-item" #printMenu matTooltip="Print">
            <i class="fas fa-print"></i>
            <i class="fas fa-sort-down"></i>
        </li>
        <li class="toolbar-item" #previewMenu matTooltip="Print preview">
            <i class="fas fa-print-search"></i>
            <i class="fas fa-sort-down"></i>
        </li>

        <li class="vertical-ruler"></li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.ZOOM_IN)" matTooltip="Zoom in">
            <i class="fas fa-search-plus"></i>
        </li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.ZOOM_OUT)"
            matTooltip="Zoom out"
        >
            <i class="fas fa-search-minus"></i>
        </li>
        <!--
        Fullscreen is disabled for now and will be implemented at a later time
        https://dev.azure.com/cerrix/Cerrix/_workitems/edit/2281
        -->
        <!-- <li
            class="toolbar-item right"
            (click)="toggleFullScreen()"
            matTooltip="Toggle fullscreen"
        >
            <i [ngClass]="isContainerFullscreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
        </li> -->
    </ul>

    <cerrix-contextmenu
        [elementClickToggle]="printMenu"
        [menu]="printMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
    <cerrix-contextmenu
        [elementClickToggle]="previewMenu"
        [menu]="previewMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
</ng-container>

<ng-template #writableToolbar>
    <ul id="toolbar">
        <li
            class="toolbar-item save-button"
            (click)="actions.execute(actionEnum.SAVE)"
            matTooltip="Save"
        >
            <i class="fas fa-save"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li class="toolbar-item" #printMenu matTooltip="Print">
            <i class="fas fa-print"></i>
            <i class="fas fa-sort-down"></i>
        </li>
        <li class="toolbar-item" #previewMenu matTooltip="Print preview">
            <i class="fas fa-print-search"></i>
            <i class="fas fa-sort-down"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.COPY)" matTooltip="Copy">
            <i class="fas fa-copy"></i>
        </li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.PASTE)" matTooltip="Paste">
            <i class="fas fa-paste"></i>
        </li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.CUT)" matTooltip="Cut">
            <i class="fas fa-cut"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.ZOOM_IN)" matTooltip="Zoom in">
            <i class="fas fa-search-plus"></i>
        </li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.ZOOM_OUT)"
            matTooltip="Zoom out"
        >
            <i class="fas fa-search-minus"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.UNDO)" matTooltip="Undo">
            <i class="fas fa-undo"></i>
        </li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.REDO)" matTooltip="Redo">
            <i class="fas fa-redo"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.REMOVE_CELLS)"
            matTooltip="Delete selected cells"
        >
            <i class="fas fa-trash"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.TO_FRONT)"
            matTooltip="To front"
        >
            <i class="fas fa-bring-front"></i>
        </li>
        <li class="toolbar-item" (click)="actions.execute(actionEnum.TO_BACK)" matTooltip="To back">
            <i class="fas fa-send-back"></i>
        </li>
        <li class="vertical-ruler"></li>
        <ng-select
            id="fontSizeInput"
            [items]="fontSizeData"
            [ngModel]="selectedFontSize || fontSizeData[4]" (ngModelChange)="selectedFontSize || (fontSizeData[4] = $event)"
            [clearable]="false"
            [addTag]="true"
            (change)="changeFontSize($event)"
        >
            <ng-template ng-label-tmp let-item="item"> {{ item.label }}pt </ng-template>
        </ng-select>
        <ng-select
            id="fontFamilyInput"
            [items]="fontFamilyData"
            [ngModel]="selectedFontFamily || fontFamilyData[0]" (ngModelChange)="selectedFontFamily || (fontFamilyData[0] = $event)"
            [clearable]="false"
            (change)="changeFontFamily($event)"
        >
        </ng-select>
        <li class="vertical-ruler"></li>
        <li class="toolbar-item" [matMenuTriggerFor]="textColorMenu" matTooltip="Text color">
            <i class="fas fa-font"></i>
        </li>
        <li class="toolbar-item" [matMenuTriggerFor]="fillColorMenu" matTooltip="Fill color">
            <i class="fas fa-fill-drip"></i>
        </li>
        <li class="toolbar-item" [matMenuTriggerFor]="strokeColorMenu" matTooltip="Stroke color">
            <i class="fas fa-pencil-alt"></i>
        </li>
        <li class="toolbar-item" #alignMenu matTooltip="Align text">
            <i class="fas fa-align-left"></i>
            <i class="fas fa-sort-down"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.TOGGLE_BOLD)"
            matTooltip="Bold"
        >
            <i class="fas fa-bold"></i>
        </li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.TOGGLE_ITALIC)"
            matTooltip="Italic"
        >
            <i class="fas fa-italic"></i>
        </li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.TOGGLE_UNDERLINE)"
            matTooltip="Underline"
        >
            <i class="fas fa-underline"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li class="toolbar-item" #connectionMenu matTooltip="Connection">
            <i class="fas fa-long-arrow-alt-right"></i>
            <i class="fas fa-sort-down"></i>
        </li>
        <li class="toolbar-item" #waypointsMenu matTooltip="Waypoints">
            <i class="fas fa-wave-square"></i>
            <i class="fas fa-sort-down"></i>
        </li>
        <li class="vertical-ruler"></li>
        <li
            class="toolbar-item"
            (click)="actions.execute(actionEnum.TOGGLE_SEQUENCE_NR)"
            matTooltip="Show print sequence nr."
        >
            <i class="fas fa-eye"></i>
        </li>

        <!--
        Fullscreen is disabled for now and will be implemented at a later time
        https://dev.azure.com/cerrix/Cerrix/_workitems/edit/2281
        -->
        <!-- <li
            class="toolbar-item right"
            (click)="toggleFullScreen()"
            matTooltip="Toggle fullscreen"
        >
            <i [ngClass]="isContainerFullscreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
        </li> -->
    </ul>
    <mat-menu #textColorMenu="matMenu" class="no-mat-menu-padding">
        <ng-template matMenuContent>
            <color-sketch
                (click)="$event.stopPropagation()"
                (onChange)="changeTextColor($event)"
                [disableAlpha]="true"
            >
            </color-sketch>
        </ng-template>
    </mat-menu>

    <mat-menu #fillColorMenu="matMenu" class="no-mat-menu-padding">
        <ng-template matMenuContent>
            <color-sketch (click)="$event.stopPropagation()" (onChange)="changeFillColor($event)">
            </color-sketch>
        </ng-template>
    </mat-menu>

    <mat-menu #strokeColorMenu="matMenu" class="no-mat-menu-padding">
        <ng-template matMenuContent>
            <color-sketch
                (click)="$event.stopPropagation()"
                (onChange)="changeStrokeColor($event)"
                [disableAlpha]="true"
            >
            </color-sketch>
        </ng-template>
    </mat-menu>

    <cerrix-contextmenu
        [elementClickToggle]="alignMenu"
        [menu]="alignMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
    <cerrix-contextmenu
        [elementClickToggle]="connectionMenu"
        [menu]="connectionMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
    <cerrix-contextmenu
        [elementClickToggle]="waypointsMenu"
        [menu]="waypointsMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
    <cerrix-contextmenu
        [elementClickToggle]="printMenu"
        [menu]="printMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
    <cerrix-contextmenu
        [elementClickToggle]="previewMenu"
        [menu]="previewMenuData"
        theme="processeditor"
    ></cerrix-contextmenu>
</ng-template>
