import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import {
    StepStateEnum,
    WorkflowStepConfig,
} from "../common/eff-workflow-bar/models/workflow-step-config";
import { EffStepStatus, EffWorkFlowState } from "../common/services/eff-workflow.service";

@Component({
    selector: "eff-workflow-status",
    templateUrl: "./eff-workflow-status.component.html",
    styleUrls: ["./eff-workflow-status.component.scss"],
})
export class EffWorkflowStatusComponent implements OnInit {
    @Input() workFlowSteps: EffWorkFlowState[] = [];
    stepsConfigs: WorkflowStepConfig[] = [];

    ngOnInit() {
        this.initSteps();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initSteps();
    }

    initSteps() {
        this.stepsConfigs = [];
        if (this.workFlowSteps && this.workFlowSteps.length > 0) {
            this.workFlowSteps.forEach((step) => {
                this.stepsConfigs.push({
                    Id: step.stepId,
                    Name: step.name,
                    State: this.getState(step),
                    CompletedBy: step.completedBy,
                    CompletedOn: step.completedOn,
                    DueDate: step.dueDate,
                    AssignedUsers: step.assignedUsers,
                });
            });
        }
    }

    getState(step: EffWorkFlowState) {
        switch (step.status) {
            case EffStepStatus.Completed:
                return StepStateEnum.Completed;
            case EffStepStatus.InProgress:
            case EffStepStatus.ReadyToStart:
                return StepStateEnum.Active;
        }

        return StepStateEnum.Disabled;
    }
}
