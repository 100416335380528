<div class="detail-page-card">
    <div class="detail-page-card-title">Linked Finding Report</div>
    <div class="detail-page-card-actions">
        <button
            class="btn btn-raised"
            (click)="openFindingReport()"
            *ngIf="showOpenButton && detailsModel"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Open Finding Report</span>
        </button>
    </div>
    <div class="detail-page-card-body padded form-group-styling">
        <div class="row">
            <div class="fancy-loader submodule" *ngIf="!detailsModel"></div>
            <div class="col-xl-6 left-block" *ngIf="detailsModel">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Report name</label>
                            <input
                                type="text"
                                [value]="detailsModel.name"
                                class="form-control form-control-sm"
                                disabled
                                inputTooltip
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Report type</label>
                            <input
                                type="text"
                                [value]="detailsModel.reportTypeName"
                                class="form-control form-control-sm"
                                disabled
                                inputTooltip
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Report no</label>
                            <input
                                type="text"
                                [value]="detailsModel.reportNumber"
                                class="form-control form-control-sm"
                                disabled
                                inputTooltip
                            />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Overall score</label>
                            <ng-select
                                [items]="[
                                    {
                                        value: detailsModel.overallScoreId,
                                        label: detailsModel.overallScoreName,
                                        color: detailsModel.overallScoreColor
                                    }
                                ]"
                                [ngModel]="detailsModel.overallScoreId"
                                bindValue="value"
                                disabled
                            >
                                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                    <span
                                        class="select-template-color"
                                        [style.background-color]="item.color"
                                    ></span>
                                    {{ item.label }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Report date</label>
                            <date-input
                                [(value)]="detailsModel.reportDate"
                                [disabled]="true"
                            ></date-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 right-block" *ngIf="detailsModel">
                <div class="form-group">
                    <label>Conclusion</label>
                    <textarea
                        type="date"
                        [value]="detailsModel.conclusion"
                        class="form-control form-control-sm"
                        disabled
                        inputTooltip
                    >
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</div>
