<div class="dialog-title" mat-dialog-title>
    <cerrix-title
        style="flex: 1"
        size="large"
        [editable]="false"
        [value]="dialogData.title"
    ></cerrix-title>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">
    <div class="icon-container">
        <mat-icon style="font-variation-settings: 'FILL' 1" fontSet="material-icons-outlined"
            >check_circle</mat-icon
        >
        <div class="text">Success</div>
    </div>
    <div class="message-text">{{ dialogData.message }}</div>
</mat-dialog-content>
