<div class="detail-page-card" *ngIf="moiModel">
    <div class="detail-page-card-title">Details</div>
    <form
        [formGroup]="parentForm"
        class="detail-page-card-body padded cerrix-control-sm form-group-styling"
        novalidate
        data-uiid="moi-detail-form"
    >
        <div class="row">
            <div class="col-12 col-xl-6 left-block">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <cerrix-textfield
                                fieldLabel="Name"
                                dataUiid="moi-name"
                                identifier="nameInput"
                                placeholder="Required"
                                formControlName="Name"
                                [required]="true"
                            ></cerrix-textfield>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label for="subjectSelect">Subject</label>
                            <ng-select
                                data-uiid="moi-subject"
                                id="subjectSelect"
                                name="subjectSelect"
                                [items]="sd.Subjects"
                                formControlName="Subject"
                                placeholder="Required"
                                bindLabel="Name"
                                bindValue="ID"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="subjectSelect">Priority</label>
                            <ng-select
                                data-uiid="moi-priority"
                                id="prioritySelect"
                                name="prioritySelect"
                                [items]="sd.Priorities"
                                placeholder="Required"
                                formControlName="Priority"
                                bindLabel="Name"
                                bindValue="ID"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="implementationSelect">Implementation score</label>
                            <ng-select
                                data-uiid="moi-implementationscore"
                                id="implementationSelect"
                                name="implementationSelect"
                                [items]="sd.ImplementationScores"
                                formControlName="ImplementationScore"
                                bindLabel="Name"
                                bindValue="ID"
                            >
                                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                    <div
                                        [style.background-color]="item.Color"
                                        style="
                                            min-width: 5px;
                                            min-height: 16px;
                                            float: left;
                                            margin-right: 10px;
                                        "
                                    ></div>
                                    {{ item.Name }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Start date</label>
                            <date-input
                                data-uiid="moi-startdate"
                                formControlName="StartDate"
                            ></date-input>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Due date</label>
                            <date-input data-uiid="moi-duedate" formControlName="DueDate">
                            </date-input>
                            <div class="red" *ngIf="parentForm?.controls?.DueDate?.errors">
                                <p
                                    *ngIf="
                                        parentForm?.controls?.DueDate?.errors.StartDateAfterDueDate
                                    "
                                >
                                    {{
                                        parentForm?.controls?.DueDate?.errors.StartDateAfterDueDate
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="form-group col">
                        <label for="orgResponsible">
                            Organization Responsible
                            <i
                                class="far fa-question-circle"
                                *ngIf="
                                    permissions.canAppointResponsible &&
                                    ((this.moiModel.LinkedControls &&
                                        this.moiModel.LinkedControls.length > 0) ||
                                        (this.moiModel.LinkedRisks &&
                                            this.moiModel.LinkedRisks.length > 0))
                                "
                                matTooltip="There are linked risks or controls that are dependant on this organization. To change the organization responsible remove these links first."
                            ></i>
                        </label>
                        <cerrix-select-tree
                            data-uiid="moi-organizationresponsible"
                            [tree]="sd.ResponsibleOrganizations"
                            formControlName="OrganizationResponsible"
                            (onChangeSingleId)="moiModel.OrganizationResponsible = $event"
                            placeholder="Required"
                            [multiselect]="false"
                        >
                        </cerrix-select-tree>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="responsibleSelect">Responsible</label>
                        <ng-select
                            data-uiid="moi-responsible"
                            id="responsibleSelect"
                            name="responsibleSelect"
                            [multiple]="true"
                            [items]="validResponsibles"
                            placeholder="Required"
                            formControlName="Responsibles"
                            bindLabel="Name"
                            bindValue="ID"
                            [closeOnSelect]="false"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="delegateSelect">Delegates</label>
                        <ng-select
                            data-uiid="moi-delegate"
                            id="delegateSelect"
                            name="delegateSelect"
                            [multiple]="true"
                            [items]="validDelegates"
                            formControlName="Delegates"
                            bindLabel="Name"
                            bindValue="ID"
                            [closeOnSelect]="false"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="businessDimension"> Business dimensions </label>
                        <div>
                            <small *ngIf="!moiModel.Id && !permissions.canChangeBusinessDimensions"
                                >Business Dimensions will be inherited from the selected parent
                                after the initial save</small
                            >
                        </div>
                        <cerrix-select-tree
                            data-uiid="moi-businessdimensions"
                            [tree]="sd.BusinessDimensions"
                            formControlName="BusinessDimensions"
                            [multiselect]="true"
                            *ngIf="moiModel.Id || permissions.canChangeBusinessDimensions"
                        >
                        </cerrix-select-tree>
                    </div>
                </div>
                <div
                    class="row"
                    *ngIf="moiModel.Type != MoiTypes.LER_Moi && moiModel.Type != MoiTypes.TP_Moi"
                >
                    <div class="form-group col">
                        <label for="frameworkDimension"> Framework dimensions </label>
                        <div>
                            <small *ngIf="!moiModel.Id && !permissions.canChangeFrameworkDimensions"
                                >Framework Dimensions will be inherited from the selected parent
                                after the initial save</small
                            >
                        </div>
                        <cerrix-select-tree
                            data-uiid="moi-frameworkdimensions"
                            [tree]="sd.FrameworkDimensions"
                            formControlName="FrameworkDimensions"
                            [multiselect]="true"
                            *ngIf="moiModel.Id || permissions.canChangeFrameworkDimensions"
                        >
                        </cerrix-select-tree>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="form-group col">
                        <label>Organization reviewer</label>
                        <cerrix-select-tree
                            data-uiid="moi-organizationreviewer"
                            [tree]="sd.ReviewerOrganizations"
                            formControlName="OrganizationReviewer"
                            placeholder="Required"
                            [multiselect]="false"
                        >
                        </cerrix-select-tree>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col">
                        <label>Reviewer</label>
                        <ng-select
                            data-uiid="moi-reviewer"
                            name="reviewerSelect"
                            [multiple]="true"
                            [items]="validReviewers"
                            placeholder="Required"
                            formControlName="Reviewers"
                            bindLabel="Name"
                            bindValue="ID"
                            [closeOnSelect]="false"
                        >
                        </ng-select>
                    </div>
                </div>

                <hr class="d-xl-none" />
            </div>
            <div class="col-12 col-xl-6 right-block">
                <div class="row">
                    <div class="form-group col">
                        <cerrix-textarea
                            fieldLabel="Finding description"
                            dataUiid="moi-findingdescription"
                            identifier="findingDescriptionInput"
                            [rows]="9"
                            formControlName="FindingDescription"
                        ></cerrix-textarea>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="form-group col">
                        <cerrix-textarea
                            fieldLabel="Recommendation"
                            dataUiid="moi-recommendation"
                            identifier="recommendationInput"
                            placeholder="Required"
                            [rows]="9"
                            formControlName="Recommendation"
                            [required]="true"
                        ></cerrix-textarea>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="form-group col">
                        <cerrix-textarea
                            fieldLabel="Management response"
                            dataUiid="moi-managementresponse"
                            identifier="managementResponseInput"
                            [rows]="9"
                            formControlName="ManagementResponse"
                        ></cerrix-textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="Url">MoI URL</label>
                        <cerrix-url-input
                            dataUiid="moi-url"
                            id="Url"
                            name="Url"
                            formControlName="Url"
                        ></cerrix-url-input>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="parentSummary">
    <moi-finding-report-summary
        [summaryModel]="parentSummary"
        [showOpenButton]="parentSummary.CanOpenParent"
        [(moi)]="moiModel"
        [canLink]="permissions.canAddFindingReportAssessment"
        *ngIf="moiModel.Type === MoiTypes.IM_Moi"
    >
    </moi-finding-report-summary>

    <control-detail-overview
        [ControlGuid]="moiModel.ParentGuid"
        [HideOpenControlButton]="!parentSummary.CanOpenParent"
        [ParentModule]="ModuleType"
        [ParentGuid]="moiModel.Guid"
        Label="Linked Control"
        *ngIf="moiModel.Type === MoiTypes.Control_Moi"
    >
    </control-detail-overview>

    <moi-event-summary
        [summaryModel]="parentSummary"
        [eventID]="moiModel.ParentGuid"
        *ngIf="moiModel.Type === MoiTypes.LER_Moi"
    >
    </moi-event-summary>

    <moi-risk-summary
        [summaryModel]="parentSummary"
        [riskGuid]="moiModel.ParentGuid"
        *ngIf="moiModel.Type === MoiTypes.RISK_Moi"
    >
    </moi-risk-summary>
    <moi-thirdparty-summary
        [summaryModel]="parentSummary"
        [thirdpartyGUID]="moiModel.ParentGuid"
        *ngIf="moiModel.Type === MoiTypes.TP_Moi"
    >
    </moi-thirdparty-summary>

    <moi-processing-purpose-summary
        [summaryModel]="parentSummary"
        [processingPurposeID]="moiModel.ParentGuid"
        *ngIf="moiModel.Type === MoiTypes.DM_Moi"
    >
    </moi-processing-purpose-summary>

    <moi-incident-summary
        [summaryModel]="parentSummary"
        [incidentGuid]="moiModel.ParentGuid"
        *ngIf="moiModel.Type === MoiTypes.Incident_Moi"
    >
    </moi-incident-summary>
</div>
