import { Component, ElementRef, ViewChild } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";
import { ControlsWidgetsDataService } from "@app/dashboard/services/controls-widgets.data.service";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { EffectivenessOverviewModel } from "@app/controls/testing/advanced/shared/models/effectiveness-overview-model";
import { nameof } from "@methods/jeffs-toolkit";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";

@Component({
    selector: "app-test-plans-overdue-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class EffectivenessOverdueWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private controlsWidgetsDataService: ControlsWidgetsDataService,
        private pages: Pages,
        tabService: TabService,
        standingDataDataService: StandingdataDataService
    ) {
        super(tabService, standingDataDataService);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            nameof<EffectivenessOverviewModel>((x) => x.organizationId);
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.controlsWidgetsDataService
            .getOverdueEffectivenessForWidget(filters)
            .toPromise();

        this.config.noData = !data || data.every((di) => di.y === 0);
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <any>{
                colorByPoint: true,
                data,
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);
        config.legend = { enabled: false };
        config.xAxis = {
            type: "category",
        };

        config.yAxis = {
            allowDecimals: false,
            title: {
                text: "Number of test plans",
            },
        };

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        return config;
    }

    protected async openWorkspace(event: Highcharts.PointClickEventObject) {
        const category = event.point.name;

        let searchKeywords: SearchKeyword[];
        switch (category) {
            case "Waiting for source document uploader":
                searchKeywords = [
                    this.getKeywordFilter((eff) => eff.isSourceUploaderOverdue, "True"),
                    this.getKeywordFilter(
                        (eff) => eff.statusDescription,
                        "Upload source documents to start workflow"
                    ),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Generate samples"),
                ];
                break;
            case "Waiting for evidence uploader":
                searchKeywords = [
                    this.getKeywordFilter((eff) => eff.isEvidenceUploaderOverdue, "True"),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Waiting for evidence"),
                ];
                break;
            case "Waiting for tester":
                searchKeywords = [
                    this.getKeywordFilter((eff) => eff.isTesterOverdue, "True"),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Ready for assessment"),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Assessment in progress"),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Waiting for conclusion"),
                    this.getKeywordFilter(
                        (eff) => eff.statusDescription,
                        "Test control for non occurrence"
                    ),
                ];
                break;
            case "Waiting for reviewer":
                searchKeywords = [
                    this.getKeywordFilter((eff) => eff.isReviewerOverdue, "True"),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Waiting for reviewer"),
                    this.getKeywordFilter((eff) => eff.statusDescription, "Review non occurrence"),
                ];
                break;
        }

        if (!searchKeywords) {
            return;
        }

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.ControlsTestingAdvEffOverview, preset);
    }

    private getKeywordFilter(
        nameFunction: (obj: EffectivenessOverviewModel) => any,
        searchValue: string,
        comparisonType?: ComparisonType
    ): SearchKeyword {
        return this.getSearchKeyword(
            nameof<EffectivenessOverviewModel>(nameFunction),
            searchValue,
            comparisonType
        );
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.ControlsTestingAdvEffOverview, preset);
    }
}
