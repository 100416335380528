import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { AppConstants } from "@app/app.constants";
import { CerrixIcon } from "@app/shared/models/CerrixIconModel";
import { Icon, SelectableIcons } from "@constants/selectableicons";
import { parseTyped } from "@methods/CommonMethods";
import { LocalStorageService } from "@services/http/LocalStorageService";
import { CerrixIconViewerComponent } from "../cerrix-icon-viewer/cerrix-icon-viewer.component";
import { CerrixPromptService } from "./../../services/cerrix-prompt.service";

@Component({
    selector: "cerrix-icon-picker",
    templateUrl: "./cerrix-icon-picker.component.html",
    styleUrls: ["./cerrix-icon-picker.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CerrixIconPickerComponent,
            multi: true,
        },
    ],
})
export class CerrixIconPickerComponent implements OnInit, ControlValueAccessor {
    categories = SelectableIcons.categories;
    icons = SelectableIcons.icons;

    filteredIcons: Icon[];

    selectedCategory = "";
    searchFilter = "";

    selectedIcon: Icon;
    animate = false;
    border = false;
    mainColor = "";

    canChangeMainColor = false;

    lastUsedIcon: CerrixIcon;
    private lastUsedIconKey = "CERRIX_Last_Used_Icon";

    _value: string;
    _onChangeFormValidation = Function.prototype;
    _onTouchedFormValidation = Function.prototype;

    @Input() disabled = false;
    @Input() pickeronly = false;

    @Output() valueChange = new EventEmitter<string>();
    @ViewChild("iconViewer", { static: true }) iconViewer: CerrixIconViewerComponent;

    @Input()
    set value(value: string) {
        if (this._value === value) {
            return;
        }

        this._value = value;
        this.valueChange.emit(value);
        this._onChangeFormValidation(value);
    }
    get value(): string {
        return this._value;
    }

    constructor(
        private promptService: CerrixPromptService,
        private localStorageService: LocalStorageService
    ) {
        this.filteredIcons = JSON.parse(JSON.stringify(this.icons));
    }

    ngOnInit(): void {
        this.validateValue();
    }

    //#region Load Validation

    validateValue() {
        const parsed = parseTyped<CerrixIcon>(this.value, {
            iconName: "",
            selector: this.value,
            animate: false,
            border: false,
            color: "#000000",
        });
        let isValid = parsed.selector ? true : false;

        if (isValid) {
            const foundIcon = this.icons.find((i) => i.selector === parsed.selector);
            if (!foundIcon) {
                isValid = false;
            }
        }

        if (!isValid) {
            const defaultIcon = this.icons.find((i) => i.selector === AppConstants.DefaultIcon);
            parsed.iconName = defaultIcon.name;
            parsed.selector = defaultIcon.selector;

            setTimeout(() => {
                this.value = JSON.stringify(parsed);
            }, 0);
        }
    }

    //#endregion Load Validation

    //#region Modal

    openModal(template: TemplateRef<any>) {
        if (!this.disabled) {
            this.initValue();
            this.loadLastUsedIcon();

            const prompt = this.promptService.prompt({
                maxWidth: 1600,
                maxHeight: 900,
                data: {
                    title: "Icon picker",
                    customTemplate: template,
                    hideBodyPadding: true,
                    extraButtons: [
                        {
                            text: "Use last icon",
                            icon: JSON.stringify(this.lastUsedIcon),
                            class: "btn btn-white",
                            show: !this.disabled,
                            action: () => {
                                if (this.disabled) {
                                    return;
                                }

                                this.setIcon(this.lastUsedIcon);
                            },
                        },
                    ],
                },
            });

            if (!this.disabled) {
                prompt.onConfirm().subscribe(() => {
                    const newModel = <CerrixIcon>{
                        iconName: this.selectedIcon.name,
                        selector: this.selectedIcon.selector,
                        animate: this.animate,
                        border: this.border,
                        color: this.mainColor,
                    };

                    this.value = JSON.stringify(newModel);
                    this.storeLastUsedIcon(newModel);
                    this.iconViewer.reload(this.value);
                });
            }
        }
    }

    initValue() {
        const parsed = parseTyped<CerrixIcon>(this.value, {
            iconName: "",
            selector: this.value,
            animate: false,
            border: false,
            color: "#000000",
        });

        this.setIcon(parsed, true);
    }

    //#endregion Modal

    //#region Icon management

    changeIcon(newIcon: Icon) {
        if (this.disabled && newIcon !== this.selectedIcon) {
            return;
        }

        this.selectedIcon = newIcon;
        this.canChangeMainColor = newIcon.categories.indexOf("Flags") < 0;

        if (!this.canChangeMainColor) {
            this.mainColor = "#000000";
        }
    }

    private setIcon(icon: CerrixIcon, setFilterToName?: boolean) {
        this.animate = icon.animate;
        this.border = icon.border;
        this.mainColor = icon.color;

        let iconModel = this.icons.find((i) => i.selector === icon.selector);
        if (!iconModel) {
            iconModel = this.icons.find((i) => i.selector === AppConstants.DefaultIcon);
        }

        this.changeIcon(iconModel);
        if (setFilterToName) {
            this.searchFilter = iconModel.name;
        }
        this.filter(true);
        this.scrollCategoryInView();
    }

    private storeLastUsedIcon(selector: CerrixIcon) {
        this.localStorageService.setItem(this.lastUsedIconKey, selector);
    }

    private loadLastUsedIcon() {
        this.lastUsedIcon = this.localStorageService.getItem<CerrixIcon>(this.lastUsedIconKey);
    }

    private scrollCategoryInView() {
        setTimeout(() => {
            const dom = document.getElementsByClassName("category selected");
            if (dom) {
                dom[0].scrollIntoView({ behavior: "smooth" });
            }
        }, 1000);
    }

    //#endregion Icon management

    //#region Filtering

    categoryChange(newCat: string) {
        if (this.categories.indexOf(newCat) >= 0) {
            this.selectedCategory = newCat;
            this.filter(true);
        }
    }

    clearFilter() {
        this.searchFilter = "";
        this.filter(this.selectedCategory ? true : false);
    }

    filter(includeCategory?: boolean) {
        this.filterText("" + this.searchFilter);

        if (includeCategory) {
            if (!this.selectedCategory) {
                if (this.selectedIcon) {
                    this.selectedIcon.categories.forEach((cat) => {
                        if (this.categories.indexOf(cat) >= 0) {
                            this.selectedCategory = cat;
                            return;
                        }
                    });
                } else {
                    this.selectedCategory = this.categories[0];
                }
            }

            this.filterCategory(this.selectedCategory);
        } else if (this.searchFilter) {
            // This will highlight 'All search results'
            this.selectedCategory = "";
        }
    }

    private filterText(textToFilter: string) {
        this.filteredIcons = JSON.parse(JSON.stringify(this.icons));
        if (textToFilter && textToFilter.length > 0) {
            const filterKeywords = textToFilter.toLowerCase().split(" ");
            this.filteredIcons = this.filteredIcons.filter((icon) =>
                filterKeywords.some(
                    (kw) =>
                        icon.name.toLowerCase().indexOf(kw) >= 0 ||
                        (icon.tags && icon.tags.some((tg) => tg.toLowerCase().indexOf(kw) >= 0)) ||
                        (icon.categories &&
                            icon.categories.some((cat) => cat.toLowerCase().indexOf(kw) >= 0))
                )
            );
        }
    }

    private filterCategory(categoryToFilter: string) {
        this.selectedCategory = categoryToFilter;

        if (this.selectedCategory && this.selectedCategory.length > 0) {
            this.filteredIcons = this.filteredIcons.filter((x) =>
                x.categories.some((c) => c === this.selectedCategory)
            );
        }
    }

    //#endregion Filtering

    //#region Form Validation

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this._onChangeFormValidation = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFormValidation = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    //#endregion Form Validation
}
