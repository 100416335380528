import { Component, OnInit, ViewChild } from "@angular/core";
import { ProcessEditorComponent } from "@app/process-editor/process-editor.component";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { nameof } from "@methods/jeffs-toolkit";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { BusinessNavigatorPermissionModel } from "../models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "../models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "../services/business-navigator-data.service";
import { CerrixPromptService } from "./../../../shared/services/cerrix-prompt.service";
import { BusinessDocumentsComponent } from "./components/business-documents/business-documents.component";
import { BusinessHyperlinksComponent } from "./components/business-hyperlinks/business-hyperlinks.component";
import { BusinessAssessmentDocumentsComponent } from "./components/business-assessment-documents/business-assessment-documents.component";

@Component({
    selector: "business-navigator-detail",
    templateUrl: "./business-navigator-detail.component.html",
})
export class BusinessNavigatorDetailComponent implements OnInit {
    tab: TabModel;

    public requestModel: BusinessNavigatorRequestModel;
    public permissions: BusinessNavigatorPermissionModel;
    public hideActionBar = false;

    public processSideMenuKey = "menuItemProccesses";
    static processSideMenuKeyReference = "menuItemProccesses";

    public documentsSideMenuKey = "menuItemDocuments";
    public assessmentDocumentsSideMenuKey = "menuItemAssessmentDocuments";
    public hyperlinksSideMenuKey = "menuItemHyperlinks";
    public historySideMenuKey = "menuItemHistory";

    @ViewChild(ProcessEditorComponent)
    private processEditorComponent: ProcessEditorComponent;

    @ViewChild(BusinessDocumentsComponent)
    private businesskDocumentsComponent: BusinessDocumentsComponent;

    @ViewChild(BusinessAssessmentDocumentsComponent)
    private businessAssessmentDocumentsComponent: BusinessAssessmentDocumentsComponent;

    @ViewChild(BusinessHyperlinksComponent)
    private businessHyperlinksComponent: BusinessHyperlinksComponent;

    @ViewChild(HistoryOverviewComponent)
    private historyOverviewComponent: HistoryOverviewComponent;

    get isStoredAssessment(): boolean {
        return this.requestModel && this.requestModel.hasAssessment;
    }

    get castedTabConfig(): BusinessNavigatorRequestModel {
        const filtersPassedThrough =
            this.tab.config &&
            ((this.tab.config as BusinessNavigatorRequestModel).organizationID ||
                (this.tab.config as BusinessNavigatorRequestModel).businessID);

        if (filtersPassedThrough) {
            return this.tab.config as BusinessNavigatorRequestModel;
        }

        // This is needed for mailmessages, that link to a specific assessment through the url.
        if (this.tab.config && (this.tab.config.organizationid || this.tab.config.businessid)) {
            return <BusinessNavigatorRequestModel>{
                organizationID: +this.tab.config.organizationid,
                businessID: +this.tab.config.businessid,
            };
        }

        return null;
    }

    public isSavingDocuments = false;
    public isDocumentMenuItem = false;
    get showDocumentButton() {
        return (
            this.isDocumentMenuItem &&
            this.businesskDocumentsComponent &&
            this.businesskDocumentsComponent.documentsLoaded
        );
    }

    public isSavingAssessmentDocuments = false;
    public isAssessmentDocumentMenuItem = false;
    get showAssessmentDocumentButton() {
        return (
            this.isAssessmentDocumentMenuItem &&
            this.businessAssessmentDocumentsComponent &&
            this.businessAssessmentDocumentsComponent.documentsLoaded &&
            this.requestModel.businessID > 0 &&
            this.requestModel.organizationID > 0
        );
    }

    public applicationPermissions: ApplicationPermissionsModel;

    constructor(
        public dataService: BusinessNavigatorDataService,
        private promptService: CerrixPromptService,
        private toastrService: ToastrService,
        private permService: PermissionsService,
        private tabService: TabService,
        private _pages: Pages
    ) {}

    async ngOnInit() {
        this.applicationPermissions = this.permService.permissions;

        if (this.castedTabConfig) {
            // Load existing
            await this.loadAssessmentConfiguration(
                this.castedTabConfig.organizationID,
                this.castedTabConfig.businessID
            );
        } else {
            // Load popup
            await this.startAssessmentConfiguration();
        }
    }

    public onMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem || !menuItem.menuItemId) {
            return;
        }

        let hideActionBar = false;
        this.isDocumentMenuItem = false;
        this.isAssessmentDocumentMenuItem = false;

        switch (menuItem.menuItemId) {
            case this.processSideMenuKey: {
                this.processEditorComponent.loadDiagram({
                    businessDimension: this.requestModel.businessID,
                    organization: this.requestModel.organizationID,
                });

                break;
            }
            case this.documentsSideMenuKey: {
                this.isDocumentMenuItem = true;
                this.businesskDocumentsComponent.loadDocuments();
                break;
            }
            case this.assessmentDocumentsSideMenuKey: {
                this.isAssessmentDocumentMenuItem = true;
                this.businessAssessmentDocumentsComponent.loadDocuments();
                break;
            }
            case this.hyperlinksSideMenuKey: {
                this.businessHyperlinksComponent.loadHyperlinks();
                break;
            }
            case this.historySideMenuKey: {
                hideActionBar = true;
                this.historyOverviewComponent.loadHistory();
                break;
            }
        }

        this.hideActionBar = hideActionBar;
    }

    public async saveDocuments() {
        this.isSavingDocuments = true;
        this.businesskDocumentsComponent.saveDocuments().finally(() => {
            this.isSavingDocuments = false;
            this.businesskDocumentsComponent.loadDocuments(true);
        });
    }

    public async saveAssessmentDocuments() {
        this.isSavingAssessmentDocuments = true;
        this.businessAssessmentDocumentsComponent.saveDocuments().finally(() => {
            this.isSavingAssessmentDocuments = false;
            this.businessAssessmentDocumentsComponent.loadDocuments(true);
        });
    }

    private async startAssessmentConfiguration() {
        const fieldResult: BusinessNavigatorRequestModel = {
            organizationID: null,
            businessID: null,
            hasAssessment: false,
            businessAssessmentID: null,
        };

        const configPrompt = this.promptService
            .prompt({
                data: {
                    promptUiid: "Business-navigator-prompt",
                    title: "Business Assessment Configuration",
                    message:
                        "Please select the organization and business dimension the assessment should be based upon. Note that without an organization selection the review cannot be initiated.",
                    fieldResult: fieldResult,
                    fields: [
                        {
                            prettyName: "Organization",
                            fieldName: nameof<BusinessNavigatorRequestModel>(
                                (x) => x.organizationID
                            ),
                            fieldType: GenericListFieldType.SingleTree,
                            uiid: "business-org-select",
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getOrganizations();
                            },
                        },
                        {
                            prettyName: "Business Dimension",
                            fieldName: nameof<BusinessNavigatorRequestModel>((x) => x.businessID),
                            fieldType: GenericListFieldType.SingleTree,
                            uiid: "business-bd-select",
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getBusinessDimensionsByOrganization(
                                    fieldResult.organizationID
                                );
                            },
                            checkReloadDataByRowChanges: (prev, current) => {
                                const prevModel = prev as BusinessNavigatorRequestModel;
                                const currentModel = current as BusinessNavigatorRequestModel;

                                return prevModel.organizationID != currentModel.organizationID;
                            },
                        },
                    ],

                    confirmButton: {
                        uiid: "business-popup-load-button",
                        text: "Load",
                        icon: "fas",
                    },
                },
            })
            .getResult();

        const result = await configPrompt.toPromise();
        if (!result) {
            this.tab.close(false);
            return;
        }

        await this.loadAssessmentConfiguration(result.organizationID, result.businessID);
    }

    private async loadAssessmentConfiguration(orgID?: number, bdID?: number) {
        if (!orgID && !bdID) {
            this.toastrService.warning(
                "At least one organization or business needs to be selected.",
                "Load failed"
            );
            await this.startAssessmentConfiguration();
            return;
        }

        this.dataService
            .getRequestValidation({
                organizationID: orgID,
                businessID: bdID,
                hasAssessment: false,
            })
            .then(async (data) => {
                this.requestModel = data;

                await this.loadPermissions(orgID, bdID);
                this.tab.showLoader = false;
            })
            .catch(async (_) => {
                if (this.castedTabConfig) {
                    this.tab.close(false);
                } else {
                    await this.startAssessmentConfiguration();
                }
            });
    }

    async loadPermissions(orgID?: number, bdID?: number) {
        var request = this.dataService.getPermissions(orgID, bdID);
        request.catch(() => {
            this.tab.close(false);
        });

        this.permissions = await request;
    }

    delete() {
        this.dataService.deleteAssessment(this.requestModel).subscribe({
            next: (validations: string[]) => {
                if (validations && validations.length > 0) {
                    this.toastrService.warning(validations.toString());
                } else {
                    this.toastrService.success("Business Assessment deleted");
                    this.tabService.refresh(this._pages.BusinessNavigator);
                    this.tab.close(false);
                }
            },
            error: (error) => {
                this.toastrService.error("", error || error.Message);
            },
        });
    }
}
