<div style="display: flex; padding: 23px 32px 23px 32px" mat-dialog-title>
    <cerrix-title
        style="flex: 1"
        size="large"
        [editable]="false"
        [value]="dialogTitle"
    ></cerrix-title>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content
    style="
        display: flex;
        flex-direction: column;
        gap: 28px;
        padding: 23px 32px 23px 32px;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
    "
>
    <form *ngIf="step === 1; else step2Form" [formGroup]="form">
        <div style="display: flex; flex-direction: column; gap: 28px">
            <div style="display: flex; flex-direction: column; gap: 16px">
                <div style="display: flex; gap: 28px">
                    <div style="flex: 1">
                        <cerrix-field-wrapper label="Name" [required]="true" type="secondary">
                            <cerrix-input
                                placeholder="Add a name for your custom field"
                                formControlName="name"
                                (ngModelChange)="validateName()"
                            ></cerrix-input>
                        </cerrix-field-wrapper>
                    </div>

                    <div style="flex: 1">
                        <cerrix-field-wrapper label="Field Type" [required]="true" type="secondary">
                            <cerrix-select
                                placeholder="Select a field type"
                                [options]="this.customFieldTypes"
                                formControlName="customFieldType"
                            ></cerrix-select>
                        </cerrix-field-wrapper>
                    </div>
                </div>

                <div style="display: flex; flex-direction: row; gap: 48px">
                    <cerrix-checkbox
                        style="margin-left: -9px"
                        formControlName="isRequired"
                        label="Required"
                    ></cerrix-checkbox>
                    <cerrix-checkbox formControlName="isVisible" label="Visible"></cerrix-checkbox>
                </div>
            </div>

            <cerrix-field-wrapper label="Placeholder" style="flex: 1">
                <cerrix-input
                    placeholder="Add a custom placeholder"
                    formControlName="placeholder"
                ></cerrix-input>
            </cerrix-field-wrapper>

            <cerrix-field-wrapper label="Instructions guide text" style="flex: 1" type="secondary">
                <cerrix-textarea
                    placeholder="Give instructions to the user what to fill in the field"
                    formControlName="description"
                ></cerrix-textarea>
            </cerrix-field-wrapper>
        </div>
    </form>

    <ng-template #step2Form>
        <div
            *ngIf="
                form.get('customFieldType').value !== FieldType.SingleSelect.toString() ||
                form.get('customFieldType').value !== FieldType.MultiSelect.toString()
            "
            class="custom-field-options"
        >
            <cerrix-field-wrapper
                type="secondary"
                label="Add answer to select"
                [required]="true"
                class="add-option-container"
            >
                <div class="controls">
                    <cerrix-color-picker-simple
                        [(ngModel)]="newOptionColor"
                        [allowEmpty]="true"
                    ></cerrix-color-picker-simple>
                    <cerrix-input
                        class="add-option-input"
                        [(ngModel)]="newOptionName"
                        (keydown.enter)="onAddOption()"
                    ></cerrix-input>
                    <cerrix-button
                        class="add-btn"
                        size="medium"
                        [disabled]="!newOptionName"
                        (click)="onAddOption()"
                        >Add</cerrix-button
                    >
                </div>
            </cerrix-field-wrapper>

            <div
                class="options-list-container"
                cdkDropList
                (cdkDropListDropped)="dropListItem($event)"
            >
                @if (customFieldSelectOptions.length > 0) { @for (optionId of
                customFieldSelectOptionsVisibleIds; track $index) { @let option =
                customFieldSelectOptions[optionId]; @if (option) {@if ($index ===
                editingOptionIndex) {
                <div class="option-item edit" #editingOptionItem>
                    <div class="option-data">
                        <cerrix-color-picker-simple
                            [(ngModel)]="editingOption.color"
                            [allowEmpty]="true"
                        ></cerrix-color-picker-simple>
                        <cerrix-input
                            class="option-editor"
                            [required]="true"
                            [(ngModel)]="editingOption.name"
                        ></cerrix-input>
                    </div>
                    <div class="option-actions">
                        <cerrix-button
                            class="action-button"
                            color="danger"
                            (click)="onCancelEditOption()"
                            ><cerrix-icon icon="close-small" [size]="14"></cerrix-icon
                        ></cerrix-button>
                        <cerrix-button
                            class="action-button"
                            color="success"
                            [disabled]="!editingOption.name"
                            (click)="onSaveOption(option)"
                            ><cerrix-icon icon="check" [size]="14"></cerrix-icon
                        ></cerrix-button>
                    </div>
                </div>
                } @else {
                <div
                    class="option-item"
                    cdkDrag
                    [cdkDragDisabled]="!option.isVisible || editingOptionIndex > -1"
                >
                    <div class="option-data">
                        <span class="color-badge" [style.backgroundColor]="option.color"></span>
                        <span>{{ option.name }}</span>
                    </div>
                    <div class="option-actions">
                        @if (option.isVisible && editingOptionIndex === -1) {
                        <cerrix-icon
                            icon="move"
                            [size]="16"
                            color="#69729F"
                            cdkDragHandle
                        ></cerrix-icon>
                        }
                        <cerrix-drop-menu
                            class="action-menu"
                            icon="menu-kebab"
                            [iconSize]="16"
                            iconColor="#69729F"
                            [dropMenuOnTop]="true"
                        >
                            <div (click)="onEditOption($index)">
                                <cerrix-icon icon="edit" [size]="16"></cerrix-icon><span>Edit</span>
                            </div>
                            <div (click)="toggleOption(option, $index)">
                                <cerrix-icon icon="hide" [size]="16"></cerrix-icon><span>Hide</span>
                            </div></cerrix-drop-menu
                        >
                    </div>
                </div>
                } } } @for (optionId of customFieldSelectOptionsHiddenIds; track $index) { @let
                option = customFieldSelectOptions[optionId]; @if (option) {
                <div class="option-item hidden">
                    <div class="option-data">
                        <span class="color-badge" [style.backgroundColor]="option.color"></span>
                        <span>{{ option.name }}</span>
                    </div>
                    <div class="option-actions">
                        <cerrix-drop-menu
                            class="action-menu"
                            icon="menu-kebab"
                            [iconSize]="16"
                            iconColor="#69729F"
                            [dropMenuOnTop]="true"
                        >
                            <div (click)="toggleOption(option, $index)">
                                <cerrix-icon icon="show" [size]="16"></cerrix-icon><span>Show</span>
                            </div></cerrix-drop-menu
                        >
                    </div>
                </div>
                } } } @else {
                <div class="no-option-container">Add at least one option...</div>
                }
            </div>
        </div>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions
    style="
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        gap: 10px;
        padding: 23px 32px 23px 32px;
    "
>
    <cerrix-button *ngIf="step === 1; else previousBtn" color="accent" mat-dialog-close
        >Close</cerrix-button
    >
    <ng-template #previousBtn>
        <cerrix-button color="accent" (click)="onPrevious()">Previous step</cerrix-button>
    </ng-template>

    <cerrix-button
        *ngIf="
            step === 2 ||
                (form.get('customFieldType').value !== FieldType.SingleSelect.toString() &&
                    form.get('customFieldType').value !== FieldType.MultiSelect.toString());
            else nextBtn
        "
        color="success"
        [disabled]="!this.form.valid"
        (click)="saveChanges()"
        >{{ saveText }}</cerrix-button
    >
    <ng-template #nextBtn>
        <cerrix-button [disabled]="!nextEnabled" color="primary" (click)="onNext()"
            >Next</cerrix-button
        >
    </ng-template>
</mat-dialog-actions>
