import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { JobSchedulerDataService } from "./services/job-scheduler-data.service";
import { JobSchedulerStatusComponent } from "./job-scheduler-status/job-scheduler-status.component";
import { JobSchedulerListComponent } from "./job-scheduler-status/components/job-scheduler-list/job-scheduler-list.component";

@NgModule({
    declarations: [JobSchedulerStatusComponent, JobSchedulerListComponent],
    exports: [],
    imports: [CommonModule, SharedModule],
})
export class JobSchedulerModule {
    static forRoot(): ModuleWithProviders<JobSchedulerModule> {
        return {
            ngModule: JobSchedulerModule,
            providers: [JobSchedulerDataService],
        };
    }
}
