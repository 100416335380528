<mat-tree
    [dataSource]="leftMenuDataSource"
    [treeControl]="treeControl"
    style="background: transparent"
>
    <!-- leaf node -->
    <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        matTreeNodePaddingIndent="15px"
        style="color: white"
    >
        <button mat-icon-button disabled *ngIf="!node.children && node.level === 0"></button>

        <button
            mat-button
            [disabled]="node.item && !!node.item && node.item.id === this.standingDataType"
            [color]="
                node.item && !!node.item && node.item.id === this.standingDataType
                    ? 'gray'
                    : 'white'
            "
            (click)="leftMenuAction(node)"
        >
            <mat-icon *ngIf="node.item" style="margin-right: 0.2rem">
                {{ node.item.icon }}
            </mat-icon>
            {{ node.name }}
        </button>
    </mat-tree-node>

    <!-- expandable nodes -->
    <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        matTreeNodePaddingIndent="15px"
        style="color: white"
        isExpanded
    >
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon>
                {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
            </mat-icon>
        </button>
        <mat-icon *ngIf="node.icon" style="margin-right: 0.2rem">
            {{ node.icon }}
        </mat-icon>

        {{ node.name }}
    </mat-tree-node>
</mat-tree>
