<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    class="details"
    *ngIf="!tab.showLoader"
    (change)="handleMenuItemClick($event)"
    data-uiid="audit-finding-detail-main"
>
    <div class="detail-page-action-bar">
        <button
            data-uiid="btn-audit-finding-save"
            class="btn btn-cerrix"
            (click)="save()"
            *ngIf="permissions.canEdit || permissions.canEditManagementResponse"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save finding</span>
        </button>

        <span class="date-edited" *ngIf="model.id > 0">
            Last save: {{ model.modifiedOn | dateTimeFormat }}
        </span>

        <div class="workflow-area" *ngIf="workflowStatusText">
            <span class="workflow-description">
                Workflow Status |
                <b>{{ workflowStatusText }}</b>
            </span>
            <span>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="acceptButtonText"
                    (click)="acceptButtonClick()"
                    data-uiid="audit-finding-workflow-accept-button"
                >
                    {{ acceptButtonText }}
                </button>
                <button
                    class="btn btn-danger"
                    type="button"
                    *ngIf="rejectButtonText"
                    (click)="rejectButtonClick()"
                    data-uiid="audit-finding-workflow-reject-button"
                >
                    {{ rejectButtonText }}
                </button>
            </span>
        </div>
    </div>

    <div class="screens" name="Audit Finding" menuicon="far fa-radar" menusystemsubmenu>
        <div
            name="Details"
            menuItemId="finding-detail"
            menuicon="fas fa-info-circle"
            menusystemitem
        >
            <audit-finding-detail-main
                [parentFormGroup]="formGroup"
                [findingModel]="model"
                [permissions]="permissions"
            ></audit-finding-detail-main>

            <audit-finding-detail-audit-info
                *ngIf="model && model.id > 0"
                [findingGuid]="model.guid"
            >
            </audit-finding-detail-audit-info>

            <generic-list-manager
                *ngIf="tableConfig && model && model.findingReportGuid"
                [config]="tableConfig"
            ></generic-list-manager>

            <div class="detail-page-card" *ngIf="tableConfig && model && !model.findingReportGuid">
                <div class="detail-page-card-title">Measures of improvements</div>
                <div class="detail-page-card-body padded form-group-styling">
                    <div class="row">Please create findings report first</div>
                </div>
            </div>
        </div>
        <div
            name="History"
            menuicon="fas fa-history"
            menuItemId="history"
            menusystemitem
            *ngIf="permissions.canViewHistory"
        >
            <app-history-overview
                [Id]="model.guid"
                [historyDataService]="_ds"
                #historyDetailOverviewComponent
            ></app-history-overview>
        </div>
        <div
            name="Options"
            menuicon="fas fa-check-double"
            menusystemitem
            *ngIf="permissions.canDelete"
        >
            <detail-options
                moduleName="audit finding"
                [objectName]="'audit finding'"
                [allowDelete]="true"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
