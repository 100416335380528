<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    class="screen-system"
    (change)="handleMenuItemClick($event)"
    *ngIf="!tab.showLoader && user"
>
    <div
        class="user-screens"
        [attr.name]="'User management'"
        menuicon="fas fa-user-cog"
        menusystemsubmenu
    >
        <div name="Profile" menuicon="fas fa-user" menusystemitem>
            <div class="row">
                <div class="col">
                    <div class="detail-page-card">
                        <div class="detail-page-card-title">Basic information</div>
                        <div class="detail-page-card-actions">
                            <button class="btn btn-raised red" (click)="logout()">
                                <i class="fas fa-sign-out-alt"></i>
                                <span class="ms-2">Logout</span>
                            </button>
                        </div>
                        <div
                            class="detail-page-card-body padded cerrix-control-sm form-group-styling"
                        >
                            <div class="form-group">
                                <label>Firstname</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="user.firstname"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label>Lastname</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="user.lastname"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="user.email"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label>Organization</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="user.organization"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6" *ngIf="user.passwordChangeAllowed">
                    <div class="detail-page-card">
                        <div class="detail-page-card-title">Change password</div>
                        <div class="detail-page-card-actions">
                            <button class="btn btn-raised" (click)="changePassword()">
                                <i class="fas fa-unlock"></i>
                                <span class="ms-2">Update password</span>
                            </button>
                        </div>
                        <div
                            class="detail-page-card-body padded cerrix-control-sm form-group-styling"
                        >
                            <div class="form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="user.username"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label>Old password</label>
                                <input
                                    type="password"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="passwordChangeModel.oldPassword"
                                />
                            </div>
                            <div class="form-group">
                                <label>New password</label>
                                <input
                                    type="password"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="passwordChangeModel.newPassword"
                                />
                            </div>
                            <div class="form-group">
                                <label>Confirm new password</label>
                                <input
                                    type="password"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="passwordChangeModel.newPasswordConfirm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <user-audit [audit]="user.audit" [loadOnStartup]="true"></user-audit>
        </div>

        <div
            name="Notification Settings"
            menuItemId="notification-settings"
            menuicon="fas fa-bell"
            menusystemitem
            *ngIf="user"
        >
            <user-notification-settings
                [userModel]="user"
                [parentTab]="tab"
            ></user-notification-settings>
        </div>

        <div name="Roles" menuItemId="roles" menuicon="fas fa-user-tag" menusystemitem>
            <div class="detail-page-action-bar">
                <span class="date-edited">
                    Active roles are indicated by a <i class="fas fa-check-square"></i>. Roles
                    indicated with a <i class="far fa-square"></i> are not active yet, but will be
                    once you logout and login again.
                </span>
            </div>

            <role-assign
                [moduleRoles]="user.moduleRoles"
                [(userModel)]="user"
                [allowChange]="false"
            >
            </role-assign>
        </div>
        <div name="API Keys" menuItemId="api-keys" menuicon="fas fa-key" menusystemitem>
            <api-key-overview></api-key-overview>
        </div>
        <div
            name="IP Whitelisting"
            menuItemId="ip-whitelisting"
            menuicon="fal fa-location"
            menusystemitem
            *ngIf="ipConfigType"
        >
            <standingdata-generic [type]="ipConfigType" name="IP Config"></standingdata-generic>
        </div>
    </div>
</div>
