import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { SortDirection } from "@angular/material/sort";

import { Observable } from "rxjs";

import { IncidentOverviewModel } from "../models/incident-overview.model";
import { IncidentHeaderModel } from "../models/incident-header.model";
import { PresetModel } from "../../preset/models/preset.model";
import { WorkspaceResult } from "@app/shared/models/Generic/workspace-result.model";
import { IncidentDetailsModel } from "../models/incident-details.model";
import { IncidentEditModel } from "../models/incident-edit.model";
import { IncidentCommentModel } from "../models/incident-comment.model";
import { IncidentCommentAddModel } from "../models/incident-comment-add.model";
import { IncidentMoiOverviewModel } from "../models/incident-moi-overview-model";
import { IncidentSearchModel } from "../models/incident-search.model";

@Injectable()
export class IncidentService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/incidents`;

    public getIncident(incidentId: string): Observable<IncidentDetailsModel> {
        return this.httpClient.get<IncidentDetailsModel>(`${this.baseUrl}/${incidentId}`);
    }

    public getEditIncidentRights(incidentId: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/${incidentId}/editable`);
    }

    public getDeleteIncidentPermission(incidentId: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/${incidentId}/deletable`);
    }
    
    public canReopenIncident(incidentId: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/${incidentId}/reopen`);
    }

    public addIncident(incident: IncidentEditModel): Observable<string> {
        return this.httpClient.post<string>(`${this.baseUrl}`, incident);
    }

    public editIncident(
        incident: IncidentEditModel,
        files: { [id: string]: File }
    ): Observable<IncidentDetailsModel> {
        const formData = new FormData();

        formData.append("incident", JSON.stringify(incident));

        Object.entries(files).forEach(([key, value]) => formData.append("files", value, key));

        return this.httpClient.put<IncidentDetailsModel>(
            `${this.baseUrl}/${incident.id}`,
            formData
        );
    }

    public deleteIncident(incidentId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/${incidentId}`);
    }

    public getIncidentsForMoi(): Observable<IncidentMoiOverviewModel[]> {
        return this.httpClient.get<IncidentMoiOverviewModel[]>(`${this.baseUrl}/moi`);
    }

    public getIncidentSummaryForMoi(incidentId: string): Observable<IncidentMoiOverviewModel> {
        return this.httpClient.get<IncidentMoiOverviewModel>(
            `${this.baseUrl}/summary/${incidentId}`
        );
    }

    public getIncidents(
        offset: number,
        count: number,
        sortBy?: string,
        sortdirection?: SortDirection,
        searchValue?: string,
        presetId?: string,
        preset?: PresetModel
    ): Observable<WorkspaceResult<IncidentOverviewModel>> {
        const searchModel: IncidentSearchModel = {
            offset: offset,
            count: count,
            sortBy: sortBy,
            sortdirection: sortdirection === "asc",
            searchValue: searchValue,
            presetId: presetId,
            preset: JSON.stringify(preset),
        };

        return this.httpClient.post<WorkspaceResult<IncidentOverviewModel>>(
            `${this.baseUrl}/search`,
            searchModel
        );
    }

    public exportIncidents(
        dateFormat: string,
        timeFormat: string,
        timeZone: string,
        sortBy?: string,
        sortdirection?: SortDirection,
        searchValue?: string,
        presetId?: string,
        preset?: PresetModel
    ): Observable<Blob> {
        const exportSearchModel: IncidentSearchModel = {
            dateFormat: dateFormat,
            timeFormat: timeFormat,
            timeZone: timeZone,
            sortBy: sortBy,
            sortdirection: sortdirection === "asc",
            searchValue: searchValue,
            presetId: presetId,
            preset: JSON.stringify(preset),
        };

        return this.httpClient.post<Blob>(`${this.baseUrl}/export`, exportSearchModel, {
            responseType: "blob" as "json",
        });
    }

    public downloadDocument(incidentId: string, documentId: string): Observable<Blob> {
        return this.httpClient.get<Blob>(
            `${this.baseUrl}/${incidentId}/documents/${documentId}/download`,
            {
                responseType: "blob" as "json",
            }
        );
    }

    public getComments(incidentId: string): Observable<IncidentCommentModel[]> {
        return this.httpClient.get<IncidentCommentModel[]>(
            `${this.baseUrl}/${incidentId}/comments`
        );
    }

    public addComment(
        incidentId: string,
        comment: IncidentCommentAddModel
    ): Observable<IncidentCommentModel> {
        return this.httpClient.put<IncidentCommentModel>(
            `${this.baseUrl}/${incidentId}/comments`,
            comment
        );
    }

    public getHeaders(presetId?: string): Observable<IncidentHeaderModel[]> {
        let params = new HttpParams();

        if (presetId) {
            params = params.append("presetId", presetId);
        }

        return this.httpClient.get<IncidentHeaderModel[]>(`${this.baseUrl}/columns`, {
            params: params,
        });
    }

    public reopenIncident(incidentId: string, comment: string): Observable<IncidentDetailsModel> {
        return this.httpClient.put<IncidentDetailsModel>(`${this.baseUrl}/${incidentId}/reopen`, {
            comment: comment,
        });
    }
}
