<div class="panel">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Header settings</div>
        <div class="detail-page-card-body padded">
            <div class="form-group">
                <cerrix-checkbox
                    label="Hide header"
                    [(value)]="config.customConfig.hideHeader"
                ></cerrix-checkbox>
            </div>

            <div class="form-group">
                <label>Custom title</label>
                <cerrix-textfield
                    [placeholder]="config.title"
                    [(ngModel)]="config.customConfig.customTitle"
                    [disabled]="config.customConfig.hideHeader"
                ></cerrix-textfield>
            </div>

            <div class="form-group">
                <label>Custom icon</label>
                <cerrix-icon-picker
                    [(value)]="config.customConfig.customIcon"
                    [disabled]="config.customConfig.hideHeader"
                ></cerrix-icon-picker>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="graphItems.length > 0 || legendItems.length > 1">
        <div class="detail-page-card-title">Graph settings</div>
        <div class="detail-page-card-body padded">
            <div class="form-group" *ngIf="graphItems.length > 0">
                <label>Graph type</label>
                <ng-select
                    [items]="graphItems"
                    (change)="filterChanged()"
                    bindLabel="Value"
                    bindValue="Key"
                    [(ngModel)]="config.customConfig.graphType"
                >
                </ng-select>
            </div>

            <div class="form-group" *ngIf="legendItems.length > 1">
                <label>Legend type</label>
                <ng-select
                    [items]="legendItems"
                    (change)="filterChanged()"
                    bindLabel="Value"
                    bindValue="Key"
                    [(ngModel)]="config.customConfig.legendType"
                >
                </ng-select>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="widgetSettings && widgetSettings.length > 0">
        <div class="detail-page-card-title">Widget settings</div>
        <div class="detail-page-card-body padded">
            <div class="form-group" *ngFor="let field of widgetSettings">
                <generic-field-editor
                    [row]="config.customConfig.filterValues"
                    [field]="field"
                    [editEnabled]="true"
                    [hideFillers]="true"
                    [selfInit]="true"
                    (valueChanged)="filterChanged()"
                ></generic-field-editor>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="dataFilters && dataFilters.length > 0">
        <div class="detail-page-card-title">Data filtering</div>
        <div class="detail-page-card-body padded">
            <div
                class="form-group global-filter-group"
                *ngIf="config.globalFilterKeys && config.globalFilterKeys.length > 0"
            >
                <cerrix-checkbox
                    label="Enable global filtering"
                    [(value)]="config.customConfig.globalFilterEnabled"
                ></cerrix-checkbox>
                <i
                    class="fas fa-info-circle ms-2"
                    *ngIf="globalFilteringDescription"
                    [matTooltip]="globalFilteringDescription"
                ></i>
            </div>

            <div class="form-group" *ngFor="let field of dataFilters">
                <generic-field-editor
                    [row]="config.customConfig.filterValues"
                    [field]="field"
                    [editEnabled]="true"
                    [hideFillers]="true"
                    [selfInit]="true"
                    (valueChanged)="filterChanged()"
                ></generic-field-editor>
            </div>
        </div>
    </div>
</div>
