import { Component, Input, OnInit } from "@angular/core";
import { AdvEffDataService } from "../../shared/services";
import { EffectivenessConclusionsModel, EffectivenessModel } from "../../shared/models";
import { FinalConclusion } from "../status-components/components/eff-final-conclusion-box/models/final-conclusion.model";

@Component({
    selector: "eff-detail-final-conclusion",
    templateUrl: "./eff-detail-final-conclusion.component.html",
    styleUrls: ["./eff-detail-final-conclusion.component.scss"],
})
export class EffDetailFinalConclusionComponent implements OnInit {
    @Input() effectivenessModel: EffectivenessModel;

    conclusions: EffectivenessConclusionsModel;

    testerFinalConclusion: FinalConclusion;
    reviewerFinalConclusion: FinalConclusion;

    constructor(private _ds: AdvEffDataService) {}

    ngOnInit() {
        this._ds.getConclusions(this.effectivenessModel.guid).subscribe((conclusions) => {
            this.conclusions = conclusions;
            this.testerFinalConclusion = {
                author: conclusions.testerName,
                conclusion: conclusions.testerComment,
                authorLabel: "Tester",
                scoreName: conclusions.testerScore,
                scoreColor: conclusions.testerScoreColor,
            };

            if (conclusions.reviewerName) {
                this.reviewerFinalConclusion = {
                    author: conclusions.reviewerName,
                    conclusion: conclusions.reviewerComment,
                    authorLabel: "Reviewer",
                    scoreName: conclusions.reviewerScore,
                    scoreColor: conclusions.reviewerScoreColor,
                };
            }
        });
    }
}
