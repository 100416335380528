import { DataStructureModel } from "./DataStructureModel";

export class DataSubjectModel {
    connectedId: number;

    systemId: number;
    id: number;
    name: string;

    dataStructures: DataStructureModel[];

    // only used client-side
    dataStructuresLoaded: boolean;

    constructor() {
        this.dataStructures = [];
    }
}