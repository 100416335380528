import { Component, Input } from "@angular/core";
import { WorkflowHistoryModel } from "../../shared/models/workflow-history-model";

@Component({
    selector: "adv-eff-workflow-history",
    templateUrl: "./adv-eff-workflow-history.component.html",
    styleUrls: ["./adv-eff-workflow-history.component.scss"]
})
export class WorkflowHistoryComponent {
    @Input() workflowHistory: WorkflowHistoryModel[];
}
