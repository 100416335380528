<div
    menuSystem
    [cerrixTab]="cerrixTab"
    class="details"
    (change)="handleMenuItemClick($event)"
    data-uiid="audit-detail-page"
    *ngIf="!cerrixTab.showLoader"
>
    <div class="detail-page-action-bar">
        <button
            class="btn btn-cerrix"
            (click)="save()"
            data-uiid="save-audit"
            *ngIf="permissions.canEdit"
        >
            <i class="fas fa-save"></i>
            <span data-uiid="save" class="d-none d-lg-inline">Save</span>
        </button>
    </div>

    <div class="screens" name="Audit" menuicon="fal fa-clipboard-list-check" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <audit-detail-main
                [auditModel]="auditModel"
                [standingData]="standingData"
                [formGroup]="formGroup"
                [readonly]="true"
            ></audit-detail-main>
        </div>
        <div
            name="Requests"
            menuicon="fas fa-user-clock"
            menusystemitem
            [attr.menuItemId]="documentRequestsTabId"
        >
            <audit-detail-auditee-requests
                #requests
                [formGroup]="formGroup"
                [permissions]="permissions"
                [auditModel]="auditModel"
                [cerrixTab]="cerrixTab"
            ></audit-detail-auditee-requests>
        </div>
        <div
            name="Findings"
            [attr.menuItemId]="findingsTabId"
            menuicon="far fa-radar"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-finding-linking
                [auditGuid]="auditModel.guid"
                #findings
            ></audit-detail-finding-linking>
        </div>
    </div>
</div>
