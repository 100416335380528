import { Component, Input, OnInit } from "@angular/core";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { NavigatorConfigType } from "@app/navigators/enums/NavigatorConfigType";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { MoiConfig } from "@models/moi/MoiConfig";

@Component({
    selector: "business-mois",
    templateUrl: "./business-mois.component.html",
})
export class BusinessMoisComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissionsModel: BusinessNavigatorPermissionModel;
    config: GenericListConfig;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _navFactory: NavGenericListFactory,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        this.config = this._navFactory.createConfig(NavigatorConfigType.Moi);

        this.config.dataMethod = () => this._businessDs.getMois(this.requestModel);
        this.config.hideRefreshButton = false;

        this.config.allowAdd = this.permissionsModel.canAddMoi;
        this.config.addOverride = () => {
            const config = <MoiConfig>{
                OrganizationId: this.requestModel.organizationID,
                BusinessId: this.requestModel.businessID,
            };
            this._tabService.generateTab(this._pages.MoiDetail, 0, "(M) New MoI", config);
        };
    }
}
