import { Component, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ProcessWidgetLink } from "@app/dashboard/shared/enums/ProcessWidgetLink.enum";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { BusinessNavigatorDetailComponent } from "@app/navigators/business-navigator/business-navigator-detail/business-navigator-detail.component";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { CerrixActionButtonConfig } from "@app/shared/models/CerrixButtonConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { toPromise } from "@methods/CommonMethods";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { BaseWidget } from "../BaseWidget";

@Component({
    selector: "process-widget",
    templateUrl: "./process-widget.component.html",
    styleUrls: ["./process-widget.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ProcessWidgetComponent extends BaseWidget {
    @ViewChild("imageContainer") imageContainer: TemplateRef<any>;
    previewImage: string;
    isMaximized: boolean = false;
    prompt: CerrixPromptComponent;

    constructor(
        protected _tabService: TabService,
        private pages: Pages,
        private procesEditorService: ProcessEditorDataService,
        private _promptService: CerrixPromptService,
        private _toatrService: ToastrService
    ) {
        super();
    }

    protected onFilterChange(): void {
        this.config.showLoader = true;
        this.previewImage = null;
        super.onFilterChange();
    }

    async load() {
        const BusinessDimensionId = this.getFilter(WidgetFilterKey.GlobalFilter.BusinessDimension);

        if (!BusinessDimensionId) {
            this.config.noData = true;
            this.config.noDataExplanation = "Business Dimension is required!";
        } else {
            const previewImage = await toPromise(
                this.procesEditorService.getPreview(BusinessDimensionId)
            );

            if (previewImage) {
                this.previewImage = previewImage;
                this.config.noData = false;
                this.config.noDataExplanation = null;
            }

            if (!this.previewImage) {
                this.config.noData = true;
                this.config.noDataExplanation =
                    "Preview image currently unavailable. Save process in process editor to create image.";
            }
        }

        super.load();
    }

    maximize() {
        if (this.config && !this.isMaximized) {
            this.prompt = this._promptService.alert({
                hasBackdrop: true,
                minWidth: "70vw",
                minHeight: "80vh",
                maxHeight: "40vh",
                data: {
                    title: this.config.title,
                    customTemplate: this.imageContainer,
                    extraButtons: this.getNavigateButton(),
                },
            });

            this.prompt.getResult().subscribe(() => {
                this.isMaximized = false;
                this.prompt = null;
            });

            this.isMaximized = true;
        }
    }

    private getNavigateButton(partialConfig?: Partial<CerrixActionButtonConfig>) {
        let buttonconfig: CerrixActionButtonConfig[] = [];
        let baseConfig = <CerrixActionButtonConfig>{
            text: "Open",
            icon: "far fa-external-link",
            class: "btn btn-outline-secondary",
            show: true,
            action: () => this.openProcess(),
        };

        if (partialConfig) {
            baseConfig = Object.assign(baseConfig, partialConfig);
        }
        buttonconfig.push(baseConfig);
        return buttonconfig;
    }

    private openProcess() {
        this.isMaximized = false;

        if (this.config.noData) {
            this._toatrService.warning("There is no process to show.", "No process");
            return;
        }

        if (this.prompt) {
            this.prompt.close();
            this.prompt = null;
        }

        if (
            this.getFilter(WidgetFilterKey.ProcessLink.LinkTo) == ProcessWidgetLink.ProcessOverview
        ) {
            let config = {
                businessDimension: this.getFilter(WidgetFilterKey.GlobalFilter.BusinessDimension),
                organization: this.getFilter(WidgetFilterKey.GlobalFilter.Organization),
            };

            this._tabService.generateTab(this.pages.NavProcessOverview, null, null, config);
        } else if (
            this.getFilter(WidgetFilterKey.ProcessLink.LinkTo) ==
            ProcessWidgetLink.BusinessNavigator
        ) {
            const config: BusinessNavigatorRequestModel = {
                organizationID: this.getFilter(WidgetFilterKey.GlobalFilter.Organization),
                businessID: this.getFilter(WidgetFilterKey.GlobalFilter.BusinessDimension),
                hasAssessment: true,
            };

            this._tabService.generateTab(
                this.pages.BusinessAssessment,
                `${config.organizationID}-${config.businessID}`,
                "Business Assessment",
                config
            );
        }
    }

    protected async onGlobalOpenClick() {
        this.openProcess();
    }
}
