import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { IncidentLinkModel } from "../models/incident-link.model";
import { IncidentLinkService } from "./incident-link.service";

@Injectable({
    providedIn: "root",
})
export class IncidentRisksService implements IncidentLinkService {
    private readonly httpClient: HttpClient = inject(HttpClient);
    private readonly baseUrl = `${localStorage.getItem("incidentUrl")}/api/risks`;

    public getAll(): Observable<IncidentLinkModel[]> {
        return this.httpClient.get<IncidentLinkModel[]>(this.baseUrl);
    }

    public search(searchTerm: string): Observable<IncidentLinkModel[]> {
        return this.httpClient.get<IncidentLinkModel[]>(`${this.baseUrl}?searchTerm=${searchTerm}`);
    }

    public getByIds(ids: string[]): Observable<IncidentLinkModel[]> {
        const params = ids.map((id) => `guids=${id}`).join("&");

        return this.httpClient.get<IncidentLinkModel[]>(`${this.baseUrl}?${params}`);
    }
}
