import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Component, OnInit } from "@angular/core";
import { FilterConfig } from "../../../common/models/workspace/FilterConfig";
import { FilterType } from "../../../common/enums/FilterType";
import { TabService } from "../../../services/tabs/TabService";
import { Pages } from "../../../common/constants/pages/Pages";
import { KriDataService } from "../services/kri-data.service";
import { ModuleType } from "../../../common/enums/ModuleType";
import { RendererConfig } from "../../../common/models/workspace/RendererConfig";
import { RendererType } from "../../../common/enums/RendererType";
import { TabModel } from "../../../common/models/generic/TabModels/TabModel";
import { WorkspaceButtonConfig } from "../../../common/models/workspace/WorkspaceButtonConfig";
import { PermissionsService } from "../../../services/permissions/PermissionsService";
import { FormatType } from "@enums/FormatType";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "app-kri-overview",
    templateUrl: "./kri-overview.component.html",
    styleUrls: ["./kri-overview.component.scss"],
})
export class KriOverviewComponent implements OnInit {
    moduleType = ModuleType.KRI;
    tabID: string;
    tab: TabModel;

    data: Promise<any[]>;
    headerLookup: any = {
        identifier: "Identifier",
        name: "Name",
        description: "Description",
        calculation: "Calculation",
        dataOwner: "Data owner",
        comments: "Comments",
        source: "Source",
        kriFrequency: "KRI frequency",
        aggregate: "Aggregate",
        measurement: "Measurement",
        greenThreshold: "Green threshold",
        redThreshold: "Red threshold",
        kriKpi: "KRI / KPI",
        graphType: "Graph type",
        riskCatalogueNames: "Risk catalogues",
        organizationNames: "Organizations",
        businessDimensionNames: "Business dimensions",
        documentCount: "# of Documents",
        organizationPaths: "Organization paths",
        aggregateScore: "Aggregate Score",
    };
    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: "organizationIds",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: "businessDimensionIds",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "aggregateScore",
            actionColumn: "aggregateScoreColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [
        {
            text: "Upload data",
            icon: "fas fa-cloud-upload-alt",
            isDisabled: !this._permissions.permissions.HasKriDataUploadAccess,
            clickEvent: () => {
                this._tabs.generateTab(this.pages.KriUploadData);
            },
        },
        {
            text: "Data Graphs",
            icon: "fas fa-chart-line",
            getVisibleRange: true,
            clickEvent: (selection: string[]) => {
                const open = () => {
                    this._tabs.generateTab(this.pages.KriDataGraphOverview, null, "KRI Graphs", {
                        guids: selection,
                    });
                };

                if (selection.length <= 10) {
                    open();
                } else {
                    this._promptService
                        .confirm(
                            `Open ${selection.length} Data Graphs?`,
                            "More than 10 KRIs are selected, which will increase load times considerably. Are you sure you want to continue?"
                        )
                        .onConfirm()
                        .subscribe(() => open());
                }
            },
        },
    ];

    numberProps: string[] = ["greenThreshold", "redThreshold", "documentCount"];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _kriDS: KriDataService,
        private _tabs: TabService,
        public pages: Pages,
        private _permissions: PermissionsService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this.setRendererConfig();
        this.permissions = this._permissions.permissions.Workspace.kri;

        this.data = this._kriDS.getKris().toPromise().then(this.mapHeaders);
    }

    setRendererConfig() {
        ["greenThreshold", "redThreshold", "documentCount"].forEach((numberProp) => {
            const config = <RendererConfig>{
                textColumn: numberProp,
                type: RendererType.Default,
                formatType: FormatType.DecimalFormat,
            };

            this.rendererConfig.push(config);
        });
    }

    openDetail(kri: any) {
        this._tabs.generateTab(
            this.pages.KriDetail,
            kri["guid"],
            `(K) ${+kri["identifier"]} - ${kri["name"]}`
        );
    }

    add() {
        this._tabs.generateTab(this.pages.KriDetail, null, "(K) New KRI");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
