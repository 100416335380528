import { Component } from "@angular/core";
import { CerrixThemingService } from "./shared/services/cerrix-theming.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    constructor(
        themeService: CerrixThemingService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        // We just have this here so it starts the theming as soon as the login page.
        const _ = themeService.currentTheme;

        this.registerMsIcons();
    }

    private registerMsIcons(): void {
        const msFileIconLoc = "../assets/icons/microsoft/";
        this.matIconRegistry.addSvgIcon(
            "ms-word",
            this.domSanitizer.bypassSecurityTrustResourceUrl(`${msFileIconLoc}word.svg`)
        );
        this.matIconRegistry.addSvgIcon(
            "ms-excel",
            this.domSanitizer.bypassSecurityTrustResourceUrl(`${msFileIconLoc}excel.svg`)
        );
        this.matIconRegistry.addSvgIcon(
            "ms-powerpoint",
            this.domSanitizer.bypassSecurityTrustResourceUrl(`${msFileIconLoc}powerpoint.svg`)
        );

        const msFileExts = [
            // Word
            "doc",
            "docm",
            "docx",
            "dot",
            "dotm",
            "dotx",
            "odt",

            // Powerpoint
            "odp",
            "pot",
            "potm",
            "potx",
            "pps",
            "ppsm",
            "ppsx",
            "ppt",
            "pptm",
            "pptx",

            // Excel
            "csv",
            "ods",
            "xls",
            "xlsm",
            "xlsx",
        ];
        for (let msFileExt of msFileExts) {
            this.matIconRegistry.addSvgIcon(
                `ms-${msFileExt}`,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`${msFileIconLoc}${msFileExt}.svg`)
            );
        }
    }
}
