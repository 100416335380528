<div class="incident-documents-tab">
    <div class="section-title">Attachments</div>
    <cerrix-documents-list
        [canAdd]="canEditIncident"
        [canEdit]="canEditIncident"
        [canDelete]="canEditIncident"
        [documents]="documents"
        (fileDownload)="onFileDownload($event)"
        (documentsChange)="onDocumentsChange($event)"
        (openDocumentViewer)="onOpenDocumentViewer($event)"
    ></cerrix-documents-list>
</div>
