<div class="fancy-loader" *ngIf="risk && isLoading"></div>
<thirdparty-linkable
    *ngIf="risk && !isLoading"
    #linkableThirdParty
    typename="third party"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="[]"
    [orderByValueProperty]="true"
    [disabled]="true"
>
</thirdparty-linkable>
