import { setAppAccentColor } from "@methods/CommonMethods";
import { Component, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { UserAuthenticationService } from "../shared/user-authentication.service";
import { AuthenticationConfiguration } from "../shared/authentication-configuration";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginResult } from "../shared/user-login-result.enum";
import { JwtExternalAuthenticationService } from "../shared/jwt-external-authentication.service";
import { AppConfigService } from "@app/shared/services/app-config.service";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";

enum LoginPageView {
    login,
    forgotPassword,
    resetPassword,
    newLocation,
}

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit {
    @Output() loginSuccessEvent = new EventEmitter();

    currentYear = new Date().getFullYear();
    onlyShowModal = false;
    config: AuthenticationConfiguration;

    warningMessage: string;
    notificationMessage: string;

    showLoader = false;
    loaderMessage = "";

    username: string;
    password: string;

    currentView = LoginPageView.login;
    allViews = LoginPageView;

    private passwordResetSecret: string;
    newPassword = "";
    newPasswordConfirm = "";

    locationVerificationCode = "";

    forcedPasswordReset = false;

    constructor(
        private _ds: UserAuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private appConfigService: AppConfigService,
        private jwtExternalAuthService: JwtExternalAuthenticationService,
        private settingsService: SettingsDataService
    ) {
        this.setNewIPLocalStorageFlag(false);
    }

    async ngOnInit() {
        this.onlyShowModal = this.loginSuccessEvent && this.loginSuccessEvent.observers.length > 0;

        const isAuthenticatedUser = await this._ds.isAuthenticated().toPromise();
        if (isAuthenticatedUser) {
            this.redirectToApp();
            return;
        }

        await this.checkUrl();
        this.appConfigService.getAuthenticationConfiguration().then((conf) => {
            this.config = conf;
            setAppAccentColor(this.config.accentColor);

            if (this.config.jwtExternalLoginEnabled && this.config.jwtExternalSilentLogin) {
                this.jwtExternalLogin(true);
            }
        });
    }

    ngAfterViewInit() {
        document
            .getElementsByClassName("login-page")[0]
            .addEventListener("keypress", (e: KeyboardEvent) => {
                if (e.key === "Enter") {
                    if (this.currentView === LoginPageView.login) {
                        this.login();
                    } else if (this.currentView === this.allViews.forgotPassword) {
                        this.resetPasswordRequest();
                    } else if (this.currentView === this.allViews.resetPassword) {
                        this.saveNewPassword();
                    } else if (this.currentView === this.allViews.newLocation) {
                        document.getElementById("location-confirm-button").click();
                    }
                }
            });
    }

    async checkUrl() {
        this.passwordResetSecret = this.route.snapshot.queryParams["passwordreset"];
        if (this.passwordResetSecret) {
            const isValidSecret = await this._ds
                .checkPasswordResetSecret(this.passwordResetSecret)
                .toPromise();
            if (isValidSecret) {
                this.setSecondaryPage(this.allViews.resetPassword);
            } else {
                this.passwordResetSecret = undefined;
                this.warningMessage = "Password reset link is unvalid. Please request a new link.";
            }
        }
    }

    async getIncidentUrl() {
        const url = await this.settingsService
            .getSetting(ApplicationSettings.IncidentsUrl)
            .toPromise();
        localStorage.setItem("incidentUrl", url.TextValue);
    }

    login() {
        this.showLoader = true;
        this.loaderMessage = "Logging in";
        this._ds.formLogin(this.username, this.password).subscribe(
            (result) => {
                this.resetMessages();

                if (result === UserLoginResult.Success) {
                    this.redirectToApp();
                } else if (result === UserLoginResult.ChangePassword) {
                    this.setSecondaryPage(this.allViews.resetPassword);
                    this.forcedPasswordReset = true;
                    this.showLoader = false;
                } else if (result === UserLoginResult.AlreadyLoggedIn) {
                    if (
                        confirm(
                            "Another session is already active. Do you wish to end that session and start a new one with this user?"
                        )
                    ) {
                        this.loaderMessage = "Ending old session";
                        this._ds.logout().subscribe((x) => {
                            this.loaderMessage = "Starting new session";
                            this.login();
                        });
                    } else {
                        this.redirectToApp();
                        return;
                    }
                } else if (result === UserLoginResult.WhiteListIP) {
                    this.setSecondaryPage(this.allViews.newLocation);
                    this.showLoader = false;
                } else if (result === UserLoginResult.Locked) {
                    this.warningMessage = "Login failed. Please contact your administrator.";
                    this.showLoader = false;
                } else {
                    this.warningMessage =
                        "Invalid username and/or password. Note that passwords are case sensitive.";
                    this.showLoader = false;
                }
            },
            () => (this.showLoader = false)
        );
    }

    async jwtExternalLogin(silent = false): Promise<void> {
        this.resetMessages();
        this.showLoader = true;
        this.loaderMessage = "Logging in";

        try {
            const user = silent
                ? await this.jwtExternalAuthService.loginSilent()
                : await this.jwtExternalAuthService.login();
            if (user) {
                // This will initialize form authentication for screens that can't send token (forms, mvc)
                const result = await this._ds.loginJwtExternal().toPromise();
                if (result < UserLoginResult.Success) {
                    // Login should always succeed as long as user is correctly setup
                    this.handleWarning("Login failed. Please contact your administrator.");
                    return;
                }

                // This will remove token etc. to only use form authentication after login to keep things simple
                await this.jwtExternalAuthService.removeUser();

                this.redirectToApp();
            }
        } catch (error) {
            this.handleWarning("Login failed. Please contact your administrator.");
        }
    }

    resetPasswordRequest() {
        this.resetMessages();
        this.showLoader = true;
        this.loaderMessage = "Requesting password reset";
        this._ds.passwordForgotRequest(this.username).subscribe(() => {
            this.notificationMessage =
                "A password reset link is sent to the email of entered username if user exists.";
            this.showLoader = false;
            this.currentView = LoginPageView.login;
        });
    }

    saveNewPassword() {
        this.resetMessages();
        this.showLoader = true;
        this.loaderMessage = "Saving password";
        this.currentView = LoginPageView.login;
        if (this.forcedPasswordReset) {
            this._ds
                .forcedPasswordReset(
                    this.username,
                    this.password,
                    this.newPassword,
                    this.newPasswordConfirm
                )
                .subscribe((result) => {
                    if (result.Key) {
                        this.password = this.newPassword;
                        this.login();
                    } else {
                        this.warningMessage = result.Value;
                        this.setSecondaryPage(this.allViews.resetPassword);
                        this.showLoader = false;
                    }
                });
        } else {
            // Do call to backend
            this._ds
                .passwordReset(this.passwordResetSecret, this.newPassword, this.newPasswordConfirm)
                .subscribe((result) => {
                    if (result.Key) {
                        this.notificationMessage = result.Value;
                    } else {
                        this.warningMessage = result.Value;
                        this.setSecondaryPage(this.allViews.resetPassword);
                    }

                    this.showLoader = false;
                });
        }
    }

    async paste(...args: any[]) {
        const pasted = await navigator.clipboard.readText();
        if (pasted.length >= args.length) {
            args.forEach((arg, i) => {
                arg.value = pasted[i];
            });
        }
    }

    inputKeyDown(event, nextElem, previousElem) {
        const fieldValue = event.target.value;
        if (!fieldValue && event.key === "Backspace") {
            if (previousElem != null) {
                previousElem.focus();
            }

            return;
        }

        if (fieldValue && !this.isIgnoredKey(event)) {
            if (nextElem != null) {
                nextElem.value = "";
                nextElem.focus();
            }

            return;
        }
    }

    private isIgnoredKey(event: KeyboardEvent) {
        const ignoredKeys = [
            "Backspace",
            "Shift",
            "Alt",
            "ArrowRight",
            "ArrowLeft",
            "ArrowUp",
            "ArrowDown",
        ];
        return event.ctrlKey || ignoredKeys.indexOf(event.key) >= 0;
    }

    verifyNewLocation() {
        if (this.locationVerificationCode.length === 5) {
            this.loaderMessage = "Verifying location";
            this.showLoader = true;
            this._ds
                .verifyLocation(this.username, this.locationVerificationCode)
                .subscribe((success) => {
                    if (success) {
                        this.currentView = LoginPageView.login;

                        // Set something in the app cache so user gets notification once logged in to extend whitelist duration if need be.
                        this.setNewIPLocalStorageFlag(true);

                        this.login();
                    } else {
                        this.warningMessage = "Entered code is invalid!";
                        this.showLoader = false;
                        this.setSecondaryPage(LoginPageView.newLocation);
                    }
                });
        } else {
            this.warningMessage = "Entered code does not match requirements!";
        }
    }

    resetMessages() {
        this.warningMessage = "";
        this.notificationMessage = "";
    }

    setSecondaryPage(setToPage: LoginPageView) {
        this.currentView = setToPage;
    }

    async redirectToApp() {
        this.loaderMessage = "Loading CERRIX";

        if (this.loginSuccessEvent.observers.length > 0) {
            this.loginSuccessEvent.emit();
        } else {
            let returnUrl = this.route.snapshot.queryParams["returnurl"];
            if (!returnUrl) {
                returnUrl = "/";
            }

            await this.getIncidentUrl();

            this.router.navigateByUrl(returnUrl);
        }
    }

    setNewIPLocalStorageFlag(setTo: boolean) {
        localStorage.setItem("USER_NEW_LOCATION_ENTRY", JSON.stringify(setTo));
    }

    private handleWarning(warning: string) {
        this.warningMessage = warning;
        this.showLoader = false;
    }
}
