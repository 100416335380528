@import "variables";

$cellmargin: 10px;

.main-dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .detail-page-action-bar {
        height: 70px !important;

        .seperator {
            display: inline;
            border-right: 1px solid darkgray;
            margin: 0 15px;
            padding: 5px 0;
        }

        .right-actions {
            float: right;
        }
    }

    .dashboard-name {
        font-weight: bold;
        font-size: 1.2em;
        display: inline-block;
        line-height: 2;

        span {
            margin-left: 10px;
        }
    }

    .right-actions-editmode {
        .btn {
            i + span {
                margin-left: 10px;
            }

            & + .btn {
                margin-left: 10px;
            }
        }
    }

    .button {
        padding: 6px 10px;
        font-size: 1.1em;
        border-radius: 0.2rem;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: 0.3s all linear;
        background-color: transparent;

        &.colored:not([disabled]) {
            color: var(--accent-color);
        }

        > cerrix-icon-viewer,
        > i {
            & + span {
                margin-left: 10px;
            }
        }

        span {
            font-weight: bold;
        }

        &.highlight {
            background: $primary-button-gradient;
            color: white;
        }

        &:not(.highlight):not([disabled]):hover {
            background: var(--background-accent-color);
        }

        & + .button {
            margin-left: 5px;
        }
    }

    .window-splitter {
        flex: 1;
        display: flex;
        flex-flow: row;
        overflow: hidden;
        height: 100%;

        gridster {
            flex: 1;
            background: initial;

            .gridster-column,
            .gridster-row {
                border-color: lightgray;
            }

            &.isediting {
                dashboard-widget {
                    pointer-events: none;
                }
            }

            gridster-item {
                border: 1px solid lightgray;

                &.bringtofront {
                    box-shadow: 0px 2px 7px 4px var(--accent-color);
                    z-index: 1000;

                    .edit-overlay {
                        background-color: rgba($color: black, $alpha: 0.1);

                        .edit-buttons {
                            display: none;
                        }
                    }
                }

                .edit-overlay {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    background-color: rgba($color: black, $alpha: 0.4);
                    pointer-events: none;
                    text-align: right;
                    padding: 10px;

                    .edit-buttons {
                        z-index: 101;
                        pointer-events: initial;

                        .button {
                            background-color: var(--background-accent-color);
                        }
                    }
                }
            }
        }

        .side-menu {
            width: 500px;
            display: flex;
            flex-flow: column;
            border-left: 1px solid lightgray;

            .side-menu-content {
                flex: 1;
                overflow: auto;
                height: 100%;
            }

            .side-menu-buttons {
                text-align: right;
                padding: 5px;
                border-top: 1px solid lightgray;
            }
        }
    }
}
