<div class="page-container">
    <div class="incident-status-steps">
        <cerrix-workflow-bar [steps]="workflowSteps"></cerrix-workflow-bar>
    </div>

    <div class="incident-edit-container">
        <div class="incident-details">
            <div class="inline-text">
                <span class="grey-text">Incidents | </span
                ><span class="blue-text">{{ incidentReference }}</span>
            </div>
            <br />
            @if (incidentForm) {
            <div [formGroup]="incidentForm.controls['headerDetails']" class="grid-container">
                <div>
                    <cerrix-title
                        size="large"
                        placeholder="Incident name"
                        formControlName="name"
                        [showAsRequired]="true"
                    ></cerrix-title>
                    <mat-divider class="title-divider"></mat-divider>
                </div>
                <div></div>

                <div *ngIf="!headerExpanded; else expandedHeader" class="header-details">
                    <div class="details-section">
                        <cerrix-field-wrapper
                            class="details-section__staffing"
                            label="Assigned"
                            [required]="true"
                            ><cerrix-avatar-group
                                [editable]="canEditStaffing"
                                [userLists]="assignedUsers"
                                [selectableUsers]="users"
                                [rolePermissionSelection]="true"
                                [selectedPermissionIndex]="selectedPermissionIndex"
                                (userListsChange)="onAssignedUsersChange($event)"
                            ></cerrix-avatar-group
                        ></cerrix-field-wrapper>
                        <cerrix-field-wrapper
                            class="details-section__status"
                            label="Status"
                            [textContent]="incident?.incidentStatus.translation"
                        >
                        </cerrix-field-wrapper>
                        <div class="details-section__dates">
                            <cerrix-field-wrapper
                                class="details-section__date"
                                label="Due date"
                                [textContent]="
                                    incidentForm.get('headerDetails').get('dueDate').value
                                        | dateTimeDisplay
                                "
                            >
                            </cerrix-field-wrapper>
                            <cerrix-field-wrapper
                                class="details-section__date"
                                label="Date detected"
                                [required]="true"
                                [textContent]="
                                    incidentForm.get('headerDetails').get('detectedOn').value
                                        | dateTimeDisplay
                                "
                            >
                            </cerrix-field-wrapper>
                            <cerrix-field-wrapper
                                class="details-section__date"
                                label="Date occurred"
                                [textContent]="
                                    incidentForm.get('headerDetails').get('occurredOn').value
                                        | dateTimeDisplay
                                "
                            >
                            </cerrix-field-wrapper>
                        </div>
                    </div>
                </div>
                <ng-template #expandedHeader>
                    <div class="header-details">
                        <div class="details-section">
                            <cerrix-field-wrapper
                                class="details-section__staffing"
                                label="Assigned"
                                [required]="true"
                                ><cerrix-avatar-group
                                    [editable]="canEditStaffing"
                                    [userLists]="assignedUsers"
                                    [selectableUsers]="users"
                                    [rolePermissionSelection]="true"
                                    [selectedPermissionIndex]="selectedPermissionIndex"
                                    (userListsChange)="assignedUsers = $event"
                                ></cerrix-avatar-group
                            ></cerrix-field-wrapper>
                            <cerrix-field-wrapper
                                class="details-section__status"
                                label="Status"
                                [textContent]="incident?.incidentStatus.translation"
                            ></cerrix-field-wrapper>
                            <div class="details-section__dates">
                                <cerrix-field-wrapper
                                    class="details-section__date"
                                    label="Due date"
                                >
                                    <cerrix-datepicker
                                        formControlName="dueDate"
                                        [minDate]="minDueDate"
                                    ></cerrix-datepicker>
                                </cerrix-field-wrapper>
                                <cerrix-field-wrapper
                                    class="details-section__date"
                                    label="Date detected"
                                    [required]="true"
                                >
                                    <cerrix-datepicker
                                        formControlName="detectedOn"
                                        [maxDate]="futureDateLimit"
                                        (ngModelChange)="onChange('detectedOn')"
                                    ></cerrix-datepicker>
                                </cerrix-field-wrapper>
                                <cerrix-field-wrapper
                                    class="details-section__date"
                                    label="Date occurred"
                                >
                                    <cerrix-datepicker
                                        formControlName="occurredOn"
                                        [maxDate]="futureDateLimit"
                                        (ngModelChange)="onChange('occurredOn')"
                                    ></cerrix-datepicker>
                                </cerrix-field-wrapper>
                            </div>
                        </div>
                        <div class="description-section">
                            <cerrix-field-wrapper [required]="true" label="Description">
                                <cerrix-textarea
                                    placeholder="Description"
                                    formControlName="description"
                                    [textareaAutosize]="true"
                                    [autosizeMinRows]="7"
                                ></cerrix-textarea>
                            </cerrix-field-wrapper>
                        </div>
                    </div>
                </ng-template>
                <div>
                    <button
                        mat-flat-button
                        class="section-toggle-button"
                        (click)="toggleHeaderSection()"
                    >
                        <mat-icon *ngIf="headerExpanded; else collapsedHeaderBtn"
                            >keyboard_arrow_up</mat-icon
                        >
                        <ng-template #collapsedHeaderBtn
                            ><mat-icon>keyboard_arrow_down</mat-icon></ng-template
                        >
                    </button>
                </div>
            </div>
            }

            <div class="tab-container">
                <mat-tab-group [mat-stretch-tabs]="false" [dynamicHeight]="true">
                    <mat-tab
                        [labelClass]="
                            formHasErrors(incidentForm?.get('details')) ? 'tab-header-error' : ''
                        "
                    >
                        <ng-template mat-tab-label>
                            <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
                            <span class="mat-tab-label">Details</span></ng-template
                        >
                        <incident-details-tabcontent
                            *ngIf="dataLoaded"
                            [incidentTypes]="incidentTypes"
                            (onIncidentTypeChange)="onIncidentTypeChange($event)"
                            [detailsForm]="incidentForm?.controls['details']"
                            [incidentId]="this.incident?.id"
                            [reportedOrganizations]="reportedOrganizations"
                            [causedByOrganizations]="causedByOrganizations"
                            [affectedOrganizations]="affectedOrganizations"
                            [rootCauseCategories]="rootCauseCategories"
                            [classifications]="classifications"
                            [businessDimensions]="businessDimensions"
                            [frameworkDimensions]="frameworkDimensions"
                            [causedByThirdparties]="causedByThirdparties"
                            [affectedThirdparties]="affectedThirdparties"
                        ></incident-details-tabcontent>
                    </mat-tab>

                    <mat-tab
                        #incidentTypesTab
                        *ngFor="let it of selectedIncidentTypes"
                        [labelClass]="
                            formHasErrors(incidentForm?.get('incidentCustomFields')?.get(it.id))
                                ? 'tab-header-error'
                                : ''
                        "
                    >
                        <ng-template mat-tab-label>
                            <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
                            <span class="mat-tab-label">{{ it.name }}</span></ng-template
                        >

                        <incident-custom-fields-tab
                            [isActive]="incidentTypesTab.isActive"
                            [customFieldsForm]="
                                incidentForm?.get('incidentCustomFields')?.get(it.id)
                            "
                            [incidentType]="it"
                            [incidentId]="this.incident?.id"
                            [currentSelectedOrganizations]="getCurrentlySelectedOrganizations()"
                            [currentSelectedThirdParties]="getCurrentlySelectedThirdParties()"
                            [currencies]="currencies"
                            [refresh]="incidentUpdated"
                        ></incident-custom-fields-tab>
                    </mat-tab>

                    <mat-tab *ngIf="this.incident?.id" labelClass="right-tab-item second-right-tab">
                        <ng-template mat-tab-label>
                            <mat-icon fontSet="material-icons-outlined">open_in_new</mat-icon>
                        </ng-template>

                        <incident-links-tab
                            *ngIf="!!incident"
                            [canEditIncident]="canEditIncident && incident?.id"
                            [incidentId]="id"
                            [riskIds]="incident.riskIds"
                            (riskIdsChange)="onRiskIdsChange($event)"
                            [controlIds]="incident.controlIds"
                            (controlIdsChange)="onControlIdsChange($event)"
                        ></incident-links-tab>
                    </mat-tab>

                    <mat-tab *ngIf="this.incident?.id" labelClass="right-tab-item first-right-tab">
                        <ng-template mat-tab-label>
                            <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                        </ng-template>

                        <incident-documents-tab
                            [canEditIncident]="
                                (canEditIncident && incident?.id) ||
                                (incident?.id &&
                                    incident.incidentStatus.id !== IncidentStatus.Closed &&
                                    incident.incidentStatus.id !== IncidentStatus.Rejected)
                            "
                            [incidentId]="id"
                            [documents]="documents"
                            (documentsChange)="onDocumentsChange($event)"
                        ></incident-documents-tab>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="action-container">
                <div class="left-container">
                    @if (showSettingsMenu) {
                    <cerrix-drop-menu icon="settings" iconColor="#001257" dropPosition="top">
                        @if (settingsPermissions.deletePermission) {
                        <div (click)="deleteIncident()">
                            <cerrix-icon icon="delete" color="#0C66E4" [size]="24"></cerrix-icon
                            >Delete
                        </div>
                        }</cerrix-drop-menu
                    >
                    } @if(incident?.incidentStatus?.id !== IncidentStatus.Rejected &&
                    incident?.incidentStatus?.id !== IncidentStatus.Closed) {
                    <cerrix-button
                        [disabled]="saveDisabled || !canEditIncident || cfRequestsInProgress > 0"
                        color="primary-light"
                        type="stroked"
                        (click)="onSave()"
                        ><ng-container>Save</ng-container
                        ><mat-icon fontSet="material-symbols-outlined"
                            >check_small</mat-icon
                        ></cerrix-button
                    >
                    }
                </div>
                <div class="right-container">
                    @if (canEditIncident) { @switch (incident?.incidentStatus?.id) { @case
                    (IncidentStatus.AwaitingAcceptance) {
                    <cerrix-button
                        [disabled]="saveDisabled || cfRequestsInProgress > 0"
                        color="error"
                        (click)="
                            onIncidentStatusChange(
                                IncidentStatus.AwaitingAcceptance,
                                IncidentStatus.Rejected
                            )
                        "
                        >Reject incident</cerrix-button
                    >
                    <cerrix-button
                        [disabled]="saveDisabled || cfRequestsInProgress > 0"
                        color="success"
                        (click)="
                            onIncidentStatusChange(
                                IncidentStatus.AwaitingAcceptance,
                                IncidentStatus.AwaitingImprovements
                            )
                        "
                        >Accept incident</cerrix-button
                    >
                    } @case (IncidentStatus.AwaitingImprovements) {
                    <cerrix-button
                        [disabled]="saveDisabled || cfRequestsInProgress > 0"
                        color="primary"
                        (click)="
                            onIncidentStatusChange(
                                IncidentStatus.AwaitingImprovements,
                                IncidentStatus.ReadyForApproval
                            )
                        "
                        >Ready for review</cerrix-button
                    >
                    } @case (IncidentStatus.ReadyForApproval){
                    <cerrix-button
                        [disabled]="saveDisabled || cfRequestsInProgress > 0"
                        color="error"
                        (click)="
                            onIncidentStatusChange(
                                IncidentStatus.ReadyForApproval,
                                IncidentStatus.AwaitingImprovements
                            )
                        "
                        >Reject</cerrix-button
                    >
                    <cerrix-button
                        [disabled]="saveDisabled || cfRequestsInProgress > 0"
                        color="success"
                        (click)="
                            onIncidentStatusChange(
                                IncidentStatus.ReadyForApproval,
                                IncidentStatus.Closed
                            )
                        "
                        >Approve & close</cerrix-button
                    >
                    } } } @if (canReopenIncident) {
                    <cerrix-button
                        [disabled]="saveDisabled || cfRequestsInProgress > 0"
                        color="primary-light"
                        type="stroked"
                        (click)="onReopen()"
                        ><ng-container>Reopen</ng-container></cerrix-button
                    >
                    }
                </div>
            </div>
        </div>

        <div class="right-space-container"></div>
    </div>

    <chat-toggle-button
        *ngIf="incident?.id"
        class="chat-toggle-button"
        [label]="chatText"
        (toggleChat)="onToggleComments()"
    ></chat-toggle-button>
</div>

<cerrix-chat
    #chatComponent
    *ngIf="incident?.id"
    [canAdd]="true"
    [entityId]="incident.id"
    [comments]="comments"
    [title]="chatText + ' - ' + incident.name"
    [class]="['incident-comments', displayComments ? 'display-comments' : '']"
    (hideChat)="onToggleComments()"
></cerrix-chat>
