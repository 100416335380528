import { Injectable, EventEmitter } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable, Subject } from "rxjs";
import { AuthenticationConfiguration } from "./authentication-configuration";
import { KeyValue } from "@models/generic/KeyValuePair";
import { UserLoginResult } from "./user-login-result.enum";

@Injectable()
export class UserAuthenticationService extends ApiDataService {
    handleSessionExpiredEvent = new EventEmitter<Subject<never>>();
    sessionInitiateObservable$: Subject<never> = null;

    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "user/authentication");
    }

    initiateNewSession() {
        if (this.sessionInitiateObservable$ === null) {
            this.sessionInitiateObservable$ = new Subject<never>();
            this.handleSessionExpiredEvent.emit(this.sessionInitiateObservable$);
        }

        return new Observable((observer) => {
            this.sessionInitiateObservable$.asObservable().subscribe(() => {
                observer.next();
                observer.complete();

                this.sessionInitiateObservable$ = null;
            });
        });
    }

    isAuthenticated(): Observable<boolean> {
        const url = "/isauthenticated";
        return this.get<boolean>(url);
    }

    getConfiguration() {
        const url = "/config";
        return this.get<AuthenticationConfiguration>(url);
    }

    formLogin(username: string, password: string) {
        const postModel = {
            username,
            password,
        };

        const url = `/login`;
        return this.post<UserLoginResult>(postModel, url);
    }

    loginJwtExternal() {
        const url = `/login/external`;
        return this.post<UserLoginResult>(null, url);
    }

    logout() {
        const url = "/logout";
        return this.post(null, url);
    }

    passwordForgotRequest(username: string) {
        const postModel = {
            username,
        };

        const url = "/forgotpassword";
        return this.post(postModel, url);
    }

    checkPasswordResetSecret(secret: string) {
        const url = `/passwordreset/${secret}/check`;
        return this.get(url);
    }

    passwordReset(secret: string, newPassword: string, newPasswordConfirm: string) {
        const postModel = {
            secret,
            newPassword,
            newPasswordConfirm,
        };

        const url = `/passwordreset`;
        return this.post<KeyValue<boolean, string>>(postModel, url);
    }

    forcedPasswordReset(
        username: string,
        oldPassword: string,
        newPassword: string,
        newPasswordConfirm: string
    ) {
        const postModel = {
            username,
            oldPassword,
            newPassword,
            newPasswordConfirm,
        };

        const url = `/forcedpasswordreset`;
        return this.post<KeyValue<boolean, string>>(postModel, url);
    }

    verifyLocation(username: string, code: string) {
        const url = `/verifylocation/${username}/${code}`;
        return this.post<boolean>(null, url);
    }
}
