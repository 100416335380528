import { MoiSourceDataModel } from "./../../shared/moi-source-data-model";
import { MoiDataService } from "./../../services/moi-data.service";
import { Component, OnInit } from "@angular/core";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { FormatType } from "@enums/FormatType";
import customTableSort from "@methods/customTableSort";
import { MatDialogRef } from "@angular/material/dialog";
import { MoiConfig } from "@models/moi/MoiConfig";
import { ToastrService } from "ngx-toastr";
import { DateFormatPipe } from "@app/shared";
import { KeyValue } from "@models/generic/KeyValuePair";
import { MoiCreatorPermissions } from "@app/moi/shared/moi-creator-permissions.enum";
import { nameof } from "@methods/CommonMethods";
import { RiskWorkspaceModel } from "@app/risk/models/RiskWorkspaceModel";
import { IncidentService } from "@app/incident/services/incident.service";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { IncidentMoiOverviewModel } from "@app/incident/models/incident-moi-overview-model";

@Component({
    selector: "moi-source-selector",
    templateUrl: "./moi-source-selector.component.html",
    styleUrls: ["./moi-source-selector.component.scss"],
})
export class MoiSourceSelectorComponent implements OnInit {
    permissions: MoiCreatorPermissions;
    data: MoiSourceDataModel = {};
    types = MoiTypes;

    // Source selection area.
    sourceType: MoiTypes = null;
    columnInfo: ColumnInfo[];
    sourceGuidColumn = "Guid";

    // Pagestuff
    allData: any[];
    filteredData: any[];
    sortedData: any[];
    currentData: any[];
    moiSources: any[] = [];

    selectedSource: MoiTypes;
    selectedGuid: string;

    textFilter: string;
    sortColumn: string;
    sortAsc = false;
    currentPage = 0;

    pageCount = 0;
    paginationAmount = 10;

    useIncidents = false;

    isLoaded = false;

    constructor(
        public dialogRef: MatDialogRef<MoiSourceSelectorComponent>,
        private _moiDS: MoiDataService,
        private toastr: ToastrService,
        private _dateFormatPipe: DateFormatPipe,
        private incidentDS: IncidentService,
        private _settingsService: SettingsDataService
    ) {}

    async ngOnInit() {
        this.permissions = await this._moiDS.getCreatorPermissions().toPromise();

        this.useIncidents = (
            await this._settingsService.getSetting(ApplicationSettings.UseIncidents).toPromise()
        ).BoolValue;

        this.setupMoiSources();
    }

    async close(isCancel?: boolean) {
        if (isCancel) {
            this.dialogRef.close();
        } else {
            if (!this.sourceType || !this.selectedGuid) {
                this.toastr.warning(
                    "Select a source from the table to continue.",
                    "No source selected."
                );
                return;
            }

            let sourceID = null;
            if (this.sourceType != MoiTypes.Incident_Moi) {
                sourceID = await this._moiDS
                    .getSourceIdByGuid(this.sourceType, this.selectedGuid)
                    .toPromise();

                if (!sourceID || sourceID <= 0) {
                    this.toastr.error("Source not found!");
                    return;
                }
            }

            const config = <MoiConfig>{
                MoiType: this.sourceType,
                ParentId: sourceID,
                ParentGuid: this.selectedGuid,
            };
            this.dialogRef.close(config);
        }
    }

    setSourceData() {
        if (this.selectedSource === this.sourceType) {
            return;
        }

        this.resetPage(this.selectedSource);

        const defaultWidth = 200;

        switch (this.selectedSource) {
            case MoiTypes.IM_Moi:
                if (
                    MoiCreatorPermissions.BimCreator ===
                    (this.permissions & MoiCreatorPermissions.BimCreator)
                ) {
                    if (!this.data.BimData) {
                        this.data.BimData = this._moiDS
                            .getLinkableSources(MoiTypes.IM_Moi)
                            .toPromise();
                    }
                    this.data.BimData.then((x) => {
                        this.allData = x;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: "Identifier",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: "Report Type",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Report Type",
                        },
                        {
                            renderer: {
                                textColumn: "Report Name",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Report Name",
                        },
                        {
                            renderer: {
                                textColumn: "Report Date",
                                type: RendererType.Default,
                                formatType: FormatType.DateFormat,
                                columnWidth: defaultWidth,
                            },
                            header: "Report Date",
                        },
                        {
                            renderer: {
                                textColumn: "Overall Score",
                                actionColumn: "OverallScoreColor",
                                type: RendererType.Score,
                                columnWidth: defaultWidth,
                            },
                            header: "Overall Score",
                        },
                    ];
                }
                break;
            case MoiTypes.Control_Moi:
                if (
                    MoiCreatorPermissions.ControlMoiCreator ===
                    (this.permissions & MoiCreatorPermissions.ControlMoiCreator)
                ) {
                    if (!this.data.ControlMoiData) {
                        this.data.ControlMoiData = this._moiDS
                            .getLinkableSources(MoiTypes.Control_Moi)
                            .toPromise();
                    }
                    this.data.ControlMoiData.then((x) => {
                        this.allData = x;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: "identifier",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: "name",
                                type: RendererType.Default,
                                columnWidth: 400,
                            },
                            header: "Name",
                        },
                        {
                            renderer: {
                                textColumn: "effectivenessScore",
                                actionColumn: "effectivenessScoreColor",
                                type: RendererType.Score,
                                columnWidth: defaultWidth,
                            },
                            header: "Effectiveness score",
                        },
                        {
                            renderer: {
                                textColumn: "finalConclusionScore",
                                actionColumn: "finalConclusionScoreColor",
                                type: RendererType.Score,
                                columnWidth: defaultWidth,
                            },
                            header: "Final conclusion score",
                        },
                    ];
                }
                break;
            case MoiTypes.LER_Moi:
                if (
                    MoiCreatorPermissions.EventMoiCreator ===
                    (this.permissions & MoiCreatorPermissions.EventMoiCreator)
                ) {
                    if (!this.data.EventMoiData) {
                        this.data.EventMoiData = this._moiDS
                            .getLinkableSources(MoiTypes.LER_Moi)
                            .toPromise();
                    }
                    this.data.EventMoiData.then((x) => {
                        this.allData = x;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: "Identifier",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: "Event name",
                                type: RendererType.Default,
                                columnWidth: 400,
                            },
                            header: "Event name",
                        },
                        {
                            renderer: {
                                textColumn: "Status",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Status",
                        },
                        {
                            renderer: {
                                textColumn: "Date occurred",
                                type: RendererType.Default,
                                formatType: FormatType.DateFormat,
                                columnWidth: defaultWidth,
                            },
                            header: "Date occurred",
                        },
                    ];
                }
                break;
            case MoiTypes.RISK_Moi:
                if (
                    MoiCreatorPermissions.RiskMoiCreator ===
                    (this.permissions & MoiCreatorPermissions.RiskMoiCreator)
                ) {
                    if (!this.data.RiskMoiData) {
                        this.data.RiskMoiData = this._moiDS
                            .getLinkableSources(MoiTypes.RISK_Moi)
                            .toPromise();
                    }
                    this.data.RiskMoiData.then((x) => {
                        this.allData = x;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: nameof<RiskWorkspaceModel>("identifier"),
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: nameof<RiskWorkspaceModel>("riskName"),
                                type: RendererType.Default,
                                columnWidth: 300,
                            },
                            header: "Risk name",
                        },
                        {
                            renderer: {
                                textColumn: nameof<RiskWorkspaceModel>("dateCreated"),
                                type: RendererType.Default,
                                formatType: FormatType.DateFormat,
                                columnWidth: defaultWidth,
                            },
                            header: "Date created",
                        },
                        {
                            renderer: {
                                textColumn: nameof<RiskWorkspaceModel>("overallRiskAssessment"),
                                actionColumn: nameof<RiskWorkspaceModel>(
                                    "overallRiskAssessmentColor"
                                ),
                                type: RendererType.Score,
                                columnWidth: 300,
                            },
                            header: "Overall risk assessment",
                        },
                    ];
                }
                break;
            case MoiTypes.TP_Moi:
                if (
                    MoiCreatorPermissions.TpMoiCreator ===
                    (this.permissions & MoiCreatorPermissions.TpMoiCreator)
                ) {
                    if (!this.data.ThirdpartyMoiData) {
                        this.data.ThirdpartyMoiData = this._moiDS
                            .getLinkableSources(MoiTypes.TP_Moi)
                            .toPromise();
                    }
                    this.data.ThirdpartyMoiData.then((x) => {
                        this.allData = x;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: "Identifier",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: "Name",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Name",
                        },
                        {
                            renderer: {
                                textColumn: "Review score",
                                actionColumn: "Latest_Score_Color",
                                type: RendererType.Score,
                                columnWidth: defaultWidth,
                            },
                            header: "Review score",
                        },
                        {
                            renderer: {
                                textColumn: "Criticality",
                                actionColumn: "CriticalityColor",
                                type: RendererType.Score,
                                columnWidth: defaultWidth,
                            },
                            header: "Criticality",
                        },
                    ];
                }
                break;
            case MoiTypes.DM_Moi:
                if (
                    MoiCreatorPermissions.DmMoiCreator ===
                    (this.permissions & MoiCreatorPermissions.DmMoiCreator)
                ) {
                    if (!this.data.DmMoiData) {
                        this.data.DmMoiData = this._moiDS
                            .getLinkableSources(MoiTypes.DM_Moi)
                            .toPromise();
                    }
                    this.data.DmMoiData.then((x) => {
                        this.allData = x;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: "Identifier",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: "Name",
                                type: RendererType.Default,
                                columnWidth: 300,
                            },
                            header: "Name",
                        },
                        {
                            renderer: {
                                textColumn: "Data subjects",
                                type: RendererType.Default,
                                columnWidth: 300,
                            },
                            header: "Data subjects",
                        },
                        {
                            renderer: {
                                textColumn: "Most recent DPIA",
                                type: RendererType.Default,
                                formatType: FormatType.DateFormat,
                                columnWidth: defaultWidth,
                            },
                            header: "Most recent DPIA",
                        },
                    ];
                }
                break;

            case MoiTypes.Incident_Moi:
                if (
                    MoiCreatorPermissions.EventMoiCreator ===
                    (this.permissions & MoiCreatorPermissions.EventMoiCreator)
                ) {
                    if (!this.data.IncidentMoiData) {
                        this.data.IncidentMoiData = this.incidentDS
                            .getIncidentsForMoi()
                            .toPromise();
                    }
                    this.data.IncidentMoiData.then((x) => {
                        const incidents = x;
                        incidents.forEach((i: IncidentMoiOverviewModel) => {
                            if (i.date_Occurred) {
                                i.date_Occurred = new Date(i.date_Occurred);
                            } else {
                                i.date_Occurred = null;
                            }
                        });
                        this.allData = incidents;
                        this.filterData();
                    });
                    this.columnInfo = [
                        {
                            renderer: {
                                textColumn: "identifier",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Identifier",
                        },
                        {
                            renderer: {
                                textColumn: "name",
                                type: RendererType.Default,
                                columnWidth: 400,
                            },
                            header: "Incident name",
                        },
                        {
                            renderer: {
                                textColumn: "status",
                                type: RendererType.Default,
                                columnWidth: defaultWidth,
                            },
                            header: "Status",
                        },
                        {
                            renderer: {
                                textColumn: "date_Occurred",
                                type: RendererType.Default,
                                formatType: FormatType.DateTimeFormat,
                                columnWidth: defaultWidth,
                            },
                            header: "Date occurred",
                        },
                    ];
                }
                break;
        }

        this.sourceType = this.selectedSource;
    }

    resetPage(sourceType: MoiTypes) {
        this.isLoaded = false;
        this.columnInfo = null;

        if (sourceType == MoiTypes.Control_Moi || sourceType == MoiTypes.RISK_Moi) {
            this.sourceGuidColumn = "guid";
        } else if (sourceType == MoiTypes.Incident_Moi) {
            this.sourceGuidColumn = "id";
        } else {
            this.sourceGuidColumn = "Guid";
        }

        this.allData = null;
        this.filteredData = null;
        this.sortedData = null;
        this.currentData = null;
        this.selectedGuid = null;
        this.textFilter = null;
        this.sortColumn = null;
        this.sortAsc = false;
        this.currentPage = 0;
        this.pageCount = 0;
    }

    setSort(header: string) {
        if (header !== this.sortColumn) {
            this.sortColumn = header;
            if (this.sortColumn.toLowerCase() != "identifier") {
                this.sortAsc = true;
            } else {
                this.sortAsc = false;
            }
        } else {
            this.sortAsc = !this.sortAsc;
        }

        this.sortData();
    }

    setPagination(page: number) {
        if (page >= 0 && page <= this.pageCount - 1) {
            this.currentPage = page;
            this.paginateData();
        }
    }

    filterData() {
        if (!this.allData || this.allData.length === 0) {
            this.isLoaded = true;
            return;
        }

        if (this.textFilter) {
            const keywords = this.textFilter.toLowerCase().split(" ");

            this.filteredData = [];
            for (const row of this.allData) {
                let compareString = "";

                for (const column of this.columnInfo) {
                    if (column.renderer.formatType === FormatType.DateFormat) {
                        compareString +=
                            " " + this._dateFormatPipe.transform(row[column.renderer.textColumn]);
                    } else {
                        compareString += (" " + row[column.renderer.textColumn]).toLowerCase();
                    }
                }

                if (!keywords.some((x) => compareString.indexOf(x) < 0)) {
                    this.filteredData.push(row);
                }
            }
        } else {
            this.filteredData = this.allData;
        }

        this.currentPage = 0;
        this.pageCount = Math.ceil(this.filteredData.length / this.paginationAmount);
        if (!this.sortColumn) {
            this.sortColumn = this.columnInfo[0].renderer.textColumn;
        }

        this.sortData();
        this.isLoaded = true;
    }

    sortData() {
        const rendererConfig: RendererConfig = this.columnInfo.find(
            (rc) => rc.renderer.textColumn === this.sortColumn
        ).renderer;

        const isNumeric =
            rendererConfig &&
            rendererConfig.formatType &&
            (rendererConfig.formatType === FormatType.DecimalFormat ||
                rendererConfig.formatType === FormatType.CurrencyFormat);
        const isDate =
            rendererConfig &&
            rendererConfig.formatType &&
            (rendererConfig.formatType === FormatType.DateFormat ||
                rendererConfig.formatType === FormatType.DateShortTimeFormat ||
                rendererConfig.formatType === FormatType.DateTimeFormat);
        const isString = !isNumeric && !isDate;

        const compareMethod = customTableSort(
            this.sortAsc,
            this.sortColumn,
            isString,
            isNumeric,
            isDate
        );
        this.sortedData = this.filteredData.sort(compareMethod);

        this.paginateData();
    }

    paginateData() {
        const startIndex = this.currentPage * this.paginationAmount;
        const endIndex = +startIndex + +this.paginationAmount;
        this.currentData = this.sortedData.slice(startIndex, endIndex);
    }

    setupMoiSources() {
        if (
            MoiCreatorPermissions.BimCreator ===
            (this.permissions & MoiCreatorPermissions.BimCreator)
        ) {
            this.moiSources.push(
                new KeyValue<MoiTypes, string>(MoiTypes.IM_Moi, MoiTypes.IM_MoiDescription)
            );
        }

        if (
            MoiCreatorPermissions.ControlMoiCreator ===
            (this.permissions & MoiCreatorPermissions.ControlMoiCreator)
        ) {
            this.moiSources.push(
                new KeyValue<MoiTypes, string>(
                    MoiTypes.Control_Moi,
                    MoiTypes.Control_MoiDescription
                )
            );
        }

        if (
            MoiCreatorPermissions.EventMoiCreator ===
            (this.permissions & MoiCreatorPermissions.EventMoiCreator)
        ) {
            this.moiSources.push(
                new KeyValue<MoiTypes, string>(MoiTypes.LER_Moi, MoiTypes.LER_MoiDescription)
            );
            if (this.useIncidents) {
                this.moiSources.push(
                    new KeyValue<MoiTypes, string>(
                        MoiTypes.Incident_Moi,
                        MoiTypes.Incident_MoiDescription
                    )
                );
            }
        }

        if (
            MoiCreatorPermissions.RiskMoiCreator ===
            (this.permissions & MoiCreatorPermissions.RiskMoiCreator)
        ) {
            this.moiSources.push(
                new KeyValue<MoiTypes, string>(MoiTypes.RISK_Moi, MoiTypes.RISK_MoiDescription)
            );
        }

        if (
            MoiCreatorPermissions.DmMoiCreator ===
            (this.permissions & MoiCreatorPermissions.DmMoiCreator)
        ) {
            this.moiSources.push(
                new KeyValue<MoiTypes, string>(MoiTypes.DM_Moi, MoiTypes.DM_MoiDescription)
            );
        }

        if (
            MoiCreatorPermissions.TpMoiCreator ===
            (this.permissions & MoiCreatorPermissions.TpMoiCreator)
        ) {
            this.moiSources.push(
                new KeyValue<MoiTypes, string>(MoiTypes.TP_Moi, MoiTypes.TP_MoiDescription)
            );
        }

        this.selectedSource = this.moiSources[0].Key;
        this.setSourceData();
    }
}

export class ColumnInfo {
    renderer: RendererConfig;
    header: string;
}
