import { Component } from "@angular/core";
import { AvailableManualModel } from "@app/manual/models/available-manual-model";
import { ManualDataService } from "@app/manual/services/manual-data.service";

@Component({
    selector: "pdf-manual",
    templateUrl: "./pdf-manual.component.html",
    styleUrls: ["./pdf-manual.component.scss"],
})
export class PdfManualComponent {
    loaded = false;
    availableManuals: AvailableManualModel[];

    constructor(ds: ManualDataService) {
        ds.getManuals().subscribe((x) => {
            this.availableManuals = x;
            this.loaded = true;
        });
    }
}
