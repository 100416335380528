<div>
    <mat-checkbox
        color="primary"
        id="structuremultiselect-{{ field.ID }}"
        [(ngModel)]="structureSettings.multiSelect"
        (change)="saveModel()"
        [disabled]="multiselectLocked || field.ID > 0"
    >
        Allow Multiselect
        <i
            class="fas fa-info-circle ms-2"
            *ngIf="multiselectLocked"
            matTooltip="This option is controlled by Mapback Field."
        ></i>
    </mat-checkbox>
</div>
<div class="row">
    <div class="form-group col">
        <label for="structuresources-{{ field.ID }}">
            Structure / Source
            <i
                class="fas fa-info-circle ms-2"
                [matTooltip]="
                    sourceLocked
                        ? 'This option is controlled by Mapback Field.'
                        : 'Data source for structure field. This option will not be editable once saved!'
                "
            ></i>
        </label>
        <ng-select
            id="structuresources-{{ field.ID }}"
            name="structuresources-{{ field.ID }}"
            [multiple]="false"
            [items]="sources"
            [(ngModel)]="structureSettings.structureType"
            bindLabel="Value"
            bindValue="Key"
            [disabled]="sourceLocked || field.ID > 0"
            (change)="applyChange()"
        >
        </ng-select>
    </div>
</div>
<div class="row">
    <div class="form-group col">
        <label for="sourcedata-{{ field.ID }}">
            Allowed Structure / Source Data
            <i
                class="fas fa-info-circle ms-2"
                [matTooltip]="
                    parametersLocked
                        ? 'This option is controlled by Mapback Field.'
                        : 'Leave empty to allow all options or select items to limit user to those options. Caution! No other right checks will be performed!'
                "
            ></i>
        </label>
        <ng-select
            id="sourcedata-{{ field.ID }}"
            name="sourcedata-{{ field.ID }}"
            [multiple]="true"
            [closeOnSelect]="false"
            [items]="field.SourceData"
            [(ngModel)]="structureSettings.parameters"
            bindLabel="Name"
            bindValue="ID"
            (change)="saveModel()"
            *ngIf="!isTreeSource"
            [disabled]="parametersLocked"
        >
        </ng-select>
        <cerrix-select-tree
            [multiselect]="true"
            [tree]="field.SourceData"
            [(value)]="structureSettings.parameters"
            (onChange)="saveModel()"
            *ngIf="isTreeSource"
            [readonly]="parametersLocked"
        >
        </cerrix-select-tree>
    </div>
</div>
