import { Component } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "job-scheduler-status",
    templateUrl: "./job-scheduler-status.component.html",
    styleUrls: ["./job-scheduler-status.component.scss"],
})
export class JobSchedulerStatusComponent {
    tab: TabModel;
}
