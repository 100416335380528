import { Component, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { getAssessmentScores } from "@app/controls/testing/advanced/shared/adv-eff-shared-methods";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { Observable, defer, firstValueFrom, map } from "rxjs";
import {
    EffectivenessModel,
    EvidenceSampleEditModel,
    WorkflowConclusionModel,
    WorkflowStepPermissionModel,
} from "../../shared/models";
import { AdvEffDataService, AdvEffWorkflowDataService } from "../../shared/services";
import { ToastrService } from "ngx-toastr";
import { toPromise } from "@methods/CommonMethods";
import { SaveButtonService } from "../common/eff-save-button/eff-save-button.service";
import { MatDialog } from "@angular/material/dialog";
import { DocumentManagerService } from "../common/services/document-manager.service";
import { SampleView } from "./models/sample-view.model";
import { EvidenceSampleTestStep } from "./models/evidence-sample-step.model";
import { MatTableDataSource } from "@angular/material/table";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { EffFinalConclusionPopupComponent } from "./components/popups/final-conclusion-popup/eff-final-conclusion-popup.component";
import { FinalConclusion } from "./components/eff-final-conclusion-box/models/final-conclusion.model";
import { SelectColoredOption } from "../common/eff-colored-selector/models/SelectColoredOption";
import { EffDialogService } from "../common/popups/eff-dialog-service.service";
import { EffWorkFlowState } from "../common/services/eff-workflow.service";
import { SampleScore, TestStepScore } from "../../shared/enums";
import { DocumentModel } from "@models/documents/documentmodel";
import { CerrixSlideOverComponent } from "@app/shared/cerrix-slide-over/cerrix-slide-over.component";

@Component({ template: "" })
export abstract class AbstractEffTestingStatusComponent implements OnInit {
    @Input() cerrixTab: TabModel;
    @Input() effectivenessModel: EffectivenessModel;
    @Input() stepPermissions: WorkflowStepPermissionModel;
    @Input() pageState: EffWorkFlowState;
    @ViewChild("cerrixSlideOver") cerrixSlideOver: CerrixSlideOverComponent;

    protected loadSamples = false;
    samples: EvidenceSampleEditModel[];

    viewSamples: SampleView[] = [];

    constructor(
        protected _ds: AdvEffDataService,
        protected _workflowDs: AdvEffWorkflowDataService,
        protected _controlDs: ControlDataService,
        protected _promptService: CerrixPromptService,
        protected _injector: Injector,
        protected _toastr: ToastrService,
        protected _saveButtonService: SaveButtonService,
        protected _userDS: UserService,
        protected _dialog: MatDialog,
        protected _documentManagerService: DocumentManagerService,
        protected _dialogService: EffDialogService
    ) {}

    async ngOnInit() {
        if (this.loadSamples) {
            this.samples = await toPromise(
                this._ds.getEvidenceSamples(this.effectivenessModel.guid)
            );
        }

        await this._documentManagerService.loadData();

        this.buildGridDataSource();

        if (this.onLoad) {
            this.onLoad();
        }
    }

    protected onLoad() {
        /** This method is basically abstract but is not required, so we cannot actually define it as abstract. */
    }

    toggleInstructions() {
        this.cerrixSlideOver.show();
    }

    public buildGridDataSource() {
        if (!this.samples) {
            return;
        }

        this.samples.forEach(
            function (sample) {
                var dataSource: EvidenceSampleTestStep[] = [];
                dataSource = sample.testSteps.map((step) => new EvidenceSampleTestStep(step));
                dataSource.push(new EvidenceSampleTestStep(sample));

                this.viewSamples.push({
                    sample: sample,
                    gridDataSource: new MatTableDataSource<EvidenceSampleTestStep>(dataSource),
                });
            }.bind(this)
        );
    }

    protected updateModels() {
        this.viewSamples.forEach((sampleView) => {
            sampleView.gridDataSource.data.forEach((item) => {
                item.updateModel();
            });
        });
    }

    protected async saveSampleChanges(): Promise<void> {
        const promptMessage = "Saving samples and test steps";
        const prompt = this._promptService.loader(promptMessage);
        this.updateModels();

        try {
            let latestStatus = this.effectivenessModel.status;
            const testStepCalls = this.samples
                .map((sample) => {
                    return sample.testSteps.map((testStep) => {
                        return defer(() => {
                            testStep.status = latestStatus;
                            return this._ds
                                .postEvidenceTestStep(
                                    testStep.effectivenessGuid,
                                    testStep.sampleGuid,
                                    testStep.guid,
                                    testStep
                                )
                                .pipe(
                                    map((result) => {
                                        latestStatus = result.status;
                                        return result;
                                    })
                                );
                        });
                    });
                })
                .reduce((acc, val) => acc.concat(val), []);

            const sampleCalls = this.samples.map((sample) => {
                return defer(() => {
                    sample.status = latestStatus;
                    return this._ds
                        .postEvidenceSample(sample.effectivenessGuid, sample.guid, sample)
                        .pipe(
                            map((result) => {
                                latestStatus = result.status;
                                return result;
                            })
                        );
                });
            });

            const calls: Observable<any>[] = [...testStepCalls, ...sampleCalls];

            for (const call of calls) {
                prompt.data.title = promptMessage + ` (${calls.indexOf(call) + 1}/${calls.length})`;
                await firstValueFrom(call);
            }

            this.effectivenessModel.status = latestStatus;
        } catch (error) {
            console.error(error);
        } finally {
            prompt.close();
        }
    }

    protected async workflowPrompt(
        title: string,
        message: string = null,
        includeScore: boolean = false
    ) {
        let additionalFields: GenericListField[] = includeScore
            ? [
                  {
                      prettyName: "Conclusion",
                      fieldName: "score",
                      fieldType: GenericListFieldType.ColoredSelect,
                      selfInit: true,
                      required: true,
                      getDataMethod: () => this.getWorkflowScores(),
                  },
              ]
            : [];

        const result = await this.reasonPrompt(title, message, additionalFields);
        if (!result) return null;

        const model: WorkflowConclusionModel = {
            comment: result.reason,
            scoreId: includeScore ? result.score : null,
        };

        return model;
    }

    protected async reasonPrompt(
        title: string,
        message: string = null,
        additionalFields: GenericListField[] = []
    ) {
        let height = (message ? 450 : 400) + additionalFields.length * 50;
        let fields: GenericListField[] = [
            ...additionalFields,
            {
                prettyName: "Comment",
                fieldName: "reason",
                fieldType: GenericListFieldType.TextArea,
                required: true,
            },
        ];

        const prompt = this._promptService
            .prompt({
                maxHeight: height + "px",
                data: {
                    title: title,
                    message: message,
                    fields: fields,
                },
            })
            .getResult();

        const result = await firstValueFrom(prompt);
        return result;
    }

    protected getWorkflowScores() {
        return getAssessmentScores(this._controlDs);
    }

    async onRemarkTypeCompleted(item: EvidenceSampleTestStep) {
        item.updateModel();
        this.saveRemarksChange(item.model as EvidenceSampleEditModel);
    }

    async saveRemarksChange(sample: EvidenceSampleEditModel) {
        await firstValueFrom(
            this._ds.postEvidenceSampleRemark(sample.effectivenessGuid, sample.guid, {
                remark: sample.remarks,
            })
        );
    }

    async giveFinalConclusion(
        title: string,
        saveButtonLabel: string,
        message: string,
        saveFunc: (finalConclusion: FinalConclusion) => void,
        width: string = "825px",
        hideScore: boolean = false
    ) {
        var scores = await toPromise(this.getWorkflowScores());
        var scoresColored: SelectColoredOption[] = scores.map((s) => {
            return {
                Id: s.ID,
                Label: s.ID == 0 ? "Add a score" : s.Name,
                BackgroundColor: s.ID == 0 ? "#B7BABF" : s.Color,
                Color: s.ID == 0 ? "#001A41" : "white",
                Hide: s.ID == 0,
            };
        });

        var username = await this._userDS.getUserName();
        this._dialog.open(EffFinalConclusionPopupComponent, {
            data: {
                scores: scoresColored,
                score: 0,
                username: username,
                title: title,
                hideScore: hideScore,
                saveButtonLabel: saveButtonLabel,
                message: message,
                onSave: saveFunc,
            },
            panelClass: "eff-popup-wrapper",
            width: width,
        });
    }

    getScoreColor(item: EvidenceSampleTestStep) {
        if (!item.isSample) {
            switch (item.stepScore) {
                case TestStepScore.correct:
                    return "score-correct";
                case TestStepScore.inapplicable:
                    return "score-inapplicable";
                case TestStepScore.incorrect:
                    return "score-incorrect";
                case TestStepScore.missingEvidence:
                    return "score-missing-evidence";
                case TestStepScore.noScore:
                    return "no-score";
            }
        } else {
            switch (item.sampleScore) {
                case SampleScore.sufficient:
                    return "score-correct";
                case SampleScore.inapplicable:
                    return "score-inapplicable";
                case SampleScore.insufficient:
                    return "score-incorrect";
                case SampleScore.noScore:
                    return "no-score";
            }
        }
    }

    visibleDocuments(documents: DocumentModel[]) {
        if (!documents) {
            return [];
        }
        return documents.filter((doc) => !doc.Deleted);
    }

    expandCollapseAll(
        expand: boolean,
        notExpandableItemFunc?: (item: any, items: any[]) => boolean
    ) {
        this.viewSamples.forEach((sample: SampleView) => {
            sample.gridDataSource.data.forEach((item: EvidenceSampleTestStep) => {
                if (!notExpandableItemFunc) {
                    item.IsExpanded = expand;
                } else {
                    if (
                        !notExpandableItemFunc(
                            item,
                            sample.gridDataSource.data.filter((gridItem) => !gridItem.isSample)
                        )
                    ) {
                        item.IsExpanded = expand;
                    }
                }
            });
        });
    }
}
