div.cerrix-workflow-history-graph {
    width: calc(100% - 2 * var(--page-margin));
    margin: var(--page-margin);

    border-radius: 3px;
    box-shadow: 0 8px 12px 0 rgba(9, 30, 66, 0.15), 0 0 1px 0 rgba(9, 30, 66, 0.31);

    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    overflow: hidden;

    .centering-flex-blox {
        overflow: auto;

        #diagramcontent {
            overflow: visible !important;
        }
    }
}

.cerrix-workflow-history-table {
    width: calc(100% - 2 * var(--page-margin));
    margin: var(--page-margin);

    .cerrix-row {
        padding: 10px 15px;

        > div {
            flex: 2;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            // Status
            &:nth-child(1) {
                flex: 2;
            }

            // Action
            &:nth-child(2) {
                flex: 1;
            }

            // On
            &:nth-child(3) {
                flex: 1;
            }

            // By
            &:nth-child(4) {
                flex: 1;
            }

            // Comment
            &:last-child {
                flex: 3;
            }
        }
    }

    .cerrix-column {
        --custom-border-config: 1px solid #e5f1fd;
        gap: 0;

        .cerrix-row {
            border-left: var(--custom-border-config);
            border-right: var(--custom-border-config);

            // give every other row a background color
            &:nth-child(even) {
                background-color: #f1f6fbbf;
            }

            &:last-child {
                border-bottom: var(--custom-border-config);
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
}
