import { CerrixPromptService } from "./../../../../../shared/services/cerrix-prompt.service";
import { Component } from "@angular/core";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { DevManagementDataService } from "@app/admin/services/devmanagement.service";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
    selector: "dev-actions",
    templateUrl: "./dev-actions.component.html",
    styleUrls: ["./dev-actions.component.scss"],
})
export class DevActionsComponent {
    private prompt;
    private toast: ActiveToast<any>;

    constructor(
        private dataService: DevManagementDataService,
        private promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    private visualizeAction(
        action: Observable<any>,
        progressMessage: string,
        completedMessage: string
    ) {
        if (this.prompt) {
            this._toastr.warning("An action is already running!");
            return;
        }

        if (this.toast) {
            this.toast.portal.destroy();
            this.toast = null;
        }

        this.prompt = this.promptService.loader(progressMessage);
        action
            .pipe(
                finalize(() => {
                    this.prompt.close();
                    this.prompt = null;
                })
            )
            .subscribe(() => {
                this.toast = this._toastr.success(completedMessage);
            });
    }

    reloadSiteConfigurations() {
        const action = this.dataService.reloadSiteConfigurations();
        this.visualizeAction(
            action,
            "Reloading Site Configuration",
            "Site Configurations Reloaded"
        );
    }

    reloadUsers() {
        const action = this.dataService.reloadUsers();
        this.visualizeAction(action, "Reloading Users", "Users Reloaded");
    }

    reloadDbSettings() {
        const action = this.dataService.reloadDbSettings();
        this.visualizeAction(action, "Reloading DB Settings", "DB Settings reloaded");
    }

    triggerNotifications() {
        const action = this.dataService.triggerNotifications();
        this.visualizeAction(action, "Triggering Notifications", "Notifications Triggered");
    }

    testScheduler() {
        const action = this.dataService.testScheduler();
        this.visualizeAction(action, "Triggering Test Scheduler", "Test Scheduler Triggered");
    }
}
