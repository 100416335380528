import { Component, Input, OnInit } from "@angular/core";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { FindingReportConfigModel } from "@app/findingreport/shared/models/finding-report-config-model";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditFindingReportSummaryModel } from "@app/audit/shared/data-models/audit-finding-report-summary-model";

@Component({
    selector: "audit-detail-finding-report-summary",
    templateUrl: "./audit-detail-finding-report-summary.component.html",
    styleUrls: ["./audit-detail-finding-report-summary.component.scss"],
})
export class AuditDetailFindingReportSummaryComponent implements OnInit {
    @Input() auditModel: AuditModel;
    @Input() showOpenButton: false;
    @Input() canCreate: boolean;

    summaryModel: AuditFindingReportSummaryModel;
    isLoading: boolean;

    constructor(
        private _tabservice: TabService,
        private _pages: Pages,
        private _auditDs: AuditDataService
    ) {}

    ngOnInit() {
        this.load();
    }

    load() {
        if (this.isLoading) {
            return;
        }

        if (this.auditModel && this.auditModel.id > 0) {
            this.isLoading = true;

            this._auditDs.getAuditFindingReportSummary(this.auditModel.guid).subscribe({
                next: (value: AuditFindingReportSummaryModel) => {
                    if (value) {
                        this.summaryModel = value;
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
        }
    }

    createFindingReport() {
        if (this.summaryModel && !this.summaryModel.canCreate) {
            return;
        }

        const frTabConfig = <FindingReportConfigModel>{
            auditGuid: this.auditModel.guid,
            name: this.auditModel.auditName,
            objective: this.auditModel.auditObjective,
        };

        const tab = this._tabservice.getTab(this._pages.ImDetail);
        tab.config = frTabConfig;

        this._tabservice.addTab(tab);
    }

    openFindingReport() {
        if (
            this.summaryModel &&
            (!this.summaryModel.findingReportGuid || !this.summaryModel.canOpen)
        ) {
            return;
        }

        this._tabservice.generateTab(this._pages.ImDetail, this.summaryModel.findingReportGuid);
    }
}
