import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-test-info-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlTestInfoApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Test Info API",
            description:
                "Retrieves testing details by control Identifier. This number can be found in the Cerrix Application.",

            httpMethod: "GET",
            path: "/controls/{controlId}/testing/advanced/effectiveness/automation/testinfo",

            parameters: [
                {
                    name: "controlId",
                    in: "path",
                    paramType: "integer",
                    required: true,
                    example: "1 | 2 | 3",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        controlId: "integer",
                        controlName: "string",
                        controlFrequency: "string",
                        testPeriods: [
                            {
                                testPeriodId: "uuid",
                                testPeriodName: "string",
                                testPeriodStart: "datetime",
                                testPeriodEnd: "datetime",
                                minimumNumberOfSamples: "integer",
                                testPlans: [
                                    {
                                        testPlanId: "uuid",
                                        testPlanName: "string",
                                        testPlanStart: "datetime",
                                        testPlanEnd: "datetime",
                                        status: "string",
                                        sampleMethod: "string",
                                        numberOfTestSamples: "integer",
                                        testPopulation: "integer",
                                        dueDateSourceDocumentUploader: "datetime",
                                        dueDateEvidenceUploader: "datetime",
                                        testSamples: [
                                            {
                                                testSampleId: "uuid",
                                                testSampleName: "string",
                                                testSteps: [
                                                    {
                                                        testStepId: "uuid",
                                                        testStepName: "string",
                                                        requiredDocuments: "string",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
        };
    }
}
