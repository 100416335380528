<div class="d-flex flex-column author-message-container">
    <div class="d-flex flex-row">
        <div class="eff-circle">
            <p class="eff-circle-inner">{{ initials }}</p>
        </div>
        <div class="d-flex flex-column author-label-container">
            <div class="author-name">{{ author }}</div>
            <div class="author-label">{{ authorLabel }}</div>
        </div>
    </div>
    <eff-read-more [text]="message" [charactersNumber]="100"></eff-read-more>
</div>
