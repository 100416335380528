import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EntityRoles } from "@app/datamanagement/enums/EntityRoles";
import { DatamanagementPermissionModel } from "@app/datamanagement/models/DatamanagementPermissionModel";
import { ProcessingPurposeModel } from "@app/datamanagement/models/ProcessingPurposeModel";
import { ProcessingPurposeStandingData } from "@app/datamanagement/models/ProcessingPurposeStandingData";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { StructureValidationHelper } from "@app/shared/helpers/structure-validation-helper";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleType } from "@enums/ModuleType";
import { StandingDataType } from "@enums/StandingDataType";
import { getFormControl, setFormControlValue } from "@methods/CommonMethods";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { Observable } from "rxjs";

@Component({
    selector: "datamanagement-detail-main",
    templateUrl: "./datamanagement-detail-main.component.html",
    styleUrls: ["./datamanagement-detail-main.component.scss"],
})
export class DataManagementDetailMainComponent implements OnInit {
    @Input() processingPurpose: ProcessingPurposeModel;
    @Input() parentForm: FormGroup;
    @Input() permissions: DatamanagementPermissionModel;

    isInitializing = true;
    maxDate = new Date();

    selectedOrganizationId: number;
    previousOrganizationId: number;
    previousBusinessDimensions: number[];

    businessDimensionTreeItems: CerrixTreeItem[] = [];
    frameworkDimensionTreeItems: CerrixTreeItem[] = [];

    recipientConfig: GenericListConfig;
    sourceDeliveryConfig: GenericListConfig;

    organizationRequest: Observable<CerrixTreeItem[]>;
    basisRequest: Observable<IdNameCombination[]>;
    removalRequest: Observable<IdNameCombination[]>;
    sensitivityRequest: Observable<IdNameCombination[]>;

    dataControllerRequest: Observable<IdNameCombination[]>;
    dataSubProcessorRequest: Observable<IdNameCombination[]>;
    thirdPartiesRequest: Observable<IdNameCombination[]>;

    dataSubjectsRequest: Observable<IdNameCombination[]>;

    constructor(
        public _datamanagementDS: DatamanagementDataService,
        private _promptService: CerrixPromptService,
        private _standingDataDS: StandingdataDataService
    ) {}

    ngOnInit() {
        this.selectedOrganizationId = this.processingPurpose.organizationId;
        this.previousOrganizationId = this.processingPurpose.organizationId;
        this.previousBusinessDimensions = this.processingPurpose.businessDimensionIds;

        this.organizationRequest = this._standingDataDS.getOrganizationsIncludingSelected(
            ModuleType.DM,
            this.selectedOrganizationId
        );

        this.basisRequest = this.createSdRequest(
            StandingDataType.DmBasis,
            this.processingPurpose.basisId
        );

        this.removalRequest = this.createSdRequest(
            StandingDataType.DmRemovalPolicy,
            this.processingPurpose.removalIds
        );

        this.sensitivityRequest = this.createSdRequest(
            StandingDataType.DmSensitivityOfProcessing,
            this.processingPurpose.sensitivityIds
        );

        this.dataControllerRequest = this.createEntitiesRequest(
            EntityRoles.DataController,
            this.processingPurpose.dataControllerIds
        );

        this.dataSubProcessorRequest = this.createEntitiesRequest(
            EntityRoles.DataProcessor,
            this.processingPurpose.dataProcessorIds
        );

        this.dataSubjectsRequest = this._datamanagementDS.getDataSubjectsSDAsList(this.processingPurpose.dataSubjectIds);

        this.thirdPartiesRequest = this.createEntitiesRequest(
            EntityRoles.ThirdParty,
            this.processingPurpose.thirdPartyIds
        );

        this.dataSubjectsRequest.subscribe((dataSubjectsSD) => {
            const dataSubjects = this.processingPurpose.dataSubjectIds.map(
                (id) => dataSubjectsSD.find((subject) => subject.ID === id).Name
            );
            this.processingPurpose.dataSubject = dataSubjects.join(", ");
        });

        this.initializeDetails();
    }

    organizationChanged(newValue: number) {
        // Do not fire when page is initalizing or the organizationId did not change
        if (this.isFetchingTrees() || newValue === this.previousOrganizationId) {
            return;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.processingPurpose);
        this.selectedOrganizationId = this.processingPurpose.organizationId;

        // Check if the organization changes should be validated
        if (
            this.processingPurpose.businessDimensionIds.length === 0 &&
            this.processingPurpose.frameworkDimensionIds.length === 0
        ) {
            // Update business dimension and framework dimension trees
            this.initializeDetails();
            return;
        }

        // Get validations based on the organizationId
        this._datamanagementDS
            .validateOrganizationChange(this.processingPurpose)
            .subscribe((validations) => {
                if (
                    validations.invalidControls.length === 0 &&
                    validations.invalidBusinessDimensions.length === 0 &&
                    validations.invalidFrameworkDimensions.length === 0
                ) {
                    // Change is valid
                    this.previousOrganizationId = newValue;

                    this.initializeDetails();
                    return;
                }

                // The organization change is not valid
                const msg = StructureValidationHelper.getOrganizationValidationMsg(validations);

                this._promptService
                    .confirmCustom({
                        maxWidth: "450px",
                        maxHeight: "350px",
                        data: {
                            title: "Organization change",
                            message: msg,
                        },
                    })
                    .getResult()
                    .subscribe((confirmed) => {
                        if (!confirmed) {
                            setFormControlValue<ProcessingPurposeModel>(
                                this.parentForm,
                                "organizationId",
                                this.previousOrganizationId
                            );
                            return;
                        }

                        const correctedControls = this.processingPurpose.controlIds.filter(
                            (x) => validations.invalidControls.indexOf(x) === -1
                        );

                        const correctedBusinessDimensions =
                            this.processingPurpose.businessDimensionIds.filter(
                                (x) => validations.invalidBusinessDimensions.indexOf(x) === -1
                            );

                        const correctedFrameworks =
                            this.processingPurpose.frameworkDimensionIds.filter(
                                (x) => validations.invalidFrameworkDimensions.indexOf(x) === -1
                            );

                        this.processingPurpose.controlIds = correctedControls;
                        this.processingPurpose.businessDimensionIds = correctedBusinessDimensions;
                        this.processingPurpose.frameworkDimensionIds = correctedFrameworks;

                        setFormControlValue<ProcessingPurposeModel>(
                            this.parentForm,
                            "businessDimensionIds",
                            correctedBusinessDimensions
                        );

                        setFormControlValue<ProcessingPurposeModel>(
                            this.parentForm,
                            "frameworkDimensionIds",
                            correctedFrameworks
                        );

                        this.initializeDetails();
                    });
            });
    }

    businessDimensionsChanged(newValues: number[]) {
        // Do not fire when tree is open, page is initalizing or the businessDimensionIds did not change
        if (this.isFetchingTrees() || !newValues.isDifferent(this.previousBusinessDimensions)) {
            return;
        }

        if (newValues.length === 0) {
            this.previousBusinessDimensions = newValues;
            return;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.processingPurpose);

        // Check if the businessDimension changes should be validated
        if (this.processingPurpose.frameworkDimensionIds.length === 0) {
            this.getBusinessDimensionDependencies();
            return;
        }
        // Get validations based on the businessDimensionIds
        this._datamanagementDS
            .validateBusinessDimensionChange(this.processingPurpose)
            .subscribe((validations) => {
                if (validations.invalidFrameworkDimensions.length === 0) {
                    // Change is valid
                    this.previousBusinessDimensions = newValues;

                    this.getBusinessDimensionDependencies();
                    return;
                }

                // Change is invalid
                const msg =
                    "Some selected framework dimensions cannot be applied to the selected business dimensions \n \n" +
                    "Press 'Confirm' to remove non-allowed framework dimensions. \n " +
                    "Press 'Cancel' to reset to previous business dimensions";

                this._promptService
                    .confirmCustom({
                        maxWidth: "450px",
                        maxHeight: "350px",
                        data: {
                            title: "Organization change",
                            message: msg,
                        },
                    })
                    .getResult()
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            const correctedFrameworks =
                                this.processingPurpose.frameworkDimensionIds.filter(
                                    (x) => validations.invalidFrameworkDimensions.indexOf(x) === -1
                                );
                            this.processingPurpose.frameworkDimensionIds = correctedFrameworks;

                            this.getBusinessDimensionDependencies();
                        } else {
                            setFormControlValue<ProcessingPurposeModel>(
                                this.parentForm,
                                "businessDimensionIds",
                                this.previousBusinessDimensions
                            );
                        }
                    });
            });
    }

    private initializeDetails() {
        this.businessDimensionTreeItems = this.isInitializing ? [] : null;
        this.frameworkDimensionTreeItems = this.isInitializing ? [] : null;

        this._datamanagementDS
            .getOrganizationDependencies(this.processingPurpose)
            .subscribe((standingData) => {
                this.setBusinessDimensionTree(standingData);
                this.setFrameworkDimensionTree(standingData);

                this.isInitializing = false;
            });

        this.initListsConfigs();
    }

    getBusinessDimensionDependencies() {
        this.frameworkDimensionTreeItems = this.isInitializing ? [] : null;

        this._datamanagementDS
            .getBusinessDimensionDependencies(this.processingPurpose)
            .subscribe((standingData) => {
                this.setFrameworkDimensionTree(standingData);
            });
    }

    setBusinessDimensionTree(standingData: ProcessingPurposeStandingData) {
        this.businessDimensionTreeItems = standingData.businessDimensions;
        setFormControlValue<ProcessingPurposeModel>(
            this.parentForm,
            "businessDimensionIds",
            this.processingPurpose.businessDimensionIds
        );
    }

    setFrameworkDimensionTree(standingData: ProcessingPurposeStandingData) {
        this.frameworkDimensionTreeItems = standingData.frameworkDimensions;
        setFormControlValue<ProcessingPurposeModel>(
            this.parentForm,
            "frameworkDimensionIds",
            this.processingPurpose.frameworkDimensionIds
        );
    }

    isFetchingTrees(): boolean {
        return (
            this.isInitializing ||
            !this.frameworkDimensionTreeItems ||
            !this.businessDimensionTreeItems
        );
    }

    private initListsConfigs() {
        this._datamanagementDS
            .getDataRecipients(this.processingPurpose.guid)
            .subscribe((recipients) => {
                this.recipientConfig = <GenericListConfig>{
                    name: "Data recipient outside EU & transfer safeguard",
                    isSortable: false,
                    idProp: "id",

                    allowAdd: !this.permissions.readonly,
                    allowEdit: !this.permissions.readonly,
                    allowDelete: !this.permissions.readonly,

                    fields: [
                        {
                            prettyName: "Data recipient",
                            fieldName: "dataRecipientId",
                            fieldType: GenericListFieldType.SingleSelect,
                            required: true,
                            unique: true,
                            getDataMethod: () => {
                                return this.createEntitiesRequest(
                                    EntityRoles.DataRecipient,
                                    this.processingPurpose.dataRecipientThirdPartyIds
                                );
                            },
                        },
                        {
                            prettyName: "Transfer safeguard",
                            fieldName: "transferSafeguardId",
                            fieldType: GenericListFieldType.SingleSelect,
                            required: true,
                            getDataMethod: () => {
                                return this.createSdRequest(StandingDataType.DmTransferSafeguard);
                            },
                        },
                    ],

                    data: recipients,
                    dataChanged: (data) => {
                        this.processingPurpose.dataRecipients = data;
                    },
                };
            });

        this._datamanagementDS
            .getSourceDelivery(this.processingPurpose.guid)
            .subscribe((sourceDeliveries) => {
                this.sourceDeliveryConfig = <GenericListConfig>{
                    name: "Source & delivery",
                    isSortable: false,

                    allowAdd: !this.permissions.readonly,
                    allowEdit: !this.permissions.readonly,
                    allowDelete: !this.permissions.readonly,

                    fields: [
                        {
                            prettyName: "Source",
                            fieldName: "sourceId",
                            fieldType: GenericListFieldType.SingleSelect,
                            required: true,
                            unique: true,
                            getDataMethod: () => {
                                return this.createSdRequest(StandingDataType.DmSourceDocuments);
                            },
                        },
                        {
                            prettyName: "Delivery",
                            fieldName: "delivery",
                            fieldType: GenericListFieldType.Text,
                            required: true,
                        },
                    ],

                    data: sourceDeliveries,
                    dataChanged: (data) => {
                        this.processingPurpose.sourceDeliveries = data;
                    },
                };
            });
    }

    private createEntitiesRequest(entityRole: EntityRoles, selected: number[]) {
        return this._datamanagementDS.getEntitiesByRole(entityRole, selected);
    }

    private createSdRequest(
        standingDataType: StandingDataType,
        selected: number[] | number = null
    ): Observable<IdNameCombination[]> {
        let selectedIds: number[] = [];
        if (selected) {
            if (Array.isArray(selected)) {
                selectedIds = selected;
            } else {
                selectedIds = [selected];
            }
        }

        return this._datamanagementDS.getStandingDataByType(standingDataType, selectedIds);
    }
}
