import { Component, Input } from "@angular/core";
import { FormLinkableModule } from "@app/forms/enums/FormLinkableModule";
import { FormsLinkModel } from "@app/forms/models/other/FormsLinkModel";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { LinkModel } from "@models/generic/LinkModel";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";

@Component({
    selector: "thirdparty-linked-forms",
    templateUrl: "./thirdparty-forms.component.html",
})
export class ThirdPartyLinkedFormsComponent {
    @Input() model: ThirdPartyModel;
    @Input() disabled: boolean;

    protected linkedModule: FormLinkableModule = FormLinkableModule.ThirdParty;
    protected linkableForms: FormsLinkModel[] = [];
    protected unlinked: FormsLinkModel[] = [];
    protected linked: FormsLinkModel[] = [];
    protected isLoading: boolean = true;

    constructor(private formsDataService: FormsDataService) {}

    public load(): void {
        this.formsDataService
            .getLinkableForms(this.linkedModule)
            .then((linkedForms: FormsLinkModel[]): void => {
                this.linkableForms = linkedForms;

                this.loadForms();

                this.isLoading = false;
            });
    }

    public linkedItemsChanged(linked: LinkModel[]): void {
        this.model.LinkedForms = linked.map((link: LinkModel): number => link.id);

        this.loadForms();
    }

    private loadForms(): void {
        const linkedItems = [];
        const unlinkedItems = [];
        if (this.model.LinkedForms && this.model.LinkedForms.length > 0) {
            for (const form of this.linkableForms) {
                if (this.model.LinkedForms.includes(form.id)) {
                    linkedItems.push(form);
                } else {
                    unlinkedItems.push(form);
                }
            }
        } else {
            unlinkedItems.addRange(this.linkableForms);
        }

        this.linked = linkedItems;
        this.unlinked = unlinkedItems;
    }
}
