import { Injectable, Injector } from "@angular/core";

export class AppConstants {
    static AppInjector: Injector;
    static DefaultIcon: string;
}

@Injectable()
export class Configuration {
    public Server = "http://localhost:65425";
    public ApiUrl = "/api/";
    public ServerWithApiUrl = this.ApiUrl;
    // Use this if we ever sepearate the Api from the rest
    // public ServerWithApiUrl = this.Server + this.ApiUrl;

    public ToastrTimeout = 3000;
    public ToastrErrorTimeout = 15000;

    public MaxTextFieldLength = 300;
    public MaxTextAreaLength = 32767;

    public FroalaLicence = "aLF3c1A7B6A5E3A2B1H2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6A3B2E4E2G1==";

    public static NoOrganizationsSelectedDescription =
        "If no organization is selected, this applies for all organizations";
}
