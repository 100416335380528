import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LinkedMoiOverviewComponent } from "@app/moi/moi-linked-overview/moi-linked-overview.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { ThirdPartyDataService } from "../services/ThirdPartyDataService";
import { ThirdPartyControlsComponent } from "../thirdparty-controls/thirdparty-controls.component";
import { ThirdPartyRisksComponent } from "../thirdparty-risks/thirdparty-risks.component";
import { ThirdPartyProcessingPurposeComponent } from "./thirdparty-processingpurpose/thirdparty-processingpurpose.component";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { ThirdPartyPermissions } from "@models/thirdparty/ThirdPartyPermissions";
import { ThirdPartyContractsComponent } from "../thirdparty-contracts/thirdparty-contracts.component";
import { ThirdPartyContractsOverviewComponent } from "../thirdparty-contracts-overview/thirdparty-contracts-overview.component";
import { TargetModule } from "@enums/document/TargetModule";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ThirdPartyConfigModel } from "@models/thirdparty/third-party-config-model";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { isGuid } from "@methods/uniqueMethods";
import { ThirdPartyLinkedFormsComponent } from "../thirdparty-forms/thirdparty-forms.component";

@Component({
    selector: "thirdparty-detail",
    templateUrl: "./thirdparty-detail.component.html",
    styleUrls: ["./thirdparty-detail.component.scss"],
})
export class ThirdPartyDetailComponent implements OnInit {
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent;
    @ViewChild("thirdpartyRisksComponent")
    thirdpartyRisksComponent: ThirdPartyRisksComponent;
    @ViewChild("thirdpartyControlsComponent")
    thirdpartyControlsComponent: ThirdPartyControlsComponent;
    @ViewChild("thirdpartyMoisComponent")
    thirdPartyMoisComponent: LinkedMoiOverviewComponent;
    @ViewChild("thirdpartyProcessingPurposeComponent")
    ThirdPartyProcessingPurposeComponent: ThirdPartyProcessingPurposeComponent;
    @ViewChild("thirdpartyContractsComponent")
    ThirdPartyContractsComponent: ThirdPartyContractsComponent;
    @ViewChild("thirdpartyContractsOverviewComponent")
    thirdpartyContractsOverviewComponent: ThirdPartyContractsOverviewComponent;
    @ViewChild("thirdPartyFormsComponent")
    thirdPartyFormsComponent: ThirdPartyLinkedFormsComponent;

    thirdParty: ThirdPartyModel;

    tpMoiType = MoiTypes.TP_Moi;
    tab: TabModel;
    tabID: string;
    id: string;

    sd: any;

    parentForm: FormGroup;

    documentsReady = false;
    readonly: boolean;

    targetModule = TargetModule.ThirdParty;
    permissions: ThirdPartyPermissions = new ThirdPartyPermissions();

    hyperlinksMenuItemId = "menuItemHyperlinks";

    constructor(
        private _toastr: ToastrService,
        public dataservice: ThirdPartyDataService,
        private _tabService: TabService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        this.id = this.tab.id;

        const standingDataRequest = this.dataservice.getStandingData().toPromise();
        this.sd = await standingDataRequest;
        if (!this.sd) {
            this._toastr.error("Something went wrong!");
            this.tab.close();
        }
        this.setupThirdParty();
    }

    InitThirdparty() {
        if (!this.thirdParty.ID || this.thirdParty.ID <= 0) {
            this.initNewThirdPartyModel();
        }

        this.initForms(this.thirdParty);

        if (!this.thirdParty.Documents) {
            this.thirdParty.Documents = [];
            this.documentsReady = true;
        }
        if (!this.thirdParty.LinkedRisks) {
            this.thirdParty.LinkedRisks = [];
        }
        if (!this.thirdParty.Contacts) {
            this.thirdParty.Contacts = [];
        }
        this.tab.name = this.thirdParty.Guid
            ? `(TP) ${this.thirdParty.Identifier} - ${this.thirdParty.Name}`
            : "Add new Third Party";
        this.tab.showLoader = false;
    }

    initNewThirdPartyModel() {
        const conf = <ThirdPartyConfigModel>this.tab.config;
        if (conf) {
            const orgID = conf.organizationId;
            if (orgID) {
                this.thirdParty.Organizations = [+orgID];
            }

            const bdID = conf.businessId;
            if (bdID) {
                this.thirdParty.BusinessDimensions = [+bdID];
            }
        }
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "history": {
                    this.historyOverviewComponent.loadHistory();
                    break;
                }
                case "risks": {
                    this.thirdpartyRisksComponent.load();
                    break;
                }
                case "controls": {
                    this.thirdpartyControlsComponent.load();
                    break;
                }
                case "moi": {
                    this.thirdPartyMoisComponent.load(false);
                    break;
                }
                case "contracts": {
                    this.thirdpartyContractsOverviewComponent.load();
                    break;
                }
                case "forms": {
                    this.thirdPartyFormsComponent.load();
                    break;
                }
                case this.hyperlinksMenuItemId:
                    this.loadHyperlinks();
                    break;
            }
        }
    }

    async save() {
        if (this.permissions.canEdit && !this.parentForm.valid) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
            return;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.thirdParty);

        const savingPrompt = this._promptService.loader("Saving changes, please wait...");
        const call = this.dataservice
            .storeThirdParty(this.thirdParty)
            .pipe(finalize(() => savingPrompt.close()))
            .toPromise()
            .then((guid) => {
                this.tab.id = guid;
            });

        await call;

        this._tabService.refresh(this._pages.Thirdparty);

        this._toastr.success("Update completed");
        this.tab.refresh();
    }

    delete() {
        this.dataservice.deleteThirdParty(this.thirdParty.Guid).subscribe(
            (validations) => {
                if (validations && validations.length > 0) {
                    this._toastr.warning(validations.toString());
                } else {
                    this._toastr.success("Third party deleted");
                    this._tabService.refresh(this._pages.Thirdparty);
                    this.tab.close(false);
                }
            },
            (error) => {
                this._toastr.error("", error || error.Message);
            }
        );
    }

    setupThirdParty() {
        this.dataservice.getPermissions(this.id).subscribe(
            (permissions: ThirdPartyPermissions) => {
                this.permissions = permissions;
                if (!this.permissions.canView) {
                    this._toastr.error("You don't have enough rights");
                    this.tab.close(false);
                }
                this.dataservice.getThirdParty(this.id).subscribe((thirdParty: ThirdPartyModel) => {
                    this.thirdParty = thirdParty;
                    this.InitThirdparty();
                });
            },
            () => {
                this.tab.close(false);
            }
        );
    }

    initForms(thirdparty: ThirdPartyModel) {
        this.parentForm = new FormGroup(
            {
                Name: new FormControl(thirdparty.Name, [Validators.required]),
                Address: new FormControl(thirdparty.Address),
                Owner: new FormControl(thirdparty.Owner),
                ThirdpartyResponsible: new FormControl(thirdparty.ThirdpartyResponsible),
                Description: new FormControl(thirdparty.Description),
                EUMember: new FormControl(thirdparty.EUMember),
                CocNumber: new FormControl(thirdparty.CocNumber, [
                    Validators.min(0),
                    Validators.max(2147483647),
                ]),
                TaxNumber: new FormControl(thirdparty.TaxNumber),
                Country: new FormControl(thirdparty.Country),
                City: new FormControl(thirdparty.City),
                BeneficialOwner: new FormControl(thirdparty.BeneficialOwner),
                AuthorizedSignatory: new FormControl(thirdparty.AuthorizedSignatory),
                StatusOfInvolvementID: new FormControl(thirdparty.StatusOfInvolvementID),
                InitialReviewDate: new FormControl(thirdparty.InitialReviewDate),
                NextReviewDate: new FormControl({
                    value: thirdparty.NextReviewDate,
                    disabled: true,
                }),
                ReviewFrequency: new FormControl(thirdparty.ReviewFrequency),
                Reviewers: new FormControl(thirdparty.Reviewers),
                UserResponsibles: new FormControl(thirdparty.UserResponsibles),
                Phone: new FormControl(
                    thirdparty.Phone,
                    FormValidationHelper.validatePhoneControl("Phone")
                ),
                Email: new FormControl(
                    thirdparty.Email,
                    FormValidationHelper.validateEmailControl("Email")
                ),
                Website: new FormControl(
                    thirdparty.Website,
                    FormValidationHelper.validateWebsiteControl("Website")
                ),
                ContractValue: new FormControl(thirdparty.ContractValue, Validators.min(0)),
                BusinessDimensions: new FormControl(thirdparty.BusinessDimensions),
                FrameworkDimensions: new FormControl(thirdparty.FrameworkDimensions),
                CurrencyCode: new FormControl(thirdparty.CurrencyCode),
                Organizations: new FormControl(thirdparty.Organizations, Validators.required),
                LinkedServiceIds: new FormControl(thirdparty.LinkedServiceIds),
                Comment: new FormControl(thirdparty.Comment),
                DataController: new FormControl(thirdparty.DataController),
                DataRecipient: new FormControl(thirdparty.DataRecipient),
                DataProcessor: new FormControl(thirdparty.DataProcessor),
                ThirdParty: new FormControl(thirdparty.ThirdParty),
                LeiCode: new FormControl(thirdparty.LeiCode, [
                    FormValidationHelper.validateFixedLength("LEI Code", 20),
                ]),
                DUNS_Number: new FormControl(
                    thirdparty.DUNS_Number,
                    FormValidationHelper.validateMax("D-U-N-S Number", 1000000000)
                ), // max 9 digits
                OrganizationBeneficialOwner: new FormControl(
                    thirdparty.OrganizationBeneficialOwner
                ),
                OrganizationResponsibleId: new FormControl(
                    thirdparty.OrganizationResponsibleId,
                    Validators.required
                ),
                Relationship: new FormControl(thirdparty.Relationship),
                Criticality: new FormControl(thirdparty.Criticality),
                StartDateServicing: new FormControl(thirdparty.StartDateServicing),
                EndDateServicing: new FormControl(thirdparty.EndDateServicing),
            },
            {
                validators: [
                    FormValidationHelper.validateStartEndDate<ThirdPartyModel>(
                        "StartDateServicing",
                        "EndDateServicing",
                        "'End date servicing' may not be before 'Start date servicing'",
                        false
                    ),
                ],
            }
        );

        if (!this.permissions.canEdit) {
            this.parentForm.disable();
        }
    }

    loadHyperlinks(): void {
        if (!this.thirdParty.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this.dataservice.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.thirdParty.Hyperlinks = hyperlinks;
            });
        }
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
    }
}
