<ng-template #advancedConfiguration>
    <mat-tab-group animationDuration="0ms" class="workspace-adv-config-tab-group">
        <mat-tab label="Filter">
            <div class="container-fluid">
                <div class="config-filters-area">
                    <div class="config-filter row mb-2">
                        <div class="config-filter-left col-md-6 config-filter-area">
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        >Column</span
                                    >
                                </div>
                                <select
                                    class="form-select custom-select"
                                    [(ngModel)]="configColumn"
                                    id="compareColumn"
                                    (change)="setComparisonTypes()"
                                >
                                    <option value="" selected>Any</option>
                                    <option *ngFor="let col of allColumns" [value]="col">
                                        {{ headerLookup[col] }}
                                    </option>
                                </select>
                            </div>
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        >Compare with</span
                                    >
                                </div>
                                <select
                                    class="form-select custom-select"
                                    [(ngModel)]="configCompareType"
                                    id="compareType"
                                >
                                    <option
                                        *ngFor="let comp of configComparisonTypes"
                                        [value]="comp.key"
                                    >
                                        {{ comp.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="input-group input-group-sm mb-2">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        >Search value</span
                                    >
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="configSearchValue"
                                    inputTooltip
                                />
                            </div>
                            <button
                                class="btn btn-outline-secondary float-end"
                                type="button"
                                (click)="addKywrd()"
                            >
                                Add Filter
                                <i class="fas fa-caret-right"></i>
                            </button>
                        </div>
                        <div class="config-filter-right col-md-6">
                            <mat-chip-listbox *ngIf="configKeywords.length > 0" id="sortabletest">
                                <mat-chip-option
                                    *ngFor="let kywrd of configKeywords"
                                    (removed)="removeKywrd(kywrd)"
                                    (click)="editKywrd(kywrd)"
                                >
                                    {{ kywrd.searchValue }}
                                    {{ kywrd.col == "" ? "" : "[" + headerLookup[kywrd.col] + "]" }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip-option>
                            </mat-chip-listbox>
                            <div *ngIf="configKeywords.length <= 0" class="no-filter-message">
                                <span>No filters specified.</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div *ngFor="let filter of filterConfig" class="mb-2">
                        <div *ngIf="!dataFilter[filter.column]">
                            <i class="fas fa-circle-notch fa-spin me-2"></i>Loading
                            {{ filter.label }}
                            filter
                        </div>
                        <div *ngIf="dataFilter[filter.column]">
                            <div class="form-group">
                                <label>{{
                                    (filter.label ? filter.label : headerLookup[filter.column]) +
                                        (filter.remoteFilter ? " *" : "")
                                }}</label>

                                <cerrix-select-tree
                                    #specificTree
                                    *ngIf="
                                        filter.component === filterComponent.MultiSelectTree ||
                                        filter.component === filterComponent.SingleSelectTree
                                    "
                                    [identifier]="filter.identifier"
                                    [tree]="dataFilter[filter.column]"
                                    [value]="
                                        filters[filter.column]
                                            ? filters[filter.column]
                                            : filter.component === filterComponent.MultiSelectTree
                                            ? []
                                            : null
                                    "
                                    [multiselect]="
                                        filter.component === filterComponent.MultiSelectTree
                                    "
                                    (onChange)="setTreeValue(filter.column, $event)"
                                >
                                </cerrix-select-tree>

                                <ng-select
                                    #combobox
                                    *ngIf="filter.component === filterComponent.ComboBox"
                                    [id]="filter.identifier"
                                    [items]="dataFilter[filter.column]"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [appendTo]="ngSelectHolderSelector"
                                    (change)="setDropdownValue(filter.column, $event)"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <small class="modal-body-info" *ngIf="hasServerFilters">
                        Filters appended with an asterisk (*) are remote filters and might take
                        longer to complete.
                    </small>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Table" *ngIf="showTableConfiguration">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="header-header bolded">Active Headers</div>
                        <ejs-listbox
                            [(dataSource)]="shownHeaderData"
                            [allowDragAndDrop]="true"
                            [fields]="setfield"
                            scope="combined-list"
                            (drop)="onDropSetHidden($event)"
                            (click)="toggleHeader($event, true)"
                            #activeListbox
                        ></ejs-listbox>
                    </div>
                    <div class="col">
                        <div class="header-header bolded">Hidden Headers</div>
                        <ejs-listbox
                            [(dataSource)]="hiddenHeaderData"
                            [allowDragAndDrop]="true"
                            [fields]="setfield"
                            scope="combined-list"
                            (click)="toggleHeader($event, false)"
                            (drop)="onDropSetActive($event)"
                            #hiddenListbox
                        ></ejs-listbox>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-template>
