import { DocumentModel } from "@models/documents/documentmodel";
import { IHasDocuments } from "@methods/DocumentMethods";
import { EventCommentModel } from "@app/event/shared/event-comment-model";
import { DataBreachModel } from "./DataBreachModel";
import { EventWorkflowModel } from "./EventWorkflowModel";
import { EventRiskControlModel } from "@app/event/shared/event-risk-control-model";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";

export class EventModel implements IHasDocuments {
    Guid: string;
    ID: number;
    BaseId: number;

    DataBreachGuid: string;

    AffectedOrganizationId: number;
    BusinessLineId: number;
    BusinessDimensions: number[] = [];
    CausedByOrganizationId: number;

    CompletedById: number;
    EventCategoryId: number;
    OrganizationId: number;
    RiskCatalogueId: number;
    ReportedById: number;
    ReporterName: string; // user who created the event bug-11363

    DateClosed: Date;
    DateCreated: Date;
    DateDetection: Date;
    DateOccurrence: Date;
    LastSaveDate: Date;
    UpdatedBy: number;

    CauseDescription: string;
    Comments: string;
    CurrencyCode: string;

    Description: string;
    EffectDescription: string;

    GrossCosts: number;
    NetCosts: number;
    Identifier: string;
    ImpactDescription: string;
    InternalIdentifier: string;
    PrimaryCause: string;
    SecondaryCause: string;
    Recovery: number;
    ReputationImpactDescription: string;
    ReputationImpactId: number;

    RiskOpinion: string;
    StartValidity: Date;
    DateDeleted: Date;
    IsDataBreach = false;

    OrganizationIds: number[] = [];
    RiskCatalogueIds: number[] = [];

    Name: string;
    Calculation: string;

    ActionIdList: number[] = [];
    MoiIdList: number[] = [];
    RiskIdList: number[] = [];
    ControlIdList: number[] = [];

    Effects: number[] = [];
    Responsible: number[] = [];
    Assessor: number[] = [];
    Informed: number[] = [];
    IncidentTypes: number[] = [];

    ClassificationId: number;

    Documents: DocumentModel[] = [];
    Hyperlinks: HyperlinkModel[];
    ExistingComments: EventCommentModel[];

    // Only used when sent to the server
    DataBreach: DataBreachModel;
    Risks: EventRiskControlModel[] = [];
    Controls: EventRiskControlModel[] = [];

    // Workflow
    Workflow: EventWorkflowModel;

    // CustomFields
    CustomFields: CustomFieldDetailModel[] = [];
}
