import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { TargetModule } from "@enums/document/TargetModule";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";

@Component({
    selector: "hyperlink-manager",
    templateUrl: "./hyperlink-manager.component.html",
    styleUrls: ["./hyperlink-manager.component.scss"],
})
export class HyperlinkManagerComponent implements OnInit {
    @Input() Hyperlinks: HyperlinkModel[] = [];
    @Input() TargetModule: TargetModule;

    @Input() Label = "Hyperlinks";
    @Input() Readonly: boolean = false;

    @Output() HyperlinksChanged = new EventEmitter<HyperlinkModel[]>();

    listConfig: GenericListConfig;
    hyperlinkTypes: IdNameCombination[];

    constructor(private _standingDataDS: StandingdataDataService, private _toastr: ToastrService) {}

    async ngOnInit(): Promise<void> {
        await this.getStandingData();
        await this.initListManager();
    }

    private async getStandingData(): Promise<void> {
        this.hyperlinkTypes = await toPromise(
            this._standingDataDS.getHyperlinkTypes(this.TargetModule)
        );
    }

    private initListManager(): void {
        this.listConfig = <GenericListConfig>{
            name: this.Label,
            idProp: nameof<HyperlinkModel>((h) => h.id),
            isSortable: false,

            allowAdd: !this.Readonly,
            allowEdit: !this.Readonly,
            allowDelete: !this.Readonly,

            fields: [
                {
                    fieldName: nameof<HyperlinkModel>((h) => h.name),
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                    required: true,
                },
                {
                    fieldName: nameof<HyperlinkModel>((h) => h.hyperlink),
                    prettyName: "Hyperlink",
                    fieldType: GenericListFieldType.Text,
                    required: true,
                },
                {
                    fieldName: nameof<HyperlinkModel>((h) => h.hyperlinkTypeId),
                    prettyName: "Hyperlink Type",
                    fieldType: GenericListFieldType.SingleSelect,
                    required: false,
                    getDataMethod: () => {
                        return of(this.hyperlinkTypes);
                    },
                },
                {
                    fieldName: nameof<HyperlinkModel>((h) => h.description),
                    prettyName: "Description",
                    fieldType: GenericListFieldType.TextArea,
                    required: false,
                },
            ],

            data: this.Hyperlinks,
            dataChanged: (data: HyperlinkModel[]) => {
                this.Hyperlinks = data;
                this.HyperlinksChanged.emit(data);
            },

            overviewRowActionsName: "Open",
            overviewRowActions: [
                {
                    icon: "fas fa-external-link-alt",
                    tooltip: "Open Hyperlink",
                    clickEvent: (row: HyperlinkModel) => {
                        if (row.hyperlink.indexOf(":") == -1)
                            this._toastr.warning(
                                "Can't open link, missing protocol (eg. 'https://')"
                            );
                        else window.open(row.hyperlink, "_blank");
                    },
                },
            ],
        };
    }
}
