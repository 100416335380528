import { Component, EventEmitter, Output, OnInit, Input } from "@angular/core";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { SampleType } from "../../shared/enums/sample-type.enum";
import { AuditSampleModel } from "./audit-sample.model";
import { AuditSamplerDataService } from "./adv-eff-audit-sampler-data.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormGroup, Validators, FormControl, AbstractControl } from "@angular/forms";
import { getFormControl } from "@methods/jeffs-toolkit";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { finalize } from "rxjs/operators";
import { toDate } from "../../shared/adv-eff-shared-methods";

@Component({
    selector: "adv-eff-audit-sampler",
    templateUrl: "./adv-eff-audit-sampler.component.html",
    styleUrls: ["./adv-eff-audit-sampler.component.scss"],
})
export class AuditSamplerComponent implements OnInit {
    @Input() modalRef: BsModalRef;
    @Output() reload = new EventEmitter();

    sampleModel: AuditSampleModel;
    formGroup: FormGroup;
    generatedSamples: string;

    sampleTypes: IdNameCombination[];
    sampleTypeEnum = SampleType;

    allValidations: string[] = [];

    isGenerating = false;

    constructor(private ds: AuditSamplerDataService) {}

    ngOnInit() {
        this.sampleTypes = [
            { ID: SampleType.number, Name: "Number" },
            { ID: SampleType.date, Name: "Date" },
        ];

        this.sampleModel = new AuditSampleModel();
        this.sampleModel.sampleType = SampleType.number;
        this.formGroup = this.createGroup();
    }

    createGroup(): FormGroup {
        return new FormGroup(
            {
                seedNumber: new FormControl(this.sampleModel.seedNumber, [Validators.required]),
                numberOfSamples: new FormControl(this.sampleModel.numberOfSamples, [
                    Validators.required,
                    Validators.min(1),
                ]),

                testPopulationSize: new FormControl(this.sampleModel.testPopulationSize),
                startDate: new FormControl(this.sampleModel.startDate),
                endDate: new FormControl(this.sampleModel.endDate),
            },
            {
                validators: [(group: FormGroup) => this.validate(group)],
            }
        );
    }

    private validate(group: FormGroup) {
        const testPopulationSizeControl = getFormControl<AuditSampleModel>(
            group,
            (sample) => sample.testPopulationSize
        );
        const startDateControl = getFormControl<AuditSampleModel>(
            group,
            (sample) => sample.startDate
        );
        const endDateControl = getFormControl<AuditSampleModel>(group, (sample) => sample.endDate);

        // Reset all
        startDateControl.setErrors(null);
        endDateControl.setErrors(null);
        testPopulationSizeControl.setErrors(null);
        this.allValidations = [];

        if (this.sampleModel.sampleType === SampleType.number) {
            this.handleNumberValidation(testPopulationSizeControl);
        } else if (this.sampleModel.sampleType === SampleType.date) {
            this.handleDateValidation(startDateControl, endDateControl);
        }

        return {};
    }

    private handleNumberValidation(testPopulationSizeControl: AbstractControl) {
        const testPopulationSize = testPopulationSizeControl.value as number;
        if (testPopulationSizeControl.touched && testPopulationSize <= 0) {
            const minimumValidation = "Test population size must be 1 or higher!";
            this.allValidations.push(minimumValidation);
            testPopulationSizeControl.setErrors({ minimum: minimumValidation });
        }
    }

    private handleDateValidation(
        startDateControl: AbstractControl,
        endDateControl: AbstractControl
    ) {
        const startDate = toDate(startDateControl.value);
        const endDate = toDate(endDateControl.value);

        if (startDateControl.touched && (!startDate || startDate <= 0)) {
            const startValidation = "Start date is required!";
            this.allValidations.push(startValidation);
            startDateControl.setErrors({ required: startValidation });
        } else if (endDateControl.touched && (!endDate || endDate <= 0)) {
            const endValidation = "End date is required!";
            this.allValidations.push(endValidation);
            endDateControl.setErrors({ required: endValidation });
        } else if (startDateControl.touched && endDateControl.touched && startDate > endDate) {
            const endAfterStartValidation = "End date must be after start date";
            this.allValidations.push(endAfterStartValidation);
            startDateControl.setErrors({ startAfterEnd: "Start date must be before end date" });
            endDateControl.setErrors({ endBeforeStart: endAfterStartValidation });
        }
    }

    generate() {
        FormValidationHelper.markAllAsTouched(this.formGroup);
        this.formGroup.updateValueAndValidity();
        FormValidationHelper.mapToModel(this.formGroup, this.sampleModel);

        if (!this.formGroup.valid) {
            return;
        }

        this.isGenerating = true;
        this.ds
            .calculateSample(this.sampleModel)
            .pipe(finalize(() => (this.isGenerating = false)))
            .subscribe((x) => {
                this.generatedSamples = x.join(
                    this.sampleModel.sampleType === SampleType.number ? "; " : "\n"
                );
            });
    }
}
