<div *ngIf="!model || !model.organizationId">Select "Organization" first</div>

<div class="fancy-loader" *ngIf="isLoading && model && model.organizationId"></div>
<control-linkable
    *ngIf="!isLoading && model && model.organizationId"
    #linkable
    displayValueProp="name"
    [(linked)]="linked"
    [(unlinked)]="unlinked"
    [hideActions]="true"
    [orderByValueProperty]="true"
    [allowAddNew]="permissions.canAddControls"
    [disabled]="!permissions.canLinkControls"
    [parentModuleType]="moduleType.ORM"
    [parentId]="model.id"
    [parentOrganizationId]="model.organizationId"
    [parentBusinessDimensions]="model.businessDimensionIds"
    [parentFrameworkDimensions]="model.frameworkDimensionIds"
    (addClicked)="addClicked($event)"
    (linkedChange)="linkedChanged($event)"
    (onReloadClicked)="reloadClicked()"
>
</control-linkable>
