import { Component } from "@angular/core";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { BaseChartWidget } from "../BaseChartWidget";

@Component({
    selector: "embedded-report-widget",
    templateUrl: "./embedded-report-widget.component.html",
    styleUrls: ["./embedded-report-widget.component.scss"],
})
export class EmbeddedReportWidgetComponent extends BaseChartWidget {
    invalidConfig = false;

    waitInput = true;
    reportID: string;

    constructor(sd: StandingdataDataService, tabService: TabService, private pages: Pages) {
        super(tabService, sd);
    }

    public openReport() {
        this.onGlobalOpenClick();
    }

    async load() {
        this.setReportID();
        this.setWaitCheck();
        super.load();
    }

    private setReportID() {
        const reportID = this.getFilter(WidgetFilterKey.Insights.ReportToEmbed);

        this.invalidConfig = !reportID || reportID <= 0;
        this.reportID = reportID;
        this.config.showLoader = false;
    }

    private setWaitCheck() {
        const skipCheck = this.getFilter(WidgetFilterKey.Insights.ReportSkipLoadCheck);
        this.waitInput = !skipCheck;
    }

    protected onFilterChange() {
        super.onFilterChange();
        this.setReportID();
    }

    protected onResize() {}

    protected async onGlobalOpenClick() {
        if (this.reportID) {
            this._tabService.generateTab(this.pages.PowerBIReport, this.reportID);
        }
    }
}
