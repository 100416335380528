import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";

export class AuditRiskAssessmentModel {
    auditGuid: string;
    auditType: number;

    linkedRisks: number[];
    linkedControls: number[];

    riskAssessment: CustomFieldDetailModel[];
}
