import { GenericListConfigMultiLevel } from "@app/shared/models/GenericList/GenericListMultiLevel";
import { RendererType } from "@enums/RendererType";
import { PrintRiskModel } from "@models/processeditor/PrintRiskModel";
import { PrintControlModel } from "@models/processeditor/PrintControlModel";
import { PrintMoiModel } from "@models/processeditor/PrintMoiModel";
import { PrintEventModel } from "@models/processeditor/PrintEventModel";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { RaciItem } from "@models/processeditor/DiagramData";
import { nameof } from "@methods/CommonMethods";

export const createLinkedRiskConfig: (
    includeChildControls: boolean
) => GenericListConfigMultiLevel = (includeChildControls) => {
    let linkedRiskConfig = {
        name: "Linked risks",
        idProp: nameof<PrintRiskModel>("riskID"),
        isSortable: false,
        allowAdd: false,
        allowEdit: false,
        allowDelete: false,
        limitViewTo: 99999,
        hideRefreshButton: true,
        showAll: true,
        hideEmptyResult: true,
        rendererConfig: [
            {
                textColumn: nameof<PrintRiskModel>("grossScore"),
                actionColumn: nameof<PrintRiskModel>("grossScoreColor"),
                hideActionColumn: true,
                type: RendererType.Score,
            },
            {
                textColumn: nameof<PrintRiskModel>("netScore"),
                actionColumn: nameof<PrintRiskModel>("netScoreColor"),
                hideActionColumn: true,
                type: RendererType.Score,
            },
            {
                textColumn: nameof<PrintRiskModel>("overallRiskAssessment"),
                actionColumn: nameof<PrintRiskModel>("overallRiskAssessmentColor"),
                hideActionColumn: true,
                type: RendererType.Score,
            },
        ],
        fields: [
            {
                fieldName: nameof<PrintRiskModel>("identifier"),
                prettyName: "Risk Identifier",
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<PrintRiskModel>("name"),
                prettyName: "Name",
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<PrintRiskModel>("organization"),
                prettyName: "Organization",
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<PrintRiskModel>("owner"),
                prettyName: "Owner",
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<PrintRiskModel>("netScore"),
                prettyName: "Net score",
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<PrintRiskModel>("netScoreColor"),
                hideInOverview: true,
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<PrintRiskModel>("riskAppetite"),
                prettyName: "Risk appetite",
                fieldType: GenericListFieldType.Text,
            },
        ],
    };
    if (includeChildControls) {
        linkedRiskConfig.fields.push({
            fieldName: nameof<PrintRiskModel>("linkedControls"),
            hideInOverview: true,
            fieldType: GenericListFieldType.GenericList,
        });
    }

    return linkedRiskConfig;
};

export const createLinkedControlConfig: () => GenericListConfig = () => ({
    name: "Linked controls",
    idProp: nameof<PrintControlModel>("controlID"),
    isSortable: false,
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    limitViewTo: 99999,
    hideRefreshButton: true,
    hideEmptyResult: true,
    rendererConfig: [
        {
            textColumn: nameof<PrintControlModel>("effectivenessScore"),
            actionColumn: nameof<PrintControlModel>("effectivenessColor"),
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: nameof<PrintControlModel>("latestPeriodScore"),
            actionColumn: nameof<PrintControlModel>("latestPeriodColor"),
            hideActionColumn: true,
            type: RendererType.Score,
        },
    ],
    fields: [
        {
            fieldName: nameof<PrintControlModel>("identifier"),
            prettyName: "Control Identifier",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintControlModel>("name"),
            prettyName: "Name",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintControlModel>("organization"),
            prettyName: "Organization",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintControlModel>("owner"),
            prettyName: "Owner",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintControlModel>("effectivenessScore"),
            prettyName: "Effectiveness score",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintControlModel>("effectivenessColor"),
            hideInOverview: true,
            fieldType: GenericListFieldType.Text,
        },
    ],
});

export const createMoiRenderer: () => RendererConfig[] = () => [
    {
        textColumn: nameof<PrintMoiModel>("progress"),
        type: RendererType.Progress,
    },
];

export const createUnlinkedMOIConfig: () => GenericListConfig = () => ({
    name: "Linked Measure of Improvements",
    idProp: nameof<PrintMoiModel>("moiID"),
    isSortable: false,
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    limitViewTo: 99999,
    hideRefreshButton: true,
    hideEmptyResult: true,
    rendererConfig: createMoiRenderer(),
    fields: [
        {
            fieldName: nameof<PrintMoiModel>("identifier"),
            prettyName: "Moi identifier",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintMoiModel>("name"),
            prettyName: "Name",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintMoiModel>("organizationResponsible"),
            prettyName: "Organization responsbile",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintMoiModel>("status"),
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintMoiModel>("progress"),
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintMoiModel>("type"),
            prettyName: "Moi Type",
            fieldType: GenericListFieldType.Text,
        },
    ],
});

export const createUnlinkedEventConfig: () => GenericListConfig = () => ({
    name: "Linked events",
    idProp: nameof<PrintEventModel>("eventId"),
    isSortable: false,
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    limitViewTo: 99999,
    hideRefreshButton: true,
    hideEmptyResult: true,
    fields: [
        {
            fieldName: nameof<PrintEventModel>("identifier"),
            prettyName: "Event Identifier",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintEventModel>("name"),
            prettyName: "Name",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintEventModel>("organization"),
            prettyName: "Organization",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintEventModel>("netCosts"),
            prettyName: "Net costs",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintEventModel>("status"),
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<PrintEventModel>("dateDetected"),
            prettyName: "Date detected",
            fieldType: GenericListFieldType.Text,
        },
    ],
});

export const createCellRaciConfig: () => GenericListConfig = () => ({
    name: "RACI",
    idProp: nameof<RaciItem>("id"),
    isSortable: false,
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    hideEmptyResult: true,
    fields: [
        {
            fieldName: nameof<RaciItem>("functionName"),
            prettyName: "Function",
            fieldType: GenericListFieldType.Text,
        },
        {
            fieldName: nameof<RaciItem>("isResponsible"),
            prettyName: "R",
            fieldType: GenericListFieldType.CheckBox,
        },
        {
            fieldName: nameof<RaciItem>("isAccountable"),
            prettyName: "A",
            fieldType: GenericListFieldType.CheckBox,
        },
        {
            fieldName: nameof<RaciItem>("isConsulted"),
            prettyName: "C",
            fieldType: GenericListFieldType.CheckBox,
        },
        {
            fieldName: nameof<RaciItem>("isInformed"),
            prettyName: "I",
            fieldType: GenericListFieldType.CheckBox,
        },
    ],
});
