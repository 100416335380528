import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app.constants";
import { EventPermissionModel } from "@models/event/EventPermissionModel";
import { Observable, of } from "rxjs";
import { EventModel } from "@models/event/EventModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { DocumentMethods } from "@methods/DocumentMethods";
import { EventCommentModel } from "../shared/event-comment-model";
import { EventCommentHistoryModel } from "../shared/event-comment-history-model";
import { EventLightStandingDataCollection } from "@models/event/EventLightStandingDataCollection";
import { EventHeavyStandingDataCollection } from "@models/event/EventHeavyStandingDataCollection";
import { EventBusinessStandingDataCollection } from "@models/event/EventBusinessStandingDataCollection";
import { EventWorkflowUpdateModel } from "@models/event/EventWorkflowUpdateModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { EventRiskControlModel } from "../shared/event-risk-control-model";
import { DocumentModel } from "@models/documents/documentmodel";
import { isGuid } from "@methods/uniqueMethods";
import { EventWidgetDataModel } from "@app/event/shared/event-widget-data-model";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";

@Injectable()
export class EventDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "events");
    }

    public getEvents(periodId: number = null): Observable<any[]> {
        const url = periodId ? `/?periodId=${periodId}` : null;

        return this.get<any[]>(url);
    }

    public hasAccess() {
        const url = this.actionUrl + "HasAccess";
        return this.http.get<boolean>(url);
    }

    public getEvent(guid: string): Observable<EventModel> {
        const url = `/${guid}`;
        return this.get<any>(url);
    }

    public getBaseGuidFromPeriodGuid(guid: string) {
        const url = `/${guid}/getbaseguidfromperiodguid`;

        return this.get<string>(url);
    }

    public storeEvent(event: EventModel): Observable<HttpEvent<Object>> {
        const formData = DocumentMethods.convertToFormDataWithFiles("event", event);
        const url = this.actionUrl;
        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");

        const uploadReq = new HttpRequest("POST", url, formData, {
            headers,
            reportProgress: true,
        });

        return this.http.request(uploadReq);
    }

    public getLightSd(guid: string): Observable<EventLightStandingDataCollection> {
        const url = `/${guid}/light`;
        return this.get<any>(url);
    }

    public getHeavySd(guid: string): Observable<EventHeavyStandingDataCollection> {
        const url = `/${guid}/heavy`;
        return this.get<any>(url);
    }

    public getBusinessSd(
        guid: string,
        organizationId: number
    ): Observable<EventBusinessStandingDataCollection> {
        const url = `/${guid}/business/${organizationId}`;
        return this.get<any>(url);
    }

    public getLightSdForNewEvent(): Observable<EventLightStandingDataCollection> {
        const url = "/light";
        return this.get<any>(url);
    }

    public getHeavySdForNewEvent(): Observable<EventHeavyStandingDataCollection> {
        const url = "/heavy";
        return this.get<any>(url);
    }

    public getPermissions(guid: string): Observable<EventPermissionModel> {
        const url = `/${guid}/permissions`;
        return this.get<EventPermissionModel>(url);
    }

    public getPermissionsForNewEvent(): Observable<EventPermissionModel> {
        const url = `/permissions`;
        return this.get<EventPermissionModel>(url);
    }

    public getHistory(eventGuid: string): Observable<HistoryModel[]> {
        const url = `/${eventGuid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(eventGuid: string, historyDate: string): Observable<HistoryChange[]> {
        const url = `/${eventGuid}/history/${historyDate}`;

        return this.get<HistoryChange[]>(url);
    }

    public getComments(eventGuid: string): Observable<EventCommentModel[]> {
        const url = `/${eventGuid}/comments`;
        return this.get<EventCommentModel[]>(url);
    }

    public getCommentHistory(
        eventGuid: string,
        commentId: number
    ): Observable<EventCommentHistoryModel[]> {
        const url = `/${eventGuid}/comments/${commentId}/history`;
        return this.get(url);
    }

    public storeComment(
        eventGuid: string,
        comment: EventCommentModel
    ): Observable<HttpEvent<number>> {
        const url = `/${eventGuid}/comments`;
        return this.post(comment, url);
    }

    public deleteComment(eventGuid: string, commentId: number): Observable<never> {
        const url = `/${eventGuid}/comments/${commentId}`;
        return this.delete(url);
    }

    public getLinkableRisks(
        eventGuid: string,
        organizationId: number
    ): Observable<EventRiskControlModel[]> {
        const url = `/${eventGuid}/risks/${organizationId}`;
        return this.get<EventRiskControlModel[]>(url);
    }

    public getLinkableControls(
        eventGuid: string,
        organizationId: number
    ): Observable<EventRiskControlModel[]> {
        const url = `/${eventGuid}/controls/${organizationId}`;
        return this.get<EventRiskControlModel[]>(url);
    }

    public updateStatus(
        guid: string,
        model: EventWorkflowUpdateModel
    ): Observable<HttpEvent<Object>> {
        const url = `/${guid}/workflow`;
        return this.post(model, url);
    }

    public getFailureTypes(): Observable<IdNameCombination[]> {
        const url = "/failureTypes";

        return this.get<IdNameCombination[]>(url);
    }

    public deleteEvent(eventGuid: string): Observable<string[]> {
        const url = `/${eventGuid}`;
        return this.delete(url);
    }

    public isEventApprovable(eventGuid: string): Observable<boolean> {
        const url = `/${eventGuid}/isapprovable`;
        return this.get<boolean>(url);
    }

    public getDocuments(eventGuid: string): Observable<DocumentModel[]> {
        const url = `/${eventGuid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(eventGuid: string): Observable<HyperlinkModel[]> {
        const url = `/${eventGuid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public hasAccessToEvent(guid: string) {
        if (isGuid(guid)) {
            const url = `/hasaccess/${guid}`;
            return this.get<boolean>(url);
        }

        return of(false);
    }

    public getWidgetInfo(filters: any) {
        const url = `/widgetinfo`;
        return this.post<EventWidgetDataModel>(filters, url);
    }

    getCustomFields(): Observable<CustomFieldStandingDataModel[]> {
        const url = `/customfields`;
        return this.get<CustomFieldStandingDataModel[]>(url);
    }
}
