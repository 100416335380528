import { Component, Input, OnInit } from "@angular/core";
import { FormField } from "@app/forms/models/detail/FormField";

@Component({
    selector: "form-entry-info",
    templateUrl: "./form-entry-info.component.html",
    styleUrls: ["../../form-entry-field.component.scss"],
})
export class FormEntryInfoComponent implements OnInit {
    @Input() field: FormField;

    ngOnInit() {
        this.field.Required = false;
    }
}
