import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { SimpleTestingType } from "../../testing/simple/enums/SimpleTestingType";
import { ControlChartData } from "./ControlChartData";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { Observable } from "rxjs";

@Injectable()
export class ControlChartDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    getSimpleChart(type: SimpleTestingType, guid: string): Observable<ControlChartData> {
        const url = `/${guid}/testing/simple/charts/${type}`;
        return this.get<ControlChartData>(url);
    }

    getAdvancedPeriods(guid: string): Observable<IdNameColorModel[]> {
        const url = `/${guid}/testing/advanced/charts/periods`;
        return this.get<IdNameColorModel[]>(url);
    }

    getAdvancedChart(periodID: number, guid: string): Observable<ControlChartData> {
        const url = `/${guid}/testing/advanced/charts/${periodID}`;
        return this.get<ControlChartData>(url);
    }
}
