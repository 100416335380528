import { Component, Input } from "@angular/core";
import { ControlModel } from "@app/controls/models/ControlModel";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { Observable } from "rxjs";
import { LinkModel } from "@models/generic/LinkModel";

@Component({
    selector: 'control-events',
    templateUrl: './control-events.component.html',
    styleUrls: ['./control-events.component.scss']
})
export class ControlEventComponent {
    @Input() controlModel: ControlModel;
    @Input() disabled = true;

    linked: LinkModel[] = [];
    unlinked: LinkModel[] = [];

    isLoading = true;

    hasAccessChecks = {};
    accessCheckInProgress = false;
    displayValueProp: string;

    constructor(private _controlDS: ControlDataService) { }

    load(): void {
        this.getLinkedEvents(this.controlModel.Guid).subscribe(data => {
            this.loadData(data);
            this.isLoading = false;
        });
    }

    loadData(data: LinkModel[]): void {
        const linked = [];
        data.forEach(item => {
            linked.push(item);
        });
        this.linked = linked;
    }

    getLinkedEvents(controlGuid: string): Observable<LinkModel[]> {
        return this._controlDS.getLinkableEvents(controlGuid);
    }
}
