export enum MoiStatusType {
    None = 0,

    Unconfirmed = 1,
    UnconfirmedDescription = "Unconfirmed",

    InProgress = 2,
    InProgressDescription = "In progress",

    ReadyForAcceptance = 3,
    ReadyForAcceptanceDescription = "Ready for acceptance",

    Accepted = 4,
    AcceptedDescription = "Accepted",

    Rejected = 5,
    RejectedDescription = "Rejected",
    
    Closed = 6,
    ClosedDescription = "Closed",

    MoiAdded = 7,
    Declined = 8,
    DeclinedDescription = "Declined"
}