<ng-template #workflowTemplate>
    <div class="loader-container" *ngIf="!steps" style="height: 40px; transform: scale(0.4)">
        <div class="fancy-loader submodule"></div>
    </div>

    <div class="cerrix-workflow-container" *ngIf="steps">
        <div class="cerrix-workflow-graph">
            <div class="workflow-step-spacer"></div>
            <ng-container *ngFor="let step of steps; let i = index">
                <div
                    class="workflow-step"
                    [class.done]="step.stepDone"
                    [class.active]="step.stepActive"
                    [class.overdue]="step.isStepToDoverdue"
                    (click)="onStepClick(step)"
                    triggers="mouseenter:mouseleave"
                    placement="bottom"
                    [popover]="step.stepToDoDescription ? step.stepToDoDescription : ''"
                ></div>
                <div
                    class="workflow-connector"
                    [class.done]="step.stepDone"
                    *ngIf="i < steps.length - 1"
                >
                    <div class="workflow-connector-icon"></div>
                    <div
                        class="workflow-connector-text"
                        triggers="mouseenter:mouseleave"
                        placement="bottom"
                        [popover]="
                            step.stepDoneHoverText ? step.stepDoneHoverText : step.stepDoneText
                        "
                    >
                        {{ step.stepDoneText }}
                    </div>
                </div>
            </ng-container>
            <div class="workflow-step-spacer"></div>
        </div>

        <div class="cerrix-workflow-text">
            <div
                [style]="'width: calc(100% / ' + steps.length + ')'"
                class="workflow-step-text"
                triggers="mouseenter:mouseleave"
                placement="bottom"
                [popover]="step.stepDescription ? step.stepDescription : step.stepName"
                *ngFor="let step of steps"
            >
                {{ step.stepName }}
            </div>
        </div>
    </div>
</ng-template>

<div [class.highlight-background]="config.highlightBackground" *ngIf="config">
    <ng-container [ngTemplateOutlet]="workflowTemplate"></ng-container>
</div>
