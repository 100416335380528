import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NotificationSettingsService } from "@app/mailmessages/service/notification-settings.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { toPromise } from "@methods/CommonMethods";
import { NotificationSettingsType } from "./notification-settings-type.enum";

@Component({
    selector: "notification-settings",
    templateUrl: "./notification-settings.component.html",
    styleUrls: ["./notification-settings.component.scss"],
})
export class NotificationSettingsComponent implements OnInit {
    @Input() parentTab: TabModel;
    slackToken: string;

    constructor(
        private settingsService: NotificationSettingsService,
        private toastr: ToastrService
    ) {}

    async ngOnInit() {
        if (!this.parentTab.showLoader) {
            this.parentTab.showLoader = true;
        }

        const settings = await toPromise(this.settingsService.getSettings());

        settings.forEach((setting) => {
            switch (setting.Type) {
                case NotificationSettingsType.SlackToken: // Slack Token
                    this.slackToken = setting.Value;
                    break;
            }
        });

        this.parentTab.showLoader = false;
    }

    async saveSettings() {
        if (!this.parentTab.showLoader) {
            this.parentTab.showLoader = true;
        }

        const settings = [
            {
                Type: NotificationSettingsType.SlackToken,
                Value: this.slackToken,
            },
        ];

        await toPromise(this.settingsService.storeSettings(settings));

        this.toastr.success("Settings saved successfully");
        this.parentTab.showLoader = false;
    }

    async syncAllUsers() {
        await toPromise(this.settingsService.syncAllUsers());
        this.toastr.success("User synced successfully.");
    }
}
