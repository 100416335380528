import { Injectable, inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { IncidentService } from "./incident.service";
import { IncidentCommentType } from "../models/incident-comment-type.enum";
import { IncidentCommentAddModel } from "../models/incident-comment-add.model";
import { IncidentCommentModel } from "../models/incident-comment.model";
import { BaseChatService } from "@app/shared/components/chat/services/base-chat.service";
import { ChatCommentModel } from "@app/shared/components/chat/models/chat-comment-model";
import { UserExtensions } from "@cerrix/components";

@Injectable({
    providedIn: "root",
})
export class IncidentChatService extends BaseChatService {
    private incidentService = inject(IncidentService);

    getComments(entityId: string): Observable<ChatCommentModel[]> {
        return this.incidentService
            .getComments(entityId)
            .pipe(map((comments) => comments.map((ic) => this.getMappedChatComment(ic))));
    }

    addComment(entityId: string, commentText: string): Observable<ChatCommentModel> {
        const comment: IncidentCommentAddModel = { value: commentText };
        return this.incidentService
            .addComment(entityId, comment)
            .pipe(map((ic) => this.getMappedChatComment(ic)));
    }

    private getMappedChatComment(ic: IncidentCommentModel): ChatCommentModel {
        return {
            id: ic.id,
            value: ic.value,
            userId: ic.userId,
            userName: ic.userFirstName + " " + ic.userLastName,
            createdOn: ic.createdOn,
            initials: UserExtensions.generateInitials(ic.userFirstName, ic.userLastName),
            commentType: IncidentCommentType[ic.commentType],
            commentTypeDescription: this.getCommentTypeDescription(
                ic.commentType,
                ic.userFirstName + " " + ic.userLastName
            ),
            commentClass: ic.commentType === IncidentCommentType.Rejected ? "warning" : null,
        };
    }

    private getCommentTypeDescription(commentType: IncidentCommentType, userName: string): string {
        switch (commentType) {
            case IncidentCommentType.Created:
                return userName + " " + "created the incident";
            case IncidentCommentType.Accepted:
                return userName + " " + "accepted the new incident";
            case IncidentCommentType.Rejected:
                return userName + " " + "rejected the new incident";
            case IncidentCommentType.ReadyForApproval:
                return userName + " " + "prepared the incident for review";
            case IncidentCommentType.Approved:
                return userName + " " + "reviewed and approved the incident";
            case IncidentCommentType.NotApproved:
                return userName + " " + "reviewed and disapproved the incident";
            case IncidentCommentType.Reopened:
                return userName + " " + "reopened the approved and closed incident";
            case IncidentCommentType.ReopenRejected:
                return userName + " " + "reopened the rejected and closed incident";

            default:
                return null;
        }
    }
}
