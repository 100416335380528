import { Component } from "@angular/core";
import { ExportDataService } from "@app/export/services/export-data.service";
import { ExportBase } from "../export-base";

@Component({
    selector: "export-controls",
    templateUrl: "./export-controls.component.html",
    styleUrls: ["./export-controls.component.scss"],
})
export class ExportControlsComponent extends ExportBase {
    includeMois = false;
    constructor(exportDataService: ExportDataService) {
        super(exportDataService);
    }
}
