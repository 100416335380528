import { Component } from "@angular/core";
import { StandingDataView } from "../standing-data-view";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ToastrService } from "ngx-toastr";
import { CellRendererPipe } from "@app/shared/pipes/cell-renderer.pipe";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "standing-data-list-view",
    templateUrl: "./standing-data-list-view.component.html",
    styleUrls: ["./standing-data-list-view.component.scss"],
})
export class StandingDataListViewComponent extends StandingDataView {
    data: any[];
    dataLoading = false;

    currentlyDragging: any;
    currentlyDraggingOver: any;
    dropAbove = false;
    dropBelow = false;

    sortLoading = false;
    sortMessage = "";

    searchedData: any[];
    searchFilter = "";

    constructor(
        dataService: StandingdataDataService,
        private _toastr: ToastrService,
        public cellRenderer: CellRendererPipe,
        public _promptService: CerrixPromptService
    ) {
        super(dataService, cellRenderer, _promptService);
    }

    loadData(selected?: number) {
        this.data = null;
        this.dataLoading = true;
        this.dataService.getMaintenanceListByType(this.standingDataType).subscribe((x) => {
            this.data = x;
            this.search();

            this.dataLoading = false;
            this.selected = selected;

            this.onDataLoaded.emit(this.data);
        });
    }

    search() {
        const keywords = this.searchFilter.toLowerCase().split(" ");
        this.searchedData = this.data.filter((v) => {
            const vName = v.Name.toLowerCase();
            return keywords.some((kw) => vName.indexOf(kw) >= 0);
        });
    }

    resetSearchFilter() {
        this.searchFilter = "";
        this.searchedData = this.data;
    }

    toggleSortMode() {
        this.editMode = !this.editMode;

        // If we go out of ordering mode, research so we get the correct sort order of all items.
        if (!this.editMode) {
            this.search();
        }
    }

    dragStart(item: any, ev) {
        item.show = false;
        this.currentlyDragging = item;
        ev.stopPropagation();
    }

    dragStop(item: any, ev) {
        if (this.currentlyDragging && item.ID === this.currentlyDragging.ID) {
            item.show = true;
            this.currentlyDragging = null;
            this.dragEnter(null);
            this.dropBelow = this.dropBelow = false;
        }

        ev.stopPropagation();
    }

    async dragDrop(target: any, ev) {
        if (this.currentlyDragging == null || target.ID === this.currentlyDragging.ID) {
            return;
        }
        this.sortMessage = "";
        this.sortLoading = true;

        // Make copy of some variables, because DragDrop lifecycle will call dropStop eventually after this method
        // which causes these values to be reset.
        const dropBelow = this.dropBelow;
        const movingObj = this.currentlyDragging;
        const currentIndex = this.data.indexOf(movingObj);
        let targetIndex = this.data.indexOf(target) + (dropBelow ? 1 : 0);

        this.sortMessage = `Moving '${movingObj.Name}' to position ${targetIndex + 1}`;

        const updateResult = await this.dataService
            .sortByType(this.standingDataType, movingObj.ID, targetIndex)
            .toPromise();

        if (updateResult) {
            this._toastr.error(
                updateResult,
                ("" + this.sortMessage).replace("Moving", "Failed to move")
            );
            this.sortLoading = false;
            return;
        }

        this.data.splice(currentIndex, 1);

        if (targetIndex > currentIndex) {
            targetIndex -= 1;
        }
        this.data.splice(targetIndex, 0, movingObj);

        this._toastr.success(("" + this.sortMessage).replace("Moving", "Moved"), "Success");
        this.animateMovedNode(movingObj);
        this.sortLoading = false;
    }

    dragEnter(item: any) {
        if (this.currentlyDraggingOver) {
            if (item && this.currentlyDraggingOver.ID === item.ID) {
                return;
            }

            this.currentlyDraggingOver.highlight = false;
            this.currentlyDraggingOver = null;
        }

        if (item) {
            item.highlight = true;
            this.currentlyDraggingOver = item;
        }
    }

    dragOver(ev) {
        const target = this.getNodeElem(ev.target);
        const sectionHeight = target.offsetHeight;
        const cursorLocation = ev.offsetY;

        this.dropAbove = this.dropBelow = false;
        if (cursorLocation <= sectionHeight / 2) {
            this.dropAbove = true;
        } else {
            this.dropBelow = true;
        }
    }

    getNodeElem(target) {
        let iteration = null;
        let stop = false;
        while (!stop) {
            iteration = iteration ? iteration.parentElement : target;
            if (!iteration || iteration.classList.contains("item")) {
                stop = true;
            }
        }

        return iteration;
    }

    animateMovedNode(item: any) {
        item["newlocation"] = true;
        setTimeout(() => {
            item["newlocation"] = false;
        }, 1000);
    }
}
