<eff-expansion-panel
    [contentTemplate]="content"
    [panelOpenState]="panelOpenState"
    title="Evidence upload instructions"
    class="eff-details-panel"
>
    <ng-template #content>
        <div class="main-container">
            <div class="d-flex flex-column details-container">
                <div class="regular-text multiple-whitespaces" [innerHTML]="instructions"></div>
            </div>
            <div
                class="steps-container"
                *ngIf="
                    samples &&
                    samples.length > 0 &&
                    samples[0].testSteps &&
                    samples[0].testSteps.length > 0
                "
            >
                <eff-expansion-panel
                    [panelOpenState]="true"
                    class="test-step-panel"
                    *ngFor="let testStep of samples[0].testSteps"
                    [contentTemplate]="stepContent"
                    [title]="'Step ' + testStep.name"
                    data-uiid="new-eff-upload-source-document-evidence-upload-instructions-info-box"
                >
                    <ng-template #stepContent>
                        <div class="step-rows">
                            <div class="step-row">
                                <div class="bold-text-small">Test Method:</div>
                                <div class="regular-text-small">{{ testStep.testMethod }}</div>
                            </div>
                            <div class="step-row">
                                <div class="bold-text-small">Description:</div>
                                <div
                                    class="regular-text-small multiple-whitespaces"
                                    [innerHTML]="testStep.description"
                                ></div>
                            </div>
                            <div class="step-row">
                                <div class="bold-text-small">Required documents:</div>
                                <div class="regular-text-small">
                                    {{
                                        testStep.requiredDocuments
                                            ? testStep.requiredDocuments
                                            : "-"
                                    }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </eff-expansion-panel>
            </div>
        </div>
    </ng-template>
</eff-expansion-panel>
