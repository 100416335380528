import { Component, Input, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NavigatorConfigType } from "@app/navigators/enums/NavigatorConfigType";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { NavigatorAssessmentDetailModel } from "@app/navigators/models/navigator-assessment-detail-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { of } from "rxjs";
import { BusinessNavigatorReviewModel } from "@app/navigators/business-navigator/models/business-navigator-review-model";

@Component({
    selector: "business-review",
    templateUrl: "./business-review.component.html",
})
export class BusinessReviewComponent implements OnInit {
    @Input() cerrixTab: TabModel;
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissionsModel: BusinessNavigatorPermissionModel;

    reviewModel: BusinessNavigatorReviewModel;
    config: GenericListConfig;

    assessmentDetailModel: NavigatorAssessmentDetailModel;

    formGroup: FormGroup;

    minDate = new Date();

    reviewsConfig: GenericListConfig;
    isSavingAssessment = false;

    constructor(
        private _ds: BusinessNavigatorDataService,
        private _toastr: ToastrService,
        private _navigatorFactory: NavGenericListFactory,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this.getAssessmentDetails();
        this.loadReviews();
    }

    async saveAssessmentConfiguration(): Promise<boolean> {
        FormValidationHelper.markAllAsTouched(this.formGroup);
        const validationErrors = FormValidationHelper.getFormControlErrors(this.formGroup);
        if (validationErrors && Object.keys(validationErrors).length > 0) {
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

            return false;
        }
        if (!this.formGroup.dirty) {
            this._toastr.info("No changes");
            return;
        }

        FormValidationHelper.mapToModel(this.formGroup, this.assessmentDetailModel);

        this.isSavingAssessment = true;
        this._ds
            .storeAssessmentDetails(this.requestModel, this.assessmentDetailModel)
            .then((assDetail) => {
                this.onAssessmentConfigurationSaved(assDetail);
            })
            .catch((_) => {
                this.isSavingAssessment = false;
            });
    }

    private onAssessmentConfigurationSaved(savedAssDetail: NavigatorAssessmentDetailModel): void {
        this._toastr.success("Configuration saved successfully", "Success.");
        if (this.requestModel.businessAssessmentID <= 0) {
            this.cerrixTab.config = <BusinessNavigatorRequestModel>{
                organizationID: this.requestModel.organizationID,
                businessID: this.requestModel.businessID,
            };
            this.cerrixTab.refresh();
        } else {
            this.isSavingAssessment = false;
        }

        this.assessmentDetailModel = savedAssDetail;
        this.requestModel.businessAssessmentID = savedAssDetail.assessmentId;

        // Refresh model
        this.getAssessmentDetails();

        // Refresh review permissions
        this._ds.isReviewAllowed(this.requestModel).then((isAllowed: boolean) => {
            this.permissionsModel.canReview = isAllowed;

            this.reviewsConfig.allowAdd = this.permissionsModel.canReview;
            this.reviewsConfig.allowEdit = this.permissionsModel.canReview;
            this.reviewsConfig.allowDelete = this.permissionsModel.canReview;

            this.reviewsConfig.reload();
        });
    }

    private getAssessmentDetails(): void {
        this._ds
            .getAssessmentDetails(this.requestModel)
            .then((reviewConfiguration: NavigatorAssessmentDetailModel) => {
                this.assessmentDetailModel = reviewConfiguration;

                this.initConfigurationFormGroup();

                if (!this.reviewsConfig) {
                    this.loadReviews();
                }
            });
    }

    private initConfigurationFormGroup(): void {
        this.formGroup = new FormGroup({
            reviewers: new FormControl(this.assessmentDetailModel.reviewers, Validators.required),
            frequency: new FormControl(this.assessmentDetailModel.frequency, Validators.required),
            initialReviewDate: new FormControl(
                this.assessmentDetailModel.initialReviewDate,
                Validators.required
            ),
            nextReviewDate: new FormControl({
                value: this.assessmentDetailModel.nextReviewDate,
                disabled: true,
            }),
        });

        if (this.permissionsModel && !this.permissionsModel.canEdit) {
            this.formGroup.disable();
        }
    }

    private loadReviews(): void {
        if (this.requestModel.businessAssessmentID != null) {
            this._ds.getReviews(this.requestModel).then((reviews: any) => {
                if (!this.reviewsConfig) {
                    this.initReviewsList(reviews);
                } else {
                    this.reviewsConfig.dataMethod = () => of(reviews);
                    this.reviewsConfig.reload();
                }
            });
        }
    }

    private initReviewsList(reviews: BusinessNavigatorReviewModel[]): void {
        const reviewsConfig = this._navigatorFactory.createConfig(
            NavigatorConfigType.BusinessReview
        );
        reviewsConfig.dataMethod = () => of(reviews);
        reviewsConfig.add = async (row: BusinessNavigatorReviewModel) => {
            await this.storeReview(row);
            return true;
        };
        reviewsConfig.edit = async (row: BusinessNavigatorReviewModel) => {
            await this.storeReview(row);
            return true;
        };
        reviewsConfig.delete = async (row: BusinessNavigatorReviewModel) => {
            await this.deleteReview(row);
            return true;
        };

        reviewsConfig.allowAdd = this.permissionsModel.canReview;
        reviewsConfig.allowEdit = this.permissionsModel.canReview;
        reviewsConfig.allowDelete = this.permissionsModel.canReview;

        this.reviewsConfig = reviewsConfig;
    }

    private async storeReview(row: BusinessNavigatorReviewModel): Promise<boolean> {
        let success = false;

        if (new Date() < this.assessmentDetailModel.initialReviewDate) {
            const confirmed = await this._promptService
                .confirmCustom({
                    data: {
                        title: "Early save date",
                        message:
                            "Saving date is earlier than the initial review date. Should the next review date be changed?",
                        confirmButton: { text: "Yes" },
                        cancelButton: { text: "No" },
                    },
                })
                .toPromise();

            if (confirmed) {
                row.updateNextReviewDate = true;
            }
        }

        await this._ds
            .storeReview(this.requestModel, row)
            .then(() => {
                success = true;
                this._toastr.success("Review saved successfully", "Success");
                this.loadReviews();
            })
            .catch(() => {
                success = false;
            });
        return success;
    }

    private async deleteReview(row: BusinessNavigatorReviewModel): Promise<boolean> {
        let success = false;

        await this._ds
            .deleteReview(this.requestModel, row.id)
            .then(() => {
                success = true;
                this.reviewsConfig.reload();
                this._toastr.success("Review deleted successfully", "Success");
            })
            .catch(() => {
                success = false;
            });
        return success;
    }
}
