import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { ProcessEditorModule } from "@app/process-editor/process-editor.module";

import { BusinessNavigatorDataService } from "./services/business-navigator-data.service";
import { BusinessNavigatorDetailComponent } from "./business-navigator-detail/business-navigator-detail.component";
import { BusinessControlsComponent } from "./business-navigator-detail/components/business-controls/business-controls.component";
import { BusinessRisksComponent } from "./business-navigator-detail/components/business-risks/risks-overview/business-risks.component";
import { BusinessDataprocessingComponent } from "./business-navigator-detail/components/business-dataprocessing/business-dataprocessing.component";
import { BusinessEventsComponent } from "./business-navigator-detail/components/business-events/business-events.component";
import { BusinessKrisComponent } from "./business-navigator-detail/components/business-kris/business-kris.component";
import { BusinessMoisComponent } from "./business-navigator-detail/components/business-mois/business-mois.component";
import { BusinessDetailsComponent } from "./business-navigator-detail/components/business-details/business-details.component";
import { BusinessDocumentsComponent } from "./business-navigator-detail/components/business-documents/business-documents.component";
import { BusinessThirdPartiesComponent } from "./business-navigator-detail/components/business-thirdparties/business-thirdparties.component";
import { BusinessReviewComponent } from "./business-navigator-detail/components/business-review/business-review.component";
import { BusinessNavigatorOverviewComponent } from "./business-navigator-overview/business-navigator-overview.component";
import { BusinessRisksWidgetComponent } from "./business-navigator-detail/components/business-risks/risks-widget/business-risks-widget.component";
import { BusinessHyperlinksComponent } from "./business-navigator-detail/components/business-hyperlinks/business-hyperlinks.component";
import { BusinessAssessmentDocumentsComponent } from './business-navigator-detail/components/business-assessment-documents/business-assessment-documents.component';

@NgModule({
    declarations: [
        BusinessNavigatorOverviewComponent,
        BusinessNavigatorDetailComponent,

        BusinessControlsComponent,
        BusinessRisksComponent,
        BusinessRisksWidgetComponent,
        BusinessDataprocessingComponent,
        BusinessEventsComponent,
        BusinessKrisComponent,
        BusinessMoisComponent,
        BusinessThirdPartiesComponent,
        BusinessHyperlinksComponent,

        BusinessReviewComponent,
        BusinessDetailsComponent,
        BusinessDocumentsComponent,
        BusinessAssessmentDocumentsComponent,
    ],
    imports: [CommonModule, SharedModule, ProcessEditorModule],
})
export class BusinessNavigatorModule {
    static forRoot(): ModuleWithProviders<BusinessNavigatorModule> {
        return {
            ngModule: BusinessNavigatorModule,
            providers: [BusinessNavigatorDataService],
        };
    }
}
