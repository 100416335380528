import { Component, OnInit, ViewChild } from "@angular/core";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FormStatusComponent } from "./status-overview/form-status.component";
import { FormPermissionModel } from "@models/permissions/FormPermissionModel";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { toPromise } from "@methods/CommonMethods";

@Component({
    selector: "form-home",
    templateUrl: "./form-home.component.html",
    styleUrls: ["./form-home.component.scss"],
})
export class FormHomeComponent implements OnInit {
    @ViewChild("status") status: FormStatusComponent;

    tab: TabModel;

    categories: IdNameCombination[];
    permissions = new FormPermissionModel();

    constructor(private _formDS: FormsDataService, public permService: PermissionsService) {
        this.permissions = permService.permissions.Forms;
    }

    async ngOnInit() {
        this.categories = await toPromise(this._formDS.getOverviewCategories());
        this.tab.showLoader = false;
    }
}
