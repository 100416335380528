<div class="detail-page-card">
    <div class="detail-page-card-title">Linked Third Party</div>
    <div class="detail-page-card-actions" *ngIf="summaryModel">
        <button
            class="btn btn-raised"
            (click)="openThirdParty()"
            *ngIf="summaryModel.CanOpenParent"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Open Third Party</span>
        </button>
    </div>
    <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
        <div class="fancy-loader submodule my-4" *ngIf="!summaryModel"></div>
        <div *ngIf="summaryModel">
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label>Identifier</label>
                        <input
                            data-uiid="tp-moi-identifier"
                            type="text"
                            class="form-control form-control-sm"
                            disabled
                            [value]="summaryModel.Identifier"
                            inputTooltip
                        />
                    </div>
                </div>
                <div class="col-9">
                    <div class="form-group">
                        <label>Name Third Party</label>
                        <input
                            data-uiid="tp-moi-name"
                            type="text"
                            class="form-control form-control-sm"
                            disabled
                            [value]="summaryModel.Name"
                            inputTooltip
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Description</label>
                <textarea
                    data-uiid="tp-moi-description"
                    class="form-control form-control-sm"
                    disabled
                    [value]="summaryModel.Description"
                    inputTooltip
                ></textarea>
            </div>
        </div>
    </div>
</div>
