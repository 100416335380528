import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { ControlEffWidgetDataModel } from "../shared/models/ControlEffWidgetDataModel";
import { DesignImplementationDashboardModel } from "../shared/models/DesignImplementationDashboardModel";
import { Observable } from "rxjs";

@Injectable()
export class ControlsWidgetsDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    public getEffectivenessWidgetInfo(filters: any) {
        const url = `/testing/simple/effectiveness/widgets/info`;
        return this.post<ControlEffWidgetDataModel[]>(filters, url);
    }

    public getDesignImplementationWidgetInfo(filters: any) {
        const url = `/testing/simple/designimplementation/widgets/info`;
        return this.post<DesignImplementationDashboardModel[]>(filters, url);
    }

    public getSimpleOverdueWidgetInfo(filters: any) {
        const url = `/testing/simple/widgets/overdue`;
        return this.post<ControlEffWidgetDataModel[]>(filters, url);
    }

    public getOverdueEffectivenessForWidget(filters: {}): Observable<ControlEffWidgetDataModel[]> {
        const url = `/testing/advanced/effectiveness/widgets/overdue`;

        return this.post<ControlEffWidgetDataModel[]>(filters, url);
    }
}
