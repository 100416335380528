<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    module="document"
    appendToModuleType="bulk-deleted"
    [moduleType]="moduleType"
    idProp="guid"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [buttonConfig]="buttonConfig"
    [NumberProperties]="numberProps"
    [DateProperties]="dateProps"
>
</cerrix-workspace>
