import { Injectable } from "@angular/core";
import { AuthenticationConfiguration } from "@app/authentication/shared/authentication-configuration";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AppConfigService {
    private authenticationConfiguration: AuthenticationConfiguration;
    private httpClient: HttpClient;

    get authenticationConfig(): AuthenticationConfiguration {
        return this.authenticationConfiguration;
    }

    constructor(httpBackend: HttpBackend) {
        // Initialize HttpClient to get around interceptors loading dependend services
        this.httpClient = new HttpClient(httpBackend);
    }

    public initialize(): Promise<AuthenticationConfiguration> {
        return this.getAuthenticationConfiguration();
    }

    public getAuthenticationConfiguration(): Promise<AuthenticationConfiguration> {
        return this.httpClient
            .get<AuthenticationConfiguration>("/api/user/authentication/config")
            .pipe(
                map((value) => {
                    this.authenticationConfiguration = value;
                    return value;
                })
            )
            .toPromise();
    }
}
