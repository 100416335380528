import { Component } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: 'devmanagement',
    templateUrl: './devmanagement.component.html',
    styleUrls: ['./devmanagement.component.scss']
})
export class DevManagementComponent {
    tab: TabModel;
}