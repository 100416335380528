<div class="btn-group">
    <button class="navbar-button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="limited-text" *ngIf="userInfo">
            {{ userInfo.firstname }} {{ userInfo.lastname }}
        </span>
        <i class="fas fa-user-circle accent-color"></i>
    </button>
    <div class="dropdown-menu">
        <div class="user-dropdown-menu" *ngIf="userInfo">
            <div class="top-row">
                <div class="user-org limited-text" [title]="userInfo.organization">
                    <span class="limited-text">{{ userInfo.organization }}</span>
                </div>
                <div class="filler"></div>
                <div
                    class="app-theme"
                    [title]="
                        _themeService.currentTheme == themes.systemdefault
                            ? 'System default'
                            : _themeService.currentTheme == themes.light
                            ? 'Light mode'
                            : 'Dark mode'
                    "
                    (click)="_themeService.cycleTheme(); $event.stopPropagation()"
                >
                    <i
                        class="fas fa-{{
                            _themeService.currentTheme == themes.systemdefault
                                ? 'eclipse'
                                : _themeService.currentTheme == themes.light
                                ? 'sun'
                                : 'moon'
                        }}"
                    ></i>
                </div>
                <div class="user-sign-out" (click)="signOut()">Sign out</div>
            </div>
            <div class="user-info">
                <div class="user-image">
                    <i class="fas fa-user-circle accent-color"></i>
                </div>
                <div class="user-data">
                    <div
                        class="user-fullname limited-text"
                        [title]="userInfo.firstname + ' ' + userInfo.lastname"
                    >
                        <span>{{ userInfo.firstname }} {{ userInfo.lastname }}</span>
                    </div>
                    <div class="user-email limited-text" [title]="userInfo.email">
                        <span>{{ userInfo.email }}</span>
                    </div>
                    <div class="user-profile-btn" (click)="openProfile()">View profile</div>
                </div>
            </div>
        </div>
    </div>
</div>
