import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ToastrService } from "ngx-toastr";
import { GenericListConfigMultiLevel } from "@app/shared/models/GenericList/GenericListMultiLevel";
import { Configuration } from "@app/app.constants";
import { GenericListManagerComponent } from "@app/shared/generic-managers/generic-list-manager/generic-list-manager.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { of } from "rxjs";

@Component({
    selector: "cerrix-table-multi-level",
    templateUrl: "./cerrix-table-multi-level.component.html",
    styleUrls: ["./cerrix-table-multi-level.component.scss"],
})
export class CerrixTableMultiLevelComponent extends GenericListManagerComponent implements OnInit {
    @Input()
    config: GenericListConfigMultiLevel;
    activeRow: any;

    constructor(
        standingdataDS: StandingdataDataService,
        toastr: ToastrService,
        configuration: Configuration,
        bsModalService: BsModalService,
        promptService: CerrixPromptService
    ) {
        super(standingdataDS, toastr, configuration, bsModalService, promptService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    triggerClick(row: any) {
        this.activeRow = row;
    }

    setScopedTable() {
        super.setScopedTable();
        this.scopedTableData.forEach((d) => (d.showEditor = this.config.showAll));
    }

    getLinkedIds(row) {
        this.scopedTableData.filter((a) => a !== row).forEach((x) => (x.showEditor = false));
    }

    setupInnerconfig(rowData) {
        const copy = { ...this.config.innerConfig };
        const data = rowData[this.config.innerListProp];

        if (!data) {
            return null;
        }

        copy.data = data;
        return copy;
    }
}
