import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { KeyValue } from "@models/generic/KeyValuePair";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { parseTyped } from "@methods/CommonMethods";
import { IncidentMapbackService } from "@app/incident/services/incident-mapback.service";
import { StandingDataItemModel } from "@app/standing-data/models/standing-data-item.model";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { NewIncidentStructureTypes } from "@app/forms/shared/constants/NewIncidentStructureTypes";

@Component({
    selector: "form-detail-structure",
    templateUrl: "./form-detail-structure.component.html",
    styleUrls: ["./form-detail-structure.component.scss"],
})
export class FormDetailStructureComponent implements OnInit {
    @Input() field: FormField;
    @Input() resultId: number;

    structureSettings: FormFieldConfig;
    sources: KeyValue<number, string>[];

    lastLoadedSource: number;
    isTreeSource = false;

    multiselectLocked = false;
    sourceLocked = false;
    parametersLocked = false;

    useIncidents: boolean = false;

    constructor(
        private _formDS: FormsDataService,
        private _mapbackService: IncidentMapbackService,
        private _settingsService: SettingsDataService
    ) {
        _formDS.getStructureSources().then((x) => {
            this.sources = x;
        });
    }

    async ngOnInit() {
        this.useIncidents = (
            await this._settingsService.getSetting(ApplicationSettings.UseIncidents).toPromise()
        ).BoolValue;

        this.structureSettings = parseTyped<FormFieldConfig>(
            this.field.CustomSettings,
            new FormFieldConfig()
        );
        await this.getSourceData();
    }

    async applyChange() {
        await this.getSourceData();
        this.saveModel();
    }

    saveModel() {
        this.field.CustomSettings = JSON.stringify(this.structureSettings);
    }

    async getSourceData() {
        if (
            this.structureSettings &&
            this.structureSettings.structureType &&
            this.lastLoadedSource !== this.structureSettings.structureType
        ) {
            this.lastLoadedSource = this.structureSettings.structureType;
            this.field.SourceData = null;

            const callModel = <FormFieldConfig>{
                isAdminRequest: true,
                structureType: this.structureSettings.structureType,
            };

            let structureData;
            if (this.useIncidents) {
                // get incident structure data
                if (
                    NewIncidentStructureTypes.newIncidentStructures.find(
                        (x) => x == this.structureSettings.structureType
                    )
                ) {
                    const incidentStructureData = await this._mapbackService.getStructureForMapback(
                        this.structureSettings.structureType
                    );
                    structureData = this.mapToStructureData(incidentStructureData);
                    this.isTreeSource = false; // only lists
                } else {
                    structureData = await this._formDS.getStructureData(this.resultId, callModel);
                    this.isTreeSource = structureData.some((item) => item["Children"]);
                }
            } else {
                structureData = await this._formDS.getStructureData(this.resultId, callModel);
                this.isTreeSource = structureData.some((item) => item["Children"]);
            }

            this.field.SourceData = structureData;
        }
    }

    mapToStructureData(incidentStructureData: StandingDataItemModel[]) {
        return incidentStructureData.map(
            (item) =>
                <IdNameColorModel>{
                    ID: +item.id,
                    Name: item.name,
                    Color: item.color,
                }
        );
    }

    updateConfig(isMapbackConfig: boolean, config?: FormFieldConfig) {
        this.multiselectLocked = false;
        this.sourceLocked = false;
        this.parametersLocked = false;

        if (!this.structureSettings) {
            this.structureSettings = new FormFieldConfig();
        }

        if (isMapbackConfig && config) {
            this.structureSettings.multiSelect = config.multiSelect === true;
            this.multiselectLocked = true;

            this.structureSettings.structureType = config.structureType;
            this.sourceLocked = true;

            if (config.parameters && config.parameters.length > 0) {
                this.structureSettings.parameters = config.parameters;
                this.parametersLocked = true;
            }
        }

        if (!this.parametersLocked) {
            const settings = parseTyped<FormFieldConfig>(
                this.field.CustomSettings,
                new FormFieldConfig()
            );
            this.structureSettings.parameters = settings.parameters;
        }

        this.saveModel();
    }
}
