import { delay } from "rxjs/operators";
import { firstValueFrom, of } from "rxjs";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { KriDataService } from "@app/kri/services/kri-data.service";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { KriDataGraphModel } from "../../models/kri-data-graph-model";
import Highcharts, { SeriesLineOptions } from "highcharts";
import { GetBaseHighchartConfig } from "@methods/HighchartMethods";

@Component({
    selector: "kri-data-graph",
    templateUrl: "./kri-data-graph.component.html",
    styleUrls: ["./kri-data-graph.component.scss"],
})
export class KriDataGraphComponent implements OnInit {
    @Input() kriGuid: string;
    @Input() lazyLoad: boolean = false;
    @Input() showOpen: boolean = false;

    @Input() preLoadedData: KriDataGraphModel = null;

    @ViewChild("chartContainer") chartContainer: ElementRef;
    @ViewChild("kriInfoTemplate") kriInfoTemplate: any;

    noAccess = false;
    data: KriDataGraphModel;
    generatedChart: Highcharts.Chart;

    preloadedDataUsed = false;

    constructor(
        private _ds: KriDataService,
        private promptService: CerrixPromptService,
        private tabService: TabService,
        private pages: Pages
    ) {}

    async ngOnInit() {
        if (!this.lazyLoad) {
            await this.load();
        }
    }

    async load() {
        this.reset();

        if (this.preLoadedData && !this.preloadedDataUsed) {
            // Page was written with a datacall in mind, so we have to put a small delay here so rest of page functions correctly.
            this.data = await firstValueFrom(of(this.preLoadedData).pipe(delay(100)));
            this.preloadedDataUsed = true;
        } else {
            this.data = await firstValueFrom(this._ds.getDataGraphInfo(this.kriGuid));
        }

        if (!this.data) {
            this.noAccess = true;
        } else {
            this.drawGraph();
        }
    }

    private reset() {
        if (this.generatedChart) {
            this.generatedChart.destroy();
            this.generatedChart = null;
        }

        this.data = null;
        this.noAccess = false;
    }

    private drawGraph() {
        const chartConfig = GetBaseHighchartConfig(this.data.graph);
        chartConfig.series = this.data.series;

        if (this.data.series && this.data.series.length <= 1) {
            chartConfig.legend = {
                enabled: false,
            };
        }

        if (this.data.graph === "column") {
            chartConfig.plotOptions.column = {
                maxPointWidth: 20,
            };
        } else if (chartConfig.series) {
            (<SeriesLineOptions[]>chartConfig.series).forEach((x) => {
                x.connectNulls = true;
            });
        }

        chartConfig.xAxis = {
            categories: this.data.categories,
        };

        chartConfig.yAxis = {
            title: {
                text: this.data.yAxisLabel,
            },
            plotLines: [
                {
                    value: this.data.greenThreshold,
                    width: 3,
                    zIndex: 5,
                    color: "green",
                    dashStyle: "ShortDash",
                    id: "green-threshold",
                    label: {
                        text: "Green Threshold",
                    },
                },
                {
                    value: this.data.redThreshold,
                    width: 3,
                    zIndex: 5,
                    color: "red",
                    dashStyle: "ShortDash",
                    id: "red-threshold",
                    label: {
                        text: "Red Threshold",
                    },
                },
            ],
        };

        chartConfig.tooltip = {
            enabled: true,
            useHTML: true,
            formatter: function () {
                let tooltip = "";

                const department = this.point.series.name;
                if (department) {
                    tooltip += `<div style="text-align: center;"><b>${department}</b></div>`;
                }

                tooltip += `<div>${this.point.category} (${this.point.y})</div>`;
                return tooltip;
            },
        };

        this.generatedChart = Highcharts.chart(
            this.chartContainer.nativeElement,
            chartConfig,
            null
        );
    }

    redrawGraph() {
        if (this.generatedChart) {
            this.generatedChart.reflow();
        }
    }

    showKriInfo() {
        this.promptService.alert({
            maxWidth: "600px",
            maxHeight: "510px",
            data: {
                title: (this.data.isKpi ? "KPI" : "KRI") + " Information",
                customTemplate: this.kriInfoTemplate,
            },
        });
    }

    openKri() {
        if (this.data && this.showOpen) {
            this.tabService.generateTab(this.pages.KriDetail, this.kriGuid, this.data.name);
        }
    }
}
