<div class="detail-page-summary" *ngIf="backupModel && backupModel.id > 0">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>ID:</b> {{ backupModel.identifier }}</div>
            <div class="property text-truncate"><b>Name:</b> {{ backupModel.name }}</div>
        </div>
        <div class="col-6">
            <div class="property"><b>Last modifier:</b> {{ backupModel.lastModifier }}</div>
            <div class="property"><b>Creator:</b> {{ backupModel.creator }}</div>
        </div>
    </div>
</div>

<div class="detail-page-card" *ngIf="!formGroup">
    <div class="detail-page-card-title">Details</div>
    <div class="detail-page-card-body padded cerrix-taskSeries-sm">
        <div class="fancy-loader submodule"></div>
    </div>
</div>

<div
    class="detail-page-card"
    [formGroup]="formGroup"
    *ngIf="formGroup"
    data-uiid="task-series-detail-form"
>
    <div class="detail-page-card-title">Define and manage series of tasks</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-task-series-sm">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <cerrix-textfield
                        fieldLabel="Name"
                        dataUiid="task-series-detail-name"
                        identifier="taskSeriesNameInput"
                        formControlName="name"
                        placeholder="Required"
                    ></cerrix-textfield>
                </div>
            </div>

            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label>Task type</label>
                    <ng-select
                        data-uiid="task-series-linked-type"
                        name="linkedTypes"
                        [multiple]="true"
                        [items]="linkedTypes"
                        placeholder="Required"
                        formControlName="linkedTypes"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group col">
                    <label for="responsibleSelect">Responsibles</label>
                    <ng-select
                        data-uiid="task-series-responsible"
                        name="responsibleSelect"
                        [multiple]="true"
                        [items]="validResponsibles"
                        placeholder="Required"
                        formControlName="responsibleIds"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group col">
                    <label for="reviewersSelect">Reviewers</label>
                    <i
                        class="far fa-info-circle mx-2"
                        matTooltip="Selecting reviewers enables the review workflow step."
                    ></i>
                    <ng-select
                        data-uiid="task-series-reviewers"
                        name="reviewersSelect"
                        [multiple]="true"
                        [items]="validReviewers"
                        formControlName="reviewerIds"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group col">
                    <label for="notificationPeriodSelect">Notifications</label>
                    <ng-select
                        data-uiid="task-series-notification"
                        name="notificationPeriodSelect"
                        [multiple]="true"
                        [items]="validNotificationPeriods"
                        formControlName="notificationPeriodIds"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group col">
                    <label for="scoreOptionsSelect">Task score option</label>
                    <i
                        class="far fa-info-circle mx-2"
                        matTooltip="Options: \n 1) Required: the responsible executing the task has to give a score, \n 2) Optional: it is optional, not requireed for the Responsible to give a score, \n 3) No: the score is not used in the series. The field task score is not shown.
                        "
                    ></i>
                    <ng-select
                        data-uiid="task-series-score-options"
                        name="scoreOptionsSelect"
                        [multiple]="false"
                        [items]="taskScoreOptions"
                        formControlName="taskScoreOption"
                        bindLabel="Name"
                        bindValue="ID"
                        [closeOnSelect]="true"
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col">
                <cerrix-textarea
                    fieldLabel="Description"
                    dataUiid="task-series-detail-description"
                    identifier="taskSeriesExecutionDescriptionInput"
                    [rows]="4"
                    formControlName="description"
                ></cerrix-textarea>
            </div>
        </div>
    </div>
    <div class="detail-page-card">
        <div class="detail-page-card-title-with-subtext">Planning</div>
        <div class="detail-page-card-subtext" *ngIf="model.id > 0">
            Changing the task series details will update all tasks in status Todo. Extending the
            period leads to extra tasks, shortening the period leads to removal of tasks.
        </div>
        <div class="detail-page-card-body padded form-group-styling cerrix-task-series-sm">
            <div class="row">
                <cerrix-recurrence
                    [parentForm]="formGroup"
                    [model]="recurrenceViewModel"
                    [disableAllExcept]="recurrenceViewModel?.id > 0 ? ['enddate'] : []"
                    #recurrenceComponent
                ></cerrix-recurrence>
            </div>
        </div>
    </div>
</div>
