import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { UserModuleRoleRightModel } from "../shared/user-module-roles-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";

@Component({
    selector: "right-description",
    templateUrl: "./right-description.component.html",
    styleUrls: ["./right-description.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class RightDescriptionComponent {
    @ViewChild("template", { static: true }) template: any;

    tableConfig: GenericListConfig;

    constructor(private _promptService: CerrixPromptService) {}

    open(header: string, rights: UserModuleRoleRightModel[]) {
        this.createConfig(rights);
        this._promptService
            .prompt({
                maxWidth: "800px",
                maxHeight: "465px",
                data: {
                    title: header,
                    customTemplate: this.template,
                    cancelButton: {
                        text: "Close",
                    },
                    confirmButton: {
                        show: false,
                    },
                },
            })
            .getResult()
            .subscribe(() => {
                this.tableConfig = null;
            });
    }

    private createConfig(rights: UserModuleRoleRightModel[]) {
        this.tableConfig = <GenericListConfig>{
            fields: [
                {
                    prettyName: "Screen",
                    fieldName: "rightLocation",
                    fieldType: GenericListFieldType.Text,
                    description: "Screen this right is related to",
                    showDescriptionInTable: true,
                },
                {
                    prettyName: "Action",
                    fieldName: "rightAction",
                    fieldType: GenericListFieldType.Text,
                    description: "Action the user can perform on targeted screen",
                    showDescriptionInTable: true,
                },
                {
                    prettyName: "Restriction",
                    fieldName: "rightRestriction",
                    fieldType: GenericListFieldType.Text,
                    description:
                        "Assigned: only assigned items.\nRestricted: only items from own organization.\nUnrestricted: items from entire organization.",
                    showDescriptionInTable: true,
                },
            ],

            hideHeader: true,
            limitViewTo: 5,
            showToolbarSearch: true,
            data: rights,
        };
    }
}
