import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as pbi from "powerbi-client";
import { ReportDataService } from "../services/ReportDataService";
import { TabModel } from "./../../../common/models/generic/TabModels/TabModel";
import { PowerbiEmbedModel } from "./../shared/powerbi-embed-model";

@Component({
    selector: "report-powerbi",
    templateUrl: "./report-powerbi.component.html",
    styleUrls: ["./report-powerbi.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ReportPowerbiComponent implements OnInit {
    tab: TabModel;

    @Input() id: string;
    showLoader = true;

    @ViewChild("container") container: ElementRef;

    constructor(private reportService: ReportDataService) {}

    ngOnInit() {
        this.reportService.getPowerBIEmbed(this.id).subscribe((x) => {
            this.showLoader = false;
            if (this.tab) {
                this.tab.name = x.reportName;
            }

            this.embed(x);
        });
    }

    embed(embedModel: PowerbiEmbedModel) {
        var config = {
            type: "report",
            tokenType: pbi.models.TokenType.Embed,
            accessToken: embedModel.accessToken,
            embedUrl: embedModel.embedUrl,
            id: embedModel.reportID,
            permissions: pbi.models.Permissions.Read,
            groupId: embedModel.groupID,
            datasetBinding: {
                datasetId: embedModel.datasetID,
            },
        };

        // Embed the report and display it within the div container
        let powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        );

        let report = powerbi.embed(this.container.nativeElement, config);
    }
}
