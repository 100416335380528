import { Component, Input, Output, EventEmitter } from "@angular/core";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "detail-options",
    templateUrl: "./detail-options.component.html",
    styleUrls: ["./detail-options.component.scss"],
})
export class DetailOptionsComponent {
    @Input() moduleName: string;
    @Input() objectName: string;
    @Input() customText: string = "";

    @Input() buttonConfig: WorkspaceButtonConfig[] = [];

    @Input() allowDelete = true;
    @Output() onDelete = new EventEmitter();

    constructor(private _promptService: CerrixPromptService) {}

    deleteClick() {
        if (!this.allowDelete) {
            return;
        }

        const popupText = this.customText
            ? this.customText
            : `Are you sure you want to delete ${this.objectName}?`;

        this._promptService
            .confirm("Delete item", popupText)
            .onConfirm()
            .subscribe(() => {
                this.onDelete.emit();
            });
    }

    buttonClick(config: WorkspaceButtonConfig) {
        if (config.isDisabled !== true && config.clickEvent) {
            config.clickEvent();
        }
    }
}
