import { Component, Input } from "@angular/core";
import { AuditFieldworkStepDetailComponent } from "@app/audit/pages/audit-fieldwork-step-detail/audit-fieldwork-step-detail.component";
import { AuditFieldworkStepDataService } from "@app/audit/services/audit-fieldworkstep-data.service";
import { AuditDetailPermissionModel } from "@app/audit/shared/permission-models/audit-detail-permission-model";
import {
    CerrixGridEditorColumnConfig,
    CerrixGridEditorConfig,
} from "@app/shared/models/cerrix-grid-editor-config";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { toPromise } from "@methods/CommonMethods";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { AuditFieldworkStepQuickExecutionModel } from "@app/audit/shared/data-models/audit-fieldwork-step-quick-execution-model";
import { AuditFieldworkStepDetailConfigModel } from "@app/audit/shared/page-models/audit-fieldwork-step-detail-config-model";
import { nameof } from "@methods/jeffs-toolkit";

@Component({
    selector: "audit-detail-fieldwork-execution",
    templateUrl: "./audit-detail-fieldwork-execution.component.html",
    styleUrls: ["./audit-detail-fieldwork-execution.component.scss"],
})
export class AuditDetailFieldworkExecutionComponent {
    @Input() permissions: AuditDetailPermissionModel;
    @Input() auditGuid: string;

    gridConfig: CerrixGridEditorConfig;

    saveCalls: number = null;
    pageReady = false;

    private executionDetailColumns: CerrixGridEditorColumnConfig[];

    constructor(
        private _ds: AuditFieldworkStepDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    init() {
        // This check makes sure the init only happens once.
        if (!this.pageReady) {
            this.loadGrid();
        }
    }

    async loadGrid() {
        if (
            !this.permissions.fieldworkPermissions ||
            !this.permissions.fieldworkPermissions.canView
        ) {
            this.pageReady = true;
            return;
        }

        this.gridConfig = null;
        this.setupGrid();
    }

    public async setupGrid() {
        const data = await this.getData();
        const columns: CerrixGridEditorColumnConfig[] = [];
        columns.push({
            prettyName: "Name",
            fieldName: "name",
            fieldType: GenericListFieldType.Text,
            isReadonly: true,
        });
        columns.push({
            prettyName: "Description",
            fieldName: "description",
            fieldType: GenericListFieldType.TextArea,
            isReadonly: true,
        });
        columns.push({
            prettyName: "Workflow Status",
            fieldName: "currentStatus",
            fieldType: GenericListFieldType.Text,
            isReadonly: true,
        });

        if (this.executionDetailColumns.length > 0) {
            columns.push(...this.executionDetailColumns);
        }

        columns.push({
            prettyName: "Observation",
            fieldName: "observation",
            fieldType: GenericListFieldType.TextArea,
            isReadonly: !this.permissions.fieldworkPermissions.canEditObservation,
            hidden: true,
        });
        columns.push({
            prettyName: "Observation Date",
            fieldName: "observationDate",
            fieldType: GenericListFieldType.Date,
            isReadonly: !this.permissions.fieldworkPermissions.canEditObservation,
            hidden: true,
        });
        columns.push({
            prettyName: "Observation Score",
            fieldName: "observationScore",
            fieldType: GenericListFieldType.ColoredSelect,
            isReadonly: !this.permissions.fieldworkPermissions.canEditObservation,
            getDataMethod: () => {
                return this._ds.getAuditObservationScores(this.auditGuid);
            },
        });
        columns.push({
            prettyName: "Auditors",
            fieldName: "auditors",
            fieldType: GenericListFieldType.Text,
            isReadonly: true,
        });
        columns.push({
            prettyName: "Reviewers",
            fieldName: "reviewers",
            fieldType: GenericListFieldType.Text,
            isReadonly: true,
        });

        columns.push({
            prettyName: "Latest comment",
            fieldName: "latestComment",
            fieldType: GenericListFieldType.Text,
            isReadonly: true,
        });

        this.gridConfig = <CerrixGridEditorConfig<AuditFieldworkStepQuickExecutionModel>>{
            data: data,

            idProperty: nameof<AuditFieldworkStepQuickExecutionModel>((x) => x.id),
            childProperty: nameof<AuditFieldworkStepQuickExecutionModel>((x) => x.children),

            allowResizing: true,
            allowReordering: true,

            columns: columns,
            rowButtonsFirst: true,
            rowButtons: [
                {
                    icon: "fal fa-file",
                    tooltip: "View documents",
                    clickEvent: (row) => {
                        this._tabService.generateTab(
                            this._pages.AuditFieldworkStep,
                            row.guid,
                            "",
                            <AuditFieldworkStepDetailConfigModel>{
                                auditguid: this.auditGuid,
                                stepguid: row.guid,
                            },
                            AuditFieldworkStepDetailComponent.documentTabId
                        );
                    },
                },
                {
                    icon: "fal fa-external-link",
                    tooltip: "Open details",
                    dataUiid: "fieldworkstep-open-detail-btn",
                    clickEvent: (row) => {
                        this._tabService.generateTab(this._pages.AuditFieldworkStep, row.guid, "", <
                            AuditFieldworkStepDetailConfigModel
                        >{
                            auditguid: this.auditGuid,
                            stepguid: row.guid,
                        });
                    },
                },
            ],

            onRowChanged: (row) => {
                if (!this.permissions.fieldworkPermissions.canEditObservation) {
                    return;
                }

                if (this.saveCalls == null) {
                    this.saveCalls = 1;
                } else {
                    this.saveCalls++;
                }

                this._ds.storeObservation(this.auditGuid, row).then(() => {
                    this.saveCalls--;
                });
            },
        };

        this.pageReady = true;
    }

    private async getData() {
        const data = await toPromise(this._ds.getFieldworkExecutionData(this.auditGuid));

        this.executionDetailColumns = [];
        data.forEach((d) => {
            this.extractExecutionDetails(d);
        });

        return data;
    }

    private extractExecutionDetails(step: AuditFieldworkStepQuickExecutionModel) {
        if (step.executionDetails) {
            const executionFields = JSON.parse(step.executionDetails);
            Object.keys(executionFields).forEach((key) => {
                const fieldName = "__fieldworkExecution__" + btoa(encodeURIComponent(key));
                if (!this.executionDetailColumns.find((s) => s.fieldName === fieldName)) {
                    this.executionDetailColumns.push({
                        prettyName: key,
                        fieldName: fieldName,
                        fieldType: GenericListFieldType.TextArea,
                        hidden: true,
                        isReadonly: true,
                    });
                }
                step[fieldName] = executionFields[key];
            });
        }

        if (step.children) {
            step.children.forEach((c) => {
                this.extractExecutionDetails(c);
            });
        }
    }
}
