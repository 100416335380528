<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-12 col-xl-6 left-block">
            <div class="form-group">
                <div class="col">
                    <label>Repeat</label>
                    <ng-select
                        data-uiid="recurrence-repeat"
                        bindValue="ID"
                        bindLabel="Name"
                        [items]="frequencies"
                        [clearable]="false"
                        formControlName="frequency"
                        (change)="frequencyChanged($event)"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-6 left-block">
                <div class="form-group">
                    <label>Repeat every </label>
                    <div style="display: flex" class="input-group">
                        <input
                            data-uiid="recurrence-repeat-every"
                            type="number"
                            min="1"
                            max="frequencycount"
                            formControlName="interval"
                            class="form-control col-5"
                        />

                        <span class="input-group-text" style="margin-left: 5px">{{
                            model.frequency == recurrenceFrequencys.Weekly
                                ? "week(s)"
                                : model.frequency == recurrenceFrequencys.Monthly
                                ? "month(s)"
                                : model.frequency == recurrenceFrequencys.Yearly
                                ? "year(s)"
                                : ""
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="model.frequency == recurrenceFrequencys.Weekly">
        <div class="form-group">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div><label>Repeat on</label></div>
                    <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic checkbox toggle button group"
                        id="weeklyDiv"
                    >
                        <mat-chip-listbox
                            multiple
                            matTooltip="Select 1 or more days"
                            [disabled]="byDayDisabled"
                        >
                            <mat-chip-option
                                *ngFor="let weekDay of weekDays"
                                [selectable]="!byDayDisabled"
                                selected="{{ model.byDay.includes(weekDay.value) }}"
                                (click)="toggleWeekDay($event, weekDay)"
                                [disabled]="byDayDisabled"
                            >
                                {{ weekDay.label }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="form-group"
        *ngIf="
            model.frequency == recurrenceFrequencys.Monthly ||
            model.frequency == recurrenceFrequencys.Yearly
        "
    >
        <div class="row">
            <div class="col-12 col-xl-6 left-block">
                <div>
                    <label>Repeat on</label>
                </div>
                <div *ngIf="model.frequency == recurrenceFrequencys.Yearly">
                    <ng-select
                        formControlName="byMonth"
                        data-uiid="recurrence-when-monthselection"
                        bindValue="ID"
                        bindLabel="Name"
                        [items]="months"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-6 left-block">
                <div style="display: flex">
                    <div class="col-1">
                        <input
                            type="radio"
                            name="abc"
                            id="radioDay"
                            value="Day"
                            style="margin-right: 5px"
                            [(ngModel)]="model.radioSelection"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="onRadioChanged($event)"
                        />
                    </div>
                    <div class="col-1">
                        <label style="margin-right: 5px" for="a">Day</label>
                    </div>
                    <input
                        data-uiid="recurrence-daynumber"
                        type="number"
                        min="1"
                        max="31"
                        formControlName="byMonthDay"
                        class="form-control"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-6 left-block">
                <div style="display: flex">
                    <div class="col-2">
                        <input
                            type="radio"
                            name="abc"
                            id="radioOther"
                            value="Other"
                            style="margin-right: 5px"
                            [(ngModel)]="model.radioSelection"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="onRadioChanged($event)"
                        />
                    </div>
                    <ng-select
                        class="col-5"
                        data-uiid="recurrence-when-selection"
                        bindValue="ID"
                        bindLabel="Name"
                        [items]="whenSelection"
                        formControlName="otherByDayType"
                    >
                    </ng-select>
                    <ng-select
                        class="col-5"
                        data-uiid="recurrence-when-day-selection"
                        bindValue="Key"
                        bindLabel="Value"
                        [items]="daySelection"
                        formControlName="otherByDay"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-xl-6 left-block">
            <div class="form-group">
                <label>Start date</label>
                <date-input
                    data-uiid="recurrence-startdate"
                    formControlName="startdate"
                ></date-input>
            </div>
            <div class="form-group">
                <label>End date</label>
                <date-input data-uiid="recurrence-enddate" formControlName="enddate"></date-input>
            </div>
        </div>
    </div>
</form>
