import { Component } from "@angular/core";

@Component({
    selector: "api-usage-manual",
    templateUrl: "./api-usage-manual.component.html",
    styleUrls: ["../api-spec/api-spec.scss", "./api-usage-manual.component.scss"],
})
export class ApiUsageManualComponent {
    apiUrl: string;

    constructor() {
        this.apiUrl = window.location.origin + "/api";
    }
}
