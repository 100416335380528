<div class="override-toggle">
    <cerrix-checkbox
        label="Use parent / default scores."
        [(value)]="row.UseParentOrDefaultScores"
        (valueChange)="useParentOrDefaultChecked($event)"
    ></cerrix-checkbox>
</div>
<hr />
<div class="parent-matrix-viewer" *ngIf="row.UseParentOrDefaultScores && row.ParentScores">
    <label>
        Preview of the current parent / default scores that will be applied to this framework
        dimension.
    </label>
</div>
<div class="sub-list-manager" *ngIf="listConfig">
    <generic-list-manager [config]="listConfig"></generic-list-manager>
</div>
