<div class="fancy-loader" *ngIf="!pageReady"></div>

<div class="detail-page-action-bar" *ngIf="pageReady">
    <button class="btn btn-raised" (click)="save()">
        <i class="fas fa-save" aria-hidden="true"></i>
        <span class="d-none d-lg-inline ms-2">Save</span>
    </button>
    <button class="btn btn-raised red" *ngIf="id > 0" (click)="deleteClick()">
        <i class="fas fa-trash"></i>
        <span>Delete</span>
    </button>
</div>

<div class="detail-page-card" [formGroup]="form" *ngIf="pageReady">
    <div class="detail-page-card-title">Edit notification</div>
    <div class="detail-page-card-body padded form-group-styling invite-compose-area">
        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Name"
                formControlName="name"
                name="notification-name"
                [required]="true"
                placeholder="Required"
            ></cerrix-textfield>
        </div>
        <div class="form-group">
            <cerrix-checkbox
                fontsize="0.9em"
                label="Active"
                formControlName="active"
            ></cerrix-checkbox>
        </div>
        <div *ngIf="!isTaskModule" class="form-group">
            <label>Period</label>
            <ng-select
                id="periodSelect"
                name="periodSelect"
                [items]="periods"
                bindLabel="Name"
                bindValue="ID"
                [multiple]="false"
                formControlName="period"
                placeholder="Required"
            ></ng-select>
        </div>
        <div *ngIf="enableTypeField" class="form-group">
            <label>Type</label>
            <ng-select
                id="typeSelect"
                name="typeSelect"
                [multiple]="true"
                [closeOnSelect]="false"
                [items]="types"
                bindLabel="Name"
                bindValue="ID"
                formControlName="types"
                placeholder="Required"
            ></ng-select>
        </div>
        <div *ngIf="!isTaskModule" class="form-group">
            <label>Sub type</label>
            <ng-select
                id="subTypeSelect"
                name="subTypeSelect"
                [items]="subTypes"
                bindLabel="Name"
                bindValue="ID"
                [multiple]="false"
                formControlName="subType"
                placeholder="Required"
                (change)="reloadRecipients($event)"
            >
            </ng-select>
        </div>
        <div class="form-group">
            <label>
                Recipients
                <i
                    class="fas fa-question-circle"
                    matTooltip="Leave selection empty to apply to everyone."
                ></i>
            </label>
            <ng-select
                id="recipientSelect"
                name="recipientSelect"
                [items]="recipients"
                bindLabel="DisplayName"
                [closeOnSelect]="false"
                bindValue="ID"
                [multiple]="true"
                formControlName="recipients"
                placeholder="Everyone"
            ></ng-select>
        </div>

        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Message"
                formControlName="subject"
                name="notification-subject"
                [required]="true"
                placeholder="Required"
                (click)="setFocusToSubject()"
            ></cerrix-textfield>
            <button
                class="keyword-button"
                *ngFor="let keyword of keywords"
                (click)="addKeyword(keyword)"
            >
                {{ keyword }}
            </button>
            <cerrix-editor
                [class.cerrixEditor]="isEmpty"
                [value]="notification.message"
                (valueChange)="onChange($event)"
                (click)="deFocusSubject()"
                #editor
            ></cerrix-editor>
        </div>

        <div class="form-group">
            <label>Selected organizations</label>
            <i
                class="fas fa-info-circle ms-2"
                [matTooltip]="noOrganizationsSelectedDescription"
                position="right"
            ></i>
            <cerrix-select-tree
                class="fullWidth"
                [getDataMethod]="organizationRequest"
                formControlName="organizations"
                [multiselect]="true"
            >
            </cerrix-select-tree>
        </div>
    </div>
</div>
