<div class="detail-page-summary" *ngIf="risk && risk.id > 0">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>ID:</b> {{ risk.identifier }}</div>
            <div class="property"><b>Name:</b> {{ risk.name }}</div>
            <div class="property">
                <b>Registration date:</b> {{ risk.dateCreated | dateFormat }}
            </div>
        </div>
        <div class="col-6">
            <div class="property"><b>Last modifier:</b> {{ risk.lastModifier }}</div>
            <div class="property"><b>Risk owner:</b> {{ risk.owner }}</div>
            <div class="property"><b>Is key risk:</b> {{ risk.isKeyRisk ? "Yes" : "No" }}</div>
        </div>
    </div>
</div>

<form [formGroup]="parentForm">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Risk details</div>
        <div class="detail-page-card-body padded">
            <div class="fancy-loader submodule" *ngIf="!riskInitialized"></div>
            <div *ngIf="riskInitialized">
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="form-group">
                            <label for="organizationSelect">Organization</label>
                            <cerrix-select-tree
                                [getDataMethod]="organizationRequest"
                                formControlName="organizationId"
                                placeholder="Required"
                                [multiselect]="false"
                                data-uiid="organization-tree"
                            >
                            </cerrix-select-tree>
                        </div>
                        <div class="form-group">
                            <label>
                                Risk catalogue
                                <i
                                    *ngIf="!selectedOrganizationId || selectedOrganizationId <= 0"
                                    class="far fa-question-circle ms-2"
                                    matTooltip="Please select an organization first."
                                ></i>
                            </label>
                            <cerrix-select
                                [hidden]="!subTypesLoaded && !eventCategoryLoaded"
                                #riskCatalogueCmp
                                id="riskCatalogueSelect"
                                formControlName="riskCatalogueId"
                                prettyName="risk catalogue"
                                bindLabel="Name"
                                bindValue="ID"
                                [getDataMethod]="riskCatalogueRequest"
                                placeholder="Required"
                                (valueChange)="riskCatalogueChanged($event, true)"
                                dataUiid="risk-catalogue-select"
                            >
                            </cerrix-select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group">
                            <label>
                                Business dimensions
                                <i
                                    *ngIf="!selectedOrganizationId || selectedOrganizationId <= 0"
                                    class="far fa-question-circle ms-2"
                                    matTooltip="Please select an organization first."
                                ></i>
                            </label>
                            <cerrix-select-tree
                                [getDataMethod]="businessDimensionsRequest"
                                formControlName="businessDimensionIds"
                                [multiselect]="true"
                                (onChangeIds)="reloadFrameworkDimensions($event)"
                                data-uiid="business-dimensions-tree"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="form-group">
                            <cerrix-textarea
                                fieldLabel="Definition risk catalogue"
                                identifier="definitionRiskCatalogueInput"
                                [rows]="9"
                                formControlName="definitionRiskCatalogue"
                                dataUiid="definition-risk-catalogue-input"
                            ></cerrix-textarea>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group">
                            <label>
                                Framework dimensions
                                <i
                                    *ngIf="!selectedOrganizationId || selectedOrganizationId <= 0"
                                    class="far fa-question-circle ms-2"
                                    matTooltip="Please select an organization first."
                                ></i>
                            </label>
                            <cerrix-select-tree
                                [getDataMethod]="frameworkDimensionsRequest"
                                formControlName="frameworkDimensionIds"
                                [multiselect]="true"
                                data-uiid="framework-dimensions-tree"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="form-group">
                            <label for="subTypeSelect">Subtypes</label>
                            <ng-select
                                *ngIf="filteredSubTypes.length > 0"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                id="subTypeSelect"
                                name="subTypeSelect"
                                [items]="filteredSubTypes"
                                bindLabel="Name"
                                bindValue="ID"
                                formControlName="subtypeIds"
                                dataUiid="subtype-select"
                            ></ng-select>
                            <div *ngIf="filteredSubTypes.length === 0">No subtypes available</div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group">
                            <label for="riskOwnerInput">Risk owner</label>
                            <cerrix-select
                                #ownersCmp
                                id="riskOwnerSelect"
                                formControlName="owner"
                                prettyName="risk owner"
                                bindLabel="Name"
                                bindValue="Name"
                                [addTag]="true"
                                [getDataMethod]="riskOwnersRequest"
                                placeholder="Required"
                                dataUiid="risk-owner-select"
                            >
                            </cerrix-select>
                        </div>
                        <div class="form-group">
                            <label for="riskAreaSelect">Risk area</label>
                            <cerrix-select
                                id="riskAreaSelect"
                                name="riskAreaSelect"
                                [getDataMethod]="riskAreaRequest"
                                prettyName="risk area"
                                formControlName="riskAreaId"
                                bindLabel="Name"
                                bindValue="ID"
                                dataUiid="risk-area-select"
                            >
                            </cerrix-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="form-group">
                            <cerrix-textfield
                                fieldLabel="Risk name"
                                identifier="riskNameInput"
                                formControlName="name"
                                placeholder="Required"
                                dataUiid="risk-name-input"
                            >
                            </cerrix-textfield>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group">
                            <label for="eventCategorySelect">Event category</label>
                            <cerrix-select-tree
                                formControlName="eventCategoryId"
                                [getDataMethod]="eventCategoryRequest"
                                data-uiid="event-category-tree"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="form-group">
                            <ai-description-generator
                                fieldLabel="Description"
                                identifier="descriptionInput"
                                [rows]="
                                    permissions.showCauseField && permissions.showEffectField
                                        ? 10
                                        : 5
                                "
                                formControlName="description"
                                cssClass="form-control"
                                dataUiid="risk-description-input"
                                [fetchDescriptionsDatamethod]="fetchDescriptions"
                                (onDescriptionGenerated)="onDescriptionGenerated($event)"
                            ></ai-description-generator>
                        </div>
                    </div>

                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group" *ngIf="permissions.showCauseField">
                            <cerrix-textarea
                                fieldLabel="Cause(s)"
                                identifier="causesInput"
                                [rows]="9"
                                formControlName="cause"
                                dataUiid="risk-cause-input"
                            ></cerrix-textarea>
                        </div>

                        <div class="form-group" *ngIf="permissions.showEffectField">
                            <cerrix-textarea
                                fieldLabel="Effect(s)"
                                identifier="effectsInput"
                                [rows]="9"
                                formControlName="effect"
                                dataUiid="risk-effect-input"
                            ></cerrix-textarea>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="bottom-bar">
                    <cerrix-checkbox
                        formControlName="applicable"
                        label="Applicable"
                        fontsize="0.9em"
                        data-uiid="risk-applicable-checkbox"
                    >
                    </cerrix-checkbox>
                    <cerrix-checkbox
                        formControlName="isKeyRisk"
                        label="Is key risk"
                        fontsize="0.9em"
                        data-uiid="risk-key-risk-checkbox"
                    >
                    </cerrix-checkbox>
                    <cerrix-checkbox
                        formControlName="confidential"
                        label="Confidential"
                        fontsize="0.9em"
                        data-uiid="risk-confidential-checkbox"
                    >
                    </cerrix-checkbox>
                    <cerrix-checkbox
                        formControlName="approved"
                        label="Approved"
                        fontsize="0.9em"
                        data-uiid="risk-approved-checkbox"
                    >
                    </cerrix-checkbox>
                    <cerrix-checkbox
                        formControlName="reviewed"
                        label="Reviewed"
                        fontsize="0.9em"
                        data-uiid="risk-reviewed-checkbox"
                    >
                    </cerrix-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Risk scoring</div>
        <div class="detail-page-card-body padded cerrix-control-sm">
            <div class="fancy-loader submodule" *ngIf="!riskInitialized"></div>

            <div *ngIf="riskInitialized">
                <div class="row">
                    <div class="col" [class.left-block]="!enableTargetRisk">
                        <div style="max-width: 400px; margin: 0 auto">
                            <cerrix-riskmatrix
                                #riskmatrix
                                [OrganizationID]="risk.organizationId"
                                [readonly]="permissions.readonly"
                                [cellSize]="50"
                                [cellSpacing]="1"
                                [(selected)]="grossRiskMatrix"
                                (selectedChange)="grossRiskMatrixChanged($event)"
                            >
                            </cerrix-riskmatrix>
                        </div>

                        <div class="form-group">
                            <label for="grossLikelihoodSelect">Gross likelihood</label>
                            <cerrix-select
                                id="grossLikelihoodSelect"
                                name="grossLikelihoodSelect"
                                formControlName="likelihoodId"
                                bindLabel="Name"
                                bindValue="ID"
                                placeholder="Required"
                                [getDataMethod]="likelihoodRequest"
                                prettyName="likelihood"
                                dataUiid="gross-likelihood-select"
                            >
                            </cerrix-select>
                        </div>

                        <div class="form-group">
                            <label for="grossImpactSelect">Gross impact (overall score)</label>
                            <cerrix-select
                                id="grossImpactSelect"
                                name="grossImpactSelect"
                                formControlName="impactId"
                                bindLabel="Name"
                                bindValue="ID"
                                placeholder="Required"
                                [getDataMethod]="impactRequest"
                                prettyName="impact"
                                (valueChange)="checkCustomScaleValuesAreAlignable()"
                                dataUiid="gross-impact-select"
                            >
                            </cerrix-select>
                            <small
                                class="scale-align-text"
                                *ngIf="alignCustomGrossIndex != null"
                                (click)="setGrossImpactToCustomScale()"
                                >Gross impact is different than
                                {{ alignCustomLeadingValue ? "leading" : "highest" }} custom impact,
                                click here to align.</small
                            >
                        </div>

                        <div class="form-group" *ngIf="enableBudgetBasedRisk">
                            <label for="grossFinancialImpact"
                                >Gross financial impact (in euro)</label
                            >
                            <input
                                type="number"
                                data-uiid="gross-financial-impact-input"
                                class="form-control form-control-sm"
                                name="grossFinancialImpact"
                                formControlName="financialImpactGross"
                            />
                        </div>
                    </div>

                    <div class="col" [class.right-block]="!enableTargetRisk">
                        <div style="max-width: 400px; margin: 0 auto">
                            <cerrix-riskmatrix
                                #riskmatrix
                                [OrganizationID]="risk.organizationId"
                                [readonly]="permissions.readonly"
                                [cellSize]="50"
                                [cellSpacing]="1"
                                [(selected)]="netRiskMatrix"
                                (selectedChange)="netRiskMatrixChanged($event)"
                            >
                            </cerrix-riskmatrix>
                        </div>

                        <div class="form-group">
                            <label for="netLikelihoodSelect">Net likelihood</label>
                            <cerrix-select
                                id="netLikelihoodSelect"
                                name="netLikelihoodSelect"
                                formControlName="netLikelihoodId"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="true"
                                [getDataMethod]="likelihoodRequest"
                                prettyName="likelihood"
                                dataUiid="net-likelihood-select"
                            >
                            </cerrix-select>
                        </div>

                        <div class="form-group">
                            <label for="netImpactSelect">Net impact (overall score)</label>
                            <cerrix-select
                                id="netImpactSelect"
                                name="netImpactSelect"
                                formControlName="netImpactId"
                                bindLabel="Name"
                                bindValue="ID"
                                [getDataMethod]="impactRequest"
                                prettyName="impact"
                                (valueChange)="checkCustomScaleValuesAreAlignable()"
                                dataUiid="net-impact-select"
                            >
                            </cerrix-select>
                            <small
                                class="scale-align-text"
                                *ngIf="alignCustomNetIndex != null"
                                (click)="setNetImpactToCustomScale()"
                                >Net impact is different than
                                {{ alignCustomLeadingValue ? "leading" : "highest" }} custom impact,
                                click here to align.</small
                            >
                        </div>

                        <div class="form-group" *ngIf="enableBudgetBasedRisk">
                            <label for="netFinancialImpact">Net financial impact (in euro)</label>
                            <input
                                type="number"
                                data-uiid="net-financial-impact-input"
                                class="form-control form-control-sm"
                                name="netFinancialImpact"
                                formControlName="financialImpactNet"
                            />
                        </div>
                    </div>

                    <div class="col" *ngIf="enableTargetRisk">
                        <div style="max-width: 400px; margin: 0 auto">
                            <cerrix-riskmatrix
                                #riskmatrix
                                [OrganizationID]="risk.organizationId"
                                [readonly]="permissions.readonly"
                                [cellSize]="50"
                                [cellSpacing]="1"
                                [(selected)]="targetRiskMatrix"
                                (selectedChange)="targetRiskMatrixChanged($event)"
                            >
                            </cerrix-riskmatrix>
                        </div>

                        <div class="form-group">
                            <label for="targetLikelihoodSelect">Target likelihood</label>
                            <cerrix-select
                                id="targetLikelihoodSelect"
                                name="targetLikelihoodSelect"
                                formControlName="targetLikelihoodId"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="true"
                                [getDataMethod]="likelihoodRequest"
                                prettyName="likelihood"
                                dataUiid="target-likelihood-select"
                            >
                            </cerrix-select>
                        </div>

                        <div class="form-group">
                            <label for="targetImpactSelect">Target impact (overall score)</label>
                            <cerrix-select
                                id="targetImpactSelect"
                                name="targetImpactSelect"
                                formControlName="targetImpactId"
                                bindLabel="Name"
                                bindValue="ID"
                                [getDataMethod]="impactRequest"
                                prettyName="impact"
                                (valueChange)="checkCustomScaleValuesAreAlignable()"
                                dataUiid="target-impact-select"
                            >
                            </cerrix-select>
                            <small
                                class="scale-align-text"
                                *ngIf="alignCustomTargetIndex != null"
                                (click)="setTargetImpactToCustomScale()"
                                >Target impact is different than
                                {{ alignCustomLeadingValue ? "leading" : "highest" }} custom impact,
                                click here to align.</small
                            >
                        </div>

                        <div class="form-group" *ngIf="enableBudgetBasedRisk">
                            <label for="targetFinancialImpact"
                                >Target financial impact (in euro)</label
                            >
                            <input
                                type="number"
                                data-uiid="target-financial-impact-input"
                                class="form-control form-control-sm"
                                name="targetFinancialImpact"
                                formControlName="financialImpactTarget"
                            />
                        </div>
                    </div>
                </div>

                <!-- Check if you have any custom impact scales, otherwise do not load this section -->
                <ng-container *ngIf="showCustomImpactScales">
                    <div class="form-group-separator"></div>

                    <div *ngIf="impactScaleData && parentForm && risk">
                        <risk-impact-scales
                            [parentForm]="parentForm"
                            [(risk)]="risk"
                            [impactScaleData]="impactScaleData"
                            [enableTargetRisk]="enableTargetRisk"
                            [enableBudgetBasedRisk]="enableBudgetBasedRisk"
                            (onValueChange)="checkCustomScaleValuesAreAlignable()"
                        >
                        </risk-impact-scales>
                    </div>
                </ng-container>

                <div class="row" *ngIf="enableBudgetBasedRisk">
                    <div class="form-group-separator"></div>
                    <div class="potential-loss-header mb-2">
                        <label>Potential loss range</label>
                    </div>
                    <div class="col" [class.left-block]="!enableTargetRisk">
                        <div class="form-group">
                            <label for="potentialLossGrossMin">Gross Min</label>
                            <input
                                type="number"
                                data-uiid="risk-potential-loss-gross-min-input"
                                class="form-control form-control-sm"
                                name="potentialLossGrossMin"
                                formControlName="potentialLossGrossMin"
                                readonly="true"
                            />
                        </div>

                        <div class="form-group">
                            <label for="potentialLossGrossMax">Gross Max</label>
                            <input
                                type="number"
                                data-uiid="risk-potential-loss-gross-max-input"
                                class="form-control form-control-sm"
                                name="potentialLossGrossMax"
                                formControlName="potentialLossGrossMax"
                                readonly="true"
                            />
                        </div>
                    </div>

                    <div class="col" [class.right-block]="!enableTargetRisk">
                        <div class="form-group">
                            <label for="potentialLossNetMin">Net Min</label>
                            <input
                                type="number"
                                data-uiid="risk-potential-loss-net-min-input"
                                class="form-control form-control-sm"
                                name="potentialLossNetMin"
                                formControlName="potentialLossNetMin"
                                readonly="true"
                            />
                        </div>

                        <div class="form-group">
                            <label for="potentialLossNetMax">Net Max</label>
                            <input
                                type="number"
                                data-uiid="risk-potential-loss-net-max-input"
                                class="form-control form-control-sm"
                                name="potentialLossNetMax"
                                formControlName="potentialLossNetMax"
                                readonly="true"
                            />
                        </div>
                    </div>

                    <div class="col" *ngIf="enableTargetRisk">
                        <div class="form-group">
                            <label for="potentialLossTargetMin">Target Min</label>
                            <input
                                type="number"
                                data-uiid="risk-potential-loss-target-min-input"
                                class="form-control form-control-sm"
                                name="potentialLossTargetMin"
                                formControlName="potentialLossTargetMin"
                                readonly="true"
                            />
                        </div>

                        <div class="form-group">
                            <label for="potentialLossTargetMax">Target Max</label>
                            <input
                                type="number"
                                data-uiid="risk-potential-loss-target-max-input"
                                class="form-control form-control-sm"
                                name="potentialLossTargetMax"
                                formControlName="potentialLossTargetMax"
                                readonly="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <risk-quantitative-scoring
        *ngIf="showQuantitativeScoring"
        [parentForm]="parentForm"
        [likelihoodRequest]="likelihoodRequest"
        [impactRequest]="impactRequest"
        [grossImpactActive]="grossRiskMatrix.Impact"
        [grossLikelihoodActive]="grossRiskMatrix.Likelihood"
        [netImpactActive]="netRiskMatrix.Impact"
        [netLikelihoodActive]="netRiskMatrix.Likelihood"
    ></risk-quantitative-scoring>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Risk assessment</div>
        <div class="detail-page-card-body padded cerrix-control-sm">
            <div class="fancy-loader submodule" *ngIf="!riskInitialized"></div>

            <div *ngIf="riskInitialized">
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="row">
                            <div class="form-group col">
                                <label for="riskAppetite">
                                    Risk appetite assessment (Based on '{{
                                        risk.riskCatalogue.Name
                                    }}')
                                </label>
                                <input
                                    class="form-control form-control-sm"
                                    id="riskAppetite"
                                    name="riskAppetite"
                                    [class.appetiteNotSet]="
                                        riskAppetiteInLine == RiskAppetiteType.NotSet
                                    "
                                    [class.appetiteInLine]="
                                        riskAppetiteInLine == RiskAppetiteType.InLine
                                    "
                                    [class.appetiteNotInLine]="
                                        riskAppetiteInLine == RiskAppetiteType.NotInLine
                                    "
                                    [value]="riskAppetiteInfo"
                                    disabled
                                    inputTooltip
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="riskTreatmentSelect">Risk treatment</label>
                                <cerrix-select
                                    id="riskTreatmentSelect"
                                    name="riskTreatmentSelect"
                                    formControlName="riskTreatmentId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [getDataMethod]="riskTreatmentRequest"
                                    prettyName="risk treatment"
                                    dataUiid="risk-treatment-select"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="row">
                            <div class="form-group col">
                                <label for="riskAssessmentSelect">Overall risk assessment</label>
                                <cerrix-select
                                    id="riskAssessmentSelect"
                                    name="riskAssessmentSelect"
                                    formControlName="riskAssessmentId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [getDataMethod]="overallAssessmentRequest"
                                    prettyName="overall risk assessment"
                                    dataUiid="overall-risk-assessment-select"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="riskAssessmentDate">Assessment date</label>
                                <date-input
                                    id="riskAssessmentDate"
                                    formControlName="riskAssessmentDate"
                                    data-uiid="assessment-date-input"
                                ></date-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="row">
                            <div
                                [hidden]="riskAppetiteInLine != RiskAppetiteType.NotInLine"
                                class="form-group col"
                            >
                                <cerrix-textarea
                                    fieldLabel="Comment on risk appetite"
                                    identifier="riskAppetiteCommentInput"
                                    [rows]="9"
                                    formControlName="appetiteComment"
                                    dataUiid="risk-appetite-input"
                                ></cerrix-textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Comment on overall risk assessment"
                                    identifier="riskAssessmentCommentInput"
                                    [rows]="9"
                                    formControlName="riskAssessmentComment"
                                    data-uiid="risk-assessment-comment-input"
                                ></cerrix-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Comments and custom fields</div>
        <div class="detail-page-card-body padded cerrix-control-sm">
            <div class="fancy-loader submodule" *ngIf="!riskInitialized"></div>

            <div *ngIf="riskInitialized">
                <div class="row">
                    <div class="col-12 col-xl-6 left-block form-group">
                        <cerrix-textarea
                            fieldLabel="Comments"
                            identifier="commentsInput"
                            [rows]="9"
                            formControlName="comments"
                            data-uiid="risk-comments-input"
                        ></cerrix-textarea>
                    </div>

                    <div class="col-12 col-xl-6 right-block">
                        <cerrix-custom-fields-editor
                            [parentForm]="parentForm"
                            [customFieldData]="customFieldData"
                        >
                        </cerrix-custom-fields-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
