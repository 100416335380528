<div *ngIf="!model || !model.organizationId">Select "Organization" first</div>

<div class="fancy-loader" *ngIf="isLoading && model && model.organizationId"></div>
<risk-linkable
    *ngIf="!isLoading && model && model.organizationId"
    #linkable
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [hideActions]="true"
    [orderByValueProperty]="true"
    (linkedChange)="linkedChanged($event)"
    [disabled]="!permissions.canLinkRisks"
>
</risk-linkable>
