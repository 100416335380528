import { RegexConstants } from "./../constants/RegexConstants";
export function validateVideoSource(url: string) {
    if (url.match(RegexConstants.youtubeRegex)) {
        return true;
    } else if (url.match(RegexConstants.vimeoRegex)) {
        return true;
    }

    return false;
}
