<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    class="fill"
    menuSystem
    [cerrixTab]="tab"
    *ngIf="!tab.showLoader"
    (change)="menuChange($event)"
    data-uiid="application-settings"
>
    <div class="fill" [attr.name]="'Settings'" menuicon="fad fa-cogs" menusystemsubmenu>
        <div
            class="fill settings-page"
            *ngFor="let group of groups"
            [attr.name]="group.module"
            [attr.menuicon]="group.icon"
            menusystemitem
        >
            <div class="search-area">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Filter / search"
                    [(ngModel)]="group.searchFilter"
                    (debounce-input-event)="filter(group.searchFilter, group.settings)"
                    *ngIf="group.allowFilter"
                    inputTooltip
                />
            </div>
            <div class="row" *ngIf="group.module === activeTabName">
                <ng-container *ngFor="let setting of group.settings">
                    <ng-container *ngIf="!group.allowFilter || !setting._hide">
                        <div
                            class="{{
                                setting.ValueType == valueType.Bit
                                    ? 'col-12 col-xl-6 col-xxl-4'
                                    : 'col-12'
                            }}"
                        >
                            <div
                                class="detail-page-card"
                                [attr.data-uiid]="'setting-for-' + setting.ID"
                            >
                                <div class="detail-page-card-title">
                                    <label [for]="'value-' + setting.ID">{{ setting.Name }}</label>
                                </div>
                                <ng-container
                                    *ngTemplateOutlet="settingEditor; context: { setting: setting }"
                                >
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #settingEditor let-setting="setting">
    <ng-container [ngSwitch]="setting.ValueType">
        <!-- Default ValueType (Text) -->
        <ng-container *ngSwitchDefault>
            <div class="detail-page-card-body padded">
                <input
                    [id]="'value-' + setting.ID"
                    type="text"
                    [name]="'textvalue-' + setting.ID"
                    class="form-control"
                    [(ngModel)]="setting.TextValue"
                    (blur)="SaveChange(setting)"
                    inputTooltip
                />
            </div>
        </ng-container>

        <!-- Boolean -->
        <ng-container *ngSwitchCase="valueType.Bit">
            <mat-slide-toggle
                [id]="'value-' + setting.ID"
                name="boolvalue"
                class="boolswitch"
                (change)="UpdateSwitchAndSave($event, setting)"
                [checked]="setting.BoolValue"
                size="small"
                color="primary"
            >
            </mat-slide-toggle>
        </ng-container>

        <!-- Number -->
        <ng-container *ngSwitchCase="valueType.Number">
            <div class="detail-page-card-body padded">
                <input
                    [id]="'value-' + setting.ID"
                    type="number"
                    [name]="'numbervalue-' + setting.ID"
                    class="form-control"
                    [(ngModel)]="setting.NumberValue"
                    (blur)="SaveChange(setting)"
                />
            </div>
        </ng-container>

        <!-- Dropdown -->
        <ng-container *ngSwitchCase="valueType.Dropdown">
            <div class="detail-page-card-body padded">
                <select
                    [id]="'value-' + setting.ID"
                    class="form-select"
                    [name]="'dropdownvalue-' + setting.ID"
                    [(ngModel)]="setting.SelectedDropdownOption"
                    (change)="UpdateDropdownAndSave(setting)"
                >
                    <option *ngFor="let option of setting.DropdownOptions" [value]="option">
                        {{ option }}
                    </option>
                </select>
            </div>
        </ng-container>

        <!-- Base64Img -->
        <ng-container *ngSwitchCase="valueType.Base64Img">
            <div class="detail-page-card-actions">
                <button class="btn btn-raised" (click)="SaveChange(setting)">Save image</button>
                <button
                    class="btn btn-raised red"
                    (click)="setting.SerializedOption = ''; SaveChange(setting)"
                >
                    Clear image
                </button>
            </div>
            <div class="detail-page-card-body padded">
                <input
                    [id]="'value-' + setting.ID"
                    type="file"
                    (change)="Base64ImageChanged($event, setting)"
                    accept="image/*"
                />
                <img
                    [src]="setting.SerializedOption"
                    *ngIf="setting.SerializedOption"
                    class="base64image"
                />
            </div>
        </ng-container>

        <!-- Color -->
        <ng-container *ngSwitchCase="valueType.Color">
            <div class="detail-page-card-actions">
                <button class="btn btn-raised" (click)="SaveColorSetting(setting)">
                    Save color
                </button>
            </div>
            <div class="detail-page-card-body padded">
                <cerrix-color-select
                    [id]="'value-' + setting.ID"
                    [(value)]="setting.SerializedOption"
                >
                </cerrix-color-select>
            </div>
        </ng-container>

        <!-- Json -->
        <ng-container *ngSwitchCase="valueType.Json">
            <div class="detail-page-card-body padded">
                <ng-container *ngIf="setting.isSdDropDown">
                    <select
                        [id]="'value-' + setting.ID"
                        class="form-select"
                        [name]="'dropdownvalue-' + setting.ID"
                        [(ngModel)]="setting.SelectedDropdownOption"
                        (change)="UpdateSdDropdownAndSave(setting)"
                    >
                        <option *ngFor="let option of setting.DropdownOptions" [value]="option">
                            {{ option }}
                        </option>
                    </select>

                    <select
                        [id]="'sddropdownsettings-' + setting.ID"
                        class="form-select mt-2"
                        [name]="'sddropdownsettings-' + setting.ID"
                        [disabled]="
                            !standingDataDropDownOptions[
                                setting.Name + '-' + setting.SelectedDropdownOption
                            ]
                        "
                        [(ngModel)]="setting.selectedStandingDataDropdownId"
                        (change)="UpdateSdDropdownAndSave(setting)"
                    >
                        <option
                            *ngFor="
                                let option of standingDataDropDownOptions[
                                    setting.Name + '-' + setting.SelectedDropdownOption
                                ]
                            "
                            [value]="option.ID"
                        >
                            {{ option.Name }}
                        </option>
                    </select>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
