export class WidgetFilterKey {
    public static GlobalFilter = {
        Organization: "Organization",
        OrganizationIncludeChildren: "OrganizationIncludeChildren",
        BusinessDimension: "BusinessDimension",
        BusinessDimensionIncludeChildren: "BusinessDimensionIncludeChildren",
        FrameworkDimension: "FrameworkDimension",
        FrameworkDimensionIncludeChildren: "FrameworkDimensionIncludeChildren",
        Period: "Period",

        // This will be used in a more central place in the future.
        WorkspacePreset: "WorkspacePreset",
    };

    public static QuickLink = {
        FontSize: "FontSize",
        LinkContent: "LinkContent",
    };

    public static ModuleOverview = {
        ...WidgetFilterKey.QuickLink,
    };

    public static ModuleDetail = {
        CustomTitle: "CustomTitle",
        Module: "Module",
        WorkspacePreset: WidgetFilterKey.GlobalFilter.WorkspacePreset,
    };

    public static Insights = {
        ...WidgetFilterKey.QuickLink,

        ReportToEmbed: "ReportToEmbed",
        ReportSkipLoadCheck: "ReportSkipLoadCheck",
    };

    public static Navigators = {
        ...WidgetFilterKey.QuickLink,
    };

    public static Admin = {
        ...WidgetFilterKey.QuickLink,
    };

    public static Calendar = {
        ShowActions: "ShowActions",
    };

    public static RiskMatrix = {
        ShowSummary: "ShowSummary",
        NetOrGross: "NetGross",
        ImpactScale: "ImpactScale",
        RiskCatalogue: "RiskCatalogue",
    };

    public static Kri = {
        KriType: "KriType",
        KriGuid: "KriGuid",
    };

    public static Moi = {
        MoiTypes: "MoiTypes",
    };

    public static ControlOverdue = {
        EffOrDI: "EffOrDI",
    };

    public static Forms = {
        FormIDs: "FormIDs",
        LinkToNewFormEntry: "LinkToNewFormEntry",
        ...WidgetFilterKey.QuickLink,
    };

    public static FindingsReport = {
        ReportType: "ReportType",
    };

    public static TextWidget = {
        Text: "Text",
    };

    public static VideoWidget = {
        Url: "Url",
    };

    public static GraphOptions = {
        GraphType: "GraphType",
    };

    public static ProcessLink = {
        LinkTo: "LinkTo",
    };
}
