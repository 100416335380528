import { Component, OnInit } from "@angular/core";
import { ControlConfigModel } from "@app/controls/models/ControlConfigModel";
import { ModuleData } from "@app/process-editor/models/ModuleData";
import { Pages } from "@constants/pages/Pages";
import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { IModule } from "@models/processeditor/DiagramData";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { TabService } from "@services/tabs/TabService";
import { Observable } from "rxjs";
import { BaseShapeProperty } from "../BaseShapeProperty";

@Component({
    selector: "process-editor-control-shape-properties",
    templateUrl: "./control-shape-properties.component.html",
    styleUrls: ["./control-shape-properties.component.scss"],
})
export class ControlShapePropertiesComponent extends BaseShapeProperty implements OnInit {
    data: ModuleData;
    controlObservable: Observable<IModule[]>;

    constructor(
        private _ds: ProcessEditorDataService,
        private pages: Pages,
        private tabService: TabService
    ) {
        super();
    }

    ngOnInit() {}

    load(data: ModuleData) {
        this.data = data;
        this.setObservable();
        this.loadModuleConfig(data.graph, data.selectedCell);
    }

    openNew() {
        const config = <ControlConfigModel>{
            organizationId: this.data.currentOrganizationId,
            businessIds: [+this.data.currentBusinessDimensionId],
        };

        const tab = this.tabService.getTab(
            this.pages.ControlDetail,
            null,
            `(C) New Control`,
            config
        );

        const listenerID = this.tabService.listeners.addTabListener(
            TabEventListenerType.OnSave,
            tab.identifier,
            (guid) =>
                this.onSave(guid, this.data.graph, this.data.selectedCell).then(() => {
                    this.tabService.listeners.removeListener(listenerID);
                }),
            this.data.tab
        );

        this.tabService.addTab(tab);
    }

    isReadOnly(): boolean {
        return this.data.isReadOnly;
    }

    getItemEnum(): ItemEnum {
        return ItemEnum.Control;
    }

    open(module: IModule) {
        const tab = this.tabService.getTab(this.pages.ControlDetail, module.guid);
        this.tabService.addTab(tab);
    }

    getObservable(): Observable<IModule[]> {
        return this.controlObservable;
    }

    setObservable(): void {
        this.controlObservable = this._ds.getProcessControls(
            -1,
            this.data.currentOrganizationId,
            this.data.currentBusinessDimensionId
        );
    }
}
