import { Component, ViewEncapsulation } from "@angular/core";
import { CalendarDataService } from "@app/calendar/services/calendar-data.service";
import { WidgetFilterKey } from "./../../shared/models/WidgetFilterKey";
import { BaseWidget } from "./../BaseWidget";

@Component({
    selector: "calendar-widget",
    templateUrl: "./calendar-widget.component.html",
    styleUrls: ["./calendar-widget.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CalendarWidgetComponent extends BaseWidget {
    get showActions() {
        return this.getFilter(WidgetFilterKey.Calendar.ShowActions, false);
    }

    constructor(private _calendarDS: CalendarDataService) {
        super();
    }

    load() {
        super.load();
        this._calendarDS.loadCalendar();
    }
}
