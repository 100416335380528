// Never change the index of these items. Always up by one.
// For sort order of items just move them to correct location.
export enum ComparisonType {
    'Contains' = 0,
    'Does not contain' = 1,
    'Is equal to' = 2,
    'Is not equal to' = 3,
    'Begins with' = 4,
    'Ends with' = 5,
    'After/Greater than' = 6,
    'Equal or after/greater than' = 8,
    'Before/Less than' = 7,
    'Equal or before/less than' = 9
  }