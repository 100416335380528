import { Component } from "@angular/core";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DataStructureModel } from "@app/datamanagement/models/DataStructureModel";

@Component({
    selector: "archivepolicy-dialog",
    templateUrl: "./archivepolicy-dialog.component.html",
    styleUrls: ["./archivepolicy-dialog.component.scss", "../../../../sass/datamanagement.scss"],
})
export class ArchivePolicyDialogComponent extends BaseModal {
    dataStructures: DataStructureModel[];
    disabled: boolean;

    constructor(_bsModalRef: BsModalRef) {
        super(_bsModalRef);
    }

    // Base modal implementation
    okClick() {
        this._bsModalRef.hide();

        super.onConfirm(this.dataStructures);
    }

    closeClick() {
        super.onCancel();
    }
}
