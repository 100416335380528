<div class="detail-page-summary" *ngIf="backupModel && backupModel.createdOn">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>Identifier:</b> {{ backupModel.identifier }}</div>
            <div class="property">
                <b>Date created:</b> {{ backupModel.createdOn | dateTimeFormat }}
            </div>
        </div>
        <div class="col-6">
            <div class="property"><b>Created by:</b> {{ backupModel.createdBy }}</div>
            <div class="property"><b>Modified By:</b> {{ backupModel.modifiedBy }}</div>
        </div>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Details</div>
    <div class="detail-page-card-body padded form-group-styling">
        <form [formGroup]="parentFormGroup">
            <div class="row">
                <div class="col-6">
                    <cerrix-textfield
                        fieldLabel="Finding name"
                        identifier="findingNameInput"
                        formControlName="findingName"
                        dataUiid="audit-finding-name"
                        placeholder="Required"
                    ></cerrix-textfield>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="auditeeSelect">Auditee(s)</label>
                        <cerrix-select
                            id="auditeeSelect"
                            [multiple]="true"
                            dataUiid="audit-finding-auditee"
                            formControlName="findingAuditees"
                            prettyName="Auditee(s)"
                            [getDataMethod]="auditeeRequest$"
                            bindValue="ID"
                            bindLabel="Name"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <cerrix-textarea
                        dataUiid="audit-finding-findingdescription"
                        fieldLabel="Finding description"
                        formControlName="findingDescription"
                        placeholder="Required"
                    ></cerrix-textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <cerrix-textarea
                        dataUiid="audit-finding-root-cause"
                        fieldLabel="Root Cause"
                        formControlName="rootCause"
                    ></cerrix-textarea>
                </div>
                <div class="col-6">
                    <cerrix-textarea
                        dataUiid="audit-finding-recommendation"
                        fieldLabel="Recommendation"
                        formControlName="recommendation"
                    ></cerrix-textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-6 form-group">
                    <label for="auditFindingSeverity">Severity</label>
                    <cerrix-select
                        id="auditFindingSeverity"
                        prettyName="severity"
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="severityId"
                        [getDataMethod]="getSeverities()"
                        dataUiid="audit-finding-severity-select"
                    >
                    </cerrix-select>
                </div>
                <div class="col-6 form-group">
                    <label for="auditFindingFollowUp">Follow-up</label>
                    <cerrix-select
                        id="auditFindingFollowUp"
                        prettyName="Follow-up"
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="followUpIds"
                        [getDataMethod]="getFollowUpData()"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        dataUiid="audit-finding-followup-select"
                    >
                    </cerrix-select>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <cerrix-textarea
                        dataUiid="audit-finding-comment"
                        fieldLabel="Comment"
                        formControlName="comment"
                    ></cerrix-textarea>
                </div>
                <div class="col-6">
                    <cerrix-textarea
                        dataUiid="audit-finding-managementResponse"
                        fieldLabel="Management Response"
                        formControlName="managementResponse"
                        [readonly]="!permissions.canEditManagementResponse"
                    ></cerrix-textarea>
                </div>
            </div>
        </form>
    </div>
</div>
