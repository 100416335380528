import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormField, FormFieldConfig } from '@app/forms/models/detail/FormField';
import { FormFieldType } from '@app/forms/enums/FormFieldTypes';
import { parseTyped } from '@methods/CommonMethods';

@Component({
    selector: "form-entry-options",
    templateUrl: "./form-entry-options.component.html",
    styleUrls: ["../../form-entry-field.component.scss"]
})
export class FormEntryOptionsComponent implements OnInit {
    @Input() field: FormField;
    @Input() answer: string;
    @Input() readonly: boolean;
    @Output() answerChange: EventEmitter<string> = new EventEmitter<string>();

    singleSelected: string;
    multiSelected: string[];
    multiSeperator = ';';

    optionSettings = new FormFieldConfig();
    types = FormFieldType;

    constructor() { }
    ngOnInit() {
        this.optionSettings = parseTyped<FormFieldConfig>(this.field.CustomSettings, new FormFieldConfig());
        if (!this.optionSettings.options || this.optionSettings.options.length === 0) {
            this.optionSettings.options = [];
        }

        this.processAnswer();
    }

    processAnswer() {
        if (this.optionSettings.multiSelect || this.field.FieldType === this.types.Checkbox) {
            this.multiSelected = [];
            if (this.answer && this.answer.trim().length > 0) {
                if (this.answer.indexOf(this.multiSeperator) > 0) {
                    this.multiSelected = this.answer.split(this.multiSeperator).map(x => x);
                } else {
                    this.multiSelected.push(this.answer);
                }

                this.multiSelected = this.multiSelected.filter(x => this.optionSettings.options.indexOf(x) >= 0);
            }
        } else {
            if (this.optionSettings.options.indexOf(this.answer) >= 0) {
                this.singleSelected = this.answer;
            }
        }

        this.updateFieldName();
    }

    updateRadio(selected: string) {
        this.singleSelected = selected;
        this.answer = '' + this.singleSelected;
        this.emitChange();
    }

    updateCheckbox(clicked: string) {
        const clickedIndex = this.multiSelected.indexOf(clicked);
        if (clickedIndex >= 0) {
            this.multiSelected.splice(clickedIndex, 1);
        } else {
            this.multiSelected.push(clicked);
        }

        this.sortMultiselect();
        this.answer = this.multiSelected.join(this.multiSeperator);
        this.emitChange();
    }

    updateDropdown(selected: string | string[]) {
        if (this.optionSettings.multiSelect) {
            this.multiSelected = selected as string[];
            this.sortMultiselect();
            this.answer = this.multiSelected.join(this.multiSeperator);
        } else {
            this.singleSelected = selected as string;
            this.answer = '' + this.singleSelected;
        }
        this.emitChange();
    }

    emitChange() {
        this.answerChange.emit(this.answer);
    }

    sortMultiselect() {
        const options = this.optionSettings.options;
        this.multiSelected.sort(function (a, b) {
            return options.indexOf(a) - options.indexOf(b);
        });
    }

    updateFieldName() {
        this.field.AdditionalLabel = '';
        if ((!this.field.MapBackField || this.field.MapBackField === 0) &&
            (this.field.FieldType === this.types.Checkbox ||
                (this.field.FieldType === this.types.Dropdown && this.optionSettings.multiSelect))) {
            if (this.optionSettings.minimumSelection === this.optionSettings.maximumSelection && this.optionSettings.minimumSelection > 0) {
                this.field.AdditionalLabel = `Select ${this.optionSettings.minimumSelection} options.`;
            } else {
                if (this.optionSettings.minimumSelection > 0) {
                    this.field.AdditionalLabel += `minimum ${this.optionSettings.minimumSelection} answer${this.optionSettings.minimumSelection > 1 ? 's' : ''}`;
                }

                if (this.optionSettings.maximumSelection > 0) {
                    if (this.field.AdditionalLabel) {
                        this.field.AdditionalLabel += ', ';
                    }

                    this.field.AdditionalLabel += `maximum ${this.optionSettings.maximumSelection} answer${this.optionSettings.maximumSelection > 1 ? 's' : ''}`;
                }
            }
        }
    }
}