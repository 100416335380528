import { Component, Input, OnInit } from "@angular/core";
import {
    EffectivenessModel,
    EffectivenessSourceDocumentsModel,
    WorkflowStepPermissionModel,
} from "../../../shared/models";
import { SampleGenerationType, SampleType } from "../../../shared/enums";
import { AdvEffDataService } from "../../../shared/services";
import { ExcelHelper } from "@app/shared/helpers/excel-helper";

@Component({
    selector: "eff-source-document-info-box",
    templateUrl: "./eff-source-document-info-box.component.html",
    styleUrls: ["./eff-source-document-info-box.component.scss"],
})
export class EffSourceDocumentInfoBoxComponent {
    @Input() isCollapsed = false;
    @Input() effectivenessModel: EffectivenessModel;
    @Input() stepPermissions: WorkflowStepPermissionModel;
    @Input() hideSampling: boolean = true;
    @Input() panelOpenState: boolean = false;

    sourceDocumentModel: EffectivenessSourceDocumentsModel;
    sampleGenerationType = SampleGenerationType;
    columnId: string;
    rowsToSkip: number;

    constructor(private _ds: AdvEffDataService) {}

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        if (this.effectivenessModel.guid && !this.sourceDocumentModel) {
            this._ds.getSourceDocuments(this.effectivenessModel.guid).subscribe((data) => {
                this.sourceDocumentModel = data;
                if (this.isSpreadSheet()) {
                    const columnName = ExcelHelper.convertNumberToExcelColumn(
                        this.sourceDocumentModel.idColumn
                    );

                    this.columnId = columnName;
                    this.rowsToSkip = this.sourceDocumentModel.rowsToSkip;
                }
            });
        }
    }

    isSpreadSheet() {
        return this.effectivenessModel.sampleType == SampleType.spreadsheet;
    }
}
