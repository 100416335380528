import { TabService } from "./../../services/tabs/TabService";
import { DashboardConstants } from "./shared/DashboardConstants";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { GridsterConfig, GridsterItem, GridsterItemComponentInterface } from "angular-gridster2";
import { DashboardWidgetMapping } from "./shared/DashboardWidgetMapping";
import { DashboardModel } from "./shared/models/DashboardModel";
import { DashboardWidgetCustomConfigurations } from "./shared/models/DashboardWidgetCustomConfigurations";
import { DashboardWidgetModel } from "./shared/models/DashboardWidgetModel";
import { parseTyped } from "@methods/CommonMethods";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";

declare var $: any;

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
    tab: TabModel;

    dashboard: DashboardModel;
    gridster: GridsterConfig;
    widgets: DashboardWidgetModel[];

    editMode = false;
    showMainConfig = false;
    showMarket = false;
    showConfigFor: DashboardWidgetModel = null;

    _minColSetterTimeout;
    _gridsterScrollTimeout = null;
    _tempMenuCache;

    constructor(private tabService: TabService) {}

    ngOnInit() {
        this.tabService.listeners.addTabListener(
            TabEventListenerType.OnFocus,
            this.tab.identifier,
            () => {
                // If a (re)load happened while the tab was not active, the grids will collapse. This will make sure it is sized correctly again if this ever happens.
                if (this.gridster && this.gridster.api && this.gridster.api.resize) {
                    // We will give the frontend time to adjust itself. Otherwise we will check if the grid is still collapsed and fix it manually.
                    setTimeout(() => {
                        const gridsterItem = $("gridster-item")[0];
                        if (gridsterItem.offsetHeight < 5) {
                            this.gridster.api.resize();
                        }
                    }, 100);
                }
            },
            this.tab
        );
    }

    loadDashboard(dashboard: DashboardModel) {
        this.tab.showLoader = true;

        this.initDashboard(dashboard);
        this.initGridster();

        if (this.dashboard._editOnLoad) {
            this.dashboard._editOnLoad = false;
            this.toggleEdit();
            this.showMainConfig = true;
        }

        this.tab.showLoader = false;
    }

    private initDashboard(dashboard: DashboardModel) {
        this.dashboard = dashboard;
        if (!this.dashboard.globalFilterValues) {
            this.dashboard.globalFilterValues = {};
        }
    }

    private async saveDashboard() {
        this.dashboard.widgetConfigurations = JSON.stringify(
            this.widgets.map((x) => x.customConfig)
        );
        this.dashboard.screenSize = window.screen.width + "x" + window.screen.height;

        // this.validate();
        const result = await this.dashboard.api.save();
        return result;
    }

    private initGridster() {
        this.widgets = [];
        if (this.dashboard.widgetConfigurations) {
            const configurations = parseTyped(
                this.dashboard.widgetConfigurations,
                []
            ) as DashboardWidgetCustomConfigurations[];
            configurations.forEach((wc) => this.addWidget(wc));
        }

        this.gridster = {
            fixedColWidth: DashboardConstants.cellSizing,
            fixedRowHeight: DashboardConstants.cellSizing,
            defaultItemCols: 2,
            defaultItemRows: 2,
            enableEmptyCellDrop: true,
            pushItems: true,
            gridType: "fixed",
            emptyCellDropCallback: this.emptyCellClick.bind(this),
            itemChangeCallback: this.itemChange.bind(this),
            itemResizeCallback: this.itemResize.bind(this),
        };

        this.setGridMinSize();
        this.changedOptions();
    }

    private itemChange(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
        this.setGridMinSize();
    }

    private itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
        const resizedObject = this.widgets.find((x) => x.customConfig.gridsterConfig === item);
        if (resizedObject && resizedObject.api.onResizeCallback) {
            // Make sure the component has enough time to receive the updated sizes.
            setTimeout(() => {
                resizedObject.api.onResizeCallback();
            }, 0);
        }

        this.setGridMinSize();
    }

    changedOptions() {
        if (this.editMode) {
            this.gridster.draggable = { enabled: true };
            this.gridster.resizable = { enabled: true };
            this.gridster.displayGrid = "always";
        } else {
            this.gridster.draggable = { enabled: false };
            this.gridster.resizable = { enabled: false };
            this.gridster.displayGrid = "none";
        }

        if (this.gridster.api && this.gridster.api.optionsChanged) {
            this.gridster.api.optionsChanged();
        }
    }

    async toggleEdit() {
        if (this.editMode) {
            if (!(await this.saveDashboard())) {
                return;
            }

            this.editMode = false;
            this.showMarket = false;
            this.showMainConfig = false;
            this.showConfigFor = null;

            this.tab.menu = this._tempMenuCache;
            this._tempMenuCache = undefined;
        } else {
            this.editMode = true;
            this._tempMenuCache = this.tab.menu;
            this.tab.menu = undefined;
        }

        this.setGridMinSize(true);
        this.changedOptions();
    }

    openMainSettings() {
        this.showConfigFor = null;
        this.showMarket = false;
        this.showMainConfig = true;
    }

    openMarket() {
        this.showConfigFor = null;
        this.showMainConfig = false;
        this.showMarket = true;
    }

    openConfig(item: DashboardWidgetModel, ev: MouseEvent) {
        // We need this timeout because otherwise the tree holds the incorrect value (ticket 7607)
        setTimeout(() => {
            this.showMainConfig = false;
            this.showMarket = false;
            this.showConfigFor = item;
        }, 0);
        ev.stopPropagation();

        this.showMainConfig = false;
        this.showMarket = false;
        this.showConfigFor = null;

        // We need this timeout so the page can show config for because the next method calculates where to scroll the editing item.
        setTimeout(() => {
            this.scrollWidgetIntoView(ev);
        }, 0);
    }

    hidePopup() {
        this.showMarket = false;
        this.showMainConfig = false;
        this.showConfigFor = null;
    }

    removeItem(item: DashboardWidgetModel, ev: MouseEvent) {
        ev.stopPropagation();

        if (!item.api.onDelete || item.api.onDelete()) {
            this.widgets.splice(this.widgets.indexOf(item), 1);
            this.setGridMinSize(true);
            this.changedOptions();
        }
    }

    addWidget(config: DashboardWidgetCustomConfigurations) {
        const baseCopy = DashboardWidgetMapping.get(config.type);
        baseCopy.customConfig = config;

        this.initWidgetModelSubObjects(baseCopy);
        this.widgets.push(baseCopy);
    }

    private initWidgetModelSubObjects(widget: DashboardWidgetModel) {
        widget.api = {};

        if (!widget.customConfig.filterValues) {
            widget.customConfig.filterValues = {};
        }

        if (widget.configurations) {
            widget.configurations.forEach((filter) => {
                if (widget.customConfig.filterValues[filter.fieldName] == undefined) {
                    widget.customConfig.filterValues[filter.fieldName] = filter.defaultValue;
                }
            });
        }

        widget.customConfig.gridsterConfig.minItemRows = widget.minRows;
        widget.customConfig.gridsterConfig.minItemCols = widget.minCols;

        if (widget.minRows) {
            widget.customConfig.gridsterConfig.rows = Math.max(
                widget.minRows,
                widget.customConfig.gridsterConfig.rows
            );
        }

        if (widget.minCols) {
            widget.customConfig.gridsterConfig.cols = Math.max(
                widget.minCols,
                widget.customConfig.gridsterConfig.cols
            );
        }
    }

    emptyCellClick(event: DragEvent, item: GridsterItem) {
        const draggingMarketItem = JSON.parse(
            event.dataTransfer.getData("text")
        ) as DashboardWidgetCustomConfigurations;

        if (draggingMarketItem) {
            draggingMarketItem.gridsterConfig = item;
            this.addWidget(draggingMarketItem);
        }
    }

    private setGridMinSize(instant?: boolean) {
        const func = () => {
            let maxCol = 0;
            let maxRow = 0;

            this.widgets.forEach((item) => {
                if (item.customConfig.gridsterConfig) {
                    const itemColMax =
                        item.customConfig.gridsterConfig.x + item.customConfig.gridsterConfig.cols;
                    const itemRowMax =
                        item.customConfig.gridsterConfig.y + item.customConfig.gridsterConfig.rows;
                    if (itemColMax > maxCol) {
                        maxCol = itemColMax;
                    }

                    if (itemRowMax > maxRow) {
                        maxRow = itemRowMax;
                    }
                }
            });

            const addExtra = this.editMode ? 3 : 0;
            this.gridster.minCols = maxCol + addExtra;
            this.gridster.minRows = maxRow + addExtra;
        };

        if (this._minColSetterTimeout) {
            clearTimeout(this._minColSetterTimeout);
            this._minColSetterTimeout = null;
        }

        if (instant) {
            func();
        } else {
            this._minColSetterTimeout = setTimeout(() => {
                func();
                this._minColSetterTimeout = null;
            }, 200);
        }
    }

    onScroll(event) {
        if (this._gridsterScrollTimeout) {
            clearTimeout(this._gridsterScrollTimeout);
            this._gridsterScrollTimeout = null;
        }

        this._gridsterScrollTimeout = setTimeout(() => {
            this.widgets.forEach((w) => {
                if (w.api.onGridScroll) {
                    w.api.onGridScroll();
                }
            });
            this._gridsterScrollTimeout = null;
        }, 250);
    }

    private scrollWidgetIntoView(ev: MouseEvent) {
        const widgetElement = $($(ev.target).closest("gridster-item")[0]);
        const wO = widgetElement.offset();
        const widgetHeight = widgetElement.outerHeight();
        const widgetWidth = widgetElement.outerWidth();

        const parentContainer = $($(widgetElement).parent()[0]);
        const pO = parentContainer.offset();
        const parentHeight = parentContainer.outerHeight();
        const parentWidth = parentContainer.outerWidth();

        const scrollLeftMin = wO.left - pO.left;
        const scrollTopMin = wO.top - pO.top;

        const scrollLeftTo = scrollLeftMin - (parentWidth / 2 - widgetWidth / 2);
        const scrollTopTo = scrollTopMin - (parentHeight / 2 - widgetHeight / 2);

        parentContainer[0].scrollBy({
            top: scrollTopTo,
            left: scrollLeftTo,
            behaviour: "smooth",
        });
    }
}
