<eff-select
    class="score-selector"
    panelClass="score-selector-panel"
    [options]="options"
    [(model)]="model"
    [formController]="formController"
    isTemplate="true"
    [selectedOptionTemplate]="selectedOptionTemplate"
    [optionTemplate]="optionTemplate"
    [disabled]="disabled"
    [class.disabled]="this.disabled"
    #selector
>
    <ng-template #selectedOptionTemplate let-item="selectedOption">
        <div
            [style.background-color]="item?.BackgroundColor"
            [style.border-color]="item?.BackgroundColor"
            [style.color]="item?.Color"
            class="selected-option-content d-flex flex-row align-items-center justify-content-between"
        >
            <div class="option-text" [style.color]="item?.Color">{{ item?.Label }}</div>
            <mat-icon [style.color]="item?.Color" class="arrow-icon">keyboard_arrow_down</mat-icon>
        </div>
    </ng-template>
    <ng-template #optionTemplate let-item="option">
        <div class="d-flex flex-row align-items-center icon-text-container">
            <mat-icon [style.color]="item.BackgroundColor">adjust</mat-icon>
            <div>{{ item.Label }}</div>
        </div>
    </ng-template>
</eff-select>
