import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IdNameDescriptionModel } from "@models/generic/IdNameDescriptionModel";
import { PresetConfig } from "@models/workspace/Preset";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "../../../app.constants";

@Injectable()
export class WorkspacePresetService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "workspacepreset");
    }

    public store(preset: PresetConfig) {
        return this.post<number>(preset);
    }

    public remove(id: number) {
        return super.delete(`/${id}`);
    }

    public getPreset(module: string, id: number) {
        return this.get<PresetConfig>(`/preset?module=${module}&id=${id}`);
    }

    public getPresets(module: string) {
        return this.get<PresetConfig[]>(`/preset?module=${module}`);
    }

    public setDefault(presetID: number, module: string) {
        return this.post(null, `/${presetID}/default?module=${module}`);
    }

    public setSystemDefault(presetID: number, module: string) {
        return this.post(null, `/${presetID}/systemdefault?module=${module}`);
    }

    public public(presetID: number, module: string) {
        return this.put(null, `/${presetID}/public?module=${module}`);
    }

    public unpublic(presetID: number, module: string) {
        return this.put(null, `/${presetID}/unpublic?module=${module}`);
    }

    public favorite(presetID: number) {
        return this.put(null, `/${presetID}/favorite`);
    }

    public unfavorite(presetID: number) {
        return this.put(null, `/${presetID}/unfavorite`);
    }

    getRoleGroups() {
        return this.get<IdNameDescriptionModel[]>("/rolegroups");
    }
}
