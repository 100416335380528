import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LinkedRiskModel } from "../../shared/models/linked-risk-model";

@Component({
    selector: "adv-eff-linked-risks",
    templateUrl: "./eff-linked-risks.component.html",
    styleUrls: ["./eff-linked-risks.component.scss"],
})
export class LinkedRisksComponent {
    @Input() linkedRisks: LinkedRiskModel[];

    @Output() readonly riskClicked: EventEmitter<LinkedRiskModel> =
        new EventEmitter<LinkedRiskModel>();

    onRiskClick(risk: LinkedRiskModel) {
        this.riskClicked.emit(risk);
    }
}
