import { CerrixPromptService } from "../../../../../shared/services/cerrix-prompt.service";
import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { DevManagementDataService } from "@app/admin/services/devmanagement.service";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { GenericEditWindowButton } from "@app/shared/models/GenericList/GenericManagerConfig";

@Component({
    selector: "dev-keys",
    templateUrl: "./dev-keys.component.html",
    styleUrls: ["./dev-keys.component.scss"],
})
export class DevKeysComponent {
    config: GenericListConfig;
    isEnabled = false;
    isLoading = true;

    constructor(
        private dataService: DevManagementDataService,
        private promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        var data = await this.dataService.getApplicationKeys();
        let idCounter = 1;
        data.forEach((i) => {
            try {
                i.id = idCounter++;
            } catch {}
        });

        this.createListConfig(data);
        this.isLoading = false;
    }

    private createListConfig(data: any[]) {
        let buttonConfig = this.createButtonConfig();

        this.config = <GenericListConfig>{
            name: "Application Keys",
            idProp: "id",
            data: data,
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xxl",
            editWindowButtons: buttonConfig,
            fields: [
                {
                    fieldName: "KeyType",
                    prettyName: "Key Type",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: "Updated",
                    prettyName: "Updated",
                    required: true,
                    fieldType: GenericListFieldType.DateTime,
                    isReadonly: true,
                },

                {
                    fieldName: "Updater",
                    prettyName: "Updater",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: "Reason",
                    prettyName: "Reason",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    isReadonly: true,
                },
            ],
        };
    }

    createButtonConfig(): GenericEditWindowButton[] {
        let generateButton = {
            text: "Generate new key",
            class: "btn btn-warning",
            icon: "far fa-key",
            uiid: "button-generate",
            clickEvent: async (row: any) => {
                if (
                    !(await this.promptService
                        .confirm(
                            "Generate Key",
                            `Are you sure you want to generate a new key for '${row.KeyType}'?`
                        )
                        .toPromise())
                ) {
                    return false;
                }

                let randomNumber = this.getRandomInt(10000, 99999);
                let validation = await this.promptService
                    .prompt({
                        data: {
                            title: "Generate Key Security Check",
                            message: `To make sure this is not an accident, please enter your reason the following number: ${randomNumber}`,
                            fields: [
                                {
                                    fieldType: GenericListFieldType.TextArea,
                                    fieldName: "reason",
                                    prettyName: "Reason",
                                    required: true,
                                },
                                {
                                    fieldType: GenericListFieldType.Number,
                                    fieldName: "number",
                                    prettyName: "Number",
                                    defaultValue: 10000,
                                    minimumValue: 10000,
                                    maximumValue: 99999,
                                    required: true,
                                },
                            ],
                        },
                    })
                    .toPromise();
                if (!validation) {
                    return false;
                }

                let number = validation.number;
                if (number != randomNumber) {
                    this._toastr.warning("Invalid validation number");
                    return false;
                }

                await this.dataService.generateApplicationKey(row.KeyType, validation.reason);
                this._toastr.success("New key has been generated");

                return true;
            },
        };

        return [generateButton];
    }

    getRandomInt(min: number, max: number) {
        let x = max - min;
        let rnd = Math.floor(Math.random() * x);
        return rnd + min;
    }
}
