import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { PermissionModel } from "@models/permissions/permission.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export abstract class BaseMicroservicePermissionService<T extends number> {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl: string;

    public abstract microserviceModule: MicroserviceModule;

    protected constructor(apiUrl: string) {
        this.baseUrl = `${apiUrl}/api/permissions`;
    }

    public getPermissions(): Observable<PermissionModel<T>[]> {
        return this.httpClient.get<PermissionModel<T>[]>(this.baseUrl);
    }
}
