import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { MoiFeasibilityModel } from "@app/moi/shared/moi-feasibility-model";
import { MoiCommentEditorParameters } from "@app/moi/shared/moi-comment-editor-parameters";
import { MoiCommentModel } from "@app/moi/shared/moi-comment-model";
import { MoiCommentHistoryModel } from "@app/moi/shared/moi-comment-history-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { toPromise } from "@methods/CommonMethods";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "moi-comments-dialog",
    templateUrl: "./moi-comments-dialog.component.html",
    styleUrls: ["./moi-comments-dialog.component.scss"],
})
export class MoiCommentsDialogComponent implements OnInit {
    moiGuid: string;
    comment: MoiCommentModel;

    title: string;
    showProgress = true;
    showFeasibility = true;
    commentRequired = true;
    checkProgress = false;
    checkProgressSubMessage: string;

    get maxCommentRows(): number {
        return 8 + (this.showProgress ? 0 : 3) + (this.showFeasibility ? 0 : 3);
    }

    loading = true;
    feasibilities: MoiFeasibilityModel[];
    histories: MoiCommentHistoryModel[];
    commentForm: FormGroup;
    columnsToDisplay: string[] = ["modifiedAt", "action", "modifiedBy", "comment"];

    constructor(
        private moiDataService: MoiDataService,
        private promptService: CerrixPromptService,
        public dialogRef: MatDialogRef<MoiCommentsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) parameters: MoiCommentEditorParameters
    ) {
        this.moiGuid = parameters.moiGuid;
        this.comment = parameters.comment;

        if (parameters.config) {
            const config = parameters.config;

            this.showProgress = !config.hideProgress;
            this.showFeasibility = !config.hideFeasibility;
            this.title = config.title;
            this.commentRequired = config.commentRequired;
            this.checkProgress = config.checkProgress;
            this.checkProgressSubMessage = config.checkProgressSubMessage;
        }

        this.commentForm = new FormGroup({
            progress: new FormControl(this.comment.progress),
            feasibilityId: new FormControl(this.comment.feasibilityId),
            comment: new FormControl(
                this.comment.comment,
                this.commentRequired ? Validators.required : null
            ),
        });

        if (this.comment.id && !this.comment.canEdit) {
            this.commentForm.disable();
        }
    }

    ngOnInit(): void {
        this.moiDataService.getFeasibilities().subscribe((value) => {
            this.feasibilities = value;
            this.loading = false;
        });
    }

    async saveClick() {
        FormValidationHelper.mapToModel(this.commentForm, this.comment);
        if (this.checkProgress) {
            const confirmedProgress = await this.isProgressComplete(
                this.moiGuid,
                this.checkProgressSubMessage,
                this.comment
            );

            if (!confirmedProgress) {
                return;
            }
        }

        this.dialogRef.close(this.comment);
    }

    private async isProgressComplete(
        moiGuid: string,
        subMessage: string,
        comment: MoiCommentModel
    ): Promise<boolean> {
        if (comment.progress === 100) {
            return true;
        }

        const progress = await toPromise(this.moiDataService.getProgress(moiGuid));
        if (progress === 100) {
            return true;
        }

        if (
            await this.promptService
                .confirm(
                    "Confirm MoI progress",
                    `This MoI is not yet at a 100% progress, ${subMessage}`
                )
                .toPromise()
        ) {
            return true;
        }

        return false;
    }

    tabChange(event: MatTabChangeEvent) {
        if (event.index === 1 && !this.histories) {
            this.moiDataService
                .getCommentHistory(this.moiGuid, this.comment.guid)
                .subscribe((histories) => {
                    this.histories = histories;
                });
        }
    }
}
