import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { Pages } from "@constants/pages/Pages";
import { TargetModule } from "@enums/document/TargetModule";
import { toPromise } from "@methods/CommonMethods";
import { DocumentMethods } from "@methods/DocumentMethods";
import { DocumentDetailsParameters } from "@models/documents/DocumentDetailsParameters";
import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { TabService } from "@services/tabs/TabService";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Observable, firstValueFrom } from "rxjs";
import { DocumentManagerDetailsComponent } from "./document-manager-details/document-manager-details.component";
import {
    SortService,
    FilterService,
    ReorderService,
    ResizeService,
    ColumnMenuService,
} from "@syncfusion/ej2-angular-grids";
import { CerrixGridColumnConfig } from "../models/cerrix-grid-editor-config";
import { DocumentManagerGenerateComponent } from "./document-manager-generate/document-manager-generate.component";
import { PopupService } from "../services/popup/popup.service";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "document-manager",
    templateUrl: "./document-manager.component.html",
    styleUrls: ["./document-manager.component.scss"],
    providers: [SortService, FilterService, ReorderService, ResizeService, ColumnMenuService],
})
export class DocumentManagerComponent implements OnChanges {
    // Actual DocumentBinding
    @Input() Documents: DocumentModel[] = [];
    @Input() ParentGuid: string;
    @Input() permissions: DocumentManagerPermissionModel;
    @Output() DocumentsChange = new EventEmitter<DocumentModel[]>();

    // Custom component Configuration options
    @Input() Label = "Documents";
    @Input() MaxHeight: string;
    @Input() headerHeight: string;
    @Input() hideLabel = false;
    @Input() hideAddButton = false;
    @Input() hideGenerateButton = true;
    @Input() noMargin = false;
    @Input() disabled = false;
    @Input() compactMode = false;

    // This option hides the entire grid, but still allows the user to add documents using direct references to the component.
    @Input() showUi = true;

    @Input() ShowType = true;
    @Input() AllowAdd = true;
    @Input() AllowEdit = true;
    @Input() AllowDelete = true;
    @Input() ForcedExtensions: string;
    @Input() DocumentTypeTargets: TargetModule | TargetModule[];

    @Input() NoDocumentMessage: string;
    @Input() ExtraFieldColumnConfig: CerrixGridColumnConfig[];

    highlightedGuid: string;
    username: string;
    documentTypes: IdNameCombination[];

    serverAllowedExtensions: string;
    allowedFileExtensions: string;
    maxUploadSizeInMb: number;

    targetedDocument: DocumentModel;

    slideOutTitle: string;
    slideOutContent: TabModel;

    _sortColumnOptions = { columns: [{ field: "Date", direction: "Descending" }] };
    _filterOptions = { type: "Excel" };

    @ViewChild("ejGrid") ejGridObj: any;

    constructor(
        private _userDS: UserService,
        private _documentDS: DocumentDataService,
        private _tabService: TabService,
        private _pages: Pages,
        private _modalService: BsModalService,
        private _standingDataDS: StandingdataDataService,
        private _toastr: ToastrService,
        private _popupService: PopupService
    ) {
        this.getSD();
    }

    async getSD() {
        this.username = await this._userDS.getUserName();
        this.documentTypes = await toPromise(
            this._standingDataDS.getDocumentTypes(this.DocumentTypeTargets)
        );
        const documentConfig = await toPromise(this._documentDS.GetDocumentConfig());

        this.serverAllowedExtensions = documentConfig.allowedFileExtensions;

        this.maxUploadSizeInMb = documentConfig.maxUploadSizeInMb;

        if (this.ForcedExtensions) {
            const forcedList = this.ForcedExtensions.split(",");
            const serverList = this.serverAllowedExtensions.split(",");
            const allowed = forcedList.filter((f) => serverList.indexOf(f) >= 0);

            this.allowedFileExtensions = allowed.join(",");
        } else {
            this.allowedFileExtensions = this.serverAllowedExtensions;
        }
    }

    ngOnChanges() {
        if (!this.permissions) {
            this.permissions = new DocumentManagerPermissionModel();
            this.permissions.canAddDocument = this.AllowAdd;

            this.Documents.forEach((x) => {
                x.CanDelete = this.AllowDelete;
                x.CanEdit = this.AllowEdit;
            });
        }

        this.reloadGrid();
    }

    emitChange() {
        if (this.ejGridObj) {
            this.ejGridObj.refresh();
        }
        this.DocumentsChange.emit(this.Documents);
    }

    openEdit(doc?: DocumentModel) {
        this.targetedDocument = doc ? (JSON.parse(JSON.stringify(doc)) as DocumentModel) : null;
        if (this.targetedDocument) {
            this.targetedDocument.File = doc.File;
        }

        this.openNewDialog(this.targetedDocument).subscribe((documents) => {
            this.applyEdit(documents);
        });
    }

    protected async openGenerate(): Promise<void> {
        const document = await this.openGenerateDialog();
        if (document) {
            this.setFileImg(document);
            this.Documents.unshift(document);
            this.emitChange();

            this._toastr.success("Document generated successfully");
        }
    }

    openEditorInWindow(doc: DocumentModel) {
        const url = "/wopi/" + doc.Guid + "/edit?singleviewinstance=true";
        this._popupService.forceOpenInNewWindow(url);
    }

    openEmbedView(doc: DocumentModel) {
        const extension = doc.Name.split(".").pop();
        this.slideOutContent = this._tabService.getTab(this._pages.Wopi, doc.Guid, doc.Name, {
            documentGuid: doc.Guid,
            documentFileExtension: extension,
            action: "embedview",
        });
        this.slideOutTitle = doc.Name;
    }

    applyEdit(documents: DocumentModel[]) {
        if (!documents) {
            return;
        }

        const allNewFiles = [...documents, ...this.Documents]
            .filter((doc) => doc.IsNew && doc.File)
            .map((doc) => <File>doc.File);

        if (
            !DocumentMethods.validateUploadLimit(this.maxUploadSizeInMb, this._toastr, allNewFiles)
        ) {
            return;
        }

        for (let docIndex = 0; docIndex < documents.length; docIndex++) {
            const doc = documents[docIndex];

            this.setFileImg(doc);
            if (doc.IsNew) {
                const updated = doc.Guid
                    ? this.Documents.filter((x) => x.IsNew && x.Guid === doc.Guid)
                    : [];
                if (updated.length > 0) {
                    this.updateFile(updated[0], doc);
                } else {
                    this.Documents.unshift(doc);
                }
            } else {
                doc.Changed = true;

                const updated = this.Documents.filter((x) => x.ID === doc.ID && !x.Deleted);
                if (updated.length > 0) {
                    this.updateFile(updated[0], doc);
                }
            }
        }

        this.emitChange();
    }

    updateFile(oldFile: DocumentModel, newFile: DocumentModel) {
        const replaceIndex = this.Documents.indexOf(oldFile);
        this.Documents.splice(replaceIndex, 1, newFile);
    }

    toggleDelete(guid: string) {
        const doc = this.Documents.find((d) => d.Guid == guid);
        if (!doc) {
            return;
        }

        if (!doc.CanDelete) {
            return;
        }

        if (doc.IsNew) {
            const newIndex = this.Documents.indexOf(doc);
            this.Documents.splice(newIndex, 1);
        } else {
            doc.Deleted = !doc.Deleted;
        }

        this.highlightedGuid = null;
        this.emitChange();
    }

    setFileImg(doc: DocumentModel) {
        const fileNameSplit = doc && doc.Name ? doc.Name.toLowerCase().split(".") : [];
        const fileExt =
            fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1].trim() : "";

        switch (fileExt) {
            // Word
            case "doc":
            case "docm":
            case "docx":
            case "dot":
            case "dotm":
            case "dotx":
            case "odt": {
                doc.msAppIcon = "ms-word";
                doc.msFileIcon = `ms-${fileExt}`;
                break;
            }

            // Powerpoint
            case "odp":
            case "pot":
            case "potm":
            case "potx":
            case "pps":
            case "ppsm":
            case "ppsx":
            case "ppt":
            case "pptm":
            case "pptx": {
                doc.msAppIcon = "ms-powerpoint";
                doc.msFileIcon = `ms-${fileExt}`;
                break;
            }

            // Excel
            case "csv":
            case "ods":
            case "xls":
            case "xlsm":
            case "xlsx": {
                doc.msAppIcon = "ms-excel";
                doc.msFileIcon = `ms-${fileExt}`;
                break;
            }

            // Others
            case "pdf": {
                doc.FileIcon = "file-pdf";
                break;
            }
            case "jpg":
            case "png":
            case "jpeg": {
                doc.FileIcon = "file-image";
                break;
            }
            case "txt":
            case "msg": {
                doc.FileIcon = "file-alt";
                break;
            }
            case "xml": {
                doc.FileIcon = "file-excel";
                break;
            }
            case "zip": {
                doc.FileIcon = "file-archive";
                break;
            }
            default: {
                doc.FileIcon = "file";
                break;
            }
        }
    }

    fileDropHandler(files: File[]) {
        if (this.AllowAdd) {
            const droppedDocuments = DocumentMethods.convertFileListToDocumentModel(
                files,
                this.username
            );

            this.applyEdit(droppedDocuments);
        }
    }

    openDocumentViewer(document: DocumentModel) {
        const url = "/api/documents/" + document.Guid + "/pdf";
        this._popupService.forceOpenInNewWindow(url);
    }

    private openNewDialog(doc: DocumentModel): Observable<DocumentModel[]> {
        const parameters = <DocumentDetailsParameters>{
            permissions: this.permissions,
            allowedFileExtensions: this.allowedFileExtensions,
            documentTypes: this.documentTypes,
            showType: this.ShowType,
            userName: this.username,
            disabled: this.disabled,

            maxUploadSizeInMb: this.maxUploadSizeInMb,
            filesToUpload: this.Documents.filter((x) => x.IsNew).map((x) => x.File),
        };

        const clone = <DocumentModel>new Object(doc);
        parameters.document = clone;

        const config = <ModalOptions<DocumentManagerDetailsComponent>>{
            backdrop: "static",
            class: "documents-dialog",

            initialState: <DocumentManagerDetailsComponent>{
                parameters,
            },
        };

        return this._modalService.show<DocumentManagerDetailsComponent>(
            DocumentManagerDetailsComponent,
            config
        ).content.afterClosed;
    }

    private async openGenerateDialog(): Promise<DocumentModel> {
        if (
            !this.ParentGuid ||
            !this.DocumentTypeTargets ||
            Array.isArray(this.DocumentTypeTargets)
        ) {
            return;
        }
        const config = <ModalOptions<DocumentManagerGenerateComponent>>{
            initialState: <DocumentManagerGenerateComponent>{
                parameters: {
                    module: this.DocumentTypeTargets,
                    parentGuid: this.ParentGuid,
                },
            },
        };
        const modal = this._modalService.show<DocumentManagerGenerateComponent>(
            DocumentManagerGenerateComponent,
            config
        );
        return (await firstValueFrom(modal.content.afterClosed)) as Promise<DocumentModel>;
    }

    //#region Grid Events

    reloadGrid() {
        this.Documents.forEach((x) => this.setFileImg(x));

        if (this.ejGridObj) {
            this.ejGridObj.refresh();
        }
    }

    //#endregion Grid Events
}
