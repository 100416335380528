import { Component, ViewChild, ElementRef, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators, ValidationErrors, FormArray } from "@angular/forms";
import { DataBreachModel } from "@models/event/DataBreachModel";
import { DataBreachDataService } from "../services/DataBreachDataService";
import { Observable } from "rxjs";
import { BreachHeavyStandingDataCollection } from "@models/event/BreachHeavyStandingDataCollection";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { BreachPermissionModel } from "@models/event/BreachPermissionModel";
import { isGuid } from "@methods/uniqueMethods";
import moment from "moment";
import { EventModel } from "@models/event/EventModel";
import {
    getFormValue,
    getFormControl,
    isDirty,
    setFormControlValue,
    toPromise,
} from "@methods/CommonMethods";
import { EventPermissionModel } from "@models/event/EventPermissionModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";
import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";

@Component({
    selector: "breach-detail",
    templateUrl: "./breach-detail.component.html",
    styleUrls: ["./breach-detail.component.scss"],
})
export class BreachDetailComponent {
    @Input() eventForm: FormGroup;
    @Input() eventPermissions: EventPermissionModel;
    @ViewChild("notificationCounter") notificationCounter: ElementRef;

    id: number;
    parentForm: FormGroup;

    breachDirty = false;
    breachBackupModel: DataBreachModel;

    eventModel: EventModel;
    breachModel: DataBreachModel;
    breachPermissions: BreachPermissionModel;
    sdHeavy: BreachHeavyStandingDataCollection;
    maxDate = new Date();
    isTogglingDataBreach = false;

    // Notification counter
    BreachTimerText = "Calculating...";
    intervalRef: any;
    notifiedDate: moment.Moment;
    targetTime: moment.Moment;
    breachMoment: moment.Moment;

    // CustomFields
    databreachCustomFieldData: { [key: number]: CustomFieldInfoModel } = {};
    databreachCustomFieldsFormGroup: FormGroup;
    databreachCustomFieldStandingDataModel: CustomFieldStandingDataModel[] = [];
    showCustomFields: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private _breachDS: DataBreachDataService,
        private _promptService: CerrixPromptService
    ) {
        this.id = this.id > 0 ? this.id : this.route.snapshot.params.id;
    }

    load(dataBreachGuid: string) {
        if (this.breachModel) {
            return;
        }

        this.setupInit(dataBreachGuid);
    }

    async createCustomFieldsFormGroup() {
        const customFieldsFormArray = new FormArray([]);
        let customFields: CustomFieldDetailModel[] = [];
        // GET THE DATA
        this.databreachCustomFieldStandingDataModel = await toPromise(
            this._breachDS.getCustomFields()
        );

        if (this.breachModel && this.breachModel.CustomFields) {
            customFields = this.breachModel.CustomFields;
        }

        for (const field of this.databreachCustomFieldStandingDataModel) {
            const answerField = customFields.find((x) => x.customFieldId === field.ID);

            const customFieldFormGroup = new FormGroup<any>({
                ID: new FormControl(answerField ? answerField.ID : 0),
                Name: new FormControl(field.Name),
                customFieldId: new FormControl(field.ID),
                required: new FormControl(field.required),
            });

            const valueProperty = getCustomFieldValueProperty(field);
            customFieldFormGroup.addControl(
                valueProperty,
                new FormControl(
                    answerField ? answerField[valueProperty] : null,
                    field.required ? [Validators.required] : []
                )
            );

            // Store extra information from the custom field in a custom object which is easier accessible in the front-end
            this.databreachCustomFieldData[field.ID] = {
                Name: field.Name,
                fieldType: field.fieldType,
                required: field.required,
                answerOptions: field.answerOptions,
            };

            customFieldsFormArray.push(customFieldFormGroup);
        }

        this.databreachCustomFieldsFormGroup = new FormGroup({
            customFields: customFieldsFormArray,
        });
        if (this.databreachCustomFieldStandingDataModel.any()) {
            this.showCustomFields = true;
        }
        if (this.eventPermissions.detailsReadonly) {
            this.databreachCustomFieldsFormGroup.disable();
        } else {
            this.databreachCustomFieldsFormGroup.valueChanges.subscribe(() => {
                this.breachModel.CustomFields =
                    this.databreachCustomFieldsFormGroup.value["customFields"];
            });
        }
    }

    setupInit(dataBreachGuid: string) {
        let permissionsRequest: Observable<BreachPermissionModel>;
        let heavySdRequest: Observable<BreachHeavyStandingDataCollection>;

        const eventExisting = this.eventModel.Guid && isGuid(this.eventModel.Guid);
        if (eventExisting) {
            permissionsRequest = this._breachDS.getPermissions(this.eventModel.Guid);
        } else {
            permissionsRequest = this._breachDS.getPermissionsForNewEvent();
        }

        const breachExisting = dataBreachGuid && isGuid(dataBreachGuid);
        if (breachExisting) {
            heavySdRequest = this._breachDS.getHeavySd(dataBreachGuid);
        } else {
            heavySdRequest = this._breachDS.getHeavySdForNewBreach();
        }

        this.processRequests(dataBreachGuid, permissionsRequest, heavySdRequest, breachExisting);
    }

    processRequests(
        guid: string,
        permissionsRequest: Observable<BreachPermissionModel>,
        heavySdReq: Observable<BreachHeavyStandingDataCollection>,
        isExisting: boolean
    ) {
        permissionsRequest.subscribe(async (permissions) => {
            this.breachPermissions = permissions;

            // Show the save button, so that the user can save the breach
            if (!this.eventPermissions.canSave && !this.breachPermissions.readonly) {
                this.eventPermissions.canSaveDataBreach = true;
            }

            if (isExisting) {
                this._breachDS.getDataBreach(guid).subscribe((breach) => {
                    this.initBreachDetails(breach);
                });
            } else {
                const breachModel = new DataBreachModel();
                this.initBreachDetails(breachModel);
            }

            this.sdHeavy = await heavySdReq.toPromise();
        });
    }

    async initBreachDetails(breachModel: DataBreachModel) {
        this.initFormValidation(breachModel);
        this.calculateDateTime();

        this.breachBackupModel = JSON.parse(JSON.stringify(breachModel));
        this.breachModel = breachModel;
        await this.createCustomFieldsFormGroup();
    }

    initFormValidation(breachModel: DataBreachModel) {
        const permissions = this.breachPermissions;

        this.parentForm = new FormGroup(
            {
                // Breach analysis
                BreachCategoryList: new FormControl(breachModel.BreachCategoryList),
                NatureOfIncidentId: new FormControl(breachModel.NatureOfIncidentId),
                PreventativeMeasures: new FormControl(breachModel.PreventativeMeasures),

                BreachDescription: new FormControl(breachModel.BreachDescription, [
                    Validators.required,
                ]),
                ConsequencesToCiaOfDataList: new FormControl(
                    breachModel.ConsequencesToCiaOfDataList
                ),
                DataBreachConsequences: new FormControl(breachModel.DataBreachConsequences),

                BreachTypes: new FormControl(breachModel.BreachTypes),
                PersonsInvolvedList: new FormControl(breachModel.PersonsInvolvedList),

                BreachCause: new FormControl(breachModel.BreachCause, [Validators.required]),
                PersonsInvolvedDescription: new FormControl(breachModel.PersonsInvolvedDescription),

                PersonalDataTypes: new FormControl(
                    breachModel.PersonalDataTypes,
                    Validators.required
                ),
                NumberOfPersonsInvolvedMinimum: new FormControl(
                    breachModel.NumberOfPersonsInvolvedMinimum
                ),

                SensitivePersonalDataTypes: new FormControl(breachModel.SensitivePersonalDataTypes),
                NumberOfPersonsInvolvedMaximum: new FormControl(
                    breachModel.NumberOfPersonsInvolvedMaximum
                ),

                NumberOfDataRegistersInvolved: new FormControl(
                    breachModel.NumberOfDataRegistersInvolved
                ),
                PersonsInvolvedFromOtherEuCountries: new FormControl(
                    breachModel.PersonsInvolvedFromOtherEuCountries
                ),

                DataProcessorName: new FormControl(breachModel.DataProcessorName),

                OutsourcedDataProcessing: new FormControl(breachModel.OutsourcedDataProcessing),
                SubdataProcessor: new FormControl(breachModel.SubdataProcessor),

                // Notifications
                AuthoritiesNotified: new FormControl({
                    value: breachModel.AuthoritiesNotified,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                AuthoritiesNotifiedDate: new FormControl(
                    {
                        value: breachModel.AuthoritiesNotifiedDate,
                        disabled: !permissions.canChangeSpecificDetails,
                    },
                    [
                        FormValidationHelper.validateDateBeforeNow<DataBreachModel>(
                            "Authorities notified date and time cannot be after the current date and time."
                        ),
                    ]
                ),

                InternationalAuthoritiesNotified: new FormControl({
                    value: breachModel.InternationalAuthoritiesNotified,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                InternationalAuthoritiesName: new FormControl({
                    value: breachModel.InternationalAuthoritiesName,
                    disabled: !permissions.canChangeSpecificDetails,
                }),

                EUCountry: new FormControl({
                    value: breachModel.EUCountry,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                CrossborderProcessing: new FormControl({
                    value: breachModel.CrossborderProcessing,
                    disabled: !permissions.canChangeSpecificDetails,
                }),

                PersonsInvolvedNotified: new FormControl({
                    value: breachModel.PersonsInvolvedNotified,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                PersonsInvolvedNotificationDate: new FormControl(
                    {
                        value: breachModel.PersonsInvolvedNotificationDate,
                        disabled: !permissions.canChangeSpecificDetails,
                    },
                    [
                        FormValidationHelper.validateDateBeforeNow<DataBreachModel>(
                            "Notification date to involved persons cannot be after the current date."
                        ),
                    ]
                ),
                NotificationPersonsInvolved: new FormControl({
                    value: breachModel.NotificationPersonsInvolved,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                ExplanationNoNotificationPersonsInvolved: new FormControl({
                    value: breachModel.ExplanationNoNotificationPersonsInvolved,
                    disabled: !permissions.canChangeSpecificDetails,
                }),

                ReasonNoNotificationId: new FormControl({
                    value: breachModel.ReasonNoNotificationId,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                MeansOfCommunication: new FormControl({
                    value: breachModel.MeansOfCommunication,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                PersonsNotified: new FormControl({
                    value: breachModel.PersonsNotified,
                    disabled: !permissions.canChangeSpecificDetails,
                }),

                SecondaryNotificationNotified: new FormControl({
                    value: breachModel.SecondaryNotificationNotified,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                SecondaryNotificationDate: new FormControl(
                    {
                        value: breachModel.SecondaryNotificationDate,
                        disabled: !permissions.canChangeSpecificDetails,
                    },
                    [
                        FormValidationHelper.validateDateBeforeNow<DataBreachModel>(
                            "Secondary notification date cannot be after the current date."
                        ),
                    ]
                ),
                SecondaryNotificationDescription: new FormControl({
                    value: breachModel.SecondaryNotificationDescription,
                    disabled: !permissions.canChangeSpecificDetails,
                }),

                // Contact details
                RegistrationNr: new FormControl(breachModel.RegistrationNr),
                ZipCode: new FormControl(breachModel.ZipCode),

                OrganizationName: new FormControl(breachModel.OrganizationName),
                City: new FormControl(breachModel.City),

                Address: new FormControl(breachModel.Address),
                ActiveInWhichSectorId: new FormControl(breachModel.ActiveInWhichSectorId),

                // Details
                InternalBreachIdentifier: new FormControl(breachModel.InternalBreachIdentifier),
                ExternalBreachIdentifier: new FormControl(breachModel.ExternalBreachIdentifier),
                OrganizationId: new FormControl(breachModel.OrganizationId, Validators.required),

                ResponsibleList: new FormControl(
                    {
                        value: breachModel.ResponsibleList,
                        disabled: !permissions.canChangeSpecificDetails,
                    },
                    Validators.required
                ),
                BreachAssessorList: new FormControl({
                    value: breachModel.BreachAssessorList,
                    disabled: !permissions.canChangeSpecificDetails,
                }),
                DataProtectionOfficerId: new FormControl({
                    value: breachModel.DataProtectionOfficerId,
                    disabled: !permissions.canChangeSpecificDetails,
                }),

                BreachOccurenceDateTime: new FormControl(breachModel.BreachOccurenceDateTime, [
                    Validators.required,
                    FormValidationHelper.validateDateBeforeNow<DataBreachModel>(
                        "Breach occurrence date cannot be later than today."
                    ),
                ]),
                BreachEndDateTime: new FormControl(breachModel.BreachEndDateTime, [
                    FormValidationHelper.validateDateBeforeNow<DataBreachModel>(
                        "Breach end date cannot be after the current date"
                    ),
                ]),
                BreachDetectionDateTime: new FormControl(breachModel.BreachDetectionDateTime, [
                    Validators.required,
                    FormValidationHelper.validateDateBeforeNow<DataBreachModel>(
                        "Date breach detected cannot be after the current date and time."
                    ),
                ]),

                // Bottom
                FollowUpDescription: new FormControl(breachModel.FollowUpDescription),
                Comments: new FormControl(breachModel.Comments),
            },
            {
                validators: [
                    // Before other dates
                    FormValidationHelper.validateStartEndDate<DataBreachModel>(
                        "BreachOccurenceDateTime",
                        "BreachDetectionDateTime",
                        "Date breach detected may not be before date breach occurred.",
                        true
                    ),
                    FormValidationHelper.validateStartEndDate<DataBreachModel>(
                        "BreachOccurenceDateTime",
                        "BreachEndDateTime",
                        "Breach end date cannot be before the occurrence.",
                        false
                    ),
                    FormValidationHelper.validateStartEndDate<DataBreachModel>(
                        "PersonsInvolvedNotificationDate",
                        "SecondaryNotificationDate",
                        "Notification date to involved persons cannot be after the secondary notification date.",
                        false
                    ),
                    FormValidationHelper.validateStartEndDate<DataBreachModel>(
                        "BreachDetectionDateTime",
                        "PersonsInvolvedNotificationDate",
                        "Notification date to involved persons cannot be before the detection date.",
                        false
                    ),
                    FormValidationHelper.validateStartEndDate<DataBreachModel>(
                        "BreachDetectionDateTime",
                        "AuthoritiesNotifiedDate",
                        "Authorities notified date cannot be before the detection date.",
                        false
                    ),
                    FormValidationHelper.validateStartEndDate<DataBreachModel>(
                        "BreachDetectionDateTime",
                        "SecondaryNotificationDate",
                        "Secondary notification date cannot be before the detection date.",
                        false
                    ),

                    // Min max
                    FormValidationHelper.validateMinMax<DataBreachModel>(
                        "NumberOfPersonsInvolvedMinimum",
                        "NumberOfPersonsInvolvedMaximum",
                        "Minimum # of persons involved cannot be higher than maximum # of persons involved."
                    ),
                    FormValidationHelper.validateMinMax<DataBreachModel>(
                        "PersonsNotified",
                        "NumberOfPersonsInvolvedMaximum",
                        "# of persons notified cannot be higher than maximum # of persons involved."
                    ),
                ],
            }
        );

        if (permissions.readonly) {
            this.parentForm.disable();
        }

        this.parentForm.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(this.parentForm, this.breachModel);
            this.checkDirty();
        });
    }

    checkDirty() {
        this.breachDirty = isDirty(this.breachBackupModel, this.breachModel);
    }

    authoritiesNotifiedChange(newValue: boolean) {
        if (!newValue) {
            setFormControlValue<DataBreachModel>(this.parentForm, "AuthoritiesNotifiedDate", null);
            this.calculateDateTime();
        }
    }

    calculateDateTime() {
        const breachMomentString = getFormValue<DataBreachModel>(
            this.parentForm,
            "BreachDetectionDateTime"
        );
        this.breachMoment = breachMomentString ? moment(breachMomentString) : null;

        const notifiedDateString = getFormValue<DataBreachModel>(
            this.parentForm,
            "AuthoritiesNotifiedDate"
        );
        this.notifiedDate = notifiedDateString ? moment(notifiedDateString) : null;

        if (this.intervalRef) {
            clearInterval(this.intervalRef);
        }

        this.setTimerText();
    }

    setTimerText() {
        if (!this.breachMoment) {
            this.BreachTimerText = "Please enter detected date and time first.";
            return;
        } else {
            this.targetTime = this.breachMoment.clone().add(3, "days");
        }

        if (this.breachMoment.isAfter(new Date(), "minutes")) {
            this.BreachTimerText = "Breach detection cannot lie in the future";
            return;
        }

        let minutesDiff = 0;
        if (this.notifiedDate) {
            minutesDiff = this.notifiedDate.diff(this.breachMoment, "minutes");
        } else {
            minutesDiff = this.targetTime.diff(moment(new Date()), "minutes");
        }

        const isNegative = minutesDiff < 0;
        minutesDiff = Math.abs(minutesDiff);

        const days = Math.floor(minutesDiff / 1440);
        const hours = Math.floor(minutesDiff / 60) % 24;
        const minutes = minutesDiff % 60;

        let timeText = "";
        if (days && days > 0) {
            timeText = ` ${days} day${days > 1 ? "s" : ""}`;
        }

        if (hours && hours > 0) {
            timeText += ` ${hours} hour${hours > 1 ? "s" : ""}`;
        }

        if (minutes && minutes >= 0) {
            timeText += ` ${minutes} minute${minutes > 1 ? "s" : ""}`;
        } else if (!timeText) {
            timeText = "0 minutes";
        }
        timeText = timeText.trim();

        if (this.notifiedDate) {
            this.BreachTimerText = isNegative
                ? "Authorities cannot be notified before detection."
                : `Notified in ${timeText}.`;
        } else {
            if (isNegative) {
                this.BreachTimerText = `${timeText} overdue.`;
            } else {
                this.BreachTimerText = `${timeText} remaining.`;
            }
        }

        const oneTime = this.notifiedDate;
        if (!oneTime) {
            clearInterval(this.intervalRef);

            const outerRef = this;
            this.intervalRef = setInterval(function () {
                outerRef.setTimerText();
            }, 60000);
        }
    }

    validate(): ValidationErrors {
        if (this.parentForm) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            if (this.parentForm.invalid) {
                return FormValidationHelper.getFormControlErrors(this.parentForm);
            }
        }

        return {};
    }

    mapback(): DataBreachModel {
        if (this.parentForm) {
            FormValidationHelper.mapToModel(this.parentForm, this.breachModel);
        }

        return this.breachModel;
    }

    toggleDataBreach() {
        this.isTogglingDataBreach = true;

        const isDataBreachControl = getFormControl<EventModel>(this.eventForm, "IsDataBreach");
        const isDataBreach = isDataBreachControl.value;

        const finalizeToggle = () => {
            if (this.breachPermissions.readonly || !isDataBreachControl.value) {
                this.parentForm.disable();
            } else {
                this.parentForm.enable();
            }

            this.isTogglingDataBreach = false;
        };

        if (isDataBreach === true && this.breachModel && this.breachModel.ID > 0) {
            this._promptService
                .confirm(
                    "Disable databreach",
                    "Are you sure you want to disable Data Breach?\nSaved Data Breach data will remain untouched, but data will not appear in the workspace anymore."
                )
                .getResult()
                .subscribe((confirmed) => {
                    if (confirmed) {
                        isDataBreachControl.setValue(!isDataBreach);
                    }
                    finalizeToggle();
                });
        } else {
            isDataBreachControl.setValue(!isDataBreach);
            finalizeToggle();
        }
    }
}
