<div>
    <div class="global-message" *ngIf="!enabled">
        <br *ngIf="!enabled" />
        <pre class="warning-banner" *ngIf="!enabled">WOPI debug logging is disabled</pre>
        <hr />
    </div>
</div>

<div menuSystem [cerrixTab]="tab">
    <div [attr.name]="'Wopi Debug'" menuicon="fas fa-code" menusystemsubmenu>
        <div name="Proof Key Info" menuicon="fas fa-file-import" menusystemitem>
            <proof-key-info></proof-key-info>
        </div>
        <div name="Lock Info" menuicon="fas fa-file-import" menusystemitem>
            <lock-info></lock-info>
        </div>
        <div name="Discovery Info" menuicon="fas fa-file-import" menusystemitem>
            <discovery-info></discovery-info>
        </div>
        <div name="Transaction Info" menuicon="fas fa-file-import" menusystemitem>
            <transaction-info></transaction-info>
        </div>
    </div>
</div>
