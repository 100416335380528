<div class="fancy-loader" *ngIf="!pageReady"></div>

<div class="detail-page-card" *ngIf="pageReady && gridConfig">
    <div class="detail-page-card-title">
        Fieldwork Execution
        <i
            [title]="saveCalls > 0 ? 'Saving changes' : 'Changes are saved'"
            *ngIf="saveCalls != null"
            class="ms-2 fas fa-{{ saveCalls > 0 ? 'circle-notch fa-spin' : 'check green' }}"
        ></i>
    </div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="loadGrid()">
            <i class="fas fa-sync"></i>
            <span class="ms-2">Reload</span>
        </button>
    </div>
    <div class="detail-page-card-body overflow-auto">
        <cerrix-grid-editor [config]="gridConfig"></cerrix-grid-editor>
    </div>
</div>
