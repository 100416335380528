import { Injectable, LOCALE_ID, inject } from "@angular/core";
import { BaseDatepickerService } from "@cerrix/components";
import { DateAdapter } from "@angular/material/core";
import { LocalizationService } from "../localization/localization.service";

// add moment locale
import "moment/locale/nl";

@Injectable({
    providedIn: "root",
})
export class DatepickerSettingsService extends BaseDatepickerService {
    private locale = inject(LOCALE_ID);
    private adapter = inject<DateAdapter<Date>>(DateAdapter);

    private localizationService = inject(LocalizationService);

    setLocale(): void {
        this.adapter.setLocale(this.locale);
    }

    getDateFormat(): string {
        return this.localizationService.localizationInfo.dateFormat;
    }

    getLocale(): string {
        return this.locale;
    }
}
