<div class="modal-header">
    <h5 class="modal-title text-truncate">{{ popupAction }}</h5>
</div>
<div class="modal-body">
    <ul class="nav nav-tabs">
        <li class="nav-item {{ selectedTab == 'details' ? 'active' : '' }}">
            <button
                class="nav-link {{ selectedTab == 'details' ? 'active' : '' }}"
                data-toggle="tab"
                (click)="toggle('details')"
            >
                Folder details
            </button>
        </li>
        <li class="nav-item {{ selectedTab == 'history' ? 'active' : '' }}" *ngIf="false">
            <button
                class="nav-link {{ selectedTab == 'history' ? 'active' : '' }}"
                data-toggle="tab"
                (click)="toggle('history')"
            >
                History
            </button>
        </li>
    </ul>

    <div class="tab-content">
        <div
            class="tab-pane {{ selectedTab == 'details' ? 'active' : '' }}"
            id="folderDetail"
            #folderDetail
        >
            <form novalidate [formGroup]="folderForm">
                <div class="row">
                    <div class="form-group col">
                        <label>Folder icon</label>
                        <div class="input-group">
                            <cerrix-icon-picker
                                formControlName="FileIcon"
                                [value]="Folder.FileIcon"
                            ></cerrix-icon-picker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label>Folder name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="Name" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div
            class="tab-pane {{ selectedTab == 'history' ? 'active' : '' }}"
            id="history"
            #history
            *ngIf="false"
        >
            <document-history [guid]=""></document-history>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        data-uiid="cerrixdocumentmanagerfolder-addfolderbutton"
        type="button"
        class="btn btn-primary"
        (click)="applyChange()"
    >
        {{ popupAction }}
    </button>
    <button class="btn btn-secondary" (click)="closeClick()">Cancel</button>
</div>
