<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar flex-action-bar" *ngIf="model">
    <div>
        <button
            *ngIf="permissions?.canEdit || permissions?.canEditDocumentsAndHyperlinks"
            data-uiid="button-savetask"
            class="btn btn-cerrix"
            (click)="save()"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save task</span>
        </button>
    </div>
    <!-- workflow -->
    <div class="workflow-area" *ngIf="this.model.id > 0">
        <span class="workflow-description">
            Status |
            <b data-uiid="task-workflow-status">{{ model.workflowStateDescription }}</b>
        </span>
        <button
            type="button"
            (click)="toDoFinished()"
            class="btn btn-success"
            id="positive"
            data-uiid="task-mark-done-button"
            *ngIf="
                model.workflowState == TaskWorkflowStatus.ToDo &&
                this.id &&
                permissions.canExecuteTask
            "
        >
            {{ isReviewEnabled ? "Ready for review" : "Mark as done" }}
        </button>
        <button
            type="button"
            (click)="reviewRejected()"
            class="btn btn-dark"
            data-uiid="task-reviewer-reject-button"
            *ngIf="
                model.workflowState == TaskWorkflowStatus.ReadyForReview &&
                permissions.canReviewTask
            "
        >
            Reject
        </button>
        <button
            type="button"
            (click)="reviewAccepted()"
            class="btn btn-success"
            data-uiid="task-reviewer-accept-button"
            *ngIf="
                model.workflowState == TaskWorkflowStatus.ReadyForReview &&
                permissions.canReviewTask
            "
        >
            Accept
        </button>
        <button
            type="button"
            (click)="reopen()"
            class="btn btn-dark"
            id="negative"
            data-uiid="task-reopen-button"
            *ngIf="model.workflowState == TaskWorkflowStatus.Done && permissions.canReopenTask"
        >
            Reopen
        </button>
    </div>
</div>

<div
    class="task-screen-system"
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    *ngIf="formGroup"
>
    <div
        class="task-screens"
        [attr.name]="'Task'"
        menuicon="fa-fw fa-list-ol far"
        menusystemsubmenu
    >
        <div menuItemId="menuDetails" name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <task-detail-main
                [(model)]="model"
                [cerrixTab]="tab"
                [permissions]="permissions"
                [formGroup]="formGroup"
                (linkedTypesChanged)="linkedTypesChanged($event)"
                [showLinkedControls]="showLinkedControls"
                [isReviewEnabled]="isReviewEnabled"
                [standingData]="standingData"
                #detailMain
            >
            </task-detail-main>
        </div>

        <div
            class="task-screens"
            [attr.menuItemId]="'menuLinks'"
            [attr.name]="'Links'"
            menuicon="fas fa-link"
            menusystemsubmenu
            *ngIf="isInitialized && this.id && permissions"
        >
            <div
                menuItemId="menuItemControls"
                name="Controls"
                menuicon="fas fa-shield-alt"
                menusystemitem
                *ngIf="permissions.canViewLinkedControls"
            >
                <!-- control linking disabled for now -->
                <task-controls
                    [(model)]="model"
                    [disabled]="
                        true || !permissions.canEdit || model.status == TaskWorkflowStatus.Done
                    "
                    #linkedControls
                ></task-controls>
            </div>
            <div
                menuItemId="menuItemDocuments"
                name="Documents"
                menuicon="far fa-file"
                menusystemitem
                *ngIf="permissions.canViewDocumentsAndHyperlinks"
            >
                <div *ngIf="!documents">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="documents">
                    <document-manager-old
                        [(Documents)]="model.Documents"
                        (DocumentsChange)="checkDocumentsDirty()"
                        [permissions]="permissions.documentPermissions"
                        [DocumentTypeTargets]="targetModule"
                    >
                    </document-manager-old>
                </div>
            </div>
            <div
                menuItemId="menuItemHyperlinks"
                name="Hyperlinks"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="permissions.canViewDocumentsAndHyperlinks"
            >
                <div *ngIf="!hyperlinksLoaded">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="hyperlinksLoaded">
                    <hyperlink-manager
                        [(Hyperlinks)]="model.hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                        [Readonly]="!permissions.canEditDocumentsAndHyperlinks"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>
        <div
            name="History"
            menuicon="fas fa-history"
            menusystemitem
            menuItemId="history"
            *ngIf="model && model.id > 0"
        >
            <app-history-overview [historyDataService]="_ds" [Id]="id" #historyOverviewComponent>
            </app-history-overview>
        </div>
        <div *ngIf="id" name="Options" menuicon="fas fa-check-double" menusystemitem>
            <detail-options
                moduleName="task"
                [objectName]="model ? model.name : 'task'"
                [allowDelete]="permissions.canDelete"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
