import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { NavigatorAssessmentDetailModel } from "@app/navigators/models/navigator-assessment-detail-model";
import { NavigatorDocumentModel } from "@app/navigators/models/navigator-document-model";
import { NavigatorHyperlinkModel } from "@app/navigators/models/navigator-hyperlinks-model";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable } from "rxjs";
import { BusinessNavigatorDetailModel } from "../models/business-navigator-detail-model";
import { BusinessNavigatorOverviewModel } from "../models/business-navigator-overview-model";
import { BusinessNavigatorPermissionModel } from "../models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "../models/business-navigator-request-model";
import { BusinessNavigatorReviewModel } from "../models/business-navigator-review-model";

@Injectable()
export class BusinessNavigatorDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "businessnavigator");
    }

    //#region Main data calls

    getPermissions(organizationID: number, businessID: number) {
        const model = <BusinessNavigatorRequestModel>{
            organizationID: organizationID,
            businessID: businessID,
        };

        const url = this.formatUrl(model, "/permissions");
        return this.get<BusinessNavigatorPermissionModel>(url).toPromise();
    }

    getOverview(): Promise<BusinessNavigatorOverviewModel[]> {
        return this.get<BusinessNavigatorOverviewModel[]>().toPromise();
    }

    getRequestValidation(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/request-validation");
        return this.get<BusinessNavigatorRequestModel>(url).toPromise();
    }

    getAssessmentDetails(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/assessment");
        return this.get<NavigatorAssessmentDetailModel>(url).toPromise();
    }

    getDetails(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/details");
        return this.get<BusinessNavigatorDetailModel>(url).toPromise();
    }

    storeAssessmentDetails(
        model: BusinessNavigatorRequestModel,
        toPost: NavigatorAssessmentDetailModel
    ) {
        const url = this.formatUrl(model, "/assessment");
        return this.post<NavigatorAssessmentDetailModel>(toPost, url).toPromise();
    }

    public getWidgetInfo(filters: any) {
        const url = `/widgetinfo`;
        return this.post<any[]>(filters, url);
    }

    public deleteAssessment(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/delete");
        return this.delete(url);
    }

    //#endregion Main data Calls

    //#region Documents calls

    getDocuments(model: BusinessNavigatorRequestModel) {
        const url = `/documents/${model.businessID}`;
        return this.get<NavigatorDocumentModel>(url);
    }

    storeDocuments(toPost: NavigatorDocumentModel) {
        const url = `/documents`;
        return this.postWithDocuments<NavigatorDocumentModel>(toPost, toPost.documents, url);
    }

    getAssessmentDocuments(model: BusinessNavigatorRequestModel) {
        const url = `/assessmentdocuments/${model.businessAssessmentID}`;
        return this.get<NavigatorDocumentModel>(url);
    }

    storeAssessmentDocuments(toPost: NavigatorDocumentModel) {
        const url = `/assessmentdocuments`;
        return this.postWithDocuments<NavigatorDocumentModel>(toPost, toPost.documents, url);
    }

    ////#endregion Document calls

    //#region Hyperlink calls
    getHyperlinks(model: BusinessNavigatorRequestModel) {
        const url = `/hyperlinks/${model.businessID}`;
        return this.get<HyperlinkModel[]>(url);
    }

    storeHyperlinks(model: NavigatorHyperlinkModel) {
        const url = `/hyperlinks`;
        return this.post<NavigatorHyperlinkModel>(model, url);
    }
    ////#endregion Hyperlink calls

    //#region Standing data calls

    public getOrganizations() {
        const url = this.actionUrl + `/standingdata/organizations`;
        return this.http.get<CerrixTreeItem[]>(url);
    }

    public getBusinessDimension(bdId: number) {
        const url = `/businessdimension/${bdId}`;
        return this.get<any[]>(url);
    }

    public getBusinessDimensionsByOrganization(selectedOrganization: number) {
        let url = "/standingdata/business";
        if (selectedOrganization) {
            url += `?organization=${selectedOrganization}`;
        }

        return this.get<CerrixTreeItem[]>(url);
    }

    //#end Standing data calls

    //#region Review Data calls

    isReviewAllowed(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/review/allowed");
        return this.get<boolean>(url).toPromise();
    }

    storeReview(model: BusinessNavigatorRequestModel, toPost: BusinessNavigatorReviewModel) {
        const url = this.formatUrl(model, "/review");
        return this.post(toPost, url).toPromise();
    }

    getReviews(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/review");
        return this.get<BusinessNavigatorRequestModel>(url).toPromise();
    }

    deleteReview(model: BusinessNavigatorRequestModel, reviewID: number) {
        const url = this.formatUrl(model, "/review/" + reviewID);
        return this.delete(url).toPromise();
    }

    //#endregion Review Data calls

    public getScores() {
        const url = `/standingdata/scores`;
        return this.get<any[]>(url);
    }

    private formatUrl(model: BusinessNavigatorRequestModel, path: string) {
        if (!model.organizationID && !model.businessID) {
            throw new Error("Organization or Business required!");
        }

        const orgID = model.organizationID ? model.organizationID : "null";
        const bdID = model.businessID ? model.businessID : "null";

        const params = `?orgID=${orgID}&bdID=${bdID}`;
        const url = path + params;

        return url;
    }

    //#region Module data calls

    getRisks(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/risks");
        return this.get<any>(url);
    }

    public getLinkedControlsByRiskId(riskGuid: string) {
        const url = `/${riskGuid}/linkedcontrols`;
        return this.get<any>(url);
    }

    getControls(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/controls");
        return this.get<any>(url);
    }

    getEvents(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/events");
        return this.get<any>(url);
    }

    getMois(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/mois");
        return this.get<any>(url);
    }

    getKris(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/kris");
        return this.get<any>(url);
    }

    getDataProcessing(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/dataprocessing");
        return this.get<any>(url);
    }

    getThirdParties(model: BusinessNavigatorRequestModel) {
        const url = this.formatUrl(model, "/moduledata/thirdparties");
        return this.get<any>(url);
    }

    //#endregion Module data calls

    //#region History

    public getHistory(businessAssessmentID: number): Observable<HistoryModel[]> {
        return this.get<HistoryModel[]>(`/${businessAssessmentID}/history`);
    }

    public getHistoryChanges(
        businessAssessmentID: string | number,
        historyDate: string | number
    ): Observable<HistoryChange[]> {
        return this.get<HistoryChange[]>(`/${businessAssessmentID}/history/${historyDate}`);
    }

    //#region History
}
