export class FormPermissionModel {
    HasAccess = false;
    IsAdministrator = false;

    IsCreator = false;
    IsEditor = false;
    IsReleaser = false;

    IsPreviewViewer = false;

    IsImporter = false;
    IsExporter = false;

    IsGroupAdministrator = false;
    IsCategoryAdministrator = false;
    IsMailMessageAdministrator = false;
}
