import { Component, Input, OnInit } from "@angular/core";
import { BusinessNavigatorDetailModel } from "@app/navigators/business-navigator/models/business-navigator-detail-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";

@Component({
    selector: "business-details",
    templateUrl: "./business-details.component.html",
})
export class BusinessDetailsComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;

    detailModel: BusinessNavigatorDetailModel;

    constructor(private dataService: BusinessNavigatorDataService) {}

    ngOnInit() {
        this.dataService.getDetails(this.requestModel).then((details) => {
            this.detailModel = details;
        });
    }
}
