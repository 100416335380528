export class LocalizationInfo {
    dateFormat: string;
    timeFormat: string;
    shortTimeFormat: string;

    dateTimeFormat: string;
    dateShortTimeFormat: string;

    locale: string;

    settingIds: number[];
}
