import { Component, Input } from "@angular/core";
import { CalendarItem } from "@app/calendar/models/CalendarItem";
import { CalendarDataService } from "@app/calendar/services/calendar-data.service";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "calendarmenu",
    templateUrl: "./calendarmenu.component.html",
    styleUrls: ["./calendarmenu.component.scss"],
})
export class CalendarmenuComponent {
    @Input() showActions = true;
    @Input() maxHeight = "auto";

    get calendar() {
        return this._calendarDS.calendarModel;
    }

    constructor(
        private _calendarDS: CalendarDataService,
        private _tabservice: TabService,
        public pages: Pages
    ) {}

    reload() {
        this._calendarDS.loadCalendar();
    }

    openItem(item: CalendarItem) {
        let lookup = this.pages.getExactName(item.tabName);
        if (!lookup && item.tabName) {
            lookup = "oldpage";
        }

        if (lookup) {
            this.openTab(lookup, item.tabID, null, item.tabConfig);
        }
    }

    openTab(lookupname: string, id?: string, tabName?: string, config?: any) {
        this._tabservice.generateTab(lookupname, id, tabName, config);
    }
}
