import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from "@angular/material/core";
import { MatCalendarCellClassFunction } from "@angular/material/datepicker";
import { LocalizationService } from "@app/shared";
import moment from "moment";

const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: { month: "short", year: "numeric", day: "numeric" },
    },
    display: {
        dateInput: "input",
        monthYearLabel: "monthYear",
        dateA11yLabel: { year: "numeric", month: "short", day: "numeric" },
        monthYearA11yLabel: "monthYear",
    },
};

let dateFormat = "YYYY-MM-DD";
let monthYear = "MMM YYYY";
class CalendarDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === "input") {
            return moment(date).format(dateFormat);
        }
        if (displayFormat === "monthYear") {
            return moment(date).format(monthYear);
        } else {
            date.toDateString();
        }
    }

    parse(value: any): Date | null {
        const date = moment(value, dateFormat);
        return date.isValid() ? date.toDate() : null;
    }
}
@Component({
    selector: "eff-date-picker",
    templateUrl: "./eff-date-picker.component.html",
    styleUrls: ["./eff-date-picker.component.scss"],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        { provide: DateAdapter, useClass: CalendarDateAdapter },
    ],
})
export class EffDatePickerComponent {
    @Input() model;
    @Input() type: string;
    @Input() label: string;
    @Input() formController: FormControl = new FormControl();
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    constructor(private locService: LocalizationService) {
        if (locService.localizationInfo.dateFormat) {
            dateFormat = locService.localizationInfo.dateFormat;
        }
    }

    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        return "eff-calendar-cell";
    };

    onModelChanged() {
        this.modelChange.emit(this.model);
    }
}
