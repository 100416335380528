import { Component } from "@angular/core";
import { KeyValue } from "@models/generic/KeyValuePair";
import { DevManagementDataService } from "@app/admin/services/devmanagement.service";

@Component({
    selector: "dev-export",
    templateUrl: "./dev-export.component.html",
    styleUrls: ["./dev-export.component.scss"],
})
export class DevExportComponent {
    exportTypes: KeyValue<number, string>[];
    selectedExportType: number;

    constructor(private dataService: DevManagementDataService) {
        this.dataService.getExportTypes().subscribe((x) => {
            this.exportTypes = x;
            this.selectedExportType = x[0].Key;
        });
    }

    public export() {
        this.dataService.export(this.selectedExportType);
    }
}
