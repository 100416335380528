import { Component, Input, OnInit } from "@angular/core";
import { Form } from "@app/forms/models";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { Pages } from "@constants/pages/Pages";
import { ImageType } from "@enums/ImageType";
import "@methods/Array";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FormPermissionModel } from "@models/permissions/FormPermissionModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { StandingDataType } from "@enums/StandingDataType";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { finalize } from "rxjs/operators";

@Component({
    selector: "form-overview",
    templateUrl: "./form-overview.component.html",
    styleUrls: ["./form-overview.component.scss"],
})
export class FormOverviewComponent implements OnInit {
    @Input() categories: IdNameCombination[];
    @Input() cerrixTab: TabModel;
    @Input() permissions: FormPermissionModel;

    formCategories: { Category: string; Forms: Form[] }[];
    scopedCategories: { Category: string; Forms: Form[] }[];

    imageType = ImageType;
    filter = "";

    constructor(
        private _formDS: FormsDataService,
        private toastr: ToastrService,
        public pages: Pages,
        public tabService: TabService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        const forms = await this._formDS.getFormOverview().toPromise();
        this.formCategories = [];
        this.categories.forEach((c) => {
            const gForms = forms.filter((d) => d.Category === c.ID);
            if (gForms.length > 0) {
                this.formCategories.push({
                    Category: c.Name,
                    Forms: gForms,
                });
            }
        });
        this.filterForms();
    }

    deleteForm(form: Form) {
        this._promptService
            .confirm(
                "Delete form",
                `Are you sure you want to delete the form '${form.Title}' and its results?`
            )
            .onConfirm()
            .subscribe(() => {
                const successStatuses: number[] = [200, 204];
                const prompt = this._promptService.loader("Deleting form, please wait...");

                this._formDS
                    .deleteForm(form.ID)
                    .pipe(finalize(() => prompt.close()))
                    .subscribe((x) => {
                        if (successStatuses.includes(x.status)) {
                            this.cerrixTab.refresh();
                            this.toastr.info("Form deleted!", "Delete");
                        } else {
                            this.toastr.error("Form could not be deleted!", "Delete");
                        }
                    });
            });
    }

    openForm(form: Form) {
        if (form.CanViewResults) {
            this.tabService.generateTab(this.pages.FormOverview, form.ID);
        }
    }

    editForm(form: Form) {
        if (form.CanEdit) {
            this.tabService.generateTab(this.pages.FormDetail, form.ID, form.Title);
        }
    }

    inviteForm(form: Form) {
        const title = "Invite - " + form.Title;
        this.tabService.generateTab(this.pages.FormInvite, form.ID, title);
    }

    filterForms() {
        this.scopedCategories = [];
        const keywords = this.filter
            ? this.filter
                  .split(" ")
                  .filter((x) => x && x.trim().length > 0)
                  .map((kw) => kw.toLowerCase())
            : [];

        if (keywords.length == 0) {
            this.scopedCategories = this.formCategories;
            return;
        }

        this.formCategories.forEach((group) => {
            const matchingForms = group.Forms.filter((f) =>
                keywords.some((kw) => f.Title.toLowerCase().indexOf(kw) >= 0)
            );
            if (matchingForms.any()) {
                this.scopedCategories.push({
                    Category: group.Category,
                    Forms: matchingForms,
                });
            }
        });
    }

    toggleIsOpenForEntries(form: Form) {
        const continueMessage = form.IsOpenForEntries
            ? "Are you sure you want to close the FORM? This will deny users to view result overviews, add and edit results."
            : "Are you sure you want to open the FORM? This will allow users to view result overviews and add/edit results when assigned.";

        this._promptService
            .confirmCustom({
                maxWidth: "350px",
                maxHeight: "275px",
                data: {
                    title: "Toggle form open status",
                    message: continueMessage,
                },
            })
            .onConfirm()
            .subscribe(() => {
                this._formDS
                    .toggleFormOpenForEntries(form, !form.IsOpenForEntries)
                    .subscribe((x) => {
                        form.IsOpenForEntries = !form.IsOpenForEntries;

                        const message = form.IsOpenForEntries
                            ? `Form '${form.Title}' is now open for entries. This behaviour can change based on start and end date.`
                            : `Form '${form.Title}' has been closed, entries will not be accepted from now on.`;
                        this.toastr.success(message, "Toggle Successful");
                    });
            });
    }

    openCategoriesSD() {
        this.tabService.generateTab(
            this.pages.StandingData,
            StandingDataType.FormCategory as number
        );
    }

    openGroupSD() {
        this.tabService.generateTab(this.pages.StandingData, StandingDataType.FormGroup as number);
    }
}
