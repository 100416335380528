<div class="popover-trigger" [matMenuTriggerFor]="popoverContent" (click)="onClick($event)">
    <ng-container *ngTemplateOutlet="popoverButtonTemplate; context: { data: data }">
    </ng-container>
</div>
<mat-menu #popoverContent="matMenu" class="eff-popover-container-wrapper" [class]="panelClass">
    <div class="popover-container" (click)="$event.stopPropagation(); $event.preventDefault()">
        <ng-container *ngTemplateOutlet="popoverContentTemplate; context: { data: data }">
        </ng-container>
    </div>
</mat-menu>
