import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SampleGenerationType, SampleType } from "@app/controls/testing/advanced/shared/enums";
import {
    EffectivenessModel,
    EffectivenessSourceDocumentsModel,
    WorkflowGenerateSamplesModel,
} from "@app/controls/testing/advanced/shared/models";
import { GenerateSamplesStep } from "./models/generate-samples-steps.enum";
import { ManualSamplesGroup } from "./steps/manual-samples-step/models/manual-sample.model";
import { SampleTypeFormGroup } from "./steps/sample-type-step/models/sample-type-form-group.model";

@Component({
    selector: "generate-samples-popup",
    templateUrl: "./generate-samples-popup.component.html",
    styleUrls: ["./generate-samples-popup.component.scss"],
})
export class GenerateSamplesPopupComponent {
    samplingInformation: WorkflowGenerateSamplesModel;
    effectivenessModel: EffectivenessModel;
    sourceDocumentModel: EffectivenessSourceDocumentsModel;
    availableSampleTypes: any[];
    currentStep: GenerateSamplesStep = GenerateSamplesStep.SampleType;
    isAutomatic: boolean = true;
    title: string;

    manualSamplesGroup: ManualSamplesGroup = new ManualSamplesGroup();
    sampleTypeFormGroup: SampleTypeFormGroup = new SampleTypeFormGroup();

    backButtonName: string;
    nextButtonName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<GenerateSamplesPopupComponent>
    ) {
        this.createDefaultSamplingInformationModel();
        this.effectivenessModel = data.effectivenessModel;
        this.sourceDocumentModel = data.sourceDocumentModel;
        this.availableSampleTypes = data.availableSampleTypes;
        this.isAutomatic =
            this.effectivenessModel.sampleGenerationType == SampleGenerationType.automatic;
        this.title = this.isAutomatic ? "Automatic Sampling" : "Manual Sampling";
        this.initStep();
    }

    private createDefaultSamplingInformationModel() {
        this.samplingInformation = new WorkflowGenerateSamplesModel();
        this.samplingInformation.sampleType = SampleType.number;
        this.samplingInformation.populationSize = 1;
        this.samplingInformation.numberOfTestSamples = 1;
    }

    onNextStep() {
        if (!this.sampleTypeFormGroup.checkAndDisplayValidity()) {
            return;
        }

        if (this.isAutomatic) {
            this.sampleTypeFormGroup.updateSample(
                this.samplingInformation,
                this.sourceDocumentModel
            );
            this.save();
        } else {
            if (this.currentStep == GenerateSamplesStep.SampleType) {
                this.sampleTypeFormGroup.updateSample(
                    this.samplingInformation,
                    this.sourceDocumentModel
                );
                this.currentStep = GenerateSamplesStep.ManualSamples;
            } else {
                this.manualSamplesGroup.updateSample(this.samplingInformation);
                this.save();
            }
        }
        this.initStep();
    }

    onPreviousStep() {
        if (this.isAutomatic) {
            this.dialogRef.close();
        } else {
            if (this.currentStep == GenerateSamplesStep.SampleType) {
                this.dialogRef.close();
            } else {
                this.currentStep = GenerateSamplesStep.SampleType;
            }
        }
        this.initStep();
    }

    initStep() {
        if (this.isAutomatic) {
            this.backButtonName = "Cancel";
            this.nextButtonName = "Generate Samples";
        } else {
            if (this.currentStep == GenerateSamplesStep.SampleType) {
                this.backButtonName = "Previous step";
                this.nextButtonName = "Specify samples";
            }

            if (this.currentStep == GenerateSamplesStep.ManualSamples) {
                this.backButtonName = "Previous step";
                this.nextButtonName = "Generate Samples";
            }
        }
    }

    get isNextButtonEnabled() {
        if (this.currentStep == GenerateSamplesStep.SampleType) {
            return this.sampleTypeFormGroup.isAllFilled();
        } else {
            return this.manualSamplesGroup.isAllFilled();
        }
    }

    save() {
        if (this.data.save) {
            this.data.save(this.samplingInformation);
        }
    }
}
