import { DocumentModel } from "@models/documents/documentmodel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";

export class FindingReportStoreModel {
    name: string = null;
    reportTypeId: number = null;
    reportNumber?: string = null;
    subName?: string = null;
    reportUrl?: string = null;
    overallScoreId?: number = null;
    validationTypeId?: number = null;
    reportDate: Date = null;
    reportDueDate?: Date = null;
    scopeAndObjective?: string = null;
    conclusion?: string = null;
    responsibleOrganizationId: number = null;
    responsibleId: number = null;
    reviewerOrganizationId: number = null;
    reviewerId: number = null;

    documents?: DocumentModel[] = [];
    Hyperlinks?: HyperlinkModel[];
    auditGuid?: string;
    auditDisplayName?: string;
}
