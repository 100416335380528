import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { DataStructuresStandingData } from "@app/datamanagement/models/DataStructuresStandingData";
import { DataSubjectModel } from "@app/datamanagement/models/DataSubjectModel";
import { DatasubjectDialogComponent } from "../../dialogs/datasubject-dialog/datasubject-dialog.component";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { DataStructureModel } from "@app/datamanagement/models/DataStructureModel";
import { SystemModel } from "@app/datamanagement/models/SystemModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "system-detail",
    templateUrl: "./system-detail.component.html",
    styleUrls: ["./system-detail.component.scss", "../../../../sass/datamanagement.scss"],
})
export class SystemDetailComponent implements OnInit {
    @Input() systemModel: SystemModel;
    @Input() standingData: DataStructuresStandingData;
    @Input() disabled: boolean;
    @Output() systemDeleted = new EventEmitter<SystemModel>();

    constructor(
        private _datamanagentDS: DatamanagementDataService,
        private modalService: BsModalService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this.loadDataSubjects();
    }

    manageData() {
        const dataSubjectClone = JSON.parse(JSON.stringify(this.systemModel.dataSubjects));
        const config = <ModalOptions<DatasubjectDialogComponent>>{
            backdrop: "static",
            class: "datamanagement-dialog modal-lg modal-xl",

            initialState: <DatasubjectDialogComponent>{
                dataSubjectModels: dataSubjectClone,
                standingData: this.standingData,
            },
        };

        this.modalService
            .show(DatasubjectDialogComponent, config)
            .content.afterClosed.subscribe((dialogResult: DataSubjectModel[] | boolean) => {
                if (dialogResult) {
                    const selectedDataSubjects = dialogResult as DataSubjectModel[];
                    this.systemModel.dataSubjects = selectedDataSubjects;
                }
            });
    }

    async loadDataSubjects() {
        if (!this.systemModel.connectedId) {
            return;
        }

        const connectedDataSubjects = await this._datamanagentDS
            .getDataSubjects(this.systemModel.connectedId)
            .toPromise();
        const dataSubjects: DataSubjectModel[] = [];

        // Process fetched dataSubjects
        connectedDataSubjects.forEach((connectedDataSubject) => {
            let dataSubject: DataSubjectModel;
            if (dataSubjects.some((x) => x.id === connectedDataSubject.connectedSystemID)) {
                dataSubject = dataSubjects.find(
                    (x) => x.id === connectedDataSubject.connectedSystemID
                );
            } else {
                dataSubject = new DataSubjectModel();
                dataSubjects.push(dataSubject);

                dataSubject.id = connectedDataSubject.dataSubjectId;
                dataSubject.name = connectedDataSubject.name;
                dataSubject.dataStructures = connectedDataSubject.connectedDataStructureIDs.map(
                    (x) =>
                        <DataStructureModel>{
                            connectedId: x,
                            dataSubjectId: connectedDataSubject.dataSubjectId,
                            archivePolicy: "",
                        }
                );
            }

            dataSubject.connectedId = connectedDataSubject.id;
        });

        this.systemModel.dataSubjects = dataSubjects;
        this.systemModel.dataSubjectsLoaded = true;
    }

    deleteSystem() {
        this._promptService
            .confirm("Delete system", "Are you sure you want to delete this system?")
            .onConfirm()
            .subscribe(() => {
                this.systemDeleted.emit(this.systemModel);
            });
    }
}
