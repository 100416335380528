import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
    CerrixTextareaComponent,
    CerrixFieldWrapperComponent,
    CerrixAutocompleteComponent,
    CerrixSelectComponent,
    BaseModel,
    CerrixTreeSelectComponent,
    TreeItemNode,
    SelectOptionModel,
    CerrixInputComponent,
} from "@cerrix/components";

import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrganizationIconComponent } from "../../../shared/icons/organization-icon/organization-icon.component";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ThirdPartyIconComponent } from "../../../shared/icons/thirdparty-icon/thirdparty-icon.component";

@Component({
    selector: "incident-details-tabcontent",
    templateUrl: "./incident-details-tabcontent.component.html",
    styleUrls: ["./incident-details-tabcontent.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CerrixFieldWrapperComponent,
        CerrixTextareaComponent,
        CerrixAutocompleteComponent,
        CerrixSelectComponent,
        CerrixTreeSelectComponent,
        CerrixInputComponent,
        OrganizationIconComponent,
        ThirdPartyIconComponent,
        MatIconModule,
        MatTooltipModule,
    ],
    providers: [],
})
export class IncidentDetailsTabcontentComponent implements OnChanges {
    @Input() public detailsForm: FormGroup;
    @Input() public incidentTypes: BaseModel[];
    @Input() public incidentId: string;

    @Input() public reportedOrganizations: TreeItemNode[] | undefined = undefined;
    @Input() public causedByOrganizations: TreeItemNode[] | undefined = undefined;
    @Input() public affectedOrganizations: TreeItemNode[] | undefined = undefined;
    @Input() public rootCauseCategories: TreeItemNode[] | undefined = undefined;
    @Input() public classifications: SelectOptionModel[] | undefined = undefined;
    @Input() public businessDimensions: TreeItemNode[] | undefined = undefined;
    @Input() public frameworkDimensions: TreeItemNode[] | undefined = undefined;
    @Input() public causedByThirdparties: SelectOptionModel[] | undefined = undefined;
    @Input() public affectedThirdparties: SelectOptionModel[] | undefined = undefined;

    @Output() public onIncidentTypeChange = new EventEmitter<string[]>();

    protected dataLoaded: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.reportedOrganizations === undefined) {
            this.reportedOrganizations = changes.reportedOrganizations?.currentValue;
        }
        if (this.causedByOrganizations === undefined) {
            this.causedByOrganizations = changes.causedByOrganizations?.currentValue;
        }
        if (this.affectedOrganizations === undefined) {
            this.affectedOrganizations = changes.affectedOrganizations?.currentValue;
        }
        if (this.rootCauseCategories === undefined) {
            this.rootCauseCategories = changes.rootCauseCategories?.currentValue;
        }
        if (this.classifications === undefined) {
            this.classifications = changes.classifications?.currentValue;
        }
        if (this.businessDimensions === undefined) {
            this.businessDimensions = changes.businessDimensions?.currentValue;
        }
        if (this.frameworkDimensions === undefined) {
            this.frameworkDimensions = changes.frameworkDimensions?.currentValue;
        }
        if (this.causedByThirdparties === undefined) {
            this.causedByThirdparties = changes.causedByThirdparties?.currentValue;
        }
        if (this.affectedThirdparties === undefined) {
            this.affectedThirdparties = changes.affectedThirdparties?.currentValue;
        }

        if (
            this.reportedOrganizations !== undefined &&
            this.causedByOrganizations !== undefined &&
            this.affectedOrganizations !== undefined &&
            this.rootCauseCategories !== undefined &&
            this.classifications !== undefined &&
            this.businessDimensions !== undefined &&
            this.frameworkDimensions !== undefined &&
            this.causedByThirdparties !== undefined &&
            this.affectedThirdparties !== undefined
        ) {
            this.dataLoaded = true;
        }
    }
}
