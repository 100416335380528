<div class="detail-page-summary" *ngIf="eventModel && eventModel.ID > 0">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>ID:</b> {{ eventModel.Identifier }}</div>
            <div class="property">
                <b>Name:</b> &nbsp;<span data-uiid="event-name-label">{{ eventModel.Name }}</span>
            </div>
            <div class="property">
                <b>Registration date:</b> {{ eventModel.DateCreated | dateFormat }}
            </div>
        </div>
        <div class="col-6">
            <div class="property">
                <b>Date occurred:</b> {{ eventModel.DateOccurrence | dateFormat }}
            </div>
            <div class="property">
                <b>Date detected:</b> {{ eventModel.DateDetection | dateFormat }}
            </div>
            <div class="property"><b>Date closed:</b> {{ eventModel.DateClosed | dateFormat }}</div>
        </div>
    </div>
</div>

<event-comments [eventGuid]="eventModel.Guid" #comments> </event-comments>

<form [formGroup]="parentForm" *ngIf="eventModel">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Event details</div>
        <div class="detail-page-card-body padded">
            <div class="fancy-loader submodule" *ngIf="!sdHeavy"></div>
            <div *ngIf="sdHeavy">
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textfield
                                    fieldLabel="Event name"
                                    dataUiid="event-name"
                                    identifier="eventNameInput"
                                    formControlName="Name"
                                    placeholder="Required"
                                ></cerrix-textfield>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col separator">
                                <cerrix-textfield
                                    fieldLabel="Internal identifier"
                                    dataUiid="event-identifier"
                                    identifier="internalIdentifierInput"
                                    formControlName="InternalIdentifier"
                                ></cerrix-textfield>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>

                        <div class="form-group">
                            <label for="organizationSelect">Organization</label>
                            <cerrix-select-tree
                                data-uiid="event-organization"
                                [tree]="sdHeavy.Organizations"
                                formControlName="OrganizationId"
                                placeholder="Required"
                                [multiselect]="false"
                                (onChangeSingleId)="reloadBusinessData()"
                            >
                            </cerrix-select-tree>
                        </div>
                        <div class="form-group">
                            <label for="organizationCauserSelect">Caused by organization</label>
                            <cerrix-select-tree
                                data-uiid="event-causedbyorganization"
                                [tree]="sdHeavy.Organizations"
                                formControlName="CausedByOrganizationId"
                                [multiselect]="false"
                            >
                            </cerrix-select-tree>
                        </div>

                        <div class="row">
                            <div class="form-group col separator">
                                <label for="organizationSelect">Affected organization</label>
                                <cerrix-select-tree
                                    data-uiid="event-affectedorganization"
                                    [tree]="sdHeavy.Organizations"
                                    formControlName="AffectedOrganizationId"
                                    [multiselect]="false"
                                >
                                </cerrix-select-tree>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>

                        <div class="form-group">
                            <label for="businessDimenionTree">
                                Business dimensions
                                <i
                                    *ngIf="!selectedOrganizationId"
                                    class="far fa-question-circle ms-2"
                                    [matTooltip]="'Please select an organization first.'"
                                ></i>
                            </label>
                            <cerrix-select-tree
                                data-uiid="event-businessdimension"
                                [(tree)]="businessDimensions"
                                formControlName="BusinessDimensions"
                                [multiselect]="true"
                                [readonly]="!selectedOrganizationId"
                            >
                            </cerrix-select-tree>
                        </div>
                        <div class="form-group">
                            <label for="businessLineSelect">Business line</label>
                            <ng-select
                                data-uiid="event-businessline"
                                id="businessLineSelect"
                                name="businessLineSelect"
                                [items]="sdHeavy.BusinessLines"
                                formControlName="BusinessLineId"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="true"
                                [multiple]="false"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group-separator d-xl-none"></div>

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="dateOccurredInput">Date occurred</label>
                                    <date-input
                                        data-uiid="event-dateoccured"
                                        formControlName="DateOccurrence"
                                        [maxDate]="maxDate"
                                    ></date-input>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="dateDetectedInput">Date detected</label>
                                    <date-input
                                        data-uiid="event-datedetected"
                                        formControlName="DateDetection"
                                        [maxDate]="maxDate"
                                    ></date-input>
                                </div>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>

                        <div class="form-group">
                            <label for="eventCategorySelect">Event category</label>
                            <cerrix-select-tree
                                data-uiid="event-category"
                                [tree]="sdHeavy.EventCategories"
                                formControlName="EventCategoryId"
                                [multiselect]="false"
                            >
                            </cerrix-select-tree>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="incidentTypeSelect">Incident type</label>
                                    <ng-select
                                        data-uiid="event-incidenttype"
                                        id="incidentTypeSelect"
                                        name="incidentTypeSelect"
                                        [items]="sdHeavy.IncidentTypes"
                                        formControlName="IncidentTypes"
                                        bindLabel="Name"
                                        bindValue="ID"
                                        [closeOnSelect]="false"
                                        placeholder="Required"
                                        [multiple]="true"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="classificationSelect">Classification</label>
                                    <ng-select
                                        data-uiid="event-classification"
                                        id="classificationSelect"
                                        name="classificationSelect"
                                        [items]="sdHeavy.Classifications"
                                        formControlName="ClassificationId"
                                        bindLabel="Name"
                                        bindValue="ID"
                                        [closeOnSelect]="true"
                                    >
                                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                            <div
                                                [style.background-color]="item.Color"
                                                style="
                                                    min-width: 5px;
                                                    min-height: 22px;
                                                    float: left;
                                                    margin-right: 10px;
                                                "
                                            ></div>
                                            {{ item.Name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>

                        <div class="row">
                            <div class="form-group col">
                                <label for="reporterSelect">Reporter</label>
                                <ng-select
                                    *ngIf="canChangeReporter"
                                    id="reporterSelect"
                                    name="reporterSelect"
                                    [items]="sdHeavy.Reporter"
                                    formControlName="ReportedById"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                                <cerrix-textfield
                                    dataUiid="event-reporter"
                                    *ngIf="!canChangeReporter"
                                    id="reportInput"
                                    formControlName="ReporterName"
                                ></cerrix-textfield>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="responsibleSelect">Responsibles</label>
                                <ng-select
                                    data-uiid="event-responsibles"
                                    id="responsibleSelect"
                                    name="responsibleSelect"
                                    [items]="sdHeavy.Responsibles"
                                    formControlName="Responsible"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    placeholder="Required"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="assessorSelect">Assessors</label>
                                <ng-select
                                    data-uiid="event-assessors"
                                    id="assessorSelect"
                                    name="assessorSelect"
                                    [items]="sdHeavy.Assessors"
                                    formControlName="Assessor"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    placeholder="Required"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="informedSelect">Informed</label>
                                <ng-select
                                    data-uiid="event-informed"
                                    id="informedSelect"
                                    name="informedSelect"
                                    [items]="sdHeavy.Informed"
                                    formControlName="Informed"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Event analysis</div>
        <div class="detail-page-card-body padded cerrix-control-sm">
            <div class="fancy-loader submodule" *ngIf="!sdLight"></div>
            <div *ngIf="sdLight">
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Event description"
                                    dataUiid="event-description"
                                    identifier="eventDescriptionInput"
                                    formControlName="Description"
                                    placeholder="Required"
                                ></cerrix-textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-lg-6">
                                <cerrix-textarea
                                    fieldLabel="Impact description"
                                    dataUiid="event-impactdescription"
                                    identifier="impactDescriptionInput"
                                    formControlName="ImpactDescription"
                                ></cerrix-textarea>
                            </div>
                            <div class="form-group col-12 col-lg-6">
                                <cerrix-textarea
                                    fieldLabel="Cause description"
                                    dataUiid="event-causedescription"
                                    identifier="causeDescriptionInput"
                                    formControlName="CauseDescription"
                                ></cerrix-textarea>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Primary cause"
                                    dataUiid="event-primarycause"
                                    identifier="primaryCauseInput"
                                    formControlName="PrimaryCause"
                                ></cerrix-textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Secondary cause"
                                    dataUiid="event-secondarycause"
                                    identifier="secondaryCauseInput"
                                    formControlName="SecondaryCause"
                                ></cerrix-textarea>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Effect description"
                                    dataUiid="event-effectdescription"
                                    identifier="effectDescriptionInput"
                                    formControlName="EffectDescription"
                                ></cerrix-textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="effectsSelect">Effects</label>
                                <ng-select
                                    data-uiid="event-effects"
                                    id="effectsSelect"
                                    name="effectsSelect"
                                    [multiple]="true"
                                    [items]="sdLight.Effects"
                                    formControlName="Effects"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>

                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Reputation impact description"
                                    data-uiid="event-reputationimpactdescription"
                                    identifier="reputationImpactDescInput"
                                    formControlName="ReputationImpactDescription"
                                ></cerrix-textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="reputationImpactSelect">Reputation impact</label>
                                <ng-select
                                    data-uiid="event-reputationimpact"
                                    id="reputationImpactSelect"
                                    name="reputationImpactSelect"
                                    [items]="sdLight.ReputationImpacts"
                                    formControlName="ReputationImpactId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="true"
                                >
                                </ng-select>
                            </div>
                        </div>

                        <div class="form-group-separator d-xl-none"></div>
                    </div>
                    <div class="col-12 col-xl-6 right-block">
                        <div class="row">
                            <div class="form-group col-6">
                                <label for="grossImpactNumber">Gross impact</label>
                                <input
                                    data-uiid="event-grossimpact"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="grossImpactNumber"
                                    name="grossImpactNumber"
                                    formControlName="GrossCosts"
                                    step="0.01"
                                    data-number-to-fixed="2"
                                    data-number-stepfactor="100"
                                    (change)="calculateNetCosts()"
                                />
                            </div>
                            <div class="form-group col-6">
                                <label for="recoveryNumber">Recovery</label>
                                <input
                                    data-uiid="event-recovery"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="recoveryNumber"
                                    name="recoveryNumber"
                                    formControlName="Recovery"
                                    (change)="calculateNetCosts()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="netImpactText">Nett impact</label>
                                <input
                                    data-uiid="event-nettimpact"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="netImpactText"
                                    name="netImpactText"
                                    formControlName="NetCosts"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="currencySelect">Currency</label>
                                <ng-select
                                    data-uiid="event-currency"
                                    id="currencySelect"
                                    name="currencySelect"
                                    formControlName="CurrencyCode"
                                    [items]="sdLight.Currencies"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>
                        </div>

                        <div class="form-group-separator"></div>

                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Risk opinion"
                                    dataUiid="event-riskopinion"
                                    identifier="riskOpinionInput"
                                    formControlName="RiskOpinion"
                                ></cerrix-textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <cerrix-textarea
                                    fieldLabel="Comments"
                                    dataUiid="event-comments"
                                    identifier="commentsInput"
                                    formControlName="Comments"
                                ></cerrix-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Custom fields -->
    <div
        class="detail-page-card"
        *ngIf="eventCustomFieldsFormGroup && this.customFieldStandingDataModel.any()"
    >
        <div class="detail-page-card-title">Custom fields</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <cerrix-custom-fields-editor
                [parentForm]="eventCustomFieldsFormGroup"
                [customFieldData]="customFieldData"
                [singleColumn]="false"
            ></cerrix-custom-fields-editor>
        </div>
    </div>
</form>
