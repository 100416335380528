import { DocumentModel } from "@models/documents/documentmodel";
import { guid } from "./uniqueMethods";
import { ToastrService } from "ngx-toastr";

export interface IHasDocuments {
    Documents: DocumentModel[];
}

export class DocumentMethods {
    constructor() {}

    static convertToFormDataWithFiles(objectName: string, object: IHasDocuments): FormData {
        const formData = new FormData();
        formData.append(objectName, JSON.stringify(object));

        const files = object.Documents;
        if (files && Array.isArray(files)) {
            for (const file of files) {
                formData.append(file.Guid, file.File);
            }
        }

        return formData;
    }

    static convertToFormData(objectName: string, files: DocumentModel[]): FormData {
        const formData = new FormData();
        for (const file of files) {
            formData.append(file.Guid, file.File);
        }

        formData.append(objectName, JSON.stringify(files));
        return formData;
    }

    static convertFileListToDocumentModel(
        documents: File[] | FileList,
        username?: string,
        description?: string,
        documentTypeName?: string,
        documentTypeID?: number
    ): DocumentModel[] {
        const addedDocuments: any[] = [];
        for (let i = 0; i < documents.length; i++) {
            const doc = new DocumentModel();
            doc.Guid = guid();
            doc.IsNew = true;
            doc.CanEdit = true;
            doc.CanDelete = true;
            doc.Date = new Date();
            doc.Version = 0;
            doc.Username = username;

            doc.Description = description;
            doc.DocumentType = documentTypeName;
            doc.DocumentTypeID = documentTypeID;

            const selectedDocument = documents[i];
            doc.Name = selectedDocument.name;
            doc.File = selectedDocument;

            addedDocuments.push(doc);
        }

        return addedDocuments;
    }

    static getFileExtension(filename: string) {
        const extension = "." + filename.split(".").pop().toLowerCase();

        return extension;
    }

    static filterAllowedFiles(
        toastr: ToastrService,
        files: File[] | FileList,
        allowedExtensions: string
    ): File[] {
        const filteredFiles: File[] = [];
        const allowedExtensionsArr = allowedExtensions.split(",");
        const notAllowedExtensions: string[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileExtension = this.getFileExtension(file.name);

            if (allowedExtensionsArr.indexOf(fileExtension) >= 0) {
                filteredFiles.push(file);
            } else {
                notAllowedExtensions.pushIfNotExists(fileExtension);
            }
        }

        if (notAllowedExtensions.length > 0) {
            toastr.error(
                "The following file extensions are not allowed: " + notAllowedExtensions.join(", "),
                "Not allowed extensions"
            );
        }

        return filteredFiles;
    }

    static validateUploadLimit(
        maxUploadSizeInMb: number,
        toastr: ToastrService,
        files: File[]
    ): boolean {
        const totalFileSize = files
            .map((f) => f.size / 1024 / 1024) // Convert to MB
            .reduce((l, r) => l + r, 0); // Sum

        if (totalFileSize > maxUploadSizeInMb) {
            toastr.error(
                "Please upload partially or upload smaller documents.",
                `Maximum upload limit of ${maxUploadSizeInMb}MB exceeded.`
            );

            return false;
        }

        return true;
    }

    static getDocumentIcon(documentName: string): string {
        const fileNameSplit = documentName ? documentName.toLowerCase().split(".") : [];
        const fileExt = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : "";

        if (fileExt.indexOf("pdf") >= 0) {
            return "file-pdf";
        } else if (
            fileExt.indexOf("jpg") >= 0 ||
            fileExt.indexOf("png") >= 0 ||
            fileExt.indexOf("jpeg") >= 0
        ) {
            return "file-image";
        } else if (fileExt.indexOf("doc") >= 0) {
            return "file-word";
        } else if (fileExt.indexOf("txt") >= 0 || fileExt.indexOf("msg") >= 0) {
            return "file-alt";
        } else if (
            fileExt.indexOf("xls") >= 0 ||
            fileExt.indexOf("xlsx") >= 0 ||
            fileExt.indexOf("xml") >= 0
        ) {
            return "file-excel";
        } else if (fileExt.indexOf("zip") >= 0) {
            return "file-archive";
        } else {
            return "file";
        }
    }
}
