<div class="fancy-loader" *ngIf="isLoading && model"></div>
<findings-linkable
    *ngIf="!isLoading && model"
    #linkable
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [hideActions]="true"
    [orderByValueProperty]="true"
    (linkedChange)="linkedChanged($event)"
    [disabled]="disabled"
>
</findings-linkable>
