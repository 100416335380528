import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentOrganizationModel } from "../models/incident-organization.model";

@Injectable({
    providedIn: "root",
})
export class IncidentOrganizationService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/organizations`;

    public getAll(): Observable<IncidentOrganizationModel[]> {
        return this.httpClient.get<IncidentOrganizationModel[]>(this.baseUrl);
    }

    public getReportedOrganizations(incidentId: string): Observable<IncidentOrganizationModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentOrganizationModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentOrganizationModel[]>(
                `${this.baseUrl}/incident/${incidentId}/reporting`
            );
        }
    }

    public getCausedbyOrganizations(incidentId: string): Observable<IncidentOrganizationModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentOrganizationModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentOrganizationModel[]>(
                `${this.baseUrl}/incident/${incidentId}/caused-by`
            );
        }
    }

    public getAffectedOrganizations(incidentId: string): Observable<IncidentOrganizationModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentOrganizationModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentOrganizationModel[]>(
                `${this.baseUrl}/incident/${incidentId}/affected`
            );
        }
    }
}
