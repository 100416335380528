export enum EffTableSelectionEnum {
    None = 0,
    Single = 1,
    Multiple = 2,
}

export class EffTableConfig {
    ExpandableRow: boolean = false;
    HideHeader?: boolean = true;
    SeparateRows?: boolean;
    Selection?: EffTableSelectionEnum;
    DisableRowFunc?: (item: any, items: any[]) => boolean;
    RowClassFunc?: (item: any) => string;
    OnRowClickFunc?: (item: any) => void;
}
