import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";

export class AuditContextModel {
    auditGuid: string;
    auditType: number;

    linkedUniverseObjectIds: number[];
    organizationIds: number[];
    businessDimensionIds: number[];
    frameworkDimensionIds: number[];

    contextValues: CustomFieldDetailModel[];
}
