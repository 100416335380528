<div class="cerrix-workflow-bar">
    <div
        [ngClass]="['step-item', WorkflowStepStatus[step.status]]"
        *ngFor="let step of steps; let i = index"
    >
        <div class="step-icon-container">
            <div
                (mouseenter)="mouseEnter(i)"
                (mouseleave)="mouseLeave()"
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin"
                class="step-circle"
            ></div>

            <div class="step-title">
                {{ step.name }}
            </div>
        </div>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="openPopupIndex === i"
            (detach)="(openPopupIndex !== i)"
            [cdkConnectedOverlayPositions]="[
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top'
                }
            ]"
        >
            <div
                (mouseenter)="mouseEnter(i)"
                (mouseleave)="mouseLeave()"
                [class]="'workflow-step-popup ' + WorkflowStepStatus[step.status]"
            >
                <div class="step-popup-container">
                    <div class="title">{{ step.infoTitle }}</div>

                    <div *ngIf="step.stepDetails" class="step-info-container">
                        <ng-container *ngFor="let details of step.stepDetails">
                            <div class="info-key">{{ details.key }}</div>
                            <div class="info-values">
                                <div class="info-value" *ngFor="let value of details.values">
                                    {{ value }}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="step-separator"></div>
    </div>
</div>
