import { Component, Input, EventEmitter, Output } from "@angular/core";
import { SimpleTestingStandingData } from "../../models/SimpleTestingStandingData";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { SimpleTestingDataService } from "../../services/simple-testing.service";
import { SimpleTestingModel } from "../../models/SimpleTestingModel";
import { SimpleTestingType } from "../../enums/SimpleTestingType";

@Component({
    selector: "simple-testing-testplan",
    templateUrl: "./simple-testing-test-plan.component.html",
    styleUrls: ["./simple-testing-test-plan.component.scss"],
})
export class SimpleTestingTestPlanComponent {
    @Input() guid: string;
    @Input() type: SimpleTestingType;
    @Input() standingData: SimpleTestingStandingData;
    @Input() simpleTestingGroup: FormGroup;
    @Output() catalogueChanged = new EventEmitter<IdNameCombination>();

    constructor(private ds: SimpleTestingDataService) {}

    SimpleTestingType = SimpleTestingType;

    async loadOldVersion(version: number): Promise<void> {
        this.simpleTestingGroup = null;
        this.standingData = null;

        const getSd = this.ds.getStandingDataByVersion(this.type, this.guid, version).toPromise();
        const getItem = this.ds.getSimpleTestingByVersion(this.type, this.guid, version).toPromise();

        this.standingData = await getSd;
        let model = await getItem;

        if (model == null) {
            model = new SimpleTestingModel();
        }

        const catalogueValidator =
            this.type === SimpleTestingType.DesignImplementation
                ? [Validators.required]
                : [];

        this.simpleTestingGroup = new FormGroup({
            TestActivity: new FormControl(model.TestActivity),
            TestNorm: new FormControl(model.TestNorm),
            Catalogue: new FormControl(model.Catalogue, catalogueValidator),
            TestSamples: new FormControl(model.TestSamples),
            Testers: new FormControl(model.Testers),
            InitialTestDate: new FormControl(model.InitialTestDate),
            EvidenceUploaders: new FormControl(model.EvidenceUploaders),
            UploaderDueDate: new FormControl(model.UploaderDueDate),
            LinkedStandard: new FormControl(model.LinkedStandard),
            QualityAspect: new FormControl(model.QualityAspect),
            TestFrequency: new FormControl(model.TestFrequency),
        });

        this.simpleTestingGroup.disable();
    }
}
