<div class="scrollable-container" (scroll)="onScroll(scrollableContainer)" #scrollableContainer>
    <div class="container my-4">
        <div class="row" *ngIf="versions">
            <div class="col-2 versions">
                <div class="static-container">
                    <label class="title">Updates</label>
                    <ul>
                        <li
                            [class.active]="v == loadedVersion"
                            *ngFor="let v of versions"
                            (click)="getReleaseNotes(v)"
                        >
                            {{ v }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-8 release-notes">
                <ng-container *ngIf="releaseNotes">
                    <h3>{{ releaseNotes.title }}</h3>
                    <p *ngIf="releaseNotes.description" [innerHTML]="releaseNotes.description"></p>

                    <ng-container *ngFor="let section of releaseNotes.sections">
                        <h4 class="section-title mt-4 mb-3 scroll-header">{{ section.title }}</h4>

                        <ng-container *ngFor="let paragraph of section.paragraphs">
                            <h6>
                                <b>{{ paragraph.title }}</b>
                            </h6>
                            <p [innerHTML]="paragraph.description"></p>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>

            <div class="col-2 toc">
                <ng-container *ngIf="releaseNotes">
                    <div class="static-container">
                        <label class="title">In this update</label>
                        <ul>
                            <li
                                [class.active]="section.title == activeHeader"
                                *ngFor="let section of releaseNotes.sections"
                                (click)="scrollToHeader(section.title)"
                            >
                                {{ section.title }}
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
