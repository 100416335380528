import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class SaveButtonService {
    saveSuccessEvent: EventEmitter<any> = new EventEmitter();
    triggerSaveSuccess() {
        this.saveSuccessEvent.emit();
    }
}
