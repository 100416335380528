import { Component, ViewChild } from "@angular/core";
import { ControlTestScoreChartConfig } from "../ControlTestScoreChartConfig";
import { ControlTestscoreChartComponent } from "../control-testscore-chart/control-testscore-chart.component";
import { ControlChartDataService } from "../control-chart.data.service";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";

@Component({
    selector: "control-adv-eff-chart",
    templateUrl: "./control-adv-eff-chart.component.html",
    styleUrls: ["./control-adv-eff-chart.component.scss"],
})
export class ControlAdvEffChartComponent {
    @ViewChild("chart", { static: true }) chart: ControlTestscoreChartComponent;

    periods: IdNameColorModel[];
    selectedPeriod: number;

    controlGuid: string;

    constructor(private ds: ControlChartDataService) {}

    setup(controlGuid: string): void {
        this.controlGuid = controlGuid;
        this.ds.getAdvancedPeriods(this.controlGuid).subscribe((x) => {
            this.periods = x;
            this.setupFirstChart();
        });
    }

    setupFirstChart(): void {
        if (!this.periods[0]) {
            this.chart.showNoData = true;
            return;
        }
        this.selectedPeriod = this.periods[0].ID;
        this.createChart(this.selectedPeriod);
    }

    createChart(periodID: number): void {
        if (this.chart.loadedChart) {
            this.chart.loadedChart.destroy();
            this.chart.loadedChart = null;
            this.chart.showNoData = false;
        }

        this.ds.getAdvancedChart(periodID, this.controlGuid).subscribe((data) => {
            const config = <ControlTestScoreChartConfig>{
                chartTitle: "",
                xTitle: "Test result date",
                xCategories: data.xAxis,
                yTitle: "Score",
                yCategories: data.yAxis.map((x) => x.Name),
                data: data.data,
            };

            this.chart.createChart(config);
        });
    }
}
