.risk-matrix-editor {
    display: inline-flex;
    width: 100%;

    .left-area {
        flex: 1;
        margin-right: 10px;
    }

    .right-area {
        flex: 1;
        margin-left: 10px;

        .right-content {
            flex: 1;
        }
    }

    .detail-page-card {
        height: 100%;
        margin: 0;

        .detail-page-card-body {
            margin-bottom: 0;
        }
    }
}

.parent-matrix-viewer {
    text-align: center;

    label {
        font-style: italic;
        margin-bottom: 10px;
    }

    .matrix .risk-matrix .scale {
        cursor: default !important;
    }
}
