import { Injectable, inject } from "@angular/core";
import { PermissionModel } from "@models/permissions/permission.model";
import { IncidentsPermissionService } from "./incidents-permission.service";
import { BaseMicroservicePermissionService } from "./base-microservice-permission.service";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { Observable, Subject, catchError, forkJoin, of } from "rxjs";
import { PermissionLevel } from "@enums/permissions/permission-level.enum";

@Injectable({
    providedIn: "root",
})
export class MicroservicePermissionService {
    private permisionServices: BaseMicroservicePermissionService<number>[] = [
        inject(IncidentsPermissionService),
    ];

    private permissions: Map<MicroserviceModule, PermissionModel<number>[]> = new Map();

    public setPermissions(): Observable<void> {
        let result = new Subject<void>();

        forkJoin(
            this.permisionServices.map((service) =>
                service.getPermissions().pipe(catchError(() => of(null)))
            )
        ).subscribe((servicePermissions) => {
            servicePermissions.forEach((servicePermision, index) => {
                this.permissions.set(
                    this.permisionServices[index].microserviceModule,
                    servicePermision
                );
            });

            result.next();
            result.complete();
        });

        return result.asObservable();
    }

    public hasModuleAccess(microserviceModule: MicroserviceModule): boolean {
        return this.permissions.get(microserviceModule)?.any() ?? false;
    }

    public hasPermission(microserviceModule: MicroserviceModule, permissionType: number): boolean {
        return this.permissions
            .get(microserviceModule)
            .some((p: PermissionModel<number>) => p.permissionType == permissionType);
    }

    public hasAnyPermission(
        microserviceModule: MicroserviceModule,
        permissionTypes: number[]
    ): boolean {
        return this.permissions
            .get(microserviceModule)
            .some((p: PermissionModel<number>) => permissionTypes.includes(p.permissionType));
    }

    public getPermissionLevel(
        microserviceModule: MicroserviceModule,
        permissionType: number
    ): PermissionLevel {
        return this.permissions
            .get(microserviceModule)
            .filter((p: PermissionModel<number>) => p.permissionType == permissionType)[0]?.level;
    }
}
