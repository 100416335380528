import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WorkflowStepItemModel } from "./models/workflow-step-item";
import { WorkflowStepStatus } from "./models/workflow-step-status.enum";
import { OverlayModule } from "@angular/cdk/overlay";

@Component({
    selector: "cerrix-workflow-bar",
    templateUrl: "./cerrix-workflow-bar.component.html",
    styleUrls: ["./cerrix-workflow-bar.component.scss"],
    standalone: true,
    imports: [CommonModule, OverlayModule],
    providers: [],
})
export class CerrixWorkflowBarComponent {
    @Input() public steps: WorkflowStepItemModel[];

    protected readonly WorkflowStepStatus: typeof WorkflowStepStatus = WorkflowStepStatus;

    protected openPopupIndex: number | null = null;
    private closePopupTimeout = null;

    protected mouseEnter(index: number) {
        if (this.closePopupTimeout) {
            clearTimeout(this.closePopupTimeout);
        }

        this.openPopupIndex = index;
    }

    protected mouseLeave() {
        this.closePopupTimeout = setTimeout(() => {
            this.openPopupIndex = null;
        }, 500);
    }
}
