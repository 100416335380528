import {
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { FilterTree } from "@methods/TreeMethods";
import { guid } from "@methods/uniqueMethods";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { LocalStorageService } from "@services/http/LocalStorageService";
import { isObservable, Observable } from "rxjs";
import { CerrixPromptService } from "./../services/cerrix-prompt.service";

@Component({
    selector: "cerrix-select-tree",
    templateUrl: "./cerrix-select-tree.component.html",
    styleUrls: ["./cerrix-select-tree.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CerrixSelectTreeComponent,
            multi: true,
        },
    ],
})
export class CerrixSelectTreeComponent implements ControlValueAccessor, OnInit {
    // In VS Code: CTRL + Shift + P --> Fold All Regions --> Keep it CLEAN!
    //#region Input Properties

    @Input() identifier = guid();
    @Input() multiselect: boolean;
    @Input() required: boolean;
    @Input() readonly: boolean;
    @Input() maxLines = 6;

    @Input() placeholder: string;
    @Input("data-uiid") dataUiid: string = guid();

    //#region getDataMethod input handler

    private _getDataMethod: Observable<CerrixTreeItem[]> | Promise<CerrixTreeItem[]>;
    @Input()
    set getDataMethod(getDataMethod: Observable<CerrixTreeItem[]> | Promise<CerrixTreeItem[]>) {
        this._getDataMethod = getDataMethod;
        if (this._getDataMethod) {
            this.processDataMethod();
        }
    }
    get getDataMethod(): Observable<CerrixTreeItem[]> | Promise<CerrixTreeItem[]> {
        return this._getDataMethod;
    }

    //#endregion getDataMethod input handler

    //#region tree input handler

    private _tree: CerrixTreeItem[];
    @Input()
    set tree(tree: CerrixTreeItem[]) {
        this._tree = tree;
        this.setSelectionTree(this.value);
    }
    get tree(): CerrixTreeItem[] {
        return this._tree;
    }

    //#endregion tree input handler

    //#endregion Input Properties

    //#region Output properties

    @Output() onChange = new EventEmitter<number | number[]>();
    @Output() onChangeIds = new EventEmitter<number[]>();
    @Output() onChangeSingleId = new EventEmitter<number>();

    //#endregion Output properties

    //#region Form Validation

    @Input() formControlName: string;
    control: AbstractControl;

    @Output() valueChange = new EventEmitter<number[] | number>();
    onChangeFormValidation = Function.prototype;
    onTouchedFormValidation = Function.prototype;

    _value: number[] | number;
    @Input()
    set value(value: number[] | number) {
        if (this._value === value) {
            return;
        }

        this._value = value;
        this.setSelectionTree(value);
        this.valueChange.emit(value);
        this.onChangeFormValidation(value);
    }
    get value(): number[] | number {
        return this._value;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChangeFormValidation = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchedFormValidation = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        // It should still be posible to view tree
        this.readonly = isDisabled;
    }

    //#endregion Form Validation

    //#region Component State properties

    @ViewChild("treeStructure", { static: true }) treeStructure: ElementRef;

    selectionTree: CerrixTreeItem[];

    popupTree: CerrixTreeItem[];
    popupSelection: number[];

    searchFilter = "";

    loading = false;

    cascadeChildren = false;
    private cascadeChildrenStorageKey = "CERRIX_SELECT_TREE_CASCADE_CHILDREN";

    customTouched = false;
    customInvalid = false;

    onCustomInputBlur() {
        if (this.control) {
            this.customTouched = false;
            this.customInvalid = false;
            return;
        }

        this.customTouched = true;
        if (this.required) {
            const valueIsEmpty =
                !this.value || (Array.isArray(this.value) && this.value.length == 0);

            if (valueIsEmpty) {
                this.customInvalid = true;
                return;
            }
        }

        this.customInvalid = false;
    }

    //#endregion Component State properties

    //#region Initializers

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private _controlContainer: ControlContainer,
        private _promptService: CerrixPromptService,
        private _localStorage: LocalStorageService
    ) {
        this.cascadeChildren = _localStorage.getItem<boolean>(this.cascadeChildrenStorageKey);
    }

    ngOnInit() {
        if (this._controlContainer && this.formControlName) {
            this.control = this._controlContainer.control.get(this.formControlName);
        }
    }

    //#endregion Initializers

    //#region Public Methods (callable outside component)

    public updateValue(value: number | number[]) {
        this.value = value;
    }

    public openSelectionPopup() {
        this.openSelection();
    }

    public reload(ids: number[]) {
        if (this.multiselect) {
            this.value = ids;

            this.onChangeIds.emit(ids);
        } else {
            const id = ids.length > 0 ? ids[0] : null;
            this.value = id;

            this.onChangeSingleId.emit(id);
        }

        this.onChange.emit(this.value);
    }

    //#endregion Public Methods (callable outside component)

    //#region Template methods (open, toggle, expand, etc)

    async openSingleSelectionIfApplicable() {
        if (this.multiselect) {
            return;
        }

        await this.openSelection();
    }

    async openSelection() {
        if (this.loading || this.readonly) {
            return;
        }

        this.onTouchedFormValidation();

        this.popupTree = JSON.parse(JSON.stringify(this.tree));
        this.popupSelection = this.getSelectionAsArray(this.value);

        this.setIndeterminate();
        this.expandSelected(this.popupTree);

        const result = await this._promptService
            .prompt({
                maxHeight: "800px",
                maxWidth: "1200px",
                data: {
                    title: "Change selection",

                    customTemplate: this.treeStructure,
                    customTemplateDataUiid: this.dataUiid + "-popup",
                    hideBodyPadding: true,

                    confirmButton: {
                        text: "Apply",
                        uiid: this.dataUiid + "-applybtn",
                    },
                    cancelButton: {
                        text: "Close",
                        uiid: this.dataUiid + "-closebtn",
                    },
                },
            })
            .toPromise();

        if (result) {
            this.applyChange();
        }

        this.popupTree = null;
        this.popupSelection = null;
        this.searchFilter = "";
        this.onCustomInputBlur();
    }

    toggleNode(node: CerrixTreeItem) {
        // Either it is readonly or not in edit mode.
        if (this.readonly || !this.popupTree) {
            return;
        }

        const idsToToggle: number[] = [node.ID];

        if (this.multiselect && this.cascadeChildren && node.Children) {
            const children = [...node.Children];
            while (children.length > 0) {
                const child = children.shift();
                idsToToggle.push(child.ID);
                if (child.Children) {
                    children.push(...child.Children);
                }
            }
        }

        if (this.multiselect) {
            if (this.popupSelection.indexOf(node.ID) < 0) {
                this.popupSelection = this.popupSelection.concat(idsToToggle).distinct();
            } else {
                this.popupSelection = this.popupSelection.filter((x) => idsToToggle.indexOf(x) < 0);
            }
        } else {
            if (this.popupSelection.indexOf(node.ID) < 0) {
                this.popupSelection = [node.ID];
            }
        }

        this.setIndeterminate();
    }

    toggleExpand(node: CerrixTreeItem) {
        if (node.Children && node.Children.length > 0) {
            node.isExpanded = !node.isExpanded;
        }
    }

    expandAll(nodes: CerrixTreeItem[]) {
        nodes.forEach((node) => {
            node.isExpanded = node.Children && node.Children.length > 0;
            if (node.isExpanded) {
                this.expandAll(node.Children);
            }
        });
    }

    expandSelected(nodes: CerrixTreeItem[]): boolean {
        let anySelected = false;

        nodes.forEach((node) => {
            const childSelected = node.Children ? this.expandSelected(node.Children) : false;
            if (childSelected) {
                node.isExpanded = true;
            } else {
                node.isExpanded = false;
            }

            if (childSelected || this.popupSelection.indexOf(node.ID) >= 0) {
                anySelected = true;
            }
        });

        return anySelected;
    }

    collapseAll(nodes: CerrixTreeItem[]) {
        nodes.forEach((node) => {
            node.isExpanded = false;
            if (node.Children && node.Children.length > 0) {
                this.collapseAll(node.Children);
            }
        });
    }

    selectAll(nodes: CerrixTreeItem[]) {
        const checkOnFilter = this.searchFilter.trim().length > 0;

        const allIDs = [];
        const toProcess = [...nodes];
        while (toProcess.length > 0) {
            const node = toProcess.shift();
            if (!checkOnFilter || node.show) {
                allIDs.push(node.ID);
            }
            if (node.Children && node.Children.length > 0) {
                toProcess.push(...node.Children);
            }
        }

        this.popupSelection = allIDs;
        this.setIndeterminate();
    }

    deselectAll(nodes: CerrixTreeItem[]) {
        const checkOnFilter = this.searchFilter.trim().length > 0;
        if (checkOnFilter) {
            const toProcess = [...nodes];
            while (toProcess.length > 0) {
                const node = toProcess.shift();

                if (!checkOnFilter || node.show) {
                    const nodeIndex = this.popupSelection.indexOf(node.ID);
                    if (nodeIndex >= 0) {
                        this.popupSelection.splice(nodeIndex, 1);
                    }
                }

                if (node.Children && node.Children.length > 0) {
                    toProcess.push(...node.Children);
                }
            }
        } else {
            this.popupSelection = [];
        }

        this.setIndeterminate();
    }

    toggleCascadeChildren() {
        this.cascadeChildren = !this.cascadeChildren;
        this._localStorage.setItem(this.cascadeChildrenStorageKey, this.cascadeChildren);
    }

    filterPopupTree(allNodes: CerrixTreeItem[]) {
        FilterTree(allNodes, this.searchFilter);
    }

    resetFilterPopupTree(allNodes: CerrixTreeItem[]) {
        this.searchFilter = "";
        this.filterPopupTree(allNodes);
    }

    //#endregion Template methods (open, toggle, expand, etc)

    //#region Private methods

    private processDataMethod() {
        const handler = (tree) => {
            this.tree = tree;
            this.loading = false;
        };

        if (this.getDataMethod) {
            this.loading = true;
            if (isObservable(this.getDataMethod)) {
                (<Observable<any>>this.getDataMethod).subscribe(handler);
            } else {
                (<Promise<any>>this.getDataMethod).then(handler);
            }
        }
    }

    private applyChange() {
        if (this.multiselect) {
            const ids = [...this.popupSelection];
            this.value = ids;

            this.onChangeIds.emit(ids);
        } else {
            const id = this.popupSelection.length > 0 ? this.popupSelection[0] : null;
            this.value = id;

            this.onChangeSingleId.emit(id);
        }

        this.onChange.emit(this.value);
    }

    private setIndeterminate() {
        const func = (nodes: CerrixTreeItem[]): boolean => {
            let anySelected = false;
            nodes.forEach((node) => {
                const nodeSelected = this.multiselect && this.popupSelection.indexOf(node.ID) >= 0;
                const childSelected = node.Children && func(node.Children);
                node.ChildSelected = childSelected && !nodeSelected;

                if (nodeSelected || childSelected) {
                    anySelected = true;
                }
            });

            return anySelected;
        };

        func(this.popupTree);
    }

    private getSelectionAsArray(value: number | number[]) {
        return value ? (this.multiselect ? <number[]>value : [<number>value]) : [];
    }

    private setSelectionTree(valueOverride?: number | number[]) {
        const tree = this.tree;
        const values = this.getSelectionAsArray(valueOverride ? valueOverride : this.value);
        this.selectionTree = this.getSelectionTree(tree, values);
    }

    private getSelectionTree(nodes: CerrixTreeItem[], selection: number[], pathPrefix?: string) {
        const selectionTree: CerrixTreeItem[] = [];
        if (!nodes || nodes.length == 0 || !selection || selection.length == 0) {
            return;
        }

        nodes.forEach((item) => {
            const itemPath = (pathPrefix ? pathPrefix + " / " : "") + item.Name;
            const selected = selection.indexOf(item.ID) >= 0;
            const childSelection =
                item.Children && item.Children.length > 0
                    ? this.getSelectionTree(item.Children, selection, itemPath)
                    : [];

            if (selected) {
                selectionTree.push({
                    ID: item.ID,
                    ParentID: item.ParentID,
                    ID_Path: itemPath,

                    Name: item.Name,
                    Icon: item.Icon,

                    Children: childSelection,
                });
            } else if (childSelection.length > 0) {
                selectionTree.push(...childSelection);
            }
        });

        return selectionTree;
    }

    //#endregion Private methods
}
