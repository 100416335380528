import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "app-process-overview",
    templateUrl: "./process-overview.component.html",
    styleUrls: ["./process-overview.component.scss"],
})
export class ProcessOverviewComponent implements OnInit {
    public tab: TabModel;

    constructor() {}

    ngOnInit() {}
}
