import { Component, Input, OnInit } from "@angular/core";
import {
    FormFieldWidgetType,
    FormFieldWidgetTypeModels,
    FormFieldWidgetTypeDescription,
    FormFieldWidgetTypeModel,
} from "@app/forms/enums/FormFieldWidgetTypes";
import { FormStructureType } from "@app/forms/enums/FormStructureType";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { parseTyped } from "@methods/CommonMethods";

@Component({
    selector: "form-detail-widget",
    templateUrl: "./form-detail-widget.component.html",
})
export class FormDetailWidgetComponent implements OnInit {
    @Input() field: FormField;

    widgetTypes = FormFieldWidgetType;
    widgetTypeModels = FormFieldWidgetTypeModels;

    widgetSettings: FormFieldConfig;

    loadedWidgetSource: FormFieldWidgetType;

    constructor(private _formDS: FormsDataService) {}

    ngOnInit() {
        this.widgetSettings = parseTyped<FormFieldConfig>(
            this.field.CustomSettings,
            new FormFieldConfig()
        );
        if (this.widgetSettings.widgetType) {
            this.changeType(this.widgetSettings.widgetType);
        } else {
            this.changeType(FormFieldWidgetType.RiskMatrix);
        }
    }

    changeType(type: FormFieldWidgetType) {
        if (this.loadedWidgetSource === type) {
            return;
        }

        this.loadedWidgetSource = type;
        this.widgetSettings.widgetType = type;
        const widgetText = (FormFieldWidgetTypeDescription[type] as FormFieldWidgetTypeModel).text;
        if (this.field.Name.trim().length === 0 || this.field.Name === widgetText) {
            this.field.Name = widgetText;
        }

        if (this.widgetSettings.widgetType === FormFieldWidgetType.RiskMatrix) {
            this.widgetSettings.structureType = FormStructureType.Organization;
            this.field.SourceDataCall = this._formDS.getStructureData(null, this.widgetSettings);
        }

        this.field.SourceData = null;
        this.field.SourceDataCall.then((x) => (this.field.SourceData = x));

        this.saveModel();
    }

    saveModel() {
        this.field.CustomSettings = JSON.stringify(this.widgetSettings);
    }
}
