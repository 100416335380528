import { Component, Input } from "@angular/core";
import { ControlModel } from "@app/controls/models/ControlModel";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { LinkModel } from "@models/generic/LinkModel";

@Component({
    selector: "control-thirdparty",
    templateUrl: "./control-thirdparty.component.html"
})
export class ControlThirdPartyComponent {
    @Input() controlModel: ControlModel;
    @Input() disabled: boolean = true;

    linked: LinkModel[] = [];
    unlinked: LinkModel[] = [];
    isLoading = true;

    menuItem: TabMenuItem;
    tabIconBackup: string;

    backupList: number[];

    constructor(private _controlService: ControlDataService) {}

    load(menuItem: TabMenuItem): void {
        this.menuItem = menuItem;
        if (!this.controlModel.Organization) {
            return;
        }
        if (!this.backupList) {
            this.backupList = this.controlModel.TpIdList;
        }
        this._controlService.getLinkableTp(this.controlModel.Guid).subscribe(data => {
            const linkedIds = this.getLinked();
            this.loadData(linkedIds, data);
            this.isLoading = false;
        });
    }

    getLinked(): number[] {
        return this.controlModel.TpIdList;
    }

    private loadData(linkedIds: number[], items: LinkModel[]): void {
        const linked: LinkModel[] = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            }
        }
        this.linked = linked;
    }
}
