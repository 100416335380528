import { Component, Input, OnInit } from "@angular/core";
import {
    CerrixGridEditorColumnConfig,
    CerrixGridEditorConfig,
} from "@app/shared/models/cerrix-grid-editor-config";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { ToastrService } from "ngx-toastr";
import { OrganizationBudgetModel } from "./models/OrganizationBudgetModel";
import { OrganizationBudgetDataService } from "./organization-budget-data.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "organization-budget",
    templateUrl: "./organization-budget.component.html",
    styleUrls: ["./organization-budget.component.scss"],
})
export class OrganizationBudgetComponent implements OnInit {
    gridConfig: CerrixGridEditorConfig;

    pageReady = false;

    private orgBudgetModels: OrganizationBudgetModel[] = [];

    constructor(
        private _ds: OrganizationBudgetDataService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        // This check makes sure the init only happens once.
        if (!this.pageReady) {
            await this.loadGrid();
        }
    }

    async loadGrid() {
        this.gridConfig = null;
        this.setupGrid();
    }

    public async setupGrid() {
        this.orgBudgetModels = await this.getData();

        // Use a copy, because the grid config has a bug where it will push all updates into the last item in the list
        // To bypass that, we use a copy and onRowChanged
        const orgBudgetCopy = this.createTree(JSON.parse(JSON.stringify(this.orgBudgetModels)));

        const columns: CerrixGridEditorColumnConfig[] = [];
        columns.push({
            prettyName: "Name",
            fieldName: "Name",
            fieldType: GenericListFieldType.Text,
            isReadonly: true,
        });
        columns.push({
            prettyName: "Budget",
            fieldName: "Budget",
            fieldType: GenericListFieldType.Number,
            isReadonly: false,
        });

        this.gridConfig = <CerrixGridEditorConfig<OrganizationBudgetModel>>{
            data: orgBudgetCopy,

            idProperty: nameof<OrganizationBudgetModel>((x) => x.Id),
            childProperty: nameof<OrganizationBudgetModel>((x) => x.Children),

            allowResizing: true,
            allowReordering: false,

            columns: columns,

            onRowChanged: (row) => {
                const item = this.orgBudgetModels.find((x) => x.Id === row.Id);
                item.Budget = row.Budget;
            },
        };

        this.pageReady = true;
    }

    private createTree(data: OrganizationBudgetModel[]): OrganizationBudgetModel[] {
        const tree = data.filter((x) => x.ParentId === null);
        tree.forEach((item) => {
            item.Children = this.getChildren(item.Id, data);
        });

        return tree;
    }

    private getChildren(parentId: number, data: OrganizationBudgetModel[]) {
        return data
            .filter((x) => x.ParentId === parentId)
            .sort((x) => x.SortOrder)
            .map((item) => {
                item.Children = this.getChildren(item.Id, data);

                return item;
            });
    }

    private async getData() {
        const data = await toPromise(this._ds.getOrgBudgets());
        return data;
    }

    public async save() {
        const result = await this._promptService
            .confirmCustom({
                maxWidth: "450px",
                maxHeight: "300px",
                data: {
                    message:
                        "Updating any organization budget will force all risk's financial impacts to be recalculated. \n \n Are you sure you want to continue?",
                    title: "Before save organizational budget",
                },
            })
            .toPromise();

        if (!result) {
            return;
        }

        await toPromise(this._ds.storeOrgBudgets(this.orgBudgetModels));
        this._toastr.success("Update completed");
    }
}
