import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef,
    ElementRef,
} from "@angular/core";
import { DashboardWidgetModel } from "../shared/models/DashboardWidgetModel";
import { IDashboardWidgetComponent } from "../shared/interfaces/IDashboardWidgetComponent";
import { PermissionsService } from "@services/permissions/PermissionsService";

declare var $: any;
@Component({
    selector: "dashboard-widget",
    templateUrl: "./dashboard-widget.component.html",
    styleUrls: ["./dashboard-widget.component.scss"],
})
export class DashboardWidgetComponent implements OnInit {
    @Input() config: DashboardWidgetModel;
    @Input() globalFilters: any;

    @ViewChild("widgetFrame", { static: true }) widgetFrame: ElementRef;
    @ViewChild("widgetContainer", { read: ViewContainerRef, static: true }) widgetContainer;
    widgetComponent: IDashboardWidgetComponent;

    initialRenderOccured = false;

    unauthorized = false;

    constructor(
        private _permissions: PermissionsService,
        private _componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        this.bindApi();
        this.initComponent();
        setTimeout(() => {
            this.checkIsInViewport();
        }, 500);
    }

    bindApi() {
        this.config.api.onDelete = this.onDestroy.bind(this);
    }

    initComponent() {
        if (this.widgetContainer) {
            this.widgetContainer.clear();
        }

        if (this.config.right) {
            this.unauthorized = !this._permissions.hasStringRight(this.config.right);
            if (this.unauthorized) {
                this.config.showLoader = false;
                return;
            }
        }

        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
            this.config.component
        );
        const componentRef = this.widgetContainer.createComponent(componentFactory);
        if (componentRef.instance) {
            this.widgetComponent = componentRef.instance;
            this.widgetComponent.config = this.config;
            this.widgetComponent.globalFilters = this.globalFilters;
        }
    }

    private checkIsInViewport() {
        if (this.initialRenderOccured) {
            return;
        }

        if (this.isElementInView(this.widgetFrame.nativeElement)) {
            this.triggerInitialLoad();
        } else if (!this.config.api.onGridScroll) {
            this.config.api.onGridScroll = this.checkIsInViewport.bind(this);
        }
    }

    private triggerInitialLoad() {
        this.initialRenderOccured = true;
        this.config.api.onGridScroll = null;
        if (this.widgetComponent != null) {
            this.widgetComponent.load();
        }
    }

    private isElementInView(element) {
        if (!element) {
            return false;
        }

        const grid = $($(element).closest("gridster")[0]);
        if (!grid) {
            return false;
        }

        const gridOffset = grid.offset();
        const gridHeight = grid.outerHeight();
        const gridWidth = grid.outerWidth();

        if (!gridOffset) {
            return false;
        }

        const viewportTop = gridOffset.top;
        const viewportBottom = gridOffset.top + gridHeight;
        const viewportLeft = gridOffset.left;
        const viewportRight = gridOffset.left + gridWidth;

        const elementOffset = $(element).offset();
        if (!elementOffset) {
            return false;
        }

        const inVerticalView =
            viewportTop <= elementOffset.top && viewportBottom >= elementOffset.top;

        const inHorizontalView =
            viewportLeft <= elementOffset.left && viewportRight >= elementOffset.left;

        return inVerticalView && inHorizontalView;
    }

    private onDestroy() {
        if (this.widgetContainer) {
            this.widgetContainer.clear();
        }

        return true;
    }
}
