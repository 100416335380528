import { FindingReportWidgetModel } from "./../shared/models/finding-report-widget-model";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { Observable, of } from "rxjs";
import {
    FindingReportWorkspaceModel,
    FindingReportDetailsModel,
    FindingReportStoreModel,
    FindingReportPermissionsModel,
} from "@app/findingreport/shared/models";
import { SelectOptionModel, SelectOptionColorModel } from "@models/generic";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { LinkedAssessmentModel } from "@app/assessment/shared/models";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { addParameterToUrl } from "@methods/CommonMethods";

@Injectable({
    providedIn: "root",
})
export class FindingReportDataService extends ApiDataService implements HistoryDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "findingreports");
    }

    public canViewFindings(): Observable<boolean> {
        return this.get<boolean>("/canviewfindings");
    }

    /**
     * Returns the general or specific permissions for finding report depending on specified finding report guid
     */
    public getPermissions(findingReportGuid?: string): Observable<FindingReportPermissionsModel> {
        return findingReportGuid
            ? this.get<FindingReportPermissionsModel>(`/${findingReportGuid}/permissions`)
            : this.get<FindingReportPermissionsModel>("/permissions");
    }

    public getFindingReports(): Observable<FindingReportWorkspaceModel[]> {
        return this.get<FindingReportWorkspaceModel[]>();
    }

    public getFindingReport(findingReportGuid: string): Observable<FindingReportDetailsModel> {
        const url = `/${findingReportGuid}`;
        return this.get<FindingReportDetailsModel>(url);
    }

    public addFindingReport(model: FindingReportStoreModel): Observable<FindingReportDetailsModel> {
        return this.postWithDocuments(model, model.documents);
    }

    public updateFindingReport(
        findingReportGuid: string,
        model: FindingReportStoreModel
    ): Observable<never> {
        return this.putWithDocuments(model, model.documents, `/${findingReportGuid}`);
    }

    public deleteFindingReport(findingReportGuid: string): Observable<never> {
        return this.delete(`/${findingReportGuid}`);
    }

    public getLinkedAssessments(findingReportGuid: string): Observable<LinkedAssessmentModel[]> {
        return this.get<LinkedAssessmentModel[]>(`/${findingReportGuid}/assessments`);
    }

    public getHyperlinks(id: string | number): Observable<HyperlinkModel[]> {
        const url = `/${id}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    //#region Standing data

    public getReportTypes(reportTypeId?: number): Observable<SelectOptionModel<number>[]> {
        let url = "/reporttypes";
        if (reportTypeId) {
            url += `?reportTypeId=${reportTypeId}`;
        }

        return this.get<SelectOptionModel<number>[]>(url);
    }

    public getOverallScores(
        selectedReportTypeId?: number
    ): Observable<SelectOptionColorModel<number>[]> {
        return selectedReportTypeId
            ? this.get<SelectOptionColorModel<number>[]>(
                  `/overallscores?selectedReportTypeId=${selectedReportTypeId}`
              )
            : of([]);
    }

    public getValidationTypes(): Observable<SelectOptionModel<number>[]> {
        return this.get<SelectOptionModel<number>[]>("/validationtypes");
    }

    public getResponsibleOrganizations(selectedId: number): Observable<CerrixTreeItem[]> {
        const url = addParameterToUrl("/responsibleorganizations", "selectedId", selectedId);

        return this.get<CerrixTreeItem[]>(url);
    }

    public getResponsibles(responsibleId?: number): Observable<SelectOptionModel<number>[]> {
        let url = "/responsibles";
        if (responsibleId) {
            url += `?responsibleId=${responsibleId}`;
        }

        return this.get<SelectOptionModel<number>[]>(url);
    }

    public getReviewerOrganizations(
        selectedId: number,
        selectedReportTypeId?: number
    ): Observable<CerrixTreeItem[]> {
        if (!selectedReportTypeId) {
            return of([]);
        }

        let url = `/reviewerorganizations`;
        url = addParameterToUrl(url, "selectedReportTypeId", selectedReportTypeId);
        url = addParameterToUrl(url, "selectedId", selectedId);

        return this.get<CerrixTreeItem[]>(url);
    }

    public getReviewers(reviewerId?: number): Observable<SelectOptionModel<number>[]> {
        let url = "/reviewers";
        if (reviewerId) {
            url += `?reviewerId=${reviewerId}`;
        }

        return this.get<SelectOptionModel<number>[]>(url);
    }

    //#region Standing data

    //#region History

    public getHistory(findingReportGuid: string): Observable<HistoryModel[]> {
        return this.get<HistoryModel[]>(`/${findingReportGuid}/history`);
    }

    public getHistoryChanges(
        findingReportGuid: string | number,
        historyDate: string | number
    ): Observable<HistoryChange[]> {
        return this.get<HistoryChange[]>(`/${findingReportGuid}/history/${historyDate}`);
    }

    //#region History

    //#region Widgets

    public getWidgetInfo(filters: any) {
        const url = `/widgetinfo`;
        return this.post<FindingReportWidgetModel[]>(filters, url);
    }

    //#endregion Widgets
}
