import { AssessmentStoreModel } from "./assessment-store-model";

export class AssessmentDetailsModel extends AssessmentStoreModel {
    guid: string = null;
    identifier: string = null;
    findingReportGuid: string = null;
    createdAt: Date = null;
    modifiedAt: Date = null;
    assessmentTypeName: string = null;
    overallScoreName: string = null;
    overallScoreColor: string = null;
    reviewerName: string = null;
}
