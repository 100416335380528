<div class="detail-page-action-bar flex-action-bar" *ngIf="!cerrixTab.showLoader">
    <button type="button" (click)="saveHyperlinks()" class="btn btn-cerrix" *ngIf="hyperlinksLoaded">
        <i class="fas fa-save"></i>
        <span class="d-none d-lg-inline">Save hyperlinks</span>
    </button>
</div>

<div class="detail-page-card" *ngIf="hyperlinksLoaded">
    <hyperlink-manager
        [(Hyperlinks)]="hyperlinks"
        (HyperlinksChanged)="checkDirty($event)"
        [TargetModule]="targetModule"
    ></hyperlink-manager>
</div>
