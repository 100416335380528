export enum StepStateEnum {
    Disabled = 0,
    Active = 1,
    Completed = 2,
}

export class WorkflowStepConfig {
    Id: number;
    Name: string;
    State: StepStateEnum;
    CompletedBy?: string;
    CompletedOn?: string;
    DueDate?: string;
    AssignedUsers: string[];
}
