import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { parseTyped, tryParseJson } from "@methods/CommonMethods";
import { FormField, FormFieldResult, FormFieldConfig, Form } from "@app/forms/models";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { TabService } from "@services/tabs/TabService";
import { of } from "rxjs";
import { MatrixCell } from "@models/generic/MatrixModel";
import { FormFieldWidgetType } from "@app/forms/enums/FormFieldWidgetTypes";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "form-results",
    templateUrl: "./form-results.component.html",
    styleUrls: ["./form-results.component.scss"],
})
export class FormResultsComponent implements OnInit {
    tabID: string;
    tab: TabModel;

    form: Form;
    data: Promise<any[]>;
    headerLookup: any = {};
    multiSeperator = ";";

    module = "";
    moduleType = ModuleType.FRM;

    filterConfigs: FilterConfig[] = [];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Date Updated",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [
        {
            text: "Delete",
            icon: "fas fa-trash-alt",
            rowRequired: true,
            clickEvent: (data) => {
                this.delete(data);
            },
            isDisabled: !this._permissions.permissions.Forms.IsAdministrator,
        },
        {
            text: "Bulk delete",
            icon: "fas fa-dumpster-fire",
            getVisibleRange: true,
            clickEvent: (data) => {
                this.delete(data);
            },
            isDisabled: !this._permissions.permissions.Forms.IsAdministrator,
        },
    ];
    numberProps: string[] = [];

    permissions: WorkspaceModulePermissionModel = new WorkspaceModulePermissionModel();

    resultsReady = false;

    constructor(
        private _formDS: FormsDataService,
        public tabService: TabService,
        public pages: Pages,
        private _permissions: PermissionsService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        if (!this.tab || !this.tab.id) {
            throw Error("Form ID is required!");
        }
        if (!this.tab.config) {
            this.tab.config = {};
        }
        this.tab.config.id = this.tab.id;

        this.data = this.getData();
    }

    async getData(): Promise<any[]> {
        this.form = await this._formDS.getResults(+this.tab.id).toPromise();
        const noResults = !this.form.Results || this.form.Results.length === 0;
        // Redirect to entry if user does have entry rights and does not have result in his/her workspace.
        if (
            this.form.CanStartEntry &&
            (noResults || (this.tab.config && this.tab.config.forceStartEntry))
        ) {
            this.tab.close(false);
            this.open();
            return;
        }

        if (!this.form) {
            this.refreshParentAndClose();
            return;
        }

        if (!this.form.CanStartEntry && noResults) {
            this._toastr.warning("No results are available to show.", "Cannot open overview");
            this.tab.close(false);
        }

        this.tab.name = "Results - " + this.form.Title;
        this.permissions.canAdd = this.form.CanStartEntry;
        this.permissions.canOpen = true;
        const formattedData = this.formatData(this.form);

        return of(formattedData).toPromise();
    }

    formatData(form: Form): any[] {
        // All fieldnames that are received, sorted on non deleted to deleted -> deleted checks are on page and field.
        const headers = ["ID", "Started By", "Date Updated", "Status"];
        this.headerLookup["ID"] = "ID";
        this.headerLookup["Started By"] = "Started By";
        this.headerLookup["Date Updated"] = "Date Updated";
        this.headerLookup["Status"] = "Status";

        const isWorkflow = form.Workflow;
        if (isWorkflow) {
            headers.push("CurrentUser");
            this.headerLookup["CurrentUser"] = "Current User";

            headers.push("WorkflowDefinitionName");
            this.headerLookup["WorkflowDefinitionName"] = "Workflow Definition";

            headers.push("Review Comment");
            this.headerLookup["Review Comment"] = "Review Comment";
        }

        // This will put all fields where the page is not deleted and the field is not deleted.
        form.Pages.forEach((page) => {
            if (!page.Deleted) {
                page.Fields.forEach((field) => {
                    if (!field.Deleted && field.FieldType !== FormFieldType.Info) {
                        const headerKey = " " + field.ID;
                        headers.push(headerKey);
                        this.headerLookup[headerKey] = field.WorkspaceLabel
                            ? field.WorkspaceLabel
                            : field.Name;
                        this.addRenderer(field, headerKey);
                    }
                });
            }
        });

        // This will put in all field names where the page is deleted or the field is deleted
        form.Pages.forEach((page) =>
            page.Fields.forEach((field) => {
                if ((page.Deleted || field.Deleted) && field.FieldType !== FormFieldType.Info) {
                    const headerKey = " " + field.ID;
                    headers.push(headerKey);
                    this.headerLookup[headerKey] =
                        "* " + (field.WorkspaceLabel ? field.WorkspaceLabel : field.Name);
                    this.addRenderer(field, headerKey);
                }
            })
        );

        const results = [];
        const allFields = form.Pages.map((x) => x.Fields).reduce(function (a, b) {
            return a.concat(b);
        });
        form.Results.forEach((res) => {
            const allFieldRes: FormFieldResult[] = [];
            res.PageResults.forEach((pr) => pr.FieldResults.forEach((fr) => allFieldRes.push(fr)));

            const row = {};
            headers.forEach((head) => {
                switch (head) {
                    case "ID":
                        row["ID"] = res.ResultID;
                        return;
                    case "Started By":
                        row["Started By"] = res.PageResults[0].UserName;
                        return;
                    case "Status":
                        row["Status"] = res.WorkflowStatus;
                        return;
                    case "CurrentUser":
                        row["CurrentUser"] = res.CurrentUser;
                        return;
                    case "WorkflowDefinitionName":
                        row["WorkflowDefinitionName"] = res.WorkflowDefinitionName;
                        return;
                    case "Date Updated":
                        row["Date Updated"] = res.DateUpdated;
                        return;
                    case "Review Comment":
                        row["Review Comment"] = res.ReviewComment ? res.ReviewComment : "-";
                        return;
                }

                const fieldRes = allFieldRes.filter((fr) => fr.FormFieldId === +head)[0];
                if (fieldRes) {
                    const extraCols = {};
                    const formField = allFields.find((f) => f.ID === fieldRes.FormFieldId);
                    row[head] = this.mapValue(fieldRes.Value, formField, extraCols);

                    const extraHeaders = Object.getOwnPropertyNames(extraCols);
                    extraHeaders.forEach((eh) => (row[eh] = extraCols[eh]));
                } else {
                    row[head] = "-";
                }
            });
            results.push(row);
        });

        return results;
    }

    mapValue(value: string, field: FormField, extraCols: {}): any {
        switch (field.FieldType) {
            case FormFieldType.Radio:
                return value;
            case FormFieldType.Checkbox:
            case FormFieldType.Dropdown:
                if (field) {
                    const customSettings = parseTyped<FormFieldConfig>(field.CustomSettings, null);
                    if (customSettings) {
                        const options = customSettings.options;
                        if (value) {
                            const answers = value.split(this.multiSeperator);

                            answers.sort(function (a, b) {
                                return options.indexOf(a) - options.indexOf(b);
                            });
                            value = answers.join(this.multiSeperator);
                            return value;
                        }
                    }
                }
                return "-";
            case FormFieldType.FileUpload:
                let count = 0;
                if (value) {
                    const documents = tryParseJson(value) as object[];
                    if (documents) {
                        count = documents.length;
                    }
                }
                return count + " document(s)";
            case FormFieldType.Structure:
                const pairs = parseTyped<KeyValue<number, string>[]>(value, []);
                const values = pairs.map((x) => x.value).join(" | ");

                return values ? values : "-";
            case FormFieldType.Date:
                return value ? new Date(value) : null;
            case FormFieldType.Widget:
                if (field) {
                    const widgetConfig = parseTyped<FormFieldConfig>(
                        field.CustomSettings,
                        new FormFieldConfig()
                    );
                    if (widgetConfig.widgetType === FormFieldWidgetType.RiskMatrix) {
                        const answer = parseTyped<MatrixCell>(value, new MatrixCell());

                        const colorKey = " " + field.ID + "Color";
                        this.rendererConfig.push({
                            textColumn: " " + field.ID,
                            actionColumn: colorKey,
                            hideActionColumn: true,
                            type: RendererType.Score,
                        });

                        extraCols[colorKey] = answer.Color;
                        return `Likelihood: ${answer.Likelihood + 1} | Impact: ${
                            answer.Impact + 1
                        }`;
                    }
                }
                return "-";
        }

        return value && value.length > 0 ? value : "-";
    }

    open(entry?: any) {
        if (this.form.CanViewResults) {
            let entryID: number = null;
            if (entry && entry.ID && !isNaN(entry.ID)) {
                entryID = +entry.ID;
            }

            const tab = this.tabService.getTab(this.pages.FormEntry, entryID);
            tab.name = `(FRM) Entry - ${+this.form.Title}`;
            tab.config = { formid: this.form.ID };
            this.tabService.addTab(tab);
        }
    }

    addRenderer(field: FormField, fieldKey: string) {
        if (field.FieldType === FormFieldType.Date) {
            // Later we can check here for DateFormat or DateTimeFormat.

            this.rendererConfig.push({
                textColumn: fieldKey,
                type: RendererType.Default,
                formatType: FormatType.DateFormat,
            });
        }
    }

    delete(data: number | string | number[] | string[]) {
        let ids: number[];

        if (!data) {
            return;
        }

        if (Array.isArray(data)) {
            ids = data as number[];
        } else {
            ids = [data as number];
        }

        if (ids.length === 0) {
            return;
        }

        this._promptService
            .confirm(
                "Delete result(s)",
                `Are you sure you want to permanently delete ${ids.length} item${
                    ids.length > 1 ? "s" : ""
                }. This action cannot be undone!`
            )
            .onConfirm()
            .subscribe(() => {
                this._formDS.deleteResults(ids).subscribe((x) => {
                    this.tab.refresh();
                });
            });
    }

    refreshParentAndClose() {
        if (this.tab.parent.lookupname === this.pages.Forms) {
            this.tab.parent.refresh();
        }

        this.tab.close(false);
    }
}
