import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormFieldWidgetType } from "@app/forms/enums/FormFieldWidgetTypes";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { parseTyped } from "@methods/CommonMethods";
import { MatrixModel } from "@models/generic/MatrixModel";

@Component({
    selector: "form-entry-widget",
    templateUrl: "./form-entry-widget.component.html"
})
export class FormEntryWidgetComponent implements OnInit {
    @Input() field: FormField;
    @Input() answer: string;
    @Input() readonly: boolean;
    @Output() answerChange: EventEmitter<string> = new EventEmitter<string>();

    widgetTypes = FormFieldWidgetType;
    widgetSettings: FormFieldConfig;

    riskMatrixAnswer: MatrixModel;

    ngOnInit() {
        this.widgetSettings = parseTyped<FormFieldConfig>(this.field.CustomSettings, new FormFieldConfig());

        if (this.widgetSettings.widgetType === FormFieldWidgetType.RiskMatrix) {
            this.riskMatrixAnswer = parseTyped<MatrixModel>(this.answer, new MatrixModel());
        }
    }

    emitChange() {
        if (this.widgetSettings.widgetType === FormFieldWidgetType.RiskMatrix) {
            this.answer = JSON.stringify(this.riskMatrixAnswer);
        }

        this.answerChange.emit(this.answer);
    }
}
