<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<cerrix-invite [inviteConfig]="inviteConfig" *ngIf="inviteConfig">
    <ng-template #userTemplate let-user>
        <div class="card" [class.bg-primary]="inviteConfig.userSelection.indexOf(user.ID) >= 0">
            <div class="card-body">
                <h6 class="card-title">{{user.displayName}}</h6>
                <p class="card-text">{{user.mail}}</p>
                <p class="card-text">{{user.organizationName}}</p>
                <p class="card-text">
                    <small class="text-muted">{{user.invitationStatus}}</small>
                </p>
            </div>
        </div>
    </ng-template>
</cerrix-invite>