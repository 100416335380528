import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AssessmentDataService } from "@app/assessment/services";
import { AssessmentDetailsModel, AssessmentStoreModel } from "@app/assessment/shared/models";
import { getFormControl } from "@methods/CommonMethods";
import { SelectOptionColorModel, SelectOptionModel } from "@models/generic";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { tap } from "rxjs/operators";

@Component({
    selector: "assessment-details-main",
    templateUrl: "./assessment-details-main.component.html",
    styleUrls: ["./assessment-details-main.component.scss"],
})
export class AssessmentDetailsMainComponent implements OnInit {
    @Input() detailsModel: AssessmentDetailsModel;
    @Input() formGroup: FormGroup;
    @Input() editAllowed: boolean;

    get finishedLoading(): boolean {
        // Need to wait for select tree component data because it doesn't work without...
        return !!this.detailsModel && !!this.formGroup && !!this.reviewerOrganizations;
    }

    assessmentTypes: SelectOptionModel<number>[];
    overallScores: SelectOptionColorModel<number>[];
    reviewerOrganizations: CerrixTreeItem[];
    reviewers: SelectOptionModel<number>[];

    assessmentTypesLoading = true;
    overallScoresLoading = true;
    reviewersLoading = true;
    dateToday = new Date();

    constructor(private assessmentService: AssessmentDataService) {}

    ngOnInit() {
        this.loadStandingData();
    }

    getAssessmentTypeName(assessmentTypeId: number): string {
        const type = this.assessmentTypes.find((t) => t.value === assessmentTypeId);
        return type ? type.label : null;
    }

    //#region Standing data

    loadStandingData() {
        if (this.detailsModel.guid) {
            this.loadAssessmentTypes();
            this.loadOverallScores(this.detailsModel.assessmentTypeId);
            this.loadReviewerOrganizations();
            this.loadReviewers(this.detailsModel.reviewerId);
        } else {
            this.loadAssessmentTypes();
            this.loadOverallScores();
            this.loadReviewerOrganizations();
            this.loadReviewers();
        }
    }

    private loadAssessmentTypes() {
        this.assessmentTypesLoading = true;
        this.assessmentService
            .getAssessmentTypes()
            .pipe(tap(() => (this.assessmentTypesLoading = false)))
            .subscribe((r) => {
                this.assessmentTypes = r;
            });
    }

    private loadOverallScores(selectedAssessmentTypeId?: number, assessmentTypeChanged = false) {
        const control = getFormControl<AssessmentStoreModel>(this.formGroup, "overallScoreId");
        if (!selectedAssessmentTypeId) {
            control.disable();
            control.setValue(null);
        } else if (this.editAllowed) {
            control.enable();
            if (assessmentTypeChanged) {
                control.setValue(null);
            }
        }

        this.overallScoresLoading = true;
        this.assessmentService
            .getOverallScores(selectedAssessmentTypeId)
            .pipe(tap(() => (this.overallScoresLoading = false)))
            .subscribe((r) => {
                this.overallScores = r;
            });
    }

    private loadReviewerOrganizations() {
        this.assessmentService.getReviewerOrganizations().subscribe((organizations) => {
            this.reviewerOrganizations = organizations;
        });
    }

    private loadReviewers(reviewerId?: number) {
        this.reviewersLoading = true;
        this.assessmentService
            .getReviewers(reviewerId)
            .pipe(tap(() => (this.reviewersLoading = false)))
            .subscribe((r) => {
                this.reviewers = r;
            });
    }

    //#endregion Standing data

    //#region Events

    onAssessmentTypeChange(assessmentTypeModel: SelectOptionModel<number>) {
        const selectedAssessmentTypeId = assessmentTypeModel ? assessmentTypeModel.value : null;

        this.loadOverallScores(selectedAssessmentTypeId, true);
    }

    //#endregion Events
}
