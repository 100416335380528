<div class="detail-page-card" *ngIf="moduleRoles">
    <div class="detail-page-card-title">Roles</div>
    <div class="detail-page-card-actions">
        <input
            type="text"
            class="form-control form-control-sm role-search"
            placeholder="Quick search / filter roles"
            [(ngModel)]="filterText"
            (debounce-input-event)="filter()"
            inputTooltip
        />
        <small class="text-muted search-helper"
            >Start search with '<b>=</b>' to perform a sentence search.</small
        >
    </div>

    <ngx-masonry [options]="{ percentPosition: true, gutter: 10 }">
        <ng-container *ngFor="let module of moduleRoles">
            <div ngxMasonryItem class="masonry-item" *ngIf="!module.hidden">
                <div class="card">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item list-group-header">{{ module.moduleName }}</li>
                        <ng-container *ngFor="let role of module.roles">
                            <li
                                class="list-group-item d-flex role-row"
                                (click)="roleClicked(role)"
                                *ngIf="!role.hidden"
                            >
                                <div class="role-checkbox">
                                    <i
                                        class="{{
                                            role.checked || role.disabled
                                                ? 'fas fa-check-square'
                                                : 'far fa-square'
                                        }}"
                                    >
                                    </i>
                                </div>
                                <div class="role-description">
                                    <div>{{ role.roleName }}</div>
                                    <small class="text-muted">{{ role.roleDescription }}</small>
                                </div>
                                <div
                                    class="right-badge"
                                    (click)="openRightOverview(role); $event.stopPropagation()"
                                >
                                    <span
                                        class="badge bg-warning py-1"
                                        matTooltip="Click to see a detailed view."
                                    >
                                        {{ role.rights.length }} right{{
                                            role.rights.length === 1 ? "" : "s"
                                        }}
                                    </span>
                                </div>
                                <div class="role-group-inherit-message" *ngIf="role.inheritedFrom">
                                    <div class="inherited-title">
                                        '{{ role.roleName }}' is inherited from:
                                    </div>
                                    <small class="inherited-roles">{{ role.inheritedFrom }}</small>
                                </div>
                            </li>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                module.module == moduleTypes.IM &&
                                userModel &&
                                (allowChange || userModel.supervisorId)
                            "
                        >
                            <li class="list-group-item">
                                <div class="role-description">
                                    <div>Supervisor</div>
                                    <small class="text-muted"
                                        >The assigned supervisor can see all MoIs of this
                                        user.</small
                                    >
                                </div>
                                <div class="supervisor-select">
                                    <ng-select
                                        [items]="imSupervisors | async"
                                        [(ngModel)]="userModel.supervisorId"
                                        bindValue="ID"
                                        bindLabel="Name"
                                        [loading]="imSupervisorsLoading"
                                        [disabled]="!allowChange"
                                        (change)="imSupervisorChanged($event)"
                                    >
                                    </ng-select>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </ng-container>
    </ngx-masonry>
</div>

<right-description #rightDescription></right-description>
