<div class="fancy-loader" *ngIf="isLoading"></div>
<event-linkable
    *ngIf="!isLoading"
    #linkable
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="[]"
    [hideActions]="true"
    [orderByValueProperty]="true"
    [disabled]="true"
>
</event-linkable>
