import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditDocumentRequestDataService } from "@app/audit/services/audit-document-request-data.service";
import { AuditDocumentRequestModel } from "@app/audit/shared/data-models/audit-document-request-model";
import { AuditDocumentRequestPermissionModel } from "@app/audit/shared/data-models/audit-document-request-permission-model";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditDocumentRequestStatusType } from "@app/audit/shared/enums/AuditDocumentRequestStatusType";
import { CerrixSelectComponent } from "@app/shared/cerrix-select/cerrix-select.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { toPromise } from "@methods/CommonMethods";
import { getFormControl, nameof, setFormControlValue } from "@methods/jeffs-toolkit";
import { guid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { ToastrService } from "ngx-toastr";
import { AuditDetailPermissionModel } from "./../../../../shared/permission-models/audit-detail-permission-model";
import _ from "underscore";
import { map, of } from "rxjs";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "audit-detail-auditee-requests",
    templateUrl: "./audit-detail-auditee-requests.component.html",
    styleUrls: ["./audit-detail-auditee-requests.component.scss"],
})
export class AuditDetailAuditeeRequestsComponent {
    @Input() formGroup: FormGroup;
    @Input() permissions: AuditDetailPermissionModel;
    @Input() auditModel: AuditModel;
    @Input() cerrixTab: TabModel;

    @ViewChild("auditeeSelect") auditeeSelect: CerrixSelectComponent;
    @ViewChild("delegateSelect") delegateSelect: CerrixSelectComponent;

    public selectedIndex: number;
    requests: AuditDocumentRequestModel[];

    selectedFormGroup: FormGroup;
    selected: AuditDocumentRequestModel;

    AuditDocumentRequestStatusType = AuditDocumentRequestStatusType;

    public auditeeRequest = of([]);

    get requestsFormArray(): FormArray {
        return getFormControl<AuditModel>(this.formGroup, (x) => x.documentRequests) as FormArray;
    }

    constructor(
        private _promptService: CerrixPromptService,
        private _documentRequestsDs: AuditDocumentRequestDataService,
        private _formBuilder: FormBuilder,
        private _toastr: ToastrService,
        private _changeDetector: ChangeDetectorRef
    ) {}

    async init(): Promise<void> {
        if (this.auditModel.id <= 0) {
            return;
        }

        if (this.requests == null || this.requests.length === 0) {
            const requests = await toPromise(
                this._documentRequestsDs.getDocumentRequests(this.auditModel.guid)
            );
            this.initFormArray(requests);

            this.requests = requests;

            if (this.cerrixTab.config && this.cerrixTab.config.selectedRequestId != 0) {
                this.openById(this.cerrixTab.config.selectedRequestId);
            }
        } else {
            // Force user reload when switching menu items
            this.reloadUsers();
        }
    }

    addRequest(): void {
        if (!this.permissions.canCreateDocumentRequests) {
            return;
        }

        const newRequest = <AuditDocumentRequestModel>{
            id: -1 * (this.requests.length + 1),
            guid: guid(),
            name: "",
            description: "",
            auditeeId: null,
            dueDate: null,
            auditeeComment: "",

            auditeeDelegates: [],
            auditeeCompleted: false,
            Documents: [],

            permissions: <AuditDocumentRequestPermissionModel>{
                canEditAuditorPart: true,
                canEditAuditeePart: false,
            },
        };

        const newForm = this.initializeFormValidation(newRequest);
        this.requestsFormArray.insert(0, newForm);
        this.requestsFormArray.markAllAsTouched();

        this.requests.push(newRequest);
        this.open(newForm, 0);
        this.selectedIndex = 0;
    }

    open(selectedFormGroup: FormGroup, selectedIndex: number): void {
        const idProp = nameof<AuditDocumentRequestModel>((x) => x.id);
        if (this.selected && selectedFormGroup.controls[idProp].value == this.selected.id) {
            return;
        }

        const selectedRequest = this.requests.find(
            (x) => x[idProp] == selectedFormGroup.controls[idProp].value
        );

        this.selected = selectedRequest;
        this.selectedFormGroup = selectedFormGroup;

        this.selectedIndex = selectedIndex;

        let indexAfterSave = selectedIndex;
        if (selectedFormGroup.value.id < 0) {
            // New created items get a negative 1-based index. Have to lower it by 1 to make is 0-based. Since it's negative, we add instead of subtract
            indexAfterSave = Math.abs(selectedFormGroup.value.id + 1);
        } else {
            // New items are added to the top. If you select another item after creating new ones, we have to do some shenanigans
            let allItems = this.formGroup.controls.documentRequests["controls"];
            let newItems = allItems.filter((fg: FormGroup) => fg.value.id < 0);
            indexAfterSave = selectedIndex - newItems.length;
        }

        this.cerrixTab.config.selectedRequestId = selectedFormGroup.value.id;

        // Make sure the change of the formgroup is detected
        this._changeDetector.detectChanges();
        this.setFormGroupControlsState();

        this.reloadUsers();
    }

    openById(selectedId: number): void {
        let usedId = selectedId;
        let allItems = this.formGroup.controls.documentRequests["controls"];

        if (selectedId < 0) {
            let highestId = 0;
            allItems.forEach((item: FormGroup) => {
                if (item.value.id > highestId) {
                    highestId = item.value.id;
                }
            });

            usedId = highestId;
        }

        let selectedIndex = 0;
        let selectedFormGroup: FormGroup = undefined;
        allItems.forEach((item: FormGroup, index) => {
            if (item.value.id == usedId) {
                selectedFormGroup = item;
                selectedIndex = index;
            }
        });

        if (selectedFormGroup == undefined) {
            return;
        }

        this.selectedIndex = selectedIndex;
        this.open(selectedFormGroup, this.selectedIndex);
    }

    async deleteRequest(): Promise<void> {
        if (!this.selected || !this.permissions.canDeleteDocumentRequests) {
            return;
        }

        if (
            await this._promptService
                .confirm(
                    "Delete document request",
                    "The document request including its documents will be deleted, are you sure?"
                )
                .toPromise()
        ) {
            if (this.selected.id > 0) {
                this._documentRequestsDs
                    .deleteRequest(this.auditModel.guid, this.selected.guid)
                    .subscribe(() => {
                        this.removeSelectedFromList();
                    });
            } else {
                this.removeSelectedFromList();
            }

            this._toastr.success("Request has been deleted.");
        }
    }

    async completeRequest(): Promise<void> {
        if (
            !this.selected ||
            this.selected.id <= 0 ||
            this.selected.auditeeCompleted ||
            !this.permissions.canCompleteDocumentRequests
        ) {
            return;
        }

        if (this.selectedFormGroup && this.selectedFormGroup.dirty) {
            this._promptService.confirmCustom({
                data: {
                    promptUiid: "unsaved-changes-prompt",
                    title: "Unsaved changes",
                    message: "There are unsaved changes, please save these changes first.",
                    confirmButton: {
                        text: "Ok",
                    },
                    cancelButton: {
                        show: false,
                    },
                },
            });

            return;
        }

        if (
            await this._promptService
                .confirm(
                    "Complete document request",
                    "The document request will be completed and be no longer editable, are you sure?"
                )
                .toPromise()
        ) {
            this._documentRequestsDs
                .completeRequest(this.auditModel.guid, this.selected.guid)
                .subscribe((newData) => {
                    this.selected.auditeeCompleted = newData.auditeeCompleted;
                    this.selected.auditeeDateCompleted = newData.auditeeDateCompleted;
                    this.selected.status = newData.status;
                    this.selected.permissions = newData.permissions;

                    setFormControlValue<AuditDocumentRequestModel>(
                        this.selectedFormGroup,
                        (x) => x.auditeeCompleted,
                        newData.auditeeCompleted
                    );

                    this.setFormGroupControlsState();
                    this._toastr.success("Request has been completed.");
                });
        }
    }

    async requestDocuments(): Promise<void> {
        this._documentRequestsDs
            .requestDocuments(this.auditModel.guid, this.selected.guid)
            .subscribe((newData) => {
                this.selected.requester = newData.requester;
                this.selected.requestedDate = newData.requestedDate;
                this.selected.auditeeCompleted = newData.auditeeCompleted;
                this.selected.auditeeDateCompleted = newData.auditeeDateCompleted;
                this.selected.completedBy = newData.completedBy;
                this.selected.status = newData.status;
                this.selected.permissions = newData.permissions;

                setFormControlValue<AuditDocumentRequestModel>(
                    this.selectedFormGroup,
                    (x) => x.auditeeCompleted,
                    newData.auditeeCompleted
                );

                this.setFormGroupControlsState();
                this._toastr.success("Documents have been requested");
            });
    }

    onDocumentsChange(documents: DocumentModel[]): void {
        const documentsControl = getFormControl<AuditDocumentRequestModel>(
            this.selectedFormGroup,
            (x) => x.Documents
        );

        documentsControl.setValue(documents);
        documentsControl.markAsDirty();
        documentsControl.markAsTouched();
        documentsControl.updateValueAndValidity();
    }

    private initFormArray(requests: AuditDocumentRequestModel[]): void {
        const initialRequestOrder = requests.sortBy("name", true);
        for (const request of initialRequestOrder) {
            const requestFormGroup = this.initializeFormValidation(request);
            this.requestsFormArray.push(requestFormGroup);
        }
    }

    private initializeFormValidation(request: AuditDocumentRequestModel): FormGroup {
        const formGroup = this._formBuilder.group({
            id: new FormControl(request.id),
            guid: new FormControl(request.guid),

            name: new FormControl(request.name, Validators.required),
            description: new FormControl(request.description),
            auditeeId: new FormControl(request.auditeeId, Validators.required),
            dueDate: new FormControl(request.dueDate, Validators.required),
            auditeeDelegates: new FormControl(request.auditeeDelegates),
            auditeeComment: new FormControl(request.auditeeComment),
            auditeeCompleted: new FormControl(request.auditeeCompleted),
            Documents: new FormControl(request.Documents),
        });

        formGroup.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(formGroup, request);
        });

        return formGroup;
    }

    private removeSelectedFromList(): void {
        const idProp = nameof<AuditDocumentRequestModel>((x) => x.id);
        const index = this.requestsFormArray.controls.findIndex(
            (control) => control.get(idProp).value == this.selected.id
        );
        this.requestsFormArray.removeAt(index);
        this.selected = null;
        this.selectedIndex = null;
    }

    private setFormGroupControlsState(): void {
        // Do not use selectedFormGroup.disable(), it will also disable the ID form control and lose it's value, resulting in a new record on save
        if (this.selected.auditeeCompleted || !this.permissions.canEdit) {
            FormValidationHelper.disableFormGroupExcept<AuditDocumentRequestModel>(
                this.selectedFormGroup,
                "id"
            );

            return;
        }

        const auditorNameControl = getFormControl<AuditDocumentRequestModel>(
            this.selectedFormGroup,
            (x) => x.name
        );
        const auditorDescriptionControl = getFormControl<AuditDocumentRequestModel>(
            this.selectedFormGroup,
            (x) => x.description
        );

        const auditorAuditeeControl = getFormControl<AuditDocumentRequestModel>(
            this.selectedFormGroup,
            (x) => x.auditeeId
        );

        const auditorDueDateControl = getFormControl<AuditDocumentRequestModel>(
            this.selectedFormGroup,
            (x) => x.dueDate
        );

        const auditeeCommentControl = getFormControl<AuditDocumentRequestModel>(
            this.selectedFormGroup,
            (x) => x.auditeeComment
        );

        if (this.selected.id > 0 && !this.selected.permissions.canEditAuditorPart) {
            auditorNameControl.disable();
            auditorDescriptionControl.disable();
            auditorAuditeeControl.disable();
            auditorDueDateControl.disable();
        } else {
            auditorNameControl.enable();
            auditorDescriptionControl.enable();
            auditorAuditeeControl.enable();
            auditorDueDateControl.enable();
        }

        if (this.selected.id == 0 || !this.selected.permissions.canEditAuditeePart) {
            auditeeCommentControl.disable();
        } else {
            auditeeCommentControl.enable();
        }
    }

    private reloadUsers(): void {
        const selectedAuditeeId = this.selected ? this.selected.auditeeId : null;

        // Create new array to prevent mutation of the original array
        const selectedAuditees = [...(this.auditModel.auditees || [])];
        if (selectedAuditeeId) {
            selectedAuditees.push(selectedAuditeeId);
        }

        const selectableAuditeesDataMethod = this._documentRequestsDs
            .getAuditees(selectedAuditeeId)
            .pipe(
                map((auditees) =>
                    auditees.filter((auditee) => selectedAuditees.includes(auditee.ID))
                )
            );

        this.auditeeSelect.getDataMethod = selectableAuditeesDataMethod;
        this.auditeeSelect.reloadData();
    }
}
