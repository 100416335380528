import { guid } from "@methods/uniqueMethods";

export class BaseFileModel {
    ID: number;
    Guid: string;
    Name: string;
    Path: string;

    UserID: number;
    Username: string;
    UserGuid: string;

    Date: Date;
    Version: number;

    Added: boolean;
    Changed: boolean;
    Deleted: boolean;

    CanDelete: boolean;
    CanEdit: boolean;

    // Used on frontend
    IsNew: boolean;
    FileIcon: string;

    ExtraFields?: any;
    IsSelected?: boolean;

    FileType: FileType;
    IsCut: boolean;

    constructor() {
        this.Guid = guid();
        this.Date = new Date();
        this.IsNew = true;
        this.CanEdit = true;
        this.CanDelete = true;
        this.Version = 0;
    }

    updateModel(json: string) {
        Object.assign(this, JSON.parse(json));
        this.IsNew = false;
    }
}

export enum FileType {
    Folder,
    Document,
}
