import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";

@Component({
    selector: "cerrix-animated-illustration",
    template: `<ng-lottie [options]="options"></ng-lottie>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CerrixAnimatedIllustrationComponent {
    options: AnimationOptions = {
        path: "/assets/img/cerrix-main-illustration.json",
    };
}
