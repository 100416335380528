import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { CustomFieldBase } from "../custom-field-base";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { nameof } from "@methods/jeffs-toolkit";
import { Validators, FormControl, AbstractControl, FormGroup } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ModuleType } from "@enums/ModuleType";
import {
    CustomFieldStandingDataModel,
    CustomFieldOptionModel,
} from "@models/customfields/CustomFieldStandingDataModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { CustomFieldType } from "@enums/customfields/CustomFieldType";
import { StandingDataField } from "@app/shared/models/GenericList/GenericStandingDataConfig";

@Component({
    selector: "custom-field-editor",
    templateUrl: "./custom-field-editor.component.html",
    styleUrls: ["./custom-field-editor.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CustomFieldEditor extends CustomFieldBase implements OnInit {
    @Input() field: StandingDataField;

    mainFormGroup: FormGroup;
    listConfig: GenericListConfig;
    fieldTypes: IdNameCombination[];

    public get showEditor(): boolean {
        return (
            this.listConfig &&
            this.row &&
            [CustomFieldType.SingleSelect, CustomFieldType.MultiSelect].indexOf(
                this.row.fieldType
            ) > -1
        );
    }

    private get row(): CustomFieldStandingDataModel {
        return <CustomFieldStandingDataModel>this.value;
    }

    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.value || !this.value.ID) {
            this.value = <CustomFieldStandingDataModel>{
                visible: false,
                required: false,
                fieldTypeChangeAllowed: true,
            };
        }

        this.fieldTypes = this.getCustomFieldTypes();
        this.setupFields();
    }

    private setupFields(): void {
        this.mainFormGroup = new FormGroup(this.getFormGroupControls());

        this.mainFormGroup.valueChanges.subscribe((x) => {
            FormValidationHelper.mapToModel(this.mainFormGroup, this.value);

            this.valueChange.emit(this.value);
        });

        if (!this.row.answerOptions) {
            this.row.answerOptions = [];
        }

        this.listConfig = <GenericListConfig>{
            name: "Answer options",

            isSortable: true,
            allowAdd: true,
            allowEdit: true,
            allowDelete: true,
            idProp: nameof<CustomFieldOptionModel>((x) => x.ID),
            sortProp: nameof<CustomFieldOptionModel>((x) => x.Sort_Order),
            data: this.row.answerOptions,

            fields: [
                {
                    fieldName: nameof<CustomFieldOptionModel>((x) => x.Name),
                    fieldType: GenericListFieldType.Text,
                    required: true,
                    unique: true,
                },
                {
                    fieldName: nameof<CustomFieldOptionModel>((x) => x.Value),
                    fieldType: GenericListFieldType.Number,
                },
                {
                    fieldName: nameof<CustomFieldOptionModel>((x) => x.Color),
                    fieldType: GenericListFieldType.ColorPicker,
                },
            ],

            dataChanged: (data: CustomFieldOptionModel[]) => {
                this.row.answerOptions = data;
            },
        };

        this.field.customFieldValidation = () => {
            FormValidationHelper.markAllAsTouched(this.mainFormGroup);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.mainFormGroup);
            const validationMessages = FormValidationHelper.getValidationMessages(
                validationErrors,
                []
            );

            if (
                this.showEditor &&
                (!this.row.answerOptions || this.row.answerOptions.length === 0)
            ) {
                validationMessages.push(
                    "At least one answer option is required for a select field."
                );
            }

            // Remove styling since it messes with toastr in standing-data-editor.component
            return validationMessages.distinct().map((msg) => {
                return (msg = msg.replace("<br> -", ""));
            });
        };
    }

    private getFormGroupControls(): { [p: string]: AbstractControl } {
        const formGroupControls: { [p: string]: AbstractControl } = {};

        formGroupControls[nameof<CustomFieldStandingDataModel>((c) => c.Name)] = new FormControl(
            this.row.Name,
            [Validators.required]
        );

        formGroupControls[nameof<CustomFieldStandingDataModel>((c) => c.required)] =
            new FormControl(this.row.required);

        formGroupControls[nameof<CustomFieldStandingDataModel>((c) => c.visible)] = new FormControl(
            this.row.visible
        );

        formGroupControls[nameof<CustomFieldStandingDataModel>((c) => c.fieldType)] =
            new FormControl(
                { value: this.row.fieldType, disabled: !this.row.fieldTypeChangeAllowed },
                [Validators.required]
            );

        return formGroupControls;
    }

    private getCustomFieldTypes(): IdNameCombination[] {
        const customFieldTypes: IdNameCombination[] = [];
        for (const enumMember in CustomFieldType) {
            if (parseInt(enumMember, 10) > 0) {
                // Skip default option
                customFieldTypes.push(<IdNameCombination>{
                    ID: +enumMember,
                    Name: CustomFieldType[CustomFieldType[enumMember] + "Description"],
                });
            }
        }

        return customFieldTypes;
    }
}
