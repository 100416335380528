<div class="modal-header">
    <h5 class="modal-title">Page Settings</h5>
</div>
<div class="container-fluid">
    <mat-accordion
        class="sortable-list"
        [sortablejs]="pages"
        [sortablejsOptions]="sortingOptions"
        *ngIf="pages"
    >
        <mat-expansion-panel *ngFor="let page of pages; let pageIndex = index" [id]="page.ID">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ page.Title }}
                    <span class="alert" *ngIf="page.ID == lockedFirstPageID && pageIndex != 0"
                        >This page must remain page 1!</span
                    >
                </mat-panel-title>
                <mat-panel-description>
                    {{ page.Type == 1 ? "Entry Page" : "Review Page" }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-group">
                <label for="form-title">Title</label>
                <cerrix-textfield
                    cssClass="form-control"
                    [id]="'form-title-' + page.ID"
                    [(ngModel)]="page.Title"
                    placeholder="Enter Title"
                ></cerrix-textfield>
            </div>
            <div class="form-group">
                <label for="form-description">Tooltip</label>
                <cerrix-textfield
                    cssClass="form-control"
                    [id]="'form-description-' + page.ID"
                    [(ngModel)]="page.Tooltip"
                    placeholder="Enter Tooltip"
                ></cerrix-textfield>
            </div>
            <div class="form-group" *ngIf="isWorkflow">
                <label for="form-category">Page type</label>
                <select
                    class="custom-select"
                    id="form-category"
                    [(ngModel)]="page.Type"
                    [disabled]="page.ID > 0"
                >
                    <option value="1" selected>Entry page</option>
                    <option value="2">Review page</option>
                </select>
            </div>

            <mat-action-row>
                <button class="btn btn-dark align-right" (click)="duplicate(pageIndex)">
                    Duplicate
                </button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSaveChanges()">Apply changes</button>
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
</div>
