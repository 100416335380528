<div menuSystem [cerrixTab]="tab" class="mailmessage-detail">
    <div [attr.name]="header" menuicon="fas fa-mail-bulk" menusystemsubmenu>
        <div
            name="Mailmessages"
            class="mailmessage-screens"
            menuicon="fas fa-envelope"
            menusystemsubmenu
        >
            <div
                name="Application"
                menuicon="fas fa-unlock-alt"
                menuItemId="application-mail"
                menusystemitem
                *ngIf="permService.permissions.IsApplicationAdmin"
            >
                <uc-mailmessages-overview [module]="moduleTypes.COMMON"></uc-mailmessages-overview>
            </div>
            <div
                name="Business Improvement"
                menuicon="fas fa-tasks"
                menuItemId="bi-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsBimMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.BIM"></uc-mailmessages-overview>
            </div>
            <div
                name="Control"
                menuicon="fas fa-shield-alt"
                menuItemId="control-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsControlMailAdministrator"
            >
                <uc-mailmessages-overview
                    [module]="moduleTypes.Controls"
                ></uc-mailmessages-overview>
            </div>
            <div
                name="Event"
                menuicon="fas fa-bolt"
                menuItemId="event-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsEventMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.LER"></uc-mailmessages-overview>
            </div>
            <div
                name="Forms"
                menuicon="fas fa-file-alt"
                menuItemId="form-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsFormMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.FRM"></uc-mailmessages-overview>
            </div>
            <div
                name="Invite"
                menuicon="fas fa-paper-plane"
                menuItemId="invite-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsInviteMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.CI"></uc-mailmessages-overview>
            </div>
            <div
                name="Navigators"
                menuicon="fad fa-sitemap"
                menuItemId="navigator-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsNavigatorMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.NAV"></uc-mailmessages-overview>
            </div>
            <div
                name="Audit"
                menuicon="fal fa-clipboard-list-check"
                menuItemId="audit-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsAuditMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.AUDIT"></uc-mailmessages-overview>
            </div>
            <div
                name="Tasks"
                menuicon="fad fa-list-ol"
                menuItemId="tasks-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsTaskMailAdministrator"
            >
                <uc-mailmessages-overview [module]="moduleTypes.Tasks"></uc-mailmessages-overview>
            </div>
        </div>
        <div name="Notifications" menuicon="fad fa-bells" menusystemsubmenu>
            <div
                name="Application"
                menuicon="fas fa-unlock-alt"
                menuItemId="application-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsApplicationMailAdministrator"
            >
                <uc-notification-overview [module]="moduleTypes.COMMON"></uc-notification-overview>
            </div>
            <div
                name="Controls"
                menuicon="fas fa-shield-alt"
                menuItemId="control-notification-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsControlMailAdministrator"
            >
                <uc-notification-overview
                    [module]="moduleTypes.Controls"
                ></uc-notification-overview>
            </div>
            <div
                name="Measures of improvement"
                menuicon="fas fa-tasks"
                menuItemId="moi-notification-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsBimMailAdministrator"
            >
                <uc-notification-overview [module]="moduleTypes.IM"></uc-notification-overview>
            </div>
            <div
                name="Forms"
                menuicon="fas fa-file-alt"
                menuItemId="form-notification-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsFormMailAdministrator"
            >
                <uc-notification-overview [module]="moduleTypes.FRM"></uc-notification-overview>
            </div>
            <div
                name="Third party"
                menuicon="fas fa-handshake"
                menuItemId="tp-notification-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsThirdPartyMailAdministrator"
            >
                <uc-notification-overview [module]="moduleTypes.TP"></uc-notification-overview>
            </div>
            <div
                name="Navigators"
                menuicon="fad fa-sitemap"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsNavigatorMailAdministrator"
            >
                <uc-notification-overview [module]="moduleTypes.NAV"> </uc-notification-overview>
            </div>
            <div
                name="Tasks"
                menuicon="fad fa-list-ol"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsTaskMailAdministrator"
            >
                <uc-notification-overview [module]="moduleTypes.Tasks"> </uc-notification-overview>
            </div>
        </div>
        <div name="Periodic Notifications" menuicon="fad fa-bells" menusystemsubmenu>
            <div
                name="Measures of improvement"
                menuicon="fas fa-tasks"
                menuItemId="moi-periodic-notification-mail"
                menusystemitem
                *ngIf="permService.permissions.MailMessages.IsBimMailAdministrator"
            >
                <uc-periodic-notification-overview
                    [module]="moduleTypes.IM"
                ></uc-periodic-notification-overview>
            </div>
        </div>
        <div
            name="Sent mails"
            class="sent-mails"
            menuItemId="sent-mails"
            menuicon="fas fa-history"
            menusystemitem
            *ngIf="permService.permissions.IsApplicationAdmin"
        >
            <uc-sentnotification-overview
                [parentTab]="tab"
                #sentmails
            ></uc-sentnotification-overview>
        </div>
        <div
            name="Notification Settings"
            class="notification-settings"
            menuItemId="notification-settings"
            menuicon="fas fa-cogs"
            menusystemitem
            *ngIf="permService.permissions.IsApplicationAdmin"
        >
            <notification-settings [parentTab]="tab" #notificationSettings></notification-settings>
        </div>
    </div>
</div>
