import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContextMenuItem, MenuItemBuilder } from "@app/shared/contextmenu/menu-item";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { clamp } from "@methods/MathMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { DiagramData, ProcessCellAttribute } from "@models/processeditor/DiagramData";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { mxgraph as mxType } from "mxgraph-factory";
import { ToastrService } from "ngx-toastr";
import { ActionEnum, Actions } from "./actions";
import { FilterComponent, FilterOutput } from "./components/filter/filter.component";
import { ShapesThatSupportLinksToOtherProcesses } from "./config/linkable-shapes";
import { GraphEditor } from "./grapheditor";
import { Hotkeys, Shortcut } from "./hotkeys";
import { mxHoverIcons } from "./hover-icons";
import { createShortcuts } from "./shortcuts";
import { doImportVisio } from "./vsdxImporter/importer.js";
import { PopupService } from "@app/shared/services/popup/popup.service";
import { PrintComponent } from "./components/print/print.component";
import { PrintData } from "./models/PrintData";
import { ProcessStepModel } from "./models/ProcessStepModel";
import { nameof } from "@methods/jeffs-toolkit";
import { ProcessEditorPrintType } from "./enums/ProcessEditorPrintType";
import { toPromise } from "@methods/CommonMethods";
import { mxStyleSheetHelper } from "./mxStylesheetHelper";

interface Area {
    visible: boolean;
    size: number;
}

interface SplitAreaConfig {
    shapeArea: Area;
    graphArea: Area;
    propertiesArea: Area;
    disabled: boolean;
}

declare var mxUrlConverter: any;
declare var mxBasePath: string;
declare var mxResources: any;

@Component({
    selector: "app-process-editor",
    templateUrl: "./process-editor.component.html",
    styleUrls: ["./process-editor.component.scss"],
})
export class ProcessEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("processEditor", { static: true }) public processEditor: ElementRef;
    @ViewChild("graphContainer", { static: true }) public graphContainer: ElementRef;

    public mxDeps: typeof mxType;
    @Input() public readOnly = false;
    @Input() public showFilter = true;
    @Input() public set cerrixTab(tab: TabModel) {
        this.tab = tab;
    }

    public graph: GraphEditor;
    public propertiesLoaded = false;
    public path: CerrixTreeItem[];

    public contextMenu: ContextMenuItem[] = [];
    public actions: Actions;

    public isFilterApplied = false;
    public isLoading = false;
    public currentOrganizationId: number;
    public currentBusinessDimensionId: number;

    public tab: TabModel;

    public readonly defaultSplitConfig: SplitAreaConfig = {
        shapeArea: {
            visible: true,
            size: 15,
        },
        graphArea: {
            visible: true,
            size: 65,
        },
        propertiesArea: {
            visible: true,
            size: 20,
        },
        disabled: false,
    };

    public splitConfig: SplitAreaConfig;
    @ViewChild("gridCanvas", { static: true }) private gridCanvas: ElementRef;
    @ViewChild("filter") private filter: FilterComponent;

    private resourcesToDestroy: (() => void)[] = [];
    private _readonlyPanIntercept = null;
    private importFileInputElt: HTMLInputElement;

    hasAccessChecks = {};
    accessCheckInProgress = false;

    constructor(
        private _route: ActivatedRoute,
        private _ds: ProcessEditorDataService,
        private _tabService: TabService,
        private _cdRef: ChangeDetectorRef,
        private _hotkeys: Hotkeys,
        private _toastr: ToastrService,
        private _menuItemBuilder: MenuItemBuilder,
        private _promptService: CerrixPromptService,
        private _permService: PermissionsService,
        private _popupService: PopupService
    ) {}

    public ngOnInit() {
        this.setup();
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            const queryParamMap = this._route.snapshot.queryParamMap;
            const businessDimensionKey = nameof<FilterOutput>((x) => x.businessDimension);
            const organizationKey = nameof<FilterOutput>((x) => x.organization);

            const config = this.tab && this.tab.config && this.tab.config;
            if (config) {
                const businessDimensionId =
                    config[businessDimensionKey] ||
                    queryParamMap.get(businessDimensionKey.toLowerCase());
                const organizationId =
                    config[organizationKey] || queryParamMap.get(organizationKey.toLowerCase());

                const filters: FilterOutput = {
                    businessDimension: businessDimensionId ? +businessDimensionId : undefined,
                    organization: organizationId ? +organizationId : undefined,
                };

                if (filters.businessDimension) {
                    if (this.filter) {
                        this.filter.setFilterSelection(filters);
                    } else {
                        this.loadDiagram(filters);
                    }
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.resourcesToDestroy.forEach((f) => f());
        this.resourcesToDestroy = [];
    }

    public setup() {
        this.graph = new GraphEditor(this.graphContainer.nativeElement);
        this.mxDeps = this.graph.dependencies;

        const mx = this.mxDeps;
        const graph = this.graph;

        graph.dropEnabled = true;
        graph.enabled = false;
        graph.centerZoom = true;
        graph.connectionArrowsEnabled = true;
        graph.view.setScale(0.5);
        graph.center(true, false, 1);
        graph.setConnectable(true);
        graph.setGridEnabled(!this.readOnly);
        graph.setGridSize(20);
        graph.setPanning(true);
        graph.setConstrainChildren(false);
        graph.setConstrainRelativeChildren(true);
        graph.setAllowLoops(true);

        mx.mxEdgeHandler.prototype.virtualBendsEnabled = true;
        mx.mxGraphHandler.prototype.removeEmptyParents = true;
        mx.mxRubberband.prototype.fadeOut = true;
        // Fix gradient in print page
        mx.mxSvgCanvas2D.prototype.getBaseUrl = () => "";

        // Initializes rubberband even though return value is unused
        const rubberBand = new mx.mxRubberband(graph);
        this.enableHTMLLabel(graph);
        this.enableWrapping(graph);
        this.enableEdgeConnectPreview(graph);
        this.enableLivePreview(graph);
        this.enableFixedPoints(graph);
        this.enableGuides(mx);
        this.enableDrill(mx);
        this.enableFocusOnSelect(mx);
        this.extendBackground(graph, mx);
        this.extendRubberband(mx);

        const HoverIcons = this.initHoverIcons();
        this.extendResizer(mx, HoverIcons);

        this.consumePanningTrigger(graph);
        this.detachChangeDetectorOnPanning(graph);
        this.setCanvas2DBaseUrl();
        this.setCursorOnPanning(graph);
        this.setContextMenuDebouncerOnPanEnd(graph);
        this.setDefaultStyle(graph, mx);
        const actions = new Actions(this, this._permService, this._toastr);
        this.actions = actions;
        this.registerHotkeys(graph, actions);
        this.createContextMenu(actions);
        this.initSplitAreaConfig();
        this.addTabListeners();
        mxResources.add("/assets/mxgraph/resources/mapping");

        graph.setEditEnabled(!this.readOnly);
    }

    public importLocalFile(): void {
        if (this.importFileInputElt == null) {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.accept = ".vsdx";
            input.style.display = "none";

            input.onchange = (): any => {
                if (input.files.length > 0) {
                    const savingPrompt = this._promptService.loader("Importing, please wait...");
                    doImportVisio(
                        input.files[0],
                        (xml: string) => {
                            this.graph.importXml(xml, 0, 0, false, false);

                            savingPrompt.close();
                        },
                        () => {
                            savingPrompt.close();
                            this._toastr.error("Import failed", "Import failed");
                        }
                    );

                    // Resets input to force change event for same file (type reset required for IE)
                    input.type = "";
                    input.type = "file";
                    input.value = "";
                }
            };

            document.body.appendChild(input);

            this.importFileInputElt = input;
        }

        this.importFileInputElt.click();
    }

    public async loadDiagram(e: FilterOutput) {
        if (e.businessDimension) {
            this.isFilterApplied = true;
            this.isLoading = true;
            this.graph.setEnabled(false);

            this.currentOrganizationId = e.organization;
            this.currentBusinessDimensionId = e.businessDimension;

            const dd = await this._ds.getDiagram(e.businessDimension, e.organization).toPromise();
            await this.graph
                .loadDiagram(dd)
                .then(() => {
                    this.reloadPropertiesSidebar();
                    this.graph.fit();
                    this.graph.setEnabled(true);

                    this.isLoading = false;

                    if (this.tab) {
                        this.tab.config = e;
                    }
                })
                .catch((err) => {
                    this._toastr.error(err, "Error occurred");
                });
        } else {
            this._toastr.error("Not all parameters have been provided", "Can't load process");
        }
    }

    public saveDiagram() {
        const graph = this.graph;
        if (!graph.diagramData) {
            return;
        }
        try {
            const { mxCodec, mxUtils } = this.graph.dependencies;
            const enc = new mxCodec(mxUtils.createXmlDocument());
            const node = enc.encode(graph.getModel());
            const xml = mxUtils.getPrettyXml(node);

            const diagramdata: DiagramData = graph.diagramData;
            diagramdata.xmlGraph = xml;

            const svgElement = graph.getSvg({
                background: "#ffffff",
                nocrop: true,
            });
            diagramdata.previewImage = svgElement.outerHTML;

            this._ds.saveDiagram(diagramdata).subscribe((softValidations) => {
                if (softValidations) {
                    this._toastr.success(softValidations, null, { enableHtml: true });
                } else {
                    this._toastr.success("Save was successful", "Saved");
                }

                this.loadDiagram({
                    organization: this.currentOrganizationId,
                    businessDimension: this.currentBusinessDimensionId,
                });
            });
        } catch (err) {
            this._toastr.error(err.message, "Error occurred");
        }
    }

    public onDragEnd() {
        this.graph.refresh();
    }

    public panContextMenuIntercept(ev: MouseEvent) {
        if (this.readOnly && this._readonlyPanIntercept) {
            ev.preventDefault();
            return false;
        }
    }

    public zoomGraph(e: WheelEvent, minZoom = 0.05, maxZoom = 5) {
        if (e.ctrlKey) {
            e.preventDefault();

            const graph = this.graph;
            const view = graph.getView();
            const { x: translateX, y: translateY } = view.translate;
            const scale = view.getScale();
            const offset = this.calculateOffset(this.graphContainer.nativeElement);
            const zoomFactor = graph.zoomFactor;
            const newScale = scale * zoomFactor;
            const ratio = zoomFactor - 1;

            const pX = (e.clientX - offset.left) * (1 / newScale) * -ratio;
            const pY = (e.clientY - offset.top) * (1 / newScale) * -ratio;

            if (e.deltaY > 0) {
                const calculatedScale = scale * (1 / zoomFactor);
                if (calculatedScale < minZoom) {
                    return;
                }
                // Zoom out
                view.scaleAndTranslate(
                    calculatedScale,
                    translateX - pX * zoomFactor,
                    translateY - pY * zoomFactor
                );
            } else if (e.deltaY < 0) {
                if (newScale > maxZoom) {
                    return;
                }
                // Zoom in
                view.scaleAndTranslate(newScale, translateX + pX, translateY + pY);
            }
        } else if (e.shiftKey) {
            e.preventDefault();

            const graph = this.graph;
            const view = graph.getView();
            // ? Will probably fail when it has a horizontal scrollbar
            view.setTranslate(view.translate.x - clamp(e.deltaY, -100, 100), view.translate.y);
        } else {
            e.preventDefault();

            const graph = this.graph;
            const view = graph.getView();
            view.setTranslate(view.translate.x, view.translate.y - clamp(e.deltaY, -100, 100));
        }
    }

    public print(isPrintPreview: boolean, printMode: ProcessEditorPrintType) {
        const graph = this.graph;
        // List of comments for each item except risk and risk control
        const cellItems = Object.values<mxType.mxCell>(graph.model.cells).filter((c) =>
            Boolean(c.geometry)
        );
        const cellList = cellItems.map((cell) => {
            const commentItem = <ProcessStepModel>{
                cellID: cell.id,
                shape: cell.getShape(),
                label: cell.getAttribute(nameof<ProcessCellAttribute>((x) => x.label)),
                comment: cell.getAttribute(nameof<ProcessCellAttribute>((x) => x.comment)),
                x: cell.geometry.x,
                y: cell.geometry.y,
                ordernr: +cell.getAttribute(nameof<ProcessCellAttribute>((x) => x.order_nr)),
                linkedId: +cell.getAttribute(nameof<ProcessCellAttribute>((x) => x.linked_id)),
                includeinprint: cell.getAttribute(
                    nameof<ProcessCellAttribute>((x) => x.include_in_print)
                ),
            };
            return commentItem;
        });
        // Fill list with relations between processes and risks
        const processCellConnectionModel = graph.getProcessCellConnections(cellItems);
        // Current translation
        const translation = graph.getView().getTranslate().clone();
        const scale = graph.getView().getScale();
        // reset scale and obtain the bounds.
        graph.getView().setScale(1);
        graph.clearSelection();
        graph.getView().setTranslate(0, 0);
        const bounds = graph.getView().graphBounds;
        // set to default
        const dx = +-bounds.x;
        const dy = +-bounds.y;
        graph.getView().setTranslate(dx, dy);
        // We only support IE for now.
        const svg = this.graph.getSvg({
            background: "#ffffff",
            nocrop: true,
        }).outerHTML;
        // return to original
        graph.getView().scaleAndTranslate(scale, translation.x, translation.y);
        const data: PrintData = {
            graph: svg,
            processSteps: cellList,
            meta: graph.diagramData.metadata,
            isPrintPreview,
            height: bounds.height,
            width: bounds.width,
            printSetting: printMode,
            cellraci: graph.diagramData.racis,
            processCellConnectionModel,
            controls: graph.diagramData.controls,
            risks: graph.diagramData.risks,
        };

        this._popupService.openPopoutModal({
            title: graph.diagramData.metadata.processName,
            data: data,
            componentOrTemplateRef: {
                componentType: PrintComponent,
            },
        });
    }

    private registerDisposableResources<T>(destroyFunc: (x: T) => void, ...resources: T[]) {
        resources.forEach((r) => this.resourcesToDestroy.push(destroyFunc.bind(null, r)));
    }

    private addTabListeners() {
        this._tabService.listeners.removeTabListenerByType(
            this.tab,
            TabEventListenerType.ReloadConfig
        );

        this.registerDisposableResources(
            (x) => this._tabService.listeners.removeListener(x),
            this._tabService.listeners.addGlobalListener(
                TabEventListenerType.ReloadConfig,
                this.tab.lookupname,
                (tab: TabModel) => this.loadDiagram(tab.config)
            )
        );
    }

    private initSplitAreaConfig() {
        this.splitConfig = this.defaultSplitConfig;
        this.splitConfig.shapeArea.visible = !this.readOnly;
        this.splitConfig.graphArea.size = this.readOnly
            ? 80
            : this.defaultSplitConfig.graphArea.size;
    }

    private initHoverIcons() {
        const mx = this.graph.dependencies;
        const HoverIcons = mxHoverIcons(mx, this.graph);
        Object.assign(mx.mxVertexHandler.prototype, {
            handleImage: HoverIcons.mainHandle,
            secondaryHandleImage: HoverIcons.secondaryHandle,
            rotationEnabled: !this.readOnly,
        });
        Object.assign(mx.mxEdgeHandler.prototype, {
            handleImage: HoverIcons.mainHandle,
            terminalHandleImage: HoverIcons.terminalHandle,
            fixedHandleImage: HoverIcons.fixedHandle,
            labelHandleImage: HoverIcons.secondaryHandle,
        });
        Object.assign(mx.mxOutline.prototype, { sizerImage: HoverIcons.mainHandle });

        mx.mxEdgeHandler.prototype.createHandleShape = this.getCreateHandleShapeFunction(mx);

        return HoverIcons;
    }

    private registerHotkeys(graph: GraphEditor, actions: Actions) {
        if (!this.readOnly) {
            const shortcuts: Partial<Shortcut>[] = createShortcuts(actions).map<Partial<Shortcut>>(
                (x) => {
                    const result: Partial<Shortcut> = {
                        keys: x.keys,
                        handler: (e) => {
                            if (
                                !graph.isEditing(null) &&
                                this.isFilterApplied &&
                                this._tabService.activeTab === this.tab
                            ) {
                                e.preventDefault();
                                if (actions.hasPermission(x.action)) {
                                    actions.execute(x.action);
                                }
                            }
                        },
                    };

                    if (!x.isGlobalEvent) {
                        result.element = this.graphContainer.nativeElement;
                    }

                    return result;
                }
            );

            this.registerDisposableResources(
                (s) => s.unsubscribe(),
                ...shortcuts.map((x) => this._hotkeys.addShortcut(x).subscribe(x.handler))
            );
        }
    }

    private calculateOffset(offsetParent, accum = { top: 0, left: 0 }) {
        if (offsetParent) {
            accum.left += offsetParent.offsetLeft;
            accum.top += offsetParent.offsetTop - (offsetParent.scrollTop || 0);
            return this.calculateOffset(offsetParent.offsetParent, accum);
        } else {
            return accum;
        }
    }

    private getCreateHandleShapeFunction(mx: typeof mxType) {
        return function (index: number) {
            const source = index != null && index === 0;
            const terminalState = this.state.getVisibleTerminalState(source);
            const c =
                index != null &&
                (index === 0 ||
                    index >= this.state.absolutePoints.length - 1 ||
                    (this.constructor === mx.mxElbowEdgeHandler && index === 2))
                    ? this.graph.getConnectionConstraint(this.state, terminalState, source)
                    : null;
            const pt =
                c != null
                    ? this.graph.getConnectionPoint(this.state.getVisibleTerminalState(source), c)
                    : null;
            const img =
                pt != null
                    ? this.fixedHandleImage
                    : c != null && terminalState != null
                    ? this.terminalHandleImage
                    : this.handleImage;

            if (img != null) {
                const shape = new mx.mxImageShape(
                    new mx.mxRectangle(0, 0, img.width, img.height),
                    img.src,
                    null,
                    null
                );

                // Allows HTML rendering of the images
                shape.preserveImageAspect = false;

                return shape;
            } else {
                let s = mx.mxConstants.HANDLE_SIZE;

                if (this.preferHtml) {
                    s -= 1;
                }

                return new mx.mxRectangleShape(
                    new mx.mxRectangle(0, 0, s, s),
                    mx.mxConstants.HANDLE_FILLCOLOR,
                    mx.mxConstants.HANDLE_STROKECOLOR
                );
            }
        };
    }

    private consumePanningTrigger(graph: GraphEditor) {
        graph.panningHandler.consumePanningTrigger = function (me) {
            if (me.evt.preventDefault) {
                me.evt.preventDefault();
            }
            // Stops event processing in IE
            me.evt.returnValue = false;
        };
    }

    private detachChangeDetectorOnPanning(graph: GraphEditor) {
        const mxEvent = graph.dependencies.mxEvent;
        graph.panningHandler.addListener(mxEvent.PAN_START, () => {
            this._cdRef.detach();
        });

        graph.panningHandler.addListener(mxEvent.PAN_END, () => {
            this._cdRef.reattach();
        });
    }

    private setDefaultStyle(graph: GraphEditor, mx: typeof mxType) {
        mx.mxConstants.VERTEX_SELECTION_COLOR = "#A9A9A9";
        mx.mxConstants.HANDLE_FILLCOLOR = "#FFFFFF";
        mx.mxConstants.HANDLE_STROKECOLOR = "#A9A9A9";
        mx.mxConstants.HANDLE_SIZE = 10;
        mx.mxConstants.GUIDE_COLOR = "#336699";

        graph.alternateEdgeStyle = "vertical";

        mxStyleSheetHelper.fixStyleSheet(graph, mx);
    }

    private setCanvas2DBaseUrl() {
        this.graph.dependencies.mxAbstractCanvas2D.prototype.createUrlConverter = () => {
            const urlConverter = mxUrlConverter.prototype;
            urlConverter.baseUrl = window.origin + "/assets/";
            return urlConverter;
        };
    }

    private setCursorOnPanning(graph: GraphEditor) {
        const mxEvent = graph.dependencies.mxEvent;
        graph.panningHandler.addListener(mxEvent.PAN_START, function () {
            document.body.style.cursor = "move";
            graph.container.style.cursor = "move";
        });
        graph.panningHandler.addListener(mxEvent.PAN_END, function () {
            document.body.style.cursor = "default";
            graph.container.style.cursor = "default";
        });
    }

    private setContextMenuDebouncerOnPanEnd(graph: GraphEditor) {
        graph.panningHandler.addListener(graph.dependencies.mxEvent.PAN_END, () => {
            if (this.readOnly) {
                if (this._readonlyPanIntercept) {
                    clearTimeout(this._readonlyPanIntercept);
                }

                this._readonlyPanIntercept = setTimeout(() => {
                    clearTimeout(this._readonlyPanIntercept);
                    this._readonlyPanIntercept = null;
                }, 100);
            }
        });
    }

    private createContextMenu(actions) {
        const shapeSubMenu = this._menuItemBuilder
            .appendItem(
                "Group",
                actions.get(ActionEnum.GROUP_CELLS),
                actions.getShortcut(ActionEnum.GROUP_CELLS)
            )
            .appendItem(
                "Ungroup",
                actions.get(ActionEnum.UNGROUP_CELLS),
                actions.getShortcut(ActionEnum.UNGROUP_CELLS)
            )
            .appendDivider()
            .appendItem(
                "To front",
                actions.get(ActionEnum.TO_FRONT),
                actions.getShortcut(ActionEnum.TO_FRONT)
            )
            .appendItem(
                "To back",
                actions.get(ActionEnum.TO_BACK),
                actions.getShortcut(ActionEnum.TO_BACK)
            )
            .build();

        this.contextMenu = this._menuItemBuilder
            .appendItem(
                "Undo",
                actions.get(ActionEnum.UNDO),
                actions.getShortcut(ActionEnum.UNDO),
                "fa fa-undo",
                () => this.graph.canUndo()
            )
            .appendDivider()
            .appendItem(
                "Cut",
                actions.get(ActionEnum.CUT),
                actions.getShortcut(ActionEnum.CUT),
                "fa fa-scissors",
                () => !this.graph.isSelectionEmpty()
            )
            .appendItem(
                "Copy",
                actions.get(ActionEnum.COPY),
                actions.getShortcut(ActionEnum.COPY),
                "fa fa-clone",
                () => !this.graph.isSelectionEmpty()
            )
            .appendItem(
                "Paste",
                actions.get(ActionEnum.PASTE),
                actions.getShortcut(ActionEnum.PASTE),
                "fa fa-clipboard"
            )
            .appendItem(
                "Delete",
                actions.get(ActionEnum.REMOVE_CELLS),
                actions.getShortcut(ActionEnum.REMOVE_CELLS),
                "fa fa-trash-o",
                () => !this.graph.isSelectionEmpty()
            )
            .appendSubMenu("Shape", shapeSubMenu, () => !this.graph.isSelectionEmpty())
            .appendDivider()
            .appendItem(
                "Edit",
                actions.get(ActionEnum.EDIT),
                actions.getShortcut(ActionEnum.EDIT),
                "fa fa-pencil"
            )
            .appendDivider()
            .appendItem(
                "Select Vertices",
                actions.get(ActionEnum.SELECT_VERTICES),
                actions.getShortcut(ActionEnum.SELECT_VERTICES)
            )
            .appendItem(
                "Select Edges",
                actions.get(ActionEnum.SELECT_EDGES),
                actions.getShortcut(ActionEnum.SELECT_EDGES)
            )
            .appendDivider()
            .appendItem(
                "Select All",
                actions.get(ActionEnum.SELECT_ALL),
                actions.getShortcut(ActionEnum.SELECT_ALL)
            )
            .build();
    }

    private extendResizer(mx: typeof mxType, HoverIcons) {
        const { mxVertexHandler, mxRectangleShape, mxEllipse, mxConstants, mxEvent } = mx;
        const superCreateSizerShape = mxVertexHandler.prototype.createSizerShape;
        const createSizerShape = function (bounds, index) {
            if (index === mxEvent.ROTATION_HANDLE) {
                this.handleImage = HoverIcons.rotationHandle;
                return superCreateSizerShape.apply(this, arguments);
            } else {
                this.handleImage =
                    index === mxEvent.LABEL_HANDLE ? this.secondaryHandleImage : this.handleImage;
            }

            const shape = superCreateSizerShape.apply(this, arguments);

            if (shape instanceof mxRectangleShape) {
                return new mxEllipse(
                    bounds,
                    mxConstants.HANDLE_FILLCOLOR,
                    mxConstants.HANDLE_STROKECOLOR
                );
            }
            return shape;
        };
        mxVertexHandler.prototype.createSizerShape = createSizerShape;
    }

    // Draw grid
    private extendBackground(graph: GraphEditor, mx: any) {
        const { mxGraphView, mxPoint } = mx;
        const canvas = this.gridCanvas.nativeElement as HTMLCanvasElement;
        this.setBackgroundStyle(canvas);
        graph.container.appendChild(canvas);

        const ctx = canvas.getContext("2d");

        this.overrideContainerEvent(graph, canvas);

        const state = {
            s: 0,
            gs: 0,
            tr: new mxPoint(),
            w: 0,
            h: 0,
            gridEnabled: false,
        };

        const that = this;

        const mxGraphViewValidateBackground = mxGraphView.prototype.validateBackground;
        mxGraphView.prototype.validateBackground = function () {
            mxGraphViewValidateBackground.apply(this, arguments);
            that.repaintGrid(ctx, canvas, graph, state);
        };
    }

    private didStateChange(state, graph: GraphEditor, width: number, height: number) {
        return (
            graph.view.scale !== state.s ||
            graph.view.translate.x !== state.tr.x ||
            graph.view.translate.y !== state.tr.y ||
            state.gs !== graph.gridSize ||
            width !== state.w ||
            height !== state.h ||
            state.gridEnabled !== graph.gridEnabled
        );
    }

    private updateState(state, graph: GraphEditor, width: number, height: number) {
        state.tr = graph.view.translate.clone();
        state.s = graph.view.scale;
        state.gs = graph.gridSize;
        state.w = width;
        state.h = height;
        state.gridEnabled = graph.gridEnabled;
    }

    private repaintGrid(
        ctx: CanvasRenderingContext2D,
        canvas: HTMLCanvasElement,
        graph: GraphEditor,
        state
    ) {
        if (ctx != null) {
            const bounds = graph.getGraphBounds();
            const width = Math.max(bounds.x + bounds.width, graph.container.clientWidth);
            const height = Math.max(bounds.y + bounds.height, graph.container.clientHeight);
            const sizeChanged = width !== state.w || height !== state.h;

            if (!graph.isGridEnabled()) {
                state.gridEnabled = graph.gridEnabled;
                ctx.clearRect(0, 0, width, height);
                return;
            }

            if (this.didStateChange(state, graph, width, height)) {
                this.updateState(state, graph, width, height);

                // Clears the background if required
                if (!sizeChanged) {
                    ctx.clearRect(0, 0, width, height);
                } else {
                    canvas.setAttribute("width", width + "");
                    canvas.setAttribute("height", height + "");
                }

                const scale = state.s;
                const tx = state.tr.x * scale;
                const ty = state.tr.y * scale;

                // Sets the distance of the grid lines in pixels
                const minStepping = graph.gridSize;
                let stepping = minStepping * scale;

                if (stepping < minStepping) {
                    const count = Math.round(Math.ceil(minStepping / stepping) / 2) * 2;
                    stepping = count * stepping;
                }

                const xs = Math.floor((0 - tx) / stepping) * stepping + tx;
                let xe = Math.ceil(width / stepping) * stepping;
                const ys = Math.floor((0 - ty) / stepping) * stepping + ty;
                let ye = Math.ceil(height / stepping) * stepping;

                xe += Math.ceil(stepping);
                ye += Math.ceil(stepping);

                const ixs = Math.round(xs);
                const ixe = Math.round(xe);
                const iys = Math.round(ys);
                const iye = Math.round(ye);

                // Draws the actual grid
                ctx.strokeStyle = "#ececec";
                ctx.beginPath();

                // Draw horizontal lines
                for (let x = xs; x <= xe; x += stepping) {
                    x = Math.round((x - tx) / stepping) * stepping + tx;
                    const ix = Math.round(x);

                    ctx.moveTo(ix + 0.5, iys + 0.5);
                    ctx.lineTo(ix + 0.5, iye + 0.5);
                }

                // Draw vertical lines
                for (let y = ys; y <= ye; y += stepping) {
                    y = Math.round((y - ty) / stepping) * stepping + ty;
                    const iy = Math.round(y);

                    ctx.moveTo(ixs + 0.5, iy + 0.5);
                    ctx.lineTo(ixe + 0.5, iy + 0.5);
                }

                ctx.closePath();
                ctx.stroke();
            }
        }
    }

    private setBackgroundStyle(canvas: HTMLCanvasElement) {
        canvas.style.position = "absolute";
        canvas.style.top = "0px";
        canvas.style.left = "0px";
        canvas.style.zIndex = -1 + "";
    }

    // Modify event filtering to accept canvas as container
    private overrideContainerEvent(graph: GraphEditor, canvas: HTMLCanvasElement) {
        const { mxGraphView, mxEvent } = graph.dependencies;
        const mxGraphViewIsContainerEvent = mxGraphView.prototype.isContainerEvent;
        mxGraphView.prototype.isContainerEvent = function (evt) {
            return (
                mxGraphViewIsContainerEvent.apply(this, arguments) ||
                mxEvent.getSource(evt) === canvas
            );
        };
    }

    // Select when dragging mouse
    private extendRubberband(mx: typeof mxType) {
        const superMouseMove = mx.mxRubberband.prototype.mouseMove;
        const mouseMove = function (g: mxType.mxGraph, mouseEvent: mxType.mxMouseEvent) {
            superMouseMove.apply(this, arguments);
            // First gets created on mxRubberband.start, we don't want to make a selection when dragging hasn't started.
            if (this.first) {
                if (this.mouseMoveDebouncer) {
                    clearTimeout(this.mouseMoveDebouncer);
                }
                this.mouseMoveDebouncer = setTimeout(() => {
                    this.execute(mouseEvent);
                }, 50);
            }
        };
        mx.mxRubberband.prototype.mouseMove = mouseMove;
    }

    private enableHTMLLabel(graph: GraphEditor) {
        graph.isHtmlLabel = function () {
            return true;
        };
    }

    private enableWrapping(graph: GraphEditor) {
        graph.isWrapping = function () {
            return true;
        };
    }

    private enableLivePreview(graph: GraphEditor) {
        const livePreviewProp = "livePreview";
        const maxLivePreviewProp = "maxLivePreview";
        graph.connectionHandler.livePreview = true;
        graph.dependencies.mxVertexHandler.prototype[livePreviewProp] = true;
        graph.graphHandler[maxLivePreviewProp] = 50;
    }

    private enableEdgeConnectPreview(graph: GraphEditor) {
        const { mxEdgeHandler, mxCellState } = graph.dependencies;
        // Disables floating connections (only use with no connect image)
        if (graph.connectionHandler.connectImage == null) {
            graph.connectionHandler.isConnectableCell = function () {
                return false;
            };
            mxEdgeHandler.prototype.isConnectableCell = function (cell) {
                return graph.connectionHandler.isConnectableCell(cell);
            };
        }

        // Connect preview
        graph.connectionHandler.createEdgeState = function () {
            const edge = graph.createEdge(
                null,
                null,
                null,
                null,
                null,
                "edgeStyle=orthogonalEdgeStyle"
            );

            return new mxCellState(this.graph.view, edge, this.graph.getCellStyle(edge));
        };
    }

    private enableGuides(mx: typeof mxType) {
        // Enables guides
        mx.mxGraphHandler.prototype.guidesEnabled = true;

        // Alt disables guides
        mx.mxGraphHandler.prototype.useGuidesForEvent = function (me) {
            return !mx.mxEvent.isAltDown(me.getEvent());
        };
    }

    // Snaps to nearest fixed point
    private enableFixedPoints(graph: GraphEditor) {
        const { mxConstraintHandler, mxEvent, mxUtils } = graph.dependencies;

        graph.selectionModel.addListener(mxEvent.CHANGE, (sender, evt: mxType.mxEventObject) => {
            const addedcells = evt.getProperty("added");
            if (addedcells) {
                graph.connectionHandler.constraintHandler.destroyIcons();
            }
        });

        const superUpdate = mxConstraintHandler.prototype.update;
        mxConstraintHandler.prototype.update = function (me: mxType.mxMouseEvent, source) {
            if (source && this.graph.isCellSelected(me.getCell())) {
                return;
            }
            superUpdate.apply(this, arguments);
        };

        // Snaps to fixed points
        mxConstraintHandler.prototype.intersects = function (icon, point, source, existingEdge) {
            return !source || existingEdge || mxUtils.intersects(icon.bounds, point);
        };
    }

    private enableFocusOnSelect(mx: typeof mxType) {
        this.graph.getSelectionModel().addListener(mx.mxEvent.CHANGE, () => {
            (this.graphContainer.nativeElement as HTMLDivElement).focus();
        });
    }

    private enableDrill(mx) {
        if (this.readOnly) {
            const { mxEvent } = mx;
            this.graph.addListener(mxEvent.DOUBLE_CLICK, async (sender, evt) => {
                const cell = evt.getProperty("cell");
                if (cell && ShapesThatSupportLinksToOtherProcesses.includes(cell.getShape())) {
                    const businessDimensionId = +cell.getAttribute("linked_id");
                    if (businessDimensionId && businessDimensionId > 0 && this.filter) {
                        if (await this.hasAccessToBusinessDimension(businessDimensionId)) {
                            this.filter.setFilterSelection({
                                organization: this.currentOrganizationId,
                                businessDimension: businessDimensionId,
                            });
                        }
                    }
                }
            });
        }
    }

    private reloadPropertiesSidebar() {
        this.propertiesLoaded = false;
        this._cdRef.detectChanges();
        this.propertiesLoaded = true;
    }

    private async hasAccessToBusinessDimension(businessDimensionId: number): Promise<boolean> {
        if (!businessDimensionId) {
            return true;
        }

        let hasAccess = this.hasAccessChecks[businessDimensionId];
        if (hasAccess == null && !this.accessCheckInProgress) {
            this.accessCheckInProgress = true;

            const loadingPrompt = this._promptService.loader("Checking rights, please wait...");
            try {
                hasAccess = await toPromise(
                    this._ds.hasAccessToBusinessDimension(businessDimensionId)
                );
                this.hasAccessChecks[businessDimensionId] = hasAccess;
            } finally {
                loadingPrompt.close();
                this.accessCheckInProgress = false;
            }
        }

        if (!hasAccess) {
            this._toastr.warning("Not enough rights to open this linked process.");
        }

        return hasAccess;
    }
}
