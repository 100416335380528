import { Component, OnInit } from "@angular/core";
import { ReleaseNoteModel } from "@app/release-notes/models/release-notes-model";
import { ReleaseNotesService } from "@app/release-notes/services/releasenotes.service";
import { TabModel } from "../../../../common/models/generic/TabModels/TabModel";

@Component({
    selector: "release-notes",
    templateUrl: "./release-notes.component.html",
    styleUrls: ["./release-notes.component.scss"],
})
export class ReleaseNotesComponent implements OnInit {
    tab: TabModel;

    versions: string[];

    releaseNotes: ReleaseNoteModel;
    loadedVersion: string;
    activeHeader: string;

    constructor(private _ds: ReleaseNotesService) {}

    async ngOnInit() {
        await this.getVersions();
        if (this.versions.length > 0) {
            this.getReleaseNotes(this.versions[0]);
        }
    }

    async getVersions() {
        this.versions = await this._ds.getList();
    }

    async getReleaseNotes(version: string) {
        this.releaseNotes = await this._ds.getVersion(version);

        this.loadedVersion = version;
        this.activeHeader = this.releaseNotes.sections[0].title;
    }

    scrollTimeout: any;
    onScroll(containerElem: HTMLElement) {
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = null;
        }

        this.scrollTimeout = setTimeout(() => {
            const scrollLocation = containerElem.scrollTop;
            const headers = containerElem.querySelectorAll(".section-title");

            let firstVisibleHeader = "";
            let highestTop = Number.MAX_VALUE;
            headers.forEach((header) => {
                const topDistance = (header as any).offsetTop - scrollLocation;
                if (topDistance >= 0 && topDistance < highestTop) {
                    highestTop = topDistance;
                    firstVisibleHeader = header.innerHTML;
                }
            });

            // If we have no header which is next in view, we will set the last header as active.
            if (!firstVisibleHeader) {
                firstVisibleHeader = headers[headers.length - 1].innerHTML;
            }

            this.activeHeader = firstVisibleHeader;
            this.scrollTimeout = null;
        }, 100);
    }

    scrollToHeader(header: string) {
        const headerElement = document.evaluate(
            `//*[contains(@class, 'scroll-header') and contains(text(), '${header}')]`,
            document,
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
        ).singleNodeValue;

        if (headerElement) {
            (headerElement as any).scrollIntoView({ behavior: "smooth" });
        }
    }
}
