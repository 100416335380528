import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FinalConclusion } from "../../eff-final-conclusion-box/models/final-conclusion.model";

declare var $: any;

@Component({
    selector: "eff-final-conclusion-popup",
    templateUrl: "./eff-final-conclusion-popup.component.html",
    styleUrls: ["./eff-final-conclusion-popup.component.scss"],
})
export class EffFinalConclusionPopupComponent {
    title: string;
    finalConclusion: FinalConclusion;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EffFinalConclusionPopupComponent>
    ) {
        this.finalConclusion = {
            author: data.username,
            authorLabel: data.authorLabel,
            availableScores: data.scores,
            conclusion: "",
            score: data.score,
            hideScore: data.hideScore,
            message: data.message,
        };
    }

    onFinalConclusion() {
        this.data.onSave(this.finalConclusion);
        this.dialogRef.close();
    }

    isButtonEnabled() {
        let isConclusionValid =
            this.finalConclusion.conclusion != "" &&
            this.finalConclusion.conclusion != undefined &&
            this.finalConclusion.conclusion != null;

        if (this.finalConclusion.hideScore) {
            return isConclusionValid;
        } else {
            return this.finalConclusion.score > 0 && isConclusionValid;
        }
    }
}
