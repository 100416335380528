import { DashboardLegendType } from "@app/dashboard/shared/enums/DashboardLegendType.enum";
import { KeyValue } from "@models/generic/KeyValuePair";
import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import {
    DashboardGraphType,
    DashboardGraphTypeDescription,
} from "../shared/enums/DashboardGraphType.enum";
import { DashboardWidgetModel } from "../shared/models/DashboardWidgetModel";
import { DashboardLegendTypeDescription } from "../shared/enums/DashboardLegendType.enum";
import { GenericListField } from "@app/shared/models/GenericList/GenericListField";
import { WidgetConfigType } from "../shared/enums/WidgetConfigType.enum";

@Component({
    selector: "widget-configuration",
    templateUrl: "./widget-configuration.component.html",
    styleUrls: ["./widget-configuration.component.scss"],
})
export class WidgetConfigurationComponent implements OnChanges {
    @Input() config: DashboardWidgetModel;

    additionalData = {};

    widgetSettings: GenericListField[] = [];
    dataFilters: GenericListField[] = [];

    graphItems: KeyValue<DashboardGraphType, string>[] = [];
    legendItems: KeyValue<DashboardLegendType, string>[] = [];

    globalFilteringDescription = "";

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        this.reset();
        this.setDefaults();
    }

    private reset() {
        this.widgetSettings = [];
        this.dataFilters = [];
        this.graphItems = [];
        this.legendItems = [];
    }

    private setDefaults() {
        if (this.config.customConfig.customTitle === this.config.title) {
            this.config.customConfig.customTitle = "";
        }

        if (!this.config.customConfig.customIcon) {
            this.config.customConfig.customIcon = this.config.icon;
        }

        this.setConfigurations();
        this.setLists();
    }

    filterChanged() {
        if (this.config.api.onFilterChanged) {
            this.config.api.onFilterChanged();
        }
    }

    private setConfigurations() {
        this.widgetSettings = this.config.configurations.filter(
            (wc) => wc.configType === WidgetConfigType.WidgetConfig
        );

        this.dataFilters = this.config.configurations.filter(
            (wc) => wc.configType === WidgetConfigType.DataFilter
        );

        if (this.config.globalFilterKeys && this.config.globalFilterKeys.length > 0) {
            const filtersForGlobal = this.config.globalFilterKeys
                .map((k) => this.dataFilters.find((d) => d.fieldName === k))
                .filter((k) => k)
                .map((k) => k.prettyName);

            this.globalFilteringDescription =
                "Allows filtering without editing the dashboard. The following filters will be available: \n\n" +
                filtersForGlobal.join("\n");
        } else {
            this.globalFilteringDescription = "";
        }
    }

    private setLists() {
        this.graphItems = this.getList(
            this.config.allowedChartTypes,
            DashboardGraphTypeDescription
        );

        if (this.config.legendConfigurable) {
            this.legendItems = this.getList(
                Object.keys(DashboardLegendTypeDescription).map((k) => <DashboardLegendType>+k),
                DashboardLegendTypeDescription
            );
        } else {
            this.legendItems = [];
        }
    }

    private getList<T>(configItems: T[], descriptions: any): KeyValue<T, string>[] {
        if (!configItems) {
            return [];
        }

        return configItems.map((ci) => new KeyValue<T, string>(ci, descriptions[ci]));
    }
}
