<div class="detail-page-summary">
    <div class="row">
        <div class="col-12" *ngIf="selectedModule === moduleTypes.ORM">
            <div class="property">
                <b>The CERRIX ORM module contains period functionality.</b>
            </div>
            <div class="property">
                This means that certain data stored in the module is valid within a certain
                period.<br />
                When closing and opening a new period in the ORM module all risks stored in the
                ‘old’ period will be reused, with the change that the checkboxes for approved and
                reviewed are reset.
            </div>
        </div>
        <div class="col-12" *ngIf="selectedModule === moduleTypes.BIM">
            <div class="property">
                <b>The CERRIX BIM module contains period functionality.</b>
            </div>
            <div class="property">
                This means that certain data stored in the module is valid within a certain
                period.<br />
                When starting a new period in the BIM module, closed Measures of Improvement will no
                longer appear without a period filter in the workspace.
            </div>
        </div>
    </div>
</div>

<div menuSystem [cerrixTab]="tab" (change)="tabChanged($event)" *ngIf="configLoaded">
    <div [attr.name]="'Periods'" menuicon="fas fa-business-time" menusystemsubmenu>
        <div
            [attr.menuItemId]="moduleTypes.ORM"
            name="ORM"
            menuicon="fas fa-exclamation-triangle"
            *ngIf="isRiskAdminU"
            menusystemitem
        >
            <div
                class="fancy-loader"
                *ngIf="selectedModule === moduleTypes.ORM && !configLoaded"
            ></div>
            <generic-list-manager
                [config]="config"
                *ngIf="selectedModule === moduleTypes.ORM && configLoaded"
            ></generic-list-manager>
        </div>
        <div
            *ngIf="isBimAdminU"
            [attr.menuItemId]="moduleTypes.BIM"
            name="BIM"
            menuicon="fas fa-tasks"
            menusystemitem
        >
            <div
                class="fancy-loader"
                *ngIf="selectedModule === moduleTypes.BIM && !configLoaded"
            ></div>
            <generic-list-manager
                [config]="config"
                *ngIf="selectedModule === moduleTypes.BIM && configLoaded"
            ></generic-list-manager>
        </div>
    </div>
</div>
