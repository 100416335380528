import { isEmpty } from "./isEmpty";

export default function customTableSort(sortOrder: boolean, col: string, isString: boolean, isNumeric: boolean, isDate: boolean) {
    if (isString) {
        return (a, b) => {
            let tempA = '' + a[col], tempB = '' + b[col];
            if (typeof tempA === 'string') {
                tempA = tempA.toLowerCase();
            }
            if (typeof tempB === 'string') {
                tempB = tempB.toLowerCase();
            }

            if (tempA === tempB) {
                return 0;
            }

            if (isEmpty(tempA)) {
                if (isEmpty(tempB)) {
                    return 0;
                }

                return 1;
            }
            if (isEmpty(tempB)) {
                if (isEmpty(tempA)) {
                    return 0;
                }

                return -1;
            }

            if (tempA < tempB) {
                return sortOrder ? -1 : 1;
            }
            if (tempA > tempB) {
                return sortOrder ? 1 : -1;
            }

            return 0;
        };
    }

    if (isNumeric) {
        return (a, b) => {
            const tempA = '' + a[col], tempB = '' + b[col];
            const parsedA = parseFloat(tempA);
            const parsedB = parseFloat(tempB);

            // Watch out when changing this part of code!
            // Check below returns 0 (as expected) when comparing empty string, null, undefined
            if (parsedA === parsedB || (isNaN(parsedA) && isNaN(parsedB))) {
                return 0;
            }

            if (isNaN(parsedA)) {
                return 1;
            }

            if (isNaN(parsedB)) {
                return -1;
            }

            if (parsedA < parsedB) {
                return sortOrder ? -1 : 1;

            } else if (parsedA > parsedB) {
                return sortOrder ? 1 : -1;
            }

            return 0;
        };
    }

    if (isDate) {
        return (a, b) => {
            const valueA = !sortOrder ? a[col] : b[col];
            const valueB = !sortOrder ? b[col] : a[col];

            return valueB - valueA;
        };
    }
}
