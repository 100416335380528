import { FormGroup, FormArray, FormControl, AbstractControl } from "@angular/forms";
import _ from "underscore";

/**
 * Helper to retrieve error messages from FormGroup
 */
export class FormValidationMessageHelper<T> {
    errorMessages: string[] = [];
    prettyFieldNames: { [key in keyof T]: string };

    /**
     * Initialize with model <T> to validate control keys of prettyFieldNames.
     * Use addAdditionalPrettyNames for custom pretty names
     * @param prettyFieldNames Optional object containing control key and pretty name
     */
    constructor(prettyFieldNames?: { [key in keyof T]: string }) {
        // Clone
        this.prettyFieldNames = prettyFieldNames
            ? Object.assign({}, prettyFieldNames)
            : <{ [key in keyof T]: string }>{};
    }

    /**
     * Get combined error message as HTML
     */
    getErrorMessage(): string {
        return this.errorMessages.length ? `- ${this.errorMessages.distinct().join("<br>- ")}` : "";
    }

    /**
     * Add error messages from FormGroup
     * @param formGroup FormGroup to get error message from
     */
    processFormGroup(formGroup: FormGroup): FormValidationMessageHelper<T> {
        this.internalProcessFormGroup(formGroup, null);

        return this;
    }

    /**
     * Add custom pretty names
     */
    addAdditionalPrettyNames(prettyNames: {
        [key: string]: string;
    }): FormValidationMessageHelper<T> {
        Object.keys(prettyNames).forEach((key) => {
            this.prettyFieldNames[key] = prettyNames[key];
        });

        return this;
    }

    private processAbstractControl(prettyKey: string, control: AbstractControl): void {
        if (control instanceof FormGroup) {
            this.internalProcessFormGroup(control, prettyKey);
        } else if (control instanceof FormArray) {
            this.processFormArray(prettyKey, control);
        } else if (control instanceof FormControl) {
            this.processErrors(prettyKey, control);
        }
    }

    private internalProcessFormGroup(formGroup: FormGroup, prettyKey: string): void {
        for (const [key, control] of Object.entries(formGroup.controls)) {
            const newPrettyKey = this.handleKey(key, prettyKey);

            this.processAbstractControl(newPrettyKey, control);
        }

        this.processErrors(prettyKey, formGroup);
    }

    private processFormArray(prettyKey: string, formArray: FormArray): void {
        formArray.controls.forEach((control) => {
            this.processAbstractControl(prettyKey, control);
        });
    }

    private processErrors(prettyName: string, formControl: FormGroup | FormControl): void {
        if (formControl.invalid && formControl.errors) {
            for (const [key, validationResult] of Object.entries(formControl.errors)) {
                if (_.isObject(validationResult) && validationResult.getErrorMessage) {
                    // Custom validators
                    const errorMessage =
                        formControl instanceof FormGroup
                            ? validationResult.getErrorMessage(this.prettyFieldNames)
                            : validationResult.getErrorMessage(prettyName, this.prettyFieldNames);
                    this.errorMessages.push(errorMessage);
                } else {
                    const lowerKey = key.toLowerCase();

                    // Default validators
                    switch (lowerKey) {
                        case "required": {
                            this.errorMessages.push(`"${prettyName}" is required`);
                            break;
                        }
                        case "min": {
                            this.errorMessages.push(
                                `"${prettyName}" must be greater than or equal to ${validationResult.min}`
                            );
                            break;
                        }
                        case "max": {
                            this.errorMessages.push(
                                `"${prettyName}" must be less than or equal to ${validationResult.max}`
                            );
                            break;
                        }
                        case "email": {
                            this.errorMessages.push(`"${prettyName}" is invalid email address`);
                            break;
                        }
                        case "minlength": {
                            this.errorMessages.push(
                                `"${prettyName}" length must be greater than or equal to ${validationResult.requiredLength}`
                            );
                            break;
                        }
                        case "maxlength": {
                            this.errorMessages.push(
                                `"${prettyName}" length must be less than or equal to ${validationResult.requiredLength}`
                            );
                            break;
                        }
                        case "pattern": {
                            this.errorMessages.push(`"${prettyName}" has invalid format`);
                            break;
                        }
                        case "invaliddaterange": {
                            this.errorMessages.push(
                                `"${prettyName}" is not between ${formControl.errors.minDate} and ${formControl.errors.maxDate}`
                            );
                            break;
                        }
                    }
                }
            }
        }
    }

    private handleKey(newKey: string, prettyKey?: string): string {
        if (newKey) {
            const newPrettyName =
                newKey in this.prettyFieldNames ? this.prettyFieldNames[newKey] : newKey;

            return prettyKey ? `${prettyKey}>${newPrettyName}` : newPrettyName;
        } else {
            return prettyKey;
        }
    }
}
