import { Component, AfterViewInit, ViewChild } from "@angular/core";
import { ParentChildSelectorConfig } from "@app/shared/models/ParentChildSelector/ParentChildSelectorConfig";
import { ExportEffectivenessDataService } from "./control-export-effectiveness.data.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ControlExportData } from "@app/controls/models/ControlExportData";
import { nameof } from "@methods/jeffs-toolkit";
import { ControlCopyExportSharedMethods } from "@app/controls/control-copy-export-shared-methods";
import moment from "moment-timezone";
import { AdvancedEffectivenessExportParametersModel } from "../models/AdvancedEffectivenessExportModel";

@Component({
    selector: "control-export-effectiveness",
    templateUrl: "./control-export-effectiveness.component.html",
    styleUrls: ["./control-export-effectiveness.component.scss"],
})
export class ControlExportEffectivenessComponent implements AfterViewInit {
    tab: TabModel;
    config: string[]; // This could be filled with the pre filtered control guids.

    selectorConfig: ParentChildSelectorConfig;
    exportRunning = false;

    constructor(
        private _ds: ExportEffectivenessDataService,
        private _promptService: CerrixPromptService
    ) {}

    private showExportRunningAlert(): void {
        this._promptService.alert({
            data: {
                title: "Please wait!",
                message: "An export is already running! Please wait for the export to finish.",
            },
        });
    }

    async ngAfterViewInit(): Promise<void> {
        this.tab.beforeClose = () => {
            if (this.exportRunning) {
                this.showExportRunningAlert();
            }
            return !this.exportRunning;
        };

        const buttons = [
            {
                prettyName: "Export data",
                icon: "fas fa-file-excel",
                disabled: false,
                click: this.exportDataClick.bind(this),
            },
        ];

        if (await this._ds.allowEvidenceExport()) {
            buttons.push({
                prettyName: "Export data & evidence",
                icon: "fas fa-file-archive",
                disabled: false,
                click: async (allItems: ControlExportData[], btn) =>
                    await this.exportDataAndEvidenceClick(allItems, btn),
            });
        }

        const controls = await this._ds.getWithGuids(this.config).toPromise();
        controls.forEach((control) => {
            control.header = `${control.identifier} - ${control.name}`;
        });

        buttons.push({
            prettyName: "Export data for import",
            icon: "fas fa-file-excel",
            disabled: false,
            click: (items: ControlExportData[], button) => this.exportDataForImport(items, button),
        });

        this.selectorConfig = ControlCopyExportSharedMethods.createSelectorConfig(
            controls,
            buttons
        );

        this.selectorConfig.extraToggles.push({
            prettyName: "Include D&I and Mois in export",
            propertyName: nameof<ControlExportData>((eff) => eff.includeExtraData),
        });

        this.tab.showLoader = false;
    }

    private async exportDataAndEvidenceClick(allItems: ControlExportData[], btn) {
        if (btn.disabled) {
            return;
        }

        btn.disabled = true;

        this._promptService.confirmCustom({
            maxWidth: "375px",
            data: {
                title: "Export started",
                message: "Closing this message will close the tab.",
                closeOnEsc: false,
                confirmButton: {
                    text: "Close",
                    action: () => {
                        this.tab.close(false);
                    },
                },
                cancelButton: {
                    show: false,
                },
            },
        });

        const exportModel = new AdvancedEffectivenessExportParametersModel();
        exportModel.items = this.getSelectedExportData(allItems);
        exportModel.userTimeZone = moment.tz.guess();
        await this._ds.exportDataAndEvidence(exportModel);
    }

    private exportDataForImport(allItems: ControlExportData[], btn) {
        if (this.exportRunning) {
            this.showExportRunningAlert();
            return;
        }
        const originalIcon = btn.icon;
        btn.icon = "fas fa-circle-notch fa-spin";
        btn.disabled = this.exportRunning = true;

        const selectedItems = this.getSelectedExportData(allItems);

        const controlGuids = selectedItems.map((i) => i.guid);
        const testplanGuids = selectedItems.flatMap((d) => d.effectivenessList.map((e) => e.guid));

        this._ds.exportDataForImport(controlGuids, testplanGuids).finally(() => {
            btn.disabled = this.exportRunning = false;
            btn.icon = originalIcon;
        });
    }

    private exportDataClick(allItems: ControlExportData[], btn) {
        if (this.exportRunning) {
            this.showExportRunningAlert();
            return;
        }

        btn.disabled = this.exportRunning = true;
        btn.icon = "fas fa-circle-notch fa-spin";

        const items = this.getSelectedExportData(allItems);
        this._ds.exportData(items).then(
            () => {
                btn.disabled = this.exportRunning = false;
                btn.icon = "fas fa-file-excel";
            },
            () => {
                btn.disabled = this.exportRunning = false;
                btn.icon = "fas fa-file-excel";
            }
        );
    }

    private getSelectedExportData(data: ControlExportData[]): ControlExportData[] {
        const selectedItems: ControlExportData[] = [];

        const copyData: ControlExportData[] = JSON.parse(JSON.stringify(data));
        copyData.forEach((control) => {
            control.effectivenessList = control.effectivenessList.filter((tp) => tp.selected);
            if (
                control.effectivenessList.length > 0 ||
                this.selectorConfig.extraToggles.some((et) => control[et.propertyName])
            ) {
                selectedItems.push(control);
            }
        });

        return selectedItems;
    }
}
