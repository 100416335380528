import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { toPromise } from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { ThirdPartyLinkModel } from "@models/thirdparty/ThirdPartyLinkModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { firstValueFrom, Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { AuditFindingReportSummaryModel } from "../shared/data-models/audit-finding-report-summary-model";
import { AuditLinkedFindingModel } from "../shared/data-models/audit-linked-finding-model";
import { AuditModel } from "../shared/data-models/audit-model";
import { AuditRiskAssessmentConfigurationModel } from "../shared/data-models/audit-risk-assessment-configuration-model";
import { AuditWorkspaceModel } from "../shared/data-models/audit-workspace-model";
import { AuditDetailPermissionModel } from "../shared/permission-models/audit-detail-permission-model";
import { IdNameCombination } from "./../../../common/models/generic/IdNameCombination";
import { CerrixTreeItem } from "./../../../common/models/tree/CerrixTreeItem";
import { AuditDetailStandingDataModel } from "./../shared/page-models/audit-detail-standing-data-model";
import { DocumentMethods } from "@methods/DocumentMethods";
import { AuditContextConfigModel } from "../shared/data-models/context/audit-context-config-model";

@Injectable()
export class AuditDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audit");
    }

    //#region Audit Permission methods

    hasAccess(guid: string): Observable<boolean> {
        if (isGuid(guid)) {
            const url = `/${guid}/hasaccess`;
            return this.get<boolean>(url);
        }

        return of(false);
    }

    getPermissions(guid?: string): Observable<AuditDetailPermissionModel> {
        let url = "/permissions";
        if (guid) {
            url = `/${guid}` + url;
        }

        return this.get<AuditDetailPermissionModel>(url);
    }

    //#endregion Audit Permission methods

    //#region Audit Data methods

    async getAudit(guid: string): Promise<AuditModel> {
        const url = `/${guid}`;
        return toPromise(this.get<AuditModel>(url));
    }

    public getList(): Promise<AuditWorkspaceModel[]> {
        return toPromise(this.get<AuditWorkspaceModel[]>());
    }

    storeAudit(auditModel: AuditModel): Observable<HttpEvent<string>> {
        const formData = DocumentMethods.convertToFormDataWithFiles("audit", auditModel);
        if (auditModel.documentRequests) {
            for (const documentRequest of auditModel.documentRequests) {
                if (documentRequest.Documents) {
                    for (const document of documentRequest.Documents) {
                        formData.append(document.Guid, document.File);
                    }
                }
            }
        }

        return this.request<string>(formData);
    }

    async getPlanningData() {
        const phases = ["Preparation Planning", "Fieldwork", "Reporting", "Draft Report", "Closed"];

        const data = [];
        for (let i = 1; i <= 5; i++) {
            const startDate = Date.UTC(
                2022 - this.randomNum(1),
                this.randomNum(12),
                this.randomNum(28)
            );

            const children = [];
            let totalTime = 0;
            for (let j = 1; j <= 5; j++) {
                const timeTaken = this.randomNum(5000, 250);
                const childStart = new Date(startDate).setHours(totalTime);
                const childEnd = new Date(childStart).setHours(timeTaken);
                totalTime += timeTaken;

                children.push({
                    id: `${i}-${j}`,
                    name: phases[j - 1],
                    start: childStart,
                    end: childEnd,
                    completed: (this.randomNum(5) * 20) / 100,
                    parent: `${i}`,
                    dependency: j > 1 ? `${i}-${j - 1}` : `${i}`,
                });
            }

            data.push({
                id: `${i}`,
                name: "Audit " + i,
                start: startDate,
                end: new Date(startDate).setHours(totalTime),
                completed: children.map((c) => c.completed).reduce((l, r) => l + r) / 5,
                collapsed: true,
            });

            data.push(...children);
        }

        const obs = of(data.sortBy("start", true)).pipe(delay(this.randomNum(5000, 1000)));
        return firstValueFrom(obs);
    }

    deleteAudit(guid: string): Observable<string[]> {
        const url = "/" + guid + "/delete";

        return this.delete(url);
    }

    exportAudit(guid: string) {
        const url = `/${guid}/export`;

        this.download(url);
    }

    //#endregion Audit Data methods

    //#region Support data

    getAuditStandingData(guid?: string) {
        let url = `/standingdata`;

        if (guid) {
            url = `/${guid}` + url;
        }

        return this.get<AuditDetailStandingDataModel>(url);
    }

    getAuditLinkedFindings(guid: string) {
        if (isGuid(guid)) {
            const url = `/${guid}/linkedfindings`;
            return this.get<AuditLinkedFindingModel[]>(url);
        }
        return of([]);
    }

    getLinkedFindingsMois(findingGuid: string) {
        const url = `/${findingGuid}/linkedfindingsmois`;
        return this.get<LinkedMoiModel[]>(url);
    }

    getAuditFindingReportSummary(guid: string): Observable<AuditFindingReportSummaryModel> {
        const url = `/${guid}/findingreportsummary`;
        return this.get<AuditFindingReportSummaryModel>(url);
    }

    getAuditContextConfig(guid: string): Observable<AuditContextConfigModel> {
        let url = "";
        if (isGuid(guid)) {
            url = `/${guid}/contextconfig`;
        } else {
            url = "/contextconfig";
        }

        return this.get<AuditContextConfigModel>(url);
    }

    getAuditRiskAssessmentData(guid: string): Observable<AuditRiskAssessmentConfigurationModel> {
        const url = `/${guid}/riskassessment`;
        return this.get<AuditRiskAssessmentConfigurationModel>(url);
    }

    getThirdParties(guid: string): Observable<ThirdPartyLinkModel[]> {
        const url = `/${guid}/thirdparty`;
        return this.get<ThirdPartyLinkModel[]>(url);
    }

    //#endregion Support data

    //#region History Data

    getHistory(guid: string | number, extraParam?: any): Observable<HistoryModel[]> {
        return this.get<HistoryModel[]>(`/${guid}/history`);
    }

    getHistoryChanges(
        guid: string | number,
        mutationId: string | number
    ): Observable<HistoryChange[]> {
        return this.get<HistoryChange[]>(`/${guid}/history/${mutationId}`);
    }

    public getHistoryLastDate(guid: string | number): Promise<Date> {
        return firstValueFrom(
            this.get<string>(`/${guid}/history/lastdate`).pipe(
                map((d: string): Date => new Date(d))
            )
        );
    }

    //#endregion History Data

    //#region Helper Methods

    private randomNum(max: number, min?: number) {
        const range = min ? max - min : max;

        return Math.floor(Math.random() * range) + (min ? min : 0);
    }

    private flattenTree(tree: CerrixTreeItem[]): IdNameCombination[] {
        const items: IdNameCombination[] = [];

        tree.forEach((x) => {
            items.push({
                ID: x.ID,
                Name: x.Name,
            });

            if (x.Children) {
                items.push(...this.flattenTree(x.Children));
                x.Children = null;
            }
        });

        return items;
    }

    //#endregion Helper Methods
}
