import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "fr-get-findingreport-ass-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class FrGetFindingReportAssDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Finding Report and Assessment Data API",
            httpMethod: "GET",
            path: "/findingreportdata/findingreportassessments",

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        "finding reports": [
                            {
                                "report Name": "string",
                                "report Type": "string",
                                "report No": "string",
                                "sub Name": "string",
                                "overall Score": "string",
                                "validation Type": "string",
                                "report Date": "datetime",
                                "report Due Date": "datetime",
                                "organization Responsible": "string",
                                responsible: "string",
                                "organization Reviewer": "string",
                                auditor: "string",
                                "scope and Objective": "string",
                                conclusion: "string",
                                "report URL": "string",
                            },
                        ],
                        assessments: [
                            {
                                "report Name": "string",
                                "report No": "string",
                                "assessment Type": "string",
                                "assessment Date": "datetime",
                                "assessment URL": "string",
                                "overall Score": "string",
                                "organization Reviewer": "string",
                                auditor: "string",
                                "comments Auditor": "string",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
