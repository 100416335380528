<div class="select-item-overlay" *ngIf="!loading && !row">
    <span>No item selected to edit.</span>
</div>
<div class="loading-item-overlay" *ngIf="loading">
    <div class="fancy-loader submodule"></div>
</div>

<ng-container *ngIf="row && !loading">
    <div class="detail-page-action-bar">
        <button class="btn btn-cerrix" (click)="save()">
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save</span>
        </button>

        <div class="right-area" *ngIf="row.ID && !config.disableDelete">
            <button class="btn btn-danger" (click)="delete()">
                <i class="fal fa-trash"></i>
                <span class="d-none d-lg-inline">Delete</span>
            </button>
        </div>
    </div>

    <ng-container *ngFor="let category of config.categories">
        <ng-container
            *ngTemplateOutlet="categoryRenderer; context: { category: category }"
        ></ng-container>
    </ng-container>
</ng-container>

<ng-template #categoryRenderer let-category="category">
    <ng-container *ngIf="!category.showInline">
        <div class="detail-page-card">
            <div class="detail-page-card-title">{{ category.categoryName }}</div>
            <div class="detail-page-card-actions" *ngIf="category.buttons">
                <button
                    *ngFor="let button of category.buttons"
                    (click)="executeCategoryButton(button)"
                    class="{{ button.class ? button.class : 'btn btn-raised' }}"
                >
                    <i [class]="button.icon" *ngIf="button.icon" aria-hidden="true"></i>
                    <span [ngClass]="{ 'd-none d-xl-inline ms-2': button.icon }">{{
                        button.name
                    }}</span>
                </button>
            </div>
            <div class="detail-page-card-body padded cerrix-control-sm">
                <ng-container
                    *ngTemplateOutlet="fieldRowRenderer; context: { fields: category.fields }"
                ></ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="category.showInline">
        <ng-container
            *ngTemplateOutlet="fieldRowRenderer; context: { fields: category.fields }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #fieldRowRenderer let-fields="fields">
    <div class="row">
        <ng-container *ngFor="let field of fields">
            <ng-container
                *ngTemplateOutlet="fieldRenderer; context: { field: field }"
            ></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #fieldRenderer let-field="field">
    <div
        class="generic-field-editor form-group col-{{
            field.editorWidth ? field.editorWidth : '12'
        }}"
        *ngIf="!field.hideInEdit"
    >
        <ng-container *ngIf="!(field.fieldType >= 0)">
            <ng-container
                *ngTemplateOutlet="categoryRenderer; context: { category: field }"
            ></ng-container>
        </ng-container>
        <ng-container *ngIf="field.fieldType >= 0">
            <generic-field-editor
                [row]="row"
                [field]="field"
                [editEnabled]="!field.isReadonly"
                [additionalData]="additionalData"
                [customEditor]="config.customEditor"
                (valueChanged)="genericFieldValueChanged($event)"
            ></generic-field-editor>
        </ng-container>
    </div>
</ng-template>
