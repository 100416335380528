import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class EventGetEventDataManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Event Data API",
            httpMethod: "GET",
            path: "/eventdata/events",

            parameters: [
                {
                    name: "includeDeleted",
                    in: "query",
                    paramType: "boolean",
                    required: false,
                    example: "true | false",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        events: [
                            {
                                identifier: "string",
                                "internal Identifier": "string",
                                "event name": "string",
                                "incident type(s)": "string",
                                classification: "string",
                                organization: "string",
                                "date occurred": "datetime",
                                "date detected": "datetime",
                                "date registered": "datetime",
                                "date closed": "datetime",
                                "event category": "string",
                                reporter: "string",
                                responsible: "string",
                                assessor: "string",
                                informed: "string",
                                "event description": "string",
                                "primary cause": "string",
                                "secondary cause": "string",
                                "cause description": "string",
                                "effect(s)": "string",
                                "effect description": "string",
                                "impact description": "string",
                                "reputation impact": "string",
                                "reputation impact description": "string",
                                "caused by organization": "string",
                                "affected organization": "string",
                                "gross costs": "integer",
                                recovery: "integer",
                                "net costs": "integer",
                                currency: "string",
                                comments: "string",
                                "risk opinion": "string",
                                "business dimension path(s)": "string",
                                "business dimension(s)": "string",
                                "business line": "string",
                                "completed by": "string",
                                status: "Approved and Closed",
                                "internal breach identifier": "string",
                                "external breach identifier": "string",
                                "data breach organization": "string",
                                "date breach detected": "datetime",
                                "date breach occurred": "datetime",
                                "date end of breach": "datetime",
                                "authorities notified": "string",
                                "authorities notified date": "datetime",
                                "data breach responsible(s)": "string",
                                "data breach assessor(s)": "string",
                                "type(s) of breach": "string",
                                "nature of breach": "string",
                                "nature of incident": "string",
                                "preventative Measures": "string",
                                "description of breach": "string",
                                "cause of breach": "string",
                                "personal data": "string",
                                "special categories of personal data": "string",
                                "name data processor": "string",
                                "outsourced data processing": "string",
                                "sub data processor": "string",
                                "minimum number of persons involved": "integer",
                                "maximum number of persons involved": "integer",
                                "description persons involved": "string",
                                "number of data registers involved": "integer",
                                "persons in other EU countries involved": "string",
                                "person(s) Involved": "string",
                                "consequences of personal data breach": "string",
                                "consequences to CIA of Data": "string",
                                "follow up description": "string",
                                "data breach comments": "string",
                                "persons involved notified": "string",
                                "explanation for no notification": "string",
                                "means Of Communication": "string",
                                "explanation notification persons involved": "string",
                                "nr Of Persons Notified": 0,
                                "reason no notification": "string",
                                "date persons involved notified": "datetime",
                                "secondary notification": "string",
                                "secondary notification description": "string",
                                "data protection officer": "string",
                                "international authorities notified": "string",
                                "name of international authorities": "string",
                                "which EU Country": "string",
                                "crossborder Processing": "string",
                                "chamber Of Commerce Registration Nr": "string",
                                "name of the Organization": "string",
                                address: "string",
                                zipCode: "string",
                                city: "string",
                                "active In Which Sector": "string",
                                "moI Identifier": "string",
                                "moI Name": "string",
                                subject: "string",
                                priority: "string",
                                feasibility: "string",
                                progress: "string",
                                "organization responsible": "string",
                                "moI responsible": "string",
                                delegate: "string",
                                auditor: "string",
                                "finding description": "string",
                                "start date": "datetime",
                                "moI date created": "datetime",
                                "due date": "datetime",
                                "initial due date": "datetime",
                                "implementation score": "string",
                                recommendation: "string",
                                "management response": "string",
                                "last comment": "string",
                                "comment date": "datetime",
                                "organization path": "string",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
