import { GenericListField } from "./GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { Observable } from "rxjs";

export class GenericStandingDataConfig {
    overviewType: StandingDataOverviewType;
    additionalConfig?: any;

    categories: StandingDataCategory[];

    parentProp?: string;
    disableAdd?: boolean;
    disableDelete?: boolean;
    disableSort?: boolean;

    sortMessageSubtitle?: string;
    onAdd?(parent: any): any;
    getAdditionalDeleteMessage?: (row: any) => string;
    getBeforeSavePromptMessage?: (
        row: any,
        originalRow: any,
        settingsService: SettingsDataService
    ) => Promise<string>;

    /** Can be used to use a custom editor component instead of the generic list.
     * Requires custom implementation. */
    customEditor?: boolean;
}

export class StandingDataCategory {
    categoryName?: string;
    editorWidth?: number;
    showInline?: boolean;

    buttons?: StandingDataCategoryButton[];
    fields: (StandingDataField | StandingDataCategory)[];
}

export class StandingDataCategoryButton {
    name: string;
    icon?: string;
    class?: string;
    requiresReload?: boolean;

    action: (row: any) => Promise<boolean>;
}

export class StandingDataField extends GenericListField {
    excludeInExport?: boolean;
    /** This overrides getDataMethod and getDataMethodName. */
    getExportDataMethod?(ds: StandingdataDataService): Observable<any>;

    customFieldValidation?(): string[];
}

export enum StandingDataOverviewType {
    List,
    Tree,
}
