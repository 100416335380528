import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { FindingReportOverviewComponent, FindingReportDetailsComponent } from "./pages";
import { FindingReportDetailsMainComponent } from "./pages/finding-report-details/finding-report-details-main/finding-report-details-main.component";
import { FindingReportDetailsDocumentsComponent } from "./pages/finding-report-details/finding-report-details-documents/finding-report-details-documents.component";
import { AssessmentSharedModule } from "@app/assessment/shared/assessment-shared.module";
import { FindingReportsFindingsComponent } from "./pages/finding-report-details/finding-report-findings/finding-report-findings.component";

@NgModule({
    imports: [CommonModule, SharedModule, AssessmentSharedModule],
    declarations: [
        FindingReportOverviewComponent,
        FindingReportDetailsComponent,
        FindingReportDetailsMainComponent,
        FindingReportDetailsDocumentsComponent,
        FindingReportsFindingsComponent,
    ],
})
export class FindingReportModule {}
