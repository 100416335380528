import { Injectable } from "@angular/core";

@Injectable()
export class Pages {
    public Dashboard: string = "Dashboard";

    public Risks: string = "Risks";
    public RiskOverview: string = this.Risks;
    public Risk: string = "Risk Detail";
    public RiskDetail: string = this.Risk;
    public NewRisk: string = this.Risk;

    public Controls: string = "Controls";
    public ControlOverview: string = this.Controls;
    public Control: string = "Control Detail";
    public ControlDetail: string = this.Control;
    public ExportEffectiveness: string = "Export Effectiveness";
    public CopyEffectiveness: string = "Copy Effectiveness";
    public SharedControlOverview: string = "Shared Control";
    public ControlExecutionOverview: string = "Control Execution Overview";
    public ControlExecutionDetail: string = "Control Execution Detail";
    public ControlExecutionTaskSeriesDetail: string = "Control Execution Task Series Detail";

    public ControlsTestingAdvEffOverview: string = "Advanced Testing Test Plans";
    // Field name is used for redirection on server side
    public ControlsTestingAdvEffDetail: string = "Advanced Testing Test Plan";
    public ControlsTestingAdvEffControlOverview: string = "Advanced Testing Control Overview";
    public TaskSeriesDetail: string = "Task Series Detail";

    // These two are as fallback for first line screen urls
    public EffectivenessSourceDocument: string = this.ControlsTestingAdvEffDetail;
    public EffectivenessEvidence: string = this.ControlsTestingAdvEffDetail;

    public EffectivenessTesting: string = "Control Simple Eff Detail";
    public EffTesting: string = this.EffectivenessTesting;
    public DiTesting: string = "Control Simple DI Detail";

    public Mois: string = "Measures of Improvement";
    public MoiOverview: string = this.Mois;
    public MoiDetail: string = "Moi Detail";
    public Moi: string = this.MoiDetail;

    public Kris: string = "Key Risk Indicators";
    public KriOverview: string = this.Kris;
    public Kri: string = "Kri Detail";
    public KriDetail: string = this.Kri;
    public KriUploadData: string = "Kri Upload Data";
    public KriDataGraphOverview: string = "Kri Graphs";

    public Events: string = "Events";
    public EventOverview: string = this.Events;
    public Event: string = "Event Detail";
    public EventDetail: string = this.Event;

    public Incidents: string = "Incidents";
    public IncidentOverview: string = this.Incidents;
    public Incident: string = "Incident Details";
    public IncidentDetail: string = this.Incident;

    public DataManagementOverview: string = "Data Management";
    public DataManagement: string = "Data Management Detail";
    public ManageDatastructures: string = "Datastucture Management";

    // Third party
    public Thirdparty: string = "Third Party";
    public ThirdpartyDetail: string = "Third Party Detail";
    public ThirdpartyContractDetail: string = "Third Party Contract Detail";

    public Reports: string = "Reports";
    public ReportOverview: string = this.Reports;
    public Report: string = "Report Detail";
    public ReportDetail: string = this.Report;
    public PowerBIReport: string = this.Report;

    public Exports: string = "Exports";
    public ExportOverview: string = this.Exports;

    public Profile: string = "Profile";
    public ChangePassword: string = this.Profile;

    public SchedulerStatus: string = "Scheduler Status";

    public Settings: string = "Settings";
    public About: string = "About";

    public Invite: string = "Invite";

    // region standing data
    public StandingData: string = "Standing Data";
    public StandingDataOverview: string = this.StandingData;

    public ImOverview: string = "Finding Reports";
    public ImDetail: string = "Findings Report Detail";

    public AssessmentDetail: string = "Assessment Detail";

    // Navigator
    public NavProcessOverview: string = "Navigator - Process Overview";
    public NavProcessEditor: string = "Navigator - Process Editor";

    public NavBusinessDimension: string = "Navigator - Business Dimensions";
    public BusinessNavigator: string = this.NavBusinessDimension;
    public BusinessAssessment: string = "Business Assessment";

    public FrameworkNavigator: string = "Framework Navigator";
    public FrameworkAssessment: string = "Framework Assessment";

    // Admin
    public MailMessages: string = "Mail messages";
    public MailMessagesDetail: string = "Mail messages detail";
    public NotificationDetail: string = "Notification detail";
    public PeriodicDetail: string = "Periodic detail";

    public Periods: string = "Periods";
    public DeletedItems: string = "Deleted items";

    public UserManagement: string = "User management";
    public UserDetail: string = "User detail";
    public UserRoleGroup: string = "User role group";

    // External Connections
    public ExternalConnections: string = "External connections";
    public TpaRequests: string = "Third Party Assurance requests";
    public TpaRequestDetail: string = "Assurance requests";

    // Forms
    public Forms: string = "Forms";
    public FormResults: string = "Form Results";
    public FormOverview: string = this.FormResults;
    public FormStatus: string = "Form Status";
    public FormDetail: string = "Form Detail";
    public FormEntry: string = "Form Entry";
    public FormHistory: string = "Form History";
    public FormInvite: string = "Form Invite";

    // History
    public HistoryComp: string = "HistoryComp";

    // Documents
    public AdminDocuments: string = "Admin Documents";
    public SharedDocuments: string = "Shared Documents";
    public BulkDeletedDocuments = "Bulk Deleted Documents";
    public Documents: string = this.AdminDocuments;
    public DocumentTemplates = "Document Templates";

    // Calendar
    public Calendar: string = "Calendar";
    public CalendarSettings: string = "Calendar Settings";

    // Audit
    public AuditUniverseOverview: string = "Audit Universe Workspace";

    public AuditUniverse: string = "Audit Universe Detail";
    public AuditUniverseDetail: string = this.AuditUniverse;

    public AuditUniversePeriod: string = "Audit Universe Period";
    public AuditUniversePeriodDetail: string = "Audit Universe Period Detail";

    public AuditUniversePeriodPlanningWidget: string = "Audit Universe Period Planning Widget";

    public AuditOverview: string = "Audit Workspace";

    public Audit: string = "Audit Detail";
    public AuditDetail: string = this.Audit;
    public AuditFieldworkStep: string = "Audit Fieldwork Step";

    public AuditPlanning: string = "Audit Planning";

    public AuditFinding: string = "Audit Finding";
    public AuditFindingDetail: string = "Audit Finding Detail";

    // Task
    public TaskOverview: string = "Tasks";
    public TaskDetail: string = "Task Detail";

    // Admin
    public DevManagement: string = "Dev Management";

    //#region Standing Data pages

    // SD General
    public OrganizationSD: string = "Organization Standing Data";
    public IpWhitelistingSD: string = "IP Whitelisting Standing Data";

    // SD Data management
    public BasisSD: string = "Basis Standing Data";
    public SourceDocumentsSD: string = "Source Documents Standing Data";
    public TransferSafeguardSD: string = "Transfer Safeguard Standing Data";
    public RemovalPolictySD: string = "Removal Policty Standing Data";
    public SensitivityOfProcessingSD: string = "Sensitivity of Processing Standing Data";

    // SD Event
    public ReasonNoNotificationSD: string = "Reason No Notification Standing Data";
    public ActiveInWhichSectorSD: string = "Active In Which Sector Standing Data";
    public NatureOfBreachSD: string = "Nature Of Breach Standing Data";
    public PersonsInvolvedSD: string = "Persons Involved Standing Data";
    public ConsequencesToCiaOfDataSD: string = "Consequences To Cia Of Data Standing Data";
    public NatureOfIncidentSD: string = "Nature of Incident Standing Data";
    public ConsequenceOfBreachSD: string = "Consequence of Breach Standing Data";
    public PersonalDataTypesSD: string = "Personal Data Types Standing Data";
    public TypesOfBreachSD: string = "Types of Breach Standing Data";
    public BusinessLineSD: string = "Business Line Standing Data";
    public FailureTypeSD: string = "Failure Type Standing Data";
    public ClassificationIncidentSD: string = "Classification Incident Standing Data";
    public EffectSD: string = "Effect Standing Data";
    public IncidentTypeSD: string = "Incident Type Standing Data";
    public ReputationImpactSD: string = "Reputation Impact Standing Data";
    public EventCategorySD: string = "Event Category Standing Data";
    public SpecialCategoriesOfPersonalDataSD: string =
        "Special Categories of Personal Data Standing Data";

    // SD Forms
    public FormCategorySD: string = "Form Category Standing Data";
    public FormGroupSD: string = "Form Groups Standing Data";

    // SD Control
    public ControlSourceSD: string = "Control Source Standing Data";
    public ControlTypeSD: string = "Control Type Standing Data";
    public ControlAspectIcSD: string = "Aspect IC Standing Data";
    public ControlFunctionSD: string = "Function Standing Data";
    public ControlExecutionMethodSD: string = "Control Execution Method Standing Data";
    public ControlLinkedStandardSD: string = "Linked Standard Standing Data";
    public ControlQualityAspectSD: string = "Quality Aspect Standing Data";

    // SD Bim
    public BimValidationType: string = "Validation Type Standing Data";
    public BimAssessmentType: string = "Assessment Type Standing Data";
    public BimSubject: string = "Subject Standing Data";
    public BimFeasibility: string = "Feasibility Standing Data";
    public BimPriority: string = "Priority Standing Data";
    public BimImplementationScore: string = "Implementation Score Standing Data";
    public BimReportType: string = "Report Type Standing Data";

    // SD Risk
    public RiskArea: string = "Risk Area Standing Data";
    public RiskCatalogue: string = "Risk Catalogue Standing Data";
    public RiskAssessment: string = "Risk Assessment Standing Data";
    public RiskSubtype: string = "Risk Subtype Standing Data";
    public RiskCustomField: string = "Risk Custom Field Standing Data";

    public RiskTreatment: string = "Risk Treatment Standing Data";
    public RiskLikelihood: string = "Risk Likelihood Standing Data";
    public RiskImpact: string = "Risk Impact Standing Data";

    //#endregion

    public Manuals: string = "Manuals";
    public ReleaseNotes: string = "Release Notes";

    public Wopi: string = "Wopi";
    public WopiDebug: string = "Wopi Debug";

    public ProcessedDocumentOverview: string = "Processed Document Overview";
    public ProcessedDocumentDetail: string = "Processed Document Detail";

    public OrganizationBudgets: string = "Organizational Budget";

    getExactName(name: string): string {
        const names = Object.getOwnPropertyNames(this);
        const targetName = name.toLowerCase().replace(/\s/g, "").replace(/%20/g, "");

        const found = names.filter((n) => n.toLowerCase().replace(/\s/g, "") === targetName)[0];

        return this[found];
    }

    getReversed(value: string): string {
        const allKeys = Object.getOwnPropertyNames(this);
        const key = allKeys.find((k) => this[k].toLowerCase() === value.toLowerCase());
        return key;
    }
}
