import { Component, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { WopiDebugDataService } from "../../services/wopi-debug-data.service";
import { WopiDiscoveryModel } from "../../models/discovery/wopi-discovery-model";
import { GenericGroupedListConfig } from "@app/shared/models/GenericList/GenericGroupedListConfig";
import { WopiDiscoveryAppActionOverviewModel } from "../../models/discovery/wopi-discovery-app-action-overview-model";
import { nameof } from "@methods/jeffs-toolkit";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "discovery-info",
    templateUrl: "./discovery-info.component.html",
    styleUrls: ["./discovery-info.component.scss"],
})
export class DiscoveryInfoComponent implements OnInit {
    currentLockConfig: GenericListConfig;
    lockLogConfig: GenericListConfig;
    isEnabled = false;
    isLoading = true;

    documentGuid: string;
    startDate: Date;
    endDate: Date;

    discoveryModel: WopiDiscoveryModel;

    overviewConfig: GenericListConfig;

    constructor(private _ds: WopiDebugDataService, private _toastr: ToastrService) {}

    async ngOnInit() {
        await this.init();
    }

    async init() {
        this.discoveryModel = await this._ds.getDiscoveryInfo();
        let models = this.prepModels(this.discoveryModel);
        this.overviewConfig = this.createOverviewConfig(models);

        this.isLoading = false;
    }

    private prepModels(discoveryModel: WopiDiscoveryModel): WopiDiscoveryAppActionOverviewModel[] {
        let result: WopiDiscoveryAppActionOverviewModel[] = [];

        let appCounter = 1;
        let actionCounter = 1;
        discoveryModel.NetZone.Apps.forEach((app) => {
            app.Actions.forEach((action) => {
                let newItem: WopiDiscoveryAppActionOverviewModel = {
                    id: actionCounter++,
                    groupId: appCounter,
                    groupName: app.Name,
                    IsDefault: action.IsDefault,
                    Extension: action.Extension,
                    AllowedAction: action.AllowedAction,
                    UrlSource: action.UrlSource,
                    Requires: action.Requires,
                };
                result.push(newItem);
            });

            appCounter++;
        });

        return result;
    }

    createOverviewConfig(data: WopiDiscoveryAppActionOverviewModel[]): GenericGroupedListConfig {
        let config = <GenericGroupedListConfig>{
            name: "App Actions",
            data: data,

            groupByProp: nameof<WopiDiscoveryAppActionOverviewModel>((eff) => eff.groupId),
            groupByDisplayName: nameof<WopiDiscoveryAppActionOverviewModel>((eff) => eff.groupName),
            overviewSortBy: nameof<WopiDiscoveryAppActionOverviewModel>((eff) => eff.id),
            overviewSortAsc: true,

            idProp: nameof<WopiDiscoveryAppActionOverviewModel>((eff) => eff.id),

            allowAdd: false,
            allowEdit: false,
            allowDelete: false,

            customModalClass: "modal-xxl",
            hideEmptyResult: false,

            fields: [
                {
                    fieldName: "Extension",
                    prettyName: "Extension",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },

                {
                    fieldName: "AllowedAction",
                    prettyName: "Allowed Action",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Requires",
                    prettyName: "Requires",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "UrlSource",
                    prettyName: "Url Source",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                },
            ],

            cellConfig: {
                Extension: {
                    alignLeft: true,
                },
            },

            cellSummaries: {
                Extension: {
                    cellStringFormat: "[embedGroupName] ([embedCount] Actions)",
                    embedGroupName: true,
                    embedCount: true,
                },
            },
        };

        return config;
    }

    private async clearDiscoveryCache() {
        await this._ds.clearDiscoveryCache();
        this._toastr.success("Discovery cache cleared");
    }
}
