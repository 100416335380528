<cerrix-loader [cerrixTab]="tab"></cerrix-loader>
<generic-list-manager [config]="config" *ngIf="config"></generic-list-manager>

<ng-template #roleGroupInfo>
    <div class="fancy-loader" *ngIf="!details"></div>
    <div class="basic-details" *ngIf="details">
        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Name"
                [(ngModel)]="details.name"
                placeholder="Required"
            ></cerrix-textfield>
        </div>
        <div class="form-group">
            <label>Description</label>
            <cerrix-textarea [(ngModel)]="details.description"></cerrix-textarea>
        </div>
    </div>
</ng-template>

<ng-template #selectedRoles>
    <role-assign
        [moduleRoles]="details.moduleRoles"
        [allowChange]="true"
        *ngIf="details"
    ></role-assign>
</ng-template>

<ng-template #selectedUsers>
    <div class="detail-page-action-bar">
        <div class="right-area">
            <button (click)="toggleAll(true)" class="btn btn-dark">
                <i class="fas fa-check-square"></i>
                <span class="ms-2">Select all</span>
            </button>

            <button (click)="toggleAll(false)" class="btn btn-dark">
                <i class="fas fa-square"></i>
                <span class="ms-2">Deselect all</span>
            </button>
        </div>
    </div>

    <input
        class="form-control form-control-sm mb-2"
        placeholder="Quick search / filter users"
        [(ngModel)]="userFilter"
        (debounce-input-event)="filterUsers()"
    />
    <span *ngIf="userFilterNoResults">There are no users found matching search criteria.</span>
    <div class="user-select" *ngIf="details">
        <ng-container *ngFor="let user of details.detailedUsers">
            <div
                class="user-card text-truncate"
                [hidden]="user._hidden"
                [title]="user.lastname + ', ' + user.firstname"
            >
                <cerrix-checkbox
                    [label]="user.lastname + ', ' + user.firstname"
                    [(value)]="user.checked"
                    fontweight="normal"
                ></cerrix-checkbox>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #selectedDashboards>
    <div class="dashboard-select row" *ngIf="details">
        <ng-container *ngFor="let dashboard of details.dashboards">
            <div class="dashboard-item col-4">
                <div
                    class="list-group-item list-group-item-action"
                    [class.active]="dashboard.checked"
                    (click)="dashboard.checked = !dashboard.checked"
                >
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ dashboard.name }}</h5>
                        <small>{{ dashboard.updatedOn | dateTimeFormat }}</small>
                    </div>
                    <p class="mb-1">
                        {{ dashboard.createdBy }}
                    </p>
                    <div class="text-truncate" [matTooltip]="dashboard.description">
                        <small>{{ dashboard.description }}</small>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #summary>
    <div class="summary" *ngIf="details">
        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Name"
                [(ngModel)]="details.name"
                [readonly]="true"
            ></cerrix-textfield>
            <small class="text-danger" *ngIf="!details.name">Name is required!</small>
        </div>
        <div class="form-group">
            <label>Description</label>
            <cerrix-textarea [(ngModel)]="details.description" [readonly]="true"></cerrix-textarea>
        </div>
        <div class="form-group">
            <label>Selected roles</label>
            <div class="selected-tags">
                <ng-container *ngFor="let module of details.moduleRoles">
                    <ng-container *ngFor="let role of module.roles">
                        <span class="badge bg-primary" *ngIf="role.checked">{{
                            role.roleName
                        }}</span>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="form-group">
            <label>Selected users</label>
            <div class="selected-tags">
                <ng-container *ngFor="let user of details.detailedUsers">
                    <span class="badge bg-primary" *ngIf="user.checked">{{
                        user.lastname + ", " + user.firstname
                    }}</span>
                </ng-container>
            </div>
        </div>
        <div class="form-group">
            <label>Selected dashboards</label>
            <div class="selected-tags">
                <ng-container *ngFor="let dashboard of details.dashboards">
                    <span class="badge bg-primary" *ngIf="dashboard.checked">{{
                        dashboard.name
                    }}</span>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<cerrix-wizard
    [config]="wizardConfig"
    *ngIf="wizardConfig"
    #wizard
    [templates]="[roleGroupInfo, selectedRoles, selectedUsers, selectedDashboards, summary]"
>
</cerrix-wizard>
