import { NgModule, ModuleWithProviders } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import { ControlDetailComponent } from "./detail/control-detail.component";
import { ControlDatamanagementComponent } from "./detail/control-datamanagement/control-datamanagement.component";
import { ControlThirdPartyComponent } from "./detail/control-thirdparty/control-thirdparty.component";
import { ControlEventComponent } from "./detail/control-events/control-events.component";
import { ControlRiskComponent } from "./detail/control-risks/control-risks.component";
import { ControlMoiComponent } from "./detail/control-mois/control-mois.component";
import { ControlDetailMainComponent } from "./detail/main/control-detail-main.component";
import { ControlTestResultComponent } from "./detail/test-result/control-test-result.component";
import { ControlTestscoreChartComponent } from "./detail/test-result/control-testscore-chart/control-testscore-chart.component";
import { ControlAdvEffChartComponent } from "./detail/test-result/control-adv-eff-chart/control-adv-eff-chart.component";

import { ControlDetailOverviewComponent } from "./detail-overview/control-detail-overview.component";

import { ControlCopyEffectivenessComponent } from "./copy-effectiveness/control-copy-effectiveness.component";
import { ControlExportEffectivenessComponent } from "./export-effectiveness/control-export-effectiveness.component";
import { ControlOverviewComponent } from "./overview/control-overview.component";

import { ControlDataService } from "./services/control.data.service";
import { ControlChartDataService } from "./detail/test-result/control-chart.data.service";

import { ControlCopyEffectivenessDataService } from "./copy-effectiveness/control-copy-effectiveness.data.service";
import { ExportEffectivenessDataService } from "./export-effectiveness/control-export-effectiveness.data.service";
import { ControlDetailMainDataService } from "@app/controls/detail/main/control-detail-main.data.service";
import { ControlDetailOverviewDataService } from "@app/controls/detail-overview/control-detail-overview.data.service";
import { SharedControlOverview } from "./overview/shared-controls/shared-control-overview";
import { ControlFormsComponent } from "./detail/control-forms/control-forms.component";
import { ControlExecutionOverviewComponent } from "./control-execution/control-execution-overview/control-execution-overview.component";
import { ControlExecutionDataService } from "./control-execution/services/control-execution-data.service";

@NgModule({
    declarations: [
        ControlDetailComponent,
        ControlOverviewComponent,
        ControlDetailOverviewComponent,
        ControlExportEffectivenessComponent,
        ControlDetailMainComponent,
        ControlRiskComponent,
        ControlMoiComponent,
        ControlEventComponent,
        ControlCopyEffectivenessComponent,
        ControlDatamanagementComponent,
        ControlTestResultComponent,
        ControlTestscoreChartComponent,
        ControlAdvEffChartComponent,
        ControlThirdPartyComponent,
        ControlFormsComponent,
        SharedControlOverview,
        ControlExecutionOverviewComponent,
    ],
    exports: [ControlDetailOverviewComponent],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class ControlModule {
    static forRoot(): ModuleWithProviders<ControlModule> {
        return {
            ngModule: ControlModule,
            providers: [
                ControlDataService,
                ExportEffectivenessDataService,
                ControlCopyEffectivenessDataService,
                ControlChartDataService,
                ControlDetailMainDataService,
                ControlDetailOverviewDataService,
                ControlExecutionDataService,
            ],
        };
    }
}
