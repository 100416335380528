import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { Configuration } from "../../app.constants";
import { AuditDocumentRequestModel } from "../shared/data-models/audit-document-request-model";
import { addParameterToUrl } from "@methods/CommonMethods";

@Injectable()
export class AuditDocumentRequestDataService extends ApiDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audit/documentrequest");
    }

    deleteRequest(auditGuid: string, requestGuid: string): Observable<never> {
        let url = `/${auditGuid}/${requestGuid}`;
        return this.delete(url);
    }

    completeRequest(auditGuid: string, requestGuid: string): Observable<AuditDocumentRequestModel> {
        let url = `/complete/${auditGuid}/${requestGuid}`;
        return this.post(null, url);
    }

    getDocumentRequests(auditGuid: string): Observable<AuditDocumentRequestModel[]> {
        let url = `/${auditGuid}`;
        return this.get<AuditDocumentRequestModel[]>(url);
    }

    requestDocuments(
        auditGuid: string,
        requestGuid: string
    ): Observable<AuditDocumentRequestModel> {
        let url = `/requestdocuments/${auditGuid}/${requestGuid}`;
        return this.post(null, url);
    }

    getAuditees(selected: number) {
        let url = `/auditees`;

        if (selected) {
            url = addParameterToUrl(url, "selectedIds", selected);
        }

        return this.get<any[]>(url);
    }

    getDelegates(selected: number[]) {
        let url = `/delegates`;

        if (selected) {
            url = addParameterToUrl(url, "selectedIds", selected);
        }
        return this.get<any[]>(url);
    }
}
