import { Component, Input, OnInit } from '@angular/core';
import { FormField, FormFieldConfig } from '@app/forms/models';
import { parseTyped } from '@methods/CommonMethods';

@Component({
    selector: "form-detail-date",
    templateUrl: "./form-detail-date.component.html",
    styleUrls: ["../../form-detail-field.component.scss"]
})
export class FormDetailDateComponent implements OnInit {
    @Input() field: FormField;
    dateSettings: FormFieldConfig;

    showTimePickerLocked = false;

    ngOnInit() {
        this.dateSettings = parseTyped<FormFieldConfig>(this.field.CustomSettings, new FormFieldConfig());
    }

    updateAnswer() {
        this.field.CustomSettings = JSON.stringify(this.dateSettings);
    }

    updateConfig(isMapbackConfig: boolean, config?: FormFieldConfig) {
        this.showTimePickerLocked = false;

        if (!this.dateSettings) {
            this.dateSettings = new FormFieldConfig();
        }

        if (isMapbackConfig && config) {
            if (config.allowTimeInput === true) {
                this.showTimePickerLocked = true;
                this.dateSettings.allowTimeInput = true;
            }
        }

        this.updateAnswer();
    }
}