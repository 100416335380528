import { ComparisonOperator } from "../../app/preset/models/comparison-operator.enum";

export const comparisonOperatorsTranslation: Map<ComparisonOperator, string> = new Map([
    [ComparisonOperator.Equal, "Equal"],
    [ComparisonOperator.NotEqual, "Not equal"],
    [ComparisonOperator.LessThan, "Less than"],
    [ComparisonOperator.LessThanOrEqual, "Less than or equal"],
    [ComparisonOperator.GreaterThan, "Greater than"],
    [ComparisonOperator.GreaterThanOrEqual, "Greater than or equal"],
    [ComparisonOperator.StartsWith, "Starts with"],
    [ComparisonOperator.EndsWith, "Ends with"],
    [ComparisonOperator.Contains, "Contains"],
    [ComparisonOperator.NotContains, "Not contains"],
]);
