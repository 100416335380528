import { Component, EventEmitter } from "@angular/core";
import { FormPage } from "@app/forms/models/detail";
import { Options } from "sortablejs";
import { CustomSortableOptions } from "@methods/PageMethods";
import { FormPageType } from "@app/forms/enums/FormPageType";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { OnInit } from "@angular/core";

@Component({
    selector: "form-page-settings",
    templateUrl: "./form-page-settings.component.html",
    styleUrls: ["./form-page-settings.component.scss"],
})
export class FormPageSettingsComponent implements OnInit {
    saveChanges = new EventEmitter<FormPage[]>();
    pages: FormPage[];
    sortingOptions: Options;
    isWorkflow: boolean;

    lockedFirstPageID: number;

    constructor(private _promptService: CerrixPromptService) {}

    ngOnInit() {
        if (this.pages.length > 0 && this.pages[0].ID > 0) {
            this.lockedFirstPageID = this.pages[0].ID;
        }
        this.sortingOptions = CustomSortableOptions({
            handle: ".mat-expansion-panel-header",
        });
    }

    async onSaveChanges() {
        if (this.lockedFirstPageID > 0 && this.pages[0].ID != this.lockedFirstPageID) {
            this._promptService.alert({
                data: {
                    title: "First page cannot be changed!",
                    message:
                        "Form has been released already, so the released first page must remain the first page!",
                },
            });
            return;
        }

        this.pages.forEach((p) => {
            p.Type = +p.Type; // somehow it sets it as a string.
        });

        if (this.pages.some((x) => x.Type === FormPageType.Review && x.Fields.any())) {
            if (
                !(await this._promptService
                    .confirm(
                        "Page type changed",
                        "Existing fields will be removed from 'Review Pages'. Are you sure you want to continue?"
                    )
                    .toPromise())
            ) {
                return;
            }
        }

        this.saveChanges.emit(this.pages);
    }

    // If new objects get added to FormPages, the ID should be reset here, before adding a copy of the selected item.
    duplicate(index: number) {
        const pageToDupe = JSON.parse(JSON.stringify(this.pages[index])) as FormPage;
        pageToDupe.Title += " (copy)";
        pageToDupe.ID = this.pages.getLowerIDByProp("ID", 1);
        if (pageToDupe.ID >= 0) {
            pageToDupe.ID = -1;
        }

        // Reset field IDS
        let lowestPageID = -1;
        this.pages.forEach((p) => {
            const newFieldID = p.Fields.getLowerIDByProp("ID");
            if (newFieldID < lowestPageID) {
                lowestPageID = newFieldID;
            }
        });

        pageToDupe.Fields.forEach((f, i) => {
            f.ID = lowestPageID - i;
            f.PageID = pageToDupe.ID;
        });

        pageToDupe.WorkflowPageDefinitions = [];
        this.pages.splice(index + 1, 0, pageToDupe);
    }
}
