import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "./../shared/shared.module";
import { AuditUniverseDetailComponent } from "./pages/audit-universe-detail/audit-universe-detail.component";
import { AuditUniverseDataComponent } from "./pages/audit-universe-detail/components/audit-universe-data/audit-universe-data.component";
import { AuditUniverseDetailMainComponent } from "./pages/audit-universe-detail/components/audit-universe-detail-main/audit-universe-detail-main.component";
import { AuditUniverseLinkedAuditsComponent } from "./pages/audit-universe-detail/components/audit-universe-linked-audits/audit-universe-linked-audits.component";
import { AuditUniverseLinkedFormsComponent } from "./pages/audit-universe-detail/components/audit-universe-linked-forms/audit-universe-linked-forms.component";
import { AuditUniverseOverviewComponent } from "./pages/audit-universe-overview/audit-universe-overview.component";
import { AuditUniversePlanningComponent } from "./pages/audit-universe-planning/audit-universe-planning.component";
import { AuditUniverseDataService } from "./services/audit-universe-data.service";
import { AuditUniverseModuleDataService } from "./services/audit-universe-module-data.service";

@NgModule({
    imports: [CommonModule, SharedModule.forRoot()],
    declarations: [
        AuditUniverseOverviewComponent,
        AuditUniverseDetailComponent,
        AuditUniversePlanningComponent,

        AuditUniverseDetailMainComponent,
        AuditUniverseDataComponent,

        AuditUniverseLinkedAuditsComponent,
        AuditUniverseLinkedFormsComponent,
    ],
})
export class AuditUniverseModule {
    static forRoot(): ModuleWithProviders<AuditUniverseModule> {
        return {
            ngModule: AuditUniverseModule,
            providers: [AuditUniverseDataService, AuditUniverseModuleDataService],
        };
    }
}
