import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeletedItemsComponent } from "./deleted-items.component";
import { SharedModule } from "@app/shared/shared.module";
import { FormsModule } from "@angular/forms";
import { DeletedItemsService } from "./shared/deleted-items.service";

@NgModule({
    imports: [FormsModule, CommonModule, SharedModule],
    declarations: [DeletedItemsComponent],
})
export class DeletedItemsModule {
    static forRoot(): ModuleWithProviders<DeletedItemsModule> {
        return {
            ngModule: DeletedItemsModule,
            providers: [DeletedItemsService],
        };
    }
}
