import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { WopiDebugDataService } from "../../services/wopi-debug-data.service";
import { WopiProofKeyInfo } from "../../models/wopi-proof-key-info-model";
import { map, Observable } from "rxjs";

@Component({
    selector: "proof-key-info",
    templateUrl: "./proof-key-info.component.html",
    styleUrls: ["./proof-key-info.component.scss"],
})
export class ProofKeyInfoComponent implements OnInit {
    config: GenericListConfig;
    isEnabled = false;

    documentGuid: string;
    startDate: Date;
    endDate: Date;

    constructor(private _ds: WopiDebugDataService, private _toastr: ToastrService) {}

    ngOnInit() {
        this.createListConfig();
    }

    private getData(): Observable<WopiProofKeyInfo[]> {
        var observable = this._ds.getProofKeyInfo(null, null, null);
        var modeled = observable.pipe(
            map((data) => {
                let idCounter = 1;
                data.forEach((i) => {
                    try {
                        i.data = JSON.parse(i.jsonData);
                        i.data.id = idCounter++;
                        i.data.logDate = i.logDate;
                    } catch {}
                });
                return data.map((d) => d.data);
            })
        );

        return modeled;
    }

    private createListConfig() {
        this.config = <GenericListConfig>{
            name: "Proof Key Logs",
            idProp: "id",
            dataMethod: () => this.getData(),
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            customModalClass: "modal-xxl",
            hideEmptyResult: false,
            fields: [
                {
                    fieldName: "logDate",
                    prettyName: "Date",
                    required: true,
                    fieldType: GenericListFieldType.DateTime,
                    hideInOverview: false,
                },
                {
                    fieldName: "SuccessfulValidation",
                    prettyName: "SuccessfulValidation",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },

                {
                    fieldName: "IsLittleEndian",
                    prettyName: "Is Little Endian",
                    required: true,
                    fieldType: GenericListFieldType.CheckBox,
                },

                {
                    fieldName: "AccessTokenLength",
                    prettyName: "AccessTokenLength",
                    required: false,
                    fieldType: GenericListFieldType.Number,
                    hideInOverview: true,
                },
                {
                    fieldName: "AccessTokenLengthBytes",
                    prettyName: "AccessTokenLengthBytes",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: true,
                },
                {
                    fieldName: "AccessToken",
                    prettyName: "AccessToken",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "AccessTokenBytes",
                    prettyName: "AccessTokenBytes",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },

                {
                    fieldName: "UrlLength",
                    prettyName: "UrlLength",
                    required: false,
                    fieldType: GenericListFieldType.Number,
                    hideInOverview: true,
                },
                {
                    fieldName: "UrlLengthBytes",
                    prettyName: "UrlLengthBytes",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: true,
                },
                {
                    fieldName: "Url",
                    prettyName: "Url",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "UrlBytes",
                    prettyName: "UrlBytes",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },

                {
                    fieldName: "TimestampLength",
                    prettyName: "TimestampLength",
                    required: false,
                    fieldType: GenericListFieldType.Number,
                    hideInOverview: true,
                },
                {
                    fieldName: "TimestampLengthBytes",
                    prettyName: "TimestampLengthBytes",
                    required: false,
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: true,
                },
                {
                    fieldName: "Timestamp",
                    prettyName: "Timestamp",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "TimestampBytes",
                    prettyName: "TimestampBytes",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },

                {
                    fieldName: "PreSignedLength",
                    prettyName: "PreSignedLength",
                    required: false,
                    fieldType: GenericListFieldType.Number,
                    hideInOverview: true,
                },
                {
                    fieldName: "PreSignedBytes",
                    prettyName: "PreSignedBytes",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },

                {
                    fieldName: "CurrentKeyCspBlob",
                    prettyName: "CurrentKeyCspBlob",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "OldKeyCspBlob",
                    prettyName: "OldKeyCspBlob",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },

                {
                    fieldName: "ReceivedSignedCurrentProof",
                    prettyName: "ReceivedSignedCurrentProof",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
                {
                    fieldName: "ReceivedSignedOldProof",
                    prettyName: "ReceivedSignedOldProof",
                    required: false,
                    fieldType: GenericListFieldType.TextArea,
                    hideInOverview: true,
                },
            ],
        };
    }

    private async deleteLogs() {
        await this._ds.deleteProofkeyLogs();
        this._toastr.success("Proofkey logs deleted");
    }
}
