<div class="ai-suggestions-dialog">
    <div class="ai-suggestions-header-container d-flex flex-row justify-content-between">
        <div class="h1-title" data-uiid="ai-suggestions-popup-header-title">Refine with AI</div>
        <mat-icon
            class="close-icon"
            (click)="closeDialog()"
            data-uiid="ai-suggestion-popup-header-close-button"
            >close</mat-icon
        >
    </div>
    <div class="ai-suggestions-dialog-body" data-uiid="ai-suggestions-popup-dialog">
        <label class="ai-suggestions-warning"
            >AI generated data can contain errors. Make sure to always check the generated responses
            before using</label
        >
        <label>Suggestion</label>
        <i
            class="mat-mdc-tooltip-trigger far fa-info-circle mx-2"
            matTooltip="This is the suggestion generated by the AI model. You can select a different suggestion by clicking on the buttons below."
        ></i>
        <div class="d-flex flex-column">
            <textarea
                class="form-control form-control-sm"
                [value]="currentSuggestion"
                data-uiid="ai-suggestions-popup-ai-suggestions-text-area"
                readonly
            ></textarea>
        </div>
        <div class="suggestion-selector">
            <label>Showing {{ currentSuggestionIndex }} of 5</label>
            <div class="suggestion-selector-buttons">
                <button
                    *ngFor="let suggestion of suggestions; index as i"
                    class="btn btn-suggestion-selector"
                    [class]="
                        currentSuggestionIndex == i + 1 ? 'btn-secondary' : 'btn-outline-secondary'
                    "
                    (click)="selectSuggestion(i + 1)"
                    (data-uiid)="('button-suggestion-' + (i + 1))"
                >
                    <span class="d-none d-lg-inline">{{ i + 1 }}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="ai-suggestions-dialog-footer">
        <button
            class="btn btn-secondary"
            (click)="closeDialog()"
            data-uiid="ai-suggestions-popup-cancel-button"
        >
            <span class="d-none d-lg-inline">Cancel</span>
        </button>
        <button
            class="btn btn-cerrix"
            (click)="onApplyChanges()"
            data-uiid="ai-suggestions-popup-apply-button"
        >
            <span class="d-none d-lg-inline">Apply Suggestion</span>
        </button>
    </div>
</div>
