import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { LinkedRiskModel } from "../shared/models";
import { AdvEffDataService } from "../shared/services";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { Observable } from "rxjs";

@Component({
    selector: "eff-detail",
    templateUrl: "./eff-detail.component.html",
    styleUrls: ["./eff-detail.component.scss"],
})
export class EffDetailComponent implements OnInit {
    tab: TabModel;

    effectivenessGuid: string;
    linkedRisks$: Observable<LinkedRiskModel[]>;

    constructor(
        private advancedTestingService: AdvEffDataService,
        private tabService: TabService,
        private pages: Pages
    ) {}

    ngOnInit() {
        this.effectivenessGuid = this.tab.id;
        this.tab.showLoader = false;
    }

    ngAfterViewInit() {
        var element = document.querySelector(`.tab-page:not([hidden])`);
        if (element) {
            element.classList.add("control-testing-details-tab-page");
        }
    }

    onMenuItemClick(menuItem) {
        switch (menuItem.name) {
            case "Risks": {
                if (!this.linkedRisks$) {
                    this.linkedRisks$ = this.advancedTestingService.getLinkedRisks(
                        this.effectivenessGuid
                    );
                }
                break;
            }
        }
    }

    openRisk(risk: LinkedRiskModel) {
        const trimmedIdentifier = risk.identifier.replace(/^0+/g, "");
        this.tabService.generateTab(
            this.pages.RiskDetail,
            risk.guid,
            `(R) ${trimmedIdentifier} - ${risk.name}`
        );
    }
}
