import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { FilterOutput } from "../filter/filter.component";

@Component({
    selector: "process-editor-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
    @Input() path: CerrixTreeItem[];
    @Output() onProcessSelected = new EventEmitter<Pick<FilterOutput, "businessDimension">>();

    treeControl = new NestedTreeControl<CerrixTreeItem>(node => node.Children);
    dataSource = new MatTreeNestedDataSource<CerrixTreeItem>();

    constructor() {}

    ngOnInit() {}

    hasChild = (_: number, node: CerrixTreeItem) => !!node.Children && node.Children.length > 0;

    processSelected(id: number) {
        this.onProcessSelected.emit({ businessDimension: id });
    }
}
