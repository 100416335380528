import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "arrayFormat"
})
export class ArrayFormatPipe implements PipeTransform {
    transform(value: any, separator = ", "): any | null {
        if (!Array.isArray(value)) {
            return null;
        }

        return value.join(separator);
    }
}
