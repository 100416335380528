import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app.constants";
import { Observable } from "rxjs";
import { ApiDataService } from "@services/http/ApiDataService";
import { NotificationSettingsModel } from "../models/notificationSettingsModel";

@Injectable()
export class NotificationSettingsService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "notifications/settings");
    }

    getSettings(): Observable<NotificationSettingsModel[]> {
        return this.get<NotificationSettingsModel[]>();
    }

    storeSettings(settings: NotificationSettingsModel[]): Observable<void> {
        return this.post<void>(settings);
    }

    syncAllUsers(): Observable<void> {
        return this.post<void>(null, "/sync");
    }

    storePreference(userGuid: string, preference: number): Observable<void> {
        return this.post<void>(null, `/${userGuid}/preference/${preference}`);
    }

    syncUser(userGuid: string): Observable<void> {
        return this.post<void>(null, `/${userGuid}/sync`);
    }
}
