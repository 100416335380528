export enum DashboardLegendType {
    off = 100,
    icon = 200,
    iconlabel = 300,
}

// The string values has to be the graph type of the chart (has to be exact)
export const DashboardLegendTypeDescription: { [type in DashboardLegendType]: string } = {
    100: "Hide legend",
    200: "Icon only",
    300: "Icon and label",
};
