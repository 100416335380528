import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { EventCommentModel } from "@app/event/shared/event-comment-model";
import { EventCommentHistoryModel } from "@app/event/shared/event-comment-history-model";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EventDataService } from "@app/event/services/EventDataService";

@Component({
    selector: "event-comments-dialog",
    templateUrl: "./event-comments-dialog.component.html",
    styleUrls: ["./event-comments-dialog.component.scss"],
})
export class EventCommentsDialogComponent extends BaseModal implements OnInit {
    eventGuid: string;
    comment: EventCommentModel;

    title: string;
    loading = true;

    histories: EventCommentHistoryModel[];
    commentForm: FormGroup;

    constructor(_bsModalRef: BsModalRef, private _eventDS: EventDataService) {
        super(_bsModalRef);
    }

    ngOnInit(): void {
        const comment = this.comment ? this.comment : new EventCommentModel();

        this.commentForm = new FormGroup({
            comment: new FormControl(comment.comment, Validators.required),
        });

        if (comment.id && !comment.canEdit) {
            this.commentForm.disable();
        }

        this.loading = false;
    }

    saveClick() {
        FormValidationHelper.mapToModel(this.commentForm, this.comment);
        this._bsModalRef.hide();

        super.onConfirm(this.comment);
    }

    closeClick() {
        super.onCancel();
    }

    loadHistory() {
        if (!this.histories) {
            this._eventDS
                .getCommentHistory(this.eventGuid, this.comment.id)
                .subscribe((histories) => {
                    this.histories = histories;
                });
        }
    }
}
