import { AppConstants, Configuration } from "@app/app.constants";
import { FormFieldType, FormFieldTypeDescription } from "@app/forms/enums/FormFieldTypes";
import { FormFieldWidgetType } from "@app/forms/enums/FormFieldWidgetTypes";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { parseTyped } from "@methods/CommonMethods";
import { validateVideoSource } from "@methods/ValidationMethods";

export class FormDesignFieldValidation {
    static ValidateField(field: FormField): boolean {
        const fieldValidations: string[] = [];
        const fieldDescription = FormFieldTypeDescription[field.FieldType].text;

        field.Valid = true;
        field.Validation = "";

        const maxFieldLength = AppConstants.AppInjector.get(Configuration).MaxTextFieldLength;
        if (field.Name.trim().length === 0) {
            field.Valid = false;
            fieldValidations.push("Field name required");
        } else if (field.Name.length > maxFieldLength) {
            field.Valid = false;
            fieldValidations.push(`Field name can't exceed ${maxFieldLength} characters`);
        }

        if (field.WorkspaceLabel && field.WorkspaceLabel.length > maxFieldLength) {
            field.Valid = false;
            fieldValidations.push("Workspace Label can't exceed ${maxFieldLength} characters");
        }

        if (
            field.CustomSettings &&
            field.CustomSettings.length > 0 &&
            field.FieldType !== FormFieldType.Info
        ) {
            const fieldConfig = parseTyped<FormFieldConfig>(
                field.CustomSettings,
                new FormFieldConfig()
            );

            if (field.FieldType === FormFieldType.Radio) {
                FormDesignFieldValidation.ValidateRadioField(fieldConfig).addTo(fieldValidations);
            }

            // if MultiselectField
            if (
                field.FieldType === FormFieldType.Checkbox ||
                (field.FieldType === FormFieldType.Dropdown && fieldConfig.multiSelect)
            ) {
                FormDesignFieldValidation.ValidateMultiselectField(
                    fieldConfig,
                    fieldDescription
                ).addTo(fieldValidations);
            }

            if (field.FieldType === FormFieldType.Widget) {
                FormDesignFieldValidation.ValidateWidgetField(fieldConfig).addTo(fieldValidations);
            }

            if (field.FieldType == FormFieldType.Video) {
                FormDesignFieldValidation.ValidateVideoField(fieldConfig).addTo(fieldValidations);
            }
        }

        if (fieldValidations.length > 0) {
            field.Valid = false;
            field.Validation = fieldValidations.join("\n");
        }

        return field.Valid;
    }

    static ValidateRadioField(fieldConfig: FormFieldConfig): string[] {
        const validations: string[] = [];
        let addWhiteSpaceMessage = false,
            addDuplicateMessage = false;

        if (fieldConfig.options) {
            fieldConfig.options.forEach((option, index) => {
                if (option.trim().length === 0) {
                    addWhiteSpaceMessage = true;
                }

                if (fieldConfig.options.indexOf(option) !== index) {
                    addDuplicateMessage = true;
                }
            });
        }

        if (addWhiteSpaceMessage) {
            validations.push("Field option cannot be empty or a whitespace!");
        }

        if (addDuplicateMessage) {
            validations.push("Duplicate field options are not allowed!");
        }

        return validations;
    }

    static ValidateMultiselectField(fieldOptions: FormFieldConfig, fieldDesc: string): string[] {
        const validations: string[] = [];
        const hasMinimumSelection = fieldOptions.minimumSelection;
        const hasMaximumSelection = fieldOptions.maximumSelection;

        if (
            hasMinimumSelection &&
            hasMaximumSelection &&
            fieldOptions.minimumSelection > fieldOptions.maximumSelection
        ) {
            validations.push(
                "Minimum selectable options can not be higher than the maximum selectable options."
            );
        }

        if (fieldOptions.options.length <= 0) {
            validations.push(`Multiselect ${fieldDesc} field requires one or more options.`);
        }

        if (hasMinimumSelection && hasMinimumSelection % 1 !== 0) {
            validations.push(`Minimum selectable options must be a whole number!`);
        }

        if (hasMaximumSelection && hasMaximumSelection % 1 !== 0) {
            validations.push(`Maximum selectable options must be a whole number!`);
        }

        if (hasMinimumSelection && fieldOptions.options.length < fieldOptions.minimumSelection) {
            validations.push(
                `${fieldDesc} field requires more options than the minimum selectable options.`
            );
        }

        if (hasMaximumSelection && fieldOptions.options.length < fieldOptions.maximumSelection) {
            validations.push(
                "Maximum selectable options can not be higher than the number of options."
            );
        }

        return validations;
    }

    static ValidateWidgetField(widgetConfig: FormFieldConfig): string[] {
        const validations: string[] = [];
        if (widgetConfig && widgetConfig.widgetType === FormFieldWidgetType.RiskMatrix) {
            if (!widgetConfig.widgetParam) {
                validations.push("Risk Matrix Organization is required!");
            }
        } else {
            validations.push("Widget Type is required!");
        }

        return validations;
    }

    static ValidateVideoField(widgetConfig: FormFieldConfig): string[] {
        const validations: string[] = [];
        if (widgetConfig && widgetConfig.videoUrl) {
            if (!validateVideoSource(widgetConfig.videoUrl)) {
                validations.push("Video url is not valid!");
            }
        } else {
            validations.push("Video Url is required!");
        }

        return validations;
    }
}
