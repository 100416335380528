export class IdNameCombination {
    ID: number;
    Name: string;
    disabled?: boolean;
    isArchived?: boolean;

    constructor(i: number, n: string) {
        this.ID = i;
        this.Name = n;
    }
}
