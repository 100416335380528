import { ApiKeyModel } from "./../models/api-key.model";
import { ApiKeyCreateConfirmationModel } from "./../models/api-key-create-confirmation.model";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";

@Injectable()
export class ApiKeyService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "users/apikeys");
    }

    public canEdit(userGuid?: string) {
        const url = `/canedit?userGuid=${userGuid}`;
        return this.get<boolean>(url);
    }

    public getNew(userGuid?: string) {
        const url = `/new?userGuid=${userGuid}`;
        return this.get<ApiKeyModel>(url);
    }

    public getApiKeys(userGuid: string) {
        const url = `?userGuid=${userGuid}`;
        return this.get<ApiKeyModel[]>(url);
    }

    public getApiKey(userGuid: string, apiKeyId: number) {
        const url = `/${apiKeyId}?userGuid=${userGuid}`;
        return this.get<ApiKeyModel>(url);
    }

    public storeApiKey(userGuid: string, apiModel: ApiKeyModel) {
        const url = `?userGuid=${userGuid}`;
        return this.post<ApiKeyCreateConfirmationModel>(apiModel, url);
    }

    public deleteApiKey(keyID: number) {
        const url = `/${keyID}`;
        return this.delete(url);
    }
}
