<div class="detail-page-card">
    <div class="detail-page-card-title">Linked Finding Report</div>
    <div class="detail-page-card-actions">
        <button
            class="btn btn-raised"
            (click)="openFindingReport()"
            *ngIf="showOpenButton && summaryModel.guid"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Open Finding Report</span>
        </button>
        <button
            class="btn btn-raised"
            (click)="openAssessment()"
            *ngIf="assessmentModel && showOpenButton"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Open Assessment</span>
        </button>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
        <div class="row">
            <div class="col-12 col-xl-6 left-block" *ngIf="summaryModel">
                <div class="fancy-loader submodule my-4" *ngIf="!summaryModel"></div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Report name</label>
                            <input
                                data-uiid="bim-moi-report-name"
                                type="text"
                                class="form-control form-control-sm"
                                disabled
                                [value]="summaryModel.objectName"
                                inputTooltip
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Type</label>
                            <input
                                data-uiid="bim-moi-report-type"
                                type="text"
                                class="form-control form-control-sm"
                                disabled
                                [value]="summaryModel.type"
                                inputTooltip
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Report no</label>
                            <input
                                data-uiid="bim-moi-report-no"
                                type="text"
                                class="form-control form-control-sm"
                                disabled
                                [value]="summaryModel.reportNo"
                                inputTooltip
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Overall score</label>
                            <ng-select
                                data-uiid="bim-moi-overall-score"
                                [items]="[
                                    {
                                        Id: 1,
                                        Name: summaryModel.scoreTypeName,
                                        Color: summaryModel.scoreTypeColor
                                    }
                                ]"
                                [ngModel]="1"
                                bindLabel="Name"
                                bindValue="Id"
                                disabled
                            >
                                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                    <div
                                        [style.background-color]="item.Color"
                                        style="
                                            min-width: 5px;
                                            min-height: 16px;
                                            float: left;
                                            margin-right: 10px;
                                        "
                                    ></div>
                                    {{ item.Name }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Report date</label>
                    <date-input
                        data-uiid="bim-moi-report-date"
                        [(value)]="summaryModel.reportDate"
                        [disabled]="true"
                    ></date-input>
                </div>
                <div class="form-group">
                    <label>Conclusion</label>
                    <textarea
                        data-uiid="bim-moi-conclusion"
                        type="date"
                        class="form-control form-control-sm"
                        disabled
                        [value]="summaryModel.conclusion"
                        inputTooltip
                    >
                    </textarea>
                </div>

                <hr class="d-xl-none" />
            </div>

            <div class="col-12 col-xl-6 right-block">
                <div class="fancy-loader submodule my-4" *ngIf="!moi || assessmentIsLoading"></div>
                <div *ngIf="moi && !assessmentIsLoading">
                    <div *ngIf="!moi.AssessmentGuid && assessments && assessments.length > 0">
                        <div class="form-group">
                            <label for="subjectSelect">Available assessment</label>
                            <div class="row me-0">
                                <div class="col-8">
                                    <ng-select
                                        id="assessmentSelect"
                                        name="assessmentSelect"
                                        [items]="assessments"
                                        bindLabel="assessmentType"
                                        bindValue="guid"
                                        (change)="change($event)"
                                        [ngModel]
                                        [disabled]="!canLink"
                                    >
                                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                            <div
                                                [style.background-color]="item.scoreColor"
                                                style="
                                                    min-width: 5px;
                                                    min-height: 16px;
                                                    float: left;
                                                    margin-right: 10px;
                                                "
                                            ></div>
                                            {{ item.assessmentType }} ({{ item.date | dateFormat }})
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <button
                                    class="btn btn-success btn-sm col me-2"
                                    (click)="linkAssessment()"
                                    *ngIf="canLink"
                                    [disabled]="!assessmentModel"
                                    matTooltip="Link assessment to MoI"
                                >
                                    <i class="fas fa-link"></i>
                                </button>
                                <button
                                    class="btn btn-info btn-sm col"
                                    (click)="load()"
                                    *ngIf="!moi.AssessmentGuid"
                                    matTooltip="Reload assessments"
                                >
                                    <i class="fas fa-sync-alt"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!moi.AssessmentGuid && (!assessments || assessments.length == 0)">
                        <div class="text-center">
                            <h6>There are no assessments available to link.</h6>
                            <small class="text-muted"
                                >To link an assessment, please add an assessment to the Finding
                                Report.</small
                            >
                        </div>
                    </div>

                    <div *ngIf="assessmentModel && assessmentModel.guid">
                        <div *ngIf="!moi.AssessmentGuid">
                            <hr />
                            <div class="row text-center">
                                <i
                                    class="
                                        fas
                                        fa-exclamation-triangle
                                        text-warning
                                        col-1
                                        flex-center
                                    "
                                ></i>
                                <small class="col">
                                    This is only a preview of the assessment. Click '
                                    <i class="fas fa-link"></i> ' to make it permanent!
                                </small>
                                <i
                                    class="
                                        fas
                                        fa-exclamation-triangle
                                        text-warning
                                        col-1
                                        flex-center
                                    "
                                ></i>
                            </div>
                            <hr />
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Assessment type</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        disabled
                                        [value]="assessmentModel.assessmentType"
                                        inputTooltip
                                    />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Assessment score</label>
                                    <ng-select
                                        [items]="[
                                            {
                                                Id: 1,
                                                Name: assessmentModel.scoreName,
                                                Color: assessmentModel.scoreColor
                                            }
                                        ]"
                                        [ngModel]="1"
                                        bindLabel="Name"
                                        bindValue="Id"
                                        disabled
                                    >
                                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                            <div
                                                [style.background-color]="item.Color"
                                                style="
                                                    min-width: 5px;
                                                    min-height: 16px;
                                                    float: left;
                                                    margin-right: 10px;
                                                "
                                            ></div>
                                            {{ item.Name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Comment</label>
                            <textarea
                                type="date"
                                class="form-control form-control-sm"
                                disabled
                                [value]="assessmentModel.comments"
                                inputTooltip
                            >
                            </textarea>
                        </div>
                        <div class="form-group">
                            <label>Assessment date</label>
                            <date-input
                                [(value)]="assessmentModel.date"
                                [disabled]="true"
                            ></date-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
