import { Component, DestroyRef, EventEmitter, inject, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IncidentService } from "@app/incident/services/incident.service";
import { downloadWithLink } from "@methods/CommonMethods";
import { CerrixDocumentsListComponent } from "../../../shared/cerrix-documents/cerrix-documents-list/cerrix-documents-list.component";
import { DocumentModel } from "../../../shared/cerrix-documents/models/document.model";
import { BaseDocumentsService } from "../../../shared/cerrix-documents/services/base-documents.service";
import { map, Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "incident-documents-tab",
    templateUrl: "./incident-documents-tab.component.html",
    styleUrls: ["./incident-documents-tab.component.scss"],
    standalone: true,
    imports: [CommonModule, CerrixDocumentsListComponent],
    providers: [],
})
export class IncidentDocumentsTabComponent {
    @Input() canEditIncident!: boolean;

    @Input() public incidentId: string;

    @Input() public documents: DocumentModel[];
    @Output() documentsChange = new EventEmitter<DocumentModel[]>();

    private destroyRef = inject(DestroyRef);
    private incidentService = inject(IncidentService);
    private documentsService = inject(BaseDocumentsService);

    protected onRemoveFile(document: DocumentModel) {
        this.documents = this.documents.filter((d) => d !== document);
        this.documentsChange.emit(this.documents);
    }

    protected onFileDownload(document: DocumentModel) {
        this.getDocumentLocalUrl(document)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((localUrl) => {
                downloadWithLink(localUrl, document.name);
            });
    }

    protected onOpenDocumentViewer(document: DocumentModel) {
        this.getDocumentLocalUrl(document)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((localUrl) => {
                this.documentsService.openDocumentViewer(document.id, document.name, localUrl);
            });
    }

    protected onDocumentsChange(documents: DocumentModel[]) {
        this.documents = documents;
        this.documentsChange.emit(this.documents);
    }

    private getDocumentLocalUrl(document: DocumentModel): Observable<string> {
        if (document.id && !document.file) {
            return this.incidentService
                .downloadDocument(this.incidentId, document.id)
                .pipe(map((data) => URL.createObjectURL(data)));
        } else {
            const localUrl = URL.createObjectURL(document.file);

            return new Observable<string>((obs) => {
                obs.next(localUrl);
                obs.complete();
            });
        }
    }
}
