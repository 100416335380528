import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { AuditUniversePeriodDetailMainComponent } from "./pages/audit-universe-period-detail-main/audit-universe-period-detail-main.component";
import { AuditUniversePeriodDetailComponent } from "./pages/audit-universe-period-detail/audit-universe-period-detail.component";
import { AuditUniversePeriodOverviewComponent } from "./pages/audit-universe-period-overview/audit-universe-period-overview.component";
import { AuditUniversePeriodDataService } from "./services/audit-universe-period-data.service";

@NgModule({
    imports: [CommonModule, SharedModule.forRoot()],
    declarations: [
        AuditUniversePeriodOverviewComponent,
        AuditUniversePeriodDetailComponent,
        AuditUniversePeriodDetailMainComponent,
    ],
})
export class AuditUniversePeriodModule {
    static forRoot(): ModuleWithProviders<AuditUniversePeriodModule> {
        return {
            ngModule: AuditUniversePeriodModule,
            providers: [AuditUniversePeriodDataService],
        };
    }
}
