import { Component, Input } from "@angular/core";
import { EffectivenessConclusionsModel } from "../../shared/models/effectiveness-conclusions-model";

@Component({
    selector: "adv-eff-conclusions",
    templateUrl: "./adv-eff-conclusions.component.html",
    styleUrls: ["./adv-eff-conclusions.component.scss"]
})
export class ConclusionsComponent {
    @Input() conclusions: EffectivenessConclusionsModel;
    @Input() useReviewer: boolean;
}
