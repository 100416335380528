export class ExcelHelper {
    public static convertExcelColumnToNumber(columnName: string): number {
        if (!columnName || !columnName.match(/^[A-z]{0,3}$/g)) {
            return null;
        }

        const charCodeOfA = "A".charCodeAt(0);
        const name = columnName.toUpperCase();

        let result = 0;
        for (let i = name.length - 1, m = 0; i >= 0; i--, m++) {
            const value = name.charCodeAt(i) - charCodeOfA + 1;

            result += value * Math.pow(26, m);
        }

        return result;
    }

    public static convertNumberToExcelColumn(columnNumber: number): string {
        return this.convertIndexToExcelColumn(columnNumber - 1);
    }

    public static convertIndexToExcelColumn(columnIndex: number): string {
        if ((!columnIndex && columnIndex !== 0) || columnIndex < 0) {
            return null;
        }

        const charCodeOfA = "A".charCodeAt(0);

        let result = "";
        while (columnIndex >= 0) {
            result = String.fromCharCode((columnIndex % 26) + charCodeOfA) + result;
            columnIndex = Math.floor(columnIndex / 26) - 1;
        }

        return result;
    }
}
