import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { TargetModule } from "@enums/document/TargetModule";

@Component({
    selector: "finding-report-details-documents",
    templateUrl: "./finding-report-details-documents.component.html",
    styleUrls: ["./finding-report-details-documents.component.scss"],
})
export class FindingReportDetailsDocumentsComponent implements OnInit {
    public readonly documentPermissions = new DocumentManagerPermissionModel();
    public documentTypeTargets = TargetModule.FindingReport;

    @Input() editAllowed: boolean;
    @Input() documents: DocumentModel[];

    @Output() documentsChange = new EventEmitter<DocumentModel[]>();

    ngOnInit(): void {
        this.documentPermissions.canAddDocument = this.editAllowed;
    }

    onDocumentsChange(documents: DocumentModel[]) {
        this.documentsChange.emit(documents);
    }
}
