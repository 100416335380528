import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ControlModule } from "@app/controls/control.module";

import { SharedModule } from "@app/shared/shared.module";
import { ThirdPartyOverviewComponent } from "./thirdparty-overview/thirdparty-overview.component";
import { ThirdPartyDetailComponent } from "./thirdparty-detail/thirdparty-detail.component";
import { ThirdPartyDataService } from "./services/ThirdPartyDataService";
import { ThirdPartyDetailMainComponent } from "./thirdparty-detail/uc/thirdparty-detail-main.component";
import { ThirdPartyRisksComponent } from "./thirdparty-risks/thirdparty-risks.component";
import { ThirdPartyReviewComponent } from "./thirdparty-detail/thirdparty-reviews/thirdparty-review.component";
import { ThirdPartyContactsComponent } from "./thirdparty-detail/thirdparty-contacts/thirdparty-contacts.component";
import { ThirdPartyControlsComponent } from "./thirdparty-controls/thirdparty-controls.component";
import { ThirdPartyProcessingPurposeComponent } from "./thirdparty-detail/thirdparty-processingpurpose/thirdparty-processingpurpose.component";
import { ThirdPartyContractsComponent } from "./thirdparty-contracts/thirdparty-contracts.component";
import { ThirdPartyContractsOverviewComponent } from "./thirdparty-contracts-overview/thirdparty-contracts-overview.component";
import { ThirdPartyDialogComponent } from "./dialogs/thirdparty-dialog.component";
import { ThirdPartyContractDataService } from "./services/ThirdPartyContractDataService";
import { ThirdPartyLinkedFormsComponent } from "./thirdparty-forms/thirdparty-forms.component";

@NgModule({
    declarations: [
        ThirdPartyDetailComponent,
        ThirdPartyDetailMainComponent,
        ThirdPartyRisksComponent,
        ThirdPartyControlsComponent,
        ThirdPartyOverviewComponent,
        ThirdPartyReviewComponent,
        ThirdPartyContactsComponent,
        ThirdPartyProcessingPurposeComponent,
        ThirdPartyContractsComponent,
        ThirdPartyContractsOverviewComponent,
        ThirdPartyDialogComponent,
        ThirdPartyLinkedFormsComponent,
    ],
    imports: [CommonModule, FormsModule, ControlModule, SharedModule],
})
export class ThirdpartyModule {
    static forRoot(): ModuleWithProviders<ThirdpartyModule> {
        return {
            ngModule: ThirdpartyModule,
            providers: [ThirdPartyDataService, ThirdPartyContractDataService],
        };
    }
}
