<div
    fileDrop
    (fileDrop)="fileDropHandler($event, false)"
    [allowedExtensions]="allowedFileExtensions"
    [allowDrop]="allowDrop"
    [fullScreenDropArea]="false"
    class="horizontal-drop-area justify-content-center align-items-center d-flex flex-row"
    data-uiid="new-eff-document-manager-drop-files-area"
>
    <mat-icon>cloud_upload</mat-icon>
    <span class="drop-label">Drop files here to attach or</span> &nbsp;
    <browse-files-button
        (onFilesDropped)="fileDropHandler($event, true)"
        [allowedFileExtensions]="allowedFileExtensions"
        data-uiid="new-eff-document-manager-drop-files-area-browse-button"
    ></browse-files-button>
</div>
