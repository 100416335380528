import { Component, OnInit, Input } from "@angular/core";
import {
    ActionShortcut,
    MenuItemBuilder,
    ContextMenuItem,
} from "@app/shared/contextmenu/menu-item";
import { Actions, ActionEnum } from "../../actions";
import { PermissionsService } from "@services/permissions/PermissionsService";

@Component({
    selector: "process-editor-menubar",
    templateUrl: "./menubar.component.html",
    styleUrls: ["./menubar.component.scss"],
})
export class MenubarComponent implements OnInit {
    @Input() actions: Actions;

    menu: Array<{ text: string; menu: ContextMenuItem[] }>;

    constructor(private _menuItemBuilder: MenuItemBuilder) {}

    ngOnInit() {
        const actions = this.actions;
        this.menu = [
            {
                text: "File",
                menu: this._menuItemBuilder
                    .appendContextMenuItem({
                        text: "import",
                        click: actions.get(ActionEnum.IMPORT),
                        shortcut: actions.getShortcut(ActionEnum.IMPORT),
                        visible: () => actions.hasPermission(ActionEnum.IMPORT),
                    })
                    .appendContextMenuItem({
                        divider: true,
                        visible: () => actions.hasPermission(ActionEnum.IMPORT),
                    })
                    .appendItem(
                        "Save",
                        actions.get(ActionEnum.SAVE),
                        actions.getShortcut(ActionEnum.SAVE)
                    )
                    .build(),
            },
            {
                text: "Edit",
                menu: this._menuItemBuilder
                    .appendItem(
                        "Undo",
                        actions.get(ActionEnum.UNDO),
                        actions.getShortcut(ActionEnum.UNDO)
                    )
                    .appendItem(
                        "Redo",
                        actions.get(ActionEnum.REDO),
                        actions.getShortcut(ActionEnum.REDO)
                    )
                    .appendDivider()
                    .appendItem(
                        "Cut",
                        actions.get(ActionEnum.CUT),
                        actions.getShortcut(ActionEnum.CUT)
                    )
                    .appendItem(
                        "Copy",
                        actions.get(ActionEnum.COPY),
                        actions.getShortcut(ActionEnum.COPY)
                    )
                    .appendItem(
                        "Paste",
                        actions.get(ActionEnum.PASTE),
                        actions.getShortcut(ActionEnum.PASTE)
                    )
                    .appendItem(
                        "Delete",
                        actions.get(ActionEnum.REMOVE_CELLS),
                        actions.getShortcut(ActionEnum.REMOVE_CELLS)
                    )
                    .appendDivider()
                    .appendItem(
                        "Edit",
                        actions.get(ActionEnum.EDIT),
                        actions.getShortcut(ActionEnum.EDIT)
                    )
                    .appendDivider()
                    .appendItem(
                        "Select Vertices...",
                        actions.get(ActionEnum.SELECT_VERTICES),
                        actions.getShortcut(ActionEnum.SELECT_VERTICES)
                    )
                    .appendItem(
                        "Select Edges...",
                        actions.get(ActionEnum.SELECT_EDGES),
                        actions.getShortcut(ActionEnum.SELECT_EDGES)
                    )
                    .appendItem(
                        "Select All...",
                        actions.get(ActionEnum.SELECT_ALL),
                        actions.getShortcut(ActionEnum.SELECT_ALL)
                    )
                    .appendItem(
                        "Select None...",
                        actions.get(ActionEnum.UNSELECT_ALL),
                        actions.getShortcut(ActionEnum.UNSELECT_ALL)
                    )
                    .appendDivider()
                    .appendItem(
                        "Lock/Unlock...",
                        actions.get(ActionEnum.TOGGLE_LOCK),
                        actions.getShortcut(ActionEnum.TOGGLE_LOCK)
                    )
                    .build(),
            },
            {
                text: "View",
                menu: this._menuItemBuilder
                    .appendItem(
                        "Grid",
                        actions.get(ActionEnum.TOGGLE_GRID),
                        actions.getShortcut(ActionEnum.TOGGLE_GRID)
                    ) // Toggle
                    .appendDivider()
                    .appendItem(
                        "Reset View",
                        actions.get(ActionEnum.RESET_VIEW),
                        actions.getShortcut(ActionEnum.RESET_VIEW)
                    )
                    .appendItem(
                        "Zoom In",
                        actions.get(ActionEnum.ZOOM_IN),
                        actions.getShortcut(ActionEnum.ZOOM_IN)
                    )
                    .appendItem(
                        "Zoom Out",
                        actions.get(ActionEnum.ZOOM_OUT),
                        actions.getShortcut(ActionEnum.ZOOM_OUT)
                    )
                    .build(),
            },
            {
                text: "Arrange",
                menu: this._menuItemBuilder
                    .appendItem(
                        "To Front",
                        actions.get(ActionEnum.TO_FRONT),
                        actions.getShortcut(ActionEnum.TO_FRONT)
                    )
                    .appendItem(
                        "To Back",
                        actions.get(ActionEnum.TO_BACK),
                        actions.getShortcut(ActionEnum.TO_BACK)
                    )
                    .appendDivider()
                    .appendItem(
                        "Group",
                        actions.get(ActionEnum.GROUP_CELLS),
                        actions.getShortcut(ActionEnum.GROUP_CELLS)
                    )
                    .appendItem(
                        "Ungroup",
                        actions.get(ActionEnum.UNGROUP_CELLS),
                        actions.getShortcut(ActionEnum.UNGROUP_CELLS)
                    )
                    .build(),
            },
        ];
    }
}
