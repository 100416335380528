<ng-template #wizardContent>
    <div class="cerrix-wizard" *ngIf="config">
        <div class="wizard-steps" *ngIf="config.steps && config.allowPageChange">
            <div
                class="wizard-step"
                *ngFor="let step of config.steps; let i = index"
                [attr.data-uiid]="'wizard-step-' + step.name"
                [hidden]="step.hide"
                [class.active]="i == config.activePageIndex"
                (click)="changePageTo(i)"
            >
                <i
                    class="far validation"
                    *ngIf="config.enableValidation"
                    [class.fa-times-circle]="step.valid == false"
                    [class.fa-dot-circle]="step.valid != false && step.valid != true"
                    [class.fa-check-circle]="step.valid == true"
                ></i>

                <span class="step-icon text-truncate">
                    <i class="{{ i <= config.activePageIndex ? 'fas' : 'far' }} fa-circle"></i>
                </span>

                <span class="step-name d-none d-lg-inline text-truncate">{{ step.name }}</span>
            </div>
            <div
                class="wizard-step"
                [class.active]="summaryIndex == config.activePageIndex"
                (click)="changePageTo(config.steps.length)"
                *ngIf="summaryEnabled"
            >
                <i class="fas fa-lock validation" *ngIf="config.enableValidation"></i>

                <span class="step-icon text-truncate">
                    <i
                        class="{{
                            summaryIndex <= config.activePageIndex ? 'fas' : 'far'
                        }} fa-circle"
                    ></i>
                </span>
                <span class="step-name d-none d-lg-inline text-truncate">Summary</span>
            </div>
        </div>

        <div
            class="wizard-pages"
            [class.summary]="summaryIndex == config.activePageIndex"
            [class.padded]="!config.noPagePadding"
        >
            <div
                class="wizard-page summary-section"
                [hidden]="summaryIndex != config.activePageIndex"
                *ngIf="summaryEnabled"
            >
                <ng-container
                    [ngTemplateOutlet]="summaryTemplate"
                    *ngIf="summaryTemplate"
                ></ng-container>
            </div>
            <div
                class="wizard-page"
                *ngFor="let template of templates; let i = index"
                [hidden]="config.activePageIndex != i && summaryIndex != config.activePageIndex"
            >
                <ng-container [ngTemplateOutlet]="template"></ng-container>
            </div>
        </div>

        <div class="wizard-footer">
            <button
                class="btn btn-dark"
                data-uiid="wizard-button-previous"
                (click)="changePageTo(config.activePageIndex - 1)"
                [disabled]="config.activePageIndex <= 0"
                *ngIf="config.allowPageChange"
            >
                <i class="fas fa-long-arrow-alt-left me-2"></i>
                Previous
            </button>

            <button
                class="btn btn-outline-danger right-button ms-2"
                data-uiid="wizard-button-close"
                *ngIf="config.showInModal && config.useDedicatedClose"
                (click)="closeWizard()"
            >
                Close
                <i class="fas fa-times ms-2"></i>
            </button>
            <button
                class="btn btn-light right-button"
                data-uiid="wizard-button-next"
                (click)="changePageTo(config.activePageIndex + 1)"
                *ngIf="
                    config.allowPageChange &&
                    config.activePageIndex < templates.length - (summaryEnabled ? 0 : 1)
                "
            >
                Next
                <i class="fas fa-long-arrow-alt-right ms-2"></i>
            </button>
            <button
                class="btn btn-cerrix right-button"
                data-uiid="wizard-button-complete"
                (click)="complete()"
                *ngIf="config.activePageIndex == templates.length - (summaryEnabled ? 0 : 1)"
                [disabled]="!config.completable || completeCallRunning"
            >
                Complete
                <i class="fas fa-check ms-2" *ngIf="!completeCallRunning"></i>
                <i class="fas fa-circle-notch fa-spin ms-2" *ngIf="completeCallRunning"></i>
            </button>

            <button
                *ngFor="let btn of config.buttons"
                (click)="btn.click()"
                [hidden]="btn.showOnPage != config.activePageIndex"
                class="btn btn-light {{ btn.alignRight ? 'right-button me-2' : 'ms-2' }}"
                [attr.data-uiid]="'wizard-button-' + btn.text"
            >
                <i [class]="btn.icon + ' me-2'" *ngIf="btn.icon && !btn.alignRight"></i>
                {{ btn.text }}
                <i [class]="btn.icon + ' ms-2'" *ngIf="btn.icon && btn.alignRight"></i>
            </button>
        </div>
    </div>
</ng-template>

<ng-container [ngTemplateOutlet]="wizardContent" *ngIf="!config.showInModal"></ng-container>
<div
    bsModal
    #wizardModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    [config]="modalConfig"
    *ngIf="config.showInModal"
>
    <div class="modal-dialog wizard-modal-dialog">
        <div class="modal-content">
            <ng-container [ngTemplateOutlet]="wizardContent"></ng-container>
        </div>
    </div>
</div>

<div
    bsModal
    #completeModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    *ngIf="config.showModalOnComplete"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content wizard-complete-modal">
            <div class="modal-body">
                <i class="far fa-check-circle green"></i>
                <h2 *ngIf="config.completeModalTitle">{{ config.completeModalTitle }}</h2>
                <p *ngIf="config.completeModalSubtitle">{{ config.completeModalSubtitle }}</p>
            </div>
            <div class="modal-footer">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button
                        *ngFor="let btn of config.completeModalButtons"
                        type="button"
                        class="btn btn-secondary"
                        (click)="btn.click()"
                    >
                        <i [class]="btn.icon + ' me-2'" *ngIf="btn.icon"></i>
                        {{ btn.text }}
                    </button>
                </div>
                <button
                    type="button"
                    class="btn btn-cerrix"
                    data-bs-dismiss="modal"
                    (click)="closeCompleteModal()"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
