import { Pipe, PipeTransform } from "@angular/core";
import { LocalizationService } from "../localization";

@Pipe({
    name: "dateFormat",
    pure: true,
})
export class DateFormatPipe implements PipeTransform {
    constructor(private localizationService: LocalizationService) {}

    transform(value: any): string | null {
        if (value == null || value === "" || value !== value) {
            return null;
        }

        if (typeof value === "string") {
            value = new Date(value);
        }

        return this.localizationService.formatDate(
            value,
            this.localizationService.localizationInfo.dateFormat
        );
    }
}
