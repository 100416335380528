import { Component, Input } from "@angular/core";
import { SaveButtonService } from "./eff-save-button.service";

@Component({
    selector: "eff-save-button",
    templateUrl: "./eff-save-button.component.html",
    styleUrls: ["./eff-save-button.component.scss"],
})
export class EffSaveButtonComponent {
    @Input() text: string = "Save";
    @Input() saveSuccessText: string = "Saved!";

    saveSuccess: boolean = false;
    constructor(private saveButtonService: SaveButtonService) {}

    ngOnInit() {
        this.saveButtonService.saveSuccessEvent.subscribe(this.onSaveSuccess.bind(this));
    }

    onSaveSuccess() {
        this.saveSuccess = true;
        setTimeout(
            function () {
                this.saveSuccess = false;
            }.bind(this),
            5 * 1000
        );
    }
}
