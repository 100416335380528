<div [formGroup]="simpleTestingGroup" *ngIf="simpleTestingGroup">
    <div class="form-group">
        <label for="catalogueSelect">
            Catalogue {{ type == SimpleTestingType.DesignImplementation ? "*" : "" }}
        </label>
        <ng-select
            id="catalogueSelect"
            name="catalogueSelect"
            [items]="standingData.Catalogues"
            (change)="catalogueChanged.emit($event)"
            formControlName="Catalogue"
            bindLabel="Name"
            bindValue="ID"
            [placeholder]="type == SimpleTestingType.DesignImplementation ? 'Required' : ''"
        >
        </ng-select>
    </div>
    <div class="form-group-separator"></div>

    <div class="row">
        <div class="col-12 col-xl-6 left-block">
            <div class="row">
                <div class="form-group col-6">
                    <label for="testersSelect">Testers *</label>
                    <ng-select
                        id="testersSelect"
                        name="testersSelect"
                        [items]="standingData.Testers"
                        formControlName="Testers"
                        bindLabel="Name"
                        bindValue="ID"
                        [multiple]="true"
                        placeholder="Required"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
                <div class="form-group col-6">
                    <label for="evidenceUploadersSelect">Evidence uploaders *</label>
                    <ng-select
                        id="evidenceUploadersSelect"
                        name="evidenceUploadersSelect"
                        [items]="standingData.EvidenceUploaders"
                        formControlName="EvidenceUploaders"
                        bindLabel="Name"
                        bindValue="ID"
                        [multiple]="true"
                        placeholder="Required"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <label>Initial test date *</label>
                    <date-input formControlName="InitialTestDate"></date-input>
                </div>
                <div class="form-group col-6">
                    <label for="uploaderDueDateInput"
                        >Uploader due date
                        <i
                            class="far fa-question-circle ms-2"
                            [matTooltip]="
                                'How many days before the initial due date the evidence must be provided'
                            "
                        ></i>
                    </label>
                    <input
                        type="number"
                        class="form-control form-control-sm"
                        id="uploaderDueDateInput"
                        name="uploaderDueDateInput"
                        formControlName="UploaderDueDate"
                    />
                    <div class="red" *ngIf="simpleTestingGroup.controls.UploaderDueDate.errors">
                        <p *ngIf="simpleTestingGroup.controls.UploaderDueDate.errors.min">
                            The value cannot be negative
                        </p>
                    </div>
                </div>
            </div>

            <div class="form-group-separator"></div>

            <div class="row">
                <div class="form-group col-6">
                    <label for="linkedStandardSelect">Linked standard</label>
                    <ng-select
                        id="linkedStandardSelect"
                        name="linkedStandardSelect"
                        [items]="standingData.LinkedStandards"
                        formControlName="LinkedStandard"
                        bindLabel="Name"
                        bindValue="ID"
                    >
                    </ng-select>
                </div>
                <div class="form-group col-6">
                    <label for="testSamplesInput"># Test samples</label>
                    <input
                        type="number"
                        class="form-control form-control-sm"
                        id="testSamplesInput"
                        name="testSamplesInput"
                        formControlName="TestSamples"
                    />
                    <div class="red" *ngIf="simpleTestingGroup.controls.TestSamples.errors">
                        <p *ngIf="simpleTestingGroup.controls.TestSamples.errors.min">
                            The value cannot be negative
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <label for="qualityAspectSelect">Quality aspect</label>
                    <ng-select
                        id="qualityAspectSelect"
                        name="qualityAspectSelect"
                        [items]="standingData.QualityAspects"
                        formControlName="QualityAspect"
                        bindLabel="Name"
                        bindValue="ID"
                        [multiple]="true"
                        [closeOnSelect]="false"
                    >
                    </ng-select>
                </div>
                <div class="form-group col-6">
                    <label for="testFrequencySelect">Test frequency</label>
                    <ng-select
                        id="testFrequencySelect"
                        name="testFrequencySelect"
                        [items]="standingData.TestFrequencies"
                        formControlName="TestFrequency"
                        bindLabel="Name"
                        bindValue="ID"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-6 right-block">
            <div class="row">
                <div class="form-group col">
                    <cerrix-textarea
                        fieldLabel="Test activity"
                        identifier="testActivityInput"
                        [rows]="7"
                        formControlName="TestActivity"
                    ></cerrix-textarea>
                </div>
            </div>
            <div class="form-group-separator"></div>
            <div class="row">
                <div class="form-group col">
                    <cerrix-textarea
                        fieldLabel="Test norm"
                        identifier="testNormInput"
                        [rows]="7"
                        formControlName="TestNorm"
                    ></cerrix-textarea>
                </div>
            </div>
        </div>
    </div>
</div>
