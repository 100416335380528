import { FindingReportStoreModel } from "./finding-report-store-model";

export class FindingReportDetailsModel extends FindingReportStoreModel {
    guid: string = null;
    identifier: number = null;
    createdAt: Date = null;
    modifiedAt: Date = null;
    reportTypeName: string = null;
    overallScoreName: string = null;
    overallScoreColor: string = null;
    validationTypeName: string = null;

    responsibleName: string = null;
    reviewerName: string = null;
}
