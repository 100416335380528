import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { CerrixInviteType } from "@enums/invite/CerrixInviteType";
import { InviteModel } from "@models/invite/InviteModel";
import { InviteMessage } from "@models/invite/InviteMessage";
import { InviteSendModel } from "@models/invite/InviteSendModel";

@Injectable()
export class CerrixInviteDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "invites");
    }

    getInviteModel(inviteType: CerrixInviteType, objectID: number, objectGuid: string) {
        const url = `/${inviteType}?objectID=${objectID}&objectGuid=${objectGuid}`;
        return this.get<InviteModel>(url);
    }

    sendInvite(inviteType: CerrixInviteType, inviteObject: InviteSendModel) {
        let url = `/send`;
        if (inviteType === CerrixInviteType.CerrixForm) {
            url += "/form";
        }

        return this.post(inviteObject, url);
    }

    storeTemplate(message: InviteMessage) {
        const url = `/template`;
        return this.post<boolean>(message, url);
    }

    deleteTemplate(inviteType: CerrixInviteType, templateID: number) {
        const url = `/template/${inviteType}/${templateID}`;
        return this.delete(url);
    }
}
