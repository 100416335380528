import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TestPeriodModel } from "../shared/test-period.model";

export class EffectivenessWizardStandingData {
    TestPeriodData: TestPeriodModel[];
    SampleGenMethData: IdNameCombination[];
    ProductiveUserIds: number[];
    SourceDocumentUploaderData: IdNameCombination[];
    EvidenceUploaderData: IdNameCombination[];
    TesterData: IdNameCombination[];
    ReviewerData: IdNameCombination[];
    LinkedStandardData: IdNameCombination[];
    QualityAspectData: IdNameCombination[];
}
