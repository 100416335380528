import fontawesomeIcons from '../../assets/icons/fontawesome.json';
import flagIcons from '../../assets/icons/flags.json';

export class Icon {
    name: string;
    selector: string;
    categories: string[];
    tags?: string[];
}

export class SelectableIcons {
    static categories = ["Accessibility", "Alert", "Animals", "Arrows", "Audio & Video", "Automotive", "Autumn", "Beverage", "Brands", "Buildings", "Business", "Camping", "Charity", "Chat", "Chess", "Childhood", "Clothing", "Code", "Communication", "Computers", "Construction", "Currency", "Date & Time", "Design", "Editors", "Education", "Emoji", "Energy", "Files", "Finance", "Fitness", "Flags", "Food", "Fruits & Vegetables", "Games", "Genders", "Halloween", "Hands", "Health", "Holiday", "Hotel", "Household", "Images", "Interfaces", "Logistics", "Maps", "Maritime", "Marketing", "Mathematics", "Medical", "Moving", "Music", "Objects", "Payments & Shopping", "Pharmacy", "Political", "Religion", "Science", "Science Fiction", "Security", "Shapes", "Shopping", "Social", "Spinners", "Sports", "Spring", "Status", "Summer", "Tabletop Gaming", "Toggle", "Travel", "Users & People", "Vehicles", "Weather", "Winter", "Writing"];

    static faIcons = fontawesomeIcons;
    static flagIcons = flagIcons;
    static icons: Icon[] = SelectableIcons.faIcons.concat(SelectableIcons.flagIcons);
}