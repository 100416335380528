import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "task-get-task-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class TaskGetTaskDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Task Data API",
            httpMethod: "GET",
            path: "/taskdata/tasks",

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: [
                        {
                            guid: "string",
                            name: "string",
                            description: "string",
                            responsibles: [
                                {
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            reviewers: [
                                {
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            actionDate: "datetime",
                            status: {
                                id: "number",
                                name: "string",
                            },
                            score: {
                                id: "number",
                                name: "string",
                                color: "string",
                            },
                            reviewer: {
                                guid: "string",
                                name: "string",
                            },
                            linkedDocuments: [
                                {
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            comments: "string",
                            dateCompleted: "datetime",
                            linkedOrganizations: [
                                {
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            linkedBusinessDimensions: [
                                {
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            linkedFrameworkDimensions: [
                                {
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            linkedControls: [
                                {
                                    identifier: "string",
                                    guid: "string",
                                    name: "string",
                                },
                            ],
                            controlOwners: ["string"],
                        },
                    ],
                },
            ],
        };
    }
}
