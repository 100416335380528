import {
    StandingDataField,
    StandingDataCategory,
} from "@app/shared/models/GenericList/GenericStandingDataConfig";
import { IdNameCombination } from "@models/generic/IdNameCombination";

export class StandingDataHelper {
    static doForField(list: (StandingDataField | StandingDataCategory)[], callback) {
        list.forEach((item) => {
            if ((item as StandingDataField).fieldType >= 0) {
                callback(item);
            } else {
                this.doForField((item as StandingDataCategory).fields, callback);
            }
        });
    }

    /**
     * Get IdNameCombination[] with disabled flag set for ng-select items
     * @param options Items for which disabled needs to be set
     * @param ids (Id's to disable)
     * @returns Updated options (set to trigger change tracking)
     */
    public static getOptionsDisabledByIds(
        options: IdNameCombination[],
        ids: number[]
    ): IdNameCombination[] {
        return options.map((option) => ({ ...option, disabled: ids.includes(option.ID) }));
    }
}
