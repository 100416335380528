import {
    ComponentPortal,
    ComponentType,
    DomPortalOutlet,
    PortalInjector,
    TemplatePortal,
} from "@angular/cdk/portal";
import {
    ApplicationRef,
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    OnDestroy,
    TemplateRef,
    ViewRef,
} from "@angular/core";
import { POPOUT_MODAL_DATA, POPOUT_MODALS, POPOUT_WINDOW_REF } from "./popup.tokens";
import { IPopupConfig } from "./models/IPopupConfig";

@Injectable()
export class PopupService implements OnDestroy {
    styleSheetElements: HTMLLinkElement[];

    constructor(
        private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef
    ) {}

    ngOnDestroy() {}

    forceOpenInNewWindow(url: string) {
        this.openOnce(url, "_blank");
    }

    openPopoutModal<T>(popupConfig: IPopupConfig<T>) {
        const windowInstance = this.openOnce("assets/popup/popup.html", "MODAL_POPOUT");

        setTimeout(() => {
            this.createCDKPortal(popupConfig, windowInstance);
        }, 1000);
    }

    private openOnce(url, target) {
        const winRef = window.open("", target, "");
        if (winRef?.location.href === "about:blank") {
            winRef.location.href = url;
        }
        return winRef;
    }

    private createCDKPortal<T>(popupConfig: IPopupConfig<T>, windowInstance: Window) {
        if (windowInstance) {
            const outlet = new DomPortalOutlet(
                windowInstance.document.body,
                this.componentFactoryResolver,
                this.applicationRef,
                this.injector
            );

            this.copyStyleFromApplication(windowInstance);

            this.styleSheetElements.last().onload = () => {
                windowInstance.document.body.innerText = "";

                const injector = this.createInjector(popupConfig.data, windowInstance);

                windowInstance.document.title = popupConfig.title;
                const containerRef = this.createCustomComponentContainer(
                    popupConfig,
                    outlet,
                    injector
                );

                if (
                    containerRef instanceof EmbeddedViewRef ||
                    containerRef instanceof ViewRef ||
                    containerRef instanceof ChangeDetectorRef ||
                    (containerRef as any).detectChanges
                ) {
                    (containerRef as any).detectChanges();
                } else if (containerRef instanceof ComponentRef) {
                    containerRef.changeDetectorRef.detectChanges();
                }

                windowInstance.addEventListener("beforeunload", () => {
                    containerRef.destroy();
                });

                POPOUT_MODALS["windowInstance"] = windowInstance;
                POPOUT_MODALS["outlet"] = outlet;
            };
        }
    }

    isPopoutWindowOpen() {
        return POPOUT_MODALS["windowInstance"] && !POPOUT_MODALS["windowInstance"].closed;
    }

    focusPopoutWindow() {
        POPOUT_MODALS["windowInstance"].focus();
    }

    closePopoutModal() {
        Object.keys(POPOUT_MODALS).forEach((modalName) => {
            if (POPOUT_MODALS["windowInstance"]) {
                POPOUT_MODALS["windowInstance"].close();
            }
        });
    }

    private createCustomComponentContainer<T>(
        popupConfig: IPopupConfig<T>,
        outlet: DomPortalOutlet,
        injector: Injector
    ) {
        let containerRef: ComponentRef<T> | EmbeddedViewRef<T>;
        const componentOrTemplateRef =
            popupConfig.componentOrTemplateRef.componentType ||
            popupConfig.componentOrTemplateRef.template.templateRef;
        if (componentOrTemplateRef instanceof TemplateRef) {
            const containerPortal = new TemplatePortal<any>(
                componentOrTemplateRef,
                popupConfig.componentOrTemplateRef.template.viewContainerRef,
                { $implicit: popupConfig.data }
            );
            containerRef = outlet.attach(containerPortal);
        } else {
            const containerPortal = new ComponentPortal(componentOrTemplateRef, null, injector);
            containerRef = outlet.attach(containerPortal);
        }

        return containerRef;
    }

    private createInjector(data, windowInstance): Injector {
        return Injector.create({
            providers: [
                { provide: POPOUT_MODAL_DATA, useValue: data },
                { provide: POPOUT_WINDOW_REF, useValue: windowInstance },
            ],
            parent: this.injector,
        });
    }

    private copyStyleFromApplication(windowInstance) {
        this.styleSheetElements = this.getStyleSheetElements();
        this.styleSheetElements.forEach((element) => {
            windowInstance.document.head.appendChild(element);
        });

        document.querySelectorAll("style").forEach((htmlElement) => {
            windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
        });

        const obs = new MutationObserver((mutations) => {
            mutations.forEach((m) => {
                m.addedNodes.forEach((n) => {
                    if (n instanceof HTMLStyleElement) {
                        windowInstance.document.head.appendChild(n.cloneNode(true));
                    }
                });
            });
        });

        obs.observe(document.head, { subtree: false, attributes: false, childList: true });
    }

    private getStyleSheetElements() {
        return (
            Array.from(document.querySelectorAll("link"))
                .map((htmlElement) => {
                    if (htmlElement.rel === "stylesheet") {
                        const styleSheetElement = document.createElement("link");
                        const absoluteUrl = new URL(htmlElement.href).href;
                        styleSheetElement.rel = "stylesheet";
                        styleSheetElement.href = absoluteUrl;
                        return styleSheetElement;
                    }
                })
                // Filter out empty
                .filter(Boolean)
        );
    }
}
