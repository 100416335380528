import { FormPageType } from "@app/forms/enums/FormPageType";
import {
    Form,
    FormPage,
    WorkflowPageDefinition,
    FormField,
    FormFieldConfig,
} from "@app/forms/models";
import { FormDesignFieldValidation } from "./FormDesignFieldValidation";
import { MapbackProperty } from "@app/forms/models/mapback/MapbackProperty";
import { parseTyped } from "@methods/CommonMethods";
import { FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { Configuration, AppConstants } from "@app/app.constants";

export class FormDesignPageValidation {
    static ValidateForm(form: Form, mapbackProperties: MapbackProperty[]): string[] {
        const formValidations: string[] = [];

        if (form.Title.trim().length === 0) {
            formValidations.push("Missing Form Title");
        } else {
            const maxFieldLength = AppConstants.AppInjector.get(Configuration).MaxTextFieldLength;
            if (form.Title.length > maxFieldLength) {
                formValidations.push(`Form Title can't exceed ${maxFieldLength} characters`);
            }
        }

        if (!form.Category || form.Category === 0) {
            formValidations.push("Missing Form Category");
        }

        if (!form.Responsibles || form.Responsibles.length === 0) {
            formValidations.push("Missing responsible");
        }

        const start = new Date(form.StartDate);
        const end = new Date(form.EndDate);

        if (form.StartDate && form.EndDate && start > end) {
            formValidations.push("Startdate cannot be after enddate!", "Date error");
        }

        if (start.getFullYear() > 9999 || end.getFullYear() > 9999) {
            formValidations.push("Start or End Date for year 10000 or later are not supported");
        }

        if (form.Workflow && form.MaxStallingDays) {
            if (isNaN(form.MaxStallingDays) || form.MaxStallingDays < 0) {
                formValidations.push(
                    "'Reviewer stalling days' has to be greater or equal to 0 (0 means off)."
                );
            }
        }

        FormDesignPageValidation.ValidateWorkflow(form).forEach((validation) =>
            formValidations.push(validation)
        );

        form.Pages.forEach((page, index) => {
            if (!FormDesignPageValidation.ValidatePage(page, index)) {
                formValidations.push(page.Validation);
            }
        });

        FormDesignPageValidation.ValidateMapback(form, mapbackProperties).forEach((validation) =>
            formValidations.push(validation)
        );

        return formValidations;
    }

    static ValidateWorkflow(form: Form): string[] {
        const validations: string[] = [];
        if (form.Workflow) {
            if (!form.WorkflowDefinitions || !form.WorkflowDefinitions.any()) {
                validations.push("Missing Workflow definition(s)");
            } else {
                FormDesignPageValidation.ValidateWorkflowDefinitions(form).addTo(validations);
            }

            FormDesignPageValidation.ValidateWorkflowPages(form).addTo(validations);
        }
        return validations;
    }

    static ValidateWorkflowDefinitions(form: Form): string[] {
        const validations: string[] = [];

        form.WorkflowDefinitions.forEach((formWorkflowDefinition) => {
            form.Pages.forEach((page) => {
                // Check if every page has every Workflow definition, should never fail so can be removed for performance
                if (
                    !page.WorkflowPageDefinitions ||
                    !page.WorkflowPageDefinitions.some(
                        (pageWorkflowDefinition) =>
                            pageWorkflowDefinition.WorkflowDefinitionID ===
                            formWorkflowDefinition.ID
                    )
                ) {
                    validations.push(
                        "Missing Workflow definition " +
                            formWorkflowDefinition.Name +
                            " on Page " +
                            page.Title
                    );
                } else {
                    page.WorkflowPageDefinitions.forEach((pageWorkflowDefinition) => {
                        if (
                            pageWorkflowDefinition.WorkflowDefinitionID ===
                            formWorkflowDefinition.ID
                        ) {
                            if (
                                !FormDesignPageValidation.IsValidWorkflowGroup(
                                    pageWorkflowDefinition
                                )
                            ) {
                                validations.push(
                                    "Page " +
                                        page.Title +
                                        " is missing Workflow definitions for " +
                                        formWorkflowDefinition.Name
                                );
                            }
                        }
                    });
                }
            });
        });

        return validations;
    }

    static IsValidWorkflowGroup(pageDefinition: WorkflowPageDefinition): boolean {
        return (
            (pageDefinition.Groups && pageDefinition.Groups.any()) ||
            pageDefinition.LinkedToPrevious ||
            pageDefinition.LinkedToStarter
        );
    }

    static ValidateWorkflowPages(form: Form): string[] {
        const validations: string[] = [];

        if (form.Pages[form.Pages.length - 1].Type !== FormPageType.Review) {
            validations.push("Last page must be a Review page");
        }

        return validations;
    }

    static ValidateMapback(form: Form, mapbackProperties: MapbackProperty[]): string[] {
        if (!form.MapBack || form.MapBack === 0) {
            return [];
        }
        let validations: string[] = [];
        let mapbackFieldsValid = true;

        const mappedFields: FormField[] = [];
        form.Pages.forEach((p) =>
            p.Fields.filter((f) => f.MapBackField > 0).forEach((f) => mappedFields.push(f))
        );

        // Duplicate mapping check
        const duplicateProps: number[] = [];
        mappedFields.forEach((field) => {
            const mappedPropFields = mappedFields.filter(
                (mf) => mf.MapBackField === field.MapBackField
            );
            if (mappedPropFields.length > 1 && duplicateProps.indexOf(field.MapBackField) < 0) {
                mappedPropFields.forEach((mpf) => {
                    mpf.Valid = mapbackFieldsValid = false;
                    mpf.Validation += `${
                        mpf.Validation.length > 0 ? "\n" : ""
                    }This Mapback field is mapped multiple times!`;
                });
                duplicateProps.push(field.MapBackField);
            }
        });

        if (duplicateProps.length > 0) {
            const propString: string[] = [];
            duplicateProps.forEach((dp) => {
                const prop = mapbackProperties.find((x) => x.ID === dp);
                propString.push(prop.Name);
            });
            validations.push(
                `\nThe following Mapback fields are required to be mapped: '${propString.join(
                    ", "
                )}'`
            );
        }

        // All required mapped check
        const requiredProperties = mapbackProperties.filter((prop) => prop.Required);
        const nonMappedRequiredProps = requiredProperties.filter(
            (rp) => !mappedFields.some((mf) => mf.MapBackField === rp.ID)
        );
        if (nonMappedRequiredProps.length > 0) {
            const requiredPropertiesText = nonMappedRequiredProps
                .map((x) => " - (" + x.Category + ") " + x.Name)
                .join("<br>");
            validations.push(
                `<br>The following Mapback fields are required, but are not mapped:<br> ${requiredPropertiesText} <br>`
            );
        }

        // Force mapback config if user has set field to mapback
        mappedFields.forEach((field) => {
            const mapping = mapbackProperties.find((mp) => mp.ID === field.MapBackField);
            const mappedConfig = parseTyped<FormFieldConfig>(
                field.CustomSettings,
                new FormFieldConfig()
            );

            if (mapping.Required && !field.Required) {
                field.Valid = mapbackFieldsValid = false;
                field.Validation += `${
                    field.Validation.length > 0 ? "\n" : ""
                }Mapback field is required, but field is set to not required.`;
            }

            if (
                mapping.Config == null &&
                mapping.AllowedFieldTypes &&
                mapping.AllowedFieldTypes.length > 0 &&
                mapping.AllowedFieldTypes.indexOf(field.FieldType) < 0
            ) {
                field.Valid = mapbackFieldsValid = false;
                field.Validation += `${
                    field.Validation.length > 0 ? "\n" : ""
                }Field Type not compatible with Mapback field!`;
            }

            if (mapping.Config != null) {
                if (mapping.Config.fieldType && mapping.Config.fieldType !== field.FieldType) {
                    field.Valid = mapbackFieldsValid = false;
                    field.Validation += `${
                        field.Validation.length > 0 ? "\n" : ""
                    }Field type does not match field type of Mapback field!`;
                }

                if (mapping.Config.fieldType === FormFieldType.Structure) {
                    if (
                        mapping.Config.structureType &&
                        mapping.Config.structureType !== mappedConfig.structureType
                    ) {
                        field.Valid = mapbackFieldsValid = false;
                        field.Validation += `${
                            field.Validation.length > 0 ? "\n" : ""
                        }Structure type does not match structure type of Mapback field!`;
                    }

                    if (mapping.Config.parameters && mapping.Config.parameters.length > 0) {
                        if (
                            !mappedConfig.parameters ||
                            mappedConfig.parameters.length !== mapping.Config.parameters.length ||
                            mapping.Config.parameters.some(
                                (x, i) => x !== mappedConfig.parameters[i]
                            )
                        ) {
                            field.Valid = mapbackFieldsValid = false;
                            field.Validation += `${
                                field.Validation.length > 0 ? "\n" : ""
                            }Allowed Source Data does not match Mapback field provided data!`;
                        }
                    }
                }

                if (mapping.Config.fieldType === FormFieldType.Checkbox) {
                    if (
                        mapping.Config.minimumSelection &&
                        mapping.Config.minimumSelection !== mappedConfig.minimumSelection
                    ) {
                        field.Valid = mapbackFieldsValid = false;
                        field.Validation += `${
                            field.Validation.length > 0 ? "\n" : ""
                        }Minimum selectable options does not match Mapback field!`;
                    }

                    if (
                        mapping.Config.maximumSelection &&
                        mapping.Config.maximumSelection !== mappedConfig.maximumSelection
                    ) {
                        field.Valid = mapbackFieldsValid = false;
                        field.Validation += `${
                            field.Validation.length > 0 ? "\n" : ""
                        }Maximum selectable options does not match Mapback field!`;
                    }

                    if (
                        mapping.Config.options &&
                        mapping.Config.options.length !== mappedConfig.options.length
                    ) {
                        field.Valid = mapbackFieldsValid = false;
                        field.Validation += `${
                            field.Validation.length > 0 ? "\n" : ""
                        }Amount of options does not match Mapback provided amount of options!`;
                    }
                }
            }
        });

        if (!mapbackFieldsValid) {
            validations = ["Mapback fields are not valid. Please correct them."].concat(
                validations
            );
        }
        return validations;
    }

    static ValidatePage(page: FormPage, pageIndex: number): boolean {
        page.Valid = true;

        const pageNumber = pageIndex + 1;
        const pageValidations: string[] = [];

        if (page.Title.trim().length === 0) {
            pageValidations.push("Missing page title for page " + pageNumber);
            page.Valid = false;
        } else {
            const maxFieldLength = AppConstants.AppInjector.get(Configuration).MaxTextFieldLength;

            if (page.Title.length > maxFieldLength) {
                pageValidations.push(
                    `Page title can't exceed ${maxFieldLength} characters for page ` + pageNumber
                );
                page.Valid = false;
            }
        }

        if (page.Type === FormPageType.Entry) {
            if (!page.Fields.any()) {
                page.Valid = false;
                pageValidations.push(
                    "Page '" + (page.Title ? page.Title : pageNumber) + "' has no fields."
                );
            }

            page.Fields.forEach((field) => {
                FormDesignFieldValidation.ValidateField(field);

                if (!field.Valid) {
                    page.Valid = false;
                    pageValidations.push("Page " + page.Title + ": " + field.Validation);
                }
            });
        }
        if (!page.Valid) {
            page.Validation = pageValidations.join("<br>");
        }

        return page.Valid;
    }
}
