import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { FormField } from "@app/forms/models/detail/FormField";
import { Observable } from "rxjs";
import { FormEntryDateComponent } from "./entry-types/date/form-entry-date.component";
import { FormEntryFileUploadComponent } from "./entry-types/fileupload/form-entry-fileupload.component";
import { FormEntryInfoComponent } from "./entry-types/info/form-entry-info.component";
import { FormEntryNumberComponent } from "./entry-types/number/form-entry-number.component";
import { FormEntryOptionsComponent } from "./entry-types/options/form-entry-options.component";
import { FormEntryStructureComponent } from "./entry-types/structure/form-entry-structure.component";
import { FormEntryTextComponent } from "./entry-types/text/form-entry-text.component";
import { FormEntryVideoComponent } from "./entry-types/video/form-entry-video.component";
import { FormEntryWidgetComponent } from "./entry-types/widget/form-entry-widget.component";

@Component({
    selector: "form-entry-field",
    templateUrl: "./form-entry-field.component.html",
    styleUrls: ["./form-entry-field.component.scss"],
})
export class FormEntryFieldComponent implements OnChanges {
    @Input() field: FormField;
    @Input() answer: any;
    @Input() readonly: boolean;

    @Input() isReview: boolean;
    @Input() comment: string;

    @Output() answerChange = new EventEmitter<any>();
    @Output() commentChange = new EventEmitter<string>();

    @ViewChild("customComponent", { read: ViewContainerRef, static: true }) customComponent;
    compRef: any;

    constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnChanges() {
        if (this.compRef) {
            // Generic component values need to be updated every time it changes
            this.compRef.instance.field = this.field;
            this.compRef.instance.readonly = this.readonly;
            this.compRef.instance.answer = this.answer;

            // Some components need to process the answer
            if (this.compRef.instance.processAnswer) {
                this.compRef.instance.processAnswer();
            }
        } else {
            this.setComponent();
        }
    }

    setComponent() {
        const comp = this.getComponentFromType(this.field.FieldType);
        if (comp) {
            const componentFactory = this._componentFactoryResolver.resolveComponentFactory(comp);
            const viewContainerRef = this.customComponent;
            viewContainerRef.clear();
            this.compRef = viewContainerRef.createComponent(componentFactory);
            if (this.compRef.instance) {
                this.compRef.instance.field = this.field;
                this.compRef.instance.readonly = this.readonly;
                this.compRef.instance.answer = this.answer;
                const ev = this.compRef.instance.answerChange as Observable<any>;
                if (ev) {
                    if (this.readonly !== true) {
                        ev.subscribe((e) => {
                            this.answer = e;
                            this.emitChange();
                        });
                    }
                }
            }
        }
    }

    emitChange() {
        this.field.Valid = null;
        this.field.Validation = null;
        this.answerChange.emit(this.answer);

        if (this.isReview) {
            this.commentChange.emit(this.comment);
        }
    }

    getComponentFromType(type: FormFieldType): any {
        let comp: any;
        switch (type) {
            case FormFieldType.Text:
            case FormFieldType.TextArea:
                comp = FormEntryTextComponent;
                break;
            case FormFieldType.Date:
                comp = FormEntryDateComponent;
                break;
            case FormFieldType.Radio:
            case FormFieldType.Checkbox:
            case FormFieldType.Dropdown:
                comp = FormEntryOptionsComponent;
                break;
            case FormFieldType.Info:
                comp = FormEntryInfoComponent;
                break;
            case FormFieldType.FileUpload:
                comp = FormEntryFileUploadComponent;
                break;
            case FormFieldType.Structure:
                comp = FormEntryStructureComponent;
                break;
            case FormFieldType.Number:
                comp = FormEntryNumberComponent;
                break;
            case FormFieldType.Widget:
                comp = FormEntryWidgetComponent;
                break;
            case FormFieldType.Video:
                comp = FormEntryVideoComponent;
                break;
        }

        return comp;
    }
}
