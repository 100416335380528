import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { FindingReportLinkedSummaryComponent } from "./components/linked-summary/finding-report-linked-summary.component";

@NgModule({
    declarations: [FindingReportLinkedSummaryComponent],
    exports: [FindingReportLinkedSummaryComponent],
    imports: [CommonModule, SharedModule]
})
export class FindingReportSharedModule {}
