import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { AngularSplitModule } from "angular-split";
import { Create } from "directives/create.directive";
import { ColorSketchModule } from "ngx-color/sketch";
import { FilterComponent } from "./components/filter/filter.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MenubarComponent } from "./components/menubar/menubar.component";
import { ProcessOverviewComponent } from "./components/process-overview/process-overview.component";
import { PropertiesSidebarComponent } from "./components/properties-sidebar/properties-sidebar.component";
import { PrintComponent } from "./components/print/print.component";
import { ControlShapePropertiesComponent } from "./components/properties-sidebar/properties/control/control-shape-properties.component";
import { RiskShapePropertiesComponent } from "./components/properties-sidebar/properties/risk/risk-shape-properties.component";
import { ShapeSidebarComponent } from "./components/shape-sidebar/shape-sidebar.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { ProcessEditorComponent } from "./process-editor.component";
import { PrintProcessFlowComponent } from "./components/print/print-process-flow/print-process-flow.component";

@NgModule({
    declarations: [
        ProcessEditorComponent,
        ProcessOverviewComponent,
        FooterComponent,
        MenubarComponent,
        PropertiesSidebarComponent,
        ShapeSidebarComponent,
        ToolbarComponent,
        FilterComponent,
        Create,
        RiskShapePropertiesComponent,
        ControlShapePropertiesComponent,
        PrintComponent,
        PrintProcessFlowComponent,
    ],
    imports: [CommonModule, SharedModule, AngularSplitModule, ColorSketchModule, PortalModule],
    exports: [ProcessEditorComponent, ProcessOverviewComponent],
    providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
})
export class ProcessEditorModule {
    static forRoot(): ModuleWithProviders<ProcessEditorModule> {
        return {
            ngModule: ProcessEditorModule,
            providers: [ProcessEditorDataService],
        };
    }
}
