export enum CustomFieldType {
    Unknown = 0,
    Textfield = 1,
    TextfieldDescription = "Textfield",
    Textarea = 2,
    TextareaDescription = "Textarea",
    DateField = 3,
    DateFieldDescription = "Date field",
    NumberField = 4,
    NumberFieldDescription = "Number field",
    SingleSelect = 5,
    SingleSelectDescription = "Dropdown single select",
    MultiSelect = 6,
    MultiSelectDescription = "Dropdown multi select",
}
