import { Component, Input, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import {
    CerrixButtonComponent,
    CerrixInputComponent,
    CerrixTitleComponent,
} from "@cerrix/components";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabChangeEvent, MatTabsModule } from "@angular/material/tabs";
import { IncidentLinkModel } from "@app/incident/models/incident-link.model";
import { IncidentLinkService } from "@app/incident/services/incident-link.service";

@Component({
    selector: "cerrix-links-edit",
    templateUrl: "./cerrix-links-edit.component.html",
    styleUrls: ["./cerrix-links-edit.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        CerrixButtonComponent,
        CerrixTitleComponent,
        MatTabsModule,
        CerrixInputComponent,
    ],
    providers: [],
})
export class CerrixLinksEditomponent {
    @Input() public dialogData = inject(MAT_DIALOG_DATA);

    private readonly dialogRef: MatDialogRef<object, IncidentLinkModel[]> = inject(MatDialogRef);
    private linksService: IncidentLinkService;

    protected incidentId: string;
    protected label: string;
    protected linkMap: Map<string, boolean> = new Map<string, boolean>();
    protected links: IncidentLinkModel[] = [];
    protected displayedLinks: IncidentLinkModel[] = [];

    ngOnInit(): void {
        this.incidentId = this.dialogData.incidentId;
        this.linksService = this.dialogData.linksService;
        this.label = this.dialogData.label;
        this.linkMap = new Map(this.dialogData.selectedLinkIds.map((id: string) => [id, true]));

        this.linksService.getAll().subscribe((links) => {
            this.displayedLinks = this.links = links;
        });
    }

    protected onTabChange(event: MatTabChangeEvent): void {
        if (event.index === 0) {
            this.displayedLinks = this.links;
        } else {
            this.displayedLinks = this.links.filter((link) => this.linkMap.has(link.id));
        }
    }

    protected displayAll(): void {
        this.displayedLinks = this.links;
    }

    protected displaySelected(): void {
        this.displayedLinks = this.links.filter((link) => this.linkMap.has(link.id));
    }

    protected onClick(id: string): void {
        if (this.linkMap.has(id)) {
            this.linkMap.delete(id);
        } else {
            this.linkMap.set(id, true);
        }
    }

    protected onSearch(searchEvent: Event): void {
        const searchTerm = (searchEvent.target as HTMLInputElement).value;

        if (searchTerm.length === 0) {
            this.displayedLinks = this.links;
        } else {
            this.displayedLinks = this.links.filter(
                (link) =>
                    link.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    link.identifier.toString().includes(searchTerm)
            );
        }
    }

    protected onApplyChanges(): void {
        const selectedLinks = this.links.filter((link) => this.linkMap.has(link.id));

        this.dialogRef.close(selectedLinks);
    }

    protected search(searchTerm: string): void {
        this.linksService.search(searchTerm).subscribe((links) => {
            this.links = links;
        });
    }
}
