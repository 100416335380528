import {
    ComponentFactoryResolver,
    Directive,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { ManualPage } from "@app/manual/models/manual-page";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Directive()
export abstract class BaseManualSection implements OnInit {
    @ViewChild("manualPageContent", { read: ViewContainerRef, static: true }) manualPageContent;
    @Input() cerrixTab: TabModel;

    tableOfContentsHeader = "Pages";
    tableOfContents: ManualPage[];
    pageToRender: any;

    set activePageID(value: string) {
        this.cerrixTab.config.manualPage = value;
    }
    get activePageID(): string {
        return this.cerrixTab.config.manualPage;
    }

    constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit() {
        this.initTableOfContents();
        this.setInitialPage();
    }

    /** This method should be overridden with an implementation of the pages within that section */
    protected initTableOfContents() {
        throw new Error("Table of Contents init is not implemented!");
    }

    private setInitialPage() {
        let pageToLoad = this.findInitialPage(this.tableOfContents, this.activePageID);

        // If we did not find the requested page we reset the requested page and open the first page.
        if (!pageToLoad && this.activePageID) {
            pageToLoad = this.findInitialPage(this.tableOfContents);
        }

        if (pageToLoad) {
            this.itemClick(pageToLoad);
        }
    }

    private findInitialPage(pages: ManualPage[], targetPageID?: string) {
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];

            if (targetPageID) {
                if (page.pageid == targetPageID) {
                    return page;
                }
            } else if (page.component) {
                return page;
            }

            if (page.children) {
                const foundInChildren = this.findInitialPage(page.children, targetPageID);
                if (foundInChildren) {
                    page.expanded = true;
                    return foundInChildren;
                }
            }
        }

        return null;
    }

    itemClick(page: ManualPage) {
        if (page.component) {
            // Open component
            this.openPage(page);
            this.activePageID = page.pageid;
        } else if (page.children && page.children.length > 0) {
            page.expanded = !page.expanded;
        }
    }

    openPage(page: ManualPage) {
        if (this.manualPageContent) {
            this.manualPageContent.clear();
        }

        if (page.component == null) {
            throw new Error(`Manual Page '${page.name}' has no component attached to it.`);
        }

        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
            page.component
        );

        const componentRef = this.manualPageContent.createComponent(componentFactory);
        if (componentRef.instance) {
            // Set values within the component here.
            componentRef.instance.pageId = page.pageid;
        }
    }
}
