import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { DisplayItemModel } from '@models/generic/DisplayItemModel';

export class EventRiskControlModel implements IHasGuid {
    id: number;
    guid: string;
    name: string;
    failureType: string;
    failureTypeId: number;
    comment: string;
    displayItems: DisplayItemModel[] = [];
    batchControls: number[] = [];
}