import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  pure: true
})
export class FileSizePipe implements PipeTransform {
  constructor() { }

  private units = [
      'bytes',
      'KB',
      'MB',
      'GB',
      'TB'
  ];

  transform(value: any): string | null {
      if (value == null || value === '' || isNaN(value) || !isFinite(value)) {
          return null;
      }

      let unit = 0;

      while (value >= 1024) {
          value /= 1024;
          unit++;
      }

      return value.toFixed(2) + ' ' + this.units[unit];
  }
}
