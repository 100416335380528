<div class="detail-page-action-bar">
    <button class="btn btn-cerrix" (click)="openPatchesModal()">
        <i class="fas fa-bars"></i>
        <span class="d-none d-lg-inline">View active patches</span>
    </button>
    <span class="sub-message">
        Patches will be active for a user after he or she or it relogs OR for all users when the
        website is restarted.
    </span>
    <div class="workflow-area">
        <button class="btn btn-outline-danger" (click)="deleteLink(true)" *ngIf="selectedRole">
            <i class="fas fa-backspace"></i>
            <span class="d-none d-lg-inline ms-2">Reset '{{ selectedRole.roleName }}' patches</span>
        </button>
        <button class="btn btn-danger" (click)="deleteLink(false)">
            <i class="fas fa-trash"></i>
            <span class="d-none d-lg-inline ms-2">Reset all patches</span>
        </button>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Role - Right definition</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm" *ngIf="roles">
        <div class="row">
            <div class="col-6">
                <ng-select
                    id="roleSelect"
                    name="roleSelect"
                    [items]="roles"
                    [(ngModel)]="selectedRole"
                    (change)="setRight()"
                    [closeOnSelect]="true"
                    [multiple]="false"
                    [clearable]="false"
                    [loading]="!roles"
                    [searchFn]="customSearchRoleFn"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <b>{{ item.roleName }}</b> -
                        <small class="text-muted">{{ item.roleDescription }}</small>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <b>{{ item.roleName }}</b
                        ><br />
                        <small class="text-muted">{{ item.roleDescription }}</small>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-6">
                <ng-select
                    id="rightSelect"
                    name="rightSelect"
                    [items]="selectedRole.rights"
                    [(ngModel)]="selectedRight"
                    [closeOnSelect]="true"
                    [multiple]="false"
                    [clearable]="false"
                    *ngIf="selectedRole"
                    [searchFn]="customSearchRightFn"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <i
                            class="fas fa-circle pr-2"
                            [class.green]="item.currentValue"
                            [class.red]="!item.currentValue"
                        ></i>
                        <b
                            >{{ item.rightName
                            }}{{ item.currentValue === item.defaultValue ? "" : "*" }}</b
                        >
                        - <small class="text-muted">{{ item.rightDescription }}</small>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <i
                            class="fas fa-circle pr-2"
                            [class.green]="item.currentValue"
                            [class.red]="!item.currentValue"
                        ></i>
                        <b
                            >{{ item.rightName
                            }}{{ item.currentValue === item.defaultValue ? "" : "*" }}</b
                        ><br />
                        <small class="text-muted">{{ item.rightDescription }}</small>
                    </ng-template>
                </ng-select>
            </div>
        </div>

        <hr />

        <div class="form-group">
            <label for="roleNameTxt">Role name</label>
            <input
                type="text"
                class="form-control form-control-sm"
                id="roleNameTxt"
                name="roleNameTxt"
                readonly
                [value]="selectedRole.roleName + ' - ' + selectedRole.roleDescription"
                inputTooltip
            />
        </div>

        <div class="form-group">
            <label for="rightNameTxt">Right name</label>
            <input
                type="text"
                class="form-control form-control-sm"
                id="rightNameTxt"
                name="rightNameTxt"
                readonly
                [value]="selectedRight.rightName + ' - ' + selectedRight.rightDescription"
                inputTooltip
            />
        </div>

        <div class="form-group">
            <label for="defaultTxt">Default</label>
            <input
                type="text"
                class="form-control form-control-sm"
                id="defaultTxt"
                name="defaultTxt"
                readonly
                [value]="selectedRight.defaultValue ? 'Linked' : 'Unlinked'"
                inputTooltip
            />
        </div>

        <div class="form-group">
            <label for="currentTxt">Current</label>
            <input
                type="text"
                class="form-control form-control-sm"
                id="currentTxt"
                name="currentTxt"
                readonly
                [value]="
                    selectedRight.currentValue === selectedRight.defaultValue
                        ? 'Default (' + (selectedRight.defaultValue ? 'Linked' : 'Unlinked') + ')'
                        : selectedRight.currentValue
                        ? 'Linked'
                        : 'Unlinked'
                "
                inputTooltip
            />
        </div>

        <button
            class="btn btn-sm btn-primary"
            *ngIf="selectedRight.currentValue !== selectedRight.defaultValue"
            (click)="changeLink()"
        >
            Restore to default ({{ selectedRight.defaultValue ? "Linked" : "Unlinked" }})
        </button>

        <button
            class="btn btn-sm btn-{{ selectedRight.defaultValue ? 'danger' : 'success' }}"
            *ngIf="selectedRight.currentValue === selectedRight.defaultValue"
            (click)="changeLink()"
        >
            {{ selectedRight.defaultValue ? "Unlink" : "Link" }}
        </button>
    </div>
</div>
