import { EntityRoles } from "@app/datamanagement/enums/EntityRoles";
import { SystemModel } from "./SystemModel";

export class EntityModel {
    processingPurposeId: number;
    id: number;
    name: string;
    role: EntityRoles;
    subProcessor: boolean;
    systemsRequired: boolean;
    systems: SystemModel[];

    // only used clientside
    isNew: boolean;
    selected: boolean;

    constructor() {
        this.systems = [];
    }
}