import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { GenericGroupedListConfig } from "@app/shared/models/GenericList/GenericGroupedListConfig";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ToastrService } from "ngx-toastr";
import { Configuration } from "../../../app.constants";
import { GenericListManagerBase } from "../GenericListManagerBase";
import { LocalizationService } from "@app/shared";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { GenericFormEditorComponent } from "../generic-form-editor/generic-form-editor.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "generic-grouped-list-manager",
    templateUrl: "./generic-grouped-list-manager.component.html",
    styleUrls: ["./generic-grouped-list-manager.component.scss"],
})
export class GenericGroupedListManagerComponent extends GenericListManagerBase implements OnInit {
    @Input() config: GenericGroupedListConfig;
    @ViewChild("editorCmp", { static: true }) editorCmp: GenericFormEditorComponent;

    headers: string[] = [];
    prettyHeaders = {};

    groups: GroupedList[];
    pageReady = false;

    constructor(
        standingdataDS: StandingdataDataService,
        toastr: ToastrService,
        configuration: Configuration,
        propmtService: CerrixPromptService,
        private localizationService: LocalizationService
    ) {
        super(standingdataDS, toastr, configuration, propmtService);
    }

    ngOnInit() {
        this.editor = this.editorCmp;
        this.baseConfig = this.config;
        this.config.reload = async () => {
            this.groups = null;
            this.baseConfig = this.config;
            await this.loadPage();
        };

        this.loadPage();
    }

    async loadPage() {
        if (this.groups && this.groups.length > 0) {
            this._promptService
                .confirm(
                    "Reload list",
                    `Are you sure you want to reload ${this.config.name}? Unsaved changes will be lost!`
                )
                .onConfirm()
                .subscribe(() => {
                    this.executeLoadPage();
                });
            return;
        }

        await this.executeLoadPage();
    }

    private async executeLoadPage() {
        this.ready = false;
        this.groups = [];
        this.headers = [];
        this.prettyHeaders = {};

        if (!this.config.cellConfig) {
            this.config.cellConfig = {};
        }

        this.config.fields.forEach((f) => {
            if (!f.hideInOverview) {
                this.headers.push(f.fieldName);
                this.prettyHeaders[f.fieldName] = f.prettyName ? f.prettyName : f.fieldName;
            }

            if (!this.config.cellConfig[f.fieldName]) {
                this.config.cellConfig[f.fieldName] = {
                    alignLeft: false,
                };
            }
        });

        await super.loadPage();
    }

    // This method overrides parent method.
    tableReadyMethod() {
        this.setGroupedTable();
    }

    setGroupedTable() {
        const distinctList = this.data.map((x) => x[this.config.groupByProp]).distinct();

        distinctList.forEach((dG) => {
            let rows = this.data.filter((r) => r[this.config.groupByProp] === dG);

            const summaries = [];

            this.config.fields.forEach((f) => {
                const fieldSummary = this.config.cellSummaries[f.fieldName];
                if (!fieldSummary) {
                    summaries.push({ summary: "-", header: f.fieldName });
                    return;
                }

                let value = fieldSummary.cellStringFormat;

                if (fieldSummary.embedGroupName) {
                    const groupName = rows[0][this.config.groupByDisplayName];
                    value = value.replace("[embedGroupName]", groupName);
                }

                if (fieldSummary.embedCount) {
                    value = value.replace("[embedCount]", "" + rows.length);
                }

                if (fieldSummary.embedSum) {
                    const sumCol =
                        typeof fieldSummary.embedSum === "string"
                            ? fieldSummary.embedSum
                            : f.fieldName;
                    const sum = rows.map((r) => r[sumCol] as number).reduce((a, b) => a + b);
                    value = value.replace("[embedSum]", "" + sum);
                }

                if (fieldSummary.embedAvg) {
                    const avgCol =
                        typeof fieldSummary.embedAvg === "string"
                            ? fieldSummary.embedAvg
                            : f.fieldName;
                    const numbers = rows.map((r) => r[avgCol] as number);
                    const total = numbers.reduce((a, b) => a + b);
                    const avg = total > 0 ? Math.round(total / numbers.length) : 0;
                    value = value.replace("[embedAvg]", "" + avg);
                }

                if (fieldSummary.embedColor) {
                    let groupValue = rows[0][fieldSummary.embedColor.textCol];
                    let colorValue = rows[0][fieldSummary.embedColor.colorCol];

                    summaries.push({
                        summary: groupValue,
                        header: f.fieldName,
                        color: colorValue,
                    });

                    return;
                }

                value = value.replace(/{{[a-zA-Z0-9]+}}/g, (match) => {
                    const embedProperty = match.substr(2, match.length - 4);
                    const propConfig = this.config.fields.find(
                        (x) => x.fieldName === embedProperty
                    );

                    let groupValue = rows[0][embedProperty];
                    if (propConfig) {
                        if (groupValue instanceof Date) {
                            if (propConfig.fieldType === GenericListFieldType.DateTime) {
                                groupValue = this.localizationService.formatDate(
                                    groupValue,
                                    this.localizationService.localizationInfo.dateTimeFormat
                                );
                            } else if (propConfig.fieldType === GenericListFieldType.Date) {
                                groupValue = this.localizationService.formatDate(groupValue);
                            }
                        }
                    } else {
                        if (groupValue instanceof Date) {
                            groupValue = this.localizationService.formatDate(groupValue);
                        }
                    }

                    if (!groupValue) {
                        groupValue = "-";
                    }

                    return groupValue;
                });

                summaries.push({ summary: value, header: f.fieldName });
            });

            let tableRows = this.tableData.filter((td) =>
                rows.some((r) => r[this.idProp] === td[this.idProp])
            );
            if (this.config.overviewSortBy) {
                const sortCol = this.baseConfig.overviewSortBy
                    ? this.baseConfig.overviewSortBy
                    : this.idProp;
                const sortAsc = this.baseConfig.overviewSortAsc === false ? false : true;

                tableRows = tableRows.sortBy(sortCol, sortAsc);
            }

            this.groups.push({
                cellSummaries: summaries,
                isExpanded: !this.config.startCollapsed,
                items: tableRows,
            });
        });
    }

    triggerClick(row: any) {
        this.activeRow = row;
        this.singleClick(row); // This will trigger open if the user is on mobile.
    }

    triggerDblClick(row: any) {
        this.editRow(row);
    }
}

class GroupedList {
    cellSummaries: { summary: string; header: string }[];
    isExpanded: boolean;

    items: any[];
}
