import { Injectable } from "@angular/core";

export enum ThemePreference {
    systemdefault = 10,
    light = 20,
    dark = 30,
}

@Injectable({
    providedIn: "platform",
})
/**
 * Implementation is in navbar-user where the user can cycle between (devicetheme, light, dark).
 * https://drafts.csswg.org/mediaqueries-5/#prefers-color-scheme
 */
export class CerrixThemingService {
    public currentTheme: ThemePreference;
    private get defaultTheme(): ThemePreference {
        return ThemePreference.light;
    }

    private darkmodeQueryMatcher = "(prefers-color-scheme: dark)";
    private darkModeStorageKey = "CERRIX_THEME_PREFERENCE";
    private get storageValue(): ThemePreference {
        const storedValue = localStorage.getItem(this.darkModeStorageKey);
        const preferencevalue =
            !isNaN(+storedValue) && +storedValue > 0
                ? <ThemePreference>+storedValue
                : this.defaultTheme;

        return preferencevalue;
    }
    private set storageValue(setTo: ThemePreference) {
        localStorage.setItem(this.darkModeStorageKey, "" + +setTo);
    }

    constructor() {
        this.currentTheme = this.storageValue;
        this.addListener();
        this.applyStyling();
    }

    public cycleTheme() {
        switch (this.currentTheme) {
            case ThemePreference.systemdefault:
                this.currentTheme = ThemePreference.light;
                break;
            case ThemePreference.light:
                this.currentTheme = ThemePreference.dark;
                break;
            case ThemePreference.dark:
                this.currentTheme = ThemePreference.systemdefault;
                break;
        }

        this.storageValue = this.currentTheme;
        this.applyStyling();
    }

    private applyStyling() {
        const useDarkMode =
            this.currentTheme == ThemePreference.systemdefault
                ? window.matchMedia && window.matchMedia(this.darkmodeQueryMatcher).matches
                : this.currentTheme == ThemePreference.dark;

        const root = document.querySelector<HTMLElement>(":root");
        if (useDarkMode) {
            root.classList.add("darkmode");
        } else {
            root.classList.remove("darkmode");
        }
    }

    private addListener() {
        if (window.matchMedia) {
            window.matchMedia(this.darkmodeQueryMatcher).addEventListener("change", () => {
                this.applyStyling();
            });
        }
    }
}
