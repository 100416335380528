import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { SentNotificationModel } from "../models/sentNotificationModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { SentNotificationOverviewModel } from "../models/sentNotificationOvervieuwModel";

@Injectable()
export class SentMailService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "sentmail");
    }

    getSentMails(): Observable<SentNotificationOverviewModel[]> {
        return this.get<SentNotificationOverviewModel[]>();
    }

    getSentMailDetail(mailId: number): Observable<SentNotificationModel> {
        const url = `/${mailId}`;
        return this.get<SentNotificationModel>(url);
    }
}
