import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { addParameterToUrl } from "@methods/CommonMethods";
import { DocumentMethods } from "@methods/DocumentMethods";
import { isGuid } from "@methods/uniqueMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable } from "rxjs";
import { Configuration } from "../../app.constants";
import { AuditUniverseInfoModel } from "../shared/data-models/audit-universe-info-model";
import { AuditUniverseLinkAuditModel } from "../shared/data-models/audit-universe-link-audit-model";
import { AuditUniverseModel } from "../shared/data-models/audit-universe-model";
import { AuditUniverseDetailPermissionModel } from "../shared/page-models/audit-universe-detail-permission-model";
import { AuditUniverseDetailStandingDataModel } from "../shared/page-models/audit-universe-detail-standing-data-model";

@Injectable()
export class AuditUniverseDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audituniverse");
    }

    //#region CRUD methods

    getObject(guid: string, date?: Date): Observable<AuditUniverseModel> {
        let url = `/${guid}`;

        if (date) {
            url += "?date=" + date.toISOString().split("T")[0];
        }

        const request = this.get<AuditUniverseModel>(url);
        return request;
    }

    getList(): Observable<AuditUniverseInfoModel[]> {
        return this.get<AuditUniverseInfoModel[]>();
    }

    storeObject(model: AuditUniverseModel) {
        const formData = DocumentMethods.convertToFormDataWithFiles("universeObject", model);
        return this.request<string>(formData, null, {
            responseType: "text",
        });
    }

    deleteObject(guid: string): Observable<string[]> {
        const url = `/${guid}`;

        const request = this.delete(url);
        return request;
    }

    //#endregion CRUD methods

    //#region Support data

    getPermissions(guid?: string): Observable<AuditUniverseDetailPermissionModel> {
        let url = "/permissions";
        if (guid) {
            url = `/${guid}` + url;
        }

        return this.get<AuditUniverseDetailPermissionModel>(url);
    }

    getStandingData(
        universeGuid: string,
        date?: Date
    ): Observable<AuditUniverseDetailStandingDataModel> {
        let url = "/standingdata";
        if (isGuid(universeGuid)) {
            url = `/${universeGuid}` + url;
        }

        if (date) {
            url = addParameterToUrl(url, "date", date.toISOString().split("T")[0]);
        }

        const request = this.get<AuditUniverseDetailStandingDataModel>(url);
        return request;
    }

    public getLinkableAudits(auditUniverseGuid: string): Observable<AuditUniverseLinkAuditModel[]> {
        return this.get<AuditUniverseLinkAuditModel[]>(`/${auditUniverseGuid}/audits/linkable`);
    }

    public getDocuments(auditUniverseGuid: string): Observable<DocumentModel[]> {
        const url = `/${auditUniverseGuid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    //#endregion Support data

    //#region History Data

    public getHistory(guid: string | number): Observable<HistoryModel[]> {
        const url = `/${guid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(
        guid: string | number,
        historyId: string | number
    ): Observable<HistoryChange[]> {
        const url = `/${guid}/history/${historyId}`;

        return this.get<HistoryChange[]>(url);
    }

    //#endregion History Data
}
