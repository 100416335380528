import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { guid } from "@methods/uniqueMethods";

@Component({
    selector: "cerrix-color-select",
    templateUrl: "./cerrix-color-select.component.html",
    styleUrls: ["./cerrix-color-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CerrixColorSelectComponent,
            multi: true,
        },
    ],
})
export class CerrixColorSelectComponent implements ControlValueAccessor, OnInit {
    _value: string;

    _onChangeFormValidation = Function.prototype;
    _onTouchedFormValidation = Function.prototype;

    @Input() disabled = false;
    @Input() required = false;
    @Input() pickeronly = false;
    @Input() dataUiid: string = "";

    @Output() valueChange = new EventEmitter<string>();

    @Input()
    set value(value: string) {
        if (this._value === value) {
            return;
        }

        this._value = value;
        this.valueChange.emit(value);
        this._onChangeFormValidation(value);
    }
    get value(): string {
        return this._value;
    }

    constructor() {}

    ngOnInit(): void {
        // Set default color
        if (!this._value) {
            this._value = "#000000";
            this.valueChange.emit(this._value);
        }
    }

    // Form Validation
    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this._onChangeFormValidation = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouchedFormValidation = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
