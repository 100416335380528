<div class="form-group">
    <label for="widgetSelect-{{ field.ID }}"> Widget Type </label>
    <ng-select
        id="widgetSelect-{{ field.ID }}"
        name="widgetSelect-{{ field.ID }}"
        [multiple]="false"
        [items]="widgetTypeModels"
        [(ngModel)]="widgetSettings.widgetType"
        (change)="changeType($event)"
        bindLabel="text"
        bindValue="type"
        [clearable]="false"
    >
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <i class="fas {{ item.icon }} me-1" style="width: 20px; text-align: center"></i
            >{{ item.text }}
        </ng-template>
    </ng-select>
</div>

<div *ngIf="field.SourceData">
    <div class="form-group" *ngIf="widgetSettings.widgetType == widgetTypes.RiskMatrix">
        <label for="widgetSelect-{{ field.ID }}">
            Show Risk Matrix of Organization
            <i
                class="fas fa-info-circle ms-2"
                matTooltip="This will show the risk matrix like it has been configured in the selected organization."
            ></i>
        </label>
        <cerrix-select-tree
            [tree]="field.SourceData"
            [(value)]="widgetSettings.widgetParam"
            (onChangeSingleId)="saveModel()"
            [multiselect]="false"
        >
        </cerrix-select-tree>
    </div>
</div>
