import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "frm-forms-results-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class FrmFormsResultsApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Forms Results",
            httpMethod: "GET",
            path: "/formsdata/{id}/results",

            parameters: [
                {
                    name: "id",
                    description: "ID of the Form the Results should be retrieved from.",
                    in: "path",
                    paramType: "integer",
                    required: true,
                    example: "12",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                },
            ],
        };
    }
}
