<div class="crossfade">
    <div class="img-container" [class.hidden]="showCustomerLogo">
        <div
            class="img"
            [style.background-image]="'url(assets/img/logo.svg)'"
            [style.width]="width + 'px'"
            [style.height]="height + 'px'"
        ></div>
    </div>
    <div class="img-container" [class.hidden]="!showCustomerLogo">
        <div
            class="img"
            [style.background-image]="'url(' + customerLogo + ')'"
            [style.width]="width + 'px'"
            [style.height]="height + 'px'"
        ></div>
    </div>
</div>
