<div class="dialog-title" mat-dialog-title>
    <cerrix-title
        style="flex: 1"
        size="large"
        [editable]="false"
        [value]="'Link ' + label + 's'"
    ></cerrix-title>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">
    <mat-tab-group
        class="dialog-content-tab"
        [mat-stretch-tabs]="false"
        preserveContent
        animationDuration="0ms"
        (selectedTabChange)="onTabChange($event)"
    >
        <mat-tab [label]="'All ' + label + 's'" class="tab-content"></mat-tab>
        <mat-tab label="Linked" class="tab-content"></mat-tab>
    </mat-tab-group>

    <cerrix-input placeholder="Search..." (change)="onSearch($event)"></cerrix-input>

    <div class="item-list">
        <div
            class="item"
            *ngFor="let link of displayedLinks"
            [class.selected]="this.linkMap.has(link.id)"
            (click)="onClick(link.id)"
        >
            <div class="item-title">
                <span class="item-identifier">
                    {{ (link.identifier | number : "8.0-0").replaceAll(",", "") }}
                </span>
                |
                {{ link.name }}
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
    <cerrix-button color="accent" mat-dialog-close>Cancel</cerrix-button>

    <cerrix-button color="success" (click)="onApplyChanges()">Link {{ label }}(s)</cerrix-button>
</mat-dialog-actions>
