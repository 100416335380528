<div class="fancy-loader" *ngIf="!documents || !documentPermissions"></div>
<document-manager-old
    *ngIf="documents && documentPermissions"
    [Documents]="documents"
    [permissions]="documentPermissions"
    (DocumentsChange)="onDocumentsChange($event)"
    [AllowAdd]="editAllowed"
    [AllowEdit]="editAllowed"
    [AllowDelete]="editAllowed"
    [DocumentTypeTargets]="documentTypeTargets"
></document-manager-old>
