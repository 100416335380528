import { Directive, OnInit } from "@angular/core";
import { ApiResponseModel } from "@app/manual/models/api-models/api-response-model";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";

@Directive()
export abstract class ApiSpecComponent implements OnInit {
    specification: ApiSpecificationModel;

    baseResponsesLoaded = false;
    baseResponses: ApiResponseModel[] = [
        {
            code: "400",
            description: "Bad Request | Validation error",
            example: "Bad Request Reason | Validations",
        },
        {
            code: "401",
            description: "Unauthorized",
            example: "Invalid credentials!",
        },
        {
            code: "403",
            description: "Forbidden",
            example: "Forbidden to execute action",
        },
        {
            code: "404",
            description: "Not Found",
            example: "Requested item not found",
        },
        {
            code: "415",
            description: "Unsupported Media Type",
            example: "Something went wrong while processing data",
        },
        {
            code: "500",
            description: "Internal Server Error",
            example: "Unexpected error. Contact API owner.",
        },
    ];

    ngOnInit() {
        this.finalizeConfig(this.initSpecs());
    }

    initSpecs(): Partial<ApiSpecificationModel> {
        throw new Error("'initSpecs' is not implemented!");
    }

    private finalizeConfig(init: Partial<ApiSpecificationModel>) {
        this.specification = new ApiSpecificationModel();
        Object.assign(this.specification, init);
    }

    loadBaseResponses() {
        if (!this.baseResponsesLoaded) {
            this.specification.responses.push(...this.baseResponses);
            this.specification.responses = this.specification.responses.sortBy("code", true);
        }

        this.baseResponsesLoaded = true;
    }
}
