import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { AuditSampleModel } from "./audit-sample.model";
import { Observable } from "rxjs";

@Injectable()
export class AuditSamplerDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls/testing/advanced/audit/samples");
    }

    calculateSample(sample: AuditSampleModel): Observable<string[]> {
        return this.post<string[]>(sample);
    }
}
