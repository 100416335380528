import { Component, Input } from "@angular/core";
import { ParentChildSelectorConfig } from "../models/ParentChildSelector/ParentChildSelectorConfig";
import { GenericListFieldType } from "../models/GenericList/GenericListField";

@Component({
    selector: "cerrix-pcs",
    templateUrl: "./cerrix-pcs.component.html",
    styleUrls: ["./cerrix-pcs.component.scss"],
})
export class CerrixParentChildSelectorComponent {
    @Input() config: ParentChildSelectorConfig;

    filterTypes = GenericListFieldType;

    constructor() {}

    filterChanged() {
        if (this.config && this.config.items && this.config.items.length > 0) {
            if (this.config.filterMethod) {
                this.config.filterMethod(this.config.items);
                return;
            }
        }
    }

    toggleAll(toggleOn: boolean) {
        this.config.items.forEach((x) => {
            x.selected = toggleOn;
            x[this.config.childProperty].forEach((child) => {
                if (!child.hide) {
                    child.selected = toggleOn;
                }
            });
            x[this.config.childToggleProperty] = toggleOn;
        });

        this.config.extraToggles.forEach((et) => {
            this.config.items.forEach((i) => {
                i[et.propertyName] = toggleOn;
            });
        });
    }

    toggleChildren(item) {
        item[this.config.childToggleProperty] = !item[this.config.childToggleProperty];

        item[this.config.childProperty].forEach((effectiveness) => {
            effectiveness.selected = item[this.config.childToggleProperty];
        });
    }

    trackByParentIdentifier(index: number, item: any) {
        return item[this.config.parentIdentifierProperty];
    }

    trackByChildIdentifier(index: number, item: any) {
        return item[this.config.childIdentifierProperty];
    }
}
