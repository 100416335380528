<div class="detail-page-card">
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="reloadSiteConfigurations()">
            <i class="fas fa-sync"></i>
            <span class="d-none d-lg-inline">Reload Site Configurations</span>
        </button>
    </div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="reloadUsers()">
            <i class="fas fa-users-class"></i>
            <span class="d-none d-lg-inline">Reload Users</span>
        </button>
    </div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="reloadDbSettings()">
            <i class="fas fa-database"></i>
            <span class="d-none d-lg-inline">Reload DB Settings</span>
        </button>
    </div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="triggerNotifications()">
            <i class="fas fa-mail-bulk"></i>
            <span class="d-none d-lg-inline">Trigger Notifications</span>
        </button>
    </div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="testScheduler()">
            <i class="fas fa-check"></i>
            <span class="d-none d-lg-inline">Test Scheduler</span>
        </button>
    </div>
</div>
