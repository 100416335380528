import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { GenericListConfig } from "../models/GenericList/GenericList";
import { CustomSortableOptions } from "@methods/PageMethods";
import customTableSort from "@methods/customTableSort";
import { GenericListFieldType } from "../models/GenericList/GenericListField";
import { SortableOptions } from "sortablejs";

@Component({
    selector: "cerrix-table",
    templateUrl: "./cerrix-table.component.html",
    styleUrls: ["./cerrix-table.component.scss"],
})
export class CerrixTableComponent implements OnInit {
    @Input() config: GenericListConfig;
    @Input() sortable = false;
    @Input() idProp: string;
    @Input() selectedProp: string;
    @Input() rendererConfig: RendererConfig[] = [];

    @Input() data: any[];
    @Input() showID?: boolean;
    @Input() activeRow: any;

    // Styling properties
    @Input() Centered: boolean;
    @Input() NoPadding: boolean;
    @Input() NoBorder: boolean;
    @Input() NoColumnBorder: boolean;

    @Output() activeRowChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowDblClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    headers: string[] = [];
    prettyHeaders = {};
    headerTooltips = {};
    sortingOptions: SortableOptions;

    columnSortEnabled = false;
    columnSortName = "";
    columnSortAsc = true;

    constructor() {
        this.sortingOptions = CustomSortableOptions({
            onSort: () => {
                // Without timeout the emit causes a race condition with the array that gets edited.
                setTimeout(() => {
                    this.sortingChanged.emit(true);
                }, 0);
            },
        });
    }

    ngOnInit() {
        if (this.config) {
            this.sortingOptions.disabled = !this.config.isSortable;
            this.setHeaders();

            if (
                this.sortingOptions.disabled &&
                this.config.isSortableByColumn &&
                this.headers.length > 0
            ) {
                this.columnSortEnabled = true;
                this.sortOnColumn(this.headers[0]);
            }
        } else {
            this.sortingOptions.disabled = this.sortable !== true;
        }
    }

    triggerChange(row: any) {
        this.activeRow = row;
        this.activeRowChange.emit(this.activeRow);
    }

    triggerClick(row: any) {
        this.triggerChange(row);
        this.rowClick.emit(row);
    }

    triggerDblClick(row: any) {
        this.triggerChange(row);
        this.rowDblClick.emit(row);
    }

    setData(data: any[]) {
        this.headers = [];
        this.prettyHeaders = {};
        this.headerTooltips = {};
        this.data = null;

        if (data == null || data.length === 0) {
            return;
        }

        this.setHeaders();

        this.data = data;
    }

    sortOnColumn(col: string) {
        if (this.columnSortEnabled) {
            if (this.columnSortName === col) {
                this.columnSortAsc = !this.columnSortAsc;
            } else {
                this.columnSortName = col;
                this.columnSortAsc = true;
            }

            const fieldConfig = this.config.fields.find((x) => x.fieldName === this.columnSortName);
            if (fieldConfig) {
                const isNumeric = fieldConfig.fieldType === GenericListFieldType.Number;
                const isDate =
                    fieldConfig.fieldType === GenericListFieldType.Date ||
                    fieldConfig.fieldType === GenericListFieldType.DateTime;
                const isString = !isNumeric && !isDate;

                const compareMethod = customTableSort(
                    this.columnSortAsc,
                    this.columnSortName,
                    isString,
                    isNumeric,
                    isDate
                );

                this.data = this.data.sort(compareMethod);
            } else {
                this.data = this.data.sortBy(this.columnSortName, this.columnSortAsc);
            }
        }
    }

    private setHeaders() {
        const fields = this.config.fields
            .filter((x) => !this.showID && x.fieldName !== this.idProp && !x.hideInOverview)
            .sortBy("OverviewSortOrder", true);

        fields.forEach((x) => {
            this.headers.push(x.fieldName);
            this.prettyHeaders[x.fieldName] = x.prettyName ? x.prettyName : x.fieldName;
            if (x.description && x.showDescriptionInTable) {
                this.headerTooltips[x.fieldName] = x.description;
            }
        });
    }
}
