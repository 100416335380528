import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, map } from "rxjs";

import { PresetModel } from "../models/preset.model";
import { PresetViewModel } from "../models/preset-view.model";
import { PresetBaseModel } from "../models/preset-base.model";
import { IncidentCustomFieldService } from "../../incident/services/incident-custom-field.service";
import { IncidentClassificationService } from "../../incident/services/incident-classification.service";
import { IncidentStatusService } from "../../incident/services/incident-status.service";
import { IncidentTypesService } from "../../incident/services/incident-types.service";
import { IncidentUsersService } from "../../incident/services/incident-users.service";
import { IncidentOrganizationService } from "../../incident/services/incident-organization.service";
import { IncidentBusinessDimensionService } from "../../incident/services/incident-business-dimension.service";
import { IncidentFrameworkDimensionService } from "../../incident/services/incident-framework-dimension.service";
import { IncidentRootCauseCategoryService } from "../../incident/services/incident-root-cause-category.service";
import { PresetConfigColumnModel } from "../models/preset-config-column.model";
import { SelectOptionModel } from "../models/select-option.model";
import { PresetRuleModel } from "../models/preset-rule.model";
import { PresetRuleDescriptionModel } from "../models/preset-rule-description.model";
import { TreeItemNode } from "@cerrix/components";
import { IncidentThirdPartyService } from "../../incident/services/incident-thirdparty.service";

@Injectable()
export class PresetService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/presets`;

    private customFieldService = inject(IncidentCustomFieldService);
    private classificationsService = inject(IncidentClassificationService);
    private incidentStatusService = inject(IncidentStatusService);
    private incidentTypesService = inject(IncidentTypesService);
    private usersService = inject(IncidentUsersService);
    private organizationService = inject(IncidentOrganizationService);
    private thirdpartyService = inject(IncidentThirdPartyService);

    private businessDimensionService = inject(IncidentBusinessDimensionService);
    private frameworkDimensionService = inject(IncidentFrameworkDimensionService);
    private rootCauseCategoryService = inject(IncidentRootCauseCategoryService);

    public getAllColumns(): Observable<PresetConfigColumnModel[]> {
        return this.httpClient.get<PresetConfigColumnModel[]>(`${this.baseUrl}/columns`);
    }

    public getPresets(): Observable<PresetViewModel[]> {
        let params = new HttpParams().set("count", "100").set("offset", "0");

        return this.httpClient.get<PresetViewModel[]>(this.baseUrl, { params: params });
    }

    public getPreset(presetId: string): Observable<PresetModel> {
        return this.httpClient.get<PresetModel>(`${this.baseUrl}/${presetId}`);
    }

    public getDefault(): Observable<PresetModel> {
        return this.httpClient.get<PresetModel>(`${this.baseUrl}/default`);
    }

    public getPredefined(): Observable<PresetBaseModel[]> {
        return this.httpClient.get<PresetBaseModel[]>(`${this.baseUrl}/predefined`);
    }

    public getFavorites(): Observable<PresetBaseModel[]> {
        return this.httpClient.get<PresetBaseModel[]>(`${this.baseUrl}/favorites`);
    }

    public addPreset(preset: PresetModel): Observable<string> {
        return this.httpClient.post<string>(this.baseUrl, preset);
    }

    public editPreset(preset: PresetModel): Observable<void> {
        return this.httpClient.put<void>(`${this.baseUrl}/${preset.id}`, preset);
    }

    public updateFavourite(presetId: string, value: boolean): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/${presetId}/favourite`, value);
    }

    public updateUserDefault(presetId: string, value: boolean): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/${presetId}/user-default`, value);
    }

    public updateSystemDefault(presetId: string, value: boolean): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/${presetId}/system-default`, value);
    }

    public updatePublic(presetId: string, value: boolean): Observable<void> {
        return this.httpClient.patch<void>(`${this.baseUrl}/${presetId}/public`, value);
    }

    public deletePreset(presetId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/${presetId}`);
    }

    public getPresetRulesDescription(
        presetRules: PresetRuleModel[]
    ): Observable<PresetRuleDescriptionModel[]> {
        return this.httpClient.get<PresetRuleDescriptionModel[]>(
            `${this.baseUrl}/rules-description?rules=${JSON.stringify(presetRules)}`
        );
    }

    public getColumnOptions(
        columnName: string,
        isCustomField: boolean
    ): Observable<SelectOptionModel[] | TreeItemNode[]> {
        if (isCustomField) {
            return this.customFieldService
                .getCustomFieldOptions(columnName)
                .pipe(map(this.mapTranslatableSelectOptions));
        } else {
            switch (columnName) {
                case "Classification":
                    return this.classificationsService
                        .getAll()
                        .pipe(map(this.mapTranslatableSelectOptions));
                case "IncidentStatus":
                    return this.incidentStatusService
                        .getAll()
                        .pipe(map(this.mapTranslatableSelectOptions));
                case "IncidentTypes":
                    return this.incidentTypesService
                        .getAll()
                        .pipe(map(this.mapTranslatableSelectOptions));
                case "ReportedBy":
                case "CompletedBy":
                case "IncidentResponsibles":
                case "IncidentAssessors":
                case "IncidentInformed":
                    return this.usersService.getAll();
                case "Organization":
                case "IncidentCausedByOrganizations":
                case "IncidentAffectedOrganizations":
                    return this.organizationService.getAll();
                case "IncidentCausedByThirdParties":
                case "IncidentAffectedThirdParties":
                    return this.thirdpartyService.getAll();
                case "IncidentBusinessDimensions":
                    return this.businessDimensionService
                        .getAll()
                        .pipe(map(this.mapTranslatableTreeOptions));
                case "IncidentFrameworkDimensions":
                    return this.frameworkDimensionService
                        .getAll()
                        .pipe(map(this.mapTranslatableTreeOptions));
                case "IncidentRootCauseCategories":
                    return this.rootCauseCategoryService
                        .getAll()
                        .pipe(map(this.mapTranslatableTreeOptions));
            }
        }

        return new Observable((obs) => {
            obs.next([]);
            obs.complete();
        });
    }

    public getTreeColumnNames(): string[] {
        return [
            "Organization",
            "IncidentCausedByOrganizations",
            "IncidentAffectedOrganizations",
            "IncidentBusinessDimensions",
            "IncidentFrameworkDimensions",
            "IncidentRootCauseCategories",
        ];
    }

    private mapTranslatableSelectOptions = (
        options: {
            id: number | string;
            translation: string;
        }[]
    ): SelectOptionModel[] =>
        options.map((o) => {
            return { id: o.id.toString(), name: o.translation };
        });

    private mapTranslatableTreeOptions = (
        options: (TreeItemNode & {
            translation: string;
        })[]
    ): TreeItemNode[] =>
        options.map((n) => {
            return {
                id: n.id,
                name: n.translation,
                parentId: n.parentId,
                icon: n.icon,
                order: n.order,
            };
        });
}
