import { Component, OnInit } from "@angular/core";
import { CalendarItem } from "@app/calendar/models/CalendarItem";
import { CalendarDataService } from "@app/calendar/services/calendar-data.service";
import { LocalizationService } from "@app/shared";
import { Pages } from "@constants/pages/Pages";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { DatepickerDateCustomClasses } from "ngx-bootstrap/datepicker/models";
import { CalendarDay } from "./../../models/CalendarDay";

@Component({
    selector: "calendar",
    templateUrl: "./calendar.component.html",
    styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
    tab: TabModel;
    bsConfig: Partial<BsDatepickerConfig>;

    selectedDate = new Date();
    dateCustomClasses: DatepickerDateCustomClasses[];

    selectedDay: CalendarDay;

    get calendar() {
        return this._calendarDS.calendarModel;
    }

    constructor(
        private _localizationService: LocalizationService,
        private _calendarDS: CalendarDataService,
        private _pages: Pages,
        private _tabservice: TabService
    ) {}

    ngOnInit() {
        const dateFormat = this._localizationService.localizationInfo.dateFormat;
        this.bsConfig = {
            dateInputFormat: dateFormat,
            containerClass: "theme-dark-blue",
            customTodayClass: "datepicker-today",
        };

        if (this.calendar) {
            this.setup();
        }

        this._calendarDS.onReload.subscribe(() => this.setup());
    }

    reloadData() {
        this._calendarDS.loadCalendar();
    }

    setup() {
        this.selectedDate = new Date();
        this.dateCustomClasses = [];
        this.tab.showLoader = true;

        this.setupData();
    }

    setupData() {
        this.calendar.days.forEach((x) => {
            this.dateCustomClasses.push({ date: x.date, classes: ["bg-success"] });
        });

        this.setSelected();

        this.tab.showLoader = false;
    }

    setSelected() {
        const selectedYear = this.selectedDate.getFullYear(),
            selectedMonth = this.selectedDate.getMonth(),
            selectedDay = this.selectedDate.getDate();

        this.selectedDay = this.calendar.days.find(
            (x) =>
                x.date.getFullYear() === selectedYear &&
                x.date.getMonth() === selectedMonth &&
                x.date.getDate() === selectedDay
        );
    }

    open(item: CalendarItem) {
        const lookup = this._pages.getExactName(item.tabName);
        if (lookup) {
            this._tabservice.generateTab(lookup, item.tabID, item.description, item.tabConfig);
        }
    }
}
