<div class="form-base-details" *ngIf="field">
    <div class="form-group">
        <label for="fieldName-{{ field.ID }}">Field Name</label>
        <cerrix-textfield
            [dataUiid]="'form-detail-field-fieldname' + field.ID"
            cssClass="form-control"
            id="fieldName-{{ field.ID }}"
            [(ngModel)]="field.Name"
        ></cerrix-textfield>
    </div>
    <div class="form-group">
        <label for="workspacelabel-{{ field.ID }}">Workspace Label</label>
        <cerrix-textfield
            [dataUiid]="'form-detail-field-workspacelabel' + field.ID"
            cssClass="form-control"
            id="workspacelabel-{{ field.ID }}"
            [(ngModel)]="field.WorkspaceLabel"
            [placeholder]="field.Name"
        ></cerrix-textfield>
        <small>Custom label for workspace only.</small>
    </div>
    <div class="row">
        <div class="form-group col-6" *ngIf="mapbackProperties">
            <label for="mapbackSelect-{{ field.ID }}">
                Mapback Field
                <i
                    class="fas fa-info-circle ms-2"
                    matTooltip="Field to mapback to. This field can also have impact on field type and other configurations. This option will not be editable once saved!"
                ></i>
            </label>
            <ng-select
                id="mapbackSelect-{{ field.ID }}"
                name="mapbackSelect-{{ field.ID }}"
                [attr.data-uiid]="'form-detail-field-mapbackfield' + field.ID"
                [multiple]="false"
                [(ngModel)]="field.MapBackField"
                [disabled]="field.ID > 0"
                (change)="changeMapback($event)"
                (clear)="clearMapback()"
                [items]="mapbackProperties"
                bindLabel="Name"
                bindValue="ID"
                groupBy="Category"
            >
                <ng-template
                    ng-optgroup-tmp
                    let-item="item"
                >
                <span [attr.data-uiid]="'form-detail-field-mapbackfield-item-' + item.Category">
                    {{ item.Category || "Unnamed group" }}
                </span>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                    
                >
                <span [attr.data-uiid]="'form-detail-field-mapbackfield-item' + item.Name">
                    {{ item.Name }} {{ item.Required ? " *" : "" }}
                </span>
                </ng-template>
            </ng-select>
        </div>
        <div class="form-group col">
            <label for="mapbackSelect-{{ field.ID }}">
                Field Type
                <i
                    class="fas fa-info-circle ms-2"
                    [matTooltip]="
                        fieldTypeLocked
                            ? 'This option is controlled by Mapback Field.'
                            : 'This option will not be editable once saved!'
                    "
                ></i>
            </label>
            <ng-select
                id="mapbackSelect-{{ field.ID }}"
                name="mapbackSelect-{{ field.ID }}"
                [attr.data-uiid]="'form-detail-field-fieldtype' + field.ID"
                [multiple]="false"
                [items]="fieldTypes"
                [(ngModel)]="field.FieldType"
                (change)="setComponent($event.type, true)"
                bindLabel="text"
                bindValue="type"
                [disabled]="fieldTypeLocked || field.ID > 0"
            >
                <ng-template ng-label-tmp let-item="item">
                    <i
                        [attr.data-uiid]="'form-detail-field-fieldtype-item' + item.text"
                        class="fas {{ item.icon }} me-1"
                        style="width: 20px; text-align: center"
                    ></i
                    >{{ item.text }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <i
                        [attr.data-uiid]="'form-detail-field-fieldtype-item' + item.text"
                        class="fas {{ item.icon }} me-1"
                        style="width: 20px; text-align: center"
                    ></i
                    >{{ item.text }}
                    <small class="text-muted" *ngIf="item.description">
                        - {{ item.description }}</small
                    >
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-checkbox
                color="primary"
                id="showName{{ field.ID }}"
                [(ngModel)]="field.ShowName"
                [attr.data-uiid]="'form-detail-field-show-to-user' + field.ID"
                >Show field name to user
            </mat-checkbox>
        </div>
        <div class="col">
            <mat-checkbox
                color="primary"
                id="fieldRequired{{ field.ID }}"
                [attr.data-uiid]="'form-detail-field-required-checkbox' + field.ID"
                [(ngModel)]="field.Required"
                [disabled]="requiredLocked"
                *ngIf="
                    field.FieldType !== typeEnum.Checkbox &&
                    field.FieldType !== typeEnum.Dropdown &&
                    field.FieldType !== typeEnum.Info &&
                    field.FieldType !== typeEnum.Video
                "
            >
                Field required
                <i
                    class="fas fa-info-circle ms-2"
                    *ngIf="requiredLocked"
                    matTooltip="This option is controlled by Mapback Field."
                ></i>
            </mat-checkbox>
        </div>
    </div>
</div>

<hr *ngIf="compRef" />
<ng-template #customComponent></ng-template>
