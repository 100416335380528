<div class="manual-body">
    <div class="table-contents" *ngIf="tableOfContents">
        <div class="detail-page-card">
            <div class="detail-page-card-title">{{tableOfContentsHeader}}</div>
            <div class="detail-page-card-body overflow-auto">
                <div class="nodes">
                    <ng-container *ngFor="let page of tableOfContents">
                        <ng-container
                            *ngTemplateOutlet="
                                nodeTemplate;
                                context: { node: page, parent: null, depth: 0 }"
                        ></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="page-content cerrix-manual-formatting">
        <ng-template #manualPageContent></ng-template>
    </div>
</div>

<ng-template #nodeTemplate let-node="node" let-parent="parent" let-depth="depth">
    <div
        [ngStyle]="{ 'z-index': depth }"
        class="node"
        [class.selected]="node.pageid === activePageID"
        [class.filterhighlight]="node.highlight"
        [class.filterhide]="node.show === false"
    >
        <div class="node-detail" [title]="node.name">
            <div class="node-child-toggler">
                <i
                    class="fas fa-angle-{{ node.expanded ? 'down' : 'right' }}"
                    (click)="node.expanded = !node.expanded"
                    *ngIf="node.children && node.children.length > 0"
                ></i>
            </div>
            <div class="node-name" [class.selectable]="node.component" (click)="itemClick(node)">
                {{ node.name }}
            </div>
        </div>
        <div class="children" *ngIf="node.expanded && node.children && node.children.length > 0">
            <ng-container *ngFor="let child of node.children">
                <ng-container
                    *ngTemplateOutlet="
                        nodeTemplate;
                        context: { node: child, parent: node, depth: depth + 1 }
                    "
                >
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
