import { Injectable } from "@angular/core";
import {
    EffectivenessPageType,
    EffectivenessStatusType,
    SampleGenerationType,
} from "../../../shared/enums";
import { DateTimeFormats, LocalizationService } from "@app/shared";
import {
    CerrixWorkflowConfig,
    CerrixWorkflowStep,
} from "@app/shared/models/cerrix-workflow-config";
import { EffectivenessModel, WorkflowHistoryModel } from "../../../shared/models";
import { AdvEffWorkflowDataService } from "../../../shared/services";
import { EffectivenessUsers } from "../../../shared/models/effectiveness-users";
import { StepStateEnum } from "../eff-workflow-bar/models/workflow-step-config";

export enum EffStepStatus {
    ReadyToStart,
    InProgress,
    Completed,
    NotStarted,
}
export class EffWorkFlowState {
    stepId: EffectivenessPageType;
    name: string;
    status: EffStepStatus;
    completedBy?: string;
    completedOn?: string;
    dueDate?: string;
    assignedUsers: string[];
    isRejected: boolean;
    rejectionMessage: string;
    rejectedBy: string;
    rejectedByRole: string;
}

@Injectable({
    providedIn: "root",
})
export class EffWorkFlowService {
    effectivenessModel: EffectivenessModel;
    workFlowStepStatus: EffWorkFlowState[] = [];

    workflowConfig: CerrixWorkflowConfig;
    users: EffectivenessUsers;

    private resolvePromise: any = null;
    private pageTypePerStatus: { [status in EffectivenessStatusType]: EffectivenessPageType } = {
        // waitingForSourceDocuments
        "-2": EffectivenessPageType.UploadSourceDocuments,

        // waitingForSamples
        "-1": EffectivenessPageType.GenerateSamples,

        // requestEvidence
        1: EffectivenessPageType.UploadEvidence,

        // nonOccurrence
        "-3": EffectivenessPageType.Test,

        // readyForAssessment
        2: EffectivenessPageType.Test,

        // assessmentInProgress
        3: EffectivenessPageType.Test,

        // waitingForConclusion
        4: EffectivenessPageType.Test,

        // reviewNonOccurrence
        "-4": EffectivenessPageType.Review,

        // waitingForReviewer
        5: EffectivenessPageType.Review,

        // completed
        6: EffectivenessPageType.Completed,

        // nonOccurrenceCompleted
        7: EffectivenessPageType.Completed,

        // undefined
        "0": EffectivenessPageType.NotStarted,
    };

    constructor(
        private _workflowDs: AdvEffWorkflowDataService,
        private _locService: LocalizationService
    ) {}

    public getWorkFlowSteps(effectivenessModel: EffectivenessModel): Promise<EffWorkFlowState[]> {
        this.effectivenessModel = effectivenessModel;
        let promise = new Promise<EffWorkFlowState[]>((resolve, reject) => {
            this.resolvePromise = resolve;
        });

        this.workFlowStepStatus = [];

        this._workflowDs
            .getUserFromEffectiveness(this.effectivenessModel.guid)
            .subscribe((users) => {
                this.users = users;
                this._workflowDs
                    .getWorkflowHistory(this.effectivenessModel.guid)
                    .subscribe((result) => {
                        this.setWorkflowConfig(result);
                        this.resolvePromise(this.workFlowStepStatus);
                    });
            });

        return promise;
    }

    private setEffSteps(steps: CerrixWorkflowStep[], workflowHistory: WorkflowHistoryModel[]) {
        this.workFlowStepStatus = [];
        let stepsLatestStatus = this.getPagesLatestStatus(workflowHistory);

        steps.forEach((step, index) => {
            let currentStepHistory = this.getStepHistory(step.stepId, workflowHistory);
            let rejectMessageObj = { comment: null, author: null, authorRole: null };
            let nextIndex = index + 1;
            if (nextIndex < steps.length) {
                let nextStep = steps[nextIndex];
                let nextStepHistory = this.getStepHistory(nextStep.stepId, workflowHistory);
                rejectMessageObj = this.getStepRejectionMessage(nextStepHistory);
            }

            const stepStatus = stepsLatestStatus[step.stepId] as WorkflowHistoryModel;

            this.workFlowStepStatus.push({
                stepId: step.stepId,
                name: step.stepName,
                completedBy: step.stepDone ? stepStatus?.actionBy : "",
                completedOn: step.stepDone
                    ? this._locService.formatDateByEnum(
                          stepStatus.actionOn,
                          DateTimeFormats.DateShortTime
                      )
                    : "",
                dueDate: this.getStepDueDate(step.stepId),
                assignedUsers: this.getStepAssignedUsers(step.stepId),
                rejectionMessage: rejectMessageObj.comment,
                rejectedBy: rejectMessageObj.author,
                rejectedByRole: rejectMessageObj.authorRole,
                isRejected:
                    rejectMessageObj.comment != null &&
                    rejectMessageObj.comment != undefined &&
                    rejectMessageObj.comment != "",
                status: this.getEffStepStatus(step, currentStepHistory),
            });
        });
    }

    getStepHistory(stepId: EffectivenessPageType, workflowHistory: WorkflowHistoryModel[]) {
        let stepStatuses: EffectivenessStatusType[] = [];

        for (const property in this.pageTypePerStatus) {
            if (this.pageTypePerStatus[property] == stepId) {
                stepStatuses.push(parseInt(property));
            }
        }

        var stepHistory = workflowHistory.filter((history) =>
            stepStatuses.some((s) => s == history.status)
        );

        if (!stepHistory) {
            return [];
        }
        return stepHistory;
    }

    getNextStep(currentStep: EffectivenessPageType) {
        let nextPage: EffectivenessPageType;
        if (currentStep == EffectivenessPageType.UploadEvidence) {
            nextPage = EffectivenessPageType.Test;
        } else if (currentStep == EffectivenessPageType.Test) {
            nextPage = EffectivenessPageType.Review;
        }

        return nextPage;
    }

    getStepRejectionMessage(stepHistory: WorkflowHistoryModel[]): {
        comment: string;
        author: string;
        authorRole: string;
    } {
        var rejectedAction = stepHistory.find(
            (h) => h.actionDescription == "Rejected" || h.actionDescription == "Reopen"
        );
        if (rejectedAction) {
            return {
                comment: rejectedAction.comment,
                author: rejectedAction.actionBy,
                authorRole: this.getAuthorRole(rejectedAction),
            };
        }
        return {
            comment: null,
            author: null,
            authorRole: null,
        };
    }

    getAuthorRole(action: WorkflowHistoryModel) {
        if (
            action.status == EffectivenessStatusType.completed ||
            action.status == EffectivenessStatusType.nonOccurrenceCompleted
        ) {
            return "Reopened";
        }
        if (
            action.status == EffectivenessStatusType.readyForAssessment ||
            action.status == EffectivenessStatusType.assessmentInProgress ||
            action.status == EffectivenessStatusType.waitingForConclusion
        ) {
            return "Tester";
        }

        return "Reviewer";
    }

    private getEffStepStatus(
        step: CerrixWorkflowStep,
        stepHistory: WorkflowHistoryModel[]
    ): EffStepStatus {
        if (step.stepDone) {
            return EffStepStatus.Completed;
        }

        if (step.stepActive) {
            if (!stepHistory || stepHistory.length == 0) {
                return EffStepStatus.ReadyToStart;
            }

            let lastAction = stepHistory[0];
            let lastStatus = lastAction.status;
            if (
                lastStatus == EffectivenessStatusType.assessmentInProgress ||
                (lastStatus == EffectivenessStatusType.requestEvidence &&
                    !lastAction.actionDescription)
            ) {
                return EffStepStatus.InProgress;
            }
            return EffStepStatus.ReadyToStart;
        }

        return EffStepStatus.NotStarted;
    }

    private getStepState(step: CerrixWorkflowStep): StepStateEnum {
        if (step.stepActive) {
            return StepStateEnum.Active;
        }
        if (step.stepDone) {
            return StepStateEnum.Completed;
        }

        return StepStateEnum.Disabled;
    }

    private setWorkflowConfig(workflowHistory: WorkflowHistoryModel[]): void {
        var steps = this.getSteps(workflowHistory);
        this.workflowConfig = {
            dataMethod: steps,
            highlightBackground: true,
        };

        this.setEffSteps(steps, workflowHistory);
    }

    private getPagesLatestStatus(workflowHistory: WorkflowHistoryModel[]) {
        const currentStep = workflowHistory[0].status;
        const currentPageType = this.pageTypePerStatus[currentStep];

        const filteredWorkflowHistory = workflowHistory.filter(
            (history) => this.pageTypePerStatus[history.status] <= currentPageType
        );

        // We will now create a new object that contains the latest status of each page type
        const latestStatusPerPageType = {};
        filteredWorkflowHistory.forEach((history) => {
            const pageType = this.pageTypePerStatus[history.status];
            if (!latestStatusPerPageType[pageType]) {
                latestStatusPerPageType[pageType] = history;
            }
        });
        return latestStatusPerPageType;
    }

    private getSteps(workflowHistory: WorkflowHistoryModel[]): CerrixWorkflowStep[] {
        const currentStep = workflowHistory[0].status;
        const currentPageType = this.pageTypePerStatus[currentStep];

        const filteredWorkflowHistory = workflowHistory.filter(
            (history) => this.pageTypePerStatus[history.status] <= currentPageType
        );

        // We will now create a new object that contains the latest status of each page type
        const latestStatusPerPageType = {};
        filteredWorkflowHistory.forEach((history) => {
            const pageType = this.pageTypePerStatus[history.status];
            if (!latestStatusPerPageType[pageType]) {
                latestStatusPerPageType[pageType] = history;
            }
        });

        const getStep = (stepName: string, currentPageType: EffectivenessPageType) => {
            const statusOfCurrentPage = latestStatusPerPageType[
                currentPageType
            ] as WorkflowHistoryModel;

            const isCompletedStep =
                statusOfCurrentPage &&
                (!!statusOfCurrentPage.actionDescription ||
                    this.pageTypePerStatus[statusOfCurrentPage.status] ==
                        EffectivenessPageType.Completed);
            const isCurrentStep = statusOfCurrentPage && !statusOfCurrentPage.actionDescription;

            return <CerrixWorkflowStep>{
                stepId: currentPageType,
                stepName: stepName,
                stepActive: isCurrentStep,
                stepDone: isCompletedStep,
                stepDoneText: isCompletedStep ? statusOfCurrentPage.actionDescription : "",
                stepDescription: statusOfCurrentPage ? statusOfCurrentPage.statusDescription : null,
                isStepToDoverdue: this.isStepOToDoverdue(currentPageType, isCompletedStep),
                disableClick: true,
            };
        };

        const steps: CerrixWorkflowStep[] = [];

        const isNonOccurrence =
            latestStatusPerPageType[EffectivenessPageType.Test]?.status ==
            EffectivenessStatusType.nonOccurrence;

        // If test plan is set to one sample, then we skip the upload source documents and generate samples steps
        if (this.effectivenessModel.sampleGenerationType != SampleGenerationType.oneSample) {
            steps.push(
                getStep("Upload source documents", EffectivenessPageType.UploadSourceDocuments)
            );

            // if (!isNonOccurrence) {
            //     steps.push(getStep("Generate samples", EffectivenessPageType.GenerateSamples));
            // }
        }

        if (!isNonOccurrence) {
            steps.push(getStep("Upload evidence", EffectivenessPageType.UploadEvidence));
        }

        steps.push(getStep("Control testing", EffectivenessPageType.Test));

        if (this.effectivenessModel.reviewEnabled) {
            steps.push(getStep("Review", EffectivenessPageType.Review));
        }

        steps.push(getStep("Completed", EffectivenessPageType.Completed));

        return steps;
    }

    private isStepOToDoverdue(stepId: EffectivenessPageType, isCompletedStep: boolean): boolean {
        let isOverdue = false;
        const date = new Date();
        if (!isCompletedStep) {
            switch (stepId) {
                case EffectivenessPageType.UploadSourceDocuments:
                    if (date > this.effectivenessModel.sourceUploaderDueDate) {
                        isOverdue = true;
                    }
                    break;
                case EffectivenessPageType.GenerateSamples:
                case EffectivenessPageType.UploadEvidence:
                    if (date > this.effectivenessModel.evidenceUploaderDueDate) {
                        isOverdue = true;
                    }
                    break;
                case EffectivenessPageType.Test:
                    if (date > this.effectivenessModel.testerDueDate) {
                        isOverdue = true;
                    }
                    break;
                case EffectivenessPageType.Review:
                    if (date > this.effectivenessModel.reviewerDueDate) {
                        isOverdue = true;
                    }
                    break;
            }
        }
        return isOverdue;
    }

    private getStepAssignedUsers(stepId: number): string[] {
        switch (stepId) {
            case EffectivenessPageType.UploadSourceDocuments:
            case EffectivenessPageType.GenerateSamples:
                return this.users.sourceDocUploaders;
            case EffectivenessPageType.UploadEvidence:
                return this.users.evidenceUploaders;
            case EffectivenessPageType.Test:
                return this.users.testers;
            case EffectivenessPageType.Review:
                return this.users.reviewers;
            case EffectivenessPageType.Completed:
                return [];
        }
        return [];
    }

    private getStepDueDate(stepId: number): string {
        switch (stepId) {
            case EffectivenessPageType.UploadSourceDocuments:
                return this._locService.formatDateByEnum(
                    this.effectivenessModel.sourceUploaderDueDate,
                    DateTimeFormats.Date
                );
            case EffectivenessPageType.GenerateSamples:
                return this._locService.formatDateByEnum(
                    this.effectivenessModel.evidenceUploaderDueDate,
                    DateTimeFormats.Date
                );
            case EffectivenessPageType.UploadEvidence:
                return this._locService.formatDateByEnum(
                    this.effectivenessModel.evidenceUploaderDueDate,
                    DateTimeFormats.Date
                );
            case EffectivenessPageType.Test:
                return this._locService.formatDateByEnum(
                    this.effectivenessModel.testerDueDate,
                    DateTimeFormats.Date
                );
            case EffectivenessPageType.Review:
                return this._locService.formatDateByEnum(
                    this.effectivenessModel.reviewerDueDate,
                    DateTimeFormats.Date
                );
            case EffectivenessPageType.Completed:
                return "";
        }
        return "";
    }
}
