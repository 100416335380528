import { Component } from "@angular/core";
import {
    EffectivenessSourceDocumentsModel,
    WorkflowGenerateSamplesModel,
} from "../../../shared/models";
import { AbstractEffTestingStatusComponent } from "../abstract-eff-testing-status-component";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { toPromise } from "@methods/CommonMethods";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { EffectivenessStatusType, SampleGenerationType, SampleType } from "../../../shared/enums";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { firstValueFrom } from "rxjs";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { PopupActionResult } from "../../common/popups/models/popup-action-result.model";
import { GenerateSamplesPopupComponent } from "../popups/generate-samples-popup/generate-samples-popup.component";
import { DocumentModel } from "@models/documents/documentmodel";

@Component({
    selector: "eff-testing-upload-source-document",
    templateUrl: "./eff-testing-upload-source-document.component.html",
    styleUrls: ["./eff-testing-upload-source-document.component.scss"],
})
export class EffTestingUploadSourceDocumentComponent extends AbstractEffTestingStatusComponent {
    sourceDocumentModel: EffectivenessSourceDocumentsModel;
    sourceDocumentPermissions = new DocumentManagerPermissionModel();

    prompt: CerrixPromptComponent;
    samplingInformation: WorkflowGenerateSamplesModel;

    onLoad(): void {
        this._ds.getSourceDocuments(this.effectivenessModel.guid).subscribe((data) => {
            this.sourceDocumentModel = data;
        });

        // Bypassing the regular permissions if the status equals "waitingForSourceDocuments" or "waitingForSamples"
        // This is specifically done to keep the "old way" intact
        if (this.stepPermissions.status < 0) {
            this.stepPermissions.sourceDocumentUploadAllowed = true;
            this.stepPermissions.sampleGenerationAllowed = true;
        }
        this.sourceDocumentPermissions.canAddDocument =
            this.stepPermissions.sourceDocumentUploadAllowed;
    }

    private async controlDidNotOccur() {
        await this.saveSourceDocuments(true);
        await this.executeWorkflow(
            this._workflowDs.postWorkflowControlDidNotOccur(this.effectivenessModel.guid)
        );
    }

    async controlDidNotOccurWithConfirmation() {
        var result = await this._dialogService.showConfirmationDialog(
            "Control didn't occur",
            "Are you sure the control did not occur this period?",
            "Control didn't occur",
            "Cancel",
            "820px",
            "warning-button"
        );

        if (result == PopupActionResult.OK) {
            await this.controlDidNotOccur();
            this.cerrixTab.refresh();
        }
    }

    areDocumentsUploaded() {
        return this.visibleDocuments(this.sourceDocumentModel.documents).length > 0;
    }

    isDocumentSelected() {
        return (
            this.visibleDocuments(this.sourceDocumentModel.documents).length == 1 ||
            this.visibleDocuments(this.sourceDocumentModel.documents).filter(
                (doc) => doc.IsSelected
            ).length > 0
        );
    }

    async completeSourceDocuments(skipPageReload: boolean = false) {
        let visibleDocuments = this.visibleDocuments(this.sourceDocumentModel.documents);
        var sourceDocuments: EffectivenessSourceDocumentsModel = {
            idColumn: this.samplingInformation.idColumn,
            rowsToSkip: this.samplingInformation.rowsToSkip,
            documents: visibleDocuments,
        };

        await this.executeWorkflow(
            this._workflowDs.postWorkflowUploadSourceDocuments(
                this.effectivenessModel.guid,
                sourceDocuments
            ),
            skipPageReload
        );
    }

    async saveSourceDocuments(skipPageReload: boolean = false) {
        if (
            this.sourceDocumentModel.documents.every(
                (sd) => !sd.IsNew && !sd.Changed && !sd.Deleted
            )
        ) {
            return;
        }
        await this.executeWorkflow(
            this._ds.postSourceDocuments(this.effectivenessModel.guid, this.sourceDocumentModel),
            skipPageReload
        );

        this._saveButtonService.triggerSaveSuccess();
    }

    documentDeleted(document: DocumentModel) {
        if (!document.IsNew) {
            this.saveSourceDocuments();
        }
    }

    async completeSourceDocumentsWithConfirm() {
        var result = await this._dialogService.showConfirmationDialog(
            "No source documents",
            "Are you sure you want to continue without uploading source documents?",
            "Start generating samples",
            "Cancel",
            "820px"
        );

        if (result == PopupActionResult.OK) {
            this.openGenerateSamplePopup();
        }
    }

    async openGenerateSamplePopup() {
        await this.saveSourceDocuments(true);

        var newSourceDocumentModel = await toPromise(
            this._ds.getSourceDocuments(this.effectivenessModel.guid)
        );

        newSourceDocumentModel.documents.forEach((newDocument) => {
            let savedDocument = this.sourceDocumentModel.documents.find(
                (doc) => doc.Name == newDocument.Name
            );
            if (savedDocument) {
                newDocument.IsSelected = savedDocument.IsSelected;
            }
        });

        this.sourceDocumentModel = newSourceDocumentModel;

        let availableSampleTypes = await this.getAvailableSampleTypes();
        this._dialog.open(GenerateSamplesPopupComponent, {
            data: {
                title: "Generate Samples",
                effectivenessModel: this.effectivenessModel,
                sourceDocumentModel: this.sourceDocumentModel,
                availableSampleTypes: availableSampleTypes,
                save: function (samplingInformation: WorkflowGenerateSamplesModel) {
                    this.samplingInformation = samplingInformation;
                    this.postSampleConfiguration();
                }.bind(this),
            },
            panelClass: "eff-popup-wrapper",
            width: "825px",
        });
    }

    private async executeWorkflow(workflowFunction, skipPageReload: boolean = false) {
        const prompt = this._promptService.loader("Saving source documents, please wait...");
        try {
            await toPromise(workflowFunction);
            if (!skipPageReload) {
                this.onLoad();
            }
        } finally {
            prompt.close();
        }
    }

    private async postSampleConfiguration() {
        if (this.stepPermissions.status != EffectivenessStatusType.waitingForSamples) {
            await this.completeSourceDocuments(true);
        }
        this._workflowDs
            .postWorkflowGenerateSamples(this.effectivenessModel.guid, this.samplingInformation)
            .subscribe(() => {
                this._dialog.closeAll();
                this.cerrixTab.refresh();
            });
    }

    private async getAvailableSampleTypes(): Promise<any[]> {
        const settingService = this._injector.get(SettingsDataService);
        const setting = (
            await firstValueFrom(settingService.getSetting(ApplicationSettings.GenerateSamplesType))
        ).TextValue;

        let sampleTypeOptions = [];
        const showSpreadsheetGeneration =
            this.effectivenessModel.sampleGenerationType == SampleGenerationType.automatic &&
            this.sourceDocumentModel &&
            this.sourceDocumentModel.documents &&
            this.visibleDocuments(this.sourceDocumentModel.documents).length > 0;

        const options = setting.split(";");
        options.forEach((option) => {
            if (option.startsWith("[") && option.endsWith("]")) {
                switch (option.toLowerCase()) {
                    case "[all]":
                        sampleTypeOptions = [
                            { ID: SampleType.number, Name: "Number" },
                            { ID: SampleType.date, Name: "Date" },
                        ];
                        if (
                            this.effectivenessModel.sampleGenerationType ==
                            SampleGenerationType.manual
                        ) {
                            sampleTypeOptions.push({
                                ID: SampleType.text,
                                Name: "Text",
                            });
                        }

                        if (showSpreadsheetGeneration) {
                            sampleTypeOptions.push({
                                ID: SampleType.spreadsheet,
                                Name: "Spreadsheet",
                            });
                        }

                        break;
                    case "[number]":
                        sampleTypeOptions = [{ ID: SampleType.number, Name: "Number" }];
                        break;
                    case "[date]":
                        sampleTypeOptions = [{ ID: SampleType.date, Name: "Date" }];
                        break;
                    case "[text]":
                        sampleTypeOptions = [{ ID: SampleType.text, Name: "Text" }];
                        break;
                    case "[spreadsheet]":
                        if (showSpreadsheetGeneration) {
                            sampleTypeOptions = [
                                { id: SampleType.spreadsheet, Name: "Spreadsheet" },
                            ];
                        } else {
                            sampleTypeOptions = [{ ID: SampleType.number, Name: "Number" }];
                        }
                        break;
                }
            }
        });

        return sampleTypeOptions;
    }
}
