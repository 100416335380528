export enum KriWidgetType {
    All = 1,
    Kri = 2,
    Kpi = 3,
}

export const KriWidgetTypeDescription: { [type in KriWidgetType]: string } = {
    1: "All",
    2: "KRI",
    3: "KPI",
};
