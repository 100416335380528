import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentBusinessDimensionModel } from "../models/incident-business-dimension.model";

@Injectable({
    providedIn: "root",
})
export class IncidentBusinessDimensionService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/business-dimensions`;

    public getAll(): Observable<IncidentBusinessDimensionModel[]> {
        return this.httpClient.get<IncidentBusinessDimensionModel[]>(this.baseUrl);
    }

    public getAllByIncident(incidentId: string): Observable<IncidentBusinessDimensionModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentBusinessDimensionModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentBusinessDimensionModel[]>(
                `${this.baseUrl}/incident/${incidentId}`
            );
        }
    }
}
