<div class="incident-links-tab">
    <div class="link-section">
        <div class="section-title">Linked Risks</div>
        <div class="linked-risks">
            <div class="linked-risks-none" *ngIf="risks.length == 0">Link an item to display</div>
            <div class="linked-risk" *ngFor="let risk of risks">
                <div class="linked-risk-title">
                    <span class="linked-risk-identifier">
                        {{ (risk.identifier | number : "8.0-0").replaceAll(",", "") }}
                    </span>
                    |
                    {{ risk.name }}
                </div>
                <div class="linked-risk-buttons">
                    <cerrix-icon-button color="accent-link" size="small" (click)="openRisk(risk)">
                        <mat-icon fontSet="material-symbols-outlined">open_in_new</mat-icon>
                    </cerrix-icon-button>
                    <cerrix-icon-button
                        color="accent-link"
                        size="small"
                        (click)="unlinkRisk(risk.id)"
                        *ngIf="canEditIncident"
                    >
                        <mat-icon fontSet="material-symbols-outlined">link_off</mat-icon>
                    </cerrix-icon-button>
                </div>
            </div>
        </div>
        <div class="linked-risk-footer" *ngIf="canEditIncident">
            <cerrix-button
                color="accent"
                type="flat"
                size="small"
                (click)="openEditLinksPopup('risks')"
            >
                <mat-icon>link</mat-icon>
                Link Risks
            </cerrix-button>
        </div>
    </div>

    <div class="link-section">
        <div class="section-title">Linked Controls</div>
        <div class="linked-risks">
            <div class="linked-risks-none" *ngIf="controls.length == 0">
                Link an item to display
            </div>
            <div class="linked-risk" *ngFor="let control of controls">
                <div class="linked-risk-title">
                    <span class="linked-risk-identifier">
                        {{ (control.identifier | number : "8.0-0").replaceAll(",", "") }}
                    </span>
                    |
                    {{ control.name }}
                </div>
                <div class="linked-risk-buttons">
                    <cerrix-icon-button
                        color="accent-link"
                        size="small"
                        (click)="openControl(control)"
                    >
                        <mat-icon fontSet="material-symbols-outlined">open_in_new</mat-icon>
                    </cerrix-icon-button>
                    <cerrix-icon-button
                        color="accent-link"
                        size="small"
                        (click)="unlinkControl(control.id)"
                        *ngIf="canEditIncident"
                    >
                        <mat-icon fontSet="material-symbols-outlined">link_off</mat-icon>
                    </cerrix-icon-button>
                </div>
            </div>
        </div>
        <div class="linked-risk-footer" *ngIf="canEditIncident">
            <cerrix-button
                color="accent"
                type="flat"
                size="small"
                (click)="openEditLinksPopup('controls')"
            >
                <mat-icon>link</mat-icon>
                Link Controls
            </cerrix-button>
        </div>
    </div>

    <div class="link-section">
        <div class="section-title">Linked Mois</div>
        <div class="linked-risks">
            <div class="linked-risks-none" *ngIf="mois.length == 0">
                Create or link an item to display
            </div>
            <div class="linked-risk" *ngFor="let moi of mois">
                <div class="linked-risk-title">
                    <span class="linked-risk-identifier">
                        {{ (moi.identifier | number : "8.0-0").replaceAll(",", "") }}
                    </span>
                    |
                    {{ moi.name }}
                </div>
                <div class="linked-risk-buttons">
                    <cerrix-icon-button color="accent-link" size="small" (click)="openMoi(moi)">
                        <mat-icon fontSet="material-symbols-outlined">open_in_new</mat-icon>
                    </cerrix-icon-button>
                    <cerrix-icon-button
                        *ngIf="canEditIncident"
                        color="accent-link"
                        size="small"
                        (click)="deleteMoi(moi)"
                    >
                        <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                    </cerrix-icon-button>
                </div>
            </div>
        </div>
        <div class="linked-risk-footer" *ngIf="canEditIncident">
            <cerrix-button color="accent" type="flat" size="small" (click)="createMoi()">
                <mat-icon>add</mat-icon>
                Create MoI
            </cerrix-button>
        </div>
    </div>
</div>
