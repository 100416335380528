import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { DashboardModel } from "../shared/models/DashboardModel";
import { IdNameDescriptionModel } from "@models/generic/IdNameDescriptionModel";

@Injectable()
export class DashboardService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "dashboard");
    }

    getAll() {
        return this.get<DashboardModel[]>();
    }

    getPersonal() {
        return this.get<DashboardModel[]>("/personal");
    }

    getDefault() {
        return this.get<DashboardModel>("/default");
    }

    store(model: DashboardModel) {
        return this.post<DashboardModel>(model);
    }

    resetDefault(guid: string) {
        return this.put(null, `/${guid}/resetdefault`);
    }

    default(guid: string) {
        return this.put(null, `/${guid}/default`);
    }

    favorite(guid: string) {
        return this.put(null, `/${guid}/favorite`);
    }

    unfavorite(guid: string) {
        return this.put(null, `/${guid}/unfavorite`);
    }

    deleteDashboard(guid: string) {
        return this.delete(`/${guid}`);
    }

    getRoleGroups() {
        return this.get<IdNameDescriptionModel[]>("/rolegroups");
    }
}
