import {
    Component,
    Output,
    Input,
    EventEmitter,
    OnInit,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { NgxMasonryComponent } from "ngx-masonry";

declare var $: any;

@Component({
    selector: "cerrix-image-picker",
    templateUrl: "./cerrix-image-picker.component.html",
    styleUrls: ["./cerrix-image-picker.component.scss"],
})
export class CerrixImagePickerComponent implements OnInit {
    @Input() fallbackImageUrl: string;
    @Input() image: string;
    @Input() maxHeight: string = "100%";
    @Input() maxWidth: string = "100%";
    @Output() imageChange = new EventEmitter<string>();

    allImages: any[];

    @ViewChild("imageModal", { static: true }) imageModal: ElementRef;
    @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

    constructor(private _documentDs: DocumentDataService) {}

    async ngOnInit() {
        this.allImages = await this._documentDs.GetImages();
    }

    applyImage(guid: string) {
        this.image = guid;
        this.imageChange.emit(this.image);
        this.hideModal();
    }

    showModal() {
        $(this.imageModal.nativeElement).modal("show");

        setTimeout(() => {
            if (this.masonry) {
                this.masonry.reloadItems();
                this.masonry.layout();
            }
        }, 250);
    }

    hideModal() {
        $(this.imageModal.nativeElement).modal("hide");
    }
}
