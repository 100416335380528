import { Component, Input, ViewChild } from "@angular/core";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { LinkedMoiOverviewComponent } from "@app/moi/moi-linked-overview/moi-linked-overview.component";
import { LinkableComponent } from "@app/shared/linkable/linkable.component";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { LinkModel } from "@models/generic/LinkModel";
import { RiskDetailComponent } from "../risk-detail.component";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { RiskModel } from "@app/risk/models/RiskModel";
import { RiskPermissionModel } from "@app/risk/models/RiskPermissionModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { FilterType } from "@enums/FilterType";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "risk-mois",
    templateUrl: "./risk-mois.component.html",
    styleUrls: ["./risk-mois.component.scss"],
})
export class RiskMoiComponent {
    @Input() risk: RiskModel;
    @Input() permissions: RiskPermissionModel;
    @Input() activeFilters: SearchKeyword[] = [];
    @Input() showAdvancedFilters = false;

    @ViewChild("linkedRiskMoisComponent")
    linkedRiskMoisComponent: LinkedMoiOverviewComponent;
    @ViewChild("linkedImMoi") linkedImMoi: LinkableComponent;

    isLoading = true;
    // Link IM MOIS
    displayValueProp = "name";
    linked: LinkModel[] = [];
    unlinked: LinkModel[] = [];
    disabled: false;
    hideActions: true;
    hideFilter: false;
    allowAddNew = false;
    moiType = MoiTypes.RISK_Moi;

    moiModuleType = ModuleType.BIM;

    hasAccessChecks = {};
    accessCheckInProgress = false;
    riskDetailComponent: RiskDetailComponent;

    filterConfig: FilterConfig[] = [
        {
            label: "Organizations",
            column: "organizationId",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: "businessDimensionIds",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: "frameworkDimensionIds",
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];

    constructor(
        private _riskDS: RiskDataService,
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        private _moiDS: MoiDataService,
        private _promptService: CerrixPromptService
    ) {}

    load(refresh: boolean, rdc: RiskDetailComponent) {
        this.linkedRiskMoisComponent.load(refresh);
        this.riskDetailComponent = rdc;

        if (!this.risk.organizationId) {
            this._toastr.warning("Select organization first!");
            this.isLoading = false;
            return;
        }

        this._riskDS.getLinkableMois(this.risk.guid).subscribe((data) => {
            this.loadData(data, this.risk.linkedBimMoiIds);
            this.isLoading = false;
        });
    }

    linkedItemsChanged(linked: LinkModel[]) {
        const newMoisList = linked.map((x) => x.id);
        this.risk.linkedBimMoiIds = newMoisList.sort((a, b) => a - b);

        const isChanged = this.risk.linkedBimMoiIds.isDifferent(
            this.riskDetailComponent.backupModel.linkedBimMoiIds
        );
        TabComponentHelper.toggleTabDirty(
            this.riskDetailComponent.tab,
            this.riskDetailComponent.moisMenuItemId,
            isChanged
        );
    }

    private loadData(mois: LinkModel[], linkedMois: number[]) {
        const linked = [],
            unlinked = [];

        if (mois) {
            mois.forEach((m) => {
                if (linkedMois && linkedMois.find((x) => x === m.id)) {
                    linked.push(m);
                } else {
                    unlinked.pushIfNotExists(m);
                }
            });
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }

    openItem(item: IHasGuid) {
        const hasAccess = this.hasAccessChecks[item.guid];
        if (hasAccess == null && !this.accessCheckInProgress) {
            this.accessCheckInProgress = true;
            const prompt = this._promptService.loader("Checking rights, please wait...");

            this.hasAccessToItem(item.guid)
                .pipe(finalize(() => prompt.close()))
                .subscribe((x) => {
                    this.hasAccessChecks[item.guid] = x;

                    this.openTab(item, x);
                    this.accessCheckInProgress = false;
                });
        } else {
            this.openTab(item, hasAccess);
        }
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._moiDS.hasAccessToMoi(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.MoiDetail,
                item.guid,
                `(M) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this Moi.");
        }
    }
}
