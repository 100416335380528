import { Component, OnInit, ViewChild } from "@angular/core";
import { TpaConnectionDataService } from "@app/external-connections/services/tpa-connection.data.service";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { nameof } from "@methods/jeffs-toolkit";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { TpaConnectionWizardComponent } from "../connection-wizard/connection-wizard.component";
import { TpaConnectionModel } from "../models/tpaConnectionModel";

@Component({
    selector: "app-tpa-overview",
    templateUrl: "./tpa-overview.component.html",
    styleUrls: ["./tpa-overview.component.scss"],
})
export class TpaConnectionOverviewComponent implements OnInit {
    @ViewChild("connectionWizard") connectionWizard: TpaConnectionWizardComponent;
    tabID: string;
    tab: TabModel;

    config: GenericListConfig;

    constructor(
        private _ds: TpaConnectionDataService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.loadConfig();
    }

    loadConfig(): void {
        this.config = <GenericListConfig>{
            name: "Third Party Assurance Connections",
            dataMethod: (): Observable<TpaConnectionModel[]> => {
                return this._ds.getTpaConnections();
            },
            allowAdd: true,
            allowDelete: true,
            idProp: nameof<TpaConnectionModel>((x: TpaConnectionModel): string => x.connectionGuid),
            isSortableByColumn: true,
            fields: [
                {
                    fieldName: nameof<TpaConnectionModel>((x: TpaConnectionModel) => x.id),
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                    hideInEdit: true,
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>(
                        (x: TpaConnectionModel): string => x.connectionGuid
                    ),
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                    hideInEdit: true,
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>(
                        (x: TpaConnectionModel): string => x.name
                    ),
                    prettyName: "Connection name",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>(
                        (x: TpaConnectionModel) => x.apikeyExpiryDate
                    ),
                    prettyName: "Expiry date API key",
                    fieldType: GenericListFieldType.Date,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>(
                        (x: TpaConnectionModel): string => x.description
                    ),
                    prettyName: "Description",
                    fieldType: GenericListFieldType.TextArea,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>(
                        (x: TpaConnectionModel): string => x.testType
                    ),
                    prettyName: "Test type",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>((x: TpaConnectionModel) => x.startDate),
                    prettyName: "Startdate",
                    fieldType: GenericListFieldType.Date,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<TpaConnectionModel>((x: TpaConnectionModel) => x.endDate),
                    prettyName: "Enddate",
                    fieldType: GenericListFieldType.Date,
                    isReadonly: true,
                },
            ],
            addOverride: async (): Promise<any> => {
                this.connectionWizard.connectionGuid = null;
                await this.connectionWizard.load(true);
            },
            editOverride: async (row: TpaConnectionModel): Promise<any> => {
                this.connectionWizard.connectionGuid = row.connectionGuid;
                await this.connectionWizard.load(true);
            },
            delete: async (row: TpaConnectionModel): Promise<boolean> => {
                const confirmed = await this._promptService
                    .confirm(
                        `Delete '${row.name}'?`,
                        "Are you sure you want to delete this third party assurance connection?"
                    )
                    .toPromise();

                if (!confirmed) return false;

                await this._ds.deleteTpaConnection(row.connectionGuid).toPromise();
                this._toastr.success(`'${row.name}' is deleted!`, "Success");
                return true;
            },
        };
    }

    reload(): void {
        this.config.reload();
    }
}
