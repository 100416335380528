import { Component, Input } from "@angular/core";
import { EffectivenessModel } from "../../shared/models";

@Component({
    selector: "eff-detail-breadcrumb-info",
    templateUrl: "./eff-detail-breadcrumb-info.component.html",
    styleUrls: ["./eff-detail-breadcrumb-info.component.scss"],
})
export class EffDetailBreadcrumbInfoComponent {
    @Input() effectivenessModel: EffectivenessModel;
}
