import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import {
    AssessmentStoreModel,
    AssessmentDetailsModel,
    AssessmentPermissionsModel,
} from "../shared/models";
import { SelectOptionModel, SelectOptionColorModel } from "@models/generic";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";

@Injectable({
    providedIn: "root",
})
export class AssessmentDataService extends ApiDataService implements HistoryDataService {
    constructor(httpClient: HttpClient, configuration: Configuration) {
        super(httpClient, configuration, "assessments");
    }

    /**
     * Returns the permissions for a assessment
     * We need to finding report guid to determin permissions (create)
     */
    public getPermissions(
        findingReportGuid: string,
        assessmentGuid: string
    ): Observable<AssessmentPermissionsModel> {
        let url = `/permissions/${findingReportGuid}/`;

        if (assessmentGuid) {
            url += assessmentGuid;
        } else {
            url += "null";
        }

        return this.get<AssessmentPermissionsModel>(url);
    }

    getAssessment(assessmentGuid: string): Observable<AssessmentDetailsModel> {
        const url = `/${assessmentGuid}`;
        return this.get<AssessmentDetailsModel>(url);
    }

    addAssessment(
        findingReportGuid: string,
        model: AssessmentStoreModel
    ): Observable<AssessmentDetailsModel> {
        return this.post<AssessmentDetailsModel>(model, `?findingReportGuid=${findingReportGuid}`);
    }

    updateAssessement(assessmentGuid: any, model: AssessmentStoreModel): Observable<never> {
        return this.put(model, `/${assessmentGuid}`);
    }

    deleteAssessment(assessmentGuid: string): Observable<never> {
        return this.delete(`/${assessmentGuid}`);
    }

    hasAccessToAssessment(findingReportGuid: string, assessmentGuid: string): Observable<boolean> {
        let url = `/hasaccess/${findingReportGuid}/${assessmentGuid}`;

        return this.get<boolean>(url);
    }

    //#region Standing data

    getAssessmentTypes(): Observable<SelectOptionModel<number>[]> {
        return this.get<SelectOptionModel<number>[]>("/assessmenttypes");
    }

    getOverallScores(
        selectedAssessmentTypeId: number
    ): Observable<SelectOptionColorModel<number>[]> {
        return selectedAssessmentTypeId
            ? this.get<SelectOptionColorModel<number>[]>(
                  `/overallscores?selectedAssessmentTypeId=${selectedAssessmentTypeId}`
              )
            : of([]);
    }

    getReviewerOrganizations(): Observable<CerrixTreeItem[]> {
        return this.get<CerrixTreeItem[]>("/reviewerorganizations");
    }

    getReviewers(reviewerId: number): Observable<SelectOptionModel<number>[]> {
        let url = "/reviewers";
        if (reviewerId) {
            url += `?reviewerId=${reviewerId}`;
        }

        return this.get<SelectOptionModel<number>[]>(url);
    }

    //#region Standing data

    //#region History

    public getHistory(assessmentGuid: string): Observable<HistoryModel[]> {
        return this.get<HistoryModel[]>(`/${assessmentGuid}/history`);
    }

    public getHistoryChanges(
        assessmentGuid: string | number,
        historyDate: string | number
    ): Observable<HistoryChange[]> {
        return this.get<HistoryChange[]>(`/${assessmentGuid}/history/${historyDate}`);
    }

    //#region History
}
