<div class="main-page-area" *ngIf="sourceDocumentModel">
    <div class="main-page-content">
        <eff-step-header
            [controlGuid]="effectivenessModel.controlGuid"
            title="Upload source documents"
            (onShowInstructions)="toggleInstructions()"
            (onSaveChanges)="saveSourceDocuments(false)"
        >
        </eff-step-header>

        <div
            class="source-documents-label h2-title"
            data-uiid="new-eff-upload-source-document-document-manager-header"
        >
            {{ areDocumentsUploaded() ? "Select source document for sampling" : "Upload document" }}
        </div>
        <div data-uiid="new-eff-upload-source-document-document-manager">
            <document-manager-new
                [documents]="sourceDocumentModel.documents"
                (documentDeleted)="documentDeleted($event)"
                [permissions]="sourceDocumentPermissions"
                [canAdd]="stepPermissions.sourceDocumentUploadAllowed"
                [canEdit]="stepPermissions.sourceDocumentUploadAllowed"
                [canDelete]="stepPermissions.sourceDocumentUploadAllowed"
            ></document-manager-new>
        </div>

        <div class="bottom-buttons-wrapper">
            <eff-button
                *ngIf="stepPermissions.sourceDocumentUploadAllowed"
                size="large-size"
                text="Control didn't occur"
                class="bottom-button control-not-occured-btn"
                (click)="controlDidNotOccurWithConfirmation()"
                data-uiid="new-eff-upload-source-document-control-didnt-occur-button"
            ></eff-button>

            <eff-button
                *ngIf="stepPermissions.sourceDocumentUploadAllowed && !areDocumentsUploaded()"
                text="Continue without source document"
                size="large-size"
                class="bottom-button without-source-documents-btn"
                (click)="completeSourceDocumentsWithConfirm()"
                data-uiid="new-eff-upload-source-document-continue-without-source-document-button"
            ></eff-button>

            <eff-button
                text="Generate Samples"
                size="large-size"
                class="bottom-button generate-sample-button"
                *ngIf="stepPermissions.isCompleteAllowed && areDocumentsUploaded()"
                [disabled]="!isDocumentSelected()"
                (onClick)="openGenerateSamplePopup()"
                data-uiid="new-eff-upload-source-document-generate-samples-button"
            >
            </eff-button>
        </div>
    </div>

    <div class="side-page-area">
        <eff-expansion-panel
            [contentTemplate]="content"
            title="Source Upload Instructions"
            [panelOpenState]="true"
            data-uiid="new-eff-upload-source-document-source-upload-instructions-info-box"
        >
            <ng-template #content>
                <div
                    class="regular-text multiple-whitespaces"
                    [innerHTML]="effectivenessModel.sourceDocumentUploadInstructions"
                ></div>
            </ng-template>
        </eff-expansion-panel>
        <eff-evidence-instructions-info-box
            [samples]="samples"
            [instructions]="effectivenessModel.evidenceUploadInstructions"
        >
        </eff-evidence-instructions-info-box>
        <eff-test-instructions-info-box
            [panelOpenState]="false"
            [samples]="samples"
            [effectivenessModel]="effectivenessModel"
        >
        </eff-test-instructions-info-box>
        <eff-control-info-box [controlGuid]="effectivenessModel.controlGuid"></eff-control-info-box>
    </div>
</div>

<cerrix-slide-over
    #cerrixSlideOver
    data-uiid="new-eff-upload-source-document-instructions-slide-over"
>
    <ng-template #title>
        <div class="paragraph">
            <div class="header">How to upload source documents</div>
        </div>
    </ng-template>
    <ng-template #content>
        <div class="paragraph">
            <div class="header">1. Add source document</div>
            <div class="content">
                To upload a source document, either click the "Add Document" button or simply drag
                and drop the document into the designated light blue box. You'll have the option to
                select the document type and give a description in the provided fields.
            </div>
            <div class="content">
                To <b>save changes</b>: Click the "Save changes" button if you wish to save the
                current changes without proceeding to the next stage.
            </div>
            <div class="content">
                To <b>complete</b> this step: Click the green "Complete source document upload"
                button to save your upload and advance to the following stage of the test.
            </div>
        </div>
        <div class="paragraph">
            <div class="header">2. Proceeding without a source document</div>
            <div class="content">
                If there is no source document available please click on the “Continue without
                source document” button. The system will continue to the next stage of the test.
            </div>
        </div>
        <div class="paragraph">
            <div class="header">3. Control did not occur</div>
            <div class="content">
                During an "on event" control, there might be instances where the control doesn't
                take place within the designated test period. Should this occur, click the "Control
                did not occur" button. The test plan will then be automatically forwarded to the
                tester for finalization.
            </div>
        </div>
    </ng-template>
</cerrix-slide-over>
