import { SettingsDataService } from "./../../services/http/SettingsDataService";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";
import { ApplicationSettings } from "@services/http/settings/application-settings";

@Injectable()
export class IsAuthenticated {
    constructor(
        private router: Router,
        private _userDS: UserAuthenticationService,
        private settingsDataService: SettingsDataService
    ) {}

    canActivate(): Observable<boolean> {
        return this._userDS.isAuthenticated().pipe(
            map((authenticated) => {
                if (!authenticated) {
                    // Get the full path + query the user navigated to.
                    const currentUrl = window.location.href
                        .substring(window.location.origin.length)
                        .toLowerCase();

                    // encode currentUrl as return url if the currentUrl is not a navigation to root.
                    const returnUrl = currentUrl != "/" ? encodeURI(currentUrl) : null;

                    this.router.navigate(["/login"], { queryParams: { returnurl: returnUrl } });
                    return false;
                } else {
                    this.getIncidentUrl();
                    return true;
                }
            })
        );
    }

    private async getIncidentUrl() {
        if (!localStorage.getItem("incidentUrl")) {
            const url = await this.settingsDataService
                .getSetting(ApplicationSettings.IncidentsUrl)
                .toPromise();
            localStorage.setItem("incidentUrl", url.TextValue);
        }
    }
}
