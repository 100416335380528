<table
    class="table-cerrix bordered rounded hoverable striped"
    [class.centered]="Centered"
    [class.bordered]="!NoBorder"
    [class.bordered-columns]="!NoColumnBorder"
    [class.p-0]="NoPadding"
>
    <thead>
        <tr>
            <th *ngIf="selectedProp"></th>
            <th *ngIf="showID">Identifier</th>
            <th
                *ngFor="let header of headers"
                (click)="sortOnColumn(header)"
                [class.clickable]="columnSortEnabled"
            >
                {{ prettyHeaders[header] }}
                <i
                    class="fas fa-question-circle"
                    [title]="headerTooltips[header]"
                    *ngIf="headerTooltips[header]"
                ></i>
                <i
                    *ngIf="columnSortEnabled && header === columnSortName"
                    class="fas fa-sort-amount-{{ columnSortAsc ? 'up' : 'down' }}"
                ></i>
            </th>
            <th *ngIf="config && config.overviewRowActions && config.overviewRowActions.length > 0">
                {{ config.overviewRowActionsName }}
            </th>
        </tr>
    </thead>
    <tbody [sortablejs]="data" [sortablejsOptions]="sortingOptions">
        <tr
            *ngFor="let row of data; index as i"
            class="hover-row"
            [attr.data-uiid]="'cerrix-table-row-' + i"
            [ngClass]="{ highlighted: activeRow && activeRow[idProp] == row[idProp] }"
            (click)="triggerClick(row)"
            (dblclick)="triggerDblClick(row)"
        >
            <td scope="row" *ngIf="selectedProp">
                <cerrix-checkbox [(value)]="row[selectedProp]"></cerrix-checkbox>
            </td>
            <td scope="row" *ngIf="showID">{{ row[idProp] }}</td>

            <td *ngFor="let header of headers" class="text-truncate" style="max-width: 400px">
                <workspace-cell
                    [configs]="rendererConfig"
                    [row]="row"
                    [header]="header"
                    [stretched]="false"
                    [attr.data-uiid]="'cerrix-table-' + header + '-row-' + i"
                >
                </workspace-cell>
            </td>
            <td *ngIf="config && config.overviewRowActions && config.overviewRowActions.length > 0">
                <i
                    *ngFor="let btnConfig of config.overviewRowActions"
                    [class]="btnConfig.icon + ' ms-2 me-2'"
                    [matTooltip]="btnConfig.tooltip"
                    (click)="btnConfig.clickEvent(row)"
                    [hidden]="btnConfig.showProperty && !row[btnConfig.showProperty]"
                ></i>
            </td>
        </tr>
    </tbody>
</table>
