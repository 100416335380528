<div class="container-with-fancy-loader">
    <div *ngIf="!finishedLoading">
        <div class="fancy-loader"></div>
    </div>
    <table class="table table-sm" *ngIf="finishedLoading">
        <thead>
            <tr>
                <th class="action-button" style="width: 28px" (click)="toggleEditors()">
                    <i
                        class="far"
                        [class.fa-minus-square]="isAllExpanded"
                        [class.fa-plus-square]="!isAllExpanded"
                    ></i>
                </th>
                <th>Sample name</th>
                <th style="width: 25px"></th>
                <th style="width: 150px">Score</th>
                <th class="d-none d-lg-table-cell">Comment tester</th>
                <th class="d-lg-none" style="width: 0px"></th>
                <th *ngIf="useReviewer" class="d-none d-lg-table-cell">Comment reviewer</th>
                <th *ngIf="useReviewer" class="d-lg-none" style="width: 0px"></th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-sample [ngForOf]="evidenceSamples">
                <tr (click)="toggleEditor(sample)" class="action-button">
                    <td class="action-column">
                        <i
                            class="far"
                            [class.fa-minus-square]="sample.showEditor"
                            [class.fa-plus-square]="!sample.showEditor"
                        ></i>
                    </td>
                    <td>
                        <b>{{ sample.name }}</b>
                        <i *ngIf="sample.remarks" [title]="sample.remarks" class="ms-2">{{
                            sample.remarks
                        }}</i>
                    </td>
                    <td (click)="onSampleEditRemarkClick(sample, $event)" class="action-column">
                        <span *ngIf="workflowStepPermission.isEditSampleRemarkAllowed"
                            ><i class="fas fa-edit"></i
                        ></span>
                    </td>
                    <td>
                        <div
                            class="cell-score-color"
                            [ngStyle]="{
                                'background-color': sample.scoreColor ? sample.scoreColor : ''
                            }"
                        ></div>
                        {{ sample.scoreName }}
                    </td>
                    <td class="d-none d-lg-table-cell">
                        {{ sample.commentTester }}
                    </td>
                    <td class="d-lg-none"></td>
                    <td *ngIf="useReviewer" class="d-none d-lg-table-cell">
                        {{ sample.commentReviewer }}
                    </td>
                    <td *ngIf="useReviewer" class="d-lg-none"></td>
                </tr>
                <tr [hidden]="!sample.showEditor">
                    <td [colSpan]="useReviewer ? 6 : 5" class="expanding-row-content">
                        <adv-eff-evidence-sample-editor
                            #editorComponent
                            #sampleEditorComponent
                            [useReviewer]="useReviewer"
                            [workflowStepPermission]="workflowStepPermission"
                            [evidenceItem]="sample"
                            [showAllFields]="showAllFields"
                            [scores]="sampleScores"
                        >
                        </adv-eff-evidence-sample-editor>
                    </td>
                </tr>

                <ng-template ngFor let-testStep let-i="index" [ngForOf]="sample.testSteps">
                    <tr
                        (click)="toggleEditor(testStep)"
                        class="action-button"
                        [class.odd-test-step]="i % 2 === 0"
                    >
                        <td class="action-column">
                            <i
                                class="far ms-2"
                                [class.fa-minus-square]="testStep.showEditor"
                                [class.fa-plus-square]="!testStep.showEditor"
                            ></i>
                        </td>
                        <td colspan="2">
                            {{ testStep.name }}
                        </td>
                        <td>
                            <div
                                class="cell-score-color"
                                [ngStyle]="{
                                    'background-color': testStep.scoreColor
                                        ? testStep.scoreColor
                                        : ''
                                }"
                            ></div>
                            {{ testStep.scoreName }}
                        </td>
                        <td class="d-none d-lg-table-cell">
                            {{ testStep.commentTester }}
                        </td>
                        <td class="d-lg-none"></td>
                        <td *ngIf="useReviewer" class="d-none d-lg-table-cell">
                            {{ testStep.commentReviewer }}
                        </td>
                        <td *ngIf="useReviewer" class="d-lg-none"></td>
                    </tr>
                    <tr [hidden]="!testStep.showEditor">
                        <td [colSpan]="useReviewer ? 6 : 5" class="expanding-row-content">
                            <adv-eff-evidence-test-step-editor
                                #editorComponent
                                #testStepEditorComponent
                                [useReviewer]="useReviewer"
                                [workflowStepPermission]="workflowStepPermission"
                                [evidenceItem]="testStep"
                                [showAllFields]="showAllFields"
                                [scores]="testStepScores"
                                (testStepScoreChange)="onTestStepScoreChange($event)"
                            ></adv-eff-evidence-test-step-editor>
                        </td>
                    </tr>
                </ng-template>
            </ng-template>
            <tr *ngIf="!evidenceSamples.length">
                <td [colSpan]="useReviewer ? 6 : 5" align="center">No samples</td>
            </tr>
        </tbody>
    </table>
</div>
