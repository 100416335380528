import { Component, Input } from "@angular/core";
import { ControlModel } from "@app/controls/models/ControlModel";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { LinkModel } from "@models/generic/LinkModel";

@Component({
    selector: 'control-datamanagement',
    templateUrl: './control-datamanagement.component.html',
    styleUrls: ['./control-datamanagement.component.scss']
})
export class ControlDatamanagementComponent {

    @Input() controlModel: ControlModel;
    @Input() disabled: boolean;

    linked: LinkModel[];
    unlinked: LinkModel[];
    isLoading = true;

    menuItem: TabMenuItem;
    tabIconBackup: string;

    backupList: number[];

    constructor(private _controlService: ControlDataService) { }

    load(menuItem: TabMenuItem): void {
        this.menuItem = menuItem;
        if (!this.controlModel.Organization) {
            return;
        }
        if (!this.backupList) {
            this.backupList = this.controlModel.PpIdList;
        }
        this._controlService.getLinkablePp(this.controlModel.Guid).subscribe(data => {
            const linkedIds = this.getLinked();
            this.loadData(linkedIds, data);
            this.isLoading = false;
        });
    }

    getLinked(): number[] {
        return this.controlModel.PpIdList;
    }

    private loadData(linkedIds: number[], items: LinkModel[]): void {
        const linked: LinkModel[] = []
        const unlinked: LinkModel[] = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }

    linkedItemsChanged(linked: LinkModel[]): void {
        const newPpsList = linked.map(x => x.id);
        this.controlModel.PpIdList = newPpsList.sort((a, b) => a - b);

        const dirty = this.backupList.isDifferent(this.controlModel.PpIdList);
        if (!this.menuItem) {
            return;
        }

        if (!this.tabIconBackup) {
            this.tabIconBackup = this.menuItem.iconClass;
        }

        this.menuItem.iconClass = this.tabIconBackup + (dirty ? " orange" : "");
    }
}
