import { Component, Input, OnInit } from "@angular/core";
import { AuditFindingLinkModel } from "@app/audit-finding/shared/data-models/audit-finding-link-model";
import { AuditFindingCreateRequestModel } from "@app/audit-finding/shared/page-models/audit-finding-create-request-model";
import { AuditFieldworkPermissionModel } from "@app/audit/shared/permission-models/audit-fieldwork-permission-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { StandingDataType } from "@enums/StandingDataType";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { map } from "rxjs";
import { TabService } from "../../../../../../services/tabs/TabService";
import { AuditFieldworkStepDataService } from "../../../../services/audit-fieldworkstep-data.service";

@Component({
    selector: "audit-fieldwork-step-finding-linking",
    templateUrl: "./audit-fieldwork-step-finding-linking.component.html",
    styleUrls: ["./audit-fieldwork-step-finding-linking.component.scss"],
})
export class AuditFieldworkStepFindingLinkingComponent implements OnInit {
    @Input() auditGuid: string;
    @Input() stepGuid: string;
    @Input() permissions: AuditFieldworkPermissionModel;
    @Input() linkedFindings: number[];

    tableConfig: GenericListConfig;

    constructor(
        private _ds: AuditFieldworkStepDataService,
        private _sdDs: StandingdataDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    async ngOnInit() {
        if (!this.permissions || !this.permissions.canViewLinkedFindings) {
            return;
        }

        this.createTableConfig();
    }

    openFinding(row: AuditFindingLinkModel) {
        if (row) {
            this._tabService.generateTab(this._pages.AuditFindingDetail, row.guid);
        } else {
            this._tabService.generateTab(this._pages.AuditFindingDetail, null, "", <
                AuditFindingCreateRequestModel
            >{
                auditguid: this.auditGuid,
                stepguid: this.stepGuid,
            });
        }
    }

    private createTableConfig() {
        this.tableConfig = <GenericListConfig>{
            name: "Linked Findings",
            idProp: nameof<AuditFindingLinkModel>((x) => x.id),

            allowAdd: this.permissions.canLinkFindings,
            allowEdit: this.permissions.canLinkFindings,
            allowLink: this.permissions.canLinkFindings,
            linkData: <any>{
                displayValueProp: nameof<AuditFindingLinkModel>((x) => x.identifierDescription),
                linkablePaginationItemCount: 15,
                hidePanelToggle: true,
            },

            uiidAdd: "add-new-finding-button",

            fields: [
                {
                    prettyName: "Identifier",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.identifier),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Finding description",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.findingDescription),
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    prettyName: "Recommendation",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.recommendation),
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    prettyName: "Severity",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.severity),
                    fieldType: GenericListFieldType.ColoredSelect,
                    getDataMethod: () => {
                        return this._sdDs.getAllByType(StandingDataType.AuditSeverity);
                    },
                },
                {
                    prettyName: "Follow-up",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.followUp),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            dataMethod: () => {
                return this._ds.getLinkableFindings(this.auditGuid).pipe(
                    map(async (data) => {
                        const newLinks = await toPromise(
                            this._ds.getFieldworkLinkedFindings(this.auditGuid, this.stepGuid)
                        );

                        this.linkedFindings.splice(0, this.linkedFindings.length);
                        this.linkedFindings.push(...newLinks);

                        let linked: AuditFindingLinkModel[] = [];
                        let unlinked: AuditFindingLinkModel[] = [];

                        if (this.linkedFindings.length === 0) {
                            unlinked = data;
                        } else {
                            data.forEach((row) => {
                                if (this.linkedFindings.indexOf(row.id) >= 0) {
                                    linked.push(row);
                                } else {
                                    unlinked.push(row);
                                }
                            });
                        }

                        this.tableConfig.linkData.unlinked = unlinked;
                        return linked;
                    })
                );
            },

            addOverride: () => {
                this.openFinding(null);
            },
            editOverride: (row) => {
                this.openFinding(row);
            },

            onLinkedDataChange: (data: AuditFindingLinkModel[]) => {
                this.linkedFindings.splice(0, this.linkedFindings.length);
                this.linkedFindings.push(...data.map((d) => d.id));
            },
        };
    }
}
