import { Component, Input, OnInit } from "@angular/core";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";

@Component({
    selector: "tuckedmenu",
    templateUrl: "./tuckedmenu.component.html",
    styleUrls: ["./tuckedmenu.component.scss"],
})
export class TuckedMenuComponent implements OnInit {
    @Input() mainMenu: TabMenu;

    @Input() parent: TabMenuItem;
    @Input() menuItems: TabMenuItem[];

    // Do not change this, unless you know what you are doing.
    @Input() isSubSection = false;

    @Input() changeCallback: () => void;

    private disableHoverTimeout: any;
    showHover = false;
    hoverMenuKeepOpen = false;
    hoverTop = 100;
    hoverItem: TabMenuItem;

    constructor() {}

    ngOnInit() {
        if (!this.menuItems) {
            this.loadInitialItems();
            this.setExpandedItems(this.mainMenu.menuItems);
        }
    }

    loadInitialItems() {
        if (!this.mainMenu.menuItems) {
            return;
        }

        this.menuItems =
            this.mainMenu.menuItems.length === 1
                ? this.mainMenu.menuItems[0].children
                : this.mainMenu.menuItems;
    }

    clickMenuItem(item: TabMenuItem) {
        if (item.clickable) {
            this.mainMenu.menuItemClicked(item);
        } else if (item.children && item.children.length > 0) {
            this.clickMenuItem(item.children[0]);
        }

        this.setExpandedItems(this.mainMenu.menuItems);

        // This triggers the mouseleft event, since logically the hovermenu cannot stay open after clicks, but this event does not trigger.
        this.mouseLeftHoverMenu();
    }

    private setExpandedItems(items: TabMenuItem[]): boolean {
        let anyExpanded = false;
        items.forEach((item) => {
            let expanded = false;
            if (item.identifier === this.mainMenu.activeMenuItem) {
                expanded = true;
            } else if (item.children && item.children.length > 0) {
                const childrenExpanded = this.setExpandedItems(item.children);
                if (childrenExpanded) {
                    expanded = true;
                }
            }

            if (expanded) {
                anyExpanded = true;
            }
        });

        return anyExpanded;
    }

    //#region Hover Menu

    mouseEnteredItem(ev: MouseEvent, item: TabMenuItem) {
        this.hoverTop = ev.y - ev.offsetY;
        this.hoverItem = item;
        this.setShowHover(true);
    }

    mouseLeftItem() {
        this.setShowHover(false);
    }

    mouseEnteredHoverMenu() {
        if (this.hoverItem.children && this.hoverItem.children.length > 0) {
            this.hoverMenuKeepOpen = true;
        }
    }

    mouseLeftHoverMenu() {
        this.hoverMenuKeepOpen = false;
    }

    setShowHover(enable: boolean) {
        if (this.disableHoverTimeout) {
            clearTimeout(this.disableHoverTimeout);
            this.disableHoverTimeout = null;
        }

        if (enable) {
            this.showHover = true;
        } else {
            this.disableHoverTimeout = setTimeout(() => {
                this.showHover = false;
            }, 10);
        }
    }

    //#endregion Hover Menu
}
