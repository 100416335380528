import { Pages } from "@constants/pages/Pages";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { DashboardQuickLinkModel } from "../../../shared/models/DashboardQuickLinkModel";
import { ILinkWidgetGeneratorType } from "./../../../shared/interfaces/ILinkWidgetGeneratorType";

export class NavigatorLinkGenerator implements ILinkWidgetGeneratorType {
    getTypes(permissions: ApplicationPermissionsModel, pages: Pages): DashboardQuickLinkModel[] {
        const quickLinks = [];
        if (permissions.HasBDManagementAccess) {
            quickLinks.push({
                title: "Organizations & Business Dimensions",
                icon: "fad fa-building",
                page: pages.NavBusinessDimension,

                description:
                    "Overview of all items linked to Organizations & Business Dimensions and review actions.",
            });
        }

        if (permissions.HasFDManagementAccess) {
            quickLinks.push({
                title: "Framework Dimensions",
                icon: "fad fa-object-group",
                page: pages.FrameworkNavigator,

                description: "Overview of Frameworks and linked controls.",
            });
        }

        if (permissions.HasProcessViewerAccess) {
            quickLinks.push({
                title: "Process Overview",
                icon: "fad fa-map",
                page: pages.NavProcessOverview,

                description: "Diagramming module in which you can view processes.",
            });
        }

        if (permissions.HasProcessEditorAccess) {
            quickLinks.push({
                title: "Process Editor",
                icon: "fad fa-sitemap",
                page: pages.NavProcessEditor,

                description: "Diagramming module in which you can draw processes.",
            });
        }

        return quickLinks;
    }
}
