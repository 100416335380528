<div mat-dialog-title>Preset configuration</div>
<mat-dialog-content class="preset-config-dialog">
    <mat-tab-group class="preset-tab-group">
        <mat-tab label="Filter">
            <mat-grid-list rowHeight="fit" cols="2" class="dialog-grid-list">
                <mat-grid-tile>
                    <form
                        [formGroup]="presetRuleForm"
                        #formDirective="ngForm"
                        (ngSubmit)="onAddFilter(formDirective)"
                        class="form-field-group"
                    >
                        <mat-form-field>
                            <mat-label>Column</mat-label>
                            <input
                                type="text"
                                placeholder="Search for column"
                                matInput
                                required
                                formControlName="column"
                                [matAutocomplete]="auto"
                            />
                            <mat-autocomplete
                                #auto="matAutocomplete"
                                [displayWith]="displayColumnText.bind(this)"
                                (optionSelected)="onSelectedColumn($event.option.value, false)"
                                autoActiveFirstOption
                            >
                                <mat-option
                                    *ngFor="let column of filteredColumns$ | async"
                                    [value]="column"
                                    >{{
                                        column.translation ??
                                            incidentColumnsTranslation.get(column.name)
                                    }}</mat-option
                                >
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Compare with</mat-label>
                            <mat-select formControlName="comparisonOperator">
                                <mat-option
                                    *ngFor="
                                        let comparisonOperator of filteredComparisonOperators$
                                            | async
                                    "
                                    [value]="comparisonOperator"
                                    >{{ comparisonOperator.name }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>

                        <ng-container
                            *ngIf="
                                this.presetRuleForm.get('column').value?.fieldType ===
                                    FieldType.Numeric;
                                else dateTime
                            "
                        >
                            <mat-form-field>
                                <mat-label>Search value</mat-label>
                                <input type="number" matInput formControlName="searchValue" />
                            </mat-form-field>
                        </ng-container>

                        <ng-template #dateTime>
                            <ng-container
                                *ngIf="
                                    this.presetRuleForm.get('column').value?.fieldType ===
                                        FieldType.Date ||
                                        this.presetRuleForm.get('column').value?.fieldType ===
                                            FieldType.DateTime;
                                    else checkbox
                                "
                            >
                                <mat-form-field>
                                    <mat-label>Search value</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="datepicker"
                                        formControlName="searchValue"
                                        [placeholder]="dateFormats.display.dateInput"
                                    />
                                    <mat-datepicker-toggle
                                        matIconSuffix
                                        [for]="datepicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #datepicker></mat-datepicker>
                                </mat-form-field>
                            </ng-container>
                        </ng-template>

                        <ng-template #checkbox>
                            <ng-container
                                *ngIf="
                                    this.presetRuleForm.get('column').value?.fieldType ===
                                        FieldType.Checkbox;
                                    else treeSelect
                                "
                            >
                                <mat-checkbox formControlName="searchValue"
                                    >value should be</mat-checkbox
                                >
                            </ng-container>
                        </ng-template>

                        <ng-template #treeSelect>
                            <ng-container
                                *ngIf="
                                    treeColumnNames.includes(
                                        this.presetRuleForm.get('column').value?.name
                                    );
                                    else selectDropdown
                                "
                            >
                                <cerrix-tree-select
                                    formControlName="searchValue"
                                    [treeList]="ruleSearchOptions"
                                    [multiple]="true"
                                ></cerrix-tree-select>
                            </ng-container>
                        </ng-template>

                        <ng-template #selectDropdown>
                            <ng-container
                                *ngIf="
                                    this.presetRuleForm.get('column').value?.fieldType ===
                                        FieldType.SingleSelect ||
                                        this.presetRuleForm.get('column').value?.fieldType ===
                                            FieldType.MultiSelect;
                                    else defaultFieldType
                                "
                            >
                                <mat-form-field>
                                    <mat-label>Search value</mat-label>
                                    <span *ngIf="loadingRuleSearchOptions" class="dropdown-spinner"
                                        ><mat-spinner diameter="20"></mat-spinner
                                    ></span>

                                    <mat-select formControlName="searchValue" [multiple]="true">
                                        <mat-option
                                            *ngFor="let ruleSearchOption of ruleSearchOptions"
                                            [value]="ruleSearchOption.id"
                                            >{{ ruleSearchOption.name }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </ng-template>

                        <ng-template #defaultFieldType>
                            <mat-form-field>
                                <mat-label>Search value</mat-label>
                                <input type="text" matInput formControlName="searchValue" />
                            </mat-form-field>
                        </ng-template>

                        <div class="add-filter-actions">
                            <button type="submit" mat-stroked-button>
                                <ng-container>Add filter</ng-container
                                ><mat-icon iconPositionEnd>arrow_right</mat-icon>
                            </button>
                        </div>
                    </form>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div *ngIf="!presetRules.length; else filters">
                        <div>No filters specified.</div>
                    </div>
                    <ng-template #filters>
                        <div class="added-filters">
                            <mat-chip-listbox>
                                <mat-chip-option
                                    *ngFor="let rule of presetRules"
                                    (removed)="onRemoveFilter(rule)"
                                    (click)="onEditFilter(rule)"
                                >
                                    {{
                                        rule.customFieldId
                                            ? rule.columnName
                                            : incidentColumnsTranslation.get(rule.columnName)
                                    }}
                                    {{
                                        comparisonOperatorsTranslation.get(rule.comparisonOperator)
                                    }}

                                    <ng-container
                                        *ngIf="
                                            rule.fieldType === FieldType.Date ||
                                                rule.fieldType === FieldType.DateTime;
                                            else ruleDefault
                                        "
                                    >
                                        {{ rule.value | dateTimeDisplay }}
                                    </ng-container>
                                    <ng-template #ruleDefault>{{
                                        rule.displayValue ?? rule.value
                                    }}</ng-template>

                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </ng-template>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-tab>
        <mat-tab label="Columns">
            <mat-grid-list rowHeight="fit" cols="2" class="dialog-grid-list" cdkDropListGroup>
                <mat-grid-tile>
                    <div class="columns-drop-container">
                        <div class="columns-drop-header">Visible columns</div>
                        <div
                            cdkDropList
                            #visibleList="cdkDropList"
                            [cdkDropListData]="visibleColumns"
                            [cdkDropListConnectedTo]="[hiddenList]"
                            class="columns-drop-list"
                            (cdkDropListDropped)="onDropColumn($event, true)"
                            cdkScrollable
                        >
                            <div
                                class="colum-drop-box"
                                cdkDrag
                                [cdkDragPreviewContainer]="'parent'"
                                matRipple
                                *ngFor="let column of visibleColumns"
                                (click)="onColumToggle(column, false)"
                            >
                                {{
                                    column.translation
                                        ? column.translation
                                        : incidentColumnsTranslation.get(column.name)
                                }}
                            </div>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div class="columns-drop-container">
                        <div class="columns-drop-header">Hidden columns</div>
                        <div
                            cdkDropList
                            #hiddenList="cdkDropList"
                            [cdkDropListData]="hiddenColumns"
                            [cdkDropListConnectedTo]="[visibleList]"
                            class="columns-drop-list"
                            (cdkDropListDropped)="onDropColumn($event, false)"
                            cdkScrollable
                        >
                            <div
                                class="colum-drop-box"
                                cdkDrag
                                [cdkDragPreviewContainer]="'parent'"
                                matRipple
                                *ngFor="let column of hiddenColumns"
                                (click)="onColumToggle(column, true)"
                            >
                                {{
                                    column.translation
                                        ? column.translation
                                        : incidentColumnsTranslation.get(column.name)
                                }}
                            </div>
                        </div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <ng-container>Details</ng-container>
                <span *ngIf="dataLoaded && !presetDetailsForm.valid" class="invalid-message"
                    >*</span
                >
            </ng-template>
            <mat-grid-list rowHeight="fit" cols="2" class="dialog-grid-list">
                <mat-grid-tile>
                    <form [formGroup]="presetDetailsForm" class="form-field-group">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input type="text" matInput formControlName="name" />
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Icon</mat-label>
                            <input type="text" matInput formControlName="icon" />
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Description</mat-label>
                            <textarea rows="3" matInput formControlName="description"></textarea>
                        </mat-form-field>

                        <mat-checkbox formControlName="isFavorite">Is favorite</mat-checkbox>
                    </form>
                </mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'" class="footer">
    <div>
        <span
            *ngIf="dataLoaded && !(this.presetRules?.length > 0) && !this.hasColumnsChanges"
            class="invalid-message"
            >Please add at least one rule or make one column change.</span
        >
        <div>
            <button mat-stroked-button [mat-dialog-close]="true">
                <mat-icon>close</mat-icon>
                <ng-container>Cancel</ng-container>
            </button>
            <button
                [disabled]="!(this.presetRules?.length > 0) && !this.hasColumnsChanges"
                mat-stroked-button
                (click)="onApplyPreset()"
            >
                <mat-icon>check</mat-icon>
                <ng-container>Preview preset</ng-container>
            </button>
            <button
                [disabled]="
                    saveDisabled || (!(this.presetRules?.length > 0) && !this.hasColumnsChanges)
                "
                mat-stroked-button
                (click)="onSavePreset()"
            >
                <mat-icon>save</mat-icon>
                <ng-container>Save preset</ng-container>
            </button>
        </div>
    </div>
</mat-dialog-actions>
