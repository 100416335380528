<button class="btn btn-raised" *ngIf="canEdit" (click)="manageExecutionDetails()">
    <span>Manage execution</span>
</button>

<ng-template #spreadsheet>
    <div class="popup-instructions">
        <span>
            A Fieldwork step can be decorated with custom fields to define the execution details.
            These custom fields are currently only manageable with Excel. To manage the execution
            details please follow the following instructions:
        </span>
        <ol>
            <li>
                <span>
                    Click the download button to download the excel file with the current steps and
                    execution details.
                </span>
            </li>
            <li>
                <span>Open the excel file and define the execution details.</span><br />
                <span><b>The following functionalities are available:</b></span>
                <ul>
                    <li>Field names can be defined in the second row starting from column C.</li>
                    <li>
                        Leaving the column value empty for a row, will not include the field on the
                        step.
                    </li>
                </ul>
                <span><b>The following limitations apply:</b></span>
                <ul>
                    <li>
                        The first two columns (column A and column B) are used for mapping, so
                        changing these columns could result in errors.
                    </li>
                    <li>
                        Fields are ordered alphabetically when shown. Prefix fields with numbers to
                        keep fields ordered.
                    </li>
                    <li>
                        A row has a limit of 30.000 allowed characters over all fields. This
                        includes the field name. Exceeding this limit will result in a warning with
                        the name of the step and the step will not have any execution details.
                    </li>
                    <li>
                        Using an excel file that is not downloaded in the current session or an
                        excel file that is not the latest downloaded file, will result in a popup
                        with a warning. The user is free to continue, but correct execution is not
                        guaranteed.
                    </li>
                </ul>
            </li>
            <li>
                <span>
                    Click the upload button and select the edited excel file. On select the
                    application will read the file and copy the execution details to the steps.
                </span>
            </li>
        </ol>
    </div>

    <div class="popup-buttons">
        <button class="btn btn-light" (click)="downloadExcel()">
            <i class="fas fa-download"></i>
            <span class="ms-2">Download</span>
        </button>

        <input
            type="file"
            multiple="false"
            style="display: none"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="processExcel($event)"
            #fileInput
        />
        <button class="btn btn-light" (click)="fileInput.click()">
            <i class="fas fa-upload"></i>
            <span class="ms-2">Upload</span>
        </button>
    </div>
</ng-template>
