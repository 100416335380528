import { TabService } from "@services/tabs/TabService";
import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { Preset, PresetConfig } from "@models/workspace/Preset";

import { ModuleType } from "@enums/ModuleType";

import { FormatType } from "common/enums/FormatType";
import "@methods/Array";
import customTableSort from "@methods/customTableSort";
import { Export } from "@methods/Export";
import { copyText, IsMobile } from "@methods/CommonMethods";
import { WorkspaceAdvancedConfigComponent } from "./workspace-advanced-config/workspace-advanced-config.component";
import { WorkspacePresetManagementComponent } from "./workspace-preset-management/workspace-preset-management.component";
import { CellRendererPipe } from "../pipes/cell-renderer.pipe";
import { WorkspaceContextMenuItem } from "@models/workspace/WorkspaceContextMenuItem";
import { WorkspacePresetCache } from "@models/workspace/WorkspacePresetCache";
import { CerrixPromptService } from "../services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { PresetFilterHelper } from "../helpers/preset-filter-helper";
import { ModuleSubType } from "@enums/ModuleSubType";
import { WorkspacePresetService } from "../services/workspace/workspace-preset.service";
import { firstValueFrom } from "rxjs";

declare var $: any;

@Component({
    selector: "cerrix-workspace",
    templateUrl: "./workspace.component.html",
    styleUrls: ["./workspace.component.scss"],
})
export class WorkspaceComponent implements OnInit {
    // Tab Related
    @Input() tabID: string;
    @Input() cerrixTab: TabModel;

    // Data Related
    @Input() idProp: string;
    @Input() data: Promise<any[]>;
    @Input() headerLookup: any = {};
    @Input() module: string;
    @Input() moduleType: ModuleType;
    @Input() moduleSubType: ModuleSubType;
    @Input() appendToModuleType: string;
    @Input() permissions = new WorkspaceModulePermissionModel();

    // Configurations
    @Input() filterConfig: FilterConfig[] = [];
    @Input() rendererConfig: RendererConfig[] = [];
    @Input() buttonConfig: WorkspaceButtonConfig[] = [];
    @Input() contextMenuItems: WorkspaceContextMenuItem[] = [];

    // Custom properties
    @Input() NumberProperties: string[] = [];
    @Input() DateProperties: string[] = [];

    @Input() CanAdd = false;
    @Input() CanEdit = false;
    @Input() CanPublish = false;

    @Input() useLightWorkspace = false;
    @Input() usePresetHyperlink = true;

    // Events
    @Output() onAddClick = new EventEmitter<string>();
    @Output() onDblClick = new EventEmitter<any>();
    @Output() onServerFiltersApplied = new EventEmitter<SearchKeyword[]>();

    // Variables for pagelogic.
    // ...
    stretchedHeaders = false;
    headerWidths = {};
    availableHeaders: string[];
    activeHeaders: string[];

    allData: any[];
    filteredData: any[]; // data that has been filtered by advanced configuration, ready to be used to quickfilter and sort.
    allActiveData: any[]; // data that is quick filtered and sorted. changing pagination will take a piece from this list
    activeData: any[]; // data that is actually shown

    awaitingPreset: Preset;
    activePreset = new Preset();
    activePresetConfig = new PresetConfig();
    activeFilters: SearchKeyword[] = [];

    quickFilter = "";

    sortCol = "";
    sortAsc = false;

    highlightedRowID: string;
    paginationIndex = 0;
    paginationAmount = 25;
    paginationAmountOptions: number[] = [100, 50, 25, 10];

    allPageNumbers: number[];
    totalPages: number;

    isGeneratingExport: Boolean = false;

    // Standing Data.
    filters: any[] = [];

    // Loader variables
    dataFetched = false;
    presetsFetched = false;
    tableReady = false;

    isSorting = false;

    remoteFiltersValid = false;
    previousRemoteFilters: SearchKeyword[] = [];

    exporter: Export;

    @ViewChild("advancedConfigComponent")
    advancedConfigComponent: WorkspaceAdvancedConfigComponent;
    @ViewChild("workspacePresetManagement")
    workspacePresetManagement: WorkspacePresetManagementComponent;

    constructor(
        public tabService: TabService,
        private cellRenderer: CellRendererPipe,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _pages: Pages,
        private _presetDS: WorkspacePresetService
    ) {}

    async ngOnInit(): Promise<void> {
        this.setupInit();
        if (this.usePresetHyperlink) await this.preloadPreset(this.cerrixTab.id ?? null);

        this.setupWorkspace();

        $(window).on("resize", () => {
            this.setColumnWidths();
        });

        this.exporter = new Export(this.cellRenderer, this._promptService);
    }

    setupInit() {
        if (!this.cerrixTab.config) {
            this.cerrixTab.config = {};
        }

        // Store the tab.component reload function into tabs configuration to prevent extending the refresh on every reload
        const baseReloadImplementation = "baseReloadImplementation";
        if (!this.cerrixTab.config[baseReloadImplementation]) {
            this.cerrixTab.config[baseReloadImplementation] = this.cerrixTab.refresh;
        }

        // Override the refresh method to store the current preset in the tab.config
        this.cerrixTab.refresh = () => {
            if (!this.activePreset && !this.activeFilters) {
                this.cerrixTab.config[baseReloadImplementation]();

                return;
            }

            this.setPresetCache(this.activeFilters, this.activePreset);

            this.cerrixTab.config[baseReloadImplementation]();
        };

        const that = this;
        this.cerrixTab.copyUrlToClipboard = function () {
            let url = window.location.origin;
            if (!this.lookupname) {
                return;
            }

            const lookupKey = that._pages.getReversed(that.cerrixTab.lookupname);
            if (!lookupKey) {
                return;
            }

            url += "/" + lookupKey;

            if (that.cerrixTab.config.id) {
                url += `/${that.cerrixTab.config.id}`;
            }

            if (that.cerrixTab.config.presetCache) {
                url +=
                    "?presetCache=" +
                    encodeURIComponent(JSON.stringify(that.cerrixTab.config.presetCache));
            }

            copyText(url.toLowerCase());
            that._toastr.success("Url copied to clipboard", "Copy URL");
        };
    }

    async preloadPreset(presetId: any) {
        if (presetId == null) return;

        let module = ModuleType[this.moduleType];
        if (this.appendToModuleType) {
            module += "-" + this.appendToModuleType;
        }

        let presetConfigObserver = this._presetDS.getPreset(module, presetId);
        let presetConfig = await firstValueFrom(presetConfigObserver);
        this.cerrixTab.config.presetOverride = JSON.stringify(presetConfig);
    }

    setupWorkspace() {
        this.data.then((d) => {
            this.filteredData = null;
            this.allActiveData = null;
            this.activeData = null;

            this.allData = d ? d : null;
            if (d && d.length > 0) {
                this.setHeaders();

                this.dataFetched = true;
                if (this.awaitingPreset) {
                    this.applyPreset(this.awaitingPreset);
                    this.awaitingPreset = null;
                } else {
                    this.calculateData();
                }
            } else {
                this.dataFetched = true;
            }
            this.checkProgress();
        });
    }

    setPaginationAmount(amount?: number) {
        const updatePage = amount && amount !== this.paginationAmount;
        if (amount === 0) {
            amount = this.allData.length;
        }
        if (amount) {
            const newIndex = Math.floor((this.paginationIndex * this.paginationAmount) / amount);
            this.paginationIndex = newIndex;
            this.paginationAmount = amount;
        }

        this.totalPages = Math.ceil(this.allActiveData.length / this.paginationAmount);
        this.allPageNumbers = Array(this.totalPages)
            .fill(0)
            .map((x, i) => i)
            .sortDesc();

        if (updatePage) {
            this.setPaginationData();
        }
    }

    setHeaders() {
        this.availableHeaders = [];
        const allHeaders = Object.getOwnPropertyNames(this.allData[0]);

        allHeaders.forEach((head) => {
            let isViewHeader = true;
            if (head === this.idProp) {
                isViewHeader = false;
                return;
            }

            this.filterConfig.forEach((fConfig) => {
                if (fConfig.column === head && fConfig.hideActionColumn) {
                    isViewHeader = false;
                }
            });
            if (!isViewHeader) {
                return;
            }

            this.rendererConfig.forEach((rConfig) => {
                if (
                    (rConfig.hideColumn && rConfig.textColumn === head) ||
                    (rConfig.hideActionColumn && rConfig.actionColumn === head)
                ) {
                    isViewHeader = false;
                }
            });
            if (!isViewHeader) {
                return;
            }

            this.availableHeaders.push(head);
        });

        this.activeHeaders = JSON.parse(JSON.stringify(this.availableHeaders));
        this.sortCol = this.activeHeaders[0];
    }

    setColumnWidths() {
        const minWidth = 200,
            maxWidth = 400,
            // full window - tabmenu width - 2px padding per item.
            tabmenuTag = "view-" + this.tabID + " .tab-menu",
            tableWidth =
                window.innerWidth -
                ($(tabmenuTag).length > 0 ? $(tabmenuTag)[0].offsetWidth : 250) -
                2 * this.activeHeaders.length;

        let totalWidth = 0;
        this.activeHeaders.forEach((header) => {
            const rendererConfig: RendererConfig = this.rendererConfig.find(
                (rc) => rc.textColumn === header
            );

            let calculatedWidth = 0;

            // Handel renderer config of header
            if (rendererConfig) {
                if (rendererConfig.columnWidth) {
                    calculatedWidth = rendererConfig.columnWidth;
                } else if (rendererConfig.formatType) {
                    switch (rendererConfig.formatType) {
                        case FormatType.DateFormat: {
                            calculatedWidth = 120;
                            break;
                        }
                        case FormatType.DateTimeFormat: {
                            calculatedWidth = 150;
                            break;
                        }
                    }
                }
            }

            if (!calculatedWidth) {
                // Find column length if it's not configured
                let totalLength = 0;
                let singleRowLength = false;
                for (const row of this.allActiveData) {
                    const value = row[header];

                    if (value instanceof Date) {
                        singleRowLength = true;
                        totalLength = 150;
                        break;
                    }

                    const colLength = ("" + value).length;
                    totalLength += colLength;
                }

                if (!singleRowLength) {
                    totalLength *= 1.2; // give a 20% boost
                    calculatedWidth = (totalLength / this.allActiveData.length) * 6.5;
                }

                // Check header length
                const headerLength = this.headerLookup[header].length * 5.5;
                if (headerLength > maxWidth) {
                    calculatedWidth = maxWidth;
                } else if (headerLength > calculatedWidth) {
                    calculatedWidth = headerLength;
                }
            }

            const colWidth =
                calculatedWidth < minWidth
                    ? minWidth
                    : calculatedWidth > maxWidth
                    ? maxWidth
                    : calculatedWidth;

            totalWidth += colWidth;
            this.headerWidths[header] = colWidth;
        });

        this.stretchedHeaders = totalWidth < tableWidth;
        if (this.stretchedHeaders) {
            this.activeHeaders.forEach((header) => {
                const oldRatio = this.headerWidths[header];
                const newRatio = tableWidth * (oldRatio / totalWidth);

                this.headerWidths[header] = newRatio;
            });
        }
    }

    calculateData() {
        this.filteredData = PresetFilterHelper.filterData(
            this.activeFilters,
            this.allData,
            this.NumberProperties,
            this.DateProperties
        );

        this.setActiveData();
    }

    setActiveData() {
        const quickFilteredData = !this.quickFilter.trim()
            ? this.filteredData
            : this.quickFilterData(this.filteredData);
        const orderedData = this.sortData(quickFilteredData);
        this.allActiveData = orderedData;
        this.paginationIndex = 0;
        this.setPaginationData();
        this.setPaginationAmount();

        this.setColumnWidths();

        this.isSorting = false;
        this.tableReady = true;
        this.checkProgress();
    }

    setPaginationData() {
        const startIndex = this.paginationIndex * this.paginationAmount;
        const endIndex = +startIndex + +this.paginationAmount;
        this.activeData = this.allActiveData.slice(startIndex, endIndex);
    }

    quickFilterData(data: any[]): any[] {
        // Split filters by space
        const filters = this.quickFilter.toLowerCase().split(" ");
        const foundData = [];

        // Foreach row in passed data
        data.forEach((row) => {
            let filterMatched = true;

            // foreach filter in filters specificied (split by space)
            filters.forEach((filter) => {
                let filterFound = false;

                // check if any col value contains the filter. atleast one must contain the value
                this.activeHeaders.forEach((head) => {
                    const headVal = (("" + row[head]) as string).toLowerCase().trim();
                    if (headVal.indexOf(filter) >= 0) {
                        filterFound = true;
                        return;
                    }
                });

                // otherwise filter has failed
                if (!filterFound) {
                    filterMatched = false;
                    return;
                }
            });

            // and row is not shown to user.
            if (filterMatched) {
                foundData.push(row);
            }
        });

        return foundData;
    }

    sortData(data: any[]): any[] {
        const rendererConfig: RendererConfig = this.rendererConfig.find(
            (rc) => rc.textColumn === this.sortCol
        );

        const isNumeric = this.NumberProperties.indexOf(this.sortCol) >= 0;
        const isDate =
            rendererConfig &&
            rendererConfig.formatType &&
            (rendererConfig.formatType === FormatType.DateFormat ||
                rendererConfig.formatType === FormatType.DateShortTimeFormat ||
                rendererConfig.formatType === FormatType.DateTimeFormat);
        const isString = !isNumeric && !isDate;

        const compareMethod = customTableSort(
            this.sortAsc,
            this.sortCol,
            isString,
            isNumeric,
            isDate
        );
        const orderedData = data.sort(compareMethod);
        return orderedData;
    }

    rowClicked(row: any) {
        this.highlightedRowID = row[this.idProp];

        const isMobile = IsMobile();
        if (isMobile) {
            this.rowDblClicked(row);
        }
    }

    rowDblClicked(row: any) {
        if (this.permissions.canOpen) {
            this.onDblClick.emit(row);
        }
    }

    setPagination(page: number) {
        if (page < 0) {
            page = 0;
        }
        if (page > this.totalPages) {
            page = this.totalPages;
        }
        this.paginationIndex = page;
        this.setPaginationData();
    }

    updateSorting(header: string) {
        this.isSorting = true;

        if (this.sortCol !== header) {
            this.sortCol = header;
            this.sortAsc = true;
        } else {
            this.sortAsc = !this.sortAsc;
        }

        setTimeout(() => {
            this.setActiveData();
        }, 0);
    }

    showAdvancedConfig() {
        this.advancedConfigComponent.showConfig();
    }

    presetRequestChange(fetched: boolean) {
        if (fetched) {
            this.presetsFetched = true;
            this.checkProgress();
        }
    }

    applyPresetConfig(config: PresetConfig) {
        let preset: Preset;
        const presetCache = this.getPresetCache();
        if (presetCache && (presetCache.activePreset || presetCache.activeFilters)) {
            preset = presetCache.activePreset;
            this.activeFilters = presetCache.activeFilters;
        } else {
            preset = (config as PresetConfig).preset as Preset;
            const remoteFilters =
                this.activePreset && this.activePreset.searchKeywords
                    ? this.activePreset.searchKeywords.filter((x) => x.remoteSearch)
                    : [];
            preset.searchKeywords = preset.searchKeywords.concat(remoteFilters);
        }

        this.activePresetConfig = config;
        this.applyPreset(preset);
        this.setPresetCache(null, null);
    }

    applyPreset(preset: Preset) {
        // await if data is not retrieved yet
        if (!this.allData || !this.availableHeaders) {
            this.awaitingPreset = preset;
            if (this.dataFetched) {
                this.applyServerFilter();
            }
            return;
        }

        this.activePreset = preset;
        this.activeFilters = preset.searchKeywords;

        // CERRIX-3160 - This if statement can no longer occur now, but we have to keep it for the presets that are already saved.
        if (preset.columns.length === 1 && preset.columns[0] === "*") {
            this.activeHeaders = JSON.parse(JSON.stringify(this.availableHeaders));
        } else {
            this.activeHeaders = preset.columns.filter(
                (head) => this.availableHeaders.indexOf(head) >= 0
            );
        }

        this.sortCol = preset.sortColumn ? preset.sortColumn : this.sortCol;
        this.sortAsc = preset.sortOrder ? preset.sortOrder.toLowerCase() !== "desc" : true;
        this.paginationAmount = preset.paginationAmount
            ? preset.paginationAmount
            : this.paginationAmount;
        this.paginationIndex = 0;

        this.applyServerFilter();
    }

    checkProgress() {
        // So data is fetched and data is empty or data is fetched, presets are fetched and table is ready.
        if (
            this.dataFetched &&
            (!this.allData || this.allData.length === 0 || (this.presetsFetched && this.tableReady))
        ) {
            setTimeout(() => {
                this.setLoader(false);
            }, 1000);
        }
    }

    setLoader(enable: boolean) {
        this.cerrixTab.showLoader = enable;
    }

    processBtnConfigClick(config: WorkspaceButtonConfig) {
        if (config.clickEvent != null && !config.isDisabled) {
            let param: string | number | string[] | number[] = null;
            if (config.getVisibleRange === true) {
                param = this.allActiveData.map((x) => x[this.idProp]) as string[];
            } else if (config.rowRequired) {
                param = this.highlightedRowID;
            }

            const paramRequired = config.getVisibleRange || config.rowRequired;
            if (paramRequired && !param) {
                this._toastr.warning("No data available");
                return;
            }

            config.clickEvent(param);
        } else {
            this._toastr.error("", "Something went wrong!");
        }
    }

    async exportData() {
        this.isGeneratingExport = true;

        const moduleName = this.module.charAt(0).toUpperCase() + this.module.slice(1);
        const data = this.allActiveData.slice();
        const headers = this.activeHeaders.slice();
        await this.exporter
            .exportData(
                `${moduleName}-overview`,
                data,
                this.rendererConfig,
                headers,
                this.headerLookup
            )
            .then(() => {
                this.isGeneratingExport = false;
            })
            .catch((reason) => {
                this._promptService.alert({
                    data: {
                        title: "Export failed",
                        message: "Something went wrong while generating the export.",
                    },
                });
                this.isGeneratingExport = false;
            });
    }

    applyServerFilter() {
        const remoteFilters = this.activePreset.searchKeywords.filter(
            (x) => x.remoteSearch === true
        );
        let filtersChanged = this.previousRemoteFilters.length !== remoteFilters.length;

        if (!filtersChanged) {
            const filterChanges = this.previousRemoteFilters.findDifferences<
                SearchKeyword,
                SearchKeyword
            >(remoteFilters, (l, r) => {
                return l.searchValue === r.searchValue && l.col === r.col;
            });

            filtersChanged = filterChanges.added.any() || filterChanges.deleted.any();
        }

        if (filtersChanged) {
            this.dataFetched = false;
            this.tableReady = false;

            this.setLoader(true);
            this.onServerFiltersApplied.emit(remoteFilters);

            const interval = setInterval(() => {
                if (this.data) {
                    this.previousRemoteFilters = remoteFilters;

                    this.setupWorkspace();
                    clearInterval(interval);
                }
            }, 500);
        } else {
            this.calculateData();
        }
    }

    reloadData() {
        this.cerrixTab.showLoader = true;
        this.cerrixTab.refresh();
    }

    resetWorkspace() {
        this._promptService
            .confirm(
                "Reset workspace",
                `Unsaved configuration changes will be lost. Continue reset?`
            )
            .onConfirm()
            .subscribe(() => {
                this.activeHeaders = JSON.parse(JSON.stringify(this.availableHeaders));
                this.activeFilters = [];
                this.activePreset = null;
                this.activePresetConfig = null;
                this.paginationAmount = 25;
                this.paginationIndex = 0;
                this.sortCol = this.activeHeaders[0];
                this.sortAsc = false;

                this.quickFilter = "";
                this.cerrixTab.menu.activeMenuItem = null;
                this.setPresetCache(null, null);

                this.calculateData();
            });
    }

    private getPresetCache(): WorkspacePresetCache {
        const cache = this.cerrixTab.config.presetCache as WorkspacePresetCache;

        return cache;
    }

    // Store the activeFilters and presets in a cache object in the tab.config object
    private setPresetCache(filtersActive: SearchKeyword[], presetActive: Preset) {
        if (filtersActive == null && presetActive == null) {
            this.cerrixTab.config.presetCache = null;
            return;
        }

        this.cerrixTab.config.presetCache = <WorkspacePresetCache>{
            activeFilters: filtersActive,
            activePreset: presetActive,
        };
    }
}
