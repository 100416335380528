<div
    class="preloader"
    [class.fadeout]="showLoader == false && (!cerrixTab || cerrixTab.showLoader == false)"
>
    <div class="fancy-loader"></div>
    <div class="subtitle" *ngIf="cerrixTab">
        {{ cerrixTab.loadingMessage ? cerrixTab.loadingMessage : "Loading " + cerrixTab.name }}
    </div>
    <div class="subtitle" *ngIf="customMessage">{{ customMessage }}</div>
</div>
