import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { Configuration } from "@app/app.constants";
import { DocumentSharedModel } from "@app/audit/shared/data-models/document-shared-model";
import { ContextMenuItem, MenuItemBuilder } from "@app/shared/contextmenu/menu-item";
import { FileType } from "@enums/FileType";
import { guid } from "@methods/uniqueMethods";
import { FroalaOptions } from "@models/documents/froala/FroalaOptions";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { firstValueFrom } from "rxjs";

declare var $: any;

@Component({
    selector: "cerrix-editor",
    templateUrl: "./cerrix-editor.component.html",
    styleUrls: ["./cerrix-editor.component.scss"],
})
export class CerrixEditorComponent implements AfterViewInit {
    @Input() tags: string[];

    /** This overrules other allow.. options. */
    @Input() allowHtml = true;
    @Input() allowPdfEmbed = true;
    @Input() allowImageUpload = true;

    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();

    @ViewChild("pdfModal") pdfModal: ElementRef;

    editorID: string;

    froalaEditor: any;
    froalaOptions = new FroalaOptions();
    froalaEditingValue: string;

    pdfs: DocumentSharedModel[];
    selectedPdf: DocumentSharedModel;

    pageReady = false;

    public contextMenu: ContextMenuItem[];

    constructor(
        private _docDS: DocumentDataService,
        private _config: Configuration,
        private _menuItemBuilder: MenuItemBuilder
    ) {
        this.editorID = "cerrix-editor-" + guid();
        window.addEventListener("message", this.actionClicked.bind(this), false);
    }

    ngAfterViewInit() {
        this.bindSettings();
        this.setupToolbar();
        this.buildContextMenu();

        setTimeout(() => {
            this.froalaEditingValue = this.value;
            this.pageReady = true;
        }, 0);
    }

    actionClicked: any = (event: any) => {
        const action = event.data as string;
        if (!action.startsWith(this.editorID)) {
            return;
        }

        if (action.endsWith("PDFCLICKED")) {
            this.showPdfModal();
        }
    };

    bindSettings(): any {
        this.froalaOptions.key = this._config.FroalaLicence;

        this.froalaOptions.iconsTemplate = "font_awesome_5";
        this.froalaOptions.useClasses = false;
        this.froalaOptions.spellcheck = true;

        this.froalaOptions.charCounterCount = true;
        this.froalaOptions.videoUpload = false;
        this.froalaOptions.fileUpload = false;

        this.froalaOptions.imageManagerLoadURL = this.allowImageUpload
            ? this._docDS.GetImagesUrl()
            : null;

        this.froalaOptions.imageUpload = this.allowImageUpload;
        this.froalaOptions.imageUploadURL = this.allowImageUpload
            ? this._docDS.GetSharedFroalaUrl()
            : null;

        this.froalaOptions.imageInsertButtons = ["imageUpload", "imageManager"];

        this.froalaOptions.videoInsertButtons = [];
        this.froalaOptions.videoAllowedProviders = [];

        this.froalaOptions.linkList = [
            {
                text: "Cerrix",
                href: window.location.origin,
                target: "_blank",
            },
        ];
        this.froalaOptions.attribution = false;

        this.froalaOptions.quickInsertEnabled = false;

        const that = this;
        this.froalaOptions.events = {
            initialized: function () {
                that.froalaEditor = this;
            },
            contentChanged: () => {
                this.valueChange.emit(this.getHtml());
            },
        };
    }

    setupToolbar() {
        const toolbarButtons = {};

        if (this.allowHtml) {
            toolbarButtons["moreText"] = {
                buttons: [
                    "fontSize",
                    "fontFamily",
                    "bold",
                    "italic",
                    "underline",
                    "textColor",
                    "backgroundColor",
                    "clearFormatting",
                    "strikeThrough",
                    "subscript",
                    "superscript",
                ],
                align: "left",
                buttonsVisible: 5,
            };

            toolbarButtons["moreParagraph"] = {
                buttons: [
                    "formatOL",
                    "formatUL",
                    "outdent",
                    "indent",
                    "alignLeft",
                    "alignCenter",
                    "alignRight",
                    "alignJustify",
                    "quote",
                    "paragraphFormat",
                    "lineHeight",
                ],
                align: "left",
                buttonsVisible: 2,
            };

            const insertButtons = ["insertImage"];
            if (this.allowPdfEmbed) {
                insertButtons.push("insertPdf");
            }

            toolbarButtons["moreRich"] = {
                buttons: insertButtons.concat(["insertLink", "insertTable", "insertHR"]),
                align: "left",
                buttonsVisible: 3,
            };
        }

        toolbarButtons["moreMisc"] = {
            buttons: ["undo", "redo", "selectAll", "help"],
            align: "right",
            buttonsVisible: this.allowHtml ? 2 : 5,
        };

        this.froalaOptions.toolbarButtons = toolbarButtons;
    }

    insertTag(tag: string) {
        this.insertText(`[${tag}]`);
    }

    insertText(text: string) {
        this.froalaEditor.selection.save();
        this.froalaEditor.html.insert(text);
        this.froalaEditor.selection.restore();

        this.froalaEditor.selection.save();
        this.froalaEditor.undo.saveStep();
        this.froalaEditor.selection.restore();

        this.valueChange.emit(this.getHtml());
    }

    insertPdf() {
        const tag = `<a data-pdfid="${this.selectedPdf.guid}">${this.selectedPdf.name}<a>`;

        this.insertText(tag);

        this.selectedPdf = null;
        $(this.pdfModal.nativeElement).modal("hide");
    }

    getHtml() {
        this.froalaEditor.selection.save();
        const value = this.froalaEditor.$el[0].innerHTML;
        this.froalaEditor.selection.restore();

        return value;
    }

    private showPdfModal() {
        $(this.pdfModal.nativeElement).modal("show");

        if (!this.pdfs) {
            // Let the UI not wait for the the refreshMethod.
            setTimeout(() => {
                this.refreshPdfs();
            }, 0);
        }
    }

    private async refreshPdfs() {
        this.pdfs = await firstValueFrom(this._docDS.GetSharedDocuments(FileType.Pdf));
    }

    private buildContextMenu() {
        if (!this.tags) {
            this.contextMenu = null;
            return;
        }

        this.tags.forEach((tag) => {
            this._menuItemBuilder.appendItem(tag, () => this.insertTag(tag));
        });

        const tagMenu = this._menuItemBuilder.build();
        this.contextMenu = this._menuItemBuilder.appendSubMenu("Insert tag", tagMenu).build();
    }
}
