import { finalize } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditUniverseDataService } from "@app/audit-universe/services/audit-universe-data.service";
import { AuditUniverseModel } from "@app/audit-universe/shared/data-models/audit-universe-model";
import { AuditUniverseDetailPermissionModel } from "@app/audit-universe/shared/page-models/audit-universe-detail-permission-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { Pages } from "@constants/pages/Pages";
import { toPromise } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { TabMenuItem } from "./../../../../common/models/generic/TabModels/TabMenuItem";
import { Configuration } from "./../../../app.constants";
import { CerrixPromptService } from "./../../../shared/services/cerrix-prompt.service";
import { AuditUniverseDetailStandingDataModel } from "./../../shared/page-models/audit-universe-detail-standing-data-model";
import { AuditUniverseDataComponent } from "./components/audit-universe-data/audit-universe-data.component";
import { DocumentModel } from "@models/documents/documentmodel";
import { isGuid } from "@methods/uniqueMethods";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { ActivatedRoute } from "@angular/router";
import { TargetModule } from "@enums/document/TargetModule";
import { AuditUniverseLinkedAuditsComponent } from "./components/audit-universe-linked-audits/audit-universe-linked-audits.component";
import { AuditUniverseLinkedFormsComponent } from "./components/audit-universe-linked-forms/audit-universe-linked-forms.component";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";

@Component({
    selector: "audit-universe-detail",
    templateUrl: "./audit-universe-detail.component.html",
    styleUrls: ["./audit-universe-detail.component.scss"],
})
export class AuditUniverseDetailComponent implements OnInit {
    tab: TabModel;
    id: string;

    historicalView = false;
    readonly = false;

    permissions: AuditUniverseDetailPermissionModel;
    standingData: AuditUniverseDetailStandingDataModel;
    model: AuditUniverseModel;
    formGroup: FormGroup;

    @ViewChild("auditsComponent")
    auditsComponent: AuditUniverseLinkedAuditsComponent;
    protected menuItemAudits: string = "menuItemAudits";

    @ViewChild("formsComponent")
    formsComponent: AuditUniverseLinkedFormsComponent;
    protected menuItemForms: string = "menuItemForms";

    @ViewChild("historyOverviewComponent") historyOverviewComponent: HistoryOverviewComponent;
    protected menuItemHistory: string = "menuItemHistory";

    public static readonly menuItemData = "audit-universe-data";
    get menuItemData() {
        // We cant use the static variable in html so we create a get variable here to pass through the value.
        return AuditUniverseDetailComponent.menuItemData;
    }

    public static readonly menuItemDocuments = "audit-universe-documents";
    get menuItemDocuments() {
        // We cant use the static variable in html so we create a get variable here to pass through the value.
        return AuditUniverseDetailComponent.menuItemDocuments;
    }

    get _loadedDateFilter() {
        return this.tab.config && this.tab.config.datefilter
            ? moment.utc(this.tab.config.datefilter, "YYYYMMDD").toDate()
            : moment.utc().toDate();
    }
    set _loadedDateFilter(date: Date) {
        if (!this.tab.config) {
            this.tab.config = {};
        }

        const isToday = moment.utc().isSame(date, "day");
        if (isToday) {
            delete this.tab.config.datefilter;
        } else {
            this.tab.config.datefilter = moment.utc(date).format("YYYYMMDD");
        }
    }
    dateFilter: Date;
    dateFilterMax: Date;

    documents: DocumentModel[];
    isDocumentDirty = false;
    targetModule = TargetModule.AuditUniverse;

    @ViewChild(AuditUniverseDataComponent) moduleDataComp: AuditUniverseDataComponent;

    constructor(
        private route: ActivatedRoute,
        public _ds: AuditUniverseDataService,
        private _promptService: CerrixPromptService,
        private _configuration: Configuration,
        private _pages: Pages,
        private _toastr: ToastrService
    ) {
        this.dateFilterMax = moment.utc().toDate();
    }

    async ngOnInit() {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;
        this.permissions = await toPromise(this._ds.getPermissions(this.id));
        if (!this.permissions.canView) {
            this._toastr.error("No Access!");
            this.tab.close(false);
            return;
        }

        this.model = this.id
            ? await toPromise(
                  this._ds.getObject(
                      this.id,
                      this.permissions.canChangeDate ? this._loadedDateFilter : null
                  )
              )
            : new AuditUniverseModel();

        this.standingData = await toPromise(
            this._ds.getStandingData(
                this.id,
                this.permissions.canChangeDate ? this._loadedDateFilter : null
            )
        );

        this.dateFilterMax = moment.utc().toDate();
        this.createFormGroup();

        this.tab.name =
            "(UO) " +
            (this.model.name
                ? +this.model.identifier + " - " + this.model.name
                : "New universe object");
        this.tab.showLoader = false;
    }

    async save() {
        const canSave = this.id ? this.permissions.canEdit : this.permissions.canCreate;
        if (!canSave) {
            this._toastr.warning("Not enough rights to save audit!", "Unauthorized");
            return;
        }

        if (this.formGroup.valid) {
            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            this._ds
                .storeObject(this.model)
                .pipe(finalize(() => savingPrompt.close()))
                .subscribe({
                    next: (value: HttpResponse<any>) => {
                        if (!value.ok || value.status != 200) {
                            return;
                        }

                        savingPrompt.close();
                        this._toastr.success("", "Save completed");
                        this.tab.id = value.body.toString();
                        if (
                            this.tab.parent &&
                            this.tab.parent.lookupname === this._pages.AuditUniverseOverview
                        ) {
                            this.tab.parent.refresh();
                        }
                        this.tab.refresh();
                    },
                });
        } else {
            FormValidationHelper.markAllAsTouched(this.formGroup);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.formGroup);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }

    delete() {
        this._ds.deleteObject(this.model.guid).subscribe({
            next: (value) => {
                if (value && value.length > 0) {
                    this._toastr.warning(value.toString());
                } else {
                    this._toastr.success("Audit Universe deleted");
                    if (
                        this.tab.parent &&
                        this.tab.parent.lookupname === this._pages.AuditUniverseOverview
                    ) {
                        this.tab.parent.refresh();
                    }
                    this.tab.close(false);
                }
            },
            error: (error) => {
                this._toastr.error("", error || error.Message);
            },
        });
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem) {
            return;
        }

        switch (menuItem.menuItemId) {
            case this.menuItemData:
                if (this.moduleDataComp) {
                    this.moduleDataComp.load();
                }
                break;
            case this.menuItemAudits:
                this.auditsComponent.load();
                break;
            case this.menuItemForms:
                this.formsComponent.load();
                break;
            case this.menuItemDocuments:
                this.loadDocuments();
                break;
            case this.menuItemHistory:
                this.historyOverviewComponent.loadHistory();
                break;
        }
    }

    private createFormGroup() {
        this.formGroup = new FormGroup({
            name: new FormControl(this.model.name, [
                Validators.required,
                FormValidationHelper.validateMaxLength(
                    "Name",
                    this._configuration.MaxTextFieldLength
                ),
            ]),
            description: new FormControl(
                this.model.description,
                FormValidationHelper.validateMaxLength(
                    "Description",
                    this._configuration.MaxTextAreaLength
                )
            ),

            impact: new FormControl(this.model.impact),
            impactComment: new FormControl(
                this.model.impactComment,
                FormValidationHelper.validateMaxLength(
                    "Impact comment",
                    this._configuration.MaxTextAreaLength
                )
            ),

            organizations: new FormControl(this.model.organizations),
            businessDimensions: new FormControl(this.model.businessDimensions),
            frameworkDimensions: new FormControl(this.model.frameworkDimensions),
        });

        const hasRights = this.id ? this.permissions.canEdit : this.permissions.canCreate;
        this.historicalView =
            this._loadedDateFilter && !moment.utc().isSame(this._loadedDateFilter, "day");
        this.readonly = !hasRights || this.historicalView;

        if (this.readonly) {
            this.formGroup.disable();
        } else {
            this.formGroup.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.formGroup, this.model);
            });
        }

        FormValidationHelper.mapToModel(this.formGroup, this.model);
    }

    dateChangeHandler(newDate: Date) {
        this.dateFilter = newDate;
        this.dateChanged();
    }

    async dateChanged() {
        // Date has not changed.
        if (moment.utc(this.dateFilter).isSame(this._loadedDateFilter, "day")) {
            return;
        }

        const isToday = moment.utc().isSame(this.dateFilter, "day");
        if (!isToday) {
            const result = await this._promptService
                .confirmCustom({
                    maxWidth: "375px",
                    data: {
                        title: "Load historical data?",
                        message:
                            "Loading historical data will put the page in a read only mode and unsaved changes will be lost. Do you want to continue?",
                    },
                })
                .toPromise();

            if (!result) {
                this.dateFilter = this._loadedDateFilter;
                return;
            }
        }

        this._loadedDateFilter = this.dateFilter;

        this.tab.showLoader = true;
        this.tab.refresh();
    }

    loadDocuments(): void {
        if (!this.documents) {
            this.documents = [];
        }

        if (this.id && isGuid(this.id.toString())) {
            this._ds.getDocuments(this.id.toString()).subscribe((documents) => {
                this.documents = documents;
                this.model.Documents = documents;
            });
        }
    }

    checkDocumentsDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.menuItemDocuments, true);
        this.isDocumentDirty = true;
    }
}
