import { Component, Input, TemplateRef, OnInit, ViewChild } from "@angular/core";
import { CerrixWizardConfig } from "../models/WizardModels/CerrixWizardConfig";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "cerrix-wizard",
    templateUrl: "./cerrix-wizard.component.html",
    styleUrls: ["./cerrix-wizard.component.scss"],
})
export class CerrixWizardComponent implements OnInit {
    @ViewChild("wizardModal") wizardModal: ModalDirective;
    @ViewChild("completeModal") completeModal: ModalDirective;

    @Input() templates: TemplateRef<any>[];
    @Input() summaryTemplate?: TemplateRef<any>;
    @Input() config: CerrixWizardConfig;

    modalConfig: any = {};

    summaryEnabled = false;
    summaryIndex = 0;

    completeCallRunning = false;

    ngOnInit() {
        if (this.templates.length !== this.config.steps.length) {
            throw new Error("Steps and template lengths do not match!");
        }

        this.summaryEnabled = this.config.enableSummary;
        this.summaryIndex = this.templates.length;

        this.modalConfig.ignoreBackdropClick = this.config.useDedicatedClose;
    }

    changePageTo(pageIndex: number) {
        const maxIndex = this.config.steps.length - (this.summaryEnabled ? 0 : 1);

        if (pageIndex > maxIndex || pageIndex === this.config.activePageIndex) {
            return;
        }

        if (this.config.changeToPage(this.config.activePageIndex, pageIndex)) {
            this.config.activePageIndex = pageIndex;
        }
    }

    async complete() {
        if (this.config.completable && !this.completeCallRunning) {
            this.completeCallRunning = true;

            try {
                if (await this.config.complete()) {
                    this.closeWizard();
                    this.openCompleteModal();
                }
            } finally {
                this.completeCallRunning = false;
            }
        }
    }

    openWizard() {
        if (this.config.showInModal) {
            this.config.reload();
            this.wizardModal.show();
        }
    }

    closeWizard() {
        if (this.config.showInModal) {
            this.wizardModal.hide();
        }
    }

    openCompleteModal() {
        if (this.config.showModalOnComplete) {
            this.completeModal.show();
        }
    }

    closeCompleteModal() {
        if (this.config.showModalOnComplete) {
            this.completeModal.hide();
        }
    }
}
