<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    [module]="'risk'"
    [moduleType]="moduleType"
    [idProp]="'guid'"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [NumberProperties]="numberProps"
    [DateProperties]="dateProps"
    (onDblClick)="openDetail($event)"
    (onAddClick)="add()"
    (onServerFiltersApplied)="getData($event)"
>
</cerrix-workspace>
