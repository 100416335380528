import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgControl } from "@angular/forms";
import { toPromise } from "@methods/CommonMethods";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { CerrixTextInputBase } from "../components/cerrix-textinput/cerrix-textinput-base";
import { Configuration } from "@app/app.constants";
import { AiSuggestionsPopupComponent } from "../ai-suggestions-popup/ai-suggestions-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "ai-description-generator",
    templateUrl: "./ai-description-generator.component.html",
    styleUrls: ["./ai-description-generator.component.scss"],
})
export class AiDescriptionGeneratorComponent extends CerrixTextInputBase implements OnInit {
    @Input() fetchDescriptionsDatamethod: () => Promise<string[]>;
    @Input() rows = 3;
    @Input() autoGrowEnabled = false;

    @Output() onDescriptionGenerated = new EventEmitter<string>();

    generateDescriptionButtonDisabled: boolean = false;
    generateDescriptionButtonIcon: string;

    aiAssistanceEnabled: boolean = false;

    public constructor(
        control: NgControl,
        _configuration: Configuration,
        private _settingsService: SettingsDataService,
        private _toastr: ToastrService,
        private _dialog: MatDialog
    ) {
        super(control, _configuration);
        this.control.valueAccessor = this;

        this.multiline = true;
    }

    async ngOnInit(): Promise<void> {
        super.init();

        const setting = await toPromise(
            this._settingsService.getSetting(ApplicationSettings.EnableAiAssistance)
        );

        this.aiAssistanceEnabled = setting.BoolValue;

        if (this.aiAssistanceEnabled) {
            this.enableDescriptionGeneratorButton();
        }
    }

    async generateDescription(): Promise<void> {
        if (!this.value || this.value.length === 0) {
            this._toastr.warning("Please enter a description before generating suggestions.");
            return;
        }

        try {
            this.disableDescriptionGeneratorButton();

            var suggestions = await this.fetchDescriptionsDatamethod();

            this._dialog.open(AiSuggestionsPopupComponent, {
                data: {
                    suggestions: suggestions,
                    onConfirm: ((suggestion: string) => {
                        this.onDescriptionGenerated.emit(suggestion);
                    }).bind(this),
                },
                panelClass: "ai-suggestions-popup",
                width: "700px",
            });
        } finally {
            this.enableDescriptionGeneratorButton();
        }
    }

    private enableDescriptionGeneratorButton(): void {
        this.generateDescriptionButtonDisabled = false;
        this.generateDescriptionButtonIcon = "fas fa-wand-magic-sparkles";
    }

    private disableDescriptionGeneratorButton(): void {
        this.generateDescriptionButtonDisabled = true;
        this.generateDescriptionButtonIcon = "fas fa-hourglass-start";
    }
}
