@import "variables";

.bolded {
    font-weight: bold;
}

.workspace-adv-config-tab-group.mat-mdc-tab-group {
    height: 100%;
    overflow: hidden;

    .mat-mdc-tab-body-wrapper {
        height: 100%;
        width: 100%;
    }

    .container-fluid {
        height: 100%;
        width: 100%;

        font-size: 0.8em;
        padding: 15px;
    }

    label {
        font-weight: bold;
    }

    .config-filter {
        .config-filter-left {
            .input-group-prepend > span {
                width: 135px;
            }
        }

        .config-filter-right {
            border-left: 1px solid lightgray;

            .no-filter-message {
                height: 100%;
                text-align: center;

                & > span {
                    font-style: italic;
                }
            }
        }
    }

    .row,
    .row .col,
    .row .col ejs-listbox {
        height: 100%;
    }

    .e-listbox-wrapper {
        max-height: 100%;
        overflow: auto;
    }
}
