import { Component, Input } from "@angular/core";
import { StepStateEnum, WorkflowStepConfig } from "../models/workflow-step-config";

@Component({
    selector: "eff-workflow-popover",
    templateUrl: "./eff-workflow-popover.component.html",
    styleUrls: ["./eff-workflow-popover.component.scss"],
})
export class EffWorkflowPopoverComponent {
    @Input() step: WorkflowStepConfig;
    stepStateEnum = StepStateEnum;
}
