import { FlatTreeControl } from "@angular/cdk/tree";
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from "@angular/material/tree";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { IncidentPermissionType } from "@enums/permissions/incident-permission-type.enum";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";

interface LeftMenuNode {
    id: string;
    icon?: string;
    name: string;
    children?: LeftMenuNode[];
}

interface LeftMenuFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    item?: LeftMenuNode;
}

@Component({
    selector: "incident-standing-data-menu",
    templateUrl: "./incident-standing-data-menu.component.html",
    styleUrls: ["./incident-standing-data-menu.component.scss"],
    standalone: true,
    imports: [CommonModule, MatTreeModule, MatIconModule, MatButtonModule],
    providers: [],
})
export class IncidentStandingDataMenuComponent implements OnInit {
    @Input() public standingDataType: string;
    @Output() public standingDataTypeChange = new EventEmitter<{ id: string; name: string }>();

    private readonly permissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );

    protected treeControl = new FlatTreeControl<LeftMenuFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    protected treeFlattener = new MatTreeFlattener(
        (node: LeftMenuNode, level: number) => {
            return {
                expandable: !!node.children && node.children.length > 0,
                name: node.name,
                level: level,
                icon: node.icon,
                item: !!node.children ? undefined : (node as LeftMenuNode),
            };
        },
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    protected leftMenuDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    protected hasChild = (_: number, node: LeftMenuFlatNode) => node.expandable;

    ngOnInit() {
        this.leftMenuDataSource.data = [
            {
                id: "StandingData",
                name: "Standing data",
                children: [
                    ...(this.permissionService.hasPermission(
                        MicroserviceModule.Incidents,
                        IncidentPermissionType.ClassificationStandingData
                    )
                        ? [{ id: "Classification", name: "Classification" }]
                        : []),
                    ...(this.permissionService.hasPermission(
                        MicroserviceModule.Incidents,
                        IncidentPermissionType.IncidentTypeStandingData
                    )
                        ? [{ id: "IncidentTypes", name: "Incident types" }]
                        : []),
                ],
            },
        ];
    }

    protected leftMenuAction(node: LeftMenuFlatNode): void {
        this.standingDataTypeChange.emit({ id: node.item.id, name: node.item.name });
    }
}
