import { DataSubjectModel } from "@app/datamanagement/models/DataSubjectModel";
import { ModalOptions, BsModalService } from "ngx-bootstrap/modal";
import { ArchivePolicyDialogComponent } from "@app/datamanagement/pages/datastructures/dialogs/archivepolicy-dialog/archivepolicy-dialog.component";
import { MdsModel } from "@app/datamanagement/models/MdsModel";
import { of } from "rxjs";
import { EntityModel } from "@app/datamanagement/models/EntityModel";
import { SystemModel } from "@app/datamanagement/models/SystemModel";
import { EntityRoles } from "@app/datamanagement/enums/EntityRoles";

export class DataStructuresHelper {
    static viewArchivePolicyDialog(
        dataSubject: DataSubjectModel,
        modalService: BsModalService,
        disabled: boolean
    ) {
        if (dataSubject.dataStructures.length === 0) {
            return of(false);
        }

        const config = <ModalOptions<ArchivePolicyDialogComponent>>{
            backdrop: "static",
            class: "datamanagement-dialog modal-lg",

            initialState: <ArchivePolicyDialogComponent>{
                dataStructures: dataSubject.dataStructures,
                disabled: disabled,
            },
        };

        return modalService.show(ArchivePolicyDialogComponent, config).content.afterClosed;
    }

    static processAndValidateManageDataStructures(overviewModel: MdsModel): string[] {
        const validations: string[] = [];

        overviewModel.entities.forEach((entity) => {
            const entityValidations: string[] = [];

            if (!entity.role || <number>entity.role === 0) {
                entityValidations.push(`Role is required.`);
            } else {
                if (entity.systemsRequired == null) {
                    entityValidations.push(`Select if systems are required.`);
                } else if (entity.systemsRequired && entity.systems.length === 0) {
                    entityValidations.push(`This entity is missing systems.`);
                }
            }

            this.processAndValidateSystems(entity, entityValidations);

            if (entityValidations.length > 0) {
                entityValidations.unshift(`<b> ${entity.name} </b>`);

                entityValidations.push(`<hr>`);

                validations.addRange(entityValidations);
            }
        });

        return validations;
    }

    private static processAndValidateSystems(entity: EntityModel, entityValidations: string[]) {
        entity.systems.forEach((system) => {
            if (!system.connectedId || system.connectedId === 0) {
                if (system.dataSubjects.length === 0) {
                    if (!system.hidden) {
                        entityValidations.push(
                            `System: <b>${system.name}</b>, requires at least one data subject.`
                        );
                    } else {
                        entityValidations.push(`This entity requires at least one data subject.`);
                    }
                }
            }

            this.processAndValidateDataSubjects(system, entityValidations);
        });
    }

    private static processAndValidateDataSubjects(
        system: SystemModel,
        entityValidations: string[]
    ) {
        // Only validate systems when they have been fetched from the server or when new systems were added
        const validateSystems =
            system.dataSubjectsLoaded ||
            system.dataSubjects.some((ds) => !ds.connectedId || ds.connectedId === 0);

        if (validateSystems) {
            system.dataSubjects.forEach((dataSubject) => {
                if (dataSubject.dataStructures.length === 0) {
                    let datastructureValidation = `Data subject: <b>${dataSubject.name}</b>`;

                    if (!system.hidden) {
                        datastructureValidation += ` (${system.name})`;
                    }

                    datastructureValidation += ", requires at least one data structure.";

                    entityValidations.push(datastructureValidation);
                } else {
                    this.processAndValidateDataStructures(system, dataSubject, entityValidations);
                }
            });
        } else {
            system.dataSubjects = null;
        }
    }

    private static processAndValidateDataStructures(
        system: SystemModel,
        dataSubject: DataSubjectModel,
        entityValidations: string[]
    ) {
        // Only validate datastructures when they have been fetched from the server or when new structures were added
        const validateDataStructures =
            dataSubject.dataStructuresLoaded ||
            dataSubject.dataStructures.some((ds) => !ds.connectedId || ds.connectedId === 0);

        if (validateDataStructures) {
            dataSubject.dataStructures.forEach((dataStructure) => {
                // Existing datastructures are only loaded when opening the dialog, therefore we have to check on the loaded property
                if (!dataStructure.archivePolicy || dataStructure.archivePolicy.length === 0) {
                    entityValidations.push(
                        `Data structure: <b>${dataStructure.name}</b> (${system.name} -> ${dataSubject.name}), requires archive policy.`
                    );
                }
            });
        } else {
            dataSubject.dataStructures = null;
        }
    }
}
