import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { AboutModel } from "../models/AboutModel";

@Injectable({
    providedIn: "root",
})
export class AboutService extends ApiDataService {
    public constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "about");
    }

    public getAbout(): Observable<AboutModel> {
        return this.get<AboutModel>();
    }
}