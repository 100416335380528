import {
    FormGroup,
    FormControl,
    ValidationErrors,
    ValidatorFn,
    FormArray,
    AbstractControl,
} from "@angular/forms";
import moment from "moment";
import { GenericManagerHelper } from "./generic-manager-helper";

export class FormValidationHelper {
    static markAllAsTouched(formGroup: FormGroup) {
        formGroup.markAsTouched({ onlySelf: true });
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                control.markAsTouched({ onlySelf: true });
                this.markAllAsTouched(control);
            } else if (control instanceof FormArray) {
                this.markFormArrayAsTouched(control);
            }
        });
    }

    private static markFormArrayAsTouched(formArray: FormArray) {
        formArray.markAsTouched({ onlySelf: true });
        for (const control of formArray.controls) {
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.markAllAsTouched(control);
            } else if (control instanceof FormArray) {
                this.markFormArrayAsTouched(control);
            }
        }
    }

    static markAllAsUntouched(formGroup: FormGroup) {
        formGroup.markAsUntouched({ onlySelf: true });
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsUntouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                control.markAsUntouched({ onlySelf: true });
                this.markAllAsUntouched(control);
            } else if (control instanceof FormArray) {
                this.markFormArrayAsUntouched(control);
            }
        });
    }

    private static markFormArrayAsUntouched(formArray: FormArray) {
        formArray.markAsUntouched({ onlySelf: true });
        for (const control of formArray.controls) {
            if (control instanceof FormControl) {
                control.markAsUntouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.markAllAsUntouched(control);
            } else if (control instanceof FormArray) {
                this.markFormArrayAsUntouched(control);
            }
        }
    }

    static allFormControlsTouched(formGroup: FormGroup): boolean {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                if (!control.touched) {
                    return false;
                }
            }
        });

        return true;
    }

    static mapToModel(formGroup: FormGroup, model: Object) {
        const data = formGroup.value;
        for (const fieldName in data) {
            // Only copy properties
            if (data.hasOwnProperty(fieldName)) {
                model[fieldName] = data[fieldName];
            }
        }
    }

    static updateFormGroup(model: Object, formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.setValue(model[field]);
            }
        });
    }

    static getGeneralErrorMessage(validationErrors: ValidationErrors): string {
        const validationMessages = this.getValidationMessages(validationErrors, []);
        const validationMessage = validationMessages.distinct().join();

        return validationMessage;
    }

    static getValidationMessages(validationErrors: ValidationErrors, validationMessages: string[]) {
        Object.keys(validationErrors).forEach((errorKey) => {
            const error = validationErrors[errorKey];
            if (typeof error === "object") {
                validationMessages = this.getValidationMessages(error, validationMessages);
            } else if (error.toString() !== "true") {
                validationMessages.push(`<br> - ${error}`);
            } else if (errorKey === "required") {
                validationMessages.push("<br> - Please fill in all required fields.");
            }
        });

        return validationMessages;
    }

    /**
     * Validates if a date is before than the max date, marks control as invalid
     * @usageNotes Use on FormGroup
     */
    static validateStartEndDate<T>(
        startDateKey: keyof T,
        endDateKey: keyof T,
        errorValue: string,
        includeTime: boolean
    ): ValidatorFn {
        return FormValidationHelper.validateDates(
            startDateKey,
            endDateKey,
            errorValue,
            includeTime,
            false
        );
    }

    static validateEndStartDate<T>(
        startDateKey: keyof T,
        endDateKey: keyof T,
        errorValue: string,
        includeTime: boolean
    ): ValidatorFn {
        return FormValidationHelper.validateDates(
            startDateKey,
            endDateKey,
            errorValue,
            includeTime,
            true
        );
    }

    /**
     * Validates if a date is before than the max date, marks control as invalid
     * @usageNotes Use on FormGroup
     */
    private static validateDates<T>(
        startDateKey: keyof T,
        endDateKey: keyof T,
        errorValue: string,
        includeTime: boolean,
        errorOnEnddate: boolean
    ): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const startDateProp = startDateKey.toString();
            const startDateControl = group.controls[startDateProp];
            let startDate = startDateControl.value;

            const endDateProp = endDateKey.toString();
            const endDateControl = group.controls[endDateProp];
            let endDate = group.controls[endDateProp].value;

            const controlWithValidation = errorOnEnddate ? endDateControl : startDateControl;
            const errorKey = `${startDateProp}After${endDateProp}`;

            if (endDate && startDate) {
                if (!includeTime) {
                    startDate = new Date(startDate).setHours(0, 0, 0);
                    endDate = new Date(endDate).setHours(0, 0, 0);
                }

                if (startDate > endDate) {
                    this.addFormControlError(controlWithValidation, errorKey, errorValue);
                } else {
                    this.removeFormControlError(controlWithValidation, errorKey);
                }
            } else {
                this.removeFormControlError(controlWithValidation, errorKey);
            }

            return {};
        };
    }

    /**
     * Validates if a date is before now, marks control as invalid
     * @usageNotes Use on FormControl
     */
    static validateExpirationDate<T>(mindate: Date, maxDate: Date): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};
            const expirationDate = control.value;

            if (expirationDate < mindate) {
                validationErrors["expirationDate"] = "Expiration date cannot be in the past";
                control.setErrors(validationErrors);
            } else if (expirationDate > maxDate) {
                validationErrors["expirationDate"] =
                    "Expiration date validaty has a maximum of one year";
                control.setErrors(validationErrors);
            } else {
                control.setErrors(null);
            }
            return validationErrors;
        };
    }

    /**
     * Validates if a date is before now, marks control as invalid
     * @usageNotes Use on FormControl
     */
    static validateDateBeforeNow<T>(errorValue: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};
            const group = <FormGroup>control.parent;
            if (group == null) {
                return validationErrors;
            }

            const startDate = control.value;
            const futureDate = moment(new Date()).add(1, "days").startOf("day");

            if (startDate && moment(startDate) >= futureDate) {
                validationErrors["AfterToday"] = errorValue;
            }

            return validationErrors;
        };
    }

    /**
     * Validates if the length of the text entered is too long, according the specified maxLength
     * @usageNotes Use on FormControl
     */
    static validateMaxLength(display: string, maxLength: number): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};

            const group = <FormGroup>control.parent;
            if (group == null) {
                return validationErrors;
            }

            const controlValue = control.value;
            if (controlValue && controlValue.length > maxLength) {
                validationErrors[display] = `${display} can not exceed ${maxLength} characters`;
            }

            return validationErrors;
        };
    }

    /**
     * Validates if the length of the text entered is a fixed length
     * @usageNotes Use on FormControl
     */
    static validateFixedLength(display: string, fixedLength: number): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};

            const group = <FormGroup>control.parent;
            if (group == null) {
                return validationErrors;
            }

            const controlValue = control.value;
            if (controlValue && controlValue.length !== fixedLength) {
                validationErrors[display] = `${display} must be ${fixedLength} characters`;
            }

            return validationErrors;
        };
    }

    static validateEmailControl(display: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};
            const group = control.parent;
            if (group == null) {
                return validationErrors;
            }

            const value = control.value;
            if (value && value.length > 0) {
                if (!GenericManagerHelper.validateEmail(value)) {
                    validationErrors[display] = `${display} is not a valid email address`;
                }
            }

            return validationErrors;
        };
    }

    static validatePhoneControl(display: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};
            const group = control.parent;
            if (group == null) {
                return validationErrors;
            }

            const value = control.value;
            if (value && value.length > 0) {
                if (!GenericManagerHelper.validatePhone(value)) {
                    validationErrors[display] = `${display} is not a valid phone number`;
                }
            }
            return validationErrors;
        };
    }

    static validateWebsiteControl(display: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};
            const group = control.parent;
            if (group == null) {
                return validationErrors;
            }

            const value = control.value;

            if (value && value.length > 0) {
                if (!GenericManagerHelper.validateWebsite(value)) {
                    validationErrors[display] = `${display} is not a valid website`;
                }
            }

            return validationErrors;
        };
    }

    /**
     * Validates if a number is lower than the max value, marks control as invalid
     * @usageNotes Use on FormGroup
     */
    static validateMinMax<T>(minKey: keyof T, maxKey: keyof T, errorValue: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const minKeyProp = minKey.toString();
            const minControl = group.controls[minKeyProp];
            const minValue = minControl.value;

            const maxKeyProp = maxKey.toString();
            const maxValue = group.controls[maxKeyProp].value;

            if (minValue && maxValue && minValue > maxValue) {
                const validationErrors = {};
                const errorKey = `${minKeyProp}HigherThan${maxKeyProp}`;
                validationErrors[errorKey] = errorValue;

                // Set control as invalid
                minControl.setErrors(validationErrors);
            } else {
                minControl.setErrors(null);
            }

            return {};
        };
    }

    /**
     * Validates if a number is lower than the max value, marks control as invalid
     * @usageNotes Use on FormControl
     */
    static validateMax<T>(display: string, maxValue: number): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const validationErrors = {};

            const group = <FormGroup>control.parent;
            if (group == null) {
                return validationErrors;
            }

            const controlValue = control.value;
            if (controlValue && controlValue > maxValue) {
                validationErrors[display] = `${display} has a maximum of  ${maxValue}`;
            }

            return validationErrors;
        };
    }

    /**
     * Validates if a field is not empty of another field has a certail provided value.
     * @usageNotes Use on FormGroup
     * @param targetControlName Control the validation will be performed on.
     * @param sourceControlName Control the value will be checked of.
     * @param valueToCheck Value the sourceControl should have, leave empty to check any value.
     */
    static requiredIf<T>(
        targetControlName: keyof T,
        sourceControlName: keyof T,
        valueToCheck?: any
    ): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const targetControl = group.controls[targetControlName.toString()];
            const sourceControl = group.controls[sourceControlName.toString()];

            let isValid = true;
            const targetValue = targetControl.value;
            if (!targetControl || !sourceControl || targetValue) {
                // Target is filled in so do nothing.
            } else {
                const sourceValue = sourceControl.value;
                if (valueToCheck && sourceValue === valueToCheck) {
                    isValid = false;
                } else if (!valueToCheck && sourceValue) {
                    isValid = false;
                }
            }

            const validationErrors = isValid ? null : { required: "This field is required." };
            targetControl.setErrors(validationErrors);
            return {};
        };
    }

    static getFormControlErrors(formGroup: FormGroup): ValidationErrors {
        const validationErrors: ValidationErrors = this.getFormGroupValidationErrors(
            formGroup,
            formGroup.errors ? formGroup.errors : {}
        );

        return validationErrors;
    }

    static disableFormGroupExcept<T>(
        formGroup: FormGroup,
        ...ignoredProperties: (keyof T)[]
    ): void {
        this.alterStateFormGroupExcept(formGroup, false, ...ignoredProperties);
    }

    static enableFormGroupExcept<T>(formGroup: FormGroup, ...ignoredProperties: (keyof T)[]): void {
        this.alterStateFormGroupExcept(formGroup, true, ...ignoredProperties);
    }

    private static alterStateFormGroupExcept<T>(
        formGroup: FormGroup,
        enable: boolean,
        ...ignoredProperties: (keyof T)[]
    ): void {
        const ignoredFields = ignoredProperties.map((ip) => ip.toString());

        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (ignoredFields.indexOf(field) == -1) {
                if (enable) {
                    control.enable();
                } else {
                    control.disable();
                }
            }
        });
    }

    private static getFormGroupValidationErrors(
        formGroup: FormGroup,
        validationErrors: ValidationErrors
    ): ValidationErrors {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                this.setFormControlValidationErrors(field, control, validationErrors);
            } else if (control instanceof FormGroup) {
                this.getFormGroupValidationErrors(control, validationErrors);
            } else if (control instanceof FormArray) {
                this.getFormArrayValidationErrors(field, control, validationErrors);
            }
        });

        return validationErrors;
    }

    private static getFormArrayValidationErrors(
        field: string,
        formArray: FormArray,
        validationErrors: ValidationErrors
    ) {
        for (const control of formArray.controls) {
            if (control instanceof FormControl) {
                this.setFormControlValidationErrors(field, control, validationErrors);
            } else if (control instanceof FormGroup) {
                this.getFormGroupValidationErrors(control, validationErrors);
            } else if (control instanceof FormArray) {
                this.getFormArrayValidationErrors(field, control, validationErrors);
            }
        }
    }

    private static setFormControlValidationErrors(
        field: string,
        formControl: FormControl,
        validationErrors: ValidationErrors
    ) {
        if (formControl && formControl.errors && Object.keys(formControl.errors).length > 0) {
            validationErrors[field] = formControl.errors;
        }
    }

    private static removeFormControlError(control: AbstractControl, errorName: string) {
        if (control?.errors && control?.errors[errorName]) {
            delete control.errors[errorName];
            if (Object.keys(control.errors).length === 0) {
                control.setErrors(null);
            }
        }
    }
    private static addFormControlError(
        controlWithValidation: AbstractControl,
        errorKey: string,
        errorValue: string
    ) {
        // Preserve any other validation errors of the control
        const validationErrors = controlWithValidation.errors ? controlWithValidation.errors : {};
        validationErrors[errorKey] = errorValue;
        controlWithValidation.setErrors(validationErrors);
    }
}
