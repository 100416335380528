import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ElementRef,
    ViewChild,
} from "@angular/core";
import { CustomSortableOptions } from "@methods/PageMethods";
import { ToastrService } from "ngx-toastr";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { DisplayItemModel } from "@models/generic/DisplayItemModel";
import Sortable, { SortableOptions } from "sortablejs";
import { LinkableIconConfig } from "@app/shared/models/Linkable/LinkableIconConfig";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { TpaControlLinkModel } from "../../models/TpaControlLinkModel";
import { TpaConnectionDataService } from "@app/external-connections/services/tpa-connection.data.service";

export type LinkData = Pick<
    TpaControlLinkableComponent,
    "typename" | "unlinked" | "displayValueProp"
>;

@Component({
    selector: "tpa-control-linkable",
    templateUrl: "./tpa-control-linkable.component.html",
    styleUrls: ["./tpa-control-linkable.component.scss"],
})
export class TpaControlLinkableComponent implements OnInit {
    @Output() linkedChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() onLinkedClick: EventEmitter<any> = new EventEmitter();
    @Output() onBatchClicked: EventEmitter<any> = new EventEmitter();
    @Output() onOpenClicked: EventEmitter<any> = new EventEmitter();

    @Input() rowIconConfig: LinkableIconConfig[];
    @Input() rendererConfig: RendererConfig[] = [];

    @Input() linked: TpaControlLinkModel[] = [];
    @Input() unlinked: TpaControlLinkModel[] = [];
    @Input() displayValueProp: string;
    @Input() orderByValueProperty: boolean = false;
    @Input() disabled: boolean = false;
    @Input() typename: string; // plural because we cant to link riskS / controlS / moiS/ etc.

    @ViewChild("page") pageElementRef: ElementRef;

    sortingOptions: SortableOptions;

    showLinkPanel: boolean = true;
    currentExpandedRow: any = null;

    filterTextUnlinked: string;
    filterTextLinked: string;
    filteredUnlinked: any[];
    filteredLinked: any[];

    showBatchButton: boolean = false;
    openItemEnabled: boolean = false;
    editItemEnabled: boolean = false;

    linkablePaginationPage: number = 0;
    linkablePaginationPageCount: number = 0;
    linkablePaginationItemCount: number = 25;
    linkablePaginationText: string = "";
    linkablePage: any[];

    linkedPaginationPage: number = 0;
    linkedPaginationPageCount: number = 0;
    linkedPaginationItemCount: number = 25;
    linkedPaginationText: string = "";
    linkedPage: any[];

    linkedControls: number[];
    useIcons: boolean = false;

    frameworkDimensionTree: CerrixTreeItem[] = [];
    selectedFdIdLinked: number[];
    selectedFdIdUnlinked: number[];

    constructor(
        private _toastr: ToastrService,
        private _sdService: StandingdataDataService,
        private _tpaDatasService: TpaConnectionDataService
    ) {}

    ngOnInit(): void {
        this._sdService
            .getAllByType(StandingDataType.FrameworkDimensions)
            .subscribe((fd: any[]): void => {
                this.frameworkDimensionTree = fd;
            });
    }

    async reload(currentLinkedControls: number[]): Promise<void> {
        await this.getControls();
        this.linkedControls = currentLinkedControls;
        this.setConnectiedControls(this.unlinked, this.linkedControls);

        this.load();
    }

    async getControls(): Promise<void> {
        this.unlinked = await this._tpaDatasService.getControls().toPromise();
    }

    setConnectiedControls(unlinked: TpaControlLinkModel[], linkedControls: number[]): void {
        this.linked = [];
        this.unlinked = [];

        unlinked.forEach((c: TpaControlLinkModel): void => {
            if (linkedControls.indexOf(c.id) >= 0) {
                if (this.orderByValueProperty) {
                    this.linked.pushOrderedDesc(c, this.displayValueProp);
                } else {
                    this.linked.push(c);
                }
            } else {
                if (this.orderByValueProperty) {
                    this.unlinked.pushOrderedDesc(c, this.displayValueProp);
                } else {
                    this.unlinked.push(c);
                }
            }
        });
    }

    load(): void {
        this.setupInit();

        this.editItemEnabled = this.onLinkedClick.observers.length !== 0;
        this.showBatchButton = this.onBatchClicked.observers.length !== 0 && !this.disabled;
        this.openItemEnabled = this.onOpenClicked.observers.length !== 0;

        this.sortingOptions = CustomSortableOptions({
            sort: false,
            group: "header-group-" + this.typename,
            draggable: ".list-group-item-action",
            disabled: this.disabled,
            onRemove: (e: Sortable.SortableEvent): void => {
                this.filter(Filters.Both);
                this.linkedChange.emit(this.linked);
            },
        });

        this.resetFilters();

        this.showLinkPanel = !this.disabled;
    }

    resetFilters(): void {
        this.filterTextLinked = "";
        this.filterTextUnlinked = "";
        this.selectedFdIdLinked = null;
        this.selectedFdIdUnlinked = null;

        this.filter(Filters.Both);
    }

    setupInit(): void {
        if (this.rowIconConfig && this.rowIconConfig.length > 0) {
            this.useIcons = true;

            this.setIcons(this.linked);
            this.setIcons(this.unlinked);
        }

        if (this.unlinked.length > 0 && this.unlinked[0].displayItems) {
            this.parseDisplayItems(this.unlinked);
        }

        if (this.linked.length > 0 && this.linked[0].displayItems) {
            this.parseDisplayItems(this.linked);
        }
    }

    itemDoubleClick(item: any, linked: boolean, force: boolean): void {
        if (!force && !linked && this.editItemEnabled) {
            this.onLinkedClick.emit(item);
        }
    }

    toggleSelection(selectAll: boolean): void {
        if (selectAll) {
            for (const item of this.filteredUnlinked) {
                this.toggleItem(item, selectAll);
            }
            this.filterTextUnlinked = "";
            this.selectedFdIdUnlinked = null;
            this.filter(Filters.Unlinked);
        } else {
            for (const item of this.filteredLinked) {
                this.toggleItem(item, selectAll);
            }
            this.filterTextLinked = "";
            this.selectedFdIdLinked = null;
            this.filter(Filters.Linked);
        }
    }

    toggleItem(item: any, linked: boolean, force: boolean = false): void {
        if (this.disabled) {
            return;
        }

        if (!force && !linked && this.editItemEnabled) {
            return;
        }

        if (linked) {
            const index = this.unlinked.indexOf(item);
            this.unlinked.splice(index, 1);

            if (this.orderByValueProperty) {
                this.linked.pushOrderedDesc(item, this.displayValueProp);
            } else {
                this.linked.push(item);
            }
        } else {
            const index = this.linked.indexOf(item);
            this.linked.splice(index, 1);

            if (this.orderByValueProperty) {
                this.unlinked.pushOrderedDesc(item, this.displayValueProp);
            } else {
                this.unlinked.push(item);
            }
        }

        this.filter(Filters.Both);
    }

    filterFrameworkDimension(fdIds: number[], linked: boolean): void {
        if (linked) {
            this.selectedFdIdLinked = fdIds;
            this.filter(Filters.Linked);
        } else {
            this.selectedFdIdUnlinked = fdIds;
            this.filter(Filters.Unlinked);
        }
    }

    filterText(linked: boolean): void {
        this.filter(linked ? Filters.Linked : Filters.Unlinked);
    }

    filter(filterType: Filters): void {
        if (filterType === Filters.Both || filterType === Filters.Unlinked) {
            const filterTags =
                this.filterTextUnlinked && this.filterTextUnlinked.length > 0
                    ? this.filterTextUnlinked.toLowerCase().split(" ")
                    : [];

            if (filterTags.length === 0) {
                this.filteredUnlinked = this.unlinked.filter(
                    (_: TpaControlLinkModel): boolean => true
                );
            } else {
                this.filteredUnlinked = this.unlinked.filter((item: TpaControlLinkModel): boolean =>
                    filterTags.some(
                        (ft: string): boolean =>
                            item[this.displayValueProp].toLowerCase().indexOf(ft) >= 0
                    )
                );
            }

            if (this.selectedFdIdUnlinked && this.selectedFdIdUnlinked.any()) {
                this.filteredUnlinked = this.filteredUnlinked.filter(
                    (item: TpaControlLinkModel): boolean => {
                        if (item.frameworkDimensionIds) {
                            return this.selectedFdIdUnlinked.some(
                                (fd: number): boolean => item.frameworkDimensionIds.indexOf(fd) >= 0
                            );
                        }
                        return false;
                    }
                );
            }
        }

        if (filterType === Filters.Both || filterType === Filters.Linked) {
            const filterTags =
                this.filterTextLinked && this.filterTextLinked.length > 0
                    ? this.filterTextLinked.toLowerCase().split(" ")
                    : [];

            if (filterTags.length === 0) {
                this.filteredLinked = this.linked.filter((_: TpaControlLinkModel): boolean => true);
            } else {
                this.filteredLinked = this.linked.filter((item: TpaControlLinkModel): boolean =>
                    filterTags.some(
                        (ft: string): boolean =>
                            item[this.displayValueProp].toLowerCase().indexOf(ft) >= 0
                    )
                );
            }

            if (this.selectedFdIdLinked && this.selectedFdIdLinked.any()) {
                this.filteredLinked = this.filteredLinked.filter(
                    (item: TpaControlLinkModel): boolean => {
                        if (item.frameworkDimensionIds) {
                            return this.selectedFdIdLinked.some(
                                (fd: number): boolean => item.frameworkDimensionIds.indexOf(fd) >= 0
                            );
                        }
                        return false;
                    }
                );
            }
        }

        this.calculatePagination(filterType);
    }

    calculatePagination(filterType: Filters): void {
        if (filterType === Filters.Both || filterType === Filters.Linked) {
            this.linkedPaginationPageCount = Math.ceil(
                this.filteredLinked.length / this.linkedPaginationItemCount
            );
        }
        if (filterType === Filters.Both || filterType === Filters.Unlinked) {
            this.linkablePaginationPageCount = Math.ceil(
                this.filteredUnlinked.length / this.linkablePaginationItemCount
            );
        }

        this.setPagination(0, filterType);
    }

    setPaginationPage(page: number, linked: boolean): void {
        this.setPagination(page, linked ? Filters.Linked : Filters.Unlinked);
    }

    setPaginationLinked(page: number): void {
        if (page < 0 || page > this.linkedPaginationPageCount) {
            return;
        }

        if (page === this.linkedPaginationPageCount) {
            this.linkedPage = this.filteredLinked;
            return;
        }

        this.linkedPaginationPage = page;

        const linkedItemStart = this.linkedPaginationPage * this.linkedPaginationItemCount;
        const linkedItemEnd = linkedItemStart + this.linkedPaginationItemCount;
        this.linkedPage = this.filteredLinked.slice(linkedItemStart, linkedItemEnd);

        const start = this.linkedPaginationPage * this.linkedPaginationItemCount + 1;
        const end =
            this.linkedPaginationPage + 1 === this.linkedPaginationPageCount
                ? this.filteredLinked.length
                : this.linkedPaginationPage * this.linkedPaginationItemCount +
                  this.linkedPaginationItemCount;

        this.linkedPaginationText = `Showing ${start} - ${end} of ${this.filteredLinked.length} ${this.typename}`;
    }

    setPaginationUnlinked(page: number): void {
        if (page < 0 || page > this.linkablePaginationPageCount) {
            return;
        }

        if (page === this.linkablePaginationPageCount) {
            this.linkablePage = this.filteredUnlinked;
            return;
        }

        this.linkablePaginationPage = page;

        const linkableItemStart = this.linkablePaginationPage * this.linkablePaginationItemCount;
        const linkableItemEnd = linkableItemStart + this.linkablePaginationItemCount;
        this.linkablePage = this.filteredUnlinked.slice(linkableItemStart, linkableItemEnd);

        const start = this.linkablePaginationPage * this.linkablePaginationItemCount + 1;
        const end =
            this.linkablePaginationPage + 1 === this.linkablePaginationPageCount
                ? this.filteredUnlinked.length
                : this.linkablePaginationPage * this.linkablePaginationItemCount +
                  this.linkablePaginationItemCount;

        this.linkablePaginationText = `Showing ${start} - ${end} of ${this.filteredUnlinked.length} ${this.typename}`;
    }

    setPagination(page: number, filterType: Filters): void {
        if (filterType === Filters.Both || filterType === Filters.Linked) {
            this.setPaginationLinked(page);
        }

        if (filterType === Filters.Both || filterType === Filters.Unlinked) {
            this.setPaginationUnlinked(page);
        }
    }

    expandItem(item: any): void {
        item._isExpanded = !item._isExpanded;

        // Comment this item to allow all row to be expanded.
        if (this.currentExpandedRow !== item) {
            if (this.currentExpandedRow) {
                this.currentExpandedRow._isExpanded = false;
            }

            this.currentExpandedRow = item;
        }
    }

    setIcons(items: any): void {
        if (items) {
            this.rowIconConfig.forEach((config: LinkableIconConfig): void => {
                items.forEach((item: any): void => {
                    if (item[config.actionColumn] === config.actionValue) {
                        item.rowIcon = config.icon;
                    }
                });
            });
        }
    }

    parseDisplayItems(items: any): void {
        // Create array of hidden columns, which should be filtered out of the displayItems array
        const renderConfig = this.rendererConfig || [];
        const hiddenRendererColumns = [];
        for (const rc of renderConfig) {
            if (rc.hideColumn) {
                hiddenRendererColumns.push(rc.textColumn);
            }
            if (rc.hideActionColumn && rc.actionColumn) {
                hiddenRendererColumns.push(rc.actionColumn);
            }
        }

        items.forEach((item: any): void => {
            // Create flat object of the whole displayItems array, required for the workspace cell renderer
            const parsedItems = {};

            for (let i = item.displayItems.length - 1; i >= 0; i--) {
                const displayItem: DisplayItemModel = item.displayItems[i];
                parsedItems[displayItem.Header] = displayItem.Value;

                // Remove the hidden renderer columns from the displayItems array
                if (hiddenRendererColumns.includes(displayItem.Header)) {
                    item.displayItems.splice(i, 1);
                }
            }

            item.displayItemRow = parsedItems;
        });
    }
}

export enum Filters {
    Both = 1,
    Linked = 2,
    Unlinked = 3,
}
