import { Component, OnInit } from "@angular/core";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { BsModalRef, ModalOptions, BsModalService } from "ngx-bootstrap/modal";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { DatasubjectDialogComponent } from "../datasubject-dialog/datasubject-dialog.component";
import { DataStructuresStandingData } from "@app/datamanagement/models/DataStructuresStandingData";
import { DataSubjectModel } from "@app/datamanagement/models/DataSubjectModel";
import { EntityModel } from "@app/datamanagement/models/EntityModel";
import { SystemModel } from "@app/datamanagement/models/SystemModel";

@Component({
    selector: "systems-dialog",
    templateUrl: "./systems-dialog.component.html",
    styleUrls: ["./systems-dialog.component.scss", "../../../../sass/datamanagement.scss"],
})
export class SystemsDialogComponent extends BaseModal implements OnInit {
    entityModel: EntityModel;
    systemOptions: IdNameCombination[];
    standingData: DataStructuresStandingData;

    showManageAllData = false;
    private _selectedDataSubjects: DataSubjectModel[] = [];

    constructor(_bsModalRef: BsModalRef, private _modalService: BsModalService) {
        super(_bsModalRef);
    }

    ngOnInit(): void {}

    changed(currentSelection: IdNameCombination[]) {
        let selectedCount = 0;

        const currentSelectedIds = currentSelection.map((x) => x["ID"]);
        this.systemOptions.forEach((system) => {
            const selected = currentSelectedIds.indexOf(system.ID) > -1;
            // The selected property is only used in this class and not used a part of the model
            system["selected"] = selected;

            if (selected) {
                selectedCount++;
            }
        });

        this.showManageAllData = selectedCount > 1;
    }

    manageDataAllSystems() {
        if (!this.manageDataAllSystems) {
            return;
        }

        const config = <ModalOptions<DatasubjectDialogComponent>>{
            backdrop: "static",
            class: "datamanagement-dialog modal-lg modal-xl",

            initialState: <DatasubjectDialogComponent>{
                standingData: this.standingData,
            },
        };

        this._modalService
            .show(DatasubjectDialogComponent, config)
            .content.afterClosed.subscribe((dialogResult: DataSubjectModel[] | boolean) => {
                if (dialogResult) {
                    this._selectedDataSubjects = dialogResult as DataSubjectModel[];
                }
            });
    }

    okClick() {
        this._bsModalRef.hide();

        const addedSystems: SystemModel[] = [];

        // Map selected systems to SystemModels
        // The selected property is only used in this class and not used a part of the model
        const selectedSystems = this.systemOptions.filter((x) => x["selected"]);
        selectedSystems.forEach((x) => {
            const addedSystem = <SystemModel>{
                id: x.ID,
                name: x.Name,
                dataSubjects: JSON.parse(
                    JSON.stringify(this._selectedDataSubjects)
                ) as DataSubjectModel[],
                dataSubjectsLoaded: true,
            };

            addedSystems.push(addedSystem);
        });

        super.onConfirm(addedSystems);
    }

    closeClick() {
        super.onCancel();
    }
}
