<div class="detail-page-card">
    <div class="detail-page-card-title">Linked data processing</div>
    <div class="detail-page-card-actions" *ngIf="summaryModel">
        <button
            class="btn btn-raised"
            (click)="openProcessingPurpose()"
            *ngIf="summaryModel.CanOpenParent"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Open data processing</span>
        </button>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
        <div class="fancy-loader submodule my-4" *ngIf="!summaryModel"></div>
        <div *ngIf="summaryModel">
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group">
                                <label>Identifier</label>
                                <input
                                    data-uiid="dm-moi-identifier"
                                    type="text"
                                    class="form-control form-control-sm"
                                    disabled
                                    [value]="summaryModel.Identifier"
                                    inputTooltip
                                />
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="form-group">
                                <label>Name Data Processing</label>
                                <input
                                    data-uiid="dm-moi-name"
                                    type="text"
                                    class="form-control form-control-sm"
                                    disabled
                                    [value]="summaryModel.Name"
                                    inputTooltip
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Data subject(s)</label>
                                <input
                                    data-uiid="dm-moi-datasubjects"
                                    type="text"
                                    class="form-control form-control-sm"
                                    disabled
                                    [value]="summaryModel.DataSubject"
                                    inputTooltip
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Most recent DPIA</label>
                                <date-input
                                    data-uiid="dm-moi-mostrecentdpia"
                                    [(value)]="summaryModel.LatestDpia"
                                    [disabled]="true"
                                ></date-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Description</label>
                        <textarea
                            data-uiid="dm-moi-description"
                            class="form-control form-control-sm"
                            disabled
                            [value]="summaryModel.Description"
                            inputTooltip
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
