import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { ProcessedDocumentUploadModel } from "../models/ProcessedDocumentUploadModel";
import { Observable } from "rxjs";
import { ProcessedDocumentDetailModel } from "../models/ProcessedDocumentDetailModel";
import { ProcessedDocumentOverviewComponent } from "../pages/processed-document-overview/processed-document-overview.component";
import { ProcessedDocumentSuggestionModel } from "../models/ProcessedDocumentSuggestionModel";
import { ProcessedDocumentFeedbackModel } from "../models/ProcessedDocumentFeedbackModel";

@Injectable()
export class ProcessedDocumentsService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "processeddocuments");
    }

    public getOverview(): Observable<ProcessedDocumentOverviewComponent[]> {
        return this.get<ProcessedDocumentOverviewComponent[]>();
    }

    public getDetail(id?: number): Observable<ProcessedDocumentDetailModel> {
        const url = `/${id}`;
        return this.get<any>(url);
    }

    public postDocument(uploadModel: ProcessedDocumentUploadModel): Observable<void> {
        const url = "/document";

        return this.postWithDocuments<void>(uploadModel, uploadModel.Documents, url);
    }

    public deleteProcessedDocument(id: number): Observable<void> {
        const url = `/${id}`;
        return this.delete(url);
    }

    public declineSuggestedRisk(feedback: ProcessedDocumentFeedbackModel): Observable<void> {
        const url = `/decline/risk`;
        return this.post<any>(feedback, url);
    }

    public declineSuggestedControl(feedback: ProcessedDocumentFeedbackModel): Observable<void> {
        const url = `/decline/control`;
        return this.post<any>(feedback, url);
    }
}
