<div class="d-flex flex-column main-container">
    <div class="label bold-text">{{ label }}</div>
    <mat-radio-group [(ngModel)]="model" (change)="onChange()">
        <mat-radio-button
            *ngFor="let option of options"
            [value]="option.id"
            data-uiid="new-eff-radio-group-option"
            >{{ option.name }}</mat-radio-button
        >
    </mat-radio-group>
</div>
