import { Component, Input } from "@angular/core";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";

@Component({
    selector: "moi-incident-summary",
    templateUrl: "./moi-incident-summary.component.html",
    styleUrls: ["./moi-incident-summary.component.scss"],
})
export class MoiIncidentSummaryComponent {
    @Input() summaryModel: any;
    @Input() incidentGuid: any;

    constructor(private tabservice: TabService, private pages: Pages) {}

    openIncident() {
        this.tabservice.generateTab(this.pages.IncidentDetail, this.incidentGuid);
    }
}
