import { Component } from "@angular/core";
import { ManualPageType } from "@app/manual/models/manual-page-type";
import { BimGetMoiDataApiManualComponent } from "../manual-pages/apis/api-spec/types/bim/bim-get-moi-data-api-manual";
import { BimGetMoiSummaryDataApiManualComponent } from "../manual-pages/apis/api-spec/types/bim/bim-get-moi-summary-data-api-manual";
import { TpaGetEvidenceExportApiManualComponent } from "../manual-pages/apis/api-spec/types/external-connections/third-party-assurance/tpa-getevidencedocument-api-manual";
import { TpaGetResultsApiManualComponent } from "../manual-pages/apis/api-spec/types/external-connections/third-party-assurance/tpa-getresults-api-manual";
import { TpaRequestExportApiManualComponent } from "../manual-pages/apis/api-spec/types/external-connections/third-party-assurance/tpa-requestexport-api-manual";
import { KriGetDataApiManualComponent } from "../manual-pages/apis/api-spec/types/kri/kri-get-data-api-manual";
import { KriPostDataApiManualComponent } from "../manual-pages/apis/api-spec/types/kri/kri-post-data-api-manual";
import { RiskGetAssessmentPerOrgDataApiManualComponent } from "../manual-pages/apis/api-spec/types/risk/risk-get-assessment-per-org-api-manual.component";
import { RiskGetRiskAndControlDataApiManualComponent } from "../manual-pages/apis/api-spec/types/risk/risk-get-risk-and-control-data-api-manual.component";
import { RiskGetRiskControlAndMoiDataApiManualComponent } from "../manual-pages/apis/api-spec/types/risk/risk-get-risk-control-and-moi-data-api-manual.component";
import { RiskGetRiskDataApiManualComponent } from "../manual-pages/apis/api-spec/types/risk/risk-get-risk-data-api-manual.component";
import { ApiUsageManualComponent } from "../manual-pages/apis/api-usage-manual/api-usage-manual.component";
import { ApiPowerBIUsageComponent } from "./../manual-pages/apis/api-powerbi-usage/api-powerbi-usage.component";
import { ControlGenerateSamplesApiManualComponent } from "./../manual-pages/apis/api-spec/types/control/control-generate-samples-api-manual.component";
import { ControlGetSamplesApiManualComponent } from "./../manual-pages/apis/api-spec/types/control/control-get-samples-api-manual.component";
import { ControlReadyForAssessmentApiManualComponent } from "./../manual-pages/apis/api-spec/types/control/control-ready-for-assessment-api-manual.component";
import { ControlTestInfoApiManualComponent } from "./../manual-pages/apis/api-spec/types/control/control-test-info-api-manual.component";
import { ControlSamplesUploadEvidenceApiManualComponent } from "../manual-pages/apis/api-spec/types/control/control-samples-upload-evidence-api-manual.component";
import { ControlTestStepsUploadEvidenceApiManualComponent } from "../manual-pages/apis/api-spec/types/control/control-teststeps-upload-evidence-api-manual.component";
import { ControlUploadSourceDocumentApiManualComponent } from "./../manual-pages/apis/api-spec/types/control/control-upload-source-document-api-manual.component";
import { EventGetDataBreachAndMoiDataManualComponent } from "./../manual-pages/apis/api-spec/types/events/event-get-data-breach-and-moi-data-manual.component";
import { EventGetEventAndMoiDataManualComponent } from "./../manual-pages/apis/api-spec/types/events/event-get-event-and-moi-data-manual.component";
import { EventGetEventDataManualComponent } from "./../manual-pages/apis/api-spec/types/events/event-get-event-data-manual.component";
import { FrGetFindingReportAssDataApiManualComponent } from "./../manual-pages/apis/api-spec/types/findingreport/fr-finding-report-ass-data-api-manual.component";
import { BaseManualSection } from "./BaseManualSection/BaseManualSection";
import { ControlSamplesNoEvidenceApiManualComponent } from "../manual-pages/apis/api-spec/types/control/control-samples-no-evidence-api-manual.component";
import { ControlSamplesScoreApiManualComponent } from "../manual-pages/apis/api-spec/types/control/control-samples-score-api-manual.component";
import { ControlTestStepsScoreApiManualComponent } from "../manual-pages/apis/api-spec/types/control/control-teststeps-score-api-manual.component";
import { FrmFormsResultsApiManualComponent } from "../manual-pages/apis/api-spec/types/frm/frm-forms-results-api-manual";
import { TaskGetTaskDataApiManualComponent } from "../manual-pages/apis/api-spec/types/task/task-get-task-data-api-manual.component";
import { ManualPage } from "@app/manual/models/manual-page";
import { DirectDataApiMapping } from "../manual-pages/apis/api-spec/types/directdata/directdata-api-mapping";
import { DirectDataApiManualComponent } from "../manual-pages/apis/api-spec/types/directdata/directdata-api-manual.component";

@Component({
    selector: "api-manual",
    templateUrl: "./BaseManualSection/BaseManualSection.html",
    styleUrls: ["./BaseManualSection/BaseManualSection.scss"],
})
export class ApiManualSectionComponent extends BaseManualSection {
    tableOfContentsHeader = "API Manual";

    initTableOfContents() {
        this.tableOfContents = [
            {
                pageid: ManualPageType.ApiUsage.page,
                name: "API Usage",
                component: ApiUsageManualComponent,
            },
            {
                pageid: ManualPageType.ApiPowerBIUsage.page,
                name: "API Power BI Usage",
                component: ApiPowerBIUsageComponent,
            },
            {
                pageid: "orm-apis",
                name: "Risk APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.RiskDataApi.page,
                        name: "Get Risk Data",
                        component: RiskGetRiskDataApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.RiskControlDataApi.page,
                        name: "Get Risk and Control Data",
                        component: RiskGetRiskAndControlDataApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.RiskControlMoiDataApi.page,
                        name: "Get Risk Control and Action Data",
                        component: RiskGetRiskControlAndMoiDataApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.RiskAssessmentPerOrganizationDataApi.page,
                        name: "Get Risk Assessment per Organization Data",
                        component: RiskGetAssessmentPerOrgDataApiManualComponent,
                    },
                ],
            },
            {
                pageid: "controls-apis",
                name: "Control APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.ControlTestInfoApi.page,
                        name: "Get Test Info",
                        component: ControlTestInfoApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlUploadSourceDocumentApi.page,
                        name: "Upload Source Document",
                        component: ControlUploadSourceDocumentApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlGenerateSamplesApi.page,
                        name: "Generate Samples",
                        component: ControlGenerateSamplesApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlGetSamplesApi.page,
                        name: "Get Samples",
                        component: ControlGetSamplesApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlSamplesNoEvidenceApi.page,
                        name: "Samples No Evidence",
                        component: ControlSamplesNoEvidenceApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlSamplesUploadEvidenceApi.page,
                        name: "Samples Upload Evidence",
                        component: ControlSamplesUploadEvidenceApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlTestStepsUploadEvidenceApi.page,
                        name: "Test Steps Upload Evidence",
                        component: ControlTestStepsUploadEvidenceApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlReadyForAssessmentApi.page,
                        name: "Ready For Assessment",
                        component: ControlReadyForAssessmentApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlSamplesScoreApi.page,
                        name: "Samples Score",
                        component: ControlSamplesScoreApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.ControlTestStepsScoreApi.page,
                        name: "Test Steps Score",
                        component: ControlTestStepsScoreApiManualComponent,
                    },
                ],
            },
            {
                pageid: "ler-apis",
                name: "Event APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.EventDataApi.page,
                        name: "Get Event Data",
                        component: EventGetEventDataManualComponent,
                    },
                    {
                        pageid: ManualPageType.EventMoiDataApi.page,
                        name: "Get Event and MoI Data",
                        component: EventGetEventAndMoiDataManualComponent,
                    },
                    {
                        pageid: ManualPageType.DataBreachMoiDataApi.page,
                        name: "Get Data Breach and Moi Data",
                        component: EventGetDataBreachAndMoiDataManualComponent,
                    },
                ],
            },
            {
                pageid: "fr-apis",
                name: "Finding Report APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.FindingReportDataApi.page,
                        name: "Get Finding Report and Assessment Data",
                        component: FrGetFindingReportAssDataApiManualComponent,
                    },
                ],
            },
            {
                pageid: "bim-apis",
                name: "Business Improvement Management APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.MoiDataApi.page,
                        name: "Get MoI Data",
                        component: BimGetMoiDataApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.MoiSummaryDataApi.page,
                        name: "Get MoI Summary Data",
                        component: BimGetMoiSummaryDataApiManualComponent,
                    },
                ],
            },
            {
                pageid: "kri-apis",
                name: "Key Risk Indicators APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.KriDataApi.page,
                        name: "Get KRI/KPI Data",
                        component: KriGetDataApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.KriUploadDataApi.page,
                        name: "Upload KRI/KPI Data",
                        component: KriPostDataApiManualComponent,
                    },
                ],
            },
            {
                pageid: "frm-apis",
                name: "Forms APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.FrmFormsResultsApi.page,
                        name: "Get Forms Results",
                        component: FrmFormsResultsApiManualComponent,
                    },
                ],
            },
            {
                pageid: "ec-apis",
                name: "External connections",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.EC_TPA_RequestExport.page,
                        name: "Request export",
                        component: TpaRequestExportApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.EC_TPA_GetResults.page,
                        name: "Get results",
                        component: TpaGetResultsApiManualComponent,
                    },
                    {
                        pageid: ManualPageType.EC_TPA_GetEvidenceDocument.page,
                        name: "Get evidence document",
                        component: TpaGetEvidenceExportApiManualComponent,
                    },
                ],
            },
            {
                pageid: "tasks-apis",
                name: "Task APIs",
                component: null,
                children: [
                    {
                        pageid: ManualPageType.TaskDataApi.page,
                        name: "Get Task Data",
                        component: TaskGetTaskDataApiManualComponent,
                    },
                ],
            },
            {
                pageid: "direct-data-apis",
                name: "Direct Data APIs",
                component: null,
                children: this.getDirectDataApiManuals(),
            },
        ];
    }

    private getDirectDataApiManuals(): ManualPage[] {
        const children: ManualPage[] = [];

        const directDataModules = DirectDataApiMapping.getMapping();

        Object.keys(directDataModules).forEach((moduleKey) => {
            // Push module item
            children.push({
                pageid: "".toString(),
                name: moduleKey.toString(),
                component: null,

                // Get children for the module
                children: Object.keys(directDataModules[moduleKey]).map((apiKey) => {
                    const api = directDataModules[moduleKey][apiKey];

                    return {
                        pageid: `${moduleKey}:${apiKey}`,
                        name: api.name,
                        component: DirectDataApiManualComponent,
                    };
                }),
            });
        });

        return children;
    }
}
