<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div menuSystem [cerrixTab]="tab" (change)="onMenuItemClick($event)">
    <div class="detail-page-action-bar flex-action-bar" *ngIf="!hideActionBar">
        <!-- This div has to stay, so the workflow area can be placed correctly. -->
        <div></div>

        <div class="workflow-area" *ngIf="this.permissions">
            <button
                *ngIf="
                    this.permissions.CanManageEffectivenesses ||
                    this.permissions.CanScoreEffectivenessesTestPeriod
                "
                class="btn btn-warning"
                (click)="openModal(testPeriodManager)"
            >
                <i class="fas fa-business-time me-2"></i>
                Manage test periods
            </button>
            <button
                *ngIf="this.permissions.CanUseAuditSampler"
                class="btn btn-info"
                (click)="openModal(auditSampler)"
            >
                <i class="fas fa-wrench me-2"></i>
                Audit sampler
            </button>
        </div>
    </div>
    <div name="Control" menuicon="fas fa-shield-alt" menusystemsubmenu>
        <div name="Links" menuicon="fas fa-link" menusystemsubmenu>
            <div
                name="Test Plans"
                menuicon="fas fa-shield-alt"
                menuItemId="effectiveness-menu-item"
                menusystemitem
            >
                <div class="detail-page-card" *ngIf="!effectivenessOverviewConfig">
                    <div class="detail-page-card-title">Test plans</div>
                    <div class="detail-page-card-body padded">
                        <div class="fancy-loader submodule"></div>
                    </div>
                </div>
                <generic-grouped-list-manager
                    *ngIf="effectivenessOverviewConfig"
                    [config]="effectivenessOverviewConfig"
                >
                </generic-grouped-list-manager>

                <adv-eff-wizard [controlGuid]="id" (reload)="reload()" #testWizard></adv-eff-wizard>

                <ng-template #testPeriodManager>
                    <adv-eff-testperiod-manager
                        [controlGuid]="id"
                        [modalRef]="modalRef"
                        (reload)="reload()"
                        [controlPermissions]="permissions"
                    >
                    </adv-eff-testperiod-manager>
                </ng-template>

                <ng-template #auditSampler>
                    <adv-eff-audit-sampler
                        [modalRef]="modalRef"
                        (reload)="reload()"
                    ></adv-eff-audit-sampler>
                </ng-template>

                <control-detail-overview
                    *ngIf="id"
                    [ControlGuid]="id"
                    Label="Linked control"
                    [HideOpenControlButton]="false"
                    [HideControlDetails]="false"
                    [HideDesignScore]="false"
                    [HideEffectivenessScore]="false"
                >
                </control-detail-overview>
            </div>
        </div>
        <div name="History" menuicon="fas fa-history" menuItemId="historymenuitem" menusystemitem>
            <app-history-overview
                [historyDataService]="advancedEffectivenessHistoryDataService"
                [Id]="id"
            >
            </app-history-overview>
        </div>
    </div>
</div>
