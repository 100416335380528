import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[autogrow]",
})
export class TextAreaAutoGrowDirective implements AfterViewInit {
    @Input() autoGrowEnabled: boolean = true;

    constructor(private inputElement: ElementRef) {}

    ngAfterViewInit(): void {
        if (!this.autoGrowEnabled) {
            return;
        }

        // Unsupported tag.
        if (this.inputElement.nativeElement.tagName.toUpperCase() != "TEXTAREA") {
            return;
        }

        this.inputElement.nativeElement.addEventListener("input", () => this.setHeight());

        // This makes sure the textarea is first populated with text before the height is set.
        setTimeout(() => {
            this.setHeight();
        }, 10);
    }

    private setHeight() {
        const domElem = this.inputElement.nativeElement;

        domElem.style.minHeight = "auto";
        domElem.style.height = "1px";
        domElem.style.height = domElem.scrollHeight + 5 + "px";
    }
}
