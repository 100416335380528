import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { UserAuditModel } from "../shared/user-audit-model";
import { UserRoleGroupModel } from "../shared/user-role-group-model";
import { UserDetailModel } from "../shared/user-detail-model";
import { UserStoreResponseModel } from "../shared/user-store-response-model";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";

@Injectable()
export class UserMaintenanceService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "users/maintenance");
    }

    public getList() {
        return this.get<any[]>();
    }

    public getNewUser() {
        const url = `/new`;
        return this.get<UserDetailModel>(url);
    }

    public getUser(userGuid: string) {
        const url = `/${userGuid}`;
        return this.get<UserDetailModel>(url);
    }

    public storeUser(model: UserDetailModel) {
        return this.post<UserStoreResponseModel>(model);
    }

    public deleteUser(userGuid: string) {
        const url = `/${userGuid}`;
        return this.delete(url);
    }

    public getOrganizations() {
        const url = `/metadata/organizations`;
        return this.get<CerrixTreeItem[]>(url);
    }

    public getRoleGroups() {
        const url = `/metadata/rolegroups`;
        return this.get<UserRoleGroupModel[]>(url);
    }

    public getAudit(userGuid: string) {
        const url = `/${userGuid}/audit`;
        return this.get<UserAuditModel>(url);
    }

    public unlockUser(userGuid: string) {
        const url = `/${userGuid}/resetlogins`;
        return this.post(null, url);
    }

    public getHistory(userGuid: string) {
        const url = `/${userGuid}/history`;
        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(userGuid: string, mutationId: number) {
        const url = `/${userGuid}/history/${mutationId}`;

        return this.get<HistoryChange[]>(url);
    }
}
