import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { GenericGroupedListConfig } from "@app/shared/models/GenericList/GenericGroupedListConfig";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { OverviewDataService } from "./shared/adv-eff-overview-data.service";
import { WizardComponent } from "./wizard/adv-eff-wizard.component";
import { EffectivenessControlOverviewModel } from "./shared/effectiveness-control-overview.model";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { finalize, map } from "rxjs/operators";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { of } from "rxjs";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { AdvEffHistoryDataService } from "./adv-eff-history-data.service";
import { nameof } from "@methods/jeffs-toolkit";

@Component({
    selector: "adv-eff-control-overview",
    templateUrl: "./adv-eff-control-overview.component.html",
    styleUrls: ["./adv-eff-control-overview.component.scss"],
})
export class AdvEffControlOverviewComponent implements OnInit {
    @ViewChild("testWizard") testWizard: WizardComponent;
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent;

    tab: TabModel;
    id: any;
    effectivenessOverviewConfig: GenericListConfig;
    modalRef: BsModalRef;
    permissions: ControlPermissionModel;
    scores: IdNameColorModel[];
    hideActionBar = false;

    constructor(
        private ds: OverviewDataService,
        private pages: Pages,
        private tabService: TabService,
        private modalService: BsModalService,
        private controlDs: ControlDataService,
        public advancedEffectivenessHistoryDataService: AdvEffHistoryDataService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit(): void {
        this.controlDs.getPermissions(this.id).subscribe(
            (perm) => {
                this.permissions = perm;
                this.controlDs.getAllScores().subscribe((d) => {
                    this.scores = d;
                    this.createOverviewConfig();

                    this.tab.showLoader = false;
                });
            },
            () => this.tab.close(false)
        );
    }

    createOverviewConfig(): void {
        this.effectivenessOverviewConfig = <GenericGroupedListConfig>{
            name: "Test plans",
            groupByProp: nameof<EffectivenessControlOverviewModel>((eff) => eff.groupId),
            groupByDisplayName: nameof<EffectivenessControlOverviewModel>((eff) => eff.groupName),
            overviewSortBy: nameof<EffectivenessControlOverviewModel>((eff) => eff.endDate),
            overviewSortAsc: true,

            idProp: nameof<EffectivenessControlOverviewModel>((eff) => eff.guid),

            allowAdd: this.permissions.CanManageEffectivenesses,
            allowEdit: this.permissions.CanManageEffectivenesses,
            allowDelete: this.permissions.CanManageEffectivenesses,

            fields: [
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>((eff) => eff.testPlanName),
                    prettyName: "Test plan name",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>(
                        (eff) => eff.statusDescription
                    ),
                    prettyName: "Status",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>((eff) => eff.startDate),
                    prettyName: "Start Date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>((eff) => eff.endDate),
                    prettyName: "End Date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>(
                        (eff) => eff.numberOfSamples
                    ),
                    prettyName: "# Samples",
                    fieldType: GenericListFieldType.Number,
                },
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>(
                        (eff) => eff.numberOfSourceDocuments
                    ),
                    prettyName: "# Source Documents",
                    fieldType: GenericListFieldType.Number,
                },
                {
                    fieldName: nameof<EffectivenessControlOverviewModel>((eff) => eff.score),
                    prettyName: "Score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    getDataMethod: () => {
                        return of(this.scores);
                    },
                },
            ],

            cellConfig: {
                testPlanName: {
                    alignLeft: true,
                },
            },

            cellSummaries: {
                testPlanName: {
                    cellStringFormat: "[embedGroupName] ([embedCount] Test plans)",
                    embedGroupName: true,
                    embedCount: true,
                },
                startDate: {
                    cellStringFormat:
                        "{{" +
                        nameof<EffectivenessControlOverviewModel>((eff) => eff.groupStartDate) +
                        "}}",
                },
                endDate: {
                    cellStringFormat:
                        "{{" +
                        nameof<EffectivenessControlOverviewModel>((eff) => eff.groupEndDate) +
                        "}}",
                },
                numberOfSamples: {
                    cellStringFormat: "[embedSum]/[embedAvg]",
                    embedSum: true,
                    embedAvg: nameof<EffectivenessControlOverviewModel>(
                        (eff) => eff.groupMinimumSampleCount
                    ), // This will be minimum sample count in future.
                },
                numberOfSourceDocuments: {
                    cellStringFormat: "[embedSum]",
                    embedSum: true,
                },
                score: {
                    embedColor: { colorCol: "groupScoreColor", textCol: "groupScoreText" },
                },
            },

            overviewRowActionsName: "Details",
            overviewRowActions: [
                {
                    icon: "fas fa-edit",
                    tooltip: "Edit Test Plan",
                    clickEvent: (row: EffectivenessControlOverviewModel) => {
                        this.testWizard.effectivenessGuid = row.guid;
                        this.testWizard.load(true);
                    },
                },
            ],

            addOverride: async () => {
                this.testWizard.effectivenessGuid = null;
                await this.testWizard.load(true);
            },
            editOverride: (row: any) => {
                this.tabService.generateTab(this.pages.ControlsTestingAdvEffDetail, row.guid);
                return false;
            },
            delete: async (row: any) => {
                if (
                    await this._promptService
                        .confirm(
                            "Delete Test Plan",
                            `Are you sure you want to delete this Test Plan?`
                        )
                        .toPromise()
                ) {
                    const deletingPrompt = this._promptService.loader(
                        "Deleting Test Plans, please wait..."
                    );

                    await this.ds
                        .deleteEffectiveness(row.guid)
                        .pipe(finalize(() => deletingPrompt.close()))
                        .toPromise();

                    this.effectivenessOverviewConfig.reload();
                    return true;
                }

                return false;
            },
            dataMethod: () => this.ds.getControlEffectivenesses(this.id, this.scores),
        };
    }

    async reload() {
        await this.effectivenessOverviewConfig.reload();
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: "modal-xl",
            animated: true,
            keyboard: false,
            ignoreBackdropClick: true,
        });
    }

    onMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem || !menuItem.menuItemId) {
            return;
        }

        this.hideActionBar = menuItem.menuItemId !== "effectiveness-menu-item";

        switch (menuItem.menuItemId) {
            case "historymenuitem": {
                this.historyOverviewComponent.loadHistory();
                break;
            }
        }
    }
}
