import { Component, OnInit } from "@angular/core";
import { AuditUniversePeriodDataService } from "@app/audit-universe-period/services/audit-universe-period-data.service";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { toPromise } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { AuditUniversePeriodInfoModel } from "./../../shared/data-models/audit-universe-period-info-model";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { ModuleSubType } from "@enums/ModuleSubType";

@Component({
    selector: "audit-universe-period-overview",
    templateUrl: "./audit-universe-period-overview.component.html",
    styleUrls: ["./audit-universe-period-overview.component.scss"],
})
export class AuditUniversePeriodOverviewComponent implements OnInit {
    moduleType = ModuleType.AUDIT;
    appendToModuleType = ModuleSubType[ModuleSubType.AuditPeriods];
    tabID: string;
    tab: TabModel;

    data: Promise<any[]>;
    headerLookup: any = {
        identifier: "Identifier",

        name: "Name",
        startDate: "Start date",
        endDate: "End date",
        dateClosed: "Date closed",
    };

    filterConfigs: FilterConfig[] = [];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "startDate",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "endDate",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "dateClosed",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];

    dateProps: string[] = ["startDate", "endDate", "dateClosed"];
    numberProps: string[] = [];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _pages: Pages,
        private _tabs: TabService,
        private _permissions: PermissionsService,
        private _ds: AuditUniversePeriodDataService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.auditPeriod;
        this.data = toPromise(this._ds.getList()).then(this.mapHeaders);
    }

    openDetail(period: AuditUniversePeriodInfoModel) {
        this._tabs.generateTab(
            this._pages.AuditUniversePeriodDetail,
            period.guid,
            `(AU.P.) ${period.name}`
        );
    }

    add() {
        this._tabs.generateTab(this._pages.AuditUniversePeriodDetail, null, "(AU.P.) New Period");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
