import {
    EffectivenessStatusType,
    SampleScore,
    TestStepScore,
} from "@app/controls/testing/advanced/shared/enums";
import {
    EvidenceSampleEditModel,
    EvidenceTestStepEditModel,
} from "@app/controls/testing/advanced/shared/models";
import { DocumentModel } from "@models/documents/documentmodel";

export class EvidenceSampleTestStep {
    guid: string;
    effectivenessGuid: string;
    name: string;
    status: EffectivenessStatusType;
    scoreName: string;
    scoreColor: string;
    commentTester: string;
    commentReviewer: string;
    documents: DocumentModel[] = [];

    model: EvidenceSampleEditModel | EvidenceTestStepEditModel;
    isSample: boolean;

    //only for step
    stepScore: TestStepScore;
    description: string;
    requiredDocuments: string;
    testMethod: string;

    //only for sample
    sampleScore: SampleScore;
    noEvidence: boolean;
    noEvidenceReason: string;
    remarks: string;

    triggerOpenScore?: boolean;
    IsExpanded: boolean;

    constructor(sample: EvidenceSampleEditModel | EvidenceTestStepEditModel) {
        this.guid = sample.guid;
        this.effectivenessGuid = sample.effectivenessGuid;
        this.name = sample.name;
        this.status = sample.status;
        this.scoreColor = sample.scoreColor;
        this.commentTester = sample.commentTester;
        this.commentReviewer = sample.commentReviewer;
        this.documents = sample.documents;
        this.isSample = true;
        this.model = sample;
        if (sample instanceof EvidenceSampleEditModel) {
            this.fillSample(sample);
        } else {
            this.fillStep(sample);
        }
    }

    updateModel() {
        this.model.guid = this.guid;
        this.model.effectivenessGuid = this.effectivenessGuid;
        this.model.name = this.name;
        this.model.status = this.status;
        this.model.scoreName = this.scoreName;
        this.model.scoreColor = this.scoreColor;
        this.model.commentTester = this.commentTester;
        this.model.commentReviewer = this.commentReviewer;
        this.model.documents = this.documents;

        if (this.model instanceof EvidenceSampleEditModel) {
            this.model.score = this.sampleScore;
            this.model.noEvidence = this.noEvidence;
            this.model.noEvidenceReason = this.noEvidenceReason;
            //this.model.remarks = this.remarks;
        } else {
            //step
            this.model.score = this.stepScore;
            this.model.description = this.description;
            this.model.requiredDocuments = this.requiredDocuments;
            this.testMethod = this.testMethod;
        }
    }

    private fillSample(sample: EvidenceSampleEditModel) {
        this.scoreName =
            sample.score == SampleScore.inapplicable ? "Not applicable" : sample.scoreName;
        this.sampleScore = sample.score;
        this.noEvidence = sample.noEvidence;
        this.noEvidenceReason = sample.noEvidenceReason;
        this.remarks = sample.remarks;
    }

    private fillStep(step: EvidenceTestStepEditModel) {
        this.isSample = false;
        this.scoreName =
            step.score == TestStepScore.inapplicable ? "Not applicable" : step.scoreName;
        this.stepScore = step.score;
        this.description = step.description;
        this.requiredDocuments = step.requiredDocuments;
        this.testMethod = step.testMethod;
    }
}
