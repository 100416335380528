import { AfterViewInit, Component, ViewChild, inject } from "@angular/core";
import { ContextmenuComponent } from "@app/shared/contextmenu/contextmenu.component";
import { ContextMenuItem, MenuItemBuilder } from "@app/shared/contextmenu/menu-item";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { MonitoringService } from "@app/shared/services/monitoring.service";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { copyText } from "@methods/CommonMethods";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ToastrService } from "ngx-toastr";
import { PageLookup } from "../../common/constants/pages/page-lookup";
import { Pages } from "../../common/constants/pages/Pages";
import { guid, isGuid } from "../../common/methods/uniqueMethods";
import { PageMapping } from "../../common/models/generic/TabModels/PageMapping";
import { TabModel } from "../../common/models/generic/TabModels/TabModel";
import { LocalStorageService } from "../../services/http/LocalStorageService";
import { PermissionsService } from "../../services/permissions/PermissionsService";
import { TabService } from "../../services/tabs/TabService";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";

declare var $: any;

@Component({
    selector: "cerrix-tabs",
    templateUrl: "./tab.component.html",
    styleUrls: ["./tab.component.scss"],
})
export class TabComponent implements AfterViewInit {
    private static readonly localStorageKeyCerrixTabs: string = "cerrix-tabs";
    private static readonly localStorageKeyCerrixTabsActive: string = "cerrix-tabs-active";
    private static readonly localStorageKeyCerrixTabMenuPinned: string = "cerrix-tab-menu-pinned";

    private microservicePermissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );

    public tabs: TabModel[] = [];
    public activeTab: string;
    public renderedTabs: string[] = [];

    public Overflow = false;

    @ViewChild("contextMenuCmp", { static: false })
    public contextMenuComponent: ContextmenuComponent;

    contextMenuItem: TabModel;
    public contextMenu: ContextMenuItem[];

    private tabElement: Element;
    private isFirstOpenTab = true;

    constructor(
        private pages: Pages,
        private _pageLookup: PageLookup,
        public _tabService: TabService,
        private toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _permService: PermissionsService,
        private _localStorageService: LocalStorageService,
        private _settingsDataService: SettingsDataService,
        private monitoringService: MonitoringService,
        private _menuItemBuilder: MenuItemBuilder
    ) {
        this.createContextMenu();
    }

    public ngAfterViewInit() {
        this.tabElement = document.getElementsByClassName("cerrix-tab")[0];
        this._tabService.addTabEvent.subscribe((value) => {
            this.addTab(value, true);
        });
        this._tabService.addTabFromUrlEvent.subscribe((value) => {
            this.processUrlTabModel(value);
        });

        this._tabService.storeTabChangesEvent.subscribe(() => {
            this.storeTabsSession();
            this.updateCurrentDocumentTitle();
        });

        this._tabService.closeTabByGuid.subscribe((value) => {
            const params = value.split("|");
            const tab = this.getTabByID(params[0]);
            const undoable = params.length > 1 && params[1] == "true";

            tab.close(undoable);
        });

        this._tabService.renameTabEvent.subscribe((value) => {
            const params = value.split("|");
            this.renameTab(this.getTabByID(params[0]), params[1].toString());
        });

        this._tabService.refreshTabEvent.subscribe((page) => this.refreshTab(page));

        // create Home tab
        this.createInternalTab(this.pages.Dashboard, null, false, true);

        this.processUrlTab(window.location.href);
        this.checkCookieTabs(true);
        this.addOverflowListener();

        this._tabService.sideMenuTucked =
            this._localStorageService.getItem<boolean>(
                TabComponent.localStorageKeyCerrixTabMenuPinned
            ) ?? true;
    }

    public processUrlTabModel(tab: TabModel) {
        // We should check if we got a legacy url/or a new url
        const url = tab.url;
        if (url && url.indexOf("/") >= 0) {
            const split = url.split("/");
            const tag = url.indexOf("/") === 0 ? split[1] : split[0];

            const exactName = this.pages.getExactName(tag);
            if (exactName) {
                this.processUrlTab(url, tab);
                return;
            }
        }
        this.addTab(tab, true);
    }

    public processUrlTab(url: string, tabWithBrokenUrl?: TabModel) {
        let path = ""; // window.location.pathname.toLowerCase();
        let search = "";

        if (url) {
            let parsed: URL;
            try {
                parsed = new URL(url);
            } catch (error) {
                try {
                    let root = window.location.origin;
                    if (root[root.length - 1] !== "/") {
                        root += "/";
                    }

                    parsed = new URL(root + url);
                } catch (err) {}
            }

            if (parsed) {
                path = parsed.pathname;
                search = parsed.search;
            }
        }

        if (!path) {
            setTimeout(() =>
                // Toastr will give a console error when not set in a setTimeout (combination of showing in AfterViewInit)
                // https://github.com/scttcper/ngx-toastr/issues/160
                this.toastr.error(
                    `Could not find a page with the name: "${path}"`,
                    "Page not found!",
                    {
                        tapToDismiss: true,
                    }
                )
            );
        }

        const hasValue = path.replace("/", "").trim();
        if (hasValue) {
            const tab = this.getTabFromUrl(path, search);
            if (tabWithBrokenUrl) {
                tabWithBrokenUrl.url = tab.url;
            } else {
                tabWithBrokenUrl = tab;
            }
            this.addTab(tabWithBrokenUrl, true);
        }
    }

    public getTabFromUrl(url: string, search: string): TabModel {
        let path = url.replace("//", "/");
        if (path[0] === "/") {
            path = path.substring(1);
        }

        let pathID: any;
        const fullPath = path;
        const slLoc = path.indexOf("/");
        if (slLoc >= 0) {
            const split = path.split("/");
            path = slLoc === 0 ? split[1] : split[0];
            pathID = split.length > 1 ? split[split.length - 1] : undefined;
        }

        if (path.length > 0 && path !== this.pages.Dashboard.toLowerCase()) {
            const lookup = this.pages.getExactName(path);
            if (lookup) {
                const mapping = this.getLookup(lookup);
                if (mapping) {
                    const tab: TabModel = {
                        identifier: guid(),
                        name: lookup,
                        lookupname: lookup,
                        closable: true,
                        id: pathID,
                        _fullPath: fullPath,
                    };

                    // this is for old urls. Since we dont know how to hande the params just pass them through.
                    if (mapping.Url) {
                        if (!search) {
                            search = "";
                        }
                        tab.url = mapping.Url;
                        if (tab.id) {
                            tab.url += "?id=" + tab.id;
                            if (search && search[0] === "?") {
                                search = "&" + search.substr(1, search.length);
                            }
                        }
                        tab.url += search;
                    } else if (search && search.length > 0) {
                        if (search[0] === "?") {
                            search = search.slice(1);
                        }

                        const config = {};
                        const configVariables = search.split("&").filter((x) => x.indexOf("=") > 0);
                        configVariables.forEach((cv) => {
                            const kv = cv.split("=");
                            config[kv[0]] = kv[1];
                        });

                        tab.config = config;
                    }

                    return tab;
                }
            }
        }
        if (!path) {
            return;
        }
        setTimeout(() =>
            // Toastr will give a console error when not set in a setTimeout (combination of showing in AfterViewInit)
            // https://github.com/scttcper/ngx-toastr/issues/160
            this.toastr.error(`Could not find a page with the name: "${path}"`, "Page not found!", {
                tapToDismiss: true,
            })
        );
    }

    public createInternalTab(name: string, paramID: any, closable: boolean, activate: boolean) {
        const tab: TabModel = {
            identifier: guid(),
            name,
            lookupname: name,
            closable,
            id: paramID,
        };

        this.addTab(tab, activate);
    }

    public getLookup(lookupName: string): PageMapping {
        const page = this._pageLookup[lookupName] as PageMapping;
        return page;
    }

    public addTab(tab: TabModel, activate?: boolean) {
        // Clean tab from previous session
        tab = JSON.parse(JSON.stringify(tab));

        if (tab.lookupname !== this.pages.Dashboard) {
            tab.closable = true;
        }

        this.bindDefaults(tab);

        if (tab.lookupname === "oldpage") {
            if (this.openDuplicate("name", tab)) {
                return;
            }

            this.tabs.push(tab);
            if (activate) {
                this.openTab(tab.identifier);
            }
            return;
        }

        const lookup = this.getLookup(tab.lookupname);

        // This will be called after the permissions check beneath this method declaration.
        const continueAdd = () => {
            let continueAdding = lookup.NoDuplicate !== true;
            if (!continueAdding) {
                if (
                    lookup.CheckDuplicateOnGuid === true
                        ? this.openDuplicate("id", tab)
                        : this.openDuplicate("lookupname", tab)
                ) {
                    if (lookup.IsWorkspace) {
                        this._tabService.handleWorkspaceDuplicate(tab);
                    } else if (lookup.ListenToConfigChangedEvent) {
                        this._tabService.handleConfigChangedEvent(tab);
                    }

                    return;
                } else {
                    continueAdding = true;
                }
            }

            if (continueAdding) {
                tab.comp = lookup.Component;
                if (!tab.url) {
                    tab.url = lookup.Url;
                    if (tab.url && tab.id) {
                        tab.url += "?id=" + tab.id;
                    }
                }

                if (lookup.ComponentConfig) {
                    if (!tab.config) {
                        // only override tabconfig if it is empty.
                        tab.config = JSON.parse(JSON.stringify(lookup.ComponentConfig));
                    }
                }

                tab.showLoader = !!lookup.ShowLoader;

                if (tab.url && this.openDuplicate("url", tab)) {
                    return;
                }

                if (tab.id && this.openDuplicate("id", tab)) {
                    return;
                }

                this.tabs.push(tab);
                if (tab.lookupname !== this.pages.Dashboard) {
                    this.storeTabsSession();
                }

                if (activate) {
                    this.openTab(tab.identifier);
                }
            }

            this.isOverflowing();
        };

        const handleFeatureToggleSetting = () => {
            if (lookup.FeatureToggleSetting) {
                this._settingsDataService
                    .getSetting(lookup.FeatureToggleSetting)
                    .subscribe((setting) => {
                        if (lookup.FeatureToggleCustomComponentSetter) {
                            const compToSet = lookup.FeatureToggleCustomComponentSetter(
                                setting.BoolValue
                            );

                            if (compToSet) {
                                lookup.Component = compToSet;
                                tab.comp = compToSet;
                            }
                        } else {
                            if (setting.BoolValue) {
                                lookup.Url = null;
                                tab.url = null;
                            } else {
                                lookup.Component = null;
                                tab.comp = null;
                            }
                        }
                        continueAdd();
                    });
            } else {
                continueAdd();
            }
        };

        if (lookup && (lookup.PermissionProperty || lookup.microserviceModule)) {
            if (lookup.microserviceModule) {
                if (
                    !this._permService.permissions[lookup.PermissionProperty] ||
                    !this.microservicePermissionService.hasModuleAccess(lookup.microserviceModule)
                ) {
                    this.denyAccess(tab.lookupname);
                    return;
                }
                handleFeatureToggleSetting();
            } else {
                // Check in permissions if user has access.
                const permCheck = () => {
                    let permModel: any = this._permService.permissions;
                    let hasAccess = false;

                    const checks = lookup.PermissionProperty.split(".");
                    checks.forEach((check, index) => {
                        if (index === checks.length - 1) {
                            hasAccess = permModel[check];
                        } else {
                            permModel = permModel[check];
                        }
                    });

                    if (!hasAccess) {
                        this.denyAccess(tab.lookupname);
                        return;
                    }
                    handleFeatureToggleSetting();
                };

                if (!this._permService.permissionsLoaded) {
                    this._permService.awaitedMethods.push(permCheck);
                } else {
                    permCheck();
                }
            }
        } else {
            handleFeatureToggleSetting();
        }
    }

    private denyAccess(tabName: string): void {
        this.toastr.error(`Not enough rights!`, `You have no rights to open ${tabName}`, {
            tapToDismiss: true,
        });
    }

    public openDuplicate(duplicateProp: string, tab: TabModel): boolean {
        const duplicateTabs = this.tabs.filter(
            (t) => t.lookupname === tab.lookupname && t[duplicateProp] === tab[duplicateProp]
        );
        if (duplicateTabs.length > 0) {
            this.openTab(duplicateTabs[0].identifier);
            return true;
        }

        return false;
    }

    public bindDefaults(tab: TabModel) {
        tab.parent = this.getTabByID(this.activeTab);

        // This will change 'undefined' to 'null', which will prevent a lot of console errors.
        if (!tab.menu) {
            tab.menu = null;
        }

        if (tab.url) {
            tab.refresh = () => {
                this.renderedTabs.splice(this.renderedTabs.indexOf(tab.identifier), 1);
                const temp = tab.url;
                tab.url = "";
                setTimeout(() => {
                    tab.url = temp;
                    this.openTab(tab.identifier);
                }, 100);
            };
        }

        if (!tab.close && tab.closable) {
            tab.close = (undoable?: boolean) => {
                if (undoable === null || undoable === undefined) {
                    undoable = true;
                }
                this.closeTab(tab, undoable);
                this._tabService.listeners.triggerListener(TabEventListenerType.OnClose, tab);
                this._tabService.listeners.removeListeners(tab);
            };
        }

        if (!tab.activate) {
            tab.activate = () => {
                this.openTab(tab.identifier);
                this._tabService.listeners.triggerListener(TabEventListenerType.OnFocus, tab);
            };
        }

        if (!tab.copyNameToClipboard) {
            const that = this;
            tab.copyNameToClipboard = function () {
                copyText(this.name);
                that.toastr.success("Tab name copied to clipboard", "Copy name");
            };
        }

        if (!tab.copyUrlToClipboard) {
            const that = this;
            tab.copyUrlToClipboard = function () {
                let url = window.location.origin;
                if (!this.lookupname) {
                    return;
                }

                const lookupKey = that.pages.getReversed(this.lookupname);
                if (!lookupKey) {
                    return;
                }

                url += "/" + lookupKey;

                if (this.id && (!isNaN(+this.id) || isGuid(this.id))) {
                    url += "/" + this.id;
                }

                if (this.config) {
                    const keys = Object.getOwnPropertyNames(this.config);
                    const query = keys.map((k) => `${k}=${this.config[k]}`).join("&");
                    url += "?" + query;
                }

                copyText(url.toLowerCase());
                that.toastr.success("Url copied to clipboard", "Copy URL");
            };
        }
    }

    //#region TabManagement

    public mouseClick(event: MouseEvent, tab: TabModel) {
        // This indicates a click on the close button, so that button has its own implementation.
        if (event.button === 0 && $(event.target).is("i")) {
            return;
        }

        switch (event.button) {
            case 0: // Main (left) button
                tab.activate();
                break;
            case 1: // Middle (scroll) button
                if (tab.closable) {
                    tab.close(true);
                }
                break;
        }

        this.isOverflowing();
    }

    public openTab(identifier: string) {
        const tab = this.tabs.filter((t) => t.identifier === identifier)[0];
        if (!tab) {
            return;
        }

        if (this.renderedTabs.indexOf(identifier) < 0) {
            this.renderedTabs.push(identifier);
            if (tab.url && tab.url.length > 0) {
                tab.showLoader = true;
                const loadingPrompt = this._promptService.loader(
                    "Loading " + (tab.name ? tab.name : "")
                );

                const interval = setInterval(() => {
                    const iframe = $(`#iframe-${identifier}`)[0];
                    const document = iframe.contentDocument || iframe.contentWindow.document;
                    if (document && document.body) {
                        const body = $.trim(document.body.innerHTML).length;
                        if (body > 0) {
                            if (this.isIframeDuplicateOfParent(tab, document)) {
                                this.closeTab(tab, false);
                                this.toastr.error(
                                    "The selected page is not available anymore.",
                                    "Page not found!",
                                    { tapToDismiss: true }
                                );
                            }

                            loadingPrompt.close();
                            tab.showLoader = false;

                            clearInterval(interval);

                            const outerRef = this;
                            $(iframe).on("load", function () {
                                // See if the iframe body contains an app-root selector (only loaded in with the application)
                                const isRoot =
                                    $.trim(this.contentDocument.body).length === 0 ||
                                    $(this.contentDocument.body).find("app-root").length > 0;
                                if (isRoot) {
                                    // Document is redirected to root, so close tab and redirect to home tab.
                                    outerRef.closeTab(tab, false);
                                }
                            });
                        }
                    }
                }, 1000);
            }
        }

        const oldTab = this.getTabByID(this.activeTab);
        this.activeTab = identifier;

        this._tabService.listeners.triggerListener(TabEventListenerType.OnBlur, oldTab);
        this._tabService.listeners.triggerListener(TabEventListenerType.OnFocus, tab);

        this._tabService.activeTab = tab;
        this.updateDocumentTitle(tab.name);
        this.logTabView(tab);

        // Do not store active tab on page load to keep previous session
        if (this.isFirstOpenTab) {
            this.isFirstOpenTab = false;
        } else {
            this._localStorageService.setUserItem(
                TabComponent.localStorageKeyCerrixTabsActive,
                identifier
            );
        }
    }

    public renameTab(tab: TabModel, newTabName: string) {
        tab.name = newTabName;
        return tab;
    }

    public refreshTab(page: string) {
        const tab = this.tabs.find((t) => t.lookupname === page);
        if (tab) {
            tab.refresh();
        }
    }

    public closeTab(tab: TabModel, allowUndo?: boolean) {
        const undoIndex = this.tabs.indexOf(tab);
        if (undoIndex > 0) {
            if (tab.beforeClose != null && !tab.beforeClose()) {
                return;
            }

            const continueClose = () => {
                const undoTab = this.tabs.splice(undoIndex, 1)[0];
                const renderedIndex = this.renderedTabs.indexOf(undoTab.identifier);
                if (renderedIndex >= 0) {
                    this.renderedTabs.splice(renderedIndex, 1);
                }

                if (tab.identifier === this.activeTab) {
                    this.openTab(this.tabs[undoIndex - 1].identifier);
                }

                this.storeTabsSession();

                if (tab.afterClose != null) {
                    tab.afterClose();
                }

                if (allowUndo) {
                    const toast = this.toastr.info(
                        `Click here to reopen '${undoTab.name}'`,
                        "Tab closed",
                        {
                            progressAnimation: "decreasing",
                            timeOut: 5000,
                            closeButton: true,
                        }
                    );
                    toast.onTap.subscribe(() => {
                        this.addTab(undoTab);
                        this.tabs.splice(undoIndex, 0, this.tabs.pop());
                        this.openTab(undoTab.identifier);
                        this.storeTabsSession();
                    });
                }
            };

            const lookup = this.getLookup(tab.lookupname);
            if (lookup && lookup.ShowWarningOnClose) {
                // check permission to show message
                if (
                    !lookup.ShowWarningPermissionProperty ||
                    this._permService.permissions[lookup.ShowWarningPermissionProperty]
                ) {
                    this._promptService
                        .confirm(
                            "Close tab",
                            tab.lookupname +
                                " will be closed. All changes will be discarded. Continue?"
                        )
                        .onConfirm()
                        .subscribe(() => {
                            continueClose();
                        });
                }
            } else {
                continueClose();
            }
        }
    }

    public closeTabs() {
        this._promptService
            .confirm("Close all tabs", "Close all open tabs?")
            .onConfirm()
            .subscribe(() => {
                // Close closable tabs
                const closedTabs: TabModel[] = [];
                for (let i = this.tabs.length - 1; i >= 0; i--) {
                    const tab = this.tabs[i];
                    if (tab.closable) {
                        closedTabs.splice(0, 0, tab); // We are reversing through the tabs, so add them in reversed order in the list.
                        this.closeTab(tab, false);
                    }
                }

                const toast = this.toastr.info(
                    "Click here to reopen closed tabs",
                    "All tabs closed",
                    {
                        progressAnimation: "decreasing",
                        timeOut: 5000,
                        closeButton: true,
                    }
                );
                toast.onTap.subscribe(() => {
                    // On tab reopen closed tabs
                    this.reopenTabsSession(closedTabs, this.tabs[0].identifier);
                });

                this.isOverflowing();
            });
    }

    public getTabByID(id: string): TabModel {
        return this.tabs.find((t) => t.identifier === id);
    }

    //#endregion

    //#region Side Menu

    public sideMenuTuckToggle() {
        this._tabService.sideMenuTucked = !this._tabService.sideMenuTucked;

        this._localStorageService.setUserItem(
            TabComponent.localStorageKeyCerrixTabMenuPinned,
            this._tabService.sideMenuTucked
        );
    }

    //#endregion

    //#region Tabs session

    public checkCookieTabs(saveNewTabs: boolean) {
        const tabs = this._localStorageService.getItem<TabModel[]>(
            TabComponent.localStorageKeyCerrixTabs
        );
        if (
            tabs &&
            tabs.length > 1 &&
            tabs[0].name.toLowerCase() === this.pages.Dashboard.toLowerCase()
        ) {
            const activeTab = this._localStorageService.getItem<string>(
                TabComponent.localStorageKeyCerrixTabsActive
            );

            setTimeout(() =>
                // Toastr will give a console error when not set in a setTimeout (combination of showing in AfterViewInit)
                // https://github.com/scttcper/ngx-toastr/issues/160
                this.toastr
                    .info("Continue where you left off?", "", {
                        progressAnimation: "decreasing",
                        timeOut: 10000,
                        extendedTimeOut: 5000,
                        closeButton: true,
                    })
                    .onTap.subscribe(() => {
                        this.reopenTabsSession(tabs, activeTab);

                        if (saveNewTabs) {
                            this.storeTabsSession();
                        }
                    })
            );
        } else {
            this.storeTabsSession();
        }
    }

    public reopenTabsSession(tabs: TabModel[], activeTab?: string) {
        tabs.forEach((tab) => {
            this.addTab(tab, tabs.length === 1 || (activeTab && activeTab === tab.identifier));
        });
    }

    public storeTabsSession() {
        const copies = this.tabs.map(
            (t) =>
                <TabModel>{
                    identifier: t.identifier,
                    name: t.name,
                    lookupname: t.lookupname,
                    id: t.id,
                    url: t.url,
                    config: t.config ? JSON.parse(JSON.stringify(t.config)) : null,
                }
        );

        const tabs = copies || "";
        const activeTab = this.activeTab;

        this._localStorageService.setUserItem(TabComponent.localStorageKeyCerrixTabs, tabs);
        this._localStorageService.setUserItem(
            TabComponent.localStorageKeyCerrixTabsActive,
            activeTab
        );
    }

    //#endregion

    public canCloseTabs(): boolean {
        return this.tabs.every((t) => t.beforeClose == null || t.beforeClose(true));
    }

    //#region ScrollTab
    public scrollTab(value) {
        this.tabElement.scrollLeft += value;
    }

    public isOverflowing() {
        setTimeout(() => {
            this.Overflow = this.tabElement.scrollWidth > this.tabElement.clientWidth;
        }, 0);
    }

    public addOverflowListener() {
        window.addEventListener(
            "resize",
            (e) => {
                this.isOverflowing();
            },
            false
        );
    }

    // Check if the opened iFrame is the same as the parent
    // which is the case if the iFrame redirected the user back to start
    public isIframeDuplicateOfParent(tab: TabModel, document: any): boolean {
        const doc = document.body.ownerDocument;
        const iframeWindow = doc.defaultView || doc.parentWindow;
        if (
            iframeWindow &&
            iframeWindow.location &&
            top &&
            top.location &&
            iframeWindow.location.pathname == "/" &&
            top.location.pathname == "/"
        ) {
            return true;
        }

        return false;
    }
    //#endregion

    public isTabClosable = (item: TabModel): boolean => {
        return item.closable;
    };

    private updateCurrentDocumentTitle() {
        const currentTab = this.getTabByID(this.activeTab);
        if (currentTab) {
            this.updateDocumentTitle(currentTab.name);
        }
    }

    private updateDocumentTitle(title: string) {
        let newTitle = "CERRIX";
        if (title) {
            newTitle += ` - ${title}`;
        }

        document.title = newTitle;
    }

    private logTabView(tab: TabModel) {
        let url = tab.url;
        if (!url && tab.lookupname) {
            url = this.pages.getReversed(tab.lookupname);
            if (url && tab.id) {
                url += `/${tab.id}`;
            }
        }

        if (url && !url.startsWith(window.location.origin)) {
            url = `${window.location.origin}/${url}`.replace(/([^:]\/)\/+/g, "$1");
        }

        this.monitoringService.logPageView(tab.lookupname, url);
    }

    public onContextMenu($event: MouseEvent, item: TabModel): void {
        this.contextMenuItem = item;
        this.contextMenuComponent.openMenuAtMousePosition($event);
        $event.stopPropagation();
    }

    public trackByIdentifier(index: number, item: TabModel): string {
        return item.identifier;
    }

    private createContextMenu() {
        this.contextMenu = this._menuItemBuilder
            .appendItem("Reload tab", () => this.contextMenuItem.refresh(), null, "fas fa-sync")
            .appendItem(
                "Copy name",
                () => this.contextMenuItem.copyNameToClipboard(),
                null,
                "fas fa-copy fa-fw"
            )
            .appendItem(
                "Copy URL",
                () => this.contextMenuItem.copyUrlToClipboard(),
                null,
                "fas fa-link fa-fw"
            )
            .appendDivider()
            .appendItem(
                "Close tab",
                () => this.contextMenuItem.close(),
                null,
                "fas fa-times fa-fw",
                () => this.contextMenuItem && this.contextMenuItem.closable
            )
            .appendItem(
                "Close all",
                () => this.closeTabs(),
                null,
                "fas fa-times-circle fa-fw",
                () => this.tabs.length > 2
            )
            .build();
    }
}
