<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<ng-template #customMenu>
    <div class="node m-3">
        <i class="fa-fw fas fa-trash"></i>
        <b>Deleted Items</b>
    </div>
    <ng-container *ngFor="let node of menuItems">
        <ng-container
            *ngTemplateOutlet="nodeTemplate; context: { node: node, parent: null, depth: 0 }"
        >
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #nodeTemplate let-node="node" let-parent="parent" let-depth="depth">
    <div class="node" [class.selected]="selectedMenuItem && node === selectedMenuItem">
        <div class="node-detail" [title]="node.name" [attr.data-uiid]="'tabmenu-item-' + node.name">
            <div class="node-child-toggler" data-uiid="toggle-node">
                <i
                    class="fas fa-angle-{{ node.isExpanded ? 'down' : 'right' }}"
                    (click)="node.isExpanded = !node.isExpanded"
                    *ngIf="node.children.length > 0"
                ></i>
            </div>
            <div class="node-icon" (click)="nodeClicked(node)">
                <cerrix-icon-viewer
                    [icon]="node.icon"
                    appendClass="fa-fw"
                    [defaultColor]="'#FFFFFF'"
                ></cerrix-icon-viewer>
            </div>
            <div
                class="node-name"
                (click)="nodeClicked(node)"
                [attr.data-uiid]="
                    node.children.length > 0 ? '' : 'tabmenu-item-' + node.name + '-node'
                "
            >
                {{ node.name }}
            </div>
        </div>
        <div class="children" *ngIf="node.isExpanded && node.children.length > 0">
            <ng-container *ngFor="let child of node.children">
                <ng-container
                    *ngTemplateOutlet="
                        nodeTemplate;
                        context: { node: child, parent: node, depth: depth + 1 }
                    "
                >
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>

<div class="fancy-loader" *ngIf="!configLoaded"></div>
<generic-list-manager
    [config]="config"
    *ngIf="configLoaded"
    [skipReloadConfirm]="true"
></generic-list-manager>
