<div class="detail-page-card">
    <div class="detail-page-card-title" *ngIf="!config.hideHeader">{{ config.name }}</div>
    <div class="detail-page-card-actions">
        <div
            *ngIf="
                (config.allowAdd && !config.hideAddButton) ||
                (config.allowDelete && !config.hideDeleteButton)
            "
        >
            <button
                class="btn btn-raised"
                (click)="addRow()"
                *ngIf="config.allowAdd && !config.hideAddButton"
            >
                <i class="fas fa-plus-circle"></i>
                <span>Add</span>
            </button>
            <button
                class="btn btn-raised red"
                (click)="deleteRow(this.activeRow)"
                *ngIf="config.allowDelete && !config.hideDeleteButton"
                [disabled]="!activeRow"
            >
                <i class="fas fa-trash"></i>
                <span>Delete</span>
            </button>
        </div>
        <div *ngIf="config.dataMethod && !config.hideRefreshButton">
            <button
                class="btn btn-raised green"
                (click)="loadPage()"
                *ngIf="config.dataMethod && !config.hideRefreshButton"
            >
                <i class="fas fa-sync"></i>
                <span>Reload</span>
            </button>
        </div>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm" *ngIf="!ready">
        <div class="fancy-loader submodule"></div>
    </div>

    <div
        class="detail-page-card-body padded"
        *ngIf="ready && (!tableData || tableData.length == 0)"
    >
        <small *ngIf="config.emptyMessage">{{ config.emptyMessage }}</small>
        <small *ngIf="!config.emptyMessage">No {{ config.name | lowercase }} available.</small>
        <small *ngIf="!config.emptyMessage && config.allowAdd"
            >&nbsp;<b>Click add</b> to create a new one.</small
        >
    </div>

    <div
        class="detail-page-card-body overflow-auto"
        *ngIf="ready && groups && groups.length > 0"
        [ngStyle]="{ maxHeight: config.maxHeight ? config.maxHeight : 'auto' }"
    >
        <table class="table-cerrix rounded hoverable centered striped p-0">
            <thead>
                <tr>
                    <th
                        class="sticky"
                        *ngFor="let header of headers"
                        [class.text-start]="config.cellConfig[header].alignLeft"
                    >
                        {{ prettyHeaders[header] }}
                    </th>
                    <th *ngIf="config.overviewRowActions && config.overviewRowActions.length > 0">
                        {{ config.overviewRowActionsName }}
                    </th>
                </tr>
            </thead>
            <tbody *ngFor="let group of groups">
                <tr
                    class="summary-row"
                    (click)="group.isExpanded = !group.isExpanded"
                    *ngIf="group.cellSummaries.length > 0"
                >
                    <td
                        *ngFor="let cellS of group.cellSummaries; let i = index"
                        [class.text-start]="config.cellConfig[cellS.header].alignLeft"
                    >
                        <i
                            class="far fa-{{
                                group.isExpanded ? 'minus' : 'plus'
                            }}-square group-expander"
                            *ngIf="i == 0"
                        ></i>

                        <b *ngIf="!cellS.color">{{ cellS.summary }}</b>

                        <div *ngIf="cellS.color">
                            <div
                                [style.background-color]="cellS.color"
                                class="color-indicator"
                            ></div>
                            <b> {{ cellS.summary }}</b>
                        </div>
                    </td>
                    <td
                        *ngIf="config.overviewRowActions && config.overviewRowActions.length > 0"
                    ></td>
                </tr>
                <tr
                    class="hover-row"
                    [ngClass]="{ highlighted: activeRow && activeRow[idProp] == row[idProp] }"
                    [hidden]="!group.isExpanded"
                    *ngFor="let row of group.items"
                    (click)="triggerClick(row)"
                    (dblclick)="triggerDblClick(row)"
                >
                    <td
                        *ngFor="let header of headers"
                        class="text-truncate"
                        style="max-width: 400px"
                        [class.text-start]="config.cellConfig[header].alignLeft"
                    >
                        <workspace-cell
                            [configs]="config.rendererConfig"
                            [row]="row"
                            [header]="header"
                            [stretched]="false"
                        >
                        </workspace-cell>
                    </td>
                    <td *ngIf="config.overviewRowActions && config.overviewRowActions.length > 0">
                        <i
                            *ngFor="let btnConfig of config.overviewRowActions"
                            [class]="btnConfig.icon + ' ms-2 me-2'"
                            [matTooltip]="btnConfig.tooltip"
                            (click)="btnConfig.clickEvent(row)"
                            [hidden]="btnConfig.showProperty && !row[btnConfig.showProperty]"
                        ></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<generic-form-editor
    [config]="config"
    [additionalData]="additionalData"
    (change)="applyRowChange($event)"
    *ngIf="ready"
    #editorCmp
>
</generic-form-editor>
