export enum FilterType {
    Organization,
    BusinessDimensions,
    FrameworkDimensions,
    Period,

    DataStructures,
    DataSubjects,

    RiskCatalogue,
}
