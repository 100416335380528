import { Component, HostListener, Input, OnInit } from "@angular/core";
import { PopupService } from "@app/shared/services/popup/popup.service";

@Component({
    selector: "cerrix-editor-viewer",
    templateUrl: "./cerrix-editor-viewer.component.html",
    styleUrls: ["./cerrix-editor-viewer.component.scss"],
})
export class CerrixEditorViewerComponent implements OnInit {
    @Input() value: string;

    embedHtml: string;
    ready = false;

    constructor(private _popupService: PopupService) {}

    ngOnInit() {
        if (this.value) {
            this.embedHtml = this.updatePdfSelectors(this.value);
            this.ready = true;
        }
    }

    @HostListener("click", ["$event"])
    onClick(ev) {
        const pdfIdAttribute = ev.target.attributes["data-pdfid"];
        if (pdfIdAttribute) {
            const url = "/api/documents/" + pdfIdAttribute.value + "/pdf";
            this._popupService.forceOpenInNewWindow(url);
        }
    }

    // This methods updates old selectors so they work like new ones.
    private updatePdfSelectors(html: string) {
        if (!html) {
            return html;
        }

        try {
            html = html ? decodeURIComponent(html) : "";
        } catch (error) {
            // If we got an error from decodeURIComponent we will try to use the unescape method for older records.
            html = unescape(html);
        }

        if (html) {
            const oldLinkKey = "/documentviewer?documentId=";
            const guidKeyLength = 36;

            let loop = true;

            // This loop fixes links pre changes.
            while (loop) {
                const i = html.indexOf(oldLinkKey);
                if (i >= 0) {
                    const foundGuid = html.substring(
                        i + oldLinkKey.length,
                        i + oldLinkKey.length + guidKeyLength
                    );

                    const oldLink = oldLinkKey + foundGuid;
                    const newLink = `/pdf/${foundGuid}`;

                    html = html.replace(oldLink, newLink);
                } else {
                    loop = false;
                }
            }

            // This loop will change hrefs to click events so they are opened as a tab.
            loop = true;
            const linkKey = `href="/pdf/`;
            while (loop) {
                const i = html.indexOf(linkKey);
                if (i >= 0) {
                    const foundGuid = html.substring(
                        i + linkKey.length,
                        i + linkKey.length + guidKeyLength
                    );
                    const link = linkKey + foundGuid;
                    const action = `data-pdfid="${foundGuid}"`;

                    html = html.replace(link, action);
                } else {
                    loop = false;
                }
            }
        }

        return html;
    }
}
