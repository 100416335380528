import { mxClient } from "../mxClient";
import { mxgraph as mxType } from "mxgraph-factory";
import { ProcessEditorHelper } from "../helpers/ProcessEditorHelper";
import { ProcessCellAttribute } from "@models/processeditor/DiagramData";

export class CerrixVisioCellCodec extends mxClient.mxObjectCodec {
    constructor() {
        super(
            new mxClient.mxCell(),
            ["children", "edges", "overlays", "mxTransient"],
            ["parent", "source", "target"]
        );
    }

    afterDecode(dec: any, node: Element, obj: mxType.mxCell) {
        if (obj.value) {
            this.extractStyleAndText(obj);
        }

        if (obj.edge) {
            obj.style = mxClient.mxUtils.setStyle(
                obj.style,
                mxClient.mxConstants.STYLE_SHAPE,
                null
            );

            if (obj.geometry.points && obj.geometry.points.length > 0) {
                obj.style = mxClient.mxUtils.setStyle(
                    obj.style,
                    mxClient.mxConstants.STYLE_EDGE,
                    mxClient.mxConstants.EDGESTYLE_SEGMENT
                );
            }
        }

        const extra = ProcessEditorHelper.createExtraAttributes(<ProcessCellAttribute>{
            shapeName: obj.getShape(),
            label: obj.value,
            isEdge: obj.edge,
        });
        obj.value = extra;

        return obj;
    }

    private extractStyleAndText(obj) {
        // Ensure there is a root node
        obj.value = `<root>${obj.value}</root>`;

        const textDoc = new DOMParser().parseFromString(obj.value, "text/xml");

        const fontElements = textDoc.getElementsByTagName("font");

        Array.from(fontElements).forEach((fontElement, index) => {
            if (index === 0) {
                const style = fontElement.getAttribute("style");
                if (style) {
                    obj.style += obj.style.endsWith(";")
                        ? CerrixVisioCellCodec.cssDeclarationsToMxStyle(style)
                        : ";" + CerrixVisioCellCodec.cssDeclarationsToMxStyle(style);
                }

                obj.value = fontElement.innerHTML.replace(/(<\s*br\s*\/?\s*>)/g, "\n");
            } else {
                obj.value += fontElement.innerHTML.replace(/(<\s*br\s*\/?\s*>)/g, "\n");
            }
        });
    }

    private static cssDeclarationsToMxStyle(css: string) {
        const keyValuePairs = css.split(";");
        return (
            keyValuePairs
                .map((kv) => kv.split(":"))
                .map(([key, value]) => {
                    const mxConstant = CerrixVisioCellCodec.cssPropertyToMxConstant(key);

                    if (!mxConstant) {
                        return null;
                    }

                    return `${mxConstant}=${CerrixVisioCellCodec.cssValueToMxValue(
                        mxConstant,
                        value
                    )}`;
                })
                .filter(Boolean)
                .join(";") + ";"
        );
    }

    private static cssPropertyToMxConstant(property: string) {
        const mxConstants = mxClient.mxConstants;
        switch (property) {
            case "color":
                return mxConstants.STYLE_FONTCOLOR;
            case "font-family":
                return mxConstants.STYLE_FONTFAMILY;
            case "font-size":
                return mxConstants.STYLE_FONTSIZE;
            case "letter-spacing":
                return mxConstants.STYLE_SPACING;
            case "opacity":
                return mxConstants.STYLE_TEXT_OPACITY;
            case "direction":
                return mxConstants.STYLE_DIRECTION;
            default:
                return "";
        }
    }

    private static cssValueToMxValue(property: string, value: string): string {
        const mxConstants = mxClient.mxConstants;
        switch (property) {
            case mxConstants.STYLE_FONTSIZE:
                // Remove unit eg. px
                return parseInt(value, 10).toString();
            case mxConstants.STYLE_TEXT_OPACITY:
                return (+value * 100).toString();
            default:
                return value;
        }
    }

    isCellCodec() {
        return true;
    }

    /**
     * Overidden to disable conversion of value to number.
     */
    isNumericAttribute(dec, attr, obj) {
        return attr.nodeName !== "value" && super.isNumericAttribute(dec, attr, obj);
    }

    /**
     * Function: isExcluded
     *
     * Excludes user objects that are XML nodes.
     */

    isExcluded(obj, attr, value, isWrite) {
        return (
            super.isExcluded(obj, attr, value, isWrite) ||
            (isWrite &&
                attr === "value" &&
                value.nodeType === mxClient.mxConstants.NODETYPE_ELEMENT)
        );
    }

    /**
     * Function: afterEncode
     *
     * Encodes an <mxCell> and wraps the XML up inside the
     * XML of the user object (inversion).
     */
    afterEncode(enc, obj, node) {
        if (obj.value != null && obj.value.nodeType === mxClient.mxConstants.NODETYPE_ELEMENT) {
            // Wraps the graphical annotation up in the user object (inversion)
            // by putting the result of the default encoding into a clone of the
            // user object (node type 1) and returning this cloned user object.
            const tmp = node;
            node = mxClient.mxUtils.importNode(enc.document, obj.value, true);
            node.appendChild(tmp);

            // Moves the id attribute to the outermost XML node, namely the
            // node which denotes the object boundaries in the file.
            const id = tmp.getAttribute("id");
            node.setAttribute("id", id);
            tmp.removeAttribute("id");
        }

        return node;
    }

    /**
     * Function: beforeDecode
     *
     * Decodes an <mxCell> and uses the enclosing XML node as
     * the user object for the cell (inversion).
     */
    beforeDecode(dec, node, obj) {
        let inner = node.cloneNode(true);
        const classname = this.getName();

        if (node.nodeName != classname) {
            // Passes the inner graphical annotation node to the
            // object codec for further processing of the cell.
            const tmp = node.getElementsByTagName(classname)[0];

            if (tmp != null && tmp.parentNode === node) {
                mxClient.mxUtils.removeWhitespace(tmp, true);
                mxClient.mxUtils.removeWhitespace(tmp, false);
                tmp.parentNode.removeChild(tmp);
                inner = tmp;
            } else {
                inner = null;
            }

            // Creates the user object out of the XML node
            obj.value = node.cloneNode(true);
            const id = obj.value.getAttribute("id");

            if (id != null) {
                obj.setId(id);
                obj.value.removeAttribute("id");
            }
        } else {
            // Uses ID from XML file as ID for cell in model
            obj.setId(node.getAttribute("id"));
        }

        // Preprocesses and removes all Id-references in order to use the
        // correct encoder (this) for the known references to cells (all).
        if (inner != null) {
            for (let i = 0; i < (this as any).idrefs.length; i++) {
                const attr = (this as any).idrefs[i];
                const ref = inner.getAttribute(attr);

                if (ref != null) {
                    inner.removeAttribute(attr);
                    let object = dec.objects[ref] || dec.lookup(ref);

                    if (object == null) {
                        // Needs to decode forward reference
                        const element = dec.getElementById(ref);

                        if (element != null) {
                            const decoder =
                                mxClient.mxCodecRegistry.codecs[element.nodeName] || this;
                            object = decoder.decode(dec, element);
                        }
                    }

                    obj[attr] = object;
                }
            }
        }

        return inner;
    }
}
