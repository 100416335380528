<div
    *ngIf="
        !localStorageService.isInitialized ||
        !localizationService.isInitialized ||
        !permService.permissionsLoaded
    "
>
    <div style="position: absolute; left: 61px; top: 12px">
        <img src="/assets/img/logo.svg" width="200" height="50" />
    </div>
    <div class="fancy-loader"></div>
</div>

<div
    *ngIf="
        localStorageService.isInitialized &&
        localizationService.isInitialized &&
        permService.permissionsLoaded
    "
    fileDrop
>
    <navbar *ngIf="fullApp"></navbar>
    <div class="mainapp" *ngIf="fullApp">
        <cerrix-tabs></cerrix-tabs>
    </div>
</div>

<ng-template #singleComponent></ng-template>

<!-- If user loses session, this will kick in and show a modal so user can login without losing any data. -->
<login-modal></login-modal>
