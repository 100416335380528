import { CommonModule } from "@angular/common";
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";

import { IncidentTypesService } from "../../incident/services/incident-types.service";
import { IncidentTypeEditModel } from "../../incident/models/incident-type-edit.model";
import { MatButtonModule } from "@angular/material/button";
import {
    CerrixButtonComponent,
    CerrixDialogService,
    CerrixFieldWrapperComponent,
    CerrixInputComponent,
    CerrixTextareaComponent,
    CerrixTitleComponent,
    CerrixValidators,
} from "@cerrix/components";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList } from "@angular/cdk/drag-drop";
import { IncidentTypeSectionModel } from "@app/incident/models/incident-type-section.model";
import { MatMenuModule } from "@angular/material/menu";
import { CustomFieldModel } from "@app/incident/models/custom-field.model";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { CustomFieldConfigComponent } from "../custom-field-config/custom-field-config.component";
import { ToastrService } from "ngx-toastr";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FieldType } from "@app/preset/models/field-type.enum";
import { BaseStandingDataEditComponent } from "../interfaces/base-standing-data-edit.component";
import { MatTabsModule } from "@angular/material/tabs";

@Component({
    selector: "incident-type-edit",
    templateUrl: "./incident-type-edit.component.html",
    styleUrls: ["./incident-type-edit.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        CerrixTitleComponent,
        CerrixButtonComponent,
        MatGridListModule,
        MatCardModule,
        CerrixInputComponent,
        CerrixTextareaComponent,
        CerrixFieldWrapperComponent,
        CdkDrag,
        CdkDragHandle,
        CdkDropList,
        MatMenuModule,
        MatDialogModule,
        MatTabsModule,
    ],
    providers: [],
})
export class IncidentTypeEditComponent implements OnInit, BaseStandingDataEditComponent {
    @Input() public id: string;

    @Output() public onEntityUpdated = new EventEmitter<string>();

    private readonly dialog = inject(MatDialog);
    private readonly incidentTypesService = inject(IncidentTypesService);
    private readonly toastrService = inject(ToastrService);
    private readonly destroyRef = inject(DestroyRef);
    protected readonly formBuilder: FormBuilder = inject(FormBuilder);
    private dialogService = inject(CerrixDialogService);

    protected form: FormGroup = undefined;
    protected predefined: boolean = true;
    protected readonly fullWidthCustomTypes: FieldType[] = [
        FieldType.TextBox,
        FieldType.FinancialImpact,
    ];

    public get hasChanges(): boolean {
        return this.form.dirty;
    }

    protected get sectionsForm(): FormArray {
        return this.form.get("incidentTypeSections") as AbstractControl as FormArray;
    }

    protected get detailCustomFieldsForm(): FormArray {
        return this.form.get("customFields") as AbstractControl as FormArray;
    }

    public ngOnInit() {
        this.loadIncidentType();
    }

    protected loading: boolean = false;

    protected showSavedButton = false;
    private savedButtonTimeout = undefined;

    protected onSave() {
        if (this.hasChanges) {
            this.validateAllFormFields(this.form);

            if (this.form.valid) {
                this.loading = true;

                if (this.form.value.id) {
                    this.incidentTypesService
                        .editIncidentType(this.getFormValues())
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                            next: () => {
                                this.loading = false;

                                this.onEntityUpdated.emit(this.form.value.id);
                                this.loadIncidentType();

                                this.displaySavedMessages();
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                } else {
                    this.incidentTypesService
                        .addIncidentType(this.getFormValues())
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                            next: (id) => {
                                this.loading = false;

                                this.id = id;
                                this.onEntityUpdated.emit(id);
                                this.loadIncidentType();

                                this.displaySavedMessages();
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                }
            }
        }
    }

    protected onDelete() {
        this.loading = true;
        this.incidentTypesService
            .deleteIncidentType(this.form.value.id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.loading = false;

                    this.toastMessage("Deleted");

                    this.onEntityUpdated.emit(null);
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    private displaySavedMessages() {
        this.showSavedButton = true;
        this.savedButtonTimeout = setTimeout(() => {
            this.savedButtonTimeout = undefined;
            this.showSavedButton = false;
        }, 2500);

        this.toastMessage("Saved");
    }

    private toastMessage(message: string) {
        this.toastrService.success(
            `<span class="toast-html-message">${message}<span class="material-symbols-outlined">check</span></span>`,
            undefined,
            {
                enableHtml: true,
            }
        );
    }

    private loadIncidentType() {
        if (this.savedButtonTimeout) {
            clearTimeout(this.savedButtonTimeout);
            this.showSavedButton = false;
        }

        this.resetForm();

        if (this.id) {
            this.loading = true;

            this.incidentTypesService
                .getIncidentType(this.id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((incidentType) => {
                    this.predefined = incidentType.isPredefined;

                    this.form.patchValue(
                        {
                            id: incidentType.id,
                            name: incidentType.name,
                            emailAddresses: incidentType.emailAddresses,
                        },
                        { emitEvent: false }
                    );

                    incidentType.customFields?.forEach((cf) =>
                        this.detailCustomFieldsForm.push(this.getCustomFieldFormGroup(cf))
                    );
                    incidentType.incidentTypeSections.forEach((its) => this.addSection(its));

                    this.loading = false;
                });
        } else {
            this.predefined = false;
        }
    }

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            formGroup.get(field)?.markAsTouched({ onlySelf: true });
        });
    }

    private getFormValues(): IncidentTypeEditModel {
        return {
            id: this.form.value.id,
            name: this.form.value.name,
            isPredefined: false,
            emailAddresses: this.form.value.emailAddresses,
            customFields: this.setCustomFieldsOrder(this.form),
            incidentTypeSections: this.setSectionsOrder(),
        };
    }

    protected addSection(
        incidentTypeSectionModel: IncidentTypeSectionModel | undefined = undefined
    ): void {
        let order = this.sectionsForm.controls.length + 1;
        let name = "";
        let id = undefined;

        if (incidentTypeSectionModel !== undefined) {
            id = incidentTypeSectionModel.id;
            name = incidentTypeSectionModel.name;
            order = incidentTypeSectionModel.order;
        }

        this.sectionsForm.push(this.getSectionFormGroup(incidentTypeSectionModel, order), {
            emitEvent: incidentTypeSectionModel === undefined,
        });
    }

    private getSectionFormGroup(
        incidentTypeSectionModel: IncidentTypeSectionModel | undefined = undefined,
        defualtOrder: number
    ): FormGroup {
        let sectionForm = this.formBuilder.group({
            id: [incidentTypeSectionModel?.id ?? undefined],
            name: [incidentTypeSectionModel?.name ?? "", Validators.required],
            order: [incidentTypeSectionModel?.order ?? defualtOrder, Validators.required],
            customFields: this.formBuilder.array<CustomFieldModel>([]),
        });

        for (let customField of incidentTypeSectionModel?.customFields ?? []) {
            (sectionForm.get("customFields") as FormArray).push(
                this.getCustomFieldFormGroup(customField, customField.order)
            );
        }

        return sectionForm;
    }

    protected sectionReorder(event: CdkDragDrop<IncidentTypeSectionModel>): void {
        const control = this.sectionsForm.at(event.previousIndex);

        this.sectionsForm.removeAt(event.previousIndex);
        this.sectionsForm.insert(event.currentIndex, control);

        this.form.markAsDirty();
    }

    protected duplicateSection(sectionFormIndex: number): void {
        const control = this.sectionsForm.at(sectionFormIndex);

        const newSection: IncidentTypeSectionModel = {
            id: undefined,
            name: control.value.name,
            order: sectionFormIndex + 1,
        };

        this.sectionsForm.insert(
            sectionFormIndex,
            this.getSectionFormGroup(newSection, sectionFormIndex)
        );
        this.sectionsForm.value.incidentTypeSections = this.setSectionsOrder();

        this.form.markAsDirty();
    }

    protected deleteSection(sectionFormIndex: number): void {
        const control = this.sectionsForm.at(sectionFormIndex);

        this.sectionsForm.removeAt(sectionFormIndex);
        this.sectionsForm.value.incidentTypeSections = this.setSectionsOrder();

        if (control.value.id !== undefined && control.value.id !== null) {
            this.form.markAsDirty();
        }
    }

    protected deleteCustomField(fieldIndex: number, sectionFormIndex?: number): void {
        let control: AbstractControl;
        let sectionControl: FormArray<any>;
        if (sectionFormIndex !== undefined && sectionFormIndex !== null) {
            sectionControl = this.sectionsForm
                .at(sectionFormIndex)
                .get("customFields") as FormArray;
            control = sectionControl.at(fieldIndex);
        } else {
            control = this.detailCustomFieldsForm.at(fieldIndex);
        }

        this.dialogService.openDialog({
            data: {
                promptUiid: "delete-customfields-dialog-uiid",
                title: `Delete custom field`,
                message: `Are you sure you want to delete ${control.value.name}?`,
                icon: "warning",
                iconClass: "material-symbols-outlined warning",
                positiveButton: {
                    text: "Delete field",
                    buttonClass: "warning",
                    onAction: () => {
                        if (sectionControl?.length > 0) {
                            sectionControl.removeAt(fieldIndex);
                        } else {
                            this.detailCustomFieldsForm.removeAt(fieldIndex);
                        }

                        if (control.value.id !== undefined && control.value.id !== null) {
                            this.form.markAsDirty();
                        }
                        return true;
                    },
                },
                negativeButton: {
                    text: "Cancel",
                    buttonClass: "cancel",
                    onAction: () => {
                        return true;
                    },
                },
            },
        });
    }

    protected editCustomField(sectionFormIndex: number | undefined, formGroup?: FormGroup): void {
        let dialogRef = this.dialog.open(CustomFieldConfigComponent, {
            width: "50%",
            height: "70%",
            data: {
                customField: formGroup?.value ?? undefined,
                isIncidentTypePredefined: this.predefined,
            },
        });

        dialogRef.afterClosed().subscribe((editedCustomField) => {
            if (editedCustomField) {
                if (sectionFormIndex != undefined) {
                    let section = this.sectionsForm.at(sectionFormIndex) as FormGroup;
                    var customFieldForm = section.controls.customFields as FormArray;
                } else {
                    customFieldForm = this.form.controls.customFields as FormArray;
                }

                editedCustomField.order = sectionFormIndex + 1;

                if (formGroup) {
                    formGroup.patchValue({
                        name: editedCustomField.name,
                        placeholder: editedCustomField.placeholder,
                        description: editedCustomField.description,
                        customFieldType: editedCustomField.customFieldType,
                        isRequired: editedCustomField.isRequired,
                        isVisible: editedCustomField.isVisible,
                        customFieldOptions: editedCustomField.customFieldOptions,
                    });
                } else {
                    customFieldForm.push(
                        this.getCustomFieldFormGroup(editedCustomField, sectionFormIndex + 1)
                    );
                }

                this.form.markAsDirty({ emitEvent: true });
            }
        });
    }

    private getCustomFieldFormGroup(
        customField: CustomFieldModel,
        defualtOrder: number | undefined = undefined
    ): FormGroup {
        return this.formBuilder.group({
            id: [customField.id ?? undefined],
            incidentTypeSectionId: [customField.incidentTypeSectionId ?? undefined],
            name: [customField.name ?? "", Validators.required],
            customFieldType: [customField.customFieldType, Validators.required],
            placeholder: [customField.placeholder],
            description: [customField.description],
            order: [customField.order ?? defualtOrder, Validators.required],
            isRequired: [customField.isRequired],
            isVisible: [customField.isVisible],
            customFieldOptions:
                customField.customFieldType === FieldType.SingleSelect ||
                customField.customFieldType === FieldType.MultiSelect
                    ? [customField.customFieldOptions, Validators.required]
                    : null,
        });
    }

    private setCustomFieldsOrder(formGroup: FormGroup): CustomFieldModel[] {
        let customFields = formGroup.value.customFields as CustomFieldModel[];

        customFields.forEach((item: CustomFieldModel, index: number) => {
            item.order = index + 1;
        });

        return customFields;
    }

    private setSectionsOrder(): IncidentTypeSectionModel[] {
        let sections = this.form.value.incidentTypeSections;

        sections.forEach((item: IncidentTypeSectionModel, index: number) => {
            item.order = index + 1;

            item.customFields.forEach((cf: CustomFieldModel, index: number) => {
                cf.order = index + 1;
            });
        });

        return sections;
    }

    private resetForm(): void {
        this.form = this.formBuilder.group({
            id: new FormControl<string | undefined>(undefined),
            name: new FormControl<string>("", Validators.required),
            emailAddresses: new FormControl<string>(
                null,
                CerrixValidators.emailList("emailAddresses", "Invalid email address")
            ),
            customFields: this.formBuilder.array<CustomFieldModel>([]),
            incidentTypeSections: this.formBuilder.array<IncidentTypeSectionModel>([]),
        });
    }
}
