import { Component, Input, OnInit } from "@angular/core";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { parseTyped } from "@methods/CommonMethods";

@Component({
    selector: "form-detail-video",
    templateUrl: "./form-detail-video.component.html",
    styleUrls: ["./form-detail-video.component.scss"],
})
export class FormDetailVideoComponent implements OnInit {
    @Input() field: FormField;
    videoSettings: FormFieldConfig;

    constructor() {}

    ngOnInit() {
        this.field.Required = false;
        this.videoSettings = parseTyped<FormFieldConfig>(
            this.field.CustomSettings,
            new FormFieldConfig()
        );
    }

    saveModel() {
        this.field.CustomSettings = JSON.stringify(this.videoSettings);
    }
}
