<form
    class="detail-page-card-body padded cerrix-control-sm form-group-styling"
    [formGroup]="parentForm"
    novalidate
>
    <div class="detail-page-summary" *ngIf="thirdParty && thirdParty.Identifier">
        <div class="row">
            <div class="col-6">
                <div class="property"><b>ID:</b> {{ thirdParty.Identifier }}</div>
                <div class="property">
                    <b>Name:</b>&nbsp;<span data-uiid="thirdparty-summary-name">{{
                        thirdParty.Name
                    }}</span>
                </div>
                <div class="property">
                    <b>Next review date:</b> {{ thirdParty.NextReviewDate | dateFormat }}
                </div>
            </div>
            <div class="col-6">
                <div class="property"><b>Last review score:</b> {{ thirdParty.Score.Name }}</div>
                <div class="property"><b>Reviewer:</b> {{ thirdParty.ReviewerNames }}</div>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="thirdParty && parentForm">
        <div class="detail-page-card-title">General information</div>
        <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Name"
                                dataUiid="thirdpartydetailmain-name"
                                formControlName="Name"
                                identifier="thirdPartyNameInput"
                                placeholder="Required"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Address"
                                dataUiid="thirdpartydetailmain-adress"
                                formControlName="Address"
                                identifier="thirdPartyAddressInput"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="City"
                                dataUiid="thirdpartydetailmain-city"
                                identifier="thirdPartyCityInput"
                                formControlName="City"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="thirdPartyCountryInput">Country</label>
                            <ng-select
                                data-uiid="thirdpartydetailmain-country"
                                id="thirdPartyCountryInput"
                                name="thirdPartyCountryInput"
                                [items]="sd.Countries"
                                formControlName="Country"
                                bindLabel="Value"
                                bindValue="Key"
                                [closeOnSelect]="true"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Email"
                                dataUiid="thirdpartydetailmain-email"
                                identifier="thirdPartyEmailInput"
                                formControlName="Email"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Phone"
                                dataUiid="thirdpartydetailmain-phone"
                                identifier="thirdPartyPhoneInput"
                                formControlName="Phone"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="thirdPartyWebsiteInput">Website</label>
                            <cerrix-url-input
                                dataUiid="thirdpartydetailmain-website"
                                formControlName="Website"
                            ></cerrix-url-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textarea
                                fieldLabel="Comment"
                                dataUiid="thirdpartydetailmain-comment"
                                identifier="thirdPartyCommentInput"
                                formControlName="Comment"
                            ></cerrix-textarea>
                        </div>
                    </div>
                </div>

                <!-- Right block -->
                <div class="col-12 col-xl-6 right-block">
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textarea
                                fieldLabel="Description"
                                dataUiid="thirdpartydetailmain-description"
                                identifier="thirdPartyDescriptionInput"
                                formControlName="Description"
                            ></cerrix-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="LEI code"
                                dataUiid="thirdpartydetailmain-leicode"
                                identifier="thirdPartyLeiCodeInput"
                                formControlName="LeiCode"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-auto">
                            <label for="thirdPartyEUInput">EU member</label>
                            <cerrix-checkbox
                                data-uiid="thirdpartydetailmain-eumember"
                                fontsize="0.9em"
                                formControlName="EUMember"
                                name="thirdPartyEUInput"
                            ></cerrix-checkbox>
                        </div>
                        <div class="form-group col">
                            <label for="DUNSInput">D-U-N-S Number</label>
                            <input
                                data-uiid="thirdpartydetailmain-duns"
                                type="number"
                                class="form-control form-control-sm"
                                id="DUNSInput"
                                formControlName="DUNS_Number"
                                name="DUNSInput"
                                min="0"
                                max="1000000000"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Authorized signatory"
                                dataUiid="thirdpartydetailmain-authorizedsign"
                                identifier="AuthorizedSignatoryInput"
                                formControlName="AuthorizedSignatory"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="thirdPartyCocInput">Chamber of commerce</label>
                            <input
                                data-uiid="thirdpartydetailmain-coc"
                                class="form-control form-control-sm"
                                id="thirdPartyCocInput"
                                type="number"
                                formControlName="CocNumber"
                                name="thirdPartyCocInput"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Value added tax (VAT)"
                                dataUiid="thirdpartydetailmain-tax"
                                identifier="thirdPartyTaxInput"
                                formControlName="TaxNumber"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Third party responsible"
                                dataUiid="thirdpartydetailmain-tpresponsible"
                                identifier="thirdPartyResponsibleInput"
                                formControlName="ThirdpartyResponsible"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="InvolvementInput">Status of involvement</label>
                            <cerrix-select
                                data-uiid="thirdpartydetailmain-statusofinvolvement"
                                [getDataMethod]="statusOfInvolvementRequest"
                                formControlName="StatusOfInvolvementID"
                                id="StatusOfInvolvementID"
                                [closeOnSelect]="true"
                            >
                            </cerrix-select>
                        </div>
                    </div>
                    <!-- Services -->
                    <div class="row">
                        <div class="form-group col">
                            <label for="Services">Services</label>
                            <cerrix-select-tree
                                data-uiid="thirdpartydetailmain-services"
                                [getDataMethod]="servicesRequest"
                                formControlName="LinkedServiceIds"
                                [multiselect]="true"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                    <!-- start + enddate servicing -->
                    <div class="row">
                        <div class="form-group col-6">
                            <label for="StartDateServicing">Start date servicing</label>
                            <date-input
                                data-uiid="thirdpartydetailmain-startdate"
                                formControlName="StartDateServicing"
                            ></date-input>
                        </div>
                        <div class="form-group col-6">
                            <label for="EndDateServicing">End date servicing</label>
                            <date-input
                                data-uiid="thirdpartydetailmain-enddate"
                                formControlName="EndDateServicing"
                            ></date-input>
                        </div>
                    </div>
                    <!-- Relationship -->
                    <div class="row">
                        <div class="form-group col">
                            <label for="Relationship">Relationship</label>
                            <cerrix-select
                                #ownersCmp
                                data-uiid="thirdpartydetailmain-relationship"
                                id="Relationship"
                                formControlName="Relationship"
                                prettyName="Relationship"
                                bindLabel="Name"
                                bindValue="ID"
                                [getDataMethod]="relationshipRequest"
                            >
                            </cerrix-select>
                        </div>
                    </div>
                    <!-- Criticality -->
                    <div class="row">
                        <div class="form-group col">
                            <label for="Criticality">Criticality</label>
                            <cerrix-select
                                #ownersCmp
                                data-uiid="thirdpartydetailmain-criticality"
                                id="Criticality"
                                formControlName="Criticality"
                                prettyName="Criticality"
                                bindLabel="Name"
                                bindValue="ID"
                                [getDataMethod]="criticalityRequest"
                            >
                            </cerrix-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="thirdParty">
        <div class="detail-page-card-title">Internal business</div>
        <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="form-group col">
                            <label for="orgResponsible">Organization responsible</label>
                            <cerrix-select-tree
                                data-uiid="thirdpartydetailmain-organizationresponsible"
                                [getDataMethod]="organizationRequest"
                                formControlName="OrganizationResponsibleId"
                                (valueChange)="organizationResponsibleChanged($event)"
                                placeholder="Required"
                                [multiselect]="false"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="orgUserSelect">Responsible</label>
                            <ng-select
                                data-uiid="thirdpartydetailmain-responsible"
                                [loading]="loadingResponsibles"
                                id="orgUserSelect"
                                name="orgUserSelect"
                                [multiple]="true"
                                [items]="users"
                                formControlName="UserResponsibles"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="false"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col">
                            <label for="orgResponsible">Organizations (in use)</label>
                            <cerrix-select-tree
                                data-uiid="thirdpartydetailmain-organizations"
                                placeholder="Required"
                                formControlName="Organizations"
                                [getDataMethod]="organizationMultiRequest"
                                [multiselect]="true"
                                (onChangeIds)="organizationsChanged($event)"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <div class="row">
                        <div class="form-group col">
                            <label for="OrganizationBeneficialInput"
                                >Organization beneficial owner</label
                            >
                            <cerrix-select-tree
                                data-uiid="thirdpartydetailmain-orgbeneficialowner"
                                [getDataMethod]="organizationRequest"
                                formControlName="OrganizationBeneficialOwner"
                                [multiselect]="false"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Beneficial owner"
                                dataUiid="thirdpartydetailmain-beneficialowner"
                                identifier="BeneficialInput"
                                formControlName="BeneficialOwner"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col">
                            <label for="businessDimension">Business dimensions</label>
                            <cerrix-select-tree
                                data-uiid="thirdpartydetailmain-businessdimensions"
                                [getDataMethod]="businessDimensionsRequest"
                                formControlName="BusinessDimensions"
                                [multiselect]="true"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="frameworkDimension">Framework dimensions</label>
                            <cerrix-select-tree
                                data-uiid="thirdpartydetailmain-frameworkdimensions"
                                [getDataMethod]="frameworkDimensionsRequest"
                                formControlName="FrameworkDimensions"
                                [multiselect]="true"
                            >
                            </cerrix-select-tree>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="thirdParty">
        <div class="detail-page-card-title">Data Management roles</div>
        <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
            <div class="row">
                <div class="form-group col">
                    <label for="DatamanagementDCRoleInput">Data controller</label>
                    <cerrix-checkbox
                        data-uiid="thirdpartydetailmain-datacontroller"
                        fontsize="0.9em"
                        formControlName="DataController"
                        name="DatamanagementDCRoleInput"
                    ></cerrix-checkbox>
                </div>
                <div class="form-group col">
                    <label for="DatamanagementDPRoleInput">Data processor</label>
                    <cerrix-checkbox
                        data-uiid="thirdpartydetailmain-dataprocessor"
                        fontsize="0.9em"
                        formControlName="DataProcessor"
                        name="DatamanagementDPRoleInput"
                    ></cerrix-checkbox>
                </div>
                <div class="form-group col">
                    <label for="DatamanagementDRRoleInput">Data recipient</label>
                    <cerrix-checkbox
                        data-uiid="thirdpartydetailmain-datarecipient"
                        fontsize="0.9em"
                        formControlName="DataRecipient"
                        name="DatamanagementDRRoleInput"
                    ></cerrix-checkbox>
                </div>
                <div class="form-group col">
                    <label for="DatamanagementTPRoleInput">Third party</label>
                    <cerrix-checkbox
                        data-uiid="thirdpartydetailmain-thirdparty"
                        fontsize="0.9em"
                        formControlName="ThirdParty"
                        name="DatamanagementTPRoleInput"
                    ></cerrix-checkbox>
                </div>
            </div>
        </div>
    </div>

    <!-- Custom fields -->
    <div class="detail-page-card" *ngIf="thirdPartyCustomFieldsFormGroup">
        <div class="detail-page-card-title">Custom fields</div>
        <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
            <cerrix-custom-fields-editor
                [parentForm]="thirdPartyCustomFieldsFormGroup"
                [customFieldData]="customFieldData"
                [singleColumn]="false"
            ></cerrix-custom-fields-editor>
        </div>
    </div>

    <thirdparty-contacts
        *ngIf="thirdParty"
        [thirdParty]="thirdParty"
        [canEdit]="permissions.canEdit"
        #contacts
    >
    </thirdparty-contacts>

    <div class="detail-page-card" *ngIf="thirdParty">
        <div class="detail-page-card-title">Review information</div>
        <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="form-group col">
                            <label for="reviewerSelect">Reviewer</label>
                            <ng-select
                                data-uiid="thirdpartydetailmain-reviewer"
                                [loading]="loadingReviewers"
                                id="reviewerSelect"
                                name="reviewerSelect"
                                [multiple]="true"
                                [items]="reviewers"
                                formControlName="Reviewers"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="false"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="reviewFrequencySelect">Review frequency</label>
                            <ng-select
                                data-uiid="thirdpartydetailmain-reviewfrequency"
                                id="reviewFrequencySelect"
                                name="reviewFrequencySelect"
                                formControlName="ReviewFrequency"
                                bindLabel="Text"
                                bindValue="ID"
                                [items]="frequencies"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6 right-block">
                    <div class="row">
                        <div class="form-group col">
                            <label for="startDate">Initial date</label>
                            <date-input
                                data-uiid="thirdpartydetailmain-initialdate"
                                formControlName="InitialReviewDate"
                            ></date-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="nextReviewDateInput">Next review date</label>
                            <date-input
                                data-uiid="thirdpartydetailmain-nextreviewdate"
                                formControlName="NextReviewDate"
                            ></date-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <thirdparty-reviews
        *ngIf="thirdParty"
        [thirdpartyModel]="thirdParty"
        [canReview]="permissions.canReview"
        #reviews
    >
    </thirdparty-reviews>
</form>
