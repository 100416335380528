<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="standing-data" *ngIf="!tab.showLoader">
    <div class="main-body standing-data-unselected" *ngIf="!sdConfig && !selectedPage">
        <span>Select a standing data page from the left menu.</span>
    </div>
    <div class="main-body standing-data-loading" *ngIf="!sdConfig && selectedPage">
        <div class="fancy-loader submodule"></div>
    </div>
    <div class="main-body standing-data-content" *ngIf="sdConfig">
        <standingdata-generic
            [type]="selectedPage.ID"
            [name]="selectedPage.Name"
            #genericSD
        ></standingdata-generic>
    </div>
</div>

<ng-template #customMenu>
    <ng-container *ngFor="let node of allowedPages">
        <ng-container
            *ngTemplateOutlet="nodeTemplate; context: { node: node, parent: null, depth: 0 }"
        >
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #nodeTemplate let-node="node" let-parent="parent" let-depth="depth">
    <div class="node" [class.selected]="selectedPage && node.ID === selectedPage.ID" data-uiid="">
        <div class="node-detail" [title]="node.Name">
            <div class="node-child-toggler">
                <i
                    class="fas fa-angle-{{ node.isExpanded ? 'down' : 'right' }}"
                    (click)="node.isExpanded = !node.isExpanded"
                    *ngIf="node.Children.length > 0"
                ></i>
            </div>
            <div class="node-icon" (click)="nodeClicked(node)">
                <cerrix-icon-viewer [icon]="node.Icon" appendClass="fa-fw"></cerrix-icon-viewer>
            </div>
            <div
                class="node-name"
                (click)="nodeClicked(node)"
                [attr.data-uiid]="'tabmenu-item-' + (parent ? parent.Name + '-' : '') + node.Name"
            >
                {{ node.Name }}
            </div>
        </div>
        <div class="children" *ngIf="node.isExpanded && node.Children.length > 0">
            <ng-container *ngFor="let child of node.Children">
                <ng-container
                    *ngTemplateOutlet="
                        nodeTemplate;
                        context: { node: child, parent: node, depth: depth + 1 }
                    "
                >
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
