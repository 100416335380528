import { Component, Input, OnInit } from "@angular/core";
import { AuditFieldworkPermissionModel } from "@app/audit/shared/permission-models/audit-fieldwork-permission-model";
import { ControlLinkableModel } from "@app/controls/models/control-linkable-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { RendererType } from "@enums/RendererType";
import { toPromise } from "@methods/CommonMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { map } from "rxjs";
import { TabService } from "../../../../../../services/tabs/TabService";
import { AuditFieldworkStepDataService } from "../../../../services/audit-fieldworkstep-data.service";

@Component({
    selector: "audit-fieldwork-step-control-linking",
    templateUrl: "./audit-fieldwork-step-control-linking.component.html",
    styleUrls: ["./audit-fieldwork-step-control-linking.component.scss"],
})
export class AuditFieldworkStepControlLinkingComponent implements OnInit {
    @Input() auditGuid: string;
    @Input() stepGuid: string;
    @Input() permissions: AuditFieldworkPermissionModel;
    @Input() linkedControls: number[];

    tableConfig: GenericListConfig;

    constructor(
        private _ds: AuditFieldworkStepDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    async ngOnInit() {
        if (!this.permissions || !this.permissions.canViewLinkedControls) {
            return;
        }

        this.createTableConfig();
    }

    openControl(row: ControlLinkableModel) {
        if (row) {
            this._tabService.generateTab(this._pages.ControlDetail, row.guid);
        }
    }

    private createTableConfig() {
        this.tableConfig = <GenericListConfig>{
            name: "Linked Controls",
            idProp: "id",

            allowAdd: false,
            allowEdit: this.permissions.canViewLinkedControls,
            allowLink: this.permissions.canLinkControls,
            linkData: <any>{
                displayValueProp: nameof<ControlLinkableModel>((x) => x.name),
                linkablePaginationItemCount: 15,
                hidePanelToggle: true,
            },

            fields: [
                {
                    prettyName: "Name",
                    fieldName: nameof<ControlLinkableModel>((x) => x.name),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Owner",
                    fieldName: nameof<ControlLinkableModel>((x) => x.owner),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "organization",
                    fieldName: nameof<ControlLinkableModel>((x) => x.organization),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Conclusion Score",
                    fieldName: nameof<ControlLinkableModel>((x) => x.conclusionScore),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<ControlLinkableModel>((x) => x.conclusionScore),
                    actionColumn: nameof<ControlLinkableModel>((x) => x.conclusionScoreColor),
                    type: RendererType.Score,
                },
            ],

            dataMethod: () => {
                return this._ds.getLinkableControls(this.auditGuid).pipe(
                    map(async (data) => {
                        const newLinks = await toPromise(
                            this._ds.getFieldworkLinkedControls(this.auditGuid, this.stepGuid)
                        );

                        this.linkedControls.splice(0, this.linkedControls.length);
                        this.linkedControls.push(...newLinks);

                        let linked: ControlLinkableModel[] = [];
                        let unlinked: ControlLinkableModel[] = [];

                        if (this.linkedControls.length === 0) {
                            unlinked = data;
                        } else {
                            data.forEach((row) => {
                                if (this.linkedControls.indexOf(row.id) >= 0) {
                                    linked.push(row);
                                } else {
                                    unlinked.push(row);
                                }
                            });
                        }

                        this.tableConfig.linkData.unlinked = unlinked;
                        return linked;
                    })
                );
            },

            addOverride: () => {
                this.openControl(null);
            },
            editOverride: (row) => {
                this.openControl(row);
            },

            onLinkedDataChange: (data: ControlLinkableModel[]) => {
                this.linkedControls.splice(0, this.linkedControls.length);
                this.linkedControls.push(...data.map((d) => d.id));
            },
        };
    }
}
