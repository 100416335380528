import { ExportType } from "@enums/ExportType";
import { ExportDataService } from "../services/export-data.service";

export class ExportBase {
    public readonly ExportType = ExportType;

    public constructor(protected exportDataService: ExportDataService) {}

    public download(exportType: ExportType, additionalData?: string) {
        this.exportDataService.downloadExport(exportType, additionalData);
    }
}
