import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

declare var $: any;

@Component({
    selector: "cerrix-checkbox",
    templateUrl: "./cerrix-checkbox.component.html",
    styleUrls: ["./cerrix-checkbox.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxComponent,
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {
    placeholder: string;

    valid = true;
    _value: boolean;

    _onChangeFormValidation = Function.prototype;
    _onTouchedFormValidation = Function.prototype;

    @Input() disabled = false;
    @Input() label: string;
    @Input() fontsize = "1em";
    @Input() fontweight = "bold";

    @Output() valueChange = new EventEmitter<boolean>();

    @Input()
    set value(value: boolean) {
        if (this._value === value) {
            return;
        }

        // Fix value if type of string
        if (value && typeof value === "string") {
            const lowerValue = (value as string).toLowerCase();
            if (lowerValue == "true" || lowerValue == "false") {
                value = JSON.parse(lowerValue);
            }
        }

        this._value = value;
        this.valueChange.emit(value);
        this._onChangeFormValidation(value);
    }
    get value(): boolean {
        return this._value;
    }

    constructor() {}

    toggle() {
        if (this.disabled) {
            return;
        }

        this.value = !this._value;
    }

    // Form Validation
    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this._onChangeFormValidation = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouchedFormValidation = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
