<div *ngIf="!model || !model.OrganizationResponsible">Select "Organization Responsible" first</div>

<div class="fancy-loader" *ngIf="isLoading && model && model.OrganizationResponsible"></div>
<risk-linkable
    *ngIf="!isLoading && model && model.OrganizationResponsible"
    #linkable
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [hideActions]="true"
    [orderByValueProperty]="true"
    (linkedChange)="linkedChanged($event)"
    [disabled]="disabled"
    [showAdvancedFilters]="true"
    [activeFilters]="activeFilters"
>
</risk-linkable>
