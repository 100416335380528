import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../../api-spec";

@Component({
    selector: "tpa-request-export-api-manual",
    templateUrl: "../../../api-spec.html",
    styleUrls: ["../../../api-spec.scss"],
})
export class TpaRequestExportApiManualComponent extends ApiSpecComponent {
    initSpecs(): ApiSpecificationModel {
        return <ApiSpecificationModel>{
            name: "Create request",
            description: "Request the export for ControlTestInformation",
            httpMethod: "POST",
            path: "/thirdpartyassurance/requestexport",

            parameters: [
                {
                    name: "controlids",
                    description: "Optional list of controls ids to be requested",
                    in: "query",
                    paramType: "string",
                    required: false,
                    example: "1;2;5",
                },
                {
                    name: "testplantypes",
                    description: "Required option for testplan type",
                    in: "query",
                    paramType: "string",
                    required: true,
                    example: "Effectiveness",
                    enum: ["Effectiveness"], // All and D&I will be added in the future
                },
                {
                    name: "testplanperiodstart",
                    description:
                        "Start date of the testplan has to be between the start period and the end period",
                    in: "query",
                    paramType: "date",
                    required: true,
                },
                {
                    name: "testplanperiodend",
                    description:
                        "Start date of the testplan has to be between the start period and the end period",
                    in: "query",
                    paramType: "date",
                    required: true,
                },
            ],
            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: "00000000-0000-0000-0000-000000000000",
                },
            ],
        };
    }
}
