export const ShapesThatSupportLinksToOtherProcesses = [
    "process",
    "predefinedprocess",
    "bpmnProcess",
    "bpmnTransaction",
    "bpmnCallActivity",
    "bpmnConversation",
    "bpmnCallConversation",
    "datastore",
    "message",
];
