<div class="fancy-loader" *ngIf="isLoading"></div>
<div>
    <form-linkable
        *ngIf="!isLoading"
        displayValueProp="name"
        [parentGuid]="parentGuid"
        [linkedModule]="linkedModule"
        [linkedForms]="linkedForms"
        [linked]="linked"
        [unlinked]="unlinked"
        [disabled]="disabled"
        [hideActions]="true"
        (linkedChange)="linkedItemsChanged($event)"
        data-uiid="auditforms-linkable"
    ></form-linkable>
</div>
