import { OnInit, Component, Input } from "@angular/core";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { MailMessageModel } from "@app/mailmessages/models/mailMessageModel";
import { MailMessageService } from "@app/mailmessages/service/mailmessage.service";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "uc-mailmessages-overview",
    templateUrl: "./mailmessages-overview.component.html",
    styleUrls: ["./mailmessages-overview.component.scss"],
})
export class MailMessagesOverviewComponent implements OnInit {
    @Input() module: ModuleType;

    config: GenericListConfig;

    constructor(
        private _tabService: TabService,
        private _mmService: MailMessageService,
        public pages: Pages
    ) {}

    ngOnInit() {
        this._mmService.getData(this.module).subscribe((data) => {
            this.setupConfig(data);
        });
    }

    setupConfig(data: MailMessageModel[]) {
        this.config = <GenericListConfig>{
            name: "Mail Messages",
            data: data,
            idProp: "ID",

            allowAdd: false,
            allowEdit: true,
            allowDelete: false,

            fields: [
                {
                    fieldName: "MailTypeDescription",
                    prettyName: "Type",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: "Description",
                    prettyName: "Description",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Enabled",
                    prettyName: "Send mail",
                    fieldType: GenericListFieldType.CheckBox,
                },
            ],
            editOverride: (row: MailMessageModel) => {
                this._tabService.generateTab(
                    this.pages.MailMessagesDetail,
                    row.ID,
                    row.MailTypeDescription
                );
            },
        };
    }
}
