import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { TabMenu } from "../../../common/models/generic/TabModels/TabMenu";
import { TabMenuItem } from "../../../common/models/generic/TabModels/TabMenuItem";

@Component({
    // tslint:disable-next-line:directive-selector
    selector: "cerrix-tabmenu",
    templateUrl: "./tabmenu.component.html",
    styleUrls: ["./tabmenu.component.scss"],
})
export class TabMenuComponent implements OnInit, OnChanges {
    @Input() mainMenu: TabMenu;
    @Input() menuItems: TabMenuItem[];

    // Do not change this, unless you know what you are doing.
    @Input() level = 1;

    groupHasIcons = true;

    constructor() {}

    ngOnInit() {
        if (!this.menuItems) {
            this.menuItems = this.mainMenu.menuItems;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.menuItems) {
            const itemsWithoutIcons = this.menuItems.filter(
                (x) => !x.iconClass || x.iconClass === ""
            ).length;
            this.groupHasIcons = itemsWithoutIcons !== this.menuItems.length;
        }
    }

    clickMenuItem(menu: TabMenuItem) {
        if (menu.clickable) {
            this.mainMenu.menuItemClicked(menu);
        }
    }
}
