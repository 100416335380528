<div class="d-flex flex-column">
    <div class="text-container">
        {{ this.displayedText }}
    </div>
    <eff-button
        *ngIf="showMoreLessButton"
        class="link-button"
        [text]="isMore ? 'Read less' : 'Read more'"
        (onClick)="onMoreClick()"
    ></eff-button>
</div>
