import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { EventDataService } from "@app/event/services/EventDataService";
import { EventRiskControlModel } from "@app/event/shared/event-risk-control-model";
import { BaseEventRiskControl } from "../event-risk-control/event-risk-control.component";
import { Observable } from "rxjs";

@Component({
    selector: "event-control",
    templateUrl: "./event-control.component.html",
    styleUrls: ["./event-control.component.scss"],
})
export class EventControlComponent extends BaseEventRiskControl {
    constructor(_eventDS: EventDataService, _modalService: BsModalService) {
        super(_eventDS, _modalService);
    }

    getLinkable(guid: string, organizationId: number): Observable<EventRiskControlModel[]> {
        return this._eventDS.getLinkableControls(guid, organizationId);
    }

    linkedItemsChanged(linked: EventRiskControlModel[]) {
        this.eventModel.ControlIdList = linked.map((x) => x.id);
        this.eventModel.Controls = linked;

        this.linkChanged.emit();
    }

    getLinked(): number[] {
        return this.eventModel.ControlIdList;
    }
}
