<div class="fancy-loader submodule" *ngIf="!loaded"></div>

<div class="standing-data-page" [class.editmode]="standingDataView && standingDataView.editMode">
    <div class="selection-menu" *ngIf="loaded && componentConfig">
        <standing-data-list-view
            #standingDataView
            *ngIf="componentConfig.overviewType == overviewTypes.List"
            [standingDataType]="type"
            [config]="componentConfig"
            [name]="name"
            (onDataLoaded)="editCmp.data = $event"
            (onAdd)="editCmp.add($event)"
            (selectedChanged)="editCmp.edit($event)"
            [changeCallback]="editCmp.edit.bind(editCmp)"
        >
        </standing-data-list-view>
        <standing-data-tree-view
            #standingDataView
            *ngIf="componentConfig.overviewType == overviewTypes.Tree"
            [standingDataType]="type"
            [config]="componentConfig"
            [name]="name"
            (onDataLoaded)="editCmp.data = $event"
            (onAdd)="editCmp.add($event)"
            (selectedChanged)="editCmp.edit($event)"
            [changeCallback]="editCmp.edit.bind(editCmp)"
        >
        </standing-data-tree-view>
    </div>

    <div class="edit-area">
        <standing-data-editor
            #editCmp
            [config]="componentConfig"
            [type]="type"
            [name]="name"
            (onChange)="standingDataView.loadData($event)"
            (onDetailOpen)="standingDataView.detailOpened($event)"
        ></standing-data-editor>
    </div>
</div>
