import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { CustomFieldOptionModel } from "../models/custom-field-option.model";

@Injectable({
    providedIn: "root",
})
export class IncidentCustomFieldService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/custom-fields`;

    public getCustomFieldOptions(customFieldId: string): Observable<CustomFieldOptionModel[]> {
        return this.httpClient.get<CustomFieldOptionModel[]>(
            `${this.baseUrl}/${customFieldId}/options`
        );
    }

    public getVisibleCustomFieldOptions(
        customFieldId: string,
        selectedOptions: string[]
    ): Observable<CustomFieldOptionModel[]> {
        if (selectedOptions === undefined || selectedOptions === null) {
            selectedOptions = [];
        }
        return this.httpClient.post<CustomFieldOptionModel[]>(
            `${this.baseUrl}/${customFieldId}/options/visible`,
            selectedOptions
        );
    }

    public isCustomFieldOptionUsed(
        customFieldId: string,
        customFieldOptionId: string
    ): Observable<boolean> {
        return this.httpClient.get<boolean>(
            `${this.baseUrl}/${customFieldId}/options/${customFieldOptionId}/is-used`
        );
    }
}
