<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    class="kri-detail"
    (change)="handleMenuItemClick($event)"
    *ngIf="canView && kri && organizations && businessDimensions"
>
    <div class="detail-page-action-bar">
        <button class="btn btn-cerrix" (click)="save()" *ngIf="canEdit">
            <i class="fas fa-save"></i>
            <span data-uiid="kri-save" class="d-none d-lg-inline">Save Kri</span>
        </button>

        <span class="date-edited"> Last save: {{ kri.modifiedAt | dateTimeFormat }} </span>
    </div>

    <div
        class="kri-screens"
        [attr.name]="'Key Risk Indicator'"
        menuicon="fas fa-tachometer-alt"
        menusystemsubmenu
    >
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <div class="detail-page-summary" *ngIf="kri && kri.guid">
                <div class="row">
                    <div class="col">
                        <div class="property"><b>ID:</b> {{ kri.identifier }}</div>
                        <div class="property">
                            <b>Name:</b>&nbsp;<span data-uiid="kri-summary-name">{{
                                kri.name
                            }}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="property"><b>Last modifier:</b> {{ kri.modifiedBy }}</div>
                        <div class="property">
                            <b>Aggregate Score:</b>
                            <div
                                class="datapoint-score"
                                [ngStyle]="{ background: kri.aggregateScoreColor }"
                            ></div>
                            {{ kri.aggregateScore }}
                        </div>
                    </div>
                </div>
            </div>

            <kri-detail-main
                [organizations]="organizations"
                [businessDimensions]="businessDimensions"
                [mainFormGroup]="mainFormGroup"
            ></kri-detail-main>
        </div>
        <div name="Data" menuicon="fas fa-database" menusystemitem>
            <kri-data [kri]="kri" [canEdit]="canEdit" [mainFormGroup]="mainFormGroup"> </kri-data>
        </div>
        <div name="Data Graph" menuicon="fal fa-chart-line" menusystemitem *ngIf="kri && kri.guid">
            <kri-data-graph [kriGuid]="kri.guid" [lazyLoad]="true" #kriDataGraph></kri-data-graph>
        </div>
        <div
            *ngIf="kri.id > 0"
            class="kri-screens"
            [attr.name]="'Links'"
            menuicon="fas fa-link"
            menusystemsubmenu
        >
            <div
                data-uiid="kridetail-risks"
                name="Risks"
                menuItemId="menuItemRisks"
                menuicon="fas fa-exclamation-triangle"
                menusystemitem
            >
                <kri-risk [model]="kri"></kri-risk>
            </div>
            <div
                data-uiid="kridetail-documents"
                name="Documents"
                menuItemId="menuItemDocuments"
                menuicon="far fa-file"
                menusystemitem
            >
                <document-manager-old
                    data-uiid="kridocuments-documentmanager"
                    [(Documents)]="kri.documents"
                    (DocumentsChange)="checkDirty()"
                    [AllowAdd]="canEdit"
                    [AllowEdit]="canEdit"
                    [AllowDelete]="canEdit"
                    [DocumentTypeTargets]="targetModule"
                ></document-manager-old>
            </div>
            <div
                name="Hyperlinks"
                [attr.menuItemID]="hyperlinksMenuItemId"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="kri.id > 0"
            >
                <div *ngIf="!kri.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="kri.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="kri.Hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>
        <div name="History" menuicon="fas fa-history" menusystemitem *ngIf="kri && kri.guid">
            <app-history-overview
                [historyDataService]="_kriDS"
                [Id]="id"
                [useOldHistory]="true"
                #historyOverviewComponent
            >
            </app-history-overview>
        </div>
        <div
            *ngIf="kri && kri.guid"
            data-uiid="kridetail-options"
            name="Options"
            menuicon="fas fa-check-double"
            menusystemitem
        >
            <detail-options
                moduleName="kri"
                [objectName]="kri.name"
                [allowDelete]="canEdit"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
