export enum FieldType {
    Checkbox = 1,
    Numeric = 2,
    TextField = 3,
    TextBox = 4,
    Date = 5,
    DateTime = 6,
    SingleSelect = 7,
    MultiSelect = 8,
    FinancialImpact = 9,
}
