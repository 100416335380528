[
    {
      "name": "500px",
      "selector": "fab fa-500px",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "abacus",
      "selector": "fas fa-abacus",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "abacus",
      "selector": "far fa-abacus",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "abacus",
      "selector": "fal fa-abacus",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "abacus",
      "selector": "fad fa-abacus",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "accessible-icon",
      "selector": "fab fa-accessible-icon",
      "categories": [
        "Brands",
        "Accessibility",
        "Health",
        "Users & People",
        "Vehicles"
      ]
    },
    {
      "name": "accusoft",
      "selector": "fab fa-accusoft",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "acorn",
      "selector": "fas fa-acorn",
      "categories": [
        "Solid",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "acorn",
      "selector": "far fa-acorn",
      "categories": [
        "Regular",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "acorn",
      "selector": "fal fa-acorn",
      "categories": [
        "Light",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "acorn",
      "selector": "fad fa-acorn",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "acquisitions-incorporated",
      "selector": "fab fa-acquisitions-incorporated",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "ad",
      "selector": "fas fa-ad",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "ad",
      "selector": "far fa-ad",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "ad",
      "selector": "fal fa-ad",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "ad",
      "selector": "fad fa-ad",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "address-book",
      "selector": "fas fa-address-book",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-book",
      "selector": "far fa-address-book",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-book",
      "selector": "fal fa-address-book",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-book",
      "selector": "fad fa-address-book",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-card",
      "selector": "fas fa-address-card",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-card",
      "selector": "far fa-address-card",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-card",
      "selector": "fal fa-address-card",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "address-card",
      "selector": "fad fa-address-card",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Users & People"
      ]
    },
    {
      "name": "adjust",
      "selector": "fas fa-adjust",
      "categories": [
        "Solid",
        "Design",
        "Images"
      ]
    },
    {
      "name": "adjust",
      "selector": "far fa-adjust",
      "categories": [
        "Regular",
        "Design",
        "Images"
      ]
    },
    {
      "name": "adjust",
      "selector": "fal fa-adjust",
      "categories": [
        "Light",
        "Design",
        "Images"
      ]
    },
    {
      "name": "adjust",
      "selector": "fad fa-adjust",
      "categories": [
        "Duotone",
        "Design",
        "Images"
      ]
    },
    {
      "name": "adn",
      "selector": "fab fa-adn",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "adobe",
      "selector": "fab fa-adobe",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "adversal",
      "selector": "fab fa-adversal",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "affiliatetheme",
      "selector": "fab fa-affiliatetheme",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "air-conditioner",
      "selector": "fas fa-air-conditioner",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "air-conditioner",
      "selector": "far fa-air-conditioner",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "air-conditioner",
      "selector": "fal fa-air-conditioner",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "air-conditioner",
      "selector": "fad fa-air-conditioner",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "air-freshener",
      "selector": "fas fa-air-freshener",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "air-freshener",
      "selector": "far fa-air-freshener",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "air-freshener",
      "selector": "fal fa-air-freshener",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "air-freshener",
      "selector": "fad fa-air-freshener",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "airbnb",
      "selector": "fab fa-airbnb",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "alarm-clock",
      "selector": "fas fa-alarm-clock",
      "categories": [
        "Solid",
        "Alert",
        "Date & Time",
        "Hotel",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "alarm-clock",
      "selector": "far fa-alarm-clock",
      "categories": [
        "Regular",
        "Alert",
        "Date & Time",
        "Hotel",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "alarm-clock",
      "selector": "fal fa-alarm-clock",
      "categories": [
        "Light",
        "Alert",
        "Date & Time",
        "Hotel",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "alarm-clock",
      "selector": "fad fa-alarm-clock",
      "categories": [
        "Duotone",
        "Alert",
        "Date & Time",
        "Hotel",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "alarm-exclamation",
      "selector": "fas fa-alarm-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-exclamation",
      "selector": "far fa-alarm-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-exclamation",
      "selector": "fal fa-alarm-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-exclamation",
      "selector": "fad fa-alarm-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-plus",
      "selector": "fas fa-alarm-plus",
      "categories": [
        "Solid",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-plus",
      "selector": "far fa-alarm-plus",
      "categories": [
        "Regular",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-plus",
      "selector": "fal fa-alarm-plus",
      "categories": [
        "Light",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-plus",
      "selector": "fad fa-alarm-plus",
      "categories": [
        "Duotone",
        "Date & Time"
      ]
    },
    {
      "name": "alarm-snooze",
      "selector": "fas fa-alarm-snooze",
      "categories": [
        "Solid",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "alarm-snooze",
      "selector": "far fa-alarm-snooze",
      "categories": [
        "Regular",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "alarm-snooze",
      "selector": "fal fa-alarm-snooze",
      "categories": [
        "Light",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "alarm-snooze",
      "selector": "fad fa-alarm-snooze",
      "categories": [
        "Duotone",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "album",
      "selector": "fas fa-album",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album",
      "selector": "far fa-album",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album",
      "selector": "fal fa-album",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album",
      "selector": "fad fa-album",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album-collection",
      "selector": "fas fa-album-collection",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album-collection",
      "selector": "far fa-album-collection",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album-collection",
      "selector": "fal fa-album-collection",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "album-collection",
      "selector": "fad fa-album-collection",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "algolia",
      "selector": "fab fa-algolia",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "alicorn",
      "selector": "fas fa-alicorn",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "alicorn",
      "selector": "far fa-alicorn",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "alicorn",
      "selector": "fal fa-alicorn",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "alicorn",
      "selector": "fad fa-alicorn",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "alien",
      "selector": "fas fa-alien",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "alien",
      "selector": "far fa-alien",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "alien",
      "selector": "fal fa-alien",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "alien",
      "selector": "fad fa-alien",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "alien-monster",
      "selector": "fas fa-alien-monster",
      "categories": [
        "Solid",
        "Games",
        "Science Fiction"
      ]
    },
    {
      "name": "alien-monster",
      "selector": "far fa-alien-monster",
      "categories": [
        "Regular",
        "Games",
        "Science Fiction"
      ]
    },
    {
      "name": "alien-monster",
      "selector": "fal fa-alien-monster",
      "categories": [
        "Light",
        "Games",
        "Science Fiction"
      ]
    },
    {
      "name": "alien-monster",
      "selector": "fad fa-alien-monster",
      "categories": [
        "Duotone",
        "Games",
        "Science Fiction"
      ]
    },
    {
      "name": "align-center",
      "selector": "fas fa-align-center",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "align-center",
      "selector": "far fa-align-center",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "align-center",
      "selector": "fal fa-align-center",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "align-center",
      "selector": "fad fa-align-center",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "align-justify",
      "selector": "fas fa-align-justify",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "align-justify",
      "selector": "far fa-align-justify",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "align-justify",
      "selector": "fal fa-align-justify",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "align-justify",
      "selector": "fad fa-align-justify",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "align-left",
      "selector": "fas fa-align-left",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "align-left",
      "selector": "far fa-align-left",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "align-left",
      "selector": "fal fa-align-left",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "align-left",
      "selector": "fad fa-align-left",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "align-right",
      "selector": "fas fa-align-right",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "align-right",
      "selector": "far fa-align-right",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "align-right",
      "selector": "fal fa-align-right",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "align-right",
      "selector": "fad fa-align-right",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "align-slash",
      "selector": "fas fa-align-slash",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "align-slash",
      "selector": "far fa-align-slash",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "align-slash",
      "selector": "fal fa-align-slash",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "align-slash",
      "selector": "fad fa-align-slash",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "alipay",
      "selector": "fab fa-alipay",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "allergies",
      "selector": "fas fa-allergies",
      "categories": [
        "Solid",
        "Hands",
        "Medical",
        "Spring"
      ]
    },
    {
      "name": "allergies",
      "selector": "far fa-allergies",
      "categories": [
        "Regular",
        "Hands",
        "Medical",
        "Spring"
      ]
    },
    {
      "name": "allergies",
      "selector": "fal fa-allergies",
      "categories": [
        "Light",
        "Hands",
        "Medical",
        "Spring"
      ]
    },
    {
      "name": "allergies",
      "selector": "fad fa-allergies",
      "categories": [
        "Duotone",
        "Hands",
        "Medical",
        "Spring"
      ]
    },
    {
      "name": "amazon",
      "selector": "fab fa-amazon",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "amazon-pay",
      "selector": "fab fa-amazon-pay",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "ambulance",
      "selector": "fas fa-ambulance",
      "categories": [
        "Solid",
        "Automotive",
        "Health",
        "Maps",
        "Medical",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "ambulance",
      "selector": "far fa-ambulance",
      "categories": [
        "Regular",
        "Automotive",
        "Health",
        "Maps",
        "Medical",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "ambulance",
      "selector": "fal fa-ambulance",
      "categories": [
        "Light",
        "Automotive",
        "Health",
        "Maps",
        "Medical",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "ambulance",
      "selector": "fad fa-ambulance",
      "categories": [
        "Duotone",
        "Automotive",
        "Health",
        "Maps",
        "Medical",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "american-sign-language-interpreting",
      "selector": "fas fa-american-sign-language-interpreting",
      "categories": [
        "Solid",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "american-sign-language-interpreting",
      "selector": "far fa-american-sign-language-interpreting",
      "categories": [
        "Regular",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "american-sign-language-interpreting",
      "selector": "fal fa-american-sign-language-interpreting",
      "categories": [
        "Light",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "american-sign-language-interpreting",
      "selector": "fad fa-american-sign-language-interpreting",
      "categories": [
        "Duotone",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "amilia",
      "selector": "fab fa-amilia",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "amp-guitar",
      "selector": "fas fa-amp-guitar",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "amp-guitar",
      "selector": "far fa-amp-guitar",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "amp-guitar",
      "selector": "fal fa-amp-guitar",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "amp-guitar",
      "selector": "fad fa-amp-guitar",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "analytics",
      "selector": "fas fa-analytics",
      "categories": [
        "Solid",
        "Finance",
        "Marketing",
        "Science"
      ]
    },
    {
      "name": "analytics",
      "selector": "far fa-analytics",
      "categories": [
        "Regular",
        "Finance",
        "Marketing",
        "Science"
      ]
    },
    {
      "name": "analytics",
      "selector": "fal fa-analytics",
      "categories": [
        "Light",
        "Finance",
        "Marketing",
        "Science"
      ]
    },
    {
      "name": "analytics",
      "selector": "fad fa-analytics",
      "categories": [
        "Duotone",
        "Finance",
        "Marketing",
        "Science"
      ]
    },
    {
      "name": "anchor",
      "selector": "fas fa-anchor",
      "categories": [
        "Solid",
        "Maps",
        "Maritime",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "anchor",
      "selector": "far fa-anchor",
      "categories": [
        "Regular",
        "Maps",
        "Maritime",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "anchor",
      "selector": "fal fa-anchor",
      "categories": [
        "Light",
        "Maps",
        "Maritime",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "anchor",
      "selector": "fad fa-anchor",
      "categories": [
        "Duotone",
        "Maps",
        "Maritime",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "android",
      "selector": "fab fa-android",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "angel",
      "selector": "fas fa-angel",
      "categories": [
        "Solid",
        "Holiday",
        "Users & People"
      ]
    },
    {
      "name": "angel",
      "selector": "far fa-angel",
      "categories": [
        "Regular",
        "Holiday",
        "Users & People"
      ]
    },
    {
      "name": "angel",
      "selector": "fal fa-angel",
      "categories": [
        "Light",
        "Holiday",
        "Users & People"
      ]
    },
    {
      "name": "angel",
      "selector": "fad fa-angel",
      "categories": [
        "Duotone",
        "Holiday",
        "Users & People"
      ]
    },
    {
      "name": "angellist",
      "selector": "fab fa-angellist",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "angle-double-down",
      "selector": "fas fa-angle-double-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-down",
      "selector": "far fa-angle-double-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-down",
      "selector": "fal fa-angle-double-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-down",
      "selector": "fad fa-angle-double-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-left",
      "selector": "fas fa-angle-double-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-left",
      "selector": "far fa-angle-double-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-left",
      "selector": "fal fa-angle-double-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-left",
      "selector": "fad fa-angle-double-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-right",
      "selector": "fas fa-angle-double-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-right",
      "selector": "far fa-angle-double-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-right",
      "selector": "fal fa-angle-double-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-right",
      "selector": "fad fa-angle-double-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-up",
      "selector": "fas fa-angle-double-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-up",
      "selector": "far fa-angle-double-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-up",
      "selector": "fal fa-angle-double-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-double-up",
      "selector": "fad fa-angle-double-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-down",
      "selector": "fas fa-angle-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-down",
      "selector": "far fa-angle-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-down",
      "selector": "fal fa-angle-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-down",
      "selector": "fad fa-angle-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-left",
      "selector": "fas fa-angle-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-left",
      "selector": "far fa-angle-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-left",
      "selector": "fal fa-angle-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-left",
      "selector": "fad fa-angle-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-right",
      "selector": "fas fa-angle-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-right",
      "selector": "far fa-angle-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-right",
      "selector": "fal fa-angle-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-right",
      "selector": "fad fa-angle-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angle-up",
      "selector": "fas fa-angle-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "angle-up",
      "selector": "far fa-angle-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "angle-up",
      "selector": "fal fa-angle-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "angle-up",
      "selector": "fad fa-angle-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "angry",
      "selector": "fas fa-angry",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "angry",
      "selector": "far fa-angry",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "angry",
      "selector": "fal fa-angry",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "angry",
      "selector": "fad fa-angry",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "angrycreative",
      "selector": "fab fa-angrycreative",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "angular",
      "selector": "fab fa-angular",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ankh",
      "selector": "fas fa-ankh",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "ankh",
      "selector": "far fa-ankh",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "ankh",
      "selector": "fal fa-ankh",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "ankh",
      "selector": "fad fa-ankh",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "app-store",
      "selector": "fab fa-app-store",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "app-store-ios",
      "selector": "fab fa-app-store-ios",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "apper",
      "selector": "fab fa-apper",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "apple",
      "selector": "fab fa-apple",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "apple-alt",
      "selector": "fas fa-apple-alt",
      "categories": [
        "Solid",
        "Autumn",
        "Childhood",
        "Education",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-alt",
      "selector": "far fa-apple-alt",
      "categories": [
        "Regular",
        "Autumn",
        "Childhood",
        "Education",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-alt",
      "selector": "fal fa-apple-alt",
      "categories": [
        "Light",
        "Autumn",
        "Childhood",
        "Education",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-alt",
      "selector": "fad fa-apple-alt",
      "categories": [
        "Duotone",
        "Autumn",
        "Childhood",
        "Education",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-crate",
      "selector": "fas fa-apple-crate",
      "categories": [
        "Solid",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-crate",
      "selector": "far fa-apple-crate",
      "categories": [
        "Regular",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-crate",
      "selector": "fal fa-apple-crate",
      "categories": [
        "Light",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-crate",
      "selector": "fad fa-apple-crate",
      "categories": [
        "Duotone",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "apple-pay",
      "selector": "fab fa-apple-pay",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "archive",
      "selector": "fas fa-archive",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Files",
        "Moving",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "archive",
      "selector": "far fa-archive",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Files",
        "Moving",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "archive",
      "selector": "fal fa-archive",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Files",
        "Moving",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "archive",
      "selector": "fad fa-archive",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Files",
        "Moving",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "archway",
      "selector": "fas fa-archway",
      "categories": [
        "Solid",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "archway",
      "selector": "far fa-archway",
      "categories": [
        "Regular",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "archway",
      "selector": "fal fa-archway",
      "categories": [
        "Light",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "archway",
      "selector": "fad fa-archway",
      "categories": [
        "Duotone",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "arrow-alt-circle-down",
      "selector": "fas fa-arrow-alt-circle-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-down",
      "selector": "far fa-arrow-alt-circle-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-down",
      "selector": "fal fa-arrow-alt-circle-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-down",
      "selector": "fad fa-arrow-alt-circle-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-left",
      "selector": "fas fa-arrow-alt-circle-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-left",
      "selector": "far fa-arrow-alt-circle-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-left",
      "selector": "fal fa-arrow-alt-circle-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-left",
      "selector": "fad fa-arrow-alt-circle-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-right",
      "selector": "fas fa-arrow-alt-circle-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-right",
      "selector": "far fa-arrow-alt-circle-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-right",
      "selector": "fal fa-arrow-alt-circle-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-right",
      "selector": "fad fa-arrow-alt-circle-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-up",
      "selector": "fas fa-arrow-alt-circle-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-up",
      "selector": "far fa-arrow-alt-circle-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-up",
      "selector": "fal fa-arrow-alt-circle-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-circle-up",
      "selector": "fad fa-arrow-alt-circle-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-down",
      "selector": "fas fa-arrow-alt-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-down",
      "selector": "far fa-arrow-alt-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-down",
      "selector": "fal fa-arrow-alt-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-down",
      "selector": "fad fa-arrow-alt-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-bottom",
      "selector": "fas fa-arrow-alt-from-bottom",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-bottom",
      "selector": "far fa-arrow-alt-from-bottom",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-bottom",
      "selector": "fal fa-arrow-alt-from-bottom",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-bottom",
      "selector": "fad fa-arrow-alt-from-bottom",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-left",
      "selector": "fas fa-arrow-alt-from-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-left",
      "selector": "far fa-arrow-alt-from-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-left",
      "selector": "fal fa-arrow-alt-from-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-left",
      "selector": "fad fa-arrow-alt-from-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-right",
      "selector": "fas fa-arrow-alt-from-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-right",
      "selector": "far fa-arrow-alt-from-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-right",
      "selector": "fal fa-arrow-alt-from-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-right",
      "selector": "fad fa-arrow-alt-from-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-top",
      "selector": "fas fa-arrow-alt-from-top",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-top",
      "selector": "far fa-arrow-alt-from-top",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-top",
      "selector": "fal fa-arrow-alt-from-top",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-from-top",
      "selector": "fad fa-arrow-alt-from-top",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-left",
      "selector": "fas fa-arrow-alt-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-left",
      "selector": "far fa-arrow-alt-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-left",
      "selector": "fal fa-arrow-alt-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-left",
      "selector": "fad fa-arrow-alt-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-right",
      "selector": "fas fa-arrow-alt-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-right",
      "selector": "far fa-arrow-alt-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-right",
      "selector": "fal fa-arrow-alt-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-right",
      "selector": "fad fa-arrow-alt-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-down",
      "selector": "fas fa-arrow-alt-square-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-down",
      "selector": "far fa-arrow-alt-square-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-down",
      "selector": "fal fa-arrow-alt-square-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-down",
      "selector": "fad fa-arrow-alt-square-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-left",
      "selector": "fas fa-arrow-alt-square-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-left",
      "selector": "far fa-arrow-alt-square-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-left",
      "selector": "fal fa-arrow-alt-square-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-left",
      "selector": "fad fa-arrow-alt-square-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-right",
      "selector": "fas fa-arrow-alt-square-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-right",
      "selector": "far fa-arrow-alt-square-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-right",
      "selector": "fal fa-arrow-alt-square-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-right",
      "selector": "fad fa-arrow-alt-square-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-up",
      "selector": "fas fa-arrow-alt-square-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-up",
      "selector": "far fa-arrow-alt-square-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-up",
      "selector": "fal fa-arrow-alt-square-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-square-up",
      "selector": "fad fa-arrow-alt-square-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-bottom",
      "selector": "fas fa-arrow-alt-to-bottom",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-bottom",
      "selector": "far fa-arrow-alt-to-bottom",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-bottom",
      "selector": "fal fa-arrow-alt-to-bottom",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-bottom",
      "selector": "fad fa-arrow-alt-to-bottom",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-left",
      "selector": "fas fa-arrow-alt-to-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-left",
      "selector": "far fa-arrow-alt-to-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-left",
      "selector": "fal fa-arrow-alt-to-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-left",
      "selector": "fad fa-arrow-alt-to-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-right",
      "selector": "fas fa-arrow-alt-to-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-right",
      "selector": "far fa-arrow-alt-to-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-right",
      "selector": "fal fa-arrow-alt-to-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-right",
      "selector": "fad fa-arrow-alt-to-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-top",
      "selector": "fas fa-arrow-alt-to-top",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-top",
      "selector": "far fa-arrow-alt-to-top",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-top",
      "selector": "fal fa-arrow-alt-to-top",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-to-top",
      "selector": "fad fa-arrow-alt-to-top",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-up",
      "selector": "fas fa-arrow-alt-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-up",
      "selector": "far fa-arrow-alt-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-up",
      "selector": "fal fa-arrow-alt-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-alt-up",
      "selector": "fad fa-arrow-alt-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-down",
      "selector": "fas fa-arrow-circle-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-down",
      "selector": "far fa-arrow-circle-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-down",
      "selector": "fal fa-arrow-circle-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-down",
      "selector": "fad fa-arrow-circle-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-left",
      "selector": "fas fa-arrow-circle-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-left",
      "selector": "far fa-arrow-circle-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-left",
      "selector": "fal fa-arrow-circle-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-left",
      "selector": "fad fa-arrow-circle-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-right",
      "selector": "fas fa-arrow-circle-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-right",
      "selector": "far fa-arrow-circle-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-right",
      "selector": "fal fa-arrow-circle-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-right",
      "selector": "fad fa-arrow-circle-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-up",
      "selector": "fas fa-arrow-circle-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-up",
      "selector": "far fa-arrow-circle-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-up",
      "selector": "fal fa-arrow-circle-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-circle-up",
      "selector": "fad fa-arrow-circle-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-down",
      "selector": "fas fa-arrow-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-down",
      "selector": "far fa-arrow-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-down",
      "selector": "fal fa-arrow-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-down",
      "selector": "fad fa-arrow-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-bottom",
      "selector": "fas fa-arrow-from-bottom",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-bottom",
      "selector": "far fa-arrow-from-bottom",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-bottom",
      "selector": "fal fa-arrow-from-bottom",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-bottom",
      "selector": "fad fa-arrow-from-bottom",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-left",
      "selector": "fas fa-arrow-from-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-left",
      "selector": "far fa-arrow-from-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-left",
      "selector": "fal fa-arrow-from-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-left",
      "selector": "fad fa-arrow-from-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-right",
      "selector": "fas fa-arrow-from-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-right",
      "selector": "far fa-arrow-from-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-right",
      "selector": "fal fa-arrow-from-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-right",
      "selector": "fad fa-arrow-from-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-top",
      "selector": "fas fa-arrow-from-top",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-top",
      "selector": "far fa-arrow-from-top",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-top",
      "selector": "fal fa-arrow-from-top",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-from-top",
      "selector": "fad fa-arrow-from-top",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-left",
      "selector": "fas fa-arrow-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-left",
      "selector": "far fa-arrow-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-left",
      "selector": "fal fa-arrow-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-left",
      "selector": "fad fa-arrow-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-right",
      "selector": "fas fa-arrow-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-right",
      "selector": "far fa-arrow-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-right",
      "selector": "fal fa-arrow-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-right",
      "selector": "fad fa-arrow-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-down",
      "selector": "fas fa-arrow-square-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-down",
      "selector": "far fa-arrow-square-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-down",
      "selector": "fal fa-arrow-square-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-down",
      "selector": "fad fa-arrow-square-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-left",
      "selector": "fas fa-arrow-square-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-left",
      "selector": "far fa-arrow-square-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-left",
      "selector": "fal fa-arrow-square-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-left",
      "selector": "fad fa-arrow-square-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-right",
      "selector": "fas fa-arrow-square-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-right",
      "selector": "far fa-arrow-square-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-right",
      "selector": "fal fa-arrow-square-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-right",
      "selector": "fad fa-arrow-square-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-up",
      "selector": "fas fa-arrow-square-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-up",
      "selector": "far fa-arrow-square-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-up",
      "selector": "fal fa-arrow-square-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-square-up",
      "selector": "fad fa-arrow-square-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-bottom",
      "selector": "fas fa-arrow-to-bottom",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-bottom",
      "selector": "far fa-arrow-to-bottom",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-bottom",
      "selector": "fal fa-arrow-to-bottom",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-bottom",
      "selector": "fad fa-arrow-to-bottom",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-left",
      "selector": "fas fa-arrow-to-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-left",
      "selector": "far fa-arrow-to-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-left",
      "selector": "fal fa-arrow-to-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-left",
      "selector": "fad fa-arrow-to-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-right",
      "selector": "fas fa-arrow-to-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-right",
      "selector": "far fa-arrow-to-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-right",
      "selector": "fal fa-arrow-to-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-right",
      "selector": "fad fa-arrow-to-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-top",
      "selector": "fas fa-arrow-to-top",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-top",
      "selector": "far fa-arrow-to-top",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-top",
      "selector": "fal fa-arrow-to-top",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-to-top",
      "selector": "fad fa-arrow-to-top",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrow-up",
      "selector": "fas fa-arrow-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrow-up",
      "selector": "far fa-arrow-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrow-up",
      "selector": "fal fa-arrow-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrow-up",
      "selector": "fad fa-arrow-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrows",
      "selector": "fas fa-arrows",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrows",
      "selector": "far fa-arrows",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrows",
      "selector": "fal fa-arrows",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrows",
      "selector": "fad fa-arrows",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt",
      "selector": "fas fa-arrows-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt",
      "selector": "far fa-arrows-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt",
      "selector": "fal fa-arrows-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt",
      "selector": "fad fa-arrows-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-h",
      "selector": "fas fa-arrows-alt-h",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-h",
      "selector": "far fa-arrows-alt-h",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-h",
      "selector": "fal fa-arrows-alt-h",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-h",
      "selector": "fad fa-arrows-alt-h",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-v",
      "selector": "fas fa-arrows-alt-v",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-v",
      "selector": "far fa-arrows-alt-v",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-v",
      "selector": "fal fa-arrows-alt-v",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrows-alt-v",
      "selector": "fad fa-arrows-alt-v",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrows-h",
      "selector": "fas fa-arrows-h",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrows-h",
      "selector": "far fa-arrows-h",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrows-h",
      "selector": "fal fa-arrows-h",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrows-h",
      "selector": "fad fa-arrows-h",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "arrows-v",
      "selector": "fas fa-arrows-v",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "arrows-v",
      "selector": "far fa-arrows-v",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "arrows-v",
      "selector": "fal fa-arrows-v",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "arrows-v",
      "selector": "fad fa-arrows-v",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "artstation",
      "selector": "fab fa-artstation",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "assistive-listening-systems",
      "selector": "fas fa-assistive-listening-systems",
      "categories": [
        "Solid",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "assistive-listening-systems",
      "selector": "far fa-assistive-listening-systems",
      "categories": [
        "Regular",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "assistive-listening-systems",
      "selector": "fal fa-assistive-listening-systems",
      "categories": [
        "Light",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "assistive-listening-systems",
      "selector": "fad fa-assistive-listening-systems",
      "categories": [
        "Duotone",
        "Accessibility",
        "Communication"
      ]
    },
    {
      "name": "asterisk",
      "selector": "fas fa-asterisk",
      "categories": [
        "Solid",
        "Spinners"
      ]
    },
    {
      "name": "asterisk",
      "selector": "far fa-asterisk",
      "categories": [
        "Regular",
        "Spinners"
      ]
    },
    {
      "name": "asterisk",
      "selector": "fal fa-asterisk",
      "categories": [
        "Light",
        "Spinners"
      ]
    },
    {
      "name": "asterisk",
      "selector": "fad fa-asterisk",
      "categories": [
        "Duotone",
        "Spinners"
      ]
    },
    {
      "name": "asymmetrik",
      "selector": "fab fa-asymmetrik",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "at",
      "selector": "fas fa-at",
      "categories": [
        "Solid",
        "Communication"
      ]
    },
    {
      "name": "at",
      "selector": "far fa-at",
      "categories": [
        "Regular",
        "Communication"
      ]
    },
    {
      "name": "at",
      "selector": "fal fa-at",
      "categories": [
        "Light",
        "Communication"
      ]
    },
    {
      "name": "at",
      "selector": "fad fa-at",
      "categories": [
        "Duotone",
        "Communication"
      ]
    },
    {
      "name": "atlas",
      "selector": "fas fa-atlas",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "atlas",
      "selector": "far fa-atlas",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "atlas",
      "selector": "fal fa-atlas",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "atlas",
      "selector": "fad fa-atlas",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "atlassian",
      "selector": "fab fa-atlassian",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "atom",
      "selector": "fas fa-atom",
      "categories": [
        "Solid",
        "Education",
        "Energy",
        "Religion",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom",
      "selector": "far fa-atom",
      "categories": [
        "Regular",
        "Education",
        "Energy",
        "Religion",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom",
      "selector": "fal fa-atom",
      "categories": [
        "Light",
        "Education",
        "Energy",
        "Religion",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom",
      "selector": "fad fa-atom",
      "categories": [
        "Duotone",
        "Education",
        "Energy",
        "Religion",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom-alt",
      "selector": "fas fa-atom-alt",
      "categories": [
        "Solid",
        "Education",
        "Energy",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom-alt",
      "selector": "far fa-atom-alt",
      "categories": [
        "Regular",
        "Education",
        "Energy",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom-alt",
      "selector": "fal fa-atom-alt",
      "categories": [
        "Light",
        "Education",
        "Energy",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "atom-alt",
      "selector": "fad fa-atom-alt",
      "categories": [
        "Duotone",
        "Education",
        "Energy",
        "Science",
        "Science Fiction",
        "Spinners"
      ]
    },
    {
      "name": "audible",
      "selector": "fab fa-audible",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "audio-description",
      "selector": "fas fa-audio-description",
      "categories": [
        "Solid",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "audio-description",
      "selector": "far fa-audio-description",
      "categories": [
        "Regular",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "audio-description",
      "selector": "fal fa-audio-description",
      "categories": [
        "Light",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "audio-description",
      "selector": "fad fa-audio-description",
      "categories": [
        "Duotone",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "autoprefixer",
      "selector": "fab fa-autoprefixer",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "avianex",
      "selector": "fab fa-avianex",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "aviato",
      "selector": "fab fa-aviato",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "award",
      "selector": "fas fa-award",
      "categories": [
        "Solid",
        "Education",
        "Interfaces",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "award",
      "selector": "far fa-award",
      "categories": [
        "Regular",
        "Education",
        "Interfaces",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "award",
      "selector": "fal fa-award",
      "categories": [
        "Light",
        "Education",
        "Interfaces",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "award",
      "selector": "fad fa-award",
      "categories": [
        "Duotone",
        "Education",
        "Interfaces",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "aws",
      "selector": "fab fa-aws",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "axe",
      "selector": "fas fa-axe",
      "categories": [
        "Solid",
        "Autumn",
        "Camping",
        "Construction"
      ]
    },
    {
      "name": "axe",
      "selector": "far fa-axe",
      "categories": [
        "Regular",
        "Autumn",
        "Camping",
        "Construction"
      ]
    },
    {
      "name": "axe",
      "selector": "fal fa-axe",
      "categories": [
        "Light",
        "Autumn",
        "Camping",
        "Construction"
      ]
    },
    {
      "name": "axe",
      "selector": "fad fa-axe",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping",
        "Construction"
      ]
    },
    {
      "name": "axe-battle",
      "selector": "fas fa-axe-battle",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "axe-battle",
      "selector": "far fa-axe-battle",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "axe-battle",
      "selector": "fal fa-axe-battle",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "axe-battle",
      "selector": "fad fa-axe-battle",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "baby",
      "selector": "fas fa-baby",
      "categories": [
        "Solid",
        "Childhood",
        "Users & People"
      ]
    },
    {
      "name": "baby",
      "selector": "far fa-baby",
      "categories": [
        "Regular",
        "Childhood",
        "Users & People"
      ]
    },
    {
      "name": "baby",
      "selector": "fal fa-baby",
      "categories": [
        "Light",
        "Childhood",
        "Users & People"
      ]
    },
    {
      "name": "baby",
      "selector": "fad fa-baby",
      "categories": [
        "Duotone",
        "Childhood",
        "Users & People"
      ]
    },
    {
      "name": "baby-carriage",
      "selector": "fas fa-baby-carriage",
      "categories": [
        "Solid",
        "Childhood",
        "Hotel",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "baby-carriage",
      "selector": "far fa-baby-carriage",
      "categories": [
        "Regular",
        "Childhood",
        "Hotel",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "baby-carriage",
      "selector": "fal fa-baby-carriage",
      "categories": [
        "Light",
        "Childhood",
        "Hotel",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "baby-carriage",
      "selector": "fad fa-baby-carriage",
      "categories": [
        "Duotone",
        "Childhood",
        "Hotel",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "backpack",
      "selector": "fas fa-backpack",
      "categories": [
        "Solid",
        "Camping",
        "Childhood",
        "Education"
      ]
    },
    {
      "name": "backpack",
      "selector": "far fa-backpack",
      "categories": [
        "Regular",
        "Camping",
        "Childhood",
        "Education"
      ]
    },
    {
      "name": "backpack",
      "selector": "fal fa-backpack",
      "categories": [
        "Light",
        "Camping",
        "Childhood",
        "Education"
      ]
    },
    {
      "name": "backpack",
      "selector": "fad fa-backpack",
      "categories": [
        "Duotone",
        "Camping",
        "Childhood",
        "Education"
      ]
    },
    {
      "name": "backspace",
      "selector": "fas fa-backspace",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "backspace",
      "selector": "far fa-backspace",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "backspace",
      "selector": "fal fa-backspace",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "backspace",
      "selector": "fad fa-backspace",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "backward",
      "selector": "fas fa-backward",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "backward",
      "selector": "far fa-backward",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "backward",
      "selector": "fal fa-backward",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "backward",
      "selector": "fad fa-backward",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "bacon",
      "selector": "fas fa-bacon",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "bacon",
      "selector": "far fa-bacon",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "bacon",
      "selector": "fal fa-bacon",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "bacon",
      "selector": "fad fa-bacon",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "badge",
      "selector": "fas fa-badge",
      "categories": [
        "Solid",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shapes",
        "Shopping",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "badge",
      "selector": "far fa-badge",
      "categories": [
        "Regular",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shapes",
        "Shopping",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "badge",
      "selector": "fal fa-badge",
      "categories": [
        "Light",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shapes",
        "Shopping",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "badge",
      "selector": "fad fa-badge",
      "categories": [
        "Duotone",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shapes",
        "Shopping",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "badge-check",
      "selector": "fas fa-badge-check",
      "categories": [
        "Solid",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "badge-check",
      "selector": "far fa-badge-check",
      "categories": [
        "Regular",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "badge-check",
      "selector": "fal fa-badge-check",
      "categories": [
        "Light",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "badge-check",
      "selector": "fad fa-badge-check",
      "categories": [
        "Duotone",
        "Business",
        "Interfaces",
        "Marketing",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "badge-dollar",
      "selector": "fas fa-badge-dollar",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-dollar",
      "selector": "far fa-badge-dollar",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-dollar",
      "selector": "fal fa-badge-dollar",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-dollar",
      "selector": "fad fa-badge-dollar",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-percent",
      "selector": "fas fa-badge-percent",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-percent",
      "selector": "far fa-badge-percent",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-percent",
      "selector": "fal fa-badge-percent",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-percent",
      "selector": "fad fa-badge-percent",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "badge-sheriff",
      "selector": "fas fa-badge-sheriff",
      "categories": [
        "Solid",
        "Security"
      ]
    },
    {
      "name": "badge-sheriff",
      "selector": "far fa-badge-sheriff",
      "categories": [
        "Regular",
        "Security"
      ]
    },
    {
      "name": "badge-sheriff",
      "selector": "fal fa-badge-sheriff",
      "categories": [
        "Light",
        "Security"
      ]
    },
    {
      "name": "badge-sheriff",
      "selector": "fad fa-badge-sheriff",
      "categories": [
        "Duotone",
        "Security"
      ]
    },
    {
      "name": "badger-honey",
      "selector": "fas fa-badger-honey",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "badger-honey",
      "selector": "far fa-badger-honey",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "badger-honey",
      "selector": "fal fa-badger-honey",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "badger-honey",
      "selector": "fad fa-badger-honey",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "bags-shopping",
      "selector": "fas fa-bags-shopping",
      "categories": [
        "Solid",
        "Maps",
        "Payments & Shopping",
        "Shopping",
        "Social"
      ]
    },
    {
      "name": "bags-shopping",
      "selector": "far fa-bags-shopping",
      "categories": [
        "Regular",
        "Maps",
        "Payments & Shopping",
        "Shopping",
        "Social"
      ]
    },
    {
      "name": "bags-shopping",
      "selector": "fal fa-bags-shopping",
      "categories": [
        "Light",
        "Maps",
        "Payments & Shopping",
        "Shopping",
        "Social"
      ]
    },
    {
      "name": "bags-shopping",
      "selector": "fad fa-bags-shopping",
      "categories": [
        "Duotone",
        "Maps",
        "Payments & Shopping",
        "Shopping",
        "Social"
      ]
    },
    {
      "name": "bahai",
      "selector": "fas fa-bahai",
      "categories": [
        "Solid",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "bahai",
      "selector": "far fa-bahai",
      "categories": [
        "Regular",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "bahai",
      "selector": "fal fa-bahai",
      "categories": [
        "Light",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "bahai",
      "selector": "fad fa-bahai",
      "categories": [
        "Duotone",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "balance-scale",
      "selector": "fas fa-balance-scale",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale",
      "selector": "far fa-balance-scale",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale",
      "selector": "fal fa-balance-scale",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale",
      "selector": "fad fa-balance-scale",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-left",
      "selector": "fas fa-balance-scale-left",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-left",
      "selector": "far fa-balance-scale-left",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-left",
      "selector": "fal fa-balance-scale-left",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-left",
      "selector": "fad fa-balance-scale-left",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-right",
      "selector": "fas fa-balance-scale-right",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-right",
      "selector": "far fa-balance-scale-right",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-right",
      "selector": "fal fa-balance-scale-right",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "balance-scale-right",
      "selector": "fad fa-balance-scale-right",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Political"
      ]
    },
    {
      "name": "ball-pile",
      "selector": "fas fa-ball-pile",
      "categories": [
        "Solid",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ball-pile",
      "selector": "far fa-ball-pile",
      "categories": [
        "Regular",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ball-pile",
      "selector": "fal fa-ball-pile",
      "categories": [
        "Light",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ball-pile",
      "selector": "fad fa-ball-pile",
      "categories": [
        "Duotone",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ballot",
      "selector": "fas fa-ballot",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "ballot",
      "selector": "far fa-ballot",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "ballot",
      "selector": "fal fa-ballot",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "ballot",
      "selector": "fad fa-ballot",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "ballot-check",
      "selector": "fas fa-ballot-check",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "ballot-check",
      "selector": "far fa-ballot-check",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "ballot-check",
      "selector": "fal fa-ballot-check",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "ballot-check",
      "selector": "fad fa-ballot-check",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "ban",
      "selector": "fas fa-ban",
      "categories": [
        "Solid",
        "Interfaces",
        "Security",
        "Status"
      ]
    },
    {
      "name": "ban",
      "selector": "far fa-ban",
      "categories": [
        "Regular",
        "Interfaces",
        "Security",
        "Status"
      ]
    },
    {
      "name": "ban",
      "selector": "fal fa-ban",
      "categories": [
        "Light",
        "Interfaces",
        "Security",
        "Status"
      ]
    },
    {
      "name": "ban",
      "selector": "fad fa-ban",
      "categories": [
        "Duotone",
        "Interfaces",
        "Security",
        "Status"
      ]
    },
    {
      "name": "band-aid",
      "selector": "fas fa-band-aid",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "band-aid",
      "selector": "far fa-band-aid",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "band-aid",
      "selector": "fal fa-band-aid",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "band-aid",
      "selector": "fad fa-band-aid",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "bandcamp",
      "selector": "fab fa-bandcamp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "banjo",
      "selector": "fas fa-banjo",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "banjo",
      "selector": "far fa-banjo",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "banjo",
      "selector": "fal fa-banjo",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "banjo",
      "selector": "fad fa-banjo",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "barcode",
      "selector": "fas fa-barcode",
      "categories": [
        "Solid",
        "Code",
        "Interfaces",
        "Shopping"
      ]
    },
    {
      "name": "barcode",
      "selector": "far fa-barcode",
      "categories": [
        "Regular",
        "Code",
        "Interfaces",
        "Shopping"
      ]
    },
    {
      "name": "barcode",
      "selector": "fal fa-barcode",
      "categories": [
        "Light",
        "Code",
        "Interfaces",
        "Shopping"
      ]
    },
    {
      "name": "barcode",
      "selector": "fad fa-barcode",
      "categories": [
        "Duotone",
        "Code",
        "Interfaces",
        "Shopping"
      ]
    },
    {
      "name": "barcode-alt",
      "selector": "fas fa-barcode-alt",
      "categories": [
        "Solid",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-alt",
      "selector": "far fa-barcode-alt",
      "categories": [
        "Regular",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-alt",
      "selector": "fal fa-barcode-alt",
      "categories": [
        "Light",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-alt",
      "selector": "fad fa-barcode-alt",
      "categories": [
        "Duotone",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-read",
      "selector": "fas fa-barcode-read",
      "categories": [
        "Solid",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-read",
      "selector": "far fa-barcode-read",
      "categories": [
        "Regular",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-read",
      "selector": "fal fa-barcode-read",
      "categories": [
        "Light",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-read",
      "selector": "fad fa-barcode-read",
      "categories": [
        "Duotone",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-scan",
      "selector": "fas fa-barcode-scan",
      "categories": [
        "Solid",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-scan",
      "selector": "far fa-barcode-scan",
      "categories": [
        "Regular",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-scan",
      "selector": "fal fa-barcode-scan",
      "categories": [
        "Light",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "barcode-scan",
      "selector": "fad fa-barcode-scan",
      "categories": [
        "Duotone",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "bars",
      "selector": "fas fa-bars",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "bars",
      "selector": "far fa-bars",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "bars",
      "selector": "fal fa-bars",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "bars",
      "selector": "fad fa-bars",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "baseball",
      "selector": "fas fa-baseball",
      "categories": [
        "Solid",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "baseball",
      "selector": "far fa-baseball",
      "categories": [
        "Regular",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "baseball",
      "selector": "fal fa-baseball",
      "categories": [
        "Light",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "baseball",
      "selector": "fad fa-baseball",
      "categories": [
        "Duotone",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "baseball-ball",
      "selector": "fas fa-baseball-ball",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "baseball-ball",
      "selector": "far fa-baseball-ball",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "baseball-ball",
      "selector": "fal fa-baseball-ball",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "baseball-ball",
      "selector": "fad fa-baseball-ball",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "basketball-ball",
      "selector": "fas fa-basketball-ball",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "basketball-ball",
      "selector": "far fa-basketball-ball",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "basketball-ball",
      "selector": "fal fa-basketball-ball",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "basketball-ball",
      "selector": "fad fa-basketball-ball",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "basketball-hoop",
      "selector": "fas fa-basketball-hoop",
      "categories": [
        "Solid",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "basketball-hoop",
      "selector": "far fa-basketball-hoop",
      "categories": [
        "Regular",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "basketball-hoop",
      "selector": "fal fa-basketball-hoop",
      "categories": [
        "Light",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "basketball-hoop",
      "selector": "fad fa-basketball-hoop",
      "categories": [
        "Duotone",
        "Childhood",
        "Sports"
      ]
    },
    {
      "name": "bat",
      "selector": "fas fa-bat",
      "categories": [
        "Solid",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "bat",
      "selector": "far fa-bat",
      "categories": [
        "Regular",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "bat",
      "selector": "fal fa-bat",
      "categories": [
        "Light",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "bat",
      "selector": "fad fa-bat",
      "categories": [
        "Duotone",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "bath",
      "selector": "fas fa-bath",
      "categories": [
        "Solid",
        "Childhood",
        "Code",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bath",
      "selector": "far fa-bath",
      "categories": [
        "Regular",
        "Childhood",
        "Code",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bath",
      "selector": "fal fa-bath",
      "categories": [
        "Light",
        "Childhood",
        "Code",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bath",
      "selector": "fad fa-bath",
      "categories": [
        "Duotone",
        "Childhood",
        "Code",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "battery-bolt",
      "selector": "fas fa-battery-bolt",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-bolt",
      "selector": "far fa-battery-bolt",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-bolt",
      "selector": "fal fa-battery-bolt",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-bolt",
      "selector": "fad fa-battery-bolt",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-empty",
      "selector": "fas fa-battery-empty",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-empty",
      "selector": "far fa-battery-empty",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-empty",
      "selector": "fal fa-battery-empty",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-empty",
      "selector": "fad fa-battery-empty",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-full",
      "selector": "fas fa-battery-full",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-full",
      "selector": "far fa-battery-full",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-full",
      "selector": "fal fa-battery-full",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-full",
      "selector": "fad fa-battery-full",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-half",
      "selector": "fas fa-battery-half",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-half",
      "selector": "far fa-battery-half",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-half",
      "selector": "fal fa-battery-half",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-half",
      "selector": "fad fa-battery-half",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-quarter",
      "selector": "fas fa-battery-quarter",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-quarter",
      "selector": "far fa-battery-quarter",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-quarter",
      "selector": "fal fa-battery-quarter",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-quarter",
      "selector": "fad fa-battery-quarter",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-slash",
      "selector": "fas fa-battery-slash",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-slash",
      "selector": "far fa-battery-slash",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-slash",
      "selector": "fal fa-battery-slash",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-slash",
      "selector": "fad fa-battery-slash",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-three-quarters",
      "selector": "fas fa-battery-three-quarters",
      "categories": [
        "Solid",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-three-quarters",
      "selector": "far fa-battery-three-quarters",
      "categories": [
        "Regular",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-three-quarters",
      "selector": "fal fa-battery-three-quarters",
      "categories": [
        "Light",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battery-three-quarters",
      "selector": "fad fa-battery-three-quarters",
      "categories": [
        "Duotone",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "battle-net",
      "selector": "fab fa-battle-net",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "bed",
      "selector": "fas fa-bed",
      "categories": [
        "Solid",
        "Hotel",
        "Household",
        "Maps",
        "Objects",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "bed",
      "selector": "far fa-bed",
      "categories": [
        "Regular",
        "Hotel",
        "Household",
        "Maps",
        "Objects",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "bed",
      "selector": "fal fa-bed",
      "categories": [
        "Light",
        "Hotel",
        "Household",
        "Maps",
        "Objects",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "bed",
      "selector": "fad fa-bed",
      "categories": [
        "Duotone",
        "Hotel",
        "Household",
        "Maps",
        "Objects",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "bed-alt",
      "selector": "fas fa-bed-alt",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "bed-alt",
      "selector": "far fa-bed-alt",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "bed-alt",
      "selector": "fal fa-bed-alt",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "bed-alt",
      "selector": "fad fa-bed-alt",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "bed-bunk",
      "selector": "fas fa-bed-bunk",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-bunk",
      "selector": "far fa-bed-bunk",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-bunk",
      "selector": "fal fa-bed-bunk",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-bunk",
      "selector": "fad fa-bed-bunk",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-empty",
      "selector": "fas fa-bed-empty",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-empty",
      "selector": "far fa-bed-empty",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-empty",
      "selector": "fal fa-bed-empty",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "bed-empty",
      "selector": "fad fa-bed-empty",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "beer",
      "selector": "fas fa-beer",
      "categories": [
        "Solid",
        "Beverage",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "beer",
      "selector": "far fa-beer",
      "categories": [
        "Regular",
        "Beverage",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "beer",
      "selector": "fal fa-beer",
      "categories": [
        "Light",
        "Beverage",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "beer",
      "selector": "fad fa-beer",
      "categories": [
        "Duotone",
        "Beverage",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "behance",
      "selector": "fab fa-behance",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "behance-square",
      "selector": "fab fa-behance-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "bell",
      "selector": "fas fa-bell",
      "categories": [
        "Solid",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Household",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "bell",
      "selector": "far fa-bell",
      "categories": [
        "Regular",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Household",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "bell",
      "selector": "fal fa-bell",
      "categories": [
        "Light",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Household",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "bell",
      "selector": "fad fa-bell",
      "categories": [
        "Duotone",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Household",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "bell-exclamation",
      "selector": "fas fa-bell-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-exclamation",
      "selector": "far fa-bell-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-exclamation",
      "selector": "fal fa-bell-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-exclamation",
      "selector": "fad fa-bell-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-on",
      "selector": "fas fa-bell-on",
      "categories": [
        "Solid",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "bell-on",
      "selector": "far fa-bell-on",
      "categories": [
        "Regular",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "bell-on",
      "selector": "fal fa-bell-on",
      "categories": [
        "Light",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "bell-on",
      "selector": "fad fa-bell-on",
      "categories": [
        "Duotone",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "bell-plus",
      "selector": "fas fa-bell-plus",
      "categories": [
        "Solid",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-plus",
      "selector": "far fa-bell-plus",
      "categories": [
        "Regular",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-plus",
      "selector": "fal fa-bell-plus",
      "categories": [
        "Light",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-plus",
      "selector": "fad fa-bell-plus",
      "categories": [
        "Duotone",
        "Communication",
        "Date & Time"
      ]
    },
    {
      "name": "bell-school",
      "selector": "fas fa-bell-school",
      "categories": [
        "Solid",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school",
      "selector": "far fa-bell-school",
      "categories": [
        "Regular",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school",
      "selector": "fal fa-bell-school",
      "categories": [
        "Light",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school",
      "selector": "fad fa-bell-school",
      "categories": [
        "Duotone",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school-slash",
      "selector": "fas fa-bell-school-slash",
      "categories": [
        "Solid",
        "Alert",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school-slash",
      "selector": "far fa-bell-school-slash",
      "categories": [
        "Regular",
        "Alert",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school-slash",
      "selector": "fal fa-bell-school-slash",
      "categories": [
        "Light",
        "Alert",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-school-slash",
      "selector": "fad fa-bell-school-slash",
      "categories": [
        "Duotone",
        "Alert",
        "Childhood",
        "Education",
        "Status"
      ]
    },
    {
      "name": "bell-slash",
      "selector": "fas fa-bell-slash",
      "categories": [
        "Solid",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "bell-slash",
      "selector": "far fa-bell-slash",
      "categories": [
        "Regular",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "bell-slash",
      "selector": "fal fa-bell-slash",
      "categories": [
        "Light",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "bell-slash",
      "selector": "fad fa-bell-slash",
      "categories": [
        "Duotone",
        "Alert",
        "Communication",
        "Date & Time",
        "Education",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "bells",
      "selector": "fas fa-bells",
      "categories": [
        "Solid",
        "Alert",
        "Holiday",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "bells",
      "selector": "far fa-bells",
      "categories": [
        "Regular",
        "Alert",
        "Holiday",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "bells",
      "selector": "fal fa-bells",
      "categories": [
        "Light",
        "Alert",
        "Holiday",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "bells",
      "selector": "fad fa-bells",
      "categories": [
        "Duotone",
        "Alert",
        "Holiday",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "betamax",
      "selector": "fas fa-betamax",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "betamax",
      "selector": "far fa-betamax",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "betamax",
      "selector": "fal fa-betamax",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "betamax",
      "selector": "fad fa-betamax",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "bezier-curve",
      "selector": "fas fa-bezier-curve",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "bezier-curve",
      "selector": "far fa-bezier-curve",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "bezier-curve",
      "selector": "fal fa-bezier-curve",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "bezier-curve",
      "selector": "fad fa-bezier-curve",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "bible",
      "selector": "fas fa-bible",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "bible",
      "selector": "far fa-bible",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "bible",
      "selector": "fal fa-bible",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "bible",
      "selector": "fad fa-bible",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "bicycle",
      "selector": "fas fa-bicycle",
      "categories": [
        "Solid",
        "Fitness",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "bicycle",
      "selector": "far fa-bicycle",
      "categories": [
        "Regular",
        "Fitness",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "bicycle",
      "selector": "fal fa-bicycle",
      "categories": [
        "Light",
        "Fitness",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "bicycle",
      "selector": "fad fa-bicycle",
      "categories": [
        "Duotone",
        "Fitness",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "biking",
      "selector": "fas fa-biking",
      "categories": [
        "Solid",
        "Childhood",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking",
      "selector": "far fa-biking",
      "categories": [
        "Regular",
        "Childhood",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking",
      "selector": "fal fa-biking",
      "categories": [
        "Light",
        "Childhood",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking",
      "selector": "fad fa-biking",
      "categories": [
        "Duotone",
        "Childhood",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking-mountain",
      "selector": "fas fa-biking-mountain",
      "categories": [
        "Solid",
        "Camping",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking-mountain",
      "selector": "far fa-biking-mountain",
      "categories": [
        "Regular",
        "Camping",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking-mountain",
      "selector": "fal fa-biking-mountain",
      "categories": [
        "Light",
        "Camping",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "biking-mountain",
      "selector": "fad fa-biking-mountain",
      "categories": [
        "Duotone",
        "Camping",
        "Fitness",
        "Sports",
        "Summer",
        "Users & People"
      ]
    },
    {
      "name": "bimobject",
      "selector": "fab fa-bimobject",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "binoculars",
      "selector": "fas fa-binoculars",
      "categories": [
        "Solid",
        "Camping",
        "Maps",
        "Maritime",
        "Objects"
      ]
    },
    {
      "name": "binoculars",
      "selector": "far fa-binoculars",
      "categories": [
        "Regular",
        "Camping",
        "Maps",
        "Maritime",
        "Objects"
      ]
    },
    {
      "name": "binoculars",
      "selector": "fal fa-binoculars",
      "categories": [
        "Light",
        "Camping",
        "Maps",
        "Maritime",
        "Objects"
      ]
    },
    {
      "name": "binoculars",
      "selector": "fad fa-binoculars",
      "categories": [
        "Duotone",
        "Camping",
        "Maps",
        "Maritime",
        "Objects"
      ]
    },
    {
      "name": "biohazard",
      "selector": "fas fa-biohazard",
      "categories": [
        "Solid",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "biohazard",
      "selector": "far fa-biohazard",
      "categories": [
        "Regular",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "biohazard",
      "selector": "fal fa-biohazard",
      "categories": [
        "Light",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "biohazard",
      "selector": "fad fa-biohazard",
      "categories": [
        "Duotone",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "birthday-cake",
      "selector": "fas fa-birthday-cake",
      "categories": [
        "Solid",
        "Business",
        "Childhood",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "birthday-cake",
      "selector": "far fa-birthday-cake",
      "categories": [
        "Regular",
        "Business",
        "Childhood",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "birthday-cake",
      "selector": "fal fa-birthday-cake",
      "categories": [
        "Light",
        "Business",
        "Childhood",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "birthday-cake",
      "selector": "fad fa-birthday-cake",
      "categories": [
        "Duotone",
        "Business",
        "Childhood",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "bitbucket",
      "selector": "fab fa-bitbucket",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "bitcoin",
      "selector": "fab fa-bitcoin",
      "categories": [
        "Brands",
        "Currency",
        "Payments & Shopping"
      ]
    },
    {
      "name": "bity",
      "selector": "fab fa-bity",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "black-tie",
      "selector": "fab fa-black-tie",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "blackberry",
      "selector": "fab fa-blackberry",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "blanket",
      "selector": "fas fa-blanket",
      "categories": [
        "Solid",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "blanket",
      "selector": "far fa-blanket",
      "categories": [
        "Regular",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "blanket",
      "selector": "fal fa-blanket",
      "categories": [
        "Light",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "blanket",
      "selector": "fad fa-blanket",
      "categories": [
        "Duotone",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "blender",
      "selector": "fas fa-blender",
      "categories": [
        "Solid",
        "Beverage",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "blender",
      "selector": "far fa-blender",
      "categories": [
        "Regular",
        "Beverage",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "blender",
      "selector": "fal fa-blender",
      "categories": [
        "Light",
        "Beverage",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "blender",
      "selector": "fad fa-blender",
      "categories": [
        "Duotone",
        "Beverage",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "blender-phone",
      "selector": "fas fa-blender-phone",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "blender-phone",
      "selector": "far fa-blender-phone",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "blender-phone",
      "selector": "fal fa-blender-phone",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "blender-phone",
      "selector": "fad fa-blender-phone",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "blind",
      "selector": "fas fa-blind",
      "categories": [
        "Solid",
        "Accessibility",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "blind",
      "selector": "far fa-blind",
      "categories": [
        "Regular",
        "Accessibility",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "blind",
      "selector": "fal fa-blind",
      "categories": [
        "Light",
        "Accessibility",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "blind",
      "selector": "fad fa-blind",
      "categories": [
        "Duotone",
        "Accessibility",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "blinds",
      "selector": "fas fa-blinds",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "blinds",
      "selector": "far fa-blinds",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "blinds",
      "selector": "fal fa-blinds",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "blinds",
      "selector": "fad fa-blinds",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "blinds-open",
      "selector": "fas fa-blinds-open",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "blinds-open",
      "selector": "far fa-blinds-open",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "blinds-open",
      "selector": "fal fa-blinds-open",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "blinds-open",
      "selector": "fad fa-blinds-open",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "blinds-raised",
      "selector": "fas fa-blinds-raised",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "blinds-raised",
      "selector": "far fa-blinds-raised",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "blinds-raised",
      "selector": "fal fa-blinds-raised",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "blinds-raised",
      "selector": "fad fa-blinds-raised",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "blog",
      "selector": "fas fa-blog",
      "categories": [
        "Solid",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "blog",
      "selector": "far fa-blog",
      "categories": [
        "Regular",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "blog",
      "selector": "fal fa-blog",
      "categories": [
        "Light",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "blog",
      "selector": "fad fa-blog",
      "categories": [
        "Duotone",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "blogger",
      "selector": "fab fa-blogger",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "blogger-b",
      "selector": "fab fa-blogger-b",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "bluetooth",
      "selector": "fab fa-bluetooth",
      "categories": [
        "Brands",
        "Communication"
      ]
    },
    {
      "name": "bluetooth-b",
      "selector": "fab fa-bluetooth-b",
      "categories": [
        "Brands",
        "Communication"
      ]
    },
    {
      "name": "bold",
      "selector": "fas fa-bold",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "bold",
      "selector": "far fa-bold",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "bold",
      "selector": "fal fa-bold",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "bold",
      "selector": "fad fa-bold",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "bolt",
      "selector": "fas fa-bolt",
      "categories": [
        "Solid",
        "Images",
        "Weather"
      ]
    },
    {
      "name": "bolt",
      "selector": "far fa-bolt",
      "categories": [
        "Regular",
        "Images",
        "Weather"
      ]
    },
    {
      "name": "bolt",
      "selector": "fal fa-bolt",
      "categories": [
        "Light",
        "Images",
        "Weather"
      ]
    },
    {
      "name": "bolt",
      "selector": "fad fa-bolt",
      "categories": [
        "Duotone",
        "Images",
        "Weather"
      ]
    },
    {
      "name": "bomb",
      "selector": "fas fa-bomb",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bomb",
      "selector": "far fa-bomb",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bomb",
      "selector": "fal fa-bomb",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bomb",
      "selector": "fad fa-bomb",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bone",
      "selector": "fas fa-bone",
      "categories": [
        "Solid",
        "Food",
        "Medical"
      ]
    },
    {
      "name": "bone",
      "selector": "far fa-bone",
      "categories": [
        "Regular",
        "Food",
        "Medical"
      ]
    },
    {
      "name": "bone",
      "selector": "fal fa-bone",
      "categories": [
        "Light",
        "Food",
        "Medical"
      ]
    },
    {
      "name": "bone",
      "selector": "fad fa-bone",
      "categories": [
        "Duotone",
        "Food",
        "Medical"
      ]
    },
    {
      "name": "bone-break",
      "selector": "fas fa-bone-break",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "bone-break",
      "selector": "far fa-bone-break",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "bone-break",
      "selector": "fal fa-bone-break",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "bone-break",
      "selector": "fad fa-bone-break",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "bong",
      "selector": "fas fa-bong",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "bong",
      "selector": "far fa-bong",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "bong",
      "selector": "fal fa-bong",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "bong",
      "selector": "fad fa-bong",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "book",
      "selector": "fas fa-book",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "book",
      "selector": "far fa-book",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "book",
      "selector": "fal fa-book",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "book",
      "selector": "fad fa-book",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "book-alt",
      "selector": "fas fa-book-alt",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "book-alt",
      "selector": "far fa-book-alt",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "book-alt",
      "selector": "fal fa-book-alt",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "book-alt",
      "selector": "fad fa-book-alt",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "book-dead",
      "selector": "fas fa-book-dead",
      "categories": [
        "Solid",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-dead",
      "selector": "far fa-book-dead",
      "categories": [
        "Regular",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-dead",
      "selector": "fal fa-book-dead",
      "categories": [
        "Light",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-dead",
      "selector": "fad fa-book-dead",
      "categories": [
        "Duotone",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-heart",
      "selector": "fas fa-book-heart",
      "categories": [
        "Solid",
        "Charity",
        "Fitness"
      ]
    },
    {
      "name": "book-heart",
      "selector": "far fa-book-heart",
      "categories": [
        "Regular",
        "Charity",
        "Fitness"
      ]
    },
    {
      "name": "book-heart",
      "selector": "fal fa-book-heart",
      "categories": [
        "Light",
        "Charity",
        "Fitness"
      ]
    },
    {
      "name": "book-heart",
      "selector": "fad fa-book-heart",
      "categories": [
        "Duotone",
        "Charity",
        "Fitness"
      ]
    },
    {
      "name": "book-medical",
      "selector": "fas fa-book-medical",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "book-medical",
      "selector": "far fa-book-medical",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "book-medical",
      "selector": "fal fa-book-medical",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "book-medical",
      "selector": "fad fa-book-medical",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "book-open",
      "selector": "fas fa-book-open",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "book-open",
      "selector": "far fa-book-open",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "book-open",
      "selector": "fal fa-book-open",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "book-open",
      "selector": "fad fa-book-open",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "book-reader",
      "selector": "fas fa-book-reader",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "book-reader",
      "selector": "far fa-book-reader",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "book-reader",
      "selector": "fal fa-book-reader",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "book-reader",
      "selector": "fad fa-book-reader",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "book-spells",
      "selector": "fas fa-book-spells",
      "categories": [
        "Solid",
        "Halloween",
        "Objects",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-spells",
      "selector": "far fa-book-spells",
      "categories": [
        "Regular",
        "Halloween",
        "Objects",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-spells",
      "selector": "fal fa-book-spells",
      "categories": [
        "Light",
        "Halloween",
        "Objects",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-spells",
      "selector": "fad fa-book-spells",
      "categories": [
        "Duotone",
        "Halloween",
        "Objects",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "book-user",
      "selector": "fas fa-book-user",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "book-user",
      "selector": "far fa-book-user",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "book-user",
      "selector": "fal fa-book-user",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "book-user",
      "selector": "fad fa-book-user",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "bookmark",
      "selector": "fas fa-bookmark",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Writing"
      ]
    },
    {
      "name": "bookmark",
      "selector": "far fa-bookmark",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Writing"
      ]
    },
    {
      "name": "bookmark",
      "selector": "fal fa-bookmark",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Writing"
      ]
    },
    {
      "name": "bookmark",
      "selector": "fad fa-bookmark",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Writing"
      ]
    },
    {
      "name": "books",
      "selector": "fas fa-books",
      "categories": [
        "Solid",
        "Education",
        "Household"
      ]
    },
    {
      "name": "books",
      "selector": "far fa-books",
      "categories": [
        "Regular",
        "Education",
        "Household"
      ]
    },
    {
      "name": "books",
      "selector": "fal fa-books",
      "categories": [
        "Light",
        "Education",
        "Household"
      ]
    },
    {
      "name": "books",
      "selector": "fad fa-books",
      "categories": [
        "Duotone",
        "Education",
        "Household"
      ]
    },
    {
      "name": "books-medical",
      "selector": "fas fa-books-medical",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "books-medical",
      "selector": "far fa-books-medical",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "books-medical",
      "selector": "fal fa-books-medical",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "books-medical",
      "selector": "fad fa-books-medical",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "boombox",
      "selector": "fas fa-boombox",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "boombox",
      "selector": "far fa-boombox",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "boombox",
      "selector": "fal fa-boombox",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "boombox",
      "selector": "fad fa-boombox",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "boot",
      "selector": "fas fa-boot",
      "categories": [
        "Solid",
        "Camping",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "boot",
      "selector": "far fa-boot",
      "categories": [
        "Regular",
        "Camping",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "boot",
      "selector": "fal fa-boot",
      "categories": [
        "Light",
        "Camping",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "boot",
      "selector": "fad fa-boot",
      "categories": [
        "Duotone",
        "Camping",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "booth-curtain",
      "selector": "fas fa-booth-curtain",
      "categories": [
        "Solid",
        "Political",
        "Shopping"
      ]
    },
    {
      "name": "booth-curtain",
      "selector": "far fa-booth-curtain",
      "categories": [
        "Regular",
        "Political",
        "Shopping"
      ]
    },
    {
      "name": "booth-curtain",
      "selector": "fal fa-booth-curtain",
      "categories": [
        "Light",
        "Political",
        "Shopping"
      ]
    },
    {
      "name": "booth-curtain",
      "selector": "fad fa-booth-curtain",
      "categories": [
        "Duotone",
        "Political",
        "Shopping"
      ]
    },
    {
      "name": "bootstrap",
      "selector": "fab fa-bootstrap",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "border-all",
      "selector": "fas fa-border-all",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-all",
      "selector": "far fa-border-all",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-all",
      "selector": "fal fa-border-all",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-all",
      "selector": "fad fa-border-all",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-bottom",
      "selector": "fas fa-border-bottom",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-bottom",
      "selector": "far fa-border-bottom",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-bottom",
      "selector": "fal fa-border-bottom",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-bottom",
      "selector": "fad fa-border-bottom",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-center-h",
      "selector": "fas fa-border-center-h",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-center-h",
      "selector": "far fa-border-center-h",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-center-h",
      "selector": "fal fa-border-center-h",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-center-h",
      "selector": "fad fa-border-center-h",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-center-v",
      "selector": "fas fa-border-center-v",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-center-v",
      "selector": "far fa-border-center-v",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-center-v",
      "selector": "fal fa-border-center-v",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-center-v",
      "selector": "fad fa-border-center-v",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-inner",
      "selector": "fas fa-border-inner",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-inner",
      "selector": "far fa-border-inner",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-inner",
      "selector": "fal fa-border-inner",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-inner",
      "selector": "fad fa-border-inner",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-left",
      "selector": "fas fa-border-left",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-left",
      "selector": "far fa-border-left",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-left",
      "selector": "fal fa-border-left",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-left",
      "selector": "fad fa-border-left",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-none",
      "selector": "fas fa-border-none",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-none",
      "selector": "far fa-border-none",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-none",
      "selector": "fal fa-border-none",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-none",
      "selector": "fad fa-border-none",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-outer",
      "selector": "fas fa-border-outer",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-outer",
      "selector": "far fa-border-outer",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-outer",
      "selector": "fal fa-border-outer",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-outer",
      "selector": "fad fa-border-outer",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-right",
      "selector": "fas fa-border-right",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-right",
      "selector": "far fa-border-right",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-right",
      "selector": "fal fa-border-right",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-right",
      "selector": "fad fa-border-right",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-style",
      "selector": "fas fa-border-style",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-style",
      "selector": "far fa-border-style",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-style",
      "selector": "fal fa-border-style",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-style",
      "selector": "fad fa-border-style",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-style-alt",
      "selector": "fas fa-border-style-alt",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-style-alt",
      "selector": "far fa-border-style-alt",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-style-alt",
      "selector": "fal fa-border-style-alt",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-style-alt",
      "selector": "fad fa-border-style-alt",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "border-top",
      "selector": "fas fa-border-top",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "border-top",
      "selector": "far fa-border-top",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "border-top",
      "selector": "fal fa-border-top",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "border-top",
      "selector": "fad fa-border-top",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "bow-arrow",
      "selector": "fas fa-bow-arrow",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "bow-arrow",
      "selector": "far fa-bow-arrow",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "bow-arrow",
      "selector": "fal fa-bow-arrow",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "bow-arrow",
      "selector": "fad fa-bow-arrow",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "bowling-ball",
      "selector": "fas fa-bowling-ball",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "bowling-ball",
      "selector": "far fa-bowling-ball",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "bowling-ball",
      "selector": "fal fa-bowling-ball",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "bowling-ball",
      "selector": "fad fa-bowling-ball",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "bowling-pins",
      "selector": "fas fa-bowling-pins",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "bowling-pins",
      "selector": "far fa-bowling-pins",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "bowling-pins",
      "selector": "fal fa-bowling-pins",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "bowling-pins",
      "selector": "fad fa-bowling-pins",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "box",
      "selector": "fas fa-box",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "box",
      "selector": "far fa-box",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "box",
      "selector": "fal fa-box",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "box",
      "selector": "fad fa-box",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "box-alt",
      "selector": "fas fa-box-alt",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "box-alt",
      "selector": "far fa-box-alt",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "box-alt",
      "selector": "fal fa-box-alt",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "box-alt",
      "selector": "fad fa-box-alt",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "box-ballot",
      "selector": "fas fa-box-ballot",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "box-ballot",
      "selector": "far fa-box-ballot",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "box-ballot",
      "selector": "fal fa-box-ballot",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "box-ballot",
      "selector": "fad fa-box-ballot",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "box-check",
      "selector": "fas fa-box-check",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "box-check",
      "selector": "far fa-box-check",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "box-check",
      "selector": "fal fa-box-check",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "box-check",
      "selector": "fad fa-box-check",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "box-fragile",
      "selector": "fas fa-box-fragile",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "box-fragile",
      "selector": "far fa-box-fragile",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "box-fragile",
      "selector": "fal fa-box-fragile",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "box-fragile",
      "selector": "fad fa-box-fragile",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "box-full",
      "selector": "fas fa-box-full",
      "categories": [
        "Solid",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "box-full",
      "selector": "far fa-box-full",
      "categories": [
        "Regular",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "box-full",
      "selector": "fal fa-box-full",
      "categories": [
        "Light",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "box-full",
      "selector": "fad fa-box-full",
      "categories": [
        "Duotone",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "box-heart",
      "selector": "fas fa-box-heart",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "box-heart",
      "selector": "far fa-box-heart",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "box-heart",
      "selector": "fal fa-box-heart",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "box-heart",
      "selector": "fad fa-box-heart",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "box-open",
      "selector": "fas fa-box-open",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "box-open",
      "selector": "far fa-box-open",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "box-open",
      "selector": "fal fa-box-open",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "box-open",
      "selector": "fad fa-box-open",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "box-up",
      "selector": "fas fa-box-up",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "box-up",
      "selector": "far fa-box-up",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "box-up",
      "selector": "fal fa-box-up",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "box-up",
      "selector": "fad fa-box-up",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "box-usd",
      "selector": "fas fa-box-usd",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "box-usd",
      "selector": "far fa-box-usd",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "box-usd",
      "selector": "fal fa-box-usd",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "box-usd",
      "selector": "fad fa-box-usd",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "boxes",
      "selector": "fas fa-boxes",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "boxes",
      "selector": "far fa-boxes",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "boxes",
      "selector": "fal fa-boxes",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "boxes",
      "selector": "fad fa-boxes",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "boxes-alt",
      "selector": "fas fa-boxes-alt",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "boxes-alt",
      "selector": "far fa-boxes-alt",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "boxes-alt",
      "selector": "fal fa-boxes-alt",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "boxes-alt",
      "selector": "fad fa-boxes-alt",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "boxing-glove",
      "selector": "fas fa-boxing-glove",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "boxing-glove",
      "selector": "far fa-boxing-glove",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "boxing-glove",
      "selector": "fal fa-boxing-glove",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "boxing-glove",
      "selector": "fad fa-boxing-glove",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "brackets",
      "selector": "fas fa-brackets",
      "categories": [
        "Solid",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets",
      "selector": "far fa-brackets",
      "categories": [
        "Regular",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets",
      "selector": "fal fa-brackets",
      "categories": [
        "Light",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets",
      "selector": "fad fa-brackets",
      "categories": [
        "Duotone",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets-curly",
      "selector": "fas fa-brackets-curly",
      "categories": [
        "Solid",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets-curly",
      "selector": "far fa-brackets-curly",
      "categories": [
        "Regular",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets-curly",
      "selector": "fal fa-brackets-curly",
      "categories": [
        "Light",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "brackets-curly",
      "selector": "fad fa-brackets-curly",
      "categories": [
        "Duotone",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "braille",
      "selector": "fas fa-braille",
      "categories": [
        "Solid",
        "Accessibility"
      ]
    },
    {
      "name": "braille",
      "selector": "far fa-braille",
      "categories": [
        "Regular",
        "Accessibility"
      ]
    },
    {
      "name": "braille",
      "selector": "fal fa-braille",
      "categories": [
        "Light",
        "Accessibility"
      ]
    },
    {
      "name": "braille",
      "selector": "fad fa-braille",
      "categories": [
        "Duotone",
        "Accessibility"
      ]
    },
    {
      "name": "brain",
      "selector": "fas fa-brain",
      "categories": [
        "Solid",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "brain",
      "selector": "far fa-brain",
      "categories": [
        "Regular",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "brain",
      "selector": "fal fa-brain",
      "categories": [
        "Light",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "brain",
      "selector": "fad fa-brain",
      "categories": [
        "Duotone",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "bread-loaf",
      "selector": "fas fa-bread-loaf",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "bread-loaf",
      "selector": "far fa-bread-loaf",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "bread-loaf",
      "selector": "fal fa-bread-loaf",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "bread-loaf",
      "selector": "fad fa-bread-loaf",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "bread-slice",
      "selector": "fas fa-bread-slice",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "bread-slice",
      "selector": "far fa-bread-slice",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "bread-slice",
      "selector": "fal fa-bread-slice",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "bread-slice",
      "selector": "fad fa-bread-slice",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "briefcase",
      "selector": "fas fa-briefcase",
      "categories": [
        "Solid",
        "Business",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "briefcase",
      "selector": "far fa-briefcase",
      "categories": [
        "Regular",
        "Business",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "briefcase",
      "selector": "fal fa-briefcase",
      "categories": [
        "Light",
        "Business",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "briefcase",
      "selector": "fad fa-briefcase",
      "categories": [
        "Duotone",
        "Business",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "briefcase-medical",
      "selector": "fas fa-briefcase-medical",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "briefcase-medical",
      "selector": "far fa-briefcase-medical",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "briefcase-medical",
      "selector": "fal fa-briefcase-medical",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "briefcase-medical",
      "selector": "fad fa-briefcase-medical",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "bring-forward",
      "selector": "fas fa-bring-forward",
      "categories": [
        "Solid",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-forward",
      "selector": "far fa-bring-forward",
      "categories": [
        "Regular",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-forward",
      "selector": "fal fa-bring-forward",
      "categories": [
        "Light",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-forward",
      "selector": "fad fa-bring-forward",
      "categories": [
        "Duotone",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-front",
      "selector": "fas fa-bring-front",
      "categories": [
        "Solid",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-front",
      "selector": "far fa-bring-front",
      "categories": [
        "Regular",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-front",
      "selector": "fal fa-bring-front",
      "categories": [
        "Light",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "bring-front",
      "selector": "fad fa-bring-front",
      "categories": [
        "Duotone",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "broadcast-tower",
      "selector": "fas fa-broadcast-tower",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Energy",
        "Objects"
      ]
    },
    {
      "name": "broadcast-tower",
      "selector": "far fa-broadcast-tower",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Energy",
        "Objects"
      ]
    },
    {
      "name": "broadcast-tower",
      "selector": "fal fa-broadcast-tower",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Energy",
        "Objects"
      ]
    },
    {
      "name": "broadcast-tower",
      "selector": "fad fa-broadcast-tower",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Energy",
        "Objects"
      ]
    },
    {
      "name": "broom",
      "selector": "fas fa-broom",
      "categories": [
        "Solid",
        "Halloween",
        "Spring"
      ]
    },
    {
      "name": "broom",
      "selector": "far fa-broom",
      "categories": [
        "Regular",
        "Halloween",
        "Spring"
      ]
    },
    {
      "name": "broom",
      "selector": "fal fa-broom",
      "categories": [
        "Light",
        "Halloween",
        "Spring"
      ]
    },
    {
      "name": "broom",
      "selector": "fad fa-broom",
      "categories": [
        "Duotone",
        "Halloween",
        "Spring"
      ]
    },
    {
      "name": "browser",
      "selector": "fas fa-browser",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "browser",
      "selector": "far fa-browser",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "browser",
      "selector": "fal fa-browser",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "browser",
      "selector": "fad fa-browser",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "brush",
      "selector": "fas fa-brush",
      "categories": [
        "Solid",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "brush",
      "selector": "far fa-brush",
      "categories": [
        "Regular",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "brush",
      "selector": "fal fa-brush",
      "categories": [
        "Light",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "brush",
      "selector": "fad fa-brush",
      "categories": [
        "Duotone",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "btc",
      "selector": "fab fa-btc",
      "categories": [
        "Brands",
        "Currency",
        "Payments & Shopping"
      ]
    },
    {
      "name": "buffer",
      "selector": "fab fa-buffer",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "bug",
      "selector": "fas fa-bug",
      "categories": [
        "Solid",
        "Code",
        "Interfaces",
        "Objects",
        "Security"
      ]
    },
    {
      "name": "bug",
      "selector": "far fa-bug",
      "categories": [
        "Regular",
        "Code",
        "Interfaces",
        "Objects",
        "Security"
      ]
    },
    {
      "name": "bug",
      "selector": "fal fa-bug",
      "categories": [
        "Light",
        "Code",
        "Interfaces",
        "Objects",
        "Security"
      ]
    },
    {
      "name": "bug",
      "selector": "fad fa-bug",
      "categories": [
        "Duotone",
        "Code",
        "Interfaces",
        "Objects",
        "Security"
      ]
    },
    {
      "name": "building",
      "selector": "fas fa-building",
      "categories": [
        "Solid",
        "Buildings",
        "Business",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "building",
      "selector": "far fa-building",
      "categories": [
        "Regular",
        "Buildings",
        "Business",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "building",
      "selector": "fal fa-building",
      "categories": [
        "Light",
        "Buildings",
        "Business",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "building",
      "selector": "fad fa-building",
      "categories": [
        "Duotone",
        "Buildings",
        "Business",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "bullhorn",
      "selector": "fas fa-bullhorn",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Interfaces",
        "Marketing",
        "Objects",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "bullhorn",
      "selector": "far fa-bullhorn",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Interfaces",
        "Marketing",
        "Objects",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "bullhorn",
      "selector": "fal fa-bullhorn",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Interfaces",
        "Marketing",
        "Objects",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "bullhorn",
      "selector": "fad fa-bullhorn",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Interfaces",
        "Marketing",
        "Objects",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "bullseye",
      "selector": "fas fa-bullseye",
      "categories": [
        "Solid",
        "Business",
        "Interfaces",
        "Marketing",
        "Objects",
        "Toggle"
      ]
    },
    {
      "name": "bullseye",
      "selector": "far fa-bullseye",
      "categories": [
        "Regular",
        "Business",
        "Interfaces",
        "Marketing",
        "Objects",
        "Toggle"
      ]
    },
    {
      "name": "bullseye",
      "selector": "fal fa-bullseye",
      "categories": [
        "Light",
        "Business",
        "Interfaces",
        "Marketing",
        "Objects",
        "Toggle"
      ]
    },
    {
      "name": "bullseye",
      "selector": "fad fa-bullseye",
      "categories": [
        "Duotone",
        "Business",
        "Interfaces",
        "Marketing",
        "Objects",
        "Toggle"
      ]
    },
    {
      "name": "bullseye-arrow",
      "selector": "fas fa-bullseye-arrow",
      "categories": [
        "Solid",
        "Marketing",
        "Spinners",
        "Toggle"
      ]
    },
    {
      "name": "bullseye-arrow",
      "selector": "far fa-bullseye-arrow",
      "categories": [
        "Regular",
        "Marketing",
        "Spinners",
        "Toggle"
      ]
    },
    {
      "name": "bullseye-arrow",
      "selector": "fal fa-bullseye-arrow",
      "categories": [
        "Light",
        "Marketing",
        "Spinners",
        "Toggle"
      ]
    },
    {
      "name": "bullseye-arrow",
      "selector": "fad fa-bullseye-arrow",
      "categories": [
        "Duotone",
        "Marketing",
        "Spinners",
        "Toggle"
      ]
    },
    {
      "name": "bullseye-pointer",
      "selector": "fas fa-bullseye-pointer",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "bullseye-pointer",
      "selector": "far fa-bullseye-pointer",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "bullseye-pointer",
      "selector": "fal fa-bullseye-pointer",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "bullseye-pointer",
      "selector": "fad fa-bullseye-pointer",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "burger-soda",
      "selector": "fas fa-burger-soda",
      "categories": [
        "Solid",
        "Beverage",
        "Food"
      ]
    },
    {
      "name": "burger-soda",
      "selector": "far fa-burger-soda",
      "categories": [
        "Regular",
        "Beverage",
        "Food"
      ]
    },
    {
      "name": "burger-soda",
      "selector": "fal fa-burger-soda",
      "categories": [
        "Light",
        "Beverage",
        "Food"
      ]
    },
    {
      "name": "burger-soda",
      "selector": "fad fa-burger-soda",
      "categories": [
        "Duotone",
        "Beverage",
        "Food"
      ]
    },
    {
      "name": "burn",
      "selector": "fas fa-burn",
      "categories": [
        "Solid",
        "Energy",
        "Fitness",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "burn",
      "selector": "far fa-burn",
      "categories": [
        "Regular",
        "Energy",
        "Fitness",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "burn",
      "selector": "fal fa-burn",
      "categories": [
        "Light",
        "Energy",
        "Fitness",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "burn",
      "selector": "fad fa-burn",
      "categories": [
        "Duotone",
        "Energy",
        "Fitness",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "buromobelexperte",
      "selector": "fab fa-buromobelexperte",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "burrito",
      "selector": "fas fa-burrito",
      "categories": [
        "Solid",
        "Energy",
        "Food"
      ]
    },
    {
      "name": "burrito",
      "selector": "far fa-burrito",
      "categories": [
        "Regular",
        "Energy",
        "Food"
      ]
    },
    {
      "name": "burrito",
      "selector": "fal fa-burrito",
      "categories": [
        "Light",
        "Energy",
        "Food"
      ]
    },
    {
      "name": "burrito",
      "selector": "fad fa-burrito",
      "categories": [
        "Duotone",
        "Energy",
        "Food"
      ]
    },
    {
      "name": "bus",
      "selector": "fas fa-bus",
      "categories": [
        "Solid",
        "Automotive",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus",
      "selector": "far fa-bus",
      "categories": [
        "Regular",
        "Automotive",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus",
      "selector": "fal fa-bus",
      "categories": [
        "Light",
        "Automotive",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus",
      "selector": "fad fa-bus",
      "categories": [
        "Duotone",
        "Automotive",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus-alt",
      "selector": "fas fa-bus-alt",
      "categories": [
        "Solid",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus-alt",
      "selector": "far fa-bus-alt",
      "categories": [
        "Regular",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus-alt",
      "selector": "fal fa-bus-alt",
      "categories": [
        "Light",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus-alt",
      "selector": "fad fa-bus-alt",
      "categories": [
        "Duotone",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "bus-school",
      "selector": "fas fa-bus-school",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "bus-school",
      "selector": "far fa-bus-school",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "bus-school",
      "selector": "fal fa-bus-school",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "bus-school",
      "selector": "fad fa-bus-school",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "business-time",
      "selector": "fas fa-business-time",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "business-time",
      "selector": "far fa-business-time",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "business-time",
      "selector": "fal fa-business-time",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "business-time",
      "selector": "fad fa-business-time",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "buy-n-large",
      "selector": "fab fa-buy-n-large",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "buysellads",
      "selector": "fab fa-buysellads",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "cabinet-filing",
      "selector": "fas fa-cabinet-filing",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "cabinet-filing",
      "selector": "far fa-cabinet-filing",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "cabinet-filing",
      "selector": "fal fa-cabinet-filing",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "cabinet-filing",
      "selector": "fad fa-cabinet-filing",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "cactus",
      "selector": "fas fa-cactus",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "cactus",
      "selector": "far fa-cactus",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "cactus",
      "selector": "fal fa-cactus",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "cactus",
      "selector": "fad fa-cactus",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "calculator",
      "selector": "fas fa-calculator",
      "categories": [
        "Solid",
        "Business",
        "Interfaces",
        "Mathematics",
        "Objects"
      ]
    },
    {
      "name": "calculator",
      "selector": "far fa-calculator",
      "categories": [
        "Regular",
        "Business",
        "Interfaces",
        "Mathematics",
        "Objects"
      ]
    },
    {
      "name": "calculator",
      "selector": "fal fa-calculator",
      "categories": [
        "Light",
        "Business",
        "Interfaces",
        "Mathematics",
        "Objects"
      ]
    },
    {
      "name": "calculator",
      "selector": "fad fa-calculator",
      "categories": [
        "Duotone",
        "Business",
        "Interfaces",
        "Mathematics",
        "Objects"
      ]
    },
    {
      "name": "calculator-alt",
      "selector": "fas fa-calculator-alt",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "calculator-alt",
      "selector": "far fa-calculator-alt",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "calculator-alt",
      "selector": "fal fa-calculator-alt",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "calculator-alt",
      "selector": "fad fa-calculator-alt",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "calendar",
      "selector": "fas fa-calendar",
      "categories": [
        "Solid",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "calendar",
      "selector": "far fa-calendar",
      "categories": [
        "Regular",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "calendar",
      "selector": "fal fa-calendar",
      "categories": [
        "Light",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "calendar",
      "selector": "fad fa-calendar",
      "categories": [
        "Duotone",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "calendar-alt",
      "selector": "fas fa-calendar-alt",
      "categories": [
        "Solid",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "calendar-alt",
      "selector": "far fa-calendar-alt",
      "categories": [
        "Regular",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "calendar-alt",
      "selector": "fal fa-calendar-alt",
      "categories": [
        "Light",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "calendar-alt",
      "selector": "fad fa-calendar-alt",
      "categories": [
        "Duotone",
        "Business",
        "Date & Time",
        "Interfaces",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "calendar-check",
      "selector": "fas fa-calendar-check",
      "categories": [
        "Solid",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-check",
      "selector": "far fa-calendar-check",
      "categories": [
        "Regular",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-check",
      "selector": "fal fa-calendar-check",
      "categories": [
        "Light",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-check",
      "selector": "fad fa-calendar-check",
      "categories": [
        "Duotone",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-day",
      "selector": "fas fa-calendar-day",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "calendar-day",
      "selector": "far fa-calendar-day",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "calendar-day",
      "selector": "fal fa-calendar-day",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "calendar-day",
      "selector": "fad fa-calendar-day",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "calendar-edit",
      "selector": "fas fa-calendar-edit",
      "categories": [
        "Solid",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-edit",
      "selector": "far fa-calendar-edit",
      "categories": [
        "Regular",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-edit",
      "selector": "fal fa-calendar-edit",
      "categories": [
        "Light",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-edit",
      "selector": "fad fa-calendar-edit",
      "categories": [
        "Duotone",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-exclamation",
      "selector": "fas fa-calendar-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-exclamation",
      "selector": "far fa-calendar-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-exclamation",
      "selector": "fal fa-calendar-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-exclamation",
      "selector": "fad fa-calendar-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-minus",
      "selector": "fas fa-calendar-minus",
      "categories": [
        "Solid",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-minus",
      "selector": "far fa-calendar-minus",
      "categories": [
        "Regular",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-minus",
      "selector": "fal fa-calendar-minus",
      "categories": [
        "Light",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-minus",
      "selector": "fad fa-calendar-minus",
      "categories": [
        "Duotone",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-plus",
      "selector": "fas fa-calendar-plus",
      "categories": [
        "Solid",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-plus",
      "selector": "far fa-calendar-plus",
      "categories": [
        "Regular",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-plus",
      "selector": "fal fa-calendar-plus",
      "categories": [
        "Light",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-plus",
      "selector": "fad fa-calendar-plus",
      "categories": [
        "Duotone",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-star",
      "selector": "fas fa-calendar-star",
      "categories": [
        "Solid",
        "Political",
        "Status"
      ]
    },
    {
      "name": "calendar-star",
      "selector": "far fa-calendar-star",
      "categories": [
        "Regular",
        "Political",
        "Status"
      ]
    },
    {
      "name": "calendar-star",
      "selector": "fal fa-calendar-star",
      "categories": [
        "Light",
        "Political",
        "Status"
      ]
    },
    {
      "name": "calendar-star",
      "selector": "fad fa-calendar-star",
      "categories": [
        "Duotone",
        "Political",
        "Status"
      ]
    },
    {
      "name": "calendar-times",
      "selector": "fas fa-calendar-times",
      "categories": [
        "Solid",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-times",
      "selector": "far fa-calendar-times",
      "categories": [
        "Regular",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-times",
      "selector": "fal fa-calendar-times",
      "categories": [
        "Light",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-times",
      "selector": "fad fa-calendar-times",
      "categories": [
        "Duotone",
        "Date & Time",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "calendar-week",
      "selector": "fas fa-calendar-week",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "calendar-week",
      "selector": "far fa-calendar-week",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "calendar-week",
      "selector": "fal fa-calendar-week",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "calendar-week",
      "selector": "fad fa-calendar-week",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "camcorder",
      "selector": "fas fa-camcorder",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "camcorder",
      "selector": "far fa-camcorder",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "camcorder",
      "selector": "fal fa-camcorder",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "camcorder",
      "selector": "fad fa-camcorder",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "camera",
      "selector": "fas fa-camera",
      "categories": [
        "Solid",
        "Images",
        "Objects",
        "Payments & Shopping",
        "Social"
      ]
    },
    {
      "name": "camera",
      "selector": "far fa-camera",
      "categories": [
        "Regular",
        "Images",
        "Objects",
        "Payments & Shopping",
        "Social"
      ]
    },
    {
      "name": "camera",
      "selector": "fal fa-camera",
      "categories": [
        "Light",
        "Images",
        "Objects",
        "Payments & Shopping",
        "Social"
      ]
    },
    {
      "name": "camera",
      "selector": "fad fa-camera",
      "categories": [
        "Duotone",
        "Images",
        "Objects",
        "Payments & Shopping",
        "Social"
      ]
    },
    {
      "name": "camera-alt",
      "selector": "fas fa-camera-alt",
      "categories": [
        "Solid",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-alt",
      "selector": "far fa-camera-alt",
      "categories": [
        "Regular",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-alt",
      "selector": "fal fa-camera-alt",
      "categories": [
        "Light",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-alt",
      "selector": "fad fa-camera-alt",
      "categories": [
        "Duotone",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-home",
      "selector": "fas fa-camera-home",
      "categories": [
        "Solid",
        "Audio & Video",
        "Household"
      ]
    },
    {
      "name": "camera-home",
      "selector": "far fa-camera-home",
      "categories": [
        "Regular",
        "Audio & Video",
        "Household"
      ]
    },
    {
      "name": "camera-home",
      "selector": "fal fa-camera-home",
      "categories": [
        "Light",
        "Audio & Video",
        "Household"
      ]
    },
    {
      "name": "camera-home",
      "selector": "fad fa-camera-home",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Household"
      ]
    },
    {
      "name": "camera-movie",
      "selector": "fas fa-camera-movie",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "camera-movie",
      "selector": "far fa-camera-movie",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "camera-movie",
      "selector": "fal fa-camera-movie",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "camera-movie",
      "selector": "fad fa-camera-movie",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "camera-polaroid",
      "selector": "fas fa-camera-polaroid",
      "categories": [
        "Solid",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "camera-polaroid",
      "selector": "far fa-camera-polaroid",
      "categories": [
        "Regular",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "camera-polaroid",
      "selector": "fal fa-camera-polaroid",
      "categories": [
        "Light",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "camera-polaroid",
      "selector": "fad fa-camera-polaroid",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "camera-retro",
      "selector": "fas fa-camera-retro",
      "categories": [
        "Solid",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-retro",
      "selector": "far fa-camera-retro",
      "categories": [
        "Regular",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-retro",
      "selector": "fal fa-camera-retro",
      "categories": [
        "Light",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "camera-retro",
      "selector": "fad fa-camera-retro",
      "categories": [
        "Duotone",
        "Images",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "campfire",
      "selector": "fas fa-campfire",
      "categories": [
        "Solid",
        "Autumn",
        "Camping",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "campfire",
      "selector": "far fa-campfire",
      "categories": [
        "Regular",
        "Autumn",
        "Camping",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "campfire",
      "selector": "fal fa-campfire",
      "categories": [
        "Light",
        "Autumn",
        "Camping",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "campfire",
      "selector": "fad fa-campfire",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "campground",
      "selector": "fas fa-campground",
      "categories": [
        "Solid",
        "Autumn",
        "Buildings",
        "Camping"
      ]
    },
    {
      "name": "campground",
      "selector": "far fa-campground",
      "categories": [
        "Regular",
        "Autumn",
        "Buildings",
        "Camping"
      ]
    },
    {
      "name": "campground",
      "selector": "fal fa-campground",
      "categories": [
        "Light",
        "Autumn",
        "Buildings",
        "Camping"
      ]
    },
    {
      "name": "campground",
      "selector": "fad fa-campground",
      "categories": [
        "Duotone",
        "Autumn",
        "Buildings",
        "Camping"
      ]
    },
    {
      "name": "canadian-maple-leaf",
      "selector": "fab fa-canadian-maple-leaf",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "candle-holder",
      "selector": "fas fa-candle-holder",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "candle-holder",
      "selector": "far fa-candle-holder",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "candle-holder",
      "selector": "fal fa-candle-holder",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "candle-holder",
      "selector": "fad fa-candle-holder",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "candy-cane",
      "selector": "fas fa-candy-cane",
      "categories": [
        "Solid",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "candy-cane",
      "selector": "far fa-candy-cane",
      "categories": [
        "Regular",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "candy-cane",
      "selector": "fal fa-candy-cane",
      "categories": [
        "Light",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "candy-cane",
      "selector": "fad fa-candy-cane",
      "categories": [
        "Duotone",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "candy-corn",
      "selector": "fas fa-candy-corn",
      "categories": [
        "Solid",
        "Food",
        "Halloween"
      ]
    },
    {
      "name": "candy-corn",
      "selector": "far fa-candy-corn",
      "categories": [
        "Regular",
        "Food",
        "Halloween"
      ]
    },
    {
      "name": "candy-corn",
      "selector": "fal fa-candy-corn",
      "categories": [
        "Light",
        "Food",
        "Halloween"
      ]
    },
    {
      "name": "candy-corn",
      "selector": "fad fa-candy-corn",
      "categories": [
        "Duotone",
        "Food",
        "Halloween"
      ]
    },
    {
      "name": "cannabis",
      "selector": "fas fa-cannabis",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "cannabis",
      "selector": "far fa-cannabis",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "cannabis",
      "selector": "fal fa-cannabis",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "cannabis",
      "selector": "fad fa-cannabis",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "capsules",
      "selector": "fas fa-capsules",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "capsules",
      "selector": "far fa-capsules",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "capsules",
      "selector": "fal fa-capsules",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "capsules",
      "selector": "fad fa-capsules",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "car",
      "selector": "fas fa-car",
      "categories": [
        "Solid",
        "Automotive",
        "Hotel",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "car",
      "selector": "far fa-car",
      "categories": [
        "Regular",
        "Automotive",
        "Hotel",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "car",
      "selector": "fal fa-car",
      "categories": [
        "Light",
        "Automotive",
        "Hotel",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "car",
      "selector": "fad fa-car",
      "categories": [
        "Duotone",
        "Automotive",
        "Hotel",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "car-alt",
      "selector": "fas fa-car-alt",
      "categories": [
        "Solid",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-alt",
      "selector": "far fa-car-alt",
      "categories": [
        "Regular",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-alt",
      "selector": "fal fa-car-alt",
      "categories": [
        "Light",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-alt",
      "selector": "fad fa-car-alt",
      "categories": [
        "Duotone",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-battery",
      "selector": "fas fa-car-battery",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "car-battery",
      "selector": "far fa-car-battery",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "car-battery",
      "selector": "fal fa-car-battery",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "car-battery",
      "selector": "fad fa-car-battery",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "car-building",
      "selector": "fas fa-car-building",
      "categories": [
        "Solid",
        "Automotive",
        "Buildings",
        "Hotel",
        "Vehicles"
      ]
    },
    {
      "name": "car-building",
      "selector": "far fa-car-building",
      "categories": [
        "Regular",
        "Automotive",
        "Buildings",
        "Hotel",
        "Vehicles"
      ]
    },
    {
      "name": "car-building",
      "selector": "fal fa-car-building",
      "categories": [
        "Light",
        "Automotive",
        "Buildings",
        "Hotel",
        "Vehicles"
      ]
    },
    {
      "name": "car-building",
      "selector": "fad fa-car-building",
      "categories": [
        "Duotone",
        "Automotive",
        "Buildings",
        "Hotel",
        "Vehicles"
      ]
    },
    {
      "name": "car-bump",
      "selector": "fas fa-car-bump",
      "categories": [
        "Solid",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-bump",
      "selector": "far fa-car-bump",
      "categories": [
        "Regular",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-bump",
      "selector": "fal fa-car-bump",
      "categories": [
        "Light",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-bump",
      "selector": "fad fa-car-bump",
      "categories": [
        "Duotone",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-bus",
      "selector": "fas fa-car-bus",
      "categories": [
        "Solid",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "car-bus",
      "selector": "far fa-car-bus",
      "categories": [
        "Regular",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "car-bus",
      "selector": "fal fa-car-bus",
      "categories": [
        "Light",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "car-bus",
      "selector": "fad fa-car-bus",
      "categories": [
        "Duotone",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "car-crash",
      "selector": "fas fa-car-crash",
      "categories": [
        "Solid",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-crash",
      "selector": "far fa-car-crash",
      "categories": [
        "Regular",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-crash",
      "selector": "fal fa-car-crash",
      "categories": [
        "Light",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-crash",
      "selector": "fad fa-car-crash",
      "categories": [
        "Duotone",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-garage",
      "selector": "fas fa-car-garage",
      "categories": [
        "Solid",
        "Automotive",
        "Hotel"
      ]
    },
    {
      "name": "car-garage",
      "selector": "far fa-car-garage",
      "categories": [
        "Regular",
        "Automotive",
        "Hotel"
      ]
    },
    {
      "name": "car-garage",
      "selector": "fal fa-car-garage",
      "categories": [
        "Light",
        "Automotive",
        "Hotel"
      ]
    },
    {
      "name": "car-garage",
      "selector": "fad fa-car-garage",
      "categories": [
        "Duotone",
        "Automotive",
        "Hotel"
      ]
    },
    {
      "name": "car-mechanic",
      "selector": "fas fa-car-mechanic",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "car-mechanic",
      "selector": "far fa-car-mechanic",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "car-mechanic",
      "selector": "fal fa-car-mechanic",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "car-mechanic",
      "selector": "fad fa-car-mechanic",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "car-side",
      "selector": "fas fa-car-side",
      "categories": [
        "Solid",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-side",
      "selector": "far fa-car-side",
      "categories": [
        "Regular",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-side",
      "selector": "fal fa-car-side",
      "categories": [
        "Light",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-side",
      "selector": "fad fa-car-side",
      "categories": [
        "Duotone",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-tilt",
      "selector": "fas fa-car-tilt",
      "categories": [
        "Solid",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-tilt",
      "selector": "far fa-car-tilt",
      "categories": [
        "Regular",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-tilt",
      "selector": "fal fa-car-tilt",
      "categories": [
        "Light",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-tilt",
      "selector": "fad fa-car-tilt",
      "categories": [
        "Duotone",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "car-wash",
      "selector": "fas fa-car-wash",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "car-wash",
      "selector": "far fa-car-wash",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "car-wash",
      "selector": "fal fa-car-wash",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "car-wash",
      "selector": "fad fa-car-wash",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "caravan",
      "selector": "fas fa-caravan",
      "categories": [
        "Solid",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan",
      "selector": "far fa-caravan",
      "categories": [
        "Regular",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan",
      "selector": "fal fa-caravan",
      "categories": [
        "Light",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan",
      "selector": "fad fa-caravan",
      "categories": [
        "Duotone",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan-alt",
      "selector": "fas fa-caravan-alt",
      "categories": [
        "Solid",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan-alt",
      "selector": "far fa-caravan-alt",
      "categories": [
        "Regular",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan-alt",
      "selector": "fal fa-caravan-alt",
      "categories": [
        "Light",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caravan-alt",
      "selector": "fad fa-caravan-alt",
      "categories": [
        "Duotone",
        "Automotive",
        "Camping",
        "Moving",
        "Travel"
      ]
    },
    {
      "name": "caret-circle-down",
      "selector": "fas fa-caret-circle-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-down",
      "selector": "far fa-caret-circle-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-down",
      "selector": "fal fa-caret-circle-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-down",
      "selector": "fad fa-caret-circle-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-left",
      "selector": "fas fa-caret-circle-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-left",
      "selector": "far fa-caret-circle-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-left",
      "selector": "fal fa-caret-circle-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-left",
      "selector": "fad fa-caret-circle-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-right",
      "selector": "fas fa-caret-circle-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-right",
      "selector": "far fa-caret-circle-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-right",
      "selector": "fal fa-caret-circle-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-right",
      "selector": "fad fa-caret-circle-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-up",
      "selector": "fas fa-caret-circle-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-up",
      "selector": "far fa-caret-circle-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-up",
      "selector": "fal fa-caret-circle-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-circle-up",
      "selector": "fad fa-caret-circle-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-down",
      "selector": "fas fa-caret-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-down",
      "selector": "far fa-caret-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-down",
      "selector": "fal fa-caret-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-down",
      "selector": "fad fa-caret-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-left",
      "selector": "fas fa-caret-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-left",
      "selector": "far fa-caret-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-left",
      "selector": "fal fa-caret-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-left",
      "selector": "fad fa-caret-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-right",
      "selector": "fas fa-caret-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-right",
      "selector": "far fa-caret-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-right",
      "selector": "fal fa-caret-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-right",
      "selector": "fad fa-caret-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-down",
      "selector": "fas fa-caret-square-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-down",
      "selector": "far fa-caret-square-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-down",
      "selector": "fal fa-caret-square-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-down",
      "selector": "fad fa-caret-square-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-left",
      "selector": "fas fa-caret-square-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-left",
      "selector": "far fa-caret-square-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-left",
      "selector": "fal fa-caret-square-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-left",
      "selector": "fad fa-caret-square-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-right",
      "selector": "fas fa-caret-square-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-right",
      "selector": "far fa-caret-square-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-right",
      "selector": "fal fa-caret-square-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-right",
      "selector": "fad fa-caret-square-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-up",
      "selector": "fas fa-caret-square-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-up",
      "selector": "far fa-caret-square-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-up",
      "selector": "fal fa-caret-square-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-square-up",
      "selector": "fad fa-caret-square-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "caret-up",
      "selector": "fas fa-caret-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "caret-up",
      "selector": "far fa-caret-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "caret-up",
      "selector": "fal fa-caret-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "caret-up",
      "selector": "fad fa-caret-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "carrot",
      "selector": "fas fa-carrot",
      "categories": [
        "Solid",
        "Food",
        "Fruits & Vegetables",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "carrot",
      "selector": "far fa-carrot",
      "categories": [
        "Regular",
        "Food",
        "Fruits & Vegetables",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "carrot",
      "selector": "fal fa-carrot",
      "categories": [
        "Light",
        "Food",
        "Fruits & Vegetables",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "carrot",
      "selector": "fad fa-carrot",
      "categories": [
        "Duotone",
        "Food",
        "Fruits & Vegetables",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "cars",
      "selector": "fas fa-cars",
      "categories": [
        "Solid",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "cars",
      "selector": "far fa-cars",
      "categories": [
        "Regular",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "cars",
      "selector": "fal fa-cars",
      "categories": [
        "Light",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "cars",
      "selector": "fad fa-cars",
      "categories": [
        "Duotone",
        "Automotive",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "cart-arrow-down",
      "selector": "fas fa-cart-arrow-down",
      "categories": [
        "Solid",
        "Arrows",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-arrow-down",
      "selector": "far fa-cart-arrow-down",
      "categories": [
        "Regular",
        "Arrows",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-arrow-down",
      "selector": "fal fa-cart-arrow-down",
      "categories": [
        "Light",
        "Arrows",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-arrow-down",
      "selector": "fad fa-cart-arrow-down",
      "categories": [
        "Duotone",
        "Arrows",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-plus",
      "selector": "fas fa-cart-plus",
      "categories": [
        "Solid",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-plus",
      "selector": "far fa-cart-plus",
      "categories": [
        "Regular",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-plus",
      "selector": "fal fa-cart-plus",
      "categories": [
        "Light",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cart-plus",
      "selector": "fad fa-cart-plus",
      "categories": [
        "Duotone",
        "Payments & Shopping",
        "Shopping",
        "Status"
      ]
    },
    {
      "name": "cash-register",
      "selector": "fas fa-cash-register",
      "categories": [
        "Solid",
        "Finance",
        "Shopping"
      ]
    },
    {
      "name": "cash-register",
      "selector": "far fa-cash-register",
      "categories": [
        "Regular",
        "Finance",
        "Shopping"
      ]
    },
    {
      "name": "cash-register",
      "selector": "fal fa-cash-register",
      "categories": [
        "Light",
        "Finance",
        "Shopping"
      ]
    },
    {
      "name": "cash-register",
      "selector": "fad fa-cash-register",
      "categories": [
        "Duotone",
        "Finance",
        "Shopping"
      ]
    },
    {
      "name": "cassette-tape",
      "selector": "fas fa-cassette-tape",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "cassette-tape",
      "selector": "far fa-cassette-tape",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "cassette-tape",
      "selector": "fal fa-cassette-tape",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "cassette-tape",
      "selector": "fad fa-cassette-tape",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "cat",
      "selector": "fas fa-cat",
      "categories": [
        "Solid",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "cat",
      "selector": "far fa-cat",
      "categories": [
        "Regular",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "cat",
      "selector": "fal fa-cat",
      "categories": [
        "Light",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "cat",
      "selector": "fad fa-cat",
      "categories": [
        "Duotone",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "cat-space",
      "selector": "fas fa-cat-space",
      "categories": [
        "Solid",
        "Animals",
        "Science Fiction"
      ]
    },
    {
      "name": "cat-space",
      "selector": "far fa-cat-space",
      "categories": [
        "Regular",
        "Animals",
        "Science Fiction"
      ]
    },
    {
      "name": "cat-space",
      "selector": "fal fa-cat-space",
      "categories": [
        "Light",
        "Animals",
        "Science Fiction"
      ]
    },
    {
      "name": "cat-space",
      "selector": "fad fa-cat-space",
      "categories": [
        "Duotone",
        "Animals",
        "Science Fiction"
      ]
    },
    {
      "name": "cauldron",
      "selector": "fas fa-cauldron",
      "categories": [
        "Solid",
        "Camping",
        "Halloween",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "cauldron",
      "selector": "far fa-cauldron",
      "categories": [
        "Regular",
        "Camping",
        "Halloween",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "cauldron",
      "selector": "fal fa-cauldron",
      "categories": [
        "Light",
        "Camping",
        "Halloween",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "cauldron",
      "selector": "fad fa-cauldron",
      "categories": [
        "Duotone",
        "Camping",
        "Halloween",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "cc-amazon-pay",
      "selector": "fab fa-cc-amazon-pay",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-amex",
      "selector": "fab fa-cc-amex",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-apple-pay",
      "selector": "fab fa-cc-apple-pay",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-diners-club",
      "selector": "fab fa-cc-diners-club",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-discover",
      "selector": "fab fa-cc-discover",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-jcb",
      "selector": "fab fa-cc-jcb",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-mastercard",
      "selector": "fab fa-cc-mastercard",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-paypal",
      "selector": "fab fa-cc-paypal",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-stripe",
      "selector": "fab fa-cc-stripe",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cc-visa",
      "selector": "fab fa-cc-visa",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cctv",
      "selector": "fas fa-cctv",
      "categories": [
        "Solid",
        "Household",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "cctv",
      "selector": "far fa-cctv",
      "categories": [
        "Regular",
        "Household",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "cctv",
      "selector": "fal fa-cctv",
      "categories": [
        "Light",
        "Household",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "cctv",
      "selector": "fad fa-cctv",
      "categories": [
        "Duotone",
        "Household",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "centercode",
      "selector": "fab fa-centercode",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "centos",
      "selector": "fab fa-centos",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "certificate",
      "selector": "fas fa-certificate",
      "categories": [
        "Solid",
        "Business",
        "Interfaces",
        "Payments & Shopping",
        "Shapes",
        "Spinners"
      ]
    },
    {
      "name": "certificate",
      "selector": "far fa-certificate",
      "categories": [
        "Regular",
        "Business",
        "Interfaces",
        "Payments & Shopping",
        "Shapes",
        "Spinners"
      ]
    },
    {
      "name": "certificate",
      "selector": "fal fa-certificate",
      "categories": [
        "Light",
        "Business",
        "Interfaces",
        "Payments & Shopping",
        "Shapes",
        "Spinners"
      ]
    },
    {
      "name": "certificate",
      "selector": "fad fa-certificate",
      "categories": [
        "Duotone",
        "Business",
        "Interfaces",
        "Payments & Shopping",
        "Shapes",
        "Spinners"
      ]
    },
    {
      "name": "chair",
      "selector": "fas fa-chair",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "chair",
      "selector": "far fa-chair",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "chair",
      "selector": "fal fa-chair",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "chair",
      "selector": "fad fa-chair",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "chair-office",
      "selector": "fas fa-chair-office",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "chair-office",
      "selector": "far fa-chair-office",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "chair-office",
      "selector": "fal fa-chair-office",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "chair-office",
      "selector": "fad fa-chair-office",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "chalkboard",
      "selector": "fas fa-chalkboard",
      "categories": [
        "Solid",
        "Communication",
        "Education",
        "Images"
      ]
    },
    {
      "name": "chalkboard",
      "selector": "far fa-chalkboard",
      "categories": [
        "Regular",
        "Communication",
        "Education",
        "Images"
      ]
    },
    {
      "name": "chalkboard",
      "selector": "fal fa-chalkboard",
      "categories": [
        "Light",
        "Communication",
        "Education",
        "Images"
      ]
    },
    {
      "name": "chalkboard",
      "selector": "fad fa-chalkboard",
      "categories": [
        "Duotone",
        "Communication",
        "Education",
        "Images"
      ]
    },
    {
      "name": "chalkboard-teacher",
      "selector": "fas fa-chalkboard-teacher",
      "categories": [
        "Solid",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "chalkboard-teacher",
      "selector": "far fa-chalkboard-teacher",
      "categories": [
        "Regular",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "chalkboard-teacher",
      "selector": "fal fa-chalkboard-teacher",
      "categories": [
        "Light",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "chalkboard-teacher",
      "selector": "fad fa-chalkboard-teacher",
      "categories": [
        "Duotone",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "charging-station",
      "selector": "fas fa-charging-station",
      "categories": [
        "Solid",
        "Automotive",
        "Energy"
      ]
    },
    {
      "name": "charging-station",
      "selector": "far fa-charging-station",
      "categories": [
        "Regular",
        "Automotive",
        "Energy"
      ]
    },
    {
      "name": "charging-station",
      "selector": "fal fa-charging-station",
      "categories": [
        "Light",
        "Automotive",
        "Energy"
      ]
    },
    {
      "name": "charging-station",
      "selector": "fad fa-charging-station",
      "categories": [
        "Duotone",
        "Automotive",
        "Energy"
      ]
    },
    {
      "name": "chart-area",
      "selector": "fas fa-chart-area",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "chart-area",
      "selector": "far fa-chart-area",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "chart-area",
      "selector": "fal fa-chart-area",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "chart-area",
      "selector": "fad fa-chart-area",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "chart-bar",
      "selector": "fas fa-chart-bar",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "chart-bar",
      "selector": "far fa-chart-bar",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "chart-bar",
      "selector": "fal fa-chart-bar",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "chart-bar",
      "selector": "fad fa-chart-bar",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "chart-line",
      "selector": "fas fa-chart-line",
      "categories": [
        "Solid",
        "Arrows",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line",
      "selector": "far fa-chart-line",
      "categories": [
        "Regular",
        "Arrows",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line",
      "selector": "fal fa-chart-line",
      "categories": [
        "Light",
        "Arrows",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line",
      "selector": "fad fa-chart-line",
      "categories": [
        "Duotone",
        "Arrows",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line-down",
      "selector": "fas fa-chart-line-down",
      "categories": [
        "Solid",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line-down",
      "selector": "far fa-chart-line-down",
      "categories": [
        "Regular",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line-down",
      "selector": "fal fa-chart-line-down",
      "categories": [
        "Light",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-line-down",
      "selector": "fad fa-chart-line-down",
      "categories": [
        "Duotone",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-network",
      "selector": "fas fa-chart-network",
      "categories": [
        "Solid",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-network",
      "selector": "far fa-chart-network",
      "categories": [
        "Regular",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-network",
      "selector": "fal fa-chart-network",
      "categories": [
        "Light",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-network",
      "selector": "fad fa-chart-network",
      "categories": [
        "Duotone",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-pie",
      "selector": "fas fa-chart-pie",
      "categories": [
        "Solid",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie",
      "selector": "far fa-chart-pie",
      "categories": [
        "Regular",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie",
      "selector": "fal fa-chart-pie",
      "categories": [
        "Light",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie",
      "selector": "fad fa-chart-pie",
      "categories": [
        "Duotone",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie-alt",
      "selector": "fas fa-chart-pie-alt",
      "categories": [
        "Solid",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie-alt",
      "selector": "far fa-chart-pie-alt",
      "categories": [
        "Regular",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie-alt",
      "selector": "fal fa-chart-pie-alt",
      "categories": [
        "Light",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-pie-alt",
      "selector": "fad fa-chart-pie-alt",
      "categories": [
        "Duotone",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "chart-scatter",
      "selector": "fas fa-chart-scatter",
      "categories": [
        "Solid",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-scatter",
      "selector": "far fa-chart-scatter",
      "categories": [
        "Regular",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-scatter",
      "selector": "fal fa-chart-scatter",
      "categories": [
        "Light",
        "Business",
        "Science"
      ]
    },
    {
      "name": "chart-scatter",
      "selector": "fad fa-chart-scatter",
      "categories": [
        "Duotone",
        "Business",
        "Science"
      ]
    },
    {
      "name": "check",
      "selector": "fas fa-check",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "check",
      "selector": "far fa-check",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "check",
      "selector": "fal fa-check",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "check",
      "selector": "fad fa-check",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "check-circle",
      "selector": "fas fa-check-circle",
      "categories": [
        "Solid",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "check-circle",
      "selector": "far fa-check-circle",
      "categories": [
        "Regular",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "check-circle",
      "selector": "fal fa-check-circle",
      "categories": [
        "Light",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "check-circle",
      "selector": "fad fa-check-circle",
      "categories": [
        "Duotone",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "check-double",
      "selector": "fas fa-check-double",
      "categories": [
        "Solid",
        "Interfaces",
        "Political"
      ]
    },
    {
      "name": "check-double",
      "selector": "far fa-check-double",
      "categories": [
        "Regular",
        "Interfaces",
        "Political"
      ]
    },
    {
      "name": "check-double",
      "selector": "fal fa-check-double",
      "categories": [
        "Light",
        "Interfaces",
        "Political"
      ]
    },
    {
      "name": "check-double",
      "selector": "fad fa-check-double",
      "categories": [
        "Duotone",
        "Interfaces",
        "Political"
      ]
    },
    {
      "name": "check-square",
      "selector": "fas fa-check-square",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "check-square",
      "selector": "far fa-check-square",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "check-square",
      "selector": "fal fa-check-square",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "check-square",
      "selector": "fad fa-check-square",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "cheese",
      "selector": "fas fa-cheese",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "cheese",
      "selector": "far fa-cheese",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "cheese",
      "selector": "fal fa-cheese",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "cheese",
      "selector": "fad fa-cheese",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "cheese-swiss",
      "selector": "fas fa-cheese-swiss",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "cheese-swiss",
      "selector": "far fa-cheese-swiss",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "cheese-swiss",
      "selector": "fal fa-cheese-swiss",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "cheese-swiss",
      "selector": "fad fa-cheese-swiss",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "cheeseburger",
      "selector": "fas fa-cheeseburger",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "cheeseburger",
      "selector": "far fa-cheeseburger",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "cheeseburger",
      "selector": "fal fa-cheeseburger",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "cheeseburger",
      "selector": "fad fa-cheeseburger",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "chess",
      "selector": "fas fa-chess",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess",
      "selector": "far fa-chess",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess",
      "selector": "fal fa-chess",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess",
      "selector": "fad fa-chess",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop",
      "selector": "fas fa-chess-bishop",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop",
      "selector": "far fa-chess-bishop",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop",
      "selector": "fal fa-chess-bishop",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop",
      "selector": "fad fa-chess-bishop",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop-alt",
      "selector": "fas fa-chess-bishop-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop-alt",
      "selector": "far fa-chess-bishop-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop-alt",
      "selector": "fal fa-chess-bishop-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-bishop-alt",
      "selector": "fad fa-chess-bishop-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-board",
      "selector": "fas fa-chess-board",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-board",
      "selector": "far fa-chess-board",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-board",
      "selector": "fal fa-chess-board",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-board",
      "selector": "fad fa-chess-board",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock",
      "selector": "fas fa-chess-clock",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock",
      "selector": "far fa-chess-clock",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock",
      "selector": "fal fa-chess-clock",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock",
      "selector": "fad fa-chess-clock",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock-alt",
      "selector": "fas fa-chess-clock-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock-alt",
      "selector": "far fa-chess-clock-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock-alt",
      "selector": "fal fa-chess-clock-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-clock-alt",
      "selector": "fad fa-chess-clock-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king",
      "selector": "fas fa-chess-king",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king",
      "selector": "far fa-chess-king",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king",
      "selector": "fal fa-chess-king",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king",
      "selector": "fad fa-chess-king",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king-alt",
      "selector": "fas fa-chess-king-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king-alt",
      "selector": "far fa-chess-king-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king-alt",
      "selector": "fal fa-chess-king-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-king-alt",
      "selector": "fad fa-chess-king-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight",
      "selector": "fas fa-chess-knight",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight",
      "selector": "far fa-chess-knight",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight",
      "selector": "fal fa-chess-knight",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight",
      "selector": "fad fa-chess-knight",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight-alt",
      "selector": "fas fa-chess-knight-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight-alt",
      "selector": "far fa-chess-knight-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight-alt",
      "selector": "fal fa-chess-knight-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-knight-alt",
      "selector": "fad fa-chess-knight-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn",
      "selector": "fas fa-chess-pawn",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn",
      "selector": "far fa-chess-pawn",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn",
      "selector": "fal fa-chess-pawn",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn",
      "selector": "fad fa-chess-pawn",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn-alt",
      "selector": "fas fa-chess-pawn-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn-alt",
      "selector": "far fa-chess-pawn-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn-alt",
      "selector": "fal fa-chess-pawn-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-pawn-alt",
      "selector": "fad fa-chess-pawn-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen",
      "selector": "fas fa-chess-queen",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen",
      "selector": "far fa-chess-queen",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen",
      "selector": "fal fa-chess-queen",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen",
      "selector": "fad fa-chess-queen",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen-alt",
      "selector": "fas fa-chess-queen-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen-alt",
      "selector": "far fa-chess-queen-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen-alt",
      "selector": "fal fa-chess-queen-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-queen-alt",
      "selector": "fad fa-chess-queen-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook",
      "selector": "fas fa-chess-rook",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook",
      "selector": "far fa-chess-rook",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook",
      "selector": "fal fa-chess-rook",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook",
      "selector": "fad fa-chess-rook",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook-alt",
      "selector": "fas fa-chess-rook-alt",
      "categories": [
        "Solid",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook-alt",
      "selector": "far fa-chess-rook-alt",
      "categories": [
        "Regular",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook-alt",
      "selector": "fal fa-chess-rook-alt",
      "categories": [
        "Light",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chess-rook-alt",
      "selector": "fad fa-chess-rook-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Games"
      ]
    },
    {
      "name": "chevron-circle-down",
      "selector": "fas fa-chevron-circle-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-down",
      "selector": "far fa-chevron-circle-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-down",
      "selector": "fal fa-chevron-circle-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-down",
      "selector": "fad fa-chevron-circle-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-left",
      "selector": "fas fa-chevron-circle-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-left",
      "selector": "far fa-chevron-circle-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-left",
      "selector": "fal fa-chevron-circle-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-left",
      "selector": "fad fa-chevron-circle-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-right",
      "selector": "fas fa-chevron-circle-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-right",
      "selector": "far fa-chevron-circle-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-right",
      "selector": "fal fa-chevron-circle-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-right",
      "selector": "fad fa-chevron-circle-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-up",
      "selector": "fas fa-chevron-circle-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-up",
      "selector": "far fa-chevron-circle-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-up",
      "selector": "fal fa-chevron-circle-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-circle-up",
      "selector": "fad fa-chevron-circle-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-down",
      "selector": "fas fa-chevron-double-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-down",
      "selector": "far fa-chevron-double-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-down",
      "selector": "fal fa-chevron-double-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-down",
      "selector": "fad fa-chevron-double-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-left",
      "selector": "fas fa-chevron-double-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-left",
      "selector": "far fa-chevron-double-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-left",
      "selector": "fal fa-chevron-double-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-left",
      "selector": "fad fa-chevron-double-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-right",
      "selector": "fas fa-chevron-double-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-right",
      "selector": "far fa-chevron-double-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-right",
      "selector": "fal fa-chevron-double-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-right",
      "selector": "fad fa-chevron-double-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-up",
      "selector": "fas fa-chevron-double-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-up",
      "selector": "far fa-chevron-double-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-up",
      "selector": "fal fa-chevron-double-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-double-up",
      "selector": "fad fa-chevron-double-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-down",
      "selector": "fas fa-chevron-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-down",
      "selector": "far fa-chevron-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-down",
      "selector": "fal fa-chevron-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-down",
      "selector": "fad fa-chevron-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-left",
      "selector": "fas fa-chevron-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-left",
      "selector": "far fa-chevron-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-left",
      "selector": "fal fa-chevron-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-left",
      "selector": "fad fa-chevron-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-right",
      "selector": "fas fa-chevron-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-right",
      "selector": "far fa-chevron-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-right",
      "selector": "fal fa-chevron-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-right",
      "selector": "fad fa-chevron-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-down",
      "selector": "fas fa-chevron-square-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-down",
      "selector": "far fa-chevron-square-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-down",
      "selector": "fal fa-chevron-square-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-down",
      "selector": "fad fa-chevron-square-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-left",
      "selector": "fas fa-chevron-square-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-left",
      "selector": "far fa-chevron-square-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-left",
      "selector": "fal fa-chevron-square-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-left",
      "selector": "fad fa-chevron-square-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-right",
      "selector": "fas fa-chevron-square-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-right",
      "selector": "far fa-chevron-square-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-right",
      "selector": "fal fa-chevron-square-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-right",
      "selector": "fad fa-chevron-square-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-up",
      "selector": "fas fa-chevron-square-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-up",
      "selector": "far fa-chevron-square-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-up",
      "selector": "fal fa-chevron-square-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-square-up",
      "selector": "fad fa-chevron-square-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "chevron-up",
      "selector": "fas fa-chevron-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "chevron-up",
      "selector": "far fa-chevron-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "chevron-up",
      "selector": "fal fa-chevron-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "chevron-up",
      "selector": "fad fa-chevron-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "child",
      "selector": "fas fa-child",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "child",
      "selector": "far fa-child",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "child",
      "selector": "fal fa-child",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "child",
      "selector": "fad fa-child",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "chimney",
      "selector": "fas fa-chimney",
      "categories": [
        "Solid",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "chimney",
      "selector": "far fa-chimney",
      "categories": [
        "Regular",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "chimney",
      "selector": "fal fa-chimney",
      "categories": [
        "Light",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "chimney",
      "selector": "fad fa-chimney",
      "categories": [
        "Duotone",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "chrome",
      "selector": "fab fa-chrome",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "chromecast",
      "selector": "fab fa-chromecast",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "church",
      "selector": "fas fa-church",
      "categories": [
        "Solid",
        "Buildings",
        "Objects",
        "Religion"
      ]
    },
    {
      "name": "church",
      "selector": "far fa-church",
      "categories": [
        "Regular",
        "Buildings",
        "Objects",
        "Religion"
      ]
    },
    {
      "name": "church",
      "selector": "fal fa-church",
      "categories": [
        "Light",
        "Buildings",
        "Objects",
        "Religion"
      ]
    },
    {
      "name": "church",
      "selector": "fad fa-church",
      "categories": [
        "Duotone",
        "Buildings",
        "Objects",
        "Religion"
      ]
    },
    {
      "name": "circle",
      "selector": "fas fa-circle",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Shapes",
        "Toggle"
      ]
    },
    {
      "name": "circle",
      "selector": "far fa-circle",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Shapes",
        "Toggle"
      ]
    },
    {
      "name": "circle",
      "selector": "fal fa-circle",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Shapes",
        "Toggle"
      ]
    },
    {
      "name": "circle",
      "selector": "fad fa-circle",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Shapes",
        "Toggle"
      ]
    },
    {
      "name": "circle-notch",
      "selector": "fas fa-circle-notch",
      "categories": [
        "Solid",
        "Spinners"
      ]
    },
    {
      "name": "circle-notch",
      "selector": "far fa-circle-notch",
      "categories": [
        "Regular",
        "Spinners"
      ]
    },
    {
      "name": "circle-notch",
      "selector": "fal fa-circle-notch",
      "categories": [
        "Light",
        "Spinners"
      ]
    },
    {
      "name": "circle-notch",
      "selector": "fad fa-circle-notch",
      "categories": [
        "Duotone",
        "Spinners"
      ]
    },
    {
      "name": "city",
      "selector": "fas fa-city",
      "categories": [
        "Solid",
        "Buildings",
        "Business"
      ]
    },
    {
      "name": "city",
      "selector": "far fa-city",
      "categories": [
        "Regular",
        "Buildings",
        "Business"
      ]
    },
    {
      "name": "city",
      "selector": "fal fa-city",
      "categories": [
        "Light",
        "Buildings",
        "Business"
      ]
    },
    {
      "name": "city",
      "selector": "fad fa-city",
      "categories": [
        "Duotone",
        "Buildings",
        "Business"
      ]
    },
    {
      "name": "clarinet",
      "selector": "fas fa-clarinet",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "clarinet",
      "selector": "far fa-clarinet",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "clarinet",
      "selector": "fal fa-clarinet",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "clarinet",
      "selector": "fad fa-clarinet",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "claw-marks",
      "selector": "fas fa-claw-marks",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "claw-marks",
      "selector": "far fa-claw-marks",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "claw-marks",
      "selector": "fal fa-claw-marks",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "claw-marks",
      "selector": "fad fa-claw-marks",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "clinic-medical",
      "selector": "fas fa-clinic-medical",
      "categories": [
        "Solid",
        "Buildings",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clinic-medical",
      "selector": "far fa-clinic-medical",
      "categories": [
        "Regular",
        "Buildings",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clinic-medical",
      "selector": "fal fa-clinic-medical",
      "categories": [
        "Light",
        "Buildings",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clinic-medical",
      "selector": "fad fa-clinic-medical",
      "categories": [
        "Duotone",
        "Buildings",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clipboard",
      "selector": "fas fa-clipboard",
      "categories": [
        "Solid",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "clipboard",
      "selector": "far fa-clipboard",
      "categories": [
        "Regular",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "clipboard",
      "selector": "fal fa-clipboard",
      "categories": [
        "Light",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "clipboard",
      "selector": "fad fa-clipboard",
      "categories": [
        "Duotone",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "clipboard-check",
      "selector": "fas fa-clipboard-check",
      "categories": [
        "Solid",
        "Logistics",
        "Science"
      ]
    },
    {
      "name": "clipboard-check",
      "selector": "far fa-clipboard-check",
      "categories": [
        "Regular",
        "Logistics",
        "Science"
      ]
    },
    {
      "name": "clipboard-check",
      "selector": "fal fa-clipboard-check",
      "categories": [
        "Light",
        "Logistics",
        "Science"
      ]
    },
    {
      "name": "clipboard-check",
      "selector": "fad fa-clipboard-check",
      "categories": [
        "Duotone",
        "Logistics",
        "Science"
      ]
    },
    {
      "name": "clipboard-list",
      "selector": "fas fa-clipboard-list",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "clipboard-list",
      "selector": "far fa-clipboard-list",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "clipboard-list",
      "selector": "fal fa-clipboard-list",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "clipboard-list",
      "selector": "fad fa-clipboard-list",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "clipboard-list-check",
      "selector": "fas fa-clipboard-list-check",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "clipboard-list-check",
      "selector": "far fa-clipboard-list-check",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "clipboard-list-check",
      "selector": "fal fa-clipboard-list-check",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "clipboard-list-check",
      "selector": "fad fa-clipboard-list-check",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "clipboard-prescription",
      "selector": "fas fa-clipboard-prescription",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clipboard-prescription",
      "selector": "far fa-clipboard-prescription",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clipboard-prescription",
      "selector": "fal fa-clipboard-prescription",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clipboard-prescription",
      "selector": "fad fa-clipboard-prescription",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "clipboard-user",
      "selector": "fas fa-clipboard-user",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "clipboard-user",
      "selector": "far fa-clipboard-user",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "clipboard-user",
      "selector": "fal fa-clipboard-user",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "clipboard-user",
      "selector": "fad fa-clipboard-user",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "clock",
      "selector": "fas fa-clock",
      "categories": [
        "Solid",
        "Date & Time"
      ]
    },
    {
      "name": "clock",
      "selector": "far fa-clock",
      "categories": [
        "Regular",
        "Date & Time"
      ]
    },
    {
      "name": "clock",
      "selector": "fal fa-clock",
      "categories": [
        "Light",
        "Date & Time"
      ]
    },
    {
      "name": "clock",
      "selector": "fad fa-clock",
      "categories": [
        "Duotone",
        "Date & Time"
      ]
    },
    {
      "name": "clone",
      "selector": "fas fa-clone",
      "categories": [
        "Solid",
        "Design",
        "Editors",
        "Files",
        "Images",
        "Interfaces"
      ]
    },
    {
      "name": "clone",
      "selector": "far fa-clone",
      "categories": [
        "Regular",
        "Design",
        "Editors",
        "Files",
        "Images",
        "Interfaces"
      ]
    },
    {
      "name": "clone",
      "selector": "fal fa-clone",
      "categories": [
        "Light",
        "Design",
        "Editors",
        "Files",
        "Images",
        "Interfaces"
      ]
    },
    {
      "name": "clone",
      "selector": "fad fa-clone",
      "categories": [
        "Duotone",
        "Design",
        "Editors",
        "Files",
        "Images",
        "Interfaces"
      ]
    },
    {
      "name": "closed-captioning",
      "selector": "fas fa-closed-captioning",
      "categories": [
        "Solid",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "closed-captioning",
      "selector": "far fa-closed-captioning",
      "categories": [
        "Regular",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "closed-captioning",
      "selector": "fal fa-closed-captioning",
      "categories": [
        "Light",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "closed-captioning",
      "selector": "fad fa-closed-captioning",
      "categories": [
        "Duotone",
        "Accessibility",
        "Audio & Video"
      ]
    },
    {
      "name": "cloud",
      "selector": "fas fa-cloud",
      "categories": [
        "Solid",
        "Interfaces",
        "Objects",
        "Shapes",
        "Weather"
      ]
    },
    {
      "name": "cloud",
      "selector": "far fa-cloud",
      "categories": [
        "Regular",
        "Interfaces",
        "Objects",
        "Shapes",
        "Weather"
      ]
    },
    {
      "name": "cloud",
      "selector": "fal fa-cloud",
      "categories": [
        "Light",
        "Interfaces",
        "Objects",
        "Shapes",
        "Weather"
      ]
    },
    {
      "name": "cloud",
      "selector": "fad fa-cloud",
      "categories": [
        "Duotone",
        "Interfaces",
        "Objects",
        "Shapes",
        "Weather"
      ]
    },
    {
      "name": "cloud-download",
      "selector": "fas fa-cloud-download",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download",
      "selector": "far fa-cloud-download",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download",
      "selector": "fal fa-cloud-download",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download",
      "selector": "fad fa-cloud-download",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download-alt",
      "selector": "fas fa-cloud-download-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download-alt",
      "selector": "far fa-cloud-download-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download-alt",
      "selector": "fal fa-cloud-download-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-download-alt",
      "selector": "fad fa-cloud-download-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-drizzle",
      "selector": "fas fa-cloud-drizzle",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-drizzle",
      "selector": "far fa-cloud-drizzle",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-drizzle",
      "selector": "fal fa-cloud-drizzle",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-drizzle",
      "selector": "fad fa-cloud-drizzle",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail",
      "selector": "fas fa-cloud-hail",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail",
      "selector": "far fa-cloud-hail",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail",
      "selector": "fal fa-cloud-hail",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail",
      "selector": "fad fa-cloud-hail",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail-mixed",
      "selector": "fas fa-cloud-hail-mixed",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail-mixed",
      "selector": "far fa-cloud-hail-mixed",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail-mixed",
      "selector": "fal fa-cloud-hail-mixed",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-hail-mixed",
      "selector": "fad fa-cloud-hail-mixed",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-meatball",
      "selector": "fas fa-cloud-meatball",
      "categories": [
        "Solid",
        "Food",
        "Weather"
      ]
    },
    {
      "name": "cloud-meatball",
      "selector": "far fa-cloud-meatball",
      "categories": [
        "Regular",
        "Food",
        "Weather"
      ]
    },
    {
      "name": "cloud-meatball",
      "selector": "fal fa-cloud-meatball",
      "categories": [
        "Light",
        "Food",
        "Weather"
      ]
    },
    {
      "name": "cloud-meatball",
      "selector": "fad fa-cloud-meatball",
      "categories": [
        "Duotone",
        "Food",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon",
      "selector": "fas fa-cloud-moon",
      "categories": [
        "Solid",
        "Halloween",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon",
      "selector": "far fa-cloud-moon",
      "categories": [
        "Regular",
        "Halloween",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon",
      "selector": "fal fa-cloud-moon",
      "categories": [
        "Light",
        "Halloween",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon",
      "selector": "fad fa-cloud-moon",
      "categories": [
        "Duotone",
        "Halloween",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon-rain",
      "selector": "fas fa-cloud-moon-rain",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon-rain",
      "selector": "far fa-cloud-moon-rain",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon-rain",
      "selector": "fal fa-cloud-moon-rain",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-moon-rain",
      "selector": "fad fa-cloud-moon-rain",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-music",
      "selector": "fas fa-cloud-music",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "cloud-music",
      "selector": "far fa-cloud-music",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "cloud-music",
      "selector": "fal fa-cloud-music",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "cloud-music",
      "selector": "fad fa-cloud-music",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "cloud-rain",
      "selector": "fas fa-cloud-rain",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-rain",
      "selector": "far fa-cloud-rain",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-rain",
      "selector": "fal fa-cloud-rain",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-rain",
      "selector": "fad fa-cloud-rain",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-rainbow",
      "selector": "fas fa-cloud-rainbow",
      "categories": [
        "Solid",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-rainbow",
      "selector": "far fa-cloud-rainbow",
      "categories": [
        "Regular",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-rainbow",
      "selector": "fal fa-cloud-rainbow",
      "categories": [
        "Light",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-rainbow",
      "selector": "fad fa-cloud-rainbow",
      "categories": [
        "Duotone",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers",
      "selector": "fas fa-cloud-showers",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers",
      "selector": "far fa-cloud-showers",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers",
      "selector": "fal fa-cloud-showers",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers",
      "selector": "fad fa-cloud-showers",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers-heavy",
      "selector": "fas fa-cloud-showers-heavy",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers-heavy",
      "selector": "far fa-cloud-showers-heavy",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers-heavy",
      "selector": "fal fa-cloud-showers-heavy",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-showers-heavy",
      "selector": "fad fa-cloud-showers-heavy",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-sleet",
      "selector": "fas fa-cloud-sleet",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-sleet",
      "selector": "far fa-cloud-sleet",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-sleet",
      "selector": "fal fa-cloud-sleet",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-sleet",
      "selector": "fad fa-cloud-sleet",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-snow",
      "selector": "fas fa-cloud-snow",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "cloud-snow",
      "selector": "far fa-cloud-snow",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "cloud-snow",
      "selector": "fal fa-cloud-snow",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "cloud-snow",
      "selector": "fad fa-cloud-snow",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun",
      "selector": "fas fa-cloud-sun",
      "categories": [
        "Solid",
        "Autumn",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun",
      "selector": "far fa-cloud-sun",
      "categories": [
        "Regular",
        "Autumn",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun",
      "selector": "fal fa-cloud-sun",
      "categories": [
        "Light",
        "Autumn",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun",
      "selector": "fad fa-cloud-sun",
      "categories": [
        "Duotone",
        "Autumn",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun-rain",
      "selector": "fas fa-cloud-sun-rain",
      "categories": [
        "Solid",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun-rain",
      "selector": "far fa-cloud-sun-rain",
      "categories": [
        "Regular",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun-rain",
      "selector": "fal fa-cloud-sun-rain",
      "categories": [
        "Light",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-sun-rain",
      "selector": "fad fa-cloud-sun-rain",
      "categories": [
        "Duotone",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "cloud-upload",
      "selector": "fas fa-cloud-upload",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload",
      "selector": "far fa-cloud-upload",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload",
      "selector": "fal fa-cloud-upload",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload",
      "selector": "fad fa-cloud-upload",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload-alt",
      "selector": "fas fa-cloud-upload-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload-alt",
      "selector": "far fa-cloud-upload-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload-alt",
      "selector": "fal fa-cloud-upload-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "cloud-upload-alt",
      "selector": "fad fa-cloud-upload-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "clouds",
      "selector": "fas fa-clouds",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "clouds",
      "selector": "far fa-clouds",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "clouds",
      "selector": "fal fa-clouds",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "clouds",
      "selector": "fad fa-clouds",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "clouds-moon",
      "selector": "fas fa-clouds-moon",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "clouds-moon",
      "selector": "far fa-clouds-moon",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "clouds-moon",
      "selector": "fal fa-clouds-moon",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "clouds-moon",
      "selector": "fad fa-clouds-moon",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "clouds-sun",
      "selector": "fas fa-clouds-sun",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "clouds-sun",
      "selector": "far fa-clouds-sun",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "clouds-sun",
      "selector": "fal fa-clouds-sun",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "clouds-sun",
      "selector": "fad fa-clouds-sun",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "cloudscale",
      "selector": "fab fa-cloudscale",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "cloudsmith",
      "selector": "fab fa-cloudsmith",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "cloudversify",
      "selector": "fab fa-cloudversify",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "club",
      "selector": "fas fa-club",
      "categories": [
        "Solid",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "club",
      "selector": "far fa-club",
      "categories": [
        "Regular",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "club",
      "selector": "fal fa-club",
      "categories": [
        "Light",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "club",
      "selector": "fad fa-club",
      "categories": [
        "Duotone",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "cocktail",
      "selector": "fas fa-cocktail",
      "categories": [
        "Solid",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "cocktail",
      "selector": "far fa-cocktail",
      "categories": [
        "Regular",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "cocktail",
      "selector": "fal fa-cocktail",
      "categories": [
        "Light",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "cocktail",
      "selector": "fad fa-cocktail",
      "categories": [
        "Duotone",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "code",
      "selector": "fas fa-code",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "code",
      "selector": "far fa-code",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "code",
      "selector": "fal fa-code",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "code",
      "selector": "fad fa-code",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "code-branch",
      "selector": "fas fa-code-branch",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "code-branch",
      "selector": "far fa-code-branch",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "code-branch",
      "selector": "fal fa-code-branch",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "code-branch",
      "selector": "fad fa-code-branch",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "code-commit",
      "selector": "fas fa-code-commit",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "code-commit",
      "selector": "far fa-code-commit",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "code-commit",
      "selector": "fal fa-code-commit",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "code-commit",
      "selector": "fad fa-code-commit",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "code-merge",
      "selector": "fas fa-code-merge",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "code-merge",
      "selector": "far fa-code-merge",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "code-merge",
      "selector": "fal fa-code-merge",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "code-merge",
      "selector": "fad fa-code-merge",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "codepen",
      "selector": "fab fa-codepen",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "codiepie",
      "selector": "fab fa-codiepie",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "coffee",
      "selector": "fas fa-coffee",
      "categories": [
        "Solid",
        "Beverage",
        "Business",
        "Code",
        "Hotel",
        "Household",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "coffee",
      "selector": "far fa-coffee",
      "categories": [
        "Regular",
        "Beverage",
        "Business",
        "Code",
        "Hotel",
        "Household",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "coffee",
      "selector": "fal fa-coffee",
      "categories": [
        "Light",
        "Beverage",
        "Business",
        "Code",
        "Hotel",
        "Household",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "coffee",
      "selector": "fad fa-coffee",
      "categories": [
        "Duotone",
        "Beverage",
        "Business",
        "Code",
        "Hotel",
        "Household",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "coffee-pot",
      "selector": "fas fa-coffee-pot",
      "categories": [
        "Solid",
        "Beverage",
        "Business",
        "Household"
      ]
    },
    {
      "name": "coffee-pot",
      "selector": "far fa-coffee-pot",
      "categories": [
        "Regular",
        "Beverage",
        "Business",
        "Household"
      ]
    },
    {
      "name": "coffee-pot",
      "selector": "fal fa-coffee-pot",
      "categories": [
        "Light",
        "Beverage",
        "Business",
        "Household"
      ]
    },
    {
      "name": "coffee-pot",
      "selector": "fad fa-coffee-pot",
      "categories": [
        "Duotone",
        "Beverage",
        "Business",
        "Household"
      ]
    },
    {
      "name": "coffee-togo",
      "selector": "fas fa-coffee-togo",
      "categories": [
        "Solid",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "coffee-togo",
      "selector": "far fa-coffee-togo",
      "categories": [
        "Regular",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "coffee-togo",
      "selector": "fal fa-coffee-togo",
      "categories": [
        "Light",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "coffee-togo",
      "selector": "fad fa-coffee-togo",
      "categories": [
        "Duotone",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "coffin",
      "selector": "fas fa-coffin",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "coffin",
      "selector": "far fa-coffin",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "coffin",
      "selector": "fal fa-coffin",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "coffin",
      "selector": "fad fa-coffin",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "cog",
      "selector": "fas fa-cog",
      "categories": [
        "Solid",
        "Interfaces",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "cog",
      "selector": "far fa-cog",
      "categories": [
        "Regular",
        "Interfaces",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "cog",
      "selector": "fal fa-cog",
      "categories": [
        "Light",
        "Interfaces",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "cog",
      "selector": "fad fa-cog",
      "categories": [
        "Duotone",
        "Interfaces",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "cogs",
      "selector": "fas fa-cogs",
      "categories": [
        "Solid",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cogs",
      "selector": "far fa-cogs",
      "categories": [
        "Regular",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cogs",
      "selector": "fal fa-cogs",
      "categories": [
        "Light",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cogs",
      "selector": "fad fa-cogs",
      "categories": [
        "Duotone",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "coin",
      "selector": "fas fa-coin",
      "categories": [
        "Solid",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "coin",
      "selector": "far fa-coin",
      "categories": [
        "Regular",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "coin",
      "selector": "fal fa-coin",
      "categories": [
        "Light",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "coin",
      "selector": "fad fa-coin",
      "categories": [
        "Duotone",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "coins",
      "selector": "fas fa-coins",
      "categories": [
        "Solid",
        "Finance"
      ]
    },
    {
      "name": "coins",
      "selector": "far fa-coins",
      "categories": [
        "Regular",
        "Finance"
      ]
    },
    {
      "name": "coins",
      "selector": "fal fa-coins",
      "categories": [
        "Light",
        "Finance"
      ]
    },
    {
      "name": "coins",
      "selector": "fad fa-coins",
      "categories": [
        "Duotone",
        "Finance"
      ]
    },
    {
      "name": "columns",
      "selector": "fas fa-columns",
      "categories": [
        "Solid",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "columns",
      "selector": "far fa-columns",
      "categories": [
        "Regular",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "columns",
      "selector": "fal fa-columns",
      "categories": [
        "Light",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "columns",
      "selector": "fad fa-columns",
      "categories": [
        "Duotone",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "comet",
      "selector": "fas fa-comet",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "comet",
      "selector": "far fa-comet",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "comet",
      "selector": "fal fa-comet",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "comet",
      "selector": "fad fa-comet",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "comment",
      "selector": "fas fa-comment",
      "categories": [
        "Solid",
        "Chat",
        "Communication",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment",
      "selector": "far fa-comment",
      "categories": [
        "Regular",
        "Chat",
        "Communication",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment",
      "selector": "fal fa-comment",
      "categories": [
        "Light",
        "Chat",
        "Communication",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment",
      "selector": "fad fa-comment",
      "categories": [
        "Duotone",
        "Chat",
        "Communication",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment-alt",
      "selector": "fas fa-comment-alt",
      "categories": [
        "Solid",
        "Chat",
        "Communication",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment-alt",
      "selector": "far fa-comment-alt",
      "categories": [
        "Regular",
        "Chat",
        "Communication",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment-alt",
      "selector": "fal fa-comment-alt",
      "categories": [
        "Light",
        "Chat",
        "Communication",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment-alt",
      "selector": "fad fa-comment-alt",
      "categories": [
        "Duotone",
        "Chat",
        "Communication",
        "Social",
        "Status"
      ]
    },
    {
      "name": "comment-alt-check",
      "selector": "fas fa-comment-alt-check",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-check",
      "selector": "far fa-comment-alt-check",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-check",
      "selector": "fal fa-comment-alt-check",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-check",
      "selector": "fad fa-comment-alt-check",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-dollar",
      "selector": "fas fa-comment-alt-dollar",
      "categories": [
        "Solid",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-alt-dollar",
      "selector": "far fa-comment-alt-dollar",
      "categories": [
        "Regular",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-alt-dollar",
      "selector": "fal fa-comment-alt-dollar",
      "categories": [
        "Light",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-alt-dollar",
      "selector": "fad fa-comment-alt-dollar",
      "categories": [
        "Duotone",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-alt-dots",
      "selector": "fas fa-comment-alt-dots",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-dots",
      "selector": "far fa-comment-alt-dots",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-dots",
      "selector": "fal fa-comment-alt-dots",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-dots",
      "selector": "fad fa-comment-alt-dots",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-edit",
      "selector": "fas fa-comment-alt-edit",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-edit",
      "selector": "far fa-comment-alt-edit",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-edit",
      "selector": "fal fa-comment-alt-edit",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-edit",
      "selector": "fad fa-comment-alt-edit",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-exclamation",
      "selector": "fas fa-comment-alt-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-exclamation",
      "selector": "far fa-comment-alt-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-exclamation",
      "selector": "fal fa-comment-alt-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-exclamation",
      "selector": "fad fa-comment-alt-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-lines",
      "selector": "fas fa-comment-alt-lines",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-lines",
      "selector": "far fa-comment-alt-lines",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-lines",
      "selector": "fal fa-comment-alt-lines",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-lines",
      "selector": "fad fa-comment-alt-lines",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-medical",
      "selector": "fas fa-comment-alt-medical",
      "categories": [
        "Solid",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-alt-medical",
      "selector": "far fa-comment-alt-medical",
      "categories": [
        "Regular",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-alt-medical",
      "selector": "fal fa-comment-alt-medical",
      "categories": [
        "Light",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-alt-medical",
      "selector": "fad fa-comment-alt-medical",
      "categories": [
        "Duotone",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-alt-minus",
      "selector": "fas fa-comment-alt-minus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-minus",
      "selector": "far fa-comment-alt-minus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-minus",
      "selector": "fal fa-comment-alt-minus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-minus",
      "selector": "fad fa-comment-alt-minus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-music",
      "selector": "fas fa-comment-alt-music",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "comment-alt-music",
      "selector": "far fa-comment-alt-music",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "comment-alt-music",
      "selector": "fal fa-comment-alt-music",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "comment-alt-music",
      "selector": "fad fa-comment-alt-music",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "comment-alt-plus",
      "selector": "fas fa-comment-alt-plus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-plus",
      "selector": "far fa-comment-alt-plus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-plus",
      "selector": "fal fa-comment-alt-plus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-plus",
      "selector": "fad fa-comment-alt-plus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-slash",
      "selector": "fas fa-comment-alt-slash",
      "categories": [
        "Solid",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-alt-slash",
      "selector": "far fa-comment-alt-slash",
      "categories": [
        "Regular",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-alt-slash",
      "selector": "fal fa-comment-alt-slash",
      "categories": [
        "Light",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-alt-slash",
      "selector": "fad fa-comment-alt-slash",
      "categories": [
        "Duotone",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-alt-smile",
      "selector": "fas fa-comment-alt-smile",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-smile",
      "selector": "far fa-comment-alt-smile",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-smile",
      "selector": "fal fa-comment-alt-smile",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-smile",
      "selector": "fad fa-comment-alt-smile",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-times",
      "selector": "fas fa-comment-alt-times",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-times",
      "selector": "far fa-comment-alt-times",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-times",
      "selector": "fal fa-comment-alt-times",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-alt-times",
      "selector": "fad fa-comment-alt-times",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-check",
      "selector": "fas fa-comment-check",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-check",
      "selector": "far fa-comment-check",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-check",
      "selector": "fal fa-comment-check",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-check",
      "selector": "fad fa-comment-check",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-dollar",
      "selector": "fas fa-comment-dollar",
      "categories": [
        "Solid",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-dollar",
      "selector": "far fa-comment-dollar",
      "categories": [
        "Regular",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-dollar",
      "selector": "fal fa-comment-dollar",
      "categories": [
        "Light",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-dollar",
      "selector": "fad fa-comment-dollar",
      "categories": [
        "Duotone",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comment-dots",
      "selector": "fas fa-comment-dots",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-dots",
      "selector": "far fa-comment-dots",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-dots",
      "selector": "fal fa-comment-dots",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-dots",
      "selector": "fad fa-comment-dots",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-edit",
      "selector": "fas fa-comment-edit",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-edit",
      "selector": "far fa-comment-edit",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-edit",
      "selector": "fal fa-comment-edit",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-edit",
      "selector": "fad fa-comment-edit",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-exclamation",
      "selector": "fas fa-comment-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-exclamation",
      "selector": "far fa-comment-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-exclamation",
      "selector": "fal fa-comment-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-exclamation",
      "selector": "fad fa-comment-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Chat"
      ]
    },
    {
      "name": "comment-lines",
      "selector": "fas fa-comment-lines",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-lines",
      "selector": "far fa-comment-lines",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-lines",
      "selector": "fal fa-comment-lines",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-lines",
      "selector": "fad fa-comment-lines",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-medical",
      "selector": "fas fa-comment-medical",
      "categories": [
        "Solid",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-medical",
      "selector": "far fa-comment-medical",
      "categories": [
        "Regular",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-medical",
      "selector": "fal fa-comment-medical",
      "categories": [
        "Light",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-medical",
      "selector": "fad fa-comment-medical",
      "categories": [
        "Duotone",
        "Chat",
        "Medical"
      ]
    },
    {
      "name": "comment-minus",
      "selector": "fas fa-comment-minus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-minus",
      "selector": "far fa-comment-minus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-minus",
      "selector": "fal fa-comment-minus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-minus",
      "selector": "fad fa-comment-minus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-music",
      "selector": "fas fa-comment-music",
      "categories": [
        "Solid",
        "Chat",
        "Music"
      ]
    },
    {
      "name": "comment-music",
      "selector": "far fa-comment-music",
      "categories": [
        "Regular",
        "Chat",
        "Music"
      ]
    },
    {
      "name": "comment-music",
      "selector": "fal fa-comment-music",
      "categories": [
        "Light",
        "Chat",
        "Music"
      ]
    },
    {
      "name": "comment-music",
      "selector": "fad fa-comment-music",
      "categories": [
        "Duotone",
        "Chat",
        "Music"
      ]
    },
    {
      "name": "comment-plus",
      "selector": "fas fa-comment-plus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-plus",
      "selector": "far fa-comment-plus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-plus",
      "selector": "fal fa-comment-plus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-plus",
      "selector": "fad fa-comment-plus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-slash",
      "selector": "fas fa-comment-slash",
      "categories": [
        "Solid",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-slash",
      "selector": "far fa-comment-slash",
      "categories": [
        "Regular",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-slash",
      "selector": "fal fa-comment-slash",
      "categories": [
        "Light",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-slash",
      "selector": "fad fa-comment-slash",
      "categories": [
        "Duotone",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "comment-smile",
      "selector": "fas fa-comment-smile",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-smile",
      "selector": "far fa-comment-smile",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-smile",
      "selector": "fal fa-comment-smile",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-smile",
      "selector": "fad fa-comment-smile",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comment-times",
      "selector": "fas fa-comment-times",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comment-times",
      "selector": "far fa-comment-times",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comment-times",
      "selector": "fal fa-comment-times",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comment-times",
      "selector": "fad fa-comment-times",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comments",
      "selector": "fas fa-comments",
      "categories": [
        "Solid",
        "Chat",
        "Communication"
      ]
    },
    {
      "name": "comments",
      "selector": "far fa-comments",
      "categories": [
        "Regular",
        "Chat",
        "Communication"
      ]
    },
    {
      "name": "comments",
      "selector": "fal fa-comments",
      "categories": [
        "Light",
        "Chat",
        "Communication"
      ]
    },
    {
      "name": "comments",
      "selector": "fad fa-comments",
      "categories": [
        "Duotone",
        "Chat",
        "Communication"
      ]
    },
    {
      "name": "comments-alt",
      "selector": "fas fa-comments-alt",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "comments-alt",
      "selector": "far fa-comments-alt",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "comments-alt",
      "selector": "fal fa-comments-alt",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "comments-alt",
      "selector": "fad fa-comments-alt",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "comments-alt-dollar",
      "selector": "fas fa-comments-alt-dollar",
      "categories": [
        "Solid",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-alt-dollar",
      "selector": "far fa-comments-alt-dollar",
      "categories": [
        "Regular",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-alt-dollar",
      "selector": "fal fa-comments-alt-dollar",
      "categories": [
        "Light",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-alt-dollar",
      "selector": "fad fa-comments-alt-dollar",
      "categories": [
        "Duotone",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-dollar",
      "selector": "fas fa-comments-dollar",
      "categories": [
        "Solid",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-dollar",
      "selector": "far fa-comments-dollar",
      "categories": [
        "Regular",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-dollar",
      "selector": "fal fa-comments-dollar",
      "categories": [
        "Light",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "comments-dollar",
      "selector": "fad fa-comments-dollar",
      "categories": [
        "Duotone",
        "Finance",
        "Marketing"
      ]
    },
    {
      "name": "compact-disc",
      "selector": "fas fa-compact-disc",
      "categories": [
        "Solid",
        "Spinners"
      ]
    },
    {
      "name": "compact-disc",
      "selector": "far fa-compact-disc",
      "categories": [
        "Regular",
        "Spinners"
      ]
    },
    {
      "name": "compact-disc",
      "selector": "fal fa-compact-disc",
      "categories": [
        "Light",
        "Spinners"
      ]
    },
    {
      "name": "compact-disc",
      "selector": "fad fa-compact-disc",
      "categories": [
        "Duotone",
        "Spinners"
      ]
    },
    {
      "name": "compass",
      "selector": "fas fa-compass",
      "categories": [
        "Solid",
        "Business",
        "Camping",
        "Maritime",
        "Objects",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "compass",
      "selector": "far fa-compass",
      "categories": [
        "Regular",
        "Business",
        "Camping",
        "Maritime",
        "Objects",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "compass",
      "selector": "fal fa-compass",
      "categories": [
        "Light",
        "Business",
        "Camping",
        "Maritime",
        "Objects",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "compass",
      "selector": "fad fa-compass",
      "categories": [
        "Duotone",
        "Business",
        "Camping",
        "Maritime",
        "Objects",
        "Spinners",
        "Status"
      ]
    },
    {
      "name": "compass-slash",
      "selector": "fas fa-compass-slash",
      "categories": [
        "Solid",
        "Maps",
        "Maritime",
        "Status"
      ]
    },
    {
      "name": "compass-slash",
      "selector": "far fa-compass-slash",
      "categories": [
        "Regular",
        "Maps",
        "Maritime",
        "Status"
      ]
    },
    {
      "name": "compass-slash",
      "selector": "fal fa-compass-slash",
      "categories": [
        "Light",
        "Maps",
        "Maritime",
        "Status"
      ]
    },
    {
      "name": "compass-slash",
      "selector": "fad fa-compass-slash",
      "categories": [
        "Duotone",
        "Maps",
        "Maritime",
        "Status"
      ]
    },
    {
      "name": "compress",
      "selector": "fas fa-compress",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress",
      "selector": "far fa-compress",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress",
      "selector": "fal fa-compress",
      "categories": [
        "Light",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress",
      "selector": "fad fa-compress",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-alt",
      "selector": "fas fa-compress-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "compress-alt",
      "selector": "far fa-compress-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "compress-alt",
      "selector": "fal fa-compress-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "compress-alt",
      "selector": "fad fa-compress-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "compress-arrows-alt",
      "selector": "fas fa-compress-arrows-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-arrows-alt",
      "selector": "far fa-compress-arrows-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-arrows-alt",
      "selector": "fal fa-compress-arrows-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-arrows-alt",
      "selector": "fad fa-compress-arrows-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-wide",
      "selector": "fas fa-compress-wide",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-wide",
      "selector": "far fa-compress-wide",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-wide",
      "selector": "fal fa-compress-wide",
      "categories": [
        "Light",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "compress-wide",
      "selector": "fad fa-compress-wide",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "computer-classic",
      "selector": "fas fa-computer-classic",
      "categories": [
        "Solid",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "computer-classic",
      "selector": "far fa-computer-classic",
      "categories": [
        "Regular",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "computer-classic",
      "selector": "fal fa-computer-classic",
      "categories": [
        "Light",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "computer-classic",
      "selector": "fad fa-computer-classic",
      "categories": [
        "Duotone",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "computer-speaker",
      "selector": "fas fa-computer-speaker",
      "categories": [
        "Solid",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "computer-speaker",
      "selector": "far fa-computer-speaker",
      "categories": [
        "Regular",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "computer-speaker",
      "selector": "fal fa-computer-speaker",
      "categories": [
        "Light",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "computer-speaker",
      "selector": "fad fa-computer-speaker",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "concierge-bell",
      "selector": "fas fa-concierge-bell",
      "categories": [
        "Solid",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "concierge-bell",
      "selector": "far fa-concierge-bell",
      "categories": [
        "Regular",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "concierge-bell",
      "selector": "fal fa-concierge-bell",
      "categories": [
        "Light",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "concierge-bell",
      "selector": "fad fa-concierge-bell",
      "categories": [
        "Duotone",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "confluence",
      "selector": "fab fa-confluence",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "connectdevelop",
      "selector": "fab fa-connectdevelop",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "construction",
      "selector": "fas fa-construction",
      "categories": [
        "Solid",
        "Construction"
      ]
    },
    {
      "name": "construction",
      "selector": "far fa-construction",
      "categories": [
        "Regular",
        "Construction"
      ]
    },
    {
      "name": "construction",
      "selector": "fal fa-construction",
      "categories": [
        "Light",
        "Construction"
      ]
    },
    {
      "name": "construction",
      "selector": "fad fa-construction",
      "categories": [
        "Duotone",
        "Construction"
      ]
    },
    {
      "name": "container-storage",
      "selector": "fas fa-container-storage",
      "categories": [
        "Solid",
        "Buildings",
        "Maritime",
        "Moving"
      ]
    },
    {
      "name": "container-storage",
      "selector": "far fa-container-storage",
      "categories": [
        "Regular",
        "Buildings",
        "Maritime",
        "Moving"
      ]
    },
    {
      "name": "container-storage",
      "selector": "fal fa-container-storage",
      "categories": [
        "Light",
        "Buildings",
        "Maritime",
        "Moving"
      ]
    },
    {
      "name": "container-storage",
      "selector": "fad fa-container-storage",
      "categories": [
        "Duotone",
        "Buildings",
        "Maritime",
        "Moving"
      ]
    },
    {
      "name": "contao",
      "selector": "fab fa-contao",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "conveyor-belt",
      "selector": "fas fa-conveyor-belt",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt",
      "selector": "far fa-conveyor-belt",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt",
      "selector": "fal fa-conveyor-belt",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt",
      "selector": "fad fa-conveyor-belt",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt-alt",
      "selector": "fas fa-conveyor-belt-alt",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt-alt",
      "selector": "far fa-conveyor-belt-alt",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt-alt",
      "selector": "fal fa-conveyor-belt-alt",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "conveyor-belt-alt",
      "selector": "fad fa-conveyor-belt-alt",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "cookie",
      "selector": "fas fa-cookie",
      "categories": [
        "Solid",
        "Childhood",
        "Food",
        "Objects"
      ]
    },
    {
      "name": "cookie",
      "selector": "far fa-cookie",
      "categories": [
        "Regular",
        "Childhood",
        "Food",
        "Objects"
      ]
    },
    {
      "name": "cookie",
      "selector": "fal fa-cookie",
      "categories": [
        "Light",
        "Childhood",
        "Food",
        "Objects"
      ]
    },
    {
      "name": "cookie",
      "selector": "fad fa-cookie",
      "categories": [
        "Duotone",
        "Childhood",
        "Food",
        "Objects"
      ]
    },
    {
      "name": "cookie-bite",
      "selector": "fas fa-cookie-bite",
      "categories": [
        "Solid",
        "Childhood",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "cookie-bite",
      "selector": "far fa-cookie-bite",
      "categories": [
        "Regular",
        "Childhood",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "cookie-bite",
      "selector": "fal fa-cookie-bite",
      "categories": [
        "Light",
        "Childhood",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "cookie-bite",
      "selector": "fad fa-cookie-bite",
      "categories": [
        "Duotone",
        "Childhood",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "copy",
      "selector": "fas fa-copy",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "copy",
      "selector": "far fa-copy",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "copy",
      "selector": "fal fa-copy",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "copy",
      "selector": "fad fa-copy",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "copyright",
      "selector": "fas fa-copyright",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "copyright",
      "selector": "far fa-copyright",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "copyright",
      "selector": "fal fa-copyright",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "copyright",
      "selector": "fad fa-copyright",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "corn",
      "selector": "fas fa-corn",
      "categories": [
        "Solid",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "corn",
      "selector": "far fa-corn",
      "categories": [
        "Regular",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "corn",
      "selector": "fal fa-corn",
      "categories": [
        "Light",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "corn",
      "selector": "fad fa-corn",
      "categories": [
        "Duotone",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "cotton-bureau",
      "selector": "fab fa-cotton-bureau",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "couch",
      "selector": "fas fa-couch",
      "categories": [
        "Solid",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "couch",
      "selector": "far fa-couch",
      "categories": [
        "Regular",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "couch",
      "selector": "fal fa-couch",
      "categories": [
        "Light",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "couch",
      "selector": "fad fa-couch",
      "categories": [
        "Duotone",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "cow",
      "selector": "fas fa-cow",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "cow",
      "selector": "far fa-cow",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "cow",
      "selector": "fal fa-cow",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "cow",
      "selector": "fad fa-cow",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "cowbell",
      "selector": "fas fa-cowbell",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "cowbell",
      "selector": "far fa-cowbell",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "cowbell",
      "selector": "fal fa-cowbell",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "cowbell",
      "selector": "fad fa-cowbell",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "cowbell-more",
      "selector": "fas fa-cowbell-more",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "cowbell-more",
      "selector": "far fa-cowbell-more",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "cowbell-more",
      "selector": "fal fa-cowbell-more",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "cowbell-more",
      "selector": "fad fa-cowbell-more",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "cpanel",
      "selector": "fab fa-cpanel",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons",
      "selector": "fab fa-creative-commons",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-by",
      "selector": "fab fa-creative-commons-by",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-nc",
      "selector": "fab fa-creative-commons-nc",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-nc-eu",
      "selector": "fab fa-creative-commons-nc-eu",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-nc-jp",
      "selector": "fab fa-creative-commons-nc-jp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-nd",
      "selector": "fab fa-creative-commons-nd",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-pd",
      "selector": "fab fa-creative-commons-pd",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-pd-alt",
      "selector": "fab fa-creative-commons-pd-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-remix",
      "selector": "fab fa-creative-commons-remix",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-sa",
      "selector": "fab fa-creative-commons-sa",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-sampling",
      "selector": "fab fa-creative-commons-sampling",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-sampling-plus",
      "selector": "fab fa-creative-commons-sampling-plus",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-share",
      "selector": "fab fa-creative-commons-share",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "creative-commons-zero",
      "selector": "fab fa-creative-commons-zero",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "credit-card",
      "selector": "fas fa-credit-card",
      "categories": [
        "Solid",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card",
      "selector": "far fa-credit-card",
      "categories": [
        "Regular",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card",
      "selector": "fal fa-credit-card",
      "categories": [
        "Light",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card",
      "selector": "fad fa-credit-card",
      "categories": [
        "Duotone",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-blank",
      "selector": "fas fa-credit-card-blank",
      "categories": [
        "Solid",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-blank",
      "selector": "far fa-credit-card-blank",
      "categories": [
        "Regular",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-blank",
      "selector": "fal fa-credit-card-blank",
      "categories": [
        "Light",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-blank",
      "selector": "fad fa-credit-card-blank",
      "categories": [
        "Duotone",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-front",
      "selector": "fas fa-credit-card-front",
      "categories": [
        "Solid",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-front",
      "selector": "far fa-credit-card-front",
      "categories": [
        "Regular",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-front",
      "selector": "fal fa-credit-card-front",
      "categories": [
        "Light",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "credit-card-front",
      "selector": "fad fa-credit-card-front",
      "categories": [
        "Duotone",
        "Finance",
        "Payments & Shopping"
      ]
    },
    {
      "name": "cricket",
      "selector": "fas fa-cricket",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "cricket",
      "selector": "far fa-cricket",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "cricket",
      "selector": "fal fa-cricket",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "cricket",
      "selector": "fad fa-cricket",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "critical-role",
      "selector": "fab fa-critical-role",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "croissant",
      "selector": "fas fa-croissant",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "croissant",
      "selector": "far fa-croissant",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "croissant",
      "selector": "fal fa-croissant",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "croissant",
      "selector": "fad fa-croissant",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "crop",
      "selector": "fas fa-crop",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "crop",
      "selector": "far fa-crop",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "crop",
      "selector": "fal fa-crop",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "crop",
      "selector": "fad fa-crop",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "crop-alt",
      "selector": "fas fa-crop-alt",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "crop-alt",
      "selector": "far fa-crop-alt",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "crop-alt",
      "selector": "fal fa-crop-alt",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "crop-alt",
      "selector": "fad fa-crop-alt",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "cross",
      "selector": "fas fa-cross",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "cross",
      "selector": "far fa-cross",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "cross",
      "selector": "fal fa-cross",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "cross",
      "selector": "fad fa-cross",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "crosshairs",
      "selector": "fas fa-crosshairs",
      "categories": [
        "Solid",
        "Design",
        "Maps",
        "Spinners"
      ]
    },
    {
      "name": "crosshairs",
      "selector": "far fa-crosshairs",
      "categories": [
        "Regular",
        "Design",
        "Maps",
        "Spinners"
      ]
    },
    {
      "name": "crosshairs",
      "selector": "fal fa-crosshairs",
      "categories": [
        "Light",
        "Design",
        "Maps",
        "Spinners"
      ]
    },
    {
      "name": "crosshairs",
      "selector": "fad fa-crosshairs",
      "categories": [
        "Duotone",
        "Design",
        "Maps",
        "Spinners"
      ]
    },
    {
      "name": "crow",
      "selector": "fas fa-crow",
      "categories": [
        "Solid",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "crow",
      "selector": "far fa-crow",
      "categories": [
        "Regular",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "crow",
      "selector": "fal fa-crow",
      "categories": [
        "Light",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "crow",
      "selector": "fad fa-crow",
      "categories": [
        "Duotone",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "crown",
      "selector": "fas fa-crown",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "crown",
      "selector": "far fa-crown",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "crown",
      "selector": "fal fa-crown",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "crown",
      "selector": "fad fa-crown",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "crutch",
      "selector": "fas fa-crutch",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "crutch",
      "selector": "far fa-crutch",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "crutch",
      "selector": "fal fa-crutch",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "crutch",
      "selector": "fad fa-crutch",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "crutches",
      "selector": "fas fa-crutches",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "crutches",
      "selector": "far fa-crutches",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "crutches",
      "selector": "fal fa-crutches",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "crutches",
      "selector": "fad fa-crutches",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "css3",
      "selector": "fab fa-css3",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "css3-alt",
      "selector": "fab fa-css3-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "cube",
      "selector": "fas fa-cube",
      "categories": [
        "Solid",
        "Objects"
      ]
    },
    {
      "name": "cube",
      "selector": "far fa-cube",
      "categories": [
        "Regular",
        "Objects"
      ]
    },
    {
      "name": "cube",
      "selector": "fal fa-cube",
      "categories": [
        "Light",
        "Objects"
      ]
    },
    {
      "name": "cube",
      "selector": "fad fa-cube",
      "categories": [
        "Duotone",
        "Objects"
      ]
    },
    {
      "name": "cubes",
      "selector": "fas fa-cubes",
      "categories": [
        "Solid",
        "Objects"
      ]
    },
    {
      "name": "cubes",
      "selector": "far fa-cubes",
      "categories": [
        "Regular",
        "Objects"
      ]
    },
    {
      "name": "cubes",
      "selector": "fal fa-cubes",
      "categories": [
        "Light",
        "Objects"
      ]
    },
    {
      "name": "cubes",
      "selector": "fad fa-cubes",
      "categories": [
        "Duotone",
        "Objects"
      ]
    },
    {
      "name": "curling",
      "selector": "fas fa-curling",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "curling",
      "selector": "far fa-curling",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "curling",
      "selector": "fal fa-curling",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "curling",
      "selector": "fad fa-curling",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "cut",
      "selector": "fas fa-cut",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cut",
      "selector": "far fa-cut",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cut",
      "selector": "fal fa-cut",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cut",
      "selector": "fad fa-cut",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "cuttlefish",
      "selector": "fab fa-cuttlefish",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "d-and-d",
      "selector": "fab fa-d-and-d",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "d-and-d-beyond",
      "selector": "fab fa-d-and-d-beyond",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dagger",
      "selector": "fas fa-dagger",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dagger",
      "selector": "far fa-dagger",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dagger",
      "selector": "fal fa-dagger",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dagger",
      "selector": "fad fa-dagger",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dashcube",
      "selector": "fab fa-dashcube",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "database",
      "selector": "fas fa-database",
      "categories": [
        "Solid",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "database",
      "selector": "far fa-database",
      "categories": [
        "Regular",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "database",
      "selector": "fal fa-database",
      "categories": [
        "Light",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "database",
      "selector": "fad fa-database",
      "categories": [
        "Duotone",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "deaf",
      "selector": "fas fa-deaf",
      "categories": [
        "Solid",
        "Accessibility"
      ]
    },
    {
      "name": "deaf",
      "selector": "far fa-deaf",
      "categories": [
        "Regular",
        "Accessibility"
      ]
    },
    {
      "name": "deaf",
      "selector": "fal fa-deaf",
      "categories": [
        "Light",
        "Accessibility"
      ]
    },
    {
      "name": "deaf",
      "selector": "fad fa-deaf",
      "categories": [
        "Duotone",
        "Accessibility"
      ]
    },
    {
      "name": "debug",
      "selector": "fas fa-debug",
      "categories": [
        "Solid",
        "Security"
      ]
    },
    {
      "name": "debug",
      "selector": "far fa-debug",
      "categories": [
        "Regular",
        "Security"
      ]
    },
    {
      "name": "debug",
      "selector": "fal fa-debug",
      "categories": [
        "Light",
        "Security"
      ]
    },
    {
      "name": "debug",
      "selector": "fad fa-debug",
      "categories": [
        "Duotone",
        "Security"
      ]
    },
    {
      "name": "deer",
      "selector": "fas fa-deer",
      "categories": [
        "Solid",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer",
      "selector": "far fa-deer",
      "categories": [
        "Regular",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer",
      "selector": "fal fa-deer",
      "categories": [
        "Light",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer",
      "selector": "fad fa-deer",
      "categories": [
        "Duotone",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer-rudolph",
      "selector": "fas fa-deer-rudolph",
      "categories": [
        "Solid",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer-rudolph",
      "selector": "far fa-deer-rudolph",
      "categories": [
        "Regular",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer-rudolph",
      "selector": "fal fa-deer-rudolph",
      "categories": [
        "Light",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "deer-rudolph",
      "selector": "fad fa-deer-rudolph",
      "categories": [
        "Duotone",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "delicious",
      "selector": "fab fa-delicious",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "democrat",
      "selector": "fas fa-democrat",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "democrat",
      "selector": "far fa-democrat",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "democrat",
      "selector": "fal fa-democrat",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "democrat",
      "selector": "fad fa-democrat",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "deploydog",
      "selector": "fab fa-deploydog",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "deskpro",
      "selector": "fab fa-deskpro",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "desktop",
      "selector": "fas fa-desktop",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "desktop",
      "selector": "far fa-desktop",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "desktop",
      "selector": "fal fa-desktop",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "desktop",
      "selector": "fad fa-desktop",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "desktop-alt",
      "selector": "fas fa-desktop-alt",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "desktop-alt",
      "selector": "far fa-desktop-alt",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "desktop-alt",
      "selector": "fal fa-desktop-alt",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "desktop-alt",
      "selector": "fad fa-desktop-alt",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "dev",
      "selector": "fab fa-dev",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "deviantart",
      "selector": "fab fa-deviantart",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "dewpoint",
      "selector": "fas fa-dewpoint",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "dewpoint",
      "selector": "far fa-dewpoint",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "dewpoint",
      "selector": "fal fa-dewpoint",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "dewpoint",
      "selector": "fad fa-dewpoint",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "dharmachakra",
      "selector": "fas fa-dharmachakra",
      "categories": [
        "Solid",
        "Maritime",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "dharmachakra",
      "selector": "far fa-dharmachakra",
      "categories": [
        "Regular",
        "Maritime",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "dharmachakra",
      "selector": "fal fa-dharmachakra",
      "categories": [
        "Light",
        "Maritime",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "dharmachakra",
      "selector": "fad fa-dharmachakra",
      "categories": [
        "Duotone",
        "Maritime",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "dhl",
      "selector": "fab fa-dhl",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "diagnoses",
      "selector": "fas fa-diagnoses",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "diagnoses",
      "selector": "far fa-diagnoses",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "diagnoses",
      "selector": "fal fa-diagnoses",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "diagnoses",
      "selector": "fad fa-diagnoses",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "diamond",
      "selector": "fas fa-diamond",
      "categories": [
        "Solid",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "diamond",
      "selector": "far fa-diamond",
      "categories": [
        "Regular",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "diamond",
      "selector": "fal fa-diamond",
      "categories": [
        "Light",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "diamond",
      "selector": "fad fa-diamond",
      "categories": [
        "Duotone",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "diaspora",
      "selector": "fab fa-diaspora",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "dice",
      "selector": "fas fa-dice",
      "categories": [
        "Solid",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice",
      "selector": "far fa-dice",
      "categories": [
        "Regular",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice",
      "selector": "fal fa-dice",
      "categories": [
        "Light",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice",
      "selector": "fad fa-dice",
      "categories": [
        "Duotone",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice-d10",
      "selector": "fas fa-dice-d10",
      "categories": [
        "Solid",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d10",
      "selector": "far fa-dice-d10",
      "categories": [
        "Regular",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d10",
      "selector": "fal fa-dice-d10",
      "categories": [
        "Light",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d10",
      "selector": "fad fa-dice-d10",
      "categories": [
        "Duotone",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d12",
      "selector": "fas fa-dice-d12",
      "categories": [
        "Solid",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d12",
      "selector": "far fa-dice-d12",
      "categories": [
        "Regular",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d12",
      "selector": "fal fa-dice-d12",
      "categories": [
        "Light",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d12",
      "selector": "fad fa-dice-d12",
      "categories": [
        "Duotone",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d20",
      "selector": "fas fa-dice-d20",
      "categories": [
        "Solid",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d20",
      "selector": "far fa-dice-d20",
      "categories": [
        "Regular",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d20",
      "selector": "fal fa-dice-d20",
      "categories": [
        "Light",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d20",
      "selector": "fad fa-dice-d20",
      "categories": [
        "Duotone",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d4",
      "selector": "fas fa-dice-d4",
      "categories": [
        "Solid",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d4",
      "selector": "far fa-dice-d4",
      "categories": [
        "Regular",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d4",
      "selector": "fal fa-dice-d4",
      "categories": [
        "Light",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d4",
      "selector": "fad fa-dice-d4",
      "categories": [
        "Duotone",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d6",
      "selector": "fas fa-dice-d6",
      "categories": [
        "Solid",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d6",
      "selector": "far fa-dice-d6",
      "categories": [
        "Regular",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d6",
      "selector": "fal fa-dice-d6",
      "categories": [
        "Light",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d6",
      "selector": "fad fa-dice-d6",
      "categories": [
        "Duotone",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d8",
      "selector": "fas fa-dice-d8",
      "categories": [
        "Solid",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d8",
      "selector": "far fa-dice-d8",
      "categories": [
        "Regular",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d8",
      "selector": "fal fa-dice-d8",
      "categories": [
        "Light",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-d8",
      "selector": "fad fa-dice-d8",
      "categories": [
        "Duotone",
        "Games",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dice-five",
      "selector": "fas fa-dice-five",
      "categories": [
        "Solid",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice-five",
      "selector": "far fa-dice-five",
      "categories": [
        "Regular",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice-five",
      "selector": "fal fa-dice-five",
      "categories": [
        "Light",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice-five",
      "selector": "fad fa-dice-five",
      "categories": [
        "Duotone",
        "Games",
        "Hotel",
        "Objects"
      ]
    },
    {
      "name": "dice-four",
      "selector": "fas fa-dice-four",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-four",
      "selector": "far fa-dice-four",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-four",
      "selector": "fal fa-dice-four",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-four",
      "selector": "fad fa-dice-four",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-one",
      "selector": "fas fa-dice-one",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-one",
      "selector": "far fa-dice-one",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-one",
      "selector": "fal fa-dice-one",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-one",
      "selector": "fad fa-dice-one",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-six",
      "selector": "fas fa-dice-six",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-six",
      "selector": "far fa-dice-six",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-six",
      "selector": "fal fa-dice-six",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-six",
      "selector": "fad fa-dice-six",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-three",
      "selector": "fas fa-dice-three",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-three",
      "selector": "far fa-dice-three",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-three",
      "selector": "fal fa-dice-three",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-three",
      "selector": "fad fa-dice-three",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-two",
      "selector": "fas fa-dice-two",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-two",
      "selector": "far fa-dice-two",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-two",
      "selector": "fal fa-dice-two",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "dice-two",
      "selector": "fad fa-dice-two",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "digg",
      "selector": "fab fa-digg",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "digging",
      "selector": "fas fa-digging",
      "categories": [
        "Solid",
        "Construction",
        "Users & People"
      ]
    },
    {
      "name": "digging",
      "selector": "far fa-digging",
      "categories": [
        "Regular",
        "Construction",
        "Users & People"
      ]
    },
    {
      "name": "digging",
      "selector": "fal fa-digging",
      "categories": [
        "Light",
        "Construction",
        "Users & People"
      ]
    },
    {
      "name": "digging",
      "selector": "fad fa-digging",
      "categories": [
        "Duotone",
        "Construction",
        "Users & People"
      ]
    },
    {
      "name": "digital-ocean",
      "selector": "fab fa-digital-ocean",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "digital-tachograph",
      "selector": "fas fa-digital-tachograph",
      "categories": [
        "Solid",
        "Objects"
      ]
    },
    {
      "name": "digital-tachograph",
      "selector": "far fa-digital-tachograph",
      "categories": [
        "Regular",
        "Objects"
      ]
    },
    {
      "name": "digital-tachograph",
      "selector": "fal fa-digital-tachograph",
      "categories": [
        "Light",
        "Objects"
      ]
    },
    {
      "name": "digital-tachograph",
      "selector": "fad fa-digital-tachograph",
      "categories": [
        "Duotone",
        "Objects"
      ]
    },
    {
      "name": "diploma",
      "selector": "fas fa-diploma",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "diploma",
      "selector": "far fa-diploma",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "diploma",
      "selector": "fal fa-diploma",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "diploma",
      "selector": "fad fa-diploma",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "directions",
      "selector": "fas fa-directions",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "directions",
      "selector": "far fa-directions",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "directions",
      "selector": "fal fa-directions",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "directions",
      "selector": "fad fa-directions",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "disc-drive",
      "selector": "fas fa-disc-drive",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "disc-drive",
      "selector": "far fa-disc-drive",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "disc-drive",
      "selector": "fal fa-disc-drive",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "disc-drive",
      "selector": "fad fa-disc-drive",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "discord",
      "selector": "fab fa-discord",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "discourse",
      "selector": "fab fa-discourse",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "disease",
      "selector": "fas fa-disease",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "disease",
      "selector": "far fa-disease",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "disease",
      "selector": "fal fa-disease",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "disease",
      "selector": "fad fa-disease",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "divide",
      "selector": "fas fa-divide",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "divide",
      "selector": "far fa-divide",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "divide",
      "selector": "fal fa-divide",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "divide",
      "selector": "fad fa-divide",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "dizzy",
      "selector": "fas fa-dizzy",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "dizzy",
      "selector": "far fa-dizzy",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "dizzy",
      "selector": "fal fa-dizzy",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "dizzy",
      "selector": "fad fa-dizzy",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "dna",
      "selector": "fas fa-dna",
      "categories": [
        "Solid",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "dna",
      "selector": "far fa-dna",
      "categories": [
        "Regular",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "dna",
      "selector": "fal fa-dna",
      "categories": [
        "Light",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "dna",
      "selector": "fad fa-dna",
      "categories": [
        "Duotone",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "do-not-enter",
      "selector": "fas fa-do-not-enter",
      "categories": [
        "Solid",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "do-not-enter",
      "selector": "far fa-do-not-enter",
      "categories": [
        "Regular",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "do-not-enter",
      "selector": "fal fa-do-not-enter",
      "categories": [
        "Light",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "do-not-enter",
      "selector": "fad fa-do-not-enter",
      "categories": [
        "Duotone",
        "Maps",
        "Security"
      ]
    },
    {
      "name": "dochub",
      "selector": "fab fa-dochub",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "docker",
      "selector": "fab fa-docker",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "dog",
      "selector": "fas fa-dog",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "dog",
      "selector": "far fa-dog",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "dog",
      "selector": "fal fa-dog",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "dog",
      "selector": "fad fa-dog",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "dog-leashed",
      "selector": "fas fa-dog-leashed",
      "categories": [
        "Solid",
        "Accessibility",
        "Animals"
      ]
    },
    {
      "name": "dog-leashed",
      "selector": "far fa-dog-leashed",
      "categories": [
        "Regular",
        "Accessibility",
        "Animals"
      ]
    },
    {
      "name": "dog-leashed",
      "selector": "fal fa-dog-leashed",
      "categories": [
        "Light",
        "Accessibility",
        "Animals"
      ]
    },
    {
      "name": "dog-leashed",
      "selector": "fad fa-dog-leashed",
      "categories": [
        "Duotone",
        "Accessibility",
        "Animals"
      ]
    },
    {
      "name": "dollar-sign",
      "selector": "fas fa-dollar-sign",
      "categories": [
        "Solid",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "dollar-sign",
      "selector": "far fa-dollar-sign",
      "categories": [
        "Regular",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "dollar-sign",
      "selector": "fal fa-dollar-sign",
      "categories": [
        "Light",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "dollar-sign",
      "selector": "fad fa-dollar-sign",
      "categories": [
        "Duotone",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "dolly",
      "selector": "fas fa-dolly",
      "categories": [
        "Solid",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly",
      "selector": "far fa-dolly",
      "categories": [
        "Regular",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly",
      "selector": "fal fa-dolly",
      "categories": [
        "Light",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly",
      "selector": "fad fa-dolly",
      "categories": [
        "Duotone",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly-empty",
      "selector": "fas fa-dolly-empty",
      "categories": [
        "Solid",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly-empty",
      "selector": "far fa-dolly-empty",
      "categories": [
        "Regular",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly-empty",
      "selector": "fal fa-dolly-empty",
      "categories": [
        "Light",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly-empty",
      "selector": "fad fa-dolly-empty",
      "categories": [
        "Duotone",
        "Logistics",
        "Moving"
      ]
    },
    {
      "name": "dolly-flatbed",
      "selector": "fas fa-dolly-flatbed",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed",
      "selector": "far fa-dolly-flatbed",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed",
      "selector": "fal fa-dolly-flatbed",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed",
      "selector": "fad fa-dolly-flatbed",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-alt",
      "selector": "fas fa-dolly-flatbed-alt",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-alt",
      "selector": "far fa-dolly-flatbed-alt",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-alt",
      "selector": "fal fa-dolly-flatbed-alt",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-alt",
      "selector": "fad fa-dolly-flatbed-alt",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-empty",
      "selector": "fas fa-dolly-flatbed-empty",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-empty",
      "selector": "far fa-dolly-flatbed-empty",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-empty",
      "selector": "fal fa-dolly-flatbed-empty",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "dolly-flatbed-empty",
      "selector": "fad fa-dolly-flatbed-empty",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "donate",
      "selector": "fas fa-donate",
      "categories": [
        "Solid",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "donate",
      "selector": "far fa-donate",
      "categories": [
        "Regular",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "donate",
      "selector": "fal fa-donate",
      "categories": [
        "Light",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "donate",
      "selector": "fad fa-donate",
      "categories": [
        "Duotone",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "door-closed",
      "selector": "fas fa-door-closed",
      "categories": [
        "Solid",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-closed",
      "selector": "far fa-door-closed",
      "categories": [
        "Regular",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-closed",
      "selector": "fal fa-door-closed",
      "categories": [
        "Light",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-closed",
      "selector": "fad fa-door-closed",
      "categories": [
        "Duotone",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-open",
      "selector": "fas fa-door-open",
      "categories": [
        "Solid",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-open",
      "selector": "far fa-door-open",
      "categories": [
        "Regular",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-open",
      "selector": "fal fa-door-open",
      "categories": [
        "Light",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "door-open",
      "selector": "fad fa-door-open",
      "categories": [
        "Duotone",
        "Hotel",
        "Household",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "dot-circle",
      "selector": "fas fa-dot-circle",
      "categories": [
        "Solid",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "dot-circle",
      "selector": "far fa-dot-circle",
      "categories": [
        "Regular",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "dot-circle",
      "selector": "fal fa-dot-circle",
      "categories": [
        "Light",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "dot-circle",
      "selector": "fad fa-dot-circle",
      "categories": [
        "Duotone",
        "Interfaces",
        "Toggle"
      ]
    },
    {
      "name": "dove",
      "selector": "fas fa-dove",
      "categories": [
        "Solid",
        "Animals",
        "Charity",
        "Political",
        "Religion"
      ]
    },
    {
      "name": "dove",
      "selector": "far fa-dove",
      "categories": [
        "Regular",
        "Animals",
        "Charity",
        "Political",
        "Religion"
      ]
    },
    {
      "name": "dove",
      "selector": "fal fa-dove",
      "categories": [
        "Light",
        "Animals",
        "Charity",
        "Political",
        "Religion"
      ]
    },
    {
      "name": "dove",
      "selector": "fad fa-dove",
      "categories": [
        "Duotone",
        "Animals",
        "Charity",
        "Political",
        "Religion"
      ]
    },
    {
      "name": "download",
      "selector": "fas fa-download",
      "categories": [
        "Solid",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "download",
      "selector": "far fa-download",
      "categories": [
        "Regular",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "download",
      "selector": "fal fa-download",
      "categories": [
        "Light",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "download",
      "selector": "fad fa-download",
      "categories": [
        "Duotone",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "draft2digital",
      "selector": "fab fa-draft2digital",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "drafting-compass",
      "selector": "fas fa-drafting-compass",
      "categories": [
        "Solid",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "drafting-compass",
      "selector": "far fa-drafting-compass",
      "categories": [
        "Regular",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "drafting-compass",
      "selector": "fal fa-drafting-compass",
      "categories": [
        "Light",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "drafting-compass",
      "selector": "fad fa-drafting-compass",
      "categories": [
        "Duotone",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "dragon",
      "selector": "fas fa-dragon",
      "categories": [
        "Solid",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dragon",
      "selector": "far fa-dragon",
      "categories": [
        "Regular",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dragon",
      "selector": "fal fa-dragon",
      "categories": [
        "Light",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dragon",
      "selector": "fad fa-dragon",
      "categories": [
        "Duotone",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "draw-circle",
      "selector": "fas fa-draw-circle",
      "categories": [
        "Solid",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-circle",
      "selector": "far fa-draw-circle",
      "categories": [
        "Regular",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-circle",
      "selector": "fal fa-draw-circle",
      "categories": [
        "Light",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-circle",
      "selector": "fad fa-draw-circle",
      "categories": [
        "Duotone",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-polygon",
      "selector": "fas fa-draw-polygon",
      "categories": [
        "Solid",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-polygon",
      "selector": "far fa-draw-polygon",
      "categories": [
        "Regular",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-polygon",
      "selector": "fal fa-draw-polygon",
      "categories": [
        "Light",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-polygon",
      "selector": "fad fa-draw-polygon",
      "categories": [
        "Duotone",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-square",
      "selector": "fas fa-draw-square",
      "categories": [
        "Solid",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-square",
      "selector": "far fa-draw-square",
      "categories": [
        "Regular",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-square",
      "selector": "fal fa-draw-square",
      "categories": [
        "Light",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "draw-square",
      "selector": "fad fa-draw-square",
      "categories": [
        "Duotone",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "dreidel",
      "selector": "fas fa-dreidel",
      "categories": [
        "Solid",
        "Games",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "dreidel",
      "selector": "far fa-dreidel",
      "categories": [
        "Regular",
        "Games",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "dreidel",
      "selector": "fal fa-dreidel",
      "categories": [
        "Light",
        "Games",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "dreidel",
      "selector": "fad fa-dreidel",
      "categories": [
        "Duotone",
        "Games",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "dribbble",
      "selector": "fab fa-dribbble",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "dribbble-square",
      "selector": "fab fa-dribbble-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "drone",
      "selector": "fas fa-drone",
      "categories": [
        "Solid",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone",
      "selector": "far fa-drone",
      "categories": [
        "Regular",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone",
      "selector": "fal fa-drone",
      "categories": [
        "Light",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone",
      "selector": "fad fa-drone",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone-alt",
      "selector": "fas fa-drone-alt",
      "categories": [
        "Solid",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone-alt",
      "selector": "far fa-drone-alt",
      "categories": [
        "Regular",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone-alt",
      "selector": "fal fa-drone-alt",
      "categories": [
        "Light",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "drone-alt",
      "selector": "fad fa-drone-alt",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "dropbox",
      "selector": "fab fa-dropbox",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "drum",
      "selector": "fas fa-drum",
      "categories": [
        "Solid",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum",
      "selector": "far fa-drum",
      "categories": [
        "Regular",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum",
      "selector": "fal fa-drum",
      "categories": [
        "Light",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum",
      "selector": "fad fa-drum",
      "categories": [
        "Duotone",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum-steelpan",
      "selector": "fas fa-drum-steelpan",
      "categories": [
        "Solid",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum-steelpan",
      "selector": "far fa-drum-steelpan",
      "categories": [
        "Regular",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum-steelpan",
      "selector": "fal fa-drum-steelpan",
      "categories": [
        "Light",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drum-steelpan",
      "selector": "fad fa-drum-steelpan",
      "categories": [
        "Duotone",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "drumstick",
      "selector": "fas fa-drumstick",
      "categories": [
        "Solid",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick",
      "selector": "far fa-drumstick",
      "categories": [
        "Regular",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick",
      "selector": "fal fa-drumstick",
      "categories": [
        "Light",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick",
      "selector": "fad fa-drumstick",
      "categories": [
        "Duotone",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick-bite",
      "selector": "fas fa-drumstick-bite",
      "categories": [
        "Solid",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick-bite",
      "selector": "far fa-drumstick-bite",
      "categories": [
        "Regular",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick-bite",
      "selector": "fal fa-drumstick-bite",
      "categories": [
        "Light",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drumstick-bite",
      "selector": "fad fa-drumstick-bite",
      "categories": [
        "Duotone",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "drupal",
      "selector": "fab fa-drupal",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "dryer",
      "selector": "fas fa-dryer",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer",
      "selector": "far fa-dryer",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer",
      "selector": "fal fa-dryer",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer",
      "selector": "fad fa-dryer",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer-alt",
      "selector": "fas fa-dryer-alt",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer-alt",
      "selector": "far fa-dryer-alt",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer-alt",
      "selector": "fal fa-dryer-alt",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "dryer-alt",
      "selector": "fad fa-dryer-alt",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "duck",
      "selector": "fas fa-duck",
      "categories": [
        "Solid",
        "Animals",
        "Childhood",
        "Maritime"
      ]
    },
    {
      "name": "duck",
      "selector": "far fa-duck",
      "categories": [
        "Regular",
        "Animals",
        "Childhood",
        "Maritime"
      ]
    },
    {
      "name": "duck",
      "selector": "fal fa-duck",
      "categories": [
        "Light",
        "Animals",
        "Childhood",
        "Maritime"
      ]
    },
    {
      "name": "duck",
      "selector": "fad fa-duck",
      "categories": [
        "Duotone",
        "Animals",
        "Childhood",
        "Maritime"
      ]
    },
    {
      "name": "dumbbell",
      "selector": "fas fa-dumbbell",
      "categories": [
        "Solid",
        "Hotel",
        "Sports",
        "Travel"
      ]
    },
    {
      "name": "dumbbell",
      "selector": "far fa-dumbbell",
      "categories": [
        "Regular",
        "Hotel",
        "Sports",
        "Travel"
      ]
    },
    {
      "name": "dumbbell",
      "selector": "fal fa-dumbbell",
      "categories": [
        "Light",
        "Hotel",
        "Sports",
        "Travel"
      ]
    },
    {
      "name": "dumbbell",
      "selector": "fad fa-dumbbell",
      "categories": [
        "Duotone",
        "Hotel",
        "Sports",
        "Travel"
      ]
    },
    {
      "name": "dumpster",
      "selector": "fas fa-dumpster",
      "categories": [
        "Solid",
        "Construction"
      ]
    },
    {
      "name": "dumpster",
      "selector": "far fa-dumpster",
      "categories": [
        "Regular",
        "Construction"
      ]
    },
    {
      "name": "dumpster",
      "selector": "fal fa-dumpster",
      "categories": [
        "Light",
        "Construction"
      ]
    },
    {
      "name": "dumpster",
      "selector": "fad fa-dumpster",
      "categories": [
        "Duotone",
        "Construction"
      ]
    },
    {
      "name": "dumpster-fire",
      "selector": "fas fa-dumpster-fire",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "dumpster-fire",
      "selector": "far fa-dumpster-fire",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "dumpster-fire",
      "selector": "fal fa-dumpster-fire",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "dumpster-fire",
      "selector": "fad fa-dumpster-fire",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "dungeon",
      "selector": "fas fa-dungeon",
      "categories": [
        "Solid",
        "Buildings",
        "Household",
        "Security",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dungeon",
      "selector": "far fa-dungeon",
      "categories": [
        "Regular",
        "Buildings",
        "Household",
        "Security",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dungeon",
      "selector": "fal fa-dungeon",
      "categories": [
        "Light",
        "Buildings",
        "Household",
        "Security",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dungeon",
      "selector": "fad fa-dungeon",
      "categories": [
        "Duotone",
        "Buildings",
        "Household",
        "Security",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "dyalog",
      "selector": "fab fa-dyalog",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ear",
      "selector": "fas fa-ear",
      "categories": [
        "Solid",
        "Medical",
        "Music"
      ]
    },
    {
      "name": "ear",
      "selector": "far fa-ear",
      "categories": [
        "Regular",
        "Medical",
        "Music"
      ]
    },
    {
      "name": "ear",
      "selector": "fal fa-ear",
      "categories": [
        "Light",
        "Medical",
        "Music"
      ]
    },
    {
      "name": "ear",
      "selector": "fad fa-ear",
      "categories": [
        "Duotone",
        "Medical",
        "Music"
      ]
    },
    {
      "name": "ear-muffs",
      "selector": "fas fa-ear-muffs",
      "categories": [
        "Solid",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ear-muffs",
      "selector": "far fa-ear-muffs",
      "categories": [
        "Regular",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ear-muffs",
      "selector": "fal fa-ear-muffs",
      "categories": [
        "Light",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "ear-muffs",
      "selector": "fad fa-ear-muffs",
      "categories": [
        "Duotone",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "earlybirds",
      "selector": "fab fa-earlybirds",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ebay",
      "selector": "fab fa-ebay",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "eclipse",
      "selector": "fas fa-eclipse",
      "categories": [
        "Solid",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "eclipse",
      "selector": "far fa-eclipse",
      "categories": [
        "Regular",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "eclipse",
      "selector": "fal fa-eclipse",
      "categories": [
        "Light",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "eclipse",
      "selector": "fad fa-eclipse",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "eclipse-alt",
      "selector": "fas fa-eclipse-alt",
      "categories": [
        "Solid",
        "Science Fiction",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "eclipse-alt",
      "selector": "far fa-eclipse-alt",
      "categories": [
        "Regular",
        "Science Fiction",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "eclipse-alt",
      "selector": "fal fa-eclipse-alt",
      "categories": [
        "Light",
        "Science Fiction",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "eclipse-alt",
      "selector": "fad fa-eclipse-alt",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "edge",
      "selector": "fab fa-edge",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "edit",
      "selector": "fas fa-edit",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "edit",
      "selector": "far fa-edit",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "edit",
      "selector": "fal fa-edit",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "edit",
      "selector": "fad fa-edit",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "egg",
      "selector": "fas fa-egg",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "egg",
      "selector": "far fa-egg",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "egg",
      "selector": "fal fa-egg",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "egg",
      "selector": "fad fa-egg",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "egg-fried",
      "selector": "fas fa-egg-fried",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "egg-fried",
      "selector": "far fa-egg-fried",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "egg-fried",
      "selector": "fal fa-egg-fried",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "egg-fried",
      "selector": "fad fa-egg-fried",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "eject",
      "selector": "fas fa-eject",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "eject",
      "selector": "far fa-eject",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "eject",
      "selector": "fal fa-eject",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "eject",
      "selector": "fad fa-eject",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "elementor",
      "selector": "fab fa-elementor",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "elephant",
      "selector": "fas fa-elephant",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "elephant",
      "selector": "far fa-elephant",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "elephant",
      "selector": "fal fa-elephant",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "elephant",
      "selector": "fad fa-elephant",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "ellipsis-h",
      "selector": "fas fa-ellipsis-h",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h",
      "selector": "far fa-ellipsis-h",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h",
      "selector": "fal fa-ellipsis-h",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h",
      "selector": "fad fa-ellipsis-h",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h-alt",
      "selector": "fas fa-ellipsis-h-alt",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h-alt",
      "selector": "far fa-ellipsis-h-alt",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h-alt",
      "selector": "fal fa-ellipsis-h-alt",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-h-alt",
      "selector": "fad fa-ellipsis-h-alt",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v",
      "selector": "fas fa-ellipsis-v",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v",
      "selector": "far fa-ellipsis-v",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v",
      "selector": "fal fa-ellipsis-v",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v",
      "selector": "fad fa-ellipsis-v",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v-alt",
      "selector": "fas fa-ellipsis-v-alt",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v-alt",
      "selector": "far fa-ellipsis-v-alt",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v-alt",
      "selector": "fal fa-ellipsis-v-alt",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "ellipsis-v-alt",
      "selector": "fad fa-ellipsis-v-alt",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "ello",
      "selector": "fab fa-ello",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ember",
      "selector": "fab fa-ember",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "empire",
      "selector": "fab fa-empire",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "empty-set",
      "selector": "fas fa-empty-set",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "empty-set",
      "selector": "far fa-empty-set",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "empty-set",
      "selector": "fal fa-empty-set",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "empty-set",
      "selector": "fad fa-empty-set",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "engine-warning",
      "selector": "fas fa-engine-warning",
      "categories": [
        "Solid",
        "Alert",
        "Automotive"
      ]
    },
    {
      "name": "engine-warning",
      "selector": "far fa-engine-warning",
      "categories": [
        "Regular",
        "Alert",
        "Automotive"
      ]
    },
    {
      "name": "engine-warning",
      "selector": "fal fa-engine-warning",
      "categories": [
        "Light",
        "Alert",
        "Automotive"
      ]
    },
    {
      "name": "engine-warning",
      "selector": "fad fa-engine-warning",
      "categories": [
        "Duotone",
        "Alert",
        "Automotive"
      ]
    },
    {
      "name": "envelope",
      "selector": "fas fa-envelope",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "envelope",
      "selector": "far fa-envelope",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "envelope",
      "selector": "fal fa-envelope",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "envelope",
      "selector": "fad fa-envelope",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "envelope-open",
      "selector": "fas fa-envelope-open",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "envelope-open",
      "selector": "far fa-envelope-open",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "envelope-open",
      "selector": "fal fa-envelope-open",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "envelope-open",
      "selector": "fad fa-envelope-open",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "envelope-open-dollar",
      "selector": "fas fa-envelope-open-dollar",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-dollar",
      "selector": "far fa-envelope-open-dollar",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-dollar",
      "selector": "fal fa-envelope-open-dollar",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-dollar",
      "selector": "fad fa-envelope-open-dollar",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-text",
      "selector": "fas fa-envelope-open-text",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-text",
      "selector": "far fa-envelope-open-text",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-text",
      "selector": "fal fa-envelope-open-text",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "envelope-open-text",
      "selector": "fad fa-envelope-open-text",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "envelope-square",
      "selector": "fas fa-envelope-square",
      "categories": [
        "Solid",
        "Business",
        "Communication"
      ]
    },
    {
      "name": "envelope-square",
      "selector": "far fa-envelope-square",
      "categories": [
        "Regular",
        "Business",
        "Communication"
      ]
    },
    {
      "name": "envelope-square",
      "selector": "fal fa-envelope-square",
      "categories": [
        "Light",
        "Business",
        "Communication"
      ]
    },
    {
      "name": "envelope-square",
      "selector": "fad fa-envelope-square",
      "categories": [
        "Duotone",
        "Business",
        "Communication"
      ]
    },
    {
      "name": "envira",
      "selector": "fab fa-envira",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "equals",
      "selector": "fas fa-equals",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "equals",
      "selector": "far fa-equals",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "equals",
      "selector": "fal fa-equals",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "equals",
      "selector": "fad fa-equals",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "eraser",
      "selector": "fas fa-eraser",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "eraser",
      "selector": "far fa-eraser",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "eraser",
      "selector": "fal fa-eraser",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "eraser",
      "selector": "fad fa-eraser",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "erlang",
      "selector": "fab fa-erlang",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ethereum",
      "selector": "fab fa-ethereum",
      "categories": [
        "Brands",
        "Currency",
        "Payments & Shopping"
      ]
    },
    {
      "name": "ethernet",
      "selector": "fas fa-ethernet",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "ethernet",
      "selector": "far fa-ethernet",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "ethernet",
      "selector": "fal fa-ethernet",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "ethernet",
      "selector": "fad fa-ethernet",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "etsy",
      "selector": "fab fa-etsy",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "euro-sign",
      "selector": "fas fa-euro-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "euro-sign",
      "selector": "far fa-euro-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "euro-sign",
      "selector": "fal fa-euro-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "euro-sign",
      "selector": "fad fa-euro-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "evernote",
      "selector": "fab fa-evernote",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "exchange",
      "selector": "fas fa-exchange",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "exchange",
      "selector": "far fa-exchange",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "exchange",
      "selector": "fal fa-exchange",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "exchange",
      "selector": "fad fa-exchange",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "exchange-alt",
      "selector": "fas fa-exchange-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "exchange-alt",
      "selector": "far fa-exchange-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "exchange-alt",
      "selector": "fal fa-exchange-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "exchange-alt",
      "selector": "fad fa-exchange-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "exclamation",
      "selector": "fas fa-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation",
      "selector": "far fa-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation",
      "selector": "fal fa-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation",
      "selector": "fad fa-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-circle",
      "selector": "fas fa-exclamation-circle",
      "categories": [
        "Solid",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-circle",
      "selector": "far fa-exclamation-circle",
      "categories": [
        "Regular",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-circle",
      "selector": "fal fa-exclamation-circle",
      "categories": [
        "Light",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-circle",
      "selector": "fad fa-exclamation-circle",
      "categories": [
        "Duotone",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-square",
      "selector": "fas fa-exclamation-square",
      "categories": [
        "Solid",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-square",
      "selector": "far fa-exclamation-square",
      "categories": [
        "Regular",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-square",
      "selector": "fal fa-exclamation-square",
      "categories": [
        "Light",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-square",
      "selector": "fad fa-exclamation-square",
      "categories": [
        "Duotone",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-triangle",
      "selector": "fas fa-exclamation-triangle",
      "categories": [
        "Solid",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-triangle",
      "selector": "far fa-exclamation-triangle",
      "categories": [
        "Regular",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-triangle",
      "selector": "fal fa-exclamation-triangle",
      "categories": [
        "Light",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "exclamation-triangle",
      "selector": "fad fa-exclamation-triangle",
      "categories": [
        "Duotone",
        "Alert",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "expand",
      "selector": "fas fa-expand",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand",
      "selector": "far fa-expand",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand",
      "selector": "fal fa-expand",
      "categories": [
        "Light",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand",
      "selector": "fad fa-expand",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand-alt",
      "selector": "fas fa-expand-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-alt",
      "selector": "far fa-expand-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-alt",
      "selector": "fal fa-expand-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-alt",
      "selector": "fad fa-expand-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows",
      "selector": "fas fa-expand-arrows",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows",
      "selector": "far fa-expand-arrows",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows",
      "selector": "fal fa-expand-arrows",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows",
      "selector": "fad fa-expand-arrows",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows-alt",
      "selector": "fas fa-expand-arrows-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows-alt",
      "selector": "far fa-expand-arrows-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows-alt",
      "selector": "fal fa-expand-arrows-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-arrows-alt",
      "selector": "fad fa-expand-arrows-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "expand-wide",
      "selector": "fas fa-expand-wide",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand-wide",
      "selector": "far fa-expand-wide",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand-wide",
      "selector": "fal fa-expand-wide",
      "categories": [
        "Light",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expand-wide",
      "selector": "fad fa-expand-wide",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "expeditedssl",
      "selector": "fab fa-expeditedssl",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "external-link",
      "selector": "fas fa-external-link",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link",
      "selector": "far fa-external-link",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link",
      "selector": "fal fa-external-link",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link",
      "selector": "fad fa-external-link",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-alt",
      "selector": "fas fa-external-link-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-alt",
      "selector": "far fa-external-link-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-alt",
      "selector": "fal fa-external-link-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-alt",
      "selector": "fad fa-external-link-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square",
      "selector": "fas fa-external-link-square",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square",
      "selector": "far fa-external-link-square",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square",
      "selector": "fal fa-external-link-square",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square",
      "selector": "fad fa-external-link-square",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square-alt",
      "selector": "fas fa-external-link-square-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square-alt",
      "selector": "far fa-external-link-square-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square-alt",
      "selector": "fal fa-external-link-square-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "external-link-square-alt",
      "selector": "fad fa-external-link-square-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "eye",
      "selector": "fas fa-eye",
      "categories": [
        "Solid",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye",
      "selector": "far fa-eye",
      "categories": [
        "Regular",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye",
      "selector": "fal fa-eye",
      "categories": [
        "Light",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye",
      "selector": "fad fa-eye",
      "categories": [
        "Duotone",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye-dropper",
      "selector": "fas fa-eye-dropper",
      "categories": [
        "Solid",
        "Design",
        "Images",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "eye-dropper",
      "selector": "far fa-eye-dropper",
      "categories": [
        "Regular",
        "Design",
        "Images",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "eye-dropper",
      "selector": "fal fa-eye-dropper",
      "categories": [
        "Light",
        "Design",
        "Images",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "eye-dropper",
      "selector": "fad fa-eye-dropper",
      "categories": [
        "Duotone",
        "Design",
        "Images",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "eye-evil",
      "selector": "fas fa-eye-evil",
      "categories": [
        "Solid",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "eye-evil",
      "selector": "far fa-eye-evil",
      "categories": [
        "Regular",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "eye-evil",
      "selector": "fal fa-eye-evil",
      "categories": [
        "Light",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "eye-evil",
      "selector": "fad fa-eye-evil",
      "categories": [
        "Duotone",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "eye-slash",
      "selector": "fas fa-eye-slash",
      "categories": [
        "Solid",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye-slash",
      "selector": "far fa-eye-slash",
      "categories": [
        "Regular",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye-slash",
      "selector": "fal fa-eye-slash",
      "categories": [
        "Light",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Security",
        "Status"
      ]
    },
    {
      "name": "eye-slash",
      "selector": "fad fa-eye-slash",
      "categories": [
        "Duotone",
        "Design",
        "Images",
        "Interfaces",
        "Maps",
        "Security",
        "Status"
      ]
    },
    {
      "name": "facebook",
      "selector": "fab fa-facebook",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "facebook-f",
      "selector": "fab fa-facebook-f",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "facebook-messenger",
      "selector": "fab fa-facebook-messenger",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "facebook-square",
      "selector": "fab fa-facebook-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fan",
      "selector": "fas fa-fan",
      "categories": [
        "Solid",
        "Energy",
        "Household",
        "Spinners"
      ]
    },
    {
      "name": "fan",
      "selector": "far fa-fan",
      "categories": [
        "Regular",
        "Energy",
        "Household",
        "Spinners"
      ]
    },
    {
      "name": "fan",
      "selector": "fal fa-fan",
      "categories": [
        "Light",
        "Energy",
        "Household",
        "Spinners"
      ]
    },
    {
      "name": "fan",
      "selector": "fad fa-fan",
      "categories": [
        "Duotone",
        "Energy",
        "Household",
        "Spinners"
      ]
    },
    {
      "name": "fan-table",
      "selector": "fas fa-fan-table",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "fan-table",
      "selector": "far fa-fan-table",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "fan-table",
      "selector": "fal fa-fan-table",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "fan-table",
      "selector": "fad fa-fan-table",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "fantasy-flight-games",
      "selector": "fab fa-fantasy-flight-games",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "farm",
      "selector": "fas fa-farm",
      "categories": [
        "Solid",
        "Buildings"
      ]
    },
    {
      "name": "farm",
      "selector": "far fa-farm",
      "categories": [
        "Regular",
        "Buildings"
      ]
    },
    {
      "name": "farm",
      "selector": "fal fa-farm",
      "categories": [
        "Light",
        "Buildings"
      ]
    },
    {
      "name": "farm",
      "selector": "fad fa-farm",
      "categories": [
        "Duotone",
        "Buildings"
      ]
    },
    {
      "name": "fast-backward",
      "selector": "fas fa-fast-backward",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-backward",
      "selector": "far fa-fast-backward",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-backward",
      "selector": "fal fa-fast-backward",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-backward",
      "selector": "fad fa-fast-backward",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-forward",
      "selector": "fas fa-fast-forward",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-forward",
      "selector": "far fa-fast-forward",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-forward",
      "selector": "fal fa-fast-forward",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "fast-forward",
      "selector": "fad fa-fast-forward",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "faucet",
      "selector": "fas fa-faucet",
      "categories": [
        "Solid",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet",
      "selector": "far fa-faucet",
      "categories": [
        "Regular",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet",
      "selector": "fal fa-faucet",
      "categories": [
        "Light",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet",
      "selector": "fad fa-faucet",
      "categories": [
        "Duotone",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet-drip",
      "selector": "fas fa-faucet-drip",
      "categories": [
        "Solid",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet-drip",
      "selector": "far fa-faucet-drip",
      "categories": [
        "Regular",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet-drip",
      "selector": "fal fa-faucet-drip",
      "categories": [
        "Light",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "faucet-drip",
      "selector": "fad fa-faucet-drip",
      "categories": [
        "Duotone",
        "Camping",
        "Household"
      ]
    },
    {
      "name": "fax",
      "selector": "fas fa-fax",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Objects"
      ]
    },
    {
      "name": "fax",
      "selector": "far fa-fax",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Objects"
      ]
    },
    {
      "name": "fax",
      "selector": "fal fa-fax",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Objects"
      ]
    },
    {
      "name": "fax",
      "selector": "fad fa-fax",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Objects"
      ]
    },
    {
      "name": "feather",
      "selector": "fas fa-feather",
      "categories": [
        "Solid",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather",
      "selector": "far fa-feather",
      "categories": [
        "Regular",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather",
      "selector": "fal fa-feather",
      "categories": [
        "Light",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather",
      "selector": "fad fa-feather",
      "categories": [
        "Duotone",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather-alt",
      "selector": "fas fa-feather-alt",
      "categories": [
        "Solid",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather-alt",
      "selector": "far fa-feather-alt",
      "categories": [
        "Regular",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather-alt",
      "selector": "fal fa-feather-alt",
      "categories": [
        "Light",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "feather-alt",
      "selector": "fad fa-feather-alt",
      "categories": [
        "Duotone",
        "Animals",
        "Objects"
      ]
    },
    {
      "name": "fedex",
      "selector": "fab fa-fedex",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fedora",
      "selector": "fab fa-fedora",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "female",
      "selector": "fas fa-female",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "female",
      "selector": "far fa-female",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "female",
      "selector": "fal fa-female",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "female",
      "selector": "fad fa-female",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "field-hockey",
      "selector": "fas fa-field-hockey",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "field-hockey",
      "selector": "far fa-field-hockey",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "field-hockey",
      "selector": "fal fa-field-hockey",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "field-hockey",
      "selector": "fad fa-field-hockey",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "fighter-jet",
      "selector": "fas fa-fighter-jet",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "fighter-jet",
      "selector": "far fa-fighter-jet",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "fighter-jet",
      "selector": "fal fa-fighter-jet",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "fighter-jet",
      "selector": "fad fa-fighter-jet",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "figma",
      "selector": "fab fa-figma",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "file",
      "selector": "fas fa-file",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file",
      "selector": "far fa-file",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file",
      "selector": "fal fa-file",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file",
      "selector": "fad fa-file",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file-alt",
      "selector": "fas fa-file-alt",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file-alt",
      "selector": "far fa-file-alt",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file-alt",
      "selector": "fal fa-file-alt",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file-alt",
      "selector": "fad fa-file-alt",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Editors",
        "Files",
        "Interfaces",
        "Objects",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "file-archive",
      "selector": "fas fa-file-archive",
      "categories": [
        "Solid",
        "Files"
      ]
    },
    {
      "name": "file-archive",
      "selector": "far fa-file-archive",
      "categories": [
        "Regular",
        "Files"
      ]
    },
    {
      "name": "file-archive",
      "selector": "fal fa-file-archive",
      "categories": [
        "Light",
        "Files"
      ]
    },
    {
      "name": "file-archive",
      "selector": "fad fa-file-archive",
      "categories": [
        "Duotone",
        "Files"
      ]
    },
    {
      "name": "file-audio",
      "selector": "fas fa-file-audio",
      "categories": [
        "Solid",
        "Audio & Video",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-audio",
      "selector": "far fa-file-audio",
      "categories": [
        "Regular",
        "Audio & Video",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-audio",
      "selector": "fal fa-file-audio",
      "categories": [
        "Light",
        "Audio & Video",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-audio",
      "selector": "fad fa-file-audio",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-certificate",
      "selector": "fas fa-file-certificate",
      "categories": [
        "Solid",
        "Education",
        "Security"
      ]
    },
    {
      "name": "file-certificate",
      "selector": "far fa-file-certificate",
      "categories": [
        "Regular",
        "Education",
        "Security"
      ]
    },
    {
      "name": "file-certificate",
      "selector": "fal fa-file-certificate",
      "categories": [
        "Light",
        "Education",
        "Security"
      ]
    },
    {
      "name": "file-certificate",
      "selector": "fad fa-file-certificate",
      "categories": [
        "Duotone",
        "Education",
        "Security"
      ]
    },
    {
      "name": "file-chart-line",
      "selector": "fas fa-file-chart-line",
      "categories": [
        "Solid",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-line",
      "selector": "far fa-file-chart-line",
      "categories": [
        "Regular",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-line",
      "selector": "fal fa-file-chart-line",
      "categories": [
        "Light",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-line",
      "selector": "fad fa-file-chart-line",
      "categories": [
        "Duotone",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-pie",
      "selector": "fas fa-file-chart-pie",
      "categories": [
        "Solid",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-pie",
      "selector": "far fa-file-chart-pie",
      "categories": [
        "Regular",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-pie",
      "selector": "fal fa-file-chart-pie",
      "categories": [
        "Light",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-chart-pie",
      "selector": "fad fa-file-chart-pie",
      "categories": [
        "Duotone",
        "Business",
        "Finance"
      ]
    },
    {
      "name": "file-check",
      "selector": "fas fa-file-check",
      "categories": [
        "Solid",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-check",
      "selector": "far fa-file-check",
      "categories": [
        "Regular",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-check",
      "selector": "fal fa-file-check",
      "categories": [
        "Light",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-check",
      "selector": "fad fa-file-check",
      "categories": [
        "Duotone",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-code",
      "selector": "fas fa-file-code",
      "categories": [
        "Solid",
        "Code",
        "Files"
      ]
    },
    {
      "name": "file-code",
      "selector": "far fa-file-code",
      "categories": [
        "Regular",
        "Code",
        "Files"
      ]
    },
    {
      "name": "file-code",
      "selector": "fal fa-file-code",
      "categories": [
        "Light",
        "Code",
        "Files"
      ]
    },
    {
      "name": "file-code",
      "selector": "fad fa-file-code",
      "categories": [
        "Duotone",
        "Code",
        "Files"
      ]
    },
    {
      "name": "file-contract",
      "selector": "fas fa-file-contract",
      "categories": [
        "Solid",
        "Security"
      ]
    },
    {
      "name": "file-contract",
      "selector": "far fa-file-contract",
      "categories": [
        "Regular",
        "Security"
      ]
    },
    {
      "name": "file-contract",
      "selector": "fal fa-file-contract",
      "categories": [
        "Light",
        "Security"
      ]
    },
    {
      "name": "file-contract",
      "selector": "fad fa-file-contract",
      "categories": [
        "Duotone",
        "Security"
      ]
    },
    {
      "name": "file-csv",
      "selector": "fas fa-file-csv",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "file-csv",
      "selector": "far fa-file-csv",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "file-csv",
      "selector": "fal fa-file-csv",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "file-csv",
      "selector": "fad fa-file-csv",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "file-download",
      "selector": "fas fa-file-download",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "file-download",
      "selector": "far fa-file-download",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "file-download",
      "selector": "fal fa-file-download",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "file-download",
      "selector": "fad fa-file-download",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "file-edit",
      "selector": "fas fa-file-edit",
      "categories": [
        "Solid",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-edit",
      "selector": "far fa-file-edit",
      "categories": [
        "Regular",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-edit",
      "selector": "fal fa-file-edit",
      "categories": [
        "Light",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-edit",
      "selector": "fad fa-file-edit",
      "categories": [
        "Duotone",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-excel",
      "selector": "fas fa-file-excel",
      "categories": [
        "Solid",
        "Files"
      ]
    },
    {
      "name": "file-excel",
      "selector": "far fa-file-excel",
      "categories": [
        "Regular",
        "Files"
      ]
    },
    {
      "name": "file-excel",
      "selector": "fal fa-file-excel",
      "categories": [
        "Light",
        "Files"
      ]
    },
    {
      "name": "file-excel",
      "selector": "fad fa-file-excel",
      "categories": [
        "Duotone",
        "Files"
      ]
    },
    {
      "name": "file-exclamation",
      "selector": "fas fa-file-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-exclamation",
      "selector": "far fa-file-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-exclamation",
      "selector": "fal fa-file-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-exclamation",
      "selector": "fad fa-file-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-export",
      "selector": "fas fa-file-export",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "file-export",
      "selector": "far fa-file-export",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "file-export",
      "selector": "fal fa-file-export",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "file-export",
      "selector": "fad fa-file-export",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "file-image",
      "selector": "fas fa-file-image",
      "categories": [
        "Solid",
        "Files",
        "Images"
      ]
    },
    {
      "name": "file-image",
      "selector": "far fa-file-image",
      "categories": [
        "Regular",
        "Files",
        "Images"
      ]
    },
    {
      "name": "file-image",
      "selector": "fal fa-file-image",
      "categories": [
        "Light",
        "Files",
        "Images"
      ]
    },
    {
      "name": "file-image",
      "selector": "fad fa-file-image",
      "categories": [
        "Duotone",
        "Files",
        "Images"
      ]
    },
    {
      "name": "file-import",
      "selector": "fas fa-file-import",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "file-import",
      "selector": "far fa-file-import",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "file-import",
      "selector": "fal fa-file-import",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "file-import",
      "selector": "fad fa-file-import",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "file-invoice",
      "selector": "fas fa-file-invoice",
      "categories": [
        "Solid",
        "Finance"
      ]
    },
    {
      "name": "file-invoice",
      "selector": "far fa-file-invoice",
      "categories": [
        "Regular",
        "Finance"
      ]
    },
    {
      "name": "file-invoice",
      "selector": "fal fa-file-invoice",
      "categories": [
        "Light",
        "Finance"
      ]
    },
    {
      "name": "file-invoice",
      "selector": "fad fa-file-invoice",
      "categories": [
        "Duotone",
        "Finance"
      ]
    },
    {
      "name": "file-invoice-dollar",
      "selector": "fas fa-file-invoice-dollar",
      "categories": [
        "Solid",
        "Finance"
      ]
    },
    {
      "name": "file-invoice-dollar",
      "selector": "far fa-file-invoice-dollar",
      "categories": [
        "Regular",
        "Finance"
      ]
    },
    {
      "name": "file-invoice-dollar",
      "selector": "fal fa-file-invoice-dollar",
      "categories": [
        "Light",
        "Finance"
      ]
    },
    {
      "name": "file-invoice-dollar",
      "selector": "fad fa-file-invoice-dollar",
      "categories": [
        "Duotone",
        "Finance"
      ]
    },
    {
      "name": "file-medical",
      "selector": "fas fa-file-medical",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "file-medical",
      "selector": "far fa-file-medical",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "file-medical",
      "selector": "fal fa-file-medical",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "file-medical",
      "selector": "fad fa-file-medical",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "file-medical-alt",
      "selector": "fas fa-file-medical-alt",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "file-medical-alt",
      "selector": "far fa-file-medical-alt",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "file-medical-alt",
      "selector": "fal fa-file-medical-alt",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "file-medical-alt",
      "selector": "fad fa-file-medical-alt",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "file-minus",
      "selector": "fas fa-file-minus",
      "categories": [
        "Solid",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-minus",
      "selector": "far fa-file-minus",
      "categories": [
        "Regular",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-minus",
      "selector": "fal fa-file-minus",
      "categories": [
        "Light",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-minus",
      "selector": "fad fa-file-minus",
      "categories": [
        "Duotone",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-music",
      "selector": "fas fa-file-music",
      "categories": [
        "Solid",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-music",
      "selector": "far fa-file-music",
      "categories": [
        "Regular",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-music",
      "selector": "fal fa-file-music",
      "categories": [
        "Light",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-music",
      "selector": "fad fa-file-music",
      "categories": [
        "Duotone",
        "Files",
        "Music"
      ]
    },
    {
      "name": "file-pdf",
      "selector": "fas fa-file-pdf",
      "categories": [
        "Solid",
        "Files"
      ]
    },
    {
      "name": "file-pdf",
      "selector": "far fa-file-pdf",
      "categories": [
        "Regular",
        "Files"
      ]
    },
    {
      "name": "file-pdf",
      "selector": "fal fa-file-pdf",
      "categories": [
        "Light",
        "Files"
      ]
    },
    {
      "name": "file-pdf",
      "selector": "fad fa-file-pdf",
      "categories": [
        "Duotone",
        "Files"
      ]
    },
    {
      "name": "file-plus",
      "selector": "fas fa-file-plus",
      "categories": [
        "Solid",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-plus",
      "selector": "far fa-file-plus",
      "categories": [
        "Regular",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-plus",
      "selector": "fal fa-file-plus",
      "categories": [
        "Light",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-plus",
      "selector": "fad fa-file-plus",
      "categories": [
        "Duotone",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-powerpoint",
      "selector": "fas fa-file-powerpoint",
      "categories": [
        "Solid",
        "Files"
      ]
    },
    {
      "name": "file-powerpoint",
      "selector": "far fa-file-powerpoint",
      "categories": [
        "Regular",
        "Files"
      ]
    },
    {
      "name": "file-powerpoint",
      "selector": "fal fa-file-powerpoint",
      "categories": [
        "Light",
        "Files"
      ]
    },
    {
      "name": "file-powerpoint",
      "selector": "fad fa-file-powerpoint",
      "categories": [
        "Duotone",
        "Files"
      ]
    },
    {
      "name": "file-prescription",
      "selector": "fas fa-file-prescription",
      "categories": [
        "Solid",
        "Medical",
        "Objects",
        "Pharmacy"
      ]
    },
    {
      "name": "file-prescription",
      "selector": "far fa-file-prescription",
      "categories": [
        "Regular",
        "Medical",
        "Objects",
        "Pharmacy"
      ]
    },
    {
      "name": "file-prescription",
      "selector": "fal fa-file-prescription",
      "categories": [
        "Light",
        "Medical",
        "Objects",
        "Pharmacy"
      ]
    },
    {
      "name": "file-prescription",
      "selector": "fad fa-file-prescription",
      "categories": [
        "Duotone",
        "Medical",
        "Objects",
        "Pharmacy"
      ]
    },
    {
      "name": "file-search",
      "selector": "fas fa-file-search",
      "categories": [
        "Solid",
        "Files",
        "Interfaces"
      ]
    },
    {
      "name": "file-search",
      "selector": "far fa-file-search",
      "categories": [
        "Regular",
        "Files",
        "Interfaces"
      ]
    },
    {
      "name": "file-search",
      "selector": "fal fa-file-search",
      "categories": [
        "Light",
        "Files",
        "Interfaces"
      ]
    },
    {
      "name": "file-search",
      "selector": "fad fa-file-search",
      "categories": [
        "Duotone",
        "Files",
        "Interfaces"
      ]
    },
    {
      "name": "file-signature",
      "selector": "fas fa-file-signature",
      "categories": [
        "Solid",
        "Security"
      ]
    },
    {
      "name": "file-signature",
      "selector": "far fa-file-signature",
      "categories": [
        "Regular",
        "Security"
      ]
    },
    {
      "name": "file-signature",
      "selector": "fal fa-file-signature",
      "categories": [
        "Light",
        "Security"
      ]
    },
    {
      "name": "file-signature",
      "selector": "fad fa-file-signature",
      "categories": [
        "Duotone",
        "Security"
      ]
    },
    {
      "name": "file-spreadsheet",
      "selector": "fas fa-file-spreadsheet",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "file-spreadsheet",
      "selector": "far fa-file-spreadsheet",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "file-spreadsheet",
      "selector": "fal fa-file-spreadsheet",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "file-spreadsheet",
      "selector": "fad fa-file-spreadsheet",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "file-times",
      "selector": "fas fa-file-times",
      "categories": [
        "Solid",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-times",
      "selector": "far fa-file-times",
      "categories": [
        "Regular",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-times",
      "selector": "fal fa-file-times",
      "categories": [
        "Light",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-times",
      "selector": "fad fa-file-times",
      "categories": [
        "Duotone",
        "Files",
        "Status"
      ]
    },
    {
      "name": "file-upload",
      "selector": "fas fa-file-upload",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "file-upload",
      "selector": "far fa-file-upload",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "file-upload",
      "selector": "fal fa-file-upload",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "file-upload",
      "selector": "fad fa-file-upload",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "file-user",
      "selector": "fas fa-file-user",
      "categories": [
        "Solid",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "file-user",
      "selector": "far fa-file-user",
      "categories": [
        "Regular",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "file-user",
      "selector": "fal fa-file-user",
      "categories": [
        "Light",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "file-user",
      "selector": "fad fa-file-user",
      "categories": [
        "Duotone",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "file-video",
      "selector": "fas fa-file-video",
      "categories": [
        "Solid",
        "Audio & Video",
        "Files"
      ]
    },
    {
      "name": "file-video",
      "selector": "far fa-file-video",
      "categories": [
        "Regular",
        "Audio & Video",
        "Files"
      ]
    },
    {
      "name": "file-video",
      "selector": "fal fa-file-video",
      "categories": [
        "Light",
        "Audio & Video",
        "Files"
      ]
    },
    {
      "name": "file-video",
      "selector": "fad fa-file-video",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Files"
      ]
    },
    {
      "name": "file-word",
      "selector": "fas fa-file-word",
      "categories": [
        "Solid",
        "Files"
      ]
    },
    {
      "name": "file-word",
      "selector": "far fa-file-word",
      "categories": [
        "Regular",
        "Files"
      ]
    },
    {
      "name": "file-word",
      "selector": "fal fa-file-word",
      "categories": [
        "Light",
        "Files"
      ]
    },
    {
      "name": "file-word",
      "selector": "fad fa-file-word",
      "categories": [
        "Duotone",
        "Files"
      ]
    },
    {
      "name": "files-medical",
      "selector": "fas fa-files-medical",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "files-medical",
      "selector": "far fa-files-medical",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "files-medical",
      "selector": "fal fa-files-medical",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "files-medical",
      "selector": "fad fa-files-medical",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "fill",
      "selector": "fas fa-fill",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "fill",
      "selector": "far fa-fill",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "fill",
      "selector": "fal fa-fill",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "fill",
      "selector": "fad fa-fill",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "fill-drip",
      "selector": "fas fa-fill-drip",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "fill-drip",
      "selector": "far fa-fill-drip",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "fill-drip",
      "selector": "fal fa-fill-drip",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "fill-drip",
      "selector": "fad fa-fill-drip",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "film",
      "selector": "fas fa-film",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film",
      "selector": "far fa-film",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film",
      "selector": "fal fa-film",
      "categories": [
        "Light",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film",
      "selector": "fad fa-film",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film-alt",
      "selector": "fas fa-film-alt",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film-alt",
      "selector": "far fa-film-alt",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film-alt",
      "selector": "fal fa-film-alt",
      "categories": [
        "Light",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film-alt",
      "selector": "fad fa-film-alt",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images",
        "Objects"
      ]
    },
    {
      "name": "film-canister",
      "selector": "fas fa-film-canister",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "film-canister",
      "selector": "far fa-film-canister",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "film-canister",
      "selector": "fal fa-film-canister",
      "categories": [
        "Light",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "film-canister",
      "selector": "fad fa-film-canister",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images"
      ]
    },
    {
      "name": "filter",
      "selector": "fas fa-filter",
      "categories": [
        "Solid",
        "Code",
        "Interfaces",
        "Science"
      ]
    },
    {
      "name": "filter",
      "selector": "far fa-filter",
      "categories": [
        "Regular",
        "Code",
        "Interfaces",
        "Science"
      ]
    },
    {
      "name": "filter",
      "selector": "fal fa-filter",
      "categories": [
        "Light",
        "Code",
        "Interfaces",
        "Science"
      ]
    },
    {
      "name": "filter",
      "selector": "fad fa-filter",
      "categories": [
        "Duotone",
        "Code",
        "Interfaces",
        "Science"
      ]
    },
    {
      "name": "fingerprint",
      "selector": "fas fa-fingerprint",
      "categories": [
        "Solid",
        "Interfaces",
        "Security"
      ]
    },
    {
      "name": "fingerprint",
      "selector": "far fa-fingerprint",
      "categories": [
        "Regular",
        "Interfaces",
        "Security"
      ]
    },
    {
      "name": "fingerprint",
      "selector": "fal fa-fingerprint",
      "categories": [
        "Light",
        "Interfaces",
        "Security"
      ]
    },
    {
      "name": "fingerprint",
      "selector": "fad fa-fingerprint",
      "categories": [
        "Duotone",
        "Interfaces",
        "Security"
      ]
    },
    {
      "name": "fire",
      "selector": "fas fa-fire",
      "categories": [
        "Solid",
        "Camping",
        "Energy",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire",
      "selector": "far fa-fire",
      "categories": [
        "Regular",
        "Camping",
        "Energy",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire",
      "selector": "fal fa-fire",
      "categories": [
        "Light",
        "Camping",
        "Energy",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire",
      "selector": "fad fa-fire",
      "categories": [
        "Duotone",
        "Camping",
        "Energy",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire-alt",
      "selector": "fas fa-fire-alt",
      "categories": [
        "Solid",
        "Camping",
        "Energy",
        "Fitness",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire-alt",
      "selector": "far fa-fire-alt",
      "categories": [
        "Regular",
        "Camping",
        "Energy",
        "Fitness",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire-alt",
      "selector": "fal fa-fire-alt",
      "categories": [
        "Light",
        "Camping",
        "Energy",
        "Fitness",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire-alt",
      "selector": "fad fa-fire-alt",
      "categories": [
        "Duotone",
        "Camping",
        "Energy",
        "Fitness",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "fire-extinguisher",
      "selector": "fas fa-fire-extinguisher",
      "categories": [
        "Solid",
        "Code",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "fire-extinguisher",
      "selector": "far fa-fire-extinguisher",
      "categories": [
        "Regular",
        "Code",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "fire-extinguisher",
      "selector": "fal fa-fire-extinguisher",
      "categories": [
        "Light",
        "Code",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "fire-extinguisher",
      "selector": "fad fa-fire-extinguisher",
      "categories": [
        "Duotone",
        "Code",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "fire-smoke",
      "selector": "fas fa-fire-smoke",
      "categories": [
        "Solid",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "fire-smoke",
      "selector": "far fa-fire-smoke",
      "categories": [
        "Regular",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "fire-smoke",
      "selector": "fal fa-fire-smoke",
      "categories": [
        "Light",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "fire-smoke",
      "selector": "fad fa-fire-smoke",
      "categories": [
        "Duotone",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "firefox",
      "selector": "fab fa-firefox",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "firefox-browser",
      "selector": "fab fa-firefox-browser",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fireplace",
      "selector": "fas fa-fireplace",
      "categories": [
        "Solid",
        "Holiday",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "fireplace",
      "selector": "far fa-fireplace",
      "categories": [
        "Regular",
        "Holiday",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "fireplace",
      "selector": "fal fa-fireplace",
      "categories": [
        "Light",
        "Holiday",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "fireplace",
      "selector": "fad fa-fireplace",
      "categories": [
        "Duotone",
        "Holiday",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "first-aid",
      "selector": "fas fa-first-aid",
      "categories": [
        "Solid",
        "Camping",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "first-aid",
      "selector": "far fa-first-aid",
      "categories": [
        "Regular",
        "Camping",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "first-aid",
      "selector": "fal fa-first-aid",
      "categories": [
        "Light",
        "Camping",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "first-aid",
      "selector": "fad fa-first-aid",
      "categories": [
        "Duotone",
        "Camping",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "first-order",
      "selector": "fab fa-first-order",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "first-order-alt",
      "selector": "fab fa-first-order-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "firstdraft",
      "selector": "fab fa-firstdraft",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fish",
      "selector": "fas fa-fish",
      "categories": [
        "Solid",
        "Animals",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "fish",
      "selector": "far fa-fish",
      "categories": [
        "Regular",
        "Animals",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "fish",
      "selector": "fal fa-fish",
      "categories": [
        "Light",
        "Animals",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "fish",
      "selector": "fad fa-fish",
      "categories": [
        "Duotone",
        "Animals",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "fish-cooked",
      "selector": "fas fa-fish-cooked",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "fish-cooked",
      "selector": "far fa-fish-cooked",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "fish-cooked",
      "selector": "fal fa-fish-cooked",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "fish-cooked",
      "selector": "fad fa-fish-cooked",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "fist-raised",
      "selector": "fas fa-fist-raised",
      "categories": [
        "Solid",
        "Hands",
        "Political",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "fist-raised",
      "selector": "far fa-fist-raised",
      "categories": [
        "Regular",
        "Hands",
        "Political",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "fist-raised",
      "selector": "fal fa-fist-raised",
      "categories": [
        "Light",
        "Hands",
        "Political",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "fist-raised",
      "selector": "fad fa-fist-raised",
      "categories": [
        "Duotone",
        "Hands",
        "Political",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flag",
      "selector": "fas fa-flag",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag",
      "selector": "far fa-flag",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag",
      "selector": "fal fa-flag",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag",
      "selector": "fad fa-flag",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag-alt",
      "selector": "fas fa-flag-alt",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "flag-alt",
      "selector": "far fa-flag-alt",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "flag-alt",
      "selector": "fal fa-flag-alt",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "flag-alt",
      "selector": "fad fa-flag-alt",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "flag-checkered",
      "selector": "fas fa-flag-checkered",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag-checkered",
      "selector": "far fa-flag-checkered",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag-checkered",
      "selector": "fal fa-flag-checkered",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag-checkered",
      "selector": "fad fa-flag-checkered",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "flag-usa",
      "selector": "fas fa-flag-usa",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "flag-usa",
      "selector": "far fa-flag-usa",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "flag-usa",
      "selector": "fal fa-flag-usa",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "flag-usa",
      "selector": "fad fa-flag-usa",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "flame",
      "selector": "fas fa-flame",
      "categories": [
        "Solid",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flame",
      "selector": "far fa-flame",
      "categories": [
        "Regular",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flame",
      "selector": "fal fa-flame",
      "categories": [
        "Light",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flame",
      "selector": "fad fa-flame",
      "categories": [
        "Duotone",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flashlight",
      "selector": "fas fa-flashlight",
      "categories": [
        "Solid",
        "Camping",
        "Childhood",
        "Household"
      ]
    },
    {
      "name": "flashlight",
      "selector": "far fa-flashlight",
      "categories": [
        "Regular",
        "Camping",
        "Childhood",
        "Household"
      ]
    },
    {
      "name": "flashlight",
      "selector": "fal fa-flashlight",
      "categories": [
        "Light",
        "Camping",
        "Childhood",
        "Household"
      ]
    },
    {
      "name": "flashlight",
      "selector": "fad fa-flashlight",
      "categories": [
        "Duotone",
        "Camping",
        "Childhood",
        "Household"
      ]
    },
    {
      "name": "flask",
      "selector": "fas fa-flask",
      "categories": [
        "Solid",
        "Beverage",
        "Maps",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "flask",
      "selector": "far fa-flask",
      "categories": [
        "Regular",
        "Beverage",
        "Maps",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "flask",
      "selector": "fal fa-flask",
      "categories": [
        "Light",
        "Beverage",
        "Maps",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "flask",
      "selector": "fad fa-flask",
      "categories": [
        "Duotone",
        "Beverage",
        "Maps",
        "Objects",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "flask-poison",
      "selector": "fas fa-flask-poison",
      "categories": [
        "Solid",
        "Beverage",
        "Halloween",
        "Science"
      ]
    },
    {
      "name": "flask-poison",
      "selector": "far fa-flask-poison",
      "categories": [
        "Regular",
        "Beverage",
        "Halloween",
        "Science"
      ]
    },
    {
      "name": "flask-poison",
      "selector": "fal fa-flask-poison",
      "categories": [
        "Light",
        "Beverage",
        "Halloween",
        "Science"
      ]
    },
    {
      "name": "flask-poison",
      "selector": "fad fa-flask-poison",
      "categories": [
        "Duotone",
        "Beverage",
        "Halloween",
        "Science"
      ]
    },
    {
      "name": "flask-potion",
      "selector": "fas fa-flask-potion",
      "categories": [
        "Solid",
        "Beverage",
        "Halloween",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flask-potion",
      "selector": "far fa-flask-potion",
      "categories": [
        "Regular",
        "Beverage",
        "Halloween",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flask-potion",
      "selector": "fal fa-flask-potion",
      "categories": [
        "Light",
        "Beverage",
        "Halloween",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flask-potion",
      "selector": "fad fa-flask-potion",
      "categories": [
        "Duotone",
        "Beverage",
        "Halloween",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "flickr",
      "selector": "fab fa-flickr",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "flipboard",
      "selector": "fab fa-flipboard",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "flower",
      "selector": "fas fa-flower",
      "categories": [
        "Solid",
        "Spring"
      ]
    },
    {
      "name": "flower",
      "selector": "far fa-flower",
      "categories": [
        "Regular",
        "Spring"
      ]
    },
    {
      "name": "flower",
      "selector": "fal fa-flower",
      "categories": [
        "Light",
        "Spring"
      ]
    },
    {
      "name": "flower",
      "selector": "fad fa-flower",
      "categories": [
        "Duotone",
        "Spring"
      ]
    },
    {
      "name": "flower-daffodil",
      "selector": "fas fa-flower-daffodil",
      "categories": [
        "Solid",
        "Spring"
      ]
    },
    {
      "name": "flower-daffodil",
      "selector": "far fa-flower-daffodil",
      "categories": [
        "Regular",
        "Spring"
      ]
    },
    {
      "name": "flower-daffodil",
      "selector": "fal fa-flower-daffodil",
      "categories": [
        "Light",
        "Spring"
      ]
    },
    {
      "name": "flower-daffodil",
      "selector": "fad fa-flower-daffodil",
      "categories": [
        "Duotone",
        "Spring"
      ]
    },
    {
      "name": "flower-tulip",
      "selector": "fas fa-flower-tulip",
      "categories": [
        "Solid",
        "Spring"
      ]
    },
    {
      "name": "flower-tulip",
      "selector": "far fa-flower-tulip",
      "categories": [
        "Regular",
        "Spring"
      ]
    },
    {
      "name": "flower-tulip",
      "selector": "fal fa-flower-tulip",
      "categories": [
        "Light",
        "Spring"
      ]
    },
    {
      "name": "flower-tulip",
      "selector": "fad fa-flower-tulip",
      "categories": [
        "Duotone",
        "Spring"
      ]
    },
    {
      "name": "flushed",
      "selector": "fas fa-flushed",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "flushed",
      "selector": "far fa-flushed",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "flushed",
      "selector": "fal fa-flushed",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "flushed",
      "selector": "fad fa-flushed",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "flute",
      "selector": "fas fa-flute",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "flute",
      "selector": "far fa-flute",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "flute",
      "selector": "fal fa-flute",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "flute",
      "selector": "fad fa-flute",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "flux-capacitor",
      "selector": "fas fa-flux-capacitor",
      "categories": [
        "Solid",
        "Automotive",
        "Date & Time",
        "Science Fiction",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "flux-capacitor",
      "selector": "far fa-flux-capacitor",
      "categories": [
        "Regular",
        "Automotive",
        "Date & Time",
        "Science Fiction",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "flux-capacitor",
      "selector": "fal fa-flux-capacitor",
      "categories": [
        "Light",
        "Automotive",
        "Date & Time",
        "Science Fiction",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "flux-capacitor",
      "selector": "fad fa-flux-capacitor",
      "categories": [
        "Duotone",
        "Automotive",
        "Date & Time",
        "Science Fiction",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "fly",
      "selector": "fab fa-fly",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fog",
      "selector": "fas fa-fog",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "fog",
      "selector": "far fa-fog",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "fog",
      "selector": "fal fa-fog",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "fog",
      "selector": "fad fa-fog",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "folder",
      "selector": "fas fa-folder",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder",
      "selector": "far fa-folder",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder",
      "selector": "fal fa-folder",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder",
      "selector": "fad fa-folder",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Shapes",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder-minus",
      "selector": "fas fa-folder-minus",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "folder-minus",
      "selector": "far fa-folder-minus",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "folder-minus",
      "selector": "fal fa-folder-minus",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "folder-minus",
      "selector": "fad fa-folder-minus",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "folder-open",
      "selector": "fas fa-folder-open",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder-open",
      "selector": "far fa-folder-open",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder-open",
      "selector": "fal fa-folder-open",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder-open",
      "selector": "fad fa-folder-open",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Files",
        "Interfaces",
        "Status",
        "Writing"
      ]
    },
    {
      "name": "folder-plus",
      "selector": "fas fa-folder-plus",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "folder-plus",
      "selector": "far fa-folder-plus",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "folder-plus",
      "selector": "fal fa-folder-plus",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "folder-plus",
      "selector": "fad fa-folder-plus",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "folder-times",
      "selector": "fas fa-folder-times",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "folder-times",
      "selector": "far fa-folder-times",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "folder-times",
      "selector": "fal fa-folder-times",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "folder-times",
      "selector": "fad fa-folder-times",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "folder-tree",
      "selector": "fas fa-folder-tree",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "folder-tree",
      "selector": "far fa-folder-tree",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "folder-tree",
      "selector": "fal fa-folder-tree",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "folder-tree",
      "selector": "fad fa-folder-tree",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "folders",
      "selector": "fas fa-folders",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "folders",
      "selector": "far fa-folders",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "folders",
      "selector": "fal fa-folders",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "folders",
      "selector": "fad fa-folders",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "font",
      "selector": "fas fa-font",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "font",
      "selector": "far fa-font",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "font",
      "selector": "fal fa-font",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "font",
      "selector": "fad fa-font",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "font-awesome",
      "selector": "fab fa-font-awesome",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "font-awesome-alt",
      "selector": "fab fa-font-awesome-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "font-awesome-flag",
      "selector": "fab fa-font-awesome-flag",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "font-case",
      "selector": "fas fa-font-case",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "font-case",
      "selector": "far fa-font-case",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "font-case",
      "selector": "fal fa-font-case",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "font-case",
      "selector": "fad fa-font-case",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "fonticons",
      "selector": "fab fa-fonticons",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fonticons-fi",
      "selector": "fab fa-fonticons-fi",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "football-ball",
      "selector": "fas fa-football-ball",
      "categories": [
        "Solid",
        "Autumn",
        "Sports"
      ]
    },
    {
      "name": "football-ball",
      "selector": "far fa-football-ball",
      "categories": [
        "Regular",
        "Autumn",
        "Sports"
      ]
    },
    {
      "name": "football-ball",
      "selector": "fal fa-football-ball",
      "categories": [
        "Light",
        "Autumn",
        "Sports"
      ]
    },
    {
      "name": "football-ball",
      "selector": "fad fa-football-ball",
      "categories": [
        "Duotone",
        "Autumn",
        "Sports"
      ]
    },
    {
      "name": "football-helmet",
      "selector": "fas fa-football-helmet",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "football-helmet",
      "selector": "far fa-football-helmet",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "football-helmet",
      "selector": "fal fa-football-helmet",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "football-helmet",
      "selector": "fad fa-football-helmet",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "forklift",
      "selector": "fas fa-forklift",
      "categories": [
        "Solid",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "forklift",
      "selector": "far fa-forklift",
      "categories": [
        "Regular",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "forklift",
      "selector": "fal fa-forklift",
      "categories": [
        "Light",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "forklift",
      "selector": "fad fa-forklift",
      "categories": [
        "Duotone",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "fort-awesome",
      "selector": "fab fa-fort-awesome",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fort-awesome-alt",
      "selector": "fab fa-fort-awesome-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "forumbee",
      "selector": "fab fa-forumbee",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "forward",
      "selector": "fas fa-forward",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "forward",
      "selector": "far fa-forward",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "forward",
      "selector": "fal fa-forward",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "forward",
      "selector": "fad fa-forward",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "foursquare",
      "selector": "fab fa-foursquare",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "fragile",
      "selector": "fas fa-fragile",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "fragile",
      "selector": "far fa-fragile",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "fragile",
      "selector": "fal fa-fragile",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "fragile",
      "selector": "fad fa-fragile",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "free-code-camp",
      "selector": "fab fa-free-code-camp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "freebsd",
      "selector": "fab fa-freebsd",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "french-fries",
      "selector": "fas fa-french-fries",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "french-fries",
      "selector": "far fa-french-fries",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "french-fries",
      "selector": "fal fa-french-fries",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "french-fries",
      "selector": "fad fa-french-fries",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "frog",
      "selector": "fas fa-frog",
      "categories": [
        "Solid",
        "Animals",
        "Camping",
        "Maritime",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "frog",
      "selector": "far fa-frog",
      "categories": [
        "Regular",
        "Animals",
        "Camping",
        "Maritime",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "frog",
      "selector": "fal fa-frog",
      "categories": [
        "Light",
        "Animals",
        "Camping",
        "Maritime",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "frog",
      "selector": "fad fa-frog",
      "categories": [
        "Duotone",
        "Animals",
        "Camping",
        "Maritime",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "frosty-head",
      "selector": "fas fa-frosty-head",
      "categories": [
        "Solid",
        "Winter"
      ]
    },
    {
      "name": "frosty-head",
      "selector": "far fa-frosty-head",
      "categories": [
        "Regular",
        "Winter"
      ]
    },
    {
      "name": "frosty-head",
      "selector": "fal fa-frosty-head",
      "categories": [
        "Light",
        "Winter"
      ]
    },
    {
      "name": "frosty-head",
      "selector": "fad fa-frosty-head",
      "categories": [
        "Duotone",
        "Winter"
      ]
    },
    {
      "name": "frown",
      "selector": "fas fa-frown",
      "categories": [
        "Solid",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "frown",
      "selector": "far fa-frown",
      "categories": [
        "Regular",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "frown",
      "selector": "fal fa-frown",
      "categories": [
        "Light",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "frown",
      "selector": "fad fa-frown",
      "categories": [
        "Duotone",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "frown-open",
      "selector": "fas fa-frown-open",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "frown-open",
      "selector": "far fa-frown-open",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "frown-open",
      "selector": "fal fa-frown-open",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "frown-open",
      "selector": "fad fa-frown-open",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "fulcrum",
      "selector": "fab fa-fulcrum",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "function",
      "selector": "fas fa-function",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "function",
      "selector": "far fa-function",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "function",
      "selector": "fal fa-function",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "function",
      "selector": "fad fa-function",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "funnel-dollar",
      "selector": "fas fa-funnel-dollar",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "funnel-dollar",
      "selector": "far fa-funnel-dollar",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "funnel-dollar",
      "selector": "fal fa-funnel-dollar",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "funnel-dollar",
      "selector": "fad fa-funnel-dollar",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "futbol",
      "selector": "fas fa-futbol",
      "categories": [
        "Solid",
        "Objects",
        "Sports"
      ]
    },
    {
      "name": "futbol",
      "selector": "far fa-futbol",
      "categories": [
        "Regular",
        "Objects",
        "Sports"
      ]
    },
    {
      "name": "futbol",
      "selector": "fal fa-futbol",
      "categories": [
        "Light",
        "Objects",
        "Sports"
      ]
    },
    {
      "name": "futbol",
      "selector": "fad fa-futbol",
      "categories": [
        "Duotone",
        "Objects",
        "Sports"
      ]
    },
    {
      "name": "galactic-republic",
      "selector": "fab fa-galactic-republic",
      "categories": [
        "Brands",
        "Science Fiction"
      ]
    },
    {
      "name": "galactic-senate",
      "selector": "fab fa-galactic-senate",
      "categories": [
        "Brands",
        "Science Fiction"
      ]
    },
    {
      "name": "galaxy",
      "selector": "fas fa-galaxy",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "galaxy",
      "selector": "far fa-galaxy",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "galaxy",
      "selector": "fal fa-galaxy",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "galaxy",
      "selector": "fad fa-galaxy",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "game-board",
      "selector": "fas fa-game-board",
      "categories": [
        "Solid",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board",
      "selector": "far fa-game-board",
      "categories": [
        "Regular",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board",
      "selector": "fal fa-game-board",
      "categories": [
        "Light",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board",
      "selector": "fad fa-game-board",
      "categories": [
        "Duotone",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board-alt",
      "selector": "fas fa-game-board-alt",
      "categories": [
        "Solid",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board-alt",
      "selector": "far fa-game-board-alt",
      "categories": [
        "Regular",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board-alt",
      "selector": "fal fa-game-board-alt",
      "categories": [
        "Light",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-board-alt",
      "selector": "fad fa-game-board-alt",
      "categories": [
        "Duotone",
        "Chess",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "game-console-handheld",
      "selector": "fas fa-game-console-handheld",
      "categories": [
        "Solid",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "game-console-handheld",
      "selector": "far fa-game-console-handheld",
      "categories": [
        "Regular",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "game-console-handheld",
      "selector": "fal fa-game-console-handheld",
      "categories": [
        "Light",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "game-console-handheld",
      "selector": "fad fa-game-console-handheld",
      "categories": [
        "Duotone",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "gamepad",
      "selector": "fas fa-gamepad",
      "categories": [
        "Solid",
        "Childhood",
        "Games",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gamepad",
      "selector": "far fa-gamepad",
      "categories": [
        "Regular",
        "Childhood",
        "Games",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gamepad",
      "selector": "fal fa-gamepad",
      "categories": [
        "Light",
        "Childhood",
        "Games",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gamepad",
      "selector": "fad fa-gamepad",
      "categories": [
        "Duotone",
        "Childhood",
        "Games",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gamepad-alt",
      "selector": "fas fa-gamepad-alt",
      "categories": [
        "Solid",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "gamepad-alt",
      "selector": "far fa-gamepad-alt",
      "categories": [
        "Regular",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "gamepad-alt",
      "selector": "fal fa-gamepad-alt",
      "categories": [
        "Light",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "gamepad-alt",
      "selector": "fad fa-gamepad-alt",
      "categories": [
        "Duotone",
        "Childhood",
        "Games"
      ]
    },
    {
      "name": "garage",
      "selector": "fas fa-garage",
      "categories": [
        "Solid",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage",
      "selector": "far fa-garage",
      "categories": [
        "Regular",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage",
      "selector": "fal fa-garage",
      "categories": [
        "Light",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage",
      "selector": "fad fa-garage",
      "categories": [
        "Duotone",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-car",
      "selector": "fas fa-garage-car",
      "categories": [
        "Solid",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-car",
      "selector": "far fa-garage-car",
      "categories": [
        "Regular",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-car",
      "selector": "fal fa-garage-car",
      "categories": [
        "Light",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-car",
      "selector": "fad fa-garage-car",
      "categories": [
        "Duotone",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-open",
      "selector": "fas fa-garage-open",
      "categories": [
        "Solid",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-open",
      "selector": "far fa-garage-open",
      "categories": [
        "Regular",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-open",
      "selector": "fal fa-garage-open",
      "categories": [
        "Light",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "garage-open",
      "selector": "fad fa-garage-open",
      "categories": [
        "Duotone",
        "Automotive",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "gas-pump",
      "selector": "fas fa-gas-pump",
      "categories": [
        "Solid",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump",
      "selector": "far fa-gas-pump",
      "categories": [
        "Regular",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump",
      "selector": "fal fa-gas-pump",
      "categories": [
        "Light",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump",
      "selector": "fad fa-gas-pump",
      "categories": [
        "Duotone",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump-slash",
      "selector": "fas fa-gas-pump-slash",
      "categories": [
        "Solid",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump-slash",
      "selector": "far fa-gas-pump-slash",
      "categories": [
        "Regular",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump-slash",
      "selector": "fal fa-gas-pump-slash",
      "categories": [
        "Light",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gas-pump-slash",
      "selector": "fad fa-gas-pump-slash",
      "categories": [
        "Duotone",
        "Automotive",
        "Energy",
        "Status"
      ]
    },
    {
      "name": "gavel",
      "selector": "fas fa-gavel",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gavel",
      "selector": "far fa-gavel",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gavel",
      "selector": "fal fa-gavel",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gavel",
      "selector": "fad fa-gavel",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gem",
      "selector": "fas fa-gem",
      "categories": [
        "Solid",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "gem",
      "selector": "far fa-gem",
      "categories": [
        "Regular",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "gem",
      "selector": "fal fa-gem",
      "categories": [
        "Light",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "gem",
      "selector": "fad fa-gem",
      "categories": [
        "Duotone",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "genderless",
      "selector": "fas fa-genderless",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "genderless",
      "selector": "far fa-genderless",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "genderless",
      "selector": "fal fa-genderless",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "genderless",
      "selector": "fad fa-genderless",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "get-pocket",
      "selector": "fab fa-get-pocket",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "gg",
      "selector": "fab fa-gg",
      "categories": [
        "Brands",
        "Currency"
      ]
    },
    {
      "name": "gg-circle",
      "selector": "fab fa-gg-circle",
      "categories": [
        "Brands",
        "Currency"
      ]
    },
    {
      "name": "ghost",
      "selector": "fas fa-ghost",
      "categories": [
        "Solid",
        "Games",
        "Halloween"
      ]
    },
    {
      "name": "ghost",
      "selector": "far fa-ghost",
      "categories": [
        "Regular",
        "Games",
        "Halloween"
      ]
    },
    {
      "name": "ghost",
      "selector": "fal fa-ghost",
      "categories": [
        "Light",
        "Games",
        "Halloween"
      ]
    },
    {
      "name": "ghost",
      "selector": "fad fa-ghost",
      "categories": [
        "Duotone",
        "Games",
        "Halloween"
      ]
    },
    {
      "name": "gift",
      "selector": "fas fa-gift",
      "categories": [
        "Solid",
        "Charity",
        "Holiday",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "gift",
      "selector": "far fa-gift",
      "categories": [
        "Regular",
        "Charity",
        "Holiday",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "gift",
      "selector": "fal fa-gift",
      "categories": [
        "Light",
        "Charity",
        "Holiday",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "gift",
      "selector": "fad fa-gift",
      "categories": [
        "Duotone",
        "Charity",
        "Holiday",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "gift-card",
      "selector": "fas fa-gift-card",
      "categories": [
        "Solid",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "gift-card",
      "selector": "far fa-gift-card",
      "categories": [
        "Regular",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "gift-card",
      "selector": "fal fa-gift-card",
      "categories": [
        "Light",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "gift-card",
      "selector": "fad fa-gift-card",
      "categories": [
        "Duotone",
        "Marketing",
        "Shopping"
      ]
    },
    {
      "name": "gifts",
      "selector": "fas fa-gifts",
      "categories": [
        "Solid",
        "Holiday",
        "Objects",
        "Shopping"
      ]
    },
    {
      "name": "gifts",
      "selector": "far fa-gifts",
      "categories": [
        "Regular",
        "Holiday",
        "Objects",
        "Shopping"
      ]
    },
    {
      "name": "gifts",
      "selector": "fal fa-gifts",
      "categories": [
        "Light",
        "Holiday",
        "Objects",
        "Shopping"
      ]
    },
    {
      "name": "gifts",
      "selector": "fad fa-gifts",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects",
        "Shopping"
      ]
    },
    {
      "name": "gingerbread-man",
      "selector": "fas fa-gingerbread-man",
      "categories": [
        "Solid",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "gingerbread-man",
      "selector": "far fa-gingerbread-man",
      "categories": [
        "Regular",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "gingerbread-man",
      "selector": "fal fa-gingerbread-man",
      "categories": [
        "Light",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "gingerbread-man",
      "selector": "fad fa-gingerbread-man",
      "categories": [
        "Duotone",
        "Food",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "git",
      "selector": "fab fa-git",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "git-alt",
      "selector": "fab fa-git-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "git-square",
      "selector": "fab fa-git-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "github",
      "selector": "fab fa-github",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "github-alt",
      "selector": "fab fa-github-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "github-square",
      "selector": "fab fa-github-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "gitkraken",
      "selector": "fab fa-gitkraken",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "gitlab",
      "selector": "fab fa-gitlab",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "gitter",
      "selector": "fab fa-gitter",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "glass",
      "selector": "fas fa-glass",
      "categories": [
        "Solid",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass",
      "selector": "far fa-glass",
      "categories": [
        "Regular",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass",
      "selector": "fal fa-glass",
      "categories": [
        "Light",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass",
      "selector": "fad fa-glass",
      "categories": [
        "Duotone",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass-champagne",
      "selector": "fas fa-glass-champagne",
      "categories": [
        "Solid",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-champagne",
      "selector": "far fa-glass-champagne",
      "categories": [
        "Regular",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-champagne",
      "selector": "fal fa-glass-champagne",
      "categories": [
        "Light",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-champagne",
      "selector": "fad fa-glass-champagne",
      "categories": [
        "Duotone",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-cheers",
      "selector": "fas fa-glass-cheers",
      "categories": [
        "Solid",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-cheers",
      "selector": "far fa-glass-cheers",
      "categories": [
        "Regular",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-cheers",
      "selector": "fal fa-glass-cheers",
      "categories": [
        "Light",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-cheers",
      "selector": "fad fa-glass-cheers",
      "categories": [
        "Duotone",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "glass-citrus",
      "selector": "fas fa-glass-citrus",
      "categories": [
        "Solid",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass-citrus",
      "selector": "far fa-glass-citrus",
      "categories": [
        "Regular",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass-citrus",
      "selector": "fal fa-glass-citrus",
      "categories": [
        "Light",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass-citrus",
      "selector": "fad fa-glass-citrus",
      "categories": [
        "Duotone",
        "Beverage",
        "Summer"
      ]
    },
    {
      "name": "glass-martini",
      "selector": "fas fa-glass-martini",
      "categories": [
        "Solid",
        "Beverage",
        "Hotel",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "glass-martini",
      "selector": "far fa-glass-martini",
      "categories": [
        "Regular",
        "Beverage",
        "Hotel",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "glass-martini",
      "selector": "fal fa-glass-martini",
      "categories": [
        "Light",
        "Beverage",
        "Hotel",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "glass-martini",
      "selector": "fad fa-glass-martini",
      "categories": [
        "Duotone",
        "Beverage",
        "Hotel",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "glass-martini-alt",
      "selector": "fas fa-glass-martini-alt",
      "categories": [
        "Solid",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "glass-martini-alt",
      "selector": "far fa-glass-martini-alt",
      "categories": [
        "Regular",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "glass-martini-alt",
      "selector": "fal fa-glass-martini-alt",
      "categories": [
        "Light",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "glass-martini-alt",
      "selector": "fad fa-glass-martini-alt",
      "categories": [
        "Duotone",
        "Beverage",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "glass-whiskey",
      "selector": "fas fa-glass-whiskey",
      "categories": [
        "Solid",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey",
      "selector": "far fa-glass-whiskey",
      "categories": [
        "Regular",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey",
      "selector": "fal fa-glass-whiskey",
      "categories": [
        "Light",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey",
      "selector": "fad fa-glass-whiskey",
      "categories": [
        "Duotone",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey-rocks",
      "selector": "fas fa-glass-whiskey-rocks",
      "categories": [
        "Solid",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey-rocks",
      "selector": "far fa-glass-whiskey-rocks",
      "categories": [
        "Regular",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey-rocks",
      "selector": "fal fa-glass-whiskey-rocks",
      "categories": [
        "Light",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glass-whiskey-rocks",
      "selector": "fad fa-glass-whiskey-rocks",
      "categories": [
        "Duotone",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "glasses",
      "selector": "fas fa-glasses",
      "categories": [
        "Solid",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "glasses",
      "selector": "far fa-glasses",
      "categories": [
        "Regular",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "glasses",
      "selector": "fal fa-glasses",
      "categories": [
        "Light",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "glasses",
      "selector": "fad fa-glasses",
      "categories": [
        "Duotone",
        "Business",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "glasses-alt",
      "selector": "fas fa-glasses-alt",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "glasses-alt",
      "selector": "far fa-glasses-alt",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "glasses-alt",
      "selector": "fal fa-glasses-alt",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "glasses-alt",
      "selector": "fad fa-glasses-alt",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "glide",
      "selector": "fab fa-glide",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "glide-g",
      "selector": "fab fa-glide-g",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "globe",
      "selector": "fas fa-globe",
      "categories": [
        "Solid",
        "Business",
        "Charity",
        "Maps",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "globe",
      "selector": "far fa-globe",
      "categories": [
        "Regular",
        "Business",
        "Charity",
        "Maps",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "globe",
      "selector": "fal fa-globe",
      "categories": [
        "Light",
        "Business",
        "Charity",
        "Maps",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "globe",
      "selector": "fad fa-globe",
      "categories": [
        "Duotone",
        "Business",
        "Charity",
        "Maps",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "globe-africa",
      "selector": "fas fa-globe-africa",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "globe-africa",
      "selector": "far fa-globe-africa",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "globe-africa",
      "selector": "fal fa-globe-africa",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "globe-africa",
      "selector": "fad fa-globe-africa",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "globe-americas",
      "selector": "fas fa-globe-americas",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "globe-americas",
      "selector": "far fa-globe-americas",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "globe-americas",
      "selector": "fal fa-globe-americas",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "globe-americas",
      "selector": "fad fa-globe-americas",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "globe-asia",
      "selector": "fas fa-globe-asia",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "globe-asia",
      "selector": "far fa-globe-asia",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "globe-asia",
      "selector": "fal fa-globe-asia",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "globe-asia",
      "selector": "fad fa-globe-asia",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "globe-europe",
      "selector": "fas fa-globe-europe",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "globe-europe",
      "selector": "far fa-globe-europe",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "globe-europe",
      "selector": "fal fa-globe-europe",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "globe-europe",
      "selector": "fad fa-globe-europe",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "globe-snow",
      "selector": "fas fa-globe-snow",
      "categories": [
        "Solid",
        "Childhood",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "globe-snow",
      "selector": "far fa-globe-snow",
      "categories": [
        "Regular",
        "Childhood",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "globe-snow",
      "selector": "fal fa-globe-snow",
      "categories": [
        "Light",
        "Childhood",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "globe-snow",
      "selector": "fad fa-globe-snow",
      "categories": [
        "Duotone",
        "Childhood",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "globe-stand",
      "selector": "fas fa-globe-stand",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "globe-stand",
      "selector": "far fa-globe-stand",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "globe-stand",
      "selector": "fal fa-globe-stand",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "globe-stand",
      "selector": "fad fa-globe-stand",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "gofore",
      "selector": "fab fa-gofore",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "golf-ball",
      "selector": "fas fa-golf-ball",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "golf-ball",
      "selector": "far fa-golf-ball",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "golf-ball",
      "selector": "fal fa-golf-ball",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "golf-ball",
      "selector": "fad fa-golf-ball",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "golf-club",
      "selector": "fas fa-golf-club",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "golf-club",
      "selector": "far fa-golf-club",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "golf-club",
      "selector": "fal fa-golf-club",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "golf-club",
      "selector": "fad fa-golf-club",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "goodreads",
      "selector": "fab fa-goodreads",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "goodreads-g",
      "selector": "fab fa-goodreads-g",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google",
      "selector": "fab fa-google",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google-drive",
      "selector": "fab fa-google-drive",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google-play",
      "selector": "fab fa-google-play",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google-plus",
      "selector": "fab fa-google-plus",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google-plus-g",
      "selector": "fab fa-google-plus-g",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google-plus-square",
      "selector": "fab fa-google-plus-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "google-wallet",
      "selector": "fab fa-google-wallet",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "gopuram",
      "selector": "fas fa-gopuram",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "gopuram",
      "selector": "far fa-gopuram",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "gopuram",
      "selector": "fal fa-gopuram",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "gopuram",
      "selector": "fad fa-gopuram",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "graduation-cap",
      "selector": "fas fa-graduation-cap",
      "categories": [
        "Solid",
        "Clothing",
        "Education",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "graduation-cap",
      "selector": "far fa-graduation-cap",
      "categories": [
        "Regular",
        "Clothing",
        "Education",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "graduation-cap",
      "selector": "fal fa-graduation-cap",
      "categories": [
        "Light",
        "Clothing",
        "Education",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "graduation-cap",
      "selector": "fad fa-graduation-cap",
      "categories": [
        "Duotone",
        "Clothing",
        "Education",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "gramophone",
      "selector": "fas fa-gramophone",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "gramophone",
      "selector": "far fa-gramophone",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "gramophone",
      "selector": "fal fa-gramophone",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "gramophone",
      "selector": "fad fa-gramophone",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "gratipay",
      "selector": "fab fa-gratipay",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "grav",
      "selector": "fab fa-grav",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "greater-than",
      "selector": "fas fa-greater-than",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than",
      "selector": "far fa-greater-than",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than",
      "selector": "fal fa-greater-than",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than",
      "selector": "fad fa-greater-than",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than-equal",
      "selector": "fas fa-greater-than-equal",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than-equal",
      "selector": "far fa-greater-than-equal",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than-equal",
      "selector": "fal fa-greater-than-equal",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "greater-than-equal",
      "selector": "fad fa-greater-than-equal",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "grimace",
      "selector": "fas fa-grimace",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grimace",
      "selector": "far fa-grimace",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grimace",
      "selector": "fal fa-grimace",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grimace",
      "selector": "fad fa-grimace",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin",
      "selector": "fas fa-grin",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin",
      "selector": "far fa-grin",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin",
      "selector": "fal fa-grin",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin",
      "selector": "fad fa-grin",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-alt",
      "selector": "fas fa-grin-alt",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-alt",
      "selector": "far fa-grin-alt",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-alt",
      "selector": "fal fa-grin-alt",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-alt",
      "selector": "fad fa-grin-alt",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam",
      "selector": "fas fa-grin-beam",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam",
      "selector": "far fa-grin-beam",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam",
      "selector": "fal fa-grin-beam",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam",
      "selector": "fad fa-grin-beam",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam-sweat",
      "selector": "fas fa-grin-beam-sweat",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam-sweat",
      "selector": "far fa-grin-beam-sweat",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam-sweat",
      "selector": "fal fa-grin-beam-sweat",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-beam-sweat",
      "selector": "fad fa-grin-beam-sweat",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-hearts",
      "selector": "fas fa-grin-hearts",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-hearts",
      "selector": "far fa-grin-hearts",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-hearts",
      "selector": "fal fa-grin-hearts",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-hearts",
      "selector": "fad fa-grin-hearts",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint",
      "selector": "fas fa-grin-squint",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint",
      "selector": "far fa-grin-squint",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint",
      "selector": "fal fa-grin-squint",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint",
      "selector": "fad fa-grin-squint",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint-tears",
      "selector": "fas fa-grin-squint-tears",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint-tears",
      "selector": "far fa-grin-squint-tears",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint-tears",
      "selector": "fal fa-grin-squint-tears",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-squint-tears",
      "selector": "fad fa-grin-squint-tears",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-stars",
      "selector": "fas fa-grin-stars",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-stars",
      "selector": "far fa-grin-stars",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-stars",
      "selector": "fal fa-grin-stars",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-stars",
      "selector": "fad fa-grin-stars",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-tears",
      "selector": "fas fa-grin-tears",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-tears",
      "selector": "far fa-grin-tears",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-tears",
      "selector": "fal fa-grin-tears",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-tears",
      "selector": "fad fa-grin-tears",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue",
      "selector": "fas fa-grin-tongue",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue",
      "selector": "far fa-grin-tongue",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue",
      "selector": "fal fa-grin-tongue",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue",
      "selector": "fad fa-grin-tongue",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-squint",
      "selector": "fas fa-grin-tongue-squint",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-squint",
      "selector": "far fa-grin-tongue-squint",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-squint",
      "selector": "fal fa-grin-tongue-squint",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-squint",
      "selector": "fad fa-grin-tongue-squint",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-wink",
      "selector": "fas fa-grin-tongue-wink",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-wink",
      "selector": "far fa-grin-tongue-wink",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-wink",
      "selector": "fal fa-grin-tongue-wink",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-tongue-wink",
      "selector": "fad fa-grin-tongue-wink",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grin-wink",
      "selector": "fas fa-grin-wink",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "grin-wink",
      "selector": "far fa-grin-wink",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "grin-wink",
      "selector": "fal fa-grin-wink",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "grin-wink",
      "selector": "fad fa-grin-wink",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "grip-horizontal",
      "selector": "fas fa-grip-horizontal",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "grip-horizontal",
      "selector": "far fa-grip-horizontal",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "grip-horizontal",
      "selector": "fal fa-grip-horizontal",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "grip-horizontal",
      "selector": "fad fa-grip-horizontal",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines",
      "selector": "fas fa-grip-lines",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines",
      "selector": "far fa-grip-lines",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines",
      "selector": "fal fa-grip-lines",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines",
      "selector": "fad fa-grip-lines",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines-vertical",
      "selector": "fas fa-grip-lines-vertical",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines-vertical",
      "selector": "far fa-grip-lines-vertical",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines-vertical",
      "selector": "fal fa-grip-lines-vertical",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "grip-lines-vertical",
      "selector": "fad fa-grip-lines-vertical",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "grip-vertical",
      "selector": "fas fa-grip-vertical",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "grip-vertical",
      "selector": "far fa-grip-vertical",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "grip-vertical",
      "selector": "fal fa-grip-vertical",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "grip-vertical",
      "selector": "fad fa-grip-vertical",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "gripfire",
      "selector": "fab fa-gripfire",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "grunt",
      "selector": "fab fa-grunt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "guitar",
      "selector": "fas fa-guitar",
      "categories": [
        "Solid",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "guitar",
      "selector": "far fa-guitar",
      "categories": [
        "Regular",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "guitar",
      "selector": "fal fa-guitar",
      "categories": [
        "Light",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "guitar",
      "selector": "fad fa-guitar",
      "categories": [
        "Duotone",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "guitar-electric",
      "selector": "fas fa-guitar-electric",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "guitar-electric",
      "selector": "far fa-guitar-electric",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "guitar-electric",
      "selector": "fal fa-guitar-electric",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "guitar-electric",
      "selector": "fad fa-guitar-electric",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "guitars",
      "selector": "fas fa-guitars",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "guitars",
      "selector": "far fa-guitars",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "guitars",
      "selector": "fal fa-guitars",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "guitars",
      "selector": "fad fa-guitars",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "gulp",
      "selector": "fab fa-gulp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "h-square",
      "selector": "fas fa-h-square",
      "categories": [
        "Solid",
        "Health",
        "Maps"
      ]
    },
    {
      "name": "h-square",
      "selector": "far fa-h-square",
      "categories": [
        "Regular",
        "Health",
        "Maps"
      ]
    },
    {
      "name": "h-square",
      "selector": "fal fa-h-square",
      "categories": [
        "Light",
        "Health",
        "Maps"
      ]
    },
    {
      "name": "h-square",
      "selector": "fad fa-h-square",
      "categories": [
        "Duotone",
        "Health",
        "Maps"
      ]
    },
    {
      "name": "h1",
      "selector": "fas fa-h1",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "h1",
      "selector": "far fa-h1",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "h1",
      "selector": "fal fa-h1",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "h1",
      "selector": "fad fa-h1",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "h2",
      "selector": "fas fa-h2",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "h2",
      "selector": "far fa-h2",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "h2",
      "selector": "fal fa-h2",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "h2",
      "selector": "fad fa-h2",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "h3",
      "selector": "fas fa-h3",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "h3",
      "selector": "far fa-h3",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "h3",
      "selector": "fal fa-h3",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "h3",
      "selector": "fad fa-h3",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "h4",
      "selector": "fas fa-h4",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "h4",
      "selector": "far fa-h4",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "h4",
      "selector": "fal fa-h4",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "h4",
      "selector": "fad fa-h4",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "hacker-news",
      "selector": "fab fa-hacker-news",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hacker-news-square",
      "selector": "fab fa-hacker-news-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hackerrank",
      "selector": "fab fa-hackerrank",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hamburger",
      "selector": "fas fa-hamburger",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "hamburger",
      "selector": "far fa-hamburger",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "hamburger",
      "selector": "fal fa-hamburger",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "hamburger",
      "selector": "fad fa-hamburger",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "hammer",
      "selector": "fas fa-hammer",
      "categories": [
        "Solid",
        "Construction"
      ]
    },
    {
      "name": "hammer",
      "selector": "far fa-hammer",
      "categories": [
        "Regular",
        "Construction"
      ]
    },
    {
      "name": "hammer",
      "selector": "fal fa-hammer",
      "categories": [
        "Light",
        "Construction"
      ]
    },
    {
      "name": "hammer",
      "selector": "fad fa-hammer",
      "categories": [
        "Duotone",
        "Construction"
      ]
    },
    {
      "name": "hammer-war",
      "selector": "fas fa-hammer-war",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hammer-war",
      "selector": "far fa-hammer-war",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hammer-war",
      "selector": "fal fa-hammer-war",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hammer-war",
      "selector": "fad fa-hammer-war",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hamsa",
      "selector": "fas fa-hamsa",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "hamsa",
      "selector": "far fa-hamsa",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "hamsa",
      "selector": "fal fa-hamsa",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "hamsa",
      "selector": "fad fa-hamsa",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "hand-heart",
      "selector": "fas fa-hand-heart",
      "categories": [
        "Solid",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-heart",
      "selector": "far fa-hand-heart",
      "categories": [
        "Regular",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-heart",
      "selector": "fal fa-hand-heart",
      "categories": [
        "Light",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-heart",
      "selector": "fad fa-hand-heart",
      "categories": [
        "Duotone",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding",
      "selector": "fas fa-hand-holding",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-holding",
      "selector": "far fa-hand-holding",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-holding",
      "selector": "fal fa-hand-holding",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-holding",
      "selector": "fad fa-hand-holding",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-box",
      "selector": "fas fa-hand-holding-box",
      "categories": [
        "Solid",
        "Hands",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "hand-holding-box",
      "selector": "far fa-hand-holding-box",
      "categories": [
        "Regular",
        "Hands",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "hand-holding-box",
      "selector": "fal fa-hand-holding-box",
      "categories": [
        "Light",
        "Hands",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "hand-holding-box",
      "selector": "fad fa-hand-holding-box",
      "categories": [
        "Duotone",
        "Hands",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "hand-holding-heart",
      "selector": "fas fa-hand-holding-heart",
      "categories": [
        "Solid",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-heart",
      "selector": "far fa-hand-holding-heart",
      "categories": [
        "Regular",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-heart",
      "selector": "fal fa-hand-holding-heart",
      "categories": [
        "Light",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-heart",
      "selector": "fad fa-hand-holding-heart",
      "categories": [
        "Duotone",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-magic",
      "selector": "fas fa-hand-holding-magic",
      "categories": [
        "Solid",
        "Hands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hand-holding-magic",
      "selector": "far fa-hand-holding-magic",
      "categories": [
        "Regular",
        "Hands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hand-holding-magic",
      "selector": "fal fa-hand-holding-magic",
      "categories": [
        "Light",
        "Hands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hand-holding-magic",
      "selector": "fad fa-hand-holding-magic",
      "categories": [
        "Duotone",
        "Hands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hand-holding-seedling",
      "selector": "fas fa-hand-holding-seedling",
      "categories": [
        "Solid",
        "Charity",
        "Hands",
        "Spring"
      ]
    },
    {
      "name": "hand-holding-seedling",
      "selector": "far fa-hand-holding-seedling",
      "categories": [
        "Regular",
        "Charity",
        "Hands",
        "Spring"
      ]
    },
    {
      "name": "hand-holding-seedling",
      "selector": "fal fa-hand-holding-seedling",
      "categories": [
        "Light",
        "Charity",
        "Hands",
        "Spring"
      ]
    },
    {
      "name": "hand-holding-seedling",
      "selector": "fad fa-hand-holding-seedling",
      "categories": [
        "Duotone",
        "Charity",
        "Hands",
        "Spring"
      ]
    },
    {
      "name": "hand-holding-usd",
      "selector": "fas fa-hand-holding-usd",
      "categories": [
        "Solid",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-usd",
      "selector": "far fa-hand-holding-usd",
      "categories": [
        "Regular",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-usd",
      "selector": "fal fa-hand-holding-usd",
      "categories": [
        "Light",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-usd",
      "selector": "fad fa-hand-holding-usd",
      "categories": [
        "Duotone",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-water",
      "selector": "fas fa-hand-holding-water",
      "categories": [
        "Solid",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-water",
      "selector": "far fa-hand-holding-water",
      "categories": [
        "Regular",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-water",
      "selector": "fal fa-hand-holding-water",
      "categories": [
        "Light",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-holding-water",
      "selector": "fad fa-hand-holding-water",
      "categories": [
        "Duotone",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hand-lizard",
      "selector": "fas fa-hand-lizard",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-lizard",
      "selector": "far fa-hand-lizard",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-lizard",
      "selector": "fal fa-hand-lizard",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-lizard",
      "selector": "fad fa-hand-lizard",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-middle-finger",
      "selector": "fas fa-hand-middle-finger",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-middle-finger",
      "selector": "far fa-hand-middle-finger",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-middle-finger",
      "selector": "fal fa-hand-middle-finger",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-middle-finger",
      "selector": "fad fa-hand-middle-finger",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-paper",
      "selector": "fas fa-hand-paper",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-paper",
      "selector": "far fa-hand-paper",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-paper",
      "selector": "fal fa-hand-paper",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-paper",
      "selector": "fad fa-hand-paper",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-peace",
      "selector": "fas fa-hand-peace",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-peace",
      "selector": "far fa-hand-peace",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-peace",
      "selector": "fal fa-hand-peace",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-peace",
      "selector": "fad fa-hand-peace",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-point-down",
      "selector": "fas fa-hand-point-down",
      "categories": [
        "Solid",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-down",
      "selector": "far fa-hand-point-down",
      "categories": [
        "Regular",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-down",
      "selector": "fal fa-hand-point-down",
      "categories": [
        "Light",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-down",
      "selector": "fad fa-hand-point-down",
      "categories": [
        "Duotone",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-left",
      "selector": "fas fa-hand-point-left",
      "categories": [
        "Solid",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-left",
      "selector": "far fa-hand-point-left",
      "categories": [
        "Regular",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-left",
      "selector": "fal fa-hand-point-left",
      "categories": [
        "Light",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-left",
      "selector": "fad fa-hand-point-left",
      "categories": [
        "Duotone",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-right",
      "selector": "fas fa-hand-point-right",
      "categories": [
        "Solid",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-right",
      "selector": "far fa-hand-point-right",
      "categories": [
        "Regular",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-right",
      "selector": "fal fa-hand-point-right",
      "categories": [
        "Light",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-right",
      "selector": "fad fa-hand-point-right",
      "categories": [
        "Duotone",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-up",
      "selector": "fas fa-hand-point-up",
      "categories": [
        "Solid",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-up",
      "selector": "far fa-hand-point-up",
      "categories": [
        "Regular",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-up",
      "selector": "fal fa-hand-point-up",
      "categories": [
        "Light",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-point-up",
      "selector": "fad fa-hand-point-up",
      "categories": [
        "Duotone",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-pointer",
      "selector": "fas fa-hand-pointer",
      "categories": [
        "Solid",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-pointer",
      "selector": "far fa-hand-pointer",
      "categories": [
        "Regular",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-pointer",
      "selector": "fal fa-hand-pointer",
      "categories": [
        "Light",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-pointer",
      "selector": "fad fa-hand-pointer",
      "categories": [
        "Duotone",
        "Arrows",
        "Hands"
      ]
    },
    {
      "name": "hand-receiving",
      "selector": "fas fa-hand-receiving",
      "categories": [
        "Solid",
        "Hands",
        "Logistics"
      ]
    },
    {
      "name": "hand-receiving",
      "selector": "far fa-hand-receiving",
      "categories": [
        "Regular",
        "Hands",
        "Logistics"
      ]
    },
    {
      "name": "hand-receiving",
      "selector": "fal fa-hand-receiving",
      "categories": [
        "Light",
        "Hands",
        "Logistics"
      ]
    },
    {
      "name": "hand-receiving",
      "selector": "fad fa-hand-receiving",
      "categories": [
        "Duotone",
        "Hands",
        "Logistics"
      ]
    },
    {
      "name": "hand-rock",
      "selector": "fas fa-hand-rock",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-rock",
      "selector": "far fa-hand-rock",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-rock",
      "selector": "fal fa-hand-rock",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-rock",
      "selector": "fad fa-hand-rock",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-scissors",
      "selector": "fas fa-hand-scissors",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hand-scissors",
      "selector": "far fa-hand-scissors",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hand-scissors",
      "selector": "fal fa-hand-scissors",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hand-scissors",
      "selector": "fad fa-hand-scissors",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hand-spock",
      "selector": "fas fa-hand-spock",
      "categories": [
        "Solid",
        "Hands",
        "Science Fiction"
      ]
    },
    {
      "name": "hand-spock",
      "selector": "far fa-hand-spock",
      "categories": [
        "Regular",
        "Hands",
        "Science Fiction"
      ]
    },
    {
      "name": "hand-spock",
      "selector": "fal fa-hand-spock",
      "categories": [
        "Light",
        "Hands",
        "Science Fiction"
      ]
    },
    {
      "name": "hand-spock",
      "selector": "fad fa-hand-spock",
      "categories": [
        "Duotone",
        "Hands",
        "Science Fiction"
      ]
    },
    {
      "name": "hands",
      "selector": "fas fa-hands",
      "categories": [
        "Solid",
        "Hands"
      ]
    },
    {
      "name": "hands",
      "selector": "far fa-hands",
      "categories": [
        "Regular",
        "Hands"
      ]
    },
    {
      "name": "hands",
      "selector": "fal fa-hands",
      "categories": [
        "Light",
        "Hands"
      ]
    },
    {
      "name": "hands",
      "selector": "fad fa-hands",
      "categories": [
        "Duotone",
        "Hands"
      ]
    },
    {
      "name": "hands-heart",
      "selector": "fas fa-hands-heart",
      "categories": [
        "Solid",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-heart",
      "selector": "far fa-hands-heart",
      "categories": [
        "Regular",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-heart",
      "selector": "fal fa-hands-heart",
      "categories": [
        "Light",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-heart",
      "selector": "fad fa-hands-heart",
      "categories": [
        "Duotone",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-helping",
      "selector": "fas fa-hands-helping",
      "categories": [
        "Solid",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-helping",
      "selector": "far fa-hands-helping",
      "categories": [
        "Regular",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-helping",
      "selector": "fal fa-hands-helping",
      "categories": [
        "Light",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-helping",
      "selector": "fad fa-hands-helping",
      "categories": [
        "Duotone",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hands-usd",
      "selector": "fas fa-hands-usd",
      "categories": [
        "Solid",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hands-usd",
      "selector": "far fa-hands-usd",
      "categories": [
        "Regular",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hands-usd",
      "selector": "fal fa-hands-usd",
      "categories": [
        "Light",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "hands-usd",
      "selector": "fad fa-hands-usd",
      "categories": [
        "Duotone",
        "Charity",
        "Finance",
        "Hands"
      ]
    },
    {
      "name": "handshake",
      "selector": "fas fa-handshake",
      "categories": [
        "Solid",
        "Charity",
        "Hands",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "handshake",
      "selector": "far fa-handshake",
      "categories": [
        "Regular",
        "Charity",
        "Hands",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "handshake",
      "selector": "fal fa-handshake",
      "categories": [
        "Light",
        "Charity",
        "Hands",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "handshake",
      "selector": "fad fa-handshake",
      "categories": [
        "Duotone",
        "Charity",
        "Hands",
        "Payments & Shopping",
        "Political"
      ]
    },
    {
      "name": "handshake-alt",
      "selector": "fas fa-handshake-alt",
      "categories": [
        "Solid",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "handshake-alt",
      "selector": "far fa-handshake-alt",
      "categories": [
        "Regular",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "handshake-alt",
      "selector": "fal fa-handshake-alt",
      "categories": [
        "Light",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "handshake-alt",
      "selector": "fad fa-handshake-alt",
      "categories": [
        "Duotone",
        "Charity",
        "Hands"
      ]
    },
    {
      "name": "hanukiah",
      "selector": "fas fa-hanukiah",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "hanukiah",
      "selector": "far fa-hanukiah",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "hanukiah",
      "selector": "fal fa-hanukiah",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "hanukiah",
      "selector": "fad fa-hanukiah",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "hard-hat",
      "selector": "fas fa-hard-hat",
      "categories": [
        "Solid",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "hard-hat",
      "selector": "far fa-hard-hat",
      "categories": [
        "Regular",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "hard-hat",
      "selector": "fal fa-hard-hat",
      "categories": [
        "Light",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "hard-hat",
      "selector": "fad fa-hard-hat",
      "categories": [
        "Duotone",
        "Construction",
        "Logistics"
      ]
    },
    {
      "name": "hashtag",
      "selector": "fas fa-hashtag",
      "categories": [
        "Solid",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "hashtag",
      "selector": "far fa-hashtag",
      "categories": [
        "Regular",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "hashtag",
      "selector": "fal fa-hashtag",
      "categories": [
        "Light",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "hashtag",
      "selector": "fad fa-hashtag",
      "categories": [
        "Duotone",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "hat-chef",
      "selector": "fas fa-hat-chef",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "hat-chef",
      "selector": "far fa-hat-chef",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "hat-chef",
      "selector": "fal fa-hat-chef",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "hat-chef",
      "selector": "fad fa-hat-chef",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "hat-cowboy",
      "selector": "fas fa-hat-cowboy",
      "categories": [
        "Solid",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy",
      "selector": "far fa-hat-cowboy",
      "categories": [
        "Regular",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy",
      "selector": "fal fa-hat-cowboy",
      "categories": [
        "Light",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy",
      "selector": "fad fa-hat-cowboy",
      "categories": [
        "Duotone",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy-side",
      "selector": "fas fa-hat-cowboy-side",
      "categories": [
        "Solid",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy-side",
      "selector": "far fa-hat-cowboy-side",
      "categories": [
        "Regular",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy-side",
      "selector": "fal fa-hat-cowboy-side",
      "categories": [
        "Light",
        "Clothing"
      ]
    },
    {
      "name": "hat-cowboy-side",
      "selector": "fad fa-hat-cowboy-side",
      "categories": [
        "Duotone",
        "Clothing"
      ]
    },
    {
      "name": "hat-santa",
      "selector": "fas fa-hat-santa",
      "categories": [
        "Solid",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "hat-santa",
      "selector": "far fa-hat-santa",
      "categories": [
        "Regular",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "hat-santa",
      "selector": "fal fa-hat-santa",
      "categories": [
        "Light",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "hat-santa",
      "selector": "fad fa-hat-santa",
      "categories": [
        "Duotone",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "hat-winter",
      "selector": "fas fa-hat-winter",
      "categories": [
        "Solid",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "hat-winter",
      "selector": "far fa-hat-winter",
      "categories": [
        "Regular",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "hat-winter",
      "selector": "fal fa-hat-winter",
      "categories": [
        "Light",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "hat-winter",
      "selector": "fad fa-hat-winter",
      "categories": [
        "Duotone",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "hat-witch",
      "selector": "fas fa-hat-witch",
      "categories": [
        "Solid",
        "Clothing",
        "Halloween"
      ]
    },
    {
      "name": "hat-witch",
      "selector": "far fa-hat-witch",
      "categories": [
        "Regular",
        "Clothing",
        "Halloween"
      ]
    },
    {
      "name": "hat-witch",
      "selector": "fal fa-hat-witch",
      "categories": [
        "Light",
        "Clothing",
        "Halloween"
      ]
    },
    {
      "name": "hat-witch",
      "selector": "fad fa-hat-witch",
      "categories": [
        "Duotone",
        "Clothing",
        "Halloween"
      ]
    },
    {
      "name": "hat-wizard",
      "selector": "fas fa-hat-wizard",
      "categories": [
        "Solid",
        "Clothing",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hat-wizard",
      "selector": "far fa-hat-wizard",
      "categories": [
        "Regular",
        "Clothing",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hat-wizard",
      "selector": "fal fa-hat-wizard",
      "categories": [
        "Light",
        "Clothing",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hat-wizard",
      "selector": "fad fa-hat-wizard",
      "categories": [
        "Duotone",
        "Clothing",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hdd",
      "selector": "fas fa-hdd",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "hdd",
      "selector": "far fa-hdd",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "hdd",
      "selector": "fal fa-hdd",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "hdd",
      "selector": "fad fa-hdd",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "head-side",
      "selector": "fas fa-head-side",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "head-side",
      "selector": "far fa-head-side",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "head-side",
      "selector": "fal fa-head-side",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "head-side",
      "selector": "fad fa-head-side",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "head-side-brain",
      "selector": "fas fa-head-side-brain",
      "categories": [
        "Solid",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "head-side-brain",
      "selector": "far fa-head-side-brain",
      "categories": [
        "Regular",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "head-side-brain",
      "selector": "fal fa-head-side-brain",
      "categories": [
        "Light",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "head-side-brain",
      "selector": "fad fa-head-side-brain",
      "categories": [
        "Duotone",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "head-side-headphones",
      "selector": "fas fa-head-side-headphones",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "head-side-headphones",
      "selector": "far fa-head-side-headphones",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "head-side-headphones",
      "selector": "fal fa-head-side-headphones",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "head-side-headphones",
      "selector": "fad fa-head-side-headphones",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "head-side-medical",
      "selector": "fas fa-head-side-medical",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "head-side-medical",
      "selector": "far fa-head-side-medical",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "head-side-medical",
      "selector": "fal fa-head-side-medical",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "head-side-medical",
      "selector": "fad fa-head-side-medical",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "head-vr",
      "selector": "fas fa-head-vr",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "head-vr",
      "selector": "far fa-head-vr",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "head-vr",
      "selector": "fal fa-head-vr",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "head-vr",
      "selector": "fad fa-head-vr",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "heading",
      "selector": "fas fa-heading",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "heading",
      "selector": "far fa-heading",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "heading",
      "selector": "fal fa-heading",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "heading",
      "selector": "fad fa-heading",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "headphones",
      "selector": "fas fa-headphones",
      "categories": [
        "Solid",
        "Audio & Video",
        "Computers",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones",
      "selector": "far fa-headphones",
      "categories": [
        "Regular",
        "Audio & Video",
        "Computers",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones",
      "selector": "fal fa-headphones",
      "categories": [
        "Light",
        "Audio & Video",
        "Computers",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones",
      "selector": "fad fa-headphones",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Computers",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones-alt",
      "selector": "fas fa-headphones-alt",
      "categories": [
        "Solid",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones-alt",
      "selector": "far fa-headphones-alt",
      "categories": [
        "Regular",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones-alt",
      "selector": "fal fa-headphones-alt",
      "categories": [
        "Light",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headphones-alt",
      "selector": "fad fa-headphones-alt",
      "categories": [
        "Duotone",
        "Music",
        "Objects"
      ]
    },
    {
      "name": "headset",
      "selector": "fas fa-headset",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "headset",
      "selector": "far fa-headset",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "headset",
      "selector": "fal fa-headset",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "headset",
      "selector": "fad fa-headset",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "heart",
      "selector": "fas fa-heart",
      "categories": [
        "Solid",
        "Charity",
        "Fitness",
        "Games",
        "Health",
        "Interfaces",
        "Maps",
        "Medical",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social"
      ]
    },
    {
      "name": "heart",
      "selector": "far fa-heart",
      "categories": [
        "Regular",
        "Charity",
        "Fitness",
        "Games",
        "Health",
        "Interfaces",
        "Maps",
        "Medical",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social"
      ]
    },
    {
      "name": "heart",
      "selector": "fal fa-heart",
      "categories": [
        "Light",
        "Charity",
        "Fitness",
        "Games",
        "Health",
        "Interfaces",
        "Maps",
        "Medical",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social"
      ]
    },
    {
      "name": "heart",
      "selector": "fad fa-heart",
      "categories": [
        "Duotone",
        "Charity",
        "Fitness",
        "Games",
        "Health",
        "Interfaces",
        "Maps",
        "Medical",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social"
      ]
    },
    {
      "name": "heart-broken",
      "selector": "fas fa-heart-broken",
      "categories": [
        "Solid",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "heart-broken",
      "selector": "far fa-heart-broken",
      "categories": [
        "Regular",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "heart-broken",
      "selector": "fal fa-heart-broken",
      "categories": [
        "Light",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "heart-broken",
      "selector": "fad fa-heart-broken",
      "categories": [
        "Duotone",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "heart-circle",
      "selector": "fas fa-heart-circle",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "heart-circle",
      "selector": "far fa-heart-circle",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "heart-circle",
      "selector": "fal fa-heart-circle",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "heart-circle",
      "selector": "fad fa-heart-circle",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "heart-rate",
      "selector": "fas fa-heart-rate",
      "categories": [
        "Solid",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "heart-rate",
      "selector": "far fa-heart-rate",
      "categories": [
        "Regular",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "heart-rate",
      "selector": "fal fa-heart-rate",
      "categories": [
        "Light",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "heart-rate",
      "selector": "fad fa-heart-rate",
      "categories": [
        "Duotone",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "heart-square",
      "selector": "fas fa-heart-square",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "heart-square",
      "selector": "far fa-heart-square",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "heart-square",
      "selector": "fal fa-heart-square",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "heart-square",
      "selector": "fad fa-heart-square",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "heartbeat",
      "selector": "fas fa-heartbeat",
      "categories": [
        "Solid",
        "Fitness",
        "Health",
        "Maps",
        "Medical"
      ]
    },
    {
      "name": "heartbeat",
      "selector": "far fa-heartbeat",
      "categories": [
        "Regular",
        "Fitness",
        "Health",
        "Maps",
        "Medical"
      ]
    },
    {
      "name": "heartbeat",
      "selector": "fal fa-heartbeat",
      "categories": [
        "Light",
        "Fitness",
        "Health",
        "Maps",
        "Medical"
      ]
    },
    {
      "name": "heartbeat",
      "selector": "fad fa-heartbeat",
      "categories": [
        "Duotone",
        "Fitness",
        "Health",
        "Maps",
        "Medical"
      ]
    },
    {
      "name": "heat",
      "selector": "fas fa-heat",
      "categories": [
        "Solid",
        "Energy",
        "Hotel",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "heat",
      "selector": "far fa-heat",
      "categories": [
        "Regular",
        "Energy",
        "Hotel",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "heat",
      "selector": "fal fa-heat",
      "categories": [
        "Light",
        "Energy",
        "Hotel",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "heat",
      "selector": "fad fa-heat",
      "categories": [
        "Duotone",
        "Energy",
        "Hotel",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "helicopter",
      "selector": "fas fa-helicopter",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "helicopter",
      "selector": "far fa-helicopter",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "helicopter",
      "selector": "fal fa-helicopter",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "helicopter",
      "selector": "fad fa-helicopter",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "helmet-battle",
      "selector": "fas fa-helmet-battle",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "helmet-battle",
      "selector": "far fa-helmet-battle",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "helmet-battle",
      "selector": "fal fa-helmet-battle",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "helmet-battle",
      "selector": "fad fa-helmet-battle",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hexagon",
      "selector": "fas fa-hexagon",
      "categories": [
        "Solid",
        "Shapes"
      ]
    },
    {
      "name": "hexagon",
      "selector": "far fa-hexagon",
      "categories": [
        "Regular",
        "Shapes"
      ]
    },
    {
      "name": "hexagon",
      "selector": "fal fa-hexagon",
      "categories": [
        "Light",
        "Shapes"
      ]
    },
    {
      "name": "hexagon",
      "selector": "fad fa-hexagon",
      "categories": [
        "Duotone",
        "Shapes"
      ]
    },
    {
      "name": "highlighter",
      "selector": "fas fa-highlighter",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "highlighter",
      "selector": "far fa-highlighter",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "highlighter",
      "selector": "fal fa-highlighter",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "highlighter",
      "selector": "fad fa-highlighter",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "hiking",
      "selector": "fas fa-hiking",
      "categories": [
        "Solid",
        "Autumn",
        "Camping",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "hiking",
      "selector": "far fa-hiking",
      "categories": [
        "Regular",
        "Autumn",
        "Camping",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "hiking",
      "selector": "fal fa-hiking",
      "categories": [
        "Light",
        "Autumn",
        "Camping",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "hiking",
      "selector": "fad fa-hiking",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "hippo",
      "selector": "fas fa-hippo",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "hippo",
      "selector": "far fa-hippo",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "hippo",
      "selector": "fal fa-hippo",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "hippo",
      "selector": "fad fa-hippo",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "hips",
      "selector": "fab fa-hips",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hire-a-helper",
      "selector": "fab fa-hire-a-helper",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "history",
      "selector": "fas fa-history",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces",
        "Pharmacy"
      ]
    },
    {
      "name": "history",
      "selector": "far fa-history",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces",
        "Pharmacy"
      ]
    },
    {
      "name": "history",
      "selector": "fal fa-history",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces",
        "Pharmacy"
      ]
    },
    {
      "name": "history",
      "selector": "fad fa-history",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces",
        "Pharmacy"
      ]
    },
    {
      "name": "hockey-mask",
      "selector": "fas fa-hockey-mask",
      "categories": [
        "Solid",
        "Halloween",
        "Sports"
      ]
    },
    {
      "name": "hockey-mask",
      "selector": "far fa-hockey-mask",
      "categories": [
        "Regular",
        "Halloween",
        "Sports"
      ]
    },
    {
      "name": "hockey-mask",
      "selector": "fal fa-hockey-mask",
      "categories": [
        "Light",
        "Halloween",
        "Sports"
      ]
    },
    {
      "name": "hockey-mask",
      "selector": "fad fa-hockey-mask",
      "categories": [
        "Duotone",
        "Halloween",
        "Sports"
      ]
    },
    {
      "name": "hockey-puck",
      "selector": "fas fa-hockey-puck",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "hockey-puck",
      "selector": "far fa-hockey-puck",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "hockey-puck",
      "selector": "fal fa-hockey-puck",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "hockey-puck",
      "selector": "fad fa-hockey-puck",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "hockey-sticks",
      "selector": "fas fa-hockey-sticks",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "hockey-sticks",
      "selector": "far fa-hockey-sticks",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "hockey-sticks",
      "selector": "fal fa-hockey-sticks",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "hockey-sticks",
      "selector": "fad fa-hockey-sticks",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "holly-berry",
      "selector": "fas fa-holly-berry",
      "categories": [
        "Solid",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "holly-berry",
      "selector": "far fa-holly-berry",
      "categories": [
        "Regular",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "holly-berry",
      "selector": "fal fa-holly-berry",
      "categories": [
        "Light",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "holly-berry",
      "selector": "fad fa-holly-berry",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "home",
      "selector": "fas fa-home",
      "categories": [
        "Solid",
        "Buildings",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "home",
      "selector": "far fa-home",
      "categories": [
        "Regular",
        "Buildings",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "home",
      "selector": "fal fa-home",
      "categories": [
        "Light",
        "Buildings",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "home",
      "selector": "fad fa-home",
      "categories": [
        "Duotone",
        "Buildings",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "home-alt",
      "selector": "fas fa-home-alt",
      "categories": [
        "Solid",
        "Buildings"
      ]
    },
    {
      "name": "home-alt",
      "selector": "far fa-home-alt",
      "categories": [
        "Regular",
        "Buildings"
      ]
    },
    {
      "name": "home-alt",
      "selector": "fal fa-home-alt",
      "categories": [
        "Light",
        "Buildings"
      ]
    },
    {
      "name": "home-alt",
      "selector": "fad fa-home-alt",
      "categories": [
        "Duotone",
        "Buildings"
      ]
    },
    {
      "name": "home-heart",
      "selector": "fas fa-home-heart",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "home-heart",
      "selector": "far fa-home-heart",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "home-heart",
      "selector": "fal fa-home-heart",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "home-heart",
      "selector": "fad fa-home-heart",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "home-lg",
      "selector": "fas fa-home-lg",
      "categories": [
        "Solid",
        "Buildings"
      ]
    },
    {
      "name": "home-lg",
      "selector": "far fa-home-lg",
      "categories": [
        "Regular",
        "Buildings"
      ]
    },
    {
      "name": "home-lg",
      "selector": "fal fa-home-lg",
      "categories": [
        "Light",
        "Buildings"
      ]
    },
    {
      "name": "home-lg",
      "selector": "fad fa-home-lg",
      "categories": [
        "Duotone",
        "Buildings"
      ]
    },
    {
      "name": "home-lg-alt",
      "selector": "fas fa-home-lg-alt",
      "categories": [
        "Solid",
        "Buildings"
      ]
    },
    {
      "name": "home-lg-alt",
      "selector": "far fa-home-lg-alt",
      "categories": [
        "Regular",
        "Buildings"
      ]
    },
    {
      "name": "home-lg-alt",
      "selector": "fal fa-home-lg-alt",
      "categories": [
        "Light",
        "Buildings"
      ]
    },
    {
      "name": "home-lg-alt",
      "selector": "fad fa-home-lg-alt",
      "categories": [
        "Duotone",
        "Buildings"
      ]
    },
    {
      "name": "hood-cloak",
      "selector": "fas fa-hood-cloak",
      "categories": [
        "Solid",
        "Clothing",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hood-cloak",
      "selector": "far fa-hood-cloak",
      "categories": [
        "Regular",
        "Clothing",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hood-cloak",
      "selector": "fal fa-hood-cloak",
      "categories": [
        "Light",
        "Clothing",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hood-cloak",
      "selector": "fad fa-hood-cloak",
      "categories": [
        "Duotone",
        "Clothing",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "hooli",
      "selector": "fab fa-hooli",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "horizontal-rule",
      "selector": "fas fa-horizontal-rule",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "horizontal-rule",
      "selector": "far fa-horizontal-rule",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "horizontal-rule",
      "selector": "fal fa-horizontal-rule",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "horizontal-rule",
      "selector": "fad fa-horizontal-rule",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "hornbill",
      "selector": "fab fa-hornbill",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "horse",
      "selector": "fas fa-horse",
      "categories": [
        "Solid",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "horse",
      "selector": "far fa-horse",
      "categories": [
        "Regular",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "horse",
      "selector": "fal fa-horse",
      "categories": [
        "Light",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "horse",
      "selector": "fad fa-horse",
      "categories": [
        "Duotone",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "horse-head",
      "selector": "fas fa-horse-head",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "horse-head",
      "selector": "far fa-horse-head",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "horse-head",
      "selector": "fal fa-horse-head",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "horse-head",
      "selector": "fad fa-horse-head",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "horse-saddle",
      "selector": "fas fa-horse-saddle",
      "categories": [
        "Solid",
        "Animals",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "horse-saddle",
      "selector": "far fa-horse-saddle",
      "categories": [
        "Regular",
        "Animals",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "horse-saddle",
      "selector": "fal fa-horse-saddle",
      "categories": [
        "Light",
        "Animals",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "horse-saddle",
      "selector": "fad fa-horse-saddle",
      "categories": [
        "Duotone",
        "Animals",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "hospital",
      "selector": "fas fa-hospital",
      "categories": [
        "Solid",
        "Buildings",
        "Health",
        "Maps",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "hospital",
      "selector": "far fa-hospital",
      "categories": [
        "Regular",
        "Buildings",
        "Health",
        "Maps",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "hospital",
      "selector": "fal fa-hospital",
      "categories": [
        "Light",
        "Buildings",
        "Health",
        "Maps",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "hospital",
      "selector": "fad fa-hospital",
      "categories": [
        "Duotone",
        "Buildings",
        "Health",
        "Maps",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "hospital-alt",
      "selector": "fas fa-hospital-alt",
      "categories": [
        "Solid",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-alt",
      "selector": "far fa-hospital-alt",
      "categories": [
        "Regular",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-alt",
      "selector": "fal fa-hospital-alt",
      "categories": [
        "Light",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-alt",
      "selector": "fad fa-hospital-alt",
      "categories": [
        "Duotone",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-symbol",
      "selector": "fas fa-hospital-symbol",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "hospital-symbol",
      "selector": "far fa-hospital-symbol",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "hospital-symbol",
      "selector": "fal fa-hospital-symbol",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "hospital-symbol",
      "selector": "fad fa-hospital-symbol",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "hospital-user",
      "selector": "fas fa-hospital-user",
      "categories": [
        "Solid",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-user",
      "selector": "far fa-hospital-user",
      "categories": [
        "Regular",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-user",
      "selector": "fal fa-hospital-user",
      "categories": [
        "Light",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospital-user",
      "selector": "fad fa-hospital-user",
      "categories": [
        "Duotone",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospitals",
      "selector": "fas fa-hospitals",
      "categories": [
        "Solid",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospitals",
      "selector": "far fa-hospitals",
      "categories": [
        "Regular",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospitals",
      "selector": "fal fa-hospitals",
      "categories": [
        "Light",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hospitals",
      "selector": "fad fa-hospitals",
      "categories": [
        "Duotone",
        "Buildings",
        "Medical"
      ]
    },
    {
      "name": "hot-tub",
      "selector": "fas fa-hot-tub",
      "categories": [
        "Solid",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hot-tub",
      "selector": "far fa-hot-tub",
      "categories": [
        "Regular",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hot-tub",
      "selector": "fal fa-hot-tub",
      "categories": [
        "Light",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hot-tub",
      "selector": "fad fa-hot-tub",
      "categories": [
        "Duotone",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hotdog",
      "selector": "fas fa-hotdog",
      "categories": [
        "Solid",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "hotdog",
      "selector": "far fa-hotdog",
      "categories": [
        "Regular",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "hotdog",
      "selector": "fal fa-hotdog",
      "categories": [
        "Light",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "hotdog",
      "selector": "fad fa-hotdog",
      "categories": [
        "Duotone",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "hotel",
      "selector": "fas fa-hotel",
      "categories": [
        "Solid",
        "Buildings",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hotel",
      "selector": "far fa-hotel",
      "categories": [
        "Regular",
        "Buildings",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hotel",
      "selector": "fal fa-hotel",
      "categories": [
        "Light",
        "Buildings",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hotel",
      "selector": "fad fa-hotel",
      "categories": [
        "Duotone",
        "Buildings",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "hotjar",
      "selector": "fab fa-hotjar",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hourglass",
      "selector": "fas fa-hourglass",
      "categories": [
        "Solid",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "hourglass",
      "selector": "far fa-hourglass",
      "categories": [
        "Regular",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "hourglass",
      "selector": "fal fa-hourglass",
      "categories": [
        "Light",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "hourglass",
      "selector": "fad fa-hourglass",
      "categories": [
        "Duotone",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "hourglass-end",
      "selector": "fas fa-hourglass-end",
      "categories": [
        "Solid",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-end",
      "selector": "far fa-hourglass-end",
      "categories": [
        "Regular",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-end",
      "selector": "fal fa-hourglass-end",
      "categories": [
        "Light",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-end",
      "selector": "fad fa-hourglass-end",
      "categories": [
        "Duotone",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-half",
      "selector": "fas fa-hourglass-half",
      "categories": [
        "Solid",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-half",
      "selector": "far fa-hourglass-half",
      "categories": [
        "Regular",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-half",
      "selector": "fal fa-hourglass-half",
      "categories": [
        "Light",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-half",
      "selector": "fad fa-hourglass-half",
      "categories": [
        "Duotone",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-start",
      "selector": "fas fa-hourglass-start",
      "categories": [
        "Solid",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-start",
      "selector": "far fa-hourglass-start",
      "categories": [
        "Regular",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-start",
      "selector": "fal fa-hourglass-start",
      "categories": [
        "Light",
        "Date & Time"
      ]
    },
    {
      "name": "hourglass-start",
      "selector": "fad fa-hourglass-start",
      "categories": [
        "Duotone",
        "Date & Time"
      ]
    },
    {
      "name": "house",
      "selector": "fas fa-house",
      "categories": [
        "Solid",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "house",
      "selector": "far fa-house",
      "categories": [
        "Regular",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "house",
      "selector": "fal fa-house",
      "categories": [
        "Light",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "house",
      "selector": "fad fa-house",
      "categories": [
        "Duotone",
        "Buildings",
        "Household"
      ]
    },
    {
      "name": "house-damage",
      "selector": "fas fa-house-damage",
      "categories": [
        "Solid",
        "Buildings"
      ]
    },
    {
      "name": "house-damage",
      "selector": "far fa-house-damage",
      "categories": [
        "Regular",
        "Buildings"
      ]
    },
    {
      "name": "house-damage",
      "selector": "fal fa-house-damage",
      "categories": [
        "Light",
        "Buildings"
      ]
    },
    {
      "name": "house-damage",
      "selector": "fad fa-house-damage",
      "categories": [
        "Duotone",
        "Buildings"
      ]
    },
    {
      "name": "house-day",
      "selector": "fas fa-house-day",
      "categories": [
        "Solid",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-day",
      "selector": "far fa-house-day",
      "categories": [
        "Regular",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-day",
      "selector": "fal fa-house-day",
      "categories": [
        "Light",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-day",
      "selector": "fad fa-house-day",
      "categories": [
        "Duotone",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-flood",
      "selector": "fas fa-house-flood",
      "categories": [
        "Solid",
        "Buildings",
        "Weather"
      ]
    },
    {
      "name": "house-flood",
      "selector": "far fa-house-flood",
      "categories": [
        "Regular",
        "Buildings",
        "Weather"
      ]
    },
    {
      "name": "house-flood",
      "selector": "fal fa-house-flood",
      "categories": [
        "Light",
        "Buildings",
        "Weather"
      ]
    },
    {
      "name": "house-flood",
      "selector": "fad fa-house-flood",
      "categories": [
        "Duotone",
        "Buildings",
        "Weather"
      ]
    },
    {
      "name": "house-leave",
      "selector": "fas fa-house-leave",
      "categories": [
        "Solid",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-leave",
      "selector": "far fa-house-leave",
      "categories": [
        "Regular",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-leave",
      "selector": "fal fa-house-leave",
      "categories": [
        "Light",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-leave",
      "selector": "fad fa-house-leave",
      "categories": [
        "Duotone",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-night",
      "selector": "fas fa-house-night",
      "categories": [
        "Solid",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-night",
      "selector": "far fa-house-night",
      "categories": [
        "Regular",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-night",
      "selector": "fal fa-house-night",
      "categories": [
        "Light",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-night",
      "selector": "fad fa-house-night",
      "categories": [
        "Duotone",
        "Buildings",
        "Date & Time",
        "Energy",
        "Household"
      ]
    },
    {
      "name": "house-return",
      "selector": "fas fa-house-return",
      "categories": [
        "Solid",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-return",
      "selector": "far fa-house-return",
      "categories": [
        "Regular",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-return",
      "selector": "fal fa-house-return",
      "categories": [
        "Light",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-return",
      "selector": "fad fa-house-return",
      "categories": [
        "Duotone",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "house-signal",
      "selector": "fas fa-house-signal",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "house-signal",
      "selector": "far fa-house-signal",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "house-signal",
      "selector": "fal fa-house-signal",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "house-signal",
      "selector": "fad fa-house-signal",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "houzz",
      "selector": "fab fa-houzz",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hryvnia",
      "selector": "fas fa-hryvnia",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "hryvnia",
      "selector": "far fa-hryvnia",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "hryvnia",
      "selector": "fal fa-hryvnia",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "hryvnia",
      "selector": "fad fa-hryvnia",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "html5",
      "selector": "fab fa-html5",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "hubspot",
      "selector": "fab fa-hubspot",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "humidity",
      "selector": "fas fa-humidity",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "humidity",
      "selector": "far fa-humidity",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "humidity",
      "selector": "fal fa-humidity",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "humidity",
      "selector": "fad fa-humidity",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "hurricane",
      "selector": "fas fa-hurricane",
      "categories": [
        "Solid",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "hurricane",
      "selector": "far fa-hurricane",
      "categories": [
        "Regular",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "hurricane",
      "selector": "fal fa-hurricane",
      "categories": [
        "Light",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "hurricane",
      "selector": "fad fa-hurricane",
      "categories": [
        "Duotone",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "i-cursor",
      "selector": "fas fa-i-cursor",
      "categories": [
        "Solid",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "i-cursor",
      "selector": "far fa-i-cursor",
      "categories": [
        "Regular",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "i-cursor",
      "selector": "fal fa-i-cursor",
      "categories": [
        "Light",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "i-cursor",
      "selector": "fad fa-i-cursor",
      "categories": [
        "Duotone",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "ice-cream",
      "selector": "fas fa-ice-cream",
      "categories": [
        "Solid",
        "Childhood",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "ice-cream",
      "selector": "far fa-ice-cream",
      "categories": [
        "Regular",
        "Childhood",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "ice-cream",
      "selector": "fal fa-ice-cream",
      "categories": [
        "Light",
        "Childhood",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "ice-cream",
      "selector": "fad fa-ice-cream",
      "categories": [
        "Duotone",
        "Childhood",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "ice-skate",
      "selector": "fas fa-ice-skate",
      "categories": [
        "Solid",
        "Clothing",
        "Objects",
        "Sports",
        "Winter"
      ]
    },
    {
      "name": "ice-skate",
      "selector": "far fa-ice-skate",
      "categories": [
        "Regular",
        "Clothing",
        "Objects",
        "Sports",
        "Winter"
      ]
    },
    {
      "name": "ice-skate",
      "selector": "fal fa-ice-skate",
      "categories": [
        "Light",
        "Clothing",
        "Objects",
        "Sports",
        "Winter"
      ]
    },
    {
      "name": "ice-skate",
      "selector": "fad fa-ice-skate",
      "categories": [
        "Duotone",
        "Clothing",
        "Objects",
        "Sports",
        "Winter"
      ]
    },
    {
      "name": "icicles",
      "selector": "fas fa-icicles",
      "categories": [
        "Solid",
        "Winter"
      ]
    },
    {
      "name": "icicles",
      "selector": "far fa-icicles",
      "categories": [
        "Regular",
        "Winter"
      ]
    },
    {
      "name": "icicles",
      "selector": "fal fa-icicles",
      "categories": [
        "Light",
        "Winter"
      ]
    },
    {
      "name": "icicles",
      "selector": "fad fa-icicles",
      "categories": [
        "Duotone",
        "Winter"
      ]
    },
    {
      "name": "icons",
      "selector": "fas fa-icons",
      "categories": [
        "Solid",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons",
      "selector": "far fa-icons",
      "categories": [
        "Regular",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons",
      "selector": "fal fa-icons",
      "categories": [
        "Light",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons",
      "selector": "fad fa-icons",
      "categories": [
        "Duotone",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons-alt",
      "selector": "fas fa-icons-alt",
      "categories": [
        "Solid",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons-alt",
      "selector": "far fa-icons-alt",
      "categories": [
        "Regular",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons-alt",
      "selector": "fal fa-icons-alt",
      "categories": [
        "Light",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "icons-alt",
      "selector": "fad fa-icons-alt",
      "categories": [
        "Duotone",
        "Chat",
        "Design",
        "Editors",
        "Social"
      ]
    },
    {
      "name": "id-badge",
      "selector": "fas fa-id-badge",
      "categories": [
        "Solid",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-badge",
      "selector": "far fa-id-badge",
      "categories": [
        "Regular",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-badge",
      "selector": "fal fa-id-badge",
      "categories": [
        "Light",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-badge",
      "selector": "fad fa-id-badge",
      "categories": [
        "Duotone",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card",
      "selector": "fas fa-id-card",
      "categories": [
        "Solid",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card",
      "selector": "far fa-id-card",
      "categories": [
        "Regular",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card",
      "selector": "fal fa-id-card",
      "categories": [
        "Light",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card",
      "selector": "fad fa-id-card",
      "categories": [
        "Duotone",
        "Images",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card-alt",
      "selector": "fas fa-id-card-alt",
      "categories": [
        "Solid",
        "Medical",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card-alt",
      "selector": "far fa-id-card-alt",
      "categories": [
        "Regular",
        "Medical",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card-alt",
      "selector": "fal fa-id-card-alt",
      "categories": [
        "Light",
        "Medical",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "id-card-alt",
      "selector": "fad fa-id-card-alt",
      "categories": [
        "Duotone",
        "Medical",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "ideal",
      "selector": "fab fa-ideal",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "igloo",
      "selector": "fas fa-igloo",
      "categories": [
        "Solid",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "igloo",
      "selector": "far fa-igloo",
      "categories": [
        "Regular",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "igloo",
      "selector": "fal fa-igloo",
      "categories": [
        "Light",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "igloo",
      "selector": "fad fa-igloo",
      "categories": [
        "Duotone",
        "Buildings",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "image",
      "selector": "fas fa-image",
      "categories": [
        "Solid",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "image",
      "selector": "far fa-image",
      "categories": [
        "Regular",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "image",
      "selector": "fal fa-image",
      "categories": [
        "Light",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "image",
      "selector": "fad fa-image",
      "categories": [
        "Duotone",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "image-polaroid",
      "selector": "fas fa-image-polaroid",
      "categories": [
        "Solid",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "image-polaroid",
      "selector": "far fa-image-polaroid",
      "categories": [
        "Regular",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "image-polaroid",
      "selector": "fal fa-image-polaroid",
      "categories": [
        "Light",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "image-polaroid",
      "selector": "fad fa-image-polaroid",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Design",
        "Images"
      ]
    },
    {
      "name": "images",
      "selector": "fas fa-images",
      "categories": [
        "Solid",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "images",
      "selector": "far fa-images",
      "categories": [
        "Regular",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "images",
      "selector": "fal fa-images",
      "categories": [
        "Light",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "images",
      "selector": "fad fa-images",
      "categories": [
        "Duotone",
        "Images",
        "Maps",
        "Objects",
        "Social"
      ]
    },
    {
      "name": "imdb",
      "selector": "fab fa-imdb",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "inbox",
      "selector": "fas fa-inbox",
      "categories": [
        "Solid",
        "Communication"
      ]
    },
    {
      "name": "inbox",
      "selector": "far fa-inbox",
      "categories": [
        "Regular",
        "Communication"
      ]
    },
    {
      "name": "inbox",
      "selector": "fal fa-inbox",
      "categories": [
        "Light",
        "Communication"
      ]
    },
    {
      "name": "inbox",
      "selector": "fad fa-inbox",
      "categories": [
        "Duotone",
        "Communication"
      ]
    },
    {
      "name": "inbox-in",
      "selector": "fas fa-inbox-in",
      "categories": [
        "Solid",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-in",
      "selector": "far fa-inbox-in",
      "categories": [
        "Regular",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-in",
      "selector": "fal fa-inbox-in",
      "categories": [
        "Light",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-in",
      "selector": "fad fa-inbox-in",
      "categories": [
        "Duotone",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-out",
      "selector": "fas fa-inbox-out",
      "categories": [
        "Solid",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-out",
      "selector": "far fa-inbox-out",
      "categories": [
        "Regular",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-out",
      "selector": "fal fa-inbox-out",
      "categories": [
        "Light",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "inbox-out",
      "selector": "fad fa-inbox-out",
      "categories": [
        "Duotone",
        "Arrows",
        "Communication"
      ]
    },
    {
      "name": "indent",
      "selector": "fas fa-indent",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "indent",
      "selector": "far fa-indent",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "indent",
      "selector": "fal fa-indent",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "indent",
      "selector": "fad fa-indent",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "industry",
      "selector": "fas fa-industry",
      "categories": [
        "Solid",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry",
      "selector": "far fa-industry",
      "categories": [
        "Regular",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry",
      "selector": "fal fa-industry",
      "categories": [
        "Light",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry",
      "selector": "fad fa-industry",
      "categories": [
        "Duotone",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry-alt",
      "selector": "fas fa-industry-alt",
      "categories": [
        "Solid",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry-alt",
      "selector": "far fa-industry-alt",
      "categories": [
        "Regular",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry-alt",
      "selector": "fal fa-industry-alt",
      "categories": [
        "Light",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "industry-alt",
      "selector": "fad fa-industry-alt",
      "categories": [
        "Duotone",
        "Buildings",
        "Business",
        "Energy",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "infinity",
      "selector": "fas fa-infinity",
      "categories": [
        "Solid",
        "Hotel",
        "Mathematics"
      ]
    },
    {
      "name": "infinity",
      "selector": "far fa-infinity",
      "categories": [
        "Regular",
        "Hotel",
        "Mathematics"
      ]
    },
    {
      "name": "infinity",
      "selector": "fal fa-infinity",
      "categories": [
        "Light",
        "Hotel",
        "Mathematics"
      ]
    },
    {
      "name": "infinity",
      "selector": "fad fa-infinity",
      "categories": [
        "Duotone",
        "Hotel",
        "Mathematics"
      ]
    },
    {
      "name": "info",
      "selector": "fas fa-info",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info",
      "selector": "far fa-info",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info",
      "selector": "fal fa-info",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info",
      "selector": "fad fa-info",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-circle",
      "selector": "fas fa-info-circle",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-circle",
      "selector": "far fa-info-circle",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-circle",
      "selector": "fal fa-info-circle",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-circle",
      "selector": "fad fa-info-circle",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-square",
      "selector": "fas fa-info-square",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-square",
      "selector": "far fa-info-square",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-square",
      "selector": "fal fa-info-square",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "info-square",
      "selector": "fad fa-info-square",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "inhaler",
      "selector": "fas fa-inhaler",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "inhaler",
      "selector": "far fa-inhaler",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "inhaler",
      "selector": "fal fa-inhaler",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "inhaler",
      "selector": "fad fa-inhaler",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "instagram",
      "selector": "fab fa-instagram",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "integral",
      "selector": "fas fa-integral",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "integral",
      "selector": "far fa-integral",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "integral",
      "selector": "fal fa-integral",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "integral",
      "selector": "fad fa-integral",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "intercom",
      "selector": "fab fa-intercom",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "internet-explorer",
      "selector": "fab fa-internet-explorer",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "intersection",
      "selector": "fas fa-intersection",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "intersection",
      "selector": "far fa-intersection",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "intersection",
      "selector": "fal fa-intersection",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "intersection",
      "selector": "fad fa-intersection",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "inventory",
      "selector": "fas fa-inventory",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "inventory",
      "selector": "far fa-inventory",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "inventory",
      "selector": "fal fa-inventory",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "inventory",
      "selector": "fad fa-inventory",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "invision",
      "selector": "fab fa-invision",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ioxhost",
      "selector": "fab fa-ioxhost",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "island-tropical",
      "selector": "fas fa-island-tropical",
      "categories": [
        "Solid",
        "Maritime",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "island-tropical",
      "selector": "far fa-island-tropical",
      "categories": [
        "Regular",
        "Maritime",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "island-tropical",
      "selector": "fal fa-island-tropical",
      "categories": [
        "Light",
        "Maritime",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "island-tropical",
      "selector": "fad fa-island-tropical",
      "categories": [
        "Duotone",
        "Maritime",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "italic",
      "selector": "fas fa-italic",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "italic",
      "selector": "far fa-italic",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "italic",
      "selector": "fal fa-italic",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "italic",
      "selector": "fad fa-italic",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "itch-io",
      "selector": "fab fa-itch-io",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "itunes",
      "selector": "fab fa-itunes",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "itunes-note",
      "selector": "fab fa-itunes-note",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "jack-o-lantern",
      "selector": "fas fa-jack-o-lantern",
      "categories": [
        "Solid",
        "Halloween",
        "Objects"
      ]
    },
    {
      "name": "jack-o-lantern",
      "selector": "far fa-jack-o-lantern",
      "categories": [
        "Regular",
        "Halloween",
        "Objects"
      ]
    },
    {
      "name": "jack-o-lantern",
      "selector": "fal fa-jack-o-lantern",
      "categories": [
        "Light",
        "Halloween",
        "Objects"
      ]
    },
    {
      "name": "jack-o-lantern",
      "selector": "fad fa-jack-o-lantern",
      "categories": [
        "Duotone",
        "Halloween",
        "Objects"
      ]
    },
    {
      "name": "java",
      "selector": "fab fa-java",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "jedi",
      "selector": "fas fa-jedi",
      "categories": [
        "Solid",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "jedi",
      "selector": "far fa-jedi",
      "categories": [
        "Regular",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "jedi",
      "selector": "fal fa-jedi",
      "categories": [
        "Light",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "jedi",
      "selector": "fad fa-jedi",
      "categories": [
        "Duotone",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "jedi-order",
      "selector": "fab fa-jedi-order",
      "categories": [
        "Brands",
        "Science Fiction"
      ]
    },
    {
      "name": "jenkins",
      "selector": "fab fa-jenkins",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "jira",
      "selector": "fab fa-jira",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "joget",
      "selector": "fab fa-joget",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "joint",
      "selector": "fas fa-joint",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "joint",
      "selector": "far fa-joint",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "joint",
      "selector": "fal fa-joint",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "joint",
      "selector": "fad fa-joint",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "joomla",
      "selector": "fab fa-joomla",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "journal-whills",
      "selector": "fas fa-journal-whills",
      "categories": [
        "Solid",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "journal-whills",
      "selector": "far fa-journal-whills",
      "categories": [
        "Regular",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "journal-whills",
      "selector": "fal fa-journal-whills",
      "categories": [
        "Light",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "journal-whills",
      "selector": "fad fa-journal-whills",
      "categories": [
        "Duotone",
        "Religion",
        "Science Fiction"
      ]
    },
    {
      "name": "joystick",
      "selector": "fas fa-joystick",
      "categories": [
        "Solid",
        "Games"
      ]
    },
    {
      "name": "joystick",
      "selector": "far fa-joystick",
      "categories": [
        "Regular",
        "Games"
      ]
    },
    {
      "name": "joystick",
      "selector": "fal fa-joystick",
      "categories": [
        "Light",
        "Games"
      ]
    },
    {
      "name": "joystick",
      "selector": "fad fa-joystick",
      "categories": [
        "Duotone",
        "Games"
      ]
    },
    {
      "name": "js",
      "selector": "fab fa-js",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "js-square",
      "selector": "fab fa-js-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "jsfiddle",
      "selector": "fab fa-jsfiddle",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "jug",
      "selector": "fas fa-jug",
      "categories": [
        "Solid",
        "Beverage"
      ]
    },
    {
      "name": "jug",
      "selector": "far fa-jug",
      "categories": [
        "Regular",
        "Beverage"
      ]
    },
    {
      "name": "jug",
      "selector": "fal fa-jug",
      "categories": [
        "Light",
        "Beverage"
      ]
    },
    {
      "name": "jug",
      "selector": "fad fa-jug",
      "categories": [
        "Duotone",
        "Beverage"
      ]
    },
    {
      "name": "kaaba",
      "selector": "fas fa-kaaba",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "kaaba",
      "selector": "far fa-kaaba",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "kaaba",
      "selector": "fal fa-kaaba",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "kaaba",
      "selector": "fad fa-kaaba",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "kaggle",
      "selector": "fab fa-kaggle",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "kazoo",
      "selector": "fas fa-kazoo",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "kazoo",
      "selector": "far fa-kazoo",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "kazoo",
      "selector": "fal fa-kazoo",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "kazoo",
      "selector": "fad fa-kazoo",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "kerning",
      "selector": "fas fa-kerning",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "kerning",
      "selector": "far fa-kerning",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "kerning",
      "selector": "fal fa-kerning",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "kerning",
      "selector": "fad fa-kerning",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "key",
      "selector": "fas fa-key",
      "categories": [
        "Solid",
        "Hotel",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Security"
      ]
    },
    {
      "name": "key",
      "selector": "far fa-key",
      "categories": [
        "Regular",
        "Hotel",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Security"
      ]
    },
    {
      "name": "key",
      "selector": "fal fa-key",
      "categories": [
        "Light",
        "Hotel",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Security"
      ]
    },
    {
      "name": "key",
      "selector": "fad fa-key",
      "categories": [
        "Duotone",
        "Hotel",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Security"
      ]
    },
    {
      "name": "key-skeleton",
      "selector": "fas fa-key-skeleton",
      "categories": [
        "Solid",
        "Halloween",
        "Science",
        "Security"
      ]
    },
    {
      "name": "key-skeleton",
      "selector": "far fa-key-skeleton",
      "categories": [
        "Regular",
        "Halloween",
        "Science",
        "Security"
      ]
    },
    {
      "name": "key-skeleton",
      "selector": "fal fa-key-skeleton",
      "categories": [
        "Light",
        "Halloween",
        "Science",
        "Security"
      ]
    },
    {
      "name": "key-skeleton",
      "selector": "fad fa-key-skeleton",
      "categories": [
        "Duotone",
        "Halloween",
        "Science",
        "Security"
      ]
    },
    {
      "name": "keybase",
      "selector": "fab fa-keybase",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "keyboard",
      "selector": "fas fa-keyboard",
      "categories": [
        "Solid",
        "Code",
        "Computers",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "keyboard",
      "selector": "far fa-keyboard",
      "categories": [
        "Regular",
        "Code",
        "Computers",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "keyboard",
      "selector": "fal fa-keyboard",
      "categories": [
        "Light",
        "Code",
        "Computers",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "keyboard",
      "selector": "fad fa-keyboard",
      "categories": [
        "Duotone",
        "Code",
        "Computers",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "keycdn",
      "selector": "fab fa-keycdn",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "keynote",
      "selector": "fas fa-keynote",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "keynote",
      "selector": "far fa-keynote",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "keynote",
      "selector": "fal fa-keynote",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "keynote",
      "selector": "fad fa-keynote",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "khanda",
      "selector": "fas fa-khanda",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "khanda",
      "selector": "far fa-khanda",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "khanda",
      "selector": "fal fa-khanda",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "khanda",
      "selector": "fad fa-khanda",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "kickstarter",
      "selector": "fab fa-kickstarter",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "kickstarter-k",
      "selector": "fab fa-kickstarter-k",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "kidneys",
      "selector": "fas fa-kidneys",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "kidneys",
      "selector": "far fa-kidneys",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "kidneys",
      "selector": "fal fa-kidneys",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "kidneys",
      "selector": "fad fa-kidneys",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "kiss",
      "selector": "fas fa-kiss",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "kiss",
      "selector": "far fa-kiss",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "kiss",
      "selector": "fal fa-kiss",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "kiss",
      "selector": "fad fa-kiss",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "kiss-beam",
      "selector": "fas fa-kiss-beam",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "kiss-beam",
      "selector": "far fa-kiss-beam",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "kiss-beam",
      "selector": "fal fa-kiss-beam",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "kiss-beam",
      "selector": "fad fa-kiss-beam",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "kiss-wink-heart",
      "selector": "fas fa-kiss-wink-heart",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "kiss-wink-heart",
      "selector": "far fa-kiss-wink-heart",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "kiss-wink-heart",
      "selector": "fal fa-kiss-wink-heart",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "kiss-wink-heart",
      "selector": "fad fa-kiss-wink-heart",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "kite",
      "selector": "fas fa-kite",
      "categories": [
        "Solid",
        "Autumn",
        "Science",
        "Summer"
      ]
    },
    {
      "name": "kite",
      "selector": "far fa-kite",
      "categories": [
        "Regular",
        "Autumn",
        "Science",
        "Summer"
      ]
    },
    {
      "name": "kite",
      "selector": "fal fa-kite",
      "categories": [
        "Light",
        "Autumn",
        "Science",
        "Summer"
      ]
    },
    {
      "name": "kite",
      "selector": "fad fa-kite",
      "categories": [
        "Duotone",
        "Autumn",
        "Science",
        "Summer"
      ]
    },
    {
      "name": "kiwi-bird",
      "selector": "fas fa-kiwi-bird",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "kiwi-bird",
      "selector": "far fa-kiwi-bird",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "kiwi-bird",
      "selector": "fal fa-kiwi-bird",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "kiwi-bird",
      "selector": "fad fa-kiwi-bird",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "knife-kitchen",
      "selector": "fas fa-knife-kitchen",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "knife-kitchen",
      "selector": "far fa-knife-kitchen",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "knife-kitchen",
      "selector": "fal fa-knife-kitchen",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "knife-kitchen",
      "selector": "fad fa-knife-kitchen",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "korvue",
      "selector": "fab fa-korvue",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "lambda",
      "selector": "fas fa-lambda",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "lambda",
      "selector": "far fa-lambda",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "lambda",
      "selector": "fal fa-lambda",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "lambda",
      "selector": "fad fa-lambda",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "lamp",
      "selector": "fas fa-lamp",
      "categories": [
        "Solid",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "lamp",
      "selector": "far fa-lamp",
      "categories": [
        "Regular",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "lamp",
      "selector": "fal fa-lamp",
      "categories": [
        "Light",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "lamp",
      "selector": "fad fa-lamp",
      "categories": [
        "Duotone",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "lamp-desk",
      "selector": "fas fa-lamp-desk",
      "categories": [
        "Solid",
        "Business",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "lamp-desk",
      "selector": "far fa-lamp-desk",
      "categories": [
        "Regular",
        "Business",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "lamp-desk",
      "selector": "fal fa-lamp-desk",
      "categories": [
        "Light",
        "Business",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "lamp-desk",
      "selector": "fad fa-lamp-desk",
      "categories": [
        "Duotone",
        "Business",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "lamp-floor",
      "selector": "fas fa-lamp-floor",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "lamp-floor",
      "selector": "far fa-lamp-floor",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "lamp-floor",
      "selector": "fal fa-lamp-floor",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "lamp-floor",
      "selector": "fad fa-lamp-floor",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "landmark",
      "selector": "fas fa-landmark",
      "categories": [
        "Solid",
        "Buildings",
        "Business",
        "Finance",
        "Maps"
      ]
    },
    {
      "name": "landmark",
      "selector": "far fa-landmark",
      "categories": [
        "Regular",
        "Buildings",
        "Business",
        "Finance",
        "Maps"
      ]
    },
    {
      "name": "landmark",
      "selector": "fal fa-landmark",
      "categories": [
        "Light",
        "Buildings",
        "Business",
        "Finance",
        "Maps"
      ]
    },
    {
      "name": "landmark",
      "selector": "fad fa-landmark",
      "categories": [
        "Duotone",
        "Buildings",
        "Business",
        "Finance",
        "Maps"
      ]
    },
    {
      "name": "landmark-alt",
      "selector": "fas fa-landmark-alt",
      "categories": [
        "Solid",
        "Buildings",
        "Political"
      ]
    },
    {
      "name": "landmark-alt",
      "selector": "far fa-landmark-alt",
      "categories": [
        "Regular",
        "Buildings",
        "Political"
      ]
    },
    {
      "name": "landmark-alt",
      "selector": "fal fa-landmark-alt",
      "categories": [
        "Light",
        "Buildings",
        "Political"
      ]
    },
    {
      "name": "landmark-alt",
      "selector": "fad fa-landmark-alt",
      "categories": [
        "Duotone",
        "Buildings",
        "Political"
      ]
    },
    {
      "name": "language",
      "selector": "fas fa-language",
      "categories": [
        "Solid",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "language",
      "selector": "far fa-language",
      "categories": [
        "Regular",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "language",
      "selector": "fal fa-language",
      "categories": [
        "Light",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "language",
      "selector": "fad fa-language",
      "categories": [
        "Duotone",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "laptop",
      "selector": "fas fa-laptop",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "laptop",
      "selector": "far fa-laptop",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "laptop",
      "selector": "fal fa-laptop",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "laptop",
      "selector": "fad fa-laptop",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "laptop-code",
      "selector": "fas fa-laptop-code",
      "categories": [
        "Solid",
        "Code",
        "Education"
      ]
    },
    {
      "name": "laptop-code",
      "selector": "far fa-laptop-code",
      "categories": [
        "Regular",
        "Code",
        "Education"
      ]
    },
    {
      "name": "laptop-code",
      "selector": "fal fa-laptop-code",
      "categories": [
        "Light",
        "Code",
        "Education"
      ]
    },
    {
      "name": "laptop-code",
      "selector": "fad fa-laptop-code",
      "categories": [
        "Duotone",
        "Code",
        "Education"
      ]
    },
    {
      "name": "laptop-medical",
      "selector": "fas fa-laptop-medical",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "laptop-medical",
      "selector": "far fa-laptop-medical",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "laptop-medical",
      "selector": "fal fa-laptop-medical",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "laptop-medical",
      "selector": "fad fa-laptop-medical",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "laravel",
      "selector": "fab fa-laravel",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "lasso",
      "selector": "fas fa-lasso",
      "categories": [
        "Solid",
        "Design",
        "Interfaces"
      ]
    },
    {
      "name": "lasso",
      "selector": "far fa-lasso",
      "categories": [
        "Regular",
        "Design",
        "Interfaces"
      ]
    },
    {
      "name": "lasso",
      "selector": "fal fa-lasso",
      "categories": [
        "Light",
        "Design",
        "Interfaces"
      ]
    },
    {
      "name": "lasso",
      "selector": "fad fa-lasso",
      "categories": [
        "Duotone",
        "Design",
        "Interfaces"
      ]
    },
    {
      "name": "lastfm",
      "selector": "fab fa-lastfm",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "lastfm-square",
      "selector": "fab fa-lastfm-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "laugh",
      "selector": "fas fa-laugh",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "laugh",
      "selector": "far fa-laugh",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "laugh",
      "selector": "fal fa-laugh",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "laugh",
      "selector": "fad fa-laugh",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "laugh-beam",
      "selector": "fas fa-laugh-beam",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "laugh-beam",
      "selector": "far fa-laugh-beam",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "laugh-beam",
      "selector": "fal fa-laugh-beam",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "laugh-beam",
      "selector": "fad fa-laugh-beam",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "laugh-squint",
      "selector": "fas fa-laugh-squint",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "laugh-squint",
      "selector": "far fa-laugh-squint",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "laugh-squint",
      "selector": "fal fa-laugh-squint",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "laugh-squint",
      "selector": "fad fa-laugh-squint",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "laugh-wink",
      "selector": "fas fa-laugh-wink",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "laugh-wink",
      "selector": "far fa-laugh-wink",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "laugh-wink",
      "selector": "fal fa-laugh-wink",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "laugh-wink",
      "selector": "fad fa-laugh-wink",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "layer-group",
      "selector": "fas fa-layer-group",
      "categories": [
        "Solid",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-group",
      "selector": "far fa-layer-group",
      "categories": [
        "Regular",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-group",
      "selector": "fal fa-layer-group",
      "categories": [
        "Light",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-group",
      "selector": "fad fa-layer-group",
      "categories": [
        "Duotone",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-minus",
      "selector": "fas fa-layer-minus",
      "categories": [
        "Solid",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-minus",
      "selector": "far fa-layer-minus",
      "categories": [
        "Regular",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-minus",
      "selector": "fal fa-layer-minus",
      "categories": [
        "Light",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-minus",
      "selector": "fad fa-layer-minus",
      "categories": [
        "Duotone",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-plus",
      "selector": "fas fa-layer-plus",
      "categories": [
        "Solid",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-plus",
      "selector": "far fa-layer-plus",
      "categories": [
        "Regular",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-plus",
      "selector": "fal fa-layer-plus",
      "categories": [
        "Light",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "layer-plus",
      "selector": "fad fa-layer-plus",
      "categories": [
        "Duotone",
        "Design",
        "Maps"
      ]
    },
    {
      "name": "leaf",
      "selector": "fas fa-leaf",
      "categories": [
        "Solid",
        "Charity",
        "Energy",
        "Fruits & Vegetables",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "leaf",
      "selector": "far fa-leaf",
      "categories": [
        "Regular",
        "Charity",
        "Energy",
        "Fruits & Vegetables",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "leaf",
      "selector": "fal fa-leaf",
      "categories": [
        "Light",
        "Charity",
        "Energy",
        "Fruits & Vegetables",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "leaf",
      "selector": "fad fa-leaf",
      "categories": [
        "Duotone",
        "Charity",
        "Energy",
        "Fruits & Vegetables",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "leaf-heart",
      "selector": "fas fa-leaf-heart",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "leaf-heart",
      "selector": "far fa-leaf-heart",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "leaf-heart",
      "selector": "fal fa-leaf-heart",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "leaf-heart",
      "selector": "fad fa-leaf-heart",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "leaf-maple",
      "selector": "fas fa-leaf-maple",
      "categories": [
        "Solid",
        "Autumn"
      ]
    },
    {
      "name": "leaf-maple",
      "selector": "far fa-leaf-maple",
      "categories": [
        "Regular",
        "Autumn"
      ]
    },
    {
      "name": "leaf-maple",
      "selector": "fal fa-leaf-maple",
      "categories": [
        "Light",
        "Autumn"
      ]
    },
    {
      "name": "leaf-maple",
      "selector": "fad fa-leaf-maple",
      "categories": [
        "Duotone",
        "Autumn"
      ]
    },
    {
      "name": "leaf-oak",
      "selector": "fas fa-leaf-oak",
      "categories": [
        "Solid",
        "Autumn"
      ]
    },
    {
      "name": "leaf-oak",
      "selector": "far fa-leaf-oak",
      "categories": [
        "Regular",
        "Autumn"
      ]
    },
    {
      "name": "leaf-oak",
      "selector": "fal fa-leaf-oak",
      "categories": [
        "Light",
        "Autumn"
      ]
    },
    {
      "name": "leaf-oak",
      "selector": "fad fa-leaf-oak",
      "categories": [
        "Duotone",
        "Autumn"
      ]
    },
    {
      "name": "leanpub",
      "selector": "fab fa-leanpub",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "lemon",
      "selector": "fas fa-lemon",
      "categories": [
        "Solid",
        "Food",
        "Fruits & Vegetables",
        "Maps",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "lemon",
      "selector": "far fa-lemon",
      "categories": [
        "Regular",
        "Food",
        "Fruits & Vegetables",
        "Maps",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "lemon",
      "selector": "fal fa-lemon",
      "categories": [
        "Light",
        "Food",
        "Fruits & Vegetables",
        "Maps",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "lemon",
      "selector": "fad fa-lemon",
      "categories": [
        "Duotone",
        "Food",
        "Fruits & Vegetables",
        "Maps",
        "Objects",
        "Summer"
      ]
    },
    {
      "name": "less",
      "selector": "fab fa-less",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "less-than",
      "selector": "fas fa-less-than",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "less-than",
      "selector": "far fa-less-than",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "less-than",
      "selector": "fal fa-less-than",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "less-than",
      "selector": "fad fa-less-than",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "less-than-equal",
      "selector": "fas fa-less-than-equal",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "less-than-equal",
      "selector": "far fa-less-than-equal",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "less-than-equal",
      "selector": "fal fa-less-than-equal",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "less-than-equal",
      "selector": "fad fa-less-than-equal",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "level-down",
      "selector": "fas fa-level-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "level-down",
      "selector": "far fa-level-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "level-down",
      "selector": "fal fa-level-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "level-down",
      "selector": "fad fa-level-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "level-down-alt",
      "selector": "fas fa-level-down-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "level-down-alt",
      "selector": "far fa-level-down-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "level-down-alt",
      "selector": "fal fa-level-down-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "level-down-alt",
      "selector": "fad fa-level-down-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "level-up",
      "selector": "fas fa-level-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "level-up",
      "selector": "far fa-level-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "level-up",
      "selector": "fal fa-level-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "level-up",
      "selector": "fad fa-level-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "level-up-alt",
      "selector": "fas fa-level-up-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "level-up-alt",
      "selector": "far fa-level-up-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "level-up-alt",
      "selector": "fal fa-level-up-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "level-up-alt",
      "selector": "fad fa-level-up-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "life-ring",
      "selector": "fas fa-life-ring",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "life-ring",
      "selector": "far fa-life-ring",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "life-ring",
      "selector": "fal fa-life-ring",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "life-ring",
      "selector": "fad fa-life-ring",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "light-ceiling",
      "selector": "fas fa-light-ceiling",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "light-ceiling",
      "selector": "far fa-light-ceiling",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "light-ceiling",
      "selector": "fal fa-light-ceiling",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "light-ceiling",
      "selector": "fad fa-light-ceiling",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "light-switch",
      "selector": "fas fa-light-switch",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "light-switch",
      "selector": "far fa-light-switch",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "light-switch",
      "selector": "fal fa-light-switch",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "light-switch",
      "selector": "fad fa-light-switch",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "light-switch-off",
      "selector": "fas fa-light-switch-off",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "light-switch-off",
      "selector": "far fa-light-switch-off",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "light-switch-off",
      "selector": "fal fa-light-switch-off",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "light-switch-off",
      "selector": "fad fa-light-switch-off",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "light-switch-on",
      "selector": "fas fa-light-switch-on",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "light-switch-on",
      "selector": "far fa-light-switch-on",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "light-switch-on",
      "selector": "fal fa-light-switch-on",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "light-switch-on",
      "selector": "fad fa-light-switch-on",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "lightbulb",
      "selector": "fas fa-lightbulb",
      "categories": [
        "Solid",
        "Energy",
        "Household",
        "Maps",
        "Marketing",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "lightbulb",
      "selector": "far fa-lightbulb",
      "categories": [
        "Regular",
        "Energy",
        "Household",
        "Maps",
        "Marketing",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "lightbulb",
      "selector": "fal fa-lightbulb",
      "categories": [
        "Light",
        "Energy",
        "Household",
        "Maps",
        "Marketing",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "lightbulb",
      "selector": "fad fa-lightbulb",
      "categories": [
        "Duotone",
        "Energy",
        "Household",
        "Maps",
        "Marketing",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "lightbulb-dollar",
      "selector": "fas fa-lightbulb-dollar",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-dollar",
      "selector": "far fa-lightbulb-dollar",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-dollar",
      "selector": "fal fa-lightbulb-dollar",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-dollar",
      "selector": "fad fa-lightbulb-dollar",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-exclamation",
      "selector": "fas fa-lightbulb-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-exclamation",
      "selector": "far fa-lightbulb-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-exclamation",
      "selector": "fal fa-lightbulb-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-exclamation",
      "selector": "fad fa-lightbulb-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-on",
      "selector": "fas fa-lightbulb-on",
      "categories": [
        "Solid",
        "Household",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-on",
      "selector": "far fa-lightbulb-on",
      "categories": [
        "Regular",
        "Household",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-on",
      "selector": "fal fa-lightbulb-on",
      "categories": [
        "Light",
        "Household",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-on",
      "selector": "fad fa-lightbulb-on",
      "categories": [
        "Duotone",
        "Household",
        "Marketing"
      ]
    },
    {
      "name": "lightbulb-slash",
      "selector": "fas fa-lightbulb-slash",
      "categories": [
        "Solid",
        "Marketing",
        "Status"
      ]
    },
    {
      "name": "lightbulb-slash",
      "selector": "far fa-lightbulb-slash",
      "categories": [
        "Regular",
        "Marketing",
        "Status"
      ]
    },
    {
      "name": "lightbulb-slash",
      "selector": "fal fa-lightbulb-slash",
      "categories": [
        "Light",
        "Marketing",
        "Status"
      ]
    },
    {
      "name": "lightbulb-slash",
      "selector": "fad fa-lightbulb-slash",
      "categories": [
        "Duotone",
        "Marketing",
        "Status"
      ]
    },
    {
      "name": "lights-holiday",
      "selector": "fas fa-lights-holiday",
      "categories": [
        "Solid",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "lights-holiday",
      "selector": "far fa-lights-holiday",
      "categories": [
        "Regular",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "lights-holiday",
      "selector": "fal fa-lights-holiday",
      "categories": [
        "Light",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "lights-holiday",
      "selector": "fad fa-lights-holiday",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "line",
      "selector": "fab fa-line",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "line-columns",
      "selector": "fas fa-line-columns",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "line-columns",
      "selector": "far fa-line-columns",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "line-columns",
      "selector": "fal fa-line-columns",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "line-columns",
      "selector": "fad fa-line-columns",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "line-height",
      "selector": "fas fa-line-height",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "line-height",
      "selector": "far fa-line-height",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "line-height",
      "selector": "fal fa-line-height",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "line-height",
      "selector": "fad fa-line-height",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "link",
      "selector": "fas fa-link",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "link",
      "selector": "far fa-link",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "link",
      "selector": "fal fa-link",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "link",
      "selector": "fad fa-link",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "linkedin",
      "selector": "fab fa-linkedin",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "linkedin-in",
      "selector": "fab fa-linkedin-in",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "linode",
      "selector": "fab fa-linode",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "linux",
      "selector": "fab fa-linux",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "lips",
      "selector": "fas fa-lips",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "lips",
      "selector": "far fa-lips",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "lips",
      "selector": "fal fa-lips",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "lips",
      "selector": "fad fa-lips",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "lira-sign",
      "selector": "fas fa-lira-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "lira-sign",
      "selector": "far fa-lira-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "lira-sign",
      "selector": "fal fa-lira-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "lira-sign",
      "selector": "fad fa-lira-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "list",
      "selector": "fas fa-list",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "list",
      "selector": "far fa-list",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "list",
      "selector": "fal fa-list",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "list",
      "selector": "fad fa-list",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "list-alt",
      "selector": "fas fa-list-alt",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "list-alt",
      "selector": "far fa-list-alt",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "list-alt",
      "selector": "fal fa-list-alt",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "list-alt",
      "selector": "fad fa-list-alt",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "list-music",
      "selector": "fas fa-list-music",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "list-music",
      "selector": "far fa-list-music",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "list-music",
      "selector": "fal fa-list-music",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "list-music",
      "selector": "fad fa-list-music",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "list-ol",
      "selector": "fas fa-list-ol",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "list-ol",
      "selector": "far fa-list-ol",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "list-ol",
      "selector": "fal fa-list-ol",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "list-ol",
      "selector": "fad fa-list-ol",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "list-ul",
      "selector": "fas fa-list-ul",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "list-ul",
      "selector": "far fa-list-ul",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "list-ul",
      "selector": "fal fa-list-ul",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "list-ul",
      "selector": "fad fa-list-ul",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "location",
      "selector": "fas fa-location",
      "categories": [
        "Solid",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location",
      "selector": "far fa-location",
      "categories": [
        "Regular",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location",
      "selector": "fal fa-location",
      "categories": [
        "Light",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location",
      "selector": "fad fa-location",
      "categories": [
        "Duotone",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location-arrow",
      "selector": "fas fa-location-arrow",
      "categories": [
        "Solid",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "location-arrow",
      "selector": "far fa-location-arrow",
      "categories": [
        "Regular",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "location-arrow",
      "selector": "fal fa-location-arrow",
      "categories": [
        "Light",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "location-arrow",
      "selector": "fad fa-location-arrow",
      "categories": [
        "Duotone",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "location-circle",
      "selector": "fas fa-location-circle",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "location-circle",
      "selector": "far fa-location-circle",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "location-circle",
      "selector": "fal fa-location-circle",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "location-circle",
      "selector": "fad fa-location-circle",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "location-slash",
      "selector": "fas fa-location-slash",
      "categories": [
        "Solid",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location-slash",
      "selector": "far fa-location-slash",
      "categories": [
        "Regular",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location-slash",
      "selector": "fal fa-location-slash",
      "categories": [
        "Light",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "location-slash",
      "selector": "fad fa-location-slash",
      "categories": [
        "Duotone",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "lock",
      "selector": "fas fa-lock",
      "categories": [
        "Solid",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock",
      "selector": "far fa-lock",
      "categories": [
        "Regular",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock",
      "selector": "fal fa-lock",
      "categories": [
        "Light",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock",
      "selector": "fad fa-lock",
      "categories": [
        "Duotone",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-alt",
      "selector": "fas fa-lock-alt",
      "categories": [
        "Solid",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-alt",
      "selector": "far fa-lock-alt",
      "categories": [
        "Regular",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-alt",
      "selector": "fal fa-lock-alt",
      "categories": [
        "Light",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-alt",
      "selector": "fad fa-lock-alt",
      "categories": [
        "Duotone",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open",
      "selector": "fas fa-lock-open",
      "categories": [
        "Solid",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open",
      "selector": "far fa-lock-open",
      "categories": [
        "Regular",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open",
      "selector": "fal fa-lock-open",
      "categories": [
        "Light",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open",
      "selector": "fad fa-lock-open",
      "categories": [
        "Duotone",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open-alt",
      "selector": "fas fa-lock-open-alt",
      "categories": [
        "Solid",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open-alt",
      "selector": "far fa-lock-open-alt",
      "categories": [
        "Regular",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open-alt",
      "selector": "fal fa-lock-open-alt",
      "categories": [
        "Light",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "lock-open-alt",
      "selector": "fad fa-lock-open-alt",
      "categories": [
        "Duotone",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "long-arrow-alt-down",
      "selector": "fas fa-long-arrow-alt-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-down",
      "selector": "far fa-long-arrow-alt-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-down",
      "selector": "fal fa-long-arrow-alt-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-down",
      "selector": "fad fa-long-arrow-alt-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-left",
      "selector": "fas fa-long-arrow-alt-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-left",
      "selector": "far fa-long-arrow-alt-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-left",
      "selector": "fal fa-long-arrow-alt-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-left",
      "selector": "fad fa-long-arrow-alt-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-right",
      "selector": "fas fa-long-arrow-alt-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-right",
      "selector": "far fa-long-arrow-alt-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-right",
      "selector": "fal fa-long-arrow-alt-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-right",
      "selector": "fad fa-long-arrow-alt-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-up",
      "selector": "fas fa-long-arrow-alt-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-up",
      "selector": "far fa-long-arrow-alt-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-up",
      "selector": "fal fa-long-arrow-alt-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-alt-up",
      "selector": "fad fa-long-arrow-alt-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-down",
      "selector": "fas fa-long-arrow-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-down",
      "selector": "far fa-long-arrow-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-down",
      "selector": "fal fa-long-arrow-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-down",
      "selector": "fad fa-long-arrow-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-left",
      "selector": "fas fa-long-arrow-left",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-left",
      "selector": "far fa-long-arrow-left",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-left",
      "selector": "fal fa-long-arrow-left",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-left",
      "selector": "fad fa-long-arrow-left",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-right",
      "selector": "fas fa-long-arrow-right",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-right",
      "selector": "far fa-long-arrow-right",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-right",
      "selector": "fal fa-long-arrow-right",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-right",
      "selector": "fad fa-long-arrow-right",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-up",
      "selector": "fas fa-long-arrow-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-up",
      "selector": "far fa-long-arrow-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-up",
      "selector": "fal fa-long-arrow-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "long-arrow-up",
      "selector": "fad fa-long-arrow-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "loveseat",
      "selector": "fas fa-loveseat",
      "categories": [
        "Solid",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "loveseat",
      "selector": "far fa-loveseat",
      "categories": [
        "Regular",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "loveseat",
      "selector": "fal fa-loveseat",
      "categories": [
        "Light",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "loveseat",
      "selector": "fad fa-loveseat",
      "categories": [
        "Duotone",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "low-vision",
      "selector": "fas fa-low-vision",
      "categories": [
        "Solid",
        "Accessibility",
        "Maps"
      ]
    },
    {
      "name": "low-vision",
      "selector": "far fa-low-vision",
      "categories": [
        "Regular",
        "Accessibility",
        "Maps"
      ]
    },
    {
      "name": "low-vision",
      "selector": "fal fa-low-vision",
      "categories": [
        "Light",
        "Accessibility",
        "Maps"
      ]
    },
    {
      "name": "low-vision",
      "selector": "fad fa-low-vision",
      "categories": [
        "Duotone",
        "Accessibility",
        "Maps"
      ]
    },
    {
      "name": "luchador",
      "selector": "fas fa-luchador",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "luchador",
      "selector": "far fa-luchador",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "luchador",
      "selector": "fal fa-luchador",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "luchador",
      "selector": "fad fa-luchador",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "luggage-cart",
      "selector": "fas fa-luggage-cart",
      "categories": [
        "Solid",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "luggage-cart",
      "selector": "far fa-luggage-cart",
      "categories": [
        "Regular",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "luggage-cart",
      "selector": "fal fa-luggage-cart",
      "categories": [
        "Light",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "luggage-cart",
      "selector": "fad fa-luggage-cart",
      "categories": [
        "Duotone",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "lungs",
      "selector": "fas fa-lungs",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "lungs",
      "selector": "far fa-lungs",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "lungs",
      "selector": "fal fa-lungs",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "lungs",
      "selector": "fad fa-lungs",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "lyft",
      "selector": "fab fa-lyft",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "mace",
      "selector": "fas fa-mace",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "mace",
      "selector": "far fa-mace",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "mace",
      "selector": "fal fa-mace",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "mace",
      "selector": "fad fa-mace",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "magento",
      "selector": "fab fa-magento",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "magic",
      "selector": "fas fa-magic",
      "categories": [
        "Solid",
        "Design",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "magic",
      "selector": "far fa-magic",
      "categories": [
        "Regular",
        "Design",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "magic",
      "selector": "fal fa-magic",
      "categories": [
        "Light",
        "Design",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "magic",
      "selector": "fad fa-magic",
      "categories": [
        "Duotone",
        "Design",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "magnet",
      "selector": "fas fa-magnet",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "magnet",
      "selector": "far fa-magnet",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "magnet",
      "selector": "fal fa-magnet",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "magnet",
      "selector": "fad fa-magnet",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Science"
      ]
    },
    {
      "name": "mail-bulk",
      "selector": "fas fa-mail-bulk",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "mail-bulk",
      "selector": "far fa-mail-bulk",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "mail-bulk",
      "selector": "fal fa-mail-bulk",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "mail-bulk",
      "selector": "fad fa-mail-bulk",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "mailbox",
      "selector": "fas fa-mailbox",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "mailbox",
      "selector": "far fa-mailbox",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "mailbox",
      "selector": "fal fa-mailbox",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "mailbox",
      "selector": "fad fa-mailbox",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "mailchimp",
      "selector": "fab fa-mailchimp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "male",
      "selector": "fas fa-male",
      "categories": [
        "Solid",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "male",
      "selector": "far fa-male",
      "categories": [
        "Regular",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "male",
      "selector": "fal fa-male",
      "categories": [
        "Light",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "male",
      "selector": "fad fa-male",
      "categories": [
        "Duotone",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "mandalorian",
      "selector": "fab fa-mandalorian",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "mandolin",
      "selector": "fas fa-mandolin",
      "categories": [
        "Solid",
        "Music",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "mandolin",
      "selector": "far fa-mandolin",
      "categories": [
        "Regular",
        "Music",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "mandolin",
      "selector": "fal fa-mandolin",
      "categories": [
        "Light",
        "Music",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "mandolin",
      "selector": "fad fa-mandolin",
      "categories": [
        "Duotone",
        "Music",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "map",
      "selector": "fas fa-map",
      "categories": [
        "Solid",
        "Camping",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "map",
      "selector": "far fa-map",
      "categories": [
        "Regular",
        "Camping",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "map",
      "selector": "fal fa-map",
      "categories": [
        "Light",
        "Camping",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "map",
      "selector": "fad fa-map",
      "categories": [
        "Duotone",
        "Camping",
        "Maps",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "map-marked",
      "selector": "fas fa-map-marked",
      "categories": [
        "Solid",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked",
      "selector": "far fa-map-marked",
      "categories": [
        "Regular",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked",
      "selector": "fal fa-map-marked",
      "categories": [
        "Light",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked",
      "selector": "fad fa-map-marked",
      "categories": [
        "Duotone",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked-alt",
      "selector": "fas fa-map-marked-alt",
      "categories": [
        "Solid",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked-alt",
      "selector": "far fa-map-marked-alt",
      "categories": [
        "Regular",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked-alt",
      "selector": "fal fa-map-marked-alt",
      "categories": [
        "Light",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marked-alt",
      "selector": "fad fa-map-marked-alt",
      "categories": [
        "Duotone",
        "Camping",
        "Travel"
      ]
    },
    {
      "name": "map-marker",
      "selector": "fas fa-map-marker",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker",
      "selector": "far fa-map-marker",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker",
      "selector": "fal fa-map-marker",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker",
      "selector": "fad fa-map-marker",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Shapes",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt",
      "selector": "fas fa-map-marker-alt",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt",
      "selector": "far fa-map-marker-alt",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt",
      "selector": "fal fa-map-marker-alt",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt",
      "selector": "fad fa-map-marker-alt",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Social",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt-slash",
      "selector": "fas fa-map-marker-alt-slash",
      "categories": [
        "Solid",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt-slash",
      "selector": "far fa-map-marker-alt-slash",
      "categories": [
        "Regular",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt-slash",
      "selector": "fal fa-map-marker-alt-slash",
      "categories": [
        "Light",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-alt-slash",
      "selector": "fad fa-map-marker-alt-slash",
      "categories": [
        "Duotone",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-check",
      "selector": "fas fa-map-marker-check",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-check",
      "selector": "far fa-map-marker-check",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-check",
      "selector": "fal fa-map-marker-check",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-check",
      "selector": "fad fa-map-marker-check",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-marker-edit",
      "selector": "fas fa-map-marker-edit",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-edit",
      "selector": "far fa-map-marker-edit",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-edit",
      "selector": "fal fa-map-marker-edit",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-edit",
      "selector": "fad fa-map-marker-edit",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-marker-exclamation",
      "selector": "fas fa-map-marker-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Maps"
      ]
    },
    {
      "name": "map-marker-exclamation",
      "selector": "far fa-map-marker-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Maps"
      ]
    },
    {
      "name": "map-marker-exclamation",
      "selector": "fal fa-map-marker-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Maps"
      ]
    },
    {
      "name": "map-marker-exclamation",
      "selector": "fad fa-map-marker-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Maps"
      ]
    },
    {
      "name": "map-marker-minus",
      "selector": "fas fa-map-marker-minus",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-minus",
      "selector": "far fa-map-marker-minus",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-minus",
      "selector": "fal fa-map-marker-minus",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-minus",
      "selector": "fad fa-map-marker-minus",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-marker-plus",
      "selector": "fas fa-map-marker-plus",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-plus",
      "selector": "far fa-map-marker-plus",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-plus",
      "selector": "fal fa-map-marker-plus",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-plus",
      "selector": "fad fa-map-marker-plus",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-marker-question",
      "selector": "fas fa-map-marker-question",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-question",
      "selector": "far fa-map-marker-question",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-question",
      "selector": "fal fa-map-marker-question",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-question",
      "selector": "fad fa-map-marker-question",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-marker-slash",
      "selector": "fas fa-map-marker-slash",
      "categories": [
        "Solid",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-slash",
      "selector": "far fa-map-marker-slash",
      "categories": [
        "Regular",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-slash",
      "selector": "fal fa-map-marker-slash",
      "categories": [
        "Light",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-slash",
      "selector": "fad fa-map-marker-slash",
      "categories": [
        "Duotone",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "map-marker-smile",
      "selector": "fas fa-map-marker-smile",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-smile",
      "selector": "far fa-map-marker-smile",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-smile",
      "selector": "fal fa-map-marker-smile",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-smile",
      "selector": "fad fa-map-marker-smile",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-marker-times",
      "selector": "fas fa-map-marker-times",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "map-marker-times",
      "selector": "far fa-map-marker-times",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "map-marker-times",
      "selector": "fal fa-map-marker-times",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "map-marker-times",
      "selector": "fad fa-map-marker-times",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "map-pin",
      "selector": "fas fa-map-pin",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-pin",
      "selector": "far fa-map-pin",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-pin",
      "selector": "fal fa-map-pin",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-pin",
      "selector": "fad fa-map-pin",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-signs",
      "selector": "fas fa-map-signs",
      "categories": [
        "Solid",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-signs",
      "selector": "far fa-map-signs",
      "categories": [
        "Regular",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-signs",
      "selector": "fal fa-map-signs",
      "categories": [
        "Light",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "map-signs",
      "selector": "fad fa-map-signs",
      "categories": [
        "Duotone",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "markdown",
      "selector": "fab fa-markdown",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "marker",
      "selector": "fas fa-marker",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "marker",
      "selector": "far fa-marker",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "marker",
      "selector": "fal fa-marker",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "marker",
      "selector": "fad fa-marker",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "mars",
      "selector": "fas fa-mars",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "mars",
      "selector": "far fa-mars",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "mars",
      "selector": "fal fa-mars",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "mars",
      "selector": "fad fa-mars",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "mars-double",
      "selector": "fas fa-mars-double",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "mars-double",
      "selector": "far fa-mars-double",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "mars-double",
      "selector": "fal fa-mars-double",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "mars-double",
      "selector": "fad fa-mars-double",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke",
      "selector": "fas fa-mars-stroke",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke",
      "selector": "far fa-mars-stroke",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke",
      "selector": "fal fa-mars-stroke",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke",
      "selector": "fad fa-mars-stroke",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-h",
      "selector": "fas fa-mars-stroke-h",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-h",
      "selector": "far fa-mars-stroke-h",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-h",
      "selector": "fal fa-mars-stroke-h",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-h",
      "selector": "fad fa-mars-stroke-h",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-v",
      "selector": "fas fa-mars-stroke-v",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-v",
      "selector": "far fa-mars-stroke-v",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-v",
      "selector": "fal fa-mars-stroke-v",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "mars-stroke-v",
      "selector": "fad fa-mars-stroke-v",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "mask",
      "selector": "fas fa-mask",
      "categories": [
        "Solid",
        "Halloween",
        "Security"
      ]
    },
    {
      "name": "mask",
      "selector": "far fa-mask",
      "categories": [
        "Regular",
        "Halloween",
        "Security"
      ]
    },
    {
      "name": "mask",
      "selector": "fal fa-mask",
      "categories": [
        "Light",
        "Halloween",
        "Security"
      ]
    },
    {
      "name": "mask",
      "selector": "fad fa-mask",
      "categories": [
        "Duotone",
        "Halloween",
        "Security"
      ]
    },
    {
      "name": "mastodon",
      "selector": "fab fa-mastodon",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "maxcdn",
      "selector": "fab fa-maxcdn",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "mdb",
      "selector": "fab fa-mdb",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "meat",
      "selector": "fas fa-meat",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "meat",
      "selector": "far fa-meat",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "meat",
      "selector": "fal fa-meat",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "meat",
      "selector": "fad fa-meat",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "medal",
      "selector": "fas fa-medal",
      "categories": [
        "Solid",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "medal",
      "selector": "far fa-medal",
      "categories": [
        "Regular",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "medal",
      "selector": "fal fa-medal",
      "categories": [
        "Light",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "medal",
      "selector": "fad fa-medal",
      "categories": [
        "Duotone",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "medapps",
      "selector": "fab fa-medapps",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "medium",
      "selector": "fab fa-medium",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "medium-m",
      "selector": "fab fa-medium-m",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "medkit",
      "selector": "fas fa-medkit",
      "categories": [
        "Solid",
        "Health",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "medkit",
      "selector": "far fa-medkit",
      "categories": [
        "Regular",
        "Health",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "medkit",
      "selector": "fal fa-medkit",
      "categories": [
        "Light",
        "Health",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "medkit",
      "selector": "fad fa-medkit",
      "categories": [
        "Duotone",
        "Health",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "medrt",
      "selector": "fab fa-medrt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "meetup",
      "selector": "fab fa-meetup",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "megaphone",
      "selector": "fas fa-megaphone",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "megaphone",
      "selector": "far fa-megaphone",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "megaphone",
      "selector": "fal fa-megaphone",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "megaphone",
      "selector": "fad fa-megaphone",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "megaport",
      "selector": "fab fa-megaport",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "meh",
      "selector": "fas fa-meh",
      "categories": [
        "Solid",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "meh",
      "selector": "far fa-meh",
      "categories": [
        "Regular",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "meh",
      "selector": "fal fa-meh",
      "categories": [
        "Light",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "meh",
      "selector": "fad fa-meh",
      "categories": [
        "Duotone",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "meh-blank",
      "selector": "fas fa-meh-blank",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "meh-blank",
      "selector": "far fa-meh-blank",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "meh-blank",
      "selector": "fal fa-meh-blank",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "meh-blank",
      "selector": "fad fa-meh-blank",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "meh-rolling-eyes",
      "selector": "fas fa-meh-rolling-eyes",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "meh-rolling-eyes",
      "selector": "far fa-meh-rolling-eyes",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "meh-rolling-eyes",
      "selector": "fal fa-meh-rolling-eyes",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "meh-rolling-eyes",
      "selector": "fad fa-meh-rolling-eyes",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "memory",
      "selector": "fas fa-memory",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "memory",
      "selector": "far fa-memory",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "memory",
      "selector": "fal fa-memory",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "memory",
      "selector": "fad fa-memory",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mendeley",
      "selector": "fab fa-mendeley",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "menorah",
      "selector": "fas fa-menorah",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "menorah",
      "selector": "far fa-menorah",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "menorah",
      "selector": "fal fa-menorah",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "menorah",
      "selector": "fad fa-menorah",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "mercury",
      "selector": "fas fa-mercury",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "mercury",
      "selector": "far fa-mercury",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "mercury",
      "selector": "fal fa-mercury",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "mercury",
      "selector": "fad fa-mercury",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "meteor",
      "selector": "fas fa-meteor",
      "categories": [
        "Solid",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "meteor",
      "selector": "far fa-meteor",
      "categories": [
        "Regular",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "meteor",
      "selector": "fal fa-meteor",
      "categories": [
        "Light",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "meteor",
      "selector": "fad fa-meteor",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "microblog",
      "selector": "fab fa-microblog",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "microchip",
      "selector": "fas fa-microchip",
      "categories": [
        "Solid",
        "Code",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "microchip",
      "selector": "far fa-microchip",
      "categories": [
        "Regular",
        "Code",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "microchip",
      "selector": "fal fa-microchip",
      "categories": [
        "Light",
        "Code",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "microchip",
      "selector": "fad fa-microchip",
      "categories": [
        "Duotone",
        "Code",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "microphone",
      "selector": "fas fa-microphone",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone",
      "selector": "far fa-microphone",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone",
      "selector": "fal fa-microphone",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone",
      "selector": "fad fa-microphone",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone-alt",
      "selector": "fas fa-microphone-alt",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "microphone-alt",
      "selector": "far fa-microphone-alt",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "microphone-alt",
      "selector": "fal fa-microphone-alt",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "microphone-alt",
      "selector": "fad fa-microphone-alt",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "microphone-alt-slash",
      "selector": "fas fa-microphone-alt-slash",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Music",
        "Status"
      ]
    },
    {
      "name": "microphone-alt-slash",
      "selector": "far fa-microphone-alt-slash",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Music",
        "Status"
      ]
    },
    {
      "name": "microphone-alt-slash",
      "selector": "fal fa-microphone-alt-slash",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Music",
        "Status"
      ]
    },
    {
      "name": "microphone-alt-slash",
      "selector": "fad fa-microphone-alt-slash",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Music",
        "Status"
      ]
    },
    {
      "name": "microphone-slash",
      "selector": "fas fa-microphone-slash",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone-slash",
      "selector": "far fa-microphone-slash",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone-slash",
      "selector": "fal fa-microphone-slash",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone-slash",
      "selector": "fad fa-microphone-slash",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "microphone-stand",
      "selector": "fas fa-microphone-stand",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "microphone-stand",
      "selector": "far fa-microphone-stand",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "microphone-stand",
      "selector": "fal fa-microphone-stand",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "microphone-stand",
      "selector": "fad fa-microphone-stand",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "microscope",
      "selector": "fas fa-microscope",
      "categories": [
        "Solid",
        "Education",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "microscope",
      "selector": "far fa-microscope",
      "categories": [
        "Regular",
        "Education",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "microscope",
      "selector": "fal fa-microscope",
      "categories": [
        "Light",
        "Education",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "microscope",
      "selector": "fad fa-microscope",
      "categories": [
        "Duotone",
        "Education",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "microsoft",
      "selector": "fab fa-microsoft",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "microwave",
      "selector": "fas fa-microwave",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "microwave",
      "selector": "far fa-microwave",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "microwave",
      "selector": "fal fa-microwave",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "microwave",
      "selector": "fad fa-microwave",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "mind-share",
      "selector": "fas fa-mind-share",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "mind-share",
      "selector": "far fa-mind-share",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "mind-share",
      "selector": "fal fa-mind-share",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "mind-share",
      "selector": "fad fa-mind-share",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "minus",
      "selector": "fas fa-minus",
      "categories": [
        "Solid",
        "Interfaces",
        "Mathematics",
        "Status"
      ]
    },
    {
      "name": "minus",
      "selector": "far fa-minus",
      "categories": [
        "Regular",
        "Interfaces",
        "Mathematics",
        "Status"
      ]
    },
    {
      "name": "minus",
      "selector": "fal fa-minus",
      "categories": [
        "Light",
        "Interfaces",
        "Mathematics",
        "Status"
      ]
    },
    {
      "name": "minus",
      "selector": "fad fa-minus",
      "categories": [
        "Duotone",
        "Interfaces",
        "Mathematics",
        "Status"
      ]
    },
    {
      "name": "minus-circle",
      "selector": "fas fa-minus-circle",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-circle",
      "selector": "far fa-minus-circle",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-circle",
      "selector": "fal fa-minus-circle",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-circle",
      "selector": "fad fa-minus-circle",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-hexagon",
      "selector": "fas fa-minus-hexagon",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-hexagon",
      "selector": "far fa-minus-hexagon",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-hexagon",
      "selector": "fal fa-minus-hexagon",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-hexagon",
      "selector": "fad fa-minus-hexagon",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-octagon",
      "selector": "fas fa-minus-octagon",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-octagon",
      "selector": "far fa-minus-octagon",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-octagon",
      "selector": "fal fa-minus-octagon",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-octagon",
      "selector": "fad fa-minus-octagon",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-square",
      "selector": "fas fa-minus-square",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-square",
      "selector": "far fa-minus-square",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-square",
      "selector": "fal fa-minus-square",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "minus-square",
      "selector": "fad fa-minus-square",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "mistletoe",
      "selector": "fas fa-mistletoe",
      "categories": [
        "Solid",
        "Holiday"
      ]
    },
    {
      "name": "mistletoe",
      "selector": "far fa-mistletoe",
      "categories": [
        "Regular",
        "Holiday"
      ]
    },
    {
      "name": "mistletoe",
      "selector": "fal fa-mistletoe",
      "categories": [
        "Light",
        "Holiday"
      ]
    },
    {
      "name": "mistletoe",
      "selector": "fad fa-mistletoe",
      "categories": [
        "Duotone",
        "Holiday"
      ]
    },
    {
      "name": "mitten",
      "selector": "fas fa-mitten",
      "categories": [
        "Solid",
        "Childhood",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mitten",
      "selector": "far fa-mitten",
      "categories": [
        "Regular",
        "Childhood",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mitten",
      "selector": "fal fa-mitten",
      "categories": [
        "Light",
        "Childhood",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mitten",
      "selector": "fad fa-mitten",
      "categories": [
        "Duotone",
        "Childhood",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mix",
      "selector": "fab fa-mix",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "mixcloud",
      "selector": "fab fa-mixcloud",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "mizuni",
      "selector": "fab fa-mizuni",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "mobile",
      "selector": "fas fa-mobile",
      "categories": [
        "Solid",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile",
      "selector": "far fa-mobile",
      "categories": [
        "Regular",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile",
      "selector": "fal fa-mobile",
      "categories": [
        "Light",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile",
      "selector": "fad fa-mobile",
      "categories": [
        "Duotone",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-alt",
      "selector": "fas fa-mobile-alt",
      "categories": [
        "Solid",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-alt",
      "selector": "far fa-mobile-alt",
      "categories": [
        "Regular",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-alt",
      "selector": "fal fa-mobile-alt",
      "categories": [
        "Light",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-alt",
      "selector": "fad fa-mobile-alt",
      "categories": [
        "Duotone",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android",
      "selector": "fas fa-mobile-android",
      "categories": [
        "Solid",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android",
      "selector": "far fa-mobile-android",
      "categories": [
        "Regular",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android",
      "selector": "fal fa-mobile-android",
      "categories": [
        "Light",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android",
      "selector": "fad fa-mobile-android",
      "categories": [
        "Duotone",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android-alt",
      "selector": "fas fa-mobile-android-alt",
      "categories": [
        "Solid",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android-alt",
      "selector": "far fa-mobile-android-alt",
      "categories": [
        "Regular",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android-alt",
      "selector": "fal fa-mobile-android-alt",
      "categories": [
        "Light",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "mobile-android-alt",
      "selector": "fad fa-mobile-android-alt",
      "categories": [
        "Duotone",
        "Communication",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "modx",
      "selector": "fab fa-modx",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "monero",
      "selector": "fab fa-monero",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "money-bill",
      "selector": "fas fa-money-bill",
      "categories": [
        "Solid",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill",
      "selector": "far fa-money-bill",
      "categories": [
        "Regular",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill",
      "selector": "fal fa-money-bill",
      "categories": [
        "Light",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill",
      "selector": "fad fa-money-bill",
      "categories": [
        "Duotone",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill-alt",
      "selector": "fas fa-money-bill-alt",
      "categories": [
        "Solid",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill-alt",
      "selector": "far fa-money-bill-alt",
      "categories": [
        "Regular",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill-alt",
      "selector": "fal fa-money-bill-alt",
      "categories": [
        "Light",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill-alt",
      "selector": "fad fa-money-bill-alt",
      "categories": [
        "Duotone",
        "Currency",
        "Finance",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "money-bill-wave",
      "selector": "fas fa-money-bill-wave",
      "categories": [
        "Solid",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave",
      "selector": "far fa-money-bill-wave",
      "categories": [
        "Regular",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave",
      "selector": "fal fa-money-bill-wave",
      "categories": [
        "Light",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave",
      "selector": "fad fa-money-bill-wave",
      "categories": [
        "Duotone",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave-alt",
      "selector": "fas fa-money-bill-wave-alt",
      "categories": [
        "Solid",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave-alt",
      "selector": "far fa-money-bill-wave-alt",
      "categories": [
        "Regular",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave-alt",
      "selector": "fal fa-money-bill-wave-alt",
      "categories": [
        "Light",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-bill-wave-alt",
      "selector": "fad fa-money-bill-wave-alt",
      "categories": [
        "Duotone",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "money-check",
      "selector": "fas fa-money-check",
      "categories": [
        "Solid",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check",
      "selector": "far fa-money-check",
      "categories": [
        "Regular",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check",
      "selector": "fal fa-money-check",
      "categories": [
        "Light",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check",
      "selector": "fad fa-money-check",
      "categories": [
        "Duotone",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-alt",
      "selector": "fas fa-money-check-alt",
      "categories": [
        "Solid",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-alt",
      "selector": "far fa-money-check-alt",
      "categories": [
        "Regular",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-alt",
      "selector": "fal fa-money-check-alt",
      "categories": [
        "Light",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-alt",
      "selector": "fad fa-money-check-alt",
      "categories": [
        "Duotone",
        "Currency",
        "Finance",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit",
      "selector": "fas fa-money-check-edit",
      "categories": [
        "Solid",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit",
      "selector": "far fa-money-check-edit",
      "categories": [
        "Regular",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit",
      "selector": "fal fa-money-check-edit",
      "categories": [
        "Light",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit",
      "selector": "fad fa-money-check-edit",
      "categories": [
        "Duotone",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit-alt",
      "selector": "fas fa-money-check-edit-alt",
      "categories": [
        "Solid",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit-alt",
      "selector": "far fa-money-check-edit-alt",
      "categories": [
        "Regular",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit-alt",
      "selector": "fal fa-money-check-edit-alt",
      "categories": [
        "Light",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "money-check-edit-alt",
      "selector": "fad fa-money-check-edit-alt",
      "categories": [
        "Duotone",
        "Business",
        "Charity",
        "Payments & Shopping"
      ]
    },
    {
      "name": "monitor-heart-rate",
      "selector": "fas fa-monitor-heart-rate",
      "categories": [
        "Solid",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "monitor-heart-rate",
      "selector": "far fa-monitor-heart-rate",
      "categories": [
        "Regular",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "monitor-heart-rate",
      "selector": "fal fa-monitor-heart-rate",
      "categories": [
        "Light",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "monitor-heart-rate",
      "selector": "fad fa-monitor-heart-rate",
      "categories": [
        "Duotone",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "monkey",
      "selector": "fas fa-monkey",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "monkey",
      "selector": "far fa-monkey",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "monkey",
      "selector": "fal fa-monkey",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "monkey",
      "selector": "fad fa-monkey",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "monument",
      "selector": "fas fa-monument",
      "categories": [
        "Solid",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "monument",
      "selector": "far fa-monument",
      "categories": [
        "Regular",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "monument",
      "selector": "fal fa-monument",
      "categories": [
        "Light",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "monument",
      "selector": "fad fa-monument",
      "categories": [
        "Duotone",
        "Buildings",
        "Travel"
      ]
    },
    {
      "name": "moon",
      "selector": "fas fa-moon",
      "categories": [
        "Solid",
        "Objects",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon",
      "selector": "far fa-moon",
      "categories": [
        "Regular",
        "Objects",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon",
      "selector": "fal fa-moon",
      "categories": [
        "Light",
        "Objects",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon",
      "selector": "fad fa-moon",
      "categories": [
        "Duotone",
        "Objects",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon-cloud",
      "selector": "fas fa-moon-cloud",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "moon-cloud",
      "selector": "far fa-moon-cloud",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "moon-cloud",
      "selector": "fal fa-moon-cloud",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "moon-cloud",
      "selector": "fad fa-moon-cloud",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "moon-stars",
      "selector": "fas fa-moon-stars",
      "categories": [
        "Solid",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon-stars",
      "selector": "far fa-moon-stars",
      "categories": [
        "Regular",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon-stars",
      "selector": "fal fa-moon-stars",
      "categories": [
        "Light",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "moon-stars",
      "selector": "fad fa-moon-stars",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "mortar-pestle",
      "selector": "fas fa-mortar-pestle",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "mortar-pestle",
      "selector": "far fa-mortar-pestle",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "mortar-pestle",
      "selector": "fal fa-mortar-pestle",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "mortar-pestle",
      "selector": "fad fa-mortar-pestle",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "mosque",
      "selector": "fas fa-mosque",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "mosque",
      "selector": "far fa-mosque",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "mosque",
      "selector": "fal fa-mosque",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "mosque",
      "selector": "fad fa-mosque",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "motorcycle",
      "selector": "fas fa-motorcycle",
      "categories": [
        "Solid",
        "Automotive",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "motorcycle",
      "selector": "far fa-motorcycle",
      "categories": [
        "Regular",
        "Automotive",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "motorcycle",
      "selector": "fal fa-motorcycle",
      "categories": [
        "Light",
        "Automotive",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "motorcycle",
      "selector": "fad fa-motorcycle",
      "categories": [
        "Duotone",
        "Automotive",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "mountain",
      "selector": "fas fa-mountain",
      "categories": [
        "Solid",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountain",
      "selector": "far fa-mountain",
      "categories": [
        "Regular",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountain",
      "selector": "fal fa-mountain",
      "categories": [
        "Light",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountain",
      "selector": "fad fa-mountain",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountains",
      "selector": "fas fa-mountains",
      "categories": [
        "Solid",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountains",
      "selector": "far fa-mountains",
      "categories": [
        "Regular",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountains",
      "selector": "fal fa-mountains",
      "categories": [
        "Light",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mountains",
      "selector": "fad fa-mountains",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "mouse",
      "selector": "fas fa-mouse",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "mouse",
      "selector": "far fa-mouse",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "mouse",
      "selector": "fal fa-mouse",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "mouse",
      "selector": "fad fa-mouse",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "mouse-alt",
      "selector": "fas fa-mouse-alt",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "mouse-alt",
      "selector": "far fa-mouse-alt",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "mouse-alt",
      "selector": "fal fa-mouse-alt",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "mouse-alt",
      "selector": "fad fa-mouse-alt",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "mouse-pointer",
      "selector": "fas fa-mouse-pointer",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "mouse-pointer",
      "selector": "far fa-mouse-pointer",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "mouse-pointer",
      "selector": "fal fa-mouse-pointer",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "mouse-pointer",
      "selector": "fad fa-mouse-pointer",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "mp3-player",
      "selector": "fas fa-mp3-player",
      "categories": [
        "Solid",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "mp3-player",
      "selector": "far fa-mp3-player",
      "categories": [
        "Regular",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "mp3-player",
      "selector": "fal fa-mp3-player",
      "categories": [
        "Light",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "mp3-player",
      "selector": "fad fa-mp3-player",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "mug",
      "selector": "fas fa-mug",
      "categories": [
        "Solid",
        "Beverage"
      ]
    },
    {
      "name": "mug",
      "selector": "far fa-mug",
      "categories": [
        "Regular",
        "Beverage"
      ]
    },
    {
      "name": "mug",
      "selector": "fal fa-mug",
      "categories": [
        "Light",
        "Beverage"
      ]
    },
    {
      "name": "mug",
      "selector": "fad fa-mug",
      "categories": [
        "Duotone",
        "Beverage"
      ]
    },
    {
      "name": "mug-hot",
      "selector": "fas fa-mug-hot",
      "categories": [
        "Solid",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "mug-hot",
      "selector": "far fa-mug-hot",
      "categories": [
        "Regular",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "mug-hot",
      "selector": "fal fa-mug-hot",
      "categories": [
        "Light",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "mug-hot",
      "selector": "fad fa-mug-hot",
      "categories": [
        "Duotone",
        "Beverage",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "mug-marshmallows",
      "selector": "fas fa-mug-marshmallows",
      "categories": [
        "Solid",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mug-marshmallows",
      "selector": "far fa-mug-marshmallows",
      "categories": [
        "Regular",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mug-marshmallows",
      "selector": "fal fa-mug-marshmallows",
      "categories": [
        "Light",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mug-marshmallows",
      "selector": "fad fa-mug-marshmallows",
      "categories": [
        "Duotone",
        "Beverage",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "mug-tea",
      "selector": "fas fa-mug-tea",
      "categories": [
        "Solid",
        "Autumn",
        "Beverage",
        "Winter"
      ]
    },
    {
      "name": "mug-tea",
      "selector": "far fa-mug-tea",
      "categories": [
        "Regular",
        "Autumn",
        "Beverage",
        "Winter"
      ]
    },
    {
      "name": "mug-tea",
      "selector": "fal fa-mug-tea",
      "categories": [
        "Light",
        "Autumn",
        "Beverage",
        "Winter"
      ]
    },
    {
      "name": "mug-tea",
      "selector": "fad fa-mug-tea",
      "categories": [
        "Duotone",
        "Autumn",
        "Beverage",
        "Winter"
      ]
    },
    {
      "name": "music",
      "selector": "fas fa-music",
      "categories": [
        "Solid",
        "Audio & Video",
        "Education",
        "Maps",
        "Music"
      ]
    },
    {
      "name": "music",
      "selector": "far fa-music",
      "categories": [
        "Regular",
        "Audio & Video",
        "Education",
        "Maps",
        "Music"
      ]
    },
    {
      "name": "music",
      "selector": "fal fa-music",
      "categories": [
        "Light",
        "Audio & Video",
        "Education",
        "Maps",
        "Music"
      ]
    },
    {
      "name": "music",
      "selector": "fad fa-music",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Education",
        "Maps",
        "Music"
      ]
    },
    {
      "name": "music-alt",
      "selector": "fas fa-music-alt",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "music-alt",
      "selector": "far fa-music-alt",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "music-alt",
      "selector": "fal fa-music-alt",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "music-alt",
      "selector": "fad fa-music-alt",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "music-alt-slash",
      "selector": "fas fa-music-alt-slash",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "music-alt-slash",
      "selector": "far fa-music-alt-slash",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "music-alt-slash",
      "selector": "fal fa-music-alt-slash",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "music-alt-slash",
      "selector": "fad fa-music-alt-slash",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "music-slash",
      "selector": "fas fa-music-slash",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "music-slash",
      "selector": "far fa-music-slash",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "music-slash",
      "selector": "fal fa-music-slash",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "music-slash",
      "selector": "fad fa-music-slash",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "napster",
      "selector": "fab fa-napster",
      "categories": [
        "Brands",
        "Music"
      ]
    },
    {
      "name": "narwhal",
      "selector": "fas fa-narwhal",
      "categories": [
        "Solid",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "narwhal",
      "selector": "far fa-narwhal",
      "categories": [
        "Regular",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "narwhal",
      "selector": "fal fa-narwhal",
      "categories": [
        "Light",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "narwhal",
      "selector": "fad fa-narwhal",
      "categories": [
        "Duotone",
        "Animals",
        "Holiday"
      ]
    },
    {
      "name": "neos",
      "selector": "fab fa-neos",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "network-wired",
      "selector": "fas fa-network-wired",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "network-wired",
      "selector": "far fa-network-wired",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "network-wired",
      "selector": "fal fa-network-wired",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "network-wired",
      "selector": "fad fa-network-wired",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "neuter",
      "selector": "fas fa-neuter",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "neuter",
      "selector": "far fa-neuter",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "neuter",
      "selector": "fal fa-neuter",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "neuter",
      "selector": "fad fa-neuter",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "newspaper",
      "selector": "fas fa-newspaper",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "newspaper",
      "selector": "far fa-newspaper",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "newspaper",
      "selector": "fal fa-newspaper",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "newspaper",
      "selector": "fad fa-newspaper",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "nimblr",
      "selector": "fab fa-nimblr",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "node",
      "selector": "fab fa-node",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "node-js",
      "selector": "fab fa-node-js",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "not-equal",
      "selector": "fas fa-not-equal",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "not-equal",
      "selector": "far fa-not-equal",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "not-equal",
      "selector": "fal fa-not-equal",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "not-equal",
      "selector": "fad fa-not-equal",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "notes-medical",
      "selector": "fas fa-notes-medical",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "notes-medical",
      "selector": "far fa-notes-medical",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "notes-medical",
      "selector": "fal fa-notes-medical",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "notes-medical",
      "selector": "fad fa-notes-medical",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "npm",
      "selector": "fab fa-npm",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ns8",
      "selector": "fab fa-ns8",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "nutritionix",
      "selector": "fab fa-nutritionix",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "object-group",
      "selector": "fas fa-object-group",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "object-group",
      "selector": "far fa-object-group",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "object-group",
      "selector": "fal fa-object-group",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "object-group",
      "selector": "fad fa-object-group",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "object-ungroup",
      "selector": "fas fa-object-ungroup",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "object-ungroup",
      "selector": "far fa-object-ungroup",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "object-ungroup",
      "selector": "fal fa-object-ungroup",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "object-ungroup",
      "selector": "fad fa-object-ungroup",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "octagon",
      "selector": "fas fa-octagon",
      "categories": [
        "Solid",
        "Shapes"
      ]
    },
    {
      "name": "octagon",
      "selector": "far fa-octagon",
      "categories": [
        "Regular",
        "Shapes"
      ]
    },
    {
      "name": "octagon",
      "selector": "fal fa-octagon",
      "categories": [
        "Light",
        "Shapes"
      ]
    },
    {
      "name": "octagon",
      "selector": "fad fa-octagon",
      "categories": [
        "Duotone",
        "Shapes"
      ]
    },
    {
      "name": "odnoklassniki",
      "selector": "fab fa-odnoklassniki",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "odnoklassniki-square",
      "selector": "fab fa-odnoklassniki-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "oil-can",
      "selector": "fas fa-oil-can",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "oil-can",
      "selector": "far fa-oil-can",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "oil-can",
      "selector": "fal fa-oil-can",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "oil-can",
      "selector": "fad fa-oil-can",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "oil-temp",
      "selector": "fas fa-oil-temp",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "oil-temp",
      "selector": "far fa-oil-temp",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "oil-temp",
      "selector": "fal fa-oil-temp",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "oil-temp",
      "selector": "fad fa-oil-temp",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "old-republic",
      "selector": "fab fa-old-republic",
      "categories": [
        "Brands",
        "Science Fiction"
      ]
    },
    {
      "name": "om",
      "selector": "fas fa-om",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "om",
      "selector": "far fa-om",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "om",
      "selector": "fal fa-om",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "om",
      "selector": "fad fa-om",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "omega",
      "selector": "fas fa-omega",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "omega",
      "selector": "far fa-omega",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "omega",
      "selector": "fal fa-omega",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "omega",
      "selector": "fad fa-omega",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "opencart",
      "selector": "fab fa-opencart",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "openid",
      "selector": "fab fa-openid",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "opera",
      "selector": "fab fa-opera",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "optin-monster",
      "selector": "fab fa-optin-monster",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "orcid",
      "selector": "fab fa-orcid",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ornament",
      "selector": "fas fa-ornament",
      "categories": [
        "Solid",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "ornament",
      "selector": "far fa-ornament",
      "categories": [
        "Regular",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "ornament",
      "selector": "fal fa-ornament",
      "categories": [
        "Light",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "ornament",
      "selector": "fad fa-ornament",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "osi",
      "selector": "fab fa-osi",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "otter",
      "selector": "fas fa-otter",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "otter",
      "selector": "far fa-otter",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "otter",
      "selector": "fal fa-otter",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "otter",
      "selector": "fad fa-otter",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "outdent",
      "selector": "fas fa-outdent",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "outdent",
      "selector": "far fa-outdent",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "outdent",
      "selector": "fal fa-outdent",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "outdent",
      "selector": "fad fa-outdent",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "outlet",
      "selector": "fas fa-outlet",
      "categories": [
        "Solid",
        "Energy",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "outlet",
      "selector": "far fa-outlet",
      "categories": [
        "Regular",
        "Energy",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "outlet",
      "selector": "fal fa-outlet",
      "categories": [
        "Light",
        "Energy",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "outlet",
      "selector": "fad fa-outlet",
      "categories": [
        "Duotone",
        "Energy",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "oven",
      "selector": "fas fa-oven",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "oven",
      "selector": "far fa-oven",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "oven",
      "selector": "fal fa-oven",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "oven",
      "selector": "fad fa-oven",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "overline",
      "selector": "fas fa-overline",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "overline",
      "selector": "far fa-overline",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "overline",
      "selector": "fal fa-overline",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "overline",
      "selector": "fad fa-overline",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "page-break",
      "selector": "fas fa-page-break",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "page-break",
      "selector": "far fa-page-break",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "page-break",
      "selector": "fal fa-page-break",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "page-break",
      "selector": "fad fa-page-break",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "page4",
      "selector": "fab fa-page4",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pagelines",
      "selector": "fab fa-pagelines",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pager",
      "selector": "fas fa-pager",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "pager",
      "selector": "far fa-pager",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "pager",
      "selector": "fal fa-pager",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "pager",
      "selector": "fad fa-pager",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "paint-brush",
      "selector": "fas fa-paint-brush",
      "categories": [
        "Solid",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "paint-brush",
      "selector": "far fa-paint-brush",
      "categories": [
        "Regular",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "paint-brush",
      "selector": "fal fa-paint-brush",
      "categories": [
        "Light",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "paint-brush",
      "selector": "fad fa-paint-brush",
      "categories": [
        "Duotone",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "paint-brush-alt",
      "selector": "fas fa-paint-brush-alt",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "paint-brush-alt",
      "selector": "far fa-paint-brush-alt",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "paint-brush-alt",
      "selector": "fal fa-paint-brush-alt",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "paint-brush-alt",
      "selector": "fad fa-paint-brush-alt",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "paint-roller",
      "selector": "fas fa-paint-roller",
      "categories": [
        "Solid",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "paint-roller",
      "selector": "far fa-paint-roller",
      "categories": [
        "Regular",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "paint-roller",
      "selector": "fal fa-paint-roller",
      "categories": [
        "Light",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "paint-roller",
      "selector": "fad fa-paint-roller",
      "categories": [
        "Duotone",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "palette",
      "selector": "fas fa-palette",
      "categories": [
        "Solid",
        "Design",
        "Spinners"
      ]
    },
    {
      "name": "palette",
      "selector": "far fa-palette",
      "categories": [
        "Regular",
        "Design",
        "Spinners"
      ]
    },
    {
      "name": "palette",
      "selector": "fal fa-palette",
      "categories": [
        "Light",
        "Design",
        "Spinners"
      ]
    },
    {
      "name": "palette",
      "selector": "fad fa-palette",
      "categories": [
        "Duotone",
        "Design",
        "Spinners"
      ]
    },
    {
      "name": "palfed",
      "selector": "fab fa-palfed",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pallet",
      "selector": "fas fa-pallet",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "pallet",
      "selector": "far fa-pallet",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "pallet",
      "selector": "fal fa-pallet",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "pallet",
      "selector": "fad fa-pallet",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "pallet-alt",
      "selector": "fas fa-pallet-alt",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "pallet-alt",
      "selector": "far fa-pallet-alt",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "pallet-alt",
      "selector": "fal fa-pallet-alt",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "pallet-alt",
      "selector": "fad fa-pallet-alt",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "paper-plane",
      "selector": "fas fa-paper-plane",
      "categories": [
        "Solid",
        "Communication",
        "Editors",
        "Objects",
        "Vehicles",
        "Writing"
      ]
    },
    {
      "name": "paper-plane",
      "selector": "far fa-paper-plane",
      "categories": [
        "Regular",
        "Communication",
        "Editors",
        "Objects",
        "Vehicles",
        "Writing"
      ]
    },
    {
      "name": "paper-plane",
      "selector": "fal fa-paper-plane",
      "categories": [
        "Light",
        "Communication",
        "Editors",
        "Objects",
        "Vehicles",
        "Writing"
      ]
    },
    {
      "name": "paper-plane",
      "selector": "fad fa-paper-plane",
      "categories": [
        "Duotone",
        "Communication",
        "Editors",
        "Objects",
        "Vehicles",
        "Writing"
      ]
    },
    {
      "name": "paperclip",
      "selector": "fas fa-paperclip",
      "categories": [
        "Solid",
        "Business",
        "Editors",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "paperclip",
      "selector": "far fa-paperclip",
      "categories": [
        "Regular",
        "Business",
        "Editors",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "paperclip",
      "selector": "fal fa-paperclip",
      "categories": [
        "Light",
        "Business",
        "Editors",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "paperclip",
      "selector": "fad fa-paperclip",
      "categories": [
        "Duotone",
        "Business",
        "Editors",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "parachute-box",
      "selector": "fas fa-parachute-box",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "parachute-box",
      "selector": "far fa-parachute-box",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "parachute-box",
      "selector": "fal fa-parachute-box",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "parachute-box",
      "selector": "fad fa-parachute-box",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "paragraph",
      "selector": "fas fa-paragraph",
      "categories": [
        "Solid",
        "Editors",
        "Writing"
      ]
    },
    {
      "name": "paragraph",
      "selector": "far fa-paragraph",
      "categories": [
        "Regular",
        "Editors",
        "Writing"
      ]
    },
    {
      "name": "paragraph",
      "selector": "fal fa-paragraph",
      "categories": [
        "Light",
        "Editors",
        "Writing"
      ]
    },
    {
      "name": "paragraph",
      "selector": "fad fa-paragraph",
      "categories": [
        "Duotone",
        "Editors",
        "Writing"
      ]
    },
    {
      "name": "paragraph-rtl",
      "selector": "fas fa-paragraph-rtl",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "paragraph-rtl",
      "selector": "far fa-paragraph-rtl",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "paragraph-rtl",
      "selector": "fal fa-paragraph-rtl",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "paragraph-rtl",
      "selector": "fad fa-paragraph-rtl",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "parking",
      "selector": "fas fa-parking",
      "categories": [
        "Solid",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking",
      "selector": "far fa-parking",
      "categories": [
        "Regular",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking",
      "selector": "fal fa-parking",
      "categories": [
        "Light",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking",
      "selector": "fad fa-parking",
      "categories": [
        "Duotone",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle",
      "selector": "fas fa-parking-circle",
      "categories": [
        "Solid",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle",
      "selector": "far fa-parking-circle",
      "categories": [
        "Regular",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle",
      "selector": "fal fa-parking-circle",
      "categories": [
        "Light",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle",
      "selector": "fad fa-parking-circle",
      "categories": [
        "Duotone",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle-slash",
      "selector": "fas fa-parking-circle-slash",
      "categories": [
        "Solid",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle-slash",
      "selector": "far fa-parking-circle-slash",
      "categories": [
        "Regular",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle-slash",
      "selector": "fal fa-parking-circle-slash",
      "categories": [
        "Light",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-circle-slash",
      "selector": "fad fa-parking-circle-slash",
      "categories": [
        "Duotone",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-slash",
      "selector": "fas fa-parking-slash",
      "categories": [
        "Solid",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-slash",
      "selector": "far fa-parking-slash",
      "categories": [
        "Regular",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-slash",
      "selector": "fal fa-parking-slash",
      "categories": [
        "Light",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "parking-slash",
      "selector": "fad fa-parking-slash",
      "categories": [
        "Duotone",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "passport",
      "selector": "fas fa-passport",
      "categories": [
        "Solid",
        "Security",
        "Travel"
      ]
    },
    {
      "name": "passport",
      "selector": "far fa-passport",
      "categories": [
        "Regular",
        "Security",
        "Travel"
      ]
    },
    {
      "name": "passport",
      "selector": "fal fa-passport",
      "categories": [
        "Light",
        "Security",
        "Travel"
      ]
    },
    {
      "name": "passport",
      "selector": "fad fa-passport",
      "categories": [
        "Duotone",
        "Security",
        "Travel"
      ]
    },
    {
      "name": "pastafarianism",
      "selector": "fas fa-pastafarianism",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "pastafarianism",
      "selector": "far fa-pastafarianism",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "pastafarianism",
      "selector": "fal fa-pastafarianism",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "pastafarianism",
      "selector": "fad fa-pastafarianism",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "paste",
      "selector": "fas fa-paste",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "paste",
      "selector": "far fa-paste",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "paste",
      "selector": "fal fa-paste",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "paste",
      "selector": "fad fa-paste",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "patreon",
      "selector": "fab fa-patreon",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pause",
      "selector": "fas fa-pause",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "pause",
      "selector": "far fa-pause",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "pause",
      "selector": "fal fa-pause",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "pause",
      "selector": "fad fa-pause",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "pause-circle",
      "selector": "fas fa-pause-circle",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "pause-circle",
      "selector": "far fa-pause-circle",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "pause-circle",
      "selector": "fal fa-pause-circle",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "pause-circle",
      "selector": "fad fa-pause-circle",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "paw",
      "selector": "fas fa-paw",
      "categories": [
        "Solid",
        "Animals",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "paw",
      "selector": "far fa-paw",
      "categories": [
        "Regular",
        "Animals",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "paw",
      "selector": "fal fa-paw",
      "categories": [
        "Light",
        "Animals",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "paw",
      "selector": "fad fa-paw",
      "categories": [
        "Duotone",
        "Animals",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "paw-alt",
      "selector": "fas fa-paw-alt",
      "categories": [
        "Solid",
        "Animals",
        "Hotel"
      ]
    },
    {
      "name": "paw-alt",
      "selector": "far fa-paw-alt",
      "categories": [
        "Regular",
        "Animals",
        "Hotel"
      ]
    },
    {
      "name": "paw-alt",
      "selector": "fal fa-paw-alt",
      "categories": [
        "Light",
        "Animals",
        "Hotel"
      ]
    },
    {
      "name": "paw-alt",
      "selector": "fad fa-paw-alt",
      "categories": [
        "Duotone",
        "Animals",
        "Hotel"
      ]
    },
    {
      "name": "paw-claws",
      "selector": "fas fa-paw-claws",
      "categories": [
        "Solid",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "paw-claws",
      "selector": "far fa-paw-claws",
      "categories": [
        "Regular",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "paw-claws",
      "selector": "fal fa-paw-claws",
      "categories": [
        "Light",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "paw-claws",
      "selector": "fad fa-paw-claws",
      "categories": [
        "Duotone",
        "Animals",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "paypal",
      "selector": "fab fa-paypal",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "peace",
      "selector": "fas fa-peace",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "peace",
      "selector": "far fa-peace",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "peace",
      "selector": "fal fa-peace",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "peace",
      "selector": "fad fa-peace",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "pegasus",
      "selector": "fas fa-pegasus",
      "categories": [
        "Solid",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "pegasus",
      "selector": "far fa-pegasus",
      "categories": [
        "Regular",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "pegasus",
      "selector": "fal fa-pegasus",
      "categories": [
        "Light",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "pegasus",
      "selector": "fad fa-pegasus",
      "categories": [
        "Duotone",
        "Animals",
        "Vehicles"
      ]
    },
    {
      "name": "pen",
      "selector": "fas fa-pen",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen",
      "selector": "far fa-pen",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen",
      "selector": "fal fa-pen",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen",
      "selector": "fad fa-pen",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen-alt",
      "selector": "fas fa-pen-alt",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen-alt",
      "selector": "far fa-pen-alt",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen-alt",
      "selector": "fal fa-pen-alt",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen-alt",
      "selector": "fad fa-pen-alt",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pen-fancy",
      "selector": "fas fa-pen-fancy",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "pen-fancy",
      "selector": "far fa-pen-fancy",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "pen-fancy",
      "selector": "fal fa-pen-fancy",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "pen-fancy",
      "selector": "fad fa-pen-fancy",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "pen-nib",
      "selector": "fas fa-pen-nib",
      "categories": [
        "Solid",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "pen-nib",
      "selector": "far fa-pen-nib",
      "categories": [
        "Regular",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "pen-nib",
      "selector": "fal fa-pen-nib",
      "categories": [
        "Light",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "pen-nib",
      "selector": "fad fa-pen-nib",
      "categories": [
        "Duotone",
        "Business",
        "Design",
        "Editors",
        "Objects"
      ]
    },
    {
      "name": "pen-square",
      "selector": "fas fa-pen-square",
      "categories": [
        "Solid",
        "Business",
        "Writing"
      ]
    },
    {
      "name": "pen-square",
      "selector": "far fa-pen-square",
      "categories": [
        "Regular",
        "Business",
        "Writing"
      ]
    },
    {
      "name": "pen-square",
      "selector": "fal fa-pen-square",
      "categories": [
        "Light",
        "Business",
        "Writing"
      ]
    },
    {
      "name": "pen-square",
      "selector": "fad fa-pen-square",
      "categories": [
        "Duotone",
        "Business",
        "Writing"
      ]
    },
    {
      "name": "pencil",
      "selector": "fas fa-pencil",
      "categories": [
        "Solid",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil",
      "selector": "far fa-pencil",
      "categories": [
        "Regular",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil",
      "selector": "fal fa-pencil",
      "categories": [
        "Light",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil",
      "selector": "fad fa-pencil",
      "categories": [
        "Duotone",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil-alt",
      "selector": "fas fa-pencil-alt",
      "categories": [
        "Solid",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil-alt",
      "selector": "far fa-pencil-alt",
      "categories": [
        "Regular",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil-alt",
      "selector": "fal fa-pencil-alt",
      "categories": [
        "Light",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil-alt",
      "selector": "fad fa-pencil-alt",
      "categories": [
        "Duotone",
        "Business",
        "Construction",
        "Design",
        "Editors",
        "Interfaces",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "pencil-paintbrush",
      "selector": "fas fa-pencil-paintbrush",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "pencil-paintbrush",
      "selector": "far fa-pencil-paintbrush",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "pencil-paintbrush",
      "selector": "fal fa-pencil-paintbrush",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "pencil-paintbrush",
      "selector": "fad fa-pencil-paintbrush",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "pencil-ruler",
      "selector": "fas fa-pencil-ruler",
      "categories": [
        "Solid",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "pencil-ruler",
      "selector": "far fa-pencil-ruler",
      "categories": [
        "Regular",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "pencil-ruler",
      "selector": "fal fa-pencil-ruler",
      "categories": [
        "Light",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "pencil-ruler",
      "selector": "fad fa-pencil-ruler",
      "categories": [
        "Duotone",
        "Construction",
        "Design"
      ]
    },
    {
      "name": "pennant",
      "selector": "fas fa-pennant",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "pennant",
      "selector": "far fa-pennant",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "pennant",
      "selector": "fal fa-pennant",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "pennant",
      "selector": "fad fa-pennant",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "penny-arcade",
      "selector": "fab fa-penny-arcade",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "people-carry",
      "selector": "fas fa-people-carry",
      "categories": [
        "Solid",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "people-carry",
      "selector": "far fa-people-carry",
      "categories": [
        "Regular",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "people-carry",
      "selector": "fal fa-people-carry",
      "categories": [
        "Light",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "people-carry",
      "selector": "fad fa-people-carry",
      "categories": [
        "Duotone",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "pepper-hot",
      "selector": "fas fa-pepper-hot",
      "categories": [
        "Solid",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pepper-hot",
      "selector": "far fa-pepper-hot",
      "categories": [
        "Regular",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pepper-hot",
      "selector": "fal fa-pepper-hot",
      "categories": [
        "Light",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pepper-hot",
      "selector": "fad fa-pepper-hot",
      "categories": [
        "Duotone",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "percent",
      "selector": "fas fa-percent",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "percent",
      "selector": "far fa-percent",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "percent",
      "selector": "fal fa-percent",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "percent",
      "selector": "fad fa-percent",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "percentage",
      "selector": "fas fa-percentage",
      "categories": [
        "Solid",
        "Finance",
        "Mathematics"
      ]
    },
    {
      "name": "percentage",
      "selector": "far fa-percentage",
      "categories": [
        "Regular",
        "Finance",
        "Mathematics"
      ]
    },
    {
      "name": "percentage",
      "selector": "fal fa-percentage",
      "categories": [
        "Light",
        "Finance",
        "Mathematics"
      ]
    },
    {
      "name": "percentage",
      "selector": "fad fa-percentage",
      "categories": [
        "Duotone",
        "Finance",
        "Mathematics"
      ]
    },
    {
      "name": "periscope",
      "selector": "fab fa-periscope",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "person-booth",
      "selector": "fas fa-person-booth",
      "categories": [
        "Solid",
        "Political",
        "Shopping",
        "Users & People"
      ]
    },
    {
      "name": "person-booth",
      "selector": "far fa-person-booth",
      "categories": [
        "Regular",
        "Political",
        "Shopping",
        "Users & People"
      ]
    },
    {
      "name": "person-booth",
      "selector": "fal fa-person-booth",
      "categories": [
        "Light",
        "Political",
        "Shopping",
        "Users & People"
      ]
    },
    {
      "name": "person-booth",
      "selector": "fad fa-person-booth",
      "categories": [
        "Duotone",
        "Political",
        "Shopping",
        "Users & People"
      ]
    },
    {
      "name": "person-carry",
      "selector": "fas fa-person-carry",
      "categories": [
        "Solid",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-carry",
      "selector": "far fa-person-carry",
      "categories": [
        "Regular",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-carry",
      "selector": "fal fa-person-carry",
      "categories": [
        "Light",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-carry",
      "selector": "fad fa-person-carry",
      "categories": [
        "Duotone",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly",
      "selector": "fas fa-person-dolly",
      "categories": [
        "Solid",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly",
      "selector": "far fa-person-dolly",
      "categories": [
        "Regular",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly",
      "selector": "fal fa-person-dolly",
      "categories": [
        "Light",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly",
      "selector": "fad fa-person-dolly",
      "categories": [
        "Duotone",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly-empty",
      "selector": "fas fa-person-dolly-empty",
      "categories": [
        "Solid",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly-empty",
      "selector": "far fa-person-dolly-empty",
      "categories": [
        "Regular",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly-empty",
      "selector": "fal fa-person-dolly-empty",
      "categories": [
        "Light",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-dolly-empty",
      "selector": "fad fa-person-dolly-empty",
      "categories": [
        "Duotone",
        "Moving",
        "Users & People"
      ]
    },
    {
      "name": "person-sign",
      "selector": "fas fa-person-sign",
      "categories": [
        "Solid",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "person-sign",
      "selector": "far fa-person-sign",
      "categories": [
        "Regular",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "person-sign",
      "selector": "fal fa-person-sign",
      "categories": [
        "Light",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "person-sign",
      "selector": "fad fa-person-sign",
      "categories": [
        "Duotone",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "phabricator",
      "selector": "fab fa-phabricator",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "phoenix-framework",
      "selector": "fab fa-phoenix-framework",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "phoenix-squadron",
      "selector": "fab fa-phoenix-squadron",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "phone",
      "selector": "fas fa-phone",
      "categories": [
        "Solid",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone",
      "selector": "far fa-phone",
      "categories": [
        "Regular",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone",
      "selector": "fal fa-phone",
      "categories": [
        "Light",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone",
      "selector": "fad fa-phone",
      "categories": [
        "Duotone",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone-alt",
      "selector": "fas fa-phone-alt",
      "categories": [
        "Solid",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone-alt",
      "selector": "far fa-phone-alt",
      "categories": [
        "Regular",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone-alt",
      "selector": "fal fa-phone-alt",
      "categories": [
        "Light",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone-alt",
      "selector": "fad fa-phone-alt",
      "categories": [
        "Duotone",
        "Business",
        "Chat",
        "Communication",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "phone-laptop",
      "selector": "fas fa-phone-laptop",
      "categories": [
        "Solid",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "phone-laptop",
      "selector": "far fa-phone-laptop",
      "categories": [
        "Regular",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "phone-laptop",
      "selector": "fal fa-phone-laptop",
      "categories": [
        "Light",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "phone-laptop",
      "selector": "fad fa-phone-laptop",
      "categories": [
        "Duotone",
        "Code",
        "Computers"
      ]
    },
    {
      "name": "phone-office",
      "selector": "fas fa-phone-office",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "phone-office",
      "selector": "far fa-phone-office",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "phone-office",
      "selector": "fal fa-phone-office",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "phone-office",
      "selector": "fad fa-phone-office",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "phone-plus",
      "selector": "fas fa-phone-plus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "phone-plus",
      "selector": "far fa-phone-plus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "phone-plus",
      "selector": "fal fa-phone-plus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "phone-plus",
      "selector": "fad fa-phone-plus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "phone-rotary",
      "selector": "fas fa-phone-rotary",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "phone-rotary",
      "selector": "far fa-phone-rotary",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "phone-rotary",
      "selector": "fal fa-phone-rotary",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "phone-rotary",
      "selector": "fad fa-phone-rotary",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "phone-slash",
      "selector": "fas fa-phone-slash",
      "categories": [
        "Solid",
        "Business",
        "Chat",
        "Communication",
        "Status"
      ]
    },
    {
      "name": "phone-slash",
      "selector": "far fa-phone-slash",
      "categories": [
        "Regular",
        "Business",
        "Chat",
        "Communication",
        "Status"
      ]
    },
    {
      "name": "phone-slash",
      "selector": "fal fa-phone-slash",
      "categories": [
        "Light",
        "Business",
        "Chat",
        "Communication",
        "Status"
      ]
    },
    {
      "name": "phone-slash",
      "selector": "fad fa-phone-slash",
      "categories": [
        "Duotone",
        "Business",
        "Chat",
        "Communication",
        "Status"
      ]
    },
    {
      "name": "phone-square",
      "selector": "fas fa-phone-square",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square",
      "selector": "far fa-phone-square",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square",
      "selector": "fal fa-phone-square",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square",
      "selector": "fad fa-phone-square",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square-alt",
      "selector": "fas fa-phone-square-alt",
      "categories": [
        "Solid",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square-alt",
      "selector": "far fa-phone-square-alt",
      "categories": [
        "Regular",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square-alt",
      "selector": "fal fa-phone-square-alt",
      "categories": [
        "Light",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-square-alt",
      "selector": "fad fa-phone-square-alt",
      "categories": [
        "Duotone",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-volume",
      "selector": "fas fa-phone-volume",
      "categories": [
        "Solid",
        "Accessibility",
        "Audio & Video",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-volume",
      "selector": "far fa-phone-volume",
      "categories": [
        "Regular",
        "Accessibility",
        "Audio & Video",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-volume",
      "selector": "fal fa-phone-volume",
      "categories": [
        "Light",
        "Accessibility",
        "Audio & Video",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "phone-volume",
      "selector": "fad fa-phone-volume",
      "categories": [
        "Duotone",
        "Accessibility",
        "Audio & Video",
        "Business",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "photo-video",
      "selector": "fas fa-photo-video",
      "categories": [
        "Solid",
        "Audio & Video",
        "Files",
        "Images",
        "Social"
      ]
    },
    {
      "name": "photo-video",
      "selector": "far fa-photo-video",
      "categories": [
        "Regular",
        "Audio & Video",
        "Files",
        "Images",
        "Social"
      ]
    },
    {
      "name": "photo-video",
      "selector": "fal fa-photo-video",
      "categories": [
        "Light",
        "Audio & Video",
        "Files",
        "Images",
        "Social"
      ]
    },
    {
      "name": "photo-video",
      "selector": "fad fa-photo-video",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Files",
        "Images",
        "Social"
      ]
    },
    {
      "name": "php",
      "selector": "fab fa-php",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pi",
      "selector": "fas fa-pi",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "pi",
      "selector": "far fa-pi",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "pi",
      "selector": "fal fa-pi",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "pi",
      "selector": "fad fa-pi",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "piano",
      "selector": "fas fa-piano",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "piano",
      "selector": "far fa-piano",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "piano",
      "selector": "fal fa-piano",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "piano",
      "selector": "fad fa-piano",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "piano-keyboard",
      "selector": "fas fa-piano-keyboard",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "piano-keyboard",
      "selector": "far fa-piano-keyboard",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "piano-keyboard",
      "selector": "fal fa-piano-keyboard",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "piano-keyboard",
      "selector": "fad fa-piano-keyboard",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "pie",
      "selector": "fas fa-pie",
      "categories": [
        "Solid",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "pie",
      "selector": "far fa-pie",
      "categories": [
        "Regular",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "pie",
      "selector": "fal fa-pie",
      "categories": [
        "Light",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "pie",
      "selector": "fad fa-pie",
      "categories": [
        "Duotone",
        "Autumn",
        "Food"
      ]
    },
    {
      "name": "pied-piper",
      "selector": "fab fa-pied-piper",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pied-piper-alt",
      "selector": "fab fa-pied-piper-alt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pied-piper-hat",
      "selector": "fab fa-pied-piper-hat",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pied-piper-pp",
      "selector": "fab fa-pied-piper-pp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pied-piper-square",
      "selector": "fab fa-pied-piper-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pig",
      "selector": "fas fa-pig",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "pig",
      "selector": "far fa-pig",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "pig",
      "selector": "fal fa-pig",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "pig",
      "selector": "fad fa-pig",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "piggy-bank",
      "selector": "fas fa-piggy-bank",
      "categories": [
        "Solid",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "piggy-bank",
      "selector": "far fa-piggy-bank",
      "categories": [
        "Regular",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "piggy-bank",
      "selector": "fal fa-piggy-bank",
      "categories": [
        "Light",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "piggy-bank",
      "selector": "fad fa-piggy-bank",
      "categories": [
        "Duotone",
        "Charity",
        "Finance",
        "Political"
      ]
    },
    {
      "name": "pills",
      "selector": "fas fa-pills",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "pills",
      "selector": "far fa-pills",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "pills",
      "selector": "fal fa-pills",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "pills",
      "selector": "fad fa-pills",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "pinterest",
      "selector": "fab fa-pinterest",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pinterest-p",
      "selector": "fab fa-pinterest-p",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pinterest-square",
      "selector": "fab fa-pinterest-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "pizza",
      "selector": "fas fa-pizza",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "pizza",
      "selector": "far fa-pizza",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "pizza",
      "selector": "fal fa-pizza",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "pizza",
      "selector": "fad fa-pizza",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "pizza-slice",
      "selector": "fas fa-pizza-slice",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "pizza-slice",
      "selector": "far fa-pizza-slice",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "pizza-slice",
      "selector": "fal fa-pizza-slice",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "pizza-slice",
      "selector": "fad fa-pizza-slice",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "place-of-worship",
      "selector": "fas fa-place-of-worship",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "place-of-worship",
      "selector": "far fa-place-of-worship",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "place-of-worship",
      "selector": "fal fa-place-of-worship",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "place-of-worship",
      "selector": "fad fa-place-of-worship",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "plane",
      "selector": "fas fa-plane",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "plane",
      "selector": "far fa-plane",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "plane",
      "selector": "fal fa-plane",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "plane",
      "selector": "fad fa-plane",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "plane-alt",
      "selector": "fas fa-plane-alt",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "plane-alt",
      "selector": "far fa-plane-alt",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "plane-alt",
      "selector": "fal fa-plane-alt",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "plane-alt",
      "selector": "fad fa-plane-alt",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "plane-arrival",
      "selector": "fas fa-plane-arrival",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "plane-arrival",
      "selector": "far fa-plane-arrival",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "plane-arrival",
      "selector": "fal fa-plane-arrival",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "plane-arrival",
      "selector": "fad fa-plane-arrival",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "plane-departure",
      "selector": "fas fa-plane-departure",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "plane-departure",
      "selector": "far fa-plane-departure",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "plane-departure",
      "selector": "fal fa-plane-departure",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "plane-departure",
      "selector": "fad fa-plane-departure",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "planet-moon",
      "selector": "fas fa-planet-moon",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-moon",
      "selector": "far fa-planet-moon",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-moon",
      "selector": "fal fa-planet-moon",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-moon",
      "selector": "fad fa-planet-moon",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-ringed",
      "selector": "fas fa-planet-ringed",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-ringed",
      "selector": "far fa-planet-ringed",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-ringed",
      "selector": "fal fa-planet-ringed",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "planet-ringed",
      "selector": "fad fa-planet-ringed",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "play",
      "selector": "fas fa-play",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Music",
        "Shapes"
      ]
    },
    {
      "name": "play",
      "selector": "far fa-play",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Music",
        "Shapes"
      ]
    },
    {
      "name": "play",
      "selector": "fal fa-play",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Music",
        "Shapes"
      ]
    },
    {
      "name": "play",
      "selector": "fad fa-play",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Music",
        "Shapes"
      ]
    },
    {
      "name": "play-circle",
      "selector": "fas fa-play-circle",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "play-circle",
      "selector": "far fa-play-circle",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "play-circle",
      "selector": "fal fa-play-circle",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "play-circle",
      "selector": "fad fa-play-circle",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "playstation",
      "selector": "fab fa-playstation",
      "categories": [
        "Brands",
        "Games"
      ]
    },
    {
      "name": "plug",
      "selector": "fas fa-plug",
      "categories": [
        "Solid",
        "Computers",
        "Energy",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "plug",
      "selector": "far fa-plug",
      "categories": [
        "Regular",
        "Computers",
        "Energy",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "plug",
      "selector": "fal fa-plug",
      "categories": [
        "Light",
        "Computers",
        "Energy",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "plug",
      "selector": "fad fa-plug",
      "categories": [
        "Duotone",
        "Computers",
        "Energy",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "plus",
      "selector": "fas fa-plus",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Mathematics",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "plus",
      "selector": "far fa-plus",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Mathematics",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "plus",
      "selector": "fal fa-plus",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Mathematics",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "plus",
      "selector": "fad fa-plus",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Mathematics",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "plus-circle",
      "selector": "fas fa-plus-circle",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-circle",
      "selector": "far fa-plus-circle",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-circle",
      "selector": "fal fa-plus-circle",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-circle",
      "selector": "fad fa-plus-circle",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-hexagon",
      "selector": "fas fa-plus-hexagon",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-hexagon",
      "selector": "far fa-plus-hexagon",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-hexagon",
      "selector": "fal fa-plus-hexagon",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-hexagon",
      "selector": "fad fa-plus-hexagon",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-octagon",
      "selector": "fas fa-plus-octagon",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-octagon",
      "selector": "far fa-plus-octagon",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-octagon",
      "selector": "fal fa-plus-octagon",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-octagon",
      "selector": "fad fa-plus-octagon",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "plus-square",
      "selector": "fas fa-plus-square",
      "categories": [
        "Solid",
        "Health",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "plus-square",
      "selector": "far fa-plus-square",
      "categories": [
        "Regular",
        "Health",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "plus-square",
      "selector": "fal fa-plus-square",
      "categories": [
        "Light",
        "Health",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "plus-square",
      "selector": "fad fa-plus-square",
      "categories": [
        "Duotone",
        "Health",
        "Interfaces",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "podcast",
      "selector": "fas fa-podcast",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "podcast",
      "selector": "far fa-podcast",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "podcast",
      "selector": "fal fa-podcast",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "podcast",
      "selector": "fad fa-podcast",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "podium",
      "selector": "fas fa-podium",
      "categories": [
        "Solid",
        "Business",
        "Political"
      ]
    },
    {
      "name": "podium",
      "selector": "far fa-podium",
      "categories": [
        "Regular",
        "Business",
        "Political"
      ]
    },
    {
      "name": "podium",
      "selector": "fal fa-podium",
      "categories": [
        "Light",
        "Business",
        "Political"
      ]
    },
    {
      "name": "podium",
      "selector": "fad fa-podium",
      "categories": [
        "Duotone",
        "Business",
        "Political"
      ]
    },
    {
      "name": "podium-star",
      "selector": "fas fa-podium-star",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "podium-star",
      "selector": "far fa-podium-star",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "podium-star",
      "selector": "fal fa-podium-star",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "podium-star",
      "selector": "fad fa-podium-star",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "police-box",
      "selector": "fas fa-police-box",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "police-box",
      "selector": "far fa-police-box",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "police-box",
      "selector": "fal fa-police-box",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "police-box",
      "selector": "fad fa-police-box",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "poll",
      "selector": "fas fa-poll",
      "categories": [
        "Solid",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll",
      "selector": "far fa-poll",
      "categories": [
        "Regular",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll",
      "selector": "fal fa-poll",
      "categories": [
        "Light",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll",
      "selector": "fad fa-poll",
      "categories": [
        "Duotone",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll-h",
      "selector": "fas fa-poll-h",
      "categories": [
        "Solid",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll-h",
      "selector": "far fa-poll-h",
      "categories": [
        "Regular",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll-h",
      "selector": "fal fa-poll-h",
      "categories": [
        "Light",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll-h",
      "selector": "fad fa-poll-h",
      "categories": [
        "Duotone",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "poll-people",
      "selector": "fas fa-poll-people",
      "categories": [
        "Solid",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "poll-people",
      "selector": "far fa-poll-people",
      "categories": [
        "Regular",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "poll-people",
      "selector": "fal fa-poll-people",
      "categories": [
        "Light",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "poll-people",
      "selector": "fad fa-poll-people",
      "categories": [
        "Duotone",
        "Political",
        "Users & People"
      ]
    },
    {
      "name": "poo",
      "selector": "fas fa-poo",
      "categories": [
        "Solid",
        "Chat",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "poo",
      "selector": "far fa-poo",
      "categories": [
        "Regular",
        "Chat",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "poo",
      "selector": "fal fa-poo",
      "categories": [
        "Light",
        "Chat",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "poo",
      "selector": "fad fa-poo",
      "categories": [
        "Duotone",
        "Chat",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "poo-storm",
      "selector": "fas fa-poo-storm",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "poo-storm",
      "selector": "far fa-poo-storm",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "poo-storm",
      "selector": "fal fa-poo-storm",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "poo-storm",
      "selector": "fad fa-poo-storm",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "poop",
      "selector": "fas fa-poop",
      "categories": [
        "Solid",
        "Energy",
        "Medical"
      ]
    },
    {
      "name": "poop",
      "selector": "far fa-poop",
      "categories": [
        "Regular",
        "Energy",
        "Medical"
      ]
    },
    {
      "name": "poop",
      "selector": "fal fa-poop",
      "categories": [
        "Light",
        "Energy",
        "Medical"
      ]
    },
    {
      "name": "poop",
      "selector": "fad fa-poop",
      "categories": [
        "Duotone",
        "Energy",
        "Medical"
      ]
    },
    {
      "name": "popcorn",
      "selector": "fas fa-popcorn",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "popcorn",
      "selector": "far fa-popcorn",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "popcorn",
      "selector": "fal fa-popcorn",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "popcorn",
      "selector": "fad fa-popcorn",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "portal-enter",
      "selector": "fas fa-portal-enter",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-enter",
      "selector": "far fa-portal-enter",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-enter",
      "selector": "fal fa-portal-enter",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-enter",
      "selector": "fad fa-portal-enter",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-exit",
      "selector": "fas fa-portal-exit",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-exit",
      "selector": "far fa-portal-exit",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-exit",
      "selector": "fal fa-portal-exit",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "portal-exit",
      "selector": "fad fa-portal-exit",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "portrait",
      "selector": "fas fa-portrait",
      "categories": [
        "Solid",
        "Images",
        "Users & People"
      ]
    },
    {
      "name": "portrait",
      "selector": "far fa-portrait",
      "categories": [
        "Regular",
        "Images",
        "Users & People"
      ]
    },
    {
      "name": "portrait",
      "selector": "fal fa-portrait",
      "categories": [
        "Light",
        "Images",
        "Users & People"
      ]
    },
    {
      "name": "portrait",
      "selector": "fad fa-portrait",
      "categories": [
        "Duotone",
        "Images",
        "Users & People"
      ]
    },
    {
      "name": "pound-sign",
      "selector": "fas fa-pound-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "pound-sign",
      "selector": "far fa-pound-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "pound-sign",
      "selector": "fal fa-pound-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "pound-sign",
      "selector": "fad fa-pound-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "power-off",
      "selector": "fas fa-power-off",
      "categories": [
        "Solid",
        "Computers",
        "Energy",
        "Users & People"
      ]
    },
    {
      "name": "power-off",
      "selector": "far fa-power-off",
      "categories": [
        "Regular",
        "Computers",
        "Energy",
        "Users & People"
      ]
    },
    {
      "name": "power-off",
      "selector": "fal fa-power-off",
      "categories": [
        "Light",
        "Computers",
        "Energy",
        "Users & People"
      ]
    },
    {
      "name": "power-off",
      "selector": "fad fa-power-off",
      "categories": [
        "Duotone",
        "Computers",
        "Energy",
        "Users & People"
      ]
    },
    {
      "name": "pray",
      "selector": "fas fa-pray",
      "categories": [
        "Solid",
        "Religion",
        "Users & People"
      ]
    },
    {
      "name": "pray",
      "selector": "far fa-pray",
      "categories": [
        "Regular",
        "Religion",
        "Users & People"
      ]
    },
    {
      "name": "pray",
      "selector": "fal fa-pray",
      "categories": [
        "Light",
        "Religion",
        "Users & People"
      ]
    },
    {
      "name": "pray",
      "selector": "fad fa-pray",
      "categories": [
        "Duotone",
        "Religion",
        "Users & People"
      ]
    },
    {
      "name": "praying-hands",
      "selector": "fas fa-praying-hands",
      "categories": [
        "Solid",
        "Hands",
        "Religion"
      ]
    },
    {
      "name": "praying-hands",
      "selector": "far fa-praying-hands",
      "categories": [
        "Regular",
        "Hands",
        "Religion"
      ]
    },
    {
      "name": "praying-hands",
      "selector": "fal fa-praying-hands",
      "categories": [
        "Light",
        "Hands",
        "Religion"
      ]
    },
    {
      "name": "praying-hands",
      "selector": "fad fa-praying-hands",
      "categories": [
        "Duotone",
        "Hands",
        "Religion"
      ]
    },
    {
      "name": "prescription",
      "selector": "fas fa-prescription",
      "categories": [
        "Solid",
        "Health",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription",
      "selector": "far fa-prescription",
      "categories": [
        "Regular",
        "Health",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription",
      "selector": "fal fa-prescription",
      "categories": [
        "Light",
        "Health",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription",
      "selector": "fad fa-prescription",
      "categories": [
        "Duotone",
        "Health",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription-bottle",
      "selector": "fas fa-prescription-bottle",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "prescription-bottle",
      "selector": "far fa-prescription-bottle",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "prescription-bottle",
      "selector": "fal fa-prescription-bottle",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "prescription-bottle",
      "selector": "fad fa-prescription-bottle",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "prescription-bottle-alt",
      "selector": "fas fa-prescription-bottle-alt",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription-bottle-alt",
      "selector": "far fa-prescription-bottle-alt",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription-bottle-alt",
      "selector": "fal fa-prescription-bottle-alt",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "prescription-bottle-alt",
      "selector": "fad fa-prescription-bottle-alt",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "presentation",
      "selector": "fas fa-presentation",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "presentation",
      "selector": "far fa-presentation",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "presentation",
      "selector": "fal fa-presentation",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "presentation",
      "selector": "fad fa-presentation",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "print",
      "selector": "fas fa-print",
      "categories": [
        "Solid",
        "Business",
        "Computers",
        "Editors",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "print",
      "selector": "far fa-print",
      "categories": [
        "Regular",
        "Business",
        "Computers",
        "Editors",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "print",
      "selector": "fal fa-print",
      "categories": [
        "Light",
        "Business",
        "Computers",
        "Editors",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "print",
      "selector": "fad fa-print",
      "categories": [
        "Duotone",
        "Business",
        "Computers",
        "Editors",
        "Maps",
        "Objects",
        "Status"
      ]
    },
    {
      "name": "print-search",
      "selector": "fas fa-print-search",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "print-search",
      "selector": "far fa-print-search",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "print-search",
      "selector": "fal fa-print-search",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "print-search",
      "selector": "fad fa-print-search",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "print-slash",
      "selector": "fas fa-print-slash",
      "categories": [
        "Solid",
        "Business",
        "Status"
      ]
    },
    {
      "name": "print-slash",
      "selector": "far fa-print-slash",
      "categories": [
        "Regular",
        "Business",
        "Status"
      ]
    },
    {
      "name": "print-slash",
      "selector": "fal fa-print-slash",
      "categories": [
        "Light",
        "Business",
        "Status"
      ]
    },
    {
      "name": "print-slash",
      "selector": "fad fa-print-slash",
      "categories": [
        "Duotone",
        "Business",
        "Status"
      ]
    },
    {
      "name": "procedures",
      "selector": "fas fa-procedures",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "procedures",
      "selector": "far fa-procedures",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "procedures",
      "selector": "fal fa-procedures",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "procedures",
      "selector": "fad fa-procedures",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "product-hunt",
      "selector": "fab fa-product-hunt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "project-diagram",
      "selector": "fas fa-project-diagram",
      "categories": [
        "Solid",
        "Business",
        "Code"
      ]
    },
    {
      "name": "project-diagram",
      "selector": "far fa-project-diagram",
      "categories": [
        "Regular",
        "Business",
        "Code"
      ]
    },
    {
      "name": "project-diagram",
      "selector": "fal fa-project-diagram",
      "categories": [
        "Light",
        "Business",
        "Code"
      ]
    },
    {
      "name": "project-diagram",
      "selector": "fad fa-project-diagram",
      "categories": [
        "Duotone",
        "Business",
        "Code"
      ]
    },
    {
      "name": "projector",
      "selector": "fas fa-projector",
      "categories": [
        "Solid",
        "Audio & Video",
        "Business"
      ]
    },
    {
      "name": "projector",
      "selector": "far fa-projector",
      "categories": [
        "Regular",
        "Audio & Video",
        "Business"
      ]
    },
    {
      "name": "projector",
      "selector": "fal fa-projector",
      "categories": [
        "Light",
        "Audio & Video",
        "Business"
      ]
    },
    {
      "name": "projector",
      "selector": "fad fa-projector",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Business"
      ]
    },
    {
      "name": "pumpkin",
      "selector": "fas fa-pumpkin",
      "categories": [
        "Solid",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pumpkin",
      "selector": "far fa-pumpkin",
      "categories": [
        "Regular",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pumpkin",
      "selector": "fal fa-pumpkin",
      "categories": [
        "Light",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pumpkin",
      "selector": "fad fa-pumpkin",
      "categories": [
        "Duotone",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "pushed",
      "selector": "fab fa-pushed",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "puzzle-piece",
      "selector": "fas fa-puzzle-piece",
      "categories": [
        "Solid",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "puzzle-piece",
      "selector": "far fa-puzzle-piece",
      "categories": [
        "Regular",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "puzzle-piece",
      "selector": "fal fa-puzzle-piece",
      "categories": [
        "Light",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "puzzle-piece",
      "selector": "fad fa-puzzle-piece",
      "categories": [
        "Duotone",
        "Games",
        "Objects"
      ]
    },
    {
      "name": "python",
      "selector": "fab fa-python",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "qq",
      "selector": "fab fa-qq",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "qrcode",
      "selector": "fas fa-qrcode",
      "categories": [
        "Solid",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "qrcode",
      "selector": "far fa-qrcode",
      "categories": [
        "Regular",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "qrcode",
      "selector": "fal fa-qrcode",
      "categories": [
        "Light",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "qrcode",
      "selector": "fad fa-qrcode",
      "categories": [
        "Duotone",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "question",
      "selector": "fas fa-question",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question",
      "selector": "far fa-question",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question",
      "selector": "fal fa-question",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question",
      "selector": "fad fa-question",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-circle",
      "selector": "fas fa-question-circle",
      "categories": [
        "Solid",
        "Accessibility",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-circle",
      "selector": "far fa-question-circle",
      "categories": [
        "Regular",
        "Accessibility",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-circle",
      "selector": "fal fa-question-circle",
      "categories": [
        "Light",
        "Accessibility",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-circle",
      "selector": "fad fa-question-circle",
      "categories": [
        "Duotone",
        "Accessibility",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-square",
      "selector": "fas fa-question-square",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-square",
      "selector": "far fa-question-square",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-square",
      "selector": "fal fa-question-square",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "question-square",
      "selector": "fad fa-question-square",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "quidditch",
      "selector": "fas fa-quidditch",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "quidditch",
      "selector": "far fa-quidditch",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "quidditch",
      "selector": "fal fa-quidditch",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "quidditch",
      "selector": "fad fa-quidditch",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "quinscape",
      "selector": "fab fa-quinscape",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "quora",
      "selector": "fab fa-quora",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "quote-left",
      "selector": "fas fa-quote-left",
      "categories": [
        "Solid",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-left",
      "selector": "far fa-quote-left",
      "categories": [
        "Regular",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-left",
      "selector": "fal fa-quote-left",
      "categories": [
        "Light",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-left",
      "selector": "fad fa-quote-left",
      "categories": [
        "Duotone",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-right",
      "selector": "fas fa-quote-right",
      "categories": [
        "Solid",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-right",
      "selector": "far fa-quote-right",
      "categories": [
        "Regular",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-right",
      "selector": "fal fa-quote-right",
      "categories": [
        "Light",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quote-right",
      "selector": "fad fa-quote-right",
      "categories": [
        "Duotone",
        "Chat",
        "Editors",
        "Interfaces",
        "Writing"
      ]
    },
    {
      "name": "quran",
      "selector": "fas fa-quran",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "quran",
      "selector": "far fa-quran",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "quran",
      "selector": "fal fa-quran",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "quran",
      "selector": "fad fa-quran",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "r-project",
      "selector": "fab fa-r-project",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "rabbit",
      "selector": "fas fa-rabbit",
      "categories": [
        "Solid",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit",
      "selector": "far fa-rabbit",
      "categories": [
        "Regular",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit",
      "selector": "fal fa-rabbit",
      "categories": [
        "Light",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit",
      "selector": "fad fa-rabbit",
      "categories": [
        "Duotone",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit-fast",
      "selector": "fas fa-rabbit-fast",
      "categories": [
        "Solid",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit-fast",
      "selector": "far fa-rabbit-fast",
      "categories": [
        "Regular",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit-fast",
      "selector": "fal fa-rabbit-fast",
      "categories": [
        "Light",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "rabbit-fast",
      "selector": "fad fa-rabbit-fast",
      "categories": [
        "Duotone",
        "Animals",
        "Spring"
      ]
    },
    {
      "name": "racquet",
      "selector": "fas fa-racquet",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "racquet",
      "selector": "far fa-racquet",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "racquet",
      "selector": "fal fa-racquet",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "racquet",
      "selector": "fad fa-racquet",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "radar",
      "selector": "fas fa-radar",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "radar",
      "selector": "far fa-radar",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "radar",
      "selector": "fal fa-radar",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "radar",
      "selector": "fad fa-radar",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "radiation",
      "selector": "fas fa-radiation",
      "categories": [
        "Solid",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation",
      "selector": "far fa-radiation",
      "categories": [
        "Regular",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation",
      "selector": "fal fa-radiation",
      "categories": [
        "Light",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation",
      "selector": "fad fa-radiation",
      "categories": [
        "Duotone",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation-alt",
      "selector": "fas fa-radiation-alt",
      "categories": [
        "Solid",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation-alt",
      "selector": "far fa-radiation-alt",
      "categories": [
        "Regular",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation-alt",
      "selector": "fal fa-radiation-alt",
      "categories": [
        "Light",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radiation-alt",
      "selector": "fad fa-radiation-alt",
      "categories": [
        "Duotone",
        "Alert",
        "Energy",
        "Medical",
        "Science"
      ]
    },
    {
      "name": "radio",
      "selector": "fas fa-radio",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "radio",
      "selector": "far fa-radio",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "radio",
      "selector": "fal fa-radio",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "radio",
      "selector": "fad fa-radio",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "radio-alt",
      "selector": "fas fa-radio-alt",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "radio-alt",
      "selector": "far fa-radio-alt",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "radio-alt",
      "selector": "fal fa-radio-alt",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "radio-alt",
      "selector": "fad fa-radio-alt",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "rainbow",
      "selector": "fas fa-rainbow",
      "categories": [
        "Solid",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "rainbow",
      "selector": "far fa-rainbow",
      "categories": [
        "Regular",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "rainbow",
      "selector": "fal fa-rainbow",
      "categories": [
        "Light",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "rainbow",
      "selector": "fad fa-rainbow",
      "categories": [
        "Duotone",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "raindrops",
      "selector": "fas fa-raindrops",
      "categories": [
        "Solid",
        "Maritime",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "raindrops",
      "selector": "far fa-raindrops",
      "categories": [
        "Regular",
        "Maritime",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "raindrops",
      "selector": "fal fa-raindrops",
      "categories": [
        "Light",
        "Maritime",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "raindrops",
      "selector": "fad fa-raindrops",
      "categories": [
        "Duotone",
        "Maritime",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "ram",
      "selector": "fas fa-ram",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "ram",
      "selector": "far fa-ram",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "ram",
      "selector": "fal fa-ram",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "ram",
      "selector": "fad fa-ram",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "ramp-loading",
      "selector": "fas fa-ramp-loading",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "ramp-loading",
      "selector": "far fa-ramp-loading",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "ramp-loading",
      "selector": "fal fa-ramp-loading",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "ramp-loading",
      "selector": "fad fa-ramp-loading",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "random",
      "selector": "fas fa-random",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "random",
      "selector": "far fa-random",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "random",
      "selector": "fal fa-random",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "random",
      "selector": "fad fa-random",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "raspberry-pi",
      "selector": "fab fa-raspberry-pi",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ravelry",
      "selector": "fab fa-ravelry",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "raygun",
      "selector": "fas fa-raygun",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "raygun",
      "selector": "far fa-raygun",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "raygun",
      "selector": "fal fa-raygun",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "raygun",
      "selector": "fad fa-raygun",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "react",
      "selector": "fab fa-react",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "reacteurope",
      "selector": "fab fa-reacteurope",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "readme",
      "selector": "fab fa-readme",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "rebel",
      "selector": "fab fa-rebel",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "receipt",
      "selector": "fas fa-receipt",
      "categories": [
        "Solid",
        "Finance",
        "Payments & Shopping",
        "Pharmacy",
        "Shopping"
      ]
    },
    {
      "name": "receipt",
      "selector": "far fa-receipt",
      "categories": [
        "Regular",
        "Finance",
        "Payments & Shopping",
        "Pharmacy",
        "Shopping"
      ]
    },
    {
      "name": "receipt",
      "selector": "fal fa-receipt",
      "categories": [
        "Light",
        "Finance",
        "Payments & Shopping",
        "Pharmacy",
        "Shopping"
      ]
    },
    {
      "name": "receipt",
      "selector": "fad fa-receipt",
      "categories": [
        "Duotone",
        "Finance",
        "Payments & Shopping",
        "Pharmacy",
        "Shopping"
      ]
    },
    {
      "name": "record-vinyl",
      "selector": "fas fa-record-vinyl",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "record-vinyl",
      "selector": "far fa-record-vinyl",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "record-vinyl",
      "selector": "fal fa-record-vinyl",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "record-vinyl",
      "selector": "fad fa-record-vinyl",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "rectangle-landscape",
      "selector": "fas fa-rectangle-landscape",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-landscape",
      "selector": "far fa-rectangle-landscape",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-landscape",
      "selector": "fal fa-rectangle-landscape",
      "categories": [
        "Light",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-landscape",
      "selector": "fad fa-rectangle-landscape",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-portrait",
      "selector": "fas fa-rectangle-portrait",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-portrait",
      "selector": "far fa-rectangle-portrait",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-portrait",
      "selector": "fal fa-rectangle-portrait",
      "categories": [
        "Light",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-portrait",
      "selector": "fad fa-rectangle-portrait",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-wide",
      "selector": "fas fa-rectangle-wide",
      "categories": [
        "Solid",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-wide",
      "selector": "far fa-rectangle-wide",
      "categories": [
        "Regular",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-wide",
      "selector": "fal fa-rectangle-wide",
      "categories": [
        "Light",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "rectangle-wide",
      "selector": "fad fa-rectangle-wide",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Images",
        "Shapes"
      ]
    },
    {
      "name": "recycle",
      "selector": "fas fa-recycle",
      "categories": [
        "Solid",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "recycle",
      "selector": "far fa-recycle",
      "categories": [
        "Regular",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "recycle",
      "selector": "fal fa-recycle",
      "categories": [
        "Light",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "recycle",
      "selector": "fad fa-recycle",
      "categories": [
        "Duotone",
        "Arrows",
        "Maps"
      ]
    },
    {
      "name": "red-river",
      "selector": "fab fa-red-river",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "reddit",
      "selector": "fab fa-reddit",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "reddit-alien",
      "selector": "fab fa-reddit-alien",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "reddit-square",
      "selector": "fab fa-reddit-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "redhat",
      "selector": "fab fa-redhat",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "redo",
      "selector": "fas fa-redo",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo",
      "selector": "far fa-redo",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo",
      "selector": "fal fa-redo",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo",
      "selector": "fad fa-redo",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo-alt",
      "selector": "fas fa-redo-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo-alt",
      "selector": "far fa-redo-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo-alt",
      "selector": "fal fa-redo-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "redo-alt",
      "selector": "fad fa-redo-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "refrigerator",
      "selector": "fas fa-refrigerator",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "refrigerator",
      "selector": "far fa-refrigerator",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "refrigerator",
      "selector": "fal fa-refrigerator",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "refrigerator",
      "selector": "fad fa-refrigerator",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "registered",
      "selector": "fas fa-registered",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "registered",
      "selector": "far fa-registered",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "registered",
      "selector": "fal fa-registered",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "registered",
      "selector": "fad fa-registered",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "remove-format",
      "selector": "fas fa-remove-format",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "remove-format",
      "selector": "far fa-remove-format",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "remove-format",
      "selector": "fal fa-remove-format",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "remove-format",
      "selector": "fad fa-remove-format",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "renren",
      "selector": "fab fa-renren",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "repeat",
      "selector": "fas fa-repeat",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat",
      "selector": "far fa-repeat",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat",
      "selector": "fal fa-repeat",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat",
      "selector": "fad fa-repeat",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1",
      "selector": "fas fa-repeat-1",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1",
      "selector": "far fa-repeat-1",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1",
      "selector": "fal fa-repeat-1",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1",
      "selector": "fad fa-repeat-1",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1-alt",
      "selector": "fas fa-repeat-1-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1-alt",
      "selector": "far fa-repeat-1-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1-alt",
      "selector": "fal fa-repeat-1-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-1-alt",
      "selector": "fad fa-repeat-1-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-alt",
      "selector": "fas fa-repeat-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-alt",
      "selector": "far fa-repeat-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-alt",
      "selector": "fal fa-repeat-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "repeat-alt",
      "selector": "fad fa-repeat-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video"
      ]
    },
    {
      "name": "reply",
      "selector": "fas fa-reply",
      "categories": [
        "Solid",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply",
      "selector": "far fa-reply",
      "categories": [
        "Regular",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply",
      "selector": "fal fa-reply",
      "categories": [
        "Light",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply",
      "selector": "fad fa-reply",
      "categories": [
        "Duotone",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply-all",
      "selector": "fas fa-reply-all",
      "categories": [
        "Solid",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply-all",
      "selector": "far fa-reply-all",
      "categories": [
        "Regular",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply-all",
      "selector": "fal fa-reply-all",
      "categories": [
        "Light",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "reply-all",
      "selector": "fad fa-reply-all",
      "categories": [
        "Duotone",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "replyd",
      "selector": "fab fa-replyd",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "republican",
      "selector": "fas fa-republican",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "republican",
      "selector": "far fa-republican",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "republican",
      "selector": "fal fa-republican",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "republican",
      "selector": "fad fa-republican",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "researchgate",
      "selector": "fab fa-researchgate",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "resolving",
      "selector": "fab fa-resolving",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "restroom",
      "selector": "fas fa-restroom",
      "categories": [
        "Solid",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "restroom",
      "selector": "far fa-restroom",
      "categories": [
        "Regular",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "restroom",
      "selector": "fal fa-restroom",
      "categories": [
        "Light",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "restroom",
      "selector": "fad fa-restroom",
      "categories": [
        "Duotone",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "retweet",
      "selector": "fas fa-retweet",
      "categories": [
        "Solid",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet",
      "selector": "far fa-retweet",
      "categories": [
        "Regular",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet",
      "selector": "fal fa-retweet",
      "categories": [
        "Light",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet",
      "selector": "fad fa-retweet",
      "categories": [
        "Duotone",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet-alt",
      "selector": "fas fa-retweet-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet-alt",
      "selector": "far fa-retweet-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet-alt",
      "selector": "fal fa-retweet-alt",
      "categories": [
        "Light",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "retweet-alt",
      "selector": "fad fa-retweet-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Social"
      ]
    },
    {
      "name": "rev",
      "selector": "fab fa-rev",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ribbon",
      "selector": "fas fa-ribbon",
      "categories": [
        "Solid",
        "Charity"
      ]
    },
    {
      "name": "ribbon",
      "selector": "far fa-ribbon",
      "categories": [
        "Regular",
        "Charity"
      ]
    },
    {
      "name": "ribbon",
      "selector": "fal fa-ribbon",
      "categories": [
        "Light",
        "Charity"
      ]
    },
    {
      "name": "ribbon",
      "selector": "fad fa-ribbon",
      "categories": [
        "Duotone",
        "Charity"
      ]
    },
    {
      "name": "ring",
      "selector": "fas fa-ring",
      "categories": [
        "Solid",
        "Objects",
        "Spinners",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "ring",
      "selector": "far fa-ring",
      "categories": [
        "Regular",
        "Objects",
        "Spinners",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "ring",
      "selector": "fal fa-ring",
      "categories": [
        "Light",
        "Objects",
        "Spinners",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "ring",
      "selector": "fad fa-ring",
      "categories": [
        "Duotone",
        "Objects",
        "Spinners",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "rings-wedding",
      "selector": "fas fa-rings-wedding",
      "categories": [
        "Solid",
        "Travel"
      ]
    },
    {
      "name": "rings-wedding",
      "selector": "far fa-rings-wedding",
      "categories": [
        "Regular",
        "Travel"
      ]
    },
    {
      "name": "rings-wedding",
      "selector": "fal fa-rings-wedding",
      "categories": [
        "Light",
        "Travel"
      ]
    },
    {
      "name": "rings-wedding",
      "selector": "fad fa-rings-wedding",
      "categories": [
        "Duotone",
        "Travel"
      ]
    },
    {
      "name": "road",
      "selector": "fas fa-road",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "road",
      "selector": "far fa-road",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "road",
      "selector": "fal fa-road",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "road",
      "selector": "fad fa-road",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "robot",
      "selector": "fas fa-robot",
      "categories": [
        "Solid",
        "Childhood",
        "Science Fiction"
      ]
    },
    {
      "name": "robot",
      "selector": "far fa-robot",
      "categories": [
        "Regular",
        "Childhood",
        "Science Fiction"
      ]
    },
    {
      "name": "robot",
      "selector": "fal fa-robot",
      "categories": [
        "Light",
        "Childhood",
        "Science Fiction"
      ]
    },
    {
      "name": "robot",
      "selector": "fad fa-robot",
      "categories": [
        "Duotone",
        "Childhood",
        "Science Fiction"
      ]
    },
    {
      "name": "rocket",
      "selector": "fas fa-rocket",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket",
      "selector": "far fa-rocket",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket",
      "selector": "fal fa-rocket",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket",
      "selector": "fad fa-rocket",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket-launch",
      "selector": "fas fa-rocket-launch",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket-launch",
      "selector": "far fa-rocket-launch",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket-launch",
      "selector": "fal fa-rocket-launch",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocket-launch",
      "selector": "fad fa-rocket-launch",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "rocketchat",
      "selector": "fab fa-rocketchat",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "rockrms",
      "selector": "fab fa-rockrms",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "route",
      "selector": "fas fa-route",
      "categories": [
        "Solid",
        "Camping",
        "Maps",
        "Moving"
      ]
    },
    {
      "name": "route",
      "selector": "far fa-route",
      "categories": [
        "Regular",
        "Camping",
        "Maps",
        "Moving"
      ]
    },
    {
      "name": "route",
      "selector": "fal fa-route",
      "categories": [
        "Light",
        "Camping",
        "Maps",
        "Moving"
      ]
    },
    {
      "name": "route",
      "selector": "fad fa-route",
      "categories": [
        "Duotone",
        "Camping",
        "Maps",
        "Moving"
      ]
    },
    {
      "name": "route-highway",
      "selector": "fas fa-route-highway",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "route-highway",
      "selector": "far fa-route-highway",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "route-highway",
      "selector": "fal fa-route-highway",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "route-highway",
      "selector": "fad fa-route-highway",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "route-interstate",
      "selector": "fas fa-route-interstate",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "route-interstate",
      "selector": "far fa-route-interstate",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "route-interstate",
      "selector": "fal fa-route-interstate",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "route-interstate",
      "selector": "fad fa-route-interstate",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "router",
      "selector": "fas fa-router",
      "categories": [
        "Solid",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "router",
      "selector": "far fa-router",
      "categories": [
        "Regular",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "router",
      "selector": "fal fa-router",
      "categories": [
        "Light",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "router",
      "selector": "fad fa-router",
      "categories": [
        "Duotone",
        "Business",
        "Computers"
      ]
    },
    {
      "name": "rss",
      "selector": "fas fa-rss",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss",
      "selector": "far fa-rss",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss",
      "selector": "fal fa-rss",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss",
      "selector": "fad fa-rss",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss-square",
      "selector": "fas fa-rss-square",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss-square",
      "selector": "far fa-rss-square",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss-square",
      "selector": "fal fa-rss-square",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "rss-square",
      "selector": "fad fa-rss-square",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Interfaces"
      ]
    },
    {
      "name": "ruble-sign",
      "selector": "fas fa-ruble-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "ruble-sign",
      "selector": "far fa-ruble-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "ruble-sign",
      "selector": "fal fa-ruble-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "ruble-sign",
      "selector": "fad fa-ruble-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "ruler",
      "selector": "fas fa-ruler",
      "categories": [
        "Solid",
        "Construction"
      ]
    },
    {
      "name": "ruler",
      "selector": "far fa-ruler",
      "categories": [
        "Regular",
        "Construction"
      ]
    },
    {
      "name": "ruler",
      "selector": "fal fa-ruler",
      "categories": [
        "Light",
        "Construction"
      ]
    },
    {
      "name": "ruler",
      "selector": "fad fa-ruler",
      "categories": [
        "Duotone",
        "Construction"
      ]
    },
    {
      "name": "ruler-combined",
      "selector": "fas fa-ruler-combined",
      "categories": [
        "Solid",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-combined",
      "selector": "far fa-ruler-combined",
      "categories": [
        "Regular",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-combined",
      "selector": "fal fa-ruler-combined",
      "categories": [
        "Light",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-combined",
      "selector": "fad fa-ruler-combined",
      "categories": [
        "Duotone",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-horizontal",
      "selector": "fas fa-ruler-horizontal",
      "categories": [
        "Solid",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-horizontal",
      "selector": "far fa-ruler-horizontal",
      "categories": [
        "Regular",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-horizontal",
      "selector": "fal fa-ruler-horizontal",
      "categories": [
        "Light",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-horizontal",
      "selector": "fad fa-ruler-horizontal",
      "categories": [
        "Duotone",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-triangle",
      "selector": "fas fa-ruler-triangle",
      "categories": [
        "Solid",
        "Construction",
        "Education"
      ]
    },
    {
      "name": "ruler-triangle",
      "selector": "far fa-ruler-triangle",
      "categories": [
        "Regular",
        "Construction",
        "Education"
      ]
    },
    {
      "name": "ruler-triangle",
      "selector": "fal fa-ruler-triangle",
      "categories": [
        "Light",
        "Construction",
        "Education"
      ]
    },
    {
      "name": "ruler-triangle",
      "selector": "fad fa-ruler-triangle",
      "categories": [
        "Duotone",
        "Construction",
        "Education"
      ]
    },
    {
      "name": "ruler-vertical",
      "selector": "fas fa-ruler-vertical",
      "categories": [
        "Solid",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-vertical",
      "selector": "far fa-ruler-vertical",
      "categories": [
        "Regular",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-vertical",
      "selector": "fal fa-ruler-vertical",
      "categories": [
        "Light",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "ruler-vertical",
      "selector": "fad fa-ruler-vertical",
      "categories": [
        "Duotone",
        "Construction",
        "Design",
        "Objects"
      ]
    },
    {
      "name": "running",
      "selector": "fas fa-running",
      "categories": [
        "Solid",
        "Fitness",
        "Sports",
        "Users & People"
      ]
    },
    {
      "name": "running",
      "selector": "far fa-running",
      "categories": [
        "Regular",
        "Fitness",
        "Sports",
        "Users & People"
      ]
    },
    {
      "name": "running",
      "selector": "fal fa-running",
      "categories": [
        "Light",
        "Fitness",
        "Sports",
        "Users & People"
      ]
    },
    {
      "name": "running",
      "selector": "fad fa-running",
      "categories": [
        "Duotone",
        "Fitness",
        "Sports",
        "Users & People"
      ]
    },
    {
      "name": "rupee-sign",
      "selector": "fas fa-rupee-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "rupee-sign",
      "selector": "far fa-rupee-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "rupee-sign",
      "selector": "fal fa-rupee-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "rupee-sign",
      "selector": "fad fa-rupee-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "rv",
      "selector": "fas fa-rv",
      "categories": [
        "Solid",
        "Automotive",
        "Camping",
        "Holiday",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "rv",
      "selector": "far fa-rv",
      "categories": [
        "Regular",
        "Automotive",
        "Camping",
        "Holiday",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "rv",
      "selector": "fal fa-rv",
      "categories": [
        "Light",
        "Automotive",
        "Camping",
        "Holiday",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "rv",
      "selector": "fad fa-rv",
      "categories": [
        "Duotone",
        "Automotive",
        "Camping",
        "Holiday",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "sack",
      "selector": "fas fa-sack",
      "categories": [
        "Solid",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack",
      "selector": "far fa-sack",
      "categories": [
        "Regular",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack",
      "selector": "fal fa-sack",
      "categories": [
        "Light",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack",
      "selector": "fad fa-sack",
      "categories": [
        "Duotone",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack-dollar",
      "selector": "fas fa-sack-dollar",
      "categories": [
        "Solid",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack-dollar",
      "selector": "far fa-sack-dollar",
      "categories": [
        "Regular",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack-dollar",
      "selector": "fal fa-sack-dollar",
      "categories": [
        "Light",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sack-dollar",
      "selector": "fad fa-sack-dollar",
      "categories": [
        "Duotone",
        "Currency",
        "Finance"
      ]
    },
    {
      "name": "sad-cry",
      "selector": "fas fa-sad-cry",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "sad-cry",
      "selector": "far fa-sad-cry",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "sad-cry",
      "selector": "fal fa-sad-cry",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "sad-cry",
      "selector": "fad fa-sad-cry",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "sad-tear",
      "selector": "fas fa-sad-tear",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "sad-tear",
      "selector": "far fa-sad-tear",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "sad-tear",
      "selector": "fal fa-sad-tear",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "sad-tear",
      "selector": "fad fa-sad-tear",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "safari",
      "selector": "fab fa-safari",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "salad",
      "selector": "fas fa-salad",
      "categories": [
        "Solid",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "salad",
      "selector": "far fa-salad",
      "categories": [
        "Regular",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "salad",
      "selector": "fal fa-salad",
      "categories": [
        "Light",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "salad",
      "selector": "fad fa-salad",
      "categories": [
        "Duotone",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "salesforce",
      "selector": "fab fa-salesforce",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "sandwich",
      "selector": "fas fa-sandwich",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "sandwich",
      "selector": "far fa-sandwich",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "sandwich",
      "selector": "fal fa-sandwich",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "sandwich",
      "selector": "fad fa-sandwich",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "sass",
      "selector": "fab fa-sass",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "satellite",
      "selector": "fas fa-satellite",
      "categories": [
        "Solid",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite",
      "selector": "far fa-satellite",
      "categories": [
        "Regular",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite",
      "selector": "fal fa-satellite",
      "categories": [
        "Light",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite",
      "selector": "fad fa-satellite",
      "categories": [
        "Duotone",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite-dish",
      "selector": "fas fa-satellite-dish",
      "categories": [
        "Solid",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite-dish",
      "selector": "far fa-satellite-dish",
      "categories": [
        "Regular",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite-dish",
      "selector": "fal fa-satellite-dish",
      "categories": [
        "Light",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "satellite-dish",
      "selector": "fad fa-satellite-dish",
      "categories": [
        "Duotone",
        "Computers",
        "Objects",
        "Science Fiction"
      ]
    },
    {
      "name": "sausage",
      "selector": "fas fa-sausage",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "sausage",
      "selector": "far fa-sausage",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "sausage",
      "selector": "fal fa-sausage",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "sausage",
      "selector": "fad fa-sausage",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "save",
      "selector": "fas fa-save",
      "categories": [
        "Solid",
        "Business",
        "Computers",
        "Design",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "save",
      "selector": "far fa-save",
      "categories": [
        "Regular",
        "Business",
        "Computers",
        "Design",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "save",
      "selector": "fal fa-save",
      "categories": [
        "Light",
        "Business",
        "Computers",
        "Design",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "save",
      "selector": "fad fa-save",
      "categories": [
        "Duotone",
        "Business",
        "Computers",
        "Design",
        "Files",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "sax-hot",
      "selector": "fas fa-sax-hot",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "sax-hot",
      "selector": "far fa-sax-hot",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "sax-hot",
      "selector": "fal fa-sax-hot",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "sax-hot",
      "selector": "fad fa-sax-hot",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "saxophone",
      "selector": "fas fa-saxophone",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "saxophone",
      "selector": "far fa-saxophone",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "saxophone",
      "selector": "fal fa-saxophone",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "saxophone",
      "selector": "fad fa-saxophone",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "scalpel",
      "selector": "fas fa-scalpel",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "scalpel",
      "selector": "far fa-scalpel",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "scalpel",
      "selector": "fal fa-scalpel",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "scalpel",
      "selector": "fad fa-scalpel",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "scalpel-path",
      "selector": "fas fa-scalpel-path",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "scalpel-path",
      "selector": "far fa-scalpel-path",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "scalpel-path",
      "selector": "fal fa-scalpel-path",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "scalpel-path",
      "selector": "fad fa-scalpel-path",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "scanner",
      "selector": "fas fa-scanner",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "scanner",
      "selector": "far fa-scanner",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "scanner",
      "selector": "fal fa-scanner",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "scanner",
      "selector": "fad fa-scanner",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "scanner-image",
      "selector": "fas fa-scanner-image",
      "categories": [
        "Solid",
        "Business",
        "Computers",
        "Design"
      ]
    },
    {
      "name": "scanner-image",
      "selector": "far fa-scanner-image",
      "categories": [
        "Regular",
        "Business",
        "Computers",
        "Design"
      ]
    },
    {
      "name": "scanner-image",
      "selector": "fal fa-scanner-image",
      "categories": [
        "Light",
        "Business",
        "Computers",
        "Design"
      ]
    },
    {
      "name": "scanner-image",
      "selector": "fad fa-scanner-image",
      "categories": [
        "Duotone",
        "Business",
        "Computers",
        "Design"
      ]
    },
    {
      "name": "scanner-keyboard",
      "selector": "fas fa-scanner-keyboard",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "scanner-keyboard",
      "selector": "far fa-scanner-keyboard",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "scanner-keyboard",
      "selector": "fal fa-scanner-keyboard",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "scanner-keyboard",
      "selector": "fad fa-scanner-keyboard",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "scanner-touchscreen",
      "selector": "fas fa-scanner-touchscreen",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "scanner-touchscreen",
      "selector": "far fa-scanner-touchscreen",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "scanner-touchscreen",
      "selector": "fal fa-scanner-touchscreen",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "scanner-touchscreen",
      "selector": "fad fa-scanner-touchscreen",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "scarecrow",
      "selector": "fas fa-scarecrow",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "scarecrow",
      "selector": "far fa-scarecrow",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "scarecrow",
      "selector": "fal fa-scarecrow",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "scarecrow",
      "selector": "fad fa-scarecrow",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "scarf",
      "selector": "fas fa-scarf",
      "categories": [
        "Solid",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "scarf",
      "selector": "far fa-scarf",
      "categories": [
        "Regular",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "scarf",
      "selector": "fal fa-scarf",
      "categories": [
        "Light",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "scarf",
      "selector": "fad fa-scarf",
      "categories": [
        "Duotone",
        "Clothing",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "schlix",
      "selector": "fab fa-schlix",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "school",
      "selector": "fas fa-school",
      "categories": [
        "Solid",
        "Buildings",
        "Childhood",
        "Education",
        "Objects"
      ]
    },
    {
      "name": "school",
      "selector": "far fa-school",
      "categories": [
        "Regular",
        "Buildings",
        "Childhood",
        "Education",
        "Objects"
      ]
    },
    {
      "name": "school",
      "selector": "fal fa-school",
      "categories": [
        "Light",
        "Buildings",
        "Childhood",
        "Education",
        "Objects"
      ]
    },
    {
      "name": "school",
      "selector": "fad fa-school",
      "categories": [
        "Duotone",
        "Buildings",
        "Childhood",
        "Education",
        "Objects"
      ]
    },
    {
      "name": "screwdriver",
      "selector": "fas fa-screwdriver",
      "categories": [
        "Solid",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "screwdriver",
      "selector": "far fa-screwdriver",
      "categories": [
        "Regular",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "screwdriver",
      "selector": "fal fa-screwdriver",
      "categories": [
        "Light",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "screwdriver",
      "selector": "fad fa-screwdriver",
      "categories": [
        "Duotone",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "scribd",
      "selector": "fab fa-scribd",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "scroll",
      "selector": "fas fa-scroll",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll",
      "selector": "far fa-scroll",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll",
      "selector": "fal fa-scroll",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll",
      "selector": "fad fa-scroll",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll-old",
      "selector": "fas fa-scroll-old",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll-old",
      "selector": "far fa-scroll-old",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll-old",
      "selector": "fal fa-scroll-old",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scroll-old",
      "selector": "fad fa-scroll-old",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scrubber",
      "selector": "fas fa-scrubber",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "scrubber",
      "selector": "far fa-scrubber",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "scrubber",
      "selector": "fal fa-scrubber",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "scrubber",
      "selector": "fad fa-scrubber",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "scythe",
      "selector": "fas fa-scythe",
      "categories": [
        "Solid",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scythe",
      "selector": "far fa-scythe",
      "categories": [
        "Regular",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scythe",
      "selector": "fal fa-scythe",
      "categories": [
        "Light",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "scythe",
      "selector": "fad fa-scythe",
      "categories": [
        "Duotone",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sd-card",
      "selector": "fas fa-sd-card",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "sd-card",
      "selector": "far fa-sd-card",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "sd-card",
      "selector": "fal fa-sd-card",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "sd-card",
      "selector": "fad fa-sd-card",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "search",
      "selector": "fas fa-search",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "search",
      "selector": "far fa-search",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "search",
      "selector": "fal fa-search",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "search",
      "selector": "fad fa-search",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "search-dollar",
      "selector": "fas fa-search-dollar",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "search-dollar",
      "selector": "far fa-search-dollar",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "search-dollar",
      "selector": "fal fa-search-dollar",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "search-dollar",
      "selector": "fad fa-search-dollar",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "search-location",
      "selector": "fas fa-search-location",
      "categories": [
        "Solid",
        "Marketing"
      ]
    },
    {
      "name": "search-location",
      "selector": "far fa-search-location",
      "categories": [
        "Regular",
        "Marketing"
      ]
    },
    {
      "name": "search-location",
      "selector": "fal fa-search-location",
      "categories": [
        "Light",
        "Marketing"
      ]
    },
    {
      "name": "search-location",
      "selector": "fad fa-search-location",
      "categories": [
        "Duotone",
        "Marketing"
      ]
    },
    {
      "name": "search-minus",
      "selector": "fas fa-search-minus",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-minus",
      "selector": "far fa-search-minus",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-minus",
      "selector": "fal fa-search-minus",
      "categories": [
        "Light",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-minus",
      "selector": "fad fa-search-minus",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-plus",
      "selector": "fas fa-search-plus",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-plus",
      "selector": "far fa-search-plus",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-plus",
      "selector": "fal fa-search-plus",
      "categories": [
        "Light",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "search-plus",
      "selector": "fad fa-search-plus",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps"
      ]
    },
    {
      "name": "searchengin",
      "selector": "fab fa-searchengin",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "seedling",
      "selector": "fas fa-seedling",
      "categories": [
        "Solid",
        "Charity",
        "Energy",
        "Food",
        "Fruits & Vegetables",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "seedling",
      "selector": "far fa-seedling",
      "categories": [
        "Regular",
        "Charity",
        "Energy",
        "Food",
        "Fruits & Vegetables",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "seedling",
      "selector": "fal fa-seedling",
      "categories": [
        "Light",
        "Charity",
        "Energy",
        "Food",
        "Fruits & Vegetables",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "seedling",
      "selector": "fad fa-seedling",
      "categories": [
        "Duotone",
        "Charity",
        "Energy",
        "Food",
        "Fruits & Vegetables",
        "Science",
        "Spring"
      ]
    },
    {
      "name": "sellcast",
      "selector": "fab fa-sellcast",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "sellsy",
      "selector": "fab fa-sellsy",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "send-back",
      "selector": "fas fa-send-back",
      "categories": [
        "Solid",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-back",
      "selector": "far fa-send-back",
      "categories": [
        "Regular",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-back",
      "selector": "fal fa-send-back",
      "categories": [
        "Light",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-back",
      "selector": "fad fa-send-back",
      "categories": [
        "Duotone",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-backward",
      "selector": "fas fa-send-backward",
      "categories": [
        "Solid",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-backward",
      "selector": "far fa-send-backward",
      "categories": [
        "Regular",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-backward",
      "selector": "fal fa-send-backward",
      "categories": [
        "Light",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "send-backward",
      "selector": "fad fa-send-backward",
      "categories": [
        "Duotone",
        "Design",
        "Editors"
      ]
    },
    {
      "name": "sensor",
      "selector": "fas fa-sensor",
      "categories": [
        "Solid",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor",
      "selector": "far fa-sensor",
      "categories": [
        "Regular",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor",
      "selector": "fal fa-sensor",
      "categories": [
        "Light",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor",
      "selector": "fad fa-sensor",
      "categories": [
        "Duotone",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-alert",
      "selector": "fas fa-sensor-alert",
      "categories": [
        "Solid",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-alert",
      "selector": "far fa-sensor-alert",
      "categories": [
        "Regular",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-alert",
      "selector": "fal fa-sensor-alert",
      "categories": [
        "Light",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-alert",
      "selector": "fad fa-sensor-alert",
      "categories": [
        "Duotone",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-fire",
      "selector": "fas fa-sensor-fire",
      "categories": [
        "Solid",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-fire",
      "selector": "far fa-sensor-fire",
      "categories": [
        "Regular",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-fire",
      "selector": "fal fa-sensor-fire",
      "categories": [
        "Light",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-fire",
      "selector": "fad fa-sensor-fire",
      "categories": [
        "Duotone",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-on",
      "selector": "fas fa-sensor-on",
      "categories": [
        "Solid",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-on",
      "selector": "far fa-sensor-on",
      "categories": [
        "Regular",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-on",
      "selector": "fal fa-sensor-on",
      "categories": [
        "Light",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-on",
      "selector": "fad fa-sensor-on",
      "categories": [
        "Duotone",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-smoke",
      "selector": "fas fa-sensor-smoke",
      "categories": [
        "Solid",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-smoke",
      "selector": "far fa-sensor-smoke",
      "categories": [
        "Regular",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-smoke",
      "selector": "fal fa-sensor-smoke",
      "categories": [
        "Light",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "sensor-smoke",
      "selector": "fad fa-sensor-smoke",
      "categories": [
        "Duotone",
        "Alert",
        "Household"
      ]
    },
    {
      "name": "server",
      "selector": "fas fa-server",
      "categories": [
        "Solid",
        "Computers"
      ]
    },
    {
      "name": "server",
      "selector": "far fa-server",
      "categories": [
        "Regular",
        "Computers"
      ]
    },
    {
      "name": "server",
      "selector": "fal fa-server",
      "categories": [
        "Light",
        "Computers"
      ]
    },
    {
      "name": "server",
      "selector": "fad fa-server",
      "categories": [
        "Duotone",
        "Computers"
      ]
    },
    {
      "name": "servicestack",
      "selector": "fab fa-servicestack",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "shapes",
      "selector": "fas fa-shapes",
      "categories": [
        "Solid",
        "Childhood",
        "Education",
        "Shapes"
      ]
    },
    {
      "name": "shapes",
      "selector": "far fa-shapes",
      "categories": [
        "Regular",
        "Childhood",
        "Education",
        "Shapes"
      ]
    },
    {
      "name": "shapes",
      "selector": "fal fa-shapes",
      "categories": [
        "Light",
        "Childhood",
        "Education",
        "Shapes"
      ]
    },
    {
      "name": "shapes",
      "selector": "fad fa-shapes",
      "categories": [
        "Duotone",
        "Childhood",
        "Education",
        "Shapes"
      ]
    },
    {
      "name": "share",
      "selector": "fas fa-share",
      "categories": [
        "Solid",
        "Arrows",
        "Editors",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share",
      "selector": "far fa-share",
      "categories": [
        "Regular",
        "Arrows",
        "Editors",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share",
      "selector": "fal fa-share",
      "categories": [
        "Light",
        "Arrows",
        "Editors",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share",
      "selector": "fad fa-share",
      "categories": [
        "Duotone",
        "Arrows",
        "Editors",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-all",
      "selector": "fas fa-share-all",
      "categories": [
        "Solid",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "share-all",
      "selector": "far fa-share-all",
      "categories": [
        "Regular",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "share-all",
      "selector": "fal fa-share-all",
      "categories": [
        "Light",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "share-all",
      "selector": "fad fa-share-all",
      "categories": [
        "Duotone",
        "Arrows",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "share-alt",
      "selector": "fas fa-share-alt",
      "categories": [
        "Solid",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-alt",
      "selector": "far fa-share-alt",
      "categories": [
        "Regular",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-alt",
      "selector": "fal fa-share-alt",
      "categories": [
        "Light",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-alt",
      "selector": "fad fa-share-alt",
      "categories": [
        "Duotone",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-alt-square",
      "selector": "fas fa-share-alt-square",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "share-alt-square",
      "selector": "far fa-share-alt-square",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "share-alt-square",
      "selector": "fal fa-share-alt-square",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "share-alt-square",
      "selector": "fad fa-share-alt-square",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "share-square",
      "selector": "fas fa-share-square",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-square",
      "selector": "far fa-share-square",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-square",
      "selector": "fal fa-share-square",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "share-square",
      "selector": "fad fa-share-square",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces",
        "Social"
      ]
    },
    {
      "name": "sheep",
      "selector": "fas fa-sheep",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "sheep",
      "selector": "far fa-sheep",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "sheep",
      "selector": "fal fa-sheep",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "sheep",
      "selector": "fad fa-sheep",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "shekel-sign",
      "selector": "fas fa-shekel-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "shekel-sign",
      "selector": "far fa-shekel-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "shekel-sign",
      "selector": "fal fa-shekel-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "shekel-sign",
      "selector": "fad fa-shekel-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "shield",
      "selector": "fas fa-shield",
      "categories": [
        "Solid",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "shield",
      "selector": "far fa-shield",
      "categories": [
        "Regular",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "shield",
      "selector": "fal fa-shield",
      "categories": [
        "Light",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "shield",
      "selector": "fad fa-shield",
      "categories": [
        "Duotone",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Shapes",
        "Status"
      ]
    },
    {
      "name": "shield-alt",
      "selector": "fas fa-shield-alt",
      "categories": [
        "Solid",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-alt",
      "selector": "far fa-shield-alt",
      "categories": [
        "Regular",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-alt",
      "selector": "fal fa-shield-alt",
      "categories": [
        "Light",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-alt",
      "selector": "fad fa-shield-alt",
      "categories": [
        "Duotone",
        "Code",
        "Interfaces",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-check",
      "selector": "fas fa-shield-check",
      "categories": [
        "Solid",
        "Code",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-check",
      "selector": "far fa-shield-check",
      "categories": [
        "Regular",
        "Code",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-check",
      "selector": "fal fa-shield-check",
      "categories": [
        "Light",
        "Code",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-check",
      "selector": "fad fa-shield-check",
      "categories": [
        "Duotone",
        "Code",
        "Security",
        "Status"
      ]
    },
    {
      "name": "shield-cross",
      "selector": "fas fa-shield-cross",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "shield-cross",
      "selector": "far fa-shield-cross",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "shield-cross",
      "selector": "fal fa-shield-cross",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "shield-cross",
      "selector": "fad fa-shield-cross",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "ship",
      "selector": "fas fa-ship",
      "categories": [
        "Solid",
        "Maps",
        "Maritime",
        "Vehicles"
      ]
    },
    {
      "name": "ship",
      "selector": "far fa-ship",
      "categories": [
        "Regular",
        "Maps",
        "Maritime",
        "Vehicles"
      ]
    },
    {
      "name": "ship",
      "selector": "fal fa-ship",
      "categories": [
        "Light",
        "Maps",
        "Maritime",
        "Vehicles"
      ]
    },
    {
      "name": "ship",
      "selector": "fad fa-ship",
      "categories": [
        "Duotone",
        "Maps",
        "Maritime",
        "Vehicles"
      ]
    },
    {
      "name": "shipping-fast",
      "selector": "fas fa-shipping-fast",
      "categories": [
        "Solid",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-fast",
      "selector": "far fa-shipping-fast",
      "categories": [
        "Regular",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-fast",
      "selector": "fal fa-shipping-fast",
      "categories": [
        "Light",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-fast",
      "selector": "fad fa-shipping-fast",
      "categories": [
        "Duotone",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-timed",
      "selector": "fas fa-shipping-timed",
      "categories": [
        "Solid",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-timed",
      "selector": "far fa-shipping-timed",
      "categories": [
        "Regular",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-timed",
      "selector": "fal fa-shipping-timed",
      "categories": [
        "Light",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shipping-timed",
      "selector": "fad fa-shipping-timed",
      "categories": [
        "Duotone",
        "Logistics",
        "Shopping"
      ]
    },
    {
      "name": "shirtsinbulk",
      "selector": "fab fa-shirtsinbulk",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "shish-kebab",
      "selector": "fas fa-shish-kebab",
      "categories": [
        "Solid",
        "Camping",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "shish-kebab",
      "selector": "far fa-shish-kebab",
      "categories": [
        "Regular",
        "Camping",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "shish-kebab",
      "selector": "fal fa-shish-kebab",
      "categories": [
        "Light",
        "Camping",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "shish-kebab",
      "selector": "fad fa-shish-kebab",
      "categories": [
        "Duotone",
        "Camping",
        "Food",
        "Summer"
      ]
    },
    {
      "name": "shoe-prints",
      "selector": "fas fa-shoe-prints",
      "categories": [
        "Solid",
        "Clothing",
        "Fitness",
        "Maps"
      ]
    },
    {
      "name": "shoe-prints",
      "selector": "far fa-shoe-prints",
      "categories": [
        "Regular",
        "Clothing",
        "Fitness",
        "Maps"
      ]
    },
    {
      "name": "shoe-prints",
      "selector": "fal fa-shoe-prints",
      "categories": [
        "Light",
        "Clothing",
        "Fitness",
        "Maps"
      ]
    },
    {
      "name": "shoe-prints",
      "selector": "fad fa-shoe-prints",
      "categories": [
        "Duotone",
        "Clothing",
        "Fitness",
        "Maps"
      ]
    },
    {
      "name": "shopping-bag",
      "selector": "fas fa-shopping-bag",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-bag",
      "selector": "far fa-shopping-bag",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-bag",
      "selector": "fal fa-shopping-bag",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-bag",
      "selector": "fad fa-shopping-bag",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-basket",
      "selector": "fas fa-shopping-basket",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-basket",
      "selector": "far fa-shopping-basket",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-basket",
      "selector": "fal fa-shopping-basket",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-basket",
      "selector": "fad fa-shopping-basket",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping"
      ]
    },
    {
      "name": "shopping-cart",
      "selector": "fas fa-shopping-cart",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping",
        "Status",
        "Vehicles"
      ]
    },
    {
      "name": "shopping-cart",
      "selector": "far fa-shopping-cart",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping",
        "Status",
        "Vehicles"
      ]
    },
    {
      "name": "shopping-cart",
      "selector": "fal fa-shopping-cart",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping",
        "Status",
        "Vehicles"
      ]
    },
    {
      "name": "shopping-cart",
      "selector": "fad fa-shopping-cart",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Payments & Shopping",
        "Shopping",
        "Status",
        "Vehicles"
      ]
    },
    {
      "name": "shopware",
      "selector": "fab fa-shopware",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "shovel",
      "selector": "fas fa-shovel",
      "categories": [
        "Solid",
        "Autumn",
        "Camping",
        "Construction",
        "Spring"
      ]
    },
    {
      "name": "shovel",
      "selector": "far fa-shovel",
      "categories": [
        "Regular",
        "Autumn",
        "Camping",
        "Construction",
        "Spring"
      ]
    },
    {
      "name": "shovel",
      "selector": "fal fa-shovel",
      "categories": [
        "Light",
        "Autumn",
        "Camping",
        "Construction",
        "Spring"
      ]
    },
    {
      "name": "shovel",
      "selector": "fad fa-shovel",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping",
        "Construction",
        "Spring"
      ]
    },
    {
      "name": "shovel-snow",
      "selector": "fas fa-shovel-snow",
      "categories": [
        "Solid",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "shovel-snow",
      "selector": "far fa-shovel-snow",
      "categories": [
        "Regular",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "shovel-snow",
      "selector": "fal fa-shovel-snow",
      "categories": [
        "Light",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "shovel-snow",
      "selector": "fad fa-shovel-snow",
      "categories": [
        "Duotone",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "shower",
      "selector": "fas fa-shower",
      "categories": [
        "Solid",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "shower",
      "selector": "far fa-shower",
      "categories": [
        "Regular",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "shower",
      "selector": "fal fa-shower",
      "categories": [
        "Light",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "shower",
      "selector": "fad fa-shower",
      "categories": [
        "Duotone",
        "Hotel",
        "Household",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "shredder",
      "selector": "fas fa-shredder",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "shredder",
      "selector": "far fa-shredder",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "shredder",
      "selector": "fal fa-shredder",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "shredder",
      "selector": "fad fa-shredder",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "shuttle-van",
      "selector": "fas fa-shuttle-van",
      "categories": [
        "Solid",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "shuttle-van",
      "selector": "far fa-shuttle-van",
      "categories": [
        "Regular",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "shuttle-van",
      "selector": "fal fa-shuttle-van",
      "categories": [
        "Light",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "shuttle-van",
      "selector": "fad fa-shuttle-van",
      "categories": [
        "Duotone",
        "Automotive",
        "Hotel",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "shuttlecock",
      "selector": "fas fa-shuttlecock",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "shuttlecock",
      "selector": "far fa-shuttlecock",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "shuttlecock",
      "selector": "fal fa-shuttlecock",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "shuttlecock",
      "selector": "fad fa-shuttlecock",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "sickle",
      "selector": "fas fa-sickle",
      "categories": [
        "Solid",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sickle",
      "selector": "far fa-sickle",
      "categories": [
        "Regular",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sickle",
      "selector": "fal fa-sickle",
      "categories": [
        "Light",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sickle",
      "selector": "fad fa-sickle",
      "categories": [
        "Duotone",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sigma",
      "selector": "fas fa-sigma",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "sigma",
      "selector": "far fa-sigma",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "sigma",
      "selector": "fal fa-sigma",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "sigma",
      "selector": "fad fa-sigma",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "sign",
      "selector": "fas fa-sign",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "sign",
      "selector": "far fa-sign",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "sign",
      "selector": "fal fa-sign",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "sign",
      "selector": "fad fa-sign",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "sign-in",
      "selector": "fas fa-sign-in",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in",
      "selector": "far fa-sign-in",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in",
      "selector": "fal fa-sign-in",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in",
      "selector": "fad fa-sign-in",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in-alt",
      "selector": "fas fa-sign-in-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in-alt",
      "selector": "far fa-sign-in-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in-alt",
      "selector": "fal fa-sign-in-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-in-alt",
      "selector": "fad fa-sign-in-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-language",
      "selector": "fas fa-sign-language",
      "categories": [
        "Solid",
        "Accessibility"
      ]
    },
    {
      "name": "sign-language",
      "selector": "far fa-sign-language",
      "categories": [
        "Regular",
        "Accessibility"
      ]
    },
    {
      "name": "sign-language",
      "selector": "fal fa-sign-language",
      "categories": [
        "Light",
        "Accessibility"
      ]
    },
    {
      "name": "sign-language",
      "selector": "fad fa-sign-language",
      "categories": [
        "Duotone",
        "Accessibility"
      ]
    },
    {
      "name": "sign-out",
      "selector": "fas fa-sign-out",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out",
      "selector": "far fa-sign-out",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out",
      "selector": "fal fa-sign-out",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out",
      "selector": "fad fa-sign-out",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out-alt",
      "selector": "fas fa-sign-out-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out-alt",
      "selector": "far fa-sign-out-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out-alt",
      "selector": "fal fa-sign-out-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "sign-out-alt",
      "selector": "fad fa-sign-out-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal",
      "selector": "fas fa-signal",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal",
      "selector": "far fa-signal",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal",
      "selector": "fal fa-signal",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal",
      "selector": "fad fa-signal",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-1",
      "selector": "fas fa-signal-1",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-1",
      "selector": "far fa-signal-1",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-1",
      "selector": "fal fa-signal-1",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-1",
      "selector": "fad fa-signal-1",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-2",
      "selector": "fas fa-signal-2",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-2",
      "selector": "far fa-signal-2",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-2",
      "selector": "fal fa-signal-2",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-2",
      "selector": "fad fa-signal-2",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-3",
      "selector": "fas fa-signal-3",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-3",
      "selector": "far fa-signal-3",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-3",
      "selector": "fal fa-signal-3",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-3",
      "selector": "fad fa-signal-3",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-4",
      "selector": "fas fa-signal-4",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-4",
      "selector": "far fa-signal-4",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-4",
      "selector": "fal fa-signal-4",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-4",
      "selector": "fad fa-signal-4",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt",
      "selector": "fas fa-signal-alt",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt",
      "selector": "far fa-signal-alt",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt",
      "selector": "fal fa-signal-alt",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt",
      "selector": "fad fa-signal-alt",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-1",
      "selector": "fas fa-signal-alt-1",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-1",
      "selector": "far fa-signal-alt-1",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-1",
      "selector": "fal fa-signal-alt-1",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-1",
      "selector": "fad fa-signal-alt-1",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-2",
      "selector": "fas fa-signal-alt-2",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-2",
      "selector": "far fa-signal-alt-2",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-2",
      "selector": "fal fa-signal-alt-2",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-2",
      "selector": "fad fa-signal-alt-2",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-3",
      "selector": "fas fa-signal-alt-3",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-3",
      "selector": "far fa-signal-alt-3",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-3",
      "selector": "fal fa-signal-alt-3",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-3",
      "selector": "fad fa-signal-alt-3",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-slash",
      "selector": "fas fa-signal-alt-slash",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-slash",
      "selector": "far fa-signal-alt-slash",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-slash",
      "selector": "fal fa-signal-alt-slash",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-alt-slash",
      "selector": "fad fa-signal-alt-slash",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-slash",
      "selector": "fas fa-signal-slash",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-slash",
      "selector": "far fa-signal-slash",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-slash",
      "selector": "fal fa-signal-slash",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-slash",
      "selector": "fad fa-signal-slash",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "signal-stream",
      "selector": "fas fa-signal-stream",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Household",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "signal-stream",
      "selector": "far fa-signal-stream",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Household",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "signal-stream",
      "selector": "fal fa-signal-stream",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Household",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "signal-stream",
      "selector": "fad fa-signal-stream",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Household",
        "Marketing",
        "Social"
      ]
    },
    {
      "name": "signature",
      "selector": "fas fa-signature",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "signature",
      "selector": "far fa-signature",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "signature",
      "selector": "fal fa-signature",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "signature",
      "selector": "fad fa-signature",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "sim-card",
      "selector": "fas fa-sim-card",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "sim-card",
      "selector": "far fa-sim-card",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "sim-card",
      "selector": "fal fa-sim-card",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "sim-card",
      "selector": "fad fa-sim-card",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "simplybuilt",
      "selector": "fab fa-simplybuilt",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "siren",
      "selector": "fas fa-siren",
      "categories": [
        "Solid",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren",
      "selector": "far fa-siren",
      "categories": [
        "Regular",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren",
      "selector": "fal fa-siren",
      "categories": [
        "Light",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren",
      "selector": "fad fa-siren",
      "categories": [
        "Duotone",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren-on",
      "selector": "fas fa-siren-on",
      "categories": [
        "Solid",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren-on",
      "selector": "far fa-siren-on",
      "categories": [
        "Regular",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren-on",
      "selector": "fal fa-siren-on",
      "categories": [
        "Light",
        "Household",
        "Security"
      ]
    },
    {
      "name": "siren-on",
      "selector": "fad fa-siren-on",
      "categories": [
        "Duotone",
        "Household",
        "Security"
      ]
    },
    {
      "name": "sistrix",
      "selector": "fab fa-sistrix",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "sitemap",
      "selector": "fas fa-sitemap",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "sitemap",
      "selector": "far fa-sitemap",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "sitemap",
      "selector": "fal fa-sitemap",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "sitemap",
      "selector": "fad fa-sitemap",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Interfaces"
      ]
    },
    {
      "name": "sith",
      "selector": "fab fa-sith",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "skating",
      "selector": "fas fa-skating",
      "categories": [
        "Solid",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skating",
      "selector": "far fa-skating",
      "categories": [
        "Regular",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skating",
      "selector": "fal fa-skating",
      "categories": [
        "Light",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skating",
      "selector": "fad fa-skating",
      "categories": [
        "Duotone",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skeleton",
      "selector": "fas fa-skeleton",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "skeleton",
      "selector": "far fa-skeleton",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "skeleton",
      "selector": "fal fa-skeleton",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "skeleton",
      "selector": "fad fa-skeleton",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "sketch",
      "selector": "fab fa-sketch",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ski-jump",
      "selector": "fas fa-ski-jump",
      "categories": [
        "Solid",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "ski-jump",
      "selector": "far fa-ski-jump",
      "categories": [
        "Regular",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "ski-jump",
      "selector": "fal fa-ski-jump",
      "categories": [
        "Light",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "ski-jump",
      "selector": "fad fa-ski-jump",
      "categories": [
        "Duotone",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "ski-lift",
      "selector": "fas fa-ski-lift",
      "categories": [
        "Solid",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "ski-lift",
      "selector": "far fa-ski-lift",
      "categories": [
        "Regular",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "ski-lift",
      "selector": "fal fa-ski-lift",
      "categories": [
        "Light",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "ski-lift",
      "selector": "fad fa-ski-lift",
      "categories": [
        "Duotone",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "skiing",
      "selector": "fas fa-skiing",
      "categories": [
        "Solid",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing",
      "selector": "far fa-skiing",
      "categories": [
        "Regular",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing",
      "selector": "fal fa-skiing",
      "categories": [
        "Light",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing",
      "selector": "fad fa-skiing",
      "categories": [
        "Duotone",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing-nordic",
      "selector": "fas fa-skiing-nordic",
      "categories": [
        "Solid",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing-nordic",
      "selector": "far fa-skiing-nordic",
      "categories": [
        "Regular",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing-nordic",
      "selector": "fal fa-skiing-nordic",
      "categories": [
        "Light",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skiing-nordic",
      "selector": "fad fa-skiing-nordic",
      "categories": [
        "Duotone",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "skull",
      "selector": "fas fa-skull",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "skull",
      "selector": "far fa-skull",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "skull",
      "selector": "fal fa-skull",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "skull",
      "selector": "fad fa-skull",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "skull-cow",
      "selector": "fas fa-skull-cow",
      "categories": [
        "Solid",
        "Animals",
        "Maps"
      ]
    },
    {
      "name": "skull-cow",
      "selector": "far fa-skull-cow",
      "categories": [
        "Regular",
        "Animals",
        "Maps"
      ]
    },
    {
      "name": "skull-cow",
      "selector": "fal fa-skull-cow",
      "categories": [
        "Light",
        "Animals",
        "Maps"
      ]
    },
    {
      "name": "skull-cow",
      "selector": "fad fa-skull-cow",
      "categories": [
        "Duotone",
        "Animals",
        "Maps"
      ]
    },
    {
      "name": "skull-crossbones",
      "selector": "fas fa-skull-crossbones",
      "categories": [
        "Solid",
        "Alert",
        "Halloween",
        "Maritime",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "skull-crossbones",
      "selector": "far fa-skull-crossbones",
      "categories": [
        "Regular",
        "Alert",
        "Halloween",
        "Maritime",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "skull-crossbones",
      "selector": "fal fa-skull-crossbones",
      "categories": [
        "Light",
        "Alert",
        "Halloween",
        "Maritime",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "skull-crossbones",
      "selector": "fad fa-skull-crossbones",
      "categories": [
        "Duotone",
        "Alert",
        "Halloween",
        "Maritime",
        "Objects",
        "Pharmacy",
        "Science",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "skyatlas",
      "selector": "fab fa-skyatlas",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "skype",
      "selector": "fab fa-skype",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "slack",
      "selector": "fab fa-slack",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "slack-hash",
      "selector": "fab fa-slack-hash",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "slash",
      "selector": "fas fa-slash",
      "categories": [
        "Solid",
        "Spinners"
      ]
    },
    {
      "name": "slash",
      "selector": "far fa-slash",
      "categories": [
        "Regular",
        "Spinners"
      ]
    },
    {
      "name": "slash",
      "selector": "fal fa-slash",
      "categories": [
        "Light",
        "Spinners"
      ]
    },
    {
      "name": "slash",
      "selector": "fad fa-slash",
      "categories": [
        "Duotone",
        "Spinners"
      ]
    },
    {
      "name": "sledding",
      "selector": "fas fa-sledding",
      "categories": [
        "Solid",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "sledding",
      "selector": "far fa-sledding",
      "categories": [
        "Regular",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "sledding",
      "selector": "fal fa-sledding",
      "categories": [
        "Light",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "sledding",
      "selector": "fad fa-sledding",
      "categories": [
        "Duotone",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "sleigh",
      "selector": "fas fa-sleigh",
      "categories": [
        "Solid",
        "Holiday",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "sleigh",
      "selector": "far fa-sleigh",
      "categories": [
        "Regular",
        "Holiday",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "sleigh",
      "selector": "fal fa-sleigh",
      "categories": [
        "Light",
        "Holiday",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "sleigh",
      "selector": "fad fa-sleigh",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "sliders-h",
      "selector": "fas fa-sliders-h",
      "categories": [
        "Solid",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h",
      "selector": "far fa-sliders-h",
      "categories": [
        "Regular",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h",
      "selector": "fal fa-sliders-h",
      "categories": [
        "Light",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h",
      "selector": "fad fa-sliders-h",
      "categories": [
        "Duotone",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h-square",
      "selector": "fas fa-sliders-h-square",
      "categories": [
        "Solid",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h-square",
      "selector": "far fa-sliders-h-square",
      "categories": [
        "Regular",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h-square",
      "selector": "fal fa-sliders-h-square",
      "categories": [
        "Light",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-h-square",
      "selector": "fad fa-sliders-h-square",
      "categories": [
        "Duotone",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v",
      "selector": "fas fa-sliders-v",
      "categories": [
        "Solid",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v",
      "selector": "far fa-sliders-v",
      "categories": [
        "Regular",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v",
      "selector": "fal fa-sliders-v",
      "categories": [
        "Light",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v",
      "selector": "fad fa-sliders-v",
      "categories": [
        "Duotone",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v-square",
      "selector": "fas fa-sliders-v-square",
      "categories": [
        "Solid",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v-square",
      "selector": "far fa-sliders-v-square",
      "categories": [
        "Regular",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v-square",
      "selector": "fal fa-sliders-v-square",
      "categories": [
        "Light",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "sliders-v-square",
      "selector": "fad fa-sliders-v-square",
      "categories": [
        "Duotone",
        "Images",
        "Interfaces",
        "Music"
      ]
    },
    {
      "name": "slideshare",
      "selector": "fab fa-slideshare",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "smile",
      "selector": "fas fa-smile",
      "categories": [
        "Solid",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "smile",
      "selector": "far fa-smile",
      "categories": [
        "Regular",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "smile",
      "selector": "fal fa-smile",
      "categories": [
        "Light",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "smile",
      "selector": "fad fa-smile",
      "categories": [
        "Duotone",
        "Chat",
        "Emoji",
        "Interfaces",
        "Users & People"
      ]
    },
    {
      "name": "smile-beam",
      "selector": "fas fa-smile-beam",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "smile-beam",
      "selector": "far fa-smile-beam",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "smile-beam",
      "selector": "fal fa-smile-beam",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "smile-beam",
      "selector": "fad fa-smile-beam",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "smile-plus",
      "selector": "fas fa-smile-plus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "smile-plus",
      "selector": "far fa-smile-plus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "smile-plus",
      "selector": "fal fa-smile-plus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "smile-plus",
      "selector": "fad fa-smile-plus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "smile-wink",
      "selector": "fas fa-smile-wink",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "smile-wink",
      "selector": "far fa-smile-wink",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "smile-wink",
      "selector": "fal fa-smile-wink",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "smile-wink",
      "selector": "fad fa-smile-wink",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "smog",
      "selector": "fas fa-smog",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "smog",
      "selector": "far fa-smog",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "smog",
      "selector": "fal fa-smog",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "smog",
      "selector": "fad fa-smog",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "smoke",
      "selector": "fas fa-smoke",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "smoke",
      "selector": "far fa-smoke",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "smoke",
      "selector": "fal fa-smoke",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "smoke",
      "selector": "fad fa-smoke",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "smoking",
      "selector": "fas fa-smoking",
      "categories": [
        "Solid",
        "Hotel",
        "Medical"
      ]
    },
    {
      "name": "smoking",
      "selector": "far fa-smoking",
      "categories": [
        "Regular",
        "Hotel",
        "Medical"
      ]
    },
    {
      "name": "smoking",
      "selector": "fal fa-smoking",
      "categories": [
        "Light",
        "Hotel",
        "Medical"
      ]
    },
    {
      "name": "smoking",
      "selector": "fad fa-smoking",
      "categories": [
        "Duotone",
        "Hotel",
        "Medical"
      ]
    },
    {
      "name": "smoking-ban",
      "selector": "fas fa-smoking-ban",
      "categories": [
        "Solid",
        "Hotel",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "smoking-ban",
      "selector": "far fa-smoking-ban",
      "categories": [
        "Regular",
        "Hotel",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "smoking-ban",
      "selector": "fal fa-smoking-ban",
      "categories": [
        "Light",
        "Hotel",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "smoking-ban",
      "selector": "fad fa-smoking-ban",
      "categories": [
        "Duotone",
        "Hotel",
        "Medical",
        "Status"
      ]
    },
    {
      "name": "sms",
      "selector": "fas fa-sms",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "sms",
      "selector": "far fa-sms",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "sms",
      "selector": "fal fa-sms",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "sms",
      "selector": "fad fa-sms",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "snake",
      "selector": "fas fa-snake",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "snake",
      "selector": "far fa-snake",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "snake",
      "selector": "fal fa-snake",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "snake",
      "selector": "fad fa-snake",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "snapchat",
      "selector": "fab fa-snapchat",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "snapchat-ghost",
      "selector": "fab fa-snapchat-ghost",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "snapchat-square",
      "selector": "fab fa-snapchat-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "snooze",
      "selector": "fas fa-snooze",
      "categories": [
        "Solid",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "snooze",
      "selector": "far fa-snooze",
      "categories": [
        "Regular",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "snooze",
      "selector": "fal fa-snooze",
      "categories": [
        "Light",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "snooze",
      "selector": "fad fa-snooze",
      "categories": [
        "Duotone",
        "Date & Time",
        "Hotel"
      ]
    },
    {
      "name": "snow-blowing",
      "selector": "fas fa-snow-blowing",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "snow-blowing",
      "selector": "far fa-snow-blowing",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "snow-blowing",
      "selector": "fal fa-snow-blowing",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "snow-blowing",
      "selector": "fad fa-snow-blowing",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "snowboarding",
      "selector": "fas fa-snowboarding",
      "categories": [
        "Solid",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "snowboarding",
      "selector": "far fa-snowboarding",
      "categories": [
        "Regular",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "snowboarding",
      "selector": "fal fa-snowboarding",
      "categories": [
        "Light",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "snowboarding",
      "selector": "fad fa-snowboarding",
      "categories": [
        "Duotone",
        "Fitness",
        "Sports",
        "Users & People",
        "Winter"
      ]
    },
    {
      "name": "snowflake",
      "selector": "fas fa-snowflake",
      "categories": [
        "Solid",
        "Hotel",
        "Household",
        "Objects",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "snowflake",
      "selector": "far fa-snowflake",
      "categories": [
        "Regular",
        "Hotel",
        "Household",
        "Objects",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "snowflake",
      "selector": "fal fa-snowflake",
      "categories": [
        "Light",
        "Hotel",
        "Household",
        "Objects",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "snowflake",
      "selector": "fad fa-snowflake",
      "categories": [
        "Duotone",
        "Hotel",
        "Household",
        "Objects",
        "Spinners",
        "Weather"
      ]
    },
    {
      "name": "snowflakes",
      "selector": "fas fa-snowflakes",
      "categories": [
        "Solid",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "snowflakes",
      "selector": "far fa-snowflakes",
      "categories": [
        "Regular",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "snowflakes",
      "selector": "fal fa-snowflakes",
      "categories": [
        "Light",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "snowflakes",
      "selector": "fad fa-snowflakes",
      "categories": [
        "Duotone",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "snowman",
      "selector": "fas fa-snowman",
      "categories": [
        "Solid",
        "Childhood",
        "Holiday"
      ]
    },
    {
      "name": "snowman",
      "selector": "far fa-snowman",
      "categories": [
        "Regular",
        "Childhood",
        "Holiday"
      ]
    },
    {
      "name": "snowman",
      "selector": "fal fa-snowman",
      "categories": [
        "Light",
        "Childhood",
        "Holiday"
      ]
    },
    {
      "name": "snowman",
      "selector": "fad fa-snowman",
      "categories": [
        "Duotone",
        "Childhood",
        "Holiday"
      ]
    },
    {
      "name": "snowmobile",
      "selector": "fas fa-snowmobile",
      "categories": [
        "Solid",
        "Objects",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowmobile",
      "selector": "far fa-snowmobile",
      "categories": [
        "Regular",
        "Objects",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowmobile",
      "selector": "fal fa-snowmobile",
      "categories": [
        "Light",
        "Objects",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowmobile",
      "selector": "fad fa-snowmobile",
      "categories": [
        "Duotone",
        "Objects",
        "Users & People",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowplow",
      "selector": "fas fa-snowplow",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowplow",
      "selector": "far fa-snowplow",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowplow",
      "selector": "fal fa-snowplow",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "snowplow",
      "selector": "fad fa-snowplow",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "socks",
      "selector": "fas fa-socks",
      "categories": [
        "Solid",
        "Business",
        "Clothing"
      ]
    },
    {
      "name": "socks",
      "selector": "far fa-socks",
      "categories": [
        "Regular",
        "Business",
        "Clothing"
      ]
    },
    {
      "name": "socks",
      "selector": "fal fa-socks",
      "categories": [
        "Light",
        "Business",
        "Clothing"
      ]
    },
    {
      "name": "socks",
      "selector": "fad fa-socks",
      "categories": [
        "Duotone",
        "Business",
        "Clothing"
      ]
    },
    {
      "name": "solar-panel",
      "selector": "fas fa-solar-panel",
      "categories": [
        "Solid",
        "Energy"
      ]
    },
    {
      "name": "solar-panel",
      "selector": "far fa-solar-panel",
      "categories": [
        "Regular",
        "Energy"
      ]
    },
    {
      "name": "solar-panel",
      "selector": "fal fa-solar-panel",
      "categories": [
        "Light",
        "Energy"
      ]
    },
    {
      "name": "solar-panel",
      "selector": "fad fa-solar-panel",
      "categories": [
        "Duotone",
        "Energy"
      ]
    },
    {
      "name": "solar-system",
      "selector": "fas fa-solar-system",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "solar-system",
      "selector": "far fa-solar-system",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "solar-system",
      "selector": "fal fa-solar-system",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "solar-system",
      "selector": "fad fa-solar-system",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "sort",
      "selector": "fas fa-sort",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort",
      "selector": "far fa-sort",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort",
      "selector": "fal fa-sort",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort",
      "selector": "fad fa-sort",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down",
      "selector": "fas fa-sort-alpha-down",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down",
      "selector": "far fa-sort-alpha-down",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down",
      "selector": "fal fa-sort-alpha-down",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down",
      "selector": "fad fa-sort-alpha-down",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down-alt",
      "selector": "fas fa-sort-alpha-down-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down-alt",
      "selector": "far fa-sort-alpha-down-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down-alt",
      "selector": "fal fa-sort-alpha-down-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-down-alt",
      "selector": "fad fa-sort-alpha-down-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up",
      "selector": "fas fa-sort-alpha-up",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up",
      "selector": "far fa-sort-alpha-up",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up",
      "selector": "fal fa-sort-alpha-up",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up",
      "selector": "fad fa-sort-alpha-up",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up-alt",
      "selector": "fas fa-sort-alpha-up-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up-alt",
      "selector": "far fa-sort-alpha-up-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up-alt",
      "selector": "fal fa-sort-alpha-up-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alpha-up-alt",
      "selector": "fad fa-sort-alpha-up-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alt",
      "selector": "fas fa-sort-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alt",
      "selector": "far fa-sort-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alt",
      "selector": "fal fa-sort-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-alt",
      "selector": "fad fa-sort-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down",
      "selector": "fas fa-sort-amount-down",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down",
      "selector": "far fa-sort-amount-down",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down",
      "selector": "fal fa-sort-amount-down",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down",
      "selector": "fad fa-sort-amount-down",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down-alt",
      "selector": "fas fa-sort-amount-down-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down-alt",
      "selector": "far fa-sort-amount-down-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down-alt",
      "selector": "fal fa-sort-amount-down-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-down-alt",
      "selector": "fad fa-sort-amount-down-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up",
      "selector": "fas fa-sort-amount-up",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up",
      "selector": "far fa-sort-amount-up",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up",
      "selector": "fal fa-sort-amount-up",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up",
      "selector": "fad fa-sort-amount-up",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up-alt",
      "selector": "fas fa-sort-amount-up-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up-alt",
      "selector": "far fa-sort-amount-up-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up-alt",
      "selector": "fal fa-sort-amount-up-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-amount-up-alt",
      "selector": "fad fa-sort-amount-up-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-circle",
      "selector": "fas fa-sort-circle",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "sort-circle",
      "selector": "far fa-sort-circle",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "sort-circle",
      "selector": "fal fa-sort-circle",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "sort-circle",
      "selector": "fad fa-sort-circle",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "sort-circle-down",
      "selector": "fas fa-sort-circle-down",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "sort-circle-down",
      "selector": "far fa-sort-circle-down",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "sort-circle-down",
      "selector": "fal fa-sort-circle-down",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "sort-circle-down",
      "selector": "fad fa-sort-circle-down",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "sort-circle-up",
      "selector": "fas fa-sort-circle-up",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "sort-circle-up",
      "selector": "far fa-sort-circle-up",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "sort-circle-up",
      "selector": "fal fa-sort-circle-up",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "sort-circle-up",
      "selector": "fad fa-sort-circle-up",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "sort-down",
      "selector": "fas fa-sort-down",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-down",
      "selector": "far fa-sort-down",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-down",
      "selector": "fal fa-sort-down",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-down",
      "selector": "fad fa-sort-down",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down",
      "selector": "fas fa-sort-numeric-down",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down",
      "selector": "far fa-sort-numeric-down",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down",
      "selector": "fal fa-sort-numeric-down",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down",
      "selector": "fad fa-sort-numeric-down",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down-alt",
      "selector": "fas fa-sort-numeric-down-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down-alt",
      "selector": "far fa-sort-numeric-down-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down-alt",
      "selector": "fal fa-sort-numeric-down-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-down-alt",
      "selector": "fad fa-sort-numeric-down-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up",
      "selector": "fas fa-sort-numeric-up",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up",
      "selector": "far fa-sort-numeric-up",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up",
      "selector": "fal fa-sort-numeric-up",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up",
      "selector": "fad fa-sort-numeric-up",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up-alt",
      "selector": "fas fa-sort-numeric-up-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up-alt",
      "selector": "far fa-sort-numeric-up-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up-alt",
      "selector": "fal fa-sort-numeric-up-alt",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-numeric-up-alt",
      "selector": "fad fa-sort-numeric-up-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-shapes-down",
      "selector": "fas fa-sort-shapes-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down",
      "selector": "far fa-sort-shapes-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down",
      "selector": "fal fa-sort-shapes-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down",
      "selector": "fad fa-sort-shapes-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down-alt",
      "selector": "fas fa-sort-shapes-down-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down-alt",
      "selector": "far fa-sort-shapes-down-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down-alt",
      "selector": "fal fa-sort-shapes-down-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-down-alt",
      "selector": "fad fa-sort-shapes-down-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up",
      "selector": "fas fa-sort-shapes-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up",
      "selector": "far fa-sort-shapes-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up",
      "selector": "fal fa-sort-shapes-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up",
      "selector": "fad fa-sort-shapes-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up-alt",
      "selector": "fas fa-sort-shapes-up-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up-alt",
      "selector": "far fa-sort-shapes-up-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up-alt",
      "selector": "fal fa-sort-shapes-up-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-shapes-up-alt",
      "selector": "fad fa-sort-shapes-up-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down",
      "selector": "fas fa-sort-size-down",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down",
      "selector": "far fa-sort-size-down",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down",
      "selector": "fal fa-sort-size-down",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down",
      "selector": "fad fa-sort-size-down",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down-alt",
      "selector": "fas fa-sort-size-down-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down-alt",
      "selector": "far fa-sort-size-down-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down-alt",
      "selector": "fal fa-sort-size-down-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-down-alt",
      "selector": "fad fa-sort-size-down-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up",
      "selector": "fas fa-sort-size-up",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up",
      "selector": "far fa-sort-size-up",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up",
      "selector": "fal fa-sort-size-up",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up",
      "selector": "fad fa-sort-size-up",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up-alt",
      "selector": "fas fa-sort-size-up-alt",
      "categories": [
        "Solid",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up-alt",
      "selector": "far fa-sort-size-up-alt",
      "categories": [
        "Regular",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up-alt",
      "selector": "fal fa-sort-size-up-alt",
      "categories": [
        "Light",
        "Arrows"
      ]
    },
    {
      "name": "sort-size-up-alt",
      "selector": "fad fa-sort-size-up-alt",
      "categories": [
        "Duotone",
        "Arrows"
      ]
    },
    {
      "name": "sort-up",
      "selector": "fas fa-sort-up",
      "categories": [
        "Solid",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-up",
      "selector": "far fa-sort-up",
      "categories": [
        "Regular",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-up",
      "selector": "fal fa-sort-up",
      "categories": [
        "Light",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "sort-up",
      "selector": "fad fa-sort-up",
      "categories": [
        "Duotone",
        "Arrows",
        "Interfaces"
      ]
    },
    {
      "name": "soundcloud",
      "selector": "fab fa-soundcloud",
      "categories": [
        "Brands",
        "Music"
      ]
    },
    {
      "name": "soup",
      "selector": "fas fa-soup",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "soup",
      "selector": "far fa-soup",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "soup",
      "selector": "fal fa-soup",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "soup",
      "selector": "fad fa-soup",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "sourcetree",
      "selector": "fab fa-sourcetree",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "spa",
      "selector": "fas fa-spa",
      "categories": [
        "Solid",
        "Fitness",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "spa",
      "selector": "far fa-spa",
      "categories": [
        "Regular",
        "Fitness",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "spa",
      "selector": "fal fa-spa",
      "categories": [
        "Light",
        "Fitness",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "spa",
      "selector": "fad fa-spa",
      "categories": [
        "Duotone",
        "Fitness",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "space-shuttle",
      "selector": "fas fa-space-shuttle",
      "categories": [
        "Solid",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "space-shuttle",
      "selector": "far fa-space-shuttle",
      "categories": [
        "Regular",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "space-shuttle",
      "selector": "fal fa-space-shuttle",
      "categories": [
        "Light",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "space-shuttle",
      "selector": "fad fa-space-shuttle",
      "categories": [
        "Duotone",
        "Objects",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "space-station-moon",
      "selector": "fas fa-space-station-moon",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon",
      "selector": "far fa-space-station-moon",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon",
      "selector": "fal fa-space-station-moon",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon",
      "selector": "fad fa-space-station-moon",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon-alt",
      "selector": "fas fa-space-station-moon-alt",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon-alt",
      "selector": "far fa-space-station-moon-alt",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon-alt",
      "selector": "fal fa-space-station-moon-alt",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "space-station-moon-alt",
      "selector": "fad fa-space-station-moon-alt",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "spade",
      "selector": "fas fa-spade",
      "categories": [
        "Solid",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "spade",
      "selector": "far fa-spade",
      "categories": [
        "Regular",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "spade",
      "selector": "fal fa-spade",
      "categories": [
        "Light",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "spade",
      "selector": "fad fa-spade",
      "categories": [
        "Duotone",
        "Games",
        "Shapes"
      ]
    },
    {
      "name": "sparkles",
      "selector": "fas fa-sparkles",
      "categories": [
        "Solid",
        "Halloween",
        "Marketing",
        "Social",
        "Tabletop Gaming",
        "Weather"
      ]
    },
    {
      "name": "sparkles",
      "selector": "far fa-sparkles",
      "categories": [
        "Regular",
        "Halloween",
        "Marketing",
        "Social",
        "Tabletop Gaming",
        "Weather"
      ]
    },
    {
      "name": "sparkles",
      "selector": "fal fa-sparkles",
      "categories": [
        "Light",
        "Halloween",
        "Marketing",
        "Social",
        "Tabletop Gaming",
        "Weather"
      ]
    },
    {
      "name": "sparkles",
      "selector": "fad fa-sparkles",
      "categories": [
        "Duotone",
        "Halloween",
        "Marketing",
        "Social",
        "Tabletop Gaming",
        "Weather"
      ]
    },
    {
      "name": "speakap",
      "selector": "fab fa-speakap",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "speaker",
      "selector": "fas fa-speaker",
      "categories": [
        "Solid",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speaker",
      "selector": "far fa-speaker",
      "categories": [
        "Regular",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speaker",
      "selector": "fal fa-speaker",
      "categories": [
        "Light",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speaker",
      "selector": "fad fa-speaker",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speaker-deck",
      "selector": "fab fa-speaker-deck",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "speakers",
      "selector": "fas fa-speakers",
      "categories": [
        "Solid",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speakers",
      "selector": "far fa-speakers",
      "categories": [
        "Regular",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speakers",
      "selector": "fal fa-speakers",
      "categories": [
        "Light",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "speakers",
      "selector": "fad fa-speakers",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Computers",
        "Household",
        "Music"
      ]
    },
    {
      "name": "spell-check",
      "selector": "fas fa-spell-check",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "spell-check",
      "selector": "far fa-spell-check",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "spell-check",
      "selector": "fal fa-spell-check",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "spell-check",
      "selector": "fad fa-spell-check",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "spider",
      "selector": "fas fa-spider",
      "categories": [
        "Solid",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider",
      "selector": "far fa-spider",
      "categories": [
        "Regular",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider",
      "selector": "fal fa-spider",
      "categories": [
        "Light",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider",
      "selector": "fad fa-spider",
      "categories": [
        "Duotone",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider-black-widow",
      "selector": "fas fa-spider-black-widow",
      "categories": [
        "Solid",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider-black-widow",
      "selector": "far fa-spider-black-widow",
      "categories": [
        "Regular",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider-black-widow",
      "selector": "fal fa-spider-black-widow",
      "categories": [
        "Light",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider-black-widow",
      "selector": "fad fa-spider-black-widow",
      "categories": [
        "Duotone",
        "Animals",
        "Halloween"
      ]
    },
    {
      "name": "spider-web",
      "selector": "fas fa-spider-web",
      "categories": [
        "Solid",
        "Halloween",
        "Spinners"
      ]
    },
    {
      "name": "spider-web",
      "selector": "far fa-spider-web",
      "categories": [
        "Regular",
        "Halloween",
        "Spinners"
      ]
    },
    {
      "name": "spider-web",
      "selector": "fal fa-spider-web",
      "categories": [
        "Light",
        "Halloween",
        "Spinners"
      ]
    },
    {
      "name": "spider-web",
      "selector": "fad fa-spider-web",
      "categories": [
        "Duotone",
        "Halloween",
        "Spinners"
      ]
    },
    {
      "name": "spinner",
      "selector": "fas fa-spinner",
      "categories": [
        "Solid",
        "Spinners"
      ]
    },
    {
      "name": "spinner",
      "selector": "far fa-spinner",
      "categories": [
        "Regular",
        "Spinners"
      ]
    },
    {
      "name": "spinner",
      "selector": "fal fa-spinner",
      "categories": [
        "Light",
        "Spinners"
      ]
    },
    {
      "name": "spinner",
      "selector": "fad fa-spinner",
      "categories": [
        "Duotone",
        "Spinners"
      ]
    },
    {
      "name": "spinner-third",
      "selector": "fas fa-spinner-third",
      "categories": [
        "Solid",
        "Spinners"
      ]
    },
    {
      "name": "spinner-third",
      "selector": "far fa-spinner-third",
      "categories": [
        "Regular",
        "Spinners"
      ]
    },
    {
      "name": "spinner-third",
      "selector": "fal fa-spinner-third",
      "categories": [
        "Light",
        "Spinners"
      ]
    },
    {
      "name": "spinner-third",
      "selector": "fad fa-spinner-third",
      "categories": [
        "Duotone",
        "Spinners"
      ]
    },
    {
      "name": "splotch",
      "selector": "fas fa-splotch",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "splotch",
      "selector": "far fa-splotch",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "splotch",
      "selector": "fal fa-splotch",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "splotch",
      "selector": "fad fa-splotch",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "spotify",
      "selector": "fab fa-spotify",
      "categories": [
        "Brands",
        "Music"
      ]
    },
    {
      "name": "spray-can",
      "selector": "fas fa-spray-can",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "spray-can",
      "selector": "far fa-spray-can",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "spray-can",
      "selector": "fal fa-spray-can",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "spray-can",
      "selector": "fad fa-spray-can",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "sprinkler",
      "selector": "fas fa-sprinkler",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "sprinkler",
      "selector": "far fa-sprinkler",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "sprinkler",
      "selector": "fal fa-sprinkler",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "sprinkler",
      "selector": "fad fa-sprinkler",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "square",
      "selector": "fas fa-square",
      "categories": [
        "Solid",
        "Shapes"
      ]
    },
    {
      "name": "square",
      "selector": "far fa-square",
      "categories": [
        "Regular",
        "Shapes"
      ]
    },
    {
      "name": "square",
      "selector": "fal fa-square",
      "categories": [
        "Light",
        "Shapes"
      ]
    },
    {
      "name": "square",
      "selector": "fad fa-square",
      "categories": [
        "Duotone",
        "Shapes"
      ]
    },
    {
      "name": "square-full",
      "selector": "fas fa-square-full",
      "categories": [
        "Solid",
        "Chess"
      ]
    },
    {
      "name": "square-full",
      "selector": "far fa-square-full",
      "categories": [
        "Regular",
        "Chess"
      ]
    },
    {
      "name": "square-full",
      "selector": "fal fa-square-full",
      "categories": [
        "Light",
        "Chess"
      ]
    },
    {
      "name": "square-full",
      "selector": "fad fa-square-full",
      "categories": [
        "Duotone",
        "Chess"
      ]
    },
    {
      "name": "square-root",
      "selector": "fas fa-square-root",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "square-root",
      "selector": "far fa-square-root",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "square-root",
      "selector": "fal fa-square-root",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "square-root",
      "selector": "fad fa-square-root",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "square-root-alt",
      "selector": "fas fa-square-root-alt",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "square-root-alt",
      "selector": "far fa-square-root-alt",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "square-root-alt",
      "selector": "fal fa-square-root-alt",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "square-root-alt",
      "selector": "fad fa-square-root-alt",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "squarespace",
      "selector": "fab fa-squarespace",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "squirrel",
      "selector": "fas fa-squirrel",
      "categories": [
        "Solid",
        "Animals",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "squirrel",
      "selector": "far fa-squirrel",
      "categories": [
        "Regular",
        "Animals",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "squirrel",
      "selector": "fal fa-squirrel",
      "categories": [
        "Light",
        "Animals",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "squirrel",
      "selector": "fad fa-squirrel",
      "categories": [
        "Duotone",
        "Animals",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "stack-exchange",
      "selector": "fab fa-stack-exchange",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "stack-overflow",
      "selector": "fab fa-stack-overflow",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "stackpath",
      "selector": "fab fa-stackpath",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "staff",
      "selector": "fas fa-staff",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "staff",
      "selector": "far fa-staff",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "staff",
      "selector": "fal fa-staff",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "staff",
      "selector": "fad fa-staff",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "stamp",
      "selector": "fas fa-stamp",
      "categories": [
        "Solid",
        "Design",
        "Finance"
      ]
    },
    {
      "name": "stamp",
      "selector": "far fa-stamp",
      "categories": [
        "Regular",
        "Design",
        "Finance"
      ]
    },
    {
      "name": "stamp",
      "selector": "fal fa-stamp",
      "categories": [
        "Light",
        "Design",
        "Finance"
      ]
    },
    {
      "name": "stamp",
      "selector": "fad fa-stamp",
      "categories": [
        "Duotone",
        "Design",
        "Finance"
      ]
    },
    {
      "name": "star",
      "selector": "fas fa-star",
      "categories": [
        "Solid",
        "Interfaces",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star",
      "selector": "far fa-star",
      "categories": [
        "Regular",
        "Interfaces",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star",
      "selector": "fal fa-star",
      "categories": [
        "Light",
        "Interfaces",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star",
      "selector": "fad fa-star",
      "categories": [
        "Duotone",
        "Interfaces",
        "Objects",
        "Payments & Shopping",
        "Shapes",
        "Social",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-and-crescent",
      "selector": "fas fa-star-and-crescent",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "star-and-crescent",
      "selector": "far fa-star-and-crescent",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "star-and-crescent",
      "selector": "fal fa-star-and-crescent",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "star-and-crescent",
      "selector": "fad fa-star-and-crescent",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "star-christmas",
      "selector": "fas fa-star-christmas",
      "categories": [
        "Solid",
        "Holiday",
        "Spinners"
      ]
    },
    {
      "name": "star-christmas",
      "selector": "far fa-star-christmas",
      "categories": [
        "Regular",
        "Holiday",
        "Spinners"
      ]
    },
    {
      "name": "star-christmas",
      "selector": "fal fa-star-christmas",
      "categories": [
        "Light",
        "Holiday",
        "Spinners"
      ]
    },
    {
      "name": "star-christmas",
      "selector": "fad fa-star-christmas",
      "categories": [
        "Duotone",
        "Holiday",
        "Spinners"
      ]
    },
    {
      "name": "star-exclamation",
      "selector": "fas fa-star-exclamation",
      "categories": [
        "Solid",
        "Alert",
        "Interfaces",
        "Payments & Shopping"
      ]
    },
    {
      "name": "star-exclamation",
      "selector": "far fa-star-exclamation",
      "categories": [
        "Regular",
        "Alert",
        "Interfaces",
        "Payments & Shopping"
      ]
    },
    {
      "name": "star-exclamation",
      "selector": "fal fa-star-exclamation",
      "categories": [
        "Light",
        "Alert",
        "Interfaces",
        "Payments & Shopping"
      ]
    },
    {
      "name": "star-exclamation",
      "selector": "fad fa-star-exclamation",
      "categories": [
        "Duotone",
        "Alert",
        "Interfaces",
        "Payments & Shopping"
      ]
    },
    {
      "name": "star-half",
      "selector": "fas fa-star-half",
      "categories": [
        "Solid",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half",
      "selector": "far fa-star-half",
      "categories": [
        "Regular",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half",
      "selector": "fal fa-star-half",
      "categories": [
        "Light",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half",
      "selector": "fad fa-star-half",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half-alt",
      "selector": "fas fa-star-half-alt",
      "categories": [
        "Solid",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half-alt",
      "selector": "far fa-star-half-alt",
      "categories": [
        "Regular",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half-alt",
      "selector": "fal fa-star-half-alt",
      "categories": [
        "Light",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-half-alt",
      "selector": "fad fa-star-half-alt",
      "categories": [
        "Duotone",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "star-of-david",
      "selector": "fas fa-star-of-david",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "star-of-david",
      "selector": "far fa-star-of-david",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "star-of-david",
      "selector": "fal fa-star-of-david",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "star-of-david",
      "selector": "fad fa-star-of-david",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "star-of-life",
      "selector": "fas fa-star-of-life",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "star-of-life",
      "selector": "far fa-star-of-life",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "star-of-life",
      "selector": "fal fa-star-of-life",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "star-of-life",
      "selector": "fad fa-star-of-life",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "star-shooting",
      "selector": "fas fa-star-shooting",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "star-shooting",
      "selector": "far fa-star-shooting",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "star-shooting",
      "selector": "fal fa-star-shooting",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "star-shooting",
      "selector": "fad fa-star-shooting",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "starfighter",
      "selector": "fas fa-starfighter",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter",
      "selector": "far fa-starfighter",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter",
      "selector": "fal fa-starfighter",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter",
      "selector": "fad fa-starfighter",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter-alt",
      "selector": "fas fa-starfighter-alt",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter-alt",
      "selector": "far fa-starfighter-alt",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter-alt",
      "selector": "fal fa-starfighter-alt",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starfighter-alt",
      "selector": "fad fa-starfighter-alt",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "stars",
      "selector": "fas fa-stars",
      "categories": [
        "Solid",
        "Maritime",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "stars",
      "selector": "far fa-stars",
      "categories": [
        "Regular",
        "Maritime",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "stars",
      "selector": "fal fa-stars",
      "categories": [
        "Light",
        "Maritime",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "stars",
      "selector": "fad fa-stars",
      "categories": [
        "Duotone",
        "Maritime",
        "Science Fiction",
        "Weather"
      ]
    },
    {
      "name": "starship",
      "selector": "fas fa-starship",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship",
      "selector": "far fa-starship",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship",
      "selector": "fal fa-starship",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship",
      "selector": "fad fa-starship",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship-freighter",
      "selector": "fas fa-starship-freighter",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship-freighter",
      "selector": "far fa-starship-freighter",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship-freighter",
      "selector": "fal fa-starship-freighter",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "starship-freighter",
      "selector": "fad fa-starship-freighter",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "staylinked",
      "selector": "fab fa-staylinked",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "steak",
      "selector": "fas fa-steak",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "steak",
      "selector": "far fa-steak",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "steak",
      "selector": "fal fa-steak",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "steak",
      "selector": "fad fa-steak",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "steam",
      "selector": "fab fa-steam",
      "categories": [
        "Brands",
        "Games"
      ]
    },
    {
      "name": "steam-square",
      "selector": "fab fa-steam-square",
      "categories": [
        "Brands",
        "Games"
      ]
    },
    {
      "name": "steam-symbol",
      "selector": "fab fa-steam-symbol",
      "categories": [
        "Brands",
        "Games"
      ]
    },
    {
      "name": "steering-wheel",
      "selector": "fas fa-steering-wheel",
      "categories": [
        "Solid",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "steering-wheel",
      "selector": "far fa-steering-wheel",
      "categories": [
        "Regular",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "steering-wheel",
      "selector": "fal fa-steering-wheel",
      "categories": [
        "Light",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "steering-wheel",
      "selector": "fad fa-steering-wheel",
      "categories": [
        "Duotone",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "step-backward",
      "selector": "fas fa-step-backward",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "step-backward",
      "selector": "far fa-step-backward",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "step-backward",
      "selector": "fal fa-step-backward",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "step-backward",
      "selector": "fad fa-step-backward",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "step-forward",
      "selector": "fas fa-step-forward",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "step-forward",
      "selector": "far fa-step-forward",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "step-forward",
      "selector": "fal fa-step-forward",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "step-forward",
      "selector": "fad fa-step-forward",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "stethoscope",
      "selector": "fas fa-stethoscope",
      "categories": [
        "Solid",
        "Health",
        "Medical"
      ]
    },
    {
      "name": "stethoscope",
      "selector": "far fa-stethoscope",
      "categories": [
        "Regular",
        "Health",
        "Medical"
      ]
    },
    {
      "name": "stethoscope",
      "selector": "fal fa-stethoscope",
      "categories": [
        "Light",
        "Health",
        "Medical"
      ]
    },
    {
      "name": "stethoscope",
      "selector": "fad fa-stethoscope",
      "categories": [
        "Duotone",
        "Health",
        "Medical"
      ]
    },
    {
      "name": "sticker-mule",
      "selector": "fab fa-sticker-mule",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "sticky-note",
      "selector": "fas fa-sticky-note",
      "categories": [
        "Solid",
        "Business",
        "Files",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "sticky-note",
      "selector": "far fa-sticky-note",
      "categories": [
        "Regular",
        "Business",
        "Files",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "sticky-note",
      "selector": "fal fa-sticky-note",
      "categories": [
        "Light",
        "Business",
        "Files",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "sticky-note",
      "selector": "fad fa-sticky-note",
      "categories": [
        "Duotone",
        "Business",
        "Files",
        "Objects",
        "Writing"
      ]
    },
    {
      "name": "stocking",
      "selector": "fas fa-stocking",
      "categories": [
        "Solid",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "stocking",
      "selector": "far fa-stocking",
      "categories": [
        "Regular",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "stocking",
      "selector": "fal fa-stocking",
      "categories": [
        "Light",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "stocking",
      "selector": "fad fa-stocking",
      "categories": [
        "Duotone",
        "Clothing",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "stomach",
      "selector": "fas fa-stomach",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "stomach",
      "selector": "far fa-stomach",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "stomach",
      "selector": "fal fa-stomach",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "stomach",
      "selector": "fad fa-stomach",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "stop",
      "selector": "fas fa-stop",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "stop",
      "selector": "far fa-stop",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "stop",
      "selector": "fal fa-stop",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "stop",
      "selector": "fad fa-stop",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "stop-circle",
      "selector": "fas fa-stop-circle",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "stop-circle",
      "selector": "far fa-stop-circle",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "stop-circle",
      "selector": "fal fa-stop-circle",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "stop-circle",
      "selector": "fad fa-stop-circle",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "stopwatch",
      "selector": "fas fa-stopwatch",
      "categories": [
        "Solid",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "stopwatch",
      "selector": "far fa-stopwatch",
      "categories": [
        "Regular",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "stopwatch",
      "selector": "fal fa-stopwatch",
      "categories": [
        "Light",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "stopwatch",
      "selector": "fad fa-stopwatch",
      "categories": [
        "Duotone",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "store",
      "selector": "fas fa-store",
      "categories": [
        "Solid",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store",
      "selector": "far fa-store",
      "categories": [
        "Regular",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store",
      "selector": "fal fa-store",
      "categories": [
        "Light",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store",
      "selector": "fad fa-store",
      "categories": [
        "Duotone",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store-alt",
      "selector": "fas fa-store-alt",
      "categories": [
        "Solid",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store-alt",
      "selector": "far fa-store-alt",
      "categories": [
        "Regular",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store-alt",
      "selector": "fal fa-store-alt",
      "categories": [
        "Light",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "store-alt",
      "selector": "fad fa-store-alt",
      "categories": [
        "Duotone",
        "Buildings",
        "Shopping"
      ]
    },
    {
      "name": "strava",
      "selector": "fab fa-strava",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "stream",
      "selector": "fas fa-stream",
      "categories": [
        "Solid",
        "Business",
        "Code",
        "Computers",
        "Status"
      ]
    },
    {
      "name": "stream",
      "selector": "far fa-stream",
      "categories": [
        "Regular",
        "Business",
        "Code",
        "Computers",
        "Status"
      ]
    },
    {
      "name": "stream",
      "selector": "fal fa-stream",
      "categories": [
        "Light",
        "Business",
        "Code",
        "Computers",
        "Status"
      ]
    },
    {
      "name": "stream",
      "selector": "fad fa-stream",
      "categories": [
        "Duotone",
        "Business",
        "Code",
        "Computers",
        "Status"
      ]
    },
    {
      "name": "street-view",
      "selector": "fas fa-street-view",
      "categories": [
        "Solid",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "street-view",
      "selector": "far fa-street-view",
      "categories": [
        "Regular",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "street-view",
      "selector": "fal fa-street-view",
      "categories": [
        "Light",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "street-view",
      "selector": "fad fa-street-view",
      "categories": [
        "Duotone",
        "Maps",
        "Users & People"
      ]
    },
    {
      "name": "stretcher",
      "selector": "fas fa-stretcher",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "stretcher",
      "selector": "far fa-stretcher",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "stretcher",
      "selector": "fal fa-stretcher",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "stretcher",
      "selector": "fad fa-stretcher",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "strikethrough",
      "selector": "fas fa-strikethrough",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "strikethrough",
      "selector": "far fa-strikethrough",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "strikethrough",
      "selector": "fal fa-strikethrough",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "strikethrough",
      "selector": "fad fa-strikethrough",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "stripe",
      "selector": "fab fa-stripe",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "stripe-s",
      "selector": "fab fa-stripe-s",
      "categories": [
        "Brands",
        "Payments & Shopping"
      ]
    },
    {
      "name": "stroopwafel",
      "selector": "fas fa-stroopwafel",
      "categories": [
        "Solid",
        "Food",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "stroopwafel",
      "selector": "far fa-stroopwafel",
      "categories": [
        "Regular",
        "Food",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "stroopwafel",
      "selector": "fal fa-stroopwafel",
      "categories": [
        "Light",
        "Food",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "stroopwafel",
      "selector": "fad fa-stroopwafel",
      "categories": [
        "Duotone",
        "Food",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "studiovinari",
      "selector": "fab fa-studiovinari",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "stumbleupon",
      "selector": "fab fa-stumbleupon",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "stumbleupon-circle",
      "selector": "fab fa-stumbleupon-circle",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "subscript",
      "selector": "fas fa-subscript",
      "categories": [
        "Solid",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "subscript",
      "selector": "far fa-subscript",
      "categories": [
        "Regular",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "subscript",
      "selector": "fal fa-subscript",
      "categories": [
        "Light",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "subscript",
      "selector": "fad fa-subscript",
      "categories": [
        "Duotone",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "subway",
      "selector": "fas fa-subway",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "subway",
      "selector": "far fa-subway",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "subway",
      "selector": "fal fa-subway",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "subway",
      "selector": "fad fa-subway",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "suitcase",
      "selector": "fas fa-suitcase",
      "categories": [
        "Solid",
        "Hotel",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "suitcase",
      "selector": "far fa-suitcase",
      "categories": [
        "Regular",
        "Hotel",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "suitcase",
      "selector": "fal fa-suitcase",
      "categories": [
        "Light",
        "Hotel",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "suitcase",
      "selector": "fad fa-suitcase",
      "categories": [
        "Duotone",
        "Hotel",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "suitcase-rolling",
      "selector": "fas fa-suitcase-rolling",
      "categories": [
        "Solid",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "suitcase-rolling",
      "selector": "far fa-suitcase-rolling",
      "categories": [
        "Regular",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "suitcase-rolling",
      "selector": "fal fa-suitcase-rolling",
      "categories": [
        "Light",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "suitcase-rolling",
      "selector": "fad fa-suitcase-rolling",
      "categories": [
        "Duotone",
        "Hotel",
        "Travel"
      ]
    },
    {
      "name": "sun",
      "selector": "fas fa-sun",
      "categories": [
        "Solid",
        "Energy",
        "Objects",
        "Spinners",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "sun",
      "selector": "far fa-sun",
      "categories": [
        "Regular",
        "Energy",
        "Objects",
        "Spinners",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "sun",
      "selector": "fal fa-sun",
      "categories": [
        "Light",
        "Energy",
        "Objects",
        "Spinners",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "sun",
      "selector": "fad fa-sun",
      "categories": [
        "Duotone",
        "Energy",
        "Objects",
        "Spinners",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "sun-cloud",
      "selector": "fas fa-sun-cloud",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "sun-cloud",
      "selector": "far fa-sun-cloud",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "sun-cloud",
      "selector": "fal fa-sun-cloud",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "sun-cloud",
      "selector": "fad fa-sun-cloud",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "sun-dust",
      "selector": "fas fa-sun-dust",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "sun-dust",
      "selector": "far fa-sun-dust",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "sun-dust",
      "selector": "fal fa-sun-dust",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "sun-dust",
      "selector": "fad fa-sun-dust",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "sun-haze",
      "selector": "fas fa-sun-haze",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "sun-haze",
      "selector": "far fa-sun-haze",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "sun-haze",
      "selector": "fal fa-sun-haze",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "sun-haze",
      "selector": "fad fa-sun-haze",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "sunglasses",
      "selector": "fas fa-sunglasses",
      "categories": [
        "Solid",
        "Spring",
        "Summer"
      ]
    },
    {
      "name": "sunglasses",
      "selector": "far fa-sunglasses",
      "categories": [
        "Regular",
        "Spring",
        "Summer"
      ]
    },
    {
      "name": "sunglasses",
      "selector": "fal fa-sunglasses",
      "categories": [
        "Light",
        "Spring",
        "Summer"
      ]
    },
    {
      "name": "sunglasses",
      "selector": "fad fa-sunglasses",
      "categories": [
        "Duotone",
        "Spring",
        "Summer"
      ]
    },
    {
      "name": "sunrise",
      "selector": "fas fa-sunrise",
      "categories": [
        "Solid",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunrise",
      "selector": "far fa-sunrise",
      "categories": [
        "Regular",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunrise",
      "selector": "fal fa-sunrise",
      "categories": [
        "Light",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunrise",
      "selector": "fad fa-sunrise",
      "categories": [
        "Duotone",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunset",
      "selector": "fas fa-sunset",
      "categories": [
        "Solid",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunset",
      "selector": "far fa-sunset",
      "categories": [
        "Regular",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunset",
      "selector": "fal fa-sunset",
      "categories": [
        "Light",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "sunset",
      "selector": "fad fa-sunset",
      "categories": [
        "Duotone",
        "Camping",
        "Weather"
      ]
    },
    {
      "name": "superpowers",
      "selector": "fab fa-superpowers",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "superscript",
      "selector": "fas fa-superscript",
      "categories": [
        "Solid",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "superscript",
      "selector": "far fa-superscript",
      "categories": [
        "Regular",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "superscript",
      "selector": "fal fa-superscript",
      "categories": [
        "Light",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "superscript",
      "selector": "fad fa-superscript",
      "categories": [
        "Duotone",
        "Editors",
        "Mathematics"
      ]
    },
    {
      "name": "supple",
      "selector": "fab fa-supple",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "surprise",
      "selector": "fas fa-surprise",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "surprise",
      "selector": "far fa-surprise",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "surprise",
      "selector": "fal fa-surprise",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "surprise",
      "selector": "fad fa-surprise",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "suse",
      "selector": "fab fa-suse",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "swatchbook",
      "selector": "fas fa-swatchbook",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "swatchbook",
      "selector": "far fa-swatchbook",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "swatchbook",
      "selector": "fal fa-swatchbook",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "swatchbook",
      "selector": "fad fa-swatchbook",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "swift",
      "selector": "fab fa-swift",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "swimmer",
      "selector": "fas fa-swimmer",
      "categories": [
        "Solid",
        "Fitness",
        "Hotel",
        "Maritime",
        "Sports",
        "Summer",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "swimmer",
      "selector": "far fa-swimmer",
      "categories": [
        "Regular",
        "Fitness",
        "Hotel",
        "Maritime",
        "Sports",
        "Summer",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "swimmer",
      "selector": "fal fa-swimmer",
      "categories": [
        "Light",
        "Fitness",
        "Hotel",
        "Maritime",
        "Sports",
        "Summer",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "swimmer",
      "selector": "fad fa-swimmer",
      "categories": [
        "Duotone",
        "Fitness",
        "Hotel",
        "Maritime",
        "Sports",
        "Summer",
        "Travel",
        "Users & People"
      ]
    },
    {
      "name": "swimming-pool",
      "selector": "fas fa-swimming-pool",
      "categories": [
        "Solid",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "swimming-pool",
      "selector": "far fa-swimming-pool",
      "categories": [
        "Regular",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "swimming-pool",
      "selector": "fal fa-swimming-pool",
      "categories": [
        "Light",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "swimming-pool",
      "selector": "fad fa-swimming-pool",
      "categories": [
        "Duotone",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "sword",
      "selector": "fas fa-sword",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sword",
      "selector": "far fa-sword",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sword",
      "selector": "fal fa-sword",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sword",
      "selector": "fad fa-sword",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "sword-laser",
      "selector": "fas fa-sword-laser",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser",
      "selector": "far fa-sword-laser",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser",
      "selector": "fal fa-sword-laser",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser",
      "selector": "fad fa-sword-laser",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser-alt",
      "selector": "fas fa-sword-laser-alt",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser-alt",
      "selector": "far fa-sword-laser-alt",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser-alt",
      "selector": "fal fa-sword-laser-alt",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "sword-laser-alt",
      "selector": "fad fa-sword-laser-alt",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "swords",
      "selector": "fas fa-swords",
      "categories": [
        "Solid",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "swords",
      "selector": "far fa-swords",
      "categories": [
        "Regular",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "swords",
      "selector": "fal fa-swords",
      "categories": [
        "Light",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "swords",
      "selector": "fad fa-swords",
      "categories": [
        "Duotone",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "swords-laser",
      "selector": "fas fa-swords-laser",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "swords-laser",
      "selector": "far fa-swords-laser",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "swords-laser",
      "selector": "fal fa-swords-laser",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "swords-laser",
      "selector": "fad fa-swords-laser",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "symfony",
      "selector": "fab fa-symfony",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "synagogue",
      "selector": "fas fa-synagogue",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "synagogue",
      "selector": "far fa-synagogue",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "synagogue",
      "selector": "fal fa-synagogue",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "synagogue",
      "selector": "fad fa-synagogue",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "sync",
      "selector": "fas fa-sync",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync",
      "selector": "far fa-sync",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync",
      "selector": "fal fa-sync",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync",
      "selector": "fad fa-sync",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync-alt",
      "selector": "fas fa-sync-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync-alt",
      "selector": "far fa-sync-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync-alt",
      "selector": "fal fa-sync-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "sync-alt",
      "selector": "fad fa-sync-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces",
        "Spinners"
      ]
    },
    {
      "name": "syringe",
      "selector": "fas fa-syringe",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "syringe",
      "selector": "far fa-syringe",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "syringe",
      "selector": "fal fa-syringe",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "syringe",
      "selector": "fad fa-syringe",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "table",
      "selector": "fas fa-table",
      "categories": [
        "Solid",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "table",
      "selector": "far fa-table",
      "categories": [
        "Regular",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "table",
      "selector": "fal fa-table",
      "categories": [
        "Light",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "table",
      "selector": "fad fa-table",
      "categories": [
        "Duotone",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "table-tennis",
      "selector": "fas fa-table-tennis",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "table-tennis",
      "selector": "far fa-table-tennis",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "table-tennis",
      "selector": "fal fa-table-tennis",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "table-tennis",
      "selector": "fad fa-table-tennis",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "tablet",
      "selector": "fas fa-tablet",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet",
      "selector": "far fa-tablet",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet",
      "selector": "fal fa-tablet",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet",
      "selector": "fad fa-tablet",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-alt",
      "selector": "fas fa-tablet-alt",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-alt",
      "selector": "far fa-tablet-alt",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-alt",
      "selector": "fal fa-tablet-alt",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-alt",
      "selector": "fad fa-tablet-alt",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android",
      "selector": "fas fa-tablet-android",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android",
      "selector": "far fa-tablet-android",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android",
      "selector": "fal fa-tablet-android",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android",
      "selector": "fad fa-tablet-android",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android-alt",
      "selector": "fas fa-tablet-android-alt",
      "categories": [
        "Solid",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android-alt",
      "selector": "far fa-tablet-android-alt",
      "categories": [
        "Regular",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android-alt",
      "selector": "fal fa-tablet-android-alt",
      "categories": [
        "Light",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-android-alt",
      "selector": "fad fa-tablet-android-alt",
      "categories": [
        "Duotone",
        "Computers",
        "Objects"
      ]
    },
    {
      "name": "tablet-rugged",
      "selector": "fas fa-tablet-rugged",
      "categories": [
        "Solid",
        "Logistics"
      ]
    },
    {
      "name": "tablet-rugged",
      "selector": "far fa-tablet-rugged",
      "categories": [
        "Regular",
        "Logistics"
      ]
    },
    {
      "name": "tablet-rugged",
      "selector": "fal fa-tablet-rugged",
      "categories": [
        "Light",
        "Logistics"
      ]
    },
    {
      "name": "tablet-rugged",
      "selector": "fad fa-tablet-rugged",
      "categories": [
        "Duotone",
        "Logistics"
      ]
    },
    {
      "name": "tablets",
      "selector": "fas fa-tablets",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "tablets",
      "selector": "far fa-tablets",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "tablets",
      "selector": "fal fa-tablets",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "tablets",
      "selector": "fad fa-tablets",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "tachometer",
      "selector": "fas fa-tachometer",
      "categories": [
        "Solid",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer",
      "selector": "far fa-tachometer",
      "categories": [
        "Regular",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer",
      "selector": "fal fa-tachometer",
      "categories": [
        "Light",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer",
      "selector": "fad fa-tachometer",
      "categories": [
        "Duotone",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer-alt",
      "selector": "fas fa-tachometer-alt",
      "categories": [
        "Solid",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer-alt",
      "selector": "far fa-tachometer-alt",
      "categories": [
        "Regular",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer-alt",
      "selector": "fal fa-tachometer-alt",
      "categories": [
        "Light",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer-alt",
      "selector": "fad fa-tachometer-alt",
      "categories": [
        "Duotone",
        "Automotive",
        "Objects"
      ]
    },
    {
      "name": "tachometer-alt-average",
      "selector": "fas fa-tachometer-alt-average",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-average",
      "selector": "far fa-tachometer-alt-average",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-average",
      "selector": "fal fa-tachometer-alt-average",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-average",
      "selector": "fad fa-tachometer-alt-average",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fast",
      "selector": "fas fa-tachometer-alt-fast",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fast",
      "selector": "far fa-tachometer-alt-fast",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fast",
      "selector": "fal fa-tachometer-alt-fast",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fast",
      "selector": "fad fa-tachometer-alt-fast",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fastest",
      "selector": "fas fa-tachometer-alt-fastest",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fastest",
      "selector": "far fa-tachometer-alt-fastest",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fastest",
      "selector": "fal fa-tachometer-alt-fastest",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-fastest",
      "selector": "fad fa-tachometer-alt-fastest",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slow",
      "selector": "fas fa-tachometer-alt-slow",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slow",
      "selector": "far fa-tachometer-alt-slow",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slow",
      "selector": "fal fa-tachometer-alt-slow",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slow",
      "selector": "fad fa-tachometer-alt-slow",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slowest",
      "selector": "fas fa-tachometer-alt-slowest",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slowest",
      "selector": "far fa-tachometer-alt-slowest",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slowest",
      "selector": "fal fa-tachometer-alt-slowest",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-alt-slowest",
      "selector": "fad fa-tachometer-alt-slowest",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-average",
      "selector": "fas fa-tachometer-average",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-average",
      "selector": "far fa-tachometer-average",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-average",
      "selector": "fal fa-tachometer-average",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-average",
      "selector": "fad fa-tachometer-average",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fast",
      "selector": "fas fa-tachometer-fast",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fast",
      "selector": "far fa-tachometer-fast",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fast",
      "selector": "fal fa-tachometer-fast",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fast",
      "selector": "fad fa-tachometer-fast",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fastest",
      "selector": "fas fa-tachometer-fastest",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fastest",
      "selector": "far fa-tachometer-fastest",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fastest",
      "selector": "fal fa-tachometer-fastest",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-fastest",
      "selector": "fad fa-tachometer-fastest",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slow",
      "selector": "fas fa-tachometer-slow",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slow",
      "selector": "far fa-tachometer-slow",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slow",
      "selector": "fal fa-tachometer-slow",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slow",
      "selector": "fad fa-tachometer-slow",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slowest",
      "selector": "fas fa-tachometer-slowest",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slowest",
      "selector": "far fa-tachometer-slowest",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slowest",
      "selector": "fal fa-tachometer-slowest",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tachometer-slowest",
      "selector": "fad fa-tachometer-slowest",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "taco",
      "selector": "fas fa-taco",
      "categories": [
        "Solid",
        "Food"
      ]
    },
    {
      "name": "taco",
      "selector": "far fa-taco",
      "categories": [
        "Regular",
        "Food"
      ]
    },
    {
      "name": "taco",
      "selector": "fal fa-taco",
      "categories": [
        "Light",
        "Food"
      ]
    },
    {
      "name": "taco",
      "selector": "fad fa-taco",
      "categories": [
        "Duotone",
        "Food"
      ]
    },
    {
      "name": "tag",
      "selector": "fas fa-tag",
      "categories": [
        "Solid",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tag",
      "selector": "far fa-tag",
      "categories": [
        "Regular",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tag",
      "selector": "fal fa-tag",
      "categories": [
        "Light",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tag",
      "selector": "fad fa-tag",
      "categories": [
        "Duotone",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tags",
      "selector": "fas fa-tags",
      "categories": [
        "Solid",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tags",
      "selector": "far fa-tags",
      "categories": [
        "Regular",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tags",
      "selector": "fal fa-tags",
      "categories": [
        "Light",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tags",
      "selector": "fad fa-tags",
      "categories": [
        "Duotone",
        "Business",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "tally",
      "selector": "fas fa-tally",
      "categories": [
        "Solid",
        "Finance",
        "Mathematics",
        "Political",
        "Science"
      ]
    },
    {
      "name": "tally",
      "selector": "far fa-tally",
      "categories": [
        "Regular",
        "Finance",
        "Mathematics",
        "Political",
        "Science"
      ]
    },
    {
      "name": "tally",
      "selector": "fal fa-tally",
      "categories": [
        "Light",
        "Finance",
        "Mathematics",
        "Political",
        "Science"
      ]
    },
    {
      "name": "tally",
      "selector": "fad fa-tally",
      "categories": [
        "Duotone",
        "Finance",
        "Mathematics",
        "Political",
        "Science"
      ]
    },
    {
      "name": "tanakh",
      "selector": "fas fa-tanakh",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "tanakh",
      "selector": "far fa-tanakh",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "tanakh",
      "selector": "fal fa-tanakh",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "tanakh",
      "selector": "fad fa-tanakh",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "tape",
      "selector": "fas fa-tape",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "tape",
      "selector": "far fa-tape",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "tape",
      "selector": "fal fa-tape",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "tape",
      "selector": "fad fa-tape",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "tasks",
      "selector": "fas fa-tasks",
      "categories": [
        "Solid",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks",
      "selector": "far fa-tasks",
      "categories": [
        "Regular",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks",
      "selector": "fal fa-tasks",
      "categories": [
        "Light",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks",
      "selector": "fad fa-tasks",
      "categories": [
        "Duotone",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks-alt",
      "selector": "fas fa-tasks-alt",
      "categories": [
        "Solid",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks-alt",
      "selector": "far fa-tasks-alt",
      "categories": [
        "Regular",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks-alt",
      "selector": "fal fa-tasks-alt",
      "categories": [
        "Light",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "tasks-alt",
      "selector": "fad fa-tasks-alt",
      "categories": [
        "Duotone",
        "Business",
        "Editors"
      ]
    },
    {
      "name": "taxi",
      "selector": "fas fa-taxi",
      "categories": [
        "Solid",
        "Automotive",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "taxi",
      "selector": "far fa-taxi",
      "categories": [
        "Regular",
        "Automotive",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "taxi",
      "selector": "fal fa-taxi",
      "categories": [
        "Light",
        "Automotive",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "taxi",
      "selector": "fad fa-taxi",
      "categories": [
        "Duotone",
        "Automotive",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles"
      ]
    },
    {
      "name": "teamspeak",
      "selector": "fab fa-teamspeak",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "teeth",
      "selector": "fas fa-teeth",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "teeth",
      "selector": "far fa-teeth",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "teeth",
      "selector": "fal fa-teeth",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "teeth",
      "selector": "fad fa-teeth",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "teeth-open",
      "selector": "fas fa-teeth-open",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "teeth-open",
      "selector": "far fa-teeth-open",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "teeth-open",
      "selector": "fal fa-teeth-open",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "teeth-open",
      "selector": "fad fa-teeth-open",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "telegram",
      "selector": "fab fa-telegram",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "telegram-plane",
      "selector": "fab fa-telegram-plane",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "telescope",
      "selector": "fas fa-telescope",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "telescope",
      "selector": "far fa-telescope",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "telescope",
      "selector": "fal fa-telescope",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "telescope",
      "selector": "fad fa-telescope",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "temperature-down",
      "selector": "fas fa-temperature-down",
      "categories": [
        "Solid",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-down",
      "selector": "far fa-temperature-down",
      "categories": [
        "Regular",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-down",
      "selector": "fal fa-temperature-down",
      "categories": [
        "Light",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-down",
      "selector": "fad fa-temperature-down",
      "categories": [
        "Duotone",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-frigid",
      "selector": "fas fa-temperature-frigid",
      "categories": [
        "Solid",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "temperature-frigid",
      "selector": "far fa-temperature-frigid",
      "categories": [
        "Regular",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "temperature-frigid",
      "selector": "fal fa-temperature-frigid",
      "categories": [
        "Light",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "temperature-frigid",
      "selector": "fad fa-temperature-frigid",
      "categories": [
        "Duotone",
        "Weather",
        "Winter"
      ]
    },
    {
      "name": "temperature-high",
      "selector": "fas fa-temperature-high",
      "categories": [
        "Solid",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-high",
      "selector": "far fa-temperature-high",
      "categories": [
        "Regular",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-high",
      "selector": "fal fa-temperature-high",
      "categories": [
        "Light",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-high",
      "selector": "fad fa-temperature-high",
      "categories": [
        "Duotone",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-hot",
      "selector": "fas fa-temperature-hot",
      "categories": [
        "Solid",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "temperature-hot",
      "selector": "far fa-temperature-hot",
      "categories": [
        "Regular",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "temperature-hot",
      "selector": "fal fa-temperature-hot",
      "categories": [
        "Light",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "temperature-hot",
      "selector": "fad fa-temperature-hot",
      "categories": [
        "Duotone",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "temperature-low",
      "selector": "fas fa-temperature-low",
      "categories": [
        "Solid",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-low",
      "selector": "far fa-temperature-low",
      "categories": [
        "Regular",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-low",
      "selector": "fal fa-temperature-low",
      "categories": [
        "Light",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-low",
      "selector": "fad fa-temperature-low",
      "categories": [
        "Duotone",
        "Science",
        "Weather"
      ]
    },
    {
      "name": "temperature-up",
      "selector": "fas fa-temperature-up",
      "categories": [
        "Solid",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-up",
      "selector": "far fa-temperature-up",
      "categories": [
        "Regular",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-up",
      "selector": "fal fa-temperature-up",
      "categories": [
        "Light",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "temperature-up",
      "selector": "fad fa-temperature-up",
      "categories": [
        "Duotone",
        "Household",
        "Weather"
      ]
    },
    {
      "name": "tencent-weibo",
      "selector": "fab fa-tencent-weibo",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "tenge",
      "selector": "fas fa-tenge",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "tenge",
      "selector": "far fa-tenge",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "tenge",
      "selector": "fal fa-tenge",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "tenge",
      "selector": "fad fa-tenge",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "tennis-ball",
      "selector": "fas fa-tennis-ball",
      "categories": [
        "Solid",
        "Sports"
      ]
    },
    {
      "name": "tennis-ball",
      "selector": "far fa-tennis-ball",
      "categories": [
        "Regular",
        "Sports"
      ]
    },
    {
      "name": "tennis-ball",
      "selector": "fal fa-tennis-ball",
      "categories": [
        "Light",
        "Sports"
      ]
    },
    {
      "name": "tennis-ball",
      "selector": "fad fa-tennis-ball",
      "categories": [
        "Duotone",
        "Sports"
      ]
    },
    {
      "name": "terminal",
      "selector": "fas fa-terminal",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "terminal",
      "selector": "far fa-terminal",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "terminal",
      "selector": "fal fa-terminal",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "terminal",
      "selector": "fad fa-terminal",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "text",
      "selector": "fas fa-text",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "text",
      "selector": "far fa-text",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "text",
      "selector": "fal fa-text",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "text",
      "selector": "fad fa-text",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "text-height",
      "selector": "fas fa-text-height",
      "categories": [
        "Solid",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-height",
      "selector": "far fa-text-height",
      "categories": [
        "Regular",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-height",
      "selector": "fal fa-text-height",
      "categories": [
        "Light",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-height",
      "selector": "fad fa-text-height",
      "categories": [
        "Duotone",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-size",
      "selector": "fas fa-text-size",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "text-size",
      "selector": "far fa-text-size",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "text-size",
      "selector": "fal fa-text-size",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "text-size",
      "selector": "fad fa-text-size",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "text-width",
      "selector": "fas fa-text-width",
      "categories": [
        "Solid",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-width",
      "selector": "far fa-text-width",
      "categories": [
        "Regular",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-width",
      "selector": "fal fa-text-width",
      "categories": [
        "Light",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "text-width",
      "selector": "fad fa-text-width",
      "categories": [
        "Duotone",
        "Arrows",
        "Editors"
      ]
    },
    {
      "name": "th",
      "selector": "fas fa-th",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "th",
      "selector": "far fa-th",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "th",
      "selector": "fal fa-th",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "th",
      "selector": "fad fa-th",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "th-large",
      "selector": "fas fa-th-large",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "th-large",
      "selector": "far fa-th-large",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "th-large",
      "selector": "fal fa-th-large",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "th-large",
      "selector": "fad fa-th-large",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "th-list",
      "selector": "fas fa-th-list",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "th-list",
      "selector": "far fa-th-list",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "th-list",
      "selector": "fal fa-th-list",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "th-list",
      "selector": "fad fa-th-list",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "the-red-yeti",
      "selector": "fab fa-the-red-yeti",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "theater-masks",
      "selector": "fas fa-theater-masks",
      "categories": [
        "Solid",
        "Education"
      ]
    },
    {
      "name": "theater-masks",
      "selector": "far fa-theater-masks",
      "categories": [
        "Regular",
        "Education"
      ]
    },
    {
      "name": "theater-masks",
      "selector": "fal fa-theater-masks",
      "categories": [
        "Light",
        "Education"
      ]
    },
    {
      "name": "theater-masks",
      "selector": "fad fa-theater-masks",
      "categories": [
        "Duotone",
        "Education"
      ]
    },
    {
      "name": "themeco",
      "selector": "fab fa-themeco",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "themeisle",
      "selector": "fab fa-themeisle",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "thermometer",
      "selector": "fas fa-thermometer",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "thermometer",
      "selector": "far fa-thermometer",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "thermometer",
      "selector": "fal fa-thermometer",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "thermometer",
      "selector": "fad fa-thermometer",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy"
      ]
    },
    {
      "name": "thermometer-empty",
      "selector": "fas fa-thermometer-empty",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "thermometer-empty",
      "selector": "far fa-thermometer-empty",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "thermometer-empty",
      "selector": "fal fa-thermometer-empty",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "thermometer-empty",
      "selector": "fad fa-thermometer-empty",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "thermometer-full",
      "selector": "fas fa-thermometer-full",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "thermometer-full",
      "selector": "far fa-thermometer-full",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "thermometer-full",
      "selector": "fal fa-thermometer-full",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "thermometer-full",
      "selector": "fad fa-thermometer-full",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "thermometer-half",
      "selector": "fas fa-thermometer-half",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "thermometer-half",
      "selector": "far fa-thermometer-half",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "thermometer-half",
      "selector": "fal fa-thermometer-half",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "thermometer-half",
      "selector": "fad fa-thermometer-half",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "thermometer-quarter",
      "selector": "fas fa-thermometer-quarter",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "thermometer-quarter",
      "selector": "far fa-thermometer-quarter",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "thermometer-quarter",
      "selector": "fal fa-thermometer-quarter",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "thermometer-quarter",
      "selector": "fad fa-thermometer-quarter",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "thermometer-three-quarters",
      "selector": "fas fa-thermometer-three-quarters",
      "categories": [
        "Solid",
        "Status"
      ]
    },
    {
      "name": "thermometer-three-quarters",
      "selector": "far fa-thermometer-three-quarters",
      "categories": [
        "Regular",
        "Status"
      ]
    },
    {
      "name": "thermometer-three-quarters",
      "selector": "fal fa-thermometer-three-quarters",
      "categories": [
        "Light",
        "Status"
      ]
    },
    {
      "name": "thermometer-three-quarters",
      "selector": "fad fa-thermometer-three-quarters",
      "categories": [
        "Duotone",
        "Status"
      ]
    },
    {
      "name": "theta",
      "selector": "fas fa-theta",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "theta",
      "selector": "far fa-theta",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "theta",
      "selector": "fal fa-theta",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "theta",
      "selector": "fad fa-theta",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "think-peaks",
      "selector": "fab fa-think-peaks",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "thumbs-down",
      "selector": "fas fa-thumbs-down",
      "categories": [
        "Solid",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-down",
      "selector": "far fa-thumbs-down",
      "categories": [
        "Regular",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-down",
      "selector": "fal fa-thumbs-down",
      "categories": [
        "Light",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-down",
      "selector": "fad fa-thumbs-down",
      "categories": [
        "Duotone",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-up",
      "selector": "fas fa-thumbs-up",
      "categories": [
        "Solid",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-up",
      "selector": "far fa-thumbs-up",
      "categories": [
        "Regular",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-up",
      "selector": "fal fa-thumbs-up",
      "categories": [
        "Light",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbs-up",
      "selector": "fad fa-thumbs-up",
      "categories": [
        "Duotone",
        "Hands",
        "Interfaces",
        "Payments & Shopping",
        "Social",
        "Status"
      ]
    },
    {
      "name": "thumbtack",
      "selector": "fas fa-thumbtack",
      "categories": [
        "Solid",
        "Business",
        "Maps",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "thumbtack",
      "selector": "far fa-thumbtack",
      "categories": [
        "Regular",
        "Business",
        "Maps",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "thumbtack",
      "selector": "fal fa-thumbtack",
      "categories": [
        "Light",
        "Business",
        "Maps",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "thumbtack",
      "selector": "fad fa-thumbtack",
      "categories": [
        "Duotone",
        "Business",
        "Maps",
        "Objects",
        "Social",
        "Writing"
      ]
    },
    {
      "name": "thunderstorm",
      "selector": "fas fa-thunderstorm",
      "categories": [
        "Solid",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm",
      "selector": "far fa-thunderstorm",
      "categories": [
        "Regular",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm",
      "selector": "fal fa-thunderstorm",
      "categories": [
        "Light",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm",
      "selector": "fad fa-thunderstorm",
      "categories": [
        "Duotone",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-moon",
      "selector": "fas fa-thunderstorm-moon",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-moon",
      "selector": "far fa-thunderstorm-moon",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-moon",
      "selector": "fal fa-thunderstorm-moon",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-moon",
      "selector": "fad fa-thunderstorm-moon",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-sun",
      "selector": "fas fa-thunderstorm-sun",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-sun",
      "selector": "far fa-thunderstorm-sun",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-sun",
      "selector": "fal fa-thunderstorm-sun",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "thunderstorm-sun",
      "selector": "fad fa-thunderstorm-sun",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "ticket",
      "selector": "fas fa-ticket",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "ticket",
      "selector": "far fa-ticket",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "ticket",
      "selector": "fal fa-ticket",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "ticket",
      "selector": "fad fa-ticket",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Shapes"
      ]
    },
    {
      "name": "ticket-alt",
      "selector": "fas fa-ticket-alt",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "ticket-alt",
      "selector": "far fa-ticket-alt",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "ticket-alt",
      "selector": "fal fa-ticket-alt",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "ticket-alt",
      "selector": "fad fa-ticket-alt",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "tilde",
      "selector": "fas fa-tilde",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "tilde",
      "selector": "far fa-tilde",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "tilde",
      "selector": "fal fa-tilde",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "tilde",
      "selector": "fad fa-tilde",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "times",
      "selector": "fas fa-times",
      "categories": [
        "Solid",
        "Interfaces",
        "Mathematics"
      ]
    },
    {
      "name": "times",
      "selector": "far fa-times",
      "categories": [
        "Regular",
        "Interfaces",
        "Mathematics"
      ]
    },
    {
      "name": "times",
      "selector": "fal fa-times",
      "categories": [
        "Light",
        "Interfaces",
        "Mathematics"
      ]
    },
    {
      "name": "times",
      "selector": "fad fa-times",
      "categories": [
        "Duotone",
        "Interfaces",
        "Mathematics"
      ]
    },
    {
      "name": "times-circle",
      "selector": "fas fa-times-circle",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "times-circle",
      "selector": "far fa-times-circle",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "times-circle",
      "selector": "fal fa-times-circle",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "times-circle",
      "selector": "fad fa-times-circle",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "times-hexagon",
      "selector": "fas fa-times-hexagon",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "times-hexagon",
      "selector": "far fa-times-hexagon",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "times-hexagon",
      "selector": "fal fa-times-hexagon",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "times-hexagon",
      "selector": "fad fa-times-hexagon",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "times-octagon",
      "selector": "fas fa-times-octagon",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "times-octagon",
      "selector": "far fa-times-octagon",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "times-octagon",
      "selector": "fal fa-times-octagon",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "times-octagon",
      "selector": "fad fa-times-octagon",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "times-square",
      "selector": "fas fa-times-square",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "times-square",
      "selector": "far fa-times-square",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "times-square",
      "selector": "fal fa-times-square",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "times-square",
      "selector": "fad fa-times-square",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "tint",
      "selector": "fas fa-tint",
      "categories": [
        "Solid",
        "Design",
        "Images",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "tint",
      "selector": "far fa-tint",
      "categories": [
        "Regular",
        "Design",
        "Images",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "tint",
      "selector": "fal fa-tint",
      "categories": [
        "Light",
        "Design",
        "Images",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "tint",
      "selector": "fad fa-tint",
      "categories": [
        "Duotone",
        "Design",
        "Images",
        "Maps",
        "Status"
      ]
    },
    {
      "name": "tint-slash",
      "selector": "fas fa-tint-slash",
      "categories": [
        "Solid",
        "Design",
        "Status"
      ]
    },
    {
      "name": "tint-slash",
      "selector": "far fa-tint-slash",
      "categories": [
        "Regular",
        "Design",
        "Status"
      ]
    },
    {
      "name": "tint-slash",
      "selector": "fal fa-tint-slash",
      "categories": [
        "Light",
        "Design",
        "Status"
      ]
    },
    {
      "name": "tint-slash",
      "selector": "fad fa-tint-slash",
      "categories": [
        "Duotone",
        "Design",
        "Status"
      ]
    },
    {
      "name": "tire",
      "selector": "fas fa-tire",
      "categories": [
        "Solid",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire",
      "selector": "far fa-tire",
      "categories": [
        "Regular",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire",
      "selector": "fal fa-tire",
      "categories": [
        "Light",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire",
      "selector": "fad fa-tire",
      "categories": [
        "Duotone",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire-flat",
      "selector": "fas fa-tire-flat",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tire-flat",
      "selector": "far fa-tire-flat",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tire-flat",
      "selector": "fal fa-tire-flat",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tire-flat",
      "selector": "fad fa-tire-flat",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tire-pressure-warning",
      "selector": "fas fa-tire-pressure-warning",
      "categories": [
        "Solid",
        "Automotive"
      ]
    },
    {
      "name": "tire-pressure-warning",
      "selector": "far fa-tire-pressure-warning",
      "categories": [
        "Regular",
        "Automotive"
      ]
    },
    {
      "name": "tire-pressure-warning",
      "selector": "fal fa-tire-pressure-warning",
      "categories": [
        "Light",
        "Automotive"
      ]
    },
    {
      "name": "tire-pressure-warning",
      "selector": "fad fa-tire-pressure-warning",
      "categories": [
        "Duotone",
        "Automotive"
      ]
    },
    {
      "name": "tire-rugged",
      "selector": "fas fa-tire-rugged",
      "categories": [
        "Solid",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire-rugged",
      "selector": "far fa-tire-rugged",
      "categories": [
        "Regular",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire-rugged",
      "selector": "fal fa-tire-rugged",
      "categories": [
        "Light",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tire-rugged",
      "selector": "fad fa-tire-rugged",
      "categories": [
        "Duotone",
        "Automotive",
        "Spinners"
      ]
    },
    {
      "name": "tired",
      "selector": "fas fa-tired",
      "categories": [
        "Solid",
        "Emoji"
      ]
    },
    {
      "name": "tired",
      "selector": "far fa-tired",
      "categories": [
        "Regular",
        "Emoji"
      ]
    },
    {
      "name": "tired",
      "selector": "fal fa-tired",
      "categories": [
        "Light",
        "Emoji"
      ]
    },
    {
      "name": "tired",
      "selector": "fad fa-tired",
      "categories": [
        "Duotone",
        "Emoji"
      ]
    },
    {
      "name": "toggle-off",
      "selector": "fas fa-toggle-off",
      "categories": [
        "Solid",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-off",
      "selector": "far fa-toggle-off",
      "categories": [
        "Regular",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-off",
      "selector": "fal fa-toggle-off",
      "categories": [
        "Light",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-off",
      "selector": "fad fa-toggle-off",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-on",
      "selector": "fas fa-toggle-on",
      "categories": [
        "Solid",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-on",
      "selector": "far fa-toggle-on",
      "categories": [
        "Regular",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-on",
      "selector": "fal fa-toggle-on",
      "categories": [
        "Light",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toggle-on",
      "selector": "fad fa-toggle-on",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "toilet",
      "selector": "fas fa-toilet",
      "categories": [
        "Solid",
        "Objects"
      ]
    },
    {
      "name": "toilet",
      "selector": "far fa-toilet",
      "categories": [
        "Regular",
        "Objects"
      ]
    },
    {
      "name": "toilet",
      "selector": "fal fa-toilet",
      "categories": [
        "Light",
        "Objects"
      ]
    },
    {
      "name": "toilet",
      "selector": "fad fa-toilet",
      "categories": [
        "Duotone",
        "Objects"
      ]
    },
    {
      "name": "toilet-paper",
      "selector": "fas fa-toilet-paper",
      "categories": [
        "Solid",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper",
      "selector": "far fa-toilet-paper",
      "categories": [
        "Regular",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper",
      "selector": "fal fa-toilet-paper",
      "categories": [
        "Light",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper",
      "selector": "fad fa-toilet-paper",
      "categories": [
        "Duotone",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper-alt",
      "selector": "fas fa-toilet-paper-alt",
      "categories": [
        "Solid",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper-alt",
      "selector": "far fa-toilet-paper-alt",
      "categories": [
        "Regular",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper-alt",
      "selector": "fal fa-toilet-paper-alt",
      "categories": [
        "Light",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "toilet-paper-alt",
      "selector": "fad fa-toilet-paper-alt",
      "categories": [
        "Duotone",
        "Camping",
        "Halloween",
        "Household"
      ]
    },
    {
      "name": "tombstone",
      "selector": "fas fa-tombstone",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "tombstone",
      "selector": "far fa-tombstone",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "tombstone",
      "selector": "fal fa-tombstone",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "tombstone",
      "selector": "fad fa-tombstone",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "tombstone-alt",
      "selector": "fas fa-tombstone-alt",
      "categories": [
        "Solid",
        "Halloween"
      ]
    },
    {
      "name": "tombstone-alt",
      "selector": "far fa-tombstone-alt",
      "categories": [
        "Regular",
        "Halloween"
      ]
    },
    {
      "name": "tombstone-alt",
      "selector": "fal fa-tombstone-alt",
      "categories": [
        "Light",
        "Halloween"
      ]
    },
    {
      "name": "tombstone-alt",
      "selector": "fad fa-tombstone-alt",
      "categories": [
        "Duotone",
        "Halloween"
      ]
    },
    {
      "name": "toolbox",
      "selector": "fas fa-toolbox",
      "categories": [
        "Solid",
        "Construction",
        "Objects"
      ]
    },
    {
      "name": "toolbox",
      "selector": "far fa-toolbox",
      "categories": [
        "Regular",
        "Construction",
        "Objects"
      ]
    },
    {
      "name": "toolbox",
      "selector": "fal fa-toolbox",
      "categories": [
        "Light",
        "Construction",
        "Objects"
      ]
    },
    {
      "name": "toolbox",
      "selector": "fad fa-toolbox",
      "categories": [
        "Duotone",
        "Construction",
        "Objects"
      ]
    },
    {
      "name": "tools",
      "selector": "fas fa-tools",
      "categories": [
        "Solid",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "tools",
      "selector": "far fa-tools",
      "categories": [
        "Regular",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "tools",
      "selector": "fal fa-tools",
      "categories": [
        "Light",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "tools",
      "selector": "fad fa-tools",
      "categories": [
        "Duotone",
        "Construction",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "tooth",
      "selector": "fas fa-tooth",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "tooth",
      "selector": "far fa-tooth",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "tooth",
      "selector": "fal fa-tooth",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "tooth",
      "selector": "fad fa-tooth",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "toothbrush",
      "selector": "fas fa-toothbrush",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "toothbrush",
      "selector": "far fa-toothbrush",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "toothbrush",
      "selector": "fal fa-toothbrush",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "toothbrush",
      "selector": "fad fa-toothbrush",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "torah",
      "selector": "fas fa-torah",
      "categories": [
        "Solid",
        "Religion"
      ]
    },
    {
      "name": "torah",
      "selector": "far fa-torah",
      "categories": [
        "Regular",
        "Religion"
      ]
    },
    {
      "name": "torah",
      "selector": "fal fa-torah",
      "categories": [
        "Light",
        "Religion"
      ]
    },
    {
      "name": "torah",
      "selector": "fad fa-torah",
      "categories": [
        "Duotone",
        "Religion"
      ]
    },
    {
      "name": "torii-gate",
      "selector": "fas fa-torii-gate",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "torii-gate",
      "selector": "far fa-torii-gate",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "torii-gate",
      "selector": "fal fa-torii-gate",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "torii-gate",
      "selector": "fad fa-torii-gate",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "tornado",
      "selector": "fas fa-tornado",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "tornado",
      "selector": "far fa-tornado",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "tornado",
      "selector": "fal fa-tornado",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "tornado",
      "selector": "fad fa-tornado",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "tractor",
      "selector": "fas fa-tractor",
      "categories": [
        "Solid",
        "Autumn",
        "Vehicles"
      ]
    },
    {
      "name": "tractor",
      "selector": "far fa-tractor",
      "categories": [
        "Regular",
        "Autumn",
        "Vehicles"
      ]
    },
    {
      "name": "tractor",
      "selector": "fal fa-tractor",
      "categories": [
        "Light",
        "Autumn",
        "Vehicles"
      ]
    },
    {
      "name": "tractor",
      "selector": "fad fa-tractor",
      "categories": [
        "Duotone",
        "Autumn",
        "Vehicles"
      ]
    },
    {
      "name": "trade-federation",
      "selector": "fab fa-trade-federation",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "trademark",
      "selector": "fas fa-trademark",
      "categories": [
        "Solid",
        "Business"
      ]
    },
    {
      "name": "trademark",
      "selector": "far fa-trademark",
      "categories": [
        "Regular",
        "Business"
      ]
    },
    {
      "name": "trademark",
      "selector": "fal fa-trademark",
      "categories": [
        "Light",
        "Business"
      ]
    },
    {
      "name": "trademark",
      "selector": "fad fa-trademark",
      "categories": [
        "Duotone",
        "Business"
      ]
    },
    {
      "name": "traffic-cone",
      "selector": "fas fa-traffic-cone",
      "categories": [
        "Solid",
        "Construction",
        "Maps"
      ]
    },
    {
      "name": "traffic-cone",
      "selector": "far fa-traffic-cone",
      "categories": [
        "Regular",
        "Construction",
        "Maps"
      ]
    },
    {
      "name": "traffic-cone",
      "selector": "fal fa-traffic-cone",
      "categories": [
        "Light",
        "Construction",
        "Maps"
      ]
    },
    {
      "name": "traffic-cone",
      "selector": "fad fa-traffic-cone",
      "categories": [
        "Duotone",
        "Construction",
        "Maps"
      ]
    },
    {
      "name": "traffic-light",
      "selector": "fas fa-traffic-light",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "traffic-light",
      "selector": "far fa-traffic-light",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "traffic-light",
      "selector": "fal fa-traffic-light",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "traffic-light",
      "selector": "fad fa-traffic-light",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-go",
      "selector": "fas fa-traffic-light-go",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-go",
      "selector": "far fa-traffic-light-go",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-go",
      "selector": "fal fa-traffic-light-go",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-go",
      "selector": "fad fa-traffic-light-go",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-slow",
      "selector": "fas fa-traffic-light-slow",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-slow",
      "selector": "far fa-traffic-light-slow",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-slow",
      "selector": "fal fa-traffic-light-slow",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-slow",
      "selector": "fad fa-traffic-light-slow",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-stop",
      "selector": "fas fa-traffic-light-stop",
      "categories": [
        "Solid",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-stop",
      "selector": "far fa-traffic-light-stop",
      "categories": [
        "Regular",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-stop",
      "selector": "fal fa-traffic-light-stop",
      "categories": [
        "Light",
        "Maps"
      ]
    },
    {
      "name": "traffic-light-stop",
      "selector": "fad fa-traffic-light-stop",
      "categories": [
        "Duotone",
        "Maps"
      ]
    },
    {
      "name": "trailer",
      "selector": "fas fa-trailer",
      "categories": [
        "Solid",
        "Automotive",
        "Camping",
        "Moving"
      ]
    },
    {
      "name": "trailer",
      "selector": "far fa-trailer",
      "categories": [
        "Regular",
        "Automotive",
        "Camping",
        "Moving"
      ]
    },
    {
      "name": "trailer",
      "selector": "fal fa-trailer",
      "categories": [
        "Light",
        "Automotive",
        "Camping",
        "Moving"
      ]
    },
    {
      "name": "trailer",
      "selector": "fad fa-trailer",
      "categories": [
        "Duotone",
        "Automotive",
        "Camping",
        "Moving"
      ]
    },
    {
      "name": "train",
      "selector": "fas fa-train",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "train",
      "selector": "far fa-train",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "train",
      "selector": "fal fa-train",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "train",
      "selector": "fad fa-train",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles"
      ]
    },
    {
      "name": "tram",
      "selector": "fas fa-tram",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "tram",
      "selector": "far fa-tram",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "tram",
      "selector": "fal fa-tram",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "tram",
      "selector": "fad fa-tram",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Travel",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "transgender",
      "selector": "fas fa-transgender",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "transgender",
      "selector": "far fa-transgender",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "transgender",
      "selector": "fal fa-transgender",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "transgender",
      "selector": "fad fa-transgender",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "transgender-alt",
      "selector": "fas fa-transgender-alt",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "transgender-alt",
      "selector": "far fa-transgender-alt",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "transgender-alt",
      "selector": "fal fa-transgender-alt",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "transgender-alt",
      "selector": "fad fa-transgender-alt",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "transporter",
      "selector": "fas fa-transporter",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter",
      "selector": "far fa-transporter",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter",
      "selector": "fal fa-transporter",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter",
      "selector": "fad fa-transporter",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-1",
      "selector": "fas fa-transporter-1",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-1",
      "selector": "far fa-transporter-1",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-1",
      "selector": "fal fa-transporter-1",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-1",
      "selector": "fad fa-transporter-1",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-2",
      "selector": "fas fa-transporter-2",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-2",
      "selector": "far fa-transporter-2",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-2",
      "selector": "fal fa-transporter-2",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-2",
      "selector": "fad fa-transporter-2",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-3",
      "selector": "fas fa-transporter-3",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-3",
      "selector": "far fa-transporter-3",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-3",
      "selector": "fal fa-transporter-3",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-3",
      "selector": "fad fa-transporter-3",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-empty",
      "selector": "fas fa-transporter-empty",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-empty",
      "selector": "far fa-transporter-empty",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-empty",
      "selector": "fal fa-transporter-empty",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "transporter-empty",
      "selector": "fad fa-transporter-empty",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "trash",
      "selector": "fas fa-trash",
      "categories": [
        "Solid",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash",
      "selector": "far fa-trash",
      "categories": [
        "Regular",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash",
      "selector": "fal fa-trash",
      "categories": [
        "Light",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash",
      "selector": "fad fa-trash",
      "categories": [
        "Duotone",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash-alt",
      "selector": "fas fa-trash-alt",
      "categories": [
        "Solid",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash-alt",
      "selector": "far fa-trash-alt",
      "categories": [
        "Regular",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash-alt",
      "selector": "fal fa-trash-alt",
      "categories": [
        "Light",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash-alt",
      "selector": "fad fa-trash-alt",
      "categories": [
        "Duotone",
        "Editors",
        "Interfaces",
        "Objects"
      ]
    },
    {
      "name": "trash-restore",
      "selector": "fas fa-trash-restore",
      "categories": [
        "Solid",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore",
      "selector": "far fa-trash-restore",
      "categories": [
        "Regular",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore",
      "selector": "fal fa-trash-restore",
      "categories": [
        "Light",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore",
      "selector": "fad fa-trash-restore",
      "categories": [
        "Duotone",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore-alt",
      "selector": "fas fa-trash-restore-alt",
      "categories": [
        "Solid",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore-alt",
      "selector": "far fa-trash-restore-alt",
      "categories": [
        "Regular",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore-alt",
      "selector": "fal fa-trash-restore-alt",
      "categories": [
        "Light",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-restore-alt",
      "selector": "fad fa-trash-restore-alt",
      "categories": [
        "Duotone",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo",
      "selector": "fas fa-trash-undo",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo",
      "selector": "far fa-trash-undo",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo",
      "selector": "fal fa-trash-undo",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo",
      "selector": "fad fa-trash-undo",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo-alt",
      "selector": "fas fa-trash-undo-alt",
      "categories": [
        "Solid",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo-alt",
      "selector": "far fa-trash-undo-alt",
      "categories": [
        "Regular",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo-alt",
      "selector": "fal fa-trash-undo-alt",
      "categories": [
        "Light",
        "Interfaces"
      ]
    },
    {
      "name": "trash-undo-alt",
      "selector": "fad fa-trash-undo-alt",
      "categories": [
        "Duotone",
        "Interfaces"
      ]
    },
    {
      "name": "treasure-chest",
      "selector": "fas fa-treasure-chest",
      "categories": [
        "Solid",
        "Finance",
        "Maritime",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "treasure-chest",
      "selector": "far fa-treasure-chest",
      "categories": [
        "Regular",
        "Finance",
        "Maritime",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "treasure-chest",
      "selector": "fal fa-treasure-chest",
      "categories": [
        "Light",
        "Finance",
        "Maritime",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "treasure-chest",
      "selector": "fad fa-treasure-chest",
      "categories": [
        "Duotone",
        "Finance",
        "Maritime",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "tree",
      "selector": "fas fa-tree",
      "categories": [
        "Solid",
        "Autumn",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "tree",
      "selector": "far fa-tree",
      "categories": [
        "Regular",
        "Autumn",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "tree",
      "selector": "fal fa-tree",
      "categories": [
        "Light",
        "Autumn",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "tree",
      "selector": "fad fa-tree",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "tree-alt",
      "selector": "fas fa-tree-alt",
      "categories": [
        "Solid",
        "Autumn",
        "Camping",
        "Maps",
        "Objects",
        "Spring"
      ]
    },
    {
      "name": "tree-alt",
      "selector": "far fa-tree-alt",
      "categories": [
        "Regular",
        "Autumn",
        "Camping",
        "Maps",
        "Objects",
        "Spring"
      ]
    },
    {
      "name": "tree-alt",
      "selector": "fal fa-tree-alt",
      "categories": [
        "Light",
        "Autumn",
        "Camping",
        "Maps",
        "Objects",
        "Spring"
      ]
    },
    {
      "name": "tree-alt",
      "selector": "fad fa-tree-alt",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping",
        "Maps",
        "Objects",
        "Spring"
      ]
    },
    {
      "name": "tree-christmas",
      "selector": "fas fa-tree-christmas",
      "categories": [
        "Solid",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-christmas",
      "selector": "far fa-tree-christmas",
      "categories": [
        "Regular",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-christmas",
      "selector": "fal fa-tree-christmas",
      "categories": [
        "Light",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-christmas",
      "selector": "fad fa-tree-christmas",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-decorated",
      "selector": "fas fa-tree-decorated",
      "categories": [
        "Solid",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-decorated",
      "selector": "far fa-tree-decorated",
      "categories": [
        "Regular",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-decorated",
      "selector": "fal fa-tree-decorated",
      "categories": [
        "Light",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-decorated",
      "selector": "fad fa-tree-decorated",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects"
      ]
    },
    {
      "name": "tree-large",
      "selector": "fas fa-tree-large",
      "categories": [
        "Solid",
        "Camping",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "tree-large",
      "selector": "far fa-tree-large",
      "categories": [
        "Regular",
        "Camping",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "tree-large",
      "selector": "fal fa-tree-large",
      "categories": [
        "Light",
        "Camping",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "tree-large",
      "selector": "fad fa-tree-large",
      "categories": [
        "Duotone",
        "Camping",
        "Objects",
        "Winter"
      ]
    },
    {
      "name": "tree-palm",
      "selector": "fas fa-tree-palm",
      "categories": [
        "Solid",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "tree-palm",
      "selector": "far fa-tree-palm",
      "categories": [
        "Regular",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "tree-palm",
      "selector": "fal fa-tree-palm",
      "categories": [
        "Light",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "tree-palm",
      "selector": "fad fa-tree-palm",
      "categories": [
        "Duotone",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "trees",
      "selector": "fas fa-trees",
      "categories": [
        "Solid",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "trees",
      "selector": "far fa-trees",
      "categories": [
        "Regular",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "trees",
      "selector": "fal fa-trees",
      "categories": [
        "Light",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "trees",
      "selector": "fad fa-trees",
      "categories": [
        "Duotone",
        "Autumn",
        "Camping"
      ]
    },
    {
      "name": "trello",
      "selector": "fab fa-trello",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "triangle",
      "selector": "fas fa-triangle",
      "categories": [
        "Solid",
        "Arrows",
        "Shapes"
      ]
    },
    {
      "name": "triangle",
      "selector": "far fa-triangle",
      "categories": [
        "Regular",
        "Arrows",
        "Shapes"
      ]
    },
    {
      "name": "triangle",
      "selector": "fal fa-triangle",
      "categories": [
        "Light",
        "Arrows",
        "Shapes"
      ]
    },
    {
      "name": "triangle",
      "selector": "fad fa-triangle",
      "categories": [
        "Duotone",
        "Arrows",
        "Shapes"
      ]
    },
    {
      "name": "triangle-music",
      "selector": "fas fa-triangle-music",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "triangle-music",
      "selector": "far fa-triangle-music",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "triangle-music",
      "selector": "fal fa-triangle-music",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "triangle-music",
      "selector": "fad fa-triangle-music",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "tripadvisor",
      "selector": "fab fa-tripadvisor",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "trophy",
      "selector": "fas fa-trophy",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy",
      "selector": "far fa-trophy",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy",
      "selector": "fal fa-trophy",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy",
      "selector": "fad fa-trophy",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy-alt",
      "selector": "fas fa-trophy-alt",
      "categories": [
        "Solid",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy-alt",
      "selector": "far fa-trophy-alt",
      "categories": [
        "Regular",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy-alt",
      "selector": "fal fa-trophy-alt",
      "categories": [
        "Light",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "trophy-alt",
      "selector": "fad fa-trophy-alt",
      "categories": [
        "Duotone",
        "Interfaces",
        "Maps",
        "Objects",
        "Payments & Shopping"
      ]
    },
    {
      "name": "truck",
      "selector": "fas fa-truck",
      "categories": [
        "Solid",
        "Automotive",
        "Logistics",
        "Maps",
        "Objects",
        "Shopping",
        "Vehicles"
      ]
    },
    {
      "name": "truck",
      "selector": "far fa-truck",
      "categories": [
        "Regular",
        "Automotive",
        "Logistics",
        "Maps",
        "Objects",
        "Shopping",
        "Vehicles"
      ]
    },
    {
      "name": "truck",
      "selector": "fal fa-truck",
      "categories": [
        "Light",
        "Automotive",
        "Logistics",
        "Maps",
        "Objects",
        "Shopping",
        "Vehicles"
      ]
    },
    {
      "name": "truck",
      "selector": "fad fa-truck",
      "categories": [
        "Duotone",
        "Automotive",
        "Logistics",
        "Maps",
        "Objects",
        "Shopping",
        "Vehicles"
      ]
    },
    {
      "name": "truck-container",
      "selector": "fas fa-truck-container",
      "categories": [
        "Solid",
        "Construction",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "truck-container",
      "selector": "far fa-truck-container",
      "categories": [
        "Regular",
        "Construction",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "truck-container",
      "selector": "fal fa-truck-container",
      "categories": [
        "Light",
        "Construction",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "truck-container",
      "selector": "fad fa-truck-container",
      "categories": [
        "Duotone",
        "Construction",
        "Moving",
        "Shopping"
      ]
    },
    {
      "name": "truck-couch",
      "selector": "fas fa-truck-couch",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "truck-couch",
      "selector": "far fa-truck-couch",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "truck-couch",
      "selector": "fal fa-truck-couch",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "truck-couch",
      "selector": "fad fa-truck-couch",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "truck-loading",
      "selector": "fas fa-truck-loading",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "truck-loading",
      "selector": "far fa-truck-loading",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "truck-loading",
      "selector": "fal fa-truck-loading",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "truck-loading",
      "selector": "fad fa-truck-loading",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "truck-monster",
      "selector": "fas fa-truck-monster",
      "categories": [
        "Solid",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "truck-monster",
      "selector": "far fa-truck-monster",
      "categories": [
        "Regular",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "truck-monster",
      "selector": "fal fa-truck-monster",
      "categories": [
        "Light",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "truck-monster",
      "selector": "fad fa-truck-monster",
      "categories": [
        "Duotone",
        "Automotive",
        "Vehicles"
      ]
    },
    {
      "name": "truck-moving",
      "selector": "fas fa-truck-moving",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "truck-moving",
      "selector": "far fa-truck-moving",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "truck-moving",
      "selector": "fal fa-truck-moving",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "truck-moving",
      "selector": "fad fa-truck-moving",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "truck-pickup",
      "selector": "fas fa-truck-pickup",
      "categories": [
        "Solid",
        "Automotive",
        "Construction",
        "Vehicles"
      ]
    },
    {
      "name": "truck-pickup",
      "selector": "far fa-truck-pickup",
      "categories": [
        "Regular",
        "Automotive",
        "Construction",
        "Vehicles"
      ]
    },
    {
      "name": "truck-pickup",
      "selector": "fal fa-truck-pickup",
      "categories": [
        "Light",
        "Automotive",
        "Construction",
        "Vehicles"
      ]
    },
    {
      "name": "truck-pickup",
      "selector": "fad fa-truck-pickup",
      "categories": [
        "Duotone",
        "Automotive",
        "Construction",
        "Vehicles"
      ]
    },
    {
      "name": "truck-plow",
      "selector": "fas fa-truck-plow",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "truck-plow",
      "selector": "far fa-truck-plow",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "truck-plow",
      "selector": "fal fa-truck-plow",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "truck-plow",
      "selector": "fad fa-truck-plow",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Vehicles",
        "Winter"
      ]
    },
    {
      "name": "truck-ramp",
      "selector": "fas fa-truck-ramp",
      "categories": [
        "Solid",
        "Moving"
      ]
    },
    {
      "name": "truck-ramp",
      "selector": "far fa-truck-ramp",
      "categories": [
        "Regular",
        "Moving"
      ]
    },
    {
      "name": "truck-ramp",
      "selector": "fal fa-truck-ramp",
      "categories": [
        "Light",
        "Moving"
      ]
    },
    {
      "name": "truck-ramp",
      "selector": "fad fa-truck-ramp",
      "categories": [
        "Duotone",
        "Moving"
      ]
    },
    {
      "name": "trumpet",
      "selector": "fas fa-trumpet",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "trumpet",
      "selector": "far fa-trumpet",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "trumpet",
      "selector": "fal fa-trumpet",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "trumpet",
      "selector": "fad fa-trumpet",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "tshirt",
      "selector": "fas fa-tshirt",
      "categories": [
        "Solid",
        "Clothing",
        "Shopping"
      ]
    },
    {
      "name": "tshirt",
      "selector": "far fa-tshirt",
      "categories": [
        "Regular",
        "Clothing",
        "Shopping"
      ]
    },
    {
      "name": "tshirt",
      "selector": "fal fa-tshirt",
      "categories": [
        "Light",
        "Clothing",
        "Shopping"
      ]
    },
    {
      "name": "tshirt",
      "selector": "fad fa-tshirt",
      "categories": [
        "Duotone",
        "Clothing",
        "Shopping"
      ]
    },
    {
      "name": "tty",
      "selector": "fas fa-tty",
      "categories": [
        "Solid",
        "Accessibility",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "tty",
      "selector": "far fa-tty",
      "categories": [
        "Regular",
        "Accessibility",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "tty",
      "selector": "fal fa-tty",
      "categories": [
        "Light",
        "Accessibility",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "tty",
      "selector": "fad fa-tty",
      "categories": [
        "Duotone",
        "Accessibility",
        "Communication",
        "Maps"
      ]
    },
    {
      "name": "tumblr",
      "selector": "fab fa-tumblr",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "tumblr-square",
      "selector": "fab fa-tumblr-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "turkey",
      "selector": "fas fa-turkey",
      "categories": [
        "Solid",
        "Autumn",
        "Food",
        "Holiday"
      ]
    },
    {
      "name": "turkey",
      "selector": "far fa-turkey",
      "categories": [
        "Regular",
        "Autumn",
        "Food",
        "Holiday"
      ]
    },
    {
      "name": "turkey",
      "selector": "fal fa-turkey",
      "categories": [
        "Light",
        "Autumn",
        "Food",
        "Holiday"
      ]
    },
    {
      "name": "turkey",
      "selector": "fad fa-turkey",
      "categories": [
        "Duotone",
        "Autumn",
        "Food",
        "Holiday"
      ]
    },
    {
      "name": "turntable",
      "selector": "fas fa-turntable",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "turntable",
      "selector": "far fa-turntable",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "turntable",
      "selector": "fal fa-turntable",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "turntable",
      "selector": "fad fa-turntable",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "turtle",
      "selector": "fas fa-turtle",
      "categories": [
        "Solid",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "turtle",
      "selector": "far fa-turtle",
      "categories": [
        "Regular",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "turtle",
      "selector": "fal fa-turtle",
      "categories": [
        "Light",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "turtle",
      "selector": "fad fa-turtle",
      "categories": [
        "Duotone",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "tv",
      "selector": "fas fa-tv",
      "categories": [
        "Solid",
        "Audio & Video",
        "Computers",
        "Hotel",
        "Household",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "tv",
      "selector": "far fa-tv",
      "categories": [
        "Regular",
        "Audio & Video",
        "Computers",
        "Hotel",
        "Household",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "tv",
      "selector": "fal fa-tv",
      "categories": [
        "Light",
        "Audio & Video",
        "Computers",
        "Hotel",
        "Household",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "tv",
      "selector": "fad fa-tv",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Computers",
        "Hotel",
        "Household",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "tv-alt",
      "selector": "fas fa-tv-alt",
      "categories": [
        "Solid",
        "Audio & Video",
        "Hotel",
        "Household",
        "Travel"
      ]
    },
    {
      "name": "tv-alt",
      "selector": "far fa-tv-alt",
      "categories": [
        "Regular",
        "Audio & Video",
        "Hotel",
        "Household",
        "Travel"
      ]
    },
    {
      "name": "tv-alt",
      "selector": "fal fa-tv-alt",
      "categories": [
        "Light",
        "Audio & Video",
        "Hotel",
        "Household",
        "Travel"
      ]
    },
    {
      "name": "tv-alt",
      "selector": "fad fa-tv-alt",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Hotel",
        "Household",
        "Travel"
      ]
    },
    {
      "name": "tv-music",
      "selector": "fas fa-tv-music",
      "categories": [
        "Solid",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "tv-music",
      "selector": "far fa-tv-music",
      "categories": [
        "Regular",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "tv-music",
      "selector": "fal fa-tv-music",
      "categories": [
        "Light",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "tv-music",
      "selector": "fad fa-tv-music",
      "categories": [
        "Duotone",
        "Computers",
        "Music"
      ]
    },
    {
      "name": "tv-retro",
      "selector": "fas fa-tv-retro",
      "categories": [
        "Solid",
        "Computers",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "tv-retro",
      "selector": "far fa-tv-retro",
      "categories": [
        "Regular",
        "Computers",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "tv-retro",
      "selector": "fal fa-tv-retro",
      "categories": [
        "Light",
        "Computers",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "tv-retro",
      "selector": "fad fa-tv-retro",
      "categories": [
        "Duotone",
        "Computers",
        "Hotel",
        "Household",
        "Objects"
      ]
    },
    {
      "name": "twitch",
      "selector": "fab fa-twitch",
      "categories": [
        "Brands",
        "Games"
      ]
    },
    {
      "name": "twitter",
      "selector": "fab fa-twitter",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "twitter-square",
      "selector": "fab fa-twitter-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "typewriter",
      "selector": "fas fa-typewriter",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "typewriter",
      "selector": "far fa-typewriter",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "typewriter",
      "selector": "fal fa-typewriter",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "typewriter",
      "selector": "fad fa-typewriter",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "typo3",
      "selector": "fab fa-typo3",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "uber",
      "selector": "fab fa-uber",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ubuntu",
      "selector": "fab fa-ubuntu",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ufo",
      "selector": "fas fa-ufo",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo",
      "selector": "far fa-ufo",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo",
      "selector": "fal fa-ufo",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo",
      "selector": "fad fa-ufo",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo-beam",
      "selector": "fas fa-ufo-beam",
      "categories": [
        "Solid",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo-beam",
      "selector": "far fa-ufo-beam",
      "categories": [
        "Regular",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo-beam",
      "selector": "fal fa-ufo-beam",
      "categories": [
        "Light",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "ufo-beam",
      "selector": "fad fa-ufo-beam",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Vehicles"
      ]
    },
    {
      "name": "uikit",
      "selector": "fab fa-uikit",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "umbraco",
      "selector": "fab fa-umbraco",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "umbrella",
      "selector": "fas fa-umbrella",
      "categories": [
        "Solid",
        "Maps",
        "Objects",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "umbrella",
      "selector": "far fa-umbrella",
      "categories": [
        "Regular",
        "Maps",
        "Objects",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "umbrella",
      "selector": "fal fa-umbrella",
      "categories": [
        "Light",
        "Maps",
        "Objects",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "umbrella",
      "selector": "fad fa-umbrella",
      "categories": [
        "Duotone",
        "Maps",
        "Objects",
        "Spring",
        "Weather"
      ]
    },
    {
      "name": "umbrella-beach",
      "selector": "fas fa-umbrella-beach",
      "categories": [
        "Solid",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "umbrella-beach",
      "selector": "far fa-umbrella-beach",
      "categories": [
        "Regular",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "umbrella-beach",
      "selector": "fal fa-umbrella-beach",
      "categories": [
        "Light",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "umbrella-beach",
      "selector": "fad fa-umbrella-beach",
      "categories": [
        "Duotone",
        "Hotel",
        "Summer",
        "Travel"
      ]
    },
    {
      "name": "underline",
      "selector": "fas fa-underline",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "underline",
      "selector": "far fa-underline",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "underline",
      "selector": "fal fa-underline",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "underline",
      "selector": "fad fa-underline",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "undo",
      "selector": "fas fa-undo",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo",
      "selector": "far fa-undo",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo",
      "selector": "fal fa-undo",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo",
      "selector": "fad fa-undo",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo-alt",
      "selector": "fas fa-undo-alt",
      "categories": [
        "Solid",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo-alt",
      "selector": "far fa-undo-alt",
      "categories": [
        "Regular",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo-alt",
      "selector": "fal fa-undo-alt",
      "categories": [
        "Light",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "undo-alt",
      "selector": "fad fa-undo-alt",
      "categories": [
        "Duotone",
        "Arrows",
        "Audio & Video",
        "Editors",
        "Interfaces"
      ]
    },
    {
      "name": "unicorn",
      "selector": "fas fa-unicorn",
      "categories": [
        "Solid",
        "Animals"
      ]
    },
    {
      "name": "unicorn",
      "selector": "far fa-unicorn",
      "categories": [
        "Regular",
        "Animals"
      ]
    },
    {
      "name": "unicorn",
      "selector": "fal fa-unicorn",
      "categories": [
        "Light",
        "Animals"
      ]
    },
    {
      "name": "unicorn",
      "selector": "fad fa-unicorn",
      "categories": [
        "Duotone",
        "Animals"
      ]
    },
    {
      "name": "union",
      "selector": "fas fa-union",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "union",
      "selector": "far fa-union",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "union",
      "selector": "fal fa-union",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "union",
      "selector": "fad fa-union",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "uniregistry",
      "selector": "fab fa-uniregistry",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "unity",
      "selector": "fab fa-unity",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "universal-access",
      "selector": "fas fa-universal-access",
      "categories": [
        "Solid",
        "Accessibility"
      ]
    },
    {
      "name": "universal-access",
      "selector": "far fa-universal-access",
      "categories": [
        "Regular",
        "Accessibility"
      ]
    },
    {
      "name": "universal-access",
      "selector": "fal fa-universal-access",
      "categories": [
        "Light",
        "Accessibility"
      ]
    },
    {
      "name": "universal-access",
      "selector": "fad fa-universal-access",
      "categories": [
        "Duotone",
        "Accessibility"
      ]
    },
    {
      "name": "university",
      "selector": "fas fa-university",
      "categories": [
        "Solid",
        "Buildings",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "university",
      "selector": "far fa-university",
      "categories": [
        "Regular",
        "Buildings",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "university",
      "selector": "fal fa-university",
      "categories": [
        "Light",
        "Buildings",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "university",
      "selector": "fad fa-university",
      "categories": [
        "Duotone",
        "Buildings",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "unlink",
      "selector": "fas fa-unlink",
      "categories": [
        "Solid",
        "Editors"
      ]
    },
    {
      "name": "unlink",
      "selector": "far fa-unlink",
      "categories": [
        "Regular",
        "Editors"
      ]
    },
    {
      "name": "unlink",
      "selector": "fal fa-unlink",
      "categories": [
        "Light",
        "Editors"
      ]
    },
    {
      "name": "unlink",
      "selector": "fad fa-unlink",
      "categories": [
        "Duotone",
        "Editors"
      ]
    },
    {
      "name": "unlock",
      "selector": "fas fa-unlock",
      "categories": [
        "Solid",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock",
      "selector": "far fa-unlock",
      "categories": [
        "Regular",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock",
      "selector": "fal fa-unlock",
      "categories": [
        "Light",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock",
      "selector": "fad fa-unlock",
      "categories": [
        "Duotone",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock-alt",
      "selector": "fas fa-unlock-alt",
      "categories": [
        "Solid",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock-alt",
      "selector": "far fa-unlock-alt",
      "categories": [
        "Regular",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock-alt",
      "selector": "fal fa-unlock-alt",
      "categories": [
        "Light",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "unlock-alt",
      "selector": "fad fa-unlock-alt",
      "categories": [
        "Duotone",
        "Objects",
        "Security",
        "Status"
      ]
    },
    {
      "name": "untappd",
      "selector": "fab fa-untappd",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "upload",
      "selector": "fas fa-upload",
      "categories": [
        "Solid",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "upload",
      "selector": "far fa-upload",
      "categories": [
        "Regular",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "upload",
      "selector": "fal fa-upload",
      "categories": [
        "Light",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "upload",
      "selector": "fad fa-upload",
      "categories": [
        "Duotone",
        "Arrows",
        "Computers",
        "Interfaces"
      ]
    },
    {
      "name": "ups",
      "selector": "fab fa-ups",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "usb",
      "selector": "fab fa-usb",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "usb-drive",
      "selector": "fas fa-usb-drive",
      "categories": [
        "Solid",
        "Computers",
        "Security"
      ]
    },
    {
      "name": "usb-drive",
      "selector": "far fa-usb-drive",
      "categories": [
        "Regular",
        "Computers",
        "Security"
      ]
    },
    {
      "name": "usb-drive",
      "selector": "fal fa-usb-drive",
      "categories": [
        "Light",
        "Computers",
        "Security"
      ]
    },
    {
      "name": "usb-drive",
      "selector": "fad fa-usb-drive",
      "categories": [
        "Duotone",
        "Computers",
        "Security"
      ]
    },
    {
      "name": "usd-circle",
      "selector": "fas fa-usd-circle",
      "categories": [
        "Solid",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-circle",
      "selector": "far fa-usd-circle",
      "categories": [
        "Regular",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-circle",
      "selector": "fal fa-usd-circle",
      "categories": [
        "Light",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-circle",
      "selector": "fad fa-usd-circle",
      "categories": [
        "Duotone",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-square",
      "selector": "fas fa-usd-square",
      "categories": [
        "Solid",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-square",
      "selector": "far fa-usd-square",
      "categories": [
        "Regular",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-square",
      "selector": "fal fa-usd-square",
      "categories": [
        "Light",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "usd-square",
      "selector": "fad fa-usd-square",
      "categories": [
        "Duotone",
        "Charity",
        "Currency",
        "Maps"
      ]
    },
    {
      "name": "user",
      "selector": "fas fa-user",
      "categories": [
        "Solid",
        "Interfaces",
        "Social",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user",
      "selector": "far fa-user",
      "categories": [
        "Regular",
        "Interfaces",
        "Social",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user",
      "selector": "fal fa-user",
      "categories": [
        "Light",
        "Interfaces",
        "Social",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user",
      "selector": "fad fa-user",
      "categories": [
        "Duotone",
        "Interfaces",
        "Social",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alien",
      "selector": "fas fa-user-alien",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "user-alien",
      "selector": "far fa-user-alien",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "user-alien",
      "selector": "fal fa-user-alien",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "user-alien",
      "selector": "fad fa-user-alien",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "user-alt",
      "selector": "fas fa-user-alt",
      "categories": [
        "Solid",
        "Interfaces",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt",
      "selector": "far fa-user-alt",
      "categories": [
        "Regular",
        "Interfaces",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt",
      "selector": "fal fa-user-alt",
      "categories": [
        "Light",
        "Interfaces",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt",
      "selector": "fad fa-user-alt",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt-slash",
      "selector": "fas fa-user-alt-slash",
      "categories": [
        "Solid",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt-slash",
      "selector": "far fa-user-alt-slash",
      "categories": [
        "Regular",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt-slash",
      "selector": "fal fa-user-alt-slash",
      "categories": [
        "Light",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-alt-slash",
      "selector": "fad fa-user-alt-slash",
      "categories": [
        "Duotone",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-astronaut",
      "selector": "fas fa-user-astronaut",
      "categories": [
        "Solid",
        "Science Fiction",
        "Users & People"
      ]
    },
    {
      "name": "user-astronaut",
      "selector": "far fa-user-astronaut",
      "categories": [
        "Regular",
        "Science Fiction",
        "Users & People"
      ]
    },
    {
      "name": "user-astronaut",
      "selector": "fal fa-user-astronaut",
      "categories": [
        "Light",
        "Science Fiction",
        "Users & People"
      ]
    },
    {
      "name": "user-astronaut",
      "selector": "fad fa-user-astronaut",
      "categories": [
        "Duotone",
        "Science Fiction",
        "Users & People"
      ]
    },
    {
      "name": "user-chart",
      "selector": "fas fa-user-chart",
      "categories": [
        "Solid",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "user-chart",
      "selector": "far fa-user-chart",
      "categories": [
        "Regular",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "user-chart",
      "selector": "fal fa-user-chart",
      "categories": [
        "Light",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "user-chart",
      "selector": "fad fa-user-chart",
      "categories": [
        "Duotone",
        "Business",
        "Users & People"
      ]
    },
    {
      "name": "user-check",
      "selector": "fas fa-user-check",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-check",
      "selector": "far fa-user-check",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-check",
      "selector": "fal fa-user-check",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-check",
      "selector": "fad fa-user-check",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-circle",
      "selector": "fas fa-user-circle",
      "categories": [
        "Solid",
        "Interfaces",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-circle",
      "selector": "far fa-user-circle",
      "categories": [
        "Regular",
        "Interfaces",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-circle",
      "selector": "fal fa-user-circle",
      "categories": [
        "Light",
        "Interfaces",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-circle",
      "selector": "fad fa-user-circle",
      "categories": [
        "Duotone",
        "Interfaces",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-clock",
      "selector": "fas fa-user-clock",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-clock",
      "selector": "far fa-user-clock",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-clock",
      "selector": "fal fa-user-clock",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-clock",
      "selector": "fad fa-user-clock",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-cog",
      "selector": "fas fa-user-cog",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-cog",
      "selector": "far fa-user-cog",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-cog",
      "selector": "fal fa-user-cog",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-cog",
      "selector": "fad fa-user-cog",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-cowboy",
      "selector": "fas fa-user-cowboy",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-cowboy",
      "selector": "far fa-user-cowboy",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-cowboy",
      "selector": "fal fa-user-cowboy",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-cowboy",
      "selector": "fad fa-user-cowboy",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-crown",
      "selector": "fas fa-user-crown",
      "categories": [
        "Solid",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-crown",
      "selector": "far fa-user-crown",
      "categories": [
        "Regular",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-crown",
      "selector": "fal fa-user-crown",
      "categories": [
        "Light",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-crown",
      "selector": "fad fa-user-crown",
      "categories": [
        "Duotone",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-edit",
      "selector": "fas fa-user-edit",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-edit",
      "selector": "far fa-user-edit",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-edit",
      "selector": "fal fa-user-edit",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-edit",
      "selector": "fad fa-user-edit",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-friends",
      "selector": "fas fa-user-friends",
      "categories": [
        "Solid",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-friends",
      "selector": "far fa-user-friends",
      "categories": [
        "Regular",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-friends",
      "selector": "fal fa-user-friends",
      "categories": [
        "Light",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-friends",
      "selector": "fad fa-user-friends",
      "categories": [
        "Duotone",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-graduate",
      "selector": "fas fa-user-graduate",
      "categories": [
        "Solid",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "user-graduate",
      "selector": "far fa-user-graduate",
      "categories": [
        "Regular",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "user-graduate",
      "selector": "fal fa-user-graduate",
      "categories": [
        "Light",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "user-graduate",
      "selector": "fad fa-user-graduate",
      "categories": [
        "Duotone",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "user-hard-hat",
      "selector": "fas fa-user-hard-hat",
      "categories": [
        "Solid",
        "Construction",
        "Logistics",
        "Users & People"
      ]
    },
    {
      "name": "user-hard-hat",
      "selector": "far fa-user-hard-hat",
      "categories": [
        "Regular",
        "Construction",
        "Logistics",
        "Users & People"
      ]
    },
    {
      "name": "user-hard-hat",
      "selector": "fal fa-user-hard-hat",
      "categories": [
        "Light",
        "Construction",
        "Logistics",
        "Users & People"
      ]
    },
    {
      "name": "user-hard-hat",
      "selector": "fad fa-user-hard-hat",
      "categories": [
        "Duotone",
        "Construction",
        "Logistics",
        "Users & People"
      ]
    },
    {
      "name": "user-headset",
      "selector": "fas fa-user-headset",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-headset",
      "selector": "far fa-user-headset",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-headset",
      "selector": "fal fa-user-headset",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-headset",
      "selector": "fad fa-user-headset",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-injured",
      "selector": "fas fa-user-injured",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-injured",
      "selector": "far fa-user-injured",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-injured",
      "selector": "fal fa-user-injured",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-injured",
      "selector": "fad fa-user-injured",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-lock",
      "selector": "fas fa-user-lock",
      "categories": [
        "Solid",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-lock",
      "selector": "far fa-user-lock",
      "categories": [
        "Regular",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-lock",
      "selector": "fal fa-user-lock",
      "categories": [
        "Light",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-lock",
      "selector": "fad fa-user-lock",
      "categories": [
        "Duotone",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-md",
      "selector": "fas fa-user-md",
      "categories": [
        "Solid",
        "Health",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md",
      "selector": "far fa-user-md",
      "categories": [
        "Regular",
        "Health",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md",
      "selector": "fal fa-user-md",
      "categories": [
        "Light",
        "Health",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md",
      "selector": "fad fa-user-md",
      "categories": [
        "Duotone",
        "Health",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md-chat",
      "selector": "fas fa-user-md-chat",
      "categories": [
        "Solid",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md-chat",
      "selector": "far fa-user-md-chat",
      "categories": [
        "Regular",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md-chat",
      "selector": "fal fa-user-md-chat",
      "categories": [
        "Light",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-md-chat",
      "selector": "fad fa-user-md-chat",
      "categories": [
        "Duotone",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-minus",
      "selector": "fas fa-user-minus",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-minus",
      "selector": "far fa-user-minus",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-minus",
      "selector": "fal fa-user-minus",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-minus",
      "selector": "fad fa-user-minus",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-music",
      "selector": "fas fa-user-music",
      "categories": [
        "Solid",
        "Music",
        "Users & People"
      ]
    },
    {
      "name": "user-music",
      "selector": "far fa-user-music",
      "categories": [
        "Regular",
        "Music",
        "Users & People"
      ]
    },
    {
      "name": "user-music",
      "selector": "fal fa-user-music",
      "categories": [
        "Light",
        "Music",
        "Users & People"
      ]
    },
    {
      "name": "user-music",
      "selector": "fad fa-user-music",
      "categories": [
        "Duotone",
        "Music",
        "Users & People"
      ]
    },
    {
      "name": "user-ninja",
      "selector": "fas fa-user-ninja",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-ninja",
      "selector": "far fa-user-ninja",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-ninja",
      "selector": "fal fa-user-ninja",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-ninja",
      "selector": "fad fa-user-ninja",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-nurse",
      "selector": "fas fa-user-nurse",
      "categories": [
        "Solid",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-nurse",
      "selector": "far fa-user-nurse",
      "categories": [
        "Regular",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-nurse",
      "selector": "fal fa-user-nurse",
      "categories": [
        "Light",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-nurse",
      "selector": "fad fa-user-nurse",
      "categories": [
        "Duotone",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "user-plus",
      "selector": "fas fa-user-plus",
      "categories": [
        "Solid",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-plus",
      "selector": "far fa-user-plus",
      "categories": [
        "Regular",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-plus",
      "selector": "fal fa-user-plus",
      "categories": [
        "Light",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-plus",
      "selector": "fad fa-user-plus",
      "categories": [
        "Duotone",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "user-robot",
      "selector": "fas fa-user-robot",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "user-robot",
      "selector": "far fa-user-robot",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "user-robot",
      "selector": "fal fa-user-robot",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "user-robot",
      "selector": "fad fa-user-robot",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "user-secret",
      "selector": "fas fa-user-secret",
      "categories": [
        "Solid",
        "Code",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-secret",
      "selector": "far fa-user-secret",
      "categories": [
        "Regular",
        "Code",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-secret",
      "selector": "fal fa-user-secret",
      "categories": [
        "Light",
        "Code",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-secret",
      "selector": "fad fa-user-secret",
      "categories": [
        "Duotone",
        "Code",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-shield",
      "selector": "fas fa-user-shield",
      "categories": [
        "Solid",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-shield",
      "selector": "far fa-user-shield",
      "categories": [
        "Regular",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-shield",
      "selector": "fal fa-user-shield",
      "categories": [
        "Light",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-shield",
      "selector": "fad fa-user-shield",
      "categories": [
        "Duotone",
        "Security",
        "Users & People"
      ]
    },
    {
      "name": "user-slash",
      "selector": "fas fa-user-slash",
      "categories": [
        "Solid",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-slash",
      "selector": "far fa-user-slash",
      "categories": [
        "Regular",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-slash",
      "selector": "fal fa-user-slash",
      "categories": [
        "Light",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-slash",
      "selector": "fad fa-user-slash",
      "categories": [
        "Duotone",
        "Status",
        "Users & People"
      ]
    },
    {
      "name": "user-tag",
      "selector": "fas fa-user-tag",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-tag",
      "selector": "far fa-user-tag",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-tag",
      "selector": "fal fa-user-tag",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-tag",
      "selector": "fad fa-user-tag",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-tie",
      "selector": "fas fa-user-tie",
      "categories": [
        "Solid",
        "Clothing",
        "Users & People"
      ]
    },
    {
      "name": "user-tie",
      "selector": "far fa-user-tie",
      "categories": [
        "Regular",
        "Clothing",
        "Users & People"
      ]
    },
    {
      "name": "user-tie",
      "selector": "fal fa-user-tie",
      "categories": [
        "Light",
        "Clothing",
        "Users & People"
      ]
    },
    {
      "name": "user-tie",
      "selector": "fad fa-user-tie",
      "categories": [
        "Duotone",
        "Clothing",
        "Users & People"
      ]
    },
    {
      "name": "user-times",
      "selector": "fas fa-user-times",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "user-times",
      "selector": "far fa-user-times",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "user-times",
      "selector": "fal fa-user-times",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "user-times",
      "selector": "fad fa-user-times",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "user-visor",
      "selector": "fas fa-user-visor",
      "categories": [
        "Solid",
        "Science Fiction"
      ]
    },
    {
      "name": "user-visor",
      "selector": "far fa-user-visor",
      "categories": [
        "Regular",
        "Science Fiction"
      ]
    },
    {
      "name": "user-visor",
      "selector": "fal fa-user-visor",
      "categories": [
        "Light",
        "Science Fiction"
      ]
    },
    {
      "name": "user-visor",
      "selector": "fad fa-user-visor",
      "categories": [
        "Duotone",
        "Science Fiction"
      ]
    },
    {
      "name": "users",
      "selector": "fas fa-users",
      "categories": [
        "Solid",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users",
      "selector": "far fa-users",
      "categories": [
        "Regular",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users",
      "selector": "fal fa-users",
      "categories": [
        "Light",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users",
      "selector": "fad fa-users",
      "categories": [
        "Duotone",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users-class",
      "selector": "fas fa-users-class",
      "categories": [
        "Solid",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "users-class",
      "selector": "far fa-users-class",
      "categories": [
        "Regular",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "users-class",
      "selector": "fal fa-users-class",
      "categories": [
        "Light",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "users-class",
      "selector": "fad fa-users-class",
      "categories": [
        "Duotone",
        "Education",
        "Users & People"
      ]
    },
    {
      "name": "users-cog",
      "selector": "fas fa-users-cog",
      "categories": [
        "Solid",
        "Users & People"
      ]
    },
    {
      "name": "users-cog",
      "selector": "far fa-users-cog",
      "categories": [
        "Regular",
        "Users & People"
      ]
    },
    {
      "name": "users-cog",
      "selector": "fal fa-users-cog",
      "categories": [
        "Light",
        "Users & People"
      ]
    },
    {
      "name": "users-cog",
      "selector": "fad fa-users-cog",
      "categories": [
        "Duotone",
        "Users & People"
      ]
    },
    {
      "name": "users-crown",
      "selector": "fas fa-users-crown",
      "categories": [
        "Solid",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users-crown",
      "selector": "far fa-users-crown",
      "categories": [
        "Regular",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users-crown",
      "selector": "fal fa-users-crown",
      "categories": [
        "Light",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users-crown",
      "selector": "fad fa-users-crown",
      "categories": [
        "Duotone",
        "Marketing",
        "Social",
        "Users & People"
      ]
    },
    {
      "name": "users-medical",
      "selector": "fas fa-users-medical",
      "categories": [
        "Solid",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "users-medical",
      "selector": "far fa-users-medical",
      "categories": [
        "Regular",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "users-medical",
      "selector": "fal fa-users-medical",
      "categories": [
        "Light",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "users-medical",
      "selector": "fad fa-users-medical",
      "categories": [
        "Duotone",
        "Medical",
        "Users & People"
      ]
    },
    {
      "name": "usps",
      "selector": "fab fa-usps",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "ussunnah",
      "selector": "fab fa-ussunnah",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "utensil-fork",
      "selector": "fas fa-utensil-fork",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-fork",
      "selector": "far fa-utensil-fork",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-fork",
      "selector": "fal fa-utensil-fork",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-fork",
      "selector": "fad fa-utensil-fork",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-knife",
      "selector": "fas fa-utensil-knife",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-knife",
      "selector": "far fa-utensil-knife",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-knife",
      "selector": "fal fa-utensil-knife",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-knife",
      "selector": "fad fa-utensil-knife",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-spoon",
      "selector": "fas fa-utensil-spoon",
      "categories": [
        "Solid",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-spoon",
      "selector": "far fa-utensil-spoon",
      "categories": [
        "Regular",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-spoon",
      "selector": "fal fa-utensil-spoon",
      "categories": [
        "Light",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensil-spoon",
      "selector": "fad fa-utensil-spoon",
      "categories": [
        "Duotone",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils",
      "selector": "fas fa-utensils",
      "categories": [
        "Solid",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils",
      "selector": "far fa-utensils",
      "categories": [
        "Regular",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils",
      "selector": "fal fa-utensils",
      "categories": [
        "Light",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils",
      "selector": "fad fa-utensils",
      "categories": [
        "Duotone",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils-alt",
      "selector": "fas fa-utensils-alt",
      "categories": [
        "Solid",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils-alt",
      "selector": "far fa-utensils-alt",
      "categories": [
        "Regular",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils-alt",
      "selector": "fal fa-utensils-alt",
      "categories": [
        "Light",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "utensils-alt",
      "selector": "fad fa-utensils-alt",
      "categories": [
        "Duotone",
        "Hotel",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "vaadin",
      "selector": "fab fa-vaadin",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "vacuum",
      "selector": "fas fa-vacuum",
      "categories": [
        "Solid",
        "Hotel",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "vacuum",
      "selector": "far fa-vacuum",
      "categories": [
        "Regular",
        "Hotel",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "vacuum",
      "selector": "fal fa-vacuum",
      "categories": [
        "Light",
        "Hotel",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "vacuum",
      "selector": "fad fa-vacuum",
      "categories": [
        "Duotone",
        "Hotel",
        "Household",
        "Moving"
      ]
    },
    {
      "name": "vacuum-robot",
      "selector": "fas fa-vacuum-robot",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "vacuum-robot",
      "selector": "far fa-vacuum-robot",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "vacuum-robot",
      "selector": "fal fa-vacuum-robot",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "vacuum-robot",
      "selector": "fad fa-vacuum-robot",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "value-absolute",
      "selector": "fas fa-value-absolute",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "value-absolute",
      "selector": "far fa-value-absolute",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "value-absolute",
      "selector": "fal fa-value-absolute",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "value-absolute",
      "selector": "fad fa-value-absolute",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "vector-square",
      "selector": "fas fa-vector-square",
      "categories": [
        "Solid",
        "Design"
      ]
    },
    {
      "name": "vector-square",
      "selector": "far fa-vector-square",
      "categories": [
        "Regular",
        "Design"
      ]
    },
    {
      "name": "vector-square",
      "selector": "fal fa-vector-square",
      "categories": [
        "Light",
        "Design"
      ]
    },
    {
      "name": "vector-square",
      "selector": "fad fa-vector-square",
      "categories": [
        "Duotone",
        "Design"
      ]
    },
    {
      "name": "venus",
      "selector": "fas fa-venus",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "venus",
      "selector": "far fa-venus",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "venus",
      "selector": "fal fa-venus",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "venus",
      "selector": "fad fa-venus",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "venus-double",
      "selector": "fas fa-venus-double",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "venus-double",
      "selector": "far fa-venus-double",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "venus-double",
      "selector": "fal fa-venus-double",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "venus-double",
      "selector": "fad fa-venus-double",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "venus-mars",
      "selector": "fas fa-venus-mars",
      "categories": [
        "Solid",
        "Genders"
      ]
    },
    {
      "name": "venus-mars",
      "selector": "far fa-venus-mars",
      "categories": [
        "Regular",
        "Genders"
      ]
    },
    {
      "name": "venus-mars",
      "selector": "fal fa-venus-mars",
      "categories": [
        "Light",
        "Genders"
      ]
    },
    {
      "name": "venus-mars",
      "selector": "fad fa-venus-mars",
      "categories": [
        "Duotone",
        "Genders"
      ]
    },
    {
      "name": "vhs",
      "selector": "fas fa-vhs",
      "categories": [
        "Solid",
        "Audio & Video"
      ]
    },
    {
      "name": "vhs",
      "selector": "far fa-vhs",
      "categories": [
        "Regular",
        "Audio & Video"
      ]
    },
    {
      "name": "vhs",
      "selector": "fal fa-vhs",
      "categories": [
        "Light",
        "Audio & Video"
      ]
    },
    {
      "name": "vhs",
      "selector": "fad fa-vhs",
      "categories": [
        "Duotone",
        "Audio & Video"
      ]
    },
    {
      "name": "viacoin",
      "selector": "fab fa-viacoin",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "viadeo",
      "selector": "fab fa-viadeo",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "viadeo-square",
      "selector": "fab fa-viadeo-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "vial",
      "selector": "fas fa-vial",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vial",
      "selector": "far fa-vial",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vial",
      "selector": "fal fa-vial",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vial",
      "selector": "fad fa-vial",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vials",
      "selector": "fas fa-vials",
      "categories": [
        "Solid",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vials",
      "selector": "far fa-vials",
      "categories": [
        "Regular",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vials",
      "selector": "fal fa-vials",
      "categories": [
        "Light",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "vials",
      "selector": "fad fa-vials",
      "categories": [
        "Duotone",
        "Medical",
        "Pharmacy",
        "Science"
      ]
    },
    {
      "name": "viber",
      "selector": "fab fa-viber",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "video",
      "selector": "fas fa-video",
      "categories": [
        "Solid",
        "Audio & Video",
        "Chat",
        "Social",
        "Status"
      ]
    },
    {
      "name": "video",
      "selector": "far fa-video",
      "categories": [
        "Regular",
        "Audio & Video",
        "Chat",
        "Social",
        "Status"
      ]
    },
    {
      "name": "video",
      "selector": "fal fa-video",
      "categories": [
        "Light",
        "Audio & Video",
        "Chat",
        "Social",
        "Status"
      ]
    },
    {
      "name": "video",
      "selector": "fad fa-video",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Chat",
        "Social",
        "Status"
      ]
    },
    {
      "name": "video-plus",
      "selector": "fas fa-video-plus",
      "categories": [
        "Solid",
        "Chat"
      ]
    },
    {
      "name": "video-plus",
      "selector": "far fa-video-plus",
      "categories": [
        "Regular",
        "Chat"
      ]
    },
    {
      "name": "video-plus",
      "selector": "fal fa-video-plus",
      "categories": [
        "Light",
        "Chat"
      ]
    },
    {
      "name": "video-plus",
      "selector": "fad fa-video-plus",
      "categories": [
        "Duotone",
        "Chat"
      ]
    },
    {
      "name": "video-slash",
      "selector": "fas fa-video-slash",
      "categories": [
        "Solid",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "video-slash",
      "selector": "far fa-video-slash",
      "categories": [
        "Regular",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "video-slash",
      "selector": "fal fa-video-slash",
      "categories": [
        "Light",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "video-slash",
      "selector": "fad fa-video-slash",
      "categories": [
        "Duotone",
        "Chat",
        "Status"
      ]
    },
    {
      "name": "vihara",
      "selector": "fas fa-vihara",
      "categories": [
        "Solid",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "vihara",
      "selector": "far fa-vihara",
      "categories": [
        "Regular",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "vihara",
      "selector": "fal fa-vihara",
      "categories": [
        "Light",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "vihara",
      "selector": "fad fa-vihara",
      "categories": [
        "Duotone",
        "Buildings",
        "Religion"
      ]
    },
    {
      "name": "vimeo",
      "selector": "fab fa-vimeo",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "vimeo-square",
      "selector": "fab fa-vimeo-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "vimeo-v",
      "selector": "fab fa-vimeo-v",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "vine",
      "selector": "fab fa-vine",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "violin",
      "selector": "fas fa-violin",
      "categories": [
        "Solid",
        "Music"
      ]
    },
    {
      "name": "violin",
      "selector": "far fa-violin",
      "categories": [
        "Regular",
        "Music"
      ]
    },
    {
      "name": "violin",
      "selector": "fal fa-violin",
      "categories": [
        "Light",
        "Music"
      ]
    },
    {
      "name": "violin",
      "selector": "fad fa-violin",
      "categories": [
        "Duotone",
        "Music"
      ]
    },
    {
      "name": "vk",
      "selector": "fab fa-vk",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "vnv",
      "selector": "fab fa-vnv",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "voicemail",
      "selector": "fas fa-voicemail",
      "categories": [
        "Solid",
        "Communication"
      ]
    },
    {
      "name": "voicemail",
      "selector": "far fa-voicemail",
      "categories": [
        "Regular",
        "Communication"
      ]
    },
    {
      "name": "voicemail",
      "selector": "fal fa-voicemail",
      "categories": [
        "Light",
        "Communication"
      ]
    },
    {
      "name": "voicemail",
      "selector": "fad fa-voicemail",
      "categories": [
        "Duotone",
        "Communication"
      ]
    },
    {
      "name": "volcano",
      "selector": "fas fa-volcano",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "volcano",
      "selector": "far fa-volcano",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "volcano",
      "selector": "fal fa-volcano",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "volcano",
      "selector": "fad fa-volcano",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "volleyball-ball",
      "selector": "fas fa-volleyball-ball",
      "categories": [
        "Solid",
        "Sports",
        "Summer"
      ]
    },
    {
      "name": "volleyball-ball",
      "selector": "far fa-volleyball-ball",
      "categories": [
        "Regular",
        "Sports",
        "Summer"
      ]
    },
    {
      "name": "volleyball-ball",
      "selector": "fal fa-volleyball-ball",
      "categories": [
        "Light",
        "Sports",
        "Summer"
      ]
    },
    {
      "name": "volleyball-ball",
      "selector": "fad fa-volleyball-ball",
      "categories": [
        "Duotone",
        "Sports",
        "Summer"
      ]
    },
    {
      "name": "volume",
      "selector": "fas fa-volume",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume",
      "selector": "far fa-volume",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume",
      "selector": "fal fa-volume",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume",
      "selector": "fad fa-volume",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume-down",
      "selector": "fas fa-volume-down",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-down",
      "selector": "far fa-volume-down",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-down",
      "selector": "fal fa-volume-down",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-down",
      "selector": "fad fa-volume-down",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-mute",
      "selector": "fas fa-volume-mute",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-mute",
      "selector": "far fa-volume-mute",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-mute",
      "selector": "fal fa-volume-mute",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-mute",
      "selector": "fad fa-volume-mute",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-off",
      "selector": "fas fa-volume-off",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-off",
      "selector": "far fa-volume-off",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-off",
      "selector": "fal fa-volume-off",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-off",
      "selector": "fad fa-volume-off",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-slash",
      "selector": "fas fa-volume-slash",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume-slash",
      "selector": "far fa-volume-slash",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume-slash",
      "selector": "fal fa-volume-slash",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume-slash",
      "selector": "fad fa-volume-slash",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "volume-up",
      "selector": "fas fa-volume-up",
      "categories": [
        "Solid",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-up",
      "selector": "far fa-volume-up",
      "categories": [
        "Regular",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-up",
      "selector": "fal fa-volume-up",
      "categories": [
        "Light",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "volume-up",
      "selector": "fad fa-volume-up",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Interfaces",
        "Music",
        "Status"
      ]
    },
    {
      "name": "vote-nay",
      "selector": "fas fa-vote-nay",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "vote-nay",
      "selector": "far fa-vote-nay",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "vote-nay",
      "selector": "fal fa-vote-nay",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "vote-nay",
      "selector": "fad fa-vote-nay",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "vote-yea",
      "selector": "fas fa-vote-yea",
      "categories": [
        "Solid",
        "Political"
      ]
    },
    {
      "name": "vote-yea",
      "selector": "far fa-vote-yea",
      "categories": [
        "Regular",
        "Political"
      ]
    },
    {
      "name": "vote-yea",
      "selector": "fal fa-vote-yea",
      "categories": [
        "Light",
        "Political"
      ]
    },
    {
      "name": "vote-yea",
      "selector": "fad fa-vote-yea",
      "categories": [
        "Duotone",
        "Political"
      ]
    },
    {
      "name": "vr-cardboard",
      "selector": "fas fa-vr-cardboard",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "vr-cardboard",
      "selector": "far fa-vr-cardboard",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "vr-cardboard",
      "selector": "fal fa-vr-cardboard",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "vr-cardboard",
      "selector": "fad fa-vr-cardboard",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "vuejs",
      "selector": "fab fa-vuejs",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wagon-covered",
      "selector": "fas fa-wagon-covered",
      "categories": [
        "Solid",
        "Automotive",
        "Travel"
      ]
    },
    {
      "name": "wagon-covered",
      "selector": "far fa-wagon-covered",
      "categories": [
        "Regular",
        "Automotive",
        "Travel"
      ]
    },
    {
      "name": "wagon-covered",
      "selector": "fal fa-wagon-covered",
      "categories": [
        "Light",
        "Automotive",
        "Travel"
      ]
    },
    {
      "name": "wagon-covered",
      "selector": "fad fa-wagon-covered",
      "categories": [
        "Duotone",
        "Automotive",
        "Travel"
      ]
    },
    {
      "name": "walker",
      "selector": "fas fa-walker",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "walker",
      "selector": "far fa-walker",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "walker",
      "selector": "fal fa-walker",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "walker",
      "selector": "fad fa-walker",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "walkie-talkie",
      "selector": "fas fa-walkie-talkie",
      "categories": [
        "Solid",
        "Communication",
        "Maritime"
      ]
    },
    {
      "name": "walkie-talkie",
      "selector": "far fa-walkie-talkie",
      "categories": [
        "Regular",
        "Communication",
        "Maritime"
      ]
    },
    {
      "name": "walkie-talkie",
      "selector": "fal fa-walkie-talkie",
      "categories": [
        "Light",
        "Communication",
        "Maritime"
      ]
    },
    {
      "name": "walkie-talkie",
      "selector": "fad fa-walkie-talkie",
      "categories": [
        "Duotone",
        "Communication",
        "Maritime"
      ]
    },
    {
      "name": "walking",
      "selector": "fas fa-walking",
      "categories": [
        "Solid",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "walking",
      "selector": "far fa-walking",
      "categories": [
        "Regular",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "walking",
      "selector": "fal fa-walking",
      "categories": [
        "Light",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "walking",
      "selector": "fad fa-walking",
      "categories": [
        "Duotone",
        "Fitness",
        "Users & People"
      ]
    },
    {
      "name": "wallet",
      "selector": "fas fa-wallet",
      "categories": [
        "Solid",
        "Business",
        "Finance",
        "Objects"
      ]
    },
    {
      "name": "wallet",
      "selector": "far fa-wallet",
      "categories": [
        "Regular",
        "Business",
        "Finance",
        "Objects"
      ]
    },
    {
      "name": "wallet",
      "selector": "fal fa-wallet",
      "categories": [
        "Light",
        "Business",
        "Finance",
        "Objects"
      ]
    },
    {
      "name": "wallet",
      "selector": "fad fa-wallet",
      "categories": [
        "Duotone",
        "Business",
        "Finance",
        "Objects"
      ]
    },
    {
      "name": "wand",
      "selector": "fas fa-wand",
      "categories": [
        "Solid",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand",
      "selector": "far fa-wand",
      "categories": [
        "Regular",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand",
      "selector": "fal fa-wand",
      "categories": [
        "Light",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand",
      "selector": "fad fa-wand",
      "categories": [
        "Duotone",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand-magic",
      "selector": "fas fa-wand-magic",
      "categories": [
        "Solid",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand-magic",
      "selector": "far fa-wand-magic",
      "categories": [
        "Regular",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand-magic",
      "selector": "fal fa-wand-magic",
      "categories": [
        "Light",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wand-magic",
      "selector": "fad fa-wand-magic",
      "categories": [
        "Duotone",
        "Halloween",
        "Objects",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "warehouse",
      "selector": "fas fa-warehouse",
      "categories": [
        "Solid",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse",
      "selector": "far fa-warehouse",
      "categories": [
        "Regular",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse",
      "selector": "fal fa-warehouse",
      "categories": [
        "Light",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse",
      "selector": "fad fa-warehouse",
      "categories": [
        "Duotone",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse-alt",
      "selector": "fas fa-warehouse-alt",
      "categories": [
        "Solid",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse-alt",
      "selector": "far fa-warehouse-alt",
      "categories": [
        "Regular",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse-alt",
      "selector": "fal fa-warehouse-alt",
      "categories": [
        "Light",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "warehouse-alt",
      "selector": "fad fa-warehouse-alt",
      "categories": [
        "Duotone",
        "Buildings",
        "Logistics"
      ]
    },
    {
      "name": "washer",
      "selector": "fas fa-washer",
      "categories": [
        "Solid",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "washer",
      "selector": "far fa-washer",
      "categories": [
        "Regular",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "washer",
      "selector": "fal fa-washer",
      "categories": [
        "Light",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "washer",
      "selector": "fad fa-washer",
      "categories": [
        "Duotone",
        "Hotel",
        "Household"
      ]
    },
    {
      "name": "watch",
      "selector": "fas fa-watch",
      "categories": [
        "Solid",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "watch",
      "selector": "far fa-watch",
      "categories": [
        "Regular",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "watch",
      "selector": "fal fa-watch",
      "categories": [
        "Light",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "watch",
      "selector": "fad fa-watch",
      "categories": [
        "Duotone",
        "Date & Time",
        "Objects"
      ]
    },
    {
      "name": "watch-calculator",
      "selector": "fas fa-watch-calculator",
      "categories": [
        "Solid",
        "Computers",
        "Date & Time",
        "Mathematics",
        "Science Fiction"
      ]
    },
    {
      "name": "watch-calculator",
      "selector": "far fa-watch-calculator",
      "categories": [
        "Regular",
        "Computers",
        "Date & Time",
        "Mathematics",
        "Science Fiction"
      ]
    },
    {
      "name": "watch-calculator",
      "selector": "fal fa-watch-calculator",
      "categories": [
        "Light",
        "Computers",
        "Date & Time",
        "Mathematics",
        "Science Fiction"
      ]
    },
    {
      "name": "watch-calculator",
      "selector": "fad fa-watch-calculator",
      "categories": [
        "Duotone",
        "Computers",
        "Date & Time",
        "Mathematics",
        "Science Fiction"
      ]
    },
    {
      "name": "watch-fitness",
      "selector": "fas fa-watch-fitness",
      "categories": [
        "Solid",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "watch-fitness",
      "selector": "far fa-watch-fitness",
      "categories": [
        "Regular",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "watch-fitness",
      "selector": "fal fa-watch-fitness",
      "categories": [
        "Light",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "watch-fitness",
      "selector": "fad fa-watch-fitness",
      "categories": [
        "Duotone",
        "Fitness",
        "Medical"
      ]
    },
    {
      "name": "water",
      "selector": "fas fa-water",
      "categories": [
        "Solid",
        "Energy",
        "Maritime",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "water",
      "selector": "far fa-water",
      "categories": [
        "Regular",
        "Energy",
        "Maritime",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "water",
      "selector": "fal fa-water",
      "categories": [
        "Light",
        "Energy",
        "Maritime",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "water",
      "selector": "fad fa-water",
      "categories": [
        "Duotone",
        "Energy",
        "Maritime",
        "Summer",
        "Weather"
      ]
    },
    {
      "name": "water-lower",
      "selector": "fas fa-water-lower",
      "categories": [
        "Solid",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-lower",
      "selector": "far fa-water-lower",
      "categories": [
        "Regular",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-lower",
      "selector": "fal fa-water-lower",
      "categories": [
        "Light",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-lower",
      "selector": "fad fa-water-lower",
      "categories": [
        "Duotone",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-rise",
      "selector": "fas fa-water-rise",
      "categories": [
        "Solid",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-rise",
      "selector": "far fa-water-rise",
      "categories": [
        "Regular",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-rise",
      "selector": "fal fa-water-rise",
      "categories": [
        "Light",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "water-rise",
      "selector": "fad fa-water-rise",
      "categories": [
        "Duotone",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wave-sine",
      "selector": "fas fa-wave-sine",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "wave-sine",
      "selector": "far fa-wave-sine",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "wave-sine",
      "selector": "fal fa-wave-sine",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "wave-sine",
      "selector": "fad fa-wave-sine",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "wave-square",
      "selector": "fas fa-wave-square",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "wave-square",
      "selector": "far fa-wave-square",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "wave-square",
      "selector": "fal fa-wave-square",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "wave-square",
      "selector": "fad fa-wave-square",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "wave-triangle",
      "selector": "fas fa-wave-triangle",
      "categories": [
        "Solid",
        "Mathematics"
      ]
    },
    {
      "name": "wave-triangle",
      "selector": "far fa-wave-triangle",
      "categories": [
        "Regular",
        "Mathematics"
      ]
    },
    {
      "name": "wave-triangle",
      "selector": "fal fa-wave-triangle",
      "categories": [
        "Light",
        "Mathematics"
      ]
    },
    {
      "name": "wave-triangle",
      "selector": "fad fa-wave-triangle",
      "categories": [
        "Duotone",
        "Mathematics"
      ]
    },
    {
      "name": "waveform",
      "selector": "fas fa-waveform",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform",
      "selector": "far fa-waveform",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform",
      "selector": "fal fa-waveform",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform",
      "selector": "fad fa-waveform",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform-path",
      "selector": "fas fa-waveform-path",
      "categories": [
        "Solid",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform-path",
      "selector": "far fa-waveform-path",
      "categories": [
        "Regular",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform-path",
      "selector": "fal fa-waveform-path",
      "categories": [
        "Light",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waveform-path",
      "selector": "fad fa-waveform-path",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Music"
      ]
    },
    {
      "name": "waze",
      "selector": "fab fa-waze",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "webcam",
      "selector": "fas fa-webcam",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Computers",
        "Household"
      ]
    },
    {
      "name": "webcam",
      "selector": "far fa-webcam",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Computers",
        "Household"
      ]
    },
    {
      "name": "webcam",
      "selector": "fal fa-webcam",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Computers",
        "Household"
      ]
    },
    {
      "name": "webcam",
      "selector": "fad fa-webcam",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Computers",
        "Household"
      ]
    },
    {
      "name": "webcam-slash",
      "selector": "fas fa-webcam-slash",
      "categories": [
        "Solid",
        "Audio & Video",
        "Communication",
        "Computers"
      ]
    },
    {
      "name": "webcam-slash",
      "selector": "far fa-webcam-slash",
      "categories": [
        "Regular",
        "Audio & Video",
        "Communication",
        "Computers"
      ]
    },
    {
      "name": "webcam-slash",
      "selector": "fal fa-webcam-slash",
      "categories": [
        "Light",
        "Audio & Video",
        "Communication",
        "Computers"
      ]
    },
    {
      "name": "webcam-slash",
      "selector": "fad fa-webcam-slash",
      "categories": [
        "Duotone",
        "Audio & Video",
        "Communication",
        "Computers"
      ]
    },
    {
      "name": "weebly",
      "selector": "fab fa-weebly",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "weibo",
      "selector": "fab fa-weibo",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "weight",
      "selector": "fas fa-weight",
      "categories": [
        "Solid",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "weight",
      "selector": "far fa-weight",
      "categories": [
        "Regular",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "weight",
      "selector": "fal fa-weight",
      "categories": [
        "Light",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "weight",
      "selector": "fad fa-weight",
      "categories": [
        "Duotone",
        "Medical",
        "Objects"
      ]
    },
    {
      "name": "weight-hanging",
      "selector": "fas fa-weight-hanging",
      "categories": [
        "Solid"
      ]
    },
    {
      "name": "weight-hanging",
      "selector": "far fa-weight-hanging",
      "categories": [
        "Regular"
      ]
    },
    {
      "name": "weight-hanging",
      "selector": "fal fa-weight-hanging",
      "categories": [
        "Light"
      ]
    },
    {
      "name": "weight-hanging",
      "selector": "fad fa-weight-hanging",
      "categories": [
        "Duotone"
      ]
    },
    {
      "name": "weixin",
      "selector": "fab fa-weixin",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "whale",
      "selector": "fas fa-whale",
      "categories": [
        "Solid",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "whale",
      "selector": "far fa-whale",
      "categories": [
        "Regular",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "whale",
      "selector": "fal fa-whale",
      "categories": [
        "Light",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "whale",
      "selector": "fad fa-whale",
      "categories": [
        "Duotone",
        "Animals",
        "Maritime"
      ]
    },
    {
      "name": "whatsapp",
      "selector": "fab fa-whatsapp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "whatsapp-square",
      "selector": "fab fa-whatsapp-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wheat",
      "selector": "fas fa-wheat",
      "categories": [
        "Solid",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "wheat",
      "selector": "far fa-wheat",
      "categories": [
        "Regular",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "wheat",
      "selector": "fal fa-wheat",
      "categories": [
        "Light",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "wheat",
      "selector": "fad fa-wheat",
      "categories": [
        "Duotone",
        "Autumn",
        "Food",
        "Fruits & Vegetables"
      ]
    },
    {
      "name": "wheelchair",
      "selector": "fas fa-wheelchair",
      "categories": [
        "Solid",
        "Accessibility",
        "Health",
        "Hotel",
        "Maps",
        "Objects",
        "Users & People",
        "Vehicles"
      ]
    },
    {
      "name": "wheelchair",
      "selector": "far fa-wheelchair",
      "categories": [
        "Regular",
        "Accessibility",
        "Health",
        "Hotel",
        "Maps",
        "Objects",
        "Users & People",
        "Vehicles"
      ]
    },
    {
      "name": "wheelchair",
      "selector": "fal fa-wheelchair",
      "categories": [
        "Light",
        "Accessibility",
        "Health",
        "Hotel",
        "Maps",
        "Objects",
        "Users & People",
        "Vehicles"
      ]
    },
    {
      "name": "wheelchair",
      "selector": "fad fa-wheelchair",
      "categories": [
        "Duotone",
        "Accessibility",
        "Health",
        "Hotel",
        "Maps",
        "Objects",
        "Users & People",
        "Vehicles"
      ]
    },
    {
      "name": "whistle",
      "selector": "fas fa-whistle",
      "categories": [
        "Solid",
        "Music",
        "Security",
        "Sports"
      ]
    },
    {
      "name": "whistle",
      "selector": "far fa-whistle",
      "categories": [
        "Regular",
        "Music",
        "Security",
        "Sports"
      ]
    },
    {
      "name": "whistle",
      "selector": "fal fa-whistle",
      "categories": [
        "Light",
        "Music",
        "Security",
        "Sports"
      ]
    },
    {
      "name": "whistle",
      "selector": "fad fa-whistle",
      "categories": [
        "Duotone",
        "Music",
        "Security",
        "Sports"
      ]
    },
    {
      "name": "whmcs",
      "selector": "fab fa-whmcs",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wifi",
      "selector": "fas fa-wifi",
      "categories": [
        "Solid",
        "Communication",
        "Hotel",
        "Interfaces",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi",
      "selector": "far fa-wifi",
      "categories": [
        "Regular",
        "Communication",
        "Hotel",
        "Interfaces",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi",
      "selector": "fal fa-wifi",
      "categories": [
        "Light",
        "Communication",
        "Hotel",
        "Interfaces",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi",
      "selector": "fad fa-wifi",
      "categories": [
        "Duotone",
        "Communication",
        "Hotel",
        "Interfaces",
        "Maps",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi-1",
      "selector": "fas fa-wifi-1",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-1",
      "selector": "far fa-wifi-1",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-1",
      "selector": "fal fa-wifi-1",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-1",
      "selector": "fad fa-wifi-1",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-2",
      "selector": "fas fa-wifi-2",
      "categories": [
        "Solid",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-2",
      "selector": "far fa-wifi-2",
      "categories": [
        "Regular",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-2",
      "selector": "fal fa-wifi-2",
      "categories": [
        "Light",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-2",
      "selector": "fad fa-wifi-2",
      "categories": [
        "Duotone",
        "Interfaces",
        "Status"
      ]
    },
    {
      "name": "wifi-slash",
      "selector": "fas fa-wifi-slash",
      "categories": [
        "Solid",
        "Hotel",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi-slash",
      "selector": "far fa-wifi-slash",
      "categories": [
        "Regular",
        "Hotel",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi-slash",
      "selector": "fal fa-wifi-slash",
      "categories": [
        "Light",
        "Hotel",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wifi-slash",
      "selector": "fad fa-wifi-slash",
      "categories": [
        "Duotone",
        "Hotel",
        "Interfaces",
        "Status",
        "Toggle"
      ]
    },
    {
      "name": "wikipedia-w",
      "selector": "fab fa-wikipedia-w",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wind",
      "selector": "fas fa-wind",
      "categories": [
        "Solid",
        "Autumn",
        "Energy",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind",
      "selector": "far fa-wind",
      "categories": [
        "Regular",
        "Autumn",
        "Energy",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind",
      "selector": "fal fa-wind",
      "categories": [
        "Light",
        "Autumn",
        "Energy",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind",
      "selector": "fad fa-wind",
      "categories": [
        "Duotone",
        "Autumn",
        "Energy",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind-turbine",
      "selector": "fas fa-wind-turbine",
      "categories": [
        "Solid",
        "Energy"
      ]
    },
    {
      "name": "wind-turbine",
      "selector": "far fa-wind-turbine",
      "categories": [
        "Regular",
        "Energy"
      ]
    },
    {
      "name": "wind-turbine",
      "selector": "fal fa-wind-turbine",
      "categories": [
        "Light",
        "Energy"
      ]
    },
    {
      "name": "wind-turbine",
      "selector": "fad fa-wind-turbine",
      "categories": [
        "Duotone",
        "Energy"
      ]
    },
    {
      "name": "wind-warning",
      "selector": "fas fa-wind-warning",
      "categories": [
        "Solid",
        "Alert",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind-warning",
      "selector": "far fa-wind-warning",
      "categories": [
        "Regular",
        "Alert",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind-warning",
      "selector": "fal fa-wind-warning",
      "categories": [
        "Light",
        "Alert",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "wind-warning",
      "selector": "fad fa-wind-warning",
      "categories": [
        "Duotone",
        "Alert",
        "Maritime",
        "Weather"
      ]
    },
    {
      "name": "window",
      "selector": "fas fa-window",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "window",
      "selector": "far fa-window",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "window",
      "selector": "fal fa-window",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "window",
      "selector": "fad fa-window",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "window-alt",
      "selector": "fas fa-window-alt",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "window-alt",
      "selector": "far fa-window-alt",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "window-alt",
      "selector": "fal fa-window-alt",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "window-alt",
      "selector": "fad fa-window-alt",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "window-close",
      "selector": "fas fa-window-close",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "window-close",
      "selector": "far fa-window-close",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "window-close",
      "selector": "fal fa-window-close",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "window-close",
      "selector": "fad fa-window-close",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "window-frame",
      "selector": "fas fa-window-frame",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "window-frame",
      "selector": "far fa-window-frame",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "window-frame",
      "selector": "fal fa-window-frame",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "window-frame",
      "selector": "fad fa-window-frame",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "window-frame-open",
      "selector": "fas fa-window-frame-open",
      "categories": [
        "Solid",
        "Household"
      ]
    },
    {
      "name": "window-frame-open",
      "selector": "far fa-window-frame-open",
      "categories": [
        "Regular",
        "Household"
      ]
    },
    {
      "name": "window-frame-open",
      "selector": "fal fa-window-frame-open",
      "categories": [
        "Light",
        "Household"
      ]
    },
    {
      "name": "window-frame-open",
      "selector": "fad fa-window-frame-open",
      "categories": [
        "Duotone",
        "Household"
      ]
    },
    {
      "name": "window-maximize",
      "selector": "fas fa-window-maximize",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "window-maximize",
      "selector": "far fa-window-maximize",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "window-maximize",
      "selector": "fal fa-window-maximize",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "window-maximize",
      "selector": "fad fa-window-maximize",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "window-minimize",
      "selector": "fas fa-window-minimize",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "window-minimize",
      "selector": "far fa-window-minimize",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "window-minimize",
      "selector": "fal fa-window-minimize",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "window-minimize",
      "selector": "fad fa-window-minimize",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "window-restore",
      "selector": "fas fa-window-restore",
      "categories": [
        "Solid",
        "Code"
      ]
    },
    {
      "name": "window-restore",
      "selector": "far fa-window-restore",
      "categories": [
        "Regular",
        "Code"
      ]
    },
    {
      "name": "window-restore",
      "selector": "fal fa-window-restore",
      "categories": [
        "Light",
        "Code"
      ]
    },
    {
      "name": "window-restore",
      "selector": "fad fa-window-restore",
      "categories": [
        "Duotone",
        "Code"
      ]
    },
    {
      "name": "windows",
      "selector": "fab fa-windows",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "windsock",
      "selector": "fas fa-windsock",
      "categories": [
        "Solid",
        "Weather"
      ]
    },
    {
      "name": "windsock",
      "selector": "far fa-windsock",
      "categories": [
        "Regular",
        "Weather"
      ]
    },
    {
      "name": "windsock",
      "selector": "fal fa-windsock",
      "categories": [
        "Light",
        "Weather"
      ]
    },
    {
      "name": "windsock",
      "selector": "fad fa-windsock",
      "categories": [
        "Duotone",
        "Weather"
      ]
    },
    {
      "name": "wine-bottle",
      "selector": "fas fa-wine-bottle",
      "categories": [
        "Solid",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "wine-bottle",
      "selector": "far fa-wine-bottle",
      "categories": [
        "Regular",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "wine-bottle",
      "selector": "fal fa-wine-bottle",
      "categories": [
        "Light",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "wine-bottle",
      "selector": "fad fa-wine-bottle",
      "categories": [
        "Duotone",
        "Autumn",
        "Beverage"
      ]
    },
    {
      "name": "wine-glass",
      "selector": "fas fa-wine-glass",
      "categories": [
        "Solid",
        "Beverage",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "wine-glass",
      "selector": "far fa-wine-glass",
      "categories": [
        "Regular",
        "Beverage",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "wine-glass",
      "selector": "fal fa-wine-glass",
      "categories": [
        "Light",
        "Beverage",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "wine-glass",
      "selector": "fad fa-wine-glass",
      "categories": [
        "Duotone",
        "Beverage",
        "Maps",
        "Moving",
        "Objects",
        "Travel"
      ]
    },
    {
      "name": "wine-glass-alt",
      "selector": "fas fa-wine-glass-alt",
      "categories": [
        "Solid",
        "Beverage",
        "Travel"
      ]
    },
    {
      "name": "wine-glass-alt",
      "selector": "far fa-wine-glass-alt",
      "categories": [
        "Regular",
        "Beverage",
        "Travel"
      ]
    },
    {
      "name": "wine-glass-alt",
      "selector": "fal fa-wine-glass-alt",
      "categories": [
        "Light",
        "Beverage",
        "Travel"
      ]
    },
    {
      "name": "wine-glass-alt",
      "selector": "fad fa-wine-glass-alt",
      "categories": [
        "Duotone",
        "Beverage",
        "Travel"
      ]
    },
    {
      "name": "wix",
      "selector": "fab fa-wix",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wizards-of-the-coast",
      "selector": "fab fa-wizards-of-the-coast",
      "categories": [
        "Brands",
        "Tabletop Gaming"
      ]
    },
    {
      "name": "wolf-pack-battalion",
      "selector": "fab fa-wolf-pack-battalion",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "won-sign",
      "selector": "fas fa-won-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "won-sign",
      "selector": "far fa-won-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "won-sign",
      "selector": "fal fa-won-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "won-sign",
      "selector": "fad fa-won-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "wordpress",
      "selector": "fab fa-wordpress",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wordpress-simple",
      "selector": "fab fa-wordpress-simple",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wpbeginner",
      "selector": "fab fa-wpbeginner",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wpexplorer",
      "selector": "fab fa-wpexplorer",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wpforms",
      "selector": "fab fa-wpforms",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wpressr",
      "selector": "fab fa-wpressr",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "wreath",
      "selector": "fas fa-wreath",
      "categories": [
        "Solid",
        "Holiday",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "wreath",
      "selector": "far fa-wreath",
      "categories": [
        "Regular",
        "Holiday",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "wreath",
      "selector": "fal fa-wreath",
      "categories": [
        "Light",
        "Holiday",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "wreath",
      "selector": "fad fa-wreath",
      "categories": [
        "Duotone",
        "Holiday",
        "Objects",
        "Spinners"
      ]
    },
    {
      "name": "wrench",
      "selector": "fas fa-wrench",
      "categories": [
        "Solid",
        "Construction",
        "Editors",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "wrench",
      "selector": "far fa-wrench",
      "categories": [
        "Regular",
        "Construction",
        "Editors",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "wrench",
      "selector": "fal fa-wrench",
      "categories": [
        "Light",
        "Construction",
        "Editors",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "wrench",
      "selector": "fad fa-wrench",
      "categories": [
        "Duotone",
        "Construction",
        "Editors",
        "Interfaces",
        "Maps",
        "Objects"
      ]
    },
    {
      "name": "x-ray",
      "selector": "fas fa-x-ray",
      "categories": [
        "Solid",
        "Medical"
      ]
    },
    {
      "name": "x-ray",
      "selector": "far fa-x-ray",
      "categories": [
        "Regular",
        "Medical"
      ]
    },
    {
      "name": "x-ray",
      "selector": "fal fa-x-ray",
      "categories": [
        "Light",
        "Medical"
      ]
    },
    {
      "name": "x-ray",
      "selector": "fad fa-x-ray",
      "categories": [
        "Duotone",
        "Medical"
      ]
    },
    {
      "name": "xbox",
      "selector": "fab fa-xbox",
      "categories": [
        "Brands",
        "Games"
      ]
    },
    {
      "name": "xing",
      "selector": "fab fa-xing",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "xing-square",
      "selector": "fab fa-xing-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "y-combinator",
      "selector": "fab fa-y-combinator",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yahoo",
      "selector": "fab fa-yahoo",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yammer",
      "selector": "fab fa-yammer",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yandex",
      "selector": "fab fa-yandex",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yandex-international",
      "selector": "fab fa-yandex-international",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yarn",
      "selector": "fab fa-yarn",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yelp",
      "selector": "fab fa-yelp",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "yen-sign",
      "selector": "fas fa-yen-sign",
      "categories": [
        "Solid",
        "Currency"
      ]
    },
    {
      "name": "yen-sign",
      "selector": "far fa-yen-sign",
      "categories": [
        "Regular",
        "Currency"
      ]
    },
    {
      "name": "yen-sign",
      "selector": "fal fa-yen-sign",
      "categories": [
        "Light",
        "Currency"
      ]
    },
    {
      "name": "yen-sign",
      "selector": "fad fa-yen-sign",
      "categories": [
        "Duotone",
        "Currency"
      ]
    },
    {
      "name": "yin-yang",
      "selector": "fas fa-yin-yang",
      "categories": [
        "Solid",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "yin-yang",
      "selector": "far fa-yin-yang",
      "categories": [
        "Regular",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "yin-yang",
      "selector": "fal fa-yin-yang",
      "categories": [
        "Light",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "yin-yang",
      "selector": "fad fa-yin-yang",
      "categories": [
        "Duotone",
        "Religion",
        "Spinners"
      ]
    },
    {
      "name": "yoast",
      "selector": "fab fa-yoast",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "youtube",
      "selector": "fab fa-youtube",
      "categories": [
        "Brands",
        "Audio & Video"
      ]
    },
    {
      "name": "youtube-square",
      "selector": "fab fa-youtube-square",
      "categories": [
        "Brands"
      ]
    },
    {
      "name": "zhihu",
      "selector": "fab fa-zhihu",
      "categories": [
        "Brands"
      ]
    }
  ]