import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TpaConnectionDataService } from "@app/external-connections/services/tpa-connection.data.service";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { nameof } from "@methods/jeffs-toolkit";
import { isGuid } from "@methods/uniqueMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { ControlSummaryModel } from "../models/ControlSummaryModel";
import { TpaRequestDetailModel } from "../models/TpaRequestDetailModel";

@Component({
    selector: "app-tpa-request-detail",
    templateUrl: "./tpa-request-detail.component.html",
    styleUrls: ["./tpa-request-detail.component.scss"],
})
export class TpaRequestDetailComponent implements OnInit {
    tabID: string;
    tab: TabModel;
    id: string;

    tpaRequestDetailModel: TpaRequestDetailModel;

    validConfig: GenericListConfig;
    invalidConfig: GenericListConfig;

    rendererConfig: RendererConfig[] = [
        RendererConfig.createScoreConfig(
            nameof<ControlSummaryModel>((x: ControlSummaryModel): string => x.effectivenessScore),
            nameof<ControlSummaryModel>(
                (x: ControlSummaryModel): string => x.effectivenessScoreColor
            )
        ),
        RendererConfig.createScoreConfig(
            nameof<ControlSummaryModel>((x: ControlSummaryModel): string => x.latestPeriodScore),
            nameof<ControlSummaryModel>(
                (x: ControlSummaryModel): string => x.latestPeriodScoreColor
            )
        ),
    ];

    constructor(
        private route: ActivatedRoute,
        private _pages: Pages,
        private _ds: TpaConnectionDataService,
        private _tabService: TabService,
        private _toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;
        this.setup();
    }

    setup(): void {
        const isExisting = this.id && isGuid(this.id.toString());
        if (!isExisting) {
            this._toastr.error("Not found", "The requested item was not found");
            this.tab.close(false);
            return;
        }

        this.tpaRequestDetailModel = this.getModel();
    }

    getModel(): TpaRequestDetailModel {
        const modelCall: Observable<TpaRequestDetailModel> = this._ds.getrequestDetail(this.id);

        modelCall.subscribe((x: TpaRequestDetailModel): void => {
            this.tpaRequestDetailModel = JSON.parse(JSON.stringify(x));
            this.initValidControls();
            this.initInvalidControls();

            this.tab.showLoader = false;
        });
        return;
    }

    initValidControls(): void {
        this.validConfig = <GenericListConfig>{
            name: "Valid controls",
            data: this.tpaRequestDetailModel.validControls,
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            idProp: nameof<ControlSummaryModel>((x: ControlSummaryModel): string => x.guid),
            isSortableByColumn: true,
            limitViewTo: 5,
            overviewRowActionsName: "",
            overviewRowActions: [
                {
                    icon: "fas fa-edit",
                    tooltip: "Open control",
                    clickEvent: (row: ControlSummaryModel): void => {
                        this.openControl(row);
                    },
                },
            ],
            rendererConfig: this.rendererConfig,
            editOverride: (row: any): void => {
                this.openControl(row);
            }, // only overview
            fields: [
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.guid
                    ),
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                    hideInEdit: true,
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.identifier
                    ),
                    prettyName: "Control identifier",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.name
                    ),
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.keyControl
                    ),
                    prettyName: "Is key control",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.organization
                    ),
                    prettyName: "Organization",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.owner
                    ),
                    prettyName: "Owner",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.effectivenessScore
                    ),
                    prettyName: "Effectiveness score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    selfInit: true,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.effectivenessScoreColor
                    ),
                    prettyName: "Effectiveness score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    isReadonly: true,
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.latestPeriodScore
                    ),
                    prettyName: "Latest period score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    isReadonly: true,
                    selfInit: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.latestPeriodScoreColor
                    ),
                    prettyName: "Latest period score color",
                    fieldType: GenericListFieldType.ColoredSelect,
                    isReadonly: true,
                    hideInOverview: true,
                },
            ],
        };
    }

    initInvalidControls(): void {
        this.invalidConfig = <GenericListConfig>{
            name: "Invalid controls",
            data: this.tpaRequestDetailModel.invalidControls,
            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            idProp: nameof<ControlSummaryModel>((x: ControlSummaryModel): string => x.guid),
            isSortableByColumn: true,
            limitViewTo: 5,
            overviewRowActionsName: "",
            overviewRowActions: [
                {
                    icon: "fas fa-edit",
                    tooltip: "Open control",
                    clickEvent: (row: ControlSummaryModel): void => {
                        this.openControl(row);
                    },
                },
            ],
            rendererConfig: this.rendererConfig,
            editOverride: (row: any): void => {
                this.openControl(row);
            }, // only overview
            fields: [
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.guid
                    ),
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                    hideInEdit: true,
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.identifier
                    ),
                    prettyName: "Control identifier",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.name
                    ),
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.keyControl
                    ),
                    prettyName: "Is key control",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.organization
                    ),
                    prettyName: "Organization",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.owner
                    ),
                    prettyName: "Owner",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.effectivenessScore
                    ),
                    prettyName: "Effectiveness score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    selfInit: true,
                    isReadonly: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.effectivenessScoreColor
                    ),
                    prettyName: "Effectiveness score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    isReadonly: true,
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<ControlSummaryModel>(
                        (x: ControlSummaryModel): string => x.reasonFailed
                    ),
                    prettyName: "Reason failed",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
            ],
        };
    }

    openControl(row: ControlSummaryModel): void {
        if (isGuid(row.guid)) {
            this._tabService.generateTab(this._pages.ControlDetail, row.guid, row.name);
        } else {
            this._toastr.warning("Requested item not found", "Not found");
        }
    }
}
