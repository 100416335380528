<div class="classification-edit">
    <div class="actions">
        <cerrix-button
            *ngIf="!showSavedButton"
            [disabled]="!form.dirty || !form.valid || loading"
            color="primary-light"
            type="stroked"
            (click)="onSave()"
            ><ng-container>Save</ng-container
            ><mat-icon fontSet="material-symbols-outlined">check_small</mat-icon></cerrix-button
        >

        <cerrix-button *ngIf="showSavedButton" color="accent-light" type="flat"
            ><ng-container>Saved!</ng-container
            ><mat-icon fontSet="material-symbols-outlined">check_small</mat-icon></cerrix-button
        >

        <cerrix-button
            (click)="onDelete()"
            [disabled]="!this.form.value.id || loading"
            color="error"
            >Delete</cerrix-button
        >
    </div>

    <div *ngIf="loading" style="flex: 1">
        <div style="margin-top: -74px" class="fancy-loader submodule"></div>
    </div>

    <form *ngIf="!loading" class="form-container" [formGroup]="form">
        <cerrix-title
            placeholder="Enter classification name"
            size="large"
            clipText="true"
            formControlName="name"
        ></cerrix-title>

        <div class="content">
            <cerrix-field-wrapper
                label="Value"
                info="Value is the weight that can be added to the score. You can use this score to compare values to each other."
                [required]="true"
                type="secondary"
                style="width: 420px"
            >
                <cerrix-input-numeric formControlName="value"></cerrix-input-numeric>
            </cerrix-field-wrapper>

            <cerrix-field-wrapper
                label="Select color"
                info="This is the color that will show next to the classification name."
                [required]="true"
                type="secondary"
            >
                <cerrix-color-picker formControlName="color"></cerrix-color-picker>
            </cerrix-field-wrapper>
        </div>
    </form>
</div>
