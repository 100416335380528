import { Component } from "@angular/core";
import { AbstractEffTestingStatusComponent } from "../abstract-eff-testing-status-component";
import { EffectivenessStatusType, SampleScore, TestStepScore } from "../../../shared/enums";
import { EffTableConfig } from "../../common/eff-table/models/eff-table-config.model";
import { EffTableColumnConfig } from "../../common/eff-table/models/eff-table-column-config.model";
import { EvidenceSampleTestStep } from "../models/evidence-sample-step.model";
import { FinalConclusion } from "../components/eff-final-conclusion-box/models/final-conclusion.model";

@Component({
    selector: "eff-testing-review",
    templateUrl: "./eff-testing-review.component.html",
    styleUrls: ["./eff-testing-review.component.scss"],
})
export class EffTestingReviewComponent extends AbstractEffTestingStatusComponent {
    protected loadSamples: boolean = true;
    protected hideSampling = false;

    isNonOccurrenceTestPlan = false;

    tableConfig: EffTableConfig = {
        ExpandableRow: true,
        SeparateRows: true,
        RowClassFunc(item: EvidenceSampleTestStep) {
            if (item.isSample) {
                return "sample-row";
            }
            return "";
        },
    };

    displayedColumns: Array<EffTableColumnConfig> = [
        { PropertyName: "name", DisplayedName: "Name", IsTemplate: true, Width: "25%" },
        {
            PropertyName: "scoreTester",
            DisplayedName: "Score tester",
            IsTemplate: true,
            Width: "25%",
        },
        {
            PropertyName: "testerComment",
            DisplayedName: "Comment tester",
            IsTemplate: true,
            Width: "25%",
        },
        {
            PropertyName: "reviewerComment",
            DisplayedName: "Comment reviewer",
            IsTemplate: true,
            Width: "25%",
        },
    ];

    override onLoad() {
        this.isNonOccurrenceTestPlan =
            this.effectivenessModel.status == EffectivenessStatusType.reviewNonOccurrence;

        this.hideSampling = this.isNonOccurrenceTestPlan;
    }

    getScoreColor(item: EvidenceSampleTestStep) {
        if (!item.isSample) {
            switch (item.stepScore) {
                case TestStepScore.correct:
                    return "score-correct";
                case TestStepScore.inapplicable:
                    return "score-inapplicable";
                case TestStepScore.incorrect:
                    return "score-incorrect";
                case TestStepScore.missingEvidence:
                    return "score-missing-evidence";
                case TestStepScore.noScore:
                    return "no-score";
            }
        } else {
            switch (item.sampleScore) {
                case SampleScore.sufficient:
                    return "score-correct";
                case SampleScore.inapplicable:
                    return "score-inapplicable";
                case SampleScore.insufficient:
                    return "score-incorrect";
                case SampleScore.noScore:
                    return "no-score";
            }
        }
    }

    async accept() {
        this.giveFinalConclusion(
            this.getWorkflowPromptTitle("Accept"),
            "Accept",
            "Are you sure ? Please leave a comment",
            (async (finalConclusion: FinalConclusion) => {
                await this.saveChanges(false);

                const call = this.isNonOccurrenceTestPlan
                    ? this._workflowDs.postWorkflowNonOccurrenceAcceptReviewer(
                          this.effectivenessModel.guid,
                          {
                              comment: finalConclusion.conclusion,
                              scoreId: finalConclusion.score,
                          }
                      )
                    : this._workflowDs.postWorkflowAcceptReviewer(this.effectivenessModel.guid, {
                          comment: finalConclusion.conclusion,
                          scoreId: finalConclusion.score,
                      });

                call.subscribe(() => {
                    this.cerrixTab.refresh();
                });
            }).bind(this),
            "600px",
            true
        );
    }

    async modify() {
        this.giveFinalConclusion(
            this.getWorkflowPromptTitle("Accept with modification"),
            "Modify",
            "Are you sure ? Please leave a score and a comment",
            (async (finalConclusion: FinalConclusion) => {
                await this.saveChanges(false);

                const call = this.isNonOccurrenceTestPlan
                    ? this._workflowDs.postWorkflowNonOccurrenceModifyReviewer(
                          this.effectivenessModel.guid,
                          {
                              comment: finalConclusion.conclusion,
                              scoreId: finalConclusion.score,
                          }
                      )
                    : this._workflowDs.postWorkflowModifyReviewer(this.effectivenessModel.guid, {
                          comment: finalConclusion.conclusion,
                          scoreId: finalConclusion.score,
                      });

                call.subscribe(() => {
                    this.cerrixTab.refresh();
                });
            }).bind(this)
        );
    }

    async reject() {
        this.giveFinalConclusion(
            this.getWorkflowPromptTitle("Reject"),
            "Reject",
            "Are you sure you want to reject this test?\n Please give a comment for this action.",
            (async (finalConclusion: FinalConclusion) => {
                await this.saveChanges(false);

                const call = this.isNonOccurrenceTestPlan
                    ? this._workflowDs.postWorkflowNonOccurrenceRejectReviewer(
                          this.effectivenessModel.guid,
                          {
                              comment: finalConclusion.conclusion,
                              scoreId: finalConclusion.score,
                          }
                      )
                    : this._workflowDs.postWorkflowRejectReviewer(this.effectivenessModel.guid, {
                          comment: finalConclusion.conclusion,
                          scoreId: finalConclusion.score,
                      });

                call.subscribe(() => {
                    this.cerrixTab.refresh();
                });
            }).bind(this),
            "600px",
            true
        );
    }

    private getWorkflowPromptTitle(action: string): string {
        return action + (this.isNonOccurrenceTestPlan ? " non occurrence" : "");
    }

    async saveChanges(doRefresh: boolean) {
        await this.saveSampleChanges();
        if (doRefresh) {
            this.cerrixTab.refresh();
        }
    }
}
