import { OnInit, Component, Input } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { FormatType } from "@enums/FormatType";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { SentMailService } from "@app/mailmessages/service/sentmail.service";
import { SentNotificationOverviewModel } from "@app/mailmessages/models/sentNotificationOvervieuwModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "uc-sentnotification-overview",
    templateUrl: "./sentnotification-overview.component.html",
    styleUrls: ["./sentnotification-overview.component.scss"],
})
export class SentNotificationOverviewComponent implements OnInit {
    @Input() parentTab: TabModel;

    data: Promise<SentNotificationOverviewModel[]>;

    tabID: string;
    moduleType: ModuleType.Admin;
    tab: TabModel;
    headerLookup: any = {
        HasError: "Error",
    };

    dateProps: string[] = ["Date"];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Date",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: "HasError",
            actionColumn: "HasError",
            actionValue: true,
            extraValue: "fas fa-exclamation-triangle",
            type: RendererType.Icon,
            columnWidth: 20,
        },
    ];
    filterConfigs: FilterConfig[] = [];

    permissions: WorkspaceModulePermissionModel = {
        canAdd: false,
        canOpen: true,
        canPublish: false,
        isModuleAdmin: false,
        canSetSystemDefault: false,
    };

    constructor(
        private _sentMailService: SentMailService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        this.tab = this.parentTab;
        this.data = this._sentMailService
            .getSentMails()
            .toPromise()
            .then((rows) => {
                this.mapHeaders(rows);
                this.tab.showLoader = false;
                return rows;
            });
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    openMailMessage(openedItem: SentNotificationOverviewModel) {
        const prompt = this._promptService.prompt({
            maxWidth: "1200px",
            maxHeight: "1000px",
            data: {
                title: "Loading mail, please wait...",
                showLoader: true,
                confirmButton: {
                    show: false,
                },
                cancelButton: {
                    text: "Close",
                },
            },
        });

        this._sentMailService.getSentMailDetail(openedItem.ID).subscribe((mail) => {
            prompt.data.title = "Sent mail preview";
            prompt.data.showLoader = false;
            prompt.data.fieldResult = mail;
            prompt.data.fields = [
                {
                    prettyName: "Recipients",
                    fieldName: "Recipients",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    prettyName: "Subject",
                    fieldName: "Subject",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    prettyName: "Error",
                    fieldName: "Error",
                    fieldType: GenericListFieldType.PreBox,
                },
                {
                    prettyName: "Message",
                    fieldName: "Body",
                    fieldType: GenericListFieldType.RenderBox,
                },
            ];

            if (!mail.HasError) {
                prompt.data.fields.splice(2, 1);
            }
        });
    }
}
