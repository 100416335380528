<div class="cerrix-detail-page" menuSystem [cerrixTab]="tab" data-uiid="document-templates-page">
    <div [attr.name]="'Document Templates'" menuicon="fal fa-file-word" menusystemsubmenu>
        <div
            *ngIf="permissions.HasAuditAccess"
            name="Audit"
            menuItemId="audit"
            menuicon="fal fa-clipboard-list-check"
            menusystemitem
        >
            <document-templates-module
                [targetModule]="TargetModule.Audit"
                (saved)="onSaved()"
            ></document-templates-module>
        </div>
    </div>
</div>
