import { KeyValue } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { parseTyped } from "@methods/CommonMethods";
import { FormField, FormFieldConfig } from "@app/forms/models";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { StandingDataItemModel } from "@app/standing-data/models/standing-data-item.model";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { NewIncidentStructureTypes } from "@app/forms/shared/constants/NewIncidentStructureTypes";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "form-entry-structure",
    templateUrl: "./form-entry-structure.component.html",
    styleUrls: ["./form-entry-structure.component.scss"],
})
export class FormEntryStructureComponent implements OnInit {
    @Input() field: FormField;
    @Input() answer: string;
    @Input() readonly: boolean;
    @Output() answerChange: EventEmitter<string> = new EventEmitter<string>();

    saveSeperator = ";";

    selectedOptions: any;
    isMultiselect = false;
    isTreeSource = true;
    loaded = false;
    useIncidents = false;

    constructor(private _settingsService: SettingsDataService) {}

    async ngOnInit() {
        this.useIncidents = (
            await firstValueFrom(this._settingsService.getSetting(ApplicationSettings.UseIncidents))
        ).BoolValue;

        const config = parseTyped<FormFieldConfig>(
            this.field.CustomSettings,
            new FormFieldConfig()
        );
        this.isMultiselect = config.multiSelect;
        this.field.SourceDataCall.then((x) => {
            if (
                this.useIncidents &&
                NewIncidentStructureTypes.newIncidentStructures.find(
                    (is) => is == config.structureType
                )
            ) {
                this.field.SourceData = this.mapToStructureData(x, config.parameters);
                this.isTreeSource = false;
            } else {
                this.field.SourceData = x;
                if (this.field.SourceData) {
                    this.isTreeSource = this.field.SourceData.some((item) => item["Children"]);
                }
            }

            this.setSelectedData();

            this.loaded = true;
        });
    }

    mapToStructureData(
        incidentStructureData: StandingDataItemModel[],
        allowedOptions: number[]
    ): IdNameColorModel[] {
        let filteredData = incidentStructureData;
        if (allowedOptions && allowedOptions.any()) {
            filteredData = incidentStructureData.filter((item) =>
                allowedOptions.some((x) => x === +item.id)
            );
        }

        return filteredData.map((item) => {
            return <IdNameColorModel>{
                ID: +item.id,
                Name: item.name,
                Color: item.color,
            };
        });
    }

    setSelectedData() {
        const pairs = parseTyped<KeyValue<any, any>[]>(this.answer, []);
        if (this.isMultiselect) {
            this.selectedOptions = pairs.map((x) => x.key);
        } else {
            this.selectedOptions = pairs.length > 0 ? pairs[0].key : null;
        }
    }

    emitChange() {
        const objects = this.getObjects();
        const pairs = objects.map((x) => <KeyValue<any, any>>{ key: x["ID"], value: x["Name"] });
        this.answer = JSON.stringify(pairs);
        this.answerChange.emit(this.answer);
    }

    getObjects(): any[] {
        if (this.isTreeSource) {
            const foundItems: CerrixTreeItem[] = [];

            let list: CerrixTreeItem[] = [];
            list.addRange(this.field.SourceData);

            while (list.length > 0) {
                const item = list.pop();
                if (this.isMultiselect) {
                    if (this.selectedOptions.some((option) => +option === item.ID)) {
                        foundItems.push(item);
                    }
                } else {
                    if (+this.selectedOptions === item.ID) {
                        foundItems.push(item);
                    }
                }

                if (item.Children) {
                    list = item.Children.concat(list);
                }
            }

            return foundItems;
        } else {
            const foundItems = (this.field.SourceData as any[]).filter(
                (x) =>
                    this.selectedOptions &&
                    ((!this.isMultiselect && this.selectedOptions === x["ID"]) ||
                        (this.isMultiselect && this.selectedOptions.indexOf(x["ID"]) >= 0))
            );

            return foundItems;
        }
    }
}
