import { RecurrenceFrequency } from "@app/shared/components/cerrix-recurrence/cerrix-recurrence-frequency.enum";

export class RecurrenceViewModel {
    id: number;
    guid: string;
    frequency: RecurrenceFrequency = RecurrenceFrequency.Monthly;
    startdate: Date;
    enddate: Date;
    interval: number;
    count: number;
    otherByDayType: number;
    otherByDay: string;
    byDay: string;
    byMonthDay: number;
    byMonth: number;

    // settings
    monthlydaynumber: number;
    radioSelection: string;
}
