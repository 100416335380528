import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { ControlRaciModel } from "./ControlRaciModel";

export class ControlModel {
    Guid: string = undefined;
    Identifier: string = undefined;
    Name: string = undefined;
    Organization: number = undefined;
    Owner: string = undefined;
    DateCreated: Date = undefined;
    LastModifier: string = undefined;
    DateModified: Date = undefined;
    ControlCatalogue?: number = undefined;
    ControlCatalogueCanOverride: boolean = undefined;
    Description: string = undefined;
    Type?: number = undefined;
    Frequency?: number = undefined;
    Source?: number = undefined;
    AspectIc?: number = undefined;
    ControlExecutionMethod?: number = undefined;
    ControlObjective: string = undefined;
    InitialCosts?: number = undefined;
    AnnualCosts?: number = undefined;
    ConclusionSummary: string = undefined;
    Comments: string = undefined;
    BusinessDimensions: number[] = [];
    FrameworkDimensions: number[] = [];
    Raci?: ControlRaciModel[] = [];

    KeyControl: boolean = true;
    RequiresMonitoring: boolean = true;
    RequiresExecution: boolean = false;
    InPlace: boolean = true;
    IsShared: boolean = false;

    DiExists: boolean = undefined;
    EffExists: boolean = undefined;

    StartDate?: Date = undefined;
    EndDate?: Date = undefined;

    RiskIdList: number[] = [];
    MoiIdList: number[] = [];
    PpIdList: number[] = [];
    TpIdList: number[] = [];
    FormsIdList: number[] = [];
    ApplicableOrganizations: number[] = [];

    Documents: DocumentModel[] = [];
    Hyperlinks: HyperlinkModel[] = undefined;

    CustomFields: CustomFieldDetailModel[] = [];

    ProcessedDocumentControlId: number = undefined;
    ReasonUpdate: string = "";

    constructor(jsonString: string = "") {
        this.parseJson(jsonString);
    }

    parseJson = (jsonString: string) => {
        try {
            const parsedModel = JSON.parse(jsonString);
            Object.keys(parsedModel).forEach((x) => {
                if (Object.prototype.hasOwnProperty.call(this, x)) {
                    this[x] = parsedModel[x];
                }
            });
        } catch (e) {}
    };

    getLinks = () => {
        return {
            Documents: this.Documents,
            FormsIdList: this.FormsIdList,
            Hyperlinks: this.Hyperlinks,
            MoiIdList: this.MoiIdList,
            PpIdList: this.PpIdList,
            RiskIdList: this.RiskIdList,
        };
    };
}
