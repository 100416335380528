<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export Controls</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <hr />

        <button
            class="btn btn-default mr-2 mb-2"
            (click)="download(ExportType.ControlAdvancedTestingHistory)"
        >
            Changes in Advanced Effectiveness testing
        </button>
    </div>
</div>
