import { MapbackProperty } from "@app/forms/models/mapback/MapbackProperty";
import { HttpEventType } from "@angular/common/http";
import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    OnInit,
    ViewChild,
    ViewContainerRef,
    AfterContentInit,
} from "@angular/core";
import { Pages } from "@constants/pages/Pages";
import { FormFieldType, FormFieldTypeDescription } from "@app/forms/enums/FormFieldTypes";
import { FormPageType } from "@app/forms/enums/FormPageType";
import { FormDesignPageValidation } from "@app/forms/shared/methods/FormDesignPageValidation";
import { CustomSortableOptions } from "@methods/PageMethods";
import { Form, FormField, FormPage } from "@app/forms/models/detail";
import HttpStatusCode from "@models/generic/HttpRequest/HttpStatusCode";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { TabService } from "@services/tabs/TabService";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { FormMainSettingsComponent } from "./modals/mainsettings/form-main-settings.component";
import { FormPageSettingsComponent } from "./modals/pagesettings/form-page-settings.component";
import { FormWorkflowSettingsComponent } from "./modals/workflowsettings/form-workflow-settings.component";
import { guid } from "@methods/uniqueMethods";
import { FormPermissionModel } from "@models/permissions/FormPermissionModel";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { IsMobile, parseTyped } from "@methods/CommonMethods";
import { finalize } from "rxjs/operators";
import { FormDraft } from "@app/forms/models/detail/FormDraft";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { SortableOptions } from "sortablejs";

declare var $: any;

@Component({
    selector: "form-detail",
    templateUrl: "./form-detail.component.html",
    styleUrls: ["./form-detail.component.scss"],
})
export class FormDetailComponent implements OnInit, AfterContentInit {
    tabID: string;
    tab: TabModel;

    id: string;
    form: Form;
    mapbackProperties: MapbackProperty[];

    typeDesc = FormFieldTypeDescription;
    sortingOptions: SortableOptions;

    pageType = FormPageType;

    activePage: number;
    postRunning: boolean;

    expandedFieldId: number = null;
    selectedFieldCount = 0;
    fieldClipboard: FormField[] = [];
    cutItemsInClipboard = false;

    permissions: FormPermissionModel;
    securityToast: ActiveToast<any>;

    validationToast: ActiveToast<any>;
    savingPrompt: CerrixPromptComponent;

    @ViewChild("customComponent", { read: ViewContainerRef, static: true }) customComponent;
    @ViewChild("popupModal", { static: true }) popupModal: ElementRef;

    //#region Init
    constructor(
        private _formDS: FormsDataService,
        permService: PermissionsService,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private toastr: ToastrService,
        private _pages: Pages,
        private _tabService: TabService,
        private _promptService: CerrixPromptService
    ) {
        this.permissions = permService.permissions.Forms;

        if (this.id) {
            if (!this.form.CanEdit) {
                this.securityException(true);
            }
        } else {
            if (!this.permissions.IsCreator) {
                this.securityException(true);
            }
        }

        this.sortingOptions = CustomSortableOptions({
            handle: ".mat-expansion-panel-header",
        });
    }

    async ngOnInit() {
        let form: Form;
        if (this.tab.config && this.tab.config.draftToLoad) {
            form = this.tab.config.draftToLoad;
        } else if (+this.id) {
            form = await this._formDS.getForm(+this.id).toPromise();
        } else {
            form = this.getNewForm();
        }

        this.initForm(form);

        // Bind model hidden event
        $("#form-detail-modal").on("hidden.bs.modal", function (e) {
            if (this.customComponent) {
                this.customComponent.clear();
            }
        });
    }

    ngAfterContentInit() {
        if (
            this.form &&
            (!this.form.ID || this.form.ID <= 0) &&
            (!this.tab.config || !this.tab.config.draftToLoad)
        ) {
            this.openEditFormWindow();
        }
    }

    initForm(data: Form) {
        this.form = data;

        this.setTabTitle();
        this.activateMenuItem(this.form.Pages[0].ID);
        this.updateMenu();

        this.form.Pages.forEach((page) => {
            page.Fields.forEach((field) => {
                field.Valid = true;
                field.Validation = null;
            });
        });

        this.checkNewFormRights();
        this.checkMapbacking();
        this.checkMobile();
    }

    setTabTitle() {
        if (this.form.LoadedDraftID > 0) {
            this.tab.name = `draft - ${this.form.Title}`;
        } else if (this.form.ID > 0) {
            this.tab.name = `edit - ${this.form.Title}`;
        }
    }

    //#endregion Init

    //#region FormObject

    async getReleaseConfirmation() {
        const confirmations = [];
        if (this.form.ID > 0) {
            const hasOpenResults = await this._formDS.hasOpenResults(this.form.ID).toPromise();
            if (hasOpenResults) {
                confirmations.push("This will release the form and update all open results.");
            }
        }

        if (confirmations.any()) {
            let msg = "Do you want to continue?";
            if (confirmations.any()) {
                msg = `${confirmations.join("\n")}\n${msg}`;
            }

            const confirmPrompt = this._promptService.confirm("Continue with release?", msg);

            return await confirmPrompt.toPromise();
        }

        return true;
    }

    showSaveModal() {
        // In case another one is open close that one first.
        if (!this.savingPrompt) {
            this.savingPrompt = this._promptService.loader("Releasing form, please wait...");
        }
    }

    closeSaveModal() {
        if (this.savingPrompt) {
            this.savingPrompt.close();
            this.savingPrompt = null;
        }
    }

    private validateForm(toValidate: Form): boolean {
        if (this.validationToast) {
            this.validationToast.portal.destroy();
        }

        const formValidations = FormDesignPageValidation.ValidateForm(
            toValidate,
            this.mapbackProperties
        );

        if (formValidations.any()) {
            this.validationToast = this.toastr.error(
                formValidations.join("<br>"),
                "Form validation failed",
                { enableHtml: true, timeOut: 0, extendedTimeOut: 0, closeButton: true }
            );

            return false;
        }

        return true;
    }

    async saveForm() {
        if (!(await this.getReleaseConfirmation())) {
            return;
        }

        const callback = (cleared: boolean) => {
            if (cleared) {
                this.showSaveModal();

                if (!this.validateForm(this.form)) {
                    this.closeSaveModal();
                    return;
                }

                this.executeSave();
            }
        };

        this.clearClipboard(callback);
    }

    private executeSave() {
        this._formDS
            .storeForm(this.form)
            .pipe(finalize(() => this.closeSaveModal()))
            .subscribe(
                (event) => {
                    this.tab.id = event.toString();

                    if (this.tab.config)
                        //When creating a new form, this is null
                        this.tab.config.draftToLoad = null;

                    this.toastr.success("", "Update completed");
                    this.tab.refresh();
                    if (this.tab.parent && this.tab.parent.lookupname === this._pages.Forms) {
                        this.tab.parent.refresh();
                    }

                    this.postRunning = false;
                },
                function onError() {
                    this.postRunning = false;
                },
                function onComplete() {
                    this.postRunning = false;
                }
            );
    }

    exportForm() {
        if (!this.permissions.IsExporter) {
            this.securityException(false);
            return;
        }

        const copyForm = JSON.parse(JSON.stringify(this.form)) as Form;
        copyForm["EXPORTURL"] = window.location.origin;
        this.resetAllIds(copyForm);

        const fileName = (this.form.Title ? this.form.Title : "FormObject") + ".json";
        this.download(fileName, copyForm);
    }

    download(filename: string, text: any) {
        const element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(text))
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    upload(files) {
        if (!this.permissions.IsImporter || (this.form.ID && this.form.ID > 0)) {
            this.securityException(false);
            return;
        }

        const selectedFile: File = files.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            const form: Form = JSON.parse(reader.result as string);

            if (form["EXPORTURL"] !== window.location.origin) {
                this.resetCrossSiteSettings(form);
            }

            this.setDefaultValues(form);
            this.initForm(form);
        };
        reader.readAsText(selectedFile);
    }

    resetCrossSiteSettings(form: Form) {
        form.ImageGuid = null;
        form.Category = null;

        this.resetAllIds(form);
        this.resetGroups(form);
    }

    resetAllIds(form: Form) {
        let id = -1;
        form.ID = id--;
        form.Guid = null;
        form.HasDraft = false;

        const workDefLookup = {};
        if (form.WorkflowDefinitions) {
            form.WorkflowDefinitions.forEach((wfDef) => {
                const temp = id--;
                workDefLookup[wfDef.ID] = temp;
                wfDef.ID = temp;
            });
        }

        if (form.Pages) {
            form.Pages.forEach((page) => {
                page.ID = id--;
                page.FormID = form.ID;

                if (page.WorkflowPageDefinitions) {
                    page.WorkflowPageDefinitions.forEach((wfPageDef) => {
                        wfPageDef.ID = id--;
                        wfPageDef.PageID = page.ID;
                        wfPageDef.WorkflowDefinitionID =
                            workDefLookup[wfPageDef.WorkflowDefinitionID];
                    });
                }

                if (page.Fields) {
                    page.Fields.forEach((field) => {
                        field.ID = id--;
                        field.PageID = page.ID;
                    });
                }
            });
        }
    }

    resetGroups(form: Form) {
        form.Groups = [];
        form.Responsibles = [];
        form.Designers = [];
        form.DataOwners = [];

        if (form.WorkflowDefinitions) {
            form.WorkflowDefinitions.forEach((wd) => {
                wd.DefinitionDataOwners = [];
            });
        }

        if (form.Pages) {
            form.Pages.forEach((p) => {
                if (p.WorkflowPageDefinitions) {
                    p.WorkflowPageDefinitions.forEach((pd) => {
                        pd.Groups = [];
                    });
                }
            });
        }
    }

    setDefaultValues(form: Form) {
        form.Guid = guid();
        form.IsOpenForEntries = false;
        form.CanStartEntry = false;
        form.LoadedDraftID = 0;
    }

    openEditFormWindow() {
        let componentRef;
        try {
            componentRef = this.createModalContainer(FormMainSettingsComponent);
        } catch (e) {
            this.toastr.error(e, "Failed loading data");
            return;
        }

        if (componentRef.instance) {
            const inst = componentRef.instance as FormMainSettingsComponent;
            inst.form = this.copyForm(this.form); // send copy
            inst.saveChanges.subscribe((form) => {
                this.form = form;

                if (!this.form.Workflow && this.form.Pages) {
                    this.form.Pages.forEach((x) => (x.Type = FormPageType.Entry));
                }

                this.checkMapbacking();
                this.updateMenu();

                this.hideModal();
            });
        }

        this.showModal();
    }

    private copyForm(form: Form): Form {
        let copy = JSON.parse(JSON.stringify(form));

        if (!this.isValidDate(copy.StartDate)) {
            copy.StartDate = new Date(Date.parse(copy.StartDate));
        }

        if (!this.isValidDate(copy.EndDate)) {
            copy.EndDate = new Date(Date.parse(copy.EndDate));
        }
        return copy;
    }

    private isValidDate(date: Date): boolean {
        if (!date) {
            return true;
        }

        if (Object.prototype.toString.call(date) !== "[object Date]") {
            return false;
        }
        if (isNaN(date.getDate())) {
            return false;
        }
        return true;
    }

    // #endRegion

    //#region Mapbacking

    checkMapbacking() {
        if (this.form.MapBack > 0) {
            this.getMapbackingFields();
        } else {
            this.mapbackProperties = null;
        }
    }

    getMapbackingFields() {
        this._formDS.getMapBackOptions(this.form.MapBack).subscribe((x) => {
            this.mapbackProperties = x;
        });
    }

    //#endregion

    //#region Workflow

    openWorkflowWindow() {
        const componentRef = this.createModalContainer(FormWorkflowSettingsComponent);
        if (componentRef.instance) {
            const inst = componentRef.instance as FormWorkflowSettingsComponent;
            inst.form = JSON.parse(JSON.stringify(this.form)); // send copy
            inst.saveChanges.subscribe((form) => {
                this.form = form;
                this.hideModal();
            });
        }

        this.showModal();
    }

    // #endRegion

    //#region Pages

    openEditPageWindow() {
        const componentRef = this.createModalContainer(FormPageSettingsComponent);
        if (componentRef.instance) {
            const inst = componentRef.instance as FormPageSettingsComponent;
            inst.pages = JSON.parse(JSON.stringify(this.form.Pages)) as FormPage[]; // send copy
            inst.isWorkflow = this.form.Workflow;
            inst.saveChanges.subscribe((p) => {
                this.form.Pages = p;
                this.hideModal();
                this.updateMenu();
            });
        }

        this.showModal();
    }

    addNewPage(isReviewPage?: boolean) {
        const page = this.getNewFormPage(isReviewPage ? FormPageType.Review : FormPageType.Entry);
        this.form.Pages.push(page);
        this.activateMenuItem(this.form.Pages[this.form.Pages.length - 1].ID);

        this.updateMenu();
    }

    deleteCurrentPage() {
        this._promptService
            .confirm("Delete page", "Are you sure you want to delete this page?")
            .onConfirm()
            .subscribe(() => {
                this.deletePage(this.findPage(this.activePage));
                this.updateMenu();
            });
    }

    deletePage(page: FormPage) {
        if (!page) {
            return;
        }

        let pos = this.form.Pages.findIndex((p) => p.ID === page.ID);
        if (pos >= 0) {
            this.form.Pages.splice(pos, 1);
        }

        if (pos >= this.form.Pages.length) {
            pos--;
        }

        this.activateMenuItem(this.form.Pages[pos].ID);
    }
    //#endregion Pages

    //#region Fields
    addNewFieldToPage() {
        const field = this.getNewFormField();
        const page = this.findPage(this.activePage);
        page.Fields.push(field);

        this.expandedFieldId = field.ID;
    }

    deleteField(field: FormField, skipPrompt: boolean) {
        if (!field) {
            return;
        }

        const deleteMethod = () => {
            this.form.Pages.forEach((page) => {
                const pos = page.Fields.findIndex((f) => f.ID === field.ID);
                if (pos >= 0) {
                    page.Fields.splice(pos, 1);

                    if (field.Selected) {
                        field.Selected = false;
                        this.fieldSelectionToggled(field);
                    }
                }
            });
        };

        if (skipPrompt) {
            deleteMethod();
        } else {
            this._promptService
                .confirm("Delete field", "Are you sure you want to delete this field?")
                .onConfirm()
                .subscribe(() => {
                    deleteMethod();
                });
        }
    }
    //#endregion Fields

    //#region Drafts

    saveDraft() {
        const prompt = this._promptService.prompt({
            maxHeight: "360px",
            maxWidth: "400px",
            data: {
                title: "Save form draft",
                message: "You are about to save the changes to this form as a draft.",
                fields: [
                    {
                        prettyName: "Draft name",
                        fieldName: "name",
                        fieldType: GenericListFieldType.Text,
                        defaultValue: "Draft - " + this.form.Title,
                    },
                    {
                        prettyName: "Ready for release",
                        fieldName: "isReady",
                        fieldType: GenericListFieldType.CheckBox,
                        defaultValue: false,
                        description:
                            "Setting this to true will indicate in the overview it is ready for release. Also this enables release validations.",
                    },
                ],
                confirmButton: {
                    text: "Save draft",
                },
            },
        });

        prompt.getResult().subscribe((result) => {
            if (result) {
                const toSave = <FormDraft>{
                    ID: this.form.LoadedDraftID,
                    Draft: JSON.stringify(this.form),
                    Name: result.name,
                    IsReady: result.isReady,
                    TargetFormID: this.form.ID ? this.form.ID : null,
                };

                if (!toSave.IsReady || this.validateForm(this.form)) {
                    const savingPrompt = this._promptService.loader("Saving draft, please wait...");

                    this._formDS
                        .storeDraft(toSave)
                        .pipe(finalize(() => savingPrompt.close()))
                        .subscribe((id) => {
                            this.toastr.success("", "Draft save completed");
                            this.form.LoadedDraftID = id;

                            this.setTabTitle();
                        });
                }
            }
        });
    }

    loadDraft() {
        if (this.form.HasDraft && !this.form.LoadedDraftID) {
            this._promptService
                .confirm(
                    "Continue loading draft?",
                    "Are you sure you want to load the draft? Any unsaved changes will be lost."
                )
                .onConfirm()
                .subscribe(() => {
                    this._formDS.getFormDraft(this.form.ID).subscribe((draft) => {
                        const draftForm = parseTyped<Form>(draft.Draft, null);
                        if (draftForm != null) {
                            draftForm.LoadedDraftID = draft.ID;
                            this.initForm(draftForm);
                        } else {
                            this.toastr.error(
                                "Draft is corrupted and cannot be loaded. Please contact your Administrator.",
                                "Draft invalid"
                            );
                        }
                    });
                });
        }
    }

    //#endregion Drafts

    //#region Navigation
    navigateFirstPage() {
        this.setActivePage(this.form.Pages[0].ID);
    }

    navigatePreviousPage(pageNumber: number) {
        // Since I used PageNumbers and not indices, I need to substract an additional one
        this.setActivePage(this.form.Pages[pageNumber - 1].ID);
    }

    navigateNextPage(pageNumber: number) {
        // Since I used PageNumbers and not indices, I need to add an additional one
        this.setActivePage(this.form.Pages[pageNumber + 1].ID);
    }

    navigateLastPage() {
        this.setActivePage(this.form.Pages[this.form.Pages.length - 1].ID);
    }

    setActivePage(pageID: number) {
        this.activateMenuItem(pageID);

        if (this.tab.menu) {
            const menuItem = this.tab.menu.menuItems[0].children.find((x) => x.obj === pageID);
            if (menuItem) {
                this.tab.menu.activeMenuItem = menuItem.identifier;
            }
        }
    }

    //#endregion Navigation

    //#region Helpers
    findPage(pageId: number): FormPage {
        if (!pageId && pageId !== 0) {
            throw new Error("No page id defined");
        }

        const foundPage = this.form.Pages.filter((page) => page.ID === pageId);
        if (foundPage.length === 1) {
            return foundPage[0];
        } else if (foundPage.length > 1) {
            throw new Error("Found multiple pages with id " + pageId);
        } else {
            throw new Error("Couldn't find page " + pageId);
        }
    }

    findFieldInPage(page: FormPage, fieldId: number): FormField {
        if (!page) {
            throw new Error("No valid page given");
        }

        for (let i = 0; i < page.Fields.length; i++) {
            if (page.Fields[i].ID === fieldId) {
                return page.Fields[i];
            }
        }
        throw new Error("Couldn't find field " + fieldId);
    }

    findField(pageId: number, fieldId: number): FormField {
        return this.findFieldInPage(this.findPage(pageId), fieldId);
    }

    checkNewFormRights() {
        if (!this.form.ID || this.form.ID <= 0) {
            this.form.CanRelease = this.permissions.IsReleaser;
        }
    }

    getNewForm(): Form {
        return <Form>{
            ID: -1,
            Title: "",
            Description: "",
            Category: 0,
            Guid: "",
            Pages: [this.getNewFormPage()],
            ImageGuid: "",
            IsOpenForEntries: false,
            ShowSummary: true,
        };
    }

    getNewFormPage(type?: FormPageType): FormPage {
        return <FormPage>{
            ID: this.getNewId(ObjectType.Page),
            Title: "",
            Tooltip: "",
            Type: type ? type : FormPageType.Entry,
            Fields: [],
        };
    }

    getNewFormField(): FormField {
        return <FormField>{
            ID: this.getNewId(ObjectType.Field),
            Name: "",
            ShowName: true,
            FieldType: FormFieldType.Info,
            Required: true,
            MapBackField: 0,
            Valid: true,
        };
    }

    getNewId(objectType: ObjectType): number {
        const lowest = this.getLowestId(objectType);

        if (lowest >= 0) {
            return -1;
        } else {
            return lowest - 1;
        }
    }

    getLowestId(objectType: ObjectType): number {
        if (!objectType || objectType === ObjectType.Form) {
            return 0;
        }

        let lowest = Number.MAX_VALUE;

        if (!this.form || !this.form.Pages) {
            return 0;
        }

        if (objectType === ObjectType.Page) {
            this.form.Pages.forEach((page) => {
                if (page.ID < lowest) {
                    lowest = page.ID;
                }
            });
        }

        if (objectType === ObjectType.Field) {
            this.form.Pages.forEach((page) => {
                if (page.Fields) {
                    page.Fields.forEach((field) => {
                        if (field.ID < lowest) {
                            lowest = field.ID;
                        }
                    });
                }
            });
        }

        return lowest;
    }
    // endregion Helpers

    createModalContainer(component: any) {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(component);
        const viewContainerRef = this.customComponent;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        return componentRef;
    }

    updateMenu() {
        if (!this.tab.menu) {
            this.tab.menu = new TabMenu();
            this.tab.menu.doNotShowTucked = true;

            this.tab.menu.menuItemClicked = (menuItem) => {
                this.activateMenuItem(menuItem.obj as number);
                this.tab.menu.activeMenuItem = menuItem.identifier;
            };

            this.tab.menu.menuItems = [];

            const header = new TabMenuItem();
            header.clickable = false;
            header.iconClass = "fas fa-file-alt";

            this.tab.menu.menuItems.push(header);
        }

        this.tab.menu.menuItems[0].name = this.form.Title ? this.form.Title : "FORM Pages";
        this.tab.menu.menuItems[0].children = [];
        this.form.Pages.forEach((page, i) => {
            const pageMenu = new TabMenuItem();
            pageMenu.clickable = true;
            pageMenu.name =
                page.Title && page.Title.trim().length > 0 ? page.Title : "(Title Missing)";
            pageMenu.obj = page.ID;

            this.tab.menu.menuItems[0].children.push(pageMenu);

            if (this.activePage === page.ID) {
                this.tab.menu.activeMenuItem = pageMenu.identifier;
            }
        });

        if (this.form.ShowSummary) {
            const sumMenu = new TabMenuItem();
            sumMenu.clickable = false;
            sumMenu.name = "Summary";
            sumMenu.iconClass = "fas fa-eye";

            this.tab.menu.menuItems[0].children.push(sumMenu);
        }
    }

    activateMenuItem(pageID: number) {
        this.activePage = pageID;
    }

    fieldSelectionToggled(field: FormField) {
        if (field.Selected) {
            this.selectedFieldCount++;
        } else {
            this.selectedFieldCount--;
        }

        // Should probably disable the count up/down while resetting, but this is an easier way to guarantee no negative selection.
        if (this.selectedFieldCount < 0) {
            this.selectedFieldCount = 0;
        }
    }

    resetFieldSelection() {
        this.form.Pages.forEach((p) => {
            p.Fields.forEach((f) => {
                f.Selected = false;
            });
        });

        this.selectedFieldCount = 0;
    }

    copyFields() {
        const callback = (cleared: boolean) => {
            if (cleared) {
                this.form.Pages.forEach((p) => {
                    p.Fields.forEach((f) => {
                        if (f.Selected) {
                            const newFormField = this.getNewFormField();

                            newFormField.Name = f.Name;
                            newFormField.ShowName = f.ShowName;
                            newFormField.FieldType = f.FieldType;
                            newFormField.CustomSettings = f.CustomSettings;

                            this.fieldClipboard.push(newFormField);
                        }
                    });
                });

                this.resetFieldSelection();
            }
        };

        this.clearClipboard(callback);
    }

    cutFields() {
        const callback = (cleared: boolean) => {
            if (cleared) {
                this.form.Pages.forEach((p) => {
                    p.Fields.forEach((f) => {
                        if (f.Selected) {
                            this.fieldClipboard.push(f);
                        }
                    });
                });

                this.fieldClipboard.forEach((field) => {
                    this.deleteField(field, true);
                });

                this.cutItemsInClipboard = true;
                this.resetFieldSelection();
            }
        };

        this.clearClipboard(callback);
    }

    pasteFields() {
        const page = this.findPage(this.activePage);
        this.fieldClipboard.forEach((f) => {
            if (this.cutItemsInClipboard) {
                page.Fields.push(f);
            } else {
                const newField = JSON.parse(JSON.stringify(f)) as FormField;
                newField.ID = this.getNewId(ObjectType.Field);
                page.Fields.push(newField);
            }
        });

        if (this.cutItemsInClipboard) {
            this.cutItemsInClipboard = false;
            this.clearClipboard();
        }
    }

    clearClipboard(callback?: (cleared: boolean) => void) {
        const clearMethod = () => {
            this.fieldClipboard = [];
            this.cutItemsInClipboard = false;

            if (callback) {
                callback(true);
            }
        };

        if (!this.cutItemsInClipboard) {
            clearMethod();
            return;
        }

        this._promptService
            .confirmCustom({
                maxHeight: "275px",
                data: {
                    title: "Clear clipboard",
                    message:
                        "Cut items have not been pasted yet. This action will result in losing these items. Are you sure you want to continue?",
                },
            })
            .onConfirm()
            .subscribe(() => {
                clearMethod();
            });
    }

    deleteFields() {
        this._promptService
            .confirm(
                "Delete fields",
                `Are you sure you want to delete ${this.selectedFieldCount} fields?`
            )
            .onConfirm()
            .subscribe(() => {
                for (let p = this.form.Pages.length - 1; p >= 0; p--) {
                    const page = this.form.Pages[p];
                    for (let f = page.Fields.length - 1; f >= 0; f--) {
                        const field = page.Fields[f];
                        if (field.Selected) {
                            page.Fields.splice(f, 1);
                        }
                    }
                }

                this.resetFieldSelection();
            });
    }

    openHistory() {
        this._tabService.generateTab(
            this._pages.FormHistory,
            this.form.ID,
            "History - " + this.form.Title
        );
    }

    openPreview() {
        this._tabService.generateTab(this._pages.FormEntry, null, this.form.Title, {
            preview: JSON.stringify(this.form),
        });
    }

    securityException(closeTab?: boolean) {
        if (closeTab) {
            this.tab.close();
        }

        if (this.securityToast) {
            this.securityToast.portal.destroy();
            this.securityToast = null;
        }

        this.securityToast = this.toastr.error(
            "Action is denied due to insufficient rights!",
            "Unauthorized",
            { disableTimeOut: true, closeButton: true }
        );
    }

    checkMobile() {
        const isMobile = IsMobile();
        if (isMobile) {
            this._tabService.sideMenuTucked = true;
        }
    }

    showModal() {
        $(this.popupModal.nativeElement).modal("show");
    }

    hideModal() {
        $(this.popupModal.nativeElement).modal("hide");
    }
}

enum ObjectType {
    Form = 1,
    Page,
    Field,
}
