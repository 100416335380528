import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RiskModel } from '@app/risk/models/RiskModel';
import { LinkModel } from '@models/generic/LinkModel';
import { RiskDataService } from '@app/risk/services/RiskDataService';

@Component({
    selector: 'risk-events',
    templateUrl: './risk-events.component.html',
    styleUrls: ['./risk-events.component.scss']
})
export class RiskEventsComponent {
    @Input() model: RiskModel;

    isLoading = true;
    linked: LinkModel[];

    constructor(private riskDataService: RiskDataService) { }

    load() {

        this.isLoading = true;

        this.riskDataService.getLinkedEvents(this.model.guid).subscribe(
            value => {
                this.linked = value;
                this.isLoading = false;
            }
        );
    }
}