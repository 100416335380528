import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpResponseBase,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { TabService } from "../../tabs/TabService";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";
import HttpStatusCode from "@models/generic/HttpRequest/HttpStatusCode";

@Injectable()
export class ResponseHttpInterceptor implements HttpInterceptor {
    private static defaultErrorTitle = "Something went wrong";
    private static defaultErrorMessage = "Please contact your administrator for more information";

    constructor(
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _authService: UserAuthenticationService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError((error) => {
                const isAuthError = error instanceof HttpResponseBase && error.status === 401;
                if (isAuthError) {
                    return this._authService.initiateNewSession().pipe(
                        mergeMap((x) => {
                            return next.handle(req);
                        })
                    );
                } else {
                    const isHttpError = error instanceof HttpErrorResponse;
                    if (isHttpError) {
                        const eResponse = error as HttpErrorResponse;

                        // Just in case it was showing the loader for the request.
                        if (this._tabService && this._tabService.activeTab) {
                            this._tabService.activeTab.showLoader = false;
                        }

                        let title: string, message: string;
                        if (eResponse.status === HttpStatusCode.PAYLOAD_TOO_LARGE) {
                            message = "The uploaded file is too large";
                        } else if (
                            // This should never occur, because error will only be an object if the exception was returned.
                            typeof eResponse.error === "object" ||
                            eResponse.error instanceof Object
                        ) {
                            // If we got a custom error message with the error show the custom message.
                            if (eResponse.error.Message) {
                                message = eResponse.error.Message;
                            } else if (eResponse.error.message) {
                                message = eResponse.error.message;
                            } else if (eResponse.error.title) {
                                message = eResponse.error.title;
                            } else {
                                title = ResponseHttpInterceptor.defaultErrorTitle;
                                message = ResponseHttpInterceptor.defaultErrorMessage;
                            }
                        } else {
                            // If we get a response error page we will try to only get the title of the message and show it as a toastr.
                            // If we do not have a title dom element in the value we will fallback to the whole message for now.
                            const parsedDom = new DOMParser().parseFromString(
                                eResponse.error,
                                "text/html"
                            );
                            const titleInDom = parsedDom.querySelector("title");
                            if (titleInDom) {
                                message = titleInDom.innerText;
                            } else {
                                message = eResponse.error;
                            }
                        }

                        // If the error page we got is a "runtime error" page, rewrite it to show a more user friendly error message.
                        if (message.toLowerCase() == "runtime error") {
                            title = ResponseHttpInterceptor.defaultErrorTitle;
                            message = ResponseHttpInterceptor.defaultErrorMessage;
                        }

                        if (eResponse.error.warning) {
                            this._toastr.warning(message, title, {
                                timeOut: 60000,
                                extendedTimeOut: 60000,
                                enableHtml: true,
                            });
                        } else {
                            this._toastr.error(message, title, {
                                timeOut: 60000,
                                extendedTimeOut: 60000,
                                enableHtml: true,
                            });
                        }

                        // This error is something the page might want to take care of aswell so continue the error.
                        throw error;
                    }
                }

                return of(error);
            })
        );
    }
}
