import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentThirdPartyModel } from "../models/incident-thirdparty.model";

@Injectable({
    providedIn: "root",
})
export class IncidentThirdPartyService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/third-party`;

    public getAll(): Observable<IncidentThirdPartyModel[]> {
        return this.httpClient.get<IncidentThirdPartyModel[]>(this.baseUrl);
    }

    public getCausedbyThirdParties(incidentId: string): Observable<IncidentThirdPartyModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentThirdPartyModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentThirdPartyModel[]>(
                `${this.baseUrl}/incident/${incidentId}/caused-by`
            );
        }
    }

    public getAffectedThirdParties(incidentId: string): Observable<IncidentThirdPartyModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentThirdPartyModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentThirdPartyModel[]>(
                `${this.baseUrl}/incident/${incidentId}/affected`
            );
        }
    }
}
