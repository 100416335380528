import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PeriodsComponent } from "./periods.component";
import { PeriodService } from "./shared/period.service";
import { SharedModule } from "@app/shared/shared.module";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [SharedModule, FormsModule, CommonModule],
    declarations: [PeriodsComponent],
})
export class PeriodsModule {
    static forRoot(): ModuleWithProviders<PeriodsModule> {
        return {
            ngModule: PeriodsModule,
            providers: [PeriodService],
        };
    }
}
