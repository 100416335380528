<div class="page-content" [class.tucked]="_tabService.sideMenuTucked">
    <div class="tab-sidemenu">
        <!-- sidemenu-actions is reversed with css, so items are shown from right to left. -->
        <div class="sidemenu-actions" [class.tucked]="_tabService.sideMenuTucked">
            <div
                class="tuck-toggle"
                data-uiid="sidemenu-tuck-toggle"
                (click)="sideMenuTuckToggle()"
            >
                <i
                    class="far fa-chevron-double-{{
                        _tabService.sideMenuTucked ? 'right' : 'left'
                    }} fa-fw"
                ></i>
            </div>
            <div class="filler"></div>
        </div>

        <ng-container *ngFor="let tab of tabs; trackByIdentifier">
            <ng-container *ngIf="tab.menu">
                <div
                    class="tab-menu-content"
                    [hidden]="_tabService.sideMenuTucked || tab.identifier !== activeTab"
                    [attr.data-uiid]="'tabmenu-' + tab.name"
                >
                    <ng-container *ngIf="tab.menu.customMenu">
                        <ng-container [ngTemplateOutlet]="tab.menu.customMenu"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!tab.menu.customMenu && tab.menu.menuItems.length > 0">
                        <cerrix-tabmenu [mainMenu]="tab.menu"></cerrix-tabmenu>
                    </ng-container>
                </div>
                <div
                    class="tab-menu-content tucked-content"
                    [hidden]="!_tabService.sideMenuTucked || tab.identifier !== activeTab"
                    *ngIf="!tab.menu.doNotShowTucked"
                >
                    <tuckedmenu [mainMenu]="tab.menu"></tuckedmenu>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="tab-content">
        <div class="tab-bar" [class.no-indent]="_tabService.sideMenuTucked">
            <div class="tab-area" [class.overflow]="Overflow">
                <button class="scroll-button" *ngIf="Overflow" (click)="scrollTab(-150)">
                    <i class="fas fa-chevron-left"></i>
                </button>
                <ul class="nav nav-tabs cerrix-tab sortable">
                    <li class="placeholder"></li>
                    <li
                        class="cerrix-tab-container"
                        [attr.data-uiid]="'tab-container-' + tab.name"
                        [class.active]="tab.identifier === activeTab"
                        *ngFor="let tab of tabs; trackByIdentifier"
                    >
                        <a
                            (mousedown)="mouseClick($event, tab)"
                            (contextmenu)="onContextMenu($event, tab)"
                        >
                            <div
                                class="cerrix-tab-content"
                                [attr.data-uiid]="'tab-content-' + tab.name"
                                [title]="tab.name"
                            >
                                <div class="filler">
                                    <i
                                        class="fas fa-circle-notch fa-spin"
                                        aria-hidden="true"
                                        *ngIf="tab.showLoader"
                                    >
                                    </i>
                                </div>

                                <div data-uiid="tab-name" class="title">
                                    {{ tab.name }}
                                </div>

                                <div class="filler">
                                    <i
                                        class="fas fa-times clickable"
                                        aria-hidden="true"
                                        (click)="tab.close()"
                                        data-uiid="close-tab-button"
                                        *ngIf="tab.closable"
                                    ></i>
                                    <i
                                        class="fas fa-ellipsis-v ms-2 clickable"
                                        aria-hidden="true"
                                        (click)="onContextMenu($event, tab)"
                                        data-uiid="option-tab-button"
                                    ></i>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <button
                    class="close-all-button"
                    *ngIf="tabs.length > 2"
                    (click)="closeTabs()"
                    title="Close all tabs"
                >
                    <i class="fas fa-times" aria-hidden="true"></i>
                </button>
                <button class="scroll-button" *ngIf="Overflow" (click)="scrollTab(150)">
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>

        <div
            class="tab-page"
            *ngFor="let tab of tabs; trackByIdentifier"
            [hidden]="tab.identifier !== activeTab"
            [id]="'view-' + tab.identifier"
            [class.full-height]="tab.url"
        >
            <tab-pane
                [cerrixTab]="tab"
                *ngIf="tab.comp && renderedTabs.indexOf(tab.identifier) >= 0"
                [id]="tab.identifier"
            >
            </tab-pane>

            <iframe
                *ngIf="tab.url && renderedTabs.indexOf(tab.identifier) >= 0"
                [src]="tab.url | safe"
                [id]="'iframe-' + tab.identifier"
            ></iframe>
        </div>
    </div>
</div>

<cerrix-contextmenu [menu]="contextMenu" #contextMenuCmp></cerrix-contextmenu>
