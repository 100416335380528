import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ControlModel } from "../models/ControlModel";
import { Observable, of } from "rxjs";
import { ControlPermissionModel } from "../models/ControlPermissionsModel";
import { isGuid } from "@methods/uniqueMethods";
import { ApiDataService } from "@services/http/ApiDataService";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { DocumentMethods } from "@methods/DocumentMethods";
import { LinkModel } from "@models/generic/LinkModel";
import { ModuleType } from "@enums/ModuleType";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { ControlInfoModel } from "@app/controls/models/ControlInfoModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { SharedControlCrudModel } from "../models/SharedControlCrudModel";
import { SharedControlLinkModel } from "../models/SharedControlLinkModel";
import { ParentControlInfoModel } from "../models/ParentControlInfoModel";

@Injectable()
export class ControlDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    public getList(): Observable<ControlInfoModel[]> {
        return this.get<ControlInfoModel[]>();
    }

    public getControl(guid: string): Observable<ControlModel> {
        const url = `/${guid}`;
        return this.get<any>(url);
    }

    public storeControl(controlModel: ControlModel): Observable<HttpEvent<ControlModel>> {
        const formData = DocumentMethods.convertToFormDataWithFiles("control", controlModel);
        return this.request<ControlModel>(formData);
    }

    public deleteControl(controlGuid: string): Observable<string[]> {
        const url = `/${controlGuid}`;
        return this.delete(url);
    }

    public hasAccess(guid: string): Observable<boolean> {
        if (isGuid(guid)) {
            const url = `/${guid}/hasaccess`;
            return this.get<boolean>(url);
        }

        return of(false);
    }

    public getPermissions(guid?: string): Observable<ControlPermissionModel> {
        const url = guid && guid.length > 0 ? `/${guid}/permissions` : "/permissions";
        return this.get<ControlPermissionModel>(url);
    }

    public getLinkableControls(
        moduleType: ModuleType,
        parentId: number,
        organizationId: number
    ): Observable<ControlLinkModel[]> {
        if (!parentId) {
            parentId = null;
        }

        const url = `/linkablecontrols/${moduleType}/${parentId}/${organizationId}`;

        return this.get<ControlLinkModel[]>(url);
    }

    public getLinkableRisks(
        controlGuid: string,
        organizationId: number
    ): Observable<SharedControlLinkModel<RiskLinkModel>> {
        const url = `/${controlGuid}/links/risks/${organizationId}`;
        return this.get<SharedControlLinkModel<RiskLinkModel>>(url);
    }

    public getLinkableMois(controlGuid: string, organizationId: number): Observable<LinkModel[]> {
        const url = `/${controlGuid}/links/mois/${organizationId}`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkableEvents(controlGuid: string): Observable<LinkModel[]> {
        const url = `/${controlGuid}/links/events`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkablePp(controlGuid: string): Observable<LinkModel[]> {
        const url = `/${controlGuid}/links/processingpurposes`;
        return this.get<LinkModel[]>(url);
    }

    public getLinkableTp(controlGuid: string): Observable<LinkModel[]> {
        const url = `/${controlGuid}/links/thirdparty`;
        return this.get<LinkModel[]>(url);
    }

    public getHistory(controlGuid: string): Observable<HistoryModel[]> {
        const url = `/${controlGuid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(controlGuid: string, mutationId: number): Observable<HistoryChange[]> {
        const url = `/${controlGuid}/history/${mutationId}`;

        return this.get<HistoryChange[]>(url);
    }

    public getDocuments(controlGuid: string): Observable<DocumentModel[]> {
        const url = `/${controlGuid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(controlGuid: string): Observable<HyperlinkModel[]> {
        const url = `/${controlGuid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public getActiveScores(): Observable<IdNameColorModel[]> {
        let url = "/scores/active";
        return this.get<IdNameColorModel[]>(url);
    }

    public getAllScores(): Observable<IdNameColorModel[]> {
        let url = "/scores/all";
        return this.get<IdNameColorModel[]>(url);
    }

    public getAllSharedControls(): Observable<ControlLinkModel[]> {
        let url = "/sharedcontrols";
        return this.get<ControlLinkModel[]>(url);
    }

    public storeSharedControlsPerOrganization(
        organizationId: number,
        linkedControls: number[]
    ): Observable<SharedControlCrudModel> {
        let url = "/sharedcontrols";
        const crudModel = new SharedControlCrudModel();
        crudModel.organizationId = organizationId;
        crudModel.linkedControls = linkedControls;
        return this.post(crudModel, url);
    }

    public getParentControlInfo(controlGuid: string): Observable<ParentControlInfoModel> {
        const url = `/${controlGuid}/parentcontrolinfo`;
        return this.get<ParentControlInfoModel>(url);
    }
}
