<div class="logo">
    <cerrix-logo [width]="200" [height]="35"></cerrix-logo>
</div>
<cerrix-loader [cerrixTab]="tab" *ngIf="!cellViewModels"></cerrix-loader>
<div class="print-name" *ngIf="printData">
    <label class="lbl-big">
        {{ data.meta.processName }}
    </label>
</div>
<div class="detail-page-card">
    <div class="detail-page-card-title">Process details</div>
    <div class="detail-page-card-body padded form-group-styling" *ngIf="printData">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="controlNameInput">Process Name</label>
                    <input
                        class="form-control form-control-sm"
                        id="controlNameInput"
                        name="controlNameInput"
                        [ngModel]="data.meta.processName"
                        inputTooltip
                    />
                </div>
                <div class="form-group">
                    <label for="controlCatalogueSelect">Root</label>
                    <ng-select
                        id="controlCatalogueSelects"
                        name="controlCatalogueSelects"
                        bindLabel="processTextPath"
                        [items]="[data.meta]"
                        [(ngModel)]="data.meta"
                    >
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="controlCatalogueSelect">Author</label>
                    <ng-select
                        id="controlCatalogueSelect"
                        name="controlCatalogueSelect"
                        bindLabel="author"
                        [items]="[data.meta]"
                        [(ngModel)]="data.meta"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="startDate">Date</label>
                    <date-input [value]="data.meta.date"></date-input>
                </div>
                <div class="form-group">
                    <label for="controlNameInput">Version</label>
                    <input
                        class="form-control form-control-sm"
                        id="controlNameInput"
                        name="controlNameInput"
                        [ngModel]="data.meta.version"
                        inputTooltip
                    />
                </div>

                <div class="form-group">
                    <label for="controlCatalogueSelect">Authorized by</label>
                    <ng-select
                        id="controlCatalogueSelect"
                        name="controlCatalogueSelect"
                        bindLabel="authorizedBy"
                        [items]="[data.meta]"
                        [(ngModel)]="data.meta"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <label for="controlDescriptionInput">Description</label>
                <div
                    class="form-control"
                    id="controlDescriptionInput"
                    name="controlDescriptionInput"
                    [rows]="4"
                    inputTooltip
                >
                    {{ data.meta.processDescription }}
                </div>
            </div>
            <div class="form-group col-12">
                <label for="controlDescriptionInput">Comments</label>
                <div
                    class="form-control"
                    id="controlDescriptionInput"
                    name="controlDescriptionInput"
                    inputTooltip
                >
                    {{ data.meta.comment }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="detail-page-card pagebreak">
    <div class="detail-page-card-title">Process flow</div>
    <div class="detail-page-card-body padded flex-center flex-row">
        <print-process-flow [printGraph]="printData.graph"></print-process-flow>
    </div>
</div>

<div class="detail-page-card pagebreak">
    <div class="detail-page-card-title">Process steps</div>
    <div class="detail-page-card-body padded form-group-styling">
        <ng-container
            *ngFor="let cellViewModel of cellViewModels; let last = last; let index = index"
        >
            <div class="row pagebreak">
                <div class="col-12">
                    <div class="form-group">
                        <label for="controlNameInput" class="lbl-med"
                            >Step {{ index + 1 }}: {{ cellViewModel.processStep.label }}</label
                        >
                    </div>
                </div>
                <div class="col-12">
                    <ng-container
                        *ngIf="cellViewModel.businessDimensionName as businessDimensionName"
                    >
                        <div class="form-group">
                            <label for="controlNameInput">Linked process</label>
                            <input
                                class="form-control form-control-sm"
                                id="controlNameInput"
                                name="controlNameInput"
                                [ngModel]="businessDimensionName"
                                inputTooltip
                            />
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label for="controlDescriptionInput">Comment</label>
                    <div
                        class="form-control"
                        id="controlDescriptionInput"
                        name="controlDescriptionInput"
                        inputTooltip
                    >
                        {{ cellViewModel.processStep.comment }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <generic-list-manager
                        *ngIf="cellViewModel.raciConfig"
                        [config]="cellViewModel.raciConfig"
                    ></generic-list-manager>
                </div>
            </div>

            <div class="row" *ngIf="printOverviewModel">
                <div class="col">
                    <ng-container
                        *ngFor="
                            let linkedRiskConfig of cellViewModel.linkedRiskConfig;
                            trackBy: trackByName
                        "
                    >
                        <generic-list-manager
                            [config]="linkedRiskConfig"
                            *ngIf="isGraphRisksAndControls; else multi_level"
                        >
                        </generic-list-manager>
                        <ng-template #multi_level>
                            <cerrix-table-multi-level [config]="linkedRiskConfig">
                            </cerrix-table-multi-level>
                        </ng-template>
                    </ng-container>
                </div>
            </div>

            <div class="row" *ngIf="printOverviewModel">
                <div class="col">
                    <ng-container
                        *ngFor="let linkedControlConfig of cellViewModel.linkedControlConfig"
                    >
                        <generic-list-manager [config]="linkedControlConfig"></generic-list-manager>
                    </ng-container>
                </div>
            </div>
            <hr *ngIf="!last" />
        </ng-container>
    </div>
</div>

<div
    class="detail-page-card pagebreak"
    *ngIf="
        printOverviewModel &&
        (!isGraphRisksAndControls ||
            unlinkedRiskConfig?.data?.length > 0 ||
            unlinkedControlConfig?.data?.length > 0)
    "
>
    <div class="detail-page-card-title">Not linked to a process step</div>
    <div class="detail-page-card-body padded">
        <div class="row">
            <div class="col">
                <generic-list-manager
                    *ngIf="unlinkedRiskConfig"
                    [config]="unlinkedRiskConfig"
                ></generic-list-manager>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <generic-list-manager
                    *ngIf="unlinkedControlConfig"
                    [config]="unlinkedControlConfig"
                ></generic-list-manager>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <generic-list-manager
                    *ngIf="unlinkedEventConfig.data"
                    [config]="unlinkedEventConfig"
                ></generic-list-manager>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <generic-list-manager
                    *ngIf="unlinkedMOIConfig.data"
                    [config]="unlinkedMOIConfig"
                ></generic-list-manager>
            </div>
        </div>
    </div>
</div>

<div class="block-mouse"></div>
