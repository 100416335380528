import { Component, ElementRef, ViewChild } from "@angular/core";
import { ControlsWidgetsDataService } from "../../../services/controls-widgets.data.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";
import { nameof } from "@methods/jeffs-toolkit";
import { ControlInfoModel } from "@app/controls/models/ControlInfoModel";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";

@Component({
    selector: "controls-simple-eff-overdue-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class ControlSimpleEffOverdueWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private controlsWidgetsDataService: ControlsWidgetsDataService,
        private pages: Pages,
        tabService: TabService,
        sd: StandingdataDataService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            nameof<ControlInfoModel>((x) => x.organizationId);
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            nameof<ControlInfoModel>((x) => x.businessDimensionIds);
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.FrameworkDimension] =
            nameof<ControlInfoModel>((x) => x.frameworkDimensionIds);
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.controlsWidgetsDataService
            .getSimpleOverdueWidgetInfo(filters)
            .toPromise();

        this.config.noData = !data || data.every((x) => x.y === 0);
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <any>{
                colorByPoint: true,
                data,
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);
        config.legend = { enabled: false };
        config.xAxis = {
            type: "category",
        };

        config.yAxis = {
            allowDecimals: false,
            title: {
                text: "Number of controls",
            },
        };

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        return config;
    }

    protected async openWorkspace(ev) {
        const filter = this.getFilter("EffOrDI");
        const category = ev.point.name;
        let searchKeywords: SearchKeyword[];

        if (filter == 1) {
            searchKeywords = [
                this.getSearchKeyword(
                    nameof<ControlInfoModel>((c) => c.effectivenessStatus),
                    category
                ),
                this.getSearchKeyword(
                    nameof<ControlInfoModel>((c) => c.isEffOverdue),
                    "Yes"
                ),
            ];
        } else if (filter == 2) {
            searchKeywords = [
                this.getSearchKeyword(
                    nameof<ControlInfoModel>((c) => c.diStatus),
                    category
                ),
                this.getSearchKeyword(
                    nameof<ControlInfoModel>((c) => c.isDiOverdue),
                    "Yes"
                ),
            ];
        }

        searchKeywords.push(this.getSearchKeyword(
            nameof<ControlInfoModel>((c) => c.requiresMonitoring),
            "Yes"
        ));

        const preset = await this.getBasePreset(searchKeywords);
        const pageToOpen = this.pages.ControlOverview;
        this.openPreset(pageToOpen, preset);
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.ControlOverview, preset);
    }
}
