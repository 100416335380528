import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { AdvEffDataService } from "../shared/services/adv-eff-data.service";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { EffectivenessOverviewModel } from "../shared/models/effectiveness-overview-model";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { map } from "rxjs/operators";
import { FilterType } from "@enums/FilterType";
import { nameof } from "@methods/jeffs-toolkit";
import { ModuleType } from "@enums/ModuleType";
import { ModuleSubType } from "@enums/ModuleSubType";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "adv-eff-overview",
    templateUrl: "./adv-eff-overview.component.html",
    styleUrls: ["./adv-eff-overview.component.scss"],
})
export class AdvEffOverviewComponent implements OnInit {
    moduleType = ModuleType.Controls;
    appendToModuleType = ModuleSubType[ModuleSubType.AdvEffTesting];
    tabID: string;
    tab: TabModel;
    idProp: string = nameof<EffectivenessOverviewModel>((x) => x.guid);
    data: Promise<EffectivenessOverviewModel[]>;
    headerLookup: { [propName: string]: string } = AdvEffOverviewComponent.getHeaderLookup();

    private static getHeaderLookup(): { [propName: string]: string } {
        let headerLookup = {};

        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.controlIdentifier)] = "Control Id";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.controlName)] = "Control Name";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.name)] = "Test Plan";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.testerScoreText)] = "Score Tester";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.reviewerScoreText)] =
            "Score Reviewer";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.statusDescription)] = "Status";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.groupName)] = "Test Period";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.groupScoreText)] =
            "Score Test Period";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.startDate)] = "Start Date";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.endDate)] = "End Date";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.numberOfSamples)] = "# of Samples";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.numberOfSourceDocuments)] =
            "# of Source Documents";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.sourceUploaders)] =
            "Source Uploaders";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.isSourceUploaderOverdue)] =
            "Is Source Uploader Overdue";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.sourceUploaderDueDate)] =
            "Source Uploader Due Date";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.evidenceUploaders)] =
            "Evidence Uploaders";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.evidenceUploaderDueDate)] =
            "Evidence Uploader Due Date";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.isEvidenceUploaderOverdue)] =
            "Is Evidence Uploader Overdue";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.testers)] = "Testers";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.testerDueDate)] =
            "Tester Due Date";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.isTesterOverdue)] =
            "Is Tester Overdue";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.reviewers)] = "Reviewers";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.reviewerDueDate)] =
            "Reviewer Due Date";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.isReviewerOverdue)] =
            "Is Reviewer Overdue";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.linkedStandards)] =
            "Linked Standards";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.qualityAspects)] =
            "Quality Aspects";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.controlOwner)] = "Control Owner";
        headerLookup[nameof<EffectivenessOverviewModel>((x) => x.organization)] = "Organization";

        return headerLookup;
    }

    filterConfigs: FilterConfig[] = [
        {
            label: "Organization",
            column: nameof<EffectivenessOverviewModel>((x) => x.organizationId),
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
    ];
    rendererConfig: RendererConfig[] = [
        RendererConfig.createScoreConfig(
            nameof<EffectivenessOverviewModel>((x) => x.testerScoreText),
            nameof<EffectivenessOverviewModel>((x) => x.testerScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<EffectivenessOverviewModel>((x) => x.reviewerScoreText),
            nameof<EffectivenessOverviewModel>((x) => x.reviewerScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<EffectivenessOverviewModel>((x) => x.groupScoreText),
            nameof<EffectivenessOverviewModel>((x) => x.groupScoreColor)
        ),
        RendererConfig.createDateConfig(nameof<EffectivenessOverviewModel>((x) => x.startDate)),
        RendererConfig.createDateConfig(nameof<EffectivenessOverviewModel>((x) => x.endDate)),
        RendererConfig.createOverdueConfig(
            nameof<EffectivenessOverviewModel>((x) => x.sourceUploaderDueDate),
            nameof<EffectivenessOverviewModel>((x) => x.isSourceUploaderOverdue)
        ),
        RendererConfig.createOverdueConfig(
            nameof<EffectivenessOverviewModel>((x) => x.evidenceUploaderDueDate),
            nameof<EffectivenessOverviewModel>((x) => x.isEvidenceUploaderOverdue)
        ),
        RendererConfig.createOverdueConfig(
            nameof<EffectivenessOverviewModel>((x) => x.testerDueDate),
            nameof<EffectivenessOverviewModel>((x) => x.isTesterOverdue)
        ),
        RendererConfig.createOverdueConfig(
            nameof<EffectivenessOverviewModel>((x) => x.reviewerDueDate),
            nameof<EffectivenessOverviewModel>((x) => x.isReviewerOverdue)
        ),
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];
    numberProps: string[] = [
        nameof<EffectivenessOverviewModel>((x) => x.numberOfSamples),
        nameof<EffectivenessOverviewModel>((x) => x.numberOfSourceDocuments),
    ];
    dateProps: string[] = [
        nameof<EffectivenessOverviewModel>((x) => x.startDate),
        nameof<EffectivenessOverviewModel>((x) => x.endDate),
        nameof<EffectivenessOverviewModel>((x) => x.sourceUploaderDueDate),
        nameof<EffectivenessOverviewModel>((x) => x.evidenceUploaderDueDate),
        nameof<EffectivenessOverviewModel>((x) => x.testerDueDate),
        nameof<EffectivenessOverviewModel>((x) => x.reviewerDueDate),
    ];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _advancedTestingService: AdvEffDataService,
        private _settingsService: SettingsDataService,
        private _tabService: TabService,
        private _pages: Pages,
        private _permissions: PermissionsService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.advEffTesting;
        this.loadData();
    }

    loadData() {
        this.data = this._advancedTestingService.getEffectivenessWorkspace().toPromise();
    }

    open(model: EffectivenessOverviewModel) {
        this._tabService.generateTab(
            this._pages.ControlsTestingAdvEffDetail,
            model.guid,
            `Test Plan: ${model.name}`
        );
    }
}
