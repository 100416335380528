<div class="detail-page-card">
    <div class="detail-page-card-title" *ngIf="this.tab && this.tab.name">{{ this.tab.name }}</div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="loadHistory()" [disabled]="isLoading">
            <i class="fas fa-sync"></i>
            <span>Reload</span>
        </button>
    </div>

    <div class="fancy-loader my-4" *ngIf="isLoading"></div>
    <div class="detail-page-card-body padded text-center" *ngIf="!isLoading && !historyModels">
        History not available.
    </div>

    <div class="detail-page-card-body flush-expansion-panels" *ngIf="!isLoading && historyModels">
        <mat-accordion>
            <mat-expansion-panel
                *ngFor="let historyModel of historyModels"
                (opened)="loadChanges(historyModel)"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ historyModel.MutationDate | dateTimeFormat: true }}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ historyModel.MutationAction }} by {{ historyModel.UserName }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="fancy-loader submodule my-4" *ngIf="!HistoryDetailModel"></div>
                    <div *ngIf="HistoryDetailModel && HistoryDetailModel.changes">
                        <fieldset
                            class="well fieldset"
                            *ngIf="HistoryDetailModel.changes.length > 0"
                        >
                            <legend class="legend">Form changes</legend>
                            <history-detail [changes]="HistoryDetailModel.changes"></history-detail>
                        </fieldset>

                        <div *ngFor="let pageChange of HistoryDetailModel.pageChanges">
                            <fieldset
                                class="well fieldset indent-1"
                                *ngIf="pageChange.changes.length > 0"
                            >
                                <legend class="legend">Page changes ({{ pageChange.name }})</legend>
                                <history-detail [changes]="pageChange.changes"></history-detail>
                            </fieldset>

                            <div *ngFor="let fieldChange of pageChange.fieldChanges">
                                <fieldset
                                    class="well fieldset indent-2"
                                    *ngIf="pageChange.fieldChanges.length > 0"
                                >
                                    <legend class="legend">
                                        Field changes (Page: {{ pageChange.name }} -> Field:
                                        {{ fieldChange.name }})
                                    </legend>
                                    <history-detail
                                        [changes]="fieldChange.changes"
                                    ></history-detail>
                                </fieldset>

                                <div *ngFor="let documentChange of fieldChange.documentChanges">
                                    <fieldset
                                        class="well fieldset indent-3"
                                        *ngIf="fieldChange.documentChanges.length > 0"
                                    >
                                        <legend class="legend">
                                            Field changes (Page: {{ pageChange.name }} -> Field:
                                            {{ fieldChange.name }})
                                        </legend>
                                        <history-detail
                                            [changes]="documentChange.changes"
                                        ></history-detail>
                                    </fieldset>
                                </div>
                            </div>

                            <div *ngFor="let workflowChange of pageChange.workflowChanges">
                                <fieldset
                                    class="well fieldset indent-2"
                                    *ngIf="pageChange.workflowChanges.length > 0"
                                >
                                    <legend class="legend">
                                        Workflow page definition changes (Page:
                                        {{ pageChange.name }})
                                    </legend>
                                    <history-detail
                                        [changes]="workflowChange.changes"
                                    ></history-detail>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
