import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";

/** Looks for specified nodes through provided tree. Stops as soon as all nodes are found or full tree has been traversed. */
export function FindNodes(tree: CerrixTreeItem[], ids: number[]): CerrixTreeItem[] {
    const foundNodes = [];
    const toProcess = [...tree];

    while (toProcess.length > 0) {
        const item = toProcess.shift();
        if (ids.indexOf(+item.ID) >= 0) {
            foundNodes.push(item);

            if (foundNodes.length === ids.length) {
                return foundNodes;
            }
        }

        if (item.Children && item.Children.length > 0) {
            toProcess.push(...item.Children);
        }
    }

    return foundNodes;
}

export function FindNode(tree: CerrixTreeItem[], id: number): CerrixTreeItem {
    const foundNodes = FindNodes(tree, [id]);
    if (foundNodes && foundNodes.length > 0) {
        return foundNodes[0];
    }

    return null;
}

export function FilterTree(tree: CerrixTreeItem[], searchFilter: string): CerrixTreeItem[] {
    ResetTreeFilter(tree);

    if (!searchFilter || searchFilter === "") {
        return tree;
    }

    const keywords = searchFilter.toLowerCase().split(" ");
    const applyFilter = (itemScope: CerrixTreeItem[]): CerrixTreeItem[] => {
        itemScope.forEach((item) => {
            const name = item.Name.toLowerCase();
            item.isExpanded = false;

            if (keywords.some((kw) => name.indexOf(kw) >= 0)) {
                item.highlight = true;
                item.show = true;
            } else {
                item.highlight = false;
                item.show = false;
            }

            applyFilter(item.Children);
            if (item.Children.some((x) => x.show)) {
                item.show = true;
                item.isExpanded = true;
            }
        });

        return itemScope;
    };

    applyFilter(tree);

    return tree;
}

export function ResetTreeFilter(tree: CerrixTreeItem[]) {
    tree.forEach((item) => {
        item.isExpanded = false;
        item.show = true;
        item.highlight = false;

        ResetTreeFilter(item.Children);
    });
}
