import { SampleScore } from "../enums/sample-score.enum";
import { DocumentModel } from "@models/documents/documentmodel";
import { EffectivenessStatusType } from "../../shared/enums/effectiveness-status-type.enum";
import { EvidenceTestStepEditModel } from "../../shared/models/evidence-test-step-edit-model";
import { TestStepScore } from "../enums";

export class EvidenceSampleEditModel {
    guid: string;
    effectivenessGuid: string;
    name: string;
    remarks: string;
    status: EffectivenessStatusType;
    noEvidence: boolean;
    noEvidenceReason: string;
    score: SampleScore;
    scoreName: string;
    scoreColor: string;
    commentTester: string;
    commentReviewer: string;

    testSteps: EvidenceTestStepEditModel[] = [];

    documents: DocumentModel[] = [];

    showEditor = false;
    disableSample = false;

    deserialize(input: EvidenceSampleEditModel): EvidenceSampleEditModel {
        this.guid = input.guid;
        this.effectivenessGuid = input.effectivenessGuid;

        this.updateValues(input);

        for (const testStep of input.testSteps) {
            let testStepModel = new EvidenceTestStepEditModel().deserialize(testStep);
            testStepModel.sampleName = this.name;
            this.testSteps.push(testStepModel);
        }

        return this;
    }

    updateValues(input: EvidenceSampleEditModel) {
        this.name = input.name;
        this.remarks = input.remarks;
        this.status = input.status;
        this.noEvidence = input.noEvidence;
        this.noEvidenceReason = input.noEvidenceReason;
        this.score = input.score;
        this.scoreName = input.scoreName;
        this.scoreColor = input.scoreColor;
        this.commentTester = input.commentTester;
        this.commentReviewer = input.commentReviewer;

        this.documents = input.documents;
    }

    updateSampleCommentEnabling(input: EvidenceSampleEditModel) {
        this.disableSample = input.testSteps.some((x) => x.score === TestStepScore.noScore);
    }
}
