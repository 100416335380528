import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { isGuid } from "@methods/uniqueMethods";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable } from "rxjs";
import { ControlExecutionOverviewModel } from "../control-execution-detail/models/control-execution-overview-model";
import { ControlExecutionPermissionModel } from "../control-execution-detail/models/control-execution-permission-model";
import { TaskSeriesOverviewModel } from "../control-execution-detail/models/task-series-overview-model";

@Injectable()
export class ControlExecutionDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controlexecution");
    }

    public getTaskSeries(guid: string): Observable<TaskSeriesOverviewModel[]> {
        if (isGuid(guid)) {
            const url = `/${guid}/taskseries`;
            return this.get<TaskSeriesOverviewModel[]>(url);
        }
    }

    public getPermissions(): Observable<ControlExecutionPermissionModel> {
        const url = "/p/permissions";
        return this.get<ControlExecutionPermissionModel>(url);
    }

    public getSpecificPermissions(guid: string): Observable<ControlExecutionPermissionModel> {
        const url = `/${guid}/p/permissions`;
        return this.get<ControlExecutionPermissionModel>(url);
    }

    public getDocuments(guid: string): Observable<DocumentModel[]> {
        const url = `/${guid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(guid: string): Observable<HyperlinkModel[]> {
        const url = `/${guid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public getLinkableControls(guid: string): Observable<ControlLinkModel[]> {
        if (isGuid(guid)) {
            const url = `/${guid}/controls`;
            return this.get<ControlLinkModel[]>(url);
        }
    }

    public getRecentAndUpcoming(controlGuid): Observable<ControlExecutionOverviewModel[]> {
        return this.get<ControlExecutionOverviewModel[]>(`/${controlGuid}/recentandupcoming`);
    }

    public getControlExecutions(controlGuid): Observable<ControlExecutionOverviewModel[]> {
        return this.get<ControlExecutionOverviewModel[]>(`/${controlGuid}/controlexecution`);
    }

    public getControlId(controlGuid: string): Observable<number> {
        const url = `/${controlGuid}/parentcontrol`;
        return this.get<number>(url);
    }

    public getHistory(guid: string): Observable<HistoryModel[]> {
        const url = `/${guid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(guid: string, mutationId: number): Observable<HistoryChange[]> {
        const url = `/${guid}/history/${mutationId}`;

        return this.get<HistoryChange[]>(url);
    }

    public deleteControlExecution(guid: string): Observable<string[]> {
        const url = "/" + guid + "/delete";

        return this.delete(url);
    }
}
