import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-upload-source-document-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlUploadSourceDocumentApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Upload Source Document API",
            description:
                "Used to provide a source document for a specific test plan. If the file is a spreadsheet and the ID column is indicated, samples can be generated using this column.",

            httpMethod: "POST",
            path: "/controls/testing/advanced/effectiveness/{effectivenessGuid}/automation/sourcedoc",

            parameters: [
                {
                    name: "effectivenessGuid",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
                {
                    name: "idColumn",
                    description:
                        "The 1-based index of the column that contains the unique IDs from the source system. Requires source file to be a spreadsheet.",
                    in: "query",
                    paramType: "integer",
                    required: false,
                    example: "1 | 2 | 3",
                },
                {
                    name: "nrOfHeadingRowsToSkip",
                    description:
                        "Indicates this spreadsheet has heading row(s) that need to be disregarded when searching for IDs. Requires source file to be a spreadsheet.",
                    in: "query",
                    paramType: "integer",
                    required: false,
                    example: "1 | 2 | 3",
                },
                {
                    name: "sourceDocument",
                    description:
                        "Single file that should be stored as source document on the supplied test plan.",
                    in: "formData",
                    paramType: "file",
                    required: true,
                    example: "binary",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: "Request has been successfully processed",
                },
            ],
        };
    }
}
