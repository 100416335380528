import { BaseFileModel, FileType } from "./BaseFileModel";

export class DocumentFolderModel extends BaseFileModel {
    public ID: number;
    public Guid: string;
    public ParentID?: number;

    public Name: string;

    // Used in front end
    public Changed: boolean;

    constructor() {
        super();
        this.FileType = FileType.Folder;
    }
}
