<eff-popup-header
    [title]="title"
    [dialogRef]="dialogRef"
    data-uiid="new-eff-generate-samples-header"
></eff-popup-header>
<div class="dialog-body">
    <sample-type-step
        *ngIf="currentStep == 1"
        [sampleFormGroup]="sampleTypeFormGroup"
        [sampleTypes]="availableSampleTypes"
        [samplingInformation]="samplingInformation"
        [sourceDocumentModel]="sourceDocumentModel"
        [effectivenessModel]="effectivenessModel"
        [isAutomatic]="isAutomatic"
    ></sample-type-step>
    <manual-samples-step
        *ngIf="currentStep == 2"
        [samplingInformation]="samplingInformation"
        [manualSamplesGroup]="manualSamplesGroup"
        [effectivenessModel]="effectivenessModel"
    >
    </manual-samples-step>
</div>

<div class="dialog-footer">
    <eff-button
        class="cancel-button"
        [text]="backButtonName"
        (onClick)="onPreviousStep()"
        data-uiid="new-eff-generate-samples-cancel-button"
    ></eff-button>
    <eff-button
        class="confirm-button"
        [disabled]="!isNextButtonEnabled"
        [text]="nextButtonName"
        (onClick)="onNextStep()"
        data-uiid="new-eff-generate-samples-confirm-button"
    ></eff-button>
</div>
