import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { CerrixIcon } from "@app/shared/models/CerrixIconModel";
import { SelectableIcons } from "@constants/selectableicons";
import { parseTyped } from "@methods/CommonMethods";

@Component({
    selector: "cerrix-icon-viewer",
    templateUrl: "./cerrix-icon-viewer.component.html",
    styleUrls: ["./cerrix-icon-viewer.component.scss"],
})
export class CerrixIconViewerComponent implements OnInit, OnChanges {
    @Input() icon: string;
    @Input() appendClass: string;
    @Input() detailed = false;
    @Input() defaultColor = "#000000";
    @Output() onClick = new EventEmitter<never>();

    iconName: string;
    selector: string;
    animated: boolean;
    bordered: boolean;
    color: string;

    constructor() {}

    ngOnInit() {
        this.reload(this.icon);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.icon.currentValue !== changes.icon.previousValue) {
            this.reload(this.icon);
        }
    }

    reload(value: string) {
        const parsed = parseTyped<CerrixIcon>(value, {
            iconName: "",
            selector: value,
            animate: false,
            border: false,
            color: this.defaultColor,
        });
        this.iconName = parsed.iconName;
        this.selector = parsed.selector;
        this.animated = parsed.animate;
        this.bordered = parsed.border;
        this.color = parsed.color;

        if (!this.iconName) {
            const foundIcon = SelectableIcons.icons.find((x) => x.selector === this.selector);
            if (foundIcon) {
                this.iconName = foundIcon.name;
            }
        }
    }

    clicked() {
        if (this.onClick.observers.any()) {
            this.onClick.emit();
        }
    }
}
