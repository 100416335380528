import { Component, ElementRef, ViewChild } from "@angular/core";
import { ControlsWidgetsDataService } from "../../../services/controls-widgets.data.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";
import { nameof } from "@methods/jeffs-toolkit";
import { ControlInfoModel } from "@app/controls/models/ControlInfoModel";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";

@Component({
    selector: "controls-eff-testing-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class ControlEffTestingWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private controlsWidgetsDataService: ControlsWidgetsDataService,
        private pages: Pages,
        tabService: TabService,
        sd: StandingdataDataService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            nameof<ControlInfoModel>((x) => x.organizationId);
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            nameof<ControlInfoModel>((x) => x.businessDimensionIds);
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.FrameworkDimension] =
            nameof<ControlInfoModel>((x) => x.frameworkDimensionIds);
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.controlsWidgetsDataService
            .getEffectivenessWidgetInfo(filters)
            .toPromise();

        this.config.noData = !data || data.every((x) => x.y === 0);
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <any>{
                colorByPoint: true,
                data,
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);
        config.legend = { enabled: false };
        config.xAxis = {
            type: "category",
        };

        config.yAxis = {
            allowDecimals: false,
            title: {
                text: "Number of controls",
            },
        };

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        return config;
    }

    protected async openWorkspace(ev) {
        const category = ev.point.name;
        const scoreColumnName = nameof<ControlInfoModel>((c) => c.effectivenessScore);
        const requiresMonitoringColumnName = nameof<ControlInfoModel>((c) => c.requiresMonitoring);
        const searchKeywords: SearchKeyword[] = [
            this.getSearchKeyword(scoreColumnName, category),
            this.getSearchKeyword(requiresMonitoringColumnName, "Yes"),
        ];

        const preset = await this.getBasePreset(searchKeywords);
        const pageToOpen = this.pages.ControlOverview;

        this.openPreset(pageToOpen, preset);
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.ControlOverview, preset);
    }
}
