<div class="form-image-preview" (click)="showModal()">
    <div class="form-image">
        <div class="preview">
            <img
                class="preview-image"
                [src]="image ? 'api/documents/' + image + '/data' : fallbackImageUrl"
                [style]="{ 'max-height': maxHeight, 'max-width': maxWidth }"
            />
        </div>
    </div>

    <div class="form-image-footer">Click to change the image.</div>
</div>

<div class="modal fade" aria-hidden="true" #imageModal>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Select an image</h5>
                <button type="button" class="btn-close" (click)="hideModal()"></button>
            </div>
            <div class="modal-body">
                <ngx-masonry
                    [options]="{ percentPosition: true, gutter: 10, horizontalOrder: false }"
                >
                    <div
                        ngxMasonryItem
                        class="masonry-item"
                        *ngFor="let img of allImages"
                        (click)="applyImage(img.guid)"
                    >
                        <img masonryLazy loading="lazy" [attr.src]="img.thumb" />
                    </div>
                </ngx-masonry>
            </div>
        </div>
    </div>
</div>
