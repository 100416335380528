import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlDetailOverviewModel } from "@app/controls/detail-overview/ControlDetailOverviewModel";
import { ControlDetailOverviewDataService } from "@app/controls/detail-overview/control-detail-overview.data.service";
import { Observable } from "rxjs";

@Component({
    selector: "eff-step-header",
    templateUrl: "./eff-step-header.component.html",
    styleUrls: ["./eff-step-header.component.scss"],
})
export class EffStepHeaderComponent {
    @Input() title: string;
    @Input() controlGuid: string;
    @Input() saveButtonLabel: string = "Save";
    @Input() hideSaveButton: boolean = false;
    @Input() showNonOccurrenceWarningArea: boolean = false;

    @Output() onSaveChanges: EventEmitter<any> = new EventEmitter();
    @Output() onShowInstructions: EventEmitter<any> = new EventEmitter();

    controlInfo$: Observable<ControlDetailOverviewModel> = undefined;

    constructor(private _controlService: ControlDetailOverviewDataService) {}

    async ngOnInit() {
        await this.loadControlInfo();
    }

    async loadControlInfo() {
        if (this.controlInfo$) return;
        this.controlInfo$ = await this._controlService.getControlOverview(this.controlGuid);
    }

    onSave() {
        this.onSaveChanges.emit();
    }

    clickInstructions() {
        this.onShowInstructions.emit();
    }
}
