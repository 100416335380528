<div class="fancy-loader" *ngIf="!pageReady"></div>

<div class="overview" *ngIf="pageReady">
    <cerrix-select-tree
        [tree]="standingData.frameworks"
        [value]="selectedFrameworkIDs"
        [multiselect]="true"
        (onChangeIds)="applyFrameworkChanges($event, frameworkSelectTree)"
        style="display: none"
        #frameworkSelectTree
    ></cerrix-select-tree>

    <div class="items">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Fieldwork Steps</div>
            <div
                class="detail-page-card-actions"
                *ngIf="
                    permissions.fieldworkPermissions.canCreate ||
                    permissions.fieldworkPermissions.canDelete
                "
            >
                <button
                    class="btn btn-raised"
                    data-uiid="fieldwork-plan-add-framework-step-button"
                    (click)="frameworkSelectTree.openSelectionPopup()"
                >
                    <i class="fas fa-sitemap me-2"></i>Framework selection
                </button>
                <div class="spacer"></div>
                <button
                    class="btn btn-raised"
                    data-uiid="fieldwork-plan-add-custom-step-button"
                    (click)="addCustomStep()"
                    *ngIf="permissions.fieldworkPermissions.canCreate"
                >
                    <i class="fas fa-plus me-2"></i>Add custom step
                </button>
            </div>
            <div class="detail-page-card-body" *ngIf="treeViewerConfig">
                <cerrix-tree-viewer [config]="treeViewerConfig"></cerrix-tree-viewer>
            </div>
        </div>
    </div>

    <div class="detail">
        <div class="edit-area">
            <div class="no-selection-message" *ngIf="!selectedFormGroup">
                <span>Select a step to configure the details.</span>
            </div>

            <div class="edit-details" *ngIf="selectedFormGroup">
                <div class="detail-page-action-bar">
                    <span class="sub-message" *ngIf="selectedStepIsFrameworkStep">
                        Framework step linked to '{{ selectedStep.frameworkName }}'
                    </span>
                    <span class="sub-message" *ngIf="!selectedStepIsFrameworkStep">
                        Custom step
                    </span>

                    <div class="right-area">
                        <button
                            class="btn btn-danger btn-sm"
                            *ngIf="permissions.fieldworkPermissions.canDelete"
                            (click)="removeStep(selectedStep.id)"
                        >
                            <i class="fal fa-trash"></i>
                            <span class="d-none d-lg-inline">Remove</span>
                        </button>
                    </div>
                </div>

                <form [formGroup]="selectedFormGroup">
                    <div class="detail-page-card">
                        <div class="detail-page-card-title">
                            <span>Step details</span>
                        </div>
                        <div class="detail-page-card-body padded">
                            <div class="row">
                                <div class="col-6">
                                    <cerrix-textfield
                                        fieldLabel="Name"
                                        formControlName="name"
                                    ></cerrix-textfield>
                                    <div class="form-group col cerrix-textfield">
                                        <label>Icon</label>
                                        <cerrix-icon-picker
                                            *ngIf="selectedStep"
                                            formControlName="icon"
                                            [value]="selectedStep.icon"
                                        ></cerrix-icon-picker>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group col cerrix-textfield">
                                        <label>Auditors</label>
                                        <ng-select
                                            id="auditorSelect"
                                            name="auditorSelect"
                                            [multiple]="true"
                                            [items]="auditors"
                                            placeholder="{{
                                                selectedStep.useReviewWorkflow ? 'Required' : ''
                                            }}"
                                            formControlName="auditors"
                                            bindLabel="Name"
                                            bindValue="ID"
                                            [closeOnSelect]="false"
                                        >
                                        </ng-select>
                                    </div>

                                    <div class="form-group col cerrix-textfield">
                                        <label>Reviewers</label>
                                        <ng-select
                                            id="reviewerSelect"
                                            name="reviewerSelect"
                                            [multiple]="true"
                                            [items]="reviewers"
                                            placeholder="{{
                                                selectedStep.useReviewWorkflow ? 'Required' : ''
                                            }}"
                                            formControlName="reviewers"
                                            bindLabel="Name"
                                            bindValue="ID"
                                            [closeOnSelect]="false"
                                        >
                                        </ng-select>
                                    </div>
                                    <div class="boolswitch detail-page-card-title">
                                        <mat-slide-toggle
                                            name="boolvalue"
                                            formControlName="useReviewWorkflow"
                                            (change)="useReviewWorkflowChanged($event)"
                                            size="small"
                                            color="primary"
                                            data-uiid="audit-detail-fieldwork-plan-use-review-workflow"
                                        >
                                            Use Review Workflow
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>

                            <cerrix-textarea
                                fieldLabel="Description"
                                formControlName="description"
                            ></cerrix-textarea>
                        </div>
                    </div>

                    <div class="detail-page-card">
                        <div class="detail-page-card-title">
                            <span>Execution working method</span>
                        </div>
                        <div class="detail-page-card-actions">
                            <audit-detail-fieldwork-execution-detail-manager
                                [steps]="steps"
                                [canEdit]="permissions.fieldworkPermissions.canEdit"
                                (onChange)="setSelectedStepExecutionDetails()"
                            >
                            </audit-detail-fieldwork-execution-detail-manager>
                        </div>
                        <div class="detail-page-card-body padded">
                            <ng-container *ngIf="selectedStepExecutionDetails">
                                <div
                                    *ngFor="let item of selectedStepExecutionDetails | keyvalue"
                                    class="form-group col cerrix-textfield"
                                >
                                    <label>{{ item.key }}</label>
                                    <textarea
                                        autogrow
                                        class="form-control form-control-sm"
                                        readonly
                                        [value]="item.value"
                                    >
                                    </textarea>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!selectedStepExecutionDetails">
                                <span
                                    >No execution working method defined for this step. Click on
                                    'Manage execution' to define fields.</span
                                >
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
