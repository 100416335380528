import { Pipe, PipeTransform } from "@angular/core";
import { LocalizationService } from "../localization";

@Pipe({
    name: "dateTimeDisplay",
    standalone: true,
    pure: true,
})
export class DateTimeDisplayPipe implements PipeTransform {
    constructor(private localizationService: LocalizationService) {}

    transform(
        value: Date | string,
        time: boolean = false,
        seconds: boolean = false
    ): string | null {
        return this.localizationService.formatDateTime(value, time, seconds);
    }
}
