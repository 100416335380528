import { Component, OnInit } from "@angular/core";
import { ModuleData } from "@app/process-editor/models/ModuleData";
import { Pages } from "@constants/pages/Pages";
import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { IModule } from "@models/processeditor/DiagramData";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { TabService } from "@services/tabs/TabService";
import { Observable } from "rxjs";
import { BaseShapeProperty } from "../BaseShapeProperty";
import { RiskConfigModel } from "@app/risk/models/risk-config-model";

@Component({
    selector: "process-editor-risk-shape-properties",
    templateUrl: "./risk-shape-properties.component.html",
    styleUrls: ["./risk-shape-properties.component.scss"],
})
export class RiskShapePropertiesComponent extends BaseShapeProperty implements OnInit {
    data: ModuleData;
    riskObservable: Observable<IModule[]>;

    constructor(
        private _ds: ProcessEditorDataService,
        private pages: Pages,
        private tabService: TabService
    ) {
        super();
    }

    ngOnInit() {}

    load(data: ModuleData) {
        this.data = data;
        this.setObservable();
        this.loadModuleConfig(data.graph, data.selectedCell);
    }

    openNew() {
        const riskConfig = <RiskConfigModel>{
            organizationId: this.data.currentOrganizationId,
            businessId: this.data.currentBusinessDimensionId,
        };

        const tab = this.tabService.getTab(this.pages.RiskDetail, null, `(R) New Risk`, riskConfig);

        const listenerID = this.tabService.listeners.addTabListener(
            TabEventListenerType.OnSave,
            tab.identifier,
            (guid) =>
                this.onSave(guid, this.data.graph, this.data.selectedCell).then(() => {
                    this.tabService.listeners.removeListener(listenerID);
                }),
            this.data.tab
        );

        this.tabService.addTab(tab);
    }

    open(module: IModule) {
        const tab = this.tabService.getTab(this.pages.RiskDetail, module.guid);
        this.tabService.addTab(tab);
    }

    isReadOnly(): boolean {
        return this.data.isReadOnly;
    }

    getObservable(): Observable<IModule[]> {
        return this.riskObservable;
    }

    setObservable(): void {
        this.riskObservable = this._ds.getProcessRisks(
            -1,
            this.data.currentOrganizationId,
            this.data.currentBusinessDimensionId
        );
    }

    getItemEnum(): ItemEnum {
        return ItemEnum.Risk;
    }
}
