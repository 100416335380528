import { Component, OnInit } from "@angular/core";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { of } from "rxjs";
import { Pages } from "./../../../../common/constants/pages/Pages";
import {
    CerrixGridEditorColumnConfig,
    CerrixGridEditorConfig,
} from "./../../../shared/models/cerrix-grid-editor-config";
import { CerrixPromptService } from "./../../../shared/services/cerrix-prompt.service";

@Component({
    selector: "audit-universe-planning",
    templateUrl: "./audit-universe-planning.component.html",
    styleUrls: ["./audit-universe-planning.component.scss"],
})
export class AuditUniversePlanningComponent implements OnInit {
    tab: TabModel;
    config: CerrixGridEditorConfig;

    constructor(
        private _promptService: CerrixPromptService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        this.setMockedData();
        this.tab.showLoader = false;
    }

    private setMockedData() {
        this.config = new CerrixGridEditorConfig();

        this.config.columns = [
            {
                prettyName: "Object Name",
                fieldName: "objectName",
                fieldType: GenericListFieldType.Text,
                pinLeft: true,
                isReadonly: true,
            },
            {
                prettyName: "Object Description",
                fieldName: "description",
                fieldType: GenericListFieldType.Text,
                pinLeft: true,
                isReadonly: true,
            },
            {
                prettyName: "Latest Risk Scoring",
                fieldName: "riskScore",
                fieldType: GenericListFieldType.ColoredSelect,
                pinLeft: true,
                isReadonly: true,
                getDataMethod: () => {
                    return of([
                        {
                            ID: 1,
                            Name: "Failed",
                            Color: "Red",
                        },
                        {
                            ID: 2,
                            Name: "Succeeded",
                            Color: "Green",
                        },
                    ]);
                },
            },
        ];

        this.config.rowButtons = [
            {
                tooltip: "Details",
                icon: "fas fa-info",
                clickEvent: (row) => {
                    this._promptService.prompt({
                        data: {
                            title: "Audit Universe details",
                            fields: [
                                {
                                    prettyName: "Object Name",
                                    fieldName: "objectName",
                                    fieldType: GenericListFieldType.Text,
                                    isReadonly: true,
                                },
                                {
                                    prettyName: "Description",
                                    fieldName: "description",
                                    fieldType: GenericListFieldType.TextArea,
                                },
                            ],
                            fieldResult: row,

                            cancelButton: {
                                show: false,
                            },
                        },
                    });
                },
            },
            {
                tooltip: "Comments",
                icon: "far fa-comments",
                clickEvent: (row) => {
                    this._promptService.prompt({
                        data: {
                            title: "Audit Universe Comments",
                            fields: [
                                {
                                    prettyName: "Comments",
                                    fieldName: "comments",
                                    fieldType: GenericListFieldType.TextArea,
                                },
                            ],
                            fieldResult: row,

                            cancelButton: {
                                show: false,
                            },
                        },
                    });
                },
            },
            {
                tooltip: "Open",
                icon: "fas fa-external-link",
                clickEvent: (row) => {
                    this._tabService.generateTab(this._pages.AuditUniverseDetail, row.id);
                },
            },
        ];

        for (let i = 1; i <= 5; i++) {
            const propName = (2018 + i).toString();
            const property: CerrixGridEditorColumnConfig = {
                prettyName: "Period " + propName,
                fieldName: propName,
                fieldType: GenericListFieldType.Number,
            };

            this.config.columns.push(property);
        }

        const getRandomCount = (max: number) => Math.floor(Math.random() * max);

        this.config.idProperty = "id";
        this.config.childProperty = "children";
        this.config.data = this.getRows(this.config, getRandomCount(25) + 5);

        if (this.config.childProperty) {
            this.config.data.forEach((d1) => {
                d1[this.config.childProperty] = this.getRows(this.config, getRandomCount(15));

                d1[this.config.childProperty].forEach((d2) => {
                    d2[this.config.childProperty] = this.getRows(this.config, getRandomCount(10));
                });
            });
        }
    }

    private getRows(config: CerrixGridEditorConfig, count: number) {
        var rows = [];
        for (let i = 1; i <= count; i++) {
            const row: any = {
                id: i,
                objectName: "Name " + i,
                description: "description " + i,
                comments: "comments " + i,
                objectEnabled: i % 2 === 0,
                riskScore: i % 2 === 0 ? 1 : 2,
            };

            config.columns.forEach((c) => {
                if (!row[c.fieldName]) {
                    row[c.fieldName] = Math.floor(Math.random() * 100);
                }
            });

            if (config.childProperty) {
                row[config.childProperty] = [];
            }

            rows.push(row);
        }

        return rows;
    }
}
