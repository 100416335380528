<div>
    <ul class="breadcrumbs">
        <li
            *ngFor="let item of path"
            [matMenuTriggerFor]="treeMenu"
            [matMenuTriggerData]="{ item: item }"
        >
            <cerrix-icon-viewer [icon]="item.Icon" appendClass="fa-fw me-1"></cerrix-icon-viewer>
            <a>{{ item.Name }}</a>
        </li>
    </ul>

    <mat-menu #treeMenu="matMenu" yPosition="above" class="mat-menu-padding mat-menu-breadcrumb">
        <ng-template matMenuContent let-item="item">
            <mat-tree [dataSource]="[item]" [treeControl]="treeControl" class="breadcrumb-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <li class="mat-tree-node">
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button mat-icon-button disabled></button>

                        <span (click)="processSelected(node.ID)" class="pointer">
                            <cerrix-icon-viewer
                                [icon]="node.Icon"
                                appendClass="fa-fw me-1"
                            ></cerrix-icon-viewer>
                            {{ node.Name }}
                        </span>
                    </li>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li>
                        <div class="mat-tree-node">
                            <button
                                mat-icon-button
                                matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.name"
                            >
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{
                                        treeControl.isExpanded(node)
                                            ? "expand_more"
                                            : "chevron_right"
                                    }}
                                </mat-icon>
                            </button>
                            <span (click)="processSelected(node.ID)" class="pointer">
                                <cerrix-icon-viewer
                                    [icon]="node.Icon"
                                    appendClass="fa-fw me-1"
                                ></cerrix-icon-viewer>
                                {{ node.Name }}
                            </span>
                        </div>
                        <ul [class.breadcrumb-tree-invisible]="!treeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                    </li>
                </mat-nested-tree-node>
            </mat-tree>
        </ng-template>
    </mat-menu>
</div>
