import { Component, Input } from "@angular/core";
import {
    EffectivenessModel,
    WorkflowGenerateSamplesModel,
} from "@app/controls/testing/advanced/shared/models";
import { ManualSample, ManualSamplesGroup } from "./models/manual-sample.model";
import { FormControl, Validators } from "@angular/forms";
import { SampleType } from "@app/controls/testing/advanced/shared/enums";
import { LocalizationService } from "@app/shared";

@Component({
    selector: "manual-samples-step",
    templateUrl: "./manual-samples-step.component.html",
    styleUrls: ["./manual-samples-step.component.scss"],
})
export class ManualSamplesStepComponent {
    @Input() samplingInformation: WorkflowGenerateSamplesModel;
    @Input() manualSamplesGroup: ManualSamplesGroup;
    @Input() effectivenessModel: EffectivenessModel;

    numberOfSamplesDisplayedPerRow: number = 3;
    samplesRows = [];
    emptyRowItems: number[] = [];

    sampleType: string = "text";
    message: string = "";

    constructor(private locService: LocalizationService) {}

    ngOnInit() {
        this.generateSamples();
        this.sampleType =
            this.samplingInformation.sampleType == SampleType.text ? "text" : "number";
        this.setMessage();
    }

    lastRowEmptyItems(isLastRow) {
        if (!isLastRow) {
            return [];
        } else {
            return this.emptyRowItems;
        }
    }

    generateSamples() {
        let samples: ManualSample[] = [];
        for (let i = 0; i < this.samplingInformation.numberOfTestSamples; i++) {
            samples.push({
                name: `sample ${i + 1}`,
                formControl: new FormControl(),
            });
        }

        this.manualSamplesGroup.setSamples(samples);

        for (var i = 0; i < samples.length / this.numberOfSamplesDisplayedPerRow; i++) {
            this.samplesRows.push(i);
        }

        if (this.samplesRows.length > 0) {
            let lastRowItems = samples.length % this.numberOfSamplesDisplayedPerRow;
            if (lastRowItems != 0) {
                let emptyItems = this.numberOfSamplesDisplayedPerRow - lastRowItems;

                for (let i = 0; i < emptyItems; i++) {
                    this.emptyRowItems.push(i);
                }
            }
        }
    }

    setMessage() {
        switch (this.samplingInformation.sampleType) {
            case SampleType.date:
                this.message = `Dates must be between ${this.locService.formatDate(
                    this.effectivenessModel.startDate
                )} and ${this.locService.formatDate(this.effectivenessModel.endDate)}.`;
                break;
            case SampleType.number:
                this.message = "Choose a number from your source document for each sample";
                break;
            case SampleType.text:
                this.message = "Choose a name from your source document for each sample";
                break;
        }
    }
    isDateSamples() {
        return this.samplingInformation.sampleType == SampleType.date;
    }
}
