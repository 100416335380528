export enum IncidentCommentType {
    Created = 1,
    Comment = 2,
    Accepted = 3,
    Rejected = 4,
    ReadyForApproval = 5,
    Approved = 6,
    NotApproved = 7,
    Reopened = 8,
    ReopenRejected = 9,
}
