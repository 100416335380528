<div
    class="matrix"
    *ngIf="matrix"
    [ngStyle]="{ width: matrix.impactSize * (cellSize + cellSpacing * 2) + 50 + 'px' }"
>
    <div class="left-legend">
        <div class="title"><span>Likelihood</span></div>
        <div class="measures">
            <div
                class="point"
                *ngFor="let y of matrix.matrix | keyvalue"
                [ngStyle]="{
                    'grid-row': matrix.likelihoodSize - y.key,
                    height: cellSize + 'px',
                    margin: cellSpacing + 'px 0'
                }"
                [title]="matrix.likelihoodInfo ? matrix.likelihoodInfo[y.key].Name : ''"
            >
                {{ +y.key + 1 }}
            </div>
        </div>
    </div>
    <div class="risk-matrix">
        <ng-container *ngFor="let y of matrix.matrix | keyvalue">
            <ng-container *ngFor="let x of y.value | keyvalue">
                <div
                    class="scale"
                    [ngStyle]="{
                        'background-color': matrix.colors[x.value],
                        'grid-row': matrix.likelihoodSize - y.key,
                        'grid-column': x.key + 1,
                        height: cellSize + 'px',
                        width: cellSize + 'px',
                        margin: cellSpacing + 'px'
                    }"
                    (click)="matrixCellClick(y.key, x.key, matrix.colors[x.value])"
                    [class.selected]="
                        highlightSelection &&
                        selected &&
                        selected.Likelihood == y.key &&
                        selected.Impact == x.key
                    "
                    [class.outline-border-top]="
                        borderTopCells && borderTopCells[y.key + '-' + x.key]
                    "
                    [class.outline-border-right]="
                        borderRightCells && borderRightCells[y.key + '-' + x.key]
                    "
                    [title]="
                        (matrix.likelihoodInfo
                            ? 'Likelihood: ' + matrix.likelihoodInfo[y.key].Name
                            : '') +
                        (matrix.impactInfo
                            ? (matrix.likelihoodInfo ? '\n' : '') +
                              'Impact: ' +
                              matrix.impactInfo[x.key].Name
                            : '')
                    "
                >
                    <div
                        class="cell-value"
                        *ngIf="
                            matrix.matrixContent &&
                            matrix.matrixContent.length > 0 &&
                            matrix.matrixContent[y.key][x.key]
                        "
                    >
                        <span>{{ matrix.matrixContent[y.key][x.key] }}</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="bottom-legend">
        <div class="measures">
            <div
                class="point"
                *ngFor="let x of matrix.matrix[0] | keyvalue"
                [title]="matrix.impactInfo ? matrix.impactInfo[x.key].Name : ''"
            >
                {{ +x.key + 1 }}
            </div>
        </div>
        <div class="title">{{ matrix.customImpactName ? matrix.customImpactName : "Impact" }}</div>
    </div>
</div>
