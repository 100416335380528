import { Observable, Subject, combineLatest, timer } from "rxjs";
import { debounce, filter } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { Component, DestroyRef, inject, Injector, OnInit, ViewChild } from "@angular/core";
import { CommonModule, NgComponentOutlet } from "@angular/common";
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule, Sort, SortDirection } from "@angular/material/sort";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from "@angular/material/tree";
import { ToastrService } from "ngx-toastr";
import moment from "moment";

import { TabModel } from "../../../common/models/generic/TabModels/TabModel";
import { IncidentHeaderModel } from "../models/incident-header.model";
import { IncidentOverviewModel } from "../models/incident-overview.model";
import { IncidentService } from "../services/incident.service";
import { IncidentStatus } from "../models/incident-status.enum";
import { PresetConfigDialogComponent } from "../../preset/preset-config-dialog/preset-config-dialog.component";
import { PresetModel } from "../../preset/models/preset.model";
import { FieldType } from "../../preset/models/field-type.enum";
import { PresetOverviewComponent } from "../../preset/preset-overview/preset-overview.component";
import { PresetDialogResult } from "../../preset/models/preset-dialog-result.model";
import { PresetDialogInput } from "../../preset/models/preset-dialog-input.model";
import { incidentColumnsTranslation } from "locale/translations-mappings/incident-columns-translation";
import { MatPaginatorTranslated } from "locale/translations-mappings/material-paginator-translation";
import { LocalizationService } from "./../../shared/localization/localization.service";
import { DateTimeDisplayPipe } from "./../../shared/pipes/date-time-display.pipe";
import { PresetService } from "@app/preset/services/preset.service";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { FlatTreeControl } from "@angular/cdk/tree";
import { PresetBaseModel } from "@app/preset/models/preset-base.model";
import { IncidentStandingDataMenuComponent } from "./incident-standing-data-menu/incident-standing-data-menu.component";
import { StandingDataListComponent } from "../../standing-data/standing-data-list/standing-data-list.component";
import { Pages } from "../../../common/constants/pages/Pages";
import { TabService } from "../../../services/tabs/TabService";
import { downloadWithLink } from "@methods/CommonMethods";
import {
    CerrixButtonComponent,
    CerrixDialogService,
    CerrixIconButtonComponent,
} from "@cerrix/components";
import { MatDividerModule } from "@angular/material/divider";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { IncidentPermissionType } from "@enums/permissions/incident-permission-type.enum";
import { BaseStandingDataService } from "@app/standing-data/interfaces/base-standing-data.service";
import { IncidentTypesService } from "../services/incident-types.service";
import { IncidentClassificationService } from "../services/incident-classification.service";

interface LeftMenuNode {
    icon?: string;
    name: string;
    children?: PresetBaseModel[];
}

interface LeftMenuFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    preset?: PresetBaseModel;
}

@Component({
    selector: "app-incident-overview",
    templateUrl: "./incident-overview.component.html",
    styleUrls: ["./incident-overview.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSortModule,
        MatInputModule,
        FormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatTreeModule,
        MatDividerModule,
        DateTimeDisplayPipe,
        IncidentStandingDataMenuComponent,
        StandingDataListComponent,
        CerrixIconButtonComponent,
        CerrixButtonComponent,
    ],
    providers: [
        IncidentService,
        PresetService,
        ToastrService,
        MatDialog,
        { provide: MatPaginatorIntl, useClass: MatPaginatorTranslated },
    ],
})
export class IncidentOverviewComponent implements OnInit {
    protected readonly incidentStatus: typeof IncidentStatus = IncidentStatus;
    protected readonly FieldType: typeof FieldType = FieldType;
    protected readonly incidentColumnsTranslation = incidentColumnsTranslation;

    @ViewChild("incidentsMenu", { static: true }) private menu: any;

    leftMenuItems: LeftMenuNode[] = [
        {
            icon: "folder_open",
            name: "Browse presets",
        },
        {
            icon: "star",
            name: "Favorite Presets",
        },
        {
            icon: "radio_button_checked",
            name: "Predefined Presets",
        },
    ];

    nodeMap = new Map<string, PresetBaseModel>();

    treeControl = new FlatTreeControl<LeftMenuFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        (node: LeftMenuNode, level: number) => {
            return {
                expandable: !!node.children && node.children.length > 0,
                name: node.name,
                level: level,
                icon: node.icon,
                preset: !!node.children ? undefined : (node as PresetBaseModel),
            };
        },
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    leftMenuDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: LeftMenuFlatNode) => node.expandable;

    private readonly destroyRef = inject(DestroyRef);
    private readonly injector = inject(Injector);
    private readonly presetService = inject(PresetService);
    private readonly incidentService = inject(IncidentService);
    private readonly toastrService = inject(ToastrService);
    private readonly localizationService = inject(LocalizationService);
    private readonly cerrixDialogService = inject(CerrixDialogService);
    private readonly permissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );
    private readonly dialog = inject(MatDialog);

    private readonly pages = inject(Pages);
    private readonly tabService = inject(TabService);
    private tab: TabModel;

    protected isReporter: boolean = false;

    protected headers = new Map<string, IncidentHeaderModel>();

    protected customFieldHeaders: IncidentHeaderModel[] = [];
    protected displayedColumns: string[] = [];
    protected loadingHeaders: boolean = true;
    protected tooltipDelay: number = 300;

    protected incidents: IncidentOverviewModel[] = [];
    protected loadingIncidents: boolean = true;
    protected incidentsCustomFields = new Map<string, string>();

    protected totalIncidents: number = 0;
    protected pageIndex: number = 0;
    protected pageSize: number = 25;

    protected sortActive: string = "Identifier";
    protected sortDirection: SortDirection = "desc";

    protected searchValue: string = "";
    private lastSearchValue: string = "";
    private search$ = new Subject<{
        force?: boolean;
    }>();
    private debounceSearch: number = 300;

    protected preset: PresetModel = null;
    private initialPresetData: string = null;
    protected hasPresetChanges: boolean = false;

    protected activePresetId: string | null = null;

    @ViewChild(NgComponentOutlet, { static: false })
    protected standingDataContainer: NgComponentOutlet;
    protected selectedStandingData: {
        component: typeof StandingDataListComponent;
        standingDataType: string;
        title: string;
        injector: Injector;
    } | null = null;

    ngOnInit() {
        this.loadLeftMenu();

        this.tab.menu = new TabMenu();
        this.tab.menu.customMenu = this.menu;

        this.loadUser();

        this.search$
            .pipe(
                debounce((search) => (search?.force ? timer(0) : timer(this.debounceSearch))),
                filter(
                    (search) =>
                        search.force ||
                        (this.searchValue !== this.lastSearchValue &&
                            (!this.searchValue || this.searchValue.length >= 3))
                ),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(() => {
                this.pageIndex = 0;
                this.loadIncidents();
            });
    }

    private loadUser(): void {
        this.isReporter = this.permissionService.hasAnyPermission(MicroserviceModule.Incidents, [
            IncidentPermissionType.SelectableAsReporter,
        ]);
    }

    private loadHeaders(): void {
        this.loadingHeaders = true;

        this.incidentService
            .getHeaders(this.activePresetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((headers) => {
                this.loadingHeaders = false;

                this.setHeaders(headers);

                if (this.incidents?.length) {
                    this.incidentsCustomFields = this.getCustomFieldsValues(this.incidents);
                }

                if (this.tab.showLoader) {
                    this.tab.showLoader = this.loadingHeaders && this.loadingIncidents;
                }
            });
    }

    private setHeaders(headers: IncidentHeaderModel[]): void {
        this.headers = new Map<string, IncidentHeaderModel>(headers.map((h) => [h.name, h]));

        this.customFieldHeaders = headers.filter((h) => !!h.translation);
        this.displayedColumns =
            this.preset?.presetColumns?.length && !this.activePresetId
                ? this.preset?.presetColumns.map((pc) =>
                      pc.columnName ? pc.columnName : pc.customFieldId
                  )
                : headers.map((h) => h.name);
    }

    private loadIncidents(): void {
        this.loadingIncidents = true;

        this.lastSearchValue = this.searchValue;

        this.incidentService
            .getIncidents(
                this.pageSize * this.pageIndex,
                this.pageSize,
                this.sortActive,
                this.sortDirection,
                this.searchValue,
                this.activePresetId,
                this.preset
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagedIncidents) => {
                this.loadingIncidents = false;

                this.totalIncidents = pagedIncidents.total;
                this.incidents = pagedIncidents.data;
                this.incidentsCustomFields = this.getCustomFieldsValues(pagedIncidents.data);

                if (this.tab.showLoader) {
                    this.tab.showLoader = this.loadingHeaders && this.loadingIncidents;
                }
            });
    }

    private loadPreset(presetId: string): void {
        this.presetService
            .getPreset(presetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((preset) => {
                this.preset = preset;
                this.initialPresetData = JSON.stringify(this.preset);
            });
    }

    private getCustomFieldsValues(incidents: IncidentOverviewModel[]): Map<string, string> {
        const incidentsCustomFields = new Map<string, string>();

        if (this.headers.size) {
            incidents.forEach((incident) => {
                if (incident.customFields?.length) {
                    incident.customFields.forEach((customField) => {
                        const header = this.headers.get(customField.id);

                        if (customField?.value && header?.columnOptions?.length) {
                            const cfIds = customField.value.split(",");

                            incidentsCustomFields.set(
                                incident.id + header.name,
                                header.columnOptions
                                    .filter((co) => cfIds.some((cfId) => cfId === co.id))
                                    .map((co) => co.translation)
                                    .join(", ")
                            );
                        } else if (
                            (header?.fieldType === FieldType.Date ||
                                header?.fieldType === FieldType.DateTime) &&
                            customField?.value
                        ) {
                            incidentsCustomFields.set(
                                incident.id + header.name,
                                this.localizationService.formatDateTime(
                                    moment.utc(customField.value).toDate(),
                                    header.fieldType === FieldType.DateTime
                                )
                            );
                        } else if (header) {
                            incidentsCustomFields.set(
                                incident.id + header.name,
                                customField?.value
                            );
                        }
                    });
                }
            });
        }

        return incidentsCustomFields;
    }

    protected onPageEvent(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.loadIncidents();
    }

    protected onSortChange(sortState: Sort) {
        this.sortActive = sortState.active;
        this.sortDirection = sortState.direction;

        this.pageIndex = 0;

        this.loadIncidents();
    }

    protected onSearch(onEnter: boolean) {
        if (!this.loadingIncidents && this.lastSearchValue !== this.searchValue) {
            this.search$.next({
                force: !!onEnter,
            });
        }
    }

    protected onClearSearch() {
        this.searchValue = "";
        this.search$.next({
            force: this.lastSearchValue !== "",
        });
    }

    // Maybe change this name to something like openIncident, openRow or doubleClickRow to make it easier to find
    protected openIncident(incident: IncidentOverviewModel) {
        this.tabService.generateTab(this.pages.IncidentDetail, incident.id, incident.name);
    }

    protected addIncident() {
        this.tabService.generateTab(this.pages.IncidentDetail, null, null);
    }

    protected onPresetConfig() {
        const dialogRef = this.dialog.open<
            PresetConfigDialogComponent,
            PresetDialogInput,
            PresetDialogResult
        >(PresetConfigDialogComponent, {
            data: { preset: this.preset },
        });

        dialogRef.componentInstance.favoriteAddEvent.subscribe((preset) =>
            this.favoriteAddSubscription(preset)
        );

        dialogRef.componentInstance.favoriteRemoveEvent.subscribe((preset) =>
            this.favoriteRemoveSubscription(preset)
        );

        dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult?.preset) {
                if (dialogResult.saved) {
                    this.toastrService.success(
                        `Preset with name '${dialogResult.preset.name}'`,
                        "Saved!"
                    );

                    this.selectPresetId(dialogResult.preset.id);
                } else {
                    this.previewPreset(dialogResult.preset);
                }
            }
        });
    }

    protected onOpenPresets(): void {
        let dialogRef = this.dialog.open<PresetOverviewComponent, string, string>(
            PresetOverviewComponent,
            {
                height: "75%",
                width: "75%",
                data: this.activePresetId,
            }
        );

        dialogRef.afterClosed().subscribe((activePresetId) => {
            if (activePresetId && this.activePresetId !== activePresetId) {
                this.selectPresetId(activePresetId);
            }
        });

        dialogRef.componentInstance.favoriteAddEvent
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((preset) => this.favoriteAddSubscription(preset));

        dialogRef.componentInstance.favoriteRemoveEvent
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((preset) => this.favoriteRemoveSubscription(preset));
    }

    private favoriteAddSubscription(preset: PresetBaseModel) {
        let favorites = this.leftMenuItems.find((pn) => pn.name === "Favorite Presets");

        if (favorites) {
            favorites.children.push(preset);
        } else {
            favorites = {
                icon: "star",
                name: "Favorite Presets",
                children: [preset],
            };

            this.leftMenuItems.splice(1, 0, favorites);
        }

        this.leftMenuDataSource.data = this.leftMenuItems;

        this.expandFavorites();
    }

    private favoriteRemoveSubscription(preset: PresetBaseModel) {
        let favorites = this.leftMenuItems.find((pn) => pn.name === "Favorite Presets");

        if (favorites.children.length == 1) {
            this.leftMenuItems.splice(this.leftMenuItems.indexOf(favorites, 0), 1);
        } else {
            favorites.children = favorites.children.filter((p) => p.id !== preset.id);
        }

        this.leftMenuDataSource.data = this.leftMenuItems;

        this.expandFavorites();
    }

    private previewPreset(preset: PresetModel): void {
        this.preset = preset;
        this.hasPresetChanges = this.initialPresetData !== JSON.stringify(this.preset);
        if (this.hasPresetChanges) {
            this.activePresetId = null;
        }

        this.reloadIncidents();
    }

    private selectPresetId(presetId: string): void {
        this.activePresetId = presetId;

        this.preset = null;
        this.initialPresetData = null;
        this.hasPresetChanges = false;
        this.loadPreset(presetId);

        this.reloadIncidents();
    }

    private reloadIncidents(): void {
        this.pageIndex = 0;

        this.headers.clear();
        this.loadHeaders();

        this.incidents = [];
        this.loadIncidents();
    }

    private clearIncidents(): void {
        this.pageIndex = 0;
        this.preset = null;
        this.activePresetId = null;
        this.initialPresetData = null;
        this.hasPresetChanges = false;
        this.headers.clear();
        this.incidents = [];
    }

    private exportIncidents(): void {
        var dateTimeFormat = this.localizationService.getExportDateTimeFormat();
        this.loadingIncidents = true;

        this.incidentService
            .exportIncidents(
                dateTimeFormat.dateFormat,
                dateTimeFormat.timeFormat,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                this.sortActive,
                this.sortDirection,
                this.searchValue,
                this.activePresetId,
                this.preset
            )
            .subscribe((data: Blob) => {
                const fileName = `Incidents_${moment().format("YYYYMMDDxHHmmss")}.xlsx`;
                const downloadURL = URL.createObjectURL(data);
                downloadWithLink(downloadURL, fileName);
                this.loadingIncidents = false;
            });
    }

    protected leftMenuAction(node: LeftMenuFlatNode): void {
        this.checkPageChanges().subscribe((allow) => {
            if (allow) {
                if (node.preset?.id) {
                    this.selectedStandingData = null;
                    this.selectPresetId(node.preset.id);
                } else if (!node.name.includes("Active")) {
                    // temporary
                    this.selectedStandingData = null;
                    this.onOpenPresets();
                }
            }
        });
    }

    private loadLeftMenu(): void {
        combineLatest([
            this.presetService.getFavorites(),
            this.presetService.getPredefined(),
            this.presetService.getDefault(),
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([favorites, predefined, defaultPreset]) => {
                this.preset = defaultPreset;
                this.activePresetId = defaultPreset.id;

                if (favorites.length) {
                    this.leftMenuItems.find((pn) => pn.name === "Favorite Presets").children =
                        favorites;
                } else {
                    this.leftMenuItems = this.leftMenuItems.filter(
                        (pn) => pn.name !== "Favorite Presets"
                    );
                }

                if (predefined.length) {
                    this.leftMenuItems.find((pn) => pn.name === "Predefined Presets").children =
                        predefined;
                } else {
                    this.leftMenuItems = this.leftMenuItems.filter(
                        (pm) => pm.name !== "Predefined Presets"
                    );
                }

                if (
                    !favorites.find((p) => p.id == this.activePresetId) &&
                    !predefined.find((p) => p.id == this.activePresetId)
                ) {
                    this.leftMenuItems.push({
                        icon: defaultPreset.icon,
                        name: `Active preset: ${defaultPreset.name}`,
                        children: [],
                    });
                }

                this.leftMenuDataSource.data = this.leftMenuItems;

                this.expandFavorites();
                this.selectPresetId(this.activePresetId);
            });
    }

    private expandFavorites(): void {
        this.treeControl.expand(
            this.treeControl.dataNodes.find((pm) => pm.name === "Favorite Presets")
        );
    }

    private checkPageChanges(): Observable<boolean> {
        if (
            this.standingDataContainer &&
            this.standingDataContainer["_componentRef"]?.instance?.hasChanges
        ) {
            return this.cerrixDialogService.confirmDialog(
                "Unsaved changes",
                "There are unsaved changes, are you sure you want to discard your changes?"
            );
        }

        return new Observable((observer) => {
            observer.next(true);
            observer.complete();
        });
    }

    protected setStandingData(standingData: { id: string; name: string }): void {
        this.checkPageChanges().subscribe((allow) => {
            if (allow) {
                let injector: Injector;

                if (standingData.id === "Classification") {
                    injector = Injector.create({
                        providers: [
                            {
                                provide: BaseStandingDataService,
                                useClass: IncidentClassificationService,
                            },
                        ],
                        parent: this.injector,
                    });
                } else if (standingData.id === "IncidentTypes") {
                    injector = Injector.create({
                        providers: [
                            { provide: BaseStandingDataService, useClass: IncidentTypesService },
                        ],
                        parent: this.injector,
                    });
                }

                this.selectedStandingData = {
                    component: StandingDataListComponent,
                    standingDataType: standingData.id,
                    title: standingData.name,
                    injector: injector,
                };

                this.clearIncidents();
            }
        });
    }

    protected hasStandingDataAccess(): boolean {
        return this.permissionService.hasAnyPermission(MicroserviceModule.Incidents, [
            IncidentPermissionType.ClassificationStandingData,
            IncidentPermissionType.IncidentTypeStandingData,
        ]);
    }
}
