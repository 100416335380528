import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { download, nameof } from "@methods/CommonMethods";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { DocumentAdminModel } from "@app/audit/shared/data-models/document-admin-model";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";

declare var $: any;

@Component({
    selector: "admin-documents",
    templateUrl: "./admin-documents.component.html",
    styleUrls: ["./admin-documents.component.scss"],
})
export class AdminDocumentsComponent implements OnInit {
    moduleType = ModuleType.CD;
    tabID: string;
    tab: TabModel;
    data: Promise<DocumentAdminModel[]>;
    headerLookup: { [propName: string]: string } = this.getHeaderLookup();
    permissions: WorkspaceModulePermissionModel;
    filterConfigs: FilterConfig[] = [];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: nameof<DocumentAdminModel>("updatedOn"),
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: nameof<DocumentAdminModel>("createdOn"),
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: nameof<DocumentAdminModel>("uploadedOn"),
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: nameof<DocumentAdminModel>("fileSize"),
            type: RendererType.Default,
            formatType: FormatType.FileSizeFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [
        {
            text: "Download",
            icon: "fas fa-download",
            rowRequired: true,
            clickEvent: (guid: string) => {
                // Should retrieve url from one place (document service)
                download(`api/documents/${guid}/data`);
            },
        },
        {
            text: "Show History",
            icon: "fas fa-history",
            rowRequired: true,
            clickEvent: (guid: string) => {
                this.documentHistoryGuid = guid;
                $(this.historyModal.nativeElement).modal("show");
            },
        },
        {
            text: "Bulk Delete",
            icon: "fas fa-dumpster-fire",
            getVisibleRange: true,
            clickEvent: (data: string[]) => {
                this.bulkDelete(data);
            },
            isDisabled: !this.permissionsService.permissions.Documents.Admin,
        },
    ];

    numberProps: string[] = [
        nameof<DocumentAdminModel>("version"),
        nameof<DocumentAdminModel>("fileSize"),
    ];
    dateProps: string[] = [
        nameof<DocumentAdminModel>("updatedOn"),
        nameof<DocumentAdminModel>("createdOn"),
        nameof<DocumentAdminModel>("uploadedOn"),
    ];

    @ViewChild("historyModal", { static: true }) historyModal: ElementRef;
    documentHistoryGuid: string;

    constructor(
        private permissionsService: PermissionsService,
        private toastr: ToastrService,
        private dataService: DocumentDataService,
        protected promptService: CerrixPromptService
    ) {
        this.permissions = {
            canAdd: false,
            canOpen: true,

            isModuleAdmin: false,
            canPublish: false,
            canSetSystemDefault: false,
        };
    }

    ngOnInit(): void {
        const hasAccess = this.permissionsService.permissions.Documents.Admin;
        if (!hasAccess) {
            this.toastr.error("No Access!");
            this.tab.close(false);
        }

        this.data = firstValueFrom(this.dataService.GetAdminDocuments()).then(this.mapHeaders);
    }

    protected getFile(row: DocumentAdminModel) {
        this.promptService
            .confirm("Download", `Do you want to download '${row.name}'?`)
            .onConfirm()
            .subscribe(() => {
                // Should retrieve url from one place (document service)
                download(`api/documents/${row.guid}/data`);
            });
    }

    private async bulkDelete(documentGuids: string[]) {
        const data = await this.data;
        if (!data || data.length === 0 || documentGuids.length === 0) {
            this.toastr.info("No documents to delete");
            return;
        }

        const confirmation = await this.approveDelete(documentGuids.length);
        if (!confirmation) {
            return;
        }

        if (documentGuids.length > data.length / 2) {
            const confirmation = await this.promptService
                .confirm(
                    "Bulk delete",
                    `You are about to delete more than half of the documents. Are you really sure?`
                )
                .toPromise();
            if (!confirmation) {
                return;
            }
        }

        var prompt = this.promptService.loader("Bulk delete", "Scheduling bulk delete.");

        try {
            await this.dataService.bulkDeleteDocuments(documentGuids);

            this.toastr.info("Bulk delete is scheduled");
        } finally {
            prompt.close();
        }
    }

    private async approveDelete(documentsAmount: number): Promise<boolean> {
        // Between 1000 - 9999
        const confirmationNumber = Math.floor(Math.random() * 8999 + 1000);

        const result = await this.promptService
            .prompt({
                maxHeight: "400px",
                maxWidth: "450px",
                data: {
                    title: "Bulk delete",
                    message: `Do you want to delete ${documentsAmount} documents?\nEnter the following code into the confirmation field below as confirmation:\n\n${confirmationNumber}`,
                    fields: [
                        {
                            prettyName: "Confirmation number",
                            fieldName: "enteredNumberText",
                            fieldType: GenericListFieldType.Text,
                            uiid: "bulk-delete-confirmation-number-input",
                        },
                    ],
                    centerText: true,
                },
            })
            .toPromise();

        if (result) {
            const enteredNumber = parseInt(result.enteredNumberText);
            if (!isNaN(enteredNumber) && enteredNumber === confirmationNumber) {
                return true;
            } else {
                this.toastr.error("Invalid confirmation number");
            }
        }

        return false;
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    private getHeaderLookup(): { [propName: string]: string } {
        const headerLookup = {};
        headerLookup[nameof<DocumentAdminModel>("guid")] = "Guid";
        headerLookup[nameof<DocumentAdminModel>("name")] = "Name";
        headerLookup[nameof<DocumentAdminModel>("description")] = "Description";
        headerLookup[nameof<DocumentAdminModel>("updatedBy")] = "Last editor";
        headerLookup[nameof<DocumentAdminModel>("updatedOn")] = "Date last edit";
        headerLookup[nameof<DocumentAdminModel>("createdOn")] = "Date created";
        headerLookup[nameof<DocumentAdminModel>("uploadedOn")] = "Date last file upload";
        headerLookup[nameof<DocumentAdminModel>("sourceTypeName")] = "Source";
        headerLookup[nameof<DocumentAdminModel>("typeName")] = "Type";
        headerLookup[nameof<DocumentAdminModel>("version")] = "Versions";
        headerLookup[nameof<DocumentAdminModel>("fileSize")] = "Filesize";

        return headerLookup;
    }
}
