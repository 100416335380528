<div class="modal-header">
    <h5 class="modal-title">Generate document</h5>
    <button type="button" class="btn-close" (click)="close()" title="Close"></button>
</div>
<div class="modal-body">
    <div *ngIf="!finishedLoading">
        <div class="fancy-loader"></div>
    </div>
    <div class="col" [formGroup]="formGroup" *ngIf="finishedLoading">
        <div class="row">
            <div class="form-group col">
                <label>Template</label>
                <ng-select
                    [items]="documentTemplatesModel.documents"
                    formControlName="templateGuid"
                    bindLabel="Name"
                    bindValue="Guid"
                    placeholder="Required"
                    data-uiid="documentmanagergenerate-templateselect"
                >
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col mb-0">
                <label>Document name</label>
                <i
                    class="far fa-info-circle ms-1"
                    tooltip='File name without extension. Extension is determined by "Document type". Characters that are not allowed: \ / : * ? " &lt; &gt; |'
                ></i>
                <cerrix-textfield
                    formControlName="documentName"
                    placeholder="Required"
                    data-uiid="documentmanagergenerate-documentnameinput"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <label>Document type</label>
                <ng-select
                    [items]="supportedFileTypes"
                    formControlName="documentType"
                    bindValue="id"
                    bindLabel="value"
                    placeholder="Required"
                    data-uiid="documentmanagergenerate-documenttypeselect"
                >
                </ng-select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-secondary"
        data-uiid="documentmanagergenerate-cancelbutton"
        (click)="close()"
    >
        Close
    </button>
    <button
        data-uiid="documentmanagergenerate-generatebutton"
        type="button"
        class="btn btn-primary"
        [disabled]="!finishedLoading"
        (click)="generate()"
    >
        Generate
    </button>
</div>
