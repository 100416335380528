<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    [module]="'Third Party'"
    [moduleType]="moduleType"
    [idProp]="'Guid'"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [NumberProperties]="numberProps"
    [DateProperties]="dateProps"
    (onDblClick)="openThirdParty($event)"
    (onAddClick)="addThirdParty()"
>
</cerrix-workspace>
