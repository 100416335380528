import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MoiModel } from "@models/moi/MoiModel";
import { MoiPermissionModel } from "@models/moi/MoiPermissionModel";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "moi-controls",
    templateUrl: "./moi-controls.component.html",
    styleUrls: ["./moi-controls.component.scss"],
})
export class MoiControlsComponent {
    @Input() model: MoiModel;
    @Input() disabled = false;
    @Input() moiPermissions: MoiPermissionModel;

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    lastOrganizationId = 0;
    linked: ControlLinkModel[];
    unlinked: ControlLinkModel[];

    constructor(private _controlDataService: ControlDataService) {}

    load() {
        if (
            !this.model ||
            !this.model.OrganizationResponsible ||
            this.lastOrganizationId === this.model.OrganizationResponsible
        ) {
            return;
        }

        this.isLoading = true;
        this.lastOrganizationId = this.model.OrganizationResponsible;

        this._controlDataService
            .getLinkableControls(ModuleType.IM, this.model.Id, this.model.OrganizationResponsible)
            .subscribe((value) => {
                this.loadData(this.model.LinkedControls, value.sortBy("name", true));
                this.isLoading = false;
            });
    }

    loadByOrganizationId(organization: number) {
        this.isLoading = true;
        this.lastOrganizationId = organization;
        this._controlDataService
            .getLinkableControls(ModuleType.IM, this.model.Id, this.model.OrganizationResponsible)
            .subscribe((value) => {
                this.loadData(this.model.LinkedControls, value.sortBy("name", true));
                this.isLoading = false;
            });
    }

    linkedChanged(linked: ControlLinkModel[]) {
        this.model.LinkedControls = linked.map((x) => x.id);
        this.changed.emit(this.model.LinkedControls);
    }

    private loadData(linkedIds: number[], items: ControlLinkModel[]) {
        const linked = [],
            unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
