import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { Observable, forkJoin, from, map } from "rxjs";
import { AuditFindingModel } from "./../../../../shared/data-models/audit-finding-model";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { AuditFindingPermissionModel } from "@app/audit-finding/shared/page-models/audit-finding-permission-model";

@Component({
    selector: "audit-finding-detail-main",
    templateUrl: "./audit-finding-detail-main.component.html",
    styleUrls: ["./audit-finding-detail-main.component.scss"],
})
export class AuditFindingDetailMainComponent implements OnInit {
    @Input() parentFormGroup: FormGroup;
    @Input() findingModel: AuditFindingModel;
    @Input() permissions: AuditFindingPermissionModel;

    auditeeRequest$: Observable<IdNameCombination[]>;
    backupModel: AuditFindingModel;
    auditGuid: string;

    constructor(
        private standingDataDataService: StandingdataDataService,
        private auditDataService: AuditDataService
    ) {}

    ngOnInit(): void {
        this.backupModel = this.findingModel;
        this.auditeeRequest$ = this.createAuditeeRequest$();
    }

    // Create a request for select box to only show auditees assigned to the audit
    private createAuditeeRequest$(): Observable<IdNameCombination[]> {
        const assignedAuditeeIds$ = from(
            this.auditDataService.getAudit(this.findingModel.auditGuid)
        ).pipe(map((audit) => audit.auditees));

        const allAuditeeUsers$ = this.auditDataService
            .getAuditStandingData(this.findingModel.auditGuid)
            .pipe(map((model) => model.auditeeUsers));

        return forkJoin([assignedAuditeeIds$, allAuditeeUsers$]).pipe(
            map(([assignedAuditeeIds, allAuditeeUsers]) =>
                allAuditeeUsers.filter((user) => assignedAuditeeIds.includes(user.ID))
            )
        );
    }

    protected getSeverities(): Observable<any> {
        return this.standingDataDataService.getAllByType(
            StandingDataType.AuditSeverity,
            this.findingModel.severityId
        );
    }

    protected getFollowUpData(): Observable<any> {
        return this.standingDataDataService.getAllByType(
            StandingDataType.AuditFindingFollowUp,
            ...this.findingModel.followUpIds
        );
    }
}
