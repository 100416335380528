import {
    Input,
    Output,
    EventEmitter,
    OnInit,
    Directive,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { guid } from "@methods/uniqueMethods";
import { Configuration } from "@app/app.constants";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";

@Directive()
export abstract class CerrixTextInputBase implements OnInit, ControlValueAccessor {
    _value = "";
    onChangeFormValidation = Function.prototype;
    onTouchedFormValidation = Function.prototype;

    isFormControl = false;
    customInvalid = false;
    fieldId: string;
    fieldName: string;
    multiline = false;

    @Input() disabled = false;
    @Input() required = false;
    @Input() readonly = false;
    @Input() limitInput = false;

    // Used to generate error message when using formControl instead of ngModel
    @Input() fieldLabel = "";
    @Input() errorLabel = "";

    @Input() identifier = "";
    @Input() id = "";
    @Input() name = "";
    @Input() cssClass = "form-control form-control-sm";
    @Input() labelCss = "";
    @Input() style = "";

    @Input() placeholder;
    @Input() type = "text";

    @Input() dataUiid: string = guid();

    @Output() valueChange = new EventEmitter<string>();
    @Output() blur = new EventEmitter();
    @Output() keyup = new EventEmitter();

    @ViewChild("inputField") inputField: ElementRef;

    @Input()
    set value(value: string) {
        if (this._value === value) {
            return;
        }

        this._value = value;
        this.valueChange.emit(value);
        this.onChangeFormValidation(value);
    }
    get value(): string {
        return this._value;
    }

    get maxLength(): number {
        const maxLength = this.multiline
            ? this._configuration.MaxTextAreaLength
            : this._configuration.MaxTextFieldLength;

        return maxLength;
    }

    get valid(): boolean {
        if (!this.isFormControl) {
            return !this.customInvalid;
        }

        return this.control && (this.control.valid || this.control.disabled);
    }

    get displayName(): string {
        if (this.fieldLabel.length > 0) {
            return this.fieldLabel;
        }

        if (this.errorLabel.length > 0) {
            return this.errorLabel;
        }

        return "This field";
    }

    constructor(public control: NgControl, protected _configuration: Configuration) {}

    ngOnInit(): void {
        this.init();
    }

    // This method can be called by the parent component to initialize the component
    public init(): void {
        this.placeholder =
            this.placeholder && this.placeholder.length > 0
                ? this.placeholder
                : this.required
                ? "Required"
                : "";

        this.fieldId = this.getFieldId();
        this.fieldName = this.getFieldName();

        // Add maxLength validator while keeping the already set validators
        if (this.control.control && this.control.control.parent) {
            this.isFormControl = true;
            this.setupFormControl();
        }

        this.validateValue(this.value);
    }

    private validateValue(value: string) {
        value = value ? value : "";
        if (value.length == 0 && this.required) {
            this.customInvalid = true;
        } else if (this.multiline && value.length > this._configuration.MaxTextAreaLength) {
            this.customInvalid = true;
        } else if (!this.multiline && value.length > this._configuration.MaxTextFieldLength) {
            this.customInvalid = true;
        } else {
            this.customInvalid = false;
        }
    }

    private setupFormControl(): void {
        const maxLenghthValidator = FormValidationHelper.validateMaxLength(
            this.displayName,
            this.maxLength
        );

        const validators = this.control.control.validator
            ? [this.control.control.validator, maxLenghthValidator]
            : [maxLenghthValidator];

        this.control.control.setValidators(validators);
        this.control.control.updateValueAndValidity();
    }

    private getFieldId(): string {
        if (this.id.length > 0) {
            return this.id;
        }

        if (this.identifier.length > 0) {
            return this.identifier;
        }

        return guid();
    }

    private getFieldName(): string {
        if (this.name.length > 0) {
            return this.name;
        }

        if (this.identifier.length > 0) {
            return this.identifier;
        }

        return this.id;
    }

    onBlur() {
        // Only run custom validation when not part of a formgroup
        if (!this.isFormControl) {
            this.validateValue(this.value);
        }

        this.onTouchedFormValidation();
        this.blur.emit();
    }

    onKeyup() {
        this.onTouchedFormValidation();
        this.keyup.emit();
    }

    writeValue(obj: string): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChangeFormValidation = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchedFormValidation = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
