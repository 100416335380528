import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KrisDataPointsExportComponent } from "./kri/kris-data-points-export/kris-data-points-export.component";
import { SharedModule } from "@app/shared/shared.module";
import { ControlModule } from "@app/controls/control.module";
import { ExportUsersComponent } from "./exporttypes/users/export-users.component";

@NgModule({
    declarations: [KrisDataPointsExportComponent, ExportUsersComponent],
    imports: [CommonModule, SharedModule, ControlModule],
    exports: [
        // Export until other exports are moved to app folder
        KrisDataPointsExportComponent,
        ExportUsersComponent,
    ],
})
export class ExportModule {}
