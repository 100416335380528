import { MatrixSelectionModel } from "@models/generic/MatrixSelectionModel";
import { OverrideRiskFieldTypes } from "../enums/OverrideRiskFieldTypes";

export class RiskCatalogueModel {
    ID: number;
    Name: string;
    definitionRiskCatalogue: string;

    riskname: string;
    Description: string;

    riskAreaId: number;
    eventCategoryId: number;

    applicableOrganizationIds: number[];

    subTypeIds: number[];

    riskAppetiteSelection: MatrixSelectionModel[];

    overrideRiskName: OverrideRiskFieldTypes;
    overrideRiskDescription: OverrideRiskFieldTypes;
    overrideRiskArea: OverrideRiskFieldTypes;
    overrideEventCategory: OverrideRiskFieldTypes;
    overrideSubTypes: OverrideRiskFieldTypes;
}
