export enum FormFieldWidgetType {
    RiskMatrix = 1
}

export class FormFieldWidgetTypeModel {
    type: FormFieldWidgetType;
    text: string;
    icon: string;
}

export class FormFieldWidgetTypeDescription {
    public static 1: FormFieldWidgetTypeModel = { type: FormFieldWidgetType.RiskMatrix, text: "Risk Matrix", icon: "fa-th" };
}

export const FormFieldWidgetTypeModels: FormFieldWidgetTypeModel[] = [
    FormFieldWidgetTypeDescription[FormFieldWidgetType.RiskMatrix]
];