import { Directive, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * This will debounce user input until it's not changed for "delayTime" amount of time
 * Usage:
 * (debounce-input-event)="onDebouncedInput($event)" [delayTime]="500"
 */
@Directive({
    selector: '[debounce-input-event]'
})
export class DebounceInputEventDirective implements AfterViewInit {
    /** Event emitter to subscribe to that is triggered after
     * the user hasn't changed input for "delayTime" amount of time */
    @Output('debounce-input-event') delayedInputEvent: EventEmitter<string> = new EventEmitter();
    /** Time in ms that user hasn't changed input */
    @Input() delayTime: number = 500;

    constructor(private inputElement: ElementRef) { }

    ngAfterViewInit(): void {
        // this is not avaible in subscribe
        const eventEmitter = this.delayedInputEvent;
        const nativeElement = <HTMLInputElement>this.inputElement.nativeElement;

        fromEvent(nativeElement, 'input').pipe(
            map(() => nativeElement.value),
            debounceTime(this.delayTime),
            distinctUntilChanged()
        ).subscribe(() => {
            eventEmitter.emit(nativeElement.value);
        });
    }
}