<div class="cerrix-slide-over" (click)="close()" *ngIf="showSlideOver">
    <div
        class="slide-over"
        [class.slide-out]="slideOutAnimation"
        style="animation-duration: {{ animationDuration + 's' }}"
        (click)="$event.stopPropagation()"
    >
        <div class="close-button" (click)="close()">
            <i class="fas fa-times-circle"></i>
        </div>
        <div class="title-area">
            <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
        </div>
        <div class="content-area">
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </div>
    </div>
</div>
