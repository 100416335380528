<div class="standing-data-container">
    <div class="standing-data-list">
        <div class="standing-data-title">{{ title }}</div>

        <div class="actions-container">
            <cerrix-button size="medium" [disabled]="addMode" (click)="onAdd()"
                ><mat-icon fontSet="material-symbols-outlined">add</mat-icon
                ><ng-container>Add</ng-container></cerrix-button
            >
        </div>

        <cerrix-input [formControl]="searchControl" placeholder="Search"></cerrix-input>

        <mat-selection-list
            [ngModel]="selectListValue"
            [multiple]="false"
            [hideSingleSelectionIndicator]="true"
            cdkDropList
            class="sd-drop-list"
            [cdkDropListDisabled]="!!this.searchControl.value"
            (cdkDropListDropped)="onOrderChanged($event)"
            (selectionChange)="onSelectionChanged($event)"
        >
            <mat-list-option
                class="sd-drop-list-item"
                *ngFor="let item of filteredStandingData"
                cdkDrag
                [value]="item.id"
            >
                <div class="drop-item-content">
                    @if(item.color){
                    <span class="color-badge" [style.backgroundColor]="item.color"></span>
                    }
                    <div class="drop-item-text">{{ item.name }}</div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div [hidden]="!selectListValue.length && !addMode" class="standing-data-edit">
        <ng-container #editComponentContainer></ng-container>
    </div>

    <div *ngIf="!selectListValue.length && !addMode" class="no-selection">
        No item selected to edit.
    </div>
</div>
