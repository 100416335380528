import { Component, Input, SimpleChanges } from "@angular/core";
import { FinalConclusion } from "./models/final-conclusion.model";

@Component({
    selector: "eff-final-conclusion-box",
    templateUrl: "./eff-final-conclusion-box.component.html",
    styleUrls: ["./eff-final-conclusion-box.component.scss"],
})
export class EffFinalConclusionBoxComponent {
    @Input() finalConclusion: FinalConclusion = new FinalConclusion();
    @Input() isEditMode: boolean = false;

    initials: string;

    ngOnInit() {
        this.initials = this.getInitials(this.finalConclusion.author);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["finalConclusion"]) {
            this.initials = this.getInitials(this.finalConclusion.author);
        }
    }

    getInitials(name) {
        if (!name) {
            return;
        }
        var names = name.split(", ");
        if (names.length == 0) {
            return;
        }

        var initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
}
