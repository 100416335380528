<div *ngIf="field.FieldType === types.Dropdown || field.FieldType === types.Checkbox">
    <div class="row mb-3" *ngIf="field.FieldType === types.Dropdown">
        <div class="col-6">
            <mat-checkbox
                color="primary"
                id="fieldMultiselect{{ field.ID }}"
                [(ngModel)]="optionSettings.multiSelect"
                (change)="updateAnswer()"
                [disabled]="multiselectLocked"
                >Multiselect</mat-checkbox
            >
        </div>
        <div class="col-6">
            <mat-checkbox
                color="primary"
                id="dropdownRequired{{ field.ID }}"
                [(ngModel)]="field.Required"
                (change)="updateAnswer()"
                *ngIf="!optionSettings.multiSelect"
            >
                Field required
            </mat-checkbox>
        </div>
    </div>
    <div class="row"></div>
    <div class="row" *ngIf="field.FieldType === types.Checkbox || optionSettings.multiSelect">
        <div class="input-group col-6">
            <div class="input-group-prepend">
                <span class="input-group-text" id="minimumSelection-{{ field.ID }}"
                    >Minimum selectable options</span
                >
            </div>
            <input
                type="number"
                class="form-control"
                id="minimumSelection-{{ field.ID }}"
                [(ngModel)]="optionSettings.minimumSelection"
                (change)="updateAnswer()"
                [readonly]="multiselectLocked"
            />
        </div>
        <div class="input-group col-6">
            <div class="input-group-prepend">
                <span class="input-group-text" id="maximumSelection-{{ field.ID }}"
                    >Maximum selectable options</span
                >
            </div>
            <input
                type="number"
                class="form-control"
                id="maximumSelection-{{ field.ID }}"
                [(ngModel)]="optionSettings.maximumSelection"
                (change)="updateAnswer()"
                [readonly]="multiselectLocked"
            />
        </div>
    </div>
    <hr />
</div>

<h6>{{ typeDescriptions[optionSettings.fieldType].text }} options</h6>
<div
    class="list-group list-group-flush"
    [sortablejs]="options"
    [sortablejsOptions]="sortingOptions"
>
    <div class="list-group-item" *ngFor="let option of options; let optionIndex = index">
        <div class="d-flex">
            <i class="fas fa-sort"></i>
            <cerrix-textfield
                cssClass="form-control form-control-sm ms-4 me-4 optionvalue-{{ field.ID }}"
                [(ngModel)]="option.Value"
                [id]="'option-' + field.ID + '-' + option.Key"
                (keyup)="updateAnswer()"
            ></cerrix-textfield>
            <i
                class="fas fa-trash"
                (click)="removeOption(optionIndex)"
                [hidden]="optionsLocked"
            ></i>
        </div>
    </div>
</div>

<div
    class="mt-3 text-center"
    (click)="addOption()"
    [hidden]="optionsLocked"
    style="cursor: pointer"
>
    <i class="fas fa-plus"></i>
</div>
