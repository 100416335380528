import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ModuleType } from "@enums/ModuleType";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { MoiModel } from "@models/moi/MoiModel";
import { MoiPermissionModel } from "@models/moi/MoiPermissionModel";

@Component({
    selector: "moi-detail-main",
    templateUrl: "./moi-detail-main.component.html",
    styleUrls: ["./moi-detail-main.component.scss"],
})
export class MoiDetailMainComponent implements OnInit {
    @Output() workflowChanged = new EventEmitter<any>();

    @Input() moiModel: MoiModel;
    @Input() permissions: MoiPermissionModel;
    @Input() parentSummary: any;
    @Input() sd: any = {};
    @Input() parentForm: FormGroup;

    // Make enums useable in the front-end
    MoiTypes = MoiTypes;
    ModuleType = ModuleType.BIM;

    validReviewers: any[];
    validResponsibles: any[];
    validDelegates: any[];

    constructor() {}

    ngOnInit() {
        this.validReviewers = this.validUsers(
            this.moiModel.Reviewers,
            this.sd.Users,
            this.sd.Reviewers
        );
        this.validResponsibles = this.validUsers(
            this.moiModel.Responsibles,
            this.sd.Users,
            this.sd.Responsibles
        );
        this.validDelegates = this.validUsers(
            this.moiModel.Delegates,
            this.sd.Users,
            this.sd.Delegates
        );
    }

    validUsers(selectedUsers: number[], allUsers: any[], otherUsers): any[] {
        const result = otherUsers;
        for (let i = 0; i < allUsers.length; i++) {
            for (let j = 0; j < selectedUsers.length; j++) {
                if (!allUsers[i].Deleted && allUsers[i].Id === selectedUsers[j]) {
                    // user not deleted, but role no longer valid, insert ** in front
                    allUsers[i].Name = "** " + allUsers[i].Name;
                    result.push(allUsers[i]);
                    break;
                }
                if (allUsers[i].Deleted && allUsers[i].Id === selectedUsers[j]) {
                    // user deleted, insert * in front
                    allUsers[i].Name = "* " + allUsers[i].Name;
                    result.push(allUsers[i]);
                    break;
                }
            }
        }

        return result.distinct();
    }
}
