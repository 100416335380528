import { Observable } from "rxjs";
import { SelectOptionModel } from "@cerrix/components";

export abstract class BaseDocumentsService {
    abstract getDocumentTypes(): Observable<SelectOptionModel[]>;
    abstract getDocumentIcon(fileName: string): string;
    abstract getDocumentConfig(): Observable<{
        maxUploadSizeInMb: number;
        allowedFileExtensions: string;
    }>;
    abstract filterAllowedFiles(files: File[] | FileList, allowedExtensions: string): File[];
    abstract validateUploadLimit(files: File[], maxUploadSizeInMb: number): boolean;
    abstract openDocumentViewer(documentId: string, documentName: string, pdfSrc?: string): void;
}
