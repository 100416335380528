import { ModuleConfig } from "@app/process-editor/Interfaces/ModuleConfig";
import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { mxgraph } from "mxgraph-factory";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { IModule } from "@models/processeditor/DiagramData";
import { Observable, from } from "rxjs";
import { ModuleData } from "@app/process-editor/models/ModuleData";
import { GraphEditor } from "@app/process-editor/grapheditor";
import { IModuleLoader } from "@app/process-editor/Interfaces/IModuleLoader";

export abstract class BaseShapeProperty implements IModuleLoader {
    moduleConfig: ModuleConfig;

    abstract load(data: ModuleData): void;

    abstract open(module: IModule): void;
    abstract openNew(): void;
    abstract isReadOnly(): boolean;
    abstract getObservable(): Observable<IModule[]>;
    abstract setObservable(): void;
    abstract getItemEnum(): ItemEnum;

    loadModuleConfig(graph: GraphEditor, cell: mxgraph.mxCell) {
        const itemEnum = this.getItemEnum();
        const name = ItemEnum.getNamePlural(itemEnum);
        if (this.moduleConfig) {
            const data = graph.getConnectedItems(cell);

            this.moduleConfig.dataMethod = () =>
                from(
                    this.getUnlinked(data).then((linkedData) => {
                        this.moduleConfig.linkData.unlinked = linkedData;
                        return data;
                    })
                );

            this.moduleConfig.onLinkedDataChange = (linkData) =>
                this.onLinkDataChange(graph, linkData, cell.id);

            if (this.moduleConfig.reload) {
                this.moduleConfig.reload();
            }
        } else {
            this.createModuleConfig(name, graph, cell, itemEnum);
        }
    }

    async onSave(objectGuid: string, graph: GraphEditor, selectedCell: mxgraph.mxCell) {
        this.setObservable();
        let newunlinked = await this.getUnlinked(this.moduleConfig.data);

        const foundrow = newunlinked.find((d) => d.guid === objectGuid);
        if (foundrow) {
            const foundIndex = newunlinked.indexOf(foundrow);
            newunlinked.splice(foundIndex, 1);
            this.moduleConfig.data.push(foundrow);
            this.onLinkDataChange(graph, this.moduleConfig.data, selectedCell.id);
            this.moduleConfig.reload();
        }

        this.moduleConfig.linkData.unlinked = newunlinked;
    }

    private async createModuleConfig(
        name: string,
        graph: GraphEditor,
        cell: mxgraph.mxCell,
        itemEnum: ItemEnum
    ) {
        const data = graph.getConnectedItems(cell);

        const config: ModuleConfig = {
            name: name,
            limitViewTo: 5,
            allowAdd: !this.isReadOnly(),
            allowLink: !this.isReadOnly(),
            allowEdit: !this.isReadOnly(),
            allowDelete: !this.isReadOnly(),
            hideRefreshButton: true,
            idProp: "guid",
            fields: [
                {
                    prettyName: "Identifier",
                    fieldName: "identifier",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Name",
                    fieldName: "name",
                    fieldType: GenericListFieldType.Text,
                },
            ],
            linkData: {
                typename: name,
                displayValueProp: "label",
                unlinked: null,
            },
            editOverride: (row) => this.open(row),
            addOverride: () => this.openNew(),
            delete: (row) => this.onDelete(graph, row),
            onLinkedDataChange: (linkData) => this.onLinkDataChange(graph, linkData, cell.id),
            moduleType: itemEnum,
        };

        config.dataMethod = () =>
            from(
                this.getUnlinked(data).then((linkedData) => {
                    config.linkData.unlinked = linkedData;
                    return data;
                })
            );

        this.moduleConfig = config;
    }

    private async getUnlinked(data: IModule[]) {
        const allData = await this.getObservable().toPromise();
        return allData.filter((item) => !data.some((d) => d.identifier === item.identifier));
    }

    private onLinkDataChange(graph: GraphEditor, linkData: IModule[], selectedCellID: string) {
        linkData.forEach((ld) => (ld.cellId = selectedCellID));
        const modules = graph.getDiagramDataModule(this.getItemEnum());
        const newData = modules.filter((x) => x.cellId !== selectedCellID).addRange(linkData);
        modules.clear();
        Object.assign(modules, newData);
        graph.refresh();
    }

    private async onDelete(graph: GraphEditor, row: IModule): Promise<boolean> {
        const modules = graph.getDiagramDataModule(this.getItemEnum());
        const module = modules.find((x) => x.guid === row.guid);
        if (module) {
            const index = modules.indexOf(module);
            modules.splice(index, 1);
            graph.refresh();

            this.loadModuleConfig(graph, graph.model.getCell(row.cellId));

            return true;
        }
        return false;
    }
}
