import { Pipe, PipeTransform } from "@angular/core";
import { TabMenuItem } from "./../../models/generic/TabModels/TabMenuItem";

@Pipe({
    name: "tabchildselected",
    pure: true,
})
export class TabMenuItemChildSelectedPipe implements PipeTransform {
    transform(item: TabMenuItem, activeID: string): any {
        if (!item || !item.children || item.children.length === 0) {
            return false;
        }

        return item.children.some((c) => c.identifier === activeID);
    }
}
