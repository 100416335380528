import { Component, ElementRef, ViewChild } from "@angular/core";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { FindingReportDataService } from "@app/findingreport/services";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";

@Component({
    selector: "finding-report-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class FindingReportWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private ds: FindingReportDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            "organizationId";
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const hasReportFilter = filters[this.filterKeys.FindingsReport.ReportType];
        if (!hasReportFilter) {
            this.config.noData = true;
            this.config.noDataExplanation = "Report type is required!";
            return null;
        }

        const data = await this.ds.getWidgetInfo(filters).toPromise();

        this.config.noData = !data || data.length === 0;
        if (this.config.noData) {
            return null;
        }

        const config = this.getBaseChartConfig(null, [
            <any>{
                colorByPoint: true,
                data,
            },
        ]);

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        config.legend = { enabled: false };
        config.xAxis = {
            type: "category",
        };

        config.yAxis = {
            min: 0,
            allowDecimals: false,
            title: {
                text: "Number of finding reports",
            },
        };

        return config;
    }

    protected async openWorkspace(ev) {
        const searchKeywords: SearchKeyword[] = await this.getSearchKeywords(ev.point.name);

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.ImOverview, preset);
    }

    private async getSearchKeywords(score: string) {
        const searchKeywords: SearchKeyword[] = [];

        const reportId = +this.getFilter(this.filterKeys.FindingsReport.ReportType);
        if (reportId > 0) {
            const reportTypeName = (await this.ds.getReportTypes().toPromise()).find(
                (x) => x.value === reportId
            ).label;

            searchKeywords.push(
                this.getSearchKeyword("reportType", reportTypeName, ComparisonType.Contains)
            );
        }

        searchKeywords.push(this.getSearchKeyword("overallScore", score, ComparisonType.Contains));

        return searchKeywords;
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.ImOverview, preset);
    }
}
