<div class="panel">
    <div class="title">
        <div class="header">Available Widgets</div>
        <small class="text-muted">Drag and drop a widget to add it to your dashboard.</small>
    </div>
    <div class="search-bar">
        <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Filter / search widgets"
            (debounce-input-event)="filter($event)"
            inputTooltip
        />
    </div>
    <div class="available-widgets">
        <div
            class="widget"
            *ngFor="let widget of filteredMarketWidgets"
            draggable="true"
            (dragstart)="dragStartHandler($event, widget)"
        >
            <div class="widget-image">
                <i [class]="widget.icon + ' fa-fw'" *ngIf="widget.icon"></i>
            </div>
            <div class="widget-info">
                <div class="widget-title">{{ widget.title }}</div>
                <div class="widget-description">{{ widget.description }}</div>
            </div>
        </div>
    </div>
</div>
