import { Injectable } from "@angular/core";
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import { AppConfigService } from "@app/shared/services/app-config.service";
import { AuthenticationConfiguration } from "./authentication-configuration";

@Injectable({
    providedIn: "root",
})
export class JwtExternalAuthenticationService {
    private userManager: UserManager;
    private userPromise: Promise<User>;
    private user: User;
    private authenticationConfig: AuthenticationConfiguration;

    constructor(appConfigService: AppConfigService) {
        this.authenticationConfig = appConfigService.authenticationConfig;
        if (!this.authenticationConfig.jwtExternalLoginEnabled) {
            return;
        }

        const baseUrl = window.location.origin;

        const config: UserManagerSettings = {
            authority: this.authenticationConfig.jwtExternalAuthority,
            client_id: this.authenticationConfig.jwtExternalClientId,
            metadataUrl: this.authenticationConfig.jwtExternalMetadataUrl,
            redirect_uri: `${baseUrl}/bundles/assets/oidc-login-redirect.html`,
            silent_redirect_uri: `${baseUrl}/bundles/assets/oidc-login-redirect.html`,
            scope: `openid profile`,
            response_type: "id_token token",
            post_logout_redirect_uri: baseUrl,
            extraQueryParams: { resource: this.authenticationConfig.jwtExternalClientId },
            loadUserInfo: false,
            prompt: "select_account",
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        };

        this.userManager = new UserManager(config);
        this.userPromise = this.loadUser();

        this.userManager.events.addUserLoaded((args) => {
            this.loadUser();
        });
    }

    public awaitInitialization(): Promise<any> {
        return this.userPromise;
    }

    public login(): Promise<User> {
        return this.userManager.signinPopup();
    }

    public loginSilent(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public logout(): Promise<any> {
        return this.userManager.signoutRedirect();
    }

    public removeUser(): Promise<void> {
        return this.userManager.removeUser();
    }

    public getExternalAuthenticationToken(): string {
        if (!this.user) {
            return null;
        }

        if (this.authenticationConfig.jwtUseIdToken) {
            return this.user.id_token;
        }

        return this.user.access_token;
    }

    private async loadUser(): Promise<User> {
        return this.userManager.getUser().then((user) => {
            if (user && !user.expired) {
                this.user = user;
            }

            return user;
        });
    }
}
