<div
    class="detail-page-card"
    [attr.data-uiid]="config.uiid"
    *ngIf="(ready && tableData && tableData.length > 0) || !config.hideEmptyResult"
>
    <div class="detail-page-card-title" *ngIf="!config.hideHeader">
        {{ config.name }}
    </div>
    <div class="detail-page-card-actions">
        <div class="left-side">
            <button
                [attr.data-uiid]="config.uiidAdd"
                class="btn btn-raised"
                (click)="addRow()"
                *ngIf="config.allowAdd && !config.hideAddButton"
            >
                <i class="fas fa-plus-circle"></i>
                <span title="{{ config.addButtonTooltip }}">Add</span>
            </button>
            <button
                [attr.data-uiid]="config.uiidDelete"
                class="btn btn-raised red"
                (click)="deleteRow(this.activeRow)"
                *ngIf="config.allowDelete && !config.hideDeleteButton"
                [disabled]="!activeRow"
            >
                <i class="fas fa-trash"></i>
                <span>Delete</span>
            </button>
            <button
                class="btn btn-raised blue"
                (click)="openLinkModal()"
                *ngIf="config.allowLink && !config.hideLinkButton"
            >
                <i class="fas fa-link"></i>
                <span>Link</span>
            </button>

            <ng-container *ngIf="config.extraButtons">
                <button
                    *ngFor="let btn of config.extraButtons"
                    [attr.data-uiid]="btn.uiid"
                    type="button"
                    [ngClass]="btn.class"
                    (click)="btn.action(this.activeRow)"
                    [hidden]="!btn.show"
                >
                    <cerrix-icon-viewer
                        [icon]="btn.icon"
                        [defaultColor]="btn.iconColor"
                        *ngIf="btn.icon"
                    ></cerrix-icon-viewer>
                    <span class="d-none d-lg-inline ms-2">{{ btn.text }}</span>
                </button>
            </ng-container>
        </div>
        <div class="right-side">
            <button
                class="btn btn-raised green"
                (click)="loadPage()"
                *ngIf="config.dataMethod && !config.hideRefreshButton"
            >
                <i class="fas fa-sync"></i>
                <span>Reload</span>
            </button>
            <input
                type="text"
                class="form-control"
                placeholder="Quick search / filter"
                (debounce-input-event)="config.onSearch($event)"
                inputTooltip
                *ngIf="config.showToolbarSearch"
            />
        </div>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm" *ngIf="!ready">
        <div class="fancy-loader submodule"></div>
    </div>

    <div
        class="detail-page-card-body padded"
        *ngIf="ready && (!tableData || tableData.length == 0) && !config.hideEmptyResult"
    >
        <small *ngIf="config.emptyMessage">{{ config.emptyMessage }}</small>
        <small *ngIf="!config.emptyMessage">No {{ config.name | lowercase }} available.</small>
        <small *ngIf="!config.emptyMessage && config.allowAdd">
            &nbsp;<b>Click add</b> to create a new one.
        </small>
    </div>

    <div
        class="detail-page-card-body overflow-auto"
        *ngIf="scopedTableData && scopedTableData.length > 0"
    >
        <cerrix-table
            [config]="config"
            [data]="scopedTableData"
            [idProp]="idProp"
            [activeRow]="activeRow"
            (activeRowChange)="activeRow = $event"
            (rowClick)="singleClick(activeRow)"
            (rowDblClick)="editRow(activeRow)"
            (sortingChanged)="sortingChanged()"
            *ngIf="tableData"
            [rendererConfig]="config.rendererConfig"
            [NoPadding]="true"
            [NoBorder]="true"
            [Centered]="true"
        ></cerrix-table>

        <div
            class="custom-pagination"
            (click)="toggleScopedTableData()"
            *ngIf="scopedToggleAllowed"
        >
            View
            {{
                scopedTableExpanded
                    ? "last " + this.config.limitViewTo
                    : "all " + this.config._visibleRowCount
            }}
        </div>
    </div>
</div>

<ng-template #linkable></ng-template>

<generic-form-editor
    [config]="config"
    [additionalData]="additionalData"
    (change)="applyRowChange($event)"
    #editorCmp
>
</generic-form-editor>
