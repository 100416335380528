import { DevPowerBIMaintenanceModel } from "./../shared/dev-powerbi-model";
import { Configuration } from "./../../app.constants";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { firstValueFrom } from "rxjs";

@Injectable()
export class DevPowerBIService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "devpowerbi");
    }

    isEnabled() {
        return this.get<boolean>("/isenabled").toPromise();
    }

    getReports() {
        return this.get<DevPowerBIMaintenanceModel>().toPromise();
    }

    updateReport(model: any) {
        return this.post<number>(model).toPromise();
    }

    deleteReport(id: number) {
        return this.delete("/" + id).toPromise();
    }

    createTemplates() {
        return this.post(null, "/templates/create");
    }
}
