import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { RoleAssignComponent } from "@app/user/user-maintenance/role-assign/role-assign.component";
import { UserDetailModel } from "@app/user/user-maintenance/shared/user-detail-model";
import { UserModuleRoleModel } from "@app/user/user-maintenance/shared/user-module-roles-model";
import { UserStandingdataModel } from "@app/user/user-maintenance/shared/user-standingdata-model";
import { UserMaintenancePermissionModel } from "@models/permissions/UserMaintenancePermissionModel";

@Component({
    selector: "user-roles",
    templateUrl: "./user-roles.component.html",
    styleUrls: ["./user-roles.component.scss"],
})
export class UserRolesComponent implements OnInit {
    @Input() user: UserDetailModel;
    @Input() sd: UserStandingdataModel;
    @Input() permissions: UserMaintenancePermissionModel;
    @ViewChild("roleAssign") roleAssign: RoleAssignComponent;

    constructor() {}

    ngOnInit() {
        this.sd.roleGroupPromise.then(() => this.roleGroupChanged());
    }

    roleGroupChanged() {
        const roleGroups = this.user.roleGroups
            ? this.sd.roleGroups.filter((rg) => this.user.roleGroups.indexOf(rg.ID) >= 0)
            : [];

        this.user.moduleRoles.forEach((moduleRole) => {
            moduleRole.roles.forEach((role) => {
                const matchedRoleGroups = roleGroups
                    .filter((rg) => rg.selectedRoles.some((r) => r === role.roleID))
                    .map((rg) => rg.name);
                if (matchedRoleGroups && matchedRoleGroups.length > 0) {
                    role.checked = true;
                    role.disabled = true;

                    const maxShownGroups = 3;
                    if (matchedRoleGroups.length > maxShownGroups) {
                        const otherCount = matchedRoleGroups.length - maxShownGroups;
                        role.inheritedFrom = matchedRoleGroups
                            .slice(0, maxShownGroups)
                            .map((rg) => `[${rg}]`)
                            .join(" ");
                        role.inheritedFrom += ` and ${otherCount} others...`;
                    } else {
                        role.inheritedFrom = matchedRoleGroups.map((rg) => `[${rg}]`).join(" ");
                    }
                } else {
                    // because the role is not disabled right now, we need to check the old value.
                    // if it was checked but not disabled, keep it checked.
                    // if it was checked but also disabled, we remove the checked.
                    role.checked = role.checked && !role.disabled;
                    role.disabled = false;

                    if (role.inheritedFrom) {
                        role.inheritedFrom = null;
                    }
                }
            });
        });
    }

    openRightOverview(role: UserModuleRoleModel) {
        this.roleAssign.openRightOverview(role);
    }
}
