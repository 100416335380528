<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar flex-action-bar" *ngIf="!tab.showLoader && model">
    <div>
        <button
            class="btn btn-cerrix"
            (click)="save()"
            [disabled]="
                !mainComponent ||
                !mainComponent.simpleTestingGroup ||
                !mainComponent.simpleTestingGroup.valid
            "
            *ngIf="permissions.CanEdit"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save {{ typeDesc }}</span>
        </button>

        <button
            class="btn btn-cerrix"
            (click)="uploadEvidence()"
            *ngIf="!permissions.CanEdit && permissions.CanUploadEvidence"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Upload evidence</span>
        </button>

        <span class="date-edited">
            <span *ngIf="model.Version > 0"
                >Version <b>{{ model.Version }}</b> saved on
                <b>{{ model.DateEdited | dateTimeFormat }}</b></span
            >
            <span *ngIf="model.Version == 0">Initial version of {{ typeDesc }}</span>
        </span>
    </div>

    <div class="workflow-area" *ngIf="model.Workflow">
        <span class="workflow-description">
            Workflow Status | <b>{{ model.Workflow.Status }}</b
            ><i
                class="ms-2 fas fa-question-circle"
                *ngIf="model.Workflow.Tooltip"
                [matTooltip]="model.Workflow.Tooltip"
            ></i>
        </span>
        <button
            (click)="doAction(btn)"
            class="btn btn-{{ btn.ButtonClass ? btn.ButtonClass : 'dark' }}"
            *ngFor="let btn of model.Workflow.Buttons"
            [matTooltip]="btn.Tooltip"
        >
            <i [class]="btn.Icon + ' me-2'" *ngIf="btn.Icon"></i>
            {{ btn.Text }}
        </button>
    </div>
</div>

<div
    menuSystem
    [cerrixTab]="tab"
    *ngIf="!tab.showLoader && model"
    (change)="handleMenuItemClick($event)"
>
    <div class="control-screens" [attr.name]="typeDesc" menuicon="fas fa-book" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <simple-testing-main
                [model]="model"
                [type]="type"
                [typeDesc]="typeDesc"
                [testScoreConfig]="testScoreConfig"
                [permissions]="permissions"
                #mainComponent
            >
            </simple-testing-main>
        </div>

        <div
            name="Evidence"
            menuItemId="evidenceTab"
            *ngIf="model.Version > 0 && permissions.CanViewEvidence"
            menuicon="fas fa-vials"
            menusystemitem
        >
            <document-manager-old
                [(Documents)]="model.Documents"
                Label="Uploader evidence"
                [AllowAdd]="permissions.CanUploadEvidence"
                [AllowEdit]="permissions.CanUploadEvidence"
                [AllowDelete]="permissions.CanUploadEvidence"
                [DocumentTypeTargets]="documentTypeTargets"
                *ngIf="model && model.UploaderEvidenceLoaded"
                #evidenceComponent
            ></document-manager-old>

            <div class="detail-page-card" *ngIf="!model.UploaderEvidenceLoaded">
                <div class="detail-page-card-title">Uploader evidence</div>
                <div class="detail-page-card-body padded cerrix-control-sm">
                    <div class="fancy-loader submodule"></div>
                </div>
            </div>
        </div>

        <div
            name="Hyperlinks"
            [attr.menuItemID]="hyperlinksMenuItemId"
            menuicon="fas fa-external-link-alt"
            menusystemitem
            *ngIf="model.Version > 0"
        >
            <div *ngIf="!model.Hyperlinks">
                <div class="fancy-loader"></div>
            </div>
            <div *ngIf="model.Hyperlinks">
                <hyperlink-manager
                    [(Hyperlinks)]="model.Hyperlinks"
                    (HyperlinksChanged)="checkHyperlinksDirty($event)"
                    [TargetModule]="targetModule"
                ></hyperlink-manager>
            </div>
        </div>

        <div name="History" menuItemId="historyTab" menuicon="fas fa-history" menusystemitem>
            <app-history-overview
                [historyDataService]="ds"
                [Id]="id"
                [ExtraParam]="type"
                label="History"
                #historyOverviewComponent
            >
            </app-history-overview>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-events-name" class="modal-title pull-left">
                    Test detail of version {{ testPlanByScoreVersion }}
                </h4>
                <button
                    type="button"
                    class="btn-close pull-right"
                    aria-label="Close"
                    (click)="modal.hide()"
                ></button>
            </div>
            <div class="modal-body">
                <simple-testing-testplan
                    [guid]="id"
                    [type]="type"
                    #testplanByVersion
                ></simple-testing-testplan>
            </div>
        </div>
    </div>
</div>
