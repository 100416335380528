<eff-popup-header [title]="data.title" [dialogRef]="dialogRef"></eff-popup-header>
<div class="dialog-body">
    <eff-final-conclusion-box
        [isEditMode]="true"
        [finalConclusion]="finalConclusion"
    ></eff-final-conclusion-box>
</div>

<div class="dialog-footer">
    <eff-button
        [text]="data.saveButtonLabel"
        [disabled]="!isButtonEnabled()"
        (onClick)="onFinalConclusion()"
    ></eff-button>
</div>
