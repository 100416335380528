import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { AssessmentLinkedOverviewComponent } from "./components/linked-overview/assessment-linked-overview.component";

@NgModule({
    declarations: [AssessmentLinkedOverviewComponent],
    exports: [AssessmentLinkedOverviewComponent],
    imports: [CommonModule, SharedModule]
})
export class AssessmentSharedModule {}
