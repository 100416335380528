<div class="row no-gutters">
    <div class="input-group mb-3">
        <cerrix-textfield
            identifier="urlInput"
            [(ngModel)]="value"
            [disabled]="disabled"
            [required]="required"
            [dataUiid]="dataUiid"
        ></cerrix-textfield>
        <div class="input-group-append">
            <button class="btn no-border" (click)="openLink()">
                <i class="fas fa-external-link"></i>
            </button>
        </div>
    </div>
</div>
