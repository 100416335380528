import { Component, Input } from "@angular/core";

@Component({
    selector: "eff-icon",
    templateUrl: "./eff-icon.component.html",
    styleUrls: ["./eff-icon.component.scss"],
})
export class EffIconComponent {
    @Input() icon: string;
    @Input() size: string = "medium-size";
}
