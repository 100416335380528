import {
    Component,
    TemplateRef,
    ViewChild,
    EventEmitter,
    Output,
    Input,
    OnInit,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import {
    GenericListFieldType,
    GenericListField,
} from "@app/shared/models/GenericList/GenericListField";
import { getDifferences, isDirty, tryParseJson } from "@methods/CommonMethods";
import { GenericFormEditor } from "@app/shared/interfaces/generic-form-editor";
import { DocumentModel } from "@models/documents/documentmodel";
import { GenericEditWindowButton } from "@app/shared/models/GenericList/GenericManagerConfig";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { GenericManagerHelper } from "@app/shared/helpers/generic-manager-helper";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "generic-form-editor",
    templateUrl: "./generic-form-editor.component.html",
    styleUrls: ["./generic-form-editor.component.scss"],
})
export class GenericFormEditorComponent implements OnInit, GenericFormEditor {
    @Input() config: GenericListConfig;
    @Input() row: any;
    @Input() additionalData = {};

    @Output() change = new EventEmitter<any>();

    @ViewChild("template", { static: true }) template: TemplateRef<any>;
    modalRef: BsModalRef;
    fieldType = GenericListFieldType;

    editEnabled = false;
    FieldsToEdit: GenericListField[];

    private _rowCopy: any = {};

    constructor(private modalService: BsModalService, private sdService: StandingdataDataService, private _toastr: ToastrService,) {}

    ngOnInit() {
        this.FieldsToEdit = this.config.fields.filter((f) => !f.hideInEdit);
    }

    edit(row: any) {
        this.row = row;
        this.config.fields.forEach((field) => {
            if (field.getDataMethodByRow) {
                this.additionalData[field.fieldName] = null;
                this.additionalData[field.fieldName + "Loading"] = true;
                field.getDataMethodByRow(row, this.sdService).subscribe((d) => {
                    const data = tryParseJson(d);
                    if (data) {
                        this.additionalData[field.fieldName] = data;
                    } else {
                        this.additionalData[field.fieldName] = d;
                    }

                    this.additionalData[field.fieldName + "Loading"] = false;
                });
            }
        });

        this.modalRef = this.modalService.show(this.template, {
            animated: true,
            class: this.config.customModalClass ? this.config.customModalClass : "modal-lg",
            ignoreBackdropClick: true,
        });
    }

    apply() {
        const tempCopy = JSON.parse(JSON.stringify(this.row));
        this.fixDocumentFiles(this.row, tempCopy);

        const validations = GenericManagerHelper.validateGenericFields(
            tempCopy,
            this.FieldsToEdit,
            true
        );

        if (validations == null || validations.length === 0) {
            this.close();
            this.change.emit(tempCopy);

            return;
        }

        // show validation errors
        if (validations.length != 0) {
                const validationMsg = validations.map((validation) => "- " + validation).join("\n");
                this._toastr.warning(validationMsg, "Save failed.");

                return false;
            }
    }

    fieldValueChanged() {
        if (!this.row) {
            return;
        }

        if (this.config.rowValueChanged && this._rowCopy && isDirty(this._rowCopy, this.row)) {
            const differences = getDifferences(this._rowCopy, this.row);
            this.config.rowValueChanged(this.row, differences);
        }

        this._rowCopy = JSON.parse(JSON.stringify(this.row));
    }

    fixDocumentFiles(original: Object, copy: Object) {
        // File instance gets lost with JSON.parse(JSON.stringify(this.row))
        // This File instance is required when posting to server
        // This is a QUICK HOTFIX with limited impact
        // Create own clone function with unit tests for proper fix
        this.config.fields.forEach((f) => {
            if (f.fieldType === GenericListFieldType.Documents) {
                const documents = original[f.fieldName] as DocumentModel[];
                if (documents) {
                    for (let i = 0; i < documents.length; i++) {
                        const document = documents[i];
                        if (document.File) {
                            copy[f.fieldName][i].File = document.File;
                        }
                    }
                }
            }
        });
    }

    close() {
        this.modalRef.hide();
        this.row = null;
    }

    async customButtonClicked(btn: GenericEditWindowButton) {
        const closeWindow = await btn.clickEvent(this.row);
        if (closeWindow) {
            this.close();
        }
    }
}
