<div class="modal-header">
    <h5 class="modal-title">{{ title ? title : "Comment for this recommendation" }}</h5>
</div>
<div class="modal-body">
    <ul class="nav nav-tabs">
        <li class="nav-item active">
            <a class="nav-link active" href="#commentDetail" data-toggle="tab">Comment</a>
        </li>
    </ul>

    <div class="tab-content">
        <div class="tab-pane active" id="commentDetail" *ngIf="failureTypes">
            <form [formGroup]="commentForm">
                <div class="form-group row" *ngIf="items">
                    <label for="batchControls" class="col-3 col-form-label">Select</label>
                    <div class="col-9">
                        <ng-select
                            id="batchControls"
                            name="batchControls"
                            [items]="items"
                            bindLabel="name"
                            formControlName="batchControls"
                            bindValue="id"
                            [closeOnSelect]="false"
                            [multiple]="true"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="failureTypes">
                    <label for="failureTypeSelect" class="col-3 col-form-label">Failure type</label>
                    <div class="col-9">
                        <ng-select
                            id="failureTypeSelect"
                            name="failureTypeSelect"
                            [items]="failureTypes"
                            formControlName="failureTypeId"
                            bindLabel="Name"
                            bindValue="ID"
                            [closeOnSelect]="true"
                            [multiple]="false"
                            (change)="failureTypeChanged($event)"
                        >
                        </ng-select>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="commentInput" class="col-3 col-form-label">Comment</label>
                    <div class="col-9">
                        <cerrix-textarea
                            errorLabel="Comment"
                            identifier="commentInput"
                            [rows]="10"
                            formControlName="comment"
                        ></cerrix-textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="!commentForm.valid"
        (click)="saveClick()"
    >
        Apply
    </button>
    <button type="button" class="btn btn-secondary" (click)="closeClick()">Close</button>
</div>
