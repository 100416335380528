import { Component, Input } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";

@Component({
    selector: "cerrix-loader",
    templateUrl: "./cerrix-loader.component.html",
    styleUrls: ["./cerrix-loader.component.scss"],
})
export class CerrixLoaderComponent {
    @Input() cerrixTab: TabModel;
    @Input() showLoader = false;
    @Input() customMessage: string = "";
}
