import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from "@angular/core";
import { CerrixSelectTreeComponent } from "@app/shared/cerrix-select-tree/cerrix-select-tree.component";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { FilterType } from "@enums/FilterType";
import { ModuleType } from "@enums/ModuleType";
import { StandingDataType } from "@enums/StandingDataType";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { FilterComponent } from "@enums/workspace/FilterComponent";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { KeyValuePair } from "@models/generic/KeyValuePair";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { Preset } from "@models/workspace/Preset";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { NgSelectComponent } from "@ng-select/ng-select";
import { EnumKeyValuePair } from "common/pipes/utils/EnumKeyValuePair";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { SortableOptions } from "sortablejs";
import { CerrixPromptService } from "./../../services/cerrix-prompt.service";
import { ListBoxComponent } from "@syncfusion/ej2-angular-dropdowns";
import { ModuleSubType } from "@enums/ModuleSubType";

@Component({
    selector: "workspace-advanced-workspace-config",
    templateUrl: "./workspace-advanced-config.component.html",
    styleUrls: ["./workspace-advanced-config.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class WorkspaceAdvancedConfigComponent implements AfterViewInit, AfterViewChecked {
    @Input() tabID: string;
    @Input() allHeaders: string[];
    @Input() activeHeaders: string[];
    @Input() headerLookup: any;
    @Input() activeFilters: SearchKeyword[];
    @Input() NumberProperties: string[] = [];
    @Input() DateProperties: string[] = [];
    @Input() filterConfig: FilterConfig[];
    @Input() moduleType: ModuleType;
    @Input() moduleSubType: ModuleSubType;
    @Input() showTableConfiguration = true;

    @ViewChild("activeListbox") activeListbox: ListBoxComponent;
    @ViewChild("hiddenListbox") hiddenListbox: ListBoxComponent;

    @Output() ApplyClick = new EventEmitter<Preset>();

    protected setfield = { text: "name" };

    // Advanced Filter Properties.
    allColumns: string[];
    shownHeaders: string[];
    hiddenHeaders: string[];
    shownHeaderData: { [key: string]: Object }[];
    hiddenHeaderData: { [key: string]: Object }[];

    configKeywords: SearchKeyword[] = [];
    configHeaders: string[];
    configComparisonTypes: KeyValuePair[] = [];

    configCompareType = 0;
    configColumn = "";
    configSearchValue: string;

    dataFilter: CerrixTreeItem[] | IdNameCombination[] = [];
    hasServerFilters = false;
    filterComponent = FilterComponent;
    filters: any[] = [];

    headerOptions: SortableOptions = {
        group: "header-group",
        draggable: ".draggable",
    };

    ngSelectHolderSelector = CerrixPromptService.ngSelectHolderSelector;
    filtersLoaded = false;

    @ViewChild("advancedConfiguration", { static: true }) advancedConfigurationTemplate: ElementRef;
    @ViewChildren("specificTree") specificTree: QueryList<CerrixSelectTreeComponent>;
    @ViewChildren("combobox") comboboxes: QueryList<NgSelectComponent>;

    constructor(
        private _filters: StandingdataDataService,
        private _toastr: ToastrService,
        private _enumKVP: EnumKeyValuePair,
        private _promptService: CerrixPromptService,
        private cd: ChangeDetectorRef
    ) {}

    ngAfterViewChecked() {
        this.cd.detectChanges();
    }

    ngAfterViewInit(): void {
        this.setComparisonTypes();
    }

    showConfig() {
        this.setupConfig();
        this._promptService
            .prompt({
                maxHeight: "800px",
                maxWidth: "1300px",
                data: {
                    title: "Advanced Configuration",

                    customTemplate: this.advancedConfigurationTemplate,
                    hideBodyPadding: true,

                    confirmButton: {
                        text: "Apply configuration",
                    },
                },
            })
            .toPromise()
            .then(() => this.applyConfiguration());

        // This is so the material tab will render the active tab correctly.
        setTimeout(() => {
            this.fillFilters();
        }, 100);
    }

    fillFilters() {
        if (this.activeFilters && this.activeFilters.any()) {
            const columns = Object.keys(this.filters);
            columns.forEach((column) => {
                const filter = this.filterConfig.find((x) => x.column === column);
                if (filter) {
                    switch (filter.component) {
                        case FilterComponent.ComboBox:
                            const cbComponent = this.comboboxes.find(
                                (cb) => cb.element.id === filter.identifier
                            ) as NgSelectComponent;
                            if (cbComponent) {
                                const activeFilter = this.activeFilters.find(
                                    (x) => x.col === column
                                );
                                if (activeFilter) {
                                    const selectedItem = cbComponent.itemsList.findItem(
                                        activeFilter.searchValue
                                    );
                                    cbComponent.select(selectedItem);
                                }
                            }
                            break;
                        case FilterComponent.MultiSelectTree:
                        case FilterComponent.SingleSelectTree:
                            const treeComponent = this.specificTree.find(
                                (sc) => sc.identifier === filter.identifier
                            ) as CerrixSelectTreeComponent;
                            if (treeComponent) {
                                const activeFilter = this.activeFilters.find(
                                    (x) => x.col === column
                                );
                                if (activeFilter) {
                                    treeComponent.updateValue(
                                        activeFilter.searchValue.split(";").map(Number)
                                    );
                                }
                            }
                            break;
                    }
                }
            });
        }
    }

    async setupConfig() {
        await this.loadFilters();
        this.sortColumns();

        this.configKeywords = JSON.parse(
            JSON.stringify(this.activeFilters.filter((x) => !x.specific))
        );

        this.filterConfig.forEach((x) => {
            let val = null;

            const kywrd = this.activeFilters.find(
                (f) => f.specific && (f.col === x.column || f.col == x.label)
            );
            if (kywrd) {
                const isTree =
                    x.component == FilterComponent.SingleSelectTree ||
                    x.component == FilterComponent.MultiSelectTree;

                if (isTree) {
                    val = kywrd.searchValue.split(";");
                    this.filters[x.column] = val;
                } else {
                    val = +kywrd.searchValue;
                    this.filters[x.column] = val;
                }
            } else {
                this.filters[x.column] = "";
            }
        });

        this.shownHeaders = [];
        this.activeHeaders.forEach((head) => {
            this.shownHeaders.push(head);
        });

        this.hiddenHeaders = [];
        this.allHeaders.forEach((head) => {
            if (this.shownHeaders.indexOf(head) < 0) {
                this.hiddenHeaders.push(head);
            }
        });

        this.setHeaderData();
    }

    setComparisonTypes() {
        const dateAndNumberTypes = [
            ComparisonType["Is equal to"],
            ComparisonType["Is not equal to"],
            ComparisonType["After/Greater than"],
            ComparisonType["Before/Less than"],
            ComparisonType["Equal or after/greater than"],
            ComparisonType["Equal or before/less than"],
        ];

        const stringTypes = [
            ComparisonType["Contains"],
            ComparisonType["Does not contain"],
            ComparisonType["Is equal to"],
            ComparisonType["Is not equal to"],
            ComparisonType["Begins with"],
            ComparisonType["Ends with"],
        ];

        if (this.configComparisonTypes.length > 0) {
            this.configComparisonTypes = [];
        }

        const isNumberCol = this.NumberProperties.indexOf(this.configColumn) >= 0;
        const isDateCol = this.DateProperties.indexOf(this.configColumn) >= 0;
        const types = this._enumKVP.transform(ComparisonType);
        if (isNumberCol || isDateCol) {
            types
                .filter((type) => dateAndNumberTypes.indexOf(type.key) >= 0)
                .forEach((type) => this.configComparisonTypes.push(type));
        } else {
            types
                .filter((type) => stringTypes.indexOf(type.key) >= 0)
                .forEach((type) => this.configComparisonTypes.push(type));
        }

        if (this.configComparisonTypes.every((type) => type.key !== this.configCompareType)) {
            this.configCompareType = this.configComparisonTypes[0].key;
        }
    }

    setTreeValue(column: string, value: number | number[]) {
        const filter = this.filterConfig.filter((x) => x.column === column)[0];
        if (filter) {
            if (!value) {
                this.filters[filter.column] = "";
            } else {
                if (filter.component === FilterComponent.MultiSelectTree) {
                    this.filters[filter.column] = value as number[];
                } else {
                    this.filters[filter.column] = value as number;
                }
            }
        }
    }

    setDropdownValue(column: string, value: IdNameCombination) {
        const filter = this.filterConfig.filter((x) => x.column === column)[0];
        if (filter) {
            if (!value) {
                this.filters[filter.column] = "";
            } else {
                this.filters[filter.column] = (value as IdNameCombination).ID;
            }
        }
    }

    applyConfiguration() {
        const searchKeywords = this.configKeywords.slice();
        this.filterConfig.forEach((fc) => {
            if (!this.filters[fc.column]) {
                return;
            }

            const filterValue = this.filters[fc.column];
            if (filterValue && filterValue.toString().length > 0) {
                const filter: SearchKeyword = {
                    searchValue:
                        fc.component == FilterComponent.MultiSelectTree
                            ? filterValue.join(";")
                            : filterValue,
                    col: fc.column,
                    specific: true,
                    compareType: ComparisonType["Is equal to"],
                    remoteSearch: fc.remoteFilter,
                };

                searchKeywords.push(filter);
            }
        });

        const preset = new Preset();
        preset.searchKeywords = searchKeywords;
        preset.columns = this.shownHeaders;

        this.ApplyClick.emit(preset);
    }

    addKywrd() {
        if (!this.configSearchValue || this.configSearchValue.trim() === "") {
            this._toastr.warning("", "Missing search value.");
            return;
        }

        const kywrd = new SearchKeyword();
        kywrd.col = this.configColumn;
        kywrd.searchValue = this.configSearchValue;
        kywrd.compareType = this.configCompareType;

        this.configKeywords.push(kywrd);

        this.configSearchValue = "";
    }

    editKywrd(kywrd: SearchKeyword) {
        this.configColumn = kywrd.col;
        this.configSearchValue = kywrd.searchValue;
        this.configCompareType = kywrd.compareType;

        this.removeKywrd(kywrd);
    }

    removeKywrd(kywrd) {
        const index = this.configKeywords.indexOf(kywrd);
        if (index >= 0) {
            this.configKeywords.splice(index, 1);
        }
    }

    async loadFilters(): Promise<void> {
        if (this.filtersLoaded) {
            return;
        }

        const processPromises = this.filterConfig.map(async (config, fIndex) => {
            this.filters[config.column] = "";
            let data;
            if (config.type === FilterType.Organization) {
                data = await this._filters.getOrganizations(this.moduleType).toPromise();
            } else if (config.type === FilterType.BusinessDimensions) {
                data = await this._filters
                    .getUserViewableBusinessDimensions(this.moduleType)
                    .toPromise();
            } else if (config.type === FilterType.FrameworkDimensions) {
                data = await this._filters
                    .getVisibleFrameworkDimensions(this.moduleType, this.moduleSubType)
                    .toPromise();
            } else if (config.type === FilterType.Period) {
                data = await this._filters.getPeriods(config.module).toPromise();
            } else if (config.type === FilterType.DataStructures) {
                data = await this._filters.getDataStructures().toPromise();
            } else if (config.type === FilterType.DataSubjects) {
                data = await this._filters.getDataSubjects().toPromise();
            } else if (config.type === FilterType.RiskCatalogue) {
                data = await this._filters.getAllByType(StandingDataType.RiskCatalogue).toPromise();
            }
            this.processFilterData(fIndex, config, data);
        });

        await Promise.all(processPromises).then(() =>
            setTimeout(() => {
                this.fillFilters();
            }, 0)
        );

        this.filtersLoaded = true;
    }

    processFilterData(
        fIndex: number,
        config: FilterConfig,
        data?: CerrixTreeItem[] | IdNameCombination[]
    ) {
        if (config.remoteFilter === true) {
            this.hasServerFilters = true;
        }

        this.dataFilter[config.column] = data;
        if (config.type === FilterType.Period) {
            config.identifier = `combobox-${this.tabID}-${fIndex}`;
            config.component = config.component ? config.component : FilterComponent.ComboBox;
        } else {
            config.identifier = `cerrix-tree-${this.tabID}-${fIndex}`;
            config.component = config.component
                ? config.component
                : FilterComponent.MultiSelectTree;
        }
    }

    replaceSearchTag(tag: string) {
        if (tag === "{{today}}") {
            tag = moment().format("DD-MM-YYYY");
        }

        return tag;
    }

    onDropSetActive(event: any) {
        const head = event.items[0];
        if (event.destination) {
            this.hiddenHeaders.splice(this.hiddenHeaders.indexOf(head.identifier), 1);
            this.hiddenHeaderData.splice(this.hiddenHeaderData.indexOf(head), 1);
            this.shownHeaders.splice(event.currentIndex, 0, head.identifier);
            this.shownHeaderData.splice(event.currentIndex, 0, head);
        } else {
            const tempHeaders = event.source.currentData.map((x) => x.identifier);
            this.hiddenHeaders = tempHeaders;
            this.hiddenHeaderData = event.source.currentData;
        }
    }

    onDropSetHidden(event: any) {
        const head = event.items[0];
        if (event.destination) {
            this.shownHeaders.splice(this.shownHeaders.indexOf(head.identifier), 1);
            this.shownHeaderData.splice(this.shownHeaderData.indexOf(head), 1);
            this.hiddenHeaders.splice(event.currentIndex, 0, head.identifier);
            this.hiddenHeaderData.splice(event.currentIndex, 0, head);
        } else {
            const tmpHeaders = event.source.currentData.map((x) => x.identifier);
            this.shownHeaders = tmpHeaders;
            this.shownHeaderData = event.source.currentData;
        }
    }

    toggleHeader(event: any, activeHeader: boolean) {
        const head = event.srcElement.innerText;

        if (activeHeader) {
            const item = this.shownHeaderData.filter((x) => x.name == head)[0];

            this.shownHeaders.splice(this.shownHeaders.indexOf(item.identifier.toString()), 1);
            this.shownHeaderData.splice(this.shownHeaderData.indexOf(item), 1);
            this.hiddenHeaders.push(item.identifier.toString());
            this.hiddenHeaderData.push(item);
            this.activeListbox.refresh();
            this.hiddenListbox.refresh();
        } else {
            const item = this.hiddenHeaderData.filter((x) => x.name == head)[0];

            this.hiddenHeaders.splice(this.hiddenHeaders.indexOf(item.identifier.toString()), 1);
            this.hiddenHeaderData.splice(this.hiddenHeaderData.indexOf(item), 1);
            this.shownHeaders.push(item.identifier.toString());
            this.shownHeaderData.push(item);
            this.activeListbox.refresh();
            this.hiddenListbox.refresh();
        }
    }

    private sortColumns() {
        const allColumns = JSON.parse(JSON.stringify(this.allHeaders)) as string[];

        if (this.headerLookup) {
            // Sort by header lookup
            this.allColumns = allColumns.sort((x, y) => {
                const headerX = this.headerLookup[x] ? this.headerLookup[x] : x;
                const headerY = this.headerLookup[y] ? this.headerLookup[y] : y;

                return headerX > headerY ? 1 : -1;
            });
        } else {
            this.allColumns = allColumns.sort();
        }
    }

    public getShownHeaders() {
        return this.shownHeaders;
    }

    private setHeaderData() {
        this.shownHeaderData = this.shownHeaders.map((identifier) => {
            return { identifier, name: this.headerLookup[identifier] };
        });

        this.hiddenHeaderData = this.hiddenHeaders.map((identifier) => {
            return { identifier, name: this.headerLookup[identifier] };
        });
    }
}
