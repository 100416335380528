<div class="d-flex flex-column main-container">
    <div class="header h3-title">
        {{ document.Name }}
    </div>
    <div class="d-flex flex-column content">
        <div class="d-flex flex-row">
            <div class="bold-text">User</div>
            <div class="d-flex regular-text">{{ document.Username }}</div>
        </div>
        <div class="d-flex flex-row">
            <div class="bold-text">Date</div>
            <div class="d-flex regular-text">{{ document.Date | dateTimeFormat : true }}</div>
        </div>
        <div class="d-flex flex-row">
            <div class="bold-text"># of versions</div>
            <div class="d-flex regular-text">{{ document.Version }}</div>
        </div>
        <div class="d-flex flex-row">
            <div class="bold-text">Type</div>
            <div class="d-flex regular-text">{{ document.DocumentType }}</div>
        </div>
        <div class="d-flex flex-row">
            <div class="bold-text">Description</div>
            <div class="d-flex regular-text">{{ document.Description }}</div>
        </div>
    </div>
</div>
