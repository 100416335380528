import { IHasDocuments } from "@methods/DocumentMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { TaskType } from "../enums/TaskType";
import { TaskWorkflowStatus } from "../enums/TaskWorkflowStatus";
import { TypeSpecificTaskDataModel } from "./TypeSpecificTaskDataModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TaskWorkflowAction } from "../enums/TaskWorkflowAction";

export class BaseTaskModel implements IHasDocuments {
    id: number;
    guid: string;
    name: string;
    responsibles: number[];
    reviewerIds: number[];
    actionDate: Date;
    notifications: number[];
    description: string;
    workflowState: TaskWorkflowStatus;
    comments: string;
    score?: number;
    scoreOption: number;

    /** Previous workflow action of comment */
    workflowAction?: string;
    /** Previous workflow comment */
    workflowComment?: string;
    commentWorkflowAction: TaskWorkflowAction;
    /** New workflow comment */
    workflowCommentNew?: string;

    Documents: DocumentModel[];
    hyperlinks: HyperlinkModel[];

    typedTasks: { [id: number]: TypeSpecificTaskDataModel };

    // Frontend only
    linkedTypeIds: TaskType[];
    controlIds: number[];

    constructor() {
        this.responsibles = [];
        this.reviewerIds = [];
        this.notifications = [];
        this.workflowState = TaskWorkflowStatus.ToDo;
        this.Documents = [];
        this.hyperlinks = [];
        this.linkedTypeIds = [];
        this.controlIds = [];
    }
}
