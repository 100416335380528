import { Component, OnInit } from "@angular/core";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { toPromise } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { AuditFindingDataService } from "./../../services/audit-finding-data.service";
import { AuditFindingInfoModel } from "./../../shared/data-models/audit-finding-info-model";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { ModuleSubType } from "@enums/ModuleSubType";

@Component({
    selector: "audit-finding-overview",
    templateUrl: "./audit-finding-overview.component.html",
    styleUrls: ["./audit-finding-overview.component.scss"],
})
export class AuditFindingOverviewComponent implements OnInit {
    moduleType = ModuleType.AUDIT;
    appendToModuleType = ModuleSubType[ModuleSubType.AuditFindings];
    tabID: string;
    tab: TabModel;

    data: Promise<any[]>;
    headerLookup: any = {
        identifier: "Identifier",

        findingName: "Finding name",
        findingDescription: "Finding description",
        rootCause: "Root cause",
        recommendation: "Recommendation",
        severityName: "Severity",
        followUp: "Follow-up",
        comment: "Comment",
        auditees: "Auditee(s)",
        managementResponse: "Management response",
        dateModified: "Date modified",
        modifiedBy: "Modified by",
        auditIdentifier: "Audit identifier",
        auditName: "Audit name",
    };

    filterConfigs: FilterConfig[] = [];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "severityName",
            actionColumn: "severityColor",
            type: RendererType.Score,
            hideActionColumn: true,
        },
        {
            textColumn: "dateModified",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];

    dateProps: string[] = ["dateModified"];
    numberProps: string[] = [];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _pages: Pages,
        private _tabs: TabService,
        private _permissions: PermissionsService,
        private _ds: AuditFindingDataService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.auditFinding;
        this.data = toPromise(this._ds.getList()).then(this.mapHeaders);
    }

    openDetail(finding: AuditFindingInfoModel) {
        this._tabs.generateTab(
            this._pages.AuditFindingDetail,
            finding.guid,
            `(A.F.) ${+finding.identifier} - ${finding.findingName}`
        );
    }

    add() {
        this._tabs.generateTab(this._pages.AuditFindingDetail, null, "(A.F.) New Audit Finding");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
