import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { WopiComponent } from "./wopi.component";
import { WopiDebugComponent } from "./wopidebug/wopi-debug.component";
import { ProofKeyInfoComponent } from "./wopidebug/components/proof-key-info/proof-key-info.component";
import { WopiConfigurationService } from "./services/wopi-configuration.service";
import { LockInfoComponent } from "./wopidebug/components/lock-info/lock-info.component";
import { DiscoveryInfoComponent } from "./wopidebug/components/discovery-info/discovery-info.component";
import { TransactionInfoComponent } from "./wopidebug/components/transaction-info/transaction-info.component";

@NgModule({
    declarations: [
        WopiComponent,
        WopiDebugComponent,
        ProofKeyInfoComponent,
        LockInfoComponent,
        TransactionInfoComponent,
        DiscoveryInfoComponent,
    ],
    exports: [],
    imports: [CommonModule, SharedModule],
})
export class WopiModule {
    static forRoot(): ModuleWithProviders<WopiModule> {
        return {
            ngModule: WopiModule,
            providers: [WopiConfigurationService, WopiDebugComponent],
        };
    }
}
