import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataStructureModel } from "@app/datamanagement/models/DataStructureModel";
import { DataStructuresStandingData } from "@app/datamanagement/models/DataStructuresStandingData";
import { DataSubjectModel } from "@app/datamanagement/models/DataSubjectModel";
import { DataStructuresHelper } from "@app/datamanagement/shared/helpers/datastructures-helper";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { FindNodes } from "@methods/TreeMethods";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "datasubject-dialog",
    templateUrl: "./datasubject-dialog.component.html",
    styleUrls: ["./datasubject-dialog.component.scss", "../../../../sass/datamanagement.scss"],
})
export class DatasubjectDialogComponent extends BaseModal implements OnInit {
    standingData: DataStructuresStandingData;
    disabled: boolean;

    dataSubjectModels: DataSubjectModel[] = [];
    currentDataSubjectsIds: number[];

    formGroup: FormGroup;

    constructor(
        _bsModalRef: BsModalRef,
        private _modalService: BsModalService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {
        super(_bsModalRef);
    }

    ngOnInit(): void {
        if (!this.dataSubjectModels) {
            this.dataSubjectModels = [];
        }

        this.initFormValidation();
    }

    initFormValidation() {
        this.formGroup = new FormGroup({});

        this.dataSubjectModels.map((dataSubject: DataSubjectModel) => {
            const dataStructureIds = dataSubject.dataStructures.map((x) => x.id);
            const dataStructureIdsControl = new FormControl(dataStructureIds, Validators.required);

            this.formGroup.addControl(dataSubject.id.toString(), dataStructureIdsControl);
        });

        if (this.disabled) {
            this.formGroup.disable();
        }
    }

    dataSubjectChanged(selection: number[]) {
        const differences = this.dataSubjectModels.findDifferences<DataSubjectModel, number>(
            selection,
            (l, r) => {
                return l.id === r;
            }
        );

        const addedNodes = FindNodes(this.standingData.dataSubjects, differences.added);
        const addedDataSubjects = addedNodes.filter((x) => x.Children.length === 0);
        if (addedDataSubjects.length === 0) {
            return;
        }

        addedDataSubjects.forEach((dataSubject) => {
            const dataStructureIdsControl = new FormControl([], Validators.required);
            this.formGroup.addControl(dataSubject.ID.toString(), dataStructureIdsControl);

            const addedDataSubject = <DataSubjectModel>{
                id: dataSubject.ID,
                name: dataSubject.Name,
                dataStructures: [],
                dataStructuresLoaded: true,
            };

            this.dataSubjectModels.push(addedDataSubject);
        });

        this.currentDataSubjectsIds = this.dataSubjectModels.map((x) => x.id);
    }

    deleteDataSubject(dataSubject: DataSubjectModel) {
        this._promptService
            .confirm(
                "Delete subject",
                "Are you sure you want to delete this datasubject from this system?"
            )
            .onConfirm()
            .subscribe(() => {
                const index = this.dataSubjectModels.indexOf(dataSubject);
                if (index >= 0) {
                    this.formGroup.removeControl(dataSubject.id.toString());
                    this.dataSubjectModels.splice(index, 1);
                }
            });
    }

    setDataStructures(selection: number[], dataSubject: DataSubjectModel) {
        if (!dataSubject.dataStructuresLoaded) {
            return;
        }

        let currentDataStructures = dataSubject.dataStructures;
        const differences = currentDataStructures.findDifferences<DataStructureModel, number>(
            selection,
            (l, r) => {
                return l.id === r;
            }
        );

        if (differences.added.length === 0 && differences.deleted.length === 0) {
            return;
        }

        // Filter out the deleted data structures
        if (differences.deleted.length !== 0) {
            currentDataStructures = currentDataStructures.filter(
                (c) => !differences.deleted.includes(c)
            );
        }

        // New data structures were added, only add leaves
        if (differences.added.length !== 0) {
            const dsNodes = FindNodes(this.standingData.dataStructures, <number[]>selection);
            const newDataStructures = dsNodes
                .filter((ds) => ds.Children.length === 0 && differences.added.indexOf(ds.ID) > -1)
                .map(
                    (x) =>
                        <DataStructureModel>{
                            dataSubjectId: dataSubject.id,
                            id: x.ID,
                            name: x.Name,
                            archivePolicy: this.standingData.generalArchivePolicy || "",
                        }
                );

            currentDataStructures.addRange(newDataStructures);
        }

        dataSubject.dataStructures = currentDataStructures;
    }

    manageArchivePolicy(dataSubject: DataSubjectModel) {
        DataStructuresHelper.viewArchivePolicyDialog(
            dataSubject,
            this._modalService,
            this.disabled
        ).subscribe((dialogResult: DataStructureModel[] | boolean) => {
            if (dialogResult) {
                dataSubject.dataStructures = dialogResult as DataStructureModel[];
            }
        });
    }

    // Base modal implementation
    okClick() {
        FormValidationHelper.markAllAsTouched(this.formGroup);
        if (!this.formGroup.valid) {
            this._toastr.warning("Not all fields are valid, please review your input.");
            return;
        }

        this._bsModalRef.hide();

        super.onConfirm(this.dataSubjectModels);
    }

    closeClick() {
        super.onCancel();
    }
}
