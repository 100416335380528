<linked-moi-overview
    [moiType]="moiType"
    [parentGuid]="parentGuid"
    [canAdd]="permissions.CanCreateMoi"
    [canDelete]="permissions.CanDeleteMoi"
    *ngIf="permissions.CanViewMoi"
    #linkedControlMoisComponent
>
</linked-moi-overview>

<hr />

<app-linkable
    *ngIf="!isLoading && permissions.CanViewBim"
    typename="measures of improvement of Finding Reports"
    [displayValueProp]="displayValueProp"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="!permissions.CanLinkBim"
    (onOpenClicked)="openItem($event)"
    [hideActions]="hideActions"
    [hideFilter]="hideFilter"
    [allowAddNew]="allowAddNew"
    (linkedChange)="onLinkedItemsChanged($event)"
    #linkedImMoi
>
</app-linkable>
