<div class="fancy-loader" *ngIf="!loaded"></div>

<ng-container *ngIf="loaded">
    <ng-container *ngIf="!availableManuals">
        <span>No available manuals found for download.</span>
    </ng-container>

    <ng-container *ngIf="availableManuals">
        <ng-container *ngFor="let m of availableManuals">
            <ng-container *ngTemplateOutlet="manualTemplate; context: { manual: m }"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #manualTemplate let-manual="manual">
    <ng-container *ngIf="manual.fileSize > 0">
        <a target="_blank" [href]="manual.downloadPath" [title]="manual.name">
            <div class="pdf-download">
                <div class="pdf-icon">
                    <i class="fas fa-file-pdf"></i>
                </div>
                <div class="pdf-info">
                    <div class="pdf-name">{{ manual.name }}</div>
                    <div class="pdf-filesize">{{ manual.fileSize | fileSize }}</div>
                </div>
            </div>
        </a>
    </ng-container>
    <ng-container *ngIf="manual.children && manual.children.length > 0">
        <div class="detail-page-card">
            <div class="detail-page-card-title">{{ manual.name }}</div>
            <div class="detail-page-card-body">
                <ng-container *ngFor="let child of manual.children">
                    <ng-container
                        *ngTemplateOutlet="manualTemplate; context: { manual: child }"
                    ></ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>
