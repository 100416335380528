import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { FormPageType } from "@app/forms/enums/FormPageType";
import {
    Form,
    FormEntryModel,
    FormFieldConfig,
    FormPageResult,
    FormResult,
} from "@app/forms/models";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { closeDirtyPageCheck, parseTyped, toPromise, tryParseJson } from "@methods/CommonMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { FileSaveModel } from "@models/generic/FileSaveModel";
import { KeyValue } from "@models/generic/KeyValuePair";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { PermissionsService } from "../../../../services/permissions/PermissionsService";
import { FormResultValidation } from "../../shared/methods/FormResultValidation";
import { lastValueFrom } from "rxjs";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { IncidentMapbackService } from "@app/incident/services/incident-mapback.service";
import { NewIncidentStructureTypes } from "@app/forms/shared/constants/NewIncidentStructureTypes";
import { IdNameCombination } from "@models/generic/IdNameCombination";

declare var $: any;

@Component({
    selector: "form-entry",
    templateUrl: "./form-entry.component.html",
    styleUrls: ["./form-entry.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FormEntryComponent implements OnInit {
    tabID: string;
    tab: TabModel;
    config: any;

    id: string;

    resultID: number;
    formID: number;

    pageTypes = FormPageType;

    form: Form;
    result: FormResult;
    selectedIndex = 0;

    followingUsers: KeyValue<number, string>[];
    selectedNextUser: number;

    answers = {};

    isReview = false;
    comments = {};

    allowReopen = false;

    rejectablePages: KeyValue<number, string>[];
    reviewRejected: boolean;
    reviewComment: string;
    rejectPage: number;

    // lastPageIndex is will be either the last actual index or +1 if summary is enabled.
    lastPageIndex = 0;
    saveClicked = false;
    readOnly = false;
    isPreview = false;

    answerChanged = false;

    userIsViewingSummary = false;

    useIncidents: boolean = false;

    @ViewChild("popupModal") popupModal: ElementRef;

    constructor(
        private _formDS: FormsDataService,
        private _toastr: ToastrService,
        private _pages: Pages,
        private route: ActivatedRoute,
        private _permissions: PermissionsService,
        private _promptService: CerrixPromptService,
        private _settingsService: SettingsDataService,
        private _mapbackService: IncidentMapbackService
    ) {}

    async ngOnInit() {
        this.tab.beforeClose = (checkOnly) => {
            const unsavedChanges = !this.readOnly && this.answerChanged;
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !unsavedChanges);
        };

        this.useIncidents = (
            await this._settingsService.getSetting(ApplicationSettings.UseIncidents).toPromise()
        ).BoolValue;

        this.resultID =
            this.id && !Number.isNaN(+this.id)
                ? +this.id
                : this.config && !Number.isNaN(+this.config["resultID"])
                ? this.config["resultID"]
                : 0;
        this.formID =
            this.config && !Number.isNaN(+this.config["formid"])
                ? +this.config["formid"]
                : this.route.snapshot.queryParams.formid;

        await this.refresh();
    }

    async refresh() {
        this.isPreview = this.config && this.config["preview"];
        if (this.isPreview) {
            if (!this._permissions.permissions.Forms.IsPreviewViewer) {
                this.refreshParentAndClose();
            }

            this.setupPreview(JSON.parse(this.config["preview"]));
            return;
        }
        if (!this.formID && !this.resultID) {
            this.tab.close();
            return;
        }
        await this.getResult();
    }

    private async getResult() {
        let data = await lastValueFrom(this._formDS.getResult(this.formID, this.resultID));
        this.setupForm(data);
    }

    setupPreview(form: Form) {
        form.Pages.forEach((p) => {
            p.Valid = null;
            p.Validation = "";

            p.Fields.forEach((f) => {
                f.Valid = null;
                f.Validation = "";
            });
        });

        const result = <FormResult>{
            FormID: form.ID,
            PageResults: form.Pages.map(
                (p) =>
                    <FormPageResult>{
                        PageID: p.ID,
                        FieldResults: [],
                    }
            ),
            FollowingUserRequired: false,
            FollowingUsers: [],
        };

        form.Results = [result];
        this.setupForm(form);
    }

    setupForm(form: Form) {
        this.form = form;
        this.formID = this.form.ID;
        this.tab.name = `${this.isPreview ? "Preview" : "Entry"} - ${this.form.Title}`;
        this.lastPageIndex = this.form.ShowSummary
            ? this.form.Pages.length
            : this.form.Pages.length - 1;

        if (form.Results) {
            this.initResult(form.Results);
        }

        this.followingUsers = this.result.FollowingUsers;

        // Check if entry is allowed.
        if (!this.isPreview && this.form.IsSingleEntry && !this.form.CanStartEntry) {
            // If the user reopens a entry that has been started already,
            // we do not want to get out. The user probably wants to continue
            // the form or review it in readonly (will be handled elsewhere).
            if (!this.result) {
                // we should check
                this._toastr.error("You already have a form entry!", "Only one entry allowed!");
                this.refreshParentAndClose();
            }
        }

        this.loadStandingData();
    }

    loadStandingData() {
        this.form.Pages.forEach((page) => {
            page.Fields.forEach((field) => {
                if (field.FieldType === FormFieldType.Structure) {
                    const model = parseTyped<FormFieldConfig>(
                        field.CustomSettings,
                        new FormFieldConfig()
                    );
                    if (this.useIncidents) {
                        // get incident structure data
                        if (
                            NewIncidentStructureTypes.newIncidentStructures.find(
                                (x) => x == model.structureType
                            )
                        ) {
                            field.SourceDataCall = this._mapbackService.getStructureForMapback(
                                model.structureType
                            );
                        } else {
                            field.SourceDataCall = this._formDS.getStructureData(
                                this.resultID,
                                model
                            );
                        }
                    } else {
                        field.SourceDataCall = this._formDS.getStructureData(this.resultID, model);
                    }
                }
            });
        });
    }

    initResult(results: FormResult[]) {
        // Results should be only one.
        if (results.length > 1) {
            throw new Error("Result error!");
        }

        if (results.length < 1) {
            return;
        }

        this.result = results[0];
        this.resultID = this.result.ResultID;

        if (this.resultID === 0 && this.form.FormOutOfDateRange) {
            this._toastr.warning("Form is outside daterange.", "Expired form.");
            this.tab.close(false);
            return;
        }

        this.readOnly =
            this.result.Completed ||
            this.isPreview ||
            this.result.PageResults.filter((x) => x.Completed || x.Readonly).length ===
                this.result.PageResults.length;

        this.allowReopen = this.result.AllowReopen;

        const lastPageResult = this.result.PageResults.last();
        const lastPage = this.form.Pages.find((x) => x.ID === lastPageResult.PageID);
        this.isReview = lastPageResult.Completed !== true && +lastPage.Type === 2;

        this.result.PageResults.forEach((pr) => {
            pr._pageIcon = pr.Completed ? "fas fa-lock" : "far fa-edit";
        });

        if (this.isReview) {
            this.rejectablePages = this.form.Pages.filter(
                (p, pi) =>
                    this.result.PageResults[pi].Completed && this.result.PageResults[pi].UserName
            ).map(
                (p, pi) =>
                    <KeyValue<number, string>>{
                        Key: p.ID,
                        Value: `${p.Title} (${this.result.PageResults[pi].UserName})`,
                    }
            );
        }

        this.initAnswersAndComments();
    }

    initAnswersAndComments() {
        this.answers = {};
        this.comments = {};
        this.reviewComment = this.result.ReviewComment ? this.result.ReviewComment : "";

        this.result.PageResults.forEach((pageResult) => {
            const page = this.form.Pages.find((p) => p.ID === pageResult.PageID);

            pageResult.FieldResults.forEach((fieldResult) => {
                const field = page.Fields.find((f) => f.ID === fieldResult.FormFieldId);

                // Special inits could be defined here for field types.
                if (field.FieldType === FormFieldType.FileUpload) {
                    const answer = tryParseJson(fieldResult.Value);
                    this.answers[fieldResult.FormFieldId] = answer ? answer : [];
                } else {
                    this.answers[fieldResult.FormFieldId] = fieldResult.Value;
                }

                this.comments[fieldResult.FormFieldId] = fieldResult.Comment;
            });
        });
    }

    completeClick(reject?: boolean) {
        this.reviewRejected = reject === true;

        const validations = FormResultValidation.ValidateResult(
            this.form,
            this.result,
            this.answers
        );

        if (validations.any()) {
            this._toastr.error(validations.join("<br>"), "Form result not valid", {
                enableHtml: true,
            });
            return;
        }

        if (!this.form.Workflow) {
            this.storeResult(true);
            return;
        }

        if (this.reviewRejected) {
            this.handleRejected();
        } else {
            this.handleAccepted();
        }
    }

    private handleAccepted(): void {
        if (!this.result.FollowingUserRequired) {
            this.storeResult(true);
            return;
        }

        if (this.followingUsers && this.followingUsers.length == 1) {
            // Don't show popup if there's only 1 possible followup user (#11388)
            this.selectedNextUser = this.followingUsers[0].Key;
            this.storeResult(true);
        } else {
            $(this.popupModal.nativeElement).modal("show");
        }
    }

    private handleRejected(): void {
        if (this.rejectablePages && this.rejectablePages.length == 1) {
            // Don't show popup if there's only 1 rejectable page
            this.rejectPage = this.rejectablePages[0].Key;
            this.storeResult(true);
        } else {
            $(this.popupModal.nativeElement).modal("show");
        }
    }

    async storeResult(complete: boolean) {
        $(this.popupModal.nativeElement).modal("hide");

        if (this.reviewRejected && this.rejectPage === undefined) {
            this._toastr.error("Reject to page is empty.", `Rejection failed`);
            return;
        }

        const storeResult = () => {
            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            const result = this.processAnswers(complete);

            this.saveClicked = true;
            this.answerChanged = false;
            this._formDS.storeFormResult(result, result.Files).subscribe(
                async (response) => {
                    if (result.Complete) {
                        // save to incidents
                        if (this.useIncidents) {
                            await this.mapBackToIncidents(result);
                        }
                        this.refreshParentAndClose();
                        this._toastr.success("", "Form entry completed!");
                    } else {
                        this.resultID = response;
                        this.config["resultID"] = this.resultID;
                        this._toastr.success("", "Form entry saved!");
                    }

                    this.saveClicked = false;
                    savingPrompt.close();
                    await this.getResult();
                },
                (error) => {
                    this.saveClicked = false;
                    savingPrompt.close();
                }
            );
        };

        if (complete) {
            this._promptService
                .confirm(
                    "Complete result",
                    `Are you sure you want to ${
                        this.isReview ? (this.reviewRejected ? "reject" : "accept") : "complete"
                    }?`
                )
                .onConfirm()
                .subscribe(() => {
                    storeResult();
                });
        } else {
            await storeResult();
        }
        return this.resultID;
    }

    async mapBackToIncidents(result: FormEntryModel) {
        //Create object to store the data like key value pairs <mapbackid, answer>
        // this.form knows about the fieldid's and result.answers knows about the answers  related to the fieldid's
        const mapBackData: IdNameCombination[] = [];
        this.form.Pages.forEach((page) => {
            page.Fields.forEach((field) => {
                if (field.MapBackField) {
                    const answer = result.Answers[field.ID];
                    if (answer) {
                        if (field.FieldType === FormFieldType.Number) {
                            mapBackData.push({ ID: field.MapBackField, Name: answer.toString() });
                        } else {
                            mapBackData.push({ ID: field.MapBackField, Name: answer });
                        }
                    }
                }
            });
        });
        await this._mapbackService.storeIncident(mapBackData);
    }

    processAnswers(complete: boolean): FormEntryModel {
        const entryPages = this.result.PageResults.filter((x) => x.Readonly !== true);

        const fieldsToSubmit: number[] = [];
        const fileUploadFields: number[] = [];
        entryPages.forEach((pr) => {
            const pageField = this.form.Pages.find((p) => p.ID === pr.PageID);

            pageField.Fields.forEach((f) => fieldsToSubmit.push(f.ID));
            fileUploadFields.addRange(
                pageField.Fields.filter((f) => f.FieldType === FormFieldType.FileUpload).map(
                    (x) => x.ID
                )
            );
        });

        const answersToSubmit = {};
        fieldsToSubmit.forEach((f) => (answersToSubmit[f] = this.answers[f]));

        const files: FileSaveModel[] = [];
        for (let fuIndex = 0; fuIndex < fileUploadFields.length; fuIndex++) {
            const fu = fileUploadFields[fuIndex];
            const fileAnswer = answersToSubmit[fu] as DocumentModel[];
            answersToSubmit[fu] = JSON.stringify(answersToSubmit[fu]);
            fileAnswer.forEach((x) => files.push({ id: x.Guid, file: x.File }));
        }

        const result: FormEntryModel = {
            FormID: this.formID,
            ResultID: this.resultID,

            Complete: complete,
            IsRejected: this.reviewRejected,
            RejectToPage: this.rejectPage,
            NextUser: this.selectedNextUser && !this.reviewRejected ? this.selectedNextUser : 0,
            ReviewComment: this.reviewComment,

            Answers: answersToSubmit,
            Comments: this.comments,
            Files: files,
        };

        return result;
    }

    previousPage() {
        this.selectedIndex--;
        this.updateSelectedMenuItem();
    }

    nextPage() {
        this.selectedIndex++;
        this.updateSelectedMenuItem();
    }

    selectedChanged(menuItem: TabMenuItem) {
        if (menuItem instanceof TabMenuItem) {
            this.selectedIndex = this.tab.menu.menuItems[0].children.indexOf(menuItem);
        }

        this.userIsViewingSummary = menuItem.iconClass === "fas fa-eye";
    }

    updateSelectedMenuItem() {
        const menuItem = this.tab.menu.menuItems[0].children[this.selectedIndex];
        this.tab.menu.menuItemClicked(menuItem);
        this.tab.menu.activeMenuItem = menuItem.identifier;
    }

    refreshParentAndClose() {
        this.refreshParent();
        this.tab.close(false);
    }

    refreshParent() {
        if (
            this.tab.parent.lookupname === this._pages.FormOverview ||
            this.tab.parent.lookupname === this._pages.Forms
        ) {
            this.tab.parent.refresh();
        }
    }

    reopenClick() {
        if (this.allowReopen) {
            this._promptService
                .confirmCustom({
                    maxWidth: "425px",
                    maxHeight: "300px",
                    data: {
                        title: "Reopen entry",
                        message:
                            "Reopening a form result alters the result to the newest version of the form. This can result in loss of older fields and pages. Also the form will be set back to the first page and has to be completed again. Are you sure you want to reopen?",
                    },
                })
                .onConfirm()
                .subscribe(() => {
                    const prompt = this._promptService.loader("Reopening result, please wait...");

                    this._formDS
                        .reopenResult(this.formID, this.resultID)
                        .pipe(finalize(() => prompt.close()))
                        .subscribe(() => {
                            this._toastr.success("Result has been reopened!", "Success");
                            this.tab.refresh();
                            this.refreshParent();
                        });
                });
        }
    }
}
