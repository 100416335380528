import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import { ControlModule } from "../../control.module";

import { AdvEffControlOverviewComponent } from "./control-overview/adv-eff-control-overview.component";
import { WizardComponent } from "./control-overview/wizard/adv-eff-wizard.component";
import { TestperiodManagerComponent } from "./control-overview/testperiod-manager/adv-eff-testperiod-manager.component";
import { AuditSamplerComponent } from "./control-overview/audit-sampler/adv-eff-audit-sampler.component";

import { AdvEffDetailComponent } from "./detail/adv-eff-detail.component";

import { LinkedRisksComponent } from "./detail/linked-risks/adv-eff-linked-risks.component";
import { SourceDocumentsComponent } from "./detail/source-documents/adv-eff-source-documents.component";

import { CommentDialogComponent } from "./detail/dialogs/comment-dialog/adv-eff-comment-dialog.component";
import { ConclusionDialogComponent } from "./detail/dialogs/conclusion-dialog/adv-eff-conclusion-dialog.component";
import { GenerateSamplesDialogComponent } from "./detail/dialogs/generate-samples-dialog/adv-eff-generate-samples-dialog.component";
import { TestPeriodConclusionDialogComponent } from "./detail/dialogs/test-period-conclusion-dialog/adv-eff-test-period-conclusion-dialog.component";

import { EvidenceComponent } from "./detail/evidence/adv-eff-evidence.component";
import { EvidenceSampleEditorComponent } from "./detail/evidence/sample-editor/adv-eff-evidence-sample-editor.component";
import { EvidenceTestStepEditorComponent } from "./detail/evidence/test-step-editor/adv-eff-evidence-test-step-editor.component";
import { SampleRemarkDialogComponent } from "./detail/evidence/sample-remark-dialog/adv-eff-sample-remark-dialog.component";

import { WorkflowHistoryComponent } from "./detail/workflow-history/adv-eff-workflow-history.component";
import { ConclusionsComponent } from "./detail/conclusions/adv-eff-conclusions.component";

import { AdvEffOverviewComponent } from "./overview/adv-eff-overview.component";

import { AdvEffHistoryDataService } from "./control-overview/adv-eff-history-data.service";
import { OverviewDataService } from "./control-overview/shared/adv-eff-overview-data.service";
import { TestPeriodDataService } from "./control-overview/testperiod-manager/adv-eff-testperiod-data.service";
import { AuditSamplerDataService } from "./control-overview/audit-sampler/adv-eff-audit-sampler-data.service";
import { AdvEffDataService, AdvEffWorkflowDataService } from "./shared/services";

import { EffDetailNewModule } from "./detail-new/eff-detail-new.module";

@NgModule({
    declarations: [
        AdvEffOverviewComponent,
        AdvEffDetailComponent,
        LinkedRisksComponent,
        SourceDocumentsComponent,
        GenerateSamplesDialogComponent,
        EvidenceComponent,
        EvidenceSampleEditorComponent,
        EvidenceTestStepEditorComponent,
        ConclusionDialogComponent,
        CommentDialogComponent,
        WorkflowHistoryComponent,
        ConclusionsComponent,
        SampleRemarkDialogComponent,
        TestPeriodConclusionDialogComponent,

        AdvEffControlOverviewComponent,
        WizardComponent,
        TestperiodManagerComponent,
        AuditSamplerComponent,
    ],
    imports: [CommonModule, SharedModule, ControlModule, EffDetailNewModule.forRoot()],
})
export class AdvancedTestingModule {
    static forRoot(): ModuleWithProviders<AdvancedTestingModule> {
        return {
            ngModule: AdvancedTestingModule,
            providers: [
                AdvEffDataService,
                AdvEffWorkflowDataService,
                OverviewDataService,
                TestPeriodDataService,
                AuditSamplerDataService,
                AdvEffHistoryDataService,
            ],
        };
    }
}
