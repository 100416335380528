import { mxgraph as mxType } from "mxgraph-factory";
import { GraphEditor } from "./grapheditor";

export class mxStyleSheetHelper {
    public static fixStyleSheet(graph: GraphEditor, mx: typeof mxType) {
        let stylesheet = graph.getStylesheet();

        //Basic Style
        let defaultVertex = this.createDefaultVertexStyle(mx);
        stylesheet.putDefaultVertexStyle(defaultVertex);

        let defaultEdge = this.createDefaultEdgeStyle(mx);
        stylesheet.putDefaultEdgeStyle(defaultEdge);

        let entityStyle = this.createDefaultEdgeStyle(mx);
        entityStyle[mx.mxConstants.STYLE_EDGE] = mx.mxConstants.EDGESTYLE_ENTITY_RELATION;
        stylesheet.putCellStyle("entity", entityStyle);

        let verticalStyle = this.createDefaultEdgeStyle(mx);
        verticalStyle[mx.mxConstants.STYLE_ELBOW] = mx.mxConstants.ELBOW_VERTICAL;
        stylesheet.putCellStyle("vertical", verticalStyle);

        let arrowStyle = this.createDefaultVertexStyle(mx);
        arrowStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_CONNECTOR;
        stylesheet.putCellStyle("arrow", arrowStyle);

        let swimlaneStyle = this.createDefaultVertexStyle(mx);
        swimlaneStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_SWIMLANE;
        swimlaneStyle[mx.mxConstants.STYLE_FONTSIZE] = 12;
        swimlaneStyle[mx.mxConstants.STYLE_FONTSTYLE] = 1;
        swimlaneStyle[mx.mxConstants.STYLE_STARTSIZE] = 23;
        stylesheet.putCellStyle("swimlane", swimlaneStyle);

        let groupStyle = this.createDefaultVertexStyle(mx);
        groupStyle[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_TOP;
        groupStyle[mx.mxConstants.STYLE_DASHED] = 1;
        groupStyle[mx.mxConstants.STYLE_OPACITY] = 50;
        stylesheet.putCellStyle("group", groupStyle);

        let ellipseStyle = this.createDefaultVertexStyle(mx);
        ellipseStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_ELLIPSE;
        ellipseStyle[mx.mxConstants.STYLE_PERIMETER] = mx.mxConstants.PERIMETER_ELLIPSE;
        stylesheet.putCellStyle("ellipse", ellipseStyle);

        let rhombusStyle = this.createDefaultVertexStyle(mx);
        rhombusStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_RHOMBUS;
        rhombusStyle[mx.mxConstants.STYLE_PERIMETER] = mx.mxConstants.PERIMETER_RHOMBUS;
        stylesheet.putCellStyle("rhombus", rhombusStyle);

        let triangleStyle = this.createDefaultVertexStyle(mx);
        triangleStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_TRIANGLE;
        triangleStyle[mx.mxConstants.STYLE_PERIMETER] = mx.mxConstants.PERIMETER_TRIANGLE;
        stylesheet.putCellStyle("triangle", triangleStyle);

        let lineStyle = this.createDefaultVertexStyle(mx);
        lineStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_LINE;
        lineStyle[mx.mxConstants.STYLE_STROKEWIDTH] = 4;
        lineStyle[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = "white";
        lineStyle[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_TOP;
        lineStyle[mx.mxConstants.STYLE_SPACING_TOP] = 8;
        stylesheet.putCellStyle("line", lineStyle);

        let imageStyle = this.createImageStyle(mx);
        stylesheet.putCellStyle("image", imageStyle);

        let roundImageStyle = this.createImageStyle(mx);
        roundImageStyle[mx.mxConstants.STYLE_PERIMETER] = mx.mxConstants.PERIMETER_ELLIPSE;
        stylesheet.putCellStyle("roundImage", roundImageStyle);

        let rhombusImageStyle = this.createImageStyle(mx);
        rhombusImageStyle[mx.mxConstants.STYLE_PERIMETER] = mx.mxConstants.PERIMETER_RHOMBUS;
        stylesheet.putCellStyle("rhombusImage", rhombusImageStyle);

        // CERRIX
        let riskStyle = new Object();
        riskStyle[mx.mxConstants.STYLE_FILLCOLOR] = "#FF4A4A";
        riskStyle[mx.mxConstants.STYLE_SPACING_TOP] = 10;
        riskStyle[mx.mxConstants.STYLE_STROKEWIDTH] = 3;
        riskStyle[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_MIDDLE;
        stylesheet.putCellStyle("risk", riskStyle);

        let riskControlStyle = this.createDefaultVertexStyle(mx);
        riskControlStyle[mx.mxConstants.STYLE_STROKECOLOR] = "#005500";
        riskControlStyle[mx.mxConstants.STYLE_FILLCOLOR] = "#FF0000";
        riskControlStyle[mx.mxConstants.STYLE_SPACING_TOP] = 10;
        riskControlStyle[mx.mxConstants.STYLE_STROKEWIDTH] = 5;
        riskControlStyle[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_TOP;
        stylesheet.putCellStyle("riskcontrol", riskControlStyle);

        let straightStyle = this.createDefaultVertexStyle(mx);
        straightStyle[mx.mxConstants.STYLE_SHAPE] = "connector";
        straightStyle[mx.mxConstants.STYLE_STROKECOLOR] = "#000000";
        straightStyle[mx.mxConstants.STYLE_FILLCOLOR] = "#000000";
        straightStyle[mx.mxConstants.STYLE_EDGE] = mx.mxConstants.NONE;
        straightStyle[mx.mxConstants.STYLE_ENDARROW] = mx.mxConstants.ARROW_CLASSIC;
        stylesheet.putCellStyle("straight", straightStyle);
    }

    private static createDefaultVertexStyle(mx: typeof mxType) {
        let style = new Object();
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_LABEL;
        style[mx.mxConstants.STYLE_PERIMETER] = mx.mxConstants.PERIMETER_RECTANGLE;
        style[mx.mxConstants.STYLE_FONTSIZE] = 11;
        style[mx.mxConstants.STYLE_ALIGN] = mx.mxConstants.ALIGN_CENTER;
        style[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_MIDDLE;
        style[mx.mxConstants.STYLE_STROKECOLOR] = "#000000";
        style[mx.mxConstants.STYLE_FILLCOLOR] = "#D6E3F2";
        return style;
    }

    private static createDefaultEdgeStyle(mx: typeof mxType) {
        let style = new Object();
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_CONNECTOR;
        style[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = "white";
        style[mx.mxConstants.STYLE_ENDARROW] = mx.mxConstants.ARROW_CLASSIC;
        style[mx.mxConstants.STYLE_FONTSIZE] = 10;
        style[mx.mxConstants.STYLE_ALIGN] = mx.mxConstants.ALIGN_CENTER;
        style[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_MIDDLE;
        style[mx.mxConstants.STYLE_STROKECOLOR] = "black";
        style[mx.mxConstants.STYLE_ORTHOGONAL] = "true";
        style[mx.mxConstants.STYLE_EDGE] = mx.mxConstants.EDGESTYLE_ELBOW;
        return style;
    }

    private static createImageStyle(mx: typeof mxType) {
        let style = this.createDefaultVertexStyle(mx);
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_IMAGE;
        style[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = "white";
        style[mx.mxConstants.STYLE_VERTICAL_ALIGN] = mx.mxConstants.ALIGN_MIDDLE;
        style[mx.mxConstants.STYLE_SPACING_TOP] = 50;
        return style;
    }
}
