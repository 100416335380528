<div class="incident-type-edit">
    <div class="actions">
        <cerrix-button
            *ngIf="!showSavedButton"
            [disabled]="!form.dirty || !form.valid || loading"
            color="primary-light"
            type="stroked"
            (click)="onSave()"
            ><ng-container>Save</ng-container
            ><mat-icon fontSet="material-symbols-outlined">check_small</mat-icon></cerrix-button
        >

        <cerrix-button *ngIf="showSavedButton" color="accent-light" type="flat"
            ><ng-container>Saved!</ng-container
            ><mat-icon fontSet="material-symbols-outlined">check_small</mat-icon></cerrix-button
        >

        <cerrix-button
            (click)="onDelete()"
            [disabled]="!this.form.value.id || loading || this.predefined"
            color="error"
            >Delete</cerrix-button
        >
    </div>

    <div *ngIf="loading" style="flex: 1">
        <div style="margin-top: -74px" class="fancy-loader submodule"></div>
    </div>

    <form *ngIf="!loading" class="form-container" [formGroup]="form">
        <cerrix-title
            placeholder="Enter incident type name"
            size="large"
            clipText="true"
            formControlName="name"
            [required]="true"
        ></cerrix-title>

        <mat-tab-group [mat-stretch-tabs]="false" [dynamicHeight]="true">
            <mat-tab label="Input fields">
                <div class="content">
                    <div class="section-container">
                        <div>
                            <div class="section-header">
                                <cerrix-title
                                    value="Details"
                                    [editable]="false"
                                    [focus]="false"
                                ></cerrix-title>
                            </div>
                            <mat-card appearance="outlined" class="custom-fields-card">
                                <div formArrayName="customFields" class="custom-fields-list">
                                    <ng-container
                                        *ngFor="
                                            let customFieldForm of detailCustomFieldsForm.controls;
                                            let cfIndex = index
                                        "
                                        [ngTemplateOutlet]="customFieldsTemplate"
                                        [ngTemplateOutletContext]="{
                                            cfIndex: cfIndex,
                                            customFieldType:
                                                customFieldForm.get('customFieldType').value,
                                            sectionIndex: undefined,
                                            customFieldForm: customFieldForm
                                        }"
                                    ></ng-container>
                                </div>

                                <div class="action-container" *ngIf="!this.predefined">
                                    <cerrix-button
                                        size="small"
                                        (click)="editCustomField(undefined)"
                                    >
                                        <mat-icon>add</mat-icon>
                                        <ng-container>Add new field</ng-container>
                                    </cerrix-button>
                                </div>
                            </mat-card>
                        </div>
                    </div>

                    <div
                        cdkDropList
                        (cdkDropListDropped)="sectionReorder($event)"
                        class="section-draggable-area"
                        formArrayName="incidentTypeSections"
                    >
                        <div
                            class="section-container"
                            *ngFor="let section of sectionsForm.controls; let sectionIndex = index"
                            cdkDrag
                        >
                            <div [formGroupName]="sectionIndex">
                                <div class="section-header">
                                    <cerrix-title
                                        class="title"
                                        placeholder="Section"
                                        formControlName="name"
                                    ></cerrix-title>

                                    <mat-button
                                        class="action-icon drag-pan"
                                        mat-icon-button
                                        cdkDragHandle
                                        *ngIf="!this.predefined"
                                    >
                                        <mat-icon fontSet="material-symbols-outlined"
                                            >drag_pan</mat-icon
                                        >
                                    </mat-button>
                                    <mat-button
                                        class="action-icon"
                                        mat-icon-button
                                        [matMenuTriggerFor]="menu"
                                        *ngIf="!this.predefined"
                                    >
                                        <mat-icon>more_vert</mat-icon>
                                    </mat-button>

                                    <mat-menu #menu="matMenu" class="section-menu">
                                        <button
                                            mat-menu-item
                                            (click)="deleteSection(sectionIndex)"
                                            class="section-menu-button"
                                        >
                                            <mat-icon
                                                class="section-menu-icon"
                                                fontSet="material-symbols-outlined"
                                                >delete</mat-icon
                                            >
                                            <span>Delete</span>
                                        </button>

                                        <button
                                            mat-menu-item
                                            (click)="duplicateSection(sectionIndex)"
                                            class="section-menu-button"
                                        >
                                            <mat-icon
                                                class="section-menu-icon"
                                                fontSet="material-symbols-outlined"
                                                >content_copy</mat-icon
                                            >

                                            <span>Duplicate</span>
                                        </button>
                                    </mat-menu>
                                </div>
                                <mat-card appearance="outlined" class="custom-fields-card">
                                    <div formArrayName="customFields" class="custom-fields-list">
                                        <ng-container
                                            *ngFor="
                                                let customFieldForm of section.get('customFields')
                                                    .controls;
                                                let cfIndex = index
                                            "
                                            [ngTemplateOutlet]="customFieldsTemplate"
                                            [ngTemplateOutletContext]="{
                                                cfIndex: cfIndex,
                                                customFieldType:
                                                    customFieldForm.get('customFieldType').value,
                                                sectionIndex: sectionIndex,
                                                customFieldForm: customFieldForm
                                            }"
                                        ></ng-container>
                                    </div>

                                    <div class="action-container" *ngIf="!this.predefined">
                                        <cerrix-button
                                            size="small"
                                            (click)="editCustomField(sectionIndex)"
                                        >
                                            <mat-icon>add</mat-icon>
                                            <ng-container>Add new field</ng-container>
                                        </cerrix-button>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <cerrix-button
                        (click)="addSection()"
                        class="footer-button"
                        color="accent"
                        *ngIf="!this.predefined"
                    >
                        <mat-icon>add</mat-icon>
                        <ng-container>Add section</ng-container>
                    </cerrix-button>
                </div>
            </mat-tab>
            <mat-tab label="Configuration">
                <div class="assign-config">
                    <cerrix-title value="Assign" [editable]="false"></cerrix-title>
                    <cerrix-field-wrapper
                        class="assign-item"
                        type="secondary"
                        label="Mail addresses to be informed"
                        info="Mail addresses will receive an email when an incident of this specific type is created. Separate the mail addresses by a semi-colon ';'. "
                        ><cerrix-input
                            placeholder="Email placeholder"
                            formControlName="emailAddresses"
                        ></cerrix-input
                    ></cerrix-field-wrapper>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>

<ng-template
    #customFieldsTemplate
    let-cfIndex="cfIndex"
    let-customFieldType="customFieldType"
    let-sectionIndex="sectionIndex"
    let-customFieldForm="customFieldForm"
>
    <div
        class="custom-field-item"
        [class.full-width]="fullWidthCustomTypes.includes(customFieldType)"
    >
        <div [formGroup]="customFieldForm">
            <div class="custom-field-header">
                <cerrix-title
                    class="title"
                    formControlName="name"
                    [editable]="false"
                ></cerrix-title>

                <mat-button
                    class="action-icon custom-field"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </mat-button>

                <mat-menu #menu="matMenu" class="section-menu">
                    <button
                        mat-menu-item
                        (click)="editCustomField(undefined, customFieldForm)"
                        class="section-menu-button"
                        [disabled]="
                            this.predefined &&
                            !(
                                customFieldForm.value.customFieldType == 7 ||
                                customFieldForm.value.customFieldType == 8
                            )
                        "
                    >
                        <mat-icon class="section-menu-icon" fontSet="material-symbols-outlined"
                            >edit</mat-icon
                        >
                        <span>Edit</span>
                    </button>

                    <button
                        mat-menu-item
                        (click)="deleteCustomField(cfIndex, sectionIndex)"
                        class="section-menu-button"
                        [disabled]="this.predefined"
                    >
                        <mat-icon class="section-menu-icon" fontSet="material-symbols-outlined"
                            >delete</mat-icon
                        >
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>

            @if (customFieldType === 4) {
            <cerrix-textarea
                [placeholder]="customFieldForm.controls.placeholder.value"
                disabled="true"
            ></cerrix-textarea>
            } @else{
            <cerrix-input
                [placeholder]="customFieldForm.controls.placeholder.value"
                disabled="true"
            ></cerrix-input>
            }
        </div>
    </div>
</ng-template>
