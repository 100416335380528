import { DateTimeFormats } from "@app/shared/localization/date-time-formats.enum";

export class HistoryModel {
    /** ID is mutation id */
    ID: number;
    MutationDate: Date;
    UserName: string;
    MutationAction: string;
    Changes: HistoryChange[];
    HistoryType: HistoryType;

    /** Filled client side */
    CombinedChanges: CombinedHistoryChange[];

    /** Validity date as string so it's not truncated in Javascript */
    CerrixMutationDate: string;
}

export class HistoryChange {
    ColumnName: string;
    HistoryType: HistoryType;
    OldValue: string;
    NewValue: string;
    dateFormat: DateTimeFormats;
}

export class CombinedHistoryChange {
    ColumnName: string;
    Changes: HistoryChange[] = [];
}

export enum HistoryType {
    insert = 0,
    update = 1,
    delete = 2,
    restore = 3,

    /** No changes */
    none = 255
}
