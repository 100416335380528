<div *ngIf="!finishedLoading">
    <div class="fancy-loader"></div>
</div>
<div *ngIf="finishedLoading">
    <div data-uiid="fr-assessment-summary" class="detail-page-summary" *ngIf="detailsModel.guid">
        <div class="row">
            <div class="col-lg-6 left-block">
                <div class="property"><b>ID:</b> {{ detailsModel.identifier }}</div>
                <div class="property text-truncate">
                    <b>Assessment type:</b> {{ detailsModel.assessmentTypeName }}
                </div>
                <div class="property"><b>Reviewer:</b> {{ detailsModel.reviewerName }}</div>
            </div>
            <div class="col-lg-6 right-block">
                <div class="property">
                    <b>Assessment date:</b> {{ detailsModel.assessmentDate | dateFormat }}
                </div>
                <div class="property">
                    <b>Overall score:</b>
                    <div
                        class="summary-score-color"
                        [style.background-color]="
                            detailsModel.overallScoreColor ? detailsModel.overallScoreColor : ''
                        "
                    ></div>
                    {{ detailsModel.overallScoreName }}
                </div>
            </div>
        </div>
    </div>
    <div class="detail-page-card mt-3" [formGroup]="formGroup">
        <div class="detail-page-card-title">Details</div>
        <div class="detail-page-card-body padded form-group-styling">
            <div class="row">
                <div class="col-xl-6 left-block">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Assessment type</label>
                                <ng-select
                                    data-uiid="fr-assessment-assessmenttype"
                                    [items]="assessmentTypes"
                                    formControlName="assessmentTypeId"
                                    bindValue="value"
                                    placeholder="Required"
                                    [loading]="assessmentTypesLoading"
                                    (change)="onAssessmentTypeChange($event)"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Assessment date</label>
                                <date-input
                                    data-uiid="fr-assessment-assessmentdate"
                                    formControlName="assessmentDate"
                                    placeholder="Required"
                                    [maxDate]="dateToday"
                                ></date-input>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-separator"></div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Assessment URL</label>
                                <cerrix-url-input
                                    dataUiid="fr-assessment-assessmenturl"
                                    formControlName="assessmentUrl"
                                >
                                </cerrix-url-input>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Overall score</label>
                                <ng-select
                                    data-uiid="fr-assessment-overallscore"
                                    [items]="overallScores"
                                    formControlName="overallScoreId"
                                    bindValue="value"
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <span
                                            class="select-template-color"
                                            [style.background-color]="item.color"
                                        ></span>
                                        {{ item.label }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-separator"></div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Reviewer</label>
                                <ng-select
                                    data-uiid="fr-assessment-reviewer"
                                    [items]="reviewers"
                                    formControlName="reviewerId"
                                    bindValue="value"
                                    placeholder="Required"
                                    [loading]="reviewersLoading"
                                >
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <label>Organization reviewer</label>
                                <cerrix-select-tree
                                    data-uiid="fr-assessment-organizationreviewer"
                                    [tree]="reviewerOrganizations"
                                    formControlName="reviewerOrganizationId"
                                    [multiselect]="false"
                                >
                                </cerrix-select-tree>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 right-block">
                    <div class="form-group-separator d-xl-none"></div>
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textarea
                                fieldLabel="Comments reviewer"
                                dataUiid="fr-assessment-commentsreviewer"
                                formControlName="reviewerComments"
                            ></cerrix-textarea>
                        </div>
                    </div>
                    <div class="form-group-separator d-none d-xl-block"></div>
                </div>
            </div>
        </div>
    </div>
    <linked-finding-report
        [findingReportGuid]="detailsModel.findingReportGuid"
        showOpenButton="true"
    ></linked-finding-report>
</div>
