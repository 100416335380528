import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    OnInit,
} from "@angular/core";
import { WorkflowStepPermissionModel } from "../../shared/models/workflow-step-permission-model";
import { EvidenceSampleEditModel } from "../../shared/models/evidence-sample-edit.model";
import { EvidenceTestStepEditModel } from "../../shared/models/evidence-test-step-edit-model";
import { EvidenceSampleEditorComponent } from "./sample-editor/adv-eff-evidence-sample-editor.component";
import { EvidenceTestStepEditorComponent } from "./test-step-editor/adv-eff-evidence-test-step-editor.component";
import { Observable, firstValueFrom, forkJoin } from "rxjs";
import { scrollToElement } from "@app/shared/helpers/scroll-helper";
import { map } from "rxjs/operators";
import { ValidationModel } from "../../shared/models/validation-model";
import { AdvEffDataService } from "../../shared/services";
import { SelectOptionColorModel } from "@models/generic";

@Component({
    selector: "adv-eff-evidence",
    templateUrl: "./adv-eff-evidence.component.html",
    styleUrls: ["./adv-eff-evidence.component.scss"],
})
export class EvidenceComponent implements OnInit {
    @Input() useReviewer = false;
    @Input() workflowStepPermission = new WorkflowStepPermissionModel();
    @Input() evidenceSamples: EvidenceSampleEditModel[];
    @Input() showAllFields: boolean;

    @Output() sampleEditRemark = new EventEmitter<EvidenceSampleEditModel>();

    @ViewChildren("editorComponent") editorComponents: QueryList<
        EvidenceSampleEditorComponent | EvidenceTestStepEditorComponent
    >;
    @ViewChildren("sampleEditorComponent")
    sampleEditorComponents: QueryList<EvidenceSampleEditorComponent>;
    @ViewChildren("testStepEditorComponent")
    testStepEditorComponents: QueryList<EvidenceTestStepEditorComponent>;

    protected sampleScores: SelectOptionColorModel<number>[];
    protected testStepScores: SelectOptionColorModel<number>[];

    protected get finishedLoading(): boolean {
        return (
            !!this.evidenceSamples &&
            !!this.workflowStepPermission &&
            !!this.sampleScores &&
            !!this.testStepScores
        );
    }

    isAllExpanded = false;

    constructor(private dataService: AdvEffDataService) {}

    async ngOnInit(): Promise<void> {
        const result = await firstValueFrom(
            forkJoin({
                sampleScores: this.dataService.getSampleScores(),
                testStepScores: this.dataService.getTestStepScores(),
            })
        );

        this.sampleScores = result.sampleScores;
        this.testStepScores = result.testStepScores;
    }

    toggleEditors() {
        const collapse = this.isAllExpanded;
        for (const sample of this.evidenceSamples) {
            sample.showEditor = !collapse;
            if (sample.showEditor) {
                this.updateSampleScoreDisable(sample.guid);
            }
            for (const testStep of sample.testSteps) {
                testStep.showEditor = !collapse;
            }
        }

        this.isAllExpanded = this.evidenceSamples.every(
            (s) => s.showEditor && s.testSteps.every((ts) => ts.showEditor)
        );
    }

    toggleEditor(evidenceItem: EvidenceSampleEditModel | EvidenceTestStepEditModel) {
        evidenceItem.showEditor = !evidenceItem.showEditor;
        if (evidenceItem instanceof EvidenceSampleEditModel) {
            if (evidenceItem.showEditor) {
                this.updateSampleScoreDisable(evidenceItem.guid);
            }

            for (const testStep of evidenceItem.testSteps) {
                testStep.showEditor = evidenceItem.showEditor;
            }
        }

        this.isAllExpanded = this.evidenceSamples.every(
            (s) => s.showEditor && s.testSteps.every((ts) => ts.showEditor)
        );
    }

    onSampleEditRemarkClick(sampleModel: EvidenceSampleEditModel, event) {
        if (!this.workflowStepPermission.isEditSampleRemarkAllowed) {
            return;
        }

        event.stopPropagation();

        this.sampleEditRemark.emit(sampleModel);
    }

    validateEvidence(): ValidationModel {
        let isValid = true;
        let errors: string[] = [];
        this.editorComponents.forEach((c) => {
            let validation = c.isValid();
            if (c.isDirty() && !validation.valid) {
                isValid = false;
                validation.errors.forEach((e) => errors.push(e));
                c.markTouched();
            }
        });

        return { valid: isValid, errors: errors };
    }

    saveChanges(): Observable<EvidenceSampleEditModel | EvidenceTestStepEditModel>[] {
        const saveFuncs: Observable<EvidenceSampleEditModel | EvidenceTestStepEditModel>[] = [];

        // First save test steps to make sure there score is saved first for validation purpose
        this.testStepEditorComponents.forEach((c) => {
            if (c.isDirty() && c.isValid().valid) {
                saveFuncs.push(c.saveChanges());
            }
        });
        this.sampleEditorComponents.forEach((c) => {
            if (c.isDirty() && c.isValid().valid) {
                saveFuncs.push(
                    c.saveChanges().pipe(
                        map((sample) => {
                            this.updateSampleScoreDisable(sample.guid);
                            return sample;
                        })
                    )
                );
            }
        });

        return saveFuncs;
    }

    scrollToFirstInvalidComponent() {
        const firstInvalidComponent = this.editorComponents.find(
            (c) => c.isDirty() && !c.isValid()
        );
        if (firstInvalidComponent) {
            firstInvalidComponent.evidenceItem.showEditor = true;
            scrollToElement(firstInvalidComponent.container.nativeElement);
        }
    }

    isDirty() {
        return this.editorComponents.some((c) => c.isDirty());
    }

    onTestStepScoreChange(sampleGuid: string) {
        this.updateSampleScoreDisable(sampleGuid);
    }

    /**
     * Hack to enable sample score when all test steps under it have a score
     * Could perhaps be implemented better when document component supports form validation with disable
     */
    private updateSampleScoreDisable(sampleGuid: string) {
        if (this.workflowStepPermission.testerAssessmentEnabled) {
            const sampleComponent = this.sampleEditorComponents.find(
                (sc) => sc.evidenceItem.guid === sampleGuid
            );
            const testStepComponents = this.testStepEditorComponents.filter(
                (tsc) => tsc.evidenceItem.sampleGuid === sampleComponent.evidenceItem.guid
            );

            const assessmentAllowed =
                testStepComponents.length === 0 ||
                testStepComponents.every((tsc) => tsc.mainFormGroup.controls.score.value > 0);
            sampleComponent.enableTesterAssessment(assessmentAllowed);
        }
    }
}
