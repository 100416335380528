import { Component, OnChanges, SimpleChanges } from "@angular/core";
import { AbstractEffTestingStatusComponent } from "../abstract-eff-testing-status-component";
import { EffectivenessStatusType, SampleScore, TestStepScore } from "../../../shared/enums";
import { EffTableColumnConfig } from "../../common/eff-table/models/eff-table-column-config.model";
import { EffTableConfig } from "../../common/eff-table/models/eff-table-config.model";
import { EvidenceSampleTestStep } from "../models/evidence-sample-step.model";
import { PopupActionResult } from "../../common/popups/models/popup-action-result.model";
import { FinalConclusion } from "../components/eff-final-conclusion-box/models/final-conclusion.model";

@Component({
    selector: "eff-testing-completed",
    templateUrl: "./eff-testing-completed.component.html",
    styleUrls: ["./eff-testing-completed.component.scss"],
})
export class EffTestingCompletedComponent
    extends AbstractEffTestingStatusComponent
    implements OnChanges
{
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.effectivenessModel) {
            this.isNonOccurrence =
                this.effectivenessModel.status === EffectivenessStatusType.nonOccurrenceCompleted;
        }
    }
    protected loadSamples: boolean = true;

    isNonOccurrence = false;

    tableConfig: EffTableConfig = {
        ExpandableRow: true,
        SeparateRows: true,
        RowClassFunc(item: EvidenceSampleTestStep) {
            if (item.isSample) {
                return "sample-row";
            }
            return "";
        },
    };

    displayedColumns: Array<EffTableColumnConfig> = [
        { PropertyName: "name", DisplayedName: "Name", IsTemplate: true, Width: "25%" },
        {
            PropertyName: "scoreTester",
            DisplayedName: "Score tester",
            IsTemplate: true,
            Width: "25%",
        },
        {
            PropertyName: "testerComment",
            DisplayedName: "Comment tester",
            IsTemplate: true,
            Width: "25%",
        },
        {
            PropertyName: "reviewerComment",
            DisplayedName: "Reviewer Comment",
            IsTemplate: true,
            Width: "25%",
        },
    ];

    hideReopenButton() {
        return !(this.isNonOccurrence
            ? this.stepPermissions.nonOccurrenceCompleteAllowed
            : this.stepPermissions.completeAllowed);
    }

    hasEvidence(item: EvidenceSampleTestStep) {
        return item.documents?.length > 0;
    }

    getScoreColor(item: EvidenceSampleTestStep) {
        if (!item.isSample) {
            switch (item.stepScore) {
                case TestStepScore.correct:
                    return "score-correct";
                case TestStepScore.inapplicable:
                    return "score-inapplicable";
                case TestStepScore.incorrect:
                    return "score-incorrect";
                case TestStepScore.missingEvidence:
                    return "score-missing-evidence";
                case TestStepScore.noScore:
                    return "no-score";
            }
        } else {
            switch (item.sampleScore) {
                case SampleScore.sufficient:
                    return "score-correct";
                case SampleScore.inapplicable:
                    return "score-inapplicable";
                case SampleScore.insufficient:
                    return "score-incorrect";
                case SampleScore.noScore:
                    return "no-score";
            }
        }
    }

    async reopenTestPlan() {
        if (!this.stepPermissions.isCompleteAllowed) {
            return;
        }

        if (this.isNonOccurrence) {
            var result = await this._dialogService.showConfirmationDialog(
                "Reopen non occurrence test plan",
                "Are you sure you want to reopen this non occurrence test plan?"
            );

            if (result == PopupActionResult.OK) {
                this._workflowDs
                    .postWorkflowNonOccurrenceReopen(this.effectivenessModel.guid)
                    .subscribe(() => {
                        this.cerrixTab.refresh();
                    });
            }
        } else {
            this.giveFinalConclusion(
                "Reopen test plan",
                "Confirm",
                null,
                ((finalConclusion: FinalConclusion) => {
                    this._workflowDs
                        .postWorkflowReopen(this.effectivenessModel.guid, {
                            comment: finalConclusion.conclusion,
                            scoreId: finalConclusion.score,
                        })
                        .subscribe(() => {
                            this.cerrixTab.refresh();
                        });
                }).bind(this),
                "600px",
                true
            );
        }
    }
}
