<div class="detail-page-card">
    <div class="detail-page-card-title">Details</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="form-group-styling" [formGroup]="mainFormGroup" *ngIf="mainFormGroup">
            <div class="row centered-row">
                <div class="col-12 text-start">
                    <div class="form-group">
                        <label>Name </label>
                        <input
                            class="form-control form-control-sm"
                            name="nameInput"
                            formControlName="Name"
                            [required]="true"
                            placeholder="Required"
                            inputTooltip
                        />
                    </div>
                    <div class="form-group">
                        <cerrix-checkbox
                            formControlName="required"
                            label="Required"
                            fontsize="0.9em"
                        >
                        </cerrix-checkbox>
                    </div>
                    <div class="form-group">
                        <cerrix-checkbox formControlName="visible" label="Visible" fontsize="0.9em">
                        </cerrix-checkbox>
                    </div>
                    <div class="form-group">
                        <label>Field type </label>
                        <ng-select
                            bindValue="ID"
                            bindLabel="Name"
                            formControlName="fieldType"
                            [required]="true"
                            [items]="fieldTypes"
                            [clearable]="false"
                            [multiple]="false"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>

            <div *ngIf="showEditor">
                <generic-list-manager [config]="listConfig"></generic-list-manager>
            </div>
        </div>
    </div>
</div>
