import { StructureValidationModel } from "@models/generic/StructureValidationModel";

export class StructureValidationHelper {
    static getOrganizationValidationMsg(validations: StructureValidationModel): string {
        const invalidElements: string[] = [];
        if (validations.invalidControls.length > 0) {
            invalidElements.push("controls");
        }

        if (validations.invalidBusinessDimensions.length > 0) {
            invalidElements.push("business dimensions");
        }

        if (validations.invalidFrameworkDimensions.length > 0) {
            invalidElements.push("framework dimensions");
        }

        let validationMsg = "Some selected ";
        invalidElements.forEach((validation, index) => {
            validationMsg +=
                index === 0
                    ? validation
                    : index < invalidElements.length - 1
                    ? `, ${validation}`
                    : ` and ${validation}`;
        });

        validationMsg +=
            " cannot be applied to the selected organization. \n \n" +
            "Press 'Confirm' to remove non-allowed elements. \n " +
            "Press 'Cancel' to reset to previous organization";

        return validationMsg;
    }
}
