<div *ngIf="model">
    <div *ngIf="isLoading">
        <div class="fancy-loader"></div>
    </div>
    <div *ngIf="!isLoading">
        <risk-linkable
            #linkable
            displayValueProp="name"
            [(linked)]="linked"
            [(unlinked)]="unlinked"
            [hideActions]="true"
            [orderByValueProperty]="true"
            [disabled]="true"
        >
        </risk-linkable>
    </div>
</div>
