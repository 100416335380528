import { Component, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { ControlDataService } from "../services/control.data.service";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { Observable } from "rxjs";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { LinkableIconConfig } from "@app/shared/models/Linkable/LinkableIconConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { nameof } from "@methods/jeffs-toolkit";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { ControlConfigModel } from "../models/ControlConfigModel";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "control-linkable",
    templateUrl: "./control-linkable.component.html",
    styleUrls: ["./control-linkable.component.scss"],
})
export class ControlLinkableComponent extends ItemLinkableBase {
    @Input() parentModuleType: ModuleType;
    @Input() parentId: number;
    @Input() parentOrganizationId: number;
    @Input() parentBusinessDimensions: number[];
    @Input() parentFrameworkDimensions: number[];

    @Input() hidden: boolean;

    hasAccessChecks = {};
    accessCheckInProgress = false;

    rowIconConfig: LinkableIconConfig[] = [
        {
            icon: "fas fa-key",
            actionColumn: nameof<ControlLinkModel>((c) => c.isKeyControl),
            actionValue: true,
        },
        {
            icon: "fas fa-share-alt",
            actionColumn: nameof<ControlLinkModel>((c) => c.isShared),
            actionValue: true,
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            type: RendererType.Score,
            textColumn: "Conclusion score",
            actionColumn: "Conclusion score color",
            hideActionColumn: true,
        },
    ];

    constructor(
        protected _toastr: ToastrService,
        protected _promptService: CerrixPromptService,
        private _controlDS: ControlDataService,
        private _pages: Pages,
        private _tabService: TabService
    ) {
        super(_toastr, _promptService);
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._controlDS.hasAccess(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.ControlDetail,
                item.guid,
                `(C) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this Control.");
        }
    }

    addClicked() {
        const config = <ControlConfigModel>{};

        if (this.parentModuleType == ModuleType.ORM) {
            config.riskId = this.parentId;
            config.organizationId = this.parentOrganizationId;
            config.businessIds = this.parentBusinessDimensions;
            config.frameworkIds = this.parentFrameworkDimensions;
        }

        this._tabService.generateTab(this._pages.ControlDetail, null, "Add new Control", config);
    }
}
