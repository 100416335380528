import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from '@app/forms/models/detail/FormField';
import { FormFieldType } from '@app/forms/enums/FormFieldTypes';

@Component({
    selector: "form-entry-text",
    templateUrl: "./form-entry-text.component.html",
    styleUrls: ["../../form-entry-field.component.scss"]
})
export class FormEntryTextComponent implements OnInit {
    @Input() field: FormField;
    @Input() readonly: boolean;
    @Input() answer: string;
    @Output() answerChange: EventEmitter<string> = new EventEmitter<string>();
    isArea: boolean;

    constructor() {}
    ngOnInit() {
        this.isArea = this.field.FieldType === FormFieldType.TextArea;
    }

    fieldChange() {
        this.answerChange.emit(this.answer);
    }
}