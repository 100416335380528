import {
    HttpClient,
    HttpHeaders,
    HttpRequest,
    HttpEvent,
    HttpContext,
    HttpParams,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Configuration } from "@app/app.constants";
import { DocumentModel } from "@models/documents/documentmodel";
import { toPromise } from "@methods/CommonMethods";

export abstract class ApiDataService {
    protected actionUrl: string;

    constructor(protected http: HttpClient, configuration: Configuration, actionUrl: string) {
        this.actionUrl = `${configuration.ApiUrl}${actionUrl}`;
        this.validateApiUrl(this.actionUrl);
    }

    protected getString(alternativeUrl: string = null): Observable<string> {
        const url = this.formatApiUrl(alternativeUrl);
        return this.http.get(url, { responseType: "text" }).pipe(catchError(this.handleError));
    }

    protected get<T>(alternativeUrl: string = null): Observable<T> {
        const url = this.formatApiUrl(alternativeUrl);
        return this.http.get<T>(url).pipe(catchError(this.handleError));
    }

    protected post<T>(model: any, alternativeUrl: string = null): Observable<T> {
        const url = this.formatApiUrl(alternativeUrl);
        return this.http.post<T>(url, model).pipe(catchError(this.handleError));
    }

    protected postString(model: any, alternativeUrl: string = null): Observable<string> {
        const url = this.formatApiUrl(alternativeUrl);
        return this.http
            .post(url, model, { responseType: "text" })
            .pipe(catchError(this.handleError));
    }

    protected request<T>(
        formData: any,
        alternativeUrl: string = null,
        options: Partial<{
            headers?: HttpHeaders;
            context?: HttpContext;
            reportProgress?: boolean;
            params?: HttpParams;
            responseType?: "arraybuffer" | "blob" | "json" | "text";
            withCredentials?: boolean;
        }> = null
    ): Observable<HttpEvent<T>> {
        const url = this.formatApiUrl(alternativeUrl);

        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");

        if (!options) {
            options = {};
        }
        options.headers = headers;

        const uploadReq = new HttpRequest("POST", url, formData, options);

        return this.http.request<T>(uploadReq).pipe(catchError(this.handleError));
    }

    protected postWithDocuments<T>(
        model: any,
        documents: DocumentModel[],
        alternativeUrl: string = null
    ): Observable<T> {
        const url = this.formatApiUrl(alternativeUrl);

        const formData = this.getFormDataWithDocuments(model, documents);

        return this.http.post<T>(url, formData).pipe(catchError(this.handleError));
    }

    protected put(model: any, alternativeUrl: string = null): Observable<never> {
        const url = this.formatApiUrl(alternativeUrl);

        return this.http.put<never>(url, model).pipe(catchError(this.handleError));
    }

    protected putWithDocuments(
        model: any,
        documents: DocumentModel[],
        alternativeUrl: string = null
    ): Observable<never> {
        const url = this.formatApiUrl(alternativeUrl);

        const formData = this.getFormDataWithDocuments(model, documents);

        return this.http.put<never>(url, formData).pipe(catchError(this.handleError));
    }

    protected delete(alternativeUrl: string = null) {
        const url = this.formatApiUrl(alternativeUrl);

        return this.http.delete<never>(url).pipe(catchError(this.handleError));
    }

    protected download(alternativeUrl: string = null) {
        const url = this.formatApiUrl(alternativeUrl);

        // Firefox doesn't close tab from hyperlink
        if (navigator.userAgent.includes("Firefox")) {
            window.open(
                url,
                "Download",
                "width=600,height=100,location=0,resizable=1,menubar=0,status=0"
            );
        } else {
            this.createDownload(url);
        }
    }

    protected async downloadFromPost(model: any, alternativeUrl: string): Promise<void> {
        const url = this.formatApiUrl(alternativeUrl);
        const file = await toPromise(
            this.http
                .post(url, model, { observe: "response", responseType: "blob" })
                .pipe(catchError(this.handleError))
        );

        const disposition = file.headers.get("Content-Disposition");
        let filename = "";
        if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
            }
        }
        const objectUrl = window.URL.createObjectURL(file.body);

        this.createDownload(objectUrl, filename);
    }

    private createDownload(url: string, filename: string = null) {
        const downloadLink = document.createElement("a");
        if (filename) {
            downloadLink.download = filename;
        }
        downloadLink.href = url;
        downloadLink.target = "_blank";
        downloadLink.setAttribute("type", "hidden");

        // Some browsers require the element to be part of the DOM
        document.body.appendChild(downloadLink);

        downloadLink.click();
        downloadLink.remove();
    }

    private formatApiUrl(alternativeUrl: string): string {
        if (alternativeUrl) {
            this.validateApiUrl(alternativeUrl);
        }

        let url = this.actionUrl;
        if (alternativeUrl) {
            url += alternativeUrl;
        }

        return url;
    }

    private validateApiUrl(url: string): void {
        if (url.endsWith("/")) {
            throw new Error("API url may not end with '/'");
        }
    }

    private getFormDataWithDocuments(model: any, documents: DocumentModel[]): FormData {
        const formData = new FormData();
        formData.append("model", JSON.stringify(model));
        if (documents) {
            for (const documentModel of documents) {
                if (documentModel.File) {
                    formData.append(documentModel.Guid, documentModel.File);
                }
            }
        }

        return formData;
    }

    private handleError(error: any): Observable<never> {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        console.error(errorMessage);
        return throwError(error);
    }
}
