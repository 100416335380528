<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    [module]="module"
    [moduleType]="moduleType"
    [appendToModuleType]="appendToModuleType"
    [idProp]="idProp"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [NumberProperties]="numberProps"
    [DateProperties]="dateProps"
    (onDblClick)="openDetail($event)"
    (onAddClick)="add()"
>
</cerrix-workspace>
