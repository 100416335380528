<div class="cerrix-drop-area" cerrixFileDrop (fileDropped)="fileDropHandler($event)">
    <mat-icon class="upload-icon" fontSet="material-symbols-outlined">cloud_upload</mat-icon>
    <span class="drop-label">Drop files here to attach or</span> &nbsp;
    <span class="browse-button" (click)="openFileSelect()">browse</span>
</div>

<input
    type="file"
    id="fileUpload"
    [accept]="allowedFileExtensions"
    [multiple]="true"
    class="cdk-visually-hidden"
    (change)="filesSelection()"
    #fileInput
/>
