<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<dashboard-manager [cerrixTab]="tab" (load)="loadDashboard($event)"></dashboard-manager>

<div class="main-dashboard" *ngIf="!tab.showLoader && dashboard">
    <div class="detail-page-action-bar" data-uiid="dashboard">
        <!-- Non Edit Mode toolbar -->
        <ng-container *ngIf="!editMode">
            <div class="dashboard-name">
                <cerrix-icon-viewer [icon]="dashboard.icon"></cerrix-icon-viewer>
                <span *ngIf="!dashboard.name">New Dashboard</span>
                <span *ngIf="dashboard.name">
                    {{
                        dashboard.name.length > 25
                            ? (dashboard.name | slice : 0 : 25) + "..."
                            : dashboard.name
                    }}
                </span>
            </div>

            <div class="seperator"></div>

            <button
                class="btn btn-secondary-outline"
                [disabled]="editMode"
                (click)="globalFilterComp.open()"
            >
                <i class="fad fa-filter"></i>
                <span> Filter </span>
            </button>

            <div class="right-actions">
                <button
                    class="button colored"
                    (click)="dashboard.api.save(true)"
                    *ngIf="dashboard._unsavedChanges"
                >
                    <i class="fad fa-save"></i>
                    <span class="d-none d-lg-inline">Save changes</span>
                </button>

                <button class="button colored" (click)="toggleEdit()" *ngIf="dashboard.canEdit">
                    <i class="far fa-pencil"></i>
                </button>

                <div class="seperator" *ngIf="dashboard._unsavedChanges"></div>

                <button
                    class="button colored"
                    (click)="loadDashboard(dashboard)"
                    *ngIf="dashboard.guid"
                >
                    <i class="far fa-sync"></i>
                </button>

                <button
                    class="button colored"
                    (click)="dashboard.api.setDefault()"
                    *ngIf="dashboard.guid"
                >
                    <i class="{{ dashboard.default ? 'fas' : 'far' }} fa-house"></i>
                </button>

                <button
                    class="button"
                    [disabled]="editMode || dashboard.default"
                    (click)="dashboard.api.toggleFavorite()"
                    *ngIf="dashboard.guid && !editMode"
                >
                    <i class="{{ dashboard.favorite ? 'fas orange' : 'far colored' }} fa-star"></i>
                </button>
            </div>
        </ng-container>

        <!-- Edit Mode toolbar -->
        <ng-container *ngIf="editMode">
            <button class="btn btn-cerrix" (click)="toggleEdit()">
                <i class="far fa-check"></i>
                <span class="d-none d-lg-inline">Done Editing</span>
            </button>

            <div class="right-actions right-actions-editmode">
                <button
                    class="btn btn-secondary-outline"
                    [disabled]="showMarket"
                    (click)="openMarket()"
                >
                    <i class="fad fa-layer-plus"></i>
                    <span class="d-none d-lg-inline">Add widget</span>
                </button>

                <button
                    class="btn btn-secondary-outline"
                    [disabled]="showMainConfig"
                    (click)="openMainSettings()"
                >
                    <i class="fad fa-cogs"></i>
                    <span class="d-none d-lg-inline">Settings</span>
                </button>
            </div>
        </ng-container>

        <global-filtering
            [widgets]="widgets"
            [filterValues]="dashboard.globalFilterValues"
            #globalFilterComp
        ></global-filtering>
    </div>

    <div class="window-splitter">
        <gridster
            [options]="gridster"
            [class.configopen]="showConfigFor"
            [class.isediting]="editMode"
            (scroll)="onScroll($event)"
        >
            <gridster-item
                *ngFor="let widget of widgets"
                [item]="widget.customConfig.gridsterConfig"
                [class.bringtofront]="showConfigFor == widget"
            >
                <dashboard-widget
                    [config]="widget"
                    [globalFilters]="dashboard.globalFilterValues"
                ></dashboard-widget>
                <div class="edit-overlay" *ngIf="editMode">
                    <div class="edit-buttons">
                        <button class="button" (click)="openConfig(widget, $event)">
                            <i class="far fa-cog fa-fw"></i>
                        </button>
                        <button class="button red" (mousedown)="removeItem(widget, $event)">
                            <i class="far fa-trash fa-fw"></i>
                        </button>
                    </div>
                </div>
            </gridster-item>
        </gridster>

        <div class="side-menu" *ngIf="showMainConfig || showMarket || showConfigFor">
            <div class="side-menu-content">
                <dashboard-configuration
                    [config]="dashboard"
                    (change)="changedOptions()"
                    *ngIf="showMainConfig"
                ></dashboard-configuration>

                <widget-market *ngIf="showMarket"></widget-market>

                <widget-configuration
                    [config]="showConfigFor"
                    *ngIf="showConfigFor"
                ></widget-configuration>
            </div>
            <div class="side-menu-buttons">
                <button class="btn btn-cerrix" (click)="hidePopup()">Done</button>
            </div>
        </div>
    </div>
</div>
