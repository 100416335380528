export enum JobTypes {
    Unknown = 0,
    SchedulerStatusLastChecked = 1010, // 000_001_010
    SchedulerBasicTest = 1100, // 000_001_100
    ExportAdvancedEffectivenessChanges = 1021010, // 001_021_010
    DocumentBulkDelete = 2000000, // 002_000_000
    ImportRisks = 2010010, // 002_010_010,
    ImportControlTestplans = 2020010, //002_020_010
    ImportControls = 2020011, //002_020_011

    AiDocumentExtractor = 100000001, //100_000_001
}

export function getJobTypeDescription(type: JobTypes) {
    switch (type) {
        case JobTypes.SchedulerStatusLastChecked: {
            return "Scheduler Heartbeat";
        }
        case JobTypes.SchedulerBasicTest: {
            return "Basic Test";
        }
        case JobTypes.ExportAdvancedEffectivenessChanges: {
            return "Export Advanced Effectiveness Changes";
        }
        case JobTypes.DocumentBulkDelete: {
            return "Document Bulk Delete";
        }
        case JobTypes.ImportRisks: {
            return "Risk Import";
        }
        case JobTypes.ImportControlTestplans: {
            return "Effectiveness Import";
        }
        case JobTypes.ImportControls: {
            return "Controls Import";
        }
        case JobTypes.AiDocumentExtractor: {
            return "AI Document Processing";
        }
        default: {
            return "Unknown";
        }
    }
}
