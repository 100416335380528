<div class="sorting-loading" *ngIf="editMode && sortLoading">
    <div class="fancy-loader"></div>
    <div class="sort-message">
        <div class="title">{{ sortMessage }}</div>
        <div class="sub-title" *ngIf="config.sortMessageSubtitle">
            {{ config.sortMessageSubtitle }}
        </div>
    </div>
</div>
<div class="detail-page-card">
    <div class="detail-page-card-title">{{ name }}</div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" *ngIf="!config.disableSort" (click)="toggleSortMode()">
            <i class="fas fa-sort me-2"></i>{{ editMode ? "Complete" : "Change" }} order
        </button>
        <div class="spacer" *ngIf="!config.disableSort"></div>
        <button
            type="button"
            class="btn btn-raised export-button"
            (click)="exportClick()"
            [disabled]="isGeneratingExport"
            title="Export"
        >
            <i class="fas fa-circle-notch fa-spin" *ngIf="isGeneratingExport"></i>
            <i class="fas fa-file-excel" aria-hidden="true" *ngIf="!isGeneratingExport"></i>
        </button>
        <button
            class="btn btn-raised green"
            *ngIf="!editMode && !config.disableAdd"
            (click)="addClick(null)"
        >
            <i class="fas fa-plus me-2"></i>Add
        </button>
    </div>
    <div class="detail-page-card-actions search-actions" *ngIf="!editMode">
        <input
            type="text"
            placeholder="Search"
            class="form-control form-control-sm"
            [(ngModel)]="searchFilter"
            inputTooltip
        />

        <button class="btn btn-outline-secondary btn-sm search-button" (click)="search()">
            <i class="fas fa-search"></i>
        </button>
        <button
            class="btn btn-outline-secondary btn-sm search-reset-button"
            (click)="resetSearchFilter()"
        >
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="detail-page-card-body" *ngIf="dataLoading">
        <div class="fancy-loader submodule"></div>
    </div>
    <div class="detail-page-card-body" *ngIf="!dataLoading">
        <div
            class="item"
            *ngFor="let item of editMode ? data : searchedData"
            (click)="itemClick(item)"
            [class.selected]="selected && selected === item.ID"
            [class.dropzone]="editMode && item.highlight"
            [class.dropabove]="editMode && dropAbove"
            [class.dropbelow]="editMode && dropBelow"
            [class.moving]="editMode && item.show === false"
            [class.just-moved]="editMode && item.newlocation"
            [class.selected]="!editMode && item.ID === selected"
            [class.filterhighlight]="!editMode && item.highlight"
            [class.filterhide]="!editMode && item.show === false"
            [draggable]="editMode"
            (dragstart)="dragStart(item, $event)"
            (dragend)="dragStop(item, $event)"
            (dragover)="dragOver($event)"
            (drop)="dragDrop(item, $event)"
            (dragenter)="dragEnter(item)"
        >
            {{ item.Name }}
        </div>
    </div>
</div>
