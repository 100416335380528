import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AuditUniverseRiskDataModel } from "@app/audit-universe/shared/data-models/audit-universe-risk-data-model";
import { AuditUniverseModuleDataRequestModel } from "@app/audit-universe/shared/request-models/audit-universe-module-data-request-model";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { nameof } from "@methods/jeffs-toolkit";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { PermissionsService } from "./../../../../../../services/permissions/PermissionsService";
import { NavigatorConfigType } from "./../../../../../navigators/enums/NavigatorConfigType";
import { GenericListConfig } from "./../../../../../shared/models/GenericList/GenericList";
import { AuditUniverseModuleDataService } from "./../../../../services/audit-universe-module-data.service";

@Component({
    selector: "audit-universe-data",
    templateUrl: "./audit-universe-data.component.html",
    styleUrls: ["./audit-universe-data.component.scss"],
})
export class AuditUniverseDataComponent implements OnInit, OnChanges {
    @Input() organizations: number[];
    @Input() businessDimensions: number[];
    @Input() frameworkDimensions: number[];
    @Input() dateFilter: Date;

    @Input() manualMode = false;

    listConfigs: (AuditUniverseRiskDataModel | GenericListConfig)[];

    ready = false;
    private _loadedOrganizations: number[];
    private _loadedBusinessDimensions: number[];
    private _loadedFrameworkDimensions: number[];
    private _loadedDateFilter: Date;

    constructor(
        private _ds: AuditUniverseModuleDataService,
        private _configFactory: NavGenericListFactory,
        private _permissionService: PermissionsService
    ) {}

    ngOnInit() {
        this.createConfigs();

        if (!this.manualMode) {
            this.load();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.manualMode) {
            return;
        }

        if (
            changes[nameof<AuditUniverseDataComponent>((x) => x.organizations)] ||
            changes[nameof<AuditUniverseDataComponent>((x) => x.businessDimensions)] ||
            changes[nameof<AuditUniverseDataComponent>((x) => x.frameworkDimensions)] ||
            changes[nameof<AuditUniverseDataComponent>((x) => x.dateFilter)]
        ) {
            this.load();
        }
    }

    load() {
        // Initial load
        if (!this.ready) {
            this.setLoadProperties();
            this.ready = true;
            return;
        }

        if (
            !this.organizations.isDifferent(this._loadedOrganizations) &&
            !this.businessDimensions.isDifferent(this._loadedBusinessDimensions) &&
            !this.frameworkDimensions.isDifferent(this._loadedFrameworkDimensions) &&
            this.dateFilter === this._loadedDateFilter
        ) {
            return;
        }

        this.setLoadProperties();
        if (this.listConfigs) {
            this.listConfigs.forEach((config) => {
                if (config instanceof GenericListConfig) {
                    config.reload();
                }
            });
        }
    }

    private setLoadProperties() {
        this._loadedOrganizations = JSON.parse(JSON.stringify(this.organizations));
        this._loadedBusinessDimensions = JSON.parse(JSON.stringify(this.businessDimensions));
        this._loadedFrameworkDimensions = JSON.parse(JSON.stringify(this.frameworkDimensions));
        this._loadedDateFilter = this.dateFilter;
    }

    private createConfigs() {
        this.listConfigs = [];
        let permissions = this._permissionService.permissions;

        if (permissions.HasRiskAccess) {
            const matrixConfig = <AuditUniverseRiskDataModel>{
                grossMatrixModel: null,
                netMatrixModel: null,
            };

            this.listConfigs.push(matrixConfig);
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.Risk, () => {
                    return this._ds.getRiskData(this.getRequestModel()).pipe(
                        tap((model) => {
                            matrixConfig.grossMatrixModel = model.grossMatrixModel;
                            matrixConfig.netMatrixModel = model.netMatrixModel;
                        }),
                        map((model) => model.data)
                    );
                })
            );
        }

        if (permissions.HasControlAccess) {
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.Control, () => {
                    return this._ds.getControlData(this.getRequestModel());
                })
            );
        }

        if (permissions.HasEventAccess) {
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.Event, () => {
                    return this._ds.getEventData(this.getRequestModel());
                })
            );
        }

        if (permissions.HasMoiAccess) {
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.Moi, () => {
                    return this._ds.geMoiData(this.getRequestModel());
                })
            );
        }

        if (permissions.HasKriAccess) {
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.Kri, () => {
                    return this._ds.getKriData(this.getRequestModel());
                })
            );
        }

        if (permissions.HasDataManagementAccess) {
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.DataProcessing, () => {
                    return this._ds.getDataManagementData(this.getRequestModel());
                })
            );
        }

        if (permissions.HasThirdPartyAccess) {
            this.listConfigs.push(
                this.getListConfig(NavigatorConfigType.ThirdParty, () => {
                    return this._ds.getThirdPartyData(this.getRequestModel());
                })
            );
        }
    }

    private getListConfig(type: NavigatorConfigType, dataMethod: () => Observable<any[]>) {
        const config = this._configFactory.createConfig(type);

        config.dataMethod = dataMethod;
        config.hideRefreshButton = false;
        config.showToolbarSearch = true;
        config.isSortableByColumn = true;

        return config;
    }

    private getRequestModel(): AuditUniverseModuleDataRequestModel {
        return {
            organizations: this._loadedOrganizations,
            businessDimensions: this._loadedBusinessDimensions,
            frameworkDimensions: this._loadedFrameworkDimensions,
            dateFilter: this._loadedDateFilter,
        };
    }
}
