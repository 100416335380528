import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { AuditThirdPartyComponent } from "@app/audit/pages/audit-detail/components/audit-thirdparty/audit-thirdparty.component";
import { AuditWorkflowDataService } from "@app/audit/services/audit-workflow-data.service";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditWorkflowButtonConfig } from "@app/audit/shared/data-models/audit-workflow-button-config";
import { AuditDetailStandingDataModel } from "@app/audit/shared/page-models/audit-detail-standing-data-model";
import { isGuid } from "@methods/uniqueMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { AuditDetailAuditeeRequestsComponent } from "../components/audit-detail-auditee-requests/audit-detail-auditee-requests.component";
import { TabMenuItem } from "./../../../../../common/models/generic/TabModels/TabMenuItem";
import { CerrixPromptService } from "./../../../../shared/services/cerrix-prompt.service";
import { AuditDetailPermissionModel } from "./../../../shared/permission-models/audit-detail-permission-model";
import { AuditDetailFieldworkExecutionComponent } from "./../components/audit-detail-fieldwork-execution/audit-detail-fieldwork-execution.component";
import { AuditDetailFindingLinkingComponent } from "./../components/audit-detail-finding-linking/audit-detail-finding-linking.component";
import { AuditDetailRiskAssessmentComponent } from "./../components/audit-detail-risk-assessment/audit-detail-risk-assessment.component";
import { AuditWorkflowUpdateModel } from "@app/audit/shared/data-models/audit-workflow-update-model";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { nameof } from "@methods/jeffs-toolkit";
import { AuditDetailWorkflowHistoryComponent } from "../audit-detail-workflow-history/audit-detail-workflow-history.component";
import { TargetModule } from "@enums/document/TargetModule";
import { CerrixGridColumnConfig } from "@app/shared/models/cerrix-grid-editor-config";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { AuditDetailFindingReportSummaryComponent } from "../audit-detail-finding-report-summary/audit-detail-finding-report-summary.component";
import { AuditUniverseLinkedFormsComponent } from "@app/audit-universe/pages/audit-universe-detail/components/audit-universe-linked-forms/audit-universe-linked-forms.component";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { Subject, takeUntil } from "rxjs";
import { AuditDetailContextComponent } from "../components/audit-detail-context/audit-detail-context.component";
import { getFormControl, getFormValue } from "@methods/CommonMethods";

@Component({
    selector: "auditor-audit-details",
    templateUrl: "./auditor-audit-details.component.html",
    styleUrls: ["./auditor-audit-details.component.scss"],
})
export class AuditorAuditDetailsComponent implements OnInit, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() auditModel: AuditModel;
    @Input() standingData: AuditDetailStandingDataModel;

    @Input() cerrixTab: TabModel;
    @Input() permissions: AuditDetailPermissionModel;
    @Input() isSaved$: Subject<boolean>;

    @Output() onSave = new EventEmitter<never>();

    id: string;
    auditGuid: string;

    buttonConfig: WorkspaceButtonConfig[] = [];

    protected dateModifiedPromise: Promise<Date>;

    workflowButtonConfig: AuditWorkflowButtonConfig;

    targetModule = TargetModule.Audit;
    extraDocumentColumns: CerrixGridColumnConfig[] = [
        {
            fieldType: GenericListFieldType.Text,
            fieldName: "ExtraFields.uploadedFrom",
            prettyName: "Uploaded from",
            hidden: false,
        },
    ];

    @ViewChild("fieldworkExecutionComp")
    fieldworkExecutionComp: AuditDetailFieldworkExecutionComponent;

    @ViewChild("riskAssessment") riskAssessmentComp: AuditDetailRiskAssessmentComponent;
    @ViewChild("findings") findingsComp: AuditDetailFindingLinkingComponent;
    @ViewChild("findingReport") findingReportComp: AuditDetailFindingReportSummaryComponent;
    @ViewChild("requests") requestsComp: AuditDetailAuditeeRequestsComponent;
    @ViewChild("context") contextComp: AuditDetailContextComponent;
    @ViewChild("auditThirdParty") auditThirdParty: AuditThirdPartyComponent;
    @ViewChild("formsComponent") formsComponent: AuditUniverseLinkedFormsComponent;
    @ViewChild("auditWorkflowHistory") auditWorkflowHistory: AuditDetailWorkflowHistoryComponent;
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent: HistoryOverviewComponent;

    fieldWorkExecutionTabId = "audit-fieldwork-execution";
    contextTabId = "audit-context";
    riskAssessmentTabId = "audit-risk-assessment";
    findingsTabId = "audit-findings";
    findingReportTabId = "audit-finding-report";
    documentRequestsTabId = "audit-document-requests";
    thirdpartyTabId = "audit-thirdparty";
    protected menuItemForms: string = "menuItemForms";
    workflowHistoryTabId = "audit-workflow-history";
    protected auditHistoryTabId: string = "audit-history";
    readonly destroy$ = new Subject<boolean>();
    positiveButtonClicked: boolean;
    negativeButtonClicked: boolean;
    protected enableDocumentComposing = false;

    private documentRequestsFetched = false;
    private previousAuditees: number[] = [];

    constructor(
        protected auditDataService: AuditDataService,
        private _workflowDs: AuditWorkflowDataService,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService,
        private _pages: Pages,
        private _tabService: TabService
    ) {}

    async ngOnInit() {
        const isExisting = isGuid(this.auditModel.guid);
        if (isExisting) {
            this.auditGuid = this.auditModel.guid;
            this.getWorkflowButtonConfig();
            this.dateModifiedPromise = this.auditDataService.getHistoryLastDate(this.auditGuid);
            this.getExportButtonConfig();
        }

        this.enableDocumentComposing = this.permissions.enableDocumentComposing;

        this.cerrixTab.showLoader = false;

        this.isSaved$.pipe(takeUntil(this.destroy$)).subscribe(async () => {
            const updateModel = <AuditWorkflowUpdateModel>{
                oldStatus: this.workflowButtonConfig.currentStatusId,
            };
            if (this.positiveButtonClicked) {
                await this.updateWorkflow(true, updateModel);
            }
            if (this.negativeButtonClicked) {
                await this.updateWorkflow(false, updateModel);
            }

            this.positiveButtonClicked = false;
            this.negativeButtonClicked = false;
        });

        const auditeesControl = getFormControl<AuditModel>(this.formGroup, "auditees");
        auditeesControl.valueChanges.subscribe(async () => {
            await this.checkAuditeesInUse();
        });

        this.previousAuditees = this.auditModel.auditees || [];
    }

    save() {
        this.notifyChildrenOfSave();

        this.onSave.emit();
    }

    private notifyChildrenOfSave() {
        if (this.contextComp != null) {
            this.contextComp.onSave();
        }

        if (this.riskAssessmentComp) {
            this.riskAssessmentComp.onSave();
        }
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case this.fieldWorkExecutionTabId:
                    this.fieldworkExecutionComp.init();
                    break;
                case this.riskAssessmentTabId:
                    this.riskAssessmentComp.init();
                    break;
                case this.findingsTabId:
                    this.findingsComp.init();
                    break;
                case this.findingReportTabId:
                    this.findingReportComp.load();
                    break;
                case this.documentRequestsTabId:
                    this.requestsComp.init();
                    break;
                case this.contextTabId:
                    this.contextComp.init();
                    break;
                case this.thirdpartyTabId:
                    this.auditThirdParty.load();
                    break;
                case this.menuItemForms:
                    this.formsComponent.load();
                    break;
                case this.workflowHistoryTabId:
                    this.auditWorkflowHistory.load();
                    break;
                case this.auditHistoryTabId:
                    this.historyOverviewComponent.loadHistory();
                    break;
            }
        }
    }

    delete(): void {
        if (this.auditModel.id < 0) {
            return;
        }

        this.auditDataService
            .deleteAudit(this.auditModel.guid)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (validations: string[]) => {
                    if (validations && validations.length > 0) {
                        this._toastr.warning(validations.join("\n\n"));
                    } else {
                        this._toastr.success("Audit deleted");
                        this._tabService.refresh(this._pages.AuditOverview);
                        this.cerrixTab.close(false);
                    }
                },
                error: (error) => {
                    this._toastr.error("", error || error.Message);
                },
            });
    }

    private getExportButtonConfig() {
        const exportButton = new WorkspaceButtonConfig();
        exportButton.text = "Export";
        exportButton.icon = "fas fa-file-export";
        exportButton.clickEvent = () => this.auditDataService.exportAudit(this.auditGuid);

        this.buttonConfig.push(exportButton);
    }

    private getWorkflowButtonConfig() {
        this._workflowDs
            .getWorkflowButtonConfig(this.auditGuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((btnCnf) => (this.workflowButtonConfig = btnCnf));
    }

    public onWorkflowButtonClick(positiveButtonClicked: boolean) {
        this.positiveButtonClicked = positiveButtonClicked;
        this.negativeButtonClicked = !positiveButtonClicked;

        this.save();
    }

    private async updateWorkflow(
        positiveButtonClicked: boolean,
        updateModel: AuditWorkflowUpdateModel
    ) {
        let confirmStatusChange = false;
        if (positiveButtonClicked) {
            updateModel.newStatus = this.workflowButtonConfig.positiveButtonNextStatusId;
            confirmStatusChange = true;
        } else {
            updateModel.newStatus = this.workflowButtonConfig.negativeButtonNextStatusId;
            confirmStatusChange = await this.showNegativeWorkflowPrompt(
                this.workflowButtonConfig.negativeButtonText,
                updateModel
            );
        }

        if (confirmStatusChange) {
            const loader = this._promptService.loader("Updating workflow status.");
            this._workflowDs.updateWorkflow(this.auditGuid, updateModel).subscribe({
                complete: () => {
                    loader.close();
                    this.cerrixTab.refresh();
                },
                error: () => {
                    loader.close();
                },
            });
        }
    }

    private async showNegativeWorkflowPrompt(
        text: string,
        updateModel: AuditWorkflowUpdateModel
    ): Promise<boolean> {
        const commentFieldName = nameof<AuditWorkflowUpdateModel>((x) => x.comment);
        const promptResult = await this._promptService
            .prompt({
                maxHeight: "350px",
                maxWidth: "440px",
                data: {
                    title: text,

                    fields: [
                        {
                            prettyName: text + " reason",
                            fieldName: commentFieldName,
                            fieldType: GenericListFieldType.TextArea,
                            required: true,
                            uiid: "audit-workflow-reject-input",
                        },
                    ],
                    fieldResult: updateModel,
                    confirmButton: {
                        text: text,
                    },
                },
            })
            .toPromise();

        if (promptResult == null || promptResult[commentFieldName] == null) {
            return false;
        }

        updateModel.comment = promptResult[commentFieldName];

        return true;
    }

    private async checkAuditeesInUse() {
        if (this.auditModel.id < 0) {
            return;
        }

        if (!this.documentRequestsFetched && this.requestsComp) {
            const auditeeLoader = this._promptService.loader(
                "Loading document requests for validation."
            );
            try {
                await this.requestsComp.init();
            } finally {
                auditeeLoader.close();
            }

            this.documentRequestsFetched = true;
        }

        if (!this.previousAuditees) {
            this.previousAuditees = [];

            return;
        }

        const currentAuditees = getFormValue<AuditModel>(this.formGroup, "auditees") as number[];
        const auditeesRemoved = this.previousAuditees.filter((x) => !currentAuditees.includes(x));

        if (auditeesRemoved.length === 0) {
            this.previousAuditees = currentAuditees;
            return;
        }

        const auditeesInUse = this.auditModel.documentRequests?.length
            ? this.auditModel.documentRequests.map((x) => x.auditeeId)
            : [];

        const removedAuditees = auditeesRemoved.filter((x) => auditeesInUse.includes(x));
        if (removedAuditees.length === 0) {
            this.previousAuditees = currentAuditees;
            return;
        }

        const confirmResult = await this._promptService
            .confirmCustom({
                maxHeight: "300px",
                maxWidth: "350px",
                data: {
                    title: "Are you sure?",
                    message:
                        "This auditee is still used in a document request but cannot access the request anymore. " +
                        "\n\nDo you want to continue?",
                },
            })
            .toPromise();

        if (!confirmResult) {
            getFormControl<AuditModel>(this.formGroup, "auditees").setValue(this.previousAuditees);
            return;
        }

        this.previousAuditees = currentAuditees;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
