<cerrix-workflow-history-graph *ngIf="workflowHistoryData" [workflowHistory]="workflowHistoryData">
    <ng-template #extraTableHeaders>
        <div>Score</div>
    </ng-template>
    <ng-template #extraTableValues let-row="row">
        <div [title]="row.score">
            <div style="display: flex; flex-flow: row" *ngIf="row.score">
                <div style="display: flex; flex-flow: column; justify-content: center">
                    <div
                        style="
                            min-width: 14px;
                            max-width: 14px;
                            min-height: 14px;
                            max-height: 14px;
                            margin-right: 10px;
                            border-radius: 3px;
                        "
                        [style.background-color]="row.scoreColor"
                    ></div>
                </div>
                <div>{{ row.score }}</div>
            </div>
        </div>
    </ng-template>
</cerrix-workflow-history-graph>
