import { Component, Input, TemplateRef } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "eff-popover",
    templateUrl: "./eff-popover.component.html",
    styleUrls: ["./eff-popover.component.scss"],
})
export class EffPopoverComponent {
    @Input() popoverContentTemplate: TemplateRef<any>;
    @Input() popoverButtonTemplate: TemplateRef<any>;
    @Input() data: any;
    @Input() panelClass: string;

    onClick(event) {
        event.stopPropagation();
    }
}
