import { Component, OnInit } from "@angular/core";
import { AuditUniverseDataService } from "@app/audit-universe/services/audit-universe-data.service";
import { AuditUniverseInfoModel } from "@app/audit-universe/shared/data-models/audit-universe-info-model";
import { Pages } from "@constants/pages/Pages";
import { FilterType } from "@enums/FilterType";
import { FormatType } from "@enums/FormatType";
import { ModuleSubType } from "@enums/ModuleSubType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { toPromise } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "audit-universe-overview",
    templateUrl: "./audit-universe-overview.component.html",
    styleUrls: ["./audit-universe-overview.component.scss"],
})
export class AuditUniverseOverviewComponent implements OnInit {
    moduleType = ModuleType.AUDIT;
    moduleSubType = ModuleSubType.AuditUniverse;

    appendToModuleType = ModuleSubType[ModuleSubType.AuditUniverse];
    tabID: string;
    tab: TabModel;

    data: Promise<any[]>;
    headerLookup: any = {
        identifier: "Identifier",

        name: "Name",

        impact: "Impact",
        riskScore: "Risk Score",
        riskCatalogue: "Risk Catalogue",
        assessment: "Assessment",
        assessmentComment: "Assessment Comment",
        assessmentDate: "Assessment Date",
        assessor: "Assessor",

        organizations: "Organizations",
        businessDimensions: "Business Dimensions",
        frameworkDimensions: "Framework Dimensions",

        dateCreated: "Date Created",
        dateUpdated: "Date Updated",
        lastUpdater: "Last Modifier",
    };

    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: "organizationIds",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: "businessDimensionIds",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },

        {
            label: "Framework Dimensions",
            column: "frameworkDimensionIds",
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "impact",
            actionColumn: "impactColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "riskScore",
            actionColumn: "riskScoreColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "assessment",
            actionColumn: "assessmentColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "dateCreated",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: "dateUpdated",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        },
        {
            textColumn: "assessmentDate",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
    ];

    buttonConfig: WorkspaceButtonConfig[] = [];

    dateProps: string[] = ["dateCreated", "dateUpdated", "assessmentDate"];
    numberProps: string[] = [];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _pages: Pages,
        private _tabs: TabService,
        private _permissions: PermissionsService,
        private _ds: AuditUniverseDataService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.auditUniverse;
        this.data = toPromise(this._ds.getList()).then(this.mapHeaders);
    }

    openDetail(audit: AuditUniverseInfoModel) {
        this._tabs.generateTab(
            this._pages.AuditUniverse,
            audit.guid,
            `(UO) ${+audit.identifier} - ${audit.name}`
        );
    }

    add() {
        this._tabs.generateTab(this._pages.AuditUniverse, null, "(UO) New Universe Object");
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
