<div class="form-detail" data-uiid="form-detail" *ngIf="this.form && this.activePage">
    <div class="detail-page-action-bar flex-action-bar">
        <div class="left-section">
            <button
                class="btn btn-cerrix"
                (click)="saveForm()"
                data-uiid="forms-detail-release-button"
                *ngIf="form.CanRelease"
            >
                <i class="fas fa-save"></i>
                <span class="d-none d-lg-inline">Release Form</span>
            </button>

            <button
                class="btn btn-outline-dark ms-2 me-2"
                (click)="saveDraft()"
                [disabled]="postRunning"
                data-uiid="forms-detail-save-draft-button"
            >
                <i class="fal fa-drafting-compass" aria-hidden="true"></i>
                <span class="d-none d-xl-inline ms-2">Save Draft</span>
            </button>

            <button
                class="btn btn-outline-dark me-2"
                (click)="loadDraft()"
                *ngIf="form.HasDraft && !form.LoadedDraftID"
                data-uiid="forms-detail-load-draft-button"
            >
                <i class="fal fa-drafting-compass" aria-hidden="true"></i>
                <span class="d-none d-xl-inline ms-2">Load Draft</span>
            </button>

            <span class="date-edited" *ngIf="form.CanRelease && form.ID > 0 && !form.LoadedDraftID">
                <i
                    class="fas fa-circle form-open-indicator"
                    [class.text-warning]="form.IsOpenForEntries && form.FormOutOfDateRange"
                    [class.text-success]="form.IsOpenForEntries && !form.FormOutOfDateRange"
                    [class.text-danger]="!form.IsOpenForEntries"
                ></i>
                This form is
                {{
                    form.IsOpenForEntries
                        ? form.FormOutOfDateRange
                            ? "date halted"
                            : "open"
                        : "closed"
                }}
                for entries.
            </span>

            <span class="date-edited" *ngIf="form.LoadedDraftID">Currently editing a draft</span>
        </div>

        <div class="right-section text-end">
            <div class="btn-group extra-actions">
                <button
                    class="btn btn-outline-secondary"
                    data-uiid="forms-detail-preview-button"
                    (click)="openPreview()"
                >
                    <i class="fas fa-eye" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">Preview</span>
                </button>
                <button
                    class="btn btn-outline-secondary"
                    data-uiid="forms-detail-history-button"
                    (click)="openHistory()"
                >
                    <i class="fas fa-history" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">History</span>
                </button>
            </div>

            <div class="right-area">
                <button
                    class="btn text-success"
                    data-uiid="forms-detail-add-page-button"
                    (click)="addNewPage()"
                    *ngIf="!form.Workflow"
                >
                    <i class="fas fa-plus" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">Add Page</span>
                </button>
                <div class="dropdown d-inline" *ngIf="form.Workflow">
                    <button
                        class="btn btn-success dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i class="fas fa-plus" aria-hidden="true"></i>
                        <span class="d-none d-xl-inline ms-2">New Page</span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item" (click)="addNewPage()">
                            <i class="fas fa-pen" aria-hidden="true"></i>Add Entry Page
                        </button>
                        <button class="dropdown-item" (click)="addNewPage(true)">
                            <i class="fas fa-eye" aria-hidden="true"></i>Add Review Page
                        </button>
                    </div>
                </div>
                <button
                    class="btn text-danger ms-2"
                    data-uiid="forms-detail-delete-page-button"
                    (click)="deleteCurrentPage()"
                    *ngIf="form.Pages.length > 1 && activePage != form.Pages[0].ID"
                >
                    <i class="fas fa-trash" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">Delete Page</span>
                </button>

                <input
                    style="display: none"
                    type="file"
                    (change)="upload($event)"
                    accept="application/json,.json"
                    #fileInput
                />
                <div class="btn-group ms-2">
                    <button
                        class="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-uiid="forms-detail-toggle-form-options-dropdown"
                    >
                        <i class="fas fa-cogs" aria-hidden="true"></i>
                        <span class="d-none d-xl-inline ms-2">Form Options</span>
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <button
                            class="dropdown-item"
                            data-uiid="forms-detail-main-settings-button"
                            (click)="openEditFormWindow()"
                        >
                            <i class="fas fa-cog"></i>Main Settings
                        </button>
                        <button
                            class="dropdown-item"
                            data-uiid="forms-detail-page-settings-button"
                            (click)="openEditPageWindow()"
                        >
                            <i class="fas fa-bars"></i>Page Settings
                        </button>
                        <button
                            class="dropdown-item"
                            (click)="openWorkflowWindow()"
                            *ngIf="form.Workflow"
                            data-uiid="forms-detail-worksflow-settings-button"
                        >
                            <i class="fas fa-code-branch"></i>Workflow Settings
                        </button>

                        <div class="dropdown-divider"></div>

                        <button
                            class="dropdown-item"
                            data-uiid="forms-detail-import-button"
                            (click)="fileInput.click()"
                            *ngIf="permissions.IsImporter && (!form.ID || form.ID <= 0)"
                        >
                            <i class="fas fa-file-import"></i>Import
                        </button>
                        <button
                            class="dropdown-item"
                            data-uiid="forms-detail-export-button"
                            (click)="exportForm()"
                            *ngIf="permissions.IsExporter"
                        >
                            <i class="fas fa-file-export ps-2"></i>Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngFor="let page of form.Pages; let pageIndex = index" class="page">
        <div class="form-page" *ngIf="page.ID === activePage">
            <div class="page-title" tooltip="{{ page.Tooltip }}">
                <input
                    type="text"
                    class="pagetitle clearinput"
                    data-uiid="forms-detail-page-title-input"
                    placeholder="Page title"
                    [(ngModel)]="page.Title"
                    (blur)="updateMenu()"
                />
            </div>

            <div class="page-fields" *ngIf="page.Type === pageType.Entry">
                <mat-accordion
                    class="sortable-list"
                    [sortablejs]="page.Fields"
                    [sortablejsOptions]="sortingOptions"
                >
                    <mat-expansion-panel
                        *ngFor="let field of page.Fields; let fieldIndex = index"
                        [expanded]="expandedFieldId == field.ID"
                        (opened)="expandedFieldId = field.ID"
                    >
                        <mat-expansion-panel-header data-uiid="forms-detail-addfield-expand">
                            <mat-panel-title [class.field-invalid]="!field.Valid">
                                <cerrix-checkbox
                                    [(value)]="field.Selected"
                                    (valueChange)="fieldSelectionToggled(field)"
                                    (click)="$event.stopPropagation()"
                                    class="mr-3"
                                ></cerrix-checkbox>

                                <span class="text-truncate">
                                    <i class="fas fa-exclamation-circle" *ngIf="!field.Valid"></i>
                                    {{ field.Name ? field.Name : "(Name missing)" }}
                                </span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <i class="fas {{ typeDesc[field.FieldType].icon }}"></i>
                                &nbsp;{{ typeDesc[field.FieldType].text }}
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <small
                            class="form-text text-danger field-validation"
                            *ngIf="!field.Valid"
                            >{{ field.Validation }}</small
                        >
                        <form-detail-field
                            [field]="field"
                            [mapbackProperties]="mapbackProperties"
                            *ngIf="!this.form.MapBack || mapbackProperties"
                        ></form-detail-field>

                        <mat-action-row>
                            <button
                                class="btn btn-danger field-edit-button align-right"
                                (click)="deleteField(field, false)"
                            >
                                <i class="fas fa-trash" aria-hidden="true"></i>
                                <span class="d-none d-xl-inline ms-2">Delete Field</span>
                            </button>
                        </mat-action-row>
                    </mat-expansion-panel>

                    <div class="page-management">
                        <div class="row">
                            <div class="add-field-block col" (click)="addNewFieldToPage()">
                                <div class="add-field-text">
                                    <i class="fas fa-plus pr-1" aria-hidden="true"></i>
                                    Add Field
                                </div>
                            </div>
                            <div
                                class="dropdown add-field-block col-4 ms-2"
                                *ngIf="selectedFieldCount > 0"
                            >
                                <div class="add-field-text" data-bs-toggle="dropdown">
                                    {{ selectedFieldCount }} Selected
                                    <i class="fas fa-caret-down pr-1" aria-hidden="true"></i>
                                </div>
                                <div class="dropdown-menu col-12 text-center">
                                    <button class="dropdown-item" (click)="resetFieldSelection()">
                                        <i class="fas fa-eraser pr-1" aria-hidden="true"></i>
                                        Clear Selection
                                    </button>
                                    <button class="dropdown-item" (click)="cutFields()">
                                        <i class="fas fa-cut pr-1" aria-hidden="true"></i>
                                        Cut
                                    </button>
                                    <button class="dropdown-item" (click)="copyFields()">
                                        <i class="fas fa-copy pr-1" aria-hidden="true"></i>
                                        Copy
                                    </button>
                                    <button class="dropdown-item" (click)="deleteFields()">
                                        <i class="fas fa-trash pr-1" aria-hidden="true"></i>
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <div
                                class="add-field-block col-2 ms-2"
                                (click)="pasteFields()"
                                *ngIf="fieldClipboard && fieldClipboard.length > 0"
                            >
                                <div class="add-field-text">
                                    <i class="fas fa-paste pr-1" aria-hidden="true"></i>
                                    Paste ({{ fieldClipboard.length }})
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-accordion>
            </div>

            <div class="page-fields" *ngIf="page.Type === pageType.Review">
                <div class="add-field-block">
                    <div class="add-field-text">This is a review page.</div>
                </div>
            </div>

            <div class="page-actions">
                <button
                    class="btn btn-secondary me-1 ms-1"
                    (click)="navigateFirstPage()"
                    [disabled]="0 >= pageIndex"
                >
                    <i class="fas fa-angle-double-left pr-1" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">First page</span>
                </button>
                <button
                    class="btn btn-secondary me-1 ms-1"
                    (click)="navigatePreviousPage(pageIndex)"
                    [disabled]="0 >= pageIndex"
                >
                    <i class="fas fa-angle-left pr-1" aria-hidden="true"></i>
                    <span class="d-none d-xl-inline ms-2">Previous page</span>
                </button>
                <span class="page-nr-info">{{ pageIndex + 1 }}/{{ form.Pages.length }}</span>
                <button
                    class="btn btn-secondary me-1 ms-1"
                    (click)="navigateNextPage(pageIndex)"
                    [disabled]="pageIndex == form.Pages.length - 1"
                >
                    <span class="d-none d-xl-inline me-2">Next page</span>
                    <i class="fas fa-angle-right pr-1" aria-hidden="true"></i>
                </button>
                <button
                    class="btn btn-secondary me-1 ms-1"
                    (click)="navigateLastPage()"
                    [disabled]="pageIndex == form.Pages.length - 1"
                >
                    <span class="d-none d-xl-inline me-2">Last page</span>
                    <i class="fas fa-angle-double-right pr-1" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="form-detail-modal"
    data-backdrop="static"
    data-keyboard="false"
    #popupModal
>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <ng-template #customComponent></ng-template>
        </div>
    </div>
</div>
