import { Component, OnInit, ViewChild } from "@angular/core";
import { CerrixWizardComponent } from "@app/shared/cerrix-wizard/cerrix-wizard.component";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import {
    CerrixWizardConfig,
    CerrixWizardStepConfig,
} from "@app/shared/models/WizardModels/CerrixWizardConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";
import { UserRoleGroupService } from "../services/user-role-group.service";
import { UserRoleGroupModel } from "../shared/user-role-group-model";

@Component({
    selector: "app-user-role-group",
    templateUrl: "./user-role-group.component.html",
    styleUrls: ["./user-role-group.component.scss"],
})
export class UserRoleGroupComponent implements OnInit {
    @ViewChild("wizard") wizard: CerrixWizardComponent;
    tab: TabModel;

    config: GenericListConfig;
    wizardConfig: CerrixWizardConfig;

    details: UserRoleGroupModel;

    userFilter = "";
    userFilterNoResults = false;

    constructor(
        private _ds: UserRoleGroupService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        this.reload();
    }

    reload() {
        this.config = null;
        this._ds.getOverview().subscribe((rgs) => {
            this.createConfig(rgs);
        });
    }

    createConfig(roleGroups: UserRoleGroupModel[]) {
        this.wizardConfig = <CerrixWizardConfig>{
            showInModal: true,
            enableSummary: false,
            enableValidation: false,
            activePageIndex: 0,
            steps: [
                "Role group information",
                "Assigned Roles",
                "Assigned Users",
                "Assigned Dashboards",
                "Summary",
            ].map(
                (s) =>
                    <CerrixWizardStepConfig>{
                        name: s,
                        valid: null,
                    }
            ),

            noPagePadding: true,
            useDedicatedClose: true,
            allowPageChange: true,
            buttons: [],

            showModalOnComplete: true,
            completeModalTitle: "Role group has been saved!",
            completeModalSubtitle:
                "Selected users have been provided with the selected roles and unselected users are updated accordingly.",

            complete: () => {
                if (this.validate()) {
                    this._promptService
                        .confirm(
                            "Update assignments",
                            "Selected roles will be assigned to selected users. Deselected roles will be removed. Are you sure you want to continue?"
                        )
                        .onConfirm()
                        .subscribe(() => {
                            const selectedRoles = this.details.moduleRoles
                                .map((x) => x.roles)
                                .reduce((a, b) => a.concat(b))
                                .filter((x) => x.checked)
                                .map((x) => x.roleID)
                                .distinct();
                            this.details.selectedRoles = selectedRoles;

                            const selectedUsers = this.details.detailedUsers
                                .filter((x) => x.checked)
                                .map((x) => x.userID)
                                .distinct();
                            this.details.selectedUsers = selectedUsers;

                            this._ds.storeDetailed(this.details).subscribe((response) => {
                                if (response) {
                                    this._toastr.error(response, "Save failed!");
                                } else {
                                    this.reload();
                                    this.wizard.closeWizard();
                                    this.wizard.openCompleteModal();
                                }
                            });
                        });
                }
            },

            reload: () => {
                // Do stuff for loading selected item.
            },

            changeToPage: (fromPage: number, toPage: number) => {
                if (!this.details) {
                    return false;
                }

                // When opening the role page we have to call resize to fix the masonry layout.
                if (toPage == 1) {
                    window.dispatchEvent(new Event("resize"));
                }

                if (toPage !== 3) {
                    return true;
                }

                // Perform validations to see if form is completable.
                this.validate();

                return true;
            },
        };

        this.config = <GenericListConfig>{
            idProp: "ID",
            data: roleGroups,
            name: "User role groups",

            allowAdd: true,
            allowDelete: true,
            allowEdit: true,

            addOverride: () => {
                this.details = null;
                this.openWizard();
                this._ds.getNewDetailed().subscribe((d) => {
                    this.details = d;
                });
            },
            editOverride: (row: any) => {
                this.details = null;
                this.openWizard();
                this._ds.getDetailed(row.ID).subscribe((d) => {
                    this.details = d;
                });
            },
            delete: async (row: any) => {
                const confirmed = await this._promptService
                    .confirm("", `Are you sure you want to delete '${row.name}'?`)
                    .toPromise();

                if (confirmed) {
                    this.details = null;
                    await this._ds.deleteDetailed(row.ID).toPromise();
                    this._toastr.success(
                        `Role group with name '${row.name}' has been deleted!`,
                        "Deleted"
                    );
                    return true;
                }

                return false;
            },

            fields: [
                {
                    fieldName: "name",
                    prettyName: "Role name",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "description",
                    prettyName: "Role description",
                    fieldType: GenericListFieldType.TextArea,
                },
            ],
        };

        this.tab.showLoader = false;
    }

    validate() {
        let isValid = true;

        if (!this.details.name) {
            isValid = false;
        }

        this.wizardConfig.completable = isValid;
        return isValid;
    }

    openWizard() {
        this.wizardConfig.activePageIndex = 0;
        this.wizard.openWizard();
    }

    filterUsers() {
        const keywords = this.userFilter
            ? this.userFilter
                  .toLowerCase()
                  .split(" ")
                  .filter((x) => x && x.trim().length > 0)
            : [];

        if (keywords.length == 0) {
            this.userFilterNoResults = false;
            this.details.detailedUsers.forEach((u) => {
                u._hidden = false;
            });
        } else {
            let userFound = false;
            this.details.detailedUsers.forEach((u) => {
                const fullName = `${u.firstname} ${u.lastname}`.toLowerCase();
                const filterMatched = keywords.some((kw) => fullName.indexOf(kw) >= 0);
                u._hidden = !filterMatched;

                if (filterMatched) {
                    userFound = true;
                }
            });

            this.userFilterNoResults = userFound == false;
        }
    }
    toggleAll(toggleOn: boolean) {
        this.details.detailedUsers.forEach((x) => {
            if (!x._hidden) {
                x.checked = toggleOn;
            }
        });
    }
}
