import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, Input, OnInit } from "@angular/core";
import { GraphEditor } from "@app/process-editor/grapheditor";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { TargetModule } from "@enums/document/TargetModule";
import { ModuleType } from "@enums/ModuleType";
import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { StandingDataType } from "@enums/StandingDataType";
import { nameof } from "@methods/jeffs-toolkit";
import { GetIconByModuleString } from "@methods/Icons";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { Meta, RaciItem, ProcessCellAttribute } from "@models/processeditor/DiagramData";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ProcessEditorDataService } from "@services/http/processeditor/ProcessEditorDataService";
import { mxgraph } from "mxgraph-factory";
import { publishReplay, refCount } from "rxjs/operators";
import "./../../graphcell"; // Import extended functionality
import { BaseShapeProperty } from "./properties/BaseShapeProperty";
import { ControlShapePropertiesComponent } from "./properties/control/control-shape-properties.component";
import { RiskShapePropertiesComponent } from "./properties/risk/risk-shape-properties.component";
import { ProcessEditorHelper } from "@app/process-editor/helpers/ProcessEditorHelper";

enum CellTypes {
    Process,
    Module,
    Shape,
}

type ProcessField = GenericListField & { fieldName: keyof Meta };

@Component({
    selector: "process-editor-properties-sidebar",
    templateUrl: "./properties-sidebar.component.html",
    styleUrls: ["./properties-sidebar.component.scss"],
})
export class PropertiesSidebarComponent implements OnInit {
    @Input() graph: GraphEditor;
    @Input() readOnly = false;
    @Input() processMetadata: Meta;
    @Input() currentBusinessDimensionId: number;
    @Input() currentOrganizationId: number;
    @Input() cerrixTab: TabModel;

    cellRaciConfig: GenericListConfig;

    processMetadataFields: ProcessField[];
    processCommentField: ProcessField = {
        fieldName: "comment",
        prettyName: "Comment",
        fieldType: GenericListFieldType.TextArea,
        required: false,
    };

    shapeCommentData = {};
    shapeCommentField: GenericListField = {
        fieldName: "nodeValue",
        prettyName: "Comment",
        fieldType: GenericListFieldType.TextArea,
        required: false,
    };

    includeInPrintData = {};
    includeInPrintField: GenericListField = {
        fieldName: "nodeValue",
        prettyName: "Include in print",
        fieldType: GenericListFieldType.CheckBox,
        defaultValue: false,
    };

    printSequenceData = {};
    printSequenceField: GenericListField = {
        fieldName: "nodeValue",
        prettyName: "Print sequence nr",
        fieldType: GenericListFieldType.Number,
        required: false,
    };

    showCellPrintSettings = false;

    documentTypeTargets = TargetModule.BusinessDimension;

    selectedLinkedProcess: number;
    businessDimensions: CerrixTreeItem[];
    additionalData = {};

    get CellTypesEnum() {
        return CellTypes;
    }

    selectedCellType: CellTypes = CellTypes.Process;

    selectedCell: mxgraph.mxCell;

    get selectedCellID(): string {
        return this.selectedCell && this.selectedCell.id;
    }

    _selectedModuleIcon: string;
    _selectedItemEnum: ItemEnum;
    _selectedPropertyPortal: ComponentPortal<any>;
    _portalComponentRef: ComponentRef<BaseShapeProperty>;
    private portalMap = new Map<ItemEnum, ComponentPortal<BaseShapeProperty>>();

    constructor(private _ds: ProcessEditorDataService, private _sdDs: StandingdataDataService) {}

    async ngOnInit() {
        const mx = this.graph.dependencies;

        this.makePortals();
        this.initProcessMetadata();

        this._ds.getBusinessDimensions(this.currentBusinessDimensionId).subscribe((data) => {
            this.businessDimensions = data;
        });

        this.loadReviewScoreStandingData();
        await this.initRACI();

        this.graph.getSelectionModel().addListener(mx.mxEvent.CHANGE, (sender, evt) => {
            this.selectionChanged(this.graph);
        });
    }

    linkedProcessSelected(businessDimensionId?: number) {
        const linkedIdName = nameof<ProcessCellAttribute>((x) => x.linked_id);
        this.selectedCell.value.setAttribute(linkedIdName, businessDimensionId ?? "");
    }

    portalAttached(componentRef: ComponentRef<BaseShapeProperty>) {
        this._portalComponentRef = componentRef;
        this.loadComponent();
    }

    isProcess(cell: mxgraph.mxCell): boolean {
        return ProcessEditorHelper.isProcess(cell.getShape());
    }

    private setModuleIcon(itemEnum: ItemEnum) {
        switch (itemEnum) {
            case ItemEnum.Control:
                this._selectedModuleIcon = GetIconByModuleString(ModuleType.ControlsDescription);
                break;
            case ItemEnum.Risk:
                this._selectedModuleIcon = GetIconByModuleString(ModuleType.ORMDescription);
                break;
        }
    }

    private makePortals() {
        this.portalMap.set(ItemEnum.Control, new ComponentPortal(ControlShapePropertiesComponent));
        this.portalMap.set(ItemEnum.Risk, new ComponentPortal(RiskShapePropertiesComponent));
    }

    private initProcessMetadata() {
        this.processCommentField.isReadonly = this.readOnly;

        this.processMetadataFields = [
            {
                fieldName: "processName",
                prettyName: "Process name",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: "author",
                prettyName: "Author",
                fieldType: GenericListFieldType.Text,
                isReadonly: this.readOnly,
            },
            {
                fieldName: "date",
                prettyName: "Date created",
                fieldType: GenericListFieldType.Date,
                isReadonly: this.readOnly,
                maximumValue: new Date(),
            },
            {
                fieldName: "authorizedBy",
                prettyName: "Authorized by",
                fieldType: GenericListFieldType.Text,
                isReadonly: this.readOnly,
            },
            {
                fieldName: "version",
                prettyName: "Version",
                fieldType: GenericListFieldType.Text,
                isReadonly: this.readOnly,
            },
            {
                fieldName: "reviewer",
                prettyName: "Reviewer",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                fieldName: "scoreReviewer",
                prettyName: "Score reviewer",
                fieldType: GenericListFieldType.ColoredSelect,
                isReadonly: true,
            },
            this.processCommentField,
        ];
    }

    private async initRACI(): Promise<void> {
        const raciFunctionDataMethod = this._sdDs
            .getAllByType(StandingDataType.ControlFunction)
            .pipe(publishReplay(1), refCount());

        const raciData = await raciFunctionDataMethod.toPromise();

        this.cellRaciConfig = {
            name: "RACI",
            idProp: "id",
            isSortable: false,
            allowAdd: !this.readOnly,
            allowEdit: !this.readOnly,
            allowDelete: !this.readOnly,
            fields: [
                {
                    fieldName: nameof<RaciItem>((x) => x.functionId),
                    prettyName: "Function",
                    fieldType: GenericListFieldType.SingleSelect,
                    required: true,
                    getDataMethod: () => raciFunctionDataMethod,
                },
                {
                    fieldName: nameof<RaciItem>((x) => x.isResponsible),
                    prettyName: "R",
                    defaultValue: false,
                    fieldType: GenericListFieldType.CheckBox,
                    editorWidth: 6,
                },
                {
                    fieldName: nameof<RaciItem>((x) => x.isAccountable),
                    prettyName: "A",
                    defaultValue: false,
                    fieldType: GenericListFieldType.CheckBox,
                    editorWidth: 6,
                },
                {
                    fieldName: nameof<RaciItem>((x) => x.isConsulted),
                    prettyName: "C",
                    defaultValue: false,
                    fieldType: GenericListFieldType.CheckBox,
                    editorWidth: 6,
                },
                {
                    fieldName: nameof<RaciItem>((x) => x.isInformed),
                    prettyName: "I",
                    defaultValue: false,
                    fieldType: GenericListFieldType.CheckBox,
                    editorWidth: 6,
                },
            ],
            add: (raci: RaciItem) => {
                return new Promise((res, rej) => {
                    raci.cellId = this.selectedCellID;
                    raci.functionName = raciData.find((r) => r.ID == raci.functionId).Name;
                    this.graph.diagramData.racis.push(raci);
                    res(true);
                });
            },
            edit: (row: RaciItem, uneditedRow: RaciItem) => {
                return new Promise((res, rej) => {
                    row.functionName = raciData.find((r) => r.ID == row.functionId).Name;
                    res(true);
                });
            },
            delete: (raci: RaciItem) => {
                return new Promise((res, rej) => {
                    const index = this.graph.diagramData.racis.findIndex(
                        (jsonRaci) => raci.id === jsonRaci.id
                    );
                    if (index > -1) {
                        this.graph.diagramData.racis.splice(index, 1);
                        res(true);
                    }
                });
            },
        };
    }

    private selectionChanged(graph: GraphEditor) {
        if (!this.graph.diagramData) {
            return;
        }

        if (this.selectedCell) {
            this.graph.updateOverlay(this.selectedCell);
        }

        const cell = graph.getSelectionCell();
        this.selectedCell = cell;
        // Edges don't have metadata connected to them.
        // If nothing or an edge is selected we just show the metadata of the process
        if (cell == null || cell.isEdge()) {
            this.selectedCellType = CellTypes.Process;
        } else {
            const shape = cell.getShape() as any;
            const itemEnum = ItemEnum.fromShape(shape);

            if (itemEnum === ItemEnum.Risk || itemEnum === ItemEnum.Control) {
                this.selectedCellType = CellTypes.Module;
                this.onModuleSelected(itemEnum);
            } else {
                this.selectedCellType = CellTypes.Shape;
                this.onShapeSelected(cell);
            }
        }
    }

    private onModuleSelected(itemEnum: ItemEnum) {
        this._selectedPropertyPortal = this.portalMap.get(itemEnum);
        this._selectedItemEnum = itemEnum;
        this.setModuleIcon(itemEnum);
        if (this.isSameInstance(itemEnum)) {
            this.loadComponent();
        }
    }

    private isSameInstance(itemEnum: ItemEnum): boolean {
        return (
            this._portalComponentRef &&
            this._portalComponentRef.instance &&
            this._portalComponentRef.instance.getItemEnum() == itemEnum
        );
    }

    private loadComponent() {
        if (
            this._portalComponentRef &&
            this._portalComponentRef.instance &&
            !this._portalComponentRef.hostView.destroyed
        ) {
            this._portalComponentRef.changeDetectorRef.detectChanges();
            this._portalComponentRef.instance.load({
                currentBusinessDimensionId: this.currentBusinessDimensionId,
                currentOrganizationId: this.currentOrganizationId,
                graph: this.graph,
                selectedCell: this.selectedCell,
                tab: this.cerrixTab,
                isReadOnly: this.readOnly,
            });
        }
    }

    private getLinkedBusinessDimensionId(cell: mxgraph.mxCell): number | null {
        if (cell.value && cell.value.attributes) {
            const linkedAttrib =
                cell.value.attributes[nameof<ProcessCellAttribute>((x) => x.linked_id)];
            const businessDimensionId = linkedAttrib && +linkedAttrib.nodeValue;
            return businessDimensionId > 0 ? businessDimensionId : null;
        } else {
            return null;
        }
    }

    private reloadLinkedProcessTree(cell: mxgraph.mxCell) {
        this.selectedLinkedProcess = this.getLinkedBusinessDimensionId(cell);
    }

    private reloadCellRaci(cell: mxgraph.mxCell) {
        const racis = this.graph.diagramData.racis.filter((x) => x.cellId === cell.id);

        this.cellRaciConfig.data = racis;

        if (this.cellRaciConfig.reload) {
            this.cellRaciConfig.reload();
        }
    }

    private reloadCellAttributes(cell: mxgraph.mxCell) {
        if (cell.value && cell.value.attributes) {
            // We don't use getAttribute because it's a copy.
            // We need a reference to the original data so it changes appropriately
            this.shapeCommentData =
                cell.value.attributes[nameof<ProcessCellAttribute>((x) => x.comment)];
            this.printSequenceData =
                cell.value.attributes[nameof<ProcessCellAttribute>((x) => x.order_nr)];
            this.includeInPrintData =
                cell.value.attributes[nameof<ProcessCellAttribute>((x) => x.include_in_print)];
        } else {
            this.shapeCommentData = {};
            this.printSequenceData = {};
            this.includeInPrintData = {};
        }
    }

    private onShapeSelected(cell: mxgraph.mxCell) {
        this.showCellPrintSettings = false;

        this.reloadCellRaci(cell);
        this.reloadLinkedProcessTree(cell);
        this.reloadCellAttributes(cell);

        this.showCellPrintSettings = ProcessEditorHelper.showPrintSettings(
            cell.getShape(),
            cell.isEdge()
        );
    }

    private async loadReviewScoreStandingData() {
        const scoreReviewer = await this._sdDs
            .allIncludeDeleted(StandingDataType.BusinessAssessmentScore)
            .toPromise();
        this.additionalData = { scoreReviewer: scoreReviewer };
    }
}
