.audit-risk-assessment {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row;

    .left-block,
    .right-block {
        width: 50%;
        height: 100%;

        flex: 1;
    }

    // These are wrapped in another form-group that adds margin-bottom. so cancel out the double margin.
    .cerrix-textfield.form-group {
        margin-bottom: 0;
    }
}
