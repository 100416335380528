<div class="d-flex flex-column main-container">
    <eff-radio-group
        [options]="sampleTypeOptions"
        [(model)]="samplingInformation.sampleType"
        label="Select a sample type"
        (modelChange)="sampleTypeChanged()"
        data-uiid="new-eff-generate-samples-sample-type-radio-group"
    ></eff-radio-group>
    <div class="d-flex flex-row row-items align-items-start" *ngIf="isSpreadsheet()">
        <eff-input
            type="text"
            label="ID column"
            class="row-item"
            [formController]="sampleFormGroup.idColumnFormControl"
            data-uiid="new-eff-generate-samples-spreadsheet-id-column"
        ></eff-input>
        <eff-input
            type="number"
            label="Rows to skip"
            class="row-item"
            [formController]="sampleFormGroup.rowsToSkipFormControl"
            data-uiid="new-eff-generate-samples-spreadsheet-rows-to-skip"
        ></eff-input>
    </div>

    <div class="d-flex flex-row row-items align-items-start">
        <eff-input
            *ngIf="!hidePopulationSize()"
            type="number"
            label="Population size"
            class="row-item"
            [disabled]="isSpreadsheet()"
            [formController]="sampleFormGroup.populationSizeFormControl"
            data-uiid="new-eff-generate-samples-population-size"
        ></eff-input>
        <eff-input
            type="number"
            label="Test samples"
            class="row-item"
            [formController]="sampleFormGroup.numberOfTestSamplesFormControl"
            data-uiid="new-eff-generate-samples-test-samples"
        ></eff-input>
        <div class="row-item" *ngIf="hidePopulationSize()"></div>
    </div>
</div>
