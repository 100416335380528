import { Component, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { Observable, of } from "rxjs";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { LinkableIconConfig } from "@app/shared/models/Linkable/LinkableIconConfig";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ModuleType } from "@enums/ModuleType";
import { AuditFindingDataService } from "../services/audit-finding-data.service";

@Component({
    selector: "findings-linkable",
    templateUrl: "./findings-linkable.component.html",
})
export class FindingsLinkableComponent extends ItemLinkableBase {
    hasAccessChecks = {};
    accessCheckInProgress = false;

    rowIconConfig: LinkableIconConfig[] = [];

    rendererConfig: RendererConfig[] = [];

    constructor(
        protected _toastr: ToastrService,
        protected _promptService: CerrixPromptService,
        private _findingsDS: AuditFindingDataService,
        private _pages: Pages,
        private _tabService: TabService
    ) {
        super(_toastr, _promptService);
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._findingsDS.hasAccess(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.AuditFindingDetail,
                item.guid,
                `(A.F.) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this Finding.");
        }
    }
}
