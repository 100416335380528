<div class="btn-dm-toolbar" [ngClass]="{ readonly: disabled }">
    <div *ngIf="!standingData">
        <i class="fas fa-circle-notch fa-spin me-2"></i> Fetching third parties
    </div>
    <button
        *ngIf="!disabled && standingData"
        class="btn btn-success buttonspace"
        (click)="addEntity()"
    >
        Add third parties
    </button>
</div>

<div class="fancy-loader" *ngIf="!dataStructuresModel || !standingData"></div>
<div class="entities-display" *ngIf="dataStructuresModel && standingData">
    <div *ngFor="let entity of dataStructuresModel.entities" class="entity-detail col-12 col-xxl-6">
        <entity-detail
            #entityDetail
            [entityModel]="entity"
            [standingData]="standingData"
            (entityDeleted)="deleteEntity($event)"
            [disabled]="disabled"
        >
        </entity-detail>
    </div>
</div>
