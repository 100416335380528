import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { toPromise, tryParseJson } from "@methods/CommonMethods";
import {
    CellSaveEventArgs,
    ColumnMenuService,
    EditService,
    FilterService,
    FreezeService,
    PageService,
    ReorderService,
    ResizeService,
    SortService,
    TreeGridComponent,
} from "@syncfusion/ej2-angular-treegrid";
import { DateFormatOptions, NumberFormatOptions } from "@syncfusion/ej2-base";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { IEditCell, QueryCellInfoEventArgs, CellSaveArgs } from "@syncfusion/ej2-grids";
import { StandingdataDataService } from "../../standingdata/shared/standingdata.service";
import { LocalizationService } from "../localization";
import {
    CerrixGridEditorColumnConfig,
    CerrixGridEditorConfig,
} from "../models/cerrix-grid-editor-config";
import { GenericListFieldType } from "../models/GenericList/GenericListField";

@Component({
    selector: "cerrix-grid-editor",
    templateUrl: "./cerrix-grid-editor.component.html",
    styleUrls: ["./cerrix-grid-editor.component.scss"],
    providers: [
        EditService,
        FreezeService,
        PageService,
        SortService,
        FilterService,
        ReorderService,
        ResizeService,
        ColumnMenuService,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class CerrixGridEditorComponent implements OnInit {
    @Input() config: CerrixGridEditorConfig;

    ready: boolean = false;
    fieldTypes = GenericListFieldType;

    scrollHeight = 0;
    rowHeight = 40;
    defaultColumnWidth = 200;

    editSettings = { allowEditing: true, allowAdding: false, allowDeleting: false, mode: "Cell" };
    filterSettings = { type: "Excel" };

    columns: InternalColumnModel[];

    _fieldData: { [key: string]: any[] };

    @ViewChild("treeTableContainer") treeTableContainer: ElementRef;
    @ViewChild("treegrid") public treeGrid: TreeGridComponent;

    constructor(
        private _standingdataDS: StandingdataDataService,
        private _localization: LocalizationService
    ) {}

    ngOnInit() {
        this.validateAndInitModel();
    }

    @HostListener("window:resize")
    onResize() {
        this.setScrollHeight();
    }

    queryCellInfo(args: QueryCellInfoEventArgs) {
        if (args.column.editType === "dropdownedit") {
            const data = this._fieldData[args.column.field];
            const row = data.find((x) => x["ID"] == args.data[args.column.field]);

            if (row) {
                var fieldDefinition = this.config.columns.find(
                    (s) => s.fieldName == args.column.field
                );

                const htmlCell = args.cell as HTMLElement;
                if (fieldDefinition.fieldType == GenericListFieldType.ColoredSelect) {
                    htmlCell.innerText = "";
                    htmlCell.innerHTML += `<div style='background-color: ${row.Color}; width: 10px; height: 10px; border-radius: 10px; display: inline-block; margin-right: 5px'></div>`;
                    htmlCell.innerHTML += `<span class='name'>${row.Name}</span>`;
                } else {
                    htmlCell.innerText = row["Name"];
                }
            }
        }
    }

    click(args) {
        const targetNode = args.target.classList.contains("e-rowcell")
            ? args.target
            : args.target.parentNode.classList.contains("e-rowcell")
            ? args.target.parentNode
            : null;

        if (targetNode) {
            var index = parseInt(targetNode.getAttribute("Index"));
            var colIndex = parseInt(targetNode.getAttribute("aria-colindex")) - 1;
            var field = this.treeGrid.getColumns()[colIndex].field;
            this.treeGrid.editModule.editCell(index, field);

            const dropdownInputs = targetNode.querySelectorAll("input.e-dropdownlist");
            if (dropdownInputs && dropdownInputs.length > 0) {
                dropdownInputs[0].ej2_instances[0].showPopup();
            }

            const datepickerInputs = targetNode.querySelectorAll("input.e-datepicker");
            if (datepickerInputs && datepickerInputs.length > 0) {
                datepickerInputs[0].ej2_instances[0].show();
            }
        }
    }

    actionCompleted(ev: CellSaveEventArgs) {
        if (ev.type == "save") {
            this.config.onRowChanged(ev.data);
        }
    }

    private validateAndInitModel() {
        if (!this.config) {
            throw Error("Config is required!");
        }

        this.checkAdditionalData();
    }

    private checkAdditionalData() {
        const calls: { fieldName: string; call: Promise<any> }[] = [];
        this.config.columns.forEach((col) => {
            if (col.getDataMethod) {
                calls.push({ fieldName: col.fieldName, call: toPromise(col.getDataMethod()) });
            }
        });

        let callsCompleted = 0;
        this._fieldData = {};
        calls.forEach((c) => {
            c.call.then((d) => {
                const data = tryParseJson(d);
                this._fieldData[c.fieldName] = data ? data : d;

                callsCompleted++;
                if (callsCompleted === calls.length) {
                    this.initColumnOptions();
                }
            });
        });

        if (calls.length === 0) {
            this.initColumnOptions();
        }
    }

    private initColumnOptions() {
        const internalColumns = [];

        // This will map the remaining values as defaultedit.
        this.config.columns.forEach((col) => {
            const internalCol: InternalColumnModel = {
                fieldName: col.fieldName,
                prettyName: col.prettyName ? col.prettyName : col.fieldName,
                columnWidth: col.columnWidth ? col.columnWidth : this.defaultColumnWidth,
                visible: !col.hidden,

                freezeDirection: col.pinLeft ? "Left" : col.pinRight ? "Right" : "null",
                readonly: col.isReadonly ? true : false,

                editType: this.getEditType(col.fieldType),
            };

            switch (col.fieldType) {
                case GenericListFieldType.CheckBox:
                    internalCol.displayAsCheckBox = true;
                    break;
                case GenericListFieldType.Date:
                    internalCol.formatOptions = <DateFormatOptions>{
                        format: this._localization.localizationInfo.dateFormat
                            .replace("DD", "dd")
                            .replace("Y", "y"),
                        type: "date",
                    };
                    break;
                case GenericListFieldType.DateTime:
                    internalCol.formatOptions = <DateFormatOptions>{
                        format: this._localization.localizationInfo.dateTimeFormat
                            .replace("DD", "dd")
                            .replace("Y", "y"),
                        type: "dateTime",
                    };
                    break;
                case GenericListFieldType.SingleSelect:
                case GenericListFieldType.ColoredSelect:
                    // Disabled Template because this requires unsafe-eval, which is not allowed by our current CSP
                    // let template =
                    //     "<span><div style='background-color: ${Color}; width: 10px; height: 10px; border-radius: 10px; display: inline-block; margin-right: 5px'></div><span class='name'>${Name}</span></span>";
                    internalCol.editOptions = {
                        params: {
                            fields: { value: "ID", text: "Name" },
                            allowFiltering: false,
                            sortOrder: "None",
                            query: new Query(),
                            dataSource: new DataManager(this._fieldData[col.fieldName]),

                            // itemTemplate: template,

                            // valueTemplate: template
                            // For the first request that asks for this: This causes problems with opening the dropdown.
                            // The template cannot resolve the "Color" property so it crashes and the dropdown never appears.
                            // It only happens if you have a selected value.
                        },
                    };

                    break;
                case GenericListFieldType.MultiSelect:
                    throw Error("Multiselect not supported yet.");
            }

            internalColumns.push(internalCol);
        });

        this.columns = internalColumns;
        this.completeLoad();
    }

    private getEditType(type: GenericListFieldType) {
        switch (type) {
            case GenericListFieldType.Number:
                return "numericedit";
            case GenericListFieldType.CheckBox:
                return "booleanedit";
            case GenericListFieldType.Date:
                return "datepickeredit";
            case GenericListFieldType.DateTime:
                return "datetimepickeredit";
            case GenericListFieldType.SingleSelect:
            case GenericListFieldType.ColoredSelect:
                return "dropdownedit";
            case GenericListFieldType.MultiSelect:
            default:
                return "defaultedit";
        }
    }

    private completeLoad() {
        setTimeout(() => {
            this.setScrollHeight();
            this.ready = true;
        }, 0);
    }

    private setScrollHeight() {
        if (this.treeTableContainer) {
            // container height - height for header - padding and border heights
            this.scrollHeight =
                this.treeTableContainer.nativeElement.offsetHeight - this.rowHeight - 4;
        }
    }
}

class InternalColumnModel {
    fieldName: string;
    prettyName: string;
    columnWidth: number;
    visible: boolean;

    freezeDirection: "null" | "Right" | "Left";
    editType:
        | "defaultedit"
        | "numericedit"
        | "dropdownedit"
        | "booleanedit"
        | "datepickeredit"
        | "datetimepickeredit";
    readonly: boolean;

    formatOptions?: string | DateFormatOptions | NumberFormatOptions;
    editOptions?: IEditCell;
    displayAsCheckBox?: boolean;
}
