import { Component, Input, OnInit } from "@angular/core";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { NavigatorConfigType } from "@app/navigators/enums/NavigatorConfigType";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { Pages } from "@constants/pages/Pages";
import { ThirdPartyConfigModel } from "@models/thirdparty/third-party-config-model";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "business-thirdparties",
    templateUrl: "./business-thirdparties.component.html",
})
export class BusinessThirdPartiesComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissionsModel: BusinessNavigatorPermissionModel;
    config: GenericListConfig;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _navFactory: NavGenericListFactory,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        this.config = this._navFactory.createConfig(NavigatorConfigType.ThirdParty);

        this.config.dataMethod = () => this._businessDs.getThirdParties(this.requestModel);
        this.config.hideRefreshButton = false;

        this.config.allowAdd = this.permissionsModel.canAddThirdParty;
        this.config.addOverride = () => {
            const config = <ThirdPartyConfigModel>{
                organizationId: this.requestModel.organizationID,
                businessId: this.requestModel.businessID,
            };

            this._tabService.generateTab(
                this._pages.ThirdpartyDetail,
                0,
                "(TP) Add new Third Party",
                config
            );
        };
    }
}
