.treetable-container {
    height: 100%;
    width: 100%;

    .cellbtn {
        cursor: pointer;
        padding: 5px;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        transition: 0.5s all linear;

        & + .cellbtn {
            margin-left: 5px;
        }

        &:hover {
            background: var(--background-accent-color);
        }
    }
}
