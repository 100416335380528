<div>
    <div *ngIf="!linkedRisks">
        <div class="fancy-loader"></div>
    </div>
    <table class="table fixed-header table-sm" *ngIf="linkedRisks">
        <thead>
            <tr>
                <th style="width: 120px;">Risk identifier</th>
                <th>Name</th>
                <th class="d-none d-lg-table-cell">Description</th>
                <th style="width: 30px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let linkedRisk of linkedRisks" (click)="onRiskClick(linkedRisk)" class="action-button">
                <td [title]="linkedRisk.identifier">{{ linkedRisk.identifier }}</td>
                <td [title]="linkedRisk.name">{{ linkedRisk.name }}</td>
                <td [title]="linkedRisk.description" class="d-none d-lg-table-cell">{{ linkedRisk.description }}</td>
                <td>
                    <div title="Open Risk">
                        <i class="fas fa-external-link-alt"></i>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!linkedRisks.length">
                <td colspan="4" align="center">No risks</td>
            </tr>
        </tbody>
    </table>
</div>
