import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    Input,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    ConnectorModel,
    Diagram,
    DiagramConstraints,
    LaneModel,
    NodeModel,
    PrintAndExport,
    SnapConstraints,
} from "@syncfusion/ej2-angular-diagrams";
import { DateTimeFormats, LocalizationService } from "../localization";
import * as excel from "exceljs/dist/exceljs.min.js";
import { IEVersion, download } from "@methods/CommonMethods";

export interface IWorkflowHistoryModel {
    statusDescription: string;
    actionDescription: string;
    actionOn: Date;
    actionBy: string;
    comment: string;
}

@Component({
    selector: "cerrix-workflow-history-graph",
    templateUrl: "./cerrix-workflow-history-graph.component.html",
    styleUrls: ["./cerrix-workflow-history-graph.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CerrixWorkflowHistoryGraphComponent implements AfterViewInit {
    @Input() existingStates: string[];
    @Input() workflowHistory: IWorkflowHistoryModel[];

    @ContentChild("extraTableHeaders") extraTableHeaders: TemplateRef<any>;
    @ContentChild("extraTableValues") extraTableValues: TemplateRef<any>;

    @ViewChild("tableContainer") tableContainer: ElementRef;
    @ViewChild("diagramContainer") diagramContainer: ElementRef;
    diagramInstance?: Diagram;

    showAsDiagram = true;
    showTableReversed = false;
    ready = false;

    private lanePrefix = "workflow-diagram-lane-";
    private laneWidth = 250;

    private nodePrefix = "workflow-diagram-node-";
    private nodeWidth = 100;
    private nodeHeight = 75;
    private nodeMargin = 60;

    constructor(private _locService: LocalizationService) {
        Diagram.Inject(PrintAndExport);
    }

    ngOnInit() {
        // this.createDiagram();
    }

    ngAfterViewInit() {
        this.createDiagram();
    }

    toggleView() {
        this.showAsDiagram = !this.showAsDiagram;
        if (this.showAsDiagram) {
            this.createDiagram();
        } else if (this.diagramInstance) {
            this.diagramInstance.destroy();
            this.diagramInstance = null;
        }
    }

    private createDiagram() {
        const states = this.existingStates
            ? this.existingStates
            : this.workflowHistory
                  .map((x) => x.statusDescription)
                  .filter((x, i, a) => a.indexOf(x) === i);

        const lanes = states.map((state, i) => {
            return <LaneModel>{
                id: this.lanePrefix + i,
                header: {
                    annotation: {
                        content: state,
                        width: this.laneWidth - 50, // This makes sure the text wraps correctly
                    },
                },
                children: [],
                width: this.laneWidth,
                canMove: false,
            };
        });

        let lastDrawnLane = -1;
        let rowToDraw = 1;
        const connectors = [];
        for (let i = 0; i < this.workflowHistory.length; i++) {
            const currentStep = this.workflowHistory[i];
            const previousStep = i > 0 ? this.workflowHistory[i - 1] : null;

            const laneIndex = states.indexOf(currentStep.statusDescription);
            const lane = lanes[laneIndex];

            // We draw on the next row if we go back to a previous lane
            if (laneIndex <= lastDrawnLane) {
                rowToDraw++;
            }

            lastDrawnLane = laneIndex;

            const node = <NodeModel>{
                id: this.nodePrefix + i,
                annotations: [
                    {
                        content: currentStep.statusDescription,
                        style: { fontSize: 12 },
                        width: this.nodeWidth - 50, // This makes sure the text wraps correctly
                        height: this.nodeHeight,
                    },
                ],
                margin: {
                    left: (this.laneWidth - this.nodeWidth) / 2,
                    top: rowToDraw * (this.nodeHeight + this.nodeMargin) - this.nodeMargin,
                },
                width: this.nodeWidth,
                height: this.nodeHeight,
                ports: [
                    {
                        id: "node-in",
                        shape: "Circle",
                        offset: { x: 0, y: 0.5 },
                    },
                    {
                        id: "node-out",
                        shape: "Circle",
                        offset: { x: 1, y: 0.5 },
                    },
                ],
            };

            lane.children.push(node);

            if (previousStep) {
                const connector = <ConnectorModel>{
                    id: "connector" + i,
                    sourceID: this.nodePrefix + (i - 1),
                    targetID: this.nodePrefix + i,
                    sourcePortID: "node-out",
                    targetPortID: "node-in",
                    type: "Orthogonal",
                    annotations: [
                        {
                            content: previousStep.actionDescription
                                ? previousStep.actionDescription
                                : "",
                            style: { fontSize: 11, bold: true },
                            margin: { top: -10 },
                            width: this.laneWidth - this.nodeWidth,
                        },
                        {
                            content:
                                previousStep.actionBy +
                                "\n" +
                                this._locService.formatDateByEnum(
                                    previousStep.actionOn,
                                    DateTimeFormats.DateShortTime
                                ),
                            style: { fontSize: 11 },
                            margin: { top: 20 },
                            width: this.laneWidth - this.nodeWidth,
                        },
                    ],
                };

                connectors.push(connector);
            }
        }

        const height = rowToDraw * (this.nodeHeight + this.nodeMargin) + this.nodeMargin;
        const width = this.laneWidth * states.length;

        this.diagramInstance = new Diagram({
            width: width + 20 + "px",
            height: height + 20 + "px",
            nodes: [
                {
                    shape: {
                        type: "SwimLane",
                        orientation: "Vertical",
                        header: {
                            annotation: {
                                content: "",
                            },
                            height: 0,
                        },
                        lanes: lanes,
                    },
                    offsetX: width / 2 + 10,
                    offsetY: height / 2 + 10,
                    width: width,
                    height: height,
                },
            ],
            connectors: connectors,
            snapSettings: {
                constraints: SnapConstraints.None,
            },
            constraints:
                DiagramConstraints.Default &
                ~DiagramConstraints.PageEditable &
                ~DiagramConstraints.Zoom,
        });

        this.diagramInstance.appendTo(this.diagramContainer.nativeElement);
        this.ready = true;
    }

    exportDiagram() {
        if (this.diagramInstance) {
            this.diagramInstance.exportDiagram({
                fileName: "workflow-history",
                mode: "Download",
                format: "SVG",
            });
        }
    }

    // Because of new design I had to implement the table and the export myself, but eventually this should be the generic table component.
    exportTable() {
        if (!this.tableContainer.nativeElement) {
            return;
        }

        // Get the first div with class cerrix-row and extract every div within this div which contain the headers.
        const headers = (
            Array.from(
                this.tableContainer.nativeElement
                    .querySelector(":scope > .cerrix-row")
                    .querySelectorAll(":scope > div")
            ) as any[]
        ).map((x) => x.innerText);

        // Get cerrix-column div which is the body of the table and extract every cerrix-row -> div for the values of the table.
        const rows = (
            Array.from(
                this.tableContainer.nativeElement
                    .querySelector(":scope  .cerrix-column")
                    .querySelectorAll(":scope > .cerrix-row")
            ) as any[]
        ).map((x) =>
            (Array.from(x.querySelectorAll(":scope > div")) as any[]).map((y) => y.innerText)
        );

        const workbook = new excel.Workbook();
        const worksheet = workbook.addWorksheet();

        worksheet.addRows([headers, ...rows]);
        worksheet.columns.forEach((column) => {
            // Set the width to fit the content
            const maxLength = column.values.reduce(
                (acc, value) => Math.max(acc, value ? value.toString().length : 0),
                0
            );
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        const row = worksheet.getRow(1); // getRow is 1-based
        row.font = { bold: true };
        row.commit();

        workbook.xlsx
            .writeBuffer({
                base64: true,
            })
            .then(function (xls64) {
                const data = new Blob([xls64], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileName = `workflow_history.xlsx`;
                if (IEVersion() > 0) {
                    (window.navigator as any).msSaveOrOpenBlob(data, fileName);
                } else {
                    const url = URL.createObjectURL(data);
                    download(url, fileName);
                }
            });
    }
}
