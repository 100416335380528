import { Component } from "@angular/core";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { Observable, of } from "rxjs";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ThirdPartyDataService } from "@pages/thirdparty/services/ThirdPartyDataService";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";

@Component({
    selector: "thirdparty-linkable",
    templateUrl: "./thirdparty-linkable.component.html",
})
export class ThirdPartyLinkableComponent extends ItemLinkableBase {
    hasAccessChecks = {};
    accessCheckInProgress = false;

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Review score",
            actionColumn: "ReviewScoreColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "Criticality",
            actionColumn: "CriticalityColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
    ];

    constructor(
        protected _toastr: ToastrService,
        protected _promptService: CerrixPromptService,
        private _tpDataService: ThirdPartyDataService,
        private _pages: Pages,
        private _tabService: TabService
    ) {
        super(_toastr, _promptService);
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._tpDataService.hasAccess(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.ThirdpartyDetail,
                item.guid,
                `(TP) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this third party.");
        }
    }
}
