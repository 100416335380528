import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-teststeps-upload-evidence-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlTestStepsUploadEvidenceApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Test Steps Upload Evidence API",
            httpMethod: "POST",
            path: "/controls/testing/advanced/effectiveness/automation/samples/{sampleGuid}/teststeps/{testStepGuid}/evidence",

            parameters: [
                {
                    name: "sampleGuid",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
                {
                    name: "testStepGuid",
                    description: "Id of test step.",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
                {
                    name: "evidence",
                    description:
                        "Single file that should be stored as evidence on the supplied test sample/step.",
                    in: "formData",
                    paramType: "file",
                    required: true,
                    example: "binary",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: "Request has been successfully processed",
                },
            ],
        };
    }
}
