<div *ngIf="tab.showLoader">
    <div class="fancy-loader"></div>
</div>
<div *ngIf="!tab.showLoader && thirdParty" data-uiid="third-party-page">
    <div menuSystem [cerrixTab]="tab" (change)="handleMenuItemClick($event)">
        <div class="detail-page-action-bar flex-action-bar">
            <div class="col-md-12 text-start">
                <button
                    data-uiid="thirdparty-save"
                    class="btn btn-cerrix btm-sm"
                    (click)="save()"
                    *ngIf="!permissions.readOnly"
                >
                    <i class="fas fa-save"></i>
                    <span class="d-none d-lg-inline">Save third party</span>
                </button>
                <span *ngIf="thirdParty.Guid" class="date-edited">
                    Last save: {{ thirdParty.DateModified | dateTimeFormat }}
                </span>
            </div>
        </div>

        <div
            class="thirdparty-screens"
            menuicon="fas fa-handshake"
            name="Third Party"
            menusystemsubmenu
        >
            <div
                data-uiid="thirdpartydetail-main"
                menuItemId="details"
                name="Details"
                menuicon="fas fa-info-circle"
                menusystemitem
            >
                <uc-thirdparty-detail-main
                    *ngIf="thirdParty"
                    class="uc"
                    [thirdParty]="thirdParty"
                    [sd]="sd"
                    [parentForm]="parentForm"
                    [readonly]="permissions.readOnly"
                    [permissions]="permissions"
                >
                </uc-thirdparty-detail-main>
            </div>
            <div
                data-uiid="thirdpartydetail-contracts"
                menuItemId="contracts"
                *ngIf="thirdParty.Guid"
                name="Contracts"
                menuicon="fas fa-file-signature"
                menusystemitem
            >
                <thirdparty-contracts-overview
                    [thirdParty]="thirdParty"
                    [permissions]="permissions"
                    #thirdpartyContractsOverviewComponent
                >
                </thirdparty-contracts-overview>
            </div>
            <div
                [attr.name]="'Links'"
                *ngIf="thirdParty.Guid"
                menuicon="fas fa-link"
                menusystemsubmenu
            >
                <div
                    data-uiid="thirdpartydetail-risks"
                    menuItemId="risks"
                    name="Risks"
                    menuicon="fas fa-exclamation-triangle"
                    menusystemitem
                >
                    <thirdparty-risks
                        *ngIf="thirdParty"
                        [model]="thirdParty"
                        [disabled]="!permissions.canLinkRisks"
                        #thirdpartyRisksComponent
                    >
                    </thirdparty-risks>
                </div>
                <div
                    data-uiid="thirdpartydetail-controls"
                    menuItemId="controls"
                    name="Controls"
                    menuicon="fas fa-shield-alt"
                    menusystemitem
                >
                    <thirdparty-controls
                        *ngIf="thirdParty"
                        [model]="thirdParty"
                        [disabled]="!permissions.canLinkControls"
                        #thirdpartyControlsComponent
                    >
                    </thirdparty-controls>
                </div>
                <div
                    data-uiid="thirdpartydetail-moi"
                    menuItemId="moi"
                    *ngIf="thirdParty.Guid"
                    name="Measures of Improvement"
                    menuicon="fas fa-tasks"
                    menusystemitem
                >
                    <linked-moi-overview
                        data-uiid="thirdpartymoi-moilinkable"
                        [parentGuid]="thirdParty.Guid"
                        [moiType]="tpMoiType"
                        [canAdd]="permissions.canAddMoi"
                        [canDelete]="permissions.canEdit"
                        #thirdpartyMoisComponent
                    >
                    </linked-moi-overview>
                </div>
                <div
                    data-uiid="thirdpartydetail-forms"
                    menuItemId="forms"
                    *ngIf="thirdParty"
                    name="Forms"
                    menuicon="fad fa-file-alt"
                    menusystemitem
                >
                    <thirdparty-linked-forms
                        data-uiid="thirdparty-formlinkable"
                        [model]="thirdParty"
                        [disabled]="!permissions.canLinkForms"
                        #thirdPartyFormsComponent
                    >
                    </thirdparty-linked-forms>
                </div>
                <div
                    data-uiid="thirdpartydetail-documents"
                    menuItemId="documents"
                    *ngIf="thirdParty.Guid"
                    name="Documents"
                    menuicon="far fa-file"
                    menusystemitem
                >
                    <document-manager-old
                        data-uiid="thirdpartydocuments-documentmanager"
                        [(Documents)]="thirdParty.Documents"
                        [permissions]="permissions.documentPermissions"
                        [DocumentTypeTargets]="targetModule"
                    >
                    </document-manager-old>
                </div>
                <div
                    name="Hyperlinks"
                    [attr.menuItemID]="hyperlinksMenuItemId"
                    menuicon="fas fa-external-link-alt"
                    menusystemitem
                    *ngIf="thirdParty.Guid"
                >
                    <div *ngIf="!thirdParty.Hyperlinks">
                        <div class="fancy-loader"></div>
                    </div>
                    <div *ngIf="thirdParty.Hyperlinks">
                        <hyperlink-manager
                            [(Hyperlinks)]="thirdParty.Hyperlinks"
                            (HyperlinksChanged)="checkHyperlinksDirty($event)"
                            [TargetModule]="targetModule"
                        ></hyperlink-manager>
                    </div>
                </div>
            </div>
            <div
                menuItemId="history"
                *ngIf="thirdParty.Guid"
                name="History"
                menuicon="fas fa-history"
                menusystemitem
            >
                <app-history-overview
                    [Id]="thirdParty.Guid"
                    [historyDataService]="dataservice"
                    #historyDetailOverviewComponent
                ></app-history-overview>
            </div>

            <div
                *ngIf="thirdParty.Guid"
                data-uiid="thirdpartydetail-options"
                name="Options"
                menuicon="fas fa-check-double"
                menusystemitem
            >
                <detail-options
                    moduleName="third party"
                    [objectName]="thirdParty ? thirdParty.Name : 'thirdparty'"
                    [allowDelete]="permissions.canDelete"
                    (onDelete)="delete()"
                >
                </detail-options>
            </div>
        </div>
    </div>
</div>
