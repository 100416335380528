<div class="panel">
    <div class="title">Dashboard Settings</div>
    <div class="configurations">
        <div class="form-group">
            <label>Name *</label>
            <cerrix-textfield
                placeholder="Dashboard name"
                [(ngModel)]="config.name"
            ></cerrix-textfield>
        </div>

        <div class="form-group">
            <label>Description</label>
            <cerrix-textarea [(ngModel)]="config.description"></cerrix-textarea>
        </div>

        <div class="form-group">
            <label>Icon</label>
            <cerrix-icon-picker [(value)]="config.icon"></cerrix-icon-picker>
        </div>

        <div class="row">
            <div class="col">
                <cerrix-checkbox
                    [(value)]="config.default"
                    (valueChange)="defaultChanged($event)"
                    label="Personal default"
                ></cerrix-checkbox>
            </div>
            <div class="col">
                <cerrix-checkbox
                    [(value)]="config.favorite"
                    [disabled]="config.default"
                    label="Favorite"
                ></cerrix-checkbox>
            </div>
        </div>

        <hr
            *ngIf="
                permissions.RoleGroupLinker ||
                permissions.SystemDefaultSetter ||
                permissions.Publisher
            "
        />

        <div class="form-group" *ngIf="permissions.RoleGroupLinker">
            <label>Role group</label>
            <ng-select
                id="executableSelect"
                name="executableSelect"
                [items]="roleGroups"
                [(ngModel)]="config.roleGroups"
                bindLabel="Name"
                bindValue="ID"
                [closeOnSelect]="false"
                [multiple]="true"
                [clearable]="true"
                [loading]="!roleGroups"
            >
            </ng-select>
        </div>

        <div class="row">
            <div class="col" *ngIf="permissions.SystemDefaultSetter">
                <cerrix-checkbox
                    [(value)]="config.systemDefault"
                    (valueChange)="defaultChanged($event)"
                    label="System default"
                ></cerrix-checkbox>
            </div>
            <div class="col" *ngIf="permissions.Publisher">
                <cerrix-checkbox
                    [(value)]="config.public"
                    (valueChange)="defaultChanged($event)"
                    label="Make public"
                ></cerrix-checkbox>
                <i
                    class="fas fa-info-circle ms-2"
                    matTooltip="Please note: All public dashboards can be edited by Dashboard Administrators."
                ></i>
            </div>
        </div>
    </div>
</div>
