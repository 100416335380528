import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { PowerbiEmbedModel } from "../shared/powerbi-embed-model";
import { ReportItem } from "../shared/ReportItem";

@Injectable()
export class ReportDataService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "reports");
    }

    public getReport(id: number) {
        const url = "/" + id;
        return this.get<ReportItem>(url);
    }

    public getList() {
        return this.get<ReportItem[]>();
    }

    public getModules() {
        return this.get("/modules");
    }

    public hasAccess() {
        const url = "/access";
        return this.get<boolean>(url);
    }

    public getPowerBIEmbed(report: string): Observable<PowerbiEmbedModel> {
        return this.get<PowerbiEmbedModel>("/embed/" + report);
    }

    public export(report: number, filter: string): void {
        let url = "/export/" + report;
        if (filter) url = url + "?filter=" + filter;
        return this.download(url);
    }
}
