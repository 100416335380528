import { Component } from "@angular/core";
import { StandingDataView } from "../standing-data-view";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ToastrService } from "ngx-toastr";
import { CellRendererPipe } from "@app/shared/pipes/cell-renderer.pipe";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { FilterTree, ResetTreeFilter } from "@methods/TreeMethods";

@Component({
    selector: "standing-data-tree-view",
    templateUrl: "./standing-data-tree-view.component.html",
    styleUrls: ["./standing-data-tree-view.component.scss"],
})
export class StandingDataTreeViewComponent extends StandingDataView {
    data: CerrixTreeItem[];
    dataLoading = false;

    sortLoading = false;
    sortMessage = "";

    currentlyDragging: CerrixTreeItem;
    currentlyDraggingParent: CerrixTreeItem;
    currentlyDraggingOver: CerrixTreeItem;
    dropAbove = false;
    dropBelow = false;

    searchFilter = "";

    constructor(
        dataService: StandingdataDataService,
        private _toastr: ToastrService,
        public cellRenderer: CellRendererPipe,
        public _promptService: CerrixPromptService
    ) {
        super(dataService, cellRenderer, _promptService);
    }

    loadData(selected?: number) {
        this.data = null;
        this.dataLoading = true;
        this.dataService.getMaintenanceListByType(this.standingDataType).subscribe((x) => {
            this.data = x as CerrixTreeItem[];
            this.selected = selected;
            this.dataLoading = false;

            this.onDataLoaded.emit(this.data);
        });
    }

    toggleEditMode() {
        if (!this.editMode) {
            this.editMode = true;
            this.resetSearchFilter();
        } else {
            this.editMode = false;
            this.changeCallback(this.selected);
            if (this.searchFilter && this.searchFilter.length > 0) {
                this.search();
            }
        }
    }

    addNew(parentID?: number) {
        if (!this.editMode) {
            let row = {};

            if (this.config.onAdd) {
                const parentNode = parentID > 0 ? this.currentRow : null;
                row = this.config.onAdd(parentNode);
            }

            if (parentID > 0) {
                row[this.config.parentProp] = parentID;
            }

            this.addClick(row);
        }
    }

    search() {
        this.data = FilterTree(this.data, this.searchFilter);
    }

    resetSearchFilter() {
        this.searchFilter = "";

        ResetTreeFilter(this.data);
    }

    dragStart(node: CerrixTreeItem, parentNode: CerrixTreeItem, ev) {
        node.isExpanded = false;
        node.show = false;
        this.currentlyDragging = node;
        this.currentlyDraggingParent = parentNode;
        ev.stopPropagation();
    }

    dragStop(node: CerrixTreeItem, ev) {
        if (this.currentlyDragging && node.ID === this.currentlyDragging.ID) {
            node.show = true;
            this.currentlyDragging = null;
            this.currentlyDraggingParent = null;
            this.dragEnter(null);
            this.dropBelow = this.dropBelow = false;
        }

        ev.stopPropagation();
    }

    async dragDrop(target: CerrixTreeItem, parentNode: CerrixTreeItem, ev) {
        if (this.currentlyDragging == null || target.ID === this.currentlyDragging.ID) {
            return;
        }
        this.sortMessage = "";
        this.sortLoading = true;

        // Make copy of some variables, because DragDrop lifecycle will call dropStop eventually after this method
        // which causes these values to be reset.
        const dropAbove = this.dropAbove;
        const dropBelow = this.dropBelow;
        const movingObj = this.currentlyDragging;
        const movingParent = this.currentlyDraggingParent;

        let targetIndex: number;
        let targetParent: CerrixTreeItem;
        if (dropAbove || dropBelow) {
            let listToCheck = this.data;
            if (parentNode !== null) {
                listToCheck = parentNode.Children;
                parentNode.isExpanded = true;
            }

            targetIndex = listToCheck.indexOf(target) + (dropBelow ? 1 : 0);
            targetParent = parentNode;
        } else {
            if (!target.Children) {
                target.Children = [];
            }

            targetIndex = target.Children.length;
            targetParent = target;
        }

        this.sortMessage = `Moving '${movingObj.Name}' to position ${targetIndex + 1}`;
        if (
            (!targetParent && movingObj.ParentID) ||
            (targetParent && targetParent.ID !== movingObj.ParentID)
        ) {
            this.sortMessage += ` in '${targetParent ? targetParent.Name : "Root"}'`;
        }

        const updateResult = await this.dataService
            .sortByType(
                this.standingDataType,
                movingObj.ID,
                targetIndex,
                targetParent ? targetParent.ID : null
            )
            .toPromise();

        if (updateResult) {
            this._toastr.error(
                updateResult,
                ("" + this.sortMessage).replace("Moving", "Failed to move")
            );
            this.sortLoading = false;
            return;
        }

        this.removeFromParent(movingParent, movingObj);
        movingObj.ParentID = targetParent ? targetParent.ID : null;
        if (dropAbove || dropBelow) {
            if (targetParent) {
                targetParent.isExpanded = true;
                targetParent.Children.splice(targetIndex, 0, movingObj);
            } else {
                (this.data as any[]).splice(targetIndex, 0, movingObj);
            }
        } else {
            targetParent.isExpanded = true;
            targetParent.Children.splice(targetIndex, 0, movingObj);
        }

        this._toastr.success(("" + this.sortMessage).replace("Moving", "Moved"), "Success");
        this.animateMovedNode(movingObj);
        this.sortLoading = false;
    }

    dragEnter(node: CerrixTreeItem) {
        if (this.currentlyDraggingOver) {
            if (node && this.currentlyDraggingOver.ID === node.ID) {
                return;
            }

            this.currentlyDraggingOver.highlight = false;
            this.currentlyDraggingOver = null;
        }

        if (node) {
            node.highlight = true;
            this.currentlyDraggingOver = node;
        }
    }

    dragOver(ev) {
        const target = this.getNodeElem(ev.target);
        const sectionHeight = target.offsetHeight;
        const cursorLocation = ev.offsetY;
        const margin = 10;

        this.dropAbove = this.dropBelow = false;
        if (cursorLocation <= margin) {
            this.dropAbove = true;
        } else if (cursorLocation >= sectionHeight - margin) {
            this.dropBelow = true;
        }
    }

    getNodeElem(target) {
        let iteration = null;
        let stop = false;
        while (!stop) {
            iteration = iteration ? iteration.parentElement : target;
            if (!iteration || iteration.classList.contains("node")) {
                stop = true;
            }
        }

        return iteration;
    }

    removeFromParent(parent: CerrixTreeItem, node: CerrixTreeItem) {
        let listToCheck = this.data;
        // Parent is null so node was in root.
        if (parent !== null) {
            listToCheck = parent.Children;
        }

        const nodeIndex = listToCheck.indexOf(node);
        listToCheck.splice(nodeIndex, 1);
    }

    animateMovedNode(node: CerrixTreeItem) {
        node["newlocation"] = true;
        setTimeout(() => {
            node["newlocation"] = false;
        }, 1000);
    }
}
