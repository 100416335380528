import { Component, Input } from "@angular/core";
import { AuditFindingLinkModel } from "@app/audit-finding/shared/data-models/audit-finding-link-model";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { RendererType } from "@enums/RendererType";
import { StandingDataType } from "@enums/StandingDataType";
import { nameof } from "@methods/jeffs-toolkit";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { Observable } from "rxjs";
import { TabService } from "./../../../../../../services/tabs/TabService";

@Component({
    selector: "audit-detail-finding-linking",
    templateUrl: "./audit-detail-finding-linking.component.html",
})
export class AuditDetailFindingLinkingComponent {
    @Input() auditGuid: string;

    mainConfig: GenericListConfig;
    innerConfig: GenericListConfig;
    innerConfigDataMethod: (guid: string) => Observable<any>;

    constructor(
        private _ds: AuditDataService,
        private _sdDs: StandingdataDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    init() {
        if (!this.mainConfig) {
            this.initMainConfig();
            this.initInnerConfig();
        }
    }

    initMainConfig() {
        this.mainConfig = {
            name: "Linked findings",
            idProp: "guid",

            allowAdd: false,
            allowDelete: false,
            allowEdit: false,

            fields: [
                {
                    prettyName: "Identifier",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.identifier),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Finding name",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.findingName),
                    fieldType: GenericListFieldType.Text,
                    hideInOverview: true,
                    hideInEdit: true,
                },
                {
                    prettyName: "Finding description",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.findingDescription),
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    prettyName: "Recommendation",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.recommendation),
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    prettyName: "Severity",
                    fieldName: nameof<AuditFindingLinkModel>((x) => x.severity),
                    fieldType: GenericListFieldType.ColoredSelect,
                    getDataMethod: () => {
                        return this._sdDs.getAllByType(StandingDataType.AuditSeverity);
                    },
                },
            ],

            dataMethod: () => {
                return this._ds.getAuditLinkedFindings(this.auditGuid);
            },
            overviewRowActions: [],
        };
        this.mainConfig.overviewRowActions.push({
            icon: "fas fa-external-link-alt",
            tooltip: "Open details",
            clickEvent: (row: AuditFindingLinkModel) => {
                this._tabService.generateTab(
                    this._pages.AuditFindingDetail,
                    row.guid,
                    `(A.F.) ${+row.identifier} - ${row.findingName}`
                );
            },
        });
    }

    initInnerConfig() {
        this.innerConfig = <GenericListConfig>{
            name: "Linked measures of improvements",
            idProp: "guid",

            allowAdd: false,
            allowEdit: false,
            allowLink: false,

            fields: [
                {
                    prettyName: "Identifier",
                    fieldName: nameof<LinkedMoiModel>((x) => x.identifier),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Name",
                    fieldName: nameof<LinkedMoiModel>((x) => x.name),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Organization responsible",
                    fieldName: nameof<LinkedMoiModel>((x) => x.organizationResponsible),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Responsible",
                    fieldName: nameof<LinkedMoiModel>((x) => x.responsible),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Status",
                    fieldName: nameof<LinkedMoiModel>((x) => x.status),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Due date",
                    fieldName: nameof<LinkedMoiModel>((x) => x.dueDate),
                    fieldType: GenericListFieldType.Date,
                },
                {
                    prettyName: "Progress",
                    fieldName: nameof<LinkedMoiModel>((x) => x.progress),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            rendererConfig: [
                {
                    textColumn: nameof<LinkedMoiModel>((x) => x.dueDate),
                    actionColumn: nameof<LinkedMoiModel>((x) => x.isOverdue),
                    actionValue: "Yes",
                    type: RendererType.DueDate,
                    formatType: FormatType.DateFormat,
                },
                {
                    textColumn: nameof<LinkedMoiModel>((x) => x.progress),
                    type: RendererType.Progress,
                },
            ],
            overviewRowActions: [],
        };
        this.innerConfig.overviewRowActions.push({
            icon: "fas fa-external-link-alt",
            tooltip: "Open details",
            clickEvent: (row: LinkedMoiModel) => {
                this._tabService.generateTab(this._pages.MoiDetail, row.guid);
            },
        });
        this.innerConfigDataMethod = this._ds.getLinkedFindingsMois.bind(this._ds);
    }
}
