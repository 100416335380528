<div class="detail-page-card">
    <div class="detail-page-card-title">Details</div>
    <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>First name</label>
                    <cerrix-textfield
                        dataUiid="userinfo-firstname"
                        [(ngModel)]="user.firstname"
                        [disabled]="!permissions.canEditDetails"
                        [required]="true"
                    >
                    </cerrix-textfield>
                </div>
                <div class="form-group">
                    <label>Last name</label>
                    <cerrix-textfield
                        dataUiid="userinfo-lastname"
                        [(ngModel)]="user.lastname"
                        [disabled]="!permissions.canEditDetails"
                        [required]="true"
                    >
                    </cerrix-textfield>
                </div>
                <div class="form-group">
                    <label
                        >Email *
                        <i
                            class="fas fa-info-circle ms-2"
                            position="right"
                            matTooltip="Email is required if user has an authentication method or any role that requires an email address."
                        ></i>
                    </label>
                    <cerrix-textfield
                        dataUiid="userinfo-email"
                        type="email"
                        [(ngModel)]="user.email"
                        [disabled]="!permissions.canEditDetails"
                        placeholder="Conditionally required"
                    >
                    </cerrix-textfield>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Organization</label>
                    <cerrix-select-tree
                        data-uiid="userinfo-organization"
                        [tree]="sd.organizations"
                        *ngIf="sd.organizations"
                        [multiselect]="false"
                        [readonly]="!permissions.canEditDetails"
                        [value]="user.organization"
                        (onChangeSingleId)="user.organization = $event"
                    ></cerrix-select-tree>
                </div>
                <div class="form-group">
                    <label>Remarks</label>
                    <cerrix-textarea
                        dataUiid="userinfo-remarks"
                        [(ngModel)]="user.remarks"
                        [disabled]="!permissions.canEditDetails"
                    ></cerrix-textarea>
                </div>
            </div>
        </div>
    </div>
</div>
