<div class="row">
    <div class="col-12 col-xxl-6 chart-col">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Audit overview {{totalAudits && totalAudits > 0 ? '(' + totalAudits + ' total)' : ''}}</div>
            <div class="detail-page-card-body">
                <div class="fancy-loader" *ngIf="!chartRendered"></div>
                <div class="chart-container" #chart></div>
            </div>
        </div>
    </div>
    <div class="col-12 col-xxl-6 tables">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">Last successful
                        login{{audit && audit.lastSuccessfulLogins.length > 1 ? 's' : ''}}</div>
                    <div class="detail-page-card-body">
                        <div class="fancy-loader" *ngIf="!audit"></div>
                        <ul class="list-group list-group-flush" *ngIf="audit">
                            <li class="list-group-item" *ngIf="audit.lastSuccessfulLogins.length === 0">
                                None occurred
                            </li>
                            <li class="list-group-item" *ngFor="let date of audit.lastSuccessfulLogins">
                                {{date | dateTimeFormat}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">Last unsuccessful
                        login{{audit && audit.lastUnsuccessfulLogins.length > 1 ? 's' : ''}}</div>
                    <div class="detail-page-card-body">
                        <div class="fancy-loader" *ngIf="!audit"></div>
                        <ul class="list-group list-group-flush" *ngIf="audit">
                            <li class="list-group-item" *ngIf="audit.lastUnsuccessfulLogins.length === 0">
                                None occurred
                            </li>
                            <li class="list-group-item" *ngFor="let date of audit.lastUnsuccessfulLogins">
                                {{date | dateTimeFormat}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">Last password
                        reset{{audit && audit.lastPasswordResets.length > 1 ? 's' : ''}}</div>
                    <div class="detail-page-card-body">
                        <div class="fancy-loader" *ngIf="!audit"></div>
                        <ul class="list-group list-group-flush" *ngIf="audit">
                            <li class="list-group-item" *ngIf="audit.lastPasswordResets.length === 0">
                                None occurred
                            </li>
                            <li class="list-group-item" *ngFor="let date of audit.lastPasswordResets">
                                {{date | dateTimeFormat}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">Last password
                        change{{audit && audit.lastPasswordChanges.length > 1 ? 's' : ''}}</div>
                    <div class="detail-page-card-body">
                        <div class="fancy-loader" *ngIf="!audit"></div>
                        <ul class="list-group list-group-flush" *ngIf="audit">
                            <li class="list-group-item" *ngIf="audit.lastPasswordChanges.length === 0">
                                None occurred
                            </li>
                            <li class="list-group-item" *ngFor="let date of audit.lastPasswordChanges">
                                {{date | dateTimeFormat}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>