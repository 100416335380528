<div class="detail-page-card">
    <div class="detail-page-card-title" *ngIf="label">{{ label }}</div>
    <div class="detail-page-card-actions" *ngIf="isValidObject">
        <button
            data-uiid="reload-button"
            class="btn btn-raised"
            (click)="loadHistory()"
            [disabled]="isLoading"
        >
            <i class="fas fa-sync"></i>
            <span>Reload</span>
        </button>
    </div>

    <div class="fancy-loader my-4" *ngIf="isLoading"></div>
    <div class="detail-page-card-body padded text-center" *ngIf="!isLoading && !historyModels">
        History not available.
    </div>

    <div class="detail-page-card-body flush-expansion-panels" *ngIf="!isLoading && historyModels">
        <mat-accordion data-uiid="history-table">
            <mat-expansion-panel
                *ngFor="let historyModel of historyModels; index as i"
                (opened)="loadChanges(historyModel)"
            >
                <mat-expansion-panel-header [attr.data-uiid]="'history-table-row-header' + i">
                    <mat-panel-title [attr.data-uiid]="'history-table-row-title-' + i">
                        {{ historyModel.MutationDate | dateTimeFormat : true }}
                    </mat-panel-title>
                    <mat-panel-description [attr.data-uiid]="'history-table-row-description-' + i">
                        {{ historyModel.MutationAction }} by {{ historyModel.UserName }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div
                        class="fancy-loader submodule my-4"
                        *ngIf="!historyModel.CombinedChanges"
                    ></div>

                    <history-detail
                        *ngIf="historyModel.CombinedChanges"
                        [changes]="historyModel.CombinedChanges"
                        data-uiid="history-table-row-body"
                    >
                    </history-detail>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
