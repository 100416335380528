import { FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { FormFieldWidgetType } from "@app/forms/enums/FormFieldWidgetTypes";
import { FormStructureType } from "@app/forms/enums/FormStructureType";

export class FormField {
    ID: number;
    Name: string;
    ShowName: boolean;
    FieldType: number;
    Required: boolean;

    WorkspaceLabel: string;

    CustomSettings: string;
    MapBackField: number;
    Deleted: boolean;

    // Value that is provided on saved items, but not needed.
    PageID: number;

    // These are used onpage. But are not meant to be saved.
    AdditionalLabel: string;
    Valid: boolean;
    Selected = false;
    Validation: string;

    SourceDataCall: Promise<any>;
    SourceData: any;
}

export class FormFieldConfig {
    isAdminRequest: boolean;
    fieldType: FormFieldType;
    multiSelect?: boolean;

    // Structures
    structureType: FormStructureType;
    parameters: any[];

    // Options (radio, checkbox, dropdown)
    options: string[];
    minimumSelection?: number;
    maximumSelection?: number;

    // Date
    allowTimeInput: boolean;

    // Widgets
    widgetType?: FormFieldWidgetType;
    widgetParam?: any;

    // Video
    videoUrl?: string;
}
