import { CustomFieldType } from "@enums/customfields/CustomFieldType";
import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { nameof } from "./jeffs-toolkit";

export function getCustomFieldValueProperty(
    customField: CustomFieldDetailModel | CustomFieldStandingDataModel
): string {
    switch (customField.fieldType) {
        case CustomFieldType.SingleSelect: {
            return nameof<CustomFieldDetailModel>((x) => x.optionId);
        }
        case CustomFieldType.MultiSelect: {
            return nameof<CustomFieldDetailModel>((x) => x.optionIds);
        }
        case CustomFieldType.DateField: {
            return nameof<CustomFieldDetailModel>((x) => x.dateValue);
        }
        default: {
            return nameof<CustomFieldDetailModel>((x) => x.value);
        }
    }
}
