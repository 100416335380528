import { Component, ElementRef, ViewChild } from "@angular/core";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";

@Component({
    selector: "moi-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class MoiWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private moiDataService: MoiDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.moiDataService.getWidgetInfo(filters).toPromise();
        const config = this.getBaseChartConfig(null, data.series);
        config.plotOptions.series.stacking = "normal";
        config.xAxis = {
            categories: data.categories,
        };

        config.yAxis = {
            min: 0,
            allowDecimals: false,
            title: {
                text: "Number of Measures of Improvement",
            },
        };

        return config;
    }

    protected async openWorkspace(ev: Highcharts.PointClickEventObject) {
        const category = ev.point.category;
        const type = ev.point.series.name;

        const searchKeywords: SearchKeyword[] = [
            this.getSearchKeyword("Moi Type", type),
            ...this.getSearchKeywords(category?.toString()),
        ];

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.MoiOverview, preset);
    }

    private getSearchKeywords(category: string) {
        const searchKeywords: SearchKeyword[] = [];
        if (category === "Open MoIs") {
            searchKeywords.push(this.getSearchKeyword("Status", "In progress"));
            searchKeywords.push(this.getSearchKeyword("Status", "Ready for acceptance"));
            searchKeywords.push(this.getSearchKeyword("Status", "Accepted"));
        } else if (category === "Closed MoIs") {
            searchKeywords.push(this.getSearchKeyword("Status", "Closed"));
        } else if (category === "Overdue MoIs") {
            searchKeywords.push(this.getSearchKeyword("Is Overdue", "Yes"));
        }

        return searchKeywords;
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.MoiOverview, preset);
    }
}
