import { NgModule, ModuleWithProviders } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { ControlModule } from "../../control.module";

import { SimpleTestingDataService } from "./services/simple-testing.service";

import { SimpleTestingDetailComponent } from "./detail/simple-testing-detail.component";

import { SimpleTestingMainComponent } from "./detail/simple-testing-main/simple-testing-main.component";
import { SimpleTestingTestPlanComponent } from "./detail/simple-testing-testplan/simple-testing-test-plan.component";

@NgModule({
    declarations: [
        SimpleTestingDetailComponent,

        SimpleTestingMainComponent,
        SimpleTestingTestPlanComponent,
    ],
    imports: [FormsModule, CommonModule, SharedModule, ControlModule],
})
export class SimpleTestingModule {
    static forRoot(): ModuleWithProviders<SimpleTestingModule> {
        return {
            ngModule: SimpleTestingModule,
            providers: [SimpleTestingDataService],
        };
    }
}
