import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { firstValueFrom } from "rxjs";
import { JobInfoModel } from "../models/job-info-model";

@Injectable({
    providedIn: "root",
})
export class JobSchedulerDataService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "scheduler");
    }

    public async getList(): Promise<JobInfoModel[]> {
        return await firstValueFrom(this.get());
    }

    public async retryJob(jobId: number): Promise<void> {
        return await firstValueFrom(this.post(null, "?jobId=" + jobId));
    }
}
