import { Injectable } from '@angular/core';
import { ApiDataService } from '@services/http/ApiDataService';
import { Configuration } from '@app/app.constants';
import { HttpClient } from '@angular/common/http';
import { UserRoleGroupModel } from '../shared/user-role-group-model';

@Injectable()
export class UserRoleGroupService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "users/rolegroups");
    }

    public getOverview() {
        return this.get<UserRoleGroupModel[]>();
    }

    public getNewDetailed() {
        const url = `/new`;
        return this.get<UserRoleGroupModel>(url);
    }

    public getDetailed(roleGroupID: number) {
        const url = `/${roleGroupID}`;
        return this.get<UserRoleGroupModel>(url);
    }

    public storeDetailed(storable: UserRoleGroupModel) {
        return this.post<string>(storable);
    }

    public deleteDetailed(roleGroupID: number) {
        const url = `/${roleGroupID}`;
        return this.delete(url);
    }
}