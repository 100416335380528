<div class="detail-page-action-bar">
    <button
        class="btn btn-cerrix"
        (click)="import()"
        [disabled]="!(selectedImportType > 0) || !fileValid"
    >
        <i class="fas fa-save"></i>
        <span class="d-none d-lg-inline">Import</span>
    </button>

    <div class="workflow-area">
        <a
            target="_blank"
            href="/api/devmanagement/imports/templates/{{ selectedImportType }}"
            class="btn btn-secondary"
            *ngIf="selectedImportType > 0"
        >
            Download template
        </a>
    </div>
</div>

<div class="detail-page-card" *ngIf="importValidations && importValidations.length > 0">
    <div class="detail-page-card-title">Import Validations</div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="importValidations = null">
            <i class="fas fa-times"></i>
            <span>Clear all Validations</span>
        </button>
    </div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div
            class="alert alert-{{ item.Value ? 'danger' : 'success' }} alert-dismissible fade show"
            *ngFor="let item of importValidations"
        >
            <strong style="white-space: break-spaces"><div [innerHtml]="item.Key"></div></strong>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
            ></button>
        </div>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Import data</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="fancy-loader submodule" *ngIf="!importTypes"></div>
        <div *ngIf="importTypes">
            <div class="form-group">
                <label for="importTypeSelect">Import type</label>
                <ng-select
                    id="importTypeSelect"
                    name="importTypeSelect"
                    [items]="importTypes"
                    [(ngModel)]="selectedImportType"
                    bindLabel="Value"
                    bindValue="Key"
                    [closeOnSelect]="true"
                    [multiple]="false"
                    [clearable]="false"
                >
                </ng-select>
            </div>

            <div class="form-group">
                <label for="importFileInput">Select excel file</label>
                <input
                    type="file"
                    id="importFileInput"
                    [accept]="allowedFileExtensions"
                    [multiple]="false"
                    [hidden]="true"
                    (change)="updateFile()"
                    #fileInput
                />
                <div class="input-group input-group-sm" (click)="fileInput.click()">
                    <div class="input-group-prepend">
                        <button
                            class="btn btn-outline-secondary"
                            type="button"
                            id="importFileInput"
                        >
                            <span class="fas fa-folder-open"></span>
                            &nbsp;Browse&hellip;
                        </button>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        id="filedescription"
                        [value]="selectedFileName"
                        readonly
                        inputTooltip
                    />
                </div>
            </div>
        </div>
    </div>
</div>
