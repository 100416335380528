<div class="detail-page-card">
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="btn-toolbar">
            <button
                *ngIf="!disabled"
                class="btn btn-raised red pull-right"
                (click)="deleteEntity()"
            >
                <i class="fas fa-minus-circle"></i>
                <span>Delete third party</span>
            </button>
        </div>
        <form [formGroup]="entityForm" *ngIf="entityModel && entityForm">
            <div class="row">
                <div class="col-4 name">
                    <div class="form-group">
                        {{ entityModel.name }}
                    </div>
                </div>
                <div class="col-8">
                    <div class="form-group">
                        <ng-select
                            name="roleSelect"
                            [items]="entityRoles"
                            formControlName="role"
                            bindLabel="name"
                            bindValue="id"
                            [closeOnSelect]="true"
                            [multiple]="false"
                            [clearable]="false"
                            placeholder="Please select the role of the entity"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="entityRoleSelected">
                <div class="col-4">
                    <div class="form-group">
                        <cerrix-checkbox
                            *ngIf="showSubprocessor"
                            label="Sub-processor"
                            fontsize="0.9em"
                            formControlName="subProcessor"
                        >
                        </cerrix-checkbox>
                    </div>
                </div>
                <div class="col-8">
                    <div class="form-group">
                        <label>Systems required?</label>
                        <ng-select
                            name="SystemsRequired"
                            [items]="systemsRequired"
                            formControlName="systemsRequired"
                            bindLabel="name"
                            bindValue="id"
                            [closeOnSelect]="true"
                            [multiple]="false"
                            placeholder="Do you want to select system(s)?"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <hr />

            <div *ngIf="systemsRequiredSelected">
                <div *ngIf="!disabled" class="btn-toolbar">
                    <span *ngIf="showAddSystems && systemOptions.length === 0"
                        >Third party is missing systems</span
                    >
                    <button
                        *ngIf="showAddSystems && systemOptions.length > 0"
                        class="btn btn-raised pull-right"
                        (click)="showAddSystemsDialog()"
                    >
                        <i class="fas fa-plus-circle"></i>
                        <span>Add systems</span>
                    </button>
                </div>

                <div *ngFor="let systemModel of entityModel.systems" class="system-detail">
                    <system-detail
                        [systemModel]="systemModel"
                        [standingData]="standingData"
                        (systemDeleted)="deleteSystem($event)"
                        [disabled]="disabled"
                    >
                    </system-detail>
                </div>
            </div>
        </form>
    </div>
</div>
