export enum FormFieldType {
    Text = 1,
    TextArea = 2,
    Date = 3,
    Radio = 4,
    Checkbox = 5,
    Dropdown = 6,
    Info = 7,
    FileUpload = 8,
    Structure = 9,
    Number = 10,
    Widget = 11,
    Video = 12,
}

export class FormFieldTypeModel {
    type: FormFieldType;
    text: string;
    icon: string;
    description?: string;
}

export class FormFieldTypeDescription {
    public static 1: FormFieldTypeModel = {
        type: FormFieldType.Text,
        text: "Single line answer",
        icon: "fa-text-width",
        description:
            "One line field, giving the ability to give an open answer. Also known as textbox.",
    };

    public static 2: FormFieldTypeModel = {
        type: FormFieldType.TextArea,
        text: "Multiline answer",
        icon: "fa-paragraph",
        description:
            "Field that has multiple lines, giving the user the ability to give an open answer. Also known as textarea.",
    };

    public static 3: FormFieldTypeModel = {
        type: FormFieldType.Date,
        text: "Date",
        icon: "fa-calendar-alt",
        description:
            "Field that allows the user to give a date as answer. Can be configured to also allow time selection.",
    };

    public static 4: FormFieldTypeModel = {
        type: FormFieldType.Radio,
        text: "Radio (single option answer)",
        icon: "fa-check-circle",
        description:
            "Field that allows the user to select a maximum of one answer that is defined by the admin.",
    };

    public static 5: FormFieldTypeModel = {
        type: FormFieldType.Checkbox,
        text: "Checkbox",
        icon: "fa-check-square",
        description:
            "Field that allows the user to select a multiple or a specific range of answers defined by the admin. Options will be displayed underneath eachother on the page.",
    };

    public static 6: FormFieldTypeModel = {
        type: FormFieldType.Dropdown,
        text: "Dropdown",
        icon: "fa-chevron-circle-down",
        description:
            "Field that allows the user to select a multiple or a specific range of answers defined by the admin. Options will be displayed in a dropdown field.",
    };

    public static 7: FormFieldTypeModel = {
        type: FormFieldType.Info,
        text: "Information paragraph",
        icon: "fa-info-circle",
        description:
            "Field to supply the user with more information. This field has an open text editor that can be used to create custom messages for the user.",
    };

    public static 8: FormFieldTypeModel = {
        type: FormFieldType.FileUpload,
        text: "File upload",
        icon: "fa-upload",
        description: "Field that allows the user to upload files.",
    };

    public static 9: FormFieldTypeModel = {
        type: FormFieldType.Structure,
        text: "Structure / Source",
        icon: "fa-sitemap",
        description:
            "Field that can be filled with data (standing data) from the CERRIX application or default lists.",
    };

    public static 10: FormFieldTypeModel = {
        type: FormFieldType.Number,
        text: "Number",
        icon: "fa-hashtag",
        description: "Field that allows the user to give a number as answer.",
    };

    public static 11: FormFieldTypeModel = {
        type: FormFieldType.Widget,
        text: "Widgets",
        icon: "fa-chart-bar",
        description:
            "Field that shows the user custom widgets and if applicable interact with them.",
    };

    public static 12: FormFieldTypeModel = {
        type: FormFieldType.Video,
        text: "Video",
        icon: "fa-video",
        description: "Field that shows the user an embedded video.",
    };
}

export const FormFieldTypeModels: FormFieldTypeModel[] = [
    FormFieldTypeDescription[FormFieldType.Info],
    FormFieldTypeDescription[FormFieldType.Text],
    FormFieldTypeDescription[FormFieldType.TextArea],
    FormFieldTypeDescription[FormFieldType.Number],
    FormFieldTypeDescription[FormFieldType.Date],
    FormFieldTypeDescription[FormFieldType.Radio],
    FormFieldTypeDescription[FormFieldType.Checkbox],
    FormFieldTypeDescription[FormFieldType.Dropdown],
    FormFieldTypeDescription[FormFieldType.Structure],
    FormFieldTypeDescription[FormFieldType.FileUpload],
    FormFieldTypeDescription[FormFieldType.Widget],
    FormFieldTypeDescription[FormFieldType.Video],
];
