<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    module="task"
    [moduleType]="moduleType"
    idProp="guid"
    [rendererConfig]="rendererConfig"
    [permissions]="permissions"
    [CanAdd]="false"
    [DateProperties]="dateProps"
    (onDblClick)="openDetail($event)"
>
</cerrix-workspace>
