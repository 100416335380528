import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, share } from "rxjs";

import { Configuration } from "@app/app.constants";

@Injectable({ providedIn: "root" })
export class JwtAuthService {
    private tokenInfo: { accessToken: string; expiration: Date } | undefined = undefined;
    private sharedObservable: Observable<string> = null;

    constructor(private http: HttpClient, private configuration: Configuration) {
        this.sharedObservable = this.http
            .get<{ accessToken: string }>(`${this.configuration.ApiUrl}user/authentication/token`)
            .pipe(
                map((response) => {
                    const jwtToken = JSON.parse(window.atob(response.accessToken.split(".")[1]));
                    this.tokenInfo = {
                        accessToken: response.accessToken,
                        expiration: new Date(jwtToken.exp * 1000),
                    };
                    return response.accessToken;
                }),
                share()
            );
    }

    getAccessToken(): Observable<string> {
        const timeLimit = new Date(new Date().getTime() + 10000); //add additional 10 seconds
        if (this.tokenInfo?.accessToken && this.tokenInfo.expiration > timeLimit) {
            return new Observable((subscriber) => {
                subscriber.next(this.tokenInfo.accessToken);
                subscriber.complete();
            });
        }

        return this.sharedObservable;
    }
}
