<div class="header-container d-flex flex-row justify-content-between">
    <div class="h1-title" data-uiid="new-eff-popup-header-title">
        <i class="far fa-exclamation-triangle warning-icon" *ngIf="showIcon"></i>{{ title }}
    </div>
    <mat-icon
        class="close-icon"
        (click)="closeDialog()"
        data-uiid="new-eff-popup-header-close-button"
        >close</mat-icon
    >
</div>
