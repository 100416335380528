<cerrix-loader [cerrixTab]="tab"></cerrix-loader>
<div class="detail-page-action-bar flex-action-bar" *ngIf="model">
    <div>
        <button data-uiid="button-savetaskseries" class="btn btn-cerrix" (click)="save()">
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save</span>
        </button>

        <span class="date-edited"> Last save: {{ model.dateModified | dateTimeFormat }} </span>
    </div>
</div>

<div
    class="task-series-screen-system"
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    *ngIf="model"
>
    <div
        class="task-series-screens"
        [attr.name]="'Task series'"
        menuicon="fa-fw fa-tasks far"
        menusystemsubmenu
    >
        <div menuItemId="menuDetails" name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <task-series-detail-main
                [(model)]="model"
                [cerrixTab]="tab"
                [permissions]="permissions"
                #detailMain
            >
            </task-series-detail-main>
        </div>
        <div
            class="task-series-screens"
            [attr.menuItemId]="'menuLinks'"
            [attr.name]="'Links'"
            menuicon="fas fa-link"
            menusystemsubmenu
            *ngIf="isInitialized && this.id && permissions"
        >
            <div
                menuItemId="menuItemControls"
                name="Controls"
                menuicon="fas fa-shield-alt"
                menusystemitem
                *ngIf="model.linkedTypes.includes(taskType.ControlExecution)"
            >
                <!-- control linking disabled for now -->
                <task-series-controls
                    [(model)]="model"
                    [disabled]="true || !permissions.CanEdit"
                    #taskseriescontrols
                ></task-series-controls>
            </div>
            <div
                menuItemId="menuItemDocuments"
                name="Documents"
                menuicon="far fa-file"
                menusystemitem
            >
                <div *ngIf="!documents">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="documents">
                    <!-- AllowEdit is set to false to prevent problems with replacing a changed file in the related task and control execution documents.
                    The way it is implemented causes the task and control execution document to be deleted because these have an DocumentId
                    that does not match the document id of the task series -->
                    <document-manager-old
                        [(Documents)]="model.Documents"
                        (DocumentsChange)="checkDocumentsDirty()"
                        [permissions]="permissions.documentPermissions"
                        [AllowEdit]="false"
                        [DocumentTypeTargets]="targetModule"
                    >
                    </document-manager-old>
                </div>
            </div>
            <div
                menuItemId="menuItemHyperlinks"
                name="Hyperlinks"
                menuicon="fas fa-external-link-alt"
                menusystemitem
            >
                <div *ngIf="!model.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="model.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="model.Hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>
        <div *ngIf="id" name="Options" menuicon="fas fa-check-double" menusystemitem>
            <detail-options
                moduleName="task serie"
                [objectName]="model ? model.name : 'task serie'"
                [allowDelete]="permissions.canDelete"
                customText='Are you sure you want to delete the task series? Tasks marked "Done" will be removed as well.'
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
