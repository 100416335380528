export class WorkspaceModulePermissions {
    risk: WorkspaceModulePermissionModel;

    control: WorkspaceModulePermissionModel;
    advEffTesting: WorkspaceModulePermissionModel;

    event: WorkspaceModulePermissionModel;
    kri: WorkspaceModulePermissionModel;
    findingReport: WorkspaceModulePermissionModel;
    dataManagement: WorkspaceModulePermissionModel;
    thirdParty: WorkspaceModulePermissionModel;
    bim: WorkspaceModulePermissionModel;

    auditUniverse: WorkspaceModulePermissionModel;
    audit: WorkspaceModulePermissionModel;
    auditPeriod: WorkspaceModulePermissionModel;
    auditFinding: WorkspaceModulePermissionModel;

    task: WorkspaceModulePermissionModel;
}

export class WorkspaceModulePermissionModel {
    canAdd: boolean = false;
    canOpen: boolean = false;

    canPublish: boolean = false;
    isModuleAdmin: boolean = false;
    canSetSystemDefault: boolean = false;
}
