export class EventCommentModel {
    EventID: number;
    UserId: number;
    
    id: number;
    guid: string;
    createdAt: Date;
    createdBy: string;
    createdByRole: string;
    commentType: string;

    modifiedAt: Date;
    modifiedBy: string;

    comment: string;

    isDeleted: boolean;
    canEdit: boolean;
    canDelete: boolean;
}
