import { Component, Input, ViewChild } from "@angular/core";
import { UserDetailModel } from "@app/user/user-maintenance/shared/user-detail-model";
import { UserMaintenanceService } from "@app/user/user-maintenance/services/user-maintenance.service";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";

@Component({
    selector: "user-history",
    templateUrl: "./user-history.component.html",
    styleUrls: ["./user-history.component.scss"],
})
export class UserHistoryComponent {
    @Input() user: UserDetailModel;
    @ViewChild("historyComponent") historyComponent: HistoryOverviewComponent;

    loaded = false;

    constructor(public _historyDS: UserMaintenanceService) {}

    load() {
        if (!this.loaded) {
            this.historyComponent.loadHistory();
            this.loaded = true;
        }
    }
}
