import { Component, AfterViewInit, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { SharedDataService } from "@app/shared/services/shared-data.service";
import { MatrixModel, MatrixCell } from "@models/generic/MatrixModel";
import { MatrixSelectionModel } from "@models/generic/MatrixSelectionModel";

@Component({
    selector: "cerrix-riskmatrix",
    templateUrl: "./cerrix-riskmatrix.component.html",
    styleUrls: ["./cerrix-riskmatrix.component.scss"],
})
export class CerrixRiskMatrixComponent implements AfterViewInit, OnChanges {
    @Input() OrganizationID: number;
    @Input() matrix: MatrixModel;

    @Input() readonly: boolean;

    @Input() cellSize = 75;
    @Input() cellSpacing = 2;
    @Input() cellBorder = 0;
    @Input() highlightSelection = true;

    @Input() selected: MatrixCell;
    @Output() selectedChange = new EventEmitter<MatrixCell>();

    lastLoadedOrganization: number = null;

    borderTopCells: {} = null;
    borderRightCells: {} = null;

    constructor(private _sharedDS: SharedDataService) {}

    async ngAfterViewInit() {
        if (!this.matrix && this.OrganizationID > 0) {
            await this.loadFromOrganization();
        }
    }

    async ngOnChanges() {
        if (this.OrganizationID > 0 && this.OrganizationID !== this.lastLoadedOrganization) {
            await this.loadFromOrganization();
        }
        if (this.matrix && this.matrix.appetiteRange) {
            this.setSuggestedSelectionOutline(this.matrix.appetiteRange);
        }
    }

    async loadFromOrganization() {
        this.matrix = await this._sharedDS.getOrganizationMatrix(this.OrganizationID).toPromise();
        this.lastLoadedOrganization = this.OrganizationID;
    }

    matrixCellClick(y: number, x: number, color: string) {
        if (this.readonly) {
            return;
        }

        this.selected = <MatrixCell>{
            Likelihood: +y,
            Impact: +x,
            Color: color,
        };

        this.selectedChange.emit(this.selected);
    }

    // Creates an outline for a given range of MatrixSelectionModels
    public setSuggestedSelectionOutline(selectionRange: MatrixSelectionModel[]) {
        if (!selectionRange) {
            this.borderTopCells = null;
            this.borderRightCells = null;
            return;
        }

        this.borderTopCells = {};
        this.borderRightCells = {};

        // Get grouped object with Likelihood as keys
        const selectionRangePerRow = selectionRange.groupBy<MatrixSelectionModel>("Likelihood");

        const likelihoods = Object.keys(selectionRangePerRow).map((x) => +x);
        const maxLikelihood = Math.max.apply(Math, likelihoods);

        let previousRowMaxImpact: number;
        likelihoods.sortDesc().forEach((likelihood) => {
            const currentRow = selectionRangePerRow[likelihood] as MatrixSelectionModel[];
            let maxImpact = 0;

            currentRow.forEach((col, colIndex) => {
                maxImpact = col.Impact > maxImpact ? col.Impact : maxImpact;

                const colSelector = `${col.Likelihood}-${col.Impact}`;

                //Always give the max likelihood cell a top border and when current rows impact is lower than the previous rows impact
                if (col.Likelihood === maxLikelihood || col.Impact > previousRowMaxImpact) {
                    this.borderTopCells[colSelector] = true;
                }

                // Give the last cell in the row a right border
                if (colIndex === currentRow.length - 1) {
                    this.borderRightCells[colSelector] = true;
                }
            });

            previousRowMaxImpact = maxImpact;
        });
    }
}
