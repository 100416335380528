<div
    menuSystem
    [cerrixTab]="tab"
    class="details"
    (change)="handleMenuItemClick($event)"
    *ngIf="!tab.showLoader"
>
    <div class="detail-page-action-bar">
        <button class="btn btn-cerrix" (click)="save()" *ngIf="permissions.canEdit">
            <i class="fas fa-save"></i>
            <span data-uiid="save" class="d-none d-lg-inline">Save Period</span>
        </button>

        <span class="date-edited" *ngIf="model.id > 0">
            Last save: {{ model.dateModified | dateTimeFormat }}
        </span>

        <div class="workflow-area">
            <button
                (click)="closePeriod()"
                class="btn btn-warning"
                *ngIf="!model.dateClosed && permissions.canClosePeriod"
            >
                Close period
            </button>
        </div>
    </div>
    <div class="screens" name="Period" menuicon="far fa-calendar-week" menusystemsubmenu>
        <div
            name="Details"
            menuItemId="universe-period-detail"
            menuicon="fas fa-info-circle"
            menusystemitem
        >
            <audit-universe-period-detail-main
                [permissions]="permissions"
                [parentForm]="parentForm"
                [model]="model"
            >
            </audit-universe-period-detail-main>
        </div>
        <div
            name="History"
            menuItemId="history"
            menuicon="fas fa-history"
            menusystemitem
            *ngIf="permissions.canViewHistory"
        >
            <app-history-overview
                [Id]="model.guid"
                [historyDataService]="_ds"
                #historyDetailOverviewComponent
            ></app-history-overview>
        </div>
        <div
            name="Options"
            menuicon="fas fa-check-double"
            menusystemitem
            *ngIf="permissions.canDelete"
        >
            <detail-options
                moduleName="audit period"
                [objectName]="model.name"
                [allowDelete]="true"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
