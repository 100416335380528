<app-linkable
    typename="controls"
    [displayValueProp]="displayValueProp"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    [hideActions]="hideActions"
    [hideFilter]="hideFilter"
    [allowAddNew]="allowAddNew"
    [rowIconConfig]="rowIconConfig"
    [rendererConfig]="rendererConfig"
    (onOpenClicked)="openItem($event)"
    (onAddClicked)="addClicked()"
    (onReloadClicked)="reloadClicked()"
>
</app-linkable>
