import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { SimpleTestingType } from "../enums/SimpleTestingType";
import { SimpleTestingStandingData } from "../models/SimpleTestingStandingData";
import { SimpleTestingModel } from "../models/SimpleTestingModel";
import { SimpleTestingScore } from "../models/SimpleTestingScore";
import { DocumentModel } from "@models/documents/documentmodel";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { SimpleTestingCatalogueUpdateModel } from "../models/SimpleTestingCatalogueUpdateModel";
import { SimpleTestingPermissions } from "../models/SimpleTestingPermissions";
import { Observable } from "rxjs";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";

@Injectable()
export class SimpleTestingDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    getPermissions(type: SimpleTestingType, guid: string): Observable<SimpleTestingPermissions> {
        const url = `/${guid}/testing/simple/${type}/permissions`;
        return this.get<SimpleTestingPermissions>(url);
    }

    getSimpleTesting(type: SimpleTestingType, guid: string): Observable<SimpleTestingModel> {
        const url = `/${guid}/testing/simple/${type}`;
        return this.get<SimpleTestingModel>(url);
    }

    getSimpleTestingByVersion(
        type: SimpleTestingType,
        guid: string,
        version: number
    ): Observable<SimpleTestingModel> {
        const url = `/${guid}/testing/simple/${type}/version/${version}`;
        return this.get<SimpleTestingModel>(url);
    }

    getHyperlinks(type: SimpleTestingType, guid: string): Observable<HyperlinkModel[]> {
        const url = `/${guid}/testing/simple/${type}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    getCatalogueValues(
        type: SimpleTestingType,
        guid: string,
        catalogueID: number
    ): Observable<SimpleTestingCatalogueUpdateModel> {
        const url = `/${guid}/testing/simple/${type}/cataloguevalues/${catalogueID}`;
        return this.get<SimpleTestingCatalogueUpdateModel>(url);
    }

    getStandingData(type: SimpleTestingType, guid: string): Observable<SimpleTestingStandingData> {
        const url = `/${guid}/testing/simple/${type}/standingdata`;
        return this.get<SimpleTestingStandingData>(url);
    }

    getStandingDataByVersion(
        type: SimpleTestingType,
        guid: string,
        version: number
    ): Observable<SimpleTestingStandingData> {
        const url = `/${guid}/testing/simple/${type}/standingdata/version/${version}`;
        return this.get<SimpleTestingStandingData>(url);
    }

    getTestScores(type: SimpleTestingType, guid: string): Observable<SimpleTestingScore[]> {
        const url = `/${guid}/testing/simple/${type}/testscores`;
        return this.get<SimpleTestingScore[]>(url);
    }

    getUploaderEvidence(type: SimpleTestingType, guid: string): Observable<DocumentModel[]> {
        const url = `/${guid}/testing/simple/${type}/uploaderevidence`;
        return this.get<DocumentModel[]>(url);
    }

    // Store
    storeSimpleTesting(
        type: SimpleTestingType,
        guid: string,
        model: SimpleTestingModel
    ): Observable<void> {
        const url = `/${guid}/testing/simple/${type}`;
        return this.postWithDocuments<void>(model, model.Documents, url);
    }

    storeTestScore(
        type: SimpleTestingType,
        guid: string,
        score: SimpleTestingScore
    ): Observable<void> {
        const url = `/${guid}/testing/simple/${type}/testscore`;
        return this.postWithDocuments<void>(score, score.Documents, url);
    }

    storeEvidence(
        type: SimpleTestingType,
        guid: string,
        model: SimpleTestingModel
    ): Observable<void> {
        const url = `/${guid}/testing/simple/${type}/uploadevidence`;
        return this.postWithDocuments<void>(model, model.Documents, url);
    }

    doWorkflow(type: SimpleTestingType, guid: string, param: any): Observable<void> {
        const url = `/${guid}/testing/simple/${type}/workflow/${param}`;
        return this.post<void>(null, url);
    }

    public getHistory(guid: string, type: SimpleTestingType): Observable<HistoryModel[]> {
        const url = `/${guid}/testing/simple/${type}/history`;
        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(
        guid: string,
        mutationId: number,
        type: SimpleTestingType
    ): Observable<HistoryChange[]> {
        const url = `/${guid}/testing/simple/${type}/history/${mutationId}`;
        return this.get<HistoryChange[]>(url);
    }
}
