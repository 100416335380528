import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { CountWidgetAvailableModuleModel } from "@app/shared/models/workspace/count-widget-available-module.model";
import { CountWidgetResultModel } from "@app/shared/models/workspace/count-widget-result.model";
import { ApiDataService } from "@services/http/ApiDataService";
import { map, of } from "rxjs";

@Injectable()
export class WorkspaceCountService extends ApiDataService {
    constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "workspace/data/count");
    }

    private _cachedAvailableModules: CountWidgetAvailableModuleModel[];
    public getAvailableModules() {
        if (this._cachedAvailableModules) {
            return of(this._cachedAvailableModules);
        }

        return this.get<CountWidgetAvailableModuleModel[]>("/available-modules").pipe(
            map((data) => {
                this._cachedAvailableModules = data;
                setTimeout(() => {
                    this._cachedAvailableModules = null;
                }, 300000); // Clear the cache after 5 minutes (5min * 60sec * 1000milliseconds)
                return data;
            })
        );
    }

    public getCount(presetCode: string, prestId?: number) {
        let url = `?module=${presetCode}`;
        if (prestId) {
            url += `&presetId=${prestId}`;
        }

        return this.get<CountWidgetResultModel>(url);
    }
}
