@import "variables";

app-print {
    .logo {
        width: $menu-width;
        padding: 0 25px;
        margin: 5px 0;
        height: $navbar-height;
    }

    .flex-row {
        flex-direction: row;
    }

    .print-name {
        text-align: center;
        font-weight: bold;
    }

    label.lbl-big {
        font-size: 1.5em !important;
    }

    label.lbl-med {
        font-size: 1em !important;
    }

    .block-mouse {
        width: 100%;
        height: 100%;
        position: fixed;
        opacity: 0%;
        top: 0%;
    }

    * {
        // fix scrollbar showing up
        contain: layout;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }

    .padded-cell {
        text-overflow: initial;
        overflow: visible;
        white-space: normal;
    }

    @media print {
        @page {
            size: A4 portrait;
            margin: 1cm;
        }

        .pagebreak {
            page-break-before: always;
        }
    }
}
