import { DatamanagementPermissionModel } from "@app/datamanagement/models/DatamanagementPermissionModel";
import { MailPermissionModel } from "@app/mailmessages/models/mailPermissionModel";
import { AuditingModulePermissionsModel } from "./AuditingModulePermissionsModel";
import { DashboardPermissionModel } from "./DashboardPermissionModel";
import { DocumentPermissionModel } from "./DocumentPermissionModel";
import { FormPermissionModel } from "./FormPermissionModel";
import { UserMaintenancePermissionModel } from "./UserMaintenancePermissionModel";
import { WorkspaceModulePermissions } from "./WorkspaceModulePermissions";
import { DocumentTemplatesPermissionModel } from "./DocumentTemplatesPermissionModel";

export class ApplicationPermissionsModel {
    IsApplicationAdmin: boolean;
    IsDeveloperAdmin: boolean;
    IsEventAdmin: boolean;
    IsControlAdmin: boolean;
    IsBimAdmin: boolean;
    IsRiskAdmin: boolean;
    HasRiskAccess: boolean;
    HasControlAccess: boolean;
    HasEffectivenessExportAccess: boolean;
    HasEffectivenessCopyAccess: boolean;
    HasSharedControlAccess: boolean;
    HasEventAccess: boolean;
    HasIncidentAccess: boolean;
    HasRiskMoiAccess: boolean;
    HasControlMoiAccess: boolean;
    HasEventMoiAccess: boolean;
    HasImprovementManagementMoiAccess: boolean;
    HasProcessingPurposeMoiAccess: boolean;
    HasMoiAccess: boolean;
    HasKriAccess: boolean;
    HasFindingReportAccess: boolean;

    HasProcessEditorAccess: boolean;
    HasProcessEditorVisioImportAccess: boolean;
    HasProcessViewerAccess: boolean;
    HasBDManagementAccess: boolean;
    HasFDManagementAccess: boolean;
    HasReportingAccess: boolean;
    HasInviteAccess: boolean;
    HasDataManagementAccess: boolean;
    HasStandingDataAccess: boolean;
    HasPeriodAccess: boolean;
    HasDeletedItemsAccess: boolean;
    HasRiskExportAccess: boolean;
    HasRiskControlExportAccess: boolean;
    HasEventExportAccess: boolean;
    HasKriDataUploadAccess: boolean;
    HasKriExportAccess: boolean;
    HasImprovementManagementExportAccess: boolean;
    HasExportAccess: boolean;
    HasNavigatorAccess: boolean;
    HasInsightsAccess: boolean;
    HasWorkspacesAccess: boolean;
    HasAdminAccess: boolean;
    HasDataManagementExportAccess: boolean;
    HasAdvancedTestingAccess: boolean;
    HasAdvancedTestingExportAccess: boolean;
    HasThirdPartyAccess: boolean;
    HasTaskAccess: boolean = true;
    HasOrganizationBudgetAccess: boolean;

    HasUsersImportExportAccess: boolean;

    auditing: AuditingModulePermissionsModel;
    UserMaintenance: UserMaintenancePermissionModel;
    Documents: DocumentPermissionModel;
    DocumentTemplates: DocumentTemplatesPermissionModel;
    Forms: FormPermissionModel;
    Dashboard: DashboardPermissionModel;
    DataManagement: DatamanagementPermissionModel;
    MailMessages: MailPermissionModel;
    Workspace: WorkspaceModulePermissions;
}
