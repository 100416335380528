import { Component, OnInit } from "@angular/core";
import { ExportBase } from "@app/export/exporttypes/export-base";
import { LocalizationService } from "@app/shared";
import { FormGroup, FormControl } from "@angular/forms";
import { FormValidators } from "@app/shared/helpers/form-validators";
import { FormValidationMessageHelper } from "@app/shared/helpers/form-validation-message-helper";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { ToastrService } from "ngx-toastr";
import { getFormValue, getFormControl } from "@methods/CommonMethods";
import { ExportDataService } from "@app/export/services/export-data.service";

interface KriDataPointsExportModel {
    startDate: Date;
    endDate: Date;
}

@Component({
    selector: "export-kri",
    templateUrl: "./kris-data-points-export.component.html",
    styleUrls: ["./kris-data-points-export.component.scss"],
})
export class KrisDataPointsExportComponent extends ExportBase implements OnInit {
    public mainFormGroup: FormGroup;
    get startDate(): Date {
        return getFormValue<KriDataPointsExportModel>(this.mainFormGroup, "startDate");
    }
    get endDate(): Date {
        return getFormValue<KriDataPointsExportModel>(this.mainFormGroup, "endDate");
    }

    constructor(
        exportDataService: ExportDataService,
        private localizationService: LocalizationService,
        private toastr: ToastrService
    ) {
        super(exportDataService);
    }

    ngOnInit(): void {
        this.initFormValidation();
    }

    public exportKriDataPoints() {
        if (this.mainFormGroup.invalid) {
            FormValidationHelper.markAllAsTouched(this.mainFormGroup);

            const validationMessage = new FormValidationMessageHelper<KriDataPointsExportModel>({
                startDate: "Start date",
                endDate: "End date",
            })
                .processFormGroup(this.mainFormGroup)
                .getErrorMessage();

            this.toastr.warning(validationMessage, "Export failed.", { enableHtml: true });

            return;
        }

        const startDate = this.localizationService.formatInvariantDate(this.startDate);
        const endDate = this.localizationService.formatInvariantDate(this.endDate);

        const extra = `startdate=${startDate}&enddate=${endDate}`;
        this.exportDataService.downloadExport(this.ExportType.KrisDataPoints, extra);
    }

    initFormValidation() {
        this.mainFormGroup = new FormGroup(
            {
                startDate: new FormControl(null),
                endDate: new FormControl(null),
            },
            {
                validators: [
                    FormValidators.startEndDate<KriDataPointsExportModel>("startDate", "endDate"),
                    FormValidators.allEmptyOrAllFilled<KriDataPointsExportModel>(
                        "startDate",
                        "endDate"
                    ),
                ],
            }
        );
    }
}
