import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class MatPaginatorTranslated extends MatPaginatorIntl {
    public firstPageLabel = "First page";
    public previousPageLabel = "Previous page";
    public nextPageLabel = "Next page";
    public lastPageLabel = "Last page";
    public itemsPerPageLabel = "Items per page";

    public getRangeLabel = (page: number, pageSize: number, length: number) => {
        const ofText = " of ";

        if (length) {
            var endLenght = (page + 1) * pageSize;
            if (endLenght > length) {
                endLenght = length;
            }
            return `${page * pageSize + 1} - ${endLenght} ${ofText} ${length}`;
        } else {
            return `0 ${ofText} 0`;
        }
    };
}
