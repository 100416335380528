import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MoiModel } from "@models/moi/MoiModel";
import { MoiDataService } from "@app/moi/services/moi-data.service";
import { LinkModel } from "@models/generic/LinkModel";

@Component({
    selector: "moi-findings",
    templateUrl: "./moi-findings.component.html",
})
export class MoiFindingsComponent {
    @Input() model: MoiModel;
    @Input() disabled = false;

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    linked: LinkModel[];
    unlinked: LinkModel[];

    constructor(private moiDs: MoiDataService) {}

    load() {
        if (!this.model) {
            return;
        }

        this.isLoading = true;

        this.moiDs.getLinkableFindings(this.model.Guid).subscribe((value) => {
            this.loadData(this.model.LinkedFindings, value.sortBy("name", true));
            this.isLoading = false;
        });
    }

    linkedChanged(linked: LinkModel[]) {
        this.model.LinkedFindings = linked.map((x) => x.id);
        this.changed.emit(this.model.LinkedFindings);
    }

    private loadData(linkedIds: number[], items: LinkModel[]) {
        const linked = [],
            unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
