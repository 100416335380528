import { MatrixModel } from "@models/generic/MatrixModel";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ModuleType } from "@enums/ModuleType";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { CerrixRight } from "@enums/authorization/CerrixRight";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { Observable, of } from "rxjs";
import { ApiDataService } from "@services/http/ApiDataService";
import { ControlsUserType } from "./enums/ControlsUserType";
import { TargetModule } from "@enums/document/TargetModule";
import { addParameterToUrl } from "@methods/CommonMethods";
import { StandingDataType } from "@enums/StandingDataType";
import { ModuleSubType } from "@enums/ModuleSubType";

@Injectable()
export class StandingdataDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "standingdata");
    }

    public getStandingDataOverview() {
        return this.get<CerrixTreeItem[]>();
    }

    public getAllByType(type: number, ...selectedIds: number[]) {
        return this.getAll(type, false, ...selectedIds);
    }

    public allIncludeDeleted(type: number) {
        return this.getAll(type, true);
    }

    private getAll(type: number, includeDeleted: boolean, ...selectedIds: number[]) {
        let url = `/${type}?includeDeleted=${includeDeleted}`;

        if (selectedIds.any()) {
            url += `&ids=${selectedIds.join(",")}`;
        }

        return this.get<any[]>(url);
    }

    public getMaintenanceListByType(type: number) {
        const url = `/${type}/maintenance`;
        return this.get<any[]>(url);
    }

    public getByType(type: number, id: number) {
        const url = `/${type}/${id}`;
        return this.get<any>(url);
    }

    public getTreeByType(type: number) {
        const url = `/tree/${type}`;
        return this.get<any[]>(url);
    }

    public getExportByType(type: number): Observable<any> {
        const url = `/${type}/export`;
        return this.get<any>(url);
    }

    public storeByType(type: number, storable: any) {
        const url = `/${type}`;
        return this.post(storable, url);
    }

    public deleteByType(type: number, id: number) {
        const url = `/${type}/${id}`;
        return this.delete(url);
    }

    public sortByType(type: number, changedItemID: number, index: number, parentID?: number) {
        if (!parentID) {
            parentID = null;
        }
        const url = `/${type}/sort/${changedItemID}?index=${index}&extraParam=${parentID}`;
        return this.post<any>(null, url);
    }

    public getIdsDownByType(
        type: StandingDataType,
        selectedIds: number | number[]
    ): Observable<number[]> {
        let url = "";
        switch (type) {
            case StandingDataType.Organization:
                url = `/organizationidsdown`;
                break;
            case StandingDataType.BusinessDimensions:
                url = `/businessidsdown`;
                break;
            case StandingDataType.FrameworkDimensions: {
                url = `/frameworkidsdown`;

                break;
            }
            default:
                throw new Error(`Type:"${type}" is not supported for this call.`);
        }

        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get(url);
    }

    public getUsers(right: CerrixRight) {
        if (!right) {
            right = CerrixRight.Default;
        }

        const url = `/users/${right}`;
        return this.get(url) as Observable<IdNameCombination[]>;
    }

    public getOrganizationsIncludingSelected(
        module: ModuleType,
        selectedIds?: number | number[],
        asWriter?: boolean
    ) {
        return this.getOrganizationTree(module, selectedIds, asWriter);
    }

    public getOrganizations(module: ModuleType, asWriter?: boolean) {
        return this.getOrganizationTree(module, null, asWriter);
    }

    private getOrganizationTree(
        module: ModuleType,
        selectedIds: number | number[],
        asWriter?: boolean
    ) {
        if (!module) {
            throw new Error("Module must be defined.");
        }

        let url = `/organizations/${module}`;
        if (asWriter) {
            url = addParameterToUrl(url, "asWriter", asWriter);
        }

        if (selectedIds) {
            url = addParameterToUrl(url, "selectedIds", selectedIds);
        }

        return this.get<CerrixTreeItem[]>(url);
    }

    public getOrganizationMatrix(id: number) {
        const url = `/organization/${id}/matrix`;
        return this.get<MatrixModel>(url);
    }

    public getBusinessDimensionsByOrganization(organizationId: number, businessDimension?: number) {
        const bdId = businessDimension ? businessDimension : "null";

        const url =
            this.actionUrl +
            `/businessdimensions?organization=${organizationId}&businessDimension=${bdId}`;
        return this.http.get<CerrixTreeItem[]>(url);
    }

    public getUserViewableBusinessDimensions(module: ModuleType) {
        if (!module) {
            throw new Error("Module must be defined.");
        }

        const url = `/businessdimensions/${module}`;
        return this.get<CerrixTreeItem[]>(url);
    }

    public getFrameworkDimensions(businessDimension: number, frameworkDimension?: number) {
        const fdId = frameworkDimension ? frameworkDimension : "null";

        const url = `/frameworkdimensions?businessDimension=${businessDimension}&frameworkDimension=${fdId}`;
        return this.get<CerrixTreeItem[]>(url);
    }

    public getVisibleFrameworkDimensions(module: ModuleType, moduleSubType?: ModuleSubType) {
        let url = `/frameworkdimensions/${module}/`;
        if (moduleSubType) {
            url += moduleSubType;
        } else {
            url += "null";
        }

        return this.get<CerrixTreeItem[]>(url);
    }

    public getPeriods(module: ModuleType) {
        if (!module) {
            throw new Error("Module must be defined.");
        }

        const url = `/periods/${module}`;
        return this.get<IdNameCombination[]>(url);
    }

    public getRisks(organizationId): Observable<RiskLinkModel[]> {
        if (!organizationId) {
            organizationId = null;
        }

        const url = `/risks/${organizationId}`;
        return this.get<RiskLinkModel[]>(url);
    }

    public getDataSubjects() {
        const url = "/datasubjects";
        return this.get<CerrixTreeItem[]>(url);
    }

    public getDataStructures() {
        const url = "/datastructures";
        return this.get<CerrixTreeItem[]>(url);
    }

    public getEventCategory(): Observable<IdNameCombination[]> {
        const url = `/eventcategory`;
        return this.get<IdNameCombination[]>(url);
    }

    public getCountries() {
        const url = "/countries";
        return this.get<any>(url);
    }

    public getDiUsers(userType: ControlsUserType, selectedIds: number[] = null) {
        let url = `/diusers?userType=${userType}`;
        if (selectedIds) {
            url += `&ids=${selectedIds.join(",")}`;
        }
        return this.get<any>(url);
    }

    public getEffusers(userType: ControlsUserType, selectedIds: number[] = null) {
        let url = `/effusers?userType=${userType}`;
        if (selectedIds) {
            url += `&ids=${selectedIds.join(",")}`;
        }
        return this.get<any>(url);
    }

    public getTaskResponsibleusers() {
        let url = `/taskresponsibles`;
        return this.get<any>(url);
    }

    public getSampleTypes() {
        return this.get<any>("/sampletypes");
    }

    public getRiskOverrideTypes() {
        return this.get<any>("/riskoverridetypes");
    }

    public getDocumentTypeTargets() {
        const url = "/documenttypetargets";
        return this.get<IdNameCombination[]>(url);
    }

    public getDocumentTypes(docTargets: TargetModule | TargetModule[]) {
        let targets = [];
        if (docTargets) {
            if (Array.isArray(docTargets)) {
                targets = docTargets;
            } else {
                targets = [docTargets];
            }
        }

        let url = `/documenttypes`;
        if (targets.any()) {
            url += `?targets=${targets.join(",")}`;
        }

        return this.get<IdNameCombination[]>(url);
    }

    public getHyperlinkTypeTargets() {
        const url = "/hyperlinktypetargets";
        return this.get<IdNameCombination[]>(url);
    }

    public getHyperlinkTypes(targetModule: TargetModule | TargetModule[]) {
        let targets = [];
        if (targetModule) {
            if (Array.isArray(targetModule)) {
                targets = targetModule;
            } else {
                targets = [targetModule];
            }
        }

        let url = `/hyperlinktypes`;
        if (targets.any()) {
            url += `?targets=${targets.join(",")}`;
        }

        return this.get<IdNameCombination[]>(url);
    }

    public getControlTestFrequencies() {
        const url = "/controltestfrequencies";
        return this.get<any>(url);
    }

    public getThirdParties(thirdPartyId: number) {
        let url = `/thirdparties`;
        if (thirdPartyId > 0) {
            url += `?thirdPartyId=${thirdPartyId}`;
        }
        return this.get<IdNameCombination[]>(url);
    }
}
