import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "risk-get-assessment-per-org-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class RiskGetAssessmentPerOrgDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Risk Assessment per Organization API",
            description:
                "Returns an array of organizations with all linked risks on the organization. Every risk will also contain base assessment information and linked controls, events and mois." +
                " When no organization filter is supplied, all risks the user has access to are returned.",

            httpMethod: "GET",
            path: "/riskdata/riskassessmentperorganization",

            parameters: [
                {
                    name: "organizations",
                    description:
                        "Filters requested organizations. Supply full organization paths as a csv value with semicolon (;) separators. Requested organizations should not contain semicolons in the name or an error will be raised.",
                    paramType: "string",
                    in: "query",
                    required: false,
                    example: "Organization A;Organization A\\Child Organization 1",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: [
                        {
                            organizationName: "string",
                            organizationPath: "string",
                            periodName: "string",
                            requestDate: "string",
                            risks: [
                                {
                                    riskCatalogueName: "string",
                                    riskSubtypeName: "string",
                                    riskIdentifier: "string",
                                    riskName: "string",
                                    riskCause: "string",
                                    riskEffect: "string",
                                    businessDimensionPaths: "string[]",
                                    businessDimensions: "string[]",
                                    riskGrossLikelihood: "integer",
                                    riskGrossLikelihoodColor: "string",
                                    riskNetLikelihood: "integer",
                                    riskNetLikelihoodColor: "string",
                                    riskGrossImpact: "integer",
                                    riskGrossImpactColor: "string",
                                    riskNetImpact: "integer",
                                    riskNetImpactColor: "string",
                                    riskGrossScore: "integer",
                                    riskGrossScoreColor: "string",
                                    riskNetScore: "integer",
                                    riskNetScoreColor: "string",
                                    riskAppetiteInLine: "string ('Yes', 'No', 'Not Defined')",
                                    riskAppetiteInLineColor: "string",
                                    controls: [
                                        {
                                            controlIdentifier: "string",
                                            controlName: "string",
                                            controlDescription: "string",
                                            isKeyControl: "boolean",
                                            effectivenessTestingScore: "string",
                                            effectivenessTestingScoreColor: "string",
                                            lastTestDate: "datetime",
                                            testFrequency: "string",
                                        },
                                    ],
                                    events: [
                                        {
                                            eventIdentifier: "string",
                                            eventName: "string",
                                            eventStatus: "string",
                                            eventClassification: "string",
                                            eventClassificationColor: "string",
                                        },
                                    ],
                                    mois: [
                                        {
                                            moiIdentifier: "string",
                                            moiName: "string",
                                            moiStatus: "string",
                                            moiProgress: "string",
                                            moiPriority: "string",
                                            dueDate: "datetime",
                                            isOverdue: "boolean",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    }
}
