import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { AuditFieldworkStepModel } from "@app/audit/shared/data-models/audit-fieldwork-step-model";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { download, IEVersion } from "@methods/CommonMethods";
import { guid } from "@methods/uniqueMethods";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "audit-detail-fieldwork-execution-detail-manager",
    templateUrl: "./audit-detail-fieldwork-execution-detail-manager.component.html",
    styleUrls: ["./audit-detail-fieldwork-execution-detail-manager.component.scss"],
})
export class AuditDetailFieldworkExecutionDetailManagerComponent {
    @Input() steps: AuditFieldworkStepModel[];
    @Input() canEdit: boolean;
    @Output() onChange = new EventEmitter<never>();
    @ViewChild("spreadsheet") spreadsheetTemplate: TemplateRef<any>;

    private syncKey: string;

    constructor(
        private _promptService: CerrixPromptService,
        private _toastrService: ToastrService,
        private _config: Configuration
    ) {}

    manageExecutionDetails() {
        this._promptService.prompt({
            maxWidth: "1000px",
            maxHeight: "725px",
            data: {
                title: "Manage Execution Details",
                customTemplate: this.spreadsheetTemplate,
                cancelButton: {
                    text: "Close",
                },
                confirmButton: {
                    show: false,
                },
            },
        });
    }

    downloadExcel() {
        const pathLookup: { [id: number]: string } = {};
        const getPath = (step: AuditFieldworkStepModel) => {
            if (pathLookup[step.id]) {
                return pathLookup[step.id];
            }

            let pathPrefix = "";
            if (step.parentId) {
                const parent = this.steps.find((s) => s.id == step.parentId);
                pathPrefix = getPath(parent);
            }

            const path = (pathPrefix ? pathPrefix + " // " : "") + step.name;
            pathLookup[step.id] = path;
            return path;
        };

        this.syncKey = guid();
        const rows: any[][] = [
            [
                this.syncKey, // ID col
                "", // Step Path col
                "Do not change columns A & B. Add 'Execution Detail' headers and values from column C and higher.",
            ],
            ["ID", "Step name"],
        ];

        const processSteps = (steps: AuditFieldworkStepModel[]) => {
            steps.forEach((s) => {
                // This is the object where the execution details are stored as JSON.
                const stepExecution = s.executionDetails ? JSON.parse(s.executionDetails) : {};

                // Add cols not known into the mix.
                const colRows = Object.keys(stepExecution);
                colRows.forEach((c) => {
                    if (rows[1].indexOf(c) === -1 && stepExecution[c]) {
                        rows[1].push(c);
                    }
                });

                // Create a row object that contains base information and all values of the execution details.
                const row = [s.id, getPath(s)];
                rows[1].forEach((c, i) => {
                    if (i > 1) {
                        row.push(stepExecution[c]);
                    }
                });
                rows.push(row);

                // Loop through all child objects and make sure they are added in directly underneath their parent.
                const childSteps = this.steps.filter((c) => c.parentId == s.id);
                if (childSteps && childSteps.length > 0) {
                    processSteps(childSteps);
                }
            });
        };

        var rootNodes = this.steps.filter((s) => !s.parentId);
        processSteps(rootNodes);

        if (rows[1].length == 2) {
            rows.addRange(["Method"]);
        }

        const workbook = new ExcelJS.Workbook();

        // Create a worksheet with the first two columns frozen and give all cols a default width.
        const worksheet = workbook.addWorksheet("Step Execution Details", {
            views: [{ state: "frozen", xSplit: 2 }],
        });
        worksheet.properties.defaultColWidth = 50;
        worksheet.addRows(rows);

        // The cells for the warning are merged and given a red bold font.
        worksheet.mergeCells("C1:E1");
        worksheet.getCell("C1").font = { bold: true, color: { argb: "00ff0000" } };

        // Make the headers bold.
        const headerRow = worksheet.getRow(2);
        headerRow.font = { bold: true };
        headerRow.commit();

        // Hide the "ID" column since user does not need to see this.
        worksheet.getColumn(1).hidden = true; // This is the ID column
        worksheet.getColumn(2).width = 75;

        // This is copied from Export.ts. I could now quickly think of a way to only have this defined only once.
        workbook.xlsx
            .writeBuffer({
                base64: true,
            })
            .then(function (xls64) {
                const data = new Blob([xls64], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileName = `AuditFieldworkExecutionTemplate_${moment().format(
                    "YYYYMMDDxHHmmss"
                )}.xlsx`;
                if (IEVersion() > 0) {
                    (window.navigator as any).msSaveOrOpenBlob(data, fileName);
                } else {
                    const url = URL.createObjectURL(data);
                    download(url, fileName);
                }
            });
    }

    processExcel(ev) {
        // This is the uploaded file.
        const file = ev.target.files[0];

        const importWb = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result;
            importWb.xlsx.load(buffer).then((workbook) => {
                this.mapWorkbookToSteps(workbook);
            });
        };
    }

    private async mapWorkbookToSteps(workbook: any) {
        const worksheet = workbook.getWorksheet(1);
        const worksheetKey = worksheet.getCell("A1").value;

        let showConfirm = worksheetKey != this.syncKey;
        let confirmTitle = "Excel file mismatched.";
        let confirmMessage =
            "The uploaded excel file is not the file downloaded with the latest changes. Are you sure you want to continue?";

        if (!this.syncKey) {
            showConfirm = true;
            confirmTitle = "Excel file not downloaded";
            confirmMessage =
                "The uploaded file is not downloaded in the current session and may not be compatible with the current Steps. Are you sure you want to continue?";
        }

        if (showConfirm) {
            const result = await firstValueFrom(
                this._promptService
                    .confirmCustom({
                        maxHeight: "300px",
                        maxWidth: "350px",
                        data: {
                            title: confirmTitle,
                            message: confirmMessage,
                        },
                    })
                    .getResult()
            );

            if (!result) {
                return;
            }
        }

        const cols = [];

        // The second row contains the headers.
        worksheet.getRow(2).eachCell((cell, i) => {
            // We take all headers that are from cell C and onwards.
            if (i > 2 && cell.value && cell.value != "undefined") {
                cols.push(cell.value);
            }
        });

        const nameLookup: { [id: number]: string } = {};
        const lookup: { [id: number]: {} } = {};
        // Starting at row 3 are the actual steps.
        for (let rowIndex = 3; rowIndex <= worksheet.rowCount; rowIndex++) {
            const row = worksheet.getRow(rowIndex);
            // We see if the id is set, if it is not we will continue.
            if (row.getCell(1).value) {
                const ID = +row.getCell(1).value;
                const values = {};
                // We make an object where the headers are the properties and the values are the cell values of the row.
                for (let cellIndex = 0; cellIndex < cols.length; cellIndex++) {
                    const cellValue = "" + row.getCell(cellIndex + 3).value;
                    if (cellValue && cellValue != "null") {
                        values[cols[cellIndex]] = cellValue;
                    }
                }

                nameLookup[ID] = row.getCell(2).value;
                lookup[ID] = values;
            }
        }

        let failedMatches = [];
        this.steps.forEach((s) => {
            const rowValue = lookup[s.id];
            if (rowValue) {
                const executionDetails =
                    Object.keys(rowValue).length > 0 ? JSON.stringify(lookup[s.id]) : null;

                if (executionDetails && executionDetails.length > this._config.MaxTextAreaLength) {
                    failedMatches.push(nameLookup[s.id]);
                } else {
                    s.executionDetails = executionDetails;
                }
            }
        });

        if (failedMatches.length > 0) {
            this._toastrService
                .warning(
                    `${failedMatches.length} step(s) exceeded the character limit. Click here to see which step(s).`,
                    "Partial success",
                    { disableTimeOut: true }
                )
                .onTap.subscribe(() => {
                    this._promptService.alert({
                        maxHeight: failedMatches.length * 50 + 200 + "px",
                        maxWidth: "600px",
                        data: {
                            title: "Steps exceeding character limit",
                            message: failedMatches.join("\n\n"),
                        },
                    });
                });
        } else {
            this._toastrService.success("Execution details imported successfully.", "Success");
        }

        this.onChange.emit();
    }
}
