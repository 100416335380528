import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "../../app.constants";
import { Observable } from "rxjs";
import { ApiDataService } from "@services/http/ApiDataService";
import { ModuleType } from "@enums/ModuleType";
import { PeriodicNotificationMoiStandingDataModel } from "../models/periodicNotificationMoiStandingDataModel";
import { PeriodicNotificationMoiModel } from "../models/periodicNotificationMoiModel";

@Injectable()
export class PeriodicNotificationMoiService extends ApiDataService {
    constructor(protected http: HttpClient, protected configuration: Configuration) {
        super(http, configuration, "periodicnotifications");
    }

    module = ModuleType.IM;

    getNotification(id: number): Observable<PeriodicNotificationMoiModel> {
        const url = `/${id}/${this.module}`;
        return this.get<PeriodicNotificationMoiModel>(url);
    }

    getNotificationStandingData(): Observable<PeriodicNotificationMoiStandingDataModel> {
        const url = "/standingdata?module=" + this.module;
        return this.get<PeriodicNotificationMoiStandingDataModel>(url);
    }

    getOrganizations() {
        const url = "/organizations";
        return this.get<any[]>(url);
    }

    saveNotification(notification: PeriodicNotificationMoiModel): Observable<number> {
        return this.post(notification);
    }

    deleteNotification(id: number): Observable<boolean> {
        const url = `/${id}/${this.module}`;
        return this.delete(url);
    }
}
