<div class="row">
    <div class="col">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Username/Password login</div>
            <div class="detail-page-card-actions">
                <button
                    class="btn btn-raised"
                    [disabled]="user.formsAuthentications.length === 1"
                    (click)="addMethod(authenticationType.FormsLogin)"
                >
                    <i class="fas fa-plus"></i>
                    <span class="d-none d-lg-inline ms-2">Add</span>
                </button>
                <button
                    class="btn btn-raised red"
                    [disabled]="user.formsAuthentications.length === 0"
                    (click)="
                        removeMethod(authenticationType.FormsLogin, user.formsAuthentications[0].ID)
                    "
                >
                    <i class="fas fa-trash"></i>
                    <span class="d-none d-lg-inline ms-2">Remove</span>
                </button>
            </div>
            <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
                <ng-container *ngFor="let method of user.formsAuthentications">
                    <div class="form-group">
                        <label>Username</label>
                        <div class="input-group">
                            <cerrix-textfield
                                placeholder="Recipient's username"
                                [(ngModel)]="method.username"
                                [limitInput]="true"
                            >
                            </cerrix-textfield>
                            <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    (click)="copyEmail(method)"
                                >
                                    Use email
                                </button>
                            </div>
                        </div>
                        <small class="text-muted" *ngIf="method.ID < 0"
                            >The user will receive an email with a password set link after
                            saving.</small
                        >
                    </div>
                    <div class="form-group row">
                        <div class="col">
                            <div class="input-group">
                                <cerrix-checkbox
                                    label="Disable login"
                                    fontsize="0.9em"
                                    [(value)]="method.disabled"
                                >
                                </cerrix-checkbox>
                                <small *ngIf="method.disabled">
                                    Username is reserved for this user but the user will not be able
                                    to login.
                                </small>
                            </div>
                        </div>
                        <div class="col-4 text-end" *ngIf="method.ID > 0">
                            <button
                                class="btn btn-outline-secondary"
                                type="button"
                                (click)="resetPasswordRequest(method)"
                            >
                                <i class="far fa-undo-alt" aria-hidden="true"></i>
                                <span class="d-none d-lg-inline ms-2">Reset Password</span>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="detail-page-card">
            <div class="detail-page-card-title">AD login</div>
            <div class="detail-page-card-actions">
                <button
                    class="btn btn-raised"
                    [disabled]="user.adAuthentications.length === 1"
                    (click)="addMethod(authenticationType.AdLogin)"
                >
                    <i class="fas fa-plus"></i>
                    <span class="d-none d-lg-inline ms-2">Add</span>
                </button>
                <button
                    class="btn btn-raised red"
                    [disabled]="user.adAuthentications.length === 0"
                    (click)="removeMethod(authenticationType.AdLogin, user.adAuthentications[0].ID)"
                >
                    <i class="fas fa-trash"></i>
                    <span class="d-none d-lg-inline ms-2">Remove</span>
                </button>
            </div>
            <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
                <ng-container *ngFor="let method of user.adAuthentications">
                    <div class="form-group">
                        <label>Username</label>
                        <div class="input-group">
                            <cerrix-textfield
                                cssClass="form-control"
                                placeholder="Recipient's username"
                                [(ngModel)]="method.username"
                                inputTooltip
                            ></cerrix-textfield>
                            <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    (click)="copyEmail(method)"
                                >
                                    Use email
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <cerrix-checkbox
                            label="Disable login"
                            fontsize="0.9em"
                            [(value)]="method.disabled"
                        ></cerrix-checkbox>
                        <small *ngIf="method.disabled"
                            >Username is reserved for this user but the user will not be able to
                            login.</small
                        >
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
