import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { BaseDocumentsService } from "../../shared/cerrix-documents/services/base-documents.service";
import { SelectOptionModel } from "@cerrix/components";
import { IncidentDocumentTypeService } from "./incident-document-type.service";
import { DocumentMethods } from "@methods/DocumentMethods";
import { DocumentDataService } from "@services/http/document/DocumentDataService";
import { ToastrService } from "ngx-toastr";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { PopupService } from "@app/shared/services/popup/popup.service";

@Injectable({
    providedIn: "root",
})
export class IncidentDocumentsService extends BaseDocumentsService {
    private documentTypeService = inject(IncidentDocumentTypeService);
    private documentDataService = inject(DocumentDataService);
    private toastrService = inject(ToastrService);
    private tabService = inject(TabService);
    private pages = inject(Pages);
    private _popupService = inject(PopupService);

    getDocumentTypes(): Observable<SelectOptionModel[]> {
        return this.documentTypeService.getAll();
    }

    getDocumentIcon(fileName: string): string {
        return DocumentMethods.getDocumentIcon(fileName);
    }

    getDocumentConfig(): Observable<{ maxUploadSizeInMb: number; allowedFileExtensions: string }> {
        return this.documentDataService.GetDocumentConfig();
    }

    filterAllowedFiles(files: File[] | FileList, allowedExtensions: string): File[] {
        return DocumentMethods.filterAllowedFiles(this.toastrService, files, allowedExtensions);
    }

    validateUploadLimit(files: File[], maxUploadSizeInMb: number): boolean {
        return DocumentMethods.validateUploadLimit(maxUploadSizeInMb, this.toastrService, files);
    }

    openDocumentViewer(documentId: string, documentName: string, pdfSrc?: string): void {
        this._popupService.forceOpenInNewWindow(pdfSrc);
    }
}
