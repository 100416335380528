import { Component, Input, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuditUniverseLinkableModel } from "@app/audit-universe/shared/data-models/audit-universe-linkable-model";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditContextConfigModel } from "@app/audit/shared/data-models/context/audit-context-config-model";
import { AuditContextStandingDataModel } from "@app/audit/shared/data-models/context/audit-context-standingdata-model";
import { AuditDetailPermissionModel } from "@app/audit/shared/permission-models/audit-detail-permission-model";
import { CerrixSelectTreeComponent } from "@app/shared/cerrix-select-tree/cerrix-select-tree.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { RendererType } from "@enums/RendererType";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "audit-detail-context",
    templateUrl: "./audit-detail-context.component.html",
    styleUrls: ["./audit-detail-context.component.scss"],
})
export class AuditDetailContextComponent {
    @Input() auditModel: AuditModel;
    @Input() permissions: AuditDetailPermissionModel;

    formGroup: FormGroup;
    auditUniverseConfig: GenericListConfig;
    customFieldData: { [key: number]: CustomFieldInfoModel } = {};

    standingData: AuditContextStandingDataModel;

    @ViewChild("orgTree") orgTree: CerrixSelectTreeComponent;
    @ViewChild("businessTree") businessTree: CerrixSelectTreeComponent;

    private _initExecuted = false;

    loaded = false;

    constructor(
        private _ds: AuditDataService,
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {}

    init(): void {
        if (this._initExecuted) {
            return;
        }

        this._initExecuted = true;
        this._ds.getAuditContextConfig(this.auditModel.guid).subscribe({
            next: (configModel: AuditContextConfigModel) => {
                this.initModel(configModel);

                this.createFormGroup();
                this.createAuditUniverseConfig();

                this.loaded = true;
            },
            error: () => {
                this._initExecuted = false;
            },
        });
    }

    private initModel(configModel: AuditContextConfigModel): void {
        this.standingData = configModel.standingData;

        this.auditModel.context = configModel.contextModel;
    }

    private createFormGroup(): void {
        const customFieldsFormArray = this.createCustomFieldsFormArray();

        this.formGroup = new FormGroup({
            organizationIds: new FormControl(
                this.auditModel.context.organizationIds,
                Validators.required
            ),
            businessDimensionIds: new FormControl(this.auditModel.context.businessDimensionIds),
            frameworkDimensionIds: new FormControl(this.auditModel.context.frameworkDimensionIds),
            customFields: customFieldsFormArray,
        });

        if (!this.permissions.canEditDetails) {
            this.formGroup.disable();
        } else {
            this.formGroup.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.formGroup, this.auditModel.context);

                this.auditModel.context.contextValues = this.formGroup.value["customFields"];
            });
        }
    }

    public onSave(): void {
        if (this.formGroup) {
            FormValidationHelper.markAllAsTouched(this.formGroup);
        }
    }

    private createCustomFieldsFormArray(): FormArray<FormGroup> {
        const customFieldsFormArray = new FormArray([]);

        if (this.auditModel.id > 0) {
            this.standingData.contextFields.forEach((field: CustomFieldStandingDataModel) => {
                const answerField = this.auditModel.context.contextValues.find(
                    (x) => x.customFieldId === field.ID
                );

                const customFieldFormGroup = new FormGroup<any>({
                    ID: new FormControl(answerField ? answerField.ID : 0),
                    Name: new FormControl(field.Name),
                    customFieldId: new FormControl(field.ID),
                    required: new FormControl(field.required),
                });

                const valueProperty = getCustomFieldValueProperty(field);
                customFieldFormGroup.addControl(
                    valueProperty,
                    new FormControl(
                        answerField ? answerField[valueProperty] : null,
                        field.required ? [Validators.required] : []
                    )
                );

                // Store extra information from the custom field in a custom object which is easier accessible in the front-end
                this.customFieldData[field.ID] = {
                    Name: field.Name,
                    fieldType: field.fieldType,
                    required: field.required,
                    answerOptions: field.answerOptions,
                };

                customFieldsFormArray.push(customFieldFormGroup);
            });
        }

        return customFieldsFormArray;
    }

    private createAuditUniverseConfig(): void {
        if (!this.permissions.canViewLinkedAuditUniverse || this.auditModel.id <= 0) {
            return;
        }

        const linked = [];
        const unlinked = [];

        this.standingData.linkableUniverseObjects.forEach((d) => {
            if (d !== undefined && d !== null) {
                if (this.auditModel.context.linkedUniverseObjectIds.indexOf(d.id) >= 0) {
                    linked.push(d);
                } else {
                    unlinked.push(d);
                }
            }
        });

        this.auditUniverseConfig = <GenericListConfig>{
            name: "Linked Universe Objects",
            idProp: nameof<AuditUniverseLinkableModel>((x) => x.id),

            allowAdd: false,
            allowEdit: false,
            allowDelete: false,
            allowLink: this.permissions.canLinkAuditUniverse,

            isSortableByColumn: true,
            limitViewTo: 5,

            linkData: <any>{
                idValueProp: nameof<AuditUniverseLinkableModel>((x) => x.id),
                displayValueProp: nameof<AuditUniverseLinkableModel>((x) => x.name),
                unlinked,
                linkablePaginationItemCount: 15,
            },

            data: linked,
            fields: [
                {
                    prettyName: "Name",
                    fieldName: nameof<AuditUniverseLinkableModel>((x) => x.name),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Overall Score",
                    fieldName: nameof<AuditUniverseLinkableModel>((x) => x.evaluatedRiskScore),
                    fieldType: GenericListFieldType.Text,
                },
                {
                    prettyName: "Date",
                    fieldName: nameof<AuditUniverseLinkableModel>((x) => x.dateChanged),
                    fieldType: GenericListFieldType.Text,
                },
            ],

            rendererConfig: [
                {
                    actionColumn: nameof<AuditUniverseLinkableModel>(
                        (x) => x.evaluatedRiskScoreColor
                    ),
                    textColumn: nameof<AuditUniverseLinkableModel>((x) => x.evaluatedRiskScore),
                    type: RendererType.Score,
                },
                {
                    actionColumn: nameof<AuditUniverseLinkableModel>((x) => x.assessmentScoreColor),
                    textColumn: nameof<AuditUniverseLinkableModel>((x) => x.assessmentScore),
                    type: RendererType.Score,
                },
                {
                    type: RendererType.Score,
                    textColumn: "Evaluated Risk Score",
                    actionColumn: nameof<AuditUniverseLinkableModel>(
                        (x) => x.evaluatedRiskScoreColor
                    ),
                    hideActionColumn: true,
                },
                {
                    type: RendererType.Score,
                    textColumn: "Assessment Score",
                    actionColumn: nameof<AuditUniverseLinkableModel>((x) => x.assessmentScoreColor),
                    hideActionColumn: true,
                },
            ],

            overviewRowActionsName: "Open",
            overviewRowActions: [
                {
                    icon: "fas fa-external-link",
                    tooltip: "Open",
                    clickEvent: (item: AuditUniverseLinkableModel) => {
                        const guid = this.standingData.linkableUniverseObjects.find(
                            (x) => x.id === item.id
                        ).guid;
                        if (!guid) {
                            this._toastr.warning(
                                "Could not open the requested item.",
                                "Item not available"
                            );
                        } else {
                            this._tabService.generateTab(this._pages.AuditUniverseDetail, guid);
                        }
                    },
                },
            ],

            onLinkedDataChange: async (data: AuditUniverseLinkableModel[]) => {
                const addedData = data.filter(
                    (uo) => !this.auditModel.context.linkedUniverseObjectIds.includes(uo.id)
                );

                this.auditModel.context.linkedUniverseObjectIds.splice(
                    0,
                    this.auditModel.context.linkedUniverseObjectIds.length
                );
                this.auditModel.context.linkedUniverseObjectIds.push(...data.map((d) => d.id));

                for (const uoLink of addedData) {
                    uoLink.organizationIds = uoLink.organizationIds.filter(
                        (oId) => !this.auditModel.context.organizationIds.includes(oId)
                    );
                    uoLink.businessDimensionIds = uoLink.businessDimensionIds.filter(
                        (bdId) => !this.auditModel.context.businessDimensionIds.includes(bdId)
                    );

                    let whatToAdd = "";
                    if (uoLink.organizationIds.length > 0) {
                        whatToAdd += "Organizations";
                    }
                    if (uoLink.businessDimensionIds.length > 0) {
                        if (whatToAdd.length > 0) {
                            whatToAdd += " and ";
                        }
                        whatToAdd += "Business Dimensions";
                    }

                    if (whatToAdd.length > 0) {
                        const addOrgBd = await this._promptService
                            .confirmCustom({
                                maxWidth: "400px",
                                maxHeight: "350px",
                                data: {
                                    title: `Adding ${whatToAdd}`,
                                    message: `Would you like to add the ${whatToAdd.toLowerCase()} that are part of ${
                                        uoLink.name
                                    }?\n`,
                                },
                            })
                            .toPromise();

                        if (addOrgBd) {
                            this.auditModel.context.organizationIds.push(...uoLink.organizationIds);
                            this.auditModel.context.businessDimensionIds.push(
                                ...uoLink.businessDimensionIds
                            );

                            if (uoLink.organizationIds.length > 0) {
                                this.orgTree.reload([...this.auditModel.context.organizationIds]);
                            }
                            if (uoLink.businessDimensionIds.length > 0) {
                                this.businessTree.reload([
                                    ...this.auditModel.context.businessDimensionIds,
                                ]);
                            }
                        }
                    }
                }
            },
        };
    }
}
