/**
 * Takes absolution position of main-page into account
 * @param node HTML element to scroll to
 */
export function scrollToElement(node: HTMLElement) {
    if (node) {
        // Node might not be visible yet
        setTimeout(() => {
            // Because window isn't the one with the scrollbar we need to get parent with scrollbar
            const scrollParent = getScrollParent(node);
            const originalParentScrollHeight = scrollParent.scrollTop;

            // Can't use smooth scrolling because scrollBy will stop animation
            node.scrollIntoView();

            // Check if node was already in view and scroll was not necessary
            if (originalParentScrollHeight !== scrollParent.scrollTop) {
                // Compensate for absolute main-page
                scrollParent.scrollBy(0, -115);
            }
        }, 0);
    }
}

/**
 * Get first parent that is scrollable
 * @param node HTML element to get scrollable parent of
 */
export function getScrollParent(node: HTMLElement): HTMLElement {
    if (!node) {
        return null;
    }

    const overflowY = window.getComputedStyle(node).overflowY;
    const isScrollable = !(overflowY.includes("hidden") || overflowY.includes("visible"));
    if (isScrollable && node.scrollHeight >= node.clientHeight) {
        return node;
    }

    return node.parentNode ? getScrollParent(node.parentNode as HTMLElement) : document.body;
}
