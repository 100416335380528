import { HistoryChange, CombinedHistoryChange } from "@models/history/HistoryModel";
import { LocalizationService } from "@app/shared";

export class HistoryMethods {
    constructor() {}

    static processChanges(changes: HistoryChange[], localizationService: LocalizationService): CombinedHistoryChange[] {
        const combinedChanges: CombinedHistoryChange[] = [];

        let previous: CombinedHistoryChange = null;
        for (const change of changes) {
            const historyChange = new HistoryChange();
            historyChange.ColumnName = change.ColumnName;
            historyChange.HistoryType = change.HistoryType;

            // Format values
            historyChange.OldValue = this.processChangeValue(change.OldValue, change, localizationService);
            historyChange.NewValue = this.processChangeValue(change.NewValue, change, localizationService);

            // Combine linked table changes
            if (previous && previous.ColumnName === historyChange.ColumnName) {
                previous.Changes.push(historyChange);
            } else {
                previous = new CombinedHistoryChange();
                previous.ColumnName = historyChange.ColumnName;
                previous.Changes.push(historyChange);

                combinedChanges.push(previous);
            }
        }

        return combinedChanges;
    }

    static processChangeValue(value: any, change: HistoryChange, localizationService: LocalizationService) {
        if (value) {
            if (value instanceof Boolean) {
                return value ? "Yes" : "No";
            } else if (value instanceof Date || /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2,4}$/.test(value)) {
                if (change.dateFormat) {
                    return localizationService.formatDateByEnum(value, change.dateFormat);
                } else {
                    return localizationService.formatDate(value, localizationService.localizationInfo.dateTimeFormat);
                }
            }
        }

        return value;
    }
}
