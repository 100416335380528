import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { SelectColoredOption } from "./models/SelectColoredOption";
import { EffSelectComponent } from "../eff-select/eff-select.component";

@Component({
    selector: "eff-colored-selector",
    templateUrl: "./eff-colored-selector.component.html",
    styleUrls: ["./eff-colored-selector.component.scss"],
})
export class EffColoredSelectorComponent {
    @Input() options: Array<SelectColoredOption> = [];
    @Input() label: string;
    @Input() model: any;
    @Input() formController: FormControl = new FormControl();
    @Input() disabled: boolean = false;
    @Input() triggerOpen: boolean = false;
    @Output() modelChange: EventEmitter<any> = new EventEmitter();

    @ViewChild("selector") selector: EffSelectComponent;

    ngOnInit() {
        this.formController.valueChanges.subscribe((value) => {
            this.model = value;
            this.modelChange.emit(value);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["triggerOpen"]) {
            this.open();
        }
    }

    open() {
        this.selector?.open();
    }

    close() {
        this.selector?.close();
    }
}
