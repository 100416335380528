import { ILinkWidgetGeneratorType } from "./../../../shared/interfaces/ILinkWidgetGeneratorType";
import { DashboardQuickLinkModel } from "../../../shared/models/DashboardQuickLinkModel";
import { Pages } from "@constants/pages/Pages";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";

export class AdminLinkGenerator implements ILinkWidgetGeneratorType {
    getTypes(permissions: ApplicationPermissionsModel, pages: Pages): DashboardQuickLinkModel[] {
        const quickLinks = [];
        if (permissions.Documents.Admin) {
            quickLinks.push({
                title: "Admin Documents",
                icon: "fad fa-folder",
                page: pages.AdminDocuments,

                description: "Overview of all stored documents.",
            });
        }

        if (permissions.DocumentTemplates.HasAccess) {
            quickLinks.push({
                title: "Document Templates",
                icon: "fad fa-folder",
                page: pages.DocumentTemplates,

                description: "Overview of all document templates.",
            });
        }

        if (permissions.Documents.Admin) {
            quickLinks.push({
                title: "Bulk Deleted Documents",
                icon: "fad fa-trash",
                page: pages.BulkDeletedDocuments,

                description: "Overview of bulk deleted documents.",
            });
        }

        if (permissions.HasDeletedItemsAccess) {
            quickLinks.push({
                title: "Deleted Items",
                icon: "fad fa-trash",
                page: pages.DeletedItems,

                description: "Overview of deleted items per module.",
            });
        }

        if (permissions.MailMessages.HasMailAccess) {
            quickLinks.push({
                title: "Mail Messages",
                icon: "fad fa-envelope",
                page: pages.MailMessages,

                description: "Maintenance of all mail messages per module.",
            });
        }

        if (permissions.HasInviteAccess) {
            quickLinks.push({
                title: "Invite",
                icon: "fad fa-paper-plane",
                page: pages.Invite,

                description: "Send invitations from CERRIX.",
            });
        }

        if (permissions.HasPeriodAccess) {
            quickLinks.push({
                title: "Periods",
                icon: "fad fa-business-time",
                page: pages.Periods,

                description: "Set periods for Risk and MoI module.",
            });
        }

        if (permissions.HasStandingDataAccess) {
            quickLinks.push({
                title: "Standing Data",
                icon: "fad fa-edit",
                page: pages.StandingDataOverview,

                description: "Tables for maintenance of standing data.",
            });
        }

        if (permissions.UserMaintenance.hasAccess) {
            quickLinks.push({
                title: "User Management",
                icon: "fad fa-users-cog",
                page: pages.UserManagement,

                description: "User management for access, user rights and roles.",
            });
        }

        if (permissions.IsApplicationAdmin) {
            quickLinks.push({
                title: "External Connections",
                icon: "fad fa-exchange-alt",
                page: pages.ExternalConnections,
            });
        }

        if (permissions.IsApplicationAdmin) {
            quickLinks.push({
                title: "Environment Settings",
                icon: "fad fa-cogs",
                page: pages.Settings,

                description: "General settings for application.",
            });
        }

        quickLinks.push({
            title: "Scheduler Status",
            icon: "fad fa-clock",
            page: pages.SchedulerStatus,
            description: "Status of pending and finished jobs.",
        });

        if (permissions.HasOrganizationBudgetAccess) {
            quickLinks.push({
                title: "Organizational Budget",
                icon: "fad fa-piggy-bank",
                page: pages.OrganizationBudgets,

                description: "Budget management for organizations",
            });
        }

        return quickLinks;
    }
}
