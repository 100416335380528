<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div
    menuSystem
    [cerrixTab]="tab"
    collapsed="true"
    class="advanced-testing"
    (change)="onMenuItemClick($event)"
    data-uiid="control-advanced-testing-details-page"
>
    <div *ngIf="finishedLoading && !hideActionBar" class="action-bar clearfix">
        <button
            class="btn btn-cerrix ng-star-inserted"
            (click)="save()"
            *ngIf="
                workflowStepPermission.evidenceSaveAllowed ||
                sourceDocumentUploadAllowed ||
                sampleGenerationAllowed
            "
            data-uiid="control-advanced-testing-save-button"
        >
            Save
        </button>

        <div class="workflow-area">
            <span class="workflow-description">
                Workflow Status |
                <b>{{ workflowStepPermission.statusDescription }}</b>
            </span>
            <span>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="nonOccurrenceCompleteAllowed"
                    (click)="openReopenNonOccurrenceDialog()"
                    data-uiid="control-advanced-testing-non-occurrence-reopen-button"
                >
                    Reopen
                </button>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="reviewNonOccurrenceAllowed"
                    (click)="openReviewerNonOccurrenceAcceptDialog()"
                    data-uiid="control-advanced-testing-non-occurrence-accept-button"
                >
                    Accept
                </button>
                <button
                    class="btn btn-warning"
                    type="button"
                    *ngIf="reviewNonOccurrenceAllowed"
                    (click)="openReviewerNonOccurrenceModifyConclusionDialog()"
                    data-uiid="control-advanced-testing-non-occurrence-modify-button"
                >
                    Modify
                </button>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="reviewNonOccurrenceAllowed"
                    (click)="openReviewerNonOccurrenceRejectDialog()"
                    data-uiid="control-advanced-testing-non-occurrence-reject-button"
                >
                    Reject
                </button>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="testNonOccurrenceAllowed"
                    (click)="openTesterNonOccurrenceConclusionDialog()"
                    data-uiid="control-advanced-testing-non-occurrence-conclusion-button"
                >
                    Non occurrence conclusion
                </button>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="testNonOccurrenceAllowed"
                    (click)="openTesterNonOccurrenceRejectDialog()"
                    data-uiid="control-advanced-testing-tester-non-occurrence-reject-button"
                >
                    Reject non occurrence
                </button>
                <button
                    class="btn btn-primary"
                    type="button"
                    *ngIf="sourceDocumentUploadAllowed"
                    (click)="finishUploadingSourceDocuments()"
                    data-uiid="control-advanced-testing-upload-source-document-button"
                >
                    Finish uploading source documents
                </button>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="sourceDocumentUploadAllowed"
                    (click)="controlDidNotOccur()"
                    data-uiid="control-advanced-testing-didnt-occur-button"
                >
                    Control didn't occur
                </button>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="sampleGenerationAllowed"
                    (click)="openGenerateSampleDialog()"
                    data-uiid="control-advanced-testing-generate-samples-button"
                >
                    Generate samples
                </button>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="completeUploadingEvidenceAllowed"
                    (click)="completeUploadingEvidence()"
                    data-uiid="control-advanced-testing-complete-upload-evidence-button"
                >
                    Complete uploading evidence
                </button>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="completeAssessmentAllowed"
                    (click)="openFinalConclusionDialog()"
                    data-uiid="control-advanced-testing-final-conclusion-button"
                >
                    Final conclusion
                </button>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="completeAssessmentAllowed"
                    (click)="openMissingEvidenceDialog()"
                    data-uiid="control-advanced-testing-missing-evidence-button"
                >
                    Missing evidence
                </button>
                <button
                    class="btn btn-success"
                    type="button"
                    *ngIf="reviewAllowed"
                    (click)="openReviewerAcceptDialog()"
                    data-uiid="control-advanced-testing-accept-button"
                >
                    Accept
                </button>
                <button
                    class="btn btn-warning"
                    type="button"
                    *ngIf="reviewAllowed"
                    (click)="openReviewerModifyConclusionDialog()"
                    data-uiid="control-advanced-testing-modify-button"
                >
                    Modify
                </button>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="reviewAllowed"
                    (click)="openReviewerRejectDialog()"
                    data-uiid="control-advanced-testing-reject-button"
                >
                    Reject
                </button>
                <button
                    class="btn btn-warning"
                    type="button"
                    *ngIf="completeAllowed"
                    (click)="openTestPeriodScoreDialog()"
                    data-uiid="control-advanced-testing-score-test-period-button"
                >
                    Score test period
                </button>
                <button
                    class="btn btn-dark"
                    type="button"
                    *ngIf="completeAllowed"
                    (click)="openReopenDialog()"
                    data-uiid="control-advanced-testing-reopen-button"
                >
                    Reopen
                </button>
            </span>
        </div>
    </div>
    <div name="Control Test plan" menuicon="fas fa-shield-alt" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <div *ngIf="finishedLoading">
                <div class="detail-page-summary">
                    <div class="row">
                        <div
                            class="col-lg-4"
                            data-uiid="control-advanced-testing-testplan-name-div"
                        >
                            <div class="property"><b>Test plan:</b> {{ details.name }}</div>
                            <div class="property">
                                <b>Effectiveness score:</b>
                                <div
                                    class="summary-score-color"
                                    [style.background-color]="
                                        details.scoreColor ? details.scoreColor : ''
                                    "
                                ></div>
                                {{ details.scoreText }}
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div
                                class="property"
                                data-uiid="control-advanced-testing-test-period-name-div"
                            >
                                <b>Test period:</b> {{ details.groupName }}
                            </div>
                            <div *ngIf="details.groupGuid" class="property">
                                <b>Period score:</b>
                                <div
                                    class="summary-score-color"
                                    data-uiid="control-advanced-testing-test-period-score-div"
                                    [style.background-color]="
                                        details.groupScoreColor ? details.groupScoreColor : ''
                                    "
                                ></div>
                                {{ details.groupScoreText }}
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div
                                class="property"
                                data-uiid="control-advanced-testing-start-date-div"
                            >
                                <b>Start:</b> {{ details.startDate | dateFormat }}
                            </div>
                            <div class="property" data-uiid="control-advanced-testing-end-date-div">
                                <b>End:</b> {{ details.endDate | dateFormat }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cerrix-compact">
                    <div class="detail-page-card mt-3">
                        <div
                            class="detail-page-card-title"
                            data-uiid="control-advanced-testing-control-details-block"
                        >
                            Control details
                            <div class="boolswitch">
                                <mat-slide-toggle
                                    name="boolvalue"
                                    (change)="toggleFields($event)"
                                    [(checked)]="showAllFields"
                                    size="small"
                                    color="primary"
                                    data-uiid="control-advanced-testing-show-all-testplan-info-toggle"
                                >
                                    Show all Test Plan information
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="detail-page-card-body padded">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Control identifier</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col input-group">
                                            <input
                                                type="text"
                                                [value]="details.controlIdentifier"
                                                class="form-control form-control-sm"
                                                data-uiid="control-advanced-testing-control-identifier-input"
                                                readonly
                                                inputTooltip
                                            />
                                            <label
                                                class="input-group-text action-button"
                                                (click)="openControl()"
                                                data-uiid="control-advanced-testing-open-control-button"
                                            >
                                                <i class="fas fa-external-link-alt"></i>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Control name</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <textarea
                                                class="form-control form-control-sm"
                                                style="min-height: 137px"
                                                data-uiid="control-advanced-testing-control-name-textarea"
                                                readonly
                                                inputTooltip
                                                >{{ details.controlName }}</textarea
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Test instructions</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <textarea
                                                class="form-control form-control-sm"
                                                style="min-height: 137px"
                                                data-uiid="control-advanced-testing-test-instructions-textarea"
                                                readonly
                                                inputTooltip
                                                >{{ details.testInstructions }}</textarea
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Control description</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <textarea
                                                class="form-control form-control-sm"
                                                style="min-height: 205px"
                                                data-uiid="control-advanced-testing-control-description-textarea"
                                                readonly
                                                inputTooltip
                                                >{{ details.controlDescription }}</textarea
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showFields" class="detail-page-card mt-3">
                        <div
                            class="detail-page-card-title"
                            data-uiid="control-advanced-testing-basic-test-information-block"
                        >
                            Basic test information
                        </div>
                        <div class="detail-page-card-body padded">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Linked standard</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input
                                                type="text"
                                                [value]="details.linkedStandard"
                                                class="form-control form-control-sm"
                                                data-uiid="control-advanced-testing-linked-standard-input"
                                                readonly
                                                inputTooltip
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Quality aspects</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input
                                                type="text"
                                                [value]="details.qualityAspects"
                                                class="form-control form-control-sm"
                                                data-uiid="control-advanced-testing-quality-aspects-input"
                                                readonly
                                                inputTooltip
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row">
                                        <label class="col col-form-label font-weight-bold"
                                            >Test norm</label
                                        >
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <textarea
                                                class="form-control form-control-sm"
                                                style="min-height: 101px; height: initial"
                                                data-uiid="control-advanced-testing-test-norm-textarea"
                                                readonly
                                                inputTooltip
                                                >{{ details.testNorm }}</textarea
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="detail-page-card mt-3">
                        <div class="detail-page-card-title">Source documents</div>
                        <div class="detail-page-card-body padded">
                            <div class="row">
                                <div class="col-lg-{{ showFields ? '6' : '12' }}">
                                    <adv-eff-source-documents
                                        *ngIf="workflowStepPermission.canViewSourceDocuments"
                                        [sourceDocuments]="sourceDocuments"
                                        [sourceDocumentsFormGroup]="sourceDocumentsFormGroup"
                                        [disabled]="
                                            !sourceDocumentUploadAllowed && !sampleGenerationAllowed
                                        "
                                    >
                                    </adv-eff-source-documents>
                                </div>
                                <div *ngIf="showFields" class="col-lg-6">
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="row">
                                                <label class="col col-form-label font-weight-bold"
                                                    >Sample population</label
                                                >
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <input
                                                        type="text"
                                                        [value]="details.samplePopulation"
                                                        class="form-control form-control-sm"
                                                        data-uiid="control-advanced-testing-sample-population-input"
                                                        readonly
                                                        inputTooltip
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="col col-form-label font-weight-bold"
                                                    >Sample size</label
                                                >
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <input
                                                        type="text"
                                                        [value]="details.numberOfTestSamples"
                                                        class="form-control form-control-sm"
                                                        data-uiid="control-advanced-testing-sample-size-input"
                                                        readonly
                                                        inputTooltip
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="col col-form-label font-weight-bold"
                                                    >Sample type</label
                                                >
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <input
                                                        type="text"
                                                        [value]="details.sampleTypeDescription"
                                                        class="form-control form-control-sm"
                                                        data-uiid="control-advanced-testing-sample-type-input"
                                                        readonly
                                                        inputTooltip
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="row">
                                                <label class="col col-form-label font-weight-bold"
                                                    >Seed number</label
                                                >
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <input
                                                        type="text"
                                                        [value]="details.seedNumber"
                                                        class="form-control form-control-sm"
                                                        data-uiid="control-advanced-testing-seed-number-input"
                                                        readonly
                                                        inputTooltip
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="col col-form-label font-weight-bold"
                                                    >Manual generation reason</label
                                                >
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <textarea
                                                        class="form-control form-control-sm"
                                                        style="min-height: 101px; height: initial"
                                                        data-uiid="control-advanced-testing-manual-generation-reason-textarea"
                                                        readonly
                                                        inputTooltip
                                                        >{{
                                                            details.sampleGenerationReason
                                                        }}</textarea
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            !(sourceDocumentUploadAllowed || sampleGenerationAllowed) ||
                            showAllFields
                        "
                        class="detail-page-card mt-3"
                    >
                        <div
                            class="detail-page-card-title"
                            data-uiid="control-advanced-testing-evidence-block"
                        >
                            Evidence and testing
                        </div>
                        <div class="detail-page-card-body padded">
                            <adv-eff-evidence
                                [useReviewer]="useReviewer"
                                [workflowStepPermission]="workflowStepPermission"
                                [evidenceSamples]="evidenceSamples"
                                [showAllFields]="!requestEvidenceUpload || showAllFields"
                                (sampleEditRemark)="onSampleEditRemark($event)"
                            ></adv-eff-evidence>
                        </div>
                    </div>

                    <div *ngIf="showFields" class="detail-page-card mt-3">
                        <div
                            class="detail-page-card-title"
                            data-uiid="control-advanced-testing-conclusions-block"
                        >
                            Conclusion
                        </div>
                        <div class="detail-page-card-body padded">
                            <adv-eff-conclusions
                                [conclusions]="conclusions"
                                [useReviewer]="useReviewer"
                            ></adv-eff-conclusions>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [attr.name]="'Links'" menuicon="fas fa-link" menusystemsubmenu>
            <div name="Risks" menuicon="fas fa-exclamation-triangle" menusystemitem>
                <div class="detail-page-card">
                    <div class="detail-page-card-title">Linked Risks</div>
                    <div class="detail-page-card-body padded cerrix-compact">
                        <adv-eff-linked-risks
                            [linkedRisks]="linkedRisks"
                            (riskClicked)="openRisk($event)"
                        >
                        </adv-eff-linked-risks>
                    </div>
                </div>
            </div>
        </div>
        <div name="Workflow History" menuicon="fas fa-history" menusystemitem>
            <div class="detail-page-card">
                <div class="detail-page-card-title">Workflow History</div>
                <div class="detail-page-card-body padded cerrix-compact">
                    <adv-eff-workflow-history [workflowHistory]="workflowHistory">
                    </adv-eff-workflow-history>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #generateSamplesDialog
    ><adv-eff-generate-samples-dialog
        *ngIf="generateSamplesModel"
        [effectivenessGuid]="this.details.guid"
        [model]="generateSamplesModel"
        [startDate]="this.details.startDate"
        [endDate]="this.details.endDate"
        [mainFormGroup]="generateSamplesFormGroup"
    ></adv-eff-generate-samples-dialog
></ng-template>
