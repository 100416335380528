<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export Events</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>

        <hr />

        <mat-checkbox color="primary" name="includeMois" [(ngModel)]="includeChecked"
            >Include closed MoIs from previous BIM periods</mat-checkbox
        >

        <hr />

        <button
            class="btn btn-default me-2 mb-2"
            (click)="downloadEvent(ExportType.Events)"
            *ngIf="permService.permissions.HasEventExportAccess"
        >
            Events
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="downloadEvent(ExportType.EventsAll)"
            *ngIf="permService.permissions.HasEventExportAccess"
        >
            Events, MoIs and Actions
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="downloadEvent(ExportType.DataBreachesAndMoi)"
            *ngIf="permService.permissions.HasEventExportAccess"
        >
            Data breach and MoIs
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="downloadEvent(ExportType.EventEmailNotifications)"
            *ngIf="permService.permissions.HasEventExportAccess"
        >
            Control design email notifications vs organizations
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            (click)="exportEventsRisksAndControls()"
            *ngIf="permService.permissions.HasEventExportAccess"
        >
            Events with Risks & Controls
        </button>
    </div>
</div>
