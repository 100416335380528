import { BaseFileModel, FileType } from "./BaseFileModel";

export class DocumentModel extends BaseFileModel {
    Description: string;

    DocumentType: string;
    DocumentTypeID: number;

    Source: number;
    SourceType: number;

    WopiViewable: boolean;
    WopiEditable: boolean;

    msAppIcon: string;
    msFileIcon: string;
    File: any;

    IsSelected?: boolean;

    FolderID: number;

    constructor() {
        super();
        this.FileType = FileType.Document;
    }
}
