import { SharedModule } from "@app/shared/shared.module";
import { ApiKeyService } from "./services/api-key.service";
import { ApiKeyDetailComponent } from "./components/api-key-detail/api-key-detail.component";
import { ApiKeyOverviewComponent } from "./components/api-key-overview/api-key-overview.component";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [SharedModule, CommonModule],
    declarations: [ApiKeyOverviewComponent, ApiKeyDetailComponent],
    exports: [ApiKeyOverviewComponent],
})
export class ApiKeyModule {
    static forRoot(): ModuleWithProviders<ApiKeyModule> {
        return {
            ngModule: ApiKeyModule,
            providers: [ApiKeyService],
        };
    }
}
