import { ElementRef } from "@angular/core";
import { FindNode } from "@methods/TreeMethods";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { GenericListField, GenericListFieldType } from "../models/GenericList/GenericListField";
import {
    GenericStandingDataConfig,
    StandingDataField,
    StandingDataOverviewType,
} from "../models/GenericList/GenericStandingDataConfig";

declare var $: any;

export class GenericManagerHelper {
    static validateGenericFields(
        editedRow: any,
        fields: GenericListField[],
        combineRequiredValidations: boolean = false
    ): string[] {
        return this.validateFields(null, null, editedRow, fields, null, combineRequiredValidations);
    }

    static validateFields(
        idProp: string,
        data: any[],
        editedRow: any,
        fields: GenericListField[],
        standingDataConfig: GenericStandingDataConfig = null,
        combineRequiredValidations: boolean = false
    ): string[] {
        const validations = [];

        let allRequiredConditionsMet = true;
        fields.forEach((field) => {
            let fieldValidations: string[] = [];
            if ((<StandingDataField>field).customFieldValidation) {
                fieldValidations = (<StandingDataField>field).customFieldValidation();
            } else {
                const validationResult = this.validateField(
                    idProp,
                    data,
                    editedRow,
                    field,
                    standingDataConfig
                );

                fieldValidations = validationResult.validations;
                if (!validationResult.requiredConditionMet) {
                    if (!combineRequiredValidations) {
                        fieldValidations.unshift(
                            field.prettyName ? field.prettyName : field.fieldName + " is required"
                        );
                    } else if (allRequiredConditionsMet) {
                        allRequiredConditionsMet = false;
                    }
                }
            }

            validations.addRange(fieldValidations);
        });

        if (combineRequiredValidations && !allRequiredConditionsMet) {
            validations.unshift("Please fill in all required fields");
        }

        const conditionallyRequiredFields = fields.filter((field) => field.conditionallyRequired);
        if (conditionallyRequiredFields.any()) {
            const atleastOneFilled = conditionallyRequiredFields.some((field) => {
                const rowValue = editedRow[field.fieldName];
                const value = rowValue && rowValue.toString().length > 0 ? rowValue : null;

                return field.conditionallyRequiredValue
                    ? value === field.conditionallyRequiredValue
                    : value != null;
            });

            if (!atleastOneFilled) {
                const fieldNames = conditionallyRequiredFields
                    .map((field) => (field.prettyName ? field.prettyName : field.fieldName))
                    .join(", ");
                validations.push(`At least one field (${fieldNames}) should be filled.`);
            }
        }

        return validations;
    }

    private static validateField(
        idProp: string,
        data: any[],
        editedRow: any,
        field: GenericListField,
        standingDataConfig: GenericStandingDataConfig = null
    ): { requiredConditionMet: boolean; validations: string[] } {
        const fieldValidations = [];
        let requiredConditionMet = true;

        editedRow[field.fieldName] = this.parseField(editedRow[field.fieldName], field);
        const fieldname = field.prettyName ? field.prettyName : field.fieldName;

        if (field.required) {
            const value = editedRow[field.fieldName];
            if (!value || ("" + value).trim() === "") {
                requiredConditionMet = false;
            }
        }

        if (field.unique) {
            let found = false;

            let dataToCheck = data;
            if (
                standingDataConfig &&
                standingDataConfig.overviewType === StandingDataOverviewType.Tree
            ) {
                // Root node
                if (
                    !editedRow[standingDataConfig.parentProp] ||
                    editedRow[standingDataConfig.parentProp] <= 0
                ) {
                    // Get all root nodes
                    dataToCheck = (dataToCheck as CerrixTreeItem[]).filter(
                        (treeItem) => !treeItem.ParentID || treeItem.ParentID <= 0
                    );
                } else {
                    // Fetch to parent node, to get all adjacent tree items
                    const parentNode = FindNode(
                        dataToCheck,
                        editedRow[standingDataConfig.parentProp]
                    );
                    if (parentNode) {
                        dataToCheck = parentNode.Children;
                    }
                }
            }

            if (dataToCheck != null) {
                dataToCheck.forEach((row) => {
                    if (
                        row[idProp] !== editedRow[idProp] &&
                        row[field.fieldName].toString().toLowerCase() ===
                            editedRow[field.fieldName].toString().toLowerCase()
                    ) {
                        found = true;
                    }
                });

                if (found) {
                    const singleIdFieldTypes = [
                        GenericListFieldType.SingleSelect,
                        GenericListFieldType.SingleTree,
                    ];
                    const displayName = singleIdFieldTypes.some(
                        (fieldType) => field.fieldType === fieldType
                    )
                        ? fieldname
                        : editedRow[field.fieldName];

                    fieldValidations.push(displayName + " already exists");
                }
            }
        }

        if (field.fieldType === GenericListFieldType.Number) {
            const value = editedRow[field.fieldName];

            if (isNaN(value)) {
                if (!value && field.required) {
                    fieldValidations.push(`${fieldname} can only be numbers`);
                }
            } else if (field.minimumValue && value < field.minimumValue) {
                fieldValidations.push(`${fieldname} should be at least ${field.minimumValue}`);
            } else if (field.maximumValue && value > field.maximumValue) {
                fieldValidations.push(
                    `${fieldname} should not be greater than ${field.maximumValue}`
                );
            }
        }

        if (
            (field.fieldType === GenericListFieldType.Date ||
                field.fieldType === GenericListFieldType.DateTime) &&
            (field.minimumValue || field.maximumValue)
        ) {
            const value = this.parseField(editedRow[field.fieldName], field);

            if (field.fieldType === GenericListFieldType.Date) {
                value.setHours(0, 0, 0, 0);
            }

            if (field.minimumValue) {
                const minValue = this.parseField(field.minimumValue, field);
                minValue.setHours(0, 0, 0, 0);

                if (value < minValue) {
                    fieldValidations.push(
                        `${fieldname} is not allowed to be less than the minimum value`
                    );
                }
            }
            if (field.maximumValue) {
                const maxValue = this.parseField(field.maximumValue, field);
                maxValue.setHours(0, 0, 0, 0);

                if (value > maxValue) {
                    fieldValidations.push(
                        `${fieldname} is not allowed to be greater than the maximum value`
                    );
                }
            }
        }

        if (field.fieldType === GenericListFieldType.Email) {
            const value = editedRow[field.fieldName];
            if (value && value.length > 0) {
                if (this.validateEmail(value) === false) {
                    fieldValidations.push(`The email in ${fieldname} is invalid`);
                }
            }
        }

        if (field.fieldType === GenericListFieldType.Phone) {
            const value = editedRow[field.fieldName];
            if (value && value.length > 0) {
                if (this.validatePhone(value) === false) {
                    fieldValidations.push(`The value in ${fieldname} is invalid`);
                }
            }
        }

        const multiSelectTypes = [GenericListFieldType.MultiSelect, GenericListFieldType.MultiTree];
        const lengthTyping =
            multiSelectTypes.indexOf(field.fieldType) >= 0 ? "item(s)" : "character(s)";

        if (field.minLength) {
            const value = editedRow[field.fieldName];
            if (value && value.length < field.minLength) {
                fieldValidations.push(
                    fieldname + ` cannot be less than ${field.minLength} ${lengthTyping}`
                );
            }
        }

        if (field.maxLength) {
            const value = editedRow[field.fieldName];
            if (value && value.length > field.maxLength) {
                fieldValidations.push(
                    fieldname + ` cannot be more than ${field.maxLength} ${lengthTyping}`
                );
            }
        }

        if (field.customValidation) {
            const customValidationResult = field.customValidation(editedRow);

            if (customValidationResult && customValidationResult.validation) {
                fieldValidations.push(customValidationResult.validation);

                if (requiredConditionMet && customValidationResult.makeRequired) {
                    requiredConditionMet = false;
                }
            }
        }

        return { requiredConditionMet, validations: fieldValidations };
    }

    static validateEmail(email: string) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static validatePhone(phone: string) {
        const regex = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
        return regex.test(phone);
    }

    static validateWebsite(website: string) {
        const regex =
            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gm;
        return regex.test(website);
    }

    static parseField(value: any, field: GenericListField) {
        if (
            field.fieldType === GenericListFieldType.Date ||
            field.fieldType === GenericListFieldType.DateTime
        ) {
            if (value) {
                const d = new Date(value);
                return d;
            }
        }

        return value;
    }

    static markAllFieldsAsTouched(elementRef: ElementRef) {
        const genericFields = Array.from(
            $(elementRef.nativeElement).find("generic-field-editor :input")
        );

        genericFields.forEach((element: any) => {
            element.focus();
            element.blur();
        });
    }
}
