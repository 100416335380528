import { Component, Input, OnInit } from "@angular/core";
import { AuditFieldworkStepDetailModel } from "@app/audit/shared/data-models/audit-fieldwork-step-detail-model";

@Component({
    selector: "audit-fieldwork-step-execution-working-method",
    templateUrl: "./audit-fieldwork-step-execution-working-method.component.html",
    styleUrls: ["./audit-fieldwork-step-execution-working-method.component.scss"],
})
export class AuditFieldworkStepExecutionWorkingMethodComponent implements OnInit {
    @Input() stepModel: AuditFieldworkStepDetailModel;
    selectedStepExecutionDetails?: any = null;

    constructor() {}

    ngOnInit() {
        if (this.stepModel && this.stepModel.executionDetails) {
            this.selectedStepExecutionDetails = JSON.parse(this.stepModel.executionDetails);
        } else {
            this.selectedStepExecutionDetails = null;
        }
    }
}
