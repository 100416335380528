import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isGuid } from "@methods/uniqueMethods";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable } from "rxjs";
import { ApiDataService } from "../../../services/http/ApiDataService";
import { Configuration } from "../../app.constants";
import { BaseTaskModel } from "../models/BaseTaskModel";
import { TaskStandingDataModel } from "../models/TaskStandingDataModel";
import { TaskPermissionModel } from "../models/TaskPermissionsModel";

@Injectable()
export class TaskDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "task");
    }

    public getPermissions(guid?: string): Observable<TaskPermissionModel> {
        const url = guid && guid.length > 0 ? `/${guid}/permissions` : "/null/permissions";
        return this.get<any>(url);
    }

    public getOverview(): Observable<any> {
        const url = "/overview";
        return this.get<any[]>(url);
    }

    public getTask(guid: string): Observable<any> {
        const url = this.actionUrl + `/${guid}`;
        return this.http.get<any>(url);
    }

    public saveTask(model: BaseTaskModel): Observable<any> {
        return this.postWithDocuments<any>(model, model.Documents);
    }

    public deleteTask(guid: string): Observable<string[]> {
        const url = `/${guid}`;
        return this.delete(url);
    }

    public getHistory(guid: string): Observable<HistoryModel[]> {
        const url = `/${guid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(guid: string, historyDate: string): Observable<HistoryChange[]> {
        const url = `/${guid}/history/${historyDate}`;

        return this.get<HistoryChange[]>(url);
    }

    public getDocuments(guid: string): Observable<DocumentModel[]> {
        const url = `/${guid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(guid: string): Observable<HyperlinkModel[]> {
        const url = `/${guid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public getLinkableControls(): Observable<ControlLinkModel[]> {
        const url = "/controls";

        return this.get<ControlLinkModel[]>(url);
    }

    public getStandingData(guid: string): Observable<TaskStandingDataModel> {
        const url = `/${guid}/standingdata`;

        return this.get<TaskStandingDataModel>(url);
    }
}
