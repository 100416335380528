<div class="detail-page-card">
    <div class="detail-page-card-title">
        <span>Execution working method</span>
    </div>
    <div class="detail-page-card-body padded">
        <ng-container *ngIf="selectedStepExecutionDetails">
            <div
                *ngFor="let item of selectedStepExecutionDetails | keyvalue"
                class="form-group col cerrix-textfield"
            >
                <label>{{ item.key }}</label>
                <textarea
                    autogrow
                    class="form-control form-control-sm"
                    readonly
                    [value]="item.value"
                >
                </textarea>
            </div>
        </ng-container>
        <ng-container *ngIf="!selectedStepExecutionDetails">
            <span>No execution working method defined for this step.</span>
        </ng-container>
    </div>
</div>
