import { CommonModule } from "@angular/common";
import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { CustomFieldModel } from "@app/incident/models/custom-field.model";
import { FieldType } from "@app/preset/models/field-type.enum";
import {
    BaseModel,
    CerrixButtonComponent,
    CerrixFieldWrapperComponent,
    CerrixInputComponent,
    CerrixSelectComponent,
    CerrixTitleComponent,
    CerrixTextareaComponent,
    CerrixCheckboxComponent,
    CerrixIconButtonComponent,
    CerrixColorPickerSimpleComponent,
    CerrixIconComponent,
    CerrixDropMenuComponent,
} from "@cerrix/components";
import { MatListModule } from "@angular/material/list";
import { CustomFieldOptionModel } from "@app/incident/models/custom-field-option.model";
import { IncidentCustomFieldService } from "@app/incident/services/incident-custom-field.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ToastrService } from "ngx-toastr";
import { CdkDragDrop, DragDropModule, moveItemInArray } from "@angular/cdk/drag-drop";
import { fromEvent, Subscription } from "rxjs";

@Component({
    selector: "app-custom-field-config",
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        CerrixTitleComponent,
        CerrixInputComponent,
        CerrixFieldWrapperComponent,
        CerrixSelectComponent,
        CerrixTextareaComponent,
        CerrixButtonComponent,
        CerrixCheckboxComponent,
        CerrixIconButtonComponent,
        CerrixIconComponent,
        CerrixDropMenuComponent,
        CerrixColorPickerSimpleComponent,
        MatCheckbox,
        ReactiveFormsModule,
        MatListModule,
        FormsModule,
        DragDropModule,
    ],
    templateUrl: "./custom-field-config.component.html",
    styleUrl: "./custom-field-config.component.scss",
})
export class CustomFieldConfigComponent implements OnInit {
    @Input() public customField?: CustomFieldModel = inject(MAT_DIALOG_DATA).customField;
    @Input() public isIncidentTypePredefined: boolean =
        inject(MAT_DIALOG_DATA).isIncidentTypePredefined;

    protected customFieldTypes: BaseModel[] = [
        {
            id: FieldType.TextField.valueOf().toString(),
            name: "Text field",
        },
        {
            id: FieldType.TextBox.valueOf().toString(),
            name: "Text area",
        },
        {
            id: FieldType.Date.valueOf().toString(),
            name: "Date",
        },
        {
            id: FieldType.DateTime.valueOf().toString(),
            name: "Date-time",
        },
        {
            id: FieldType.Numeric.valueOf().toString(),
            name: "Number",
        },
        {
            id: FieldType.Checkbox.valueOf().toString(),
            name: "Checkbox",
        },
        {
            id: FieldType.SingleSelect.valueOf().toString(),
            name: "Dropdown single select",
        },
        {
            id: FieldType.MultiSelect.valueOf().toString(),
            name: "Dropdown multi select",
        },
        {
            id: FieldType.FinancialImpact.valueOf().toString(),
            name: "Financial impact",
        },
    ];

    private readonly dialogRef: MatDialogRef<CustomFieldModel, CustomFieldModel> =
        inject(MatDialogRef);

    private readonly destroyRef = inject(DestroyRef);
    private readonly customFieldService = inject(IncidentCustomFieldService);
    private readonly toastrService = inject(ToastrService);

    private readonly formBuilder: FormBuilder = inject(FormBuilder);

    protected form: FormGroup;
    protected dialogTitle: string;
    protected saveText: string;
    protected step: 1 | 2 = 1;

    protected nextEnabled: boolean;

    protected customFieldSelectOptions: CustomFieldOptionModel[] = [];
    protected customFieldSelectOptionsVisibleIds: number[] = [];
    protected customFieldSelectOptionsHiddenIds: number[] = [];

    @ViewChild("editingOptionItem") editingOptionItem?: ElementRef<HTMLElement>;
    protected editingOption: CustomFieldOptionModel;
    protected editingOptionIndex: number = -1;
    private editingClickOutsideEvent: Subscription = new Subscription();

    protected readonly FieldType: typeof FieldType = FieldType;

    ngOnInit(): void {
        if (this.customField?.id) {
            this.dialogTitle = "Edit field";
            this.saveText = "Save changes";
            this.nextEnabled = true;
        } else {
            this.dialogTitle = "Add field";
            this.saveText = "Add field";
        }

        if (this.customField?.customFieldOptions) {
            this.customFieldSelectOptions = [...this.customField.customFieldOptions];
            this.customFieldSelectOptions.forEach((o, index) => {
                if (o.isVisible) {
                    this.customFieldSelectOptionsVisibleIds.push(index);
                } else {
                    this.customFieldSelectOptionsHiddenIds.push(index);
                }
            });
        }

        this.resetForm();
    }

    private resetForm(): void {
        this.form = this.formBuilder.group({
            id: [this.customField?.id],
            name: [
                { value: this.customField?.name ?? "", disabled: this.isIncidentTypePredefined },
                Validators.required,
            ],
            customFieldType: [
                {
                    value: this.customField?.customFieldType.toString() ?? null,
                    disabled: !!this.customField?.id || this.isIncidentTypePredefined,
                },
                Validators.required,
            ],
            placeholder: [
                {
                    value: this.customField?.placeholder ?? null,
                    disabled: this.isIncidentTypePredefined,
                },
            ],
            description: [
                {
                    value: this.customField?.description ?? null,
                    disabled: this.isIncidentTypePredefined,
                },
            ],
            isVisible: [
                {
                    value: this.customField?.isVisible ?? true,
                    disabled: this.isIncidentTypePredefined,
                },
                Validators.required,
            ],
            isRequired: [
                {
                    value: this.customField?.isRequired ?? false,
                    disabled: this.isIncidentTypePredefined,
                },
                Validators.required,
            ],
        });
    }

    protected validateName() {
        this.nextEnabled = this.form.get("name").value.trim().length > 0;
    }

    protected onNext(): void {
        this.step++;

        if (this.isDropdownCustomField()) {
            this.dialogTitle = "Specify options";
        }
    }

    protected onPrevious(): void {
        this.step--;

        this.dialogTitle = this.customField?.id ? "Edit field" : "Add field";
    }

    protected dropListItem(event: CdkDragDrop<CustomFieldOptionModel[]>) {
        moveItemInArray(this.customFieldSelectOptions, event.previousIndex, event.currentIndex);
        this.updateOptionsList(Math.min(event.previousIndex, event.currentIndex));
        console.log(this.customFieldSelectOptions);
    }

    protected saveChanges(): void {
        if (this.form.valid) {
            this.dialogRef.close({
                id: this.customField?.id,
                name: this.form.getRawValue().name.trim(),
                customFieldType: this.customField?.id
                    ? this.customField.customFieldType
                    : parseInt(this.form.getRawValue().customFieldType),
                placeholder: this.form.getRawValue().placeholder,
                description: this.form.getRawValue().description,
                isVisible: this.form.getRawValue().isVisible,
                isRequired: this.form.getRawValue().isRequired,
                order: null,
                customFieldOptions: this.customFieldSelectOptions,
            });
        }
    }

    private isDropdownCustomField(): boolean {
        return (
            this.form.get("customFieldType").value === FieldType.SingleSelect.toString() ||
            this.form.get("customFieldType").value === FieldType.MultiSelect.toString()
        );
    }

    protected newOptionColor: string = "";
    protected newOptionName: string;
    protected checkingOptionUsage: boolean = false;

    protected onAddOption(): void {
        if (this.newOptionName) {
            let indexToPlace = this.customFieldSelectOptions.findIndex((o) => !o.isVisible);
            if (indexToPlace === -1) {
                indexToPlace = this.customFieldSelectOptions.length;
            }
            this.customFieldSelectOptions.splice(indexToPlace, 0, {
                id: null,
                name: this.newOptionName,
                order: this.cerrixOrderCorrection(indexToPlace + 1),
                color: this.newOptionColor ?? "",
                isVisible: true,
                translation: "",
            });
            this.updateOptionsList(this.cerrixOrderCorrection(indexToPlace + 1));

            this.newOptionColor = "";
            this.newOptionName = undefined;
        }
    }

    protected onEditOption(index: number): void {
        this.editingOptionIndex = index;
        this.editingOption = {
            ...this.customField.customFieldOptions[index],
        };
        this.editingClickOutsideEvent = fromEvent(document, "mousedown").subscribe((e) => {
            if (!this.editingOptionItem.nativeElement.contains(e.target as HTMLElement)) {
                this.onCancelEditOption();
            }
        });
    }

    protected onSaveOption(option: CustomFieldOptionModel): void {
        option.name = this.editingOption.name;
        option.color = this.editingOption.color;
        this.onCancelEditOption();
    }

    protected onCancelEditOption(): void {
        this.editingOption = null;
        this.editingOptionIndex = -1;
        this.editingClickOutsideEvent.unsubscribe();
    }

    protected toggleOption(option: CustomFieldOptionModel, index: number): void {
        option.isVisible = !option.isVisible;

        if (index > -1) {
            const currentIndex = option.isVisible
                ? this.customFieldSelectOptionsHiddenIds[index]
                : this.customFieldSelectOptionsVisibleIds[index];
            const indexToPlace = option.isVisible
                ? this.customFieldSelectOptionsHiddenIds.first()
                : this.customFieldSelectOptions.length - 1;
            moveItemInArray(this.customFieldSelectOptions, currentIndex, indexToPlace);
            this.updateOptionsList(Math.min(index, indexToPlace));
        }
    }

    protected removeOption(index: number, option: CustomFieldOptionModel): void {
        if (option.id) {
            this.checkingOptionUsage = true;

            this.customFieldService
                .isCustomFieldOptionUsed(this.customField.id, option.id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (isUsed) => {
                        this.checkingOptionUsage = false;

                        if (isUsed) {
                            this.toastrService.error("This option is still in use");
                        } else {
                            this.removeOptionFromList(index);
                        }
                    },
                    error: () => {
                        this.checkingOptionUsage = false;
                    },
                });
        } else {
            this.removeOptionFromList(index);
        }
    }

    private removeOptionFromList(index: number) {
        this.customFieldSelectOptions.splice(index, 1);
        this.updateOptionsList(index);
    }

    private updateOptionsList(startIndex: number = 0): void {
        for (let i = startIndex; i < this.customFieldSelectOptions.length; i++) {
            this.customFieldSelectOptions[i].order = this.cerrixOrderCorrection(i + 1);
        }
        this.updateOptionsVisibility();
    }

    private updateOptionsVisibility(): void {
        this.customFieldSelectOptionsVisibleIds = [];
        this.customFieldSelectOptionsHiddenIds = [];
        this.customFieldSelectOptions.forEach((o, index) => {
            if (o.isVisible) {
                this.customFieldSelectOptionsVisibleIds.push(index);
            } else {
                this.customFieldSelectOptionsHiddenIds.push(index);
            }
        });
    }

    private cerrixOrderCorrection(index: number): number {
        return index * 10;
    }
}
