<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    [module]="'user'"
    [moduleType]="moduleType"
    [appendToModuleType]="'usermaintenance'"
    [idProp]="idProperty"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [buttonConfig]="buttonConfig"
    [contextMenuItems]="contextMenuItems"
    [DateProperties]="DateProperties"
    (onDblClick)="openDetail($event)"
    (onAddClick)="add()"
>
</cerrix-workspace>
