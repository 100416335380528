import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ThirdPartyDataService } from "../services/ThirdPartyDataService";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { FilterType } from "@enums/FilterType";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { ModuleType } from "@enums/ModuleType";
import { PermissionsDataService } from "@services/http/permissions/PermissionsDataService";
import { FormatType } from "@enums/FormatType";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { CustomFieldHelper } from "@app/shared/helpers/customField-helper";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "thirdparty-overview",
    templateUrl: "./thirdparty-overview.component.html",
    styleUrls: ["./thirdparty-overview.component.scss"],
})
export class ThirdPartyOverviewComponent implements OnInit {
    moduleType = ModuleType.TP;
    tabID: string;
    tab: TabModel;
    data: Promise<any[]>;
    headerLookup: any = {};

    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations Responsible",
            column: "OrganizationResponsibleID",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business dimensions",
            column: "BusinessDimensionIds",
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework dimensions",
            column: "FrameworkDimensionIds",
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Review score",
            actionColumn: "Latest_Score_Color",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "Criticality",
            actionColumn: "CriticalityColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "Next review date",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "Date reviewed",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "Start date servicing",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "End date servicing",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
    ];

    numberProps: string[] = [];
    dateProps: string[] = [
        "Next review date",
        "Date reviewed",
        "Start date servicing",
        "End date servicing",
    ];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private tabService: TabService,
        private thirdpartyDataService: ThirdPartyDataService,
        private _permissions: PermissionsService,
        private pages: Pages,
        private standingDataService: StandingdataDataService
    ) {}

    ngOnInit() {
        this.loadPermissions();
        this.data = this.loadData();
    }

    loadPermissions() {
        this.permissions = this._permissions.permissions.Workspace.thirdParty;
    }

    public openThirdParty(thirdparty: any) {
        this.tab.showLoader = true;
        this.tabService.generateTab(
            this.pages.ThirdpartyDetail,
            thirdparty.Guid,
            `(TP) ${thirdparty.Identifier} - ${thirdparty.Name}`,
            null
        );
        this.tab.showLoader = false;
    }

    public addThirdParty() {
        this.tabService.generateTab(this.pages.ThirdpartyDetail, null, "Add new Third Party");
    }

    private async loadData() {
        const data$ = this.thirdpartyDataService.getThirdParties().toPromise();
        const data = await data$;
        await this.expandStandingDataCustomFields(data);
        return this.mapHeaders(data);
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    private async expandStandingDataCustomFields(data: Object[]) {
        const expandableFields = (
            await this.standingDataService
                .getAllByType(StandingDataType.ThirdPartyCustomFields)
                .toPromise()
        ).filter((x) => x.visible);

        CustomFieldHelper.expandCustomFields(
            data,
            "customFields",
            expandableFields,
            this.headerLookup,
            this.rendererConfig
        );
    }
}
