import { IHasDocuments } from "@methods/DocumentMethods";
import { ContactModel } from "./ContactModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { ThirdPartyReviewModel } from "./ThirdpartyReviewModel";
import { ThirdPartyReviewScoreModel } from "./ThirdpartyReviewScoreModel";
import { EntityRoles } from "@app/datamanagement/enums/EntityRoles";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ThirdPartyContractModel } from "./ThirdPartyContractModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { CustomFieldDetailModel } from "@models/customfields/CustomFieldDetailModel";

export class ThirdPartyModel implements IHasDocuments {
    ID: number;
    Identifier: string;
    BaseId: number;
    Guid: string;

    Name: string;
    Address: string;
    Owner: string;
    OrganizationResponsibleId: number;
    ThirdpartyResponsible: string;
    Description: string;
    EUMember: boolean;
    CocNumber: number;
    TaxNumber: number;
    Country: string;
    City: string;
    BeneficialOwner: string;
    OrganizationBeneficialOwner: number;
    AuthorizedSignatory: string;
    StatusOfInvolvementID: number;
    InitialReviewDate: Date;
    ReviewFrequency: number;
    Phone: string;
    Email: string;
    NextReviewDate: Date;
    ReviewerNames: string;
    Website: string;
    ContractValue: string;
    CurrencyCode: string;
    Comment: String;
    DataController: boolean = false;
    DataProcessor: boolean = false;
    DataRecipient: boolean = false;
    ThirdParty: boolean = false;
    LeiCode: string;
    DUNS_Number: number;
    EntityRoles: EntityRoles;
    DateModified: Date;

    StartDateServicing: Date;
    EndDateServicing: Date;
    Criticality: number;
    Relationship: number;

    Score: ThirdPartyReviewScoreModel;
    Contacts: ContactModel[];
    Reviews: ThirdPartyReviewModel[] = [];
    Documents: DocumentModel[] = [];
    Hyperlinks: HyperlinkModel[];

    Reviewers: number[] = [];
    Responsibles: number[] = [];
    LinkedRisks: number[] = [];
    LinkedControls: number[] = [];
    LinkedPp: number[] = [];
    UserResponsibles: number[] = [];
    BusinessDimensions: number[] = [];
    FrameworkDimensions: number[] = [];
    Organizations: number[] = [];
    LinkedServiceIds: number[] = [];
    Contracts: ThirdPartyContractModel[] = [];
    Systems: IdNameCombination[];
    CustomFields: CustomFieldDetailModel[] = [];

    LinkedForms: number[] = [];
}
