import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { nameof } from "@methods/CommonMethods";
import { DocumentFolderModel } from "@models/documents/DocumentFolderModel";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-cerrix-document-manager-folder-details",
    templateUrl: "./document-manager-folder-details.component.html",
    styleUrls: ["./document-manager-folder-details.component.scss"],
})
export class DocumentManagerFolderDetailsComponent extends BaseModal implements OnInit {
    Folder: DocumentFolderModel;
    ExistingFolders: DocumentFolderModel[];
    folderForm: FormGroup;
    Username: string;

    selectedTab: string = "details";

    popupAction: string = "";

    constructor(_bsModalRef: BsModalRef, private _toastr: ToastrService) {
        super(_bsModalRef);
    }

    async ngOnInit(): Promise<void> {
        this.folderForm = new FormGroup({
            Name: new FormControl(this.Folder.Name),
            FileIcon: new FormControl(this.Folder.FileIcon),
        });

        this.popupAction =
            this.Folder.Name && this.Folder.Name.length > 0 ? "Edit folder" : "Add folder";
    }

    isValid(): boolean {
        // Get the trimmed folder name
        const nameProp = nameof<DocumentFolderModel>("Name");
        const folderName = this.folderForm.controls[nameProp].value?.trim() || "";

        // Check if the folder name is empty
        if (folderName.length === 0) {
            this._toastr.warning("Name is required.");
            return false;
        }

        // Check if a folder with the same parent ID and name (case-insensitive) already exists
        const exists = this.ExistingFolders.some(
            (folder) =>
                ((folder.ParentID == null && this.Folder.ParentID == null) || // Root folder
                    folder.ParentID === this.Folder.ParentID) && // Sub folder
                folder.ID != this.Folder.ID && // Not the same folder
                folder.Name.trim().toLowerCase() === folderName.toLowerCase() // Name matches
        );

        if (exists) {
            this._toastr.warning("A folder with the same name already exists.");
            return false;
        }

        return true;
    }

    applyChange() {
        if (!this.isValid()) {
            return;
        }

        const nameProp = nameof<DocumentFolderModel>("Name");
        const folderName = this.folderForm.controls[nameProp].value;

        const iconProp = nameof<DocumentFolderModel>("FileIcon");
        const folderIcon = this.folderForm.controls[iconProp].value;

        this.Folder = this.setFolderDetails(this.Folder, folderName, folderIcon);
        if (this.Folder.ID > 0) {
            // Editing existing
            this.Folder.Changed = !this.Folder.IsNew;
        } else {
            this.Folder.IsNew = true;
        }

        this.folderForm.reset();

        super.onConfirm(this.Folder);
    }

    setFolderDetails(
        folder: DocumentFolderModel,
        folderName?: string,
        folderIcon?: string
    ): DocumentFolderModel {
        folder.Name = folderName;
        folder.FileIcon = folderIcon;

        return folder;
    }

    closeClick() {
        super.onCancel();
    }

    toggle(tab: string) {
        this.selectedTab = tab;
    }
}
