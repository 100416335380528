<div
    menuSystem
    [cerrixTab]="cerrixTab"
    class="details"
    (change)="handleMenuItemClick($event)"
    data-uiid="audit-detail-page"
    *ngIf="!cerrixTab.showLoader"
>
    <div class="detail-page-action-bar">
        <button
            class="btn btn-cerrix"
            (click)="save()"
            data-uiid="save-audit"
            *ngIf="permissions.canEdit"
        >
            <i class="fas fa-save"></i>
            <span data-uiid="save" class="d-none d-lg-inline">Save audit</span>
        </button>

        <span class="date-edited" *ngIf="auditModel.id > 0">
            Last save: {{ dateModifiedPromise | async | dateTimeFormat }}
        </span>

        <div class="workflow-area" *ngIf="workflowButtonConfig">
            <span class="workflow-description">
                Workflow Status |
                <b data-uiid="audit-workflow-status">{{ workflowButtonConfig.currentStatus }}</b>
            </span>
            <span>
                <button
                    data-uiid="audit-negative-workflow-button"
                    class="btn"
                    [class.btn-dark]="workflowButtonConfig.negativeButtonDarkColor"
                    [class.btn-danger]="!workflowButtonConfig.negativeButtonDarkColor"
                    (click)="onWorkflowButtonClick(false)"
                    *ngIf="workflowButtonConfig.showNegativeButton"
                >
                    {{ workflowButtonConfig.negativeButtonText }}
                </button>
                <button
                    data-uiid="audit-positive-workflow-button"
                    class="btn btn-success"
                    (click)="onWorkflowButtonClick(true)"
                    *ngIf="workflowButtonConfig.showPositiveButton"
                >
                    {{ workflowButtonConfig.positiveButtonText }}
                </button>
            </span>
        </div>
    </div>

    <div class="screens" name="Audit" menuicon="fal fa-clipboard-list-check" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <audit-detail-main
                [auditModel]="auditModel"
                [standingData]="standingData"
                [formGroup]="formGroup"
                (dateChanged)="dateChangeHandler($event)"
            ></audit-detail-main>
        </div>
        <div
            name="Requests"
            [attr.menuItemId]="documentRequestsTabId"
            menuicon="fas fa-user-clock"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-auditee-requests
                #requests
                [formGroup]="formGroup"
                [permissions]="permissions"
                [auditModel]="auditModel"
                [cerrixTab]="cerrixTab"
            ></audit-detail-auditee-requests>
        </div>
        <div name="Context" [attr.menuItemId]="contextTabId" menuicon="fas fa-globe" menusystemitem>
            <audit-detail-context
                #context
                [formGroup]="formGroup"
                [permissions]="permissions"
                [auditModel]="auditModel"
            ></audit-detail-context>
        </div>
        <div
            name="Risk Assessment"
            [attr.menuItemId]="riskAssessmentTabId"
            menuItemId="audit-risk-assessment"
            menuicon="fad fa-th"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-risk-assessment
                [permissions]="permissions"
                [auditModel]="auditModel"
                #riskAssessment
            ></audit-detail-risk-assessment>
        </div>
        <div
            name="Fieldwork Plan"
            menuItemId="audit-fieldwork-plan"
            menuicon="fad fa-route"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-fieldwork-plan
                [auditGuid]="auditModel.guid"
                [permissions]="permissions"
                [steps]="auditModel.fieldworkSteps"
            ></audit-detail-fieldwork-plan>
        </div>
        <div
            name="Fieldwork Execution"
            [attr.menuItemId]="fieldWorkExecutionTabId"
            menuicon="fas fa-play"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-fieldwork-execution
                [permissions]="permissions"
                [auditGuid]="auditModel.guid"
                #fieldworkExecutionComp
            ></audit-detail-fieldwork-execution>
        </div>
        <div
            name="Findings"
            [attr.menuItemId]="findingsTabId"
            menuicon="far fa-radar"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-finding-linking
                [auditGuid]="auditModel.guid"
                #findings
            ></audit-detail-finding-linking>
        </div>
        <div
            name="Findings Report"
            [attr.menuItemId]="findingReportTabId"
            menuicon="fad fa-clipboard-list"
            menusystemitem
            *ngIf="auditModel.id > 0"
        >
            <audit-detail-finding-report-summary [auditModel]="auditModel" #findingReport>
            </audit-detail-finding-report-summary>
        </div>
        <div
            class="h-auto"
            name="Links"
            menuicon="fas fa-link"
            menusystemsubmenu
            *ngIf="
                permissions.canViewLinkedThirdParty ||
                permissions.canViewLinkedForms ||
                permissions.canViewDocuments
            "
        >
            <div
                name="Third Party"
                [attr.menuItemId]="thirdpartyTabId"
                menuicon="fas fa-handshake"
                menusystemitem
                *ngIf="permissions.canViewLinkedThirdParty"
            >
                <audit-thirdparty
                    *ngIf="auditModel"
                    [model]="auditModel"
                    [disabled]="!permissions.canLinkThirdParty"
                    #auditThirdParty
                >
                </audit-thirdparty>
            </div>
            <div
                [attr.menuItemId]="menuItemForms"
                name="Forms"
                menuicon="fad fa-file-alt"
                menusystemitem
                *ngIf="permissions.canViewLinkedForms"
            >
                <audit-linked-forms
                    #formsComponent
                    [model]="auditModel"
                    [disabled]="!permissions.canLinkForms"
                ></audit-linked-forms>
            </div>
            <div
                name="Documents"
                menuItemId="audit-documents"
                menuicon="far fa-file"
                menusystemitem
                *ngIf="permissions.canViewDocuments"
            >
                <document-manager-folder
                    [cerrixTab]="cerrixTab"
                    [(Documents)]="auditModel.Documents"
                    [(Folders)]="auditModel.Folders"
                    [ParentGuid]="auditModel.guid"
                    [permissions]="permissions.documentPermissions"
                    [DocumentTypeTargets]="targetModule"
                    [ExtraFieldColumnConfig]="extraDocumentColumns"
                    [hideGenerateButton]="!enableDocumentComposing"
                ></document-manager-folder>
            </div>
        </div>

        <div
            class="h-auto"
            name="History"
            menuicon="fas fa-history"
            menusystemsubmenu
            *ngIf="auditModel.id > 0"
        >
            <div
                name="Workflow History"
                menuicon="fas fa-history"
                [attr.menuItemId]="workflowHistoryTabId"
                menusystemitem
            >
                <audit-detail-workflow-history #auditWorkflowHistory [auditModel]="auditModel">
                </audit-detail-workflow-history>
            </div>
            <div
                name="Audit History"
                menusystemitem
                [attr.menuItemId]="auditHistoryTabId"
                menuicon="fas fa-history"
                *ngIf="auditModel.guid"
            >
                <app-history-overview
                    [historyDataService]="auditDataService"
                    [Id]="auditModel.guid"
                    #historyOverviewComponent
                >
                </app-history-overview>
            </div>
        </div>
        <div name="Options" menuicon="fas fa-check-double" menusystemitem *ngIf="auditModel.id > 0">
            <detail-options
                moduleName="audit"
                [objectName]="auditModel.auditName"
                [allowDelete]="true"
                [buttonConfig]="buttonConfig"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
