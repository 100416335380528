import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormField } from "@app/forms/models/detail/FormField";

@Component({
    selector: "form-entry-number",
    templateUrl: "./form-entry-number.component.html",
    styleUrls: ["../../form-entry-field.component.scss"],
})
export class FormEntryNumberComponent {
    @Input() field: FormField;
    @Input() readonly: boolean;
    @Input() answer: number;
    @Output() answerChange = new EventEmitter<number>();

    fieldChange() {
        this.answerChange.emit(this.answer);
    }
}
