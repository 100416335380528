import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { PopupService } from "@app/shared/services/popup/popup.service";
import { wopiFileValidationModel } from "@app/wopi/wopidebug/models/wopiFileValidationModel";
import { WopiDebugDataService } from "@app/wopi/wopidebug/services/wopi-debug-data.service";
import { isNullOrEmpty, toPromise } from "@methods/CommonMethods";
import { DocumentMethods } from "@methods/DocumentMethods";
import { guid, isGuid } from "@methods/uniqueMethods";
import { DocumentDetailsParameters } from "@models/documents/DocumentDetailsParameters";
import { DocumentModel } from "@models/documents/documentmodel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { PermissionsDataService } from "@services/http/permissions/PermissionsDataService";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

declare var $: any;

@Component({
    selector: "document-manager-editor",
    templateUrl: "./document-manager-details.component.html",
    styleUrls: ["./document-manager-details.component.scss"],
})
export class DocumentManagerDetailsComponent extends BaseModal implements OnInit {
    Document: DocumentModel;
    parameters: DocumentDetailsParameters;
    AllowedFileExtensions: string;
    MaxUploadSizeInMb: number;

    ShowType = true;
    ShowDescription = true;
    SingleFileUpload = false;
    Username: string;

    AllowAdd = true;
    AllowEdit = true;
    disabled = false;

    documentTypes: IdNameCombination[];
    showPdfButton: boolean;
    fileInputText = "";
    fileInputCount = 0;

    documentType: number;
    description: string;
    documentForm: FormGroup;

    showValidationBtn: boolean;

    validationModel: wopiFileValidationModel;
    validationExtensions: string[] = ["docx", "xlsx", "pptx"];

    selectedTab: string = "details";

    permissions: ApplicationPermissionsModel;

    @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

    @ViewChild("documentDetail") docDetails: ElementRef;
    @ViewChild("history") history: ElementRef;

    constructor(
        private _permissionsDS: PermissionsDataService,
        private _toastr: ToastrService,
        private _popupService: PopupService,
        private _wopiDebugService: WopiDebugDataService,
        _bsModalRef: BsModalRef
    ) {
        super(_bsModalRef);
    }

    async ngOnInit(): Promise<void> {
        this.Document = this.parameters.document;
        this.documentTypes = this.parameters.documentTypes;
        this.AllowedFileExtensions = this.parameters.allowedFileExtensions;
        this.MaxUploadSizeInMb = this.parameters.maxUploadSizeInMb;
        this.Username = this.parameters.userName;
        this.ShowType = this.parameters.showType;
        this.ShowDescription = this.parameters.showDescription;
        this.SingleFileUpload = this.parameters.singleFileUpload;

        this.AllowAdd = this.parameters.permissions.canAddDocument;
        this.AllowEdit = this.Document.CanEdit;
        this.disabled = this.parameters.disabled;

        this.documentForm = new FormGroup({
            file: new FormControl({ value: this.Document.Name, disabled: true }),
            DocumentType: new FormControl(this.Document.DocumentTypeID),
            Description: new FormControl(this.Document.Description),
        });

        if (this.disabled || (this.Document.ID && !this.Document.CanEdit)) {
            this.documentForm.disable();
        }

        this.permissions = await toPromise(this._permissionsDS.getPermissions());

        this.showValidationBtn =
            this.Document.ID &&
            this.Document.ID > 0 &&
            this.AllowEdit &&
            this.permissions &&
            this.permissions.IsDeveloperAdmin &&
            this.validationExtensions.includes(this.Document.Name.split(".").pop());

        this.initDocument(this.Document);
    }

    initDocument(doc: DocumentModel) {
        this.fileInput.nativeElement.type = "";
        this.fileInput.nativeElement.type = "file";

        if (doc && (doc.ID > 0 || doc.IsNew)) {
            this.fileInputText = doc.Name;
            this.documentType = doc.DocumentTypeID;
            this.description = doc.Description;

            if (doc.ID > 0) {
                this.showPdfButton = doc.Name.endsWith(".pdf");
            }
        } else {
            this.fileInputText = "";
            this.documentType = 0;
            this.description = "";
            this.showPdfButton = false;
        }
    }

    applyChange() {
        FormValidationHelper.mapToModel(this.documentForm, this.Document);

        const applyDocuments: DocumentModel[] = [];
        const allowedFiles = DocumentMethods.filterAllowedFiles(
            this._toastr,
            this.fileInput.nativeElement.files,
            this.AllowedFileExtensions
        );

        // Check if all staged and new documents exceed the upload limit
        if (
            !DocumentMethods.validateUploadLimit(this.MaxUploadSizeInMb, this._toastr, [
                ...allowedFiles,
                ...this.parameters.filesToUpload,
            ])
        ) {
            return;
        }

        if (this.Document.ID) {
            // Editing existing
            const file = allowedFiles[0];
            this.Document = this.setDocumentDetails(this.Document, file);
            this.Document.Changed = !this.Document.IsNew;

            applyDocuments.push(this.Document);
        } else if (allowedFiles.length > 0) {
            // Adding new
            for (let fileIndex = 0; fileIndex < allowedFiles.length; fileIndex++) {
                const file = allowedFiles[fileIndex];

                // Pass copy of document
                const clonedDoc = JSON.parse(JSON.stringify(this.Document));
                const doc = this.setDocumentDetails(clonedDoc, file);
                applyDocuments.push(doc);
            }
        } else if (this.Document.IsNew && this.Document.File) {
            // Editing new
            const doc = this.setDocumentDetails(this.Document);
            applyDocuments.push(doc);
        }

        this.initDocument(null);

        super.onConfirm(applyDocuments);
    }

    closeClick() {
        super.onCancel();
    }

    async validateClick(document: DocumentModel) {
        this.validationModel = await this._wopiDebugService.validateDocument(document.Guid);
        if (!isNullOrEmpty(this.validationModel.message)) {
            console.log(this.validationModel.message);
        }
    }

    setDocumentDetails(doc: DocumentModel, file?: File): DocumentModel {
        doc = doc ? doc : new DocumentModel();

        const description = doc.Description;
        const documentType = +doc.DocumentType;

        if (!isGuid(doc.Guid)) {
            doc.Guid = guid();
        }

        if (!doc.ID || doc.ID <= 0) {
            doc.Date = new Date();
            doc.IsNew = true;
            doc.CanEdit = true;
            doc.CanDelete = true;
            doc.Version = 0;
            doc.Username = this.Username;
        }

        doc.Description = description;

        if (file) {
            doc.Name = file.name;
            doc.File = file;
        }

        if (this.ShowType && !isNaN(documentType) && documentType > 0) {
            doc.DocumentTypeID = documentType;
            doc.DocumentType = this.documentTypes.find((x) => x.ID === documentType).Name;
        }
        return doc;
    }

    updateFiles() {
        const allowedFiles = DocumentMethods.filterAllowedFiles(
            this._toastr,
            this.fileInput.nativeElement.files,
            this.AllowedFileExtensions
        );

        this.fileInputCount = allowedFiles.length;
        const fileNames: string[] = [];
        for (let fileIndex = 0; fileIndex < allowedFiles.length; fileIndex++) {
            const file = allowedFiles[fileIndex];
            fileNames.push(file.name);
        }

        this.fileInputText = fileNames.join(" ; ");
    }

    openFileSelect() {
        $(this.fileInput.nativeElement).trigger("click");
    }

    openDocumentViewer() {
        const url = "/api/documents/" + this.Document.Guid + "/pdf";
        this._popupService.forceOpenInNewWindow(url);
    }

    toggle(tab: string) {
        if (tab == "details") {
            this.selectedTab = "details";
        } else if (tab == "history") {
            this.selectedTab = "history";
        }
    }
}
