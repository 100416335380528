import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptComponent } from "../cerrix-prompt/cerrix-prompt.component";
import { ICerrixPrompt } from "../cerrix-prompt/models/ICerrixPrompt";
import { CerrixButtonConfig } from "../models/CerrixButtonConfig";

@Injectable()
export class CerrixPromptService {
    public static ngSelectHolderCls = "modal-ngselect-holder";
    public static ngSelectHolderSelector =
        "cerrix-prompt ." + CerrixPromptService.ngSelectHolderCls;

    constructor(public toastr: ToastrService, private _dialog: MatDialog) {}

    confirm(title: string, message: string) {
        return this.confirmCustom({
            data: {
                title: title,
                message: message,
            },
        });
    }

    confirmCustom(partialConfig?: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const dialogConfig = this.getConfirmConfig(partialConfig);
        return this.openPrompt(dialogConfig);
    }

    alert(partialConfig?: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const dialogConfig = this.getAlertConfig(partialConfig);
        return this.openPrompt(dialogConfig);
    }

    loader(title: string, message?: string) {
        return this.loaderCustom({
            data: {
                title: title,
                message: message,
            },
        });
    }

    loaderCustom(partialConfig?: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const dialogConfig = this.getLoaderConfig(partialConfig);
        return this.openPrompt(dialogConfig);
    }

    prompt(partialConfig?: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const dialogConfig = this.getPromptConfig(partialConfig);
        return this.openPrompt(dialogConfig);
    }

    private openPrompt(config: MatDialogConfig<ICerrixPrompt>) {
        return this._dialog.open(CerrixPromptComponent, config).componentInstance;
    }

    private getConfirmConfig(partialConfig: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const config = this.buildDialogConfig(
            {
                maxHeight: "250px",
                maxWidth: "350px",
                data: {
                    title: "Confirm",
                    message: "Are you sure you want to continue?",
                    confirmButton: this.getDefaultConfirmButton(),
                    cancelButton: this.getDefaultCancelButton(),
                    showButtonBar: true,
                    closeOnEsc: true,
                    confirmOnEnter: false,
                },
            },
            partialConfig
        );

        return config;
    }

    private getAlertConfig(partialConfig: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const config = this.buildDialogConfig(
            {
                maxHeight: "250px",
                maxWidth: "350px",
                data: {
                    title: "",
                    message: "",
                    confirmButton: this.getDefaultConfirmButton({
                        text: "Ok",
                    }),
                    cancelButton: this.getDefaultCancelButton({ show: false }),
                    showButtonBar: true,
                    confirmOnEnter: true,
                },
            },
            partialConfig
        );

        return config;
    }

    private getLoaderConfig(partialConfig: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const config = this.buildDialogConfig(
            {
                maxHeight: "325px",
                maxWidth: "400px",
                data: {
                    title: "Loading, please wait...",
                    centerText: true,

                    confirmButton: this.getDefaultCancelButton({ show: false }),
                    cancelButton: this.getDefaultCancelButton({ show: false }),
                    showLoader: true,
                },
            },
            partialConfig
        );

        return config;
    }

    private getPromptConfig(partialConfig: Partial<MatDialogConfig<ICerrixPrompt>>) {
        const config = this.buildDialogConfig(
            {
                data: {
                    title: "",
                    message: "",
                    confirmButton: this.getDefaultConfirmButton(),
                    cancelButton: this.getDefaultCancelButton(),
                    showButtonBar: true,
                    closeOnEsc: true,
                    confirmOnEnter: false,
                },
            },
            partialConfig
        );

        return config;
    }

    private buildDialogConfig(
        typeSpecificConfig: Partial<MatDialogConfig<ICerrixPrompt>>,
        partialConfig: Partial<MatDialogConfig<ICerrixPrompt>>
    ) {
        let dialogConfig = this.getDialogConfig(typeSpecificConfig);

        if (partialConfig) {
            dialogConfig = {
                ...dialogConfig,
                ...partialConfig,
                data: {
                    ...dialogConfig.data,
                    ...partialConfig.data,

                    confirmButton: {
                        ...dialogConfig.data.confirmButton,
                        ...partialConfig.data.confirmButton,
                    },

                    cancelButton: {
                        ...dialogConfig.data.cancelButton,
                        ...partialConfig.data.cancelButton,
                    },
                },
            };
        }

        return dialogConfig;
    }

    private getDialogConfig(partialConfig: Partial<MatDialogConfig<ICerrixPrompt>>) {
        let baseConfig = <MatDialogConfig<ICerrixPrompt>>{
            maxHeight: "600px",
            maxWidth: "600px",
            height: "100vh",
            width: "100vw",
            closeOnNavigation: false,
            panelClass: "unpadded-matdialog",
            disableClose: true,
        };

        if (partialConfig) {
            baseConfig = Object.assign(baseConfig, partialConfig);
        }

        return baseConfig;
    }

    private getDefaultConfirmButton(partialConfig?: Partial<CerrixButtonConfig>) {
        let baseConfig = <CerrixButtonConfig>{
            uiid: "cerrixprompt-confirmbutton",
            text: "Confirm",
            icon: "far fa-check",
            class: "btn btn-cerrix",
            show: true,
        };

        if (partialConfig) {
            baseConfig = Object.assign(baseConfig, partialConfig);
        }

        return baseConfig;
    }

    private getDefaultCancelButton(partialConfig?: Partial<CerrixButtonConfig>) {
        let baseConfig = <CerrixButtonConfig>{
            text: "Cancel",
            icon: "far fa-times",
            class: "btn btn-secondary",
            show: true,
        };

        if (partialConfig) {
            baseConfig = Object.assign(baseConfig, partialConfig);
        }

        return baseConfig;
    }
}
