import { CommonModule } from "@angular/common";
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { IncidentClassificationService } from "@app/incident/services/incident-classification.service";
import {
    CerrixButtonComponent,
    CerrixColorPickerComponent,
    CerrixFieldWrapperComponent,
    CerrixInputNumericComponent,
    CerrixTitleComponent,
    CerrixValidators,
} from "@cerrix/components";
import { ToastrService } from "ngx-toastr";
import { BaseStandingDataEditComponent } from "../interfaces/base-standing-data-edit.component";

@Component({
    selector: "classification-edit",
    templateUrl: "./classification-edit.component.html",
    styleUrls: ["./classification-edit.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        CerrixTitleComponent,
        CerrixButtonComponent,
        CerrixFieldWrapperComponent,
        CerrixInputNumericComponent,
        CerrixColorPickerComponent,
    ],
    providers: [],
})
export class ClassificationEditComponent implements OnInit, BaseStandingDataEditComponent {
    @Input() public id: string;

    @Output() public onEntityUpdated = new EventEmitter<string>();

    public get hasChanges(): boolean {
        return this.form.dirty;
    }

    private readonly classificationService = inject(IncidentClassificationService);
    private readonly toastrService = inject(ToastrService);
    private readonly destroyRef = inject(DestroyRef);
    protected readonly formBuilder: FormBuilder = inject(FormBuilder);

    protected form: FormGroup = undefined;

    protected loading: boolean = false;

    protected showSavedButton = false;
    private savedButtonTimeout = undefined;

    public ngOnInit() {
        this.loadData();
    }

    protected onSave() {
        if (this.hasChanges) {
            this.validateAllFormFields(this.form);

            if (this.form.valid) {
                this.loading = true;

                if (this.form.value.id) {
                    this.classificationService
                        .editClassification({ ...this.form.value })
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                            next: () => {
                                this.loading = false;

                                this.onEntityUpdated.emit(this.form.value.id);
                                this.loadData();

                                this.displaySavedMessages();
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                } else {
                    this.classificationService
                        .addClassification({ ...this.form.value })
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                            next: (id) => {
                                this.loading = false;

                                this.id = id;
                                this.onEntityUpdated.emit(id);
                                this.loadData();

                                this.displaySavedMessages();
                            },
                            error: () => {
                                this.loading = false;
                            },
                        });
                }
            }
        }
    }

    protected onDelete() {
        this.loading = true;

        this.classificationService
            .deleteClassification(this.form.value.id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.loading = false;

                    this.toastMessage("Deleted");

                    this.onEntityUpdated.emit(null);
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    private loadData() {
        if (this.savedButtonTimeout) {
            clearTimeout(this.savedButtonTimeout);
            this.showSavedButton = false;
        }

        this.resetForm();

        if (this.id) {
            this.loading = true;

            this.classificationService
                .getClassification(this.id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((classification) => {
                    this.form.patchValue(
                        {
                            id: classification.id,
                            name: classification.name,
                            value: classification.value,
                            color: classification.color,
                        },
                        { emitEvent: false }
                    );

                    this.loading = false;
                });
        }
    }

    private resetForm(): void {
        this.form = this.formBuilder.group({
            id: new FormControl<string | undefined>(undefined),
            name: new FormControl<string | undefined>(undefined, [
                CerrixValidators.required(),
                CerrixValidators.maxLength(300),
            ]),
            value: new FormControl<number | undefined>(undefined, [
                CerrixValidators.required("The Value is required"),
            ]),
            color: new FormControl<string | undefined>("#000000", CerrixValidators.required()),
        });
    }

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            formGroup.get(field)?.markAsTouched({ onlySelf: true });
        });
    }

    private displaySavedMessages() {
        this.showSavedButton = true;
        this.savedButtonTimeout = setTimeout(() => {
            this.savedButtonTimeout = undefined;
            this.showSavedButton = false;
        }, 2500);

        this.toastMessage("Saved");
    }

    private toastMessage(message: string) {
        this.toastrService.success(
            `<span class="toast-html-message">${message}<span class="material-symbols-outlined">check</span></span>`,
            undefined,
            {
                enableHtml: true,
            }
        );
    }
}
