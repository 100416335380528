import { Component } from "@angular/core";
import { BaseModal } from "@app/shared/cerrix-modal/BaseModal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EntityModel } from "@app/datamanagement/models/EntityModel";

@Component({
    selector: "entity-dialog",
    templateUrl: "./entity-dialog.component.html",
    styleUrls: ["./entity-dialog.component.scss", "../../../../sass/datamanagement.scss"],
})
export class EntityDialogComponent extends BaseModal {
    entities: EntityModel[];

    constructor(_bsModalRef: BsModalRef) {
        super(_bsModalRef);
    }

    changed(currentSelection: EntityModel[]) {
        const currentSelectedIds = currentSelection.map((x) => x.id);
        this.entities.forEach((x) => {
            x.selected = currentSelectedIds.indexOf(x.id) > -1;
        });
    }

    okClick() {
        this._bsModalRef.hide();

        super.onConfirm(this.entities.filter((x) => x.selected));
    }

    closeClick() {
        super.onCancel();
    }
}
