import { Directive, HostListener } from "@angular/core";
import { UserService } from "@app/user/user-profile/shared/user.service";
import moment from "moment";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { LocalizationService } from "../localization";
import { HeartbeatConfigurationModel } from "../models/heartbeat-configuration-model";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";

@Directive({ selector: "[user-heartbeat]" })
export class UserHeartbeatDirective {
    configuration: HeartbeatConfigurationModel;

    heartbeatInterval;

    intervalCycle = 0;
    userIsActive = true;
    toastr: ActiveToast<any>;

    constructor(
        private _userDS: UserService,
        private _userAuthService: UserAuthenticationService,
        private _toastrService: ToastrService,
        private _locService: LocalizationService
    ) {
        this._userDS.getHeartbeat().subscribe((x) => {
            this.configuration = x;
            if (this.configuration.enabled) {
                this.initInterval();
            }
        });
    }

    initInterval() {
        this.heartbeatInterval = setInterval(() => {
            this.intervalCycle++;

            if (this.userIsActive && this.intervalCycle % this.configuration.pingInterval === 0) {
                this._userDS.postHeartbeat().toPromise();
            }

            if (this.intervalCycle === this.configuration.inactiveTime) {
                this.userIsActive = false;
            }

            if (this.intervalCycle === this.configuration.warningTime) {
                const minutesToLogout = this.configuration.logoutTime - this.intervalCycle;
                const time = moment()
                    .add(minutesToLogout, "minutes")
                    .format(this._locService.localizationInfo.shortTimeFormat);

                this.toastr = this._toastrService.warning(
                    `To extend your session click here or within the application.`,
                    `Session ending at ${time}`,
                    {
                        closeButton: false,
                        disableTimeOut: true,
                        positionClass: "toast-top-right",
                    }
                );

                this.toastr.onTap.subscribe(() => this.resetTimer());
            } else if (
                this.intervalCycle > this.configuration.warningTime &&
                this.intervalCycle < this.configuration.logoutTime
            ) {
                this.toastr.message = "" + this.intervalCycle;
            }

            if (this.intervalCycle === this.configuration.logoutTime) {
                this.removeToastr();
                this._userAuthService.initiateNewSession().toPromise();
            }
        }, 60000); // every minute
    }

    @HostListener("keyup")
    @HostListener("wheel")
    @HostListener("click")
    resetTimer() {
        if (this.configuration.enabled) {
            this.intervalCycle = 0;

            this.userIsActive = true;
            this.removeToastr();
        }
    }

    removeToastr() {
        if (this.toastr) {
            this.toastr.portal.destroy();
        }
    }
}
