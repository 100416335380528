<ng-container *ngIf="!linked">
    <div class="fancy-loader"></div>
</ng-container>

<div class="detail-page-card" data-uiid="forms-linkable">
    <div class="detail-page-card-title">Linked forms</div>
    <div class="detail-page-card-actions">
        <button
            class="btn btn-raised"
            (click)="openLinkPopup()"
            data-uiid="button-link-form"
            *ngIf="!disabled"
        >
            <i class="fas fa-link"></i>
            <span class="d-none d-lg-inline">Link</span>
        </button>
    </div>
</div>

<ng-container>
    <div class="all-forms overview-content" *ngIf="linked.length > 0">
        <div class="detail-page-card">
            <div class="detail-page-card-body padded">
                <ul class="form-cards">
                    <li
                        class="card"
                        *ngFor="let item of linked"
                        [ngClass]="{ clickable: item.hasAccess }"
                        data-uiid="linked-form-preview"
                        (click)="openForm(item)"
                    >
                        <div
                            class="card-img-top main"
                            [style.backgroundImage]="
                                'url(' +
                                (item.imageGuid
                                    ? '/thumbnails/FormsOverview/' + item.imageGuid + '.png'
                                    : '/static/images/form_logo_fallback.jpg') +
                                ')'
                            "
                        ></div>
                        <div class="card-body">
                            <h5 class="card-title" data-uiid="linked-form-name">
                                {{ item.name }}
                                <i
                                    class="fas fa-lock ms-2 font-weight-normal"
                                    *ngIf="!item.hasAccess"
                                ></i>
                            </h5>
                            <p class="card-text" title="{{ item.description }}">
                                {{ item.description }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #linkTemplate>
    <app-linkable
        typename="forms"
        [displayValueProp]="displayValueProp"
        [linked]="linked"
        [unlinked]="unlinked"
        [orderByValueProperty]="true"
        [disabled]="disabled"
        [hideActions]="hideActions"
        [hideFilter]="hideFilter"
        [allowAddNew]="allowAddNew"
        [cleanSpacers]="cleanSpacers"
        [rowIconConfig]="rowIconConfig"
    ></app-linkable>
</ng-template>
