import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { InviteConfigModel, InviteModel } from "@models/invite/InviteModel";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { CerrixInviteType } from "@enums/invite/CerrixInviteType";
import { CerrixInviteDataService } from "@app/shared/services/cerrix-invite.service";
import { InviteMessage } from "@models/invite/InviteMessage";
import { InviteSendModel } from "@models/invite/InviteSendModel";
import { InviteUser } from "@models/invite/InviteUser";
import { isGuid } from "@methods/uniqueMethods";
import { finalize } from "rxjs/operators";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";


@Component({
    selector: "app-invite",
    templateUrl: "./invite.component.html",
    styleUrls: ["./invite.component.scss"],
})
export class InviteComponent implements OnInit {
    tab: TabModel;
    id: any;

    objectID: number;
    objectGuid: string;

    inviteModel: InviteModel;
    inviteConfig: InviteConfigModel;

    private inviteType: CerrixInviteType = CerrixInviteType.Default;

    constructor(
        public pages: Pages,
        public tabservice: TabService,
        public _dataService: CerrixInviteDataService,
        private _toastrService: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        if (this.tab.config && this.tab.config.type) {
            this.inviteType = this.tab.config.type;
        }

        this.objectID = isNaN(+this.id) ? null : +this.id;
        this.objectGuid = this.objectID === null && isGuid(this.id) ? (this.id as string) : null;
        this.inviteModel = await this._dataService
            .getInviteModel(this.inviteType, this.objectID, this.objectGuid)
            .toPromise();
        this.setupConfig();
    }

    setupConfig() {
        const config = new InviteConfigModel();

        config.tags = this.inviteModel.keywords;
        config.quickFilterProperties = ["displayName", "mail", "organizationName"];

        if (this.inviteModel.targetName) {
            config.inviteTargetMessage = `Invitation for ${this.inviteModel.targetName}`;
        } else {
            config.inviteTargetMessage = "Generic invitation";
        }

        config.subject = this.inviteModel.subject;
        config.message = this.inviteModel.message;

        config.users = this.inviteModel.users;
        config.userSelection = [];

        config.templates = this.inviteModel.templates;
        config.organizations = this.inviteModel.organizations;

        config.onSaveTemplate = (template: InviteMessage) => {
            template.templateType = this.inviteType;
            return this._dataService.storeTemplate(template).toPromise();
        };

        config.onDeleteTemplate = async (id: number) => {
            await this._dataService.deleteTemplate(this.inviteType, id).toPromise();
            return true;
        };

        config.onSend = (subject: string, message: string, users: InviteUser[]) => {
            if (
                !subject ||
                subject.trim().length === 0 ||
                !message ||
                message.trim().length === 0
            ) {
                this._toastrService.warning(
                    "Please make sure subject and message field are filled in!",
                    "Invitation error"
                );
                return;
            }

            const prompt = this._promptService.loader("Sending invites, please wait...");
            const model = <InviteSendModel>{
                subject: subject,
                message: message,
                users: users.map((x) => x.ID),
                inviteType: this.inviteType,
            };

            this._dataService
                .sendInvite(model.inviteType, model)
                .pipe(finalize(() => prompt.close()))
                .toPromise()
                .then(() => {
                    this._toastrService.success(
                        `Invited ${users.length} user(s)`,
                        "Invite sent successfully!"
                    );
                    this.tab.refresh();
                });
        };

        this.inviteConfig = config;
    }
}
