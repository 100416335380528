import { Component, ElementRef, HostListener } from "@angular/core";

@Component({
    selector: "navbar-snow",
    templateUrl: "./navbar-snow.component.html",
    styleUrls: ["./navbar-snow.component.scss"],
})
export class NavbarSnowComponent {
    keyInput = "";
    n = 60;
    flakes: Flake[] = [];
    interval: number = null;
    snowActive: Boolean = false;
    clicks: number = 0;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {}

    getScreenSize(): { w: number; h: number } {
        return {
            w:
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth,
            h:
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight,
        };
    }

    startSnow() {
        if (this.clicks < 3) {
            this.clicks += 1;
        }
        if (this.clicks == 2) {
            if (!this.snowActive) {
                this.build();
                this.run();
            } else {
                this.stop();
            }
        }
    }

    build() {
        const { w, h } = this.getScreenSize();

        for (let i = 0; i < this.n; i++) {
            const flake = new Flake();

            flake.setPosition(
                Math.round(Math.random() * w - 20),
                Math.round(Math.random() * h - 100)
            );

            this.flakes.push(flake);
            this.elementRef.nativeElement.appendChild(flake.nativeElement);
        }
    }

    run() {
        if (this.interval == null) {
            this.interval = window.setInterval(() => {
                const scrollTop = document.querySelector("html").scrollTop;
                const { w, h } = this.getScreenSize();

                this.flakes.forEach((flake) => {
                    const p = flake.nativeElement.getBoundingClientRect();
                    let y = p.top + (1 + Math.random()) * flake.my;

                    if (y < 0) {
                        y = h;
                    }

                    if (y > h) {
                        y = 0;
                    }

                    const vx = (-3 * flake.mx * (-w * 0.5)) / w;
                    let x = p.left + Math.random() - 0.5 + vx;

                    if (x < 0) {
                        x = w - 20;
                    }

                    if (x > w - 20) {
                        x = 0;
                    }

                    // Change direction
                    if (Math.abs(flake.mx) < 4) {
                        if (Math.random() < 1e-3) {
                            flake.mx *= -1;
                        }
                    } else {
                        if (Math.random() < 5e-2) {
                            flake.mx *= -1;
                        }
                        if (Math.random() < 5e-2) {
                            flake.my *= -1;
                        }
                    }

                    flake.setPosition(x, y + scrollTop);
                });
            }, 50);
            this.snowActive = true;
            this.clicks = 0;
        }
    }

    stop() {
        if (this.interval != null) {
            window.clearInterval(this.interval);
            this.interval = null;
            this.flakes.forEach((flake) => {
                flake.nativeElement.remove();
            });
            this.snowActive = false;
            this.clicks = 0;
        }
    }
}

class Flake {
    nativeElement: HTMLDivElement;
    mx = 1;
    my = 1;

    constructor() {
        this.nativeElement = document.createElement("div");
        this.nativeElement.style.position = "fixed";
        this.nativeElement.style.zIndex = "5000";
        this.nativeElement.style.userSelect = "none";
        this.nativeElement.style.padding = "1em";
        this.nativeElement.style.pointerEvents = "none";
        this.nativeElement.style.fontSize = `${Math.round(10 + 20 * Math.random())}px`;

        this.flake();
    }

    setPosition(x, y) {
        this.nativeElement.style.left = `${x}px`;
        this.nativeElement.style.top = `${y}px`;
    }

    flake() {
        this.mx = Math.sign(this.mx);
        this.my = 1;
        this.nativeElement.innerHTML = "&#10052;";
        this.nativeElement.style.color = "#9999AA";
    }
}
