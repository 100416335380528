import { Component } from "@angular/core";
import { ExportDataService } from "@app/export/services/export-data.service";
import { ExportBase } from "../export-base";

@Component({
    selector: "export-users",
    templateUrl: "./export-users.component.html",
    styleUrls: ["./export-users.component.scss"],
})
export class ExportUsersComponent extends ExportBase {
    constructor(exportDataService: ExportDataService) {
        super(exportDataService);
    }
}
