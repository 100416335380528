<div class="fancy-loader" *ngIf="!config"></div>

<div *ngIf="config">
    <div class="detail-page-action-bar flex-action-bar" *ngIf="!config.hideToolbar">
        <div class="buttons">
            <button
                class="btn btn-cerrix"
                *ngFor="let btn of config.buttons"
                (click)="btn.click(config.items, btn)"
                [disabled]="btn.disabled"
            >
                <i [class]="btn.icon" *ngIf="btn.icon"></i>
                <span>{{ btn.prettyName }}</span>
            </button>
        </div>

        <div class="workflow-area">
            <button (click)="toggleAll(true)" class="btn btn-dark">
                <i class="fas fa-check-square"></i>
                <span class="ms-2">Select all</span>
            </button>

            <button (click)="toggleAll(false)" class="btn btn-dark">
                <i class="fas fa-square"></i>
                <span class="ms-2">Deselect all</span>
            </button>
        </div>
    </div>

    <div class="row filters" *ngIf="config.filters && config.filters.length > 0">
        <div class="form-group col" *ngFor="let filter of config.filters">
            <label>{{ filter.prettyName }}</label>
            <date-input
                *ngIf="filter.filterType == filterTypes.Date"
                [(value)]="filter.value"
                (valueChange)="filterChanged()"
            ></date-input>
            <ng-select
                *ngIf="
                    filter.filterType == filterTypes.SingleSelect ||
                    filter.filterType == filterTypes.MultiSelect
                "
                [multiple]="filter.filterType == filterTypes.MultiSelect"
                [closeOnSelect]="filter.filterType == filterTypes.SingleSelect"
                [items]="filter.data"
                [(ngModel)]="filter.value"
                (change)="filterChanged()"
            >
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngIf="items.length > 0">
                        <span class="ng-value-label">{{ items.length }} selected</span>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    </div>

    <div class="row items" *ngIf="config.items">
        <div
            class="detail-page-card mb-3 col-12 col-lg-6 col-xxl-4"
            *ngFor="let item of config.items; trackBy: trackByParentIdentifier.bind(this)"
            [hidden]="item.hide"
        >
            <div
                class="detail-page-card-title text-truncate"
                [matTooltip]="item[config.headerProp]"
            >
                {{ item[config.headerProp] }}
            </div>
            <div class="detail-page-card-body padded">
                <div class="overview-table">
                    <div class="overview-row" *ngFor="let ovProp of config.overviewProperties">
                        <div class="overview-header">{{ ovProp.prettyName }}:</div>
                        <div
                            class="overview-value text-truncate"
                            [matTooltip]="item[ovProp.propertyName]"
                        >
                            {{ item[ovProp.propertyName] }}
                        </div>
                    </div>
                </div>

                <div class="form-group-separator"></div>

                <div class="row" style="margin: -7px">
                    <div
                        class="text-truncate col-6 p-2"
                        *ngFor="
                            let child of item[config.childProperty];
                            trackBy: trackByChildIdentifier.bind(this)
                        "
                        [matTooltip]="child[config.childNameProperty]"
                        [hidden]="child.hide"
                    >
                        <cerrix-checkbox
                            [label]="child[config.childNameProperty]"
                            [(value)]="child.selected"
                        >
                        </cerrix-checkbox>
                    </div>
                </div>

                <div class="form-group-separator"></div>

                <div class="flex-spacing">
                    <div class="extra-toggles">
                        <cerrix-checkbox
                            [label]="toggle.prettyName"
                            [(value)]="item[toggle.propertyName]"
                            *ngFor="let toggle of config.extraToggles"
                        >
                        </cerrix-checkbox>
                    </div>
                    <button class="btn btn-cerrix btn-sm" (click)="toggleChildren(item)">
                        {{ item[this.config.childToggleProperty] ? "Deselect" : "Select" }}
                        {{ config.childPrettyName }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
