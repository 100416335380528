import { Component, Input, OnInit } from "@angular/core";
import { ControlDetailOverviewModel } from "./ControlDetailOverviewModel";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { ModuleType } from "@enums/ModuleType";
import { ControlDetailOverviewDataService } from "./control-detail-overview.data.service";

@Component({
    selector: "control-detail-overview",
    templateUrl: "./control-detail-overview.component.html",
    styleUrls: ["./control-detail-overview.component.scss"],
})
export class ControlDetailOverviewComponent implements OnInit {
    @Input() ControlGuid: string;
    @Input() Label = "Control overview";

    @Input() ParentModule: ModuleType;
    @Input() ParentGuid: string;

    @Input() HideControlDetails: boolean;
    @Input() HideDesignScore: boolean;
    @Input() HideEffectivenessScore: boolean;
    @Input() HideOpenControlButton: boolean;

    overviewModel: ControlDetailOverviewModel;

    constructor(
        private _controlDetailOverviewDS: ControlDetailOverviewDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit(): void {
        if (this.ParentModule && this.ParentGuid) {
            this._controlDetailOverviewDS
                .getControlOverviewWithModule(this.ControlGuid, this.ParentModule, this.ParentGuid)
                .subscribe((x) => {
                    this.overviewModel = x;
                });
        } else {
            this._controlDetailOverviewDS.getControlOverview(this.ControlGuid).subscribe((x) => {
                this.overviewModel = x;
            });
        }
    }

    openControl(): void {
        if (!this.HideOpenControlButton) {
            this._tabService.generateTab(this._pages.ControlDetail, this.ControlGuid);
        }
    }
}
