export const incidentColumnsTranslation: Map<string, string> = new Map([
    ["Classification", "Classification"],
    ["Organization", "Reporting organization"],
    ["ReportedBy", "Reported by"],
    ["CompletedBy", "Completed by"],
    ["IncidentStatus", "Incident status"],
    ["Name", "Name"],
    ["Identifier", "Identifier"],
    ["Description", "Description"],
    ["OccurredOn", "Occurred on"],
    ["DetectedOn", "Detected on"],
    ["ClosedOn", "Closed on"],
    ["DueDate", "Due date"],
    ["RootCause", "Rootcause"],
    ["InternalIdentifier", "Internal identifier"],
    ["Impact", "Impact description"],
    ["IncidentTypes", "Incident types"],
    ["IncidentBusinessDimensions", "Business dimensions"],
    ["IncidentFrameworkDimensions", "Framework dimensions"],
    ["IncidentRootCauseCategories", "Rootcause category"],
    ["IncidentCausedByOrganizations", "Caused by organizations"],
    ["IncidentAffectedOrganizations", "Affected organizations"],
    ["IncidentCausedByThirdParties", "Caused by third parties"],
    ["IncidentAffectedThirdParties", "Affected third parties"],
    ["IncidentResponsibles", "Responsible"],
    ["IncidentAssessors", "Assessor"],
    ["IncidentInformed", "Informed"],
]);
