import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe, CurrencyPipe, PercentPipe } from "@angular/common";
import { FileSizePipe } from "./file-size.pipe";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { FormatType } from "@enums/FormatType";
import { LocalizationService } from "../localization";

@Pipe({
    name: "cellRenderer",
})
export class CellRendererPipe implements PipeTransform {
    constructor(
        private localizationService: LocalizationService,
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe,
        private filesizePipe: FileSizePipe
    ) {}

    transform(value: any, rendererConfig: RendererConfig): any {
        const localizationInfo = this.localizationService.localizationInfo;
        if (value instanceof Date) {
            if (rendererConfig && rendererConfig.formatType) {
                const dateFormat = this.getDateFormat(rendererConfig.formatType);

                value = this.localizationService.formatDate(value, dateFormat);
            } else if (
                value.getHours() === 0 &&
                value.getMinutes() === 0 &&
                value.getSeconds() === 0
            ) {
                value = this.localizationService.formatDate(value);
            } else {
                value = this.localizationService.formatDate(value, localizationInfo.dateTimeFormat);
            }
        } else if (typeof value === "number") {
            if (rendererConfig && rendererConfig.formatType) {
                const locale = localizationInfo.locale;
                switch (rendererConfig.formatType) {
                    case FormatType.DecimalFormat:
                        value = this.decimalPipe.transform(value, null, locale);
                        break;
                    case FormatType.CurrencyFormat:
                        value = this.currencyPipe.transform(value, null, locale);
                        break;
                    case FormatType.PercentFormat:
                        let divisor = 100;
                        if (rendererConfig.percentMultiplier)
                            divisor *= rendererConfig.percentMultiplier;
                        value = this.percentPipe.transform(value / divisor, null, locale);
                        break;
                    case FormatType.FileSizeFormat:
                        value = this.filesizePipe.transform(value);
                        break;
                }
            }
        }

        return value;
    }

    getDateFormat(formatType: FormatType) {
        const localizationInfo = this.localizationService.localizationInfo;
        if (formatType === FormatType.DateFormat) {
            return localizationInfo.dateFormat;
        } else if (formatType === FormatType.DateShortTimeFormat) {
            return localizationInfo.dateShortTimeFormat;
        } else if (formatType === FormatType.DateTimeFormat) {
            return localizationInfo.dateTimeFormat;
        }
    }
}
