import { SelectColoredOption } from "../../../../common/eff-colored-selector/models/SelectColoredOption";

export class FinalConclusion {
    score?: number;
    conclusion: string;
    availableScores?: SelectColoredOption[] = [];
    author: string;
    authorLabel: string;

    scoreName?: string;
    scoreColor?: string;
    message?: string;

    hideScore?: boolean = false;
}
