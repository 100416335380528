<div class="cerrix-tree-viewer" *ngIf="ejTreeFieldsConfig">
    <ejs-treeview
        id="default"
        [fields]="ejTreeFieldsConfig"
        [allowDragAndDrop]="config.allowDragDrop"
        (nodeSelected)="onSelect($event)"
        (nodeDropped)="onDrop($event)"
        #treeViewComp
    >
        <ng-template #nodeTemplate="" let-data="">
            <div class="tree-node">
                <div class="tree-node-icon" *ngIf="config.iconProperty">
                    <cerrix-icon-viewer [icon]="data.Icon"></cerrix-icon-viewer>
                </div>
                <div class="tree-node-text">
                    {{ data.Name }}
                </div>
            </div>
        </ng-template>
    </ejs-treeview>
</div>
