import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable } from "rxjs";
import { ConnectionWizardModel } from "../third-party-assurance/models/ConnectionWizardModel";
import { TpaConnectionModel } from "../third-party-assurance/models/tpaConnectionModel";
import { TpaControlLinkModel } from "../third-party-assurance/models/TpaControlLinkModel";
import { TpaRequestDetailModel } from "../third-party-assurance/models/TpaRequestDetailModel";
import { TpaRequestModel } from "../third-party-assurance/models/tpaRequestModel";
import { TpaWizardModel } from "../third-party-assurance/models/TpaWizardModel";
import { WizardResponseModel } from "../third-party-assurance/models/WizardResponseModel";

@Injectable()
export class TpaConnectionDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "thirdpartyassurance");
    }

    getTpaConnections(): Observable<TpaConnectionModel[]> {
        const url = "/tpaconnections";
        return this.get<TpaConnectionModel[]>(url);
    }

    getRequests(): Observable<TpaRequestModel[]> {
        const url = "/tparequests";
        return this.get<TpaRequestModel[]>(url);
    }

    getrequestDetail(guid: string): Observable<TpaRequestDetailModel> {
        const url = `/tparequestdetail/${guid}`;
        return this.get<TpaRequestDetailModel>(url);
    }

    getControls(): Observable<TpaControlLinkModel[]> {
        const url = "/tpawizardcontrols";
        return this.get<TpaControlLinkModel[]>(url);
    }

    getWizardSetup(): Observable<TpaWizardModel> {
        const url = "/tpawizardsetup";
        return this.get<TpaWizardModel>(url);
    }

    getTpaConnectionWizardModel(connectionGuid: string): Observable<ConnectionWizardModel> {
        const url = `/tpaconnectionwizardmodel/${connectionGuid}`;
        return this.get<ConnectionWizardModel>(url);
    }

    storeTpaConnection(
        connectionGuid: string,
        model: ConnectionWizardModel
    ): Observable<WizardResponseModel> {
        const url = `/storetpaconnection`;
        return this.post<WizardResponseModel>(model, url);
    }

    deleteTpaConnection(connectinGuid: string) {
        const url = `/deleteconnection/${connectinGuid}`;
        return this.delete(url);
    }
}
