import { isGuid } from "@methods/uniqueMethods";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TaskSeriesDataService } from "@app/tasks/services/task-series-data.service";
import { lastValueFrom } from "rxjs";
import { toPromise } from "@methods/CommonMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { TargetModule } from "@enums/document/TargetModule";
import { TaskSeriesModel } from "@app/tasks/models/task-series-model";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { ControlExecutionDataService } from "@app/controls/control-execution/services/control-execution-data.service";
import { TaskSeriesDetailMainComponent } from "./details-main/task-series-detail-main.component";
import { TaskSeriesRecurrenceModel } from "@app/tasks/models/task-series-recurrence-model";
import { RecurrenceFrequency } from "@app/shared/components/cerrix-recurrence/cerrix-recurrence-frequency.enum";
import { RecurrenceViewModel } from "@app/shared/components/cerrix-recurrence/recurrence-view-model";
import { TaskSeriesControlsComponent } from "./task-series-controls/task-series-controls.component";
import { TaskType } from "@app/tasks/enums/TaskType";
import { ControlExecutionPermissionModel } from "@app/controls/control-execution/control-execution-detail/models/control-execution-permission-model";

@Component({
    selector: "task-series-detail",
    templateUrl: "./task-series-detail.component.html",
    styleUrls: ["./task-series-detail.component.scss"],
})
export class TaskSeriesDetailComponent implements OnInit {
    @ViewChild("detailMain") detailMain: TaskSeriesDetailMainComponent;
    @ViewChild("taskseriescontrols") taskseriescontrols: TaskSeriesControlsComponent;

    constructor(
        private route: ActivatedRoute,
        private _ds: TaskSeriesDataService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _controlExecutionDataService: ControlExecutionDataService
    ) {}

    id: string;
    tab: TabModel;
    model: TaskSeriesModel;
    originalEndDate: Date;
    permissions: ControlExecutionPermissionModel;

    isInitialized = false;

    //docs
    documents: DocumentModel[];
    isDocumentDirty = false;
    targetModule = TargetModule.Control;

    //hyperlinks
    hyperlinksMenuItemId = "menuItemHyperlinks";
    isHyperlinksDirty = false;

    async ngOnInit(): Promise<void> {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;

        this.permissions = await lastValueFrom(this._controlExecutionDataService.getPermissions());

        if (!this.permissions.canView) {
            this.tab.close(false);
            return;
        }

        if (this.id) {
            this.model = await toPromise(this._ds.getTaskSeries(this.id));
            this.tab.name = `(TS) ${this.model.name}`;
            this.originalEndDate = this.model.recurrence?.enddate;
        } else {
            this.model = new TaskSeriesModel();
            this.tab.name = "New task series";
            if (this.tab.config) {
                const controlGuid = this.tab.config.controlGuid;
                const controlId = await toPromise(
                    this._controlExecutionDataService.getControlId(controlGuid)
                );
                this.model.linkedIds.push(controlId);
                this.model.linkedTypes.push(TaskType.ControlExecution);
            }
        }

        this.isInitialized = true;
        this.tab.showLoader = false;
    }

    async save(): Promise<void> {
        const validatingPrompt = this._promptService.loader("Validating changes, please wait...");
        this.detailMain.formGroup.markAllAsTouched();
        const recurrenceValidation = this.detailMain.validateRecurrence(); // returns null if all ok

        if (this.detailMain.formGroup.valid && !recurrenceValidation) {
            // check if any control is linked
            validatingPrompt.close();
            if (!this.model.linkedIds.any()) {
                this._toastr.warning("Please link at least one control to the control execution.");
                return;
            }

            this.model.recurrence = this.ConvertFromViewModel(this.detailMain.recurrenceViewModel);

            if (!(await this.showTimeChangePrompt())) {
                this._toastr.warning("Save cancelled by user request.");
                return;
            }

            if (!(await this.showLastDayOfMonthPrompt())) {
                this._toastr.warning("Save cancelled by user request.");
                return;
            }

            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            let result: string;
            try {
                result = await toPromise(this._ds.saveTaskSeries(this.model));
            } catch {
                savingPrompt.close();
                return;
            }

            const successMessage = this.model.id > 0 ? "Update completed" : "Save successful";
            this._toastr.success(successMessage);

            this.tab.id = result.toString();
            this.tab.refresh();
            savingPrompt.close();
        } else {
            validatingPrompt.close();
            if (this.detailMain.formGroup.valid) {
                this._toastr.warning(recurrenceValidation, "Save failed.", { enableHtml: true });
                return;
            }

            FormValidationHelper.markAllAsTouched(this.detailMain.formGroup);
            const validationErrors = FormValidationHelper.getFormControlErrors(
                this.detailMain.formGroup
            );
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }

    async showTimeChangePrompt(): Promise<boolean> {
        if (!this.model.id || !this.model.recurrence) {
            return true;
        }

        if (!this.hasEndDateChanged()) {
            return true;
        }

        const message =
            this.model.recurrence.enddate > this.originalEndDate
                ? "Changes will be made to all tasks in status ToDo.\n\nExtending the period will add extra tasks and will reset all changes made to individual tasks"
                : "Changes will be made to all tasks in status ToDo.\n\nShortening the period will lead to removal of tasks beyond the new enddate and will reset all changes made to individual tasks";

        const promptResult = await toPromise(
            this._promptService
                .prompt({
                    maxWidth: 500,
                    maxHeight: 270,
                    data: {
                        confirmOnEnter: false,
                        closeOnEsc: false,
                        title: "End date changed",
                        message: message,
                        confirmButton: {
                            text: "OK",
                        },
                        cancelButton: {
                            show: true,
                        },
                    },
                })
                .getResult()
        );
        return promptResult;
    }

    async showLastDayOfMonthPrompt(): Promise<boolean> {
        if (!this.model.recurrence || !this.hasEndDateChanged()) {
            return true;
        }

        if (
            this.checkDayNumberExistenceInRange(
                this.model.recurrence.startdate,
                this.model.recurrence.enddate,
                this.model.recurrence.byMonthDay
            )
        ) {
            return true;
        }

        const message = `Some months contain fewer than ${this.model.recurrence.byMonthDay} days. \n\nIn these months, the recurring instance falls on the last day of the month.`;

        const promptResult = await toPromise(
            this._promptService
                .prompt({
                    maxWidth: 500,
                    maxHeight: 270,
                    data: {
                        confirmOnEnter: false,
                        closeOnEsc: false,
                        title: "Confirm last day of month",
                        message: message,
                        confirmButton: {
                            text: "OK",
                        },
                        cancelButton: {
                            show: true,
                        },
                    },
                })
                .getResult()
        );
        return promptResult;
    }

    handleMenuItemClick(menuItem: TabMenuItem): void {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "menuItemControls":
                    this.taskseriescontrols.load();
                    break;
                case "menuItemDocuments":
                    this.loadDocuments();
                    break;
                case "menuItemHyperlinks":
                    this.loadHyperlinks();
                    break;
            }
        }
    }

    loadDocuments(): void {
        if (!this.documents && this.id && isGuid(this.id.toString())) {
            this._ds.getDocuments(this.id.toString()).subscribe((documents) => {
                this.documents = documents;
                this.model.Documents = documents;
            });
        } else {
            this.documents = [];
        }
    }

    loadHyperlinks(): void {
        if (!this.model.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this._ds.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.model.Hyperlinks = hyperlinks;
            });
        }
    }

    checkDocumentsDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, "menuItemDocuments", true);
        this.isDocumentDirty = true;
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.isHyperlinksDirty = true;
    }

    private ConvertFromViewModel(
        recurrenceViewModel: RecurrenceViewModel
    ): TaskSeriesRecurrenceModel {
        let model: TaskSeriesRecurrenceModel = Object.assign(
            {},
            new TaskSeriesRecurrenceModel(),
            recurrenceViewModel
        );

        if (
            recurrenceViewModel.frequency == RecurrenceFrequency.Monthly ||
            recurrenceViewModel.frequency == RecurrenceFrequency.Yearly
        ) {
            if (!recurrenceViewModel.byMonthDay || recurrenceViewModel.byMonthDay == 0) {
                model.byDay = recurrenceViewModel.otherByDay;
                model.bySetPos = recurrenceViewModel.otherByDayType;
            }
        }
        return model;
    }

    async delete() {
        const deletePrompt = this._promptService.loader("Deleting task serie, please wait...");
        try {
            await toPromise(this._ds.deleteTaskSeries(this.id));

            this._toastr.success("Task series deleted");
            this.tab.close(false);
        } finally {
            deletePrompt.close();
        }
    }

    private checkDayNumberExistenceInRange(
        startdate: Date,
        enddate: Date,
        dayNumber: number
    ): boolean {
        let currentMonth = startdate.getMonth();
        let currentYear = startdate.getFullYear();
        const endMonth = enddate.getMonth();
        const endYear = enddate.getFullYear();

        while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
            if (new Date(currentYear, currentMonth + 1, 0).getDate() < dayNumber) {
                return false;
            }

            currentMonth++;
            if (currentMonth === 12) {
                currentMonth = 0;
                currentYear++;
            }
        }

        return true;
    }

    private hasEndDateChanged(): boolean {
        if (!this.model.recurrence) {
            return false;
        }

        return this.model.recurrence.enddate != this.originalEndDate;
    }

    // Make TaskType enum available in hhtml
    public get taskType(): typeof TaskType {
        return TaskType;
    }
}
