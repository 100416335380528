<div *ngIf="model">
    <div *ngIf="isLoading">
        <div class="fancy-loader"></div>
    </div>
    <div *ngIf="!isLoading">
        <control-linkable
            #linkable
            data-uiid="task-controllinkable"
            typename="Controls"
            displayValueProp="name"
            [linked]="linked"
            [unlinked]="unlinked"
            [hideActions]="true"
            [orderByValueProperty]="true"
            (linkedChange)="linkedChanged($event)"
            [disabled]="disabled"
        >
        </control-linkable>
    </div>
</div>
