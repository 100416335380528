import { Component, OnInit, ViewChild } from "@angular/core";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { CerrixRole } from "@enums/authorization/CerrixRole";
import { isGuid } from "@methods/uniqueMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { UserMaintenancePermissionModel } from "@models/permissions/UserMaintenancePermissionModel";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { UserMaintenanceService } from "../services/user-maintenance.service";
import { UserDetailModel } from "../shared/user-detail-model";
import { UserStandingdataModel } from "../shared/user-standingdata-model";
import { UserAuditComponent } from "./user-audit/user-audit.component";
import { UserHistoryComponent } from "./user-history/user-history.component";

@Component({
    selector: "app-user-detail",
    templateUrl: "./user-detail.component.html",
    styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit {
    @ViewChild("audit") auditComp: UserAuditComponent;
    @ViewChild("history") historyComp: UserHistoryComponent;

    tab: TabModel;
    id: string;

    permissions: UserMaintenancePermissionModel;
    user: UserDetailModel;
    sd: UserStandingdataModel;

    isNewUserRequest = false;
    saveToastr: ActiveToast<any>;
    canSave = false;

    hideSaveBar = false;

    constructor(
        private _ds: UserMaintenanceService,
        private userAuthenticationService: UserAuthenticationService,
        private _toastr: ToastrService,
        permService: PermissionsService,
        private _promptService: CerrixPromptService
    ) {
        this.permissions = permService.permissions.UserMaintenance;
        this.canSave =
            this.permissions.canEditDetails ||
            this.permissions.canEditLogins ||
            this.permissions.canAssignGroups ||
            this.permissions.canAssignRoles;
    }

    ngOnInit() {
        // Is editor and is requesting existing item or is creator and is requesting new item.
        if (
            (this.id && (!isGuid(this.id) || !this.permissions.hasAccess)) ||
            (!this.id && !this.permissions.canCreate)
        ) {
            this.tab.close(false);
            return;
        }

        this.isNewUserRequest = !this.id;
        const call = this.id
            ? this._ds.getUser(this.id).toPromise()
            : this._ds.getNewUser().toPromise();

        call.then((user) => {
            if (!user) {
                this.tab.close(false);
                this._toastr.warning("User not found", "");
                return;
            }
            this.loadUser(user);
        });
    }

    loadUser(user: UserDetailModel) {
        this.user = user;
        this.loadSd();

        this.tab.name = this.isNewUserRequest
            ? `User | New user`
            : `User | ${user.firstname} ${user.lastname}`;
        this.tab.showLoader = false;
    }

    loadSd() {
        this.sd = {
            organizationPromise: this._ds.getOrganizations().toPromise(),
            roleGroupPromise: this.permissions.canAssignRoles
                ? this._ds.getRoleGroups().toPromise()
                : null,
        };

        this.sd.organizationPromise.then((o) => {
            this.sd.organizations = o;
        });

        if (this.permissions.canAssignRoles) {
            this.sd.roleGroupPromise.then((rg) => {
                this.sd.roleGroups = rg;
            });
        }
    }

    async save() {
        const copyModel: UserDetailModel = JSON.parse(JSON.stringify(this.user));
        const selectedRoles: CerrixRole[] = [];
        copyModel.moduleRoles.forEach((module) => {
            module.roles.forEach((role) => {
                if (role.checked) {
                    selectedRoles.push(role.roleID);
                }
            });
        });

        copyModel.selectedRoles = selectedRoles;
        copyModel.moduleRoles = null;

        if (this.saveToastr) {
            this.saveToastr.portal.destroy();
            this.saveToastr = null;
        }

        if (copyModel.isOwnProfile) {
            const logoutConfirmation = await this._promptService
                .confirm(
                    "Updating own profile",
                    "You will be logged out after updating your own profile, do you want to continue?"
                )
                .toPromise();

            if (!logoutConfirmation) {
                return;
            }
        }

        const savingPrompt = this._promptService.loader("Saving user, please wait...");

        this._ds
            .storeUser(copyModel)
            .pipe(finalize(() => savingPrompt.close()))
            .subscribe((x) => {
                if (x.stored) {
                    if (copyModel.isOwnProfile) {
                        this._promptService.loader("Logout user, please wait...");
                        this.userAuthenticationService.logout().subscribe(() => {
                            window.location.reload();
                        });
                    } else {
                        this.tab.id = this.id = x.guid;
                        this.tab.refresh();
                        this.saveToastr = this._toastr.success("User is saved.", "Success.");
                    }
                } else {
                    this.saveToastr = this._toastr.warning(
                        x.validations.join("\n"),
                        "Save failed.",
                        {
                            disableTimeOut: true,
                        }
                    );
                }
            });
    }

    delete() {
        const deletePrompt = this._promptService.loader("Deleting user, please wait...");
        this._ds
            .deleteUser(this.user.guid)
            .pipe(finalize(() => deletePrompt.close()))
            .subscribe((x) => {
                this._toastr.success("User has been deleted.", "Deleted.");
                this.tab.parent.refresh();
                this.tab.close(false);
            });
    }

    unlockUser() {
        if (this.user.dateLocked) {
            this._ds.unlockUser(this.user.guid).subscribe((x) => {
                this.user.dateLocked = null;
                this.user.failedLogins = 0;
            });
        }
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem) {
            return;
        }

        this.hideSaveBar = menuItem.menuItemId === "apikeytab";
        switch (menuItem.menuItemId) {
            case "roles":
                window.dispatchEvent(new Event("resize"));
                break;
            case "audittab":
                this.auditComp.load();
                break;
            case "historytab":
                this.historyComp.load();
                break;
        }
    }
}
