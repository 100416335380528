import { ComparisonType } from "@enums/workspace/ComparisonType";
import { SearchKeyword, SearchKeywordHelper } from "@models/workspace/SearchKeyword";

export class PresetFilterHelper {
    static filterData(
        activeFilters: SearchKeyword[],
        allData: any[],
        numberProperties: string[],
        dateProperties: string[]
    ) {
        const filteredColumns = activeFilters.distinctProp("col").filter((x) => x.trim());
        let foundResults = allData.filter((v) => Object.create(v));

        // Check per filter column
        filteredColumns.forEach((col) => {
            const tempResults = [];
            const colChecks = activeFilters.filter((key) => {
                if (key.col === col && !key.specific) {
                    return key;
                }
            });
            // Col specific check can only be one per column.
            const colSpecificCheck = activeFilters.filter((key) => {
                if (key.col === col && key.specific && !key.remoteSearch) {
                    return key;
                }
            })[0];

            const comparisonTypeGroups: { [comparisonType: string]: SearchKeyword[] } = {};
            for (const colCheck of colChecks) {
                const comparisonType = colCheck.compareType;
                if (!comparisonTypeGroups[comparisonType]) {
                    comparisonTypeGroups[comparisonType] = [];
                }

                comparisonTypeGroups[comparisonType].push(colCheck);
            }

            // Filter rows
            for (const row of foundResults) {
                const rowPassedFilters = this.checkIfRowPassesFilters(
                    col,
                    row,
                    comparisonTypeGroups,
                    colSpecificCheck,
                    numberProperties,
                    dateProperties
                );
                if (rowPassedFilters) {
                    tempResults.push(row);
                }
            }

            foundResults = tempResults;
        });

        const anyKeywords = activeFilters.filter((key) => {
            if (key.col === "") {
                return key;
            }
        });
        anyKeywords.forEach((check) => {
            const tempResults = [];
            for (const row of foundResults) {
                const found = new SearchKeywordHelper(check).isSearched(row);
                if (found) {
                    tempResults.push(row);
                }
            }
            foundResults = tempResults;
        });

        return foundResults;
    }

    private static checkIfRowPassesFilters(
        columnName: string,
        row: any,
        comparisonTypeGroups: { [comparisonType: string]: SearchKeyword[] },
        colSpecificCheck: SearchKeyword,
        numberProperties: string[],
        dateProperties: string[]
    ): boolean {
        let notSpecific = colSpecificCheck === undefined;
        if (notSpecific === false) {
            // so specific
            const columnValue = "" + row[columnName];
            if (columnValue.length > 0) {
                const columnIds = (
                    Array.isArray(row[columnName]) ? row[columnName] : columnValue.split(";")
                ).map(Number);

                const filterIds: number[] = ("" + colSpecificCheck.searchValue)
                    .split(";")
                    .map(Number);

                notSpecific = new SearchKeywordHelper(colSpecificCheck).isSearchedSpecific(
                    columnIds,
                    filterIds
                );
            }
        }

        let found = false;
        if (notSpecific && Object.keys(comparisonTypeGroups).length === 0) {
            found = true;
        } else {
            const passedFilters = this.checkIfRowPassesFiltersByComparisonTypes(
                columnName,
                row,
                comparisonTypeGroups,
                numberProperties,
                dateProperties
            );
            if (passedFilters) {
                found = true;
            }
        }

        return notSpecific && found;
    }

    private static checkIfRowPassesFiltersByComparisonTypes(
        columnName: string,
        row: any,
        comparisonTypeGroups: { [comparisonType: string]: SearchKeyword[] },
        numberProperties: string[],
        dateProperties: string[]
    ): boolean {
        // Types comparison types require "and" for the same column and comparison type
        const andCompareTypes = [
            ComparisonType["Does not contain"],
            ComparisonType["Is not equal to"],
        ];

        // Between comparison types we use "and"
        const comparisonTypeMatches: boolean[] = [];
        for (const key of Object.keys(comparisonTypeGroups)) {
            const comparisonTypeGroup = comparisonTypeGroups[key];
            if (
                andCompareTypes.indexOf(+comparisonTypeGroup[0].compareType as ComparisonType) >= 0
            ) {
                // Handle "and"
                let match = true;
                for (const check of comparisonTypeGroup) {
                    const compareResult = new SearchKeywordHelper(check).isSearched(
                        row,
                        numberProperties.indexOf(columnName) >= 0,
                        dateProperties.indexOf(columnName) >= 0
                    );
                    if (!compareResult) {
                        match = false;
                        break;
                    }
                }

                comparisonTypeMatches.push(match);
            } else {
                // Handle "or"
                let match = false;
                for (const check of comparisonTypeGroup) {
                    const compareResult = new SearchKeywordHelper(check).isSearched(
                        row,
                        numberProperties.indexOf(columnName) >= 0,
                        dateProperties.indexOf(columnName) >= 0
                    );
                    if (compareResult) {
                        match = true;
                        break;
                    }
                }

                comparisonTypeMatches.push(match);
            }
        }

        return comparisonTypeMatches.indexOf(false) < 0;
    }
}
