export interface Shape {
    name: string;
    value?: string;
    width: number;
    height: number;
    style: string;
    isEdge?: boolean;
    svg?: string;
    useImage?: boolean;
    image?: string;
}

export const predefinedShapes: {
    General: Shape[];
    "BPMN General": Shape[];
    "BPMN Events": Shape[];
    Images: Shape[];
} = {
    General: [
        {
            name: "Process",
            width: 100,
            height: 40,
            style: "shape=process",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="7.68" width="28.8" height="14.4" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Rounded Rectangle",
            width: 100,
            height: 40,
            style: "rounded=1;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="7.68" width="28.8" height="14.4" rx="2.16" ry="2.16" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Text",
            value: "Text",
            width: 40,
            height: 20,
            style: "text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="0.73" y="7.3" width="29.2" height="14.6" fill="none" stroke="white" pointer-events="stroke" visibility="hidden" stroke-width="9"></rect><rect x="0.73" y="7.3" width="29.2" height="14.6" fill="none" stroke="none" pointer-events="all"></rect></g><g style=""><g transform="translate(7,10)scale(0.73)"><foreignObject style="overflow:visible;" pointer-events="all" width="22" height="12"><div style="display: inline-block; font-size: 12px; font-family: Helvetica; color: rgb(0, 0, 0); line-height: 1.2; vertical-align: top; width: 24px; white-space: normal; overflow-wrap: normal; text-align: center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;white-space:normal;">Text</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Ellipse",
            width: 120,
            height: 60,
            style: "shape=ellipse",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><ellipse cx="15.84" cy="14.88" rx="14.399999999999999" ry="9.6" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Square",
            width: 100,
            height: 100,
            style: "shape=rectangle",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="2.38" y="1.36" width="27.2" height="27.2" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Circle",
            width: 60,
            height: 60,
            style: "shape=ellipse",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><ellipse cx="15.98" cy="14.96" rx="13.600000000000001" ry="13.600000000000001" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Predefined Process",
            width: 100,
            height: 40,
            style: "shape=predefinedprocess",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="7.68" width="28.8" height="14.4" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect><path d="M 4.32 7.68 L 4.32 22.08 M 27.36 7.68 L 27.36 22.08" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 4.32 7.68 L 4.32 22.08 M 27.36 7.68 L 27.36 22.08" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Diamond",
            width: 80,
            height: 80,
            style: "shape=rhombus",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 15.98 1.36 L 29.58 14.96 L 15.98 28.56 L 2.38 14.96 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Parallelogram",
            width: 100,
            height: 40,
            style: "shape=parallelogram",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 22.08 L 7.2 7.68 L 30.24 7.68 L 24.48 22.08 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Hexagon",
            width: 80,
            height: 60,
            style: "shape=hexagon",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 8.64 5.28 L 23.04 5.28 L 30.24 14.88 L 23.04 24.48 L 8.64 24.48 L 1.44 14.88 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Triangle",
            width: 40,
            height: 60,
            style: "shape=triangle",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.78 1.36 L 26.18 14.96 L 5.78 28.56 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Cylinder",
            width: 60,
            height: 80,
            style: "shape=cylinder",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.78 6.8 C 5.78 -0.45 26.18 -0.45 26.18 6.8 L 26.18 23.12 C 26.18 30.37 5.78 30.37 5.78 23.12 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 5.78 6.8 C 5.78 12.24 26.18 12.24 26.18 6.8" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Cloud",
            width: 80,
            height: 60,
            style: "ellipse;shape=cloud",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 8.64 10.08 C 2.88 10.08 1.44 14.88 6.05 15.84 C 1.44 17.95 6.62 22.56 10.37 20.64 C 12.96 24.48 21.6 24.48 24.48 20.64 C 30.24 20.64 30.24 16.8 26.64 14.88 C 30.24 11.04 24.48 7.2 19.44 9.12 C 15.84 6.24 10.08 6.24 8.64 10.08 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Document",
            width: 120,
            height: 80,
            style: "shape=document",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 5.28 L 30.24 5.28 L 30.24 21.6 Q 23.04 16.42 15.84 21.6 Q 8.64 26.78 1.44 21.6 L 1.44 8.16 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Internal Storage",
            width: 80,
            height: 80,
            style: "shape=internalStorage",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="2.38" y="1.36" width="27.2" height="27.2" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect><path d="M 2.38 8.16 L 29.58 8.16" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 2.38 8.16 L 29.58 8.16" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 9.18 1.36 L 9.18 28.56" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 9.18 1.36 L 9.18 28.56" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Cube",
            width: 80,
            height: 60,
            style: "shape=cube",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 5.28 L 25.44 5.28 L 30.24 10.08 L 30.24 24.48 L 6.24 24.48 L 1.44 19.68 L 1.44 5.28 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 1.44 5.28 L 25.44 5.28 L 30.24 10.08 L 6.24 10.08 Z" fill-opacity="0.05" fill="#000000" stroke="none" pointer-events="all"></path><path d="M 1.44 5.28 L 6.24 10.08 L 6.24 24.48 L 1.44 19.68 Z" fill-opacity="0.1" fill="#000000" stroke="none" pointer-events="all"></path><path d="M 6.24 24.48 L 6.24 10.08 L 1.44 5.28 M 6.24 10.08 L 30.24 10.08" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Step",
            width: 100,
            height: 40,
            style: "shape=step",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 5.28 L 25.44 5.28 L 30.24 14.88 L 25.44 24.48 L 1.44 24.48 L 6.24 14.88 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Trapezoid",
            width: 100,
            height: 40,
            style: "shape=trapezoid",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 22.08 L 7.2 7.68 L 24.48 7.68 L 30.24 22.08 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Tape",
            width: 100,
            height: 40,
            style: "shape=tape",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 7.68 Q 8.64 16.32 15.84 7.68 Q 23.04 -0.96 30.24 7.68 L 30.24 22.08 Q 23.04 13.44 15.84 22.08 Q 8.64 30.72 1.44 22.08 L 1.44 7.68 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Note",
            width: 80,
            height: 100,
            style: "shape=note",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.13 1.35 L 18.63 1.35 L 26.73 9.45 L 26.73 28.35 L 5.13 28.35 L 5.13 1.35 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 18.63 1.35 L 18.63 9.45 L 26.73 9.45 Z" fill-opacity="0.05" fill="#000000" stroke="none" pointer-events="all"></path><path d="M 18.63 1.35 L 18.63 9.45 L 26.73 9.45" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Card",
            width: 80,
            height: 100,
            style: "shape=card",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 13.23 1.35 L 26.73 1.35 L 26.73 28.35 L 5.13 28.35 L 5.13 9.45 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Callout",
            width: 120,
            height: 80,
            style: "shape=callout",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.44 5.28 L 30.24 5.28 L 30.24 17.28 L 20.64 17.28 L 15.84 24.48 L 15.84 17.28 L 1.44 17.28 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Actor",
            width: 40,
            height: 60,
            style: "shape=umlActor",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><ellipse cx="15.75" cy="4.73" rx="3.375" ry="3.375" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse><path d="M 15.75 8.1 L 15.75 19.35 M 15.75 10.35 L 9 10.35 M 15.75 10.35 L 22.5 10.35 M 15.75 19.35 L 9 28.35 M 15.75 19.35 L 22.5 28.35" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 15.75 8.1 L 15.75 19.35 M 15.75 10.35 L 9 10.35 M 15.75 10.35 L 22.5 10.35 M 15.75 19.35 L 9 28.35 M 15.75 19.35 L 22.5 28.35" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Or",
            width: 60,
            height: 80,
            style: "shape=xor",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.78 1.36 Q 26.18 1.36 26.18 14.96 Q 26.18 28.56 5.78 28.56 Q 15.98 14.96 5.78 1.36 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "And",
            width: 60,
            height: 80,
            style: "shape=or",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.78 1.36 Q 26.18 1.36 26.18 14.96 Q 26.18 28.56 5.78 28.56 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Data Storage",
            width: 100,
            height: 80,
            style: "shape=dataStorage",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 4.35 3.19 L 30.45 3.19 Q 24.65 14.79 30.45 26.39 L 4.35 26.39 Q -1.45 14.79 4.35 3.19 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Curve",
            width: 100,
            height: 40,
            style: "curved=1;edgeStyle=segmentEdgeStyle",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 3.06 28.56 Q 28.56 28.56 15.81 15.81 Q 3.06 3.06 25.31 3.06" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.06 28.56 Q 28.56 28.56 15.81 15.81 Q 3.06 3.06 25.31 3.06" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path><path d="M 27.99 3.06 L 24.42 4.84 L 25.31 3.06 L 24.42 1.27 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Bidirectional Arrow",
            width: 50,
            height: 50,
            style: "shape=flexArrow;endArrow=classic;startArrow=classic;edgeStyle=segmentEdgeStyle;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 11.73 22.96 L 15.81 27.04 L 2.39 28.41 L 3.76 14.99 L 7.84 19.07 L 20.17 6.74 L 16.09 2.66 L 29.51 1.29 L 28.14 14.71 L 24.06 10.63 Z" fill="none" stroke="white" stroke-width="9.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 11.73 22.96 L 15.81 27.04 L 2.39 28.41 L 3.76 14.99 L 7.84 19.07 L 20.17 6.74 L 16.09 2.66 L 29.51 1.29 L 28.14 14.71 L 24.06 10.63 Z" fill="none" stroke="#000000" stroke-width="1.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Arrow",
            width: 50,
            height: 50,
            style: "shape=flexArrow;endArrow=classic;edgeStyle=segmentEdgeStyle;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.66 28.69 L 1.99 25.02 L 20.63 6.37 L 16.77 2.51 L 29.46 1.22 L 28.17 13.91 L 24.31 10.05 Z" fill="none" stroke="white" stroke-width="9.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 5.66 28.69 L 1.99 25.02 L 20.63 6.37 L 16.77 2.51 L 29.46 1.22 L 28.17 13.91 L 24.31 10.05 Z" fill="none" stroke="#000000" stroke-width="1.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Link",
            width: 100,
            height: 40,
            style: "shape=link",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.86 26.82 L 27.86 0.82 M 29.34 2.3 L 3.34 28.3 M 29.34 2.3" fill="none" stroke="white" stroke-width="9.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 1.86 26.82 L 27.86 0.82 M 29.34 2.3 L 3.34 28.3 M 29.34 2.3" fill="none" stroke="#000000" stroke-width="1.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Dashed Line",
            width: 100,
            height: 40,
            style: "endArrow=none;dashed=1;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 2.12 27.56 L 28.62 1.06" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 2.12 27.56 L 28.62 1.06" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" stroke-dasharray="1.59 1.59" pointer-events="stroke"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Line",
            width: 100,
            height: 40,
            style: "endArrow=none;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 2.12 27.56 L 28.62 1.06" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 2.12 27.56 L 28.62 1.06" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Bidirectional Connector",
            width: 100,
            height: 40,
            style: "endArrow=classic;startArrow=classic;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 7.18 23.16 L 23.98 6.36" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 7.18 23.16 L 23.98 6.36" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path><path d="M 5.65 24.69 L 6.67 21.64 L 7.18 23.16 L 8.7 23.67 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 25.51 4.83 L 24.49 7.88 L 23.98 6.36 L 22.46 5.85 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Directional Connector",
            width: 100,
            height: 40,
            style: "endArrow=classic;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 5.04 25.2 L 24.15 6.09" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 5.04 25.2 L 24.15 6.09" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path><path d="M 25.71 4.53 L 24.67 7.65 L 24.15 6.09 L 22.59 5.57 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Risk",
            width: 48,
            height: 48,
            style: "shape=risk;risk;",
            useImage: true,
            image: "assets/mxgraph/editors/images/cerrix-risk.png",
        },
        {
            name: "Control",
            width: 48,
            height: 48,
            style: "shape=riskcontrol",
            useImage: true,
            image: "assets/mxgraph/editors/images/cerrix-control.png",
        },
    ],
    "BPMN General": [
        {
            name: "Process",
            value: "Process",
            width: 120,
            height: 80,
            style: "shape=bpmnProcess;rounded=1;html=1;whiteSpace=wrap;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="5.28" width="28.8" height="19.2" rx="2.88" ry="2.88" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g><g style=""><g transform="translate(12,13)scale(0.24)"><foreignObject style="overflow:visible;" pointer-events="all" width="24" height="12"><div style="display: inline-block; font-size: 12px; font-family: Helvetica; color: rgb(0, 0, 0); line-height: 1.2; vertical-align: top; width: 26px; white-space: normal; overflow-wrap: normal; text-align: center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;white-space:normal;">Task</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Transaction",
            value: "Transaction",
            width: 120,
            height: 80,
            style: "shape=bpmnTransaction;rounded=1;html=1;whiteSpace=wrap;double=1;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="5.28" width="28.8" height="19.2" rx="2.88" ry="2.88" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect><rect x="1.92" y="5.76" width="27.84" height="18.24" rx="2.74" ry="2.74" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g><g style=""><g transform="translate(8,13)scale(0.24)"><foreignObject style="overflow:visible;" pointer-events="all" width="62" height="12"><div style="display: inline-block; font-size: 12px; font-family: Helvetica; color: rgb(0, 0, 0); line-height: 1.2; vertical-align: top; width: 64px; white-space: normal; overflow-wrap: normal; text-align: center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;white-space:normal;">Transaction</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Event\nSub-Process",
            value: "Event Sub-Process",
            width: 120,
            height: 80,
            style: "shape=bpmnEventSubProcess;rounded=1;html=1;whiteSpace=wrap;dashed=1;dashPattern=1 4;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="5.28" width="28.8" height="19.2" rx="2.88" ry="2.88" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-dasharray="0.24 0.96" pointer-events="all"></rect></g><g style=""><g transform="translate(7,11)scale(0.24)"><foreignObject style="overflow:visible;" pointer-events="all" width="68" height="26"><div style="display: inline-block; font-size: 12px; font-family: Helvetica; color: rgb(0, 0, 0); line-height: 1.2; vertical-align: top; width: 70px; white-space: normal; overflow-wrap: normal; text-align: center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;white-space:normal;">Event<br>Sub-Process</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Call Activity",
            value: "Call Activity",
            width: 120,
            height: 80,
            style: "shape=bpmnCallActivity;rounded=1;html=1;whiteSpace=wrap;strokeWidth=3;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="5.28" width="28.8" height="19.2" rx="2.88" ry="2.88" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g><g style=""><g transform="translate(8,13)scale(0.24)"><foreignObject style="overflow:visible;" pointer-events="all" width="62" height="12"><div style="display: inline-block; font-size: 12px; font-family: Helvetica; color: rgb(0, 0, 0); line-height: 1.2; vertical-align: top; width: 64px; white-space: normal; overflow-wrap: normal; text-align: center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;white-space:normal;">Call Activity</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Pool",
            value: "Pool",
            width: 240,
            height: 320,
            style: "shape=swimlane;html=1;horizontal=1;startSize=20;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform-origin="center" transform="translate(0.5,0.5) rotate(90)" style="visibility: visible;"><path d="M 3.33 4.14 L 1.53 4.14 L 1.53 25.74 L 3.33 25.74" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 3.33 4.14 L 30.33 4.14 L 30.33 25.74 L 3.33 25.74" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.33 4.14 L 30.33 4.14 L 30.33 25.74 L 3.33 25.74" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="none"></path><path d="M 3.33 4.14 L 3.33 25.74" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.33 4.14 L 3.33 25.74" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="none"></path></g><g style=""><g transform="translate(1,15)scale(0.09)rotate(-90,13,6)"><foreignObject style="overflow:visible;" pointer-events="all" width="26" height="12"><div style="display:inline-block;font-size:12px;font-family:Helvetica;color:#000000;line-height:1.2;vertical-align:top;white-space:nowrap;font-weight:bold;text-align:center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;">Pool</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Lane",
            value: "Lane",
            width: 120,
            height: 300,
            style: "shape=swimlane;swimlaneLine=0;html=1;horizontal=1;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform-origin="center" transform="translate(0.5,0.5) rotate(90)" style="visibility: visible;"><path d="M 4.5 9.54 L 2.43 9.54 L 2.43 20.34 L 4.5 20.34" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 4.5 9.54 L 29.43 9.54 L 29.43 20.34 L 4.5 20.34" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 4.5 9.54 L 29.43 9.54 L 29.43 20.34 L 4.5 20.34" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="none"></path></g><g style=""><g transform="translate(2,15)scale(0.09)rotate(-90,14,6)"><foreignObject style="overflow:visible;" pointer-events="all" width="28" height="12"><div style="display:inline-block;font-size:12px;font-family:Helvetica;color:#000000;line-height:1.2;vertical-align:top;white-space:nowrap;font-weight:bold;text-align:center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;">Lane</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Pool (Horizontal)",
            value: "Pool",
            width: 320,
            height: 240,
            style: "shape=swimlane;html=1;horizontal=0;startSize=20;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 3.33 4.14 L 1.53 4.14 L 1.53 25.74 L 3.33 25.74" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 3.33 4.14 L 30.33 4.14 L 30.33 25.74 L 3.33 25.74" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.33 4.14 L 30.33 4.14 L 30.33 25.74 L 3.33 25.74" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="none"></path><path d="M 3.33 4.14 L 3.33 25.74" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.33 4.14 L 3.33 25.74" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="none"></path></g><g style=""><g transform="translate(1,15)scale(0.09)rotate(-90,13,6)"><foreignObject style="overflow:visible;" pointer-events="all" width="26" height="12"><div style="display:inline-block;font-size:12px;font-family:Helvetica;color:#000000;line-height:1.2;vertical-align:top;white-space:nowrap;font-weight:bold;text-align:center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;">Pool</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Lane (Horizontal)",
            value: "Lane",
            width: 300,
            height: 120,
            style: "shape=swimlane;swimlaneLine=0;html=1;horizontal=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g  transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 4.5 9.54 L 2.43 9.54 L 2.43 20.34 L 4.5 20.34" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 4.5 9.54 L 29.43 9.54 L 29.43 20.34 L 4.5 20.34" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 4.5 9.54 L 29.43 9.54 L 29.43 20.34 L 4.5 20.34" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="none"></path></g><g style=""><g transform="translate(2,15)scale(0.09)rotate(-90,14,6)"><foreignObject style="overflow:visible;" pointer-events="all" width="28" height="12"><div style="display:inline-block;font-size:12px;font-family:Helvetica;color:#000000;line-height:1.2;vertical-align:top;white-space:nowrap;font-weight:bold;text-align:center;"><div xmlns="http://www.w3.org/1999/xhtml" style="display:inline-block;text-align:inherit;text-decoration:inherit;">Lane</div></div></foreignObject></g></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Conversation",
            width: 60,
            height: 50,
            style: "shape=bpmnConversation;html=1;whiteSpace=wrap;perimeter=hexagonPerimeter;rounded=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 8.33 2.45 L 23.03 2.45 L 30.38 14.7 L 23.03 26.95 L 8.33 26.95 L 0.98 14.7 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Call Conversation",
            width: 60,
            height: 50,
            style: "shape=bpmnCallConversation;html=1;whiteSpace=wrap;perimeter=hexagonPerimeter;strokeWidth=4;rounded=0",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g style="visibility: visible;"><path d="M 8.74 3.22 L 22.54 3.22 L 29.44 14.72 L 22.54 26.22 L 8.74 26.22 L 1.84 14.72 Z" fill="#ffffff" stroke="#000000" stroke-width="1.84" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Data Store",
            width: 60,
            height: 60,
            style: "shape=datastore;whiteSpace=wrap;html=1;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 2.25 4.95 C 2.25 0.15 29.25 0.15 29.25 4.95 L 29.25 24.75 C 29.25 29.55 2.25 29.55 2.25 24.75 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 2.25 4.95 C 2.25 8.55 29.25 8.55 29.25 4.95 M 2.25 6.75 C 2.25 10.35 29.25 10.35 29.25 6.75 M 2.25 8.55 C 2.25 12.15 29.25 12.15 29.25 8.55" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Off Page Connector",
            width: 60,
            height: 60,
            style: "shape=offPageConnector;whiteSpace=wrap;html=1;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 2.38 1.36 L 29.58 1.36 L 29.58 18.36 L 15.98 28.56 L 2.38 18.36 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Sub-Process Marker",
            width: 14,
            height: 14,
            style: "shape=plus;html=1;outlineConnect=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g style="visibility: visible;"><rect x="1.86" y="1.86" width="26.04" height="26.04" fill="#ffffff" stroke="#000000" stroke-width="1.86" pointer-events="all"></rect><path d="M 14.88 8.93 L 14.88 20.83 M 8.93 14.88 L 20.83 14.88" fill="none" stroke="white" stroke-width="9.86" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 14.88 8.93 L 14.88 20.83 M 8.93 14.88 L 20.83 14.88" fill="none" stroke="#000000" stroke-width="1.86" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Parallel MI Marker",
            width: 14,
            height: 14,
            style: "shape=parallelMarker;html=1;outlineConnect=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g style="visibility: visible;"><rect x="1.86" y="1.86" width="5.21" height="26.04" fill="#000000" stroke="#000000" stroke-width="1.86" pointer-events="all"></rect><rect x="12.28" y="1.86" width="5.21" height="26.04" fill="#000000" stroke="#000000" stroke-width="1.86" pointer-events="all"></rect><rect x="22.69" y="1.86" width="5.21" height="26.04" fill="#000000" stroke="#000000" stroke-width="1.86" pointer-events="all"></rect></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Sequential MI Marker",
            width: 14,
            height: 14,
            style: "shape=parallelMarker;direction=south;html=1;outlineConnect=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g style="visibility: visible;"><rect x="1.86" y="1.86" width="5.21" height="26.04" fill="#000000" stroke="#000000" stroke-width="1.86" transform="rotate(90,14.88,14.88)" pointer-events="all"></rect><rect x="12.28" y="1.86" width="5.21" height="26.04" fill="#000000" stroke="#000000" stroke-width="1.86" transform="rotate(90,14.88,14.88)" pointer-events="all"></rect><rect x="22.69" y="1.86" width="5.21" height="26.04" fill="#000000" stroke="#000000" stroke-width="1.86" transform="rotate(90,14.88,14.88)" pointer-events="all"></rect></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Send Task",
            width: 40,
            height: 30,
            style: "shape=message;whiteSpace=wrap;html=1;outlineConnect=0;fillColor=#000000;strokeColor=#ffffff;strokeWidth=2;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.42 4.26 L 29.82 4.26 L 29.82 25.56 L 1.42 25.56 Z" fill="#000000" stroke="#ffffff" stroke-width="1.42" stroke-miterlimit="10" pointer-events="all"></path><path d="M 1.42 4.26 L 15.62 14.91 L 29.82 4.26" fill="none" stroke="#ffffff" stroke-width="1.42" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Receive Task",
            width: 40,
            height: 30,
            style: "shape=message;whiteSpace=wrap;html=1;outlineConnect=0;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 0.73 3.65 L 29.93 3.65 L 29.93 25.55 L 0.73 25.55 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 0.73 3.65 L 15.33 14.6 L 29.93 3.65" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Group",
            width: 200,
            height: 200,
            style: "rounded=1;arcSize=10;dashed=1;strokeColor=#000000;fillColor=none;gradientColor=none;dashPattern=8 3 1 3;strokeWidth=2;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="2.99" y="1.95" width="26" height="26" rx="2.6" ry="2.6" fill="none" stroke="white" stroke-width="9.3" pointer-events="stroke" visibility="hidden"></rect><rect x="2.99" y="1.95" width="26" height="26" rx="2.6" ry="2.6" fill="none" stroke="#000000" stroke-width="1.3" stroke-dasharray="2.08 0.78 0.26 0.78" pointer-events="all"></rect></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Collapsed Sub-Process",
            value: "Collapsed Sub-Process",
            width: 120,
            height: 80,
            style: "shape=cerrix.collapsed_sub-process",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="1.44" y="5.28" width="28.8" height="19.2" rx="2.88" ry="2.88" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect></g><g style=""><g transform="scale(0.24)"><foreignObject style="overflow: visible; text-align: left;" pointer-events="none" width="417%" height="417%"><div style="display: flex; align-items: unsafe center; justify-content: unsafe center; width: 118px; height: 1px; padding-top: 62px; margin-left: 7px;"><div style="box-sizing: border-box; font-size: 0; text-align: center; "><div style="display: inline-block; font-size: 12px; font-family: Helvetica; color: #000000; line-height: 1.2; pointer-events: all; white-space: normal; word-wrap: normal; ">Sub-Process</div></div></div></foreignObject></g></g><g transform="translate(0.5,0.5)" style="visibility: visible;"><rect x="14.16" y="21.12" width="3.36" height="3.36" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></rect><path d="M 15.84 22.03 L 15.84 23.57 M 15.07 22.8 L 16.61 22.8" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 15.84 22.03 L 15.84 23.57 M 15.07 22.8 L 16.61 22.8" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Sequence Flow",
            width: 100,
            height: 0,
            style: "endArrow=block;endFill=1;endSize=6;html=1;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 3.25 14.75 L 26.22 14.75" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.25 14.75 L 26.22 14.75" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path><path d="M 27.97 14.75 L 26.22 15.63 L 26.22 13.88 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Default Flow",
            width: 100,
            height: 0,
            style: "startArrow=dash;startSize=8;endArrow=block;endFill=1;endSize=6;html=1;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 3.84 14.88 L 25.89 14.88" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 3.84 14.88 L 25.89 14.88" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path><path d="M 5.04 13.68 L 7.44 16.08" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 5.04 13.68 L 7.44 16.08" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 27.57 14.88 L 25.89 15.72 L 25.89 14.04 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Conditional Flow",
            width: 100,
            height: 0,
            style: "startArrow=diamondThin;startFill=0;startSize=14;endArrow=block;endFill=1;endSize=6;html=1;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 8.36 14.74 L 25.05 14.74" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 8.36 14.74 L 25.05 14.74" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="stroke"></path><path d="M 5.06 14.74 L 6.71 13.77 L 8.36 14.74 L 6.71 15.71 Z" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 5.06 14.74 L 6.71 13.77 L 8.36 14.74 L 6.71 15.71 Z" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path><path d="M 26.59 14.74 L 25.05 15.51 L 25.05 13.97 Z" fill="#000000" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Message Flow 1",
            width: 100,
            height: 0,
            style: "startArrow=oval;startFill=0;startSize=7;endArrow=block;endFill=0;endSize=10;dashed=1;html=1;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 4.68 14.88 L 24.93 14.88" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 4.68 14.88 L 24.93 14.88" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" stroke-dasharray="0.72 0.72" pointer-events="stroke"></path><ellipse cx="3.84" cy="14.88" rx="0.84" ry="0.84" fill="none" stroke="white" stroke-width="9.3" pointer-events="stroke" visibility="hidden"></ellipse><ellipse cx="3.84" cy="14.88" rx="0.84" ry="0.84" fill="none" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse><path d="M 27.57 14.88 L 24.93 16.2 L 24.93 13.56 Z" fill="none" stroke="white" stroke-width="9.3" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 27.57 14.88 L 24.93 16.2 L 24.93 13.56 Z" fill="none" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Link",
            width: 100,
            height: 0,
            style: "shape=link;html=1;",
            isEdge: true,
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 1.45 14.21 L 30.45 14.21 M 30.45 15.37 L 1.45 15.37 M 30.45 15.37" fill="none" stroke="white" stroke-width="9.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="stroke" visibility="hidden"></path><path d="M 1.45 14.21 L 30.45 14.21 M 30.45 15.37 L 1.45 15.37 M 30.45 15.37" fill="none" stroke="#000000" stroke-width="1.3" stroke-linejoin="round" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
    ],
    "BPMN Events": [
        {
            name: "Start Event",
            width: 40,
            height: 40,
            style: "shape=startEvent;verticalLabelPosition=bottom;verticalAlign=top;align=center;perimeter=ellipsePerimeter;outlineConnect=0;outline=standard;symbol=general;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><ellipse cx="15.66" cy="14.58" rx="13.5" ry="13.5" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Intermediate Event",
            width: 40,
            height: 40,
            style: "shape=intermediateEvent;verticalLabelPosition=bottom;verticalAlign=top;align=center;perimeter=ellipsePerimeter;outlineConnect=0;outline=throwing;symbol=general;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><ellipse cx="15.66" cy="14.58" rx="13.5" ry="13.5" fill="#ffffff" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse><ellipse cx="15.66" cy="14.58" rx="11.88" ry="11.88" fill="none" stroke="white" stroke-width="9.3" pointer-events="stroke" visibility="hidden"></ellipse><ellipse cx="15.66" cy="14.58" rx="11.88" ry="11.88" fill="none" stroke="#000000" stroke-width="1.3" pointer-events="all"></ellipse></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "End Event",
            width: 40,
            height: 40,
            style: "shape=endEvent;verticalLabelPosition=bottom;verticalAlign=top;align=center;perimeter=ellipsePerimeter;outlineConnect=0;outline=end;symbol=general;",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><ellipse cx="15.66" cy="14.58" rx="13.5" ry="13.5" fill="#ffffff" stroke="#000000" stroke-width="1.62" pointer-events="all"></ellipse></g></g><g></g><g></g></g></svg>',
        },
        {
            name: "Gateway",
            width: 40,
            height: 40,
            style: "shape=rhombus",
            svg: '<svg style="left: 1px; top: 1px; width: 32px; height: 30px; display: block; position: relative; overflow: hidden;"><g><g></g><g><g transform="translate(0.5,0.5)" style="visibility: visible;"><path d="M 15.98 1.36 L 29.58 14.96 L 15.98 28.56 L 2.38 14.96 Z" fill="#ffffff" stroke="#000000" stroke-width="1.3" stroke-miterlimit="10" pointer-events="all"></path></g></g><g></g><g></g></g></svg>',
        },
    ],
    Images: [
        {
            name: "Bell",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/bell.png;",
            useImage: true,
            image: "assets/mxgraph/editors/images/bell.png",
        },
        {
            name: "Box",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/box.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/box.png",
        },
        {
            name: "Cube",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/cube_green.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/cube_green.png",
        },
        {
            name: "User",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/dude3.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/dude3.png",
        },
        {
            name: "Earth",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/earth.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/earth.png",
        },
        {
            name: "Gear",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/gear.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/gear.png",
        },
        {
            name: "Home",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/house.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/house.png",
        },
        {
            name: "Package",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/package.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/package.png",
        },
        {
            name: "Printer",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/printer.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/printer.png",
        },
        {
            name: "Server",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/server.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/server.png",
        },
        {
            name: "Workplace",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/workplace.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/workplace.png",
        },
        {
            name: "Wrench",
            width: 48,
            height: 48,
            style: "shape=image;image=mxGraph/editors/images/wrench.png",
            useImage: true,
            image: "assets/mxgraph/editors/images/wrench.png",
        },
    ],
};
