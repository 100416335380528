import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { Observable, of } from "rxjs";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { DocumentMethods } from "@methods/DocumentMethods";
import { HistoryModel, HistoryChange } from "@models/history/HistoryModel";
import { ThirdPartyReviewFrequencyModel } from "@models/thirdparty/ThirdPartyReviewFrequencyModel";
import { ThirdPartyPermissions } from "@models/thirdparty/ThirdPartyPermissions";
import { isGuid } from "@methods/uniqueMethods";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { ThirdPartyTermsOfNoticeModel } from "@models/thirdparty/ThirdPartyTermsOfNoticeModel";
import { ThirdpartyOrganizationType } from "@models/thirdparty/ThirdpartyOrganizationType";
import { ThirdPartyDashboardModel } from "@models/thirdparty/ThirdPartyDashboardModel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { addParameterToUrl } from "@methods/CommonMethods";

@Injectable()
export class ThirdPartyDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "thirdparties");
    }

    public getThirdParties(): Observable<any[]> {
        return this.get<any[]>();
    }

    public getThirdParty(guid: string): Observable<ThirdPartyModel> {
        if (guid && isGuid(guid)) {
            const url = `/${guid}`;
            return this.get<any>(url);
        }
        return of(new ThirdPartyModel());
    }

    public getRisks(organizationId): Observable<RiskLinkModel[]> {
        if (!organizationId) {
            organizationId = null;
        }

        const url = `/risks/${organizationId}`;
        return this.get<RiskLinkModel[]>(url);
    }

    public getStandingData() {
        const url = `/standingdata`;
        return this.get<any>(url);
    }

    public getFrequency(): Observable<ThirdPartyReviewFrequencyModel[]> {
        const url = `/frequencies`;
        return this.get<ThirdPartyReviewFrequencyModel[]>(url);
    }

    public getTermOfNotice(): Observable<ThirdPartyTermsOfNoticeModel[]> {
        const url = `/termsofnotice`;
        return this.get<ThirdPartyTermsOfNoticeModel[]>(url);
    }

    public getReviewers(...selectedIds: number[]) {
        let url = "/reviewers";
        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get<any[]>(url);
    }

    public getHistory(guid: string): Observable<HistoryModel[]> {
        const url = `/${guid}/history`;
        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(guid: string, mutationId: number): Observable<HistoryChange[]> {
        const url = `/${guid}/history/${mutationId}`;
        return this.get<HistoryChange[]>(url);
    }

    public getPermissions(guid: string): Observable<ThirdPartyPermissions> {
        const url = isGuid(guid) ? `/${guid}/permissions` : "/null/permissions";
        return this.get<ThirdPartyPermissions>(url);
    }

    public getBusinessDimensions(orgs: number[], selectedIds: number[]) {
        let url = "/businessdimensions";
        url = addParameterToUrl(url, "organizations", orgs);
        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get<CerrixTreeItem[]>(url);
    }

    public getFrameworkDimensions(selectedIds: number[]) {
        let url = "/frameworkdimensions";
        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get<CerrixTreeItem[]>(url);
    }

    public getServices() {
        const url = `/services`;
        return this.get<CerrixTreeItem[]>(url);
    }

    public getUsers(...selectedIds: number[]) {
        let url = "/users";
        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get<any[]>(url);
    }

    public getOrganizations(selectedIds: number | number[], type: ThirdpartyOrganizationType) {
        const formData = new FormData();
        formData.append("organizationIds", JSON.stringify(selectedIds));

        const url = `/organizations/${type}`;
        return this.post<CerrixTreeItem[]>(formData, url);
    }

    public getHyperlinks(id: string | number): Observable<HyperlinkModel[]> {
        const url = `/${id}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public deleteThirdParty(guid: string): Observable<string[]> {
        const url = `/${guid}`;
        return this.delete(url);
    }

    public storeThirdParty(model: ThirdPartyModel) {
        const formData = DocumentMethods.convertToFormDataWithFiles("thirdParty", model);
        return this.post<string>(formData);
    }

    public getWidgetInfo(filters: any) {
        const url = `/widgetinfo`;
        return this.post<ThirdPartyDashboardModel[]>(filters, url);
    }

    public hasAccess(tpGuid: string) {
        if (isGuid(tpGuid)) {
            const url = `/hasaccess/${tpGuid}`;
            return this.get<boolean>(url);
        }

        return of(false);
    }

    public getCustomFields(): Observable<CustomFieldStandingDataModel[]> {
        const url = `/customfields`;
        return this.get<CustomFieldStandingDataModel[]>(url);
    }
}
