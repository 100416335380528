<div *ngIf="!changes">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header d-block">
                <h3 class="text-center">Loading Changes...</h3>
            </div>
        </div>
    </div>
</div>

<table
    mat-table
    [dataSource]="dataSource"
    *ngIf="dataSource"
    class="mat-elevation-z8"
    data-uiid="history-table-row-body-row"
>
    <ng-container matColumnDef="Column">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td
            mat-cell
            *matCellDef="let combinedChange; index as i"
            [attr.data-uiid]="'history-table-row-body-row-cell-name-' + i"
        >
            {{ combinedChange.ColumnName }}
        </td>
    </ng-container>
    <ng-container matColumnDef="OldValue">
        <th mat-header-cell *matHeaderCellDef>Old</th>
        <td mat-cell *matCellDef="let combinedChange">
            <div
                class="truncate-text"
                *ngFor="let change of combinedChange.Changes; index as i"
                [title]="change.OldValue"
                [attr.data-uiid]="'history-table-row-body-row-cell-oldvalue-' + i"
                [class.history-update]="change.HistoryType === HistoryType.update"
                [class.history-delete]="change.HistoryType === HistoryType.delete"
            >
                {{ change.OldValue }}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="NewValue">
        <th mat-header-cell *matHeaderCellDef>New</th>
        <td mat-cell *matCellDef="let combinedChange">
            <div
                class="truncate-text"
                *ngFor="let change of combinedChange.Changes; index as i"
                [title]="change.NewValue"
                [attr.data-uiid]="'history-table-row-body-row-cell-newvalue-' + i"
                [class.history-update]="change.HistoryType === HistoryType.update"
                [class.history-insert]="change.HistoryType === HistoryType.insert"
            >
                {{ change.NewValue }}
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
</table>
