<div class="modal-header">
    <h5 class="modal-title">View data structures & manage archive policy</h5>
</div>
<div class="modal-body">
    <div class="content">
        <div class="row datastructures-header">
            <div class="col-6">Data structure:</div>
            <div class="col-6">Archive policy:</div>
        </div>
        <div *ngFor="let dataStructure of dataStructures" class="row datastructures-body">
            <div class="col-6">{{ dataStructure.name }}</div>
            <div class="col-6">
                <div class="form-group">
                    <cerrix-textfield
                        [(ngModel)]="dataStructure.archivePolicy"
                        [disabled]="disabled"
                    ></cerrix-textfield>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="!disabled" type="button" class="btn btn-primary" (click)="okClick()">Ok</button>
    <button type="button" class="btn btn-secondary" (click)="closeClick()">Close</button>
</div>
