import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "./../shared/shared.module";
import { AuditDetailComponent } from "./pages/audit-detail/audit-detail.component";
import { AuditeeAuditDetailsComponent } from "./pages/audit-detail/auditee-audit-details/auditee-audit-details.component";
import { AuditorAuditDetailsComponent } from "./pages/audit-detail/auditor-audit-details/auditor-audit-details.component";
import { AuditDetailAuditeeRequestsComponent } from "./pages/audit-detail/components/audit-detail-auditee-requests/audit-detail-auditee-requests.component";
import { AuditDetailFieldworkExecutionDetailManagerComponent } from "./pages/audit-detail/components/audit-detail-fieldwork-execution-detail-manager/audit-detail-fieldwork-execution-detail-manager.component";
import { AuditDetailFieldworkExecutionComponent } from "./pages/audit-detail/components/audit-detail-fieldwork-execution/audit-detail-fieldwork-execution.component";
import { AuditDetailFieldworkPlanComponent } from "./pages/audit-detail/components/audit-detail-fieldwork-plan/audit-detail-fieldwork-plan.component";
import { AuditDetailFindingLinkingComponent } from "./pages/audit-detail/components/audit-detail-finding-linking/audit-detail-finding-linking.component";
import { AuditDetailMainComponent } from "./pages/audit-detail/components/audit-detail-main/audit-detail-main.component";
import { AuditDetailRiskAssessmentComponent } from "./pages/audit-detail/components/audit-detail-risk-assessment/audit-detail-risk-assessment.component";
import { AuditFieldworkStepDetailComponent } from "./pages/audit-fieldwork-step-detail/audit-fieldwork-step-detail.component";
import { AuditFieldworkStepDetailMainComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-detail-main/audit-fieldwork-step-detail-main.component";
import { AuditFieldworkStepDocumentLinkingComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-document-linking/audit-fieldwork-step-document-linking.component";
import { AuditFieldworkStepExecutionWorkingMethodComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-execution-working-method/audit-fieldwork-step-execution-working-method.component";
import { AuditFieldworkStepFindingLinkingComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-finding-linking/audit-fieldwork-step-finding-linking.component";
import { AuditOverviewComponent } from "./pages/audit-overview/audit-overview.component";
import { AuditPlanningComponent } from "./pages/audit-planning/audit-planning.component";
import { AuditDataService } from "./services/audit-data.service";
import { AuditDocumentRequestDataService } from "./services/audit-document-request-data.service";
import { AuditFieldworkStepDataService } from "./services/audit-fieldworkstep-data.service";
import { AuditModuleDataService } from "./services/audit-module-data.service";
import { AuditThirdPartyComponent } from "./pages/audit-detail/components/audit-thirdparty/audit-thirdparty.component";
import { AuditWorkflowDataService } from "./services/audit-workflow-data.service";
import { AuditFieldworkStepRiskLinkingComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-risk-linking/audit-fieldwork-step-risk-linking.component";
import { AuditFieldworkStepControlLinkingComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-control-linking/audit-fieldwork-step-control-linking.component";
import { AuditDetailWorkflowHistoryComponent } from "./pages/audit-detail/audit-detail-workflow-history/audit-detail-workflow-history.component";
import { AuditDetailFindingReportSummaryComponent } from "./pages/audit-detail/audit-detail-finding-report-summary/audit-detail-finding-report-summary.component";
import { AuditLinkedFormsComponent } from "./pages/audit-detail/components/audit-linked-forms/audit-linked-forms.component";
import { AuditFieldworkStepCommentsComponent } from "./pages/audit-fieldwork-step-detail/components/audit-fieldwork-step-comment/audit-fieldwork-step-comments.component";
import { AuditDetailContextComponent } from "./pages/audit-detail/components/audit-detail-context/audit-detail-context.component";

@NgModule({
    imports: [CommonModule, SharedModule.forRoot()],
    declarations: [
        AuditOverviewComponent,
        AuditDetailComponent,
        AuditPlanningComponent,
        AuditFieldworkStepDetailComponent,

        AuditorAuditDetailsComponent,
        AuditeeAuditDetailsComponent,

        AuditDetailMainComponent,
        AuditDetailAuditeeRequestsComponent,
        AuditDetailRiskAssessmentComponent,
        AuditDetailFieldworkPlanComponent,
        AuditDetailFieldworkExecutionDetailManagerComponent,
        AuditDetailFieldworkExecutionComponent,
        AuditDetailFindingLinkingComponent,
        AuditDetailWorkflowHistoryComponent,

        AuditFieldworkStepDetailMainComponent,
        AuditFieldworkStepFindingLinkingComponent,
        AuditFieldworkStepRiskLinkingComponent,
        AuditFieldworkStepControlLinkingComponent,
        AuditFieldworkStepDocumentLinkingComponent,
        AuditFieldworkStepExecutionWorkingMethodComponent,
        AuditThirdPartyComponent,
        AuditDetailFindingReportSummaryComponent,
        AuditLinkedFormsComponent,
        AuditFieldworkStepCommentsComponent,
        AuditDetailContextComponent,
    ],
})
export class AuditModule {
    static forRoot(): ModuleWithProviders<AuditModule> {
        return {
            ngModule: AuditModule,
            providers: [
                AuditDataService,
                AuditWorkflowDataService,
                AuditFieldworkStepDataService,
                AuditModuleDataService,
                AuditDocumentRequestDataService,
            ],
        };
    }
}
