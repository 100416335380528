import { DataSubjectModel } from "./DataSubjectModel";

export class SystemModel {
    id: number;
    connectedId: number;
    name: string;

    dataSubjects: DataSubjectModel[];
    dataSubjectIds: number[];
    hidden: boolean;

    // only used client-side
    selected: boolean;
    dataSubjectsLoaded: boolean;

    constructor() {
        this.dataSubjects = [];
    }
}