<div class="api-overview">
    <div class="items">
        <div class="detail-page-card">
            <div class="detail-page-card-title">API Keys</div>
            <div class="detail-page-card-actions" *ngIf="canEdit">
                <button class="btn btn-raised" (click)="new()">
                    <i class="fas fa-plus me-2"></i>Add
                </button>
                <div class="spacer"></div>
                <button type="button" class="btn btn-raised green" (click)="loadData()">
                    <i class="fas fa-sync me-2"></i>Refresh
                </button>
            </div>
            <div class="detail-page-card-body">
                <div
                    class="item"
                    [class.selected]="selected && key.id === selected.id"
                    (click)="open(key)"
                    *ngFor="let key of apiKeys"
                >
                    {{ key.name }}
                </div>
            </div>
        </div>
    </div>

    <div class="detail">
        <div class="detail-page-action-bar" *ngIf="selected && canEdit">
            <button class="btn btn-cerrix btn-sm" (click)="save()">
                <i class="fas fa-save"></i>
                <span class="d-none d-lg-inline">Save</span>
            </button>

            <div class="right-area">
                <button class="btn btn-dark btn-sm" (click)="openManual()">
                    <i class="fas fa-question-circle"></i>
                    <span class="d-none d-lg-inline ms-2">API Usage</span>
                </button>
                <button class="btn btn-danger btn-sm" (click)="delete()" *ngIf="selected.id > 0">
                    <i class="fal fa-trash"></i>
                    <span class="d-none d-lg-inline">Delete</span>
                </button>
            </div>
        </div>
        <div class="edit-area">
            <div class="no-selection-message" *ngIf="!selected">
                <span>Select an existing API key from the left menu, or create a new one.</span>
            </div>
            <api-key-detail></api-key-detail>
        </div>
    </div>
</div>
