// Advanced testing contains several instances of the exact same code being used in different components
// To reduce code size and make it easier to maintain, all of those are placed here

import { ControlDataService } from "@app/controls/services/control.data.service";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export function toDate(dateVal: any) {
    const date = new Date(dateVal);
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return date.valueOf();
}

export function getAssessmentScores(controlDs: ControlDataService): Observable<IdNameColorModel[]> {
    const scores = controlDs.getActiveScores().pipe(
        tap((result: IdNameColorModel[]) => {
            const noScoreAssessment = new IdNameColorModel(0, "No score", "666666");
            result.unshift(noScoreAssessment);
        })
    );

    return scores;
}
