import { Component } from "@angular/core";
import { ExportBase } from "../export-base";
import { ExportType } from "@enums/ExportType";
import { ExportDataService } from "@app/export/services/export-data.service";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ModuleType } from "@enums/ModuleType";
import { EventsRisksAndControlsModel } from "@app/export/shared/models/events-risks-and-controls-model";
import { nameof } from "@methods/jeffs-toolkit";

@Component({
    selector: "export-events",
    templateUrl: "./export-events.component.html",
    styleUrls: ["./export-events.component.scss"],
})
export class ExportEventsComponent extends ExportBase {
    includeChecked = false;
    constructor(
        exportDataService: ExportDataService,
        public permService: PermissionsService,
        private cerrixPrompt: CerrixPromptService,
        private dataService: StandingdataDataService
    ) {
        super(exportDataService);
    }

    downloadEvent(type: ExportType) {
        const extra = `includedeletedandclosedmois=${this.includeChecked}`;
        this.exportDataService.downloadExport(type, extra);
    }

    exportEventsRisksAndControls(): void {
        this.cerrixPrompt
            .prompt({
                height: "fit-content",
                data: {
                    title: "Events with Risks & Controls Export",
                    message: "Select between 1 and 200 organizations to generate an export.",

                    fields: [
                        {
                            prettyName: "Organizations",
                            fieldName: nameof<EventsRisksAndControlsModel>((x) => x.organizations),
                            fieldType: GenericListFieldType.MultiTree,
                            minLength: 1,
                            maxLength: 200,
                            required: true,
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getOrganizations(ModuleType.LER);
                            },
                        },
                    ],
                    confirmButton: {
                        text: "Download",
                        icon: "fad fa-cloud-download",
                    },
                },
            })
            .toPromise()
            .then((result: EventsRisksAndControlsModel) => {
                if (!result) {
                    return;
                }

                const stringSections: string[] = [];
                if (result.organizations && result.organizations.length > 0) {
                    stringSections.push("organizations=" + result.organizations.join(";"));
                }

                const extraString = stringSections.length > 0 ? stringSections.join("&") : "";
                this.download(this.ExportType.EventsRisksAndControls, extraString);
            });
    }
}
