import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { CustomFieldBase } from "../custom-field-base";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingDataItemWithColor } from "@models/standingdata/StandingDataItemWithColor";
import { of } from "rxjs";

@Component({
    selector: "framework-assessment-scores",
    templateUrl: "./framework-assessment-scores.component.html",
    styleUrls: ["./framework-assessment-scores.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FrameworkAssessmentScores extends CustomFieldBase implements OnInit {
    @Input() row: any;

    listConfig: GenericListConfig;

    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.value) {
            this.value = [];
        }
        this.initListConfig();
        this.useParentOrDefaultChecked(this.row.UseParentOrDefaultScores);
    }

    useParentOrDefaultChecked(val: boolean) {
        this.row.UseParentOrDefaultScores = val;
        if (val) {
            this.setListConfig(this.row.ParentScores);
        } else {
            this.setListConfig(this.row.Scores);
        }
    }

    private initListConfig() {
        const allowEdit = !this.row.UseParentOrDefaultScores;

        this.listConfig = <GenericListConfig>{
            name: "Framework Assessment Scores",
            isSortable: allowEdit,

            allowAdd: allowEdit,
            allowEdit: allowEdit,
            allowDelete: allowEdit,
            idProp: "ID",
            sortProp: "Sort_Order",

            fields: [
                {
                    prettyName: "Name",
                    fieldName: "Name",
                    fieldType: GenericListFieldType.Text,
                    required: true,
                    unique: true,
                },
                {
                    prettyName: "Color",
                    fieldName: "Color",
                    fieldType: GenericListFieldType.ColorPicker,
                    required: true,
                    maxLength: 128,
                },
                {
                    prettyName: "Weight",
                    fieldName: "Weight",
                    fieldType: GenericListFieldType.Number,
                    required: false,
                    maxLength: 128,
                    defaultValue: 0,
                },
            ],

            dataChanged: (data) => {
                if (this.row.UseParentOrDefaultScores) {
                    this.row.Scores = data;
                }
            },
        };
    }

    private setListConfig(data: StandingDataItemWithColor[]) {
        const allowEdit = !this.row.UseParentOrDefaultScores;

        this.listConfig.isSortable = allowEdit;
        this.listConfig.allowAdd = allowEdit;
        this.listConfig.allowEdit = allowEdit;
        this.listConfig.allowDelete = allowEdit;

        this.listConfig.dataMethod = () => {
            return of(data);
        };

        this.listConfig.reload();
    }
}
