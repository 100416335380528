<ng-template #allDashboardView>
    <div class="fancy-loader" *ngIf="!allDashboards"></div>
    <div class="dashboards" *ngIf="allDashboards">
        <input
            type="text"
            placeholder="Search / filter dashboard"
            class="form-control mb-2"
            [(ngModel)]="scopeFilter"
            (debounce-input-event)="filterDashboards()"
            inputTooltip
        />
        <ngx-masonry [options]="{ percentPosition: true, gutter: 10 }">
            <div ngxMasonryItem class="masonry-item" *ngFor="let dashboard of scopedDashboards">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            <cerrix-icon-viewer [icon]="dashboard.icon"></cerrix-icon-viewer>
                            {{ dashboard.name }}
                        </h5>
                        <p class="card-text text-muted" [matTooltip]="dashboard.description">
                            {{ dashboard.description }}
                        </p>
                        <footer class="blockquote-footer text-muted">
                            {{ dashboard.createdBy }} ({{ dashboard.updatedOn | dateTimeFormat }})
                        </footer>
                        <div class="card-actions mt-2">
                            <button class="button" (click)="loadDashboard(dashboard)">
                                <i class="fad fa-external-link fa-fw"></i>
                            </button>
                            <button class="button" (click)="dashboard.api.showInfo()">
                                <i class="fas fa-info fa-fw"></i>
                            </button>
                            <button class="button" (click)="dashboard.api.setDefault()">
                                <i
                                    class="{{
                                        dashboard.default ? 'fas primary-color' : 'far'
                                    }} fa-house fa-fw"
                                ></i>
                            </button>
                            <button
                                class="button"
                                [disabled]="dashboard.default"
                                (click)="dashboard.api.toggleFavorite()"
                            >
                                <i
                                    class="{{
                                        dashboard.favorite ? 'fas orange' : 'far'
                                    }} fa-star fa-fw"
                                ></i>
                            </button>
                            <button
                                class="button delete-button"
                                (click)="dashboard.api.delete()"
                                *ngIf="dashboard.canEdit"
                            >
                                <i class="fas fa-trash fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ngx-masonry>
    </div>
</ng-template>
