<div class="detail-page-card">
    <div class="detail-page-card-title">Comments</div>
    <div class="detail-page-card-body">
        <div class="fancy-loader submodule my-4" *ngIf="isLoading"></div>

        <div *ngIf="!isLoading">
            <div class="no-comments" *ngIf="!comments || comments.length == 0">There are no comments available yet.
            </div>

            <div class="comments" *ngIf="comments && comments.length > 0">
                <div class="row headers">
                    <div class="col-5">Comment</div>
                    <div class="col">Type</div>
                    <div class="col">User</div>
                    <div class="col">Date</div>
                </div>

                <div class="row comment-row" *ngFor="let comment of scopedComments"
                    [class.comment-deleted]="comment.isDeleted">
                    <div class="col-5"><i style="font-size: 0.8em;" *ngIf="!comment.comment">* No comment
                            given.</i><span *ngIf="comment.comment">{{comment.comment}}</span></div>
                    <div class="col">{{comment.commentType}}</div>
                    <div class="col">{{comment.createdBy}}</div>
                    <div class="col">{{comment.createdAt | dateFormat}}</div>
                </div>
            </div>

            <div class="more-comments" (click)="showAllClick(!showAll)" *ngIf="comments && comments.length > 5">
                View {{showAll ? 'last 5 ' : 'all ' + comments.length}} comments
            </div>
        </div>
    </div>
</div>