import { TestStepScore } from "../enums/test-step-score.enum";

import { DocumentModel } from "@models/documents/documentmodel";
import { EffectivenessStatusType } from "../enums/effectiveness-status-type.enum";

export class EvidenceTestStepEditModel {
    guid: string;
    effectivenessGuid: string;
    sampleGuid: string;
    sampleName: string;
    name: string;
    status: EffectivenessStatusType;
    description: string;
    requiredDocuments: string;
    score: TestStepScore;
    scoreName: string;
    scoreColor: string;
    commentTester: string;
    commentReviewer: string;
    testMethod: string;

    documents: DocumentModel[] = [];

    showEditor = false;

    deserialize(input: EvidenceTestStepEditModel): EvidenceTestStepEditModel {
        this.guid = input.guid;
        this.effectivenessGuid = input.effectivenessGuid;
        this.sampleGuid = input.sampleGuid;

        this.updateValues(input);

        return this;
    }

    updateValues(input: EvidenceTestStepEditModel) {
        this.name = input.name;
        this.status = input.status;
        this.description = input.description;
        this.requiredDocuments = input.requiredDocuments;
        this.score = input.score;
        this.scoreName = input.scoreName;
        this.scoreColor = input.scoreColor;
        this.commentTester = input.commentTester;
        this.commentReviewer = input.commentReviewer;
        this.testMethod = input.testMethod;

        this.documents = input.documents;
    }
}
