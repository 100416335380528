import { Component, OnInit, ViewChild, Input, ViewContainerRef } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ToastrService } from "ngx-toastr";
import { Configuration } from "../../../app.constants";
import { GenericListManagerBase } from "../GenericListManagerBase";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericFormEditorComponent } from "../generic-form-editor/generic-form-editor.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { LinkableComponent } from "@app/shared/linkable/linkable.component";

@Component({
    selector: "generic-list-manager",
    templateUrl: "./generic-list-manager.component.html",
    styleUrls: ["./generic-list-manager.component.scss"],
})
export class GenericListManagerComponent extends GenericListManagerBase implements OnInit {
    @Input() config: GenericListConfig;
    @Input() skipReloadConfirm = false;
    @ViewChild("editorCmp", { static: true }) editorCmp: GenericFormEditorComponent;
    @ViewChild("linkable", { static: true, read: ViewContainerRef }) linkableTemplate;

    scopedTableData: any[];
    scopedTableExpanded = false;
    scopedToggleAllowed = false;

    searchedTableData: any[];

    private bsModalRef: BsModalRef;

    constructor(
        standingdataDS: StandingdataDataService,
        toastr: ToastrService,
        configuration: Configuration,
        private bsModalService: BsModalService,
        promptService: CerrixPromptService
    ) {
        super(standingdataDS, toastr, configuration, promptService);
    }

    ngOnInit() {
        this.editor = this.editorCmp;
        this.baseConfig = this.config;
        this.config.reload = async () => {
            this.tableData = null;
            this.baseConfig = this.config;
            await this.loadPage();
        };

        this.config.onSearch = (terms: string) => {
            this.searchTableData(terms);
        };

        this.loadPage();
    }

    async loadPage() {
        if (!this.skipReloadConfirm && this.tableData && this.tableData.length > 0) {
            this._promptService
                .confirm(
                    "Reload list",
                    `Are you sure you want to reload ${this.config.name}? Unsaved changes will be lost!`
                )
                .onConfirm()
                .subscribe(() => {
                    this.executeLoadPage();
                });
            return;
        }

        await this.executeLoadPage();
    }

    private async executeLoadPage() {
        this.scopedTableData = null;
        this.scopedTableExpanded = false;
        this.scopedToggleAllowed = false;
        this.config.isSortable = this.config.isSortable && this.config.allowEdit;
        this.config.isSortableByColumn =
            this.config.isSortableByColumn && !this.config.isSortable && !this.config.limitViewTo;

        await super.loadPage();
    }

    openLinkModal() {
        if (!this.baseConfig.linkData) {
            throw Error("To use linkable functionality you must provide a linkData object.");
        }

        this.bsModalRef = this.bsModalService.show(LinkableComponent, {
            class: "modal-xl",
            animated: true,
            keyboard: false,
            ignoreBackdropClick: false,
        });

        const linkableCmp = this.bsModalRef.content as LinkableComponent;

        linkableCmp.linkedChange.subscribe((x) => {
            this.data = x;
            this.setTable();
            this.baseConfig.onLinkedDataChange(x);
        });

        linkableCmp.rendererConfig = this.config.rendererConfig;
        linkableCmp.linked = this.data;
        Object.keys(this.baseConfig.linkData).forEach((key) => {
            linkableCmp[key] = this.baseConfig.linkData[key];
        });
        linkableCmp.typename = this.config.name;

        linkableCmp.setupInit();
        linkableCmp.quickFilter();
    }

    // This method overrides parent method.
    setCustomProps(row) {
        if (this.config.isSortable) {
            row[this.sortProp] = this.data.getHigherIDByProp(this.sortProp, 10);
        }
    }

    sortingChanged() {
        const newSorting = [];
        this.tableData.forEach((t) => {
            const dr = this.data.find((d) => d[this.idProp] === t[this.idProp]);
            newSorting.push(dr);
        });

        this.data = newSorting;
        this.emitDataChanged(this.data);
        if (this.config && this.config.onSortChange) {
            this.config.onSortChange(this.data);
        }
    }

    tableReadyMethod() {
        this.setMissingFields();

        this.searchedTableData = this.tableData;
        this.updateTableSort();
        this.setScopedTable();
    }

    setMissingFields() {
        if (!this.tableData || this.tableData.length === 0) {
            return;
        }

        const referenceRow = this.tableData[0];
        const existingHeaders = Object.getOwnPropertyNames(referenceRow);
        const missingHeaders = this.config.fields.filter(
            (x) => existingHeaders.indexOf(x.fieldName) < 0
        );

        this.tableData.forEach((tr) => {
            const dataRow = this.data.find((d) => d[this.idProp] === tr[this.idProp]);
            if (dataRow) {
                missingHeaders.forEach((mh) => (tr[mh.fieldName] = dataRow[mh.fieldName]));
            }
        });
    }

    updateTableSort() {
        if (this.config.overviewSortBy) {
            const sortCol = this.baseConfig.overviewSortBy
                ? this.baseConfig.overviewSortBy
                : this.idProp;
            const sortAsc = this.baseConfig.overviewSortAsc === false ? false : true;

            this.searchedTableData = this.searchedTableData.sortBy(sortCol, sortAsc);
        }
    }

    toggleScopedTableData() {
        this.scopedTableExpanded = !this.scopedTableExpanded;
        this.setScopedTable();
    }

    setScopedTable() {
        this.scopedToggleAllowed =
            this.config.limitViewTo > 0 &&
            !this.config.isSortable &&
            this.searchedTableData.length > this.config.limitViewTo;

        if (this.scopedToggleAllowed && !this.scopedTableExpanded) {
            this.scopedTableData = this.searchedTableData.slice(0, this.config.limitViewTo);
        } else {
            this.scopedTableData = this.searchedTableData;
        }

        this.config._visibleRowCount = this.searchedTableData.length;
    }

    private searchTableData(terms: string) {
        terms = terms.trim();

        if (terms.length === 0) {
            this.searchedTableData = this.tableData;
        } else if (this.tableData && this.tableData.length > 0) {
            this.searchedTableData = [];
            const searchTerms = terms
                .split(" ")
                .filter((t) => t.trim().length > 0)
                .map((t) => t.toLowerCase());

            const properties = Object.getOwnPropertyNames(this.tableData[0]);
            this.tableData.forEach((row) => {
                const rowText = properties
                    .map((p) => row[p])
                    .join("")
                    .toLowerCase();
                const rowMatched = searchTerms.some((st) => rowText.indexOf(st) >= 0);

                if (rowMatched) {
                    this.searchedTableData.push(row);
                }
            });
        }

        this.setScopedTable();
    }
}
