import { Form, FormResult, FormField, FormFieldConfig } from "@app/forms/models";
import { FormFieldType } from "@app/forms/enums/FormFieldTypes";
import { parseTyped } from "@methods/CommonMethods";
import { KeyValue } from "@models/generic/KeyValuePair";

export class FormResultValidation {
    static ValidateResult(form: Form, result: FormResult, answers: any): string[] {
        const validations: string[] = [];
        const nonCompletedPages = form.Pages.filter(
            (p, pi) => result.PageResults[pi].Readonly !== true
        );

        nonCompletedPages.forEach((page) => {
            // Reset previous validations, if they were done.
            page.Fields.forEach((x) => {
                x.Valid = true;
                x.Validation = "";
            });
            page.Fields.forEach((field) =>
                FormResultValidation.ValidateField(field, answers[field.ID]).addTo(validations)
            );
        });

        return validations;
    }

    /**
     * Runs the given field and answer through all validation pipes to check if the field result is valid
     */
    static ValidateField(field: FormField, answer: any): string[] {
        const validations: string[] = [];

        FormResultValidation.ValidateMultiSelectField(field, answer).addTo(validations);
        FormResultValidation.ValidateObjectField(field, answer).addTo(validations);
        FormResultValidation.ValidateTextField(field, answer).addTo(validations);
        FormResultValidation.ValidateNumberField(field, answer).addTo(validations);
        FormResultValidation.ValidateDateField(field, answer).addTo(validations);

        return validations;
    }

    static ValidateMultiSelectField(field: FormField, answer: any): string[] {
        const validations: string[] = [];

        if (
            field.FieldType === FormFieldType.Checkbox ||
            field.FieldType === FormFieldType.Dropdown
        ) {
            const config = JSON.parse(field.CustomSettings) as FormFieldConfig;

            if (field.Required && (!answer || (answer as string).trim().length === 0)) {
                field.Valid = false;
                field.Validation = `This field is Required!`;
                validations.push("Required Field " + field.Name + " is empty");
            }

            if (field.FieldType === FormFieldType.Checkbox || config.multiSelect) {
                const selectedOptions = answer
                    ? answer.indexOf(";") > 0
                        ? (answer as string).split(";")
                        : [answer]
                    : [];
                if (
                    (config.minimumSelection > 0 &&
                        selectedOptions.length < config.minimumSelection) ||
                    (config.maximumSelection > 0 &&
                        selectedOptions.length > config.maximumSelection)
                ) {
                    field.Valid = false;
                    field.Validation = `Correct amount not selected!`;
                    validations.push(
                        "Field " + field.Name + " has an invalid amount of selected values"
                    );
                }
            }
        }

        return validations;
    }

    static ValidateObjectField(field: FormField, answer: any): string[] {
        const validations: string[] = [];

        if (field.FieldType === FormFieldType.FileUpload) {
            if (field.Required && (!answer || answer.length === 0)) {
                field.Valid = false;
                field.Validation = `File required!`;
                validations.push("Field " + field.Name + " is missing a file");
            }
        } else if (field.FieldType === FormFieldType.Structure) {
            const structureAnswer = parseTyped<KeyValue<any, any>[]>(answer, []);
            if (
                field.Required &&
                (!structureAnswer ||
                    !Array.isArray(structureAnswer) ||
                    structureAnswer.length === 0)
            ) {
                field.Valid = false;
                field.Validation = `This field is Required!`;
                validations.push("Required Field " + field.Name + " is empty");
            }
        }

        return validations;
    }

    static ValidateTextField(field: FormField, answer: any): string[] {
        const validations: string[] = [];

        if (
            field.FieldType === FormFieldType.Text ||
            field.FieldType === FormFieldType.TextArea ||
            field.FieldType === FormFieldType.Radio ||
            field.FieldType === FormFieldType.Widget
        ) {
            if (field.Required && (!answer || (answer as string).trim().length === 0)) {
                field.Valid = false;
                field.Validation = `This field is Required!`;
                validations.push("Required Field " + field.Name + " is empty");
            }
        }

        return validations;
    }

    static ValidateNumberField(field: FormField, answer: any): string[] {
        const validations: string[] = [];

        if (field.FieldType === FormFieldType.Number) {
            if (field.Required && (answer === null || isNaN(answer))) {
                field.Valid = false;
                field.Validation = `This field is Required!`;
                validations.push("Required Field " + field.Name + " is empty");
            }
        }

        return validations;
    }

    static ValidateDateField(field: FormField, answer: any): string[] {
        const validations: string[] = [];
        if (field.FieldType === FormFieldType.Date) {
            if (field.Required && !answer) {
                field.Valid = false;
                field.Validation = `This field is Required!`;
                validations.push("Required Field " + field.Name + " is empty");
            }

            if (answer && (!(answer instanceof Date) || isNaN((answer as Date).getTime()))) {
                field.Valid = false;
                field.Validation = `This is not a valid date!`;
                validations.push("Field " + field.Name + " is not valid!");
            }
        }

        return validations;
    }
}
