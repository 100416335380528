<div *ngIf="!model || !model.organizationId">Select "Organization" first</div>

<div class="fancy-loader" *ngIf="isLoading && model && model.organizationId"></div>
<kri-linkable
    *ngIf="!isLoading && model && model.organizationId"
    #linkable
    displayValueProp="name"
    [(linked)]="linked"
    [(unlinked)]="unlinked"
    [hideActions]="true"
    [orderByValueProperty]="true"
    [disabled]="!permissions.canLinkKri"
    (linkedChange)="linkedChanged($event)"
    (onReloadClicked)="reloadClicked()"
>
</kri-linkable>
