<div menuSystem [cerrixTab]="tab">
    <div [attr.name]="'Processed Documents'" menuicon="fas fa-microchip-ai" menusystemsubmenu>
        <div name="Overview" menuicon="fas fa-file-import" menusystemitem>
            <ng-container>
                <ng-container *ngIf="config">
                    <div class="detail-page-action-bar flex-action-bar">
                        <div></div>
                        <div class="right-area">
                            <button (click)="addDocumentToProcess()" class="btn btn-dark">
                                <i class="fas fa-plus me-2"></i>
                                Add document to process
                            </button>
                        </div>
                    </div>
                    <generic-list-manager [config]="config"></generic-list-manager>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #uploadDocument>
    <document-manager-old
        [Documents]="[]"
        [AllowAdd]="true"
        [AllowEdit]="false"
        [AllowDelete]="false"
        [hideLabel]="true"
        [noMargin]="true"
        headerHeight="38px"
        (DocumentsChange)="onDocumentsChange($event)"
        [ShowType]="false"
        [ShowDescription]="false"
        [SingleFileUpload]="true"
        [compactMode]="true"
    >
    </document-manager-old>
</ng-template>
