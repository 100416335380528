import { ManualSectionType } from "./manual-section-type.enum";
import { ManualPageTypeModel } from "./ManualPageTypeModel";

var getPathObj = (section: ManualSectionType, page?: string) => {
    return new ManualPageTypeModel({
        section: section.toString(),
        page: page,
        path: `${section}/${page}`,
    });
};

var ManualPageType: { [key: string]: ManualPageTypeModel } = {
    Pdfs: getPathObj(ManualSectionType.PdfManual),

    ApiUsage: getPathObj(ManualSectionType.ApiManual, "api-usage"),
    ApiPowerBIUsage: getPathObj(ManualSectionType.ApiManual, "api-powerbi-usage"),

    RiskDataApi: getPathObj(ManualSectionType.ApiManual, "orm-apis/get-risk-data"),
    RiskControlDataApi: getPathObj(
        ManualSectionType.ApiManual,
        "orm-apis/get-risk-and-control-data"
    ),
    RiskControlMoiDataApi: getPathObj(
        ManualSectionType.ApiManual,
        "orm-apis/get-risk-control-and-moi-data"
    ),
    RiskAssessmentPerOrganizationDataApi: getPathObj(
        ManualSectionType.ApiManual,
        "orm-apis/get-risk-assessment-per-organization-data"
    ),

    ControlTestInfoApi: getPathObj(ManualSectionType.ApiManual, "controls-apis/get-test-info"),
    ControlUploadSourceDocumentApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/upload-source-document"
    ),
    ControlGenerateSamplesApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/generate-samples"
    ),
    ControlGetSamplesApi: getPathObj(ManualSectionType.ApiManual, "controls-apis/get-samples"),
    ControlSamplesNoEvidenceApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/sample-no-evidence" // No separate right, uses Upload Evidence
    ),
    ControlSamplesUploadEvidenceApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/upload-evidence"
    ),
    ControlTestStepsUploadEvidenceApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/teststeps-upload-evidence" // No separate right, uses Upload Evidence
    ),
    ControlReadyForAssessmentApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/ready-for-assessment"
    ),
    ControlSamplesScoreApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/score-samples-and-test-steps"
    ),
    ControlTestStepsScoreApi: getPathObj(
        ManualSectionType.ApiManual,
        "controls-apis/score-samples-and-test-steps2" // No separate right, uses Score Samples and Test Steps
    ),

    EventDataApi: getPathObj(ManualSectionType.ApiManual, "ler-apis/get-event-data"),
    EventMoiDataApi: getPathObj(ManualSectionType.ApiManual, "ler-apis/get-event-and-moi-data"),
    DataBreachMoiDataApi: getPathObj(
        ManualSectionType.ApiManual,
        "ler-apis/get-data-breach-and-moi-data"
    ),

    FindingReportDataApi: getPathObj(
        ManualSectionType.ApiManual,
        "fr-apis/get-finding-report-and-assessment-data"
    ),

    MoiDataApi: getPathObj(ManualSectionType.ApiManual, "bim-apis/get-moi-data"),
    MoiSummaryDataApi: getPathObj(ManualSectionType.ApiManual, "bim-apis/get-moi-summary-data"),

    KriDataApi: getPathObj(ManualSectionType.ApiManual, "kri-apis/get-kri/kpi-data"),
    KriUploadDataApi: getPathObj(ManualSectionType.ApiManual, "kri-apis/upload-kri/kpi-data"),

    FrmFormsResultsApi: getPathObj(ManualSectionType.ApiManual, "frm-apis/get-forms-results"),

    EC_TPA_RequestExport: getPathObj(
        ManualSectionType.ApiManual,
        "ec-apis/third-party-assurance-requests"
    ),
    EC_TPA_GetResults: getPathObj(ManualSectionType.ApiManual, "ec-apis/tpa-getresults-data"),
    EC_TPA_GetEvidenceDocument: getPathObj(
        ManualSectionType.ApiManual,
        "ec-apis/tpa-getevidencedocument-data"
    ),

    TaskDataApi: getPathObj(ManualSectionType.ApiManual, "tasks-apis/get-task-data"),
};

export { ManualPageType };
