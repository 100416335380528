<div class="fancy-loader" *ngIf="!detailModel"></div>

<div menuSystem [cerrixTab]="tab" *ngIf="detailModel">
    <div [attr.name]="'Processed Document'" menuicon="fas fa-microchip-ai" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <div class="detail-page-card">
                <div class="detail-page-card-title">{{ detailModel.title }}</div>
                <div
                    class="detail-page-card-body padded form-group-styling"
                    *ngFor="let chunk of detailModel.chunks; let i = index"
                >
                    <cerrix-collapsing-box [boxTitle]="'Chunk ' + (i + 1)">
                        <ng-template #content>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Content</label>
                                        <textarea
                                            class="form-control"
                                            rows="5"
                                            [value]="chunk.content"
                                            readonly
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="detail-page-card-body padded form-group-styling"
                                *ngIf="chunk.risks && chunk.risks.length > 0"
                            >
                                <div class="detail-page-card-title">Risks</div>

                                <div class="row" *ngFor="let risk of chunk.risks">
                                    <cerrix-collapsing-box
                                        [boxTitle]="
                                            risk.name +
                                            (risk.isDoneProcessing ? ' (Processed)' : '')
                                        "
                                        [isCollapsed]="risk.isDoneProcessing"
                                    >
                                        <ng-template #content>
                                            <div class="row">
                                                <div class="form-group col">
                                                    <label>Name</label>
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        [value]="risk.name"
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col">
                                                    <label>Description</label>
                                                    <textarea
                                                        class="form-control"
                                                        rows="5"
                                                        [value]="risk.description"
                                                        readonly
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div class="detail-page-action-bar flex-action-bar">
                                                <div></div>
                                                <div class="right-area">
                                                    <div *ngIf="risk.isDoneProcessing">
                                                        Risk has been processed
                                                    </div>
                                                    <div *ngIf="!risk.isDoneProcessing">
                                                        <button
                                                            (click)="acceptRisk(risk)"
                                                            class="btn btn-success"
                                                        >
                                                            <i class="fas fa-plus me-2"></i>
                                                            Accept
                                                        </button>
                                                        <button
                                                            (click)="acceptRiskWithEdits(risk)"
                                                            class="btn btn-cerrix"
                                                        >
                                                            <i class="fas fa-pencil me-2"></i>
                                                            Accept with edits
                                                        </button>
                                                        <button
                                                            (click)="declineRisk(risk)"
                                                            class="btn btn-danger"
                                                        >
                                                            <i class="fas fa-minus me-2"></i>
                                                            Decline
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </cerrix-collapsing-box>
                                </div>
                            </div>
                            <div
                                class="detail-page-card-body padded form-group-styling"
                                *ngIf="chunk.controls && chunk.controls.length > 0"
                            >
                                <div class="detail-page-card-title">Controls</div>

                                <div class="row" *ngFor="let control of chunk.controls">
                                    <cerrix-collapsing-box
                                        [boxTitle]="
                                            control.name +
                                            (control.isDoneProcessing ? ' (Processed)' : '')
                                        "
                                        [isCollapsed]="control.isDoneProcessing"
                                    >
                                        <ng-template #content>
                                            <div class="row">
                                                <div class="form-group col">
                                                    <label>Name</label>
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        [value]="control.name"
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col">
                                                    <label>Description</label>
                                                    <textarea
                                                        class="form-control"
                                                        rows="5"
                                                        [value]="control.description"
                                                        readonly
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div class="detail-page-action-bar flex-action-bar">
                                                <div></div>
                                                <div class="right-area">
                                                    <div *ngIf="control.isDoneProcessing">
                                                        Control has been processed
                                                    </div>
                                                    <div *ngIf="!control.isDoneProcessing">
                                                        <button
                                                            (click)="acceptControl(control)"
                                                            class="btn btn-success"
                                                        >
                                                            <i class="fas fa-plus me-2"></i>
                                                            Accept
                                                        </button>
                                                        <button
                                                            (click)="
                                                                acceptControlWithEdits(control)
                                                            "
                                                            class="btn btn-cerrix"
                                                        >
                                                            <i class="fas fa-pencil me-2"></i>
                                                            Accept with edits
                                                        </button>
                                                        <button
                                                            (click)="declineControl(control)"
                                                            class="btn btn-danger"
                                                        >
                                                            <i class="fas fa-minus me-2"></i>
                                                            Decline
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </cerrix-collapsing-box>
                                </div>
                            </div>
                        </ng-template>
                    </cerrix-collapsing-box>
                </div>
            </div>
        </div>
        <div name="Options" menuicon="fas fa-check-double" menusystemitem>
            <detail-options
                moduleName="processed document"
                [objectName]="detailModel.title"
                [allowDelete]="true"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
