import { Component, ViewChild, ElementRef } from "@angular/core";
import { DocumentMethods } from "@methods/DocumentMethods";
import { ToastrService } from "ngx-toastr";
import { DevManagementDataService } from "@app/admin/services/devmanagement.service";
import { KeyValue } from "@models/generic/KeyValuePair";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "dev-import",
    templateUrl: "./dev-import.component.html",
    styleUrls: ["./dev-import.component.scss"],
})
export class DevImportComponent {
    @ViewChild("fileInput") fileInput: ElementRef;

    allowedFileExtensions =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xls,.xlsx";

    importTypes: KeyValue<number, string>[];
    selectedImportType: number;

    fileValid = false;
    selectedFile: File;
    selectedFileName = "";

    importValidations: KeyValue<string, boolean>[];

    constructor(
        private dataService: DevManagementDataService,
        private promptService: CerrixPromptService,
        private _toastr: ToastrService
    ) {
        this.dataService.getImportTypes().subscribe((x) => {
            this.importTypes = x;
            this.selectedImportType = x[0].Key;
        });
    }

    async import() {
        if (this.selectedImportType > 0 && this.fileValid) {
            const prompt = this.promptService.loader("Importing, please wait...");

            try {
                this.importValidations = await this.dataService
                    .import(this.selectedImportType, this.selectedFile)
                    .toPromise();
            } finally {
                prompt.close();
            }
        }
    }

    updateFile() {
        this.fileValid = false;
        this.selectedFile = null;
        this.selectedFileName = "";

        const allowedFiles = DocumentMethods.filterAllowedFiles(
            this._toastr,
            this.fileInput.nativeElement.files,
            this.allowedFileExtensions
        );

        if (allowedFiles.length !== 1) {
            if (this.fileInput.nativeElement.files.length > 0) {
                this._toastr.warning("File(s) not valid!");
            }

            return;
        }

        this.selectedFile = allowedFiles[0];
        this.selectedFileName = this.selectedFile.name;
        this.fileValid = true;
    }
}
