import { Component, OnInit } from '@angular/core';
import { MatrixModel, MatrixCell } from '@models/generic/MatrixModel';
import { MatrixSelectionModel } from '@models/generic/MatrixSelectionModel';
import { CustomFieldBase } from '../custom-field-base';

@Component({
    selector: 'risk-appetite-selection',
    templateUrl: './risk-appetite-selection.component.html',
    styleUrls: ['./risk-appetite-selection.component.scss']
})
export class RiskAppetiteSelectionComponent extends CustomFieldBase implements OnInit {
    selectedCells: MatrixSelectionModel;

    matrix: MatrixModel;

    likelihoodSize = 5;
    impactSize = 5;
    loaded = false;

    constructor() {
        super();
    }

    ngOnInit() {
        this.matrix = <MatrixModel>{
            colors: ["#92B926", "#D65035"],
            likelihoodSize: 5,
            impactSize: 5
        };

        if (!this.value) {
            this.value = [];
        }

        this.setColors();
        this.loaded = true;
    }

    cellClicked(cell: MatrixCell) {
        if (this.disabled) { return; }

        let startLikelihoodIndex = 0,
            startImpactIndex = 0,
            maxLikelihood = +cell.Likelihood,
            maxImpact = +cell.Impact;

        const selectedCells = JSON.parse(JSON.stringify(this.value)) as MatrixSelectionModel[];
        const removeItems = selectedCells.find(x => x.Likelihood === maxLikelihood && x.Impact === maxImpact) !== undefined;

        if (removeItems) {
            startLikelihoodIndex = maxLikelihood;
            startImpactIndex = maxImpact;
            maxLikelihood = this.matrix.likelihoodSize;
            maxImpact = this.matrix.impactSize;
        }

        for (let likelihoodIndex = startLikelihoodIndex; likelihoodIndex <= maxLikelihood; likelihoodIndex++) {
            for (let impactIndex = startImpactIndex; impactIndex <= maxImpact; impactIndex++) {
                const isToggled = selectedCells.find(x => x.Likelihood === likelihoodIndex && x.Impact === impactIndex);

                if (removeItems && isToggled) {
                    const index = selectedCells.indexOf(isToggled);
                    selectedCells.splice(index, 1);
                } else if (!removeItems && !isToggled) {
                    selectedCells.push(<MatrixSelectionModel>{
                        Likelihood: likelihoodIndex,
                        Impact: impactIndex
                    });
                }
            }
        }

        this.value = selectedCells;
        this.setColors();
        this.valueChange.emit(this.value);
    }

    setColors() {
        if (!this.matrix.matrix) {
            this.matrix.matrix = {};
        }

        for (let lIndex = 0; lIndex < this.matrix.likelihoodSize; lIndex++) {
            if (!this.matrix.matrix[lIndex]) {
                this.matrix.matrix[lIndex] = [];
            }

            for (let iIndex = 0; iIndex < this.matrix.impactSize; iIndex++) {
                const isToggled = (this.value as MatrixSelectionModel[]).find(x => x.Likelihood === lIndex && x.Impact === iIndex);
                this.matrix.matrix[lIndex][iIndex] = isToggled ? 0 : 1;
            }
        }
    }
}
