// This file is a exact copy of ExportType.cs
export enum ExportType {
    // Generic 0 - 9
    Undefined = 0,
    Users = 1,
    UsersImport = 6,
    // Development 10 - 19

    // ORM 20 - 29
    Risks = 20,
    RisksControls = 21,
    RiskControlMois = 23,
    RiskAssessmentPerOrganization = 24,

    // Controls 30 - 39
    ControlAdvancedTestingHistory = 30,
    ControlAdvancedTestingEffectiveness = 31,

    // LER 40 - 49
    Events = 40,
    EventsAll = 41,
    DataBreachesAndMoi = 42,
    EventEmailNotifications = 43,
    EventsRisksAndControls = 44,

    // IM 50 - 59
    Mois = 50,
    MoiSummary = 51,
    MoiComments = 52,

    // BIM 60 - 69
    FindingReportsAndAssessments = 60,
    FindingReportInformation = 61,

    // KRI 70 - 79
    KriData = 70,
    KrisDataPoints = 71,
    KriDataTemplate = 72,
    // NAV 80 - 89

    // DSB 90 - 99

    // FRM 100 - 109

    // CR 110 - 119

    // CI 120 - 129

    // CD 130 - 139

    // MET 140 - 149

    // EXT 150 - 159

    // DM 160 - 169
    DataManagement = 160,
    DataManagementAndMoi = 161,
    DataManagementAll = 162,

    // TP 170 - 179

    // EC 180 - 189

    // Audit 190 - 199

    // Tasks 200 - 209
    Tasks = 200,
}
