<div class="row">
    <div
        class="col"
        *ngIf="!sourceDocuments || !sourceDocumentsFormGroup || !sourceDocumentPermissions"
    >
        <div class="container-with-fancy-loader table-sm">
            <div class="fancy-loader"></div>
        </div>
    </div>
    <div
        class="col-6"
        *ngIf="sourceDocuments && sourceDocumentsFormGroup && sourceDocumentPermissions"
    >
        <div class="row">
            <label
                class="col col-form-label font-weight-bold"
                data-uiid="control-advanced-testing-source-documents-label"
                >Source documents</label
            >
        </div>
        <div class="row">
            <div class="col">
                <div class="table-sm">
                    <div>
                        <div class="text-center" *ngIf="disabled && sourceDocuments.length === 0">
                            <span>No source documents</span>
                        </div>
                        <document-manager-old
                            [Documents]="sourceDocuments"
                            [permissions]="sourceDocumentPermissions"
                            *ngIf="!disabled || sourceDocuments.length > 0"
                            [AllowAdd]="!disabled"
                            [AllowEdit]="!disabled"
                            [AllowDelete]="!disabled"
                            [hideLabel]="true"
                            [hideAddButton]="disabled"
                            [noMargin]="true"
                            [disabled]="disabled"
                            headerHeight="38px"
                            [DocumentTypeTargets]="documentTypeTargets"
                            [compactMode]="true"
                        ></document-manager-old>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="col-6"
        [formGroup]="sourceDocumentsFormGroup"
        *ngIf="sourceDocuments && sourceDocumentsFormGroup && sourceDocumentPermissions"
    >
        <div class="row">
            <label class="col col-form-label font-weight-bold">ID column</label>
        </div>
        <div class="row">
            <div class="col">
                <input
                    excelColumnNameInput
                    formControlName="idColumn"
                    class="form-control form-control-sm"
                    data-uiid="control-advanced-testing-source-document-id-column-input"
                />
            </div>
        </div>
        <div class="row">
            <label class="col col-form-label font-weight-bold">Rows to skip</label>
        </div>
        <div class="row">
            <div class="col">
                <input
                    type="number"
                    formControlName="rowsToSkip"
                    min="0"
                    max="1048575"
                    class="form-control form-control-sm"
                    data-uiid="control-advanced-testing-source-document-rows-to-skip-input"
                />
            </div>
        </div>
    </div>
</div>
