<div class="row no-gutters">
    <div class="col">
        <input
            bsDatepicker
            [(bsValue)]="bsValue"
            (blur)="callTouchedFormValidation()"
            [attr.disabled]="disabled ? '' : null"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [attr.placeholder]="disabled ? '' : placeholder"
            class="form-control form-control-sm"
            (keydown)="dateKeyDown($event)"
        />
    </div>
    <div *ngIf="showTimePicker && isInitialized" class="col-auto" style="margin-top: -1px">
        <timepicker
            #timepicker
            [(ngModel)]="timeValue"
            [showSpinners]="false"
            [showMeridian]="false"
            class="timepicker"
            [attr.disabled]="disabled ? '' : null"
            (keydown)="dateKeyDown($event)"
            [disabled]="disabled"
        ></timepicker>
    </div>
</div>
