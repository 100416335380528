<eff-expansion-panel
    [contentTemplate]="content"
    [panelOpenState]="panelOpenState"
    title="Test instructions"
    class="eff-details-panel"
>
    <ng-template #content>
        <div class="rows-container">
            <div class="" *ngIf="effectivenessModel.testInstructions">
                <div
                    class="regular-text multiple-whitespaces"
                    [innerHTML]="effectivenessModel.testInstructions"
                ></div>
            </div>
            <div
                class="steps-container"
                *ngIf="
                    samples &&
                    samples.length > 0 &&
                    samples[0].testSteps &&
                    samples[0].testSteps.length > 0
                "
            >
                <eff-expansion-panel
                    class="test-step-panel"
                    [panelOpenState]="true"
                    *ngFor="let testStep of samples[0].testSteps"
                    [contentTemplate]="stepContent"
                    [title]="'Step ' + testStep.name"
                    data-uiid="new-eff-upload-source-document-test-instructions-info-box"
                >
                    <ng-template #stepContent>
                        <div class="step-rows">
                            <div class="step-row">
                                <div class="bold-text-small">Test Method:</div>
                                <div class="regular-text-small">{{ testStep.testMethod }}</div>
                            </div>
                            <div class="step-row">
                                <div class="bold-text-small">Description:</div>
                                <div
                                    class="regular-text-small multiple-whitespaces"
                                    [innerHTML]="testStep.description"
                                ></div>
                            </div>
                            <div class="step-row">
                                <div class="bold-text-small">Required documents:</div>
                                <div class="regular-text-small">
                                    {{
                                        testStep.requiredDocuments
                                            ? testStep.requiredDocuments
                                            : "-"
                                    }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </eff-expansion-panel>
            </div>
            <div class="row-container">
                <div class="bold-text row-label">Linked standard:</div>
                <div
                    class="regular-text multiple-whitespaces"
                    [innerHTML]="effectivenessModel.linkedStandard"
                ></div>
            </div>
            <div class="row-container">
                <div class="bold-text row-label">Quality aspects:</div>
                <div
                    class="regular-text multiple-whitespaces"
                    [innerHTML]="effectivenessModel.qualityAspects"
                ></div>
            </div>
            <div class="row-container">
                <div class="bold-text row-label">Test norm:</div>
                <div
                    class="regular-text multiple-whitespaces"
                    [innerHTML]="effectivenessModel.testNorm"
                ></div>
            </div>

            <div
                class="bold-text row-label"
                *ngIf="
                    showEvidenceUploadInstructions && effectivenessModel.evidenceUploadInstructions
                "
            >
                Evidence upload instructions:
            </div>
            <div
                class="regular-text"
                *ngIf="
                    showEvidenceUploadInstructions && effectivenessModel.evidenceUploadInstructions
                "
            >
                <div
                    class="regular-text multiple-whitespaces"
                    [innerHTML]="effectivenessModel.evidenceUploadInstructions"
                ></div>
            </div>
        </div>
    </ng-template>
</eff-expansion-panel>
