import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "bim-get-moi-summary-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class BimGetMoiSummaryDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get MoI Summary Data API",
            httpMethod: "GET",
            path: "/moidata/moisummary",

            parameters: [
                {
                    name: "includeDeleted",
                    in: "query",
                    paramType: "boolean",
                    required: false,
                    example: "true | false",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        "moIs Summary": [
                            {
                                organization_Responsible: "string",
                                organization_Responsible_Path: "string",
                                moi_Source: "string",
                                finding_Report_No: "string",
                                moi_ID: "integer",
                                priority: "string",
                                responsible: "string",
                                "moI Auditor/Assessor": "string",
                                date_Created: "datetime",
                                report_Date: "datetime",
                                report_Due_Date: "datetime",
                                due_Date: "datetime",
                                progress: "integer",
                                status: "string",
                                date_Status_Changed: "datetime",
                                comment_Text: "string",
                                internal_Audit_Comment: "string",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
