import { Component, ElementRef, ViewChild } from "@angular/core";
import { KriWidgetType } from "@app/dashboard/shared/enums/KriWidgetType.enum";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { KriDataService } from "../../../../kri/services/kri-data.service";
import { WidgetFilterKey } from "./../../../shared/models/WidgetFilterKey";
import { BaseHighchartWidget } from "./../BaseHighchartWidget";

@Component({
    selector: "kri-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class KriWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private kriDataService: KriDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            "organizationIds";
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            "businessDimensionIds";
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.kriDataService.getWidgetInfo(filters).toPromise();

        this.config.noData = !data || data.length === 0;
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <any>{
                name: "Key Risk Indicators",
                data,
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        config.chart.spacingLeft = 0;
        config.chart.spacingRight = 0;

        config.plotOptions.pie.size = "65%";
        config.plotOptions.pie.innerSize = "50%";

        return config;
    }

    protected async openWorkspace(ev) {
        const widgettype = this.getFilter(WidgetFilterKey.Kri.KriType);

        const searchKeywords: SearchKeyword[] = [
            this.getSearchKeyword("aggregateScore", ev.point.name, ComparisonType.Contains),
        ];

        if (widgettype == KriWidgetType.Kri) {
            searchKeywords.push(
                this.getSearchKeyword("kriKpi", "No", ComparisonType["Is equal to"])
            );
        } else if (widgettype == KriWidgetType.Kpi) {
            searchKeywords.push(
                this.getSearchKeyword("kriKpi", "Yes", ComparisonType["Is equal to"])
            );
        }

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.KriOverview, preset);
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.KriOverview, preset);
    }
}
