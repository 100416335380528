<div class="detail-page-card" data-uiid="audit-detail-finding-report-summary">
    <div class="detail-page-card-title">Linked Findings Report</div>
    <div class="detail-page-card-actions">
        <button
            class="btn btn-raised"
            (click)="openFindingReport()"
            data-uiid="open-findings-report-button"
            *ngIf="summaryModel && summaryModel.findingReportGuid && summaryModel.canOpen"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Open Findings Report</span>
        </button>
        <button
            class="btn btn-raised"
            (click)="createFindingReport()"
            data-uiid="create-findings-report-button"
            *ngIf="summaryModel && !summaryModel.findingReportGuid && summaryModel.canCreate"
        >
            <i class="fas fa-external-link-alt"></i>
            <span>Create Findings Report</span>
        </button>
        <button
            class="btn btn-raised float-end"
            data-uiid="reload-findings-report-button"
            (click)="load()"
        >
            <i class="fas fa-sync"></i>
            <span>Reload</span>
        </button>
    </div>
    <div class="fancy-loader submodule" *ngIf="isLoading"></div>

    <div
        class="detail-page-card-body padded form-group-styling"
        *ngIf="!isLoading && summaryModel && !summaryModel.findingReportGuid"
    >
        <small data-uiid="no-linked-findings-report-txt"
            >No linked Findings Report available.</small
        >
    </div>
    <div
        class="detail-page-card-body padded form-group-styling"
        *ngIf="!isLoading && summaryModel && summaryModel.findingReportGuid"
    >
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Report name</label>
                    <input
                        type="text"
                        [value]="summaryModel.name"
                        class="form-control form-control-sm"
                        disabled
                        inputTooltip
                        data-uiid="linked-findings-report-name-input"
                    />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Report type</label>
                    <input
                        type="text"
                        [value]="summaryModel.type"
                        class="form-control form-control-sm"
                        disabled
                        inputTooltip
                        data-uiid="linked-findings-report-type-input"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group">
                <label>Scope & Objective</label>
                <textarea
                    [value]="summaryModel.scopeAndObjective"
                    class="form-control form-control-sm"
                    disabled
                    inputTooltip
                    data-uiid="linked-findings-report-scopeAndObjective-input"
                >
                </textarea>
            </div>
        </div>
    </div>
</div>
