<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar flex-action-bar" *ngIf="!tab.showLoader">
    <div>
        <button
            *ngIf="showDocumentButton"
            type="button"
            (click)="saveDocuments()"
            class="btn btn-cerrix"
            [disabled]="!permissions.documentPermissions.canAddDocument || isSavingDocuments"
        >
            <i class="fas fa-save" *ngIf="!isSavingDocuments"></i>
            <i class="fas fa-circle-notch fa-spin ms-2" *ngIf="isSavingDocuments"></i>
            <span class="d-none d-lg-inline">Save documents</span>
        </button>

        <button
            *ngIf="showAssessmentDocumentButton"
            type="button"
            (click)="saveAssessmentDocuments()"
            class="btn btn-cerrix"
            [disabled]="
                !permissions.documentPermissions.canAddDocument || isSavingAssessmentDocuments
            "
        >
            <i class="fas fa-save" *ngIf="!isSavingAssessmentDocuments"></i>
            <i class="fas fa-circle-notch fa-spin ms-2" *ngIf="isSavingAssessmentDocuments"></i>
            <span class="d-none d-lg-inline">Save documents</span>
        </button>

        <ng-container *ngIf="!isStoredAssessment && requestModel">
            <span class="sub-message" *ngIf="requestModel.organizationID">
                Save review configuration to start assessment.
            </span>
            <span class="sub-message" *ngIf="!requestModel.organizationID">
                Organization is required to start assessment.
            </span>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="!tab.showLoader && permissions">
    <div menuSystem [cerrixTab]="tab" (change)="onMenuItemClick($event)">
        <div [attr.name]="'Business Assessment'" menuicon="fad fa-object-group" menusystemsubmenu>
            <div
                *ngIf="requestModel"
                menuItemId="assessment"
                name="Assessment"
                menuicon="fas fa-info-circle"
                menusystemitem
            >
                <business-details [requestModel]="requestModel"></business-details>
                <business-review
                    *ngIf="requestModel.organizationID"
                    [cerrixTab]="tab"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-review>
                <business-risks-widget
                    *ngIf="applicationPermissions.HasRiskAccess"
                    [businessDimensionId]="requestModel.businessID"
                    [orgId]="requestModel.organizationID"
                >
                </business-risks-widget>
                <business-risks
                    *ngIf="applicationPermissions.HasRiskAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-risks>
                <business-controls
                    *ngIf="applicationPermissions.HasControlAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-controls>
                <business-events
                    *ngIf="applicationPermissions.HasEventAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-events>
                <business-mois
                    *ngIf="applicationPermissions.HasMoiAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-mois>
                <business-kris
                    *ngIf="applicationPermissions.HasKriAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-kris>
                <business-dataprocessing
                    *ngIf="applicationPermissions.HasDataManagementAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-dataprocessing>
                <business-thirdparties
                    *ngIf="applicationPermissions.HasThirdPartyAccess"
                    [requestModel]="requestModel"
                    [permissionsModel]="permissions"
                ></business-thirdparties>
            </div>
            <div
                *ngIf="applicationPermissions.HasProcessDiagramAccess && requestModel.businessID"
                name="Process Overview"
                menuicon="fas fa-map"
                [attr.menuItemId]="processSideMenuKey"
                menusystemitem
            >
                <app-process-editor
                    [readOnly]="true"
                    [showFilter]="false"
                    [cerrixTab]="tab"
                    #processEditor
                ></app-process-editor>
            </div>
            <div
                menuItemId="menuLinks"
                name="Links"
                menuicon="fas fa-link"
                menusystemsubmenu
                *ngIf="isStoredAssessment"
            >
                <div
                    [attr.menuItemId]="documentsSideMenuKey"
                    name="Business Dimension Documents"
                    menuicon="far fa-file"
                    menusystemitem
                >
                    <div *ngIf="permissions">
                        <business-documents
                            [requestModel]="requestModel"
                            [permissions]="permissions"
                            [cerrixTab]="tab"
                            #businesskDocumentsComponent
                        ></business-documents>
                    </div>
                </div>
                <div
                    *ngIf="requestModel.businessID && requestModel.organizationID"
                    [attr.menuItemId]="assessmentDocumentsSideMenuKey"
                    name="Assessment Documents"
                    menuicon="far fa-file"
                    menusystemitem
                >
                    <div *ngIf="permissions">
                        <business-assessment-documents
                            [requestModel]="requestModel"
                            [permissions]="permissions"
                            [cerrixTab]="tab"
                            #businessAssessmentDocumentsComponent
                        ></business-assessment-documents>
                    </div>
                </div>
                <div
                    name="Hyperlinks"
                    [attr.menuItemID]="hyperlinksSideMenuKey"
                    menuicon="fas fa-external-link-alt"
                    menusystemitem
                >
                    <business-hyperlinks
                        [requestModel]="requestModel"
                        [permissions]="permissions"
                        [cerrixTab]="tab"
                        #businessHyperlinksComponent
                    ></business-hyperlinks>
                </div>
            </div>

            <div
                [attr.menuItemId]="historySideMenuKey"
                name="History"
                menuicon="fas fa-history"
                menusystemitem
                *ngIf="isStoredAssessment"
            >
                <app-history-overview
                    [historyDataService]="dataService"
                    [Id]="requestModel.businessAssessmentID"
                    #historyOverviewComponent
                >
                </app-history-overview>
            </div>

            <div
                name="Options"
                menuicon="fas fa-check-double"
                menusystemitem
                *ngIf="isStoredAssessment"
            >
                <detail-options
                    moduleName="Business assessment"
                    objectName="business assessment"
                    customText="This is a permanent delete. No restore possible. Are you sure you want to continue?"
                    [allowDelete]="true"
                    (onDelete)="delete()"
                >
                </detail-options>
            </div>
        </div>
    </div>
</ng-container>
