<div #container class="container-with-fancy-loader">
    <div *ngIf="!finishedLoading">
        <div class="fancy-loader"></div>
    </div>
    <div class="card" *ngIf="finishedLoading">
        <div class="card-body row pt-0 pb-0" [formGroup]="mainFormGroup">
            <div class="col-xl-{{ showAllFields ? '6' : '12' }}">
                <div class="row">
                    <div class="col-lg-6 mt-1 mb-1">
                        <div class="row">
                            <label class="col col-form-label font-weight-bold"
                                >Step description</label
                            >
                        </div>
                        <div class="row">
                            <div class="col">
                                <textarea
                                    class="form-control form-control-sm"
                                    style="min-height: 177px"
                                    readonly
                                    inputTooltip
                                    >{{ evidenceItem.description }}</textarea
                                >
                            </div>
                        </div>
                        <div class="row">
                            <label class="col col-form-label font-weight-bold"
                                >Required documents</label
                            >
                        </div>
                        <div class="row">
                            <div class="col">
                                <textarea
                                    class="form-control form-control-sm"
                                    style="min-height: 63px; height: initial"
                                    readonly
                                    inputTooltip
                                    >{{ evidenceItem.requiredDocuments }}</textarea
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-1 mb-1">
                        <div class="row">
                            <label class="col col-form-label font-weight-bold">Evidence</label>
                        </div>
                        <div class="row">
                            <div class="col" style="min-height: 150px">
                                <div
                                    class="text-center"
                                    *ngIf="
                                        evidenceItem.documents &&
                                        documentsDisabled &&
                                        !evidenceItem.documents.length
                                    "
                                >
                                    <span>No evidence documents</span>
                                </div>
                                <document-manager-old
                                    [Documents]="evidenceItem.documents"
                                    [disabled]="documentsDisabled"
                                    [AllowAdd]="workflowStepPermission.evidenceSaveAllowed"
                                    [AllowEdit]="workflowStepPermission.evidenceSaveAllowed"
                                    [AllowDelete]="workflowStepPermission.evidenceSaveAllowed"
                                    [permissions]="evidenceDocumentPermissions"
                                    [hideLabel]="true"
                                    [hideAddButton]="documentsDisabled"
                                    [noMargin]="true"
                                    headerHeight="38px"
                                    (DocumentsChange)="onDocumentsChange($event)"
                                    [DocumentTypeTargets]="documentTypeTargets"
                                    [compactMode]="true"
                                    *ngIf="
                                        evidenceItem.documents &&
                                        (!documentsDisabled || evidenceItem.documents.length)
                                    "
                                ></document-manager-old>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showAllFields" class="col-xl-6">
                <div class="row">
                    <div class="col">
                        <div class="row mt-1">
                            <label class="col-lg col-form-label font-weight-bold"
                                >Test step score</label
                            >
                            <div class="col-lg-9">
                                <ng-select
                                    formControlName="score"
                                    [items]="scores"
                                    bindLabel="label"
                                    bindValue="value"
                                    [searchable]="false"
                                    [clearable]="false"
                                    dropdownPosition="bottom"
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <div
                                            [style.background-color]="item.color"
                                            style="
                                                min-width: 5px;
                                                min-height: 16px;
                                                float: left;
                                                margin-right: 10px;
                                            "
                                        ></div>
                                        {{ item.label }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col d-none d-lg-block"></div>
                </div>
                <div class="row">
                    <div class="col-lg">
                        <div class="row">
                            <label class="col col-form-label font-weight-bold"
                                >Comment tester</label
                            >
                        </div>
                        <div class="row">
                            <div class="col">
                                <cerrix-textarea
                                    errorLabel="Comment tester"
                                    formControlName="commentTester"
                                    style="min-height: 240px"
                                ></cerrix-textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg mb-1" [hidden]="!useReviewer">
                        <div class="row d-none d-lg-block"></div>
                        <div class="row">
                            <label class="col col-form-label font-weight-bold"
                                >Comment reviewer</label
                            >
                        </div>
                        <div class="row">
                            <div class="col">
                                <cerrix-textarea
                                    errorLabel="Comment reviewer"
                                    formControlName="commentReviewer"
                                    style="min-height: 240px"
                                ></cerrix-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
