<div class="cerrix-documents-list">
    <cerrix-drop-area *ngIf="canAdd" (onFileSelection)="onFileSelection($event)"></cerrix-drop-area>

    <div class="documents-list" *ngIf="!!documents">
        <div class="document-item" *ngFor="let document of documents">
            <div class="document-text">
                {{ document.name }}
            </div>
            <div class="document-actions">
                <cerrix-icon-button
                    *ngIf="document.name.toLowerCase().endsWith('.pdf')"
                    color="accent-link"
                    size="small"
                    (click)="onOpenPdfDocument(document)"
                >
                    <mat-icon fontSet="material-symbols-outlined">find_in_page</mat-icon>
                </cerrix-icon-button>
                <cerrix-icon-button
                    *ngIf="canEdit"
                    color="accent-link"
                    size="small"
                    (click)="onEditDocument(document)"
                >
                    <mat-icon fontSet="material-symbols-outlined">edit_square_outlined</mat-icon>
                </cerrix-icon-button>
                <cerrix-icon-button
                    color="accent-link"
                    size="small"
                    (click)="onFileDownload(document)"
                >
                    <mat-icon fontSet="material-symbols-outlined">cloud_download</mat-icon>
                </cerrix-icon-button>
                <cerrix-confirmation-button
                    *ngIf="canDelete"
                    btnLabel="Delete?"
                    (onConfirmationClick)="onRemoveFile(document)"
                >
                </cerrix-confirmation-button>
            </div>
        </div>
    </div>
</div>
