import { KriDataGraphOverviewComponent } from "./shared/pages/kri-data-graph-overview/kri-data-graph-overview.component";
import { KriDataGraphComponent } from "./shared/pages/kri-data-graph/kri-data-graph.component";
import { NgModule, ModuleWithProviders } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import {
    KriDetailComponent,
    KriDetailMainComponent,
    KriDataComponent,
    KriOverviewComponent,
    KriDataService,
} from ".";
import { KriDataUploadComponent } from "./kri-data-upload/kri-data-upload.component";
import { KriRiskComponent } from "./kri-risk/kri-risk.component";

@NgModule({
    declarations: [
        KriDetailComponent,
        KriDetailMainComponent,
        KriDataComponent,
        KriOverviewComponent,
        KriDataUploadComponent,
        KriRiskComponent,
    ],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class KriModule {
    static forRoot(): ModuleWithProviders<KriModule> {
        return {
            ngModule: KriModule,
            providers: [KriDataService],
        };
    }
}
