<div class="modal-dialog" [class.text-center]="data.centerText" [attr.data-uiid]="data.promptUiid">
    <div class="modal-content">
        <div class="modal-header" *ngIf="data.title">
            <h5 class="modal-title">{{ data.title }}</h5>
        </div>
        <div class="modal-body" [class.p-0]="data.hideBodyPadding">
            <p *ngIf="data.message">{{ data.message }}</p>
            <div class="fancy-loader submodule" *ngIf="data.showLoader"></div>
            <div class="fields" *ngIf="data.fields && data.fields.length > 0">
                <div class="cerrix-control-sm container-fluid">
                    <div class="row">
                        <div
                            class="form-group col-{{
                                field.editorWidth ? field.editorWidth : '12'
                            }}"
                            *ngFor="let field of data.fields"
                        >
                            <generic-field-editor
                                *ngIf="!field.hideInEdit"
                                [row]="data.fieldResult"
                                [field]="field"
                                [editEnabled]="!field.isReadonly"
                            ></generic-field-editor>
                        </div>
                    </div>
                </div>
            </div>
            <div
                [attr.data-uiid]="data.customTemplateDataUiid"
                class="custom-template"
                *ngIf="data.customTemplate"
            >
                <ng-container
                    *ngTemplateOutlet="data.customTemplate; context: data.customTemplateContext"
                ></ng-container>
            </div>
        </div>
        <div class="modal-footer" *ngIf="data.showButtonBar">
            <div class="left-area" *ngIf="data.extraButtons">
                <button
                    *ngFor="let btn of data.extraButtons"
                    [attr.data-uiid]="btn.uiid"
                    type="button"
                    [ngClass]="btn.class"
                    (click)="btn.action()"
                    [hidden]="!btn.show"
                >
                    <cerrix-icon-viewer
                        [icon]="btn.icon"
                        [defaultColor]="btn.iconColor"
                        *ngIf="btn.icon"
                    ></cerrix-icon-viewer>
                    <span class="d-none d-lg-inline ms-2">{{ btn.text }}</span>
                </button>
            </div>
            <div class="right-area">
                <button
                    type="button"
                    [ngClass]="data.cancelButton.class"
                    (click)="cancelClick()"
                    *ngIf="data.cancelButton.show"
                >
                    <cerrix-icon-viewer
                        [icon]="data.cancelButton.icon"
                        defaultColor="white"
                        *ngIf="data.cancelButton.icon"
                    ></cerrix-icon-viewer>
                    <span class="d-none d-lg-inline ms-2">{{ data.cancelButton.text }}</span>
                </button>
                <button
                    [attr.data-uiid]="data.confirmButton.uiid"
                    type="button"
                    [ngClass]="data.confirmButton.class"
                    (click)="confirmClick()"
                    *ngIf="data.confirmButton.show"
                >
                    <cerrix-icon-viewer
                        [icon]="data.confirmButton.icon"
                        defaultColor="white"
                        *ngIf="data.confirmButton.icon"
                    ></cerrix-icon-viewer>
                    <span class="d-none d-lg-inline ms-2">{{ data.confirmButton.text }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div [class]="ngSelectHolderCls"></div>
