import { Pages } from "@constants/pages/Pages";
import { ModuleType } from "@enums/ModuleType";
import { GetIconByModuleString } from "@methods/Icons";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { DashboardQuickLinkModel } from "../../../shared/models/DashboardQuickLinkModel";
import { ILinkWidgetGeneratorType } from "./../../../shared/interfaces/ILinkWidgetGeneratorType";
import { ModuleSubType } from "@enums/ModuleSubType";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";
import { MicroserviceModule } from "@enums/microservice-module.enum";

export class ModuleOverviewLinkGenerator implements ILinkWidgetGeneratorType {
    constructor(private microservicePermissionService: MicroservicePermissionService) {}

    getTypes(permissions: ApplicationPermissionsModel, pages: Pages): DashboardQuickLinkModel[] {
        const quickLinks = [];
        if (permissions.HasRiskAccess) {
            quickLinks.push({
                title: ModuleType.ORMDescription.toString(),
                icon: GetIconByModuleString(ModuleType.ORMDescription),
                page: pages.RiskOverview,

                description: "Workspace for all risks you have access to.",
            });
        }

        if (permissions.HasControlAccess) {
            quickLinks.push({
                title: ModuleType.ControlsDescription.toString(),
                icon: GetIconByModuleString(ModuleType.ControlsDescription),
                page: pages.ControlOverview,

                description: "Workspace for all controls you have access to.",
            });
        }

        if (permissions.HasAdvancedTestingAccess) {
            quickLinks.push({
                title: ModuleSubType.AdvEffTestingDescription.toString(),
                icon: GetIconByModuleString(ModuleSubType.AdvEffTestingDescription),
                page: pages.ControlsTestingAdvEffOverview,

                description: "Workspace for all effectiveness test plans you have access to.",
            });
        }

        if (permissions.HasTaskAccess) {
            quickLinks.push({
                title: ModuleType.TasksDescription.toString(),
                icon: GetIconByModuleString(ModuleType.TasksDescription),
                page: pages.TaskOverview,

                description: "Workspace for all tasks you have access to.",
            });
        }

        if (permissions.HasEventAccess) {
            quickLinks.push({
                title: ModuleType.LERDescription.toString(),
                icon: GetIconByModuleString(ModuleType.LERDescription),
                page: pages.EventOverview,

                description: "Workspace for all events you have access to.",
            });
        }

        if (
            permissions.HasIncidentAccess &&
            this.microservicePermissionService.hasModuleAccess(MicroserviceModule.Incidents)
        ) {
            quickLinks.push({
                title: ModuleType.LERIncidentDescription.toString(),
                icon: GetIconByModuleString(ModuleType.LERIncidentDescription),
                page: pages.IncidentOverview,

                description: "Workspace for all incidents you have access to.",
            });
        }

        if (permissions.HasFindingReportAccess) {
            quickLinks.push({
                title: ModuleType.FRDescription.toString(),
                icon: GetIconByModuleString(ModuleType.FRDescription),
                page: pages.ImOverview,

                description: "Workspace for all finding reports you have access to.",
            });
        }

        if (permissions.HasMoiAccess) {
            quickLinks.push({
                title: "MoIs", // ModuleType.BIMDescription,
                icon: GetIconByModuleString(ModuleType.BIMDescription),
                page: pages.MoiOverview,

                description: "Workspace for all mois you have access to.",
            });
        }

        if (permissions.HasKriAccess) {
            quickLinks.push({
                title: ModuleType.KRIDescription.toString(),
                icon: GetIconByModuleString(ModuleType.KRIDescription),
                page: pages.KriOverview,

                description: "Workspace for all kri-/kpis you have access to.",
            });
        }

        if (permissions.HasDataManagementAccess) {
            quickLinks.push({
                title: ModuleType.DMDescription.toString(),
                icon: GetIconByModuleString(ModuleType.DMDescription),
                page: pages.DataManagementOverview,

                description: "Workspace for all processing purposes you have access to.",
            });
        }

        if (permissions.HasThirdPartyAccess) {
            quickLinks.push({
                title: ModuleType.TPDescription.toString(),
                icon: GetIconByModuleString(ModuleType.TPDescription),
                page: pages.Thirdparty,

                description: "Workspace for all third parties you have access to.",
            });
        }

        if (permissions.Forms.HasAccess) {
            quickLinks.push({
                title: ModuleType.FRMDescription.toString(),
                icon: GetIconByModuleString(ModuleType.FRMDescription),
                page: pages.Forms,

                description: "Workspace for all forms you have access to.",
            });
        }

        return quickLinks;
    }
}
