import { Component } from "@angular/core";
import { ManualPageType } from "@app/manual/models/manual-page-type";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { nameof } from "@methods/jeffs-toolkit";
import { TabService } from "@services/tabs/TabService";
import {
    ApiKeyModel,
    ApiKeySelectionModel,
    ApiKeyWhitelistAddress,
} from "./../../models/api-key.model";

@Component({
    selector: "api-key-detail",
    templateUrl: "./api-key-detail.component.html",
    styleUrls: ["./api-key-detail.component.scss"],
})
export class ApiKeyDetailComponent {
    model: ApiKeyModel;
    readonly: boolean = true;

    softIpRequestedConfig: GenericListConfig;
    softIpAllowedConfig: GenericListConfig;
    softIpDeniedConfig: GenericListConfig;

    minDate: Date;
    maxDate: Date;

    constructor(private _tabService: TabService, private _promptService: CerrixPromptService) {
        this.minDate = new Date();
        this.maxDate = new Date();
        this.maxDate.setUTCFullYear(this.maxDate.getUTCFullYear() + 1);
    }

    async open(m: ApiKeyModel, readonly: boolean): Promise<void> {
        this.model = m;
        this.readonly = readonly;

        this.softIpRequestedConfig = undefined;
        this.softIpAllowedConfig = undefined;
        this.softIpDeniedConfig = undefined;

        await new Promise((f) => setTimeout(f, 1)); //Allow UI thread to update first

        if (this.model.whitelist.enabled) {
            this.initWhitelists();
        }
    }

    openManual(apiKey: ApiKeySelectionModel, moduleKey: string): void {
        const pages = ManualPageType;
        const pageKeys = Object.keys(pages);

        let pageName = (moduleKey + "-apis/" + apiKey.name).toLowerCase();
        pageName = this.replace(pageName, " ", "-");
        pageName = this.replace(pageName, ",", "");

        const apiPageKey = pageKeys.find((x) => pages[x].page == pageName);

        if (apiPageKey) {
            this._tabService.openManual(pages[apiPageKey]);
        }
    }

    private replace(value: string, from: string, to: string): string {
        while (value.indexOf(from) >= 0) {
            value = value.replace(from, to);
        }

        return value;
    }

    initWhitelists(): void {
        this.initSoftIpRequestedConfig();
        this.initAllowedIpsConfig();
        this.initDeniedIpConfig();
    }

    private initSoftIpRequestedConfig(): void {
        this.softIpRequestedConfig = <GenericListConfig>{
            name: "Requested IPs",
            idProp: nameof<ApiKeyWhitelistAddress>((x) => x.id),
            isSortable: false,

            allowAdd: false,
            allowEdit: false,
            allowDelete: !this.readonly,

            fields: this.GetFields(),

            data: this.model.whitelist.requested,
        };

        if (!this.readonly) {
            this.softIpRequestedConfig.dataChanged = (data: ApiKeyWhitelistAddress[]) => {
                this.model.whitelist.requested = data;
            };
            this.softIpRequestedConfig.overviewRowActionsName = "Actions";
            this.softIpRequestedConfig.overviewRowActions = [
                {
                    icon: "fas fa-check",
                    tooltip: "Allow",
                    clickEvent: (row: ApiKeyWhitelistAddress) => {
                        this.model.whitelist.allowed.push(row);

                        this.model.whitelist.requested.forEach((item, index) => {
                            if (item.id == row.id) {
                                this.model.whitelist.requested.splice(index, 1);
                            }
                        });

                        this.refreshTables();
                    },
                },
                {
                    icon: "fas fa-times",
                    tooltip: "Deny",
                    clickEvent: (row: ApiKeyWhitelistAddress) => {
                        this.model.whitelist.denied.push(row);

                        this.model.whitelist.requested.forEach((item, index) => {
                            if (item.id == row.id) {
                                this.model.whitelist.requested.splice(index, 1);
                            }
                        });

                        this.refreshTables();

                        this.showDenyModal();
                    },
                },
            ];
        }
    }

    private initAllowedIpsConfig(): void {
        this.softIpAllowedConfig = <GenericListConfig>{
            name: "Allowed IPs",
            idProp: nameof<ApiKeyWhitelistAddress>((x) => x.id),
            isSortable: false,

            allowAdd: !this.readonly,
            allowEdit: !this.readonly,
            allowDelete: !this.readonly,

            fields: this.GetFields(),

            data: this.model.whitelist.allowed,
        };

        if (!this.readonly) {
            this.softIpAllowedConfig.dataChanged = (data: ApiKeyWhitelistAddress[]) => {
                this.model.whitelist.allowed = data;
            };
        }
    }

    private initDeniedIpConfig(): void {
        this.softIpDeniedConfig = <GenericListConfig>{
            name: "Denied IPs",
            idProp: nameof<ApiKeyWhitelistAddress>((x) => x.id),
            isSortable: false,

            allowAdd: !this.readonly,
            allowEdit: !this.readonly,
            allowDelete: !this.readonly,

            fields: this.GetFields(),

            data: this.model.whitelist.denied,
        };

        if (!this.readonly) {
            this.softIpDeniedConfig.dataChanged = (data: ApiKeyWhitelistAddress[]) => {
                this.model.whitelist.denied = data;
            };
        }
    }

    private GetFields(): GenericListField[] {
        return <GenericListField[]>[
            {
                fieldName: nameof<ApiKeyWhitelistAddress>((wla) => wla.ipAddress),
                prettyName: "IP Address",
                fieldType: GenericListFieldType.Text,
                description:
                    "Allows single IP Address (192.168.0.1), CIDR (192.168.0.0/24) & ranges (192.168.0.0-192.168.0.255)",
                required: true,
                customValidation: (row: ApiKeyWhitelistAddress) => {
                    let validationResult = null;

                    const ipv4Regex =
                        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(\/(3[0-2]|[0-2]?[0-9]))|(-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))?$/;
                    const ipv6Regex =
                        /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?:(\/(12[0-8]|1[0-1][0-9]|0?[0-9][0-9]?))|(-(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))))?$/;

                    if (!ipv4Regex.test(row.ipAddress) && !ipv6Regex.test(row.ipAddress)) {
                        validationResult = "Invalid IP Address";
                    }

                    return {
                        validation: validationResult,
                        makeRequired: true,
                    };
                },
            },
            {
                fieldName: nameof<ApiKeyWhitelistAddress>((wla) => wla.description),
                prettyName: "Description",
                fieldType: GenericListFieldType.Text,
            },
            {
                fieldName: nameof<ApiKeyWhitelistAddress>((wla) => wla.added),
                prettyName: "Added",
                fieldType: GenericListFieldType.DateTime,
                hideInEdit: true,
            },
            {
                fieldName: nameof<ApiKeyWhitelistAddress>((wla) => wla.lastUsed),
                prettyName: "Last Used",
                fieldType: GenericListFieldType.DateTime,
                hideInEdit: true,
            },
        ];
    }

    private refreshTables(): void {
        this.softIpRequestedConfig.reload();
        this.softIpAllowedConfig.reload();
        this.softIpDeniedConfig.reload();
    }

    private showDenyModal(): void {
        this._promptService.alert({
            maxHeight: "220px",
            maxWidth: "600px",
            data: {
                title: "IP Address moved to Denied",
                message:
                    "If this IP Address is unknown, it is recommended to revoke this API." +
                    "\nThe credentials linked to this API might have leaked.",
                closeOnEsc: false,
                confirmOnEnter: false,
                centerText: true,
            },
        });
    }
}
