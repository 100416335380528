import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "kri-get-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class KriGetDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get KRI/KPI Data",
            httpMethod: "GET",
            path: "/kri/data/list",

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        Kri: [
                            {
                                Identfier: "string",
                                Name: "string",
                                Organizations: "string[]",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
