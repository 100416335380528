<div class="detail-page-card">
    <div class="detail-page-card-title">Code executions</div>
    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <cerrix-checkbox label="Perform as testrun" [(value)]="testrunSelected"></cerrix-checkbox>
        <small class="text-muted ms-2">(rollback on the end)</small>
        <div class="row mt-2">
            <div class="col">
                <ng-select
                    id="executableSelect"
                    name="executableSelect"
                    [items]="executables"
                    [(ngModel)]="selectedExecutable"
                    bindLabel="Value"
                    bindValue="Key"
                    [closeOnSelect]="true"
                    [multiple]="false"
                    [clearable]="false"
                    [loading]="!executables"
                >
                </ng-select>
            </div>
            <button class="btn btn-light btn-sm" (click)="execute()">Execute</button>
        </div>

        <div *ngIf="executionMessage">
            <hr />
            <pre>{{ executionMessage }}</pre>
        </div>
    </div>
</div>
