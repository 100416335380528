import { Component } from "@angular/core";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { BaseWidget } from "../BaseWidget";

@Component({
    selector: "text-widget",
    templateUrl: "./text-widget.component.html",
    styleUrls: ["./text-widget.component.scss"],
})
export class TextWidgetComponent extends BaseWidget {
    configuredText = "";

    constructor() {
        super();
    }

    load() {
        this.configuredText = this.getFilter(WidgetFilterKey.TextWidget.Text);
        super.load();
    }
}
