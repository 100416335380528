<cerrix-loader [cerrixTab]="tab" [showLoader]="!finishedLoading"></cerrix-loader>

<div *ngIf="finishedLoading && detailsModel">
    <div class="detail-page-action-bar flex-action-bar" *ngIf="!hideActionBar">
        <div>
            <button class="btn btn-cerrix" [disabled]="!this.saveAllowed" (click)="save()">
                <i class="fas fa-save"></i>
                <span data-uiid="fr-assessment-save" class="d-none d-lg-inline">Save</span>
            </button>

            <span class="date-edited" *ngIf="detailsModel.modifiedAt">
                Last save: {{ detailsModel.modifiedAt | dateTimeFormat }}
            </span>
        </div>
    </div>

    <div menuSystem [cerrixTab]="tab" class="assessment" (change)="onMenuItemClick($event)">
        <div name="Assessment" menuicon="fas fa-poll-people" menusystemsubmenu>
            <div menuItemId="details" name="Details" menusystemitem menuicon="fas fa-info-circle">
                <assessment-details-main
                    [formGroup]="formGroup"
                    [detailsModel]="detailsModel"
                    [editAllowed]="saveAllowed"
                >
                </assessment-details-main>
            </div>
            <div name="Links" menusystemsubmenu menuicon="fas fa-link" *ngIf="detailsModel.guid">
                <div
                    menuItemId="mois"
                    name="Measure of Improvements"
                    menusystemitem
                    menuicon="fas fa-tasks"
                >
                    <linked-moi-overview
                        [moiType]="moiType"
                        [parentGuid]="detailsModel.findingReportGuid"
                        [assessmentGuid]="detailsModel.guid"
                        [canAdd]="permissionsModel.canAddMoi"
                        [canDelete]="permissionsModel.canDeleteMoi"
                        [useLazyLoading]="false"
                    >
                    </linked-moi-overview>
                </div>
            </div>
            <div
                menuItemId="history"
                name="History"
                menusystemitem
                menuicon="fas fa-history"
                *ngIf="detailsModel.guid"
            >
                <app-history-overview
                    [historyDataService]="assessmentService"
                    [Id]="detailsModel.guid"
                    #historyOverviewComponent
                >
                </app-history-overview>
            </div>
        </div>
    </div>
</div>
