declare var mxBpmnShape: any;

export function initCerrixShapes(mx) {
    /* ******************************************************************** */

    /*
        ManualOperation
    */
    function ManualOperationShape() {}

    ManualOperationShape.prototype = new mx.mxCylinder();
    ManualOperationShape.prototype.constructor = ManualOperationShape;

    ManualOperationShape.prototype.extrude = 10;

    /*
                       _________
         Background:   \       /
                        -------
    */
    ManualOperationShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dx = (w / 100) * this.extrude;

        path.moveTo(0, 0);
        path.lineTo(dx, h);
        path.lineTo(w - dx, h);
        path.lineTo(w, 0);
        path.close();
    };

    mx.mxCellRenderer.defaultShapes["manualoperation"] = ManualOperationShape;

    /* ******************************************************************** */

    /*
        ManualOperation
    */
    function RoundedRectangleShape() {}

    RoundedRectangleShape.prototype = new mx.mxRectangleShape();
    RoundedRectangleShape.prototype.constructor = RoundedRectangleShape;

    RoundedRectangleShape.prototype.paintBackground = function (c, x, y, w, h) {
        this.isRounded = true;
        mx.mxRectangleShape.prototype.paintBackground.apply(this, arguments);
    };

    mx.mxCellRenderer.defaultShapes["roundedRectangle"] = RoundedRectangleShape;

    /* ******************************************************************** */

    /*
        Manual input
    */
    function ManualInputShape() {}

    ManualInputShape.prototype = new mx.mxCylinder();
    ManualInputShape.prototype.constructor = ManualInputShape;

    ManualInputShape.prototype.extrude = 20;

    /*
                       /------|
         Background:   |      |
                        -------
    */
    ManualInputShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dy = (w / 100) * this.extrude;

        path.moveTo(0, dy);
        path.lineTo(0, h);
        path.lineTo(w, h);
        path.lineTo(w, 0);
        path.close();
    };

    mx.mxCellRenderer.defaultShapes["manualinput"] = ManualInputShape;

    /* ******************************************************************** */

    /*
        Preparation
    */
    function PreparationShape() {}

    PreparationShape.prototype = new mx.mxCylinder();
    PreparationShape.prototype.constructor = PreparationShape;

    PreparationShape.prototype.extrude = 10;

    /*
                        -------
                       /       \
         Background:   \       /
                        -------
    */
    PreparationShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dx = (w / 100) * this.extrude;

        path.moveTo(dx, 0);
        path.lineTo(0, h / 2);
        path.lineTo(dx, h);
        path.lineTo(w - dx, h);
        path.lineTo(w, h / 2);
        path.lineTo(w - dx, 0);
        path.close();
    };

    mx.mxCellRenderer.defaultShapes["preparation"] = PreparationShape;

    /* ******************************************************************** */

    /*
        Preparation
    */
    function ProcessShape() {}

    ProcessShape.prototype = new mx.mxCylinder();
    ProcessShape.prototype.constructor = ProcessShape;

    /*
                        -------
                       /       \
         Background:   \       /
                        -------
    */
    ProcessShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        path.moveTo(0, 0);
        path.lineTo(0, h);
        path.lineTo(w, h);
        path.lineTo(w, 0);
        path.close();
    };

    mx.mxCellRenderer.defaultShapes["process"] = ProcessShape;

    /* ******************************************************************** */

    /*
        Preparation
    */
    function DecisionShape() {}

    DecisionShape.prototype = new mx.mxRhombus();
    DecisionShape.prototype.constructor = DecisionShape;

    mx.mxCellRenderer.defaultShapes["decision"] = DecisionShape;

    /* ******************************************************************** */

    /*
        Preparation
    */
    function OnPageReferenceShape() {}

    OnPageReferenceShape.prototype = new mx.mxEllipse();
    OnPageReferenceShape.prototype.constructor = OnPageReferenceShape;

    mx.mxCellRenderer.defaultShapes["onpagereference"] = OnPageReferenceShape;

    /* ******************************************************************** */

    /*
        Predefined process
    */
    function PredefinedProcessShape() {}

    PredefinedProcessShape.prototype = new mx.mxCylinder();
    PredefinedProcessShape.prototype.constructor = PredefinedProcessShape;

    PredefinedProcessShape.prototype.extrude = 10;

    PredefinedProcessShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dx = (w / 100) * this.extrude;

        if (isForeground) {
            path.moveTo(dx, 0);
            path.lineTo(dx, h);

            path.moveTo(w - dx, 0);
            path.lineTo(w - dx, h);

            path.end();
        } else {
            path.moveTo(0, 0);
            path.lineTo(0, h);
            path.lineTo(w, h);
            path.lineTo(w, 0);
            path.close();
        }
    };

    mx.mxCellRenderer.defaultShapes["predefinedprocess"] = PredefinedProcessShape;

    /* ******************************************************************** */

    /*
        Terminator
    */
    function TerminatorShape() {}

    TerminatorShape.prototype = new mx.mxCylinder();
    TerminatorShape.prototype.constructor = TerminatorShape;

    TerminatorShape.prototype.extrude = 10;

    TerminatorShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dx = (w / 100) * this.extrude;

        path.moveTo(dx, 0);
        path.curveTo(dx, 0, -dx, h / 2, dx, h);
        path.lineTo(w - dx, h);
        path.curveTo(w - dx, h, w + dx, h / 2, w - dx, 0);

        path.close();
    };

    mx.mxCellRenderer.defaultShapes["terminator"] = TerminatorShape;

    /* ******************************************************************** */

    /*
        Off Page reference
    */
    function OffPageReferenceShape() {}

    OffPageReferenceShape.prototype = new mx.mxCylinder();
    OffPageReferenceShape.prototype.constructor = OffPageReferenceShape;

    OffPageReferenceShape.prototype.extrude = 40;

    OffPageReferenceShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dy = (h / 100) * this.extrude;

        path.moveTo(0, 0);
        path.lineTo(0, h - dy);
        path.lineTo(w / 2, h);
        path.lineTo(w, h - dy);
        path.lineTo(w, 0);

        path.close();
    };

    mx.mxCellRenderer.defaultShapes["offpagereference"] = OffPageReferenceShape;

    /* ******************************************************************** */

    /*
        Document
    */
    function DocumentShape() {}

    DocumentShape.prototype = new mx.mxCylinder();
    DocumentShape.prototype.constructor = DocumentShape;

    DocumentShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        path.moveTo(0, 0);
        path.lineTo(0, h * 0.725);
        path.curveTo(w * 0.2, h * 1.3, w - w / 4, h * 0.375, w, h * 0.675);
        path.lineTo(w, 0);
        path.close();
    };

    mx.mxCellRenderer.defaultShapes["document"] = DocumentShape;

    /* ******************************************************************** */

    /*
        Risk
    */
    function RiskShape() {}

    RiskShape.prototype = new mx.mxCylinder();
    RiskShape.prototype.constructor = RiskShape;
    //         p2
    //        /  \
    //       / || \
    //      /  ||  \
    //     /        \
    //    /    []    \
    // p1/____________\p3
    //
    RiskShape.prototype.redrawPath = function (path, x, y, w, h, isForeground) {
        const dx = w / 2;
        path.moveTo(0, h); // p1
        path.lineTo(dx, 0); // 2
        path.lineTo(w, h); // p3
        path.lineTo(0, h); // p1
        path.close();

        // draw two rectangles for exclamation mark
        let dy = (h / 100) * 40;
        let dh = (h / 100) * 30;
        path.moveTo(dx, dy);
        path.lineTo(dx, dy + dh);

        dy = h - (h / 100) * 25;
        dh = (h / 100) * 10;
        path.moveTo(dx, dy);
        path.lineTo(dx, dy + dh);
        path.close();
    };

    RiskShape.prototype.getLabelBounds = function (rect) {
        const padding = rect.height / 2 + 15 * this.scale;
        return new mx.mxRectangle(rect.x, rect.y + padding, rect.width, rect.height);
    };

    mx.mxCellRenderer.defaultShapes["risk"] = RiskShape;

    /* ******************************************************************** */

    /*
        Risk control
    */
    function RiskControlShape() {}

    RiskControlShape.prototype = new mx.mxCylinder();
    RiskControlShape.prototype.constructor = RiskControlShape;
    RiskControlShape.prototype.redrawPath = function (canvas, x, y, w, h, isForeground) {
        const ratio = w / 512;

        canvas.setFillColor("#005500");
        this.style[mx.mxConstants.STYLE_ASPECT] = "fixed";

        let lastX = 0,
            lastY = 0;
        const shiftX = (x) => (lastX += x);
        const shiftY = (y) => (lastY += y);
        const setX = (x) => (lastX = x);
        const setY = (y) => (lastY = y);

        canvas.moveTo(setX(466.5 * ratio), setY(83.7 * ratio));
        canvas.lineTo(shiftX(-192 * ratio), shiftY(-80 * ratio));
        canvas.arcTo(
            lastX + 48.15 * ratio,
            lastY + 48.15 * ratio,
            0,
            0,
            0,
            shiftX(-36.9 * ratio),
            lastY
        );
        canvas.lineTo(shiftX(-192 * ratio), shiftY(80 * ratio));
        canvas.curveTo(
            27.7 * ratio,
            91.1 * ratio,
            16 * ratio,
            108.6 * ratio,
            setX(16 * ratio),
            setY(128 * ratio)
        );
        canvas.curveTo(
            lastX,
            lastY + 198.5 * ratio,
            lastX + 114.5 * ratio,
            lastY + 335.7 * ratio,
            shiftX(221.5 * ratio),
            shiftY(380.3 * ratio)
        );
        canvas.curveTo(
            lastX + 11.8 * ratio,
            lastY + 4.9 * ratio,
            lastX + 25.1 * ratio,
            lastY + 4.9 * ratio,
            shiftX(36.9 * ratio),
            lastY
        );
        canvas.curveTo(
            360.1 * ratio,
            472.6 * ratio,
            496 * ratio,
            349.3 * ratio,
            setX(496 * ratio),
            setY(128 * ratio)
        );
        canvas.curveTo(
            lastX,
            lastY + -19.4 * ratio,
            lastX + -11.7 * ratio,
            lastY + -36.9 * ratio,
            shiftX(-29.5 * ratio),
            shiftY(-44.3 * ratio)
        );
        canvas.close();
        canvas.moveTo(setX(256.1 * ratio), setY(446.3 * ratio));
        canvas.lineTo(shiftX(0.1 * ratio), shiftY(-381 * ratio));
        canvas.lineTo(shiftX(175.9 * ratio), shiftY(73.3 * ratio));
        canvas.curveTo(
            lastX + -3.3 * ratio,
            lastY + 151.4 * ratio,
            lastX + -82.1 * ratio,
            lastY + 261.1 * ratio,
            shiftX(-175.8 * ratio),
            shiftY(307.7 * ratio)
        );
        canvas.close();
        canvas.fill();
    };
    RiskControlShape.prototype.strokewidth = 5;
    RiskControlShape.prototype.styleEnabled = true;

    RiskControlShape.prototype.getLabelBounds = function (rect) {
        const padding = rect.height / 2 + 25 * this.scale;
        return new mx.mxRectangle(rect.x, rect.y + padding, rect.width, rect.height);
    };

    mx.mxCellRenderer.defaultShapes["riskcontrol"] = RiskControlShape;

    /* ******************************************************************** */

    /*
        Preparation
    */
    function EndEventShape() {}

    EndEventShape.prototype = new mx.mxEllipse();
    EndEventShape.prototype.strokewidth = 2;
    EndEventShape.prototype.constructor = EndEventShape;

    mx.mxCellRenderer.defaultShapes["endEvent"] = EndEventShape;

    function UnsupportedShape() {}

    UnsupportedShape.prototype = new mx.mxRectangleShape();
    UnsupportedShape.prototype.constructor = UnsupportedShape;
    UnsupportedShape.prototype.fill = "#FF0000";
    UnsupportedShape.prototype.fillOpacity = "100";
    UnsupportedShape.prototype.gradient = undefined;

    UnsupportedShape.prototype.paintBackground = function (c, x, y, w, h) {
        const NOT_IMPORTED_STRING = "NOT IMPORTED\n";
        this.fill = "#FF0000";
        this.fillOpacity = "100";
        this.gradient = undefined;

        const text = this.state.cell.value.getAttribute("label");
        if (!text.startsWith(NOT_IMPORTED_STRING)) {
            this.state.cell.value.setAttribute("label", NOT_IMPORTED_STRING + text);
        }

        mx.mxRectangleShape.prototype.paintBackground.apply(this, arguments);
    };

    UnsupportedShape.prototype.styleEnabled = true;

    const defaultShapes = mx.mxCellRenderer.defaultShapes;
    defaultShapes["unsupported"] = UnsupportedShape;

    // Aliases
    defaultShapes["decision"] = mx.mxRhombus;
    defaultShapes["gateway"] = mx.mxRhombus;
    defaultShapes["data"] = defaultShapes["parallelogram"];
    defaultShapes["onpageReference"] = mx.mxEllipse;
    defaultShapes["database"] = defaultShapes["datastore"];
    defaultShapes["task"] = mx.mxRectangleShape;
    defaultShapes["dataObject"] = defaultShapes["note"];
    defaultShapes["expandedSubProcess"] = mx.mxRectangleShape;
    defaultShapes["startEvent"] = mx.mxEllipse;
    defaultShapes["intermediateEvent"] = mx.mxDoubleEllipse;
    defaultShapes["pool"] = mx.mxSwimlane;
    defaultShapes["group"] = mx.mxRectangleShape;
    defaultShapes["phaseList"] = mx.mxRectangleShape;
    defaultShapes["separator"] = mx.mxRectangleShape;
    defaultShapes["startEnd"] = RoundedRectangleShape;
    defaultShapes["bpmnProcess"] = defaultShapes["ext"];
    defaultShapes["bpmnTransaction"] = defaultShapes["ext"];
    defaultShapes["bpmnEventSubProcess"] = defaultShapes["ext"];
    defaultShapes["bpmnCallActivity"] = defaultShapes["ext"];
    defaultShapes["bpmnConversation"] = defaultShapes["hexagon"];
    defaultShapes["bpmnCallConversation"] = defaultShapes["hexagon"];
} // init
