import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DatamanagementPermissionModel } from "@app/datamanagement/models/DatamanagementPermissionModel";
import { ProcessingPurposeModel } from "@app/datamanagement/models/ProcessingPurposeModel";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { ModuleType } from "@enums/ModuleType";

@Component({
    selector: "datamanagement-controls",
    templateUrl: "./datamanagement-controls.component.html",
    styleUrls: ["./datamanagement-controls.component.scss"],
})
export class DatamanagementControlsComponent {
    @Input() model: ProcessingPurposeModel;
    @Input() disabled = false;
    @Input() permissions: DatamanagementPermissionModel;

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    lastOrganizationId = 0;
    linked: ControlLinkModel[];
    unlinked: ControlLinkModel[];

    constructor(private controlDataService: ControlDataService) {}

    load() {
        if (
            !this.model ||
            !this.model.organizationId ||
            this.lastOrganizationId === this.model.organizationId
        ) {
            return;
        }

        this.isLoading = true;
        this.lastOrganizationId = this.model.organizationId;

        this.controlDataService
            .getLinkableControls(ModuleType.DM, this.model.ID, this.model.organizationId)
            .subscribe((value) => {
                this.loadData(this.model.controlIds, value.sortBy("name", true));
                this.isLoading = false;
            });
    }

    linkedChanged(linked: ControlLinkModel[]) {
        this.model.controlIds = linked.map((x) => x.id);
        this.changed.emit(this.model.controlIds);
    }

    private loadData(linkedIds: number[], items: ControlLinkModel[]) {
        const linked = [],
            unlinked = [];
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
