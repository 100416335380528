<cerrix-loader
    [cerrixTab]="cerrixTab"
    [attr.data-uiid]="module + '-workspace-loader'"
></cerrix-loader>

<div
    class="workspace"
    [attr.data-uiid]="module + '-workspace'"
    [class.menu-indent]="
        cerrixTab.menu && cerrixTab.menu.menuItems.length > 0 && !tabService.sideMenuTucked
    "
>
    <div class="top-bar">
        <div class="search-area">
            <button
                data-uiid="button-addnew"
                class="btn btn-cerrix me-2"
                (click)="onAddClick.emit()"
                *ngIf="permissions.canAdd"
            >
                <i class="fas fa-plus-circle"></i>
                <span class="d-none d-lg-inline">Add new {{ module }}</span>
            </button>
            <input
                data-uiid="input-searchfilter"
                type="text"
                class="form-control quick-search me-2"
                placeholder="Search / filter {{ module }}"
                (debounce-input-event)="this.setActiveData()"
                [(ngModel)]="quickFilter"
                inputTooltip
            />
            <button
                data-uiid="button-advanced-configuration"
                class="btn btn-outline-secondary me-2"
                *ngIf="!useLightWorkspace"
                (click)="advancedConfigComponent.showConfig()"
            >
                <i class="fas fa-filter" aria-hidden="true"></i>
                <span class="d-none d-lg-inline">Advanced configuration</span>
            </button>
            <button
                data-uiid="button-update-preset"
                class="btn btn-outline-secondary"
                *ngIf="
                    activePresetConfig &&
                    (activePresetConfig.isOwner ||
                        (permissions.isModuleAdmin && activePresetConfig.isPublic))
                "
                (click)="workspacePresetManagement.updatePreset()"
            >
                <i class="fas fa-save" aria-hidden="true"></i>
                <span class="d-none d-lg-inline">Update preset</span>
            </button>
        </div>
        <div class="button-area" *ngIf="!useLightWorkspace">
            <div class="btn-group" role="group">
                <button
                    data-uiid="button-workspace-tools"
                    id="workspace-tools"
                    type="button"
                    class="btn btn-outline-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-cog"></i>
                    <span class="d-none d-xl-inline">Workspace Tools</span>
                </button>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="workspace-tools">
                    <!-- Custom buttons -->
                    <div
                        class="dropdown-item"
                        *ngFor="let btnConfig of buttonConfig"
                        [attr.data-uiid]="'button-workspace-tools-' + btnConfig.text"
                        (click)="processBtnConfigClick(btnConfig)"
                        [hidden]="btnConfig.isDisabled"
                        [disabled]="
                            (btnConfig.getVisibleRange &&
                                (!allActiveData || allActiveData.length == 0)) ||
                            (btnConfig.rowRequired && !this.highlightedRowID)
                        "
                        [title]="
                            btnConfig.getVisibleRange &&
                            (!allActiveData || allActiveData.length == 0)
                                ? 'Data required to use this action'
                                : btnConfig.rowRequired && !this.highlightedRowID
                                ? 'Active row required to use this action'
                                : btnConfig.text
                        "
                    >
                        <i
                            class="{{
                                btnConfig.icon ? btnConfig.icon : 'fas fa-hand-pointer-o'
                            }}  fa-fw"
                            aria-hidden="true"
                        ></i>
                        <span class="d-none d-xl-inline ms-2">{{ btnConfig.text }}</span>
                    </div>
                    <div
                        *ngIf="buttonConfig && buttonConfig.length > 0"
                        data-uiid="divider-workspace-tools"
                        class="dropdown-divider m-0"
                    ></div>
                    <!-- Default buttons that are always available -->
                    <div
                        data-uiid="button-workspace-tools-export"
                        class="dropdown-item"
                        (click)="exportData()"
                        [disabled]="
                            isGeneratingExport || !allActiveData || allActiveData.length == 0
                        "
                        title="Export"
                    >
                        <i class="fas fa-circle-notch fa-spin fa-fw" *ngIf="isGeneratingExport"></i>
                        <i
                            class="fas fa-file-excel fa-fw"
                            aria-hidden="true"
                            *ngIf="!isGeneratingExport"
                        ></i>
                        <span class="d-none d-xl-inline ms-2">{{
                            isGeneratingExport ? "Exporting" : "Export"
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="no-data-container"
        data-uiid="container-no-data"
        *ngIf="!allActiveData || allActiveData.length === 0"
    >
        <span class="no-data-text">No data to show.</span>
    </div>

    <div
        class="table-body"
        [ngClass]="{ 'no-overflow': stretchedHeaders }"
        [hidden]="!allActiveData || allActiveData.length == 0"
    >
        <table data-uiid="table-workspace" class="table table-hover table-sm workspace-table">
            <thead data-uiid="table-workspace-headers">
                <tr>
                    <th
                        *ngFor="let header of activeHeaders"
                        [attr.data-uiid]="'tableheader-workspace-' + header"
                        class="sticky"
                        [class.header-with-icon]="header == sortCol"
                        [title]="headerLookup[header]"
                        (click)="updateSorting(header)"
                        [ngStyle]="{ width: headerWidths[header] + 'px' }"
                    >
                        <span>{{ headerLookup[header] }}</span>
                        <i
                            *ngIf="header == sortCol && !isSorting"
                            [attr.data-uiid]="'icon-workspace-' + header + '-sort'"
                            class="fas fa-sort-amount-{{ sortAsc ? 'up' : 'down' }}"
                        ></i>
                        <i
                            *ngIf="header == sortCol && isSorting"
                            [attr.data-uiid]="'icon-workspace-' + header + '-sorting'"
                            class="fas fa-circle-notch fa-spin"
                        ></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let row of activeData"
                    [attr.data-uiid]="'tablerow-workspace-row-' + row[idProp]"
                    [ngClass]="{ highlighted: highlightedRowID == row[idProp] }"
                    (click)="rowClicked(row)"
                    (dblclick)="rowDblClicked(row)"
                    (contextmenu)="rowClicked(row)"
                >
                    <td
                        *ngFor="let header of activeHeaders"
                        [attr.data-uiid]="
                            'tabledata-workspace-column-' + header + '-row-' + row[idProp]
                        "
                    >
                        <workspace-cell
                            *ngIf="row[header]"
                            [attr.data-uiid]="
                                'workspacecell-workspace-column-' + header + '-row-' + row[idProp]
                            "
                            [configs]="rendererConfig"
                            [row]="row"
                            [header]="header"
                            [width]="headerWidths[header]"
                            [stretched]="stretchedHeaders"
                        ></workspace-cell>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div data-uiid="div-bottom-bar" class="bottom-bar disable-selection" *ngIf="allActiveData">
        <div class="btn-group dropup workspace-options">
            <button
                data-uiid="button-bottom-bar-reload"
                class="btn btn-outline-secondary"
                title="Get new data."
                (click)="reloadData()"
            >
                <i class="fas fa-sync" aria-hidden="true"></i>
                <span class="d-none d-xl-inline ms-1">Reload</span>
            </button>
            <button
                data-uiid="button-bottom-bar-reset"
                class="btn btn-outline-secondary"
                title="Reset the workspace to default settings."
                (click)="resetWorkspace()"
            >
                <i class="fas fa-undo" aria-hidden="true"></i>
                <span class="d-none d-xl-inline ms-1">Reset</span>
            </button>
            <button
                type="button"
                data-uiid="button-bottom-bar-pagination-amount"
                class="btn btn-outline-secondary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                *ngIf="allActiveData && allActiveData.length > 0"
            >
                {{ paginationAmount }} <span class="d-none d-lg-inline">{{ module }}s</span>
            </button>
            <div class="dropdown-menu">
                <a
                    class="dropdown-item"
                    *ngFor="let option of paginationAmountOptions"
                    [attr.data-uiid]="'button-bottom-bar-pagination-option-' + option"
                    (click)="setPaginationAmount(option)"
                >
                    Show {{ option }} {{ module }}s
                </a>
            </div>
        </div>
        <div
            class="btn-group pagination"
            role="group"
            aria-label="Basic example"
            *ngIf="allActiveData && allActiveData.length > 0"
        >
            <button
                type="button"
                data-uiid="button-bottom-bar-pagination-first"
                class="btn btn-outline-secondary d-none d-md-inline"
                [disabled]="paginationIndex <= 0"
                (click)="setPagination(0)"
            >
                First
            </button>
            <button
                type="button"
                data-uiid="button-bottom-bar-pagination-previous"
                class="btn btn-outline-secondary pagination-action-prev"
                [disabled]="paginationIndex <= 0"
                (click)="setPagination(paginationIndex - 1)"
            >
                Previous
            </button>
            <div class="btn-group dropup" role="group">
                <button
                    id="allPageNumbers"
                    data-uiid="button-bottom-bar-pagination-page-selection"
                    type="button"
                    class="btn btn-outline-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span class="d-xl-none">{{ paginationIndex + 1 }}</span>
                    <span class="d-none d-xl-inline"
                        >Page {{ paginationIndex + 1 }} of {{ totalPages }}</span
                    >
                </button>
                <div
                    class="dropdown-menu dropdown-menu-end all-pages"
                    aria-labelledby="allPageNumbers"
                >
                    <div
                        class="dropdown-item"
                        [ngClass]="{ active: paginationIndex == page }"
                        *ngFor="let page of allPageNumbers"
                        [attr.data-uiid]="'button-bottom-bar-pagination-select-page-' + page"
                        (click)="setPagination(page)"
                    >
                        <span class="d-none d-xl-inline">Page</span> {{ page + 1 }}
                    </div>
                </div>
            </div>
            <button
                data-uiid="button-bottom-bar-pagination-next"
                type="button"
                class="btn btn-outline-secondary pagination-action-next"
                [disabled]="paginationIndex + 1 >= totalPages"
                (click)="setPagination(paginationIndex + 1)"
            >
                Next
            </button>
            <button
                data-uiid="button-bottom-bar-pagination-last"
                type="button"
                class="btn btn-outline-secondary d-none d-md-inline"
                [disabled]="paginationIndex + 1 >= totalPages"
                (click)="setPagination(totalPages - 1)"
            >
                Last
            </button>
        </div>
        <div
            data-uiid="div-bottom-bar-item-counter"
            class="data-info d-none d-xl-block"
            *ngIf="allActiveData && allActiveData.length > 0"
        >
            Showing {{ module }} {{ paginationIndex * paginationAmount + 1 }} -
            {{
                paginationIndex + 1 >= totalPages
                    ? allActiveData.length
                    : paginationIndex * paginationAmount + paginationAmount
            }}
            of {{ allActiveData.length }} {{ module }}s
        </div>
    </div>

    <workspace-advanced-workspace-config
        *ngIf="availableHeaders"
        [tabID]="tabID"
        [moduleType]="moduleType"
        [moduleSubType]="moduleSubType"
        [filterConfig]="filterConfig"
        [activeFilters]="activeFilters"
        [NumberProperties]="NumberProperties"
        [DateProperties]="DateProperties"
        [allHeaders]="availableHeaders"
        [headerLookup]="headerLookup"
        [activeHeaders]="activeHeaders"
        (ApplyClick)="applyPreset($event)"
        #advancedConfigComponent
    >
    </workspace-advanced-workspace-config>

    <workspace-preset-management
        *ngIf="availableHeaders"
        #workspacePresetManagement
        [cerrixTab]="cerrixTab"
        [moduleType]="moduleType"
        [appendToModuleType]="appendToModuleType"
        (selectedPreset)="applyPresetConfig($event)"
        [allHeaders]="availableHeaders"
        [activeColumns]="activeHeaders"
        [activeSearchKeywords]="activeFilters"
        [activePaginationAmount]="paginationAmount"
        [activeSortCol]="sortCol"
        [sortAsc]="sortAsc"
        [permissions]="permissions"
        [usePresetHyperlink]="usePresetHyperlink"
        (presetsReady)="presetRequestChange($event)"
    >
    </workspace-preset-management>
</div>
