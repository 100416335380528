import { Component } from "@angular/core";
import { AbstractEffTestingStatusComponent } from "../abstract-eff-testing-status-component";
import { EffectivenessStatusType, SampleScore, TestStepScore } from "../../../shared/enums";
import { EffTableConfig } from "../../common/eff-table/models/eff-table-config.model";
import { EffTableColumnConfig } from "../../common/eff-table/models/eff-table-column-config.model";
import { EvidenceSampleTestStep } from "../models/evidence-sample-step.model";
import { SampleScoreColors, StepScoreColorEnum } from "../models/score-color.enum";
import { SelectColoredOption } from "../../common/eff-colored-selector/models/SelectColoredOption";
import { FinalConclusion } from "../components/eff-final-conclusion-box/models/final-conclusion.model";
import _ from "underscore";

@Component({
    selector: "eff-testing-test",
    templateUrl: "./eff-testing-test.component.html",
    styleUrls: ["./eff-testing-test.component.scss"],
})
export class EffTestingTestComponent extends AbstractEffTestingStatusComponent {
    protected loadSamples: boolean = true;

    isNonOccurrenceTestPlan = false;

    allEvidenceValid = false;
    sampleScores: SelectColoredOption[] = [];
    stepScores: SelectColoredOption[] = [];

    tableConfig: EffTableConfig = {
        ExpandableRow: true,
        SeparateRows: true,
        DisableRowFunc(item: EvidenceSampleTestStep, items: EvidenceSampleTestStep[]) {
            if (!item.isSample) {
                return false;
            }

            return items.some((item) => !item.isSample && !item.stepScore);
        },
        RowClassFunc(item: EvidenceSampleTestStep) {
            if (item.isSample) {
                return "sample-row";
            }
            return "";
        },
        OnRowClickFunc(item: EvidenceSampleTestStep) {
            if (item.IsExpanded && item.stepScore === 0) {
                setTimeout(() => {
                    item.triggerOpenScore = !item.triggerOpenScore;
                }, 200);
            }
        },
    };

    displayedColumns: Array<EffTableColumnConfig> = [];
    onChangesDebounce = _.debounce(this.onChanges, 400, false);

    onScoreChanged(newScore: number, item: EvidenceSampleTestStep) {
        if (item.isSample) {
            var score = this.sampleScores.find((s) => s.Id == newScore);
            if (score) {
                item.sampleScore = score.Id;
                item.scoreName = score.Label;
            }
        } else {
            var score = this.stepScores.find((s) => s.Id == newScore);
            if (score) {
                item.stepScore = score.Id;
                item.scoreName = score.Label;
            }
        }

        this.onChanges();
    }

    getSampleScoreColor(score: SampleScore) {
        switch (score) {
            case SampleScore.sufficient:
                return SampleScoreColors.Sufficient;
            case SampleScore.inapplicable:
                return SampleScoreColors.Inapplicable;
            case SampleScore.insufficient:
                return SampleScoreColors.Insufficient;
            case SampleScore.noScore:
                return SampleScoreColors.NoScore;
        }
    }

    getStepScoreColor(score: TestStepScore) {
        switch (score) {
            case TestStepScore.correct:
                return StepScoreColorEnum.Correct;
            case TestStepScore.inapplicable:
                return StepScoreColorEnum.Inapplicable;
            case TestStepScore.incorrect:
                return StepScoreColorEnum.Incorrect;
            case TestStepScore.missingEvidence:
                return StepScoreColorEnum.MissingEvidence;
            case TestStepScore.noScore:
                return StepScoreColorEnum.NoScore;
        }
    }

    override onLoad() {
        this.displayedColumns = [
            { PropertyName: "name", DisplayedName: "Name", IsTemplate: true, Width: "40%" },
            {
                PropertyName: "testerComment",
                DisplayedName: this.pageState.isRejected ? "Score tester" : "Comment tester",
                IsTemplate: true,
                Width: "30%",
            },
        ];

        if (this.pageState.isRejected) {
            this.displayedColumns.push({
                PropertyName: "commentReviewer",
                DisplayedName: "Comment reviewer",
                IsTemplate: true,
                Width: "30%",
            });
        } else {
            this.displayedColumns.push({
                PropertyName: "score",
                DisplayedName: "Score",
                IsTemplate: true,
                Width: "30%",
            });
        }

        this.isNonOccurrenceTestPlan =
            this.effectivenessModel.status == EffectivenessStatusType.nonOccurrence;

        this._ds.getSampleScores().subscribe((scores) => {
            this.sampleScores = scores.map((s) => {
                return {
                    Id: s.value,
                    Label:
                        s.value == 0
                            ? "add a score"
                            : s.value == SampleScore.inapplicable
                            ? "Not applicable"
                            : s.label,
                    BackgroundColor: this.getSampleScoreColor(s.value),
                    Color:
                        s.value != 0 && s.value != SampleScore.inapplicable ? "white" : "#001A41",
                    Hide: s.value == 0,
                };
            });
        });

        this._ds.getTestStepScores().subscribe((scores) => {
            this.stepScores = scores.map((s) => {
                return {
                    Id: s.value,
                    Label:
                        s.value == 0
                            ? "add a score"
                            : s.value == TestStepScore.inapplicable
                            ? "Not applicable"
                            : s.label,
                    BackgroundColor: this.getStepScoreColor(s.value),
                    Color:
                        s.value != 0 && s.value != TestStepScore.inapplicable ? "white" : "#001A41",
                    Hide: s.value == 0,
                };
            });
        });

        this.onChanges();
    }

    async save(doRefresh: boolean) {
        this.updateModels();
        if (this.validateFromSave()) {
            await this.saveChanges(doRefresh);
        }
    }

    async saveChanges(doRefresh: boolean) {
        await this.saveSampleChanges();
        if (doRefresh) {
            this.cerrixTab.refresh();
        }
    }

    onChanges() {
        this.allEvidenceValid = this.validate(false);
    }

    validateFromSave(): boolean {
        let valid = true;
        this.samples.forEach((s) => {
            if (
                // sample score and comment are filled, so all step scores must have a valid score (not noScore)
                s.score != SampleScore.noScore &&
                s.commentTester &&
                s.commentTester.trim().length > 0
            ) {
                s.testSteps.forEach((c) => {
                    if (c.score == TestStepScore.noScore) {
                        this._toastr.warning(
                            `Please enter score on sample '${s.name}" and test step "${c.name}"`
                        );
                        valid = false;
                    }
                });
            }
        });

        if (valid) {
            // all steps are ok
            this.samples.forEach((s) => {
                if (
                    (s.commentTester && s.commentTester.trim().length > 0) ||
                    s.score != SampleScore.noScore
                ) {
                    if (!s.commentTester || s.commentTester.trim().length == 0) {
                        this._toastr.warning(`Please enter comment on sample "${s.name}"`);
                        valid = false;
                    }
                    if (s.score == SampleScore.noScore) {
                        this._toastr.warning(`Please enter score on sample "${s.name}"`);
                        valid = false;
                    }
                }
            });
        }

        return valid;
    }

    validate(showWarning: boolean): boolean {
        this.updateModels();

        let valid = true;
        this.samples.forEach((s) => {
            s.testSteps.forEach((c) => {
                if (c.score == TestStepScore.noScore) {
                    if (showWarning) {
                        this._toastr.warning(
                            `Please enter score on sample '${s.name}" and test step "${c.name}"`
                        );
                    }
                    valid = false;
                }
            });
        });
        if (valid) {
            // all steps are ok
            this.samples.forEach((s) => {
                if (!s.commentTester || s.commentTester.trim().length == 0) {
                    if (showWarning) {
                        this._toastr.warning(`Please enter comment on sample "${s.name}"`);
                    }
                    valid = false;
                }
                if (s.score == SampleScore.noScore) {
                    if (showWarning) {
                        this._toastr.warning(`Please enter score on sample "${s.name}"`);
                    }
                    valid = false;
                }
            });
        }
        return valid;
    }

    isSampleDisabled(item: any): boolean {
        if (item.isSample) {
            return item.model.testSteps.map((x) => x.score > 0).some((x) => x === false);
        }
        return false;
    }

    async onFinalConclusionClick() {
        if (!this.stepPermissions.isCompleteAllowed) {
            return;
        }

        if (!this.validate(true)) {
            return;
        }

        await this.saveChanges(false);

        this.giveFinalConclusion(
            "Tester final conclusions",
            "Save final conclusion",
            "Are you sure ? Please leave a score and a comment",
            ((finalConclusion: FinalConclusion) => {
                this._workflowDs
                    .postWorkflowAcceptTester(this.effectivenessModel.guid, {
                        comment: finalConclusion.conclusion,
                        scoreId: finalConclusion.score,
                    })
                    .subscribe(() => {
                        this.cerrixTab.refresh();
                    });
            }).bind(this)
        );
    }

    async onMissingEvidenceClick() {
        if (!this.stepPermissions.isCompleteAllowed) {
            return;
        }

        if (!this.validate(true)) {
            return;
        }

        await this.saveChanges(false);

        this.giveFinalConclusion(
            "Missing Evidence",
            "Confirm",
            "Are you sure you want to reject this test due to missing evidence?\n Please give a comment for this action.",
            ((finalConclusion: FinalConclusion) => {
                this._workflowDs
                    .postWorkflowRejectTester(this.effectivenessModel.guid, {
                        comment: finalConclusion.conclusion,
                        scoreId: finalConclusion.score,
                    })
                    .subscribe(() => {
                        this.cerrixTab.refresh();
                    });
            }).bind(this),
            "600px",
            true
        );
    }

    async onRejectNonOccurrenceClick() {
        if (!this.stepPermissions.isCompleteAllowed) {
            return;
        }

        if (!this.validate(true)) {
            return;
        }

        this.giveFinalConclusion(
            "Reject non occurrence",
            "Reject",
            "Are you sure you want to reject this non occurrence case?\n Please give a comment for this action.",
            ((finalConclusion: FinalConclusion) => {
                this._workflowDs
                    .postWorkflowNonOccurrenceRejectTester(this.effectivenessModel.guid, {
                        comment: finalConclusion.conclusion,
                        scoreId: finalConclusion.score,
                    })
                    .subscribe(() => {
                        this.cerrixTab.refresh();
                    });
            }).bind(this),
            "600px",
            true
        );
    }

    async onAcceptNonOccurrenceClick() {
        if (!this.stepPermissions.isCompleteAllowed) {
            return;
        }

        if (!this.validate(true)) {
            return;
        }

        this.giveFinalConclusion(
            "Accept non occurrence",
            "Accept",
            "Are you sure you want to accept this non occurrence case?\n Please give a conclusion and comment for this action.",
            ((finalConclusion: FinalConclusion) => {
                this._workflowDs
                    .postWorkflowNonOccurrenceAcceptTester(this.effectivenessModel.guid, {
                        comment: finalConclusion.conclusion,
                        scoreId: finalConclusion.score,
                    })
                    .subscribe(() => {
                        this.cerrixTab.refresh();
                    });
            }).bind(this)
        );
    }
}
