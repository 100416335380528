import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CerrixCalendarModule } from "@app/calendar/calendar.module";
import { ControlsWidgetsDataService } from "@app/dashboard/services/controls-widgets.data.service";
import { SharedModule } from "@app/shared/shared.module";
import { KriModule } from "./../kri/kri.module";
import { ReportModule } from "./../report/report.module";
import { DashboardConfigurationComponent } from "./dashboard-configuration/dashboard-configuration.component";
import { DashboardManagerComponent } from "./dashboard-manager/dashboard-manager.component";
import { DashboardWidgetComponent } from "./dashboard-widget/dashboard-widget.component";
import { DashboardComponent } from "./dashboard.component";
import { GlobalFilteringComponent } from "./global-filtering/global-filtering.component";
import { DashboardService } from "./services/dashboard.service";
import { WidgetConfigurationComponent } from "./widget-configuration/widget-configuration.component";
import { WidgetMarketComponent } from "./widget-market/widget-market.component";
import { CalendarWidgetComponent } from "./widgets/calendar-widget/calendar-widget.component";
import { EmbeddedReportWidgetComponent } from "./widgets/embedded-report-widget/embedded-report-widget.component";
import { FormOverviewWidgetComponent } from "./widgets/form-overview-widget/form-overview-widget.component";
import { ControlEffTestingWidgetComponent } from "./widgets/highchart-widgets/types/controls-eff-testing-widget.component";
import { ControlSimpleEffOverdueWidgetComponent } from "./widgets/highchart-widgets/types/controls-simple-eff-overdue-widget.component";
import { DesignImplementationWidgetComponent } from "./widgets/highchart-widgets/types/designimplementation-widget-component";
import { EffectivenessOverdueWidgetComponent } from "./widgets/highchart-widgets/types/effectiveness-overdue-widget.component";
import { EventWidgetComponent } from "./widgets/highchart-widgets/types/event-widget.component";
import { FindingReportWidgetComponent } from "./widgets/highchart-widgets/types/finding-report-widget.component";
import { KriWidgetComponent } from "./widgets/highchart-widgets/types/kri-widget.component";
import { MoiWidgetComponent } from "./widgets/highchart-widgets/types/moi-widget.component";
import { RisksPerCatalogueWidgetComponent } from "./widgets/highchart-widgets/types/risks-per-catalogue-widget.component";
import { ThirdPartyWidgetComponent } from "./widgets/highchart-widgets/types/thirdparty-widget.component";
import { KriDetailWidgetComponent } from "./widgets/kri-detail-widget/kri-detail-widget.component";
import { LinkWidgetComponent } from "./widgets/link-widget/link-widget.component";
import { ModuleDetailWidgetComponent } from "./widgets/module-detail-widget/module-detail-widget.component";
import { ProcessWidgetComponent } from "./widgets/process-widget/process-widget.component";
import { RiskMatrixWidgetComponent } from "./widgets/risk-matrix-widget/risk-matrix-widget.component";
import { TextWidgetComponent } from "./widgets/text-widget/text-widget.component";
import { VideoWidgetComponent } from "./widgets/video-widget/video-widget.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CerrixCalendarModule,
        KriModule,
        ReportModule,
    ],
    declarations: [
        DashboardComponent,
        DashboardWidgetComponent,
        DashboardManagerComponent,

        WidgetMarketComponent,
        WidgetConfigurationComponent,

        LinkWidgetComponent,
        ModuleDetailWidgetComponent,
        CalendarWidgetComponent,
        TextWidgetComponent,
        VideoWidgetComponent,
        EmbeddedReportWidgetComponent,
        MoiWidgetComponent,
        EventWidgetComponent,
        ControlEffTestingWidgetComponent,
        ControlSimpleEffOverdueWidgetComponent,
        KriWidgetComponent,
        KriDetailWidgetComponent,
        DashboardConfigurationComponent,
        GlobalFilteringComponent,
        RiskMatrixWidgetComponent,
        ThirdPartyWidgetComponent,
        EffectivenessOverdueWidgetComponent,
        FormOverviewWidgetComponent,
        ProcessWidgetComponent,
        FindingReportWidgetComponent,
        RisksPerCatalogueWidgetComponent,
        DesignImplementationWidgetComponent,
    ],
})
export class DashboardModule {
    static forRoot(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [DashboardService, ControlsWidgetsDataService],
        };
    }
}
