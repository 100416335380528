import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ApiKeyModel } from "../../models/api-key.model";
import { ApiKeyService } from "../../services/api-key.service";
import { ManualPageType } from "@app/manual/models/manual-page-type";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabService } from "@services/tabs/TabService";
import { ApiKeyDetailComponent } from "../api-key-detail/api-key-detail.component";

@Component({
    selector: "api-key-overview",
    templateUrl: "./api-key-overview.component.html",
    styleUrls: ["./api-key-overview.component.scss"],
})
export class ApiKeyOverviewComponent implements OnInit {
    @ViewChild(ApiKeyDetailComponent) editCmp: ApiKeyDetailComponent;
    @Input() userGuid: string = null;

    apiKeys: ApiKeyModel[];
    canEdit = false;
    selected: ApiKeyModel;

    constructor(
        private _ds: ApiKeyService,
        private _prompt: CerrixPromptService,
        private _tabService: TabService
    ) {}

    async ngOnInit(): Promise<void> {
        this._ds.canEdit(this.userGuid).subscribe((canEdit) => {
            this.canEdit = canEdit;
        });
        this.loadData();
    }

    reset(): void {
        this.apiKeys = [];
    }

    loadData(): void {
        this.reset();
        this._ds.getApiKeys(this.userGuid).subscribe((x) => {
            this.apiKeys = x;
            if (this.selected && this.apiKeys.length > 0) {
                this.open(this.apiKeys.find((k) => k.id === this.selected.id));
            }
        });
    }

    async open(model: ApiKeyModel): Promise<void> {
        let data: ApiKeyModel;
        if (model.id > 0) {
            data = await this._ds.getApiKey(this.userGuid, model.id).toPromise();
        } else {
            data = model;
        }

        this.selected = data;
        this.editCmp.open(data, !this.canEdit);
    }

    openManual(): void {
        this._tabService.openManual(ManualPageType.ApiUsage);
    }

    async new(): Promise<void> {
        const template = await this._ds.getNew(this.userGuid).toPromise();
        await this.open(template);
    }

    async save(): Promise<void> {
        if (!this.canEdit) {
            return;
        }

        let loader = this._prompt.loader("", "Saving API Key, please wait...");

        try {
            const storeResult = await this._ds
                .storeApiKey(this.userGuid, this.selected)
                .toPromise();

            loader.close();
            loader = null;

            if (this.selected.id < 0) {
                this.selected.id = storeResult.id;
                this._prompt.alert({
                    maxHeight: "475px",
                    maxWidth: "600px",
                    data: {
                        title: "API-Key Created",
                        message:
                            "A new API Key has been created." +
                            "\nThis is the first and last time you can see this." +
                            "\nAfter you close this, it won't be shown again for security reasons." +
                            "\nPlease copy the Identifier and Password to the application where you intend to use it now.",
                        closeOnEsc: false,
                        confirmOnEnter: false,
                        centerText: false,
                        fields: [
                            {
                                prettyName: "Identifier",
                                fieldName: "identifier",
                                fieldType: GenericListFieldType.Text,
                                isReadonly: true,
                            },
                            {
                                prettyName: "Password",
                                fieldName: "password",
                                fieldType: GenericListFieldType.Text,
                                isReadonly: true,
                            },
                        ],
                        fieldResult: storeResult,
                    },
                });
            }

            this.loadData();
        } catch (error) {
        } finally {
            if (loader) {
                loader.close();
            }
        }
    }

    async delete(): Promise<void> {
        if (!this.selected || this.selected.id <= 0) {
            return;
        }

        const continueDelete = await this._prompt
            .confirmCustom({
                maxWidth: "400px",
                maxHeight: "250px",
                data: {
                    title: "Continue deleting API key?",
                    message:
                        "This action cannot be undone and all applications that use this key to access data will have to be reconfigured.\n",
                },
            })
            .toPromise();

        if (continueDelete) {
            const loader = this._prompt.loader("", "Deleting API Key, please wait...");
            await this._ds.deleteApiKey(this.selected.id).toPromise();

            this.selected = null;
            this.loadData();
            loader.close();
        }
    }
}
