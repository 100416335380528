<div class="fancy-loader" *ngIf="!breachModel"></div>
<div *ngIf="breachModel">
    <div class="detail-page-summary" *ngIf="BreachTimerText">
        <div class="row">
            <div class="col-4">
                <div class="property">
                    <b>Date occurred:</b> {{ breachModel.BreachOccurenceDateTime | dateFormat }}
                </div>
                <div class="property">
                    <b>Date detected:</b> {{ breachModel.BreachDetectionDateTime | dateTimeFormat }}
                </div>
                <div class="property">
                    <b>Date end breach:</b> {{ breachModel.BreachEndDateTime | dateFormat }}
                </div>
            </div>
            <div class="col timer">
                {{ BreachTimerText }}
            </div>
        </div>
    </div>

    <div class="detail-page-card" *ngIf="eventModel">
        <div class="detail-page-card-actions">
            <button
                data-uiid="event-db-isdatabreach"
                class="btn btn-raised"
                (click)="toggleDataBreach()"
                [disabled]="!eventPermissions.canSave || breachPermissions.readonly"
            >
                <i
                    class="{{ eventModel.IsDataBreach ? 'fas fa-check-square' : 'far fa-square' }}"
                ></i>
                <span>Is data breach</span>
            </button>
        </div>
    </div>

    <div class="fancy-loader" *ngIf="isTogglingDataBreach"></div>

    <form
        [formGroup]="parentForm"
        novalidate
        *ngIf="(eventModel && eventModel.IsDataBreach) || breachModel.ID > 0"
        class="cerrix-control-sm"
        [class.breach-disabled-overlay]="!eventModel.IsDataBreach"
    >
        <div class="detail-page-card">
            <div class="detail-page-card-title">Data breach details</div>
            <div class="detail-page-card-body padded">
                <div class="fancy-loader submodule" *ngIf="!sdHeavy"></div>
                <div *ngIf="sdHeavy">
                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="row">
                                <div class="form-group col">
                                    <cerrix-textfield
                                        fieldLabel="Internal breach identifier"
                                        dataUiid="event-db-internalidentifier"
                                        identifier="internalIdentifierInput"
                                        formControlName="InternalBreachIdentifier"
                                    ></cerrix-textfield>
                                </div>

                                <div class="form-group col">
                                    <cerrix-textfield
                                        fieldLabel="External breach identifier"
                                        dataUiid="event-db-externalidentifier"
                                        identifier="externalIdentifierInput"
                                        formControlName="ExternalBreachIdentifier"
                                    ></cerrix-textfield>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group">
                                <label for="organizationSelect">Organization</label>
                                <cerrix-select-tree
                                    data-uiid="event-db-organization"
                                    [tree]="sdHeavy.organizations"
                                    formControlName="OrganizationId"
                                    placeholder="Required"
                                    [multiselect]="false"
                                >
                                </cerrix-select-tree>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group">
                                <label for="responsibleSelect">Data breach responsible(s)</label>
                                <ng-select
                                    data-uiid="event-db-responsibles"
                                    id="breachResponsibleSelect"
                                    name="responsibleSelect"
                                    [items]="sdHeavy.responsibles"
                                    formControlName="ResponsibleList"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    placeholder="Required"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <label for="assessorSelect">Data breach assessor(s)</label>
                                <ng-select
                                    data-uiid="event-db-assessors"
                                    id="breachAssessorSelect"
                                    name="assessorSelect"
                                    [items]="sdHeavy.assessors"
                                    formControlName="BreachAssessorList"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <label for="dataProtectionOfficerSelect"
                                    >Data protection officer</label
                                >
                                <ng-select
                                    data-uiid="event-db-protection"
                                    id="dataProtectionOfficerSelect"
                                    name="dataProtectionOfficerSelect"
                                    [items]="sdHeavy.officers"
                                    formControlName="DataProtectionOfficerId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group">
                                <label>Date breach occurred</label>
                                <date-input
                                    data-uiid="event-db-dateoccured"
                                    formControlName="BreachOccurenceDateTime"
                                    [maxDate]="maxDate"
                                ></date-input>
                            </div>

                            <div class="form-group">
                                <label>Date breach detected</label>
                                <date-input
                                    data-uiid="event-db-datedetected"
                                    formControlName="BreachDetectionDateTime"
                                    (valueChange)="calculateDateTime()"
                                    [showTimePicker]="true"
                                    [maxDate]="maxDate"
                                >
                                </date-input>
                            </div>

                            <div class="form-group">
                                <label>Date end of breach</label>
                                <date-input
                                    data-uiid="event-db-dateendofbreach"
                                    formControlName="BreachEndDateTime"
                                    [maxDate]="maxDate"
                                ></date-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-page-card">
            <div class="detail-page-card-title">Breach analysis</div>
            <div class="detail-page-card-body padded">
                <div class="fancy-loader submodule" *ngIf="!sdHeavy"></div>
                <div *ngIf="sdHeavy">
                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group">
                                <label for="breachCategorySelect">Nature of breach</label>
                                <ng-select
                                    data-uiid="event-db-natureofbreach"
                                    id="breachCategorySelect"
                                    name="breachCategorySelect"
                                    [items]="sdHeavy.breachCategories"
                                    formControlName="BreachCategoryList"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <label for="natureOfIncidentSelect">Nature of incident</label>
                                <ng-select
                                    data-uiid="event-db-natureofincident"
                                    id="natureOfIncidentSelect"
                                    name="natureOfIncidentSelect"
                                    [items]="sdHeavy.natureOfIncidents"
                                    formControlName="NatureOfIncidentId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="true"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <cerrix-textarea
                                    fieldLabel="Description of breach"
                                    dataUiid="event-db-descriptionofbreach"
                                    identifier="breachDescriptionInput"
                                    formControlName="BreachDescription"
                                    placeholder="Required"
                                ></cerrix-textarea>
                            </div>

                            <div class="form-group">
                                <label for="typesOfBreachSelect">Types of breach</label>
                                <ng-select
                                    data-uiid="event-db-typesofbreach"
                                    id="typesOfBreachSelect"
                                    name="typesOfBreachSelect"
                                    [items]="sdHeavy.breachTypes"
                                    formControlName="BreachTypes"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <cerrix-textarea
                                    fieldLabel="Cause of breach"
                                    dataUiid="event-db-causeofbreach"
                                    identifier="causeOfbreachInput"
                                    formControlName="BreachCause"
                                    placeholder="Required"
                                ></cerrix-textarea>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group">
                                <cerrix-textarea
                                    fieldLabel="Preventative measures"
                                    dataUiid="event-db-preventativemeasures"
                                    identifier="preventativeMeasuresInput"
                                    formControlName="PreventativeMeasures"
                                ></cerrix-textarea>
                            </div>

                            <div class="form-group">
                                <label for="ciaOfDataSelect">Consequences to CIA of data</label>
                                <ng-select
                                    data-uiid="event-db-consequencescia"
                                    id="ciaOfDataSelect"
                                    name="ciaOfDataSelect"
                                    [items]="sdHeavy.consequencesToCiaOfData"
                                    formControlName="ConsequencesToCiaOfDataList"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <label for="personalBreachSelect"
                                    >Consequences of personal data breach</label
                                >
                                <ng-select
                                    data-uiid="event-db-consequencespersonal"
                                    id="personalBreachSelect"
                                    name="personalBreachSelect"
                                    [items]="sdHeavy.consequencesOfBreach"
                                    formControlName="DataBreachConsequences"
                                    [multiple]="true"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <label for="personsInvolvedSelect">Person(s) involved</label>
                                <ng-select
                                    data-uiid="event-db-personsinvolved"
                                    id="personsInvolvedSelect"
                                    name="personsInvolvedSelect"
                                    [items]="sdHeavy.personsInvolvedTypes"
                                    formControlName="PersonsInvolvedList"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <cerrix-textarea
                                    fieldLabel="Description persons involved"
                                    dataUiid="event-db-descriptionpersonsinvolved"
                                    identifier="personsInvolvedInput"
                                    formControlName="PersonsInvolvedDescription"
                                ></cerrix-textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group-separator"></div>

                            <div class="form-group">
                                <label for="personalDataSelect">Personal data</label>
                                <ng-select
                                    data-uiid="event-db-personaldata"
                                    id="personalDataSelect"
                                    name="personalDataSelect"
                                    [items]="sdHeavy.personalDataTypes"
                                    formControlName="PersonalDataTypes"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    placeholder="Required"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <label for="specialCatPersonalDataSelect"
                                    >Special categories of personal data</label
                                >
                                <ng-select
                                    data-uiid="event-db-specialcategories"
                                    id="specialCatPersonalDataSelect"
                                    name="specialCatPersonalDataSelect"
                                    [items]="sdHeavy.specialPersonalDataTypes"
                                    formControlName="SensitivePersonalDataTypes"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                >
                                </ng-select>
                            </div>

                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Name data processor"
                                    dataUiid="event-db-namedataprocessor"
                                    identifier="nameDataProcessorInput"
                                    formControlName="DataProcessorName"
                                ></cerrix-textfield>
                            </div>

                            <div class="row">
                                <div class="col-6 form-group">
                                    <cerrix-checkbox
                                        data-uiid="event-db-outsourced"
                                        formControlName="OutsourcedDataProcessing"
                                        label="Outsourced data processing"
                                        fontsize="0.9em"
                                    >
                                    </cerrix-checkbox>
                                </div>
                                <div class="col-6 form-group">
                                    <cerrix-checkbox
                                        data-uiid="event-db-subdata"
                                        formControlName="SubdataProcessor"
                                        label="Sub-data processor"
                                        fontsize="0.9em"
                                    >
                                    </cerrix-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group-separator d-none d-xl-block"></div>

                            <div class="form-group">
                                <label for="dataregistersInvolvedNumber"
                                    ># dataregisters involved</label
                                >
                                <input
                                    data-uiid="event-db-numberdataregisters"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="dataregistersInvolvedNumber"
                                    formControlName="NumberOfDataRegistersInvolved"
                                    step="1"
                                />
                            </div>

                            <div class="form-group">
                                <label for="minimumPersonsInvolvedNumber"
                                    >Minimum # of persons involved</label
                                >
                                <input
                                    data-uiid="event-db-minimumnumberofpersons"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="minimumPersonsInvolvedNumber"
                                    formControlName="NumberOfPersonsInvolvedMinimum"
                                    step="1"
                                />
                            </div>

                            <div class="form-group">
                                <label for="maximumPersonsInvolvedNumber"
                                    >Maximum # of persons involved</label
                                >
                                <input
                                    data-uiid="event-db-maximumnumberofpersons"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="maximumPersonsInvolvedNumber"
                                    formControlName="NumberOfPersonsInvolvedMaximum"
                                    step="1"
                                />
                            </div>

                            <div class="row">
                                <div class="col form-group">
                                    <cerrix-checkbox
                                        data-uiid="event-db-personsothereu"
                                        formControlName="PersonsInvolvedFromOtherEuCountries"
                                        label="Persons in other EU-countries involved"
                                        fontsize="0.9em"
                                    >
                                    </cerrix-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-page-card">
            <div class="detail-page-card-title">Notifications</div>
            <div class="detail-page-card-body padded">
                <div class="fancy-loader submodule" *ngIf="!sdHeavy"></div>
                <div *ngIf="sdHeavy">
                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group">
                                <cerrix-checkbox
                                    data-uiid="event-db-authoritiesnotified"
                                    formControlName="AuthoritiesNotified"
                                    label="Authorities notified"
                                    fontsize="0.9em"
                                    (valueChange)="authoritiesNotifiedChange($event)"
                                >
                                </cerrix-checkbox>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.AuthoritiesNotified.value"
                            >
                                <label>Date authorities notified</label>
                                <date-input
                                    data-uiid="event-db-dateauthoritiesnotified"
                                    formControlName="AuthoritiesNotifiedDate"
                                    (valueChange)="calculateDateTime()"
                                    [showTimePicker]="true"
                                    [maxDate]="maxDate"
                                >
                                </date-input>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group-separator d-xl-none"></div>
                            <div class="form-group">
                                <cerrix-checkbox
                                    data-uiid="event-db-intauthoritiesnotified"
                                    formControlName="InternationalAuthoritiesNotified"
                                    label="International authorities notified"
                                    fontsize="0.9em"
                                >
                                </cerrix-checkbox>
                            </div>
                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.InternationalAuthoritiesNotified.value"
                            >
                                <cerrix-textfield
                                    fieldLabel="Name of international authority"
                                    dataUiid="event-db-nameinternationalauthority"
                                    identifier="nameInternationalAuthority"
                                    formControlName="InternationalAuthoritiesName"
                                ></cerrix-textfield>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group-separator"></div>
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Which EU country"
                                    dataUiid="event-db-whicheucountry"
                                    identifier="whichEuCountryInput"
                                    formControlName="EUCountry"
                                ></cerrix-textfield>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group-separator d-none d-xl-block"></div>

                            <div class="form-group">
                                <cerrix-checkbox
                                    data-uiid="event-db-crossborderprocessing"
                                    formControlName="CrossborderProcessing"
                                    label="Crossborder processing"
                                    fontsize="0.9em"
                                >
                                </cerrix-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group-separator"></div>

                            <div class="form-group">
                                <cerrix-checkbox
                                    data-uiid="event-db-notificationpersonsinvolved"
                                    formControlName="PersonsInvolvedNotified"
                                    label="Notification to persons involved"
                                    fontsize="0.9em"
                                >
                                </cerrix-checkbox>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.PersonsInvolvedNotified.value"
                            >
                                <label for="personsInvNotDate"
                                    >Date notification to persons involved</label
                                >
                                <date-input
                                    data-uiid="event-db-datenotifications"
                                    formControlName="PersonsInvolvedNotificationDate"
                                    [maxDate]="maxDate"
                                >
                                </date-input>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.PersonsInvolvedNotified.value"
                            >
                                <label for="personsInvolvedNotifiedNumber"
                                    ># of Persons notified</label
                                >
                                <input
                                    data-uiid="event-db-numberpersonsnotified"
                                    type="number"
                                    class="form-control form-control-sm"
                                    id="personsInvolvedNotifiedNumber"
                                    name="personsInvolvedNotifiedNumber"
                                    formControlName="PersonsNotified"
                                    min="0"
                                />
                            </div>

                            <div
                                class="form-group"
                                *ngIf="!parentForm.controls.PersonsInvolvedNotified.value"
                            >
                                <label for="reasonNoNotificationSelect"
                                    >Reason no notification</label
                                >
                                <ng-select
                                    data-uiid="event-db-reasonnonotification"
                                    id="reasonNoNotificationSelect"
                                    name="reasonNoNotificationSelect"
                                    [items]="sdHeavy.noNotificationReasons"
                                    formControlName="ReasonNoNotificationId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="true"
                                    [multiple]="false"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group-separator d-none d-xl-block"></div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.PersonsInvolvedNotified.value"
                            >
                                <cerrix-textarea
                                    fieldLabel="Description notification to persons involved"
                                    dataUiid="event-db-descriptionnotification"
                                    identifier="personsInvNotiDescr"
                                    formControlName="NotificationPersonsInvolved"
                                >
                                </cerrix-textarea>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="!parentForm.controls.PersonsInvolvedNotified.value"
                            >
                                <cerrix-textarea
                                    fieldLabel="Explanation no notification"
                                    dataUiid="event-db-explanationnonotification"
                                    identifier="personsInvNoNotiExpl"
                                    formControlName="ExplanationNoNotificationPersonsInvolved"
                                >
                                </cerrix-textarea>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.PersonsInvolvedNotified.value"
                            >
                                <cerrix-textarea
                                    fieldLabel="Means of communication"
                                    dataUiid="event-db-meansofcommunication"
                                    identifier="meansOfCommunicationInput"
                                    formControlName="MeansOfCommunication"
                                >
                                </cerrix-textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group-separator"></div>

                            <div class="form-group">
                                <cerrix-checkbox
                                    data-uiid="event-db-secondarynotification"
                                    formControlName="SecondaryNotificationNotified"
                                    label="Secondary notification"
                                    fontsize="0.9em"
                                >
                                </cerrix-checkbox>
                            </div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.SecondaryNotificationNotified.value"
                            >
                                <label for="secondaryNotDate">Date secondary notification</label>
                                <date-input
                                    data-uiid="event-db-datesecondarynotification"
                                    formControlName="SecondaryNotificationDate"
                                    [maxDate]="maxDate"
                                >
                                </date-input>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group-separator d-none d-xl-block"></div>

                            <div
                                class="form-group"
                                *ngIf="parentForm.controls.SecondaryNotificationNotified.value"
                            >
                                <cerrix-textarea
                                    fieldLabel="Description secondary notification"
                                    dataUiid="event-db-descriptionsecondarynotification"
                                    identifier="secondaryNotifiDescr"
                                    formControlName="SecondaryNotificationDescription"
                                >
                                </cerrix-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-page-card">
            <div class="detail-page-card-title">Contact details</div>
            <div class="detail-page-card-body padded">
                <div class="fancy-loader submodule" *ngIf="!sdHeavy"></div>
                <div *ngIf="sdHeavy">
                    <div class="row">
                        <div class="col-12 col-xl-6 left-block">
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Chamber of commerce registration nr."
                                    dataUiid="event-db-cocnumber"
                                    identifier="cocRegistrationNumberInput"
                                    formControlName="RegistrationNr"
                                ></cerrix-textfield>
                            </div>

                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Name of the organization"
                                    dataUiid="event-db-organizationname"
                                    identifier="organizationNameInput"
                                    formControlName="OrganizationName"
                                ></cerrix-textfield>
                            </div>

                            <div class="form-group">
                                <label for="activeSectorSelect">Active in which sector</label>
                                <ng-select
                                    data-uiid="event-db-activeinsector"
                                    id="activeSectorSelect"
                                    name="activeSectorSelect"
                                    [items]="sdHeavy.whichSectors"
                                    formControlName="ActiveInWhichSectorId"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="true"
                                >
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-12 col-xl-6 right-block">
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Zip code"
                                    dataUiid="event-db-zipcode"
                                    identifier="zipCodeInput"
                                    formControlName="ZipCode"
                                ></cerrix-textfield>
                            </div>

                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="City"
                                    dataUiid="event-db-city"
                                    identifier="cityInput"
                                    formControlName="City"
                                ></cerrix-textfield>
                            </div>

                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="Address"
                                    dataUiid="event-db-address"
                                    identifier="addressInput"
                                    formControlName="Address"
                                ></cerrix-textfield>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-page-card">
            <div class="detail-page-card-title">Additional information</div>
            <div class="detail-page-card-body padded">
                <div class="row">
                    <div class="col-12 col-xl-6 left-block">
                        <div class="form-group">
                            <cerrix-textarea
                                fieldLabel="Follow-up description"
                                dataUiid="event-db-followupdescription"
                                identifier="followUpDescriptionInput"
                                formControlName="FollowUpDescription"
                            >
                            </cerrix-textarea>
                        </div>
                    </div>

                    <div class="col-12 col-xl-6 right-block">
                        <div class="form-group">
                            <cerrix-textarea
                                fieldLabel="Comments"
                                dataUiid="event-db-comments"
                                identifier="commentsInput"
                                formControlName="Comments"
                            ></cerrix-textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Custom fields -->
        <div class="detail-page-card" *ngIf="showCustomFields">
            <div class="detail-page-card-title">Custom fields</div>
            <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
                <cerrix-custom-fields-editor
                    [parentForm]="databreachCustomFieldsFormGroup"
                    [customFieldData]="databreachCustomFieldData"
                    [singleColumn]="false"
                ></cerrix-custom-fields-editor>
            </div>
        </div>
    </form>
</div>
