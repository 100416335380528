import { IdNameCombination } from "@models/generic/IdNameCombination";

export enum MoiTypes {
    IM_Moi = 1,
    IM_MoiDescription = "Improvement management MoI",

    Control_Moi = 2,
    Control_MoiDescription = "Control MoI",

    LER_Moi = 3,
    LER_MoiDescription = "Event MoI",

    RISK_Moi = 4,
    RISK_MoiDescription = "Risk MoI",

    DM_Moi = 5,
    DM_MoiDescription = "Datamanagement MoI",

    TP_Moi = 6,
    TP_MoiDescription = "Third party MoI",

    Incident_Moi = 7,
    Incident_MoiDescription = "Incident MoI",

    Default = 99,
    DefaultDescription = "Default MoI",
}

export function getMoiTypes(): IdNameCombination[] {
    return [
        new IdNameCombination(MoiTypes.IM_Moi, MoiTypes.IM_MoiDescription),
        new IdNameCombination(MoiTypes.Control_Moi, MoiTypes.Control_MoiDescription),
        new IdNameCombination(MoiTypes.LER_Moi, MoiTypes.LER_MoiDescription),
        new IdNameCombination(MoiTypes.RISK_Moi, MoiTypes.RISK_MoiDescription),
        new IdNameCombination(MoiTypes.DM_Moi, MoiTypes.DM_MoiDescription),
        new IdNameCombination(MoiTypes.TP_Moi, MoiTypes.TP_MoiDescription),
        new IdNameCombination(MoiTypes.Incident_Moi, MoiTypes.Incident_MoiDescription),
    ];
}
