import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuditFindingLinkModel } from "@app/audit-finding/shared/data-models/audit-finding-link-model";
import { ControlLinkableModel } from "@app/controls/models/control-linkable-model";
import { RiskLinkableModel } from "@app/risk/models/risk-linkable-model";
import { addParameterToUrl, toPromise } from "@methods/CommonMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { StandingDataItemWithColor } from "@models/standingdata/StandingDataItemWithColor";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable } from "rxjs";
import { Configuration } from "../../app.constants";
import { AuditFieldworkStandingDataModel } from "../shared/data-models/audit-fieldwork-standing-data-model";
import { AuditFieldworkStepCommentModel } from "../shared/data-models/audit-fieldwork-step-comment-model";
import { AuditFieldworkStepDetailModel } from "../shared/data-models/audit-fieldwork-step-detail-model";
import { AuditFieldworkStepQuickExecutionModel } from "../shared/data-models/audit-fieldwork-step-quick-execution-model";
import { AuditFieldworkStepWorkflowUpdateModel } from "../shared/data-models/audit-fieldwork-step-workflow-update-model";
import { AuditFieldworkPermissionModel } from "../shared/permission-models/audit-fieldwork-permission-model";

@Injectable()
export class AuditFieldworkStepDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audit");
    }

    private getUrl(auditGuid: string, extendedUrl?: string) {
        let url = "/" + auditGuid + "/fieldworkstep";
        if (extendedUrl) {
            if (extendedUrl.startsWith("/")) {
                url += extendedUrl;
            } else {
                url += "/" + extendedUrl;
            }
        }

        return url;
    }

    //#region Audit Fieldwork Step Data methods

    getFieldworkDetails(auditGuid: string, stepGuid: string) {
        const url = this.getUrl(auditGuid, stepGuid);
        return this.get<AuditFieldworkStepDetailModel>(url);
    }

    getFieldworkLinkedFindings(auditGuid: string, stepGuid: string) {
        const url = this.getUrl(auditGuid, stepGuid + "/findings");
        return this.get<number[]>(url);
    }

    getFieldworkLinkedRisks(auditGuid: string, stepGuid: string) {
        const url = this.getUrl(auditGuid, stepGuid + "/risks");
        return this.get<number[]>(url);
    }

    getFieldworkLinkedControls(auditGuid: string, stepGuid: string) {
        const url = this.getUrl(auditGuid, stepGuid + "/controls");
        return this.get<number[]>(url);
    }

    getFieldworkExecutionData(auditGuid: string) {
        const url = this.getUrl(auditGuid, "executionoverview");
        return this.get<AuditFieldworkStepQuickExecutionModel[]>(url);
    }

    getPermissions(auditGuid: string, stepGuid: string): Observable<AuditFieldworkPermissionModel> {
        const url = this.getUrl(auditGuid, stepGuid + "/permissions");
        return this.get<AuditFieldworkPermissionModel>(url);
    }

    //#endregion Audit Fieldwork Step Data methods

    //#region Storing

    storeDetails(auditGuid: string, model: AuditFieldworkStepDetailModel) {
        const url = this.getUrl(auditGuid, "store/detail");
        return toPromise(this.post(model, url));
    }

    storeObservation(auditGuid: string, model: AuditFieldworkStepQuickExecutionModel) {
        const copyModel: AuditFieldworkStepQuickExecutionModel = JSON.parse(JSON.stringify(model));
        if (model.observationDate) {
            copyModel.observationDate = new Date(copyModel.observationDate);
            copyModel.observationDate.setHours(
                model.observationDate.getHours() - model.observationDate.getTimezoneOffset() / 60
            );
        }
        const url = this.getUrl(auditGuid, "store/observation");
        return toPromise(this.post(copyModel, url));
    }

    //#endregion Storing

    //#region Support data

    getAuditObservationScores(auditGuid: string, selected?: number) {
        let url = this.getUrl(auditGuid, "observationscores");
        if (selected) {
            url = addParameterToUrl(url, "selectedIds", selected);
        }

        return this.get<StandingDataItemWithColor[]>(url);
    }

    getPlanningStandingData(auditGuid: string) {
        const url = this.getUrl(auditGuid, "planstandingdata");
        return this.get<AuditFieldworkStandingDataModel>(url);
    }

    getLinkableFindings(auditGuid: string) {
        const url = this.getUrl(auditGuid, "linkablefindings");
        return this.get<AuditFindingLinkModel[]>(url);
    }

    getLinkableRisks(auditGuid: string) {
        const url = this.getUrl(auditGuid, "linkablerisks");
        return this.get<RiskLinkableModel[]>(url);
    }

    getLinkableControls(auditGuid: string) {
        const url = this.getUrl(auditGuid, "linkablecontrols");
        return this.get<ControlLinkableModel[]>(url);
    }

    getLinkableDocuments(auditGuid: string) {
        const url = this.getUrl(auditGuid, "linkabledocuments");
        return this.get<DocumentModel[]>(url);
    }

    //#endregion Support data

    //#region History Data

    // The auditGuid is passed as ExtraParam from the historycomponent that's why these params are reversed
    public getHistory(stepGuid: string, auditGuid: string): Observable<HistoryModel[]> {
        const url = this.getUrl(auditGuid, `/${stepGuid}/history`);
        return this.get<HistoryModel[]>(url);
    }

    // The auditGuid is passed as ExtraParam from the historycomponent that's why these params are reversed
    public getHistoryChanges(
        stepGuid: string,
        historyDate: string | number,
        auditGuid: string
    ): Observable<HistoryChange[]> {
        const url = this.getUrl(auditGuid, `/${stepGuid}/history/${historyDate}`);
        return this.get<HistoryChange[]>(url);
    }

    //#endregion History Data

    public updateStatus(
        guid: string,
        model: AuditFieldworkStepWorkflowUpdateModel,
        auditGuid: string
    ): Observable<any> {
        const url = this.getUrl(auditGuid, `/${guid}/workflow`);
        return this.post(model, url);
    }

    public getComments(
        auditGuid: string,
        auditFieldworkStepGuid: string
    ): Observable<AuditFieldworkStepCommentModel[]> {
        const url = this.getUrl(auditGuid, auditFieldworkStepGuid + "/comments");

        return this.get<AuditFieldworkStepCommentModel[]>(url);
    }
}
