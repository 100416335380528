import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { StandingDataMapping } from "@constants/standingdatamapping";
import { StandingDataView } from "./standing-data-view";
import { StandingDataEditorComponent } from "./standing-data-editor/standing-data-editor.component";
import { StandingDataType } from "@enums/StandingDataType";
import {
    StandingDataOverviewType,
    GenericStandingDataConfig,
} from "@app/shared/models/GenericList/GenericStandingDataConfig";

@Component({
    selector: "standingdata-generic",
    templateUrl: "./standingdata-generic.component.html",
    styleUrls: ["./standingdata-generic.component.scss"],
})
export class StandingDataGenericComponent implements OnInit {
    @ViewChild("standingDataView") standingDataView: StandingDataView;
    @ViewChild("editCmp", { static: true }) editCmp: StandingDataEditorComponent;

    @Input() type: StandingDataType;
    @Input() name: string;

    loaded = false;

    componentConfig: GenericStandingDataConfig;
    overviewTypes = StandingDataOverviewType;
    originalEditComp: StandingDataEditorComponent;

    constructor(private _sdMapping: StandingDataMapping) {}

    async ngOnInit() {
        this.componentConfig = this._sdMapping[this.type];
        this.loaded = true;
        this.originalEditComp = this.editCmp;
    }
}
