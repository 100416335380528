import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { IdNameCombination } from "@models/generic/IdNameCombination";

export class ControlStandingData {
    Organizations: CerrixTreeItem[];
    AllApplicableOrganizations: CerrixTreeItem[];
    BusinessDimensions: CerrixTreeItem[];
    FrameworkDimensions: CerrixTreeItem[];

    ControlCatalogues: IdNameCombination[];
    Types: IdNameCombination[];
    Frequencies: IdNameCombination[];
    Sources: IdNameCombination[];
    AspectICs: IdNameCombination[];
    TypesOfControl: IdNameCombination[];
    ControlOwners: IdNameCombination[];
    RACIFunctions: IdNameCombination[];
    ControlExecutionMethods: IdNameCombination[];
}
