import { TabMenuItem } from "./TabMenuItem";

export class TabMenu {
    customMenu?: any;

    menuItems?: TabMenuItem[] = [];
    activeMenuItem?: string;

    doNotShowTucked?: boolean;

    menuItemClicked?(tab: TabMenuItem): void;

    public getTabMenuItemByMenuItemId(menuItemId: string, ignoreCase?: boolean): TabMenuItem {
        const tabMenuItem = this.getTabMenuItem(menuItemId, this.menuItems, ignoreCase);

        return tabMenuItem;
    }

    getTabMenuItem(menuItemId: string, items: TabMenuItem[], ignoreCase?: boolean): TabMenuItem {
        let tabMenuItem =
            ignoreCase === true
                ? items.find((x) => x.menuItemId.toLowerCase() === menuItemId.toLowerCase())
                : items.find((x) => x.menuItemId === menuItemId);

        if (!tabMenuItem) {
            items.forEach((item) => {
                if (item.children) {
                    if (!tabMenuItem) {
                        tabMenuItem = this.getTabMenuItem(menuItemId, item.children, ignoreCase);
                    }

                    if (tabMenuItem) {
                        return tabMenuItem;
                    }
                }
            });
        }

        return tabMenuItem;
    }
}
