import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DocumentModel } from "@models/documents/documentmodel";
import { DocumentManagerService } from "../../../common/services/document-manager.service";
import { DroppedDocuments } from "../../../common/eff-drop-files-area/eff-drop-files-area.component";
import { OpenDocumentPopupReason } from "../../../common/document-manager/models/add-documents-parameters.model";
import { PopupService } from "@app/shared/services/popup/popup.service";

@Component({
    selector: "documents-list",
    templateUrl: "./documents-list.component.html",
    styleUrls: ["./documents-list.component.scss"],
})
export class DocumentsListComponent {
    @Input() documents: DocumentModel[];
    @Input() canAdd: boolean = true;
    @Input() canEdit: boolean = true;
    @Input() canDelete: boolean = true;
    @Output() documentsChange = new EventEmitter<DocumentModel[]>();

    openPopupReason = OpenDocumentPopupReason;

    constructor(
        private _documentServiceManager: DocumentManagerService,
        private _popupService: PopupService
    ) {}

    downloadDocument(doc: DocumentModel) {
        this._documentServiceManager.downloadDocument(doc);
    }

    onEditDeleteDocument(doc: DocumentModel) {
        this._documentServiceManager.openEditDocumentDialog(
            [doc],
            this.documents,
            OpenDocumentPopupReason.EditDocument,
            this.canAdd,
            this.canEdit,
            this.canDelete,
            (() => {
                this.documentsChange.emit(this.documents);
            }).bind(this)
        );
    }

    onFilesDropped(droppedDocuments: DroppedDocuments) {
        if (!this.canAdd) {
            return;
        }

        if (!droppedDocuments.IsBrowse) {
            this._documentServiceManager.addEditFiles(droppedDocuments.Documents, this.documents);
            this.documentsChange.emit(this.documents);
        } else {
            this._documentServiceManager.openEditDocumentDialog(
                droppedDocuments.Documents,
                this.documents,
                OpenDocumentPopupReason.AddDocument,
                this.canAdd,
                this.canEdit,
                this.canDelete,
                (() => {
                    this.documentsChange.emit(this.documents);
                }).bind(this)
            );
        }
    }

    openDocumentViewer(document: DocumentModel) {
        const url = "/api/documents/" + document.Guid + "/pdf";
        this._popupService.forceOpenInNewWindow(url);
    }

    filteredDocuments() {
        return this.documents?.filter((doc) => !doc.Deleted);
    }

    get allowedFileExtensions() {
        return this._documentServiceManager?.documentConfig?.allowedFileExtensions;
    }
}
