import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssessmentDetailsComponent } from "./pages/details/assessment-details.component";
import { SharedModule } from "@app/shared/shared.module";
import { AssessmentDetailsMainComponent } from "./pages/details/details-main/assessment-details-main.component";
import { FindingReportSharedModule } from "@app/findingreport/shared/finding-report-shared.module";

@NgModule({
    declarations: [AssessmentDetailsComponent, AssessmentDetailsMainComponent],
    imports: [CommonModule, SharedModule, FindingReportSharedModule],
})
export class AssessmentModule {}
