import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { CerrixSlideOverComponent } from "../cerrix-slide-over/cerrix-slide-over.component";
import { WopiComponent } from "@app/wopi/wopi.component";
import { nameof } from "@methods/CommonMethods";

@Component({
    selector: "cerrix-slide-over-wopi",
    templateUrl: "./cerrix-slide-over-wopi.component.html",
    styleUrls: ["./cerrix-slide-over-wopi.component.scss"],
})
export class CerrixSlideOverWopiComponent extends CerrixSlideOverComponent implements OnChanges {
    @Input() wopiData = null;
    @Input() title: string;
    content = null;

    @ViewChild("iframeOverlay", { read: ViewContainerRef, static: true })
    iframeOverlay: ViewContainerRef;
    @ViewChild("slideOutContainer", { read: ElementRef, static: true })
    slideOutContainer: ElementRef;

    containerWidth = "500px";
    containerDragging = false;
    mouseMoveListener: any;
    mouseUpListener: any;

    constructor(private renderer: Renderer2) {
        super();
    }

    close() {
        this.wopiData = null;
        this.content = null;
        this.iframeOverlay.clear();
        super.close();
    }

    initDrag(e: MouseEvent) {
        e.preventDefault();
        this.containerDragging = true;
        const startX = e.clientX;
        const startWidth = parseInt(
            document.defaultView.getComputedStyle(this.slideOutContainer.nativeElement).width,
            10
        );
        this.mouseMoveListener = this.renderer.listen("document", "mousemove", (event) =>
            this.doDrag(event, startX, startWidth)
        );
        this.mouseUpListener = this.renderer.listen("document", "mouseup", () => this.stopDrag());
    }

    doDrag(e: MouseEvent, startX: number, startWidth: number) {
        e.preventDefault();
        this.containerWidth = `${startWidth + startX - e.clientX}px`;
    }

    stopDrag() {
        this.containerDragging = false;
        this.mouseMoveListener();
        this.mouseUpListener();
    }

    ngOnChanges() {
        if (this.wopiData) {
            this.iframeOverlay.clear();

            const wopiComponentRef = this.iframeOverlay.createComponent(WopiComponent);
            wopiComponentRef.setInput(nameof<WopiComponent>("preventFavIcon"), true);

            this.content = wopiComponentRef;
            if (this.content.instance) {
                this.content.instance.id = this.wopiData.id;
                this.content.instance.tab = this.wopiData;
                this.content.instance.tabID = this.wopiData.identifier;
                this.content.instance.config = this.wopiData.config;
            }
        }
    }
}
