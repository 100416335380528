<div class="detail-page-action-bar flex-action-bar">
    <div class="form-group-inline">
        <div class="form-group">
            <button
                data-uiid="button-save"
                class="btn btn-cerrix"
                (click)="save()"
                *ngIf="CanShareControls"
            >
                <i class="fas fa-save"></i>
                <span class="d-none d-lg-inline">Save Shared controls</span>
            </button>
        </div>
    </div>
    <div class="col-6 col-xl-3 right-block">
        <div class="form-group">
            <label> Organization </label>
            <cerrix-select-tree
                data-uiid="control-detail-applicable-organizations"
                [tree]="OrganizationList"
                [multiselect]="false"
                [readonly]="false"
                placeholder="required"
                (onChangeSingleId)="OrganizationChanged($event)"
            >
            </cerrix-select-tree>
        </div>
    </div>
</div>

<control-linkable
    *ngIf="showLinkable && !controlsLoading"
    #linkableControls
    showReloadButton="false"
    displayValueProp="name"
    [(linked)]="linked"
    [(unlinked)]="unlinked"
    [hideActions]="true"
    [orderByValueProperty]="true"
    [disabled]="!CanShareControls"
>
</control-linkable>
