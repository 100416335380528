<div class="fancy-loader" *ngIf="controlModel && isLoading"></div>
<datamanagement-linkable
    *ngIf="controlModel && !isLoading"
    #linkableProcessingPurpose
    typename="data processing"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="unlinked"
    [orderByValueProperty]="true"
    [disabled]="disabled"
    (linkedChange)="linkedItemsChanged($event)"
>
</datamanagement-linkable>
