<div
    class="menu-section"
    *ngFor="let menuItem of menuItems"
    [hidden]="menuItem.hidden"
    [attr.data-uiid]="'tabmenu-' + (level == 1 ? '' : 'section-') + menuItem.name"
>
    <div
        class="menuname"
        [ngClass]="{
            clickable: menuItem.clickable,
            active: mainMenu.activeMenuItem === menuItem.identifier
        }"
        [ngStyle]="{ 'font-weight': 800 - level * 200, 'font-size': 1.1 - level * 0.1 + 'em' }"
        (click)="clickMenuItem(menuItem)"
        [title]="menuItem.name"
        [attr.data-uiid]="'tabmenu-item-' + menuItem.name"
    >
        <span class="text">
            <span class="icon" *ngIf="groupHasIcons">
                <i *ngIf="menuItem.iconClass" [ngStyle]="{ left: (level + 2) * 5 + 'px' }">
                    <cerrix-icon-viewer
                        [icon]="menuItem.iconClass"
                        defaultColor="white"
                    ></cerrix-icon-viewer>
                </i>
            </span>
            {{ menuItem.name }}
        </span>
    </div>
    <div class="menu-children" *ngIf="menuItem.children && menuItem.children.length > 0">
        <cerrix-tabmenu
            [mainMenu]="mainMenu"
            [menuItems]="menuItem.children"
            [level]="level + 1"
        ></cerrix-tabmenu>
    </div>
</div>
