import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { TabComponent } from "@core-components/tab/tab.component";
import { RegisterPdfButton } from "@extensions/FroalaExtensions";
import { setAppAccentColor } from "@methods/CommonMethods";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { PageLookup } from "../../common/constants/pages/page-lookup";
import { Pages } from "../../common/constants/pages/Pages";
import { PageMapping } from "../../common/models/generic/TabModels/PageMapping";
import { LocalStorageService } from "../../services/http/LocalStorageService";
import { PermissionsService } from "../../services/permissions/PermissionsService";
import { LocalizationService } from "../shared/localization/localization.service";
import { SettingsDataService } from "./../../services/http/SettingsDataService";

@Component({
    selector: "cerrix-app",
    templateUrl: "./cerrix-app.component.html",
    styleUrls: ["./cerrix-app.component.scss"],
})
export class CerrixAppComponent implements OnInit {
    title = "Cerrix";
    fullApp: Boolean = false;
    @ViewChild("singleComponent", { read: ViewContainerRef, static: true }) singleComponent;
    @ViewChild(TabComponent) tabComponent: TabComponent;

    constructor(
        public permService: PermissionsService,
        private settingService: SettingsDataService,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private pages: Pages,
        private _pageLookup: PageLookup,
        private _tabService: TabService,
        private _toastr: ToastrService,
        public localStorageService: LocalStorageService,
        public localizationService: LocalizationService
    ) {
        window.name = "CERRIXMAIN";
    }

    ngOnInit() {
        const searchParams = new URL(window.location.href).searchParams;
        const singleView = searchParams.get("singleviewinstance") === "true";
        this.fullApp = !singleView;

        this.permService.initializePermissions();
        this.settingService
            .getSetting(ApplicationSettings.AccentColor)
            .subscribe((s) => setAppAccentColor(s.SerializedOption));
        RegisterPdfButton();

        if (!this.fullApp) {
            this.createSingleComponent();
        }

        this.checkLocalStorage();
        window.addEventListener("beforeunload", this.handleUnload.bind(this));
    }

    checkLocalStorage() {
        this.checkLocalStorageForNewIP();
    }

    checkLocalStorageForNewIP() {
        const isNewLocation = this.localStorageService.getItem<boolean>("USER_NEW_LOCATION_ENTRY");
        if (isNewLocation) {
            this.localStorageService.removeItem("USER_NEW_LOCATION_ENTRY");
            const toast = this._toastr.warning(
                "Click here to save and extend this new location",
                "Login from new location",
                {
                    disableTimeOut: true,
                    closeButton: true,
                }
            );
            toast.onTap.subscribe(() => {
                this._tabService.generateTab(
                    this.pages.Profile,
                    null,
                    null,
                    null,
                    "ip-whitelisting"
                );
            });
        }
    }

    createSingleComponent() {
        let path = window.location.pathname.toLowerCase();
        path = path.replace("//", "/");
        let pathID: any;
        const slLoc = path.indexOf("/");
        if (slLoc >= 0) {
            const split = path.split("/");
            path = slLoc === 0 ? split[1] : split[1];
            pathID = split.length > 1 ? split[2] : undefined;
        }
        if (path.length > 0 && path !== this.pages.Dashboard.toLowerCase()) {
            const lookup = this.pages.getExactName(path);
            if (lookup) {
                const mapping = this._pageLookup[lookup] as PageMapping;
                const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
                    mapping.Component
                );
                const componentRef = this.singleComponent.createComponent(componentFactory);
                if (componentRef.instance) {
                    // Keep params, except singleviewinstance
                    const searchParams = new URL(window.location.href).searchParams;
                    searchParams.delete("singleviewinstance");

                    if (searchParams.toString().length > 0) {
                        pathID = pathID + "?" + searchParams.toString();
                    }
                    componentRef.instance.id = pathID;
                }
            }
        }
    }

    handleUnload(event: BeforeUnloadEvent) {
        if (this.tabComponent && !this.tabComponent.canCloseTabs()) {
            // Standard
            event.preventDefault();
            // Chrome
            event.returnValue = "";
        }
    }
}
