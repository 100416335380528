<div class="dialog-title" mat-dialog-title>
    <cerrix-title
        style="flex: 1"
        size="large"
        [editable]="false"
        [value]="dialogData.title"
    ></cerrix-title>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">
    @if (dialogData.infoText) {
    <div class="info-text">{{ dialogData.infoText }}</div>
    }

    <div>
        <cerrix-field-wrapper [required]="true" label="Comment" type="secondary">
            <cerrix-textarea [formControl]="commentForm"></cerrix-textarea>
        </cerrix-field-wrapper>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
    @if (dialogData.showCancelBtn) {
    <cerrix-button color="accent" mat-dialog-close>Cancel</cerrix-button>
    }

    <cerrix-button [color]="dialogData.btnColor" (click)="onClick()">{{
        dialogData.btnText
    }}</cerrix-button>
</mat-dialog-actions>
