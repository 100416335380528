import { CommonModule } from "@angular/common";
import {
    Component,
    ComponentRef,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
    inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatGridListModule } from "@angular/material/grid-list";
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatListModule, MatSelectionListChange } from "@angular/material/list";

import { StandingDataItemModel } from "../models/standing-data-item.model";
import { BaseStandingDataService } from "../interfaces/base-standing-data.service";
import {
    CerrixButtonComponent,
    CerrixDialogService,
    CerrixInputComponent,
} from "@cerrix/components";
import { BaseStandingDataEditComponent } from "../interfaces/base-standing-data-edit.component";

@Component({
    selector: "standing-data-list",
    templateUrl: "./standing-data-list.component.html",
    styleUrls: ["./standing-data-list.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        CdkDropList,
        CdkDrag,
        MatListModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        CerrixButtonComponent,
        CerrixInputComponent,
    ],
})
export class StandingDataListComponent implements OnInit {
    @Input() public title: string;

    private readonly standingDataService = inject(BaseStandingDataService);
    private readonly cerrixDialogService = inject(CerrixDialogService);

    protected addMode: boolean = false;

    protected standingDataList: StandingDataItemModel[] = [];
    protected filteredStandingData: StandingDataItemModel[] = [];

    protected selectListValue: string[] = [];

    protected searchControl = new FormControl("");

    @ViewChild("editComponentContainer", {
        read: ViewContainerRef,
    })
    protected editComponentContainer: ViewContainerRef;
    private editComponentRef: ComponentRef<BaseStandingDataEditComponent>;
    private get hasChanges(): boolean {
        return this.editComponentRef?.instance?.hasChanges;
    }

    public ngOnInit() {
        this.loadStandingDataList();

        this.searchControl.valueChanges.subscribe((value) => {
            this.filterStandingData(value);
        });
    }

    protected onEntityUpdated(id?: string): void {
        if (id) {
            this.selectListValue = [id];
            this.addMode = false;
        } else {
            this.selectListValue = [];
            this.editComponentContainer?.clear();
        }

        this.loadStandingDataList();
    }

    protected onAdd(): void {
        if (this.hasChanges) {
            this.cerrixDialogService
                .confirmDialog(
                    "Unsaved changes",
                    "There are unsaved changes, are you sure you want to discard your changes?"
                )
                .subscribe((allow) => {
                    if (allow) {
                        this.setAddMode();
                    }
                });
        } else {
            this.setAddMode();
        }
    }

    protected onSelectionChanged(event: MatSelectionListChange): void {
        const newSelectionId = event.options.length ? event.options[0].value : null;

        if (this.hasChanges) {
            this.selectListValue = [...this.selectListValue];

            this.cerrixDialogService
                .confirmDialog(
                    "Unsaved changes",
                    "There are unsaved changes, are you sure you want to discard your changes?"
                )
                .subscribe((allow) => {
                    if (allow) {
                        this.selectEntity(newSelectionId);
                    }
                });
        } else {
            this.selectEntity(newSelectionId);
        }
    }

    protected onOrderChanged(event: CdkDragDrop<StandingDataItemModel[]>) {
        const id = this.standingDataList[event.previousIndex].id;
        const order = this.standingDataList[event.currentIndex].order;

        moveItemInArray(this.standingDataList, event.previousIndex, event.currentIndex);

        this.standingDataService.changeOrder(id, order).subscribe(() => {
            this.loadStandingDataList();
        });
    }

    private loadStandingDataList(): void {
        this.standingDataService.getAll().subscribe((result) => {
            this.standingDataList = result;

            this.filterStandingData(this.searchControl.value);
        });
    }

    private filterStandingData(value: string): void {
        const searchValue = value ? value.toLowerCase() : null;
        this.filteredStandingData = searchValue
            ? this.standingDataList.filter((sd) => sd.name.toLowerCase().includes(searchValue))
            : this.standingDataList;
    }

    private setAddMode(): void {
        this.createEditComponent();

        this.selectListValue = [];
        this.addMode = true;
    }

    private selectEntity(id: string): void {
        this.createEditComponent(id);

        this.selectListValue = [id];
        this.addMode = false;
    }

    private createEditComponent(id?: string): void {
        this.editComponentContainer?.clear();
        this.editComponentRef = this.editComponentContainer?.createComponent(
            this.standingDataService.getEditComponent()
        );
        this.editComponentRef?.setInput("id", id);
        this.editComponentRef?.instance.onEntityUpdated.subscribe((id?: string) =>
            this.onEntityUpdated(id)
        );
    }
}
