<div class="row">
    <div class="col-12 col-xl-6 left-block">
        <div class="detail-page-card">
            <div class="detail-page-card-body padded">
                <risk-period-score-chart #likelihoodScoreChart></risk-period-score-chart>
            </div>
        </div>
    </div>

    <div class="col-12 col-xl-6 right-block">
        <div class="detail-page-card">
            <div class="detail-page-card-body padded">
                <risk-period-score-chart #impactScoreChart></risk-period-score-chart>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="numberOfKris > 0">
    <label class="title">Linked KRI's ({{ numberOfKris }})</label>
    <kri-data-graph-overview [kriGuidsFromHtml]="risk.kriGuids"></kri-data-graph-overview>
</div>
