<span class="fancy-loader" *ngIf="!requests"> </span>
<div
    class="overview"
    [formGroup]="formGroup"
    *ngIf="requests"
    data-uiid="audit-detail-document-requests"
>
    <div class="items">
        <div class="detail-page-card">
            <div class="detail-page-card-title">Document requests</div>
            <div class="detail-page-card-actions">
                <button
                    class="btn btn-raised"
                    data-uiid="add-request-button"
                    (click)="addRequest()"
                    *ngIf="permissions.canCreateDocumentRequests"
                >
                    <i class="fas fa-plus me-2"></i>Add request
                </button>
            </div>
            <div
                class="detail-page-card-body"
                data-uiid="requests-list"
                formArrayName="documentRequests"
            >
                <div
                    [formGroupName]="i"
                    class="item"
                    [class.selected]="selectedIndex === i"
                    [class.fst-italic]="request.controls.id.value <= 0"
                    [class.red]="
                        !request.controls.auditeeCompleted.value &&
                        (!request.controls.name.value || request.invalid)
                    "
                    *ngFor="
                        let request of formGroup.controls.documentRequests['controls'];
                        let i = index
                    "
                    (click)="open(request, i)"
                >
                    <span>{{
                        request.controls.name.value ? request.controls.name.value : ">> No name"
                    }}</span>
                    <i
                        *ngIf="request.controls.auditeeCompleted.value"
                        class="fas fa-check-circle green"
                    ></i>
                </div>
            </div>
        </div>
    </div>

    <div class="details" *ngIf="!selected">
        <div class="no-selection-message">
            <span>Select a request to start.</span>
        </div>
    </div>

    <div
        class="details"
        *ngIf="selected && selectedFormGroup"
        [formGroup]="selectedFormGroup"
        data-uiid="document-request-details"
    >
        <div class="detail-page-action-bar">
            <span class="sub-message" data-uiid="requested-by-text" *ngIf="selected.id > 0">
                Requested by {{ selected.requester }} on
                {{ selected.requestedDate | dateTimeFormat }}</span
            >
            <span
                class="sub-message"
                *ngIf="
                    selected.id > 0 &&
                    selected.status == AuditDocumentRequestStatusType.Completed &&
                    selected.auditeeCompleted &&
                    selected.completedBy &&
                    selected.auditeeDateCompleted
                "
                data-uiid="completed-by-text"
            >
                Completed by {{ selected.completedBy }} on
                {{ selected.auditeeDateCompleted | dateTimeFormat }}
            </span>

            <div class="right-area">
                <button
                    class="btn btn-danger btn-sm"
                    data-uiid="delete-request-button"
                    (click)="deleteRequest()"
                    *ngIf="permissions.canDeleteDocumentRequests"
                >
                    <i class="fal fa-trash"></i>
                    <span class="d-none d-lg-inline">Delete</span>
                </button>
                <button
                    class="btn btn-primary btn-sm"
                    data-uiid="request-documents-button"
                    (click)="requestDocuments()"
                    *ngIf="
                        permissions.canRequestDocumentRequests &&
                        selected.id > 0 &&
                        selected.status != AuditDocumentRequestStatusType.Requested
                    "
                >
                    <span class="d-none d-lg-inline">Request documents</span>
                </button>
                <button
                    class="btn btn-success btn-sm"
                    data-uiid="complete-request-button"
                    *ngIf="
                        permissions.canCompleteDocumentRequests &&
                        selected.id > 0 &&
                        selected.status == AuditDocumentRequestStatusType.Requested
                    "
                    (click)="completeRequest()"
                >
                    <i class="fal fa-check"></i>
                    <span class="d-none d-lg-inline">Mark as completed</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">
                        <span>Auditor</span>
                    </div>
                    <div class="detail-page-card-body padded form-group-styling">
                        <cerrix-textfield
                            fieldLabel="Request Name"
                            placeholder="Required"
                            [formControl]="selectedFormGroup.controls.name"
                            dataUiid="request-name-input"
                        ></cerrix-textfield>
                        <cerrix-textarea
                            fieldLabel="Request Description"
                            [formControl]="selectedFormGroup.controls.description"
                            dataUiid="request-description-input"
                        ></cerrix-textarea>
                        <div class="form-group">
                            <label for="nameInput">Auditee</label>
                            <i
                                class="fas fa-info-circle ms-2"
                                title="Only auditees defined for the audit in the details page are shown here."
                            ></i>
                            <cerrix-select
                                #auditeeSelect
                                id="auditeeSelect"
                                prettyName="Auditee"
                                bindValue="ID"
                                bindLabel="Name"
                                [formControl]="selectedFormGroup.controls.auditeeId"
                                dataUiid="request-auditee-select"
                                [getDataMethod]="auditeeRequest"
                            >
                            </cerrix-select>
                        </div>
                        <div class="form-group">
                            <label>Due date</label>
                            <date-input
                                [formControl]="selectedFormGroup.controls.dueDate"
                                data-uiid="request-duedate-select"
                            >
                            </date-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="detail-page-card">
                    <div class="detail-page-card-title">
                        <span>Auditee</span>
                    </div>
                    <div class="detail-page-card-body padded form-group-styling">
                        <!-- Hidden for now. Will be put back later -->
                        <ng-container *ngIf="false">
                            <div class="form-group">
                                <label for="nameInput">Delegates</label>
                                <cerrix-select
                                    #delegateSelect
                                    id="delegateSelect"
                                    prettyName="Delegates"
                                    bindValue="ID"
                                    bindLabel="Name"
                                    [formControl]="selectedFormGroup.controls.auditeeDelegates"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    dataUiid="request-delegates-select"
                                >
                                </cerrix-select>
                            </div>
                        </ng-container>

                        <cerrix-textarea
                            fieldLabel="Auditee Comment"
                            [formControl]="selectedFormGroup.controls.auditeeComment"
                            dataUiid="request-auditeecomment-input"
                        ></cerrix-textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-page-card mt-0">
            <document-manager
                [(Documents)]="selected.Documents"
                [disabled]="
                    !permissions.canEdit ||
                    !selected.permissions ||
                    !selected.permissions.canEditAuditeePart ||
                    selected.auditeeCompleted
                "
                [hideAddButton]="
                    !permissions.canEdit ||
                    !selected.permissions ||
                    !selected.permissions.canEditAuditeePart ||
                    selected.auditeeCompleted
                "
                [noMargin]="true"
                (DocumentsChange)="onDocumentsChange($event)"
            ></document-manager>
        </div>
    </div>
</div>
