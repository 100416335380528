<eff-expansion-panel
    [contentTemplate]="content"
    [panelOpenState]="panelOpenState"
    title="Control description"
    class="eff-details-panel"
    data-uiid="new-eff-upload-source-document-control-description-info-box"
>
    <ng-template #content>
        <div class="rows-container" *ngIf="controlInfo$ | async as controlInfo">
            <div class="d-flex flex-row justify-content-between">
                <eff-button
                    class="link-button"
                    icon="north_east_outlined"
                    size="medium-size"
                    iconPosition="right"
                    iconSize="small-size"
                    text="open control"
                    (click)="openControl()"
                ></eff-button>
                <eff-button
                    class="link-button"
                    icon="north_east_outlined"
                    size="medium-size"
                    iconPosition="right"
                    iconSize="small-size"
                    text="testplan overview"
                    (click)="openEffOverview()"
                ></eff-button>
            </div>
            <div class="row-container">
                <div class="bold-text row-label">ID:</div>
                <div class="regular-text">{{ controlInfo.ControlIdentifier }}</div>
            </div>

            <div class="row-container">
                <div class="bold-text row-label">Control name:</div>
                <div class="regular-text">{{ controlInfo.ControlName }}</div>
            </div>

            <div class="row-container">
                <div class="bold-text row-label">Control frequency:</div>
                <div class="regular-text">{{ controlInfo.ControlFrequency }}</div>
            </div>

            <div class="row-container">
                <div class="bold-text row-label">Execution method:</div>
                <div class="regular-text">{{ controlInfo.ControlExecutionMethod }}</div>
            </div>

            <div class="bold-text row-label">Description:</div>
            <div
                class="regular-text multiple-whitespaces"
                [innerHTML]="controlInfo.ControlDescription"
            ></div>
        </div>
    </ng-template>
</eff-expansion-panel>
