import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DocumentModel } from "@models/documents/documentmodel";
import { MatTableDataSource } from "@angular/material/table";
import { EffTableColumnConfig } from "../eff-table/models/eff-table-column-config.model";
import { EffTableConfig, EffTableSelectionEnum } from "../eff-table/models/eff-table-config.model";
import { DroppedDocuments } from "../eff-drop-files-area/eff-drop-files-area.component";
import { OpenDocumentPopupReason } from "./models/add-documents-parameters.model";
import { DocumentManagerService } from "../services/document-manager.service";
import { PopupService } from "@app/shared/services/popup/popup.service";

@Component({
    selector: "document-manager-new",
    templateUrl: "./document-manager.component.html",
    styleUrls: ["./document-manager.component.scss"],
})
export class DocumentManagerNewComponent implements OnChanges {
    @Input() documents: DocumentModel[] = [];
    @Input() canAdd = true;
    @Input() canEdit = true;
    @Input() canDelete = true;

    @Output() documentsChange = new EventEmitter<DocumentModel[]>();
    @Output() documentDeleted = new EventEmitter();

    dataSource: MatTableDataSource<DocumentModel> = new MatTableDataSource<DocumentModel>([]);
    tableConfig: EffTableConfig = {
        ExpandableRow: false,
        HideHeader: true,
        Selection: EffTableSelectionEnum.Single,
    };

    displayedColumns: Array<EffTableColumnConfig> = [
        { PropertyName: "Name", DisplayedName: "Name", Width: "100%", IsTemplate: true },
        { PropertyName: "Actions", DisplayedName: "", IsTemplate: true },
    ];

    openPopupReason = OpenDocumentPopupReason;

    constructor(
        private _documentServiceManager: DocumentManagerService,
        private _popupService: PopupService
    ) {}

    ngOnInit() {
        this.refreshDocumentsTable();
        this.enableDisableCheckbox();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["documents"]) {
            this.refreshDocumentsTable();
        }

        if (changes["canAdd"]) {
            this.enableDisableCheckbox();
        }
    }

    enableDisableCheckbox() {
        if (!this.canAdd) {
            this.tableConfig.Selection = EffTableSelectionEnum.None;
        } else {
            this.tableConfig.Selection = EffTableSelectionEnum.Single;
        }
    }

    refreshDocumentsTable() {
        if (this.documents) {
            this.dataSource = new MatTableDataSource(this.documents.filter((doc) => !doc.Deleted));
        }
    }

    emitChange() {
        this.refreshDocumentsTable();
        this.documentsChange.emit(this.documents);
    }

    downloadDocument(doc: DocumentModel) {
        this._documentServiceManager.downloadDocument(doc);
    }

    onEditDocument(doc: DocumentModel, reason: OpenDocumentPopupReason) {
        this._documentServiceManager.openEditDocumentDialog(
            [doc],
            this.documents,
            OpenDocumentPopupReason.EditDocument,
            this.canAdd,
            this.canEdit,
            false,
            (() => {
                this.emitChange();
            }).bind(this)
        );
    }

    onFilesDropped(droppedDocuments: DroppedDocuments) {
        if (!this.canAdd) {
            return;
        }

        if (!droppedDocuments.IsBrowse) {
            this._documentServiceManager.addEditFiles(droppedDocuments.Documents, this.documents);
            this.emitChange();
        } else {
            this._documentServiceManager.openEditDocumentDialog(
                droppedDocuments.Documents,
                this.documents,
                OpenDocumentPopupReason.AddDocument,
                this.canAdd,
                this.canEdit,
                this.canDelete,
                (() => {
                    this.emitChange();
                }).bind(this)
            );
        }
    }

    deleteDocument(doc: DocumentModel) {
        this._documentServiceManager.deleteDocument(doc.Guid, this.documents);
        this.emitChange();
        this.documentDeleted.emit(doc);
    }

    openDocumentViewer(document: DocumentModel) {
        const url = "/api/documents/" + document.Guid + "/pdf";
        this._popupService.forceOpenInNewWindow(url);
    }

    get allowedFileExtensions() {
        return this._documentServiceManager?.documentConfig?.allowedFileExtensions;
    }
}
