import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { CustomFieldOptionModel } from "../models/custom-field-option.model";
import { StandingdataItem } from "@models/standingdata/StandingdataItem";
import { StandingDataItemModel } from "@app/standing-data/models/standing-data-item.model";
import { FormStructureType } from "@app/forms/enums/FormStructureType";
import { FormEntryModel } from "@app/forms/models";
import { IdNameCombination } from "@models/generic/IdNameCombination";

@Injectable({
    providedIn: "root",
})
export class IncidentMapbackService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/mapback`;

    public getStructureForMapback(type: FormStructureType): Promise<StandingDataItemModel[]> {
        return this.httpClient
            .get<StandingDataItemModel[]>(`${this.baseUrl}/mapbackoptions/${type}`)
            .toPromise();
    }

    public storeIncident(result: IdNameCombination[]): Promise<void> {
        return this.httpClient.post<void>(this.baseUrl, result).toPromise();
    }
}
