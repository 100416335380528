import { Component } from "@angular/core";
import { ExportDataService } from "@app/export/services/export-data.service";
import { RiskAssessmentPerOrganizationModel } from "@app/export/shared/models/risk-assessment-per-organization-model";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleType } from "@enums/ModuleType";
import { nameof } from "@methods/jeffs-toolkit";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ExportBase } from "../export-base";
import { CerrixPromptService } from "./../../../shared/services/cerrix-prompt.service";

@Component({
    selector: "export-risks",
    templateUrl: "./export-risks.component.html",
    styleUrls: ["./export-risks.component.scss"],
})
export class ExportRisksComponent extends ExportBase {
    constructor(
        exportDataService: ExportDataService,
        public permService: PermissionsService,
        private cerrixPrompt: CerrixPromptService,
        private dataService: StandingdataDataService
    ) {
        super(exportDataService);
    }

    exportRiskAssessmentPerOrganization(): void {
        this.cerrixPrompt
            .prompt({
                height: "fit-content",
                data: {
                    title: "Risk Assessment per Organization (CRSA) Export",
                    message: "Select between 1 and 200 organizations to generate an export.",

                    fields: [
                        {
                            prettyName: "Organizations",
                            fieldName: nameof(
                                (x: RiskAssessmentPerOrganizationModel) => x.organizations
                            ),
                            fieldType: GenericListFieldType.MultiTree,
                            minLength: 1,
                            maxLength: 200,
                            required: true,
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getOrganizations(ModuleType.ORM);
                            },
                        },
                        {
                            prettyName: "Period",
                            fieldName: nameof((x: RiskAssessmentPerOrganizationModel) => x.period),
                            fieldType: GenericListFieldType.SingleSelect,
                            placeholder: "No filter",
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getPeriods(ModuleType.ORM);
                            },
                        },
                    ],
                    confirmButton: {
                        text: "Download",
                        icon: "fad fa-cloud-download",
                    },
                },
            })
            .toPromise()
            .then((result: RiskAssessmentPerOrganizationModel) => {
                if (!result) {
                    return;
                }

                const stringSections: string[] = [];
                if (result.organizations && result.organizations.length > 0) {
                    stringSections.push("organizations=" + result.organizations.join(";"));
                }

                if (result.period && result.period > 0) {
                    stringSections.push("period=" + result.period);
                }

                const extraString = stringSections.length > 0 ? stringSections.join("&") : "";
                this.download(this.ExportType.RiskAssessmentPerOrganization, extraString);
            });
    }
}
