<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<ng-container *ngIf="!tab.showLoader">
    <div class="detail-page-action-bar">
        <button class="btn btn-cerrix">
            <i class="fas fa-save"></i>
            <span data-uiid="save" class="d-none d-lg-inline">Save planning</span>
        </button>

        <div class="right-area">
            <button class="btn btn-secondary">
                <i class="fad fa-align-right fa-rotate-270"></i>
                <span class="d-none d-lg-inline ms-1">Change periods</span>
            </button>
        </div>
    </div>

    <div class="detail-page-card">
        <div class="detail-page-card-title">Planning</div>
        <div class="detail-page-card-body overflow-auto">
            <cerrix-grid-editor [config]="config"></cerrix-grid-editor>
        </div>
    </div>
</ng-container>
