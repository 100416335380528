<div
    class="render-cell"
    [ngClass]="{ 'text-center': stretched || width <= 300 }"
    [title]="showTooltip && row[header] ? row[header] : ''"
    [ngStyle]="{ width: width + 'px' }"
>
    <!-- No renderer -->
    <div class="padded-cell" *ngIf="!renderer || renderer.type == rendererTypes.Default">
        {{ defaultRender(row[header], renderer) }}
    </div>

    <!-- Overdue renderer -->
    <div
        #overDueElement
        class="padded-cell"
        *ngIf="renderer && renderer.type == rendererTypes.DueDate"
    >
        {{ overDueRender(row[header], renderer, overDueElement) }}
    </div>

    <!-- Score renderer -->
    <div
        class="score-renderer"
        *ngIf="renderer && row[renderer.actionColumn] && renderer.type == rendererTypes.Score"
    >
        <div
            class="score-indication"
            [ngStyle]="{
                background:
                    (row[renderer.actionColumn][0] == '#' ? '' : '#') + row[renderer.actionColumn]
            }"
        ></div>
        <div class="score-value padded-cell">{{ defaultRender(row[header], renderer) }}</div>
    </div>

    <!-- Progress renderer -->
    <div
        class="padded-cell"
        *ngIf="renderer && renderer.type == rendererTypes.Progress"
        [title]="row[header] + '%'"
    >
        <div class="progress">
            <div
                class="progress-bar progress-bar"
                role="progressbar"
                attr.aria-valuenow="{{ row[header] }}"
                aria-valuemin="0"
                aria-valuemax="100"
                [ngStyle]="{ width: row[header] + '%' }"
            ></div>
        </div>
    </div>

    <!-- Icon renderer -->
    <div
        class="padded-cell"
        *ngIf="renderer && renderer.type == rendererTypes.Icon && !renderer.extraValue"
    >
        <div class="icon">
            <cerrix-icon-viewer [icon]="row[header]"></cerrix-icon-viewer>
        </div>
    </div>

    <!-- Icon renderer with specific icon -->
    <div
        class="padded-cell"
        *ngIf="
            renderer &&
            renderer.type == rendererTypes.Icon &&
            renderer.extraValue &&
            row[renderer.actionColumn] == renderer.actionValue
        "
    >
        <div class="icon">
            <cerrix-icon-viewer [icon]="renderer.extraValue"></cerrix-icon-viewer>
        </div>
    </div>
</div>
