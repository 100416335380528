<cerrix-loader [cerrixTab]="parentTab"></cerrix-loader>

<div class="detail-page-action-bar flex-action-bar" *ngIf="showSave">
    <div>
        <button
            data-uiid="button-save"
            class="btn btn-cerrix"
            (click)="saveNotificationPreference()"
        >
            <i class="fas fa-save"></i>
            <span class="d-none d-lg-inline">Save</span>
        </button>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Notification Settings</div>
    <div class="detail-page-card-body padded">
        <label for="notificationPreferenceSelect">Notification Preference</label>
        <cerrix-select
            id="notificationPreferenceSelect"
            prettyName="Notification Preference"
            bindValue="ID"
            bindLabel="Name"
            [getDataMethod]="notificationOptions"
            [(ngModel)]="userModel.notificationPreference"
            [multiple]="false"
            [closeOnSelect]="true"
            dataUiid="user-notification-preference-select"
        >
        </cerrix-select>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Slack Status</div>
    <div class="detail-page-card-body padded">
        <div>
            <label>{{ slackStatusText }}</label>
        </div>
        <div>
            <button data-uiid="button-sync" class="btn btn-success" (click)="syncUser()">
                <i class="fas fa-sync"></i>
                <span class="d-none d-lg-inline">Link Slack Account</span>
            </button>
        </div>
    </div>
</div>
