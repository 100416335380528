import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-generate-samples-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlGenerateSamplesApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Generate Samples API",
            httpMethod: "POST",
            path: "/controls/testing/advanced/effectiveness/{effectivenessGuid}/automation/samples/generate",

            parameters: [
                {
                    name: "effectivenessGuid",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
                {
                    name: "sampleMethod",
                    description:
                        "Number sampling picks numbers from the population. Date sampling picks dates from the test plan period. Spreadsheet sampling picks the unique IDs from the source document, provided it's a spreadsheet.",
                    in: "query",
                    paramType: "string",
                    required: true,
                    enum: ["number", "date", "spreadsheet"],
                },
                {
                    name: "population",
                    description:
                        "Population is required when using the number method as the 'sampleMethod'.",
                    in: "query",
                    paramType: "integer",
                    required: true,
                    example: "1 | 2 | 3",
                },
                {
                    name: "numberOfSamples",
                    in: "query",
                    paramType: "integer",
                    required: true,
                    example: "1 | 2 | 3",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: "Request has been successfully processed",
                },
            ],
        };
    }
}
