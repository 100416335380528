import { Component, inject } from "@angular/core";
import { DashboardWidgetType } from "@app/dashboard/shared/enums/DashboardWidgetType.enum";
import { QuickLinkItemContent } from "@app/dashboard/shared/enums/QuickLinkItemContent.enum";
import { DashboardQuickLinkModel } from "@app/dashboard/shared/models/DashboardQuickLinkModel";
import { Pages } from "@constants/pages/Pages";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { ILinkWidgetGeneratorType } from "./../../shared/interfaces/ILinkWidgetGeneratorType";
import { BaseWidget } from "./../BaseWidget";
import { AdminLinkGenerator } from "./link-types/AdminLinkGenerator";
import { AuditLinkGenerator } from "./link-types/AuditLinkGenerator";
import { InsightsLinkGenerator } from "./link-types/InsightsLinkGenerator";
import { ModuleOverviewLinkGenerator } from "./link-types/ModuleOverviewLinkGenerator";
import { NavigatorLinkGenerator } from "./link-types/NavigatorLinkGenerator";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";

@Component({
    selector: "link-widget",
    templateUrl: "./link-widget.component.html",
    styleUrls: ["./link-widget.component.scss"],
})
export class LinkWidgetComponent extends BaseWidget {
    private microsevicePermissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );

    quickLinks: DashboardQuickLinkModel[];
    contentType = QuickLinkItemContent;

    constructor(
        private _tabService: TabService,
        private _pages: Pages,
        private _permissions: PermissionsService
    ) {
        super();
    }

    load() {
        this.initAvailableTypes();
        super.load();
    }

    openPage(page: string) {
        this._tabService.generateTab(page);
    }

    private initAvailableTypes() {
        let type: ILinkWidgetGeneratorType;
        switch (this.config.customConfig.type) {
            case DashboardWidgetType.AdminPanel:
                type = new AdminLinkGenerator();
                break;
            case DashboardWidgetType.Insights:
                type = new InsightsLinkGenerator();
                break;
            case DashboardWidgetType.ModuleOverview:
                type = new ModuleOverviewLinkGenerator(this.microsevicePermissionService);
                break;
            case DashboardWidgetType.Navigators:
                type = new NavigatorLinkGenerator();
                break;
            case DashboardWidgetType.AuditOverview:
                type = new AuditLinkGenerator();
                break;
        }

        this.quickLinks = type.getTypes(this._permissions.permissions, this._pages);
        this.quickLinks.forEach((link) => {
            link._datauiid = "dashboard-link-" + link.title.replace(/\s+/g, "-").toLowerCase();
        });
    }
}
