import { CommonModule } from "@angular/common";
import { Component, DestroyRef, Input, inject, OnChanges, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormRecord, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFieldOptionModel } from "@app/incident/models/custom-field-option.model";
import { IncidentTypeDisplayModel } from "@app/incident/models/incident-type-display.model";
import { IncidentCustomFieldService } from "@app/incident/services/incident-custom-field.service";
import { FieldType } from "@app/preset/models/field-type.enum";
import {
    CerrixAutocompleteComponent,
    CerrixCheckboxComponent,
    CerrixDatepickerComponent,
    CerrixDatetimePickerComponent,
    CerrixFieldWrapperComponent,
    CerrixInputComponent,
    CerrixInputNumericComponent,
    CerrixSelectComponent,
    CerrixTextareaComponent,
    CerrixFinancialImpactComponent,
    TreeItemNode,
    SelectOptionModel,
    CurrencyInterface,
} from "@cerrix/components";
import { Observable, forkJoin } from "rxjs";

@Component({
    selector: "incident-custom-fields-tab",
    templateUrl: "./incident-custom-fields-tab.component.html",
    styleUrls: ["./incident-custom-fields-tab.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CerrixFieldWrapperComponent,
        CerrixTextareaComponent,
        CerrixInputComponent,
        CerrixInputNumericComponent,
        CerrixAutocompleteComponent,
        CerrixSelectComponent,
        CerrixCheckboxComponent,
        CerrixDatepickerComponent,
        CerrixDatetimePickerComponent,
        CerrixFinancialImpactComponent,
    ],
    providers: [],
})
export class IncidentCustomFieldsTabcontentComponent implements OnChanges {
    @Input() public customFieldsForm: FormRecord;
    @Input() public incidentType: IncidentTypeDisplayModel;

    @Input() public currentSelectedOrganizations: TreeItemNode[] | undefined = undefined;
    @Input() public currentSelectedThirdParties: SelectOptionModel[] | undefined = undefined;
    @Input() public currencies: CurrencyInterface[] | undefined = undefined;

    private _isActive: boolean = false;
    @Input()
    get isActive(): boolean {
        return this._isActive;
    }
    set isActive(newIsActive: boolean) {
        this._isActive = newIsActive;

        this.loadCustomFieldOptions();
    }

    private _refresh: boolean = false;
    @Input()
    get refresh(): boolean {
        return this._refresh;
    }
    set refresh(newRefresh: boolean) {
        this._refresh = newRefresh;
        if (this._refresh) {
            this.cfOptionsLoaded = false;

            this.loadCustomFieldOptions();
        }
    }

    private destroyRef = inject(DestroyRef);
    private customFieldService = inject(IncidentCustomFieldService);

    private loadingOptions: boolean = false;
    private cfOptionsLoaded: boolean = false;
    protected customFieldOptions = new Map<string, CustomFieldOptionModel[]>();

    protected readonly FieldType: typeof FieldType = FieldType;
    protected readonly ObjectKeys = Object.keys;
    protected readonly fullWidthCustomTypes: FieldType[] = [
        FieldType.TextBox,
        FieldType.FinancialImpact,
    ];

    ngOnChanges(changes: SimpleChanges): void {
        if (this.currentSelectedOrganizations === undefined) {
            this.currentSelectedOrganizations = changes.currentSelectedOrganizations?.currentValue;
        }
        if (this.currentSelectedThirdParties === undefined) {
            this.currentSelectedThirdParties = changes.currentSelectedThirdParties?.currentValue;
        }
        if (this.currencies === undefined) {
            this.currencies = changes.currencies?.currentValue;
        }
    }

    private loadCustomFieldOptions() {
        if (!this.cfOptionsLoaded && this._isActive && !this.loadingOptions && this.incidentType) {
            const selectCustomFieldIds = this.getCustomFieldOfTypeSelect();

            if (selectCustomFieldIds.length) {
                const requests: Observable<CustomFieldOptionModel[]>[] = [];

                selectCustomFieldIds.forEach((id) => {
                    const selectedOptions =
                        typeof this.customFieldsForm.controls[id].value === "string"
                            ? [this.customFieldsForm.controls[id].value]
                            : this.customFieldsForm.controls[id].value;
                    requests.push(
                        this.customFieldService
                            .getVisibleCustomFieldOptions(id, selectedOptions)
                            .pipe(takeUntilDestroyed(this.destroyRef))
                    );
                });

                if (requests.length) {
                    this.loadingOptions = true;

                    forkJoin(requests)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                            next: (allOptions) => {
                                allOptions.forEach((options, index) => {
                                    this.customFieldOptions.set(
                                        selectCustomFieldIds[index],
                                        options
                                    );
                                });

                                this.cfOptionsLoaded = true;
                                this.loadingOptions = false;
                            },
                            error: () => {
                                this.loadingOptions = false;
                            },
                        });
                }
            }
        }
    }

    private getCustomFieldOfTypeSelect(): string[] {
        const selectCustomFieldIds: string[] = [];

        if (this.incidentType) {
            this.incidentType.customFields?.forEach((cf) => {
                if (
                    cf.customFieldType === FieldType.SingleSelect ||
                    cf.customFieldType === FieldType.MultiSelect
                ) {
                    selectCustomFieldIds.push(cf.id);
                }
            });
            this.incidentType.incidentTypeSections.forEach((its) => {
                its.customFields?.forEach((cf) => {
                    if (
                        cf.customFieldType === FieldType.SingleSelect ||
                        cf.customFieldType === FieldType.MultiSelect
                    ) {
                        selectCustomFieldIds.push(cf.id);
                    }
                });
            });
        }

        return selectCustomFieldIds;
    }
}
