import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { ProcessCellAttribute } from "@models/processeditor/DiagramData";
import { nameof } from "@methods/jeffs-toolkit";
import { mxClient } from "../mxClient";

export class ProcessEditorHelper {
    static showPrintSettings(shape: any, isEdge: boolean): boolean {
        if (isEdge) {
            // Edges like arrows, links, connectors and flow shapes will be ignored
            return false;
        }

        const ignoredShapes = [ItemEnum.Risk, ItemEnum.Control];
        const e = ItemEnum.fromShape(shape.toLowerCase());
        if (ignoredShapes.indexOf(e) > -1) {
            return false;
        }

        return true;
    }

    static isProcess(shape: any): boolean {
        const e = ItemEnum.fromShape(shape.toLowerCase());
        return e === ItemEnum.Process || e === ItemEnum.PredefinedProcess;
    }

    static createExtraAttributes(attributesModel: ProcessCellAttribute): Element {
        const mxUtils = mxClient.mxUtils;
        const doc = mxUtils.createXmlDocument();
        let extra = doc.createElement("extra");

        extra = this.setExtraAttributes(extra, attributesModel);

        return extra;
    }

    static setExtraAttributes(extra: Element, attributesModel: ProcessCellAttribute): Element {
        // Common all cells will have these items
        extra.setAttribute(
            nameof<ProcessCellAttribute>((x) => x.label),
            attributesModel.label || ""
        );

        extra.setAttribute(
            nameof<ProcessCellAttribute>((x) => x.comment),
            attributesModel.comment || ""
        );

        extra.setAttribute(
            nameof<ProcessCellAttribute>((x) => x.order_nr),
            attributesModel.order_nr || ""
        );

        if (
            ProcessEditorHelper.showPrintSettings(attributesModel.shapeName, attributesModel.isEdge)
        ) {
            const includeInPrintSetting =
                attributesModel.include_in_print != null
                    ? attributesModel.include_in_print
                    : ProcessEditorHelper.isProcess(attributesModel.shapeName)
                    ? "true"
                    : "false";

            extra.setAttribute(
                nameof<ProcessCellAttribute>((x) => x.include_in_print),
                includeInPrintSetting
            );
        }

        return extra;
    }
}
