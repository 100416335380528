export enum TargetModule {
    Default = 0,
    All = 1,
    Risk = 100,
    Kri = 200,
    Event = 300,
    Control = 400,
    ImprovementManagement = 500,
    FindingReport = 700,
    DataProcessing = 800,
    Form = 900,
    BusinessDimension = 1100,
    FrameworkDimension = 1200,
    ThirdParty = 1300,
    Audit = 1400,
    AuditUniverse = 1500,
    Tasks = 1600,
    DocumentTemplates = 3000,
}
