import Highcharts from "highcharts";
import HighchartsExport from "highcharts/modules/exporting";
import HighchartsOfflineExport from "highcharts/modules/offline-exporting";

HighchartsExport(Highcharts);
HighchartsOfflineExport(Highcharts);

import { Component, ViewChild, ElementRef } from "@angular/core";
import {
    Chart,
    SeriesLineOptions,
    PointOptionsObject,
    TooltipFormatterContextObject,
} from "highcharts";
import { RiskScoreChartModel } from "@app/risk/models/RiskScoreChartModel";
import { RiskAppetiteType } from "@app/risk/enums/RiskAppetiteType";

@Component({
    selector: "risk-period-score-chart",
    templateUrl: "./risk-period-score-chart.component.html",
    styleUrls: [
        "./risk-period-score-chart.component.scss",
        "../../../../../shared/sass/risk-shared.scss",
    ],
})
export class RiskPeriodScoreChartComponent {
    @ViewChild("periodScoreChart", { static: true }) periodScoreChart: ElementRef;

    loadedPeriodChart: Chart;
    showNoPeriodData = false;

    loadChart(chartModel: RiskScoreChartModel) {
        if (chartModel.series.length === 0) {
            this.showNoPeriodData = true;
            return;
        } else {
            this.showNoPeriodData = false;
        }

        const chartSeries = this.processSeries(chartModel);
        const periodChartOptions = this.createChart(chartModel, ...chartSeries);

        this.loadedPeriodChart = Highcharts.chart(
            this.periodScoreChart.nativeElement,
            periodChartOptions,
            (chart: Highcharts.Chart) => chart.reflow()
        );
    }

    private createChart(
        chartModel: RiskScoreChartModel,
        ...series: Highcharts.SeriesOptionsType[]
    ): Highcharts.Options {
        const context = this;

        const chartOptions = <Highcharts.Options>{
            chart: {
                type: "line",
            },
            title: {
                text: chartModel.chartTitle,
            },
            xAxis: {
                categories: chartModel.xAxisCategories,
                title: {
                    text: "",
                },
                reversed: false,
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const toolTipContext = this;

                    return context.tooltipFormatter(toolTipContext);
                },
            },
            yAxis: {
                tickInterval: 1,
                min: 1,
                max: 5,
                title: {
                    text: "Score",
                },
            },
            series,
            exporting: {
                enabled: true,
                fallbackToExportServer: false,
            },
            credits: {
                enabled: false,
            },
        };

        return chartOptions;
    }

    private processSeries(chartModel: RiskScoreChartModel): Highcharts.SeriesLineOptions[] {
        const chartSeries = chartModel.series.map(
            (serie) =>
                <SeriesLineOptions>{
                    index: serie.index,
                    name: serie.name,
                    connectNulls: true,
                    data: serie.data.map(
                        (d) =>
                            <PointOptionsObject>{
                                name: d.name,

                                y: d.scoreId,
                                color:
                                    d.riskAppetiteType == null ||
                                    d.riskAppetiteType === RiskAppetiteType.NotSet
                                        ? "grey"
                                        : d.riskAppetiteType === RiskAppetiteType.InLine
                                        ? "green"
                                        : "red",
                                // Store the color indicator value in the fillColor
                                dataLabels: {
                                    enabled: false,
                                    fillColor: d.color,
                                },
                                inLineWithCatalogue:
                                    d.riskAppetiteType == null ||
                                    serie.index >= chartModel.customImpactStartIndex
                                        ? ""
                                        : d.riskAppetiteType === RiskAppetiteType.NotSet
                                        ? "Risk Appetite not set"
                                        : d.riskAppetiteType === RiskAppetiteType.InLine
                                        ? "In line with Risk Appetite"
                                        : "Not in line with Risk Appetite",
                            }
                    ),
                }
        );

        return chartSeries;
    }

    private tooltipFormatter(tooltipContext: TooltipFormatterContextObject) {
        const dataLabels = tooltipContext.point.options["dataLabels"];

        const tooltipBodyContent = `
                <div class="highcharts-tooltip-color-indicator" style="background-color: ${dataLabels["fillColor"]}"></div>
                <span class="highcharts-tooltip-text">${tooltipContext.key}</span>`;

        const tooltipContent = `
        <div class="highcharts-tooltip">
            <div class="highcharts-tooltip-header">${tooltipContext.series.name} (${tooltipContext.x})</div>
            <div class="highcharts-tooltip-text">${tooltipContext.point["inLineWithCatalogue"]}</div>
            <div class="highcharts-tooltip-body">
                ${tooltipBodyContent}
            </div>
        </div>`;

        return tooltipContent;
    }
}
