import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AuditFindingDataService } from "@app/audit-finding/services/audit-finding-data.service";
import {
    AuditFindingLinkedAuditInfoModel,
    AuditFindingLinkedFieldworkStepInfoModel,
} from "@app/audit-finding/shared/data-models/audit-finding-linked-audit-info-model";
import { AuditFieldworkStepDetailConfigModel } from "@app/audit/shared/page-models/audit-fieldwork-step-detail-config-model";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { Pages } from "@constants/pages/Pages";
import { nameof } from "@methods/jeffs-toolkit";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "audit-finding-detail-audit-info",
    templateUrl: "./audit-finding-detail-audit-info.component.html",
    styleUrls: ["./audit-finding-detail-audit-info.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AuditFindingDetailAuditInfoComponent implements OnInit {
    @Input() findingGuid: string;

    linkInfo: AuditFindingLinkedAuditInfoModel;
    tableConfig: GenericListConfig;

    constructor(
        private _ds: AuditFindingDataService,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        this._ds.getLinkedAuditInfo(this.findingGuid).subscribe({
            next: (model: AuditFindingLinkedAuditInfoModel): void => {
                this.linkInfo = model;

                if (this.linkInfo.linkedSteps && this.linkInfo.linkedSteps.length > 0) {
                    this.createStepConfig();
                }
            },
        });
    }

    openAudit() {
        this._tabService.generateTab(this._pages.Audit, this.linkInfo.auditGuid);
    }

    createStepConfig() {
        this.tableConfig = <GenericListConfig>{
            name: "Linked Fieldwork Steps",
            idProp: nameof<AuditFindingLinkedFieldworkStepInfoModel>((x) => x.stepGuid),

            allowEdit: true,
            limitViewTo: 3,
            data: this.linkInfo.linkedSteps,

            fields: [
                {
                    prettyName: "Name",
                    fieldName: nameof<AuditFindingLinkedFieldworkStepInfoModel>((x) => x.stepName),
                    fieldType: GenericListFieldType.Text,
                },
            ],
            editOverride: (row: AuditFindingLinkedFieldworkStepInfoModel) => {
                this._tabService.generateTab(this._pages.AuditFieldworkStep, row.stepGuid, "", <
                    AuditFieldworkStepDetailConfigModel
                >{
                    auditguid: this.linkInfo.auditGuid,
                    stepguid: row.stepGuid,
                });
            },
        };
    }
}
