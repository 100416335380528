import { NgModule, ModuleWithProviders } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

import { CalendarDataService } from "./services/calendar-data.service";
import { CalendarmenuComponent } from "./pages/calendarmenu/calendarmenu.component";
import { CalendarSettingsComponent } from "./pages/settings/calendarsettings.component";
import { CalendarComponent } from "./pages/calendar/calendar.component";

@NgModule({
    declarations: [CalendarmenuComponent, CalendarSettingsComponent, CalendarComponent],
    exports: [CalendarmenuComponent],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class CerrixCalendarModule {
    static forRoot(): ModuleWithProviders<CerrixCalendarModule> {
        return {
            ngModule: CerrixCalendarModule,
            providers: [CalendarDataService],
        };
    }
}
