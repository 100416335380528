<div class="modal-header">
    <h5 class="modal-title text-truncate">
        {{ !Document.ID && !Document.IsNew ? "Add document" : "Edit " + Document.Name }}
    </h5>
</div>
<div class="modal-body">
    <ul class="nav nav-tabs">
        <li class="nav-item {{ selectedTab == 'details' ? 'active' : '' }}">
            <button
                class="nav-link {{ selectedTab == 'details' ? 'active' : '' }}"
                data-toggle="tab"
                (click)="toggle('details')"
            >
                Document details
            </button>
        </li>
        <li
            class="nav-item {{ selectedTab == 'history' ? 'active' : '' }}"
            *ngIf="Document && Document.ID > 0"
        >
            <button
                class="nav-link {{ selectedTab == 'history' ? 'active' : '' }}"
                data-toggle="tab"
                (click)="toggle('history')"
            >
                History
            </button>
        </li>
    </ul>
    <div class="tab-content">
        <div
            class="tab-pane {{ selectedTab == 'details' ? 'active' : '' }}"
            id="documentDetail"
            #documentDetail
        >
            <form novalidate [formGroup]="documentForm">
                <div class="row">
                    <div class="form-group col">
                        <label>File{{ !Document.ID ? "(s)" : "" }}</label>
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                formControlName="file"
                                [value]="
                                    fileInputCount > 1 ? fileInputCount + ' files' : fileInputText
                                "
                                [title]="fileInputText"
                                inputTooltip
                            />
                            <input
                                type="file"
                                id="fileUpload"
                                [accept]="AllowedFileExtensions"
                                [multiple]="!Document.ID && !SingleFileUpload"
                                [hidden]="true"
                                (change)="updateFiles()"
                                #fileInput
                            />
                            <div
                                *ngIf="(!Document.ID && AllowAdd) || (Document.ID > 0 && AllowEdit)"
                                class="input-group-append"
                            >
                                <button
                                    data-uiid="documentmanagerdetails-browsebutton"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    (click)="openFileSelect()"
                                >
                                    <i
                                        class="fas fa-{{
                                            fileInputText.length === 0 ? 'folder-plus' : 'sync-alt'
                                        }}"
                                        aria-hidden="true"
                                    ></i>
                                    <span class="d-none d-lg-inline ms-2">Browse</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col" *ngIf="ShowType && documentTypes">
                        <label for="documentTypeSelect">Document type</label>
                        <ng-select
                            data-uiid="documentmanagerdetails-documenttype"
                            id="documentTypeSelect"
                            name="documentTypeSelect"
                            [items]="documentTypes"
                            formControlName="DocumentType"
                            bindLabel="Name"
                            bindValue="ID"
                            [closeOnSelect]="true"
                            [multiple]="false"
                        >
                        </ng-select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col" *ngIf="ShowDescription">
                        <label for="descriptionInput">Description</label>
                        <cerrix-textarea
                            errorLabel="Description"
                            dataUiid="documentmanagerdetails-description"
                            identifier="descriptionInput"
                            [rows]="10"
                            formControlName="Description"
                        ></cerrix-textarea>
                    </div>
                </div>

                <div class="row" *ngIf="showPdfButton">
                    <div class="offset-sm-3 col-sm-9">
                        <a class="pdfLink" (click)="openDocumentViewer()"
                            >Open PDF in document viewer</a
                        >
                    </div>
                </div>
            </form>
        </div>
        <div
            class="tab-pane {{ selectedTab == 'history' ? 'active' : '' }}"
            id="history"
            #history
            *ngIf="Document && Document.ID > 0"
        >
            <document-history [guid]="Document.Guid"></document-history>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="justify-start">
        <label *ngIf="validationModel && validationModel.isValid" style="color: #1bb642">
            <i class="fa-regular fa-circle-check" style="color: #1bb642"></i> File is valid</label
        >
        <label
            *ngIf="validationModel && validationModel.isValid != null && !validationModel.isValid"
            style="color: #c91818"
        >
            <i class="fa-regular fa-circle-xmark" style="color: #c91818"></i> File is invalid</label
        >
    </div>
    <button *ngIf="showValidationBtn" class="btn" (click)="validateClick(Document)">
        Validate
    </button>
    <button
        data-uiid="documentmanagerdetails-adddocumentsbutton"
        type="button"
        *ngIf="(!Document.ID && AllowAdd) || (Document.ID > 0 && AllowEdit)"
        class="btn btn-primary"
        [disabled]="this.disabled || this.fileInputText.length === 0"
        (click)="applyChange()"
    >
        {{ Document.ID || Document.IsNew ? "Apply changes" : "Add document(s)" }}
    </button>
    <button class="btn btn-secondary" (click)="closeClick()">Cancel</button>
</div>
