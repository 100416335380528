import { Component } from "@angular/core";
import { TargetModule } from "@enums/document/TargetModule";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { DocumentTemplatesPermissionModel } from "@models/permissions/DocumentTemplatesPermissionModel";
import { PermissionsService } from "@services/permissions/PermissionsService";

@Component({
    selector: "document-templates-details",
    templateUrl: "./document-templates-details.component.html",
    styleUrls: ["./document-templates-details.component.scss"],
})
export class DocumentTemplatesDetailsComponent {
    protected tab: TabModel;

    protected permissions: DocumentTemplatesPermissionModel;

    protected TargetModule = TargetModule;

    constructor(permissionsService: PermissionsService) {
        this.permissions = permissionsService.permissions.DocumentTemplates;
    }

    protected onSaved() {
        this.tab.refresh();
    }
}
