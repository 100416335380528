import { ApiKeyModule } from "@app/user/shared/api-key/api-key.module";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { UserDetailComponent } from "./user-detail/user-detail.component";
import { UserOverviewComponent } from "./user-overview/user-overview.component";
import { UserMaintenanceService } from "./services/user-maintenance.service";
import { UserAuditComponent } from "./user-detail/user-audit/user-audit.component";
import { UserHistoryComponent } from "./user-detail/user-history/user-history.component";
import { UserInfoComponent } from "./user-detail/user-info/user-info.component";
import { UserLoginsComponent } from "./user-detail/user-logins/user-logins.component";
import { UserRolesComponent } from "./user-detail/user-roles/user-roles.component";
import { UserRoleGroupComponent } from "./user-role-group/user-role-group.component";
import { UserRoleGroupService } from "./services/user-role-group.service";
import { RoleAssignComponent } from "./role-assign/role-assign.component";
import { RightDescriptionComponent } from "./right-description/right-description.component";
import { UserNotificationSettingsModule } from "../shared/notification-settings/user-notification-settings.module";

@NgModule({
    declarations: [
        UserDetailComponent,
        UserOverviewComponent,
        UserRoleGroupComponent,

        UserAuditComponent,
        UserHistoryComponent,
        UserInfoComponent,
        UserLoginsComponent,
        UserRolesComponent,
        RoleAssignComponent,
        RightDescriptionComponent,
    ],
    exports: [
        UserAuditComponent,
        UserInfoComponent,
        UserLoginsComponent,
        UserRolesComponent,
        RoleAssignComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        ApiKeyModule,
        UserNotificationSettingsModule,
    ],
})
export class UserMaintenanceModule {
    static forRoot(): ModuleWithProviders<UserMaintenanceModule> {
        return {
            ngModule: UserMaintenanceModule,
            providers: [UserMaintenanceService, UserRoleGroupService],
        };
    }
}
