import { Component, OnInit, Input } from "@angular/core";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { HistoryModel, HistoryType } from "@models/history/HistoryModel";
import { LocalizationService } from "..";
import { isGuid } from "@methods/uniqueMethods";
import { HistoryMethods } from "@methods/HistoryMethods";

@Component({
    selector: "app-history-overview",
    templateUrl: "./history-overview.component.html",
    styleUrls: ["./history-overview.component.scss"],
})
export class HistoryOverviewComponent implements OnInit {
    @Input() historyDataService: HistoryDataService;
    @Input() Id: number | string;
    @Input() ExtraParam: any;
    @Input() useOldHistory = false;
    @Input() useLazyLoading = true;
    @Input() label = "History Overview";

    isLoading: boolean;
    isValidObject = false;

    historyModels: HistoryModel[];
    displayedColumn: string[] = ["Column", "OldValue", "NewValue"];
    HistoryType = HistoryType;

    constructor(private localizationService: LocalizationService) {}

    ngOnInit() {
        if (!this.useLazyLoading) {
            this.loadHistory();
        }
    }

    loadHistory() {
        this.isValidObject = this.Id && (+this.Id > 0 || isGuid(this.Id.toString()));
        if (this.isValidObject) {
            this.isLoading = true;
            this.historyDataService.getHistory(this.Id, this.ExtraParam).subscribe(
                (history) => {
                    this.historyModels = history;
                },
                () => {
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
        } else {
            this.isLoading = false;
        }
    }

    loadChanges(historyModel: HistoryModel) {
        if (historyModel.CombinedChanges) {
            return;
        }

        // Workaround for long long rounding
        const historyId = !this.useOldHistory ? historyModel.ID : historyModel.CerrixMutationDate;

        this.historyDataService
            .getHistoryChanges(this.Id, historyId, this.ExtraParam)
            .subscribe((changes) => {
                const combinedChanges = HistoryMethods.processChanges(
                    changes,
                    this.localizationService
                );
                historyModel.CombinedChanges = combinedChanges;
            });
    }
}
