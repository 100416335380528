import { ActionEnum, Actions } from "./actions";

export function createShortcuts(
    actions: Actions
): { keys: string; action: ActionEnum; isGlobalEvent?: boolean }[] {
    return [
        {
            keys: actions.getShortcut(ActionEnum.SELECT_ALL).toKeyString(),
            action: ActionEnum.SELECT_ALL,
        },
        {
            keys: actions.getShortcut(ActionEnum.UNSELECT_ALL).toKeyString(),
            action: ActionEnum.UNSELECT_ALL,
        },
        {
            keys: actions.getShortcut(ActionEnum.UNDO).toKeyString(),
            action: ActionEnum.UNDO,
        },
        {
            keys: actions.getShortcut(ActionEnum.REDO).toKeyString(),
            action: ActionEnum.REDO,
        },
        {
            keys: actions.getShortcut(ActionEnum.SELECT_VERTICES).toKeyString(),
            action: ActionEnum.SELECT_VERTICES,
        },
        {
            keys: actions.getShortcut(ActionEnum.SELECT_EDGES).toKeyString(),
            action: ActionEnum.SELECT_EDGES,
        },
        {
            keys: actions.getShortcut(ActionEnum.EDIT).toKeyString(),
            action: ActionEnum.EDIT,
        },
        {
            keys: actions.getShortcut(ActionEnum.TOGGLE_LOCK).toKeyString(),
            action: ActionEnum.TOGGLE_LOCK,
        },
        {
            keys: actions.getShortcut(ActionEnum.TOGGLE_GRID).toKeyString(),
            action: ActionEnum.TOGGLE_GRID,
        },
        {
            keys: actions.getShortcut(ActionEnum.RESET_VIEW).toKeyString(),
            action: ActionEnum.RESET_VIEW,
        },
        {
            keys: actions.getShortcut(ActionEnum.GROUP_CELLS).toKeyString(),
            action: ActionEnum.GROUP_CELLS,
        },
        {
            keys: actions.getShortcut(ActionEnum.UNGROUP_CELLS).toKeyString(),
            action: ActionEnum.UNGROUP_CELLS,
        },
        {
            keys: actions.getShortcut(ActionEnum.TO_FRONT).toKeyString(),
            action: ActionEnum.TO_FRONT,
        },
        {
            keys: actions.getShortcut(ActionEnum.TO_BACK).toKeyString(),
            action: ActionEnum.TO_BACK,
        },
        {
            keys: actions.getShortcut(ActionEnum.REMOVE_CELLS).toKeyString(),
            action: ActionEnum.REMOVE_CELLS,
        },
        {
            keys: actions.getShortcut(ActionEnum.COPY).toKeyString(),
            action: ActionEnum.COPY,
        },
        {
            keys: actions.getShortcut(ActionEnum.PASTE).toKeyString(),
            action: ActionEnum.PASTE,
        },
        {
            keys: actions.getShortcut(ActionEnum.CUT).toKeyString(),
            action: ActionEnum.CUT,
        },
        {
            keys: actions.getShortcut(ActionEnum.PRINT).toKeyString(),
            action: ActionEnum.PRINT,
            isGlobalEvent: true,
        },
        {
            keys: actions.getShortcut(ActionEnum.SAVE).toKeyString(),
            action: ActionEnum.SAVE,
            isGlobalEvent: true,
        },
        {
            keys: actions.getShortcut(ActionEnum.IMPORT).toKeyString(),
            action: ActionEnum.IMPORT,
            isGlobalEvent: true,
        },
    ];
}
