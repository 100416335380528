<div
    class="popover-wrapper"
    [ngClass]="{
        'popover-completed-step': step.State == stepStateEnum.Completed
    }"
>
    <div class="popover-title bold-text" data-uiid="new-eff-popover-step-name">{{ step.Name }}</div>
    <div class="popover-content">
        <div
            class="table-row"
            *ngIf="step.State != stepStateEnum.Completed"
            data-uiid="new-eff-popover-assignees"
        >
            <div class="table-cell bold-text">Assignee(s)</div>
            <div class="table-cell">
                <div class="d-flex flex-column assigned-persons-wrapper">
                    <div *ngFor="let user of step.AssignedUsers">
                        {{ user }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="table-row"
            *ngIf="step.State == stepStateEnum.Completed"
            data-uiid="new-eff-popover-completed-by"
        >
            <div class="table-cell bold-text">Completed by</div>
            <div class="table-cell">{{ step.CompletedBy }}</div>
        </div>

        <div
            class="table-row"
            *ngIf="step.State == stepStateEnum.Completed"
            data-uiid="new-eff-popover-date-completed"
        >
            <div class="table-cell bold-text">Date completed</div>
            <div class="table-cell">{{ step.CompletedOn }}</div>
        </div>
        <div class="table-row" data-uiid="new-eff-popover-due-date">
            <div class="table-cell bold-text">Due date</div>
            <div class="table-cell">{{ step.DueDate }}</div>
        </div>
    </div>
</div>
