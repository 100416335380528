export class KeyValuePair {
    key: number;
    value: string;

    constructor(k: number, v: string) {
        this.key = k;
        this.value = v;
    }
}

export class KeyValue<T, TVal> {
    Key: T;
    Value: TVal;

    constructor(k: T, v: TVal) {
        this.Key = k;
        this.Value = v;
    }
}