import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { isGuid } from "@methods/uniqueMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ControlPermissionModel } from "../models/ControlPermissionsModel";
import { ControlDataService } from "../services/control.data.service";
import { ControlTestResultComponent } from "./test-result/control-test-result.component";
import { ControlDetailMainComponent } from "./main/control-detail-main.component";
import { ControlRiskComponent } from "./control-risks/control-risks.component";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { ControlModel } from "../models/ControlModel";
import { SimpleTestingType } from "../testing/simple/enums/SimpleTestingType";
import { TabService } from "@services/tabs/TabService";
import { Observable, of } from "rxjs";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { ControlMoiComponent } from "./control-mois/control-mois.component";
import { ControlEventComponent } from "./control-events/control-events.component";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { DocumentModel } from "@models/documents/documentmodel";
import { HttpResponse } from "@angular/common/http";
import { ControlDatamanagementComponent } from "./control-datamanagement/control-datamanagement.component";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { TargetModule } from "@enums/document/TargetModule";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { closeDirtyPageCheck, refreshDirtyPageCheck } from "@methods/CommonMethods";
import { ControlConfigModel } from "../models/ControlConfigModel";
import { ControlThirdPartyComponent } from "./control-thirdparty/control-thirdparty.component";
import { ControlFormsComponent } from "./control-forms/control-forms.component";
import { SettingsDataService } from "@services/http/SettingsDataService";

@Component({
    selector: "control-detail",
    templateUrl: "./control-detail.component.html",
    styleUrls: ["./control-detail.component.scss"],
})
export class ControlDetailComponent implements OnInit {
    @ViewChild("detailMain") detailMain: ControlDetailMainComponent;
    @ViewChild("testResult") testResult: ControlTestResultComponent;
    @ViewChild("controlRisks") controlRisks: ControlRiskComponent;
    @ViewChild("controlMoisComponent") controlMoisComponent: ControlMoiComponent;
    @ViewChild("controlEvents") controlEvents: ControlEventComponent;
    @ViewChild(HistoryOverviewComponent) historyOverviewComponent;
    @ViewChild("controlDmComponent")
    controlDmComponent: ControlDatamanagementComponent;
    @ViewChild("controlTpComponent")
    controlTpComponent: ControlThirdPartyComponent;
    @ViewChild("formsComponent")
    formsComponent: ControlFormsComponent;

    tab: TabModel;
    id: string;
    controlGuid: string;
    permissions: ControlPermissionModel;
    previousOrganizationId: number;
    controlModel: ControlModel;

    cmMoiType = MoiTypes.Control_Moi;

    formDirty = true;
    saving = false;

    simpleTestingTypes = SimpleTestingType;

    documents: DocumentModel[];
    isDocumentDirty = false;
    isHyperlinksDirty = false;
    targetModule = TargetModule.Control;
    requiresMonitoringOnInit = false;
    protected requiresExecutionOnInit = false;

    controlConfig: ControlConfigModel;

    hyperlinksMenuItemId = "menuItemHyperlinks";

    constructor(
        private route: ActivatedRoute,
        public _controlDS: ControlDataService,
        private _toastr: ToastrService,
        private _pages: Pages,
        private _tabService: TabService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit(): Promise<void> {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;
        this.setupInit();
    }

    setupInit(): void {
        const isExisting = this.id && isGuid(this.id);
        this.controlGuid = isExisting ? this.id : null;

        this._controlDS.getPermissions(this.controlGuid).subscribe(
            async (permissions) => {
                this.permissions = permissions;

                if (!this.permissions.CanView) {
                    this.tab.close(false);
                    return;
                }

                this.getModel();
            },
            () => {
                this.tab.close(false);
            }
        );
    }

    getModel(): void {
        const modelCall: Observable<ControlModel> =
            this.controlGuid && this.controlGuid.length > 0
                ? this._controlDS.getControl(this.controlGuid)
                : of(this.getNewControlModel());

        modelCall.subscribe((x) => {
            this.controlModel = new ControlModel(JSON.stringify(x));
            this.requiresMonitoringOnInit = this.controlModel.RequiresMonitoring;
            this.requiresExecutionOnInit = this.controlModel.RequiresExecution;

            this.tab.showLoader = false;

            this.tab.beforeClose = (checkOnly: boolean) => {
                const unsavedChanges =
                    this.detailMain.checkDirty() || this.isDocumentDirty || this.isHyperlinksDirty;
                return closeDirtyPageCheck(
                    this.tab,
                    this._promptService,
                    checkOnly,
                    !unsavedChanges
                );
            };
            this.tab.beforeRefresh = (checkOnly: boolean) => {
                const unsavedChanges = !this.saving && this.detailMain.checkDirty();
                return refreshDirtyPageCheck(
                    this.tab,
                    this._promptService,
                    checkOnly,
                    !unsavedChanges
                );
            };
        });
    }

    getNewControlModel(): ControlModel {
        const controlModel = new ControlModel();

        this.controlConfig = <ControlConfigModel>this.tab.config;
        if (this.controlConfig) {
            const orgID = this.controlConfig.organizationId;
            if (orgID) {
                controlModel.Organization = +orgID;
            }

            const bdIDs = this.controlConfig.businessIds;
            if (bdIDs) {
                controlModel.BusinessDimensions = bdIDs;
            }

            const fdIDs = this.controlConfig.frameworkIds;
            if (fdIDs) {
                controlModel.FrameworkDimensions = fdIDs;
            }

            const riskID = this.controlConfig.riskId;
            if (riskID) {
                controlModel.RiskIdList = [+riskID];
            }

            const name = this.controlConfig.name;
            if (name) {
                controlModel.Name = name;
            }

            const description = this.controlConfig.description;
            if (description) {
                controlModel.Description = description;
            }

            const processedDocumentControlId = this.controlConfig.processedDocumentControlId;
            if (processedDocumentControlId) {
                controlModel.ProcessedDocumentControlId = processedDocumentControlId;

                const reasonUpdate = this.controlConfig.reasonUpdate;
                if (reasonUpdate) {
                    controlModel.ReasonUpdate = reasonUpdate;
                }
            }
        }

        return controlModel;
    }

    checkDocumentsDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, "menuItemDocuments", true);
        this.isDocumentDirty = true;
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.isHyperlinksDirty = true;
    }

    handleMenuItemClick(menuItem: TabMenuItem): void {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "menuTestResultOverview":
                    this.testResult.load();
                    break;
                case "menuItemRisks":
                    this.controlRisks.load(this.controlModel, this.permissions, this);
                    break;
                case "menuItemMoi":
                    this.controlMoisComponent.load(true, this);
                    break;
                case "menuItemEvents":
                    this.controlEvents.load();
                    break;
                case "menuItemHistory":
                    this.historyOverviewComponent.loadHistory();
                    break;
                case "menuItemDocuments":
                    this.loadDocuments();
                    break;
                case this.hyperlinksMenuItemId:
                    this.loadHyperlinks();
                    break;
                case "menuItemDm":
                    this.controlDmComponent.load(menuItem);
                    break;
                case "menuItemForms":
                    this.formsComponent.load();
                    break;
                case "menuItemTp":
                    this.controlTpComponent.load(menuItem);
                    break;
            }
        }
    }

    loadDocuments(): void {
        if (!this.documents && this.id && isGuid(this.id.toString())) {
            this._controlDS.getDocuments(this.id.toString()).subscribe((documents) => {
                this.documents = documents;
                this.controlModel.Documents = documents;
                this.detailMain.backupModel.Documents = documents;
            });
        } else {
            this.documents = [];
        }
    }

    loadHyperlinks(): void {
        if (!this.controlModel.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this._controlDS.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.controlModel.Hyperlinks = hyperlinks;
                this.detailMain.backupModel.Hyperlinks = hyperlinks;
            });
        }
    }

    save() {
        if (!this.detailMain) {
            return;
        }

        if (!this.detailMain.backupModel.IsShared && this.detailMain.controlModel.IsShared) {
            this._promptService
                .confirm(
                    "Share Control",
                    "This control can be linked to objects of other organizations. Are you sure you want to make this control shared?"
                )
                .onConfirm()
                .subscribe((x) => {
                    this.savecontinuation();
                });

            return;
        } else {
            this.savecontinuation();
        }
    }

    async savecontinuation(): Promise<void> {
        //When organization is changed, remove automatic link with risk (if made from risk screen)
        if (this.controlConfig && this.controlConfig.riskId) {
            if (this.controlConfig.organizationId != this.controlModel.Organization) {
                this.controlModel.RiskIdList.forEach((item, index) => {
                    if (item === this.controlConfig.riskId)
                        this.controlModel.RiskIdList.splice(index, 1);
                });
            }
        }

        if (this.detailMain.controlFormGroup.valid) {
            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            await this._controlDS
                .storeControl(this.detailMain.controlModel)
                .toPromise()
                .then((value: HttpResponse<any>) => {
                    savingPrompt.close();
                    this._toastr.success("", "Update completed");
                    this.tab.id = value.body.toString();
                    this.saving = true;
                    if (this.tab.parent && this.tab.parent.lookupname === this._pages.Controls) {
                        this.tab.parent.refresh();
                    }

                    if (this.controlModel.ProcessedDocumentControlId > 0) {
                        this._tabService.refresh(this._pages.ProcessedDocumentDetail);
                    }

                    this.tab.refresh();
                })
                .catch((error) => {
                    savingPrompt.close();
                });
        } else {
            FormValidationHelper.markAllAsTouched(this.detailMain.controlFormGroup);

            const validationErrors = FormValidationHelper.getFormControlErrors(
                this.detailMain.controlFormGroup
            );
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
        }
    }

    delete(): void {
        this._controlDS.deleteControl(this.controlModel.Guid).subscribe(
            (value) => {
                if (value && value.length > 0) {
                    this._toastr.error(this.toCustomizedMessage(value), "", { enableHtml: true });
                } else {
                    this._toastr.success("Control deleted");
                    if (this.tab.parent && this.tab.parent.lookupname === this._pages.Controls) {
                        this.tab.parent.refresh();
                    }
                    this.tab.close(false);
                }
            },
            (error) => {
                this._toastr.error("", error || error.Message);
            }
        );
    }

    openSimpleTesting(type: SimpleTestingType): void {
        if (type === SimpleTestingType.DesignImplementation) {
            this.createTab(this._pages.DiTesting);
        } else if (type === SimpleTestingType.Effectiveness) {
            this.createTab(this._pages.EffectivenessTesting);
        }
    }

    openAdvOverview(): void {
        if (!this.permissions.CanOpenAdvEff) {
            return;
        }

        this.createTab(this._pages.ControlsTestingAdvEffControlOverview);
    }

    openControlExecution() {
        const identifier = +this.controlModel.Identifier;
        this._tabService.generateTab(
            this._pages.ControlExecutionOverview,
            this.controlModel.Guid,
            `(C-exec) ${identifier} - ${this.controlModel.Name}`
        );
    }

    private createTab(page: string) {
        let prefix: string = "";
        switch (page) {
            case this._pages.EffectivenessTesting:
            case this._pages.ControlsTestingAdvEffControlOverview:
                prefix = "Eff";
                break;
            case this._pages.DiTesting:
                prefix = "D&I";
        }

        this._tabService.generateTab(
            page,
            this.controlModel.Guid,
            `(C - ${prefix}) ${this.controlModel.Identifier} - ${this.controlModel.Name}`
        );
    }

    private toCustomizedMessage(values: string[]): string {
        // the value is coming from the backend and should have 1 line with a description and 1 or more lines with a description
        // of the entity the control is linked in
        // 0: Control is linked in
        // 1: description of 1st entity the control is linked in
        // 2: description of 2nd entity the control is linked in
        // 3: description of 3rd entity the control is linked in

        if (values?.length > 1) {
            // show the items in a list
            let messageList = "<ul>";
            for (let rowIndex = 1; rowIndex < values.length; rowIndex++) {
                messageList = messageList + `<li>${values[rowIndex]}</li>`;
            }
            messageList = messageList + "</ul>";
            return `<div>${values[0]}</div>${messageList}`;
        }
        return `<div>${values.toString()}</div>`;
    }
}
