import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NotificationSettingsService } from "@app/mailmessages/service/notification-settings.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { toPromise } from "@methods/CommonMethods";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { BaseUserModel } from "../models/base-user-model";

@Component({
    selector: "user-notification-settings",
    templateUrl: "./user-notification-settings.component.html",
    styleUrls: ["./user-notification-settings.component.scss"],
})
export class UserNotificationSettingsComponent implements OnInit {
    @Input() parentTab: TabModel;
    @Input() userModel: BaseUserModel;
    @Input() showSave: boolean = true;

    notificationOptions: IdNameCombination[] = [{ ID: 1, Name: "Email" }];
    slackStatusText: string = "Slack not connected";

    constructor(
        private settingsService: NotificationSettingsService,
        private toastr: ToastrService
    ) {}

    async ngOnInit() {
        if (!this.parentTab.showLoader) {
            this.parentTab.showLoader = true;
        }

        if (this.userModel.linkedToSlack) {
            this.notificationOptions = [
                { ID: 1, Name: "Email" },
                { ID: 2, Name: "Slack" },
                { ID: 3, Name: "Both" },
            ];

            this.slackStatusText = "Slack connected";
        }

        this.parentTab.showLoader = false;
    }

    async saveNotificationPreference() {
        await toPromise(
            this.settingsService.storePreference(
                this.userModel.guid,
                this.userModel.notificationPreference
            )
        );
        this.toastr.success("Notification preference saved successfully.");
    }

    async syncUser() {
        await toPromise(this.settingsService.syncUser(this.userModel.guid));
        this.toastr.success("User synced successfully.");
    }
}
