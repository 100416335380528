import { Component, ElementRef, ViewChild } from "@angular/core";
import { ControlsWidgetsDataService } from "../../../services/controls-widgets.data.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { DesignImplementationDashboardModel } from "../../../shared/models/DesignImplementationDashboardModel";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { BaseHighchartWidget } from "../BaseHighchartWidget";
import { nameof } from "@methods/jeffs-toolkit";
import { ControlInfoModel } from "@app/controls/models/ControlInfoModel";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";

@Component({
    selector: "controls-designimplementation-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class DesignImplementationWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private controlsWidgetsDataService: ControlsWidgetsDataService,
        private pages: Pages,
        tabService: TabService,
        sd: StandingdataDataService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            nameof<ControlInfoModel>((x) => x.organizationId);
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            nameof<ControlInfoModel>((x) => x.businessDimensionIds);
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.FrameworkDimension] =
            nameof<ControlInfoModel>((x) => x.frameworkDimensionIds);
    }

    protected async getChartConfig() {
        const filters = this.getFilters();
        const data = await this.controlsWidgetsDataService
            .getDesignImplementationWidgetInfo(filters)
            .toPromise();

        this.config.noData =
            !data || data.every((x) => x.designcount === 0 && x.implementationcount === 0);
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            {
                name: "Design score",
                type: "column",
                data: this.getDataItems(data, true),
            },
            {
                name: "Implementation score",
                type: "column",
                data: this.getDataItems(data, false),
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);
        config.legend = { enabled: true };
        config.xAxis = {
            type: "category",
            categories: this.getCategories(data),
        };

        config.yAxis = {
            allowDecimals: false,
            title: {
                text: "Number of controls",
            },
        };

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        config.plotOptions.column = { grouping: true };

        return config;
    }

    getDataItems(data: DesignImplementationDashboardModel[], isDesignCount: boolean) {
        return data.map((element) =>
            isDesignCount ? element.designcount : element.implementationcount
        );
    }

    getCategories(data: DesignImplementationDashboardModel[]) {
        return data.map((element) => element.name);
    }

    protected async openWorkspace(ev) {
        const category = ev.point.category;
        let type;

        switch (ev.point.series.name) {
            case "Design score":
                type = nameof<ControlInfoModel>((c) => c.designScore);
                break;
            case "Implementation score":
                type = nameof<ControlInfoModel>((c) => c.implementationScore);
                break;
        }

        const searchKeywords: SearchKeyword[] = [this.getSearchKeyword(type, category)];

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.ControlOverview, preset);
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.ControlOverview, preset);
    }
}
