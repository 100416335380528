<div class="tucked-menu" [class.initial-menu]="!isSubSection" *ngIf="menuItems">
    <div
        class="tucked-group"
        *ngFor="let item of menuItems"
        [class.expanded-group]="item | tabchildselected: mainMenu.activeMenuItem"
        [hidden]="item.hidden"
    >
        <div
            class="tucked-item"
            [class.clickable]="
                item.clickable ||
                (item.children &&
                    item.children.length > 0 &&
                    item.children[0].identifier !== mainMenu.activeMenuItem)
            "
            [class.active]="mainMenu.activeMenuItem === item.identifier"
            (click)="clickMenuItem(item)"
            (mouseenter)="mouseEnteredItem($event, item)"
            (mouseleave)="mouseLeftItem()"
        >
            <cerrix-icon-viewer [icon]="item.iconClass" defaultColor="white"></cerrix-icon-viewer>
        </div>
        <div
            class="tucked-children"
            *ngIf="
                item.children &&
                item.children.length > 0 &&
                (item | tabchildselected: mainMenu.activeMenuItem)
            "
        >
            <tuckedmenu
                [mainMenu]="mainMenu"
                [parent]="item"
                [menuItems]="item.children"
                [isSubSection]="true"
            ></tuckedmenu>
        </div>
    </div>
</div>

<div
    class="hover-menu"
    [style.top]="hoverTop + 'px'"
    *ngIf="hoverItem && (showHover || hoverMenuKeepOpen)"
    (mouseenter)="mouseEnteredHoverMenu()"
    (mouseleave)="mouseLeftHoverMenu()"
>
    <div
        class="hover-menu-content"
        [class.active]="hoverItem.identifier === mainMenu.activeMenuItem"
        [class.expanded-group]="
            isSubSection || (hoverItem | tabchildselected: mainMenu.activeMenuItem)
        "
        [class.clickable]="
            showHover &&
            (hoverItem.clickable ||
                (hoverItem.children &&
                    hoverItem.children.length > 0 &&
                    hoverItem.children[0].identifier !== mainMenu.activeMenuItem))
        "
    >
        <ng-container
            [ngTemplateOutlet]="tooltipContent"
            *ngIf="
                !hoverItem.children ||
                hoverItem.children.length === 0 ||
                (hoverItem | tabchildselected: mainMenu.activeMenuItem)
            "
        ></ng-container>
        <ng-container
            [ngTemplateOutlet]="sidemenuContent"
            *ngIf="
                hoverItem.children &&
                hoverItem.children.length > 0 &&
                !(hoverItem | tabchildselected: mainMenu.activeMenuItem)
            "
        ></ng-container>
    </div>
</div>

<ng-template #tooltipContent>
    <div class="hover-tooltip">
        <label>{{ hoverItem.name }}</label>
    </div>
</ng-template>

<ng-template #sidemenuContent>
    <div class="hover-sidemenu">
        <ng-container [ngTemplateOutlet]="tooltipContent"></ng-container>
        <div class="children">
            <div
                class="child-item"
                *ngFor="let child of hoverItem.children"
                (click)="clickMenuItem(child)"
            >
                <div class="child-info">
                    <div class="icon">
                        <cerrix-icon-viewer
                            [icon]="child.iconClass"
                            defaultColor="white"
                        ></cerrix-icon-viewer>
                    </div>
                    <span> {{ child.name }} </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>
