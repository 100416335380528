import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'byteArray' })
export class ByteArray implements PipeTransform {
  transform(str: string): number[] {
    let array = [];

    for (let i = 0; i < str.length; ++i) {
        const code = str.charCodeAt(i);
        array = array.concat([code & 0xff, code / 256 >>> 0]);
    }

    return array;
  }
}
