import { Component, Input, OnInit } from "@angular/core";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";

@Component({
    selector: "cerrix-logo",
    templateUrl: "./cerrix-logo.component.html",
    styleUrls: ["./cerrix-logo.component.scss"],
})
export class CerrixLogoComponent implements OnInit {
    @Input() retrieveLogo: boolean = true;
    @Input() customerLogo: string;

    @Input() width = 250;
    @Input() height = 50;

    showCustomerLogo = false;

    constructor(private _settingDS: SettingsDataService) {}

    ngOnInit() {
        if (this.retrieveLogo) {
            this._settingDS.getSetting(ApplicationSettings.CustomerLogo).subscribe((setting) => {
                this.customerLogo = setting.SerializedOption;
                this.startCrossfade();
            });
        } else {
            this.startCrossfade();
        }
    }

    private startCrossfade() {
        if (this.customerLogo) {
            setTimeout(() => {
                this.showCustomerLogo = !this.showCustomerLogo;
            }, 15000);
        }
    }
}
