<div class="detail-page-card">
    <div class="detail-page-card-title">Comments</div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="addClick()" *ngIf="permissions.canAddComment">
            <i class="fas fa-plus-circle"></i>
            <span>Add new comment</span>
        </button>
        <button
            class="btn btn-raised red"
            (click)="deleteClick(selectedComment)"
            [disabled]="!selectedComment || !selectedComment.canDelete"
        >
            <i class="fas fa-minus-circle"></i>
            <span>Delete comment</span>
        </button>
    </div>

    <div class="detail-page-card-body">
        <div class="fancy-loader submodule my-4" *ngIf="isLoading"></div>

        <div *ngIf="!isLoading">
            <div class="no-comments" *ngIf="!comments || comments.length == 0">
                There are no comments available yet.
            </div>

            <div class="comments" *ngIf="comments && comments.length > 0">
                <div class="row headers">
                    <div class="col-4">Comment</div>
                    <div class="col-2">Progress</div>
                    <div class="col">Type</div>
                    <div class="col">Feasibility</div>
                    <div class="col-2">User</div>
                    <div class="col">Date</div>
                </div>

                <div
                    class="row comment-row"
                    *ngFor="let comment of scopedComments"
                    [class.comment-deleted]="comment.isDeleted"
                    [class.highlight]="selectedComment && selectedComment.id == comment.id"
                    (click)="selectedComment = comment"
                    (dblclick)="editClick(comment)"
                >
                    <div
                        class="col-4"
                        [matTooltip]="comment.comment ? comment.comment : ''"
                        matTooltipPosition="after"
                    >
                        <i style="font-size: 0.8em" *ngIf="!comment.comment">* No comment given.</i>
                        <span *ngIf="comment.comment">{{ comment.comment }}</span>
                    </div>
                    <div class="col-2">
                        <div
                            class="progress"
                            [matTooltip]="comment.progress > 0 ? comment.progress + '%' : '-'"
                            matTooltipPosition="after"
                        >
                            <div
                                class="progress-bar progress-bar"
                                role="progressbar"
                                attr.aria-valuenow="{{ comment.progress }}"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                [ngStyle]="{ width: comment.progress + '%' }"
                            ></div>
                        </div>
                    </div>
                    <div
                        class="col"
                        [matTooltip]="comment.commentType | moiCommentType"
                        matTooltipPosition="after"
                    >
                        {{ comment.commentType | moiCommentType }}
                    </div>
                    <div class="col" [matTooltip]="comment.feasibility" matTooltipPosition="after">
                        {{ comment.feasibility }}
                    </div>
                    <div class="col-2">
                        {{ comment.createdBy }} <br />
                        <i>({{ comment.createdByRole }})</i>
                    </div>
                    <div class="col">{{ comment.createdAt | dateFormat }}</div>
                </div>
            </div>

            <div
                class="more-comments"
                (click)="showAllClick(!showAll)"
                *ngIf="comments && comments.length > 5"
            >
                View {{ showAll ? "last 5 " : "all " + comments.length }} comments
            </div>
        </div>
    </div>
</div>
