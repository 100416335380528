<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    [module]="'form result'"
    [moduleType]="moduleType"
    [appendToModuleType]="tab.id"
    [idProp]="'ID'"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [buttonConfig]="buttonConfig"
    [NumberProperties]="numberProps"
    [usePresetHyperlink]="false"
    (onDblClick)="open($event)"
    (onAddClick)="open()"
    attr.data-uiid="form-result-overview"
>
</cerrix-workspace>
