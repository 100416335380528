export class ControlDetailOverviewModel {
    ControlIdentifier: string;
    ControlName: string;
    ControlDescription: string;
    ControlFrequency: string;
    ControlMitigatedRisks: string;
    ControlExecutionMethod: string;

    DesignScoreColor: string;
    DesignScore: string;

    ImplementationScoreColor: string;
    ImplementationScore: string;

    EffectivenessScoreColor: string;
    EffectivenessScore: string;

    ConclusionScoreColor: string;
    ConclusionScore: string;

    EffectivenessDateTested: Date;
    EffectivenessNextDateTested: Date;

    DIDateTested: Date;
    DINextDateTested: Date;
}
