import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RadioOption } from "./models/radio-option.model";

@Component({
    selector: "eff-radio-group",
    templateUrl: "./eff-radio-group.component.html",
    styleUrls: ["./eff-radio-group.component.scss"],
})
export class EffRadioGroupComponent {
    @Input() model;
    @Input() label: string;
    @Input() options: RadioOption[] = [];
    @Output() modelChange: EventEmitter<any> = new EventEmitter();

    onChange() {
        this.modelChange.emit(this.model);
    }
}
