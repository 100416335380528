<div class="form-group">
    <ng-select [(ngModel)]="selectedPeriod" [items]="periods" bindValue="ID" bindLabel="Name" [loading]="!periods"
        [clearable]="false" [multiple]="false" placeholder="Advanced testing period" (change)="createChart(selectedPeriod)">
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <div [style.background-color]="item.Color"
                style="min-width: 5px; min-height: 20px; float: left; margin-right: 10px;">
            </div>
            {{item.Name}}
        </ng-template>
    </ng-select>
</div>

<control-testscore-chart #chart></control-testscore-chart>