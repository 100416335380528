import { ToastrService } from "ngx-toastr";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { ItemLinkableBase } from "@app/shared/linkable/item-linkable-base";
import { Observable } from "rxjs";
import { Component, Input } from "@angular/core";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { RendererType } from "@enums/RendererType";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { ModuleType } from "@enums/ModuleType";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { nameof } from "@methods/jeffs-toolkit";
import { FilterType } from "@enums/FilterType";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { FilterConfig } from "@models/workspace/FilterConfig";

@Component({
    selector: "risk-linkable",
    templateUrl: "./risk-linkable.component.html",
    styleUrls: ["./risk-linkable.component.scss"],
})
export class RiskLinkableComponent extends ItemLinkableBase {
    moduleType = ModuleType.ORM;

    @Input() typename = "risks";
    @Input() activeFilters: SearchKeyword[] = [];
    @Input() showAdvancedFilters = false;

    hasAccessChecks = {};
    accessCheckInProgress = false;

    filterConfig: FilterConfig[] = [
        {
            label: "Organizations",
            column: nameof<RiskLinkModel>((x) => x.organizationId),
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: nameof<RiskLinkModel>((x) => x.businessDimensionIds),
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: nameof<RiskLinkModel>((x) => x.frameworkDimensionIds),
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];

    numberProperties = ["Gross score", "Net score"];
    rendererConfig: RendererConfig[] = [
        {
            textColumn: "Gross score",
            actionColumn: "GrossScoreColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "Net score",
            actionColumn: "NetScoreColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
    ];

    constructor(
        protected _toastr: ToastrService,
        protected _promptService: CerrixPromptService,
        private _riskDS: RiskDataService,
        private _pages: Pages,
        private _tabService: TabService
    ) {
        super(_toastr, _promptService);
    }

    hasAccessToItem(guid: string): Observable<boolean> {
        return this._riskDS.hasAccessToRisk(guid);
    }

    openTab(item: IHasGuid, hasAccess: boolean) {
        if (hasAccess === true) {
            this._tabService.generateTab(
                this._pages.RiskDetail,
                item.guid,
                `(R) ${item[this.displayValueProp]}`
            );
        } else {
            this._toastr.warning("Not enough rights to open this Risk.");
        }
    }
}
