import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { ThirdPartyContractsComponent } from "../thirdparty-contracts/thirdparty-contracts.component";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { FormGroup } from "@angular/forms";
import { ThirdPartyContractModel } from "@models/thirdparty/ThirdPartyContractModel";
import { ThirdPartyPermissions } from "@models/thirdparty/ThirdPartyPermissions";
import { ThirdPartyDataService } from "../services/ThirdPartyDataService";
import { IHasGuid } from "@app/shared/interfaces/IHasGuid";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ThirdPartyContractDataService } from "../services/ThirdPartyContractDataService";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "thirdparty-contracts-overview",
    templateUrl: "./thirdparty-contracts-overview.component.html",
    styleUrls: ["./thirdparty-contracts-overview.component.scss"],
})
export class ThirdPartyContractsOverviewComponent implements OnInit {
    @ViewChild("thirdpartyContractsComponent")
    ThirdPartyContractsComponent: ThirdPartyContractsComponent;

    @Input() thirdParty: ThirdPartyModel;
    @Input() permissions: ThirdPartyPermissions;
    @Input() canEdit: boolean;

    parentForm: FormGroup;

    disabled: Boolean;

    config: any;
    constructor(
        private _tabService: TabService,
        private pages: Pages,
        private _CDS: ThirdPartyContractDataService,
        private _promptService: CerrixPromptService
    ) {}

    contractsConfig: GenericListConfig;

    ngOnInit() {
        this.canEdit = this.permissions.canEdit;
        this.initContractsConfig();
    }

    load() {
        this.config = {
            thirdPartyGuid: this.thirdParty.Guid,
        };
    }

    openTab(item: IHasGuid) {
        this._tabService.generateTab(
            this.pages.ThirdpartyContractDetail,
            item.guid,
            null,
            this.config
        );
    }

    initContractsConfig() {
        this.contractsConfig = <GenericListConfig>{
            idProp: "ID",
            uiidAdd: "thirdpartycontractoverview-addbutton",
            uiidDelete: "thirdpartycontractoverview-deletebutton",
            name: "Contracts",
            isSortable: true,
            hideRefreshButton: false,

            allowAdd: this.canEdit,
            allowDelete: this.canEdit,
            allowEdit: this.canEdit,

            fields: [
                {
                    fieldName: "ID",
                    prettyName: "ID",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "ContractNumber",
                    prettyName: "Contract no.",
                    fieldType: GenericListFieldType.Text,
                    editorWidth: "12",
                },
                {
                    fieldName: "ContractedValue",
                    prettyName: "Contracted value",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "AnnualValue",
                    prettyName: "Annual value",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Status",
                    prettyName: "Status",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Services",
                    prettyName: "Services",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "ContractOwner",
                    prettyName: "Contract owner",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "EndDate",
                    prettyName: "End date",
                    fieldType: GenericListFieldType.Date,
                },
            ],
            dataMethod: () => this._CDS.getContracts(this.thirdParty.Guid),

            addOverride: async () => {
                this._tabService.generateTab(
                    this.pages.ThirdpartyContractDetail,
                    null,
                    null,
                    this.config
                );
                return true;
            },

            editOverride: async (row: ThirdPartyContractModel) => {
                this._tabService.generateTab(
                    this.pages.ThirdpartyContractDetail,
                    row.guid,
                    null,
                    this.config
                );

                return true;
            },

            delete: async (row: ThirdPartyContractModel) => {
                const continueDelete = await this._promptService
                    .confirm("Delete contract", "Are you sure you want to delete this contract?")
                    .toPromise();

                if (continueDelete) {
                    this._CDS.deleteContract(row.guid).subscribe();
                    return true;
                }
                return false;
            },
        };
    }
}
