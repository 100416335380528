import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { Observable } from "rxjs";
import { isGuid } from "@methods/uniqueMethods";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";
import { DocumentMethods } from "@methods/DocumentMethods";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";
import { TaskSeriesModel } from "../models/task-series-model";
import { addParameterToUrl } from "@methods/CommonMethods";

@Injectable({
    providedIn: "root",
})
export class TaskSeriesDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "taskseries");
    }

    public getLinkableControls(guid: string): Observable<ControlLinkModel[]> {
        if (isGuid(guid)) {
            const url = `/${guid}/controls`;
            return this.get<ControlLinkModel[]>(url);
        }
    }

    public saveTaskSeries(model: TaskSeriesModel) {
        const formData = DocumentMethods.convertToFormDataWithFiles("ce", model);
        return this.post<string>(formData);
    }

    public getResponsibles(selectedIds: number[]): Observable<IdNameCombination[]> {
        let url = "/sd/responsibles";
        url = addParameterToUrl(url, "selectedIds", selectedIds);

        return this.get<IdNameCombination[]>(url);
    }

    public getReviewers(selectedIds: number[]): Observable<IdNameCombination[]> {
        const url = addParameterToUrl("/reviewers", "selectedIds", selectedIds);
        return this.get<IdNameCombination[]>(url);
    }

    public getTaskSeries(guid: string): Observable<TaskSeriesModel> {
        if (isGuid(guid)) {
            return this.get<TaskSeriesModel>(`/${guid}`);
        }
    }

    public getPermissions(): Observable<ControlPermissionModel> {
        const url = "/p/permissions";
        return this.get<ControlPermissionModel>(url);
    }

    public getDocuments(guid: string): Observable<DocumentModel[]> {
        const url = `/${guid}/documents`;
        return this.get<DocumentModel[]>(url);
    }

    public getHyperlinks(guid: string): Observable<HyperlinkModel[]> {
        const url = `/${guid}/hyperlinks`;
        return this.get<HyperlinkModel[]>(url);
    }

    public deleteTaskSeries(guid: string): Observable<boolean> {
        const url = `/${guid}`;
        return this.delete(url);
    }
}
