<div class="d-flex flex-column main-container">
    <div class="bold-text-small">{{ message }}</div>
    <div class="d-flex flex-row" *ngFor="let row of samplesRows; index as i; let isLast = last">
        <div class="d-flex flex-row row-samples" *ngIf="!isDateSamples()">
            <eff-input
                class="row-item"
                *ngFor="
                    let sample of manualSamplesGroup.samples
                        | slice
                            : i * numberOfSamplesDisplayedPerRow
                            : (i + 1) * numberOfSamplesDisplayedPerRow
                "
                [type]="sampleType"
                [label]="sample.name"
                [formController]="sample.formControl"
            ></eff-input>
            <div class="row-item" *ngFor="let empty of lastRowEmptyItems(isLast)"></div>
        </div>
        <div class="d-flex flex-row row-samples" *ngIf="isDateSamples()">
            <eff-date-picker
                class="row-item"
                *ngFor="
                    let sample of manualSamplesGroup.samples
                        | slice
                            : i * numberOfSamplesDisplayedPerRow
                            : (i + 1) * numberOfSamplesDisplayedPerRow
                "
                [label]="sample.name"
                [formController]="sample.formControl"
                [minDate]="this.effectivenessModel.startDate"
                [maxDate]="this.effectivenessModel.endDate"
            ></eff-date-picker>
            <div class="row-item" *ngFor="let empty of lastRowEmptyItems(isLast)"></div>
        </div>
    </div>
</div>
