import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "chat-toggle-button",
    templateUrl: "./chat-toggle-button.component.html",
    styleUrls: ["./chat-toggle-button.component.scss"],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule],
    providers: [],
})
export class ChatToggleButtonComponent implements OnInit {
    @Input() public label: string;
    @Output() toggleChat = new EventEmitter<void>();

    ngOnInit() {}

    protected onClick() {
        this.toggleChat.emit();
    }
}
