<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<ng-container *ngIf="initReady">
    <ng-container *ngIf="permissions.canView">
        <auditor-audit-details
            [cerrixTab]="tab"
            [permissions]="permissions"
            [formGroup]="formGroup"
            [auditModel]="auditModel"
            [standingData]="standingData"
            [isSaved$]="isSaved$"
            (onSave)="save()"
        ></auditor-audit-details>
    </ng-container>

    <ng-container *ngIf="permissions.canViewLimited">
        <auditee-audit-details
            [cerrixTab]="tab"
            [permissions]="permissions"
            [formGroup]="formGroup"
            [auditModel]="auditModel"
            [standingData]="standingData"
            (onSave)="save()"
        ></auditee-audit-details>
    </ng-container>
</ng-container>
