import { Pages } from "@constants/pages/Pages";
import { ModuleType } from "@enums/ModuleType";
import { GetIconByModuleString } from "@methods/Icons";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { DashboardQuickLinkModel } from "../../../shared/models/DashboardQuickLinkModel";
import { ILinkWidgetGeneratorType } from "./../../../shared/interfaces/ILinkWidgetGeneratorType";

export class AuditLinkGenerator implements ILinkWidgetGeneratorType {
    getTypes(permissions: ApplicationPermissionsModel, pages: Pages): DashboardQuickLinkModel[] {
        const quickLinks = [];
        if (permissions.auditing.hasUniverseAccess) {
            quickLinks.push({
                title: "Audit Universe",
                icon: GetIconByModuleString("audit universe"),
                page: pages.AuditUniverseOverview,

                description: "Overview with all Audit Universe Object that define Audit Universe.",
            });
        }

        if (permissions.auditing.hasAuditAccess) {
            quickLinks.push({
                title: ModuleType.AUDITDescription.toString(),
                icon: GetIconByModuleString(ModuleType.AUDITDescription),
                page: pages.AuditOverview,

                description:
                    "Overview with all Audits that are or will be executed to research Audit Universe Objects.",
            });
        }

        if (permissions.auditing.hasPeriodAccess) {
            quickLinks.push({
                title: "Audit Periods",
                icon: "fad fa-calendar-day",
                page: pages.AuditUniversePeriod,

                description:
                    "Overview of all periods where you can schedule Audit Universe Objects and Audits .",
            });
        }

        if (permissions.auditing.hasFindingAccess) {
            quickLinks.push({
                title: "Findings",
                icon: "far fa-radar",
                page: pages.AuditFinding,

                description: "Workspace for all registered audit findings.",
            });
        }

        if (permissions.HasFindingReportAccess) {
            quickLinks.push({
                title: ModuleType.FRDescription.toString(),
                icon: GetIconByModuleString(ModuleType.FRDescription),
                page: pages.ImOverview,

                description: "Workspace for all finding reports you have access to.",
            });
        }

        if (permissions.HasMoiAccess) {
            quickLinks.push({
                title: "MoIs",
                icon: GetIconByModuleString(ModuleType.BIMDescription),
                page: pages.MoiOverview,

                description: "Workspace for all mois you have access to.",
            });
        }

        return quickLinks;
    }
}
