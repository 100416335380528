import { ElementRef } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { TabService } from "@services/tabs/TabService";
import Highcharts from "highcharts";
import { DashboardConstants } from "../../shared/DashboardConstants";
import { BaseChartWidget } from "../BaseChartWidget";
import { GetBaseHighchartConfig } from "@methods/HighchartMethods";
import { DashboardGraphTypeHighchartMapping } from "../../shared/enums/DashboardGraphType.enum";
import { DashboardLegendType } from "../../shared/enums/DashboardLegendType.enum";

/** To use this baseclass make sure you retrieve the chartContainer with a viewchild and have an implementation of getChartConfig. */
export abstract class BaseHighchartWidget extends BaseChartWidget {
    chartContainer: ElementRef;
    generatedChart: Highcharts.Chart;

    constructor(tabService: TabService, sdService: StandingdataDataService) {
        super(tabService, sdService);
    }

    async load() {
        this.config.showLoader = true;
        this.reset();
        await this.createHighchart();

        super.load();
    }

    protected onResize() {
        if (this.generatedChart) {
            setTimeout(() => {
                this.generatedChart.reflow();
            }, 100);
        }
    }

    protected reset() {
        if (this.generatedChart) {
            this.generatedChart.destroy();
            this.generatedChart = null;
        }
    }

    protected async createHighchart() {
        const config = await this.getChartConfig();
        if (!config) {
            return;
        }

        this.generatedChart = Highcharts.chart(
            this.chartContainer.nativeElement,
            config,
            this.onChartCreated
        );
    }

    protected async getChartConfig(): Promise<Highcharts.Options> {
        return null;
    }

    protected getBaseChartConfig(type?, data?) {
        if (!type) {
            type = this.getConfiguredChartType();
        }

        var baseChart = GetBaseHighchartConfig(type);
        baseChart.plotOptions.series.point.events.click = (t) => {
            this.openWorkspace(t);
        };

        if (data) {
            baseChart.series = data;
        }

        const legendType = +this.config.customConfig.legendType;
        if (!legendType || legendType === DashboardLegendType.off) {
            baseChart.legend.enabled = false;
        } else if (legendType === DashboardLegendType.icon) {
            baseChart.legend.labelFormat = " "; // Hide the label and only show the colored icons.
        }

        return baseChart;
    }

    protected onChartCreated() {}
    protected async openWorkspace(ev: Highcharts.PointClickEventObject) {}

    protected getMaxChartWidth() {
        return this.config.customConfig.gridsterConfig.cols * DashboardConstants.cellSizing - 10;
    }

    protected getMaxChartHeight() {
        return this.config.customConfig.gridsterConfig.rows * DashboardConstants.cellSizing - 10;
    }

    protected getConfiguredChartType() {
        if (!this.config.customConfig.graphType) {
            throw Error("Graph Type is required!");
        }

        return DashboardGraphTypeHighchartMapping[this.config.customConfig.graphType];
    }
}
