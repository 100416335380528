import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

@Component({
    selector: "eff-expansion-panel",
    templateUrl: "./eff-expansion-panel.component.html",
    styleUrls: ["./eff-expansion-panel.component.scss"],
})
export class EffExpansionPanelComponent {
    @Input() title: string;
    @Input() contentTemplate: TemplateRef<any>;
    @Input() panelOpenState: boolean = false;
    @Input() data: any;
    constructor() {}
}
