<cerrix-icon-viewer
    [icon]="value"
    [detailed]="!pickeronly"
    (onClick)="openModal(iconPickerTemplate)"
    #iconViewer
>
</cerrix-icon-viewer>

<ng-template #iconPickerTemplate>
    <div class="icon-picker">
        <div class="left-menu">
            <div class="filter">
                <div class="input-group">
                    <input
                        type="text"
                        placeholder="Search / Filter"
                        class="form-control form-control-sm"
                        (debounce-input-event)="filter(this.selectedCategory ? true : false)"
                        [(ngModel)]="searchFilter"
                        inputTooltip
                    />
                    <div class="input-group-append">
                        <button
                            class="btn btn-sm btn-outline-danger"
                            type="button"
                            (click)="clearFilter()"
                        >
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="all-filter-results"
                *ngIf="searchFilter"
                [class.selected]="!selectedCategory"
                (click)="filter()"
            >
                All search results<br />
            </div>
            <div class="categories">
                <div
                    class="category"
                    *ngFor="let cat of categories"
                    [class.selected]="cat === selectedCategory"
                    (click)="categoryChange(cat)"
                >
                    {{ cat }}
                </div>
            </div>
        </div>
        <div class="icons-container">
            <div class="icons">
                <div
                    class="icon"
                    *ngFor="let icon of filteredIcons | slice: 0:150"
                    (click)="changeIcon(icon)"
                    [class.selected]="icon.selector === selectedIcon.selector"
                    [title]="icon.name"
                >
                    <i class="{{ icon.selector }} fa-2x fa-fw"></i>
                </div>
            </div>
            <div class="icons-message">
                <div *ngIf="filteredIcons.length > 150">
                    Only showing the first 150 icons available with current category and filter.
                </div>
            </div>
        </div>
        <div class="right-menu">
            <div class="selected-icon">
                <i
                    class="{{ selectedIcon.selector }}"
                    [class.fa-spin]="animate"
                    [class.fa-border]="border"
                    [style.color]="mainColor | safe: 'style'"
                ></i>
            </div>
            <div class="edit-area">
                <div class="form-group">
                    <label>Color</label>
                    <cerrix-color-select
                        [(value)]="mainColor"
                        [disabled]="!canChangeMainColor"
                    ></cerrix-color-select>
                </div>

                <div class="toggles">
                    <cerrix-checkbox label="Animate" [(value)]="animate"></cerrix-checkbox>
                    <cerrix-checkbox label="Border" [(value)]="border"></cerrix-checkbox>
                </div>
            </div>
        </div>
    </div>
</ng-template>
