<div class="login-page" data-uiid="login-page" *ngIf="!onlyShowModal">
    <div class="login-block">
        <div class="login-submodules">
            <ng-container *ngTemplateOutlet="loginModalTemplate"> </ng-container>

            <div class="login-illustration">
                <cerrix-animated-illustration></cerrix-animated-illustration>
            </div>
        </div>

        <div class="login-block-bottom-shape"></div>
    </div>

    <div class="copyright-block">© CERRIX B.V. {{ currentYear }} - All Rights Reserved</div>
</div>

<div class="centered-modal-container" *ngIf="onlyShowModal && config">
    <ng-container *ngTemplateOutlet="loginModalTemplate"> </ng-container>
</div>

<ng-template #loginModalTemplate>
    <div class="login-modal" *ngIf="config">
        <div class="loading-backdrop" *ngIf="showLoader">
            <div class="fancy-loader"></div>
            <div class="loading-message" *ngIf="loaderMessage">{{ loaderMessage }}</div>
        </div>

        <div class="client-logo">
            <cerrix-logo
                [width]="175"
                [height]="35"
                [retrieveLogo]="false"
                [customerLogo]="config.customerLogo"
            ></cerrix-logo>
        </div>

        <div class="message-area">
            <div data-uiid="login-warning-message" class="message warnings" *ngIf="warningMessage">
                <span>{{ warningMessage }}</span>
            </div>
            <div class="message notifications" *ngIf="notificationMessage">
                <span>{{ notificationMessage }}</span>
            </div>
            <div class="message notifications" *ngIf="onlyShowModal">
                <span>Please log in to continue.</span>
            </div>
            <div class="message notifications" *ngIf="!warningMessage && forcedPasswordReset">
                This password has expired. Please enter a new password for this username.
            </div>
        </div>

        <ng-container *ngIf="currentView === allViews.login">
            <div class="default-login" *ngIf="config.formLoginEnabled">
                <span class="default-login-text">Login to CERRIX</span>

                <div class="input-group login-input">
                    <span>
                        <i class="fa fa-user fa-fw fa-sm" aria-hidden="true"></i>
                    </span>
                    <input
                        data-uiid="login-name"
                        type="text"
                        class="form-control"
                        placeholder="Username"
                        [(ngModel)]="username"
                        id="login-username"
                        autofocus
                        inputTooltip
                    />
                </div>

                <div class="input-group login-input">
                    <span>
                        <i class="fa fa-lock fa-fw fa-sm" aria-hidden="true"></i>
                    </span>
                    <input
                        data-uiid="login-password"
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        [(ngModel)]="password"
                        id="login-password"
                    />
                </div>

                <button
                    class="btn login-page-button"
                    id="login-button"
                    data-uiid="login-button"
                    (click)="login()"
                >
                    Login
                </button>

                <div class="forgot-password clickable" *ngIf="config.passwordResetEnabled">
                    <span (click)="setSecondaryPage(allViews.forgotPassword)"
                        >Forgot password?</span
                    >
                </div>
            </div>

            <div class="alternative-login" *ngIf="config.jwtExternalLoginEnabled">
                <small class="alternative-msg">{{
                    !config.formLoginEnabled ? "Login with" : "Or login with"
                }}</small>

                <div class="work-login-button clickable" (click)="jwtExternalLogin()">
                    <span>Work account</span>
                    <i class="fa fa-chevron-right ms-3"></i>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="currentView === allViews.forgotPassword">
            <div class="forgot-password-control">
                <span class="return-to-login clickable" (click)="currentView = allViews.login">
                    <i class="fas fa-chevron-left me-2"></i>Back to login
                </span>

                <div class="input-group login-input">
                    <span>
                        <i class="fa fa-user fa-fw fa-sm" aria-hidden="true"></i>
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Username"
                        [(ngModel)]="username"
                        inputTooltip
                    />
                </div>

                <button class="btn login-page-button" (click)="resetPasswordRequest()">
                    Reset
                </button>

                <small class="text-muted mt-2">
                    If entered username exists, a password reset link will be sent within moments.
                    Use this link to reset your password.
                </small>
            </div>
        </ng-container>

        <ng-container *ngIf="currentView === allViews.resetPassword">
            <div class="password-reset-control">
                <span class="default-password-reset-text">Password reset</span>

                <div class="input-group login-input">
                    <span>
                        <i class="fa fa-lock fa-fw fa-sm" aria-hidden="true"></i>
                    </span>
                    <input
                        type="password"
                        class="form-control"
                        placeholder="New password"
                        [(ngModel)]="newPassword"
                        autofocus
                    />
                </div>

                <div class="input-group login-input">
                    <span>
                        <i class="fa fa-lock fa-fw fa-sm" aria-hidden="true"></i>
                    </span>
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Confirm new password"
                        [(ngModel)]="newPasswordConfirm"
                    />
                </div>

                <button class="btn login-page-button" (click)="saveNewPassword()">
                    Save password
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentView === allViews.newLocation">
            <div class="new-location-control">
                <span class="default-new-location-text">Verify your location</span>

                <div class="input-group login-input">
                    <span>
                        <i class="fa fa-fingerprint fa-fw fa-sm" aria-hidden="true"></i>
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Verification code"
                        [(ngModel)]="locationVerificationCode"
                        maxlength="5"
                        autofocus
                    />
                </div>

                <button class="btn login-page-button" (click)="verifyNewLocation()">
                    Verify code
                </button>

                <small class="text-muted mt-2">
                    You tried to login from a new location. Enter the code we sent to your mail to
                    continue login.
                </small>
            </div>
        </ng-container>
    </div>
</ng-template>
