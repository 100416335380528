import { Component, OnInit, ViewChild } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import {
    FindingReportDetailsModel,
    FindingReportStoreModel,
    FindingReportPermissionsModel,
} from "@app/findingreport/shared/models";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { FindingReportDataService } from "@app/findingreport/services";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import HttpStatusCode from "@models/generic/HttpRequest/HttpStatusCode";
import { TabService } from "@services/tabs/TabService";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { FormValidators } from "@app/shared/helpers/form-validators";
import { FormValidationMessageHelper } from "@app/shared/helpers/form-validation-message-helper";
import { Pages } from "@constants/pages/Pages";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { closeDirtyPageCheck } from "@methods/CommonMethods";
import { TargetModule } from "@enums/document/TargetModule";
import { isGuid } from "@methods/uniqueMethods";
import { FindingReportConfigModel } from "@app/findingreport/shared/models/finding-report-config-model";
import { FindingReportsFindingsComponent } from "./finding-report-findings/finding-report-findings.component";

@Component({
    selector: "finding-report-details",
    templateUrl: "./finding-report-details.component.html",
    styleUrls: ["./finding-report-details.component.scss"],
})
export class FindingReportDetailsComponent implements OnInit {
    public tab: TabModel;
    public tabID: string;
    public id: string;

    public finishedLoading = false;
    public get saveAllowed(): boolean {
        return (
            (!this.id && this.permissionsModel.canAdd) || (this.id && this.permissionsModel.canEdit)
        );
    }
    public get deleteAllowed(): boolean {
        return this.id && this.permissionsModel.canDelete;
    }

    public hideActionBar = false;
    public formGroup: FormGroup;
    private isDocumentsDirty = false;

    targetModule = TargetModule.FindingReport;

    public permissionsModel: FindingReportPermissionsModel;
    public detailsModel: FindingReportDetailsModel;

    @ViewChild(HistoryOverviewComponent)
    private historyOverviewComponent: HistoryOverviewComponent;

    @ViewChild(FindingReportsFindingsComponent)
    private findingsComponent: FindingReportsFindingsComponent;

    hyperlinksMenuItemId = "menuItemHyperlinks";

    canViewFindings: boolean = false;

    public constructor(
        public findingReportService: FindingReportDataService,
        private tabService: TabService,
        private pages: Pages,
        private toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {}

    public async ngOnInit() {
        await this.loadData(this.id);
        this.tab.beforeClose = (checkOnly) => {
            const unsavedChanges = this.detailsModel && this.isDirty();
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !unsavedChanges);
        };
    }

    private async loadData(id: string) {
        try {
            this.permissionsModel = await this.findingReportService.getPermissions(id).toPromise();
            if (this.hasInvalidPermissions(id)) {
                return;
            }

            this.detailsModel = id
                ? await this.findingReportService.getFindingReport(id).toPromise()
                : this.getNewFindingReportModel();

            this.canViewFindings =
                (this.detailsModel.auditGuid ? true : false) &&
                (await this.findingReportService.canViewFindings().toPromise());

            this.initializeFormValidation();
        } catch (error) {
            if (error.status === HttpStatusCode.NOT_FOUND) {
                this.tab.close(false);
            } else {
                this.toastr.warning("Couldn't load finding report");
            }
        } finally {
            this.finishedLoading = true;
        }
    }

    public async save() {
        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        try {
            if (!this.formGroup.valid) {
                FormValidationHelper.markAllAsTouched(this.formGroup);

                const validationMessage = new FormValidationMessageHelper<FindingReportStoreModel>({
                    name: "Report name",
                    reportTypeId: "Report type",
                    reportNumber: "Report no.",
                    subName: "Sub name",
                    reportUrl: "Report URL",
                    reportDate: "Report date",
                    reportDueDate: "Report due date",
                    responsibleOrganizationId: "Organization responsible",
                    responsibleId: "Responsible",
                    reviewerOrganizationId: "Organization reviewer",
                    reviewerId: "Reviewer",
                })
                    .processFormGroup(this.formGroup)
                    .getErrorMessage();

                this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
            } else {
                const storeModel = new FindingReportStoreModel();
                FormValidationHelper.mapToModel(this.formGroup, storeModel);
                storeModel.documents = this.detailsModel.documents;
                storeModel.Hyperlinks = this.detailsModel.Hyperlinks;
                storeModel.auditGuid = this.detailsModel.auditGuid;

                // Save
                if (this.detailsModel.guid) {
                    await this.findingReportService
                        .updateFindingReport(this.detailsModel.guid, storeModel)
                        .toPromise();

                    FormValidationHelper.mapToModel(this.formGroup, this.detailsModel);
                } else {
                    this.detailsModel = await this.findingReportService
                        .addFindingReport(storeModel)
                        .toPromise();

                    this.tab.id = this.id = this.detailsModel.guid;
                }

                this.tab.name = `(F) ${this.detailsModel.identifier} - ${this.detailsModel.name}`;
                this.tabService.storeTabChanges();

                // Because menusystem doesn't reload properly we need to refresh tab
                // this.formGroup.reset(this.formGroup.value);
                this.toastr.success("", "Update completed");
                this.tab.refresh();
            }
        } finally {
            savingPrompt.close();
        }
    }

    public async delete() {
        if (!this.detailsModel.guid) {
            return;
        }

        const savingPrompt = this._promptService.loader("Deleting report, please wait...");

        try {
            await this.findingReportService.deleteFindingReport(this.detailsModel.guid).toPromise();

            this.toastr.success(`Finding report '${this.detailsModel.name}' deleted`);

            this.tabService.refresh(this.pages.ImOverview);

            this.tab.close(false);
        } finally {
            savingPrompt.close();
        }
    }

    //#region Helpers

    private hasInvalidPermissions(id: string): boolean {
        let warning = null;
        if (!this.permissionsModel.canView) {
            warning = "Not allowed to open/view finding report";
        } else if (!id && !this.permissionsModel.canAdd) {
            warning = "Not allowed to add finding report";
        }

        if (warning) {
            this.toastr.warning(warning);
            return true;
        }

        return false;
    }

    private initializeFormValidation() {
        this.isDocumentsDirty = false;

        this.formGroup = new FormGroup(
            {
                name: new FormControl(this.detailsModel.name, [Validators.required]),
                reportTypeId: new FormControl(this.detailsModel.reportTypeId, Validators.required),
                reportNumber: new FormControl(this.detailsModel.reportNumber),
                subName: new FormControl(this.detailsModel.subName),
                reportUrl: new FormControl(this.detailsModel.reportUrl),
                overallScoreId: new FormControl(this.detailsModel.overallScoreId),
                validationTypeId: new FormControl(this.detailsModel.validationTypeId),
                reportDate: new FormControl(this.detailsModel.reportDate, Validators.required),
                reportDueDate: new FormControl(this.detailsModel.reportDueDate),

                scopeAndObjective: new FormControl(this.detailsModel.scopeAndObjective),
                conclusion: new FormControl(this.detailsModel.conclusion),

                responsibleOrganizationId: new FormControl(
                    this.detailsModel.responsibleOrganizationId,
                    Validators.required
                ),
                responsibleId: new FormControl(
                    this.detailsModel.responsibleId,
                    Validators.required
                ),
                reviewerOrganizationId: new FormControl(this.detailsModel.reviewerOrganizationId),
                reviewerId: new FormControl(this.detailsModel.reviewerId, Validators.required),
            },
            {
                validators: [
                    FormValidators.startEndDate<FindingReportStoreModel>(
                        "reportDate",
                        "reportDueDate"
                    ),
                ],
            }
        );

        if (this.id && !this.permissionsModel.canEdit) {
            this.formGroup.disable();
        }
    }

    private isDirty(): boolean {
        return this.formGroup.dirty || this.isDocumentsDirty;
    }

    //#endregion Helpers

    //#region Events

    public onDocumentsChange() {
        this.isDocumentsDirty = true;
    }

    public onMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem || !menuItem.menuItemId) {
            return;
        }

        let hideActionBar = false;
        switch (menuItem.menuItemId) {
            case "findings": {
                this.findingsComponent.init();
                break;
            }
            case "assessments": {
                hideActionBar = true;
                break;
            }
            case "documents": {
                break;
            }
            case this.hyperlinksMenuItemId: {
                this.loadHyperlinks();
                break;
            }
            case "history": {
                hideActionBar = true;
                this.historyOverviewComponent.loadHistory();
                break;
            }
        }

        this.hideActionBar = hideActionBar;
    }

    loadHyperlinks(): void {
        if (!this.detailsModel.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this.findingReportService.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.detailsModel.Hyperlinks = hyperlinks;
            });
        }
    }

    private getNewFindingReportModel(): FindingReportDetailsModel {
        let findingReportModel = new FindingReportDetailsModel();

        const configModel = <FindingReportConfigModel>this.tab.config;
        if (configModel) {
            const auditGuid = configModel.auditGuid;
            if (auditGuid) {
                findingReportModel.auditGuid = auditGuid;
            }

            const name = configModel.name;
            if (name) {
                findingReportModel.name = name;
            }

            const objective = configModel.objective;
            if (objective) {
                findingReportModel.scopeAndObjective = objective;
            }
        }

        return findingReportModel;
    }

    //#endregion Events
}
