import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { FormField } from "@app/forms/models";
import { DocumentModel } from '../../../../../../../common/models/documents/documentmodel';

@Component({
    selector: "form-entry-fileupload",
    templateUrl: "./form-entry-fileupload.component.html",
    styleUrls: ["./form-entry-fileupload.component.scss"]
})
export class FormEntryFileUploadComponent implements OnInit {
    @Input() field: FormField;
    @Input() readonly: boolean;
    @Input() answer: DocumentModel[];
    @Output() answerChange = new EventEmitter<DocumentModel[]>();

    constructor() {}

    ngOnInit(): void {
        if (!this.answer) {
            this.answer = [];
        }
    }

    changeTrigger() {
        this.answerChange.emit(this.answer);
    }
}
