<div class="d-flex flex-column main-container">
    <div class="label bold-text">{{ label }}</div>
    <div class="d-flex flex-row align-items-center justify-content-between calendar-wrapper">
        <input
            class="regular-text-small"
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="model"
            [formControl]="formController"
            [min]="minDate"
            [max]="maxDate"
        />
        <eff-icon (click)="picker.open()" icon="today_outlined"> </eff-icon>
    </div>
    <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
</div>
