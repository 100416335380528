<cerrix-loader [cerrixTab]="tab" [showLoader]="!finishedLoading"></cerrix-loader>

<div data-uiid="finding-report-details-page" *ngIf="finishedLoading && detailsModel">
    <div class="detail-page-action-bar flex-action-bar" *ngIf="!hideActionBar">
        <div>
            <button
                data-uiid="finding-report-save-button"
                class="btn btn-cerrix"
                [disabled]="!this.saveAllowed"
                (click)="save()"
            >
                <i class="fas fa-save"></i>
                <span class="d-none d-lg-inline">Save</span>
            </button>

            <span class="date-edited" *ngIf="detailsModel.modifiedAt">
                Last save: {{ detailsModel.modifiedAt | dateTimeFormat }}
            </span>
        </div>
    </div>

    <div menuSystem [cerrixTab]="tab" class="finding-report" (change)="onMenuItemClick($event)">
        <div name="Finding Report" menuicon="fas fa-clipboard-list" menusystemsubmenu>
            <div menuItemId="details" name="Details" menusystemitem menuicon="fas fa-info-circle">
                <finding-report-details-main
                    [formGroup]="formGroup"
                    [detailsModel]="detailsModel"
                    [permissionsModel]="permissionsModel"
                    [editAllowed]="saveAllowed"
                ></finding-report-details-main>
            </div>
            <div name="Links" menusystemsubmenu menuicon="fas fa-link" *ngIf="detailsModel.guid">
                <div
                    *ngIf="canViewFindings"
                    menuItemId="findings"
                    name="Findings"
                    menusystemitem
                    menuicon="far fa-radar"
                >
                    <finding-report-findings
                        [auditGuid]="detailsModel.auditGuid"
                    ></finding-report-findings>
                </div>
                <div
                    menuItemId="assessments"
                    name="Assessments"
                    menusystemitem
                    menuicon="fas fa-poll-people"
                >
                    <assessment-linked-overview
                        [parentGuid]="detailsModel.guid"
                        [canAdd]="permissionsModel.canAddAssessment"
                        [canDelete]="permissionsModel.canDeleteAssessment"
                    >
                    </assessment-linked-overview>
                </div>
                <div menuItemId="documents" name="Documents" menusystemitem menuicon="far fa-file">
                    <finding-report-details-documents
                        [editAllowed]="saveAllowed"
                        [documents]="detailsModel.documents"
                        (documentsChange)="onDocumentsChange()"
                    ></finding-report-details-documents>
                </div>
                <div
                    name="Hyperlinks"
                    [attr.menuItemID]="hyperlinksMenuItemId"
                    menuicon="fas fa-external-link-alt"
                    menusystemitem
                    *ngIf="detailsModel.guid"
                >
                    <div *ngIf="!detailsModel.Hyperlinks">
                        <div class="fancy-loader"></div>
                    </div>
                    <div *ngIf="detailsModel.Hyperlinks">
                        <hyperlink-manager
                            [(Hyperlinks)]="detailsModel.Hyperlinks"
                            (HyperlinksChanged)="checkHyperlinksDirty($event)"
                            [TargetModule]="targetModule"
                            [Readonly]="!saveAllowed"
                        ></hyperlink-manager>
                    </div>
                </div>
            </div>
            <div
                menuItemId="history"
                name="History"
                menusystemitem
                menuicon="fas fa-history"
                *ngIf="detailsModel.guid"
            >
                <app-history-overview
                    [historyDataService]="findingReportService"
                    [Id]="detailsModel.guid"
                    #historyOverviewComponent
                >
                </app-history-overview>
            </div>

            <div
                data-uiid="finding-report-detail-options"
                name="Options"
                menusystemitem
                menuicon="fas fa-check-double"
                *ngIf="detailsModel.guid"
            >
                <detail-options
                    moduleName="Finding Report"
                    [objectName]="detailsModel.name ? detailsModel.name : 'finding report'"
                    [allowDelete]="this.deleteAllowed"
                    (onDelete)="delete()"
                >
                </detail-options>
            </div>
        </div>
    </div>
</div>
