import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import { IncidentRootCauseCategoryModel } from "../models/incident-root-cause-category.model";

@Injectable({
    providedIn: "root",
})
export class IncidentRootCauseCategoryService {
    private httpClient: HttpClient = inject(HttpClient);
    private baseUrl = `${localStorage.getItem("incidentUrl")}/api/root-cause-categories`;

    public getAll(): Observable<IncidentRootCauseCategoryModel[]> {
        return this.httpClient.get<IncidentRootCauseCategoryModel[]>(this.baseUrl);
    }

    public getAllByIncident(incidentId: string): Observable<IncidentRootCauseCategoryModel[]> {
        if (!incidentId) {
            return this.httpClient.get<IncidentRootCauseCategoryModel[]>(this.baseUrl);
        } else {
            return this.httpClient.get<IncidentRootCauseCategoryModel[]>(
                `${this.baseUrl}/incident/${incidentId}`
            );
        }
    }

    public getEventCategoryUsage(catergoryGuid: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/rootcause/${catergoryGuid}`);
    }
}
