<div
    class="detail-page-card"
    [class.m-0]="noMargin"
    [class.compact-document-manager]="compactMode"
    [class.no-documents]="folderData.length === 0"
    fileDrop
    (fileDrop)="fileDropHandler($event)"
    [allowedExtensions]="allowedFileExtensions"
    [allowDrop]="permissions.canAddDocument"
    *ngIf="allowedFileExtensions && showUi"
>
    <ng-container>
        <div class="detail-page-card-title" *ngIf="!hideLabel && Label">{{ Label }}</div>
        <div
            class="detail-page-card-actions"
            *ngIf="!hideAddFolderButton || !hideAddButton || !hideGenerateButton"
        >
            <button
                data-uiid="documentmanager-addfolder"
                class="btn btn-raised"
                (click)="openAddFolder()"
                [disabled]="disabled || !permissions.canAddFolder"
            >
                <i class="fas fa-plus-circle"></i>
                <span>Add folder</span>
            </button>
            <button
                data-uiid="documentmanager-adddocument"
                class="btn btn-raised"
                (click)="openEditFile()"
                [disabled]="disabled || !permissions.canAddDocument"
            >
                <i class="fas fa-plus-circle"></i>
                <span>Add document</span>
            </button>
            <button
                data-uiid="documentmanager-generatedocument"
                class="btn btn-raised"
                (click)="openGenerate()"
                [disabled]="disabled || !permissions.canAddDocument"
            >
                <i class="fas fa-plus-circle"></i>
                <span>Generate document</span>
            </button>
            <div class="spacer"></div>
            <button
                data-uiid="documentmanager-cutselection"
                class="btn btn-raised"
                (click)="cutSelectedRows()"
                [disabled]="disabled || cutRows.length > 0"
            >
                <i class="fas fa-scissors"></i>
                <span>Cut selection</span>
            </button>
            <button
                data-uiid="documentmanager-pasteselection"
                class="btn btn-raised"
                (click)="pasteSelectedRows()"
                [disabled]="disabled || cutRows.length === 0"
            >
                <i class="fas fa-paste"></i>
                <span>Paste selection</span>
            </button>
        </div>

        <div class="document-grid" *ngIf="folderData.length > 0 || folderPath.length > 0">
            <div class="document-grid-breadcrumbs">
                <div class="document-grid-breadcrumbs-crumb" (click)="navigateBreadcrumb()">
                    <i class="fas fa-house"></i>
                </div>
                <ng-container *ngFor="let crumb of folderPath">
                    <div class="document-grid-breadcrumbs-separator">
                        <i class="fas fa-chevron-right"></i>
                    </div>
                    <div
                        class="document-grid-breadcrumbs-crumb"
                        (click)="navigateBreadcrumb(crumb)"
                    >
                        {{ crumb.Name }}
                    </div>
                </ng-container>
            </div>
            <ejs-grid
                [dataSource]="folderData"
                [filterSettings]="_filterOptions"
                [sortSettings]="_sortColumnOptions"
                [showColumnMenu]="true"
                [allowReordering]="true"
                [allowResizing]="true"
                [allowFiltering]="true"
                [allowSorting]="true"
                [enableHover]="true"
                [selectionSettings]="selectionOptions"
                [contextMenuItems]="contextMenuItems"
                (contextMenuClick)="contextMenuClick($event)"
                (recordClick)="singleClickAction($event)"
                (recordDoubleClick)="openEdit($event)"
                *ngIf="folderData"
                #ejGrid
            >
                <e-columns>
                    <e-column
                        type="checkbox"
                        field="IsSelected"
                        width="20"
                        [uid]="selectionColumnName"
                        [allowResizing]="false"
                        [allowFiltering]="false"
                        [showColumnMenu]="false"
                    ></e-column>
                    <e-column field="Name" headerText="Name">
                        <ng-template #template let-data>
                            <span
                                class="mat-icon-workaround"
                                *ngIf="
                                    data.msFileIcon && !data.IsNew && !data.Edited && !data.Deleted
                                "
                            >
                                <mat-icon [svgIcon]="data.msFileIcon"></mat-icon>
                            </span>
                            <i
                                *ngIf="
                                    data.FileType == FileType.Document &&
                                    !data.msFileIcon &&
                                    !data.IsNew &&
                                    !data.Edited &&
                                    !data.Deleted
                                "
                                class="fas fa-{{ data.FileIcon }}"
                            ></i>
                            <i
                                *ngIf="data.IsNew && data.FileType == FileType.Document"
                                class="fas fa-plus"
                            ></i>
                            <i
                                *ngIf="data.IsNew && data.FileType == FileType.Folder"
                                class="fas fa-folder-plus"
                            ></i>
                            <cerrix-icon-viewer
                                *ngIf="
                                    data.FileType == FileType.Folder &&
                                    !data.IsNew &&
                                    !data.Deleted &&
                                    data.FileIcon
                                "
                                [icon]="data.FileIcon"
                            ></cerrix-icon-viewer>
                            <i *ngIf="data.Edited" class="fas fa-pencil"></i>
                            <i *ngIf="data.Deleted" class="fas fa-times"></i>
                            <span
                                class="document-name ms-2"
                                [class.red]="data.Deleted"
                                [class.cut]="data.IsCut"
                            >
                                {{ data.Name }}
                                <i *ngIf="data.Changed">(edited)</i>
                            </span>
                        </ng-template>
                    </e-column>
                    <e-column
                        field="Description"
                        headerText="Description"
                        [visible]="false"
                    ></e-column>
                    <e-column
                        field="Username"
                        headerText="User"
                        [visible]="!compactMode"
                    ></e-column>
                    <e-column headerText="Date uploaded" [visible]="!compactMode">
                        <ng-template #template let-data>{{
                            data.Date | dateTimeFormat : true
                        }}</ng-template>
                    </e-column>
                    <e-column
                        field="DocumentType"
                        headerText="Document type"
                        [visible]="!compactMode"
                    ></e-column>
                    <e-column
                        field="Version"
                        headerText="# of versions"
                        [visible]="false"
                    ></e-column>

                    <ng-container *ngFor="let col of ExtraFieldColumnConfig">
                        <e-column
                            [field]="col.fieldName"
                            [headerText]="col.prettyName"
                            [width]="col.columnWidth"
                            [visible]="!col.hidden"
                        >
                        </e-column>
                    </ng-container>

                    <e-column
                        width="40"
                        textAlign="Center"
                        [allowEditing]="false"
                        [allowResizing]="false"
                        [uid]="fileMenuColumnName"
                    >
                        <ng-template #template let-data>
                            <i
                                class="fas fa-bars"
                                mat-icon-button
                                [matMenuTriggerFor]="docManagerFileMenu"
                            ></i>
                            <mat-menu #docManagerFileMenu="matMenu">
                                <button
                                    mat-menu-item
                                    (click)="
                                        openEmbedView(data);
                                        cerrixSlideOverWopi.show();
                                        $event.stopPropagation()
                                    "
                                    *ngIf="!data.IsNew && !data.Deleted && data.WopiViewable"
                                >
                                    <mat-icon [svgIcon]="data.msAppIcon"></mat-icon>
                                    <span>View in Office for the web</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="openEditorInWindow(data); $event.stopPropagation()"
                                    *ngIf="
                                        !data.IsNew &&
                                        !disabled &&
                                        !data.Deleted &&
                                        AllowEdit &&
                                        data.WopiEditable
                                    "
                                >
                                    <mat-icon [svgIcon]="data.msAppIcon"></mat-icon>
                                    <span>Edit in Office for the web</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="openDocumentViewer(data)"
                                    *ngIf="!data.IsNew && data.Name && data.Name.endsWith('.pdf')"
                                >
                                    <mat-icon fontSet="fas" fontIcon="fa-file-pdf"></mat-icon>
                                    <span>Open PDF Viewer</span>
                                </button>
                                <a
                                    *ngIf="data.FileType == FileType.Document"
                                    mat-menu-item
                                    [href]="'api/documents/manager/' + data.Guid + '/data'"
                                    target="_blank"
                                >
                                    <mat-icon><i class="fas fa-download"></i></mat-icon>
                                    <span>Download</span>
                                </a>
                                <button
                                    mat-menu-item
                                    (click)="toggleDelete(data)"
                                    *ngIf="!disabled && data.CanDelete && AllowDelete"
                                >
                                    <mat-icon *ngIf="data.Deleted"
                                        ><i class="fas fa-undo"></i
                                    ></mat-icon>
                                    <mat-icon *ngIf="!data.Deleted"
                                        ><i class="fas fa-trash"></i
                                    ></mat-icon>
                                    <span>{{ data.Deleted ? "Undo delete" : "Delete" }}</span>
                                </button>
                            </mat-menu>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </ng-container>

    <div class="no-documents-area-container" *ngIf="folderData.length === 0">
        <div class="no-documents-area">
            <div class="no-documents-text">
                <i class="fas fa-info-circle fa-fw me-2 primary-color"></i>
                <span class="fw-bold">No documents are uploaded</span>
            </div>
            <div
                class="add-first-document-section"
                *ngIf="!hideAddButton && !disabled && permissions.canAddDocument"
            >
                <span class="fw-bold primary-color">
                    <i class="far fa-arrow-to-bottom me-2"></i>
                    <span>Drag your documents here to start uploading</span>
                </span>
            </div>
        </div>
    </div>
</div>

<cerrix-slide-over-wopi
    #cerrixSlideOverWopi
    [title]="slideOutTitle"
    [wopiData]="slideOutContent"
></cerrix-slide-over-wopi>
