import { Component, OnInit, ViewChild } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
    AssessmentDetailsModel,
    AssessmentStoreModel,
    AssessmentPermissionsModel,
} from "@app/assessment/shared/models";
import { AssessmentDataService } from "@app/assessment/services";
import HttpStatusCode from "@models/generic/HttpRequest/HttpStatusCode";
import { ToastrService } from "ngx-toastr";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { AssessmentDetailsMainComponent } from "./details-main/assessment-details-main.component";
import { TabService } from "@services/tabs/TabService";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { FormValidationMessageHelper } from "@app/shared/helpers/form-validation-message-helper";
import { FormValidators } from "@app/shared/helpers/form-validators";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { closeDirtyPageCheck } from "@methods/CommonMethods";
import { Configuration } from "@app/app.constants";

@Component({
    selector: "assessment-details",
    templateUrl: "./assessment-details.component.html",
    styleUrls: ["./assessment-details.component.scss"],
})
export class AssessmentDetailsComponent implements OnInit {
    public tab: TabModel;
    public tabID: string;
    public id: string;

    public finishedLoading = false;
    public get saveAllowed(): boolean {
        return (
            (!this.id && this.permissionsModel.canAdd) || (this.id && this.permissionsModel.canEdit)
        );
    }

    public hideActionBar = false;
    public formGroup: FormGroup;

    public moiType = MoiTypes.IM_Moi;
    public permissionsModel: AssessmentPermissionsModel;
    public detailsModel: AssessmentDetailsModel;

    @ViewChild(AssessmentDetailsMainComponent)
    private mainComponent: AssessmentDetailsMainComponent;
    @ViewChild(HistoryOverviewComponent)
    private historyOverviewComponent: HistoryOverviewComponent;

    constructor(
        public assessmentService: AssessmentDataService,
        private tabService: TabService,
        private toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _configuration: Configuration
    ) {}

    public async ngOnInit() {
        await this.loadData(this.tab.id);
        this.tab.beforeClose = (checkOnly) => {
            return closeDirtyPageCheck(
                this.tab,
                this._promptService,
                checkOnly,
                !this.detailsModel || !this.isDirty()
            );
        };
    }

    private async loadData(id: string) {
        try {
            const findingReportGuid = this.tab.config.parentGuid;
            if (!this.tab.config || !findingReportGuid) {
                this.toastr.error("No finding report specified");
                return;
            }

            this.permissionsModel = await this.assessmentService
                .getPermissions(findingReportGuid, id)
                .toPromise();
            if (this.hasInvalidPermissions(id)) {
                return;
            }

            if (id) {
                const assessment = await this.assessmentService.getAssessment(id).toPromise();
                this.detailsModel = assessment;
            } else {
                this.detailsModel = new AssessmentDetailsModel();
                this.detailsModel.findingReportGuid = findingReportGuid;
            }

            this.initializeFormValidation();
        } catch (error) {
            if (error.status === HttpStatusCode.NOT_FOUND) {
                this.toastr.warning("Assessment not found");
            } else {
                this.toastr.warning("Couldn't load assessment");
            }
        } finally {
            this.finishedLoading = true;
        }
    }

    public async save() {
        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        try {
            if (!this.formGroup.valid) {
                FormValidationHelper.markAllAsTouched(this.formGroup);

                const validationMessage = new FormValidationMessageHelper<AssessmentStoreModel>({
                    assessmentTypeId: "Assessment type",
                    assessmentDate: "Assessment date",
                    assessmentUrl: "Assessment URL",
                    reviewerOrganizationId: "Organization reviewer",
                    reviewerId: "Reviewer",
                })
                    .processFormGroup(this.formGroup)
                    .getErrorMessage();

                this.toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
            } else {
                const storeModel = new AssessmentStoreModel();
                FormValidationHelper.mapToModel(this.formGroup, storeModel);

                // Save
                if (this.detailsModel.guid) {
                    await this.assessmentService
                        .updateAssessement(this.detailsModel.guid, storeModel)
                        .toPromise();

                    FormValidationHelper.mapToModel(this.formGroup, this.detailsModel);
                } else {
                    this.detailsModel = await this.assessmentService
                        .addAssessment(this.tab.config.parentGuid, storeModel)
                        .toPromise();

                    this.tab.id = this.id = this.detailsModel.guid;
                }

                const typeName = this.mainComponent.getAssessmentTypeName(
                    this.detailsModel.assessmentTypeId
                );
                this.tab.name = `(A) ${this.detailsModel.identifier} - ${typeName}`;
                this.tabService.storeTabChanges();

                // Because menusystem doesn't reload properly we need to refresh tab
                // this.formGroup.reset(this.formGroup.value);
                this.tab.refresh();
            }
        } finally {
            savingPrompt.close();
        }
    }

    //#region Helpers

    private hasInvalidPermissions(id: string): boolean {
        let warning = null;
        if (!this.permissionsModel.canView) {
            warning = "Not allowed to open/view assessment";
        } else if (!id && !this.permissionsModel.canAdd) {
            warning = "Not allowed to add assessment";
        }

        if (warning) {
            this.toastr.warning(warning);
            return true;
        }

        return false;
    }

    private initializeFormValidation() {
        this.formGroup = new FormGroup({
            assessmentTypeId: new FormControl(
                this.detailsModel.assessmentTypeId,
                Validators.required
            ),
            assessmentDate: new FormControl(this.detailsModel.assessmentDate, [
                Validators.required,
                FormValidators.notFutureDate,
            ]),
            assessmentUrl: new FormControl(this.detailsModel.assessmentUrl),
            overallScoreId: new FormControl(this.detailsModel.overallScoreId),
            reviewerOrganizationId: new FormControl(this.detailsModel.reviewerOrganizationId),
            reviewerId: new FormControl(this.detailsModel.reviewerId, Validators.required),
            reviewerComments: new FormControl(this.detailsModel.reviewerComments),
        });

        if (this.id && !this.permissionsModel.canEdit) {
            this.formGroup.disable();
        }
    }

    private isDirty(): boolean {
        return this.formGroup.dirty;
    }

    //#endregion Helpers

    //#region Events

    public onMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem || !menuItem.menuItemId) {
            return;
        }

        let hideActionBar = false;
        switch (menuItem.menuItemId) {
            case "mois": {
                hideActionBar = true;
                break;
            }
            case "history": {
                hideActionBar = true;
                this.historyOverviewComponent.loadHistory();
                break;
            }
        }

        this.hideActionBar = hideActionBar;
    }

    //#endregion Events
}
