<div class="fancy-loader submodule my-4" *ngIf="isLoading"></div>

<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="config">
        <div class="detail-page-action-bar flex-action-bar">
            <div></div>
            <div class="right-area">
                <button (click)="createTemplateDatasets()" class="btn btn-dark">
                    <i class="fas fa-plus me-2"></i>
                    Create templates
                </button>
            </div>
        </div>

        <generic-list-manager [config]="config"></generic-list-manager>
    </ng-container>

    <ng-container *ngIf="!config">
        <span>Power BI is not enabled for this site.</span>
    </ng-container>
</ng-container>
