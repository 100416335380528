import { mxgraph as mxType } from "mxgraph-factory";
import { DiagramData, IModule } from "@models/processeditor/DiagramData";
import { mxClient as mx } from "./mxClient";
import "./graphcell"; // Import extended functionality
import { ItemEnum } from "@enums/processeditor/ItemEnum";
import { IProcessRiskConnection } from "./Interfaces/IProcessRiskConnection";
import { IProcessControlConnection } from "./Interfaces/IProcessControlConnection";
import { IRiskControlConnection } from "./Interfaces/IRiskControlConnection";
import { CerrixVisioCellCodec } from "./codec/CerrixVisioCellCodec";
import { CerrixCellCodec } from "./codec/CerrixCellCodec";
import { ProcessCellConnectionModel } from "./models/ProcessCellConnectionModel";

declare var pako: any;
declare var Base64: any;
declare var mxUtils: typeof mxType.mxUtils;
declare var mxConstants: typeof mxType.mxConstants;

export class GraphEditor extends mx.mxGraph {
    private _diagramData: DiagramData;
    get diagramData() {
        return this._diagramData;
    }

    set diagramData(value: DiagramData) {
        this._diagramData = value;
        this._diagramDataBackup = JSON.parse(JSON.stringify(value));
    }

    private _diagramDataBackup: DiagramData;
    get diagramDataBackup() {
        return this._diagramDataBackup;
    }

    get dependencies(): typeof mxType {
        return mx;
    }

    connectionArrowsEnabled: boolean;
    useCssTransforms: boolean;
    showOrderNr: boolean;
    private history: mxType.mxUndoManager;

    // Reduces a parent node to a leaf node
    // Copies over text from a child node to the parent node along with
    // styles that are not none
    private static defaultMappingFunction(parent: mxType.mxCell, currentCell: mxType.mxCell) {
        if (!currentCell.edge) {
            parent.children.splice(parent.children.indexOf(currentCell), 1);
        }

        // Copy over text
        if (currentCell.value.getAttribute("label")) {
            parent.value = currentCell.value;
        }

        GraphEditor.CopyStyles(
            [
                mxConstants.STYLE_FILLCOLOR,
                mxConstants.STYLE_STROKECOLOR,
                mxConstants.STYLE_FONTCOLOR,
                mxConstants.STYLE_FONTFAMILY,
                mxConstants.STYLE_FONTSIZE,
                mxConstants.STYLE_SPACING,
                mxConstants.STYLE_TEXT_OPACITY,
                mxConstants.STYLE_DIRECTION,
                mxConstants.STYLE_VERTICAL_ALIGN,
                mxConstants.STYLE_ARCSIZE,
            ],
            currentCell,
            parent
        );
    }

    private static readonly mappingFunctions = {
        parent: {
            swimlane: GraphEditor.defaultMappingFunction,
            pool: GraphEditor.defaultMappingFunction,
            data: GraphEditor.defaultMappingFunction,
            decision: GraphEditor.defaultMappingFunction,
            task: GraphEditor.defaultMappingFunction,
            dataObject: GraphEditor.defaultMappingFunction,
            text: GraphEditor.defaultMappingFunction,
            expandedSubProcess: GraphEditor.defaultMappingFunction,
            startEvent: GraphEditor.defaultMappingFunction,
            intermediateEvent: GraphEditor.defaultMappingFunction,
            endEvent: GraphEditor.defaultMappingFunction,
            "cerrix.collapsed_sub-process": GraphEditor.defaultMappingFunction,
            gateway: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                GraphEditor.defaultMappingFunction(parent, currentCell);

                parent.style = mxUtils.setStyle(
                    parent.style,
                    mxConstants.STYLE_STROKECOLOR,
                    "#000000"
                );
            },
            process: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                GraphEditor.defaultMappingFunction(parent, currentCell);

                parent.style = mxUtils.setStyle(
                    parent.style,
                    mxConstants.STYLE_STROKECOLOR,
                    "#000000"
                );
            },
        },
        currentCell: {
            ignoreImport: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                // Remove child from the list of children
                parent.children.splice(parent.children.indexOf(currentCell), 1);
            },
            startEnd: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                currentCell.style = mxUtils.setStyle(
                    currentCell.style,
                    mxConstants.STYLE_ARCSIZE,
                    "50"
                );
            },
            pool: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                currentCell.style = mxUtils.setStyle(
                    currentCell.style,
                    mxConstants.STYLE_SWIMLANE_LINE,
                    "0"
                );
            },
            decision: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                currentCell.style = mxUtils.setStyle(
                    currentCell.style,
                    mxConstants.STYLE_PERIMETER,
                    mxConstants.PERIMETER_RHOMBUS
                );
            },
            gateway: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                currentCell.style = mxUtils.setStyle(
                    currentCell.style,
                    mxConstants.STYLE_PERIMETER,
                    mxConstants.PERIMETER_RHOMBUS
                );
            },
            startEvent: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                const setStyle = (styleConstant, value) => {
                    currentCell.style = mxUtils.setStyle(currentCell.style, styleConstant, value);
                };

                setStyle(mxConstants.STYLE_VERTICAL_LABEL_POSITION, mxConstants.ALIGN_BOTTOM);
                setStyle(mxConstants.STYLE_VERTICAL_ALIGN, mxConstants.ALIGN_TOP);
                setStyle(mxConstants.STYLE_ALIGN, mxConstants.ALIGN_CENTER);
                setStyle(mxConstants.STYLE_PERIMETER, mxConstants.PERIMETER_ELLIPSE);
                setStyle("outlineConnect", "0");
                setStyle("outline", "standard");
                setStyle("symbol", "general");
            },
            intermediateEvent: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                const setStyle = (styleConstant, value) => {
                    currentCell.style = mxUtils.setStyle(currentCell.style, styleConstant, value);
                };

                setStyle(mxConstants.STYLE_VERTICAL_LABEL_POSITION, mxConstants.ALIGN_BOTTOM);
                setStyle(mxConstants.STYLE_VERTICAL_ALIGN, mxConstants.ALIGN_TOP);
                setStyle(mxConstants.STYLE_ALIGN, mxConstants.ALIGN_CENTER);
                setStyle(mxConstants.STYLE_PERIMETER, mxConstants.PERIMETER_ELLIPSE);
                setStyle("outlineConnect", "0");
                setStyle("outline", "throwing");
                setStyle("symbol", "general");
            },
            endEvent: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                const setStyle = (styleConstant, value) => {
                    currentCell.style = mxUtils.setStyle(currentCell.style, styleConstant, value);
                };

                setStyle(mxConstants.STYLE_VERTICAL_LABEL_POSITION, mxConstants.ALIGN_BOTTOM);
                setStyle(mxConstants.STYLE_VERTICAL_ALIGN, mxConstants.ALIGN_TOP);
                setStyle(mxConstants.STYLE_ALIGN, mxConstants.ALIGN_CENTER);
                setStyle(mxConstants.STYLE_PERIMETER, mxConstants.PERIMETER_ELLIPSE);
                setStyle("outlineConnect", "0");
                setStyle("outline", "end");
                setStyle("symbol", "general");
            },
            phaseList: (parent: mxType.mxCell, currentCell: mxType.mxCell) => {
                currentCell.style = mxUtils.setStyle(
                    currentCell.style,
                    mxConstants.STYLE_FILL_OPACITY,
                    "0"
                );
            },
        },
    };

    constructor(container: HTMLElement) {
        super(container, new mx.mxGraphModel());
        this.addHistoryListeners();

        this.initializeOverlay();
    }

    private initializeOverlay() {
        const graph = this;

        // cell added to the graph.
        const superCellAdded = graph.dependencies.mxGraphModel.prototype.cellAdded;
        graph.dependencies.mxGraphModel.prototype.cellAdded = function (cell) {
            graph.updateOverlay(cell);
            superCellAdded.apply(this, arguments);
        };
    }

    /**
     * Removes all illegal control characters with ASCII code <32 except TAB, LF
     * and CR.
     */
    static zapGremlins(text) {
        const checked = [];
        for (let i = 0; i < text.length; i++) {
            const code = text.charCodeAt(i);
            // Removes all control chars except TAB, LF and CR
            if (
                (code >= 32 || code === 9 || code === 10 || code === 13) &&
                code !== 0xffff &&
                code !== 0xfffe
            ) {
                checked.push(text.charAt(i));
            }
        }
        return checked.join("");
    }
    /**
     * Turns the given string into an array.
     */
    static stringToBytes(str) {
        const arr = new Array(str.length);
        for (let i = 0; i < str.length; i++) {
            arr[i] = str.charCodeAt(i);
        }
        return arr;
    }
    /**
     * Turns the given array into a string.
     */
    static bytesToString(arr) {
        const result = new Array(arr.length);
        for (let i = 0; i < arr.length; i++) {
            result[i] = String.fromCharCode(arr[i]);
        }
        return result.join("");
    }
    /**
     * Returns a base64 encoded version of the compressed outer XML of the given node.
     */
    static compressNode(node) {
        return this.compress(this.zapGremlins(mx.mxUtils.getXml(node)));
    }
    /**
     * Returns a base64 encoded version of the compressed string.
     */
    static compress(data, deflate?): string {
        if (data == null || data.length === 0 || typeof pako === "undefined") {
            return data;
        } else {
            const tmp = deflate
                ? pako.deflate(encodeURIComponent(data))
                : pako.deflateRaw(encodeURIComponent(data));

            // pako.deflate no longer supports { to: "string" } in v2 so:
            const tmp2 = String.fromCharCode.apply(null, tmp);

            return window.btoa ? window.btoa(tmp2) : Base64.encode(tmp2, true);
        }
    }
    /**
     * Returns a decompressed version of the base64 encoded string.
     */
    static decompress(data, inflate?, checked?: boolean): string {
        if (data == null || data.length === 0 || typeof pako === "undefined") {
            return data;
        } else {
            const tmp = window.atob ? window.atob(data) : Base64.decode(data, true);

            // pako.inflate no longer supports string as input in v2 so:
            const tmp2 = new Uint8Array([...tmp].map((c) => c.charCodeAt(0)));

            const inflated = decodeURIComponent(
                inflate
                    ? pako.inflate(tmp2, { to: "string" })
                    : pako.inflateRaw(tmp2, { to: "string" })
            );

            return checked ? inflated : GraphEditor.zapGremlins(inflated);
        }
    }

    // Copy style from source to target if style exists and if the value is not 'none'
    private static CopyStyle(styleConstant: string, source: mxType.mxCell, target: mxType.mxCell) {
        const searchString = styleConstant + "=";
        const searchIndex = source.style.lastIndexOf(searchString);
        const NOT_FOUND = -1;

        if (searchIndex !== source.style.lastIndexOf(searchString + mxConstants.NONE)) {
            let seperatorIndex = source.style.indexOf(";", searchIndex);

            // The seperator is optional at the end of the string
            if (seperatorIndex === NOT_FOUND) {
                seperatorIndex = source.style.length;
            }

            target.style = mxUtils.setStyle(
                target.style,
                styleConstant,
                source.style.substring(searchIndex + searchString.length, seperatorIndex)
            );
        }
    }

    private static CopyStyles(
        styleConstants: string[],
        source: mxType.mxCell,
        target: mxType.mxCell
    ) {
        styleConstants.forEach((styleConstant) =>
            GraphEditor.CopyStyle(styleConstant, source, target)
        );
    }

    importXml(xml: string, dx: number, dy: number, crop: boolean, noErrorHandling: boolean) {
        dx = dx != null ? dx : 0;
        dy = dy != null ? dy : 0;
        let cells = [];

        try {
            const graph = this;

            if (xml != null && xml.length > 0) {
                // Adds pages
                graph.model.beginUpdate();
                try {
                    const doc = graph.dependencies.mxUtils.parseXml(xml);
                    const mapping = {};

                    // Checks for mxfile with multiple pages
                    const node = this.extractGraphModel(doc.documentElement, false);

                    if (node != null && node.nodeName === "mxGraphModel") {
                        cells = graph.importGraphModel(node, dx, dy, crop);

                        if (cells != null) {
                            for (let i = 0; i < cells.length; i++) {
                                // this.updatePageLinksForCell(mapping, cells[i]);
                            }
                        }
                    }
                } finally {
                    graph.model.endUpdate();
                }
            }
        } catch (e) {
            if (!noErrorHandling) {
                console.error(e);
            } else {
                throw e;
            }
        }

        return cells;
    }

    // editor
    private static parseDiagramNode(diagramNode: Element, checked?: boolean) {
        const text = mxUtils.trim(mxUtils.getTextContent(diagramNode));
        let node = null;

        if (text.length > 0) {
            const tmp = GraphEditor.decompress(text, null, checked);

            if (tmp != null && tmp.length > 0) {
                node = mxUtils.parseXml(tmp).documentElement;
            }
        } else {
            const temp = mxUtils.getChildNodes(diagramNode);

            if (temp.length > 0) {
                // Creates new document for unique IDs within mxGraphModel
                const doc = mxUtils.createXmlDocument() as Document;
                doc.appendChild(doc.importNode(temp[0], true));
                node = doc.documentElement;
            }
        }

        return node;
    }

    // graph
    private createCellLookup(cells: mxType.mxCell[], lookup?) {
        const mxObjectIdentity = {
            /**
             * Class: mxObjectIdentity
             *
             * Identity for JavaScript objects and functions. This is implemented using
             * a simple incrementing counter which is stored in each object under
             * <FIELD_NAME>.
             *
             * The identity for an object does not change during its lifecycle.
             *
             * Variable: FIELD_NAME
             *
             * Name of the field to be used to store the object ID. Default is
             * <code>mxObjectId</code>.
             */
            FIELD_NAME: "mxObjectId",

            /**
             * Variable: counter
             *
             * Current counter.
             */
            counter: 0,

            /**
             * Function: get
             *
             * Returns the ID for the given object or function or null if no object
             * is specified.
             */
            get(obj) {
                if (obj != null) {
                    if (obj[mxObjectIdentity.FIELD_NAME] == null) {
                        if (typeof obj === "object") {
                            const ctor = mxUtils.getFunctionName(obj.constructor);
                            obj[mxObjectIdentity.FIELD_NAME] =
                                ctor + "#" + mxObjectIdentity.counter++;
                        } else if (typeof obj === "function") {
                            obj[mxObjectIdentity.FIELD_NAME] =
                                "Function#" + mxObjectIdentity.counter++;
                        }
                    }

                    return obj[mxObjectIdentity.FIELD_NAME];
                }

                return null;
            },

            /**
             * Function: clear
             *
             * Deletes the ID from the given object or function.
             */
            clear(obj) {
                if (typeof obj === "object" || typeof obj === "function") {
                    delete obj[mxObjectIdentity.FIELD_NAME];
                }
            },
        };

        lookup = lookup != null ? lookup : new Object();

        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            lookup[mxObjectIdentity.get(cell)] = cell.getId();
            const childCount = this.model.getChildCount(cell);

            for (let j = 0; j < childCount; j++) {
                this.createCellLookup([this.model.getChildAt(cell, j)], lookup);
            }
        }

        return lookup;
    }

    // graph
    private createCellMapping(mapping, lookup, cellMapping?) {
        cellMapping = cellMapping != null ? cellMapping : new Object();

        for (const objectId in mapping) {
            const cellId = lookup[objectId];

            if (cellMapping[cellId] == null) {
                // Uses empty string if clone ID was null which means
                // the cell was cloned but not inserted into the model.
                cellMapping[cellId] = mapping[objectId].getId() || "";
            }
        }

        return cellMapping;
    }

    // graph
    private importGraphModel(node: Element, dx: number, dy: number, crop: boolean) {
        const { mxCodec, mxGraphModel, mxCodecRegistry } = this.dependencies;
        dx = dx != null ? dx : 0;
        dy = dy != null ? dy : 0;

        const mxCellCodedBackup = mxCodecRegistry.codecs["mxCell"];
        mxCodecRegistry.codecs["mxCell"] = new CerrixVisioCellCodec();

        const parseFloatBackup = window["parseFloat"];

        // ! exceljs overwrites the parsefloat function
        // ! mxgraph calls the overwritten parsefloat function with an input that it doesn't expect, resulting in an error
        window["parseFloat"] = function (n) {
            return +n;
        };

        const codec = new mxCodec(node.ownerDocument);
        const tempModel = new mxGraphModel();
        codec.decode(node, tempModel);
        let cells = [];

        mxCodecRegistry.codecs["mxCell"] = mxCellCodedBackup;

        // Combine loose shape to form a proper swimlane/pool
        const traverse = (cell: mxType.mxCell, matcher: { parent: any; currentCell: any }) => {
            if (cell.children) {
                // Clone the list of children because a child can be removed mid loop
                const clone = [...cell.children];
                clone.forEach((c) => {
                    const parent: mxType.mxCell = c.parent;

                    // If the parent is a swimlane all the loose shapes under it are not necessary anymore
                    const op = matcher.parent[parent.getShape()];
                    if (parent && op) {
                        op(parent, c);
                    }

                    const currentCellOp = matcher.currentCell[c.getShape()];
                    if (currentCellOp && parent) {
                        currentCellOp(parent, c);
                    }

                    traverse(c, matcher);
                });
            }
        };

        traverse(tempModel.root, GraphEditor.mappingFunctions);

        // Clones cells to remove invalid edges
        const cloneMap = new Object();
        const cellMapping = new Object();
        let layers = tempModel.getChildren(
            this.cloneCells([tempModel.root], this.isCloneInvalidEdges(), cloneMap)[0]
        );

        if (layers != null) {
            // Creates lookup from object IDs to cell IDs
            const lookup = this.createCellLookup([tempModel.root]);

            // Uses copy as layers are removed from array inside loop
            layers = layers.slice();

            this.model.beginUpdate();
            try {
                // Merges into unlocked current layer if one layer is pasted
                if (layers.length === 1 && !this.isCellLocked(this.getDefaultParent())) {
                    cells = this.moveCells(
                        tempModel.getChildren(layers[0]),
                        dx,
                        dy,
                        false,
                        this.getDefaultParent()
                    );

                    // Imported default parent maps to local default parent
                    cellMapping[tempModel.getChildAt(tempModel.root, 0).getId()] =
                        this.getDefaultParent().getId();
                } else {
                    for (let i = 0; i < layers.length; i++) {
                        const children = this.model.getChildren(
                            this.moveCells(
                                layers[i].children,
                                dx,
                                dy,
                                false,
                                this.getDefaultParent()
                            )[0]
                        );

                        if (children != null) {
                            cells = cells.concat(children);
                        }
                    }
                }

                if (cells != null) {
                    // Adds mapping for all cloned entries from imported to local cell ID
                    this.createCellMapping(cloneMap, lookup, cellMapping);
                    // this.updateCustomLinks(cellMapping, cells);

                    if (crop) {
                        if (this.isGridEnabled()) {
                            dx = this.snap(dx);
                            dy = this.snap(dy);
                        }

                        const bounds = this.getBoundingBoxFromGeometry(cells, true);

                        if (bounds != null) {
                            this.moveCells(cells, dx - bounds.x, dy - bounds.y);
                        }
                    }
                }
            } finally {
                this.model.endUpdate();
            }
        }

        window["parseFloat"] = parseFloatBackup;

        return cells;
    }

    public getSvg({
        background = null,
        scale = 1,
        border = 0,
        nocrop = false,
        crisp = true,
        ignoreSelection = true,
        showText = true,
        imgExport = null,
        linkTarget = null,
        hasShadow = false,
        exportType = "diagram",
    }) {
        //Disable Css Transforms if it is used
        const origUseCssTrans = this.useCssTransforms;

        if (origUseCssTrans) {
            this.useCssTransforms = false;
            this.view.revalidate();
            this.sizeDidChange();
        }

        try {
            const bounds =
                exportType == "page"
                    ? this.view.getBackgroundPageBounds()
                    : ignoreSelection || nocrop || exportType == "diagram"
                    ? this.getGraphBounds()
                    : this.getBoundingBox(this.getSelectionCells());

            if (bounds == null) {
                throw Error(this.dependencies.mxResources.get("drawingEmpty"));
            }

            const vs = this.view.scale;

            // Prepares SVG document that holds the output
            const svgDoc = mxUtils.createXmlDocument();
            const root =
                svgDoc.createElementNS != null
                    ? svgDoc.createElementNS(mxConstants.NS_SVG, "svg")
                    : svgDoc.createElement("svg");

            if (background != null) {
                if (root.style != null) {
                    root.style.backgroundColor = background;
                } else {
                    root.setAttribute("style", "background-color:" + background);
                }
            }

            if (svgDoc.createElementNS == null) {
                root.setAttribute("xmlns", mxConstants.NS_SVG);
                root.setAttribute("xmlns:xlink", mxConstants.NS_XLINK);
            } else {
                // KNOWN: Ignored in IE9-11, adds namespace for each image element instead. No workaround.
                root.setAttributeNS(
                    "http://www.w3.org/2000/xmlns/",
                    "xmlns:xlink",
                    mxConstants.NS_XLINK
                );
            }

            const s = scale / vs;
            const w = Math.max(1, Math.ceil(bounds.width * s) + 2 * border) + (hasShadow ? 5 : 0);
            const h = Math.max(1, Math.ceil(bounds.height * s) + 2 * border) + (hasShadow ? 5 : 0);

            root.setAttribute("version", "1.1");
            root.setAttribute("width", w + "px");
            root.setAttribute("height", h + "px");
            root.setAttribute("viewBox", (crisp ? "-0.5 -0.5" : "0 0") + " " + w + " " + h);
            svgDoc.appendChild(root);

            // Renders graph. Offset will be multiplied with state's scale when painting state.
            // TextOffset only seems to affect FF output but used everywhere for consistency.
            const group =
                svgDoc.createElementNS != null
                    ? svgDoc.createElementNS(mxConstants.NS_SVG, "g")
                    : svgDoc.createElement("g");
            root.appendChild(group);

            const svgCanvas = this.createSvgCanvas(group);
            svgCanvas.foOffset = crisp ? -0.5 : 0;
            svgCanvas.textOffset = crisp ? -0.5 : 0;
            svgCanvas.imageOffset = crisp ? -0.5 : 0;
            svgCanvas.translate(
                Math.floor((border / scale - bounds.x) / vs),
                Math.floor((border / scale - bounds.y) / vs)
            );

            // Convert HTML entities
            const htmlConverter = document.createElement("div");

            // Adds simple text fallback for viewers with no support for foreignObjects
            const getAlternateText = svgCanvas["getAlternateText"];
            svgCanvas["getAlternateText"] = function (
                fo,
                x,
                y,
                w,
                h,
                str,
                align,
                valign,
                wrap,
                format,
                overflow,
                clip,
                rotation
            ) {
                // Assumes a max character width of 0.5em
                if (str != null && this.state.fontSize > 0) {
                    try {
                        if (this.dependencies.mxUtils.isNode(str)) {
                            str = str.innerText;
                        } else {
                            htmlConverter.innerHTML = str;
                            str = mxUtils.extractTextWithWhitespace(htmlConverter.childNodes);
                        }

                        // Workaround for substring breaking double byte UTF
                        const exp = Math.ceil((2 * w) / this.state.fontSize);
                        const result = [];
                        let length = 0;
                        let index = 0;

                        while ((exp == 0 || length < exp) && index < str.length) {
                            const char = str.charCodeAt(index);

                            if (char == 10 || char == 13) {
                                if (length > 0) {
                                    break;
                                }
                            } else {
                                result.push(str.charAt(index));

                                if (char < 255) {
                                    length++;
                                }
                            }

                            index++;
                        }

                        // Uses result and adds ellipsis if more than 1 char remains
                        if (result.length < str.length && str.length - result.length > 1) {
                            str = mxUtils.trim(result.join("")) + "...";
                        }

                        return str;
                    } catch (e) {
                        return getAlternateText.apply(this, arguments);
                    }
                } else {
                    return getAlternateText.apply(this, arguments);
                }
            };

            // Paints background image
            const bgImg = this.backgroundImage;

            if (bgImg != null) {
                const s2 = vs / scale;
                const tr = this.view.translate;
                const tmp = new this.dependencies.mxRectangle(
                    tr.x * s2,
                    tr.y * s2,
                    bgImg.width * s2,
                    bgImg.height * s2
                );

                // Checks if visible
                if (mxUtils.intersects(bounds, tmp)) {
                    svgCanvas.image(
                        tr.x,
                        tr.y,
                        bgImg.width,
                        bgImg.height,
                        bgImg.src,
                        true,
                        null,
                        null
                    );
                }
            }

            svgCanvas.scale(s);
            svgCanvas.textEnabled = showText;

            imgExport = imgExport != null ? imgExport : this.createSvgImageExport();
            const imgExportDrawCellState = imgExport.drawCellState;

            // Ignores custom links
            const imgExportGetLinkForCellState = imgExport.getLinkForCellState;

            imgExport.getLinkForCellState = function (state, canvas) {
                const result = imgExportGetLinkForCellState.apply(this, arguments);

                return result != null && !state.view.graph.isCustomLink(result) ? result : null;
            };

            // Implements ignoreSelection flag
            imgExport.drawCellState = function (state, canvas) {
                const graph = state.view.graph;
                let selected = graph.isCellSelected(state.cell);
                let parent = graph.model.getParent(state.cell);

                // Checks if parent cell is selected
                while (!ignoreSelection && !selected && parent != null) {
                    selected = graph.isCellSelected(parent);
                    parent = graph.model.getParent(parent);
                }

                if (ignoreSelection || selected) {
                    imgExportDrawCellState.apply(this, arguments);
                }
            };

            imgExport.drawState(this.getView().getState(this.model.root), svgCanvas);
            this.updateSvgLinks(root, linkTarget, true);

            return root;
        } finally {
            if (origUseCssTrans) {
                this.useCssTransforms = true;
                this.view.revalidate();
                this.sizeDidChange();
            }
        }
    }

    private convertImages(svgRoot, callback, imageCache, converter) {
        // Converts images to data URLs for immediate painting
        if (converter == null) {
            converter = this.createImageUrlConverter();
        }

        // Barrier for asynchronous image loading
        let counter = 0;

        function inc() {
            counter++;
        }

        function dec() {
            counter--;

            if (counter == 0) {
                callback(svgRoot);
            }
        }

        const cache = imageCache || new Object();

        const convertImages = mxUtils.bind(this, function (tagName, srcAttr) {
            const images = svgRoot.getElementsByTagName(tagName);

            for (let i = 0; i < images.length; i++) {
                mxUtils.bind(this, function (img) {
                    try {
                        if (img != null) {
                            const src = converter.convert(img.getAttribute(srcAttr));

                            // Data URIs are pass-through
                            if (src != null && src.substring(0, 5) != "data:") {
                                let tmp = cache[src];

                                if (tmp == null) {
                                    inc();

                                    this.convertImageToDataUri(src, function (uri) {
                                        if (uri != null) {
                                            cache[src] = uri;
                                            img.setAttribute(srcAttr, uri);
                                        }

                                        dec();
                                    });
                                } else {
                                    img.setAttribute(srcAttr, tmp);
                                }
                            } else if (src != null) {
                                img.setAttribute(srcAttr, src);
                            }
                        }
                    } catch (e) {
                        // ignore
                    }
                })(images[i]);
            }
        });

        // Converts all known image tags in output
        // LATER: Add support for images in CSS
        convertImages("image", "xlink:href");
        convertImages("img", "src");

        // All from cache or no images
        if (counter == 0) {
            callback(svgRoot);
        }
    }

    public exportToCanvas(
        callback,
        width,
        imageCache,
        background,
        error,
        limitHeight,
        ignoreSelection,
        scale,
        transparentBackground,
        addShadow,
        converter,
        graph,
        border,
        noCrop,
        grid,
        keepTheme,
        exportType
    ) {
        try {
            limitHeight = limitHeight != null ? limitHeight : true;
            ignoreSelection = ignoreSelection != null ? ignoreSelection : true;
            graph = graph != null ? graph : this;
            border = border != null ? border : 0;

            let bg = transparentBackground ? null : graph.background;

            if (bg == mxConstants.NONE) {
                bg = null;
            }

            if (bg == null) {
                bg = background;
            }

            // Handles special case where background is null but transparent is false
            if (bg == null && transparentBackground == false) {
                bg = "#ffffff";
            }

            this.convertImages(
                graph.getSvg(
                    null,
                    null,
                    border,
                    noCrop,
                    null,
                    ignoreSelection,
                    null,
                    null,
                    null,
                    addShadow,
                    null,
                    keepTheme,
                    exportType
                ),
                mxUtils.bind(this, function (svgRoot) {
                    try {
                        const img = new Image();

                        img.onload = mxUtils.bind(this, function () {
                            try {
                                const canvas = document.createElement("canvas");
                                let w = parseInt(svgRoot.getAttribute("width"));
                                let h = parseInt(svgRoot.getAttribute("height"));
                                scale = scale != null ? scale : 1;

                                if (width != null) {
                                    scale = !limitHeight
                                        ? width / w
                                        : Math.min(1, Math.min((width * 3) / (h * 4), width / w));
                                }

                                scale = this.getMaxCanvasScale(w, h, scale);
                                w = Math.ceil(scale * w);
                                h = Math.ceil(scale * h);

                                canvas.setAttribute("width", w.toString());
                                canvas.setAttribute("height", h.toString());
                                const ctx = canvas.getContext("2d");

                                if (bg != null) {
                                    ctx.beginPath();
                                    ctx.rect(0, 0, w, h);
                                    ctx.fillStyle = bg;
                                    ctx.fill();
                                }

                                if (scale != 1) {
                                    ctx.scale(scale, scale);
                                }

                                const drawImage = () => {
                                    // Workaround for broken data URI images in Safari on first export
                                    if (this.dependencies.mxClient.IS_SF) {
                                        window.setTimeout(function () {
                                            ctx.drawImage(img, 0, 0);
                                            callback(canvas);
                                        }, 0);
                                    } else {
                                        ctx.drawImage(img, 0, 0);
                                        callback(canvas);
                                    }
                                };

                                if (grid) {
                                    const view = graph.view;
                                    const curViewScale = view.scale;
                                    view.scale = 1; //Reset the scale temporary to generate unscaled grid image which is then scaled
                                    let gridImage = btoa(
                                        unescape(
                                            encodeURIComponent(view.createSvgGrid(view.gridColor))
                                        )
                                    );
                                    view.scale = curViewScale;
                                    gridImage = "data:image/svg+xml;base64," + gridImage;
                                    const phase = graph.gridSize * view.gridSteps * scale;

                                    const b = graph.getGraphBounds();
                                    const tx = view.translate.x * curViewScale;
                                    const ty = view.translate.y * curViewScale;
                                    const x0 = tx + (b.x - tx) / curViewScale - border;
                                    const y0 = ty + (b.y - ty) / curViewScale - border;

                                    const background = new Image();

                                    background.onload = function () {
                                        try {
                                            const x = -Math.round(
                                                phase - mxUtils.mod((tx - x0) * scale, phase)
                                            );
                                            const y = -Math.round(
                                                phase - mxUtils.mod((ty - y0) * scale, phase)
                                            );

                                            for (let i = x; i < w; i += phase) {
                                                for (let j = y; j < h; j += phase) {
                                                    ctx.drawImage(background, i / scale, j / scale);
                                                }
                                            }

                                            drawImage();
                                        } catch (e) {
                                            if (error != null) {
                                                error(e);
                                            }
                                        }
                                    };

                                    background.onerror = function (e) {
                                        if (error != null) {
                                            error(e);
                                        }
                                    };

                                    background.src = gridImage;
                                } else {
                                    drawImage();
                                }
                            } catch (e) {
                                if (error != null) {
                                    error(e);
                                }
                            }
                        });

                        img.onerror = function (e) {
                            //console.log('img', e, img.src);

                            if (error != null) {
                                error(e);
                            }
                        };

                        if (addShadow) {
                            this.graph.addSvgShadow(svgRoot);
                        }

                        if (this.graph.mathEnabled) {
                            this.addMathCss(svgRoot);
                        }

                        const done = mxUtils.bind(this, function () {
                            try {
                                if (this.resolvedFontCss != null) {
                                    this.addFontCss(svgRoot, this.resolvedFontCss);
                                }

                                img.src = this.createSvgDataUri(mxUtils.getXml(svgRoot));
                            } catch (e) {
                                if (error != null) {
                                    error(e);
                                }
                            }
                        });

                        this.embedExtFonts(
                            mxUtils.bind(this, function (extFontsEmbeddedCss) {
                                try {
                                    if (extFontsEmbeddedCss != null) {
                                        this.addFontCss(svgRoot, extFontsEmbeddedCss);
                                    }

                                    this.loadFonts(done);
                                } catch (e) {
                                    if (error != null) {
                                        error(e);
                                    }
                                }
                            })
                        );
                    } catch (e) {
                        if (error != null) {
                            error(e);
                        }
                    }
                }),
                imageCache,
                converter
            );
        } catch (e) {
            if (error != null) {
                error(e);
            }
        }
    }

    public createSvgDataUri(svg) {
        return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)));
    }

    private createImageUrlConverter() {
        const converter = new this.dependencies.mxUrlConverter();
        converter.updateBaseUrl();

        return converter;
    }

    private isCustomLink(href) {
        return href.substring(0, 5) == "data:";
    }

    private createSvgImageExport() {
        const exp = new this.dependencies.mxImageExport();

        // Adds hyperlinks (experimental)
        exp.getLinkForCellState = mxUtils.bind(this, function (state, canvas) {
            return this.getLinkForCell(state.cell);
        });

        return exp;
    }

    /**
     * Hook for creating the canvas used in getSvg.
     */
    private updateSvgLinks(node, target, removeCustom) {
        const links = node.getElementsByTagName("a");

        for (let i = 0; i < links.length; i++) {
            let href = links[i].getAttribute("href");

            if (href == null) {
                href = links[i].getAttribute("xlink:href");
            }

            if (href != null) {
                if (target != null && /^https?:\/\//.test(href)) {
                    links[i].setAttribute("target", target);
                } else if (removeCustom && this.isCustomLink(href)) {
                    links[i].setAttribute("href", "javascript:void(0);");
                }
            }
        }
    }

    /**
     * Hook for creating the canvas used in getSvg.
     */
    private createSvgCanvas(node) {
        const canvas = new this.dependencies.mxSvgCanvas2D(node);

        canvas.pointerEvents = true;

        return canvas;
    }

    // editor
    private extractGraphModel(node: Element, allowMxFile: boolean, checked?: boolean) {
        const { mxUtils, mxResources } = this.dependencies;

        if (node != null && typeof pako !== "undefined") {
            const tmp = node.ownerDocument.getElementsByTagName("div");
            const divs = [];

            if (tmp != null && tmp.length > 0) {
                for (let i = 0; i < tmp.length; i++) {
                    if (tmp[i].getAttribute("class") === "mxgraph") {
                        divs.push(tmp[i]);
                        break;
                    }
                }
            }

            if (divs.length > 0) {
                const data = divs[0].getAttribute("data-mxgraph");

                if (data != null) {
                    const config = JSON.parse(data);

                    if (config != null && config.xml != null) {
                        const doc2 = mxUtils.parseXml(config.xml);
                        node = doc2.documentElement;
                    }
                } else {
                    const divs2 = divs[0].getElementsByTagName("div");

                    if (divs2.length > 0) {
                        let data = mxUtils.getTextContent(divs2[0]);
                        data = GraphEditor.decompress(data, null, checked);

                        if (data.length > 0) {
                            const doc2 = mxUtils.parseXml(data);
                            node = doc2.documentElement;
                        }
                    }
                }
            }
        }

        if (node != null && node.nodeName === "svg") {
            let tmp = node.getAttribute("content");
            const cont = ""; // Geen idee waar dit vandaan komt

            if (tmp != null && tmp.charAt(0) !== "<" && tmp.charAt(0) !== "%") {
                tmp = unescape(window.atob ? atob(tmp) : Base64.decode(cont, tmp));
            }

            if (tmp != null && tmp.charAt(0) === "%") {
                tmp = decodeURIComponent(tmp);
            }

            if (tmp != null && tmp.length > 0) {
                node = mxUtils.parseXml(tmp).documentElement;
            } else {
                throw { message: mxResources.get("notADiagramFile") };
            }
        }

        if (node != null && !allowMxFile) {
            let diagramNode = null;

            if (node.nodeName === "diagram") {
                diagramNode = node;
            } else if (node.nodeName === "mxfile") {
                const diagrams = node.getElementsByTagName("diagram");

                if (diagrams.length > 0) {
                    diagramNode =
                        diagrams[
                            Math.max(
                                0,
                                Math.min(
                                    diagrams.length - 1,
                                    /* window["urlParams"]["page"] || */ 0
                                )
                            )
                        ];
                }
            }

            if (diagramNode != null) {
                node = GraphEditor.parseDiagramNode(diagramNode, checked);
            }
        }

        if (
            node != null &&
            node.nodeName !== "mxGraphModel" &&
            (!allowMxFile || node.nodeName !== "mxfile")
        ) {
            node = null;
        }

        return node;
    }

    public isDiagramEmpty() {
        const model = this.getModel();

        return (
            model.getChildCount(model.root) === 1 &&
            model.getChildCount(model.getChildAt(model.root, 0)) === 0
        );
    }

    public updateOverlay(cell) {
        if (!cell) {
            return;
        }

        if (!this.dependencies.mxUtils.isNode(cell.value, cell.name)) {
            // no data
            this.removeCellOverlays(cell);
            return;
        }

        this.removeCellOverlays(cell);

        const comment = cell.getAttribute("comment");
        if (comment) {
            const overlay = new this.dependencies.mxCellOverlay(
                new this.dependencies.mxImage("mxGraph/editors/images/information.png", 16, 16),
                comment
            );

            // Installs a handler for clicks on the overlay
            overlay.addListener(this.dependencies.mxEvent.CLICK, (sender, evt, cell) => {
                this.setSelectionCell(cell);
            });

            this.addCellOverlay(cell, overlay);
        }

        const linked = cell.getAttribute("linked_id");
        if (linked && linked.length > 0) {
            const linkedName = cell.getAttribute("linked_name");
            const linkedOverlay = new this.dependencies.mxCellOverlay(
                new this.dependencies.mxImage("mxGraph/editors/images/link.gif", 16, 16),
                "Linked to: " + linkedName,
                "right",
                "top"
            );
            this.addCellOverlay(cell, linkedOverlay);
        }
    }

    public undo() {
        if (this.history.canUndo()) {
            this.history.undo();
        }
    }

    public redo() {
        if (this.history.canRedo()) {
            this.history.redo();
        }
    }

    public copy() {
        mx.mxClipboard.copy(this, null);
    }

    public paste() {
        mx.mxClipboard.paste(this);
    }

    public cut() {
        mx.mxClipboard.cut(this, null);
    }

    public canUndo() {
        return this.history.canUndo();
    }

    public clear() {
        this.model.clear();
        this.getView().clear();
        this.refresh();
    }

    // PBI 11292 - commented out the lines calling this.dependencies, since those settings are saved across all editors, not just the current instance.
    // This messes with the readonly settings in the editor.

    public setEditEnabled(isEnabled: boolean) {
        this.setCellsLocked(!isEnabled);
        // this.dependencies.mxVertexHandler.prototype.setHandlesVisible(isEnabled);
        // this.dependencies.mxVertexHandler.prototype["rotationEnabled"] = isEnabled;
        this.selectionCellsHandler.setEventsEnabled(isEnabled);
        this.graphHandler.setMoveEnabled(isEnabled);
        this.graphHandler.setCloneEnabled(isEnabled);
        this.connectionArrowsEnabled = isEnabled;
        this.connectionHandler.enabled = isEnabled;
        this.cellsEditable = isEnabled;
        // if (!isEnabled) {
        //     this.dependencies.mxSelectionCellsHandler.prototype.refresh = () => null;
        // }
    }

    public toggleGrid() {
        this.gridEnabled = !this.gridEnabled;
        this.refresh();
    }

    public toggleLockCells(cells?: mxType.mxCell[]) {
        const keys = ["movable", "resizable", "rotatable", "deletable", "editable", "connectable"];
        keys.forEach((key) => this.toggleCellStyles(key, null, cells));
        this.refresh();
    }

    public getProcessCellConnections(cellList: mxType.mxCell[]): ProcessCellConnectionModel {
        const processRiskConnections: IProcessRiskConnection[] = [];
        const riskControlConnections: IRiskControlConnection[] = [];
        const processControlConnections: IProcessControlConnection[] = [];

        cellList.forEach((cell) => {
            const itemEnum = ItemEnum.fromShape(cell.getShape() as any);
            if (itemEnum === ItemEnum.Process || itemEnum === ItemEnum.PredefinedProcess) {
                this.findProcessRiskConnection([], cell, processRiskConnections);
                this.findProcessControlConnection([], cell, processControlConnections);
            } else if (itemEnum === ItemEnum.Risk) {
                this.findRiskControlConnection([], cell, riskControlConnections);
            }
        });

        const allShapes = this.getChildVertices(this.getDefaultParent());
        const controlCellIds = allShapes
            .filter(
                (cell) =>
                    cell != null && ItemEnum.fromShape(cell.getShape() as any) === ItemEnum.Control
            )
            .map((cell) => cell.id);

        return {
            riskControlConnections,
            processRiskConnections,
            controlCellIds,
            processControlConnections,
        };
    }

    public getConnectedItems(cell: mxType.mxCell): IModule[] {
        const diagramData: DiagramData = this.diagramData;
        const shape = cell.getShape() as any;
        switch (ItemEnum.fromShape(shape)) {
            case ItemEnum.Risk:
                return diagramData.risks.filter((x) => x.cellId === cell.id);
            case ItemEnum.Control:
                return diagramData.controls.filter((x) => x.cellId === cell.id);
        }
    }

    public getDiagramDataModule(itemEnum: ItemEnum): IModule[] {
        switch (itemEnum) {
            case ItemEnum.Risk:
                return this.diagramData.risks;
            case ItemEnum.Control:
                return this.diagramData.controls;
        }
    }

    public getConnectedRiskCount(cell: mxType.mxCell) {
        const diagramData: DiagramData = this.diagramData;

        if (!diagramData) {
            return 0;
        }

        return diagramData.risks.filter((x) => x.cellId === cell.id).length;
    }

    public getConnectedControlCount(cell: mxType.mxCell) {
        const diagramData: DiagramData = this.diagramData;

        if (!diagramData) {
            return 0;
        }

        return diagramData.controls.filter((x) => x.cellId === cell.id).length;
    }

    public getAllConnectionConstraints(terminal) {
        if (terminal != null && this.model.isVertex(terminal.cell)) {
            return [
                new mx.mxConnectionConstraint(new mx.mxPoint(0, 0), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(0.5, 0), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(1, 0), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(0, 0.5), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(1, 0.5), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(0, 1), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(0.5, 1), true),
                new mx.mxConnectionConstraint(new mx.mxPoint(1, 1), true),
            ];
        }

        return null;
    }

    public cellLabelChanged(cell: mxType.mxCell, newValue: any, autoSize: boolean) {
        if (mx.mxUtils.isNode(cell.value, null, null, null)) {
            this.model.beginUpdate();
            try {
                cell.value.setAttribute("label", newValue);
                if (autoSize) {
                    this.cellSizeUpdated(cell, false);
                }
            } finally {
                this.refresh(cell);
                this.model.endUpdate();
            }
        } else {
            super.cellLabelChanged(cell, newValue, autoSize);
        }
    }

    public getLabel(cell: mxType.mxCell): string {
        if (mx.mxUtils.isNode(cell.value, null, null, null)) {
            if (this.showOrderNr) {
                if (cell.value.nodeName.toLowerCase() === "extra") {
                    const orderNr = cell.getAttribute("order_nr");
                    if (typeof orderNr !== "undefined" && orderNr.length > 0) {
                        return "[" + orderNr + "]";
                    } else {
                        return "[?]";
                    }
                }
            }

            let label = cell.getAttribute("label");
            const shape = cell.getShape();

            if (shape === "riskcontrol") {
                const countControl = this.getConnectedControlCount(cell);
                label = `Controls ${countControl}`;
            } else if (shape === "risk") {
                const countRisk = this.getConnectedRiskCount(cell);
                label = `Risks ${countRisk}`;
            }

            return label;
        }

        return super.getLabel(cell);
    }

    public getEditingValue(cell: mxType.mxCell) {
        if (mx.mxUtils.isNode(cell.value, null, null, null)) {
            const label = cell.getAttribute("label");
            return label;
        }
        return super.getEditingValue(cell);
    }

    public loadDiagram(data: DiagramData) {
        return new Promise((resolve, reject) => {
            const graph = this;
            graph.diagramData = data;
            graph.getModel().beginUpdate();
            const mxCellCodedBackup = mx.mxCodecRegistry.codecs["mxCell"];
            try {
                const cell = new mx.mxCell();
                cell.insert(new mx.mxCell());
                graph.getModel().setRoot(cell);

                const graphXml = data.xmlGraph;
                if (graphXml !== "") {
                    const doc = mx.mxUtils.parseXml(graphXml);
                    const decoder = new mx.mxCodec(doc);
                    mx.mxCodecRegistry.codecs["mxCell"] = new CerrixCellCodec();
                    decoder.decode(doc.documentElement, graph.getModel());
                }
            } catch (err) {
                reject(err);
            } finally {
                mx.mxCodecRegistry.codecs["mxCell"] = mxCellCodedBackup;
                // Updates the display
                graph.getModel().endUpdate();
                graph.fireEvent(new mx.mxEventObject("dataLoaded", this));
                this.history.clear();
                resolve(null);
            }
        });
    }

    private containsCombination<T extends { process: string }>(
        list: T[],
        item: T,
        combinationsToCheck: Array<keyof T>
    ) {
        return list.some((x) => combinationsToCheck.every((c) => x[c] === item[c]));
    }

    private containsControl<T extends { control: string }>(
        riskControlConnections: T[],
        controlId: string
    ) {
        return riskControlConnections.some((x) => x.control === controlId);
    }

    private isProcess(element: mxType.mxCell) {
        const itemEnum = ItemEnum.fromShape(element.getShape() as any);
        return itemEnum === ItemEnum.Process || itemEnum === ItemEnum.PredefinedProcess;
    }

    private visit(visitedNodes, element: mxType.mxCell, operation: (cell: mxType.mxCell) => void) {
        let edgesCount = 0;

        if (visitedNodes.indexOf(element.id) > -1) {
            return;
        }

        visitedNodes.push(element.id);
        if (element.edges) {
            edgesCount = element.edges.length;

            for (let x = 0; x < edgesCount; x++) {
                const processEdge = element.edges[x];

                if (visitedNodes.indexOf(+processEdge.id === -1)) {
                    visitedNodes.push(processEdge.id);

                    if (processEdge.source != null && processEdge.source.id !== element.id) {
                        operation(processEdge.source);
                    } else if (processEdge.target && processEdge.target.id !== element.id) {
                        operation(processEdge.target);
                    }
                }
            }
        }
    }

    private findProcessRiskConnection(
        visitedNodes,
        element: mxType.mxCell,
        processRiskList: IProcessRiskConnection[]
    ) {
        const op = (cell: mxType.mxCell) => {
            const cellShape = cell.getShape();
            const connectionObject = {
                process: element.id,
                risk: cell.id,
            };

            const itemEnum = ItemEnum.fromShape(cellShape as any);

            if (
                itemEnum === ItemEnum.Risk &&
                this.isProcess(element) &&
                !this.containsCombination(processRiskList, connectionObject, ["process", "risk"])
            ) {
                processRiskList.push(connectionObject);
            } else if (itemEnum !== ItemEnum.Process && itemEnum !== ItemEnum.PredefinedProcess) {
                this.findProcessRiskConnection(visitedNodes, cell, processRiskList);
            }
        };

        this.visit(visitedNodes, element, op);
    }

    private findProcessControlConnection(
        visitedNodes,
        element: mxType.mxCell,
        processControlList: IProcessControlConnection[]
    ) {
        const op = (cell: mxType.mxCell) => {
            const cellShape = cell.getShape();
            const connectionObject = {
                process: element.id,
                control: cell.id,
            };

            const itemEnum = ItemEnum.fromShape(cellShape as any);

            if (
                itemEnum === ItemEnum.Control &&
                this.isProcess(element) &&
                !this.containsCombination(processControlList, connectionObject, [
                    "process",
                    "control",
                ])
            ) {
                processControlList.push(connectionObject);
            } else if (itemEnum !== ItemEnum.Process && itemEnum !== ItemEnum.PredefinedProcess) {
                this.findProcessControlConnection(visitedNodes, cell, processControlList);
            }
        };

        this.visit(visitedNodes, element, op);
    }

    private findRiskControlConnection(
        visitedNodes,
        element: mxType.mxCell,
        processControlList: IRiskControlConnection[]
    ) {
        const op = (cell: mxType.mxCell) => {
            const sourceShape = cell.getShape();
            const connectionSourceObject = {
                risk: element.id,
                control: cell.id,
            };

            const itemEnum = ItemEnum.fromShape(sourceShape as any);

            if (
                itemEnum === ItemEnum.Control &&
                !this.containsControl(processControlList, connectionSourceObject.control)
            ) {
                processControlList.push(connectionSourceObject);
            } else if (itemEnum !== ItemEnum.Process && itemEnum !== ItemEnum.PredefinedProcess) {
                this.findRiskControlConnection(visitedNodes, cell, processControlList);
            }
        };

        this.visit(visitedNodes, element, op);
    }

    private addHistoryListeners() {
        const model = this.getModel();
        const view = this.getView();
        const history = new mx.mxUndoManager(null);
        this.history = history;
        model.addListener(mx.mxEvent.UNDO, (sender, event) => {
            history.undoableEditHappened(event.getProperty("edit"));
        });
        view.addListener(mx.mxEvent.UNDO, (sender, event) => {
            history.undoableEditHappened(event.getProperty("edit"));
        });
        model.addListener(mx.mxEvent.REDO, (sender, event) => {
            history.undoableEditHappened(event.getProperty("edit"));
        });
        view.addListener(mx.mxEvent.REDO, (sender, event) => {
            history.undoableEditHappened(event.getProperty("edit"));
        });
    }
}
