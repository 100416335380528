import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ApiKeyModule } from "@app/user/shared/api-key/api-key.module";
import { SharedModule } from "@app/shared/shared.module";
import { StandingdataModule } from "@app/standingdata/standingdata.module";
import { UserMaintenanceModule } from "@app/user/user-maintenance/user-maintenance.module";
import { UserService } from "./shared/user.service";
import { UserProfileComponent } from "./user-profile.component";
import { UserNotificationSettingsModule } from "../shared/notification-settings/user-notification-settings.module";

@NgModule({
    imports: [
        ApiKeyModule,
        CommonModule,
        FormsModule,
        StandingdataModule,
        UserMaintenanceModule,
        SharedModule,
        UserNotificationSettingsModule,
    ],
    declarations: [UserProfileComponent],
})
export class UserProfileModule {
    static forRoot(): ModuleWithProviders<UserProfileModule> {
        return {
            ngModule: UserProfileModule,
            providers: [UserService],
        };
    }
}
