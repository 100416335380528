import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { Input, Component, OnInit } from "@angular/core";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ContactModel } from "@models/thirdparty/ContactModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "thirdparty-contacts",
    templateUrl: "thirdparty-contacts.component.html",
    styleUrls: ["thirdparty-contacts.component.scss"],
})
export class ThirdPartyContactsComponent implements OnInit {
    @Input() thirdParty: ThirdPartyModel;
    @Input() canEdit: boolean;

    contactConfig: GenericListConfig;

    constructor(private _promptService: CerrixPromptService) {}

    ngOnInit() {
        this.initContactsConfig();
    }

    initContactsConfig() {
        this.contactConfig = <GenericListConfig>{
            idProp: "id",
            uiidAdd: "thirdpartycontacts-addbutton",
            name: "Contact",
            isSortable: true,

            allowAdd: this.canEdit,
            allowDelete: this.canEdit,
            allowEdit: this.canEdit,

            data: this.thirdParty.Contacts,
            fields: [
                {
                    fieldName: "name",
                    prettyName: "Name",
                    uiid: "thirdpartycontacts-name",
                    required: true,
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "role",
                    prettyName: "Role",
                    uiid: "thirdpartycontacts-role",
                    fieldType: GenericListFieldType.Text,
                    editorWidth: "12",
                },
                {
                    fieldName: "phone",
                    prettyName: "Phone",
                    uiid: "thirdpartycontacts-phone",
                    fieldType: GenericListFieldType.Phone,
                    maxLength: 13,
                },
                {
                    fieldName: "email",
                    prettyName: "Email",
                    uiid: "thirdpartycontacts-email",
                    fieldType: GenericListFieldType.Email,
                },
            ],

            delete: async (row: ContactModel) => {
                return await this._promptService
                    .confirm("Delete contract", "Are you sure you want to delete this contract?")
                    .toPromise();
            },
        };
    }
}
