import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CerrixCalendarModule } from "@app/calendar/calendar.module";
import { SharedModule } from "./../shared/shared.module";
import { NavbarCalendarComponent } from "./navbar-plugins/navbar-calendar/navbar-calendar.component";
import { NavbarHelpComponent } from "./navbar-plugins/navbar-help/navbar-help.component";
import { NavbarSnowComponent } from "./navbar-snow/navbar-snow.component";
import { NavbarUserComponent } from "./navbar-plugins/navbar-user/navbar-user.component";
import { NavbarComponent } from "./navbar.component";

@NgModule({
    imports: [CommonModule, SharedModule, CerrixCalendarModule],
    declarations: [
        NavbarComponent,

        NavbarCalendarComponent,
        NavbarHelpComponent,
        NavbarSnowComponent,
        NavbarUserComponent,
    ],
    exports: [NavbarComponent],
})
export class NavbarModule {
    static forRoot(): ModuleWithProviders<NavbarModule> {
        return {
            ngModule: NavbarModule,
            providers: [],
        };
    }
}
