import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "risk-get-risk-and-control-data-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class RiskGetRiskAndControlDataApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Get Risk and Control Data API",
            httpMethod: "GET",
            path: "/riskdata/riskcontrol",

            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: {
                        risksandcontrols: [
                            {
                                "risk Organization Path": "string",
                                "risk Organization": "string",
                                "risk Catalogue": "string",
                                "risk Subtype": "string",
                                "risk Definition": "string",
                                "risk Name": "string",
                                "risk Identifier": "string",
                                "risk Description": "string",
                                "risk Business Dimension Paths": "string",
                                "risk Business Dimensions": "string",
                                cause: "string",
                                effect: "string",
                                "gross Likelihood": "string",
                                "gross Impact": "string",
                                "net Likelihood": "string",
                                "net Impact": "string",
                                "gross Score": "integer",
                                "net Score": "integer",
                                "gross Single loss Expectancy": "integer",
                                "gross Quantitative Likelihood": "integer",
                                "gross Annualized loss Expectancy": "integer",
                                "net Single loss Expectancy": "integer",
                                "net Quantitative Likelihood": "integer",
                                "net Annualized loss Expectancy": "integer",
                                "risk Area": "string",
                                "event Category": "string",
                                "risk Owner": "string",
                                "risk Treatment": "string",
                                "risk Appetite": "string",
                                "risk Appetite Comment": "string",
                                "risk Comments": "string",
                                confidential: "boolean",
                                "risk Applicable": "boolean",
                                approved: "boolean",
                                reviewed: "boolean",
                                "date Created": "datetime",
                                "risk Assessment": "string",
                                "risk Assessment Date": "datetime",
                                "control Identifier": "string",
                                "control Catalogue": "string",
                                "control Definition": "string",
                                type: "string",
                                source: "string",
                                "control Name": "string",
                                "control Description": "string",
                                "is Key Control": "boolean",
                                "requires Monitoring": "boolean",
                                "aspect IC": "string",
                                "control Frequency": "string",
                                "control Execution Method": "string",
                                "control in Place": "boolean",
                                "control Owner": "string",
                                "start Date": "datetime",
                                "end Date": "datetime",
                                "control Objective": "string",
                                "initial Costs": "decimal",
                                "annual Costs": "decimal",
                                "control Comments": "string",
                                "design Score": "string",
                                "design Score_COLOR": "color",
                                "implementation Score": "string",
                                "implementation Score_COLOR": "color",
                                "effectiveness Score": "string",
                                "effectiveness Score_COLOR": "color",
                                "latest Period Score": "string",
                                "latest Period Score_COLOR": "color",
                                "final Conclusion Score": "string",
                                "final Conclusion Score_COLOR": "color",
                                "comment ORM": "string",
                            },
                        ],
                    },
                },
            ],
        };
    }
}
