<div class="data-chart" *ngIf="!noAccess">
    <div class="row">
        <div class="col-9 text-truncate">
            <b [matTooltip]="data.name" *ngIf="data">{{ data.identifier }} - {{ data.name }}</b>
        </div>
        <div class="col-3">
            <i
                class="fas fa-question-circle float-end"
                matTooltip="View Information"
                (click)="showKriInfo()"
            ></i>
            <i class="fas fa-redo float-end me-2" matTooltip="Reload" (click)="load()"></i>
            <i
                class="fas fa-external-link float-end me-2"
                matTooltip="Open KRI"
                *ngIf="showOpen"
                (click)="openKri()"
            ></i>
        </div>
    </div>
    <hr />
    <div class="chart-container" #chartContainer></div>
</div>

<div class="no-access-message" *ngIf="noAccess">
    <span>This KRI does not exist or you have insufficient rights.</span>
</div>

<ng-template #kriInfoTemplate>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label>Aggregation Method</label>
                <input
                    class="form-control form-control-sm"
                    [value]="data.aggregationMethod"
                    readonly
                    disabled
                />
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Aggregate Score</label>
                <ng-select
                    [items]="[{ Name: data.aggregateScore, ID: 0 }]"
                    [disabled]="true"
                    [ngModel]="0"
                    bindLabel="Name"
                    bindValue="ID"
                >
                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        <div
                            [style.background-color]="data.aggregateScoreColor"
                            style="
                                min-width: 5px;
                                min-height: 18px;
                                float: left;
                                margin-right: 5px;
                                position: relative;
                                top: 2px;
                            "
                        ></div>
                        {{ item.Name }}
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Threshold green</label>
                <input
                    class="form-control form-control-sm"
                    [value]="data.greenThreshold"
                    readonly
                    disabled
                />
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Threshold red</label>
                <input
                    class="form-control form-control-sm"
                    [value]="data.redThreshold"
                    readonly
                    disabled
                />
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label>Total data entries</label>
                <input
                    class="form-control form-control-sm"
                    [value]="data.totalCount"
                    readonly
                    disabled
                />
            </div>
        </div>
        <div class="col-6" *ngFor="let count of data.counts">
            <div class="form-group">
                <label>{{ count.key }}</label>
                <input
                    class="form-control form-control-sm"
                    [value]="count.value"
                    readonly
                    disabled
                />
            </div>
        </div>
    </div>
</ng-template>
