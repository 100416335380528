import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Configuration } from "@app/app.constants";
import { ApiDataService } from "@services/http/ApiDataService";
import { ReleaseNoteModel } from "../models/release-notes-model";

@Injectable()
export class ReleaseNotesService extends ApiDataService {
    public constructor(http: HttpClient, configuration: Configuration) {
        super(http, configuration, "releasenotes");
    }

    public async getList(): Promise<string[]> {
        return firstValueFrom(this.get<string[]>());
    }

    public getVersion(version: string): Promise<ReleaseNoteModel> {
        return firstValueFrom(this.get<ReleaseNoteModel>("/" + version));
    }
}
