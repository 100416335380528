import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatChipsModule } from "@angular/material/chips";
import { ToastrService } from "ngx-toastr";
import { PresetService } from "../services/preset.service";
import { PresetViewModel } from "../models/preset-view.model";
import { PresetBaseModel } from "../models/preset-base.model";
import { DateTimeDisplayPipe } from "./../../shared/pipes/date-time-display.pipe";
import { MicroservicePermissionService } from "@services/permissions/permissions.service";
import { MicroserviceModule } from "@enums/microservice-module.enum";
import { IncidentPermissionType } from "@enums/permissions/incident-permission-type.enum";

@Component({
    selector: "app-presets-overview",
    templateUrl: "./preset-overview.component.html",
    styleUrls: ["./preset-overview.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatGridListModule,
        MatCardModule,
        MatTooltipModule,
        MatDividerModule,
        MatChipsModule,
        DateTimeDisplayPipe,
    ],
    providers: [PresetService, ToastrService],
})
export class PresetOverviewComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private permissionService: MicroservicePermissionService = inject(
        MicroservicePermissionService
    );
    private presetService: PresetService = inject(PresetService);
    private toastrService = inject(ToastrService);
    private dialogRef: MatDialogRef<PresetOverviewComponent, string> = inject(MatDialogRef);

    @Input() @Output() public activePresetId: string | null = inject(MAT_DIALOG_DATA);
    @Output() public favoriteAddEvent: EventEmitter<PresetBaseModel> =
        new EventEmitter<PresetBaseModel>();
    @Output() public favoriteRemoveEvent: EventEmitter<PresetBaseModel> =
        new EventEmitter<PresetBaseModel>();

    protected presets: PresetViewModel[] = [];

    public ngOnInit(): void {
        this.loadPresets();
    }

    protected loadPresets(): void {
        this.presetService
            .getPresets()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => (this.presets = data));
    }

    protected applyPreset(presetId: string) {
        this.activePresetId = presetId;
        this.dialogRef.close(presetId);
    }

    protected updateFavourite(preset: PresetBaseModel, newValue: boolean) {
        this.presetService
            .updateFavourite(preset.id, newValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                if (newValue) {
                    this.favoriteAddEvent.emit(preset);
                } else {
                    this.favoriteRemoveEvent.emit(preset);
                }

                this.loadPresets();
            });
    }

    protected deletePreset(presetId: string) {
        this.presetService
            .deletePreset(presetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.loadPresets());
    }

    protected updateUserDefault(preset: PresetBaseModel, newValue: boolean) {
        this.presetService
            .updateUserDefault(preset.id, newValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.loadPresets();

                if (newValue) {
                    const presetView = this.presets.find((p) => p.id == preset.id);
                    if (!presetView.favourite) {
                        this.updateFavourite(preset, true);
                    }
                }
            });
    }

    protected canSetSystemDefault(): boolean {
        return this.permissionService.hasPermission(
            MicroserviceModule.Incidents,
            IncidentPermissionType.SetSystemDefaultPreset
        );
    }

    protected updateSystemDefault(presetId: string, newValue: boolean) {
        this.presetService
            .updateSystemDefault(presetId, newValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.loadPresets());
    }

    protected updatePublic(presetId: string, newValue: boolean, isSystemDefault: boolean) {
        if (!newValue && isSystemDefault) {
            this.toastrService.warning("System default preset can only be public.");
            return;
        }

        this.presetService
            .updatePublic(presetId, newValue)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.loadPresets());
    }
}
