import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";
import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes, UrlSerializer } from "@angular/router";
import { ThirdpartyModule } from "@pages/thirdparty/thirdparty.module";
import { JwtInterceptor } from "@services/http/interceptors/jwt-intercepter";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerConfig, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SortablejsModule } from "./shared/sortablejs/sortablejs.module";
import { ToastrModule } from "ngx-toastr";
import { PageLookup } from "../common/constants/pages/page-lookup";
import { Pages } from "../common/constants/pages/Pages";
import { StandingDataMapping } from "../common/constants/standingdatamapping";
import { IsAuthenticated } from "../common/guards/IsAuthenticated";
import { HasRiskAccess } from "../common/guards/risk/HasRiskAccess";
import { ByteArray } from "../common/pipes/utils/ByteArray";
import { EnumKeyValuePair } from "../common/pipes/utils/EnumKeyValuePair";
import { TabPaneComponent } from "../core-components/tab/tab-pane/tab-pane.component";
import { TabComponent } from "../core-components/tab/tab.component";
import { TabMenuComponent } from "../core-components/tab/tabmenu/tabmenu.component";
import { TabSystemDirective } from "../directives/tab-system.directive";
import { InviteComponent } from "../pages/invite/invite.component";
import { DocumentDataService } from "../services/http/document/DocumentDataService";
import { JsonDateHttpInterceptor } from "../services/http/interceptors/json-date-http-interceptor";
import { ResponseHttpInterceptor } from "../services/http/interceptors/response-http-interceptor";
import { InviteDataService } from "../services/http/invite/InviteDataService";
import { PermissionsDataService } from "../services/http/permissions/PermissionsDataService";
import { SettingsDataService } from "../services/http/SettingsDataService";
import { PermissionsService } from "../services/permissions/PermissionsService";
import { TabService } from "../services/tabs/TabService";
import { LowerCaseUrlSerializer } from "../services/URLSerializer";
import { TabMenuItemChildSelectedPipe } from "./../common/pipes/utils/tab-menu.pipe";
import { TuckedMenuComponent } from "./../core-components/tab/tuckedmenu/tuckedmenu.component";
import { AboutComponent } from "./about/about.component";
import { AdminModule } from "./admin/admin.module";
import { ApiKeyModule } from "./user/shared/api-key/api-key.module";
import { AppComponent } from "./app.component";
import { AppConstants, Configuration } from "./app.constants";
import { AssessmentModule } from "./assessment/assessment.module";
import { AuditFindingModule } from "./audit-finding/audit-finding.module";
import { AuditUniversePeriodModule } from "./audit-universe-period/audit-universe-period.module";
import { AuditUniverseModule } from "./audit-universe/audit-universe.module";
import { AuditModule } from "./audit/audit.module";
import { AuthenticationModule } from "./authentication/authentication.module";
import { LoginComponent } from "./authentication/login/login.component";
import { CerrixCalendarModule } from "./calendar/calendar.module";
import { CerrixAppComponent } from "./cerrix-app/cerrix-app.component";
import { ControlModule } from "./controls/control.module";
import { AdvancedTestingModule } from "./controls/testing/advanced/advanced-testing.module";
import { SimpleTestingModule } from "./controls/testing/simple/simple-testing.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { DatamanagementModule } from "./datamanagement/datamanagement.module";
import { DeletedItemsModule } from "./deleted-items/deleted-items.module";
import { AdminDocumentsComponent } from "./documents/admin/admin-documents.component";
import { SharedDocumentsComponent } from "./documents/shared/shared-documents.component";
import { BulkDeletedDocumentsComponent } from "./documents/bulk-deleted/bulk-deleted-documents.component";
import { EventModule } from "./event/event.module";
import { EventDataService } from "./event/services/EventDataService";
import { ExportOverviewComponent } from "./export/export-overview.component";
import { ExportModule } from "./export/export.module";
import {
    ExportBimComponent,
    ExportControlsComponent,
    ExportDatamanagementComponent,
    ExportEventsComponent,
    ExportRisksComponent,
    ExportTasksComponent,
} from "./export/exporttypes";
import { ExternalConnectionModule } from "./external-connections/external-connection.module";
import { FindingReportModule } from "./findingreport/finding-report.module";
import { CerrixFormsModule } from "./forms/forms.module";
import { KriModule } from "./kri/kri.module";
import { MailMessagesModule } from "./mailmessages/mailmessages.module";
import { ManualModule } from "./manual/manual.module";
import { MoiModule } from "./moi/moi.module";
import { NavbarModule } from "./navbar/navbar.module";
import { BusinessNavigatorModule } from "./navigators/business-navigator/business-navigator.module";
import { PeriodsModule } from "./periods/periods.module";
import { ProcessEditorModule } from "./process-editor/process-editor.module";
import { ReleaseNotesModule } from "./release-notes/release-notes.module";
import { ReportModule } from "./report/report.module";
import { RiskModule } from "./risk/risk.module";
import { RiskDataService } from "./risk/services/RiskDataService";
import { SettingsComponent } from "./settings/settings.component";
import { AppConfigService } from "./shared/services/app-config.service";
import { ErrorHandlerService } from "./shared/services/error-handler.service";
import { MonitoringService } from "./shared/services/monitoring.service";
import { SharedModule } from "./shared/shared.module";
import { StandingdataModule } from "./standingdata/standingdata.module";
import { TaskModule } from "./tasks/task.module";
import { WopiModule } from "./wopi/wopi.module";
import { UserMaintenanceModule } from "./user/user-maintenance/user-maintenance.module";
import { UserProfileModule } from "./user/user-profile/user-profile.module";
import { ScheduleModule } from "@syncfusion/ej2-angular-schedule";
import { DocumentTemplatesModule } from "./document-templates/document-templates.module";
import { JobSchedulerModule } from "./job-scheduler/job-scheduler.module";
import { AccessTokenInterceptorService } from "@services/http/interceptors/access-token-interceptor";
import { ContextMenuService } from "@syncfusion/ej2-angular-grids";
import { DocumentIntelligenceModule } from "./document-intelligence/document-intelligence.module";
import { OrganizationBudgetComponent } from "./org-budget/organization-budget.component";
import { OrganizationBudgetDataService } from "./org-budget/organization-budget-data.service";

const routes: Routes = [
    { path: "login", component: LoginComponent },
    {
        path: "",
        component: CerrixAppComponent,
        pathMatch: "full",
        canLoad: [IsAuthenticated],
        canActivate: [IsAuthenticated],
    },
    {
        path: "**",
        component: CerrixAppComponent,
        canLoad: [IsAuthenticated],
        canActivate: [IsAuthenticated],
    },
];

export function initializeAppConfig(
    appConfigService: AppConfigService,
    monitoringService: MonitoringService
) {
    return () =>
        appConfigService.initialize().then((config) => {
            // Must initialize here because ErrorHandlers are loaded before APP_INITIALIZER
            monitoringService.initialize(config.appInsightsInstrumentationKey);
        });
}

export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: "DD-MM-YYYY",
        rangeInputFormat: "DD-MM-YYYY",
        containerClass: "theme-default",
        customTodayClass: "datepicker-today",
    });
}

@NgModule({
    declarations: [
        AppComponent,
        CerrixAppComponent,
        // Pipes
        ByteArray,
        EnumKeyValuePair,
        TabMenuItemChildSelectedPipe,
        // Directives
        TabSystemDirective,
        // Core-Components
        TabComponent,
        TabMenuComponent,
        TuckedMenuComponent,
        TabPaneComponent,
        // Pages
        AboutComponent,
        AdminDocumentsComponent,
        SharedDocumentsComponent,
        BulkDeletedDocumentsComponent,
        ExportOverviewComponent,
        ExportRisksComponent,
        ExportEventsComponent,
        ExportBimComponent,
        ExportControlsComponent,
        ExportDatamanagementComponent,
        ExportTasksComponent,
        SettingsComponent,
        InviteComponent,
        OrganizationBudgetComponent,
    ],
    exports: [
        // Services
        HttpClientModule,
        RouterModule,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        ScheduleModule,
        // Modules
        SharedModule.forRoot(),
        NavbarModule.forRoot(),
        MoiModule.forRoot(),
        KriModule.forRoot(),
        AdvancedTestingModule.forRoot(),
        EventModule.forRoot(),
        FindingReportModule,
        AssessmentModule,
        CerrixFormsModule.forRoot(),
        CerrixCalendarModule.forRoot(),
        ControlModule.forRoot(),
        SimpleTestingModule.forRoot(),
        AdminModule.forRoot(),
        JobSchedulerModule.forRoot(),
        RiskModule.forRoot(),
        DatamanagementModule.forRoot(),
        BusinessNavigatorModule.forRoot(),
        UserMaintenanceModule.forRoot(),
        ProcessEditorModule.forRoot(),
        AuthenticationModule.forRoot(),
        UserProfileModule.forRoot(),
        ThirdpartyModule.forRoot(),
        ExportModule,
        StandingdataModule.forRoot(),
        DeletedItemsModule.forRoot(),
        PeriodsModule.forRoot(),
        MailMessagesModule.forRoot(),
        ApiKeyModule.forRoot(),
        DashboardModule.forRoot(),
        ReportModule.forRoot(),
        ManualModule.forRoot(),
        ReleaseNotesModule.forRoot(),
        AuditUniverseModule.forRoot(),
        AuditUniversePeriodModule.forRoot(),
        AuditModule.forRoot(),
        AuditFindingModule.forRoot(),
        ExternalConnectionModule.forRoot(),
        TaskModule.forRoot(),
        WopiModule.forRoot(),
        DocumentTemplatesModule,
        DocumentIntelligenceModule.forRoot(),
        RouterModule,
        // Routing
        // Routing
        RouterModule.forRoot(routes),
        // Libraries
        ToastrModule.forRoot({
            progressBar: true,
            progressAnimation: "increasing",
            positionClass: "toast-bottom-right",
        }),
        AccordionModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        SortablejsModule.forRoot({ animation: 150 }),
        BsDatepickerModule.forRoot(),
    ],
    providers: [
        Configuration,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
        TabService,
        SettingsDataService,
        PermissionsService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppConfig,
            deps: [AppConfigService, MonitoringService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonDateHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        RiskDataService,
        EventDataService,
        PermissionsDataService,
        DocumentDataService,
        InviteDataService,
        OrganizationBudgetDataService,
        // Constants
        Pages,
        PageLookup,
        StandingDataMapping,
        // Pipes
        ByteArray,
        EnumKeyValuePair,
        DecimalPipe,
        CurrencyPipe,
        PercentPipe,
        TabMenuItemChildSelectedPipe,
        // Guards
        IsAuthenticated,
        HasRiskAccess,
        // Syncfusion
        ContextMenuService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        AppConstants.AppInjector = injector;
        AppConstants.DefaultIcon = "fas fa-building";
    }
}
