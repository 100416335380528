<ng-container *ngIf="detailed">
    <div class="selected-icon-area" (click)="clicked()">
        <div class="selected-icon">
            <ng-container [ngTemplateOutlet]="iconTemplate"> </ng-container>
        </div>
        <input
            class="form-control form-control-sm icon-name"
            value="{{ iconName | titlecase }} ({{ selector }})"
            disabled
            type="text"
            inputTooltip
        />
    </div>
</ng-container>

<ng-container *ngIf="!detailed" [ngTemplateOutlet]="iconTemplate"> </ng-container>

<ng-template #iconTemplate>
    <i
        class="{{ selector }} {{ appendClass }}"
        [class.fa-spin]="animated"
        [class.fa-border]="bordered"
        [class.darkmode-icon]="color == defaultColor"
        [style.color]="color | safe: 'style'"
    ></i>
</ng-template>
