import { FormStructureType } from "@app/forms/enums/FormStructureType";

export class NewIncidentStructureTypes {
    public static newIncidentStructures: FormStructureType[] = [
        FormStructureType.IncidentType,
        FormStructureType.Classification,
        FormStructureType.Effects,
        FormStructureType.ReputationImpact,
        FormStructureType.BusinessLine,
        FormStructureType.PersonalData,
        FormStructureType.ConsequencesPersonalDataBreach,
        FormStructureType.ConsequencesCia,
        FormStructureType.NatureOfIncident,
        FormStructureType.ReasonNoNofication,
        FormStructureType.ActiveSector,
        FormStructureType.BreachType,
        FormStructureType.BreachCategory,
        FormStructureType.PersonalDataSpecialCategories,
        FormStructureType.PersonsInvolved,
    ];
}
