import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { WorkflowStepPermissionModel } from "../../../shared/models/workflow-step-permission-model";
import { DocumentManagerPermissionModel } from "@models/moi/MoiDocumentPermissionModel";
import { DocumentModel } from "@models/documents/documentmodel";
import { EvidenceTestStepEditModel } from "../../../shared/models/evidence-test-step-edit-model";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AdvEffDataService } from "../../../shared/services/adv-eff-data.service";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { nameof } from "@methods/jeffs-toolkit";
import { map } from "rxjs/operators";
import { Observable, defer } from "rxjs";
import { TargetModule } from "@enums/document/TargetModule";
import { ValidationModel } from "../../../shared/models/validation-model";
import { getFormControl } from "@methods/CommonMethods";
import { SelectOptionColorModel } from "@models/generic";

@Component({
    selector: "adv-eff-evidence-test-step-editor",
    templateUrl: "./adv-eff-evidence-test-step-editor.component.html",
    styleUrls: ["./adv-eff-evidence-test-step-editor.component.scss"],
})
export class EvidenceTestStepEditorComponent implements OnInit {
    evidenceDocumentPermissions: DocumentManagerPermissionModel;
    documentTypeTargets = TargetModule.Control;

    @Input() useReviewer = false;
    @Input() workflowStepPermission: WorkflowStepPermissionModel;
    @Input() evidenceItem: EvidenceTestStepEditModel;
    @Input() showAllFields: boolean;
    @Input() scores: SelectOptionColorModel<number>[];

    @Output() testStepScoreChange = new EventEmitter<string>();

    @ViewChild("container", { static: true }) container: ElementRef;

    get finishedLoading(): boolean {
        return !!this.workflowStepPermission && !!this.evidenceItem;
    }
    get documentsDisabled(): boolean {
        return (
            !this.workflowStepPermission.canViewTestStepDocuments ||
            getFormControl<EvidenceTestStepEditModel>(this.mainFormGroup, this.etseDocumentsProp)
                .disabled
        );
    }

    mainFormGroup: FormGroup;

    etseDocumentsProp: keyof EvidenceTestStepEditModel;
    etseScoreProp: keyof EvidenceTestStepEditModel;
    etseCommentTesterProp: keyof EvidenceTestStepEditModel;
    etseCommentReviewerProp: keyof EvidenceTestStepEditModel;

    constructor(private advancedTestingService: AdvEffDataService) {
        this.initializeModelProps();
    }

    ngOnInit() {
        this.evidenceDocumentPermissions = new DocumentManagerPermissionModel();
        this.evidenceDocumentPermissions.canAddDocument = false;

        this.initFormValidation();
    }

    saveChanges(): Observable<EvidenceTestStepEditModel> {
        FormValidationHelper.mapToModel(this.mainFormGroup, this.evidenceItem);

        return defer(async () => {
            this.evidenceItem.status = this.workflowStepPermission.status;

            return await this.advancedTestingService
                .postEvidenceTestStep(
                    this.evidenceItem.effectivenessGuid,
                    this.evidenceItem.sampleGuid,
                    this.evidenceItem.guid,
                    this.evidenceItem
                )
                .pipe(
                    map((result) => {
                        this.evidenceItem.updateValues(result);
                        this.initFormValidation();
                        return result;
                    })
                )
                .toPromise();
        });
    }

    isValid(): ValidationModel {
        let isValid = this.mainFormGroup.valid;
        let messages: string[] = [];

        if (!isValid) {
            var errors = FormValidationHelper.getFormControlErrors(this.mainFormGroup);
            if (errors[nameof<EvidenceTestStepEditModel>((x) => x.score)])
                messages.push(
                    `Score is required for test step ${this.evidenceItem.name} for sample ${this.evidenceItem.sampleName}`
                );
            if (errors[nameof<EvidenceTestStepEditModel>((x) => x.commentTester)])
                messages.push(
                    `Comment is required for test step ${this.evidenceItem.name} for sample ${this.evidenceItem.sampleName}`
                );
        }

        return { valid: isValid, errors: messages };
    }

    isDirty(): boolean {
        return this.mainFormGroup.dirty;
    }

    markTouched() {
        FormValidationHelper.markAllAsTouched(this.mainFormGroup);
    }

    onDocumentsChange(documents: DocumentModel[]) {
        const documentsControl = getFormControl<EvidenceTestStepEditModel>(
            this.mainFormGroup,
            this.etseDocumentsProp
        );
        documentsControl.setValue(documents);
        documentsControl.markAsDirty();
        documentsControl.updateValueAndValidity();
    }

    private initFormValidation() {
        if (!this.mainFormGroup) {
            this.mainFormGroup = new FormGroup({
                documents: new FormControl(),
                score: new FormControl({ value: this.evidenceItem.score, disabled: true }),
                commentTester: new FormControl({
                    value: this.evidenceItem.commentTester,
                    disabled: true,
                }),
                commentReviewer: new FormControl({
                    value: this.evidenceItem.commentReviewer,
                    disabled: true,
                }),
            });

            const scoreControl = getFormControl<EvidenceTestStepEditModel>(
                this.mainFormGroup,
                this.etseScoreProp
            );

            scoreControl.valueChanges.subscribe((_) => {
                if (this.workflowStepPermission.testerAssessmentEnabled) {
                    this.testStepScoreChange.emit(this.evidenceItem.sampleGuid);
                }
            });
        } else {
            FormValidationHelper.updateFormGroup(this.evidenceItem, this.mainFormGroup);
        }

        // Disable on evidence doesn't work as formState most likely because it's not directly used in html
        // So set to disabled manually
        const documentsControl = getFormControl<EvidenceTestStepEditModel>(
            this.mainFormGroup,
            this.etseDocumentsProp
        );
        documentsControl.disable();

        if (this.workflowStepPermission.evidenceUploadEnabled) {
            this.evidenceDocumentPermissions.canAddDocument = true;
            documentsControl.enable();
        } else if (this.workflowStepPermission.testerAssessmentEnabled) {
            const scoreControl = getFormControl<EvidenceTestStepEditModel>(
                this.mainFormGroup,
                this.etseScoreProp
            );
            scoreControl.setValidators([Validators.required, Validators.min(1), Validators.max(4)]);
            scoreControl.enable({ emitEvent: false });

            const commentTesterControl = getFormControl<EvidenceTestStepEditModel>(
                this.mainFormGroup,
                this.etseCommentTesterProp
            );
            commentTesterControl.enable();

            this.evidenceDocumentPermissions.canAddDocument = true;
            documentsControl.enable();
        } else if (this.workflowStepPermission.reviewerAssessmentEnabled) {
            const commentReviewerControl = getFormControl<EvidenceTestStepEditModel>(
                this.mainFormGroup,
                this.etseCommentReviewerProp
            );
            commentReviewerControl.enable();

            this.evidenceDocumentPermissions.canAddDocument = true;
            documentsControl.enable();
        }
    }

    private initializeModelProps() {
        this.etseDocumentsProp = nameof<EvidenceTestStepEditModel>((x) => x.documents);
        this.etseScoreProp = nameof<EvidenceTestStepEditModel>((x) => x.score);
        this.etseCommentTesterProp = nameof<EvidenceTestStepEditModel>((x) => x.commentTester);
        this.etseCommentReviewerProp = nameof<EvidenceTestStepEditModel>((x) => x.commentReviewer);
    }
}
