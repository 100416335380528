import { Component } from "@angular/core";
import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { BaseWidget } from "../BaseWidget";

@Component({
    selector: "video-widget",
    templateUrl: "./video-widget.component.html",
    styleUrls: ["./video-widget.component.scss"],
})
export class VideoWidgetComponent extends BaseWidget {
    configuredUrl = "";

    constructor() {
        super();
    }

    load() {
        const filterValue = this.getFilter(WidgetFilterKey.VideoWidget.Url);
        if (filterValue) {
            this.configuredUrl = filterValue;
        }

        super.load();
    }
}
