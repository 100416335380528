<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar" *ngIf="processingPurpose">
    <button class="btn btn-cerrix" (click)="save()" *ngIf="permissions.canSave">
        <i class="fas fa-save"></i>
        <span data-uiid="dmdetail-save" class="d-none d-lg-inline">Save data processing</span>
    </button>

    <span class="date-edited" *ngIf="processingPurpose.ID > 0">
        Last save: {{ processingPurpose.lastSaveDate | dateTimeFormat }}
    </span>
</div>

<div
    class="datamanagement-screen-system"
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    *ngIf="processingPurpose"
>
    <div
        class="datamanagement-screens"
        [attr.name]="'Data Processing'"
        menuicon="fas fa-database"
        menusystemsubmenu
    >
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <datamanagement-detail-main
                [processingPurpose]="processingPurpose"
                [parentForm]="parentForm"
                [permissions]="permissions"
            >
            </datamanagement-detail-main>
        </div>
        <div
            *ngIf="processingPurpose && processingPurpose.ID > 0"
            name="Manage Data Structures"
            menuItemId="manageDataStructures"
            menuicon="fas fa-stream"
            menusystemitem
        >
            <processingpurpose-datastructure
                [processingPurpose]="processingPurpose"
                [permissions]="permissions"
                #dataStructureComponent
            >
            </processingpurpose-datastructure>
        </div>
        <div
            class="datamanagement-screens"
            [attr.name]="'Links'"
            menuicon="fas fa-link"
            menusystemsubmenu
        >
            <div
                *ngIf="processingPurpose && processingPurpose.ID > 0"
                name="Measures of Improvement"
                menuicon="fas fa-tasks"
                menuItemId="dmMois"
                menusystemitem
            >
                <linked-moi-overview
                    [moiType]="dmMoiType"
                    [parentGuid]="processingPurpose.guid"
                    [canAdd]="permissions.canAddMoi"
                    [canDelete]="permissions.canDeleteMoi"
                    #dmMoisComponent
                >
                </linked-moi-overview>
            </div>
            <div
                *ngIf="processingPurpose && processingPurpose.ID > 0"
                name="Risks"
                menuItemId="risks"
                menuicon="fas fa-exclamation-triangle"
                menusystemitem
            >
                <datamanagement-risks
                    #risksComponent
                    [model]="processingPurpose"
                    [permissions]="permissions"
                >
                </datamanagement-risks>
            </div>
            <div
                *ngIf="processingPurpose && processingPurpose.ID > 0"
                name="Controls"
                menuItemId="controls"
                menuicon="fas fa-shield-alt"
                menusystemitem
            >
                <datamanagement-controls
                    #controlsComponent
                    [model]="processingPurpose"
                    [permissions]="permissions"
                >
                </datamanagement-controls>
            </div>
            <div
                *ngIf="processingPurpose.ID > 0"
                name="Documents"
                menuItemId="documents"
                menuicon="far fa-file"
                menusystemitem
            >
                <div class="fancy-loader submodule" *ngIf="!documents"></div>
                <div *ngIf="documents">
                    <document-manager-old
                        [(Documents)]="processingPurpose.documents"
                        [permissions]="permissions.documentPermissions"
                        (DocumentsChange)="checkDirty()"
                        [DocumentTypeTargets]="targetModule"
                    ></document-manager-old>
                </div>
            </div>
            <div
                name="Hyperlinks"
                [attr.menuItemID]="hyperlinksMenuItemId"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="processingPurpose.ID > 0"
            >
                <div *ngIf="!processingPurpose.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="processingPurpose.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="processingPurpose.Hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>

        <div
            *ngIf="processingPurpose && processingPurpose.ID > 0"
            name="History"
            menuicon="fas fa-history"
            menusystemsubmenu
        >
            <div
                name="Data Processing Details History"
                menuicon="fas fa-bolt"
                menusystemitem
                menuItemId="detailsHistory"
            >
                <app-history-overview
                    [Id]="processingPurpose.guid"
                    [historyDataService]="_datamanagementDS"
                    ExtraParam="detail"
                    #historyDetailOverviewComponent
                >
                </app-history-overview>
            </div>
            <div
                name="Manage Data Structures History"
                menuicon="fas fa-stream"
                menusystemitem
                menuItemId="mdsHistory"
            >
                <app-history-overview
                    [Id]="processingPurpose.guid"
                    [historyDataService]="_datamanagementDS"
                    ExtraParam="mds"
                    #historyMdsOverviewComponent
                >
                </app-history-overview>
            </div>
        </div>

        <div
            *ngIf="processingPurpose && processingPurpose.ID > 0"
            data-uiid="dmdetail-options"
            name="Options"
            menuItemId="options"
            menuicon="fas fa-check-double"
            menusystemitem
        >
            <detail-options
                moduleName="data processing"
                [objectName]="processingPurpose ? processingPurpose.name : 'data processing'"
                [allowDelete]="permissions.canDelete"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
