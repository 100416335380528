export enum NavigatorConfigType {
    Risk = "Risks",
    Control = "Controls",
    Event = "Events",
    Moi = "Measures of Improvements",
    DataProcessing = "Data Processings Items",
    Kri = "Risk & Performance Indicators",
    BusinessReview = "Overall Reviews",
    LinkedControl = "Linked Controls",
    Info = "Info",
    ThirdParty = "Third Parties",
}
