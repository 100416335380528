<div class="detail-page-card" [formGroup]="mainFormGroup" *ngIf="kri && mainFormGroup">
    <div class="detail-page-card-title">Kri Data</div>

    <div class="detail-page-card-actions" *ngIf="canEdit">
        <button class="btn btn-raised btn-sm green" (click)="addData()">
            <i class="fas fa-plus"></i>
            <span class="d-none d-lg-inline ms-2">Add</span>
        </button>
        <button class="btn btn-raised btn-sm" (click)="exportData()" *ngIf="kri && kri.guid">
            <i class="fas fa-file-excel"></i>
            <span class="d-none d-lg-inline ms-2">Export</span>
        </button>
        <div class="flex-xl-fill"></div>
        <div class="d-flex flex-fill ms-2 overflow-hidden">
            <cerrix-select-tree
                *ngIf="organizations"
                [tree]="organizations"
                (onChangeSingleId)="targetedOrganization = $event"
                [multiselect]="false"
                class="flex-grow-1 flex-shrink-1 overflow-hidden"
            >
            </cerrix-select-tree>
            <button
                class="btn btn-raised btn-sm success ms-1"
                [disabled]="targetedOrganization == null || highlightedRowIndex == null"
                (click)="applyOrganization()"
            >
                <i class="fas fa-long-arrow-alt-down"></i>
                <span class="d-none d-lg-inline ms-2">Apply</span>
            </button>
        </div>
    </div>

    <div class="detail-page-card-body overflow-auto">
        <table class="table-cerrix rounded hoverable centered striped p-0">
            <thead>
                <tr class="row">
                    <th class="col-md-2">Value</th>
                    <th class="col-md-2">Date</th>
                    <th class="col-md-2">Time</th>
                    <th class="col-md-5">Organization</th>
                    <th class="col-md-1"></th>
                </tr>
            </thead>
            <tbody formArrayName="datas">
                <tr
                    class="row"
                    [formGroupName]="i"
                    *ngFor="let data of mainFormGroup.controls.datas['controls']; let i = index"
                    (click)="highlightedRowIndex = i"
                    [class.highlighted]="i === highlightedRowIndex"
                >
                    <td class="col-md-2">
                        <div class="data-value">
                            <div
                                class="datapoint-score"
                                [ngStyle]="{ background: getKriDataItemScore(data) }"
                            ></div>
                            <input
                                class="form-control form-control-sm"
                                type="number"
                                formControlName="value"
                            />
                        </div>
                    </td>
                    <td class="col-md-2">
                        <date-input formControlName="date"></date-input>
                    </td>
                    <td class="col-md-2">
                        <input
                            class="form-control form-control-sm"
                            type="time"
                            step="1"
                            formControlName="time"
                        />
                    </td>
                    <td class="col-md-5">
                        <input type="hidden" formControlName="organizationId" />
                        <span class="selected-organization">{{
                            data.controls.organizationName.value
                        }}</span>
                    </td>
                    <td class="col-md-1">
                        <i
                            class="fas fa-trash"
                            *ngIf="canEdit && !data.delete"
                            (click)="deleteDataRow(i)"
                        ></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
