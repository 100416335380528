import { Component } from "@angular/core";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "navbar-help",
    templateUrl: "./navbar-help.component.html",
    styleUrls: ["../navbar-plugin-base.scss", "./navbar-help.component.scss"],
})
export class NavbarHelpComponent {
    constructor(private _tabService: TabService, private _pages: Pages) {}

    openManuals() {
        this._tabService.generateTab(this._pages.Manuals);
    }

    openReleaseNotes() {
        this._tabService.generateTab(this._pages.ReleaseNotes);
    }

    openAbout() {
        this._tabService.generateTab(this._pages.About);
    }
}
