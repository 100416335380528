import { AbstractControl, FormGroup } from "@angular/forms";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { firstValueFrom, Observable } from "rxjs";
import _ from "underscore";
declare var $: any;

export function setAppAccentColor(accentColor: string) {
    var stylesheet = document.querySelector<HTMLElement>(":root, ::before, ::after").style;

    var conversion = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(accentColor);
    var rgb = conversion
        ? {
              r: parseInt(conversion[1], 16),
              g: parseInt(conversion[2], 16),
              b: parseInt(conversion[3], 16),
          }
        : null;
    if (!rgb) {
        return;
    }

    stylesheet.setProperty("--accent", `${rgb.r}, ${rgb.g}, ${rgb.b}`);
}

export function IsMobile() {
    const agent = navigator.userAgent;
    return (
        agent.match(/Android/i) ||
        agent.match(/webOS/i) ||
        agent.match(/iPhone/i) ||
        agent.match(/iPad/i) ||
        agent.match(/iPod/i) ||
        agent.match(/BlackBerry/i) ||
        agent.match(/Windows Phone/i)
    );
}

export function IsSmallDevice() {
    const isMobile = IsMobile();
    if (isMobile) {
        return true;
    }

    if (window.innerWidth <= 800) {
        return true;
    }

    return false;
}

export function IEVersion() {
    const sAgent = window.navigator.userAgent;
    const Idx = sAgent.indexOf("MSIE");

    // If IE, return version number.
    if (Idx > 0) {
        return Number.parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
    } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
        // If IE 11 then look for Updated user agent string.
        return 11;
    } else {
        return 0; // It is not IE
    }
}

export function download(url: string, name?: string) {
    const agent = navigator.userAgent;

    // Firefox doesn't close tab from hyperlink
    if (agent.indexOf("Firefox") !== -1) {
        window.open(
            url,
            "Download",
            "width=600,height=100,location=0,resizable=1,menubar=0,status=0"
        );
    } else {
        downloadWithLink(url, name);
    }
}

export function downloadWithLink(url: string, name?: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.setAttribute("type", "hidden");
    if (name) {
        downloadLink.download = name;
    }

    // Some browsers require the element to be part of the DOM
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
}

export function copyProperties(copyTo: object, copyFrom: object) {
    const properties = Object.getOwnPropertyNames(copyFrom);
    properties.forEach((p) => (copyTo[p] = copyFrom[p]));
}

export function tryParseJson(jsonString: string): boolean | object {
    try {
        const o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {}

    return false;
}

export function parseTyped<T>(jsonString: string, fallbackValue: T): T {
    const obj = tryParseJson(jsonString);
    if (obj) {
        return <T>(<unknown>obj);
    }

    return fallbackValue;
}

export function getFormControl<T>(form: FormGroup, name: keyof T): AbstractControl {
    return form.controls[name.toString()];
}

export function getFormValue<T>(form: FormGroup, name: keyof T) {
    const formControl = getFormControl(form, name);

    return formControl.value;
}

export function isNumberKey(evt) {
    const charCode = evt.charCode;
    return 48 <= charCode && charCode <= 57;
}

export function isDirty(original: {}, updated: {}): boolean {
    const origString = JSON.stringify(original);
    const upString = JSON.stringify(updated);

    return origString !== upString;
}

export function isObjectDirty<T>(
    original: {},
    updated: {},
    ...ignoredProperties: (keyof T)[]
): boolean {
    return Object.keys(getDifferences(original, updated, ...ignoredProperties)).length > 0;
}

/** Returns the differences between two objects, ignoring the order */
export function getDifferences<T>(original: {}, updated: {}, ...ignoredProperties: (keyof T)[]) {
    if (ignoredProperties.length > 0) {
        original = JSON.parse(JSON.stringify(original));
        updated = JSON.parse(JSON.stringify(updated));

        ignoredProperties.forEach((element) => {
            const propertyToRemove = element.toString();

            delete original[propertyToRemove];
            delete updated[propertyToRemove];
        });
    }

    const changes = (object, base) =>
        _.pick(
            _.mapObject(object, (value, key) =>
                !_.isEqual(value, base[key])
                    ? _.isObject(value) && _.isObject(base[key])
                        ? changes(value, base[key])
                        : value
                    : null
            ),
            (value) => value !== null
        );
    return changes(updated, original);
}

export function setEmptyStringsToNull<T>(model: T) {
    for (const [key, value] of Object.entries(model)) {
        if (Object.prototype.toString.call(value) === "[object String]") {
            if (!value || isNullOrWhitespace(value)) {
                model[key] = null;
            }
        }
    }
    return model;
}

export function setFormControlValue<T>(formGroup: FormGroup, name: keyof T, value: any) {
    const formControl = getFormControl(formGroup, name);

    formControl.setValue(value);
}

export function copyText(text: string) {
    const input = document.createElement("input");
    input.style.position = "fixed";
    input.style.left = "0";
    input.style.top = "0";
    input.style.opacity = "0";
    input.value = text;
    document.body.appendChild(input);

    input.focus();
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
}

export function isNullOrEmpty(text: string): boolean {
    return !text || text.length === 0;
}

export function isNullOrWhitespace(text: string): boolean {
    return !text || text.length === 0 || text.match(/^ *$/) !== null;
}

export function nameof<T>(key: keyof T, _instance?: T): keyof T {
    return key;
}

export function closeDirtyPageCheck(
    tab: TabModel,
    promptService: CerrixPromptService,
    checkOnly: boolean,
    preBoolCheck?: boolean
): boolean {
    if (preBoolCheck === true) {
        return true;
    }

    if (!checkOnly) {
        promptService
            .confirm(
                "Unsaved changes",
                "There are unsaved changes, are you sure you want to discard your changes?"
            )
            .onConfirm()
            .subscribe(() => {
                tab.beforeClose = () => true;
                tab.close();
            });
    }

    return false;
}

export function refreshDirtyPageCheck(
    tab: TabModel,
    promptService: CerrixPromptService,
    checkOnly: boolean,
    preBoolCheck?: boolean
): boolean {
    if (preBoolCheck === true) {
        return true;
    }

    if (!checkOnly) {
        promptService
            .confirm(
                "Unsaved changes",
                "There are unsaved changes, are you sure you want to discard your changes?"
            )
            .onConfirm()
            .subscribe(() => {
                tab.beforeRefresh = () => true;
                tab.refresh();
            });
    }

    return false;
}

export function charToUpperCase(value: string, charAt?: number) {
    if (!charAt) charAt = 0;

    if (charAt < 0 || charAt > value.length) return value;

    const charAtIndex = value[charAt];
    const newValue = value.slice(0, charAt) + charAtIndex.toUpperCase() + value.slice(charAt + 1);

    return newValue;
}

export function toPromise<T>(observable: Observable<T>): Promise<T> {
    return firstValueFrom(observable);
}

export function addParameterToUrl(
    url: string,
    paramName: string,
    paramValue: number | number[] | string | string[] | boolean
) {
    if (!url) {
        return url;
    }

    if (paramValue) {
        if (Array.isArray(paramValue)) {
            paramValue = paramValue.join(",");
        }
    } else if (paramValue === undefined) {
        paramValue = null;
    }

    url += (url.split("?")[1] ? "&" : "?") + paramName + "=" + paramValue;

    return url;
}
