import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import {
    FormFieldType,
    FormFieldTypeDescription,
    FormFieldTypeModels,
} from "@app/forms/enums/FormFieldTypes";
import { FormField, FormFieldConfig } from "@app/forms/models/detail/FormField";
import { MapbackProperty } from "@app/forms/models/mapback/MapbackProperty";
import { Observable } from "rxjs";
import { FormDetailDateComponent } from "./field-types/date/form-detail-date.component";
import { FormDetailInfoComponent } from "./field-types/info/form-detail-info.component";
import { FormDetailOptionsComponent } from "./field-types/options/form-detail-options.component";
import { FormDetailStructureComponent } from "./field-types/structure/form-detail-structure.component";
import { FormDetailVideoComponent } from "./field-types/video/form-detail-video.component";
import { FormDetailWidgetComponent } from "./field-types/widget/form-detail-widget.component";

@Component({
    selector: "form-detail-field",
    templateUrl: "./form-detail-field.component.html",
    styleUrls: ["./form-detail-field.component.scss"],
})
export class FormDetailFieldComponent implements OnInit {
    @Input() field: FormField;
    @Input() mapbackProperties: MapbackProperty[];
    @Output() fieldChange = new EventEmitter<FormField>();

    fieldTypes = FormFieldTypeModels;
    typeEnum = FormFieldType;
    typeDesc = FormFieldTypeDescription;

    fieldTypeLocked = false;
    requiredLocked = false;

    preChangeMapbackValue: number;

    @ViewChild("customComponent", { read: ViewContainerRef, static: true }) customComponent;
    compRef: any;

    constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit() {
        if (+this.field.FieldType) {
            this.changeType(this.field.FieldType);
        }

        if (this.field.MapBackField) {
            this.changeMapback(
                this.mapbackProperties.find((x) => x.ID === this.field.MapBackField)
            );
        } else {
            this.field.MapBackField = null;
        }
    }

    clearMapback() {
        this.fieldTypeLocked = false;
        this.requiredLocked = false;
        this.fieldTypes = FormFieldTypeModels;

        this.field.MapBackField = null;
        this.setConfig(false);
    }

    changeMapback(ev: MapbackProperty) {
        const oldProperty = this.mapbackProperties.find((x) => x.ID === this.preChangeMapbackValue);
        this.preChangeMapbackValue = ev.ID;

        this.fieldTypeLocked = false;
        this.requiredLocked = false;
        this.fieldTypes = FormFieldTypeModels;

        if (ev) {
            if (
                this.field.Name.trim().length === 0 ||
                (oldProperty && this.field.Name === oldProperty.Name)
            ) {
                this.field.Name = ev.Name;
            }

            // Limit fields on what is allowed;
            if (ev.AllowedFieldTypes && ev.AllowedFieldTypes.length > 0) {
                this.fieldTypes = this.fieldTypes.filter(
                    (x) => ev.AllowedFieldTypes.indexOf(x.type) >= 0
                );
                if (!this.fieldTypes.some((x) => x.type === this.field.FieldType)) {
                    this.changeType(this.fieldTypes[0].type);
                }
            }

            if (ev.Required) {
                this.field.Required = true;
                this.requiredLocked = true;
            }

            if (ev.Config && ev.Config.fieldType) {
                this.fieldTypeLocked = true;
                this.changeType(ev.Config.fieldType);
                this.setConfig(true, ev.Config);
            } else {
                this.setConfig(false);
            }
        }
    }

    setConfig(isMapbackConfig: boolean, config?: FormFieldConfig) {
        if (this.compRef && this.compRef.instance && this.compRef.instance.updateConfig) {
            this.compRef.instance.updateConfig(isMapbackConfig, config);
        }
    }

    changeType(type: FormFieldType) {
        if (this.field.FieldType !== type || !this.compRef) {
            this.field.FieldType = type;

            this.setComponent(this.field.FieldType);
        }
    }

    setComponent(fieldType: number, clearCustomSettings = false) {
        if (clearCustomSettings) {
            this.field.CustomSettings = "";
        }

        this.compRef = null;
        this.customComponent.clear();
        const comp = this.getAdditionalSettingComponent(fieldType);
        if (comp) {
            const componentFactory = this._componentFactoryResolver.resolveComponentFactory(comp);
            const viewContainerRef = this.customComponent;
            viewContainerRef.clear();
            this.compRef = viewContainerRef.createComponent(componentFactory);
            if (this.compRef.instance) {
                this.compRef.instance.field = this.field;
                const ev = this.compRef.instance.fieldChange as Observable<FormField>;
                if (ev) {
                    ev.subscribe((e) => {
                        this.field = e;
                        this.fieldChange.emit(this.field);
                    });
                }
            }
        }
    }

    getAdditionalSettingComponent(type: number): any {
        let comp: any;
        switch (type) {
            case FormFieldType.Info:
                comp = FormDetailInfoComponent;
                break;
            case FormFieldType.Radio:
            case FormFieldType.Checkbox:
            case FormFieldType.Dropdown:
                comp = FormDetailOptionsComponent;
                break;
            case FormFieldType.Structure:
                comp = FormDetailStructureComponent;
                break;
            case FormFieldType.Date:
                comp = FormDetailDateComponent;
                break;
            case FormFieldType.Widget:
                comp = FormDetailWidgetComponent;
                break;
            case FormFieldType.Video:
                comp = FormDetailVideoComponent;
                break;
        }
        return comp;
    }
}
