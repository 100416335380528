<div class="detail-page-card" *ngIf="!formGroup || !standingData">
    <div class="detail-page-card-title">Task details</div>
    <div class="detail-page-card-body padded cerrix-control-sm">
        <div class="fancy-loader submodule"></div>
    </div>
</div>

<form [formGroup]="formGroup" *ngIf="formGroup && standingData" data-uiid="task-detail-form">
    <div class="form-group global-comment" *ngIf="showWorkflowComment">
        <br />
        <pre class="readonly-comment">{{ model.workflowComment }}</pre>
        <hr />
    </div>
    <div class="detail-page-card">
        <div class="detail-page-card-title">Details</div>
        <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="col">
                            <cerrix-textfield
                                fieldLabel="Name"
                                dataUiid="task-detail-name"
                                identifier="taskNameInput"
                                formControlName="name"
                                placeholder="Required"
                                [readonly]="!permissions?.canEdit"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="responsibleSelect">Responsibles</label>
                            <ng-select
                                data-uiid="task-responsible"
                                s
                                id="responsibleSelect"
                                name="responsibleSelect"
                                [multiple]="true"
                                [items]="standingData.responsibles"
                                placeholder="Required"
                                formControlName="responsibles"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="false"
                                [readonly]="!permissions?.canEdit"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textarea
                                fieldLabel="Description"
                                dataUiid="task-detail-description"
                                identifier="taskDescriptionInput"
                                [rows]="4"
                                formControlName="description"
                                [readonly]="!permissions?.canEdit"
                            ></cerrix-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label>Action date</label>
                            <date-input
                                data-uiid="task-detail-actiondate"
                                formControlName="actionDate"
                            ></date-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="notificationsSelect">Notifications</label>
                            <ng-select
                                data-uiid="task-notifications"
                                id="notificationsSelect"
                                name="notificationsSelect"
                                [multiple]="true"
                                [items]="standingData.notifications"
                                formControlName="notifications"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="false"
                                [readonly]="!permissions?.canEdit"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xl-6 right-block">
                    <div class="row">
                        <div class="form-group col">
                            <label for="linkedTypeSelect">Linked type</label>
                            <ng-select
                                data-uiid="task-linkedtype"
                                s
                                id="linkedTypeSelect"
                                name="linkedTypeSelect"
                                [multiple]="true"
                                [items]="standingData.taskTypes"
                                placeholder="Required"
                                formControlName="linkedTypeIds"
                                bindLabel="Name"
                                bindValue="ID"
                                (change)="linkedTypesChanged($event)"
                                [closeOnSelect]="false"
                                [readonly]="!permissions?.canEdit"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="reviewersSelect">Reviewers</label>
                            <i
                                class="far fa-info-circle mx-2"
                                matTooltip="Selecting reviewers enables the review workflow step."
                            ></i>
                            <ng-select
                                data-uiid="task-series-reviewers"
                                name="reviewersSelect"
                                [multiple]="true"
                                [items]="standingData.reviewers"
                                formControlName="reviewerIds"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="false"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div
                        class="row"
                        *ngIf="
                            standingData.taskScores && model.taskScoreOption != TaskScoreOptions.No
                        "
                    >
                        <div class="form-group col">
                            <label
                                *ngIf="model.taskScoreOption == TaskScoreOptions.Required"
                                for="taskScoreSelect"
                                >Score (Requires scoring)</label
                            >
                            <label
                                *ngIf="model.taskScoreOption != TaskScoreOptions.Required"
                                for="taskScoreSelect"
                                >Score</label
                            >
                            <p *ngIf="standingData.taskScores.length === 0">
                                There are no scores defined in <b>Standing Data</b>
                            </p>
                            <cerrix-select
                                *ngIf="standingData.taskScores.length > 0"
                                data-uiid="task-score"
                                id="score"
                                name="score"
                                [multiple]="false"
                                [getDataMethod]="standingData.taskScores"
                                formControlName="score"
                                bindLabel="Name"
                                bindValue="ID"
                                [closeOnSelect]="true"
                                [required]="model.taskScoreOption == TaskScoreOptions.Required"
                            >
                            </cerrix-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="taskCommentsInput">Comments</label>
                            <i
                                class="far fa-info-circle mx-2"
                                matTooltip="Give your comments about the task execution here"
                            >
                            </i>
                            <cerrix-textarea
                                dataUiid="task-detail-comments"
                                identifier="taskCommentsInput"
                                [rows]="4"
                                formControlName="comments"
                                [readonly]="!permissions?.canEditComment"
                            ></cerrix-textarea>
                        </div>
                    </div>
                    <div class="row" *ngIf="model.guid">
                        <div class="form-group col">
                            <label for="taskEvidenceInput">Evidence</label>
                            <i
                                class="far fa-info-circle mx-2"
                                matTooltip="Use this field to add documents such as evidence and if there are documents already available, use these according the task description"
                            >
                            </i>
                            <document-manager-old
                                [Label]="''"
                                [disabled]="model.workflowState == TaskWorkflowStatus.Done"
                                [(Documents)]="model.Documents"
                                [permissions]="permissions.documentPermissions"
                                [DocumentTypeTargets]="targetModule"
                            >
                            </document-manager-old>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
