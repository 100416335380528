import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { DocumentMethods } from "@methods/DocumentMethods";
import { DocumentModel } from "@models/documents/documentmodel";

export class DroppedDocuments {
    IsBrowse: boolean;
    Documents: DocumentModel[];
}

@Component({
    selector: "eff-drop-files-area",
    templateUrl: "./eff-drop-files-area.component.html",
    styleUrls: ["./eff-drop-files-area.component.scss"],
})
export class EffDropFilesAreaComponent {
    @Input() allowedFileExtensions: string;
    @Input() allowDrop: boolean = false;
    @Output() onDroppedFiles: EventEmitter<DroppedDocuments> = new EventEmitter();
    constructor(private _userDS: UserService) {}

    async fileDropHandler(files: File[], isBrowse: boolean) {
        if (this.allowDrop) {
            const droppedDocuments = DocumentMethods.convertFileListToDocumentModel(
                files,
                await this._userDS.getUserName()
            );

            this.onDroppedFiles.emit({ IsBrowse: isBrowse, Documents: droppedDocuments });
        }
    }
}
