import * as excel from "exceljs/dist/exceljs.min.js";
import moment from "moment";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { download, IEVersion } from "./CommonMethods";
import { FormatType } from "@enums/FormatType";
import { CellRendererPipe } from "@app/shared/pipes/cell-renderer.pipe";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

export class Export {
    constructor(
        private cellRenderer: CellRendererPipe,
        private _promptService: CerrixPromptService
    ) {}

    public async exportData(
        exportName: string,
        data: object[],
        renderConfigs: RendererConfig[],
        headers?: string[],
        prettyHeaders?: any
    ): Promise<void> {
        if (data.length === 0) {
            this._promptService.alert({
                data: {
                    title: "Export Invalid",
                    message: "No data found to export.",
                },
            });
            return;
        }

        let exportData = [];
        if (headers) {
            const headerRow = [];
            headers.forEach((header) =>
                headerRow.push(prettyHeaders ? prettyHeaders[header] : header)
            );
            exportData.push(headerRow);

            data.forEach((dataRow) => {
                const row = [];
                headers.forEach((header) => {
                    row.push(dataRow[header]);
                });
                exportData.push(row);
            });
        } else {
            exportData = data;
        }

        const workbook = new excel.Workbook();
        const worksheet = workbook.addWorksheet(exportName);
        worksheet.addRows(exportData);

        const row = worksheet.getRow(1); // getRow is 1-based
        row.font = { bold: true };
        row.commit();

        const prettyHeaderEntries = Object.entries(prettyHeaders);

        let currentDate = new Date();
        currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );

        for (let i = 1; i <= worksheet.columnCount; i++) {
            const column = worksheet.getColumn(i); // getColumn is 1-based
            const columnHeader = column.values[1].toString();
            const prettyHeaderEntry = prettyHeaderEntries.find(
                (entry) => entry[1].toString() === columnHeader
            );
            if (prettyHeaderEntry) {
                const rendererConfig = renderConfigs.find(
                    (rc) => rc.textColumn === prettyHeaderEntry[0]
                );
                if (rendererConfig) {
                    if (
                        rendererConfig.formatType === FormatType.DateFormat ||
                        rendererConfig.formatType === FormatType.DateTimeFormat
                    ) {
                        // Fix date and always display datetime regardless of what is configured
                        column.eachCell((cell, rowNumber) => {
                            if (rowNumber === 1) {
                                // ExcelJS is 1-based and the first row is empty
                                return;
                            }

                            cell.numFmt = this.cellRenderer.getDateFormat(
                                rendererConfig.formatType
                            );

                            // Exceljs uses UTC date and adds it to excel without specifying timezone...
                            // So a local datetime "2020-01-01 12:00 +2:00" needs to become "2020-01-01 12:00z"
                            // Selected dates without time can be added directly because they should always be UTC
                            if (
                                rendererConfig.formatType !== FormatType.DateFormat &&
                                cell.value &&
                                Date.parse(cell.value) > 0
                            ) {
                                cell.value = moment(cell.value).utc(true).toDate();
                            }
                        });
                    } else {
                        column.eachCell((cell, rowNumber) => {
                            if (rowNumber === 1) {
                                // ExcelJS is 1-based and the first row is empty
                                return;
                            }
                            if (cell.value) {
                                cell.value = this.cellRenderer.transform(
                                    cell.value,
                                    rendererConfig
                                );
                            }
                        });
                    }

                    switch (rendererConfig.type) {
                        case RendererType.DueDate:
                            // Fill Overdue date cells with the color red
                            column.eachCell((cell, rowNumber) => {
                                if (rowNumber === 1) {
                                    // ExcelJS is 1-based and the first row is empty
                                    return;
                                }

                                let isOverDue = false;
                                if (rendererConfig.actionColumn) {
                                    isOverDue =
                                        data[rowNumber - 2][rendererConfig.actionColumn] ===
                                        rendererConfig.actionValue;
                                } else if (cell.value && cell.value instanceof Date) {
                                    isOverDue = cell.value < currentDate;
                                }

                                if (isOverDue) {
                                    cell.font = { color: { argb: "FFFF0000" } };
                                }
                            });
                            break;
                        case RendererType.Score:
                            // Fill the background color of scores
                            column.eachCell((cell, rowNumber) => {
                                if (rowNumber === 1) {
                                    // ExcelJS is 1-based and the first row is empty
                                    return;
                                }
                                if (rendererConfig.actionColumn) {
                                    const dataRow = data[rowNumber - 2];
                                    const rowValue = dataRow[rendererConfig.actionColumn];
                                    if (rowValue) {
                                        const color = (
                                            rowValue[0] == "#"
                                                ? rowValue.toString().slice(1)
                                                : rowValue
                                        ).toString();
                                        if (color) {
                                            cell.style = {
                                                fill: {
                                                    type: "pattern",
                                                    pattern: "solid",
                                                    bgColor: { argb: "00000000" },
                                                    fgColor: { argb: color.padStart(8, "F") },
                                                },
                                            };
                                        }
                                    }
                                }
                            });
                            break;
                        case RendererType.Progress:
                            column.numFmt = "0%";
                            column.eachCell((cell, rowNumber) => {
                                if (rowNumber === 1) {
                                    // ExcelJS is 1-based and the first row is empty
                                    return;
                                }
                                if (cell.value) {
                                    cell.value /= 100;
                                }
                            });
                            break;
                        case RendererType.Default:
                            break;
                    }
                }
            }
            column.width = Math.max(columnHeader.length * 1.5, 10);
        }

        workbook.xlsx
            .writeBuffer({
                base64: true,
            })
            .then(function (xls64) {
                const data = new Blob([xls64], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const fileName = `${exportName}_${moment().format("YYYYMMDDxHHmmss")}.xlsx`;
                if (IEVersion() > 0) {
                    (window.navigator as any).msSaveOrOpenBlob(data, fileName);
                } else {
                    const url = URL.createObjectURL(data);
                    download(url, fileName);
                }
            });
    }
}
