import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditDataService } from "@app/audit/services/audit-data.service";
import { FindingReportDataService } from "@app/findingreport/services";
import {
    FindingReportDetailsModel,
    FindingReportPermissionsModel,
} from "@app/findingreport/shared/models";
import { Pages } from "@constants/pages/Pages";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { getFormControl, toPromise } from "@methods/CommonMethods";
import { isGuid } from "@methods/uniqueMethods";
import { SelectOptionColorModel, SelectOptionModel } from "@models/generic";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { TabService } from "@services/tabs/TabService";
import { tap } from "rxjs/operators";

@Component({
    selector: "finding-report-details-main",
    templateUrl: "./finding-report-details-main.component.html",
    styleUrls: ["./finding-report-details-main.component.scss"],
})
export class FindingReportDetailsMainComponent implements OnInit {
    @Input() detailsModel: FindingReportDetailsModel;
    @Input() formGroup: FormGroup;
    @Input() permissionsModel: FindingReportPermissionsModel;
    @Input() editAllowed: boolean;

    get finishedLoading(): boolean {
        // Need to wait for select tree component data because it doesn't work without...
        return (
            !!this.detailsModel &&
            !!this.formGroup &&
            !!this.responsibleOrganizations &&
            !!this.reviewerOrganizations
        );
    }

    moiType = MoiTypes.IM_Moi;

    reportTypesPromise: Promise<SelectOptionModel<number>[]>;
    overallScoresPromise: Promise<SelectOptionColorModel<number>[]>;
    validationTypesPromise: Promise<SelectOptionModel<number>[]>;
    responsibleOrganizations: CerrixTreeItem[];
    responsiblesPromise: Promise<SelectOptionModel<number>[]>;
    reviewerOrganizations: CerrixTreeItem[];
    reviewersPromise: Promise<SelectOptionModel<number>[]>;

    reportTypesLoading = true;
    overallScoresLoading = true;
    validationTypesLoading = true;
    responsiblesLoading = true;
    reviewersLoading = true;

    canOpenAudit: boolean = false;

    constructor(
        private findingReportService: FindingReportDataService,
        private _tabService: TabService,
        private _pages: Pages,
        private _auditDS: AuditDataService
    ) {}

    ngOnInit() {
        this.loadStandingData();
    }

    //#region Standing data

    loadStandingData() {
        if (this.detailsModel.guid) {
            this.loadReportTypes(this.detailsModel.reportTypeId);
            this.loadOverallScores(this.detailsModel.reportTypeId);
            this.loadValidationTypes();
            this.loadResponsibleOrganizations();
            this.loadResponsibles(this.detailsModel.responsibleId);
            this.loadReviewerOrganizations(this.detailsModel.reportTypeId);
            this.loadReviewers(this.detailsModel.reviewerId);
            this.hasAuditAccess(this.detailsModel.auditGuid);
        } else {
            this.loadReportTypes();
            this.loadOverallScores();
            this.loadValidationTypes();
            this.loadResponsibleOrganizations();
            this.loadResponsibles();
            this.loadReviewerOrganizations();
            this.loadReviewers();
        }
    }

    private async hasAuditAccess(auditGuid: string) {
        if (isGuid(auditGuid)) {
            const auditPermissions = await toPromise(this._auditDS.getPermissions());
            this.canOpenAudit = auditPermissions.canView;
        } else {
            this.canOpenAudit = false;
        }
    }

    private loadReportTypes(reportTypeId?: number) {
        this.reportTypesLoading = true;
        this.reportTypesPromise = this.findingReportService
            .getReportTypes(reportTypeId)
            .pipe(tap(() => (this.reportTypesLoading = false)))
            .toPromise();
    }

    private loadOverallScores(selectedReportTypeId?: number) {
        const control = getFormControl(this.formGroup, "overallScoreId");
        if (!selectedReportTypeId) {
            control.disable();
            control.setValue(null);
        } else if (this.editAllowed) {
            control.enable();
        }

        this.overallScoresLoading = true;
        this.overallScoresPromise = this.findingReportService
            .getOverallScores(selectedReportTypeId)
            .pipe(tap(() => (this.overallScoresLoading = false)))
            .toPromise();
    }

    private loadValidationTypes() {
        this.validationTypesLoading = true;
        this.validationTypesPromise = this.findingReportService
            .getValidationTypes()
            .pipe(tap(() => (this.validationTypesLoading = false)))
            .toPromise();
    }

    private loadResponsibleOrganizations() {
        this.findingReportService
            .getResponsibleOrganizations(this.detailsModel.responsibleOrganizationId)
            .subscribe((organizations) => {
                this.responsibleOrganizations = organizations;
            });
    }

    private loadResponsibles(responsibleId?: number) {
        this.responsiblesLoading = true;
        this.responsiblesPromise = this.findingReportService
            .getResponsibles(responsibleId)
            .pipe(tap(() => (this.responsiblesLoading = false)))
            .toPromise();
    }

    private loadReviewerOrganizations(selectedReportTypeId?: number) {
        const control = getFormControl(this.formGroup, "reviewerOrganizationId");
        if (!selectedReportTypeId) {
            control.disable();
            control.setValue(null);
        } else if (this.editAllowed) {
            control.enable();
        }

        this.findingReportService
            .getReviewerOrganizations(
                this.detailsModel.reviewerOrganizationId,
                selectedReportTypeId
            )
            .subscribe((organizations) => {
                this.reviewerOrganizations = organizations;
            });
    }

    private loadReviewers(reviewerId?: number) {
        this.reviewersLoading = true;
        this.reviewersPromise = this.findingReportService
            .getReviewers(reviewerId)
            .pipe(tap(() => (this.reviewersLoading = false)))
            .toPromise();
    }

    //#endregion Standing data

    //#region Events

    onReportTypeChange(reportTypeModel: SelectOptionModel<number>) {
        // Reset overallScoreId after report type change
        const control = getFormControl<FindingReportDetailsModel>(this.formGroup, "overallScoreId");
        control.setValue(null);

        const selectedReportTypeId = reportTypeModel ? reportTypeModel.value : null;

        this.loadOverallScores(selectedReportTypeId);
        this.loadReviewerOrganizations(selectedReportTypeId);
    }

    //#endregion Events

    //#region Linked Audit

    openAudit(): void {
        this._tabService.generateTab(this._pages.Audit, this.detailsModel.auditGuid);
    }

    //#endregion Linked Audit
}
