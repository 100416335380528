<h3 [class.mb-2]="specification.description">{{specification.name}}</h3>
<p class="no-indent" *ngIf="specification.description">
    <span> {{specification.description}} </span>
</p>

<h5>Routing</h5>
<p class="no-indent">
    <span>
        <span class="badge bg-cerrix">{{specification.httpMethod}}</span>
        <span class="d-inline ms-3">{{specification.path}}</span>
    </span>
</p>

<ng-container *ngIf="specification.parameters">
    <h5>Parameters</h5>
    <ng-container *ngFor="let param of specification.parameters">
        <p>
            <strong> {{param.name}} </strong>
            <sup *ngIf="param.required"><small class="red">* required</small></sup>

            <span> {{param.paramType}} <small>({{param.in}})</small> </span>

            <span *ngIf="param.description">{{param.description}}</span>

            <span *ngIf="param.enum">
                Available values:
                <span class="badge bg-dark ms-2" *ngFor="let enum of param.enum">{{enum}}</span>
            </span>

            <span *ngIf="param.example">
                <pre class="example-block">{{param.example | json}}</pre>
            </span>
        </p>
    </ng-container>
</ng-container>

<ng-container *ngIf="specification.responses">
    <h5>Responses</h5>
    <ng-container *ngFor="let response of specification.responses">
        <p>
            <strong>{{response.code}}</strong>
            <i class="ms-2">{{response.description}}</i>

            <span class="mt-2" *ngIf="response.example">
                <pre class="example-block">{{response.example | json}}</pre>
            </span>
        </p>
    </ng-container>

    <span class="inline-link" *ngIf="!baseResponsesLoaded" (click)="loadBaseResponses()">
        Show default responses
    </span>
</ng-container>
