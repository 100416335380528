import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { FormDetailDateComponent } from "./pages/detail/field-detail/field-types/date/form-detail-date.component";
import { FormDetailInfoComponent } from "./pages/detail/field-detail/field-types/info/form-detail-info.component";
import { FormDetailOptionsComponent } from "./pages/detail/field-detail/field-types/options/form-detail-options.component";
import { FormDetailStructureComponent } from "./pages/detail/field-detail/field-types/structure/form-detail-structure.component";
import { FormDetailVideoComponent } from "./pages/detail/field-detail/field-types/video/form-detail-video.component";
import { FormDetailWidgetComponent } from "./pages/detail/field-detail/field-types/widget/form-detail-widget.component";
// Form detail
import { FormDetailFieldComponent } from "./pages/detail/field-detail/form-detail-field.compontent";
// Forms
import { FormDetailComponent } from "./pages/detail/form-detail.component";
import { FormMainSettingsComponent } from "./pages/detail/modals/mainsettings/form-main-settings.component";
import { FormPageSettingsComponent } from "./pages/detail/modals/pagesettings/form-page-settings.component";
import { FormWorkflowSettingsComponent } from "./pages/detail/modals/workflowsettings/form-workflow-settings.component";
// Form entry
import {
    FormEntryDateComponent,
    FormEntryFieldComponent,
    FormEntryFileUploadComponent,
    FormEntryInfoComponent,
    FormEntryNumberComponent,
    FormEntryOptionsComponent,
    FormEntryStructureComponent,
    FormEntryTextComponent,
    FormEntryTreeComponent,
    FormEntryWidgetComponent,
} from "./pages/entry/entry-fields";
import { FormEntryVideoComponent } from "./pages/entry/entry-fields/entry-types/video/form-entry-video.component";
import { FormEntryComponent } from "./pages/entry/form-entry.component";
import { FormHistoryOverviewComponent } from "./pages/history/form-history-overview.component";
import { FormInviteComponent } from "./pages/invite/form-invite.component";
import { FormHomeComponent } from "./pages/overview/form-home.component";
import { FormOverviewComponent } from "./pages/overview/form-overview/form-overview.component";
import { FormResultsComponent } from "./pages/overview/results-overview/form-results.component";
import { FormStatusComponent } from "./pages/overview/status-overview/form-status.component";
import { UnreleasedFormOverviewComponent } from "./pages/overview/unreleased-form-overview/unreleased-form-overview.component";
import { FormsDataService } from "./services/forms-data.service";

@NgModule({
    declarations: [
        FormDetailComponent,
        FormEntryComponent,
        FormHomeComponent,
        FormOverviewComponent,
        FormResultsComponent,
        FormStatusComponent,
        FormEntryFieldComponent,
        FormDetailFieldComponent,
        FormDetailInfoComponent,
        FormDetailStructureComponent,
        FormDetailOptionsComponent,
        FormDetailWidgetComponent,
        FormDetailDateComponent,
        FormDetailVideoComponent,
        FormEntryDateComponent,
        FormEntryOptionsComponent,
        FormEntryFileUploadComponent,
        FormEntryInfoComponent,
        FormEntryTextComponent,
        FormEntryTreeComponent,
        FormEntryStructureComponent,
        FormEntryVideoComponent,
        FormEntryNumberComponent,
        FormEntryWidgetComponent,
        FormMainSettingsComponent,
        FormPageSettingsComponent,
        FormWorkflowSettingsComponent,
        FormHistoryOverviewComponent,
        FormInviteComponent,
        UnreleasedFormOverviewComponent,
    ],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class CerrixFormsModule {
    static forRoot(): ModuleWithProviders<CerrixFormsModule> {
        return {
            ngModule: CerrixFormsModule,
            providers: [FormsDataService],
        };
    }
}
