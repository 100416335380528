import { SharedModule } from "@app/shared/shared.module";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProcessedDocumentOverviewComponent } from "./pages/processed-document-overview/processed-document-overview.component";
import { ProcessedDocumentsService } from "./services/processed-documents-service";
import { ProcessedDocumentDetailComponent } from "./pages/processed-document-detail/processed-document-detail.component";

@NgModule({
    imports: [SharedModule, CommonModule],
    declarations: [ProcessedDocumentOverviewComponent, ProcessedDocumentDetailComponent],
    exports: [ProcessedDocumentOverviewComponent],
})
export class DocumentIntelligenceModule {
    static forRoot(): ModuleWithProviders<DocumentIntelligenceModule> {
        return {
            ngModule: DocumentIntelligenceModule,
            providers: [ProcessedDocumentsService],
        };
    }
}
