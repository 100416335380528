<cerrix-icon-button
    *ngIf="!showConfirmation"
    color="accent-link"
    size="small"
    (click)="onIconClick($event)"
>
    <mat-icon fontSet="material-symbols-outlined">{{ icon }}</mat-icon>
</cerrix-icon-button>

<cerrix-button *ngIf="showConfirmation" size="small" color="error" (click)="onButtonClick($event)"
    >{{ btnLabel
    }}<mat-icon fontSet="material-symbols-outlined">{{ icon }}</mat-icon></cerrix-button
>
