import { Component, OnInit } from "@angular/core";
import { FormsDataService } from "@app/forms/services/forms-data.service";
import { FormDraft } from "@app/forms/models/detail/FormDraft";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { parseTyped } from "@methods/CommonMethods";
import { Form } from "@app/forms/models";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "unreleased-form-overview",
    templateUrl: "./unreleased-form-overview.component.html",
    styleUrls: ["./unreleased-form-overview.component.scss"],
})
export class UnreleasedFormOverviewComponent {
    loaded = false;
    callRunning = false;

    drafts: FormDraft[];

    releasedConfig: GenericListConfig;
    unreleasedConfig: GenericListConfig;

    constructor(
        private _ds: FormsDataService,
        private _pages: Pages,
        private _tabService: TabService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {
        this.load();
    }

    load() {
        this.loaded = true;
        this.callRunning = true;

        this._ds.getUnreleasedFormDrafts().subscribe((drafts) => {
            this.drafts = drafts;
            this.createConfig();
            this.callRunning = false;
        });
    }

    createConfig() {
        const releasedDrafts = this.drafts.filter((x) => x.TargetFormID > 0);
        this.releasedConfig = this.getListConfig("Released form drafts", releasedDrafts);

        const unreleasedDrafts = this.drafts.filter((x) => !x.TargetFormID || x.TargetFormID <= 0);
        this.unreleasedConfig = this.getListConfig("Unreleased form drafts", unreleasedDrafts);
    }

    private getListConfig(name: string, data: FormDraft[]) {
        return <GenericListConfig>{
            name: name,

            allowDelete: true,
            fields: [
                {
                    fieldName: "Name",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Status",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "DateUpdated",
                    prettyName: "Date Updated",
                    fieldType: GenericListFieldType.DateTime,
                },
                {
                    fieldName: "UpdatedBy",
                    prettyName: "Updated By",
                    fieldType: GenericListFieldType.Text,
                },
            ],

            data: data,

            editOverride: (row) => {
                const draftForm = parseTyped<Form>(row.Draft, null);
                draftForm.LoadedDraftID = row.ID;

                const tabId = row.TargetFormID > 0 ? row.TargetFormID : `draft-${row.ID}`;
                this._tabService.generateTab(this._pages.FormDetail, tabId, draftForm.Title, {
                    draftToLoad: draftForm,
                });
            },

            delete: async (row) => {
                if (
                    await this._promptService
                        .confirm(
                            "Delete draft",
                            `Are you sure you want to delete the draft: '${row.Name}'?`
                        )
                        .toPromise()
                ) {
                    await this._ds.deleteDraft(row.ID).toPromise();
                    this._toastr.success("Draft has been deleted.", "Success");
                    return true;
                }

                return false;
            },
        };
    }
}
