import { Component, Input } from "@angular/core";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { ThirdPartyLinkModel } from "@models/thirdparty/ThirdPartyLinkModel";
import { AuditDataService } from "../../../../services/audit-data.service";

@Component({
    selector: "audit-thirdparty",
    templateUrl: "./audit-thirdparty.component.html",
    styleUrls: ["./audit-thirdparty.component.scss"],
})
export class AuditThirdPartyComponent {
    @Input() model: AuditModel;
    @Input() disabled;

    linked: ThirdPartyLinkModel[];
    unlinked: ThirdPartyLinkModel[];
    isLoading = true;

    constructor(private _DS: AuditDataService) {}

    load() {
        if (!this.model) {
            return;
        }

        this._DS.getThirdParties(this.model.guid).subscribe((value) => {
            this.loadData(this.model.linkedThirdParties, value.sortBy("name", true));
            this.isLoading = false;
        });
    }

    linkedChanged(newList: ThirdPartyLinkModel[]) {
        this.model.linkedThirdParties = newList.map((x) => x.id);
    }

    private loadData(linkedIds: number[], items: ThirdPartyLinkModel[]) {
        const linked = [],
            unlinked = [];

        for (const item of items) {
            if (linkedIds && linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }
        this.linked = linked;
        this.unlinked = unlinked;
    }
}
