<div *ngIf="!isLoading">
    <form
        class="detail-page-card-body padded cerrix-control-sm form-group-styling"
        [formGroup]="parentForm"
        novalidate
    >
        <div class="detail-page-action-bar flex-action-bar">
            <div class="col-md-12 text-start">
                <button
                    data-uiid="thirdpartycontract-save"
                    class="btn btn-cerrix btm-sm"
                    (click)="save()"
                    *ngIf="!permissions.readOnly"
                >
                    <i class="fas fa-save"></i>
                    <span class="d-none d-lg-inline">Save contract</span>
                </button>
                <span data-uiid="thirdpartycontracts-lastsaved" class="date-edited">
                    Last save: {{ contract.DateModified | dateTimeFormat }}
                </span>
            </div>
        </div>

        <div class="detail-page-summary" *ngIf="thirdParty">
            <div class="row">
                <div class="col-6">
                    <div class="property"><b>Third party ID:</b> {{ thirdParty.Identifier }}</div>
                    <div class="property"><b>Name:</b> {{ thirdParty.Name }}</div>
                    <div class="property">
                        <b>Next review date:</b> {{ thirdParty.NextReviewDate | dateFormat }}
                    </div>
                </div>
                <div class="col-6">
                    <div class="property">
                        <b>Last review score:</b> {{ thirdParty.Score.Name }}
                    </div>
                    <div class="property"><b>Reviewer:</b> {{ thirdParty.ReviewerNames }}</div>
                </div>
            </div>
        </div>

        <div
            menuSystem
            [cerrixTab]="tab"
            class="thirdparty-screens"
            (change)="handleMenuItemClick($event)"
        >
            <div [attr.name]="header" menuicon="fas fa-handshake" menusystemsubmenu>
                <div name="Details" class="handshake" menuicon="fas fa-info-circle" menusystemitem>
                    <div class="detail-page-card">
                        <div class="detail-page-card-title">Contract details</div>
                        <div
                            class="detail-page-card-body padded cerrix-control-sm form-group-styling"
                        >
                            <div class="row">
                                <div class="col-12 col-xl-6 left-block">
                                    <div class="row">
                                        <div class="form-group col">
                                            <cerrix-textfield
                                                fieldLabel="Contract no."
                                                dataUiid="thirdpartycontractdetail-contractno"
                                                identifier="thirdPartyContractNoInput"
                                                formControlName="ContractNumber"
                                                placeholder="Required"
                                            ></cerrix-textfield>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <div *ngIf="services">
                                                <label for="services">Services</label>
                                                <cerrix-select-tree
                                                    data-uiid="thirdpartycontractdetail-services"
                                                    [tree]="services"
                                                    formControlName="Services"
                                                    [multiselect]="true"
                                                    #servicesTree
                                                >
                                                </cerrix-select-tree>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="thirdPartyContractStatusSelect"
                                                >Status</label
                                            >
                                            <ng-select
                                                data-uiid="thirdpartycontractdetail-status"
                                                *ngIf="status"
                                                id="thirdPartyContractStatusSelect"
                                                name="thirdPartyContractStatusSelect"
                                                formControlName="Status"
                                                bindLabel="Name"
                                                bindValue="Name"
                                                [items]="status"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="contractOwnerSelect">Contract owner</label>
                                            <cerrix-select
                                                data-uiid="thirdpartycontractdetail-contractowner"
                                                *ngIf="contract"
                                                [getDataMethod]="contractOwnerRequest"
                                                formControlName="ContractOwner"
                                                id="ContractOwner"
                                                [closeOnSelect]="true"
                                            >
                                            </cerrix-select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="currencySelect">Currency</label>
                                            <ng-select
                                                data-uiid="thirdpartycontractdetail-currency"
                                                id="currencySelect"
                                                name="currencySelect"
                                                formControlName="currencyCode"
                                                [items]="sd.Currencies"
                                                bindLabel="Name"
                                                bindValue="ID"
                                                [closeOnSelect]="true"
                                            >
                                            </ng-select>
                                        </div>
                                        <div class="form-group col">
                                            <label for="thirdPartyContractValueInput"
                                                >Contracted value</label
                                            >
                                            <input
                                                data-uiid="thirdpartycontractdetail-contractedvalue"
                                                type="number"
                                                class="form-control form-control-sm"
                                                id="thirdPartyContractValueInput"
                                                formControlName="ContractedValue"
                                                name="thirdPartyContractValueInput"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="AnnualValue">Annual value</label>
                                            <input
                                                data-uiid="thirdpartycontractdetail-annualvalue"
                                                type="number"
                                                class="form-control form-control-sm"
                                                id="AnnualValue"
                                                formControlName="AnnualValue"
                                                name="AnnualValue"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-6 right-block">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="SignatureDate">Signature date</label>
                                            <date-input
                                                data-uiid="thirdpartycontractdetail-signaturedate"
                                                id="SignatureDate"
                                                formControlName="SignatureDate"
                                            ></date-input>
                                        </div>
                                        <div class="form-group col">
                                            <label for="StartDate">Start date</label>
                                            <date-input
                                                data-uiid="thirdpartycontractdetail-startdate"
                                                id="StartDate"
                                                formControlName="StartDate"
                                            ></date-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="termOfNoticeSelect">Term of notice</label>
                                            <ng-select
                                                data-uiid="thirdpartycontractdetail-termofnotice"
                                                *ngIf="termOfNotice"
                                                id="termOfNoticeSelect"
                                                name="termOfNoticeSelect"
                                                formControlName="TermsOfNotice"
                                                bindLabel="Name"
                                                bindValue="ID"
                                                required="true"
                                                placeholder="Required"
                                                [items]="termOfNotice"
                                            >
                                            </ng-select>
                                        </div>
                                        <div class="form-group col">
                                            <label for="EndDate">End date</label>
                                            <date-input
                                                data-uiid="thirdpartycontractdetail-enddate"
                                                id="EndDate"
                                                formControlName="EndDate"
                                            ></date-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="thirdPartyFrameworkContractInput"
                                                >Framework contract</label
                                            >
                                            <cerrix-checkbox
                                                data-uiid="thirdpartycontractdetail-frameworkcontract"
                                                fontsize="0.9em"
                                                formControlName="Framework"
                                                name="thirdPartyFrameworkContractInput"
                                            ></cerrix-checkbox>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="form-group col">
                                            <label for="contractDocumentManager"
                                                >Contract documents</label
                                            >
                                            <document-manager-old
                                                data-uiid="thirdpartycontractdetail-contractdocuments"
                                                [hideLabel]="true"
                                                [(Documents)]="contract.Documents"
                                                [permissions]="permissions.documentPermissions"
                                                [DocumentTypeTargets]="documentTypeTargets"
                                            ></document-manager-old>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    menuItemId="history"
                    *ngIf="contract.guid"
                    name="History"
                    menuicon="fas fa-history"
                    menusystemitem
                >
                    <app-history-overview
                        [Id]="contract.guid"
                        [historyDataService]="_contractDS"
                        #historyDetailOverviewComponent
                    ></app-history-overview>
                </div>
                <div
                    *ngIf="contract.guid"
                    name="Options"
                    menuicon="fas fa-check-double"
                    menusystemitem
                >
                    <detail-options
                        moduleName="contract"
                        [objectName]="contract ? contract.ContractNumber : 'contract'"
                        [allowDelete]="permissions.canDelete"
                        (onDelete)="delete()"
                    >
                    </detail-options>
                </div>
            </div>
        </div>
    </form>
</div>
