import { Configuration } from "@app/app.constants";
import { FormatType } from "@enums/FormatType";
import { RendererType } from "@enums/RendererType";
import { FindNode, FindNodes } from "@methods/TreeMethods";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { GenericListFieldType } from "../models/GenericList/GenericListField";
import { GenericFormManagerBase } from "./GenericFormManagerBase";
import { CerrixPromptService } from "../services/cerrix-prompt.service";

export class GenericListManagerBase extends GenericFormManagerBase {
    ready = false;

    tableData: any[];
    activeRow: any = null;

    constructor(
        standingdataDS: StandingdataDataService,
        toastr: ToastrService,
        config: Configuration,
        promptService: CerrixPromptService
    ) {
        super(standingdataDS, toastr, config, promptService);
    }

    async loadPage() {
        this.idProp = this.baseConfig.idProp ? this.baseConfig.idProp : "ID";

        this.ready = false;
        this.activeRow = null;
        this.tableData = null;

        this.loadForm();
        await this.loadData();
    }

    async loadData() {
        this.data = null;

        const dataCall = this.baseConfig.data
            ? of(this.baseConfig.data).toPromise()
            : this.baseConfig.dataMethod().toPromise();
        dataCall.then((d) => {
            this.data = d;
            this.checkAdditionalData();
        });
    }

    setTableRenderers() {
        if (!this.baseConfig.rendererConfig) {
            this.baseConfig.rendererConfig = [];
        }

        this.baseConfig.fields.forEach((f) => {
            if (
                f.fieldType === GenericListFieldType.ColoredSelect ||
                f.fieldType === GenericListFieldType.ColorPicker
            ) {
                this.baseConfig.rendererConfig.push({
                    textColumn: f.fieldName,
                    actionColumn: f.fieldName + "COLOR",
                    hideActionColumn: true,
                    type: RendererType.Score,
                });
            }

            if (
                f.fieldType === GenericListFieldType.Date ||
                f.fieldType === GenericListFieldType.DateTime
            ) {
                this.baseConfig.rendererConfig.push({
                    textColumn: f.fieldName,
                    type: RendererType.Default,
                    formatType:
                        f.fieldType === GenericListFieldType.DateTime
                            ? FormatType.DateTimeFormat
                            : FormatType.DateFormat,
                });
            }

            if (f.fieldType === GenericListFieldType.IconPicker) {
                this.baseConfig.rendererConfig.push({
                    textColumn: f.fieldName,
                    type: RendererType.Icon,
                });
            }
        });
    }

    setTable() {
        const converted = [];
        this.data.forEach((x) => {
            const tableRow = this.dataRowToTableRow(x);
            converted.push(tableRow);
        });

        this.setTableRenderers();

        this.tableData = converted;
        this.tableReadyMethod();

        this.ready = true;
    }

    dataRowToTableRow(dataRow: any, tableRow?: any) {
        if (!tableRow) {
            tableRow = {};
            tableRow[this.idProp] = dataRow[this.idProp];
        }

        this.baseConfig.fields.sortBy("overviewSortOrder", true).forEach((f) => {
            if (!f.hideInOverview || f.includeInSearch) {
                let value = dataRow[f.fieldName];

                const fieldSD = this.additionalData[f.fieldName];
                if (fieldSD) {
                    // get actual values;
                    if (f.fieldType === GenericListFieldType.SingleSelect) {
                        const singleRow = (fieldSD as IdNameCombination[]).find(
                            (x) => x.ID === value
                        );
                        value = singleRow ? singleRow.Name : "";
                    } else if (f.fieldType === GenericListFieldType.MultiSelect) {
                        const multiRow = (fieldSD as IdNameCombination[]).filter(
                            (x) => value.indexOf(x.ID) >= 0
                        );
                        value = multiRow ? multiRow.map((x) => x.Name).join(" | ") : "";
                    } else if (f.fieldType === GenericListFieldType.SingleTree) {
                        value = FindNode(fieldSD as CerrixTreeItem[], +value);
                    } else if (f.fieldType === GenericListFieldType.MultiTree) {
                        value = FindNodes(fieldSD as CerrixTreeItem[], value);
                    } else if (f.fieldType === GenericListFieldType.ColoredSelect) {
                        const singleRow = (fieldSD as IdNameColorModel[]).find(
                            (x) => x.ID === value
                        );
                        value = singleRow ? singleRow.Name : "";
                        const color = singleRow ? singleRow.Color : "";
                        tableRow[f.fieldName + "COLOR"] = color;
                    }
                }

                if (f.fieldType === GenericListFieldType.CheckBox) {
                    value = dataRow[f.fieldName] ? "✓" : "x";
                } else if (f.fieldType === GenericListFieldType.Documents) {
                    const d = dataRow[f.fieldName];
                    value = (d && Array.isArray(d) ? d.length : 0) + " Documents";
                } else if (f.fieldType === GenericListFieldType.ColorPicker) {
                    tableRow[f.fieldName + "COLOR"] = value;
                }

                // Add action columns into tableRow. Specific cases do this automatically, but predefined row renderers were ignored.
                const rowRenderer = this.baseConfig.rendererConfig
                    ? this.baseConfig.rendererConfig.find((x) => x.textColumn == f.fieldName)
                    : null;
                if (
                    rowRenderer &&
                    rowRenderer.actionColumn &&
                    !tableRow[rowRenderer.actionColumn]
                ) {
                    tableRow[rowRenderer.actionColumn] = dataRow[rowRenderer.actionColumn];
                }

                tableRow[f.fieldName] = value;
            }
        });

        return tableRow;
    }

    async applyRowChange(editedRow: any) {
        const success = await super.applyRowChange(editedRow);
        if (success) {
            let tableRow = this.tableData.find((x) => x[this.idProp] === editedRow[this.idProp]);
            const push = !tableRow;
            tableRow = this.dataRowToTableRow(editedRow, tableRow);
            if (push) {
                this.tableData.push(tableRow);
            }

            this.activeRow = editedRow;

            this.tableReadyMethod();
            this.emitDataChanged(this.data);

            // reload data, because you need the ids of the new rows in the backend sometimes and probably for sorting as well
            this.loadData();
        }

        return success;
    }

    async deleteRow(activeRow) {
        if (!activeRow) {
            return;
        }
        const success = await super.deleteRow(activeRow);
        if (success) {
            const tableI = this.tableData.indexOf(this.activeRow);
            this.tableData.splice(tableI, 1);

            this.activeRow = null;
        }

        return success;
    }

    tableReadyMethod() {
        // This method can be overridden in derived class. Do not put any code in here because it most likely will not run.
    }
}
