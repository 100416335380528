<div class="modal-header">
    <h5 class="modal-title">Form Settings</h5>
</div>
<div class="fancy-loader submodule" *ngIf="!sd"></div>
<mat-dialog-content *ngIf="sd" class="overflow-dialog">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="General" data-uiid="forms-main-settings-general-tab">
            <div class="container-fluid">
                <div class="form-group">
                    <label for="form-title">Title</label>
                    <cerrix-textfield
                        dataUiid="forms-main-settings-title-input"
                        cssClass="form-control"
                        id="form-title"
                        [(ngModel)]="this.form.Title"
                        placeholder="Enter Title"
                    ></cerrix-textfield>
                </div>
                <div class="form-group">
                    <label for="form-description">Description</label>
                    <cerrix-textarea
                        dataUiid="forms-main-settings-description-input"
                        cssClass="form-control"
                        id="form-description"
                        aria-describedby="emailHelp"
                        [(ngModel)]="this.form.Description"
                        placeholder="Enter Description"
                    ></cerrix-textarea>
                </div>
                <div class="form-group">
                    <label for="form-groups">Category</label>
                    <ng-select
                        data-uiid="forms-main-settings-category-select"
                        [(ngModel)]="form.Category"
                        id="form-groups"
                        [items]="sd.categories"
                        bindValue="ID"
                        bindLabel="Name"
                        [closeOnSelect]="true"
                        [multiple]="false"
                    >
                    </ng-select>
                    <div
                        class="alert alert-danger small-alert system-required"
                        role="alert"
                        *ngIf="!sd.categories || sd.categories.length == 0"
                    >
                        No Categories found. Please create Form Categories first.
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="form-startdate">Start date</label>
                        <date-input
                            data-uiid="forms-main-settings-startdate-input"
                            [(value)]="form.StartDate"
                        ></date-input>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="form-enddate">End date</label>
                        <date-input
                            data-uiid="forms-main-settings-enddate-input"
                            [(value)]="form.EndDate"
                        ></date-input>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Assigned Users" data-uiid="forms-main-settings-assigned-users-tab">
            <div class="container-fluid">
                <div class="form-group">
                    <label for="form-groups">Form responsibles</label>
                    <ng-select
                        data-uiid="forms-main-settings-responsibles-select"
                        [(ngModel)]="form.Responsibles"
                        id="form-groups"
                        [items]="sd.responsibles"
                        bindValue="ID"
                        bindLabel="Name"
                        [closeOnSelect]="false"
                        [multiple]="true"
                    >
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="form-groups">Form designers</label>
                    <ng-select
                        data-uiid="forms-main-settings-designers-select"
                        [(ngModel)]="form.Designers"
                        id="form-groups"
                        [items]="sd.designers"
                        bindValue="ID"
                        bindLabel="Name"
                        [closeOnSelect]="false"
                        [multiple]="true"
                    >
                    </ng-select>
                </div>
                <hr />
                <div class="form-group">
                    <label for="form-groups">Entry groups</label>
                    <ng-select
                        data-uiid="forms-main-settings-entrygroups-select"
                        [(ngModel)]="form.Groups"
                        id="form-groups"
                        [items]="sd.entryGroups"
                        bindValue="ID"
                        bindLabel="Name"
                        [closeOnSelect]="false"
                        [multiple]="true"
                        *ngIf="!form.Workflow"
                    >
                    </ng-select>
                    <input
                        type="text"
                        class="form-control"
                        value="Workflow groups can be configured in workflow settings."
                        disabled
                        *ngIf="form.Workflow"
                        inputTooltip
                    />
                </div>
                <div class="form-group">
                    <label for="form-groups">Data owner groups</label>
                    <ng-select
                        data-uiid="forms-main-settings-data-owners-select"
                        [(ngModel)]="form.DataOwners"
                        id="form-groups"
                        [items]="sd.dataOwnerGroups"
                        bindValue="ID"
                        bindLabel="Name"
                        [closeOnSelect]="false"
                        [multiple]="true"
                    >
                    </ng-select>
                    <small class="text-muted"
                        >Users in selected groups get view access to all completed pages/forms under
                        this form.</small
                    >
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Design" data-uiid="forms-main-settings-design-tab">
            <div class="container-fluid">
                <label for="form-image">Form Image</label>
                <cerrix-image-picker
                    data-uiid="forms-main-settings-image-picker"
                    maxHeight="500px"
                    [(image)]="form.ImageGuid"
                    [fallbackImageUrl]="'/static/images/form_logo_fallback.jpg'"
                >
                </cerrix-image-picker>
            </div>
        </mat-tab>
        <mat-tab label="Advanced" data-uiid="forms-main-settings-advanced-tab">
            <div class="container-fluid">
                <div class="form-group">
                    <cerrix-checkbox
                        data-uiid="forms-main-settings-enable-summary-checkbox"
                        label="Enable summary."
                        [(value)]="form.ShowSummary"
                    ></cerrix-checkbox>
                    <cerrix-checkbox
                        data-uiid="forms-main-settings-limit-entry-checkbox"
                        label="Limit form to one (1) entry per user."
                        [(value)]="form.IsSingleEntry"
                    >
                    </cerrix-checkbox>
                    <small class="form-text text-muted mt-0"
                        >Deselect to allow multiple results per user. (default)</small
                    >
                </div>
                <hr />
                <cerrix-checkbox
                    data-uiid="forms-main-settings-enable-workflow-checkbox"
                    label="Enable workflow"
                    [(value)]="form.Workflow"
                    [disabled]="form.ID > 0"
                >
                </cerrix-checkbox>
                <div class="form-group" [hidden]="!form.Workflow">
                    <label for="form-title">Reviewer stalling days</label>
                    <input
                        data-uiid="forms-main-settings-reviewer-stallingdays-input"
                        type="number"
                        class="form-control"
                        id="form-MaxStallingDays"
                        [(ngModel)]="this.form.MaxStallingDays"
                        min="0"
                        max="1000"
                    />
                    <small class="form-text text-muted mt-0"
                        >Amount of days the form should await a reviewer. After this the previous
                        user can select another reviewer.</small
                    >
                    <small class="form-text text-muted mt-0" *ngIf="form.MaxStallingDays > 0"
                        >This value will be checked daily with the 'Scheduled Mail Alerts' job. At
                        the configured time this check will send results back that are stalled for
                        at least {{ form.MaxStallingDays * 24 }} hours.</small
                    >
                </div>
                <hr />
                <div class="form-group" *ngIf="!form.MapBack || form.MapBack === 0">
                    <small class="form-text text-muted mt-0">Select who may reopen result:</small>
                    <cerrix-checkbox
                        label="Entry Starter"
                        data-uiid="forms-main-settings-entry-starter-checkbox"
                        [(value)]="form.AllowReopenEntryStarter"
                    >
                    </cerrix-checkbox>
                    <cerrix-checkbox
                        data-uiid="forms-main-settings-last-reviewer-checkbox"
                        label="Last Reviewer"
                        [disabled]="!form.Workflow"
                        [(value)]="form.AllowReopenLastReviewer"
                    >
                    </cerrix-checkbox>
                    <cerrix-checkbox
                        label="Forms Data Owners"
                        data-uiid="forms-main-settings-data-owners-checkbox"
                        [disabled]="!form.DataOwners || form.DataOwners.length === 0"
                        [(value)]="form.AllowReopenFormsDataOwners"
                    >
                    </cerrix-checkbox>
                    <hr />
                </div>
                <div class="form-group" *ngIf="sd.mapbackClasses && sd.mapbackClasses.length > 0">
                    <label for="form-mapback">Mapback</label>
                    <ng-select
                        data-uiid="forms-main-settings-mapback-select"
                        [(ngModel)]="form.MapBack"
                        id="form-groups"
                        [items]="sd.mapbackClasses"
                        [multiple]="false"
                        bindValue="ID"
                        bindLabel="Name"
                        [disabled]="form.ID > 0"
                        [dropdownPosition]="'top'"
                    >
                    </ng-select>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Links" data-uiid="forms-main-settings-links-tab">
            <div class="container-fluid">
                <label for="form-links">Module</label>
                <ng-select
                    *ngIf="linkableModules"
                    data-uiid="forms-main-settings-module-select"
                    (change)="moduleChanged($event)"
                    [(ngModel)]="form.LinkedModule"
                    id="form-linkedmodule"
                    [multiple]="false"
                    [items]="linkableModules"
                    bindValue="ID"
                    bindLabel="Name"
                    [disabled]="moduleDisabled"
                >
                </ng-select>
                <small class="text-muted" *ngIf="moduleDisabled"
                    >To change the module, please unlink all items first.</small
                >

                <div class="form-group"></div>
                <app-linkable
                    *ngIf="this.selectedLinkedModule"
                    [typename]="typename"
                    disabled="true"
                    [displayValueProp]="displayValueProp"
                    [linked]="linked"
                    [unlinked]="unlinked"
                ></app-linkable>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<div class="modal-footer">
    <button
        type="button"
        data-uiid="forms-main-settings-save-button"
        class="btn btn-primary"
        (click)="onSaveChanges()"
    >
        Apply changes
    </button>
    <button
        type="button"
        data-uiid="forms-main-settings-cancel-button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
    >
        Cancel
    </button>
</div>
