import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { HttpClient } from "@angular/common/http";
import { MatrixModel } from "@models/generic/MatrixModel";

@Injectable()
export class SharedDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "shared");
    }

    getOrganizationMatrix(organizationID: number) {
        return this.http.get<MatrixModel>(this.actionUrl + `/${organizationID}/organizationmatrix`);
    }

    getDefaultMatrix() {
        return this.http.get<MatrixModel>(this.actionUrl + `/organizationmatrix`);
    }
}
