import { Component, OnInit } from "@angular/core";
import { Pages } from "../../../common/constants/pages/Pages";
import { TabService } from "../../../services/tabs/TabService";
import { ReportDataService } from "../services/ReportDataService";
import { ReportItem } from "../shared/ReportItem";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-report-overview",
    templateUrl: "./report-overview.component.html",
    styleUrls: ["./report-overview.component.scss"],
})
export class ReportOverviewComponent implements OnInit {
    data: ReportItem[];
    allModules: {};

    protected finishedLoading = false;

    constructor(
        public tabService: TabService,
        public pages: Pages,
        private _reportDS: ReportDataService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        this.allModules = await firstValueFrom(this._reportDS.getModules());
        this.data = await firstValueFrom(this._reportDS.getList());

        this.finishedLoading = true;
    }

    getCategories() {
        const modules = this.allModules;
        const items = this.data.map(function (a) {
            if (modules) {
                return modules[a.Module];
            }
        });
        return new Set(items);
    }

    getCategoryReports(c: string): ReportItem[] {
        const modules = this.allModules;
        const items = this.data.filter(function (a) {
            if (modules) {
                return modules[a.Module] === c;
            }
        });
        return items;
    }

    open(report: ReportItem) {
        this.tabService.generateTab(this.pages.PowerBIReport, report.ID, report.Name);
    }

    download(report: ReportItem) {
        this._promptService
            .prompt({
                maxHeight: 400,
                data: {
                    confirmOnEnter: false,
                    closeOnEsc: false,
                    title: "Export filter",
                    fields: [
                        {
                            prettyName: "Filter",
                            fieldName: "filter",
                            fieldType: GenericListFieldType.TextArea,
                            customValidation(row) {
                                const isInvalid = row.filter && row.filter.length > 2000;
                                return {
                                    makeRequired: isInvalid,
                                    validation: isInvalid
                                        ? "Filter can not be longer than 2000 characters"
                                        : "",
                                };
                            },
                        },
                    ],
                    confirmButton: {
                        text: "OK",
                    },
                    cancelButton: {
                        show: true,
                    },
                },
            })
            .getResult()
            .subscribe((result) => {
                if (result) {
                    let filter = result.filter.replace(/\n/g, " ");
                    this._reportDS.export(report.ID, filter);
                }
            });
    }
}
