import { Component, OnInit } from "@angular/core";
import { FindingReportDataService } from "@app/findingreport/services";
import { FindingReportWorkspaceModel } from "@app/findingreport/shared/models";
import { Pages } from "@constants/pages/Pages";
import { FilterType } from "@enums/FilterType";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "finding-report-overview",
    templateUrl: "./finding-report-overview.component.html",
    styleUrls: ["./finding-report-overview.component.scss"],
})
export class FindingReportOverviewComponent implements OnInit {
    moduleType = ModuleType.FR;
    tabID: string;
    tab: TabModel;

    data: Promise<any[]>;
    headerLookup: any = {
        identifier: "Identifier",
        reportType: "Report Type",
        reportNumber: "Report No",
        reportName: "Report Name",
        subName: "Sub Name",
        overallScore: "Overall Score",
        ownerOrganization: "Organization Responsible",
        reportDate: "Report Date",
        reportDueDate: "Report Due Date",
        ownerName: "Responsible",
        reviewerOrganization: "Organization Reviewer",
        reviewerName: "Reviewer",
        validationType: "Validation Type",
        numberOfMois: "# MoIs",
        numberOfOpenMois: "# Open MoIs",
        numberOfAssessments: "# Assessments",
        organizationPath: "Organization Path",
    };

    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations Responsible",
            column: "organizationId",
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "overallScore",
            actionColumn: "overallScoreColor",
            hideActionColumn: true,
            type: RendererType.Score,
        },
        {
            textColumn: "reportDate",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "reportDueDate",
            type: RendererType.Default,
            formatType: FormatType.DateFormat,
        },
    ];

    numberProps: string[] = [
        "numberOfMois",
        "numberOfOpenMois",
        "numberOfAssessments",
    ];
    dateProps: string[] = ["reportDate", "reportDueDate"];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private findingReportDataService: FindingReportDataService,
        private tabService: TabService,
        public pages: Pages,
        private permissionsService: PermissionsService
    ) {}

    ngOnInit() {
        this.permissions = this.permissionsService.permissions.Workspace.findingReport;
        this.loadData();
    }

    private loadData() {
        this.data = this.findingReportDataService
            .getFindingReports()
            .toPromise()
            .then(this.mapHeaders);
    }

    openDetail(fr: FindingReportWorkspaceModel) {
        this.tabService.generateTab(
            this.pages.ImDetail,
            fr.guid,
            `(F) ${+fr.identifier} - ${fr.reportName}`
        );
    }

    add() {
        this.tabService.generateTab(this.pages.ImDetail, null, "Add new Finding Report");
    }

    mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };
}
