<div class="card">
    <div class="card-body">
        <h5 class="card-title">Export BIM</h5>
        <h6 class="card-subtitle mb-2 text-muted">Select export type below</h6>
        <hr />
        <button class="btn btn-default me-2 mb-2" (click)="this.moiExport()">MoIs</button>
        <button class="btn btn-default me-2 mb-2" (click)="this.moiSummaryExport()">
            MoI Summary
        </button>
        <button
            class="btn btn-default me-2 mb-2"
            *ngIf="isBimAdmin"
            (click)="this.moiCommentsExport()"
        >
            MoIs and Comments
        </button>
        <button class="btn btn-default me-2 mb-2" (click)="this.findingReportAssessmentExport()">
            Finding reports & assessments
        </button>
        <button class="btn btn-default me-2 mb-2" (click)="this.findingReportInformationExport()">
            Finding report information
        </button>
    </div>
</div>
