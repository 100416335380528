<div class="modal-body">
    <div *ngIf="!finishedLoading">
        <div class="fancy-loader"></div>
    </div>
    <form novalidate *ngIf="finishedLoading">
        <div class="container cerrix-compact" [formGroup]="mainFormGroup">
            <div class="row">
                <label class="col col-form-label">Sample type</label>
                <div class="col-8" *ngIf="sampleType">
                    <select
                        class="form-select form-select-sm"
                        formControlName="sampleType"
                        (change)="sampleCountChanged()"
                    >
                        <option
                            *ngFor="let sampleTypeOption of sampleTypeOptions"
                            [ngValue]="sampleTypeOption.id"
                            selected
                        >
                            {{ sampleTypeOption.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="sampleType && sampleType == SampleType.spreadsheet">
                <label class="col col-form-label">Source document</label>
                <div class="col-8">
                    <select
                        class="form-select form-select-sm"
                        formControlName="documentGuid"
                        (change)="sourceDocumentChanged()"
                    >
                        <option
                            *ngFor="let sourceDocument of sourceDocuments"
                            [value]="sourceDocument.Guid"
                            selected
                        >
                            {{ sourceDocument.Name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="sampleType && sampleType != SampleType.date">
                <label class="col col-form-label">Population size</label>
                <div class="col-8">
                    <input
                        type="number"
                        [min]="numberOfTestSamples"
                        max="maxPopulationSize"
                        step="1"
                        formControlName="populationSize"
                        class="form-control form-control-sm"
                    />
                </div>
            </div>
            <div class="row" *ngIf="sampleType">
                <label class="col col-form-label">Test samples</label>
                <div class="col-8">
                    <input
                        type="number"
                        min="1"
                        max="100"
                        step="1"
                        (input)="sampleCountChanged()"
                        formControlName="numberOfTestSamples"
                        class="form-control form-control-sm"
                    />
                </div>
            </div>
            <div *ngIf="sampleType && model.sampleGenerationType == SampleGenerationType.manual">
                <hr />
                <div class="manual-samples-container">
                    <div
                        class="row"
                        formArrayName="numberSamples"
                        *ngFor="
                            let numberValue of mainFormGroup.get('numberSamples')['controls'];
                            let i = index
                        "
                    >
                        <label class="col col-form-label">Sample {{ i + 1 }}</label>
                        <div class="col-8">
                            <input
                                type="number"
                                [formControlName]="i"
                                class="form-control form-control-sm"
                                step="1"
                            />
                        </div>
                    </div>
                    <div
                        class="row"
                        formArrayName="dateSamples"
                        *ngFor="
                            let dateValue of mainFormGroup.get('dateSamples')['controls'];
                            let i = index
                        "
                    >
                        <label class="col col-form-label">Sample {{ i + 1 }}</label>
                        <div class="col-8">
                            <date-input
                                [formControlName]="i"
                                [minDate]="startDate"
                                [maxDate]="endDate"
                            ></date-input>
                        </div>
                    </div>
                    <div
                        class="row"
                        formArrayName="textSamples"
                        *ngFor="
                            let textValue of mainFormGroup.get('textSamples')['controls'];
                            let i = index
                        "
                    >
                        <label class="col col-form-label">Sample {{ i + 1 }}</label>
                        <div class="col-8">
                            <input
                                type="text"
                                [formControlName]="i"
                                [maxlength]="maxTextFieldLength"
                                class="form-control form-control-sm"
                                step="1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
