<div class="dialog-title" mat-dialog-title>
    <cerrix-title
        style="flex: 1"
        size="large"
        [editable]="false"
        [value]="dialogTitle"
    ></cerrix-title>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">
    <div [formGroup]="documentForm" class="edit-document-container">
        <cerrix-field-wrapper label="Files" type="secondary">
            <div class="documents-list">
                <div class="document-item" *ngFor="let document of dialogData.documents">
                    <div class="icon-container">
                        <span
                            *ngIf="document.fileIcon"
                            [class]="'document-icon fal fa-' + document.fileIcon"
                        ></span>
                    </div>
                    <div class="document-name">{{ document.name }}</div>
                    <div class="document-action">
                        <cerrix-confirmation-button
                            *ngIf="dialogData.documents.length > 1"
                            btnLabel="Delete?"
                            (onConfirmationClick)="onRemoveFile(document)"
                        >
                        </cerrix-confirmation-button>
                    </div>
                </div>
            </div>
        </cerrix-field-wrapper>

        <cerrix-field-wrapper class="document-type" label="Document Type" type="secondary">
            <cerrix-select
                formControlName="documentTypeId"
                [options]="dialogData.documentTypes"
            ></cerrix-select>
        </cerrix-field-wrapper>

        <cerrix-field-wrapper label="Description" type="secondary">
            <cerrix-textarea formControlName="description"></cerrix-textarea>
        </cerrix-field-wrapper>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
    <cerrix-button color="accent" mat-dialog-close>Close</cerrix-button>

    <cerrix-button
        *ngIf="dialogData.openReason !== 'View'"
        color="success"
        (click)="onApplyChanges()"
        >{{ btnLabel }}</cerrix-button
    >
</mat-dialog-actions>
