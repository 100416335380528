import { Component, OnInit, Input } from "@angular/core";
import { AuditFieldworkStepCommentModel } from "@app/audit/shared/data-models/audit-fieldwork-step-comment-model";
import { AuditFieldworkStepDataService } from "@app/audit/services/audit-fieldworkstep-data.service";

@Component({
    selector: "audit-fieldwork-step-comments",
    templateUrl: "./audit-fieldwork-step-comments.component.html",
    styleUrls: ["./audit-fieldwork-step-comments.component.scss"],
})
export class AuditFieldworkStepCommentsComponent implements OnInit {
    @Input() auditGuid: string;
    @Input() auditFieldworkStepGuid: string;

    isLoading = true;
    comments: AuditFieldworkStepCommentModel[];
    scopedComments: AuditFieldworkStepCommentModel[];

    showAll = false;

    constructor(private _ds: AuditFieldworkStepDataService) {}

    ngOnInit(): void {
        if (!this.auditFieldworkStepGuid) {
            this.isLoading = false;
            return;
        }

        this.loadComments();
    }

    loadComments(): void {
        this._ds
            .getComments(this.auditGuid, this.auditFieldworkStepGuid)
            .subscribe((value: AuditFieldworkStepCommentModel[]) => {
                this.comments = value;
                this.comments = this.comments.sort(
                    (val1, val2) => val2.createdAt.getTime() - val1.createdAt.getTime()
                );

                this.showAllClick(false);
                this.isLoading = false;
            });
    }

    showAllClick(showAll: boolean) {
        if (showAll) {
            this.scopedComments = this.comments;
        } else {
            this.scopedComments = this.comments.slice(0, 5);
        }

        this.showAll = showAll;
    }
}
