import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { KriDataService } from "@app/kri/services/kri-data.service";
import { LocalizationService } from "@app/shared";
import { ExportType } from "@enums/ExportType";
import { getFormControl, getFormValue } from "@methods/CommonMethods";
import { FindNode } from "@methods/TreeMethods";
import { KriModel } from "@models/kri";
import { KriDataModel } from "@models/kri/KriDataModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ExportDataService } from "@app/export/services/export-data.service";

@Component({
    selector: "kri-data",
    templateUrl: "./kri-data.component.html",
    styleUrls: ["./kri-data.component.scss"],
})
export class KriDataComponent implements OnInit {
    @Input() mainFormGroup: FormGroup;
    @Input() kri: KriModel;
    @Input() canEdit: boolean;

    targetedOrganization: number;
    highlightedRowIndex: number;

    organizations: CerrixTreeItem[];

    constructor(
        private _localizationService: LocalizationService,
        private _kriDataService: KriDataService,
        private _exportDs: ExportDataService
    ) {}

    ngOnInit(): void {
        this._kriDataService
            .getOrganizations([])
            .subscribe((orgnizations) => (this.organizations = orgnizations));
    }

    applyOrganization() {
        if (this.targetedOrganization && this.highlightedRowIndex != null) {
            const dataFormArray = getFormControl<KriModel>(
                this.mainFormGroup,
                "datas"
            ) as FormArray;
            const dataItemFormGroup = dataFormArray.controls[this.highlightedRowIndex] as FormGroup;
            if (dataItemFormGroup) {
                const orgNode = FindNode(this.organizations, this.targetedOrganization);

                getFormControl<KriDataModel>(dataItemFormGroup, "organizationId").setValue(
                    orgNode.ID
                );
                getFormControl<KriDataModel>(dataItemFormGroup, "organizationName").setValue(
                    orgNode.Name
                );
            }
        }
    }

    addData() {
        const orgNode = this.targetedOrganization
            ? FindNode(this.organizations, this.targetedOrganization)
            : null;

        const dataFormArray = getFormControl<KriModel>(this.mainFormGroup, "datas") as FormArray;
        const dataFormGroup = new FormGroup({
            id: new FormControl(-1),
            value: new FormControl(0, Validators.required),
            date: new FormControl(
                this._localizationService.getUserDateAsUtc(),
                Validators.required
            ),
            time: new FormControl("00:00:00", Validators.required),
            organizationId: new FormControl(orgNode ? orgNode.ID : null, Validators.required),
            organizationName: new FormControl(orgNode ? orgNode.Name : null),
        });

        dataFormArray.insert(0, dataFormGroup);
        if (this.highlightedRowIndex != null) {
            this.highlightedRowIndex += 1;
        }
    }

    deleteDataRow(index: number) {
        const dataFormArray = getFormControl<KriModel>(this.mainFormGroup, "datas") as FormArray;

        dataFormArray.removeAt(index);
        if (this.highlightedRowIndex >= index) {
            this.highlightedRowIndex =
                this.highlightedRowIndex === index ? null : this.highlightedRowIndex - 1;
        }
    }

    getKriDataItemScore(dataFormGroup: FormGroup) {
        const value = getFormValue<KriDataModel>(dataFormGroup, "value") as number;
        const greenThreshold = getFormValue<KriModel>(
            this.mainFormGroup,
            "greenThreshold"
        ) as number;
        const redThreshold = getFormValue<KriModel>(this.mainFormGroup, "redThreshold") as number;

        const reversedThreshold = greenThreshold > redThreshold;
        if (reversedThreshold) {
            if (value <= redThreshold) {
                return "red";
            } else if (value < greenThreshold) {
                return "orange";
            } else {
                return "green";
            }
        } else {
            if (value >= redThreshold) {
                return "red";
            } else if (value > greenThreshold) {
                return "orange";
            } else {
                return "green";
            }
        }
    }

    exportData() {
        this._exportDs.downloadExport(ExportType.KriData, `kriGuid=${this.kri.guid}`);
    }
}
