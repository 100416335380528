<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar" *ngIf="moiModel && moiPermissions">
    <button
        class="btn btn-cerrix"
        (click)="save()"
        [disabled]="moiPermissions.canOnlyReadMoi || hideSaveButton"
    >
        <i class="fas fa-save"></i>
        <span data-uiid="moi-save" class="d-none d-lg-inline">Save MoI</span>
    </button>

    <span class="date-edited" *ngIf="moiModel.Id > 0">
        Last save: {{ moiModel.DateUpdated | dateTimeFormat }}
    </span>

    <div class="workflow-area" *ngIf="moiModel.Id > 0">
        <span class="workflow-description">
            Workflow Status |
            <b>{{ moiStatusDescription }}</b>
        </span>
        <span *ngIf="!workflowDisabled">
            <button
                data-uiid="moi-confirm-button"
                class="btn btn-success"
                type="button"
                (click)="nextStatus(true)"
                matTooltip="Change status to [Confirmed]. An email will be sent to the reviewer and delegate."
                *ngIf="moiPermissions.canConfirm"
            >
                Confirm
            </button>
            <button
                data-uiid="moi-decline-button"
                class="btn btn-danger"
                type="button"
                (click)="nextStatus(false)"
                matTooltip="Change status to [Declined]. An email will be sent to the reviewer."
                *ngIf="moiPermissions.canConfirm"
            >
                Decline
            </button>
            <button
                data-uiid="moi-resubmit-button"
                class="btn btn-success"
                type="button"
                (click)="nextStatus(true)"
                matTooltip="Change status to [Unconfirmed]. An email will be sent to the reviewer."
                *ngIf="moiPermissions.canResubmit"
            >
                Resubmit
            </button>

            <button
                data-uiid="moi-ready-for-acceptance-button"
                class="btn btn-success"
                type="button"
                (click)="nextStatus(true)"
                *ngIf="moiPermissions.canSetReadyForAcceptance"
                matTooltip="Change status to [Ready for acceptance]. An email will be sent to the reviewer"
            >
                Ready for acceptance
            </button>

            <button
                data-uiid="moi-accept-button"
                class="btn btn-success"
                type="button"
                (click)="nextStatus(true)"
                matTooltip="Change status to [Accepted]. An email will be sent to the responsible and delegate."
                *ngIf="moiPermissions.canAccept"
            >
                Accept
            </button>
            <button
                data-uiid="moi-reject-button"
                class="btn btn-danger"
                type="button"
                (click)="nextStatus(false)"
                matTooltip="Change status to [In progress]. An email will be sent to the responsible and delegate."
                *ngIf="moiPermissions.canAccept"
            >
                Reject
            </button>

            <button
                data-uiid="moi-close-button"
                class="btn btn-warning"
                type="button"
                (click)="closeMoi()"
                matTooltip="Change status to [Closed]. An email will be sent to the responsible and delegate."
                *ngIf="moiPermissions.canClose"
            >
                Close MoI
            </button>
            <button
                data-uiid="moi-reopen-button"
                class="btn btn-dark"
                type="button"
                (click)="nextStatus(true)"
                matTooltip="Reopen the MoI. It will be set to the last active status."
                *ngIf="moiPermissions.canReopen"
            >
                Reopen
            </button>
        </span>
    </div>
</div>

<div
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    *ngIf="moiModel && moiPermissions"
    class="moi-screen-system"
>
    <div
        class="moi-screens"
        [attr.name]="moiTypeDescription"
        menuicon="fas fa-tasks"
        menusystemsubmenu
    >
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem *ngIf="sd">
            <div class="detail-page-summary" *ngIf="moiModel.Id > 0">
                <div class="row">
                    <div class="col-6">
                        <div class="property"><b>ID:</b> {{ moiModel.Identifier }}</div>
                        <div class="property">
                            <b>Name:</b> <span data-uiid="moi-name-label">{{ moiModel.Name }}</span>
                        </div>
                        <div class="property"><b>Requester:</b> {{ moiModel.Requester }}</div>
                    </div>
                    <div class="col-6">
                        <div class="property">
                            <b>Due date:</b> {{ moiModel.DueDate | dateFormat }}
                        </div>
                        <div class="property">
                            <b>Initial due date:</b> {{ moiModel.InitialDueDate | dateFormat }}
                        </div>
                        <div class="property">
                            <b>Date created:</b> {{ moiModel.DateCreated | dateFormat }}
                        </div>
                        <div class="property">
                            <b>Date status changed:</b>
                            {{ moiModel.DateStatusChanged | dateFormat }}
                        </div>
                    </div>
                </div>
            </div>

            <moi-comments
                [moiGuid]="moiModel.Guid"
                [permissions]="moiPermissions.commentPermissions"
                *ngIf="moiModel.Id > 0"
                #comments
            >
            </moi-comments>

            <moi-detail-main
                [moiModel]="moiModel"
                [sd]="sd"
                [permissions]="moiPermissions"
                [parentSummary]="parentSummary"
                [parentForm]="parentForm"
            >
            </moi-detail-main>
        </div>
        <div [attr.name]="'Links'" menuicon="fas fa-link" menusystemsubmenu>
            <div
                name="Risks"
                menuicon="fas fa-exclamation-triangle"
                menusystemitem
                *ngIf="showLinkedRisksAndControls"
            >
                <moi-risks
                    *ngIf="moiModel"
                    [model]="moiModel"
                    [disabled]="!moiPermissions.canLinkControls"
                    (changed)="linkedRiskControlsChanged()"
                    #moiRisksComponent
                >
                </moi-risks>
            </div>
            <div
                name="Controls"
                menuicon="fas fa-shield-alt"
                menusystemitem
                *ngIf="showLinkedRisksAndControls"
            >
                <moi-controls
                    *ngIf="moiModel"
                    [model]="moiModel"
                    [disabled]="!moiPermissions.canLinkControls"
                    [moiPermissions]="moiPermissions"
                    (changed)="linkedRiskControlsChanged()"
                    #moiControlsComponent
                >
                </moi-controls>
            </div>
            <div
                name="Audit findings"
                menuicon="fas fa-radar"
                menusystemitem
                *ngIf="showLinkedFindings"
            >
                <moi-findings
                    *ngIf="moiModel"
                    [model]="moiModel"
                    [disabled]="!moiPermissions.canLinkFindings"
                    [moiPermissions]="moiPermissions"
                    (changed)="linkedRiskControlsChanged()"
                    #moiFindingsComponent
                >
                </moi-findings>
            </div>
            <div
                name="Documents"
                menuicon="far fa-file"
                menusystemitem
                *ngIf="moiModel.Id > 0 && targetModule"
            >
                <document-manager-old
                    [(Documents)]="moiModel.Documents"
                    [permissions]="moiPermissions.documentPermissions"
                    (DocumentsChange)="checkDirty()"
                    [DocumentTypeTargets]="targetModule"
                >
                </document-manager-old>
            </div>
            <div
                name="Hyperlinks"
                [attr.menuItemID]="hyperlinksMenuItemId"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="moiModel.Id > 0"
            >
                <div *ngIf="!moiModel.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="moiModel.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="moiModel.Hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                        [Readonly]="moiPermissions.canOnlyReadMoi"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>
        <div name="History" menuicon="fas fa-history" menusystemitem *ngIf="moiModel.Id > 0">
            <app-history-overview [historyDataService]="_moiDS" [Id]="moiModel.Guid">
            </app-history-overview>
        </div>
    </div>
</div>
