import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { Observable } from "rxjs";

export class GenericListField {
    fieldType: GenericListFieldType;

    uiid?: string;

    fieldName: string;
    prettyName?: string;
    description?: string;
    placeholder?: string;

    showDescriptionInTable?: boolean;

    /** Width of field (0-12) where 12 is full width. */
    editorWidth?: number;

    defaultValue?: any;
    minimumValue?: any;
    maximumValue?: any;

    isReadonly?: boolean;

    hideInEdit?: boolean;
    hideInOverview?: boolean;
    // This only has effect if hideInOverview is true
    includeInSearch?: boolean;

    overviewSortOrder?: number;

    // Do not use this. This will be overriden constantly.
    formattedName?: string;

    required?: boolean;
    unique?: boolean;
    /** This will check all fields that have this set to true. At least one of these fields must be filled. */
    conditionallyRequired?: boolean;
    /** Can be used optionally in combination with conditionallyRequired, give any value which it should meet  */
    conditionallyRequiredValue?: any;

    minLength?: number;
    maxLength?: number;

    // This must be a methodname inside standingdataDataService service.
    getDataMethodName?: string;
    getDataParams?: any;

    additionalConfig?: any;

    selfInit?: boolean;
    hideFillers?: boolean;

    // used to append ng-selects to a certain element in the page
    appendTo?: string;

    /** Perform custom validation based on all values. Return null or empty string if validations passed. Otherwise return validation error. */
    customValidation?(row: any): { validation: string; makeRequired: boolean };

    /** This method will be called on ngOnChanges events within generic field editor, to determine if metadata should be reset. */
    checkReloadDataByRowChanges?(previousRow: any, currentRow: any): boolean;

    /** This will be executed every time the user opens a existing row or creates a new row. This method gets highest priority!*/
    getDataMethodByRow?(row: any, ds?: StandingdataDataService): Observable<any>;
    /** This will be executed once. If this is not set, the application will look for getDataMethodName. */
    getDataMethod?(): Observable<any>;
    defaultValueCalculate?(allRows: any[]): any;

    hideInEditBySettingsService?(settingsService: SettingsDataService): Promise<boolean>;
}

export enum GenericListFieldType {
    Label,
    Text,
    TextArea,
    CheckBox,
    Number,
    Decimal,
    Email,
    Phone,

    Date,
    DateTime,

    SingleSelect,
    MultiSelect,

    ColoredSelect,
    StringArray,

    SingleTree,
    MultiTree,

    Documents,

    ColorPicker,
    IconPicker,

    GenericList,

    PreBox,
    RenderBox,

    WYSIWYG,

    // Custom FieldTypes (Requires custom implementation)
    MultiSelectRiskMatrix,
    OrganizationRiskMatrixEditor,
    FrameworkAssessmentScores,

    CustomFields,
}
