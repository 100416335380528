import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocalizationInfo } from "./localization-info";
import { Configuration } from "../../app.constants";
import { Subscription, Observable } from "rxjs";
import { SettingsDataService } from "services/http/SettingsDataService";
import { ApiDataService } from "@services/http/ApiDataService";
import moment from "moment";
import { DateTimeFormats } from "./date-time-formats.enum";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { registerLocaleData } from "@angular/common";
import localeNL from "@angular/common/locales/en-NL";

@Injectable({
    providedIn: "root",
})
export class LocalizationService extends ApiDataService {
    private _isInitialized = false;
    private _localizationInfo: LocalizationInfo = new LocalizationInfo();
    private _settingsChangedSubscription: Subscription;

    get localizationInfo(): LocalizationInfo {
        return Object.create(this._localizationInfo);
    }
    get isInitialized(): boolean {
        return this._isInitialized;
    }

    constructor(
        httpClient: HttpClient,
        configuration: Configuration,
        settingsDataService: SettingsDataService,
        private bsDatepickerConfig: BsDatepickerConfig
    ) {
        super(httpClient, configuration, "localization");

        this._settingsChangedSubscription = settingsDataService
            .getSavedChangeObservable()
            .subscribe((id) => {
                if (this._localizationInfo.settingIds.indexOf(id) >= 0) {
                    this.reload();
                }
            });

        this.getLocalizationInfo().subscribe((localizationInfo) => {
            this.setNewLocalizationInfo(localizationInfo);
            this._isInitialized = true;

            if (localizationInfo.locale === "en-NL") registerLocaleData(localeNL);
        });
    }

    public formatDateTime(
        date: Date | string,
        addTime: boolean = false,
        addSeconds: boolean = false
    ) {
        if (!date) {
            return null;
        }

        var format = !addTime
            ? this.localizationInfo.dateFormat
            : addSeconds
            ? this.localizationInfo.dateTimeFormat
            : this.localizationInfo.dateShortTimeFormat;

        return moment(date).format(format);
    }

    public formatDate(date: Date, format: string = null) {
        let momentDate = moment(date);
        if (format && format !== this.localizationInfo.dateFormat) {
            momentDate = momentDate.local();
        } else {
            momentDate = momentDate.utc();
        }
        return momentDate.format(format || this.localizationInfo.dateFormat);
    }

    public formatDateByEnum(date: Date, format: DateTimeFormats) {
        if (!date) {
            return "";
        }

        switch (format) {
            case DateTimeFormats.Date: {
                return this.formatDate(date, this.localizationInfo.dateFormat);
            }
            case DateTimeFormats.DateShortTime: {
                return this.formatDate(date, this.localizationInfo.dateShortTimeFormat);
            }
            case DateTimeFormats.ShortTime: {
                return this.formatDate(date, this.localizationInfo.shortTimeFormat);
            }
            case DateTimeFormats.Time: {
                return this.formatDate(date, this.localizationInfo.timeFormat);
            }
            default: {
                return this.formatDate(date, this.localizationInfo.dateTimeFormat);
            }
        }
    }

    public formatInvariantDate(date: Date, includeTime = false) {
        return moment(date)
            .local()
            .format(includeTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD");
    }

    public getExportDateTimeFormat(): { dateFormat: string; timeFormat: string } {
        return {
            dateFormat: this.localizationInfo.dateFormat.toLowerCase().replace("mm", "MM"),
            timeFormat: this.localizationInfo.shortTimeFormat.includes("a")
                ? this.localizationInfo.shortTimeFormat.replace("a", "tt")
                : this.localizationInfo.shortTimeFormat,
        };
    }

    public getUserDateAsUtc() {
        const currentDate = new Date();
        return new Date(
            Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        );
    }

    private reload() {
        return this.getLocalizationInfo().subscribe((localizationInfo) => {
            this.setNewLocalizationInfo(localizationInfo);
        });
    }

    private setNewLocalizationInfo(localizationInfo: LocalizationInfo) {
        this._localizationInfo = localizationInfo;
        this.bsDatepickerConfig.dateInputFormat = localizationInfo.dateFormat;
        this.bsDatepickerConfig.rangeInputFormat = localizationInfo.dateFormat;
    }

    private getLocalizationInfo(): Observable<LocalizationInfo> {
        return this.get<LocalizationInfo>();
    }
}
