<div class="detail-page-action-bar" *ngIf="inviteConfig">
    <button class="btn btn-cerrix" (click)="sendInvite()" *ngIf="inviteConfig.onSend">
        <i class="fas fa-paper-plane" aria-hidden="true"></i>
        <span class="d-none d-lg-inline ms-2">Send</span>
    </button>

    <span class="sub-message">{{ inviteConfig.inviteTargetMessage }}</span>

    <div class="workflow-area">
        <button class="btn btn-outline-secondary" (click)="presetModal.show()">
            <i class="fas fa-bookmark" aria-hidden="true"></i>
            <span class="d-none d-lg-inline ms-2">Preset management</span>
        </button>
    </div>
</div>

<div class="detail-page-card">
    <div class="detail-page-card-title">Compose invite</div>
    <div
        class="detail-page-card-body padded form-group-styling invite-compose-area"
        *ngIf="inviteConfig"
    >
        <div class="form-group" *ngIf="inviteConfig.users.length > 0">
            <label for="userSelect">User selection</label>
            <div class="inline-form-group">
                <ng-select
                    id="userSelect"
                    name="userSelect"
                    [items]="inviteConfig.users"
                    bindLabel="username"
                    bindValue="ID"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [searchFn]="customSearchFn"
                    [(ngModel)]="inviteConfig.userSelection"
                >
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice: 0:10">
                            <span class="ng-value-label">{{ item["displayName"] }}</span>
                            <span
                                class="ng-value-icon right"
                                (click)="clear.call(undefined, item)"
                                aria-hidden="true"
                                >×</span
                            >
                        </div>

                        <div class="ng-value" *ngIf="items.length > 10">
                            <span class="ng-value-label">{{ items.length - 10 }} more...</span>
                        </div>
                    </ng-template>

                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                        let-search="searchTerm"
                    >
                        <p class="mb-0">
                            <b>{{ item.displayName }}</b>
                            <small>&nbsp;({{ item.mail }})</small>
                        </p>
                    </ng-template>
                </ng-select>

                <button class="btn btn-cerrix btn-sm" (click)="userModal.show()">
                    <i class="fas fa-search" aria-hidden="true"></i>
                    <span class="d-none d-lg-inline ms-2">Find users</span>
                </button>
            </div>
        </div>

        <div class="form-group">
            <label>Subject</label>
            <input
                #subjectInput
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="inviteConfig.subject"
                name="invite-subject"
                inputTooltip
            />
        </div>

        <cerrix-contextmenu
            [menu]="contextMenu"
            [container]="subjectInput"
            *ngIf="contextMenu"
        ></cerrix-contextmenu>

        <div class="form-group">
            <label>Message</label>
            <div class="height-limitter">
                <cerrix-editor
                    [(value)]="inviteConfig.message"
                    [tags]="inviteConfig.tags"
                    [allowPdfEmbed]="false"
                    [allowImageUpload]="false"
                    #cerrixEditor
                >
                </cerrix-editor>
            </div>
        </div>
    </div>
</div>

<div bsModal #userModal="bs-modal" class="modal fade overflow-hidden" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl user-selection-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">User search</h4>
                <button
                    type="button"
                    class="btn-close pull-right"
                    (click)="userModal.hide()"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div
                        class="form-group col-12"
                        *ngIf="inviteConfig.organizations"
                        [class.col-lg-6]="inviteConfig.quickFilterProperties"
                    >
                        <label for="organizationSelect">Organization</label>
                        <cerrix-select-tree
                            [tree]="inviteConfig.organizations"
                            [(value)]="organizationSelection"
                            [multiselect]="true"
                            (onChangeIds)="filterChanged()"
                        >
                        </cerrix-select-tree>
                    </div>
                    <div
                        class="form-group col-12"
                        *ngIf="inviteConfig.quickFilterProperties"
                        [class.col-lg-6]="inviteConfig.organizations"
                    >
                        <label for="organizationSelect">Keyword filtering</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [(ngModel)]="quickFilter"
                            (debounce-input-event)="filterChanged()"
                            placeholder="Quick filter/search users"
                            inputTooltip
                        />
                    </div>
                </div>
                <div>
                    <button class="btn btn-outline-primary" (click)="toggleFilteredItems()">
                        Toggle filtered users
                    </button>
                </div>
                <div class="row user-cards">
                    <div
                        class="user-card col-3"
                        *ngFor="let user of inviteConfig.users"
                        [class.disabled]="user.disabled"
                        [hidden]="user['_hidden']"
                        (click)="userClicked(user)"
                    >
                        <ng-template
                            *ngIf="!user['_hidden']"
                            ngFor
                            let-item
                            [ngForOf]="[user]"
                            [ngForTemplate]="userTemplate"
                        >
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #presetModal="bs-modal" class="modal fade overflow-hidden" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Invite presets</h4>
                <button
                    type="button"
                    class="btn-close pull-right"
                    (click)="presetModal.hide()"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="presetSelect">Available presets</label>
                    <div class="inline-form-group">
                        <ng-select
                            id="presetSelect"
                            name="presetSelect"
                            [items]="inviteConfig.templates"
                            bindLabel="templateName"
                            bindValue="ID"
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [(ngModel)]="selectedPreset"
                        >
                        </ng-select>

                        <button
                            class="btn btn-danger btn-sm"
                            (click)="deletePreset()"
                            [disabled]="!selectedPreset"
                        >
                            <i class="fas fa-trash" aria-hidden="true"></i>
                            <span class="d-none d-lg-inline ms-2">Delete preset</span>
                        </button>

                        <button
                            class="btn btn-cerrix btn-sm"
                            (click)="loadPreset(); presetModal.hide()"
                            [disabled]="!selectedPreset"
                        >
                            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                            <span class="d-none d-lg-inline ms-2">Load preset</span>
                        </button>
                    </div>
                </div>

                <div class="form-group">
                    <div class="inline-form-group">
                        <cerrix-textfield
                            fieldLabel="New preset"
                            identifier="newPresetInput"
                            [(ngModel)]="newPresetName"
                        ></cerrix-textfield>
                        <button class="btn btn-cerrix btn-sm" (click)="saveNewPreset()">
                            <i class="fas fa-save" aria-hidden="true"></i>
                            <span class="d-none d-lg-inline ms-2">Save preset</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
