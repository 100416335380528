import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Configuration } from "../../../app/app.constants";
import { ApplicationPermissionsModel } from "../../../common/models/permissions/ApplicationPermissionsModel";
import { ApiDataService } from "../ApiDataService";

@Injectable()
export class PermissionsDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "permissions");
    }

    public getPermissions(): Observable<ApplicationPermissionsModel> {
        return this.get<ApplicationPermissionsModel>();
    }
}
