import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from "@angular/core";

declare var $: any;

@Component({
    selector: "eff-button-with-confirmation",
    templateUrl: "./eff-button-with-confirmation.component.html",
    styleUrls: ["./eff-button-with-confirmation.component.scss"],
})
export class EffButtonWithConfirmationComponent {
    @Input() icon: string;
    @Input() label: string;
    @ViewChild("confirmationButton") confirmationButton: ElementRef;

    showConfirmation: boolean = false;

    @Output() onConfirmation: EventEmitter<any> = new EventEmitter();

    ngAfterViewInit() {
        $(document).click(
            function () {
                this.showConfirmation = false;
            }.bind(this)
        );
    }

    onClick() {
        this.showConfirmation = true;
    }

    onConfirmationClick() {
        this.onConfirmation.emit();
    }
}
