import { ApiResponseModel } from "./api-response-model";
import { ApiParamModel } from "./api-param-model";

export class ApiSpecificationModel {
    name: string;
    description?: string;

    httpMethod: "GET" | "POST" | "DELETE";

    path: string;

    parameters: ApiParamModel[];
    responses: ApiResponseModel[];
}
