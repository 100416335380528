import { Component } from "@angular/core";
import { ExportDataService } from "@app/export/services/export-data.service";
import { RiskAssessmentPerOrganizationModel } from "@app/export/shared/models/risk-assessment-per-organization-model";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleType } from "@enums/ModuleType";
import { nameof } from "@methods/jeffs-toolkit";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ExportBase } from "../export-base";
import { CerrixPromptService } from "../../../shared/services/cerrix-prompt.service";
import { TasksExportFilterModel } from "@app/export/shared/models/tasks-export-filter-model";
import { LocalizationService } from "@app/shared";

@Component({
    selector: "export-tasks",
    templateUrl: "./export-tasks.component.html",
    styleUrls: ["./export-tasks.component.scss"],
})
export class ExportTasksComponent extends ExportBase {
    constructor(
        exportDataService: ExportDataService,
        public permService: PermissionsService,
        private cerrixPrompt: CerrixPromptService,
        private dataService: StandingdataDataService,
        private localizationService: LocalizationService
    ) {
        super(exportDataService);
    }

    exportTasks(): void {
        this.cerrixPrompt
            .prompt({
                height: "fit-content",
                maxHeight: 900,
                maxWidth: 800,
                data: {
                    title: "Tasks Export",

                    fields: [
                        {
                            prettyName: "Organizations",
                            fieldName: nameof((x: TasksExportFilterModel) => x.organizations),
                            fieldType: GenericListFieldType.MultiTree,
                            required: false,
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getOrganizations(ModuleType.Controls); // TODO #12834: Has to be updated to use correct right after
                            },
                        },
                        {
                            prettyName: "Business Dimensions",
                            fieldName: nameof((x: TasksExportFilterModel) => x.businessDimensions),
                            fieldType: GenericListFieldType.MultiTree,
                            required: false,
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getUserViewableBusinessDimensions(
                                    ModuleType.Controls
                                ); // TODO #12834: Has to be updated to use correct right after
                            },
                        },
                        {
                            prettyName: "Framework Dimensions",
                            fieldName: nameof((x: TasksExportFilterModel) => x.frameworkDimensions),
                            fieldType: GenericListFieldType.MultiTree,
                            required: false,
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getFrameworkDimensions(ModuleType.Controls); // TODO #12834: Has to be updated to use correct right after
                            },
                        },
                        {
                            prettyName: "Start Date",
                            fieldName: nameof((x: TasksExportFilterModel) => x.startDate),
                            fieldType: GenericListFieldType.Date,
                            required: false,
                            selfInit: true,
                            defaultValue: null,
                        },
                        {
                            prettyName: "End Date",
                            fieldName: nameof((x: TasksExportFilterModel) => x.endDate),
                            fieldType: GenericListFieldType.Date,
                            required: false,
                            selfInit: true,
                            defaultValue: null,
                        },
                        {
                            prettyName: "Responsibles",
                            fieldName: nameof((x: TasksExportFilterModel) => x.responsibles),
                            fieldType: GenericListFieldType.MultiSelect,
                            placeholder: "No filter",
                            required: false,
                            selfInit: true,
                            getDataMethod: () => {
                                return this.dataService.getTaskResponsibleusers();
                            },
                        },
                    ],
                    confirmButton: {
                        text: "Download",
                        icon: "fad fa-cloud-download",
                    },
                },
            })
            .toPromise()
            .then((result: TasksExportFilterModel) => {
                if (!result) {
                    return;
                }

                const stringSections: string[] = [];
                if (result.organizations && result.organizations.length > 0) {
                    stringSections.push("orgs=" + result.organizations.join(";"));
                }

                if (result.businessDimensions && result.businessDimensions.length > 0) {
                    stringSections.push("bds=" + result.businessDimensions.join(";"));
                }

                if (result.frameworkDimensions && result.frameworkDimensions.length > 0) {
                    stringSections.push("fds=" + result.frameworkDimensions.join(";"));
                }

                if (result.responsibles && result.responsibles.length > 0) {
                    stringSections.push("users=" + result.responsibles.join(";"));
                }

                if (result.startDate) {
                    stringSections.push(
                        "start=" + this.localizationService.formatInvariantDate(result.startDate)
                    );
                }

                if (result.endDate) {
                    stringSections.push(
                        "end=" + this.localizationService.formatInvariantDate(result.endDate)
                    );
                }

                const extraString = stringSections.length > 0 ? stringSections.join("&") : "";
                this.download(this.ExportType.Tasks, extraString);
            });
    }
}
