import { MoiCommentType } from "./moi-comment-type.enum";

export class MoiCommentModel {
    id: number;
    guid: string;
    createdAt: Date;
    createdBy: string;
    createdByRole: string;
    modifiedAt: Date;
    modifiedBy: string;
    commentType: MoiCommentType;

    comment: string;
    progress: number;
    feasibilityId: number;
    feasibility: string;

    isDeleted: boolean;
    canEdit: boolean;
    canDelete: boolean;
}
