import { TabModel } from '@models/generic/TabModels/TabModel';

export class TabComponentHelper {

    private static iconBackupLookup = { };

    static toggleTabDirty(tab: TabModel, menuItemId: string, dirty: boolean) {
        const menuItem = tab.menu.getTabMenuItemByMenuItemId(menuItemId);

        if (!menuItem) { return; }

        const uniqueKey = tab.identifier + "_" + menuItemId;

        if (!this.iconBackupLookup[uniqueKey]) {
            this.iconBackupLookup[uniqueKey] = menuItem.iconClass;
        }

        menuItem.iconClass = this.iconBackupLookup[uniqueKey] + (dirty ? " orange" : "");
    }
}