.login-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    z-index: 1000000;

    .centered-container {
        z-index: 1000001;
    }
}