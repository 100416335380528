import { Component, OnInit } from "@angular/core";
import { ControlInfoModel } from "@app/controls/models/ControlInfoModel";
import { CustomFieldHelper } from "@app/shared/helpers/customField-helper";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { FilterType } from "@enums/FilterType";
import { ModuleType } from "@enums/ModuleType";
import { StandingDataType } from "@enums/StandingDataType";
import { nameof } from "@methods/jeffs-toolkit";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { WorkspaceButtonConfig } from "@models/workspace/WorkspaceButtonConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";
import { ControlDataService } from "../services/control.data.service";
import { toPromise } from "@methods/CommonMethods";

@Component({
    selector: "app-control-overview",
    templateUrl: "./control-overview.component.html",
    styleUrls: ["./control-overview.component.scss"],
})
export class ControlOverviewComponent implements OnInit {
    constructor(
        private _controlDS: ControlDataService,
        private _tabs: TabService,
        public pages: Pages,
        private _permissions: PermissionsService,
        private standingDataService: StandingdataDataService,
    ) {}

    moduleType = ModuleType.Controls;
    tabID: string;
    tab: TabModel;
    idProp: string = nameof<ControlInfoModel>((x) => x.guid);
    data: Promise<ControlInfoModel[]>;
    headerLookup: { [propName: string]: string } = ControlOverviewComponent.getHeaderLookup();

    private static getHeaderLookup(): { [propName: string]: string } {
        let headerLookup = {};
        headerLookup[nameof<ControlInfoModel>((x) => x.identifier)] = "Identifier";
        headerLookup[nameof<ControlInfoModel>((x) => x.name)] = "Name";
        headerLookup[nameof<ControlInfoModel>((x) => x.isKeyControl)] = "Is Key Control";
        headerLookup[nameof<ControlInfoModel>((x) => x.designScore)] = "Score Design";
        headerLookup[nameof<ControlInfoModel>((x) => x.implementationScore)] =
            "Score Implementation";
        headerLookup[nameof<ControlInfoModel>((x) => x.diStatus)] = "Status D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.diScoreComment)] = "Score Comment D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.effectivenessScore)] = "Score Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.effectivenessConclusionComment)] =
            "Conclusion Comment Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.effectivenessStatus)] =
            "Status Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.finalConclusionScore)] =
            "Final Conclusion Score";
        headerLookup[nameof<ControlInfoModel>((x) => x.latestPeriodScore)] = "Latest Period Score";
        headerLookup[nameof<ControlInfoModel>((x) => x.controlObjective)] = "Control Objective";
        headerLookup[nameof<ControlInfoModel>((x) => x.organization)] = "Organization";
        headerLookup[nameof<ControlInfoModel>((x) => x.dateEffTested)] =
            "Date Tested Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.dateDiTested)] = "Date Tested D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.isEffOverdue)] = "Is Overdue Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.isDiOverdue)] = "Is Overdue D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.diEvidenceUploader)] =
            "Evidence Uploader D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.diTester)] = "Tester D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.effEvidenceUploader)] =
            "Evidence Uploader Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.effTester)] = "Tester Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.controlCatalogue)] = "Control Catalogue";
        headerLookup[nameof<ControlInfoModel>((x) => x.type)] = "Type";
        headerLookup[nameof<ControlInfoModel>((x) => x.frameworkDimensions)] =
            "Framework Dimensions";
        headerLookup[nameof<ControlInfoModel>((x) => x.businessDimensions)] = "Business Dimensions";
        headerLookup[nameof<ControlInfoModel>((x) => x.description)] = "Description";
        headerLookup[nameof<ControlInfoModel>((x) => x.controlExecutionMethod)] =
            "Control Execution Method";
        headerLookup[nameof<ControlInfoModel>((x) => x.source)] = "Source";
        headerLookup[nameof<ControlInfoModel>((x) => x.aspectIc)] = "Aspect IC";
        headerLookup[nameof<ControlInfoModel>((x) => x.effEvidenceUploader)] =
            "Evidence Uploader Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.owner)] = "Owner";
        headerLookup[nameof<ControlInfoModel>((x) => x.controlFrequency)] = "Control Frequency";
        headerLookup[nameof<ControlInfoModel>((x) => x.inPlace)] = "In Place";
        headerLookup[nameof<ControlInfoModel>((x) => x.requiresMonitoring)] = "Requires Monitoring";
        headerLookup[nameof<ControlInfoModel>((x) => x.isShared)] = "Is Shared";
        headerLookup[nameof<ControlInfoModel>((x) => x.startDate)] = "Start Date";
        headerLookup[nameof<ControlInfoModel>((x) => x.endDate)] = "End Date";
        headerLookup[nameof<ControlInfoModel>((x) => x.comments)] = "Comments";
        headerLookup[nameof<ControlInfoModel>((x) => x.dateCreated)] = "Date Created";
        headerLookup[nameof<ControlInfoModel>((x) => x.dateModified)] = "Date Modified";
        headerLookup[nameof<ControlInfoModel>((x) => x.lastModifier)] = "Last Modifier";
        headerLookup[nameof<ControlInfoModel>((x) => x.nextEffTestDate)] =
            "Next Test Date Effectiveness";
        headerLookup[nameof<ControlInfoModel>((x) => x.nextDiTestDate)] = "Next Test Date D&I";
        headerLookup[nameof<ControlInfoModel>((x) => x.requiresControlExecution)] = "Requires control execution";
        headerLookup[nameof<ControlInfoModel>((x) => x.lastControlExecutionDate)] = "Last control execution date";
        headerLookup[nameof<ControlInfoModel>((x) => x.nextControlExecutionDate)] = "Next control execution date";       
        headerLookup[nameof<ControlInfoModel>((x) => x.moiCount)] = "# of MoIs";
        headerLookup[nameof<ControlInfoModel>((x) => x.riskCount)] = "# of Risks";
        headerLookup[nameof<ControlInfoModel>((x) => x.linkedRiskNames)] = "Linked Risk Names";
        headerLookup[nameof<ControlInfoModel>((x) => x.organizationPath)] = "Organization Path";

        return headerLookup;
    }

    filterConfigs: FilterConfig[] = [
        {
            label: "Organizations",
            column: nameof<ControlInfoModel>((x) => x.organizationId),
            type: FilterType.Organization,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Business Dimensions",
            column: nameof<ControlInfoModel>((x) => x.businessDimensionIds),
            type: FilterType.BusinessDimensions,
            hideActionColumn: true,
            param: "",
        },
        {
            label: "Framework Dimensions",
            column: nameof<ControlInfoModel>((x) => x.frameworkDimensionIds),
            type: FilterType.FrameworkDimensions,
            hideActionColumn: true,
            param: "",
        },
    ];

    rendererConfig: RendererConfig[] = [
        RendererConfig.createScoreConfig(
            nameof<ControlInfoModel>((x) => x.designScore),
            nameof<ControlInfoModel>((x) => x.designScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<ControlInfoModel>((x) => x.implementationScore),
            nameof<ControlInfoModel>((x) => x.implementationScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<ControlInfoModel>((x) => x.effectivenessScore),
            nameof<ControlInfoModel>((x) => x.effectivenessScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<ControlInfoModel>((x) => x.finalConclusionScore),
            nameof<ControlInfoModel>((x) => x.finalConclusionScoreColor)
        ),
        RendererConfig.createScoreConfig(
            nameof<ControlInfoModel>((x) => x.latestPeriodScore),
            nameof<ControlInfoModel>((x) => x.latestPeriodScoreColor)
        ),
        RendererConfig.createShortTimeConfig(nameof<ControlInfoModel>((x) => x.dateCreated)),
        RendererConfig.createShortTimeConfig(nameof<ControlInfoModel>((x) => x.dateModified)),
        RendererConfig.createDateConfig(nameof<ControlInfoModel>((x) => x.dateEffTested)),
        RendererConfig.createOverdueConfig(nameof<ControlInfoModel>((x) => x.nextEffTestDate)),
        RendererConfig.createDateConfig(nameof<ControlInfoModel>((x) => x.dateDiTested)),
        RendererConfig.createOverdueConfig(nameof<ControlInfoModel>((x) => x.nextDiTestDate)),
        RendererConfig.createOverdueConfig(nameof<ControlInfoModel>((x) => x.nextControlExecutionDate)),
        RendererConfig.createDateConfig(nameof<ControlInfoModel>((x) => x.lastControlExecutionDate)),
        RendererConfig.createDateConfig(nameof<ControlInfoModel>((x) => x.startDate)),
        RendererConfig.createDateConfig(nameof<ControlInfoModel>((x) => x.endDate)),
    ];

    buttonConfig: WorkspaceButtonConfig[] = [
        {
            text: "Shared Controls",
            icon: "far fa-share-alt",
            getVisibleRange: false,
            clickEvent: () =>
                this._tabs.generateTab(this.pages.SharedControlOverview, null, "Shared Control"),
            isDisabled: !this._permissions.permissions.HasSharedControlAccess,
        },
        {
            text: "Export Test Plan",
            icon: "fas fa-file-archive",
            getVisibleRange: true,
            clickEvent: (data) =>
                this._tabs.generateTab(
                    this.pages.ExportEffectiveness,
                    null,
                    "Export Test Plan",
                    data
                ),
            isDisabled: !this._permissions.permissions.HasEffectivenessExportAccess,
        },
        {
            text: "Copy Test Plan",
            icon: "fas fa-copy",
            getVisibleRange: true,
            clickEvent: (data) =>
                this._tabs.generateTab(this.pages.CopyEffectiveness, null, "Copy Test Plan", data),
            isDisabled: !this._permissions.permissions.HasEffectivenessCopyAccess,
        },
    ];

    numberProps: string[] = [
        nameof<ControlInfoModel>((x) => x.riskCount),
    ];
    dateProps: string[] = [
        nameof<ControlInfoModel>((x) => x.dateCreated),
        nameof<ControlInfoModel>((x) => x.dateModified),
        nameof<ControlInfoModel>((x) => x.dateEffTested),
        nameof<ControlInfoModel>((x) => x.nextEffTestDate),
        nameof<ControlInfoModel>((x) => x.dateDiTested),
        nameof<ControlInfoModel>((x) => x.nextDiTestDate),
        nameof<ControlInfoModel>((x) => x.startDate),
        nameof<ControlInfoModel>((x) => x.endDate),
    ];

    permissions: WorkspaceModulePermissionModel;
    
    ngOnInit(): void {
        this.permissions = this._permissions.permissions.Workspace.control;
        this.data = this.getFormattedData();
    }

    async getFormattedData() {
        const data = await toPromise(this._controlDS.getList());

        await this.expandStandingDataCustomFields(data);

        return this.mapHeaders(data);
    }

    private mapHeaders = (rows) => {
        if (rows && rows.length) {
            Object.getOwnPropertyNames(rows[0]).forEach((propName) => {
                if (!this.headerLookup[propName]) {
                    this.headerLookup[propName] = propName;
                }
            });
        }
        return rows;
    };

    private async expandStandingDataCustomFields(data: Object[]) {
        const expandableFields = (
            await this.standingDataService
                .getAllByType(StandingDataType.ControlCustomFields)
                .toPromise()
        ).filter((x) => x.visible);

        CustomFieldHelper.expandCustomFields(
            data,
            "customFields",
            expandableFields,
            this.headerLookup,
            this.rendererConfig
        );
    }

    open(control: ControlInfoModel): void {
        this._tabs.generateTab(
            this.pages.ControlDetail,
            control.guid,
            `(C) ${+control.identifier} - ${control.name}`
        );
    }

    add(): void {
        this._tabs.generateTab(this.pages.ControlDetail, null, "Add new Control");
    }
}
