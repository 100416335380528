<ng-container *ngIf="model">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Basic details</div>
        <div class="detail-page-card-body padded form-group-styling">
            <div class="form-group">
                <label>Name *</label>
                <cerrix-textfield placeholder="Name" [(ngModel)]="model.name" [disabled]="readonly">
                </cerrix-textfield>
            </div>
            <div class="form-group">
                <label>Expiration Date *</label>
                <date-input
                    [(value)]="model.expirationDate"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [disabled]="readonly"
                ></date-input>
            </div>
        </div>
    </div>

    <generic-list-manager
        data-uiid="api-soft-ip-requested"
        *ngIf="softIpRequestedConfig"
        [config]="softIpRequestedConfig"
    ></generic-list-manager>

    <generic-list-manager
        data-uiid="api-soft-ip-allowed"
        *ngIf="softIpAllowedConfig"
        [config]="softIpAllowedConfig"
    ></generic-list-manager>

    <generic-list-manager
        data-uiid="api-soft-ip-denied"
        *ngIf="softIpDeniedConfig"
        [config]="softIpDeniedConfig"
    ></generic-list-manager>

    <div class="detail-page-card" *ngFor="let module of model.keysByModule">
        <div class="detail-page-card-title">{{ module.name }}</div>
        <div class="detail-page-card-body padded form-group-styling">
            <ng-container *ngFor="let group of module.groups; let i = index">
                <hr *ngIf="i > 0" />
                <div class="group-info" *ngIf="group.name">
                    <label>{{ group.name }}</label>
                    <small class="text-muted">{{ group.description }}</small>
                </div>
                <div class="keys">
                    <div class="key-option" *ngFor="let access of group.keys">
                        <span>
                            <cerrix-checkbox
                                [(value)]="access.selected"
                                [label]="access.name"
                                [disabled]="readonly || access.readOnly"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                            <i
                                class="fas fa-question-circle ms-2"
                                (click)="openManual(access, module.moduleKey)"
                            ></i>
                        </span>
                        <small class="text-muted text-truncate" [title]="access.description">{{
                            access.description
                        }}</small>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
