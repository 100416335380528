import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "@app/app.constants";
import { TestPeriodModel } from "../shared/test-period.model";
import { TestSamplesModel } from "../../shared/models/testsamples-model";
import { Observable } from "rxjs";
import { WorkflowConclusionModel } from "../../shared/models";

@Injectable()
export class TestPeriodDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    getTestPeriodData(controlGuid: string): Observable<TestPeriodModel[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/periods`;
        return this.get<TestPeriodModel[]>(url);
    }

    getNumberOfTestSamples(controlGuid: string): Observable<TestSamplesModel> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/periods/samples/count`;
        return this.get<TestSamplesModel>(url);
    }

    store(controlGuid: string, model: TestPeriodModel): Observable<void> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/periods/store`;
        return this.post<void>(model, url);
    }

    deletePeriod(controlGuid: string, periodID: number): Observable<void> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/periods/${periodID}`;
        return this.delete(url);
    }

    postTestPeriodScore(
        controlGuid: string,
        periodID: number,
        model: WorkflowConclusionModel
    ): Observable<void> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/periods/${periodID}`;

        return this.post<void>(model, url);
    }
}
