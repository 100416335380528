import { Component, OnInit } from "@angular/core";
import { TpaConnectionDataService } from "@app/external-connections/services/tpa-connection.data.service";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { nameof } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { FilterConfig } from "@models/workspace/FilterConfig";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { TabService } from "@services/tabs/TabService";
import { TpaRequestModel } from "../models/tpaRequestModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";

@Component({
    selector: "app-tpa-request-overview",
    templateUrl: "./tpa-request-overview.component.html",
    styleUrls: ["./tpa-request-overview.component.scss"],
})
export class TpaRequestOverviewComponent implements OnInit {
    moduleType: ModuleType = ModuleType.EC;
    tabID: string;
    tab: TabModel;
    data: Promise<TpaRequestModel[]>;
    headerLookup: any = {};
    filterConfigs: FilterConfig[] = [];
    rendererConfig: RendererConfig[] = [];

    dateProps: string[] = ["startDate", "endDate", "requestDate"];

    permissions: WorkspaceModulePermissionModel = {
        canAdd: false,
        canOpen: true,

        isModuleAdmin: false,
        canPublish: false,
        canSetSystemDefault: false,
    };

    constructor(
        private _tabs: TabService,
        public pages: Pages,
        private _ds: TpaConnectionDataService
    ) {}

    ngOnInit(): void {
        this.setRendererConfig();
        this.mapHeaders();
        this.data = this._ds.getRequests().toPromise();
    }

    setRendererConfig(): void {
        ["startDate", "endDate"].forEach((dateProp) => {
            const config = <RendererConfig>{
                textColumn: dateProp,
                type: RendererType.Default,
                formatType: FormatType.DateFormat,
            };

            this.rendererConfig.push(config);
        });

        const requestDateConfig = <RendererConfig>{
            textColumn: "requestDate",
            type: RendererType.Default,
            formatType: FormatType.DateTimeFormat,
        };

        this.rendererConfig.push(requestDateConfig);
    }

    openDetail(request: TpaRequestModel): void {
        this.openTab(request.requestID, request.connectionName);
    }

    private openTab(guid: string, name: string): void {
        this._tabs.generateTab(this.pages.TpaRequestDetail, guid, name);
    }

    private mapHeaders(): void {
        const headerLookup = {};
        headerLookup[nameof<TpaRequestModel>("requestID")] = "Request ID";
        headerLookup[nameof<TpaRequestModel>("requestDate")] = "Request date";
        headerLookup[nameof<TpaRequestModel>("connectionName")] = "Connection name";
        headerLookup[nameof<TpaRequestModel>("startDate")] = "Start date";
        headerLookup[nameof<TpaRequestModel>("endDate")] = "End date";
        headerLookup[nameof<TpaRequestModel>("requestedTestType")] = "Requested test type";
        headerLookup[nameof<TpaRequestModel>("id")] = "id";
        headerLookup[nameof<TpaRequestModel>("status")] = "Status";
        this.headerLookup = headerLookup;
    }
}
