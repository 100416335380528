<div
    class="d-flex flex-column main-container"
    [ngClass]="{ 'display-error': hasErrors(), 'disabled-input': disabled }"
>
    <div class="label bold-text">{{ label }}</div>
    <div class="d-flex flex-column">
        <input
            class="regular-text-small"
            matInput
            (keyup)="onModelChanged()"
            [type]="type"
            [disabled]="disabled"
            [formControl]="formController"
        />
        <div class="regular-text-small form-control-error">{{ formController?.errors?.error }}</div>
    </div>
</div>
