<div class="fancy-loader" *ngIf="!pageReady"></div>

<div class="detail-page-action-bar" *ngIf="pageReady">
    <button class="btn btn-raised" (click)="save()">
        <i class="fas fa-save" aria-hidden="true"></i>
        <span class="d-none d-lg-inline ms-2">Save</span>
    </button>
    <button class="btn btn-raised red" *ngIf="id > 0" (click)="deleteClick()">
        <i class="fas fa-trash"></i>
        <span>Delete</span>
    </button>
</div>

<div class="detail-page-card" [formGroup]="form" *ngIf="pageReady">
    <div class="detail-page-card-title">Edit periodic</div>
    <div class="detail-page-card-body padded form-group-styling invite-compose-area">
        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Name"
                formControlName="Name"
                name="notification-name"
                [required]="true"
                placeholder="Required"
            ></cerrix-textfield>
        </div>
        <div class="form-group">
            <cerrix-checkbox
                fontsize="0.9em"
                label="Active"
                formControlName="Active"
            ></cerrix-checkbox>
        </div>
        <div class="form-group">
            <label>Period</label>
            <ng-select
                id="periodSelect"
                name="periodSelect"
                [items]="periods"
                bindLabel="Name"
                bindValue="ID"
                [multiple]="false"
                formControlName="Period"
                placeholder="Required"
                (change)="setDayNumbers($event)"
            ></ng-select>
        </div>
        <div class="form-group">
            <label
                >Day
                <i
                    class="fas fa-question-circle"
                    matTooltip="Which day in period to send. Weekly 1 will send on Monday. Quarterly 1 on January 1st"
                    matTooltipClass="breakable-tooltip"
                ></i>
            </label>
            <ng-select
                id="dayNumberSelect"
                name="dayNumberSelect"
                [items]="dayNumbers"
                [readonly]="!notification.Period"
                bindLabel="Name"
                bindValue="ID"
                [multiple]="false"
                formControlName="DayNumber"
                placeholder="Required"
            ></ng-select>
        </div>
        <div class="form-group">
            <label>
                Organization
                <i
                    class="fas fa-question-circle"
                    matTooltip="Leave selection empty to apply to all organizations."
                ></i>
            </label>
            <cerrix-select-tree
                class="fullWidth"
                [getDataMethod]="organizationRequest"
                formControlName="Organizations"
                [multiselect]="true"
            ></cerrix-select-tree>
        </div>
        <div class="form-group">
            <label
                >MoI Type
                <i
                    class="fas fa-question-circle"
                    matTooltip="Leave selection empty to apply to all MoI types."
                ></i>
            </label>
            <ng-select
                id="moiTypeSelect"
                name="moiTypeSelect"
                [multiple]="true"
                [closeOnSelect]="false"
                [items]="moiTypes"
                bindLabel="Name"
                bindValue="ID"
                formControlName="MoiTypeIds"
                placeholder="All"
            ></ng-select>
        </div>
        <div class="form-group">
            <label
                >User type
                <i
                    class="fas fa-question-circle"
                    matTooltip="Leave selection empty to apply to everyone."
                ></i>
            </label>
            <ng-select
                id="userTypeSelect"
                name="userTypeSelect"
                [multiple]="true"
                [closeOnSelect]="false"
                [items]="userTypes"
                bindLabel="Name"
                bindValue="ID"
                formControlName="UserTypeIds"
                placeholder="All"
            >
            </ng-select>
        </div>
        <div class="form-group">
            <label>
                Priority
                <i
                    class="fas fa-question-circle"
                    matTooltip="Leave selection empty to apply to all priorities."
                ></i>
            </label>
            <ng-select
                id="prioritySelect"
                name="prioritySelect"
                [items]="priorities"
                bindLabel="Name"
                [closeOnSelect]="false"
                bindValue="ID"
                [multiple]="true"
                formControlName="PriorityIds"
                placeholder="Any"
            ></ng-select>
        </div>

        <div class="form-group">
            <cerrix-textfield
                fieldLabel="Message"
                formControlName="Subject"
                name="notification-subject"
                [required]="true"
                placeholder="Required"
                (click)="setFocusToSubject()"
            ></cerrix-textfield>
            <button
                class="keyword-button"
                *ngFor="let keyword of keywords"
                (click)="addKeyword(keyword)"
            >
                {{ keyword }}
            </button>
            <cerrix-editor
                [class.cerrixEditor]="isEmpty"
                [value]="notification.Message"
                (valueChange)="onChange($event)"
                (click)="deFocusSubject()"
                #editor
            ></cerrix-editor>
        </div>
    </div>
</div>
