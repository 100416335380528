export class RegexConstants {
    // Youtube (https://stackoverflow.com/questions/3717115/regular-expression-for-youtube-links)
    public static youtubeRegex =
        /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+)/gm;
    public static youtubeRegexIdCaptureGroup = 1;

    // Vimeo (https://stackoverflow.com/questions/5008609/vimeo-video-link-regex)
    public static vimeoRegex =
        /(?:https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/g;
    public static vimeoRegexIdCaptureGroup = 4;
}
