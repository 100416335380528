<div class="detail-page-card">
    <span [ngClass]="{ 'fancy-loader': isLoadingDocuments }"> </span>

    <document-manager-old
        data-uiid="business-assessment-documentmanager"
        [(Documents)]="docModel.documents"
        (DocumentsChange)="checkDirty()"
        [permissions]="permissions.documentPermissions"
        [DocumentTypeTargets]="documentTypeTargets"
        Tooltip="Assessment documents are specific for the organization-business dimension combination. The documents stored in this assessment documents section are only visible in this specific assessment."
        Label="Assessment Documents"
    ></document-manager-old>
</div>
