<div class="modal-header">
    <h5 class="modal-title">Adding third parties</h5>
</div>
<div class="modal-body">
    <div class="content" *ngIf="entities">
        <div class="form-group cerrix-control-sm">
            <ng-select
                [items]="entities"
                bindLabel="name"
                bindValue="id"
                [isOpen]="true"
                [multiple]="true"
                (change)="changed($event)"
            >
                <ng-template ng-option-tmp let-entity="item" let-search="searchTerm">
                    <div class="card" (click)="entity.selected = !entity.selected">
                        <div class="card-body" [class.selected]="entity.selected">
                            <div *ngIf="entity.selected">{{ entity.name }}</div>
                            <div *ngIf="!entity.selected">
                                <p class="card-title">
                                    {{ entity.name }}
                                </p>
                                <p class="card-text">
                                    Outside GDPR jurisdiction: {{ entity.outsideEu ? "Yes" : "No" }}
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="okClick()">Ok</button>
    <button type="button" class="btn btn-secondary" (click)="closeClick()">Close</button>
</div>
