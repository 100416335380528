import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ApiDataService } from "@services/http/ApiDataService";
import { Observable, map } from "rxjs";
import { TestPeriodModel } from "./test-period.model";
import { EffectivenessControlOverviewModel } from "./effectiveness-control-overview.model";
import { EffectivenessWizardModel } from "../../shared/models/effectiveness-wizard-model";
import { isGuid } from "@methods/uniqueMethods";
import { IdNameColorModel } from "@models/generic/IdNameColorModel";

@Injectable()
export class OverviewDataService extends ApiDataService {
    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls");
    }

    storeEffectiveness(
        controlGuid: string,
        effectivenessGuid: string,
        effectivenessWizardModel: EffectivenessWizardModel
    ): Observable<string> {
        if (!isGuid(controlGuid)) {
            return;
        }
        if (!effectivenessGuid) {
            effectivenessGuid = null;
        }
        return this.post<string>(
            effectivenessWizardModel,
            `/${controlGuid}/testing/advanced/effectiveness/${effectivenessGuid}`
        );
    }

    deleteEffectiveness(effectivenessGuid: string): Observable<void> {
        return this.delete(`/testing/advanced/effectiveness/${effectivenessGuid}`);
    }

    canEditEffectiveness(controlGuid: string, effectivenessGuid: string): Observable<boolean> {
        if (!isGuid(controlGuid)) {
            return;
        }
        if (!effectivenessGuid) {
            effectivenessGuid = null;
        }
        return this.get<boolean>(
            `/${controlGuid}/testing/advanced/effectiveness/${effectivenessGuid}/canedit`
        );
    }

    getControlEffectivenesses(
        controlGuid: string,
        scores: IdNameColorModel[]
    ): Observable<EffectivenessControlOverviewModel[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness`;
        return this.get<EffectivenessControlOverviewModel[]>(url).pipe(
            map((effectivenessControls) => {
                const items = [];
                for (const eff of effectivenessControls) {
                    const groupScore = scores.find((s) => s.ID === eff.groupScore);
                    items.push({
                        ...eff,
                        groupScoreText: groupScore?.Name,
                        groupScoreColor: groupScore?.Color,
                    });
                }

                return items;
            })
        );
    }

    getEffectivenessNames(controlGuid: string): Observable<IdNameCombination[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/lookup`;
        return this.get<IdNameCombination[]>(url);
    }

    getEffectiveness(effectivenessGuid: string): Observable<EffectivenessWizardModel> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/wizard`;
        return this.get<EffectivenessWizardModel>(url);
    }

    getEffectivenessAsCopy(effectivenessGuid: string): Observable<EffectivenessWizardModel> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/loadAsCopy`;
        return this.get<EffectivenessWizardModel>(url);
    }

    getCatalogues(controlGuid: string): Observable<IdNameCombination[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/catalogues`;
        return this.get<IdNameCombination[]>(url);
    }

    getCatalogue(catalogueGuid: string): Observable<EffectivenessWizardModel> {
        const url = `/testing/advanced/effectiveness/catalogue/${catalogueGuid}`;
        return this.get<EffectivenessWizardModel>(url);
    }

    getTestPeriodData(controlGuid: string): Observable<TestPeriodModel[]> {
        const url = `/${controlGuid}/testing/advanced/effectiveness/testperioddata`;
        return this.get<TestPeriodModel[]>(url);
    }

    getSampleGenMethData(): Observable<IdNameCombination[]> {
        const url = `/testing/advanced/effectiveness/samplegenmethdata`;
        return this.get<IdNameCombination[]>(url);
    }

    getProductiveUsers(string, effectivenessGuid: string): Observable<number[]> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/productiveusers`;
        return this.get<number[]>(url);
    }

    getEvidenceUploaderData(effectivenessGuid: string): Observable<IdNameCombination[]> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/evidenceuploaderdata`;
        return this.get<IdNameCombination[]>(url);
    }

    getTesterData(effectivenessGuid: string): Observable<IdNameCombination[]> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/testerdata`;
        return this.get<IdNameCombination[]>(url);
    }

    getReviewerData(effectivenessGuid: string): Observable<IdNameCombination[]> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/reviewerdata`;
        return this.get<IdNameCombination[]>(url);
    }

    getLinkedStandardData(effectivenessGuid: string): Observable<IdNameCombination[]> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/linkedstandarddata`;
        return this.get<IdNameCombination[]>(url);
    }

    getQualityAspectData(effectivenessGuid: string): Observable<IdNameCombination[]> {
        const url = `/testing/advanced/effectiveness/${effectivenessGuid}/qualityaspectdata`;
        return this.get<IdNameCombination[]>(url);
    }
}
