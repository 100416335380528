import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[cerrixFileDrop]",
    standalone: true,
})
export class CerrixFileDropDirective {
    @HostBinding("class.fileover") fileOver: boolean;
    @Output() fileDropped = new EventEmitter<any>();

    @HostListener("dragover", ["$event"]) onDragOver(evt) {
        evt.dataTransfer.dropEffect = "copy";
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    @HostListener("drop", ["$event"]) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        this.fileOver = false;
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
