import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { BusinessNavigatorOverviewModel } from "@app/navigators/business-navigator/models/business-navigator-overview-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { Pages } from "@constants/pages/Pages";
import { ComparisonType } from "@enums/workspace/ComparisonType";
import { nameof } from "@methods/jeffs-toolkit";
import { SearchKeyword } from "@models/workspace/SearchKeyword";
import { TabService } from "@services/tabs/TabService";
import { WidgetFilterKey } from "./../../../shared/models/WidgetFilterKey";
import { BaseHighchartWidget } from "./../BaseHighchartWidget";

@Component({
    selector: "business-assessment-widget",
    templateUrl: "../highchart-widget.component.html",
    styleUrls: ["../highchart-widget.component.scss"],
})
export class BusinessAssessmentWidgetComponent extends BaseHighchartWidget {
    @ViewChild("chartContainer") chartContainer: ElementRef;

    constructor(
        private businessDataService: BusinessNavigatorDataService,
        private pages: Pages,
        sd: StandingdataDataService,
        tabService: TabService
    ) {
        super(tabService, sd);

        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.Organization] =
            "organizationID";
        this.globalFilterWorkspaceColumns[WidgetFilterKey.GlobalFilter.BusinessDimension] =
            "businessID";
    }

    protected async getChartConfig() {
        this.config.noData = false;
        this.config.noDataExplanation = "";

        const filters = this.getFilters();
        const data = await this.businessDataService
            .getWidgetInfo(filters)
            .toPromise()
            .catch((x: HttpErrorResponse) => {
                this.config.noData = true;
                if (
                    x.statusText == "Not Found" &&
                    filters[this.filterKeys.GlobalFilter.WorkspacePreset]
                ) {
                    this.config.noDataExplanation =
                        "Workspace preset not accessible. Please verify the workspace preset is set to public.";
                }
            });

        this.config.noData = !data || data.length === 0;
        if (this.config.noData) {
            return null;
        }

        const mappedData = [
            <any>{
                name: "Business Assessments",
                data,
            },
        ];

        const config = this.getBaseChartConfig(null, mappedData);

        config.tooltip = {
            headerFormat: "<b>{point.key}:</b> {point.y}",
            pointFormat: "",
        };

        config.chart.spacingLeft = 0;
        config.chart.spacingRight = 0;

        config.plotOptions.pie.size = "65%";
        config.plotOptions.pie.innerSize = "50%";

        return config;
    }

    protected async openWorkspace(ev) {
        const searchKeywords: SearchKeyword[] = [
            this.getSearchKeyword(
                nameof<BusinessNavigatorOverviewModel>((x) => x.score),
                ev.point.name,
                ComparisonType["Is equal to"]
            ),
        ];

        const preset = await this.getBasePreset(searchKeywords);
        this.openPreset(this.pages.BusinessNavigator, preset);
    }

    protected async onGlobalOpenClick() {
        const preset = await this.getBasePreset();
        this.openPreset(this.pages.BusinessNavigator, preset);
    }
}
