<div class="detail-page-card">
    <div class="detail-page-card-title">{{ Label }}</div>
    <div class="detail-page-card-actions">
        <button class="btn btn-raised" (click)="openControl()" *ngIf="!HideOpenControlButton">
            <i class="fas fa-external-link-alt"></i>
            <span>Open control</span>
        </button>
    </div>

    <div class="detail-page-card-body padded form-group-styling cerrix-control-sm">
        <div class="fancy-loader submodule" *ngIf="!overviewModel"></div>
        <div *ngIf="overviewModel">
            <div class="row" *ngIf="!HideControlDetails">
                <div class="col left-block">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group">
                                <label>Identifier</label>
                                <input
                                    data-uiid="control-identifier"
                                    type="text"
                                    class="form-control form-control-sm"
                                    disabled
                                    [value]="overviewModel.ControlIdentifier"
                                    inputTooltip
                                />
                            </div>
                        </div>

                        <div class="col-9">
                            <div class="form-group">
                                <label>Control name</label>
                                <input
                                    data-uiid="control-name"
                                    type="text"
                                    class="form-control form-control-sm"
                                    disabled
                                    [value]="overviewModel.ControlName"
                                    inputTooltip
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Description</label>
                        <textarea
                            data-uiid="control-description"
                            class="form-control form-control-sm"
                            disabled
                            [value]="overviewModel.ControlDescription"
                            inputTooltip
                        ></textarea>
                    </div>
                </div>

                <div class="col right-block">
                    <div class="form-group">
                        <label>Control frequency</label>
                        <input
                            data-uiid="control-frequency"
                            type="text"
                            class="form-control form-control-sm"
                            disabled
                            [value]="overviewModel.ControlFrequency"
                            inputTooltip
                        />
                    </div>

                    <div class="form-group">
                        <label>Mitigated risks</label>
                        <textarea
                            data-uiid="control-mitigatedrisks"
                            type="date"
                            class="form-control form-control-sm"
                            disabled
                            [value]="overviewModel.ControlMitigatedRisks"
                            inputTooltip
                        ></textarea>
                    </div>
                </div>
            </div>
            <div
                class="form-group-separator"
                *ngIf="!HideControlDetails && (!HideDesignScore || !HideEffectivenessScore)"
            ></div>

            <div class="row" *ngIf="!HideDesignScore || !HideEffectivenessScore">
                <div
                    class="col"
                    [class.left-block]="!HideEffectivenessScore"
                    *ngIf="!HideDesignScore"
                >
                    <div class="row">
                        <div class="col-6" [class.left-block]="HideEffectivenessScore">
                            <div class="form-group">
                                <label>Design score</label>
                                <ng-select
                                    data-uiid="control-designscore"
                                    [items]="[
                                        {
                                            Id: 1,
                                            Name: overviewModel.DesignScore,
                                            Color: overviewModel.DesignScoreColor
                                        }
                                    ]"
                                    [ngModel]="1"
                                    bindLabel="Name"
                                    bindValue="Id"
                                    disabled
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <div
                                            [style.background-color]="item.Color"
                                            class="color-indicator"
                                        ></div>
                                        {{ item.Name }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-6" [class.right-block]="HideEffectivenessScore">
                            <div class="form-group">
                                <label>Implementation score</label>
                                <ng-select
                                    data-uiid="control-implementationscore"
                                    [items]="[
                                        {
                                            Id: 1,
                                            Name: overviewModel.ImplementationScore,
                                            Color: overviewModel.ImplementationScoreColor
                                        }
                                    ]"
                                    [ngModel]="1"
                                    bindLabel="Name"
                                    bindValue="Id"
                                    disabled
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <div
                                            [style.background-color]="item.Color"
                                            class="color-indicator"
                                        ></div>
                                        {{ item.Name }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6" [class.left-block]="HideEffectivenessScore">
                            <div class="form-group">
                                <label>Date tested (D&I)</label>
                                <date-input
                                    data-uiid="control-datetesteddi"
                                    [(value)]="overviewModel.DIDateTested"
                                    [disabled]="true"
                                ></date-input>
                            </div>
                        </div>

                        <div class="col-6" [class.right-block]="HideEffectivenessScore">
                            <div class="form-group">
                                <label>Next test date (D&I)</label>
                                <date-input
                                    data-uiid="control-nexttestdatedi"
                                    [(value)]="overviewModel.DINextDateTested"
                                    [disabled]="true"
                                >
                                </date-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="col"
                    [class.right-block]="!HideDesignScore"
                    *ngIf="!HideEffectivenessScore"
                >
                    <div class="row">
                        <div class="col-6" [class.left-block]="HideDesignScore">
                            <div class="form-group">
                                <label>Effectiveness score</label>
                                <ng-select
                                    data-uiid="control-effectivenessscore"
                                    [items]="[
                                        {
                                            Id: 1,
                                            Name: overviewModel.EffectivenessScore,
                                            Color: overviewModel.EffectivenessScoreColor
                                        }
                                    ]"
                                    [ngModel]="1"
                                    bindLabel="Name"
                                    bindValue="Id"
                                    disabled
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <div
                                            [style.background-color]="item.Color"
                                            class="color-indicator"
                                        ></div>
                                        {{ item.Name }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-6" [class.right-block]="HideDesignScore">
                            <div class="form-group">
                                <label>Conclusion score</label>
                                <ng-select
                                    data-uiid="control-conclusionscore"
                                    [items]="[
                                        {
                                            Id: 1,
                                            Name: overviewModel.ConclusionScore,
                                            Color: overviewModel.ConclusionScoreColor
                                        }
                                    ]"
                                    [ngModel]="1"
                                    bindLabel="Name"
                                    bindValue="Id"
                                    disabled
                                >
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item">
                                        <div
                                            [style.background-color]="item.Color"
                                            class="color-indicator"
                                        ></div>
                                        {{ item.Name }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6" [class.left-block]="HideDesignScore">
                            <div class="form-group">
                                <label>Date tested (effectiveness)</label>
                                <date-input
                                    data-uiid="control-datetestedeffectiveness"
                                    [(value)]="overviewModel.EffectivenessDateTested"
                                    [disabled]="true"
                                >
                                </date-input>
                            </div>
                        </div>

                        <div class="col-6" [class.right-block]="HideDesignScore">
                            <div class="form-group">
                                <label>Next test date (effectiveness)</label>
                                <date-input
                                    data-uiid="control-nexttestdateeffectiveness"
                                    [(value)]="overviewModel.EffectivenessNextDateTested"
                                    [disabled]="true"
                                >
                                </date-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
