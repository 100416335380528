<div class="detail-page-summary" *ngIf="processingPurpose && processingPurpose.ID > 0">
    <div class="row">
        <div class="col-6">
            <div class="property"><b>ID:</b> {{ processingPurpose.ID }}</div>
            <div class="property">
                <b>Name:</b>&nbsp;<span data-uiid="dm-summary-name">{{
                    processingPurpose.name
                }}</span>
            </div>
        </div>
        <div class="col-6">
            <div class="property">
                <b>Data subject:</b> {{ processingPurpose.dataSubject || "None" }}
            </div>
            <div class="property">
                <b>Most recent DPIA:</b> {{ processingPurpose.dpiaDate | dateFormat }}
            </div>
        </div>
    </div>
</div>

<form [formGroup]="parentForm" *ngIf="processingPurpose">
    <div class="detail-page-card">
        <div class="detail-page-card-title">Processing details</div>
        <div class="detail-page-card-body padded cerrix-control-sm">
            <div class="row">
                <div class="col-12 col-xl-6 left-block">
                    <div class="row">
                        <div class="form-group col">
                            <cerrix-textfield
                                fieldLabel="Processing name"
                                dataUiid="dmdetailmain-processingname"
                                identifier="name"
                                formControlName="name"
                                placeholder="Required"
                            ></cerrix-textfield>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-lg-6">
                            <cerrix-textarea
                                fieldLabel="Description"
                                dataUiid="dmdetailmain-description"
                                identifier="description"
                                formControlName="description"
                            ></cerrix-textarea>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <cerrix-textarea
                                fieldLabel="Purpose"
                                dataUiid="dmdetailmain-purpose"
                                identifier="purpose"
                                formControlName="purpose"
                            ></cerrix-textarea>
                        </div>
                    </div>

                    <div class="form-group">
                        <cerrix-textarea
                            fieldLabel="Security measures"
                            dataUiid="dmdetailmain-securitymeasures"
                            identifier="securityMeasures"
                            formControlName="securityMeasures"
                        ></cerrix-textarea>
                    </div>

                    <div class="form-group">
                        <label for="basisId">Basis</label>
                        <cerrix-select
                            data-uiid="dmdetailmain-basis"
                            id="basisId"
                            formControlName="basisId"
                            prettyName="basis"
                            bindLabel="Name"
                            bindValue="ID"
                            [getDataMethod]="basisRequest"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <cerrix-textarea
                            fieldLabel="Explanation for the basis"
                            dataUiid="dmdetailmain-explanation"
                            identifier="explanationBasis"
                            formControlName="explanationBasis"
                        ></cerrix-textarea>
                    </div>
                </div>

                <div class="col-12 col-xl-6 right-block">
                    <hr class="d-xl-none" />

                    <div class="form-group">
                        <label>Organization</label>
                        <cerrix-select-tree
                            data-uiid="dmdetailmain-organization"
                            [getDataMethod]="organizationRequest"
                            formControlName="organizationId"
                            placeholder="Required"
                            [multiselect]="false"
                            (onChangeSingleId)="organizationChanged($event)"
                        >
                        </cerrix-select-tree>
                    </div>

                    <div class="form-group">
                        <label
                            >Business dimensions
                            <i
                                *ngIf="!selectedOrganizationId"
                                class="far fa-question-circle ms-2"
                                [matTooltip]="'Please select an organization first.'"
                            ></i>
                            <i
                                *ngIf="selectedOrganizationId && !businessDimensionTreeItems"
                                class="fas fa-circle-notch fa-spin me-2"
                            ></i>
                        </label>
                        <cerrix-select-tree
                            data-uiid="dmdetailmain-businessdimensions"
                            [(tree)]="businessDimensionTreeItems"
                            formControlName="businessDimensionIds"
                            [multiselect]="true"
                            [readonly]="!selectedOrganizationId"
                            (onChangeIds)="businessDimensionsChanged($event)"
                        >
                        </cerrix-select-tree>
                    </div>

                    <div class="form-group">
                        <label
                            >Framework dimensions
                            <i
                                *ngIf="!selectedOrganizationId"
                                class="far fa-question-circle ms-2"
                                [matTooltip]="'Please select an organization first.'"
                            ></i>
                            <i
                                *ngIf="selectedOrganizationId && !frameworkDimensionTreeItems"
                                class="fas fa-circle-notch fa-spin me-2"
                            ></i>
                        </label>
                        <cerrix-select-tree
                            data-uiid="dmdetailmain-frameworkdimensions"
                            [(tree)]="frameworkDimensionTreeItems"
                            formControlName="frameworkDimensionIds"
                            [multiselect]="true"
                            [readonly]="!selectedOrganizationId"
                        >
                        </cerrix-select-tree>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="removalIds">Removal</label>
                                <cerrix-select
                                    data-uiid="dmdetailmain-removal"
                                    id="removalIds"
                                    formControlName="removalIds"
                                    prettyName="removal types"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    [getDataMethod]="removalRequest"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="sensitivityIds">Sensitivity of processing</label>
                                <cerrix-select
                                    data-uiid="dmdetailmain-sensitivity"
                                    id="sensitivityIds"
                                    formControlName="sensitivityIds"
                                    prettyName="sensitivity of processing"
                                    bindLabel="Name"
                                    bindValue="ID"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    [getDataMethod]="sensitivityRequest"
                                >
                                </cerrix-select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <cerrix-textfield
                            fieldLabel="General archive policy"
                            dataUiid="dmdetailmain-generalarchivepolicy"
                            identifier="generalArchivePolicy"
                            formControlName="generalArchivePolicy"
                            placeholder="Required"
                        ></cerrix-textfield>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <cerrix-checkbox
                                data-uiid="dmdetailmain-profiling"
                                label="Profiling"
                                formControlName="profiling"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                        </div>
                        <div class="col-12 col-lg-6">
                            <cerrix-checkbox
                                data-uiid="dmdetailmain-dataanalytics"
                                label="Data analytics"
                                formControlName="dataAnalytics"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-xl-6">
            <generic-list-manager *ngIf="recipientConfig" [config]="recipientConfig" idProp="id">
            </generic-list-manager>
        </div>

        <div class="col-12 col-xl-6">
            <generic-list-manager
                *ngIf="sourceDeliveryConfig"
                [config]="sourceDeliveryConfig"
                idProp="id"
            >
            </generic-list-manager>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-xl-6">
            <div class="detail-page-card">
                <div class="detail-page-card-title">
                    Stakeholders
                    <i
                        class="far fa-exclamation-circle ms-2"
                        [matTooltip]="
                            'The data from data subject(s), data controller(s), data (sub)processor(s), is inherited from manage data structure screen'
                        "
                    ></i>
                </div>
                <div class="detail-page-card-body padded cerrix-control-sm">
                    <div class="form-group">
                        <label>Data subject(s)</label>
                        <cerrix-select
                            data-uiid="dmdetailmain-datasubjects"
                            [getDataMethod]="dataSubjectsRequest"
                            [value]="processingPurpose.dataSubjectIds"
                            [multiple]="true"
                            [disabled]="true"
                        >
                        </cerrix-select>
                    </div>
                    <div class="form-group">
                        <label for="dataControllerSelect">Data controller(s)</label>
                        <cerrix-select
                            data-uiid="dmdetailmain-datacontrollers"
                            id="dataControllerSelect"
                            prettyName="data controller(s)"
                            bindValue="ID"
                            [value]="processingPurpose.dataControllerIds"
                            [ngModelOptions]="{ standalone: true }"
                            [multiple]="true"
                            [disabled]="true"
                            [getDataMethod]="dataControllerRequest"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <label for="dataProcessorSelect">Data (sub)processor(s)</label>
                        <cerrix-select
                            data-uiid="dmdetailmain-datasubprocessors"
                            id="dataProcessorSelect"
                            name="dataProcessorSelect"
                            prettyName="data (sub)processor(s)"
                            bindValue="ID"
                            [value]="processingPurpose.dataProcessorIds"
                            [ngModelOptions]="{ standalone: true }"
                            [multiple]="true"
                            [disabled]="true"
                            [getDataMethod]="dataSubProcessorRequest"
                        >
                        </cerrix-select>
                    </div>

                    <div class="form-group">
                        <label for="thirdPartyIds">Third parties</label>
                        <cerrix-select
                            data-uiid="dmdetailmain-thirdparties"
                            id="thirdPartyIds"
                            formControlName="thirdPartyIds"
                            prettyName="third parties"
                            bindValue="ID"
                            [closeOnSelect]="false"
                            [multiple]="true"
                            [getDataMethod]="thirdPartiesRequest"
                        >
                        </cerrix-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6">
            <div class="detail-page-card">
                <div class="detail-page-card-title">Data privacy impact assessment</div>
                <div class="detail-page-card-body padded cerrix-control-sm">
                    <div class="row">
                        <div class="col">
                            <cerrix-checkbox
                                data-uiid="dmdetailmain-dpiarequired"
                                label="DPIA required"
                                formControlName="dpiaRequired"
                                fontsize="0.9em"
                            >
                            </cerrix-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Most recent DPIA</label>
                                <date-input
                                    data-uiid="dmdetailmain-mostrecentdpia"
                                    formControlName="dpiaDate"
                                    [maxDate]="maxDate"
                                ></date-input>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <cerrix-textfield
                                    fieldLabel="DPIA reference"
                                    dataUiid="dmdetailmain-dpiareference"
                                    identifier="dpiaReference"
                                    formControlName="dpiaReference"
                                ></cerrix-textfield>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <cerrix-textarea
                            fieldLabel="Latest conclusion DPIA"
                            dataUiid="dmdetailmain-latestconclusiondpia"
                            identifier="dpiaConclusion"
                            formControlName="dpiaConclusion"
                        ></cerrix-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
