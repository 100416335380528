<div class="detail-page-card">
    <div class="detail-page-card-title">Linked Event</div>
    <div class="detail-page-card-actions" *ngIf="summaryModel">
        <button class="btn btn-raised" (click)="openEvent()" *ngIf="summaryModel.CanOpenParent">
            <i class="fas fa-external-link-alt"></i>
            <span>Open Event</span>
        </button>
    </div>

    <div class="detail-page-card-body padded cerrix-control-sm form-group-styling">
        <div class="fancy-loader submodule my-4" *ngIf="!summaryModel"></div>
        <div class="row" *ngIf="summaryModel">
            <div class="col-12 col-xl-6 left-block">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label>Identifier</label>
                            <input
                                data-uiid="event-moi-identifier"
                                type="text"
                                class="form-control form-control-sm"
                                disabled
                                [value]="summaryModel.Identifier"
                                inputTooltip
                            />
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-group">
                            <label>Event Name</label>
                            <input
                                data-uiid="event-moi-name"
                                type="text"
                                class="form-control form-control-sm"
                                disabled
                                [value]="summaryModel.Name"
                                inputTooltip
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Date occurred</label>
                            <date-input
                                data-uiid="event-moi-dateoccurred"
                                [(value)]="summaryModel.DateOccurred"
                                [disabled]="true"
                            ></date-input>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Date detected</label>
                            <date-input
                                data-uiid="event-moi-datedetected"
                                [(value)]="summaryModel.DateDetected"
                                [disabled]="true"
                            ></date-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-6 right-block">
                <div class="form-group">
                    <label>Description</label>
                    <textarea
                        data-uiid="event-moi-description"
                        class="form-control form-control-sm"
                        disabled
                        [rows]="5"
                        [value]="summaryModel.Description"
                        inputTooltip
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
