export function GetIconByModuleString(module: string): string {
    switch (module.toLowerCase()) {
        case "general":
        case "organization maintenance":
        case "business dimension maintenance":
        case "framework dimension maintenance":
            return "fad fa-cog";
        case "risks":
            return "fad fa-exclamation-triangle";
        case "controls":
        case "controls advanced effectiveness testing":
            return "fad fa-shield-alt";
        case "events":
        case "incidents":
            return "fad fa-bolt";
        case "business improvement management":
        case "measures of improvement":
            return "fad fa-tasks";
        case "key risk indicators":
            return "fad fa-tachometer-alt";
        case "data management":
            return "fad fa-database";
        case "forms":
            return "fad fa-file-alt";
        case "finding reports":
            return "fad fa-clipboard-list";
        case "navigators":
            return "fad fa-sitemap";
        case "third party":
            return "fad fa-handshake";
        case "audit universe objects":
        case "audit universe":
            return "fad fa-planet-ringed";
        case "audit":
        case "audits":
            return "fal fa-clipboard-list-check";
        case "audit findings":
            return "far fa-radar";
        case "audit period":
            return "fad fa-calendar-day";
        case "cerrix documents":
            return "fad fa-file";
        case "tasks":
            return "fad fa-list-ol";
    }
}
