import { DocumentModel } from "@models/documents/documentmodel";
import { SimpleTestingWorkflowModel } from "./SimpleTestingWorkflowModel";
import { IHasDocuments } from "@methods/DocumentMethods";
import { HyperlinkModel } from "@models/hyperlinks/hyperlinkmodel";

export class SimpleTestingModel implements IHasDocuments {
    Guid: string;
    ControlGuid: string;

    ControlIdentifier: string;
    ControlName: string;

    DateEdited: Date;
    Version: number;
    Workflow: SimpleTestingWorkflowModel;

    TestActivity: string;
    TestNorm: string;

    Catalogue: number;
    TestSamples: number;

    Testers: number[];
    InitialTestDate: Date;

    EvidenceUploaders: number[];
    UploaderDueDate: number;
    CalculatedUploaderDueDate: Date;

    LinkedStandard: number;
    QualityAspect: number[] = [];
    TestFrequency: number;

    UploaderEvidenceLoaded: boolean;
    /** Uploaded evidence. This is named documents to be able to use IHasDocuments */
    Documents: DocumentModel[] = [];
    Hyperlinks: HyperlinkModel[];
}
