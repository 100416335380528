<div class="cerrix-compact row" *ngIf="conclusions">
    <div class="col-lg-{{ useReviewer ? '6' : '12' }}">
        <div class="row">
            <div class="col-md">
                <div class="row">
                    <label class="col col-form-label font-weight-bold">Tester</label>
                </div>
                <div class="row">
                    <div class="col">
                        <input
                            type="text"
                            [value]="conclusions.testerName"
                            class="form-control form-control-sm"
                            readonly
                            inputTooltip
                        />
                    </div>
                </div>
            </div>
            <div class="col-md">
                <div class="row">
                    <label class="col col-form-label font-weight-bold">Score</label>
                </div>
                <div class="row">
                    <div class="col input-group">
                        <span class="input-group-text">
                            <div
                                class="conclusion-score-color"
                                [style.background-color]="
                                    conclusions.testerScoreColor ? conclusions.testerScoreColor : ''
                                "
                            ></div>
                        </span>
                        <input
                            type="text"
                            [value]="conclusions.testerScore"
                            class="form-control form-control-sm"
                            readonly
                            inputTooltip
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <label class="col col-form-label font-weight-bold">Comment</label>
        </div>
        <div class="row">
            <div class="col">
                <textarea
                    class="form-control form-control-sm"
                    style="min-height: 200px"
                    readonly
                    inputTooltip
                    >{{ conclusions.testerComment }}</textarea
                >
            </div>
        </div>
    </div>

    <div class="col-lg-6" *ngIf="useReviewer">
        <div class="row">
            <div class="col-md">
                <div class="row">
                    <label class="col col-form-label font-weight-bold">Reviewer</label>
                </div>
                <div class="row">
                    <div class="col">
                        <input
                            type="text"
                            [value]="conclusions.reviewerName"
                            class="form-control form-control-sm"
                            readonly
                            inputTooltip
                        />
                    </div>
                </div>
            </div>
            <div class="col-md">
                <div class="row">
                    <label class="col col-form-label font-weight-bold">Score</label>
                </div>
                <div class="row">
                    <div class="col input-group">
                        <span class="input-group-text">
                            <div
                                class="conclusion-score-color"
                                [style.background-color]="
                                    conclusions.reviewerScoreColor
                                        ? conclusions.reviewerScoreColor
                                        : ''
                                "
                            ></div>
                        </span>
                        <input
                            type="text"
                            [value]="conclusions.reviewerScore"
                            class="form-control form-control-sm"
                            readonly
                            inputTooltip
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <label class="col col-form-label font-weight-bold">Comment</label>
        </div>
        <div class="row">
            <div class="col">
                <textarea
                    class="form-control form-control-sm"
                    style="min-height: 200px"
                    readonly
                    inputTooltip
                    >{{ conclusions.reviewerComment }}</textarea
                >
            </div>
        </div>
    </div>
</div>
