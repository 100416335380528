import { Directive, ElementRef } from "@angular/core";
import { fromEvent } from "rxjs";

@Directive({
    selector: "[inputTooltip]",
})
export class InputTooltipDirective {
    constructor(private inputElement: ElementRef) {
        const nativeElement = <HTMLInputElement>this.inputElement.nativeElement;

        // Set initial tooltip, use setTimeout to perform this after the render queue.
        setTimeout(() => {
            nativeElement.title = nativeElement.value;
        }, 0);

        // Bind event to set new title when input changes.
        fromEvent(nativeElement, "input").subscribe(() => {
            nativeElement.title = nativeElement.value;
        });
    }
}
