export class ThirdPartyReviewModel {
    id: number;
    guid: string;
    createdAt: Date;
    createdBy: string;
    createdByRole: string;

    comment: string;
    scoreId: number;
    score: string;

}