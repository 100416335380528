import { Component, Input, Output, EventEmitter } from "@angular/core";
import { RiskModel } from "@app/risk/models/RiskModel";
import { RiskPermissionModel } from "@app/risk/models/RiskPermissionModel";
import { RiskDetailComponent } from "../risk-detail.component";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { KriLinkModel } from "@models/kri/KriLinkModel";

@Component({
    selector: "risk-kri",
    templateUrl: "./risk-kri.component.html",
    styleUrls: ["./risk-kri.component.scss"],
})
export class RiskKriComponent {
    @Input() model: RiskModel;
    @Input() disabled = false;
    @Input() permissions: RiskPermissionModel;

    @Output() changed = new EventEmitter<number[]>();

    isLoading = true;
    lastOrganizationId = 0;
    linked: KriLinkModel[];
    unlinked: KriLinkModel[];
    riskDetailComponent: RiskDetailComponent;

    constructor(private _riskDataService: RiskDataService) {}

    load(rdc: RiskDetailComponent, forceReload = false): void {
        this.riskDetailComponent = rdc;
        if (
            !forceReload &&
            (!this.model ||
                !this.model.organizationId ||
                this.lastOrganizationId === this.model.organizationId)
        ) {
            return;
        }

        this.isLoading = true;
        this.lastOrganizationId = this.model.organizationId;

        this._riskDataService
            .getLinkableKris(this.model.guid, this.model.organizationId)
            .subscribe((value) => {
                this.loadData(this.model.kriIds, value.sortBy("name", true));
                this.isLoading = false;
            });
    }

    reloadClicked(): void {
        this.load(this.riskDetailComponent, true);
    }

    loadByOrganizationId(organization: number): void {
        this.isLoading = true;
        this.lastOrganizationId = organization;
        this._riskDataService.getLinkableKris(this.model.guid, organization).subscribe((value) => {
            this.loadData(this.model.kriIds, value.sortBy("name", true));
            this.isLoading = false;
        });
    }

    linkedChanged(linked: KriLinkModel[]): void {
        const newList = linked.map((x) => x.id);
        this.model.kriIds = newList.sort((a, b) => a - b);
    }

    private loadData(linkedIds: number[], items: KriLinkModel[]): void {
        const linked = [];
        const unlinked = [];
        if (linkedIds === undefined) {
            linkedIds = [];
        }
        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }
}
