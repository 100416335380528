export enum DashboardWidgetType {
    //#region  General Widgets 0 - 999

    ModuleOverview = 10,
    ModuleCount = 20,

    Insights = 100,
    Navigators = 110,
    AdminPanel = 120,

    Calendar = 200,
    TextWidget = 300,
    VideoWidget = 310,

    EmbeddedReportWidget = 400,

    //#endregion  General Widgets 0 - 999

    //#region Risk widgets 1000-1999

    RiskMatrix = 1010,

    RiskPerCatalogueWidget = 1300,

    //#endregion Risk widgets 1000-1999

    //#region KRI widgets 2000-2999

    KriWidget = 2010,
    KriDetailWidget = 2100,

    //#endregion KRI widgets 2000-2999

    //#region Event widgets 3000-3999

    EventWidget = 3010,

    //#endregion Event widgets 3000-3999

    //#region Control widgets 4000-4999

    SimpleEffOverdue = 4010,
    DesignImplementationScore = 4020,
    EffectivenessOverdue = 4100,
    EffectivenessTesting = 4500,

    //#endregion Control widgets 4000-4999

    //#region Business Improvement Management widgets 5000-5999
    //#endregion Business Improvement Management widgets 5000-5999

    //#region Forms widgets 7000-7999

    FormOverviewWidget = 7010,

    //#endregion Forms widgets 7000-7999

    //#region Document widgets 8000-8999
    //#endregion Document widgets 8000-8999

    //#region Invite widgets 9000-9999
    //#endregion Invite widgets 9000-9999

    //#region Dashboard widget 11000-11999
    //#endregion Dashboard widget 11000-11999

    //#region Data management widgets 12000-12999
    //#endregion Data management widgets 12000-12999

    //#region Finding report widgets 13000-13999

    FindingsReportWidget = 13010,

    //#endregion Finding report widgets 13000-13999

    //#region Process Management widgets 14000-14999

    ProcessWidget = 14010,
    BusinessAssessmentsWidget = 14100,

    //#endregion Process Management widgets 14000-14999

    //#region Moi widgets 15000-15999

    MoiWidget = 15010,

    //#endregion Moi widgets 15000-15999

    //#region Control advanced testing widgets 16000-16999

    //#endregion Control advanced testing widgets 16000-16999

    //#region Reporting widgets 17000-17999
    //#endregion Reporting widgets 17000-17999

    //#region Third Party widgets 18000-18999

    ThirdParty = 18010,

    //#endregion Third Party widgets 18000-18999

    //#region Third Party widgets 20000-20999

    AuditOverview = 20010,

    //#endregion Third Party widgets 20000-20999

    //#region Administrator (<-- DEVA!) widgets 100000-100999
    //#endregion Administrator (<-- DEVA!) widgets 100000-100999
}
