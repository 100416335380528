import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";
import { DirectDataApiMapping } from "./directdata-api-mapping";
import { DirectDataApiModel } from "./directdata-api-model";
import { ApiParamModel } from "@app/manual/models/api-models/api-param-model";

@Component({
    selector: "directdata-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class DirectDataApiManualComponent extends ApiSpecComponent {
    // This will be set from the api manual
    pageId = "";

    apiPath = "";

    initSpecs() {
        const apiModel = this.getApiModelByPageId();
        const parameters = this.getApiParameters(apiModel);

        return <ApiSpecificationModel>{
            name: apiModel.name,
            description: apiModel.description,

            httpMethod: "GET",
            path: "/directdata/v1/" + this.apiPath,
            parameters: parameters,
            responses: [
                {
                    code: "200",
                    description: "OK",
                    example: [apiModel.response],
                },
            ],
        };
    }

    private getApiModelByPageId(): DirectDataApiModel {
        const mapping = DirectDataApiMapping.getMapping();

        const keys = this.pageId.split(":");
        let apiForPageId: any = mapping;
        for (const key of keys) {
            apiForPageId = apiForPageId[key];
        }

        // Store last entry as this will be the api path
        this.apiPath = keys[keys.length - 1];

        return apiForPageId;
    }

    private getApiParameters(apiModel: DirectDataApiModel): ApiParamModel[] {
        const parameters: ApiParamModel[] = [];

        if (apiModel.hasSplitPathsOption) {
            const prettyName = apiModel.name.toLowerCase();

            parameters.push({
                name: "splitPaths",
                description: `Include this parameter to split the ${prettyName} path into separate paths.`,
                in: "query",
                paramType: "boolean",
                required: false,
                example: "true",
            });
        }

        return parameters;
    }
}
