import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { RiskControlsComponent } from "./pages/detail/risk-detail/risk-controls/risk-controls.component";
import { RiskDatamanagementComponent } from "./pages/detail/risk-detail/risk-datamanagement/risk-datamanagement.component";
import { RiskDetailMainComponent } from "./pages/detail/risk-detail/risk-detail-main/risk-detail-main.component";
import { RiskImpactScalesComponent } from "./pages/detail/risk-detail/risk-detail-main/risk-impact-scales/risk-impact-scales.component";
import { RiskQuantitativeScoringComponent } from "./pages/detail/risk-detail/risk-detail-main/risk-quantitative-scoring/risk-quantitative-scoring.component";
import { RiskDetailComponent } from "./pages/detail/risk-detail/risk-detail.component";
import { RiskEventsComponent } from "./pages/detail/risk-detail/risk-events/risk-events.component";
import { RiskMoiComponent } from "./pages/detail/risk-detail/risk-mois/risk-mois.component";
import { RiskPeriodScoreChartComponent } from "./pages/detail/risk-detail/risk-result-overview/risk-period-score-chart/risk-period-score-chart.component";
import { RiskResultOverviewComponent } from "./pages/detail/risk-detail/risk-result-overview/risk-result-overview.component";
import { RiskOverviewComponent } from "./pages/risk-overview/risk-overview.component";
import { RiskDataService } from "./services/RiskDataService";
import { RiskThirdpartyComponent } from "./pages/detail/risk-detail/risk-thirdparty/risk-thirdparty.component";
import { RiskKriComponent } from "./pages/detail/risk-detail/risk-kri/risk-kri.component";

@NgModule({
    declarations: [
        RiskOverviewComponent,
        RiskDetailComponent,
        RiskDetailMainComponent,
        RiskImpactScalesComponent,
        RiskControlsComponent,
        RiskKriComponent,
        RiskMoiComponent,
        RiskDatamanagementComponent,
        RiskEventsComponent,
        RiskResultOverviewComponent,
        RiskPeriodScoreChartComponent,
        RiskQuantitativeScoringComponent,
        RiskThirdpartyComponent,
    ],
    imports: [FormsModule, CommonModule, SharedModule],
})
export class RiskModule {
    static forRoot(): ModuleWithProviders<RiskModule> {
        return {
            ngModule: RiskModule,
            providers: [RiskDataService],
        };
    }
}
