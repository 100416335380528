import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AuditFieldworkStepDataService } from "@app/audit/services/audit-fieldworkstep-data.service";
import { AuditFieldworkPermissionModel } from "@app/audit/shared/permission-models/audit-fieldwork-permission-model";
import { DocumentManagerComponent } from "@app/shared/document-manager/document-manager.component";
import { CerrixGridColumnConfig } from "@app/shared/models/cerrix-grid-editor-config";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TargetModule } from "@enums/document/TargetModule";
import { toPromise } from "@methods/CommonMethods";
import { DocumentModel } from "@models/documents/documentmodel";
import { DisplayItemModel } from "@models/generic/DisplayItemModel";

@Component({
    selector: "audit-fieldwork-step-document-linking",
    templateUrl: "./audit-fieldwork-step-document-linking.component.html",
    styleUrls: ["./audit-fieldwork-step-document-linking.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AuditFieldworkStepDocumentLinkingComponent implements OnInit {
    @Input() auditGuid: string;
    @Input() permissions: AuditFieldworkPermissionModel;
    @Input() linkedDocuments: number[];
    linked: any[];
    unlinked: any[];

    ready = false;

    documentTargetModule = TargetModule.Audit;
    documentExtraColumns: CerrixGridColumnConfig[] = [
        {
            fieldType: GenericListFieldType.Text,
            fieldName: "ExtraFields.uploadedFrom",
            prettyName: "Uploaded from",
            hidden: false,
        },
    ];

    @ViewChild("linkableComp") linkableComp: ElementRef;
    @ViewChild("documentManager") documentManager: DocumentManagerComponent;

    constructor(
        private _ds: AuditFieldworkStepDataService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit() {
        if (!this.permissions || !this.permissions.canViewLinkedDocuments) {
            return;
        }

        await this.loadData();
        this.ready = true;
    }

    public linkedChanged(linkedItems: DocumentModel[]) {
        const linkedIDs = linkedItems.map((x) => x.ID);

        for (let i = this.linkedDocuments.length; i >= 0; i--) {
            if (linkedIDs.indexOf(this.linkedDocuments[i]) === -1) {
                this.linkedDocuments.splice(i, 1);
            }
        }

        linkedIDs.forEach((id) => {
            if (this.linkedDocuments.indexOf(id) === -1) {
                this.linkedDocuments.push(id);
            }
        });

        this.documentManager.reloadGrid();
    }

    public openLinkPopup() {
        if (!this.permissions.canLinkDocuments) {
            return;
        }

        this._promptService.prompt({
            maxWidth: "1300px",
            maxHeight: "800px",
            data: {
                title: "Manage document links",
                customTemplate: this.linkableComp,
            },
        });
    }

    private async loadData() {
        const data = await toPromise(this._ds.getLinkableDocuments(this.auditGuid));
        this.setDisplayItemProperty(data);

        let linked: any[] = [];
        let unlinked: any[] = [];

        if (this.linkedDocuments.length === 0) {
            unlinked = data;
        } else {
            data.forEach((row) => {
                if (this.linkedDocuments.indexOf(row.ID) >= 0) {
                    linked.push(row);
                } else {
                    unlinked.push(row);
                }
            });
        }

        this.linked = linked;
        this.unlinked = unlinked;
    }

    private setDisplayItemProperty(data: any[]) {
        data.forEach((d) => {
            const displayItems: DisplayItemModel[] = [];
            displayItems.push({ Header: "Name", Value: d.Name });
            displayItems.push({ Header: "Description", Value: d.Description });
            displayItems.push({ Header: "Document type", Value: d.DocumentType });
            displayItems.push({ Header: "Linked from", Value: d.ExtraFields.uploadedFrom });
            displayItems.push({ Header: "Uploaded by", Value: d.Username });
            displayItems.push({ Header: "Uploaded on", Value: d.Date });

            d.displayItems = displayItems;
        });
    }
}
