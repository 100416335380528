import { Component, OnInit, ViewChild } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { toPromise } from "@methods/CommonMethods";
import { ActivatedRoute } from "@angular/router";
import { TargetModule } from "@enums/document/TargetModule";
import { DocumentModel } from "@models/documents/documentmodel";
import { isGuid } from "@methods/uniqueMethods";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { HttpResponse } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TaskDataService } from "../services/TaskDataService";
import { TaskDetailMainComponent } from "./task-detail-main/task-detail-main.component";
import { TaskWorkflowStatus } from "../enums/TaskWorkflowStatus";
import { DateTimeFormats, LocalizationService } from "@app/shared";
import { BaseTaskModel } from "../models/BaseTaskModel";
import { TaskPermissionModel } from "../models/TaskPermissionsModel";
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { TaskType } from "../enums/TaskType";
import { TaskStandingDataModel } from "../models/TaskStandingDataModel";
import { TaskControlsComponent } from "./task-controls/task-controls.component";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TypeSpecificTaskDataModel } from "../models/TypeSpecificTaskDataModel";
import { ControlDataService } from "@app/controls/services/control.data.service";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { StandingDataHelper } from "@app/standingdata/shared/standingdata.helper";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { TaskWorkflowAction } from "../enums/TaskWorkflowAction";
import { TaskScoreOptions } from "../enums/TaskScoreOptions";

@Component({
    selector: "task-detail",
    templateUrl: "./task-detail.component.html",
})
export class TaskDetailComponent implements OnInit {
    @ViewChild("detailMain") detailMain: TaskDetailMainComponent;
    @ViewChild("historyOverviewComponent") historyOverviewComponent: HistoryOverviewComponent;
    @ViewChild("linkedControls") linkedControls: TaskControlsComponent;

    constructor(
        private route: ActivatedRoute,
        protected _ds: TaskDataService,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService,
        private _appSettingDS: SettingsDataService,
        private _localizationService: LocalizationService,
        private _controlDataService: ControlDataService,
        private _userService: UserService
    ) {}

    id: string;
    tab: TabModel;
    model: BaseTaskModel;
    permissions: TaskPermissionModel;
    formGroup: FormGroup;
    protected standingData: TaskStandingDataModel;

    TaskWorkflowStatus = TaskWorkflowStatus;

    isInitialized = false;
    protected isReviewEnabled = false;

    //docs
    documents: DocumentModel[];
    isDocumentDirty = false;
    targetModule = TargetModule.Tasks;

    //hyperlinks
    hyperlinksMenuItemId = "menuItemHyperlinks";
    isHyperlinksDirty = false;
    hyperlinksLoaded = false;

    // type specific booleans
    showLinkedControls: boolean = false;

    async ngOnInit(): Promise<void> {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;

        this.permissions = await lastValueFrom(this._ds.getPermissions(this.id));

        if (!this.permissions.canView) {
            this.tab.close(false);
            return;
        }

        if (this.id) {
            this.model = await lastValueFrom(this._ds.getTask(this.id));
            const date = this._localizationService.formatDateByEnum(
                this.model.actionDate,
                DateTimeFormats.Date
            );
            this.tab.name = `Task ${date} - ${this.model.name}`;
            this.isReviewEnabled = this.model.reviewerIds.length > 0;

            this.readTypeSpecificData();
        } else {
            this.model = new BaseTaskModel();
            this.model.workflowState = this.TaskWorkflowStatus.ToDo;

            this.tab.name = "New Task";
            this.model.actionDate = new Date();
            const currentId = await toPromise(this._userService.getCurrentUserId());
            this.model.responsibles.push(currentId);

            if (this.tab.config) {
                // For now
                const controlGuid = this.tab.config.controlGuid;
                const controlinfo = await toPromise(
                    this._controlDataService.getParentControlInfo(controlGuid)
                );
                this.model.controlIds.push(controlinfo.id);
                this.model.name = controlinfo.name;
                this.model.description = controlinfo.description;
            }
        }

        // For now
        this.model.linkedTypeIds = [TaskType.ControlExecution];
        this.model.guid = this.id;

        this.standingData = await this.loadStandingData();
        this.initializeFormValidation();
        this.linkedTypesChanged(this.model.linkedTypeIds.map((x) => <IdNameCombination>{ ID: x }));

        this.isInitialized = true;
        this.tab.showLoader = false;
    }

    async loadStandingData() {
        return await toPromise(this._ds.getStandingData(this.model.guid));
    }

    async save(): Promise<boolean> {
        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        FormValidationHelper.markAllAsTouched(this.formGroup);
        FormValidationHelper.mapToModel(this.formGroup, this.model);

        let validationErrors = FormValidationHelper.getFormControlErrors(this.formGroup);

        validationErrors = this.performTypeSpecificValidations(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });
            savingPrompt.close();

            return false;
        }

        this.writeTypeSpecificData();

        lastValueFrom(this._ds.saveTask(this.model))
            .then((v: HttpResponse<any>) => {
                this.tab.id = v.toString();
                this._toastr.success("Save successful");
                this.tab.refresh();
                return true;
            })
            .finally(() => savingPrompt.close());
    }

    async delete() {
        const value = await toPromise(this._ds.deleteTask(this.id));

        if (value && value.length > 0) {
            this._toastr.error(this.toCustomizedMessage(value), "", { enableHtml: true });
        } else {
            this._toastr.success("Task deleted");
            //TODO: Refresh series?
            this.tab.close(false);
        }
    }

    private toCustomizedMessage(values: string[]): string {
        if (values?.length > 1) {
            // show the items in a list
            let messageList = "<ul>";
            for (let rowIndex = 1; rowIndex < values.length; rowIndex++) {
                messageList = messageList + `<li>${values[rowIndex]}</li>`;
            }
            messageList = messageList + "</ul>";
            return `<div>${values[0]}</div>${messageList}`;
        }
        return `<div>${values.toString()}</div>`;
    }

    handleMenuItemClick(menuItem: TabMenuItem): void {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "history":
                    this.historyOverviewComponent.loadHistory();
                    break;
                case "menuItemControls":
                    this.linkedControls.load();
                    break;
                case "menuItemDocuments":
                    this.loadDocuments();
                    break;
                case this.hyperlinksMenuItemId:
                    this.loadHyperlinks();
                    break;
            }
        }
    }

    loadDocuments(): void {
        // When a document is added on the detail page, it should not retrieve the documents again.
        if (!this.documents && this.model.Documents) {
            this.documents = this.model.Documents;
        }

        if (!this.documents && this.id && isGuid(this.id.toString())) {
            this._ds.getDocuments(this.id.toString()).subscribe((documents) => {
                this.documents = documents;
                this.model.Documents = documents;
            });
        } else {
            this.documents = [];
        }
    }

    loadHyperlinks(): void {
        if (!this.hyperlinksLoaded && this.id && isGuid(this.id.toString())) {
            this._ds.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.model.hyperlinks = hyperlinks;

                this.hyperlinksLoaded = true;
            });
        }
    }

    checkDocumentsDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, "menuItemDocuments", true);
        this.isDocumentDirty = true;
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.isHyperlinksDirty = true;
    }

    protected async toDoFinished() {
        this.model.workflowState = this.isReviewEnabled
            ? TaskWorkflowStatus.ReadyForReview
            : TaskWorkflowStatus.Done;
        const isSaved = await this.save();
        if (!isSaved) {
            this.model.workflowState = this.TaskWorkflowStatus.ToDo;
        }
    }

    protected reviewRejected() {
        const previousRejectComment =
            this.model.commentWorkflowAction == TaskWorkflowAction.ReviewReject
                ? this.model.workflowComment
                : null;

        this.showCommentDialog(
            "Reject",
            true,
            (comment?: string) => {
                this.model.workflowState = this.TaskWorkflowStatus.ToDo;
                this.model.workflowCommentNew = comment;
                this.save().then((isSaved) => {
                    if (!isSaved) {
                        this.model.workflowState = this.TaskWorkflowStatus.ReadyForReview;
                        this.model.workflowCommentNew = null;
                    }
                });
            },
            previousRejectComment
        );
    }

    protected async reviewAccepted() {
        this.showCommentDialog("Accept", false, (comment?: string) => {
            this.model.workflowState = this.TaskWorkflowStatus.Done;
            this.model.workflowCommentNew = comment;
            this.save().then((isSaved) => {
                if (!isSaved) {
                    this.model.workflowState = this.TaskWorkflowStatus.ReadyForReview;
                    this.model.workflowCommentNew = null;
                }
            });
        });
    }

    protected async reopen() {
        this.showCommentDialog("Reopen", true, (comment?: string) => {
            this.model.workflowState = this.isReviewEnabled
                ? TaskWorkflowStatus.ReadyForReview
                : TaskWorkflowStatus.ToDo;

            this.model.workflowCommentNew = comment;
            this.save().then((isSaved) => {
                if (!isSaved) {
                    this.model.workflowState = this.TaskWorkflowStatus.Done;
                    this.model.workflowCommentNew = null;
                }
            });
        });
    }

    linkedTypesChanged(selection: IdNameCombination[]) {
        let showLinkedControls = false;

        // Only loop once
        selection.forEach((element) => {
            if (element.ID == TaskType.ControlExecution) {
                showLinkedControls =
                    this.setBoolValue(showLinkedControls, true) &&
                    this.permissions.canViewDocumentsAndHyperlinks;
            }
        });

        this.showLinkedControls = showLinkedControls;
    }

    // Make sure to only set to false if not set to true by another type
    setBoolValue(currentValue: boolean, newValue: boolean): boolean {
        if (currentValue == true && newValue == false) {
            return currentValue;
        }

        return newValue;
    }

    private initializeFormValidation() {
        const canEdit = !this.id || (this.permissions && this.permissions.canEdit);
        const responsibleIdsFormControl = new FormControl(
            { value: this.model.responsibles, disabled: !canEdit },
            Validators.required
        );
        const reviewerIdsFormControl = new FormControl({
            value: this.model.reviewerIds,
            disabled: !canEdit,
        });

        const baseFormGroup = new FormGroup({
            name: new FormControl(
                { value: this.model.name, disabled: !canEdit },
                Validators.required
            ),
            linkedTypeIds: new FormControl(
                { value: this.model.linkedTypeIds, disabled: true }, // Always disabled for now
                Validators.required
            ),
            responsibles: responsibleIdsFormControl,
            reviewerIds: reviewerIdsFormControl,
            actionDate: new FormControl(
                { value: this.model.actionDate, disabled: !canEdit },
                Validators.required
            ),
            notifications: new FormControl({
                value: this.model.notifications,
                disabled: !canEdit,
            }),
            description: new FormControl({
                value: this.model.description,
                disabled: !canEdit,
            }),
            comments: new FormControl({
                value: this.model.comments,
                disabled: !this.permissions?.canEditComment,
            }),
            score: new FormControl(
                {
                    value: this.model.score,
                    disabled: !this.permissions?.canExecuteTask,
                },
                this.model.scoreOption == TaskScoreOptions.Required ? Validators.required : null
            ),
        });

        // User can't be responsible and reviewer at the same time
        responsibleIdsFormControl.valueChanges.subscribe((v) => {
            this.standingData.reviewers = StandingDataHelper.getOptionsDisabledByIds(
                this.standingData.reviewers,
                v
            );
        });
        reviewerIdsFormControl.valueChanges.subscribe((v) => {
            this.standingData.responsibles = StandingDataHelper.getOptionsDisabledByIds(
                this.standingData.responsibles,
                v
            );
            this.isReviewEnabled = v.length > 0;
        });

        this.standingData.responsibles = StandingDataHelper.getOptionsDisabledByIds(
            this.standingData.responsibles,
            this.model.reviewerIds
        );
        this.standingData.reviewers = StandingDataHelper.getOptionsDisabledByIds(
            this.standingData.reviewers,
            this.model.responsibles
        );

        // Type specific controls
        // const modelTypes = this.model.typedTasks;
        // const hasControlExecutionFlag =
        //     TaskType.ControlExecution === (this.model.typedTasks & EntityRoles.DataController);

        this.formGroup = baseFormGroup;
    }

    private performTypeSpecificValidations(validationErrors: ValidationErrors): ValidationErrors {
        //check if any control is linked
        if (this.showLinkedControls && !this.model.controlIds?.any()) {
            validationErrors["Linked controls"] = "Please link at least one control to the task.";
        }

        return validationErrors;
    }

    private writeTypeSpecificData() {
        this.model.typedTasks = {};

        if (this.showLinkedControls) {
            let typeSpecificTaskModel: TypeSpecificTaskDataModel = {
                id: this.model.id,
                taskType: +TaskType.ControlExecution,
                data: {},
            };

            typeSpecificTaskModel.data["controlIds"] = this.model.controlIds.join(",");

            this.model.typedTasks[+TaskType.ControlExecution] = typeSpecificTaskModel;
        }
    }

    private readTypeSpecificData() {
        const controlExecutionData = this.model.typedTasks[TaskType.ControlExecution];
        if (controlExecutionData) {
            this.model.controlIds = controlExecutionData.data["controlIds"].split(";").map(Number);
        }
    }

    private showCommentDialog(
        actionName: string,
        commentRequired: boolean,
        saveAction: (comment: string) => void,
        commentText?: string
    ) {
        const commentField = "comment";
        this._promptService
            .prompt({
                hasBackdrop: true,
                maxHeight: "350px",
                data: {
                    confirmOnEnter: false,
                    closeOnEsc: true,
                    title: `${actionName} reason`,
                    fields: [
                        {
                            prettyName: "Comment",
                            fieldName: commentField,
                            fieldType: GenericListFieldType.TextArea,
                            required: commentRequired,
                            uiid: "task-workflow-comment-input",
                            defaultValue: commentText,
                        },
                    ],
                    confirmButton: {
                        text: actionName,
                    },
                },
            })
            .getResult()
            .subscribe((result) => {
                if (result) {
                    saveAction(result[commentField]);
                }
            });
    }
}
