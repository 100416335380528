<ng-container *ngIf="config && !config.showLoader && !config.noData">
    <div class="risk-matrix-container">
        <div class="matrix-area">
            <ng-container *ngIf="showSummary">
                <cerrix-riskmatrix-summary
                    [matrix]="matrix"
                    [cellSize]="cellSize"
                    (cellClick)="drillDownFromCell($event)"
                    (summaryClick)="drillDownFromSummary($event)"
                    *ngIf="enableSummaryClick"
                ></cerrix-riskmatrix-summary>
                <cerrix-riskmatrix-summary
                    [matrix]="matrix"
                    [cellSize]="cellSize"
                    (cellClick)="drillDownFromCell($event)"
                    *ngIf="!enableSummaryClick"
                ></cerrix-riskmatrix-summary>
            </ng-container>

            <div class="matrix-only" *ngIf="!showSummary">
                <div class="matrix">
                    <cerrix-riskmatrix
                        [matrix]="matrix"
                        [cellSize]="cellSize"
                        [highlightSelection]="false"
                        (selectedChange)="drillDownFromCell($event)"
                    ></cerrix-riskmatrix>
                </div>
            </div>
        </div>
        <div class="legend-area" *ngIf="showLegend">
            <div
                class="likelihood-legend"
                *ngIf="matrix.likelihoodInfo && matrix.likelihoodInfo.length > 0"
            >
                <div class="title">Likelihood</div>
                <div
                    class="value"
                    *ngFor="let info of matrix.likelihoodInfo; let i = index"
                    [title]="info.Name + (info.Description ? '\n' + info.Description : '')"
                >
                    {{ info.Name.startsWith(i + 1 + ". ") ? info.Name : i + 1 + ". " + info.Name }}
                </div>
            </div>
            <div class="impact-legend" *ngIf="matrix.impactInfo && matrix.impactInfo.length > 0">
                <div class="title">
                    {{ matrix.customImpactName ? matrix.customImpactName : "Impact" }}
                </div>
                <div
                    class="value"
                    *ngFor="let info of matrix.impactInfo; let i = index"
                    [title]="info.Name + (info.Description ? '\n' + info.Description : '')"
                >
                    {{ info.Name.startsWith(i + 1 + ". ") ? info.Name : i + 1 + ". " + info.Name }}
                </div>
            </div>
        </div>
    </div>
</ng-container>
