import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiDataService } from "@services/http/ApiDataService";
import { Configuration } from "@app/app.constants";
import { ControlExportData } from "@app/controls/models/ControlExportData";
import { Observable } from "rxjs";
import { AdvancedEffectivenessExportParametersModel } from "../models/AdvancedEffectivenessExportModel";

@Injectable()
export class ExportEffectivenessDataService extends ApiDataService {
    private allowEvidence: Promise<boolean>;

    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "controls/testing/advanced/effectiveness/exports");
    }

    public allowEvidenceExport(): Promise<boolean> {
        if (!this.allowEvidence) {
            this.allowEvidence = this.get<boolean>("/evidence/hasaccess").toPromise();
        }

        return this.allowEvidence;
    }

    public getWithGuids(guids: string[]): Observable<ControlExportData[]> {
        const formData = new FormData();
        formData.append("guids", JSON.stringify(guids));

        return this.post<ControlExportData[]>(formData);
    }

    public exportDataForImport(controlGuids: string[], testplanGuids: string[]): Promise<void> {
        const formData = new FormData();

        formData.append("controls", JSON.stringify(controlGuids));
        formData.append("testplans", JSON.stringify(testplanGuids));

        const url = "/import";
        return this.downloadFromPost(formData, url);
    }

    public exportData(data: any[]): Promise<void> {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));

        const url = "/testplan";
        return this.downloadFromPost(formData, url);
    }

    public async exportDataAndEvidence(
        model: AdvancedEffectivenessExportParametersModel
    ): Promise<void> {
        if (!(await this.allowEvidenceExport())) {
            return;
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(model));

        const url = "/evidence";
        return this.post<void>(formData, url).toPromise();
    }
}
