import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { FindingsLinkableComponent } from "./linkable/findings-linkable.component";
import { AuditFindingDetailComponent } from "./pages/audit-finding-detail/audit-finding-detail.component";
import { AuditFindingDetailAuditInfoComponent } from "./pages/audit-finding-detail/components/audit-finding-detail-audit-info/audit-finding-detail-audit-info.component";
import { AuditFindingDetailMainComponent } from "./pages/audit-finding-detail/components/audit-finding-detail-main/audit-finding-detail-main.component";
import { AuditFindingDetailMoiLinksComponent } from "./pages/audit-finding-detail/components/audit-finding-detail-moi-links/audit-finding-detail-moi-links.component";
import { AuditFindingDetailRelatedLinksComponent } from "./pages/audit-finding-detail/components/audit-finding-detail-related-links/audit-finding-detail-related-links.component";
import { AuditFindingOverviewComponent } from "./pages/audit-finding-overview/audit-finding-overview.component";
import { AuditFindingDataService } from "./services/audit-finding-data.service";

@NgModule({
    imports: [CommonModule, SharedModule.forRoot()],
    declarations: [
        AuditFindingOverviewComponent,
        AuditFindingDetailComponent,

        AuditFindingDetailMainComponent,
        AuditFindingDetailMoiLinksComponent,
        AuditFindingDetailRelatedLinksComponent,
        AuditFindingDetailAuditInfoComponent,
    ],
})
export class AuditFindingModule {
    static forRoot(): ModuleWithProviders<AuditFindingModule> {
        return {
            ngModule: AuditFindingModule,
            providers: [AuditFindingDataService],
        };
    }
}
