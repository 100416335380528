import { AuditUniverseDetailStandingDataModel } from "./../../../../shared/page-models/audit-universe-detail-standing-data-model";
import { AuditUniverseDetailPermissionModel } from "@app/audit-universe/shared/page-models/audit-universe-detail-permission-model";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditUniverseModel } from "@app/audit-universe/shared/data-models/audit-universe-model";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { of } from "rxjs";
import { GenericListConfig } from "./../../../../../shared/models/GenericList/GenericList";
import { nameof } from "@methods/jeffs-toolkit";
import { AuditUniverseAssessmentModel } from "@app/audit-universe/shared/data-models/audit-universe-assessment-model";
import { AuditUniverseOverallRiskScoreResultModel } from "@app/audit-universe/shared/data-models/audit-universe-overall-risk-score-result-model";
import { UserService } from "@app/user/user-profile/shared/user.service";
import { AuditUniverseControlEnvScoreModel } from "@app/audit-universe/shared/data-models/audit-universe-control-env-score-model";
import { AuditUniverseControlEnvironmentStandingDataModel } from "@app/audit-universe/shared/data-models/audit-universe-control-environment-standing-data-model";

@Component({
    selector: "audit-universe-detail-main",
    templateUrl: "./audit-universe-detail-main.component.html",
    styleUrls: ["./audit-universe-detail-main.component.scss"],
})
export class AuditUniverseDetailMainComponent implements OnInit {
    @Input() permissions: AuditUniverseDetailPermissionModel;
    @Input() standingData: AuditUniverseDetailStandingDataModel;
    @Input() model: AuditUniverseModel;
    @Input() formGroup: FormGroup;

    controlEnvironmentTotalScore: number;

    controlEnvironmentListConfig: GenericListConfig;
    overallRiskScoringListConfig: GenericListConfig;
    assessmentListConfig: GenericListConfig;

    // Internal SD splitup to make handling them all easier
    controlEnvironmentSD: { ID: number; Name: string }[];
    controlEnvironmentScorePerEnvironment: {
        [env: number]: { ID: number; Name: string; Color: string }[];
    };
    controlEnvironmentWeightSD: { [id: number]: number }; // ID will be scoreID

    constructor(private _userDs: UserService) {}

    async ngOnInit() {
        if (this.permissions.canViewControlEnvironment) {
            this.arrangeControlEnvironmentSD(this.standingData.controlEnvironments);
            this.setWeightForControlEnvironmentModels();
            this.createControlEnvironmentListConfig();
        }

        if (this.permissions.canViewRiskScoring) {
            await this.createOverallRiskScoringConfig();
        }

        if (this.permissions.canViewAssessment) {
            this.createRiskAssessmentConfig();
        }
    }

    //#region private methods
    private arrangeControlEnvironmentSD(
        controlEnvironments: AuditUniverseControlEnvironmentStandingDataModel[]
    ): void {
        this.controlEnvironmentSD = [];
        this.controlEnvironmentScorePerEnvironment = [];
        this.controlEnvironmentWeightSD = [];

        controlEnvironments.forEach((sd: AuditUniverseControlEnvironmentStandingDataModel) => {
            this.controlEnvironmentSD.push({ ID: sd.ID, Name: sd.Name });
            this.controlEnvironmentScorePerEnvironment[sd.ID] = [];
            sd.Scores.forEach((score) => {
                this.controlEnvironmentScorePerEnvironment[sd.ID].push({
                    ID: score.ID,
                    Name: score.Name,
                    Color: score.Color,
                });
                this.controlEnvironmentWeightSD[score.ID] = score.Weight;
            });
        });
    }

    private setWeightForControlEnvironmentModels() {
        this.model.controlEnvironments.forEach((controlEnv) => {
            if (controlEnv.scoreId > 0)
                controlEnv.weight = this.controlEnvironmentWeightSD[controlEnv.scoreId];
        });

        this.calculateControlEnvironmentTotal();
    }

    private calculateControlEnvironmentTotal() {
        this.controlEnvironmentTotalScore = this.model.controlEnvironments
            .map((x) => {
                if (x.weight) return x.weight;
                else return 0;
            })
            .reduce((l, r) => l + r, 0);
    }

    private createControlEnvironmentListConfig() {
        this.controlEnvironmentListConfig = <GenericListConfig>{
            name: "Control Environment",
            data: this.model.controlEnvironments,
            idProp: nameof<AuditUniverseControlEnvScoreModel>((x) => x.id),

            allowAdd: this.permissions.canEditControlEnvironment,
            allowEdit: this.permissions.canEditControlEnvironment,
            allowDelete: this.permissions.canEditControlEnvironment,

            fields: [
                {
                    fieldName: nameof<AuditUniverseControlEnvScoreModel>(
                        (x) => x.controlEnvironmentId
                    ),
                    prettyName: "Control Environment",
                    fieldType: GenericListFieldType.SingleSelect,
                    required: true,
                    getDataMethod: () => {
                        return of(this.controlEnvironmentSD);
                    },
                },
                {
                    fieldName: nameof<AuditUniverseControlEnvScoreModel>((x) => x.scoreId),
                    prettyName: "Score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    required: true,
                    getDataMethodByRow: (row: AuditUniverseControlEnvScoreModel) => {
                        var scoreFieldConfig = this.controlEnvironmentListConfig.fields.find(
                            (f) =>
                                f.fieldName ==
                                nameof<AuditUniverseControlEnvScoreModel>((x) => x.scoreId)
                        );

                        if (row && row.controlEnvironmentId) {
                            scoreFieldConfig.isReadonly = row.controlEnvironmentId <= 0;

                            return of(
                                this.controlEnvironmentScorePerEnvironment[row.controlEnvironmentId]
                            );
                        }

                        scoreFieldConfig.isReadonly = true;
                        let items = [];
                        this.standingData.controlEnvironments.forEach((env) => {
                            env.Scores.forEach((score) => {
                                items.push({ ID: score.ID, Name: score.Name, Color: score.Color });
                            });
                        });
                        return of(items);
                    },
                    checkReloadDataByRowChanges(
                        previousRow: AuditUniverseControlEnvScoreModel,
                        currentRow: AuditUniverseControlEnvScoreModel
                    ) {
                        if (previousRow.controlEnvironmentId != currentRow.controlEnvironmentId) {
                            currentRow.scoreId = 0;
                            return true;
                        }
                        return false;
                    },
                },
                {
                    fieldName: nameof<AuditUniverseControlEnvScoreModel>((x) => x.weight),
                    prettyName: "Weight",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                    checkReloadDataByRowChanges(
                        previousRow: AuditUniverseControlEnvScoreModel,
                        currentRow: AuditUniverseControlEnvScoreModel
                    ) {
                        return previousRow.scoreId != currentRow.scoreId;
                    },
                },
                {
                    fieldName: nameof<AuditUniverseControlEnvScoreModel>((x) => x.comment),
                    prettyName: "Comment",
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    fieldName: nameof<AuditUniverseControlEnvScoreModel>((x) => x.dateChanged),
                    prettyName: "Date",
                    hideInEdit: true,
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<AuditUniverseControlEnvScoreModel>((x) => x.changedBy),
                    prettyName: "Assessed By",
                    hideInEdit: true,
                    defaultValue: "User, User",
                    fieldType: GenericListFieldType.Text,
                },
            ],
            rowValueChanged: (
                currentRow: AuditUniverseControlEnvScoreModel,
                updatedValues: AuditUniverseControlEnvScoreModel
            ) => {
                currentRow.weight = this.controlEnvironmentWeightSD[currentRow.scoreId];
            },
            dataChanged: (data: AuditUniverseControlEnvScoreModel) => {
                this.calculateControlEnvironmentTotal();
            },
        };
    }

    private async createOverallRiskScoringConfig(): Promise<void> {
        const defaultUserName = await this._userDs.getUserName();

        const standingData = this.standingData;
        const dateProp = nameof<AuditUniverseOverallRiskScoreResultModel>((x) => x.date);
        const scoreProp = nameof<AuditUniverseOverallRiskScoreResultModel>((x) => x.score);

        this.overallRiskScoringListConfig = <GenericListConfig>{
            name: "Overall Score",
            data: this.model.overallRiskScoreResults,
            idProp: nameof<AuditUniverseOverallRiskScoreResultModel>((x) => x.id),

            allowAdd: this.permissions.canEditRiskScoring,
            allowEdit: this.permissions.canEditRiskScoring,
            allowDelete: this.permissions.canEditRiskScoring,

            fields: [
                {
                    fieldName: scoreProp,
                    prettyName: "Score",
                    fieldType: GenericListFieldType.ColoredSelect,
                    required: true,
                    uiid: "overall-risk-score-result-score-input",
                    getDataMethodByRow(row, ds) {
                        if (!row) {
                            return of(standingData.riskScores);
                        }

                        const applicableScores = standingData.riskScores.filter(
                            (s) => !s.isArchived || s.ID == row[scoreProp]
                        );

                        return of(applicableScores);
                    },
                },
                {
                    fieldName: nameof<AuditUniverseOverallRiskScoreResultModel>((x) => x.comment),
                    prettyName: "Comment",
                    fieldType: GenericListFieldType.TextArea,
                    uiid: "overall-risk-score-result-comment-input",
                },
                {
                    fieldName: dateProp,
                    prettyName: "Date",
                    required: true,
                    fieldType: GenericListFieldType.Date,
                    maximumValue: new Date(),
                    uiid: "overall-risk-score-result-date-input",
                },
                {
                    fieldName: nameof<AuditUniverseOverallRiskScoreResultModel>((x) => x.scoredBy),
                    prettyName: "Auditor",
                    hideInEdit: true,
                    defaultValue: defaultUserName,
                    fieldType: GenericListFieldType.Text,
                },
            ],

            uiid: "list-overall-risk-score-results",
            uiidAdd: "btn-add-overall-risk-score-result",
            uiidDelete: "btn-delete-overall-risk-score-result",
        };
    }

    private createRiskAssessmentConfig() {
        const riskCatalogueProp = nameof<AuditUniverseAssessmentModel>((x) => x.riskCatalogue);
        const descriptionProp = nameof<AuditUniverseAssessmentModel>((x) => x.description);
        const dateProp = nameof<AuditUniverseAssessmentModel>((x) => x.date);
        const scoreProp = nameof<AuditUniverseAssessmentModel>((x) => x.score);

        const standingData = this.standingData;
        this.assessmentListConfig = <GenericListConfig>{
            name: "Risk Assessments",
            data: this.model.assessments,
            idProp: nameof<AuditUniverseAssessmentModel>((x) => x.id),

            allowAdd: this.permissions.canEditAssessment,
            allowEdit: this.permissions.canEditAssessment,
            allowDelete: this.permissions.canEditAssessment,

            rowValueChanged: (currentRow: any, updatedRowValues: any) => {
                const riskCatalogueValue = updatedRowValues[riskCatalogueProp];
                if (!riskCatalogueValue) {
                    return;
                }

                const chosenRiskCatalogue = this.standingData.riskCatalogues.filter(
                    (x) => x.ID == currentRow[riskCatalogueProp]
                );

                if (chosenRiskCatalogue.length > 0) {
                    currentRow[descriptionProp] = chosenRiskCatalogue[0].Description;
                }
            },

            fields: [
                {
                    fieldName: riskCatalogueProp,
                    prettyName: "Risk Catalogue",
                    fieldType: GenericListFieldType.SingleSelect,
                    getDataMethod: () => {
                        return of(this.standingData.riskCatalogues);
                    },
                },
                {
                    fieldName: descriptionProp,
                    prettyName: "Description",
                    hideInOverview: true,
                    isReadonly: true,
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    fieldName: nameof<AuditUniverseAssessmentModel>((x) => x.comment),
                    prettyName: "Comment",
                    fieldType: GenericListFieldType.TextArea,
                },
                {
                    fieldName: nameof<AuditUniverseAssessmentModel>((x) => x.assessedBy),
                    prettyName: "Assessed By",
                    hideInEdit: true,
                    defaultValue: "User, User",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: dateProp,
                    prettyName: "Assess Date",
                    fieldType: GenericListFieldType.Date,
                },
                {
                    fieldName: nameof<AuditUniverseAssessmentModel>((x) => x.score),
                    prettyName: "Score",
                    required: true,
                    fieldType: GenericListFieldType.ColoredSelect,
                    getDataMethodByRow(row, ds) {
                        if (!row) {
                            return of(standingData.assessmentScores);
                        }

                        const applicableScores = standingData.assessmentScores.filter(
                            (s) => !s.isArchived || s.ID == row[scoreProp]
                        );

                        return of(applicableScores);
                    },
                },
            ],
        };
    }

    //#endregion private methods
}
