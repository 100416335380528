<div class="container-fluid pt-3">
    <ngx-masonry [options]="{ percentPosition: true, gutter: 10 }">
        <div
            ngxMasonryItem
            class="masonry-item"
            *ngIf="permService.permissions.HasRiskExportAccess"
        >
            <export-risks></export-risks>
        </div>
        <div
            ngxMasonryItem
            class="masonry-item"
            *ngIf="permService.permissions.HasEventExportAccess"
        >
            <export-events></export-events>
        </div>
        <div ngxMasonryItem class="masonry-item" *ngIf="permService.permissions.HasKriExportAccess">
            <export-kri></export-kri>
        </div>
        <div
            ngxMasonryItem
            class="masonry-item"
            *ngIf="permService.permissions.HasAdvancedTestingExportAccess"
        >
            <export-controls></export-controls>
        </div>
        <div
            ngxMasonryItem
            class="masonry-item"
            *ngIf="permService.permissions.HasImprovementManagementExportAccess"
        >
            <export-bim></export-bim>
        </div>
        <div
            ngxMasonryItem
            class="masonry-item"
            *ngIf="permService.permissions.HasDataManagementExportAccess"
        >
            <export-datamanagement></export-datamanagement>
        </div>
        <div
            ngxMasonryItem
            class="masonry-item"
            *ngIf="permService.permissions.HasUsersImportExportAccess"
        >
            <export-users></export-users>
        </div>
        <div ngxMasonryItem class="masonry-item" *ngIf="permService.permissions.HasTaskAccess">
            <export-tasks></export-tasks>
        </div>
    </ngx-masonry>
</div>
