import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { AuditUniverseLinkableModel } from "@app/audit-universe/shared/data-models/audit-universe-linkable-model";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable } from "rxjs";
import { AuditUniversePeriodModel } from "../shared/data-models/audit-universe-period-model";
import { AuditUniversePeriodPermissionModel } from "../shared/page-models/audit-universe-period-permission-model";
import { AuditUniversePeriodInfoModel } from "./../shared/data-models/audit-universe-period-info-model";

@Injectable()
export class AuditUniversePeriodDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audituniverse/period");
    }

    //#region CRUD calls

    getList() {
        return this.get<AuditUniversePeriodInfoModel[]>();
    }

    getObject(guid: string) {
        const url = "/" + guid;

        return this.get<AuditUniversePeriodModel>(url);
    }

    store(model: AuditUniversePeriodModel): Observable<HttpEvent<AuditUniversePeriodModel>> {
        const url = "";
        return this.post(model, url);
    }

    public deletePeriod(guid: string): Observable<string[]> {
        const url = `/${guid}`;
        return this.delete(url);
    }

    //#endregion CRUD calls

    //#region Support data calls

    getPermissions(guid?: string): Observable<AuditUniversePeriodPermissionModel> {
        let url = "/permissions";
        if (guid) {
            url = `/${guid}` + url;
        }

        return this.get<AuditUniversePeriodPermissionModel>(url);
    }

    public getLinkableUniverseObjects(): Observable<AuditUniverseLinkableModel[]> {
        return this.get<AuditUniverseLinkableModel[]>("/universeobjects/linkable");
    }

    //#endregion Support data calls

    //#region History Calls

    public getHistory(guid: string | number): Observable<HistoryModel[]> {
        const url = `/${guid}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(
        guid: string | number,
        historyDate: string | number
    ): Observable<HistoryChange[]> {
        const url = `/${guid}/history/${historyDate}`;

        return this.get<HistoryChange[]>(url);
    }

    //#endregion History Calls
}
