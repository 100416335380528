import { AuditFieldworkStepModel } from "./audit-fieldwork-step-model";
import { AuditPhasesModel } from "./audit-phases-model";
import { AuditDocumentRequestModel } from "./audit-document-request-model";
import { AuditRiskAssessmentModel } from "./audit-risk-assessment-model";
import { DocumentFolderModel } from "@models/documents/DocumentFolderModel";
import { IHasDocumentFolder } from "@interfaces/Documents/IHasDocumentFolder";
import { DocumentModel } from "@models/documents/documentmodel";
import { AuditContextModel } from "./context/audit-context-model";

export class AuditModel implements IHasDocumentFolder {
    guid: string;
    id: number;

    identifier: string;
    responsible: string;

    dateCreated: Date;
    dueDate: Date;

    overallScore: string;
    overallScoreColor: string;

    auditName: string;
    auditPlan: string;

    // Dropdowns
    auditType: number;
    auditStatus: number;
    criticality: number;
    confidentiality: number;
    auditScore: number;

    // Text Areas
    auditObjective: string;
    auditRationale: string;
    auditConclusion: string;

    // Users
    sponsors: number[];
    auditees: number[];
    auditors: number[];
    reviewers: number[];
    chiefAuditExecutives: number[];

    linkedThirdParties: number[] = [];
    formIds: number[] = [];
    riskAssessment?: AuditRiskAssessmentModel;
    context: AuditContextModel;

    phases: AuditPhasesModel[];
    documentRequests: AuditDocumentRequestModel[];
    fieldworkSteps: AuditFieldworkStepModel[];

    Documents: DocumentModel[];
    Folders: DocumentFolderModel[];

    constructor() {
        this.context = new AuditContextModel();
    }
}
