<div class="document-link-container">
    <div class="fancy-loader submodule" *ngIf="!ready"></div>
    <div class="detail-page-card" *ngIf="ready">
        <div class="detail-page-card-title">Linked Documents</div>
        <div class="detail-page-card-actions">
            <button
                class="btn btn-raised blue"
                (click)="openLinkPopup()"
                *ngIf="permissions.canLinkDocuments"
            >
                <i class="fas fa-link"></i>
                <span class="ms-2">Link</span>
            </button>
        </div>

        <document-manager
            NoDocumentMessage="No documents linked"
            [(Documents)]="linked"
            [AllowAdd]="false"
            [AllowDelete]="false"
            [disabled]="false"
            [DocumentTypeTargets]="documentTargetModule"
            [ExtraFieldColumnConfig]="documentExtraColumns"
            #documentManager
        ></document-manager>
    </div>
</div>

<ng-template #linkableComp *ngIf="permissions.canLinkDocuments">
    <app-linkable
        *ngIf="ready"
        typename="documents"
        idValueProp="ID"
        displayValueProp="Name"
        [orderByValueProperty]="true"
        [linked]="linked"
        [unlinked]="unlinked"
        [hidePanelToggle]="true"
        [linkablePaginationItemCount]="12"
        (linkedChange)="linkedChanged($event)"
    >
    </app-linkable>
</ng-template>
