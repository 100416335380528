import { OnInit, Component, Input } from "@angular/core";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { ModuleType } from "@enums/ModuleType";
import { PeriodicNotificationModel } from "@app/mailmessages/models/periodicNotificationModel";
import { PeriodicNotificationService } from "@app/mailmessages/service/periodic-notification.service";

@Component({
    selector: "uc-periodic-notification-overview",
    templateUrl: "./periodic-notification-overview.component.html",
    styleUrls: ["./periodic-notification-overview.component.scss"],
})
export class PeriodicNotificationOverviewComponent implements OnInit {
    @Input() module: ModuleType;

    config: GenericListConfig;

    constructor(
        private _tabService: TabService,
        private _pnService: PeriodicNotificationService,
        public pages: Pages
    ) {}

    ngOnInit() {
        this._pnService.getNotifications(this.module).subscribe((data) => {
            this.setupConfig(data);
        });
    }

    setupConfig(data: PeriodicNotificationModel[]) {
        this.config = <GenericListConfig>{
            name: "Periodic Notifications",
            data: data,
            idProp: "Id",

            allowAdd: true,
            allowEdit: true,
            allowDelete: false,

            fields: [
                {
                    fieldName: "Name",
                    prettyName: "Name",
                    fieldType: GenericListFieldType.Text,
                    isReadonly: true,
                },
                {
                    fieldName: "Subject",
                    prettyName: "Subject",
                    fieldType: GenericListFieldType.Text,
                },
                {
                    fieldName: "Active",
                    prettyName: "Send Active",
                    fieldType: GenericListFieldType.CheckBox,
                },
            ],
            addOverride: () => {
                const config = {
                    module: this.module,
                };
                this._tabService.generateTab(
                    this.pages.PeriodicDetail,
                    0,
                    "New periodic notification",
                    config
                );
            },
            editOverride: (row: PeriodicNotificationModel) => {
                const config = {
                    module: this.module,
                };
                this._tabService.generateTab(this.pages.PeriodicDetail, row.Id, row.Name, config);
            },
        };
    }

    openNotification(item: PeriodicNotificationModel) {
        const config = {
            module: this.module,
        };
        this._tabService.generateTab(this.pages.PeriodicDetail, item.Id, item.Name, config);
    }

    add() {
        const config = {
            module: this.module,
        };
        this._tabService.generateTab(
            this.pages.PeriodicDetail,
            0,
            "New periodic notification",
            config
        );
    }
}
