<ng-container *ngIf="pageReady">
    <div
        [froalaEditor]="froalaOptions"
        [(froalaModel)]="froalaEditingValue"
        [id]="editorID"
        #editorCmp
    ></div>

    <cerrix-contextmenu
        [menu]="contextMenu"
        [container]="editorCmp"
        *ngIf="contextMenu"
    ></cerrix-contextmenu>

    <div class="modal fade" tabindex="-1" role="dialog" #pdfModal>
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">PDF Selector</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body" *ngIf="pdfs">
                    <div>
                        <table class="table-cerrix bordered rounded centered hoverable striped">
                            <thead>
                                <tr class="row">
                                    <th class="col-md-4">Name</th>
                                    <th class="col-md-4">Description</th>
                                    <th class="col-md-2">Uploader</th>
                                    <th class="col-md-2">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="row"
                                    *ngFor="let data of pdfs"
                                    (click)="selectedPdf = data"
                                    [ngClass]="{
                                        highlighted:
                                            selectedPdf != null && selectedPdf.guid == data.guid
                                    }"
                                >
                                    <td class="col-md-4">{{ data.name }}</td>
                                    <td class="col-md-4">{{ data.description }}</td>
                                    <td class="col-md-2">{{ data.updatedBy }}</td>
                                    <td class="col-md-2">{{ data.updatedOn | dateTimeFormat }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="!selectedPdf"
                        (click)="insertPdf()"
                    >
                        Insert PDF
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
