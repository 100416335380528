import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DatamanagementDataService } from "@app/datamanagement/services/datamanagement-data.service";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ProcessingPurposeModel } from "@app/datamanagement/models/ProcessingPurposeModel";
import { isGuid } from "@methods/uniqueMethods";
import { FormGroup, FormControl, Validators, ValidationErrors } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { DocumentModel } from "@models/documents/documentmodel";
import { getFormControl, getFormValue, closeDirtyPageCheck } from "@methods/CommonMethods";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { LinkedMoiOverviewComponent } from "@app/moi/moi-linked-overview/moi-linked-overview.component";
import { DatamanagementPermissionModel } from "@app/datamanagement/models/DatamanagementPermissionModel";
import { DataManagementDetailMainComponent } from "./datamanagement-detail-main/datamanagement-detail-main.component";
import { ToastrService } from "ngx-toastr";
import { HttpResponse } from "@angular/common/http";
import { ProcessingPurposeDataStructuresComponent } from "../datastructures/datastructures.component";
import { ExpandedProcessingPurposeModel } from "@app/datamanagement/models/ExpandedProcessingPurposeModel";
import { DataStructuresHelper } from "@app/datamanagement/shared/helpers/datastructures-helper";
import { DatamanagementControlsComponent } from "./datamanagement-controls/datamanagement-controls.component";
import { DatamanagementRisksComponent } from "./datamanagement-risks/datamanagement-risks.component";
import { Pages } from "@constants/pages/Pages";
import { TargetModule } from "@enums/document/TargetModule";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { DatamanagementConfigModel } from "@app/datamanagement/models/DatamanagementConfigModel";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";

@Component({
    selector: "datamanagement-detail",
    templateUrl: "./datamanagement-detail.component.html",
    styleUrls: ["./datamanagement-detail.component.scss"],
})
export class DataManagementDetailComponent implements AfterViewInit {
    @ViewChild("detailComponent")
    detailComponent: DataManagementDetailMainComponent;
    @ViewChild("dataStructureComponent")
    dataStructureComponent: ProcessingPurposeDataStructuresComponent;
    @ViewChild("dmMoisComponent") dmMoisComponent: LinkedMoiOverviewComponent;
    @ViewChild("risksComponent") risksComponent: DatamanagementRisksComponent;
    @ViewChild("controlsComponent")
    controlsComponent: DatamanagementControlsComponent;
    @ViewChild("historyDetailOverviewComponent")
    historyDetailOverviewComponent: HistoryOverviewComponent;
    @ViewChild("historyMdsOverviewComponent")
    historyMdsOverviewComponent: HistoryOverviewComponent;

    tab: TabModel;
    id: number;

    parentForm: FormGroup;

    processingPurpose: ProcessingPurposeModel;
    permissions: DatamanagementPermissionModel;

    documents: DocumentModel[];
    isDocumentDirty = false;
    targetModule = TargetModule.DataProcessing;
    tabIconBackup: string;

    dmMoiType = MoiTypes.DM_Moi;

    hyperlinksMenuItemId = "menuItemHyperlinks";

    constructor(
        public _datamanagementDS: DatamanagementDataService,
        private route: ActivatedRoute,
        private _toastr: ToastrService,
        private _pages: Pages,
        private _promptService: CerrixPromptService
    ) {
        this.id = this.id > 0 ? this.id : this.route.snapshot.params.id;
    }

    ngAfterViewInit() {
        this.processRequests();
    }

    processRequests() {
        const guid = this.id ? this.id.toString() : "";
        const isExisting = isGuid(guid);

        this._datamanagementDS.getPermissions(guid).subscribe(
            async (permissions) => {
                this.permissions = permissions;
                if (isExisting) {
                    this._datamanagementDS
                        .getProcessingPurpose(this.id.toString())
                        .subscribe((ppModel) => {
                            this.initDetails(ppModel, isExisting);
                        });
                } else {
                    const ppModel = new ProcessingPurposeModel();
                    this.initDetails(ppModel, isExisting);
                }
            },
            () => {
                this.tab.close();
            }
        );
    }

    initDetails(processingPurpose: ProcessingPurposeModel, isExisting: boolean) {
        this.processingPurpose = processingPurpose;

        if (isExisting) {
            this.tab.name = `(P) ${processingPurpose.ID} - ${processingPurpose.name}`;
        } else {
            this.initNewProccessingPurposeModel();
        }

        this.initFormValidation(processingPurpose);
        this.setStakeHolderArea(processingPurpose.dpiaRequired);

        this.tab.showLoader = false;

        this.tab.beforeClose = (checkOnly: boolean) => {
            const unsavedChanges = this.isDocumentDirty;
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !unsavedChanges);
        };
    }

    initNewProccessingPurposeModel() {
        const conf = <DatamanagementConfigModel>this.tab.config;
        if (conf) {
            const orgID = conf.organizationId;
            if (orgID) {
                this.processingPurpose.organizationId = +orgID;
            }

            const bdID = conf.businessId;
            if (bdID) {
                this.processingPurpose.businessDimensionIds = [+bdID];
            }

            const fdID = conf.frameworkId;
            if (fdID) {
                this.processingPurpose.frameworkDimensionIds = [+fdID];
            }
        }
    }

    initFormValidation(ppModel: ProcessingPurposeModel) {
        this.parentForm = new FormGroup({
            // Details
            name: new FormControl(ppModel.name, [Validators.required]),
            description: new FormControl(ppModel.description),
            purpose: new FormControl(ppModel.purpose),
            securityMeasures: new FormControl(ppModel.securityMeasures),
            basisId: new FormControl(ppModel.basisId),
            explanationBasis: new FormControl(ppModel.explanationBasis),
            organizationId: new FormControl(ppModel.organizationId, Validators.required),
            businessDimensionIds: new FormControl(ppModel.businessDimensionIds),
            frameworkDimensionIds: new FormControl(ppModel.frameworkDimensionIds),
            removalIds: new FormControl(ppModel.removalIds),
            sensitivityIds: new FormControl(ppModel.sensitivityIds),
            generalArchivePolicy: new FormControl(
                ppModel.generalArchivePolicy,
                Validators.required
            ),
            profiling: new FormControl(ppModel.profiling),
            dataAnalytics: new FormControl(ppModel.dataAnalytics),

            // Stakeholders
            thirdPartyIds: new FormControl(ppModel.thirdPartyIds),

            // Data privacy impact assessment
            dpiaRequired: new FormControl(ppModel.dpiaRequired),
            dpiaDate: new FormControl(
                ppModel.dpiaDate,
                FormValidationHelper.validateDateBeforeNow(
                    "Most recent DPIA cannot be later than today."
                )
            ),
            dpiaReference: new FormControl(ppModel.dpiaReference),
            dpiaConclusion: new FormControl(ppModel.dpiaConclusion),
        });

        if (this.permissions.readonly) {
            this.parentForm.disable();
        } else {
            getFormControl<ProcessingPurposeModel>(
                this.parentForm,
                "dpiaRequired"
            ).valueChanges.subscribe((toggled) => this.setStakeHolderArea(toggled));
        }
    }

    async save() {
        const validationResult = this.validateProcessingPurpose();
        if (validationResult) {
            const savingPrompt = this._promptService.loader("Saving changes, please wait...");

            const storeModel = validationResult as ExpandedProcessingPurposeModel;
            await this._datamanagementDS
                .storeProcessingPurpose(storeModel)
                .toPromise()
                .then((value: HttpResponse<any>) => {
                    savingPrompt.close();
                    this._toastr.success("", "Update completed");

                    this.tab.id = value.toString();
                    this.tab.refresh();
                })
                .catch((error) => {
                    savingPrompt.close();
                });
        }
    }

    private validateProcessingPurpose(): boolean | ExpandedProcessingPurposeModel {
        let validationErrors: ValidationErrors;
        const canEdit = !this.permissions.readonly;

        if (canEdit && !this.parentForm.valid) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
        }

        if (validationErrors && Object.keys(validationErrors).length > 0) {
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

            return false;
        }

        // Fetch processingpurpose
        FormValidationHelper.mapToModel(this.parentForm, this.processingPurpose);

        const storeModel = new ExpandedProcessingPurposeModel();
        storeModel.processingPurpose = this.processingPurpose;
        storeModel.sourceDocuments = this.processingPurpose.sourceDeliveries;
        storeModel.dataRecipients = this.processingPurpose.dataRecipients;
        storeModel.Documents = this.processingPurpose.documents;

        if (this.dataStructureComponent) {
            const dataStructureModel = this.dataStructureComponent.getDataStructureModel();
            if (dataStructureModel) {
                storeModel.dataStructures = dataStructureModel;
                const dataStructureValidations =
                    DataStructuresHelper.processAndValidateManageDataStructures(
                        storeModel.dataStructures
                    );
                if (dataStructureValidations.length > 0) {
                    const validationMessage = dataStructureValidations.join("<br>");
                    this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

                    return false;
                }
            }
        }
        return storeModel;
    }

    delete() {
        this._datamanagementDS.deleteProcessingPurpose(this.processingPurpose.guid).subscribe(
            (value) => {
                if (value && value.length > 0) {
                    this._toastr.warning(value.toString());
                } else {
                    this._toastr.success("Data processing deleted");
                    if (
                        this.tab.parent &&
                        this.tab.parent.lookupname === this._pages.DataManagementOverview
                    ) {
                        this.tab.parent.refresh();
                    }
                    this.tab.close(false);
                }
            },
            (error) => {
                this._toastr.error("", error || error.Message);
            }
        );
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case "manageDataStructures": {
                    const generalArchivePolicy = getFormValue<ProcessingPurposeModel>(
                        this.parentForm,
                        "generalArchivePolicy"
                    );
                    this.dataStructureComponent.loadDataStructures(generalArchivePolicy);
                    break;
                }
                case "dmMois": {
                    this.dmMoisComponent.load(false);
                    break;
                }
                case "risks": {
                    this.risksComponent.load();
                    break;
                }
                case "controls": {
                    this.controlsComponent.load();
                    break;
                }
                case "documents": {
                    this.loadDocuments();
                    break;
                }
                case this.hyperlinksMenuItemId:
                    this.loadHyperlinks();
                    break;
                case "detailsHistory": {
                    this.historyDetailOverviewComponent.loadHistory();
                    break;
                }
                case "mdsHistory": {
                    this.historyMdsOverviewComponent.loadHistory();
                    break;
                }
            }
        }
    }

    loadRisks() {
        this.risksComponent.linked = [];
        this.risksComponent.unlinked = [];
    }

    loadDocuments() {
        if (!this.documents && this.id && isGuid(this.id.toString())) {
            this._datamanagementDS.getDocuments(this.id.toString()).subscribe((documents) => {
                this.documents = documents;
                this.processingPurpose.documents = documents;
            });
        } else {
            this.documents = [];
        }
    }

    loadHyperlinks(): void {
        if (!this.processingPurpose.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this._datamanagementDS.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.processingPurpose.Hyperlinks = hyperlinks;
            });
        }
    }

    setStakeHolderArea(toggled: boolean) {
        if (toggled) {
            getFormControl<ProcessingPurposeModel>(this.parentForm, "dpiaDate").enable();
            getFormControl<ProcessingPurposeModel>(this.parentForm, "dpiaReference").enable();
            getFormControl<ProcessingPurposeModel>(this.parentForm, "dpiaConclusion").enable();
        } else {
            getFormControl<ProcessingPurposeModel>(this.parentForm, "dpiaDate").disable();
            getFormControl<ProcessingPurposeModel>(this.parentForm, "dpiaReference").disable();
            getFormControl<ProcessingPurposeModel>(this.parentForm, "dpiaConclusion").disable();
        }
    }

    checkDirty() {
        this.setDirty("documents", true);
        this.isDocumentDirty = true;
    }

    setDirty(menuItem: string, dirty: boolean) {
        const tab = this.tab.menu.getTabMenuItemByMenuItemId(menuItem);

        if (!tab) {
            return;
        }

        if (!this.tabIconBackup) {
            this.tabIconBackup = tab.iconClass;
        }

        tab.iconClass = this.tabIconBackup + (dirty ? " orange" : "");
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.setDirty(this.hyperlinksMenuItemId, true);
    }
}
