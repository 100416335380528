<div class="fancy-loader" *ngIf="!loaded"></div>

<div class="audit-risk-assessment" *ngIf="loaded">
    <div class="left-block">
        <generic-list-manager [config]="risksConfig" *ngIf="risksConfig"></generic-list-manager>

        <generic-list-manager
            [config]="controlsConfig"
            *ngIf="controlsConfig"
        ></generic-list-manager>
    </div>

    <div class="right-block">
        <div class="detail-page-card">
            <div class="detail-page-card-title">
                <span>Risk Assessment</span>
            </div>
            <div class="detail-page-card-body padded form-group-styling">
                <cerrix-custom-fields-editor
                    [parentForm]="riskAssessmentFormGroup"
                    [customFieldData]="customFieldData"
                    emptyMessage="No risk assessment fields are defined for this Audit Type."
                >
                </cerrix-custom-fields-editor>
            </div>
        </div>
    </div>
</div>
