import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { AuditDetailStandingDataModel } from "@app/audit/shared/page-models/audit-detail-standing-data-model";
import { AuditDetailPermissionModel } from "@app/audit/shared/permission-models/audit-detail-permission-model";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { AuditDetailAuditeeRequestsComponent } from "../components/audit-detail-auditee-requests/audit-detail-auditee-requests.component";
import { AuditDetailFindingLinkingComponent } from "../components/audit-detail-finding-linking/audit-detail-finding-linking.component";

@Component({
    selector: "auditee-audit-details",
    templateUrl: "./auditee-audit-details.component.html",
    styleUrls: ["./auditee-audit-details.component.scss"],
})
export class AuditeeAuditDetailsComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() auditModel: AuditModel;
    @Input() standingData: AuditDetailStandingDataModel;

    @Input() cerrixTab: TabModel;
    @Input() permissions: AuditDetailPermissionModel;

    @Output() onSave = new EventEmitter<never>();

    @ViewChild("requests") requestsComp: AuditDetailAuditeeRequestsComponent;
    documentRequestsTabId = "audit-document-requests";

    @ViewChild("findings") findingsComp: AuditDetailFindingLinkingComponent;
    findingsTabId = "audit-findings";

    constructor() {}

    async ngOnInit() {
        this.cerrixTab.showLoader = false;
    }

    save() {
        this.onSave.emit();
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.menuItemId) {
                case this.documentRequestsTabId:
                    this.requestsComp.init();
                    break;
                case this.findingsTabId:
                    this.findingsComp.init();
                    break;
            }
        }
    }
}
