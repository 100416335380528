import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { Pages } from "@constants/pages/Pages";
import { TargetModule } from "@enums/document/TargetModule";
import { closeDirtyPageCheck } from "@methods/CommonMethods";
import { getFormControl } from "@methods/jeffs-toolkit";
import { isGuid } from "@methods/uniqueMethods";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { KriModel } from "@models/kri";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { ToastrService } from "ngx-toastr";
import { forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { KriDataService } from "../services/kri-data.service";
import { KriConfigModel } from "../shared/models/kri-config-model";
import { KriPermissions } from "../shared/models/kri-permissions";
import { KriDataGraphComponent } from "../shared/pages/kri-data-graph/kri-data-graph.component";

@Component({
    selector: "kri-detail",
    templateUrl: "./kri-detail.component.html",
    styleUrls: ["./kri-detail.component.scss"],
})
export class KriDetailComponent implements OnInit {
    tab: TabModel;
    tabID: string;
    id: string;

    kri: KriModel;
    organizations: CerrixTreeItem[];
    // CerrixTree doesn't handle setting tree later very well
    businessDimensions: CerrixTreeItem[];

    mainFormGroup: FormGroup;

    canView = false;
    canEdit = false;

    isDocumentDirty = false;
    isHyperlinkDirty = false;
    targetModule = TargetModule.Kri;
    tabIconBackup: string;

    @ViewChild(HistoryOverviewComponent) historyOverviewComponent: HistoryOverviewComponent;
    @ViewChild(KriDataGraphComponent) kriDataGraph: KriDataGraphComponent;

    hyperlinksMenuItemId = "menuItemHyperlinks";

    constructor(
        public _kriDS: KriDataService,
        private _pages: Pages,
        private _toastr: ToastrService,
        private _permissions: PermissionsService,
        private _promptService: CerrixPromptService
    ) {}

    ngOnInit() {
        if (this.id) {
            this._kriDS.getPermissions(this.id).subscribe((permissions: KriPermissions) => {
                if (permissions.canView) {
                    this.canView = permissions.canView;
                    this.canEdit = permissions.canEdit;

                    this.loadKri();
                }
            });
        } else {
            if (this._permissions.permissions.Workspace.kri.canAdd) {
                this.canView = true;
                this.canEdit = true;

                this.loadKri();
            }
        }
    }

    loadKri() {
        (this.id ? this._kriDS.getKri(this.id) : of(new KriModel())).subscribe((kri) => {
            this.kri = kri;

            if (!this.id) {
                this.initNewKriModel();
            }

            this.initFormValidation();

            this.tab.name = this.kri.guid
                ? `(K) ${this.kri.identifier} - ${this.kri.name}`
                : "(K) New KRI";

            forkJoin([
                this._kriDS
                    .getOrganizations(this.kri.organizationIds)
                    .pipe(map((orgs) => (this.organizations = orgs))),
                this._kriDS
                    .getBusinessDimensions(this.kri.organizationIds, this.kri.businessDimensionIds)
                    .pipe(map((bds) => (this.businessDimensions = bds))),
            ]).subscribe((): void => {
                const riskCatalogueControl = getFormControl<KriModel>(
                    this.mainFormGroup,
                    (x: KriModel): number[] => x.riskCatalogueIds
                );
                if (this.canEdit && this.organizations && this.organizations.length > 0) {
                    riskCatalogueControl.enable();
                } else {
                    riskCatalogueControl.disable();
                }

                this.tab.showLoader = false;
            });

            this.tab.beforeClose = (checkOnly: boolean) => {
                const unsavedChanges = this.isDocumentDirty || this.isHyperlinkDirty;
                return closeDirtyPageCheck(
                    this.tab,
                    this._promptService,
                    checkOnly,
                    !unsavedChanges
                );
            };
        });
    }

    initNewKriModel() {
        const conf = <KriConfigModel>this.tab.config;
        if (conf) {
            const orgID = conf.organizationId;
            if (orgID) {
                this.kri.organizationIds = [+orgID];
            }

            const bdID = conf.businessId;
            if (bdID) {
                this.kri.businessDimensionIds = [+bdID];
            }
        }
    }

    save() {
        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        if (!this.mainFormGroup.valid) {
            FormValidationHelper.markAllAsTouched(this.mainFormGroup);

            const validationErrors = FormValidationHelper.getFormControlErrors(this.mainFormGroup);
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

            savingPrompt.close();
            return;
        }

        FormValidationHelper.mapToModel(this.mainFormGroup, this.kri);

        const kriDataValidations = this.validateDataPoints();
        const kriDataValidationsMessage =
            "The following organizations are not valid:<br> " +
            kriDataValidations.distinct().join("<br>");
        if (kriDataValidations.any()) {
            this._toastr.warning(kriDataValidationsMessage, "Save failed.", { enableHtml: true });

            savingPrompt.close();
            return;
        }

        this._kriDS.storeKri(this.kri).subscribe(
            (event) => {
                this.id = event.toString();

                this.tab.id = this.id;
                this.tab.refresh();

                savingPrompt.close();
                this._toastr.success("Update completed");
            },
            () => {
                savingPrompt.close();
            }
        );
    }

    validateDataPoints() {
        const validationMessage = [];
        this.kri.datas.forEach((d) => {
            if (this.kri.organizationIds.indexOf(d.organizationId) < 0) {
                validationMessage.push(d.organizationName);
            }
        });
        return validationMessage;
    }

    delete() {
        this._kriDS.deleteKri(this.kri.guid).subscribe(() => {
            this._toastr.success("KRI deleted");
            if (this.tab.parent && this.tab.parent.lookupname === this._pages.KriOverview) {
                this.tab.parent.refresh();
            }
            this.tab.close(false);
        });
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (!menuItem) {
            return;
        }

        switch (menuItem.name) {
            case "Data Graph": {
                this.kriDataGraph.load();
                break;
            }
            case "History": {
                this.historyOverviewComponent.loadHistory();
                break;
            }
            case "Documents": {
                this.loadDocuments();
                break;
            }
            case "Hyperlinks": {
                this.loadHyperlinks();
                break;
            }
        }
    }

    private initFormValidation() {
        const dataFormArray = new FormArray([]);
        for (const data of this.kri.datas) {
            const dataFormGroup = new FormGroup({
                id: new FormControl(data.id),
                value: new FormControl(data.value, Validators.required),
                date: new FormControl(data.date, Validators.required),
                time: new FormControl(data.time, Validators.required),
                organizationId: new FormControl(data.organizationId, Validators.required),
                organizationName: new FormControl(data.organizationName),
            });

            dataFormArray.push(dataFormGroup);
        }

        this.mainFormGroup = new FormGroup({
            name: new FormControl(this.kri.name, Validators.required),
            description: new FormControl(this.kri.description),
            calculation: new FormControl(this.kri.calculation),
            organizationIds: new FormControl(this.kri.organizationIds, Validators.required),
            businessDimensionIds: new FormControl(this.kri.businessDimensionIds),
            riskCatalogueIds: new FormControl(this.kri.riskCatalogueIds),
            dataOwner: new FormControl(this.kri.dataOwner),
            source: new FormControl(this.kri.source),
            frequency: new FormControl(this.kri.frequency, Validators.required),
            aggregate: new FormControl(this.kri.aggregate, Validators.required),
            measurement: new FormControl(this.kri.measurement, Validators.required),
            graphType: new FormControl(this.kri.graphType, Validators.required),
            greenThreshold: new FormControl(this.kri.greenThreshold),
            redThreshold: new FormControl(this.kri.redThreshold),
            isKpi: new FormControl(this.kri.isKpi),
            comments: new FormControl(this.kri.comments),
            datas: dataFormArray,
        });

        if (!this.canEdit) {
            this.mainFormGroup.disable();
        }
    }

    checkDirty() {
        this.setDirty("menuItemDocuments", true);
        this.isDocumentDirty = true;
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.setDirty(this.hyperlinksMenuItemId, true);
        this.isHyperlinkDirty = true;
    }

    setDirty(menuItem: string, dirty: boolean) {
        const tab = this.tab.menu.getTabMenuItemByMenuItemId(menuItem);

        if (!tab) {
            return;
        }

        if (!this.tabIconBackup) {
            this.tabIconBackup = tab.iconClass;
        }

        tab.iconClass = this.tabIconBackup + (dirty ? " orange" : "");
    }

    private loadDocuments() {
        if (this.kri.documents.any()) return;

        if (this.id && isGuid(this.id.toString())) {
            this._kriDS.getDocuments(this.id.toString()).subscribe((documents) => {
                this.kri.documents = documents;
            });
        } else {
            this.kri.documents = [];
        }
    }

    loadHyperlinks(): void {
        if (!this.kri.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this._kriDS.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.kri.Hyperlinks = hyperlinks;
            });
        }
    }
}
