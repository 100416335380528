import { Component, Input, OnInit } from "@angular/core";
import { KriModel } from "@models/kri";
import { RiskLinkModel } from "@models/risk/RiskLinkModel";
import { KriDetailComponent } from "../kri-detail/kri-detail.component";
import { KriDataService } from "../services/kri-data.service";

@Component({
    selector: "kri-risk",
    templateUrl: "./kri-risk.component.html",
    styleUrls: ["./kri-risk.component.scss"],
})
export class KriRiskComponent implements OnInit {
    @Input() model: KriModel;
    kriDetailComponent: KriDetailComponent;
    linked: RiskLinkModel[] = [];
    unlinked: RiskLinkModel[] = [];
    isLoading = true;

    constructor(private _kriDataservice: KriDataService) {}

    ngOnInit() {
        this.load();
    }
    load() {
        this.isLoading = true;
        this._kriDataservice.getLinkedRisks(this.model.guid).subscribe((data) => {
            this.linked = data;

            this.isLoading = false;
        });
    }
}
