import { WidgetFilterKey } from "@app/dashboard/shared/models/WidgetFilterKey";
import { Component, ViewChild } from "@angular/core";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { TabService } from "@services/tabs/TabService";
import { BaseChartWidget } from "../BaseChartWidget";
import { KriDataGraphComponent } from "@app/kri/shared/pages/kri-data-graph/kri-data-graph.component";
import { Pages } from "@constants/pages/Pages";

@Component({
    selector: "kri-detail-widget",
    templateUrl: "./kri-detail-widget.component.html",
    styleUrls: ["./kri-detail-widget.component.scss"],
})
export class KriDetailWidgetComponent extends BaseChartWidget {
    invalidConfig = false;
    kriGuid: string;

    @ViewChild(KriDataGraphComponent) dataGraph: KriDataGraphComponent;

    constructor(sd: StandingdataDataService, tabService: TabService, private pages: Pages) {
        super(tabService, sd);
    }

    async load() {
        this.setKriGuid();
        super.load();
    }

    private setKriGuid() {
        const kriGuid = this.getFilter(WidgetFilterKey.Kri.KriGuid);
        this.invalidConfig = !kriGuid;

        this.kriGuid = kriGuid;
        this.config.showLoader = false;
    }

    protected onFilterChange() {
        super.onFilterChange();
        this.setKriGuid();
        this.dataGraph.kriGuid = this.kriGuid;
        this.dataGraph.load();
    }

    protected onResize() {
        if (this.dataGraph) {
            this.dataGraph.redrawGraph();
        }
    }

    protected async onGlobalOpenClick() {
        this._tabService.generateTab(this.pages.KriDetail, this.kriGuid);
    }
}
