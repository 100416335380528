import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { FormField } from "@app/forms/models";

@Component({
    selector: "form-detail-info",
    templateUrl: "./form-detail-info.component.html",
    styleUrls: ["../../form-detail-field.component.scss"],
})
export class FormDetailInfoComponent implements OnInit {
    @Input() field: FormField;
    @Output() fieldChange = new EventEmitter<FormField>();

    htmlValue: string;
    constructor() {}

    ngOnInit() {
        try {
            this.htmlValue = this.field.CustomSettings
                ? decodeURIComponent(this.field.CustomSettings)
                : "";
        } catch (error) {
            // If we got an error from decodeURIComponent we will try to use the unescape method for older records.
            this.htmlValue = unescape(this.field.CustomSettings);
        }
    }

    updateValue(newSettings: string) {
        this.field.CustomSettings = encodeURIComponent(newSettings);
        this.fieldChange.emit(this.field);
    }
}
