import { AppConstants } from "@app/app.constants";
import { FindingReportDataService } from "@app/findingreport/services";
import { KriDataService } from "@app/kri/services/kri-data.service";
import { ReportDataService } from "@app/report/services/ReportDataService";
import { RiskDataService } from "@app/risk/services/RiskDataService";
import { GenericListFieldType } from "@app/shared/models/GenericList/GenericListField";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { ModuleSubType } from "@enums/ModuleSubType";
import { ModuleType } from "@enums/ModuleType";
import { StandingDataType } from "@enums/StandingDataType";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { WorkspacePresetService } from "@app/shared/services/workspace/workspace-preset.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { CalendarWidgetComponent } from "../widgets/calendar-widget/calendar-widget.component";
import { EmbeddedReportWidgetComponent } from "../widgets/embedded-report-widget/embedded-report-widget.component";
import { BusinessAssessmentWidgetComponent } from "../widgets/highchart-widgets/types/business-assessment-widget.component";
import { ControlEffTestingWidgetComponent } from "../widgets/highchart-widgets/types/controls-eff-testing-widget.component";
import { ControlSimpleEffOverdueWidgetComponent } from "../widgets/highchart-widgets/types/controls-simple-eff-overdue-widget.component";
import { DesignImplementationWidgetComponent } from "../widgets/highchart-widgets/types/designimplementation-widget-component";
import { EffectivenessOverdueWidgetComponent } from "../widgets/highchart-widgets/types/effectiveness-overdue-widget.component";
import { EventWidgetComponent } from "../widgets/highchart-widgets/types/event-widget.component";
import { KriWidgetComponent } from "../widgets/highchart-widgets/types/kri-widget.component";
import { MoiWidgetComponent } from "../widgets/highchart-widgets/types/moi-widget.component";
import { ThirdPartyWidgetComponent } from "../widgets/highchart-widgets/types/thirdparty-widget.component";
import { ProcessWidgetComponent } from "../widgets/process-widget/process-widget.component";
import { VideoWidgetComponent } from "../widgets/video-widget/video-widget.component";
import { FormsDataService } from "./../../forms/services/forms-data.service";
import { FormOverviewWidgetComponent } from "./../widgets/form-overview-widget/form-overview-widget.component";
import { FindingReportWidgetComponent } from "./../widgets/highchart-widgets/types/finding-report-widget.component";
import { RisksPerCatalogueWidgetComponent } from "./../widgets/highchart-widgets/types/risks-per-catalogue-widget.component";
import { KriDetailWidgetComponent } from "./../widgets/kri-detail-widget/kri-detail-widget.component";
import { LinkWidgetComponent } from "./../widgets/link-widget/link-widget.component";
import { RiskMatrixWidgetComponent } from "./../widgets/risk-matrix-widget/risk-matrix-widget.component";
import { TextWidgetComponent } from "./../widgets/text-widget/text-widget.component";
import { DashboardGraphType } from "./enums/DashboardGraphType.enum";
import { DashboardLegendType } from "./enums/DashboardLegendType.enum";
import { DashboardWidgetType } from "./enums/DashboardWidgetType.enum";
import { KriWidgetTypeDescription } from "./enums/KriWidgetType.enum";
import { ProcessWidgetLink, ProcessWidgetLinkDescription } from "./enums/ProcessWidgetLink.enum";
import { QuickLinkItemContent } from "./enums/QuickLinkItemContent.enum";
import { WidgetConfigType } from "./enums/WidgetConfigType.enum";
import { DashboardWidgetModel } from "./models/DashboardWidgetModel";
import { WidgetConfigField } from "./models/WidgetConfigField";
import { WidgetFilterKey } from "./models/WidgetFilterKey";
import { getMoiTypes } from "@enums/moi/MoiTypes";
import { ModuleDetailWidgetComponent } from "../widgets/module-detail-widget/module-detail-widget.component";
import { WorkspaceCountService } from "@app/shared/services/workspace/workspace-count.service";

export class DashboardWidgetMapping {
    private static mapping: { [type in DashboardWidgetType]: DashboardWidgetModel };

    public static all() {
        if (!this.mapping) {
            this.set();
        }

        return this.asCopy(Object.values(this.mapping));
    }

    public static get(type: DashboardWidgetType) {
        if (!this.mapping) {
            this.set();
        }

        return this.asCopy(this.mapping[type], ["component", "configurations"]);
    }

    private static asCopy<T>(original: T, persistantKeys?: (keyof T)[]): T {
        const copy = JSON.parse(JSON.stringify(original)) as T;

        if (persistantKeys) {
            persistantKeys.forEach((key) => {
                copy[key] = original[key];
            });
        }

        return copy;
    }

    private static set() {
        this.mapping = {
            "10": {
                component: LinkWidgetComponent,
                module: ModuleType.COMMON,
                title: "Workspaces",
                description: "Adds quick links to 'Add new' and 'Workspace' of available modules.",
                icon: "fad fa-table",
                right: "HasWorkspacesAccess",
                showLoader: true,
                minCols: 2,
                minRows: 2,
                customConfig: {
                    type: DashboardWidgetType.ModuleOverview,
                    globalFilterEnabled: false,
                },
                configurations: [...DashboardWidgetMapping._defaultQuickLinkFilters],
            },
            "20": {
                component: ModuleDetailWidgetComponent,
                module: ModuleType.COMMON,
                title: "Module Count",
                description:
                    "Displays the amount of items within a module filtered by the selected preset (optional).",
                icon: "fad fa-tally",
                showLoader: true,
                right: "HasWidgetCountAccess",
                minCols: 1,
                minRows: 1,

                hasGlobalOpen: true,

                customConfig: {
                    type: DashboardWidgetType.ModuleCount,
                    hideHeader: true,
                    globalFilterEnabled: true,
                },
                configurations: [
                    {
                        prettyName: "Custom title",
                        description:
                            "The default title and subtitle will be replaced by this value if set.",
                        fieldName: WidgetFilterKey.ModuleDetail.CustomTitle,
                        fieldType: GenericListFieldType.Text,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: "",
                    },
                    {
                        prettyName: "Module",
                        fieldName: WidgetFilterKey.ModuleDetail.Module,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: "",
                        getDataMethod: () => {
                            const countService =
                                AppConstants.AppInjector.get(WorkspaceCountService);
                            return countService.getAvailableModules().pipe(
                                map((data) => {
                                    return data.map((x) => {
                                        return { ID: x.presetCode, Name: x.name };
                                    });
                                })
                            );
                        },
                    },
                    {
                        prettyName: "Workspace Preset",
                        description:
                            "Please note that the widget will display the number of items based on the preset that you select. However, there are some limitations with the existing presets.\n\nCertain filters may not work with the widget, including the period filter and filters on Risk Custom Impact scales and Custom fields.\n\nWe apologize for any inconvenience this may cause.",
                        fieldName: WidgetFilterKey.ModuleDetail.WorkspacePreset,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: "",
                        getDataMethodByRow(row) {
                            const selectedModule = row[WidgetFilterKey.ModuleDetail.Module];
                            if (!selectedModule) {
                                return of([{ ID: -1, Name: "No preset" }]);
                            }

                            const countService =
                                AppConstants.AppInjector.get(WorkspaceCountService);
                            return countService.getAvailableModules().pipe(
                                map((data) => {
                                    return data.find((s) => s.presetCode == selectedModule).presets;
                                })
                            );
                        },
                        checkReloadDataByRowChanges(previous, current) {
                            const keyToCheck = WidgetFilterKey.ModuleDetail.Module;
                            if (previous[keyToCheck] !== current[keyToCheck]) {
                                current[WidgetFilterKey.ModuleDetail.WorkspacePreset] = null; // Reset the selected preset if the module has changed
                                return true;
                            }

                            return false;
                        },
                    },
                ],
                globalFilterKeys: [],
            },
            "100": {
                component: LinkWidgetComponent,
                module: ModuleType.COMMON,
                title: "Insights",
                description: "Adds quick links to reporting, exporting and document workspace.",
                icon: "fad fa-file-chart-line",
                right: "HasInsightsAccess",
                showLoader: true,
                minCols: 2,
                minRows: 2,
                customConfig: {
                    type: DashboardWidgetType.Insights,
                    globalFilterEnabled: false,
                },
                configurations: [...DashboardWidgetMapping._defaultQuickLinkFilters],
            },
            "110": {
                component: LinkWidgetComponent,
                module: ModuleType.COMMON,
                title: "Navigators",
                description:
                    "Adds quick links to Framework- and Business Dimension Navigators and Process templates.",
                icon: "fad fa-sitemap",
                right: "HasNavigatorAccess",
                showLoader: true,
                minCols: 2,
                minRows: 2,
                customConfig: {
                    type: DashboardWidgetType.Navigators,
                    globalFilterEnabled: false,
                },
                configurations: [...DashboardWidgetMapping._defaultQuickLinkFilters],
            },
            "120": {
                component: LinkWidgetComponent,
                module: ModuleType.COMMON,
                title: "Administration Controls",
                description:
                    "Adds quick links to multiple Administration portals, such as 'User Management', 'Standing Data', etc.",
                icon: "fad fa-toolbox",
                right: "HasAdminAccess",
                showLoader: true,
                minCols: 2,
                minRows: 2,
                customConfig: {
                    type: DashboardWidgetType.AdminPanel,
                    globalFilterEnabled: false,
                },
                configurations: [...DashboardWidgetMapping._defaultQuickLinkFilters],
            },
            "200": {
                component: CalendarWidgetComponent,
                module: ModuleType.COMMON,
                title: "Calendar",
                description:
                    "Shows items that are assigned to you, categorized by 'Overdue' (if enabled), 'This week' and 'Later'",
                icon: "fad fa-calendar-alt",
                showLoader: true,
                minCols: 2,
                minRows: 2,
                customConfig: {
                    type: DashboardWidgetType.Calendar,
                    globalFilterEnabled: false,
                },
                configurations: [
                    {
                        prettyName: "Show calendar actions",
                        fieldName: WidgetFilterKey.Calendar.ShowActions,
                        fieldType: GenericListFieldType.CheckBox,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: false,
                    },
                ],
            },
            "300": {
                component: TextWidgetComponent,
                module: ModuleType.COMMON,
                title: "Text Widget",
                description: "Text editor that can be configured and embedded on the dashboard.",
                icon: "fad fa-paragraph",
                showLoader: false,
                minCols: 1,
                minRows: 1,
                customConfig: {
                    type: DashboardWidgetType.TextWidget,
                    globalFilterEnabled: false,
                },
                configurations: [
                    {
                        prettyName: "Embedded Text Value",
                        fieldName: WidgetFilterKey.TextWidget.Text,
                        fieldType: GenericListFieldType.WYSIWYG,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: "",
                    },
                ],
            },
            "310": {
                component: VideoWidgetComponent,
                module: ModuleType.COMMON,
                title: "Video Widget",
                description: "Widget that can be configured with an embedded video.",
                icon: "fas fa-video",
                showLoader: false,
                minCols: 1,
                minRows: 1,
                hasGlobalOpen: false,
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.VideoWidget,
                    globalFilterEnabled: false,
                },
                configurations: [
                    {
                        prettyName: "Video URL",
                        description:
                            "Url of video to embed. Only youtube and vimeo urls are supported.",
                        fieldName: WidgetFilterKey.VideoWidget.Url,
                        fieldType: GenericListFieldType.Text,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: "",
                    },
                ],
            },
            "400": {
                component: EmbeddedReportWidgetComponent,
                module: ModuleType.COMMON,
                title: "Embedded Report",
                description: "Embed a (Power BI) report on the dashboard.",
                icon: "fad fa-file-invoice",
                right: "HasReportingAccess",
                hasGlobalOpen: true,
                showLoader: true,
                minCols: 6,
                minRows: 4,
                customConfig: {
                    type: DashboardWidgetType.EmbeddedReportWidget,
                    globalFilterEnabled: false,
                },
                configurations: [
                    {
                        prettyName: "Report to Embed",
                        fieldName: WidgetFilterKey.Insights.ReportToEmbed,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: 0,
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(ReportDataService);
                            return ds.getList();
                        },
                    },
                    {
                        prettyName: "Load report on load",
                        description:
                            "Enable this to load report directly, without waiting for user input.",
                        fieldName: WidgetFilterKey.Insights.ReportSkipLoadCheck,
                        fieldType: GenericListFieldType.CheckBox,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: false,
                    },
                ],
            },
            "1010": {
                component: RiskMatrixWidgetComponent,
                module: ModuleType.ORM,
                title: "Risk Matrix",
                description: "Organization Matrix with risk scores (gross/net).",
                icon: "fas fa-exclamation-triangle",
                right: "HasRiskAccess",
                showLoader: true,
                minCols: 3,
                minRows: 3,
                hasGlobalOpen: true,
                legendConfigurable: true,
                customConfig: {
                    type: DashboardWidgetType.RiskMatrix,
                    globalFilterEnabled: true,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    {
                        prettyName: "Show summary",
                        description:
                            "Show summary per defined color above the Risk Matrix with a sum of risks per color.",
                        fieldName: WidgetFilterKey.RiskMatrix.ShowSummary,
                        fieldType: GenericListFieldType.CheckBox,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: true,
                    },
                    {
                        prettyName: "Risk Scoring Type (Net / Gross)",
                        description:
                            "Select the scoring type that should be used to determine the 'Likelihood x Impact Scale' the risk is part of.",
                        fieldName: WidgetFilterKey.RiskMatrix.NetOrGross,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: 1,
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(RiskDataService);
                            return ds.getRiskScoringTypes();
                        },
                    },
                    {
                        prettyName: "Custom Impact Scales",
                        description:
                            "Select which labels should be used for labels of the Impact scales. This will disable summary click drilldown.",
                        fieldName: WidgetFilterKey.RiskMatrix.ImpactScale,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: 0,
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(RiskDataService);
                            return ds.getAvailableScales();
                        },
                    },
                    {
                        prettyName: "Risk Catalogue",
                        description:
                            "Filter risks on selected Risk Catalogue and show the appetite (if configured) in the matrix.",
                        fieldName: WidgetFilterKey.RiskMatrix.RiskCatalogue,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: 0,
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(StandingdataDataService);
                            return ds.getAllByType(StandingDataType.RiskCatalogue);
                        },
                    },
                    DashboardWidgetMapping._globalPeriodFilter,
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter.FrameworkDimension,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter
                        .FrameworkDimensionIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Period,
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                    WidgetFilterKey.GlobalFilter.FrameworkDimension,
                    WidgetFilterKey.GlobalFilter.FrameworkDimensionIncludeChildren,
                ],
            },
            "1300": {
                component: RisksPerCatalogueWidgetComponent,
                module: ModuleType.ORM,
                title: "Risk Catalogue Widget",
                description: "Graphical overview of risks per risk catalogue.",
                icon: "fas fa-book",
                right: "HasRiskAccess",
                showLoader: true,
                minCols: 3,
                minRows: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                legendConfigurable: true,
                customConfig: {
                    type: DashboardWidgetType.RiskPerCatalogueWidget,
                    globalFilterEnabled: true,
                    graphType: DashboardGraphType.pie,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                ],
            },
            "2010": {
                component: KriWidgetComponent,
                module: ModuleType.KRI,
                title: "KPI/KRI Widget",
                description: "Overview of KPI/KRIs",
                icon: "fad fa-tachometer-alt",
                right: "HasKriAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                legendConfigurable: true,
                customConfig: {
                    type: DashboardWidgetType.KriWidget,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.pie,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    {
                        prettyName: "KRI/KPI Type",
                        fieldName: WidgetFilterKey.Kri.KriType,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: 0,
                        getDataMethod: () => {
                            const values = Object.keys(KriWidgetTypeDescription)
                                .filter((value) => isNaN(Number(value)) === false)
                                .map(
                                    (key) =>
                                        new IdNameCombination(+key, KriWidgetTypeDescription[key])
                                );
                            return of(values);
                        },
                    },
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                ],
            },
            "2100": {
                component: KriDetailWidgetComponent,
                module: ModuleType.KRI,
                title: "KPI/KRI Detail Widget",
                description: "KPI/KRI Data points in line chart",
                icon: "fad fa-chart-line",
                right: "HasKriAccess",
                showLoader: true,
                minRows: 3,
                minCols: 4,
                hasGlobalOpen: true,
                customConfig: {
                    type: DashboardWidgetType.KriDetailWidget,
                    globalFilterEnabled: false,
                },
                configurations: [
                    {
                        prettyName: "KRI to show details of ",
                        fieldName: WidgetFilterKey.Kri.KriGuid,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: null,
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(KriDataService);
                            const kris = ds.getNameFilterModels();

                            return kris;
                        },
                    },
                ],
                globalFilterKeys: [],
            },
            "3010": {
                component: EventWidgetComponent,
                module: ModuleType.LER,
                title: "Event Widget",
                description: "Incidents overview",
                icon: "fad fa-bolt",
                right: "HasEventAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                customConfig: {
                    type: DashboardWidgetType.EventWidget,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.bar,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                ],
            },
            "4010": {
                component: ControlSimpleEffOverdueWidgetComponent,
                module: ModuleType.Controls,
                title: "Controls Overdue Widget",
                description: "All controls that are overdue are shown",
                icon: "fas fa-shield-alt",
                right: "HasControlAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                customConfig: {
                    type: DashboardWidgetType.SimpleEffOverdue,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.column,
                },
                configurations: [
                    {
                        prettyName: "Effectiveness or D&I overdue",
                        fieldName: WidgetFilterKey.ControlOverdue.EffOrDI,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: 0,
                        getDataMethod: () => {
                            const values = [
                                <IdNameCombination>{
                                    ID: 1,
                                    Name: "Simple effectiveness overdue",
                                },
                                <IdNameCombination>{
                                    ID: 2,
                                    Name: "Design implementation overdue",
                                },
                            ];

                            return of(values);
                        },
                    },
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter.FrameworkDimension,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter
                        .FrameworkDimensionIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                    WidgetFilterKey.GlobalFilter.FrameworkDimension,
                    WidgetFilterKey.GlobalFilter.FrameworkDimensionIncludeChildren,
                ],
            },
            "4020": {
                component: DesignImplementationWidgetComponent,
                module: ModuleType.Controls,
                title: "D&I Widget",
                description: "Design implementations per control are shown",
                icon: "fas fa-shield-alt",
                right: "HasControlAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [DashboardGraphType.bar, DashboardGraphType.column],
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.DesignImplementationScore,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.column,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter.FrameworkDimension,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter
                        .FrameworkDimensionIncludeChildren,
                    ,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                    WidgetFilterKey.GlobalFilter.FrameworkDimension,
                    WidgetFilterKey.GlobalFilter.FrameworkDimensionIncludeChildren,
                ],
            },
            "4100": {
                component: EffectivenessOverdueWidgetComponent,
                module: ModuleType.Controls,
                title: "Overdue Test Plans Widget",
                description: "All test plans that are overdue are shown",
                icon: "fas fa-shield-alt",
                right: "HasControlAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.EffectivenessOverdue,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.column,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                ],
            },
            "4500": {
                component: ControlEffTestingWidgetComponent,
                module: ModuleType.Controls,
                title: "Controls Effectiveness Testing Widget",
                description: "Effectiveness scores per control are shown",
                icon: "fas fa-shield-alt",
                right: "HasControlAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.EffectivenessTesting,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.column,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter.FrameworkDimension,
                    DashboardWidgetMapping._globalFrameworkDimensionFilter
                        .FrameworkDimensionIncludeChildren,
                    ,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                    WidgetFilterKey.GlobalFilter.FrameworkDimension,
                    WidgetFilterKey.GlobalFilter.FrameworkDimensionIncludeChildren,
                ],
            },
            "7010": {
                component: FormOverviewWidgetComponent,
                module: ModuleType.FRM,
                title: "Form Overview Widget",
                description: "Overview of selected forms that the user has access to.",
                icon: "fad fa-file-alt",
                showLoader: true,
                minRows: 2,
                minCols: 2,
                customConfig: {
                    type: DashboardWidgetType.FormOverviewWidget,
                    globalFilterEnabled: false,
                },
                configurations: [
                    ...DashboardWidgetMapping._defaultQuickLinkFilters,
                    {
                        prettyName: "Forms",
                        fieldName: WidgetFilterKey.Forms.FormIDs,
                        fieldType: GenericListFieldType.MultiSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: [],
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(FormsDataService);
                            return ds.getFormWidgetOverview(null).pipe(
                                map((forms) =>
                                    forms.map((f) => {
                                        return {
                                            ID: f.ID,
                                            Name: f.Title,
                                        };
                                    })
                                )
                            );
                        },
                    },
                    {
                        prettyName: "Link to new entry",
                        description:
                            "Start a new entry if allowed. If not allowed or disabled will link to result workspace.",
                        fieldName: WidgetFilterKey.Forms.LinkToNewFormEntry,
                        fieldType: GenericListFieldType.CheckBox,
                        configType: WidgetConfigType.WidgetConfig,
                        defaultValue: true,
                    },
                ],
                globalFilterKeys: [],
            },
            "13010": {
                component: FindingReportWidgetComponent,
                module: ModuleType.FR,
                title: "Findings Report Widget",
                description:
                    "Graphical overview of overall scores Findings reports per report type",
                icon: "fad fa-clipboard-list",
                right: "HasFindingReportAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                legendConfigurable: false,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                customConfig: {
                    type: DashboardWidgetType.FindingsReportWidget,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.column,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    {
                        prettyName: "Report type",
                        fieldName: WidgetFilterKey.FindingsReport.ReportType,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.DataFilter,
                        required: true,
                        defaultValue: null,
                        getDataMethod: () => {
                            const ds = AppConstants.AppInjector.get(FindingReportDataService);
                            const reportTypes = ds
                                .getReportTypes()
                                .pipe(
                                    map((x) =>
                                        x.map(
                                            (rt) =>
                                                <IdNameCombination>{ ID: rt.value, Name: rt.label }
                                        )
                                    )
                                );

                            return reportTypes;
                        },
                    },
                ],
                globalFilterKeys: [WidgetFilterKey.GlobalFilter.Organization],
            },
            "14010": {
                component: ProcessWidgetComponent,
                module: ModuleType.NAV,
                title: "Process Widget",
                description: "Visual view of the selected business dimension process.",
                icon: "fas fa-sitemap",
                right: "HasProcessViewerAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                customConfig: {
                    type: DashboardWidgetType.ProcessWidget,
                    globalFilterEnabled: false,
                },
                configurations: [
                    {
                        prettyName: "Link to",
                        fieldName: WidgetFilterKey.ProcessLink.LinkTo,
                        fieldType: GenericListFieldType.SingleSelect,
                        configType: WidgetConfigType.WidgetConfig,
                        required: true,
                        defaultValue: ProcessWidgetLink.BusinessNavigator,
                        getDataMethod: () => {
                            const values = Object.keys(ProcessWidgetLinkDescription)
                                .filter((value) => isNaN(Number(value)) === false)
                                .map(
                                    (key) =>
                                        new IdNameCombination(
                                            +key,
                                            ProcessWidgetLinkDescription[key]
                                        )
                                );
                            return of(values);
                        },
                    },
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                ],
            },
            "14100": {
                component: BusinessAssessmentWidgetComponent,
                module: ModuleType.NAV,
                title: "Business Assessment Widget",
                description: "Visual view of the scored Business Assessments.",
                icon: "fas fa-building",
                right: "HasBDManagementAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                workspacePresetModuleKey: [
                    ModuleType[ModuleType.NAV],
                    ModuleSubType[ModuleSubType.BusinessNavigator],
                ].join("-"),
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.BusinessAssessmentsWidget,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.pie,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    this._getWorkspacePresetList(
                        ModuleType[ModuleType.NAV],
                        ModuleSubType[ModuleSubType.BusinessNavigator]
                    ),
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                ],
            },
            "15010": {
                component: MoiWidgetComponent,
                module: ModuleType.IM,
                title: "Moi Widget",
                description: "Measure of Improvement overview",
                icon: "fad fa-tasks",
                right: "HasMoiAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [DashboardGraphType.bar, DashboardGraphType.column],
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.MoiWidget,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.bar,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                    {
                        prettyName: "MoI Types",
                        fieldName: WidgetFilterKey.Moi.MoiTypes,
                        fieldType: GenericListFieldType.MultiSelect,
                        configType: WidgetConfigType.DataFilter,
                        defaultValue: [],
                        getDataMethod: (): Observable<IdNameCombination[]> => of(getMoiTypes()),
                    },
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                ],
            },
            "18010": {
                component: ThirdPartyWidgetComponent,
                module: ModuleType.TP,
                title: "Third Party Widget",
                description: "Review scores of third parties",
                icon: "fad fa-handshake",
                right: "HasThirdPartyAccess",
                showLoader: true,
                minRows: 3,
                minCols: 3,
                hasGlobalOpen: true,
                allowedChartTypes: [
                    DashboardGraphType.bar,
                    DashboardGraphType.column,
                    DashboardGraphType.pie,
                ],
                legendConfigurable: false,
                customConfig: {
                    type: DashboardWidgetType.ThirdParty,
                    globalFilterEnabled: false,
                    graphType: DashboardGraphType.column,
                    legendType: DashboardLegendType.iconlabel,
                },
                configurations: [
                    DashboardWidgetMapping._globalOrganizationFilter.Organization,
                    DashboardWidgetMapping._globalOrganizationFilter.OrganizationIncludeChildren,
                    DashboardWidgetMapping._globalBusinessDimensionFilter.BusinessDimension,
                    DashboardWidgetMapping._globalBusinessDimensionFilter
                        .BusinessDimensionIncludeChildren,
                ],
                globalFilterKeys: [
                    WidgetFilterKey.GlobalFilter.Organization,
                    WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
                    WidgetFilterKey.GlobalFilter.BusinessDimension,
                    WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
                ],
            },
            "20010": {
                component: LinkWidgetComponent,
                module: ModuleType.COMMON,
                title: "Auditing",
                description: "Contains navigation options through the Audit Module.",
                icon: "fad fa-file-search",
                right: "auditing.hasModuleAccess",
                showLoader: true,
                minCols: 2,
                minRows: 2,
                customConfig: {
                    type: DashboardWidgetType.AuditOverview,
                    globalFilterEnabled: false,
                },
                configurations: [...DashboardWidgetMapping._defaultQuickLinkFilters],
            },
        };
    }

    private static _getWorkspacePresetList(
        module: string,
        appendToModule?: string
    ): WidgetConfigField {
        let moduleString = "" + module;
        if (appendToModule) {
            moduleString += "-" + appendToModule;
        }

        return {
            prettyName: "Workspace Preset",
            description:
                "Select the workspace preset that should be used as filter. All other filters will be ignored if this option is used. It is recommended to use public presets when sharing the dashboard.",
            fieldName: WidgetFilterKey.GlobalFilter.WorkspacePreset,
            fieldType: GenericListFieldType.SingleSelect,
            configType: WidgetConfigType.DataFilter,
            required: false,
            defaultValue: null,
            getDataMethod: () => {
                const ds = AppConstants.AppInjector.get(WorkspacePresetService);
                const presets = ds
                    .getPresets(moduleString)
                    .pipe(
                        map((configs) =>
                            configs.map(
                                (c) =>
                                    new IdNameCombination(
                                        c.id,
                                        c.name + (c.isPublic ? " (public)" : " (private)")
                                    )
                            )
                        )
                    );

                return presets;
            },
        };
    }

    private static _globalOrganizationFilter: {
        Organization: WidgetConfigField;
        OrganizationIncludeChildren: WidgetConfigField;
    } = {
        Organization: {
            prettyName: "Organization",
            fieldName: WidgetFilterKey.GlobalFilter.Organization,
            fieldType: GenericListFieldType.SingleTree,
            configType: WidgetConfigType.DataFilter,
            defaultValue: null,
            getDataMethodByRow: (row, ds) => {
                const selectedOrgId = row ? row[WidgetFilterKey.GlobalFilter.Organization] : null;
                return ds.getOrganizationsIncludingSelected(ModuleType.DSB, selectedOrgId);
            },
        },
        OrganizationIncludeChildren: {
            prettyName: "Include organization children",
            fieldName: WidgetFilterKey.GlobalFilter.OrganizationIncludeChildren,
            fieldType: GenericListFieldType.CheckBox,
            configType: WidgetConfigType.DataFilter,
            defaultValue: true,
            hideFillers: true,
        },
    };

    private static _globalBusinessDimensionFilter: {
        BusinessDimension: WidgetConfigField;
        BusinessDimensionIncludeChildren: WidgetConfigField;
    } = {
        BusinessDimension: {
            prettyName: "Business Dimension",
            fieldName: WidgetFilterKey.GlobalFilter.BusinessDimension,
            fieldType: GenericListFieldType.SingleTree,
            configType: WidgetConfigType.DataFilter,
            defaultValue: null,
            getDataMethodByRow: (row, ds) => {
                let orgid = row ? row[WidgetFilterKey.GlobalFilter.Organization] : null;
                if (!orgid) {
                    orgid = -1;
                }

                const selectedBdId = row
                    ? row[WidgetFilterKey.GlobalFilter.BusinessDimension]
                    : null;
                return ds.getBusinessDimensionsByOrganization(orgid, selectedBdId);
            },
            checkReloadDataByRowChanges(previous, current) {
                const keyToCheck = WidgetFilterKey.GlobalFilter.Organization;
                if (previous[keyToCheck] !== current[keyToCheck]) {
                    return true;
                }

                return false;
            },
        },
        BusinessDimensionIncludeChildren: {
            prettyName: "Include business dimension children",
            fieldName: WidgetFilterKey.GlobalFilter.BusinessDimensionIncludeChildren,
            fieldType: GenericListFieldType.CheckBox,
            configType: WidgetConfigType.DataFilter,
            defaultValue: true,
            hideFillers: true,
        },
    };

    private static _globalFrameworkDimensionFilter: {
        FrameworkDimension: WidgetConfigField;
        FrameworkDimensionIncludeChildren: WidgetConfigField;
    } = {
        FrameworkDimension: {
            prettyName: "Framework Dimension",
            fieldName: WidgetFilterKey.GlobalFilter.FrameworkDimension,
            fieldType: GenericListFieldType.SingleTree,
            configType: WidgetConfigType.DataFilter,
            defaultValue: null,
            getDataMethodByRow: (row, ds) => {
                let bdid = row ? row[WidgetFilterKey.GlobalFilter.BusinessDimension] : null;
                if (!bdid) {
                    bdid = -1;
                }

                const selectedFdId = row
                    ? row[WidgetFilterKey.GlobalFilter.FrameworkDimension]
                    : null;
                return ds.getFrameworkDimensions(bdid, selectedFdId);
            },
            checkReloadDataByRowChanges(previous, current) {
                const keyToCheck = WidgetFilterKey.GlobalFilter.BusinessDimension;
                if (previous[keyToCheck] !== current[keyToCheck]) {
                    return true;
                }

                return false;
            },
        },
        FrameworkDimensionIncludeChildren: {
            prettyName: "Include framework dimension children",
            fieldName: WidgetFilterKey.GlobalFilter.FrameworkDimensionIncludeChildren,
            fieldType: GenericListFieldType.CheckBox,
            configType: WidgetConfigType.DataFilter,
            defaultValue: true,
            hideFillers: true,
        },
    };

    private static _globalPeriodFilter: WidgetConfigField = {
        prettyName: "Period",
        fieldName: WidgetFilterKey.GlobalFilter.Period,
        fieldType: GenericListFieldType.SingleSelect,
        configType: WidgetConfigType.DataFilter,
        defaultValue: 0,
        getDataMethodByRow: (row: any, ds: StandingdataDataService) => {
            return ds.getPeriods(ModuleType.ORM);
        },
    };

    private static _defaultQuickLinkFilters: WidgetConfigField[] = [
        {
            prettyName: "Font size",
            fieldName: WidgetFilterKey.QuickLink.FontSize,
            fieldType: GenericListFieldType.Number,
            configType: WidgetConfigType.WidgetConfig,
            defaultValue: 16,
            required: true,
        },
        {
            prettyName: "Content of link",
            fieldName: WidgetFilterKey.QuickLink.LinkContent,
            fieldType: GenericListFieldType.SingleSelect,
            configType: WidgetConfigType.WidgetConfig,
            defaultValue: 10,
            required: true,
            getDataMethod: () => {
                const options: IdNameCombination[] = [
                    {
                        ID: QuickLinkItemContent.IconTitleDescription,
                        Name: "Icon, Title & Description",
                    },
                    {
                        ID: QuickLinkItemContent.IconTitle,
                        Name: "Icon & Title",
                    },
                    {
                        ID: QuickLinkItemContent.Icon,
                        Name: "Icon",
                    },
                ];

                return of(options);
            },
        },
    ];
}
