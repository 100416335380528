<div class="fancy-loader" *ngIf="risk && isLoading"></div>
<datamanagement-linkable
    *ngIf="risk && !isLoading"
    #linkableProcessingPurpose
    typename="data processing"
    displayValueProp="name"
    [linked]="linked"
    [unlinked]="[]"
    [orderByValueProperty]="true"
    [disabled]="true"
>
</datamanagement-linkable>
