<cerrix-workspace
    [tabID]="tabID"
    [cerrixTab]="tab"
    [data]="data"
    [headerLookup]="headerLookup"
    module="document"
    appendToModuleType="admin"
    [moduleType]="moduleType"
    idProp="guid"
    [permissions]="permissions"
    [filterConfig]="filterConfigs"
    [rendererConfig]="rendererConfig"
    [buttonConfig]="buttonConfig"
    [NumberProperties]="numberProps"
    [DateProperties]="dateProps"
    (onDblClick)="getFile($event)"
>
</cerrix-workspace>

<div
    class="modal fade"
    role="dialog"
    id="document-history-modal"
    data-backdrop="static"
    data-keyboard="false"
    #historyModal
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Document Viewer</h5>
            </div>
            <div class="container-fluid">
                <document-history [guid]="documentHistoryGuid"></document-history>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Done
                </button>
            </div>
        </div>
    </div>
</div>
