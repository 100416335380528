import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { UserMaintenanceService } from "@app/user/user-maintenance/services/user-maintenance.service";
import { UserAuditModel } from "@app/user/user-maintenance/shared/user-audit-model";
import { UserDetailModel } from "@app/user/user-maintenance/shared/user-detail-model";
import * as Highcharts from "highcharts";
declare var require: any;
require("highcharts/modules/map")(Highcharts);

@Component({
    selector: "user-audit",
    templateUrl: "./user-audit.component.html",
    styleUrls: ["./user-audit.component.scss"],
})
export class UserAuditComponent implements OnInit {
    @Input() user: UserDetailModel;
    @Input() audit: UserAuditModel;
    @Input() loadOnStartup: boolean;
    @ViewChild("chart", { static: true }) chart: ElementRef;

    totalAudits: number;
    chartRendered = false;

    constructor(private _ds: UserMaintenanceService) {}

    ngOnInit() {
        if (this.loadOnStartup) {
            this.load();
        }
    }

    load() {
        if (this.chartRendered) {
            return;
        }

        if (!this.audit) {
            this._ds.getAudit(this.user.guid).subscribe((a) => {
                this.audit = a;
                this.loadAll();
            });
        } else {
            this.loadAll();
        }
    }

    loadAll() {
        this.totalAudits =
            this.audit.totalSuccessfulLogins +
            this.audit.totalUnsuccessfulLogins +
            this.audit.totalPasswordResets +
            this.audit.totalPasswordChanges;
        this.createChart();
    }

    createChart() {
        this.chartRendered = true;

        const categories = [
            `Successful Logins (${this.audit.totalSuccessfulLogins})`,
            `Unsuccessful Logins (${this.audit.totalUnsuccessfulLogins})`,
            `Password Resets (${this.audit.totalPasswordResets})`,
            `Password Changes (${this.audit.totalPasswordChanges})`,
        ];

        Highcharts.chart(this.chart.nativeElement, {
            tooltip: {
                followPointer: true,
                formatter: function () {
                    return `<b>${this.point.name}</b>`;
                },
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    showInLegend: true,
                    size: "50%",
                    innerSize: "50%",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>",
                    },
                },
            },
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: "Amount",
                },
            },
            xAxis: {
                categories: categories,
                labels: {
                    rotation: 0,
                },
            },
            chart: {
                type: "pie",
            },
            series: [
                <Highcharts.SeriesBarOptions>{
                    data: [
                        {
                            name: categories[0],
                            color: "#33a24c",
                            y: this.audit.totalSuccessfulLogins,
                        },
                        {
                            name: categories[1],
                            color: "#c51b2c",
                            y: this.audit.totalUnsuccessfulLogins,
                        },
                        {
                            name: categories[2],
                            color: "#6610f2",
                            y: this.audit.totalPasswordResets,
                        },
                        {
                            name: categories[3],
                            color: "#f47c3c",
                            y: this.audit.totalPasswordChanges,
                        },
                    ],
                },
            ],
        });
    }
}
