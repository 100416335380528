import { Component, Input } from "@angular/core";
import {
    UserDetailModel,
    UserBaseAuthenticationModel,
    UserFormsAuthenticationModel,
} from "@app/user/user-maintenance/shared/user-detail-model";
import { UserStandingdataModel } from "@app/user/user-maintenance/shared/user-standingdata-model";
import { UserMaintenancePermissionModel } from "@models/permissions/UserMaintenancePermissionModel";
import { UserAuthenticationType } from "@app/user/user-maintenance/shared/user-authentication-type.enum";
import { UserAuthenticationService } from "@app/authentication/shared/user-authentication.service";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "user-logins",
    templateUrl: "./user-logins.component.html",
    styleUrls: ["./user-logins.component.scss"],
})
export class UserLoginsComponent {
    @Input() user: UserDetailModel;
    @Input() sd: UserStandingdataModel;
    @Input() permissions: UserMaintenancePermissionModel;

    authenticationType = UserAuthenticationType;

    showLoader = false;
    loaderMessage = "";
    validationMessages: string[];

    constructor(private _ds: UserAuthenticationService, private toastrService: ToastrService) {}

    copyEmail(method: UserBaseAuthenticationModel) {
        method.username = this.user.email;
    }

    addMethod(type: UserAuthenticationType) {
        if (type === UserAuthenticationType.FormsLogin) {
            if (this.user.formsAuthentications.length === 0) {
                this.user.formsAuthentications.push({
                    ID: this.user.formsAuthentications.getLowerIDByProp("ID", 1),
                    username: "",
                    disabled: false,
                });
            }
        } else if (type === UserAuthenticationType.AdLogin) {
            if (this.user.adAuthentications.length === 0) {
                this.user.adAuthentications.push({
                    ID: this.user.adAuthentications.getLowerIDByProp("ID", 1),
                    username: "",
                    disabled: false,
                });
            }
        }
    }

    removeMethod(type: UserAuthenticationType, authenticationID: number) {
        if (type === UserAuthenticationType.FormsLogin) {
            const formMethod = this.user.formsAuthentications.find(
                (fa) => fa.ID === authenticationID
            );
            if (formMethod) {
                const formIndex = this.user.formsAuthentications.indexOf(formMethod);
                this.user.formsAuthentications.splice(formIndex, 1);
            }
        } else if (type === UserAuthenticationType.AdLogin) {
            const adMethod = this.user.adAuthentications.find((fa) => fa.ID === authenticationID);
            if (adMethod) {
                const adIndex = this.user.adAuthentications.indexOf(adMethod);
                this.user.adAuthentications.splice(adIndex, 1);
            }
        }
    }

    async resetPasswordRequest(authModel: UserFormsAuthenticationModel) {
        if (authModel.ID > 0) {
            this.showLoader = true;
            await this._ds.passwordForgotRequest(authModel.username).toPromise();

            this.showLoader = false;
            this.toastrService.success(
                "A password reset link has been sent to the email of the user."
            );
        } else {
            this.validationMessages.push("<br> - This user is not valid for a password request.");
        }
    }
}
