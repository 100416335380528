<div class="detail-page-card">
    <span [ngClass]="{ 'fancy-loader': isLoadingDocuments }"> </span>

    <document-manager-old
        data-uiid="business-documentmanager"
        [(Documents)]="docModel.documents"
        (DocumentsChange)="checkDirty()"
        [permissions]="permissions.documentPermissions"
        [DocumentTypeTargets]="documentTypeTargets"
        Tooltip="Process documents are business dimension related documents and will be shown in all business assessments involving this business dimension. They will also be shown in the related process diagram."
    ></document-manager-old>
</div>
