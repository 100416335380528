import { Component, Input, OnInit } from "@angular/core";
import { DatamanagementConfigModel } from "@app/datamanagement/models/DatamanagementConfigModel";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { NavigatorConfigType } from "@app/navigators/enums/NavigatorConfigType";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "business-dataprocessing",
    templateUrl: "./business-dataprocessing.component.html",
})
export class BusinessDataprocessingComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissionsModel: BusinessNavigatorPermissionModel;
    config: GenericListConfig;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _navFactory: NavGenericListFactory,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        this.config = this._navFactory.createConfig(NavigatorConfigType.DataProcessing);

        this.config.dataMethod = () => this._businessDs.getDataProcessing(this.requestModel);
        this.config.hideRefreshButton = false;

        this.config.allowAdd = this.permissionsModel.canAddProcessingPurpose;
        this.config.addOverride = () => {
            const config = <DatamanagementConfigModel>{
                organizationId: this.requestModel.organizationID,
                businessId: this.requestModel.businessID,
            };

            this._tabService.generateTab(
                this._pages.DataManagement,
                0,
                "Add new Data Processing",
                config
            );
        };
    }
}
