import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ControlCatalogueModel } from "./ControlCatalogueModel";
import { ControlModel } from "@app/controls/models/ControlModel";
import { ControlPermissionModel } from "@app/controls/models/ControlPermissionsModel";
import { ControlStandingData } from "./ControlStandingData";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { isDirty, toPromise } from "@methods/CommonMethods";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ApplicationSettings } from "@services/http/settings/application-settings";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { forkJoin, Observable, of } from "rxjs";
import { ControlDetailMainDataService } from "@app/controls/detail/main/control-detail-main.data.service";
import { ControlRaciModel } from "../../models/ControlRaciModel";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import { getFormControl, getFormValue, nameof, setFormControlValue } from "@methods/jeffs-toolkit";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";
import { ControlStandingDataOrganizationBound } from "./ControlStandingDataOrganizationBound";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";

@Component({
    selector: "control-detail-main",
    templateUrl: "./control-detail-main.component.html",
    styleUrls: ["./control-detail-main.component.scss"],
})
export class ControlDetailMainComponent implements OnInit {
    @Input() cerrixTab: TabModel;
    @Input() controlModel: ControlModel;
    @Input() permissions: ControlPermissionModel;

    formDirty = false;
    tabIconBackup: string;

    backupModel: ControlModel;

    controlFormGroup: FormGroup;
    standingData: ControlStandingData;
    selectedOrganizationId: number;
    previousSelectedBusinessDimensionIds: number[] = [];
    previousSelectedOrganizationId: number;

    raciConfig: GenericListConfig;
    useControlAnalysis: boolean;

    customFieldData: { [key: number]: CustomFieldInfoModel } = {};
    controlCustomFieldsFormGroup: FormGroup;
    customFieldStandingDataModel: CustomFieldStandingDataModel[] = [];

    inPlaceChecked = false;
    customFieldsFormArray: FormArray<any>;

    constructor(
        private _controlDetailMainDS: ControlDetailMainDataService,
        private _settingsService: SettingsDataService,
        private _promptService: CerrixPromptService
    ) {}

    async ngOnInit(): Promise<void> {
        this._settingsService
            .getSetting(ApplicationSettings.UseControlAnalysis)
            .subscribe((value) => {
                this.useControlAnalysis = value.BoolValue;
            });

        let standingDataCalls: Observable<
            ControlStandingData | ControlStandingDataOrganizationBound
        >[] = [this._controlDetailMainDS.getStandingData(this.controlModel.Guid)];
        if (!this.controlModel.Guid && this.controlModel.Organization > 0) {
            standingDataCalls.push(
                this._controlDetailMainDS.getOrganizationStandingData(
                    this.controlModel.Organization
                )
            );
        }

        this.standingData = new ControlStandingData();
        const sdList = await forkJoin(standingDataCalls).toPromise();

        sdList.forEach((sd) => {
            for (const key in sd) {
                if (sd.hasOwnProperty(key) && sd[key]) {
                    this.standingData[key] = sd[key];
                }
            }
        });

        await this.createCustomFieldsFormGroup();
        await this.initControlDetails();
        this.backupModel = new ControlModel(JSON.stringify(this.controlModel));
    }

    async createCustomFieldsFormGroup() {
        this.customFieldsFormArray = new FormArray([]);

        this.customFieldStandingDataModel = await toPromise(
            this._controlDetailMainDS.getCustomFields()
        );

        // backend error, console error...
        if (!this.controlModel.CustomFields) this.controlModel.CustomFields = [];

        for (const field of this.customFieldStandingDataModel) {
            const answerField = this.controlModel.CustomFields.find(
                (x) => x.customFieldId === field.ID
            );

            const customFieldFormGroup = new FormGroup<any>({
                ID: new FormControl(answerField ? answerField.ID : 0),
                Name: new FormControl(field.Name),
                customFieldId: new FormControl(field.ID),
                required: new FormControl(field.required),
            });

            const valueProperty = getCustomFieldValueProperty(field);
            customFieldFormGroup.addControl(
                valueProperty,
                new FormControl(
                    answerField ? answerField[valueProperty] : null,
                    field.required ? [Validators.required] : []
                )
            );

            // Store extra information from the custom field in a custom object which is easier accessible in the front-end
            this.customFieldData[field.ID] = {
                Name: field.Name,
                fieldType: field.fieldType,
                required: field.required,
                answerOptions: field.answerOptions,
            };

            this.customFieldsFormArray.push(customFieldFormGroup);
        }

        this.controlCustomFieldsFormGroup = new FormGroup({
            customFields: this.customFieldsFormArray,
        });

        if (!this.permissions.CanEdit) {
            this.controlCustomFieldsFormGroup.disable();
        } else {
            this.controlCustomFieldsFormGroup.valueChanges.subscribe(() => {
                this.controlModel.CustomFields =
                    this.controlCustomFieldsFormGroup.value["customFields"];
            });
        }
    }

    async initControlDetails(): Promise<void> {
        this.cerrixTab.name = this.controlModel.Identifier
            ? `(C) ${this.controlModel.Identifier} - ${this.controlModel.Name}`
            : "(C) New Control";

        this.selectedOrganizationId = this.controlModel.Organization;

        // Only set this if it an existing Control is loaded.
        if (this.controlModel.Identifier) {
            this.previousSelectedOrganizationId = this.controlModel.Organization;
            this.previousSelectedBusinessDimensionIds = this.controlModel.BusinessDimensions;
        }

        await this.initFormValidation();
    }

    async initFormValidation(): Promise<void> {
        this.controlFormGroup = new FormGroup(this.getFormGroupControls(), {
            validators: [
                FormValidationHelper.validateStartEndDate<ControlModel>(
                    nameof<ControlModel>((c) => c.StartDate),
                    nameof<ControlModel>((c) => c.EndDate),
                    "End Date may not be before Start Date",
                    false
                ),
                FormValidationHelper.requiredIf<ControlModel>(
                    nameof<ControlModel>((c) => c.Owner),
                    nameof<ControlModel>((c) => c.InPlace)
                ),
            ],
        });

        this.controlFormGroup.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(this.controlFormGroup, this.controlModel);
        });

        if (!this.permissions.CanEdit) {
            this.controlFormGroup.disable();
        }

        await this.initRaciConfig();
    }

    private getFormGroupControls(): { [p: string]: AbstractControl } {
        const formGroupControls: { [p: string]: AbstractControl } = {};

        formGroupControls[nameof<ControlModel>((c) => c.Name)] = new FormControl(
            this.controlModel.Name,
            [Validators.required]
        );
        formGroupControls[nameof<ControlModel>((c) => c.Description)] = new FormControl(
            this.controlModel.Description
        );

        formGroupControls[nameof<ControlModel>((c) => c.Organization)] = new FormControl(
            this.controlModel.Organization,
            Validators.required
        );
        formGroupControls[nameof<ControlModel>((c) => c.ControlCatalogue)] = new FormControl(
            this.controlModel.ControlCatalogue
        );

        formGroupControls[nameof<ControlModel>((c) => c.Type)] = new FormControl({
            value: this.controlModel.Type,
            disabled:
                !this.controlModel.ControlCatalogueCanOverride &&
                (this.controlModel.ControlCatalogue ? true : false),
        });

        formGroupControls[nameof<ControlModel>((c) => c.Frequency)] = new FormControl(
            this.controlModel.Frequency
        );

        formGroupControls[nameof<ControlModel>((c) => c.Source)] = new FormControl(
            this.controlModel.Source
        );
        formGroupControls[nameof<ControlModel>((c) => c.AspectIc)] = new FormControl(
            this.controlModel.AspectIc
        );

        formGroupControls[nameof<ControlModel>((c) => c.ControlExecutionMethod)] = new FormControl(
            this.controlModel.ControlExecutionMethod
        );
        formGroupControls[nameof<ControlModel>((c) => c.ControlObjective)] = new FormControl(
            this.controlModel.ControlObjective
        );

        formGroupControls[nameof<ControlModel>((c) => c.Owner)] = new FormControl(
            this.controlModel.Owner
        );
        formGroupControls[nameof<ControlModel>((c) => c.InitialCosts)] = new FormControl(
            this.controlModel.InitialCosts
        );
        formGroupControls[nameof<ControlModel>((c) => c.AnnualCosts)] = new FormControl(
            this.controlModel.AnnualCosts
        );
        formGroupControls[nameof<ControlModel>((c) => c.ConclusionSummary)] = new FormControl(
            this.controlModel.ConclusionSummary
        );
        formGroupControls[nameof<ControlModel>((c) => c.Comments)] = new FormControl(
            this.controlModel.Comments
        );

        formGroupControls[nameof<ControlModel>((c) => c.BusinessDimensions)] = new FormControl({
            value: this.controlModel.BusinessDimensions,
            disabled: !this.controlModel.Organization,
        });
        formGroupControls[nameof<ControlModel>((c) => c.FrameworkDimensions)] = new FormControl({
            value: this.controlModel.FrameworkDimensions,
            disabled: !this.controlModel.Organization,
        });

        formGroupControls[nameof<ControlModel>((c) => c.KeyControl)] = new FormControl(
            this.controlModel.KeyControl
        );
        formGroupControls[nameof<ControlModel>((c) => c.RequiresMonitoring)] = new FormControl(
            this.controlModel.RequiresMonitoring
        );
        formGroupControls[nameof<ControlModel>((c) => c.RequiresExecution)] = new FormControl(
            this.controlModel.RequiresExecution
        );
        formGroupControls[nameof<ControlModel>((c) => c.InPlace)] = new FormControl(
            this.controlModel.InPlace
        );
        formGroupControls[nameof<ControlModel>((c) => c.IsShared)] = new FormControl(
            this.controlModel.IsShared
        );
        formGroupControls[nameof<ControlModel>((c) => c.StartDate)] = new FormControl(
            this.controlModel.StartDate
        );
        formGroupControls[nameof<ControlModel>((c) => c.EndDate)] = new FormControl(
            this.controlModel.EndDate
        );
        formGroupControls[nameof<ControlModel>((c) => c.ApplicableOrganizations)] = new FormControl(
            {
                value: this.controlModel.ApplicableOrganizations,
                disabled: true,
            }
        );
        formGroupControls[nameof<ControlModel>((c) => c.CustomFields)] = this.customFieldsFormArray;

        return formGroupControls;
    }

    async initRaciConfig(): Promise<void> {
        const useRaci = (
            await this._settingsService.getSetting(ApplicationSettings.UseRaciTable).toPromise()
        ).BoolValue;
        if (!useRaci) {
            return;
        }

        this.raciConfig = <GenericListConfig>{
            name: "RACI",
            permissionProp: nameof<ApplicationPermissionsModel>((perms) => perms.HasControlAccess),
            isSortable: false,

            allowAdd: this.permissions.CanEdit,
            allowEdit: this.permissions.CanEdit,
            allowDelete: this.permissions.CanEdit,

            fields: [
                {
                    fieldName: "Function",
                    fieldType: GenericListFieldType.SingleSelect,
                    required: true,
                    getDataMethod: () => {
                        return of(this.standingData.RACIFunctions);
                    },
                },
                this.createCheckBox("Responsible"),
                this.createCheckBox("Accountable"),
                this.createCheckBox("Consulted"),
                this.createCheckBox("Informed"),
            ],

            data: this.controlModel.Raci,
            dataChanged: (data: ControlRaciModel[]) => {
                this.controlModel.Raci = data;
            },
        };
    }

    checkDirty(): boolean {
        const detailsDirty = this.controlFormGroup.dirty || this.controlCustomFieldsFormGroup.dirty;
        const linksDirty = isDirty(this.backupModel.getLinks(), this.controlModel.getLinks());
        this.formDirty = detailsDirty || linksDirty;
        TabComponentHelper.toggleTabDirty(this.cerrixTab, "menuDetails", this.formDirty);
        return this.formDirty;
    }

    async reloadBusinessData(): Promise<void> {
        const org = getFormValue<ControlModel>(this.controlFormGroup, (cm) => cm.Organization);
        if (this.previousSelectedOrganizationId === org) {
            this.selectedOrganizationId = org;
            return;
        } else if (!org) {
            getFormControl<ControlModel>(
                this.controlFormGroup,
                (c) => c.BusinessDimensions
            ).disable();

            getFormControl<ControlModel>(
                this.controlFormGroup,
                (c) => c.FrameworkDimensions
            ).disable();

            this.selectedOrganizationId = null;

            return;
        }

        FormValidationHelper.mapToModel(this.controlFormGroup, this.controlModel);
        // no extra validation is needed when no bds or fwds are selected -> start reload
        if (
            this.controlModel.BusinessDimensions.length === 0 &&
            this.controlModel.FrameworkDimensions.length === 0 &&
            this.controlModel.RiskIdList.length == 0
        ) {
            this.reloadOrganizationDependencies(org);
            return;
        }

        const msg = await this._controlDetailMainDS
            .validateOrganizationChange(this.controlModel)
            .toPromise();
        if (!msg || (msg.invalidRisks.length == 0 && msg.messages.length == 0)) {
            this.reloadOrganizationDependencies(org);
            return;
        }

        //If there are errors, we have to notify the user
        if (msg.invalidRisks.length > 0) {
            let message =
                "There are linked risk(s) blocking the organization change. Please remove the following risk(s) before changing the organization:\n\n";
            msg.messages.forEach((m) => (message = message + m + "\n"));

            this.createConfirm("Cannot change organization!", message);
            setFormControlValue<ControlModel>(
                this.controlFormGroup,
                (c) => c.Organization,
                this.previousSelectedOrganizationId
            );

            return;
        }

        // Only case left is msg.messages.length > 0, so we ask user if they want to remove the invalid items
        let message =
            "Due to links with business dimension(s) this organization cannot be changed. Do you want to remove the following item(s) before changing the organization? :\n\n";
        msg.messages.forEach((m) => (message = message + m + "\n"));
        const confirmed = await this.createConfirm("Cannot change business dimensions!", message)
            .getResult()
            .toPromise();

        if (confirmed) {
            this.updateBusinessDimension(msg.invalidBusinessDimensions);
            this.updateFrameworkDimension(msg.invalidFrameworkDimensions);
            this.reloadOrganizationDependencies(org);
        } else {
            setFormControlValue<ControlModel>(
                this.controlFormGroup,
                (c) => c.Organization,
                this.previousSelectedOrganizationId
            );
        }
    }

    updateBusinessDimension(invalidBD: number[]): void {
        const previousBd = this.controlModel.BusinessDimensions;
        for (const ifd of invalidBD) {
            const index = previousBd.indexOf(ifd);
            if (index !== -1) {
                previousBd.splice(index, 1);
            }
        }

        this.controlModel.BusinessDimensions = previousBd;
    }

    async reloadFrameworkDimensions(bds: number[]): Promise<void> {
        if (!bds || !bds.isDifferent(this.previousSelectedBusinessDimensionIds)) {
            return;
        }

        FormValidationHelper.mapToModel(this.controlFormGroup, this.controlModel);

        const msg = await this._controlDetailMainDS
            .validateBusinessDimensionChange(this.controlModel)
            .toPromise();
        if (!msg || msg.messages.length == 0) {
            this.reloadBusinessDimensionDependencies(bds);
            return;
        }

        // only invalid FD and Process Diagrams
        let message = "These items can't be deleted, ";
        const hasInvalidFrameworkDimensions = msg.invalidFrameworkDimensions?.length > 0;
        const hasInvalidProcesses = msg.invalidProcesses?.length > 0;

        let linksDetailMessage: string;
        if (hasInvalidFrameworkDimensions && hasInvalidProcesses) {
            linksDetailMessage =
                "framework dimensions and process diagrams (which will be removed on save). ";
        } else {
            if (hasInvalidFrameworkDimensions) {
                linksDetailMessage = "framework dimensions. ";
            } else {
                linksDetailMessage = "process diagrams (which will be removed on save). ";
            }
        }
        message +=
            "due to links with " +
            linksDetailMessage +
            "\nDo you want to change the business dimensions and remove the following item(s)? :\n\n";
        msg.messages.forEach((m) => (message = message + m + "\n"));

        const confirmed = await this.createConfirm("Cannot change business dimensions!", message)
            .getResult()
            .toPromise();
        if (confirmed) {
            this.updateFrameworkDimension(msg.invalidFrameworkDimensions);
            this.reloadBusinessDimensionDependencies(bds);
        } else {
            setFormControlValue<ControlModel>(
                this.controlFormGroup,
                (c) => c.BusinessDimensions,
                this.previousSelectedBusinessDimensionIds
            );
        }
    }

    updateFrameworkDimension(invalidFd: number[]): void {
        const previousFd = this.controlModel.FrameworkDimensions;
        for (const ifd of invalidFd) {
            const index = previousFd.indexOf(ifd);
            if (index !== -1) {
                previousFd.splice(index, 1);
            }
        }

        this.controlModel.FrameworkDimensions = previousFd;
    }

    reloadOrganizationDependencies(org: number): void {
        this.selectedOrganizationId = org;
        this.previousSelectedOrganizationId = org;

        const selectedBusinessDimensions = getFormValue<ControlModel>(
            this.controlFormGroup,
            (c) => c.BusinessDimensions
        );

        this._controlDetailMainDS
            .getOrganizationStandingData(this.selectedOrganizationId)
            .subscribe((x) => {
                this.standingData.BusinessDimensions = x.BusinessDimensions;
                this.standingData.FrameworkDimensions = x.FrameworkDimensions;
                this.standingData.ControlOwners = x.ControlOwners;

                const businessDimensionFormControl = getFormControl<ControlModel>(
                    this.controlFormGroup,
                    (c) => c.BusinessDimensions
                );
                businessDimensionFormControl.setValue(selectedBusinessDimensions);
                businessDimensionFormControl.enable();

                this.reloadBusinessDimensionDependencies(selectedBusinessDimensions);
            });
    }

    reloadBusinessDimensionDependencies(bds: number[]): void {
        this.previousSelectedBusinessDimensionIds = bds;

        const selectedFrameworkDimensions = getFormValue<ControlModel>(
            this.controlFormGroup,
            (c) => c.FrameworkDimensions
        );

        this._controlDetailMainDS
            .getFrameworkDimensionStandingData(this.controlModel)
            .subscribe((x) => {
                this.standingData.FrameworkDimensions = x;

                const frameworkDimensionsFormControl = getFormControl<ControlModel>(
                    this.controlFormGroup,
                    (c) => c.FrameworkDimensions
                );
                frameworkDimensionsFormControl.setValue(selectedFrameworkDimensions);
                frameworkDimensionsFormControl.enable();
            });
    }

    onControlCatalogueChange(item: IdNameCombination): void {
        if (!item) {
            const typeControl = getFormControl<ControlModel>(this.controlFormGroup, (c) => c.Type);
            typeControl.enable();

            return;
        }

        this.cerrixTab.showLoader = true;
        this._controlDetailMainDS.getControlCatalogue(item.ID).subscribe((cat) => {
            this.setControlCataloguesDefaults(cat);
            this.cerrixTab.showLoader = false;
        });
    }

    private setControlCataloguesDefaults(cat: ControlCatalogueModel): void {
        setFormControlValue<ControlModel>(this.controlFormGroup, (c) => c.Type, cat.Type);
        setFormControlValue<ControlModel>(this.controlFormGroup, (c) => c.Source, cat.Source);

        if (!this.controlModel.Description) {
            setFormControlValue<ControlModel>(
                this.controlFormGroup,
                (c) => c.Description,
                cat.Description
            );
        }

        if (this.controlModel.Organization) {
            setFormControlValue<ControlModel>(
                this.controlFormGroup,
                (c) => c.FrameworkDimensions,
                cat.FrameworkDimensionIds
            );
        }

        const typeControl = getFormControl<ControlModel>(this.controlFormGroup, (c) => c.Type);
        cat.TypeOverwritable ? typeControl.enable() : typeControl.disable();
    }

    checkboxChanged(checked: boolean): void {
        this.inPlaceChecked = checked;
    }

    checkIfSharedHasChanged(): boolean {
        return !this.backupModel.IsShared && this.controlModel.IsShared;
    }

    fetchDescriptions = (): Promise<string[]> => {
        FormValidationHelper.mapToModel(this.controlFormGroup, this.controlModel);

        return toPromise(this._controlDetailMainDS.generateDescription(this.controlModel));
    };

    onDescriptionGenerated(description: string): void {
        const formControl = getFormControl<ControlModel>(
            this.controlFormGroup,
            (c) => c.Description
        );
        formControl.setValue(description);
    }

    private createCheckBox(name: string): GenericListField {
        return {
            fieldName: name,
            fieldType: GenericListFieldType.CheckBox,
            editorWidth: 6,
        };
    }

    private createConfirm(title: string, body: string): CerrixPromptComponent {
        return this._promptService.confirmCustom({
            maxWidth: "450px",
            maxHeight: "350px",
            data: {
                title: title,
                message: body,
            },
        });
    }
}
