import { Component } from "@angular/core";
import { ApiSpecificationModel } from "@app/manual/models/api-models/api-specification-model";
import { ApiSpecComponent } from "../../api-spec";

@Component({
    selector: "control-ready-for-assessment-api-manual",
    templateUrl: "../../api-spec.html",
    styleUrls: ["../../api-spec.scss"],
})
export class ControlReadyForAssessmentApiManualComponent extends ApiSpecComponent {
    initSpecs() {
        return <ApiSpecificationModel>{
            name: "Ready for Assessment API",
            httpMethod: "POST",
            path: "/controls/testing/advanced/effectiveness/{effectivenessGuid}/automation/workflow/readyforassessment",

            parameters: [
                {
                    name: "effectivenessGuid",
                    in: "path",
                    paramType: "uuid",
                    required: true,
                    example: "00000000-0000-0000-0000-000000000000",
                },
            ],

            responses: [
                {
                    code: "200",
                    description: "OK",
                },
            ],
        };
    }
}
