<div class="treetable-container" #treeTableContainer>
    <ejs-treegrid
        #treegrid
        [dataSource]="config.data"
        [treeColumnIndex]="config.rowButtonsFirst ? 1 : 0"
        [idMapping]="config.idProperty"
        [childMapping]="config.childProperty"
        [height]="scrollHeight"
        [rowHeight]="rowHeight"
        [allowReordering]="config.allowReordering"
        [allowResizing]="true"
        [allowFiltering]="true"
        [allowSorting]="true"
        [showColumnMenu]="true"
        [enableCollapseAll]="true"
        [enableHover]="true"
        [filterSettings]="filterSettings"
        [editSettings]="editSettings"
        (queryCellInfo)="queryCellInfo($event)"
        (click)="click($event)"
        (actionComplete)="actionCompleted($event)"
        *ngIf="ready"
    >
        <e-columns>
            <ng-container *ngIf="config.rowButtonsFirst && config.rowButtons">
                <e-column
                    headerText="Actions"
                    textAlign="Center"
                    [allowEditing]="false"
                    [allowResizing]="false"
                    [width]="config.rowButtons.length * 40"
                >
                    <ng-template #template let-data>
                        <i
                            *ngFor="let btn of config.rowButtons"
                            [class]="'cellbtn ' + btn.icon"
                            [title]="btn.tooltip"
                            (click)="btn.clickEvent(data)"
                            [attr.data-uiid]="btn.dataUiid"
                        ></i>
                    </ng-template>
                </e-column>
            </ng-container>
            <ng-container *ngFor="let col of columns">
                <e-column
                    [field]="col.fieldName"
                    [headerText]="col.prettyName"
                    [edit]="col.editOptions"
                    [editType]="col.editType"
                    [format]="col.formatOptions"
                    [displayAsCheckBox]="col.displayAsCheckBox"
                    [width]="col.columnWidth"
                    [freeze]="col.freezeDirection"
                    [allowEditing]="!col.readonly"
                    [visible]="col.visible"
                >
                </e-column>
            </ng-container>
            <ng-container *ngIf="!config.rowButtonsFirst && config.rowButtons">
                <e-column
                    headerText="Actions"
                    textAlign="Center"
                    [allowEditing]="false"
                    [allowResizing]="false"
                    [width]="config.rowButtons.length * 40"
                >
                    <ng-template #template let-data>
                        <i
                            *ngFor="let btn of config.rowButtons"
                            [class]="'cellbtn ' + btn.icon"
                            [title]="btn.tooltip"
                            (click)="btn.clickEvent(data)"
                            [attr.data-uiid]="btn.dataUiid"
                        ></i>
                    </ng-template>
                </e-column>
            </ng-container>
        </e-columns>
    </ejs-treegrid>
</div>
