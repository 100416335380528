import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, afterNextRender } from "@angular/core";
import { ChatCommentModel } from "../models/chat-comment-model";
import { DateTimeDisplayPipe } from "@app/shared/pipes/date-time-display.pipe";

@Component({
    selector: "chat-comment",
    templateUrl: "./chat-comment.component.html",
    styleUrls: ["./chat-comment.component.scss"],
    standalone: true,
    imports: [CommonModule, DateTimeDisplayPipe],
    providers: [],
})
export class ChatCommentComponent implements OnInit {
    @Input() public userColor: string;
    @Input() charactersLimitClip: number = 200;
    @Input() public comment: ChatCommentModel;

    @Input() triggerAfterRendered: boolean = false;
    @Output() afterRendered = new EventEmitter<void>();

    protected clippedText: string | null = null;
    protected showMoreLessButton: boolean = false;
    protected showMore: boolean = true;

    constructor() {
        afterNextRender({
            read: () => {
                if (this.triggerAfterRendered) {
                    this.afterRendered.emit();
                }
            },
        });
    }

    ngOnInit() {
        this.setClippedText();
    }

    private setClippedText() {
        let displayedText = this.comment?.value ?? "";

        if (displayedText.length > this.charactersLimitClip) {
            this.showMoreLessButton = true;
            displayedText = displayedText.slice(0, this.charactersLimitClip);
        }

        const lines = displayedText.split("\n");
        if (lines.length > 6) {
            this.showMoreLessButton = true;
            displayedText = lines.slice(0, 5).join("\n");
        }

        if (this.showMoreLessButton) {
            this.clippedText = displayedText + "...";
        }
    }

    protected onToggleMore() {
        this.showMore = !this.showMore;
    }
}
