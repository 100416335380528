import { SampleGenerationType } from "../enums/sample-generation-type.enum";
import { SampleType } from "../enums/sample-type.enum";

export class WorkflowGenerateSamplesModel {
    sampleType: SampleType;
    documentGuid: string;
    populationSize = 0;
    numberOfTestSamples = 0;
    numberSamples: number[];
    dateSamples: Date[];
    textSamples: string[];

    // Used in frontend
    sampleGenerationType?: SampleGenerationType;
    maxPopulationSize?: number;
    sampleTypeOptions?: any[];
    idColumn?: number;
    rowsToSkip?: number;
    documentID?: number;
}
