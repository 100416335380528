import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { RendererType } from "@enums/RendererType";
import { FormatType } from "@enums/FormatType";
import { TabService } from "@services/tabs/TabService";
import { Pages } from "@constants/pages/Pages";
import { MoiDataService } from "../services/moi-data.service";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { ToastrService } from "ngx-toastr";
import { CerrixTableComponent } from "@app/shared/cerrix-table/cerrix-table.component";
import { MoiConfig } from "@models/moi/MoiConfig";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { nameof } from "@methods/CommonMethods";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";

@Component({
    selector: "linked-moi-overview",
    templateUrl: "./moi-linked-overview.component.html",
    styleUrls: ["./moi-linked-overview.component.scss"],
})
export class LinkedMoiOverviewComponent implements OnInit {
    @Input() moiType: MoiTypes;
    @Input() parentGuid: string;
    @Input() assessmentGuid: string;
    @Input() useLazyLoading = true;

    @Input() canAdd: boolean;
    @Input() canDelete: boolean;

    @ViewChild("cerrixTableComponent", { static: true }) cerrixTableComponent: CerrixTableComponent;

    rendererConfig: RendererConfig[];
    overviewConfig: GenericListConfig;
    headerLookup: any = {};
    linkedMois: LinkedMoiModel[];
    activeRow: any = null;

    numberProps: string[] = ["Progress"];
    dateProps: string[] = ["Date status changed", "Due Date"];

    constructor(
        private tabService: TabService,
        private _moiDS: MoiDataService,
        private pages: Pages,
        private _toastr: ToastrService,
        private _promptService: CerrixPromptService
    ) {
        this.overviewConfig = <GenericListConfig>{
            idProp: "guid",
            fields: [
                {
                    fieldName: nameof<LinkedMoiModel>("id"),
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("guid"),
                    hideInOverview: true,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("identifier"),
                    overviewSortOrder: 0,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("name"),
                    prettyName: "Name",
                    overviewSortOrder: 1,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("organizationResponsible"),
                    prettyName: "Organization responsible",
                    overviewSortOrder: 3,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("status"),
                    prettyName: "Status",
                    overviewSortOrder: 4,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("dateStatusChanged"),
                    prettyName: "Date status changed",
                    overviewSortOrder: 5,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("responsible"),
                    prettyName: "Responsible",
                    overviewSortOrder: 6,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("dueDate"),
                    prettyName: "Due Date",
                    overviewSortOrder: 7,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("isOverdue"),
                    prettyName: "Is Overdue",
                    overviewSortOrder: 8,
                },
                {
                    fieldName: nameof<LinkedMoiModel>("progress"),
                    prettyName: "Progress",
                    overviewSortOrder: 9,
                },
            ],
        };

        this.rendererConfig = [
            {
                textColumn: nameof<LinkedMoiModel>("dateStatusChanged"),
                type: RendererType.Default,
                formatType: FormatType.DateFormat,
            },
            {
                textColumn: nameof<LinkedMoiModel>("dueDate"),
                actionColumn: nameof<LinkedMoiModel>("isOverdue"),
                actionValue: "Yes",
                type: RendererType.DueDate,
                formatType: FormatType.DateFormat,
            },
            {
                textColumn: nameof<LinkedMoiModel>("progress"),
                type: RendererType.Progress,
            },
        ];
    }

    ngOnInit() {
        if (!this.useLazyLoading) {
            this.load(true);
        }
    }

    openMoi(moi: LinkedMoiModel) {
        if (moi.canOpen === true) {
            this.generateMoiTab(moi);
        } else if (moi.canOpen === false) {
            this.showUnableToOpenWarning();
        } else {
            this._moiDS.hasAccessToMoi(moi.guid).subscribe((hasAccess) => {
                // Store the result in the moi
                if (hasAccess) {
                    moi.canOpen = true;

                    this.generateMoiTab(moi);
                } else {
                    moi.canOpen = false;

                    this.showUnableToOpenWarning();
                }
            });
        }
    }

    private generateMoiTab(moi: LinkedMoiModel) {
        this.tabService.generateTab(
            this.pages.MoiDetail,
            moi.guid,
            `(M) ${+moi.identifier} - ${moi.name}`
        );
    }

    private showUnableToOpenWarning() {
        this._toastr.warning("Not enough rights to open this MoI.");
    }

    async addMoi() {
        const sourceID = await this._moiDS
            .getSourceIdByGuid(this.moiType, this.parentGuid)
            .toPromise();
        if (!sourceID || sourceID <= 0) {
            this._toastr.error("Source not found!");
            return;
        }

        const config = <MoiConfig>{
            MoiType: this.moiType,
            ParentId: sourceID,
            ParentGuid: this.parentGuid,
            AssessmentGuid: this.assessmentGuid,
        };

        this.tabService.generateTab(
            this.pages.MoiDetail,
            null,
            `(M) New Measure of Improvement`,
            config
        );
    }

    deleteMoi() {
        if (this.activeRow == null) {
            this._toastr.error("Select an MoI first", "Unable to delete MoI");
            return;
        }

        const moi = this.activeRow as LinkedMoiModel;
        this._promptService
            .confirm(
                "Delete MoI",
                `Are you sure you want to delete '${moi.identifier} - ${moi.name}'?`
            )
            .onConfirm()
            .subscribe(() => {
                this._moiDS.deleteMoi(moi.guid).subscribe((x) => {
                    this.load(true);
                });
            });
    }

    load(refresh: boolean): void {
        if (this.linkedMois && !refresh) {
            return;
        }

        this.linkedMois = null;
        this.activeRow = null;

        this._moiDS
            .getLinkedMois(this.assessmentGuid || this.parentGuid, this.moiType)
            .subscribe((d) => {
                this.linkedMois = d;

                this.setHeaders();
                this.cerrixTableComponent.setData(this.linkedMois);
            });
    }

    private setHeaders() {
        if (this.linkedMois && this.linkedMois.length > 0) {
            Object.getOwnPropertyNames(this.linkedMois[0]).forEach((key) => {
                if (!this.headerLookup[key]) {
                    this.headerLookup[key] = key;
                }
            });
        }
    }
}
