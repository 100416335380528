<ng-template #basicInfo>
    <form [formGroup]="nameGroup" *ngIf="nameGroup">
        <div class="row header-row">
            <div class="col">
                <label>Basic information</label>
            </div>
        </div>
        <div class="row centered-row">
            <div class="col left-block">
                <div class="form-group">
                    <label>Name Third Party Assurance Connection *</label>
                    <cerrix-textfield
                        dataUiid="tpa-name"
                        identifier="name"
                        formControlName="name"
                        placeholder="Required"
                        inputTooltip
                    >
                    </cerrix-textfield>
                </div>
                <div class="form-group">
                    <label>Description Third Party Assurance Connection</label>
                    <cerrix-textarea
                        dataUiid="tpa-description"
                        identifier="description"
                        formControlName="description"
                    ></cerrix-textarea>
                </div>
            </div>
            <div class="col right-block">
                <div class="form-group">
                    <label>Third Party Assurance Employee</label>
                    <cerrix-textfield
                        dataUiid="tpa-employee"
                        identifier="employee"
                        formControlName="employee"
                        inputTooltip
                    >
                    </cerrix-textfield>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #apiKey>
    <form [formGroup]="apiKeyGroup" *ngIf="apiKeyGroup">
        <div class="row header-row">
            <div class="col">
                <label>API key details</label>
            </div>
        </div>
        <div class="row centered-row">
            <div class="col left-block">
                <div class="form-group">
                    <label>Expiration date *</label>
                    <date-input
                        formControlName="expirationDate"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                    ></date-input>
                    <div class="red" *ngIf="apiKeyGroup.controls.expirationDate.errors">
                        <p *ngIf="apiKeyGroup.controls.expirationDate.errors.expirationDate">
                            {{ apiKeyGroup.controls.expirationDate.errors.expirationDate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col right-block">
                <div class="form-group"></div>
            </div>
        </div>
        <div *ngIf="apiSoftIpActive" class="row centered-row">
            <div class="col left-block">
                <div class="form-group">
                    <label>IP Restriction *</label>
                    <cerrix-textarea
                        dataUiid="tpa-iprestriction"
                        identifier="ipRestriction"
                        formControlName="ipRestriction"
                        placeholder="Required"
                        required
                        inputTooltip
                    ></cerrix-textarea>
                </div>
            </div>
            <div class="col right-block">
                <div class="form-group">
                    <label></label>
                    <div>
                        Please fill IP restriction: <br />
                        Single: 192.168.0.1 <br />
                        Multiple: 192.168.0.1;192.168.0.2<br />
                        Range: 192.168.0.1-192.168.0.3<br />
                        CIDR: 192.168.0.1/32
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #controls>
    <form [formGroup]="controlGroup" *ngIf="controlGroup">
        <div class="row header-row">
            <div class="col">
                <label>Controls</label>
            </div>
        </div>
        <div class="row centered-row">
            <tpa-control-linkable
                typename="controls"
                [displayValueProp]="displayProperty"
                [orderByValueProperty]="true"
                [linked]="linked"
                [unlinked]="unlinked"
                [disabled]="disabled"
                [hideFilter]="false"
                [rowIconConfig]="rowIconConfig"
                [rendererConfig]="rendererConfig"
                #linkable
            >
                ></tpa-control-linkable
            >
        </div>
    </form>
</ng-template>

<ng-template #testInformation>
    <form [formGroup]="testInformationGroup" *ngIf="testInformationGroup">
        <div class="row header-row">
            <div class="col">
                <label>Test information</label>
            </div>
        </div>
        <div class="row centered-row">
            <div class="col left-block">
                <div class="form-group">
                    <label>Testplan type</label>
                    <ng-select
                        bindValue="ID"
                        bindLabel="Name"
                        formControlName="testplanType"
                        [items]="[{ ID: 1, Name: 'Effectiveness' }]"
                        [clearable]="false"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col right-block">
                <div class="form-group">
                    <label>Range test dates</label>
                    <div class="row centered-row">
                        <div class="col">
                            <div class="form-group">
                                <date-input formControlName="startDate"></date-input>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <date-input formControlName="endDate"></date-input>
                            </div>
                        </div>
                        <div class="red" *ngIf="testInformationGroup.controls.startDate.errors">
                            <p
                                *ngIf="
                                    testInformationGroup.controls.startDate.errors
                                        .startDateAfterendDate
                                "
                            >
                                {{
                                    testInformationGroup.controls.startDate.errors
                                        .startDateAfterendDate
                                }}
                            </p>
                        </div>
                        <div class="detail-page-action-bar">
                            <span class="date-edited"
                                >All testplans with a start date within the given period will be
                                send to the assurance party.</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!-- Wizard -->
<cerrix-wizard
    [config]="wizardConfig"
    *ngIf="wizardConfig"
    [templates]="[basicInfo, apiKey, controls, testInformation]"
    [summaryTemplate]="summary"
    #wizard
>
</cerrix-wizard>
