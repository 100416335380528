import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "@app/app.constants";
import { isGuid } from "@methods/uniqueMethods";
import { HistoryChange, HistoryModel } from "@models/history/HistoryModel";
import { LinkedMoiModel } from "@models/moi/LinkedMoiModel";
import { ApiDataService } from "@services/http/ApiDataService";
import { HistoryDataService } from "@services/http/history/HistoryDataService";
import { Observable, of } from "rxjs";
import { AuditFindingInfoModel } from "../shared/data-models/audit-finding-info-model";
import { AuditFindingLinkedAuditInfoModel } from "../shared/data-models/audit-finding-linked-audit-info-model";
import { AuditFindingModel } from "../shared/data-models/audit-finding-model";
import { AuditFindingPermissionModel } from "../shared/page-models/audit-finding-permission-model";

@Injectable()
export class AuditFindingDataService extends ApiDataService implements HistoryDataService {
    constructor(protected http: HttpClient, protected _configuration: Configuration) {
        super(http, _configuration, "audit/finding");
    }

    //#region CRUD calls

    getList() {
        return this.get<AuditFindingInfoModel[]>();
    }

    getObject(guid: string) {
        const url = "/" + guid;

        return this.get<AuditFindingModel>(url);
    }

    storeAuditFinding(model: AuditFindingModel): Observable<string> {
        return this.post(model);
    }

    deleteAuditFinding(guid: string): Observable<string[]> {
        const url = "/" + guid + "/delete";

        return this.delete(url);
    }
    //#endregion CRUD calls

    //#region Support data calls

    public getPermissions(guid: string): Observable<AuditFindingPermissionModel> {
        return this.get<AuditFindingPermissionModel>(`/${guid}/permissions`);
    }

    public getPermissionsByAudit(auditGuid: string): Observable<AuditFindingPermissionModel> {
        return this.get<AuditFindingPermissionModel>(`/permissions?auditGuid=${auditGuid}`);
    }

    getLinkedAuditInfo(guid: string) {
        const url = "/" + guid + "/auditlinkinfo";

        return this.get<AuditFindingLinkedAuditInfoModel>(url);
    }

    public hasAccess(guid: string): Observable<boolean> {
        if (isGuid(guid)) {
            const url = `/${guid}/hasaccess`;
            return this.get<boolean>(url);
        }

        return of(false);
    }
    //#endregion Support data calls

    //#region History Calls

    getHistory(guid: string | number, extraParam?: any): Observable<HistoryModel[]> {
        return this.get<HistoryModel[]>(`/${guid}/history`);
    }

    getHistoryChanges(
        guid: string | number,
        mutationId: string | number
    ): Observable<HistoryChange[]> {
        return this.get<HistoryChange[]>(`/${guid}/history/${mutationId}`);
    }

    //#endregion History Calls

    // #region linked mois

    getLinkableMois(guid: string) {
        if (isGuid(guid)) {
            const url = "/" + guid + "/linkablemois";

            return this.get<LinkedMoiModel[]>(url);
        }
        return of([]);
    }

    // #endregion linked mois
}
