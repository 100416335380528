import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { DocumentTemplatesDetailsComponent } from "./details/document-templates-details.component";
import { DocumentTemplatesModuleComponent } from "./details/components/document-templates-module/document-templates-module.component";
import { DocumentTemplatesMergeFieldsComponent } from "./details/components/document-templates-merge-fields/document-templates-merge-fields.component";
import { DocumentTemplatesMailMergeInfoComponent } from "./details/components/document-templates-mail-merge-info/document-templates-mail-merge-info.component";

@NgModule({
    declarations: [
        DocumentTemplatesDetailsComponent,
        DocumentTemplatesModuleComponent,
        DocumentTemplatesMergeFieldsComponent,
        DocumentTemplatesMailMergeInfoComponent,
    ],
    exports: [DocumentTemplatesDetailsComponent],
    imports: [CommonModule, FormsModule, SharedModule],
})
export class DocumentTemplatesModule {}
