import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { StandingdataDataService } from "@app/standingdata/shared/standingdata.service";
import { StandingDataType } from "@enums/StandingDataType";
import { toPromise } from "@methods/CommonMethods";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";
import { nameof } from "@methods/jeffs-toolkit";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { EventLightStandingDataCollection } from "@models/event/EventLightStandingDataCollection";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { ThirdPartyModel } from "@models/thirdparty/ThirdpartyModel";
import { ThirdpartyOrganizationType } from "@models/thirdparty/ThirdpartyOrganizationType";
import { ThirdPartyPermissions } from "@models/thirdparty/ThirdPartyPermissions";
import { ThirdPartyReviewFrequencyModel } from "@models/thirdparty/ThirdPartyReviewFrequencyModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { ThirdPartyDataService } from "@pages/thirdparty/services/ThirdPartyDataService";
import { Observable } from "rxjs";

@Component({
    selector: "uc-thirdparty-detail-main",
    templateUrl: "./thirdparty-detail-main.component.html",
    styleUrls: ["./thirdparty-detail-main.component.scss"],
})
export class ThirdPartyDetailMainComponent implements OnInit {
    @Output() organizationsChange = new EventEmitter<number[]>();
    @Output() organizationResponsibleChange = new EventEmitter<number>();

    @Input() thirdParty: ThirdPartyModel;
    @Input() sd: any = {};
    @Input() parentForm: FormGroup;
    @Input() readonly: boolean;
    @Input() permissions: ThirdPartyPermissions;
    @Input() sdLight: EventLightStandingDataCollection;

    contactConfig: GenericListConfig;
    frequencies: ThirdPartyReviewFrequencyModel[];
    users: IdNameCombination[];
    reviewers: IdNameCombination[];

    servicesRequest: Observable<CerrixTreeItem[]>;
    relationshipRequest: Observable<IdNameCombination[]>;
    criticalityRequest: Observable<IdNameCombination[]>;
    organizationRequest: Observable<CerrixTreeItem[]>;
    organizationMultiRequest: Observable<CerrixTreeItem[]>;
    statusOfInvolvementRequest: Observable<IdNameCombination[]>;
    businessDimensionsRequest: Observable<CerrixTreeItem[]>;
    frameworkDimensionsRequest: Observable<CerrixTreeItem[]>;

    customFieldData: { [key: number]: CustomFieldInfoModel } = {};
    thirdPartyCustomFieldsFormGroup: FormGroup;
    customFieldStandingDataModel: CustomFieldStandingDataModel[] = [];

    organizationLoadTimeout;

    loadingResponsibles = true;
    loadingReviewers = true;

    constructor(
        private _thirdPartyService: ThirdPartyDataService,
        private standingDataService: StandingdataDataService
    ) {}

    async ngOnInit() {
        this._thirdPartyService.getFrequency().subscribe((value) => {
            this.getFrequencies(value);
        });

        this.servicesRequest = this.standingDataService.getAllByType(
            StandingDataType.TpService,
            ...this.thirdParty.LinkedServiceIds
        );
        this.criticalityRequest = this.standingDataService.getAllByType(
            StandingDataType.TpCriticality,
            this.thirdParty.Criticality
        );
        this.relationshipRequest = this.standingDataService.getAllByType(
            StandingDataType.TpRelationship,
            this.thirdParty.Relationship
        );
        this.statusOfInvolvementRequest = this.standingDataService.getAllByType(
            StandingDataType.TpStatusOfInvolvement,
            this.thirdParty.StatusOfInvolvementID
        );

        this.organizationRequest = this._thirdPartyService.getOrganizations(
            this.thirdParty.OrganizationResponsibleId
                ? this.thirdParty.OrganizationResponsibleId
                : 0,
            ThirdpartyOrganizationType.SingleSelect
        );

        this.organizationMultiRequest = this._thirdPartyService.getOrganizations(
            this.thirdParty.Organizations,
            ThirdpartyOrganizationType.MultiSelect
        );

        this._thirdPartyService
            .getUsers(...this.thirdParty.UserResponsibles)
            .subscribe((value: IdNameCombination[]) => {
                this.users = value;
                this.loadingResponsibles = false;
            });

        this._thirdPartyService
            .getReviewers(...this.thirdParty.Reviewers)
            .subscribe((value: IdNameCombination[]) => {
                this.reviewers = value;
                this.loadingReviewers = false;
            });

        this.businessDimensionsRequest = this._thirdPartyService.getBusinessDimensions(
            this.thirdParty.Organizations,
            this.thirdParty.BusinessDimensions
        );

        this.frameworkDimensionsRequest = this._thirdPartyService.getFrameworkDimensions(
            this.thirdParty.FrameworkDimensions
        );

        await this.createCustomFieldsFormGroup();
    }

    async createCustomFieldsFormGroup() {
        const customFieldsFormArray = new FormArray([]);

        this.customFieldStandingDataModel = await toPromise(
            this._thirdPartyService.getCustomFields()
        );

        // backend error, console error...
        if (!this.thirdParty.CustomFields) this.thirdParty.CustomFields = [];

        for (const field of this.customFieldStandingDataModel) {
            const answerField = this.thirdParty.CustomFields.find(
                (x) => x.customFieldId === field.ID
            );

            const customFieldFormGroup = new FormGroup<any>({
                ID: new FormControl(answerField ? answerField.ID : 0),
                Name: new FormControl(field.Name),
                customFieldId: new FormControl(field.ID),
                required: new FormControl(field.required),
            });

            const valueProperty = getCustomFieldValueProperty(field);
            customFieldFormGroup.addControl(
                valueProperty,
                new FormControl(
                    answerField ? answerField[valueProperty] : null,
                    field.required ? [Validators.required] : []
                )
            );

            // Store extra information from the custom field in a custom object which is easier accessible in the front-end
            this.customFieldData[field.ID] = {
                Name: field.Name,
                fieldType: field.fieldType,
                required: field.required,
                answerOptions: field.answerOptions,
            };

            customFieldsFormArray.push(customFieldFormGroup);
        }

        this.thirdPartyCustomFieldsFormGroup = new FormGroup({
            customFields: customFieldsFormArray,
        });

        if (!this.permissions.canEdit) {
            this.thirdPartyCustomFieldsFormGroup.disable();
        } else {
            this.thirdPartyCustomFieldsFormGroup.valueChanges.subscribe(() => {
                this.thirdParty.CustomFields =
                    this.thirdPartyCustomFieldsFormGroup.value[
                        nameof<ThirdPartyModel>((tp) => tp.CustomFields)
                    ];
            });
        }

        this.parentForm.addControl(
            nameof<ThirdPartyModel>((tp) => tp.CustomFields),
            customFieldsFormArray
        );
    }

    getFrequencies(items: ThirdPartyReviewFrequencyModel[]) {
        this.frequencies = items;
    }

    organizationResponsibleChanged(organisationId: number) {
        this.organizationResponsibleChange.emit(organisationId);
        this.thirdParty.OrganizationResponsibleId = organisationId;
    }

    organizationsChanged(organizationIDs: number[]) {
        if (this.organizationLoadTimeout) {
            clearTimeout(this.organizationLoadTimeout);
            this.organizationLoadTimeout = null;
        }

        this.organizationLoadTimeout = setTimeout(() => {
            this.organizationsChange.emit(organizationIDs);
            this.thirdParty.Organizations = organizationIDs;

            this.businessDimensionsRequest = this._thirdPartyService.getBusinessDimensions(
                organizationIDs,
                []
            );

            this.organizationLoadTimeout = null;
        }, 1000);
    }
}
