import { Component, Input, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import {
    CerrixButtonComponent,
    CerrixFieldWrapperComponent,
    CerrixSelectComponent,
    CerrixTextareaComponent,
    CerrixTitleComponent,
} from "@cerrix/components";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DocumentModel } from "../models/document.model";
import { EditDocumentPopup } from "../models/edit-document-popup.model";
import { CerrixConfirmationButtonComponent } from "../cerrix-confirmation-button/cerrix-confirmation-button.component";

@Component({
    selector: "cerrix-edit-document",
    templateUrl: "./cerrix-edit-document.component.html",
    styleUrls: ["./cerrix-edit-document.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        CerrixButtonComponent,
        CerrixTitleComponent,
        CerrixFieldWrapperComponent,
        CerrixSelectComponent,
        CerrixTextareaComponent,
        CerrixTextareaComponent,
        CerrixConfirmationButtonComponent,
    ],
    providers: [],
})
export class CerrixEditDocumentComponent {
    private dialogRef: MatDialogRef<EditDocumentPopup, DocumentModel[]> = inject(MatDialogRef);
    @Input() public dialogData: EditDocumentPopup = inject(MAT_DIALOG_DATA);

    protected dialogTitle = "";
    protected btnLabel = "";

    protected documentForm: FormGroup = new FormGroup({
        documentTypeId: new FormControl(null),
        description: new FormControl(null),
    });

    ngOnInit(): void {
        if (this.dialogData.openReason === "Add") {
            this.dialogTitle = "Add document(s)";
            this.btnLabel = "Add document(s)";
        } else if (this.dialogData.openReason === "Edit") {
            this.dialogTitle = "Edit document";
            this.btnLabel = "Apply changes";

            this.documentForm = new FormGroup({
                documentTypeId: new FormControl(this.dialogData.documents[0].documentTypeId),
                description: new FormControl(this.dialogData.documents[0].description),
            });
        } else {
            this.dialogTitle = "View document";

            this.documentForm = new FormGroup({
                documentTypeId: new FormControl({
                    value: this.dialogData.documents[0].documentTypeId,
                    disabled: true,
                }),
                description: new FormControl({
                    value: this.dialogData.documents[0].description,
                    disabled: true,
                }),
            });
        }
    }

    protected onRemoveFile(document: DocumentModel) {
        let index = this.dialogData.documents.findIndex((d) => d === document);
        this.dialogData.documents.splice(index, 1);
    }

    protected onApplyChanges() {
        if (this.documentForm.valid) {
            this.dialogData.documents.forEach((document) => {
                document.documentTypeId = this.documentForm.value.documentTypeId;
                document.description = this.documentForm.value.description;
            });

            this.dialogRef.close(this.dialogData.documents);
        }
    }
}
