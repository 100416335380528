import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Form, FormEntryModel, FormFieldConfig } from "@app/forms/models";
import { FormHistory } from "@app/forms/models/other/FormHistory";
import { FileSaveModel } from "@models/generic/FileSaveModel";
import { IdNameCombination } from "@models/generic/IdNameCombination";
import { KeyValue } from "@models/generic/KeyValuePair";
import { HistoryModel } from "@models/history/HistoryModel";
import { Observable } from "rxjs";
import { ApiDataService } from "../../../services/http/ApiDataService";
import { Configuration } from "../../app.constants";
import { MapbackProperty } from "../models/mapback/MapbackProperty";
import { FormDraft } from "../models/detail/FormDraft";
import { FormMainStandingData } from "../models/other/FormMainStandingData";
import { FormLinkableModule } from "../enums/FormLinkableModule";
import { LinkModel } from "@models/generic/LinkModel";
import { FormsLinkModel } from "../models/other/FormsLinkModel";

@Injectable()
export class FormsDataService extends ApiDataService {
    private structureSourcesCall: Promise<KeyValue<number, string>[]>;

    constructor(protected http: HttpClient, configuration: Configuration) {
        super(http, configuration, "forms");
    }

    getForm(formId: number): Observable<Form> {
        const url = this.actionUrl + `/${formId}`;
        return this.http.get<Form>(url);
    }

    // This will only return forms the user can view
    getFormOverview() {
        const url = this.actionUrl + `/formoverview`;
        return this.http.get<Form[]>(url);
    }

    // Get minimal overview data needed to show in the widget.
    getFormWidgetOverview(ids: number[]) {
        let url = `/formwidgetoverview`;
        if (ids && ids.length > 0) {
            url += "?formids=" + ids.join(",");
        }
        return this.get<Form[]>(url);
    }

    // This will only return forms the user can view
    getStatusOverview() {
        const url = this.actionUrl + `/statusoverview`;
        return this.http.get<Form[]>(url);
    }

    // This will return one form with all its results the user has view/edit access on
    getResults(formId: number): Observable<Form> {
        const url = this.actionUrl + `/${formId}/results`;
        return this.http.get<Form>(url);
    }

    hasOpenResults(formId: number): Observable<boolean> {
        const url = this.actionUrl + `/${formId}/results/any`;
        return this.http.get<any>(url);
    }

    // This will return one form with one result if the result is reachable.
    getResult(formID: number, resultID: number): Observable<Form> {
        if (!formID && resultID) {
            formID = null;
        }
        const url = this.actionUrl + `/${formID}/result/${resultID}`;
        return this.http.get<Form>(url);
    }

    deleteResults(ids: number[]) {
        const url = this.actionUrl + "/deleteresults";
        return this.http.post<any>(url, ids);
    }

    reopenResult(formID: number, resultID: number) {
        const url = `/${formID}/result/${resultID}/reopen`;
        return this.post<any>(null, url);
    }

    deleteForm(id: number) {
        const url = this.actionUrl + `/${id}/delete`;
        return this.http.post(url, null, { observe: "response" });
    }

    deleteInvite(id: number) {
        const url = `/invite/${id}`;
        return this.delete(url);
    }

    storeForm(form: Form): Observable<HttpEvent<Object>> {
        return this.post(form, "/store");
    }

    storeFormResult(formEntryModel: FormEntryModel, files: FileSaveModel[]): Observable<any> {
        const url = this.actionUrl + `/storeresult`;

        const formData = new FormData();
        formData.append("entryModel", JSON.stringify(formEntryModel));

        for (const file of files) {
            formData.append(file.id, file.file);
        }

        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(url, formData, {
            headers: headers,
            reportProgress: true,
        });
    }

    toggleFormOpenForEntries(form: Form, toggle: boolean) {
        const url = this.actionUrl + `/${form.ID}/toggleform/${toggle}`;
        return this.http.post<any>(url, form);
    }

    //#region Drafts

    getFormDraft(formID: number) {
        if (formID > 0) {
            const url = `/${formID}/draft`;
            return this.get<FormDraft>(url);
        }
        throw Error("Form ID is required.");
    }

    getUnreleasedFormDrafts() {
        const url = "/draft/overview";
        return this.get<FormDraft[]>(url);
    }

    storeDraft(draft: FormDraft) {
        const url = `/draft`;
        return this.post<number>(draft, url);
    }

    deleteDraft(draftID: number) {
        if (!draftID || draftID <= 0) {
            throw Error("Invalid draft ID.");
        }

        const url = `/draft/${draftID}`;
        return this.delete(url);
    }

    //#endregion Drafts

    // #region Standing Data

    getMainStandingData(formID: number): Observable<FormMainStandingData> {
        if (!formID) {
            formID = 0;
        }

        const url = `/${formID}/mainstandingdata`;
        return this.get<FormMainStandingData>(url);
    }

    getMapBackOptions(mapbackClassID: number): Observable<MapbackProperty[]> {
        const url = this.actionUrl + `/${mapbackClassID}/mapbackproperties`;
        return this.http.get<MapbackProperty[]>(url);
    }

    getStructureSources() {
        if (!this.structureSourcesCall) {
            const url = this.actionUrl + `/structuresources`;
            this.structureSourcesCall = this.http.get<KeyValue<number, string>[]>(url).toPromise();
        }

        return this.structureSourcesCall;
    }

    getStructureData(formResultId: number, model: FormFieldConfig): Promise<any[]> {
        const url = this.actionUrl + `/structuredata`;
        return this.http
            .get<any[]>(url, {
                params: {
                    formResultId: formResultId,
                    modelJson: JSON.stringify(model),
                },
            })
            .toPromise();
    }

    getFormGroups() {
        const url = this.actionUrl + `/groups`;
        return this.http.get<IdNameCombination[]>(url);
    }

    getOverviewCategories() {
        const url = this.actionUrl + `/overviewcategories`;
        return this.http.get<IdNameCombination[]>(url);
    }

    // #endregion

    // #region History

    public getHistory(id: number): Observable<HistoryModel[]> {
        const url = `/${id}/history`;

        return this.get<HistoryModel[]>(url);
    }

    public getHistoryChanges(id: number, historyId: number): Observable<FormHistory> {
        const url = `/${id}/history/${historyId}`;

        return this.get<FormHistory>(url);
    }

    // #endregion

    // #region linked forms
    public getLinkedForms(formId: number, linkedModule: FormLinkableModule): Promise<LinkModel[]> {
        const url = `/linked/${formId}/${linkedModule}`;

        return this.get<LinkModel[]>(url).toPromise();
    }
    // #endregion

    // #region linkable forms
    public getLinkableForms(linkedModule: FormLinkableModule): Promise<FormsLinkModel[]> {
        const url = `/linkableforms/${linkedModule}`;

        return this.get<FormsLinkModel[]>(url).toPromise();
    }

    // #endregion
}
