import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from "./../shared/shared.module";
import { ReleaseNotesComponent } from "./pages/release-notes/release-notes.component";
import { ReleaseNotesService } from "./services/releasenotes.service";

@NgModule({
    imports: [CommonModule, SharedModule],
    declarations: [ReleaseNotesComponent],
})
export class ReleaseNotesModule {
    static forRoot(): ModuleWithProviders<ReleaseNotesModule> {
        return {
            ngModule: ReleaseNotesModule,
            providers: [ReleaseNotesService],
        };
    }
}
