import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EventDataService } from "../services/EventDataService";
import { isGuid } from "@methods/uniqueMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { Observable, Subject } from "rxjs";
import { EventPermissionModel } from "@models/event/EventPermissionModel";
import { EventModel } from "@models/event/EventModel";
import { FormGroup, FormControl, Validators, ValidationErrors } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { ToastrService } from "ngx-toastr";
import { HttpResponse } from "@angular/common/http";
import { EventLightStandingDataCollection } from "@models/event/EventLightStandingDataCollection";
import { EventHeavyStandingDataCollection } from "@models/event/EventHeavyStandingDataCollection";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { BreachDetailComponent } from "../breach-detail/breach-detail.component";
import { EventRiskComponent } from "./event-risk/event-risk.component";
import { EventWorkflowModel } from "@models/event/EventWorkflowModel";
import { EventWorkflowUpdateModel } from "@models/event/EventWorkflowUpdateModel";
import { EventCommentModel } from "../shared/event-comment-model";
import { EventStatusCode } from "@models/event/EventStatusCode";
import { HistoryOverviewComponent } from "@app/shared/history-overview/history-overview.component";
import { DataBreachDataService } from "../services/DataBreachDataService";
import { LinkedMoiOverviewComponent } from "@app/moi/moi-linked-overview/moi-linked-overview.component";
import { MoiTypes } from "@enums/moi/MoiTypes";
import { getFormValue, getFormControl, isDirty, closeDirtyPageCheck } from "@methods/CommonMethods";
import { Pages } from "@constants/pages/Pages";
import { DocumentModel } from "@models/documents/documentmodel";
import { TabService } from "@services/tabs/TabService";
import { TargetModule } from "@enums/document/TargetModule";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { EventConfigModel } from "../shared/event-config-model";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { nameof } from "@methods/jeffs-toolkit";
import { TabComponentHelper } from "@app/shared/helpers/tab-component-helper";
import { SettingsDataService } from "@services/http/SettingsDataService";
import { ApplicationSettings } from "@services/http/settings/application-settings";

@Component({
    selector: "app-event-detail",
    templateUrl: "./event-detail.component.html",
    styleUrls: ["./event-detail.component.scss"],
})
export class EventDetailComponent implements OnInit {
    @ViewChild("dataBreachTab") dataBreachComponent: BreachDetailComponent;
    @ViewChild("eventMoisComponent")
    eventMoisComponent: LinkedMoiOverviewComponent;
    @ViewChild("eventRiskComponent") eventRiskComponent: EventRiskComponent;
    @ViewChild("eventControlComponent")
    eventControlComponent: EventRiskComponent;
    @ViewChild("eventHistoryOverviewComponent")
    eventHistoryOverviewComponent: HistoryOverviewComponent;
    @ViewChild("breachHistoryOverviewComponent")
    breachHistoryOverviewComponent: HistoryOverviewComponent;

    tab: TabModel;
    id: number;
    parentForm: FormGroup;
    lerMoiType = MoiTypes.LER_Moi;

    documentsDirty = false;
    eventDirty = false;
    eventBackupModel: EventModel;

    eventModel: EventModel;
    eventPermissions: EventPermissionModel;
    sdLight: EventLightStandingDataCollection;
    sdHeavy: EventHeavyStandingDataCollection;

    canSave = true;

    nextStatus: string;
    backStatus: string;

    isValid = false;
    hideSaveButton = false;

    documents: DocumentModel[];
    targetModule = TargetModule.Event;

    hyperlinksMenuItemId = "menuItemHyperlinks";
    documentsMenuItemId = "menuItemDocuments";

    titlePos: string;
    titleNeg: string;

    constructor(
        private route: ActivatedRoute,
        private _toastr: ToastrService,
        private _tabService: TabService,
        private _pages: Pages,
        public _eventDS: EventDataService,
        public _breachDS: DataBreachDataService,
        private _promptService: CerrixPromptService,
        private _settingsService: SettingsDataService
    ) {
        this.id = this.id > 0 ? this.id : this.route.snapshot.params.id;
    }

    ngOnInit() {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;
        this.setupInit();
    }

    setupInit() {
        const isExisting = this.id && isGuid(this.id.toString());
        let permissionsRequest: Observable<EventPermissionModel>;
        let lightSdRequest: Observable<EventLightStandingDataCollection>;
        let heavySdRequest: Observable<EventHeavyStandingDataCollection>;

        if (isExisting) {
            const guid = this.id.toString();
            permissionsRequest = this._eventDS.getPermissions(guid);
            lightSdRequest = this._eventDS.getLightSd(guid);
            heavySdRequest = this._eventDS.getHeavySd(guid);
        } else {
            // no new events allowed when incidents module is enabled.
            this._settingsService
                .getSetting(ApplicationSettings.UseIncidents)
                .subscribe((setting) => {
                    if (setting.BoolValue) {
                        this._toastr.warning(
                            "Please use the Incidents module to create new incidents."
                        );
                        this.tab.close(true);
                    }
                });

            permissionsRequest = this._eventDS.getPermissionsForNewEvent();
            lightSdRequest = this._eventDS.getLightSdForNewEvent();
            heavySdRequest = this._eventDS.getHeavySdForNewEvent();
        }

        this.tab.beforeClose = (checkOnly: boolean) => {
            let unsavedChanges = this.eventDirty;
            if (
                this.eventPermissions &&
                this.eventPermissions.detailsReadonly &&
                !this.documentsDirty
            ) {
                unsavedChanges = false;
            }
            return closeDirtyPageCheck(this.tab, this._promptService, checkOnly, !unsavedChanges);
        };

        this.processRequests(permissionsRequest, lightSdRequest, heavySdRequest, isExisting);
    }

    processRequests(
        permissionsRequest: Observable<EventPermissionModel>,
        lightSdReq: Observable<EventLightStandingDataCollection>,
        heavySdReq: Observable<EventHeavyStandingDataCollection>,
        isExisting: boolean
    ) {
        permissionsRequest.subscribe(
            async (permissions) => {
                this.eventPermissions = permissions;
                if (isExisting) {
                    this._eventDS.getEvent(this.id.toString()).subscribe((eventModel) => {
                        this.initEventDetails(eventModel, isExisting);
                        this.handleWorkflow(eventModel.Workflow);
                    });
                } else {
                    const eventModel = new EventModel();
                    this.initEventDetails(eventModel, isExisting);
                }

                this.sdLight = await lightSdReq.toPromise();
                this.sdHeavy = await heavySdReq.toPromise();
            },
            () => {
                this.tab.close(false);
            }
        );
    }

    initEventDetails(eventModel: EventModel, isExisting: boolean) {
        if (isExisting) {
            this.tab.name = `(E) ${eventModel.Identifier} - ${eventModel.Name}`;
        } else {
            this.initNewEventModel(eventModel);
        }

        this.initFormValidation(eventModel, this.eventPermissions);

        this.eventBackupModel = JSON.parse(JSON.stringify(eventModel));
        this.eventModel = eventModel;

        this.tab.showLoader = false;
    }

    initNewEventModel(eventModel: EventModel) {
        const conf = <EventConfigModel>this.tab.config;
        if (conf) {
            const orgID = conf.organizationId;
            if (orgID) {
                eventModel.OrganizationId = +orgID;
            }

            const bdID = conf.businessId;
            if (bdID) {
                eventModel.BusinessDimensions = [+bdID];
            }
        }
    }

    initFormValidation(eventModel: EventModel, permissions: EventPermissionModel) {
        this.parentForm = new FormGroup(
            {
                IsDataBreach: new FormControl(eventModel.IsDataBreach),

                // Event analysis
                Description: new FormControl(eventModel.Description, [Validators.required]),

                PrimaryCause: new FormControl(eventModel.PrimaryCause),

                ImpactDescription: new FormControl(eventModel.ImpactDescription, []),
                SecondaryCause: new FormControl(eventModel.SecondaryCause, []),

                EffectDescription: new FormControl(eventModel.EffectDescription, []),
                CauseDescription: new FormControl(eventModel.CauseDescription, []),

                Effects: new FormControl(eventModel.Effects),
                GrossCosts: new FormControl(eventModel.GrossCosts),

                ReputationImpactDescription: new FormControl(
                    eventModel.ReputationImpactDescription
                ),
                Recovery: new FormControl(eventModel.Recovery),
                NetCosts: new FormControl({ value: eventModel.NetCosts, disabled: true }),

                ReputationImpactId: new FormControl(eventModel.ReputationImpactId),
                CurrencyCode: new FormControl(eventModel.CurrencyCode),

                RiskOpinion: new FormControl(eventModel.RiskOpinion),
                Comments: new FormControl(eventModel.Comments),

                // Details
                Name: new FormControl(eventModel.Name, [Validators.required]),
                DateOccurrence: new FormControl(eventModel.DateOccurrence, [
                    Validators.required,
                    FormValidationHelper.validateDateBeforeNow<EventModel>(
                        "Date occurred cannot be later than today."
                    ),
                ]),

                InternalIdentifier: new FormControl(eventModel.InternalIdentifier),
                DateDetection: new FormControl(eventModel.DateDetection, [
                    Validators.required,
                    FormValidationHelper.validateDateBeforeNow<EventModel>(
                        "Date detected cannot be later than today."
                    ),
                ]),

                OrganizationId: new FormControl(eventModel.OrganizationId, [Validators.required]),
                ReportedById: new FormControl({
                    value: eventModel.ReportedById,
                    disabled: !permissions.canChangeReporter,
                }),
                ReporterName: new FormControl({
                    value: "",
                    disabled: true,
                }),

                EventCategoryId: new FormControl(eventModel.EventCategoryId),
                Responsible: new FormControl(
                    { value: eventModel.Responsible, disabled: !permissions.canChangeUsers },
                    [Validators.required]
                ),

                IncidentTypes: new FormControl(eventModel.IncidentTypes, [Validators.required]),
                Assessor: new FormControl(
                    { value: eventModel.Assessor, disabled: !permissions.canChangeUsers },
                    [Validators.required]
                ),

                ClassificationId: new FormControl(eventModel.ClassificationId),
                Informed: new FormControl({
                    value: eventModel.Informed,
                    disabled: !permissions.canChangeUsers,
                }),

                CausedByOrganizationId: new FormControl(eventModel.CausedByOrganizationId),
                AffectedOrganizationId: new FormControl(eventModel.AffectedOrganizationId),

                BusinessLineId: new FormControl(eventModel.BusinessLineId),
                BusinessDimensions: new FormControl(eventModel.BusinessDimensions),
            },
            {
                validators: [
                    FormValidationHelper.validateStartEndDate<EventModel>(
                        "DateOccurrence",
                        "DateDetection",
                        "Date detected may not be before date occurred",
                        false
                    ),
                ],
            }
        );

        if (permissions.detailsReadonly) {
            this.parentForm.disable();
        } else {
            getFormControl<EventModel>(this.parentForm, "IsDataBreach").valueChanges.subscribe(
                (isDataBreach) => (this.eventModel.IsDataBreach = isDataBreach)
            );
        }

        this.parentForm.valueChanges.subscribe(() => {
            FormValidationHelper.mapToModel(this.parentForm, this.eventModel);
            this.checkDirty();
        });
    }

    checkDirty() {
        this.eventDirty = isDirty(this.eventBackupModel, this.eventModel);
    }

    checkHyperlinksDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.hyperlinksMenuItemId, true);
        this.eventDirty = true;
    }

    checkDocumentsDirty(): void {
        TabComponentHelper.toggleTabDirty(this.tab, this.documentsMenuItemId, true);
        this.documentsDirty = true;
    }

    async save(skipReload?: boolean) {
        let validationErrors: ValidationErrors;
        const canEditEvent = !this.eventPermissions.detailsReadonly;

        // Make sure users are able to upload documents or edit data breach even if the event is invalid
        if (canEditEvent && !this.parentForm.valid) {
            FormValidationHelper.markAllAsTouched(this.parentForm);

            validationErrors = FormValidationHelper.getFormControlErrors(this.parentForm);
        }

        const isDataBreach = getFormValue<EventModel>(this.parentForm, "IsDataBreach") === true;
        if (isDataBreach) {
            const breachValidationErrors = this.dataBreachComponent.validate();

            if (breachValidationErrors && Object.keys(breachValidationErrors).length > 0) {
                validationErrors = validationErrors ? validationErrors : {};

                validationErrors = { ...validationErrors, ...breachValidationErrors };
            }
        }

        if (validationErrors && Object.keys(validationErrors).length > 0) {
            const validationMessage = FormValidationHelper.getGeneralErrorMessage(validationErrors);
            this._toastr.warning(validationMessage, "Save failed.", { enableHtml: true });

            return false;
        }

        FormValidationHelper.mapToModel(this.parentForm, this.eventModel);
        if (isDataBreach) {
            this.eventModel.DataBreach = this.dataBreachComponent.mapback();
        }

        const savingPrompt = this._promptService.loader("Saving changes, please wait...");

        let storeSuccess = false;
        await this._eventDS
            .storeEvent(this.eventModel)
            .toPromise()
            .then((value: HttpResponse<any>) => {
                savingPrompt.close();
                this._toastr.success("", "Update completed");
                this.tab.id = value.body.toString();
                storeSuccess = true;

                this._tabService.refresh(this._pages.Events);

                if (!skipReload) {
                    this.tab.refresh();
                }
            })
            .catch((error) => {
                savingPrompt.close();
                storeSuccess = false;
            });

        return storeSuccess;
    }

    delete() {
        this._eventDS.deleteEvent(this.eventModel.Guid).subscribe(
            (value) => {
                if (value && value.length > 0) {
                    this._toastr.warning(value.toString());
                } else {
                    this._toastr.success("Event deleted");
                    this._tabService.refresh(this._pages.Events);
                    this.tab.close(false);
                }
            },
            (error) => {
                this._toastr.error("", error || error.Message);
            }
        );
    }

    handleMenuItemClick(menuItem: TabMenuItem) {
        if (menuItem) {
            switch (menuItem.name) {
                case "Data Breach": {
                    this.dataBreachComponent.eventModel = this.eventModel;
                    this.dataBreachComponent.load(this.eventModel.DataBreachGuid);
                    break;
                }
                case "Measures of Improvement": {
                    this.eventMoisComponent.load(true);
                    break;
                }
                case "Risks": {
                    this.eventRiskComponent.load();
                    break;
                }
                case "Controls": {
                    this.eventControlComponent.load();
                    break;
                }
                case "Documents": {
                    this.loadDocuments();
                    break;
                }
                case "Hyperlinks": {
                    this.loadHyperlinks();
                    break;
                }
                case "Event History": {
                    this.eventHistoryOverviewComponent.loadHistory();
                    break;
                }
                case "Data Breach History": {
                    this.breachHistoryOverviewComponent.loadHistory();
                    break;
                }
            }
        }
    }

    loadDocuments() {
        if (!this.documents && this.id && isGuid(this.id.toString())) {
            this._eventDS.getDocuments(this.id.toString()).subscribe((documents) => {
                this.documents = documents;
                this.eventModel.Documents = documents;
            });
        } else {
            this.documents = [];
        }
    }

    loadHyperlinks(): void {
        if (!this.eventModel.Hyperlinks && this.id && isGuid(this.id.toString())) {
            this._eventDS.getHyperlinks(this.id.toString()).subscribe((hyperlinks) => {
                this.eventModel.Hyperlinks = hyperlinks;
            });
        }
    }

    // Region workflow

    handleWorkflow(workflow: EventWorkflowModel) {
        switch (workflow.Status) {
            // New event
            case EventStatusCode.NewEvent: {
                break;
            }
            // AwaitingAcceptance
            case EventStatusCode.AwaitingAcceptance: {
                if (this.eventPermissions.canAcceptReject) {
                    this.nextStatus = "Accept Event";
                    this.backStatus = "Reject & Close Event";
                    this.titleNeg = "In case there is no further action required, close the event";
                }
                break;
            }
            // rejected
            case EventStatusCode.Rejected:
            // closed
            case EventStatusCode.Closed: {
                if (this.eventPermissions.canCloseReopen) {
                    this.nextStatus = "Reopen";
                }
                break;
            }
            // AwaitingImprovements
            case EventStatusCode.AwaitingImprovements: {
                if (this.eventPermissions.canSetReadyForApproval) {
                    this.nextStatus = "Ready for Approval";
                }
                break;
            }
            // ReadyForApproval
            case EventStatusCode.ReadyForApproval: {
                if (this.eventPermissions.canApproveReject) {
                    this.nextStatus = "Approve & Close";
                    this.backStatus = "Not Approved";
                }
                break;
            }
        }
    }

    canEditBreach(canEdit: boolean) {
        if (this.eventPermissions.canSave && canEdit) {
            // User doesn't have write permissions on event but can edit breach
            this.eventPermissions.canSave = true;
        }
    }

    async newCommentDialog(approve: boolean) {
        let reloadSkipped = false;

        const openDialogMethod = () => {
            if (this.eventModel.Workflow.Status === EventStatusCode.AwaitingImprovements) {
                // check if linked MoIs are accepted.
                this._eventDS.isEventApprovable(this.eventModel.Guid).subscribe((value) => {
                    if (value) {
                        this.openCommentDialog(approve, reloadSkipped);
                    } else {
                        this._toastr.warning(
                            "Event has open MoIs. Please complete or close linked event MoIs to continue."
                        );
                    }
                });
            } else {
                this.openCommentDialog(approve, reloadSkipped);
            }
        };
        if (this.eventDirty || this.dataBreachComponent.breachDirty) {
            const saved = await this.save(true);
            if (!saved) {
                return;
            }
        }
        openDialogMethod();
    }

    openCommentDialog(approve: boolean, reloadAfterdone: boolean) {
        this.openNewDialog().subscribe((newComment) => {
            if (newComment) {
                this.tab.showLoader = true;
                const workflow = new EventWorkflowUpdateModel();
                workflow.approved = approve;
                workflow.comment = newComment.comment;
                workflow.currentStatus = this.eventModel.Workflow.Status;
                this._eventDS.updateStatus(this.eventModel.Guid, workflow).subscribe(
                    (value) => {
                        this._toastr.success(value.toString());
                        this.tab.refresh();
                    },
                    (error) => {
                        this._toastr.error("", error || error.Message);
                        this.tab.showLoader = false;
                    }
                );
            } else if (reloadAfterdone) {
                this.tab.refresh();
            }
        });
    }

    private openNewDialog(): Observable<EventCommentModel> {
        const fields: GenericListField[] = [];
        fields.push({
            prettyName: "Comment",
            fieldName: nameof<EventCommentModel>((x) => x.comment),
            required: true,
            fieldType: GenericListFieldType.TextArea,
        });

        return <Observable<EventCommentModel>>this._promptService
            .prompt({
                height: "350px",
                data: {
                    title: "Add comment",
                    fields: fields,
                    closeOnEsc: false,
                    confirmOnEnter: false,
                    confirmButton: {
                        text: "OK",
                    },
                },
            })
            .getResult();
    }
}
