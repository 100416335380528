import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }

    const properties = Object.getOwnPropertyNames(filter);

    //To avoid changing the referenced object list
    let itemsToFilter = JSON.parse(JSON.stringify(items));

    properties.forEach(filterName =>
        itemsToFilter = itemsToFilter
            .filter(singleItem => singleItem[filterName] === filter[filterName]))

    return itemsToFilter;
  }
}
