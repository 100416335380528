import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
    selector: "eff-read-more",
    templateUrl: "./eff-read-more.component.html",
    styleUrls: ["./eff-read-more.component.scss"],
})
export class EffReadMoreComponent {
    @Input() text: string;
    @Input() charactersNumber: number = 100;

    displayedText: string;
    showMoreLessButton: boolean = false;
    isMore: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.text) {
            this.init();
        }
    }

    ngOnInit() {
        this.init();
    }

    init() {
        if (!this.text) return;

        this.showMoreLessButton = this.text.length > this.charactersNumber;

        if (!this.showMoreLessButton) {
            this.displayedText = this.text;
            return;
        }

        if (this.isMore) {
            this.displayedText = this.text;
        } else {
            this.displayedText = this.text.slice(0, this.charactersNumber) + "...";
        }
    }

    onMoreClick() {
        this.isMore = !this.isMore;
        this.init();
    }
}
