import { CommonModule } from "@angular/common";
import { Component, InjectionToken, Input, OnInit, inject } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatDialogConfig,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { CerrixTitleComponent } from "@cerrix/components";
import { MessageDialogModel } from "./models/incident-workflow-change-input.model";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "cerrix-message-dialog",
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, CerrixTitleComponent],
    templateUrl: "./cerrix-message-dialog.component.html",
    styleUrl: "./cerrix-message-dialog.component.scss",
})
export class CerrixMessageDialogComponent {
    @Input() public dialogData: MessageDialogModel = inject(MAT_DIALOG_DATA);
}
