import { Component, OnInit, EventEmitter } from '@angular/core';
import { Form, WorkflowDefinition, WorkflowPageDefinition } from '@app/forms/models';
import { FormsDataService } from '@app/forms/services/forms-data.service';
import { IdNameCombination } from '@models/generic/IdNameCombination';
import { FormPageType } from '@app/forms/enums/FormPageType';

@Component({
    selector: "form-workflow-settings",
    templateUrl: "./form-workflow-settings.component.html",
    styleUrls: ["./form-workflow-settings.component.scss"]
})
export class FormWorkflowSettingsComponent implements OnInit {
    saveChanges: EventEmitter<Form> = new EventEmitter<Form>();

    form: Form;

    definitions: WorkflowDefinition[];
    focusedDefinition = 0;

    groups: IdNameCombination[];
    pageType = FormPageType;

    constructor(private _formDS: FormsDataService) { }

    ngOnInit() {
        this._formDS.getFormGroups().subscribe(x => this.groups = x);
        this.definitions = this.form.WorkflowDefinitions ? JSON.parse(JSON.stringify(this.form.WorkflowDefinitions)) : [];
        if (!this.definitions) { this.definitions = []; }
        this.form.Pages.forEach(p => { if (!p.WorkflowPageDefinitions) { p.WorkflowPageDefinitions = []; } });

        this.setupDefinitions();

        if (this.definitions.length === 0) {
            this.newDefinition();
        }
    }

    setupDefinitions() {
        this.definitions.forEach(def => {
            def.PageDefinitions = [];
            this.form.Pages.forEach(p => {
                let pageDef: WorkflowPageDefinition;

                const foundPages = p.WorkflowPageDefinitions.filter(x => x.WorkflowDefinitionID === def.ID);
                if (foundPages.length > 0) {
                    pageDef = JSON.parse(JSON.stringify(foundPages[0]));
                } else {
                    pageDef = new WorkflowPageDefinition();
                    pageDef.ID = this.getNewID(ObjectType.PageDefinition);
                    pageDef.WorkflowDefinitionID = def.ID;
                    pageDef.PageID = p.ID;
                }

                pageDef.Page = p;
                def.PageDefinitions.push(pageDef);
            });
        });
    }

    newDefinition() {
        const defID = this.getNewID(ObjectType.Definition);

        const newDefinition = new WorkflowDefinition();
        newDefinition.ID = defID;
        newDefinition.Name = "Definition " + (this.definitions.length + 1);
        newDefinition.DefinitionDataOwners = [];
        newDefinition.PageDefinitions = [];
        this.definitions.push(newDefinition);

        this.form.Pages.forEach(p => {
            const pageDef = new WorkflowPageDefinition();
            pageDef.ID = this.getNewID(ObjectType.PageDefinition);
            pageDef.WorkflowDefinitionID = defID;
            pageDef.PageID = p.ID;
            pageDef.Page = p;
            newDefinition.PageDefinitions.push(pageDef);
        });

        this.focusedDefinition = this.definitions.length - 1;
    }

    deleteDefinition() {
        if (this.definitions.length > 1) {
            this.definitions.splice(this.focusedDefinition, 1);

            if (this.focusedDefinition >= this.definitions.length) {
                this.focusedDefinition = this.definitions.length - 1;
            }
        }
    }

    getNewID(ot: ObjectType): number {
        let lowest = Number.MAX_VALUE;

        if (this.definitions) {
            if (ot === ObjectType.Definition) {
                lowest = this.definitions.getLowerIDByProp('ID', 1);
            }

            if (ot === ObjectType.PageDefinition) {
                this.definitions.forEach(def => {
                    if (def.PageDefinitions) {
                        const temp = def.PageDefinitions.getLowerIDByProp('ID', 1);
                        if (temp <= lowest) { lowest = temp; }
                    }
                });
            }
        }

        return lowest < 0 ? lowest : -1;
    }

    setGroup(pageDef: WorkflowPageDefinition, groups: IdNameCombination[]) {
        const selectedIds = groups.map(x => x.ID);
        pageDef.Groups = selectedIds;
    }

    onSaveChanges() {
        // Apply and save changes.
        this.form.WorkflowDefinitions = [];
        this.form.Pages.forEach(x => x.WorkflowPageDefinitions = []);

        this.definitions.forEach(def => {
            const definition = new WorkflowDefinition();
            definition.ID = def.ID;
            definition.Name = def.Name;
            definition.DefinitionDataOwners = JSON.parse(JSON.stringify(def.DefinitionDataOwners));

            def.PageDefinitions.forEach((pageDef, index) => {
                const pageDefinition = new WorkflowPageDefinition();
                pageDefinition.ID = pageDef.ID;
                pageDefinition.WorkflowDefinitionID = definition.ID;
                pageDefinition.PageID = pageDef.PageID;

                pageDefinition.LinkedToStarter = pageDef.Page.Type === FormPageType.Entry && pageDef.Page && pageDef.LinkedToStarter;
                if (!pageDefinition.LinkedToStarter) {
                    pageDefinition.LinkedToPrevious = pageDef.LinkedToPrevious;

                    if (!pageDefinition.LinkedToPrevious) {
                        pageDefinition.Groups = pageDef.Groups;
                    }
                }

                this.form.Pages[index].WorkflowPageDefinitions.push(pageDefinition);
            });

            this.form.WorkflowDefinitions.push(definition);
        });

        this.saveChanges.emit(this.form);
    }
}

enum ObjectType { Definition = 1, PageDefinition }