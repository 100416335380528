import { Component, OnInit } from "@angular/core";
import { TaskWorkspaceModel } from "@app/tasks/models/TaskWorkspaceModel";
import { TaskDataService } from "@app/tasks/services/TaskDataService";
import { Pages } from "@constants/pages/Pages";
import { FormatType } from "@enums/FormatType";
import { ModuleType } from "@enums/ModuleType";
import { RendererType } from "@enums/RendererType";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { nameof, toPromise } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { WorkspaceModulePermissionModel } from "@models/permissions/WorkspaceModulePermissions";
import { RendererConfig } from "@models/workspace/RendererConfig";
import { PermissionsService } from "@services/permissions/PermissionsService";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "app-task-overview",
    templateUrl: "./task-overview.component.html",
    styleUrls: [],
})
export class TaskOverviewComponent implements OnInit {
    moduleType = ModuleType.Tasks;
    tabID: string;
    tab: TabModel;
    data: Promise<TaskWorkspaceModel[]>;
    headerLookup: any = {};

    rendererConfig: RendererConfig[] = [
        {
            textColumn: "actionDate",
            actionColumn: "isOverdue",
            actionValue: "Yes",
            type: RendererType.DueDate,
            formatType: FormatType.DateFormat,
        },
        {
            textColumn: "scoreName",
            actionColumn: "scoreName_COLOR",
            hideActionColumn: true,
            type: RendererType.Score,
        },
    ];

    dateProps: string[] = ["actionDate"];

    permissions: WorkspaceModulePermissionModel;

    constructor(
        private _taskDs: TaskDataService,
        private _tabs: TabService,
        public pages: Pages,
        private _permissions: PermissionsService
    ) {}

    ngOnInit() {
        this.permissions = this._permissions.permissions.Workspace.task;
        this.data = this.getData();

        this._tabs.listeners.addGlobalListener(
            TabEventListenerType.OnSave,
            this.pages.TaskDetail,
            (taskID) => {
                this.tab.refresh();
            },
            this.tab
        );
    }

    getData() {
        let data = toPromise(this._taskDs.getOverview());
        this.mapHeaders();

        return data;
    }

    async openDetail(task: TaskWorkspaceModel) {
        const taskName = "(T)" + task.taskIdentifier + " - " + task.taskName;
        this._tabs.generateTab(this.pages.TaskDetail, task.guid, taskName);
    }

    private mapHeaders(): void {
        let headerLookup: any = {};

        headerLookup[nameof<TaskWorkspaceModel>("taskIdentifier")] = "Task Identifier";
        headerLookup[nameof<TaskWorkspaceModel>("taskName")] = "Task Name";
        headerLookup[nameof<TaskWorkspaceModel>("linkedItem")] = "Linked Item";
        headerLookup[nameof<TaskWorkspaceModel>("actionDate")] = "Action Date";
        headerLookup[nameof<TaskWorkspaceModel>("status")] = "Status";
        headerLookup[nameof<TaskWorkspaceModel>("scoreName")] = "Task Score";
        headerLookup[nameof<TaskWorkspaceModel>("scoreName_COLOR")] = "Task Score Color";
        headerLookup[nameof<TaskWorkspaceModel>("responsibles")] = "Responsibles";
        headerLookup[nameof<TaskWorkspaceModel>("reviewers")] = "Reviewers";
        headerLookup[nameof<TaskWorkspaceModel>("comments")] = "Comments";
        headerLookup[nameof<TaskWorkspaceModel>("isOverdue")] = "Is Overdue";

        this.headerLookup = headerLookup;
    }
}
