import { Directive, Output, EventEmitter, Input, SimpleChange, OnInit } from '@angular/core';

@Directive({
    selector: '[Create]'
})

export class Create implements OnInit {

    @Output() Create: EventEmitter<any> = new EventEmitter<any>();
    constructor() { }

    ngOnInit() {
        this.Create.emit();
    }

}