import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiskDataService } from '../../../app/risk/services/RiskDataService';

@Injectable()
export class HasRiskAccess  {

    constructor(private router: Router, private _riskDS: RiskDataService) { }

    canActivate(): Observable<boolean> {
        return this._riskDS.hasAccess().pipe(map((hasAccess) => {
            if (!hasAccess) {
                this.router.navigateByUrl('start');
                return false;
            } else {
                return true;
            }
        }));
    }
}
