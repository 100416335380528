import { Component, Input, OnInit } from "@angular/core";
import { BusinessNavigatorPermissionModel } from "@app/navigators/business-navigator/models/business-navigator-permission-model";
import { BusinessNavigatorRequestModel } from "@app/navigators/business-navigator/models/business-navigator-request-model";
import { BusinessNavigatorDataService } from "@app/navigators/business-navigator/services/business-navigator-data.service";
import { RiskConfigModel } from "@app/risk/models/risk-config-model";
import { Observable } from "rxjs";
import { NavGenericListFactory } from "@app/navigators/services/NavGenericListFactory";
import { NavigatorConfigType } from "@app/navigators/enums/NavigatorConfigType";
import { GenericListConfig } from "@app/shared/models/GenericList/GenericList";
import { Pages } from "@constants/pages/Pages";
import { TabService } from "@services/tabs/TabService";

@Component({
    selector: "business-risks",
    templateUrl: "./business-risks.component.html",
})
export class BusinessRisksComponent implements OnInit {
    @Input() requestModel: BusinessNavigatorRequestModel;
    @Input() permissionsModel: BusinessNavigatorPermissionModel;

    mainConfig: GenericListConfig;
    innerConfig: GenericListConfig;
    innerConfigDataMethod: (guid: string) => Observable<any>;

    constructor(
        private _businessDs: BusinessNavigatorDataService,
        private _navFactory: NavGenericListFactory,
        private _tabService: TabService,
        private _pages: Pages
    ) {}

    ngOnInit() {
        const config = this._navFactory.createConfig(NavigatorConfigType.Risk);
        config.hideRefreshButton = false;
        config.dataMethod = () => this._businessDs.getRisks(this.requestModel);

        if (this.permissionsModel.canAddRisk) {
            config.allowAdd = this.permissionsModel.canAddRisk;
            config.addOverride = () => {
                const config = <RiskConfigModel>{
                    organizationId: this.requestModel.organizationID,
                    businessId: this.requestModel.businessID,
                };
                this._tabService.generateTab(this._pages.RiskDetail, 0, "(R) New Risk", config);
            };
        }

        const innerConfig = this._navFactory.createConfig(NavigatorConfigType.LinkedControl);
        this.innerConfigDataMethod = this._businessDs.getLinkedControlsByRiskId.bind(
            this._businessDs
        );

        this.mainConfig = config;
        this.innerConfig = innerConfig;
    }
}
