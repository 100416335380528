import { ILinkWidgetGeneratorType } from "./../../../shared/interfaces/ILinkWidgetGeneratorType";
import { DashboardQuickLinkModel } from "../../../shared/models/DashboardQuickLinkModel";
import { Pages } from "@constants/pages/Pages";
import { ApplicationPermissionsModel } from "@models/permissions/ApplicationPermissionsModel";

export class InsightsLinkGenerator implements ILinkWidgetGeneratorType {
    getTypes(permissions: ApplicationPermissionsModel, pages: Pages): DashboardQuickLinkModel[] {
        const quickLinks = [];
        if (permissions.HasReportingAccess) {
            quickLinks.push({
                title: "Reporting",
                icon: "fad fa-file-invoice",
                page: pages.Reports,

                description: "Open overview with available reports to generate.",
            });
        }

        if (permissions.HasExportAccess) {
            quickLinks.push({
                title: "Exports",
                icon: "fad fa-cloud-download",
                page: pages.Exports,

                description: "Open overview of available data exports.",
            });
        }

        if (permissions.Documents.SharedViewer) {
            quickLinks.push({
                title: "Shared Documents",
                icon: "fad fa-folders",
                page: pages.SharedDocuments,

                description:
                    "Open workspace with all uploaded documents within application you have access to.",
            });
        }

        if (permissions.IsApplicationAdmin) {
            quickLinks.push({
                title: "Third Party Assurance",
                icon: "fad fa-calculator",
                page: pages.TpaRequests,

                description: "Open overview of all control export requests.",
            });
        }

        return quickLinks;
    }
}
