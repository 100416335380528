<div class="d-flex flex-row" (click)="$event.stopPropagation()">
    <eff-icon
        class="action-icon"
        *ngIf="!isEditMode && !model && !this.disabled"
        icon="new_label_outlined"
        (click)="onEdit(); $event.stopPropagation()"
    ></eff-icon>

    <input
        #inputText
        autofocus
        (click)="$event.stopPropagation()"
        (keyup)="onModelChanged()"
        [disabled]="!isEditMode"
        type="text"
        [(ngModel)]="model"
    />
    <eff-icon
        class="edit-comment-icon"
        *ngIf="!isEditMode && model && !this.disabled"
        icon="edit_outlined"
        (click)="onEdit(); $event.stopPropagation()"
    ></eff-icon>
</div>
