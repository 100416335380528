<form
    class="form-entry"
    menuSystem
    [doNotShowTucked]="true"
    [cerrixTab]="tab"
    (change)="selectedChanged($event)"
    data-uiid="form-entry"
    *ngIf="form"
>
    <div class="detail-page-action-bar">
        <!-- This div has to stay, so the workflow area can be placed correctly. -->
        <button class="btn btn-sm" (click)="previousPage()" [disabled]="selectedIndex <= 0">
            <i class="fas fa-angle-left" aria-hidden="true"></i>
            <span class="d-none d-lg-inline ms-2">Previous page</span>
        </button>
        <span class="sub-message" *ngIf="readOnly">Readonly viewer.</span>
        <span class="sub-message" *ngIf="!readOnly && isReview">Viewing as reviewer.</span>
        <span class="sub-message" *ngIf="!readOnly && !isReview">Viewing as assignee.</span>
        <div class="workflow-area" *ngIf="!readOnly">
            <button
                class="btn btn-danger btn-sm"
                (click)="completeClick(true)"
                [disabled]="saveClicked"
                *ngIf="selectedIndex === lastPageIndex && isReview"
            >
                <i class="fas fa-times" aria-hidden="true"></i>
                <span class="d-none d-lg-inline ms-2">Reject</span>
            </button>
            <button
                class="btn btn-success btn-sm"
                (click)="completeClick()"
                [disabled]="saveClicked"
                *ngIf="selectedIndex === lastPageIndex && isReview"
            >
                <i class="fas fa-check" aria-hidden="true"></i>
                <span class="d-none d-lg-inline ms-2">Accept</span>
            </button>
            <button
                class="btn btn-cerrix btn-sm"
                (click)="completeClick()"
                [disabled]="saveClicked"
                *ngIf="selectedIndex === lastPageIndex && !isReview"
            >
                <i class="fas fa-check" aria-hidden="true"></i>
                <span class="d-none d-lg-inline">Complete</span>
            </button>
        </div>
        <button
            class="btn btn-sm float-end ms-2"
            (click)="nextPage()"
            *ngIf="selectedIndex < lastPageIndex"
        >
            <span class="d-none d-lg-inline">Next page</span>
            <i class="fas fa-angle-right ms-2" aria-hidden="true"></i>
        </button>
        <button
            class="btn btn-secondary btn-sm float-end"
            (click)="storeResult(false)"
            [disabled]="saveClicked"
            *ngIf="!readOnly && !isReview && selectedIndex !== lastPageIndex"
        >
            <i class="fas fa-save" aria-hidden="true"></i>
            <span class="d-none d-lg-inline ms-2">Save</span>
        </button>
        <button
            class="btn btn-warning btn-sm float-end"
            (click)="reopenClick()"
            *ngIf="allowReopen"
        >
            <i class="far fa-undo-alt" aria-hidden="true"></i>
            <span class="d-none d-lg-inline ms-2">Reopen</span>
        </button>
    </div>

    <div class="form-pages" [attr.name]="form.Title" menuicon="fas fa-file-alt" menusystemsubmenu>
        <div class="form-group global-comment" *ngIf="isReview || reviewComment">
            <cerrix-textarea
                fieldLabel="Global review comment"
                class="form-control"
                [(ngModel)]="reviewComment"
                [ngModelOptions]="{ standalone: true }"
                identifier="review-comment-{{ result.ResultID }}"
                placeholder="Review Message"
                *ngIf="isReview"
            ></cerrix-textarea>
            <br *ngIf="!isReview" />
            <pre class="readonly-comment" *ngIf="!isReview">{{ reviewComment }}</pre>
            <hr />
        </div>

        <!-- Actually defined pages -->
        <div
            *ngFor="let page of form.Pages; let pI = index"
            class="detail-page-card"
            [attr.name]="page.Title"
            menusystemitem
            [attr.menuicon]="result.PageResults[pI]._pageIcon"
        >
            <div class="detail-page-card-title">{{ page.Title }}</div>
            <div class="detail-page-card-body padded form-group-styling">
                <div class="review-message" *ngIf="page.Type == pageTypes.Review">
                    <small class="text-muted" *ngIf="pI != form.Pages.length - 1">
                        This is a review page assigned to user
                        {{ result.PageResults[pI].UserName }}.
                    </small>
                    <small class="text-muted" *ngIf="pI == form.Pages.length - 1">
                        You are currently reviewing the submitted answers on the previous pages.
                        <br />
                        On every field a comment can be placed as well as a global comment for the
                        entire entry. <br />
                        After the review is done, this page gives the option to either accept or
                        reject the entry in it's current state. <br /><br />

                        Note: The global comment will be shown in the workspace.
                    </small>
                </div>

                <div class="form-group" *ngFor="let field of page.Fields">
                    <form-entry-field
                        [field]="field"
                        [(answer)]="answers[field.ID]"
                        [readonly]="
                            (result && result.PageResults[pI].Completed) || (readOnly && !isPreview)
                        "
                        [isReview]="isReview"
                        [(comment)]="comments[field.ID]"
                        (answerChange)="answerChanged = true"
                        (commentChange)="answerChanged = true"
                    >
                    </form-entry-field>
                </div>
            </div>
        </div>

        <!-- Last page if it is a summary page. -->
        <div
            class="page-fields"
            name="Summary"
            menuicon="fas fa-eye"
            menusystemitem
            *ngIf="form.ShowSummary"
        >
            <div class="detail-page-card" *ngFor="let page of form.Pages; let pI = index">
                <div *ngIf="userIsViewingSummary">
                    <div class="detail-page-card-title">
                        {{ page.Title }}<br />
                        <small class="text-muted" *ngIf="result.PageResults[pI].UserName">
                            {{ page.Type == pageTypes.Entry ? "Entry" : "Review" }} page
                            {{ result.PageResults[pI].Completed ? "completed by" : "assigned to" }}
                            {{ result.PageResults[pI].UserName }}
                        </small>
                    </div>

                    <div class="detail-page-card-body padded form-group-styling">
                        <div class="review-summary-message" *ngIf="page.Type == pageTypes.Review">
                            <small class="text-muted"> Review page </small>
                        </div>

                        <div class="form-group" *ngFor="let field of page.Fields">
                            <form-entry-field
                                [field]="field"
                                [answer]="answers[field.ID]"
                                [comment]="comments[field.ID]"
                                [readonly]="true"
                            >
                            </form-entry-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-bottom-controls mb-2">
        <button class="btn btn-sm" (click)="previousPage()" [disabled]="selectedIndex <= 0">
            <i class="fas fa-angle-left" aria-hidden="true"></i>
            <span class="d-none d-lg-inline ms-2">Previous page</span>
        </button>
        <button class="btn btn-sm" (click)="nextPage()" [disabled]="selectedIndex >= lastPageIndex">
            <span class="d-none d-lg-inline">Next page</span>
            <i class="fas fa-angle-right ms-2" aria-hidden="true"></i>
        </button>
    </div>

    <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        id="form-detail-modal"
        data-backdrop="static"
        data-keyboard="false"
        #popupModal
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Workflow {{ reviewRejected ? "Rejection" : "Completion" }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-group row" *ngIf="reviewRejected != true">
                        <label for="followingUserSelect" class="col-sm-3 col-form-label"
                            >Follow-up User</label
                        >
                        <div class="col-sm-9">
                            <ng-select
                                id="followingUserSelect"
                                name="followingUserSelect"
                                [items]="followingUsers"
                                [(ngModel)]="selectedNextUser"
                                bindValue="Key"
                                bindLabel="Value"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <!-- Review Page get rejection options -->
                    <div class="form-group row" *ngIf="reviewRejected == true">
                        <label for="rejectedPageSelect" class="col-sm-3 col-form-label"
                            >Reject to page</label
                        >
                        <div class="col-sm-9">
                            <ng-select
                                id="rejectedPageSelect"
                                name="rejectedPageSelect"
                                [items]="rejectablePages"
                                [(ngModel)]="rejectPage"
                                bindValue="Key"
                                bindLabel="Value"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button
                        type="button"
                        class="btn btn-{{
                            isReview ? (reviewRejected ? 'danger' : 'success') : 'cerrix'
                        }}"
                        (click)="storeResult(true)"
                        [attr.disabled]="
                            selectedNextUser === undefined && !isReview ? 'disabled' : null
                        "
                    >
                        <i
                            class="fas fa-{{ reviewRejected ? 'times' : 'check' }}"
                            aria-hidden="true"
                        ></i>
                        <span class="d-none d-lg-inline {{ isReview ? 'ms-2' : '' }}">{{
                            isReview ? (reviewRejected ? "Reject" : "Accept") : "Complete"
                        }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
