import { Component, Input } from "@angular/core";
import { BaseTaskModel } from "@app/tasks/models/BaseTaskModel";
import { TaskDataService } from "@app/tasks/services/TaskDataService";
import { ControlLinkModel } from "@models/controls/ControlLinkModel";

@Component({
    selector: "task-controls",
    templateUrl: "./task-controls.component.html",
})
export class TaskControlsComponent {
    @Input() model: BaseTaskModel;
    @Input() disabled = false;

    linked: ControlLinkModel[];
    unlinked: ControlLinkModel[];
    isLoading = true;
    constructor(private _ds: TaskDataService) {}

    load() {
        if (!this.model || !this.isLoading) {
            return;
        }

        if (!this.model.controlIds) {
            this.model.controlIds = [];
        }

        this._ds.getLinkableControls().subscribe((value) => {
            this.loadData(this.model.controlIds, value.sortBy("name", true));
            this.isLoading = false;
        });
    }

    linkedChanged(newList: ControlLinkModel[]) {
        this.model.controlIds = newList.map((x) => x.id);
    }

    private loadData(linkedIds: number[], items: ControlLinkModel[]) {
        const linked = [],
            unlinked = [];

        for (const item of items) {
            if (linkedIds.indexOf(item.id) >= 0) {
                linked.push(item);
            } else {
                unlinked.push(item);
            }
        }
        this.linked = linked;
        this.unlinked = unlinked;
    }
}
