import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { EventDataService } from "@app/event/services/EventDataService";
import { UserService } from "@app/user/user-profile/shared/user.service";
import {
    getFormControl,
    getFormValue,
    setFormControlValue,
    toPromise,
} from "@methods/CommonMethods";
import { getCustomFieldValueProperty } from "@methods/CustomFieldMethods";
import { FindNodes } from "@methods/TreeMethods";
import { CustomFieldInfoModel } from "@models/customfields/CustomFieldInfoModel";
import { CustomFieldStandingDataModel } from "@models/customfields/CustomFieldStandingDataModel";
import { EventBusinessStandingDataCollection } from "@models/event/EventBusinessStandingDataCollection";
import { EventHeavyStandingDataCollection } from "@models/event/EventHeavyStandingDataCollection";
import { EventLightStandingDataCollection } from "@models/event/EventLightStandingDataCollection";
import { EventModel } from "@models/event/EventModel";
import { EventPermissionModel } from "@models/event/EventPermissionModel";
import { CerrixTreeItem } from "@models/tree/CerrixTreeItem";
import { Observable } from "rxjs";

@Component({
    selector: "event-detail-main",
    templateUrl: "./event-detail-main.component.html",
    styleUrls: ["./event-detail-main.component.scss"],
})
export class EventDetailMainComponent implements OnInit {
    @Input() eventModel: EventModel;
    @Input() sdLight: EventLightStandingDataCollection;
    @Input() sdHeavy: EventHeavyStandingDataCollection;
    @Input() parentForm: FormGroup;
    @Input() permissions: EventPermissionModel;

    loadingData = false;
    selectedOrganizationId: number;
    businessDimensions: CerrixTreeItem[] = [];
    previousOrganizationId: number;
    maxDate = new Date();
    canChangeReporter = false;

    customFieldData: { [key: number]: CustomFieldInfoModel } = {};
    eventCustomFieldsFormGroup: FormGroup;
    customFieldStandingDataModel: CustomFieldStandingDataModel[] = [];

    constructor(private _eventDs: EventDataService, private _userDs: UserService) {}

    async ngOnInit() {
        this.selectedOrganizationId = getFormValue<EventModel>(this.parentForm, "OrganizationId");
        if (this.eventModel.OrganizationId > 0) {
            this.reloadBusinessData();
        }

        this.canChangeReporter = this.permissions.canChangeReporter;

        if (!this.canChangeReporter) {
            let reporterName = "";
            if (!this.eventModel.ID) {
                reporterName = await this._userDs.getUserName();
            } else {
                reporterName = this.eventModel.ReporterName;
            }
            getFormControl(this.parentForm, "ReporterName").setValue(reporterName);
        }
        await this.createCustomFieldsFormGroup();
    }

    async createCustomFieldsFormGroup() {
        const customFieldsFormArray = new FormArray([]);

        // GET THE DATA
        this.customFieldStandingDataModel = await toPromise(this._eventDs.getCustomFields());

        // backend error, console error...
        if (!this.eventModel.CustomFields) this.eventModel.CustomFields = [];

        for (const field of this.customFieldStandingDataModel) {
            const answerField = this.eventModel.CustomFields.find(
                (x) => x.customFieldId === field.ID
            );

            const customFieldFormGroup = new FormGroup<any>({
                ID: new FormControl(answerField ? answerField.ID : 0),
                Name: new FormControl(field.Name),
                customFieldId: new FormControl(field.ID),
                required: new FormControl(field.required),
            });

            const valueProperty = getCustomFieldValueProperty(field);
            customFieldFormGroup.addControl(
                valueProperty,
                new FormControl(
                    answerField ? answerField[valueProperty] : null,
                    field.required ? [Validators.required] : []
                )
            );

            // Store extra information from the custom field in a custom object which is easier accessible in the front-end
            this.customFieldData[field.ID] = {
                Name: field.Name,
                fieldType: field.fieldType,
                required: field.required,
                answerOptions: field.answerOptions,
            };

            customFieldsFormArray.push(customFieldFormGroup);
        }

        this.eventCustomFieldsFormGroup = new FormGroup({
            customFields: customFieldsFormArray,
        });

        if (this.permissions.detailsReadonly) {
            this.eventCustomFieldsFormGroup.disable();
        } else {
            this.eventCustomFieldsFormGroup.valueChanges.subscribe(() => {
                this.eventModel.CustomFields =
                    this.eventCustomFieldsFormGroup.value["customFields"];
            });
        }

        this.parentForm.addControl("customFields", customFieldsFormArray);
    }

    calculateNetCosts() {
        const grossCosts = getFormValue<EventModel>(this.parentForm, "GrossCosts");
        const recovery = getFormValue<EventModel>(this.parentForm, "Recovery");

        const netCosts = (+grossCosts - +recovery).toFixed(2);
        getFormControl<EventModel>(this.parentForm, "NetCosts").setValue(netCosts);
    }

    reloadBusinessData() {
        this.selectedOrganizationId = getFormValue<EventModel>(this.parentForm, "OrganizationId");
        this.businessDimensions = [];

        if (!this.selectedOrganizationId) {
            return;
        }

        const selectedBusinessDimensions = getFormValue<EventModel>(
            this.parentForm,
            "BusinessDimensions"
        );

        this.getBusinessData(this.selectedOrganizationId).subscribe((sdBusiness) => {
            this.businessDimensions = sdBusiness.BusinessDimensions;

            const selectedValues = FindNodes(
                this.businessDimensions,
                selectedBusinessDimensions
            ).map((x) => x.ID);

            setFormControlValue<EventModel>(this.parentForm, "BusinessDimensions", selectedValues);
        });
    }

    getBusinessData(organizationId: number): Observable<EventBusinessStandingDataCollection> {
        this.previousOrganizationId = organizationId;

        return this._eventDs.getBusinessSd(this.eventModel.Guid, organizationId);
    }
}
