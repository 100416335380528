import { EffectivenessStatusType } from "../enums/effectiveness-status-type.enum";
import { Serializable } from "@app/shared/interfaces/serializable";

export class WorkflowStepPermissionModel implements Serializable<WorkflowStepPermissionModel> {
    status = EffectivenessStatusType.undefined;
    isEditAllowed = false;
    isCompleteAllowed = false;
    isEditSampleRemarkAllowed = false;
    statusDescription = "";
    canViewSourceDocuments = false;
    canViewSampleDocuments = false;
    canViewTestStepDocuments = false;
    nonOccurrenceCompleteAllowed = false;
    reviewNonOccurrenceAllowed = false;
    testNonOccurrenceAllowed = false;
    sourceDocumentUploadAllowed = false;
    sampleGenerationAllowed = false;
    completeUploadingEvidenceAllowed = false;
    requestEvidenceUpload = false;
    completeAssessmentAllowed = false;
    reviewAllowed = false;
    completeAllowed = false;

    get evidenceUploadEnabled(): boolean {
        return this.status === EffectivenessStatusType.requestEvidence && this.isEditAllowed;
    }

    get testerAssessmentEnabled(): boolean {
        return (
            (this.status === EffectivenessStatusType.readyForAssessment ||
                this.status === EffectivenessStatusType.assessmentInProgress ||
                this.status === EffectivenessStatusType.waitingForConclusion) &&
            this.isEditAllowed
        );
    }

    get reviewerAssessmentEnabled(): boolean {
        return this.status === EffectivenessStatusType.waitingForReviewer && this.isEditAllowed;
    }

    get evidenceSaveAllowed(): boolean {
        return (
            this.evidenceUploadEnabled ||
            this.testerAssessmentEnabled ||
            this.reviewerAssessmentEnabled
        );
    }

    deserialize(input: any): WorkflowStepPermissionModel {
        this.status = input.status;
        this.isEditAllowed = input.isEditAllowed;
        this.isCompleteAllowed = input.isCompleteAllowed;
        this.isEditSampleRemarkAllowed = input.isEditSampleRemarkAllowed;
        this.statusDescription = input.statusDescription;
        this.canViewSourceDocuments = input.canViewSourceDocuments;
        this.canViewSampleDocuments = input.canViewSampleDocuments;
        this.canViewTestStepDocuments = input.canViewTestStepDocuments;

        this.processPermissions();

        return this;
    }

    private processPermissions(): void {
        this.nonOccurrenceCompleteAllowed = this.CompleteAllowed(
            EffectivenessStatusType.nonOccurrenceCompleted
        );
        this.reviewNonOccurrenceAllowed = this.CompleteAllowed(
            EffectivenessStatusType.reviewNonOccurrence
        );
        this.testNonOccurrenceAllowed = this.CompleteAllowed(EffectivenessStatusType.nonOccurrence);
        this.sourceDocumentUploadAllowed = this.CompleteAllowed(
            EffectivenessStatusType.waitingForSourceDocuments
        );
        this.sampleGenerationAllowed = this.CompleteAllowed(
            EffectivenessStatusType.waitingForSamples
        );
        this.completeUploadingEvidenceAllowed = this.CompleteAllowed(
            EffectivenessStatusType.requestEvidence
        );
        this.requestEvidenceUpload = this.completeUploadingEvidenceAllowed;
        this.completeAssessmentAllowed = this.CompleteAllowed(
            EffectivenessStatusType.waitingForConclusion
        );
        this.reviewAllowed = this.CompleteAllowed(EffectivenessStatusType.waitingForReviewer);
        this.completeAllowed = this.CompleteAllowed(EffectivenessStatusType.completed);
    }

    private CompleteAllowed(status: EffectivenessStatusType): boolean {
        if (this.isCompleteAllowed) return this.status === status;
        return false;
    }
}
