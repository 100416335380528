import { AuditDataService } from "@app/audit/services/audit-data.service";
import { AuditDetailPermissionModel } from "./../../shared/permission-models/audit-detail-permission-model";
import { Component, OnInit } from "@angular/core";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ActivatedRoute } from "@angular/router";
import { AuditModel } from "@app/audit/shared/data-models/audit-model";
import { isGuid } from "@methods/uniqueMethods";
import { AuditDetailStandingDataModel } from "@app/audit/shared/page-models/audit-detail-standing-data-model";
import { toPromise } from "@methods/CommonMethods";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { FormValidationHelper } from "@app/shared/helpers/form-validation-helper";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { ToastrService } from "ngx-toastr";
import { Pages } from "@constants/pages/Pages";
import { HttpResponse } from "@angular/common/http";
import { Subject } from "rxjs";

@Component({
    selector: "audit-detail",
    templateUrl: "./audit-detail.component.html",
    styleUrls: ["./audit-detail.component.scss"],
})
export class AuditDetailComponent implements OnInit {
    tab: TabModel;
    id: string;
    auditGuid: string;

    formGroup: FormGroup;
    auditModel: AuditModel;
    standingData: AuditDetailStandingDataModel;

    permissions: AuditDetailPermissionModel;

    initReady = false;
    isSaved$ = new Subject<void>();

    constructor(
        protected _ds: AuditDataService,
        private route: ActivatedRoute,
        private _promptService: CerrixPromptService,
        private _toastr: ToastrService,
        private _pages: Pages
    ) {}

    async ngOnInit() {
        this.id = this.route.snapshot.params.id ? this.route.snapshot.params.id : this.tab.id;
        const isExisting = this.id && isGuid(this.id);
        this.auditGuid = isExisting ? this.id : null;

        this.permissions = await toPromise(this._ds.getPermissions(this.auditGuid));
        if (!this.permissions.hasAccess) {
            this.tab.close(false);
        }

        if (isExisting) {
            this.auditModel = await this._ds.getAudit(this.auditGuid);
        } else {
            this.auditModel = new AuditModel();
        }

        this.standingData = await toPromise(this._ds.getAuditStandingData(this.auditGuid));

        this.createFormGroup();

        this.tab.name =
            "(A) " +
            (this.auditModel.auditName
                ? +this.auditModel.identifier + " - " + this.auditModel.auditName
                : "New Audit");

        this.initReady = true;
    }

    save() {
        const savingPrompt = this._promptService.loader("Saving changes, please wait...");
        this._ds.storeAudit(this.auditModel).subscribe({
            next: (value: HttpResponse<string>) => {
                if (!value.ok || value.status != 200) {
                    return;
                }

                savingPrompt.close();
                this._toastr.success("", "Update completed");
                this.isSaved$.next();

                this.tab.id = value.body.toString();

                if (this.tab.parent && this.tab.parent.lookupname === this._pages.Audit) {
                    this.tab.parent.refresh();
                }
                this.tab.refresh();
            },
            error: () => {
                FormValidationHelper.markAllAsTouched(this.formGroup);
                savingPrompt.close();
            },
            complete: () => {
                savingPrompt.close();
            },
        });
    }

    private createFormGroup() {
        this.formGroup = new FormGroup({
            auditName: new FormControl(this.auditModel.auditName, [Validators.required]),
            auditPlan: new FormControl(this.auditModel.auditPlan),

            auditType: new FormControl(
                { value: this.auditModel.auditType, disabled: this.auditModel.id > 0 },
                [Validators.required]
            ),
            auditStatus: new FormControl(this.auditModel.auditStatus),
            criticality: new FormControl(this.auditModel.criticality),
            confidentiality: new FormControl(this.auditModel.confidentiality),
            auditScore: new FormControl(this.auditModel.auditScore),

            auditObjective: new FormControl(this.auditModel.auditObjective),
            auditRationale: new FormControl(this.auditModel.auditRationale),
            auditConclusion: new FormControl(this.auditModel.auditConclusion),

            sponsors: new FormControl(this.auditModel.sponsors),
            auditees: new FormControl(this.auditModel.auditees),
            auditors: new FormControl(this.auditModel.auditors),
            reviewers: new FormControl(this.auditModel.reviewers),
            chiefAuditExecutives: new FormControl(this.auditModel.chiefAuditExecutives),

            documentRequests: new FormArray([]),
        });

        if (!this.permissions.canEdit) {
            this.formGroup.disable();
        } else {
            if (!this.permissions.canEditDetails) {
                // The document request permissions are set per request in the requests component itself so exclude them from being disabled
                FormValidationHelper.disableFormGroupExcept<AuditModel>(
                    this.formGroup,
                    "documentRequests"
                );
            }

            this.formGroup.valueChanges.subscribe(() => {
                FormValidationHelper.mapToModel(this.formGroup, this.auditModel);
            });
        }
    }
}
