import { Component, Input } from "@angular/core";
import { GenericListField } from "@app/shared/models/GenericList/GenericListField";
import { DashboardWidgetModel } from "../shared/models/DashboardWidgetModel";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { MatDialogConfig } from "@angular/material/dialog";
import { ICerrixPrompt } from "@app/shared/cerrix-prompt/models/ICerrixPrompt";

@Component({
    selector: "global-filtering",
    templateUrl: "./global-filtering.component.html",
    styleUrls: ["./global-filtering.component.scss"],
})
export class GlobalFilteringComponent {
    @Input() widgets: DashboardWidgetModel[];
    @Input() filterValues: any = {};

    filters: GenericListField[];
    widgetsWithFilters: DashboardWidgetModel[];

    constructor(private _promptService: CerrixPromptService) {}

    open() {
        this.resetComp();

        if (!this.filterValues) {
            this.filterValues = {};
        }

        this.widgets.forEach((widget) => {
            if (
                widget.globalFilterKeys &&
                widget.globalFilterKeys.length > 0 &&
                widget.customConfig.globalFilterEnabled
            ) {
                this.widgetsWithFilters.push(widget);
                widget.globalFilterKeys.forEach((fk) => {
                    if (!this.filters.find((f) => f.fieldName === fk)) {
                        const filterToAdd = widget.configurations.find((f) => f.fieldName === fk);
                        if (filterToAdd) {
                            filterToAdd.selfInit = true;
                            this.filters.push(filterToAdd);
                        }
                    }
                });
            }
        });

        this.showPrompt();
    }

    showPrompt() {
        const promptConfig: Partial<MatDialogConfig<ICerrixPrompt>> = {
            data: {
                title: "Global dashboard filters",
                fields: this.filters,
                fieldResult: JSON.parse(JSON.stringify(this.filterValues)),
                confirmButton: {
                    text: "Apply",
                },
            },
        };

        if (this.filters.length == 0) {
            promptConfig.maxWidth = "400px";
            promptConfig.maxHeight = "250px";

            promptConfig.data.message = "No widgets with global filters configured.";
            promptConfig.data.confirmButton = { show: false };
            promptConfig.data.cancelButton = { text: "Close" };
        }

        this._promptService
            .prompt(promptConfig)
            .getResult()
            .subscribe((result) => {
                if (result) {
                    Object.getOwnPropertyNames(result).forEach((k) => {
                        this.filterValues[k] = result[k];
                    });
                    setTimeout(() => {
                        this.emitNewFilters();
                    }, 0);
                }
            });
    }

    emitNewFilters() {
        this.widgetsWithFilters.forEach((widget) => {
            if (widget.api.onGlobalFilterChange) {
                widget.api.onGlobalFilterChange();
            }
        });
    }

    resetComp() {
        this.filters = [];
        this.widgetsWithFilters = [];
    }
}
