import { DifferencesModel } from "@models/generic/DifferencesModel";

declare global {
    interface Array<T> {
        distinct(): this;
        distinctProp(prop: string): string[];
        sortDesc(): this;
        sortBy(prop: string, asc?: boolean): this;

        pushOrdered(item: any, propertyName: string);
        pushOrderedDesc(item: any, propertyName: string);

        any(): boolean;
        empty(): boolean;

        selectMany<T1, T2>(callbackFn: (value: T1) => T2[]): T2[];

        makeDistinct(prop: string, newProp?: () => any): this;

        getLowerIDByProp(prop: string, lowerBy?: number): number;
        getHigherIDByProp(prop: string, higherBy?: number): number;

        addRange(arr: any[]): any[];
        addTo(arr: any[]): void;
        getByProp<T>(prop: keyof T, value: any): T[];

        isDifferent(secondArr: T[]): boolean;
        pushIfNotExists(item: any): this;

        first(): T;
        last(): T;
        findDuplicatesByProp<T>(prop: keyof T): any[];
        getDuplicates<T>(prop: keyof T): any[];

        findDifferences<TLeft, TRight>(
            right: TRight[],
            compareFunc: (l: TLeft, r: TRight) => boolean
        ): DifferencesModel;

        groupBy<T>(prop: keyof T): {};

        clear(): void;
    }
}

Array.prototype.distinct = function () {
    return Array.from(new Set(this));
};

Array.prototype.distinctProp = function (prop: string): string[] {
    return Array.from(new Set(this.map((item: any) => item[prop]))) as string[];
};

Array.prototype.sortDesc = function () {
    const sorted = this.sort((a, b) => {
        if (a < b) {
            return 1;
        }
        if (a > b) {
            return -1;
        }
        return 0;
    });

    return sorted;
};

Array.prototype.sortBy = function (prop: string, asc?: boolean) {
    const sorted = this.sort((a, b) => {
        if (asc) {
            if (a[prop] < b[prop]) {
                return -1;
            }
            if (a[prop] > b[prop]) {
                return 1;
            }
        } else {
            if (a[prop] < b[prop]) {
                return 1;
            }
            if (a[prop] > b[prop]) {
                return -1;
            }
        }
        return 0;
    });

    return sorted;
};

Array.prototype.pushOrdered = function pushOrdered(item: any, propertyName: string) {
    const sortByValue = item[propertyName];
    for (let i = 0; i < this.length; i++) {
        if (this[i][propertyName] > sortByValue) {
            this.splice(i, 0, item);
            return;
        }
    }

    this.push(item);
};

Array.prototype.pushOrderedDesc = function pushOrderedDesc(item: any, propertyName: string) {
    const sortByValue = item[propertyName];
    for (let i = 0; i < this.length; i++) {
        if (this[i][propertyName] < sortByValue) {
            this.splice(i, 0, item);
            return;
        }
    }

    this.push(item);
};

Array.prototype.any = function (): boolean {
    return (this as any[]).length > 0;
};

Array.prototype.empty = function (): boolean {
    return (this as any[]).length <= 0;
};

Array.prototype.selectMany = function <T1, T2>(callbackFn: (value: T1) => T2[]): T2[] {
    let result: T2[] = [];
    this.forEach((t1Item) => {
        let t2Items = callbackFn(t1Item);

        if (t2Items) {
            t2Items.forEach((t2Item) => {
                result.push(t2Item);
            });
        }
    });

    return result;
};

Array.prototype.makeDistinct = function (prop: string, newProp: () => any) {
    const identifiableList = [];
    (this as any[]).forEach((obj) => {
        const dupe = identifiableList.find((i) => i[prop] === obj[prop]);
        if (dupe) {
            obj[prop] = newProp();
        }
        identifiableList.push(obj);
    });
    return identifiableList;
};

Array.prototype.getLowerIDByProp = function (prop?: string, lowerBy?: number): number {
    const arr = this as any[];
    let lowest = Number.MAX_VALUE;
    arr.forEach((obj) => {
        let value: number = Number.MAX_VALUE;
        if (prop && prop.trim().length > 0) {
            value = obj[prop] as number;
        } else {
            value = obj as number;
        }
        lowest = value < lowest ? value : lowest;
    });
    lowerBy = lowerBy ? lowerBy : 1;
    lowest = lowest === Number.MAX_VALUE ? 0 : lowest;
    return lowest - lowerBy;
};

Array.prototype.getHigherIDByProp = function (prop: string, higherBy?: number): number {
    const arr = this as any[];
    let highest = Number.MIN_VALUE;
    arr.forEach((obj) => {
        let value: number = Number.MIN_VALUE;
        if (prop && prop.trim().length > 0) {
            value = obj[prop] as number;
        } else {
            value = obj as number;
        }
        highest = value > highest ? value : highest;
    });
    higherBy = higherBy ? higherBy : 1;
    highest = highest === Number.MIN_VALUE ? 0 : highest;
    return highest + higherBy;
};

Array.prototype.addRange = function (arr: any[]): any[] {
    arr.forEach(function (v) {
        this.push(v);
    }, this);

    return this;
};

/**
 * Adds the array this is called on to the given param
 * @param arr The array to which the current items are added
 */
Array.prototype.addTo = function (arr: any[]) {
    const items = this as any[];
    arr.addRange(items);
};

Array.prototype.getByProp = function <T>(prop: keyof T, value: any): T[] {
    const items = this.filter((x) => x[prop] === value);
    return items;
};

Array.prototype.isDifferent = function (secondArr: any[]): boolean {
    const arr = this as any[];
    let allKeysFound = true;

    if (secondArr === null || arr.length !== secondArr.length) {
        return true; // One list is empty.
    }

    arr.forEach((a) => {
        if (secondArr.indexOf(a) < 0) {
            allKeysFound = false;
        }
    });

    return !allKeysFound;
};

Array.prototype.pushIfNotExists = function (item: any) {
    if (this.indexOf(item) === -1) {
        this.push(item);
    }

    return this;
};

Array.prototype.first = function () {
    const arr = this as any[];
    if (arr && arr.length > 0) {
        return arr[0];
    }

    return null;
};

Array.prototype.last = function () {
    const arr = this as any[];
    if (arr && arr.length > 0) {
        return arr[arr.length - 1];
    }

    return null;
};

Array.prototype.findDuplicatesByProp = function <T>(prop: keyof T) {
    const arr = this as any[];

    const uniq = arr
        .map((x) => {
            return {
                count: 1,
                obj: x[prop.toString()],
            };
        })
        .reduce((a, b) => {
            a[b.obj] = (a[b.obj] || 0) + b.count;
            return a;
        }, {});

    const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);

    return duplicates;
};

Array.prototype.getDuplicates = function <T>(prop: keyof T) {
    const arr = this as T[];

    const duplicateProps = arr.findDuplicatesByProp(prop);
    const duplicateObjects: T[] = [];

    duplicateProps.forEach((x) => {
        // We need double == here, since we don't know the type
        const duplicates = arr.filter((a) => a[prop] == x);

        if (duplicates.length > 0) {
            duplicateObjects.push(duplicates[0]);
        }
    });

    return duplicateObjects;
};

Array.prototype.findDifferences = function <TLeft, TRight>(
    right: TRight[],
    compareFunc: (l: TLeft, r: TRight) => boolean
) {
    const left = this as TLeft[];

    const added = right.filter((r) => left.filter((l) => compareFunc(l, r)).length === 0);
    const deleted = left.filter((l) => right.filter((r) => compareFunc(l, r)).length === 0);

    return <DifferencesModel>{
        added: added,
        deleted: deleted,
    };
};

Array.prototype.groupBy = function <T>(prop: keyof T) {
    const arr = this as T[];

    const groupedObj = arr.reduce((g: any, item: T) => {
        g[item[prop]] = g[item[prop]] || []; // Check if the value exists, if not assign a new array
        g[item[prop]].push(item); // Push the new value to the array
        return g;
    }, {});

    return groupedObj;
};

Array.prototype.clear = function () {
    this.splice(0, this.length);
};

export {};
