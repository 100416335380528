import { Component } from "@angular/core";
import { CalendarSettingsModel } from "@app/calendar/models/CalendarSettingsModel";
import { CalendarDataService } from "@app/calendar/services/calendar-data.service";
import { isDirty } from "@methods/CommonMethods";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "calendar-settings",
    templateUrl: "./calendarsettings.component.html",
})
export class CalendarSettingsComponent {
    tab: TabModel;

    settings: CalendarSettingsModel;
    settingsCompareBackup: CalendarSettingsModel;

    formDirty = false;

    constructor(private dataService: CalendarDataService, private toastr: ToastrService) {
        dataService.getSettings().subscribe((x) => {
            this.settings = JSON.parse(JSON.stringify(x));
            this.settingsCompareBackup = JSON.parse(JSON.stringify(x));

            this.tab.showLoader = false;
        });
    }

    changed() {
        this.formDirty = isDirty(this.settingsCompareBackup, this.settings);
    }

    save() {
        if (this.formDirty) {
            this.dataService.storeSettings(this.settings).subscribe((x) => {
                // settings can cause change of data so set cached to null.
                this.dataService.loadCalendar();

                this.formDirty = false;
                this.toastr.success("Successfully updated calendar settings.");
            });
        }
    }
}
