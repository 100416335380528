import { CerrixActionButtonConfig } from "../CerrixButtonConfig";
import { GenericManagerConfig } from "./GenericManagerConfig";

export class GenericListConfig extends GenericManagerConfig {
    permissionProp?: any;

    /** This will not work if sortable is enabled. Otherwise this will make the view only show a max amount of items. The user has to click show more to see more. */
    limitViewTo?: number;

    isSortable?: boolean;
    sortProp?: string;

    /** This means sorting like in the workspace. This config will be a lot like isSortable, but only one can be true and the isSortable flag is leading.
     * Also this setting will not work in combination of the limitViewTo config.
     */
    isSortableByColumn?: boolean;

    showToolbarSearch?: boolean;
    customModalClass?: string;

    _visibleRowCount?: number;

    onSortChange?: (sortedData) => void;
    onSearch?: (terms: string) => void;
    extraButtons?: CerrixActionButtonConfig[];
}
