import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { CerrixPromptComponent } from "@app/shared/cerrix-prompt/cerrix-prompt.component";
import {
    GenericListField,
    GenericListFieldType,
} from "@app/shared/models/GenericList/GenericListField";
import { CerrixPromptService } from "@app/shared/services/cerrix-prompt.service";
import { TabEventListenerType } from "@enums/TabEventListenerType.enum";
import { TabMenu } from "@models/generic/TabModels/TabMenu";
import { TabMenuItem } from "@models/generic/TabModels/TabMenuItem";
import { TabModel } from "@models/generic/TabModels/TabModel";
import { TabService } from "@services/tabs/TabService";
import { of } from "rxjs";
import { DashboardService } from "../services/dashboard.service";
import { DashboardModel } from "../shared/models/DashboardModel";

@Component({
    selector: "dashboard-manager",
    templateUrl: "./dashboard-manager.component.html",
    styleUrls: ["./dashboard-manager.component.scss"],
})
export class DashboardManagerComponent implements OnInit {
    @ViewChild("allDashboardView", { static: true }) allDashboardView: ElementRef;

    @Input() cerrixTab: TabModel;
    @Output() load = new EventEmitter<DashboardModel>();

    currentDashboard: DashboardModel;

    openPrompt: CerrixPromptComponent;
    favorites: DashboardModel[] = [];
    roleGroups: DashboardModel[] = [];

    allDashboards: DashboardModel[];

    scopeFilter: string;
    scopedDashboards: DashboardModel[];

    constructor(
        private _tabService: TabService,
        private _ds: DashboardService,
        private _prompt: CerrixPromptService
    ) {}

    ngOnInit() {
        if (this.cerrixTab.identifier === this._tabService.activeTab.identifier) {
            this.initDashboard();
        } else {
            const focusListenerID = this._tabService.listeners.addTabListener(
                TabEventListenerType.OnFocus,
                this.cerrixTab.identifier,
                () => {
                    if (this.cerrixTab.menu) {
                        return;
                    }

                    this.initDashboard();
                    this._tabService.listeners.removeListener(focusListenerID);
                },
                this.cerrixTab
            );
        }
    }

    initDashboard() {
        this.createPersonalView(true);
    }

    async loadDashboard(dashboard: DashboardModel) {
        if (this.currentDashboard && this.currentDashboard._unsavedChanges) {
            const continueLoad = await this._prompt
                .confirm(
                    "Unsaved changes",
                    "There are unsaved changes, which may be lost. Do you wish to continue?"
                )
                .toPromise();

            if (!continueLoad) {
                return;
            }
        }

        if (!dashboard.api) {
            this.bindApi(dashboard);
        }

        if (this.cerrixTab.menu) {
            this.cerrixTab.menu.activeMenuItem = dashboard.guid;
        }

        this.currentDashboard = dashboard;
        this.load.emit(dashboard);

        if (this.openPrompt) {
            this.openPrompt.close();
            this.openPrompt = null;
        }
    }

    createPersonalView(loadInitial: boolean) {
        this.favorites = [];
        this.roleGroups = [];

        this._ds.getPersonal().subscribe((data) => {
            data.forEach((db) => {
                this.bindApi(db);
            });

            this.favorites = data.filter((d) => d.favorite);
            this.roleGroups = data.filter((d) => !d.favorite && d.roleGroupAssigned);

            if (loadInitial) {
                if (this.favorites.any()) {
                    const defaultDb = this.favorites.find((x) => x.default);
                    if (defaultDb) {
                        this.loadDashboard(defaultDb);
                    } else {
                        this.loadDashboard(this.favorites[0]);
                    }
                } else if (this.roleGroups.any()) {
                    this.loadDashboard(this.roleGroups[0]);
                } else if (data.length == 0) {
                    this.getDefaultDashboard(false).then((x) => {
                        this.loadDashboard(x);
                    });
                }
            }

            this.createDefaultSideMenu();
        });
    }

    showBrowseAll() {
        if (this.openPrompt) {
            this.openPrompt.close();
            this.openPrompt = null;
        }

        this.loadBrowseAllData();

        this.openPrompt = this._prompt.prompt({
            maxWidth: "1200px",
            maxHeight: "800px",
            data: {
                title: "All available dashboards",
                customTemplate: this.allDashboardView,
                confirmButton: {
                    show: false,
                },
                cancelButton: {
                    text: "Close",
                },
            },
        });
    }

    private async loadBrowseAllData() {
        this.allDashboards = null;

        let data = await this._ds.getAll().toPromise();
        if (!data) {
            data = [];
        }

        data.forEach((db) => {
            this.bindApi(db);
        });

        this.allDashboards = data;
        this.filterDashboards();
    }

    filterDashboards() {
        if (!this.scopeFilter) {
            this.scopedDashboards = this.allDashboards;
            return;
        }

        const keywords = this.scopeFilter.toLowerCase().split(" ");
        const compare = (db: DashboardModel, text: string) => {
            if (
                db.name.toLowerCase().indexOf(text) >= 0 ||
                db.description.toLowerCase().indexOf(text) >= 0 ||
                db.createdBy.toLowerCase().indexOf(text) >= 0
            ) {
                return true;
            }

            return false;
        };

        this.scopedDashboards = [];
        this.allDashboards.forEach((db) => {
            if (keywords.some((kw) => compare(db, kw))) {
                this.scopedDashboards.push(db);
            }
        });
    }

    bindApi(dashboard: DashboardModel) {
        if (!dashboard.api) {
            dashboard.api = {};
        }

        dashboard.api.save = this.saveDashboard.bind(this, dashboard);
        dashboard.api.delete = this.delete.bind(this, dashboard);
        dashboard.api.showInfo = this.showDashboardInfo.bind(this, dashboard);
        dashboard.api.toggleFavorite = this.toggleFavorite.bind(this, dashboard);
        dashboard.api.setDefault = this.setAsDefault.bind(this, dashboard);
    }

    async saveDashboard(dashboard: DashboardModel, skipSaveCheck?: boolean) {
        if (!dashboard.canEdit) {
            this._prompt.alert({
                data: {
                    title: "Cannot save!",
                    message: "You are not the owner of this dashboard and do not have edit rights!",
                },
            });
            return false;
        }

        const save = skipSaveCheck
            ? true
            : await this._prompt
                  .confirm(
                      "Save changes?",
                      "Clicking cancel will add a 'Save changes' button to the toolbar."
                  )
                  .toPromise();

        if (save) {
            try {
                const result = await this._ds.store(dashboard).toPromise();

                Object.keys(result).forEach(function (key) {
                    dashboard[key] = result[key];
                });

                dashboard._unsavedChanges = false;
                this.currentDashboard = result;
                this.createPersonalView(false);
                return true;
            } catch (error) {
                return false;
            }
        } else {
            dashboard._unsavedChanges = true;
            return true;
        }
    }

    async toggleFavorite(dashboard: DashboardModel) {
        if (!dashboard.guid) {
            this._prompt.alert({
                data: {
                    title: "Dashboard not saved!",
                    message:
                        "This dashboard has not been saved yet, so cannot toggle favorite status.",
                },
            });

            return;
        }

        if (dashboard.default) {
            this._prompt.alert({
                data: {
                    title: "Cannot unfavorite!",
                    message:
                        "Cannot unfavorite dashboard, because it is configured as default. Select another dashboard as default first.",
                },
            });

            return;
        }

        if (dashboard.favorite) {
            await this._ds.unfavorite(dashboard.guid).toPromise();
            dashboard.favorite = false;
            dashboard.default = false;
        } else {
            await this._ds.favorite(dashboard.guid).toPromise();
            dashboard.favorite = true;
        }

        this.createPersonalView(false);
    }

    async setAsDefault(dashboard: DashboardModel) {
        if (!dashboard.guid) {
            this._prompt.alert({
                data: {
                    title: "Dashboard not saved!",
                    message: "This dashboard has not been saved yet, so cannot set as default.",
                },
            });

            return;
        }

        if (dashboard.default) {
            await this._ds.resetDefault(dashboard.guid).toPromise();
            dashboard.default = false;
            dashboard.favorite = true;
        } else {
            await this._ds.default(dashboard.guid).toPromise();
            dashboard.default = true;
            dashboard.favorite = true;
        }

        this.loadBrowseAllData();
        this.createPersonalView(false);
    }

    async delete(dashboard: DashboardModel) {
        if (!dashboard.guid) {
            this._prompt.alert({
                data: {
                    title: "Dashboard isn't saved!",
                    message: "Dashboard is not saved yet, so cannot delete.",
                },
            });

            return;
        }

        if (dashboard.default) {
            const ignoreDefault = await this._prompt
                .confirm(
                    "Default dashboard",
                    "This dashboard is configured as default. Are you sure you want to continue?"
                )
                .toPromise();

            if (!ignoreDefault) {
                return false;
            }
        }

        await this._ds.deleteDashboard(dashboard.guid).toPromise();

        this.loadBrowseAllData();
        this.createPersonalView(false);
    }

    async createNew() {
        if (!this.allDashboards && !Array.isArray(this.allDashboards)) {
            await this.loadBrowseAllData();
        }

        const myDefaultDashboard = Array.isArray(this.allDashboards)
            ? this.allDashboards.find((d) => d.default)
            : null;
        const systemDefaultIdentifier = "##systemDefaultDashboard";
        const emptyIdentifier = "##emptyDashboard";

        this._prompt
            .prompt({
                maxWidth: "500px",
                maxHeight: "500px",
                data: {
                    title: "New dashboard",
                    message: "Select a template you would like to start with:",
                    fields: [
                        {
                            fieldName: "template",
                            prettyName: "Template",
                            fieldType: GenericListFieldType.SingleSelect,
                            required: true,
                            selfInit: true,
                            getDataMethod: () => {
                                const options: { ID: string; Name: string }[] = [];

                                options.push({
                                    ID: emptyIdentifier,
                                    Name: "Empty",
                                });

                                if (myDefaultDashboard) {
                                    options.push({
                                        ID: myDefaultDashboard.guid,
                                        Name: `My Default (${myDefaultDashboard.name})`,
                                    });
                                }

                                options.push({
                                    ID: systemDefaultIdentifier,
                                    Name: "System Default",
                                });

                                if (this.allDashboards.length > 0) {
                                    const dbTemplates = this.allDashboards
                                        .map((db) => {
                                            return { ID: db.guid, Name: db.name };
                                        })
                                        .filter(
                                            (db) =>
                                                !myDefaultDashboard ||
                                                myDefaultDashboard.guid != db.ID
                                        );

                                    options.push(...dbTemplates);
                                }

                                return of(options);
                            },
                        },
                    ],
                    fieldResult: {
                        template: emptyIdentifier,
                    },
                    confirmButton: {
                        text: "Create",
                        icon: "far fa-plus",
                    },
                },
            })
            .getResult()
            .subscribe(async (result) => {
                const selectedTemplate = result ? result["template"] : null;
                if (selectedTemplate) {
                    let toLoad: DashboardModel;

                    if (myDefaultDashboard && selectedTemplate == myDefaultDashboard.guid) {
                        toLoad = this.createCopyOfDashboard(myDefaultDashboard);
                    } else if (selectedTemplate == systemDefaultIdentifier) {
                        toLoad = await this.getDefaultDashboard(true);
                    } else if (selectedTemplate == emptyIdentifier) {
                        toLoad = this.getEmptyDashboard();
                    } else {
                        const existing = this.allDashboards.find((d) => d.guid == selectedTemplate);
                        toLoad = this.createCopyOfDashboard(existing);
                    }

                    if (toLoad) {
                        this.loadDashboard(toLoad);
                    }
                }
            });
    }

    private getEmptyDashboard() {
        const emptyDashboard: DashboardModel = {
            guid: null,

            name: "",
            description: "",
            icon: "fad fa-th",
            screenSize: window.screen.width + "x" + window.screen.height,

            createdBy: "-",
            updatedOn: new Date(),

            isOwner: true,
            canEdit: true,

            systemDefault: false,
            public: false,

            default: false,
            favorite: true,
            roleGroupAssigned: false,
            roleGroups: [],

            widgetConfigurations: "",
            globalFilterValues: {},

            _editOnLoad: true,
        };

        return emptyDashboard;
    }

    private async getDefaultDashboard(isNewDashboard: boolean) {
        let dashboard = await this._ds.getDefault().toPromise();

        if (isNewDashboard || !dashboard.guid) {
            const template = this.getEmptyDashboard();

            template.name = dashboard.name;
            template.description = dashboard.description;
            template.widgetConfigurations = dashboard.widgetConfigurations;
            template.globalFilterValues = dashboard.globalFilterValues;

            if (!isNewDashboard) {
                template.isOwner = false;
                template.favorite = false;
                template._editOnLoad = false;
            }

            dashboard = template;
        } else {
            dashboard._editOnLoad = false;
        }

        return dashboard;
    }

    private createCopyOfDashboard(model: DashboardModel) {
        const newModel = this.getEmptyDashboard();

        newModel.name = model.name;
        newModel.description = model.description;
        newModel.icon = model.icon;

        newModel.widgetConfigurations = model.widgetConfigurations;
        newModel.globalFilterValues = model.globalFilterValues;

        return newModel;
    }

    showDashboardInfo(dashboard: DashboardModel) {
        const currentResolution = window.screen.width + "x" + window.screen.height;
        const fields: GenericListField[] = [];
        let height = 530;

        if (dashboard.description) {
            height += 175;
            fields.push({
                prettyName: "Description",
                fieldName: "description",
                fieldType: GenericListFieldType.TextArea,
                isReadonly: true,
            });
        }

        fields.addRange([
            {
                prettyName: "Created by",
                fieldName: "createdBy",
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                prettyName: "Last updated on",
                fieldName: "updatedOn",
                fieldType: GenericListFieldType.DateTime,
                isReadonly: true,
            },
            {
                prettyName: "Optimized screen resolution",
                fieldName: "screenSize",
                description:
                    currentResolution !== dashboard.screenSize
                        ? `Dashboard can look different than creator intended, because current resolution (${currentResolution}) is different than resolution the dashboard was created on.`
                        : undefined,
                fieldType: GenericListFieldType.Text,
                isReadonly: true,
            },
            {
                prettyName: "Is set as default",
                fieldName: "default",
                fieldType: GenericListFieldType.CheckBox,
                isReadonly: true,
                hideFillers: true,
            },
            {
                prettyName: "Is favorited",
                fieldName: "favorite",
                fieldType: GenericListFieldType.CheckBox,
                isReadonly: true,
                hideFillers: true,
            },
            {
                prettyName: "Is system default",
                fieldName: "systemDefault",
                fieldType: GenericListFieldType.CheckBox,
                isReadonly: true,
                hideFillers: true,
            },
            {
                prettyName: "Inherited from role group",
                fieldName: "roleGroupAssigned",
                fieldType: GenericListFieldType.CheckBox,
                isReadonly: true,
                hideFillers: true,
            },
        ]);

        this._prompt.prompt({
            maxWidth: "700px",
            maxHeight: height + "px",
            data: {
                title: dashboard.name,
                confirmButton: {
                    show: false,
                },
                cancelButton: {
                    text: "Close",
                },
                fields: fields,
                fieldResult: dashboard,
            },
        });
    }

    private createDefaultSideMenu() {
        const menu = new TabMenu();
        menu.menuItemClicked = (menuItem) => {
            if (menuItem.menuItemId === "createnew") {
                this.createNew();
            } else if (menuItem.menuItemId === "browse") {
                this.showBrowseAll();
            } else {
                let foundItem = this.favorites.find((i) => i.guid == menuItem.menuItemId);
                if (!foundItem) {
                    foundItem = this.roleGroups.find((i) => i.guid == menuItem.menuItemId);
                }

                if (foundItem) {
                    this.loadDashboard(foundItem);
                    menu.activeMenuItem = menuItem.identifier;
                }
            }
        };

        if (this.currentDashboard) {
            menu.activeMenuItem = this.currentDashboard.guid;
        }

        menu.menuItems = [];

        const createNewItem = new TabMenuItem();
        createNewItem.name = "Create new";
        createNewItem.iconClass = "fal fa-plus";
        createNewItem.menuItemId = "createnew";
        menu.menuItems.push(createNewItem);

        const browseItem = new TabMenuItem();
        browseItem.name = "Browse all";
        browseItem.iconClass = "fad fa-folder-open";
        browseItem.menuItemId = "browse";
        menu.menuItems.push(browseItem);

        if (this.favorites.any()) {
            const favorites = new TabMenuItem();
            favorites.name = "Favorites";
            favorites.iconClass = "fas fa-star";
            favorites.menuItemId = "favorites";
            favorites.clickable = false;
            favorites.children = this.favorites.map((i) => {
                const item = new TabMenuItem();
                item.identifier = i.guid;
                item.name = i.name;
                item.iconClass = i.icon;
                item.menuItemId = i.guid;
                return item;
            });
            menu.menuItems.push(favorites);
        }

        if (this.roleGroups.any()) {
            const rolegroups = new TabMenuItem();
            rolegroups.name = "Role Group";
            rolegroups.iconClass = "fad fa-folder-open";
            rolegroups.menuItemId = "rolegroups";
            rolegroups.clickable = false;
            rolegroups.children = this.roleGroups.map((i) => {
                const item = new TabMenuItem();
                item.identifier = i.guid;
                item.name = i.name;
                item.iconClass = i.icon;
                item.menuItemId = i.guid;
                return item;
            });
            menu.menuItems.push(rolegroups);
        }

        if (!this.cerrixTab.menu) {
            this.cerrixTab.menu = menu;
        } else {
            this.cerrixTab.menu = null;
            // This forces the front to render correctly, when have updates for the menu.
            setTimeout(() => {
                this.cerrixTab.menu = menu;
            }, 10);
        }
    }
}
