<cerrix-loader [cerrixTab]="tab"></cerrix-loader>

<div class="detail-page-action-bar" *ngIf="eventModel">
    <button
        class="btn btn-cerrix"
        (click)="save()"
        *ngIf="eventPermissions.canSave || eventPermissions.canSaveDataBreach"
    >
        <i class="fas fa-save"></i>
        <span data-uiid="event-save" class="d-none d-lg-inline">Save event</span>
    </button>

    <span class="date-edited" *ngIf="eventModel.ID > 0">
        Last save: {{ eventModel.LastSaveDate | dateTimeFormat }}
    </span>

    <div class="workflow-area">
        <span class="workflow-description">
            Workflow Status |
            <b>{{
                eventModel.Workflow && eventModel.Workflow.StatusDescription
                    ? eventModel.Workflow.StatusDescription
                    : "New Event"
            }}</b>
            <i
                class="far fa-question-circle mx-2"
                [matTooltip]="
                    eventModel.Workflow && eventModel.Workflow.StatusDescription
                        ? eventModel.Workflow.StatusDescription
                        : 'Event is currently ...'
                "
            ></i>
        </span>
        <button
            type="button"
            (click)="newCommentDialog(true)"
            class="btn btn-success"
            id="positive"
            title="{{ titlePos }}"
            *ngIf="nextStatus"
        >
            {{ nextStatus }}
        </button>
        <button
            (click)="newCommentDialog(false)"
            class="btn btn-danger"
            id="negative"
            title="{{ titleNeg }}"
            *ngIf="backStatus"
        >
            {{ backStatus }}
        </button>
    </div>
</div>

<div
    class="event-screen-system"
    menuSystem
    [cerrixTab]="tab"
    (change)="handleMenuItemClick($event)"
    *ngIf="eventModel"
>
    <div class="event-screens" [attr.name]="'Event'" menuicon="fas fa-bolt" menusystemsubmenu>
        <div name="Details" menuicon="fas fa-info-circle" menusystemitem>
            <event-detail-main
                [eventModel]="eventModel"
                [sdLight]="sdLight"
                [sdHeavy]="sdHeavy"
                [parentForm]="parentForm"
                [permissions]="eventPermissions"
                novalidate
            ></event-detail-main>
        </div>
        <div
            data-uiid="event-databreach"
            name="Data Breach"
            menuicon="fas fa-unlock-alt"
            menusystemitem
        >
            <breach-detail
                #dataBreachTab
                [eventForm]="parentForm"
                [eventPermissions]="eventPermissions"
            >
            </breach-detail>
        </div>
        <div class="event-screens" [attr.name]="'Links'" menuicon="fas fa-link" menusystemsubmenu>
            <div
                *ngIf="eventModel && eventModel.ID > 0"
                name="Measures of Improvement"
                menuicon="fas fa-tasks"
                menusystemitem
            >
                <linked-moi-overview
                    [moiType]="lerMoiType"
                    [parentGuid]="eventModel.Guid"
                    [canAdd]="eventPermissions.canAddMoi"
                    [canDelete]="eventPermissions.canDeleteMoi"
                    #eventMoisComponent
                >
                </linked-moi-overview>
            </div>
            <div
                *ngIf="eventModel && eventModel.ID > 0"
                name="Risks"
                menuicon="fas fa-exclamation-triangle"
                menusystemitem
            >
                <event-risk
                    [disabled]="!eventPermissions.canLinkRisks"
                    [eventModel]="eventModel"
                    [parentForm]="parentForm"
                    (linkChanged)="checkDirty()"
                    #eventRiskComponent
                >
                </event-risk>
            </div>
            <div
                *ngIf="eventModel && eventModel.ID > 0"
                name="Controls"
                menuicon="fas fa-shield-alt"
                menusystemitem
            >
                <event-control
                    [disabled]="!eventPermissions.canLinkControls"
                    [eventModel]="eventModel"
                    [parentForm]="parentForm"
                    (linkChanged)="checkDirty()"
                    #eventControlComponent
                >
                </event-control>
            </div>
            <div
                *ngIf="eventModel"
                name="Documents"
                menuItemId="menuItemDocuments"
                menuicon="far fa-file"
                menusystemitem
            >
                <div *ngIf="!documents">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="documents">
                    <document-manager-old
                        [(Documents)]="eventModel.Documents"
                        (DocumentsChange)="checkDocumentsDirty()"
                        [permissions]="eventPermissions.documentPermissions"
                        [DocumentTypeTargets]="targetModule"
                    ></document-manager-old>
                </div>
            </div>
            <div
                name="Hyperlinks"
                [attr.menuItemID]="hyperlinksMenuItemId"
                menuicon="fas fa-external-link-alt"
                menusystemitem
                *ngIf="eventModel"
            >
                <div *ngIf="!eventModel.Hyperlinks">
                    <div class="fancy-loader"></div>
                </div>
                <div *ngIf="eventModel.Hyperlinks">
                    <hyperlink-manager
                        [(Hyperlinks)]="eventModel.Hyperlinks"
                        (HyperlinksChanged)="checkHyperlinksDirty($event)"
                        [TargetModule]="targetModule"
                    ></hyperlink-manager>
                </div>
            </div>
        </div>
        <div
            *ngIf="eventModel && eventModel.ID > 0"
            name="History"
            menuicon="fas fa-history"
            menusystemsubmenu
        >
            <div name="Event History" menuicon="fas fa-bolt" menusystemitem>
                <app-history-overview
                    [historyDataService]="_eventDS"
                    [Id]="eventModel.Guid"
                    [useOldHistory]="true"
                    label="Event History"
                    #eventHistoryOverviewComponent
                >
                </app-history-overview>
            </div>
            <div name="Data Breach History" menuicon="fas fa-unlock-alt" menusystemitem>
                <app-history-overview
                    [historyDataService]="_breachDS"
                    [Id]="eventModel.DataBreachGuid"
                    [useOldHistory]="true"
                    #breachHistoryOverviewComponent
                >
                </app-history-overview>
            </div>
        </div>
        <div
            *ngIf="eventModel && eventModel.ID > 0"
            name="Options"
            menuicon="fas fa-check-double"
            menusystemitem
        >
            <detail-options
                moduleName="event"
                [objectName]="eventModel ? eventModel.Name : 'event'"
                [allowDelete]="eventPermissions.isAdmin"
                (onDelete)="delete()"
            >
            </detail-options>
        </div>
    </div>
</div>
