import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PopupActionResult } from "./models/popup-action-result.model";
import { EffConfirmationPopupComponent } from "./eff-confirmation-popup/eff-confirmation-popup.component";

@Injectable({
    providedIn: "root",
})
export class EffDialogService {
    constructor(public dialog: MatDialog) {}

    private resolvePromise: any = null;
    dialogRef: MatDialogRef<any>;

    showConfirmationDialog(
        title: string,
        message: string,
        confirmButton: string = "Confirm",
        cancelButton: string = "",
        width: string = "500px",
        confirmType: string = "confirm-button"
    ): Promise<PopupActionResult> {
        let promise = new Promise<PopupActionResult>((resolve, reject) => {
            this.resolvePromise = resolve;
        });

        this.dialogRef = this.dialog.open(EffConfirmationPopupComponent, {
            data: {
                message: message,
                title: title,
                confirmButton: confirmButton,
                confirmType: confirmType,
                cancelButton: cancelButton,
                onActionPerformed: this.onActionPerformed.bind(this),
            },
            panelClass: "eff-popup-wrapper",
            width: width,
        });

        this.dialogRef.backdropClick().subscribe(() => {
            this.resolvePromise(PopupActionResult.Cancel);
        });

        return promise;
    }

    private onActionPerformed(action: PopupActionResult) {
        this.resolvePromise(action);
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}
