<div *ngIf="model">
    <div *ngIf="isLoading">
        <div class="fancy-loader"></div>
    </div>
    <div *ngIf="!isLoading">
        <thirdparty-linkable
            #linkable
            data-uiid="auditthirdparty-linkable"
            typename="Thirdparty"
            displayValueProp="name"
            [linked]="linked"
            [unlinked]="unlinked"
            [hideActions]="true"
            [orderByValueProperty]="true"
            (linkedChange)="linkedChanged($event)"
            [disabled]="disabled"
        >
        </thirdparty-linkable>
    </div>
</div>
