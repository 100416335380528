<div class="container-fluid pt-3" *ngIf="finishedLoading">
    <ngx-masonry [options]="{ percentPosition: true, gutter: 10, horizontalOrder: false }">
        <div ngxMasonryItem class="masonry-item" *ngFor="let c of getCategories()">
            <div class="card">
                <div class="card-header">{{ c }} Reports</div>
                <div class="list-group">
                    <div
                        class="list-group-item list-group-item-action d-flex"
                        *ngFor="let d of getCategoryReports(c)"
                    >
                        <div class="w-100 justify-content-between" (click)="open(d)" role="button">
                            <h5 class="mb-1">{{ d.Name }}</h5>
                            <p class="mb-1">{{ d.Description }}</p>
                        </div>
                        <i
                            class="fas fa-download"
                            title="Download"
                            (click)="download(d)"
                            role="button"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    </ngx-masonry>
</div>
