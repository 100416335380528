import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "eff-textarea",
    templateUrl: "./eff-textarea.component.html",
    styleUrls: ["./eff-textarea.component.scss"],
})
export class EffTextareaComponent {
    @Input() label: string;
    @Input() model: any;
    @Input() disabled: boolean = false;
    @Input() formController: FormControl = new FormControl();

    @Output() modelChange: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        if (this.model) {
            this.formController.setValue(this.model);
        }

        if (this.disabled) {
            this.disableEnableFormControl();
        }
    }

    onModelChanged() {
        this.modelChange.emit(this.formController.value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["model"]) {
            this.formController.setValue(this.model);
        }

        if (changes["disabled"]) {
            this.disableEnableFormControl();
        }
    }

    disableEnableFormControl() {
        if (this.formController) {
            if (this.disabled) {
                this.formController.disable();
            } else {
                this.formController.enable();
            }
        }
    }
}
